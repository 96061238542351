import React, { Component } from "react";
import { TabView, TabPanel } from 'primereact/tabview';

//custom imports
import { PendingApplicant } from './PendingApplicant';
import { ExamMark } from "./ExamMark";
import { RejectedApplicant } from "./RejectedApplicant";

export class AdmisiaAssessmentMain extends Component {

    render() {

        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView>
                            <TabPanel header="Pending Applicant" leftIcon="fas fa-download"> <PendingApplicant /> </TabPanel>
                            <TabPanel header="Exam Mark" leftIcon="fas fa-upload"> <ExamMark /> </TabPanel>
                            <TabPanel header="Rejected Applicant" leftIcon="fas fa-upload"> <RejectedApplicant /></TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }
}