import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { Panel } from 'primereact/panel';
import { RadioButton } from 'primereact/radiobutton';
import { EmAdminPointService } from '../../service/emAdminPoint/EmAdminPointService';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';
import { pdfDownloadPortrait_TableWithSubTitle } from '../common/JsPdfDownload';

export class EmAuditPointUserList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            errorMsgVisible: false,
            topProgressBar: false,
            moduleEligibility: {},
            errorMsgBody: '',
            pointList: [],
            memberStatus: '',
            emInstituteInfo: {
                enableStatus: 0,
                moduleClassTest: 0,
                moduleGeneralAccounts: 0,
                moduleHrAttendance: 0,
                moduleHrManagement: 0,
                moduleInventoryl: 0,
                moduleMessaging: 0,
                modulePayroll: 0,
                moduleRoutine: 0,
                moduleSemesterExam: 0,
                moduleStudent: 0,
                moduleStudentAccounts: 0,
                moduleStudentAttendance: 0,
            },
            selectAllModule: 0

        }

        this.userOtherAccessService = new UserOtherAccessService();
        this.EmAdminPointService = new EmAdminPointService();
        this.exportPdf = this.exportPdf.bind(this);
    }

    onHide = (event) => {
        this.setState({ visible: false });
        this.setState({
            emInstituteInfo: {
                enableStatus: 0, moduleClassTest: 0, moduleGeneralAccounts: 0, moduleHrAttendance: 0, moduleHrManagement: 0, moduleInventoryl: 0, moduleMessaging: 0, modulePayroll: 0, moduleRoutine: 0, moduleSemesterExam: 0, moduleStudent: 0, moduleStudentAccounts: 0, moduleStudentAttendance: 0,
            },
            selectAllModule: 0
        })
    }

    viewDialog = (e, rowData) => {
        // console.log("dialog rowdata", rowData)
        this.setState({ visible: true });
        this.getEmInstituteInfo(rowData)
    }

    getEmInstituteInfo = (rowData) => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.userOtherAccessService.findEmModulesByInstituteIdForUpdate(rowData.emID, rowData.instituteID, rowData.netiID).then(res => {
            if (res.status == 200) {
                return res.json().then((body) => {
                    // console.log("emInstituteInfo from api", body);
                    this.setState({ moduleEligibility: body });
                    const entries = Object.entries(body)
                    let moduleObj = {
                        saModulePermissionID: ''
                    };
                    for (let [key, val] of entries) {
                        if (val === 8) {
                            moduleObj[key] = 0;
                        }

                        if (val === 1) {
                            moduleObj[key] = val;
                        }
                    }

                    // console.log("moduleObj", moduleObj);

                    this.setState({ emInstituteInfo: moduleObj })
                    this.setState({ topProgressBar: false, errorMsgVisible: false });
                })
            } else {
                this.userOtherAccessService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                    });
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
        });
    }

    onChangeModule = (e) => {
        let { emInstituteInfo } = this.state;
        let selectValue = emInstituteInfo[e.target.name] == 1 ? 0 : 1
        if (selectValue == 0) this.setState({ selectAllModule: 0 })
        emInstituteInfo[e.target.name] = selectValue
        this.setState({ emInstituteInfo })
    }

    onChangeAllModule = (e) => {
        let { emInstituteInfo } = this.state
        let selectValue = this.state.selectAllModule == 0 ? 1 : 0
        this.setState({ selectAllModule: selectValue })
        emInstituteInfo.moduleStudent = emInstituteInfo.moduleHrManagement = emInstituteInfo.moduleSemesterExam = emInstituteInfo.moduleClassTest = emInstituteInfo.moduleRoutine = emInstituteInfo.moduleStudentAccounts = selectValue
        this.setState({ emInstituteInfo })
    }

    onUpdateTagging = (e) => {
        let { emInstituteInfo, moduleEligibility } = this.state;
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        emInstituteInfo.saModulePermissionID = moduleEligibility.saModulePermissionID
        emInstituteInfo.edumanDetailsInfoDTO = moduleEligibility.edumanDetailsInfoDTO
        emInstituteInfo.saUserBasicInfoDTO = moduleEligibility.saUserBasicInfoDTO
        emInstituteInfo.assignDate = moduleEligibility.assignDate

        this.setState({ emInstituteInfo });
        // console.log("update institute obj", this.state.emInstituteInfo)
        this.EmAdminPointService.updateEmAuditPointTaggingInstituteInfo(this.state.emInstituteInfo).then(res => {
            if (res.status == 202) {
                this.setState({ topProgressBar: false, errorMsgVisible: false });
                this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Updated successfully' });
                this.onHide()
            } else {
                this.userOtherAccessService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                    });
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
        });
    }

    onUpdateStatus = () => {
        let submitData = {};
        submitData.enableStatus = this.state.emInstituteInfo.enableStatus;
        submitData.saModulePermissionID = this.state.emInstituteInfo.saModulePermissionID

        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.EmAdminPointService.updateEmAuditPointTaggingEnableStatus(submitData).then(res => {
            if (res.status == 202) {
                this.setState({ topProgressBar: false, errorMsgVisible: false });
                this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Status Updated successfully' });
                this.onHideUserStatus()
            } else {
                this.userOtherAccessService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                    });
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
        });
    }

    actionTemplate = (rowData) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="p-button-primary"
                style={{ marginRight: '.5em', 'fontSize': '1.2em' }}
                onClick={e => this.viewDialog(e, rowData)}
            >
            </Button>
        </div>;
    }

    onChangeStatus = (rowData) => {
        return <div>
            <span
            >{rowData.status}
                <Button
                    onClick={e => this.onChangeViewStatus(e, rowData)}
                    style={{ marginLeft: '1em', fontSize: '1.2em' }}
                    className="p-button-primary"
                    icon="fas fa-exclamation-circle"
                >
                </Button>
            </span>
        </div>;

    }

    onChangeViewStatus = (e, rowData) => {
        this.setState({ viewStatus: true });
        this.getEmInstituteInfo(rowData)
    }

    onHideUserStatus = () => {
        this.setState({ viewStatus: false });
        this.setState({
            emInstituteInfo: {
                enableStatus: 0, moduleClassTest: 0, moduleGeneralAccounts: 0, moduleHrAttendance: 0, moduleHrManagement: 0, moduleInventoryl: 0, moduleMessaging: 0, modulePayroll: 0, moduleRoutine: 0, moduleSemesterExam: 0, moduleStudent: 0, moduleStudentAccounts: 0, moduleStudentAttendance: 0,
            }
        })
    }

    onChangeStatusValue = () => {
        let { emInstituteInfo } = this.state;
        emInstituteInfo.enableStatus = emInstituteInfo.enableStatus == 1 ? 0 : 1
        this.setState({ emInstituteInfo })
    }

    exportPdf(){

        let pdfSubTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
          ];
         let  pdfSubTitleList = [
            { A: "Institute", B: this.props.instituteName, }
          ];

        let tableColumns = [
          { title: "custom ID", dataKey: "customID" },
          { title: "Name", dataKey: "name" },
          { title: "Mobile No.", dataKey: "mobileNo" },
        //   { title: "Status", dataKey: "status" },
        ];
        
        pdfDownloadPortrait_TableWithSubTitle("Audit Point Tagging User List", pdfSubTitleColumn, pdfSubTitleList, tableColumns, this.props.userTaggingList, "AuditPoint_Tagging_UserList.pdf");
    
      }

    render() {

        // console.log('userList', this.props.userTaggingList);
        
        let { topProgressBar, errorMsgVisible, errorMsgBody, emInstituteInfo, moduleEligibility } = this.state;
        let moduleHeader = <h3 className="text-center">Update Module Permission</h3>
        var tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            User List
            <span style={{ 'float': 'right' }}>Total Found: {this.props.userTaggingList.length} </span>
        </div>;
        return (

            <div className="p-fluid">
                <div className="p-grid">
                    {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                    {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            <Growl ref={(el) => this.growl = el} />
                            <DataTable
                                value={this.props.userTaggingList}
                                selectionMode="single"
                                header={tableHeader}
                                selection={this.state.dataTableSelection}
                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                responsive={true}
                                rows={10}
                                paginator={true}
                                rowsPerPageOptions={[5, 10, 20]}
                                columnResizeMode="fit"
                            >
                                <Column field="customID" header="Neti ID" filter={true} />
                                <Column field="name" header="Name" filter={true} />
                                <Column field="mobileNo" header="Mobile No." filter={true} />
                                <Column field="status" header="Status" body={this.onChangeStatus} filter={true} />
                                <Column field="action" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                            </DataTable>

                            <div className="nw-button-parent m-t-8 p-r-0">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Download"
                                    icon="fas fa-download"
                                    onClick={this.exportPdf}
                                />
                            </div>

                        </div>
                    </div>
                    <Dialog
                        className="nw-confirm-dialog"
                        header="Audit Point Tagging(Update Status)"
                        visible={this.state.viewStatus}
                        onHide={this.onHideUserStatus}
                        closable
                    >
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                    <div className="confirm-wrapper">
                                    <h3>Do you want to change Status?</h3>
                                    <br/>
                                        <div className="p-grid p-col-12">
                                            <div className="p-xl-6">
                                                <RadioButton inputId="cb1" value="1" onChange={this.onChangeStatusValue} checked={this.state.emInstituteInfo.enableStatus}></RadioButton>
                                                <label htmlFor="cb1" className="p-radiobutton-label">Enable</label>
                                            </div>
                                            <div className="p-xl-6">
                                                <RadioButton inputId="cb1" value="0" onChange={this.onChangeStatusValue} checked={!this.state.emInstituteInfo.enableStatus}></RadioButton>
                                                <label htmlFor="cb1" className="p-radiobutton-label">Disable</label>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12" >
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-center"
                                                label="Update"
                                                onClick={this.onUpdateStatus}
                                            />
                                        </div>

                                    </div>
                                    </div>
                             
                            
                        </div>
                    </Dialog>

                    <Dialog
                        header="Audit Point Tagging Update"
                        visible={this.state.visible}
                        className="nw-dialog"
                        onHide={this.onHide}
                        closable
                    >
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">

                                    <div className="card">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-grid p-col-12">
                                                            <div className="p-col-5" >  <label htmlFor="input">Institute</label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    {`${moduleEligibility.edumanDetailsInfoDTO && moduleEligibility.edumanDetailsInfoDTO.instituteId} - ${moduleEligibility.edumanDetailsInfoDTO && moduleEligibility.edumanDetailsInfoDTO.instituteName}`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-grid p-col-12">
                                                            <div className="p-col-5" >  <label htmlFor="input">Neti ID </label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    {moduleEligibility.saUserBasicInfoDTO && moduleEligibility.saUserBasicInfoDTO.customNetiID}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-grid p-col-12">
                                                            <div className="p-col-5" >  <label htmlFor="input">Name</label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    {moduleEligibility.saUserBasicInfoDTO && moduleEligibility.saUserBasicInfoDTO.fullName}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-grid p-col-12">
                                                            <div className="p-col-5" >  <label htmlFor="input">Mobile No </label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    {moduleEligibility.saUserBasicInfoDTO && moduleEligibility.saUserBasicInfoDTO.basicMobile}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-grid p-col-12">
                                                            <div className="p-col-5" >  <label htmlFor="input">Status </label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    {moduleEligibility.enableStatus === 1 ? "Enable" : "Disable"}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div>

                                                    <div className="p-col-12 p-xl-12"></div>
                                                    <Panel header={moduleHeader}>
                                                        <div className="p-grid p-col-12 p-xl-12" >
                                                            <div className="p-col-12 p-xl-12">
                                                                <Checkbox inputId="cb1" value="Module" onChange={this.onChangeAllModule} checked={this.state.selectAllModule}></Checkbox>
                                                                <label className="p-checkbox-label">Modules</label>
                                                            </div>
                                                        </div>
                                                        <hr />

                                                        {this.state.moduleEligibility.moduleStudent == 0 ?
                                                            '' :
                                                            <div className="p-grid p-col-12">
                                                                <div className="p-md-4"> <Checkbox inputId="cb1" value="Student" onChange={this.onChangeModule} checked={this.state.emInstituteInfo.moduleStudent} name="moduleStudent" ></Checkbox>
                                                                    <label htmlFor="cb1" className="p-checkbox-label">Student</label>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.moduleEligibility.moduleHrManagement == 0 ?
                                                            '' :
                                                            <div className="p-grid p-col-12">
                                                                <div className="p-md-4"> <Checkbox inputId="cb2" value="HR" onChange={this.onChangeModule} checked={this.state.emInstituteInfo.moduleHrManagement} name="moduleHrManagement" ></Checkbox>
                                                                    <label htmlFor="cb2" className="p-checkbox-label">HR Management</label>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.moduleEligibility.moduleSemesterExam == 0 ?
                                                            '' :
                                                            <div className="p-grid p-col-12">
                                                                <div className="p-md-4"> <Checkbox inputId="cb3" value="SemesterExam" onChange={this.onChangeModule} checked={this.state.emInstituteInfo.moduleSemesterExam} name="moduleSemesterExam" ></Checkbox>
                                                                    <label htmlFor="cb3" className="p-checkbox-label">Semester Exam</label>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.moduleEligibility.moduleClassTest == 0 ?
                                                            '' :

                                                            <div className="p-grid p-col-12">
                                                                <div className="p-md-4"> <Checkbox inputId="cb4" value="Class Test" onChange={this.onChangeModule} checked={this.state.emInstituteInfo.moduleClassTest} name="moduleClassTest" ></Checkbox>
                                                                    <label htmlFor="cb4" className="p-checkbox-label">Class Test</label>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.moduleEligibility.moduleRoutine == 0 ?
                                                            '' :
                                                            <div className="p-grid p-col-12">
                                                                <div className="p-md-4"> <Checkbox inputId="cb5" value="Routine" onChange={this.onChangeModule} checked={this.state.emInstituteInfo.moduleRoutine} name="moduleRoutine" ></Checkbox>
                                                                    <label htmlFor="cb5" className="p-checkbox-label">Routine</label>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.moduleEligibility.moduleStudentAccounts == 0 ?
                                                            '' :
                                                            <div className="p-grid p-col-12">
                                                                <div className="p-md-5"> <Checkbox inputId="cb6" value="Student Accounts" onChange={this.onChangeModule} checked={this.state.emInstituteInfo.moduleStudentAccounts} name="moduleStudentAccounts" ></Checkbox>
                                                                    <label htmlFor="cb6" className="p-checkbox-label">Student Accounts</label>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.moduleEligibility.moduleStudentAttendance == 0 ?
                                                            '' :
                                                            <div className="p-col-12">
                                                                <Checkbox inputId="cb7"
                                                                    value="Student Attendance"
                                                                    name="moduleStudentAttendance"
                                                                    onChange={this.onChangeModule}
                                                                    checked={this.state.emInstituteInfo.moduleStudentAttendance}
                                                                />
                                                                <label htmlFor="cb7" className="p-checkbox-label">Student Attendance</label>
                                                            </div>
                                                        }

                                                        {this.state.moduleEligibility.moduleHrAttendance == 0 ?
                                                            '' :
                                                            <div className="p-col-12">
                                                                <Checkbox inputId="cb8"
                                                                    value="Hr Attendance"
                                                                    name="moduleHrAttendance"
                                                                    onChange={this.onChangeModule}
                                                                    checked={this.state.emInstituteInfo.moduleHrAttendance}
                                                                />
                                                                <label htmlFor="cb8" className="p-checkbox-label">HR Attendance</label>
                                                            </div>
                                                        }

                                                        {this.state.moduleEligibility.modulePayroll == 0 ?
                                                            '' :
                                                            <div className="p-col-12">
                                                                <Checkbox inputId="cb9"
                                                                    value="Payroll"
                                                                    name="modulePayroll"
                                                                    onChange={this.onChangeModule}
                                                                    checked={this.state.emInstituteInfo.modulePayroll}
                                                                />
                                                                <label htmlFor="cb9" className="p-checkbox-label">Payroll</label>
                                                            </div>
                                                        }

                                                        {this.state.moduleEligibility.moduleInventory == 0 ?
                                                            '' :
                                                            <div className="p-col-12">
                                                                <Checkbox inputId="cb10"
                                                                    value="Inventory"
                                                                    name="moduleInventory"
                                                                    onChange={this.onChangeModule}
                                                                    checked={this.state.emInstituteInfo.moduleInventory}
                                                                />
                                                                <label htmlFor="cb10" className="p-checkbox-label">Inventory</label>
                                                            </div>
                                                        }

                                                        {this.state.moduleEligibility.moduleGeneralAccounts == 0 ?
                                                            '' :

                                                            <div className="p-col-12">
                                                                <Checkbox inputId="cb11"
                                                                    value="General Accounts"
                                                                    name="moduleGeneralAccounts"
                                                                    onChange={this.onChangeModule}
                                                                    checked={this.state.emInstituteInfo.moduleGeneralAccounts}
                                                                />
                                                                <label htmlFor="cb11" className="p-checkbox-label">General Accounts</label>
                                                            </div>
                                                        }

                                                        {this.state.moduleEligibility.moduleMessaging == 0 ?
                                                            '' :
                                                            <div className="p-col-12">
                                                                <Checkbox inputId="cb12"
                                                                    value="Messaging"
                                                                    name="moduleMessaging"
                                                                    onChange={this.onChangeModule}
                                                                    checked={this.state.emInstituteInfo.moduleMessaging}
                                                                />
                                                                <label htmlFor="cb12" className="p-checkbox-label">Messaging</label>
                                                            </div>
                                                        }
                                                    </Panel>
                                                    <div className="p-col-12 p-xl-12 nw-button-parent" >
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            label="Update"
                                                            icon="fas fa-check"
                                                            onClick={this.onUpdateTagging}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        {/* <div className="p-fluid">
                            <div className="p-grid">
                                <Fieldset className="p-col-12 p-xl-12" legend="Institute Info">
                                    <div>
                                        <div className="card card-w-title">
                                            <div className="p-grid">
                                               
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-col-12 p-md-12">
                                                        <div className="p-grid p-col-12">
                                                            <div className="p-col-5" >  <label htmlFor="input">Education Board</label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    Mohakhali, dhaka
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-grid p-col-12">
                                                            <div className="p-col-5" >  <label htmlFor="input">Institute Type </label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    Mohakhali, dhaka
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-grid p-col-12">
                                                            <div className="p-col-5" >  <label htmlFor="input">Institute Category</label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    Mohakhali, dhaka
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-grid p-col-12">
                                                            <div className="p-col-5" >  <label htmlFor="input">Total Student </label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    Mohakhali, dhaka
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-grid p-col-12">
                                                            <div className="p-col-5" >  <label htmlFor="input">Total Teacher </label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    Mohakhali, dhaka
                                                                </div>
                                                            </div>
                                                        </div>
                                                
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fieldset>
                               
                            </div>
                        </div> */}
                    </Dialog>

                </div>
            </div>
        );
    }
}