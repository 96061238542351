import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { ErrorMessageView } from "../common/ErrorMessageView";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputSwitch } from "primereact/inputswitch";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";

//antd
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

import { UserCategoryService } from "../../service/UserCategoryService";
import { AdminPointService } from "../../service/adminPoint/AdminPointService";
import { ProfileService } from "../../service/profile/ProfileService";
import { NetiFileHandler } from "../../utils/NetiFileHandler";
import { Editor } from "primereact/editor";
import NetiContentLoader from "../common/NetiContentLoader";

import { ValidatorUtility } from "../../utils/ValidatorUtility";
import CommonFuctionality from "../common/CommonFuctionality";
// import { ProducListInfoDialog } from "./ProducListInfoDialog";

import { Formik } from "formik";
import * as Yup from "yup";
import OtherProductInfoDetails from "./OtherProductInfoDetails";
import UpdateOtherProductInfoDetails from "./UpdateOtherProductInfoDetails";

let cloneStateBeforeMount;
const hundred = 100;

export default function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const OtherProductInfo = () => {
  const commonFuctionality = new CommonFuctionality();
  const validatorUtility = new ValidatorUtility();
  const adminPointService = new AdminPointService();
  const profileService = new ProfileService();
  const growl = useRef(null);

  const [topProgressBar, settopProgressBar] = useState(false);
  const [errorMsgVisible, seterrorMsgVisible] = useState(false);
  const [dataTableIsLoading, setdataTableIsLoading] = useState(false);
  const [errorMsgBody, seterrorMsgBody] = useState("");
  const [generateCode, setgenerateCode] = useState(1);
  const netiContentLoader = new NetiContentLoader();
  const [additionalInfo, setadditionalInfo] = useState(false);
  const [togleBtnActive, setTogleBtnActive] = useState(false);
  const [salesPrice, setsalesPrice] = useState("");
  const [vatPercent, setvatPercent] = useState("");
  const [selectedRowData, setselectedRowData] = useState({});
  const [viewDialogueShow, setviewDialogueShow] = useState(false);
  const [updateDialogueShow, setupdateDialogueShow] = useState(false);
  const [dataTableValue, setdataTableValue] = useState([]);
  const [userDetails, setuserDetails] = useState({});
  const [fileList, setfileList] = useState([]);
  const [imageContent, setImageContent] = useState("");
  const [imageName, setimageName] = useState("");

  useLayoutEffect(() => {
    fetchOtherProductList();
  }, []);

  useEffect(() => {
    setUserProfileDetails();
  }, []);

  const setUserProfileDetails = async () => {
    const profile = await JSON.parse(
      profileService.getUserDetailsFromLocalStorage()
    );
    setuserDetails(profile);
  };

  console.log({ userDetails });

  const fetchOtherProductList = () => {
    settopProgressBar(true);
    setdataTableIsLoading(true);
    seterrorMsgVisible(false);
    adminPointService
      .fetchOtherProductList()
      .then((res) => {
        //may be changed later
        if (res.status == 200) {
          return res.json().then((body) => {
            setdataTableValue(body.item);
            settopProgressBar(false);
            setdataTableIsLoading(false);
          });
        } else {
          adminPointService.Auth.handleErrorStatus(res).then((resp) => {
            settopProgressBar(false);
            setdataTableIsLoading(false);
            seterrorMsgVisible(true);
            seterrorMsgBody(resp);
          });
        }
      })
      .catch((error) => {
        settopProgressBar(false);
        setdataTableIsLoading(false);
        seterrorMsgVisible(true);
        seterrorMsgBody("Please check your connection");
      });
  };

  const handleImageChange = async ({ fileList }) => {
    setfileList(fileList);
    let imageContent = "";
    if (fileList && fileList.length) {
      imageContent = await getBase64(fileList[0].originFileObj);
      var keyw = "data:" + fileList[0].type + ";base64,"; //link will be same from the word webapps in URL
      var url = imageContent.substring(
        imageContent.indexOf(keyw) + keyw.length
      );
      setImageContent(url);
      setimageName(fileList[0].originFileObj.name);
    } else {
      setImageContent("");
      setimageName("");
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const initialFormValues = {
    productType: "",
    productTypeDefaultCode: "",
    productName: "",
    defaultCode: "",
    productDetails: "",
    remainingStock: "",
    sellNumber: "",
    multiNumber: "",
    salesPrice: "",
    vatPercent: "",
    videoUrl: "",
  };

  const ytVurlRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm;
  const nameRegex = /^[A-Za-z ]+$/;

  const formValidationSchema = Yup.object().shape({
    productType: Yup.string()
      //   .matches(nameRegex, 'Only text is allowed')
      .label("Category Type")
      .required("Category Type is required"),
    productTypeDefaultCode: Yup.string()
      .label("Category Type Default Code")
      .required("Category Type Default Code is required"),

    productName: Yup.string()
      .label("Product Name")
      .required("Product Name is required"),

    defaultCode: Yup.string()
      //   .matches(phoneRegex, 'Invalid Phone Number')
      .label("Default Code")
      .required("Default Code is required"),

    productDetails: Yup.string()
      .label("Product Details")
      .required("Product Details is required"),

    remainingStock: Yup.number("Must be stock amount").required(
      "Stock amount is required"
    ),
    // .positive("Must be positive value"),

    sellNumber: additionalInfo
      ? Yup.string().label("Sell Number").required("Sell Number is required")
      : null,

    multiNumber: additionalInfo
      ? Yup.string().label("Multi Number").required("Multi Number is required")
      : null,

    salesPrice: Yup.number("Must be price amount").required(
      "Sales price is required"
    ),
    // .positive("Must be positive value"),

    vatPercent: Yup.number("Must be vat percentage amount"),
    // .positive("Must be positive value"),

    videoUrl: Yup.string()
      .matches(ytVurlRegex, "Not Valid Url")
      .label("Video Url"),
  });

  const onProductSubmitHandler = (values, actions) => {
    let requestedObj = {};

    // if (this.handleError()) {
    if (true) {
      settopProgressBar(true);
      setdataTableIsLoading(true);
      seterrorMsgVisible(false);

      if (additionalInfo) {
        requestedObj = {
          // ...values,
          productDefaultCode: values.defaultCode,
          productName: values.productName,
          productDetails: values.productDetails,
          productAdditionalStatus: 1,
          remainingStock: values.remainingStock,
          salesPrice: values.salesPrice,
          percentVat: vatPercent === "" ? 0 : vatPercent,
          codeGenStatus: generateCode,
          bijoySaltNumber: values.sellNumber,
          bijoyMultiNumber: values.multiNumber,
          addedNetiId: userDetails.customNetiID,
          categoryDefaultCode: values.productTypeDefaultCode,
          productCategory: values.productType,
          imageContent: imageContent,
          imageName: imageName,
          imageSaveOrEditable: imageContent.length ? true : false,
          videoUrl: values.videoUrl,
        };
      } else {
        requestedObj = {
          // ...values,
          productDefaultCode: values.defaultCode,
          productName: values.productName,
          productDetails: values.productDetails,
          productAdditionalStatus: 0,
          remainingStock: values.remainingStock,
          salesPrice: values.salesPrice,
          percentVat: vatPercent === "" ? 0 : vatPercent,
          codeGenStatus: generateCode,
          addedNetiId: userDetails.customNetiID,
          categoryDefaultCode: values.productTypeDefaultCode,
          productCategory: values.productType,
          imageContent: imageContent,
          imageName: imageName,
          imageSaveOrEditable: imageContent.length ? true : false,
          videoUrl: values.videoUrl,
        };
      }
      adminPointService
        .createOtherProduct(requestedObj)
        .then((res) => {
          if (res.status == 201) {
            growl.current.show({
              severity: "success",
              summary: "Success",
              detail: "Data saved successfully.",
            });

            actions.resetForm();
            setadditionalInfo(false);
            setTogleBtnActive(false);
            setfileList([]);
            setsalesPrice("");
            setvatPercent("");
            fetchOtherProductList();
            settopProgressBar(false);
            setdataTableIsLoading(false);
          } else {
            adminPointService.Auth.handleErrorStatus(res).then((resp) => {
              settopProgressBar(false);
              setdataTableIsLoading(false);
              seterrorMsgVisible(true);
              seterrorMsgBody(resp);
            });
          }
        })
        .catch((error) => {
          seterrorMsgVisible(true);
          settopProgressBar(false);
          setdataTableIsLoading(false);
          seterrorMsgBody("Please check your connection !!!");
        });
    }
  };

  const onChangeSalesPrice = (value) => {
    setsalesPrice(value);
  };

  const onChangeVatPercent = (value) => {
    setvatPercent(value);
  };

  let requiredSign = (
    <span style={{ color: "red", marginRight: "2px" }}>*</span>
  );

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  let vatAmountCal = (salesPrice * vatPercent) / 100;

  let detailsTableHeader = (
    <div className="header-title">
      <div className="header-title-left">Product List</div>
      <div className="header-title-right">
        <a className="total-found">Total Found: {dataTableValue.length}</a>
      </div>
    </div>
  );

  let editBody = (rowData) => {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button"
          icon="far fa-eye"
          title="View"
          onClick={(e) => viewDialog(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "top" }}
        />
        <Button
          className="nw-action-button"
          icon="fas fa-pencil-alt"
          title="Edit"
          onClick={(e) => updateDialog(rowData)}
          tooltip="Edit"
          tooltipOptions={{ position: "top" }}
        />
      </div>
    );
  };

  const viewDialog = (rowData) => {
    setselectedRowData(rowData);
    setviewDialogueShow(true);
    commonFuctionality.blurDialogBackgroundActive();
    // this.getProductOwnerRevenueInfo(rowData.productID);
  };

  const updateDialog = (rowData) => {
    setselectedRowData(rowData);
    setupdateDialogueShow(true);
    commonFuctionality.blurDialogBackgroundActive();
    // this.getProductOwnerRevenueInfo(rowData.productID);
  };

  const onHideViewDialog = () => {
    setviewDialogueShow(false);
    commonFuctionality.blurDialogBackgroundDeActive();
  };

  const onHideUpdateDialog = () => {
    setupdateDialogueShow(false);
    commonFuctionality.blurDialogBackgroundDeActive();
  };

  return (
    <div className="p-fluid admin-point">
      {topProgressBar ? (
        <ErrorMessageView topProgressBar={topProgressBar} />
      ) : null}
      {errorMsgVisible ? (
        <ErrorMessageView
          errorMsgVisible={errorMsgVisible}
          errorMsgBody={errorMsgBody}
        />
      ) : null}
      <div className="main-section blur-section">
        <div className="p-grid">
          <Growl ref={growl} />
          <div className="p-col-12 p-xl-12 nw-form">
            <div className="p-col-12 p-xl-12">
              <div className="p-grid nw-form-body">
                <Formik
                  initialValues={initialFormValues}
                  onSubmit={(values, actions) =>
                    onProductSubmitHandler(values, actions)
                  }
                  validationSchema={formValidationSchema}
                  validateOnBlur={false}
                >
                  {({
                    handleChange,
                    values,
                    handleSubmit,
                    errors,
                    isSubmitting,
                    touched,
                    setFieldTouched,
                  }) => (
                    <form onSubmit={handleSubmit} className="p-col-12 p-xl-12">
                      <div className="p-col-12 p-xl-12">
                        <div className="p-col-12 p-xl-12">
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Category Type {requiredSign}
                              </span>
                              <InputText
                                id="productType"
                                name="productType"
                                placeholder="Enter Product Type"
                                value={values.productType}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("productType");
                                }}
                                type="text"
                              />
                            </div>
                            <span className="error-message">
                              {touched.productType && errors.productType}
                            </span>
                          </div>
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Category Default Code {requiredSign}
                              </span>
                              <InputText
                                id="productTypeDefaultCode"
                                name="productTypeDefaultCode"
                                placeholder="Enter Product Type Default Code"
                                value={values.productTypeDefaultCode}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("productTypeDefaultCode");
                                }}
                                type="text"
                              />
                            </div>
                            <span className="error-message">
                              {touched.productTypeDefaultCode &&
                                errors.productTypeDefaultCode}
                            </span>
                          </div>
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Product Name {requiredSign}
                              </span>
                              <InputText
                                id="productName"
                                name="productName"
                                placeholder="Enter Product Name"
                                value={values.productName}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("productName");
                                }}
                                type="text"
                              />
                            </div>
                            <span className="error-message">
                              {touched.productName && errors.productName}
                            </span>
                          </div>
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Default Code {requiredSign}
                              </span>
                              <InputText
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("defaultCode");
                                }}
                                value={values.defaultCode}
                                placeholder="Enter Default Code"
                                type="text"
                                id="defaultCode"
                                name="defaultCode"
                              />
                            </div>
                            <span className="error-message">
                              {touched.defaultCode && errors.defaultCode}
                            </span>
                          </div>
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon nw-inputtextarea-label">
                                Product Details <span>*</span>
                              </span>
                              <InputTextarea
                                placeholder="Enter Product Details"
                                name="productDetails"
                                value={values.productDetails}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("productDetails");
                                }}
                              />
                            </div>
                            <span className="error-message">
                              {touched.productDetails && errors.productDetails}
                            </span>
                          </div>
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Remaining Stock {requiredSign}
                              </span>
                              <InputText
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("remainingStock");
                                }}
                                value={values.remainingStock}
                                placeholder="Enter Stock Amount"
                                type="text"
                                id="remainingStock"
                                name="remainingStock"
                              />
                            </div>
                            <span className="error-message">
                              {touched.remainingStock && errors.remainingStock}
                            </span>
                          </div>
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <div className="input-radio-button">
                                <span className="p-inputgroup-addon addon-no-style">
                                  Generate Code <span>*</span>
                                </span>
                                <div className="radio-button-inside">
                                  <RadioButton
                                    value={1}
                                    inputId="rb1"
                                    onChange={(event) =>
                                      setgenerateCode(event.value)
                                    }
                                    checked={generateCode === 1}
                                  />
                                  <label
                                    htmlFor="rb1"
                                    className="p-radiobutton-label"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="radio-button-inside">
                                  <RadioButton
                                    value={0}
                                    inputId="rb2"
                                    onChange={(event) =>
                                      setgenerateCode(event.value)
                                    }
                                    checked={generateCode === 0}
                                  />
                                  <label
                                    htmlFor="rb2"
                                    className="p-radiobutton-label"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                            <span className="error-message">
                              {errors["generateCode"]}
                            </span>
                          </div>
                          <div className="p-col-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon addon-no-style">
                                Additional Info<span></span>
                              </span>
                              <div className="nw-inputgroup-desc desc-no-style custom-inputswitch">
                                <span className="p-radiobutton-label">No</span>
                                <InputSwitch
                                  checked={additionalInfo}
                                  onChange={(e) => {
                                    setadditionalInfo(e.value);
                                    setTogleBtnActive(!togleBtnActive);
                                  }}
                                />
                                {togleBtnActive === true ? (
                                  <span
                                    className="p-radiobutton-label"
                                    style={{ color: "#0099FF" }}
                                  >
                                    Yes
                                  </span>
                                ) : (
                                  <span className="p-radiobutton-label">
                                    Yes
                                  </span>
                                )}
                              </div>
                            </div>
                            <span className="error-message"></span>
                          </div>
                          {additionalInfo ? (
                            <>
                              <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                  <span className="p-inputgroup-addon">
                                    Bijoy Sell Number {requiredSign}
                                  </span>
                                  <InputText
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldTouched("sellNumber");
                                    }}
                                    value={values.sellNumber}
                                    placeholder="Enter Bijoy Sell Number"
                                    type="text"
                                    id="sellNumber"
                                    name="sellNumber"
                                  />
                                </div>
                                <span className="error-message">
                                  {touched.sellNumber && errors.sellNumber}
                                </span>
                              </div>
                              <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                  <span className="p-inputgroup-addon">
                                    Bijoy Multi Number {requiredSign}
                                  </span>
                                  <InputText
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldTouched("multiNumber");
                                    }}
                                    value={values.multiNumber}
                                    placeholder="Enter Bijoy Sell Number"
                                    type="text"
                                    id="multiNumber"
                                    name="multiNumber"
                                  />
                                </div>
                                <span className="error-message">
                                  {touched.multiNumber && errors.multiNumber}
                                </span>
                              </div>
                            </>
                          ) : null}
                          <div className="p-col-12 p-xl-12" />
                        </div>
                      </div>
                      <div className="p-col-12 p-col-nogutter">
                        <div className="p-col-12">
                          <div class="from-group-heading">
                            <span>Price Configuration</span>
                          </div>
                        </div>
                        <div className="p-col-12">
                          <div className="calculation-box-wrapper">
                            <div className="p-md-3 p-sm-12">
                              <div className="calculation-box plusSign">
                                <div className="calculation-box-title">
                                  <span class="p-inputgroup-addon">
                                    Sales Price <span>*</span>
                                  </span>
                                </div>
                                <div className="calculation-box-inner-wrapper">
                                  <div className="calculation-box-amount">
                                    {validatorUtility.currencyFormatter(
                                      salesPrice
                                    )}
                                  </div>
                                  <div className="calculation-box-field inputTkSign">
                                    <span className="input-label">
                                      enter sales price
                                    </span>
                                    <InputText
                                      name="salesPrice"
                                      value={values.salesPrice}
                                      onChange={(e) => {
                                        onChangeSalesPrice(e.target.value);
                                        handleChange(e);
                                        setFieldTouched("salesPrice");
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <span className="error-message">
                                {touched.salesPrice && errors.salesPrice}
                              </span>
                            </div>
                            <div className="p-md-3 p-sm-12">
                              <div className="calculation-box">
                                <div className="calculation-box-title">
                                  <span class="p-inputgroup-addon">
                                    Vat Amount
                                  </span>
                                </div>
                                <div className="calculation-box-inner-wrapper">
                                  <div className="calculation-box-amount">
                                    {validatorUtility.currencyFormatter(
                                      vatAmountCal
                                    )}
                                  </div>
                                  <div className="calculation-box-field percentSign">
                                    <span className="input-label">
                                      enter vat percent
                                    </span>
                                    <InputText
                                      name="vatPercent"
                                      value={values.vatPercent}
                                      onChange={(e) => {
                                        onChangeVatPercent(e.target.value);
                                        handleChange(e);
                                        setFieldTouched("vatPercent");
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <span className="error-message">
                                {touched.vatPercent && errors.vatPercent}
                              </span>
                            </div>
                            <div className="p-md-3 p-sm-12">
                              <div className="calculation-total-box">
                                <div className="calculation-box-inner-wrapper">
                                  <div className="calculation-box-title">
                                    <span class="p-inputgroup-addon">
                                      Total Price Including VAT
                                    </span>
                                  </div>
                                  <div className="calculation-box-amount">
                                    {validatorUtility.currencyFormatter(
                                      +salesPrice + vatAmountCal
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup nw-upload-button">
                          <span
                            style={{
                              height: "140px",
                            }}
                            className="p-inputgroup-addon nw-inputtextarea-label"
                          >
                            Upload Image <span></span>
                            <br />
                            <span>(PNG/JPG/JPEG/GIF) </span>
                          </span>

                          <div className="nw-upload-button-inside">
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              customRequest={dummyRequest}
                              onChange={handleImageChange}
                              fileList={fileList}
                              accept="image/png, image/jpeg"
                            >
                              {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                          </div>
                        </div>
                        <span className="error-message"></span>
                      </div>
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">Video URL</span>
                          <InputText
                            id="videoUrl"
                            name="videoUrl"
                            placeholder="Enter Video URL"
                            value={values.videoUrl}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("videoUrl");
                            }}
                            type="text"
                          />
                        </div>
                        <span className="error-message">
                          {touched.videoUrl && errors.videoUrl}
                        </span>
                      </div>
                      <div className="p-col-12 p-xl-12 nw-button-parent">
                        <div className="required-field">
                          (<span>*</span>) required fields
                        </div>
                        <Button
                          className="p-button p-button-primary nw-button nw-button-right"
                          label="Save"
                          icon="fas fa-check"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                          }}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              <div className="p-col-12 p-xl-12">
                <div className="p-col-12 p-xl-12 nw-datatable-detail-view product-list-datatable-detail-view">
                  {dataTableIsLoading ? (
                    netiContentLoader.MyPointTableLoader()
                  ) : (
                    <DataTable
                      header={detailsTableHeader}
                      value={dataTableValue}
                      selectionMode="single"
                      //selection={this.state.dataTableSelection}
                      //onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                      responsive={true}
                      paginator={true}
                      rows={20}
                      // globalFilter={this.state.globalFilter}
                    >
                      <Column field="productName" header="Product" />
                      <Column field="productCategory" header="Product Type" />
                      <Column field="remainingStock" header="In Stock" />
                      <Column
                        field="productDefaultCode"
                        header="Default Code"
                      />
                      <Column field="salesPrice" header="Total Price" />
                      <Column header="Action" body={editBody} />
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dialog-section">
        {" "}
        <Dialog
          header="Other Product Information"
          className="nw-dialog"
          visible={viewDialogueShow}
          onHide={onHideViewDialog}
          maximizable
        >
          <div className="p-fluid">
            <div className="p-grid">
              <div className="p-col-12 p-xl-12">
                {viewDialogueShow ? (
                  <OtherProductInfoDetails
                    rowDataInfo={selectedRowData}
                    productViewDialog={onHideViewDialog}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
      <div className="dialog-section">
        <Dialog
          header="Update Product Information"
          className="nw-dialog"
          visible={updateDialogueShow}
          onHide={onHideUpdateDialog}
          maximizable
        >
          <div className="p-fluid">
            <div className="p-grid">
              <div className="p-col-12 p-xl-12">
                {updateDialogueShow ? (
                  <UpdateOtherProductInfoDetails
                    rowDataInfo={selectedRowData}
                    productUpdateDialog={onHideUpdateDialog}
                    settopProgressBar={settopProgressBar}
                    setdataTableIsLoading={setdataTableIsLoading}
                    seterrorMsgVisible={seterrorMsgVisible}
                    seterrorMsgBody={seterrorMsgBody}
                    growl={growl}
                    fetchOtherProductList={fetchOtherProductList}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export { OtherProductInfo };
