import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { FileUpload } from 'primereact/fileupload';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { HostingOrderList } from './HostingOrderList';
import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';


let errors = {}
let cloneStateBeforeMount;
export class HostingOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hostingError: [],
            hostingOrderErrorHandle: [],
            searchView: false,
            searchViewError: false,
            searchErrorMsgBody: '',
            buttonDisabled: true,
            purchaseCodeError: [],
            chekMessage: '',
            purchaseCodeDomain: '',
            purchaseCodeHosting: '',
            domainDetails: {
                purchaseCode: ''

            },
            hostingDetails: {
                purchaseCode: ''
            },
            orderInfo: {
                attachFileName: '',
                attachFilePath: '',
                attachFileContent: '',
                attachFileSaveOrEditable: true,
                productPurchaseCodeDTO: {}
            },
            uploadfile: {
            },
            errors: {},
            hostingOrderListReload: true,
            topProgressBar: false,
            errorMsgVisible: false,
            inputFieldIsLoading: false,
            errorMsgBody: ''

        }

        this.PartnerPointService = new PartnerPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.NetiFileHandler = new NetiFileHandler(); this.netiContentLoader = new NetiContentLoader();

    }

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state))
    }

    purchaseCodeHostingHandler = (event) => {
        errors["purchaseCode"] = "";
        this.setState({ purchaseCodeHosting: event.target.value })
    }

    urlHandler = (event) => {
        let { orderInfo } = this.state;
        orderInfo.domainName = event.target.value
        this.setState({ orderInfo })
    }

    searchPurchaseCode = (e) => {
        this.setState({ hostingDetails: cloneStateBeforeMount.hostingDetails, searchErrorMsgBody:''  });
        this.setState({searchView: false});
        if (this.purchaseCodeError()) {
            this.setState({ topProgressBar: true,searchViewError: false, searchView: true, inputFieldIsLoading: true, errorMsgVisible: false });
            this.PartnerPointService.getUserPurchaseCode(this.state.purchaseCodeHosting)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {

                            let productDefaultCode = body.productPurchaseLogDTO.productInfoDTO.productDefaultCode

                            if(productDefaultCode == 327 ){
                                let { hostingDetails } = this.state;
                                hostingDetails.productPurchaseCodeID = body.productPurchaseCodeID
                                hostingDetails.productName = body.productPurchaseLogDTO.productInfoDTO.productName;
                                hostingDetails.purchaseCode = body.purchaseCode;
                                hostingDetails.usedStatus = body.usedStatus;
                                hostingDetails.purchaseDate = NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
                                this.setState({ hostingDetails, buttonDisabled: false, searchViewError: false,searchView: true, topProgressBar: false, inputFieldIsLoading: false, errorMsgVisible: false });
                            }else{
                                this.setState({
                                    searchErrorMsgBody: "Purchase code is invalid for this segment",
                                    searchViewError: true,
                                    buttonDisabled: true,
                                    searchView: true, 
                                    hostingDetails: {
                                        purchaseCode: '',
                                    }
                                });
                                this.setState({ topProgressBar: false, inputFieldIsLoading: false, errorMsgVisible: false });
                            }
                            
                        })
                    } else {
                        this.PartnerPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({
                                searchErrorMsgBody: resp,
                                searchViewError: true,
                                buttonDisabled: true,
                                searchView: true, 
                                hostingDetails: {
                                    purchaseCode: '',
                                }
                            });
                            this.setState({ topProgressBar: false, inputFieldIsLoading: false, errorMsgVisible: false });
                        })
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, buttonDisabled: true, inputFieldIsLoading: false, errorMsgVisible: true,searchView: false, searchViewError: false,  errorMsgBody: 'Unable to load. Please check your connection' });
                })
        }
    }

    // exitsUrlCheckHandler = () => {
    //     if (this.hostingOrderErrorHandle()) {
    //         let { orderInfo, hostingOrderErrorHandle } = this.state;
    //         this.PartnerPointService.checkExitsUrl(orderInfo.domainName)
    //             .then(res => {
    //                 if (res.status == 200) {
    //                     hostingOrderErrorHandle["success"] = "This URL is avaiable";
    //                     this.setState({ hostingOrderErrorHandle });
    //                 }
    //                 else {
    //                     hostingOrderErrorHandle["success"] = "This URL is currently unavaiable Please try another one";
    //                     this.setState({ hostingOrderErrorHandle });
    //                 }
    //             });

    //     }

    // }

    onLoadFile(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                fileContent: urlStr,
                contentName: photo.name
            };
            scope.setState({ uploadfile: album });

        }
    }

    hostingOrderHandler = () => {

        let { orderInfo, domainDetails, hostingDetails } = this.state;

        if (this.props.hosting == true) {
            orderInfo.productPurchaseCodeDTO.productPurchaseCodeID = hostingDetails.productPurchaseCodeID
            orderInfo.productPurchaseCodeDTO.purchaseCode = hostingDetails.purchaseCode
        }

        if (this.hostingOrderErrorHandle()) {

            if (this.state.uploadfile.fileContent != null) {
                orderInfo.attachFileContent = this.state.uploadfile.fileContent;
                orderInfo.attachFileSaveOrEditable = true;
                let extention = this.NetiFileHandler.getImageExtention(this.state.uploadfile.extention);
                orderInfo.attachFileName = 'hosting_order_file_' + Date.now() + extention;
            }
            else {
                orderInfo.attachFileSaveOrEditable = false;
            }

            this.setState({ topProgressBar: true, errorMsgVisible: false });

            this.PartnerPointService.createNewHostingOrder(orderInfo)
                .then(res => {

                    if (res.status == 201) {
                        return res.json().then((body) => {
                            this.growl.show({ severity: 'success', summary: 'Order Successfully', detail: 'Data Saved' });
                            this.setState(cloneStateBeforeMount);
                            errors = {};
                            this.setState({ hostingOrderListReload: false });
                            this.setState({ hostingOrderListReload: true });
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                        })
                    } else {
                        this.PartnerPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({  topProgressBar: false, errorMsgVisible: true, errorMsgBody:resp });
                            errors = {}
                        })
                    }
                })
        }

    }

    purchaseCodeError() {
        let formIsValid = true;
        if (this.state.purchaseCodeHosting === '') {
            formIsValid = false;
            errors["purchaseCode"] = "Purchase Code can't left empty";
        } else {
            errors["purchaseCode"] = "";
        }
        return formIsValid;
    }

    hostingError() {
        let { purchaseCode, hostingError } = this.state;
        let formIsValid = true;
        if (this.state.purchaseCode === '') {
            formIsValid = false;
            hostingError["purchaseCode"] = "Purchase code name can't left empty";
        } else {
            hostingError["purchaseCode"] = "";
        }
        this.setState({ hostingError });
        return formIsValid;
    }

    hostingOrderErrorHandle() {
        let { hostingOrderErrorHandle, orderInfo, purchaseCodeError } = this.state;
        let formIsValid = true;

        if (!orderInfo.productPurchaseCodeDTO.productPurchaseCodeID && !this.state.purchaseCodeHosting) {
            formIsValid = false;
            errors["purchaseCode"] = "Purchase Code can't left empty";
        }

        // if (!orderInfo.domainName) {
        //     formIsValid = false;
        //     errors["urlError"] = "Domain can't left empty";
        // }


        // if (this.props.hosting) {
        //     if (!orderInfo.productPurchaseCodeDTO.productPurchaseCodeID) {
        //         formIsValid = false;
        //         errors["purchaseCodeHosting"] = "Code can't left empty";
        //     }
        // }

        if (!this.state.uploadfile.contentName) {
            formIsValid = false;
            errors["attachment"] = "Attachment can't left empty";
        }




        // if (this.state.uploadfile.fileContent === '') {
        //     formIsValid = false;
        //     hostingOrderErrorHandle["attachFile"] = "File can't left empty";
        // } else {
        //     hostingOrderErrorHandle["url"] = "";
        // }
        this.setState({ hostingOrderErrorHandle });
        return formIsValid;
    }

    focusSearch = e =>{
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
}

        blurSearch = e =>{
            this.setState({ searchLabel: '', addSearchBtnClass: ""  });
        }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, inputFieldIsLoading } = this.state;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid nw-form">
                    <div className='p-col-12 p-xl-12'>
                        <Panel header="Hosting Info" style={{ margin: "0px" }}>
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-col-12 p-xl-12" >
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                            <InputText
                                                value={this.state.purchaseCodeHosting}
                                                onChange={(e) => this.purchaseCodeHostingHandler(e)}
                                                placeholder="Enter Purchase Code"
                                                name="customNetiID"
                                                style={{ width: "15%" }}
                                                type="text"
                                                keyfilter="pint"
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                label={this.state.searchLabel}
                                                icon="fas fa-search"
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                                onClick={(e) => this.searchPurchaseCode(e)}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.purchaseCodeHosting ? '' : errors["purchaseCode"]}</span>
                                    </div>

                                    {this.state.searchView ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-search-view">
                                                {this.state.searchViewError ?
                                                    <center  className="error-message">{this.state.searchErrorMsgBody}</center> :
                                                    <div>

                                                        {!this.state.hostingDetails.purchaseCode && !inputFieldIsLoading ?
                                                            <div className="text-center">
                                                                <div className="devicePassword">
                                                                    <i className="fa fa-info-circle"></i>
                                                                </div>
                                                                <span><b>Purchase hosting info is showing here</b></span>
                                                            </div>
                                                            :
                                                            <div>
                                                                <div className="p-col-12 p-xl-12">
                                                                    {inputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Product Name</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.hostingDetails.productName}
                                                                            </div>

                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {inputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Purchase Code</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.hostingDetails.purchaseCode}
                                                                            </div>

                                                                        </div>
                                                                    }
                                                                </div>


                                                                <div className="p-col-12 p-xl-12">
                                                                    {inputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Use Status</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.hostingDetails.usedStatus === 0 ? "Unused" : "Used"}
                                                                            </div>

                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {inputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Purchase Date</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.hostingDetails.purchaseDate}
                                                                            </div>

                                                                        </div>
                                                                    }
                                                                </div>

                                                            </div>
                                                        }

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }

                                </div>

                            </div>
                        </Panel>
                    </div>









                    <div className="p-col-12 p-xl-12">
                        <div className="card card-w-title">

                            <div className="p-xl-12 p-col-12 nw-upload-button">
                                <FileUpload
                                    mode="basic"
                                    maxFileSize={10000000}
                                    onSelect={this.onLoadFile.bind(this)}
                                    auto={true}
                                    chooseLabel="Choose an Attachment"
                                />
                                <center>
                                    <span>{this.state.uploadfile.contentName}</span>
                                    <span className="error-message">{this.state.uploadfile.contentName ? '' : errors["attachment"]}</span>
                                </center>

                            </div>

                            <div className="p-col-12 p-xl-12"/>


                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <div className="required-field">
                                    (<span>*</span>) required fields
                                </div> 

                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Order"
                                    icon="fas fa-cart-arrow-down"
                                    onClick={(e) => this.hostingOrderHandler(e)}
                                    disabled={this.state.buttonDisabled}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="p-col-12" >
                        {this.state.hostingOrderListReload ? <HostingOrderList hosting={this.props.hosting} /> : ''}
                    </div>


                </div>
            </div>
        );
    }
}