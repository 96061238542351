import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ProfileService } from '../../service/profile/ProfileService'
import { AdminPointService } from '../../service/adminPoint/AdminPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';
let cloneStateBeforeMount;


export class PointAssign extends Component {
    constructor() {
        super();
        this.state = {
            roleIDList: [],
            purchaseCodeSearchView: false,
            purchaseCodeSearchViewError: false,
            purchaseCodeSearchErrorMsgBody: '',
            netiSearchView: false,
            netiSearchViewError: false,
            netiSearchErrorMsgBody: '',
            buttonDisabled: true,
            purchaseCodeSearchBtnDisabled: true,
            netiIDSearchBtnDisabled: true,
            netiIDError: [],
            purchaseCodeError: [],
            pointError: [],
            checked: false,
            purchaseCode: '',
            netiID: '',
            pointList: [],
            purchaseInformation: {
                productName: '',
            },
            netiInformation: {
                netiID: '',
                fullName: '',
            },
            assignPointInfo: {
                pointType: '',
                roleID: '',
                // purchaseCode: '',
                netiID: ''
            },
            checkEligibility: true,
            topProgressBar: false,
            errorMsgVisible: false,
            firstInputFieldIsLoading: false,
            secondInputFieldIsLoading: false,
            errorMsgBody: '',
            firstDropdownIsLoading: false
        };

        this.PartnerPointService = new PartnerPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.ProfileService = new ProfileService();
        this.AdminPointService = new AdminPointService();
        this.ErrorMessageView = new ErrorMessageView();
        this.netiContentLoader = new NetiContentLoader();


    }

    componentWillMount() {
        this.fetchPointList();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    fetchPointList() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true })
        this.PartnerPointService.getAssignablePointsByPartnerPoint()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ roleIDList: body });
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false })
                    });

                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false })
            });
    }

    pointTypeHandler = (event) => {
        let { assignPointInfo } = this.state
        assignPointInfo.roleID = event.target.value;
        assignPointInfo.pointType = event.target.value.coreRoleDefCode;
        assignPointInfo.coreRoleDefCode = event.target.value.coreRoleDefCode;
        this.setState({ assignPointInfo });
    }

    // purchaseCodeHandler = (event) => {
    //     let { assignPointInfo,purchaseCodeError } = this.state
    //     purchaseCodeError["purchaseCode"] = "";
    //     assignPointInfo.purchaseCode = event.target.value;
    //     this.setState({ assignPointInfo, purchaseCodeError });
    // }

    netiIDHandler = (event) => {
        let { netiIDError } = this.state;
        netiIDError["netiIDError"] = "";
        this.setState({ netiID: event.target.value,netiIDError });
    }

    // purchaseCodeCheck = (e) => {
    //     this.setState({ purchaseInformation: cloneStateBeforeMount.purchaseInformation,purchaseCodeSearchErrorMsgBody: '', purchaseCodeSearchView: false });
    //     if (this.purchaseCodeError()) {
    //         this.setState({ topProgressBar: true, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: true, errorMsgVisible: false, purchaseCodeSearchView: true });
    //         this.PartnerPointService.getUserPurchaseCode(this.state.assignPointInfo.purchaseCode)
    //             .then(res => {
    //                 if (res.status === 302) {
    //                     return res.json().then((body) => {
    //                         let { purchaseInformation, assignPointInfo } = this.state;

    //                         if( assignPointInfo.coreRoleDefCode == body.productPurchaseLogDTO.productInfoDTO.productDefaultCode){
    //                             purchaseInformation.productName = body.productPurchaseLogDTO.productInfoDTO.productName;
    //                             purchaseInformation.usedStatus = body.usedStatus;
    //                             purchaseInformation.purchaseDate = NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
    //                             this.setState({ purchaseInformation,topProgressBar: false, purchaseCodeSearchBtnDisabled: false , purchaseCodeSearchViewError: false, firstInputFieldIsLoading: false, errorMsgVisible: false, purchaseCodeSearchView: true});
    //                         }
    //                         else{
    //                             this.setState({
    //                                 firstInputFieldIsLoading: false,
    //                                 purchaseCodeSearchBtnDisabled: true,
    //                                 topProgressBar: false,
    //                                 purchaseCodeSearchErrorMsgBody: "Purchase code is invalid for this segment",
    //                                 purchaseCodeSearchViewError: true,
    //                                 purchaseCodeSearchView: true, 
    //                                 purchaseInformation: {
    //                                     productName: ''
    //                                 }
    //                             });
    //                         }
                            
    //                     })
    //                 } else {
    //                     this.PartnerPointService.Auth.handleErrorStatus(res)
    //                     .then((resp) => {
    //                         this.setState({
    //                             firstInputFieldIsLoading: false,
    //                             purchaseCodeSearchBtnDisabled: true,
    //                             topProgressBar: false,
    //                             purchaseCodeSearchErrorMsgBody: resp,
    //                             purchaseCodeSearchViewError: true,
    //                             purchaseCodeSearchView: true, 
    //                             purchaseInformation: {
    //                                 productName: ''
    //                             }
    //                         });
    //                     })
    //                 }
    //             }).catch(error => {
    //                 this.setState({ topProgressBar: false,  purchaseCodeSearchBtnDisabled: true, firstInputFieldIsLoading: false, errorMsgVisible: true, purchaseCodeSearchView: false, purchaseCodeSearchViewError: false, errorMsgBody: 'Unable to load. Please check your connection.' })

    //             });
    //     }
    // }

    netiIdCheckHandler = (e) => {
        this.setState({ netiInformation: cloneStateBeforeMount.netiInformation,netiSearchViewError: false, netiSearchErrorMsgBody: '',netiSearchView: false });
        if (this.netiIDCodeError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, netiSearchViewError: false, netiSearchView: true,secondInputFieldIsLoading: true  });
            this.ProfileService.findBasicInfoByCustomNetiID(this.state.netiID)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            let { netiInformation } = this.state;
                            netiInformation.netiID = body.netiID;
                            netiInformation.fullName = body.fullName;
                            netiInformation.basicMobile = body.basicMobile;
                            netiInformation.registrationDate = NetiDateUtils.getAnyShortForm(body.registrationDate, 'DD-MMM-YYYY');
                            this.setState({ netiInformation, topProgressBar: false,secondInputFieldIsLoading: false, netiIDSearchBtnDisabled: false, netiSearchViewError: false, netiSearchView: true  });
                        });

                    }
                    else {
                        this.PartnerPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ secondInputFieldIsLoading: false,topProgressBar: false, errorMsgVisible: true,netiIDSearchBtnDisabled: true,netiSearchViewError: true, netiSearchView: true,secondInputFieldIsLoading: false, errorMsgVisible: true, netiSearchErrorMsgBody: resp  });
                        })
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, secondInputFieldIsLoading: false, netiSearchView: false, netiSearchViewError: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })
                });
        }
    }


    pointTypeError() {
        let { purchaseCode, pointError } = this.state;
        let formIsValid = true;
        if (this.state.assignPointInfo.roleID === '') {
            formIsValid = false;
            pointError["pointError"] = "Point Type can't left empty";
        } else {
            pointError["pointError"] = "";
        }
        this.setState({ pointError });
        return formIsValid;
    }
    purchaseCodeError() {
        let { purchaseCode, purchaseCodeError } = this.state;
        let formIsValid = true;
        if (this.state.assignPointInfo.purchaseCode === '') {
            formIsValid = false;
            purchaseCodeError["purchaseCode"] = "Purchase Code can't left empty";
        } else {
            purchaseCodeError["purchaseCode"] = "";
        }
        this.setState({ purchaseCodeError });
        return formIsValid;
    }

    netiIDCodeError() {
        let { purchaseCode, netiIDError, netiID } = this.state;
        let formIsValid = true;
        if (netiID === '') {
            formIsValid = false;
            netiIDError["netiIDError"] = "Neti ID can't left empty";
        }
        if (netiID.length < 10) {
            formIsValid = false;
            netiIDError["netiIDError"] = "Neti ID is too sort";
        } 
        if (netiID == '' && netiID.length < 10) {
            formIsValid = false
            netiIDError['netiIDError'] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10';
        }

        this.setState({ netiIDError });
        return formIsValid;
    }
    pointAssignSubmitHandler = (e) => {
        let { netiInformation, assignPointInfo } = this.state;
        if (this.pointTypeError() && this.netiIDCodeError()) {
            assignPointInfo.netiID = netiInformation.netiID;
            assignPointInfo.roleID=assignPointInfo.roleID.coreRoleID;
            this.PartnerPointService.createPointAssign(this.state.assignPointInfo)
                .then(res => {
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Point assigned successfully' });
                    }
                    else {
                        this.PartnerPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                })
        }
    }


  

    // extend search bar
    focusSearch = e =>{
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
}

    blurSearch = e =>{
        this.setState({ searchLabel: '', addSearchBtnClass: ""  });
    }

    focusSearchNetiId = e =>{
        this.setState({ searchLabelNetiId: "Search", addSearchBtnClassNetiId: "nw-button p-button-primary text-color-white"  });
}

    blurSearchNetiId = e =>{
        this.setState({ searchLabelNetiId: '', addSearchBtnClassNetiId: ""  });
    }

    render() {
        let { topProgressBar,secondInputFieldIsLoading, errorMsgVisible, errorMsgBody, firstDropdownIsLoading, firstInputFieldIsLoading } = this.state;
        let pointType = [];
        if (this.state.roleIDList && this.state.roleIDList.length) {
            pointType = this.state.roleIDList.map(item => ({
                value: item,
                label: item.coreRoleNote
            }));
        }

        return (

            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-form">

                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    
                                    <div className="p-col-12 p-xl-12">
                                        {firstDropdownIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Point Type <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Point Type"
                                                    value={this.state.assignPointInfo.roleID}
                                                    options={pointType}
                                                    autoWidth={false}
                                                    onChange={(e) => this.pointTypeHandler(e)}
                                                    filter={true}
                                                    filterPlaceholder="Select Point Type"
                                                    filterBy="label,value"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{this.state.pointError["pointError"]}</span>
                                    </div>

                                    {/* <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                            <InputText
                                                placeholder="Enter Purchase Code"
                                                value={this.state.assignPointInfo.purchaseCode}
                                                style={{ width: "15%" }}
                                                type="text"
                                                name="purchaseCode"
                                                onChange={(e) => this.purchaseCodeHandler(e)}
                                                keyfilter="num"
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                label={this.state.searchLabel}
                                                icon="fas fa-search"
                                                onClick={(e) => this.purchaseCodeCheck(e)}
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.purchaseCodeError["purchaseCode"]}</span>

                                    </div> */}


                                    <div>

                                    {this.state.purchaseCodeSearchView ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-search-view">
                                                {this.state.purchaseCodeSearchViewError ?
                                                    <center className="error-message">{this.state.purchaseCodeSearchErrorMsgBody}</center> :
                                            <div>


                                        {
                                            this.state.purchaseInformation.productName === '' ? '' :
                                                <div>
                                                    <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Product Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.purchaseInformation.productName}
                                                                    </div>

                                                                </div>
                                                            }
                                                        </div>
                                                
                                                    <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Use Status</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {(this.state.purchaseInformation.usedStatus === 0) ? "Unused" : this.state.purchaseInformation.usedStatus}
                                                                    </div>

                                                                </div>
                                                            }
                                                        </div>
                                            
                                                            <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Purchase Date</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.purchaseInformation.purchaseDate}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                </div>

                                        }


                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Neti ID : <span>*</span></span>
                                                <InputText
                                                    value={this.state.netiID}
                                                    placeholder="Enter Neti ID"
                                                    style={{ width: "15%" }}
                                                    type="text"
                                                    name="netiID"
                                                    onChange={(e) => this.netiIDHandler(e)}
                                                    // keyfilter="num"
                                                    onFocus={this.focusSearchNetiId}
                                                    onBlur={this.blurSearchNetiId}
                                                    maxLength={10}

                                                />
                                                <Button
                                                    className={"p-button-animation " + this.state.addSearchBtnClassNetiId}
                                                    label={this.state.searchLabelNetiId}
                                                    icon="fas fa-search"
                                                    onClick={(e) => this.netiIdCheckHandler(e)}
                                                    onFocus={this.focusSearchNetiId}
                                                    onBlur={this.blurSearchNetiId}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.netiIDError["netiIDError"]}</span>
                                        </div>

                                        {this.state.netiSearchView ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-search-view">
                                                {this.state.netiSearchViewError ?
                                                    <center className="error-message">{this.state.netiSearchErrorMsgBody}</center> :
                                                    <div>


                                        {
                                            this.state.netiInformation.fullName === '' ? '' :
                                                <div>

                                                    <div className="p-col-12 p-xl-12">
                                                            {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.netiInformation.fullName}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                    

                                                    <div className="p-col-12 p-xl-12">
                                                            {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Mobile No.</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.netiInformation.basicMobile}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                        
                                                        <div className="p-col-12 p-xl-12">
                                                            {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Register Date</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.netiInformation.registrationDate}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                 
                                                </div>
                                        }


                                        </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }

                                        <div className="p-col-12"/>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div> 

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Assign"
                                                icon="fas fa-check"
                                                onClick={(e) => this.pointAssignSubmitHandler(e)}
                                                disabled={this.state.netiIDSearchBtnDisabled == false? !this.state.buttonDisabled : this.state.buttonDisabled}

                                                // disabled={this.state.purchaseCodeSearchBtnDisabled == false && this.state.netiIDSearchBtnDisabled == false? !this.state.buttonDisabled : this.state.buttonDisabled}

                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}