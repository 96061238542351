import { MANAGEMENT_POINT, CRM_POINT } from './PointWiseRouteConsts';
import { PARTNER_POINT } from './PointWiseRouteConsts';

export const ManagementPointMenu = {

    items: [
        {
            label: 'Management Point', icon: 'fab fa-bootstrap',

            items: [
                {
                    label: 'Dashboard', icon: '',
                    items: [
                        { label: 'Company Status', icon: '', to: CRM_POINT.CRM_CURRENT_STATUS_DASHBOARD },//pi pi-fw pi-home
                        { label: 'Date Wise Summary', icon: '', to: CRM_POINT.CRM_DASHBOARD_DATE_WISE_STATUS },//pi pi-fw pi-home
                        { label: 'Partner Performance', icon: '', to: MANAGEMENT_POINT.MP_PARTNER_PERFOMANC_BOARD },//pi pi-fw pi-home
                        { label: 'Eduman Info', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_EDUMAN_CURRENT_STATUS },//pi pi-fw pi-user
                        { label: 'Token & Call', icon: '', to: CRM_POINT.CRM_DASHBOARD_TOKEN_AND_CALL },//pi pi-fw pi-home
                        { label: 'Token Summary', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_TOKEN_SUMMARY },//pi pi-fw pi-user
                        { label: 'MBP Type Dashboard', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_PARTNER_DASHBOARD_MBP_TYPE },
                        { label: 'EM Action Log', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_EM_ACTION_LOG_SUMMARY_CHART },
                    ]
                },
                {
                    label: 'Eduman Bill Report', icon: '',
                    items: [
                        { label: 'Accumulate Statement', icon: '', to: MANAGEMENT_POINT.ACCUMULATE_STATEMENT },//pi pi-fw pi-user
                        { label: 'Yearly Statement', icon: '', to: MANAGEMENT_POINT.YEARLY_STATEMENT },
                        { label: 'Monthly Statement', icon: '', to: MANAGEMENT_POINT.MONTHLY_STATEMENT },
                        { label: 'Collective Statement', icon: '', to: MANAGEMENT_POINT.COLLECTIVE_STATEMENT },
                        { label: 'Due Wise Tree', icon: '', to: MANAGEMENT_POINT.DUE_STATEMENT_TREE },
                        { label: 'Month Wise Tree', icon: '', to: MANAGEMENT_POINT.MONTHLY_PAID_UNPAID_INSTITUTE_INFO },

                    ]
                },
                {
                    label: 'Balance Report', icon: '',
                    items: [
                        { label: 'User Wallet', icon: '', to: MANAGEMENT_POINT.USER_WALLET },//pi pi-fw pi-user
                        { label: 'Monthly Transaction', icon: '', to: MANAGEMENT_POINT.MONTHLY_TRANSACTION },
                        { label: 'Daily Transaction', icon: '', to: MANAGEMENT_POINT.DAILY_TRANSACTION },
                        { label: 'Message Transfer', icon: '', to: MANAGEMENT_POINT.BALANCE_REPORT_MESSAGE_TRANSFER },
                        { label: 'Prepaid Deposit', icon: '', to: MANAGEMENT_POINT.PREPAID_DEPOSIT_BALANCE },
                        { label: 'Prepaid Deposit Tree', icon: '', to: MANAGEMENT_POINT.PREPAID_DEPOSIT_TREE_VIEW },
                        { label: 'Mismatch Info', icon: '', to: MANAGEMENT_POINT.BALANCE_MISMATCH_INFO },

                    ]
                },
                {
                    label: 'Partner Report', icon: '',
                    items: [
                        { label: 'Partner Tree', icon: '', to: MANAGEMENT_POINT.PARTNER_TREE },//pi pi-fw pi-user
                        // Remove Partner List menu from menu List
                        // { label: 'Partner List', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_PARTNER_LIST },//pi pi-fw pi-user
                        // Remove Partner Area menu from menu List
                        // { label: 'Partner Area', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_PARTNER_AREA },
                        { label: 'Income Statement', icon: '', to: MANAGEMENT_POINT.PARTNER_INCOME_STATEMENT },
                        { label: 'Income Details', icon: '', to: MANAGEMENT_POINT.PARTNER_INCOME_SUMMARY },
                        { label: 'Income Tree', icon: '', to: MANAGEMENT_POINT.MP_PARTNER_INCOME_TREE_VIEW },
                        // { label: 'Product Purchase Tree', icon: '', to: MANAGEMENT_POINT.MP_PARTNER_PRODUCT_PURCHASE_TREE_VIEW},
                    ]
                },
                {
                    label: 'Revenue Report', icon: '',
                    items: [
                        // { label: 'Purchase Info By Invoice ID', icon: '', to: MANAGEMENT_POINT.PRODUCT_PURCHASE_INFO_BY_INVOICE_ID },//Transfer to Support Find Details > Purchase a Glance
                        { label: 'Product Purchase', icon: '', to: MANAGEMENT_POINT.PRODUCT_PURCHASE_REVENUE },//pi pi-fw pi-user
                        { label: 'Pre Purchase', icon: '', to: MANAGEMENT_POINT.PRODUCT_PRE_PURCHASE },//pi pi-fw pi-user
                    ]
                },
                {
                    label: 'Point Report', icon: '',
                    items: [
                        { label: 'Expiry Info', icon: '', to: MANAGEMENT_POINT.POINT_EXPIRY_INFO },//pi pi-fw pi-user
                    ]
                },
                {
                    label: 'Service Report', icon: '',
                    items: [
                        { label: 'Application Wise Token', icon: '', to: MANAGEMENT_POINT.APPLICATION_WISE_TOKEN },//pi pi-fw pi-user
                        { label: 'Person Wise Token', icon: '', to: MANAGEMENT_POINT.PERSON_WISE_TOKEN },
                        { label: 'Netimail History', icon: '', to: MANAGEMENT_POINT.NETI_CHAT_HISTORY },
                    ]
                },
                {
                    label: 'Eduman Action Log', icon: '',
                    items: [
                        { label: 'Type Wise', icon: '', to: MANAGEMENT_POINT.EM_ACTION_TYPE_WISE_MODULE_LOG },//pi pi-fw pi-user
                        { label: 'Package Wise', icon: '', to: MANAGEMENT_POINT.EM_MODULE_WISE_INSTITUTE_ACTION_LOG },
                        { label: 'Module Wise', icon: '', to: MANAGEMENT_POINT.EM_MODULE_USED_UNUSED_INSTITUTE_ACTION_LOG },
                        { label: 'Institute Wise', icon: '', to: MANAGEMENT_POINT.EM_INSTITUTE_WISE_ACTION_LOG },

                        // { label: 'Netichat History', icon: '', to: MANAGEMENT_POINT.NETI_CHAT_HISTORY },
                    ]
                },
                {
                    label: 'EM Implementation Info', icon: '',
                    items: [
                        { label: 'SMS Module', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_EM_IMPLEMENTATION_SMS },//pi pi-fw pi-user
                        { label: 'Accounts Module', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_EM_IMPLEMENTATION_ACCOUNTS },
                        { label: 'Attendance Taken', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_AREA_WISE_ATTENDANCE_TREE_VIEW },
                        { label: 'NetiCMS Summary', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_DASHBOARD },

                        // { label: 'Netichat History', icon: '', to: MANAGEMENT_POINT.NETI_CHAT_HISTORY },
                    ]
                },
                {
                    label: 'Product Report', icon: '',
                    items: [
                        { label: 'Purchase Details', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_PRODUCT_PURCHASE_DETAILS },
                        { label: 'Sells Tree', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_PRODUCT_SELL_TREE_VIEW },
                        { label: 'Date Range Wise', icon: '', to: MANAGEMENT_POINT.DATE_RANGE_WISE_PURCHASE_REPORT },

                    ]
                },
                {
                    label: 'DFPS Report', icon: '',
                    items: [
                        { label: 'Summary Info', icon: '', to: MANAGEMENT_POINT.ONLINE_PAYMENT_SYSTEM },
                        { label: 'Date Wise', icon: '', to: MANAGEMENT_POINT.DATE_WISE_COLLECTION },
                        { label: 'Institute Wise', icon: '', to: MANAGEMENT_POINT.INSTITUTE_WISE_COLLECTION },
                        { label: 'Transaction ID Wise', icon: '', to: MANAGEMENT_POINT.TRANSACTION_ID_WISE_COLLECTION },
                    ]
                },

                // {
                //     label: 'Others', icon: '' ,
                //     items: [
                //         { label: 'Partner Basic Info', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_PARTNER_BASIC_INFO },//pi pi-fw pi-user
                //         // { label: 'Send SMS Notification', icon: '', to: MANAGEMENT_POINT.SEND_NOTIFICATION_SMS },//Transfer to CRM > Send SMS > To Eduman
                //         { label: 'Area Wise Partner Info', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_AREA_WISE_PARTNER_INFO },
                //         // { label: 'Paid Unpaid Institute', icon: '', to: MANAGEMENT_POINT.MONTHLY_PAID_UNPAID_INSTITUTE_INFO },
                //         // { label: 'Product Sell', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_PRODUCT_SELL_TREE_VIEW },
                //         // { label: 'MBP Type Dashboard', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_PARTNER_DASHBOARD_MBP_TYPE },// added in Management dashboard
                //         // { label: 'EM Implementation SMS', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_EM_IMPLEMENTATION_SMS },//pi pi-fw pi-user
                //         // { label: 'EM Implementation Accounts', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_EM_IMPLEMENTATION_ACCOUNTS },//pi pi-fw pi-user
                //         // { label: 'EM Action Log Summary Chart', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_EM_ACTION_LOG_SUMMARY_CHART },// added in Management dashboard
                //         // { label: 'Token Summary', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_TOKEN_SUMMARY },// added in Management dashboard
                //         { label: 'Eduman Bill Generate', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_EDUMAN_BILL_GENERATE },//pi pi-fw pi-user
                //         // { label: 'Area Wise Attendance', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_AREA_WISE_ATTENDANCE_TREE_VIEW },//pi pi-fw pi-user
                //         // { label: 'Neti CMS Dashboard', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_DASHBOARD },//pi pi-fw pi-user
                //         { label: 'Call Info Dashboard', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_CALL_INFO_DASHBOARD },//pi pi-fw pi-user
                //         { label: 'DFPS Dashboard', icon: '', to: MANAGEMENT_POINT.MANAGEMENT_POINT_DFPS_DASHBOARD },//pi pi-fw pi-user

                //     ]
                // },

            ]
        }
    ]
}