import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { DwsService } from '../../service/dwsPoint/DwsService';
import { Growl } from "primereact/growl";

const ContactListTable = ({contactList, instituteAddress, designationList, setLoadContactList, validateInput, selectedRowsData, setSelectedRowsData}) => {
    const dws = new DwsService();
    const growl = useRef(null);

    const [viewDialogVisible, setViewDialogVisible] = useState({isVisible: false, edit: false, data: {},});
    const [editInfo, setEditInfo] = useState({});
    const [errors, setErrors] = useState({});

    function viewDialog(data, edit = false){
        console.log(data);
        setViewDialogVisible({isVisible: true, edit, data});
        setEditInfo(data);
    }

    function handleInput(e) {
        const newInfo = {...editInfo};
        newInfo[e.target.name] = e.target.value;
        setEditInfo(newInfo);
    }

    function handleUpdate(){
        console.log(editInfo);
        const err = validateInput(editInfo);
        setErrors(err);
        if(Object.keys(err).length === 0) {
            dws.updateContact(editInfo)
            .then(res => res.json())
            .then(result => {
                if(result > 0){
                    growl.current.show({severity: 'success', summary: 'Success Message', detail: "Contact Updated Successfully", life: 3000});
                } else{
                    growl.current.show({severity: 'error', summary: 'Error Message', detail: result.message ? result.message: "Please check your connection.", life: 3000});
                }
                console.log(result);
                setEditInfo({});
                setViewDialogVisible({isVisible: false, edit: false, data: {},});
                setLoadContactList(true);
            })
        }
    }

    function handleDiscard(){
        setEditInfo({});
        setErrors({});
        setViewDialogVisible({isVisible: false, edit: false, data: {},});
    }

    const tableHeader =   (
        <div className="header-title">
            <div className="header-title-left">Institute Contact List</div>
            <div className="header-title-right">
                <a>Total Found: {contactList && contactList.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>
    );

    const requiredSign = (
        <span style={{ color: "red", marginRight: "2px" }}>*</span>
      );

    const bodyActionTemplate = (rowData) => {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Growl ref={growl} />
                <div className="text-center">
                    <Button
                        className="nw-action-button info"
                        icon="fas fa-pen"
                        tooltip="Update Info"
                        onClick={() => viewDialog(rowData, true)}
                    />
                </div>
                <div className="text-center">
                    <Button
                        className="nw-action-button info"
                        icon="fas fa-eye"
                        tooltip="View Details"
                        onClick={() => viewDialog(rowData)}
                    />
                </div>
            </div>
        );
    }
        
    return (
        <div className="nw-data-table">
            <DataTable
            value={contactList}
            header={tableHeader}
            paginator={true}
            responsive={true}
            rows={10}
            selection={selectedRowsData}
            onSelectionChange={e => setSelectedRowsData(e.value) }
            >
                <Column selectionMode="multiple" header="Mark" headerStyle={{ width: '4em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} />
                <Column field="name" header="Name" filter={true} />
                <Column field="designationDTO.categoryName" header="Designation" filter={true} />
                <Column field="mobile" header="Mobile No." filter={true} />
                <Column field="email" header="Email" filter={true} />
                <Column field="action" header="Action" headerStyle={{ textAlign: 'center' }} body={bodyActionTemplate}/>
            </DataTable>

            <Dialog
                className="nw-dialog"
                header='View Contact Info Details'
                visible={viewDialogVisible.isVisible && !viewDialogVisible.edit}
                onHide={handleDiscard}
                closable
                style={{height: 'auto'}}
            >
                <div className="dialog-view">
                    <ul>
                        <li>
                            <span>Institute ID</span>
                            <span>:</span>
                            <span>{viewDialogVisible.data.applicationID}</span>
                        </li>
                        <li>
                            <span>Institute Name</span>
                            <span>:</span>
                            <span>{viewDialogVisible.data.instituteName}</span>
                        </li>
                        <li>
                            <span>Institute Address</span>
                            <span>:</span>
                            <span>{instituteAddress}</span>
                        </li>
                        <li>
                            <span>Name</span>
                            <span>:</span>
                            <span>{viewDialogVisible.data.name}</span>
                        </li>
                        <li>
                            <span>Designation</span>
                            <span>:</span>
                            <span>{viewDialogVisible.data.designationDTO && viewDialogVisible.data.designationDTO.categoryName}</span>
                        </li>
                        <li>
                            <span>Contact No.</span>
                            <span>:</span>
                            <span>{viewDialogVisible.data.mobile}</span>
                        </li>
                        <li>
                            <span>Email</span>
                            <span>:</span>
                            <span>{viewDialogVisible.data.email}</span>
                        </li>
                        <li>
                            <span>Note</span>
                            <span>:</span>
                            <span>{viewDialogVisible.data.note}</span>
                        </li>
                    </ul>
                </div>
            </Dialog>

            <Dialog
                className="nw-dialog"
                header='Update Contact Details Info'
                visible={viewDialogVisible.isVisible && viewDialogVisible.edit}
                onHide={handleDiscard}
                closable
                style={{height: 'auto'}}
            >
                <div className="p-col-12 p-xl-12">
                    {/* for extra space in top */}
                </div>

                <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            institute id
                        </span>
                        <InputText
                            name="instituteId"
                            value={editInfo.applicationID}
                            placeholder="enter name"
                            type="text"
                            readOnly
                        />
                    </div>
                    {/* <span className="error-message">{errors.name}</span> */}
                </div>
                
                <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            institute name
                        </span>
                        <InputText
                            name="instituteName"
                            value={editInfo.instituteName}
                            placeholder="enter name"
                            type="text"
                            readOnly
                        />
                    </div>
                    {/* <span className="error-message">{errors.name}</span> */}
                </div>

                <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            institute address
                        </span>
                        <InputText
                            name="instituteAddress"
                            value={instituteAddress}
                            placeholder="enter name"
                            type="text"
                            readOnly
                        />
                    </div>
                    {/* <span className="error-message">{errors.name}</span> */}
                </div>

                <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            Name {requiredSign}
                        </span>
                        <InputText
                            name="name"
                            value={editInfo.name}
                            placeholder="enter name"
                            onChange={(e) => handleInput(e)}
                            type="text"
                        />
                    </div>
                    <span className="error-message">{errors.name}</span>
                </div>

                <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        Designation {requiredSign}
                    </span>
                    <Dropdown
                        options={designationList}
                        optionLabel="categoryName"
                        value={editInfo.designationDTO}
                        onChange={(e) => handleInput(e)}
                        resetFilterOnHide={false}
                        placeholder="enter designation"
                        style={{ width: "100%" }}
                        name="designationDTO"

                        filterBy="categoryName"
                        filter={true}
                    />
                    </div>
                    <span className="error-message">{errors.designation}</span>
                </div>
                
                <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            mobile no. {requiredSign}
                        </span>
                        <InputText
                            name="mobile"
                            value={editInfo.mobile}
                            placeholder="enter name"
                            onChange={(e) => handleInput(e)}
                            type="text"
                        />
                    </div>
                    <span className="error-message">{errors.mobile}</span>
                </div>
                
                <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            email {requiredSign}
                        </span>
                        <InputText
                            name="email"
                            value={editInfo.email}
                            placeholder="enter name"
                            onChange={(e) => handleInput(e)}
                            type="text"
                        />
                    </div>
                    <span className="error-message">{errors.email}</span>
                </div>
                
                <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                            note {requiredSign}
                        </span>
                        <InputTextarea
                            name="note"
                            placeholder="enter note"
                            onChange={(e) => handleInput(e)}
                            type="text"
                            rows={3}
                            cols={30}
                            autoResize={true}
                            value={editInfo.note}
                        />
                    </div>
                    <span className="error-message">{errors.note}</span>
                </div>

                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                    <Button
                    className="p-button p-component p-button-danger nw-button nw-button-multiple p-button-text-icon-left"
                    label="Discard"
                    icon="fas fa-times"
                    onClick={handleDiscard}
                    />
                    <Button
                    className="p-button p-component p-button-primary nw-button nw-button-multiple p-button-text-icon-left"
                    label="Update"
                    icon="fas fa-check"
                    onClick={handleUpdate}
                    />
                </div>

                <div className="p-col-12 p-xl-12">
                    {/* for extra space in bottom */}
                </div>

            </Dialog>
        </div>
    );
};

export default ContactListTable;