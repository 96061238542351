import { SUPPORT_POINT, CRM_POINT, MANAGEMENT_POINT } from './PointWiseRouteConsts';

export const SupportPointMenu = {

    items: [
        {
            label: 'Support Point', icon: 'fas fa-headset',

            items: [

                {
                    label: 'Manage', icon: '', //pi pi-fw pi-user
                    items: [
                        { label: 'Token', icon: '', to: SUPPORT_POINT.MANAGE_TOKEN }, //pi pi-fw pi-user
                        { label: 'Call Log', icon: '', to: SUPPORT_POINT.CREATE_CALL_LOGS }, //pi pi-fw pi-user
                        { label: 'DFP Bank', icon: '', to: SUPPORT_POINT.MAPPING_BANK_ACCOUNT }, //pi pi-fw pi-user
                        { label: 'Domain Hosting', icon: '', to: CRM_POINT.DOMAIN_AND_HOSTING_REQUEST }, //pi pi-fw pi-user
                        { label: 'Observer Point', icon: '', to: SUPPORT_POINT.OBSERVER_POINT_INFO }, //pi pi-fw pi-user
                        { label: 'Core Contact', icon: '', to: SUPPORT_POINT.INSTITUTE_CONTACT_INFO }, //pi pi-fw pi-user
                    ]
                },

                {
                    label: 'Neti CMS', icon: '', //pi pi-fw pi-user
                    items: [

                        { label: 'Mapping Request List ', icon: '', to: SUPPORT_POINT.NEW_INSTITUTE_URL_MAP },

                        // { label: 'NetiCMS with EM', icon: '', to: CRM_POINT.URL_INSTITUTE_MAP },
                        // { label: 'Menu Config', icon: '', to: SUPPORT_POINT.DWS_MENU_CONFIG }, //pi pi-fw pi-user
                        // { label: 'Global Book', icon: '', to: SUPPORT_POINT.DWS_GLOBAL_BOOK_ADD }, //pi pi-fw pi-arrow-right
                    ]
                },

                {
                    label: 'Bijoy Support', icon: '', //pi pi-fw pi-user
                    items: [
                        { label: 'Device ID', icon: '', to: SUPPORT_POINT.BIJOY_ID_REPLACE }, //pi pi-fw pi-arrow-right
                    ]
                },

                { label: 'Reports' },

                {
                    label: 'Find Details', icon: '', //pi pi-fw pi-dollar
                    items: [
                        { label: 'Eduman At a Glance', icon: '', to: CRM_POINT.EDUMAN_AT_A_GLANCE },
                        { label: 'User At a Glance', icon: '', to: CRM_POINT.PARTNER_AT_A_GLANCE },
                        { label: 'Invoice At a Glance', icon: '', to: MANAGEMENT_POINT.PRODUCT_PURCHASE_INFO_BY_INVOICE_ID }
                    ]
                },

            ]
        }
    ]
}