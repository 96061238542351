import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import NetiContentLoader from '../common/NetiContentLoader';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { EmAdminPointService } from '../../service/emAdminPoint/EmAdminPointService';

let cloneStateBeforeMount;
let totalInstituteDeviceTaggedList = [];
export class EmYssDevice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberStatus: '',
            deviceDetails: {},
            dataTableValue: [],
            yssDeviceTaggedList: [],
            deviceInfoError: {},
            instituteFormError: {},
            deviceInfo: {
                deviceSerial: '',
                deviceIdentification: '',
                userRegistrationDTO: {
                    userID: ''
                },
                activationCode: ''
            },
            yssConfigRequestInfo: {
                purchaseCode: '',
                instituteInfoDTO: {
                    emDetailsId: ''
                }
            },
            payabaleInstituteList: [],
            payableInstituteID: '',
            payableInstituteInfo: {},
            errorMsgBody: null,
            yssDeviceData: [
                { "orderDate": "Netiworld", "orderStatus": '4.0', "urlInfo": "30-06-2019", "desireUrl": '1', "domain": "30-06-2019", "hosting": 'new version' },
                { "orderDate": "Eduman", "orderStatus": '5.1', "urlInfo": "03-05-2019", "desireUrl": '2', "domain": "30-06-2019", "hosting": 'new version' },
                { "orderDate": "Eduman", "orderStatus": '5.1', "urlInfo": "03-05-2019", "desireUrl": '3', "domain": "30-06-2019", "hosting": 'new version' },
            ],
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
        }
        this.partnerPointService = new PartnerPointService();
        this.emAdminPointService = new EmAdminPointService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.viewYssDeviceInfoList();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));

    }

    viewYssDeviceInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.emAdminPointService.getEmAdminYssPartnerPoints(5, 0)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        let yssRegIds = [];
                        body.map((item) => {
                            yssRegIds.push(item.yssRegistrationID)
                        });
                        this.getYSSDevicesByRegIds(yssRegIds);
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    });
                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    getYSSDevicesByRegIds = (value) => {
        this.setState({ topProgressBar: true, errorMsgVisible: false  });
        this.emAdminPointService.getEmAdminYSSDevicesByRegistrationIds(value)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        let devices = body;
                        const { dataTableValue } = this.state;
                        for (let d = 0; d < devices.length; d++) {
                            for (let i = 0; i < dataTableValue.length; i++) {
                                if (devices[d].userRegistrationDTO.registrationID == dataTableValue[i].yssRegistrationID) {
                                    devices[d].instituteId = dataTableValue[i].instituteID;
                                    devices[d].instituteName = dataTableValue[i].instituteName;
                                }
                            }
                        }

                   
                        this.setState({ yssDeviceTaggedList: devices,topProgressBar: false, errorMsgVisible: false});

                    });
                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }



    onChangeInstitute = (event) => {
        let { deviceInfoError, deviceInfo, dataTableValue } = this.state;

        deviceInfoError['institute'] = ''
        this.setState({ deviceInfoError })
        deviceInfo.userRegistrationDTO.userID = event.target.value;
        this.setState({ deviceInfo })

        for (let i = 0; i < dataTableValue.length; i++) {
            if (dataTableValue[i].yssRegistrationID === event.target.value) {
                deviceInfo.instituteId = dataTableValue[i].instituteID;
                this.setState({ deviceInfo });
            }
        }
    }

    onHide = (event) => {
        this.setState({ visible: false });
    }


    viewDialog = () => {
        this.getYSSDeviceIDList();
        this.setState({ visible: true });
    }

    onChangeDevice = (rowData) => {
        return <div>
            <span onClick={this.onChangeViewDevice}>{rowData.desireUrl}</span>
        </div>;

    }

    onHideYssDevice = (event) => {
        this.setState({ viewYssDevice: false });
    }

    onChangeViewDevice = () => {
        this.setState({ viewYssDevice: true });
    }

    onChangePurchaseCode = (e) => {
        let { instituteFormError, yssConfigRequestInfo } = this.state;
        yssConfigRequestInfo.purchaseCode = e.target.value;
        this.setState({ yssConfigRequestInfo });
        instituteFormError["purchaseCode"] = '';
        this.setState({ instituteFormError });
    }

    onChangeSerial = (e) => {
        let { deviceInfo } = this.state;
        deviceInfo.deviceSerial = e.target.value;
        this.setState({ deviceInfo });
    }

    onChangeDeviceID = (e) => {
        let { deviceInfo, deviceIDList, deviceDetails } = this.state;
        deviceInfo.deviceIdentification = e.target.value;

        this.setState({ deviceInfo });
        for (let i = 0; i < deviceIDList.length; i++) {
            if (deviceIDList[i].productUniqueID == e.target.value) {
                deviceDetails = deviceIDList[i];
                this.setState({ deviceDetails });
            }
        }

    }

    onSubmitYssDevice = () => {
        let { deviceInfo } = this.state;
        if (this.addDeviceFormError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.emAdminPointService.addEmAdminYssDevice(deviceInfo)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                        this.setState({
                            deviceInfo: cloneStateBeforeMount.deviceInfo, topProgressBar: false
                        });
                        this.viewYssDeviceInfoList();
                        this.onHide();
                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
        }

    }

    addDeviceFormError = () => {
        let { deviceInfoError, deviceInfo } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (deviceInfo.userRegistrationDTO.userID === '') {
            formIsValid = false;
            deviceInfoError["institute"] = "Institute can't left empty.";
        }

        if (deviceInfo.deviceSerial === '') {
            formIsValid = false;
            deviceInfoError["deviceSerial"] = "Serial can't left empty.";
        }
        if (deviceInfo.deviceIdentification === '') {
            formIsValid = false;
            deviceInfoError["deviceID"] = "Device ID can't left empty.";
        }
        this.setState({ deviceInfoError });
        return formIsValid;
    }

    getYSSDeviceIDList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.emAdminPointService.getYSSDevicesByUser(0)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ deviceIDList: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    });
                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
            });

    }

    render() {
        let { topProgressBar, yssConfigRequestInfo, errorMsgVisible, errorMsgBody, dataTableValue, deviceInfo } = this.state;


        let tableHeader = <div className="header-title">
            <div className="header-title-left">
                Device List
        </div>


            <div className="header-title-right">
                <a>
                    Total Found: {this.state.yssDeviceTaggedList.length}
                </a>

                <Button
                    label="Add Device"
                    icon="fas fa-plus"
                    className="p-button p-button-success nw-button nw-button-right"
                    onClick={this.viewDialog}
                    iconPos="right"
                />
            </div>
        </div>;







      


        let instituteList = [];
        if ((this.state.dataTableValue != null) && (this.state.dataTableValue.length > 0)) {
            instituteList = this.state.dataTableValue.map((item) => ({
                value: item.yssRegistrationID,
                label: item.instituteName + '-' + item.instituteID,
            }));
        }




        const deviceSerialList = [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
            { label: '6', value: '6' },
            { label: '7', value: '7' },
            { label: '8', value: '8' },
            { label: '9', value: '9' },
            { label: '10', value: '10' },
            { label: '11', value: '11' },
            { label: '12', value: '12' },
        ];

        let deviceID = [];
        if ((this.state.deviceIDList != null) && (this.state.deviceIDList.length > 0)) {
            deviceID = this.state.deviceIDList.map((item) => ({
                value: item.productUniqueID,
                label: item.productUniqueID,
            }));
        }

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                <div className="p-grid">

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    value={this.state.yssDeviceTaggedList}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    columnResizeMode="fit"
                                >
                                    <Column field="deviceSerial" header="Serial" filter={true} />
                                    <Column field="regDate" header="Add Date" filter={true} />
                                    <Column field="deviceIdentification" header="Device ID" filter={true} />
                                    <Column field="instituteId" header="Insitute ID" filter={true} />
                                    <Column field="instituteName" header="Institute Name" filter={true} />
                                </DataTable>
                            }
                        </div>


                    </div>

                    {/* it will implement later */}

                    {/* <Dialog className="nw-dialog" header="YSS Config" visible={this.state.viewYssDevice} onHide={this.onHideYssDevice} closable>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <DataTable
                                        value={this.state.yssDeviceData}
                                        selectionMode="single"
                                        header={yssDeviceHeader}
                                        selection={this.state.dataTableSelection}
                                        onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                        responsive={true}
                                        rows={10}
                                        columnResizeMode="fit"
                                    >
                                        <Column field="orderDate" header="Serial" />
                                        <Column field="orderStatus" header="Add Date" />
                                        <Column field="urlInfo" header="Device ID" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </Dialog> */}



                </div>
                <div className="dialog-section">

                    <Dialog

                        className="nw-dialog"
                        header="Add Device"
                        visible={this.state.visible}
                        onHide={this.onHide}
                        maximizable
                        closable>
                        <div className="nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Institute <span>*</span></span>
                                            <Dropdown
                                                value={deviceInfo.userRegistrationDTO.userID}
                                                options={instituteList}
                                                onChange={this.onChangeInstitute}
                                                placeholder="Select Institute"
                                                showClear={true}
                                                autoWidth={false}
                                                name="institute"
                                                filter={true}
                                                filterBy="value"

                                       
                                            />
                                        </div>
                                        <span className="error-message">{this.state.deviceInfoError["institute"]}</span>
                                    </div>



                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Serial <span>*</span></span>
                                            <Dropdown
                                                value={this.state.deviceInfo.deviceSerial}
                                                options={deviceSerialList}
                                                onChange={this.onChangeSerial}
                                                placeholder="Select Serial"
                                                filter={true}
                                                filterBy="value"
                                                showClear={true}
                                                autoWidth={false}
                                                name="deviceSerial"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.deviceInfoError["deviceSerial"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Device ID <span>*</span></span>
                                            <Dropdown
                                                value={this.state.deviceInfo.deviceIdentification}
                                                options={deviceID}
                                                onChange={this.onChangeDeviceID}
                                                placeholder="Select Device ID"
                                                filter={true}
                                                filterBy="value"
                                                showClear={true}
                                                autoWidth={false}
                                                name="deviceID"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.deviceInfoError["deviceID"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            label="Link Up"
                                            icon="fas fa-check"
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            onClick={this.onSubmitYssDevice}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}