import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';

import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import Formatter from '../../../utils/Formatter';
import { pdfDownloadPortrait_FullTable } from '../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';

let depositSearchInfo = {};

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class MonthlyTransaction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            totalDepositAmount: 0,
            totalWithdrawAmount: 0,
            totalTransferAmount: 0,
            totalPurchaseAmount: 0,
            totalRevenueAmount: 0,
            error: {},

            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]

        }
        this.managementPointService = new ManagementPointService();
        this.ValidatorUtility = new ValidatorUtility();
        this.Formatter = new Formatter()
        this.NetiContentLoader = new NetiContentLoader();
        depositSearchInfo = JSON.parse(localStorage.getItem("depositSearchInfo"));

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    componentDidMount() {

        if (depositSearchInfo) {
            this.setState({
                yearValue: depositSearchInfo.year,
                error: {}
            })

            this.onSubmitGetMonthlyBalance(depositSearchInfo.year);
        }

    }

    depositAmountBody = (rowData) => {
        return (
            <div className="col-number text-right" >
                <Link to={{
                    pathname: MANAGEMENT_POINT.MONTHLY_DEPOSIT_AMOUNT_LIST,
                    searchObj: {
                        month: rowData.month,
                        year: this.state.yearValue,
                        urlType: 'deposit',
                        type: 'deposit'
                    }
                }}>
                    <u>{this.ValidatorUtility.currencyFormatter(rowData.depositAmount)}</u>
                </Link>
            </div>

        )
    }

    withdrawAmountBody = (rowData) => {
        return (
            <div className="col-number text-right" >
                <Link to={{
                    pathname: MANAGEMENT_POINT.MONTHLY_WITHDRAW_AMOUNT_LIST,
                    searchObj: {
                        month: rowData.month,
                        year: this.state.yearValue,
                        urlType: 'withdraw',
                        type: 'withdraw'

                    }
                }}>
                    <u>{this.ValidatorUtility.currencyFormatter(rowData.withdrawAmount)}</u>
                </Link>
            </div>

        )
    }

    transferAmountBody = (rowData) => {
        return (
            <div className="col-number text-right" >
                <Link to={{
                    pathname: MANAGEMENT_POINT.MONTHLY_TRANSFER_AMOUNT_LIST,
                    searchObj: {
                        month: rowData.month,
                        year: this.state.yearValue,
                        urlType: 'transfer',
                        type: 'send'
                    }
                }}>
                    <u>{this.ValidatorUtility.currencyFormatter(rowData.transferAmount)}</u>
                </Link>
            </div>

        )
    }

    purchaseAmountBody = (rowData) => {
        return (
            <div className="col-number text-right" >
                <Link to={{
                    pathname: MANAGEMENT_POINT.MONTHLY_PURCHASE_AMOUNT_LIST,
                    searchObj: {
                        month: rowData.month,
                        year: this.state.yearValue,
                        urlType: 'purchase',
                        type: 'purchase'
                    }

                }}>
                    <u>{this.ValidatorUtility.currencyFormatter(rowData.purchaseAmount)}</u>
                </Link>
            </div>

        )
    }

    disbursmentAmountBody = (rowData) => {
        return (
            <div className="col-number text-right" >
                {/* --- */}
                {/* <Link to={{ pathname: MANAGEMENT_POINT.MONTHLY_DISBURSEMENT_AMOUNT_LIST }}> */}
                {/* <u> */}
                {this.ValidatorUtility.currencyFormatter(rowData.revenueAmount)}
                {/* </u> */}
                {/* </Link> */}
            </div>

        )
    }

    onChangeYearValue = e => {
        let { error } = this.state
        error['year'] = ''
        this.setState({ yearValue: e.target.value, error })
    }

    onSubmitGetMonthlyBalance = (year) => {

        this.setState({
            totalDepositAmount: 0, totalWithdrawAmount: 0, totalTransferAmount: 0, totalPurchaseAmount: 0,
        })
        if (this.onSearchErrorHandle()) {
            this.setState({ dataTableValue: {}, topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: true });
            this.managementPointService.fetchMonthlyBalanceTransactionList(year)
                .then(res => {
                    if (res.status == 200) {
                        return res.json().then((body) => {
                            if (body.length == 0) {
                                return this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
                            }
                            else {
                                body.map(item => {
                                    item.depositAmount = item.depositAmount.toFixed(2)
                                    item.withdrawAmount = item.withdrawAmount.toFixed(2)
                                    item.transferAmount = item.transferAmount.toFixed(2)
                                    item.purchaseAmount = item.purchaseAmount.toFixed(2)
                                    item.revenueAmount = item.revenueAmount.toFixed(2)
                                    //item.disbursmentAmount = item.disbursmentAmount.toFixed(2)
                                })
                                this.setState({ dataTableIsLoading: false, dataTableValue: body, topProgressBar: false, errorMsgVisible: false });

                                this.setState({
                                    totalDepositAmount: this.ValidatorUtility.currencyFormatter(this.calculateTotalAmount(body, "depositAmount")),
                                    totalWithdrawAmount: this.ValidatorUtility.currencyFormatter(this.calculateTotalAmount(body, "withdrawAmount")),
                                    totalTransferAmount: this.ValidatorUtility.currencyFormatter(this.calculateTotalAmount(body, "transferAmount")),
                                    totalPurchaseAmount: this.ValidatorUtility.currencyFormatter(this.calculateTotalAmount(body, "purchaseAmount")),
                                    totalRevenueAmount: this.ValidatorUtility.currencyFormatter(this.calculateTotalAmount(body, "revenueAmount"))
                                })
                            }
                        })
                    } else if (res.status == 404) {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
                    }
                    else {
                        this.managementPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                            });
                    }
                }).catch((error) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: error + ' Please check your connection!!!' });
                    console.log(error)
                });
        }
    }

    calculateTotalAmount(body, amountCategory) {
        return body.map(item => +item[amountCategory]).reduce((a, b) => { return (a + +b) }, 0);
    }

    onSearchErrorHandle = () => {

        if (!depositSearchInfo) {
            let formIsValid = true;
            let { error } = this.state;
            if (!this.state.yearValue) {
                formIsValid = false;
                error['year'] = "Year can't left empty"
            }

            this.setState({ error });
            return formIsValid
        }
        else {
            return true
        }

    }

    exportPdf() {

        let pdfColumns = [
            { title: "Month", dataKey: "month" },
            { title: "Deposit Amount", dataKey: "depositAmount" },
            { title: "Withdraw Amount", dataKey: "withdrawAmount" },
            { title: "Transfer Amount", dataKey: "transferAmount" },
            { title: "Purchase Amount", dataKey: "purchaseAmount" },
            { title: "Disbursement Amount", dataKey: "revenueAmount" },
        ]

        let tableColStyles = {
            month: { halign: "right", cellWidth: 17, },
            depositAmount: { halign: "right", cellWidth: 31.5, },
            withdrawAmount: { halign: "right", cellWidth: 35, },
            transferAmount: { halign: "right", cellWidth: 34.5, },
            purchaseAmount: { halign: "right", cellWidth: 33.5, },
            revenueAmount: { halign: "right", cellWidth: 29, },
        }

        var totalArrayList = [
            {
                id: "1",
                totalPdf: "Total",
                totalAmount1: this.state.totalDepositAmount,
                totalAmount2: this.state.totalWithdrawAmount,
                totalAmount3: this.state.totalTransferAmount,
                totalAmount4: this.state.totalPurchaseAmount,
                totalAmount5: this.state.totalRevenueAmount,
            }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }, { dataKey: "totalAmount1" }, { dataKey: "totalAmount2" }, { dataKey: "totalAmount3" }, { dataKey: "totalAmount4" }, { dataKey: "totalAmount5" },];

        let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, cellWidth: 17, fontStyle: "bold", overflow: "linebreak" },
            totalAmount1: { halign: "right", fontStyle: "bold", fontSize: 10, cellWidth: 31.5 },
            totalAmount2: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 35 },
            totalAmount3: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 34.5 },
            totalAmount4: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 33.5 },
            totalAmount5: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 29 }
        }

        pdfDownloadPortrait_FullTable("Balance Transaction (Month Wise)", 70, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableColStyles, totalColumns, totalArrayList, totalColumnStyles, "Monthly_Balance_Transaction_Report.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody } = this.state;

        // pdf reletaed task
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
        ];
        subTitleList = [
            { A: "Year", B: this.state.yearValue }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = "Balance Transaction of " + this.state.yearValue + ".xlsx";
        let excelSheetName = "balance_transaction_" + this.state.yearValue;
        excelColumns = [
            <Workbook.Column value="month" label="Month" />,
            <Workbook.Column value="depositAmount" label="Discount Amount" />,
            <Workbook.Column value="withdrawAmount" label="Withdraw Amount" />,
            <Workbook.Column value="transferAmount" label="Transfe Amount" />,
            <Workbook.Column value="purchaseAmount" label="Purchase Amount" />,
            <Workbook.Column value="revenueAmount" label="Disbursement Amount" />,
        ];
        // end excel

        let tableHeader = '';
        console.log('data', dataTableValue);

        if (dataTableValue && dataTableValue.length) {

            tableHeader = <div className="header-title">
                <div className="header-title-left">Balance Transaction (Month Wise)</div><div className="header-title-right"><a>Total Found: {this.state.dataTableValue.length.toLocaleString('EN-IN')}</a></div>
            </div>;

        }

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" className="nw-tfoot-total-text" />
                <Column footer={this.state.totalDepositAmount} />
                <Column footer={this.state.totalWithdrawAmount} />
                <Column footer={this.state.totalTransferAmount} />
                <Column footer={this.state.totalPurchaseAmount} />
                <Column footer={this.state.totalRevenueAmount} />
            </Row>
        </ColumnGroup>;

        let yearSelection = [];

        let currentFullDate = new Date();
        let currentYear = currentFullDate.getFullYear('yyyy');
        ///year generator
        for (let index = 2013; index <= currentYear; index++) {
            yearSelection.unshift({ label: `${index}`, value: `${index}` });
        }

        return (
            <div className="p-fluid">
                <div className='main-section'>

                    <div className="p-grid">
                        {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }

                        <div className="p-col-12 p-lg-12 p-xl-12">
                            <div className="nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form-body">
                                        <div className="p-col-12 p-xl-9 p-lg-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Year <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Year"
                                                    value={this.state.yearValue}
                                                    options={yearSelection}
                                                    autoWidth={false}
                                                    onChange={(e) => this.onChangeYearValue(e)}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.error["year"]}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-3 p-lg-12 nw-button-parent">
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-center"
                                                label="Search"
                                                icon="fas fa-search"
                                                onClick={e => this.onSubmitGetMonthlyBalance(this.state.yearValue)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-12 p-xl-12">
                                    <div className="nw-data-table">
                                        {
                                            this.state.dataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader()
                                                : <div>
                                                    <DataTable
                                                        header={tableHeader}
                                                        footerColumnGroup={footer}
                                                        responsive={true}
                                                        selectionMode="single"
                                                        paginator={true}
                                                        rows={10}
                                                        rowsPerPageOptions={[10, 20, 50]}
                                                        value={this.state.dataTableValue}
                                                    //selection={this.state.dataTableSelection}
                                                    >
                                                        <Column field="month" header="Month" filter={true} />
                                                        <Column className="col-number" field="depositAmount" header="Deposit Amount" body={this.depositAmountBody} filter={true} />
                                                        <Column className="col-number" field="withdrawAmount" header="Withdraw Amount" body={this.withdrawAmountBody} filter={true} />
                                                        <Column className="col-number" field="transferAmount" header="Transfer Amount" body={this.transferAmountBody} filter={true} />
                                                        <Column className="col-number" field="purchaseAmount" header="Purchase Amount" body={this.purchaseAmountBody} filter={true} />
                                                        <Column className="col-number" field="revenueAmount" header="Disbursement Amount" body={this.disbursmentAmountBody} filter={true} />

                                                    </DataTable>
                                                </div>
                                        }

                                        {/* :
                                            
                                            <BlankDataTableAnim/> 
                                    } */}
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                    <div style={{ display: 'none' }}>

                                        <Workbook
                                            filename={excelFileName}
                                            element={
                                                <Button
                                                    label="Download Excel"
                                                    id="clickExcelBtn"
                                                    icon="fas fa-download"
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                />
                                            }
                                        >
                                            <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                                {excelColumns}
                                            </Workbook.Sheet>
                                        </Workbook>

                                    </div>

                                </div >

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}