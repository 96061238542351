import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { Button } from 'primereact/button';

let errors = {};

export class MPAreaWisePartnerInfo extends Component {

    constructor(props) {

        super(props);
        this.state = {

            errorMsgVisible: false,
            errorMsgBody: '',
            errors: {},

            selectedArea: '',
            partnerDashBoardInfo: [],
            dataTableValue: [],

        }

        this.ManagementPointService = new ManagementPointService();
        this.netiContentLoader = new NetiContentLoader();

        this.fetchAreaWise_PartnerDashboardInfo = this.fetchAreaWise_PartnerDashboardInfo.bind(this);

    }

    componentWillMount() { this.fetchAreaWise_PartnerDashboardInfo() }

    fetchAreaWise_PartnerDashboardInfo() {

        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ManagementPointService.fetchAreaWise_Partner_DashboardInfo()
            .then((res) => {

                if (res.status == 302) {

                    return res.json().then((body) => {
                        // console.log('dashboardInfo body', body);

                        this.setState({ partnerDashBoardInfo: body[0] });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });

                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }

            }).catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    fetchAreaWise_UnallocatedPartner = (selectedArea) => {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.ManagementPointService.fetchAreaWise_UnallocatedPartnerList(selectedArea)
            .then((res) => {

                if (res.status == 302) {

                    return res.json().then((body) => {
                        // console.log('UnallocatedPartner body', body);

                        this.setState({ dataTableValue: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });

                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }

            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    onChangeArea = (e) => {

        errors["selectedArea"] = "";
        this.setState({ selectedArea: e.value, errors });

    }

    onSubmitSearch() {

        if (this.onSubmitSearchError()) {
            this.fetchAreaWise_UnallocatedPartner(this.state.selectedArea);
        }

    }

    onSubmitSearchError = () => {

        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.selectedArea === '') {
            formIsValid = false;
            errors["selectedArea"] = "Area can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    render() {

        let { partnerDashBoardInfo, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Unallocated Partner Area</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let progressMainClass1 = "info-box-wrapper progress-view step-1 "
        let progressMainClass2 = "info-box-wrapper progress-view step-2 "
        let progressMainClass3 = "info-box-wrapper progress-view step-3 "
        let progressMainClass4 = "info-box-wrapper progress-view step-4 "
        let progressMainClass5 = "info-box-wrapper progress-view step-5 ";

        let areaOptions = [
            { label: 'Region', value: 13 },
            { label: 'Zone', value: 14 },
            { label: 'District', value: 16 },
            { label: 'Area', value: 18 },
        ]

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />

                <div className="accrodion-section">

                    <Accordion activeIndex={this.state.activeIndex || 0} onTabChange={(e) => this.setState({ activeIndex: e.index })} >
                        <AccordionTab header="Area Wise Partner Info">
                            <div className="main-section">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">

                                        <div className="tracking-progress-view">
                                            <div className={progressMainClass1}>
                                                {/* <div className="info-icon icon-bg-1">
                                                    <i class="fas fa-sign-in-alt"></i> 
                                                </div> */}
                                                <div className="info-text">
                                                    <p>Bangladesh</p>
                                                </div>
                                                <div className="info-text">
                                                    <p></p>
                                                </div>
                                                <div className="info-button info-btn-1">
                                                    <i class="fas fa-info-circle"></i> Country
                                                </div>
                                            </div>

                                            <div className="next"><div></div></div>

                                            <div className={progressMainClass2}>
                                                {/* <div className="info-icon icon-bg-1">
                                                    <i class="fas fa-spinner"></i>
                                                </div> */}
                                                <div className="info-text">
                                                    <u><h2>{partnerDashBoardInfo.totalMbp}</h2></u>
                                                </div>
                                                <div className="info-text">
                                                    <p>Total MBP</p>
                                                </div>
                                                <div className="info-button info-btn-1">
                                                    <i class="fas fa-info-circle"></i> Region: {partnerDashBoardInfo.totalRegion}
                                                </div>
                                            </div>

                                            <div className="next"><div></div></div>

                                            <div className={progressMainClass3}>
                                                <div className="info-text">
                                                    <u><h2>{partnerDashBoardInfo.totalZbp}</h2></u>
                                                </div>
                                                <div className="info-text">
                                                    <p>Total ZBP</p>
                                                </div>
                                                <div className="info-button info-btn-1">
                                                    <i class="fas fa-info-circle"></i> Zone: {partnerDashBoardInfo.totalZone}
                                                </div>
                                            </div>

                                            <div className="next"><div></div></div>

                                            <div className={progressMainClass4}>
                                                <div className="info-text">
                                                    <u><h2>{partnerDashBoardInfo.totalBdp}</h2></u>
                                                </div>
                                                <div className="info-text">
                                                    <p>Total BDP</p>
                                                </div>
                                                <div className="info-button info-btn-1">
                                                    <i class="fas fa-info-circle"></i> District: {partnerDashBoardInfo.totalDistrict}
                                                </div>
                                            </div>

                                            <div className="next"><div></div></div>

                                            <div className={progressMainClass5}>
                                                <div className="info-text">
                                                    <u><h2>{partnerDashBoardInfo.totalBep}</h2></u>
                                                </div>
                                                <div className="info-text">
                                                    <p>Total BEP</p>
                                                </div>
                                                <div className="info-button info-btn-1">
                                                    <i class="fas fa-info-circle"></i> Area: {partnerDashBoardInfo.totalArea}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>

                    <br />

                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-9 p-lg-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Area <span>*</span></span>
                                        <Dropdown placeholder="Select Area" options={areaOptions} value={this.state.selectedArea} onChange={this.onChangeArea} autoWidth={false} />
                                    </div>
                                    <span className="error-message">{this.state.errors["selectedArea"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-12 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitSearch.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-12 p-xl-12 p-col-nogutter nw-data-table" style={{ margin: '0' }}>
                        {
                            // this.state.tableView === true ?
                            this.state.dataTableIsLoading ?
                                this.netiContentLoader.MyPointTableLoader()
                                : <div>

                                    <DataTable
                                        header={tableHeader}
                                        value={dataTableValue}
                                        responsive={true}
                                        selectionMode="single"
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="area" header="Area" filter={true} />
                                        <Column field="parenArea" header="Area Name" filter={true} />
                                        <Column field="status" header="Partner Type" filter={true} />
                                    </DataTable>

                                </div>

                            // :
                            // <BlankDataTableAnim />
                        }
                    </div>
                </div>
            </div>
        );
    }
}