import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

let maxDate = new Date();
let dialogHeader = '';
let dialogTableHeader = '';
let dialogTableValue = [];
let dialogTableColumns = [];
let backBtnVisible = false;
export class DFPSDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {

            errorMsgVisible: false,
            errorMsgBody: '',
            errors: {},
            dataTableIsLoading: false,

            dpfsInstituteInfo: [],
            dpfsConnectedInstituteList: [],
            dpfsTrnInstitute: [],
            searchObj: {
                startDate: '',
                endDate: ''
            },
            feesCollectionInfo: [],
            visible: false,

        }

        this.ManagementPointService = new ManagementPointService();
        this.netiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
        this.CommonFuctionality = new CommonFuctionality();

        this.fetchDfpsInstituteInfo = this.fetchDfpsInstituteInfo.bind(this);

    }

    componentWillMount() { this.fetchDfpsInstituteInfo() }

    fetchDfpsInstituteInfo() {

        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ManagementPointService.fetchCountDfpsInstituteInfo()
            .then((res) => {

                if (res.status == 302) {

                    return res.json().then((body) => {
                        // console.log('dpfsInstituteInfo body', body);

                        this.setState({ dpfsInstituteInfo: body.item });
                        this.setState({ topProgressBar: false, errorMsgVisible: false });

                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }

            }).catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    fetchDfpsConnectedInstitute = () => {

        this.makeDialogVisible();
        dialogHeader = "DFPS Connected Institute";

        this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: true, });
        this.ManagementPointService.fetchDfpsConnectedInstituteList()
            .then((res) => {

                if (res.status == 302) {

                    return res.json().then((body) => {
                        // console.log('Connected body', body);

                        // dialog content set
                        dialogTableHeader = <div className="header-title">
                            <div className="header-title-left">DFPS Connected Institute List</div>
                            <div className="header-title-right"><a>Total Found: {body.item.length.toLocaleString('EN-IN')}</a></div>
                        </div>;

                        Array.prototype.push.apply(dialogTableValue, body.item);
                        dialogTableColumns.push(
                            <Column field="instituteId" header="Institute ID" />,
                            <Column field="instituteName" header="Institute Name" />,
                            <Column field="instituteAddress" header="Address" />,
                            <Column field="area" header="Area" />,
                            <Column field="dpsStatus" header="DFPS Status" />
                        );
                        // dialog content set end

                        this.setState({ topProgressBar: false, dataTableIsLoading: false });

                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody, dataTableIsLoading: false, }) });
                }

            }).catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection', dataTableIsLoading: false, })

            );

    }

    fetchDfpsTransactionalInstitute = () => {

        if (!this.state.dpfsInstituteInfo.todaysTransactionalInstitutes == 0) {

            this.makeDialogVisible();
            dialogHeader = "Today's Transactional Institute";

            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: true, });
            this.ManagementPointService.fetchDfpsTransactionalInstituteList()
                .then((res) => {

                    if (res.status == 302) {

                        return res.json().then((body) => {
                            // console.log('Transactional body', body);

                            // dialog content set
                            dialogTableHeader = <div className="header-title">
                                <div className="header-title-left">Today's Transactional Institute List</div>
                                <div className="header-title-right"><a>Total Found: {body.item.length.toLocaleString('EN-IN')}</a></div>
                            </div>;

                            Array.prototype.push.apply(dialogTableValue, body.item);
                            dialogTableColumns.push(
                                <Column field="instituteId" header="Institute ID" />,
                                <Column field="instituteName" header="Institute Name" />,
                                <Column field="instituteAddress" header="Address" />,
                                <Column field="area" header="Area" />,
                                <Column field="transactionalAmount" header="Collection Amount" />
                            );
                            // dialog content set end

                            this.setState({ topProgressBar: false, dataTableIsLoading: false });

                        });

                    } else {
                        this.ManagementPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => { this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody, dataTableIsLoading: false, }) });
                    }

                }).catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection', dataTableIsLoading: false, })

                );
        }

    }

    fetchDfpsFeesCollectionInfoByDateRange = (searchObj) => {

        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ManagementPointService.fetchDfpsFeesCollectionInfo(searchObj)
            .then((res) => {

                if (res.status == 302) {

                    return res.json().then((body) => {

                        // console.log('FeesCollection DateR body', body);

                        this.setState({ feesCollectionInfo: body.item });
                        this.setState({ topProgressBar: false });

                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }

            }).catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );
    }

    onChangeFromDate = (e) => {
        let { searchObj, errors } = this.state;
        errors["fromDate"] = "";
        searchObj.startDate = e.target.value
        this.setState({ searchObj, errors });
    }

    onChangeToDate = (e) => {
        let { searchObj, errors } = this.state;
        errors["toDate"] = "";
        searchObj.endDate = e.target.value
        this.setState({ searchObj, errors });
    }

    onSubmitSearch = () => {

        let searchObj = { ...this.state.searchObj }

        searchObj.startDate = NetiDateUtils.getDateFromStringDDMMYYYY(searchObj.startDate)
        searchObj.endDate = NetiDateUtils.getDateFromStringDDMMYYYY(searchObj.endDate)
        if (this.onSubmiSearchError()) { this.fetchDfpsFeesCollectionInfoByDateRange(searchObj) }

    }

    onSubmiSearchError = () => {
        let { errors, searchObj } = this.state;
        let formIsValid = true;

        if (searchObj.startDate === '') {
            formIsValid = false;
            errors["fromDate"] = "Start Date can't left empty.";
        }
        if (searchObj.endDate === '') {
            formIsValid = false;
            errors["toDate"] = "End Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    makeDialogVisible = () => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visible: true });
    }

    makeDialogHide = () => {
        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visible: false });
        this.resetDialogAndTableData();
    }

    resetDialogAndTableData = () => {
        dialogHeader = ''
        dialogTableHeader = '';
        dialogTableValue = []
        dialogTableColumns = [];
    }

    render() {

        let { dpfsInstituteInfo, feesCollectionInfo, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let dialogContent = <div className="p-col-12 p-xl-12 m-b-20 nw-data-table nw-data-table-tabview">
            <DataTable
                value={dialogTableValue}
                header={dialogTableHeader}
                responsive={true}
                paginator={true}
                rows={10}
            >
                {dialogTableColumns}
            </DataTable>
        </div>

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />

                <div className="accrodion-section">

                    <Accordion activeIndex={this.state.activeIndex || 0} onTabChange={(e) => this.setState({ activeIndex: e.index })} >
                        <AccordionTab header="DFPS Report Dashboard">

                            <Panel header="DFPS Institute Info" className="bg-primary">

                                <div className="main-section">
                                    <div className="p-grid">

                                        <div className="p-col-12">
                                            <div className="p-col-12 p-xl-12 ">
                                                <div className="nw-circle-panel">

                                                    <div className="circle-panel circle-panel-first bg-primary">
                                                        <br />
                                                        {dpfsInstituteInfo.totalInstitutes}
                                                        <div className="circle-panel-label">Total Institute</div>
                                                    </div>

                                                    <div className="circle-panel circle-panel-second bg-success">
                                                        <br />
                                                        <span style={{ color: "#ffffff", cursor: "pointer", textDecoration: "underline" }} onClick={this.fetchDfpsConnectedInstitute}>{dpfsInstituteInfo.totalDpsConnectedInstitutes}</span>
                                                        <div className="circle-panel-label">DFPS Connected Institute</div>
                                                    </div>

                                                    <div className="circle-panel circle-panel-third bg-danger">
                                                        <br />
                                                        <span style={{ color: "#ffffff", cursor: "pointer", textDecoration: "underline" }} onClick={this.fetchDfpsTransactionalInstitute}>{dpfsInstituteInfo.todaysTransactionalInstitutes}</span>
                                                        <div className="circle-panel-label">Today's Transactional Institute</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                            <br />

                            <Panel header="Fees Collection Info From DFPS">
                                <br />
                                <div className="p-grid nw-form">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-grid nw-form-body">
                                            <div className="p-col-12 p-xl-5 p-lg-6">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                                    <Calendar
                                                        maxDate={maxDate}
                                                        value={this.state.searchObj.startDate}
                                                        onChange={this.onChangeFromDate}
                                                        showIcon={true}
                                                        dateFormat="dd/mm/yy"
                                                        name="fromDate"
                                                        placeholder="Select Date"
                                                        autoWidth={false}
                                                        yearRange="2010:2030"
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        dateOnly="true"
                                                        showButtonBar={true}
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.errors["fromDate"]}</span>
                                            </div>
                                            <div className="p-col-12 p-xl-5 p-lg-6">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                                    <Calendar
                                                        maxDate={maxDate}
                                                        value={this.state.searchObj.endDate}
                                                        onChange={this.onChangeToDate}
                                                        showIcon={true}
                                                        dateFormat="dd/mm/yy"
                                                        name="toDate"
                                                        placeholder="Select Date"
                                                        autoWidth={false}
                                                        yearRange="2010:2030"
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        dateOnly="true"
                                                        showButtonBar={true}
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.errors["toDate"]}</span>
                                            </div>
                                            <div className="p-col-12 p-xl-2 p-lg-12 nw-button-parent">
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-center"
                                                    label="Search"
                                                    icon="fas fa-search"
                                                    onClick={this.onSubmitSearch}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-6 ">
                                        <Panel header="Total Fees Collection" className="bg-success">
                                            <center>
                                                <div>
                                                    <h2><u>{feesCollectionInfo.allInstituteAmount ? this.ValidatorUtility.currencyFormatter(feesCollectionInfo.allInstituteAmount) : 0}</u></h2>
                                                </div>
                                                <h3>All Institute Amount</h3>
                                            </center>
                                        </Panel>
                                    </div>

                                    <div className="p-col-12 p-xl-6 ">
                                        <Panel header="Total Transaction ID" className="bg-danger">
                                            <center>
                                                <div>
                                                    <h2><u>{feesCollectionInfo.totalTransactionId ? feesCollectionInfo.totalTransactionId.toLocaleString('EN-IN') : 0}</u></h2>
                                                </div>
                                                <h3>All Institute Trx ID</h3>
                                            </center>
                                        </Panel>
                                    </div>

                                    <div className="p-col-12 p-xl-6 ">
                                        <Panel header="Total Payslip Collection" className="bg-success">
                                            <center>
                                                <div>
                                                    <h2><u>{feesCollectionInfo.totalPayslipId ? feesCollectionInfo.totalPayslipId.toLocaleString('EN-IN') : 0}</u> </h2>
                                                </div>
                                                <h3></h3>
                                            </center>
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-xl-6 ">
                                        <Panel header="Total Payslip Amount" className="bg-danger">
                                            <center>
                                                <div>
                                                    <h2><u>{feesCollectionInfo.totalPayslipcollection ? feesCollectionInfo.totalPayslipcollection.toLocaleString('EN-IN') : 0}</u> </h2>
                                                </div>
                                                <h3></h3>
                                            </center>
                                        </Panel>
                                    </div>

                                    <div className="p-col-12 p-xl-6 ">
                                        <Panel header="Bkash Payment" className="bg-success">
                                            <center>
                                                <div>
                                                    <h2><u>{feesCollectionInfo.bkashPayment ? feesCollectionInfo.bkashPayment.toLocaleString('EN-IN') : 0}</u> </h2>
                                                </div>
                                                <h3></h3>
                                            </center>
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-xl-6 ">
                                        <Panel header="Sure Cash Payment" className="bg-danger">
                                            <center>
                                                <div>
                                                    <h2><u>{feesCollectionInfo.sureCashPayment ? feesCollectionInfo.sureCashPayment.toLocaleString('EN-IN') : 0}</u></h2>
                                                </div>
                                                <h3></h3>
                                            </center>
                                        </Panel>
                                    </div>

                                </div>
                            </Panel>
                            <br />

                        </AccordionTab>
                    </Accordion>

                    <Dialog className="nw-dialog" header={dialogHeader} visible={this.state.visible} onHide={this.makeDialogHide} maximizable>

                        <div className="p-fluid">
                            <div className="p-grid">
                                {this.state.dataTableIsLoading ? this.netiContentLoader.MyPointTableLoader() : dialogContent}
                            </div>
                        </div>
                    </Dialog>

                </div>
            </div>
        );
    }
}