import React, {Component} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { UserCoreUrlInfoService } from '../../../service/UserCoreUrlInfoService';
import CommonFuctionality from '../../common/CommonFuctionality';

const languageList=[
    { label: "English", value: "English"},
    { label: "Bangla", value: "Bangla"}
]
let urlId=null;
export class SocialLinkFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultLanguageValue:'',
            instituteInformation: {
                defaultLanguage:''
            },
            updateSocialLink:{}
        };
        
        this.dwsService = new DwsService();
        this.userCoreUrlInfoService=new UserCoreUrlInfoService();
        this.CommonFuctionality = new CommonFuctionality();
    }

    componentWillMount(){
        urlId=this.dwsService.getUrlInfoFromLocalStorage();
    }

    onHide = (event) => {
        this.setState({visible: false});
        this.CommonFuctionality.blurDialogBackgroundDeActive()
    }

    viewDialog = (e) => {
        this.setState({ visible: true });
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    onChangeSocialBtn = (e) => {
        this.viewDialog();

    }

    onChangeFaceBookLink = (e) => {
		let { updateSocialLink } = this.state;
		updateSocialLink.facebookProfile = e.target.value;
		this.setState({updateSocialLink});
    }

    onChangeLinkedInLink = (e) => {
		let { updateSocialLink } = this.state;
		updateSocialLink.linkedinProfile = e.target.value;
		this.setState({updateSocialLink});
    }

    onChangeTwitterLink = (e) => {
		let { updateSocialLink } = this.state;
		updateSocialLink.twitterProfile = e.target.value;
		this.setState({updateSocialLink});
    }

    onChangeYouTubeLink = (e) => {
		let { updateSocialLink } = this.state;
		updateSocialLink.youtubeProfile = e.target.value;
		this.setState({updateSocialLink});
    }

    onUpdateHandler = () => {
        
        let { updateMemberDetails } = this.state;


    
        if(this.updateMemberFormHandleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            this.dwsService.updateDwsMemberInfo(updateMemberDetails)
            .then(res => {
                if(res.status == 202) {
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                } else {
                    this.dwsService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                    });
                }
                
            }).catch((error) => {
                this.setState({topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
            });

        }

    }


    render() {
        let { updateSocialLink, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
       

        return (
            <div className="">
                { topProgressBar?
                    <ErrorMessageView 
                        topProgressBar={topProgressBar} 
                    />
                    : null
                }
                { errorMsgVisible ?
                    <ErrorMessageView 
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section blur-section">
                    <Toolbar>
                        <div className="p-toolbar-group-left">
                            <center>
                                <a href="#">
                                    <span ><i style={{ 'fontSize': '2em', color: "white", paddingRight: "5px" }} className="fab fa-facebook-square"></i></span>
                                </a>
                                <span ><i style={{ 'fontSize': '2em', color: "white", paddingRight: "5px" }} className="fab fa-twitter-square"></i></span>
                                <span ><i style={{ 'fontSize': '2em', color: "white", paddingRight: "5px" }} className="fab fa-linkedin-square"></i></span>
                                <span ><i style={{ 'fontSize': '2em', color: "white", paddingRight: "5px" }} className="fab fa-youtube-square"></i></span>

                            </center>
                        </div>

                        <div className="p-toolbar-group-right">
                            
                        
                            <Button 
                                onClick={this.onChangeSocialBtn} 
                                icon="fas fa-edit" 
                                label="Edit Social Link"
                            />
                        </div>

                    </Toolbar>
                </div>
                

                <div className="dialog-section">
                    <Dialog 
                        header="Edit Social Link" 
                        visible={this.state.visible} 
                        className="nw-dialog"
                        onHide={this.onHide} 
                        //dragable={true}
                        closable
                    >
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12 ">
                                <div className="nw-form-body">

                                    {/* <center className="p-col-10 p-xl-12"><i className="pi pi-user" style={{ 'fontSize': '10em' }}></i></center> */}

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Facebook Link</span>
                                            <InputText
                                                id="fb"
                                                placeholder="Enter facebook link"
                                                showClear={true}
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="fb"
                                                value={ updateSocialLink.facebookProfile }
                                                onChange={(e) => this.onChangeFaceBookLink(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Twitter Link</span>
                                            <InputText
                                                id="twitter"
                                                placeholder="Enter twitter link"
                                                showClear={true}
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="twitter"
                                                value={ updateSocialLink.twitterProfile }
                                                onChange={(e) => this.onChangeTwitterLink(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Linkedin Link</span>
                                            <InputText
                                                placeholder="Enter linkedin Link"
                                                style={{ width: "100%" }}
                                                type="text"
                                                id="linkedin"
                                                name="linkedin"
                                                value={ updateSocialLink.linkedInProfile }
                                                onChange={(e) => this.onChangeLinkedInLink(e)}
                                            />
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">YouTube Link</span>
                                            <InputText
                                                placeholder="Enter youtube link"
                                                style={{ width: "100%" }}
                                                type="text"
                                                id="utube"
                                                name="utube"
                                                value={ updateSocialLink.youTubeProfile }
                                                onChange={(e) => this.onChangeYouTubeLink(e)}
                                            />
                                        </div>
                                    </div>

                                    
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                        />
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Update"
                                            icon="fas fa-check"

                                        />
                                    </div>

                                    

                                </div>
                            </div>

                        </div>
                    

                    </Dialog>

                </div>

                
            </div>
        );
    }
}


