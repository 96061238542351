import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { InputText } from 'primereact/inputtext';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { UserCategoryService } from '../../service/UserCategoryService';
import { Dialog } from 'primereact/dialog';
import CommonFuctionality from '../common/CommonFuctionality';
import { RadioButton } from 'primereact/radiobutton';
import NetiContentLoader from '../common/NetiContentLoader';
import { UpdateEMBasicInformation } from './UpdateEMBasicInformation';
import { UpdateEMPaymentInfo } from './UpdateEMPaymentInfo';
import { EMBillRegenerateInfo } from './EMBillRegenerateInfo';
import { EmBillPaidLog } from './EmBillPaidLog';
import { EmBillDiscountLog } from './EmBillDiscountLog';
import { FileUpload } from 'primereact/fileupload';
import { Editor } from 'primereact/editor';
import { EmGeneratedBillLog } from './EmGeneratedBillLog';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { EmBillDetailsLog } from './EmBillDetailsLog';
import { EmChangePartner } from './EmChangePartner';
import { EmChangeAdmin } from './EmChangeAdmin';
import { EMInstituteIDTermination } from './EMInstituteIDTermination';
import { InputTextarea } from 'primereact/inputtextarea';
let cloneStateBeforeMount;

export class EdumanAtAGlance extends Component {
    constructor() {
        super();
        this.state = {
            productPackageList: [],
            submitBtnDisable: true,
            postpaidBillList: [],
            reasonTitleDropdownList: [],
            purchaseCode: '',
            purchaseInformation: {
                productName: '',
            },
            purchaseCodeError: [],

            emBillDiscountInfo: {
                reasonDetails: '',
                approvedNote: '',
                approvedAmount: '',
                requestAmount: '',
                productPurchaseCodeDTO: {
                    productPurchaseCodeID: ''
                },
                emDetailsInfoDTO: {
                    emDetailsId: ''
                },
                edumanBillGenerateLogDTO: {
                    generateBillId: ''
                },
                reasonTitleCoreCategoryDTO: {
                    coreCategoryID: ''
                }
            },
            firstInputFieldIsLoading: false,
            billDiscountInfoError: {},

            viewEmBillDiscountForm: false,
            instituteID: '',
            institutePic: '',
            emAccessInfoObj: {
                paymentInfo: {
                    edumanPackageId: ''
                },
                instituteInfo: {
                    instituteName: '',
                    instituteStatus: 0,
                    instituteAddress: ''
                }

            },
            errors: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            infoDialogHide: false,
            packageDropdownIsLoading: false,
            changeOwnerDialogHide: false,
            billDetails: false,
            globalFilter: '',
            payableBillDetails: '',
            discountPayable: '',
            totalPaidDetails: '',
            createDuration: '',
            changeEMAdmin: '',
            showInformation: false,
            purchaseCodeSearchView: false,
            purchaseCodeSearchViewError: false,
            purchaseCodeSearchErrorMsgBody: '',
        }

        this.CRMPointService = new CRMPointService();
        this.partnerPointService = new PartnerPointService();

        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.CommonFuctionality = new CommonFuctionality();
        this.infoUpdateDialog = this.infoUpdateDialog.bind(this);
        this.infoUpdateDialogHide = this.infoUpdateDialogHide.bind(this);
        this.changeOwnerDialog = this.changeOwnerDialog.bind(this);
        this.changeOwnerDialogHide = this.changeOwnerDialogHide.bind(this);
        this.userOtherAccessService = new UserOtherAccessService();
        this.userCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
        this.billDetailsDialog = this.billDetailsDialog.bind(this);
        this.billDetailsDialogHide = this.billDetailsDialogHide.bind(this);
        this.validatorUtility = new ValidatorUtility();

    }

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    viewPostpaidBillSummaryList = (value) => {
        let { payableInstituteInfo, postpaidBillList } = this.state;
        this.setState({ postpaidBillList: [] })
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.CRMPointService.getPostpaidBillSummary(value)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log("bill summary body", body);
                        if (body.length !== 0) {
                            this.setState({ postpaidBillList: body, errorMsgVisible: false });
                        } else {
                            this.setState({ errorMsgVisible: true, errorMsgBody: 'No bill amount found' });
                        }

                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, topProgressBar: false, errorMsgBody: 'Please check your connection.' })
            });

    }

    getReasonTitleList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.findByCategoryDefaultCode('C11002035')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ reasonTitleDropdownList: body, topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })                            
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    onChangeBillMonth = (e) => {
        let { billDiscountInfoError } = this.state;
        billDiscountInfoError["billMonth"] = '';
        let { emBillDiscountInfo } = this.state;
        emBillDiscountInfo.edumanBillGenerateLogDTO.generateBillId = e.target.value;
        this.setState({ emBillDiscountInfo });
        this.setState({ billDiscountInfoError })
    }

    onChangeReasonTitle = (e) => {
        let { billDiscountInfoError } = this.state;
        billDiscountInfoError["reasonTitle"] = '';
        let { emBillDiscountInfo } = this.state;
        emBillDiscountInfo.reasonTitleCoreCategoryDTO.coreCategoryID = e.target.value;
        this.setState({ emBillDiscountInfo });
        this.setState({ billDiscountInfoError })
    }

    onChangeRequestAmount = (e) => {
        let { billDiscountInfoError } = this.state;
        billDiscountInfoError["requestAmount"] = '';
        let { emBillDiscountInfo } = this.state;
        emBillDiscountInfo.requestAmount = e.target.value;
        this.setState({ emBillDiscountInfo });
        this.setState({ billDiscountInfoError })
    }

    onChangeApproveAmount = (e) => {
        let { billDiscountInfoError } = this.state;
        billDiscountInfoError["approvedAmount"] = '';
        let { emBillDiscountInfo } = this.state;
        emBillDiscountInfo.approvedAmount = e.target.value;
        this.setState({ emBillDiscountInfo });
        this.setState({ billDiscountInfoError })
    }

    onChangeApproveNote = (e) => {
        let { billDiscountInfoError } = this.state;
        billDiscountInfoError["approvedNote"] = '';
        let { emBillDiscountInfo } = this.state;
        emBillDiscountInfo.approvedNote = e.target.value;
        this.setState({ emBillDiscountInfo });
        this.setState({ billDiscountInfoError })
    }

    onChangeReasonDetails = (e) => {
        let { billDiscountInfoError } = this.state;
        billDiscountInfoError["reasonDetails"] = '';
        let { emBillDiscountInfo } = this.state;
        emBillDiscountInfo.reasonDetails = e.target.value;
        this.setState({ emBillDiscountInfo });
        this.setState({ billDiscountInfoError })
    }

    onSubmitHandler = () => {
        let { emBillDiscountInfo, payableInstituteInfo, purchaseInformation } = this.state;
        let { basicInfo } = this.state.emAccessInfoObj;
        emBillDiscountInfo.emDetailsInfoDTO.emDetailsId = basicInfo.emDetailsId;
        emBillDiscountInfo.productPurchaseCodeDTO.productPurchaseCodeID = purchaseInformation.productPurchaseCodeID;
        this.setState({ emBillDiscountInfo });
        if (this.discountBillFormError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, });
            this.CRMPointService.saveEdumanBillDiscount(emBillDiscountInfo)
                .then(res => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    if (res.status == 201) {
                        this.setState({
                            payableAmount: '',
                            postpaidBillList: [],
                            payableInstituteID: '',
                            payableInstituteInfo: cloneStateBeforeMount.payableInstituteInfo,
                            emBillDiscountInfo: cloneStateBeforeMount.emBillDiscountInfo
                        });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                        setTimeout(() => {
                            this.emBillDiscountFormDialogHide()
                        }, 800);
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resBody, dataTableIsLoading: false })
                            });
                    }
                }).catch(error => {
                    this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: 'Please check your connection.' })

                });
        }

    }

    discountBillFormError = () => {
        let { billDiscountInfoError, emBillDiscountInfo } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (!emBillDiscountInfo.edumanBillGenerateLogDTO.generateBillId) {
            formIsValid = false;
            billDiscountInfoError["billMonth"] = "Bill Month can't left empty.";
        }
        if (emBillDiscountInfo.reasonTitleCoreCategoryDTO.coreCategoryID === '') {
            formIsValid = false;
            billDiscountInfoError["reasonTitle"] = "Reason Title can't left empty.";
        }
        if (emBillDiscountInfo.reasonDetails === '') {
            formIsValid = false;
            billDiscountInfoError["reasonDetails"] = "Reason Details can't left empty.";
        }
        if (emBillDiscountInfo.requestAmount === '') {
            formIsValid = false;
            billDiscountInfoError["requestAmount"] = "Request Amount can't left empty.";
        }

        if (emBillDiscountInfo.approvedAmount === '') {
            formIsValid = false;
            billDiscountInfoError["approvedAmount"] = "Approved Amount can't left empty.";
        }
        if (emBillDiscountInfo.approvedNote === '') {
            formIsValid = false;
            billDiscountInfoError["approvedNote"] = "Approve Note can't left empty.";
        }

        this.setState({ billDiscountInfoError });
        return formIsValid;
    }

    basicInfoUpdateDialog = () => {
        this.refs.updateEMBasicInformation.basicInfoUpdateDialog();
    }

    paymentInfoUpdateDialog = () => {
        this.refs.updateEMPaymentInfo.paymentInfoUpdateDialog();
    }

    infoUpdateDialog() {
        this.setState({ infoDialogHide: true });
        this.getPackageList();
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    infoUpdateDialogHide() {
        this.setState({ infoDialogHide: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    regenerateUpdateDialog = () => {
        let { basicInfo } = this.state.emAccessInfoObj;
        this.refs.emBillRegenerateInfo.regenerateUpdateDialog(basicInfo.emDetailsId);
    }



    terminationDialogPartOne = () => {
        let { basicInfo } = this.state.emAccessInfoObj;
        this.refs.emInstituteIDTermination.emIDTerminationDialogAction(basicInfo.emDetailsId);
    }

    changeOwnerDialog() {
        this.setState({ changeOwnerDialogHide: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    changeOwnerDialogHide() {
        this.setState({ changeOwnerDialogHide: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }


    billDetailsDialog() {
        let { basicInfo } = this.state.emAccessInfoObj;
        this.refs.emBillDetailsLog.billDetailsLogDialog(basicInfo.emDetailsId);
    }


    billDetailsDialogHide() {
        this.setState({ billDetails: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    payableBillDetailsDialog = () => {
        let { basicInfo } = this.state.emAccessInfoObj;
        this.refs.emGeneratedBillLog.billDetailsLogDialog(basicInfo.emDetailsId);
    }

    payableBillDetailsDialogHide = () => {
        this.setState({ payableBillDetails: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    discountDetailsDialog = (e) => {
        let { basicInfo } = this.state.emAccessInfoObj;
        this.refs.emBillDiscountLog.discountLogDialogAction(basicInfo.emDetailsId);
    }

    discountDetailsDialogHide = () => {
        this.setState({ discountPayable: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }


    totalPaidDetailsDialog = (e) => {
        let { basicInfo } = this.state.emAccessInfoObj;
        this.refs.emBillPaidLog.paidLogDialogAction(basicInfo.emDetailsId);
    }


    totalPaidDetailsDialogHide = () => {
        this.setState({ totalPaidDetails: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    changePartnerDialog = () => {
        let { basicInfo } = this.state.emAccessInfoObj;
        this.refs.emChangePartner.changePartnerDialog(basicInfo.emDetailsId);
    }

    createDurationDialogHide = () => {
        this.setState({ createDuration: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    changeAdminDialog = () => {
        let { basicInfo } = this.state.emAccessInfoObj;
        this.refs.emChangeAdmin.changeEmAdminDialogAction(basicInfo.emDetailsId);
    }

    changeAdminDialogHide = () => {
        this.setState({ changeEMAdmin: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }


    onSearchHandler = () => {
        this.setState({ topProgressBar: true, searchErrorMsgBody: '', errorMsgVisible: false });
        if (this.searchRegError()) {
            this.CRMPointService.getEdumanAccessInfo(this.state.instituteID)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            if (body.instituteInfo && body.instituteInfo.instituteStatus === 2) {
                                body.instituteInfo.instituteStatus = 0
                            } else if (body.instituteInfo && body.instituteInfo.instituteStatus === 1) {
                                body.instituteInfo.instituteStatus = 1
                            }
                            this.setState({ emAccessInfoObj: body, showInformation: true, topProgressBar: false });
                        });
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ showInformation: false, topProgressBar: false, errorMsgVisible: true, searchErrorMsgBody: responseBody,  })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, showInformation: false, errorMsgVisible: true, errorMsgBody: "Please check your connection.",  })
                });

        }

    }

    searchRegError = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.instituteID === '') {
            formIsValid = false;
            errors["instituteID"] = "Institute ID can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onChangeInstituteID = (e) => {
        let { errors } = this.state;
        errors["instituteID"] = "";
        this.setState({ instituteID: e.target.value, errors });
    }

    onUpdateInstituteInfo = () => {
        let { basicInfo, instituteInfo, paymentInfo, emAdminInfo, partnerInfo } = this.state.emAccessInfoObj;

        this.setState({ packageDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        if (instituteInfo.instituteStatus === 0) {
            instituteInfo.instituteStatus = 2
        } else if (instituteInfo.instituteStatus === 1) {
            instituteInfo.instituteStatus = 1
        }
        let updateRequestInfo = {
            'emDetailsId': basicInfo.emDetailsId,
            "instituteName": instituteInfo.instituteName,
            "instituteAddress": instituteInfo.instituteAddress,
            "instituteStatus": instituteInfo.instituteStatus,
            "packageCoreCategoryInfoDTO": {
                "coreCategoryID": paymentInfo.edumanPackageId,
            }
        }

        this.userOtherAccessService.updateEdumanInstituteInfo(updateRequestInfo)
            .then(res => {
                if (res.status === 202) {
                    this.setState({ packageDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Updated successfully', life: 800 })
                    setTimeout(() => {
                        this.infoUpdateDialogHide();
                    }, 800);

                } else {
                    this.userOtherAccessService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ packageDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ packageDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
            });
    }

    onChangeInstituteName = (e) => {
        let { emAccessInfoObj } = this.state
        emAccessInfoObj.instituteInfo.instituteName = e.target.value;
        this.setState({ emAccessInfoObj });
    }

    onChangeInstituteAddress = (e) => {
        let { emAccessInfoObj } = this.state
        emAccessInfoObj.instituteInfo.instituteAddress = e.target.value;
        this.setState({ emAccessInfoObj });
    }

    onChangePackage = (e) => {
        let { emAccessInfoObj } = this.state;
        emAccessInfoObj.paymentInfo.edumanPackageId = e.target.value;
        this.setState({ emAccessInfoObj });

    }

    getPackageList = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode('T111')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productPackageList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    })
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeInstituteStatus = (e) => {
        let { instituteInfo } = this.state.emAccessInfoObj
        instituteInfo.instituteStatus = e.target.value;
        this.setState({ instituteInfo });

    }

    onChangeTypeID = (e) => {
        this.setState({ typeID: e.target.value });
    }

    purchaseCodeCheck = (e) => {
        this.setState({ purchaseInformation: cloneStateBeforeMount.purchaseInformation, purchaseCodeSearchErrorMsgBody: '', purchaseCodeSearchView: false });
        if (this.purchaseCodeError()) {
            this.setState({ topProgressBar: true, submitBtnDisable: true, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: true, errorMsgVisible: false, purchaseCodeSearchView: true });
            this.partnerPointService.getUserPurchaseCode(this.state.purchaseCode)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            console.log("purchaseInformation", body);
                            let { purchaseInformation } = this.state;
                            purchaseInformation.productName = body.productPurchaseLogDTO.productInfoDTO.productName;
                            purchaseInformation.usedStatus = body.usedStatus;
                            purchaseInformation.purchaseDate = NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
                            purchaseInformation.productPurchaseCodeID = body.productPurchaseCodeID
                            this.setState({ submitBtnDisable: false, purchaseInformation, topProgressBar: false, purchaseCodeSearchBtnDisabled: false, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: false, errorMsgVisible: false, purchaseCodeSearchView: true });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({
                                    submitBtnDisable: true,
                                    firstInputFieldIsLoading: false,
                                    purchaseCodeSearchBtnDisabled: true,
                                    topProgressBar: false,
                                    purchaseCodeSearchErrorMsgBody: resp,
                                    purchaseCodeSearchViewError: true,
                                    purchaseCodeSearchView: true,
                                    purchaseInformation: {
                                        productName: ''
                                    }
                                });
                            })
                    }
                }).catch(error => {
                    this.setState({  submitBtnDisable: true, topProgressBar: false, purchaseCodeSearchBtnDisabled: true, firstInputFieldIsLoading: false, errorMsgVisible: true, purchaseCodeSearchView: false, purchaseCodeSearchViewError: false, errorMsgBody: 'Unable to load. Please check your connection.' })

                });
        }
    }

    purchaseCodeHandler = (event) => {
        let { purchaseCodeError } = this.state
        purchaseCodeError["purchaseCode"] = "";
        this.setState({ purchaseCode: event.target.value, purchaseCodeError });
    }

    purchaseCodeError = () => {
        let { purchaseCodeError } = this.state;
        let formIsValid = true;
        if (this.state.purchaseCode === '') {
            formIsValid = false;
            purchaseCodeError["purchaseCode"] = "Purchase Code can't left empty";
        } else {
            purchaseCodeError["purchaseCode"] = "";
        }
        this.setState({ purchaseCodeError });
        return formIsValid;
    }

    viewEmBillDiscountSaveForm = () => {
        let { basicInfo } = this.state.emAccessInfoObj;
        this.setState({ viewEmBillDiscountForm: true});
        this.viewPostpaidBillSummaryList(basicInfo.emDetailsId);
        this.getReasonTitleList();
    }

    emBillDiscountFormDialogHide = () => {
        this.setState({viewEmBillDiscountForm: false});
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, emAccessInfoObj, firstInputFieldIsLoading,emBillDiscountInfo } = this.state;
        let { basicInfo, instituteInfo, paymentInfo, emAdminInfo, partnerInfo } = this.state.emAccessInfoObj;

        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">370 x 240 px (recommended)</span></div>

        let packageList = []
        if (this.state.productPackageList && this.state.productPackageList.length) {
            packageList = this.state.productPackageList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let typeList = [
            { label: 'Institute Name', value: 'INSTITUTE_NAME' },
            { label: 'Institute Address', value: 'INSTITUTE_ADDRESS' },
            { label: 'Institute Package', value: 'INSTITUTE_PACKAGE' },
            { label: 'Institute Status', value: 'INSTITUTE_STATUS' },

        ];

        let reasonTitleList = [];
        if ((this.state.reasonTitleDropdownList != null) && (this.state.reasonTitleDropdownList.length > 0)) {
            reasonTitleList = this.state.reasonTitleDropdownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let billSummaryList = [];
        if ((this.state.postpaidBillList != null) && (this.state.postpaidBillList.length > 0)) {
            billSummaryList = this.state.postpaidBillList.map((item) => ({
                value: item.generateBillId,
                label: `${item.year} - ${item.month} - ${item.dueAmount} Tk`
            }));
        }
   

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section blur-section">

                    <div className="nw-form">
                        <div className="p-col-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Institute Id <span>*</span></span>
                                        <InputText
                                            value={this.state.instituteID}
                                            onChange={this.onChangeInstituteID}
                                            placeholder="Enter Institute ID"
                                            style={{ width: "100%" }}
                                            type="text"
                                            keyfilter="pint"
                                            name="instituteID"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["instituteID"]}</span>
                                </div>
                                <div className="p-col-12"></div>
                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                            </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-left"
                                        name="submit"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSearchHandler}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                    { this.state.showInformation === true?
                        <div className="p-col-12 nw-m-t-60">
                            <div className="portal-dashboard-profile at-a-glance">
                                <div className="layout-dashboard">
                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="portal-profile-info-wrapper">
                                            <div className="portal-profile-image user-active">
                                                <img
                                                    src={instituteInfo && instituteInfo.instituteLogo ? `data:image/png;base64,${instituteInfo.instituteLogo}` : ''}
                                                />
                                            </div>
                                            <div className="portal-profile-info">
                                                <h1>{instituteInfo && instituteInfo.instituteName}</h1>
                                                <h3>{instituteInfo && instituteInfo.instituteAddress}</h3>
                                                {/* <p className="active">{instituteInfo && instituteInfo.instituteStatus === 1 ? "Active" : "InActive" }</p> */}
                                            </div>
                                            <div className="basic-detais-wrapper">
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <span className="info-header">Status</span>
                                                            <span className="info-content">{instituteInfo && instituteInfo.instituteStatus === 1 ? 'Active' : 'Inactive'}</span>
                                                        </td>
                                                        <td>
                                                            <span className="info-header">Academic Year</span>
                                                            <span className="info-content">{instituteInfo && instituteInfo.academicYear}</span>
                                                        </td>
                                                        <td>
                                                            <span className="info-header">Current HR</span>
                                                            <span className="info-content">{instituteInfo && instituteInfo.currentHR}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span className="info-header">Package</span>
                                                            <span className="info-content">{paymentInfo && paymentInfo.edumanPackage}</span>
                                                        </td>
                                                        <td>
                                                            <span className="info-header">Current Student</span>
                                                            <span className="info-content">{instituteInfo && instituteInfo.currentStudent}</span>
                                                        </td>
                                                        <td>
                                                            <span className="info-header">Message Balance</span>
                                                            <span className="info-content">{instituteInfo && instituteInfo.messageBalance}</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="top-btn-group">
                                                <Button label="" icon="fa fa-edit" onClick={this.infoUpdateDialog} className="editDialog" tooltip="Edit" tooltipOptions={{ position: 'bottom' }} />
                                                <Button label="" icon="fa fa-trash" onClick={this.terminationDialogPartOne} className="editDialog" tooltip="Delete" tooltipOptions={{ position: 'bottom' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nw-m-t-45">
                                <div className="p-grid">
                                    <div className="p-xs-12 p-sm-12 p-lg-4 p-xl-4">
                                        <div className="at-glance-single-info-box">
                                            <div className="protal-single-info-title">
                                                <h4>Basic Info:</h4>
                                            </div>
                                            <div className="subTitleName">
                                                <h5>Create Duration:</h5>
                                                <span>{basicInfo && basicInfo.createDuration}</span>
                                                <Button label="" onClick={this.basicInfoUpdateDialog} icon="fa fa-edit" className="editDialog" tooltip="Edit" tooltipOptions={{ position: 'bottom' }} />
                                            </div>
                                            <div className="protal-single-info-content-box">
                                                <table>
                                                    <tr>
                                                        <td>Institute</td>
                                                        <td>{basicInfo && basicInfo.instituteId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Education Board</td>
                                                        <td>{basicInfo && basicInfo.educationBoard}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Institute Category</td>
                                                        <td>{basicInfo && basicInfo.instituteCategory}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Institute Type</td>
                                                        <td>{basicInfo && basicInfo.instituteType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Institute Phone</td>
                                                        <td>{basicInfo && basicInfo.institutePhone}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Institute Email</td>
                                                        <td>{basicInfo && basicInfo.instituteEmail}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Division</td>
                                                        <td>{basicInfo && basicInfo.division}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Distict</td>
                                                        <td>{basicInfo && basicInfo.district}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Area</td>
                                                        <td>{basicInfo && basicInfo.area}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Agreement Date</td>
                                                        <td>{basicInfo && basicInfo.agreementDate}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Agreement Exprire</td>
                                                        <td>{basicInfo && basicInfo.agreementExpiryDate}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Data Submission</td>
                                                        <td>{basicInfo && basicInfo.dataSubmissionDate}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Hand Over</td>
                                                        <td>{basicInfo && basicInfo.handoverDate}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Form Serial</td>
                                                        <td> {basicInfo && basicInfo.formSerial} 
                                                            {/* <span> <i class="fas fa-cloud-download-alt"></i></span> */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Eduman ID Create</td>
                                                        <td>{basicInfo && basicInfo.edumanIdCreateDate}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Remain to Expire</td>
                    <td>{basicInfo && basicInfo.remainingExpiredDuration}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Contact Person</td>
                                                        <td>{basicInfo && basicInfo.contactPerson}</td>
                                                        {/* <span>[Details]</span> */}
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-xs-12 p-sm-12 p-lg-4 p-xl-4">
                                        <div className="at-glance-single-info-box">
                                            <div className="protal-single-info-title">
                                                <h4>Payment Information:</h4>
                                            </div>
                                            <div className="subTitleName">
                                                <h5 className="danger">Current Due</h5>
                                                <span>{paymentInfo && paymentInfo.emCurrentDue ? `${this.validatorUtility.currencyFormatter(paymentInfo && paymentInfo.emCurrentDue)} TK` : 0}</span>
                                                <Button onClick={this.paymentInfoUpdateDialog} icon="fa fa-edit" className="editDialog" tooltip="Edit" tooltipOptions={{ position: 'bottom' }} />
                                            </div>
                                            <div className="protal-single-info-content-box">
                                                <table>
                                                    <tr>
                                                        <td>Eduman Package</td>
                                                        <td>{paymentInfo && paymentInfo.edumanPackage}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Reg. Charge</td>
                                                        <td>{paymentInfo && paymentInfo.developmentCharge}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rate per Month</td>
                                                        <td>{paymentInfo && paymentInfo.monthlyRate}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Payment Type</td>
                                                        <td>{paymentInfo && paymentInfo.paymentType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Academic Year</td>
                                                        <td>{instituteInfo && instituteInfo.academicYear}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Current Student</td>
                                                        <td>{instituteInfo && instituteInfo.currentStudent}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Current HR</td>
                                                        <td>{instituteInfo && instituteInfo.currentHR}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Billed Month</td>
                                                        <td><span onClick={this.billDetailsDialog}>{paymentInfo && paymentInfo.totalBilledMonth}</span> <span onClick={this.regenerateUpdateDialog}>[Regenerate]</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Last Billed Month</td>
                                                        <td>{paymentInfo && paymentInfo.lastBillMonth}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Last Billed Amount</td>
                                                        <td>{paymentInfo && paymentInfo.lastBillAmount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Payable</td>
                                                        <td><span onClick={this.payableBillDetailsDialog}>{paymentInfo && paymentInfo.totalPayable}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Discount</td>
                                                        <td><span onClick={this.discountDetailsDialog}>{paymentInfo && paymentInfo.totalDiscount}</span>
                                                         <span style={{ paddingLeft:"50px", textDecoration:"none"}} onClick={this.viewEmBillDiscountSaveForm}> <i class="fas fa-plus"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Paid</td>
                                                        <td><span onClick={this.totalPaidDetailsDialog}>{paymentInfo && paymentInfo.totalPaid}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="at-glance-single-info-box">
                                            <div className="protal-single-info-title">
                                                <h4>Other Information:</h4>
                                            </div>
                                            <br />
                                            <div className="protal-single-info-content-box">
                                                <table>
                                                    <tr>
                                                        <td>Device Found</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Neti CMS Found</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Admisia Found</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td> Observer point Found</td>
                                                        <td></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="p-xs-12 p-sm-12 p-lg-4 p-xl-4">
                                        <div className="at-glance-single-info-box">
                                            <div className="protal-single-info-title">
                                                <h4>Partner Information:</h4>

                                            </div>
                                            <div className="subTitleName">
                                                <h5>Partner Neti ID</h5>
                                                <span>{partnerInfo && partnerInfo.partnerCustomNetiID}</span>
                                                <Button icon="fa fa-edit" className="editDialog" tooltip="Edit" onClick={this.changePartnerDialog} tooltipOptions={{ position: 'bottom' }} />
                                            </div>
                                            <div className="protal-single-info-content-box">
                                                <table>
                                                    {/* <tr>
                                                        <td>Partner Neti ID:</td>
                                                        <td></td>
                                                    </tr> */}
                                                    <tr>
                                                        <td>
                                                            <p>Partner Name</p>
                                                        </td>
                                                        <td>{partnerInfo && partnerInfo.partnerName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Designation</td>
                                                        <td>{partnerInfo && partnerInfo.partnerDesignation}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Area</td>
                                                        <td>{partnerInfo && partnerInfo.partnerArea}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mobile</td>
                                                        <td>{partnerInfo && partnerInfo.partnerMobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Email</td>
                                                        <td>{partnerInfo && partnerInfo.partnerEmail}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="at-glance-single-info-box">
                                            <div className="subTitleName">
                                                <h5>EM Admin Neti ID:</h5>
                                                <span>{emAdminInfo && emAdminInfo.emAdminCustomNetiID}</span>
                                                <Button label="" icon="fa fa-edit" className="editDialog" tooltip="Edit" onClick={this.changeAdminDialog} tooltipOptions={{ position: 'bottom' }} />
                                            </div>

                                            <div className="protal-single-info-content-box">
                                                <table>
                                                    {/* <tr>
                                                        <td>Admin Neti ID</td>
                                                        <td>{emAdminInfo && emAdminInfo.emAdminCustomNetiID}</td>
                                                    </tr> */}
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>{emAdminInfo && emAdminInfo.emAdminName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mobile</td>
                                                        <td>{emAdminInfo && emAdminInfo.emAdminMobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Email</td>
                                                        <td>{emAdminInfo && emAdminInfo.emAdminEmail}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }

                </div>
                <div className="dialog-section">
                    <UpdateEMBasicInformation ref="updateEMBasicInformation" basicInfoProps={ basicInfo }/>
                    <UpdateEMPaymentInfo ref="updateEMPaymentInfo"  paymentInfoProps={ paymentInfo } basicInfoProps={ basicInfo }/>
                        <EmBillPaidLog 
                            ref="emBillPaidLog"/>
                        <EmBillDiscountLog 
                            ref="emBillDiscountLog"/>
                    <EmGeneratedBillLog ref="emGeneratedBillLog" />
                    <EmBillDetailsLog ref="emBillDetailsLog" />
                    <Dialog header="Change Info" visible={this.state.infoDialogHide} onHide={this.infoUpdateDialogHide} className="nw-dialog product-info-dialog">
                        <div className="p-fluid">
                            <div className="p-grid  nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Change Type</span>
                                                <Dropdown
                                                    placeholder='Enter Type'
                                                    name="type"
                                                    options={typeList}
                                                    value={this.state.typeID}
                                                    onChange={this.onChangeTypeID}
                                                    filter={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className='error-message'></span>
                                        </div>

                                        {this.state.typeID === 'INSTITUTE_NAME' ?
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Name</span>
                                                    <InputText
                                                        onChange={this.onChangeInstituteName}
                                                        value={emAccessInfoObj.instituteInfo && emAccessInfoObj.instituteInfo.instituteName}
                                                        placeholder="Enter Name"
                                                        style={{ width: "100%" }}
                                                        type="text"
                                                        name="name"
                                                    />
                                                </div>
                                                <span className='error-message'></span>
                                            </div>
                                            : ''
                                        }

                                        {this.state.typeID === 'INSTITUTE_ADDRESS' ?
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Address</span>
                                                    <InputText
                                                        value={emAccessInfoObj.instituteInfo && emAccessInfoObj.instituteInfo.instituteAddress}
                                                        onChange={this.onChangeInstituteAddress}
                                                        placeholder="Enter Address"
                                                        style={{ width: "100%" }}
                                                        type="text"
                                                        name="address"
                                                    />
                                                </div>
                                                <span className='error-message'></span>
                                            </div>
                                            : ''
                                        }

                                        {this.state.typeID === 'INSTITUTE_STATUS' ?
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Status  <span>*</span></span>
                                                    <div className="nw-radiobutton-group dialog-radio-btn">
                                                        <RadioButton
                                                            value={1}
                                                            inputId="active"
                                                            name="instituteStatus"
                                                            onChange={this.onChangeInstituteStatus}
                                                            checked={emAccessInfoObj.instituteInfo && emAccessInfoObj.instituteInfo.instituteStatus === 1}
                                                        />
                                                        <label htmlFor="rb3" className="p-radiobutton-label">
                                                            Active
                                                    </label>
                                                        <RadioButton
                                                            value={0}
                                                            inputId="inActive"
                                                            name="instituteStatus"
                                                            onChange={this.onChangeInstituteStatus}
                                                            checked={emAccessInfoObj.instituteInfo && emAccessInfoObj.instituteInfo.instituteStatus === 0}
                                                        />
                                                        <label htmlFor="rb4" className="p-radiobutton-label">
                                                            Inactive
                                                    </label>
                                                    </div>
                                                </div>
                                            </div>

                                            : ''}

                                        {this.state.typeID === 'INSTITUTE_PACKAGE' ?
                                            <div className="p-col-12 p-xl-12">
                                                {this.state.packageDropdownIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() :
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Package</span>
                                                        <Dropdown
                                                            placeholder='Enter Package'
                                                            name="Package"
                                                            options={packageList}
                                                            value={emAccessInfoObj.paymentInfo && emAccessInfoObj.paymentInfo.edumanPackageId}
                                                            onChange={this.onChangePackage}
                                                            filter={true}
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                }
                                                <span className='error-message'></span>
                                            </div>
                                            : ''}
                                        <div className="p-col-12 p-col-12  nw-button-parent-multiple">
                                            <Button
                                                className="p-button-danger nw-button nw-button-multiple"
                                                label="Discard"
                                                icon="fas fa-times"
                                                onClick={this.infoUpdateDialogHide}
                                            // onClick={this.onResetInstitute}
                                            />

                                            <Button
                                                className="p-button-primary nw-button nw-button-multiple"
                                                label="Update"
                                                icon="fas fa-check"
                                                onClick={this.onUpdateInstituteInfo}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <EMBillRegenerateInfo ref="emBillRegenerateInfo" />
                    <EmChangePartner  ref="emChangePartner"/>
                    <EmChangeAdmin  ref="emChangeAdmin"/>
                    <EMInstituteIDTermination  ref="emInstituteIDTermination"/>
                    <Dialog header="Change EM Admin" visible={this.state.changeEMAdmin} onHide={this.changeAdminDialogHide} className="nw-dialog product-info-dialog admin-point">
                        <div className="p-fluid">
                            <div className="p-grid  nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form-body">
                                        <div className="p-col-12 p-xl-12">
                                            {this.state.packageDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Change purpose </span>
                                                    <Dropdown
                                                        placeholder='Enter Package'
                                                        name="Package"
                                                        options={packageList}
                                                        value={emAccessInfoObj.paymentInfo && emAccessInfoObj.paymentInfo.edumanPackageId}
                                                        onChange={this.onChangePackage}
                                                        filter={true}
                                                        autoWidth={false}
                                                    />
                                                </div>
                                            }
                                            <span className='error-message'></span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtexteditor-label">DETAILS</span>
                                                <Editor
                                                    className="nw-inputtexteditor"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors["description"]}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Attachment <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/GIF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    <FileUpload
                                                        chooseLabel={-uploadFileLabel}
                                                        mode="basic"
                                                        maxFileSize={1000000}
                                                        auto={true}
                                                        accept="image/*"
                                                    />

                                                </div>
                                            </div>
                                            <span className='error-message'></span>
                                        </div>
                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                            <br />
                                            <br />
                                            <br />
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">NETI ID <span>*</span></span>
                                                <InputText
                                                    name="productName"
                                                    autowidth={'false'}
                                                    placeholder="Type code here"
                                                />
                                                <Button
                                                    className=""
                                                    label=""
                                                    icon="fas fa-search"
                                                />
                                            </div>
                                            <span className='error-message'></span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-search-view no-border p-0">
                                                <div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Name</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">Abul hossain</div>
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">MOBILE NO.</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">01689932015</div>
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">DESIGNATION</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">BEP</div>
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">AREA</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">Dhaka, North</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-col-12  nw-button-parent-multiple">
                                            <Button
                                                className="p-button-danger nw-button nw-button-multiple"
                                                label="Discard"
                                                icon="fas fa-times"
                                                onClick={this.changeAdminDialogHide}
                                            />
                                            <Button
                                                className="p-button-primary nw-button nw-button-multiple"
                                                label="Confirm"
                                                icon="fas fa-check"
                                                onClick={this.changeAdminDialog}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header="Em Bill Discount" visible={this.state.viewEmBillDiscountForm} onHide={this.emBillDiscountFormDialogHide} className="nw-dialog product-info-dialog admin-point">
                        <div className="p-fluid">
                            <div className="p-grid  nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form-body">
                                  

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                            <InputText
                                                placeholder="Enter Purchase Code"
                                                value={this.state.purchaseCode}
                                                style={{ width: "15%" }}
                                                type="text"
                                                name="purchaseCode"
                                                onChange={this.purchaseCodeHandler}
                                                keyfilter="num"
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                label={this.state.searchLabel}
                                                icon="fas fa-search"
                                                onClick={(e) => this.purchaseCodeCheck(e)}
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.purchaseCodeError["purchaseCode"]}</span>

                                    </div>

                                    <div>

                                        {this.state.purchaseCodeSearchView ?
                                            <div className="p-col-12 p-xl-12">
                                                <div className="nw-search-view">
                                                    {this.state.purchaseCodeSearchViewError ?
                                                        <center className="error-message">{this.state.purchaseCodeSearchErrorMsgBody}</center> :
                                                        <div>

                                                            {
                                                                this.state.purchaseInformation.productName === '' ? '' :
                                                                    <div>
                                                                        <div className="p-col-12 p-xl-12">
                                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                                <div className="p-inputgroup">
                                                                                    <span className="task-badge found"></span>
                                                                                    <span className="p-inputgroup-addon">Product Name</span>
                                                                                    <span className="p-inputgroup-colon">:</span>
                                                                                    <div className="nw-inputgroup-desc">
                                                                                        {this.state.purchaseInformation.productName}
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div className="p-col-12 p-xl-12">
                                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                                <div className="p-inputgroup">
                                                                                    <span className="task-badge found"></span>
                                                                                    <span className="p-inputgroup-addon">Use Status</span>
                                                                                    <span className="p-inputgroup-colon">:</span>
                                                                                    <div className="nw-inputgroup-desc">
                                                                                        {(this.state.purchaseInformation.usedStatus === 0) ? "Unused" : this.state.purchaseInformation.usedStatus}
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div className="p-col-12 p-xl-12">
                                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                                <div className="p-inputgroup">
                                                                                    <span className="task-badge found"></span>
                                                                                    <span className="p-inputgroup-addon">Purchase Date</span>
                                                                                    <span className="p-inputgroup-colon">:</span>
                                                                                    <div className="nw-inputgroup-desc">
                                                                                        {this.state.purchaseInformation.purchaseDate}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                    </div>

                                                            }

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Payable Institute <span>*</span></span>
                                            <InputText
                                                value={this.state.instituteID}
                                                autoWidth={false}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Bill Month <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Bill Month"
                                                value={emBillDiscountInfo.edumanBillGenerateLogDTO.generateBillId}
                                                options={billSummaryList}
                                                onChange={this.onChangeBillMonth}
                                                filter={true}
                                                filterBy="value"
                                                showClear={true}
                                                autoWidth={false}
                                                name="billMonth"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.billDiscountInfoError["billMonth"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.secondDropdownIsLoading ? this.NetiContentLoader.normalFormInputField() :

                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Reason Title <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Reason Title"
                                                    value={emBillDiscountInfo.reasonTitleCoreCategoryDTO.coreCategoryID || ''}
                                                    options={reasonTitleList}
                                                    onChange={this.onChangeReasonTitle}
                                                    filter={true}
                                                    filterBy="value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="reasonTitle"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{this.state.billDiscountInfoError["reasonTitle"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Reason Details <span>*</span></span>
                                            <InputTextarea
                                                value={emBillDiscountInfo.reasonDetails || ''}
                                                onChange={this.onChangeReasonDetails}
                                                placeholder="Enter Reason Details"
                                                rows={3}
                                                cols={30}
                                                autoResize={true}
                                                name="reasonDetails"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.billDiscountInfoError["reasonDetails"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Request Amount <span>*</span></span>
                                            <InputText
                                                placeholder="Enter Request Amount"
                                                keyfilter="money"
                                                name="requestAmount"
                                                value={emBillDiscountInfo.requestAmount || ''}
                                                onChange={this.onChangeRequestAmount}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.billDiscountInfoError["requestAmount"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Approve Amount <span>*</span></span>
                                            <InputText
                                                placeholder="Enter Approve Amount"
                                                keyfilter="money"
                                                name="approvedAmount"
                                                value={emBillDiscountInfo.approvedAmount || ''}
                                                onChange={this.onChangeApproveAmount}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.billDiscountInfoError["approvedAmount"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Approve Note <span>*</span></span>
                                            <InputTextarea
                                                value={emBillDiscountInfo.approvedNote || ''}
                                                onChange={this.onChangeApproveNote}
                                                placeholder="Enter Approve Note"
                                                rows={3}
                                                cols={30}
                                                autoResize={true}
                                                name="approvedNote"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.billDiscountInfoError["approvedNote"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Submit"
                                            icon="fas fa-hand-holding-usd"
                                            onClick={this.onSubmitHandler}
                                            disabled={this.state.submitBtnDisable}
                                        />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                {/*Change Em Admin dialog end */}
            </div >
        )
    }
}


