import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Dropdown } from 'primereact/dropdown';

// Custom imports
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { DwsService } from '../../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { NetiCMSSetupProgress } from '../../common/NetiCMSSetupProgress';
import staticImg from '../../../../assets/images/avatar.png';
import { ApplicantPrevExamInfo } from './ApplicantPrevExamInfo';
import { pdfDownloadLandscape_TableWithSubTitle } from '../../../common/JsPdfDownload';

let cmsId = '';
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";

export class ApplicantAtaGlance extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,

            academicYear: '',
            dataTableValue: [],
            viewDialogVisible: false,
            selectedViewRowData: [],

            searchViewError: false,
            searchErrorMsgBody: '',
            errorMsgBody: '',

        }

        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

        this.getApplicantInfoDetails = this.getApplicantInfoDetails.bind(this);

    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    onChageInputValue = (e) => {

        this.setState({ academicYear: e.target.value })
        this.clearErrorMsg(e.target.name);
    }

    onSearchHandler = () => {

        if (this.emptyHandleError()) {
            this.getApplicantAtAGlanceReport();
        }

    }

    emptyHandleError = () => {

        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.academicYear === '') {
            formIsValid = false;
            errors["academicYear"] = "Academic Year can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;

    }

    clearErrorMsg = (name) => {
        let { errors } = this.state;
        errors[name] = '';
        this.setState({ errors })
    }

    getApplicantAtAGlanceReport() {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.getAdmisiaApplicantAtaGlanceReport({ cmsId: cmsId, academicYear: this.state.academicYear })
            .then(res => {

                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('dataTable-body', body);
                        if (body.messageType == 1) {
                            this.setState({ dataTableValue: body.item });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, });
                        } else {
                            this.setState({ dataTableValue: [] });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    }))
                }

            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    bodyActionTemplate = (rowData) => {
        return (
            <div className="text-center">
                <Button
                    className="nw-action-button info"
                    icon="fas fa-eye"
                    tooltip="View details"
                    onClick={(e) => this.viewDialog(rowData)}
                />
            </div>
        );
    }

    viewDialog = (rowData) => {
        this.getApplicantInfoDetails(rowData.registrationId);
    }

    getApplicantInfoDetails(regNo) {

        this.setState({ topProgressBar: true, searchErrorMsgBody: '', searchViewError: false, errorMsgVisible: false });
        this.DwsService.getAdmisiaApplicantInfoByRegId(cmsId, parseInt(regNo))
            .then(res => {

                this.setState({ topProgressBar: false });
                this.setState({ viewDialogVisible: true });

                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('search-body', body.item);
                        if (body.messageType == 1) {
                            this.setState({ selectedViewRowData: body.item, searchViewError: false, errorMsgVisible: false });
                        } else {
                            this.setState({ searchErrorMsgBody: body.message, errorMsgVisible: false, searchViewError: true })
                        }
                    }))
                }

            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, searchViewError: false, errorMsgBody: "Please check your connection." })
            })

    }
    onHideViewDialog = () => { this.setState({ viewDialogVisible: false, selectedViewRowData: '' }); }

    photoColumnBody = (rowData) => {

        return (
            <div className="p-grid">
                <div className="p-col-4 p-col-nogutter" style={{ maxWidth: "78px", width: 'auto' }}>
                    {
                        rowData.fileContent ?
                            <img src={("data:image/*;base64," + rowData.fileContent)} width="100%" /> :
                            <img src={staticImg} width="100%" />
                    }
                </div>
            </div>
        )
    }

    applicantInfoBody = (rowData) => {

        return (
            <div className="p-grid">
                <div className="p-col-12 nw-datatable-dataview-small-panel">

                    <div>
                        <div>Registration No.</div>
                        <div>:</div>
                        <div>{rowData.registrationId}</div>
                    </div>

                    <div>
                        <div>Applicant Name</div>
                        <div>:</div>
                        <div>{rowData.applicantName}</div>
                    </div>

                    <div>
                        <div>Class</div>
                        <div>:</div>
                        <div>{rowData.className}</div>
                    </div>

                    <div>
                        <div>Group</div>
                        <div>:</div>
                        <div>{rowData.groupName}</div>
                    </div>

                    <div>
                        <div>Date of Birth</div>
                        <div>:</div>
                        <div>{rowData.dob}</div>
                    </div>

                    <div>
                        <div>Contact No.</div>
                        <div>:</div>
                        <div>{rowData.mobile}</div>
                    </div>

                </div>

            </div>
        )
    }

    applicantStatusBody = (rowData) => {
        return <span> {
            rowData.applicantStatus === 0 ? 'PENDING ASSESSMENT'
                :
                rowData.applicantStatus === 1 ? 'PENDING ADMISSION'
                    : rowData.applicantStatus === 2 ? 'REJECTED ASSESSMENT'
                        : rowData.applicantStatus === 3 ? 'REJECTED ADMISSION'
                            : rowData.applicantStatus === 4 ? 'WAITING ADMISSION'
                                : rowData.applicantStatus === 5 ? 'APPROVED ADMISSION'
                                    : rowData.applicantStatus == 10 ? 'TRANSFERRED ADMISSION' : ''
        }
        </span>
    }

    exportPdf = () => {

        let tableColumns = [
            { title: "Photo", dataKey: "fileContent" },
            { title: "Registration No.", dataKey: "registrationId" },
            { title: "Applicant Name", dataKey: "applicantName" },
            { title: "Class", dataKey: "className" },
            { title: "Group", dataKey: "groupName" },
            { title: "Date of Birth", dataKey: "dob" },
            { title: "Contact No.", dataKey: "mobile" },
        ];

        const tdColumnStyle = {};

        pdfDownloadLandscape_TableWithSubTitle("At a Glance Report of Admisia", 98, pdfTitleHeader, pdfTitleHeaderData, tableColumns, this.state.dataTableValue, tdColumnStyle, "at_a_glance_report.pdf");

    }

    render() {

        let { dataTableValue, selectedViewRowData, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">At a Glance of Applicants</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>;

        let currentYear = new Date().getFullYear();
        let academicYearOptions = [
            { label: currentYear + 1, value: currentYear + 1 },
            { label: currentYear, value: currentYear },
            { label: currentYear - 1, value: currentYear - 1 },
            { label: currentYear - 2, value: currentYear - 2 },
            { label: currentYear - 3, value: currentYear - 3 },
            { label: currentYear - 4, value: currentYear - 4 },
        ];

        let applicantPersonalViewResponse = '';
        let applicantPrevExamInfo = '';

        if (selectedViewRowData) {
            applicantPersonalViewResponse = selectedViewRowData.applicantPersonalViewResponse;
            applicantPrevExamInfo = selectedViewRowData.applicantPreviousExamViewResponses;
        }

        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
        ];
        pdfTitleHeaderData = [
            { A: "Year", B: this.state.academicYear }
        ];

        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    <div className="p-col-12 p-xl-12">
                        <Growl ref={(el) => this.growl = el} />

                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-9">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Academic year <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Academic Year"
                                            value={this.state.academicYear}
                                            options={academicYearOptions}
                                            onChange={(e) => this.onChageInputValue(e)}
                                            showClear={true}
                                            autoWidth={false}
                                            name="academicYear"
                                        />
                                    </div>
                                }
                                <span className="error-message">{errors["academicYear"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-2 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onSearchHandler}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?

                                this.NetiContentLoader.MyPointTableLoader()

                                :
                                <DataTable
                                    header={tableHeader}
                                    value={dataTableValue}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column field="" header="Photo" filter={true} body={this.photoColumnBody} style={{ width: '130px' }} />
                                    <Column field="registrationId" filterBy header="Applicant Info" filter={true} body={this.applicantInfoBody} />
                                    <Column field="applicantStatus" filterBy header="Applicant Status" filter={true} body={this.applicantStatusBody} style={{ width: '130px' }} />
                                    <Column field="" header="Show Details" filter={true} body={this.bodyActionTemplate} style={{ width: '120px' }} />
                                </DataTable>
                            }

                        </div>
                    </div>

                    <div className="col-12   p-xl-12 nw-button-parent">
                        <Button
                            style={{ float: 'right' }}
                            label="Download PDF"
                            className="p-button p-button-primary nw-button nw-button-right"
                            icon="fas fa-arrow-down"
                            onClick={this.exportPdf}
                        />
                    </div>

                </div>

                <Dialog
                    className="nw-dialog"
                    header='Applicant At a Glance'
                    visible={this.state.viewDialogVisible}
                    onHide={this.onHideViewDialog}
                    maximizable
                    closable
                >

                    {this.state.viewDialogVisible ?
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <Growl ref={(el) => this.growl = el} />
                                <div className="p-col-12 p-xl-12 p-col-nogutter seprator">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-form-body">
                                            <div className="p-grid">

                                                <div className="p-col-12 p-lg-12 p-xl-12 p-t-0">

                                                    <div class="nw-search-view no-border">
                                                        <div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Student Image</span>
                                                                    <span className="p-inputgroup-colon">:</span>

                                                                    <div style={{ paddingLeft: '22px' }}>
                                                                        {
                                                                            applicantPersonalViewResponse && applicantPersonalViewResponse.fileContent ?
                                                                                <img src={("data:image/*;base64," + applicantPersonalViewResponse.fileContent)} height='80px' width='80px' /> :
                                                                                <img src={staticImg} height='80px' width='80px' />
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Student Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{applicantPersonalViewResponse && applicantPersonalViewResponse.applicantName ? applicantPersonalViewResponse.applicantName : '-'}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon"> Gender </span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{applicantPersonalViewResponse && applicantPersonalViewResponse.gender ? applicantPersonalViewResponse.gender : '-'}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Religion</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{applicantPersonalViewResponse && applicantPersonalViewResponse.religion ? applicantPersonalViewResponse.religion : '-'}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Date of Birth</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc"> {applicantPersonalViewResponse && applicantPersonalViewResponse.dob ? applicantPersonalViewResponse.dob : '-'} </div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Father's Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{applicantPersonalViewResponse && applicantPersonalViewResponse.fatherName ? applicantPersonalViewResponse.fatherName : '-'}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Father's Occupation</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{applicantPersonalViewResponse && applicantPersonalViewResponse.fatherOccupation ? applicantPersonalViewResponse.fatherOccupation : '-'}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Mother's Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{applicantPersonalViewResponse && applicantPersonalViewResponse.motherName ? applicantPersonalViewResponse.motherName : '-'}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Mother's Occupation</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{applicantPersonalViewResponse && applicantPersonalViewResponse.motherOccupation ? applicantPersonalViewResponse.motherOccupation : '-'}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Guardian Mobile No. </span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{applicantPersonalViewResponse && applicantPersonalViewResponse.mobileNo ? applicantPersonalViewResponse.mobileNo : '-'}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Address</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{applicantPersonalViewResponse && applicantPersonalViewResponse.addressDetails ? applicantPersonalViewResponse.addressDetails : '-'} </div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Admission Status</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {
                                                                            applicantPersonalViewResponse && applicantPersonalViewResponse.applicantStatus === 0 ? 'PENDING ASSESSMENT'
                                                                                :
                                                                                applicantPersonalViewResponse && applicantPersonalViewResponse.applicantStatus === 1 ? 'PENDING ADMISSION'
                                                                                    : applicantPersonalViewResponse && applicantPersonalViewResponse.applicantStatus === 2 ? 'REJECTED ASSESSMENT'
                                                                                        : applicantPersonalViewResponse && applicantPersonalViewResponse.applicantStatus === 3 ? 'REJECTED ADMISSION'
                                                                                            : applicantPersonalViewResponse && applicantPersonalViewResponse.applicantStatus === 4 ? 'WAITING ADMISSION'
                                                                                                : applicantPersonalViewResponse && applicantPersonalViewResponse.applicantStatus === 5 ? 'APPROVED ADMISSION'
                                                                                                    : applicantPersonalViewResponse && applicantPersonalViewResponse.applicantStatus == 10 ? 'TRANSFERRED ADMISSION' : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                            {applicantPrevExamInfo ? <ApplicantPrevExamInfo applicantPrevExamInfo={applicantPrevExamInfo} /> : ' NO PREVIOUS EXAM INFO FOUND'}
                        </div>

                        : ''}
                </Dialog>

            </div>

        )

    }
}