import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { ErrorMessageView } from '../../common/ErrorMessageView';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

let dialogType = '';
let dialogHeader = '';
let cmsId = 0;

export class AdminGroupInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            dataTableValue: [],
            dialogVisible: false,
            requestedBodyObj: {
                "cmsId": 0,
                "groupName": "",
                "groupSerial": 0
            },
            deleteDialogVisible: false,
            selectedRowData: '',

        }

        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });

            this.getGroupTableData();
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    getGroupTableData() {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.getGroupList(cmsId)
            .then(res => {

                // console.log('res-body', res);
                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('body', body);

                        if (body.messageType == 1) {
                            this.setState({ dataTableValue: body.item });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                        } else {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    }))
                }

            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });

    }

    onChangeToDialogVisible = (source) => {
        if (source == "insert") { dialogHeader = "Add Group Name"; dialogType = source; }
        else if (source == "update") { dialogHeader = "Update Group Name"; dialogType = source; }
        this.setState({ dialogVisible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    };

    onHideDialog = () => { this.setState({ dialogVisible: false }); this.resetDialogValue(); this.CommonFuctionality.blurDialogBackgroundDeActive(); }

    resetDialogValue = () => {

        let requestedBodyObj = {
            "cmsId": Number(cmsId),
            "groupName": "",
            "groupSerial": 0
        }

        this.setState({ errors: {} });
        this.setState({ requestedBodyObj: requestedBodyObj });

    }

    setRowDataToUpdateDialog = (rowData) => {

        let requestedBodyObj = {
            "groupId": rowData.groupId,
            "cmsId": Number(cmsId),
            "groupName": rowData.groupName,
            "groupSerial": rowData.groupSerial,

        }

        this.setState({ requestedBodyObj: requestedBodyObj });
        this.onChangeToDialogVisible('update')

    }

    showDeleteDialog = (dialogType, rowData) => {
        this.setState({ deleteDialogVisible: true, selectedRowData: rowData });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onHideDeleteDialog = () => {
        this.setState({ deleteDialogVisible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    bodyActionTemplate = (rowData) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                tooltip="Update"
                onClick={(e) => this.setRowDataToUpdateDialog(rowData, 'update')}
            >
            </Button>

            <Button
                type="button"
                icon="fas fa-trash"
                tooltip="Delete"
                className="nw-action-button p-button-danger"
                onClick={(e) => this.showDeleteDialog('delete', rowData)}
            />
        </div>;
    }

    onChageInputValue = (e) => {

        let { requestedBodyObj } = this.state;

        requestedBodyObj[e.target.name] = e.target.value;

        this.setState({ requestedBodyObj })
        this.clearErrorMsg(e.target.name);
    }

    clearErrorMsg = (name) => {
        let { errors } = this.state;
        errors[name] = '';
        this.setState({ errors })
    }

    onSubmitHandler = (dialogType) => {

        let { requestedBodyObj } = this.state;

        if (this.emptyHandleError()) {

            if (dialogType == 'insert') {

                requestedBodyObj.groupSerial = this.state.dataTableValue.length + 1;
                requestedBodyObj.cmsId = cmsId;
                let { errors } = this.state;
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.DwsService.cmsSaveGroup(requestedBodyObj)
                    .then(res => {
                        // console.log('save-res', res);

                        if (res.status == 201) {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                            this.onHideDialog();
                            this.getGroupTableData();
                            this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Group successfully saved' });
                        } else {
                            this.DwsService.Auth.handleErrorStatus(res)
                                .then((res) => {
                                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: res })
                                });
                        }
                    })
                    .catch(error => {
                        this.setState({ errors: errors })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });

            } else if (dialogType == 'update') {

                // console.log('update obj', requestedBodyObj);

                let { errors } = this.state;
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.DwsService.cmsUpdateGroup(requestedBodyObj)
                    .then(res => {
                        console.log('update-res', res);
                        if (res.status == 201) {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                            this.onHideDialog();
                            this.getGroupTableData();
                            this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Group Successfully Updated' });
                        }

                    })
                    .catch(error => {
                        this.setState({ errors: errors })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });
            }
        }

    }

    emptyHandleError = () => {

        let { requestedBodyObj, errors } = this.state;
        let formIsValid = true;

        if (requestedBodyObj.groupName === '' || requestedBodyObj.groupName === null) {
            formIsValid = false;
            errors["groupName"] = "Group Name can't left empty.";
        }

        if (dialogType === 'update') {

            if (requestedBodyObj.groupSerial === '' || requestedBodyObj.groupSerial === null) {
                formIsValid = false;
                errors["groupSerial"] = "Serial No. can't left empty.";
            }

        }

        this.setState({ errors });
        return formIsValid;

    }

    deleteRow = () => {

        let { errors, selectedRowData } = this.state;
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.DwsService.cmsDeleteGroup(selectedRowData.groupId)
            .then(res => {

                // console.log('delete-res', res);

                if (res.status == 200) {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    this.onHideDeleteDialog();
                    this.getGroupTableData();
                    this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Group successfully deleted.' });
                }
                else if (res.status == 500) {
                    this.onHideDeleteDialog();
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Unable to delete: Group already used in application" })
                }
                else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((res) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: res })
                        });
                }
            })
            .catch(error => {
                this.setState({ errors: errors })
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    render() {

        let { requestedBodyObj, dataTableValue, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Group List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
                <Button
                    className="nw-button nw-button-center p-button-success"
                    name="district"
                    label="Add New"
                    icon="fas fa-plus"
                    iconPos="right"
                    onClick={() => this.onChangeToDialogVisible('insert')}
                />
            </div>
        </div>;

        return (

            <div className="p-fluid">
                <NetiCMSSetupProgress />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section blur-section">
                    {this.state.homeReturnButton === false ?

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column field="groupSerial" header="Serial No." filter={true} />
                                    <Column field="groupName" header="Group Name" filter={true} />
                                    <Column field="action" header="Action" body={this.bodyActionTemplate} />
                                </DataTable>

                                : this.NetiContentLoader.MyPointTableLoader()
                            }

                        </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        header={dialogHeader}
                        visible={this.state.dialogVisible}
                        onHide={this.onHideDialog}
                        closable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>

                                                {dialogType == "insert" ?
                                                    <div className="nw-inputgroup-desc">
                                                        {requestedBodyObj.groupSerial || dataTableValue.length + 1}
                                                    </div>
                                                    :
                                                    <InputText
                                                        placeholder="Enter Serial no."
                                                        name="groupSerial"
                                                        value={requestedBodyObj.groupSerial}
                                                        onChange={e => this.onChageInputValue(e)}
                                                    />
                                                }
                                            </div>
                                            {dialogType == "update" ? <span className="error-message">{errors["groupSerial"]}</span> : ''}

                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Group Name<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Group Name"
                                                    name="groupName"
                                                    value={requestedBodyObj.groupName}
                                                    onChange={e => this.onChageInputValue(e)}
                                                />
                                            </div>
                                            <span className="error-message">{errors["groupName"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">(<span>*</span>) required fields</div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                label={dialogType == "insert" ? "Save" : "Update"}
                                                icon="fas fa-check"
                                                onClick={() => this.onSubmitHandler(dialogType)}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>

                <Dialog header="Delete Group" visible={this.state.deleteDialogVisible} onHide={this.onHideDeleteDialog} className="nw-confirm-dialog">
                    <div className="p-fluid">
                        <div className="p-col-12 p-col-12">
                            <div className="confirm-wrapper">

                                <h1>Are You Sure?</h1>
                                <div className="nw-button-parent-multiple">
                                    <Button
                                        className="p-button-danger nw-button nw-button-multiple"
                                        label="No"
                                        icon="fas fa-times"
                                        onClick={this.onHideDeleteDialog}
                                    />
                                    <Button
                                        className="p-button-primary nw-button nw-button-multiple"
                                        label="Yes"
                                        icon="fas fa-check"
                                        onClick={(e) => this.deleteRow()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

            </div>

        )
    }
}