import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { SeatInfoList } from "./SeatInfoList";
import { UserCategoryService } from "../../../service/UserCategoryService";
import { DwsService } from "../../../service/dwsPoint/DwsService";
import NetiContentLoader from "../../common/NetiContentLoader";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { Link } from "react-router-dom";
import { NetiCMSSetupProgress } from "../common/NetiCMSSetupProgress";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import CommonFuctionality from "../../common/CommonFuctionality";

let dialogStatus = false;
let errors = {};
let dropDownListName1 = "";
let dropDownListName2 = "";
let cloneStateBeforeMount;
let urlId;

export class AdminFeesInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogFormType: false,
      cmsId: "",
      feeSerial: "",
      classId: "",
      feeId: "",
      groupId: "",
      feeName: "",
      feeDetails: "",
      feeAmount: "",
      feeType: "",
      feePaymentMode: "",
      classDropDownList: [],
      groupDropDownList: [],
      serialNumber: "",
      dataTableValue: [],
      coreClassCategoryID: "",
      coreGroupCategoryID: "",
      totalSeat: "",
      errors: {},
      homeReturnButton: true,
      dataTableIsLoading: false,
      firstDropDownIsLoading: false,
      secondDropDownIsLoading: false,
      totalDataFound: 0,

      deleteDialogVisible: false,
      selectedRowData: "",
    };

    this.UserCategoryService = new UserCategoryService();
    this.DwsService = new DwsService();
    this.NetiContentLoader = new NetiContentLoader();
    this.CommonFuctionality = new CommonFuctionality();

    this.reloadCoreUrlIdFromLocal();
  }

  async reloadCoreUrlIdFromLocal() {
    urlId = await this.DwsService.getCmsIdFromLocalStorage();
    console.log("urlId .>", urlId);

    if (typeof urlId === "string" || typeof urlId === "number") {
      this.setState({ cmsId: urlId });
      this.setState({ homeReturnButton: false });
      this.getTableData();

      this.getClassOrGroupListByDefaultCode("class");
      this.getClassOrGroupListByDefaultCode("group");
    } else {
      this.setState({ homeReturnButton: true });
    }
  }

  getTableData = () => {
    this.setState({
      firstDropDownIsLoading: true,
      secondDropDownIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });

    this.DwsService.getCmsFeesInfoList(this.state.cmsId)
      .then((res) => {
        console.log("res-list", res);

        if (res.status == 200) {
          return res.json().then((body) => {
            console.log("table-list", body.item);

            // this.props.countTableSize(body.length);
            this.setState({ dataTableValue: body.item });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.DwsService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection.",
        });
      });
  };

  getClassOrGroupListByDefaultCode(type) {
    this.setState({ error: {} });
    this.setState({
      firstDropDownIsLoading: true,
      secondDropDownIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });

    this.DwsService.cmsClassOrGroupList(type, urlId)
      .then((res) => {
        if (res.status == 200) {
          return res.json().then((body) => {
            if (type === "class") {
              this.setState({ classDropDownList: body.item });
            } else if (type === "group") {
              this.setState({ groupDropDownList: body.item });
            }
            errors["classError"] = "";
            errors["groupError"] = "";
            errors[dropDownListName1] = "";
            this.setState({ errors: errors });
            this.setState({
              firstDropDownIsLoading: false,
              secondDropDownIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.UserCategoryService.Auth.handleErrorStatus(res).then((resp) => {
            errors[dropDownListName1] = resp.message;
            this.setState({
              firstDropDownIsLoading: false,
              secondDropDownIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        errors["classError"] = "Class List Not Found";
        errors["groupError"] = "Group List Not Found";
        errors[dropDownListName1] = "Connection Problem";
        this.setState({ errors: errors });
        this.setState({
          firstDropDownIsLoading: false,
          secondDropDownIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  }

  onChangeClass = (e) => {
    errors = {};
    this.setState({ errors: errors });

    this.setState({ classId: e.target.value });
  };

  onChangeGroup = (e) => {
    errors = {};
    this.setState({ groupId: e.target.value });
    this.setState({ errors: errors });
  };

  onChangeFeeName = (e) => {
    errors = {};
    this.setState({ errors: errors });
    this.setState({ feeName: e.target.value });
  };

  onChangeFeeDetails = (e) => {
    errors = {};
    this.setState({ errors: errors });
    this.setState({ feeDetails: e.htmlValue });
  };

  onChangeFeeAmount = (e) => {
    errors = {};
    this.setState({ errors: errors });
    this.setState({ feeAmount: e.target.value });
  };

  onChangeFeeType = (e) => {
    errors = {};
    this.setState({ errors: errors });
    this.setState({ feeType: e.target.value });
  };

  onChangeFeePaymentMode = (e) => {
    errors = {};
    this.setState({ errors: errors });
    this.setState({ feePaymentMode: e.target.value });
  };

  onSubmitFeeInfo = () => {
    let { errors } = this.state;

    let feesInfoObj = {
      classId: this.state.classId,
      cmsId: this.state.cmsId,
      feeAmount: this.state.feeAmount,
      feeName: this.state.feeName,
      feePaymentMode: this.state.feePaymentMode,
      feeSerial: this.state.dataTableValue.length + 1,
      feeType: this.state.feeType,
      groupId: this.state.groupId,
    };
    if (this.handleError()) {
      this.DwsService.cmsSaveFeesInfo(feesInfoObj)
        .then((res) => {
          console.log("fees info res", res);
          return res.json();
        })
        .then((body) => {
          if (body.messageType == 1) {
            console.log("body.message", body.message);
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
            this.growl.show({
              life: 800,
              severity: "success",
              summary: "Success",
              detail: body.message,
            });
            this.getTableData();
            this.resetSaveAndUpdateForm();
            setTimeout(() => {
              this.onHide();
            }, 1000);
          } else {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: body.message,
            });
          }
        })
        .catch((error) => {
          this.setState({ errors: errors });
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection",
          });
        });
    }
  };

  onUpdateFeeInfo = () => {
    let { errors } = this.state;

    let feesInfoObj = {
      classId: this.state.classId,
      feeId: this.state.feeId,
      cmsId: this.state.cmsId,
      feeAmount: this.state.feeAmount,
      feeName: this.state.feeName,
      feePaymentMode: this.state.feePaymentMode,
      feeSerial: this.state.feeSerial,
      feeType: this.state.feeType,
      groupId: this.state.groupId,
    };
    //   if(this.handleError()) {

    this.DwsService.cmsUpdateFeesInfo(feesInfoObj)
      .then((res) => {
        console.log("fees info res", res);
        return res.json();
      })
      .then((body) => {
        if (body.messageType == 1) {
          console.log("body.message", body.message);
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
          });
          this.growl.show({
            life: 800,
            severity: "success",
            summary: "Success",
            detail: body.message,
          });
          this.getTableData();
          setTimeout(() => {
            this.onHide();
          }, 1000);
        } else {
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: body.message,
          });
        }
      })
      .catch((error) => {
        this.setState({ errors: errors });
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
    // }
  };

  handleError = () => {
    let formIsValid = true;
    // let { errors} = this.state;

    if (this.state.classId === "" || this.state.classId === null) {
      formIsValid = false;
      errors["className"] = "Class can't left empty.";
    }
    if (this.state.groupId === "" || this.state.groupId === null) {
      formIsValid = false;
      errors["groupName"] = "Group can't left empty.";
    }
    if (this.state.feeName === "") {
      formIsValid = false;
      errors["feeName"] = "Fee Name can't left empty.";
    }
    // if (this.state.feeDetails === '') {
    //     formIsValid = false;
    //     errors["feeDetails"] = "Details can't left empty.";
    // }
    if (this.state.feeAmount === "") {
      formIsValid = false;
      errors["feeAmount"] = "Fee Amount can't left empty.";
    }
    if (this.state.feePaymentMode === "") {
      formIsValid = false;
      errors["feePaymentMode"] = "Payment Mode can't left empty.";
    }

    if (this.state.feeType === "" || this.state.feeType === null) {
      formIsValid = false;
      errors["feeType"] = "Fee Type can't left empty.";
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  showFormDialog = (e, rowData, dialogType) => {
    if (dialogType === "update") {
      this.setState({
        feeSerial: rowData.feeSerial,
        feeId: rowData.feeId,
        classId: rowData.classId,
        groupId: rowData.groupId,
        feeName: rowData.feeName,
        feeAmount: rowData.feeAmount,
        feeType: rowData.feeType,
        feePaymentMode: rowData.feePaymentMode,
      });
      this.showMainForm();

      this.setState({ dialogFormType: false });
    } else if (dialogType === "save") {
      this.resetSaveAndUpdateForm();
      this.showMainForm();

      this.setState({ dialogFormType: true });
    }
  };

  deleteRow = () => {
    let { errors, selectedRowData } = this.state;
    console.log("delete fee id", selectedRowData);

    this.setState({
      dataTableIsLoading: false,
      topProgressBar: true,
      errorMsgVisible: false,
    });

    this.DwsService.cmsDeleteFeesInfo(selectedRowData.feeId)
      .then((res) => {
        // console.log('dress code delete info res', res)
        return res.json();
      })
      .then((body) => {
        console.log("body", body);

        if (body.messageType == 1) {
          console.log("body.message", body.message);
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
          });
          this.onHideDeleteDialog();
          this.growl.show({
            life: 800,
            severity: "success",
            summary: "Success",
            detail: body.message,
          });
          this.getTableData();
        } else {
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: body.message,
          });
        }
      })
      .catch((error) => {
        this.setState({ errors: errors });
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  };

  resetSaveAndUpdateForm = () => {
    this.setState({
      feeSerial: "",
      classId: "",
      groupId: "",
      feeName: "",
      feeDetails: "",
      feeAmount: "",
      feeType: "",
      feePaymentMode: "",
    });
  };

  showMainForm = () => {
    this.setState({ visible: true });
  };

  onHide = () => {
    this.setState({ visible: false });
    this.resetSaveAndUpdateForm();
  };

  showDeleteDialog = (rowData) => {
    this.setState({ deleteDialogVisible: true, selectedRowData: rowData });
    this.CommonFuctionality.blurDialogBackgroundActive();
  };

  onHideDeleteDialog = () => {
    this.setState({ deleteDialogVisible: false });
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  };

  onChageInputValue = (e) => {
    this.setState({
      ...this.state,
      feeSerial: e.target.value,
    });

    // this.onClearErrorMsg(e.target.name);
  };

  editBody = (rowData) => {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button"
          icon="fas fa-pencil-alt"
          title="Update"
          onClick={(e) => this.showFormDialog(e, rowData, "update")}
          tooltip="Update"
          tooltipOptions={{ position: "top" }}
        />
        <Button
          type="button"
          icon="fas fa-trash"
          className="nw-action-button p-button-danger"
          onClick={(e) => this.showDeleteDialog(rowData)}
          // onClick={ e => this.deleteRow(rowData.feeId)}
          tooltip="Delete"
          tooltipOptions={{ position: "top" }}
        />
      </div>
    );
  };

  render() {
    let {
      dataTableValue,
      topProgressBar,
      errorMsgVisible,
      errorMsgBody,
      errors,
    } = this.state;
    console.log("this.state.feeSerial", this.state.feeSerial);

    let classList = [];
    if (
      this.state.classDropDownList != null &&
      this.state.classDropDownList.length > 0
    ) {
      classList = this.state.classDropDownList.map((item) => ({
        value: item.classId,
        label: item.className,
      }));
    }

    let groupList = [];
    if (
      this.state.groupDropDownList != null &&
      this.state.groupDropDownList.length > 0
    ) {
      groupList = this.state.groupDropDownList.map((item) => ({
        value: item.groupId,
        label: item.groupName,
      }));
    }
    let feeTypeList = [
      { label: "Yearly", value: "Yearly" },
      { label: "Monthly", value: "Monthly" },
      { label: "Exam", value: "Exam" },
    ];

    let tableHeader = (
      <div className="header-title">
        <div className="header-title-left">Fees Info List</div>
        <div className="header-title-right">
          <a>Total Found: {dataTableValue.length.toLocaleString("EN-IN")}</a>

          <Button
            className="nw-button nw-button-center p-button-success"
            name="district"
            label="Add New"
            icon="fas fa-plus"
            iconPos="right"
            onClick={(e) => this.showFormDialog(e, null, "save")}
          />
        </div>
      </div>
    );

    return (
      <div className="p-fluid">
        <NetiCMSSetupProgress />

        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <Growl ref={(el) => (this.growl = el)} />

        <div className="main-section">
          {this.state.homeReturnButton === false ? (
            <div className="nw-data-table">
              {!this.state.dataTableIsLoading ? (
                <DataTable
                  value={this.state.dataTableValue}
                  selectionMode="single"
                  header={tableHeader}
                  responsive={true}
                  rows={10}
                  paginator={true}
                  columnResizeMode="fit"
                >
                  <Column field="feeSerial" header="Serial No." filter={true} />
                  <Column field="className" header="Class" filter={true} />
                  <Column field="groupName" header="Group" filter={true} />
                  <Column field="feeName" header="Fee Name" filter={true} />
                  <Column field="feeAmount" header="Fee Amount" filter={true} />
                  <Column field="feeType" header="Fee Type" filter={true} />
                  <Column
                    field="action"
                    header="Action"
                    body={this.editBody}
                    style={{ textAlign: "center", width: "8em" }}
                  />
                </DataTable>
              ) : (
                this.NetiContentLoader.MyPointTableLoader()
              )}
            </div>
          ) : (
            <div className="">
              <div className="p-grid">
                <div className="card p-col-12 p-xl-3">
                  <center className="custom-center">
                    <Link
                      to="/home"
                      className="rainbow-button"
                      alt="Go DWS Home"
                    />
                  </center>
                </div>
                <div className="card p-col-12 p-xl-3">
                  <center className="custom-center">
                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                  </center>
                </div>
                <div className="card p-col-12 p-xl-6">
                  <img src="assets/images/dws_home.png" width="100%" />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="dialog-section">
          <Dialog
            className="nw-dialog"
            header={`${
              this.state.dialogFormType === true ? "Add" : "Update"
            } Fees Information`}
            visible={this.state.visible}
            onHide={this.onHide}
            closable
          >
            <div className="p-fluid">
              <div className="p-grid nw-form">
                <div className="p-col-12 p-xl-12">
                  <div className="nw-form-body">
                    {!this.props.dialogFormType ? (
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Serial No. <span>*</span>
                          </span>
                          <InputText
                            placeholder="Enter Serial"
                            name="feeSerial"
                            value={this.state.feeSerial}
                            onChange={(e) => this.onChageInputValue(e)}
                          />
                        </div>
                        <span className="error-message">
                          {errors["feeSerial"]}
                        </span>
                      </div>
                    ) : (
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">Serial No.</span>
                          <div className="nw-inputgroup-desc">
                            {this.state.feeSerial
                              ? this.state.feeSerial
                              : this.state.dataTableValue.length + 1}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Class <span>*</span>
                        </span>
                        <Dropdown
                          placeholder="Select Class"
                          value={this.state.classId}
                          options={classList}
                          onChange={this.onChangeClass}
                          filter={true}
                          name="className"
                          filterBy="label,value"
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {errors["className"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Group <span>*</span>
                        </span>
                        <Dropdown
                          placeholder="Select Group"
                          value={this.state.groupId}
                          options={groupList}
                          onChange={this.onChangeGroup}
                          filter={true}
                          filterBy="label,value"
                          name="groupName"
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {errors["groupName"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Fee Name <span>*</span>
                        </span>
                        <InputText
                          placeholder="Enter fee name"
                          style={{ width: "100%" }}
                          type="text"
                          name="feeName"
                          onChange={this.onChangeFeeName}
                          value={this.state.feeName}
                        />
                      </div>
                      <span className="error-message">{errors["feeName"]}</span>
                    </div>

                    {
                      // this.state.dialogFormType === true ?
                      // <div className="p-col-12 p-xl-12">
                      //     <div className="p-inputgroup">
                      //         <span className="p-inputgroup-addon nw-inputtexteditor-label">
                      //             Details <span>*</span>
                      //         </span>
                      //         <Editor
                      //             id="feeDetails"
                      //             name="feeDetails"
                      //             placeholder="Enter fee Details"
                      //             value={this.state.feeDetails}
                      //             onTextChange={(e) => this.onChangeFeeDetails(e)}
                      //             autoWidth={false}
                      //             name='feeDetails'
                      //             maxLength={200}
                      //         />
                      //     </div>
                      //     <span className="error-message">
                      //         {this.state.errors["feeDetails"]}
                      //     </span>
                      // </div>
                      // : ''
                    }

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Fee Amount <span>*</span>
                        </span>
                        <InputText
                          keyfilter="pint"
                          placeholder="Enter fee amount"
                          style={{ width: "100%" }}
                          type="text"
                          name="feeAmount"
                          onChange={this.onChangeFeeAmount}
                          value={this.state.feeAmount}
                        />
                      </div>
                      <span className="error-message">
                        {errors["feeAmount"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Fee Type <span>*</span>
                        </span>
                        <Dropdown
                          placeholder="Enter Fee Type"
                          value={this.state.feeType}
                          options={feeTypeList}
                          name="feeType"
                          onChange={this.onChangeFeeType}
                          filter={true}
                          filterBy="label,value"
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">{errors["feeType"]}</span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Fee Payment Mode <span>*</span>
                        </span>
                        <InputText
                          placeholder="Enter Fee Payment Mode"
                          value={this.state.feePaymentMode}
                          style={{ width: "100%" }}
                          type="text"
                          name="feePaymentMode"
                          onChange={this.onChangeFeePaymentMode}
                        />
                      </div>
                      <span className="error-message">
                        {errors["feePaymentMode"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12" />

                    <div className="p-col-12 p-xl-12 nw-button-parent">
                      <div className="required-field">
                        (<span>*</span>) required fields
                      </div>

                      {this.state.dialogFormType === true ? (
                        <Button
                          className="p-button p-button-primary nw-button nw-button-left"
                          label="Save"
                          icon="fas fa-check"
                          onClick={this.onSubmitFeeInfo.bind(this)}
                        />
                      ) : (
                        ""
                      )}

                      {this.state.dialogFormType === false ? (
                        <Button
                          className="p-button p-button-primary nw-button nw-button-left"
                          label="Update"
                          icon="fas fa-check"
                          onClick={this.onUpdateFeeInfo.bind(this)}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>

          <Dialog
            header="Delete Fees Info"
            visible={this.state.deleteDialogVisible}
            onHide={this.onHideDeleteDialog}
            className="nw-confirm-dialog"
          >
            <div className="p-fluid">
              <div className="p-col-12 p-col-12">
                <div className="confirm-wrapper">
                  <h1>Are You Sure?</h1>
                  <div className="nw-button-parent-multiple">
                    <Button
                      className="p-button-danger nw-button nw-button-multiple"
                      label="No"
                      icon="fas fa-times"
                      onClick={this.onHideDeleteDialog}
                    />
                    <Button
                      className="p-button-primary nw-button nw-button-multiple"
                      label="Yes"
                      icon="fas fa-check"
                      onClick={(e) => this.deleteRow()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
