import { BaseURIHolder } from '../../utils/BaseURIHolder';
import UnAuthService from '../../auth/UnAuthService';

export class RegistrationService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.unAuthService = new UnAuthService();
    }

    checkUserName(username) {
        let uri = this.baseURIHolder.getGuestUser() + '/check-user?username=' + username;
        this.loadAddressList(101);
        return this.unAuthService.getFetch(uri)
            .then(res => {
                if (res.status === 302) {
                    return true;
                } else if (res.status === 404) {
                    return false;
                }
            })
            .catch((error) => console.log('error', error));
        //     let uri = this.baseURIHolder.getGuestUser() + '/check-user?username=' + username;
        // return fetch(uri, { method: 'GET' })
        //     .then((res) => {
        //         console.log('res', res);
        //         console.log('body', res.body);
        //         if (res.status == 302) {
        //             return true;
        //         } else if (res.status == 404) {
        //             return false;
        //         }
        //     })
        //     .catch((error) => console.log('error', error));

    }

    loadAddressList(typeDefCode) {
        let uri = this.baseURIHolder.getGuestCore() + '/addresses?typeDefCode=' + typeDefCode;
        return this.unAuthService.getFetch(uri)
            .then(res => {
                console.log('Addess', res)
                return res.json()
            }).then(res => console.log(res))
    }

    registerNewUser(userInfo) {
        let uri = this.baseURIHolder.getGuestUser() + '/register-user';
        return this.unAuthService.postFetch(uri,userInfo)
            .then((res) => {
                console.log('res', res);
                if (res.status === 201) {
                    return true;
                } else {
                    return res.json();
                }
            })
            .catch((error) => console.log('error', error));

            // return fetch(uri,
            //     {
            //         method: 'POST',
            //         headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
            //         body: JSON.stringify(userInfo)
            //     })
            //     .then((res) => {
            //         console.log('res', res);
            //         console.log('body', res.body);
            //         if (res.status == 302) {
            //             return true;
            //         } else if (res.status == 404) {
            //             return false;
            //         } else if (res.status == 400) {
            //             return false;
            //         } else if (res.status == 201) {
            //             return true;
            //         }
            //     })
            //     .catch((error) => console.log('error', error));
    }



    fetchDistrictList() {

        let uri = this.baseURIHolder.getGuest() + '/addresses/district';
        return this.unAuthService.getFetch(uri)
            .catch((error) => console.log('error', error));

    }

    fetchAreaList(districtId) {
        let uri = this.baseURIHolder.getGuest() + '/addresses/area?parentCategoryID=' + districtId;
        return this.unAuthService.getFetch(uri)
        .catch((error) => console.log('error', error));
    }
}