import { SA_POINT } from './PointWiseRouteConsts';

export const ObserverPointMenu = {

	items: [
		{ label: 'Observer point', icon: 'fab fa-atlassian',
		  items:[
			{
				label: 'Summary', //pi pi-fw pi-bookmark
				items: [

					{ label: 'Student', icon: '', to: SA_POINT.STUDENT_SUMMARY }, //pi pi-fw pi-money-bill

					{ label: 'HR Management', icon: '', to: SA_POINT.STAFF_SUMMARY }, //pi pi-fw pi-money-bill

					{ label: 'Student Attendance', icon: '', to: SA_POINT.STUDENT_ATTENDANCE_SUMMARY }, //pi pi-fw pi-money-bill

					{ label: 'HR Attendance', icon: '', to: SA_POINT.STAFF_ATTENDANCE_SUMMARY }, //pi pi-fw pi-money-bill

					{ label: 'Student Accounts', icon: '', to: SA_POINT.FEE_COLLECTION_SUMMARY }, //pi pi-fw pi-money-bill

					{ label: 'General Accounts', icon: '', to: SA_POINT.CASH_FLOW_SUMMARY }, //pi pi-fw pi-money-bill
					
					{ label: 'Messaging', icon: '', to: SA_POINT.MESSAGING_INFO }, //pi pi-fw pi-money-bill	//OBSERVER_DISTRICT_WISE_ATTENDANCE
				
					{ label: 'Attendance', icon: '', to: SA_POINT.TEACHER_ATTENDENCE_INFO }, //pi pi-fw pi-money-bill	//OBSERVER_DIVISION_WISE_ATTENDANCE

					// { label: 'District Wise Attendance', icon: '', to: SA_POINT.OBSERVER_DISTRICT_WISE_ATTENDANCE }, //pi pi-fw pi-money-bill	//OBSERVER_DISTRICT_WISE_ATTENDANCE
					
					// { label: 'Institute Wise Attendance', icon: '', to: SA_POINT.OBSERVER_INSTITUTE_WISE_ATTENDANCE }, //pi pi-fw pi-money-bill	//OBSERVER_DISTRICT_WISE_ATTENDANCE
	

				]
			
			},

		  ]
		
		}
	]
}
            