import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProfileService } from '../../../service/profile/ProfileService'; 
import {Dialog} from 'primereact/dialog';
import {Growl} from 'primereact/growl';
import {updateprofileform, UpdateProfileForm} from './UpdateProfileForm';

export class UpdateProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            passwordIcon: true,
            passwordHideOrShow: true,
            passwordSet: '',
            changePassword: {
                passwordMatch: '12',
                emptyPassword: false,
            },
            passwordDialogView: true,
            passwordError:{}
        }
        this.profileService = new ProfileService();

        
        this.showWarn = this.showWarn.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.passwordOnSubmit = this.passwordOnSubmit.bind(this);
        this.dialogOnHide = this.dialogOnHide.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {

        // setTimeout(function() { //Start the timer
        //     this.setState({renderLogoutDialog: false}) //After 1 second, set render to true
        // }.bind(this), 5000)
            
    }

    onHide(event) {
        window.location.replace('/');
    }

    dialogOnHide(event) {
       
    }

    onChangeHandlePassword (event, props){
        this.setState({
            passwordSet: event.target.value
        })
    }

    togglePasswordShow(event, props) {
        this.setState({
            passwordHideOrShow: !this.state.passwordHideOrShow,
            passwordIcon: !this.state.passwordIcon
        });

    }

    passwordIsMatch(){
        return true;
    }

    passwordOnSubmit() {
        if (this.passwordrawHandleError()) {

            if (this.state.passwordSet != '') {

                let userPasswordInfo = {
                    password: this.state.passwordSet
                }

                this.profileService.checkPasswordForUpdate(userPasswordInfo)
                    .then((res) => {
                        if (res.status === 200) {
                            return res.json().then((body) => {

                                if (body === false) {
                                    this.setState({ errorMSG: "Please check password again" })
                                    this.showWarn(this.state.errorMSG)
                                }
                                else {
                                    this.setState({
                                        visible: false,
                                        updateprofileform: true,
                                        passwordDialogView: false,
                                        errorMSG: ''
                                    })

                                    this.passwordIsMatch();
                                }
                            });
                        }
                        else {
                            return res.json().then((body) => {
                                this.setState({ errorMSG: body.message })
                                this.showWarn(this.state.errorMSG)
                            });
                        }
                    }).catch((error) => console.log('error', error));
            }
        }
    }

    showWarn(msg) {
        this.growl.show({severity: 'error', summary: 'Error Message', detail: msg});
    }
    showSuccess(msg) {
        this.growl.show({severity: 'success', summary: 'Success Message', detail: msg});
    }

    passwordrawHandleError = () => {
        let passwordError = {};
        let formIsValid = true;
        if (this.state.passwordSet === '') {
            formIsValid = false;
            passwordError["passwordCheck"] = "Password doesn't left empty";
		}

        this.setState({ passwordError: passwordError });
        return formIsValid;
    }

    render(){
        return(
            <div>
                <div className="p-grid">

                    <Growl ref={(el) => this.growl = el} />
                    <div>
                        <Dialog 
                            header="Enter Password" 
                            className="nonHideDialog" 
                            onHide={this.onHide}
                            visible={this.state.passwordDialogView}
                        >
                            <p>Please enter your password for <b>update</b></p>
                            <br />
                            <div className="formControl">
                                <div className="p-inputgroup">
                                    <InputText
                                        type={this.state.passwordHideOrShow ? "password" : "text"}
                                        name="passwordSet"
                                        value={this.state.passwordSet}
                                        className="custom-field-control"
                                        placeholder="Enter Password"
                                        onChange={this.onChangeHandlePassword.bind(this)}
                                        required="required"
                                        keyfilter="alphanum"
                                    />
                                    <Button
                                        icon={this.state.passwordIcon ? "far fa-eye-slash" : "far fa-eye"}
                                        className="p-button-secondary-custom"
                                        onClick={(event) => this.togglePasswordShow(event, this.props)} />
                                </div>
                                <span className="error-message">{this.state.passwordError["passwordCheck"]}</span>
                            </div>
                            <br />
                            <Button onClick={this.passwordOnSubmit} label="Next" className="pull-right" />
                        </Dialog>
                    </div>        
                </div>
                {this.state.passwordDialogView === true ? '' : <UpdateProfileForm userPassword={this.state.passwordSet}/>}
            </div>
        )
    }
}