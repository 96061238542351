import React, { Component } from 'react';
import { CarService } from '../../service/CarService';
import { Button } from 'primereact/components/button/Button';
import AuthService from '../../auth/AuthService';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
// import { profileImage } from '../../src/assets/images/profile-image-1.png';

export class DwsDashboard extends Component {

    constructor() {
        super();
        this.state = {
        };
        this.onTaskChange = this.onTaskChange.bind(this);
        this.logoTemplate = this.logoTemplate.bind(this);
        this.scrollLeftBtn = this.scrollLeftBtn.bind(this);
        this.scrollRightBtn = this.scrollRightBtn.bind(this);
        this.carservice = new CarService();
        this.AuthService = new AuthService();
        this.netiFileHandler = new NetiFileHandler();
    }



    onTaskChange(e) {
        let selectedTasks = [...this.state.tasks];
        if (e.checked)
            selectedTasks.push(e.value);
        else
            selectedTasks.splice(selectedTasks.indexOf(e.value), 1);

        this.setState({ tasks: selectedTasks });
    }

    async componentDidMount() {
        this.carservice.getCarsMedium().then(data => this.setState({ cars: data }));
        const profile = this.AuthService.getProfile();

    }

    scrollLeftBtn() {
        document.getElementById('scrollableFlexWrapper').scrollLeft -= 185;
    }

    scrollRightBtn() {
        document.getElementById('scrollableFlexWrapper').scrollLeft += 185;
    }

    actionTemplate() {
        return <div className="p-grid">
            <div className="p-col-6">
                <Button type="button" icon="pi pi-search" className="p-button-info" style={{ marginRight: '.5em' }}></Button>
            </div>
            <div className="p-col-6">
                <Button type="button" icon="pi pi-times" className="p-button-danger"></Button>
            </div>
        </div>;
    }

    logoTemplate(rowData, column) {
        var src = "assets/demo/images/car/" + rowData.brand + ".png";
        return <img src={src} alt={rowData.brand} width="50px" />;
    }

    render() {
        const { realimg, resizeImg } = this.state;
        let tokenHeader = (
            <div className="taken">Token Taken <span>(1)</span></div>
        );
        let pendingHeader = (
            <div className="pending">Pending <span>(1)</span></div>
        );
        let rejectedHeader = (
            <div className="rejected">Rejected <span>(1)</span></div>
        );
        let solvedHeader = (
            <div className="solved">Solved <span>(1)</span></div>
        );
        return <div className="main-section netiWorld-DashBoard-wrapper">
            <div className="netiWorld-DashBoard">
                <div className="p-grid m-0">
                    <div class="p-col-12 p-md-12 p-xl-9 seprator-inside">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                <div className="notify-box-wrapper gradient-color-light-blue-2">
                                    <div className="notify-box-wrapper-bg-image boxHeight-284">
                                        <div className="notify-box clearfix">
                                            <div className="notify-title-position-l-b">
                                                <div className="notify-box-title">
                                                    <div className="notify-box-icon arcrive-icon"></div>
                                                    <h1 className="font-16 color-white">Total Active Menu</h1>
                                                    <hr />
                                                    <div className="notify-currency">
                                                        <p className="font-20 color-white arrow-sign">6</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                <div className="notify-box-wrapper gradient-color-light-pink">
                                    <div className="notify-box-wrapper-bg-image boxHeight-284">
                                        <div className="notify-box clearfix">
                                            <div className="notify-title-position-l-b">
                                                <div className="notify-box-title">
                                                    <div className="notify-box-icon visitor-icon"></div>
                                                    <h1 className="font-16 color-white">Visitor Today</h1>
                                                    <hr />
                                                    <div className="notify-currency">
                                                        <p className="font-20 color-white arrow-sign up-sign">712</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                <div className="notify-box-wrapper gradient-color-light-pink">
                                    <div className="notify-box-wrapper-bg-image boxHeight-284">
                                        <div className="notify-box clearfix">
                                            <div className="notify-title-position-l-b">
                                                <div className="notify-box-title">
                                                    <div className="notify-box-icon visitor-icon"></div>
                                                    <h1 className="font-16 color-white">Visitor This Year</h1>
                                                    <hr />
                                                    <div className="notify-currency">
                                                        <p className="font-20 color-white arrow-sign up-sign">12,34,562</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                <div className="notify-box-wrapper gradient-color-light-pink">
                                    <div className="notify-box-wrapper-bg-image boxHeight-284">
                                        <div className="notify-box clearfix">
                                            <div className="notify-title-position-l-b">
                                                <div className="notify-box-title">
                                                    <div className="notify-box-icon visitor-icon"></div>
                                                    <h1 className="font-16 color-white">Today</h1>
                                                    <hr />
                                                    <div className="notify-currency">
                                                        <p className="font-20 color-white down-sign">2,762</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="panel-box p-l-0 p-r-0">
                                    <div className="panel-box-header">
                                        <h1 className="m-0">Content Info</h1>
                                        <div className="scroll-btn-wrapper">
                                            <ul>
                                                <li onClick={(e) => this.scrollLeftBtn(e)}><i class="fas fa-chevron-left"></i></li>
                                                <li onClick={(e) => this.scrollRightBtn(e)}><i class="fas fa-chevron-right"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="scrollable-flex-wrapper m-t-20" id="scrollableFlexWrapper">
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Total Photo</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Total Speech</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Total Book</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Recieve</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Total Event</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Total Notice</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Impotant Links</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Total Photo</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Recieve</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Recieve</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Recieve</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-box">
                                            <div className="gradient-color-gray">
                                                <div className="notify-box-wrapper-bg-image">
                                                    <div className="notify-box boxHeight-174 boxWidth-174">
                                                        <div className="notify-box-title">
                                                            <h1 className="font-18 color-white text-left m-0">Recieve</h1>
                                                            <hr className="m-b-0" />
                                                        </div>
                                                        <div className="notify-flex boxHeight-128">
                                                            <div className="notify-box-title text-center flex-box-title-opacity">
                                                                <div className="notify-currency">
                                                                    <p className="font-20 color-white tk-sign">52,000/-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="panel-box p-l-0 p-r-0">
                                    <div className="panel-box-header">
                                        <h1 className="m-0">Visitor's Graph <sub className="font-16">( Year 2019 )</sub></h1>
                                    </div>
                                    <div className="chart-box m-t-20">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-12 p-xl-3">
                        <div className="panel-box  gray-bg m-b-20">
                            <div className="panel-box-header">
                                <h1 className="font-20">Complete Setting Up Your Website</h1>
                            </div>
                            <div className="notice-list dot-notice-list">
                                <div className="notice dot-notice">
                                    <div className="success-dot-icon"><i class="fas fa-check-circle success-dot"></i></div>
                                    <div className="notice-title-dot m-0"><h3 className="success-dot">Add Main Slider</h3></div>
                                    <div className="notice-text">
                                        <p>Add 1 or more image to your website main sliding area.</p>
                                    </div>
                                </div>
                                <div className="notice dot-notice">
                                    <div className="success-dot-icon"><i class="fas fa-circle unSuccess-dot"></i></div>
                                    <div className="notice-title-dot m-0"><h3 className="">Add Main Slider</h3></div>
                                    <div className="notice-text">
                                        <p>Add 1 or more image to your website main sliding area.</p>
                                    </div>
                                </div>
                                <div className="notice dot-notice">
                                    <div className="success-dot-icon"><i class="fas fa-circle unSuccess-dot"></i></div>
                                    <div className="notice-title-dot m-0"><h3 className="">Add Main Slider</h3></div>
                                    <div className="notice-text">
                                        <p>Add 1 or more image to your website main sliding area.</p>
                                    </div>
                                </div>
                                <div className="notice dot-notice">
                                    <div className="success-dot-icon"><i class="fas fa-check-circle success-dot"></i></div>
                                    <div className="notice-title-dot m-0"><h3 className="success-dot">Add Main Slider</h3></div>
                                    <div className="notice-text">
                                        <p>Add 1 or more image to your website main sliding area.</p>
                                    </div>
                                </div>
                                <div className="notice dot-notice">
                                    <div className="success-dot-icon"><i class="fas fa-check-circle success-dot"></i></div>
                                    <div className="notice-title-dot m-0"><h3 className="success-dot">Add Main Slider</h3></div>
                                    <div className="notice-text">
                                        <p>Add 1 or more image to your website main sliding area.</p>
                                    </div>
                                </div>
                                <div className="notice dot-notice">
                                    <div className="success-dot-icon"><i class="fas fa-circle unSuccess-dot"></i></div>
                                    <div className="notice-title-dot m-0"><h3 className="">Add Main Slider</h3></div>
                                    <div className="notice-text">
                                        <p>Add 1 or more image to your website main sliding area.</p>
                                    </div>
                                </div>
                                <div className="notice dot-notice">
                                    <div className="success-dot-icon"><i class="fas fa-circle unSuccess-dot"></i></div>
                                    <div className="notice-title-dot m-0"><h3 className="">Add Main Slider</h3></div>
                                    <div className="notice-text">
                                        <p>Add 1 or more image to your website main sliding area.</p>
                                    </div>
                                </div>
                                <div className="notice dot-notice">
                                    <div className="success-dot-icon"><i class="fas fa-check-circle success-dot"></i></div>
                                    <div className="notice-title-dot m-0"><h3 className="success-dot">Add Main Slider</h3></div>
                                    <div className="notice-text">
                                        <p>Add 1 or more image to your website main sliding area.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}