import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from "primereact/calendar";
import { ProfileService } from '../../../service/profile/ProfileService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { UserCategoryService } from '../../../service/UserCategoryService';


export class PersonalEducationInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEducationalInfoObj: [],
            updateUserObj:{
                degreeInfoDTO:{
                    coreCategoryID: ''
                },
                subjectInfoDTO: {
                    coreCategoryID: '',
                },
                gradeInfoDTO:{
                    coreCategoryID: ''
                },
                passingYearInfoDTO: {
                    coreCategoryID: '',
                },
                boardInfoDTO: {
                    coreCategoryID: '',
                },
            },
            insertUserObj:{
                degreeInfoDTO:{
                    coreCategoryID: ''
                },
                subjectInfoDTO: {
                    coreCategoryID: '',
                },
                gradeInfoDTO:{
                    coreCategoryID: ''
                },
                passingYearInfoDTO: {
                    coreCategoryID: '',
                },
                boardInfoDTO: {
                    coreCategoryID: '',
                },
            },
            degreeInfo: [],
            subjectInfo: [],
            gradeInfo: [],
            yearList: [],
            boardInfo: [],
            visibleDialog: false,
            visibleEditDialog: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: '',
            errors: {}
        }
        this.CommonFuctionality = new CommonFuctionality();
        this.ProfileService = new ProfileService();
        this.UserCategoryService = new UserCategoryService();
    }

    componentWillMount(){
        this.getUserEducationalInfo();
        this.getValueByDefaultCode('T140');
        this.getValueByDefaultCode('T141');
        this.getValueByDefaultCode('T142');
        this.getValueByDefaultCode('T116');
        this.getValueByDefaultCode('T106');
    }

    getValueByDefaultCode = (defaultCode) => {
        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if(defaultCode == 'T140'){
                            this.setState({ degreeInfo: body });
                        }
                        else if(defaultCode == 'T141'){
                            this.setState({ yearList: body });
                        }
                        else if(defaultCode == 'T142'){
                            this.setState({ subjectInfo: body });
                        }
                        else if(defaultCode == 'T116'){
                            this.setState({ gradeInfo: body });
                        }
                        else if(defaultCode == 'T106'){
                            this.setState({ boardInfo: body });
                        }
                        this.setState({ topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    getUserEducationalInfo = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ProfileService.fetchUserBasicInfo('education')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ userEducationalInfoObj: body, topProgressBar: false, errorMsgVisible: false });
                        // this.getSlipFile(rowData.attachFilePath,rowData.attachFileName);
                    })
                }
                else {
                    this.ProfileService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeDialogHide = () => {
        this.setState({ visibleDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showDialog = () => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visibleDialog: true, errors: {} });
    }

    onChangeEditDialogHide = () => {
        this.setState({ visibleEditDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showEditDialog = (itemData) => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ updateUserObj: {...itemData}, visibleEditDialog: true, errors: {} });
    }

    onChageEduInfoSerial = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.eduInfoSerial = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.eduInfoSerial = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageDegreeInfo = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.degreeInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.degreeInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageLevelOfEducation = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.examTitle = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.examTitle = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageSubjectInfo = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.subjectInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.subjectInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageResultInfo = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.gradeInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.gradeInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageEduDuration = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.eduDuration = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.eduDuration = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChagePassingYear = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.passingYearInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.passingYearInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageBoardInfo = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.boardInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.boardInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageInstituteName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.instituteName = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.instituteName = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageInstituteForeginStatus = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.instituteForeginStatus = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.instituteForeginStatus = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageAchievementDetails = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.achievementDetails = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.achievementDetails = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    handleError = (objectHandle) => {
        let { errors } = this.state;
        let formIsValid = true;

        if (!objectHandle.degreeInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["degreeInfo"] = "Degree can't left empty.";
        }

        if (!objectHandle.examTitle) {
            formIsValid = false;
            errors["examTitle"] = "Exam title can't left empty.";
        }

        if (!objectHandle.subjectInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["subjectInfo"] = "Subject can't left empty.";
        }

        if (!objectHandle.gradeInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["gradeInfo"] = "Grade can't left empty.";
        }

        if (!objectHandle.passingYearInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["passingYear"] = "Passing year can't left empty.";
        }

        if (!objectHandle.eduDuration) {
            formIsValid = false;
            errors["eduDuration"] = "Education Duration can't left empty.";
        }

        if (!objectHandle.boardInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["boardInfo"] = "Education board can't left empty.";
        }

        if (!objectHandle.instituteName) {
            formIsValid = false;
            errors["instituteName"] = "Institute name can't left empty.";
        }

        if (!objectHandle.instituteForeginStatus) {
            formIsValid = false;
            errors["foreignStatus"] = "Foregin status can't left empty.";
        }

        if (!objectHandle.achievementDetails) {
            formIsValid = false;
            errors["achievementDetails"] = "Achievement details can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onUpdateUserEducationInfo = () => {
        let { updateUserObj } = this.state;
        if (this.handleError(updateUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
            this.ProfileService.updateUserBasicInfo( updateUserObj, 'education')
                .then(res => {
                    if (res.status == 202) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                        this.onChangeEditDialogHide();
                        setTimeout(() => {
                            this.getUserEducationalInfo();
                        }, 800);
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' })
                });
        }
    }

    onInsertUserEducationInfo = () => {
        let { insertUserObj } = this.state;
        if (this.handleError(insertUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
            this.ProfileService.insertUserBasicInfo( insertUserObj, 'education')
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                        this.onChangeDialogHide();
                        setTimeout(() => {
                            this.getUserEducationalInfo();
                        }, 800);
                        
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' })
                });
        }
    }

    render() {
        let { userEducationalInfoObj, updateUserObj, insertUserObj, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let uploadFileLabel = <div>Click / Drag Here To Upload</div>

        let degree = []
        if (this.state.degreeInfo && this.state.degreeInfo.length) {
            degree = this.state.degreeInfo.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
        
        let subject = []
        if (this.state.subjectInfo && this.state.subjectInfo.length) {
            subject = this.state.subjectInfo.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let result = []
        if (this.state.gradeInfo && this.state.gradeInfo.length) {
            result = this.state.gradeInfo.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let year = []
        if (this.state.yearList && this.state.yearList.length) {
            year = this.state.yearList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let board = []
        if (this.state.boardInfo && this.state.boardInfo.length) {
            board = this.state.boardInfo.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let foreignStatus = [
            {label: 'No', value: 1},
            {label: 'Yes', value: 2},
        ]
        return (
            <div>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section blur-section">
                    <div className="p-grid">
                        {
                            userEducationalInfoObj.map((item, index) =>
                                <div className="p-col-12 p-md-6">
                                    <div className="personal-box-wrapper m-t-30">
                                        <div className="personal-box-title-wrapper">
                                            <div className="personal-box-title">
                                                <h4>Academic Education - {item.eduInfoSerial}</h4>
                                            </div>
                                            <div className="edit-button">
                                                <Button icon="fas fa-edit" onClick={e => this.showEditDialog(item)} />
                                            </div>
                                        </div>
                                        <div className="personal-box">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Level of Education</td>
                                                        <td>{item.degreeInfoDTO && item.degreeInfoDTO.categoryName || '---'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Exam / Degree Title</td>
                                                        <td>{item.examTitle || '---'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Major / Group</td>
                                                        <td>{item.subjectInfoDTO && item.subjectInfoDTO.categoryName || '---'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Result</td>
                                                        <td>{item.gradeInfoDTO && item.gradeInfoDTO.categoryName || '---'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Year of Passing</td>
                                                        <td>{item.passingYearInfoDTO && item.passingYearInfoDTO.categoryName || '---'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Duration</td>
                                                        <td>{item.eduDuration || '---'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Board</td>
                                                        <td>{item.boardInfoDTO && item.boardInfoDTO.categoryName || '---'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Institute Name</td>
                                                        <td>{ item.instituteName }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>This is a Foreign Institute</td>
                                                        <td><span>{ item.instituteForeginStatus == 1 ? "No" : "Yes" }</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Achievement </td>
                                                        <td><span>{ item.achievementDetails }</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {/* <div className="p-col-12 p-md-6">
                            <div className="personal-box-wrapper m-t-30">
                                <div className="personal-box-title-wrapper">
                                    <div className="personal-box-title">
                                        <h4>Academic Education - 1</h4>
                                    </div>
                                    <div className="edit-button">
                                        <Button icon="fas fa-edit" onClick={this.showEditDialog}/>
                                    </div>
                                </div>
                                <div className="personal-box">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Level of Education</td>
                                                <td>Secondary</td>
                                            </tr>
                                            <tr>
                                                <td>Exam / Degree Title</td>
                                                <td>S.S.C</td>
                                            </tr>
                                            <tr>
                                                <td>Major / Group</td>
                                                <td>Science</td>
                                            </tr>
                                            <tr>
                                                <td>Result</td>
                                                <td> A+ (5.00)</td>
                                            </tr>
                                            <tr>
                                                <td>Year of Passing</td>
                                                <td>2006</td>
                                            </tr>
                                            <tr>
                                                <td>Duration</td>
                                                <td> 2 years</td>
                                            </tr>
                                            <tr>
                                                <td>Board</td>
                                                <td>Jessore Board</td>
                                            </tr>
                                            <tr>
                                                <td>Institute Name</td>
                                                <td>Kushtia Zilla School</td>
                                            </tr>
                                            <tr>
                                                <td>This is a Foreign Institute</td>
                                                <td><span>No</span></td>
                                            </tr>
                                            <tr>
                                                <td>Achievement </td>
                                                <td><span>None</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="add-button">
                        <Button
                            icon="fas fa-plus"
                            tooltip="Add Academic Education"
                            tooltipOptions={{ position: 'bottom' }}
                            onClick={this.showDialog}
                        />
                    </div>
                </div>
                <div  className="dialog-section">
                    <Dialog id="dialog" className="nw-dialog" header="Add Academic Education" visible={this.state.visibleDialog} onHide={this.onChangeDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Level of Education"
                                                    name="eduInfoSerial"
                                                    value={insertUserObj.eduInfoSerial}
                                                    onChange={e => this.onChageEduInfoSerial(e, 'insert')}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['eduInfoSerial']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Level of Education  <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Exam/Degree"
                                                    onChange= { e => this.onChageDegreeInfo(e, 'insert') }
                                                    value={ insertUserObj.degreeInfoDTO.coreCategoryID }
                                                    options={degree}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="degreeInfo"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['degreeInfo']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Exam / Degree Title<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Level of Education"
                                                    name="examTitle"
                                                    value={ insertUserObj.examTitle }
                                                    onChange= { e => this.onChageLevelOfEducation(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['examTitle']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Major / Group<span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Major/Group"
                                                    onChange= { e => this.onChageSubjectInfo(e, 'insert') }
                                                    value={ insertUserObj.subjectInfoDTO.coreCategoryID }
                                                    options={subject}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="subjectInfo"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['subjectInfo']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Result <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Major/Group"
                                                    onChange= { e => this.onChageResultInfo(e, 'insert') }
                                                    value={ insertUserObj.gradeInfoDTO.coreCategoryID }
                                                    options={result}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="gradeInfo"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['gradeInfo']}</span>
                                        </div>
                                        
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Year of Passing  <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Year"
                                                    onChange= { e => this.onChagePassingYear(e, 'insert') }
                                                    value={ insertUserObj.passingYearInfoDTO.coreCategoryID }
                                                    options={year}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="passingYear"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['passingYear']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Duration <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Education Duration"
                                                    name="eduDuration"
                                                    value={ insertUserObj.eduDuration }
                                                    onChange= { e => this.onChageEduDuration(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['eduDuration']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Board <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Board"
                                                    onChange= { e => this.onChageBoardInfo(e, 'insert') }
                                                    value={ insertUserObj.boardInfoDTO.coreCategoryID }
                                                    options={board}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="boardInfo"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['boardInfo']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institute Name <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute Name"
                                                    name="instituteName"
                                                    value={ insertUserObj.instituteName }
                                                    onChange= { e => this.onChageInstituteName(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['instituteName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Foreign Institute<span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select this is a foreign institute"
                                                    onChange= { e => this.onChageInstituteForeginStatus(e, 'insert') }
                                                    value={ insertUserObj.instituteForeginStatus }
                                                    options={foreignStatus}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="foreignStatus"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['foreignStatus']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Achievement <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Achievement"
                                                    name="achievementDetails"
                                                    value={ insertUserObj.achievementDetails }
                                                    onChange= { e => this.onChageAchievementDetails(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['achievementDetails']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={this.onInsertUserEducationInfo}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog id="dialog" className="nw-dialog" header="Edit Academic Education" visible={this.state.visibleEditDialog} onHide={this.onChangeEditDialogHide} maximizable>
                    <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Serial no"
                                                    name="eduInfoSerial"
                                                    value={updateUserObj.eduInfoSerial}
                                                    onChange={e => this.onChageEduInfoSerial(e, 'update')}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['eduInfoSerial']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Level of Education  <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Exam/Degree"
                                                    onChange= { e => this.onChageDegreeInfo(e, 'update') }
                                                    value={ updateUserObj.degreeInfoDTO.coreCategoryID }
                                                    options={degree}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="degreeInfo"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['degreeInfo']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Exam / Degree Title<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Level of Education"
                                                    name="examTitle"
                                                    value={ updateUserObj.examTitle }
                                                    onChange= { e => this.onChageLevelOfEducation(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['examTitle']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Major / Group<span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Major/Group"
                                                    onChange= { e => this.onChageSubjectInfo(e, 'update') }
                                                    value={ updateUserObj.subjectInfoDTO.coreCategoryID }
                                                    options={subject}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="subjectInfo"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['subjectInfo']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Result <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Major/Group"
                                                    onChange= { e => this.onChageResultInfo(e, 'update') }
                                                    value={ updateUserObj.gradeInfoDTO.coreCategoryID }
                                                    options={result}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="gradeInfo"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['gradeInfo']}</span>
                                        </div>
                                        
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Year of Passing  <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Year"
                                                    onChange= { e => this.onChagePassingYear(e, 'update') }
                                                    value={ updateUserObj.passingYearInfoDTO.coreCategoryID }
                                                    options={year}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="passingYear"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['passingYear']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Duration <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Education Duration"
                                                    name="eduDuration"
                                                    value={ updateUserObj.eduDuration }
                                                    onChange= { e => this.onChageEduDuration(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['eduDuration']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Board <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Board"
                                                    onChange= { e => this.onChageBoardInfo(e, 'update') }
                                                    value={ updateUserObj.boardInfoDTO.coreCategoryID }
                                                    options={board}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="boardInfo"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['boardInfo']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institute Name <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute Name"
                                                    name="instituteName"
                                                    value={ updateUserObj.instituteName }
                                                    onChange= { e => this.onChageInstituteName(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['instituteName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Foreign Institute<span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select this is a foreign institute"
                                                    onChange= { e => this.onChageInstituteForeginStatus(e, 'update') }
                                                    value={ updateUserObj.instituteForeginStatus }
                                                    options={foreignStatus}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="foreignStatus"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['foreignStatus']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Achievement <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Achievement"
                                                    name="achievementDetails"
                                                    value={ updateUserObj.achievementDetails }
                                                    onChange= { e => this.onChageAchievementDetails(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['achievementDetails']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={this.onUpdateUserEducationInfo}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </Dialog>
                </div>
            </div>
        )
    }
} 