import React, { Component } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ChatBoxService } from '../../../service/chatBox/chatBoxService';
import Formatter from '../../../utils/Formatter';
import CommonFuctionality from '../../common/CommonFuctionality';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ProfileService } from '../../../service/profile/ProfileService';
import { SendSmsAndNetiMail } from "../../common/SendSmsAndNetiMail";
import { Dropdown } from 'primereact/dropdown';
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
import { log } from "fullcalendar";
import { Dialog } from "primereact/dialog";
import { UserOtherAccessService } from "../../../service/UserOtherAccessService";
import { CUSTOM_MESSAGE } from "../../common/CustomMessage";



export class MessageSendForDueBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageBody: "",
            userInfo: {},
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            searchIsLoading: false,
            dataTableValue: [],
            searchViewError: false,
            searchIsLoadingFirst: false,
            searchIsLoadingSecond: false,
            uploadImage: {},
            secondSearchIsLoading: false,
            secondSearchErrorMsg: '',
            secondfirstSearchView: false,
            secondSearchErrorVisible: false,
            netiInformation: {},
            disableAddButton: true,
            /***Data table checkbox***/
            filterArray:[],
            selectPartner: null,
            selectedCustomers: [],
            globalFilter: null,
            selectedRepresentatives: null,
            smsDialogVisible:false

        };

        this.NetiContentLoader = new NetiContentLoader();
        this.chatBoxService = new ChatBoxService();
        this.formatter = new Formatter();
        this.CommonFuctionality = new CommonFuctionality();
        this.CRMPointService = new CRMPointService();
        this.ProfileService = new ProfileService();
        this.validatorUtility = new ValidatorUtility();
        this.UserOtherAccessService = new UserOtherAccessService();
    }


    netiIdHandleError() {
        let errors = {};
        let formIsValid = true;

        if (this.state.netiId === "") {
            formIsValid = false;
            errors["netiId"] = "Please input atleast one ID.";
            this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
        }

        this.setState({ errors: errors });
        return formIsValid;
    }



    viewActionBody = (rowData) => {
        return <div>
            {/* <span>{rowData.basicMobile}</span> */}
            <Button
                className="p-button-danger nw-action-button"
                icon="fas fa-times"
                onClick={e => this.onDeleteRow(rowData)}
            />
        </div>
    }

    onDeleteRow = (rowData) => {
        let { dataTableValue } = this.state
        const filteredItems = dataTableValue.filter(function (item) {
            return item !== rowData
        })

        this.setState({ dataTableValue: filteredItems })
    }

    selectPartnerHandler = (e) => {
        this.setState({ selectPartner: e.target.value,selectedCustomers: [] });
    }

    ///Sms sender list
    getSenderInformation = () => {
        if (this.netiIdHandleError()) {
            let { designationArray, topProgressBar, dataTableIsLoading, selectPartner, dataTableValue } = this.state;
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.CRMPointService.getUserDetailsForDueDetailsList(selectPartner)
                .then(res => {
                    if (res.status == 302) {
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
                        return res.json().then((body) => {
                            this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false, });
                        });

                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
                });;
        }
    }

    netiIdHandleError = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.selectPartner) {
            formIsValid = false;
            errors["netiIDError"] = "Please Select User Type.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    showSMSDialog = () =>{
        this.setState({
            smsDialogVisible: true
        })
    }

    IdTerminateConfirmation = () => {
        this.setState({
            smsDialogVisible: false
        })
    }

    onSubmitSendSMSorMail = () => {
        let { selectedCustomers, filterArray } = this.state;
        let selectedCustomersMakeArray = [];
        selectedCustomers.map((item, index) => {
            let stringMsg = `Dear ${this.state.selectPartner} Instiute ID - ${item.instituteID} InstituteName Name - ${item.instituteName} Istiutte total due - ${this.validatorUtility.currencyFormatter(item.dueAmount)} Please arrange your due from client as early as possible.`;
            let createArray = [item.instituteID, item.instituteName, item.netiID, item.name, item.mobile, item.email, this.validatorUtility.currencyFormatter(item.dueAmount) ];
            selectedCustomersMakeArray.push(createArray);
        });

        let urlName="/send/sms/single?reportType=emDue";

        this.setState({ topProgressBar: true, errorMsgVisible: false, smsDialogVisible: false })
        this.UserOtherAccessService.sendSingleSMS(selectedCustomersMakeArray, urlName)
            .then((res) => {
                if (res.status === 201) {
                    this.growl.show({ severity: 'success', summary: CUSTOM_MESSAGE.SUCCESS_MSG_SHORT, detail: "SMS Send Successfull." });
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false})
                    
                } else {
                    this.UserOtherAccessService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check connection !!!", buttonIcon: "fas fa-check", buttonIconAnimClass: "p-button-primary"  });
            });
    }


    render() {
        let { smsCounterObj, messageBodyMaxlength, selectedCustomers, dataTableValue, sendSMSorMailObj, netiInformation, disableAddButton, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        const designationArray = [
            { label: 'Admin', value: 'admin' },
            { label: 'Parent', value: 'parent' }
        ];

        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Institute List <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;
        let headerForMessage = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Message List <span style={{ 'float': 'right' }}> Total Found: {selectedCustomers.length} </span></div>;

        let netiIDAndNameTemplate = (rowData) => {
            return <div>
                <span><b>ID : {rowData.netiID}</b></span><br />
                <span><b>Name : {rowData.name}</b></span>
            </div>
        }

        let netiMobileAndEmailTemplate = (rowData) => {
            return <div>
                <span><b>Mobile : {rowData.mobile}</b></span><br />
                <span><b>Email : {rowData.email}</b></span>
            </div>
        }

        let netiDueAmountTemplate = (rowData) => {
            return <div>
                <span>{this.validatorUtility.currencyFormatter(rowData.dueAmount)}</span>
            </div>
        }

        let messageTemplate = (rowData) => {
            return <div>
                {`Dear ${this.state.selectPartner == 'admin' ? 'Admin' : 'Parent' } Instiute ID - ${rowData.instituteID} InstituteName Name - ${rowData.instituteName} Istiutte total due - ${this.validatorUtility.currencyFormatter(rowData.dueAmount)} Please arrange your due from client as early as possible.`}
            </div>
        }


        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={el => (this.growl = el)} />
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                            <div className="p-grid seprator-inside">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">User Type <span>*</span></span>
                                                <Dropdown
                                                    options={designationArray}
                                                    autoWidth={false}
                                                    onChange={this.selectPartnerHandler}
                                                    value={this.state.selectPartner}
                                                    placeholder="User Type" />
                                            </div>

                                            <span className="error-message">{this.state.errors["netiIDError"]}</span>
                                        </div>

                                        <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Search"
                                                onClick={this.getSenderInformation}
                                                icon="fas fa-search"

                                            />
                                        </div>

                                        <div className="p-col-12" />
                                        <div className="p-col-12" />
                                        <div className="p-col-12" />

                                        <div className="p-col-12 p-lg-12 p-xl-12 p-col-nogutter">
                                            {
                                                this.state.dataTableIsLoading ?
                                                    this.NetiContentLoader.MyPointTableLoader() :
                                                    <div className="nw-data-table">
                                                        <DataTable
                                                            value={dataTableValue}
                                                            header={header}
                                                            responsive={true}
                                                            selection={selectedCustomers}
                                                            onSelectionChange={e => this.setState({selectedCustomers: e.value})}
                                                            paginator={true}
                                                            rows={10}
                                                        >
                                                            <Column selectionMode="multiple" style={{ width: '3em' }} />
                                                            <Column field="instituteID" header="Institute ID" filter={true} style={{ width: '10em' }} />
                                                            <Column field="instituteName" header="Institute Name" filter={true} />
                                                            <Column field="netiID" header="Neti ID / name" body={netiIDAndNameTemplate} filter={true} />
                                                            <Column field="mobile" header="Mobile / Email" body={netiMobileAndEmailTemplate} filter={true} />
                                                            <Column field="amount" header="Total Due Amount" body={netiDueAmountTemplate} filter={true} />
                                                        </DataTable>

                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-4">
                            <div className="p-col-nogutter nw-message-view-main">
                                <div className="p-col-12 p-xl-12 nw-message-view header">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Total Contacts </span>

                                        <Button
                                            label={selectedCustomers.length || "0"}
                                            onClick={(e) => window.location.href = "#"}
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12 p-col-nogutter nw-message-view body nw-tabview">
                                        <div className="nw-data-table">
                                            <DataTable
                                                value={selectedCustomers}
                                                header={headerForMessage}
                                                responsive={true}
                                                paginator={true}
                                                rows={10}
                                            >
                                                <Column field="instituteID" header="Institute ID" />
                                                <Column field="instituteName" header="Institute Name"/>
                                                <Column field="netiID" header="Message"  body={messageTemplate}/>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className='p-grid'>
                                    <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                        <div className="required-field"></div>
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Send"
                                            onClick={this.showSMSDialog }
                                            icon="fas fa-check"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="dialog-section">
                                <Dialog header="Send SMS" visible={this.state.smsDialogVisible} onHide={this.IdTerminateConfirmation} className="nw-confirm-dialog">
                                    <div className="p-fluid">
                                        <div className="p-col-12 p-col-12">
                                            <div className="confirm-wrapper">
                                                <div className="body-wrapper">
                                                    <header>Total messages you are about to send</header>
                                                    <h1>{selectedCustomers.length}</h1>
                                                    <footer>{selectedCustomers.length}  Person x {selectedCustomers.length} Message</footer>
                                                </div>
                                                <h1>Are You Sure?</h1>
                                                <div className="nw-button-parent-multiple">
                                                    <Button
                                                        className="p-button-danger nw-button nw-button-multiple"
                                                        label="No"
                                                        icon="fas fa-times"
                                                        onClick={this.IdTerminateConfirmation}
                                                    />
                                                    <Button
                                                        className="p-button-primary nw-button nw-button-multiple"
                                                        label="Yes"
                                                        icon="fas fa-check"
                                                        onClick={this.onSubmitSendSMSorMail }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                        
                    </div>
                </div>
                </div>
        );
    }
}
