import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProfileService } from '../../../service/profile/ProfileService'; 
import {Dialog} from 'primereact/dialog';
import {Growl} from 'primereact/growl';
import { ErrorMessageView } from '../../common/ErrorMessageView';

let errors = {};

export class PasswordRequired extends Component {
    constructor(props){
        super(props);
        this.state = {
            passwordIcon: true,
            passwordHideOrShow: true,
            passwordSet: '',
            changePassword: {

                passwordMatch: '12',
                emptyPassword: false,
            },
            passwordDialogView: true,
            errors:{}
        }
        this.profileService = new ProfileService();

        
        this.showWarn = this.showWarn.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.passwordOnSubmit = this.passwordOnSubmit.bind(this);
        this.dialogOnHide = this.dialogOnHide.bind(this);
        this.onHide = this.onHide.bind(this);

        

        
        
    }

    componentDidMount() {

        // setTimeout(function() { //Start the timer
        //     this.setState({renderLogoutDialog: false}) //After 1 second, set render to true
        // }.bind(this), 5000)
            
    }

    onHide(event) {
        window.location.replace('/');
    }

    dialogOnHide(event) {
       
    }

    onChangeHandlePassword (event, props){

        let { errors } = this.state
        errors['passwordSet'] = ''
        this.setState({
            passwordSet: event.target.value,
            errors,
        })
    }

    togglePasswordShow(event, props) {
        this.setState({
            passwordHideOrShow: !this.state.passwordHideOrShow,
            passwordIcon: !this.state.passwordIcon
        });

    }

    passwordIsMatch(){
        return true;
    }

    passwordOnSubmit(){

        if(this.state.passwordSet != ''){
            let setPassword = this.state.passwordSet
            let matchPassword = this.state.changePassword.passwordMatch

            this.setState({topProgressBar: true, errorMsgVisible: false});

            let userPasswordInfo = {
                "password": setPassword
            }

            this.profileService.checkPasswordForUpdate(userPasswordInfo)
            .then((res)=>{
                    if(res.status === 200){
                        return res.json().then((body) => {
                            if(body === false){
                                this.setState({errorMSG: "Please check password again"})
                                this.showWarn(this.state.errorMSG)
                            }
                            else{
                                this.setState({
                                    visible: false,
                                    updateprofileform: true,
                                    passwordDialogView:false,
                                    errorMSG: ''
                                })

                               this.passwordIsMatch();
                            }
                            this.setState({topProgressBar: false, errorMsgVisible: false});
                        });
                    }
                    else{
                        this.profileService.Auth.handleErrorStatus(res)
                        .then((resp)=>{
                            this.setState({ checkEligibility: false });
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                    }
            }).catch((error) => {
                this.setState({ secondDropDownIsLoading:false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
        }
        else{
            errors["passwordSet"]="Please Input Your Password"
        }
        
        this.setState({
            errors: errors
        })
    }

    showWarn(msg) {
        this.growl.show({severity: 'warn', summary: 'Warning', detail: msg});
    }
    showSuccess(msg) {
        this.growl.show({severity: 'success', summary: 'Success Message', detail: msg});
    }

    render(){
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        return(
            <div>
                <div className="p-grid">
                    { topProgressBar?
                        <ErrorMessageView 
                            topProgressBar={topProgressBar} 
                        />
                        : null
                    }
                    { errorMsgVisible ?
                        <ErrorMessageView 
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} />
                    <div>
                        <Dialog 
                            header="Enter Password" 
                            className="nonHideDialog nw-dialog" 
                            onHide={this.onHide}
                            visible={this.state.passwordDialogView}
                        >
                            <center className="p-col-12 p-xl-12">
                                <i class="fas fa-unlock-alt fa-4x"/>
                            </center>
                            <br/>

                            <div className="p-col-12 p-xl-12">
                                <span className="formControl">
                                    <label>Please enter your password for <b>update</b></label>
                                    <div className="p-inputgroup">
                                        <InputText
                                            type={this.state.passwordHideOrShow ? "password" : "text"}
                                            name="passwordSet"
                                            value={this.state.passwordSet}
                                            className="custom-field-control"
                                            placeholder="Enter Password"
                                            onChange={this.onChangeHandlePassword.bind(this)}
                                            required="required"
                                            keyfilter="alphanum"
                                        />
                                        
                                        <Button
                                            icon={this.state.passwordIcon ? "pi pi-eye-slash" : "pi pi-eye"}
                                            className=""
                                            onClick={(event) => this.togglePasswordShow(event, this.props)} />

                                        
                                    </div>
                                    <span className='error-message'>{this.state.errors["passwordSet"]}</span>
                                    <span className='error-message'>{ this.state.errorMSG }</span>
                                </span>
                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                <Button 
                                    icon="fas fa-arrow-right"
                                    iconPos="right"
                                    onClick={this.passwordOnSubmit} 
                                    label="Next" 
                                    className="p-button-primary nw-button nw-button-right" />
                            </div>
                            
                        </Dialog>
                    </div>
                                 
                </div>
            </div>
        )
    }
}