import React, { Component } from 'react';

export class EmAuditPointTaggingList extends Component {
  
    render() {
        
        return (
            <div>
                <h1>This Module is Under Construction</h1>
            </div>

        )
    }

}