import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { Growl } from 'primereact/growl';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import { Panel } from 'primereact/panel';
import { DataView } from 'primereact/dataview';
import { ErrorMessageView } from '../common/ErrorMessageView';


let errors = {};
export class SupportDwsMenuConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            menuList: [],
            configurationMenu: {},
            disableDialog: false,
            enableDialog: false,
            dataTableIsLoading: false
        }
        this.itemTemplate = this.itemTemplate.bind(this);
        this.onSubmitMenuConfig = this.onSubmitMenuConfig.bind(this);
        this.supportPointService = new SupportPointService();
    }

    componentWillMount() { this.fetchSupportMenuConfigList() };

    visibleDialog = (menuName, activeStatus) => {
        let dialogHeader = '';
        { activeStatus === 1 ? dialogHeader = 'Enable' : dialogHeader = 'Disable' };
        let configMenuDetails = { menuName: menuName, status: activeStatus, dialogHeader: dialogHeader };
        this.setState({ configurationMenu: configMenuDetails });
        this.setState({ enableDialog: true });
    }

    onHideDialog = () => { this.setState({ enableDialog: false }) };

    fetchSupportMenuConfigList() {
        this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' })
        this.supportPointService.fetchDWS_MenuConfigurationList()
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => { 
                        this.setState({ menuList: body }) 
                        this.setState({ topProgressBar: false, errorMsgVisible: false, errorMsgBody: '' })
                    })
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                };
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
            });
    }

    itemTemplate(menuList) {

        let menuName = menuList;
        return (
            <div className="p-col-12 p-md-3">

                <Fieldset legend={<strong> {menuList} </strong>} >

                    <div className="p-col-12">

                        <div className="p-col-12 p-md-12 p-xl-6">
                            <Button icon="fas fa-plus-circle"
                                className="p-button p-component p-button-success p-button-text-only"
                                label="Enable"
                                onClick={() => this.visibleDialog(menuName, 1)}
                            />
                        </div>
                        <div className="p-col-12  p-md-12 p-xl-6">
                            <Button icon="fas fa-plus-circle"
                                className="p-button p-component p-button-danger p-button-text-only"
                                label="Disable"
                                onClick={() => this.visibleDialog(menuName, 0)}
                            />
                        </div>

                    </div>
                </Fieldset>
            </div>
        )
    }

    onSubmitMenuConfig() {
        this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' })
        this.supportPointService.submitDWS_MenuConfig(this.state.configurationMenu)
            .then(res => {
               
                if (res.status === 202) {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: ` ${this.state.configurationMenu.menuName} Menu ${this.state.configurationMenu.dialogHeader} successful.` });
                    this.setState({ topProgressBar: false, errorMsgVisible: false, errorMsgBody: '' })
                    this.onHideDialog();
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
                    };
            }).catch(error => {
                this.setState({ topProgressBar: false,errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
            });
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;


        return (

            <Panel>
                <div className="p-fluid">
                    <div className="p-grid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                        <div className="p-col-12">
                            <DataView value={this.state.menuList} layout={'Grid'} itemTemplate={this.itemTemplate}></DataView>
                        </div>
                    </div>
                </div>
                <Growl ref={(el) => this.growl = el} />

                <Dialog header={this.state.configurationMenu.dialogHeader} className="nw-confirm-dialog" visible={this.state.enableDialog} onHide={this.onHideDialog} closable>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="confirm-wrapper">
                                    <h3 className="text-center">Are you want to <strong>{this.state.configurationMenu.dialogHeader} {this.state.configurationMenu.menuName} </strong> menu?</h3>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                            <Button 
                                                    label='No' 
                                                    icon='fas fa-times'
                                                    className="p-button p-button-danger nw-button nw-button-multiple" 
                                                    onClick={this.onHideDialog} 
                                                />
                                                <Button 
                                                    label='Yes' 
                                                    icon='fas fa-check' 
                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                    onClick={this.onSubmitMenuConfig} 
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

            </Panel>
        );
    }
}