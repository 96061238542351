
import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import NetiContentLoader from '../../common/NetiContentLoader';
import { Dialog } from 'primereact/dialog';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';


let emDueBillStatusArr = [];
let totalEmDueBillAmount = 0;
let emDueBillStatus;

let emDueInstituteStatusArr = [];
let totalEmDueInstitute = 0;
let emDueInstituteStatus;

let mbpPartnerStatusArr = [];
let totalMbp = 0;
let mbpPartnerStatus;

let zbpPartnerStatusArr = [];
let totalZbp = 0;
let zbpPartnerStatus;

let bdpPartnerStatusArr = [];
let totalBdp = 0;
let bdpPartnerStatus;

let bepPartnerStatusArr = [];
let totalBep = 0;
let bepPartnerStatus;

let uniquePointNames = [];
let yearNameColumn = [];
let pointName = [];

let uniqueYearNames = [];
let yearName = [];

export class DashboardCurrentStatus extends Component {

    constructor() {
        super();
        this.state = {
            topProgressBar: false,
            dataTableIsLoading: false,
            userWalletDetailsList: [],
            prepaidWalletDetailsList: [],
            payableWalletDetailsList: [],
            netizenRevenueWalletDetailsList: [],
            emSmsBalanceList: [],
            errorMsgVisible: false,
            visible: false,
            viewPrepaidWalletInfoDialog: false,
            viewPayableWalletInfoDialog: false,
            viewNetizenRevenueWalletInfoDialog: false,
            viewEmSmsBalanceInfoDialog: false,
            errorMsgBody: null,
            currentStatusDashboardInfo: {
                payableInfo: {},
                pendingRequestInfo: {}
            },
            pendingTokenDialog: false,
            pendingTokenList: [],

            emOrderDialog: false,
            emOrderList: [],

            pendingSmsDialog: false,
            pendingSmsList: [],

            remainDeviceDialog: false,
            remainDeviceList: [],

            pendingDeviceReqDialog: false ,
            pendingDeviceReqList: [],
        }

        this.CRMPointService = new CRMPointService();
        this.ManagementPointService = new ManagementPointService();
        this.validatorUtility = new ValidatorUtility();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentDidMount() {
        this.getcrmCurrentStatusDashboardInfo();
    }

    getcrmCurrentStatusDashboardInfo() {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.CRMPointService.findCurrentStatusDashboardInfo()
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {

                        let emDueBillAmountColumnHeader = ["Active", "Inactive"];
                        emDueBillStatus = Object.entries(body.emDueBillAmountStatus)
                        emDueBillStatusArr.push(emDueBillAmountColumnHeader, ...emDueBillStatus);
                        totalEmDueBillAmount = emDueBillStatus.map(item => item[1]).reduce((a, b) => (a + b));

                        let emDueInstituteColumnHeader = ["Active", "Inactive"];
                        emDueInstituteStatus = Object.entries(body.emDueInstituteStatus)
                        emDueInstituteStatusArr.push(emDueInstituteColumnHeader, ...emDueInstituteStatus);
                        totalEmDueInstitute = emDueInstituteStatus.map(item => item[1]).reduce((a, b) => (a + b));

                        let mbpPartnerStatusColumnHeader = ["title", "count"];
                        mbpPartnerStatus = Object.entries(body.mbpPartnerStatus)
                        mbpPartnerStatusArr.push(mbpPartnerStatusColumnHeader, ...mbpPartnerStatus);
                        totalMbp = mbpPartnerStatus.map(item => item[1]).reduce((a, b) => (a + b));

                        let zbpPartnerStatusColumnHeader = ["title", "count"];
                        zbpPartnerStatus = Object.entries(body.zbpPartnerStatus)
                        zbpPartnerStatusArr.push(zbpPartnerStatusColumnHeader, ...zbpPartnerStatus);
                        totalZbp = zbpPartnerStatus.map(item => item[1]).reduce((a, b) => (a + b));

                        let bdpPartnerStatusColumnHeader = ["title", "count"];
                        bdpPartnerStatus = Object.entries(body.bdpPartnerStatus)
                        bdpPartnerStatusArr.push(bdpPartnerStatusColumnHeader, ...bdpPartnerStatus);
                        totalBdp = bdpPartnerStatus.map(item => item[1]).reduce((a, b) => (a + b));

                        let bepPartnerStatusColumnHeader = ["title", "count"];
                        bepPartnerStatus = Object.entries(body.bepPartnerStatus)
                        bepPartnerStatusArr.push(bepPartnerStatusColumnHeader, ...bepPartnerStatus);
                        totalBep = bepPartnerStatus.map(item => item[1]).reduce((a, b) => (a + b));

                        this.setState({ currentStatusDashboardInfo: body })
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    columnTemplate = (headerName, body) => {
        let amount = 0;
        if (body) {
            let values = Object.values(body);
            for (let k = 0; k < values[0].length; k++) {
                if (headerName == values[0][k].year) {
                    amount = values[0][k].subTotal
                }
            }
        }
        if (amount !== 0) {
            return amount;
        } else {
            return <div>---</div>
        }
    }

    pointNameTemplate = (rowData) => {

        let pointName;
        if (rowData) {
            for (let [key, value] of Object.entries(rowData)) {
                pointName = key;
            }
        }
        if (pointName) {
            return pointName;
        } else {
            return (<div>---</div>);
        }

    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    onHidePrepaidWalletInfoDialog = (event) => {
        this.setState({ viewPrepaidWalletInfoDialog: false });
    }

    onHidePayableWalletInfoDialog = (event) => {
        this.setState({ viewPayableWalletInfoDialog: false });
    }

    onHideNetizenRevenueWalletInfoDialog = (event) => {
        this.setState({ viewNetizenRevenueWalletInfoDialog: false });
    }

    onHideEmSmsBalanceInfoDialog= (event) => {
        this.setState({ viewEmSmsBalanceInfoDialog: false });
    }

    viewUserWalletDialog = () => {
        this.setState({ userWalletDetailsList: [], topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.CRMPointService.findCrmUserWalletDetails('userWallet')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ visible: true });
                        this.setState({ userWalletDetailsList: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    viewPrepaidWalletDialog = () => {
        this.setState({ prepaidWalletDetailsList: [], topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.CRMPointService.findCrmUserWalletDetails('prepaidWallet')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ viewPrepaidWalletInfoDialog: true });
                        this.setState({ prepaidWalletDetailsList: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    viewPayableWalletDialog = () => {
        this.setState({ payableWalletDetailsList: [], topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.CRMPointService.findCrmUserWalletDetails('payableWallet')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ viewPayableWalletInfoDialog: true });
                        this.setState({ payableWalletDetailsList: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    viewNetizenRevenueWalletDialog = () => {
        this.setState({ netizenRevenueWalletDetailsList: [], topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.CRMPointService.findCrmUserWalletDetails('revenueWallet')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ viewNetizenRevenueWalletInfoDialog: true });
                        this.setState({ netizenRevenueWalletDetailsList: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    viewEdumanSmsBalanceDetailsDialog = () => {
        this.setState({ emSmsBalanceList: [], topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.ManagementPointService.findEmSmsDetails()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ viewEmSmsBalanceInfoDialog: true });
                        this.setState({ emSmsBalanceList: body.item, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    balanceFormatTemplate = (rowData) => {
        return (<span>{rowData.balance ? this.validatorUtility.currencyFormatter(rowData.balance) : 0}</span>);

    }

    smsBalanceFormatTemplate = (rowData) => {
        return (<span>{rowData.smsBalance ? this.validatorUtility.currencyFormatter(rowData.smsBalance) : 0}</span>);

    }

    fetchPendignTokenDetails = () => {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.CRMPointService.fetchPendingTokenList()
            .then(res => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log('pTokenBody', res);

                        this.setState({ pendingTokenList: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });

    }

    fetchEmOrderDetails = () => {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.CRMPointService.fetchEmOrderDetailsList()
            .then(res => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log('emOrderBody', res);

                        this.setState({ emOrderList: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });

    }

    fetchPendignSmsDetails = () => {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.CRMPointService.fetchPendignSmsDetailsList()
            .then(res => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log('pendingSmsList', body);
                        this.setState({ pendingSmsList: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });

    }

    fetchRemainDeviceDetails = () => {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.CRMPointService.fetchRemainDeviceDetailsList()
            .then(res => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log('PendingDeviceList', body);
                        this.setState({ pendi: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });

    }

    fetchPendingDeviceReqDetails = () => {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.CRMPointService.fetchPendingDeviceReqDetailsList()
            .then(res => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log('pendingDeviceReqList', body);
                        this.setState({ pendingDeviceReqList: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });

    }

    viewPendingTokenDialog = () => {
        this.setState({ pendingTokenDialog: true });
        this.fetchPendignTokenDetails();
    }

    viewPendingTokenDialogHide = () => {
        this.setState({ pendingTokenDialog: false });
    }

    viewEmOrderDialog = () => {
        this.setState({ emOrderDialog: true });
        this.fetchEmOrderDetails();
    }

    viewEmOrderDialogHide = () => {
        this.setState({ emOrderDialog: false });
    }

    viewPendingSmsDialog = () => {
        this.setState({ pendingSmsDialog: true });
        this.fetchPendignSmsDetails();
    }

    viewPendingSmsDialogHide = () => {
        this.setState({ pendingSmsDialog: false });
    }

    viewRemainDeviceDialog = () => {
        this.setState({ remainDeviceDialog: true });
        this.fetchRemainDeviceDetails();
    }

    viewRemainDeviceDialogHide = () => {
        this.setState({ remainDeviceDialog: false });
    }

    viewPendingDeviceReqDialog = () => {
        this.setState({ pendingDeviceReqDialog: true });
        this.fetchPendingDeviceReqDetails();
    }

    viewPendingDeviceReqDialogHide = () => {
        this.setState({ pendingDeviceReqDialog: false });
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { payableInfo, pendingRequestInfo, pointSummary } = this.state.currentStatusDashboardInfo;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> User Wallet Details <span style={{ 'float': 'right' }}> Total Found: {this.state.userWalletDetailsList && this.state.userWalletDetailsList.length !== 0 ? this.state.userWalletDetailsList.length : 0} </span></div>;
        let prepaidWalletTableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Prepaid Wallet Details <span style={{ 'float': 'right' }}> Total Found: {this.state.prepaidWalletDetailsList && this.state.prepaidWalletDetailsList.length !== 0 ? this.state.prepaidWalletDetailsList.length : 0} </span></div>;
        let payableWalletTableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Payable Wallet Details <span style={{ 'float': 'right' }}> Total Found: {this.state.payableWalletDetailsList && this.state.payableWalletDetailsList.length !== 0 ? this.state.payableWalletDetailsList.length : 0} </span></div>;
        let netizenRevenueWalletTableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Netizen Revenue Wallet Details <span style={{ 'float': 'right' }}> Total Found: {this.state.netizenRevenueWalletDetailsList && this.state.netizenRevenueWalletDetailsList.length !== 0 ? this.state.netizenRevenueWalletDetailsList.length : 0} </span></div>;
        let emSmsBalanceInfoTableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Eduman SMS Balance Info Details <span style={{ 'float': 'right' }}> Total Found: {this.state.emSmsBalanceList && this.state.emSmsBalanceList.length !== 0 ? this.state.emSmsBalanceList.length : 0} </span></div>;

        let unUsedHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Point Info <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {pointSummary ? pointSummary.length : 0}
            </span>
        </div>;

        let footer = <ColumnGroup>
            <Row>
            </Row>
        </ColumnGroup>;

        if (pointSummary != null) {
            for (let i = 0; i < pointSummary.length; i++) {
                for (let [key, value] of Object.entries(pointSummary[i])) {
                    pointName.push(key);
                    uniquePointNames = Array.from(new Set(pointName))
                    for (let k = 0; k < value.length; k++) {
                        yearName.push(value[k].year);
                        uniqueYearNames = Array.from(new Set(yearName)).sort(function (a, b) { return b - a })
                    }
                }
            }

        }

        let emOrderHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Eduman Order List <span style={{ 'float': 'right' }}> Total Found: {this.state.emOrderList && this.state.emOrderList.length !== 0 ? this.state.emOrderList.length : 0} </span></div>;
        let smsBalanceHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Remain SMS List <span style={{ 'float': 'right' }}> Total Found: {this.state.pendingSmsList && this.state.pendingSmsList.length !== 0 ? this.state.pendingSmsList.length : 0} </span></div>;
        let deviceInfoHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Remain Device List <span style={{ 'float': 'right' }}> Total Found: {this.state.remainDeviceList && this.state.remainDeviceList.length !== 0 ? this.state.remainDeviceList.length : 0} </span></div>;
        let pendingDeviceReqHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Pending Device Request List <span style={{ 'float': 'right' }}> Total Found: {this.state.pendingDeviceReqList && this.state.pendingDeviceReqList.length !== 0 ? this.state.pendingDeviceReqList.length : 0} </span></div>;

        return (
            <div className="main-section netiWorld-DashBoard-wrapper">
                <div className="netiWorld-DashBoard">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} />

                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="panel-box p-0">
                                <div className="panel-box-header">
                                    <h1>Payable Information</h1>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12" onClick={e => this.viewUserWalletDialog(e)}>
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-1">
                                            <i class="fas fa-street-view"></i>
                                        </div>
                                        <div className="info-text">
                                            <h4>{payableInfo && payableInfo.userWallet ? this.validatorUtility.currencyFormatter(payableInfo.userWallet) : 0}</h4>
                                            <span>BDT</span>
                                            <p>User Wallet</p>
                                        </div>
                                        <div className="info-button info-btn-1" >
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12" onClick={e => this.viewPrepaidWalletDialog(e)}>
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-2">
                                            <i class="fas fa-money-check-alt"></i>
                                        </div>
                                        <div className="info-text">
                                            <h4>{payableInfo && payableInfo.prepaidWallet ? this.validatorUtility.currencyFormatter(payableInfo.prepaidWallet) : 0}</h4>
                                            <span>BDT</span>
                                            <p>Pre-Paid Wallet</p>
                                        </div>
                                        <div className="info-button info-btn-2">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12" onClick={e => this.viewPayableWalletDialog(e)}>
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-3">
                                            <i class="fas fa-user-tag"></i>
                                        </div>
                                        <div className="info-text">
                                            <h4>{payableInfo && payableInfo.vendorWallet ? this.validatorUtility.currencyFormatter(payableInfo.vendorWallet) : 0}</h4>
                                            <span>BDT</span>
                                            <p>Payable Wallet</p>
                                        </div>
                                        <div className="info-button info-btn-3">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12" onClick={e => this.viewNetizenRevenueWalletDialog(e)}>
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-4">
                                            <i class="fas fa-wallet"></i>
                                        </div>
                                        <div className="info-text">
                                            <h4>{payableInfo && payableInfo.netizenWallet ? this.validatorUtility.currencyFormatter(payableInfo.netizenWallet) : 0}</h4>
                                            <span>BDT</span>
                                            <p>Netizen Revenue Wallet</p>
                                        </div>
                                        <div className="info-button info-btn-4">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid m-t-20">
                        <div className="p-col-12">
                            <div className="panel-box p-0">
                                <div className="panel-box-header">
                                    <h1>Pending Request Information</h1>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12" onClick={e => this.viewPendingTokenDialog(e)}>
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-1">
                                            <i class="fas fa-ticket-alt"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            <h4 className="font-size-50">{pendingRequestInfo && pendingRequestInfo.pendingTokens ? pendingRequestInfo.pendingTokens.toLocaleString('EN-IN') : 0}</h4>
                                            <p className="m-0">Pending Token</p>
                                        </div>
                                        <div className="info-button info-btn-1">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12" onClick={e => this.viewEmOrderDialog(e)}>
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-5">
                                            <i class="fas fa-digital-tachograph"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            <h4 className="font-size-50">{pendingRequestInfo && pendingRequestInfo.pendingInstitutes ? pendingRequestInfo.pendingInstitutes.toLocaleString('EN-IN') : 0}</h4>
                                            <p className="m-0">Eduman Order</p>
                                        </div>
                                        <div className="info-button info-btn-5">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12" >
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-6">
                                            <i class="fas fa-envelope-open"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            <h4 className="font-size-50">0</h4>
                                            <p className="m-0">SMS Pending in SMS Pool</p>
                                        </div>
                                        <div className="info-button info-btn-6">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-7">
                                            <i class="fas fa-wallet"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            <h4 className="font-size-50">{pendingRequestInfo && pendingRequestInfo.discountBillRequests ? pendingRequestInfo.discountBillRequests.toLocaleString('EN-IN') : 0}</h4>
                                            <p className="m-0">Bill Discount Request</p>
                                        </div>
                                        <div className="info-button info-btn-7">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid m-t-20">
                        <div className="p-col-12 p-xl-6 p-md-6 p-sm-12">
                            <div className="panel-box p-l-0">
                                <div className="panel-box-header">
                                    <h1>SMS Remain Balance</h1>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-6 p-md-6" onClick={e => this.viewEdumanSmsBalanceDetailsDialog(e)}>
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-1">
                                            <i class="fas fa-graduation-cap"></i>
                                        </div>
                                        <div className="info-text">
                <h4 className="font-size-30">{this.state.currentStatusDashboardInfo.emSmsBalance && this.state.currentStatusDashboardInfo.emSmsBalance ? this.state.currentStatusDashboardInfo.emSmsBalance.toLocaleString('EN-IN') : 0}</h4>
                                            <p className="">Eduman</p>
                                        </div>
                                        <div className="info-button info-btn-1">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-6 p-md-6" onClick={e => this.viewPendingSmsDialog(e)}>
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-1">
                                            <i class="fas fa-globe-asia"></i>
                                        </div>
                                        <div className="info-text">
                                            <h4 className="font-size-30">{pendingRequestInfo && pendingRequestInfo.smsBalance ? pendingRequestInfo.smsBalance.toLocaleString('EN-IN') : 0}</h4>
                                            <p className="">Netiworld</p>
                                        </div>
                                        <div className="info-button info-btn-1">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-xl-6 p-md-6 p-sm-12">
                            <div className="panel-box p-l-0">
                                <div className="panel-box-header">
                                    <h1>Device Info</h1>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-6 p-md-6" onClick={e => this.viewRemainDeviceDialog(e)}>
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-8">
                                            <i class="fas fa-boxes"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            <h4 className="font-size-50">{pendingRequestInfo && pendingRequestInfo.deviceStockRemain ? pendingRequestInfo.deviceStockRemain.toLocaleString('EN-IN') : 0}</h4>
                                            <p className="m-0">Device Stock Remain</p>
                                        </div>
                                        <div className="info-button info-btn-8">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-6 p-md-6" onClick={e => this.viewPendingDeviceReqDialog(e)}>
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-9">
                                            <i class="fas fa-fingerprint"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            <h4 className="font-size-50">{pendingRequestInfo && pendingRequestInfo.deviceOrderPending ? pendingRequestInfo.deviceOrderPending.toLocaleString('EN-IN') : 0}</h4>
                                            <p className="m-0">Device Order Pending</p>
                                        </div>
                                        <div className="info-button info-btn-9">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="p-grid m-t-20">
                        <div className="p-col-12 p-xl-12 p-md-12 p-sm-12">
                            <div className="panel-box p-l-0">
                                <div className="panel-box-header">
                                    <h1>Eduman Due Bill</h1>
                                </div>
                            </div>
                            <div className="p-grid m-0">
                                <div className="p-col-12 p-xl-6 p-md-12 p-sm-12 p-l-0">
                                    <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover">
                                                <div className="chart-box">
                                                    <Chart
                                                        width={226}
                                                        height={226}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emDueBillStatusArr}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'none', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: {
                                                                0: { color: '#3598DB' },
                                                                1: { color: '#F1C40F' },
                                                            },
                                                        }}
                                                        // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                        render={({ renderChart }) => {
                                                            return (
                                                                <div>{renderChart()}</div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <span className="chart-box-title">Eduman Due Bill Amount</span><br />
                                                <span className="chart-box-title">{totalEmDueBillAmount !== 0 ? this.validatorUtility.currencyFormatter(totalEmDueBillAmount) + ' ' + 'BDT' : 0}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                <div className="chart-box-wrapper">
                                                    <div className="point-list">
                                                        {emDueBillStatus !== undefined ?
                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: "#3598DB" }}></span><p>Active</p></td>
                                                                    <td><p>: {emDueBillStatus[0][1] ? this.validatorUtility.currencyFormatter(emDueBillStatus[0][1]) + ' ' + "BDT" : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#F1C40F" }}></span><p>Inactive</p></td>
                                                                    <td><p>: {emDueBillStatus[1][1] ? this.validatorUtility.currencyFormatter(emDueBillStatus[1][1]) + ' ' + 'BDT' : 0}</p></td>
                                                                </tr>
                                                            </table>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                    <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover">
                                                <div className="chart-box">
                                                    <Chart
                                                        width={226}
                                                        height={226}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emDueInstituteStatusArr}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'none', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: {
                                                                0: { color: '#16A086' },
                                                                1: { color: '#FCA152' },
                                                            },
                                                        }}
                                                        // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                        render={({ renderChart }) => {
                                                            return (
                                                                <div>{renderChart()}</div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <span className="chart-box-title">Eduman Due Institute</span><br />
                                                <span className="chart-box-title">{totalEmDueInstitute !== 0 ? totalEmDueInstitute.toLocaleString('EN-IN') : 0}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                <div className="chart-box-wrapper">
                                                    <div className="point-list">
                                                        {emDueInstituteStatus !== undefined ?

                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: "#16A086" }}></span><p>Active</p></td>
                                                                    <td><p>: {emDueInstituteStatus[0][1] ? emDueInstituteStatus[0][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FCA152" }}></span><p>Inactive</p></td>
                                                                    <td><p>: {emDueInstituteStatus[1][1] ? emDueInstituteStatus[1][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                            </table>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid m-t-20">
                        <div className="p-col-12 p-xl-12 p-md-12 p-sm-12">
                            <div className="panel-box p-l-0">
                                <div className="panel-box-header">
                                    <h1>Current Partner Status</h1>
                                </div>
                            </div>
                            <div className="p-grid m-0">
                                <div className="p-col-12 p-xl-6 p-md-12 p-sm-12 p-l-0">
                                    <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover">
                                                <div className="chart-box">
                                                    <Chart
                                                        width={226}
                                                        height={226}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        data={mbpPartnerStatusArr}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            legend: 'none',
                                                            tooltip: { trigger: 'none', showColorCode: true },
                                                            slices: {
                                                                0: { color: '#27AE5F' },
                                                                1: { color: '#FF3A27' },
                                                                2: { color: '#FFDB10' },
                                                            },
                                                        }}
                                                        render={({ renderChart }) => {
                                                            return (
                                                                <div>{renderChart()}</div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <span className="chart-box-title">Total MBP Area</span><br />
                                                <span className="chart-box-title">{totalMbp !== 0 ? totalMbp.toLocaleString('EN-IN') : 0}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                <div className="chart-box-wrapper">
                                                    <div className="point-list">
                                                        {mbpPartnerStatus !== undefined ?

                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: "#27AE5F" }}></span><p>Active</p></td>
                                                                    <td><p>: {mbpPartnerStatus[0][1] ? mbpPartnerStatus[0][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FF3A27" }}></span><p>Suspend</p></td>
                                                                    <td><p>: {mbpPartnerStatus[1][1] ? mbpPartnerStatus[1][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FFDB10" }}></span><p>Blank</p></td>
                                                                    <td><p>: {mbpPartnerStatus[2][1] ? mbpPartnerStatus[2][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                            </table>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                    <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover">
                                                <div className="chart-box">
                                                    <Chart
                                                        width={226}
                                                        height={226}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        data={zbpPartnerStatusArr}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            legend: 'none',
                                                            tooltip: { trigger: 'none', showColorCode: true },
                                                            slices: {
                                                                0: { color: '#27AE5F' },
                                                                1: { color: '#FF3A27' },
                                                                2: { color: '#FFDB10' },
                                                            },
                                                        }}
                                                        render={({ renderChart }) => {
                                                            return (
                                                                <div>{renderChart()}</div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <span className="chart-box-title">Total ZBP Area</span><br />
                                                <span className="chart-box-title">{totalZbp !== 0 ? totalZbp.toLocaleString('EN-IN') : 0}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                <div className="chart-box-wrapper">
                                                    <div className="point-list">
                                                        {zbpPartnerStatus !== undefined ?

                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: "#27AE5F" }}></span><p>Active</p></td>
                                                                    <td><p>: {zbpPartnerStatus[0][1] ? zbpPartnerStatus[0][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FF3A27" }}></span><p>Suspend</p></td>
                                                                    <td><p>: {zbpPartnerStatus[1][1] ? zbpPartnerStatus[1][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FFDB10" }}></span><p>Blank</p></td>
                                                                    <td><p>: {zbpPartnerStatus[2][1] ? zbpPartnerStatus[2][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                            </table>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-grid m-0">
                                <div className="p-col-12 p-xl-6 p-md-12 p-sm-12 p-l-0 m-t-8">
                                    <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover">
                                                <div className="chart-box">
                                                    <Chart
                                                        width={226}
                                                        height={226}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        data={bdpPartnerStatusArr}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            legend: 'none',
                                                            tooltip: { trigger: 'none', showColorCode: true },
                                                            slices: {
                                                                0: { color: '#27AE5F' },
                                                                1: { color: '#FF3A27' },
                                                                2: { color: '#FFDB10' },
                                                            },
                                                        }}
                                                        render={({ renderChart }) => {
                                                            return (
                                                                <div>{renderChart()}</div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <span className="chart-box-title">Total BDP Area</span><br />
                                                <span className="chart-box-title">{totalBdp !== 0 ? totalBdp.toLocaleString('EN-IN') : 0}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                <div className="chart-box-wrapper">
                                                    <div className="point-list">
                                                        {bdpPartnerStatus !== undefined ?
                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: "#27AE5F" }}></span><p>Active</p></td>
                                                                    <td><p>: {bdpPartnerStatus[0][1] ? bdpPartnerStatus[0][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FF3A27" }}></span><p>Suspend</p></td>
                                                                    <td><p>: {bdpPartnerStatus[1][1] ? bdpPartnerStatus[1][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FFDB10" }}></span><p>Blank</p></td>
                                                                    <td><p>: {bdpPartnerStatus[2][1] ? bdpPartnerStatus[2][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                            </table>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-6 p-md-12 p-sm-12 m-t-8">
                                    <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover">
                                                <div className="chart-box">
                                                    <Chart
                                                        width={226}
                                                        height={226}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        data={bepPartnerStatusArr}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            legend: 'none',
                                                            tooltip: { trigger: 'none', showColorCode: true },
                                                            slices: {
                                                                0: { color: '#27AE5F' },
                                                                1: { color: '#FF3A27' },
                                                                2: { color: '#FFDB10' },
                                                            },
                                                        }}
                                                        render={({ renderChart }) => {
                                                            return (
                                                                <div>{renderChart()}</div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <span className="chart-box-title">Total BEP Area</span><br />
                                                <span className="chart-box-title">{totalBep !== 0 ? totalBep.toLocaleString('EN-IN') : 0}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                <div className="chart-box-wrapper">
                                                    <div className="point-list">
                                                        {bepPartnerStatus !== undefined ?

                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: "#27AE5F" }}></span><p>Active</p></td>
                                                                    <td><p>: {bepPartnerStatus[0][1] ? bepPartnerStatus[0][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FF3A27" }}></span><p>Suspend</p></td>
                                                                    <td><p>: {bepPartnerStatus[1][1] ? bepPartnerStatus[1][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FFDB10" }}></span><p>Blank</p></td>
                                                                    <td><p>: {bepPartnerStatus[2][1] ? bepPartnerStatus[2][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                            </table>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid m-t-20">
                        <div className="p-col-12 p-xl-12 p-md-12 p-sm-12">
                            <div className="panel-box p-l-0">
                                <div className="panel-box-header">
                                    <h1>Current Point Status</h1>
                                </div>
                            </div>
                            <div className="p-grid m-0">
                                <div className="p-col-12 p-xl-12 p-md-12 p-sm-12 p-l-0 p-t-0 p-b-0">
                                    <div className="nw-data-table m-0">
                                        <DataTable
                                            header={unUsedHeader}
                                            responsive={true}
                                            value={pointSummary}
                                            footerColumnGroup={footer}
                                        >
                                            <Column field="" header="Point Name" body={this.pointNameTemplate} filter={true} />
                                            {uniqueYearNames ? uniqueYearNames.map(k => {
                                                return (<Column columnKey={k} header={k} body={e => this.columnTemplate(k, e)} filter={true} />)
                                            })
                                                : ''}
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    header="Wallet Details Report"
                    className="nw-dialog"
                    visible={this.state.visible}
                    // style={{ width: '55vw' }} 
                    onHide={this.onHide}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.userWalletDetailsList}
                                            header={tableHeader}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="customNetiID" header="Neti ID" filter={true} />
                                            <Column field="fullName" header="Name" filter={true} />
                                            <Column field="basicMobile" header="Mobile" filter={true} />
                                            <Column field="balance" header="Balance" body={this.balanceFormatTemplate} filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header="Prepaid Wallet Details Report"
                    className="nw-dialog"
                    visible={this.state.viewPrepaidWalletInfoDialog}
                    // style={{ width: '55vw' }} 
                    onHide={this.onHidePrepaidWalletInfoDialog}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.prepaidWalletDetailsList}
                                            header={prepaidWalletTableHeader}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="customNetiID" header="Neti ID" filter={true} />
                                            <Column field="fullName" header="Name" filter={true} />
                                            <Column field="basicMobile" header="Mobile" filter={true} />
                                            <Column field="balance" header="Balance" body={this.balanceFormatTemplate} filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header="Payable Wallet Details Report"
                    className="nw-dialog"
                    visible={this.state.viewPayableWalletInfoDialog}
                    // style={{ width: '55vw' }} 
                    onHide={this.onHidePayableWalletInfoDialog}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.payableWalletDetailsList}
                                            header={payableWalletTableHeader}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="customNetiID" header="Neti ID" filter={true} />
                                            <Column field="fullName" header="Name" filter={true} />
                                            <Column field="basicMobile" header="Mobile" filter={true} />
                                            <Column field="balance" header="Balance" body={this.balanceFormatTemplate} filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header="Netizen Revenue Wallet Details Report"
                    className="nw-dialog"
                    visible={this.state.viewNetizenRevenueWalletInfoDialog}
                    // style={{ width: '55vw' }} 
                    onHide={this.onHideNetizenRevenueWalletInfoDialog}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.netizenRevenueWalletDetailsList}
                                            header={netizenRevenueWalletTableHeader}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="customNetiID" header="Neti ID" filter={true} />
                                            <Column field="fullName" header="Name" filter={true} />
                                            <Column field="basicMobile" header="Mobile" filter={true} />
                                            <Column field="balance" header="Balance" body={this.balanceFormatTemplate} filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header="Eduman SMS Balance Details Report"
                    className="nw-dialog"
                    visible={this.state.viewEmSmsBalanceInfoDialog}
                    // style={{ width: '55vw' }} 
                    onHide={this.onHideEmSmsBalanceInfoDialog}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.emSmsBalanceList}
                                            header={emSmsBalanceInfoTableHeader}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="instituteId" header="Institute ID" filter={true} />
                                            <Column field="instituteName" header="Institute Name" filter={true} />
                                            <Column field="smsBalance" header="Balance" body={this.smsBalanceFormatTemplate} filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header="Pending Token"
                    className="nw-dialog"
                    visible={this.state.pendingTokenDialog}
                    // style={{ width: '55vw' }} 
                    onHide={this.viewPendingTokenDialogHide}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.pendingTokenList}
                                            header={'Pending Token List'}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="application" header="Application" filter={true} />
                                            <Column field="quantity" header="Quantity" filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header="Eduman Order"
                    className="nw-dialog"
                    visible={this.state.emOrderDialog}
                    // style={{ width: '55vw' }} 
                    onHide={this.viewEmOrderDialogHide}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.emOrderList}
                                            header={emOrderHeader}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="region" header="Region" filter={true} />
                                            <Column field="quantity" header="Quantity" filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header="SMS Remain in Netiworld"
                    className="nw-dialog"
                    visible={this.state.pendingSmsDialog}
                    // style={{ width: '55vw' }} 
                    onHide={this.viewPendingSmsDialogHide}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.pendingSmsList}
                                            header={smsBalanceHeader}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="name" header="Name" filter={true} />
                                            <Column field="netiID" header="Neti ID" filter={true} />
                                            <Column field="quantity" header="Quantity" filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header="Remain Device Info"
                    className="nw-dialog"
                    visible={this.state.remainDeviceDialog}
                    // style={{ width: '55vw' }} 
                    onHide={this.viewRemainDeviceDialogHide}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.remainDeviceList}
                                            header={deviceInfoHeader}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="model" header="Model" filter={true} />
                                            <Column field="quantity" header="Quantity" filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>
             
                <Dialog
                    header="Pending Device Request"
                    className="nw-dialog"
                    visible={this.state.pendingDeviceReqDialog}
                    // style={{ width: '55vw' }} 
                    onHide={this.viewPendingDeviceReqDialogHide}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.pendingDeviceReqList}
                                            header={pendingDeviceReqHeader}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="model" header="Model" filter={true} />
                                            <Column field="quantity" header="Quantity" filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>

            </div>

        )

    }
}