import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { InputTextarea } from 'primereact/inputtextarea';

export class PartnerIncomeTargetList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataTableValue: [{
                date: "test",
                category: "test",
                year: "test",
                month: "test",
                partnerType: "test",
                targetAmount: "test",
                action: "test",
            }],
            proPic: {},
            updateCalendarInfoError: {},
            updatePartnerIncomeTargetDetails: {
                partnerIncomeTargetInfo: {

                    coreUrlInfoDTO: {
                        urlInfoID: 1
                    }
                },
                topProgressBar: false,
                errorMsgVisible: false,
                errorMsgBody: null

            }
        }

    }

    componentWillMount() {
        //this.viewPartnerIncomeTergetInfoList();
    }

    viewPartnerIncomeTergetInfoList = () => {
        this.setState({ topProgressBar: true });

    }


    onHide = (event) => {
        this.setState({ visible: false });
    }

    viewDialog = (rowData) => {
        this.setState({ visible: true });
    }

    parterIncomeTargetDetails = (rowData) => {
        let { updatePartnerIncomeTargetDetails } = this.state;
        this.viewDialog();
        this.setState({ updatePartnerIncomeTargetDetails: rowData });
    }

    actionTemplate = (rowData) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button edit"
                onClick={(e) => this.parterIncomeTargetDetails(rowData)}
            >
            </Button>
        </div>;
    }

    getPartnerType = () => {
        return "Test - ZBP"
    }



    onUpdateHandler = () => {
        

    }

    updateDialogDiscard = () => {
        this.setState({visible: false});
    }

    updateMemberFormHandleError = () => {
        let { updateCalendarInfoError } = this.state;
        let formIsValid = true;


        this.setState({ updateCalendarInfoError });
        return formIsValid;
    }

    render() {
        let { updatePartnerIncomeTargetDetails, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let year = <span className="text-center">Update Partner Income Target Info</span>
        var tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Partner Income Target List
            <span style={{ 'float': 'right' }}>Total Found: {this.state.dataTableValue.length}</span>
        </div>;

        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>

        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid">
                    <div className="nw-data-table">
                        <Growl ref={(el) => this.growl = el} />
                        <DataTable
                            value={this.state.dataTableValue}
                            selectionMode="single"
                            header={tableHeader}
                            selection={this.state.dataTableSelection}
                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                            responsive={true}
                            rows={10}
                            columnResizeMode="fit"
                            paginator={true}
                            rows={5}
                            rowsPerPageOptions={[5, 10, 20]}
                            className="text-center"
                        >
                            <Column field="date" header="Date" filter={true} />
                            <Column field="category" header="Category" filter={true} />
                            <Column field="year" header="Year" filter={true} />
                            <Column field="month" header="Month" filter={true} />
                            <Column field="partnerType" header="Partner Type" filter={true} />
                            <Column field="targetAmount" header="Target Amount" filter={true} />
                            <Column field="action" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                        </DataTable>
                    </div>

                    <Dialog header={year} visible={this.state.visible} className="nw-dialog" onHide={this.onHide} closable>

                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <Growl ref={(el) => this.growl = el} />
                                    <div className="p-grid nw-form">
                                       <div className="p-col-12 p-xl-12">
                                           <div className="nw-form-body">
                                                
                                                    <div className="formControl">
                                                        <label htmlFor='serialNo'>Category {requiredSing}</label>
                                                        <div className="p-inputgroup">
                                                            <Dropdown
                                                                id="bannerCategory"
                                                                placeholder="Select Banner Category"
                                                                showClear={true}
                                                                type="text"
                                                                name="bannerCategory"
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateCalendarInfoError["Category"]}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label htmlFor="name">Year {requiredSing}</label>
                                                        <div className="p-inputgroup">
                                                            <Dropdown
                                                                value={updatePartnerIncomeTargetDetails.banneryear}
                                                                onChange={(e) => this.onChangePartnerIncomeYear(e)}
                                                                placeholder=""
                                                                autoWidth={false}
                                                                type="text"
                                                                id="name"
                                                                name="requestedAmmount"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateCalendarInfoError["year"]}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label htmlFor="name">Month {requiredSing}</label>
                                                        <div className="p-inputgroup">
                                                            <Dropdown
                                                                value={updatePartnerIncomeTargetDetails.banneryear}
                                                                onChange={(e) => this.onChangePartnerIncomeYear(e)}
                                                                placeholder=""
                                                                autoWidth={false}
                                                                type="text"
                                                                id="name"
                                                                name="requestedAmmount"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateCalendarInfoError["year"]}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label htmlFor="designation">Partner Type</label>
                                                        <div className="p-inputgroup">
                                                            <div>
                                                                {this.getPartnerType()}
                                                            </div>
                                                        </div>
                                                        <span className="error-message">{this.state.updateCalendarInfoError["details"]}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label htmlFor="designation">Target Amount</label>
                                                        <div className="p-inputgroup">
                                                            <InputText
                                                                id="parterIncomeTargetDetails"
                                                                onChange={(e) => this.onChangeBannerDetails(e)}
                                                                value={updatePartnerIncomeTargetDetails.parterIncomeTargetDetails}
                                                                placeholder=""
                                                                name="parterIncomeTargetDetails"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateCalendarInfoError["details"]}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label htmlFor="designation">Note {requiredSing}</label>
                                                        <div className="p-inputgroup">
                                                            <InputTextarea
                                                                id="parterIncomeTargetDetails"
                                                                onChange={(e) => this.onChangeBannerDetails(e)}
                                                                value={updatePartnerIncomeTargetDetails.parterIncomeTargetDetails}
                                                                placeholder=""
                                                                style={{ width: "100%" }}
                                                                type="text"
                                                                rows={3}
                                                                cols={30}
                                                                name="parterIncomeTargetDetails"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateCalendarInfoError["details"]}</span>
                                                    </div>


                                                    {/* <div className="formControl">
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-xl-12 nw-button-parent" >
                                                                <Button
                                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                                    label="Update"
                                                                    onClick={this.onUpdateHandler}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        onClick={this.updateDialogDiscard}
                                                    />
                                                    <Button
                                                        label="Update"
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        icon="fas fa-check"
                                                        onClick={this.onUpdateHandler}
                                                        />
                                                </div>
                                                
                                            </div>
                                        </div>

                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </Dialog>

                </div>
            </div>
        );
    }
}
