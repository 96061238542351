import React, {Component} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import { UserCoreUrlInfoService } from '../../../service/UserCoreUrlInfoService';

const languageList=[
    { label: "English", value: "English"},
    { label: "Bangla", value: "Bangla"}
]
let urlId=null;
export class AdmisiaPointSocialLinkFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            updateSocialLink:{},
            instituteInformation: {
                defaultLanguage:''
            },
            errorMsgVisible:false,
            defaultLanguageValue:'',
            topProgressBar: false,
            errorMsgBody: ''
        };

        this.userCoreUrlInfoService=new UserCoreUrlInfoService();
        this.admisiaPointService=new AdmisiaPointService();
        this.onChangeDefaultLanguage = this.onChangeDefaultLanguage.bind(this);
    }

    componentWillMount(){
        urlId=this.admisiaPointService.getUrlInfoFromLocalStorage();
    }

    onHide = (event) => {
        this.setState({visible: false});
    }

    viewDialog = (e) => {
        this.setState({ visible: true });
    }

    onChangeSocialBtn = (e) => {
        this.viewDialog();
    }

    onChangeDefaultLanguage = (e) =>{
        let { instituteInformation } = this.state;
        this.setState({ defaultLanguageValue: e.target.value})
        Object.assign(instituteInformation, this.props.instituteDetails)
        instituteInformation.defaultLanguage = e.target.value;
        this.setState({instituteInformation });
        this.onUpdateDefaultLanguage()
    }

    onUpdateDefaultLanguage = () => {
        let {instituteInformation} = this.state;

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.userCoreUrlInfoService.updateInstituteInfo(instituteInformation)
        .then(res=>{
            if(res.status == 202){
                return res.json().then((body) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    this.growl.show({severity: 'success', summary: 'Success', detail: 'Successfully submitted.'});
                })
            }
            if(res.status == 404){
                return res.json().then((body) => {
                    this.setState({checkEligibility: false});
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please insert your institute details" });
                });
            }
            else{
                this.userCoreUrlInfoService.Auth.handleErrorStatus(res)
                .then((resp)=>{
                    this.setState({dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                });
            }

        }).catch(error => {
            this.setState({topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
        })
    }

   
    


    render() {
        let { updateSocialLink, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        return (

            
            <div className="card card-w-title">
                { topProgressBar?
                    <ErrorMessageView 
                        topProgressBar={topProgressBar} 
                    />
                    : null
                }
                { errorMsgVisible ?
                    <ErrorMessageView 
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <h1>Toolbar</h1>
                <Toolbar>
                    <div className="p-toolbar-group-left">
                        <div className="formControl">
                            <label style={{ color: "white" }} htmlFor="language">Language</label>
                            <div className="p-inputgroup">
                                <Dropdown
                                    id="language"
                                    placeholder={this.props.defaultLanguage || "Select"}
                                    autoWidth={false}
                                    name="language"
                                    options={languageList}
                                    value={this.state.defaultLanguageValue}
                                    onChange={this.onChangeDefaultLanguage}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="p-toolbar-group-right">
                        <a href="https://www.fb.com" target="_blank">
                            <span ><i style={{ 'fontSize': '2em', color: "white", paddingRight: "5px" }} className="fab fa-facebook-square"></i></span>
                        </a>
                        <span ><i style={{ 'fontSize': '2em', color: "white", paddingRight: "5px" }} className="fab fa-twitter-square"></i></span>
                        <span ><i style={{ 'fontSize': '2em', color: "white", paddingRight: "5px" }} className="fab fa-linkedin-square"></i></span>
                        <span ><i style={{ 'fontSize': '2em', color: "white", paddingRight: "5px" }} className="fab fa-youtube-square"></i></span>
                        <Button id="toolbarEditButton" onClick={this.onChangeSocialBtn} style={{ 'fontSize': '1.8em', color: "white", margin:0}} icon="fas fa-edit" Title="Edit Social Links"/>
                    </div>

                </Toolbar>

                <Dialog 
                    header="Edit Social Link" 
                    visible={this.state.visible} 
                    className="nw-dialog"
                    onHide={this.onHide} 
                    closable>
                    <div className="p-fluid">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Facebook Link</span>
                                            <InputText
                                                id="fb"
                                                placeholder="Enter fb link"
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="fb"
                                                value={ updateSocialLink.facebookProfile }
                                                onChange={(e) => this.onChangeFaceBookLink(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Twitter Link</span>
                                            <InputText
                                                id="twitter"
                                                placeholder="Enter twitter link"
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="twitter"
                                                value={ updateSocialLink.twitterProfile }
                                                onChange={(e) => this.onChangeTwitterLink(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Linkedin Link</span>
                                            <InputText
                                                placeholder="Enter linkedin Link"
                                                style={{ width: "100%" }}
                                                type="text"
                                                id="linkedin"
                                                name="linkedin"
                                                value={ updateSocialLink.linkedInProfile }
                                                onChange={(e) => this.onChangeLinkedInLink(e)}
                                            />
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">YouTube Link</span>
                                            <InputText
                                                placeholder="Enter youtube link"
                                                style={{ width: "100%" }}
                                                type="text"
                                                id="utube"
                                                name="utube"
                                                value={ updateSocialLink.youTubeProfile }
                                                onChange={(e) => this.onChangeYouTubeLink(e)}
                                            />
                                        </div>
                                    </div>

                                    
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">

                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={e => this.onHide()}

                                        />

                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Update"
                                            icon="fas fa-check"
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Dialog>
            </div>
        );
    }
}


