import React, { Component } from 'react';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';

export class TeacherBasicInfo extends Component {

    constructor(props) {
        super(props);
        this.teacherPortal = new TeacherPortal();
    }

    render() {

        let designation, gender, religion,
            dateOfBirth, bloodGroup, mobile,
            email, joiningDate, resignDate

        if (this.props.basicInfo.item) {
            designation = this.props.basicInfo.item.designationName;
            gender = this.props.basicInfo.item.gender;
            religion = this.props.basicInfo.item.staffReligion;
            dateOfBirth = this.props.basicInfo.item.staffDob;
            bloodGroup = this.props.basicInfo.item.bloodGroup;
            mobile = this.props.basicInfo.item.staffMobile1;
            email = this.props.basicInfo.item.staffEmail;
            joiningDate = this.props.basicInfo.item.joiningDate;
            resignDate = this.props.basicInfo.item.resignDate;
        }

        return (
            <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                <div className="protal-single-info-box">
                    <div className="protal-single-info-title">
                        <h4>Basic Info</h4>
                    </div>
                    <div className="protal-single-info-content-box">
                        <div className="protal-single-info-content">
                            <h4>Designation</h4>
                            <p>{designation || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Gender</h4>
                            <p>{gender}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Religion</h4>
                            <p>{religion || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Date of Birth</h4>
                            <p>{dateOfBirth || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Mobile No.</h4>
                            <p>{mobile || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Email</h4>
                            <p>{email || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Joining Date</h4>
                            <p>{joiningDate || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Resign Date</h4>
                            <p>{resignDate || '---'}</p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}