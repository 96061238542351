import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { DomainApproval } from './DomainApproveReport';
import { HostingApproval } from './HostingApproveReport';

export class DomainHostingApproveReport extends Component {
    
    render() {
        return (
            <div className="p-fluid">
                <div className="p-grid">

                    <div className="p-col-12 p-lg-12 p-xl-12 nw-tabview">
                        <TabView >
                            <TabPanel header="Domain">
                                <DomainApproval />
                            </TabPanel>
                            <TabPanel header="Hosting">
                                <HostingApproval/>
                            </TabPanel>
                        </TabView>
                    </div>

                </div>
            </div>
        )
    }
}