import { BIJOY_POINT } from './PointWiseRouteConsts';

export const BijoyPointMenu = {

    items: [
        {label: 'Bijoy Point', icon: 'fab fa-bootstrap',

        items: [
            { label: 'Download Content', icon: '',
            items: [
                { label: 'Bijoy Content', icon: '', to: BIJOY_POINT.BIJOY_CONTENT },//pi pi-fw pi-user
                { label: 'Essential Software', icon: '', to: BIJOY_POINT.ESSENTIAL_SOFTWARE } //pi pi-fw pi-user
            ]
         },
         { label: 'Device Unlock', icon: '',
         items: [
             { label: 'New User', icon: '', to: BIJOY_POINT.BIJOY_NEW_USER },//pi pi-fw pi-user
             { label: 'Existing User', icon: '', to: BIJOY_POINT.BIJOY_EXISTING_USER } //pi pi-fw pi-user
         ]
      }
        ]

          
        
        
        
        
        
        
        
        
        
        // items: [
            //     { label: 'Download Content', icon: '', to: BIJOY_POINT.DOWNLOAD_CONTENT },//pi pi-fw pi-user
            //     { label: 'Device Unlock', icon: '', to: BIJOY_POINT.DEVICE_UNLOCK } //pi pi-fw pi-user
            // ]
        }
    ]
}