import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import NetiContentLoader from './NetiContentLoader';
import { ErrorMessageView } from './ErrorMessageView';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';
import {NetiDateUtils} from '../../utils/NetiDateUtils';
import { Link } from 'react-router-dom';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { Editor } from 'primereact/editor';
import ReactDOM from 'react-dom';
import CommonFuctionality from './CommonFuctionality';




let noticeListObj = {};
let slideIndex = 0;
let timerId;
let timeCount = 5000;
export class UniversalNotice extends Component {
    constructor(props) {
      super(props);
      this.state = {
        netiworldCoreNoticesList: [],
        purchaseCode: "",
        activeIndex: 1,
        purchaseCodeError: [],
        userInfoShow: false,
        userInfo: {
          productName: "",
          deviceID: "",
          password: "",
          mobileNo: ""
        },
        topProgressBar: false,
        searchIsLoading: false,
        errorMsgVisible: false,
        errorMsgBody: '',
        searchErrorMsgBody: '',
        searchView: false,
        searchViewError: false,
        pageValue: 0,
        multipleAccrodion: 1
      };
  
      this.NetiContentLoader = new NetiContentLoader();
      this.netiFileHandler = new NetiFileHandler();
      this.UserOtherAccessService = new UserOtherAccessService();
      this.CommonFuctionality = new CommonFuctionality();

      this.showSlides();
    }

    componentWillMount() {
        this.getNetiworldCoreNotices();
        // this.showSlides();

        
    }

    getNetiworldCoreNotices() {
        this.setState({ topProgressBar: true, errorMsgVisible: false });

        this.UserOtherAccessService.findCoreNoticesForNetiworld()
            .then(res => {
                if (res.status === 302 ) {
                    return res.json().then((body) => {
                        this.setState({ netiworldCoreNoticesList: body })
                        this.setState({ topProgressBar: false });
                    });
                }
                else {
                    this.UserOtherAccessService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: false, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });

    }

    onTabChange = (e) => {
        this.setState({activeIndex: e.index})

    }

    

    onClickAttachmentDownload = (netiworldCoreNotices) => {
        if ( netiworldCoreNotices.attachmentPath !== null && netiworldCoreNotices.attachmentFileName !== null) {
            this.netiFileHandler.getByteImage( netiworldCoreNotices.attachmentPath ).then(res => {
                if (res.status == 200) {
                    return res.json().then(body => {
                        let contentType = this.netiFileHandler.getImageContentType(
                            netiworldCoreNotices.attachmentFileName
                        );
                        let a = document.createElement("a");
                        a.href = contentType + body.fileContent;
                        a.download = netiworldCoreNotices.attachmentFileName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    });
                } else {
                }
            });
        }

    }

    onClickSliderHeader = (e) =>{
        let sliderMain = document.getElementsByClassName("slider-main")

        for (let i = 0 ; i < sliderMain.length; i++ ){
            if(sliderMain[i] != undefined){
                if(sliderMain[i].childNodes != undefined){
                    var x = sliderMain[i].childNodes[1];
                    
                    if (x.style.display === "none" || x.style.display === ""  ) {
                        x.style.display = "block";
                    } else {
                        x.style.display = "none";
                    }
                }
                this.CommonFuctionality.sliderEffect(sliderMain[i], sliderMain[i].id);
            }
        }

    }

    showSlides = () => {
        var i;
        var slides = document.getElementsByClassName("slider-main");
        for (i = 0; i < slides.length; i++) {
            if(slides[i] != undefined){
                slides[i].style.display = "none";  
            }
        }
        slideIndex++;
        if (slideIndex > slides.length){
            slideIndex = 1
        }
        if(slides[slideIndex-1] != undefined){
            slides[slideIndex-1].style.display = "block";  
        }

        this.setState({ slideIndex : slideIndex})
        timerId = setTimeout(this.showSlides, timeCount); 
    }

    pause() {
        clearTimeout(timerId)
    }

    resume() {
        clearTimeout(timerId)
        timerId = setTimeout(this.showSlides, timeCount);
    }

    start() {
        timerId = setTimeout(this.showSlides, timeCount);
    }

    mouseOver = () =>{
        this.pause()
    }

    mouseOut = () =>{
        this.start();
    }

    onChangeValue = (e) => {
        var i;
        var slides = document.getElementsByClassName("slider-main");
        if (e == "plus") {
            for (i = 0; i < slides.length; i++) {
                if(slides[i] != undefined){
                    slides[i].style.display = "none";  
                }
            }
            slideIndex++;
            if (slideIndex > slides.length) {slideIndex = 1}    

            if(slides[slideIndex-1] != undefined){
                slides[slideIndex-1].style.display = "block";  
            }
        }
        else if (e == "minus") {
            for (i = 0; i < slides.length; i++) {
                if(slides[i] != undefined){
                    slides[i].style.display = "none";
                }
            }
            slideIndex--;
            if (slideIndex == 0) {slideIndex = slides.length }
            if(slides[slideIndex-1] != undefined){
                slides[slideIndex-1].style.display = "block";
            }
        }

        this.setState({ slideIndex : slideIndex})
    }

    

    render() {
        
        let { netiworldCoreNoticesList,topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let noticeHeader;
        noticeHeader = (netiworldCoreNotices) =>{
            if (this.state.netiworldCoreNoticesList.length !== 0) {
            return(
                    <div className="header">
                        <div className="header-left">
                            <i class="fas fa-flag"></i> 
                            <span className="message-title">{netiworldCoreNotices.noticeTitle}</span>
                        </div>
                        <div className="header-right">
                            <span className="message-qty">{+(this.state.slideIndex || 1) + '/' + netiworldCoreNoticesList.length}</span>
                            <span >See in Details <i class="fas fa-chevron-down"></i> </span>
                        </div>
                    </div>
                )
            }
        }

        return (
            <div className="p-fluid blur-section">
                {topProgressBar ?
                    <ErrorMessageView
                    topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                    errorMsgVisible={errorMsgVisible}
                    errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                
                <div className="accrodion-section universal-notice-section">
                    <div className="p-grid" style={{ flexWrap: "inherit"}}>

                        {netiworldCoreNoticesList.length == 0 ?
                            <div className="p-col-12 slider-main">
                                <a href="#"> 
                                    <div className="header">
                                        <div className="header-left">
                                            <i class="fas fa-flag text-danger"></i> 
                                            <span className="message-title">No notice available</span>
                                        </div>
                                        <div className="header-right">
                                            {/* <span className="message-qty">{+(this.state.slideIndex) + '/' + netiworldCoreNoticesList.length}</span>
                                            <span >See in Details <i class="fas fa-chevron-down"></i> </span> */}
                                        </div>
                                    </div>
                                </a>    
                            </div>
                            :
                            netiworldCoreNoticesList.map((netiworldCoreNotices) => 
                                <div className="p-col-12 slider-main" onMouseOver={ this.mouseOver } onMouseOut={ this.mouseOut }>

                                    <a href="#" onClick={ e => this.onClickSliderHeader(e)}> {noticeHeader(netiworldCoreNotices)}</a>
                                    <div className="notice-main">
                                        <div className="p-col-12 notice-body" >
                                            <Editor
                                                value={ netiworldCoreNotices.noticeDetails }
                                                className="nw-inputtexteditor only-view"
                                                readOnly={true}
                                                headerTemplate={ e => {return null}}
                                            />
                                        </div>
                                        <div className="p-col-12 notice-footer">
                                            <div className="footer-left">
                                                <span className="download-attachment">
                                                    <i class="fas fa-paperclip"></i> 
                                                    <u> 
                                                        <a href='#' onClick={ e => this.onClickAttachmentDownload(netiworldCoreNotices)}> DOWNLOAD ATTACHMENT</a>
                                                    </u>
                                                </span>

                                                <span className="date">
                                                    <i class="fas fa-clock"></i> 
                                                    <span className="published-title"> PUBLISHED ON :</span>
                                                    <span className="published-date"> {NetiDateUtils.getAnyShortForm(netiworldCoreNotices.publishDate, 'DD-MMM-YYYY')}</span> 
                                                </span>
                                                <span className="date">
                                                    <i class="fas fa-clock"></i> 
                                                    <span className="expire-title"> EXPIRE ON :</span>
                                                    <span className="expire-date"> {NetiDateUtils.getAnyShortForm(netiworldCoreNotices.expireDate, 'DD-MMM-YYYY')}</span> 
                                                </span>
                                            </div>
                                            <div className="footer-right">
                                                    <Button 
                                                        label="PREV" 
                                                        icon="fas fa-chevron-left" 
                                                        className="desktop-button"
                                                        onClick={ e => this.onChangeValue("minus")}
                                                        />
                                                        |
                                                    <Button 
                                                        label="NEXT" 
                                                        icon="fas fa-chevron-right" 
                                                        className="desktop-button" 
                                                        iconPos="right" 
                                                        onClick={ e => this.onChangeValue("plus")}
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                            
                                
                                </div>
                            )
                        }
                    </div>
                </div>
                
            </div>
        );
    }
}