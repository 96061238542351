import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import NetiContentLoader from '../../common/NetiContentLoader';
import { DownloadButton } from "../../../utils/DownloadButton";
import CommonFuctionality from '../../common/CommonFuctionality';



let errors = {};
let urlId;
const userUrlInfo = localStorage.getItem('dwsUrlInfo');
export class SyllabusInfoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            proPic: {},
            memberStatus: '',
            dataTableValue: [],
            dataTableIsLoading: false,
            updateSyllabusInfoDetails: {
                syllabusSerial: '',
                classCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                groupCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            errors: {},

        }

        this.DwsService = new DwsService();
        //this.groupNameTemplate = this.groupNameTemplate.bind(this);
        this.onChangeClass = this.onChangeClass.bind(this);
        this.onChangeGroup = this.onChangeGroup.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.commonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        this.viewSyllabusInfoList();
    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    viewSyllabusInfoList = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' })
        let syllabusInfoList = {
            urlInfoID: userUrlInfo
        }
        this.DwsService.getDwsSyllabusInfoList(syllabusInfoList)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.props.countTableSize(body.length);
                        this.setState({
                            dataTableValue: body
                        });
                        this.setState({ topProgressBar: false, errorMsgVisible: false, errorMsgBody: '' })
                    });
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!" })
            });
    }

    viewDownloadData = (rowData) => {
        this.setState({ visible: true });

        //let { updateSyllabusInfoDetails } = this.state
        // updateSyllabusInfoDetails.syllabusId = rowData.syllabusId
        // updateSyllabusInfoDetails.syllabusSerial = rowData.syllabusSerial
        // updateSyllabusInfoDetails.classCategoryInfoDTO.coreCategoryID = rowData.classCategoryInfoDTO.coreCategoryID
        // updateSyllabusInfoDetails.groupCategoryInfoDTO.coreCategoryID = rowData.groupCategoryInfoDTO.coreCategoryID
        // updateSyllabusInfoDetails.classCategoryInfoDTO.categoryName = rowData.classCategoryInfoDTO.categoryName
        // updateSyllabusInfoDetails.groupCategoryInfoDTO.categoryName = rowData.groupCategoryInfoDTO.categoryName
        // updateSyllabusInfoDetails.coreUrlInfoDTO.urlInfoID = rowData.coreUrlInfoDTO.urlInfoID

        this.setState({ updateSyllabusInfoDetails: { ...rowData } });
        console.log('Log: SyllabusInfoList -> viewDownloadData -> updateSyllabusInfoDetails', rowData)

    }

    actionTemplate = (rowData, column) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                onClick={(e) => this.viewDownloadData(rowData)}
            >
            </Button>
        </div>;
    }

    // classNameTemplate = (rowData, column) => {
    //     return rowData.classCategoryInfoDTO.categoryName
    // }

    // groupNameTemplate = (rowData, column) => {
    //     return rowData.groupCategoryInfoDTO.categoryName;
    // }

    onChangeSyllabusSerial = (e) => {
        errors = {}
        let { updateSyllabusInfoDetails } = this.state;
        updateSyllabusInfoDetails.syllabusSerial = e.target.value
        this.setState({ updateSyllabusInfoDetails, errors })
    }

    onChangeClass = (e) => {
        errors = {}
        let { updateSyllabusInfoDetails } = this.state;
        updateSyllabusInfoDetails.classCategoryInfoDTO.coreCategoryID = e.target.value
        this.setState({ updateSyllabusInfoDetails, errors })
    }

    onChangeGroup = (e) => {
        errors = {}
        let { updateSyllabusInfoDetails } = this.state;
        updateSyllabusInfoDetails.groupCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ updateSyllabusInfoDetails, errors })
    }

    onImageUpload(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album, errors: {} });
        }
    }

    handleError() {
        let { updateSyllabusInfoDetails } = this.state
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (updateSyllabusInfoDetails.syllabusSerial === '') {
            formIsValid = false;
            errors["serialNumber"] = "Serial can't left empty.";
        }
        if (!updateSyllabusInfoDetails.classCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["classError"] = "Class can't left empty.";
        }
        if (!updateSyllabusInfoDetails.groupCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["groupError"] = "Group can't left empty.";
        }
        if (!this.state.proPic.contentName && !updateSyllabusInfoDetails.syllabusFileName) {
            formIsValid = false;
            errors["attachment"] = "Attachment can't left empty.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    updateSyllabusInfo = (event, props) => {
        let { updateSyllabusInfoDetails } = this.state;

        if (this.state.proPic.contentPic) {
            updateSyllabusInfoDetails.syllabusFileContent = this.state.proPic.contentPic;
            updateSyllabusInfoDetails.syllabusFileSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            // updateSyllabusInfoDetails.syllabusFileName = Date.now() + extention;
            updateSyllabusInfoDetails.syllabusFileName = this.state.proPic.contentName;
        }

        if (this.handleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.setState({ updateSyllabusInfoDetails });

            this.DwsService.updateSyllabusInfo(updateSyllabusInfoDetails)
                .then(res => {

                    if (res.status === 202) {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: "success", summary: "Success Message", detail: "Successfully Updated", life: 800 });
                        setTimeout(() => {
                            this.onHide();
                        }, 800);
                        this.viewSyllabusInfoList();
                    } else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        }
    }

    cancelSyllabusDetails = (e) => {
        this.setState({ visible: false });
    }


    render() {
        let { dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody, updateSyllabusInfoDetails } = this.state;

        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                                Syllabus Info List
                            </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length}
                                </a>
                            </div>
                            </div>;
        return (

            <div>

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            <Growl ref={(el) => this.growl = el} />
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                    columnResizeMode="fit"
                                >
                                    <Column field="syllabusSerial" header="Serial No." filter={true} />
                                    <Column field="classCategoryInfoDTO.categoryName" header="Class" filter={true} />
                                    <Column field="groupCategoryInfoDTO.categoryName" header="Group" filter={true} />
                                    <Column field="action" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                </DataTable>
                            }
                        </div>
                    </div>


                    <Dialog 
                        className="nw-dialog" 
                        header="Update Syllabus Information" 
                        visible={this.state.visible} 
                        onHide={this.onHide} 
                        closable
                    >
                        <div className="p-fluid">

                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No. <span>*</span></span>

                                                <div className="nw-inputgroup-desc">
                                                    { updateSyllabusInfoDetails.syllabusSerial }
                                                </div>
                                            </div>
                                            <span className='error-message'></span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Class <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Enter Class"
                                                    value={updateSyllabusInfoDetails.classCategoryInfoDTO.coreCategoryID}
                                                    options={this.props.classList}
                                                    onChange={this.onChangeClass}
                                                    // itemTemplate={this.bankTemplate}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["classError"]}</span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Group <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Enter Group"
                                                    value={updateSyllabusInfoDetails.groupCategoryInfoDTO.coreCategoryID}
                                                    options={this.props.groupList}
                                                    onChange={this.onChangeGroup}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["groupError"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                                            <div className="formControl nw-upload-button">
                                                <label htmlFor="title">Upload File</label>
                                                <div className="nw-upload-button-inside">
                                                    <div className="image-view-main">
                                                        <div className="upload-image-view">
                                                            <Button
                                                                className="delete-upload-button"
                                                                icon="fas fa-times-circle"
                                                                onClick={e => this.setState({ proPic: { contentPic: '' } })}
                                                            />
                                                            {this.state.proPic.contentName ?
                                                                this.commonFuctionality.getFileContentTypeIcon(this.state.proPic.contentName) : this.commonFuctionality.getFileContentTypeIcon(updateSyllabusInfoDetails.syllabusFileName)}

                                                            <DownloadButton fileName={updateSyllabusInfoDetails.syllabusFileName} filePath={updateSyllabusInfoDetails.syllabusFilePath} />
                                                        </div>
                                                    </div>

                                                    <FileUpload
                                                        chooseLabel={this.state.proPic.contentName || updateSyllabusInfoDetails.syllabusFileName}
                                                        mode="basic"
                                                        maxFileSize={1000000}
                                                        onSelect={this.onImageUpload.bind(this)}
                                                        auto={true}
                                                    />
                                                </div>
                                            </div>
                                            <span className="error-message">{this.state.errors["attachment"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                            <Button
                                                className="p-button p-button-danger nw-button nw-button-multiple"
                                                label="Discard"
                                                icon="fas fa-times"
                                                onClick={this.cancelSyllabusDetails}
                                            />
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-multiple"
                                                label="Update"
                                                icon="fas fa-check"
                                                onClick={this.updateSyllabusInfo.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                           <div className="p-grid">
                               <div className="p-col-12 p-xl-12">
                                  <div className="p-grid nw-form">
                                    <div className="p-col-12 p-xl-12">
                                        <Growl ref={(el) => this.growl = el} />
                                        <div className="nw-form-body">
                                            
                                                {/* <div className="formControl">
                                                    <label htmlFor='serialNo'>Serial No.</label>
                                                    <div className="p-inputgroup">
                                                        <InputText
                                                            value={updateSyllabusInfoDetails.syllabusSerial}
                                                            id="serialNo"
                                                            placeholder="Enter serial number"
                                                            showClear={true}
                                                            style={{ width: "100%" }}
                                                            type="text"
                                                            name="serialNumber"
                                                            onChange={(e) => this.onChangeSyllabusSerial(e)}
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.errors["serialNumber"]}</span>
                                                </div> */}


                                                {/* <div className="formControl">
                                                    <label htmlFor="class">Class</label>
                                                    <div className="p-inputgroup">
                                                        <Dropdown
                                                            placeholder="Enter Class"
                                                            value={updateSyllabusInfoDetails.classCategoryInfoDTO.coreCategoryID}
                                                            options={this.props.classList}
                                                            onChange={this.onChangeClass}
                                                            // itemTemplate={this.bankTemplate}
                                                            filter={true}
                                                            filterBy="label,value"
                                                            showClear={true}
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.errors["classError"]}</span>
                                                </div> */}

                                                {/* <div className="formControl">
                                                    <label htmlFor="group">Group</label>
                                                    <div className="p-inputgroup">
                                                        <Dropdown
                                                            placeholder="Enter Group"
                                                            value={updateSyllabusInfoDetails.groupCategoryInfoDTO.coreCategoryID}
                                                            options={this.props.groupList}
                                                            onChange={this.onChangeGroup}
                                                            filter={true}
                                                            filterBy="label,value"
                                                            showClear={true}
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.errors["groupError"]}</span>
                                                </div> */}


                                                

                                                {/* <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        onClick={this.cancelSyllabusDetails}
                                                    />
                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        label="Update"
                                                        icon="fas fa-check"
                                                        onClick={this.updateSyllabusInfo.bind(this)}
                                                    />
                                                </div> */}
                                        
                                        </div>

                                    </div>
                                  </div>

                               </div>
                           </div>

                        </div>


                    </Dialog>

                </div>
            </div>
        );
    }
}