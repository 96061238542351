import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { AdmisiaPointViewInstitute } from './AdmisiaPointViewInstitute';
import { AdmisiaPointSocialLinkFooter } from './AdmisiaPointSocialLinkFooter';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { FileUpload } from 'primereact/fileupload';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { UserCoreUrlInfoService } from '../../../service/UserCoreUrlInfoService';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';


let urlId = null;
export class InstituteConfigHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultLanguage: "English",
            errors: {},
            proPic: {},
            checkURLInfo: {
                urlName: '',
                applicationType: "admisia",
                applicationPackage: "adm-basic",
            },
            admisiaInstituteConfigInfo: {
                applicationType: "admisia",
                applicationPackage: "adm-basic",
                applicationTheme: "rebirth",
                defaultLanguage: "English",
                instituteName: '',
                instituteAddress: '',
                instituteContact: '',
                instituteEmail: '',
                urlName: '',
                logoName: '',
                logoPath: '',
                logoContent: '',
                logoSaveOrEditable: false
            },

            instituteInfo: {
                urlInfoID: '',
                instituteName: '',
                instituteAddress: '',
                instituteContact: '',
                instituteEmail: '',
                urlName: '',
                urlCreateDate: '',
                imageContent: ''
            },
            checkEligibility: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: ''
        }

        this.fetchInstituteInfoDetails = this.fetchInstituteInfoDetails.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.userCoreUrlInfoService = new UserCoreUrlInfoService();
        this.admisiaPointService = new AdmisiaPointService();
        this.validatorUtility = new ValidatorUtility();
    }

    componentWillMount() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
    }
    componentDidMount() { this.fetchInstituteInfoDetails() }

    fetchInstituteInfoDetails() {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.userCoreUrlInfoService.fetchInstituteDetails('admisia', 'adm-basic')
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ instituteInfo: body });
                        this.setState({ checkEligibility: true });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                if (res.status == 404) {
                    return res.json().then((body) => {
                        this.setState({ checkEligibility: false });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }

            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }

    handleUrlError() {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.checkURLInfo.urlName === '') {
            formIsValid = false;
            this.setState({ urlMsgView: 'text-danger', urlErrorMsgBody: "Please fill url " })
        }

        this.setState({ errors });
        return formIsValid;
    }

    onChangeCheckDwsUrl = () => {

        if (this.handleUrlError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.userCoreUrlInfoService.checkExistanceOfUrl('admisia', 'adm-basic', this.state.checkURLInfo)
                .then(res => {
                    return res.json().then(body => {
                        if (body == true) {
                            this.setState({ urlMsgView: 'text-danger', urlErrorMsgBody: "This URL is unavailable. Please try another one." })
                            this.setState({ componentIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        }
                        else {
                            this.setState({ urlMsgView: 'text-success', urlErrorMsgBody: "This URL is available." })
                            this.setState({ componentIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        }
                    });

                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }


    }

    onChangeInstituteName = (e) => {
        let { admisiaInstituteConfigInfo } = this.state;
        admisiaInstituteConfigInfo.instituteName = e.target.value;
        this.setState({ admisiaInstituteConfigInfo });
    }

    onChangeInstituteAddress = (e) => {
        let { admisiaInstituteConfigInfo } = this.state;
        admisiaInstituteConfigInfo.instituteAddress = e.target.value;
        this.setState({ admisiaInstituteConfigInfo });
    }

    onChangeInstituteContact = (e) => {
        let { admisiaInstituteConfigInfo } = this.state;
        admisiaInstituteConfigInfo.instituteContact = e.target.value;
        this.setState({ admisiaInstituteConfigInfo });
    }

    onChangeInstituteEmail = (e) => {
        let { admisiaInstituteConfigInfo, errors } = this.state;
        admisiaInstituteConfigInfo.instituteEmail = e.target.value;
        errors["instituteEmail"] = '';
        this.setState({ admisiaInstituteConfigInfo, errors });
    }

    onChangeUrlName = (e) => {
        let { checkURLInfo } = this.state;
        checkURLInfo.urlName = e.target.value;
        this.setState({ checkURLInfo, urlErrorMsgBody: '' });
    }

    onBasicUploadAuto(e) {
        let { admisiaInstituteConfigInfo } = this.state;

        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });
            admisiaInstituteConfigInfo.logoSaveOrEditable = true

        }
    }

    onSubmitUrlInfo = () => {
        let { admisiaInstituteConfigInfo, checkURLInfo } = this.state;

        if (this.handleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            if (admisiaInstituteConfigInfo.logoSaveOrEditable === true) {
                admisiaInstituteConfigInfo.logoContent = this.state.proPic.contentPic;
                // let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
                // admisiaInstituteConfigInfo.logoName = admisiaInstituteConfigInfo.urlName + extention;
                admisiaInstituteConfigInfo.logoName = this.state.proPic.contentName;

            }
            admisiaInstituteConfigInfo.urlName = checkURLInfo.urlName;

            this.userCoreUrlInfoService.createNewUrlInfo(admisiaInstituteConfigInfo)
                .then(res => {
                    if (res.status == 201) {
                        return res.json().then(body => {
                            this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Created!" });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                            this.setState({ errors: {} })
                            this.fetchInstituteInfoDetails();
                        });
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }


    handleError() {

        let { admisiaInstituteConfigInfo, errors, checkURLInfo } = this.state;
        let formIsValid = true;

        if (admisiaInstituteConfigInfo.instituteName === '') {
            formIsValid = false;
            errors["instituteName"] = "Institute Name can't left empty";
        }
        if (admisiaInstituteConfigInfo.instituteAddress === '') {
            formIsValid = false;
            errors["instituteAddress"] = "Address can't left empty";
        }
        if (admisiaInstituteConfigInfo.instituteContact === '') {
            formIsValid = false;
            errors["instituteContact"] = "Contact can't left empty";
        }
        if (admisiaInstituteConfigInfo.instituteEmail === '') {
            formIsValid = false;
            errors["instituteEmail"] = "Email can't left empty";
        }
        else if (!this.validatorUtility.emailIsValid(admisiaInstituteConfigInfo.instituteEmail)) {
            formIsValid = false;
            errors["instituteEmail"] = "Invalid Email format";

        }

        if (checkURLInfo.urlName === '') {
            formIsValid = false;
            errors["urlName"] = "Please fill up URL";
        }
        if (admisiaInstituteConfigInfo.logoSaveOrEditable === false) {
            formIsValid = false;
            errors["logo"] = "Logo can't left empty";
        }
      

        this.setState({ errors });
        return formIsValid;
    }

    render() {
        let { admisiaInstituteConfigInfo, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        return (
            <div>
                <div>

                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    <Growl ref={(el) => this.growl = el} />

                    <div className="p-fluid">

                        <div>
                            {
                                this.state.checkEligibility === true ?
                                    <AdmisiaPointViewInstitute instituteInfo={this.state.instituteInfo} logo={this.state.institutePicture} fetchInstituteInfoDetails={this.fetchInstituteInfoDetails} />
                                    :
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-10 p-xl-3">
                                                <center>
                                                    {this.state.proPic.contentPic ?
                                                        <img
                                                            src={"data:image/jpeg;base64, " + this.state.proPic.contentPic}
                                                            alt="Institute Image"
                                                            width="150px"
                                                        />
                                                        :
                                                        <img
                                                            src='assets/layout/images/institute.png'
                                                            alt="Institute Image"
                                                            width="150px"
                                                        />
                                                    }
                                                </center>


                                                <div className="p-col-12 p-xl-12 nw-upload-button">
                                                    <FileUpload
                                                        className="p-button-raised p-button-primary"
                                                        mode="basic"
                                                        accept="image/*"
                                                        maxFileSize={1000000}
                                                        onSelect={this.onBasicUploadAuto.bind(this)}
                                                        auto={true}
                                                        chooseLabel="Choose"
                                                        autoWidth={false}
                                                    />

                                                </div>
                                                <label style={{ marginLeft: "3%", position: "absolute" }}>
                                                    {this.state.proPic.contentName}
                                                </label>
                                                <span className='error-message'>{this.state.proPic.contentName ? '' : errors["logo"]}</span>
                                            </div>



                                            <div className="p-col-12 p-xl-9">
                                                <div className="p-grid nw-form">

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="nw-form-body">

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Institute Name <span>*</span></span>
                                                                    <InputText
                                                                        value={admisiaInstituteConfigInfo.instituteName}
                                                                        id="instituteName"
                                                                        onChange={this.onChangeInstituteName}
                                                                        placeholder="Enter Institute Name"
                                                                        style={{ width: "100%" }}
                                                                        type="text"
                                                                        name="instituteName"
                                                                        maxLength="120"
                                                                    />
                                                                </div>
                                                                <span className='error-message'>{admisiaInstituteConfigInfo.instituteName ? '' : errors["instituteName"]}</span>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Address <span>*</span></span>
                                                                    <InputText
                                                                        value={admisiaInstituteConfigInfo.instituteAddress}
                                                                        onChange={this.onChangeInstituteAddress}
                                                                        id="address"
                                                                        placeholder="Enter Address"
                                                                        style={{ width: "100%" }}
                                                                        type="text"
                                                                        name="address"
                                                                        maxLength="120"
                                                                    />
                                                                </div>
                                                                <span className='error-message'>{admisiaInstituteConfigInfo.instituteAddress ? '' : errors["instituteAddress"]}</span>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Mobile No. <span>*</span></span>
                                                                    <InputText
                                                                        value={admisiaInstituteConfigInfo.instituteContact}
                                                                        onChange={this.onChangeInstituteContact}
                                                                        placeholder="Enter mobile no."
                                                                        style={{ width: "100%" }}
                                                                        type="text"
                                                                        id="mobileNo"
                                                                        name="mobileNo"
                                                                        keyfilter="pint"
                                                                        maxLength="11"
                                                                    />
                                                                </div>
                                                                <span className='error-message'>{admisiaInstituteConfigInfo.instituteContact ? '' : errors["instituteContact"]}</span>
                                                            </div>


                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Email Address <span>*</span></span>
                                                                    <InputText
                                                                        value={admisiaInstituteConfigInfo.instituteEmail}
                                                                        onChange={this.onChangeInstituteEmail}
                                                                        placeholder="Enter email address"
                                                                        style={{ width: "100%" }}
                                                                        type="text"
                                                                        id="email"
                                                                        name="email"
                                                                    />
                                                                </div>
                                                                <span className='error-message'>{admisiaInstituteConfigInfo.instituteEmail && !errors["instituteEmail"] ? '' : errors["instituteEmail"]}</span>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" style={{ width: "24%", fontStyle: "bold" }}>Desire URL <span>*</span></span>
                                                                    <span className="p-inputgroup-addon">http://test.dwsbd.com/p2/</span>
                                                                    <InputText
                                                                        placeholder={"ex: link-name"}
                                                                        value={this.state.checkURLInfo.urlName}
                                                                        onChange={this.onChangeUrlName}
                                                                        style={{ width: "15%" }}
                                                                        type="text"
                                                                        id="urlName"
                                                                        name="urlName"
                                                                    />
                                                                    <Button
                                                                        className="p-button p-component p-button-success p-button-text-only"
                                                                        label="Check"
                                                                        onClick={this.onChangeCheckDwsUrl}
                                                                    />
                                                                </div>
                                                                <span className='error-message'>{this.state.checkURLInfo.urlName ? '' : errors["urlName"]}</span>
                                                                <span className={this.state.urlMsgView}>
                                                                    {
                                                                        this.state.urlErrorMsgBody && this.state.checkURLInfo.urlName ? this.state.urlErrorMsgBody : null
                                                                    }
                                                                </span>
                                                            </div>

                                                            <div className="p-col-6 p-xl-12 nw-button-parent">
                                                                <Button
                                                                    className="p-button p-button-primary nw-button nw-button-center"
                                                                    label="Save"
                                                                    icon="fas fa-check"
                                                                    onClick={this.onSubmitUrlInfo.bind(this)}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            }

                            <div className="p-col-12">
                                {this.state.instituteInfo ? <AdmisiaPointSocialLinkFooter defaultLanguage={this.state.instituteInfo.defaultLanguage} instituteDetails={this.state.instituteInfo} /> : ''}
                            </div>

                        </div>
                    </div>
                </div>
                {/* : null
            } */}
            </div>

        );
    }
}