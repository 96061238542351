import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import CommonFuctionality from '../common/CommonFuctionality';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { NetiDateUtils } from '../../utils/NetiDateUtils';

let errors = {};
export class EmBillPaidLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            paidLogDialog: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            emPaidBillLogList: [],
        }

        this.CommonFuctionality = new CommonFuctionality();
        this.CRMPointService = new CRMPointService();
        this.validatorUtility = new ValidatorUtility();
    }



    getEdumanPaidBillLog = (param) => {
        this.setState({ topProgressBar: true, errorMsgBody: '', errorMsgVisible: false });
        this.CRMPointService.getEdumanBillLogByBillType("Bill Paid", param)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("em bill log body", body);
                        this.setState({emPaidBillLogList: body});
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection.", topProgressBar: false })
            });
    }

    paidLogDialogAction = (param) => {
        this.setState({ paidLogDialog: true });
        this.getEdumanPaidBillLog(param);
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    paidLogDialogActionHide = () => {
        this.setState({ paidLogDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    dateInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo">
                <div>
                    <div>Paid Date</div>
                    <div>:</div>
                    <div>{NetiDateUtils.getAnyShortForm(rowData.billDate, 'DD-MMM-YYYY')}</div>
                </div>

                <div>
                    <div>Bill Of</div>
                    <div>:</div>
                    <div>{rowData.emBillGenerateLogDTO.billYear} - {rowData.emBillGenerateLogDTO.billMonth}</div>
                </div>
            </div>
        );
    }

    paidInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo">
                <div>
                    <div>Paid Note</div>
                    <div>:</div>
                    {/* <div>{rowData.billNote}</div> */}
                    {/* <div>{}</div> */}

                </div>

      
            </div>
        );
    }

    userInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo">
                <div>
                    <div>Paid Neti ID</div>
                    <div>:</div>
                    <div>{rowData.productPuchaseLogDTO && rowData.productPuchaseLogDTO.userBasicInfoDTO && rowData.productPuchaseLogDTO.userBasicInfoDTO.customNetiID}</div>
                </div>
                <div>
                    <div>Name</div>
                    <div>:</div>
                    <div>{rowData.productPuchaseLogDTO && rowData.productPuchaseLogDTO.userBasicInfoDTO && rowData.productPuchaseLogDTO.userBasicInfoDTO.fullName}</div>
                </div>
            </div>
        );
    }

    paidAmount = (rowData) => {
        return (
            <div className="nw-datatable-dataview-small-panel priceInfo">
                <div>
                    <div>PAID</div>
                </div>
                <h2> <span className="symbol-taka">৳ </span>{this.validatorUtility.currencyFormatter(rowData.billAmount)}</h2>
            </div>
        );
    }



    render() {
        console.log("state emPaidBillLogList",this.state.emPaidBillLogList);
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let detailsTableHeader = <div className="header-title">
        <div className="header-title-left">Bill Paid Log</div>

        <div className="header-title-right">
            <Button
                className="nw-button nw-button-center p-button-success"
                name="district"
                label="Download"
                icon="fas fa-plus"
                iconPos="right"
            />
        </div>
    </div>

        return (
<>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                    <Growl ref={(el) => this.growl = el} />
                    <Dialog header="Paid Log" style={{width: '70vw'}} visible={this.state.paidLogDialog} onHide={this.paidLogDialogActionHide} className="nw-dialog product-info-dialog">
                        <br />
                        <div className="p-fluid">
                            <div className="p-col-12">
                                <div className="p-col-12 p-xl-12 nw-datatable-detail-view product-list-datatable-detail-view">
                                    <DataTable
                                        header={detailsTableHeader}
                                        selectionMode="single"
                                        responsive={true}
                                        value={this.state.emPaidBillLogList}
                                        globalFilter={this.state.globalFilter}
                                        paginator={true}
                                        rows={5}
                                    >
                                        <Column field="" header="Date Info" body={this.dateInfo} />
                                        <Column field="" header="Paid Info" body={this.paidInfo} />
                                        <Column field="" header="User Info" body={this.userInfo}/>
                                        <Column field="" header="Paid Amount" body={this.paidAmount}/>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </Dialog>

</>
        )
    }
}