import React, { Component } from 'react';

import {TabView,TabPanel} from 'primereact/tabview';
import { UnusedPurchaseCodeList } from './UnusedPurchaseCodeList';
import { UsedPurchaseCodeList } from './UsedPurchaseCodeList';

export class PurchaseCodeLogMain extends Component {

    constructor(props) {
		super(props);
		this.state = {
            
        }
        
    }
      
    
    render() {
        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView>
                            <TabPanel header="Unused Purchase Code" leftIcon="fas fa-download">
                                <UnusedPurchaseCodeList/>
                            </TabPanel>
                            <TabPanel header="Used Purchase Code" leftIcon="fas fa-upload">
                                <UsedPurchaseCodeList/>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }
}