import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BalanceService } from '../../../../service/myPoint/BalanceService';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import { pdfDownloadPortrait_FullTable } from '../../../common/JsPdfDownload';

let maxDate = new Date();
let totalIncome = 0;
let totalExpense = 0;
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
export class BalanceStatement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableSelection: null,
            dataViewValue: [],
            tableView: false,
            balanceStatementErr: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            balanceStatementRecords:
            {
                requestStartDate: "",
                requestEndDate: ""
            },
            dataTableIsLoading: false

        };

        this.balanceService = new BalanceService();
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
    }



    onChangeStartDate = (e) => {
        let { balanceStatementRecords } = this.state;
        balanceStatementRecords[e.target.name] = e.target.value
        this.setState({ balanceStatementRecords });
        this.clearDateError(e.target.name);

    }

    onChangeEndDate = (e) => {
        let { balanceStatementRecords } = this.state;
        balanceStatementRecords[e.target.name] = e.target.value
        this.setState({ balanceStatementRecords });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { balanceStatementErr } = this.state;
        balanceStatementErr[name] = ''
        this.setState({ balanceStatementErr })
    }


    onSearchBtn = (e, props) => {
        this.setState({ dataViewValue: [] });
        if (this.balanceRecordsHandleError()) {
            this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            let { balanceStatementRecords } = this.state;
            if (Date.parse(balanceStatementRecords.requestEndDate) >= Date.parse(balanceStatementRecords.requestStartDate)) {
                balanceStatementRecords.limit = 10;
                this.balanceService.getUserBalanceStatementRecords(balanceStatementRecords)
                    .then((res) => {
                        if (res.status == 200) {
                            return res.json().then((body) => {
                                
                                if (body.length == 0) {
                                    return this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found.' })
                                }

                                for (let i = 0; i < body.length; i++) {
                                    if (body[i].transactionDate == null) {
                                        body[i].transactionDate = '';
                                    } else {
                                        body[i].transactionDate = NetiDateUtils.getAnyShortForm(body[i].transactionDate, 'DD-MMM-YYYY hh:mm:ss a');
                                    }
                                }
                                totalIncome = body.map(item => item.income).reduce((a, b) => (a + b));
                                totalExpense = body.map(item => item.expense).reduce((a, b) => (a + b));

                                this.setState({ dataViewValue: body, topProgressBar: false, dataTableIsLoading: false, tableView: true });
                            });
                        } else {
                            this.balanceService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                    });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be ahead of To Date." });
            }

        }

    }

    balanceRecordsHandleError = () => {
        let balanceStatementErr = {};
        let formIsValid = true;

        if (this.state.balanceStatementRecords.requestStartDate === '') {
            formIsValid = false;
            balanceStatementErr["requestStartDate"] = "Start Date can't left empty.";
        }
        if (this.state.balanceStatementRecords.requestEndDate === '') {
            formIsValid = false;
            balanceStatementErr["requestEndDate"] = "Start Date can't left empty.";
        }

        this.setState({ balanceStatementErr: balanceStatementErr });
        return formIsValid;
    }

    balanceDateFormat = (rowData, Column) => {
        return <span>{NetiDateUtils.getAnyShortForm(rowData['requestDate'], 'DD-MMM-YYYY')}</span>;

    }

    cashInBody = (rowData) => {
        return (<b> {this.validatorUtility.currencyFormatter(rowData.income)}</b>)

    }

    cashOutBody = (rowData) => {
        return (<b> {this.validatorUtility.currencyFormatter(rowData.expense)}</b>)
    }

    exportPdf = () => {
        let { dataViewValue, balanceStatementRecords } = this.state;
        let pdfColumns = [
                  { title: "Transaction Time & Date", dataKey: "transactionDate" },
                  { title: "Transaction For", dataKey: "transactionFor" },
                  { title: "Cash In", dataKey: "income" },
                  { title: "Cash Out", dataKey: "expense" },
                ];
    
        let tableColumnStyles = {
            income: { halign: "right"},
            expense: { halign: "right"},
        }
    
        var totalArrayList = [
          {
            id: "1",
            totalPdf: "Total",
            totalAmount1: totalIncome,
            totalAmount2: totalExpense,
          }
        ];
    
        let totalColumns = [{ dataKey: "totalPdf" }, { dataKey: "totalAmount1" }, { dataKey: "totalAmount2" }];
    
        let totalColumnStyles = {
          totalPdf: { halign: "right", fontSize: 10, cellWidth: 122.5, fontStyle: "bold", },
          totalAmount1: { halign: "right", fontStyle: "bold", fontSize: 9, cellWidth: 26.5 },
          totalAmount2: { halign: "right", fontSize: 9, fontStyle: "bold", cellWidth: 31.5 },
        }

        pdfDownloadPortrait_FullTable("Balance Statement Report", 75, pdfTitleHeader, pdfTitleHeaderData, pdfColumns, this.state.dataViewValue, tableColumnStyles, totalColumns, totalArrayList, totalColumnStyles, "Balance_Statement.pdf");
    
      }

    render() {
        let { dataViewValue, balanceStatementRecords } = this.state;
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let header = <div className="header-title">
                            <div className="header-title-left">
                                Balance Statement
                            </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataViewValue.length.toLocaleString("EN-IN")}
                                </a>
                            </div>
                        </div>;

        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        pdfTitleHeaderData = [
        { A: "From Date", B: NetiDateUtils.getAnyShortForm(balanceStatementRecords.requestStartDate, 'DD-MMM-YYYY'), C: "To Date", D: NetiDateUtils.getAnyShortForm(balanceStatementRecords.requestEndDate, 'DD-MMM-YYYY')}
        ];

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={2} />
                <Column footer={this.validatorUtility.currencyFormatter(totalIncome)} />
                <Column footer={this.validatorUtility.currencyFormatter(totalExpense)} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-md-6 p-lg-6 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.balanceStatementRecords.requestStartDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            name="requestStartDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.balanceStatementErr["requestStartDate"]}</span>

                                </div>
                                <div className="p-col-12 p-md-6 p-lg-6 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.balanceStatementRecords.requestEndDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            name="requestEndDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className={"error-message"}>{this.state.balanceStatementErr["requestEndDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={(e) => this.onSearchBtn(e, this.props)}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="p-col-12 p-xl-12 p-col-nogutter nw-data-table">
                            {/* <div className=""> */}
                                {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            :
                                            <div>
                                                <DataTable
                                                footerColumnGroup={footerGroup}
                                                value={this.state.dataViewValue}
                                                header={header}
                                                // footer={this.footerTemplate}
                                                // rowGroupFooterTemplate={this.footerTemplate}
                                                responsive={true}
                                                selectionMode="single"
                                                paginator={true}
                                                rows={10}
                                                rowsPerPageOptions={[10, 20, 30]}
                                                selection={this.state.dataTableSelection}
                                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            >
                                                <Column field="transactionDate" header="Date & Time" filter={true} className="table-datetime" />
                                                <Column field="transactionFor" header="Transaction For" filter={true} />
                                                <Column field="income" header="Cash In" body={this.cashInBody} filter={true} className="text-right" />
                                                <Column field="expense" header="Cash Out" body={this.cashOutBody} filter={true} className="text-right" />

                                            </DataTable>
                                            <div className="nw-button-parent m-t-8 p-r-0">
                                                <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Download"
                                                icon="fas fa-download"
                                                onClick={this.exportPdf}
                                                />
                                            </div>
                                            </div>

                                        :

                                        <BlankDataTableAnim />
                                }
                            {/* </div> */}

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}