import React, { Component } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Editor } from 'primereact/editor';
import { Growl } from "primereact/growl";
import { TabView, TabPanel } from 'primereact/tabview';
import { FileUpload } from "primereact/fileupload";
import { ErrorMessageView } from './ErrorMessageView';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';
import { CUSTOM_MESSAGE } from './CustomMessage';
import CommonFuctionality from '../common/CommonFuctionality';
import {InputText} from 'primereact/inputtext';


export class SendSmsAndNetiMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            topProgressBar: false,
            netiMailBtnDisabled:true,
            viewChatBody: true,
            viewAttachmentBody: true,
            searchIsLoading: false,
            dataTableValue: [],
            searchViewError: false,
            searchIsLoadingFirst: false,
            searchIsLoadingSecond: false,
            uploadImage: {
                contentPic:'',
            },
            uploadEmailImage: {},
            smsDialogVisible: false,
            sendSMSorMailObj: {
                messageBody: '',
                receiverContacts:[]
            },
            eMailObjValue:{
                mailBody : '',
                mailSubject : '',
                receiverEmail :[],
                eMailObjFile:null,
            },
            messageBodyMaxlength: 160,
            smsCounterObj:{
                encoding: "GSM_7BIT",
                length: 0,
                per_message: 160,
                remaining: 0,
                messages: 1
            },
            activeIndex: 0,
            valueFromProps:{
                smsActive: false,
                netiMailActive: false,
                userInfo: []
            },
        };

        this.UserOtherAccessService = new UserOtherAccessService();
        this.CommonFuctionality = new CommonFuctionality();

        if(props.smsActive == false){
            setTimeout(() => {
                this.setState({ activeIndex: 1})
            }, 500);
        }
        else if(props.netiMailActive == false){
            setTimeout(() => {
                this.setState({ activeIndex: 0})
            }, 500);
        }
    }

    componentWillMount(){
        this.setState({ valueFromProps: { ...this.props }})
    }

    componentWillReceiveProps(newProps) {    
        // console.log('Component WILL RECIEVE PROPS!', newProps)

        // if( this.state.valueFromProps.userInfo.includes( newProps )){
            console.log("true");
            
        // }
        this.setState({ valueFromProps: { ...newProps }})
     }


    showSMSDialog = () =>{
        // this.setState({
        //     smsDialogVisible: true
        // })

        if(this.props.smsActive == false){
            this.onSubmitSendSMSorMail()
        }
        else if(this.props.netiMailActive == false){
            this.onSubmitSendSMSorMail()
        }
        else if(this.props.smsActive == true && this.props.netiMailActive == true){
            this.setState({
                smsDialogVisible: true
            })
        }

        // this.CommonFuctionality.blurDialogBackgroundActive()
    }

    onHide = () =>{
        this.setState({
            smsDialogVisible: false
        })

        // this.CommonFuctionality.blurDialogBackgroundDeActive()
    }

    viewActionBody = (rowData) =>{
        return <div>
                    {/* <span>{rowData.basicMobile}</span> */}
                    <Button
                        className="p-button-danger nw-action-button"
                        icon="fas fa-times"
                        onClick={e=>this.onDeleteRow(rowData)}
                    />
                </div>
    }

    onDeleteRow = (rowData) =>{
        let { dataTableValue } = this.state
        const filteredItems = dataTableValue.filter(function(item) {
            return item !== rowData
        })

        this.setState({ dataTableValue: filteredItems })
    }

    onChangeSMSBody = (e) =>{
        let { sendSMSorMailObj, activeIndex } = this.state
        sendSMSorMailObj.messageBody=e.target.value
       
        this.setState({ sendSMSorMailObj })
    }

    onChangeMailBody = (e) =>{
        let { sendSMSorMailObj, activeIndex } = this.state
        this.setState({  viewChatBody: false })

        sendSMSorMailObj.chatboxBody=e.htmlValue
       
        this.setState({ sendSMSorMailObj })
    }

    onChangeEMailBody = (e) => {
        let { eMailObjValue, activeIndex } = this.state
        eMailObjValue.mailBody=e.htmlValue
        this.setState({ eMailObjValue })
    }
    

    textCounter = (input) => {
        let { smsCounterObj }=this.state

        let SmsCounter={}

        SmsCounter.gsm7bitChars = "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";

        SmsCounter.gsm7bitExChar = "\\^{}\\\\\\[~\\]|€";
 
        SmsCounter.gsm7bitRegExp = RegExp("^[" + SmsCounter.gsm7bitChars + "]*$");
 
        SmsCounter.gsm7bitExRegExp = RegExp("^[" + SmsCounter.gsm7bitChars + SmsCounter.gsm7bitExChar + "]*$");
 
        SmsCounter.gsm7bitExOnlyRegExp = RegExp("^[\\" + SmsCounter.gsm7bitExChar + "]*$");
 
        SmsCounter.GSM_7BIT = 'GSM_7BIT';
 
        SmsCounter.GSM_7BIT_EX = 'GSM_7BIT_EX';
 
        SmsCounter.UTF8 = 'UTF8';

        SmsCounter.messageLength = {
            GSM_7BIT: 160,
            GSM_7BIT_EX: 160,
            UTF8: 70
        };

        SmsCounter.multiMessageLength = {
            GSM_7BIT: 153,
            GSM_7BIT_EX: 153,
            UTF8: 67
        };

        SmsCounter.count = function (text) {
            var count, encoding, length, messages, per_message, remaining;
            encoding = this.detectEncoding(text);
            length = text.length;
            if (encoding === this.GSM_7BIT_EX) {
                length += this.countGsm7bitEx(text);
            }
            per_message = this.messageLength[encoding];
            if (length > per_message) {
                per_message = this.multiMessageLength[encoding];
            }
            messages = Math.ceil(length / per_message);
            remaining = (per_message * messages) - length;
            if (remaining == 0 && messages == 0) {
                remaining = per_message;
            }
            return count = {
                encoding: encoding,
                length: length,
                per_message: per_message,
                remaining: remaining,
                messages: messages
            };
        };

        SmsCounter.detectEncoding = function (text) {
            switch (false) {
              case text.match(this.gsm7bitRegExp) == null:
                return this.GSM_7BIT;
              case text.match(this.gsm7bitExRegExp) == null:
                return this.GSM_7BIT_EX;
              default:
                return this.UTF8;
            }
        };

        SmsCounter.countGsm7bitEx = function (text) {
            var char2, chars;
            chars = (function () {
                var _i, _len, _results;
                _results = [];
                for (_i = 0, _len = text.length; _i < _len; _i++) {
                    char2 = text[_i];
                    if (char2.match(this.gsm7bitExOnlyRegExp) != null) {
                        _results.push(char2);
                    }
                }
                return _results;
            }).call(this);
            return chars.length;
        };

        this.setState({ smsCounterObj: SmsCounter.count(input)})
    }

    emailSubjectHandler = (e) => {
        this.setState({ eMailObjValue : {mailSubject : e.target.value}});
    }

    clearSendObj = (e) =>{
        let { sendSMSorMailObj, valueFromProps } = this.state

        sendSMSorMailObj.messageBody = ''
        this.setState({ sendSMSorMailObj, activeIndex: e.index })
    }

    onLoadPic(e) {
        console.log('e',e);
        this.setState({  viewAttachmentBody: false })
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ uploadImage: album });
            console.log('album',album);

            // noticeSubmitError["noticeImgContent"] = "";

        }

        
    }

    onLoadEmailPic(e) {
        let {eMailObjValue} = this.state;
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            let imageObject = eMailObjValue ;
            imageObject.eMailObjFile = photo;
            scope.setState({ uploadEmailImage: album, eMailObjFile: imageObject});
            
        }
        
    }

    onSubmitSendSMSorMail = () =>{
        let { sendSMSorMailObj, valueFromProps, activeIndex, eMailObjValue } = this.state
        let newUserObj=[]
        let urlName;

        if( activeIndex == 0 ){
            if (valueFromProps.filterData) {
                urlName="/send/sms";
                valueFromProps.userInfo.map((item) => {
                    newUserObj.push( item.mobile)
                });
                sendSMSorMailObj.receiverContacts = newUserObj;
            }
            else{
                urlName="/send/sms";
                valueFromProps.userInfo.map((item) => {
                    newUserObj.push( item.basicMobile)
                });
                sendSMSorMailObj.receiverContacts = newUserObj//receiverBasicInfoDTO
            }
            
        }else if( activeIndex == 2 ){
            let bulkEmail = [];

            if (valueFromProps.filterData) {
                valueFromProps.userInfo.map((item) => {
                    bulkEmail.push( item.email)
                });
                eMailObjValue.receiverEmail = bulkEmail;
            }else{
                valueFromProps.userInfo.map((item) => {
                    newUserObj.push( item.basicEmail );
                });
                eMailObjValue.receiverEmail = bulkEmail;
            }
            
            
        }
        else{
            if (valueFromProps.filterData) {
                urlName="/chat/logs/save"
                valueFromProps.userInfo.map((item) => {
                    newUserObj.push( item.netiID);
                });

                if (this.state.uploadImage.contentPic) {
                    sendSMSorMailObj.attachContent = this.state.uploadImage.contentPic;
                    sendSMSorMailObj.attachSaveOrEditable = true;
                    // let extention = this.netiFileHandler.getImageExtention(this.state.uploadImage.extention);
                    // noticeInfo.noticeImgName = 'notice_' + urlId + '_' + Date.now() + extention;
        
                    sendSMSorMailObj.chatboxAttachmentName = this.state.uploadImage.contentName;
                }
                sendSMSorMailObj.netiIDs = newUserObj;
            }else{
                urlName="/chat/logs/save"
                valueFromProps.userInfo.map((item) => {
                    newUserObj.push( item.netiID );
                });

                if (this.state.uploadImage.contentPic) {
                    sendSMSorMailObj.attachContent = this.state.uploadImage.contentPic;
                    sendSMSorMailObj.attachSaveOrEditable = true;
                    // let extention = this.netiFileHandler.getImageExtention(this.state.uploadImage.extention);
                    // noticeInfo.noticeImgName = 'notice_' + urlId + '_' + Date.now() + extention;
        
                    sendSMSorMailObj.chatboxAttachmentName = this.state.uploadImage.contentName;
                }
                sendSMSorMailObj.netiIDs = newUserObj;
            }
            
        }

        this.setState({ topProgressBar: true, errorMsgVisible: false })
        if(eMailObjValue.receiverEmail.length > 0){
            console.log('this is demo');
            
        }else{
            this.UserOtherAccessService.sendAnySMS(sendSMSorMailObj, urlName)
            .then((res) => {
                if (res.status === 201) {
                    this.growl.show({ severity: 'success', summary: CUSTOM_MESSAGE.SUCCESS_MSG_SHORT, detail: "SMS Send Successfull." });
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false})
                    
                } else {
                    this.UserOtherAccessService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check connection !!!", buttonIcon: "fas fa-check", buttonIconAnimClass: "p-button-primary"  });
            });
        }
        
        
    }
    
    render() {
        let { activeIndex, valueFromProps, eMailObjValue, smsCounterObj, sendSMSorMailObj, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { userInfo, smsActive, netiMailActive } = this.props
        let uploadFileLabel = <div>Click / Drag File Here To Upload <br/><span className="upload-file-label">(Max 1MB (recommended)</span></div>
       console.log("btn 1 ",sendSMSorMailObj.chatboxBody);
       console.log("btn 2 ",this.state.uploadImage.contentPic);

       return (
            <React.Fragment>
                <Growl ref={(el) => this.growl = el} />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                
                <div className="p-col-12 p-xl-12 p-col-nogutter nw-message-view-main">
                    <div className="p-col-12 p-xl-12 nw-message-view header">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">Total Contacts </span>
                        
                            <Button
                                label={valueFromProps.userInfo.length || "0"}
                                onClick={(e) => window.location.href = "#"}
                            />
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12 p-col-nogutter nw-message-view body nw-tabview">
                        
                        <TabView className="" onTabChange={ this.clearSendObj } activeIndex={ activeIndex }>

                                <TabPanel header="Send SMS" leftIcon="fas fa-comment-alt" disabled={ !valueFromProps.smsActive ? true : false }>
                                    { valueFromProps.smsActive === true ? 
                                        <React.Fragment>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon multiple-value">
                                                        SMS Body <span>*</span>
                                                        <pre> { smsCounterObj.length || 1 }/{ smsCounterObj.per_message * smsCounterObj.messages  }</pre>
                                                    </span>
                                                    <InputTextarea
                                                        placeholder="Enter Message"
                                                        value={ sendSMSorMailObj.messageBody }
                                                        onChange={ this.onChangeSMSBody }
                                                        onKeyUp={ e=>this.textCounter(sendSMSorMailObj.messageBody) }
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.errors["netiIDError"]}</span>
                                            </div>

                                            <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                                <div className="required-field"></div>
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                    label="Send"
                                                    onClick={this.showSMSDialog}
                                                    icon="fas fa-check"
                                                    disabled={ sendSMSorMailObj.messageBody === '' }
                                                />
                                            </div>
                                        </React.Fragment>
                                        :''
                                    }

                                </TabPanel>

                                <TabPanel header="Neti Mail" leftIcon="fas fa-envelope" disabled={ !valueFromProps.netiMailActive ? true : false }>
                                    { valueFromProps.netiMailActive === true ? 
                                        <React.Fragment>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Mail Body <span>*</span></span>
                                                    <Editor
                                                        className="nw-inputtexteditor"
                                                        value={ sendSMSorMailObj.messageBody }
                                                        onTextChange={ this.onChangeMailBody }
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.errors["netiIDError"]}</span>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup nw-upload-button">
                                                    <span className="p-inputgroup-addon">
                                                        Upload File <span>*</span>
                                                        <br />
                                                        <span style={{color: '#666666'}}>(PNG/JPG/DOC/PDF)</span>
                                                    </span>

                                                    <div className="nw-upload-button-inside">

                                                        {
                                                            this.state.uploadImage.contentPic ?
                                                                <div className="image-view-main">
                                                                    <div className="upload-image-view">
                                                                        <Button
                                                                            className="delete-upload-button"
                                                                            icon="fas fa-times-circle"
                                                                            onClick={e => this.setState({ uploadImage: { contentPic: '' } })}
                                                                        />

                                                                        {
                                                                            this.state.uploadImage.contentName ?
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadImage.contentName) : this.commonFuctionality.getFileContentTypeIcon(this.props.noticeInfo.noticeImgName)
                                                                        }
                                                                        {/* <img
                                                                            src={
                                                                                this.state.uploadImage.contentPic === '' ?
                                                                                    'assets/images/demo.jpg'
                                                                                    :
                                                                                    "data:image/png;base64," + this.state.uploadImage.contentPic
                                                                            }
                                                                            style={{ "height": "80px" }}
                                                                        /> */}


                                                                    </div>
                                                                    <div className="image-title">{this.state.uploadImage.contentName}</div>
                                                                </div>

                                                                :
                                                                <FileUpload
                                                                    chooseLabel={this.state.uploadImage.contentName || uploadFileLabel}
                                                                    id="fileUpload"
                                                                    mode="basic"
                                                                    maxFileSize={1000000}
                                                                    onSelect={this.onLoadPic.bind(this)}
                                                                    auto={true}
                                                                />
                                                        }

                                                    </div>
                                                </div>
                                                {/* <span className='error-message'>{this.state.uploadImage.contentName ? null : this.state.noticeSubmitError.noticeImgContent}</span> */}
                                            </div>

                                            <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                                <div className="required-field"></div>
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                    label="Send"
                                                    onClick={this.showSMSDialog }
                                                    icon="fas fa-check"
                                                    disabled={this.state.viewChatBody == false && this.state.viewAttachmentBody == false ? !this.state.netiMailBtnDisabled : this.state.netiMailBtnDisabled}

                                                    // disabled={sendSMSorMailObj.chatboxBody === '' && this.state.uploadImage.contentPic === ''}
                                                />
                                            </div>
                                        </React.Fragment>
                                        : ''
                                    }
                                </TabPanel>
                                
                                <TabPanel header="E-Mail" leftIcon="fas fa-envelope" disabled={ !valueFromProps.emailActive ? true : false }>
                                    { valueFromProps.emailActive === true ? 
                                        <React.Fragment>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Subject</span>
                                                    <InputText 
                                                        value={eMailObjValue.mailSubject} 
                                                        onChange={(e) => this.emailSubjectHandler(e)} 
                                                        placeholder="Write subject here"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Mail Body <span>*</span></span>
                                                    <Editor
                                                        className="nw-inputtexteditor"
                                                        value={ eMailObjValue.messageBody }
                                                        onTextChange={ this.onChangeEMailBody }
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.errors["netiIDError"]}</span>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup nw-upload-button">
                                                    <span className="p-inputgroup-addon">
                                                        Upload File <span>*</span>
                                                        <br />
                                                        <span style={{color: '#666666'}}>(PNG/JPG/DOC/PDF)</span>
                                                    </span>

                                                    <div className="nw-upload-button-inside">

                                                        {
                                                            this.state.uploadEmailImage.contentPic ?
                                                                <div className="image-view-main">
                                                                    <div className="upload-image-view">
                                                                        <Button
                                                                            className="delete-upload-button"
                                                                            icon="fas fa-times-circle"
                                                                            onClick={e => this.setState({ uploadEmailImage: { contentPic: '' }, eMailObjValue : {eMailObjFile: ''} })}
                                                                            
                                                                        />

                                                                        {
                                                                            this.state.uploadEmailImage.contentName ?
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadEmailImage.contentName) : this.commonFuctionality.getFileContentTypeIcon(this.props.noticeInfo.noticeImgName)
                                                                        }
                                                                        {/* <img
                                                                            src={
                                                                                this.state.uploadImage.contentPic === '' ?
                                                                                    'assets/images/demo.jpg'
                                                                                    :
                                                                                    "data:image/png;base64," + this.state.uploadImage.contentPic
                                                                            }
                                                                            style={{ "height": "80px" }}
                                                                        /> */}


                                                                    </div>
                                                                    <div className="image-title">{this.state.uploadEmailImage.contentName}</div>
                                                                </div>

                                                                :
                                                                <FileUpload
                                                                    chooseLabel={this.state.uploadEmailImage.contentName || uploadFileLabel}
                                                                    id="fileUpload"
                                                                    mode="basic"
                                                                    maxFileSize={1000000}
                                                                    onSelect={this.onLoadEmailPic.bind(this)}
                                                                    auto={true}
                                                                />
                                                        }

                                                    </div>
                                                </div>
                                                {/* <span className='error-message'>{this.state.uploadImage.contentName ? null : this.state.noticeSubmitError.noticeImgContent}</span> */}
                                            </div>

                                            <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                                <div className="required-field"></div>
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                    label="Send"
                                                    onClick={this.showSMSDialog }
                                                    icon="fas fa-check"
                                                />
                                            </div>
                                        </React.Fragment>
                                        : ''
                                    }
                                </TabPanel>
                                
                        </TabView>
                    </div>
                </div>
                        

                <div className="dialog-section">
                    <Dialog header="Send SMS" visible={this.state.smsDialogVisible} onHide={this.onHide} className="nw-confirm-dialog">
                        <div className="p-fluid">
                            <div className="p-col-12 p-col-12">
                                <div className="confirm-wrapper">
                                    <div className="body-wrapper">
                                        <header>Total {activeIndex==0?'messages': 'person'} you are about to send</header>
                                        <h1>{ activeIndex==0? valueFromProps.userInfo.length * smsCounterObj.messages : valueFromProps.userInfo.length}</h1>
                                        <footer style={  activeIndex==0? {display:'block'} :{display:'none'}}>{valueFromProps.userInfo.length || "0"} Person x {smsCounterObj.messages} Message</footer>
                                    </div>
                                    <h1>Are You Sure?</h1>
                                    <div className="nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="No"
                                            icon="fas fa-times"
                                            onClick={this.IdTerminateConfirmation}
                                        />
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Yes"
                                            icon="fas fa-check"
                                            onClick={this.onSubmitSendSMSorMail }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                

            </React.Fragment>
        );
    }
}
