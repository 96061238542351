import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Link } from "react-router-dom";
import { NetiFileHandler } from "../../../utils/NetiFileHandler";
import { Growl } from "primereact/growl";
import { FileUpload } from "primereact/fileupload";
import { WalletBalance } from "../common/WalletBalance";
import { WalletBalanceDepositList } from "./WalletBalanceDepositList";
import { NetiDateUtils } from "../../../utils/NetiDateUtils";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { CUSTOM_MESSAGE } from "../../common/CustomMessage";
import { UserCategoryService } from "../../../service/UserCategoryService";
import { BalanceService } from "../../../service/myPoint/BalanceService";
import NetiContentLoader from "../../common/NetiContentLoader";
import { CRMPointService } from "../../../service/crmPoint/CRMPointService";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";

let cloneStateBeforeMount;
let maxDate = new Date();
let errors = {};
let bankCategory = "";
export class WalletBalanceDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantFormVisibility: true,
      errorMsgVisible: false,
      topProgressBar: false,
      mobileBankingTransactionID: "",
      mobileBankingNo: "",
      proPic: {},
      bankList: "",
      depositDate: "",
      country: null,
      filteredCountries: null,
      countriesData: [],
      dropdownCity: null,
      filteredBanks: null,
      bankName: "",
      accountNumber: "",
      banksAccount: [],
      bankAccountNumberList: [],
      depositeType: null,
      depositAmount: "",
      depositBranch: null,
      depositNote: null,
      depositMobileBankNo: null,
      depositTransactionID: null,
      switchBankName: null,
      transectionType: "",
      errors: {},
      accountNumberDetails: {},
      balanceDepositBody: {
        attachFileContent: "",
        attachFileSaveOrEditable: null,
        coreBankAccountInfoDTO: {
          coreBankAccId: "",
        },
      },
      dataTableIsLoading: false,
      firstDropdownIsLoading: false,
      secondDropdownIsLoading: false,
      buttonIconAnimClass: "p-button-primary",
      buttonIcon: "fas fa-check",
    };

    this.netiFileHandler = new NetiFileHandler();
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.filterBanks = this.filterBanks.bind(this);
    this.onChangeBank = this.onChangeBank.bind(this);
    this.onChangeAccountNumber = this.onChangeAccountNumber.bind(this);
    this.onChangeDepositDate = this.onChangeDepositDate.bind(this);
    this.onChangeTransactionType = this.onChangeTransactionType.bind(this);

    this.onBankAccountChange = this.onBankAccountChange.bind(this);
    this.onBasicUploadAuto = this.onBasicUploadAuto.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.bankTemplate = this.bankTemplate.bind(this);
    this.bankAccountTemplate = this.bankAccountTemplate.bind(this);
    this.reloadDataTableValue = this.reloadDataTableValue.bind(this);

    this.userCategoryService = new UserCategoryService();
    this.balanceService = new BalanceService();
    this.NetiContentLoader = new NetiContentLoader();
    this.CRMPointService = new CRMPointService();
    this.validatorUtility = new ValidatorUtility();
  }

  componentWillMount() {
    // this.bankListInfo();
    this.fetchBankList("T10103");
    cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
  }

  fetchBankList(defaultCode) {
    this.setState({
      firstDropdownIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });
    this.userCategoryService
      .fetchBankListByDefaultCode(defaultCode)
      .then((res) => {
        if (res.status === 200) {
          return res.json().then((body) => {
            this.setState({ bankAccountNumberList: body });
            this.setState({
              firstDropdownIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          return res.json().then((errorBody) => {
            this.setState({
              firstDropdownIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: errorBody.message,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          firstDropdownIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load. Please check your connection.",
        });
      });
  }

  // bankListInfo() {
  //     this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
  //     this.userCategoryService.fetchBankList('T100')
  //         .then(res => {
  //             if (res.status == 302) {
  //                 return res.json().then((body) => {
  //                     this.setState({ bankList: body, topProgressBar: false, firstDropdownIsLoading: false });
  //                 });
  //             } else {
  //                 this.userCategoryService.Auth.handleErrorStatus(res)
  //                     .then((resp) => {
  //                         this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
  //                     });
  //             }
  //         }).catch(error => {
  //             this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: CUSTOM_MESSAGE.ERROR_CONNECTION_MSG });
  //         });

  // }

  onCheckboxChange(event) {
    let selected = [...this.state.checkboxValue];
    if (event.checked) selected.push(event.value);
    else selected.splice(selected.indexOf(event.value), 1);
    this.setState({ checkboxValue: selected });
  }

  filterBanks(event) {
    setTimeout(() => {
      let results;
      if (event.query.length === 0) {
        results = [...this.banks];
      } else {
        results = this.banks.filter((brand) => {
          return brand.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      this.setState({ filteredBanks: results });
    }, 250);
  }

  onChangeBank(e) {
    this.setState({ accountNumberDetails: {} });
    let Options;

    if (e.target.value) {
      Options = e.target.name.map((item) => ({
        value: item.value,
        parentTypeInfoDTO: item.parentTypeInfoDTO,
      }));
      for (var i = 0; i < Options.length; i++) {
        let val = Options[i].value;
        if (val === e.value) {
          this.setState({ transectionType: Options[i].parentTypeInfoDTO });
        }
      }

      this.setState({ bankAccountNumberList: [], accountNumber: null });
      this.setState({
        topProgressBar: true,
        secondDropdownIsLoading: true,
        errorMsgVisible: false,
        errorMsgBody: "",
      });
      errors["bankName"] = "";
      this.userCategoryService
        .fetchAccountNumber(e.target.value)
        .then((res) => {
          if (res.status === 302) {
            return res.json().then((body) => {
              this.setState({
                bankAccountNumberList: body,
                topProgressBar: false,
                secondDropdownIsLoading: false,
                errorMsgVisible: false,
              });
              errors["accountNumber"] = "";
            });
          } else if (res.status === 404) {
            this.setState({
              topProgressBar: false,
              secondDropdownIsLoading: false,
              errorMsgVisible: true,
              errorMsgBody: "No Data Found",
            });
          } else {
            this.userCategoryService.Auth.handleErrorStatus(res).then(
              (resp) => {
                this.setState({
                  topProgressBar: false,
                  secondDropdownIsLoading: false,
                  errorMsgVisible: true,
                  errorMsgBody: resp,
                });
              }
            );
          }
        })
        .catch((error) => {
          this.setState({
            topProgressBar: false,
            secondDropdownIsLoading: false,
            errorMsgVisible: true,
            errorMsgBody: CUSTOM_MESSAGE.ERROR_CONNECTION_MSG,
          });
        });

      this.setState({
        bankName: e.target.value,
        errors: errors,
      });
    } else {
      this.setState({
        bankName: e.target.value,
        bankAccountNumberList: [],
        accountNumber: null,
      });
    }
  }

  bankTemplate(option) {
    if (!option.value) {
      return option.label;
    } else {
      return (
        <div className="p-clearfix">
          <span style={{ float: "left", margin: ".5em .25em 0 0" }}>
            {option.label + " " + option.value}
          </span>
        </div>
      );
    }
  }

  onBankAccountChange(e) {
    this.setState({ accountNumber: e.value });
  }

  bankAccountTemplate(option) {
    if (!option.value) {
      return option.value;
    } else {
      return (
        <div className="p-clearfix">
          <span style={{ float: "left", margin: ".5em .25em 0 0" }}>
            {option.value}
          </span>
        </div>
      );
    }
  }

  onChangeAccountNumber = (e) => {
    this.setState({ accountNumberDetails: {} });
    errors["accountNumber"] = "";

    let { bankAccountNumberList, accountNumberDetails } = this.state;
    let accountNmbr = e.target.value.coreBankAccId;
    for (let i = 0; i < bankAccountNumberList.length; i++) {
      if (bankAccountNumberList[i].coreBankAccId == accountNmbr) {
        accountNumberDetails = bankAccountNumberList[i];
        this.setState({ accountNumberDetails });
      }
    }
    console.log("VALUE", e.target.value);
    this.setState({
      accountNumber: e.target.value,
      accountNumberCoreBankAccId: e.target.value.coreBankAccId,
      errors: errors,
      transectionType:
        e.target.value.coreCategoryInfoDTO.parentTypeInfoDTO
          .categoryDefaultCode,
    });
  };

  onBasicUploadAuto(e) {
    let { errors } = this.state;
    errors["depositSlip"] = "";
    var reader = new FileReader();
    let photo = e.files[0];
    const scope = this;
    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = "data:" + photo.type + ";base64,"; //link will be same from the word webapps in URL
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      let album = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name,
      };
      scope.setState({ proPic: album });
    };
    this.setState({ errors });
  }

  onChangeHandle = (event) => {
    errors[event.target.name] = "";
    let amount = event.target.value;
    event.target.value =
      amount.indexOf(".") >= 0
        ? amount.substr(0, amount.indexOf(".")) +
          amount.substr(amount.indexOf("."), 3)
        : amount;
    this.setState({
      [event.target.name]: event.target.value,
      errors: errors,
    });
  };

  onChangeDepositDate = (e) => {
    errors["depositDate"] = "";
    this.setState({
      depositDate: e.target.value,
      errors: errors,
    });
  };

  onChangeTransactionType = (e) => {
    errors["transectionType"] = "";
    this.setState({
      transectionType: e.target.value,
      errors: errors,
    });
  };

  onSubmitHandler = (event, props) => {
    if (this.handleError()) {
      this.setState({
        topProgressBar: true,
        dataTableIsLoading: true,
        errorMsgVisible: false,
        buttonIcon: "fas fa-spinner fa-spin",
        buttonIconAnimClass: "nw-button-anim-save",
      });
      let { balanceDepositBody } = this.state;

      if (balanceDepositBody.attachFileSaveOrEditable === true) {
        balanceDepositBody.attachFileContent = this.state.proPic.contentPic;
        balanceDepositBody.attachFileSaveOrEditable = true;
        let extention = this.netiFileHandler.getImageExtention(
          this.state.proPic.extention
        );
        balanceDepositBody.attachFileName = Date.now() + extention;
      }

      balanceDepositBody.requestedAmount = this.state.depositAmount;
      balanceDepositBody.requestNote = this.state.depositNote;
      balanceDepositBody.fromWhere = this.state.depositBranch;
      balanceDepositBody.transactionNumber = this.state.depositTransactionID;
      balanceDepositBody.transactionDate = NetiDateUtils.getDateFromString(
        this.state.depositDate
      );
      balanceDepositBody.coreBankAccountInfoDTO.coreBankAccId = this.state.accountNumberCoreBankAccId;
      balanceDepositBody.paymentType = this.state.depositeType;

      // console.log("balanceDepositBody", balanceDepositBody);

      this.balanceService
        .balanceDepositeSave(balanceDepositBody)
        .then((res) => {
          if (res.status === 201) {
            this.setState({
              balanceDepositBody: {
                coreBankAccountInfoDTO: { coreBankAccId: "" },
              },
              topProgressBar: false,
              dataTableIsLoading: false,
              errorMsgVisible: false,
            });
            this.growl.show({
              severity: "success",
              summary: CUSTOM_MESSAGE.SUCCESS_MSG_SHORT,
              detail: CUSTOM_MESSAGE.SUCCESS_MSG_LONG_SAVE,
            });
            this.setState({
              topProgressBar: false,
              dataTableIsLoading: false,
              errorMsgVisible: false,
              buttonIcon: "fas fa-check",
              buttonIconAnimClass: "nw-button-anim-save-success",
            });
            this.setState({
              accountNumberDetails: {},
              balanceDepositBody: cloneStateBeforeMount.balanceDepositBody,
              proPic: cloneStateBeforeMount.proPic,
              accountNumber: "",
              bankName: "",
              depositeType: null,
              depositAmount: "",
              depositBranch: null,
              depositType: null,
              depositMobileBankNo: null,
              depositTransactionID: null,
              depositDate: "",
              transectionType: "",
            });
            setTimeout(() => {
              this.setState({ buttonIconAnimClass: "p-button-primary" });
            }, 3000);
            this.reloadDataTableValue();
          } else {
            this.balanceService.Auth.handleErrorStatus(res).then(
              (responseBody) => {
                this.setState({
                  errorMsgVisible: true,
                  errorMsgBody: responseBody,
                  topProgressBar: false,
                  dataTableIsLoading: false,
                  buttonIcon: "fas fa-check",
                  buttonIconAnimClass: "p-button-primary",
                });
              }
            );
          }
        })
        .catch((error) => {
          this.setState({
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check connection !!!",
            buttonIcon: "fas fa-check",
            buttonIconAnimClass: "p-button-primary",
          });
        });
    }
  };

  handleError() {
    let { accountNumberDetails } = this.state;

    errors = {};
    let formIsValid = true;
    this.setState({ topProgressBar: false });

    // if (!this.state.bankName) {
    //     formIsValid = false;
    //     errors["bankName"] = "Bank Name can't left empty.";
    // }
    if (!this.state.accountNumber) {
      formIsValid = false;
      errors["accountNumber"] = "Account Number can't left empty.";
    }
    if (this.state.depositAmount === "") {
      formIsValid = false;
      errors["depositAmount"] = "Deposit Amount can't left empty.";
    } else if (
      !(parseInt(this.state.depositAmount) >= accountNumberDetails.minRecharge)
    ) {
      formIsValid = false;
      errors["depositAmount"] =
        "Amount can't be less than minimum recharge amount";
    } else if (
      !(parseInt(this.state.depositAmount) <= accountNumberDetails.maxRecharge)
    ) {
      formIsValid = false;
      errors["depositAmount"] =
        "Amount can't be greater than maximum recharge amount";
    }
    if (this.state.depositDate === "") {
      formIsValid = false;
      errors["depositDate"] = "Deposit Date can't left empty.";
    }

    if (this.state.transectionType === "") {
      formIsValid = false;
      errors["transectionType"] = "Transaction Type can't left empty.";
    }

    if (bankCategory === "Mobile Banking") {
      if (this.state.depositBranch == null) {
        formIsValid = false;
        errors["depositBranch"] = "Mobile Banking no can't left empty.";
      }

      if (this.state.depositTransactionID == null) {
        formIsValid = false;
        errors["depositTransactionID"] = "Transaction ID can't left empty.";
      }
    }

    if (bankCategory === "General Banking") {
      if (this.state.depositBranch == null) {
        formIsValid = false;
        errors["depositBranch"] = "Branch can't left empty.";
      }

      if (this.state.depositeType == null) {
        formIsValid = false;
        errors["depositType"] = "Deposit Type can't left empty.";
      }

      if (this.state.depositNote == null) {
        formIsValid = false;
        errors["depositNote"] = "Note can't left empty.";
      }

      if (this.state.proPic.contentPic == null) {
        formIsValid = false;
        errors["depositSlip"] = "Deposit Slip can't left empty.";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  reloadDataTableValue() {
    this.refs.walletBalanceDepositList.viewTopTenBalanceDepositList();
  }

  depositTypeCheck = () => {
    let activeHomePage = "far fa-circle",
      activeGallery = "far fa-circle";
    let cashStatus = false;
    let chequeStatus = false;

    if (this.state.depositeType === "cash") {
      activeHomePage = "fas fa-check-circle";
      cashStatus = true;
    } else if (this.state.depositeType === "cheque") {
      activeGallery = "fas fa-check-circle";
      chequeStatus = true;
    }
    return (
      <div className="input-radio-button">
        <div className="radio-button-inside">
          {/* <i class={activeHomePage}></i> */}
          <RadioButton
            value="cash"
            inputId="rb1"
            onChange={(event) => this.setState({ depositeType: event.value })}
            checked={this.state.depositeType === "cash"}
          />
          <label htmlFor="rb1" className="p-radiobutton-label">
            Cash
          </label>
        </div>

        <div className="radio-button-inside">
          {/* <i class={activeGallery}></i> */}
          <RadioButton
            value="cheque"
            inputId="rb1"
            onChange={(event) => this.setState({ depositeType: event.value })}
            checked={this.state.depositeType === "cheque"}
          />
          <label htmlFor="rb1" className="p-radiobutton-label">
            Cheque
          </label>
        </div>

        {/* { rowData.photoType} */}
      </div>
    );
  };

  // depositeTypeChange = (event, props) => {
  //     let { depositeType } = this.state;
  //     depositeType = event.value;
  //     //this.setState({ photoInformation });
  // }

  render() {
    let {
      balanceDepositBody,
      topProgressBar,
      errorMsgVisible,
      errorMsgBody,
      accountNumberDetails,
    } = this.state;

    let bankListOptions = [];
    if (this.state.bankList && this.state.bankList.length) {
      bankListOptions = this.state.bankList.map((item) => ({
        value: item.coreCategoryID,
        label: item.categoryName,
        parentTypeInfoDTO: item.parentTypeInfoDTO.categoryDefaultCode,
      }));
    }

    if (this.state.bankName) {
      this.state.bankList.filter((bank) => {
        if (bank.coreCategoryID == this.state.bankName) {
          bankCategory = bank.parentTypeInfoDTO.categoryName;
        }
      });
    }

    let accountNumberListOptions = [];
    if (
      this.state.bankAccountNumberList &&
      this.state.bankAccountNumberList.length
    ) {
      accountNumberListOptions = this.state.bankAccountNumberList.map(
        (item) => ({
          value: item,
          label:
            item.accShortName +
            " ( " +
            item.coreCategoryInfoDTO.categoryName +
            " )",
        })
      );
    }

    if (this.state.transectionType === "T10001") {
      balanceDepositBody.attachFileSaveOrEditable = true;
    } else if (this.state.transectionType === "T10002") {
      balanceDepositBody.attachFileSaveOrEditable = false;
    }

    let uploadFileLabel = (
      <div>
        Click / Drag Image Here To Upload <br />
        <span className="upload-file-label">370 x 240 px (recommended)</span>
      </div>
    );

    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}

        <Growl ref={(el) => (this.growl = el)} />

        <div className="main-section ">
          <div className="p-grid nw-form blur-section">
            <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
              <div className="p-col-12 p-xl-12">
                <div className="nw-form-body">
                  <div className="p-grid seprator-inside">
                    {/* <div className="p-col-12 p-xl-12">
                                            {this.state.firstDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Bank Name<span>*</span></span>
                                                    <Dropdown
                                                        placeholder="Select Bank Name"
                                                        value={this.state.bankName}
                                                        options={bankListOptions}
                                                        onChange={(e) => this.onChangeBank(e)}
                                                        // itemTemplate={this.bankTemplate}
                                                        filter={true}
                                                        filterBy="label,value"
                                                        showClear={true}
                                                        autoWidth={false}
                                                        name={bankListOptions}
                                                    />
                                                </div>
                                            }
                                            <span className='error-message'>{this.state.errors["bankName"]}</span>
                                        </div> */}

                    <div className="p-col-12 p-xl-12">
                      {this.state.firstDropdownIsLoading ? (
                        this.NetiContentLoader.normalFormInputField()
                      ) : (
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Account Number <span>*</span>
                          </span>
                          <Dropdown
                            placeholder="Select Account Number"
                            value={this.state.accountNumber}
                            options={accountNumberListOptions}
                            onChange={(e) => {
                              this.onChangeAccountNumber(e);
                            }}
                            // itemTemplate={this.bankAccountTemplate}
                            filter={true}
                            filterBy="value"
                            showClear={true}
                            autoWidth={false}
                          />
                          {/* <span className="p-inputgroup-addon">@</span> */}
                        </div>
                      )}
                      <span className="error-message">
                        {this.state.errors["accountNumber"]}
                      </span>
                      {/* <span style={{textAlign:"right"}}>{accountNumberDetails.minRecharge !== undefined && accountNumberDetails.maxRecharge !== undefined ? `Minimum Recharge: ${accountNumberDetails.minRecharge} - Maximum Recharge: ${accountNumberDetails.maxRecharge}`: ''}</span> */}
                    </div>
                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Deposit Amount <span>*</span>
                        </span>
                        <InputText
                          placeholder="Enter Deposit Amount"
                          id="deposit_amount"
                          keyfilter="num"
                          name="depositAmount"
                          value={this.state.depositAmount}
                          onChange={(event) =>
                            this.onChangeHandle(event, this.props)
                          }
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["depositAmount"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Deposit Date <span>*</span>
                        </span>
                        <Calendar
                          placeholder="Select Deposit Date"
                          value={this.state.depositDate}
                          onChange={(e) => this.onChangeDepositDate(e)}
                          showIcon={true}
                          yearRange="2010:2030"
                          dateFormat="dd/mm/yy"
                          maxDate={maxDate}
                          monthNavigator={true}
                          showButtonBar={true}
                          yearNavigator={true}
                          dateOnly="true"
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["depositDate"]}
                      </span>
                    </div>

                    {this.state.transectionType === "T10001" ? (
                      <React.Fragment>
                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              Branch <span>*</span>
                            </span>
                            <InputText
                              placeholder="Enter Branch Name"
                              id="bank_branch"
                              name="depositBranch"
                              onChange={(event) =>
                                this.onChangeHandle(event, this.props)
                              }
                              autoWidth={false}
                            />
                          </div>
                          <span className="error-message">
                            {this.state.errors["depositBranch"]}
                          </span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon addon-no-style">
                              Deposit Type <span>*</span>
                            </span>

                            <div className="nw-inputgroup-desc desc-no-style">
                              {this.depositTypeCheck()}
                            </div>
                          </div>
                          <span className="error-message"></span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                              Note <span>*</span>
                            </span>
                            <InputTextarea
                              placeholder="Enter Deposit Note"
                              id="textarea"
                              rows={3}
                              cols={30}
                              autoResize={false}
                              name="depositNote"
                              onChange={(event) =>
                                this.onChangeHandle(event, this.props)
                              }
                            />
                          </div>
                          <span className="error-message">
                            {this.state.errors["depositNote"]}
                          </span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup nw-upload-button">
                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                              Upload Image <span>*</span>
                              <br />
                              <span>(PNG/JPG/JPEG/GIF)</span>
                            </span>

                            <div className="nw-upload-button-inside">
                              {this.state.proPic.contentPic ? (
                                <div className="image-view-main">
                                  <div className="upload-image-view">
                                    <Button
                                      className="delete-upload-button"
                                      icon="fas fa-times-circle"
                                      onClick={(e) =>
                                        this.setState({
                                          proPic: { contentPic: "" },
                                        })
                                      }
                                    />
                                    <img
                                      src={
                                        this.state.proPic.contentPic === ""
                                          ? "assets/images/demo.jpg"
                                          : "data:image/png;base64," +
                                            this.state.proPic.contentPic
                                      }
                                      style={{ height: "80px" }}
                                    />
                                  </div>
                                  <div className="image-title">
                                    {this.state.proPic.contentName}
                                  </div>
                                </div>
                              ) : (
                                <FileUpload
                                  chooseLabel={
                                    this.state.proPic.contentName ||
                                    uploadFileLabel
                                  }
                                  mode="basic"
                                  accept="image/*"
                                  maxFileSize={1000000}
                                  onSelect={this.onBasicUploadAuto.bind(this)}
                                  auto={true}
                                  name="depositSlip"
                                />
                              )}
                            </div>

                            {/* <center>
                                                            {this.state.uploadImage.contentName}
                                                        </center> */}
                          </div>
                          <span className="error-message">
                            {this.state.proPic.contentName
                              ? null
                              : errors["logo"]}
                          </span>
                        </div>

                        {/* <div className="p-col-12 p-xl-12 nw-upload-button">
                                                    <center><span>Upload Deposit Slip <span style={{ color: "Red" }}>*</span>
                                                    </span></center>
                                                    <FileUpload
                                                        mode="basic"
                                                        accept="image/*"
                                                        maxFileSize={1000000}
                                                        onSelect={this.onBasicUploadAuto.bind(this)}
                                                        auto={true}
                                                        name="depositSlip"
                                                        chooseLabel="Choose"
                                                        autoWidth={false}
                                                    />
                                                    <center><label>{this.state.proPic.contentName}</label></center>
                                                    <center><span className='error-message'>{this.state.errors["depositSlip"]}</span></center>
                                                </div> */}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                    {this.state.transectionType === "T10002" ? (
                      <React.Fragment>
                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              Mobile Banking No. <span>*</span>
                            </span>
                            <InputText
                              placeholder="Enter Mobile Banking No."
                              id="mobile_banking_no"
                              name="depositBranch"
                              // value={this.state.mobileBankingNo}
                              onChange={(event) =>
                                this.onChangeHandle(event, this.props)
                              }
                            />
                          </div>
                          <span className="error-message">
                            {this.state.errors["depositBranch"]}
                          </span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              Transaction ID <span>*</span>
                            </span>
                            <InputText
                              placeholder="Enter Transaction ID"
                              id="transaction_id"
                              // value={this.state.mobileBankingTransactionID}
                              name="depositTransactionID"
                              onChange={(event) =>
                                this.onChangeHandle(event, this.props)
                              }
                            />
                          </div>
                          <span className="error-message">
                            {this.state.errors["depositTransactionID"]}
                          </span>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}

                    <div className="p-col-12 p-xl-12" />

                    <div className="p-col-12 p-xl-12 nw-button-parent">
                      <div className="required-field">
                        (<span>*</span>) required fields
                      </div>

                      <Button
                        className={
                          this.state.buttonIconAnimClass +
                          " nw-button nw-button-right"
                        } //p-button-primary
                        label="Save"
                        icon={this.state.buttonIcon} //"fas fa-check" //fas fa-spinner fa-spin
                        onClick={this.onSubmitHandler.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-col-12 p-xl-4">
              {this.state.dataTableIsLoading ? (
                ""
              ) : (
                <WalletBalance walletBalanceName="Wallet" />
              )}

              <div className="panel-box-right">
                <div className="p-col-12">
                  <Card className="panel-box-min-max">
                    <div className="p-col-12">
                      <div className="nw-dataview-small-panel">
                        <div>
                          <div>Minimum Recharge</div>
                          <div>:</div>
                          <div
                            className={
                              accountNumberDetails.minRecharge !== undefined
                                ? "primary-color-bg"
                                : ""
                            }
                          >
                            {accountNumberDetails.minRecharge !== undefined
                              ? this.validatorUtility.currencyFormatter(
                                  accountNumberDetails.minRecharge
                                )
                              : 0.0}
                          </div>
                        </div>

                        <div>
                          <div>Maximum Recharge</div>
                          <div>:</div>
                          <div
                            className={
                              accountNumberDetails.maxRecharge !== undefined
                                ? "primary-color-bg"
                                : ""
                            }
                          >
                            {accountNumberDetails.maxRecharge !== undefined
                              ? this.validatorUtility.currencyFormatter(
                                  accountNumberDetails.maxRecharge
                                )
                              : 0.0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                {/* <div className="p-col-12">
                                    <Card className="panel-box-instruction">
                                        <center>
                                            <div className="p-col-12">
                                                <h1 style={{fontWeight: 200}}>Deposit Instruction</h1>
                                            </div>
                                            <div className="p-col-12">
                                                <Link to="#">
                                                    Click here <i class="fas fa-angle-right"></i><i class="fas fa-angle-right"></i>
                                                </Link>
                                            </div>
                                        </center>
                                    </Card>
                                </div> */}
              </div>
            </div>
          </div>
          <div className="p-col-12 p-xl-12">
            {this.state.dataTableIsLoading ? (
              ""
            ) : (
              <WalletBalanceDepositList ref="walletBalanceDepositList" />
            )}
          </div>
        </div>
      </div>
    );
  }
}
