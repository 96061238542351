import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Growl } from "primereact/growl";
import { UserCategoryService } from '../../../service/UserCategoryService';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { ErrorMessageView } from "../../common/ErrorMessageView";
import NetiContentLoader from '../../common/NetiContentLoader';
import { InputText } from "primereact/inputtext";
import CommonFuctionality from '../../common/CommonFuctionality';

export class ProductUserRole extends Component {

    constructor() {
        super();
        this.state = {
            tooltipsview: false,
            productTypeList: '',
            productType: '',
            productList: '',
            productName: '',
            userRoleList: '',
            userRole: '',
            updateUserRole: '',
            status: '',
            dataTableValue: [],
            selectedRow: {},
            visible: false,
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            thirdDropdownIsLoading: false

        }

        this.onChangeProductType = this.onChangeProductType.bind(this);
        this.onChangeProductName = this.onChangeProductName.bind(this);
        this.onChangeUserRole = this.onChangeUserRole.bind(this);
        this.saveProductUserRole = this.saveProductUserRole.bind(this);
        this.onClickProductTypeCheck = this.onClickProductTypeCheck.bind(this);
        this.CommonFuctionality = new CommonFuctionality();
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);

        this.onChangeUpdateUserRole = this.onChangeUpdateUserRole.bind(this);

        this.userCategoryService = new UserCategoryService();
        this.adminPointService = new AdminPointService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {

        this.fetchProductTypeList('T104');
        this.fetchUserRole();
        this.getAssignedProductUserRoleList();
    }

    onChangeProductType(value) {
        this.state.errors["productType"] = "";
        this.setState({ productType: value, errors: this.state.errors });
        this.fetchProductList(1, value);
    }

    onClickProductTypeCheck(value) {
        if (this.state.productType === '') {
            this.setState({ tooltipsview: true });
        } else {
            this.setState({ tooltipsview: false });
        }
    }

    onChangeProductName(value) {

        this.state.errors["productName"] = "";
        this.setState({ productName: value, errors: this.state.errors });
    }

    onChangeUserRole(value) {
        this.state.errors["userRole"] = "";
        this.setState({ userRole: value, errors: this.state.errors });
    }

    onChangeUpdateUserRole(value) {
        let { selectedRow } = this.state;
        selectedRow.coreUserRoleDTO.coreRoleID = value;
        this.setState({ selectedRow });
    }

    onChangeStatus(value) {

        let assignEnableStatus;
        if (value === 'Enable') {
            assignEnableStatus = 1;
        } else if (value === 'Disable') {
            assignEnableStatus = 0;
        }

        this.setState({ status: value });
        let { selectedRow } = this.state;
        selectedRow.assignEnableStatus = assignEnableStatus;
        this.setState({ selectedRow });
    }

    fetchProductTypeList(defaultCode) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productTypeList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    })
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchProductList(enableStatus, productTypeId) {
        let productTypeInfoDTO = {
            "coreCategoryID": productTypeId,
        }
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchProductListByProductType(enableStatus, productTypeInfoDTO)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchUserRole() {
        this.setState({ topProgressBar: true, thirdDropdownIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchUserRoleList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ userRoleList: body, topProgressBar: false, thirdDropdownIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    getAssignedProductUserRoleList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchtAssignedProductUserRoleList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].assignEnableStatus == 1) {
                                body[i].assignEnableStatus = 'Yes';
                            } else if (body[i].assignEnableStatus == 0) {
                                body[i].assignEnableStatus = 'No';
                            }
                        }
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    saveProductUserRole() {
        if (this.handleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            let requestedObj = {
                "productInfoDTO": {
                    "productID": this.state.productName,
                    "productTypeInfoDTO": {
                        "coreCategoryID": this.state.productType,
                    },
                },
                "coreUserRoleDTO": {
                    "coreRoleID": this.state.userRole,
                }
            }

            this.adminPointService.productUserRoleAssign(requestedObj)
                .then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully submitted.' });
                        this.getAssignedProductUserRoleList();
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false })
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });

                    }

                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }



    handleError() {

        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.productType === '') {
            formIsValid = false;
            errors["productType"] = "Product Type can't left empty.";
        }
        if (this.state.productName === '') {
            formIsValid = false;
            errors["productName"] = "Product Name can't left empty.";
        }
        if (this.state.userRole === '') {
            formIsValid = false;
            errors["userRole"] = "User Role can't left empty.";
        } else {
            return formIsValid;
        }
        this.setState({ errors });
    }

    viewDialog(rowData) {

        let productStatus;
        if (rowData.assignEnableStatus == "Yes") {
            productStatus = 'Enable';
        } else if (rowData.assignEnableStatus == "No") {
            productStatus = 'Disable';
        }

        this.setState({ status: productStatus });
        this.setState({ selectedRow: rowData, visible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onHide() {
        this.setState({ status: '' });
        this.setState({ visible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    submitUpdate() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        let { selectedRow } = this.state;
        this.adminPointService.updateProductUserRole(this.state.selectedRow)
            .then(res => {
                if (res.status == 202) {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully updated.' });
                    this.setState({ topProgressBar: false, dataTableIsLoading: false })
                    this.getAssignedProductUserRoleList();
                    this.setState({ visible: false });
                    this.CommonFuctionality.blurDialogBackgroundDeActive();
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    updateDialogDiscard = () => {
        this.onHide();
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="header-title">
                                <div className="header-title-left">Assigned Product User Role List</div>

                                <div className="header-title-right">
                                    <a className="total-found">
                                        Total Found: {this.state.dataTableValue.length}
                                    </a>
                                </div>
                            </div>;

        let productTypeOptions = [];
        if (this.state.productTypeList && this.state.productTypeList.length) {
            productTypeOptions = this.state.productTypeList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let productListOptions = [];
        if (this.state.productList && this.state.productList.length) {
            productListOptions = this.state.productList.map(item => ({
                value: item.productID,
                label: item.productName
            }));
        }

        let userRoleOptions = [];
        if (this.state.userRoleList && this.state.userRoleList.length) {
            userRoleOptions = this.state.userRoleList.map(item => ({
                value: item.coreRoleID,
                label: item.coreRoleNote
            }));
        }


        let editBody = (rowData) => {

            return <div className='text-center'>
                <Button
                    className="p-button p-component p-button-text-only nw-action-button"
                    icon="fas fa-pencil-alt"
                    title="Edit"
                    onClick={(e) => this.viewDialog(rowData)}
                />
            </div>
        }

        let enableStatus = 'Enable';
        let disableStatus = 'Disable';

        return (

            <div className="p-fluid">
                <div className="main-section blur-section">
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12">
                            {topProgressBar ?
                                <ErrorMessageView
                                    topProgressBar={topProgressBar}
                                />
                                : null
                            }
                            {errorMsgVisible ?
                                <ErrorMessageView
                                    errorMsgVisible={errorMsgVisible}
                                    errorMsgBody={errorMsgBody}
                                />
                                : null
                            }
                            <div className="p-grid nw-form">
                                <Growl ref={(el) => this.growl = el} />

                                <div className="nw-w-100">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            {this.state.firstDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Product Type <span>*</span></span>
                                                    <Dropdown
                                                        placeholder='Select Product Type'
                                                        options={productTypeOptions}
                                                        value={this.state.productType}
                                                        onChange={(e) => this.onChangeProductType(e.target.value)}
                                                        autoWidth={false}
                                                        filter={true}
                                                        name="productType"
                                                    />
                                                </div>
                                            }
                                            <span className='error-message'>{this.state.errors["productType"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            {this.state.secondDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup p-relative">
                                                    <span className="p-inputgroup-addon">Product Name <span>*</span></span>
                                                    {this.state.productType == "" ?
                                                        <div className="nw-w-100">
                                                            <InputText
                                                                autoWidth={false}
                                                                value=""
                                                                placeholder='Product Name'
                                                                onClick={(e) => this.onClickProductTypeCheck(e.target.value)}
                                                            />
                                                            <span class="pi pi-chevron-down emptyDropdown"></span>
                                                            {this.state.tooltipsview === true ? <span className="custom-tooltips"><div>Please Select Product Type</div></span> : ''}
                                                        </div>
                                                        :

                                                        <Dropdown
                                                            placeholder='Product Name'
                                                            options={productListOptions}
                                                            value={this.state.productName}
                                                            onChange={(e) => this.onChangeProductName(e.target.value)}
                                                            autoWidth={false}
                                                            name='productName'
                                                            filter={true}
                                                        />

                                                    }
                                                </div>
                                            }
                                            <span className='error-message'>{this.state.errors["productName"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            {this.state.thirdDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">User Role <span>*</span></span>
                                                    <Dropdown
                                                        placeholder='Select User Role'
                                                        name="userRole"
                                                        options={userRoleOptions}
                                                        value={this.state.userRole}
                                                        onChange={(e) => this.onChangeUserRole(e.target.value)}
                                                        autoWidth={false}
                                                    />
                                                </div>
                                            }
                                            <span className='error-message'>{this.state.errors["userRole"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12" />


                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                            {/* <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div> */}
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Save"
                                                icon="fas fa-check"
                                                onClick={event => this.saveProductUserRole(event)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-l-0 p-r-0 nw-data-table">
                                    {this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <DataTable
                                            header={tableHeader}
                                            value={this.state.dataTableValue}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            rowsPerPageOptions={[10, 20, 50]}
                                        >
                                            <Column field="productInfoDTO.productTypeInfoDTO.categoryName" header="Product Type" sortable={true} filter={true} />
                                            <Column field="productInfoDTO.productName" header="Product Name" sortable={true} filter={true} />
                                            <Column field="productInfoDTO.productDefaultCode" header="Default Code" sortable={true} filter={true} />
                                            <Column field="coreUserRoleDTO.coreRoleNote" header="User Role" sortable={true} filter={true} />
                                            <Column field="assignEnableStatus" header="Status" sortable={true} filter={true} />
                                            <Column field="" header="Action" style={{ width: "80px" }} body={editBody} />

                                        </DataTable>
                                    }
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
                <div className="dialog-section">
                    <Dialog header="Update Product User Role Infomation" className="nw-dialog" visible={this.state.visible} onHide={this.onHide}>

                        {this.state.visible ?
                            <div className="p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-grid nw-form">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="nw-form-body">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Product Type <span></span></span>
                                                            <InputText
                                                                value={this.state.selectedRow.productInfoDTO.productTypeInfoDTO.categoryName}
                                                                autoWidth={false}
                                                                disabled
                                                                className='disable'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Product Name <span></span></span>
                                                            <InputText
                                                                value={this.state.selectedRow.productInfoDTO.productName}
                                                                autoWidth={false}
                                                                disabled
                                                                className='disable'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Default Code <span></span></span>
                                                            <InputText
                                                                value={this.state.selectedRow.productInfoDTO.productDefaultCode}
                                                                autoWidth={false}
                                                                disabled
                                                                className='disable'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">User Role<span>*</span></span>
                                                            <Dropdown
                                                                placeholder='Select User Role'
                                                                name="updateUserRole"
                                                                options={userRoleOptions}
                                                                value={this.state.selectedRow.coreUserRoleDTO.coreRoleID}
                                                                onChange={(e) => this.onChangeUpdateUserRole(e.value)}
                                                                filter={true}
                                                                filterBy="label,value"
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <div className="input-radio-button">
                                                                <span className="p-inputgroup-addon addon-no-style">Status<span>*</span></span>
                                                                <div className="radio-button-inside">
                                                                    <RadioButton
                                                                        value={enableStatus}
                                                                        inputId="rb1"
                                                                        onChange={event => this.onChangeStatus(event.value)}
                                                                        checked={this.state.status === "Enable"}
                                                                    />
                                                                    <label htmlFor="rb1" className="p-radiobutton-label">Enable</label>
                                                                </div>
                                                                <div className="radio-button-inside">
                                                                    <RadioButton
                                                                        value={disableStatus}
                                                                        inputId="rb2"
                                                                        onChange={event => this.onChangeStatus(event.value)}
                                                                        checked={this.state.status === "Disable"}
                                                                    />
                                                                    <label htmlFor="rb2" className="p-radiobutton-label">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span className='error-message'>{this.state.errors["generateCode"]}</span>
                                                    </div>

                                                    <div className="p-col-12">
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                                <Button
                                                                    label="Discard"
                                                                    icon="fas fa-times"
                                                                    className="p-button p-button-danger nw-button nw-button-multiple"
                                                                    onClick={this.updateDialogDiscard}
                                                                />
                                                                <Button
                                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                                    label="Update"
                                                                    onClick={event => this.submitUpdate(event)}
                                                                    icon="fas fa-check"
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            : ''}


                    </Dialog>


                </div>
            </div>

        )
    }
}