import React, { Component } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import { FileUpload } from "primereact/fileupload";
import { UserCategoryService } from "../../service/UserCategoryService";
import { TokenService } from "../../service/myPoint/TokenService";
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import { NetiFileHandler } from "../../utils/NetiFileHandler";
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { InputText } from 'primereact/inputtext';



let callPurposeInfoDTO = null;

export class CreateCallLog extends Component {
  constructor() {
    super();
    this.state = {
      conatctNo: "",
      problemType:[],
      tokenContact: "",
      problemDetailsBody: "",
      problemListValue: "",
      forwardTo: null,
      callTypeValue: "inbound",
      callStatusTypeValue: "token",
      purposeType: null,
      statusType: true,
      callType: true,
      checked: false,
      noteBody: "",
      problemDetailsBody: "",
      problemModule: [],
      proPic: {},
      attachContent: "",
      attachmentName: "",
      attachmentNameView: "Click to Attach File",
      resetAttachment: "Click to Attach File",
      attachSaveOrEditable: false,
      errors: {},
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',
      firstDropdownIsLoading: false,
      secondDropdownIsLoading: false,
      thirdDropdownIsLoading: false

    };
    this.userCategoryService = new UserCategoryService();
    this.tokenService = new TokenService();
    this.onChangeContatcNo = this.onChangeContatcNo.bind(this);
    this.onChecked = this.onChecked.bind(this);
    this.inBound = this.inBound.bind(this);
    this.inBound = this.inBound.bind(this);
    this.outBound = this.outBound.bind(this);
    this.onChangeToken = this.onChangeToken.bind(this);
    this.onChangeForward = this.onChangeForward.bind(this);
    this.onChangeForwardTo = this.onChangeForwardTo.bind(this);
    this.onChangeClose = this.onChangeClose.bind(this);
    this.onChangePurposeType = this.onChangePurposeType.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
    this.handleError = this.handleError.bind(this);
    this.supportPointService = new SupportPointService();
    this.netiFileHandler = new NetiFileHandler();
    this.NetiContentLoader = new NetiContentLoader();

  }
  componentWillMount() {
    this.fetchPurposeList();
    this.fetchForwardtoList();
  }

  fetchForwardtoList() {
    this.supportPointService.fetchUserByrole().then(res => {
      if (res.status == 302) {
        return res.json().then(body => {
          this.setState({ forwardList: body });
        });
      } else {
        return res.json().then((errorBody) => console.log(errorBody));
      }
    }).catch(error => console.log('error', 'Connection Problem'));
  }

  onChangeContatcNo(e) {
    let { errors } = this.state;
    errors["conatctNo"] = '';
    this.setState({ conatctNo: e.target.value, tokenContact: e.target.value });
    this.setState({ errors });
  }
  onChangeForwardTo(e) {
    this.setState({
      forwardTo: e.value,
      tokenContact: "",
      problemDetailsBody: "",
      problemListValue: null,
      tokenContact: "",
      problemDetailsBody: "",
      attachmentNameView: this.state.resetAttachment,
      attachmentName: "",
      attachmentName: "",
      attachContent: "",
      attachSaveOrEditable: false
    });
  }
  onChangePurposeType(e) {
    let { errors } = this.state;
    errors["purposeType"] = '';
    this.setState({ errors });

    this.setState({ purposeType: e.value });
    this.setState({ secondDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    {
      this.tokenService.getUserTokenByDefaultCategory(e.value).then(res => {
        if (res.status == 302) {
          return res.json().then(body => {
            this.setState({ problemModule: body });
            this.setState({ secondDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          });
        } else {
          this.tokenService.Auth.handleErrorStatus(res)
            .then((responseBody) => {
                this.setState({ problemModule: [] });
                this.setState({ secondDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody });
            });
        }
      }).catch(error => {
        this.setState({ secondDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })
      });
    }
  }

  onChangeNote = e => {
    let { errors } = this.state;
    errors["noteBody"] = '';
    this.setState({ errors });

    this.setState({ noteBody: e.target.value })
    console.log('Log: CreateCallLog -> e.target.value', e.target.value)
  }

  getProblemModuleList = e => {
    let { errors } = this.state;
    errors["moduleValue"] = '';
    this.setState({ errors });

    this.setState({ moduleValue: e.value });
    this.setState({ thirdDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: true });
    {
      this.tokenService
        .getUserTokenByParentCategory(e.value)
        .then(res => {
          if (res.status == 302) {
            return res.json().then(body => {
              console.log('body', body);
              
              this.setState({ problemType: body });
              this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
            });
          } else {
            this.tokenService.Auth.handleErrorStatus(res)
            .then((responseBody) => {
              this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody });
            });
          }
        })
        .catch(error => {
          this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
        });
    }
  };

  getProblemTypeList = e => {
    let { errors } = this.state;
    errors["problemListValue"] = '';
    this.setState({ errors });

    this.setState({ problemListValue: e.value });
  };

  onChangeProblemDetails = e => {
    let { errors } = this.state;
    errors["problemDetailsBody"] = '';
    this.setState({ errors });

    this.setState({ problemDetailsBody: e.target.value })
  }

  inBound(e) {
    this.setState({
      callType: e.value,
      show: true,
      show2: false,
      tokenshow: true,
      statusType: true
    });
  }
  outBound(e) {
    this.setState({
      callType: e.value,
      show: false,
      show2: true,
      forwardshow: false,
      tokenshow: false
    });
  }

  onStatusChange = (e) => {
    console.log("status change",e.target.value)
    if (!this.state.checked) {
      if (e.target.value === true) {
        this.setState({
          statusType: e.target.value,
          callStatusTypeValue: "token",
          tokenContact: this.state.conatctNo,
          attachmentNameView: this.state.resetAttachment,
          attachmentName: "",
          attachmentName: "",
          attachContent: "",
          attachSaveOrEditable: false
        })
      } else if(e.target.value === false) {
        this.setState({
          statusType: e.target.value,
          callStatusTypeValue: "forward",
          tokenContact: ""
        })
      }
    }
  }
  onChecked(e) {
    this.setState({
      checked: e.checked,
      callStatusTypeValue: null,
      forwardTo: null,
      tokenContact: "",
      problemDetailsBody: "",
      problemListValue: null,
      tokenContact: "",
      attachmentNameView: this.state.resetAttachment,
      attachContent: "",
      attachSaveOrEditable: false
    });

    if (e.checked == true) {
      this.setState({ statusType: null,callStatusTypeValue: 'close'
      });
    }
    // else if (e.checked == false) {
    //   this.setState({ statusType: true });
    // }

  }

  onImageUpload = e => {
    var reader = new FileReader();
    let photo = e.files[0];
    const scope = this;
    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = "data:" + photo.type + ";base64,"; //link will be same from the word webapps in URL
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      let album = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name
      };
      scope.setState({
        proPic: album,
        attachContent: album.contentPic,
        attachSaveOrEditable: true,
        attachmentName: album.contentName,
      });
    };

    let { errors } = this.state;
    errors["image"] = '';
    this.setState({ errors });
  };

  onChangeToken(e) {
    this.setState({ statusType: e.value, tokenshow: true, forwardshow: false });
  }
  onChangeForward(e) {
    this.setState({ statusType: e.value, tokenshow: false, forwardshow: true });
  }
  onChangeClose(e) {
    this.setState({
      statusType: e.value,
      tokenshow: false,
      forwardshow: false
    });
  }


  fetchPurposeList() {
    this.setState({ firstDropdownIsLoading: true, topProgressBar: true });

    this.userCategoryService.fetchListByDefaultCode("T11001").then(res => {
      if (res.status == 302) {
        return res.json().then(body => {
          this.setState({ firstDropdownIsLoading: false, topProgressBar: false });
          this.setState({ purposeList: body });
        });
      } else {
        this.supportPointService.Auth.handleErrorStatus(res)
        .then((resp) => {
          this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
        });
      }
    }).catch(error => {
      this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
    });
  }


  handleSaveData = () => {
    if (this.handleError()) {
      // if (this.state.proPic){
      //   if (this.state.proPic.contentPic !== null)
      //   this.setState({ attachContent: this.state.proPic.contentPic,attachSaveOrEditable:true, attachmentName:this.state.proPic.contentName });
      // }
      let requestedObject = {
        callLogID: 0,
        callType: this.state.callTypeValue,
        serviceSeekerContact: this.state.conatctNo,
        callNote: this.state.noteBody,
        callStatusType: this.state.callStatusTypeValue,
        callPurposeInfoDTO: {
          coreCategoryID: callPurposeInfoDTO
        },
        callTransferredUserBasicInfoDTO: {
          netiID: this.state.forwardTo
        },
        tokenInfoDTO: {
          tokenDetails: this.state.problemDetailsBody,
          tokenContact: this.state.tokenContact,
          attachmentName: this.state.attachmentName,
          tokenTypeInfoDTO: {
            coreCategoryID: this.state.problemListValue
          },
          attachSaveOrEditable: this.state.attachSaveOrEditable,
          attachContent: this.state.attachContent
        }
      };

      this.setState({ topProgressBar: true, errorMsgVisible: false });
      console.log("all object",requestedObject);
      
      this.supportPointService.createCallLog(requestedObject)
        .then(res => {
          if (res.status === 201) {
            this.growl.show({ severity: "success", summary: "Success Message", detail: "Successfully submitted" });
            this.setState({callType: true, purposeType: null,checked: false, callStatusTypeValue: "token",callTypeValue: "inbound",attachmentNameView: this.state.resetAttachment, attachContent: "", attachmentName: "", attachSaveOrEditable: false });
            this.setState({ conatctNo:"",noteBody:'',moduleValue:null,problemDetailsBody:'' });
            this.removeButtonOnclick();
            this.setState({ topProgressBar: false });
          } else {
            this.supportPointService.Auth.handleErrorStatus(res)
              .then((resp) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
              });
          }
        })
        .catch(error => {
          this.setState({ topProgressBar: false });
          console.log("error", 'Connection Problem')
        });
    }
  };

  handleError() {
    let errors = {};
    let formIsValid = true;
    if (this.state.conatctNo === "") {
      formIsValid = false;
      errors["conatctNo"] = "Enter Mobile No. can't left empty";
    }

    if (this.state.purposeType === null) {
      formIsValid = false;
      errors["purposeType"] = "Select Purpose Type can't left empty";
    }

    if (this.state.noteBody === "") {
      formIsValid = false;
      errors["noteBody"] = "Enter Note can't left empty";
    }

    if (this.state.callType === true) {

      if (this.state.statusType === true) {

        if (this.state.moduleValue === undefined) {
          formIsValid = false;
          errors["moduleValue"] = "Select Problem Module can't left empty";
        }

        if (this.state.problemListValue === "") {
          formIsValid = false;
          errors["problemListValue"] = "Select Problem Type can't left empty";
        }

        if (this.state.problemDetailsBody === "") {
          formIsValid = false;
          errors["problemDetailsBody"] = "Enter Problem Details can't left empty";
        }
      }

      if (this.state.statusType === false) {
        if (this.state.forwardTo === null) {
          formIsValid = false;
          errors["forwardTo"] = "Select User can't left empty";
        }
      }

    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  removeButtonOnclick = () =>{
    // if(this.state.viewUpdatePicture != ''){
    //     this.setState({ uploadImage: { contentPic: '' } })
    // }
    // else if(this.state.uploadImage.contentPic != ''){
        this.setState({ proPic: { contentPic: '' } })
        // this.getImg()
        
    // }
    
}

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">(370 x 240 px (recommended))</span></div>

    let purposeListOptions = [];
    if (this.state.purposeList && this.state.purposeList.length) {
      purposeListOptions = this.state.purposeList.map(item => ({
        value: item.categoryDefaultCode,
        label: item.categoryName
      }));
    }
    if (this.state.purposeList) {
      for (var i = 0; i < this.state.purposeList.length; i++) {
        if (
          this.state.purposeList[i].categoryDefaultCode ==
          this.state.purposeType
        ) {
          callPurposeInfoDTO = this.state.purposeList[i].coreCategoryID;
        }
      }
    }
    let problemModuleList = [];
    if (this.state.problemModule && this.state.problemModule.length) {
      problemModuleList = this.state.problemModule.map(item => ({
        label: item.categoryName,
        value: item.coreCategoryID
      }));
    }
    let problemTypeList = [];
    // if (this.state.problemType && this.state.problemType.length) {
    //   problemTypeList = this.state.problemType.map(item => ({
    //     label: item.categoryName,
    //     value: item.coreCategoryID
    //   }));
    // }


    if ((this.state.problemType != null) && (this.state.problemType.length > 0)) {
      problemTypeList = this.state.problemType.map(item => ({
        label: item.categoryName,
        value: item.coreCategoryID
      }));
  }


    let forwardtoOptions = [];
    if (this.state.forwardList && this.state.forwardList.length) {
      forwardtoOptions = this.state.forwardList.map(item => ({
        label: item.fullName,
        value: item.netiID
      }));
    }

    return (
      <div className="p-grid nw-form">
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <Growl ref={el => (this.growl = el)} />

        <div className="p-col-12 p-xl-12">
          <div className="nw-form-body">

            <div className="p-col-12 p-xl-12">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">Contact No. <span>*</span></span>
                <InputText
                  type="text"
                  value={this.state.conatctNo}
                  placeholder="Enter Contact No."
                  onChange={this.onChangeContatcNo}
                  name="tokenContact"
                  keyfilter="pint"
                  maxLength={11}
                />
              </div>
              <span className="error-message">
                {this.state.errors["conatctNo"]}
              </span>
            </div>


            <div className="p-col-12 p-xl-12">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">Call Type <span>*</span></span>
                <div className="nw-radiobutton-group">
                  <RadioButton
                    inputId="rb1"
                    name="callType"
                    value={true}
                    onChange={e =>
                      this.setState({
                        callType: e.target.value,
                        callTypeValue: "inbound",
                        // callStatusTypeValue: "token"
                      })
                    }
                    checked={this.state.callType === true}
                  />
                  <label htmlFor="rb1" className="p-radiobutton-label">
                    Inbound
                            </label>
                  &nbsp; &nbsp;
                            <RadioButton
                    inputId="rb2"
                    name="callType"
                    value={false}
                    onChange={e =>
                      this.setState({
                        callType: e.target.value,
                        callStatusTypeValue: null,
                        callTypeValue: "outbound",
                        forwardTo: null,
                        tokenContact: "",
                        problemDetailsBody: "",
                        problemListValue: null
                      })
                    }
                    checked={this.state.callType === false}
                  />
                  <label htmlFor="rb2" className="p-radiobutton-label">
                    Outbound
                        </label>
                </div>
              </div>
            </div>

            {this.state.callType ? (
              <div>
                <div className="p-col-12 p-xl-12">
                  {this.state.firstDropdownIsLoading ?
                    this.NetiContentLoader.normalFormInputField() :
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Purpose  <span>*</span></span>
                      <Dropdown
                        value={this.state.purposeType}
                        options={purposeListOptions}
                        onChange={this.onChangePurposeType}
                        placeholder="Select Purpose"
                        filter={true}
                        filterBy="label,value"
                        autoWidth={false}
                      />
                    </div>
                  }
                  <span className="error-message">
                    {this.state.errors["purposeType"]}
                  </span>
                </div>


                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputtextarea-label">Note  <span>*</span></span>
                    <InputTextarea
                      value={this.state.noteBody}
                      placeholder="Enter Note"
                      onChange={this.onChangeNote}
                      rows={5}
                      cols={30}
                    />
                  </div>
                  <span className="error-message">
                    {this.state.errors["noteBody"]}
                  </span>
                </div>


                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Status  <span>*</span></span>
                    <div className="nw-radiobutton-group">
                      <RadioButton
                        inputId="rb3"
                        name="statusType"
                        value={true}
                        onChange={e => this.onStatusChange(e)}
                        checked={this.state.statusType === true}
                      />
                      <label htmlFor="rb3" className="p-radiobutton-label">
                        Token
                      </label>
                      &nbsp; &nbsp;
                      <RadioButton
                        inputId="rb4"
                        name="statusType"
                        value={false}
                        onChange={e => this.onStatusChange(e)}
                        checked={this.state.statusType === false}
                      />
                      <label htmlFor="rb4" className="p-radiobutton-label">
                        Forward
                      </label>
                      &nbsp; &nbsp;

                      <Checkbox
                        onChange={this.onChecked}
                        checked={this.state.checked}
                      />

                      <label htmlFor="rb5" className="p-radiobutton-label">
                        Close
                      </label>


                    </div>
                  </div>
                </div>


                {this.state.checked ? (
                  ""
                ) : (
                    <div className="p-col-12 p-fluid">
                      {this.state.statusType ? (
                        <div className="p-grid p-fluid">

                          <div className="p-col-12 p-xl-12">
                            {this.state.secondDropdownIsLoading ?
                              this.NetiContentLoader.normalFormInputField() :
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Problem Module  <span>*</span></span>
                                <Dropdown
                                  value={this.state.moduleValue}
                                  options={problemModuleList}
                                  onChange={this.getProblemModuleList}
                                  placeholder="Select Problem Module"
                                  filter={true}
                                  filterBy="label,value"
                                  autoWidth={false}
                                />
                              </div>
                            }
                            <span className="error-message">
                              {this.state.errors["moduleValue"]}
                            </span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            {this.state.thirdDropdownIsLoading ?
                              this.NetiContentLoader.normalFormInputField() :
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Problem Type  <span>*</span></span>
                                <Dropdown
                                  value={this.state.problemListValue}
                                  options={problemTypeList}
                                  onChange={this.getProblemTypeList}
                                  placeholder="Select Problem Type"
                                  filter={true}
                                  filterBy="label,value"
                                  autoWidth={false}
                                />
                              </div>
                            }
                            <span className="error-message">
                              {this.state.errors["problemListValue"]}
                            </span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon nw-inputtextarea-label">Problem Details <span>*</span></span>
                              <InputTextarea
                                value={this.state.problemDetailsBody}
                                placeholder="Enter Problem Details"
                                onChange={this.onChangeProblemDetails}
                                rows={5}
                                cols={30}
                              />
                            </div>
                            <span className="error-message">
                              {this.state.errors["problemDetailsBody"]}
                            </span>
                          </div>

                          {/* <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">Contact No.</span>
                              <InputMask
                                type="text"
                                value={this.state.conatctNo}
                                placeholder="Enter Contact No."
                                keyfilter="num"
                                name="tokenContact"
                                maxlength={14}
                                mask="01999999999"
                                disabled="disabled"
                              />
                            </div>
                          </div> */}

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup nw-upload-button">
                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                    Attachment
                                    <br />
                                    <span>(PNG/JPG/JPEG/GIF)</span>
                                </span>

                                <div className="nw-upload-button-inside">
                                  { this.state.proPic.contentPic ?  
                                    <div className="image-view-main">
                                      <div className="upload-image-view">
                                        <Button
                                              className="delete-upload-button"
                                              icon="fas fa-times-circle"
                                              onClick={this.removeButtonOnclick}
                                          />
                                          <img
                                          src={
                                              "data:" + this.state.proPic.extention + ";base64," + this.state.proPic.contentPic
                                          }
                                          
                                          style={{ "height": "80px" }}
                                      />
                                    </div>
                                    <div>{this.state.proPic.contentName ? this.state.proPic.contentName : this.state.attachmentName}</div>
                                  </div>
                                  :
                                  <FileUpload
                                    chooseLabel={this.state.proPic.contentName || uploadFileLabel}
                                    mode="basic"
                                    id="attachment"
                                    accept="image/*"
                                    maxFileSize={1000000}
                                    onSelect={this.onImageUpload}
                                    auto={true}
                                />
                                  
                                }
                                </div>
                            </div>
                            <span className="error-message">{this.state.errors['image']}</span>
                          </div>

                        </div>
                      ) : (
                          <div className="p-fluid">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">Forward To  <span>*</span></span>
                              <Dropdown
                                value={this.state.forwardTo}
                                options={forwardtoOptions}
                                onChange={this.onChangeForwardTo}
                                placeholder="Select Forward To"
                                filter={true}
                                filterBy="label,value"
                                autoWidth={false}
                              />
                            </div>
                            <span className="error-message">
                              {this.state.errors["forwardTo"]}
                            </span>
                          </div>
                        )}
                    </div>
                  )}


                <div className="p-col-12 p-xl-12" />

                <div className="p-col-12 p-xl-12 nw-button-parent">

                  <div className="required-field">
                    (<span>*</span>) required fields
                  </div>

                  <Button
                    className="p-button p-button-primary nw-button nw-button-right"
                    label="Save"
                    onClick={this.handleSaveData}
                    icon="fas fa-check"
                  />
                </div>

              </div>
            ) : (
                <div className="p-fluid">
                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Purpose  <span>*</span></span>
                      <Dropdown
                        value={this.state.purposeType}
                        options={purposeListOptions}
                        onChange={this.onChangePurposeType}
                        placeholder="Select a Purpose Type"
                        filter={true}
                        filterBy="label,value"
                        autoWidth={false}
                      />
                    </div>
                    <span className="error-message">
                      {this.state.errors["purposeType"]}
                    </span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                      <InputTextarea
                        value={this.state.noteBody}
                        placeholder="Enter Note"
                        onChange={this.onChangeNote}
                        rows={5}
                        cols={30}
                      />
                    </div>
                    <span className="error-message">
                      {this.state.errors["noteBody"]}
                    </span>
                  </div>

                  <div className="p-col-12 p-xl-12" />


                  <div className="p-col-12 p-xl-12 nw-button-parent">

                    <div className="required-field">
                      (<span>*</span>) required fields
                    </div>

                    <Button
                      className="p-button p-button-primary nw-button nw-button-right"
                      label="Save"
                      onClick={this.handleSaveData}
                      icon="fas fa-check"
                    />
                  </div>
                </div>
              )}
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}
