import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Rating } from "primereact/rating";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { PartnerRatingListToTeamLead } from "./PartnerRatingListToTeamLead";

export class PartnerRatingProvideToTeamLead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partnerRatingProvidetoTeamLeadInfo: {
        rating: null
      },

      partnerRatingProvidetoTeamLeadInfo: {
        incomeCategory: "",
        incomeYear: null,
        incomeMonth: "",
        incomeNote: "",

        coreUrlInfoDTO: {
          urlInfoID: 1
        }
      },
      errors: {},
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: ""
    };

    this.markTemplate = this.markTemplate.bind(this);
  }

  markTemplate(rowData) {
    return (
      <Checkbox checked={rowData.checked} onChange={e => this.setState({})} />
    );
  }

  targetAmountTemplate = () => {
    return (
      <div class="p-inputgroup">
        <InputText
          id="calendarDetails"
          type="text"
          name="calendarDetails"
          autoWidth={true}
          keyfilter="pint"
        />
        <span> BDT</span>
      </div>
    );
  };

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    // console.log("RENDER PROPS", this.props);

    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}

        <div className="p-grid">
          <div className="p-col-12 p-xl-12">
              <Growl ref={el => (this.growl = el)} />
              <div className="p-grid nw-form">
                <div className="p-col-12 p-xl-12">
                  <div className="nw-form-body">
                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Year <span>*</span></span>
                        <Dropdown
                          // value={calendarInfo.banneCategory}
                          // onChange={this.onChangecalendarCategory}
                          id="year"
                          placeholder="Select Year"
                          showClear={true}
                          type="text"
                          name="year"
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {/* {this.state.errors["calendarCategory"]} */}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Month <span>*</span></span>
                        <Dropdown
                          // value={calendarInfo.banneCategory}
                          // onChange={this.onChangecalendarCategory}
                          id="month"
                          placeholder="Select Month"
                          showClear={true}
                          type="text"
                          name="month"
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {/* {this.state.errors["calendarCategory"]} */}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Designation <span>*</span></span>
                        <Dropdown
                          // value={calendarInfo.banneCategory}
                          // onChange={this.onChangecalendarCategory}
                          id="designation"
                          placeholder="Select Designation"
                          showClear={true}
                          type="text"
                          name="designation"
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {/* {this.state.errors["calendarCategory"]} */}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Neti ID <span>*</span></span>
                        <InputText
                          // value={calendarInfo.banneCategory}
                          // onChange={this.onChangecalendarCategory}
                          id="netiId"
                          placeholder={
                            this.state.partnerRatingProvidetoTeamLeadInfo.netiId
                          }
                          showClear={true}
                          type="text"
                          name="netiId"
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {/* {this.state.errors["calendarCategory"]} */}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Rating <span>*</span></span>
                        <Rating
                          id="rating"
                          value={
                            this.state.partnerRatingProvidetoTeamLeadInfo.rating
                          }
                          cancel={false}
                          onChange={this.setRating}
                        />
                      </div>
                      <span className="error-message">
                        {/* {this.state.errors["calendarCategory"]} */}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">

                    </div>

                    <div className="p-col-12 p-xl-12 nw-button-parent">

                    <div className="required-field">
                        (<span>*</span>) required fields
                    </div> 

                      <Button
                        className="p-button p-button-primary nw-button nw-button-right"
                        label="Save"
                        icon="fas fa-check"
                        onClick={this.onSubmitHandler}
                      />

                    </div>
                  </div>
                </div>

                <div className="p-col-12 p-xl-12">
                  <div>
                    {!this.state.dataTableIsLoading ? (
                      <PartnerRatingListToTeamLead />
                    ) : (
                      this.NetiContentLoader.MyPointTableLoader()
                    )}
                  </div>
                </div>
              </div>
          </div>
          
        </div>
      </div>
    );
  }
}
