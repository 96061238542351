import React, { Component } from "react";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { SaCoreSettingsService } from "../../../service/saPoint/SaCoreSettingsService";
import { StudentClassConfigInfo } from "../common/StudentClassConfigInfo";
import NetiContentLoader from "../../common/NetiContentLoader";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { EmUserPointService } from '../../../service/emUserPoint/EmUserPointService';


let totalFullMark;
let totalHighestMark;
let totalObtainedMark;
export class StudentPortalClassTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      academicYearList: [],
      academicYear: "",
      dataTableValue: [],
      classTestName: "",
      classTestList: [],
      errorMsgBody: '',
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      firstDropdownIsLoading: false,
      secondDropdownIsLoading: false,
      userList: [],
      userID: '',
      userLength: '',
      insID: '',
      basicInfo: [],
      stId: '',
    };
    this.onChangeclassTestName = this.onChangeclassTestName.bind(this);
    this.onChangeAcYear = this.onChangeAcYear.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.studentPortal = new StudentPortal();
    this.saCoreSettingsService = new SaCoreSettingsService();
    this.NetiContentLoader = new NetiContentLoader();
    // this.StudentPortalMain = new StudentPortalMain();
     this.emUserPointService = new EmUserPointService();
   
  }
 



  
    // componentWillMount() {
    //     this.loadStudentInfo();
    //     this.fetchAcademicYearList();
    //     // this.fetchClassTestList();
    //     this.setCurrentAcademicYearToDropDown();
    //     totalFullMark = totalHighestMark = totalObtainedMark = '';
        
    // }
    componentWillMount(){
      this.loadStudentInfo()
      totalFullMark = totalHighestMark = totalObtainedMark = '';
    }

  handledropdwon = (id, insId) => {
    this.setState({userID: id})
    this.setState({insID:insId})

    this.loadStudentInfo(); 
 }




loadStudentInfo = () => {

    // setTimeout(() => {
    //     [...document.querySelectorAll('.protal-single-info-content')].map(item => {
    //         if (!item.querySelector('p').innerHTML.trim()) {
    //             item.querySelector('p').innerHTML = '---'
    //         }
    //     })
    // }, 1500);

    let userListtt = [];
    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    this.emUserPointService.fetchMappedPortalList()
        .then((res) => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    // console.log('Log: GoToPortal -> componentWillMount -> body......', body[0].idForStudent)
                    console.log('Log: GoToPortal -> componentWillMount -> body......', body)
                    

                    body.map(item => {
                        if(item.mappingType === "Student") {
                          userListtt.push(item)
                        }
                    })
                    this.setState({userList: userListtt})
                    this.setState({userLength:body.length})
                    this.setState({stId:body[0].idForStudent})

                    console.log("user id inside : ", this.state.userID)
                    
                    this.studentPortal
                    .fetchStudentProfileDetailsList(
                        this.state.insID || body[0]["emDetailsInfoDTO"].instituteId ,
                        this.state.userID || body[0].idForStudent
                        // body[this.state.userID]["emDetailsInfoDTO"].instituteId,
                        // body[this.state.userID].idForStudent,
                    )
                    .then(res => {
                        if (res.status == 200) {
                          
                            return res.json().then(body => {
                                this.setState({ topProgressBar: false, errorMsgVisible: false })
                                this.setState({ basicInfo: body });
                                console.log("test...", this.state.basicInfo)
                                this.setCurrentAcademicYearToDropDown();
                                this.fetchAcademicYearList();
                                this.fetchClassTestList();
                               
                    
                            });
                        } else {
                            this.studentPortal.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    })
                    .catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));

                    body.map(item => {
                        if(item.mappingType === "Student") {
                          userListtt.push(item)
                        }
                    })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                })
            } else {
                this.emUserPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch((error) => {
            console.log(error)
            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        });


    this.setState({ topProgressBar: true, errorMsgVisible: false })
};




//   componentWillMount() {

//     this.fetchAcademicYearList();
//     this.fetchClassTestList();
//     this.setCurrentAcademicYearToDropDown();
//     totalFullMark = totalHighestMark = totalObtainedMark = '';
//   }

  fetchAcademicYearList = () => {
    this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
    this.saCoreSettingsService.getGlobalInfoByTypeId(2101)
      .then(res => {

        if (res.item) {
          this.setState({ academicYearList: res.item });
          this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false });
        } else {
          this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
        }

      }).catch(error => {
        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
      });
  }



  setCurrentAcademicYearToDropDown = () => {
    let academicYear = NetiDateUtils.getYear(new Date());
    this.setState({ academicYear })
  }

  onAcademicYearFilter = () => {
    if (this.state.academicYearFiltering) this.setCurrentAcademicYearToDropDown();
    this.setState({ academicYearFiltering: !this.state.academicYearFiltering });
  }

  onChangeAcYear = (e) => {
    let { errors } = this.state;
    errors["academicYear"] = "";
    this.setState({ academicYear: e.target.value, errors });
  };

  onChangeclassTestName = e => {
    let { errors } = this.state;
    errors["classTestName"] = "";
    this.setState({ classTestName: e.value, errors });
  };

  fetchClassTestList() {

    console.log("class test...", this.state.basicInfo)
    this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false });
    this.studentPortal.fetchClassTestList(this.state.basicInfo.instituteId, this.state.basicInfo.studentId, this.state.basicInfo.academicYear).then(res => {
      if (res.status == 200) {
        return res.json().then(body => {
          this.setState({ classTestList: body.item })
          this.setState({ topProgressBar: false, secondDropdownIsLoading: false })
        });
      } else {
        this.studentPortal.Auth.handleErrorStatus(res)
          .then((responseBody) => {
            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
          });
      }
    }).catch(
      error => this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
    );
  }

  handleSubmit(e) {
    this.setState({ dataTableValue: [] });

    if (this.handleError()) {
      this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
      this.studentPortal.fetchClassTestMarksList(this.state.basicInfo.instituteId, this.state.basicInfo.studentId, this.state.academicYear, this.state.classTestName)
        .then(res => {

          if (res.status == 200) {
            return res.json().then(body => {
              if (body.item === null || body.item.stdCtExamMarks.length == 0) {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
              } else {
                totalFullMark = body.item.stdCtExamMarks.map(item => item.subjectFullMark).reduce((a, b) => (a + b));
                totalHighestMark = body.item.stdCtExamMarks.map(item => item.highestMarks).reduce((a, b) => (a + b));
                totalObtainedMark = body.item.stdCtExamMarks.map(item => item.obtainedMark).reduce((a, b) => (a + b));
                this.setState({ dataTableValue: body.item.stdCtExamMarks, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
              }
            });
          } else {
            this.studentPortal.Auth.handleErrorStatus(res)
              .then((responseBody) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
              });
          }
        }).catch(error => {
          this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        })
    }
  }


  handleError = () => {

    let { errors } = this.state;
    let formIsValid = true;

    if (this.state.academicYear === '') {
      formIsValid = false;
      errors["academicYear"] = "Academic Year can't left empty.";
    }
    if (this.state.classTestName === '') {
      formIsValid = false;
      errors["classTestName"] = "Class Test can't left empty.";
    }

    else {
      return formIsValid;
    }
    this.setState({ errors });
  }

  render() {
        var rows = [];
        let l = this.state.userLength;
        this.state.userList.map((item) =>{
           
            if(l > rows.length){
                rows.push(<a  onClick={() => this.handledropdwon(item.idForStudent, item["emDetailsInfoDTO"].instituteId)} >    {item.idForStudent}  </a>)
            }
        })
      

        // console.log("user id render : ", this.state.userID)
        let basicInfo = this.state.basicInfo;

    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
      Class Test Mark List
    <div
        style={{ color: "white", display: "flex", float: "right" }}
      >
        Total Found: {this.state.dataTableValue.length}

      </div>
    </div>;
    let footerGroup = <ColumnGroup>
      <Row>
        <Column footer="Total:" />
        <Column footer={totalFullMark} />
        <Column footer={totalHighestMark} />
        <Column footer={totalObtainedMark} />
      </Row>
    </ColumnGroup>;

    let acYearOptions = [];
    if (this.state.academicYearList && this.state.academicYearList.length) {
      acYearOptions = this.state.academicYearList.map(item => ({
        value: item.name,
        label: item.name
      }));
    }

    let examListOptions = [];
    if (this.state.classTestList && this.state.classTestList.length) {
      examListOptions = this.state.classTestList.map(item => ({
        value: item.classTestConfigID,
        label: item.testName
      }));
    }

    return (
      <div className="fluid">
        <Growl ref={(el) => this.growl = el} />
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }



        <div className="p-grid nw-form">
          <div className="p-col-12" style={{position: "relative"}}>
            <StudentClassConfigInfo basicInfo={this.state.basicInfo} />
          
                    <div className='dropdown'>
            
                            <button className="dropbtn">
                            {/* <i className="fa fa-caret-down"></i> */}
                            <span class="fas fa-align-right p-c p-button-icon-left"></span>                            </button>
                            <div className="dropdown-content">
                                
                             {rows}

                            </div>
                    
                    </div>
          </div>

          <div className="p-col-12">
            <p className="profile-custom-table-header">Class Test</p>
          </div>


          <div className="p-col-12 p-col-nogutter">
            <div className="nw-form-body">

              <div className="p-col-12">
                <div className="p-md-2 p-md-offset-10 p-col-4 p-offset-8">
                  <Button
                    label="Academic Year"
                    icon="fas fa-filter"
                    iconPos="right"
                    onClick={this.onAcademicYearFilter}
                  />
                </div>
              </div>

              <div className="p-col-12">
                {this.state.firstDropdownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputgroup-small-span">Academic Year <span>*</span></span>
                    {this.state.academicYearFiltering ?
                      <Dropdown
                        options={acYearOptions}
                        placeholder="Select Academic Year"
                        value={this.state.academicYear}
                        onChange={this.onChangeAcYear}
                        autoWidth={false}
                        name='academicYear'
                        filter={true}
                      />
                      :
                      <Dropdown
                        disabled
                        options={acYearOptions}
                        placeholder="Select Academic Year"
                        value={this.state.academicYear}
                        onChange={this.onChangeAcYear}
                        autoWidth={false}
                        name='academicYear'
                      />}
                  </div>
                }
                <span className="error-message"> {this.state.academicYear ? '' : this.state.errors['academicYear']} </span>
              </div>

              <div className="p-col-12">
                {this.state.secondDropdownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputgroup-small-span">
                      Class Test <span>*</span>
                    </span>
                    <Dropdown
                      placeholder="Select Class Test"
                      name="classTestName"
                      options={examListOptions}
                      value={this.state.classTestName}
                      onChange={this.onChangeclassTestName}
                      filter={true}
                      filterBy="label,value"
                      autoWidth={false}
                    />
                  </div>
                }
                <span className='error-message'>{this.state.errors["classTestName"]}</span>
              </div>


              <div className="p-col-12 nw-button-parent">
                <Button
                  className="p-button p-button-primary nw-button nw-button-right"
                  label="Search"
                  icon="fas fa-search"
                  onClick={this.handleSubmit}
                />
              </div>

            </div>
            <br />
          </div>


          <div className="p-col-12 p-xl-12">
            {this.state.dataTableIsLoading ?
              this.NetiContentLoader.MyPointTableLoader() :
              <div className="nw-data-table">
                <DataTable
                  footerColumnGroup={footerGroup}
                  header={tableHeader}
                  value={this.state.dataTableValue}
                  responsive={true}
                  paginator={true}
                  rows={10}
                >
                  <Column field="subjectName" header="Subject Name" sortable={true} filter={true} />
                  <Column field="subjectFullMark" header="Full Mark" sortable={true} filter={true} />
                  <Column field="highestMarks" header="Highest Marks" sortable={true} filter={true} />
                  <Column field="obtainedMark" header="Obtained Marks" sortable={true} filter={true} />
                </DataTable>
              </div>
            }

          </div>

        </div>
      </div>
    );
  }
}
