import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { Growl } from "primereact/growl";
import { RadioButton } from "primereact/radiobutton";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
import NetiContentLoader from "../../common/NetiContentLoader";

import { ProductInfoUpdate } from "./ProductInfoUpdate";
import { ProductStockInfoUpdate } from "./ProductStockInfoUpdate";
import { UserCategoryService } from "../../../service/UserCategoryService";
import { ProfileService } from "../../../service/profile/ProfileService";
import { AdminPointService } from "../../../service/adminPoint/AdminPointService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
import CommonFuctionality from "../../common/CommonFuctionality";
import { ProducListInfoDialog } from "./ProducListInfoDialog";
let cloneStateBeforeMount;
let errors = {};
const hundred = 100;
export class ProductInfo extends Component {
  constructor() {
    super();
    this.state = {
      viewDialogShow: false,
      productOwnerRevenueInfo: {},
      errors: {},
      productTypeList: "",
      productType: "",
      productName: "",
      defaultCode: "",
      productDetails: "",
      remainingStock: "",
      generateCode: "No",
      additionalInfo1: false,
      additionalInfo2: true,
      bijoySellNumb: "",
      bijoyMultiNumb: "",
      salesPrice: "",
      costPercent: "",
      fixedPercent: "",
      levelPercent: "",
      taxPercent: "",
      vatPercent: "",
      ownerNetiId: "",
      userDetails: "",

      dataTableValue: [],
      productOwnerDialogueFixedRevenueDataTableValue: [
        {
          netiID: "testData",
          name: "testData",
          percentage: "testData",
          amount: "testData",
        },
      ],
      productOwnerDialogueLevelRevenueDataTableValue: [
        {
          netiID: "testData",
          name: "testData",
          percentage: "testData",
          amount: "testData",
        },
      ],

      updateDialogShow: false,
      stockDialogShow: false,
      firstDropdownIsLoading: false,
      searchIsLoading: false,
      dataTableIsLoading: false,
      topProgressBar: false,
      selectedRowData: {},
      searchView: false,
      searchViewError: false,
      netiIDViewError: false,
      buttonDisabled: true,
      togleBtnActive: false,
    };

    this.onChangeProductType = this.onChangeProductType.bind(this);
    this.onChangeProductName = this.onChangeProductName.bind(this);
    this.onChangeDefaultCode = this.onChangeDefaultCode.bind(this);
    this.onChangeProductDetails = this.onChangeProductDetails.bind(this);
    this.onChangeRemainingStock = this.onChangeRemainingStock.bind(this);
    this.onChangeGenerateCode = this.onChangeGenerateCode.bind(this);
    this.validatorUtility = new ValidatorUtility();

    this.onChangeBijoySellNumb = this.onChangeBijoySellNumb.bind(this);
    this.onChangeBijoyMultiNumb = this.onChangeBijoyMultiNumb.bind(this);
    this.onChangeSalesPrice = this.onChangeSalesPrice.bind(this);
    this.onChangeCostPercent = this.onChangeCostPercent.bind(this);
    this.onChangeFixedPercent = this.onChangeFixedPercent.bind(this);

    this.onChangeLevelPercent = this.onChangeLevelPercent.bind(this);
    this.onChangeTaxPercent = this.onChangeTaxPercent.bind(this);
    this.onChangeVatPercent = this.onChangeVatPercent.bind(this);
    this.onChangeOwnerNetiId = this.onChangeOwnerNetiId.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.submitSave = this.submitSave.bind(this);

    this.fetchNewProductList = this.fetchNewProductList.bind(this);

    this.viewDialog = this.viewDialog.bind(this);
    this.updateDialog = this.updateDialog.bind(this);
    this.stockUpdateDialog = this.stockUpdateDialog.bind(this);
    this.onHideViewDialog = this.onHideViewDialog.bind(this);
    this.onHideUpdateDialog = this.onHideUpdateDialog.bind(this);
    this.onHideStockDialog = this.onHideStockDialog.bind(this);

    this.userCategoryService = new UserCategoryService();
    this.profileService = new ProfileService();
    this.adminPointService = new AdminPointService();
    this.NetiContentLoader = new NetiContentLoader();
    this.CommonFuctionality = new CommonFuctionality();
    this.ValidatorUtility = new ValidatorUtility();
  }

  componentWillMount() {
    this.fetchProductTypeList("T104");
    this.fetchNewProductList();
    cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
  }

  getProductOwnerRevenueInfo = (param) => {
    this.setState({ topProgressBar: true, errorMsgVisible: false });
    this.adminPointService
      .getPurchaseAtAGlanceByproductID(param)
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            this.setState({
              productOwnerRevenueInfo: body,
              topProgressBar: false,
            });
          });
        } else {
          this.adminPointService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  };

  onChangeProductType(value) {
    errors["productType"] = "";
    this.setState({
      productType: value,
      errors: errors,
    });
  }

  onChangeProductName(value) {
    errors["productName"] = "";
    this.setState({
      productName: value,
      errors: errors,
    });
  }

  onChangeDefaultCode(value) {
    errors["defaultCode"] = "";
    this.setState({
      defaultCode: value,
      errors: errors,
    });
  }

  onChangeProductDetails(value) {
    errors["productDetails"] = "";
    this.setState({
      productDetails: value,
      errors: errors,
    });
  }

  onChangeRemainingStock(value) {
    errors["remainingStock"] = "";
    this.setState({
      remainingStock: value,
      errors: errors,
    });
  }

  onChangeGenerateCode(value) {
    errors["generateCode"] = "";
    this.setState({
      generateCode: value,
      errors: errors,
    });
  }

  onChangeBijoySellNumb(value) {
    errors["bijoySellNumb"] = "";
    this.setState({
      bijoySellNumb: value,
      errors: errors,
    });
  }

  onChangeBijoyMultiNumb(value) {
    errors["bijoyMultiNumb"] = "";
    this.setState({
      bijoyMultiNumb: value,
      errors: errors,
    });
  }

  onChangeSalesPrice(value) {
    errors["salesPrice"] = "";
    this.setState({
      salesPrice: value,
      errors: errors,
    });
  }

  onChangeCostPercent(value) {
    errors["costPercent"] = "";
    value =
      +value + +this.state.fixedPercent + +this.state.levelPercent <= hundred
        ? value
        : "";
    this.setState({
      costPercent: value,
      errors: errors,
    });
  }

  onChangeFixedPercent(value) {
    errors["fixedPercent"] = "";

    value =
      +this.state.costPercent + +value + +this.state.levelPercent <= hundred
        ? value
        : "";

    this.setState({
      fixedPercent: value,
      errors: errors,
    });
  }

  onChangeLevelPercent(value) {
    errors["levelPercent"] = "";
    value =
      +this.state.costPercent + +this.state.fixedPercent + +value <= hundred
        ? value
        : "";
    this.setState({
      levelPercent: value,
      errors: errors,
    });
  }

  onChangeTaxPercent(value) {
    errors["taxPercent"] = "";
    this.setState({
      taxPercent: value,
      errors: errors,
    });
  }

  onChangeVatPercent(value) {
    errors["vatPercent"] = "";
    this.setState({
      vatPercent: value,
      errors: errors,
    });
  }

  onChangeOwnerNetiId(value) {
    errors["ownerNetiId"] = "";
    errors["EmptySearch"] = "";
    this.setState({
      ownerNetiId: value,
      errors: errors,
    });
  }

  submitSearch() {
    if (this.state.ownerNetiId !== "") {
      errors["Error"] = "";
      errors["EmptySearch"] = "";
      this.setState({
        topProgressBar: true,
        searchIsLoading: true,
        errorMsgVisible: false,
        searchView: false,
        searchViewError: false,
        netiIDViewError: false,
      });
      this.profileService
        .findBasicInfoByCustomNetiID(this.state.ownerNetiId)
        .then((res) => {
          if (res.status == 302) {
            errors["Error"] = "";
            errors["EmptySearch"] = "";
            return res.json().then((body) => {
              this.setState({
                userDetails: body,
                topProgressBar: false,
                searchIsLoading: false,
                searchView: true,
                errorMsgVisible: false,
                netiIDViewError: false,
                searchViewError: false,
                buttonDisabled: false,
              });
            });
          } else {
            this.profileService.Auth.handleErrorStatus(res).then((resp) => {
              console.log("search resp", resp);
              // errors["Error"] = resp;
              this.setState({
                searchViewError: false,
                searchView: false,
                errors,
                topProgressBar: false,
                searchIsLoading: false,
                netiIDErrorBody: "No ID Found",
                netiIDViewError: true,
                buttonDisabled: true,
              });
            });
          }
        })
        .catch((error) =>
          this.setState({
            searchViewError: false,
            netiIDViewError: false,
            searchView: false,
            topProgressBar: false,
            searchIsLoading: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection !!!",
          })
        );
    } else {
      errors["EmptySearch"] = "Neti ID can't left empty.";
      this.setState({
        errors,
        searchView: false,
        searchViewError: false,
        buttonDisabled: true,
        netiIDViewError: false,
      });
    }
  }

  submitSave() {
    let requestedObj = {};
    let generateCode;
    if (this.handleError()) {
      this.setState({
        topProgressBar: true,
        dataTableIsLoading: true,
        errorMsgVisible: false,
      });

      {
        this.state.generateCode ? (generateCode = 1) : (generateCode = 0);
      }

      if (this.state.additionalInfo1 == true) {
        requestedObj = {
          productDefaultCode: this.state.defaultCode,
          productName: this.state.productName,
          productNote: this.state.productDetails,
          productAdditionalStatus: 1,
          remainingStock: this.state.remainingStock,
          salesPrice: this.state.salesPrice,
          distributePercentCost: this.state.costPercent,
          distributePercentFixed: this.state.fixedPercent,
          distributePercentLevel: this.state.levelPercent,
          percentTax: this.state.taxPercent,
          percentVat: this.state.vatPercent,
          genCodeStatus: generateCode,
          userBasicInfoDTO: {
            netiID: this.state.userDetails.netiID,
          },
          productTypeInfoDTO: {
            coreCategoryID: this.state.productType,
          },
          additionalInfoDTO: {
            bijoySaltNumber: this.state.bijoySellNumb,
            bijoyMultiNumber: this.state.bijoyMultiNumb,
          },
        };
      } else {
        requestedObj = {
          productDefaultCode: this.state.defaultCode,
          productName: this.state.productName,
          productNote: this.state.productDetails,
          productAdditionalStatus: 0,
          remainingStock: this.state.remainingStock,
          salesPrice: this.state.salesPrice,
          distributePercentCost: this.state.costPercent,
          distributePercentFixed: this.state.fixedPercent,
          distributePercentLevel: this.state.levelPercent,
          percentTax: this.state.taxPercent,
          percentVat: this.state.vatPercent,
          genCodeStatus: generateCode,
          userBasicInfoDTO: {
            netiID: this.state.userDetails.netiID,
          },
          productTypeInfoDTO: {
            coreCategoryID: this.state.productType,
          },
        };
      }

      this.adminPointService
        .createNewProduct(requestedObj)
        .then((res) => {
          if (res.status == 201) {
            this.growl.show({
              severity: "success",
              summary: "Success",
              detail: "Data saved successfully.",
            });
            this.setState({ requestedObj: cloneStateBeforeMount.requestedObj });
            this.setState({ userDetails: cloneStateBeforeMount.userDetails });
            this.setState({
              ownerNetiId: "",
              netiIDViewError: false,
              searchView: false,
              defaultCode: "",
              bijoySellNumb: "",
              bijoyMultiNumb: "",
              productType: "",
              taxPercent: "",
              vatPercent: "",
              generateCode: "",
              productName: "",
              productDetails: "",
              remainingStock: "",
              salesPrice: "",
              costPercent: "",
              fixedPercent: "",
              levelPercent: "",
            });
            this.fetchNewProductList();
            this.setState({ topProgressBar: false, dataTableIsLoading: false });
          } else {
            this.adminPointService.Auth.handleErrorStatus(res).then((resp) => {
              this.setState({
                topProgressBar: false,
                dataTableIsLoading: false,
                errorMsgVisible: true,
                errorMsgBody: resp,
              });
            });
          }
        })
        .catch((error) => {
          this.setState({
            errorMsgVisible: true,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: "Please check your connection !!!",
          });
        });
    }
  }

  viewDialog(rowData) {
    this.setState({ selectedRowData: rowData, viewDialogShow: true });
    this.CommonFuctionality.blurDialogBackgroundActive();
    this.getProductOwnerRevenueInfo(rowData.productID);
  }

  updateDialog(rowData) {
    let { selectedRowData } = this.state;
    if (rowData.genCodeStatus == "Yes") {
      rowData.genCodeStatus = 1;
    } else if (rowData.genCodeStatus == "No") {
      rowData.genCodeStatus = 0;
    }

    this.setState({ selectedRowData: rowData });
    this.setState({ updateDialogShow: true });
    this.CommonFuctionality.blurDialogBackgroundActive();
  }

  stockUpdateDialog(rowData) {
    this.setState({ selectedRowData: rowData });
    this.setState({ stockDialogShow: true });
    this.CommonFuctionality.blurDialogBackgroundActive();
  }

  onHideViewDialog() {
    this.setState({ viewDialogShow: false });
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  }

  onHideUpdateDialog() {
    this.setState({ updateDialogShow: false });
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  }

  onHideStockDialog() {
    this.setState({ stockDialogShow: false });
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  }

  fetchProductTypeList(defaultCode) {
    this.setState({
      topProgressBar: true,
      firstDropdownIsLoading: true,
      errorMsgVisible: false,
    });
    this.userCategoryService
      .fetchListByDefaultCode(defaultCode)
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            this.setState({
              productTypeList: body,
              topProgressBar: false,
              firstDropdownIsLoading: false,
            });
          });
        } else {
          this.userCategoryService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              topProgressBar: false,
              firstDropdownIsLoading: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) =>
        this.setState({
          topProgressBar: false,
          firstDropdownIsLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection !!!",
        })
      );
  }

  fetchNewProductList() {
    this.setState({
      topProgressBar: true,
      dataTableIsLoading: true,
      errorMsgVisible: false,
    });
    this.adminPointService
      .fetchNewProductList()
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            for (let i = 0; i < body.length; i++) {
              if (body[i].genCodeStatus == 1) {
                body[i].genCodeStatus = "Yes";
              } else if (body[i].genCodeStatus == 0) {
                body[i].genCodeStatus = "No";
              }
            }
            this.setState({
              dataTableValue: body,
              topProgressBar: false,
              dataTableIsLoading: false,
            });
          });
        } else {
          this.adminPointService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              topProgressBar: false,
              dataTableIsLoading: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          dataTableIsLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  }

  handleError() {
    let { errors } = this.state;
    let formIsValid = true;

    if (this.state.productType === "") {
      formIsValid = false;
      errors["productType"] = "Product Type can't left empty.";
    }
    if (this.state.productName === "") {
      formIsValid = false;
      errors["productName"] = "Product Name can't left empty.";
    }
    if (this.state.defaultCode === "") {
      formIsValid = false;
      errors["defaultCode"] = "Default Code can't left empty.";
    }
    if (this.state.productDetails === "") {
      formIsValid = false;
      errors["productDetails"] = "Product Details can't left empty.";
    }
    if (this.state.remainingStock === "") {
      formIsValid = false;
      errors["remainingStock"] = "Remaining Stock can't left empty.";
    }
    if (this.state.generateCode === "") {
      formIsValid = false;
      errors["generateCode"] = "Generate Code can't left empty.";
    }
    if (this.state.additionalInfo1 == true) {
      if (this.state.bijoySellNumb === "") {
        formIsValid = false;
        errors["bijoySellNumb"] = "Bijoy Sell Number can't left empty.";
      }
      if (this.state.bijoyMultiNumb === "") {
        formIsValid = false;
        errors["bijoyMultiNumb"] = "Bijoy Multi Number can't left empty.";
      }
    }

    if (this.state.salesPrice === "") {
      formIsValid = false;
      errors["salesPrice"] = "Sales Price can't left empty.";
    }
    if (this.state.costPercent === "") {
      formIsValid = false;
      errors["costPercent"] = "Cost Percent can't left empty.";
    }
    if (this.state.fixedPercent === "") {
      formIsValid = false;
      errors["fixedPercent"] = "Fixed Percent can't left empty.";
    }
    if (this.state.levelPercent === "") {
      formIsValid = false;
      errors["levelPercent"] = "Level Percent can't left empty.";
    }
    if (this.state.taxPercent === "") {
      formIsValid = false;
      errors["taxPercent"] = "Income Tax Percent can't left empty.";
    }
    if (this.state.vatPercent === "") {
      formIsValid = false;
      errors["vatPercent"] = "Vat Percent can't left empty.";
    }
    // if (this.state.ownerNetiId === "") {
    //     formIsValid = false;
    //     errors["ownerNetiId"] = "Owner Neti ID can't left empty.";
    // }
    else {
      return formIsValid;
    }
    this.setState({ errors });
  }

  focusSearch = (e) => {
    this.setState({
      searchLabel: "Search",
      addSearchBtnClass: "nw-button p-button-primary text-color-white",
    });
  };

  blurSearch = (e) => {
    this.setState({ searchLabel: "", addSearchBtnClass: "" });
  };

  productInfoBody = (rowData) => {
    return (
      <div className="nw-datatable-dataview-small-panel productInfo">
        <div>
          <div>Name</div>
          <div>:</div>
          <div> {rowData.productName}</div>
        </div>

        <div>
          <div>Type</div>
          <div>:</div>
          <div> {rowData.productTypeInfoDTO.categoryName}</div>
        </div>

        <div>
          <div>Status</div>
          <div>:</div>
          <div className="badge">
            {rowData.productTypeInfoDTO.categoryEnableStatus === 1 ? (
              <div>
                <span className="task-badge online"> </span> Enable
              </div>
            ) : (
              <div>
                <span className="task-badge offline"> </span> Disable
              </div>
            )}
          </div>
        </div>

        <div>
          <div>In Stoke</div>
          <div>:</div>
          <div> {rowData.remainingStock}</div>
        </div>
      </div>
    );
  };

  codeInfoBody = (rowData) => {
    return (
      <div className="nw-datatable-dataview-small-panel">
        <div>
          <div>Default Code</div>
          <div>:</div>
          <div> {rowData.productDefaultCode}</div>
        </div>

        <div>
          <div>Generate Code</div>
          <div>:</div>
          <div> {rowData.genCodeStatus}</div>
        </div>
      </div>
    );
  };

  ownerInfoBody = (rowData) => {
    return (
      <div className="nw-datatable-dataview-small-panel ownerInfo">
        <div>
          <div>Name</div>
          <div>:</div>
          <div> {rowData.userBasicInfoDTO.fullName}</div>
        </div>

        <div>
          <div>Neti ID.</div>
          <div>:</div>
          <div> {rowData.userBasicInfoDTO.customNetiID}</div>
        </div>
      </div>
    );
  };

  priceInfoBody = (rowData) => {
    return (
      <div className="nw-datatable-dataview-small-panel priceInfo">
        <h2>
          <span className="symbol-taka">৳</span>{" "}
          {this.ValidatorUtility.currencyFormatter(rowData.salesPrice)}
        </h2>

        <div>
          <div>Vat-Tax (%)</div>
          <div>:</div>
          <div>
            {" "}
            {this.ValidatorUtility.currencyFormatter(rowData.percentVat)} -{" "}
            {this.ValidatorUtility.currencyFormatter(rowData.percentTax)}
          </div>
        </div>

        <div>
          <div>Cost-Fixed-Level (%)</div>
          <div>:</div>
          <div>
            {" "}
            {this.ValidatorUtility.currencyFormatter(
              rowData.distributePercentCost
            )}{" "}
            -{" "}
            {this.ValidatorUtility.currencyFormatter(
              rowData.distributePercentFixed
            )}{" "}
            -{" "}
            {this.ValidatorUtility.currencyFormatter(
              rowData.distributePercentLevel
            )}
          </div>
        </div>

        {/* <div>
                    <div>{rowData.distributePercentCost}</div>
                    <div>{rowData.distributePercentFixed}</div>
                    <div>{rowData.distributePercentLevel}</div>
                </div> */}
      </div>
    );
  };

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    // let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Product List<span style={{ 'float': 'right' }}> Total Found:{this.state.dataTableValue.length} </span></div>;

    let tableHeader = (
      <div className="header-title">
        <div className="header-title-left">Product List</div>
        <div className="header-title-right">
          <Link to="#">Total Found: {this.state.dataTableValue.length}</Link>
        </div>
      </div>
    );

    let detailsTableHeader = (
      <div className="header-title">
        <div className="header-title-left">Product List</div>

        <div className="header-title-right">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="fas fa-search"></i>
            </span>
            <InputText
              type="search"
              onInput={(e) => this.setState({ globalFilter: e.target.value })}
              placeholder="Search By Product, Code. etc"
              size="50"
            />
          </div>

          <a className="total-found">
            Total Found: {this.state.dataTableValue.length}
          </a>
        </div>
      </div>
    );

    let productTypeOptions = [];
    if (this.state.productTypeList && this.state.productTypeList.length) {
      productTypeOptions = this.state.productTypeList.map((item) => ({
        value: item.coreCategoryID,
        label: item.categoryName,
      }));
    }

    let costLevelFixedBody = (rowData) => {
      return (
        <span>
          {rowData.distributePercentCost} - {rowData.distributePercentFixed} -{" "}
          {rowData.distributePercentLevel}
        </span>
      );
    };

    let vatTaxBody = (rowData) => {
      return (
        <span>
          {rowData.percentVat} - {rowData.percentTax}
        </span>
      );
    };

    let editBody = (rowData) => {
      return (
        <div className="text-center">
          <Button
            className="nw-action-button"
            icon="far fa-eye"
            title="View"
            onClick={(e) => this.viewDialog(rowData)}
            tooltip="View"
            tooltipOptions={{ position: "top" }}
          />
          <Button
            className="nw-action-button"
            icon="fas fa-pencil-alt"
            title="Edit"
            onClick={(e) => this.updateDialog(rowData)}
            tooltip="Edit"
            tooltipOptions={{ position: "top" }}
          />
          <Button
            className="nw-action-button"
            icon="fas fa-box-open"
            title="Stock Update"
            onClick={(e) => this.stockUpdateDialog(rowData)}
            tooltip="Stock Update"
            tooltipOptions={{ position: "top" }}
          />
        </div>
      );
    };

    let { selectedRowData } = this.state;

    let footerForDialogueFixedRevenue = (
      <ColumnGroup>
        <Row rowSpan={2}>
          <Column footer="" colSpan={2} rowSpan={2} />
          <Column footer="100%" rowSpan={2} style={{ textAlign: "left" }} />
          <Column
            footer={
              <div>
                <span style={{ fontSize: "14px", opacity: "0.8" }}>Total:</span>
                <br />
                <span style={{ color: "#FF8800" }}>20,00.00/-</span>
              </div>
            }
            rowSpan={2}
            className="nw-tfoot-total-text"
            style={{ textAlign: "left" }}
          />
        </Row>
      </ColumnGroup>
    );

    let footerForDialogueLevelRevenue = (
      <ColumnGroup>
        <Row>
          <Column footer="" colSpan={2} rowSpan={2} />
          <Column footer="100%" rowSpan={2} style={{ textAlign: "left" }} />
          <Column
            footer={
              <div>
                <span style={{ fontSize: "14px", opacity: "0.8" }}>Total:</span>
                <br />
                <span style={{ color: "#00AFD4" }}>20,00.00/-</span>
              </div>
            }
            rowSpan={2}
            className="nw-tfoot-total-text"
            style={{ textAlign: "left" }}
          />
        </Row>
      </ColumnGroup>
    );

    let dialogRevenueHeader1 = (
      <div
        className="p-clearfix p-inputgroup"
        style={{ lineHeight: "1.87em", display: "block" }}
      >
        Fixed Revenue List
        <span>
          <p className="p-inputgroup-info-value-color2">20%</p> of sales Price =
          <p className="p-inputgroup-info-value-color2"> 2000/-</p>
        </span>
      </div>
    );

    // <div>Fixed Revenue List  <span style={{ float: 'right', fontSize: '1em', color: '#004BA0' }}>20% of sales Price = 2000/-</span></div>;
    let dialogRevenueHeader2 = (
      <div
        className="p-clearfix p-inputgroup"
        style={{ lineHeight: "1.87em", display: "block" }}
      >
        Level Revenue List
        <span>
          <p className="p-inputgroup-info-value-color3">20%</p> of sales Price =
          <p className="p-inputgroup-info-value-color3"> 2000/-</p>
        </span>
      </div>
    );

    let vatAmountCal = (this.state.salesPrice * this.state.vatPercent) / 100;
    let revenueParcentCal =
      +this.state.costPercent +
      +this.state.fixedPercent +
      +this.state.levelPercent;
    let totalRevenueCal =
      (this.state.salesPrice * this.state.costPercent) / 100 +
      (this.state.salesPrice * this.state.fixedPercent) / 100 +
      (this.state.salesPrice * this.state.levelPercent) / 100;
    let fixedPercentCal =
      (this.state.salesPrice * this.state.fixedPercent) / 100;
    let levelPercentCal =
      (this.state.salesPrice * this.state.levelPercent) / 100;
    let costPercentCal = (this.state.salesPrice * this.state.costPercent) / 100;
    let incomeTaxPercentCal =
      (+(fixedPercentCal + levelPercentCal) * this.state.taxPercent) / 100;

    return (
      <div className="p-fluid admin-point">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <div className="main-section blur-section">
          <div className="p-grid">
            <Growl ref={(el) => (this.growl = el)} />
            <div className="p-col-12 p-xl-12 nw-form">
              <div className="p-col-12 p-xl-12">
                <div className="p-grid nw-form-body">
                  <div className="p-col-12 p-xl-12">
                    <div className="p-col-12 p-xl-12">
                      {this.state.firstDropdownIsLoading ? (
                        this.NetiContentLoader.normalFormInputField()
                      ) : (
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Product Type <span>*</span>
                          </span>
                          <Dropdown
                            placeholder="Select Product Type"
                            name="productType"
                            options={productTypeOptions}
                            value={this.state.productType}
                            onChange={(e) => this.onChangeProductType(e.value)}
                            filter={true}
                            autoWidth={false}
                          />
                        </div>
                      )}
                      <span className="error-message">
                        {this.state.errors["productType"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Product Name <span>*</span>
                        </span>
                        <InputText
                          placeholder="Enter Product Name"
                          name="productName"
                          value={this.state.productName}
                          onChange={(e) =>
                            this.onChangeProductName(e.target.value)
                          }
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["productName"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Default Code <span>*</span>
                        </span>
                        <InputText
                          placeholder="Enter Default Code"
                          name="defaultCode"
                          value={this.state.defaultCode}
                          onChange={(e) =>
                            this.onChangeDefaultCode(e.target.value)
                          }
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["defaultCode"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                          Product Details <span>*</span>
                        </span>
                        <InputTextarea
                          placeholder="Enter Product Details"
                          name="productDetails"
                          value={this.state.productDetails}
                          onChange={(e) =>
                            this.onChangeProductDetails(e.target.value)
                          }
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["productDetails"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Remaining Stock <span>*</span>
                        </span>
                        <InputText
                          placeholder="Enter Remaining Stock"
                          name="remainingStock"
                          value={this.state.remainingStock}
                          onChange={(e) =>
                            this.onChangeRemainingStock(e.target.value)
                          }
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["remainingStock"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <div className="input-radio-button">
                          <span className="p-inputgroup-addon addon-no-style">
                            Generate Code <span>*</span>
                          </span>
                          <div className="radio-button-inside">
                            <RadioButton
                              value="Yes"
                              inputId="rb1"
                              onChange={(event) =>
                                this.onChangeGenerateCode(event.value)
                              }
                              checked={this.state.generateCode === "Yes"}
                            />
                            <label
                              htmlFor="rb1"
                              className="p-radiobutton-label"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="radio-button-inside">
                            <RadioButton
                              value="No"
                              inputId="rb2"
                              onChange={(event) =>
                                this.onChangeGenerateCode(event.value)
                              }
                              checked={this.state.generateCode === "No"}
                            />
                            <label
                              htmlFor="rb2"
                              className="p-radiobutton-label"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <span className="error-message">
                        {this.state.errors["generateCode"]}
                      </span>
                    </div>

                    <div className="p-col-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon addon-no-style">
                          Additional Info<span></span>
                        </span>

                        <div className="nw-inputgroup-desc desc-no-style custom-inputswitch">
                          <span className="p-radiobutton-label">No</span>
                          <InputSwitch
                            checked={this.state.additionalInfo1}
                            onChange={(e) =>
                              this.setState({
                                additionalInfo1: e.value,
                                togleBtnActive: !this.state.togleBtnActive,
                              })
                            }
                          />
                          {this.state.togleBtnActive === true ? (
                            <span
                              className="p-radiobutton-label"
                              style={{ color: "#0099FF" }}
                            >
                              Yes
                            </span>
                          ) : (
                            <span className="p-radiobutton-label">Yes</span>
                          )}
                        </div>
                      </div>
                      <span className="error-message"></span>
                    </div>

                    {this.state.additionalInfo1 ? (
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Bijoy Sell Number
                          </span>
                          <InputText
                            placeholder="Enter Bijoy Sell Number"
                            name="bijoySellNumb"
                            value={this.state.bijoySellNumb}
                            onChange={(e) =>
                              this.onChangeBijoySellNumb(e.target.value)
                            }
                          />
                        </div>
                        <span className="error-message">
                          {this.state.errors["bijoySellNumb"]}
                        </span>
                        <br />

                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Bijoy Multi Number
                          </span>
                          <InputText
                            placeholder="Enter Bijoy Multi Number"
                            name="bijoyMultiNumb"
                            value={this.state.bijoyMultiNumb}
                            onChange={(e) =>
                              this.onChangeBijoyMultiNumb(e.target.value)
                            }
                          />
                        </div>
                        <span className="error-message">
                          {this.state.errors["bijoyMultiNumb"]}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="p-col-12 p-col-nogutter">
                    <div className="p-col-12">
                      <div class="from-group-heading">
                        <span>Price Configuration</span>
                      </div>
                    </div>
                    <div className="p-col-12">
                      <div className="calculation-box-wrapper">
                        <div className="p-md-3 p-sm-12">
                          <div className="calculation-box plusSign">
                            <div className="calculation-box-title">
                              <span class="p-inputgroup-addon">
                                Sales Price <span>*</span>
                              </span>
                            </div>
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-amount">
                                {this.validatorUtility.currencyFormatter(
                                  this.state.salesPrice
                                )}
                              </div>
                              <div className="calculation-box-field inputTkSign">
                                <span className="input-label">
                                  enter sales price
                                </span>
                                <InputText
                                  name="salesPrice"
                                  value={this.state.salesPrice}
                                  onChange={(e) =>
                                    this.onChangeSalesPrice(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <span className="error-message">
                            {this.state.errors["salesPrice"]}
                          </span>
                        </div>
                        <div className="p-md-3 p-sm-12">
                          <div className="calculation-box">
                            <div className="calculation-box-title">
                              <span class="p-inputgroup-addon">
                                Vat Amount <span>*</span>
                              </span>
                            </div>
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-amount">
                                {this.validatorUtility.currencyFormatter(
                                  vatAmountCal
                                )}
                              </div>
                              <div className="calculation-box-field percentSign">
                                <span className="input-label">
                                  enter vat percent
                                </span>
                                <InputText
                                  name="vatPercent"
                                  value={this.state.vatPercent}
                                  onChange={(e) =>
                                    this.onChangeVatPercent(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <span className="error-message">
                            {this.state.errors["vatPercent"]}
                          </span>
                        </div>
                        <div className="p-md-3 p-sm-12">
                          <div className="calculation-total-box">
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-title">
                                <span class="p-inputgroup-addon">
                                  Total Price Including VAT
                                </span>
                              </div>
                              <div className="calculation-box-amount">
                                {this.validatorUtility.currencyFormatter(
                                  +this.state.salesPrice + vatAmountCal
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-col-nogutter">
                    <div className="p-col-12">
                      <div class="from-group-heading">
                        <span>Revenue Configuration</span>
                      </div>
                    </div>
                    <div className="p-col-12">
                      <div className="calculation-box-wrapper">
                        <div className="p-md-3 p-sm-12">
                          <div className="calculation-box plusSign">
                            <div className="calculation-box-title">
                              <span class="p-inputgroup-addon">
                                Cost Amount <span>*</span>
                              </span>
                            </div>
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-amount">
                                {this.validatorUtility.currencyFormatter(
                                  costPercentCal
                                )}
                              </div>
                              <div className="calculation-box-field percentSign">
                                <span className="input-label">
                                  enter cost percent
                                </span>
                                <InputText
                                  name="costPercent"
                                  value={this.state.costPercent}
                                  onChange={(e) =>
                                    this.onChangeCostPercent(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <span className="error-message">
                            {this.state.errors["costPercent"]}
                          </span>
                        </div>
                        <div className="p-md-3 p-sm-12">
                          <div className="calculation-box plusSign">
                            <div className="calculation-box-title">
                              <span class="p-inputgroup-addon">
                                Fixed Amount <span>*</span>
                              </span>
                            </div>
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-amount">
                                {this.validatorUtility.currencyFormatter(
                                  fixedPercentCal
                                )}
                              </div>
                              <div className="calculation-box-field percentSign">
                                <span className="input-label">
                                  enter fixed percent
                                </span>
                                <InputText
                                  name="fixedPercent"
                                  value={this.state.fixedPercent}
                                  onChange={(e) =>
                                    this.onChangeFixedPercent(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <span className="error-message">
                            {this.state.errors["fixedPercent"]}
                          </span>
                        </div>
                        <div className="p-md-3 p-sm-12">
                          <div className="calculation-box">
                            <div className="calculation-box-title">
                              <span class="p-inputgroup-addon">
                                Level Amount <span>*</span>
                              </span>
                            </div>
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-amount">
                                {this.validatorUtility.currencyFormatter(
                                  levelPercentCal
                                )}
                              </div>
                              <div className="calculation-box-field percentSign">
                                <span className="input-label">
                                  enter level percent
                                </span>
                                <InputText
                                  name="levelPercent"
                                  value={this.state.levelPercent}
                                  onChange={(e) =>
                                    this.onChangeLevelPercent(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <span className="error-message">
                            {this.state.errors["levelPercent"]}
                          </span>
                        </div>
                        <div className="p-col-12 p-md-3">
                          <div className="calculation-total-box">
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-title">
                                <span class="p-inputgroup-addon">
                                  Revenue Percent
                                </span>
                              </div>
                              <div className="calculation-box-amount no-tkSign">
                                {revenueParcentCal}
                              </div>
                              <div className="top-position">
                                <div className="calculation-box-amount">
                                  {this.validatorUtility.currencyFormatter(
                                    totalRevenueCal
                                  )}
                                </div>
                              </div>
                              <div className="left-percent">
                                <span>
                                  {" "}
                                  {hundred - revenueParcentCal === 0 ? (
                                    <span className="text-success">
                                      Completed
                                    </span>
                                  ) : (
                                    `${hundred - revenueParcentCal}% Left`
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-grid p-col-12 p-xl-12">
                      <div className="p-col-12 p-xl-6">
                        <div class="from-group-heading m-b-20">
                          <span>Income Tax</span>
                        </div>
                        <div className="calculation-box-wrapper">
                          <div className="calculation-box ">
                            <div className="calculation-box-title">
                              <span class="p-inputgroup-addon">
                                Income Tax Percent <span>*</span>
                              </span>
                            </div>
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-amount">
                                {this.validatorUtility.currencyFormatter(
                                  incomeTaxPercentCal
                                )}
                              </div>
                              <div className="calculation-box-field full-width percentSign">
                                <span className="input-label">
                                  enter income tax percent
                                </span>
                                <InputText
                                  name="taxPercent"
                                  value={this.state.taxPercent}
                                  onChange={(e) =>
                                    this.onChangeTaxPercent(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <span className="error-message">
                          {this.state.errors["taxPercent"]}
                        </span>
                      </div>
                      <div className="p-col-12 p-xl-6">
                        <div class="from-group-heading m-b-20">
                          <span>Owner's Neti ID</span>
                        </div>
                        <div className="calculation-box-wrapper">
                          <div className="calculation-box">
                            <div className="calculation-box-title">
                              <span class="p-inputgroup-addon">
                                Neti ID Validation Check <span>*</span>
                              </span>
                            </div>
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-amount">
                                {this.validatorUtility.currencyFormatter(
                                  costPercentCal
                                )}
                              </div>
                              <div className="calculation-box-field idSearchField">
                                <span className="input-label">
                                  type owner's NETI ID (e.g. 1000000001)
                                </span>
                                <InputText
                                  name="ownerNetiId"
                                  value={this.state.ownerNetiId}
                                  onChange={(e) =>
                                    this.onChangeOwnerNetiId(e.target.value)
                                  }
                                />
                                <Button
                                  label="Check Availability"
                                  onClick={(e) =>
                                    this.submitSearch(e.target.value)
                                  }
                                  onFocus={this.focusSearch}
                                  onBlur={this.blurSearch}
                                />

                                {this.state.netiIDViewError ? (
                                  <div className="error-tooltips-wrapper">
                                    <div className="error-tooltips">
                                      <i class="fas fa-exclamation-triangle"></i>
                                      <span>{this.state.netiIDErrorBody}</span>
                                      <span
                                        className="msgClose"
                                        onClick={(e) =>
                                          this.setState({
                                            netiIDViewError: false,
                                          })
                                        }
                                      ></span>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {/* <div className="error-tooltips-wrapper">

                                                                {/* <div className="error-tooltips-wrapper">
                                                                <div className="error-tooltips">
                                                                    <i class="fas fa-exclamation"></i>
                                                                    <span>Invalid ID</span>
                                                                </div>
                                                            </div> */}
                                {this.state.searchView ? (
                                  <div className="info-tooltips-wrapper">
                                    <div className="info-tooltips">
                                      <span className="info-tooltips-title">
                                        Neti ID Found
                                      </span>
                                      <span
                                        className="msgClose"
                                        onClick={(e) =>
                                          this.setState({ searchView: false })
                                        }
                                      ></span>

                                      <div className="p-col-12 p-xl-12">
                                        <div className="nw-search-view">
                                          {/* {this.state.searchViewError ? */}
                                          {/* <center className="error-message">{this.state.errors["Error"] || "No Data Found"}</center> : */}
                                          <div>
                                            <div className="p-col-12 p-xl-12">
                                              {this.state.searchIsLoading ? (
                                                this.NetiContentLoader.normalFormInputField()
                                              ) : (
                                                <div className="p-inputgroup">
                                                  <span className="task-badge found"></span>
                                                  <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Name
                                                  </span>
                                                  <span className="p-inputgroup-colon">
                                                    :
                                                  </span>
                                                  <div className="nw-inputgroup-desc">
                                                    {
                                                      this.state.userDetails
                                                        .fullName
                                                    }
                                                  </div>
                                                </div>
                                              )}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                              {this.state.searchIsLoading ? (
                                                this.NetiContentLoader.normalFormInputField()
                                              ) : (
                                                <div className="p-inputgroup">
                                                  <span className="task-badge found"></span>
                                                  <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Mobile No
                                                  </span>
                                                  <span className="p-inputgroup-colon">
                                                    :
                                                  </span>
                                                  <div className="nw-inputgroup-desc">
                                                    {
                                                      this.state.userDetails
                                                        .basicMobile
                                                    }
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <span className="error-message">
                              {this.state.errors["EmptySearch"]}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="p-col-12 p-xl-6">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Sales Price <span>*</span></span>
                                            <InputText
                                                placeholder='Enter Sales Price'
                                                name="salesPrice"
                                                value={this.state.salesPrice}
                                                onChange={(e) => this.onChangeSalesPrice(e.target.value)}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["salesPrice"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Cost Percent (%) <span>*</span></span>
                                            <InputText
                                                placeholder='Enter Cost Percent (%)'
                                                name="costPercent"
                                                value={this.state.costPercent}
                                                onChange={(e) => this.onChangeCostPercent(e.target.value)}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["costPercent"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Fixed Percent (%) <span>*</span></span>
                                            <InputText
                                                placeholder='Enter Fixed Percent'
                                                name="fixedPercent"
                                                value={this.state.fixedPercent}
                                                onChange={(e) => this.onChangeFixedPercent(e.target.value)}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["fixedPercent"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Level Percent (%) <span>*</span></span>

                                            <InputText
                                                placeholder='Enter Level Percent (%)'
                                                name="levelPercent"
                                                value={this.state.levelPercent}
                                                onChange={(e) => this.onChangeLevelPercent(e.target.value)}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["levelPercent"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Tax Percent (%) <span>*</span></span>
                                            <InputText
                                                placeholder='Enter Tax Percent (%)'
                                                name="taxPercent"
                                                value={this.state.taxPercent}
                                                onChange={(e) => this.onChangeTaxPercent(e.target.value)}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["taxPercent"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Vat Percent (%) <span>*</span></span>
                                            <InputText
                                                placeholder='Enter Vat Percent (%)'
                                                name="vatPercent"
                                                value={this.state.vatPercent}
                                                onChange={(e) => this.onChangeVatPercent(e.target.value)}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["vatPercent"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">

                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Owner Neti ID <span>*</span></span>
                                            <InputText
                                                placeholder='Enter Owner Neti ID'
                                                name="ownerNetiId"
                                                value={this.state.ownerNetiId}
                                                style={{ width: '15%' }}
                                                onChange={(e) => this.onChangeOwnerNetiId(e.target.value)}
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                label={this.state.searchLabel}
                                                icon="fas fa-search"
                                                onClick={(e) => this.submitSearch(e.target.value)}
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />

                                        </div>
                                        <span className='error-message'>{this.state.errors["EmptySearch"]}</span>
                                    </div>


                                    {this.state.searchView ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-search-view">
                                                {this.state.searchViewError ?
                                                    <center className="error-message">{this.state.errors["Error"] || "No Data Found"}</center> :
                                                    <div>
                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.searchIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :

                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                        Name
                                                                    </span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.userDetails.fullName}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.searchIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :

                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                        Mobile No
                                                                    </span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.userDetails.basicMobile}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        : ''
                                    }

                                </div>
                                <div className="p-col-12 p-xl-12">

                                </div> */}

                  <div className="p-col-12 p-xl-12 nw-button-parent">
                    {/* <div className="required-field">
                                        (<span>*</span>) required fields
                                    </div> */}

                    <Button
                      className="p-button p-button-primary nw-button nw-button-right"
                      label="Save"
                      icon="fas fa-check"
                      onClick={(event) => this.submitSave(event)}
                      disabled={this.state.buttonDisabled}
                    />
                  </div>
                </div>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-col-12 p-xl-12 nw-datatable-detail-view product-list-datatable-detail-view">
                  {this.state.dataTableIsLoading ? (
                    this.NetiContentLoader.MyPointTableLoader()
                  ) : (
                    <DataTable
                      header={detailsTableHeader}
                      value={this.state.dataTableValue}
                      selectionMode="single"
                      //selection={this.state.dataTableSelection}
                      //onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                      responsive={true}
                      paginator={true}
                      rows={20}
                      globalFilter={this.state.globalFilter}
                    >
                      <Column
                        field="productName"
                        header="Product"
                        body={this.productInfoBody}
                      />
                      <Column
                        field="productDefaultCode"
                        header="Code Info"
                        body={this.codeInfoBody}
                      />
                      <Column
                        field="userBasicInfoDTO.customNetiID"
                        header="Owner Info"
                        body={this.ownerInfoBody}
                      />
                      <Column
                        field="salesPrice"
                        header="Price"
                        body={this.priceInfoBody}
                      />
                      <Column header="Action" body={editBody} />
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dialog-section">
          <ProducListInfoDialog
            viewDialogShow={this.state.viewDialogShow}
            onHideViewDialog={this.onHideViewDialog}
            productOwnerRevenueInfo={this.state.productOwnerRevenueInfo}
          />
        </div>
        <div className="dialog-section">
          <Dialog
            header="Update Product Information"
            className="nw-dialog"
            visible={this.state.updateDialogShow}
            onHide={this.onHideUpdateDialog}
            maximizable
          >
            <div className="p-fluid">
              <div className="p-grid">
                <div className="p-col-12 p-xl-12">
                  {this.state.updateDialogShow ? (
                    <ProductInfoUpdate
                      rowDataInfo={this.state.selectedRowData}
                      productUpdateDialog={this.onHideUpdateDialog}
                      productList={this.fetchNewProductList}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Dialog>
        </div>
        <div className="dialog-section">
          <Dialog
            header="Update Product Stock Information"
            className="nw-dialog"
            visible={this.state.stockDialogShow}
            onHide={this.onHideStockDialog}
          >
            <div className="p-fluid">
              <div className="p-grid">
                <div className="p-col-12 p-xl-12">
                  <ProductStockInfoUpdate
                    rowDataInfo={this.state.selectedRowData}
                    stockUpdateDialog={this.onHideStockDialog}
                    productList={this.fetchNewProductList}
                  />
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
