import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { StudentClassConfigInfo } from '../common/StudentClassConfigInfo';
import { Panel } from 'primereact/panel';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from "primereact/growl";
import { EmUserPointService } from '../../../service/emUserPoint/EmUserPointService';


export class StudentPortalClassRoutine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            classRoutine: '',
            dataTableValue: '',
            errorMsgBody: '',
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            userList: [],
            userID: '',
            userLength: '',
            insID: '',
            basicInfo: [],
            stId: '',   

        }
        this.studentPortal = new StudentPortal();
        this.NetiContentLoader = new NetiContentLoader();
        this.emUserPointService = new EmUserPointService();
    }

    componentWillMount(){
        this.loadStudentInfo();
    }
  
    handledropdwon = (id, insId) => {

        this.setState({userID: id})
        this.setState({insID:insId})
    
        this.loadStudentInfo(); 
    }
    
    loadStudentInfo = () => {
    
        setTimeout(() => {
            [...document.querySelectorAll('.protal-single-info-content')].map(item => {
                if (!item.querySelector('p').innerHTML.trim()) {
                    item.querySelector('p').innerHTML = '---'
                }
            })
        }, 1500);
    
        let userListtt = [];
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.emUserPointService.fetchMappedPortalList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log('Log: GoToPortal -> componentWillMount -> body......', body[0].idForStudent)
                        console.log('Log: GoToPortal -> componentWillMount -> body......', body)
                        
    
                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({userList: userListtt})
                        this.setState({userLength:body.length})
                        this.setState({stId:body[0].idForStudent})
    
            
                        
                        this.studentPortal
                        .fetchStudentProfileDetailsList(
                            this.state.insID || body[0]["emDetailsInfoDTO"].instituteId ,
                            this.state.userID || body[0].idForStudent
                            // body[this.state.userID]["emDetailsInfoDTO"].instituteId,
                            // body[this.state.userID].idForStudent,
                        )
                        .then(res => {
                            if (res.status == 200) {
                              
                                return res.json().then(body => {
                                    this.setState({ topProgressBar: false, errorMsgVisible: false })
                                    this.setState({ basicInfo: body });
                                    this.fetchClassRoutineInfo();
                                });
                                
                            } else {
                                this.studentPortal.Auth.handleErrorStatus(res)
                                    .then((responseBody) => {
                                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                    });
                            }
                        })
                        .catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));
    
                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                    })
                } else {
                    this.emUserPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch((error) => {
                console.log(error)
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    
    
        this.setState({ topProgressBar: true, errorMsgVisible: false })
    };
  


    fetchClassRoutineInfo() {
        let requestobject = this.state.basicInfo;
        console.log("data....", requestobject)
        this.setState({ dataTableIsLoading: true, errorMsgVisible: false, topProgressBar: true })
        this.studentPortal.fetchStudentClassRoutine(requestobject).then(res => {
            if (res.status === 200) {
                return res.json().then((body) => {
                    this.setState({ classRoutine: body, dataTableValue: body.routines });
                    this.setState({ dataTableIsLoading: false, topProgressBar: false });

                });
            } else {
                return res.json().then((errorBody) =>
                    this.setState({ dataTableIsLoading: false, errorMsgVisible: true, topProgressBar: false, errorMsgBody: errorBody.message }))
            }
        }).catch(error => this.setState({ dataTableIsLoading: false, errorMsgVisible: true, topProgressBar: false, errorMsgBody: 'Unable to load data. Please check connection' }));

    }

    render() {

        var rows = [];
        let l = this.state.userLength;
        this.state.userList.map((item) =>{
            if(l > rows.length){
                rows.push(<a  onClick={() => this.handledropdwon(item.idForStudent, item["emDetailsInfoDTO"].instituteId)} >    {item.idForStudent}  </a>)
            }
        })

        let columns = [];
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let TableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Class Routine
            <div style={{ color: "white", display: "flex", float: "right" }}>
                {/* Total Found: {this.state.tableDetails.length} */}
            </div>
        </div>;

        if (this.state.classRoutine) {

            let fisrtPeriodHeader = <b>{this.state.classRoutine.firstPeriodName}<br />{this.state.classRoutine.firstPeriodTime}</b>
            let secondPeriodHeader = <b>{this.state.classRoutine.secondPeriodName}<br />{this.state.classRoutine.secondPeriodTime}</b>
            let thirdPeriodHeader = <b>{this.state.classRoutine.thirdPeriodName}<br />{this.state.classRoutine.thirdPeriodTime}</b>
            let fourthPeriodHeader = <b>{this.state.classRoutine.fourthPeriodName}<br />{this.state.classRoutine.fourthPeriodTime}</b>
            let fifthPeriodHeader = <b>{this.state.classRoutine.fifthPeriodName}<br />{this.state.classRoutine.fifthPeriodTime}</b>
            let sixthPeriodHeader = <b>{this.state.classRoutine.sixthPeriodName}<br />{this.state.classRoutine.sixthPeriodTime}</b>
            let seventhPeriodHeader = <b>{this.state.classRoutine.seventhPeriodName}<br />{this.state.classRoutine.seventhPeriodTime}</b>
            let eighthPeriodHeader = <b>{this.state.classRoutine.eighthPeriodName}<br />{this.state.classRoutine.eighthPeriodTime}</b>
            let ninethPeriodHeader = <b>{this.state.classRoutine.ninethPeriodName}<br />{this.state.classRoutine.ninethPeriodTime}</b>
            let tenthPeriodHeader = <b>{this.state.classRoutine.tenthPeriodName}<br />{this.state.classRoutine.tenthPeriodTime}</b>

            let elevenPeriodHeader = <b>{this.state.classRoutine.elevenPeriodName}<br />{this.state.classRoutine.elevenPeriodTime}</b>
            let twelvePeriodHeader = <b>{this.state.classRoutine.twelvePeriodName}<br />{this.state.classRoutine.twelvePeriodTime}</b>
            let thirteenPeriodHeader = <b>{this.state.classRoutine.thirteenPeriodName}<br />{this.state.classRoutine.thirteenPeriodTime}</b>
            let fourtheenPeriodHeader = <b>{this.state.classRoutine.fourtheenPeriodName}<br />{this.state.classRoutine.fourtheenPeriodTime}</b>
            let tiffinPeriodHeader = <b>{this.state.classRoutine.tiffinPeriodName}<br />{this.state.classRoutine.tiffinPeriodTime}</b>


            let cellValue = (value) => {
                if (value != null) {
                    let temp = value.split('<br/>');
                    return <div>
                        {temp[2]}<br />{temp[3]}</div>
                } else {
                    return <div></div>;
                }
            }


            let firstPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.firstPeriodSubject)}</div>;
            }
            let secondPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.secondPeriodSubject)}</div>;
            }
            let thirdPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.thirdPeriodSubject)}</div>;
            }
            let fourthPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.fourthPeriodSubject)}</div>;
            }
            let fifthPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.fifthPeriodSubject)}</div>;
            }
            let sixthPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.sixthPeriodSubject)}</div>;
            }
            let seventhPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.seventhPeriodSubject)}</div>;
            }
            let eighthPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.eighthPeriodSubject)}</div>;
            }
            let ninethPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.ninethPeriodSubject)}</div>;
            }
            let tenthPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.tenthPeriodSubject)}</div>
            };


            let elevenPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.elevenPeriodSubject)}</div>;
            }
            let twelvePeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.twelvePeriodSubject)}</div>;
            }
            let thirteenPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.thirteenPeriodSubject)}</div>;
            }
            let fourtheenPeriodSubject = (rowData) => {
                return <div>{cellValue(rowData.fourtheenPeriodSubject)}</div>
            };
            let tiffinPeriodSubject = (rowData) => {
                return <div><i className='ui-icon-resturent'></i></div>
            };


            columns.push(<Column field="dayName" header="Day" />);


            if (this.state.classRoutine.firstPeriodName) columns.push(<Column body={firstPeriodSubject} header={fisrtPeriodHeader} />);
            if (this.state.classRoutine.secondPeriodName) columns.push(<Column body={secondPeriodSubject} header={secondPeriodHeader} />);
            if (this.state.classRoutine.thirdPeriodName) columns.push(<Column body={thirdPeriodSubject} header={thirdPeriodHeader} />);
            if (this.state.classRoutine.fourthPeriodName) columns.push(<Column body={fourthPeriodSubject} header={fourthPeriodHeader} />);
            if (this.state.classRoutine.fifthPeriodName) columns.push(<Column body={fifthPeriodSubject} header={fifthPeriodHeader} />);
            if (this.state.classRoutine.sixthPeriodName) columns.push(<Column body={sixthPeriodSubject} header={sixthPeriodHeader} />);
            if (this.state.classRoutine.seventhPeriodName) columns.push(<Column body={seventhPeriodSubject} header={seventhPeriodHeader} />);
            if (this.state.classRoutine.eighthPeriodName) columns.push(<Column body={eighthPeriodSubject} header={eighthPeriodHeader} />);
            if (this.state.classRoutine.ninethPeriodName) columns.push(<Column body={ninethPeriodSubject} header={ninethPeriodHeader} />);
            if (this.state.classRoutine.tenthPeriodName) columns.push(<Column body={tenthPeriodSubject} header={tenthPeriodHeader} />);

            if (this.state.classRoutine.elevenPeriodName) columns.push(<Column body={elevenPeriodSubject} header={elevenPeriodHeader} />);
            if (this.state.classRoutine.twelvePeriodName) columns.push(<Column body={twelvePeriodSubject} header={twelvePeriodHeader} />);
            if (this.state.classRoutine.thirteenPeriodName) columns.push(<Column body={thirteenPeriodSubject} header={thirteenPeriodHeader} />);
            if (this.state.classRoutine.fourtheenPeriodName) columns.push(<Column body={fourtheenPeriodSubject} header={fourtheenPeriodHeader} />);
            if (this.state.classRoutine.tiffinPeriodName) columns.push(<Column body={tiffinPeriodSubject} header={tiffinPeriodHeader} />);

        }

        return (
            <div className="p-col-12 p-col-nogutter">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid nw-form">
                    <div className="p-col-12" style={{position: "relative"}}>
                        <StudentClassConfigInfo basicInfo={this.state.basicInfo} />

                        <div className='dropdown'>
            
                            <button className="dropbtn">
                            {/* <i className="fa fa-caret-down"></i> */}
                            <span class="fas fa-align-right p-c p-button-icon-left"></span>
                            </button>
                                <div className="dropdown-content">
                                    
                                {rows}

                                </div>

                        </div>
                        
                    </div>

                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <p className='profile-custom-table-header'>Class Routine</p>
                    </div>

                    <div className="p-col-12 p-lg-12 p-xl-12">
                        {this.state.dataTableIsLoading ?
                            this.NetiContentLoader.MyPointTableLoader() :
                            <div className="nw-data-table">
                                <DataTable
                                    value={this.state.dataTableValue}
                                    header={TableHeader}
                                >
                                    {columns}
                                </DataTable>
                            </div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}