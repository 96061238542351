import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AuthService from './auth/AuthService';
import customStyle from './customStyle.css';
import globalStyle from './globalStyle.css';
// import './assets/style/_globalStyle_nw4.scss'
import { ClearSession } from './auth/ClearSession';
import { MY_POINT, EM_USER_POINT, GUEST_POINT } from './utils/PointWiseRouteConsts';
import { withRouter } from 'react-router';
import { AppBreadcrumb } from './AppBreadcrumb';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Editor } from 'primereact/editor';
import { NetiDateUtils } from './utils/NetiDateUtils';
import { ChatBoxService } from './service/chatBox/chatBoxService';
import CommonFuctionality from "./components/common/CommonFuctionality";
import ChatBoxCommonBody from './components/myPoint/chatbox/ChatBoxCommonBody';
import { TokenCommonBody } from './components/myPoint/profile/TokenCommonBody';
import { TokenService } from './service/myPoint/TokenService';
import { InputTextarea } from 'primereact/inputtextarea';
import { Rating } from 'primereact/rating';
import { DownloadButton } from './utils/DownloadButton';
import { ErrorMessageView } from './components/common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import TokenCommonBodyNew from './components/myPoint/profile/TokenCommonBodyNew';


const Auth = new AuthService();
let countPending= 0, countSolved= 0;



export class AppTopbar extends Component {

	static defaultProps = {
		onMenuButtonClick: null,
		onTopbarMenuButtonClick: null,
		onTopbarItemClick: null,
		topbarMenuActive: false,
		activeTopbarItem: null,
		profileMode: null,
		horizontal: null,
		history: null
	}

	static propTypes = {
		onMenuButtonClick: PropTypes.func.isRequired,
		onTopbarMenuButtonClick: PropTypes.func.isRequired,
		onTopbarItemClick: PropTypes.func.isRequired,
		topbarMenuActive: PropTypes.bool.isRequired,
		activeTopbarItem: PropTypes.string,
		profileMode: PropTypes.string,
		horizontal: PropTypes.bool,
		history: PropTypes.object
	}

	constructor() {
		super();
		this.state = {
			checkAddpoint: true,
			unreadMessageList: [],
			unreadMessageDetails: {
				senderBasicInfoDTO: {}
			},
			ratingObj:{
                ratingValue: '',
                commentValue:'',  
			},
			chatAndTokenCount:{
				chats: 0,
    			tokens: 0
			},
			tokenDataTableValue:[],
			tokenList:'',
			errors:{},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
		};


		this.chatBoxService = new ChatBoxService();
		this.CommonFuctionality = new CommonFuctionality();
		this.tokenService = new TokenService();
	}

	componentWillMount() {
		(Auth.getLoggedRoles()).map(role => {
			if (role === 'ROLE_EMUSER') {
				this.setState({ checkAddpoint: false });
			}
		});

		// this.getUnreadMessageList();
		// this.showUserTopTenTokenList();
		this.getChatAndTokenCount();
	}

	getChatAndTokenCount = () =>{
		this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
		this.chatBoxService.fetchChatAndTokenCount()
			.then(res => {
				if (res.status === 302) {
					return res.json().then((body) => {
						this.setState({ chatAndTokenCount: body, topProgressBar: false, dataTableIsLoading: false });
					});
				} else {
					this.chatBoxService.Auth.handleErrorStatus(res)
						.then((responseBody) => {
							this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false, errorMsgBody: responseBody })
						});
				}
			}).catch(error =>
				this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
			);
	}


	onTopbarItemClick(event, item){
		if (this.props.onTopbarItemClick) {
			this.props.onTopbarItemClick({
				originalEvent: event,
				item: item
			});
		}
	}

	handleLogout() {
		Auth.logout();
		this.props.history.replace(GUEST_POINT.LOGIN)
	}

	handleAddPoint() {
		this.props.history.replace(EM_USER_POINT.ADD_POINT)
	}

	handleChatBox() {
		this.props.history.replace(MY_POINT.CHAT_BOX)
	}

	getUnreadMessageList() {
		let { chatAndTokenCount } = this.state
		this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
		this.chatBoxService.fetchUnreadMessageInfo()
			.then(res => {
				if (res.status === 302) {
					return res.json().then((body) => {
						// console.log("unreadMessageList", body);

						chatAndTokenCount.chats = body.length

						this.setState({ unreadMessageList: body, topProgressBar: false, dataTableIsLoading: false });

					});
				} else {
					this.chatBoxService.Auth.handleErrorStatus(res)
						.then((responseBody) => {
							this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false, errorMsgBody: responseBody })
						});
				}
			}).catch(error =>
				this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
			);
	}

	showUserTopTenTokenList = () => {
		let { chatAndTokenCount } = this.state
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.tokenService.getPendingTokenList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {

						chatAndTokenCount.tokens = body.length
						countSolved = countPending = 0;
                        for(let i=0; i<body.length; i++){
                            if(body[i].tokenTypeInfoDTO.parentCoreCategoryInfoDTO==null){
                                body[i].tokenTypeInfoDTO.parentCoreCategoryInfoDTO={categoryName:''}
                            }

                            body[i].tokenStatus == 0 ? countPending += 1 : countPending += 0
                            body[i].tokenStatus == 10 ? countSolved += 1 : countSolved += 0
                        }
                        this.setState({ tokenDataTableValue: body });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.tokenService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false, errorMsgBody: resp})
                    });
                }
            }).catch(error => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }

	viewUnreadMSGdetails = (unreadMessage) => {


		// // console.log("unreadMessage", unreadMessage);
		// this.setState({ visibleUnreadMSG: true, unreadMessageDetails: unreadMessage })

		// this.CommonFuctionality.blurDialogBackgroundActive();
		// setTimeout(() => {
		// 	let dialogMask = document.getElementsByClassName('p-dialog-mask')[0]; //p-component-overlay p-dialog-mask
		// 	let dialog = document.getElementsByClassName('p-dialog')[0]; //p-component-overlay p-dialog-mask
		// 	let nwTopbar = document.getElementsByClassName('nw-topbar')[0]; //p-component-overlay p-dialog-mask
		// 	// console.log("dialogMask", dialogMask);
		// 	// console.log("dialog", dialog);

		// 	dialogMask.style.zIndex = "995"
		// }, 500);
	}

	onHideMessage = () => {
		this.setState({ visibleUnreadMSG: false });
		this.CommonFuctionality.blurDialogBackgroundDeActive();
	}


	onChangeRatingValue = e =>{
        let { ratingObj, errors } = this.state
        errors["ratingMark"] = "";
        ratingObj.ratingValue = e.value
        this.setState({ ratingObj, ratingValue: e.value })
    }

    onChangeCommentValue = e =>{
        let { ratingObj, errors } = this.state
        errors["ratingComment"] = "";
        ratingObj.commentValue = e.target.value
        this.setState({ ratingObj })
    }

    ratingHandleError = () => {
        let { tokenRatingObj, ratingObj, errors } = this.state
        let formIsValid = true;

        if (ratingObj.ratingValue == '') {
            formIsValid = false;
            errors["ratingMark"] = "Rating Mark can't left empty.";
        }

        if (ratingObj.commentValue == '') {
            formIsValid = false;
            errors["ratingComment"] = "Rating Comment can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitRating = () =>{
        let { tokenList, ratingObj } = this.state

        tokenList.ratingMark = ratingObj.ratingValue
        tokenList.ratingMessage = ratingObj.commentValue

        // console.log("tokenRatingObj::::", tokenRatingObj)
        if (this.ratingHandleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.tokenService.saveUserRating(tokenList)
              .then(res => {
                if (res.status == 202) {
                  this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                  this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted" });
                //   this.showUserTopTenMessageRechargeList();
                setTimeout(() => {
                    this.onHide();
                    this.showUserTopTenTokenList()
                }, 800);
                } else {
                  this.MessageRechargeService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                      this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                    });
                }
            }).catch(error => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
        }
    }

    showTokenDetails = (item) =>{
		// let { tokenList, ratingObj } = this.state;

        // this.setState({ visible: true, tokenList: item });
		// this.CommonFuctionality.blurDialogBackgroundActive();
		



		// setTimeout(() => {
		// 	let dialogMask = document.getElementsByClassName('p-dialog-mask')[0]; //p-component-overlay p-dialog-mask
		// 	let dialog = document.getElementsByClassName('p-dialog')[0]; //p-component-overlay p-dialog-mask
		// 	let nwTopbar = document.getElementsByClassName('nw-topbar')[0]; //p-component-overlay p-dialog-mask
		// 	console.log("dialogMask", dialogMask);
		// 	console.log("dialogMask", dialog);

		// 	dialogMask.style.zIndex = "995"
		// 	// dialog.style.zIndex = "996"
		// 	// nwTopbar.style.display = "none"
		// }, 500);
    }

    onHide = () => {
        this.setState({ visible: false })
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    getRemainTime = (start, end) =>{
        let countValue 
        var countDownDate = new Date( start ).getTime()
        var now
        if(end != null){ 
            now = new Date( end ).getTime()
        }else{
            now = new Date().getTime();
        }
            
                
            var distance = now - countDownDate;
                
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            countValue =days + "d " + hours + "h "+ minutes + "m " + seconds + "s "

        return countValue
	}
	







	render() {
		let { chatAndTokenCount, tokenDataTableValue, unreadMessageList, unreadMessageDetails, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

		new ClearSession(this.props);
		let topbarItemsClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': this.props.topbarMenuActive });

		const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

		let dialogHeader = <div className="header">
			<img src="/assets/images/demo.jpg" width="35px" />
			<div>
				<div className="username">{unreadMessageDetails.senderBasicInfoDTO && unreadMessageDetails.senderBasicInfoDTO.fullName}</div>
				<div className="date">{NetiDateUtils.getAnyShortForm(unreadMessageDetails.chatboxDate, 'DD-MMM-YYYY hh:mm:ss a')}</div>
			</div>
		</div>


		let { tokenList, ratingObj } = this.state;

		let progressMainClass1 = "info-box-wrapper progress-view step-1 "
		let progressMainClass2 = "info-box-wrapper progress-view step-2 "
		let progressMainClass3 = "info-box-wrapper progress-view step-3 "
		let progressMainClass4 = "info-box-wrapper progress-view step-4 "
		let progressMainClass5 = "info-box-wrapper progress-view step-5 ";

		if(tokenList && tokenList.tokenStatus == 0){
			progressMainClass1 = progressMainClass1+"active"
			progressMainClass2 = progressMainClass2+"active"
			// this.getRemainTime(tokenList && tokenList.createDate)
		}
		else if(tokenList && tokenList.tokenStatus == 1){
			progressMainClass1 = progressMainClass1+"active"
			progressMainClass2 = progressMainClass2+"active"
			progressMainClass3 = progressMainClass3+"active"
		}

		else if(tokenList && tokenList.tokenStatus == 10){
			progressMainClass1 = progressMainClass1+"active"
			progressMainClass2 = progressMainClass2+"active"
			progressMainClass3 = progressMainClass3+"active"
			progressMainClass4 = progressMainClass4+"active"
		}

		

		return <div className="layout-topbar nw-topbar p-col clearfix">
			{topProgressBar ?
                    <ErrorMessageView
                    topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                    errorMsgVisible={errorMsgVisible}
                    errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />


			<div className="animated-circle blur-section">
				<div className="circle-first"></div>
				<div className="circle-second"></div>
				<div className="circle-third"></div>
			</div>


			<div className="p-fluid blur-section">

				<div className="nw-topbar-left-menu p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-nogutter">
					<div className="nw-topbar-button ">
						<button className="layout-topbar-logo p-link">
							<img id="layout-topbar-logo" alt="Netizen IT Limited" src="assets/layout/images/NetiWorld-White--with-text.png" />
						</button>

						<button className="layout-menu-button p-link" onClick={this.props.onMenuButtonClick}>
							<i className="fas fa-align-left"></i>
							{/* <AppBreadCrumbWithRouter topLoadingBar={this.props.showTopLoadingBar} /> */}
						</button>

						<AppBreadCrumbWithRouter topLoadingBar={this.props.showTopLoadingBar} />

						<button id="topbar-menu-button" className="topbar-menu-button p-link" onClick={this.props.onTopbarMenuButtonClick}>
							<i className="pi pi-ellipsis-v" />
						</button>
					</div>

					{/* <AppBreadCrumbWithRouter topLoadingBar={this.props.showTopLoadingBar} /> */}
				</div>



				<div className="nw-topbar-right-menu p-md-6 p-lg-6 p-xl-6 p-col-nogutter">

					{/* <div className="topbar-turorial-link">
						<i className="fas fa-play-circle"/>
						<span> How it works? <u>Watch a tutorial now!</u></span>

					</div> */}

					{/* Search bar */}
					{/* <div className="topbar-search">
						<div className="p-inputgroup">  
							<InputText
								value=""
								onChange={(e) => this.onChangeNote(e)}
								placeholder="search"
							/>
							<Button
								icon="fas fa-search"
								onClick={(e) => this.onChangeSearchByCustomNetiID(e, this.props)}
							/>
						</div>
					</div> */}


					<ul className={topbarItemsClassName}>
						{(this.props.profileMode === 'popup' || this.props.horizontal) &&
							<li className={classNames('user-profile', { 'active-topmenuitem': this.props.activeTopbarItem === 'profile' })}
								onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
								<button className="p-link">
									<img alt="babylon-layout" src="assets/layout/images/avatar.png" />
									<span className="topbar-item-name">Arlene Welch</span>
								</button>

								<ul className="fadeInDown">
									<li role="menuitem">
										<button className="p-link">
											<i className="pi pi-user" />
											<span>Profile</span>
										</button>
									</li>
									<li role="menuitem">
										<button className="p-link">
											<i className="pi pi-cog" />
											<span>Settings</span>
										</button>
									</li>
									<li role="menuitem">
										<button className="p-link">
											<i className="pi pi-envelope" />
											<span>Message</span>
										</button>
									</li>
									<li role="">
									</li>
									<li role="menuitem">
										<button className="p-link" onClick={this.handleLogout.bind(this)}>
											<i className="fas fa-sign-out-alt" />
											<span>Logout</span>
										</button>
									</li>
								</ul>
							</li>}

						<li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'settings' })}
							onClick={(e) => this.onTopbarItemClick(e, 'settings')}>
							{/* <button className="p-link">
								<i className="topbar-icon pi pi-calendar"/>
								<span className="topbar-item-name">Notifications</span>
							</button> */}
							<button className="p-link" >
								<i className="topbar-icon fas fa-cog" />
								{/* <span className="topbar-item-name">Settings</span> */}
								{/* <span className="tooltip">Settings</span> */}
							</button>

							<ul className="fadeInDown">
								{/* <li role="menuitem">
									<button className="p-link">
										<i className="pi pi-tags"/>
										<span>Pending tasks</span>
										<span className="topbar-submenuitem-badge">6</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="p-link">
										<i className="pi pi-calendar-plus"/>
										<span>Meeting today at 3pm</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="p-link">
										<i className="pi pi-download"/>
										<span>Download</span>
									</button>
								</li> */}

								<li role="menuitem">
									<button className="p-link" onClick={this.handleAddPoint.bind(this)}>
										<i className="pi pi-plus-circle" />
										<span>Add Point</span>
										{this.state.checkAddpoint ? <span className="topbar-submenuitem-badge">1</span> : null}
									</button>
								</li>
								<li role="menuitem">
									<button className="p-link" onClick={this.handleLogout.bind(this)}>
										<i className="topbar-icon fas fa-sign-out-alt" />
										<span className="topbar-item-name">Logout</span>
										{/* <span className="tooltip">Logout</span> */}
									</button>
								</li>
							</ul>
						</li>
						<li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'messages' })}
							onClick={(e) => this.onTopbarItemClick(e, 'messages')}>


						</li>
						<li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'messages' })} style={{ display: "none"}}
							onClick={(e) => this.onTopbarItemClick(e, 'messages')}>
							<button className="p-link" >
								{/* Notification icon */}
								{/* <span className="topbar-badge">99</span>
								<i className="topbar-icon far fa-bell"/> */}
								{/* <span className="topbar-item-name">Messages</span> */}
								{/* <span className="tooltip">Notifications</span> */}

							</button>
							<ul className="fadeInDown">
								<li role="menuitem">
									<button className="topbar-message p-link">
										<img src="assets/layout/images/avatar-john.png" alt="babylon-layout" />
										<span>Give me a call</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="topbar-message p-link">
										<img src="assets/layout/images/avatar-julia.png" alt="babylon-layout" />
										<span>Reports attached</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="topbar-message p-link">
										<img src="assets/layout/images/avatar-kevin.png" alt="babylon-layout" />
										<span>About your invoice</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="topbar-message p-link">
										<img src="assets/layout/images/avatar-julia.png" alt="babylon-layout" />
										<span>Meeting today</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="topbar-message p-link">
										<img src="assets/layout/images/avatar.png" alt="babylon-layout" />
										<span>Out of office</span>
									</button>
								</li>
							</ul>
						</li>

						<li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'message' })}
							onClick={(e) => this.onTopbarItemClick(e, 'message')}>
							<button className="p-link" onClick={ e=> this.getUnreadMessageList() }>
								{/* Message count */}
								{ chatAndTokenCount.chats == 0  ? null : <span className="topbar-badge"> { chatAndTokenCount.chats } </span>}
								{/* <span className="topbar-badge"> { unreadMessageList.length || chatAndTokenCount.chats } </span> */}
								<i className="topbar-icon fas fa-envelope" />
								{/* <span className="topbar-item-name">Settings</span> */}
								{/* <span className="tooltip">Message</span> */}
							</button>

							<ul className="fadeInDown" style={{ padding: 0, width: "375px" }}>
								{/* <li role="menuitem">
									<button className="p-link" onClick={this.handleChatBox.bind(this)}>
										<i className="pi pi-fw pi-comment" />
										<span>New Chat Box</span>
									</button>
								</li> */}


								<div className="p-col-12 p-xl-12 nw-chatbox-view-main topbar">
									<div className="p-col-12 p-xl-12 p-col-nogutter nw-chatbox-view body">
										{unreadMessageList.map((unreadMessage, index) =>
											<div className="p-col-12 p-xl-12 inside">
												<ChatBoxCommonBody viewUnreadMSGdetails={this.viewUnreadMSGdetails} unreadMessage={unreadMessage} />
											</div>
										)}
									</div>
									<div className="p-grid p-col-12 p-xl-12 footer">
										<div className="p-col-6">
											<Button
												className="nw-button"
												label="View All"
												icon="fas fa-eye"
												iconPos='right'
												onClick={(e) => window.location.href = "/chat_box_list"}
											/>
										</div>
										<div className="p-col-6">
											<Button
												className="nw-button"
												label="Neti Mail"
												icon="fas fa-plus-circle"
												iconPos='right'
												onClick={(e) => window.location.href = "/chat_box"}
											/>
										</div>
									</div>
								</div>


							</ul>

						</li>

						<li className={classNames({'active-topmenuitem': this.props.activeTopbarItem === 'notifications'})}
							onClick={(e) => this.onTopbarItemClick(e, 'notifications')}>
							
							<ul className="fadeInDown">
								<li role="menuitem">
									<button className="p-link">
										<i className="pi pi-pencil"/>
										<span>Change Theme</span>
										<span className="topbar-submenuitem-badge">4</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="p-link">
										<i className="pi pi-star-o"/>
										<span>Favorites</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="p-link">
										<i className="pi pi-lock"/>
										<span>Lock Screen</span>
										<span className="topbar-submenuitem-badge">2</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="p-link">
										<i className="pi pi-image"/>
										<span>Wallpaper</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="p-link" onClick={this.handleAddPoint.bind(this)}>
										<i className="pi pi-plus-circle"/>
										<span>Add Point</span>
									</button>
								</li>
								<li role="menuitem">
									<button className="p-link" onClick={this.handleChatBox.bind(this)}>
										<i className="pi pi-fw pi-comment"/>
										<span>Chat Box</span>
									</button>
								</li>
								
							</ul>
						</li>

						<li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'token' })}
							onClick={(e) => this.onTopbarItemClick(e, 'token')}>
							<button className="p-link" onClick={ e=> this.showUserTopTenTokenList() }>
								{/* Message count */}
								{/* { countPending + countSolved == 0 ? null : <span className="topbar-badge"> {countPending + countSolved} </span>} */}
								{ chatAndTokenCount.tokens == 0  ? null : <span className="topbar-badge"> { chatAndTokenCount.tokens } </span>}
								<i className="topbar-icon fas fa-ticket-alt" />
								{/* <span className="topbar-item-name">Settings</span> */}
								{/* <span className="tooltip">Message</span> */}
							</button>

							<ul className="fadeInDown" style={{ padding: 0, width: "375px" }}>
								{/* <li role="menuitem">
									<button className="p-link" onClick={this.handleChatBox.bind(this)}>
										<i className="pi pi-fw pi-comment" />
										<span>New Chat Box</span>
									</button>
								</li> */}


								<div className="p-col-12 p-xl-12 nw-token-view-main topbar">

									<div className="pending-header">
										<span className="title">Pending</span>
										<span className="count">{ countPending }</span>
									</div>

									<div className="p-col-12 p-xl-12 p-col-nogutter nw-token-view body">
										{tokenDataTableValue.length > 0 ?
											tokenDataTableValue.map((item, index) =>
												item.tokenStatus == 0  && item.ratingMark == 0 ?
													<div className="p-col-12 p-xl-12 inside">
														<TokenCommonBody actionDetails={this.actionDetails} tokenList={ item } reloadTokenList={this.showUserTopTenTokenList}/>
														
														{/* <div className="p-inputgroup">
															<span className="p-inputgroup-addon info blur-section">
																<div> Token Id: { item.customTokenID}</div>
																<div> Issue Date: { NetiDateUtils.getAnyShortForm(item.createDate, 'DD-MMM-YYYY hh:mm:ss a') }</div>
																{ item.tokenStatus == 10? 
																	<div> Solve Date: { NetiDateUtils.getAnyShortForm(item.solveDate, 'DD-MMM-YYYY hh:mm:ss a') }</div>
																	:''
																}
															</span>
															<Button
																label="View"
																className={ item.tokenStatus == 10? "bg-success blur-section" : item.tokenStatus == 0 ? "bg-warning blur-section" : ''}
																onClick={ e => this.showTokenDetails(item)}
															/>
														</div> */}
													</div>
													:null
											)
											: <div className="p-col-12 p-xl-12 inside">
												<div className="p-inputgroup">
													<span className="p-inputgroup-addon info blur-section">Token not found</span>
												</div>
											</div>
										} 
									</div>

									<div className="solved-header">
										<span className="title">Solved</span>
										<span className="count">{ countSolved }</span>
									</div>

									<div className="p-col-12 p-xl-12 p-col-nogutter nw-token-view body">
										{tokenDataTableValue.length > 0 ?
											tokenDataTableValue.map((item, index) =>
												item.tokenStatus == 10 && item.ratingMark == 0 ?
													<div className="p-col-12 p-xl-12 inside">

														<TokenCommonBodyNew actionDetails={this.actionDetails} tokenList={ item } reloadTokenList={this.showUserTopTenTokenList}/>
														
														{/* <div className="p-inputgroup">
															<span className="p-inputgroup-addon info blur-section">
																<div> Token Id: { item.customTokenID}</div>
																<div> Issue Date: { NetiDateUtils.getAnyShortForm(item.createDate, 'DD-MMM-YYYY hh:mm:ss a') }</div>
																{ item.tokenStatus == 10? 
																	<div> Solve Date: { NetiDateUtils.getAnyShortForm(item.solveDate, 'DD-MMM-YYYY hh:mm:ss a') }</div>
																	:''
																}
															</span>
															<Button
																label="View"
																className={ item.tokenStatus == 10? "bg-success blur-section" : item.tokenStatus == 0 ? "bg-warning blur-section" : ''}
																onClick={ e => this.showTokenDetails(item)}
															/>
														</div> */}
													</div>
													:null
											)
											: <div className="p-col-12 p-xl-12 inside">
												<div className="p-inputgroup">
													<span className="p-inputgroup-addon info blur-section">Token not found</span>
												</div>
											</div>
										} 
									</div>
									<div className="p-grid p-col-12 p-xl-12 footer">
										<div className="p-col-6">
											<Button
												className="nw-button"
												label="View All"
												icon="fas fa-eye"
												iconPos='right'
												onClick={(e) => window.location.href = "/token_list"}
											/>
										</div>
										<div className="p-col-6">
											<Button
												className="nw-button"
												label="New Token"
												icon="fas fa-plus-circle"
												iconPos='right'
												onClick={(e) => window.location.href = "/new_token"}
											/>
										</div>
									</div>
								</div>


							</ul>

						</li>


						{/* Add inline css  in nth child 4 */}
						<li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'messages' })}
							onClick={(e) => this.onTopbarItemClick(e, 'messages')} style={{ border: '0px solid' }}>



						</li>

						<li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'tutorial' })}
							onClick={(e) => this.onTopbarItemClick(e, 'tutorial')}>
							<button className="p-link" >
								{/* Question Icon */}
								{/* <i className="topbar-icon fas fa-question"/> */}
								{/* <span className="topbar-item-name">Settings</span> */}
								{/* <span className="tooltip">Tutorial</span> */}
							</button>

						</li>

						<li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'support-token' })}
							onClick={(e) => this.onTopbarItemClick(e, 'support-token')}>
							<button className="p-link" >
								{/* Ring Icon */}
								{/* <i className="topbar-icon far fa-life-ring"/> */}
								{/* <span className="topbar-item-name">Settings</span> */}
								{/* <span className="tooltip">Support Token</span> */}
							</button>

						</li>
					</ul>


				</div>
			</div>

			<div className="dialog-section">
				<Dialog
					header={dialogHeader}
					visible={this.state.visibleUnreadMSG}
					className="nw-dialog chat-box-dialog"
					onHide={this.onHideMessage}
					maximizable
				>

					<div className="p-grid body nw-form">
						<div className="p-col-12 p-xl-12">
							<div className="p-inputgroup">
								<div className="nw-inputgroup-desc" style={{ height: "auto" }}>
									<Editor
										value={unreadMessageDetails.chatboxBody}
										className="nw-inputtexteditor editor-only-view"
										readOnly={true}
										headerTemplate={e => { return null }}
									/>
								</div>

							</div>
						</div>

						<div className="p-col-12 p-xl-12">
							{/* <div className="p-grid">
								<div className="p-col-6">
									<Button
									className="nw-button nw-button-no-style"
									label="Attachment"
									icon="fas fa-download"
									iconPos='left'
									onClick={(e) => this.downloadAttachment(unreadMessageDetails)}
									></Button>
								</div>

								<div className="p-col-6 nw-button-parent-multiple">
									<span></span>
									<Button
									className="nw-button nw-button-multiple nw-button-no-style"
									label="Reply"
									icon="fas fa-reply"
									iconPos='left'
									onClick={(e) => this.downloadAttachment(unreadMessageDetails)}
									></Button>
									<Button
									className="nw-button nw-button-multiple nw-button-no-style"
									label="Forward"
									icon="fas fa-share-square"
									iconPos='left'
									onClick={(e) => this.downloadAttachment(unreadMessageDetails)}
									></Button>
								</div>
							</div> */}

						</div>

						{/* <div className="p-col-12 p-xl-12 body">
							<Button
								className="nw-action-button"
								label="View"
								icon="fas fa-download"
								iconPos='left'
								onClick={(e) => this.downloadAttachment(unreadMessageDetails)}
							></Button>
						</div> */}
					</div>
				</Dialog>

				<Dialog 
                    header="Token Information" 
                    className="nw-dialog"
                    onHide={this.onHide} 
                    visible={this.state.visible}
                    dismissableMask={true}
                >

                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                        <div className="p-col-12 p-xl-12">

                                            <div className="tracking-progress-view">
                                                <div className={ progressMainClass1 }>
                                                    <div className="info-icon icon-bg-1">
                                                        <i className="fas fa-sign-in-alt"></i>
                                                    </div>
                                                    <div className="info-text">
                                                        <p>Submit</p>
                                                    </div>
                                                    <div className="info-button info-btn-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </div>
                                                </div>

                                                <div className="next"><div></div></div>

                                                <div className={ progressMainClass2 }>
                                                    <div className="info-icon icon-bg-1">
                                                        <i className="fas fa-spinner"></i>
                                                    </div>
                                                    <div className="info-text">
                                                        <p>Pending</p>
                                                    </div>
                                                    <div className="info-button info-btn-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </div>
                                                </div>

                                                <div className="next"><div></div></div>

                                                <div className={ progressMainClass3 }>
                                                    <div className="info-icon icon-bg-1">
                                                        <i className="fas fa-cogs"></i>
                                                    </div>
                                                    <div className="info-text">
                                                        <p>Process</p>
                                                    </div>
                                                    <div className="info-button info-btn-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </div>
                                                </div>

                                                <div className="next"><div></div></div>

                                                <div className={ progressMainClass4 }>
                                                    <div className="info-icon icon-bg-1">
                                                        <i className="fas fa-clipboard-check"></i>
                                                    </div>
                                                    <div className="info-text">
                                                        <p>Solved</p>
                                                    </div>
                                                    <div className="info-button info-btn-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </div>
                                                </div>

                                                <div className="next"><div></div></div>

                                                <div className={ progressMainClass5 }>
                                                    <div className="info-icon icon-bg-1">
                                                        <i className="fas fa-star"></i>
                                                    </div>
                                                    <div className="info-text">
                                                        <p>Rating</p>
                                                    </div>
                                                    <div className="info-button info-btn-1">
                                                        <i className="fas fa-info-circle"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12"></div>
                                        <div className="p-col-12 p-xl-12"></div>
                                        <div className="p-col-12 p-xl-12"></div>

                                        { tokenList && tokenList.tokenStatus == 10?

                                            <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Give Rating  <span>*</span></span>
                                                        <div className="nw-inputgroup-desc">
                                                            <Rating 
                                                                value={this.state.ratingValue} 
                                                                cancel={false} 
                                                                onChange={this.onChangeRatingValue}
                                                                />
                                                        </div>
                                                    </div>
													<span className="error-message">{this.state.errors["ratingMark"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">Comments <span>*</span></span>
                                                        <InputTextarea
                                                            id="textarea"
                                                            rows={3}
                                                            cols={30}
                                                            autoResize={true}
                                                            name="tokenDetails"
                                                            value={ratingObj && ratingObj.commentValue}
                                                            onChange={this.onChangeCommentValue}
                                                        />
                                                    </div>
													<span className="error-message">{this.state.errors["ratingComment"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                                    <div className="required-field">
                                                        (<span>*</span>) required fields
                                                    </div>

                                                    <Button
                                                        className="p-button-primary nw-button nw-button-right token-submit-button" //p-button-primary 
                                                        label="Submit"
                                                        icon="fas fa-check" //fas fa-spinner fa-spin
                                                        onClick={this.onSubmitRating}
                                                        disabled={ tokenList && tokenList.tokenStatus == 10? false:true }
                                                    />
                                                </div>
                                            </div>
                                            :null
                                        }
                                        

                                        <div className="p-col-12 p-xl-12">

                                            <div className="nw-search-view">
                                                <div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Token ID</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.customTokenID }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Issue Date</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && NetiDateUtils.getAnyShortForm(tokenList.createDate, 'DD-MMM-YYYY hh:mm:ss a') }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Contact No.</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.tokenContact }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Application</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.tokenSource }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Module</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Problem Type</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.tokenTypeInfoDTO.categoryName }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Problem Details</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.tokenDetails }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup details">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Attachment</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc attachment-download-button" style={{ display: 'flex', alignItems: 'center'}}>
                                                                <DownloadButton fileName={ tokenList && tokenList.attachmentName } filePath={ tokenList && tokenList.attachmentPath } /> <span className="download-button-text">Attachment</span> 
                                                            </div>
                                                        </div>
                                                    </div>

                                                    { tokenList && tokenList.tokenStatus <= 1?

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Pending Duration</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc demo" id='demo'>
                                                                { this.getRemainTime(tokenList && tokenList.createDate, null) }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :null
                                                    }


                                                    { tokenList && tokenList.tokenStatus == 10?
                                                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                            <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Solved Date</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc demo" id='demo'>
                                                                { tokenList && NetiDateUtils.getAnyShortForm(tokenList.solveDate, 'DD-MMM-YYYY hh:mm:ss a') }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Solved Duration</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc demo" id='demo'>
                                                                { this.getRemainTime(tokenList && tokenList.createDate, tokenList && tokenList.solveDate ) }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Solved Note</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc demo" id='demo'>
                                                                { tokenList && tokenList.solveMessage }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :null
                                                    }
                                                        


                                                </div>
                                            </div>
                                        </div>

                                        {/* { tokenList && tokenList.tokenStatus == 10?

                                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                                <div className="required-field">
                                                    (<span>*</span>) required fields
                                                </div>

                                                <Button
                                                    className="p-button-primary nw-button nw-button-right token-submit-button" //p-button-primary 
                                                    label="Submit"
                                                    icon="fas fa-check" //fas fa-spinner fa-spin
                                                    onClick={this.onSubmitRating}
                                                    disabled={ tokenList && tokenList.tokenStatus == 10? false:true }
                                                />
                                            </div>
                                            :null
                                        } */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </Dialog>

			</div>
		</div>;
	}
}