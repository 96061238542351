import React, { Component } from 'react';

import { TabView, TabPanel } from 'primereact/tabview';
import { WalletBalanceDeposit } from './WalletBalanceDeposit';
import { WalletBalanceWithdraw } from './WalletBalanceWithdraw';
import { WalletBalanceTransfer } from './WalletBalanceTransfer';
export class WalletMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        // let walletMainTabview = document.getElementsByClassName('p-tabview-selected');
        // let TabviewWidth = document.getElementsByClassName('p-unselectable-text');

        // console.log("walletMainTabview", walletMainTabview);
        // console.log("TabviewWidth", TabviewWidth);


        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView className="wallet-main-tabview">
                            <TabPanel header="Deposit" >
                                <WalletBalanceDeposit />
                            </TabPanel>
                            {/* <TabPanel header="Withdraw">
                                <WalletBalanceWithdraw />
                            </TabPanel>
                            <TabPanel header="Transfer">
                                <WalletBalanceTransfer />
                            </TabPanel> */}
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }
}