import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Growl } from "primereact/growl";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';

const hundred = 100;
export class ProductInfoUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            updateProductInfoErr: {},
            productInfoUpdateObj: {
                productID: props.rowDataInfo.productID,
                productDefaultCode: props.rowDataInfo.productDefaultCode,
                productName: props.rowDataInfo.productName,
                productNote: props.rowDataInfo.productNote,
                // remainingStock: props.rowDataInfo.remainingStock,
                productEnableStatus: props.rowDataInfo.productEnableStatus,
                productAdditionalStatus: 0,
                salesPrice: props.rowDataInfo.salesPrice,
                distributePercentCost: props.rowDataInfo.distributePercentCost,
                distributePercentFixed: props.rowDataInfo.distributePercentFixed,
                distributePercentLevel: props.rowDataInfo.distributePercentLevel,
                percentTax: props.rowDataInfo.percentTax,
                percentVat: props.rowDataInfo.percentVat,
                genCodeStatus: props.rowDataInfo.genCodeStatus,
                userBasicInfoDTO: {
                    netiID: this.props.rowDataInfo.userBasicInfoDTO.netiID,
                },
                productTypeInfoDTO: {
                    coreCategoryID: this.props.rowDataInfo.productTypeInfoDTO.coreCategoryID,
                },
                additionalInfoDTO: {
                    productAdditionalID: 0,
                    bijoySaltNumber: "",
                    bijoyMultiNumber: ""
                }
            },
            togleBtnActive: false
        }

        this.onChangeUpdateProductDetails = this.onChangeUpdateProductDetails.bind(this);
        this.onChangeUpdateSalesPrice = this.onChangeUpdateSalesPrice.bind(this);
        this.onChangeUpdateCostPercent = this.onChangeUpdateCostPercent.bind(this);
        this.onChangeUpdateFixedPercent = this.onChangeUpdateFixedPercent.bind(this);
        this.onChangeUpdateLevelPercent = this.onChangeUpdateLevelPercent.bind(this);
        this.onChangeUpdateTaxPercent = this.onChangeUpdateTaxPercent.bind(this);
        this.onChangeUpdateVatPercent = this.onChangeUpdateVatPercent.bind(this);
        this.onChangeUpdateGenerateCode = this.onChangeUpdateGenerateCode.bind(this);
        this.onChangeUpdateStatus = this.onChangeUpdateStatus.bind(this);

        this.onChangeAdditionalInfo = this.onChangeAdditionalInfo.bind(this);
        this.onChangeUpdateBijoySellNumb = this.onChangeUpdateBijoySellNumb.bind(this);
        this.onChangeUpdateBijoyMultiNumb = this.onChangeUpdateBijoyMultiNumb.bind(this);
        this.onUpdateProductInfo = this.onUpdateProductInfo.bind(this);

        this.hideProductUpdateDialog = this.hideProductUpdateDialog.bind(this);
        this.fetchProductList = this.fetchProductList.bind(this);

        this.adminPointService = new AdminPointService();
        this.validatorUtility = new ValidatorUtility();

    }

    clearInputError = (name) =>{
        let { updateProductInfoErr } = this.state;
        updateProductInfoErr[name] = ""
        this.setState({ updateProductInfoErr });
    }

    onChangeUpdateProductDetails(value) {
        let { productInfoUpdateObj } = this.state;
        productInfoUpdateObj.productNote = value;
        this.setState({ productInfoUpdateObj });
        this.clearInputError('productDetails');
    }

    onChangeUpdateSalesPrice(value) {
        let { productInfoUpdateObj } = this.state;
        productInfoUpdateObj.salesPrice = value;
        this.setState({ productInfoUpdateObj });
        this.clearInputError('salesPrice');
    }

    onChangeUpdateCostPercent(value) {
        let { productInfoUpdateObj } = this.state;
        value = (+(value) + +(productInfoUpdateObj.distributePercentFixed) + +(productInfoUpdateObj.distributePercentLevel)) <= hundred ? value : '';
        productInfoUpdateObj.distributePercentCost = value;
        this.setState({ productInfoUpdateObj });
        this.clearInputError('costPercent');
    }

    onChangeUpdateFixedPercent(value) {
        let { productInfoUpdateObj } = this.state;
        value = (+(productInfoUpdateObj.distributePercentCost) + +(value) + +(productInfoUpdateObj.distributePercentLevel)) <= hundred ? value : '';
        productInfoUpdateObj.distributePercentFixed = value;
        this.setState({ productInfoUpdateObj });
        this.clearInputError('fixedPercent');
    }

    onChangeUpdateLevelPercent(value) {
        let { productInfoUpdateObj } = this.state;
        value = (+(productInfoUpdateObj.distributePercentCost) + +(productInfoUpdateObj.distributePercentFixed) + +(value)) <= hundred ? value : '';
        productInfoUpdateObj.distributePercentLevel = value;
        this.setState({ productInfoUpdateObj });
        this.clearInputError('levelPercent');
    }

    onChangeUpdateTaxPercent(value) {
        let { productInfoUpdateObj } = this.state;
        productInfoUpdateObj.percentTax = value;
        this.setState({ productInfoUpdateObj });
        this.clearInputError('taxPercent');
    }

    onChangeUpdateVatPercent(value) {
        let { productInfoUpdateObj } = this.state;
        productInfoUpdateObj.percentVat = value;
        this.setState({ productInfoUpdateObj });
        this.clearInputError('vatPercent');
    }

    onChangeUpdateGenerateCode(value) {
        let { productInfoUpdateObj } = this.state;
        productInfoUpdateObj.genCodeStatus = value;
        this.setState({ productInfoUpdateObj });
    }

    onChangeUpdateStatus(value) {
        let { productInfoUpdateObj } = this.state;
        productInfoUpdateObj.productEnableStatus = value;
        this.setState({ productInfoUpdateObj });
    }

    onChangeAdditionalInfo(value) {
        let { productInfoUpdateObj } = this.state;
        productInfoUpdateObj.productAdditionalStatus = value;

        this.setState({
            productInfoUpdateObj,
            togleBtnActive: !this.state.togleBtnActive
        });
    }

    onChangeUpdateBijoySellNumb(value) {
        let { productInfoUpdateObj } = this.state;
        productInfoUpdateObj.additionalInfoDTO.bijoySaltNumber = value;
        this.setState({ productInfoUpdateObj });
    }

    onChangeUpdateBijoyMultiNumb(value) {
        let { productInfoUpdateObj } = this.state;
        productInfoUpdateObj.additionalInfoDTO.bijoyMultiNumber = value;
        this.setState({ productInfoUpdateObj });
    }

    hideProductUpdateDialog() {
        this.props.productUpdateDialog();
    }

    fetchProductList() {
        this.props.productList();
    }

    onUpdateProductInfo() {
        let { productInfoUpdateObj } = this.state;

        if (productInfoUpdateObj.productAdditionalStatus == true) {
            productInfoUpdateObj.productAdditionalStatus = 1;
        } else if (productInfoUpdateObj.productAdditionalStatus == false) {
            productInfoUpdateObj.productAdditionalStatus = 0;
        }

        if (this.updateProductInfoError()) {
            this.setState({ productInfoUpdateObj });
            this.adminPointService.updateProductInfo(productInfoUpdateObj)
                .then(res => {
                    if (res.status === 202) {
                        this.growl.show({ severity: "success", summary: "Success Message", detail: "Successfully Updated", life: 800 });
                        setTimeout(() => {
                            this.hideProductUpdateDialog();
                        }, 800);
                        this.fetchProductList();
                    } else {
                        console.log("error res", "No Data Found")
                    }
                })
        }
    }

    updateDialogDiscard = () => {
        this.hideProductUpdateDialog();
    }

    updateProductInfoError = () => {

        let { updateProductInfoErr, productInfoUpdateObj } = this.state;
        let formIsValid = true;

        if (productInfoUpdateObj.productNote === '') {
            formIsValid = false;
            updateProductInfoErr["productDetails"] = "Product Details can't left empty.";
        }
        if (productInfoUpdateObj.salesPrice === '') {
            formIsValid = false;
            updateProductInfoErr["salesPrice"] = "Sales Price can't left empty.";
        }

        if (productInfoUpdateObj.percentVat === '') {
            formIsValid = false;
            updateProductInfoErr["vatPercent"] = "Vat Percent can't left empty.";
        }

        if (productInfoUpdateObj.genCodeStatus === '') {
            formIsValid = false;
            updateProductInfoErr["generateCode"] = "Generate Code can't left empty.";
        }
        if (productInfoUpdateObj.productAdditionalStatus == true) {

            if (productInfoUpdateObj.bijoySaltNumber === '') {
                formIsValid = false;
                updateProductInfoErr["bijoySellNumb"] = "Bijoy Sell Number can't left empty.";
            }
            if (productInfoUpdateObj.bijoyMultiNumber === '') {
                formIsValid = false;
                updateProductInfoErr["bijoyMultiNumb"] = "Bijoy Multi Number can't left empty.";
            }
        }
        if (productInfoUpdateObj.distributePercentCost == '') {
            formIsValid = false;
            updateProductInfoErr["costPercent"] = "Cost Percent can't left empty.";
        }
        if (productInfoUpdateObj.distributePercentFixed == '') {
            formIsValid = false;
            updateProductInfoErr["fixedPercent"] = "Fixed Percent can't left empty.";
        }
        if (productInfoUpdateObj.distributePercentLevel == '') {
            formIsValid = false;
            updateProductInfoErr["levelPercent"] = "Level Percent can't left empty.";
        }

        // this.state.productInfoUpdateObj.percentTax
        if (productInfoUpdateObj.percentTax === '') {
            formIsValid = false;
            updateProductInfoErr["taxPercent"] = "Tax Percent can't left empty.";
        }


        this.setState({ updateProductInfoErr });
        return formIsValid;
    }

    render() {
        let { productInfoUpdateObj } = this.state;
        let vatAmountCal = (productInfoUpdateObj.salesPrice * this.state.productInfoUpdateObj.percentVat) / 100;
        let costPercentCal = (productInfoUpdateObj.salesPrice * productInfoUpdateObj.distributePercentCost) / 100
        let fixedPercentCal = (productInfoUpdateObj.salesPrice * productInfoUpdateObj.distributePercentFixed) / 100
        let levelPercentCal = (productInfoUpdateObj.salesPrice * productInfoUpdateObj.distributePercentLevel) / 100
        let revenueParcentCal = +(productInfoUpdateObj.distributePercentCost) + +(productInfoUpdateObj.distributePercentFixed) + +(productInfoUpdateObj.distributePercentLevel);
        let totalRevenueCal = (productInfoUpdateObj.salesPrice * productInfoUpdateObj.distributePercentCost) / 100 + (productInfoUpdateObj.salesPrice * productInfoUpdateObj.distributePercentFixed) / 100 + (productInfoUpdateObj.salesPrice * productInfoUpdateObj.distributePercentLevel) / 100
        let incomeTaxPercentCal = (+(fixedPercentCal + levelPercentCal) * productInfoUpdateObj.percentTax) / 100



        return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <div className="nw-form">
                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="p-grid nw-form-body">
                                    <div className="p-col-12">
                                        <Growl ref={(el) => this.growl = el} />


                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Product Type</span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.props.rowDataInfo.productTypeInfoDTO.categoryName}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Product Name</span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.props.rowDataInfo.productName}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Default Code</span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.props.rowDataInfo.productDefaultCode}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Owner Neti ID</span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.props.rowDataInfo.userBasicInfoDTO.customNetiID}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Owner Name</span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.props.rowDataInfo.userBasicInfoDTO.fullName}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Product Details <span>*</span></span>
                                                <InputTextarea
                                                    placeholder='Enter Product Details'
                                                    name="productDetails"
                                                    value={this.state.productInfoUpdateObj.productNote || ''}
                                                    onChange={(e) => this.onChangeUpdateProductDetails(e.target.value)}
                                                    autoWidth={false}
                                                />
                                               
                                            </div> 
                                            <span className='error-message'>{this.state.updateProductInfoErr["productDetails"]}</span>
                                        </div>

                                        {/* <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Remaining Stock <span>*</span></span>
                                                <InputText
                                                    placeholder='Enter Remaining Stock'
                                                    name="remainingStock"
                                                    value={this.state.productInfoUpdateObj.remainingStock}
                                                    onChange={(e) => this.onChangeUpdateRemainingStock(e.target.value)}
                                                />
                                            </div>
                                        </div> */}
                                    </div>

                                    {/* new Design */}

                                    <div className="p-col-12">
                                        <div class="from-group-heading"><span>Price Configuration</span></div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="calculation-box-wrapper">
                                            <div className="p-md-4 p-sm-12">
                                                <div className="calculation-box plusSign">
                                                    <div className="calculation-box-title">
                                                        <span class="p-inputgroup-addon">Sales Price <span>*</span></span>
                                                    </div>
                                                    <div className="calculation-box-inner-wrapper">
                                                        <div className="calculation-box-amount">
                                                        {this.validatorUtility.currencyFormatter(this.state.productInfoUpdateObj.salesPrice)}
                                                    </div>
                                                        <div className="calculation-box-field inputTkSign">
                                                            <span className="input-label">enter sales price</span>
                                                            <InputText
                                                                placeholder='Enter Sales Price'
                                                                keyfilter="pint"
                                                                name="salesPrice"
                                                                value={this.state.productInfoUpdateObj.salesPrice || ''}
                                                                onChange={(e) => this.onChangeUpdateSalesPrice(e.target.value)}
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className='error-message'>{this.state.updateProductInfoErr["salesPrice"]}</span>
                                            </div>
                                            <div className="p-md-4 p-sm-12">
                                                <div className="calculation-box">
                                                    <div className="calculation-box-title">
                                                        <span class="p-inputgroup-addon">Vat Amount <span>*</span></span>
                                                    </div>
                                                    <div className="calculation-box-inner-wrapper">
                                                        <div className="calculation-box-amount">
                                                        {this.validatorUtility.currencyFormatter(vatAmountCal)}
                                                    </div>
                                                        <div className="calculation-box-field percentSign">
                                                            <span className="input-label">enter vat percent</span>
                                                            <InputText
                                                                placeholder='Enter Vat Percent (%)'
                                                                keyfilter="num"
                                                                name="vatPercent"
                                                                value={this.state.productInfoUpdateObj.percentVat}
                                                                onChange={(e) => this.onChangeUpdateVatPercent(e.target.value)}
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className='error-message'>{this.state.updateProductInfoErr["vatPercent"]}</span>

                                            </div>
                                            <div className="p-md-4 p-sm-12">
                                                <div className="calculation-total-box">
                                                    <div className="calculation-box-inner-wrapper">
                                                        <div className="calculation-box-title text-center">
                                                            <span class="p-inputgroup-addon">Total Price Including VAT</span>
                                                        </div>
                                                        <div className="calculation-box-amount">
                                                        {this.validatorUtility.currencyFormatter(+(this.state.productInfoUpdateObj.salesPrice) + vatAmountCal)}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12">
                                        <div class="from-group-heading"><span>Revenue Configuration</span></div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="calculation-box-wrapper">
                                            <div className="p-md-6 p-sm-12">
                                                <div className="calculation-box plusSign">
                                                    <div className="calculation-box-title">
                                                        <span class="p-inputgroup-addon">Cost Amount <span>*</span></span>
                                                    </div>
                                                    <div className="calculation-box-inner-wrapper">
                                                        <div className="calculation-box-amount">
                                                            {this.validatorUtility.currencyFormatter(costPercentCal)}
                                                        </div>
                                                        <div className="calculation-box-field percentSign">
                                                            <span className="input-label">enter cost percent</span>
                                                            <InputText
                                                                placeholder='Enter Cost Percent (%)'
                                                                name="costPercent"
                                                                keyfilter="num"
                                                                value={this.state.productInfoUpdateObj.distributePercentCost || ''}
                                                                onChange={(e) => this.onChangeUpdateCostPercent(e.target.value)}
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className='error-message'>{this.state.updateProductInfoErr["costPercent"]}</span>
                                            </div>

                                            <div className="p-md-6 p-sm-12">
                                                <div className="calculation-box plusSign">
                                                    <div className="calculation-box-title">
                                                        <span class="p-inputgroup-addon">Fixed Amount <span>*</span></span>
                                                    </div>
                                                    <div className="calculation-box-inner-wrapper">
                                                        <div className="calculation-box-amount">
                                                            {this.validatorUtility.currencyFormatter(fixedPercentCal)}
                                                        </div>
                                                        <div className="calculation-box-field percentSign">
                                                            <span className="input-label">enter fixed percent</span>
                                                            <InputText
                                                                placeholder='Enter Fixed Percent'
                                                                keyfilter="num"
                                                                name="fixedPercent"
                                                                value={this.state.productInfoUpdateObj.distributePercentFixed || ''}
                                                                onChange={(e) => this.onChangeUpdateFixedPercent(e.target.value)}
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className='error-message'>{this.state.updateProductInfoErr["fixedPercent"]}</span>
                                            </div>

                                            <div className="p-md-6 p-sm-12">
                                                <div className="calculation-box">
                                                    <div className="calculation-box-title">
                                                        <span class="p-inputgroup-addon">Level Amount <span>*</span></span>
                                                    </div>
                                                    <div className="calculation-box-inner-wrapper">
                                                        <div className="calculation-box-amount">
                                                            {this.validatorUtility.currencyFormatter(levelPercentCal)}
                                                        </div>
                                                        <div className="calculation-box-field percentSign">
                                                            <span className="input-label">enter level percent</span>
                                                            <InputText
                                                                placeholder='Enter Level Percent (%)'
                                                                keyfilter="num"
                                                                name="levelPercent"
                                                                value={this.state.productInfoUpdateObj.distributePercentLevel || ''}
                                                                onChange={(e) => this.onChangeUpdateLevelPercent(e.target.value)}
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className='error-message'>{this.state.updateProductInfoErr["levelPercent"]}</span>
                                            </div>

                                            <div className="p-md-6 p-sm-12">
                                                <div className="calculation-total-box">
                                                    <div className="calculation-box-inner-wrapper">
                                                        <div className="calculation-box-title">
                                                            <span class="p-inputgroup-addon">Revenue Percent</span>
                                                        </div>
                                                        <div className="calculation-box-amount no-tkSign">
                                                        {revenueParcentCal}
                                                    </div>
                                                        <div className="top-position">
                                                            <div className="calculation-box-amount">
                                                            {this.validatorUtility.currencyFormatter(totalRevenueCal)}
                                                        </div>
                                                        </div>
                                                        <div className="left-percent">
                                                            <span>{hundred - revenueParcentCal}% Left</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12">
                                        <div class="from-group-heading"><span>Income Tax</span></div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="calculation-box-wrapper">
                                            <div className="p-md-6 p-sm-12">
                                                <div className="calculation-box ">
                                                    <div className="calculation-box-title">
                                                        <span class="p-inputgroup-addon">Income Tax Percent <span>*</span></span>
                                                    </div>
                                                    <div className="calculation-box-inner-wrapper">
                                                        <div className="calculation-box-amount">
                                                        {this.validatorUtility.currencyFormatter(incomeTaxPercentCal)}
                                                    </div>
                                                        <div className="calculation-box-field percentSign">
                                                            <span className="input-label">enter income cost percent</span>
                                                            <InputText
                                                                placeholder='Enter Tax Percent (%)'
                                                                name="taxPercent"
                                                                keyfilter="num"
                                                                value={this.state.productInfoUpdateObj.percentTax}
                                                                onChange={(e) => this.onChangeUpdateTaxPercent(e.target.value)}
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className='error-message'>{this.state.updateProductInfoErr["taxPercent"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* new Design */}


                                    {/* <div className="p-col-12">
                                        <div className="formControl">
                                            <label>Sales Price <span>*</span></label>
                                            <InputText
                                                placeholder='Enter Sales Price'
                                                keyfilter="num"
                                                name="salesPrice"
                                                value={this.state.productInfoUpdateObj.salesPrice || ''}
                                                onChange={(e) => this.onChangeUpdateSalesPrice(e.target.value)}
                                                autoWidth={false}
                                            />
                                            <span className='error-message'>{this.state.updateProductInfoErr["salesPrice"]}</span>
                                        </div>
                                    </div> */}

                                    {/* <div className="p-col-12">
                                        <div className="formControl">
                                            <label>Cost Percent (%) <span>*</span></label>
                                            <InputText
                                                placeholder='Enter Cost Percent (%)'
                                                name="costPercent"
                                                keyfilter="num"
                                                value={this.state.productInfoUpdateObj.distributePercentCost || ''}
                                                onChange={(e) => this.onChangeUpdateCostPercent(e.target.value)}
                                                autoWidth={false}
                                            />
                                            <span className='error-message'>{this.state.updateProductInfoErr["costPercent"]}</span>
                                        </div>
                                    </div> */}

                                    {/* <div className="p-col-12">
                                        <div className="formControl">
                                            <label>Fixed Percent (%) <span>*</span></label>
                                            <InputText
                                                placeholder='Enter Fixed Percent'
                                                keyfilter="num"
                                                name="fixedPercent"
                                                value={this.state.productInfoUpdateObj.distributePercentFixed || ''}
                                                onChange={(e) => this.onChangeUpdateFixedPercent(e.target.value)}
                                                autoWidth={false}
                                            />
                                            <span className='error-message'>{this.state.updateProductInfoErr["fixedPercent"]}</span>
                                        </div>
                                    </div> */}

                                </div>

                                <div className="p-grid p-col-12">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon addon-no-style">Generate Code <span>*</span></span>
                                            <div className="nw-inputgroup-desc desc-no-style">
                                                <div className="input-radio-button">
                                                    <div className="radio-button-inside">
                                                        <RadioButton
                                                            value={1}
                                                            inputId="rb1"
                                                            name='generateCode'
                                                            onChange={event => this.onChangeUpdateGenerateCode(event.value)}
                                                            checked={this.state.productInfoUpdateObj.genCodeStatus == 1}
                                                        />
                                                        <label htmlFor="rb1" className="p-radiobutton-label">Yes</label>
                                                    </div>

                                                    <div className="radio-button-inside">
                                                        <RadioButton
                                                            value={0}
                                                            name='generateCode'
                                                            inputId="rb2"
                                                            onChange={event => this.onChangeUpdateGenerateCode(event.value)}
                                                            checked={this.state.productInfoUpdateObj.genCodeStatus == 0}
                                                        />
                                                        <label htmlFor="rb1" className="p-radiobutton-label">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon addon-no-style">Status <span>*</span></span>
                                            <div className="nw-inputgroup-desc desc-no-style">
                                                <div className="input-radio-button">
                                                    <div className="radio-button-inside">
                                                        <RadioButton
                                                            value={1}
                                                            inputId="rb1"
                                                            onChange={event => this.onChangeUpdateStatus(event.value)}
                                                            checked={this.state.productInfoUpdateObj.productEnableStatus == 1}
                                                        />
                                                        <label htmlFor="rb1" className="p-radiobutton-label">Enable</label>
                                                    </div>

                                                    <div className="radio-button-inside">
                                                        <RadioButton
                                                            value={0}
                                                            inputId="rb2"
                                                            onChange={event => this.onChangeUpdateStatus(event.value)}
                                                            checked={this.state.productInfoUpdateObj.productEnableStatus == 0}
                                                        />
                                                        <label htmlFor="rb2" className="p-radiobutton-label">Disable</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon addon-no-style">Additional Info <span></span></span>

                                            <div className="nw-inputgroup-desc desc-no-style custom-inputswitch">
                                                <span className="p-radiobutton-label">No</span>
                                                <InputSwitch checked={this.state.productInfoUpdateObj.productAdditionalStatus} onChange={event => this.onChangeAdditionalInfo(event.value)} />
                                                {this.state.togleBtnActive === true ? <span className="p-radiobutton-label" style={{ color: '#0099FF' }}>Yes</span> : <span className="p-radiobutton-label">Yes</span>}
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.productInfoUpdateObj.productAdditionalStatus == true ?

                                        <div className="p-col-12">

                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Bijoy Sell Number  <span>*</span></span>
                                                <InputText
                                                    placeholder='Enter Bijoy Sell Number'
                                                    name="bijoySellNumb"
                                                    value={this.state.productInfoUpdateObj.bijoySaltNumber || ''}
                                                    onChange={(e) => this.onChangeUpdateBijoySellNumb(e.target.value)}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.updateProductInfoErr["bijoySellNumb"]}</span>
                                            <br />

                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Bijoy Multi Number  <span>*</span></span>
                                                <InputText
                                                    placeholder='Enter Bijoy Multi Number'
                                                    name="bijoyMultiNumb"
                                                    value={this.state.productInfoUpdateObj.bijoyMultiNumber || ''}
                                                    onChange={(e) => this.onChangeUpdateBijoyMultiNumb(e.target.value)}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.updateProductInfoErr["bijoyMultiNumb"]}</span>
                                        </div>
                                        : ''}

                                    
                                </div>
                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                    <Button
                                        label="Discard"
                                        icon="fas fa-times"
                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                        onClick={this.updateDialogDiscard}
                                    />
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                        label="Update"
                                        onClick={event => this.onUpdateProductInfo(event)}
                                        icon="fas fa-check"
                                    />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <div className="p-col-12 p-lg-1 p-xl-1" />
            </div>

        )
    }
}