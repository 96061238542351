import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';
import { UserOtherAccessService } from '../UserOtherAccessService';
import { downloadJasperFile } from '../../utils/JasperFileDownloader'

export class PartnerPointService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
        this.userOtherAccessService = new UserOtherAccessService();
    }

    getUserPurchaseCode(purchaseCode) {
        let uri = this.baseURIHolder.getUser() + '/purchase/code/by/code?code=' + purchaseCode;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    checkExitsUrl(urlName) {
        let uri = this.baseURIHolder.getPartner() + '/domain/check/by/domain-name?domainName=' + urlName;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getDomainlist(param) {
        let uri = this.baseURIHolder.getPartner() + '/domain-hosting/by/user?orderType=' + param;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }


    createNewEmDetailsInfo(emDetailsInfo) {
        let uri = this.baseURIHolder.getUser() + '/em/order/new';
        return this.Auth.postFetch(uri, emDetailsInfo)
            .catch((error) => console.log('error', error));
    }

    getEmRate(paymentType, packageName) {
        let uri = this.baseURIHolder.getUser() + `/em/rate?paymentType=${paymentType}&packageName=${packageName}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEMOrderList() {
        let uri = this.baseURIHolder.getUser() + '/em/get/all';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createNewDomainOrder(orderObj) {
        let uri = this.baseURIHolder.getPartner() + '/domain/order/new';
        return this.Auth.postFetch(uri, orderObj)
            .catch((error) => console.log('error', error));
    }

    createNewHostingOrder(orderObj) {
        let uri = this.baseURIHolder.getPartner() + '/hosting/order/new';
        return this.Auth.postFetch(uri, orderObj)
            .catch((error) => console.log('error', error));
    }

    createNewHostingOrder(orderObj) {
        let uri = this.baseURIHolder.getPartner() + '/hosting/order/new';
        return this.Auth.postFetch(uri, orderObj)
            .catch((error) => console.log('error', error));
    }
    createPointAssign(assignInfo) {
        let uri = this.baseURIHolder.getPartner() + '/assign/point';
        return this.Auth.postFetch(uri, assignInfo)
            .catch((error) => console.log('error', error));
    }
    payPrepaidBillByPartner(prepaidBillInfo) {
        let uri = this.baseURIHolder.getPartner() + '/prepaid/bill/pay';
        return this.Auth.postFetch(uri, prepaidBillInfo)
            .catch((error) => console.log('error', error));
    }

    getPostpaidBillSummary(edumanID) {
        let uri = this.baseURIHolder.getPartner() + '/postpaid/bill/summary?emID=' + edumanID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    payPostBillByPartner(postpaidBillInfo) {
        let uri = this.baseURIHolder.getPartner() + '/postpaid/bill/pay';
        return this.Auth.postFetch(uri, postpaidBillInfo)
            .catch((error) => console.log('error', error));
    }

    getProductTypeByDefCode(typeDefCode) {
        let uri = this.baseURIHolder.getUser() + '/products/by/type?typeDefCode=' + typeDefCode;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    checkPartnerMessageRequest(transferRequestInfo) {
        let uri = this.baseURIHolder.getPartner() + '/message/transfer/check';
        return this.Auth.postFetch(uri, transferRequestInfo)
            .catch((error) => console.log('error', error));
    }

    sendPartnerMessageTransferRequest(transferMessageInfo) {
        let uri = this.baseURIHolder.getPartner() + '/message/transfer';
        return this.Auth.postFetch(uri, transferMessageInfo)
            .catch((error) => console.log('error', error));
    }

    getMessageTransferLogs(messageLog) {
        let uri = this.baseURIHolder.getPartner() + '/message/transfer/logs';
        return this.Auth.postFetch(uri, messageLog)
            .catch((error) => console.log('error', error));
    }

    getInstituteByPartner() {
        let uri = this.baseURIHolder.getPartner() + '/em/institute/by/partner';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getInstitute() {
        let uri = this.baseURIHolder.getPartner() + '/em/institute/list';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getPayableInstituteByPartner(paymentType) {
        let uri = this.baseURIHolder.getPartner() + `/em/billable/institute/by?paymentType=${paymentType}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getInstituteBalanceByInstitute(InstituteID) {
        let uri = this.baseURIHolder.getUser() + '/em/institute/message/balance/em5/by/instituteID?instituteID=' + InstituteID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getYssPartnerPoints(limit, pageNo) {
        let uri = this.baseURIHolder.getPartner() + '/yss/points?limit=' + limit + '&pageNo=' + pageNo;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    getYssPartnerPointsAllinstitute(limit, pageNo) {
        let uri = this.baseURIHolder.getPartner() + '/yss/points/allinstitute?limit=' + limit + '&pageNo=' + pageNo;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createPartnerYssConfig(yssConfigRequest) {
        let uri = this.baseURIHolder.getPartner() + '/configure/yss';
        return this.Auth.postFetch(uri, yssConfigRequest)
            .catch((error) => console.log('error', error));
    }

    createPartnerYssConfig1(yssConfigRequest) {
        let uri = this.baseURIHolder.getPartner() + '/configure/ysswithoutperchaseCode';
        return this.Auth.postFetch(uri, yssConfigRequest)
            .catch((error) => console.log('error', error));
    }

    addYssDevice(yssDeviceInfo) {
        let uri = this.baseURIHolder.getPartner() + '/yss/device/tag';
        return this.Auth.postFetch(uri, yssDeviceInfo)
            .catch((error) => console.log('error', error));
    }

    getAssignablePointsByPartnerPoint() {
        let accessPath = this.baseURIHolder.getPartner().split('/');
        return this.userOtherAccessService.getAccessablePoints(accessPath[accessPath.length - 1]);
    }

    fetchPartnerUserList() {
        let uri = this.baseURIHolder.getPartner() + '/due/institutes/by-team';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    assignExistingInstituteToEmAdmin(emID, netiID) {
        let uri = this.baseURIHolder.getPartner() + `/em/map/institute?emID=${emID}&netiID=${netiID}`;
        return this.Auth.postFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getInstituteListByUserAdmin(netiID) {
        let uri = this.baseURIHolder.getPartner() + `/em/institutes/by/user-admin?netiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchPartnerInfoList(defaultCode, searchID) {
        let uri = this.baseURIHolder.getPartner() + '/due/institutes/by-parentid?designationCode=' + defaultCode + '&parentAssignID=' + searchID;
        // let uri = this.baseURIHolder.getPartner() + `/em/institutes/by/user-admin?netiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchInstituteFoundInfoList(partnerAssignID) {
        let uri = this.baseURIHolder.getPartner() + '/due/institutes/by-selfid?partnerAssignID=' + partnerAssignID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getPartnerYSSDevicesByUser(taggingStatus) {
        let uri = this.baseURIHolder.getPartner() + `/yss/device/by/user?taggingStatus=${taggingStatus}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getYSSDevicesByRegistrationIds(regIDs) {
        let uri = this.baseURIHolder.getPartner() + '/yss/devices/by/registration-ids';
        return this.Auth.postFetch(uri, regIDs)
            .catch((error) => console.log('error', error));
    }

    getAllYSSDevicesByRegistrationIds(regIDs) {
        let uri = this.baseURIHolder.getPartner() + '/yss/devices/by/registration-ids/all';
        return this.Auth.postFetch(uri, regIDs)
            .catch((error) => console.log('error', error));
    }
    saveEdumanBillDiscount(discountInfo) {
        let uri = this.baseURIHolder.getPartner() + '/eduman/bill/discount/request/save';
        return this.Auth.postFetch(uri, discountInfo)
            .catch((error) => console.log('error', error));
    }

    findEmBillDiscountsByNetiID() {
        let uri = this.baseURIHolder.getPartner() + '/find/eduman/bill/discount/request/by/neti-id';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEdumanCurrentStatusInfo() {
        let uri = this.baseURIHolder.getPartner() + '/team-wise/eduman/current-status-dashboard';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }


    findEmPrePaidBillsSummary(searchObjInfo) {
        let uri = this.baseURIHolder.getPartner() + '/em/prepaid-bills';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    findEmPostPaidBillsSummary(searchObjInfo) {
        let uri = this.baseURIHolder.getPartner() + '/em/postpaid-bills';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    downloadEdumanPrepaidMoneyReceipt(emBillLogId) {
        var Token = JSON.parse(localStorage.getItem('Token'));
        let uri = this.baseURIHolder.getPartnerJasper() + `/em/prepaid/money-receipt/download?emBillLogId=${emBillLogId}&access_token=${Token.accessToken}`;
        return downloadJasperFile(uri);
    }

    downloadEdumanPostpaidMoneyReceipt(emBillLogId) {
        var Token = JSON.parse(localStorage.getItem('Token'));
        let uri = this.baseURIHolder.getPartnerJasper() + `/em/postpaid/money-receipt/download?emBillLogId=${emBillLogId}&access_token=${Token.accessToken}`;
        return downloadJasperFile(uri);
    }

    downloadEdumanInvoice(emDetailsId, emBillGenerateLogIds) {
        var Token = JSON.parse(localStorage.getItem('Token'));
        let uri = this.baseURIHolder.getPartnerJasper() + `/em/invoice/download?emDetailsId=${emDetailsId}&emBillGenerateLogIds=${emBillGenerateLogIds}&access_token=${Token.accessToken}`;
        return downloadJasperFile(uri);
    }

    findEmInvoicesSummary() {
        let uri = this.baseURIHolder.getPartner() + '/em/invoices';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findEmInvoicesSummary() {
        let uri = this.baseURIHolder.getPartner() + '/em/invoices';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getSmsNotifications(category, notifyStatus) {
        console.log('category', category);

        let uri = this.baseURIHolder.getPartner() + `/team-wise/partner/${category}${notifyStatus}/due/details`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    findPrepaidBillCollectionDetails(searchObjInfo) {
        let uri = this.baseURIHolder.getPartner() + '/team-wise/prepaid/collection/details/by';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    findEmBillDetailsByBillLogID(emBillLogID) {
        let uri = this.baseURIHolder.getPartner() + `/bill/by?emBillLogID=${emBillLogID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }


    findPostpaidBillCollectionDetails(searchObjInfo) {
        let uri = this.baseURIHolder.getPartner() + '/team-wise/postpaid/collection/details/by';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    findProductTypeWiseReport(netiID, productType) {
        let uri = this.baseURIHolder.getPartner() + `/report/product-wise?netiID=${netiID}&productType=${productType}`;
        return this.Auth.getFetch(uri).catch((error) => console.log('error', error));
    }

    findProductPurchaseDetails(searchObjInfo) {
        let uri = this.baseURIHolder.getPartner() + '/team-wise/product/purchase/summary/by';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    findPrepaidDepositSummary(searchObjInfo) {
        let uri = this.baseURIHolder.getPartner() + '/report/pre-paid/deposit';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    findPartnerDateWiseIndividualIncome(searchObjInfo) {
        let uri = this.baseURIHolder.getPartner() + '/report/income/individual';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    findPartnerTeamWisePurchase(searchObjInfo, productType) {
        let uri = this.baseURIHolder.getPartner() + `/report/team/purchase?productType=${productType}`;
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    findPostpaidCollectionSummary(searchObjInfo) {
        let uri = this.baseURIHolder.getPartner() + '/report/post-paid/collection ';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log('error', error));
    }
    
    findPartnerMonthWiseIndividualIncome(searchObjInfo, duration) {
        let uri = this.baseURIHolder.getPartner() + `/report/month-wise/income/individual?reportType=${duration}`;
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    fetchPartnerPerformance(dateRange){
        let uri = this.baseURIHolder.getPartner() + '/report/partner/performance';
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    fetchPartnerProductList(){
        let uri = this.baseURIHolder.getPartner() + '/report/product-wise/revenue';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

}