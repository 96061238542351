
import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { EmUserPointService } from '../../service/emUserPoint/EmUserPointService';
import { GuestPointService } from '../../service/guestAccessPoint/GuestPointService';
import { ErrorMessageView } from '../../components/common/ErrorMessageView';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StudentPortal } from "../../service/emUserPoint/StudentPortal";
import { Dropdown } from "primereact/dropdown";
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { TabView } from 'primereact/tabview';
import { BaseURIHolder } from '../../utils/BaseURIHolder';

let Logo = require('./img/bkash.png')
let Ssl = require('./img/ssl.png')
let Nagad =  require('./img/nagad.png')
let Tap = require('./img/tap.jpg');
let user = require('./img/users.png')



let dialogStatus = false;
export class MarchantPoint extends Component {


  constructor(props) {
    super(props)
    this.state = {
        visible: false,
        otpCodeError: {},
        errors:{},
        errorMsgVisible: false,
        topProgressBar: false,
        errorMsgBody: '',
        OTPcode: '',
        customUserID: '',
        instituteID: '',
        onPay: false,
        payData: '',
        unpaidInvoiceData: [],
        PaidInvoiceData: [],
        show: "none",
        onPaymentoption: false,
        message: "",
        invoiceId: '',
        fatherName:'',
        motherName: '',
        studentName: '',
        stId: '',
        paidHistroyVisiable: false,
        paidpayabledata: '',
        checkedRetriveObj: {},
        basicInfo: '',
        onProcessVisible: false
    }
    this.emUserPointService = new EmUserPointService();
    this.guestPointService = new GuestPointService();
    this.studentPortal = new StudentPortal();
    this.netiFileHandler = new NetiFileHandler();
}

onChangeStudenId = (e)  => {
    let { errors } = this.state;
    this.setState({ customUserID: e.target.value });
    errors["customUserID"] = "";
    this.setState({errors})

}
onChangeinstituteId = (e)  => {
    let { errors } = this.state;
    this.setState({ instituteID: e.target.value });
    errors["instituteID"] = "";
    this.setState({ errors });
}


viewDialog = (rowData) => {
    this.setState({ visible: true, viewDetailsObject: rowData });
}
paymentOption = (rowData) => {
    this.setState({payData: rowData.payableAmount + rowData.edumanCharge})
    this.setState({onPaymentoption: true});
    this.setState({invoiceId: rowData.payslipId})
    this.setState({show: "block"})
}


onHide = (event) => {
    this.setState({
        visible: false,
        onPay: false,
        onProcessVisible: false
    })
}

onChangeOTPcode = (e) => {
    this.setState({
        OTPcode: e.target.value
    });
}

onProcessBtn = () => {
   let data ;
    if(this.handleError()) {
        this.setState({ topProgressBar: true, errorMsgVisible: false })
        this.paidHistorycallBysignleStudent()
        this.studentPortal
        .fetchfindunPaidInvoiceByInstituteStudentId(
            (this.state.instituteID +  this.state.customUserID)
            
        )
        .then(res => {
            if (res.status === 200) {
                return res.json().then(body => {

                    this.setState({ topProgressBar: false, errorMsgVisible: false })
                    this.setState({basicInfo:body.item})


                    if(body.item && body.item[0].responseCode == "200"){

                        this.setState({unpaidInvoiceData: body.item});
                        this.setState({basicInfo: body.item[0]})

                        let invoiceList = body.item.map( (items) => ({ 
                            "insvoiceID": items.payslipId
                        }));
                            this.studentPortal
                            .fetchpayemntHostoryByListOfInvoiceIdForpaymentButtondisable((invoiceList))
                                .then( res => {
                                    if (res.status === 200) {
                                        return res.json().then( body => {
                                            this.setState({paidpayabledata: body})
                                        })
                                    }
                                }).catch(e => {
                                    console.log(e)
                                })

                    } else {
                        this.setState({ 
                            unpaidInvoiceData: '',
                            topProgressBar: false, errorMsgVisible: true, 
                            errorMsgBody: 'NO data found' 
                    })
                    }
               
                })
              }
            }).catch(error => this.setState({ 
                    topProgressBar: false, errorMsgVisible: true, 
                    errorMsgBody: 'NO payable payslip found', 
                    unpaidInvoiceData: '',
            })
        )
                          
    } else {
        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "error something" })
    }

}

paidHistorycallBysignleStudent = () => {
    this.studentPortal
    .fetchfindPaidInvoiceByInstituteStudentId( (this.state.instituteID +  this.state.customUserID))
    .then( respose =>  respose.json() )
    .then( body => {
        this.setState({PaidInvoiceData: body.item})
    }).catch( error => {
        console.log(error)
    })

    
}


handleError = () => {
    let { errors, customUserID, instituteID } = this.state;
    let formIsValid = true;

        if (customUserID === "") {
            formIsValid = false;
            errors["customUserID"] = "Student ID can't be left empty";
        }
        if (instituteID === "") {
            formIsValid = false;
            errors["instituteID"] = "Institute ID can't be left empty";
        }

    this.setState({ errors });
    return formIsValid;
  }

  
  downloadexportPdf = (e) => {
     this.studentPortal.fetchfindPayslipDownload(e.payslipId, this.state.instituteID, this.state.customUserID)

    }


  detailsBodyforPaid = (rowData) => {
    return <div className='text-center'>

             <Button
                 className="nw-action-button edit"
                 icon="fas fa-eye"
                 tooltip="Edit"
                 onClick={(e) => this.viewDialog(rowData)}
             />
             <Button
                 icon="fas fa-download"
                 className="nw-action-button edit"
                 onClick={(e) => this.downloadexportPdf(rowData)}
             />        
    </div>
    
}
  detailsBody = (rowData) => {
      
    let paid = false;
    if(this.state.paidpayabledata) {
        for (const key of Object.keys(this.state.paidpayabledata)) {
            if(key == rowData.payslipId){
                paid = true;
            }
        }
    }
    
    return <div className=''>
       {
           paid === true ?
           <div>
                <Button
                    className="nw-action-button edit"
                    icon="fas fa-eye"
                    tooltip="Edit"
                    onClick={(e) => this.viewDialog(rowData)}
                />
                <Button
                    icon="fas fa-download"
                    className="nw-action-button edit"
                    
                    onClick={(e) => this.downloadexportPdf(rowData)}
                />
                 <Button
                 disabled
                 icon="fas fa-hand-holding-usd"
                 className="nw-action-button edit"
                 onClick={(e) => this.paymentOption(rowData)}
                />
               
           </div>
         :
         <div>
                <Button
                    className="nw-action-button edit"
                    icon="fas fa-eye"
                    tooltip="Edit"
                    onClick={(e) => this.viewDialog(rowData)}
                    
                />
                <Button
                    icon="fas fa-download"
                    className="nw-action-button edit"
                    
                    onClick={(e) => this.downloadexportPdf(rowData)}
                />
                <Button
   
                icon="fas fa-hand-holding-usd"
                className="nw-action-button edit"
                
                onClick={(e) => this.paymentOption(rowData)}
               />
             
           </div>
       } 
                                      
    </div>
  } 


CancelBtn = () => {
    this.setState({
        onPay: false,
        onProcessVisible: false
    })
    this.setState({onPaymentoption: false})
    
}

crossIcon = () => {
    this.setState({onPaymentoption: false})
}
ReSearch = () => {
    this.setState({
        onProcessVisible: false
    })
    this.onProcessBtn()
}
PaymentMethod = (e) =>{
    if(e === 1 ) {
        this.setState({message: "Bkash"})
    } else if( e === 2) {
        this.setState({message: "Nagad"})
    } else if( e === 3) {
        this.setState({message: "Tap"})
    } else if( e === 4) {
        this.setState({message: "SSL"})
    } 

    this.setState({onPay: true});
    this.setState({onPaymentoption: false})
}

onFinalProcessBtn = () => {
    this.setState({onPay: true});
    if(this.state.message == "SSL"){
        this.setState({ topProgressBar: true, errorMsgVisible: false })
        this.studentPortal
        .fetchpayemntByInvoiceByStudentId(
            this.state.invoiceId,
            this.state.payData,
            this.state.instituteID
        )
        .then( res => {
            if (res.status == 200) {
                return res.json().then(body => {
                    if(body.sslInitiatePaymentResponse.status == 'SUCCESS'){
                        let url = body.sslInitiatePaymentResponse.gatewayPageURL;
                        window.open(url, '_blank');
                        this.setState({
                            onPay: false,
                            topProgressBar: false,
                            onProcessVisible: true
                        })
                    }else{
                        this.setState({onPay: false}); 
                        this.setState({ 
                            topProgressBar: false, errorMsgVisible: true, 
                            errorMsgBody: body.sslInitiatePaymentResponse.failedreason
                       })
                    }
        
                })
            } else {
                this.setState({onPay: false}); 
                this.setState({ 
                    topProgressBar: false, errorMsgVisible: true, 
                    errorMsgBody: "Some Error Occur"
               })
            }
        }).catch( (e) => {
            this.setState({onPay: false}); 
                this.setState({ 
                    topProgressBar: false, errorMsgVisible: true, 
                    errorMsgBody: e.message
               })
        })
    } else {
    
        // this.setState({onPay: false});
        // window.open("https://google.com/", '_blank');
         this.setState({}); 
                this.setState({ 
                    onPay: false,
                    topProgressBar: false, errorMsgVisible: true, 
                    errorMsgBody: "Please Select SSL"
           })
  
    }
   

    

}
onClickPaidHandler = () => {
   this.setState({paidHistroyVisiable: true})
}
onClickPayableHandler = () => {
    this.setState({paidHistroyVisiable: false})
}
render() {
   let {basicInfo} = this.state;
    let motherName = '';
    let fatherName = ''
    let studentId =  ''
    let studentName =  ''
    let roll = ''
    let imageName = ''
    let payableAmount = 0 ;
    if(basicInfo) {
        motherName = basicInfo.motherName;
        fatherName = basicInfo.fatherName;
        studentId =  basicInfo.studentId;
        studentName =  basicInfo.studentName;
        roll = basicInfo.studentRoll;
        imageName = basicInfo.imageName;
    }
   if(this.state.unpaidInvoiceData){
       if(this.state.unpaidInvoiceData[0] != undefined) {
        this.state.unpaidInvoiceData.map( (item) => {
            payableAmount =  payableAmount + item.payableAmount;
           })
       }
    }
      
   let tableFooter = 
   <div className="p-datatable-footer-cus1">
       <div>
           <span> Total payable : &nbsp;&nbsp; {payableAmount}</span>
       </div>

   </div>;
    let { instituteID, customUserID } = this.state;
    let { topProgressBar, errorMsgVisible, errorMsgBody} = this.state;

    let tableHeader = <div className="header-title" style={{position: "relative", backgroundColor: "#697cab"}}>
         <div className="header-title-left" style={{position: "absolute", top: "12px", left: "0px"}}>
            <b>Payable List</b>
             {/* {
                this.state.paidHistroyVisiable == false ?
                 <Button
                    // style={{ border: "none" }}
                    className="p-button-primary nw-button nw-button-left payable-list-show"
                    label="Payable List"
                    onClick={this.onClickPayableHandler}
                /> :
                <Button
                   style={{ border: "none" }}
                    className="nw-button nw-button-left payable-list-show"
                    label="Payable List"
                    onClick={this.onClickPayableHandler}
                />

             } */}
              
     
         </div>
         {/* <div className="header-title-right" style={{position: "absolute", top: "0", left: "110px"}}>
            {
                 this.state.paidHistroyVisiable== true ?
                 <Button
                    className="p-button-primary nw-button nw-button-left payable-list-show"
                    label="Paid List"
                    onClick={this.onClickPaidHandler}
                /> :
                <Button
                    className=" nw-button nw-button-left payable-list-show"
                    label="Paid List"
                    onClick={this.onClickPaidHandler}
                />

             }
            
                
        </div> */}

    </div>;
    let show = "none"
    return (
      <div className="p-fluid">
      <Growl ref={(el) => this.growl = el} />
      {topProgressBar ?
          <ErrorMessageView
              topProgressBar={topProgressBar}
          />
          : null
      }
      {errorMsgVisible ?
          <ErrorMessageView
              errorMsgVisible={errorMsgVisible}
              errorMsgBody={errorMsgBody}
          />
          : null
      }
      <div className="main-section blur-section">
          <div className="p-grid nw-form">
              <div className="p-col-12 p-xl-12 p-col-nogutter seprator">
                  <div className="p-grid seprator-inside" style={{position: "relative"}}>
                      <div className="p-col-12 p-xl-12">
                          <div className="nw-form-body">
                              {/* <div className="p-col-12 p-xl-12"><h2>Student</h2></div> */}
                              
                              <div className="p-col-12 p-xl-12">
                                  <div className="p-inputgroup">
                                      <span className="p-inputgroup-addon">Student ID <span>*</span></span>
                                      <InputText
                                          type="text"
                                          id="stdID"
                                          placeholder="Enter Student ID"
                                          name="customUserID"
                                          onChange={this.onChangeStudenId}
                                          value={customUserID}
                                      />
                                  </div>
                                 
                                   <span className='error-message'>{this.state.errors["customUserID"]}</span>
                              </div>
                              <div className="p-col-12 p-xl-12">
                                  <div className="p-inputgroup">
                                      <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                      <InputText
                                          type="text"
                                          name="instituteID"
                                          onChange={ this.onChangeinstituteId}
                                          value={instituteID}
                                          placeholder="Enter Institute ID"
                                      />
                                  </div>
                                 
                                   <span className='error-message'> {this.state.errors["instituteID"]}</span>
                              </div>

                              

                              <div className="p-col-12 p-xl-12 nw-button-parent">

                                  <div className="required-field">
                                      (<span>*</span>) required fields
                                  </div>

                                  <Button
                                      className="button p-button-primary nw-button nw-button-left"
                                      type="button"
                                      label="Search"
                                      icon="pi pi-search"
                                      onClick={this.onProcessBtn}
                                      style={{ background: "#4a94d0 !important"}}
                                  />

                              </div>
                          </div>
                      </div>
                        
                        <div className="p-col-12 p-xl-12">
                                {/* <hr style={{ background: "gray " }} /> */}
                                
                            <div className='details-wrap'>
                                <div className='data-box'>
                                    <ul className='bolateColor'>
                                            <li><p>STUDENT NAME &nbsp;  &nbsp;: &nbsp; <span>{studentName}</span></p></li>
                                            <li><p>STUDENT ID &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; : &nbsp; <span>{studentId}</span></p></li>
                                            <li><p>ROLL NO &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : &nbsp; <span>{roll}</span></p></li>
                                            <li><p>MOTHER'S NAME &nbsp;: &nbsp; <span>{motherName}</span></p></li>
                                            <li><p>FATHER'S NAME &nbsp;&nbsp; : &nbsp; <span>{fatherName}</span> </p></li>
                                    </ul>
                                    
                                </div>

                            </div>
                        </div>
                        
                      {/* <div className="p-col-12 p-xl-12">
                                  <div className='profile-wrap'>
                                    <div className='img-box'>
                                        <div className='img-part-invoice'>
                                                <img src={user} />
                                            </div>
                                            <div className='roll-part'>
                                                <p>Roll No <br/> {roll}</p>
                                                
                                            </div>
                                        
                                    </div>
                                    
                                    <div className='data-box'>
                                        <p>Student Name &nbsp;  &nbsp;: &nbsp;{studentName}</p>
                                        <p>Student Id &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;: &nbsp; {studentId}</p>
                                        <p>Mother's Name &nbsp;: &nbsp;{motherName}</p>
                                        <p>Father's Name  &nbsp;&nbsp; : &nbsp; {fatherName} </p>
                                    </div>
                                  </div>
                                  
                      </div> */}

                       <div className='p-col-12 p-xl-12'>

                       </div>


                      <div className="p-col-12 p-xl-12">
                        <div className="p-col-12 p-xl-12 nw-data-table">
                          {
                              this.state.paidHistroyVisiable == false ? 
                                <DataTable
                                value={this.state.unpaidInvoiceData}
                                selectionMode="single"
                                header={tableHeader}
                                footer={tableFooter}
                                responsive={true}
                                columnResizeMode="fit"
                                >
    
                                    <Column field="createdDate" header="Invoive Date"  />
                                    <Column field="payslipId"  header="Payslip Id"  />
                                    <Column field="payableAmount" header="Due Amount" />
                                    <Column field=""  body={this.detailsBody} header="Actions" />
                        
                                    
                                </DataTable>
                               :
                               <DataTable
                               value={this.state.PaidInvoiceData}
                               selectionMode="single"
                               header={tableHeader}
                            //    footer={tableFooter}
                               responsive={true}
                               columnResizeMode="fit"
                               >
   
                
                                    <Column field="createdDate" header="Invoive Date"  />
                                    <Column field="payslipId"  header="Payslip Id"  />
                                    <Column field="payableAmount" header="Paid Amount" />
                                    <Column field=""  body={this.detailsBodyforPaid} header="Actions" />
                                   
                               </DataTable>
                          }
                            

                         
                            
                         </div>
                     </div>

                     <div className="dialog-section">
                        <Dialog header="Bill Discount Request Info" visible={this.state.visible} className="nw-dialog" onHide={this.onHide} closable>
                        
                            <div className="p-grid nw-form">

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Status</span>
                                        <Dropdown
                                            placeholder='Select Status'
                                        // options={statusListOptions}
                                            name="status"
                                            value={this.state.approvalValue}
                                            //onChange={this.onChangeStatus}
                                            filter={true}
                                            filterBy="label,value"
                                            autoWidth={false}
                                        />
                                    </div>
                                    {/* <span className="error-message">{this.state.errors["status"]}</span> */}

                                </div>

                            
                            </div>
                        </Dialog>
                    </div>

                    <div className="dialog-section">
                            <Dialog  visible={this.state.onPay}  onHide={this.onHide} closable style={{position: "absolute"}}>
                            
                            <div className='payment-window'>
                                <div className='content-top'>
                                        <h1> Payment Collect By {this.state.message}</h1>
                                        <p> Are you sure want to pay your <br/> payable fees?</p>
                                        <p className='last-p'> Amount:  {this.state.payData} /-</p>
                                </div>
                                <div className='content-bottom'>
                                    <h1 className="cancelbtn"
                                        onClick={this.CancelBtn}>Cancel</h1>
                                    
                                    <h1 className="proccedbtn"
                                        onClick={this.onFinalProcessBtn}>Procced</h1>
                                </div>
                            
                            </div>
                                    
                            </Dialog>
                    </div>



                    <div className="dialog-section">

                            <Dialog  visible={this.state.onPaymentoption}  onHide={this.onHide} closable style={{position: "absolute"}}>
                            
                                <div className='payment-options'>

                                    <div className='payment-options-h' style={{position: "relative"}} >
                                        <p>Please Select Your Payment Method</p>
                                        
                                        <span class="p-dialog-titlebar-close-icon pi pi-times cross-icon" 
                                        
                                        onClick={this.crossIcon}

                                        > </span>
                                    </div>

                                    <div className='payment-options-f' >
                                        <div className='content-l'>
                                            <div className='content-data' >
                                                
                                             
                                                <img
                                                    src={Logo}
                                                    alt="Bkash"
                                                    onClick={() => this.PaymentMethod(1)}   
                                                />
                                            </div>
                                            <div className='content-data'>
                                            <img   
                                                   src={Nagad}
                                                   alt="Nagad" 
                                                   onClick={() => this.PaymentMethod(2)}   
                                                   />
                                                   
                                    
                                            </div>
                                        </div>
                                        <div className='content-l'>
                                            <div className='content-data'>
                                            <img    
                                                src={Tap}
                                                alt="Tap"
                                                onClick={() => this.PaymentMethod(3)} />
                                                  
                                            </div>
                                            <div className='content-data'>
                                            <img  
                                            
                                              src={Ssl}
                                              alt="ssl"
                                              onClick={() => this.PaymentMethod(4)} 
                                              />
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className='content-bottom'>
                                        <h1 className="cancelbtn"
                                            onClick={this.CancelBtn}>Cancel</h1>
                                        
                                        <h1 className="proccedbtn"
                                            onClick={this.onFinalProcessBtn}>Procced</h1>
                                    </div> */}

                                   
                                
                                </div>
                                    
                            </Dialog>
                    </div>





                    <div className="dialog-section">

                        <Dialog  visible={this.state.onProcessVisible}  onHide={this.onHide} closable style={{position: "absolute"}}>
                        
    
                         
                            <div className='payment-options' style={{top: "-75px", height: "104px", width: "388px", fontSize: "20px"}}>
                                <div className='payment-options-h' style={{position: "relative"}} >
                                            <p >Your Transaction In proceed</p>
                                            
                                            <span class="p-dialog-titlebar-close-icon pi pi-times cross-icon" 
                                            
                                            onClick={this.ReSearch}
                                         

                                            > </span>
                                </div>
                            </div>
                        </Dialog>
                    </div>
            
                </div>
              </div>

              {/* <div class="p-col-12 p-xl-4 netiWorld-DashBoard-wrapper">
                  <div class="p-col-12 p-l-0">
                      <div class="panel-box p-l-1 p-r-0 p-t-0">
                          <div class="advisement-wrapper">
                              <div class="gradient-color-dark-blue">
                                  <div class="notify-box-wrapper-bg-image boxHeight-385">
                                      <div class="advisement-text">
                                          <p>PLACE FOR AD</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> */}
          </div>
      </div>

     

      
      
  </div>
    )
}
}
