
import React, { Component } from 'react';

export class DemoCrmDashboard extends Component {

    constructor() {
        super();
        this.state = {


        }




    }

    render() {


        return (
            <div className="p-fluid">
                <div className="main-section comming-soon-anim" >
                    <div class="comming-soon-anim-inside">
                        <h1>
                            <span>D</span>
                            <span>A</span>
                            <span>S</span>
                            <span>H</span>
                            <span>B</span>
                            <span>O</span>
                            <span>A</span>
                            <span>R</span>
                            <span>D</span>
                            <br/>
                            <span>C</span>
                            <span>o</span>
                            <span>m</span>
                            <span>i</span>
                            <span>n</span>
                            <span>g</span>
                            <span> </span>
                            <span>S</span>
                            <span>o</span>
                            <span>o</span>
                            <span>n</span>
                            <br/>
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                            {/* <br/>
                            <span>2</span>
                            <span>0</span>
                            <span>2</span>
                            <span>0</span> */}
                        </h1>
                    </div>
                </div>
            </div>
        )

    }
}