import React, { Component } from 'react';
import { StudentPortalMain } from './StudentPortalMain'
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import { PortalProfileInfo } from '../common/PortalProfileInfo';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { EmUserPointService } from '../../../service/emUserPoint/EmUserPointService';
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";

export class StudentPortalProfile extends Component {


    constructor(props) {
        super(props);
        this.state = {
            studentInfo: [],
            basicInfo: [],
            stId: '',
           
            topProgressBar: '',
            userList: [],
            userID: '',
            userLength: '',
            insID: '',
       
            
        }
        this.StudentPortalMain = new StudentPortalMain();
        this.emUserPointService = new EmUserPointService();
        this.studentPortal = new StudentPortal();
    }
    
    componentDidMount() {     
        //This is only for exceptional empty/null/undefined output
        setTimeout(() => {
            [...document.querySelectorAll('.protal-single-info-content')].map(item => {
                if (!item.querySelector('p').innerHTML.trim()) {
                    item.querySelector('p').innerHTML = '---'
                }
            })
        }, 1500);
           this.loadStudentInfo();             
    }

    handledropdwon = (id, insId) => {
       
        this.setState({userID: id})
        this.setState({insID:insId})

        this.loadStudentInfo(); 
    }

   
   

    loadStudentInfo = () => {

        setTimeout(() => {
            [...document.querySelectorAll('.protal-single-info-content')].map(item => {
                if (!item.querySelector('p').innerHTML.trim()) {
                    item.querySelector('p').innerHTML = '---'
                }
            })
        }, 1500);

        let userListtt = [];
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.emUserPointService.fetchMappedPortalList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log('Log: GoToPortal -> componentWillMount -> body......', body[0].idForStudent)
                        console.log('Log: GoToPortal -> componentWillMount -> body......', body)
                        

                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({userList: userListtt})
                        this.setState({userLength:body.length})
                        this.setState({stId:body[0].idForStudent})

                        console.log("user id inside : ", this.state.userID)
                        
                        this.studentPortal
                        .fetchStudentProfileDetailsList(
                            this.state.insID || body[0]["emDetailsInfoDTO"].instituteId ,
                            this.state.userID || body[0].idForStudent
                            // body[this.state.userID]["emDetailsInfoDTO"].instituteId,
                            // body[this.state.userID].idForStudent,
                        )
                        .then(res => {
                            if (res.status == 200) {
                                     
                                return res.json().then(body => {
                                    this.setState({ topProgressBar: false, errorMsgVisible: false })
                                    this.setState({ basicInfo: body });
                        
                                });
                            } else {
                                this.studentPortal.Auth.handleErrorStatus(res)
                                    .then((responseBody) => {
                                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                    });
                            }
                        })
                        .catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));

                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                    })
                } else {
                    this.emUserPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch((error) => {
                console.log(error)
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });

    
        this.setState({ topProgressBar: true, errorMsgVisible: false })
  };

   
    render() {
        
         var rows = [];
         let l = this.state.userLength;
         this.state.userList.map((item) =>{
            if(l > rows.length){
                rows.push(<a  onClick={() => this.handledropdwon(item.idForStudent, item["emDetailsInfoDTO"].instituteId)} >    {item.idForStudent}  </a>)
            }
        })
        
        // console.log("user id render : ", this.state.userID)
        let basicInfo = this.state.basicInfo;
             
        return (

            
              

            <div className="p-fluid nw-form nw-w-100">
                <div className="p-col-12" style={{position: "relative"}} >

                    <PortalProfileInfo basicInfo={this.state.basicInfo} />
                    <div className='dropdown'>
            
                            <button className="dropbtn">
                            {/* <i className="fa fa-caret-down"></i> */}
                            <span class="fas fa-align-right p-c p-button-icon-left"></span>
                            </button>
                            <div className="dropdown-content">
                             {rows}

                            </div>
                    
                    </div>
                </div>


                <div className="p-col-12 nw-m-t-80">
                    <div className="p-grid m-l-m-35 m-r-m-35">
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Basic Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Gender</h4>
                                       
                                        <p>{this.state.basicInfo.studentGender || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Religion</h4>
                                        <p>{this.state.basicInfo.studentReligion || '---'}</p>
                                       
                                        
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Date of Birth</h4>
                                        <p>{this.state.basicInfo.studentDOB || '---'}</p>
                                       
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Mobile No.</h4>
                                        <p>{this.state.basicInfo.studentMobile || '---'}</p>
                                        
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Email</h4>
                                        <p>{this.state.basicInfo.studentEmail || '---'}</p>
                                        {/* { check && <p> {basicInfo["emDetailsInfoDTO"].assignedUserBasicInfoDTO.basicEmail}  </p>} */}
                                        {/* { check ? <p> {basicInfo["emDetailsInfoDTO"].assignedUserBasicInfoDTO.basicEmail} </p>: "......"} */}
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Birth Certificate No.</h4>
                                        <p>{this.state.basicInfo.birthCertificateNo || '---'}</p>
                                         {/* { check ? <p> {basicInfo["emDetailsInfoDTO"].assignedUserBasicInfoDTO.dateOfBirth} </p>: "......"} */}

                                         
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Academic Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Class</h4>
                                        <p>{this.state.basicInfo.className || '---'}</p>
                                        {/* <p>{this.state.stId || '---'}</p> */}
                                        
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Shift</h4>
                                        <p>{this.state.basicInfo.shiftName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Section</h4>
                                        <p>{this.state.basicInfo.sectionName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Roll No.</h4>
                                        <p>{this.state.basicInfo.studentRoll || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Group</h4>
                                        <p>{this.state.basicInfo.groupName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Category</h4>
                                        <p>{this.state.basicInfo.admissionCategory || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Parent Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Father's Name</h4>
                                        <p>{this.state.basicInfo.fatherName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Mother's Name</h4>
                                        <p>{this.state.basicInfo.motherName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Guardian Mobile No.</h4>
                                        <p>{this.state.basicInfo.guardianMobile || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Roll No.</h4>
                                        <p>{this.state.basicInfo.studentRoll || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Mother's NID</h4>
                                        <p>{this.state.basicInfo.mothersNID || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Guardian Email</h4>
                                        <p>{this.state.basicInfo.guardianEmail || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Address Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Present Address</h4>
                                        <p>{`${this.state.basicInfo.presentHouseNo ? "House- " + this.state.basicInfo.presentHouseNo + ", Road- " : ''}
                                         ${this.state.basicInfo.presentRoadNo ? this.state.basicInfo.presentRoadNo + ", Village- " : ''}
                                         ${this.state.basicInfo.presentVillage ? this.state.basicInfo.presentVillage + ", P.O- " : ''}
                                         ${this.state.basicInfo.postalCode ? this.state.basicInfo.postalCode + ", P.S- " : ''}
                                         ${this.state.basicInfo.presentThana ? this.state.basicInfo.presentThana + ", District- " : ''}
                                         ${this.state.basicInfo.presentDistrict ? this.state.basicInfo.presentDistrict + ", Country- " : ''}
                                         ${this.state.basicInfo.country ? this.state.basicInfo.country : ''}`.trim() || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Permanent Address</h4>
                                        <p>{`${this.state.basicInfo.houseNo ? "House- " + this.state.basicInfo.houseNo + ", Road- " : ''} 
                                        ${this.state.basicInfo.roadNo ? this.state.basicInfo.roadNo + ", Village- " : ''}
                                        ${this.state.basicInfo.village ? this.state.basicInfo.village + ", P.O- " : ''}
                                        ${this.state.basicInfo.postOffice ? this.state.basicInfo.postOffice + ", P.S- " : ''}
                                        ${this.state.basicInfo.thana ? this.state.basicInfo.thana + ", District- " : ''}
                                        ${this.state.basicInfo.district ? this.state.basicInfo.district + ", Country- " : ''}
                                        ${this.state.basicInfo.country ? this.state.basicInfo.country : ''}`.trim() || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Medical Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Blood Group</h4>
                                        <p>{this.state.basicInfo.bloodGroup || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Height</h4>
                                        <p>{this.state.basicInfo.height || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Weight</h4>
                                        <p>{this.state.basicInfo.weight || '---'}</p>
                                    </div>
                                    {/* <div className="protal-single-info-content">
                                        <h4>Eye Color</h4>
                                        <p></p>
                                    </div> */}
                                    <div className="protal-single-info-content">
                                        <h4>Special Disease</h4>
                                        <p>{this.state.basicInfo.specialDisease || '---'}</p>
                                    </div>
                                    {/* <div className="protal-single-info-content">
                                        <h4>Last Treatment Date</h4>
                                        <p></p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Previous Institute Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Institute Name</h4>
                                        <p>{this.state.basicInfo.previousInstituteName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Address</h4>
                                        <p>{this.state.basicInfo.previousInstituteAddress || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Contact No.</h4>
                                        <p>{this.state.basicInfo.instituteMobile || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Email</h4>
                                        <p>{this.state.basicInfo.instituteEmail || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Time Period</h4>
                                        <p>{this.state.basicInfo.timePeriod || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Last Education</h4>
                                        <p>{this.state.basicInfo.lastEducation || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        )
        }
    
}




