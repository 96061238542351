import React, { Component } from 'react';
import BalanceWalletLogDetail from '../../common/BalanceWalletLogDetail';

let pathname;

export class BalanceLogDeposit extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            balanceLogDepositTable: [
                {"requestDate": "23-01-2019", "bank": "DBBL", "accountNumber": "101.125.51100", "requestAmount": 50099, "transactionDate": "25-01-2019", "transactionID": "25364BD", "processDate": "24-01-2019", "status": "approved"},
                {"requestDate": "25-01-2019", "bank": "MBL", "accountNumber": "102.102.02101", "requestAmount": 50099, "transactionDate": "26-01-2019", "transactionID": "25364BD", "processDate": "26-01-2019", "status": "pending"},
                {"requestDate": "26-01-2019", "bank": "DBBL", "accountNumber": "101.520.12055", "requestAmount": 50099, "transactionDate": "29-01-2019", "transactionID": "25364BD", "processDate": "27-01-2019", "status": "Rejected"},
                {"requestDate": "21-01-2019", "bank": "PBL", "accountNumber": "111.001.01010", "requestAmount": 50099, "transactionDate": "28-01-2019", "transactionID": "25364BD", "processDate": "29-01-2019", "status": "approved"},
            ]
        } 
        
    }

    

	render() {

		return (
            <div >
                <BalanceWalletLogDetail tableValue={this.state.balanceLogDepositTable}/>
            </div>
        );
	}
}