import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService'


export class ChatBoxService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    fetchSendMessageInfo() { 
        let uri = this.baseURIHolder.getUser() + "/chat/log/by/usertype?userType=sender";
        return this.Auth.getFetch(uri)
        .catch((error) => console.log("error", error));
      
    }

    fetchReceivedMessageInfo(){
        let uri2 = this.baseURIHolder.getUser() + "/chat/log/by/usertype?userType=receiver";
        return this.Auth.getFetch(uri2)
        .catch((error) => console.log("error", error));

    }

    fetchProfileInfo(netiId){
        let uri = this.baseURIHolder.getUser() + "/profile/by/custom_id?custom_id=" + netiId;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log("error", error));
    }


    sendProfileInfo(data){
        let uri = this.baseURIHolder.getUser() + "/chat/new";
        console.log("uri", uri);
        return this.Auth.postFetch(uri, data).catch(error =>
          console.log("error", error));

    }

    fetchUnreadMessageInfo() { 
        let uri = this.baseURIHolder.getUser() + "/chat/unread";
        return this.Auth.getFetch(uri)
        .catch((error) => console.log("error", error));
      
    }

    changeUnreadStatus(data){
        let uri = this.baseURIHolder.getUser() + "/chat/unread/status";
        console.log("uri", uri);
        return this.Auth.putFetch(uri, data).catch(error =>
          console.log("error", error));

    }

    fetchChatAndTokenCount() { 
        let uri = this.baseURIHolder.getUser() + "/token/and/chat/count";
        return this.Auth.getFetch(uri)
        .catch((error) => console.log("error", error));
      
    }

}

