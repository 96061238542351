import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import { FileUpload } from 'primereact/fileupload';
import { MyPointTokenTable } from './MyPointTokenTable';
import { ProfileService } from '../../../service/profile/ProfileService';
import { TokenService } from '../../../service/myPoint/TokenService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { TokenCommonBody } from './TokenCommonBody';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import TokenCommonBodyNew from './TokenCommonBodyNew';
import { Rating } from 'primereact/rating';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { DownloadButton } from '../../../utils/DownloadButton';
import CommonFuctionality from '../../common/CommonFuctionality';


let tokenDetailsError={}
let countPending= 0, countSolved= 0;
export class Token extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newMobile: '',
            problemModule: '',
            moduleValue: '',
            problemType: '',
            problemTypeValue: '',
            proPic: {},
            tokenDetailsError: {},
            tokenFormData: {
                attachSaveOrEditable: false,
                tokenSource: "Netiworld",
                tokenDetails: "",
                tokenContact: "",
                tokenTypeInfoDTO: {
                    coreCategoryID: ''
                }
            },
            oldMobile: '',
            disabled: true,
            firstDropDownIsLoading: false,
            secondDropDownIsLoading: false,
            dataTableIsLoading: false,
            dataTableValue:[],
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            errors:{},
            tokenList: [],
            ratingObj:{
                ratingValue: '',
                commentValue:'',  
            },
            
        };

        this.onChangeHandle = this.onChangeHandle.bind(this);
        this.onChangeMobileNumber = this.onChangeMobileNumber.bind(this);
        this.onImageUpload = this.onImageUpload.bind(this);
        this.profileService = new ProfileService();
        this.tokenService = new TokenService();
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
    }

    componentWillMount() {
        let userProfile = JSON.parse(localStorage.getItem('Profile'));
        this.setState({oldMobile:userProfile.basicMobile });
        this.getProblemModuleList();
        this.showUserTopTenTokenList();
        
    }

    showUserTopTenTokenList = () => {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.tokenService.getPendingTokenList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        countSolved = countPending = 0;
                        for(let i=0; i<body.length; i++){
                            if(body[i].tokenTypeInfoDTO.parentCoreCategoryInfoDTO==null){
                                body[i].tokenTypeInfoDTO.parentCoreCategoryInfoDTO={categoryName:''}
                            }

                            body[i].tokenStatus == 0 ? countPending += 1 : countPending += 0
                            body[i].tokenStatus == 10 ? countSolved += 1 : countSolved += 0
                        }
                        this.setState({ dataTableValue: body });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.tokenService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                    });
                }
            }).catch(error => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }

    getProblemModuleList = () => {
        this.setState({ firstDropDownIsLoading: true, dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.tokenService.getUserTokenByDefaultCategory('C11001002')
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({
                            problemModule: body
                        });
                        this.setState({ firstDropDownIsLoading: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.tokenService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        tokenDetailsError["moduleValue"] = resp;
                        this.setState({ tokenDetailsError, firstDropDownIsLoading:false, dataTableIsLoading: false, topProgressBar: false})
                    });
                }
            }).catch((error) => {
                this.setState({ firstDropDownIsLoading:false, dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }

    getProblemTypeList = (e) => {
        let {tokenDetailsError} = this.state;
        tokenDetailsError["moduleValue"] = "";
        this.setState({tokenDetailsError});

        this.setState({ secondDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.setState({ problemType: null });
        this.setState({ moduleValue: e.value });
        this.tokenService.getUserTokenByParentCategory(e.value)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ problemType: body, secondDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.tokenService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        tokenDetailsError["coreCategoryID"] = resp;
                        this.setState({ tokenDetailsError, secondDropDownIsLoading:false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                    });
                }
            }).catch((error) => {
                this.setState({ secondDropDownIsLoading:false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }


    onChangeList = (e) => {
        let { tokenFormData, tokenDetailsError } = this.state;
        tokenDetailsError["coreCategoryID"] = "";
        this.setState({tokenDetailsError});
        tokenFormData.tokenTypeInfoDTO[e.target.name] = e.target.value;
        this.setState({ tokenFormData });
    }


    onChangeHandle = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onChangeMobileNumber = (e) => {
        let {tokenFormData, tokenDetailsError} = this.state;
        tokenDetailsError["tokenContact"] = ''
        tokenFormData.tokenContact = e.target.value;
        this.setState({ tokenFormData, tokenDetailsError})

    }

    onChangeTokenDetails = (e) => {
        let { tokenFormData,tokenDetailsError } = this.state;
        tokenDetailsError["tokenDetails"] = "";
        tokenFormData[e.target.name] = e.target.value;
        this.setState({ tokenFormData,tokenDetailsError });

    }

    onImageUpload(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });

        }
        // this.growl.show({severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode'});
    }

    onSubmitHandler = (e) => {

        let { tokenFormData } = this.state;

        if (tokenFormData.tokenContact === '') {
            tokenFormData.tokenContact = this.state.oldMobile
        }

        if (this.state.proPic.contentPic !== null && this.state.proPic.contentPic !== undefined) {
            tokenFormData.attachContent = this.state.proPic.contentPic;
            tokenFormData.attachSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            tokenFormData.attachmentName = Date.now() + extention;
        } 
        if (this.tokenFormHandleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.tokenService.addNetiworldToken(tokenFormData)
                .then((res) => {
                    if (res.status === 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully submitted' });
                        let tokenFormDataBlank = {
                            tokenSource: "",
                            tokenDetails: "",
                            tokenContact: "",
                            tokenTypeInfoDTO: {
                                coreCategoryID: ''
                            }
                        }
                        this.setState({   proPic: {},tokenFormData: tokenFormDataBlank, moduleValue: '' });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                        this.showUserTopTenTokenList();
                    } else {
                        this.tokenService.Auth.handleErrorStatus(res)
                        .then((resp)=>{
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                        });
                    }
                }).catch((error) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });

        }



    }

    tokenFormHandleError = () => {
        let tokenDetailsError = {};
        let formIsValid = true;

        if (this.state.moduleValue === '') {
            formIsValid = false;
            tokenDetailsError["moduleValue"] = "Problem module can't left empty.";
        }
        if (this.state.tokenFormData.tokenTypeInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            tokenDetailsError["coreCategoryID"] = "Problem Type can't left empty.";
        }
        if (this.state.tokenFormData.tokenDetails === '') {
            formIsValid = false;
            tokenDetailsError["tokenDetails"] = "Problem details can't left empty.";
        }
        if (this.state.tokenFormData.tokenContact === '' && !this.state.oldMobile) {
            formIsValid = false;
            tokenDetailsError["tokenContact"] = "Contact number can't left empty.";
        } 
        // else if (this.state.oldMobile === '') {
        //     formIsValid = false;
        //     tokenDetailsError["tokenContact"] = "Contact number can't left empty.";
        // }
        this.setState({ tokenDetailsError: tokenDetailsError });
        return formIsValid;
    }

    actionDetails = (rowData) =>{

        this.setState({ dialogDate: rowData.createDate })
        this.setState({ dialogProblemModule: rowData.tokenTypeInfoDTO.categoryName })
        this.setState({ dialogProblemType: rowData.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName })
        this.setState({ dialogProblemDetails: rowData.tokenDetails })
        this.setState({ dialogProblemAttachments: rowData.attachmentName })
        this.setState({ dialogContactNo: rowData.tokenContact })
        this.setState({ dialogStatus: rowData.tokenStatus })

        this.setState({ 
            visible: true,
         })
         
    }

    onHide = () => {
        this.setState({ visible: false })
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showTokenDetails = (tokenList) =>{
        let { ratingObj } = this.state
        ratingObj.commentValue = null

        // console.log("tokenList",tokenList);
        this.setState({ visible: true, tokenList: tokenList, ratingObj, ratingValue: 0})
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    getRemainTime = (start, end) =>{
        let countValue 
        var countDownDate = new Date( start ).getTime()
        var now
        if(end != null){ 
            now = new Date( end ).getTime()
        }else{
            now = new Date().getTime();
        }
            
                
            var distance = now - countDownDate;
                
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            countValue =days + "d " + hours + "h "+ minutes + "m " + seconds + "s "

        return countValue
    }

    onChangeRatingValue = e =>{
        let { ratingObj, errors } = this.state
        errors["ratingMark"] = "";
        ratingObj.ratingValue = e.value
        this.setState({ ratingObj, ratingValue: e.value })
    }

    onChangeCommentValue = e =>{
        let { ratingObj, errors } = this.state
        errors["ratingComment"] = "";
        ratingObj.commentValue = e.target.value
        this.setState({ ratingObj })
    }

    ratingHandleError = () => {
        let { tokenRatingObj, ratingObj, errors } = this.state
        let formIsValid = true;

        if (ratingObj.ratingValue == '') {
            formIsValid = false;
            errors["ratingMark"] = "Rating Mark can't left empty.";
        }

        if (ratingObj.commentValue == '') {
            formIsValid = false;
            errors["ratingComment"] = "Rating Comment can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitRating = () =>{
        let { tokenList, ratingObj } = this.state

        tokenList.ratingMark = ratingObj.ratingValue
        tokenList.ratingMessage = ratingObj.commentValue

        console.log("tokenList::::", tokenList)
        if (this.ratingHandleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.tokenService.saveUserRating(tokenList)
              .then(res => {
                if (res.status == 202) {
                  this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                  this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted" });
                //   this.showUserTopTenMessageRechargeList();
                setTimeout(() => {
                    this.onHide();
                    this.showUserTopTenTokenList()
                }, 800);
                } else {
                  this.MessageRechargeService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                      this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                    });
                }
            }).catch(error => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
        }
    }

    // countTokenStatus = type =>{
    //     let { dataTableValue } = this.state
        

    //     for(let i=0 ; i<dataTableValue.length ; i++){
    //         if(type === 'pending')
    //             dataTableValue[i].tokenStatus == 0 ? countPending += 1 : countPending += 0
    //         else if(type === 'solved')
    //             dataTableValue[i].tokenStatus == 10 ? countSolved += 1 : countSolved += 0
    //     }

    //     // return countPending
    // }

    render() {
        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">370 x 240 px (recommended)</span></div>
        let { tokenList, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let problemModuleList = []
        if (this.state.problemModule && this.state.problemModule.length) {
            problemModuleList = this.state.problemModule.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        let problemTypeList = []
        if (this.state.problemType && this.state.problemType.length) {
            problemTypeList = this.state.problemType.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }



        
        let { ratingObj, errors } = this.props;

        let progressMainClass1 = "info-box-wrapper progress-view step-1 "
        let progressMainClass2 = "info-box-wrapper progress-view step-2 "
        let progressMainClass3 = "info-box-wrapper progress-view step-3 "
        let progressMainClass4 = "info-box-wrapper progress-view step-4 "
        let progressMainClass5 = "info-box-wrapper progress-view step-5 ";

        if(tokenList && tokenList.tokenStatus == 0){
            progressMainClass1 = progressMainClass1+"active"
            progressMainClass2 = progressMainClass2+"active"
            // this.getRemainTime(tokenList && tokenList.createDate)
        }
        else if(tokenList && tokenList.tokenStatus == 1){
            progressMainClass1 = progressMainClass1+"active"
            progressMainClass2 = progressMainClass2+"active"
            progressMainClass3 = progressMainClass3+"active"
        }

        else if(tokenList && tokenList.tokenStatus == 10){
            progressMainClass1 = progressMainClass1+"active"
            progressMainClass2 = progressMainClass2+"active"
            progressMainClass3 = progressMainClass3+"active"
            progressMainClass4 = progressMainClass4+"active"
        }

        

        const msg =  this.props.location.tokenList ? dataTableValue.filter( dataTableValue => dataTableValue.tokenInfoID == (this.props.location.tokenList && this.props.location.tokenList.tokenInfoID) ): null;
        
        // console.log("msg", msg);
        // console.log("msg[0]", msg && msg[0]);
        // console.log("msg.chatboxLogID", msg && msg[0] && msg[0].tokenInfoID);
        
        if( (msg !== null) && ( msg && msg[0] && (msg && msg[0] && msg[0].tokenInfoID) )  ) {
          let logID = document.getElementById(msg[0].tokenInfoID)
    
        //   console.log("logID && logID.scrollIntoView()", logID);
          
          if(logID !== null){
            logID.scrollIntoView()
          }
        }
        

        return (
            <div className="p-fluid">
                { topProgressBar?
                    <ErrorMessageView 
                        topProgressBar={topProgressBar} 
                    />
                    : null
                }
                { errorMsgVisible ?
                    <ErrorMessageView 
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator blur-section">
                            <div className="p-grid seprator-inside">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                        <div className="p-col-12 p-xl-12">
                                            { this.state.firstDropDownIsLoading ? 
                                                this.NetiContentLoader.normalFormInputField():
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Problem Module <span>*</span></span>
                                                    <Dropdown
                                                        value={this.state.moduleValue}
                                                        options={problemModuleList}
                                                        onChange={this.getProblemTypeList}
                                                        filter={true}
                                                        showClear={true}
                                                        style={{ width: "100%" }}
                                                        name="moduleValue"
                                                    />
                                                </div>
                                            }
                                            <span className="error-message">{this.state.tokenDetailsError["moduleValue"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            { this.state.secondDropDownIsLoading ? 
                                                this.NetiContentLoader.normalFormInputField():
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Problem Type <span>*</span></span>
                                                    <Dropdown
                                                        value={this.state.tokenFormData.tokenTypeInfoDTO.coreCategoryID}
                                                        options={problemTypeList}
                                                        onChange={this.onChangeList}
                                                        // itemTemplate={this.bankTemplate.bind(this)}
                                                        filter={true}
                                                        filterBy="label,value"
                                                        showClear={true}
                                                        name="coreCategoryID"
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            }
                                            <span className="error-message">{this.state.tokenDetailsError["coreCategoryID"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                                <InputTextarea
                                                    id="textarea"
                                                    rows={3}
                                                    cols={30}
                                                    autoResize={true}
                                                    name="tokenDetails"
                                                    value={this.state.tokenFormData.tokenDetails}
                                                    onChange={this.onChangeTokenDetails}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.tokenDetailsError["tokenDetails"]}</span>
                                        </div>
                                            
                                        {/* </div> */}

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Contact No. <span>*</span></span>

                                                {this.state.oldMobile != "" ?

                                                    <InputText
                                                        value={this.state.oldMobile}
                                                        readOnly={this.state.disabled}
                                                    /> :
                                                    <InputText
                                                        type="text"
                                                        value={this.state.tokenFormData.tokenContact}
                                                        placeholder="Enter Your Mobile No."
                                                        onChange={this.onChangeMobileNumber}
                                                        keyfilter="pint"
                                                        name="tokenContact"
                                                        maxlength={11}

                                                    />
                                                }
                                                    <Button
                                                        className=""
                                                        icon="fas fa-pencil-alt"
                                                        onClick={(e) => this.setState({ oldMobile: '', disabled: false })}
                                                    >
                                                    </Button>
                                                

                                            </div>
                                            <span className="error-message">{this.state.tokenDetailsError["tokenContact"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                        Upload Image
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/GIF)</span>
                                                </span>
                                                    <div className="nw-upload-button-inside">
                                                    {
                                                        this.state.proPic.contentPic ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={e => this.setState({ proPic: { contentPic: '' } })}
                                                                />
                                                                <img
                                                                    src={
                                                                        this.state.proPic.contentPic === '' ?
                                                                            'assets/images/demo.jpg'
                                                                            :
                                                                            "data:image/png;base64," + this.state.proPic.contentPic
                                                                    }
                                                                    style={{ "height": "80px" }}
                                                                />


                                                            </div>
                                                            <div className="image-title">{this.state.proPic.contentName}</div>
                                                        </div>
                                                        :
                                                        <FileUpload
                                                            chooseLabel={this.state.proPic.contentName || uploadFileLabel}          
                                                            mode="basic"
                                                            accept="image/*"
                                                            maxFileSize={1000000}
                                                            onSelect={this.onImageUpload.bind(this)}
                                                            auto={true}
                                                            style={{ height: '30px', textAlign: 'left', position: 'relative' }} 
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                            <Button
                                                className="p-button-primary nw-button nw-button-right"
                                                label="Submit"
                                                icon="fas fa-check"
                                                onClick={this.onSubmitHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-4">
                            <div className="p-col-12 p-xl-12 nw-token-view-main">
                                <div className="p-col-12 p-xl-12 title-bar blur-section">
                                    <span>
                                        <h1>Token Status</h1>
                                        <Link to="/token_list" >View All <i className="fas fa-angle-right" /><i className="fas fa-angle-right" /></Link>
                                    </span>
                                </div>

                                <div className="p-col-12 p-xl-12 nw-token-view header blur-section">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <div>Pending Token </div>
                                            <div>{ countPending } </div>
                                        </span>
                                        <span className="p-inputgroup-addon">
                                            <div>Solved Token </div>
                                            <div>{   countSolved }</div>
                                        </span>

                                        {/* <Button
                                            label={this.state.unreadMessageCount || "0"}
                                            onClick={(e) => window.location.href = "/chat_box_list"}
                                        >View All <i className="fas fa-angle-right" /><i className="fas fa-angle-right" /></Button> */}
                                    </div>
                                </div>

                                <div 
                                    className="p-col-12 p-xl-12 p-col-nogutter nw-token-view body"
                                    
                                >
                                    {
                                        dataTableValue.map((item, index) =>
                                        item.tokenStatus == 0 || (item.tokenStatus == 10 && item.ratingMark == 0) ?
                                            <div 
                                                id={ item.tokenInfoID }
                                                className="p-col-12 p-xl-12 inside"
                                                style={ msg && msg[0] && msg[0].tokenInfoID == item.tokenInfoID ? {backgroundColor: '#ffffcc'} : {backgroundColor: 'white'} }
                                            >
                                                {/* <TokenCommonBody viewUnreadMSGdetails={this.viewUnreadMSGdetails} unreadMessage={unreadMessage} /> */}
                                                <TokenCommonBodyNew actionDetails={this.actionDetails} tokenList={ item } reloadTokenList={this.showUserTopTenTokenList} showTokenDetails={this.showTokenDetails}/>
                                            </div>
                                            :''
                                        )
                                    }
                                </div>

                                
                            </div>
                        </div>
                    </div>

                    {/* <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader():
                                    <MyPointTokenTable />
                            }
                        </div>
                    </div> */}

                </div>

                <div className="dialog-section">
                    <Dialog 
                        header="Token Information" 
                        className="nw-dialog"
                        onHide={this.onHide} 
                        visible={this.state.visible}
                        dismissableMask={true}
                    >

                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-form-body">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="tracking-progress-view">
                                                    <div className={ progressMainClass1 }>
                                                        <div className="info-icon icon-bg-1">
                                                            <i class="fas fa-sign-in-alt"></i>
                                                        </div>
                                                        <div className="info-text">
                                                            <p>Submit</p>
                                                        </div>
                                                        <div className="info-button info-btn-1">
                                                            <i class="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>

                                                    <div className="next"><div></div></div>

                                                    <div className={ progressMainClass2 }>
                                                        <div className="info-icon icon-bg-1">
                                                            <i class="fas fa-spinner"></i>
                                                        </div>
                                                        <div className="info-text">
                                                            <p>Pending</p>
                                                        </div>
                                                        <div className="info-button info-btn-1">
                                                            <i class="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>

                                                    <div className="next"><div></div></div>

                                                    <div className={ progressMainClass3 }>
                                                        <div className="info-icon icon-bg-1">
                                                            <i class="fas fa-cogs"></i>
                                                        </div>
                                                        <div className="info-text">
                                                            <p>Process</p>
                                                        </div>
                                                        <div className="info-button info-btn-1">
                                                            <i class="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>

                                                    <div className="next"><div></div></div>

                                                    <div className={ progressMainClass4 }>
                                                        <div className="info-icon icon-bg-1">
                                                            <i class="fas fa-clipboard-check"></i>
                                                        </div>
                                                        <div className="info-text">
                                                            <p>Solved</p>
                                                        </div>
                                                        <div className="info-button info-btn-1">
                                                            <i class="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>

                                                    <div className="next"><div></div></div>

                                                    <div className={ progressMainClass5 }>
                                                        <div className="info-icon icon-bg-1">
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                        <div className="info-text">
                                                            <p>Rating</p>
                                                        </div>
                                                        <div className="info-button info-btn-1">
                                                            <i class="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-xl-12"></div>
                                            <div className="p-col-12 p-xl-12"></div>
                                            <div className="p-col-12 p-xl-12"></div>

                                            { tokenList && tokenList.tokenStatus == 10?

                                                <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Give Rating  <span>*</span></span>
                                                            <div className="nw-inputgroup-desc">
                                                                <Rating
                                                                    value={this.state.ratingValue} 
                                                                    cancel={false} 
                                                                    onChange={this.onChangeRatingValue}
                                                                    />
                                                            </div>
                                                            
                                                        </div>
                                                        <span className="error-message">{this.state.errors["ratingMark"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Comments <span>*</span></span>
                                                            <InputTextarea
                                                                id="textarea"
                                                                rows={3}
                                                                cols={30}
                                                                autoResize={true}
                                                                name="tokenDetails"
                                                                value={ratingObj && ratingObj.commentValue}
                                                                onChange={this.onChangeCommentValue}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["ratingComment"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                                        <div className="required-field">
                                                            (<span>*</span>) required fields
                                                        </div>

                                                        <Button
                                                            className="p-button-primary nw-button nw-button-right token-submit-button" //p-button-primary 
                                                            label="Submit"
                                                            icon="fas fa-check" //fas fa-spinner fa-spin
                                                            onClick={this.onSubmitRating}
                                                            disabled={ tokenList && tokenList.tokenStatus == 10? false:true }
                                                        />
                                                    </div>
                                                </div>
                                                :null
                                            }
                                            

                                            <div className="p-col-12 p-xl-12">

                                                <div className="nw-search-view">
                                                    <div>
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Token ID</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    { tokenList && tokenList.customTokenID }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Issue Date</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    { tokenList && NetiDateUtils.getAnyShortForm(tokenList.createDate, 'DD-MMM-YYYY hh:mm:ss a') }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Contact No.</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    { tokenList && tokenList.tokenContact }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Application</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    { tokenList && tokenList.tokenSource }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Module</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    { tokenList.tokenTypeInfoDTO && tokenList.tokenTypeInfoDTO.parentCoreCategoryInfoDTO && tokenList.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Problem Type</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    { tokenList.tokenTypeInfoDTO && tokenList.tokenTypeInfoDTO.categoryName }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Problem Details</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    { tokenList && tokenList.tokenDetails }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Attachment</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc attachment-download-button" style={{ display: 'flex', alignItems: 'center'}}>
                                                                    <DownloadButton fileName={ tokenList && tokenList.attachmentName } filePath={ tokenList && tokenList.attachmentPath } /> <span className="download-button-text">Attachment</span> 
                                                                </div>
                                                            </div>
                                                        </div>

                                                        { tokenList && tokenList.tokenStatus <= 1?

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup details">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Pending Duration</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc demo" id='demo'>
                                                                    { this.getRemainTime(tokenList && tokenList.createDate, null) }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :null
                                                        }


                                                        { tokenList && tokenList.tokenStatus == 10?
                                                            <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                                <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup details">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Solved Date</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc demo" id='demo'>
                                                                    { tokenList && NetiDateUtils.getAnyShortForm(tokenList.solveDate, 'DD-MMM-YYYY hh:mm:ss a') }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup details">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Solved Duration</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc demo" id='demo'>
                                                                    { this.getRemainTime(tokenList && tokenList.createDate, tokenList && tokenList.solveDate ) }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Solved Note</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc demo" id='demo'>
                                                                    { tokenList && tokenList.solveMessage }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :null
                                                        }
                                                            


                                                    </div>
                                                </div>
                                            </div>

                                            {/* { tokenList && tokenList.tokenStatus == 10?

                                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                                    <div className="required-field">
                                                        (<span>*</span>) required fields
                                                    </div>

                                                    <Button
                                                        className="p-button-primary nw-button nw-button-right token-submit-button" //p-button-primary 
                                                        label="Submit"
                                                        icon="fas fa-check" //fas fa-spinner fa-spin
                                                        onClick={this.onSubmitRating}
                                                        disabled={ tokenList && tokenList.tokenStatus == 10? false:true }
                                                    />
                                                </div>
                                                :null
                                            } */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </Dialog>
                </div>
            </div>
        )
    }
}