import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';
import { UserOtherAccessService } from '../UserOtherAccessService';

export class EmAdminPointService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
        this.userOtherAccessService = new UserOtherAccessService();
    }

    getInstituteByUser() {
        let uri = this.baseURIHolder.getUser() + '/em/institute/by/user';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserByCustomIDAndRole(customID, role) {
        let uri = this.baseURIHolder.getUser() + `/by/customid_role?customID=${customID}&role=${role}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserTaggingListByEmID(emID) {
        let uri = this.baseURIHolder.getUser() + `/em/user/tagging/by/emID?emID=${emID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEmInstituteListByType(paymentType) {
        let uri = this.baseURIHolder.getEmAdmin() + `/em/billable/institute/by?paymentType=${paymentType}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    payPrepaidBillByEmAdmin(prepaidBillInfo) {
        let uri = this.baseURIHolder.getEmAdmin() + '/prepaid/bill/pay';
        return this.Auth.postFetch(uri, prepaidBillInfo)
            .catch((error) => console.log('error', error));
    }

    saveModuleTaggingByAdmin(taggedModuleInfo) {
        let uri = this.baseURIHolder.getEmAdmin() + '/tag/sa/admin';
        return this.Auth.postFetch(uri, taggedModuleInfo)
            .catch((error) => console.log('error', error));
    }

    getEmAdminPostpaidBillSummary(emID) {
        let uri = this.baseURIHolder.getEmAdmin() + `/postpaid/bill/summary?emID=${emID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    payPostpaidBillByEmAdmin(postpaidBillInfo) {
        let uri = this.baseURIHolder.getEmAdmin() + '/postpaid/bill/pay';
        return this.Auth.postFetch(uri, postpaidBillInfo)
            .catch((error) => console.log('error', error));
    }

    sendEmAdminMessageTransferRequest(transferMessageInfo) {
        let uri = this.baseURIHolder.getEmAdmin() + '/message/transfer';
        return this.Auth.postFetch(uri, transferMessageInfo)
            .catch((error) => console.log('error', error));
    }

    // checkEmadminMessageRequest(transferRequestInfo){
    //     let uri = this.baseURIHolder.getEmAdmin() + '/message/transfer/check';
    //     return this.Auth.postFetch(uri, transferRequestInfo)
    //         .catch((error) => console.log('error', error));
    // }

    getEmAdminMessageTransferLogs(messageLog) {
        let uri = this.baseURIHolder.getEmAdmin() + '/message/transfer/logs';
        return this.Auth.postFetch(uri, messageLog)
            .catch((error) => console.log('error', error));
    }

    addEmAdminYssDevice(yssDeviceInfo) {
        let uri = this.baseURIHolder.getEmAdmin() + '/yss/device/tag';
        return this.Auth.postFetch(uri, yssDeviceInfo)
            .catch((error) => console.log('error', error));
    }

    getEmAdminYssPartnerPoints(limit, pageNo) {
        let uri = this.baseURIHolder.getEmAdmin() + '/yss/points?limit=' + limit + '&pageNo=' + pageNo;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createEmAdminPartnerYssConfig(yssConfigRequest) {
        let uri = this.baseURIHolder.getEmAdmin() + '/configure/yss';
        return this.Auth.postFetch(uri, yssConfigRequest)
            .catch((error) => console.log('error', error));
    }

    getYSSDevicesByUser(taggingStatus) {
        let uri = this.baseURIHolder.getEmAdmin() + `/yss/device/by/user?taggingStatus=${taggingStatus}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getAssignablePointsByEmAdminPoint() {
        let accessPath = this.baseURIHolder.getEmAdmin().split('/');
        return this.userOtherAccessService.getAccessablePoints(accessPath[accessPath.length - 1]);
    }

    updateEmAuditPointTaggingInstituteInfo(emUpdateInstituteInfo) {
        let uri = this.baseURIHolder.getEmAdmin() + '/sa/module/permission/update';
        return this.Auth.putFetch(uri, emUpdateInstituteInfo)
            .catch((error) => console.log('error', error));
    }

    updateEmAuditPointTaggingEnableStatus(emUpdateEnableStatus) {
        let uri = this.baseURIHolder.getEmAdmin() + '/sa/module/permission/update/status';
        return this.Auth.putFetch(uri, emUpdateEnableStatus)
            .catch((error) => console.log('error', error));
    }

    getInstitutePermissionList(instituteId) {
        let uri = this.baseURIHolder.getUser() + `/sa/eduman/modules/by/institute-id?instituteId=${instituteId}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getInstituteByEmAdminUser(netiID) {
        let uri = this.baseURIHolder.getEmAdmin() + `/em/sa/unpermitted/institute/by/user?netiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEmAdminYSSDevicesByRegistrationIds(regIDs) {
        let uri = this.baseURIHolder.getEmAdmin() + '/yss/devices/by/registration-ids';
        return this.Auth.postFetch(uri,regIDs)
            .catch((error) => console.log('error', error));
    }

        // Ops related report

        fetchOpsInstituteCollectionList() {
            let uri = this.baseURIHolder.getEmAdmin() + '/ops/summary';
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }
    
        fetchOpsDateWiseCollectionList(instituteID) {
            let uri = this.baseURIHolder.getEmAdmin() + '/report/ops/date-wise/collections/by?instituteID=' + instituteID;
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }
    
        fetchOpsStudentWiseCollectionList(date, instituteID) {
            let uri = this.baseURIHolder.getEmAdmin() + `/report/ops/student-wise/collections/by?date=${date}&instituteID=${instituteID}`;
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }
    
        fetchOpsInstituteTotalSettlementList(instituteID) {
            let uri = this.baseURIHolder.getEmAdmin() + '/report/ops/date-wise/settlement-amount/details/by/institute-id?instituteID=' + instituteID;
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }

        fetchInstituteList() {
            let uri = this.baseURIHolder.getEmAdmin() + '/core/clientSettlements';
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }

        fetchOpsCollectionSummaryList(instituteID, fromDate, toDate) {
            let uri = this.baseURIHolder.getEmAdmin() + `/date-wise/collection-summary/by?instituteID=${instituteID}&fromDate=${fromDate}&toDate=${toDate}`;
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }

        fetchOpsCollectionDetailsList(instituteID, fromDate, toDate) {
            let uri = this.baseURIHolder.getEmAdmin() + `/student-wise/collection-details/by?instituteID=${instituteID}&fromDate=${fromDate}&toDate=${toDate}`;
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }
        
        fetchOpsRefundDetailsListByTrnId(trnID) {
            let uri = this.baseURIHolder.getEmAdmin() + `/ops-details/by?trnID=${trnID }`;
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }

        fetchOpsRefundSummaryList(instituteID, fromDate, toDate) {
            let uri = this.baseURIHolder.getEmAdmin() + `/report/ops/date-wise/settlement-amount/details/by?instituteID=${instituteID}&fromDate=${fromDate}&toDate=${toDate}`;
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }

        fetchDfpsConnectedInstituteList() {
            let uri = this.baseURIHolder.getEmAdmin() + '/dfps/connected/institutes';
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }

        fetchDfpsInstituteWiseSummaryInfo() {
            let uri = this.baseURIHolder.getEmAdmin() + '/dfps/summary/infos';
            return this.Auth.getFetch(uri)
                .catch((error) => console.log("error", error));
        }
        
        // Ops related report end

}