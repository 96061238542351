import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import {Message} from 'primereact/message';
import Registrationadvancedform from './Registrationadvancedform';
import { RegistrationService } from '../../service/registration/RegistrationService';
import { GUEST_POINT } from '../../utils/PointWiseRouteConsts';
import NetizenLogo from "../../assets/images/login/logo.png";
import { ErrorMessageView } from '../common/ErrorMessageView';

let letterCheckedClass, numberCheckedClass, minCheckedClass, maxCheckedClass;
let errors = {};
export default class RegistrationPrimaryForm extends Component {

    constructor(props) {

        super(props)
        this.state = {
            userName: '',
            userNamematch: true,
            emptyPassword: false,
            password: '',
            reTypePassword: '',
            topProgressBar:false,
            regitrationadvancedform: false,
            passwordIcon: true,
            hidden: true,
            passwordMatch: false,
            inValidedUserName:null,
            upperCaseCheck:false,
            errors: {},
        }
        this.onSubmitHandler = this.onSubmitHandler.bind(this)
        this.onChangeUsername = this.onChangeUsername.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
        this.onChangeRetypePassword = this.onChangeRetypePassword.bind(this)
        this.togglePasswordShow = this.togglePasswordShow.bind(this)
        this.registrationService = new RegistrationService();
    }

    onChangeUsername = (event) => {
        let errors = {};
        if (event.target.value.toLowerCase() !== event.target.value) {
            errors["userName"] = "User name ( "+event.target.value +" ) is invalid. Use small letter. ";
            this.setState({ errors: errors });
        } else {
            errors["userName"] = "";
            this.setState({ errors: errors });
        }
        this.setState({ userName: event.target.value });
    }

    onChangePassword = (event) => {
        this.setState({password: event.target.value});}

    onChangeRetypePassword = (event) => {
        let errors = {};
        if (this.state.password === event.target.value) {
            errors["re-typePassword"] = "";
            this.setState({
                reTypePassword: event.target.value,
                passwordMatch: true,
                errors: errors
            });
        } else {
            this.setState({ reTypePassword: event.target.value });
            errors["re-typePassword"] = "Password does't match.";
            this.setState({ passwordMatch: false });
            this.setState({ errors: errors });
        }
    }

    togglePasswordShow() {
        this.setState({
            hidden: !this.state.hidden,
            passwordIcon: !this.state.passwordIcon
        });
    }

    showResponse() {
        this.growl.show({ severity: 'info', summary: 'Success', detail: 'User Responded' });
    }

    onSubmitHandler = () => {

        const userName = this.state.userName
        if (this.handleError()) {
            this.setState({ topProgressBar: true });
            this.registrationService.checkUserName(userName)
                .then((res) => {
                    console.log("RES::::", res);
                    
                    if(res === false 
                        && this.state.password === this.state.reTypePassword 
                        && !!this.state.password.match(/^(?=.*?[a-zA-Z]).{1,}$/)
                        && !!this.state.password.match(/^(?=.*?[0-9]).{1,}$/)
                        && this.state.password.length >= 6){
                        this.setState({ regitrationadvancedform: true,topProgressBar: false }) 
                    }else {
                        errors["password"] = "Should contain Min 1 letter & 1 number.";
                        this.setState({ regitrationadvancedform: false, errors: errors,topProgressBar: false })
                    }
                    res === false ? this.setState({ userNamematch: true,topProgressBar: false }) : this.setState({ userNamematch: false,topProgressBar: false })
                    res === true ? this.setState({ inValidedUserName: 'User name ( ' + this.state.userName + ' ) already exits.',topProgressBar: false }) : this.setState({ inValidedUserName: null })
                });
        }
    }

    handleError() {

        let errors = {};
        let formIsValid = true;

        if (this.state.userName === '') {
            formIsValid = false;
            errors["userName"] = "User name can't left empty.";
        }
        if (this.state.password === '') {
            formIsValid = false;
            errors["password"] = "Password can't left empty.";
        }
        

        // if(!this.state.password.match(/^(?=.*?[a-zA-Z]).{1,}$/)){
            // letterCheckedClass = "pi pi-check-circle text-danger"
        // }


        if (this.state.reTypePassword === '') {
            formIsValid = false;
            errors["re-typePassword"] = "Re-type password can't left empty.";
        } else if (!this.state.passwordMatch) {
            formIsValid = false;
            errors["re-typePassword"] = "Password does't match.";
        } 
        
        this.setState({ errors: errors });
        return formIsValid;
    }
    
    checkPassword = (password) =>{
        letterCheckedClass = numberCheckedClass = minCheckedClass = maxCheckedClass = "fas fa-check-circle" ;
        // return !!password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/);
        // return !!password.match(/^(?=.*?[a-zA-Z]).{1,}$/);
        if(!!password.match(/^(?=.*?[a-zA-Z]).{1,}$/)){
            letterCheckedClass = "fas fa-check-circle text-success"
        }
        if(!!password.match(/^(?=.*?[0-9]).{1,}$/)){
            numberCheckedClass = "fas fa-check-circle text-success"
        }
        if(password.length >= 6 && password.length < 16){
            minCheckedClass = "fas fa-check-circle text-success"
        }
        if(password.length === 16){
            maxCheckedClass = "fas fa-check-circle text-success"

            if(password.length >= 6 && password.length <= 16){
                minCheckedClass = maxCheckedClass = "fas fa-check-circle text-success"
            }
        }
        else if(password.length >= 6 && password.length <= 16){
            minCheckedClass = maxCheckedClass = "fas fa-check-circle text-success"
        }
    };

    render() {
        
        let {topProgressBar} = this.state;

        console.log("checkPassword", this.checkPassword(this.state.password));

        return (

            <div>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }

                {this.state.regitrationadvancedform === false ?
                    <div className="p-grid">
                        
                        <div className="p-col-12 p-md-12">
                        {this.state.userNamematch === false ? <Message severity="error" text={this.state.inValidedUserName} /> : ''}
                        </div>

                        <span className="p-float-label">
                            
                            <div className="p-col-12">
                                <InputText
                                    className="custom-field-control"
                                    placeholder="Enter User Name (character must be small letter)"
                                    name="userName"
                                    required="required"
                                    keyfilter="alphanum"
                                    value={this.state.userName}
                                    onChange={(event) => this.onChangeUsername(event)}
                                />
                                <span className="required-icon">*</span>
                            </div>


                            <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <InputText
                                        type={this.state.hidden ? "password" : "text"}
                                        name="password"
                                        className="custom-field-control"
                                        required="required"
                                        keyfilter="alphanum"
                                        placeholder="Enter Password"
                                        onChange={(event) => this.onChangePassword(event)}
                                        value={this.state.password}
                                        maxLength="16"
                                    />
                                    <Button
                                        icon={this.state.passwordIcon ? "far fa-eye-slash" : "far fa-eye"}
                                        className="p-button-secondary-custom"
                                        onClick={(event) => this.togglePasswordShow(event)} />
                                </div>
                                {/* <span className="required-icon">*</span> */}
                                <span className="error-message">{this.state.errors["password"]}</span>
                            </div>


                            <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <InputText
                                        className="custom-field-control"
                                        type={this.state.hidden ? "password" : "text"}
                                        name="re-password"
                                        placeholder="Re-Type Password"
                                        onChange={(event) => this.onChangeRetypePassword(event)}
                                        required="required"
                                        maxLength="16"
                                    />
                                    <Button
                                        icon={this.state.passwordIcon ? "far fa-eye-slash" : "far fa-eye"}
                                        className="p-button-secondary-custom"
                                        onClick={(event) => this.togglePasswordShow(event)}
                                    />
                                </div>
                                {/* <span className="required-icon">*</span> */}
                            </div>

                            <div className="p-col-12 p-xl-12">
                                {this.state.passwordMatch ? <Message severity="info" text="Password Matched" /> : <Message severity="warn" text="Password didn't Match" />}
                            </div>

                            <div className="p-col-12 p-xl-12">
                                {/* <Captcha siteKey="YOUR_SITE_KEY" onResponse={this.showResponse} /> */}

                                <div className="password-condition">
                                    <ul>
                                        <li>A Letter <i class={ letterCheckedClass }></i></li>
                                        <li>A number <i class={ numberCheckedClass }></i></li>
                                        <li>Min 6 characters <i class={ minCheckedClass }></i></li>
                                        <li>Max 16 characters <i class={ maxCheckedClass }></i></li>
                                    </ul>
                                </div>
                            </div>
                            <br />

                            <div className="p-col-12">
                                {/* <button 
                                    className="p-button p-component p-button p-button-text-icon-left custom-login-button gradiant-btn box-shadow" 
                                    onClick={this.onSubmitHandler}
                                >
                                    <span className="p-button-text p-c"><i class="pi pi-arrow-right"></i>Next</span>
                                </button> */}

                                <button 
                                    className="p-button p-component p-button p-button-text-icon-left custom-login-button gradiant-btn box-shadow" 
                                    type="submit" 
                                    onClick={this.onSubmitHandler}>
                                    <span className="p-button-text p-c">Next</span>
                                </button>
                            </div>

                            <span className="error-message">
                                {/* {this.state.usernameSearchErrors["userName"]} */}
                            </span>
                            <span className="error-message">
                                {/* {this.state.noUser} */}
                            </span>

                        </span>






                        
                        {/* <div className="p-col-12">
                            <button className="p-button p-component p-button-rounded p-button-text-icon-left custom-login-button" type="submit" onClick={event => this.onSubmitHandler(event)}>
                                <span className="p-button-text p-c">Next</span>
                            </button>
                        </div> */}
                        <br />
                        <div className="p-col-12 p-md-12">
                            <br />
                            <hr className="nw-signup-hr"/>
                            <br />
                            {
                                this.state.registration ?
                                    <h4>Have an account? <a href="#" onClick={this.loginHandler}>Log In</a> now.</h4>
                                    : <h4>Already have an account? <a href={GUEST_POINT.LOGIN} >Login</a> now.</h4>
                            }

                            <div className="copyright">
                                <img src={NetizenLogo} />
                                <span>POWERED BY <strong>NETIZEN IT LIMITED</strong></span>
                            </div>
                        </div>
                    </div>
                    :
                    <Registrationadvancedform userName={this.state.userName} password={this.state.password}/>}
            </div>
        )

    }

}