import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { BijoyPointService } from "../../../service/bijoyPoint/BijoyPointService";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';

export class ExistingUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseCode: "",
      purchaseCodeError: [],
      userInfoShow: false,
      userInfo: {
        productName: "",
        deviceID: "",
        password: "",
        mobileNo: ""
      },
      topProgressBar: false,
      searchIsLoading: false,
      errorMsgVisible: false,
      errorMsgBody: '',
      searchErrorMsgBody: '',
      searchView: false,
      searchViewError: false,

    };

    this.BijoyPointService = new BijoyPointService();
    this.NetiContentLoader = new NetiContentLoader();
  }

  purchaseCodeHandler = event => {
    this.setState({
      purchaseCode: event.target.value,
      purchaseCodeError: []
    });
  };

  searchUser = () => {
    if (this.errorHandler()) {
      this.setState({ topProgressBar: true, searchIsLoading: true, errorMsgVisible: false, userInfoShow: true, searchView: true });
      let { userInfo } = this.state;
      let purchaseCode = this.state.purchaseCode;
      this.BijoyPointService.getBijoyDeviceInfo(purchaseCode).then(res => {
        if (res.status === 200) {
          return res.json().then(body => {
            this.setState({ userInfo: body });
            this.setState({ userInfoShow: true });
            this.setState({ topProgressBar: false, searchIsLoading: false, errorMsgVisible: false, searchViewError: false });
          });
        } else {
          this.BijoyPointService.Auth.handleErrorStatus(res).then(errorRes => {
            this.setState({ userInfoShow: false });
           
            this.setState({ topProgressBar: false, searchViewError: true, searchIsLoading: false, searchErrorMsgBody: errorRes });
          });
        }
      }).catch(error => {
        this.setState({ userInfoShow: false, searchView: false, searchViewError: false, topProgressBar: false, searchIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection' })
      });
    }
  };

  errorHandler() {
    let { purchaseCode, purchaseCodeError } = this.state;
    let formIsValid = true;
    this.setState({ searchView: false });

    if (purchaseCode === "") {
      formIsValid = false;
      purchaseCodeError["purchaseCode"] = "Purchase Code can't left empty";
      this.setState({ userInfo: "" });
      this.setState({ userInfoShow: false });
    }
    if (isNaN(purchaseCode)) {
      formIsValid = false;
      purchaseCodeError["purchaseCode"] = "Invalid Purchase Code";
    }

    this.setState({ purchaseCodeError });
    return formIsValid;
  }

  focusSearch = e =>{
      this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
  }

  blurSearch = e =>{
      this.setState({ searchLabel: '', addSearchBtnClass: ""  });
  }

  copySelection = () =>{
    var copyText = document.getElementById("myInput");
    
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    
    // var tooltip = document.getElementById("myTooltip");
    // tooltip.innerHTML = "Copied: " + copyText.value;

    this.setState({
      copyText: "Copied"
    })
  }


  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

    return (
      <div className="p-fluid">
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <Growl ref={el => (this.growl = el)} position="topright" />
        <div className="p-grid nw-form">
          <div className="p-col-12 p-xl-12">
            <div className="nw-form-body">
             <div className="p-col-12 p-xl-12">

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    Purchase Code <span>*</span>
                  </span>
                  <InputText
                    placeholder="Enter Purchase Code"
                    onChange={event => this.purchaseCodeHandler(event)}
                    keyfilter="num"
                    style={{ width: "15%" }}
                  />
                  <Button 
                    icon="fas fa-search" 
                    onClick={this.searchUser} 
                    className={"p-button-animation " + this.state.addSearchBtnClass}
                    label={ this.state.searchLabel}
                    onFocus={ this.focusSearch }
                    onBlur={ this.blurSearch }
                  />
                </div>
                <span className="error-message">
                  {(this.state.purchaseCode === "" ||
                    isNaN(this.state.purchaseCode)) &&
                    this.state.purchaseCodeError["purchaseCode"]
                  }
                </span>
              </div>
            
                {this.state.userInfoShow === true ? (
                  <div className="p-col-12 p-xl-12">
                    <div className="nw-search-view">
                      <div>

                          {this.state.searchIsLoading ?
                            this.NetiContentLoader.normalFormInputField() :
                            <div className="p-col-12 p-xl-12">
                              <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon">Product Name</span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                  {this.state.userInfo.productName || '-'}
                                </div>
                              </div>
                            </div>
                          }

                          {this.state.searchIsLoading ?
                            this.NetiContentLoader.normalFormInputField() :

                            <div className="p-col-12 p-xl-12">
                              <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon">Password</span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                  <input className="nw-copyText" type="text" value={ this.state.userInfo.password } id="myInput"/>
                                  {/* <span className="nw-copyText" id="nw-copyText">{this.state.userInfo.password || "-"}</span> */}
                                  <Button 
                                    icon="fas fa-save" 
                                    onClick={this.copySelection} 
                                    className={" no-border"}
                                    label=""
                                    tooltip={ this.state.copyText || "Copy" }
                                  />
                                </div>
                                
                              </div>
                            </div>
                          }

                          {this.state.searchIsLoading ?
                            this.NetiContentLoader.normalFormInputField() :

                            <div className="p-col-12 p-xl-12">
                              <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon">Mobile No.</span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                  {this.state.userInfo.mobileNo || '-'}
                                </div>
                              </div>
                            </div>
                           }
                      </div>
                    </div>
                  </div>
                  
                ) : (
                    <div className="p-col-12 p-xl-12">
                      {this.state.searchView ?
                        <div className="nw-search-view">
                          {this.state.searchViewError ?
                            <center className="error-message">{this.state.searchErrorMsgBody || 'No Data Found'}</center> : ''}
                        </div> : ''
                      }

                      {/* <div className="p-col-12 p-xl-12">
                        <div className="text-center">
                          <div className="devicePassword">
                            <i className="fa fa-phone-square" />
                          </div>
                          <h2>Contact Us</h2>
                          <span>
                            Mobile No: <b>01722123455</b>
                          </span>
                        </div>
                      </div> */}

                    </div>
                  )}

             

            </div>
           </div>
          
           
          </div>
         </div>
      </div>
    );
  }
}
