import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { DataView } from "primereact/dataview";
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { UserCategoryService } from "../../../service/UserCategoryService";
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { AdmisiaApplicantPrevExam } from '../AdmisiaApplicantPrevExam';
import NetiContentLoader from '../../common/NetiContentLoader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
let urlId;
export class AdmisiaAtaGlance extends Component {
    constructor() {
        super();
        this.state = {
            applicantOtherInfoObj: {},
            applicantExamInfoObj: {},
            applicantID: '',
            academicYear: '',
            dataTableView: false,
            firstDropdownIsLoading: false,
            academicYearErr: {},
            academicYearList: [],
            dataTableValue: [],
            otherInfoValue: [],
            examInfoValue: [],
            AllTutorialInfoList: [],
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            layout: 'list',
        };
        this.admisiaPointService = new AdmisiaPointService();
        this.netiFileHandler = new NetiFileHandler();
        this.userCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
        // this.export = this.export.bind(this);
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentDidMount();
            }, 1000);
        }
    }


    componentDidMount() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        this.getAcademicYearListByTypeID();
   
        // this.getOtherInfoList();
    }
    


    getAcademicYearListByTypeID = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true });
        this.userCategoryService.fetchListByDefaultCode("T107")
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ academicYearList: body, firstDropdownIsLoading: false });

                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, firstDropdownIsLoading: false, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log(error);
                this.setState({ firstDropdownIsLoading: false })
                // this.growl.show({severity: 'warn', summary: 'Warning', detail: 'Connection Problem'});
            });
    }




    onChangeAcademicYear = (e) => {
        let { academicYear } = this.state;
        this.setState({ academicYear: e.target.value });
    }

    onSearchHandler = () => {
        this.setState({ topProgressBar: true });
        if (this.searchAcademicYearError()) {
            this.admisiaPointService.getApplicantListForGlance(this.state.academicYear, urlId)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            console.log("body list glance", body);
                            
                            this.setState({ dataTableValue: body });  
                            this.setState({ dataTableView: true });

                            // this.state.applicantID = body.map(item => item.admisiaApplicantInfoDTO);
                           
                            // console.log("ITEM", item.admisiaApplicantInfoDTO);

                            
                        });
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    console.log(error);
                    // this.growl.show({ severity: 'warn', summary: 'Warning', detail: 'Connection Problem' });
                });
        }
    }



  

    getOtherInfoList = (e, item) => {
        this.setState({ visibleOtherInfo: true });
        console.log("Item Other Info", item);

        this.setState({ topProgressBar: true });
       
        this.setState({applicantOtherInfoObj: item.admisiaApplicantInfoDTO});

        this.admisiaPointService.getOtherInfoByID(item.admisiaApplicantInfoDTO.admisiaApplicantInfoId)  
        .then(res => {
            this.setState({ topProgressBar: false });
            if (res.status == 302) {
                return res.json().then((body) => {
                    console.log("OtherInfoList", body);

                    this.setState({ otherInfoValue: body });  
                    
                    // console.log("OtherInfoList", this.state.dataTableValue.admisiaApplicantInfoId);
                  
                    
                });
            } else {
                this.admisiaPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false });

            console.log(error);
        });

    }


    getExamInfoList = (e, item) => {
        this.setState({ visible: true });
        this.setState({ topProgressBar: true });
        this.setState({applicantExamInfoObj: item.admisiaApplicantInfoDTO})

        this.admisiaPointService.getPreviousInfoByID(item.admisiaApplicantInfoDTO.admisiaApplicantInfoId)  
        .then(res => {
            this.setState({ topProgressBar: false });
            if (res.status == 302) {
                return res.json().then((body) => {
                    
                    console.log("ExamInfoList", body);
                    this.setState({ examInfoValue: body });  
                    
                    // console.log("OtherInfoList", this.state.dataTableValue.admisiaApplicantInfoId);
                  
                });
            } else {
                this.admisiaPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false });

            console.log(error);
        });

    }
    

    searchAcademicYearError = () => {
        let { academicYearErr } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;
        if (this.state.academicYear === '') {
            formIsValid = false;
            academicYearErr["academicYear"] = 'Academic Year can not left empty.';
        }
        this.setState({ academicYearErr });
        return formIsValid;
    }

    previousExamInfoHandler = () => {
        this.setState({ visible: true });
    }

    othersInfoHandler = () => {
        this.setState({ visibleOtherInfo: true });

    }

    onHideOtherInfo = (event) => {
        this.setState({ visibleOtherInfo: false });
    }


    onHide = (event) => {
        this.setState({ visible: false });
    }

    renderListItem = (item) => {
        console.log("renderListItem", item.admisiaApplicantInfoDTO.admisiaApplicantInfoId);

        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Other Info List<span style={{ 'float': 'right' }}>Total Found: {this.state.otherInfoValue.length} </span></div>;
  
        //prevExamInfoRequired 
        
        return (
            <div className="p-col-12" style={{ padding: '1em', borderBottom: '1px solid #d9d9d9' }}>
                <div className="p-col-12 p-xl-12">
                    <div className="p-grid p-col-12">
                        <div className="p-col-12 p-col-2 p-xl-2" style={{ borderRight: '1px solid #d9d9d9' }}>
                            <div className="p-col-12" style={{ paddingTop: "45px" }}>
                                <div className="p-col-12 p-md-4"> <h2>{item.admisiaApplicantInfoDTO.registrationID}</h2></div>
                                <div className="p-col-12 p-md-4">Class:<span style={{ paddingLeft: "5px" }}>{item.admisiaApplicantInfoDTO.admisiaClassConfigDTO.classCoreCategoryInfoDTO.categoryName}</span></div>
                                <div className="p-col-12 p-md-4">Group:<span style={{ paddingLeft: "5px" }}>{item.admisiaApplicantInfoDTO.admisiaClassConfigDTO.groupCoreCategoryInfoDTO.categoryName}</span></div>
                            </div>
                        </div>
                        <div className="p-col-10 p-xl-10" >
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-8" style={{ paddingLeft: "30px" }}>
                                    <div className="p-col-12" style={{ paddingLeft: "8px" }}><h2>{item.admisiaApplicantInfoDTO.applicantName}</h2></div>
                                    <div className="p-grid p-md-8 p-lg-8" >
                                        <div className="p-md-6 p-lg-6">
                                            {item.admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName}
                                        </div>
                                        <div className="p-md-6 p-lg-6">
                                            {item.admisiaApplicantInfoDTO.religionCoreCategoryInfoDTO.categoryName}
                                        </div>

                                        <div className="p-md-6 p-lg-6">
                                            <span style={{ paddingRight: "5px" }}> <i class="fas fa-mobile fa-2x"></i></span> {item.admisiaApplicantInfoDTO.mobileNo}
                                        </div>

                                        <div className="p-md-6 p-lg-6">
                                            <span style={{ paddingRight: "5px" }}> <i class="fas fa-birthday-cake fa-2x"></i></span>{NetiDateUtils.getDateFromString(item.admisiaApplicantInfoDTO.dateOfBirth)}
                                        </div>

                                        <div className="p-md-6 p-lg-6">
                                            <span style={{ paddingRight: "5px" }}> <i class="fas fa-mars fa-2x"></i></span> {item.admisiaApplicantInfoDTO.fatherName} - {item.admisiaApplicantInfoDTO.fatherOccupationCategoryInfoDTO.categoryName}
                                        </div>

                                        <div className="p-md-6 p-lg-6">
                                            <span style={{ paddingRight: "5px" }}> <i class="fas fa-venus fa-2x"></i></span> {item.admisiaApplicantInfoDTO.motherName} - {item.admisiaApplicantInfoDTO.motherOccupationCategoryInfoDTO.categoryName}
                                        </div>

                                        <div className="p-md-6 p-lg-6">
                                            <span style={{ paddingRight: "5px" }}> <i class="fas fa-map-marker fa-2x"></i></span> {item.admisiaApplicantInfoDTO.addressDetails}
                                        </div>

                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-4" >
                                    <div className="p-col-12 p-col-12">
                                        <center>
                                            <img src={item.admisiaApplicantInfoDTO.photoContent !== null ? `data:image/png;base64, ${item.admisiaApplicantInfoDTO.photoContent}` : `assets/images/demo.jpg`}
                                                width="80px"
                                                alt="Applicant Image"
                                            />
                                        </center>
                                    </div>


                                    <div className="p-col-12 p-xl-12" >

                                        {item.admisiaApplicantInfoDTO.admisiaClassConfigDTO.prevExamInfoRequired == 0 ?
                                        
                                            "" 
                                            :

                                            <div className="p-col-12 p-col-12">
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-center"
                                                    label="Previous Exam"
                                                    onClick={(e) => this.getExamInfoList(e, item)}
                                                />
                                            </div>

                                        }


                                        {item.admisiaApplicantInfoDTO.admisiaClassConfigDTO.othersInfoRequired == 0 ?

                                         ""
                                         :

                                         <div className="p-col-12 p-col-12">
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-center"
                                                label="Other Info"
                                                // onClick={this.othersInfoHandler}
                                                onClick={(e) => this.getOtherInfoList(e, item)}
                                            />
                                         </div>

                                        }

    
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <Dialog 
                        className="text-center nw-dialog" 
                        header="Applicant Prev Exam Info" 
                        visible={this.state.visible}
                        onHide={this.onHide} 
                        maximizable
                    >
                            {/* {item.admisiaApplicantPreviousExamInfoResponseDTOList ?
                                Object.keys(item.admisiaApplicantPreviousExamInfoResponseDTOList).map(key => (
                                    <AdmisiaApplicantPrevExam
                                        key={key}
                                        index={key}
                                        applicantPrevExamInfo={item.admisiaApplicantPreviousExamInfoResponseDTOList[key]}
                                    />
                            )) : null} */}

                            <AdmisiaApplicantPrevExam

                            // applicantPrevExamInfo = {this.state.examInfoValue}  
                            
                            applicantPrevExamInfo = {this.state.applicantExamInfoObj}
                            applicantPrevDataList = {this.state.examInfoValue}

                            />


                        
                    </Dialog>

                    <Dialog 
                        className="nw-dialog" 
                        header="Applicant Other Info" 
                        visible={this.state.visibleOtherInfo}
                        onHide={this.onHideOtherInfo} 
                        maximizable
                    >

                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-grid nw-form-body">
                                       
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Applicant ID
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.applicantOtherInfoObj.registrationID || ""}
                                                        
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                   
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Student Name
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.applicantOtherInfoObj.applicantName  || "" } 
                                                        
                                                        </div>
                                                    </div>
                                                 
                                                </div>


                                                <div className="p-col-12 p-xl-12">
                                                    <div className="nw-data-table">
                                                        {
                                                    
                                                        this.state.dataTableIsLoading ?
                                                            this.NetiContentLoader.MyPointTableLoader()
                                                            :
                                                            <DataTable
                                                                header={header}
                                                                // footerColumnGroup={footerBEP}
                                                                responsive={true}
                                                                selectionMode="single"
                                                                paginator={true}
                                                                rows={10}
                                                                value={this.state.otherInfoValue}
                                                            >
                                                                <Column field="columnTitle" header="Title" filter={true} />
                                                                <Column field="columnValue" header="Detail" filter={true} />
                                                            
                                                            </DataTable>
                                                        }
                                                
                            
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                           


                        
                    </Dialog>

                </div>
            </div>
        );
    }

    itemTemplate = (item) => {
        if (!item) {
            return null;
        }
        else {
            return this.renderListItem(item);
        }
    }

  

    renderHeader = () => {
        return (
            // <div className="p-grid">
            //     <div className="p-col-6" style={{ textAlign: 'right' }}>
            //         <span>Applicants Info</span>
            //     </div>
            //     <div className="p-col-6" style={{ textAlign: 'right' }}>
            //         <span
            //             style={{ color: "white", display: "flex", float: "right" }}
            //         >
            //             Total Found: {this.state.dataTableValue.length}
            //         </span>
            //     </div>
            // </div>

            <div className="p-clearfix">
                Applicants Info
                <span>Total Found: {this.state.dataTableValue.length} </span>
            </div>
        );
    }

    // export() { 

    //     this.dt.exportCSV();

    // }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, errors, dataTableValue } = this.state;
        const header = this.renderHeader();

        let academicYear = [];
        if ((this.state.academicYearList != null) && (this.state.academicYearList.length > 0)) {
            academicYear = this.state.academicYearList.map((item) => ({
                value: item.categoryName,
                label: item.categoryName,
            }));
        }

        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    <div className="p-col-12 p-xl-12">
                        <Growl ref={(el) => this.growl = el} />

                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-9">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Academic year</span>
                                        <Dropdown
                                            placeholder="Select Academic Year"
                                            value={this.state.academicYear}
                                            options={academicYear}
                                            onChange={this.onChangeAcademicYear}
                                            showClear={true}
                                            autoWidth={false}
                                            name="academicYear"
                                        />
                                    </div>
                                }
                                <span className="error-message">{this.state.academicYearErr["academicYear"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-3 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onSearchHandler}
                                />
                            </div>
                        </div>
                    </div>

                    {this.state.dataTableView == true ?
                        <div className="p-col-12 p-xl-12">
                            <div className="card card-w-title">

                                <div className="content-section implementation nw-data-table">
                                    <DataView
                                        value={this.state.dataTableValue}
                                        layout={this.state.layout}
                                        header={header}
                                        itemTemplate={this.itemTemplate}
                                        paginatorPosition={'both'}
                                        paginator={true}
                                        rows={5}
                                        ref={(el) => { this.dt = el; }}

                                       
                                    />
                                </div>
                            </div>

                            {/* <div className="nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Download"
                                    icon="fas fa-arrow-down"
                                    onClick={this.export}

                                />
                            </div> */}
                            <br />
                            <br />

                        </div>
                        : null}
                </div>
            </div>
        );
    }
}

