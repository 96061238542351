import React, { component, Component } from "react";
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Dropdown } from 'primereact/dropdown';
import NetiContentLoader from "../../common/NetiContentLoader";

import { EmAdminPointService } from "../../../service/emAdminPoint/EmAdminPointService";
import { NetiDateUtils } from "../../../utils/NetiDateUtils";

let maxDate = new Date();
let totalLengthOfDT = 0;
let selectedInstituteName = '';
let headerName = '';

export class OnlineCollectionRefundSummary extends Component {

    constructor() {
        super();
        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            firstDropdownIsLoading: false,
            errors: {},
            instituteList: [],
            requestedSearchObj: {
                startDate: "",
                endDate: "",
                instiuteID: '',
            },
            dataTableValue: [],
        }
        this.ValidatorUtility = new ValidatorUtility();
        this.NetiContentLoader = new NetiContentLoader();

        this.emAdminPointService = new EmAdminPointService();
        this.fetchInstituteListForDropDown = this.fetchInstituteListForDropDown.bind(this);

    }

    componentWillMount() { this.fetchInstituteListForDropDown(); }

    fetchInstituteListForDropDown() {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.emAdminPointService.fetchDfpsConnectedInstituteList()
            .then((res) => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ instituteList: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    });

                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }

            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    onChangeStartDate = (e) => {

        let { requestedSearchObj } = this.state;
        requestedSearchObj[e.target.name] = e.target.value;
        this.setState({ requestedSearchObj });
        this.clearEmptyErrorMsg(e.target.name);

    }

    onChangeEndDate = (e) => {

        let { requestedSearchObj } = this.state;
        requestedSearchObj[e.target.name] = e.target.value;
        this.setState({ requestedSearchObj });
        this.clearEmptyErrorMsg(e.target.name);

    }

    onChangeInstiuteID = (e) => {

        let { requestedSearchObj } = this.state;
        requestedSearchObj[e.target.name] = e.target.value;
        this.setState({ requestedSearchObj });
        this.clearEmptyErrorMsg(e.target.name);

    }

    searchHandleError = () => {

        let { errors, requestedSearchObj } = this.state;
        let formIsValid = true;

        if (requestedSearchObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "From Date can't left empty.";
        }
        if (requestedSearchObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "To Date can't left empty.";
        }
        if (requestedSearchObj.instiuteID === '') {
            formIsValid = false;
            errors["instiuteID"] = "Instiute ID can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    clearEmptyErrorMsg(name) {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onSubmitHandler = () => {

        if (this.searchHandleError()) {

            let { requestedSearchObj } = this.state;

            let formatedStartDate = NetiDateUtils.getDateFromStringDDMMYYYY(requestedSearchObj.startDate);
            let formatedEndDate = NetiDateUtils.getDateFromStringDDMMYYYY(requestedSearchObj.endDate);

            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.emAdminPointService.fetchOpsRefundSummaryList(requestedSearchObj.instiuteID, formatedStartDate, formatedEndDate)
                .then((res) => {

                    if (res.status == 302) {
                        return res.json().then((body) => {
                            totalLengthOfDT = body.length;
                            this.setState({ dataTableValue: body });

                            // for table header institute name
                            if (this.state.instituteList && requestedSearchObj.instiuteID) {
                                this.state.instituteList.filter(item => {
                                    if (item.instituteId === requestedSearchObj.instiuteID) { selectedInstituteName = item.instituteName }
                                })
                                headerName = '"' + selectedInstituteName + '" '
                            }

                            this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        });

                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                    }

                }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

                );

        }
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, requestedSearchObj, dataTableValue } = this.state;

        // all dropdown options 
        let instituteOptions = [];
        if (this.state.instituteList && this.state.instituteList.length) {
            instituteOptions = this.state.instituteList.map((item) => ({
                value: item.instituteId,
                label: item.instituteId,
            }));

        }

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>{headerName} OPS Refund Amount Info <span style={{ 'float': 'right' }}> Total Found: {totalLengthOfDT} </span></div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section crm-dashboard">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            name='startDate'
                                            value={requestedSearchObj.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            name='endDate'
                                            value={requestedSearchObj.endDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            dateFormat='dd/mm/yy'
                                            yearRange="2010:2030"
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    {this.state.firstDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-small-span">Instiute ID <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Instiute ID"
                                                value={requestedSearchObj.instiuteID}
                                                options={instituteOptions}
                                                onChange={(e) => { this.onChangeInstiuteID(e) }}
                                                // itemTemplate={this.bankAccountTemplate}
                                                name='instiuteID'
                                                filter={true}
                                                filterBy="value"
                                                showClear={true}
                                                autoWidth={false}
                                            />
                                            {/* <span className="p-inputgroup-addon">@</span> */}
                                        </div>

                                    }
                                    <span className='error-message'>{this.state.errors["instiuteID"]}</span>
                                    {/* <span style={{textAlign:"right"}}>{accountNumberDetails.minRecharge !== undefined && accountNumberDetails.maxRecharge !== undefined ? `Minimum Recharge: ${accountNumberDetails.minRecharge} - Maximum Recharge: ${accountNumberDetails.maxRecharge}`: ''}</span> */}
                                </div>
                                <div className="p-col-12 p-lg-12 nw-button-parent">
                                    <div class="required-field">(<span>*</span>) required fields</div>
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid">
                            <div className="nw-data-table nw-data-table-tabview">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12 m-b-20">
                                        <DataTable
                                            value={dataTableValue}
                                            header={tableHeader}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                        >
                                            <Column field="settlementDate" header="Date" filter={true} />
                                            <Column field="instituteBank" header="Settlement Bank" filter={true} />
                                            <Column field="accountNumber" header="Settlement Account" filter={true} />
                                            <Column field="amount" header="Amount" filter={true} />
                                            <Column field="settlementBy" header="Settlement By" filter={true} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}