import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { ProfileService } from '../../../service/profile/ProfileService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { UserOtherAccessService } from '../../../service/UserOtherAccessService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { InputTextarea } from 'primereact/inputtextarea';



export class PersonalBankInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userBankInfoObj: [],
            updateUserObj:{
                userBasicInfoDTO:{
                    netiID: ''
                },
                userBankAccountTaggingDTO:{ 
                    taggingID: '',
                    taggingTypeCoreCategoryInfoDTO:{
                        coreCategoryID: ''
                    },   
                    userBankAccountInfoDTO:{
                        userBankAccId: '',
                        coreCategoryInfoDTO:{
                            coreCategoryID:''
                        },
                        coreBankBranchInfoDTO:{
                            branchID: ''
                        }
                    }
                },
            },
            insertUserObj:{
                userBasicInfoDTO:{
                    netiID:''
                },
                userBankAccountTaggingDTO: {
                    taggingTypeCoreCategoryInfoDTO: {
                        coreCategoryID: ''
                    },
                    userBankAccountInfoDTO: {
                        userBankAccId: '',
                        bankAccNumber: "",
                        bankAccHolderName: "",
                        bankNote: "",
                        // "accEnableStatus": 1,
                        chequeSlipContent: "",
                        chequeSlipEditable: false,
                        chequeSlipName: "",

                        othersAttachmentContent: "",
                        othersAttachmentEditable: false,
                        othersAttachmentName: "",
                        
                        coreCategoryInfoDTO: {
                            coreCategoryID: ''
                        },
                        coreBankBranchInfoDTO: {
                            branchID: ''
                        }
                    }
                },
            },
            bankList: [],
            bankAccountInfo: {},
            branchList: [],
            districtList: [],
            taggingList: [],
            chequeLeafFile: {
                extention: '',
                contentPic: '',
                contentName: '',
            },
            othersFile: {
                extention: '',
                contentPic: '',
                contentName: '',
            },
            visibleDialog: false,
            visibleEditDialog: false,
            firstDropDownIsLoading: true,
            secondDropdownIsLoading: false,
            thirdDropdownIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            errors: {}
        }
        this.CommonFuctionality = new CommonFuctionality();
        this.ProfileService = new ProfileService();
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.userCategoryService = new UserCategoryService();
        this.userOtherAccessService = new UserOtherAccessService();

    }

    componentWillMount(){
        // this.getUserBankInfo();
    }

    componentDidMount() {
        let { insertUserObj, updateUserObj } = this.state

        insertUserObj.userBasicInfoDTO.netiID = JSON.parse(localStorage.getItem("Profile")).netiID;
        updateUserObj.userBasicInfoDTO.netiID = JSON.parse(localStorage.getItem("Profile")).netiID;
        // for (var a in localStorage) {
        //     console.log(a, ' = ', localStorage[a]);
        // }
        this.fetchBankList();
        this.fetchUserBankAccountList();
        this.getDistrictList();
        this.getTaggingList();
    }

    getTaggingList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode('T123')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ taggingList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    getDistrictList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode('T10203')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ districtList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    fetchBankList() {
        this.setState({ firstDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.userCategoryService.fetchListBy2ndParentTypeDefCode('T100')
            .then(res => {
                if (res.status === 302) {
                    this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    return res.json().then((body) => { this.setState({ bankList: body }) })
                } else {
                    return res.json().then((errorBody) => {
                        this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorBody.message });
                    })
                };
            }).catch(error => {
                this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
            });
    }

    fetchUserBankAccountList = () => {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.ProfileService.findBankAccountByUser()
          .then(res => {
            if (res.status === 302) {
              return res.json().then((body) => {
                // for (let i = 0; i < body.length; i++) {
                //   if (body[i].userBankAccountInfoDTO.enableStatus === 1) {
                //     body[i].userBankAccountInfoDTO.enableStatus = 'Active';
                //   } else if (body[i].userBankAccountInfoDTO.enableStatus === 0) {
                //     body[i].userBankAccountInfoDTO.chatboxDate = 'Inactive';
                //   }
                // }
                this.setState({ userBankInfoObj: body })
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
              })
            } else {
              this.userOtherAccessService.Auth.handleErrorStatus(res)
                .then((resp) => {
                  this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
                });
            };
          }).catch(error => {
            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
          });
      }

    getUserBankInfo = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ProfileService.fetchUserBankInfo()
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        this.setState({ userBankInfoObj: body, topProgressBar: false, errorMsgVisible: false });
                        // this.getSlipFile(rowData.attachFilePath,rowData.attachFileName);
                    })
                }
                else {
                    this.ProfileService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    chequeLeafFileUpload(e) {
        let { errors } = this.state;
        errors['attachment'] = ''

        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let fileObj = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ chequeLeafFile: fileObj });
        }
    }

    othersFileUpload(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let fileObj = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ othersFile: fileObj });
        }
    }

    chequeLeafemoveButtonOnclick = () => {
        this.setState({ chequeLeafFile: { contentPic: '' } });
        this.setState({ chequeLeafFile: { contentName: '' } });
    }

    othersRemoveButtonOnclick = () => {
        this.setState({ othersFile: { contentPic: '' } });
        this.setState({ othersFile: { contentName: '' } });

    }

    getSlipFile = (filepath, fileName) => {
        let { depositApproveObj } = this.state;
        this.setState({ downloadSlip: '' });
        if (filepath !== null) {
            this.netiFileHandler.getByteImage(filepath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(fileName);
                                this.setState({ downloadSlip: 'data:image/*;base64,' + body.fileContent });
                                
                                let a = document.createElement("a");
                                a.href = contentType + body.fileContent;
                                a.download = fileName;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                            })
                    } else {
                    }
                });
        }
    }


    onChangeDialogHide = () => {
        this.setState({ visibleDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showDialog = () => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visibleDialog: true });
    }

    onChangeEditDialogHide = () => {
        this.setState({ visibleEditDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showEditDialog = (itemData) => {
        let { updateUserObj } = this.state
        updateUserObj.userBankAccountTaggingDTO.taggingID = itemData.taggingID;
        updateUserObj.userBankAccountTaggingDTO.taggingTypeCoreCategoryInfoDTO.coreCategoryID = itemData.taggingTypeCoreCategoryInfoDTO.coreCategoryID
        updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.userBankAccId = itemData.userBankAccountInfoDTO.userBankAccId

        updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccHolderName = itemData.userBankAccountInfoDTO.bankAccHolderName
        updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccNumber = itemData.userBankAccountInfoDTO.bankAccNumber

        updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID = itemData.userBankAccountInfoDTO.coreBankBranchInfoDTO.coreBankInfoDTO.coreCategoryID
        updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchID = itemData.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchID
        updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchName = itemData.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchName

        this.fetchBankBranchesList(itemData.userBankAccountInfoDTO.coreBankBranchInfoDTO.coreBankInfoDTO.coreCategoryID, itemData.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchID);

        this.setState({ visibleEditDialog: true, updateUserObj, errors: {} });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onChangeTaggingType = (e, action) => {
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.userBankAccountTaggingDTO.taggingTypeCoreCategoryInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.userBankAccountTaggingDTO.taggingTypeCoreCategoryInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
      };

    onChangebankName = (e, action) =>{
        let { bankList, updateUserObj, insertUserObj, district } = this.state;

        if(action == 'insert'){
            insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID = e.target.value
            updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchName = ''
        }

        if(district){
            this.fetchBankBranchesList(e.target.value, district);
        }

        for (let i = 0; i < bankList.length; i++) {
            if (bankList[i].userBankAccountID == e.target.value) {
                this.setState({ bankAccountInfo:bankList[i] });
            }
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    // onChangebankName = e => {
    //     this.setState({ bankName: e.target.value, errors: {} })
    //   };
    

    onChangeBranch =(e, action) => {
        let { bankList, updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);

        if (this.state.branchList && this.state.branchList.length) {
            for (let i = 0; i < this.state.branchList.length; i++) {
                if (this.state.branchList[i].branchID === e.target.value) {
                    this.setState({ routingNumber: this.state.branchList[i].routingNumber });
                }
            }
        }
    }

    onUpdateBranchID = (e) => {
        this.setState({ updateBranchID: e.target.value });
    }

    onChangeAccountHolderName = (e, action) => {
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccHolderName = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccHolderName = e.target.value
        }
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);        
    };

    onChangeAccountNumber = (e, action) => {
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccNumber = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccNumber = e.target.value
        }
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);        
    }
    
    onChangeAccountDetails = (e, action) => {
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankNote = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankNote = e.target.value
        }
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);        
    }

    onChangeAccountType = e => {
        this.setState({ accountType: e.target.value, errors: {} })
    };

    

    onChangeDistrict = (e, action) => {
        let { updateUserObj, insertUserObj } = this.state;

        this.setState({ district: e.target.value })
        this.onClearErrorMsg(e.target.name);

        if(action == 'insert'){
            this.fetchBankBranchesList(insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID, e.target.value);
        }
        else if(action == 'update'){
            this.fetchBankBranchesList(updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID, e.target.value);
        }
    }

    fetchBankBranchesList(param1, param2) {
        this.setState({ branchList: [], thirdDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.userOtherAccessService.findAllBankBranches(param1, param2)
          .then(res => {
            if (res.status === 302) {
              return res.json().then((body) => {
                this.setState({ branchList: body })
                this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
              })
            } else {
              this.userOtherAccessService.Auth.handleErrorStatus(res)
                .then((resp) => {
                  this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
                });
            };
          }).catch(error => {
            this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
          });
      }

    onSubmitErrorHandle = (objectHandle) => {
        let { errors } = this.state;
        let formIsValid = true;
        // insertUserObj.taggingTypeDefaultCode

        if (!objectHandle.userBankAccountTaggingDTO.taggingTypeCoreCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors['taggingTypeDefaultCode'] = "Using purpose can't left empty."
        }
        if (!objectHandle.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors['bankName'] = "Bank Name can't left empty."
        }
        if (!this.state.district) {
            formIsValid = false;
            errors['district'] = "District can't left empty."
        }
        if (!objectHandle.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchID) {
            formIsValid = false;
            errors['branch'] = "Branch Name can't left empty."
        }
        // if(!this.state.routingNumber){
        //     formIsValid = false;
        //     errors['routingNumber'] = "Routing Number can't left empty."
        // }
        if (!objectHandle.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccHolderName) {
            formIsValid = false;
            errors['bankAccHolderName'] = "Name can't left empty."
        }
        if (!objectHandle.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccNumber) {
            formIsValid = false;
            errors['bankAccNumber'] = "Account number can't left empty."
        }
        if (!objectHandle.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankNote) {
            formIsValid = false;
            errors['bankNote'] = "Account details can't left empty."
        }
        if (!this.state.chequeLeafFile.contentName) {
            formIsValid = false;
            errors['chequeLeafFile'] = "Cheque leaf  can't left empty."
        }
        if (!this.state.othersFile.contentName) {
            formIsValid = false;
            errors['othersFile'] = "Others file  can't left empty."
        }

        this.setState({ errors });
        return formIsValid;
    }


    onSubmitHandler = () => {
        let { bankAccountInfo, chequeLeafFile, othersFile, insertUserObj } = this.state;
        if (this.onSubmitErrorHandle(insertUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false })
            let chequeFileExtention = this.netiFileHandler.getImageExtention(chequeLeafFile.extention);
            let othersFileExtention = this.netiFileHandler.getImageExtention(othersFile.extention);

            if(chequeLeafFile.contentPic){
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipName = "chequeSlip_" + Date.now() + chequeFileExtention;
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipContent = chequeLeafFile.contentPic;
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipEditable = true;
            }
            else{
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipName = '';
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipContent = '';
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipEditable = false;
            }

            if(chequeLeafFile.contentPic){
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentName = "othersAttachment_" + Date.now() + othersFileExtention;
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentContent = othersFile.contentPic;
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentEditable = true;
            }
            else{
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentName = '';
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentContent = '';
                insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentEditable = false;
            }

            // insertUserObj.userBasicInfoDTO.netiID = bankAccountInfo.netiID;

            this.setState({ insertUserObj })
            
            this.userOtherAccessService.createUserProfileBankAccount(insertUserObj)
                .then(res => {
                    if (res.status === 201) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfuly submitted.' });
                        this.setState({ bankName: '', accountNumber: '', accountHolder: '', branchName: '', accountDetails: '', });
                        this.fetchUserBankAccountList();
                        this.setState({ chequeLeafFile: { contentPic: '', contentName: '' } });
                        this.setState({ othersFile: { contentPic: '', contentName: '' } });
                        this.setState({ topProgressBar: false, errorMsgVisible: false })

                    } else {
                        this.userOtherAccessService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: resp })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: 'Please check your connection.' })
                })
        }

    }

    onUpdateErrorHandle = (objectHandle) => {
        let { errors, updateUserObj } = this.state;
        let formIsValid = true;
        // insertUserObj.taggingTypeDefaultCode

        if (!updateUserObj.userBankAccountTaggingDTO.taggingTypeCoreCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors['taggingTypeDefaultCode'] = "Using purpose can't left empty."
        }
        if (!updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors['bankName'] = "Bank Name can't left empty."
        }
        if (!this.state.district) {
            formIsValid = false;
            errors['district'] = "District can't left empty."
        }
        if (!updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchID) {
            formIsValid = false;
            errors['branch'] = "Branch Name can't left empty."
        }
        if (!updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccHolderName) {
            formIsValid = false;
            errors['bankAccHolderName'] = "Name can't left empty."
        }
        if (!updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccNumber) {
            formIsValid = false;
            errors['bankAccNumber'] = "Account number can't left empty."
        }
        

        this.setState({ errors });
        return formIsValid;
    }

    onUpdateHandler = () => {
        let { bankAccountInfo, chequeLeafFile, othersFile, updateUserObj } = this.state;
        if (this.onUpdateErrorHandle(updateUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false })
            // let chequeFileExtention = this.netiFileHandler.getImageExtention(chequeLeafFile.extention);
            // let othersFileExtention = this.netiFileHandler.getImageExtention(othersFile.extention);

            // if(chequeLeafFile.contentPic){
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipName = Date.now() + chequeFileExtention;
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipContent = chequeLeafFile.contentPic;
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipEditable = true;
            // }
            // else{
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipName = '';
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipContent = '';
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.chequeSlipEditable = false;
            // }

            // if(chequeLeafFile.contentPic){
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentName = Date.now() + othersFileExtention;
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentName = othersFile.contentPic;
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentEditable = true;
            // }
            // else{
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentName = '';
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentName = '';
            //     updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.othersAttachmentEditable = false;
            // }

            // insertUserObj.userBasicInfoDTO.netiID = bankAccountInfo.netiID;

            this.setState({ updateUserObj })
            
            this.userOtherAccessService.updateUserProfileBankAccount(updateUserObj)
                .then(res => {
                    if (res.status === 202) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfuly submitted.' });
                        // this.setState({ bankName: '', accountNumber: '', accountHolder: '', branchName: '', accountDetails: '', });
                        this.fetchUserBankAccountList();
                        // this.setState({ chequeLeafFile: { contentPic: '', contentName: '' } });
                        // this.setState({ othersFile: { contentPic: '', contentName: '' } });
                        this.setState({ topProgressBar: false, errorMsgVisible: false })

                    } else {
                        this.userOtherAccessService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: resp })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: 'Please check your connection.' })
                })
        }

    }

    render() {
        let { userBankInfoObj, updateUserObj, insertUserObj, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        
        let uploadFileLabel = <div>Click / Drag Here To Upload</div>

        let bankOptions = [];
        if (this.state.bankList && this.state.bankList.length) {
            bankOptions = this.state.bankList.map(item => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }))
        }

        let districtItemList = []
        if (this.state.districtList && this.state.districtList.length) {
            districtItemList = this.state.districtList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let branchItemList = []
        if (this.state.branchList && this.state.branchList.length) {
            branchItemList = this.state.branchList.map((item) => ({
                value: item.branchID,
                label: item.branchName,
            }));
        }

        let taggingItemList = []
        if (this.state.taggingList && this.state.taggingList.length) {
            taggingItemList = this.state.taggingList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let taggingTypeList = [
            { label: "Balance Withdraw", value: "C123001" },
            { label: "Eduman Fee", value: "C123002" },
            { label: "Admisia Withdraw", value: "C123003" }
          ];

        return (
            <div>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                
                <div className="main-section blur-section">
                    <div className="p-grid">
                        {/* { userBankInfoObj.length === 0 ?
                            '': ''
                        } */}
                        {
                            userBankInfoObj.map((item, index) =>
                                <div className="p-col-12 p-md-6">
                                    <div className="personal-box-wrapper m-t-30">
                                        <div className="personal-box-title-wrapper">
                                            <div className="personal-box-title">
                                                <h4>Bank - { index + 1 }</h4>
                                            </div>
                                            <div className="edit-button">
                                                <Button icon="fas fa-edit" onClick={ e => this.showEditDialog(item)} />
                                            </div>
                                        </div>
                                        <div className="personal-box">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Verification Status</td>
                                                        <td>{ item.userBankAccountInfoDTO.verifyStatus == 0  ? 'Unverified' : 'Verified' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Using Purpose</td>
                                                        <td>{item.taggingTypeCoreCategoryInfoDTO.categoryName || '---'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Bank Name</td>
                                                        <td>{ item.userBankAccountInfoDTO.coreBankBranchInfoDTO.coreBankInfoDTO.categoryName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Branch</td>
                                                        <td>{ item.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchName || '---' }</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td>District</td>
                                                        <td>{ item.districtName || '---' }</td>
                                                    </tr> */}
                                                    <tr>
                                                        <td>Routing No.</td>
                                                        <td>{ item.userBankAccountInfoDTO.coreBankBranchInfoDTO.routingNumber || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Account Holder Name</td>
                                                        <td>{ item.userBankAccountInfoDTO.bankAccHolderName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Account No.</td>
                                                        <td>{ item.userBankAccountInfoDTO.bankAccNumber || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Check Leaf</td>
                                                        <td>
                                                            <span className="click-link">
                                                                <a
                                                                    onClick={e => this.getSlipFile(item.userBankAccountInfoDTO.chequeSlipPath, item.userBankAccountInfoDTO.chequeSlipName)}
                                                                >
                                                                    Download
                                                            </a>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Other Attachment </td>
                                                        <td>
                                                            <span className="click-link">
                                                                <a
                                                                    onClick={e => this.getSlipFile(item.userBankAccountInfoDTO.othersAttachmentPath, item.userBankAccountInfoDTO.othersAttachmentName)}
                                                                >
                                                                    Download
                                                                </a>
                                                            </span>
                                                        </td>
                                                       
                                                        
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className="add-button">
                        <Button
                            icon="fas fa-plus"
                            tooltip="Add Bank Info"
                            tooltipOptions={{ position: 'bottom' }}
                            onClick={this.showDialog}
                        />
                    </div>
                </div>
                <div  className="dialog-section">
                    <Dialog id="dialog" className="nw-dialog" header="Add Bank Information" visible={this.state.visibleDialog} onHide={this.onChangeDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Using Purpose<span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Type"
                                                    onChange={ e => this.onChangeTaggingType(e, 'insert')}
                                                    value={insertUserObj.userBankAccountTaggingDTO.taggingTypeCoreCategoryInfoDTO.coreCategoryID}
                                                    options={taggingItemList}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="taggingTypeDefaultCode"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["taggingTypeDefaultCode"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            {this.state.firstDropDownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Bank Name <span>*</span></span>
                                                    <Dropdown
                                                        placeholder="Select Bank Name"
                                                        options={bankOptions}
                                                        onChange={ e => this.onChangebankName(e, 'insert')}
                                                        value={insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID}
                                                        filter={true}
                                                        autoWidth={false}
                                                        name='bankName'
                                                    />
                                                </div>
                                            }
                                            <span className='error-message'>{this.state.errors['bankName']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                                            {this.state.secondDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">District<span>*</span></span>
                                                    <Dropdown
                                                        placeholder="select district"
                                                        onChange={ e => this.onChangeDistrict(e, 'insert')}
                                                        value={this.state.district}
                                                        options={districtItemList}
                                                        filter={true}
                                                        autoWidth={false}
                                                        name="district"
                                                        showClear={true}
                                                    />
                                                </div>
                                            }
                                            <span className="error-message">
                                                {this.state.errors["district"]}
                                            </span>
                                        </div>

                                        <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                                            {this.state.thirdDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Branch <span>*</span></span>
                                                    <Dropdown
                                                        placeholder="select branch"
                                                        onChange={ e=> this.onChangeBranch(e, 'insert')}
                                                        value={insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchID}
                                                        options={branchItemList}
                                                        filter={true}
                                                        autoWidth={false}
                                                        name="branch"
                                                        showClear={true}
                                                    />
                                                </div>
                                            }
                                            <span className="error-message">
                                                {this.state.errors["branch"]}
                                            </span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Routing Number <span>*</span></span>
                                                <InputText
                                                    type="text"
                                                    className='disable'
                                                    disabled
                                                    value={this.state.routingNumber}
                                                    name='routingNumber'
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors['routingNumber']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Account Holder Name <span>*</span></span>
                                                <InputText
                                                    type="text"
                                                    placeholder="Enter Account Holder Name"
                                                    onChange={ e => this.onChangeAccountHolderName(e, 'insert')}
                                                    value={insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccHolderName}
                                                    maxlength={120}
                                                    name="bankAccHolderName"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors['bankAccHolderName']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Account Number <span>*</span></span>
                                                <InputText
                                                    type="text"
                                                    placeholder="Enter Account Number"
                                                    onChange={ e => this.onChangeAccountNumber(e, 'insert')}
                                                    value={insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccNumber}
                                                    maxlength={50}
                                                    name="bankAccNumber"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors['bankAccNumber']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Account Details <span>*</span></span>
                                                <InputTextarea
                                                    type="text"
                                                    placeholder="Enter Account Details"
                                                    onChange={ e => this.onChangeAccountDetails(e, 'insert')}
                                                    value={insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankNote}
                                                    maxlength={50}
                                                    name="bankNote"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors['bankNote']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Cheque Leaf Attachment <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/PDF)</span>
                                                </span>
                                                <div className="nw-upload-button-inside">
                                                    {
                                                        this.state.chequeLeafFile.contentPic ?
                                                            <div className="image-view-main">
                                                                <div className="upload-image-view">
                                                                    <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={this.chequeLeafemoveButtonOnclick}
                                                                    />
                                                                    {this.state.chequeLeafFile.contentName ?
                                                                        this.CommonFuctionality.getFileContentTypeIcon(this.state.chequeLeafFile.contentName) : ''}
                                                                </div>
                                                                <div className="image-title">{this.state.chequeLeafFile.contentName}</div>
                                                            </div>
                                                            :
                                                            <FileUpload
                                                                id="attachments"
                                                                mode="basic"
                                                                maxFileSize={1000000}
                                                                name="chequeLeafFile"
                                                                onSelect={this.chequeLeafFileUpload.bind(this)}
                                                                auto={true}
                                                                chooseLabel={this.state.chequeLeafFile.contentName ? this.state.chequeLeafFile.contentName : "Click / Drag Here To Upload"}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                            <span className='error-message'>{this.state.chequeLeafFile.contentName === '' && this.state.errors["chequeLeafFile"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Others Attachment
                                                    <br />
                                                    <span>(PNG/JPG/PDF)</span>
                                                </span>
                                                <div className="nw-upload-button-inside">
                                                    {
                                                        this.state.othersFile.contentPic ?
                                                            <div className="image-view-main">
                                                                <div className="upload-image-view">
                                                                    <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={this.othersRemoveButtonOnclick}
                                                                    />
                                                                    {this.state.othersFile.contentName ?
                                                                        this.CommonFuctionality.getFileContentTypeIcon(this.state.othersFile.contentName) : ''}
                                                                </div>
                                                                <div className="image-title">{this.state.othersFile.contentName}</div>
                                                            </div>
                                                            :
                                                            <FileUpload
                                                                id="attachments"
                                                                mode="basic"
                                                                maxFileSize={1000000}
                                                                name="othersFile"
                                                                onSelect={this.othersFileUpload.bind(this)}
                                                                auto={true}
                                                                chooseLabel={this.state.othersFile.contentName ? this.state.othersFile.contentName : "Click / Drag Here To Upload"}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                            <center><span className='error-message'>{this.state.othersFile.contentName === '' && this.state.errors["othersFile"]}</span></center>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={this.onSubmitHandler}
                                                />
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog id="dialog" className="nw-dialog" header="Edit Bank Information" visible={this.state.visibleEditDialog} onHide={this.onChangeEditDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">
                                        
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Using Purpose<span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Type"
                                                    onChange={ e => this.onChangeTaggingType(e, 'update')}
                                                    value={updateUserObj.userBankAccountTaggingDTO.taggingTypeCoreCategoryInfoDTO.coreCategoryID}
                                                    options={taggingItemList}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="taggingTypeDefaultCode"
                                                    // showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["taggingTypeDefaultCode"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            {this.state.firstDropDownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Bank Name <span>*</span></span>
                                                    <Dropdown
                                                        placeholder="Select Bank Name"
                                                        options={bankOptions}
                                                        onChange={ e => this.onChangebankName(e, 'update')}
                                                        value={updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID}
                                                        filter={true}
                                                        autoWidth={false}
                                                        name='bankName'
                                                        // disabled
                                                    />
                                                </div>
                                            }
                                            <span className='error-message'>{this.state.errors['bankName']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                                            {this.state.secondDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">District <span>*</span></span>
                                                    <Dropdown
                                                        placeholder="select district"
                                                        onChange={ e => this.onChangeDistrict(e, 'update')}
                                                        value={this.state.district}
                                                        options={districtItemList}
                                                        filter={true}
                                                        autoWidth={false}
                                                        name="district"
                                                        // showClear={true}
                                                    />
                                                </div>
                                            }
                                            <span className="error-message">
                                                {this.state.errors["district"]}
                                            </span>
                                        </div>

                                        <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                                            {this.state.thirdDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Branch <span>*</span></span>
                                                    <Dropdown
                                                        placeholder={updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchName || "Select Branch Name"}
                                                        onChange={ e=> this.onChangeBranch(e, 'update')}
                                                        value={updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchID }
                                                        options={branchItemList}
                                                        filter={true}
                                                        autoWidth={false}
                                                        name="branch"
                                                        // showClear={true}
                                                        className={updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchName? "custom-dropdown" : ''}
                                                    />
                                                </div>
                                            }
                                            <span className="error-message">{this.state.errors["branch"]}</span>
                                        </div>

                                        {/* <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Routing Number<span>*</span></span>
                                                <InputText
                                                    type="text"
                                                    className='disable'
                                                    disabled
                                                    value={this.state.routingNumber}
                                                    name='routingNumber'
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors['routingNumber']}</span>
                                        </div>*/}

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Account Holder Name <span>*</span></span>
                                                <InputText
                                                    type="text"
                                                    placeholder="Enter Account Holder Name"
                                                    onChange={ e => this.onChangeAccountHolderName(e, 'update')}
                                                    value={updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccHolderName}
                                                    maxlength={120}
                                                    name="bankAccHolderName"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors['bankAccHolderName']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Account Number <span>*</span></span>
                                                <InputText
                                                    type="text"
                                                    placeholder="Enter Account Number"
                                                    onChange={ e => this.onChangeAccountNumber(e, 'update')}
                                                    value={updateUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankAccNumber}
                                                    maxlength={50}
                                                    name="bankAccNumber"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors['bankAccNumber']}</span>
                                        </div>

                                        {/*<div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Account Details <span>*</span></span>
                                                <InputTextarea
                                                    type="text"
                                                    placeholder="Enter Account Details"
                                                    onChange={ e => this.onChangeAccountDetails(e, 'insert')}
                                                    value={insertUserObj.userBankAccountTaggingDTO.userBankAccountInfoDTO.bankNote}
                                                    maxlength={50}
                                                    name="bankNote"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors['bankNote']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Cheque Leaf Attachment <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/PDF)</span>
                                                </span>
                                                <div className="nw-upload-button-inside">
                                                    {
                                                        this.state.chequeLeafFile.contentPic ?
                                                            <div className="image-view-main">
                                                                <div className="upload-image-view">
                                                                    <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={this.chequeLeafemoveButtonOnclick}
                                                                    />
                                                                    {this.state.chequeLeafFile.contentName ?
                                                                        this.CommonFuctionality.getFileContentTypeIcon(this.state.chequeLeafFile.contentName) : ''}
                                                                </div>
                                                                <div className="image-title">{this.state.chequeLeafFile.contentName}</div>
                                                            </div>
                                                            :
                                                            <FileUpload
                                                                id="attachments"
                                                                mode="basic"
                                                                maxFileSize={1000000}
                                                                name="chequeLeafFile"
                                                                onSelect={this.chequeLeafFileUpload.bind(this)}
                                                                auto={true}
                                                                chooseLabel={this.state.chequeLeafFile.contentName ? this.state.chequeLeafFile.contentName : "Click / Drag Here To Upload"}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                            <span className='error-message'>{this.state.chequeLeafFile.contentName === '' && this.state.errors["chequeLeafFile"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Others Attachment
                                                    <br />
                                                    <span>(PNG/JPG/PDF)</span>
                                                </span>
                                                <div className="nw-upload-button-inside">
                                                    {
                                                        this.state.othersFile.contentPic ?
                                                            <div className="image-view-main">
                                                                <div className="upload-image-view">
                                                                    <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={this.othersRemoveButtonOnclick}
                                                                    />
                                                                    {this.state.othersFile.contentName ?
                                                                        this.CommonFuctionality.getFileContentTypeIcon(this.state.othersFile.contentName) : ''}
                                                                </div>
                                                                <div className="image-title">{this.state.othersFile.contentName}</div>
                                                            </div>
                                                            :
                                                            <FileUpload
                                                                id="attachments"
                                                                mode="basic"
                                                                maxFileSize={1000000}
                                                                name="othersFile"
                                                                onSelect={this.othersFileUpload.bind(this)}
                                                                auto={true}
                                                                chooseLabel={this.state.othersFile.contentName ? this.state.othersFile.contentName : "Click / Drag Here To Upload"}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                            <center><span className='error-message'>{this.state.othersFile.contentName === '' && this.state.errors["othersFile"]}</span></center>
                                        </div> */}

                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={ this.onUpdateHandler }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }
} 