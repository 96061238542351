import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { SaSummaryService } from '../../../service/saPoint/SaSummaryService';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import mapImage from '../../../assets/images/map-image.png';
import mapPin from '../../../assets/images/map-pin.png';
import ImageMapper from 'react-image-mapper';
import {DatatableAttendanceInformation} from './DatatableAttendanceInformation';
import { Card } from 'primereact/card';
import Chart from 'react-google-charts';
import { AttendancerChartsColorArray } from '../../common/ChartsColorArray';



let maxDate = new Date();
export class InstituteWiseAttendance extends Component {

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.state = {
            hoveredArea: '',
            city: null,
            checked1: false,
            dateRangeObj: {
                startDate: "",
                endDate: "",
            },
            dashboardViewObjMain:{},
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            errors: {}
        }

        // this.cssAnimation = this.cssAnimation.bind();
        // this.createMapDetails = this.createMapDetails.bind();

        
        
    }


    photoView = rowData => {

        let staticImg = 'assets/layout/images/userAvatar.png';
        
        return (
                <div className="table-image">
                    <img src={staticImg} alt={rowData.photoName} ></img>
                </div>
            

        )
    }

    hrInfoBody = rowData =>{
        return (
            <div className="nw-datatable-dataview-small-panel">
                <div>
                    <div> {rowData.staffId}</div>
                </div>
                <div>
                    {/* <div>Name</div>
                    <div>:</div> */}
                    <div> {rowData.staffName}</div>
                </div>
                <div>
                    {/* <div>Degination</div>
                    <div>:</div> */}
                    <div></div>
                </div>
                <div>
                    {/* <div>Cell No.</div>
                    <div>:</div> */}
                    <div>{rowData.staffMobileNo}</div>
                </div>

            </div>
        )
    }

    inTimeBody = (rowData) => {
        return (
            <div className="input-radio-button">
                <div className="p-col-12 p-col-nogutter">
                    <label htmlFor="rb1" className="p-radiobutton-label">{rowData.inTime != 'null' ? rowData.inTime : '-'}</label>
                </div>

                <div className="p-col-12 p-col-nogutter">
                   <label htmlFor="rb1" className="p-radiobutton-label">{rowData.inTimeStatus != 'null' ? rowData.inTimeStatus : '-'}</label>
                </div>

            </div>
        )
    }

    outTimeBody = (rowData) => {
        return (
            <div className="input-radio-button">
                <div className="p-col-12 p-col-nogutter">
                   <label htmlFor="rb1" className="p-radiobutton-label">{rowData.outTime != 'null' ? rowData.outTime : '-'}</label>
                </div>

                <div className="p-col-12 p-col-nogutter">
                   <label htmlFor="rb1" className="p-radiobutton-label">{rowData.outTimeStatus != 'null' ? rowData.outTimeStatus : '-' }</label>
                </div>

            </div>
        )
    }

    goBackToInstitutePage = (e) => {
        this.props.hideSingleInstituteAttendanceInfoView();
        this.props.showInstituteWiseAttendanceInfoView();

    }

    render() {
        console.log('institute wise props',this.props);
        let { pendingTokenArray, dateRangeObj, dashboardViewObj, colorArray, topProgressBar, errorMsgVisible, errorMsgBody, staffAttendanceSummaryList } = this.state;
        let {singleInstituteDetails, teacherAttendanceInfoFinalArr, totalAttendanceInfoFinalArr} = this.props;
        let homePageAndGalleryPhotoHeader = <div className="header-title">
                                                <div className="header-title-left">Attendance Details</div>
                                                <div style={{cursor:"pointer"}} className="header-title-right">
                                                    <span className="total-found">Total Found: { singleInstituteDetails && singleInstituteDetails.details && singleInstituteDetails.details.length !== 0 ? singleInstituteDetails.details.length : 0}</span>
                                                    <Button
                                                        className="nw-button nw-button-center p-button-success"
                                                        label="Back"
                                                        icon="fas fa-angle-left"
                                                        onClick={ e => this.goBackToInstitutePage(e)}
                                                    />
                                                    {/* <i className="fas fa-angle-left" ></i> &nbsp;&nbsp;  Back</span> */}
                                                    
                                                </div>
                                            </div>;


        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section crm-dashboard">
                    <div className="p-grid nw-form">
                        

                        <div className="p-col-12 dashboard-card-view">
                            <Card header="Institute Attendance Info" className="bg-primary">
                                <div className="p-grid p-col-nogutter">
                                    <div className="p-col-12 p-xl-4">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                <Chart
                                                    width={226}
                                                    height={226}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={teacherAttendanceInfoFinalArr.length !== 0 ? teacherAttendanceInfoFinalArr : []}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        legend: 'none',
                                                        slices: {
                                                                0: { color: '#D7DF23' },
                                                                1: { color: '#8DC63F' },
                                                            },
                                                    }}
                                                    // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                    render={({ renderChart }) => {
                                                        return (
                                                            <div>{renderChart()}</div>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <span className="chart-box-title">Total Teacher</span><br />
                                            <span className="chart-box-title">{singleInstituteDetails.totalTeacher ? singleInstituteDetails.totalTeacher.toLocaleString(
                               "EN-IN"
                             ) : 0}</span> 
                                        </div>
                                    </div>
                                    
                                    <div className="p-col-12 p-xl-2">
                                        <div className="netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list">
                                                    <div className="p-grid p-col-12">
                                                    {
                                                        teacherAttendanceInfoFinalArr.length !== 0 ?
                                                                <div className="p-col-12 p-xl-12 chart-text-view">
                                                                    <div className="text-view-wrapper">
                                                                        <span className="task-badge" style={{ background: '#D7DF23' }}></span> 
                                                                        <span className="title">{ teacherAttendanceInfoFinalArr[1] && teacherAttendanceInfoFinalArr[1][0] ? 'Male' : 0}</span>

                                                                      
                                                                    </div>
                                                                    <div className="text-view-wrapper">
                                                                    <span className="task-badge" style={{ background: '#8DC63F' }}></span> 
                                                                        <span className="title">{  teacherAttendanceInfoFinalArr[2] && teacherAttendanceInfoFinalArr[2][0] ? 'Female' : 0}</span>
                                                                        </div>
                                                                </div>
                                                                : ''
                                                            
                                                        }
                                               
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-xl-4">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                <Chart
                                                    width={226}
                                                    height={226}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={totalAttendanceInfoFinalArr.length !== 0 ? totalAttendanceInfoFinalArr : []}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        legend: 'none',
                                                        slices: {
                                                                0: { color: '#D7DF23' },
                                                                1: { color: '#8DC63F' },
                                                                2: { color: '#7BC408' }
                                                            },
                                                    }}
                                                    // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                    render={({ renderChart }) => {
                                                        return (
                                                            <div>{renderChart()}</div>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <span className="chart-box-title">Total Taken Attendance</span><br />
                                            <span className="chart-box-title">{singleInstituteDetails.attendanceTaken ? singleInstituteDetails.attendanceTaken.toLocaleString(
                               "EN-IN"
                             ) : 0}</span> 
                                            {/* //personWisePendingTokenSum */}
                                        </div>
                                    </div>
                                    
                                    <div className="p-col-12 p-xl-2">
                                        <div className="netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list">
                                                    <div className="p-grid p-col-12">
                                                    {
                                                        totalAttendanceInfoFinalArr.length !== 0 ?
                                                                <div className="p-col-12 p-xl-12 chart-text-view">
                                                                    <div className="text-view-wrapper">
                                                                        <span className="task-badge" style={{ background: '#D7DF23' }}></span> 
                                                                        <span className="title">{ totalAttendanceInfoFinalArr[1] && totalAttendanceInfoFinalArr[1][0] ? 'Present' : 0}</span>

                                                                      
                                                                    </div>
                                                                    <div className="text-view-wrapper">
                                                                    <span className="task-badge" style={{ background: '#8DC63F' }}></span> 
                                                                        <span className="title">{  totalAttendanceInfoFinalArr[2] && totalAttendanceInfoFinalArr[2][0] ? 'Absent' : 0}</span>
                                                                        </div>
                                                                        <div className="text-view-wrapper">
                                                                    <span className="task-badge" style={{ background: '#7BC408' }}></span> 
                                                                        <span className="title">{  totalAttendanceInfoFinalArr[3] && totalAttendanceInfoFinalArr[3][0] ? 'Leave' : 0}</span>
                                                                        </div>
                                                                </div>
                                                                : ''
                                                            
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </Card>
                        </div>

                        <div className="p-col-12 p-xl-12 nw-datatable-detail-view">
                            {/* {this.state.dataTableIsLoadingHomepage ?
                                this.NetiContentLoader.MyPointTableLoader() : */}
                                <DataTable
                                    header={homePageAndGalleryPhotoHeader}
                                    value={singleInstituteDetails.details}
                                    selectionMode="single"
                                    responsive={true}
                                    paginator={true}
                                    rows={20}
                                >
                                    <Column field="" header="Image" body={this.photoView} />
                                    <Column field="" header="HR Info" body={this.hrInfoBody} />
                                    <Column field="attendanceStatus" header="Attendance Status" />
                                    <Column field="inTime" header="In Time" body={this.inTimeBody} />
                                    <Column field="outTime" header="Out Time" body={this.outTimeBody} />
                                </DataTable>
                            {/* } */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}