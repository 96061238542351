import React, { Component } from 'react';
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { SaCoreSettingsService } from "../../../service/saPoint/SaCoreSettingsService";
import { StudentClassConfigInfo } from '../common/StudentClassConfigInfo';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';


let totalFullMark;
let totalHighestMark;
let totalObtainedMark;
let totalLetterGrade;
let totalGradePoint;
export class Exam extends Component {

  constructor() {
    super();

    this.state = {
      academicYearList: [],
      academicYear: "",
      errors: {},
      examDetails: {
        examMarks: []
      },
      examName: "",
      examList: [],
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',
      firstDropdownIsLoading: false,
      secondDropdownIsLoading: false,
    };
    this.onChangeExam = this.onChangeExam.bind(this);
    this.onChangeAcYear = this.onChangeAcYear.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.studentPortal = new StudentPortal();
    this.saCoreSettingsService = new SaCoreSettingsService();
    this.NetiContentLoader = new NetiContentLoader();
  }

  componentWillMount() {
    this.fetchAcademicYearList();
    this.fetchExamList();
    this.setCurrentAcademicYearToDropDown();
    totalFullMark = totalHighestMark = totalObtainedMark = totalLetterGrade = totalGradePoint = '';
  }

  setCurrentAcademicYearToDropDown = () => {
    let academicYear = NetiDateUtils.getYear(new Date());
    this.setState({ academicYear })
  }

  onAcademicYearFilter = () => {
    if (this.state.academicYearFiltering) this.setCurrentAcademicYearToDropDown();
    this.setState({ academicYearFiltering: !this.state.academicYearFiltering });
  }

  onChangeExam = (e) => {
    let { errors } = this.state;
    errors["examName"] = "";
    this.setState({ examName: e.target.value, errors })
  };
  onChangeAcYear = (e) => {
    let { errors } = this.state;
    errors["academicYear"] = "";
    this.setState({ academicYear: e.target.value, errors })
  };

  fetchAcademicYearList() {
    this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
    this.saCoreSettingsService.getGlobalInfoByTypeId(2101).then(res => {

      if (res.item) {
        return this.setState({ academicYearList: res.item, topProgressBar: false, firstDropdownIsLoading: false })
      } else {
        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No data fund' })
      }

    }).catch(error => {
      this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
    });
  }

  fetchExamList() {
    this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false });
    this.studentPortal.fetchExamsOfStudent(this.props.basicInfo.instituteId, this.props.basicInfo.studentId, this.props.basicInfo.academicYear).then(res => {
      if (res) {
        return res.json().then(body => {
          this.setState({ examList: body.item, topProgressBar: false, secondDropdownIsLoading: false })
        });
      } else {
        console.log("Exam res", res);
        this.studentPortal.Auth.handleErrorStatus(res)
          .then((responseBody) => {
            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
          });
      }
    }).catch(error => {
      this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
    });
  }

  handleSubmit(e) {
    this.setState({ examDetails: { examMarks: [] } });
    totalFullMark = totalHighestMark = totalObtainedMark = totalLetterGrade = totalGradePoint = '';
    console.log("this.props.basicInfo", this.props.basicInfo)
    if (this.handleError()) {
      this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
      this.studentPortal.fetchfindExamResultsByStudentId(this.props.basicInfo.instituteId, this.props.basicInfo.customStudentId, this.state.academicYear, this.state.examName)
        .then(res => {
          if (res.status == 200) {
            return res.json().then(body => {
              if (body.message == null) {
                body.examMarks.map(item => {
                  item.subjectName = `${item.subjectName} ${item.subjectCountableStatus}`
                })
                this.setState({ examDetails: body });
                totalFullMark = body.fullMarks
                totalObtainedMark = body.obtainedMarks
                totalLetterGrade = body.grade
                totalGradePoint = body.gpa
                this.setState({ topProgressBar: false, dataTableIsLoading: false });
              } else if (body.examMarks == null) {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: body.message });
              } else {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
              }
            });
          } else {
            this.studentPortal.Auth.handleErrorStatus(res)
              .then((responseBody) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
              });
          }
        }).catch(error => {
          this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        })
    }
  }


  handleError = () => {

    let { errors } = this.state;
    let formIsValid = true;

    if (!this.state.academicYear) {
      formIsValid = false;
      errors["academicYear"] = "Academic Year can't left empty.";
    }
    if (this.state.examName === '') {
      formIsValid = false;
      errors["examName"] = "Exam Name can't left empty.";
    }

    this.setState({ errors });
    return formIsValid;
  }

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
      Exam Mark List
    <div style={{ color: "white", display: "flex", float: "right" }}>
        Total Found: {this.state.examDetails.examMarks.length}

      </div>
    </div>;


    let acYearOptions = [];
    if (this.state.academicYearList && this.state.academicYearList.length) {
      acYearOptions = this.state.academicYearList.map(item => ({
        value: item.name,
        label: item.name
      }));
    }

    let examListOptions = [];
    if (this.state.examList && this.state.examList.length) {
      examListOptions = this.state.examList.map(item => ({
        value: item.examObject.id,
        label: item.examObject.name
      }));
    }

    let footerGroup = <ColumnGroup>
      <Row>
        <Column footer="Total:" />
        <Column footer={totalFullMark} />
        <Column footer={totalHighestMark} />
        <Column footer={totalObtainedMark} />
        <Column footer={totalLetterGrade} />
        <Column footer={totalGradePoint} />
      </Row>
    </ColumnGroup>;

    return (

      <div className="p-col-12 p-col-nogutter">
        <Growl ref={(el) => this.growl = el} />
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }


        <div className="p-grid nw-form">
          <div className="p-col-12">
            <StudentClassConfigInfo basicInfo={this.props.basicInfo} />
          </div>

          <div className="p-col-12">
            <p className='profile-custom-table-header'>Exam</p>
          </div>


          <div className="p-col-12 p-0">
            <div className="nw-form-body">

              <div className="p-col-12">
                <div className="p-lg-2 p-lg-offset-10 p-md-3 p-md-offset-9 p-col-4 p-offset-8">
                  <Button
                    label="Academic Year"
                    icon="fas fa-filter"
                    iconPos="right"
                    onClick={this.onAcademicYearFilter}
                  />
                </div>
              </div>

              <div className="p-col-12">
                {this.state.firstDropdownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputgroup-small-span">
                      Academic Year <span>*</span>
                    </span>
                    {this.state.academicYearFiltering ?
                      <Dropdown
                        options={acYearOptions}
                        placeholder="Select Academic Year"
                        value={this.state.academicYear}
                        onChange={this.onChangeAcYear}
                        autoWidth={false}
                        name='academicYear'
                        filter={true}
                      />
                      :
                      <Dropdown
                        disabled
                        options={acYearOptions}
                        placeholder="Select Academic Year"
                        value={this.state.academicYear}
                        onChange={this.onChangeAcYear}
                        autoWidth={false}
                        name='academicYear'
                      />}
                  </div>
                }
                <span className="error-message"> {this.state.academicYear ? '' : this.state.errors['academicYear']} </span>
              </div>

              <div className="p-col-12">
                {this.state.secondDropdownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputgroup-small-span">
                      Exam <span>*</span>
                    </span>
                    <Dropdown
                      placeholder="Select Exam"
                      name="examName"
                      options={examListOptions}
                      value={this.state.examName}
                      onChange={this.onChangeExam}
                      filter={true}
                      filterBy="label,value"
                      autoWidth={false}
                    />
                  </div>
                }
                <span className='error-message'>{this.state.errors["examName"]}</span>
              </div>

              <div className="p-col-12 nw-button-parent">
                <Button
                  className="p-button p-button-primary nw-button nw-button-right"
                  label="Search"
                  icon="fas fa-search"
                  onClick={this.handleSubmit}
                />
                <br />
              </div>

            </div>
          </div>




          <div className="p-col-12 p-xl-12">
            {this.state.dataTableIsLoading ?
              this.NetiContentLoader.MyPointTableLoader()
              :
              <div className="nw-data-table">
                <DataTable
                  // footerColumnGroup={footerGroup}
                  header={tableHeader}
                  footerColumnGroup={footerGroup}
                  value={this.state.examDetails.examMarks}
                  responsive={true}
                  paginator={true}
                  rows={10}
                >
                  <Column field="subjectName" header="Subject Name" sortable={true} filter={true} />
                  <Column field="fullMarks" header="Full Marks" sortable={true} filter={true} />
                  <Column field="heightMark" header="Highest Marks" sortable={true} filter={true} />
                  <Column field="obtainedMarks" header="Obtained Marks" sortable={true} filter={true} />
                  <Column field="grade" header="Letter Grade" sortable={true} filter={true} />
                  <Column field="gpa" header="Grade Point" sortable={true} filter={true} />
                </DataTable>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
