import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Dialog } from 'primereact/dialog';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';
import Formatter from '../../../utils/Formatter';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';


let errors = {};
export class AdmisiaApproveRequest extends Component {

    constructor() {
        super();
        this.state = {
            status: 0,
            visible: false,
            selectedRowData: {},
            partnerPicture: '',
            depositSlip: '',
            transactionRefID: '',
            transactionRefNo: '',
            rejectNote: '',
            dataTableValue: [],
            netiIdAndNameBody: {
                customNetiID: ''
            },
            selectedRow: {},
            depositApproveObj: {
                userBasicReportDTO: {
                    partnerDesignation: '',
                    partnerStatus: '',
                    assignedArea: '',
                    userBasicInfoDTO: {
                        customNetiID: '',
                        approveNote: '',
                    globalAreaInfoDTO: {
                        categoryName: ''
                    }
                }
            },
                coreBankAccountInfoDTO: {
                    coreCategoryInfoDTO: {
                        categoryName: ''
                    }
                }
            },
            visible: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            errors: {}

        }

        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangeTransaction = this.onChangeTransaction.bind(this);
        this.onChangeReject = this.onChangeReject.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.CRMPointService = new CRMPointService();
        this.formatter = new Formatter();
        this.ValidatorUtility = new ValidatorUtility();
    }

    componentWillMount() {
        this.getRefundApproveRequestList();
    }


    getRefundApproveRequestList() {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.CRMPointService.findRefundRequestByRefundStatus(0)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        body.map((item) => {
                            item['refundRequestDate'] = NetiDateUtils.getAnyShortForm(item['refundRequestDate'], 'DD-MMM-YYYY');
                        });
                        console.log("approve refund list",body);
                        this.setState({ dataTableValue: body })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    onChangeStatus(value) {
        errors["transactionAndApproveError"] = ''
        this.setState({ errors, status: value });
    }

    onChangeTransaction(value) {
        let { depositApproveObj } = this.state
        errors["transactionAndApproveError"] = ''
        this.setState({ transactionRefID: value });
        depositApproveObj.transactionNumber = value;
        depositApproveObj.approveNote = ""
        this.setState({ errors, depositApproveObj });
    }

    onChangeReject(value) {
        let { depositApproveObj } = this.state
        errors["transactionAndApproveError"] = ''
        depositApproveObj.approveNote = value
        depositApproveObj.transactionNumber = ""
        this.setState({ errors, depositApproveObj });
    }


    actionBodyTemplet = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-edit"
                tooltip="Update"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    viewDialog(rowData) {
        console.log('rowdata',rowData);
        this.setState({ selectedRowData: rowData, visible: true });
    }

    

    onHide(e) {
        this.setState({ visible: false });
    }



    requestedAmountTemplate = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.requestedAmount)
    }

    netiIDandNameTemplate = (rowData) => {
        return <span>{rowData.userBasicReportDTO.userBasicInfoDTO.customNetiID} <br /> {rowData.userBasicReportDTO.userBasicInfoDTO.fullName}</span>;

    }
    mobNoAndAreaTemplate = (rowData) => {
        return <span>{rowData.userBasicReportDTO.userBasicInfoDTO.basicMobile} <br /> {rowData.userBasicReportDTO.userBasicInfoDTO.globalAreaInfoDTO.categoryName}</span>;
    }

    getDateFromTemplate = (rowData) => {
        return <center><div>{rowData.requestDate} <br /> {rowData.requestTime}</div></center>
    }

    export = () => {
        this.dt.exportCSV();
    }

    actionBodyTemplate = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-edit"
                tooltip="Update"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    viewDialog(rowData) {
        this.setState({ selectedRowData: rowData, visible: true });
    }

    onSubmitUpdate() {
        let {selectedRowData} = this.state;

        let refundRequestInfo = {
            "refundLogID": selectedRowData.refundLogID,
        }
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            this.CRMPointService.approvedRefundRequest(refundRequestInfo)
                .then(res => {
                    if (res.status === 202) {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Updated successfully' })
                        this.onHide();
                        this.getRefundApproveRequestList();
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        


    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, depositApproveObj, dataTableValue, selectedRowData } = this.state;

      

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Admisia Refund Approve List <span style={{ float: 'right' }}> Total Found: {this.state.dataTableValue.length} </span></div>
       
       
       let headerGroup = <ColumnGroup>
           <Row>
               <Column  field="admisiaCoreConfigDTO.coreUrlInfoDTO.instituteName"  filter={true} header="Institute Name" rowSpan={2} />
               <Column  field="refundRequestDate"  filter={true} header="Request Date"  rowSpan={2} />
               <Column  header="Admisia Admin"  colSpan={3}/>
               <Column  header="Service Admin"  colSpan={3} />
               <Column  header="MFS Admin"  colSpan={3}/>
               <Column  header="Action"  rowSpan={2}/>
           </Row>
           <Row>
               <Column field="admisiaAdminUserBasicInfoDTO.customNetiID"  filter={true} header="Neti ID" rowSpan={1} />
               <Column field="admisiaAdminUserBasicInfoDTO.fullName"  filter={true} header="Name" rowSpan={1}  />
               <Column field="refundAmount"   filter={true} header="Amount" rowSpan={1}  />

               <Column field="serviceAdminUserBasicInfoDTO.customNetiID"  filter={true} header="Neti ID" rowSpan={1} />
               <Column field="serviceAdminUserBasicInfoDTO.fullName"  filter={true} header="Name" rowSpan={1}  />
               <Column field="serviceChargeAmount"  filter={true} header="Amount" rowSpan={1}  />

               <Column field="mfsAdminUserBasicInfoDTO.customNetiID"   filter={true} header="Neti ID" rowSpan={1} />
               <Column field="mfsAdminUserBasicInfoDTO.fullName"  filter={true}  header="Name" rowSpan={1}  />
               <Column field="mfsChargeAmount"  filter={true} header="Amount" rowSpan={1}  />

           </Row>

        </ColumnGroup>;

        return (

            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid">

                    <div className="p-col-12 p-xl-12">
                        <Growl ref={(el) => this.growl = el} />

                        <div className="nw-data-table nw-data-table-tabview">
                            {!this.state.dataTableIsLoading ?
                                <div>
                                    <DataTable
                                        header={tableHeader}
                                        value={this.state.dataTableValue}
                                        responsive={true}
                                        headerColumnGroup={headerGroup}
                                        paginator={true}
                                        ref={(el) => { this.dt = el; }}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="admisiaCoreConfigDTO.coreUrlInfoDTO.instituteName"  filter={true} />
                                        <Column field="refundRequestDate"  filter={true} />

                                        <Column field="admisiaAdminUserBasicInfoDTO.customNetiID"  filter={true} />
                                        <Column field="admisiaAdminUserBasicInfoDTO.fullName"  filter={true} />
                                        <Column field="refundAmount"   filter={true} />

                                        <Column field="serviceAdminUserBasicInfoDTO.customNetiID"  filter={true} />
                                        <Column field="serviceAdminUserBasicInfoDTO.fullName"  filter={true} />
                                        <Column field="serviceChargeAmount"   filter={true} />

                                        <Column field="mfsAdminUserBasicInfoDTO.customNetiID"   filter={true} />
                                        <Column field="mfsAdminUserBasicInfoDTO.fullName"  filter={true} />
                                        <Column field="mfsChargeAmount"  filter={true} />

                                        <Column field="" header="Action" body={this.actionBodyTemplate} style={{ textAlign: "center", width: "80px" }} />


                                    </DataTable>
{/* 
                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                            <Button
                                                label="Download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                icon="fas fa-arrow-down"
                                                onClick={this.export}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>


                    </div>

                </div>
                <Dialog className="nw-dialog" header="Admisia Refund Approve Info" visible={this.state.visible} onHide={e => this.onHide(e)}>
                    <div className="p-fluid">
                        <div className="p-grid">

                            <div className="p-col-12">

                                <div className="p-col-12 p-xl-12">
                                        <div className="p-grid nw-form">
                                        <div className="p-col-12 p-xl-8 p-md-offset-2 text-center">
                                        <h1>{ selectedRowData && selectedRowData.admisiaCoreConfigDTO && selectedRowData.admisiaCoreConfigDTO.coreUrlInfoDTO && selectedRowData.admisiaCoreConfigDTO.coreUrlInfoDTO.instituteName}</h1>
                                    </div>



                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Total Amount
                                                                        </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {selectedRowData.totalAmount}
                                                    </div>
                                                </div>
                                            </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Admisia Admin ID
                                                                        </span>
                                                <div className="nw-inputgroup-desc">
                                                    {selectedRowData.admisiaAdminUserBasicInfoDTO && selectedRowData.admisiaAdminUserBasicInfoDTO.customNetiID}
                                                </div>
                                            </div>
                                        </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Admisia Charge
                                                                        </span>
                                                    <div className="nw-inputgroup-desc">
                                                    {selectedRowData.refundAmount}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Service Admin ID
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                            {selectedRowData.serviceAdminUserBasicInfoDTO && selectedRowData.serviceAdminUserBasicInfoDTO.customNetiID }

                                                            </div>
                                                        </div>
                                                    </div>


                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Service Charge
                                                                        </span>
                                                    <div className="nw-inputgroup-desc">
                                                    {selectedRowData.serviceChargeAmount}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        MFS Admin ID
                                                                        </span>
                                                    <div className="nw-inputgroup-desc">
                                                    {selectedRowData.mfsAdminUserBasicInfoDTO && selectedRowData.mfsAdminUserBasicInfoDTO.customNetiID}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        MFS Charge
                                                                        </span>
                                                    <div className="nw-inputgroup-desc">
                                                    {selectedRowData.mfsChargeAmount}
                                                    </div>
                                                </div>
                                            </div>


    
                                            <div>





</div>
                                        </div>

                                </div>


                                <div className="p-grid nw-form">
                                    <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Approve"
                                            icon="far fa-save"
                                            onClick={e => this.onSubmitUpdate(e)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
}