import React, { Component } from 'react';

import { TabView, TabPanel } from 'primereact/tabview';

//custom imports
import { PendingApplicant } from './PendingApplicant';
import { WaitingApplicant } from "./WaitingApplicant";
import { ApprovedApplicant } from "./ApprovedApplicant";
import { RejectedApplicant } from "./RejectedApplicant";

export class AdmissionMain extends Component {

    render() {

        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView>
                            <TabPanel header="Pending Applicant" leftIcon="fas fa-download"> <PendingApplicant /> </TabPanel>
                            <TabPanel header="Waiting Applicant" leftIcon="fas fa-upload"> <WaitingApplicant /> </TabPanel>
                            <TabPanel header="Approved Applicant" leftIcon="fas fa-upload"> <ApprovedApplicant /> </TabPanel>
                            <TabPanel header="Rejected Applicant" leftIcon="fas fa-upload"> <RejectedApplicant /></TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }

}