import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ProfileService } from '../../service/profile/ProfileService';
import { UserCategoryService } from "../../service/UserCategoryService";
import { RegistrationService } from '../../service/registration/RegistrationService';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { TeacherPortal } from '../../service/emUserPoint/TeacherPortal';
import NetiContentLoader from '../common/NetiContentLoader';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';
import { EmAdminPointService } from '../../service/emAdminPoint/EmAdminPointService';
import { ValidatorUtility } from '../../utils/ValidatorUtility';



let edumanModuleID;
let dialogStatus = false;
let edumanModuleListObj = {};
let balanceInfoError = {};
let cloneStateBeforeMount;
export class EdumanOrder extends Component {
    constructor() {
        super();
        this.state = {
            edumanDetailsInfoError: {},
            EdumanListSelection: [],
            searchView: false,
            searchViewError: false,
            searchErrorMsgBody: '',
            netiSearchView: false,
            netiSearchViewError: false,
            netiSearchErrorMsgBody: '',
            buttonDisabled: true,
            purchaseCodeSearchBtnDisabled: true,
            netiIDSearchBtnDisabled: true,
            agreementStartDate:"",
            dataSubmissionDate: "",
            handoverDate: "",
            agreementExpiryDate: "",
            edumanOrderInfo: {
                formApplyDate: "",
                formSerial: '',
                agreementStartDate: "",
                agreementExpiryDate: "",
                searchBtnError: {},
                instituteName: "",
                instituteAddress: "",
                instituteContact: "",
                instituteEmail: "",
                paymentType: "",
                developmentCharge: '',
                dataSubmissionDate: "",
                handoverDate: "",
                emUsedType: "",
                monthlyRate: 0,
                formAttachmentName: '',
                formAttachmentContent: '',
                formAttachmentSaveOrEditable: '',
                formAttachmentPath: "",

                productPurchaseCodeDTO: {
                    productPurchaseCodeID: '',

                },
                packageCoreCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                assignedUserBasicInfoDTO: {
                    netiID: ''
                },
                globalAreaCoreCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                instituteTypeCoreCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                instituteCategoryCoreCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                instituteBoardCoreCategoryInfoDTO: {
                    coreCategoryID: ''
                },

            },
            purchaseCode: '',
            division: '',
            districtList: '',
            upazillaId: '',
            districtDropDownList: [],
            board: '',
            instituteType: '',
            instituteCategory: '',
            proPic: {
                contentPic: null
            },
            divisionDropDownList: [],
            upazillaDropDownList: [],
            packageNameDropDownList: [],
            boardDropDownList: [],
            instituteTypeDropDownList: [],
            instituteCategoryDropDownList: [],
            districtId: '',
            customNetiID: '',
            userBasicInfo: {
                netiID: '',
                registrationDate: '',
                fullName: '',
                basicMobile: ''

            },
            purchaseInfo: {
                usedStatus: '',
                productPurchaseLogDTO: {
                    purchaseDate: '',
                    productInfoDTO: {
                        productName: ''
                    }
                }
            },
            checked: false,
            errorMsgVisible: false,
            topProgressBar: false,
            // edumanModuleList: [],
            edumanTaggedIDbtnnDisabled: true,

            firstInputFieldIsLoading: false,
            secondInputFieldIsLoading: false,

            firstDropdownIsLoading: false,
            districtDropdownIsLoading: false,
            upazillaDropdownIsLoading: false,
            paymentDropdownIsLoading: false,
            searchAdminNoBtn: false,
            errorMsgBody: '',
            pointList: [],
            dataTableValue: [
            ],
            viewAdminMobileNoField: false,
            adminMobileNo: '',
            edumanRate: {}
        }

        this.partnerPointService = new PartnerPointService();
        this.UserOtherAccessService = new UserOtherAccessService();

        this.netiDateUtils = new NetiDateUtils();
        this.profileService = new ProfileService();
        this.userCategoryService = new UserCategoryService();
        this.registrationService = new RegistrationService();
        this.netiFileHandler = new NetiFileHandler();
        this.teacherPortal = new TeacherPortal();
        this.netiContentLoader = new NetiContentLoader();
        this.emAdminPointService = new EmAdminPointService();
        this.ValidatorUtility = new ValidatorUtility();

    }

    componentWillMount() {
        // this.getEdumanModuleList();
        this.getBoardOrTypeOrCategoryOrDivisionByDefCode('T10202');
        this.getBoardOrTypeOrCategoryOrDivisionByDefCode('T106');
        this.getBoardOrTypeOrCategoryOrDivisionByDefCode('T105');
        this.getBoardOrTypeOrCategoryOrDivisionByDefCode('T113');
        this.getBoardOrTypeOrCategoryOrDivisionByDefCode('T111');
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }



    onChangeEdumanListSelection = (e) => {
        let { edumanOrderInfo } = this.state;
        let edumanListSelectionArr = [];
        let totalEdumanListSelectionValue = [];
        edumanListSelectionArr.push(e.value);
        totalEdumanListSelectionValue = edumanListSelectionArr[0].map(item => item.modulePrice).reduce((a, b) => a + b);
        edumanOrderInfo.monthlyRate = totalEdumanListSelectionValue;
        this.setState({ edumanOrderInfo });
        this.setState({ edumanListSelection: e.value });
        edumanModuleID = edumanListSelectionArr[0].map(item => item.moduleId).join();
    }


    onChangePoint = (e) => {
        let selectedPoint = [...this.state.pointList];

        if (e.checked)
            selectedPoint.push(e.value);
        else
            selectedPoint.splice(selectedPoint.indexOf(e.value), 1);

        this.setState({ pointList: selectedPoint });
    }


    onChangeEdumanTaggedID = () => {
        let { userBasicInfo } = this.state;
        this.setState({ edumanTaggedID: true, errorMsgVisible: false });
        this.partnerPointService.getInstituteListByUserAdmin(userBasicInfo.netiID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then(body => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].formApproveDate == null) {
                                body[i].formApproveDate = '';
                            } else {
                                body[i].formApproveDate = NetiDateUtils.getAnyShortForm(body[i].formApproveDate, 'DD-MMM-YYYY');
                            }
                        }
                        this.setState({ dataTableValue: body, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp, topProgressBar: false, })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
            });
    }

    onHideEdumanTaggedID = (event) => {
        this.setState({ edumanTaggedID: false });
    }

    onChangePurchaseCode = (e) => {
        let { edumanDetailsInfoError } = this.state;
        edumanDetailsInfoError["productPurchaseCode"] = '';
        this.setState({ purchaseCode: e.target.value, edumanDetailsInfoError });
    }

    onSearchPurchaseCode = () => {
        let { edumanDetailsInfoError } = this.state;
        this.setState({ purchaseInfo: cloneStateBeforeMount.purchaseInfo, searchErrorMsgBody: '' });
        this.setState({ searchViewError: false, errorMsgVisible: false, searchView: false });
        if (this.state.purchaseCode !== '') {
            this.setState({ topProgressBar: true, firstInputFieldIsLoading: true, searchView: true });
            this.partnerPointService.getUserPurchaseCode(this.state.purchaseCode)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then(body => {
                            console.log("purchase code Info", body);
                            this.setState({ purchaseInfo: body });
                            this.setState({ purchaseCodeSearchBtnDisabled: false })
                            this.setState({ topProgressBar: false, searchViewError: false, firstInputFieldIsLoading: false, errorMsgVisible: false, searchView: true });
                            this.setPackageName(body);
                        });
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, purchaseCodeSearchBtnDisabled: true, searchView: true, firstInputFieldIsLoading: false, errorMsgVisible: true, searchViewError: true, searchErrorMsgBody: resp })
                            });
                        this.setPackageName(null);
                    }
                }).catch(error => {
                    this.setState({ purchaseCodeSearchBtnDisabled: true, topProgressBar: false, firstInputFieldIsLoading: false, errorMsgVisible: true, searchView: false, searchViewError: false, errorMsgBody: 'Unable to load. Please check your connection.' })
                    this.setPackageName(null);
                });
        } else {
            edumanDetailsInfoError["productPurchaseCode"] = 'Purchase Code can\'t left empty.';
            this.setState({ purchaseCodeSearchBtnDisabled: true });
            this.setPackageName(null);
        }

    }

    setPackageName = (purchaseInfo) => {
        let { edumanOrderInfo, packageNameDropDownList } = this.state;
        if (purchaseInfo) {
            for (let i = 0; i < packageNameDropDownList.length; i++) {
                if (packageNameDropDownList[i].categoryName == purchaseInfo.productPurchaseLogDTO.productInfoDTO.productName) {
                    edumanOrderInfo.packageCoreCategoryInfoDTO = packageNameDropDownList[i];
                }
            }
            this.setState({ edumanOrderInfo });
        } else {
            edumanOrderInfo.packageCoreCategoryInfoDTO = {};
            this.setState({ edumanOrderInfo });
        }
    }

    onChangeCustomNetiID = (e) => {
        let { edumanDetailsInfoError } = this.state;
        edumanDetailsInfoError["netiID"] = '';
        this.setState({ customNetiID: e.target.value, edumanDetailsInfoError });
    }

    onSearchCustomNetiID = () => {
        this.setState({ userBasicInfo: cloneStateBeforeMount.userBasicInfo, adminMobileNo: '', edumanTaggedIDbtnnDisabled: true });
        this.setState({ netiSearchViewError: false, netiSearchErrorMsgBody: '', errorMsgVisible: false, netiSearchView: false })
        if (this.onSearchCustomNetiIDErrorHandle()) {
            this.setState({ topProgressBar: true, secondInputFieldIsLoading: true, netiSearchView: true });
            this.emAdminPointService.getUserByCustomIDAndRole(this.state.customNetiID, 'ROLE_EMADMIN')
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then(body => {
                            this.setState({ netiIDSearchBtnDisabled: false })
                            this.setState({ userBasicInfo: body, edumanTaggedIDbtnnDisabled: false, netiSearchViewError: false, netiSearchView: true })
                            this.teacherPortal.isStaffAsAUser(body.basicMobile)
                                .then(data => {
                                    if (data == true) {
                                        this.setState({ searchAdminNoBtn: true, viewAdminMobileNoField: true, adminMobileNo: body.basicMobile });
                                        this.setState({ topProgressBar: false, netiSearchView: true, netiSearchViewError: false, secondInputFieldIsLoading: false, errorMsgVisible: false })
                                    } else {
                                        this.setState({ viewAdminMobileNoField: false, searchAdminNoBtn: false });
                                        this.setState({ topProgressBar: false, netiSearchView: true, netiSearchViewError: false, secondInputFieldIsLoading: false, errorMsgVisible: true })
                                    }
                                })
                        });
                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, netiIDSearchBtnDisabled: true, netiSearchViewError: true, netiSearchView: true, secondInputFieldIsLoading: false, errorMsgVisible: true, netiSearchErrorMsgBody: resp })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, secondInputFieldIsLoading: false, netiIDSearchBtnDisabled: true, netiSearchView: false, netiSearchViewError: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })
                });

        } else {
            this.setState({ netiIDSearchBtnDisabled: true });
        }

    }

    onChangeAdminMobileNo = (e) => {
        let { edumanDetailsInfoError } = this.state;
        edumanDetailsInfoError["adminMblNo"] = '';
        this.setState({ adminMobileNo: e.target.value, edumanDetailsInfoError, urlErrorMsgBody: '' });
    }

    onSearchAdminMblNo = (e) => {
        let { edumanDetailsInfoError } = this.state;
        if (this.adminMobileNUmberCheck()) {
            this.setState({ topProgressBar: true, secondInputFieldIsLoading: true });
            this.teacherPortal.isStaffAsAUser(this.state.adminMobileNo)
                .then(data => {
                    if (data == true) {
                        console.log("data", data);
                        this.setState({ urlMsgView: 'text-success', urlErrorMsgBody: "You can use this contact number." })

                        // edumanDetailsInfoError["adminMblNo"] = 'You can use this contact number.';
                        this.setState({ viewAdminMobileNoField: true });
                        this.setState({ topProgressBar: false, netiSearchViewError: false, secondInputFieldIsLoading: false, errorMsgVisible: false })
                    } else {
                        this.setState({ urlMsgView: 'text-danger', urlErrorMsgBody: 'Please try with another number.' })
                        // edumanDetailsInfoError["adminMblNo"] = 'Pleae try with another number.';
                        this.setState({ viewAdminMobileNoField: false });
                        this.setState({ topProgressBar: false, netiSearchViewError: false, secondInputFieldIsLoading: false, errorMsgVisible: true })
                    }
                })
        }

    }

    getBoardOrTypeOrCategoryOrDivisionByDefCode(defaultCode) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if (defaultCode === 'T10202') {
                            this.setState({ divisionDropDownList: body });
                        }
                        else if (defaultCode === 'T106') {
                            this.setState({ boardDropDownList: body });
                        }
                        else if (defaultCode === 'T105') {
                            this.setState({ instituteTypeDropDownList: body });
                        }
                        else if (defaultCode === 'T113') {
                            this.setState({ instituteCategoryDropDownList: body });
                        }
                        else if (defaultCode === 'T111') {
                            this.setState({ packageNameDropDownList: body });
                        }
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
                    });

                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }

    /* Institute Info*/
    onChangeInstituteName = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.instituteName = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
    }

    onChangeInstituteContact = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.instituteContact = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
    }

    onChangeInstituteEmail = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.instituteEmail = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
    }

    onChangeBoard = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.instituteBoardCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
    }

    onChangeInstituteType = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.instituteTypeCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
    }

    onChangeInstituteCategory = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.instituteCategoryCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
    }

    /* Address Details*/
    onChangeDivision = (e) => {
        this.setState({ districtDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.setState({ division: e.target.value })
        this.userCategoryService.fetchListByParentCategoryID(e.target.value)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ districtDropDownList: body });
                        this.setState({ districtDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false })

                    });
                } else {
                    return res.json().then(errorMsg => {
                        this.setState({ districtDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Opps! Something went wrong' })
                    });
                }
            }).catch(error => {
                this.setState({ districtDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })

            });
        this.onClearErrorMsg(e.target.name);
    }

    onChangeDistrict = (e) => {

        this.setState({ districtId: e.target.value })
        this.setState({ upazillaDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false })

        if (e.target.value) {
            this.userCategoryService.fetchListByParentCategoryID(e.target.value)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ upazillaDropDownList: body });
                            this.setState({ upazillaDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                        });
                    } else {
                        this.userCategoryService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ upazillaDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ upazillaDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })
                });
        }
        this.onClearErrorMsg(e.target.name);
    }

    onChangeAddress = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.instituteAddress = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
    }

    /* Payment Info*/
    onChangePackageName = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.packageCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
    }

    onChangePaymentType = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.paymentType = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
        this.setServiceCharge();
    }

    setServiceCharge = () => {
        let { edumanOrderInfo, edumanRate } = this.state;
        this.partnerPointService.getEmRate(edumanOrderInfo.paymentType, edumanOrderInfo.packageCoreCategoryInfoDTO.categoryName)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        edumanRate = body;
                        edumanOrderInfo.monthlyRate = body.monthlyRate;
                        this.setState({ edumanOrderInfo, edumanRate });
                    });
                } else {
                }
            }).catch(error => {

            });
    }
    onChangeServiceCharge = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.monthlyRate = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
    }

    onChangeRegistrationCharge = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.developmentCharge = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);
    }

    /* Addional Info*/
    onChangeAgreementStartDate = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.agreementStartDate = NetiDateUtils.getDateFromString(e.target.value);
        this.setState({ edumanOrderInfo,  agreementStartDate: e.target.value});
        this.onClearErrorMsg(e.target.name);
    }

    onChangeAgreementExpiryDate = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.agreementExpiryDate =  NetiDateUtils.getDateFromString(e.target.value);
        this.setState({ edumanOrderInfo, agreementExpiryDate:e.target.value });
        this.onClearErrorMsg(e.target.name);

    }

    onChangeAgreementFormSerial = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.formSerial = e.target.value;
        this.setState({ edumanOrderInfo, });
        this.onClearErrorMsg(e.target.name);

    }

    onChangeDataSubmissionDate = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.dataSubmissionDate =  NetiDateUtils.getDateFromString(e.target.value);
        this.setState({ edumanOrderInfo, dataSubmissionDate:e.target.value });
        this.onClearErrorMsg(e.target.name);

    }

    onChangeHandoverDate = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.handoverDate =  NetiDateUtils.getDateFromString(e.target.value);
        this.setState({ edumanOrderInfo, handoverDate : e.target.value });
        this.onClearErrorMsg(e.target.name);

    }

    onImageUpload = (e) => {
        var reader = new FileReader();
        let photo = e.files[0];
        reader.readAsDataURL(photo);
        reader.onload = () => {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            this.setState({ proPic: album });
        }

        let { edumanDetailsInfoError } = this.state;
        edumanDetailsInfoError['agreementForm'] = ''
        this.setState({ edumanDetailsInfoError })
    }

    onChangeUpazilla = (e) => {
        let { edumanOrderInfo } = this.state;
        edumanOrderInfo.globalAreaCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ edumanOrderInfo });
        this.onClearErrorMsg(e.target.name);

    }

    onSubmitOrderHandler = (e) => {
        let { edumanOrderInfo, purchaseInfo, userBasicInfo, adminMobileNo } = this.state;
        edumanOrderInfo.productPurchaseCodeDTO = purchaseInfo;
        edumanOrderInfo.assignedUserBasicInfoDTO.netiID = userBasicInfo.netiID;
        edumanOrderInfo.instituteAdminContact = adminMobileNo;
        // edumanOrderInfo.modules = edumanModuleID;
        
        if (this.state.proPic.contentPic !== null) {
            edumanOrderInfo.formAttachmentContent = this.state.proPic.contentPic;
            edumanOrderInfo.formAttachmentSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            edumanOrderInfo.formAttachmentName = Date.now() + extention;
        }
        // edumanOrderInfo.agreementStartDate = NetiDateUtils.getDateFromString(edumanOrderInfo.agreementStartDate)
        // edumanOrderInfo.agreementExpiryDate = NetiDateUtils.getDateFromString(edumanOrderInfo.agreementExpiryDate)
        // edumanOrderInfo.dataSubmissionDate = NetiDateUtils.getDateFromString(edumanOrderInfo.dataSubmissionDate)
        // edumanOrderInfo.handoverDate = NetiDateUtils.getDateFromString(edumanOrderInfo.handoverDate)

        this.setState({ edumanOrderInfo });
        if (this.edumanAddInfoFormHandleError()) {
            if (Date.parse(edumanOrderInfo.agreementExpiryDate) > Date.parse(edumanOrderInfo.agreementStartDate)) {
                if (Date.parse(edumanOrderInfo.handoverDate) > Date.parse(edumanOrderInfo.dataSubmissionDate)) {
                    this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
                    this.partnerPointService.createNewEmDetailsInfo(edumanOrderInfo)
                        .then(res => {
                            if (res.status == 201) {
                                this.setState({
                                    adminMobileNo: '',
                                    purchaseCode: '',
                                    customNetiID: '',
                                    edumanOrderInfo: cloneStateBeforeMount.edumanOrderInfo,
                                    purchaseInfo: cloneStateBeforeMount.purchaseInfo,
                                    userBasicInfo: cloneStateBeforeMount, userBasicInfo
                                });
                                this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                                this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Ordered" });
                            } else {
                                this.partnerPointService.Auth.handleErrorStatus(res)
                                    .then((responseBody) => {
                                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                    });
                            }
                        });
                } else {
                    this.growl.show({ severity: 'warn', summary: 'Warn Message', detail: "Submission Date can't be ahead of Handover Date" });
                }
            } else {
                this.growl.show({ severity: 'warn', summary: 'Warn Message', detail: "Agreement Start Date can't be ahead of Expiry Date" });

            }
        }
    }

    onClearErrorMsg = (errorIndex) => {
        let { edumanDetailsInfoError } = this.state;
        edumanDetailsInfoError[errorIndex] = ''
        this.setState({ edumanDetailsInfoError })
    }

    onSearchCustomNetiIDErrorHandle = () => {
        let { edumanDetailsInfoError, customNetiID } = this.state;
        let formIsValid = true;
        this.setState({ searchViewError: false })

        if (customNetiID == '') {
            formIsValid = false;
            console.log('this id empty');
            edumanDetailsInfoError['netiID'] = 'Neti ID can\'t left empty';
        }

        if (customNetiID.length < 10) {
            formIsValid = false
            console.log('this id sort');
            edumanDetailsInfoError['netiID'] = 'Neti ID is too sort';
        }

        if (customNetiID == '' && customNetiID.length < 10) {
            formIsValid = false
            edumanDetailsInfoError['netiID'] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10';
        }

        this.setState({ edumanDetailsInfoError })

        return formIsValid;
    }

    adminMobileNUmberCheck = () => {
        let { edumanDetailsInfoError, edumanOrderInfo } = this.state;
        let formIsValid = true;
        if (this.state.adminMobileNo === '') {
            formIsValid = false;
            edumanDetailsInfoError["adminMblNo"] = "Admin Mobile No. can't left empty.";
        }
        this.setState({ edumanDetailsInfoError });
        return formIsValid;
    }

    edumanAddInfoFormHandleError = () => {
        let { edumanDetailsInfoError, edumanOrderInfo } = this.state;
        let formIsValid = true;

        if (this.state.purchaseCode === '') {
            formIsValid = false;
            edumanDetailsInfoError["productPurchaseCode"] = "Purchase code can't left empty.";
        }
        if (this.state.customNetiID === '') {
            formIsValid = false;
            edumanDetailsInfoError["netiID"] = "Neti ID can't left empty.";
        }
        if (edumanOrderInfo.instituteName === '') {
            formIsValid = false;
            edumanDetailsInfoError["instituteName"] = "Institute Name can't left empty.";
        }
        if (edumanOrderInfo.instituteContact === '') {
            formIsValid = false;
            edumanDetailsInfoError["instituteContactNo"] = "Institute Contact No. can't left empty.";
        }

        if (edumanOrderInfo.instituteEmail === '') {
            formIsValid = false;
            edumanDetailsInfoError["instituteEmail"] = "Institute Email Address can't left empty.";
        }
        else if (!this.ValidatorUtility.emailIsValid(edumanOrderInfo.instituteEmail)) {
            formIsValid = false;
            edumanDetailsInfoError["instituteEmail"] = "Invalid Email ID";
        }

        if (edumanOrderInfo.instituteBoardCoreCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            edumanDetailsInfoError["board"] = "Education Board can't left empty.";
        }
        if (edumanOrderInfo.instituteTypeCoreCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            edumanDetailsInfoError["instituteType"] = "Institute Type can't left empty.";
        }
        if (edumanOrderInfo.instituteCategoryCoreCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            edumanDetailsInfoError["instituteCategory"] = "Institute Category can't left empty.";
        }
        if (this.state.division === '') {
            formIsValid = false;
            edumanDetailsInfoError["division"] = "Division can't left empty.";
        }
        if (this.state.districtId === '') {
            formIsValid = false;
            edumanDetailsInfoError["district"] = "District can't left empty.";
        }
        if (edumanOrderInfo.globalAreaCoreCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            edumanDetailsInfoError["upazilla"] = "Upazilla can't left empty.";
        }
        if (edumanOrderInfo.instituteAddress === '') {
            formIsValid = false;
            edumanDetailsInfoError["address"] = "Address can't left empty.";
        }

        if (edumanOrderInfo.paymentType === '') {
            formIsValid = false;
            edumanDetailsInfoError["paymentType"] = "Payment Type can't left empty.";
        }
        if (edumanOrderInfo.monthlyRate === 0) {
            formIsValid = false;
            edumanDetailsInfoError["serviceCharge"] = "Service Charge can't left empty.";
        }
        if (edumanOrderInfo.developmentCharge === '') {
            formIsValid = false;
            edumanDetailsInfoError["registrationCharge"] = "Registration Charge can't left empty.";
        }
        if (this.state.agreementStartDate === '') {
            formIsValid = false;
            edumanDetailsInfoError["agreementStartDate"] = "Agreement Start Date can't left empty.";
        }
        if (this.state.agreementExpiryDate === '') {
            formIsValid = false;
            edumanDetailsInfoError["agreementExpiryDate"] = "Agreement Expiry Date can't left empty.";
        }
        if (edumanOrderInfo.formSerial === '') {
            formIsValid = false;
            edumanDetailsInfoError["agreementFormSerial"] = "Agreement Form Serial can't left empty.";
        }
        if (this.state.dataSubmissionDate === '') {
            formIsValid = false;
            edumanDetailsInfoError["dataSubmissionDate"] = "Data Submission date can't left empty.";
        }
        if (this.state.handoverDate === '') {
            formIsValid = false;
            edumanDetailsInfoError["handoverDate"] = "Handover Date can't left empty.";
        }
        if (this.state.proPic.contentPic === null) {
            formIsValid = false;
            edumanDetailsInfoError["agreementForm"] = "Agreement Form can't left empty.";
        }
        
        if (edumanOrderInfo.packageCoreCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            edumanDetailsInfoError["packageName"] = "Package Name. can't left empty.";
        }
        this.setState({ edumanDetailsInfoError });
        return formIsValid;
    }

    focusSearch = e => {
        this.setState({
            searchLabel: "Search",
            addSearchBtnClass: "nw-button p-button-primary text-color-white"
        });
    }

    onBlur = e => {
        this.setState({
            searchLabel: "",
            addSearchBtnClass: ""
        });
    }

    searchLabelNetiId = e => {
        this.setState({
            searchLabelNetiId: "Search",
            addSearchBtnClassNetiId: "nw-button p-button-primary text-color-white"
        });
    }

    onBlurNetiId = e => {
        this.setState({
            searchLabelNetiId: "",
            addSearchBtnClassNetiId: ""
        });
    }

    searchLabelMobile = e => {
        this.setState({
            searchLabelMobile: "Search",
            addSearchBtnClassMobile: "nw-button p-button-primary text-color-white"
        });
    }

    onBlurMobile = e => {
        this.setState({
            searchLabelMobile: "",
            addSearchBtnClassMobile: ""
        });
    }


    render() {
        let { purchaseInfo, userBasicInfo, edumanOrderInfo, topProgressBar, errorMsgVisible, errorMsgBody, firstInputFieldIsLoading
            , secondInputFieldIsLoading } = this.state;

            console.log('edumanOrderInfo.agreementStartDate', edumanOrderInfo.agreementStartDate);
            
        let formatRegistrationDate = (userBasicInfo && userBasicInfo.registrationDate ? NetiDateUtils.getAnyShortForm(userBasicInfo.registrationDate, 'DD-MMM-YYYY') : '');
        let formatPurchaseDate = (purchaseInfo && purchaseInfo.productPurchaseLogDTO.purchaseDate ? NetiDateUtils.getAnyShortForm(purchaseInfo && purchaseInfo.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY') : '');
        let formatContactNo = (userBasicInfo && userBasicInfo.basicMobile ? userBasicInfo.basicMobile.replace(/-/g, '').replace(/\+/g, '').replace(/\s/g, '') : '');

        let productUseStatus;
        if (purchaseInfo && purchaseInfo.usedStatus === 0) {
            productUseStatus = "Unused";
        } else if (purchaseInfo && purchaseInfo.usedStatus === 1) {
            productUseStatus = "Used"
        }

        let boardList = [];
        if (this.state.boardDropDownList) {
            if (this.state.boardDropDownList.length > 0) {
                boardList = this.state.boardDropDownList.map((item) => ({
                    value: item.coreCategoryID,
                    label: item.categoryName,
                }));
            }
        }

        let instituteTypeList = [];
        if ((this.state.instituteTypeDropDownList != null) && (this.state.instituteTypeDropDownList.length > 0)) {
            instituteTypeList = this.state.instituteTypeDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let instituteCategoryList = [];
        if ((this.state.instituteCategoryDropDownList != null) && (this.state.instituteCategoryDropDownList.length > 0)) {
            instituteCategoryList = this.state.instituteCategoryDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let divisionList = [];
        if ((this.state.divisionDropDownList != null) && (this.state.divisionDropDownList.length > 0)) {
            divisionList = this.state.divisionDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let districtList = []
        if (this.state.districtDropDownList && this.state.districtDropDownList.length) {
            districtList = this.state.districtDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let upazillaList = []
        if (this.state.upazillaDropDownList && this.state.upazillaDropDownList.length) {
            upazillaList = this.state.upazillaDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let packageNameList = []
        if (this.state.packageNameDropDownList && this.state.packageNameDropDownList.length) {
            packageNameList = this.state.packageNameDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        const paymentTypeList = [
            { label: 'PRE-PAID', value: 'PRE-PAID' },
            { label: 'POST-PAID', value: 'POST-PAID' }
        ];

        var tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Eduman Tagged ID
       <span style={{ 'float': 'right' }}>Total Found: {this.state.dataTableValue ? this.state.dataTableValue.length : ''} </span>
        </div>;


        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">

                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                                <InputText
                                                    value={this.state.purchaseCode}
                                                    onChange={this.onChangePurchaseCode}
                                                    placeholder="Enter Purchase Code"
                                                    type="text"
                                                    keyfilter="pint"
                                                    style={{ width: "15%" }}
                                                    name="productPurchaseCode"
                                                    onFocus={this.focusSearch}
                                                    onBlur={this.onBlur}
                                                />
                                                <Button
                                                    className={"p-button-animation " + this.state.addSearchBtnClass}
                                                    icon="fas fa-search"
                                                    label={this.state.searchLabel}
                                                    onClick={this.onSearchPurchaseCode}
                                                    onFocus={this.focusSearch}
                                                    onBlur={this.onBlur}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.edumanDetailsInfoError["productPurchaseCode"]}</span>
                                        </div>

                                        {this.state.searchView ?
                                            <div className="p-col-12 p-xl-12">
                                                <div className="nw-search-view">
                                                    {this.state.searchViewError ?
                                                        <span className="error-message">{this.state.searchErrorMsgBody}</span> :
                                                        <div>
                                                            <div className="p-col-12 p-xl-12">
                                                                {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Product Name</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {purchaseInfo && purchaseInfo.productPurchaseLogDTO && purchaseInfo.productPurchaseLogDTO.productInfoDTO.productName}
                                                                        </div>

                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Use Status</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {productUseStatus}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Purchase Date</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {formatPurchaseDate}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }


                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Neti ID : <span>*</span></span>
                                                <InputText
                                                    value={this.state.customNetiID}
                                                    onChange={this.onChangeCustomNetiID}
                                                    placeholder="Enter Neti ID"
                                                    keyfilter="pint"
                                                    style={{ width: "15%" }}
                                                    type="text"
                                                    name="netiID"
                                                    onFocus={this.searchLabelNetiId}
                                                    onBlur={this.onBlurNetiId}
                                                    maxlength={10}
                                                />
                                                <Button
                                                    className={"p-button-animation " + this.state.addSearchBtnClassNetiId}
                                                    label={this.state.searchLabelNetiId}
                                                    icon="fas fa-search"
                                                    onClick={this.onSearchCustomNetiID}
                                                    onFocus={this.searchLabelNetiId}
                                                    onBlur={this.onBlurNetiId}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.edumanDetailsInfoError["netiID"]}</span>

                                        </div>

                                        {this.state.netiSearchView ?
                                            <div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="nw-search-view">
                                                        {this.state.netiSearchViewError ?
                                                            <center className="error-message">{this.state.netiSearchErrorMsgBody}</center> :
                                                            <div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Name</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {userBasicInfo && userBasicInfo.fullName}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Register Date</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {formatRegistrationDate}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Mobile No.</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {formatContactNo}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <center className={this.state.urlMsgView}>
                                                                    {
                                                                        this.state.urlErrorMsgBody ? this.state.urlErrorMsgBody : null
                                                                    }
                                                                </center>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                    
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Admin Mobile No.  <span>*</span></span>
                                                            <InputText
                                                                value={this.state.adminMobileNo}
                                                                type="text"
                                                                placeholder="enter admin mobile number"
                                                                keyfilter="pint"
                                                                name="adminMblNo"
                                                                autoWidth={false}
                                                                style={{ width: "15%" }}
                                                                maxLength={11}
                                                                onChange={this.onChangeAdminMobileNo}
                                                                onFocus={this.searchLabelMobile}
                                                                onBlur={this.onBlurMobile}
                                                                disabled={this.state.viewAdminMobileNoField}
                                                            />
                                                            <Button
                                                                icon="fas fa-search"
                                                                label={this.state.searchLabelMobile}
                                                                className={"p-button-animation " + this.state.addSearchBtnClassMobile}
                                                                disabled={this.state.searchAdminNoBtn}
                                                                onClick={this.onSearchAdminMblNo}
                                                                onFocus={this.searchLabelMobile}
                                                                onBlur={this.onBlurMobile}
                                                            />
                                                        </div>
                                                    }
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["adminMblNo"]}</span>
                                                </div>

                                            </div>
                                            :
                                            ''
                                        }


                                        <div className="p-col-12 p-xl-12" />

                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div>

                                            <Button
                                                type="button"
                                                icon="fas fa-info-circle"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                // style={{width:'70px' , padding:'0px'}}
                                                onClick={this.onChangeEdumanTaggedID}
                                                disabled={this.state.edumanTaggedIDbtnnDisabled}
                                            >
                                            </Button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">

                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12  ">
                                <Panel header="Institute Info">
                                    {/* <h3>Institute Info</h3> */}
                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-6">
                                            <div className="p-grid">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Institute Name <span>*</span></span>
                                                        <InputText
                                                            value={edumanOrderInfo.instituteName}
                                                            onChange={this.onChangeInstituteName}
                                                            className="custom-form-control"
                                                            id="instituteName"
                                                            name="instituteName"
                                                            placeholder="Enter Institute Name"
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError['instituteName']}</span>
                                                </div>



                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Institute Contact No.  <span>*</span></span>
                                                        <InputText
                                                            className="custom-form-control"
                                                            value={edumanOrderInfo.instituteContact}
                                                            onChange={this.onChangeInstituteContact}
                                                            id="instituteContactNo"
                                                            name="instituteContactNo"
                                                            placeholder="Enter Institute Contact No"
                                                            keyfilter="pint"
                                                            maxLength={11}
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["instituteContactNo"]}</span>
                                                </div>



                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Institute Email Address <span>*</span></span>
                                                        <InputText
                                                            className="custom-form-control"
                                                            value={edumanOrderInfo.instituteEmail}
                                                            onChange={this.onChangeInstituteEmail}
                                                            id="instituteEmail"
                                                            type="email"
                                                            name="instituteEmail"
                                                            placeholder="Enter Institute Email Address"
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["instituteEmail"]}</span>
                                                </div>


                                            </div>

                                        </div>
                                        <div className="p-col-12 p-xl-6">
                                            <div className="p-grid">

                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.firstDropdownIsLoading ?
                                                        this.netiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Education Board <span>*</span></span>
                                                            <Dropdown
                                                                placeholder="Select Education Board"
                                                                value={edumanOrderInfo && edumanOrderInfo.instituteBoardCoreCategoryInfoDTO && edumanOrderInfo.instituteBoardCoreCategoryInfoDTO.coreCategoryID}
                                                                onChange={this.onChangeBoard}
                                                                options={boardList}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="board"
                                                            />
                                                        </div>
                                                    }
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["board"]}</span>
                                                </div>


                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.firstDropdownIsLoading ?
                                                        this.netiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Institute Type <span>*</span></span>
                                                            <Dropdown
                                                                placeholder="Select Institute Type"
                                                                value={edumanOrderInfo && edumanOrderInfo.instituteTypeCoreCategoryInfoDTO && edumanOrderInfo.instituteTypeCoreCategoryInfoDTO.coreCategoryID ? edumanOrderInfo.instituteTypeCoreCategoryInfoDTO.coreCategoryID : ''}
                                                                onChange={this.onChangeInstituteType}
                                                                options={instituteTypeList}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="instituteType"
                                                            />
                                                        </div>
                                                    }
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["instituteType"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.firstDropdownIsLoading ?
                                                        this.netiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Institute Category <span>*</span></span>
                                                            <Dropdown
                                                                placeholder="Select Institute Category"
                                                                value={edumanOrderInfo && edumanOrderInfo.instituteCategoryCoreCategoryInfoDTO && edumanOrderInfo.instituteCategoryCoreCategoryInfoDTO.coreCategoryID}
                                                                onChange={this.onChangeInstituteCategory}
                                                                options={instituteCategoryList}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="instituteCategory"
                                                            />
                                                        </div>
                                                    }
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["instituteCategory"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        </div>

                    </div>
                    <div className="p-col-12">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <Panel header="Address Details">
                                    {/* <h3>Address Details</h3> */}
                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-6">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.firstDropdownIsLoading ?
                                                        this.netiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Division <span>*</span></span>
                                                            <Dropdown
                                                                placeholder="Select Division"
                                                                value={this.state.division}
                                                                onChange={this.onChangeDivision}
                                                                options={divisionList}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="division"
                                                            />
                                                        </div>
                                                    }
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["division"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.districtDropdownIsLoading ?
                                                        this.netiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">District <span>*</span></span>
                                                            <Dropdown
                                                                placeholder="Select District"
                                                                options={districtList}
                                                                value={this.state.districtId}
                                                                onChange={this.onChangeDistrict}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="district"
                                                            />
                                                        </div>
                                                    }
                                                    <span className="error-message">{this.state.edumanDetailsInfoError['district']}</span>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="p-col-12 p-xl-6">
                                            <div className="p-grid">

                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.upazillaDropdownIsLoading ?
                                                        this.netiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Upazilla/Thana <span>*</span></span>
                                                            <Dropdown
                                                                placeholder="Select Upazilla/Thana"
                                                                value={edumanOrderInfo && edumanOrderInfo.globalAreaCoreCategoryInfoDTO && edumanOrderInfo.globalAreaCoreCategoryInfoDTO.coreCategoryID}
                                                                options={upazillaList}
                                                                onChange={this.onChangeUpazilla}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="upazilla"
                                                            />
                                                        </div>
                                                    }
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["upazilla"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">Address <span>*</span></span>
                                                        <InputTextarea
                                                            value={edumanOrderInfo.instituteAddress}
                                                            onChange={this.onChangeAddress}
                                                            placeholder='Enter Address'
                                                            name="address"
                                                            filter={true}
                                                            filterBy="label,value"
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError['address']}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        </div>

                    </div>
                    <div className="p-col-12" >
                        <div className="p-grid nw-form">

                            <div className="p-col-12 p-xl-12">
                                <Panel header="Payment Info">

                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-6">
                                            <div className="p-grid">



                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.firstDropdownIsLoading ?
                                                        this.netiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Package Name <span>*</span></span>
                                                            <Dropdown
                                                                value={edumanOrderInfo && edumanOrderInfo.packageCoreCategoryInfoDTO && edumanOrderInfo.packageCoreCategoryInfoDTO.coreCategoryID}
                                                                options={packageNameList}
                                                                onChange={this.onChangePackageName}
                                                                placeholder="Select Package Name"
                                                                showClear={true} disabled
                                                                autoWidth={false}
                                                                name="packageName"
                                                            />
                                                        </div>
                                                    }
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["packageName"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Payment Type <span>*</span></span>
                                                        <Dropdown
                                                            value={edumanOrderInfo.paymentType}
                                                            onChange={this.onChangePaymentType}
                                                            options={paymentTypeList}
                                                            placeholder="Select Payment Type"
                                                            showClear={true}
                                                            autoWidth={false}
                                                            name="paymentType"
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["paymentType"]}</span>
                                                </div>


                                                {/* <div className="p-col-12 p-xl-10 p-xl-offset-1">
                                                    <div className="p-fluid">
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="card card-w-title">
                                                                    <DataTable
                                                                        value={this.state.edumanModuleList}
                                                                        selectionMode="multiple"
                                                                        header="Modules"
                                                                        selection={this.state.edumanListSelection}
                                                                        onSelectionChange={this.onChangeEdumanListSelection}
                                                                        responsive={true}
                                                                        metaKeySelection={false}
                                                                        rows={10}
                                                                        columnResizeMode="fit"
                                                                    >
                                                                        <Column field="moduleName" header="Module Name" />
                                                                        <Column field="modulePrice" header="Module Price" />
                                                                    </DataTable>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-6">
                                            <div className="p-grid">



                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Service Charge <span>*</span></span>
                                                        <InputText
                                                            value={edumanOrderInfo.paymentType == 'PRE-PAID' ? `${edumanOrderInfo.monthlyRate} Yearly(${this.state.edumanRate.yearlyRate})`
                                                                : edumanOrderInfo.monthlyRate}
                                                            onChange={this.onChangeServiceCharge}
                                                            className="custom-form-control"
                                                            keyfilter="num"
                                                            id="serviceCharge"
                                                            name="serviceCharge"
                                                            placeholder="Enter Service Charge" disabled
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["serviceCharge"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Registration Charge <span>*</span></span>
                                                        <InputText
                                                            value={edumanOrderInfo.developmentCharge}
                                                            onChange={this.onChangeRegistrationCharge}
                                                            keyfilter="num"
                                                            className="custom-form-control"
                                                            id="registrationCharge"
                                                            name="registrationCharge"
                                                            placeholder="Enter Registration Charge"
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["registrationCharge"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        </div>


                    </div>
                    <div className="p-col-12">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <Panel header="Additional Info">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-6">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-xl-12">

                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Agreement Start Date <span>*</span></span>
                                                        <Calendar
                                                            value={this.state.agreementStartDate}
                                                            onChange={(e) => this.onChangeAgreementStartDate(e)}
                                                            showIcon={true}
                                                            yearRange="2010:2030"
                                                            dateFormat="dd/mm/yy"
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            dateOnly="true"
                                                            placeholder="select Agreement Start Date"
                                                            name="agreementStartDate"
                                                            id="agreementStartDate"
                                                        />

                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["agreementStartDate"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Agreement Expiry Date <span>*</span></span>
                                                        <Calendar
                                                            value={this.state.agreementExpiryDate}
                                                            onChange={this.onChangeAgreementExpiryDate}
                                                            dateFormat="dd/mm/yy"
                                                            showIcon={true}
                                                            yearRange="2010:2030"
                                                            monthNavigator={true}
                                                            dateOnly="true"
                                                            yearNavigator={true}
                                                            id="agreementExpiryDate"
                                                            name="agreementExpiryDate"
                                                            showButtonBar={true}
                                                            placeholder="select Agreement Expiry Date"
                                                            className="custom-calender-input"
                                                        />

                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["agreementExpiryDate"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">

                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Agreement Form Serial <span>*</span></span>
                                                        <InputText
                                                            value={edumanOrderInfo.formSerial}
                                                            onChange={this.onChangeAgreementFormSerial}
                                                            className="custom-form-control"
                                                            keyfilter="pint"
                                                            id="agreementFormSerial"
                                                            name="agreementFormSerial"
                                                            placeholder="Enter Agreement Form Serial"
                                                        />

                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["agreementFormSerial"]}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="p-col-12 p-xl-6">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Data Submission Date <span>*</span></span>
                                                        <Calendar
                                                            value={this.state.dataSubmissionDate}
                                                            onChange={this.onChangeDataSubmissionDate}
                                                            dateFormat="dd/mm/yy"
                                                            yearRange="2010:2030"
                                                            monthNavigator={true}
                                                            dateOnly="true"
                                                            yearNavigator={true}
                                                            id="dataSubmissionDate"
                                                            name="dataSubmissionDate"
                                                            showIcon={true}
                                                            showButtonBar={true}
                                                            placeholder="select data submission date"
                                                            className="custom-calender-input"
                                                        />

                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["dataSubmissionDate"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Handover Date <span>*</span></span>
                                                        <Calendar
                                                            value={this.state.handoverDate}
                                                            onChange={this.onChangeHandoverDate}
                                                            dateFormat="dd/mm/yy"
                                                            yearRange="2010:2030"
                                                            monthNavigator={true}
                                                            dateOnly="true"
                                                            yearNavigator={true}
                                                            id="handoverDate"
                                                            name="handoverDate"
                                                            showIcon={true}
                                                            showButtonBar={true}
                                                            placeholder="select handover date"
                                                            className="custom-calender-input"
                                                        />

                                                    </div>
                                                    <span className="error-message">{this.state.edumanDetailsInfoError["handoverDate"]}</span>
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-xl-12 nw-upload-button">
                                                <FileUpload
                                                    mode="basic"
                                                    accept="image/*"
                                                    id="attachment"
                                                    maxFileSize={1000000}
                                                    onSelect={this.onImageUpload}
                                                    auto={true}
                                                    name="agreementForm"
                                                    chooseLabel="Agreement Form"
                                                />
                                            </div>
                                            <center>
                                                {this.state.proPic && this.state.proPic.contentName}
                                            </center>
                                            <center>
                                                <span className="error-message">{this.state.edumanDetailsInfoError["agreementForm"]}</span>
                                            </center>
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12" />


                        <div className="p-col-12 p-xl-12 nw-button-parent" >

                            <div className="required-field">
                                (<span>*</span>) required fields
                            </div>

                            <Button
                                className="p-button p-button-primary nw-button nw-button-right"
                                label="Place Your Order"
                                onClick={this.onSubmitOrderHandler}
                                disabled={this.state.purchaseCodeSearchBtnDisabled == false && this.state.netiIDSearchBtnDisabled == false ? !this.state.buttonDisabled : this.state.buttonDisabled}
                            />
                        </div>
                    </div>

                    <Dialog className="nw-dialog" header="Eduman Tagged ID" visible={this.state.edumanTaggedID} onHide={this.onHideEdumanTaggedID} closable>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="card card-w-title">
                                        <DataTable
                                            value={this.state.dataTableValue}
                                            selectionMode="single"
                                            header={tableHeader}
                                            selection={this.state.dataTableSelection}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            rows={10}
                                            columnResizeMode="fit"
                                            paginator={true}
                                        >
                                            <Column field="emDetailsId" header="Eduman ID" filter={true} />
                                            <Column field="instituteName" header="Institute Name" filter={true} />
                                            <Column field="formApproveDate" header="ID Create Date" filter={true} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}