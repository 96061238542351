import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';

export class EmUserPointService { 

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    assignMyEmUserPoint() {
        let uri = this.baseURIHolder.getUser() + '/em/user/point/assign';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error)); 
    }

    checkForLinkUpNewPortal(mappingDetails) {
        let uri = this.baseURIHolder.getUser() + '/em/user/linkup/check';
        return this.Auth.postFetch(uri, mappingDetails)
            .catch((error) => console.log('error', error)); 
    }

    linkUpNewPortal(mappingDetails) {
        let uri = this.baseURIHolder.getUser() + '/em/user/linkup/new';
        return this.Auth.postFetch(uri, mappingDetails)
            .catch((error) => console.log('error', error)); 
    }

    fetchMappedPortalList() {
        let uri = this.baseURIHolder.getUser() + '/em/linked-institutes';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error)); 
    }
}