import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { SeatInfoList } from './SeatInfoList';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import staticImg from '../../../assets/images/avatar.png';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ImageCropper } from '../../common/ImageCropper';
import CommonFuctionality from '../../common/CommonFuctionality';

let errors = {};
let urlId;

export class AdminAlumnus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            memberSerial: '',
            btnDisabled: false,
            updateBtnDisabled: false,
            memberInfo: {},
            memberId: '',
            memberStatus: '',
            dialogFormType: false,
            cmsId: '',

            requestedObjBody: {
                "alumonusMemberId": '',
                "batch": "",
                "cmsId": 0,
                "name": "",
                "email": "",
                "contactNo": "",
                "createBy": "",
                "designation": "",
                "details": "",
                "fileContent": "",
                "fileSaveOrEditable": false,
                "imageName": "",
                "organization": "",
                "serial": '',
                "modifiedBy": "",
            },

            memberType: '',
            memberEmail: '',
            facebookProfile: '',
            twitterProfile: '',
            memberName: '',
            linkedinProfile: '',
            memberMobile: '',
            memberDesignation: '',
            viewMemberInfo: false,
            uploadFile: {
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            dataTableValue: [],
            errors: {},
            homeReturnButton: true,
            dataTableIsLoading: false,
            firstDropDownIsLoading: false,
            secondDropDownIsLoading: false,
            totalDataFound: 0,
            deleteDialogVisible: false,

            cropperVisible: false,
            cropperObject: {
                //main class parameter
                main: {
                    viewport: { width: 270, height: 270 },
                    boundary: { width: 600, height: 480 },
                    showZoomer: true,
                    enableOrientation: false,
                },
                //bind parameter
                bind: {
                    url: '',
                    orientation: 4
                }

            }
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.NetiFileHandler = new NetiFileHandler();
        this.CommonFuctionality = new CommonFuctionality();

        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        urlId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {
            this.setState({ cmsId: urlId });
            this.setState({ homeReturnButton: false })
            this.getTableData();

        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    showFormDialog = (e, rowData, dialogType) => {

        if (dialogType === 'update') {

            let { requestedObjBody } = this.state;

            requestedObjBody.serial = rowData.serial;
            requestedObjBody.alumonusMemberId = rowData.alumonusMemberId;
            requestedObjBody.name = rowData.name;
            requestedObjBody.batch = rowData.batch;
            requestedObjBody.contactNo = rowData.contactNo;
            requestedObjBody.email = rowData.email;
            requestedObjBody.organization = rowData.organization;
            requestedObjBody.designation = rowData.designation;
            requestedObjBody.details = rowData.details;

            this.setState({ requestedObjBody, uploadFile: { fileName: rowData.imageName, fileContent: rowData.imgContent, fileSaveOrEditable: true }, });
            this.showMainForm();

            this.setState({ dialogFormType: false });

        } else if (dialogType === 'save') {

            this.resetSaveAndUpdateForm();
            this.showMainForm();

            this.setState({ dialogFormType: true });

        }

    }

    showDeleteDialog = (rowData) => {
        this.setState({ deleteDialogVisible: true, memberInfo: rowData });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onHideDeleteDialog = () => {
        this.setState({ deleteDialogVisible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    deleteRow = () => {

        let { errors } = this.state;
        this.setState({ topProgressBar: true, errorMsgVisible: false });

        this.DwsService.cmsDeleteAlumnusMemberInfo(this.state.memberInfo.alumonusMemberId)
            .then(res => {
                return res.json()
            }).then(body => {

                if (body.messageType == 1) {
                    // console.log('body.message', body.message);
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    this.growl.show({ life: 800, severity: 'success', summary: 'Success', detail: body.message });
                    this.getTableData();
                    this.onHideDeleteDialog();
                } else {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                }
            })
            .catch(error => {
                this.setState({ errors: errors })
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }

    getTableData = () => {

        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.DwsService.getCmsAlumnusList(this.state.cmsId)
            .then((res) => {

                if (res.status == 200) {
                    return res.json().then((body) => {
                        this.setState({ dataTableValue: body.item });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }

            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    showMainForm = () => {
        this.setState({ visible: true });
    }

    resetSaveAndUpdateForm = () => {

        let { requestedObjBody } = this.state;

        requestedObjBody.serial = '';
        requestedObjBody.name = '';
        requestedObjBody.batch = '';
        requestedObjBody.contactNo = '';
        requestedObjBody.email = '';
        requestedObjBody.organization = '';
        requestedObjBody.designation = '';
        requestedObjBody.details = '';

        this.setState({ requestedObjBody, uploadFile: { fileName: '', fileContent: '', fileSaveOrEditable: false }, })
    }

    onHide = () => {

        this.setState({ visible: false });
        this.emptyErrorMessage();
        this.resetSaveAndUpdateForm();
        this.hideImageCropper();
        this.setState({ btnDisabled: false });
        this.setState({ updateBtnDisabled: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();

    }

    onHideMemberInfo = () => {
        this.setState({ viewMemberInfo: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    memberInfoBody = (rowData) => {

        return (
            <div className="p-grid">
                <div className="p-col-2" style={{ minWidth: "110px" }}>
                    {
                        rowData.imgContent ?
                            <img src={("data:image/*;base64," + rowData.imgContent)} height='auto' width='80px' /> : <img src={staticImg} width="100%" />
                    }
                </div>

                <div className="p-col-10 nw-datatable-dataview-small-panel productInfo">

                    <div>{rowData.name}</div>
                    <div>{rowData.batch}</div>
                    <div>{rowData.contactNo}</div>
                    <div>{rowData.email}</div>
                    {/* <div>{rowData.organization}</div>
                    <div>{rowData.designation}</div>
                    <div>{rowData.contactNo}</div>
                    <div>{rowData.email}</div> */}

                </div>

            </div>

        )
    }

    editBody = (rowData) => {

        return <div className='text-center'>
            <Button
                className="nw-action-button"
                icon="far fa-eye"
                title="View"
                onClick={(e) => this.viewDialog(rowData)}
                tooltip="View"
                tooltipOptions={{ position: 'top' }}
            />
            <Button
                className="nw-action-button"
                icon="fas fa-pencil-alt"
                title="Update"
                onClick={e => this.showFormDialog(e, rowData, 'update')}
                tooltip="Update"
                tooltipOptions={{ position: 'top' }}
            />
            <Button
                type="button"

                icon="fas fa-trash"
                className="nw-action-button p-button-danger"
                onClick={e => this.showDeleteDialog(rowData)}
                tooltip="Delete"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    }

    viewDialog = (rowData) => {
        this.setState({ memberInfo: rowData });
        this.setState({ viewMemberInfo: true });
    }

    onChangeInputFiledValue = (e) => {

        let { requestedObjBody } = this.state;
        requestedObjBody[e.target.name] = e.target.value;

        this.setState({ requestedObjBody })
        this.clearErrorMsg(e.target.name);
    }

    clearErrorMsg = (name) => {
        let { errors } = this.state;
        errors[name] = '';
        this.setState({ errors })
    }

    onLoadPic = (e) => {

        let { errors, cropperObject } = this.state;
        this.emptyErrorMessage();

        let fileType = this.NetiFileHandler.getFileContentType(e.files[0].name);
        let supportedExtention = ['image/jpeg', 'image/jpg', 'image/png'];
        // console.log('fileType', fileType);
        if (supportedExtention.includes(fileType)) {

            var reader = new FileReader();
            let photo = e.files[0];
            const scope = this
            reader.readAsDataURL(photo);
            reader.onload = () => {
                let content = reader.result;
                var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                let album = {
                    fileContent: urlStr,
                    fileName: photo.name,
                    fileSaveOrEditable: true
                };
                scope.setState({ uploadFile: album });
            }
            errors["image"] = '';
            cropperObject.bind.url = photo.objectURL;
            this.setState({ errors, cropperObject, cropperVisible: true });

        }
        else {
            errors["image"] = "File format not supported";
            this.setState({ errors });
        }

    }

    getCroppedResult = (imageObj) => {

        let { uploadFile, errors } = this.state;

        let maxSize = this.NetiFileHandler.getMaxFileSizeIsValid(imageObj.photoBlob.size, 500000);
        if (maxSize) {
            uploadFile.fileContent = imageObj.contentPic;
            errors["image"] = '';
            this.setState({ errors: errors });
        } else {
            errors["image"] = "Image size can't be more than 500 KB";
            this.setState({ errors });
            this.removeCropSection();
        }

        this.hideImageCropper();
        this.setState({ uploadFile });
    }

    hideImageCropper = () => {
        this.setState({ cropperVisible: false });
    }

    removeCropSection = () => {

        let { uploadFile } = this.state;
        uploadFile.fileContent = '';
        uploadFile.fileName = '';
        uploadFile.fileSaveOrEditable = false;

        this.setState({ cropperVisible: false, uploadFile });
    }

    onSubmitMemberInfo = () => {

        let { errors, requestedObjBody, uploadFile } = this.state;
        let user_name = localStorage.getItem('user_name');

        requestedObjBody.serial = this.state.dataTableValue.length + 1;
        requestedObjBody.imageName = uploadFile.fileName;
        requestedObjBody.fileContent = uploadFile.fileContent;
        requestedObjBody.fileSaveOrEditable = uploadFile.fileSaveOrEditable;
        requestedObjBody.createBy = user_name;
        requestedObjBody.cmsId = parseInt(this.state.cmsId);

        this.setState({ requestedObjBody });

        if (this.emptyFieldHandle()) {

            this.setState({ topProgressBar: true, errorMsgVisible: false, btnDisabled: true });
            this.DwsService.cmsSaveAlumnusMember(requestedObjBody)
                .then(res => {
                    // console.log('alumnus res', res);
                    return res.json()
                }).then(body => {
                    if (body.messageType == 1) {
                        this.growl.show({ life: 800, severity: 'success', summary: 'Success', detail: body.message });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.getTableData();
                        this.onHide();
                    } else {
                        this.setState({ btnDisabled: false })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                    }
                })
                .catch(error => {
                    this.setState({ errors: errors })
                    this.setState({ btnDisabled: false })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });

        }

    }

    onUpdateMemberInfo = () => {

        let { errors, requestedObjBody, uploadFile } = this.state;
        let user_name = localStorage.getItem('user_name');

        requestedObjBody.imageName = uploadFile.fileName;
        requestedObjBody.fileContent = uploadFile.fileContent;
        requestedObjBody.fileSaveOrEditable = uploadFile.fileSaveOrEditable;
        requestedObjBody.modifiedBy = user_name;
        requestedObjBody.cmsId = parseInt(this.state.cmsId);

        this.setState({ requestedObjBody });

        if (this.emptyFieldHandle()) {

            this.setState({ topProgressBar: true, errorMsgVisible: false, updateBtnDisabled: true });
            this.DwsService.cmsUpdateAlumnusMember(requestedObjBody)
                .then(res => {
                    // console.log('update-alumnus-res', res);
                    return res.json()
                }).then(body => {
                    if (body.messageType == 1) {
                        this.growl.show({ life: 800, severity: 'success', summary: 'Success', detail: body.message });
                        this.getTableData();
                        this.resetSaveAndUpdateForm();
                        this.onHide();
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    } else {
                        this.setState({ updateBtnDisabled: false });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                    }
                })
                .catch(error => {
                    this.setState({ errors: errors })
                    this.setState({ updateBtnDisabled: false })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }

    }

    emptyErrorMessage() {
        errors = {}
        this.setState({ errors });
    }

    emptyFieldHandle = () => {

        let { errors, requestedObjBody } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });

        if (requestedObjBody.name === '') {
            formIsValid = false;
            errors["name"] = "Name can't left empty.";
        }
        if (requestedObjBody.batch === '') {
            formIsValid = false;
            errors["batch"] = "Batch Year can't left empty.";
        }

        if (requestedObjBody.contactNo === '') {
            formIsValid = false;
            errors["contactNo"] = "Mobile No. can't left empty.";
        }
        if (requestedObjBody.email === '') {
            formIsValid = false;
            errors["email"] = "Email can't left empty.";
        }

        // if (requestedObjBody.organization === '') {
        //     formIsValid = false;
        //     errors["organization"] = "Organization Year can't left empty.";
        // }
        // if (requestedObjBody.designation === '') {
        //     formIsValid = false;
        //     errors["designation"] = "Designation can't left empty.";
        // }

        if (requestedObjBody.details === '') {
            formIsValid = false;
            errors["details"] = "Details can't left empty.";
        }

        if (requestedObjBody.fileContent === '') {
            formIsValid = false;
            errors["image"] = "Upload Image can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;
    }

    render() {

        let { dataTableValue, requestedObjBody, memberInfo, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let uploadFileLabel = <div>Click / Drag File Here To Upload <br /><span className="upload-file-label">(Max 500 KB (recommended))</span></div>

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Alumnus List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue.length.toLocaleString("EN-IN")}</a>

                <Button
                    className="nw-button nw-button-center p-button-success"
                    name="district"
                    label="Add New"
                    icon="fas fa-plus"
                    iconPos="right"
                    onClick={e => this.showFormDialog(e, null, 'save')}

                />
            </div>
        </div>;

        return (

            <div className="p-fluid">
                <NetiCMSSetupProgress />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">
                    {this.state.homeReturnButton === false ?

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                    columnResizeMode="fit"
                                >
                                    <Column field="serial" header="Serial No." style={{ width: '12em' }} filter={true} />
                                    <Column field="" header="Brief Member Info" body={this.memberInfoBody} filter={true} />
                                    <Column field="action" header="Action" body={this.editBody} style={{ textAlign: 'center', width: '10em' }} />
                                </DataTable>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        header={`${this.state.dialogFormType === true ? 'Add' : 'Update'} Alumnus Member`}
                        visible={this.state.visible}
                        onHide={this.onHide}
                        closable
                        maximizable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12 blur-section">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>
                                                <div className="nw-inputgroup-desc">
                                                    {requestedObjBody.serial ? requestedObjBody.serial : this.state.dataTableValue.length + 1}
                                                </div>
                                            </div>
                                            {/* <span className="error-message">{this.state.errors["memberSerial"]}</span> */}

                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Name <span>*</span></span>
                                                <InputText
                                                    onChange={(e) => this.onChangeInputFiledValue(e)}
                                                    value={requestedObjBody.name}
                                                    placeholder="Enter name"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["name"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Batch Year<span>*</span></span>
                                                <InputText
                                                    onChange={(e) => this.onChangeInputFiledValue(e)}
                                                    value={requestedObjBody.batch}
                                                    placeholder="Ex. 2018-2020"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    id="batch"
                                                    name="batch"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["batch"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Mobile No. <span>*</span></span>
                                                <InputText
                                                    keyfilter="pint"
                                                    maxLength={11}
                                                    id="contactNo"
                                                    onChange={(e) => this.onChangeInputFiledValue(e)}
                                                    value={requestedObjBody.contactNo}
                                                    placeholder="Enter mobile number"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="contactNo"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["contactNo"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Email <span>*</span></span>
                                                <InputText
                                                    id="email"
                                                    onChange={(e) => this.onChangeInputFiledValue(e)}
                                                    value={requestedObjBody.email}
                                                    placeholder="Enter email address"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="email"
                                                    keyfilter="email"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["email"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Organization</span>
                                                <InputText
                                                    id="organization"
                                                    onChange={(e) => this.onChangeInputFiledValue(e)}
                                                    value={requestedObjBody.organization}
                                                    placeholder="Enter Job organization name"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="organization"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["organization"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Designation</span>
                                                <InputText
                                                    id="designation"
                                                    onChange={(e) => this.onChangeInputFiledValue(e)}
                                                    value={requestedObjBody.designation}
                                                    placeholder="Enter designation"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="designation"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["designation"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Details <span>*</span></span>
                                                <InputTextarea
                                                    id="details"
                                                    onChange={(e) => this.onChangeInputFiledValue(e)}
                                                    value={requestedObjBody.details}
                                                    placeholder="Enter Details"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="details"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["details"]}</span>
                                        </div>

                                        {/* <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Facebook Link </span>
                                                <InputText
                                                    id="email"
                                                    onChange={this.onChangeFacebookProfile}
                                                    value={this.state.facebookProfile}
                                                    placeholder="Enter Facebook Link"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="fb"
                                                />
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Linkedin Link </span>
                                                <InputText
                                                    id="email"
                                                    onChange={this.onChangeLinkedinProfile}
                                                    value={this.state.linkedinProfile}
                                                    placeholder="Enter Linkedin Link"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="linkedin"
                                                />
                                            </div>
                                        </div> */}

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Upload Image <span>*</span><br /><span>(PNG/JPG/JPEG)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    {
                                                        this.state.uploadFile.fileContent ?
                                                            <div className="image-view-main">
                                                                <div className="upload-image-view">
                                                                    <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={e => this.setState({ uploadFile: { fileContent: '', fileName: '' } })}
                                                                    />
                                                                    <img
                                                                        src={
                                                                            this.state.uploadFile.fileContent === '' ?
                                                                                'assets/images/demo.jpg'
                                                                                :
                                                                                "data:image/png;base64," + this.state.uploadFile.fileContent
                                                                        }
                                                                        style={{ "height": "80px" }}
                                                                    />

                                                                </div>
                                                                <div className="image-title">{this.state.uploadFile.fileName}</div>
                                                            </div>

                                                            :
                                                            <FileUpload
                                                                chooseLabel={this.state.uploadFile.fileName || uploadFileLabel}
                                                                mode="basic"
                                                                accept="image/*"
                                                                onSelect={e => this.onLoadPic(e)}
                                                                auto={true}
                                                            />
                                                    }

                                                </div>

                                            </div>
                                            {
                                                this.state.uploadFile && this.state.uploadFile.fileContent && this.state.cropperVisible ?
                                                    <ImageCropper
                                                        cropperObject={this.state.cropperObject}
                                                        getCroppedResult={this.getCroppedResult}
                                                        removeCropSection={this.removeCropSection}
                                                    />
                                                    : ''
                                            }
                                            <span className="error-message">{!this.state.uploadFile.fileName && this.state.errors["image"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                            <div className="required-field">(<span>*</span>) required fields</div>
                                            {this.state.dialogFormType === true ?
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-left"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={this.onSubmitMemberInfo.bind(this)}
                                                    disabled={this.state.btnDisabled}
                                                    disabled={this.state.cropperVisible ? true : false}

                                                />
                                                : ''}
                                            {this.state.dialogFormType === false ?
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-left"
                                                    label="Update"
                                                    icon="fas fa-check"
                                                    onClick={this.onUpdateMemberInfo.bind(this)}
                                                    disabled={this.state.updateBtnDisabled}
                                                    disabled={this.state.cropperVisible ? true : false}

                                                />
                                                : ''}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Dialog>

                    <Dialog
                        className="nw-dialog"
                        header="View Member Info"
                        visible={this.state.viewMemberInfo}
                        onHide={this.onHideMemberInfo}
                        closable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12 blur-section">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>
                                                <div className="nw-inputgroup-desc">
                                                    {memberInfo.serial || '---'}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Name</span>
                                                <div className="nw-inputgroup-desc">
                                                    {memberInfo.name || '---'}

                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Batch Year</span>
                                                <div className="nw-inputgroup-desc">
                                                    {memberInfo.batch || '---'}

                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Mobile No.</span>
                                                <div className="nw-inputgroup-desc">
                                                    {memberInfo.contactNo || '---'}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Email</span>
                                                <div className="nw-inputgroup-desc">
                                                    {memberInfo.email || '---'}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Job Organization</span>
                                                <div className="nw-inputgroup-desc">
                                                    {memberInfo.organization || '---'}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Designation</span>
                                                <div className="nw-inputgroup-desc">
                                                    {memberInfo.designation || '---'}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Details</span>
                                                <div className="nw-inputgroup-desc">
                                                    {memberInfo.details || '---'}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Image</span>
                                                <div style={{ paddingLeft: '22px' }}>
                                                    {
                                                        memberInfo.imageName && memberInfo.imgContent ?
                                                            <img src={("data:image/*;base64," + memberInfo.imgContent)} height='auto' width='80px' /> :
                                                            <img src={staticImg} width="100%" />
                                                    }
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </Dialog>

                    <Dialog header="Delete Alumnus" visible={this.state.deleteDialogVisible} onHide={this.onHideDeleteDialog} className="nw-confirm-dialog">
                        <div className="p-fluid">
                            <div className="p-col-12 p-col-12">
                                <div className="confirm-wrapper">

                                    <h1>Are You Sure?</h1>
                                    <div className="nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="No"
                                            icon="fas fa-times"
                                            onClick={this.onHideDeleteDialog}
                                        />
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Yes"
                                            icon="fas fa-check"
                                            onClick={(e) => this.deleteRow()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }
}