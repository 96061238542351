import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import AuthService from "../../../auth/AuthService";
import { BaseURIHolder } from "../../../utils/BaseURIHolder";
import { Growl } from "primereact/growl";
import { FileUpload } from "primereact/fileupload";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ChatBoxService } from '../../../service/chatBox/chatBoxService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';


export class ChatBoxList extends Component {
  constructor() {
    super();
    this.state = {
      purchesPoint: null,
      productType: null,
      productName: null,
      netiId: "",
      messageBody: "",
      userInfo: {},
      postInfo: {},
      sender: [],
      receiver: [],
      errors: {},
      idcheck: true,
      chatboxDate: null,
      dataTableIsLoading: false,
      secondDataTableIsLoading: false,
      topProgressBar: false,
      searchIsLoading: false,
      chatboxBody: null,
      fullName: null,
      netiIDvalue: null,
      chatboxDate2: null,
      chatboxBody2: null,
      fullName2: null,
      netiIDvalue2: null,
      dataTableSelection: null,
      dataViewValue: [],
      
      dataTableValue: [],
      unreadMessageCount: 20,
    };
    this.actionBody = this.actionBody.bind(this);
    this.actionBody2 = this.actionBody2.bind(this);
    this.viewDialog = this.viewDialog.bind(this);
    this.viewDialog2 = this.viewDialog2.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onHide2 = this.onHide2.bind(this);
    this.getSendMessageList = this.getSendMessageList.bind(this);
    this.onBasicUpload = this.onBasicUpload.bind(this);
    this.AuthService = new AuthService();
    this.baseURIHolder = new BaseURIHolder();
    this.NetiContentLoader = new NetiContentLoader();
    this.chatBoxService = new ChatBoxService();
  }
  
   componentDidMount() {
      this.getReceivedMessageList();
      this.getSendMessageList();
  }

  getSendMessageList () {

    this.setState({ topProgressBar:true, dataTableIsLoading: true, errorMsgVisible: false});

    this.chatBoxService.fetchSendMessageInfo()
        .then(res => {
            if (res.status === 302) {
                return res.json().then((body) => {
                  for(let i=0; i<body.length; i++){
                    if (body[i].chatboxDate == null) {
                        body[i].chatboxDate='';
                    } else {
                        body[i].chatboxDate = NetiDateUtils.getAnyShortForm(body[i].chatboxDate, 'DD-MMM-YYYY');
                    }
                }
                    this.setState({ sender: body, topProgressBar: false, dataTableIsLoading: false });
                });
            } else {
                this.chatBoxService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => 
          this.setState({ topProgressBar:false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
      );
  }



  getReceivedMessageList () {
   
    this.setState({ topProgressBar:true, secondDataTableIsLoading: true, errorMsgVisible: false});
    this.chatBoxService.fetchReceivedMessageInfo()
        .then(res => {
            if (res.status === 302) {
                return res.json().then((body) => {
                  for(let i=0; i<body.length; i++){
                    if (body[i].chatboxDate == null) {
                        body[i].chatboxDate='';
                    } else {
                        body[i].chatboxDate = NetiDateUtils.getAnyShortForm(body[i].chatboxDate, 'DD-MMM-YYYY');
                    }
                }
                    this.setState({ receiver: body, topProgressBar: false, secondDataTableIsLoading: false });
                    
                });
            } else {
                this.chatBoxService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => 
          this.setState({ topProgressBar:false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
      );

  }


  handleProfileInfo = () => {
    if (this.handleError()) 
    this.setState({ topProgressBar:true, searchIsLoading: true, errorMsgVisible: false});
    this.chatBoxService.fetchProfileInfo(this.state.netiId)
        .then(res => {
            if (res.status === 302) {
                return res.json().then((body) => {
                    this.setState({ userInfo: body, topProgressBar: false, searchIsLoading: false });
                    
                });
            } else {
                this.chatBoxService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, searchIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => 
          this.setState({ topProgressBar:false, searchIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
      );

  };



  handlepostData = () => {
    
    if (this.handleError()) 
   
    {
      this.setState({ topProgressBar:true, dataTableIsLoading: true, errorMsgVisible: false});
      let data = {
        chatboxBody: this.state.messageBody,
        receiverBasicInfoDTO: {
          netiID: this.state.userInfo.netiID
        }
      };

      this.chatBoxService.sendProfileInfo(data)
        .then(res => {
          
          if (res.status === 201) {
            this.growl.show({
              severity: "success",
              summary: "Success Message",
              detail: "Message Sent"
            });
            this.getSendMessageList();
            this.setState({ topProgressBar:false, dataTableIsLoading:false, errorMsgVisible: false});
          } else {
            
            this.setState({ topProgressBar:false, dataTableIsLoading:false, errorMsgVisible: true, });
          }
        }).catch(error => 
          this.setState({ topProgressBar:false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
      );
    }
  };



  handleError() {
    let errors = {};
    let formIsValid = true;

    if (this.state.netiId === "") {
      formIsValid = false;
      errors["netiId"] = "Please input atleast one ID.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  onBasicUpload(event) {
    this.growl.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded with Basic Mode"
    });
  }
  viewDialog(rowData) {
    this.setState({ chatboxDate: rowData.chatboxDate });
    this.setState({ chatboxBody: rowData.chatboxBody });
    this.setState({ fullName: rowData.senderBasicInfoDTO.fullName });
    this.setState({ netiIDvalue: rowData.senderBasicInfoDTO.netiID });
    this.setState({ visible: true });
  }
  viewDialog2(rowData) {
    this.setState({ chatboxDate2: rowData.chatboxDate });
    this.setState({ chatboxBody2: rowData.chatboxBody });
    this.setState({ fullName2: rowData.receiverBasicInfoDTO.fullName });
    this.setState({ netiIDvalue2: rowData.receiverBasicInfoDTO.netiID });
    this.setState({ visible2: true });
  }
  onHide(event) {
    this.setState({ visible: false });
  }
  onHide2(event) {
    this.setState({ visible2: false });
  }

  actionBody(rowData, column) {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button p-button-text-only"
          icon="fas fa-info"
          title="View Purchase"
          onClick={e => this.viewDialog(rowData)}
        />
      </div>
    );
  }
  actionBody2(rowData, column) {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button p-button-text-only"
          icon="fas fa-info"
          title="View Purchase"
          onClick={e => this.viewDialog2(rowData)}
        />
      </div>
    );
  }

  handleChange(e) {
    this.setState({ messageBody: e.target.value });
  }
  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody} = this.state;
    let tableHeadersend = (
      <span>
        {" "}
        <span>Sent Message List </span>
        <span className="float-right text-bold"> </span>
      </span>
    );
    let tableHeaderreceive = (
      <span>
        {" "}
        <span> Received Message List</span>
        <span className="float-right text-bold"> </span>
      </span>
    );

    let name;
    let mobile;
    if (this.state.userInfo) {
      name = this.state.userInfo.fullName;
      mobile = this.state.userInfo.basicMobile;
    }

    let readStatusvalue = rowData => {
      if (rowData.readStatus == 0) {
        return <div className="text-center"> Unread </div>;
      } else return <div className="text-center"> Read </div>;
    };

    return (
      <div className="p-fluid">
         {topProgressBar ?
              <ErrorMessageView
                  topProgressBar={topProgressBar}
              />
              : null
          }
          {errorMsgVisible ?
              <ErrorMessageView
                  errorMsgVisible={errorMsgVisible}
                  errorMsgBody={errorMsgBody}
              />
              : null
          }
        <Growl ref={el => (this.growl = el)} />
        <div className="p-grid">
            <div className="p-col-12 p-xl-12 nw-tabview">
                <TabView renderActiveOnly={false}>
                    <TabPanel header="Sent" leftIcon="fas fa-check">
                        <div className= "p-col-12 p-xl-12">
                        { this.state.dataTableIsLoading ?
                        this.NetiContentLoader.MyPointTableLoader():
                        <div className="nw-data-table nw-data-table-tabview">
                            <DataTable
                                header={tableHeadersend}
                                value={this.state.sender}
                                selectionMode="single"
                                selection={this.state.dataTableSelection}
                                onSelectionChange={event =>this.setState({ dataTableSelection: event.value })}
                                responsive={true}
                                paginator={true}
                                rows={10}
                            >
                                <Column
                                field="chatboxDate"
                                header="Date"
                                sortable={true} 
                                filter={true}
                                />
                                <Column
                                field="senderBasicInfoDTO.customNetiID"
                                header="NetiId"
                                sortable={true}
                                filter={true}
                                />
                                <Column
                                field="senderBasicInfoDTO.fullName"
                                header="Name"
                                sortable={true}
                                filter={true}
                                />
                                <Column
                                field="senderBasicInfoDTO.basicMobile"
                                header="Mobile No."
                                sortable={true}
                                filter={true}
                                />
                                <Column
                                header="Read Status"
                                body={readStatusvalue}
                                />
                                <Column
                                header="Action"
                                body={this.actionBody}
                                />
                            </DataTable>
                        </div>
                        }
                        </div>
                    </TabPanel>
                    <TabPanel header="Received" rightIcon="fas fa-inbox">
                        <div className= "p-col-12 p-xl-12">
                        { this.state.secondDataTableIsLoading ?
                        this.NetiContentLoader.MyPointTableLoader():
                        <div className="nw-data-table nw-data-table-tabview">
                            <DataTable
                            header={tableHeaderreceive}
                            value={this.state.receiver}
                            selectionMode="single"
                            selection={this.state.dataTableSelection}
                            onSelectionChange={event =>
                                this.setState({ dataTableSelection: event.value })
                            }
                            responsive={true}
                            paginator={true}
                            rows={10}
                            >
                            <Column
                                field="chatboxDate"
                                header="Date"
                                sortable={true}
                                filter={true}
                            />
                            <Column
                                field="receiverBasicInfoDTO.customNetiID"
                                header="NetiId"
                                sortable={true}
                                filter={true}
                            />
                            <Column
                                field="receiverBasicInfoDTO.fullName"
                                header="Name"
                                sortable={true}
                                filter={true}
                            />
                            <Column
                                field="receiverBasicInfoDTO.basicMobile"
                                header="Mobile No."
                                sortable={true}
                                filter={true}
                            />
                            <Column
                                header="Read Status"
                                body={readStatusvalue}
                            />
                            <Column
                                header="Action"
                                body={this.actionBody2}
                            />
                            </DataTable>
                        </div>
                        }
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
        
        
        <div className="dialog-section">
          <Dialog
            header="Send Message Information"
            visible={this.state.visible}
            className="nw-dialog"
            onHide={this.onHide}
            maximizable
          >
            <div className="p-fluid">
              <div className="p-grid">
                <div className="p-col-6 p-xl-6">
                  <span>Date : {this.state.chatboxDate}</span>
                  <br />
                  <span>Neti Id : {this.state.netiIDvalue}</span>
                  <br />
                  <span>Name : {this.state.fullName}</span>
                  <br />
                  <span>Message Body : {this.state.chatboxBody} </span>
                  <br />
                  <span>
                    Attachement : <a href="file:///C:/Temp/test.pdf">File.pdf</a>{" "}
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            header="Received Message Information"
            visible={this.state.visible2}
            className="nw-dialog"
            onHide={this.onHide2}
            maximizable
          >
            <div className="p-fluid">
              <div className="p-grid">
                <div className="p-col-6 p-xl-6">
                  <span>Date : {this.state.chatboxDate2}</span>
                  <br />
                  <span>Neti Id : {this.state.netiIDvalue2}</span>
                  <br />
                  <span>Name : {this.state.fullName2}</span>
                  <br />
                  <span>Message Body : {this.state.chatboxBody2} </span>
                  <br />
                  <span>
                    Attachement : <a href="file:///C:/Temp/test.pdf">File.pdf</a>{" "}
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </Dialog>
        </div>
        
      </div>
    );
  }
}
