import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { SaSummaryService } from '../../../service/saPoint/SaSummaryService';
export class GenderAndReligionWiseStudentInfo extends Component {

    constructor() {
        super();

        this.state = {
            genderWiseStudentList: [],
            religionWiseStudentList: [],
            dataViewValue: [],
            type: null,
            dataTableSelection: true,
            topProgressBar: false,
            firstDataTableIsLoading: false,
            secondDataTableIsLoading: false
        }
        this.saSummaryService = new SaSummaryService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.getGenderWiseStudentInfoList();
        // this.getReligionWiseStudentInfoList();
    }

    getGenderWiseStudentInfoList = () => {
        this.setState({ topProgressBar: true, firstDataTableIsLoading: true, errorMsgVisible: false })
        this.saSummaryService.getGenderWiseStudentInfo()
            .then(data => {
                console.log("res::::", data);
                
                if (data.item) {
                    if (data.item.length === 0) {
                        return this.setState({ topProgressBar: false, firstDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                    }
                    data.item.map(item => {
                        item.maleInfo = `${item.totalMale} (${item.totalMalePercent}%)`;
                        item.femaleInfo = `${item.totalFemale} (${item.totalFemalePercent}%)`;
                        item.otherInfo = `${item.totalOther} (${item.totalOtherPercent}%)`;
                    })
                    this.setState({ genderWiseStudentList: data.item })
                    this.setState({ topProgressBar: false, firstDataTableIsLoading: false })
                } else {
                    // this.saSummaryService.Auth.handleErrorStatus(data)
                    //     .then((responseBody) => {
                            this.setState({ topProgressBar: false, firstDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                        // });

                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
            });
    }

    getReligionWiseStudentInfoList = () => {

        this.setState({ topProgressBar: true, secondDataTableIsLoading: true, errorMsgVisible: false })
        this.saSummaryService.getReligionWiseStudentInfo()
            .then(data => {
                if (data.item) {
                    if (data.item.length === 0) {
                        return this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                    }
                    data.item.map(item => {
                        item.islamInfo = `${item.totalIslam} (${item.totalIslamPercent}%)`;
                        item.hinduInfo = `${item.totalHindu} (${item.totalHinduPercent}%)`;
                        item.buddhistInfo = `${item.totalBouddha} (${item.totalBouddhaPercent}%)`;
                        item.cristianInfo = `${item.totalChristian} (${item.totalChristianPercent}%)`;
                        item.othersInfo = `${item.totalOtherReligions} (${item.totalOtherReligionsPercent}%)`;
                    })
                    this.setState({ religionWiseStudentList: data.item })
                    this.setState({ topProgressBar: false, secondDataTableIsLoading: false })
                } else {
                    // this.saSummaryService.Auth.handleErrorStatus(data)
                    //     .then((responseBody) => {
                            this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                        // });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
            );
    }

    buttonSelector(e) {
        if (e.value !== null) {
            this.setState({ dataTableSelection: e.value })
        }
    }

    toggleButton(e) {
        if (e.value === true) {
            this.getGenderWiseStudentInfoList();
        } else {
            this.getReligionWiseStudentInfoList();
        }

    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, genderWiseStudentList, religionWiseStudentList } = this.state;

        const options = [
            { label: 'Gender', value: true },
            { label: 'Religion', value: false },
        ];

        let tableHeaderGender = (
            <div className="p-clearfix">
                Institute Wise Gender Information{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {genderWiseStudentList.length}{" "}
                </span>
            </div>
        );

        let tableHeaderReligion = (
            <div className="p-clearfix">
                Institute Wise Religion Information{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {religionWiseStudentList.length}{" "}
                </span>
            </div>
        );

        return (

            <div className="p-grid">
                <Growl ref={(el) => { this.growl = el; }}></Growl>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-col-12">

                    <div className="p-col-12 p-xl-12">
                        <div className="dataTable-change">
                            <SelectButton
                                value={this.state.dataTableSelection}
                                options={options}
                                onChange={(e) => { this.buttonSelector(e); this.toggleButton(e) }}
                            />
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        {this.state.dataTableSelection ?
                            <div className="nw-data-table">
                                {this.state.firstDataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() :
                                    <DataTable
                                        header={tableHeaderGender}
                                        value={this.state.genderWiseStudentList}
                                        responsive={true}
                                        paginator={true}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rows={10}
                                    >
                                        <Column field="instituteId" header="Institute ID" filter={true} />
                                        <Column field="instituteName" header="Institute Name" filter={true} />
                                        <Column field="totalStudents" header="Total Student" filter={true} />
                                        <Column field="maleInfo" header="Male" filter={true} />
                                        <Column field="femaleInfo" header="Female" filter={true} />
                                        <Column field="otherInfo" header="Others" filter={true} />
                                    </DataTable>
                                }

                            </div>
                            :

                            <div className="nw-data-table">
                                {this.state.secondDataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() :
                                    <DataTable
                                        header={tableHeaderReligion}
                                        value={this.state.religionWiseStudentList}
                                        responsive={true}
                                        paginator={true}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rows={10}
                                    >
                                        <Column field="instituteId" header="Institute ID" filter={true} />
                                        <Column field="instituteName" header="Institute Name" filter={true} />
                                        <Column field="totalStudents" header="Total Student" filter={true} />
                                        <Column field="islamInfo" header="Islam" filter={true} />
                                        <Column field="hinduInfo" header="Hinduism" filter={true} />
                                        <Column field="buddhistInfo" header="Buddhism" filter={true} />
                                        <Column field="cristianInfo" header="Christianity" filter={true} />
                                        <Column field="othersInfo" header="Others" filter={true} />
                                    </DataTable>
                                }
                            </div>
                        }

                    </div>

                </div>
            </div>
        );
    }
}