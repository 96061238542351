import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { TreeTable } from 'primereact/treetable';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import NetiContentLoader from '../../../common/NetiContentLoader';
import { PartnerPointService } from '../../../../service/partnerPoint/PartnerPointService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { Calendar } from 'primereact/calendar';
import { UserCategoryService } from "../../../../service/UserCategoryService";

let assignedPartnerListArr = [];
let firstSixMonth = { display: 'table-cell' };
let lastSixMonth = { display: 'table-cell' };
let maxDate = new Date();
let profile;
export class PartnerYearWiseIncomeSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productType: '',
            year: '',
            duration: '',
            calenderYearList: [],
            tableView: false,
            errors: {},
            onExpander: true,
            searchObj: {
                netiID: ''
            },
            assignedPartnerList: [],
            productTypeErr: {},
            productType: '',
            dataTableIsLoading: false,
            mbpListObj: {},
            zbpListObj: {},
            bdpListObj: {},
            assignListObj: {},
            assignListArr: [],
            expandedKeys: {},
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            loading: false
        }

        this.partnerPointService = new PartnerPointService;
        this.onExpand = this.onExpand.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.commonFuctionality = new CommonFuctionality();
        this.userCategoryService = new UserCategoryService();
    }

    componentDidMount = () => {
        this.getCalenderYearListByTypeID();
    }

    getCalenderYearListByTypeID = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false, firstDropdownIsLoading: true });
        this.userCategoryService.fetchListByDefaultCode("T107")
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ calenderYearList: body });
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgVisible: true, errorMsgBody: responseBody, firstDropdownIsLoading: false })
                        });
                }
            }).catch(error => {
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false, errorMsgBody: 'Unable to load data. Please check connection' })

            });
    }


    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onSearchHandler = () => {
        let { assignListObj, searchObj } = this.state;
        assignedPartnerListArr = []
        this.searchPartnerMonthWiseIndividualIncome(null, assignListObj, false)
    }

    searchPartnerMonthWiseIndividualIncome(lazyNode, objectName, isLeaf) {
        let { searchObj } = this.state;
        profile = JSON.parse(localStorage.getItem('Profile'));

        searchObj.netiID = (lazyNode != null ? lazyNode.data.netiID : profile.netiID)
        searchObj.year = this.state.year
        this.setState({ searchObj });

        if (this.searchErrorHandler()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, tableView: true, });
            this.partnerPointService.findPartnerMonthWiseIndividualIncome(searchObj, this.state.duration)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            if (lazyNode && lazyNode.children.length === 0) {
                                body.map((item, i) => {
                                    objectName.data = item;
                                    objectName.children = [];
                                    objectName.leaf = isLeaf
                                    objectName.key = `${lazyNode['key']}-${i}`
                                    lazyNode.children.push(Object.assign({}, objectName))
                                })
                                
                            }
                            else if (assignedPartnerListArr.length === 0) {
                                body.map((item, i) => {
                                    if (item.designation === "BEP") {
                                        this.setState({ onExpander: false });
                                    }
                                    objectName.data = item;
                                    objectName.children = []
                                    objectName.leaf = isLeaf
                                    objectName.key = i
                                    assignedPartnerListArr.push(Object.assign({}, objectName))
                                })
                                if (this.state.duration == "1to6") {
                                    firstSixMonth = { display: 'table-cell' }
                                } else {
                                    firstSixMonth = { display: 'none' }
                                }

                                if (this.state.duration == "7to12") {
                                    lastSixMonth = { display: 'table-cell' }
                                } else {
                                    lastSixMonth = { display: 'none' }
                                }
                            }

                            this.setState({ dataTableIsLoading: false, tableView: true, errorMsgVisible: false, topProgressBar: false });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection.' });
                });

            setTimeout(() => {
                this.commonFuctionality.setCustomizedTreeDatatable('pType', assignedPartnerListArr && assignedPartnerListArr[0] && assignedPartnerListArr[0].data && assignedPartnerListArr[0].data.designation);
            }, 500);
        }

    }

    searchErrorHandler = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.year === '') {
            formIsValid = false;
            errors["year"] = "Year can't left empty.";
        }
        if (this.state.duration === '') {
            formIsValid = false;
            errors["duration"] = "Duration can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }


    onExpand(event) {
        let { mbpListObj, zbpListObj, bdpListObj } = this.state;

        let lazyNode = { ...event.node };
            if (lazyNode.data.designation === "MBP") {
                this.searchPartnerMonthWiseIndividualIncome(lazyNode, mbpListObj, false)
            }
            else if (lazyNode.data.designation === "ZBP") {
                this.searchPartnerMonthWiseIndividualIncome(lazyNode, zbpListObj, false)
            }
            else if (lazyNode.data.designation === "BDP") {
                this.searchPartnerMonthWiseIndividualIncome(lazyNode, bdpListObj, true)
            }
    }


    onExpandToggle = (e) => {
        this.setState({ expandedKeys: e.value })
    }

    onChangeStatus = (rowData) => {
        return (<span>{rowData.data["partnerStatus"] === 1 ? "Active" : "Inactive"}</span>);
    }

    onChangeDuration = e => {
        this.setState({ duration: e.target.value });
        this.clearDateError(e.target.name);
    }

    amountBodyTemplate = (rowData) => {
        console.log('rowData', rowData);

        //    return <div className="col-number text-right">{rowData && rowData.data.amount}</div>
    }

    onChangeYear = (e) => {
        this.setState({ year: e.target.value });
        this.clearDateError(e.target.name);

    }

    render() {
        console.log('assignedPartnerListArr', assignedPartnerListArr);
        
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let header = <div className="header-title">
        <div className="header-title-left">
        Year Wise Partner Income
        </div>
        <div className="header-title-right">
            <a>
                Total Found: {assignedPartnerListArr.length.toLocaleString("EN-IN")}
            </a>
        </div>
    </div>;
        let duration = [
            { label: 'First 6 months', value: '1to6' },
            { label: 'Last 6 months', value: '7to12' },
        ]


        let calenderYear = [];
        if ((this.state.calenderYearList != null) && (this.state.calenderYearList.length > 0)) {
            calenderYear = this.state.calenderYearList.map((item) => ({
                value: item.categoryName,
                label: item.categoryName,
            }));
        }


        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Year <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Year"
                                            value={this.state.year}
                                            options={calenderYear}
                                            onChange={this.onChangeYear}
                                            showClear={false}
                                            autoWidth={false}
                                            name="year"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["year"]}</span>
                                </div>

                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Column <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Column"
                                            value={this.state.duration}
                                            options={duration}
                                            onChange={this.onChangeDuration}
                                            showClear={false}
                                            autoWidth={false}
                                            name="duration"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["duration"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-2 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSearchHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-12 p-xl-12 p-col-nogutter nw-data-table">
                            {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <TreeTable
                                            header={header}
                                            lazy={true}
                                            value={assignedPartnerListArr}
                                            onExpand={this.onExpand}
                                            expandedKeys={this.state.expandedKeys}
                                            onToggle={this.onExpandToggle}
                                            style={{ marginTop: '.5em' }}>
                                            <Column field="designation" header="Partner Type" className='pType' expander={this.state.onExpander} />
                                            <Column field="area" header="Area" />
                                            <Column field="customNetiID" header="Neti ID" />
                                            <Column field="fullName" header="Name" />
                                            <Column field="janIncome" header="January" style={firstSixMonth} className="col-number text-right" />
                                            <Column field="febIncome" header="February" style={firstSixMonth} className="col-number text-right" />
                                            <Column field="marIncome" header="March" style={firstSixMonth} className="col-number text-right" />
                                            <Column field="aprIncome" header="April" style={firstSixMonth} className="col-number text-right" />
                                            <Column field="mayIncome" header="May" style={firstSixMonth} className="col-number text-right" />
                                            <Column field="junIncome" header="June" style={firstSixMonth} className="col-number text-right" />
                                            <Column field="julIncome" header="July" style={lastSixMonth} className="col-number text-right" />
                                            <Column field="augIncome" header="August" style={lastSixMonth} className="col-number text-right" />
                                            <Column field="sepIncome" header="September" style={lastSixMonth} className="col-number text-right" />
                                            <Column field="octIncome" header="October" style={lastSixMonth} className="col-number text-right" />
                                            <Column field="novIncome" header="November" style={lastSixMonth} className="col-number text-right" />
                                            <Column field="decIncome" header="December" style={lastSixMonth} className="col-number text-right" />
                                        </TreeTable>
                                    :
                                    <BlankDataTableAnim />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}