import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { SaCommonService } from '../../../service/saPoint/SaCommonService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim';

let maxDate = new Date();
export class InstitueSmsSummary extends Component {

    constructor() {
        super();

        this.state = {
            errors: {},
            fromDate: null,
            toDate: null,
            instituteWiseSMSBalanceSummaryList: [],

            dataTableIsLoading: false,
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: ""

        }
        this.saCommonService = new SaCommonService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    onChangeFromDate = (e) => {
        let { errors, fromDate } = this.state;
        errors["fromDate"] = '';
        fromDate = e.target.value
        this.setState({ fromDate, errors });

    }

    onChangeToDate = (e) => {
        let { errors, toDate } = this.state;
        errors["toDate"] = '';
        toDate = e.target.value
        this.setState({ toDate, errors });
    }

    onSubmitHandler = (event, props) => {
        if (this.searchHandleError()) {
            if (Date.parse(this.state.toDate) > Date.parse(this.state.fromDate)) {
                let startDate = NetiDateUtils.getDateFromStringDDMMYYYY(this.state.fromDate);
                let endDate = NetiDateUtils.getDateFromStringDDMMYYYY(this.state.toDate)
                this.setState({ topProgressBar: true, dataTableIsLoading: true, tableView: false, errorMsgVisible: false })
                this.saCommonService.getDateWiseSmsBalance(startDate, endDate)
                    .then(data => {
                        if (data) {
                            if (data.item) {
                                this.setState({ instituteWiseSMSBalanceSummaryList: data.item })
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, tableView: true })
                            }
                            else {
                                this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found.' })

                            }
                        } else {
                            this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Opps! Something went wrong. Data is unavailable.' })

                        }

                    }).catch(error => {
                        this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load datatable. Please check connection' })
                    });

            } else {
                this.growl.show({ severity: 'warn', summary: 'Warn', detail: "From Date can't be greater than To Date." });
                this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false })
            }
        }
    }

    searchHandleError = () => {
        let { errors } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (this.state.fromDate === null) {
            formIsValid = false;
            errors["fromDate"] = "Start Date can't left empty.";
        }
        else if (!Date.parse(this.state.fromDate)) {
            formIsValid = false;
            errors["fromDate"] = "Invalid Date format ";
        }
        if (this.state.toDate === null) {
            formIsValid = false;
            errors["toDate"] = "End Date can't left empty.";
        }
        else if (!Date.parse(this.state.toDate)) {
            formIsValid = false;
            errors["toDate"] = "Invalid Date format";
        }
        this.setState({ errors });
        return formIsValid;
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let { instituteWiseSMSBalanceSummaryList } = this.state;


        let headervalue = (
            <div className="p-clearfix">
                Institute SMS Summary{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {instituteWiseSMSBalanceSummaryList.length}{" "}
                </span>
            </div>
        );

        // if(this.state.instituteWiseSMSBalanceSummaryList){
        //     instituteWiseSMSBalanceSummaryInfo = this.state.instituteWiseSMSBalanceSummaryList.item 
        // }

        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>
        return (
            <div className="p-fluid">
                {topProgressBar ? (
                    <ErrorMessageView topProgressBar={topProgressBar} />
                ) : null}
                {errorMsgVisible ? (
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                ) : null}
                <Growl ref={(el) => this.growl = el} />

                <div className="nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            {/* <div className="p-col-12">
                                {!this.state.fDateErrorMessage ? null :
                                    <div className="p-messages p-component p-messages-warn" style={{ margin: '0 0 1em 0', display: 'block' }}>
                                        <div className="p-messages-wrapper">
                                            <ul>
                                                <li>
                                                    <span className="p-messages-icon pi pi-fw pi-2x pi-exclamation-triangle"></span>
                                                    <span className="p-messages-detail">
                                                        Please Select From Date
                                                </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                }

                                {!this.state.tDateErrorMessage ? null :
                                    <div className="p-messages p-component p-messages-warn" style={{ margin: '0 0 1em 0', display: 'block' }}>
                                        <div className="p-messages-wrapper">
                                            <ul>
                                                <li>
                                                    <span className="p-messages-icon pi pi-fw pi-2x pi-exclamation-triangle"></span>
                                                    <span className="p-messages-detail">
                                                        Please Select To Date
                                                </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div> */}

                            <div className="p-col-12 p-lg-6 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Start Date {requiredSing}</span>
                                    <Calendar
                                        value={this.state.fromDate}
                                        onChange={this.onChangeFromDate}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        name="fromDate"
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                        showButtonBar={true} 

                                    />
                                </div>
                                <span className="error-message">{this.state.errors["fromDate"]}</span>
                            </div>

                            <div className="p-col-12 p-lg-6 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">End Date {requiredSing}</span>
                                    <Calendar
                                        value={this.state.toDate}
                                        onChange={this.onChangeToDate}
                                        showIcon={true}
                                        name="toDate"
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                        showButtonBar={true} 

                                    />
                                </div>
                                <span className="error-message">{this.state.errors["toDate"]}</span>
                            </div>



                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                <Button
                                    label="Search"
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitHandler.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.tableView === true ?
                                this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() :
                                    <DataTable
                                        header={headervalue}
                                        value={instituteWiseSMSBalanceSummaryList}
                                        responsive={true}
                                        paginator={true}
                                        rows={5}
                                    >
                                        <Column field="instituteId" header="Institute ID" sortable={true} filter={true} />
                                        <Column field="instituteName" header="Institute Name" sortable={true} filter={true} />
                                        <Column field="totalSms" header="SMS Quantity" sortable={true} filter={true} />

                                    </DataTable>
                                :
                                <BlankDataTableAnim />
                            }

                        </div>
                    </div>

                </div>

            </div>
        );
    }
}