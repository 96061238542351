import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import Formatter from '../../../utils/Formatter';
import { TreeTable } from 'primereact/treetable';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

let partnerListArr = [];
let sumOfInstitute = 0;
let totalActiveInstitute = 0;
let totalInactiveInstitute = 0;
let totalActiveInstitutePercentage = 0;
let totalInactiveInstitutePercentage = 0;
let totalActiveRate = 0;
let totalInactiveRate = 0;
let totalActiveDue = 0;
let totalInactiveDue = 0;
let status = { display: 'none' };


let mbpListObj = {};
let zbpListObj = {};
let bdpListObj = {};
let bepListObj = {};

let maxDate = new Date();
export class MpAreaWiseAttendanceTreeView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewAtaGlance: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,
            searchObj: {
                startDate: "",
                endDate: "",
                netiID: ""
            },
            errors: {},
            takenVisible: false,
            notTakenVisible: false,
        }

        this.ManagementPointService = new ManagementPointService;
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
        this.Formatter = new Formatter();
        this.CommonFuctionality = new CommonFuctionality();
    }

    // componentDidMount() {
    //     this.fetchMBPInfo();

    //     // this.setCustomizedDatatable();
        
    // }

    fetchMBPInfo = () => {
        sumOfInstitute = 0;
        totalActiveInstitute = 0;
        totalInactiveInstitute = 0;
        totalActiveRate = 0;
        totalInactiveRate = 0;
        totalActiveDue = 0;
        totalInactiveDue = 0;

        partnerListArr = [];
        mbpListObj = {}
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.ManagementPointService.fetchMbpInfoList() /*this.state.rowDataObj.partnerAssignID*/
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("mbp list", body);
                        body.map((item, i) => {
                            mbpListObj.data = item;
                            mbpListObj.key = i;
                            mbpListObj.children = [];
                            mbpListObj.leaf = false;
                            partnerListArr.push(Object.assign({}, mbpListObj));
                        });

                    

                        // sumOfInstitute = body.map(item => item.totalInstitute).reduce((a, b) => (a + b));
                        // totalActiveInstitute = body.map(item => item.activeInstitute).reduce((a, b) => (a + b));
                        // totalInactiveInstitute = body.map(item => item.inActiveInstitute).reduce((a, b) => (a + b));
                        // totalActiveRate = body.map(item => item.activeRate).reduce((a, b) => (a + b));
                        // totalInactiveRate = body.map(item => item.inActiveRate).reduce((a, b) => (a + b));
                        // totalActiveDue = body.map(item => item.activePayble).reduce((a, b) => (a + b));
                        // totalInactiveDue = body.map(item => item.inActivePayble).reduce((a, b) => (a + b));


                        this.setState({ topProgressBar: false, dataTableIsLoading: false });

                    });
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection!!!' })

            );
    }





    // paidDetailsBody(rowData, column) {
    //     console.log("rowData",rowData);
    //     return <div className='text-center'>
    //         <Link to={{
    //             pathname: MANAGEMENT_POINT.INSTITUTE_DUE_STATEMENT,
    //             searchObj: { ...rowData }
    //         }}>
    //             <i class="fas fa-arrow-circle-right fa-2x"></i>
    //         </Link>
    //     </div>
    // }

    activeInstitute = (rowData) => {
        if( rowData.data.partnerType == "BEP" ){
            return <a
                        onClick={(e) => this.takenViewDialog(rowData)}
                    >
                <u>{rowData.data.activeInstitute}</u>
            </a>
        }
        else{
            return <span >
                <span className="text-success">{rowData.data.activeInstitute}</span> <br />
            </span>;
        }
    }

    inActiveInstitute = (rowData) => {
        if( rowData.data.partnerType == "BEP" ){
            return <a
                        onClick={(e) => this.notTakenViewDialog(rowData)}
                    >
                <u>{rowData.data.inActiveInstitute}</u>
            </a>
        }
        else{
            return <span >
                <span className="text-danger">{rowData.data.inActiveInstitute}</span> <br />
            </span>;
        }
    }

    takenViewDialog = (rowData) => {
        this.setState({
            takenVisible: true,
        })
    }

    notTakenViewDialog = (rowData) => {
        this.setState({
            notTakenVisible: true,
        })
    }

    onHide = () =>{
        this.setState({
            takenVisible: false,
            notTakenVisible: false,
        })
    }


    mbpDueActiveInstituteBody(rowData) {

        return <div className="text-success col-number text-right" >

            <span className="text-success">{this.validatorUtility.currencyFormatter(rowData.data["activePayble"])}</span><br />

            <span className="text-danger">{this.validatorUtility.currencyFormatter(rowData.data["inActivePayble"])}</span>

        </div>;
    }

    partnerAreaInfo(rowData) {

        return <span>
            {/* <span style={{ color: "blue" }}>{rowData.data.partnerMobile}</span><br /> */}
            <span>{rowData.data.areaName}</span>
        </span>
    }

    partnerDetailInfo = (rowData) => {
        return <span>
            <span >{rowData.data.partnerName}</span><br />
            <span >{rowData.data.partnerNetiID}</span>
        </span>
    }

    // userBasicMobile = (rowData) => {
    //     console.log("rowData", rowData);
        
    //     return <span>
    //         {/* <span >{rowData.userBasicInfoDTO.basicMobile}</span> */}
    //         {/* <span >{rowData.data.partnerNetiID}</span> */}
    //     </span>
    // }

    sumOfActiveInactive = () => {
        return <span>
            <span className="text-success">{totalActiveInstitute + "[" + this.Formatter.getFixedDecimalNumber(this.totalActiveInstitutePercentageInfo()) + "%" + "]" + "[" + this.validatorUtility.currencyFormatter(totalActiveRate) + "]"}</span><br />

            <span className="text-danger">{totalInactiveInstitute + "[" + this.Formatter.getFixedDecimalNumber(this.totalInactiveInstitutePercentageInfo()) + "%" + "]" + "[" + this.validatorUtility.currencyFormatter(totalInactiveRate) + "]"}</span>

        </span>

    }

    sumOfActiveInactiveDue = () => {
        return <span>

            <span className="text-success">{this.validatorUtility.currencyFormatter(totalActiveDue)}</span><br />

            <span className="text-danger">{this.validatorUtility.currencyFormatter(totalInactiveDue)}</span>

        </span>

    }

    totalActiveInstitutePercentageInfo = () => {

        let totalActiveInstitutePercentage = (totalActiveInstitute / sumOfInstitute) * 100
        if (totalActiveInstitutePercentage) {
            return isNaN(totalActiveInstitutePercentage) ? 0 : totalActiveInstitutePercentage;
        }
    }

    totalInactiveInstitutePercentageInfo = () => {

        let totalInactiveInstitutePercentage = (totalInactiveInstitute / sumOfInstitute) * 100
        if (totalInactiveInstitutePercentage) {
            return isNaN(totalInactiveInstitutePercentage) ? 0 : totalInactiveInstitutePercentage;
        }
    }

    onExpand = (event) => {
        zbpListObj = {};
        bdpListObj = {};
        bepListObj = {};
        let lazyNode = { ...event.node };
        console.log('onexpand event',event);
        
        if (lazyNode.data.partnerDesignationCode === "T1120202") {
            if(lazyNode.children.length === 0) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.ManagementPointService.fetchPartnerInfoList('T1120203', lazyNode.data.partnerAssignID).then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        console.log('zbp list',body.instituteReportDTOs);

                        body.instituteReportDTOs.map((item, i) => {
                            zbpListObj.data = item;
                            zbpListObj.key = `${lazyNode['key']}-${i}`;
                            zbpListObj.children = [];
                            zbpListObj.leaf = false;
                            lazyNode.children.push(Object.assign({}, zbpListObj));
                        });

                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    })
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
            });
            }
        }

        else if (lazyNode.data.partnerDesignationCode === "T1120203") {
            if(lazyNode.children.length === 0) {

            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.ManagementPointService.fetchPartnerInfoList('T1120204', lazyNode.data.partnerAssignID).then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        console.log('bdp list',body);
                        body.instituteReportDTOs.map((item, i) => {
                            bdpListObj.data = item;
                            bdpListObj.key = `${lazyNode['key']}-${i}`;
                            bdpListObj.children = [];
                            bdpListObj.leaf = false;
                            lazyNode.children.push(Object.assign({}, bdpListObj));
                        });

                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    })
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
            });
            }
        }
        else if (lazyNode.data.partnerDesignationCode === "T1120204") {
            if(lazyNode.children.length === 0) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.ManagementPointService.fetchPartnerInfoList('T1120205', lazyNode.data.partnerAssignID).then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        console.log('bdp list',body);
                        body.instituteReportDTOs.map((item, i) => {
                            bepListObj.data = item;
                            bepListObj.key = `${lazyNode['key']}-${i}`;
                            bepListObj.children = [];
                            lazyNode.children.push(Object.assign({}, bepListObj));
                        });
                        status = { display: 'table-cell' }
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    })
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
            }
        }

        

        setTimeout(() => {
            this.CommonFuctionality.setCustomizedTreeDatatable('pType', null);
        }, 500);
        
    }

    onSearchPaidUnpaidInstitute = () =>{
        this.fetchMBPInfo();
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let header = <div className="header-title">
                        <div className="header-title-left">
                            Area Wise Attendance Report
                        </div>


                        <div className="header-title-right">

                            {/* <Link to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 0 }}>
                                More<i className="fas fa-angle-right" ></i>
                            </Link> */}
                        </div>
                    </div>;

        let takenHeader = <div className="header-title">
                        <div className="header-title-left">
                            Attendance Taken Institute List
                        </div>


                        <div className="header-title-right">

                            <Link to="#">
                                Total: 0
                            </Link>
                        </div>
                    </div>;

        let notTakenHeader = <div className="header-title">
                            <div className="header-title-left">
                                Attendance Not Taken Institute List
                            </div>


                            <div className="header-title-right">

                                <Link to="#">
                                    Total: 0
                                </Link>
                            </div>
                        </div>;
        
        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-lg-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.searchObj.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            name="startDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSearchPaidUnpaidInstitute}
                                    />
                                </div>

                                
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12 p-col-nogutter nw-data-table">
                            {
                                this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    :
                                    <TreeTable
                                        header={header}
                                        lazy={true}
                                        value={partnerListArr}
                                        onExpand={this.onExpand}
                                        expandedKeys={this.state.expandedKeys}
                                        onToggle={this.onExpandToggle}
                                        >
                                        <Column field="" header="Area" body={this.partnerAreaInfo.bind(this)} className='pType' expander />
                                        <Column field="partnerNetiID" header="Neti ID." />
                                        <Column field="partnerName" header="Name" />
                                        <Column field="totalInstitute" header="Total Active Institute" />
                                        <Column field="" header="Attendance Taken" body={this.activeInstitute}/>
                                        <Column field="" header="Attendance Not Taken" body={this.inActiveInstitute} />
                                        {/* <Column field="" header="Amount" body={this.mbpActiveInstituteBody.bind(this)} /> */}
                                    </TreeTable>
                            }

                        </div>
                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog" 
                        header="Attendance Taken" 
                        visible={this.state.takenVisible} 
                        onHide={this.onHide} 
                        closable
                    >
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table">
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={takenHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                >
                                    <Column field="" header="Institute ID." body={this.getDateFromTemplate} filter={true} />
                                    <Column field="" header="Institute Name" filter={true} />
                                    <Column field="" header="Device Found" filter={true} />
                                    <Column field="" header="Total Section" body={this.requestedAmount} sortable={true} filter={true} />
                                    <Column field="" header="Attendance Taken Section" body={this.statusBody} filter={true} />
                                    <Column field="" header="Attendance Not Taken Section" body={this.detailBody}  />
                                </DataTable>
                                
                            </div>
                        </div>

                    </Dialog>

                    <Dialog
                        className="nw-dialog" 
                        header="Attendance Not Taken" 
                        visible={this.state.notTakenVisible} 
                        onHide={this.onHide} 
                        closable
                    >
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table">
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={notTakenHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                >
                                    <Column field="" header="Institute ID." body={this.getDateFromTemplate} filter={true} />
                                    <Column field="" header="Institute Name" filter={true} />
                                    <Column field="" header="Total Section" body={this.requestedAmount} sortable={true} filter={true} />
                                </DataTable>
                            </div>
                        </div>

                    </Dialog>
                </div>
            </div>
        )
    }
}