import React, { Component } from "react";
import { BalanceService } from "../../../service/myPoint/BalanceService";
import { Growl } from "primereact/growl";
import NetiContentLoader from "../../common/NetiContentLoader";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
export class WalletBalance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      userWalletBalance: {},
      isLoading: false,
      walletColorClass: "",
    };

    this.balanceService = new BalanceService();
    this.showWarn = this.showWarn.bind(this);
    this.NetiContentLoader = new NetiContentLoader();
    this.validatorUtility = new ValidatorUtility();
  }

  componentDidMount() {
    this.fetchUserWalletBalance();
  }

  fetchUserWalletBalance() {
    this.setState({ isLoading: true, errorMsgVisible: false });
    this.balanceService
      .getUserWalletBalance()
      .then((res) => {
        if (res.status == 200) {
          return res.json().then((body) => {
            this.setState({ userWalletBalance: body });
            this.setState({ isLoading: false });
          });
        } else {
          this.balanceService.Auth.handleErrorStatus(res).then(
            (responseBody) => {
              this.setState({
                isLoading: false,
                errorMsgVisible: true,
                errorMsgBody: "Please check your connection !!!",
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection !!!",
        });
      });
  }

  showWarn(msg) {
    this.growl.show({ severity: "warn", summary: "Warning", detail: msg });
  }

  // componentWalletBalance=()=>

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

    let messageBalance = 0;
    let {
      walletBalance,
      voiceBalance,
      smsBalance,
      emailBalance,
      reserveBalance,
    } = this.state.userWalletBalance;
    messageBalance = voiceBalance + smsBalance + emailBalance;

    let userWalletBalanceStatus,
      userMessageBalanceStatus,
      userReserveBalanceStatus;

    if (isNaN(walletBalance)) {
      walletBalance = 0.0;
    }
    if (walletBalance <= 100) {
      userWalletBalanceStatus = "Please recharge your wallet";
    } else if (walletBalance > 100) {
      userWalletBalanceStatus = "Use to purchase different products";
    }

    if (isNaN(messageBalance)) {
      messageBalance = 0.0;
    }
    if (messageBalance <= 100) {
      userMessageBalanceStatus = "Please recharge your message balance";
    } else if (messageBalance > 100) {
      userMessageBalanceStatus = "Use to send message to others";
    }

    if (isNaN(reserveBalance)) {
      reserveBalance = 0.0;
    }
    if (reserveBalance <= 100) {
      userReserveBalanceStatus = "Please recharge balance";
    } else if (reserveBalance > 100) {
      userReserveBalanceStatus = "Use to purchase different products";
    }

    return (
      <div className="nw-wallet">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        {this.props.walletBalanceName === "Wallet" ? (
          this.state.isLoading ? (
            this.NetiContentLoader.WalletLoaderContent()
          ) : (
            <center
              style={{ maxWidth: "unset", width: "32rem" }}
              className="wallet-balance"
            >
              <h2>
                <i className="fas fa-wallet fa-3x" />
              </h2>
              <div className="nw-wallet-title">
                Your {this.props.walletBalanceName} Balance
              </div>

              <div className="nw-wallet-balance">
                <div>
                  <span>৳</span>{" "}
                  {this.validatorUtility.currencyFormatter(walletBalance)}
                </div>
              </div>
              <div className="balance-available">Available</div>
            </center>
          )
        ) : (
          ""
        )}

        {this.props.messageBalanceName === "Message" ? (
          this.state.isLoading ? (
            this.NetiContentLoader.WalletLoaderContent()
          ) : (
            <center className="message-balance">
              <h2>
                <i className="fas fa-comments-dollar fa-3x" />
              </h2>
              <div className="nw-wallet-title">
                Your {this.props.messageBalanceName} Balance
              </div>

              <div className="nw-wallet-balance">
                <div>
                  {this.validatorUtility.messageFormatter(messageBalance)}
                </div>
              </div>
              <div className="balance-available">Available</div>
            </center>
          )
        ) : (
          ""
        )}
        {this.props.reserveBalanceName === "Reserve" ? (
          this.state.isLoading ? (
            this.NetiContentLoader.WalletLoaderContent()
          ) : (
            <center className="reverse-balance">
              <h2>
                <i className="fa fa-money fa-3x" />
              </h2>
              <div className="nw-wallet-title">
                Your {this.props.reserveBalanceName} Balance
              </div>

              <div className="nw-wallet-balance">
                {/* <div>&#2547;  { parseFloat( reserveBalance ).toLocaleString({maximumFractionDigits:2})} </div>  */}

                {/* <div>&#2547; {this.validatorUtility.isValidCurrency( reserveBalance )}</div> */}
                <div>
                  &#2547;{" "}
                  {this.validatorUtility.currencyFormatter(reserveBalance)}
                </div>
              </div>
              <div className="balance-available">Available</div>
            </center>
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}
