import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { PurchaseService } from '../../../../service/myPoint/PurchaseService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import { pdfDownloadLandscape_TableWithSubTitle } from '../../../common/JsPdfDownload';

let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
let maxDate = new Date();
export class PurchaseLogOfferProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offerProductPurchaseList: [],
            fromDate: '',
            toDate: '',
            dataTableSelection: null,
            dataViewValue: [],
            visible: false,
            errors: {},
            tableView: false,
            purchesPoint: null,
            productType: null,
            productName: null,
            productQuantity: null,
            purchaseProductId: null,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableValue: [],
            dataTableIsLoading: false,
            dialogDataTableIsLoading: false,
            tableView: false
        };

        this.purchaseCodeBody = this.purchaseCodeBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);
        this.purchaseService = new PurchaseService();
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
    }

    viewDialog(rowData) {
        this.setState({ topProgressBar: true, dialogDataTableIsLoading: true, errorMsgVisible: false })
        this.setState({ offerProductPurchaseList: [] });
        this.setState({ purchesPoint: rowData.purchasePoint })
        this.setState({ productType: rowData.productType })
        this.setState({ productName: rowData.productName })
        this.setState({ productQuantity: rowData.productQuantity })
        this.setState({ purchaseDate: rowData.offerUseDate })
        this.setState({ purchaseProductId: rowData.purchaseProductId })
        this.setState({ visible: true });


        this.purchaseService.fetchProductPurchaseCodeDetailsByPurchaseId(rowData.productPurchaseLogID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        this.setState({ offerProductPurchaseList: body, topProgressBar: false, dialogDataTableIsLoading: false });
                    });
                }
                else {
                    this.purchaseService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dialogDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dialogDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            })

    }

    usedDateBody = (rowData) => {
        return (
            <span>
                {rowData.usedDate != null ? NetiDateUtils.getAnyShortForm(rowData.usedDate, 'DD-MMM-YYYY') : ''}

            </span>
        );
    }

    statusCheck = (rowData) => {
        return (
            <span>
                {rowData.usedStatus == 0 ? "Unused" : ''}
                {rowData.usedStatus == 1 ? "Used" : ''}
            </span>
        )
    }

    onHide(event) {
        this.setState({ visible: false });
    }

    purchaseCodeBody(rowData) {
        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title="View Purchase"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    getFormatedDate = (rowData) => {

        return <span>{NetiDateUtils.getAnyShortForm(rowData['offerUseDate'], 'DD-MMM-YYYY')}  &nbsp; {NetiDateUtils.getHHMMSSFForHome(rowData['offerUseDate'])}</span>;

    }

    onDateRangeSubmitHandler = () => {
        this.setState({ dataTableValue: [] });
        let dateRangeParamFormat = {
            "startDate": NetiDateUtils.getDateFromString(this.state.fromDate),
            "endDate": NetiDateUtils.getDateFromString(this.state.toDate),
            "pageLimit": 10,
            "pageNo": 0
        }
        if (this.searchHandleError()) {
            this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(this.state.toDate) >= Date.parse(this.state.fromDate)) {
                this.purchaseService.fetchOfferProductListByDateRange(dateRangeParamFormat)
                    .then(res => {
                        if (res.status == 302) {
                            return res.json().then((body) => {
                                if (body.length == 0) {
                                    return this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found.' })
                                }
                                for (let i = 0; i < body.length; i++) {
                                    if (body[i].offerUseDate == null) {
                                        body[i].offerUseDate = '';
                                    } else {
                                        body[i].offerUseDate = NetiDateUtils.getAnyShortForm(body[i].offerUseDate, 'DD-MMM-YYYY hh:mm:ss a');
                                        body[i].purchaseInvoiceID = body[i].purchaseInvoiceID.toString();
                                    }
                                }
                                this.setState({ dataTableValue: body, tableView: true, topProgressBar: false, dataTableIsLoading: false });
                            });
                        }
                        else {
                            this.purchaseService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                    })
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be greater than To Date." });
                this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
            }
        }

    }

    onFromDate = (e) => {
        this.setState({ fromDate: e.value })
        this.clearDateError(e.target.name)

    }

    onToDate = (e) => {
        this.setState({ toDate: e.value })
        this.clearDateError(e.target.name)
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    searchHandleError = () => {
        let { errors } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (this.state.fromDate === '') {
            formIsValid = false;
            errors["fromDate"] = "Start Date can't left empty.";
        }
        if (this.state.toDate === '') {
            formIsValid = false;
            errors["toDate"] = "End Date can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;
    }

    payableAmountBody = (rowData) => {
        return (<b> {this.validatorUtility.currencyFormatter(rowData.payableAmount)}</b>)

    }

    discountBody = (rowData) => {
        return `${rowData.discountPercent}%`;
    }

    exportPdf = () => {

    

        let tableColumns = [
          { title: "Date", dataKey: "offerUseDate" },
          { title: "Product Type", dataKey: "productType" },
          { title: "Product Name", dataKey: "productName" },
          { title: "Invoice ID", dataKey: "purchaseInvoiceID" },
          { title: "Offer Code", dataKey: "offerCode" },
          { title: "Product Quantity", dataKey: "productQuantity" },
          { title: "Total Amount", dataKey: "totalPrice" },
          { title: "Discount Amount", dataKey: "discountPercent" },
          { title: "Payable Amount", dataKey: "payableAmount" },
          { title: "Purchase Code", dataKey: "productOfferLogID" },
        ];

        let tableColumnStyles = {
            totalPrice: { halign: "right"},
            discountPercent: { halign: "right"},
            payableAmount: { halign: "right"},
        }

        pdfDownloadLandscape_TableWithSubTitle("Purchase Log Offer Product",110, pdfTitleHeader, pdfTitleHeaderData, tableColumns, this.state.dataTableValue,tableColumnStyles, "Purchase_Log_Offer_Product.pdf");

    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue } = this.state;
        let tableHeader = <div className="header-title">
                    <div className="header-title-left">
                        Offer Product Purchase List
                    </div>


                    <div className="header-title-right">
                        <a>
                        Total Found: {dataTableValue.length.toLocaleString("EN-IN")}
                        </a>
                    </div>
                    </div>;

        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        pdfTitleHeaderData = [
        { A: "From Date", B: NetiDateUtils.getAnyShortForm(this.state.fromDate, 'DD-MMM-YYYY'), C: "To Date", D: NetiDateUtils.getAnyShortForm(this.state.toDate, 'DD-MMM-YYYY')}
        ];

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        name="fromDate"
                                        value={this.state.fromDate}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        onChange={this.onFromDate}
                                        dateFormat="dd/mm/yy"
                                        showIcon={true}
                                        placeholder="Select Date"
                                    >

                                    </Calendar>
                                </div>
                                <span className="error-message">{this.state.errors["fromDate"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        name="toDate"
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        value={this.state.toDate}
                                        onChange={this.onToDate}
                                        dateFormat="dd/mm/yy"
                                        showIcon={true}
                                        placeholder="Select Date"
                                    >
                                    </Calendar>
                                </div>
                                <span className="error-message">{this.state.errors["toDate"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-2 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onDateRangeSubmitHandler}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.tableView === true ?
                                this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    :
                                    <div>
                                        <DataTable
                                            header={tableHeader}
                                            value={this.state.dataTableValue}
                                            selectionMode="single"
                                            selection={this.state.dataTableSelection}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            // paginator={true} 
                                            rows={10}
                                        >
                                            <Column field="offerUseDate" header="Date" sortable={true} filter={true} className="table-datetime" />
                                            <Column field="productType" header="Product Type" sortable={true} filter={true} />
                                            <Column field="productName" header="Product Name" sortable={true} filter={true} />
                                            <Column field="purchaseInvoiceID" header="Invoice ID" sortable={true} filter={true} />
                                            <Column field="offerCode" header="Offer Code" sortable={true} filter={true} />
                                            <Column field="productQuantity" header="Product Quantity" sortable={true} filter={true} />
                                            <Column field="totalPrice" className="text-right" header="Total Amount" sortable={true} filter={true} />
                                            <Column field="discountPercent" className="text-right" header="Discount Percentage" sortable={true} filter={true} body={this.discountBody}/>
                                            <Column field="payableAmount" className="text-right" header="Payable Amount" body={this.payableAmountBody} sortable={true} filter={true} />
                                            <Column field="productOfferLogID" header="Details" body={this.purchaseCodeBody} sortable={true} style={{width:'95px'}}/>
                                        </DataTable>
                                        <div className="nw-button-parent m-t-8 p-r-0">
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Download"
                                                icon="fas fa-download"
                                                onClick={this.exportPdf}
                                                />
                                        </div>
                                    </div>
                                :

                                <BlankDataTableAnim />
                            }
                        </div>



                        <Dialog className="nw-dialog" header="Offer Product Purchase Code Info" visible={this.state.visible} onHide={this.onHide} maximizable>

                            <div className="p-fluid">
                                <div className="p-grid">

                                    <div className="p-col-6 p-xl-6">
                                        <span>Purchase Point : {this.state.purchesPoint}</span><br />
                                        <span>Product Type :  {this.state.productType}</span><br />
                                        <span>Product  Name : {this.state.productName} </span><br />
                                    </div>
                                    <div className="p-col-6 p-xl-6">
                                        <span>Date : {this.state.purchaseDate}</span><br />
                                        <span>Quantity: {this.state.productQuantity} </span><br />
                                    </div>

                                    {/* <OfferProductPurchaseCodeInfoTable getPurchaseProductId={this.state.purchaseProductId} /> */}

                                    <div className="p-col-12 p-xl-12 nw-data-table">
                                        {this.state.dialogDataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            :

                                            <DataTable
                                                header={'Offer Product Purchase Code Info'}
                                                value={this.state.offerProductPurchaseList}
                                                selectionMode="single"
                                                selection={this.state.dataTableSelection}
                                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                responsive={true}
                                                paginator={true}
                                                rowsPerPageOptions={[20, 50]}
                                                rows={10}
                                            >
                                                <Column field="purchaseCode" header="Purchase Code" sortable={true} filter={true} />
                                                <Column field="usedStatus" header="Status" body={this.statusCheck} sortable={true} filter={true} />
                                                <Column field="usedDate" header="Used Date" body={this.usedDateBody} sortable={true} filter={true} />
                                            </DataTable>

                                        }

                                    </div>


                                </div>
                            </div>

                        </Dialog>

                    </div>

                </div>

            </div>
        );
    }
}