import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { ProfileService } from '../../../service/profile/ProfileService';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';

let errors = {};
export class FixedRevenue extends Component {

    constructor() {
        super();
        this.state = {
            updateFixedRevenueErr: {},
            productTypeList: '',
            productType: '',
            productList: '',
            productName: '',
            netiId: '',
            userDetails: '',
            percentage: '',
            note: '',
            visible: false,
            dataTableValue: [],

            selectedRow: {},
            errors: {},

            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            searchIsLoading: false,
            searchView: false,
            searchViewError: false,
            buttonDisabled: true,
        }

        this.onChangeProductType = this.onChangeProductType.bind(this);
        this.onChangeProductName = this.onChangeProductName.bind(this);
        this.onChangeNetiId = this.onChangeNetiId.bind(this);
        this.submitSearch = this.submitSearch.bind(this);

        this.onChangePercentage = this.onChangePercentage.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.submitSave = this.submitSave.bind(this);

        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);

        this.onChangeUpdatePercentage = this.onChangeUpdatePercentage.bind(this);
        this.onChangeUpdateNote = this.onChangeUpdateNote.bind(this);

        this.submitUpdate = this.submitUpdate.bind(this);
        this.fetchProductTypeList = this.fetchProductTypeList.bind(this);
        this.fetchFixedRevenueList = this.fetchFixedRevenueList.bind(this)
        this.fetchProductList = this.fetchProductList.bind(this);

        this.userCategoryService = new UserCategoryService();
        this.adminPointService = new AdminPointService();
        this.profileService = new ProfileService();
        this.NetiContentLoader = new NetiContentLoader();

    }

    componentWillMount() {
        this.fetchProductTypeList('T104');
        this.fetchFixedRevenueList();
    }

    onChangeProductType(value) {
        errors["productType"] = "";
        this.setState({ productType: value,errors:errors });
        this.fetchProductList(1, value);
    }
    onChangeProductName(value) {
        errors["productName"] = "";
        this.setState({ productName: value, errors: errors });
    }
    onChangeNetiId(value) {
        this.setState({ netiId: value });
    }



    submitSearch() {

        if (this.onSearchCustomNetiIDErrorHandle()) {
            this.setState({ topProgressBar: true, searchIsLoading: true, errorMsgVisible: false, searchView: true, searchViewError: false });
            this.profileService.findBasicInfoByCustomNetiID(this.state.netiId)
                .then((res) => {
                    if (res.status == 302) {
                        errors["netiId"] = "";
                        return res.json().then((body) => {
                            this.setState({ userDetails: body, topProgressBar: false, searchIsLoading: false, searchViewError: false, buttonDisabled: false })

                        });
                    } else {
                        this.profileService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                errors["Error"] = resp
                                this.setState({ errors, topProgressBar: false, searchViewError: true, searchIsLoading: false, buttonDisabled: true })

                            });
                    }
                }).catch((error) => {
                    this.setState({ searchViewError: false, searchView: false, topProgressBar: false, searchIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }

    }

    onSearchCustomNetiIDErrorHandle = () => {
        let { errors, netiId } = this.state;
        let formIsValid = true;
        this.setState({ searchView: false })

        if (!netiId) {
            formIsValid = false;
            errors["netiId"] = 'Neti ID can\'t left empty'
        }

        if (netiId.length < 10) {
            formIsValid = false;
            errors["netiId"] = "Neti ID is too sort";
        } 
        if (netiId == '' && netiId.length < 10) {
            formIsValid = false
            errors["netiId"] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10';
        }

        this.setState({ errors })

        return formIsValid;
    }

    onChangePercentage(value) {
        errors["percentage"] = "";
        this.setState({ percentage: value, errors: errors });
    }
    onChangeNote(value) {
        errors["note"] = "";
        this.setState({ note: value, errors: errors });
    }

    submitSave() {

        if (this.handleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            let requestedObj = {
                "fixedNote": this.state.note,
                "configPercentage": this.state.percentage,
                "productInfoDTO": {
                    "productID": this.state.productName,
                },
                "userBasicInfoDTO": {
                    "netiID": this.state.userDetails.netiID,
                }
            }
            this.adminPointService.productFixedRevenueConfig(requestedObj)
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data saved successfully.' });
                        this.setState({note:'', percentage:'',productName:'',});
                        this.fetchFixedRevenueList();
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }



    handleError() {

        let formIsValid = true;

        if (this.state.productType === '') {
            formIsValid = false;
            errors["productType"] = "Product Type can't left empty.";
        }
        if (this.state.productName === '') {
            formIsValid = false;
            errors["productName"] = "Product Name can't left empty.";
        }
        if (this.state.netiId === '') {
            formIsValid = false;
            errors["netiId"] = "Neti ID can't left empty.";
        }
        if (this.state.percentage === '') {
            formIsValid = false;
            errors["percentage"] = "Percentage can't left empty.";
        }
        if (this.state.note === '') {
            formIsValid = false;
            errors["note"] = "Note can't left empty.";
        }
        else {
            return formIsValid;
        }
        this.setState({ errors: errors });
    }

    fetchFixedRevenueList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchListOfProductFixedRevenue()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchProductTypeList(defaultCode) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })

        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productTypeList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    })
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }


    fetchProductList(enableStatus, productTypeId) {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        let productTypeInfoDTO = {
            "coreCategoryID": productTypeId,
        }

        this.adminPointService.fetchProductListByProductType(enableStatus, productTypeInfoDTO)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productList: body, topProgressBar: false, secondDropdownIsLoading: false });

                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    viewDialog(rowData) {
        this.setState({ selectedRow: rowData, visible: true });
    }

    onHide() {
        this.setState({ visible: false });
    }

    onChangeUpdatePercentage(value) {
        let { selectedRow,updateFixedRevenueErr } = this.state;
        updateFixedRevenueErr["updatePercentage"] = "";
        selectedRow.configPercentage = value;
        this.setState({ selectedRow, updateFixedRevenueErr });
    }

    onChangeUpdateNote(value) {
        let { selectedRow, updateFixedRevenueErr } = this.state;
        updateFixedRevenueErr["updateFixedNote"] = "";
        selectedRow.fixedNote = value;
        this.setState({ selectedRow, updateFixedRevenueErr });
    }

    submitUpdate() {

        if (this.updateFixedRevenueErrorhandler()) {

            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            this.adminPointService.updateProductFixedRevenue(this.state.selectedRow)
                .then(res => {
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data update successful.' });
                        this.fetchFixedRevenueList();
                        this.setState({ visible: false, dataTableIsLoading: false, topProgressBar: false });
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }

    updateDialogDiscard = () => {
        this.setState({ visible: false });
        this.fetchFixedRevenueList();
    }

    updateFixedRevenueErrorhandler = () => {

        let { selectedRow, updateFixedRevenueErr } = this.state;
        let formIsValid = true;

        if (selectedRow.configPercentage === '') {
            formIsValid = false;
            updateFixedRevenueErr["updatePercentage"] = "Percentage can't left empty.";
        }
        if (selectedRow.fixedNote === '') {
            formIsValid = false;
            updateFixedRevenueErr["updateFixedNote"] = "Note can't left empty.";
        }

        this.setState({ updateFixedRevenueErr });
        return formIsValid;
    }

    focusSearch = e =>{
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
    }

    blurSearch = e =>{
        this.setState({ searchLabel: '', addSearchBtnClass: ""  });
    }

    render() {
        var tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Configured Fixed Revenue List
        <span style={{ 'float': 'right' }}>Total Found: {this.state.dataTableValue.length} </span>
        </div>;

        let productTypeOptions = [];
        if (this.state.productTypeList && this.state.productTypeList.length) {
            productTypeOptions = this.state.productTypeList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let productListOptions = [];
        if (this.state.productList && this.state.productList.length) {
            productListOptions = this.state.productList.map(item => ({
                value: item.productID,
                label: item.productName
            }));
        }

        let editBody = (rowData) => {

            return <div className='text-center'>
                <Button
                    className="nw-action-button edit"
                    icon="fas fa-edit"
                    title="Edit"
                    onClick={(e) => this.viewDialog(rowData)}
                />
            </div>
        }

        let { selectedRow } = this.state;
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        return (

            <div className="p-fluid">

                {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}

                <div className="p-grid nw-form">
                    <Growl ref={(el) => this.growl = el} />
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-12">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Product Type <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Product Type'
                                            name="productType"
                                            options={productTypeOptions}
                                            value={this.state.productType}
                                            onChange={(e) => this.onChangeProductType(e.target.value)}
                                            autoWidth={false}
                                            filter={true}
                                        />
                                    </div>
                                }
                                <span className='error-message'>{this.state.errors["productType"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                {this.state.secondDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Product Name <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Product Name'
                                            name='productName'
                                            options={productListOptions}
                                            value={this.state.productName}
                                            onChange={(e) => this.onChangeProductName(e.target.value)}
                                            autoWidth={false}
                                            filter={true}
                                        />
                                    </div>
                                }
                                <span className='error-message'>{this.state.errors["productName"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Neti ID <span>*</span></span>
                                    <InputText 
                                        placeholder='Enter Neti ID'
                                        name="netiId"
                                        value={this.state.netiId}
                                        style={{ width:"15%" }}
                                        onChange={(e) => this.onChangeNetiId(e.target.value)}
                                        onFocus={this.focusSearch}
                                        onBlur={this.blurSearch}
                                    />
                                    <Button
                                        className={"p-button-animation " + this.state.addSearchBtnClass}
                                        label={this.state.searchLabel}
                                        icon="fas fa-search"
                                        onClick={(e) => this.submitSearch(e.target.value)}
                                        onFocus={this.focusSearch}
                                        onBlur={this.blurSearch}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["netiId"]}</span>
                            </div>


                            {this.state.searchView ?
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-search-view">
                                        {this.state.searchViewError ?
                                            <center className="error-message">{this.state.errors["Error"] || "No Data Found"}</center> :
                                            <div>
                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.searchIsLoading ?
                                                        this.NetiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Name
                                                    </span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                {this.state.userDetails.fullName}
                                                            </div>
                                                        </div>

                                                    }
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.searchIsLoading ?
                                                        this.NetiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Mobile No
                                                    </span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                {this.state.userDetails.basicMobile}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                                : ''
                            }

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Percentage  <span>*</span></span>
                                    <InputText
                                        placeholder='Enter Percentage'
                                        name="percentage"
                                        keyfilter="num"
                                        value={this.state.percentage}
                                        onChange={(e) => this.onChangePercentage(e.target.value)}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["percentage"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                    <InputTextarea
                                        placeholder='Enter Note'
                                        name="note"
                                        value={this.state.note}
                                        onChange={(e) => this.onChangeNote(e.target.value)}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["note"]}</span>
                            </div>

                            <div className="p-col-12"/>


                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <div className="required-field">
                                    (<span>*</span>) required fields
                                </div>

                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Save"
                                    icon="fas fa-check"
                                    onClick={event => this.submitSave(event)}
                                    disabled={this.state.buttonDisabled}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table nw-data-table-tabview">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    header={tableHeader}
                                    value={this.state.dataTableValue}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column field="productInfoDTO.productTypeInfoDTO.categoryName" header="Product Type" sortable={true} filter={true} />
                                    <Column field="productInfoDTO.productName" header="Product Name" sortable={true} filter={true} />
                                    <Column field="userBasicInfoDTO.customNetiID" header="Neti ID" sortable={true} filter={true} />
                                    <Column field="userBasicInfoDTO.fullName" header="Name" sortable={true} filter={true} />
                                    <Column field="configPercentage" header="Percentage" sortable={true} filter={true} />
                                    <Column field="" header="Action" style={{width: "80px"}} body={editBody} />

                                </DataTable>
                            }
                        </div>

                    </div>

                    <Dialog header="Update Fixed Revenue Infomation" className="nw-dialog" visible={this.state.visible} onHide={this.onHide}>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                     <div className="p-grid nw-form">
                                         <div className="p-col-12 p-xl-12">
                                             <div className="nw-form-body">

                                                 <div className="p-col-12">
                                                    <div className="p-grid">
                                                        <div className="p-col-3">
                                                            <label>Product Type : </label><br />
                                                            <label>Product Name : </label><br />
                                                            <label>Neti ID : </label><br />
                                                            <label>Name : </label>
                                                        </div>
                                                        {this.state.visible ?
                                                            <div className="p-col-8 text-bold">
                                                                <label>{selectedRow.productInfoDTO.productTypeInfoDTO.categoryName}</label> <br />
                                                                <label>{selectedRow.productInfoDTO.productName} </label> <br />
                                                                <label>{selectedRow.userBasicInfoDTO.customNetiID} </label> <br />
                                                                <label>{selectedRow.userBasicInfoDTO.fullName} </label>

                                                            </div>
                                                            : ''}

                                                    </div>
                                                </div>

                                                <div className="p-col-12">
                                                    <div className="formControl">
                                                        <label>Percentage <span>*</span></label>
                                                        <InputText
                                                            placeholder='Enter Percentage'
                                                            name="updatePercentage"
                                                            value={this.state.selectedRow.configPercentage || ''}
                                                            onChange={(e) => this.onChangeUpdatePercentage(e.target.value)}
                                                        />
                                                        <span className='error-message'>{this.state.updateFixedRevenueErr["updatePercentage"]}</span>
                                                    </div>
                                                </div>

                                                <div className="p-col-12">
                                                    <div className="formControl">
                                                        <label>Note <span>*</span></label>
                                                        <InputTextarea
                                                            placeholder='Enter Note'
                                                            name="updateFixedNote"
                                                            value={this.state.selectedRow.fixedNote || ''}
                                                            onChange={(e) => this.onChangeUpdateNote(e.target.value)}
                                                        />
                                                        <span className='error-message'>{this.state.updateFixedRevenueErr["updateFixedNote"]}</span>
                                                    </div>
                                                </div>

                                                <div className="p-col-8" />
                    
                                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        onClick={this.updateDialogDiscard}
                                                    />
                                                    <Button
                                                        label="Update"
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        icon="fas fa-check"
                                                        onClick={event => this.submitUpdate(event)}
                                                    />
                                                </div>

                                             </div>

                                         </div>
                                     </div>
                                </div>

                            </div>
                        </div>

                    </Dialog>

                </div>
            </div>

        )
    }
}