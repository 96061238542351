import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from "primereact/dropdown";
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Growl } from 'primereact/growl';
import { WalletBalance } from '../myPoint/common/WalletBalance';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { Panel } from 'primereact/panel';
import NetiContentLoader from '../common/NetiContentLoader';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { pdfDownloadPortrait_TableOnly } from '../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';



let statusListOptions = [
    { label: "Pending", value: 'pending' },
    { label: "Approved", value: 1 },
    { label: "Rejected", value: 0 }
]
let selectedInstituteName = '';
let selectedInstituteAddress = '';
let selectedAdvanceAmount = 0;

let paybillInstituteName = '';
let paybillInstituteAddress = '';
let paybillAdvanceAmount = 0;

export class PrePaidDisbursment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ],
            errors:{},
            emID:'',
            generateBillID:'',
            billAmount:"",
            payPrepaidbill: false,
            dueBillDetailsInfo: {},
            dataTableValue: [],
            advanceBillDetails: [],
            paidBillDetails: [],
            dueBillDetails: [],
            productList: [],
            approvalValue: null,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            dataTableIsLoading: false,
            secondDataTableIsLoading: false,
            thirdDataTableIsLoading: false

        };

        this.viewDialog = this.viewDialog.bind(this)
        this.onHide = this.onHide.bind(this)
        this.NetiContentLoader = new NetiContentLoader();
        this.crmPointService = new CRMPointService();
        this.ValidatorUtility = new ValidatorUtility();
        this.fetchPrepaidDisbursmentList = this.fetchPrepaidDisbursmentList.bind(this);
    }

    componentWillMount() {
        this.fetchPrepaidDisbursmentList();
    }

    exportPdf = () => {

        let tableColumns = [
            { title: "Institute ID", dataKey: "instituteId" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Contact No.", dataKey: "instituteContact" },
            { title: "Amount", dataKey: "advanceAmount" },
        ];
        let tableColStyle = { advanceAmount: { halign: 'right' } }

        pdfDownloadPortrait_TableOnly("Pre-Paid Disbursement",80, tableColumns, this.state.dataTableValue,tableColStyle, "Pre-Paid_Disbursement_Report.pdf");

    }

    exportExcel = () => {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog = () => { console.log('excel downloaded'); }

    fetchPrepaidDisbursmentList() {
        this.setState({dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false})
        this.crmPointService.fetchPre_PaidDisbursementInstituteList().then(res => {
            if (res.status == 302) {
                return res.json().then((body) => { 
                this.setState({ dataTableValue: body, dataTableIsLoading: false, topProgressBar: false }) 
            });
            } else { 
                this.crmPointService.Auth.handleErrorStatus(res)
                .then((resp) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                }); 
            }
        }).catch((error) => {
            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
        });
    }

    fetchAdvanceBill_Log(emDetailsId) {
        this.setState({topProgressBar: true, thirdDataTableIsLoading: true, errorMsgVisible: false})
        this.crmPointService.fetchAdvanceBill_LogByEmDetailsId(emDetailsId).then(res => {
            if (res.status == 302) {
                return res.json().then((body) => { 
                    this.setState({ advanceBillDetails: body, topProgressBar: false, thirdDataTableIsLoading: false,  errorMsgVisible: false }) 
                });
            } else { 
                this.crmPointService.Auth.handleErrorStatus(res)
                .then((resp) => {
                    this.setState({ thirdDataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                }); 
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, thirdDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
        });
    }

    fetchPaidBill_Log(emDetailsId) {
        this.setState({topProgressBar: true, secondDataTableIsLoading: true, errorMsgVisible: false})
        this.crmPointService.fetchPaidBill_LogByEmDetailsId(emDetailsId).then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                this.setState({ paidBillDetails: body, topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: false }) 
            });
            } else { 
                this.crmPointService.Auth.handleErrorStatus(res)
                .then((resp) => {
                    this.setState({ secondDataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                }); 
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
        });
    }

    fetchGeneratedDueBill_Log(emDetailsId) {
                this.setState({topProgressBar: true, errorMsgVisible: false})

        this.crmPointService.fetchGeneratedDueBill_LogByEmDetailsId(emDetailsId).then(res => {
            if (res.status == 302) {
                return res.json().then((body) => { 
                    this.setState({ dueBillDetails: body }) 
                    this.setState({topProgressBar: false, errorMsgVisible: false})

                });
            } else {
                this.crmPointService.Auth.handleErrorStatus(res)
                .then((resp) => {
                    this.setState({  topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                }); 
                 }
        }).catch((error) => {
            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
        });
    }

    viewDialog(rowData) {
        selectedInstituteName = rowData.instituteName;
        selectedInstituteAddress = rowData.instituteAddress;
        selectedAdvanceAmount=rowData.advanceAmount;
        this.fetchAdvanceBill_Log(rowData.emDetailsId);
        this.fetchPaidBill_Log(rowData.emDetailsId);
        this.fetchGeneratedDueBill_Log(rowData.emDetailsId);
        this.setState({ visible: true })
    }

    onHide() {
        this.setState({ visible: false })
        selectedInstituteName = '';
        selectedInstituteAddress = '';
        selectedAdvanceAmount=0;
    }
    // 
     viewActionBody = (rowData) => {

        return <div className='text-center'>
        <Button
            className="nw-action-button edit"                
            icon="fab fa-paypal"
            tooltip="Pay"
            onClick={(e) => this.viewPayPrepaidBillDialog(rowData)}
        />
         <Button
            className="nw-action-button info"
            icon="fas fa-eye"
            tooltip="View Bill Log"
            onClick={(e) => this.viewDialog(rowData)}
        />
    </div>
    }

    viewPayPrepaidBillDialog = (rowData) => {
        paybillInstituteName = rowData.instituteName;
        paybillInstituteAddress = rowData.instituteAddress;
        paybillAdvanceAmount = rowData.advanceAmount;
        this.setState({ emID: rowData.emDetailsId })
        this.fetchGeneratedDueBill_Log(rowData.emDetailsId);
        this.setState({ payPrepaidbill: true });

    }

    onHidePayPrepaidbillDialog = () => {
        paybillInstituteName = '';
        paybillInstituteAddress = '';
        paybillAdvanceAmount = 0;
        let {errors} = this.state;
        errors["billAmount"] = "";
        this.setState({ errors });
        this.setState({ payPrepaidbill: false, generateBillID:'', billAmount:'', dueBillDetailsInfo: {}});
    }

    advanceAmountFormatBody = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.advanceAmount)
    }

    paymentAmountFormatBody = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.paymentAmount)
    }

    paidAmountFormatBody = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.billAmount)
    }

    paymentDetailFormatBody = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.paymentDetails)
    }

    dueAmountFormatBody = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.dueAmount)
    }

    monthYearBody = (rowData) => {
        return <div><span>{rowData.month}</span><span>/</span><span>{rowData.year}</span></div>
    }

    onChangeBillSummary = (e) => {
        let { dueBillDetailsInfo, dueBillDetails,billAmount, errors } = this.state;
        errors["billMonth"] = "";
        errors["billAmount"] = "";
        this.setState({ generateBillID: e.target.value });
        this.setState({errors});
        for (let i = 0; i < dueBillDetails.length; i++) {
            if (dueBillDetails[i].generateBillId == e.target.value) {
                dueBillDetailsInfo = dueBillDetails[i];
                this.setState({billAmount: dueBillDetails[i].dueAmount});
                this.setState({ dueBillDetailsInfo});
            }
        }

    }

    onChangeBillAmount = (e) => {
        let {errors} = this.state;
        errors["billAmount"] = "";
        this.setState({errors});
        this.setState({billAmount: e.target.value});
    }

    onSubmitHandler = () => {
        let { dueBillDetailsInfo, errors,  billAmount} = this.state;
        if (this.submitErrorHandle()) {
            let payBillAmount=billAmount;
            let requestObject = {
                "billAmount": payBillAmount,
                "emBillGenerateLogDTO": {
                    "generateBillId": this.state.generateBillID
                },
                "emDetailsInfoDTO": {
                    "emDetailsId": this.state.emID
                }
            }
            let formIsValid = true;

            
            if (payBillAmount > paybillAdvanceAmount) {
                formIsValid = false;

                errors["billAmount"] = "Bill amount can't be greater than payable amount";
                this.setState({ errors });
                return '';
            }
            
            if (payBillAmount > dueBillDetailsInfo.dueAmount) {
                formIsValid = false;

                errors["billAmount"] = "Bill amount can't be greater than due amount";
                this.setState({ errors });
                return '';
            }
            if(billAmount == 0.0){
                formIsValid = false;
                errors["billAmount"] = "Bill amount can't be left empty";
                this.setState({ errors });
            }
            errors["billAmount"] = "";
            this.setState({ errors });
            
            this.setState({ topProgressBar: true, errorMsgVisible: false });

        this.crmPointService.payPrepaidBill(requestObject)
            .then(res => {
                this.setState({ topProgressBar: false })
                if (res.status === 201) {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data Saved successfully.' });
                    this.setState({ payPrepaidbill: false, billAmount: '', generateBillID: '', dueBillDetailsInfo: {} });
                    this.fetchPrepaidDisbursmentList();
                    this.setState({ errorMsgVisible: false })

                } else {
                    this.crmPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
        }
    }

    submitErrorHandle = () => {
    let { generateBillID, errors, billAmount } = this.state;
    let formIsValid = true;

 
        if (generateBillID === '') {
            formIsValid = false;
            errors["billMonth"] = "Bill month can't left empty.";
        }

        if (billAmount == "") {
            formIsValid = false;
            errors["billAmount"] = "Bill Amount can't left empty.";
        }
    this.setState({ errors });
    return formIsValid;
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let productListOptions = [];
        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Pre-Paid Disbursement List <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {this.state.dataTableValue.length}
            </span>
        </div>;

        let advanceBillHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Advance Bill Log <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {this.state.advanceBillDetails.length}
            </span>
        </div>;

        let prepaidBillHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Paid Bill Log <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {this.state.paidBillDetails.length}
            </span>
        </div>;

        let dueBillHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Generated Due Bill Log <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {this.state.dueBillDetails.length}
            </span>
        </div>;
        

        let billSummaryList = [];
        if ((this.state.dueBillDetails != null) && (this.state.dueBillDetails.length > 0)) {
            billSummaryList = this.state.dueBillDetails.map((item) => ({
                value: item.generateBillId,
                label: `${item.year} - ${item.month} - ${this.ValidatorUtility.currencyFormatter(item.dueAmount)} Tk`
            }));
        }


        let dateFormateBody = (rowData) => { return  NetiDateUtils.getAnyShortForm(rowData.paymentDate, 'DD-MMM-YYYY') }
        let billPaidDateFormate = (rowData) => { return NetiDateUtils.getAnyShortForm(rowData.billDate, 'DD-MMM-YYYY') }

             // excel
             let excelColumns = "";
             let excelFileName = "Pre-Paid_Disbursement_Report.xlsx";
             let excelSheetName = "Pre-Paid_Disbursement_Report";
             excelColumns = [
                 <Workbook.Column value="instituteId" label="Institute ID" />,
                 <Workbook.Column value="instituteName" label="Institute Name" />,
                 <Workbook.Column value="instituteContact" label="Contact No." />,
                 <Workbook.Column value="advanceAmount" label="Amount" />,
             ];
             // end excel
       
        return (


            <div className="p-grid nw-form">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }

                <div className="p-col-12 p-xl-9 p-col-nogutter seprator">
                    <div className="p-grid seprator-inside">
                        <div className="p-col-12 p-xl-12">
                            {   this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader():
                                <>
                                <div className="nw-data-table">
                                    <DataTable
                                        value={this.state.dataTableValue}
                                        header={header}
                                        paginator={true}
                                        rows={10}
                                        responsive={true}
                                    >
                                        <Column field="instituteId" header="Institute ID" filter={true} />
                                        <Column field="instituteName" header="Institute Name" filter={true} />
                                        <Column field="instituteContact" header="Contact No." filter={true} />
                                        <Column field="advanceAmount" header="Amount" body={this.advanceAmountFormatBody} filter={true} />
                                        <Column field="action" header="Action" style={{ width: "110px" }} body={this.viewActionBody} />
                                    </DataTable>

                                </div>
                                <div className="p-col-12 p-xl-12 nw-button-parent">

<SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

<div style={{ display: 'none' }}>

    <Workbook
        filename={excelFileName}
        element={
            <Button
                label="Download Excel"
                id="clickExcelBtn"
                icon="fas fa-download"
                className="p-button p-button-primary nw-button nw-button-right"
            />
        }
    >
        <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
            {excelColumns}
        </Workbook.Sheet>
    </Workbook>

</div>

</div >
                                {/* <div className="nw-button-parent-multiple m-t-8 p-r-0">

<Workbook
    filename={"Pre-Paid Disbursement Report.xlsx"}
    element={
        <Button
            label="Excel Download"
            icon="ui-icon-cloud-download"
            className="p-button p-button-primary nw-button nw-button-multiple"
        />
    }
>
    <Workbook.Sheet data={this.state.dataTableValue} name="Pre-Paid Disbursement">
        {excelColumns}
    </Workbook.Sheet>
</Workbook>

<Button
    className="p-button p-button-primary nw-button nw-button-multiple"
    label="Download"
    icon="fas fa-download"
    onClick={this.exportPdf}
/>
</div> */}
                                </>
                            }
                            
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-xl-3">
                {  this.state.dataTableIsLoading ? "" :
                    <WalletBalance walletBalanceName='Wallet' />
                }
                </div>

                <Dialog
                    header="Pre-Paid Bill Info"
                    className="nw-dialog"
                    onHide={this.onHide}
                    visible={this.state.visible}
                    dismissableMask={true}
                    modal={true}
                >

                    <div className="p-fluid">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12 nw-form-body">

                                <div className="p-col-12 p-xl-12">

                                    <div className="p-col-12 p-xl-6 p-md-offset-3 text-center">
                                        <h1>{selectedInstituteName}</h1>
                                        <p>{selectedInstituteAddress}</p>
                                    </div>



                                    <div className="p-col-12 p-xl-6 p-md-offset-3 text-center">

                                            <label>Payable Amount</label>
                                            <span>:</span>
                                            <label>
                                            {selectedAdvanceAmount ? `${this.ValidatorUtility.currencyFormatter(selectedAdvanceAmount)} Tk.` : ''}
                                            </label>

                                    </div>



                                        <div className="p-grid">
                                            <div className="p-col-12 p-xl-12">
                                            {  this.state.thirdDataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader():
                                                <div className="nw-data-table">

                                                <DataTable
                                                    value={this.state.advanceBillDetails}
                                                    header={advanceBillHeader}
                                                    responsive={true}
                                                    paginator={true}
                                                    rows={5}
                                                >
                                                    <Column field="paymentDate" header="Date" body={dateFormateBody} />
                                                    <Column field="paymentDetails" header="Payment Details" body={this.paymentDetailFormatBody} />
                                                    <Column field="paymentAmount"  header="Amount" body={this.paymentAmountFormatBody} />
                                                </DataTable>
                                                </div>
                                            }

                                            </div>
                                            <div className="p-col-12 p-xl-12">
                                            {  this.state.secondDataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader():
                                                <div className="nw-data-table">

                                                <DataTable
                                                    value={this.state.paidBillDetails}
                                                    selectionMode="single"
                                                    header={prepaidBillHeader}
                                                    selection={this.state.dataTableSelection}
                                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                    paginator={true}
                                                    rows={5}
                                                    responsive={true}
                                                >
                                                    <Column field="billDate" header="Date" body={billPaidDateFormate} />
                                                    <Column field="billAmount" header="Amount" body={this.paidAmountFormatBody} />
                                                </DataTable>
                                                </div>
                                            }

                                            </div>
                                            <div className="p-col-12 p-xl-12">
                                            {  this.state.secondDataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader():
                                                <div className="nw-data-table">

                                                <DataTable
                                                    value={this.state.dueBillDetails}
                                                    selectionMode="single"
                                                    header={dueBillHeader}
                                                    selection={this.state.dueDataTableSelection}
                                                    onSelectionChange={event => this.setState({ dueDataTableSelection: event.value })}
                                                    paginator={true}
                                                    rows={5}
                                                    responsive={true}
                                                >
                                                    <Column field="monthYear" body={this.monthYearBody} header="Month/Year" />
                                                    <Column field="dueAmount" body={this.dueAmountFormatBody} header="Due Amount" />
                                                </DataTable>
                                                </div>
                                            }

                                            </div>


                                          
                                        </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    header="Pay Pre-Paid Bill"
                    className="nw-dialog"
                    onHide={this.onHidePayPrepaidbillDialog}
                    visible={this.state.payPrepaidbill}
                    dismissableMask={true}
                    modal={true}
                >

                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">

                                <div className="p-col-12 p-xl-12">

                                <div className="p-col-12 p-xl-6 p-md-offset-3 text-center">
                                        <h1>{paybillInstituteName}</h1>
                                        <p>{paybillInstituteAddress}</p>
                                    </div>



                                    <div className="p-col-12 p-xl-6 p-md-offset-3 text-center">

                                            <label>Payable Amount</label>
                                            <span>:</span>
                                            <label>
                                            {paybillAdvanceAmount ? `${this.ValidatorUtility.currencyFormatter(paybillAdvanceAmount)} Tk.` : ''}
                                            </label>

                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Bill Month <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Bill Month"
                                                value={this.state.generateBillID}
                                                options={billSummaryList}
                                                onChange={this.onChangeBillSummary}
                                                filter={true}
                                                filterBy="value"
                                                showClear={true}
                                                autoWidth={false}
                                                name="billMonth"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors["billMonth"]}</span>

                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div class="nw-search-view">
                                            <div> 
                                                <div className="p-col-12 p-xl-12"> 
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon"> Year</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                        {this.state.dueBillDetailsInfo.year}
                                                        </div>
                                                    </div> 
                                                </div>
                                                    
                                                <div className="p-col-12 p-xl-12">
                                                
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon"> Month</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                        {this.state.dueBillDetailsInfo.month}
                                                        </div>
                                                    </div>
                                            
                                                </div>
                                            </div>
                                        </div>        
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Bill Amount <span>*</span></span>
                                            <InputText
                                                onChange={this.onChangeBillAmount}
                                                value={this.state.billAmount}
                                                keyfilter="pint"
                                                placeholder="Enter Bill Amount"
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="billAmount"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors["billAmount"]}</span>

                                    </div>
                                    
                                    <div className="p-col-12 p-xl-12 nw-button-parent" >
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Pay"
                                            icon="fas fa-hand-holding-usd"
                                            onClick={this.onSubmitHandler}
                                        />
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>

        )
    }
}