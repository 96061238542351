import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Link } from 'react-router-dom';

import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { NetiDateUtils } from "../../../../utils/NetiDateUtils";

import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import NetiContentLoader from '../../../common/NetiContentLoader';

export class InstituteDueStatement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            instituteDetails: {},
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            remainDays: '',
            createDuration: '',
            // rowDataObj: this.props.location.searchObj || this.props.searchObj
        }

        // this.paidDetailsBody = this.paidDetailsBody.bind(this);
        this.managementPointService = new ManagementPointService();
    }

    componentWillMount() {
        if ( this.props.searchObj || this.props.location && this.props.location.searchObj && this.props.location.searchObj.data) {
            this.getEdumanInstituteDueStatement( (this.props.searchObj && this.props.searchObj.data && this.props.searchObj.data.emDetailsId) || this.props.location && this.props.location.searchObj.data.emDetailsId);
        }

    }

    getEdumanInstituteDueStatement = (param) => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        // let emDetailsId = JSON.parse(localStorage.getItem("emDetailsId"));
        // if (!emDetailsId) {
        //     return;
        // }
        this.managementPointService.fetchEdumanInstituteDueStatementById(param)
            .then(res => {
                if (res.status === 302) {
                    return res.json().then(body => {
                        console.log("institute due statement body",body);
                        this.setState({ instituteDetails: body });
                        this.setState({ remainDays: NetiDateUtils.getDurationYearMonthAndDayStringFromDateRange(new Date(), body.agreementExpiryDate) })
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        })
                }
            })
            .catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }
   


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, instituteDetails } = this.state;


        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="nw-form">

                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-12">
                                <h1>{instituteDetails.instituteName}</h1>
                                <p>{instituteDetails.instituteAddress}</p>
                            </div>

                            <div className="p-col-12 p-xl-12  p-col-nogutter">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Basic Information</span>
                                    <div className="nw-inputgroup-desc">Create Duration: Years(1), Months(7), Days(23)</div>
                                </div>
                            </div>


                            <div className="p-col-12 p-xl-6 nw-dataview-small-panel">
                                <div>
                                    <div>Institute ID</div>
                                    <div>:</div>
                                    <div>{instituteDetails.instituteId}</div>
                                </div>

                                <div>
                                    <div>Education Board</div>
                                    <div>:</div>
                                    <div>{instituteDetails.instituteBoardCoreCategoryInfoDTO ? instituteDetails.instituteBoardCoreCategoryInfoDTO.categoryName : ''}</div>
                                </div>

                                <div>
                                    <div>Institute Category</div>
                                    <div>:</div>
                                    <div>{instituteDetails.instituteCategoryCoreCategoryInfoDTO ? instituteDetails.instituteCategoryCoreCategoryInfoDTO.categoryName : ''}</div>
                                </div>

                                <div>
                                    <div>Institute Type</div>
                                    <div>:</div>
                                    <div>{instituteDetails.instituteTypeCoreCategoryInfoDTO ? instituteDetails.instituteTypeCoreCategoryInfoDTO.categoryName : ''}</div>
                                </div>

                                <div>
                                    <div>Total Shift</div>
                                    <div>:</div>
                                    <div> Triple</div>
                                </div>

                                <div>
                                    <div>Institute Phone</div>
                                    <div>:</div>
                                    <div>{instituteDetails.instituteContact}</div>
                                </div>

                                <div>
                                    <div>Institute Email</div>
                                    <div>:</div>
                                    <div>{instituteDetails.instituteEmail}</div>
                                </div>

                                <div>
                                    <div>Division</div>
                                    <div>:</div>
                                    <div>{instituteDetails.globalAreaCoreCategoryInfoDTO && instituteDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO ? instituteDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''}</div>
                                </div>

                                <div>
                                    <div>District</div>
                                    <div>:</div>
                                    <div>{instituteDetails.globalAreaCoreCategoryInfoDTO ? instituteDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''}</div>
                                </div>

                                <div>
                                    <div>Area</div>
                                    <div>:</div>
                                    <div>{instituteDetails.globalAreaCoreCategoryInfoDTO ? instituteDetails.globalAreaCoreCategoryInfoDTO.categoryName : ''}</div>
                                </div>

                            </div>


                            <div className="p-col-12 p-xl-6 nw-dataview-small-panel">
                                <div>
                                    <div>Agreement Date</div>
                                    <div>:</div>
                                    <div>{instituteDetails.agreementStartDate}</div>
                                </div>

                                <div>
                                    <div>Agreement Expire Date</div>
                                    <div>:</div>
                                    <div>{instituteDetails.agreementExpiryDate}</div>
                                </div>

                                <div>
                                    <div>Submission Date</div>
                                    <div>:</div>
                                    <div>{instituteDetails.dataSubmissionDate}</div>
                                </div>

                                <div>
                                    <div>Hand Over</div>
                                    <div>:</div>
                                    <div>{instituteDetails.handoverDate}</div>
                                </div>

                                <div>
                                    <div>Form Serial</div>
                                    <div>:</div>
                                    <div>{instituteDetails.formSerial}</div>
                                </div>

                                {/* <div>
                                    <div>Eduman ID</div>
                                    <div>:</div>
                                    <div>{instituteDetails.emDetailsId}</div>
                                </div> */}

                                <div>
                                    <div>Remain to Expire</div>
                                    <div>:</div>
                                    <div>{this.state.remainDays ? this.state.remainDays : '0 Days'}</div>
                                </div>


                            </div>

                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">

                            <div className="p-col-12 p-xl-12 p-col-nogutter">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Payable Information </span>
                                    <div className="nw-inputgroup-desc">Current Due</div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-6 nw-dataview-small-panel">
                                <div>
                                    <div>Eduman Package</div>
                                    <div>:</div>
                                    <div>{instituteDetails.packageCoreCategoryInfoDTO ? instituteDetails.packageCoreCategoryInfoDTO.categoryName : ''}</div>
                                </div>

                                <div>
                                    <div>Registration Charge</div>
                                    <div>:</div>
                                    <div>{instituteDetails.developmentCharge}</div>
                                </div>

                                <div>
                                    <div>Rate Per Month</div>
                                    <div>:</div>
                                    <div>{instituteDetails.monthlyRate}</div>
                                </div>

                                <div>
                                    <div>Payment Type</div>
                                    <div>:</div>
                                    <div>{instituteDetails.paymentType}</div>
                                </div>

                                <div>
                                    <div>Institute Status</div>
                                    <div>:</div>
                                    <div>{instituteDetails.instituteTypeCoreCategoryInfoDTO && instituteDetails.instituteTypeCoreCategoryInfoDTO.categoryEnableStatus === 1 ? 'ACTIVE' : 'INACTIVE'}</div>
                                </div>

                            </div>

                            <div className="p-col-12 p-xl-6 nw-dataview-small-panel">
                                <div>
                                    <div>Academic Year</div>
                                    <div>:</div>
                                    <div> 2019</div>
                                </div>

                                <div>
                                    <div>Current Student</div>
                                    <div>:</div>
                                    <div> 750</div>
                                </div>

                                <div>
                                    <div>Current HR</div>
                                    <div>:</div>
                                    <div> 30</div>
                                </div>

                                <div>
                                    <div>Message Balance</div>
                                    <div>:</div>
                                    <div> 120</div>
                                </div>

                                <div>
                                    <div>Total Billed Month</div>
                                    <div>:</div>
                                    <div> 10</div>
                                </div>

                                <div>
                                    <div>Last Billed Month</div>
                                    <div>:</div>
                                    <div> April (2019)</div>
                                </div>

                                <div>
                                    <div>Last Billed Amount</div>
                                    <div>:</div>
                                    <div> 10900</div>
                                </div>

                                <div>
                                    <div>Total Payable</div>
                                    <div>:</div>
                                    <div> 150000</div>
                                </div>

                                <div>
                                    <div>Total Discount</div>
                                    <div>:</div>
                                    <div> 10900</div>
                                </div>

                                <div>
                                    <div>Total Paid</div>
                                    <div>:</div>
                                    <div> 159905</div>
                                </div>

                            </div>


                        </div>
                    </div>


                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">

                            <div className="p-col-12 p-xl-12 p-col-nogutter">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Partner Information</span>
                                    <div className="nw-inputgroup-desc">Partner ID: {instituteDetails.partnerAssignDTO ? instituteDetails.partnerAssignDTO.userBasicInfoDTO.customNetiID : ''}</div>
                                </div>
                            </div>

                            <div className=" p-col-12 p-xl-6 p-xl-offset-3 nw-dataview-small-panel">
                                <div>
                                    <div>Partner Point ID</div>
                                    <div>:</div>
                                    <div>123</div>
                                </div>

                                <div>
                                    <div>Partner Name</div>
                                    <div>:</div>
                                    <div>{instituteDetails.partnerAssignDTO ? instituteDetails.partnerAssignDTO.userBasicInfoDTO.fullName : ''}</div>

                                </div>

                                <div>
                                    <div>Designation</div>
                                    <div>:</div>
                                    <div>{instituteDetails.partnerAssignDTO ? instituteDetails.partnerAssignDTO.partnerDesignationInfoDTO.categoryName : ''}</div>
                                </div>

                                <div>
                                    <div>Area</div>
                                    <div>:</div>
                                    <div>{instituteDetails.partnerAssignDTO ? instituteDetails.partnerAssignDTO.partnerAreaInfoDTO.categoryName : ''}</div>
                                </div>

                                <div>
                                    <div>Mobile No.</div>
                                    <div>:</div>
                                    <div>{instituteDetails.partnerAssignDTO ? instituteDetails.partnerAssignDTO.userBasicInfoDTO.basicMobile : ''}</div>
                                </div>

                                <div>
                                    <div>Email</div>
                                    <div>:</div>
                                    <div>{instituteDetails.partnerAssignDTO ? instituteDetails.partnerAssignDTO.userBasicInfoDTO.basicEmail : ''}</div>
                                </div>

                                {/* <div>
                                    <div>Supervisor Info</div>
                                    <div>:</div>
                                    <div>
                                        <Link to={{
                                            pathname: "",
                                            parentInfo: instituteDetails.parentAssignDTO
                                        }}><u>View</u></Link>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}