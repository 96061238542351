import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';

let errors = {};
let urlId;

export class DressCodeInfoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            proPic: {
                contentName: ''
            },
            gender: '',
            memberStatus: '',
            dataTableValue: [],
            genderCategoryInfoID: 0,
            dressCode: {
                dressSerial: "",
                classRange: "",
                dressDetails: "",
                dressImageName: "",
                dressImagePath: "",
                dressImageContent: "",
                dressImageSaveOrEditable: false,
                genderCategoryInfoDTO: {
                    coreCategoryID: 0,
                    categoryName: ""
                },
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            dataTableIsLoading: false,
            errors: {},
            updateImg: '',

        }

        this.DwsService = new DwsService();
        this.netiFileHandler = new NetiFileHandler();
        this.reloadCoreUrlIdFromLocal();
        this.NetiContentLoader = new NetiContentLoader();
        this.getImg = this.getImg.bind(this);
        
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        const urlId = this.DwsService.getUrlInfoFromLocalStorage();
        this.viewDressCodeList();
    }

    viewDressCodeList = () => {
        const urlId = this.DwsService.getUrlInfoFromLocalStorage();
        let coreUrl = {
            "urlInfoID": parseInt(urlId)
        }
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.fetchDressCodeList(urlId)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ dataTableValue: body })
                        this.props.countTableSize(body.length);
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }



    onHide = (event) => {
        let { errors } = this.state
        errors={}
        this.setState({ dialogVisible: false, errors: errors });
    }

    viewDialog = (rowData) => {
        this.setState({ dialogVisible: true });
    }

    viewDownloadData = (rowData) => {
        let { dressCode } = this.state
        this.setState({ dialogVisible: true });

        this.DwsService.getDressInfoByID(rowData.dressId)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ dressCode: body })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.getImg();
                    });
                }
                else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });

    }

    actionTemplate = (rowData) => {
        return <div className="text-center">
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button edit"
                onClick={(e) => this.viewDownloadData(rowData)}
            >
            </Button>
        </div>;
    }

    onChangeDressSerial = (e) => {
        errors = {}
        let { dressCode } = this.state;
        dressCode.dressSerial = e.target.value
        this.setState({ dressCode, errors })
    }

    onChangeClassRange = (e) => {
        errors = {}
        let { dressCode } = this.state;
        dressCode.classRange = e.target.value
        this.setState({ dressCode, errors })
    }

    onChangeDetails = (e) => {
        errors = {}
        let { dressCode } = this.state;
        dressCode.dressDetails = e.target.value
        this.setState({ dressCode, errors })
    }

    onChangeGender = (e) => {
        errors = {}
        let { dressCode } = this.state;
        dressCode.genderCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ dressCode })
    }

    onChangeInputValue = (e) => {
        errors = {}
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    countTableSize() {
        const { dataTableValue } = this.state;
        return dataTableValue.length;
    }

    updateDressCodeFormHandleError = () => {
        let { errors, dressCode } = this.state;
        let formIsValid = true;

        if (dressCode.dressSerial === '') {
            formIsValid = false;
            errors["serialNumber"] = "Serial Number can't left empty.";
        }
        if (!dressCode.genderCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["gender"] = "Gender can't left empty.";
        }
        if (dressCode.classRange === '') {
            formIsValid = false;
            errors["classRange"] = "Class Range can't left empty.";
        }
        if (dressCode.dressDetails === '') {
            formIsValid = false;
            errors["dressDetails"] = "Dress Details can't left empty.";
        }
        if (dressCode.dressImageContent == '' || this.state.updateImg == '') {
            console.log("test");
            
            formIsValid = false;
            errors["updateImage"] = "Image can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onBasicUploadAuto = (e) => {
        let { dressCode, errors } = this.state;

        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });
            scope.setState({ updateImg: album.contentPic })
            dressCode.dressImageSaveOrEditable = true
            
            
        }
        errors["updateImage"] = "";
        this.setState({ errors, dressCode})
    }

    onUpdateDressInfo = (event, props) => {
        let { dressCode } = this.state;

        if (this.state.proPic.contentPic) {
            dressCode.dressImageContent = this.state.proPic.contentPic || this.state.updateImg;
            dressCode.dressImageSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            // dressCode.dressImageName = 'dress_code_' + urlId + '_' + Date.now() + extention;
            dressCode.dressImageName = this.state.proPic.contentName;

        }


        if (this.updateDressCodeFormHandleError()) {

            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.setState({ dressCode });

            this.DwsService.updateDressInfo(dressCode)
                .then(res => {

                    if (res.status === 202) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully Updated",
                            life: 800
                        });
                        this.setState({ updateImg: '', proPic: {} });
                        setTimeout(() => {
                            this.onHide();
                        }, 800);
                        this.viewDressCodeList();

                    } else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        }
    }

    cancelDressInfo = (e) => {
        this.setState({ dialogVisible: false });
    }

    getImg() {
        let { dressCode } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        let photoMainPath;

        if (this.state.photoName) {
            photoMainPath = "/images/nw/dws/gallery/" + this.state.photoName
        }
        else {
            photoMainPath = dressCode.dressImagePath
        }

        if (photoMainPath !== null) {
            this.netiFileHandler.getByteImage(photoMainPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {

                                let contentType = this.netiFileHandler.getImageContentType(dressCode.dressImageName);
                                this.setState({ updateImg: body.fileContent });
                            })
                    } else {
                        this.setState({ updateImg: staticImg });
                    }
                });
        } else {
            this.setState({ updateImg: staticImg });
        }

    }

    maxFileSize = () =>{
        let maxFileSize = 1000000;
        if(maxFileSize < maxFileSize+1){

        }
    }

    render() {

        console.log(this.state);
        
        let { dressCode, dataTableValue } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">(370 x 240 px (recommended)</span></div>

        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                                Dress Code Info List
                            </div>

                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length}
                                </a>
                            </div>
                            </div>;
        return (

            <div className="p-fluid">
                <div className="p-grid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} />
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                    columnResizeMode="fit"
                                >
                                    <Column field="dressSerial" header="Serial No." filter={true} />
                                    <Column field="genderCategoryInfoDTO.categoryName" header="Gender" filter={true} />
                                    <Column field="classRange" header="Class Range" filter={true} />
                                    <Column field="action" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                </DataTable>
                            }
                        </div>
                    </div>

                    <Dialog
                        className="nw-dialog"
                        header="Update Dress Code Information"
                        visible={this.state.dialogVisible}
                        onHide={this.onHide} closable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>
                                                <div className="nw-inputgroup-desc">
                                                    {dressCode.dressSerial}
                                                </div>
                                            </div>
                                            <span className="error-message">{this.state.errors["serialNumber"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                        { this.state.firstDropDownIsLoading ? 
                                            this.NetiContentLoader.normalFormInputField():
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Gender <span>*</span></span>
                                                    <Dropdown
                                                        name="gender"
                                                        value={dressCode.genderCategoryInfoDTO ? dressCode.genderCategoryInfoDTO.coreCategoryID : ''}
                                                        options={this.props.genderList}
                                                        onChange={this.onChangeGender}
                                                        showClear={true}
                                                        autoWidth={false}
                                                    />
                                            </div>
                                        }
                                            <span className="error-message">{this.state.errors["gender"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Class <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter class"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    id="class"
                                                    name="classRange"
                                                    value={dressCode.classRange}
                                                    onChange={(e) => this.onChangeClassRange(e)}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["classRange"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                                <InputTextarea
                                                    id="details"
                                                    rows={3}
                                                    cols={30}
                                                    autoResize={true}
                                                    name="details"
                                                    placeholder="Enter Dress Code Details"
                                                    value={dressCode.dressDetails}
                                                    onChange={(e) => this.onChangeDetails(e)}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["dressDetails"]}</span>
                                        </div>




                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Upload Image <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/GIF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    {
                                                        this.state.updateImg ?
                                                            <div className="image-view-main">
                                                                <div className="upload-image-view">
                                                                    <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={e => this.setState({ proPic: { contentPic: ''}, updateImg:''  })}
                                                                    />

                                                                    {
                                                                        this.state.updateImg != "assets/layout/images/avatar.png" ? 
                                                                            <img src={`data:image/*;base64, ${this.state.updateImg}`} style={{ "height": "80px" }} /> : 
                                                                            <img src={this.state.updateImg} style={{ "height": "80px" }} />
                                                                    }
                                                                    {/* <img
                                                                        src={
                                                                            this.state.proPic.contentPic === '' ?
                                                                                'assets/images/demo.jpg'
                                                                                :
                                                                                "data:image/png;base64," + this.state.proPic.contentPic
                                                                        }
                                                                        style={{ "height": "80px" }}
                                                                    /> */}


                                                                </div>
                                                                <div className="image-title">{this.state.proPic.contentName}</div>
                                                            </div>

                                                            :
                                                            <FileUpload
                                                                chooseLabel={this.state.proPic.contentName || uploadFileLabel}
                                                                mode="basic"
                                                                accept="image/*"
                                                                maxFileSize={this.maxFileSize}
                                                                onSelect={this.onBasicUploadAuto}
                                                                auto={true}
                                                                name="updateImage"
                                                            />
                                                    }

                                                </div>


                                                {/* <center>
                                                    {this.state.uploadImage.contentName}
                                                </center> */}
                                            </div>
                                            <span className='error-message'>{ this.state.errors["updateImage"]}</span>
                                        </div>


                                        

                                        {/* <div className="nw-upload-button ">
                                            <div className="p-col-12 p-xl-12">
                                                <FileUpload
                                                    chooseLabel="Attachments"
                                                    className="p-button-raised p-button-secondary"
                                                    mode="basic"
                                                    maxFileSize={1000000}
                                                    onSelect={this.onBasicUploadAuto.bind(this)}
                                                    auto={true}
                                                    autoWidth={false}
                                                    accept="image/*"
                                                />
                                                <center>{this.state.proPic.contentName ? this.state.proPic.contentName : this.state.dressCode.dressImageName}</center>
                                            </div>
                                        </div> */}
                                
                                        <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                            <Button
                                                className="p-button p-button-danger nw-button nw-button-multiple"
                                                label="Discard"
                                                icon="fas fa-times"
                                                onClick={this.cancelDressInfo.bind(this)}
                                            />
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-multiple"
                                                label="Update"
                                                icon="fas fa-check"
                                                onClick={this.onUpdateDressInfo.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>

                </div>
            </div>
        );
    }
}