import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import CommonFuctionality from '../../common/CommonFuctionality';
import { DownloadButton } from "../../../utils/DownloadButton";

let urlId;
export class NoticeUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noticeSubmitError: {},
            uploadImage: {
                contentName: ''
            },
            createDate: '',
            expiryDate: '',
            noticeInfo: {
                noticeTitle: "",
                noticeDetails: "",
                noticeImgName: "",
                noticeImgPath: "",
                noticeCreateDate: "",
                noticeStatus: "",
                noticeExpiryDate: "",
                noticeImgContent: "",
                urlInfoDTO: {
                    urlInfoID: ''
                }
            },
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            homeReturnButton: false

        }
        this.NetiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.DwsService = new DwsService();
        this.commonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            this.setState({ homeReturnButton: false })
            let { noticeInfo } = this.state;
            noticeInfo.urlInfoDTO.urlInfoID = urlId;
            this.setState({ noticeInfo });
            this.setState({ noticeInfo: this.props.noticeInformation });
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    noticeNameHandler = (event, props) => {
        let { noticeInfo } = this.state;
        this.props.noticeInformation.noticeTitle = event.target.value;
        noticeInfo.noticeTitle = this.props.noticeInformation.noticeTitle;
        this.setState({ noticeInfo });
    }

    issueDateHandler = (event, props) => {
        let { noticeInfo } = this.state;
        this.state.createDate = event.target.value;
        noticeInfo.noticeCreateDate = this.state.createDate;
        this.setState({ noticeInfo });
    }

    expiryDateHandler = (event, props) => {
        let { noticeInfo } = this.state;
        this.state.expiryDate = event.target.value;
        noticeInfo.noticeExpiryDate = this.state.expiryDate;
        this.setState({ noticeInfo });
    }

    noticeDetailsHandler = (event, props) => {
        let { noticeInfo } = this.state;
        this.props.noticeInformation.noticeDetails = event.target.value;
        noticeInfo.noticeDetails = this.props.noticeInformation.noticeDetails;
        this.setState({ noticeInfo });
    }

    noticeStatushandler = (event, props) => {
        let { noticeInfo } = this.state;
        this.props.noticeInformation.noticeStatus = event.target.value;
        noticeInfo.noticeStatus = this.props.noticeInformation.noticeStatus;
        this.setState({ noticeInfo });
    }

    onLoadPic(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ uploadImage: album });

        }

        // console.log("uploadFile", this.state.uploadImage);
    }

    updateNoticeHandler = () => {
        let { noticeInfo, createDate, expiryDate } = this.state;
        if (this.state.uploadImage.contentPic != null) {
            noticeInfo.noticeImgContent = this.state.uploadImage.contentPic;
            noticeInfo.noticeImgSaveOrEditable = true;
            // let extention = this.netiFileHandler.getImageExtention(this.state.uploadImage.extention);
            // noticeInfo.noticeImgName = 'notice_' + urlId + '_' + Date.now() + extention;
            // noticeInfo.noticeImgPath = this.props.noticeInformation.noticeImgPath

            noticeInfo.noticeImgName = this.state.uploadImage.contentName;
        }

        if(!createDate){
            // console.log("noticeCreateDate", noticeInfo.noticeCreateDate);
            this.setState({
                noticeInfo,
                createDate: NetiDateUtils.getDateFromString(noticeInfo.noticeCreateDate, 'DD/MM/YYYY')
            })
        }

        if(!expiryDate){
            // console.log("noticeExpiryDate", this.props.noticeInformation.noticeExpiryDate);
            this.setState({
                expiryDate: NetiDateUtils.getDateFromString(noticeInfo.noticeExpiryDate, 'DD/MM/YYYY')
            })
        }

        if (this.errorHandler()) {
            this.setState({ noticeInfo });
            this.DwsService.updateNotice(this.state.noticeInfo)
                .then(res => {
                    if (res.status == 202) {

                        this.props.showUpdateMessage(true);
                        setTimeout(() => {this.props.loadDialog();}, 800);

                        this.props.turnOffDialog();
                        this.props.viewTopNoticeList();
                        
                    } else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({  errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error => {
                    this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
                });
        }
    }

    errorHandler() {

        let { noticeSubmitError } = this.state;
        let formIsValid = true;
        if (this.props.noticeInformation.noticeTitle === '') {
            formIsValid = false;
            noticeSubmitError["noticeTitle"] = "Notice title can't left empty";
        }

        if (!this.props.noticeInformation.noticeCreateDate && !this.state.createDate) {
            formIsValid = false;
            noticeSubmitError["noticeCreateDate"] = "Create date can't left empty";
        }
        else if (this.state.createDate && !Date.parse(this.state.createDate)) {
            formIsValid = false;
            noticeSubmitError["noticeCreateDate"] = "Invalid date format";
        }

        if (!this.props.noticeInformation.noticeExpiryDate && !this.state.expiryDate) {
            formIsValid = false;
            noticeSubmitError["noticeExpiryDate"] = "Expiry date can't left empty.";
        } else if (this.state.expiryDate && !Date.parse(this.state.expiryDate)) {
            formIsValid = false;
            noticeSubmitError["noticeExpiryDate"] = "Invalid date format";
        }

        if (this.props.noticeInformation.noticeDetails === '') {
            formIsValid = false;
            noticeSubmitError["noticeDetails"] = "Notice details can't left empty.";
        }
        if (this.props.noticeInformation.noticeImgContent === false) {
            formIsValid = false;
            noticeSubmitError["noticeImgContent"] = "Attachment is missing.";
        }
        this.setState({ noticeSubmitError });
        return formIsValid;
    }

    noticePublishTo = (noticeStatus) => {
        return (
            <div className="input-radio-button">
                <div className="radio-button-inside">
                    <RadioButton
                        value={1}
                        inputId="enable"
                        name="speechEnable"
                        onChange={(e) => this.noticeStatushandler(e, this.props)}
                        checked={this.props.noticeInformation.noticeStatus === 1}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Enable</label>
                </div>

                <div className="radio-button-inside">
                    <RadioButton
                        value={0}
                        inputId="enable"
                        name="speechEnable"
                        onChange={(e) => this.noticeStatushandler(e, this.props)}
                        checked={this.props.noticeInformation.noticeStatus === 0}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Disable</label>
                </div>
            </div>
        )
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, noticeInfo } = this.state;

        // console.log("NoticeInfo", noticeInfo.noticeCreateDate);
        // const notic=this.props.noticeInformation;
        // let dat=NetiDateUtils.getAnyShortForm(noticeInfo.noticeCreateDate, 'DD/MM/YYYY');
        // console.log("NoticeInfo1", notic);
        return (

            <div className="p-fluid">
                {
                    this.state.homeReturnButton === true ?
                        <Button
                            className="p-button p-component p-button-success p-button-text-only"
                            label="Save"
                            onClick={this.onSubmitHandler}
                        />
                        : null
                }
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Title <span>*</span></span>
                                            <InputText
                                                id="title"
                                                onChange={(e) => this.noticeNameHandler(e, this.props)}
                                                value={this.state.noticeInfo.noticeTitle ? this.state.noticeInfo.noticeTitle : ''}
                                                name="title"
                                                placeholder="Enter Notice Title"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.noticeSubmitError.noticeTitle}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Issue Date <span>*</span></span>
                                            <Calendar
                                                id="issueDate"
                                                name="issueDate"
                                                showIcon={true}
                                                value={ this.state.createDate }
                                                // value={this.state.noticeInfo.noticeCreateDate ? this.state.noticeInfo.noticeCreateDate : '' }
                                                onChange={(e) => this.issueDateHandler(e, this.props)}
                                                placeholder={NetiDateUtils.getAnyShortForm(noticeInfo.noticeCreateDate, 'DD/MM/YYYY')}
                                                className="custom-calender-input"
                                                yearRange="2010:2030"
                                                dateFormat="dd/mm/yy"
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                dateOnly="true"
                                                showButtonBar={true} 
                                            />
                                        </div>
                                        <span className="error-message">{this.state.noticeSubmitError.noticeCreateDate}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Expiry Date <span>*</span></span>
                                            <Calendar
                                                id="expiryDate"
                                                name="expiryDate"
                                                value={this.state.expiryDate}
                                                showIcon={true}
                                                onChange={(e) => this.expiryDateHandler(e, this.props)}
                                                className="custom-calender-input"
                                                placeholder={NetiDateUtils.getAnyShortForm(noticeInfo.noticeExpiryDate, 'DD/MM/YYYY')}
                                                yearRange="2010:2030"
                                                dateFormat="dd/mm/yy"
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                dateOnly="true"
                                                showButtonBar={true} 
                                            />
                                        </div>
                                        <span className="error-message">{this.state.noticeSubmitError.noticeExpiryDate}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                            <InputTextarea
                                                value={this.props.noticeInformation.noticeDetails}
                                                id="details"
                                                onChange={(e) => this.noticeDetailsHandler(e, this.props)}
                                                name="details"
                                                rows={5}
                                                cols={30}
                                                placeholder="Write Notice Details"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.noticeSubmitError.noticeDetails}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon addon-no-style">PUBLISH TO <span></span></span>
                                            
                                            <div className="nw-inputgroup-desc desc-no-style">{this.noticePublishTo(noticeInfo.noticeStatus)}</div>

                                        </div>
                                        {/* <span className="error-message">{this.state.photoSubmitError.photoType}</span> */}
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload Image <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/JPEG/GIF)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                <div className="image-view-main">
                                                    <div className="upload-image-view">
                                                        <Button
                                                            className="delete-upload-button"
                                                            icon="fas fa-times-circle"
                                                            onClick={e => this.setState({ uploadImage: { contentPic: '' } })}
                                                        />
                                                        {this.state.uploadImage.contentName ?
                                                            this.commonFuctionality.getFileContentTypeIcon(this.state.uploadImage.contentName) : this.commonFuctionality.getFileContentTypeIcon(this.props.noticeInformation.noticeImgName)}

                                                        <DownloadButton fileName={this.props.noticeInformation.noticeImgName} filePath={this.props.noticeInformation.noticeImgPath} />
                                                    </div>
                                                </div>
                                                <FileUpload
                                                    chooseLabel={this.state.uploadImage.contentName ? this.state.uploadImage.contentName : this.props.noticeInformation.noticeImgName}
                                                    mode="basic"
                                                    maxFileSize={1000000}
                                                    onSelect={this.onLoadPic.bind(this)}
                                                    auto={true}
                                                />

                                            </div>
                                        </div>
                                        <span className="error-message">{this.state.noticeSubmitError.noticeImgContent}</span>
                                    </div>

                                <div className="p-col-12 p-xl-12"></div>
                                
                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                    <Button
                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                        label="Discard"
                                        icon="fas fa-times"
                                        onClick={this.props.turnOffDialog}
                                    />

                                    <Button
                                        label="Update"
                                        icon="fas fa-check"
                                        className="p-button-primary nw-button nw-button-multiple"
                                        onClick={(e) => this.updateNoticeHandler(e, this.props)}
                                    />
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                </div>
            // </div>

        );
    }
}