import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from "react-google-charts";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { CHOOSE_COLOR } from '../../common/CommonVariables';


let maxDate = new Date();
let submitedTokenArray=[]
let submitedTokenSum=0, totalCallSum=0, personWisePendingTokenSum=0, personWiseSolvedTokenSum=0, personWiseInboundCallSum=0, personWiseOutboundCallSum=0,
    applicationWiseTokenSum=0, durationWiseTokenSum=0, emModuleWiseTokenSum=0, nwPointWiseTokenSum=0, inboundCallSum=0, outboundCallSum=0 ;
export class CRMDashboardTokenAndCall extends Component {

    constructor() {
        super();
        this.state = {
            transactionInfoDataTableValue:[
                {type: "deposit", amount: 50000000000},
                {type: "deposit", amount: 50000000000},
                {type: "deposit", amount: 50000000000},
                {type: "deposit", amount: 50000000000},
                {type: "deposit", amount: 50000000000}
            ],
            dateRangeParamFormat: {
                requestStartDate: "",
                requestEndDate: "",
            },
            dateRangeObj: {
                startDate: "",
                endDate: "",
            },
            // submitedToken: [
            //     {"Title": "Value"}

            // ],
            dashboardViewObj:{
                submitedToken:[
                    {"Title": "Value"}
                ],
                totalCall:[
                    {"Title": "Value"}
                ],
                personWisePendingToken:[
                    {"Title": "Value"}
                ],
                personWiseSolvedToken:[
                    {"Title": "Value"}
                ],
                personWiseInboundCall:[
                    {"Title": "Value"}
                ],
                personWiseOutboundCall:[
                    {"Title": "Value"}
                ],
                applicationWiseToken:[
                    {"Title": "Value"}
                ],
                emModuleWiseToken:[
                    {"Title": "Value"}
                ],
                nwPointWiseToken:[
                    {"Title": "Value"}
                ],
                inboundCall:[
                    {"Title": "Value"}
                ],
                outboundCall:[
                    {"Title": "Value"}
                ],
                durationWiseToken:[
                    {"Title": "Value"}
                ],
            },
            dashboardViewObjMain:{},
            colorArray: [
                { color: '#FFD86D' },
                { color: '#7178AC' },
                { color: '#FF9191' },
                { color: '#85C2FF' },
                { color: '#65EEE1' },
                { color: '#EED86D' },
                { color: '#7168AC' },
                { color: '#FC9121' },
                { color: '#25C2FF' },
                { color: '#65EEF1' },
            ],
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            errors: {}

        }

        this.CRMPointService = new CRMPointService();
    }

    componentWillMount() {
        // this.getDashboardTokenAndCallDetails();
    }

    getDashboardTokenAndCallDetails = () => {
        let { dateRangeObj, dashboardViewObj } = this.state
        this.setState({ topProgressBar: true, errorMsgVisible: false });

        console.log("dateRangeObj", dateRangeObj);
        

        this.CRMPointService.fetchDashboardTokenAndCallDetails(dateRangeObj)
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        this.setState({ dashboardViewObjMain: body });

                        let columnsHeader = ["Title", "Value"];

                        let submitedTokenArray=[]
                        let submitedToken = Object.entries(body.submitedToken)
                        // let columnsHeaderSubmitedToken = ["Title", "Value"];
                        submitedTokenArray.push(columnsHeader, ...submitedToken);
                        submitedTokenSum = submitedToken.map(item => item[1]).reduce((a, b) => (a + b));

                        dashboardViewObj.submitedToken = submitedTokenArray
                        // dashboardViewObj.submitedTokenSum = submitedTokenSum

                        let totalCallArray=[]
                        let totalCall = Object.entries(body.submitedCall)
                        // let columnsHeaderTotalCall = ["Title", "Value"];
                        totalCallArray.push(columnsHeader, ...totalCall);
                        totalCallSum = totalCall.map(item => item[1]).reduce((a, b) => (a + b));

                        dashboardViewObj.totalCall = totalCallArray
                        // dashboardViewObj.totalCallSum = totalCallSum

                        let personWisePendingTokenArray=[]
                        let personWisePendingToken = Object.entries(body.personWisePendingToken)
                        personWisePendingTokenArray.push(columnsHeader, ...personWisePendingToken);
                        personWisePendingTokenSum = personWisePendingToken.map(item => item[1]).reduce((a, b) => (a + b));
                        dashboardViewObj.personWisePendingToken = personWisePendingTokenArray

                        let personWiseSolvedTokenArray=[]
                        let personWiseSolvedToken = Object.entries(body.personWiseSolvedToken)
                        personWiseSolvedTokenArray.push(columnsHeader, ...personWiseSolvedToken);
                        personWiseSolvedTokenSum = personWiseSolvedToken.map(item => item[1]).reduce((a, b) => (a + b));
                        dashboardViewObj.personWiseSolvedToken = personWiseSolvedTokenArray

                        let personWiseInboundCallArray=[]
                        let personWiseInboundCall = Object.entries(body.personWiseInboundCalls)
                        personWiseInboundCallArray.push(columnsHeader, ...personWiseInboundCall);
                        personWiseInboundCallSum = personWiseInboundCall.map(item => item[1]).reduce((a, b) => (a + b));
                        dashboardViewObj.personWiseInboundCall = personWiseInboundCallArray

                        let personWiseOutboundCallArray=[]
                        let personWiseOutboundCall = Object.entries(body.personWiseOutboundCalls)
                        personWiseOutboundCallArray.push(columnsHeader, ...personWiseOutboundCall);
                        if(Object.entries(body.personWiseOutboundCalls).length > 0 ){
                            personWiseOutboundCallSum = personWiseOutboundCall.map(item => item[1]).reduce((a, b) => (a + b));
                        }
                        dashboardViewObj.personWiseOutboundCall = personWiseOutboundCallArray

                        let applicationWiseTokenArray=[]
                        let applicationWiseToken = Object.entries(body.applicationWiseTokens)
                        applicationWiseTokenArray.push(columnsHeader, ...applicationWiseToken);
                        applicationWiseTokenSum = applicationWiseToken.map(item => item[1]).reduce((a, b) => (a + b));
                        dashboardViewObj.applicationWiseToken = applicationWiseTokenArray

                        let emModuleWiseTokenArray=[]
                        let emModuleWiseToken = Object.entries(body.emModuleWiseTokens)
                        emModuleWiseTokenArray.push(columnsHeader, ...emModuleWiseToken);
                        emModuleWiseTokenSum = emModuleWiseToken.map(item => item[1]).reduce((a, b) => (a + b));
                        dashboardViewObj.emModuleWiseToken = emModuleWiseTokenArray

                        let nwPointWiseTokenArray=[]
                        let nwPointWiseToken = Object.entries(body.nwPointWiseTokens)
                        nwPointWiseTokenArray.push(columnsHeader, ...nwPointWiseToken);
                        nwPointWiseTokenSum = nwPointWiseToken.map(item => item[1]).reduce((a, b) => (a + b));
                        dashboardViewObj.nwPointWiseToken = nwPointWiseTokenArray

                        let durationWiseTokenArray=[]
                        let durationWiseToken = Object.entries(body.durationWiseTokens)
                        durationWiseTokenArray.push(columnsHeader, ...durationWiseToken);
                        durationWiseTokenSum = durationWiseToken.map(item => item[1]).reduce((a, b) => (a + b));
                        dashboardViewObj.durationWiseToken = durationWiseTokenArray

                        let inboundCallArray=[]
                        let inboundCall = Object.entries(body.inboundCalls)
                        inboundCallArray.push(columnsHeader, ...inboundCall);
                        if(Object.entries(body.inboundCalls).length > 0 ){
                            inboundCallSum = inboundCall.map(item => item[1]).reduce((a, b) => (a + b));
                        }
                        dashboardViewObj.inboundCall = inboundCallArray

                        let outboundCallArray=[]
                        let outboundCall = Object.entries(body.outboundCalls)
                        outboundCallArray.push(columnsHeader, ...outboundCall);
                        if(Object.entries(body.outboundCalls).length > 0 ){
                            outboundCallSum = outboundCall.map(item => item[1]).reduce((a, b) => (a + b));
                        }
                        dashboardViewObj.outboundCall = outboundCallArray



                        this.setState({ dashboardViewObjMain: body, dashboardViewObj }); //nwPointWiseToken
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
                console.log("error", error);
                
            });

    }

    instituteSubmitTokenBody = (rowData) =>{
        return <div class="nw-datatable-dataview-small-panel">
            {/* <div> */}
                <h4>{rowData.name}</h4>
                {/* <div>:</div>
                <div> 802</div> */}
            {/* </div> */}
            {/* <div> */}
                <div>{rowData.id}</div>
                {/* <div>:</div>
                <div> No</div> */}
            {/* </div> */}

            {/* <div className="circle-panel">
                { rowData.count }
            </div> */}
        </div>
    }
    
    instituteSubmitCountBody = (rowData) =>{

        console.log("rowData", rowData.id.length);
       
        return <div class="nw-datatable-dataview-small-panel">
            <div className={ rowData.id.length === 10 ? "circle-panel primary-color-bg":'circle-panel primary-color-dark-bg'}>
                { rowData.count }
            </div>
        </div>
    }

    inboundCallContactsBody = (rowData) =>{
        return <div class="nw-datatable-dataview-small-panel">
            <h4><i class="fas fa-mobile-alt text-info"></i> {rowData.contactNo}</h4>
        </div>
    }

    inboundCallCountBody = (rowData) =>{
        return <div class="nw-datatable-dataview-small-panel">
            <div className="rectangle-panel">
                { rowData.count }
            </div>
        </div>
    }

    onChangeDate = (e) => {
        let { dateRangeObj } = this.state;
        dateRangeObj[e.target.name] = e.target.value;
        this.setState({ dateRangeObj });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onSubmitHandler = () => {
        if(this.onSubmitErrorHandler()) {
        this.getDashboardTokenAndCallDetails();
        }
    }

    onSubmitErrorHandler = () => {
        let { errors, dateRangeObj } = this.state;
        let formIsValid = true;

        if (dateRangeObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start date can't left empty.";
        }

        if (dateRangeObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End date can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;

    }





    render() {
        let { colorArray, submitedToken, dashboardViewObj, dashboardViewObjMain, dateRangeObj, topProgressBar, errorMsgVisible, errorMsgBody } = this.state

        console.log("dashboardViewObjMain", dashboardViewObjMain);
        console.log("dashboardViewObj", dashboardViewObj);
        
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section crm-dashboard">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            name='startDate'
                                            value={dateRangeObj.startDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            name='endDate'
                                            value={dateRangeObj.endDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            dateFormat='dd/mm/yy'
                                            yearRange="2010:2030"
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                            </div>
                            
                        </div>

                        <div className="p-col-12">
                            {/* <div className="panel-box p-0">
                                <div className="panel-box-header">
                                    <h1>Payable Information</h1>
                                </div>
                            </div> */}
                        </div>

                        <div className="p-col-12">
                            <div className="p-grid p-col-12 p-col-nogutter">
                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Total Submit Token">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.submitedToken}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: {
                                                                    0: { color: CHOOSE_COLOR.SUCCESS },
                                                                    1: { color: CHOOSE_COLOR.INFO },
                                                                    2: { color: CHOOSE_COLOR.WARNING },
                                                                },
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Token</span><br />
                                                    <span className="chart-box-title">{ submitedTokenSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: CHOOSE_COLOR.SUCCESS }}></span><p>Solved</p></td>
                                                                    <td><p>{ dashboardViewObjMain.submitedToken && dashboardViewObjMain.submitedToken.solved }</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: CHOOSE_COLOR.INFO }}></span><p>Assigned</p></td>
                                                                    <td><p>{ dashboardViewObjMain.submitedToken && dashboardViewObjMain.submitedToken.assigned }</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: CHOOSE_COLOR.WARNING }}></span><p>Pending</p></td>
                                                                    <td><p>{ dashboardViewObjMain.submitedToken && dashboardViewObjMain.submitedToken.pending }</p></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Total Call">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.totalCall}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: {
                                                                    0: { color: CHOOSE_COLOR.SUCCESS },
                                                                    1: { color: CHOOSE_COLOR.DANGER },
                                                                },
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Call</span><br />
                                                    <span className="chart-box-title">{ totalCallSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: CHOOSE_COLOR.SUCCESS }}></span><p>Inbound</p></td>
                                                                    <td><p>{ dashboardViewObjMain.submitedCall && dashboardViewObjMain.submitedCall.inbound }</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: CHOOSE_COLOR.DANGER }}></span><p>Outbound</p></td>
                                                                    <td><p>{ dashboardViewObjMain.submitedCall && dashboardViewObjMain.submitedCall.outbound }</p></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>





                                
                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>

                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-1">
                                            <i class="fas fa-university"></i>
                                        </div>
                                        <div className="info-text">
                                            <h4 class="font-size-50">{ dashboardViewObjMain.tokenGeneralMap && dashboardViewObjMain.tokenGeneralMap.tokenSubmittedInstitute }</h4>
                                            {/* <span>BDT</span> */}
                                            <p>Token Submitted Institute</p>
                                        </div>
                                        <div className="info-button info-btn-1">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-2">
                                            <i class="fas fa-user-tie"></i>
                                        </div>
                                        <div className="info-text">
                                            <h4 class="font-size-50">{ dashboardViewObjMain.tokenGeneralMap && dashboardViewObjMain.tokenGeneralMap.tokenSubmittedNetiId }</h4>
                                            {/* <span>BDT</span> */}
                                            <p>Token Submitted Neti ID</p>
                                        </div>
                                        <div className="info-button info-btn-1">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-3">
                                            <i class="fas fa-download"></i>
                                        </div>
                                        <div className="info-text">
                                            <h4 class="font-size-50">{ dashboardViewObjMain.tokenGeneralMap && dashboardViewObjMain.tokenGeneralMap.inboundTotalContact }</h4>
                                            {/* <span>BDT</span> */}
                                            <p>Inbound Total Contact</p>
                                        </div>
                                        <div className="info-button info-btn-1">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-4">
                                            <i class="fas fa-upload"></i>
                                        </div>
                                        <div className="info-text">
                                            <h4 class="font-size-50">{ dashboardViewObjMain.tokenGeneralMap && dashboardViewObjMain.tokenGeneralMap.outboundTotalContact }</h4>
                                            {/* <span>BDT</span> */}
                                            <p>Outbound Total Contact</p>
                                        </div>
                                        <div className="info-button info-btn-1">
                                            <i class="fas fa-info-circle"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>


                                
                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Person Wise Assigned Token" className="bg-pending">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.personWisePendingToken}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: colorArray,
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Token</span><br />
                                                    <span className="chart-box-title">{ personWisePendingTokenSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                {
                                                                    dashboardViewObj.personWisePendingToken.map((item, index) =>
                                                                        index != 0 ?
                                                                        <tr>
                                                                            <td><span style={{ background: colorArray[index-1] ? colorArray[index-1].color: 'gray' }}></span><p>{ item[0] }</p></td>
                                                                            <td><p>{ item[1] }</p></td>
                                                                        </tr>: null
                                                                    )
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Person Wise Solved Token" className="bg-success">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.personWiseSolvedToken}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: colorArray,
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Token</span><br />
                                                    <span className="chart-box-title">{ personWiseSolvedTokenSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                {
                                                                    dashboardViewObj.personWiseSolvedToken.map((item, index) =>
                                                                        index != 0 ?
                                                                        <tr>
                                                                            <td><span style={{ background: colorArray[index-1] ? colorArray[index-1].color: 'gray' }}></span><p>{ item[0] }</p></td>
                                                                            <td><p>{ item[1] }</p></td>
                                                                        </tr>: null
                                                                    )
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Person Wise Inbound Call" className="bg-success">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.personWiseInboundCall}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: colorArray,
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Call</span><br />
                                                    <span className="chart-box-title">{ personWiseInboundCallSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                {
                                                                    dashboardViewObj.personWiseInboundCall.map((item, index) =>
                                                                        index != 0 ?
                                                                        <tr>
                                                                            <td><span style={{ background: colorArray[index-1] ? colorArray[index-1].color: 'gray' }}></span><p>{ item[0] }</p></td>
                                                                            <td><p>{ item[1] }</p></td>
                                                                        </tr>: null
                                                                    )
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Person Wise Outbound Call" className="bg-danger">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.personWiseOutboundCall}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: colorArray,
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Call</span><br />
                                                    <span className="chart-box-title">{ personWiseOutboundCallSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                {
                                                                    dashboardViewObj.personWiseOutboundCall.map((item, index) =>
                                                                        index != 0 ?
                                                                        <tr>
                                                                            <td><span style={{ background: colorArray[index-1] ? colorArray[index-1].color: 'gray' }}></span><p>{ item[0] }</p></td>
                                                                            <td><p>{ item[1] }</p></td>
                                                                        </tr>: null
                                                                    )
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Application Wise Token">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.applicationWiseToken}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: {
                                                                    0: { color: '#2e3192'},
                                                                    1: { color: '#0099ff'},
                                                                    2: { color: '#ffcc99'},
                                                                },
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Token</span><br />
                                                    <span className="chart-box-title">{ applicationWiseTokenSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                {/* {
                                                                    dashboardViewObj.applicationWiseToken.map((item, index) =>
                                                                        index != 0 ? */}
                                                                        {/* <tr>
                                                                            <td><span style={{ background: ''}}></span><p>{ item[0] }</p></td>
                                                                            <td><p>{ item[1] }</p></td>
                                                                        </tr> */}
                                                                        {/* : null
                                                                    ) */}
                                                                {/* } */}
                                                                <tr>
                                                                    <td><span style={{ background: '#2e3192'}}></span><p>Eduman</p></td>
                                                                    <td><p>{ dashboardViewObjMain.applicationWiseTokens && dashboardViewObjMain.applicationWiseTokens.Eduman }</p></td>
                                                                </tr>

                                                                <tr>
                                                                    <td><span style={{ background: '#0099ff'}}></span><p>Netiworld</p></td>
                                                                    <td><p>{ dashboardViewObjMain.applicationWiseTokens && dashboardViewObjMain.applicationWiseTokens.Netiworld }</p></td>
                                                                </tr>

                                                                <tr>
                                                                    <td><span style={{ background: '#ffcc99'}}></span><p>CRM</p></td>
                                                                    <td><p>{ dashboardViewObjMain.applicationWiseTokens && dashboardViewObjMain.applicationWiseTokens.CRM }</p></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Eduman Module Wise Token" className="bg-eduman">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.emModuleWiseToken}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: colorArray,
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Token</span><br />
                                                    <span className="chart-box-title">{ emModuleWiseTokenSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                {
                                                                    dashboardViewObj.emModuleWiseToken.map((item, index) =>
                                                                        index != 0 ?
                                                                        <tr>
                                                                            <td><span style={{ background: colorArray[index-1] ? colorArray[index-1].color: 'gray' }}></span><p>{ item[0] }</p></td>
                                                                            <td><p>{ item[1] }</p></td>
                                                                        </tr>: null
                                                                    )
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Netiworld Point Wise Token">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.nwPointWiseToken}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: colorArray,
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Token</span><br />
                                                    <span className="chart-box-title">{ nwPointWiseTokenSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                {
                                                                    dashboardViewObj.nwPointWiseToken.map((item, index) =>
                                                                        index != 0 ?
                                                                        <tr>
                                                                            <td><span style={{ background: colorArray[index-1] ? colorArray[index-1].color: 'gray' }}></span><p>{ item[0] }</p></td>
                                                                            <td><p>{ item[1] }</p></td>
                                                                        </tr>: null
                                                                    )
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Token Solved Duration">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.durationWiseToken}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: colorArray,
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Token</span><br />
                                                    <span className="chart-box-title">{ durationWiseTokenSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                {
                                                                    dashboardViewObj.durationWiseToken.map((item, index) =>
                                                                        index != 0 ?
                                                                        <tr>
                                                                            <td><span style={{ background: colorArray[index-1] ? colorArray[index-1].color: 'gray' }}></span><p>{ item[0] }</p></td>
                                                                            <td><p>{ item[1] }</p></td>
                                                                        </tr>: null
                                                                    )
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Inbound Call Purpose" className="bg-success">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.inboundCall}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: colorArray,
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Call</span><br />
                                                    <span className="chart-box-title">{ inboundCallSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                {
                                                                    dashboardViewObj.inboundCall.map((item, index) =>
                                                                        index != 0 ?
                                                                        <tr>
                                                                            <td><span style={{ background: colorArray[index-1] ? colorArray[index-1].color: 'gray' }}></span><p>{ item[0] }</p></td>
                                                                            <td><p>{ item[1] }</p></td>
                                                                        </tr>: null
                                                                    )
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Outbound Call Purpose" className="bg-danger">
                                        <div className="p-grid p-col-nogutter">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={226}
                                                            height={226}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={dashboardViewObj.outboundCall}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                legend: 'none',
                                                                slices: colorArray,
                                                            }}
                                                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                            render={({ renderChart }) => {
                                                                return (
                                                                    <div>{renderChart()}</div>
                                                                )
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Call</span><br />
                                                    <span className="chart-box-title">{ outboundCallSum }</span>
                                                </div>
                                            </div>
                                            
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                {
                                                                    dashboardViewObj.outboundCall.map((item, index) =>
                                                                        index != 0 ?
                                                                        <tr>
                                                                            <td><span style={{ background: colorArray[index-1] ? colorArray[index-1].color: 'gray' }}></span><p>{ item[0] }</p></td>
                                                                            <td><p>{ item[1] }</p></td>
                                                                        </tr>: null
                                                                    )
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                



                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>




                                
                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Top 10 Institute Submit Token" className="bg-eduman">
                                        <div className="nw-datatable-detail-view">
                                            <DataTable
                                                value={ dashboardViewObjMain.top10TokenSubmittedInstitues }
                                            >
                                                <Column field="name" body={ this.instituteSubmitTokenBody } />
                                                <Column field="count" body={ this.instituteSubmitCountBody }/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Top 10 Neti ID Submit Token">
                                        <div className="nw-datatable-detail-view">
                                            <DataTable
                                                value={ dashboardViewObjMain.top10TokenSubmittedNetiIDs }
                                            >
                                                <Column field="name" body={ this.instituteSubmitTokenBody } />
                                                <Column field="count" body={ this.instituteSubmitCountBody }/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>
                                <div className="p-col-12"></div>

                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Top 10 Contact For Inbound Call" className="bg-success">
                                        <div className="nw-datatable-detail-view">
                                            <DataTable
                                                value={ dashboardViewObjMain.top10InboundCallContacts }
                                            >
                                                <Column field="name" body={ this.inboundCallContactsBody } />
                                                <Column field="count" body={ this.inboundCallCountBody }/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>
                                
                                <div className="p-col-12 p-xl-6 p-md-6 p-sm-12 dashboard-card-view">
                                    <Card header="Top 10 Contact For Outbound Call" className="bg-danger">
                                        <div className="nw-datatable-detail-view">
                                            <DataTable
                                                value={ dashboardViewObjMain.top10OutboundCallContacts }
                                            >
                                                <Column field="name" body={ this.inboundCallContactsBody } />
                                                <Column field="count" body={ this.inboundCallCountBody }/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>





                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}