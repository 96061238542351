import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';
import { UserCoreUrlInfoService } from '../UserCoreUrlInfoService';

export class DwsService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
        this.userCoreUrlInfoService = new UserCoreUrlInfoService();
        this.headers = {
            'Content-Type': 'application/json'
        }
    }

     getUrlInfoFromLocalStorage() {
        let urlInfo = localStorage.getItem('dwsUrlInfo');
        if (urlInfo != null) {
            console.log('From Local', urlInfo);

            return urlInfo;
        } else {
            this.userCoreUrlInfoService.fetchCmsId()
                .then((res) => {
                    if (res.status == 200) {
                        return res.json().then((body) => {
                            console.log('From Server body', body);
                            localStorage.setItem('dwsUrlInfo', body.cmsId);
                            urlInfo = body.cmsId
                            console.log(urlInfo)
                            return urlInfo;
                        })
                    }
                })

        }
    }

    addNewSpeech(createNewSpeech) {
        let uri = this.baseURIHolder.getDWS() + '/speech/new';
        return this.Auth.postFetch(uri, createNewSpeech)
            .catch((error) => console.log('error', error));
    }

    updateSpeech(speechInfo) {
        let uri = this.baseURIHolder.getDWS() + '/speech/update';
        return this.Auth.putFetch(uri, speechInfo)
            .catch((error) => console.log('error', error));
    }

    updateSpeechList(speechListInfo) {
        let uri = this.baseURIHolder.getDWS() + '/speech/update/list';
        return this.Auth.putFetch(uri, speechListInfo)
            .catch((error) => console.log('error', error));
    }

    getSpeechList(urlinfoID, welcomeSpeechStatus) {
        let uri = this.baseURIHolder.getDWS() + '/speech/get/speeches?urlInfoID=' + urlinfoID + '&welcomeSpeechStatus=' + welcomeSpeechStatus;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createNewLink(createLink) {
        let uri = this.baseURIHolder.getDWS() + '/link/new';
        return this.Auth.postFetch(uri, createLink)
            .catch((error) => console.log('error', error));
    }

    getTopLinkList(listInfo, urlID) {
        let uri = this.baseURIHolder.getDWS() + '/link/list/toplink?topLink=' + urlID;
        return this.Auth.postFetch(uri, listInfo)
            .catch((error) => console.log('error', error));
    }

    updateTopLinkList(updateListInfo) {
        let uri = this.baseURIHolder.getDWS() + '/link/update/list';
        return this.Auth.putFetch(uri, updateListInfo)
            .catch((error) => console.log('error', error));
    }

    updateImportantLink(linkInfo) {
        let uri = this.baseURIHolder.getDWS() + '/link/update';
        return this.Auth.putFetch(uri, linkInfo)
            .catch((error) => console.log('error', error));
    }

    getNoticeList(urlId) {
        let uri = this.baseURIHolder.getDWS() + '/notice/get/list?urlId=' + urlId;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getTopNoticeList(urlId, topNotice) {
        let uri = this.baseURIHolder.getDWS() + '/notices/get/by?topNotice=' + topNotice;
        return this.Auth.postFetch(uri, urlId)
            .catch((error) => console.log('error', error));
    }

    updateTopNoticeList(topNoticeInfo) {
        let uri = this.baseURIHolder.getDWS() + '/notice/update/list';
        return this.Auth.putFetch(uri, topNoticeInfo)
            .catch((error) => console.log('error', error));
    }

    addNewNotice(createNotice) {
        let uri = this.baseURIHolder.getDWS() + '/notice/new';
        return this.Auth.postFetch(uri, createNotice)
            .catch((error) => console.log('error', error));
    }

    updateNotice(noticeInfo) {
        let uri = this.baseURIHolder.getDWS() + '/notice/update';
        return this.Auth.putFetch(uri, noticeInfo)
            .catch((error) => console.log('error', error));
    }

    createNewDownloadFile(createDownloadFile) {
        let uri = this.baseURIHolder.getDWS() + '/download-corner/new';
        return this.Auth.postFetch(uri, createDownloadFile)
            .catch((error) => console.log('error', error));
    }

    getTopListofDownloadFile(listFetch) {
        let uri = this.baseURIHolder.getDWS() + '/download-corner/list';
        return this.Auth.postFetch(uri, listFetch)
            .catch((error) => console.log("error", error));
    }

    updateDownloadFile(updateDownloadFile) {
        let uri = this.baseURIHolder.getDWS() + '/download-corner/update';
        return this.Auth.putFetch(uri, updateDownloadFile)
            .catch((error) => console.log("error", error));
    }

    deleteDownloadFile(fileID) {
        let uri = this.baseURIHolder.getDWS() + '/download-corner/delete?id=' + fileID;
        return this.Auth.putFetch(uri)
            .catch((error) => console.log("error", error));
    }

    createNewDwsMember(memberInfo) {
        let uri = this.baseURIHolder.getDWS() + '/members/new';
        return this.Auth.postFetch(uri, memberInfo)
            .catch((error) => console.log("error", error));
    }

    getDwsMemberUrlInfoList(memberInfoList) {
        let uri = this.baseURIHolder.getDWS() + '/members';
        return this.Auth.postFetch(uri, memberInfoList)
            .catch((error) => console.log("error", error));
    }

    updateDwsMemberInfo(updateMember) {
        let uri = this.baseURIHolder.getDWS() + '/members/update';
        return this.Auth.putFetch(uri, updateMember)
            .catch(error => console.log('error', error));
    }

    studentFeeInformationSubmit(feeObject) {
        let uri = this.baseURIHolder.getDWS() + '/feeinfo/new';
        return this.Auth.postFetch(uri, feeObject)
            .catch((error) => console.log('error', error));
    }

    fetchStudentFeeList(defaultObject) {
        let uri = this.baseURIHolder.getDWS() + '/feeinfo/get';
        return this.Auth.postFetch(uri, defaultObject)
            .catch((error) => console.log('error', error));
    }

    createNewDressCode(dressCodeObject) {
        let uri = this.baseURIHolder.getDWS() + '/dress/new';
        return this.Auth.postFetch(uri, dressCodeObject)
            .catch((error) => console.log('error', error));
    }

    fetchDressCodeList(urlID) {
        let uri = this.baseURIHolder.getDWS() + '/dress/by/url?urlID=' + urlID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getDressInfoByID(dressID) {
        let uri = this.baseURIHolder.getDWS() + '/dress/by/id?id=' + dressID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createNewSeat(dressCodeObject) {
        let uri = this.baseURIHolder.getDWS() + '/seat-info/new';
        return this.Auth.postFetch(uri, dressCodeObject)
            .catch((error) => console.log('error', error));
    }

    fetchSeatInfoList(defaultObject) {
        let uri = this.baseURIHolder.getDWS() + '/seat-info/get/by/url';
        return this.Auth.postFetch(uri, defaultObject)
            .catch((error) => console.log('error', error));
    }

    createDwsSyllabusInfo(syllabusInfo) {
        let uri = this.baseURIHolder.getDWS() + '/syllabus-info/add';
        return this.Auth.postFetch(uri, syllabusInfo)
            .catch((error) => console.log("error", error));
    }

    getDwsSyllabusInfoList(syllabusInfoList) {
        let uri = this.baseURIHolder.getDWS() + '/syllabus-info/list/by/url';
        return this.Auth.postFetch(uri, syllabusInfoList)
            .catch((error) => console.log("error", error));
    }

    createDwsEventLog(eventLogInfo) {
        let uri = this.baseURIHolder.getDWS() + '/event-log/new';
        return this.Auth.postFetch(uri, eventLogInfo)
            .catch((error) => console.log("error", error));
    }

    getDwsEventLogList(eventLog) {
        let uri = this.baseURIHolder.getDWS() + '/event-log/list';
        return this.Auth.postFetch(uri, eventLog)
            .catch((error) => console.log("error", error));
    }

    getDwsGlobalBooks(urlid) {
        let uri = this.baseURIHolder.getDWS() + '/global/approved/book/list/?urlid=' + urlid;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createDwsNewBookRequest(bookRequest) {
        let uri = this.baseURIHolder.getDWS() + '/book/global/add';
        return this.Auth.postFetch(uri, bookRequest)
            .catch((error) => console.log("error", error));
    }

    createNewPhotoGallery(galleryInfo) {
        let uri = this.baseURIHolder.getDWS() + '/photo-gallery/add';
        return this.Auth.postFetch(uri, galleryInfo)
            .catch((error) => console.log('error', error));
    }

    updatePhoto(photoInfo) {
        let uri = this.baseURIHolder.getDWS() + '/photo-gallery/update';
        return this.Auth.putFetch(uri, photoInfo)
            .catch((error) => console.log('error', error));
    }

    fetchPhotolist(photoInfo, listType) {
        let uri = this.baseURIHolder.getDWS() + '/photo-gallerys/by/url?photoType=' + listType;
        return this.Auth.postFetch(uri, photoInfo)
            .catch((error) => console.log('error', error));
    }

    createNewAboutUsInfo(aboutUsObject) {
        let uri = this.baseURIHolder.getDWS() + '/aboutus/new';
        return this.Auth.postFetch(uri, aboutUsObject)
            .catch((error) => console.log('error', error));
    }

    fetchAboutUsList(aboutUsUrlId) {
        let uri = this.baseURIHolder.getDWS() + '/aboutus/by/url?urlID=' + aboutUsUrlId;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateAboutUsInfo(aboutUsObject) {
        let uri = this.baseURIHolder.getDWS() + '/aboutus/update';
        return this.Auth.putFetch(uri, aboutUsObject)
            .catch((error) => console.log('error', error));
    }

    updateFeeInfo(feeInfoObject) {
        let uri = this.baseURIHolder.getDWS() + '/feeinfo/update';
        return this.Auth.putFetch(uri, feeInfoObject)
            .catch((error) => console.log('error', error));
    }
    saveDwsMenuConfigInfo(menuConfigInfo) {
        let uri = this.baseURIHolder.getDWS() + '/menu-config/add';
        return this.Auth.postFetch(uri, menuConfigInfo)
            .catch((error) => console.log("error", error));
    }

    updateDressInfo(dressInfoObject) {
        let uri = this.baseURIHolder.getDWS() + '/dress/update';
        return this.Auth.putFetch(uri, dressInfoObject)
            .catch((error) => console.log('error', error));
    }

    updateDwsApplicationTheme(themeName, urlId) {
        let uri = this.baseURIHolder.getUser() + '/url-info/edit/url/theme?theme=' + themeName + '&urlId=' + urlId;
        return this.Auth.putFetch(uri)
            .catch((error) => console.log('error', error));
    }

    saveDwsMenuConfigInfo(menuConfigInfo) {
        let uri = this.baseURIHolder.getDWS() + '/menu-config/add';
        return this.Auth.postFetch(uri, menuConfigInfo)
            .catch((error) => console.log("error", error));
    }

    updateSeatInfo(seatInfoObject) {
        let uri = this.baseURIHolder.getDWS() + '/seat-info/update';
        return this.Auth.putFetch(uri, seatInfoObject)
            .catch((error) => console.log('error', error));
    }

    updateSyllabusInfo(feeInfoObject) {
        let uri = this.baseURIHolder.getDWS() + '/syllabus-info/update';
        return this.Auth.putFetch(uri, feeInfoObject)
            .catch((error) => console.log('error', error));
    }

    updateEventInfo(eventInfoObject) {
        let uri = this.baseURIHolder.getDWS() + '/event-log/update';
        return this.Auth.putFetch(uri, eventInfoObject)
            .catch((error) => console.log('error', error));
    }

    saveDwsBookInfo(selectedBookInfo) {
        let uri = this.baseURIHolder.getDWS() + '/book/add';
        return this.Auth.postFetch(uri, selectedBookInfo)
            .catch((error) => console.log("error", error));
    }

    deleteDownloadableFile(fileID) {
        let uri = this.baseURIHolder.getDWS() + '/download-corner/delete?id=' + fileID;
        return this.Auth.deleteFetch(uri)
            .catch((error) => console.log("error", error));
    }

    deleteMemberInfo(memberID) {
        let uri = this.baseURIHolder.getDWS() + '/members/delete?id=' + memberID;
        return this.Auth.deleteFetch(uri)
            .catch((error) => console.log("error", error));
    }

    disableEventInfo(eventID) {
        let uri = this.baseURIHolder.getDWS() + '/event-log/delete?id=' + eventID;
        return this.Auth.deleteFetch(uri)
            .catch((error) => console.log("error", error));
    }

    getMenuConfigByUrlID(UrlID) {
        let uri = this.baseURIHolder.getDWS() + `/menu-config/by/urlId?urlID=${UrlID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateMenuConfig(menuConfigInfo) {
        let uri = this.baseURIHolder.getDWS() + '/menu-config/update';
        return this.Auth.putFetch(uri, menuConfigInfo)
            .catch((error) => console.log('error', error));
    }

    dwsCoreURLMapping(urlMappingInfo) {
        let uri = this.baseURIHolder.getUser() + '/map/url';
        return this.Auth.postFetch(uri, urlMappingInfo)
            .catch((error) => console.log("error", error));
    }

    getURLMappedInstituteList(urlID) {
        let uri = this.baseURIHolder.getUser() + `/mapped/url/by/urlID?urlID=${urlID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEdumanInstituteInfoByID(instituteID, urlID) {
        let uri = this.baseURIHolder.getUser() + `/get/institute/by/instituteID?instituteID=${instituteID}&urlID=${urlID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    checkAdmisiaUrl(linkTitle, urlInfoID) {
        let uri = this.baseURIHolder.getDWS() + '/link/by/linktitle?linkTitle=' + linkTitle;
        return this.Auth.postFetch(uri, urlInfoID)
            .catch((error) => console.log('error', error));
    }

    getFeedBackInfo(feedbackStatus, urlInfoID) {
        // let uri = this.baseURIHolder.getDWS() + `/feedback/by-status?feedbackStatus=${feedbackStatus}&urlInfoID=${urlInfoID}`;
        let uri = this.baseURIHolder.getHostNetiCMS() + `/user/feedback/list?cmsId=${urlInfoID}&status=${feedbackStatus}`;
        console.log(uri);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateFeedbacknfo(feedbackInfoObject) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/user/feedback/update';
        return this.Auth.patchFetch(uri, feedbackInfoObject)
            .catch((error) => console.log('error', error));
    }

    getDwsPublishedBooks(urlInfoID) {
        let uri = this.baseURIHolder.getDWS() + '/books/by/url';
        return this.Auth.postFetch(uri, urlInfoID)
            .catch((error) => console.log('error', error));
    }

    //CMS ADMIN API

    // ************Kabir Vy************

    async getCmsIdFromLocalStorage() {
        let adminCmsId;
        adminCmsId = localStorage.getItem('adminCmsId');
        if (adminCmsId != null) {
            return adminCmsId;
        } else {
            await this.userCoreUrlInfoService.fetchCmsId()
                .then((res) => {
                    // console.log('getCmsIdFromLocalStorage', res);
                    if (res.status == 200) {
                        return res.json().then((body) => {
                            console.log('From Server body', body);
                            localStorage.setItem('adminCmsId', body.cmsId);
                            adminCmsId = body.cmsId

                            
                            return body.cmsId;
                        })
                    }
                })

            return adminCmsId

        }
    }

    getFetch(url) {
        const headers = this.headers;
        return fetch(url, { method: 'GET', headers });
    }

    postFetch(url, data) {
        const headers = this.headers;
        return fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(data) });
    }

    putFetch(url, data) {
        const headers = this.headers;
        return fetch(url, { method: 'PUT', headers: headers, body: JSON.stringify(data) });
    }

    deleteFetch(url) {
        const headers = this.headers;
        return fetch(url, { method: 'DELETE', headers });
    }

    downloadCornerGetByteImage(fileId) {
        var uri = this.baseURIHolder.getHostNetiCMS() + '/download/corner/file/find?fileId=' + fileId;
        return this.getFetch(uri);
    }

    cmsClassOrGroupList(type, urlId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/' + type + '-info/list?cmsId=' + urlId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    // cmsGroupList(urlId) {
    //     let uri = this.baseURIHolder.getHostNetiCMS() + '/group-info/list?cmsId=' + urlId;
    //     return this.getFetch(uri)
    //         .catch((error) => console.log('error', error));
    // }

    cmsSeatInfoList(urlId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/seat-info/list?cmsId=' + urlId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsInserAndUpdateSeat(dialogType, seatObject) {
        let submitUrl = '';
        let uri = this.baseURIHolder.getHostNetiCMS()

        if (dialogType == 'insert') {
            submitUrl = "/seat-info/save";
            return this.postFetch(uri + submitUrl, seatObject).catch((error) => console.log('error', error));
        }
        else {
            submitUrl = "/seat-info/update";
            return this.putFetch(uri + submitUrl, seatObject).catch((error) => console.log('error', error));
        }
    }

    cmsDeleteSeat(seatObject, urlId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + "/seat-info/delete?cmsId=" + urlId + "&seatId=" + seatObject.seatId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsDownloadCornerList(urlId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/download/corner/list?cmsId=' + urlId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsInserAndUpdateDownloadCorner(dialogType, seatObject) {
        let submitUrl = '';
        let uri = this.baseURIHolder.getHostNetiCMS()

        if (dialogType == 'insert') {
            submitUrl = "/download/corner/save";
            return this.postFetch(uri + submitUrl, seatObject).catch((error) => console.log('error', error));
        }
        else {
            submitUrl = "/download/corner/update";
            return this.putFetch(uri + submitUrl, seatObject).catch((error) => console.log('error', error));
        }
    }

    cmsDeleteDownloadCorner(fileObject, urlId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + "/download/corner/delete?cmsId=" + urlId + "&fileId=" + fileObject.fileId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsSpeechInfoList(urlId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/speech/info/list?cmsId=' + urlId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsInserAndUpdateSpeechInfo(dialogType, seatObject) {
        console.log('seatObject-test', seatObject);
        let submitUrl = '';
        let uri = this.baseURIHolder.getHostNetiCMS()

        if (dialogType == 'insert') {
            submitUrl = "/speech/info/save";
            return this.postFetch(uri + submitUrl, seatObject).catch((error) => console.log('error', error));
        }
        else {
            submitUrl = "/speech/info/update";
            return this.putFetch(uri + submitUrl, seatObject).catch((error) => console.log('error', error));
        }
    }

    cmsDeleteSpeechInfo(fileObject, urlId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + "/speech/info/delete?cmsId=" + urlId + "&speechId=" + fileObject.speechId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsEventInfoList(urlId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/event/log/list?cmsId=' + urlId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsInserAndUpdateEventInfo(dialogType, eventObject) {
        let submitUrl = '';
        let uri = this.baseURIHolder.getHostNetiCMS()

        if (dialogType == 'insert') {
            submitUrl = "/event/log/save";
            return this.postFetch(uri + submitUrl, eventObject).catch((error) => console.log('error', error));
        }
        else {
            submitUrl = "/event/log/update";
            return this.putFetch(uri + submitUrl, eventObject).catch((error) => console.log('error', error));
        }
    }

    cmsDeleteEventInfo(fileObject, urlId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + "/event/log/delete?cmsId=" + urlId + "&eventId=" + fileObject.eventId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    // *************Sobhan***************
    getCmsSyllabusInfo(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/syllabus-info/list?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsSaveSyllabusInfo(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/syllabus-info/save';
        return this.postFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsUpdateSyllabusInfo(updateObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/syllabus-info/update';
        return this.putFetch(uri, updateObj)
            .catch((error) => console.log('error', error));
    }

    cmsDeleteSyllabusInfo(syllabusId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/syllabus-info/delete' + '?syllabusId=' + syllabusId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getCmsEnableNoticeInfo(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/notice/info/enable/list?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getCmsDisableNoticeInfo(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/notice/info/expired-disable-enable/list?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsSaveNoticeInfo(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/notice/info/save';
        return this.postFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsUpdateNoticeInfo(updateObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/notice/info/update';
        return this.putFetch(uri, updateObj)
            .catch((error) => console.log('error', error));
    }

    getCmsNoticeFileContent(noticeId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/notice/info/file/find?noticeId=${noticeId}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getClassList(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/class-info/list?cmsId=${cmsId}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsSaveClass(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/class-info/save';
        return this.postFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsUpdateClass(updateObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/class-info/update';
        return this.putFetch(uri, updateObj)
            .catch((error) => console.log('error', error));
    }

    cmsDeleteClass(classId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/class-info/delete' + '?classId=' + classId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getGroupList(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/group-info/list?cmsId=${cmsId}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsSaveGroup(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/group-info/save';
        return this.postFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsUpdateGroup(updateObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/group-info/update';
        return this.putFetch(uri, updateObj)
            .catch((error) => console.log('error', error));
    }

    cmsDeleteGroup(groupId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/group-info/delete' + '?groupId=' + groupId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getBookList(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/book/info/list?cmsId=${cmsId}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsSaveBook(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/book/info/save';
        return this.postFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsUpdateBookInfo(updateObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/book/info/update';
        return this.putFetch(uri, updateObj)
            .catch((error) => console.log('error', error));
    }

    cmsDeleteBook(bookId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/book/info/delete' + '?bookId=' + bookId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getNetiCMSApprovedList(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/cms-eduman-mapping/list?cmsId=${cmsId}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEMInstituteInfoByInstituteID(instituteID) {
        let uri = this.baseURIHolder.getUser() + `/get/institute/by/instituteID/cms?instituteID=${instituteID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsSaveEmInstituteMapp(saveObj) {
        console.log('saveObj-Mappppp', saveObj);
        let uri = this.baseURIHolder.getUser() + '/map/cms';
        return this.Auth.postFetch(uri, saveObj)
            .catch((error) => console.log("error", error));
    }

    // emInstitute Mapp to Neticms workable func  
    cmsSaveEmInstituteMappToNetiCMS(saveObj) {
        console.log('saveObj-Mappppp', saveObj);
        let uri = this.baseURIHolder.getHostNetiCMS() + '/cms-eduman-mapping/save';
        return this.Auth.postFetch(uri, saveObj)
            .catch((error) => console.log("error", error));
    }
    // emInstitute Mapp to Neticms   end

    cmsUpdatePurchaseCodeToNW(productPurchaseCodeID) {
        console.log('update-productPurchaseCodeID', productPurchaseCodeID);
        let uri = this.baseURIHolder.getUser() + '/update/purchase-code';
        return this.Auth.putFetch(uri, productPurchaseCodeID)
            .catch((error) => console.log("error", error));
    }

    // for support point neticms mapping start
    getNetiCMSPendingList() {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/cms-eduman-mapping/pending/cms-institute/list`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateInstituteMapStatus(updateObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/cms-eduman-mapping/update';
        return this.putFetch(uri, updateObj)
            .catch((error) => console.log('error', error));
    }
    // for support point neticms mapping end

    getCmsAlumnusList(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/alumonus/member/list?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsSaveAlumnusMember(saveObj) {
        console.log('saveObj-alumns', saveObj);
        let uri = this.baseURIHolder.getHostNetiCMS() + '/alumonus/member/save';
        return this.postFetch(uri, saveObj)
            .catch((error) => console.log('error', error));

    }

    cmsUpdateAlumnusMember(updateObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/alumonus/member/update';
        return this.putFetch(uri, updateObj)
            .catch((error) => console.log('error', error));
    }

    cmsDeleteAlumnusMemberInfo(alumonusMemberId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/alumonus/member/delete?alumonusMemberId=' + alumonusMemberId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    // ***************Bannna***************

    getCmsFeesInfoList(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/fee-info/list?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    cmsSaveFeesInfo(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/fee-info/save';
        return this.postFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsUpdateFeesInfo(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/fee-info/update';
        return this.putFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsDeleteFeesInfo(feeId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/fee-info/delete?feeId=' + feeId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getCmsDressCodeInfoList(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/dress-info/list/with/image?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsSaveDressCodeInfo(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/dress-info/save';
        return this.postFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsUpdateDressCodeInfo(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/dress-info/update';
        return this.putFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsDeleteDressCodeInfo(dressID) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/dress-info/delete?dressId=' + dressID;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getCmsMemberInfoList(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/member/info/list/with-photo?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsSaveMemberInfo(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/member/info/save';
        return this.postFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsUpdateMemberInfo(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/member/info/update';
        return this.putFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    cmsDeleteMemberInfo(memberId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/member/info/delete?memberId=' + memberId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    // ***************Miraj***************
    cmsInserGalleryImage(reqObject, submitType) {
        let submitUrl = '';
        let uri = this.baseURIHolder.getHostNetiCMS();
        if (submitType == 'submit') {
            submitUrl = "/gallery/save";
            return this.postFetch(uri + submitUrl, reqObject).catch((error) => console.log('error', error));
        }
        else {
            console.log('reqObject service', reqObject);
            submitUrl = "/gallery/update";
            return this.putFetch(uri + submitUrl, reqObject).catch((error) => console.log('error', error));
        }
    }
    cmsFetchPhotoCount(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/gallery/count/photo?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsFetchPhotoList(cmsId, photoType) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/gallery/typeWise/photoGallerys?cmsId=' + cmsId + '&photoType=' + photoType;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsDeletePhotoImage(photoGalleryId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/gallery/delete?photoGalleryId=' + photoGalleryId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsFetchImportantLinks(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/importantLink/info/list?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsInserImportantLink(reqObject, submitType) {
        let submitUrl = '';
        let uri = this.baseURIHolder.getHostNetiCMS();
        if (submitType == 'submit') {
            submitUrl = "/importantLink/info/save";
            console.log('reqObject-save', reqObject);
            return this.postFetch(uri + submitUrl, reqObject).catch((error) => console.log('error', error));
        }
        else {
            console.log('reqObject-update', reqObject);
            submitUrl = "/importantLink/info/update";
            return this.putFetch(uri + submitUrl, reqObject).catch((error) => console.log('error', error));
        }
    }

    cmsDeleteImportantLink(linkId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/importantLink/info/delete?linkId=' + linkId;
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsFetchAboutUsList(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/aboutusinfo/list?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsFetchAboutUsContentByType(cmsId, contentType) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/aboutusinfo/aboutus/by/type?cmsId=' + cmsId + '&aboutusType=' + contentType;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    cmsDeleteAboutUs(aboutUsId) {
        console.log('aboutUsId', aboutUsId);
        let uri = this.baseURIHolder.getHostNetiCMS() + '/aboutusinfo/delete?aboutusId=' + aboutUsId;
        console.log('url --- ', uri);
        return this.deleteFetch(uri)
            .catch((error) => console.log('error', error));
    }

    cmsInserAboutUs(reqObject, submitType) {
        console.log('reqObject --- ', reqObject);
        let submitUrl = '';
        let uri = this.baseURIHolder.getHostNetiCMS();
        if (submitType == 'submit') {
            submitUrl = "/aboutusinfo/save";
            console.log('reqObject', reqObject);
            return this.postFetch(uri + submitUrl, reqObject).catch((error) => console.log('error', error));
        }
        else {
            console.log('reqObject service', reqObject);
            submitUrl = "/aboutusinfo/update";
            return this.putFetch(uri + submitUrl, reqObject).catch((error) => console.log('error', error));
        }
    }

    // ADMISIA API

    getAdmisiaCoreConfigInfo(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/core/config/find?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createAdmisiaCoreConfig(coreConfigInfo) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/core/config/save';
        return this.Auth.postFetch(uri, coreConfigInfo)
            .catch((error) => console.log('error', error));
    }

    updateAdmisiaCoreConfig(coreConfigInfo) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/core/config/update';
        return this.Auth.putFetch(uri, coreConfigInfo)
            .catch((error) => console.log('error', error));
    }

    //class config in admisia

    fetchApplicationServiceChargeByCmsId(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/class/config/service-charge?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    admisiaSaveClassConfig(saveObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/class/config/save';
        return this.postFetch(uri, saveObj)
            .catch((error) => console.log('error', error));
    }

    getAdmisiaClassConfigListByCmsId(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/class/config/list?cmsId=' + cmsId;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    admisiaUpdateClassConfig(coreConfigInfo) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/class/config/update';
        return this.Auth.putFetch(uri, coreConfigInfo)
            .catch((error) => console.log('error', error));
    }

    // admisia core-config all in admin point 
    getAdmisiaAllCoreConfigList() {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/core/config/list';
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    admisiaUpdateServiceCharge(chargeUpdateRequest) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/core/config/update/service-charge';
        return this.Auth.putFetch(uri, chargeUpdateRequest)
            .catch((error) => console.log('error', error));
    }

    // admisia assessment list

    getAdmisiaAplicantAssessmentList(searchObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/admisia/applicant/assessment/list?cmsId=${searchObj.cmsId}&classId=${searchObj.classId}&groupId=${searchObj.groupId}&assessmentType=${searchObj.assessmentType}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getAdmisiaClassConfigInfoWithStatus(statusSearchObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/admisia/class/config/info?cmsId=${statusSearchObj.cmsId}&classId=${statusSearchObj.classId}&groupId=${statusSearchObj.groupId}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    admisiaUpdateApplicantsStatus(requestedBody) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/applicant/update/applicants-status';
        return this.Auth.putFetch(uri, requestedBody)
            .catch((error) => console.log('error', error));
    }

    admisiaUpdateApplicantsMarksAndResult(requestedObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + '/admisia/applicant/mark/input';
        return this.postFetch(uri, requestedObj)
            .catch((error) => console.log('error', error));
    }

    // admisia admission list

    getAdmisiaApplicantAdmisionApprovalList(searchObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/admisia/applicant/approval/list?cmsId=${searchObj.cmsId}&classId=${searchObj.classId}&groupId=${searchObj.groupId}&approvalType=${searchObj.approvalType}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    // admisia report list

    getAdmisiaApplicantAnalyticsList(searchObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/admisia/applicant/list/analytical/by-academicYear?cmsId=${searchObj.cmsId}&academicYear=${searchObj.academicYear}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getAdmisiaClassConfigurationList(cmsId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/admisia/class/config/info/list?cmsId=${cmsId}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getAdmisiaApplicantReportsByStatusWise(searchObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/admisia/applicant/status-wise/list?cmsId=${searchObj.cmsId}&classConfigId=${searchObj.classConfigId}&applicantStatus=${searchObj.applicantStatus}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getAdmisiaApplicantInfoByRegId(cmsId, registrationId) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/admisia/applicant/details-info/by-registrationId?cmsId=${cmsId}&registrationId=${registrationId}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getAdmisiaApplicantAtaGlanceReport(searchObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/admisia/applicant/ata/glance/by-academicYear?cmsId=${searchObj.cmsId}&academicYear=${searchObj.academicYear}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getPaymentCollectionAnalyticsByAcademicYear(searchObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/admisia/applicant/list/collection/analytics/by-academicYear?cmsId=${searchObj.cmsId}&academicYear=${searchObj.academicYear}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getPaymentCollectionInfoList(searchObj) {
        let uri = this.baseURIHolder.getHostNetiCMS() + `/admisia/applicant/paid/applicants/by-academicYear?cmsId=${searchObj.cmsId}&academicYear=${searchObj.academicYear}&classConfigId=${searchObj.classConfigId}`;
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    // core contact by AR Arzu
    getInstituteInfoByCMSID(cmsId) {
        const uri = this.baseURIHolder.getHostNetiCMS() + `/cms-info/find/by-customCmsId?customCmsId=${cmsId}`
        return this.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    getInstituteInfoByInstituteID(instituteId){
        const uri = this.baseURIHolder.getSupport() + `/institute-info/by?instituteId=${instituteId}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    getAllContacts(applicationID){
        const uri = this.baseURIHolder.getSupport() + `/get/all/contacts/by?applicationID=${applicationID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    getDesignationList(){
        const uri = this.baseURIHolder.getUser() + `/category/by/typeDefaultCode?typeDefaultCode=T11201`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    addNewContact(body){
        const uri = this.baseURIHolder.getSupport() + `/contact-info/add`;
        return this.Auth.postFetch(uri, body)
            .catch((error) => console.log('error', error));
    }
    updateContact(body){
        const uri = this.baseURIHolder.getSupport() + `/contact-info/update`;
        return this.Auth.putFetch(uri, body)
            .catch((error) => console.log('error', error));
    }
    getTagList(){
        const uri = this.baseURIHolder.getUser() + `/category/by/typeDefaultCode?typeDefaultCode=T128`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    saveContactTag(body){
        const uri = this.baseURIHolder.getSupport() + `/contact/tag`;
        return this.Auth.postFetch(uri, body)
            .catch((error) => console.log('error', error));
    }
}



