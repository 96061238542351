import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import NetiContentLoader from '../../common/NetiContentLoader';



let errors = {};
export class AdminPointDownloadCornerList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roleIDList: [],
            errors: {},
            dataTableIsLoading: false,
            proPic: {
                extention: '',
                contentPic: '',
                contentName: '',
            },
            expiryDate: '',
            searchDataObj: {
                pageLimit: 30,
                pageNo: 0
            },
            topProgressBar: false,
            errorMsgVisible: false,
            downloadCornerDetails: {
                fileTitle: '',
                fileDetails: '',
                fileSerial: '',
                fileDownloadAddress: '',
                expiryDate: '',
                coreUserRoleDTO: {
                    coreRoleID: ''
                }
            },
            errorMsgBody: null,
            dataTableValue: [
            ],
        }

        this.AdminPointService = new AdminPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.viewDownloadCornerInfoList();
        this.fetchUserRole();
    }

    fetchUserRole = () => {
        this.AdminPointService.fetchUserRoleList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ roleIDList: body });
                    })
                } else {
                    this.AdminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    viewDownloadCornerInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.AdminPointService.getDownloadCornerInfoList(this.state.searchDataObj)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        console.log("body", body);


                        body.map(item => {
                            if (item.uploadDate) {
                                item.uploadDate = NetiDateUtils.getAnyShortForm(item.uploadDate, 'DD-MMM-YYYY');
                            } else {
                                item.uploadDate = ''
                            }

                            if (item.expiryDate) {
                                item.expiryDate = NetiDateUtils.getAnyShortForm(item.expiryDate, 'DD-MMM-YYYY');
                            } else {
                                item.expiryDate = ''
                            }

                            item.enableStatus = (item.enableStatus == 1 ? 'Enable' : 'Disable')
                        })

                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    })
                } else {
                    this.AdminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    onHide = (event) => {
        this.setState({ dialogVisible: false });
    }

    viewDialog = (rowData) => {
        this.setState({ dialogVisible: true });
    }

    viewDownloadData = (rowData) => {
        this.setState({ topProgressBar: true });
        this.AdminPointService.getInfoByDownloadCornerID(rowData.downloadCornerID)
            .then((res) => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ downloadCornerDetails: body });
                    });
                } else {
                    this.AdminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });

        this.viewDialog();

    }

    actionTemplate = (rowData) => {
        return <div className="text-center">
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button edit"
                style={{ marginRight: '.5em', 'fontSize': '1em' }}
                onClick={(e) => this.viewDownloadData(rowData)}
            >
            </Button>
        </div>;
    }

    // uploadDateFormat = (rowData) => {
    //     return <span>{NetiDateUtils.getAnyShortForm(rowData['uploadDate'], 'DD-MMM-YYYY')}</span>;

    // }

    // expiryDateFormat = (rowData) => {
    //     return <span>{NetiDateUtils.getAnyShortForm(rowData['expiryDate'], 'DD-MMM-YYYY')}</span>;

    // }

    StatusAction = (rowData) => {
        return <span>{rowData.enableStatus === 0 ? "Disable" : ''}
            {rowData.enableStatus === 1 ? "Enable" : ''}
        </span>

    }

    onChangeFileTitle = (e) => {
        let { downloadCornerDetails, errors } = this.state;
        errors["title"] = ''
        downloadCornerDetails.fileTitle = e.target.value;
        this.setState({ downloadCornerDetails, errors });
    }

    onChangeFileDetails = (e) => {
        let { downloadCornerDetails, errors } = this.state;
        errors["details"] = ''
        downloadCornerDetails.fileDetails = e.target.value;
        this.setState({ downloadCornerDetails, errors });
    }

    onChangeUrl = (e) => {
        let { downloadCornerDetails, errors } = this.state;
        errors["url"] = ''
        downloadCornerDetails.fileDownloadAddress = e.target.value;
        this.setState({ downloadCornerDetails, errors });
    }

    pointTypeHandler = (event) => {
        let { downloadCornerDetails, errors } = this.state;
        errors["pointFor"] = ''
        downloadCornerDetails.coreUserRoleDTO.coreRoleID = event.target.value;
        if (event.target.value === null) {
            downloadCornerDetails.coreUserRoleDTO.coreRoleID = '';
            this.setState({ downloadCornerDetails });
        }
        this.setState({ downloadCornerDetails, errors });
    }

    onChangeExpiryDate = (e) => {

        this.setState({ expiryDate: e.target.value });
    }

    onChangeFileSerial = (e) => {
        let { downloadCornerDetails, errors } = this.state;
        errors["serial"] = ''
        downloadCornerDetails.fileSerial = e.target.value;
        this.setState({ downloadCornerDetails, errors });

    }

    onChangeUpdateStatus = (e) => {
        let { downloadCornerDetails } = this.state;
        downloadCornerDetails.enableStatus = e;
        this.setState({
            downloadCornerDetails
        })
    }

    onImageUpload(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });

        }
        let { errors } = this.state;
        errors["attachment"] = ''
        this.setState({ errors });

    }

    onUpdateHandler = () => {
        let { downloadCornerDetails } = this.state;

        if (this.state.proPic.contentPic !== '') {
            downloadCornerDetails.fileIconContent = this.state.proPic.contentPic;
            downloadCornerDetails.iconSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            downloadCornerDetails.fileIconName = Date.now() + extention;
        }

        let { expiryDate, errors } = this.state;
        errors["expiryDate"] = ''
        if (this.state.expiryDate) {
            downloadCornerDetails.expiryDate = NetiDateUtils.getDateFromString(this.state.expiryDate, errors);
        }
        if (this.downloadCornerUpdateFormError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.AdminPointService.updateDownloadCornerInfo(downloadCornerDetails)
                .then(res => {
                    if (res.status == 202) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated!", life: 800 });
                        setTimeout(() => {
                            this.onHide();
                        }, 800);
                        this.viewDownloadCornerInfoList();
                    } else {
                        this.AdminPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });

        }

    }

    updateDialogDiscard = () => {
        this.onHide();
    }

    downloadCornerUpdateFormError = () => {
        let { errors, downloadCornerDetails } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (downloadCornerDetails.fileTitle === '') {
            formIsValid = false;
            errors["title"] = "Title can't left empty.";
        }
        if (downloadCornerDetails.fileDetails === '') {
            formIsValid = false;
            errors["details"] = "Details can't left empty.";
        }
        if (downloadCornerDetails.fileDownloadAddress === '') {
            formIsValid = false;
            errors["url"] = "URL can't left empty.";
        }
        if (downloadCornerDetails.coreUserRoleDTO.coreRoleID === '') {
            formIsValid = false;
            errors["pointFor"] = "point type can't left empty.";
        }
        if (downloadCornerDetails.expiryDate === '') {
            formIsValid = false;
            errors["expiryDate"] = "Expiry date can't left empty.";
        }
        if (downloadCornerDetails.fileSerial === '') {
            formIsValid = false;
            errors["serial"] = "Serial can't left empty.";
        }
        if (!this.state.proPic.contentName && !downloadCornerDetails.fileIconName) {
            formIsValid = false;
            errors["attachment"] = "Attachment can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let pointType = [];
        if (this.state.roleIDList && this.state.roleIDList.length) {
            pointType = this.state.roleIDList.map(item => ({
                value: item.coreRoleID,
                label: item.coreRoleNote
            }));
        }
        var tableHeader = <div class="header-title"><div class="header-title-left">Download Corner List</div><div class="header-title-right"><a>Total Found: {this.state.dataTableValue.length}</a></div></div>



        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>

        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            <Growl ref={(el) => this.growl = el} />
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    rows={10}
                                    columnResizeMode="fit"
                                    paginator={true}
                                    rowsPerPageOptions={[10, 20]}
                                >
                                    <Column field="uploadDate" header="Date" filter={true} />
                                    <Column field="fileTitle" header="Title" filter={true} />
                                    <Column field="pointFor" header="Point For" filter={true} />
                                    <Column field="expiryDate" header="Expiry Date" filter={true} />
                                    <Column field="enableStatus" header="Status" filter={true} />
                                    <Column field="downloadCount" header="Download Count" filter={true} />
                                    <Column header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '80px' }} />
                                </DataTable>
                            }
                        </div>
                    </div>


                    <Dialog header="Update Download Corner Information" maximizable visible={this.state.dialogVisible}
                        className="nw-dialog"
                        onHide={this.onHide} closable>

                        <div className="p-fluid" >
                            {topProgressBar ?
                                <ErrorMessageView
                                    topProgressBar={topProgressBar}
                                />
                                : null
                            }
                            {errorMsgVisible ?
                                <ErrorMessageView
                                    errorMsgVisible={errorMsgVisible}
                                    errorMsgBody={errorMsgBody}
                                />
                                : null
                            }
                            <div className="p-fluid" >
                                <Growl ref={(el) => this.growl = el} />
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-grid nw-form">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="nw-form-body">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Title {requiredSing}</span>
                                                            <InputText
                                                                id="title"
                                                                placeholder="Enter Title"
                                                                onChange={this.onChangeFileTitle}
                                                                value={this.state.downloadCornerDetails.fileTitle || ''}
                                                                showClear={true}
                                                                style={{ width: "100%" }}
                                                                type="text"
                                                                name="title"
                                                            />
                                                        </div>
                                                        <span className="error-message">{errors["title"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Details {requiredSing}</span>
                                                            <InputTextarea
                                                                placeholder="Enter fee details"
                                                                onChange={this.onChangeFileDetails}
                                                                value={this.state.downloadCornerDetails.fileDetails || ''}
                                                                rows={3}
                                                                cols={30}
                                                                autoResize={true}
                                                                id="detail"
                                                                name="details"
                                                            />
                                                        </div>
                                                        <span className='error-message'>{errors['details']}</span>

                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">URL {requiredSing}</span>
                                                            <InputText
                                                                id="url"
                                                                onChange={this.onChangeUrl}
                                                                value={this.state.downloadCornerDetails.fileDownloadAddress || ''}
                                                                placeholder="Enter URl"
                                                                style={{ width: "100%" }}
                                                                type="text"
                                                                name="url"
                                                            />
                                                        </div>
                                                        <span className='error-message'>{errors['url']}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Point For {requiredSing}</span>
                                                            <Dropdown
                                                                id="pointFor"
                                                                placeholder="Select Point"
                                                                value={this.state.downloadCornerDetails.coreUserRoleDTO.coreRoleID || ''}
                                                                options={pointType}
                                                                onChange={this.pointTypeHandler}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="pointFor"
                                                            />
                                                        </div>
                                                        <span className='error-message'>{errors['pointFor']}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Expiry Date {requiredSing}</span>
                                                            <Calendar
                                                                id="expiryDate"
                                                                name="expiryDate"
                                                                dateFormat="dd/mm/yy"
                                                                value={this.state.expiryDate || ''}
                                                                showIcon={true}
                                                                onChange={this.onChangeExpiryDate}
                                                                placeholder={NetiDateUtils.getAnyShortForm(this.state.downloadCornerDetails.expiryDate, 'DD/MM/YYYY')}
                                                                className="custom-calender-input"
                                                                autoWidth={false}
                                                                yearRange="2010:2030"
                                                                monthNavigator={true}
                                                                yearNavigator={true}
                                                                dateOnly="true"
                                                            />
                                                        </div>
                                                        <span className="error-message">{errors['expiryDate']}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Serial {requiredSing}</span>
                                                            <InputText
                                                                id="serial"
                                                                onChange={this.onChangeFileSerial}
                                                                value={this.state.downloadCornerDetails.fileSerial}
                                                                keyfilter="pint"
                                                                placeholder="Enter serial"
                                                                style={{ width: "100%" }}
                                                                type="text"
                                                                name="serial"
                                                            />
                                                        </div>
                                                        <span className='error-message'>{errors['serial']}</span>
                                                    </div>


                                                    <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <div className="input-radio-button">
                                <span className="p-inputgroup-addon addon-no-style">Status <span>*</span></span>
                                <div className="radio-button-inside">
                                <RadioButton
                                                                value={1}
                                                                inputId="rb1"
                                                                onChange={e => this.onChangeUpdateStatus(e.target.value)}
                                                                checked={this.state.downloadCornerDetails.enableStatus == 1}
                                                            />
                                                            <label htmlFor="rb1" className="p-radiobutton-label">Enable</label>

                                </div>
                                <div className="radio-button-inside">
                                <RadioButton
                                                                value={0}
                                                                inputId="rb2"
                                                                onChange={e => this.onChangeUpdateStatus(e.target.value)}
                                                                checked={this.state.downloadCornerDetails.enableStatus == 0}
                                                            />
                                                            <label htmlFor="rb2" className="p-radiobutton-label">Disable</label>
                                </div>
                            </div>
                          </div>
                        </div>

                                                    <div className="p-col-12 p-xl-12 nw-upload-button">
                                                        <FileUpload
                                                            chooseLabel="Click to Attach File"
                                                            id="attachments"
                                                            mode="basic"
                                                            accept="image/*"
                                                            maxFileSize={1000000}
                                                            onSelect={this.onImageUpload.bind(this)}
                                                            auto={true}
                                                        />
                                                        <center>
                                                            {this.state.proPic.contentName || this.state.downloadCornerDetails.fileIconName}
                                                        </center>
                                                        <center>
                                                            <span className='error-message'>{errors['attachment']}</span>
                                                        </center>

                                                    </div>

                                                    {/* 
                                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            label="Update"
                                                            icon="far fa-save"
                                                            onClick={this.onUpdateHandler}
                                                        />
                                                    </div> */}

                                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                        <Button
                                                            label="Discard"
                                                            icon="fas fa-times"
                                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                                            onClick={this.updateDialogDiscard}
                                                        />
                                                        <Button
                                                            label="Update"
                                                            icon="fas fa-check"
                                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                                            onClick={this.onUpdateHandler}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                </div>
            </div>
        );
    }
}