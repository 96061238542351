import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { EmUserPointService } from '../../service/emUserPoint/EmUserPointService';
import { GuestPointService } from '../../service/guestAccessPoint/GuestPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import CommonFuctionality from '../common/CommonFuctionality';

let dialogStatus = false;

export class AddPortalForEmployee extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            otpCodeError: {},
            errors: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            OTPcode: '',
            hrProfileLinkObj: {
                customUserID: '',
                instituteID: '',
                mappingType: 'HR'
            },
            checkedRetriveObj: {},
            resendButtonDisable: true
        }
        this.emUserPointService = new EmUserPointService();
        this.guestPointService = new GuestPointService();
        this.CommonFuctionality = new CommonFuctionality();
    }

    onChangeHrProfileLinkUp(e) {
        let { hrProfileLinkObj, errors } = this.state;
        hrProfileLinkObj[e.target.name] = e.target.value;
        errors[e.target.name] = ''
        this.setState({ hrProfileLinkObj, errors });
    }
    viewDialog() {
        dialogStatus = true;
        this.setState({ visible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onHide = (event) => {
        this.setState({ visible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    onChangeOTPcode = (e) => {
        this.setState({
            OTPcode: e.target.value,
            otpCodeError: {}
        });
    }

    onProcessBtn = () => {
        const { hrProfileLinkObj } = this.state;
        if (this.handleError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false })
            this.emUserPointService.checkForLinkUpNewPortal(hrProfileLinkObj)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ checkedRetriveObj: body });
                            this.viewDialog();
                            this.setState({ topProgressBar: false, errorMsgVisible: false })
                            this.secondCountDown();
                        })
                    } else {
                        this.emUserPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection!' })
                });
        }
    }


    handleError = (v) => {
        let { errors, hrProfileLinkObj } = this.state;
        let formIsValid = true;

        if (hrProfileLinkObj.customUserID === "") {
            formIsValid = false;
            errors["customUserID"] = "HR ID can't be left empty";
        }
        if (hrProfileLinkObj.instituteID === "") {
            formIsValid = false;
            errors["instituteID"] = "Institute ID can't be left empty";
        }

        this.setState({ errors });
        return formIsValid;
    }

    async checkOtpCode(e) {
        if (this.otpCodeFieldError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false })
            const res = await this.guestPointService.checkOTP(this.state.OTPcode);
            const status = await res.status;
            if (status == 200) {
                this.emUserPointService.linkUpNewPortal(this.state.checkedRetriveObj)
                    .then((resp) => {
                        if (resp.status == 201) {
                            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully submitted.' });
                            this.onHide(e);
                            this.setState({ 
                                hrProfileLinkObj: {
                                    customUserID: '',
                                    instituteID: '',
                                }, 
                                topProgressBar: false, 
                                errorMsgVisible: false 
                            })
                        } else {
                            this.emUserPointService.Auth.handleErrorStatus(res)
                                .then((resp) => {
                                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                                });
                        }
                    })
            } else {
                this.emUserPointService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                    });
            }
            // else {
            //     const errorBody = await res.json();
            //     console.log('ErrorBody', errorBody);

            // }
        }
    }

    otpCodeFieldError = () => {
        let { otpCodeError } = this.state;
        let formIsValid = true;
        if (!this.state.OTPcode) {
            formIsValid = false;
            otpCodeError["otpCode"] = "OTP code can't be left empty";
        }
        this.setState({ otpCodeError });
        return formIsValid;

    }

    secondCountDown = () =>{
        this.setState({ resendButtonDisable: true });
        var timeleft = 60;
        var downloadTimer = setInterval(() =>{
            let countdownElemrnt = document.getElementById("countdown")

            if(countdownElemrnt != null){
                countdownElemrnt.innerHTML = timeleft + " Sec Remaining";
                timeleft -= 1;
                if(timeleft <= 0){
                    clearInterval(downloadTimer);
                    document.getElementById("countdown").innerHTML = "Finished"
                    this.setState({ resendButtonDisable: false })
                }
            }
            
        }, 1000);
    }


    render() {

        let { hrProfileLinkObj } = this.state;
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section blur-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                            <div className="p-grid seprator-inside">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                        <div className="p-col-12 p-xl-12"><h2>Teacher</h2></div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">HR ID <span>*</span></span>
                                                <InputText
                                                    type="text"
                                                    placeholder="Enter HR ID"
                                                    id="hrID"
                                                    name="customUserID"
                                                    onChange={this.onChangeHrProfileLinkUp.bind(this)}
                                                    value={hrProfileLinkObj.customUserID}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors["customUserID"]}</span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                                <InputText
                                                    type="text"
                                                    name="instituteID"
                                                    id="hrInstituteID"
                                                    onChange={this.onChangeHrProfileLinkUp.bind(this)}
                                                    value={hrProfileLinkObj.instituteID}
                                                    style={{ width: "100%" }}
                                                    placeholder="Enter Institute ID"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors["instituteID"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div>

                                            <Button
                                                type="button"
                                                label="Process"
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                icon="fas fa-check"
                                                onClick={this.onProcessBtn}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="p-col-12 p-xl-4 netiWorld-DashBoard-wrapper">
                            <div class="p-col-12 p-l-0">
                                <div class="panel-box p-l-1 p-r-0 p-t-0">
                                    <div class="advisement-wrapper">
                                        <div class="gradient-color-dark-blue">
                                            <div class="notify-box-wrapper-bg-image boxHeight-385">
                                                <div class="advisement-text">
                                                    <p>PLACE FOR AD</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dialog-section ">
                    <Dialog className="text-center" header="OTP"  visible={this.state.visible} style={{ width: '50vw' }} onHide={this.onHide} maximizable>
                    <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                        <div className="p-col-12 p-xl-12 text-left">
                                            <span>A 6-digit verification code has been sent to '{this.state.checkedRetriveObj.contactNo}' contact number.</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-small-span">OTP Code. <span>*</span></span>
                                                <InputText
                                                    onChange={(e) => this.onChangeOTPcode(e)}
                                                    value={this.state.OTPcode}
                                                    placeholder="Enter OTP Code"
                                                    style={{ width: "50%" }}
                                                    type="text"
                                                    name="otpCode"
                                                />  
                                                <span 
                                                    className="p-inputgroup-addon nw-inputgroup-small-span"
                                                    style={{ backgroundColor: "#ffffff", borderLeft: "unset" }}
                                                >
                                                    <p id="countdown"></p>
                                                </span>
                                            </div>
                                            <span className='error-message text-left'>{this.state.otpCodeError["otpCode"]}</span>
                                        </div>

                                        
                                        <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                            <Button
                                                className="p-button-warning nw-button nw-button-multiple"
                                                label="Resend OTP Code"
                                                icon="fas fa-redo-alt"
                                                onClick={this.onProcessBtn}
                                                disabled={this.state.resendButtonDisable}
                                            />
                                            <Button
                                                className="p-button-primary nw-button nw-button-multiple"
                                                label="Submit"
                                                icon="fas fa-check"
                                                onClick={(e) => this.checkOtpCode(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>

                

            </div>
        )
    }
}