import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { Growl } from 'primereact/growl';

import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';

let maxDate = new Date();
let errors = {}

// pdf
let subTitleColumn = [];
let subTitleList = [];
export class ProductPrePurchase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchDataObj: {
                startDate: '',
                endDate: '',
                pageLimit: 0,
                pageNo: 0
            },
            dataTableValue: [
                { "name": "one", "details": "hello" },
                { "name": "two", "details": "hello" }
            ],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            errors: {},
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }

        this.managementPointService = new ManagementPointService();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    startDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["startDate"] = "";
        searchDataObj.startDate = event.target.value;
        this.setState({ searchDataObj, errors })
    }

    endDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["endDate"] = "";
        searchDataObj.endDate = event.target.value;
        this.setState({ searchDataObj, errors })
    }

    searchHandleError = () => {
        let { errors, searchDataObj } = this.state;
        let formIsValid = true;
        if (searchDataObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start date can't left empty";
        }
        if (searchDataObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End date can't left empty";
        }
        this.setState({ errors });
        return formIsValid;
    }

    calculateTotalAmount = (body, amountCategory) => {
        return body.map(item => item[`${amountCategory}`]).reduce((a, b) => (a + b));
    }

    onDateWiseSearchHandler = () => {
        let { searchDataObj, errors } = this.state;
        this.setState({ errors: {} })

        if (this.searchHandleError()) {
            if (Date.parse(searchDataObj.endDate) >= Date.parse(searchDataObj.startDate)) {
                this.setState({ tableview: true, topProgressBar: true, dataTableIsLoading: true });

            } else {
                this.growl.show({ severity: 'warn', summary: 'Warning', detail: "To Date can not be earlier than From Date." });
                this.setState({ topProgressBar: false, errorMsgVisible: true })
            }
        }
    }

    detailsBody = (rowData) => {
        return <center>
            {/* <Button
                className="nw-action-button"
                icon="far fa-eye"
                title="View Pre Purchase"
                onClick={(e) => this.photoDetails(rowData)}
            /> */}

            <Link to={{ pathname: MANAGEMENT_POINT.PRODUCT_PRE_PURCHASE_DETAILS, walletTabIndex: 1 }}>
                <i className="far fa-eye" ></i>
            </Link>
        </center>
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Product Name", dataKey: "name" },
            { title: "Purchase Date", dataKey: "" },
            { title: "Unit Price", dataKey: "" },
            { title: "Total Amount", dataKey: "amount" },
            { title: "Purchase By", dataKey: "" },
        ]

        let tableColStyle = { amount: { halign: 'right' } }

        pdfDownloadPortrait_TableWithSubTitle("Product Pre-Purchase List", 73, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableColStyle, "Product_Pre-Purchase_List.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody, searchDataObj } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (searchDataObj.startDate && searchDataObj.endDate) {
            formatedStartDate = (searchDataObj.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (searchDataObj.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = formatedStartDate + ' to ' + formatedEndDate + "_Product_Pre-Purchase_List.xlsx";
        let excelSheetName = "Product_Pre-Purchase";
        excelColumns = [
            <Workbook.Column value="name" label="Product Name" />,
            <Workbook.Column value="" label="Purchase Date" />,
            <Workbook.Column value="" label="Unit Price" />,
            <Workbook.Column value="" label="Total Amount" />,
            <Workbook.Column value="" label="Purchase By" />,
        ];
        // end excel

        // let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Product Pre Purchase List <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">  Product Pre Purchase List</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        return (
            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="nw-form">
                    <div className="p-col-12 p-lg-12 p-xl-12">

                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={searchDataObj.startDate}
                                        dateFormat="dd/mm/yy"
                                        onChange={this.startDateHandler}
                                        showIcon={true}
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />

                                </div>
                                <span className="error-message">{this.state.errors["startDate"]}</span>

                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={searchDataObj.endDate}
                                        dateFormat="dd/mm/yy"
                                        onChange={this.endDateHandler}
                                        showIcon={true}
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["endDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                <Button
                                    label="Search"
                                    className="p-button-primary nw-button nw-button-right"
                                    icon="fas fa-search"
                                    onClick={this.onDateWiseSearchHandler}
                                />
                            </div>
                        </div>
                        {/* </div> */}
                    </div>

                    <div className="nw-data-table">
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            {/* {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ? 
                                        this.NetiContentLoader.MyPointTableLoader()
                                        : */}
                            <DataTable
                                header={tableHeader}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                value={this.state.dataTableValue}
                                reorderableColumns={true}
                                reorderableRows={true} onRowReorder={(e) => { this.setState({ dataTableValue: e.value }); console.log("e.value", e.value) }}>

                                <Column rowReorder={true} style={{ width: '3em' }} />
                                <Column field="name" header="Product Name" filter={true} />
                                <Column field="" header="Purchase Date" filter={true} />
                                <Column field="" header="Unit Price" filter={true} />
                                <Column field="" header="Total Amount" filter={true} />
                                <Column field="" header="Purchase By" filter={true} />
                                <Column field="details" header="Details" body={this.detailsBody} filter={true} />

                            </DataTable>

                            {/* :
                                        
                                        <BlankDataTableAnim/> 
                                } */}
                        </div>

                        <div className="p-col-12 p-xl-12 nw-button-parent">

                            <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                            <div style={{ display: 'none' }}>

                                <Workbook
                                    filename={excelFileName}
                                    element={
                                        <Button
                                            label="Download Excel"
                                            icon="fas fa-download"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                        />
                                    }
                                >
                                    <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                        {excelColumns}
                                    </Workbook.Sheet>
                                </Workbook>

                            </div>

                        </div >

                    </div>

                </div>

            </div>

            // </div>
        )
    }
}