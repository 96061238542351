import React, { Component, useLayoutEffect } from 'react';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Growl} from 'primereact/growl';
import {AssessmentPendingApplicantList} from './AssessmentPendingApplicantList';
import {AssessmentExamMarkList} from './AssessmentExamMarkList';
import { PendingTransferList } from './PendingTransferList';
import { TransferredApplicantsList } from './TransferredApplicantsList';
import {AdmissionPendingApplicantList} from './AdmissionPendingApplicantList';
import {AdmissionApprovedApplicantList} from './AdmissionApprovedApplicantList';
import {AdmissionWaitingApplicantList} from './AdmissionWaitingApplicantList';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../common/ErrorMessageView';


let errors = {};
let urlId=null;
let admisiaStatus;
export class CommonSearchByClassAndGroup extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            rejectedStutas: "Custom Status",
            dataTableSelection: null,
            classAndGroupValueObj:{},
            classAndGroupList: '',
            classAndGroupListValue: '',
            dataTableValue: [],
            assessmentRequired: '',
            firstDropdownIsLoading: false,
            autoApproveStatus: '',
            applicantLimit: '',
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible:false,
            errorMsgBody: null,
            buttonDisabled: true,
            errors:{}

        };
        this.admisiaPointService=new AdmisiaPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.getFetchClassAndGroupList = this.getFetchClassAndGroupList.bind(this);
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => { 
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (urlId) {
            this.getFetchClassAndGroupList(urlId);
        }
        else {
        }
    }

    
    getFetchClassAndGroupList(urlId){
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true,  errorMsgVisible: false });
        this.admisiaPointService.getClassAndGroupForApproval(urlId)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                      
                        if(!body[0]){
                            errors['classAndGroupListError'] = "Desire list is empty"
                            this.setState({ errors })
                        }
                        else {
                            this.setState({ classAndGroupList: body});
                        }
                        
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp})
                    });
                }
            }).catch(error => {
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }





    onChangeClassAndGroupList(e) {
        errors['classAndGroupListError']=''

        let assessmentRequired, autoApproveStatus= null
        
        if(e.target.value.admissionTestStatus === 0){
            assessmentRequired = "No"
        }
        else if(e.target.value.admissionTestStatus === 1){
            assessmentRequired = "Yes"
        }

        console.log("class & grp onchange", e.target.value );

        if(e.target.value.autoApproveStatus === 0){

            autoApproveStatus = "(Manual Approve)"
        }
        else if(e.target.value.autoApproveStatus === 1){
          
            autoApproveStatus = "(Auto Approve)"
        }

        this.setState({ 
            classAndGroupListValue: e.target.value.classConfigID,
            classAndGroupLabel: e.target.value.className + " (" + e.target.value.group +")",
            assessmentRequired: assessmentRequired,
            // admissionBy: autoApproveStatus,
            autoApproveStatus: autoApproveStatus,
            applicantLimit: e.target.value.applicantLimit,
            classAndGroupValueObj:e.target.value
        })


    }

    onSubmitHandler(){
      this.setState({ dataTableValue: []});

        if(this.state.classAndGroupListValue){

            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            let {componentName} = this.props
            let urlName = '';
            
            if(componentName === "assessmentPendingApplicants") { urlName = 'assessment'; admisiaStatus = 0 }
            else if(componentName === "assessmentExamMark") { urlName = 'assessment'; admisiaStatus = 1 }

            else if(componentName === "admissionPendingApplicants") { urlName = 'admission'; admisiaStatus = 1 }
            else if(componentName === "admissionWaitingApplicants") { urlName = 'admission'; admisiaStatus = 4 }
            else if(componentName === "admissionApprovedApplicants") { urlName = 'admission'; admisiaStatus = 5 }
            else if(componentName === "pendingTransfer") { urlName = 'admission'; admisiaStatus = 5 }
            else if(componentName === "transferredApplicants") { urlName = 'admission'; admisiaStatus = 10 }


            let urlObj={
                "urlInfoID": urlId,
                "classConfigID": this.state.classAndGroupListValue,
                "status": admisiaStatus,
                "pageNo": 0,
                "limit": 10
            }

            this.admisiaPointService.fetchApprovalList( urlName, urlObj )
                .then(res => {
                    if(res.status == 302) {
                        return res.json().then((body) => {
                            console.log("body2", body);

                            for(let i=0; i<body.length; i++){
                              if (body[i].admisiaApplicantInfoDTO.applicationDate == null) {
                                  body[i].admisiaApplicantInfoDTO.applicationDate='';
                              } else {
                                  body[i].admisiaApplicantInfoDTO.applicationDate = NetiDateUtils.getAnyShortForm(body[i].admisiaApplicantInfoDTO.applicationDate, 'DD-MMM-YYYY');
                              }
                            }

                            for(let j=0; j<body.length; j++){
                              if (body[j].admisiaApplicantInfoDTO.dateOfBirth == null) {
                                  body[j].admisiaApplicantInfoDTO.dateOfBirth='';
                              } else {
                                  body[j].admisiaApplicantInfoDTO.dateOfBirth = NetiDateUtils.getAnyShortForm(body[j].admisiaApplicantInfoDTO.dateOfBirth, 'DD-MMM-YYYY');
                              }
                            }


                          //   body.admisiaApplicantInfoDTO.map(item => {

                          //     console.log("item", item);
                              
                          //     item['applicationDate'] = NetiDateUtils.getAnyShortForm(item['applicationDate'], 'DD-MMM-YYYY')
                              
                          // })


                            this.setState({ dataTableValue: body})
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                            this.buttonDisableCheck();
                        });
                    }
                    else{
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp)=>{
                            this.setState({dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                        });
                    }
                }).catch((error) => {
                    this.setState({dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                });
        }
        else{
            errors['classAndGroupListError'] = "Field can not left empty"
        }
    }

    
    buttonDisableCheck = () => {

      console.log("Length", this.state.dataTableValue.length);
      

      if (this.state.dataTableValue.length > 0) {
          this.setState({ buttonDisabled: false })
      }
      else {
          this.setState({ buttonDisabled: true })
      }

  }


    render() {

        let {componentName} = this.props
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, classAndGroupListValue } = this.state;

        let classAndGroupListOptions = []
        if (this.state.classAndGroupList && this.state.classAndGroupList.length) {
            classAndGroupListOptions = this.state.classAndGroupList.map((item) => ({
              value: item ,
              label: item.className + " (" + item.group +")"
            }));
        }
        
        return (
          <div className="layout-dashboard">
            {topProgressBar ? (
              <ErrorMessageView topProgressBar={topProgressBar} />
            ) : null}
            {errorMsgVisible ? (
              <ErrorMessageView
                errorMsgVisible={errorMsgVisible}
                errorMsgBody={errorMsgBody}
              />
            ) : null}

            <div className="p-grid nw-form">
              <Growl ref={el => (this.growl = el)} />
              
              {/* <div className="p-grid "> */}
                <div className="p-col-12 p-xl-12">
                  <div className="p-grid nw-form-body">
                    <div className="p-col-12 p-xl-10">
                      {this.state.firstDropdownIsLoading ? (
                        this.NetiContentLoader.normalFormInputField()
                      ) : (
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Class & Group
                          </span>
                          <Dropdown
                            placeholder={
                              this.state.classAndGroupLabel ||
                              "Select Class & Group Name"
                            }
                            filter={true}
                            filterBy="label,value"
                            options={classAndGroupListOptions}
                            value={this.state.classAndGroupListValue}
                            onChange={e => this.onChangeClassAndGroupList(e)}
                            style={{ width: "100%" }}
                          />
                        </div>
                      )}
                      <span className="error-message">
                        {errors["classAndGroupListError"]}
                      </span>

                      <br />
                      {componentName === "pendingTransfer" ||
                      componentName === "transferredApplicants" ? (
                        ""
                      ) : (
                        <div className="p-grid">
                          <div className="p-col-12 p-xl-2">
                            <center>
                              <i
                                className="overview-icon fas fa-info-circle text-success"
                                style={{ fontSize: "50px" }}
                              ></i>{" "}
                            </center>
                          </div>

                          <div className="p-col-12 p-sm-10">
                            <table className="admisia-table">
                              <tbody>
                                <tr>
                                  <td> Assessment Required </td>
                                  <td> : </td>
                                  <td> {this.state.assessmentRequired} 
                                  
                                  {componentName ===
                                    "assessmentPendingApplicants" ||
                                  componentName === "assessmentExamMark" ? (
                                   
                                   " " + this.state.autoApproveStatus
                                   
                                  ) : (
                                    ""
                                  )}
                                  </td>
                                </tr>

                                <tr>
                                  <td> Applicant Required</td>
                                  <td> : </td>
                                  <td> {this.state.applicantLimit} </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="p-col-12 p-xl-2 nw-button-parent">
                      <Button
                        className="p-button p-button-primary nw-button nw-button-center"
                        label="Search"
                        icon="fas fa-search"
                        onClick={this.onSubmitHandler.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>

            <div className="p-col-12">
              {!this.state.dataTableIsLoading ? (
                componentName === "assessmentPendingApplicants" ? (
                  <AssessmentPendingApplicantList
                    reCallClassGroupSearch={() => this.onSubmitHandler()}
                    classConfigID={classAndGroupListValue}
                    componentNameData={dataTableValue}
                  />
                ) : componentName === "assessmentExamMark" ? (
                  <AssessmentExamMarkList
                    reCallClassGroupSearch={() => this.onSubmitHandler()}
                    classConfigID={classAndGroupListValue}
                    componentNameData={dataTableValue}
                    autoApproveStatus={this.state.autoApproveStatus}
                    admisiaStatus={admisiaStatus}
                    buttonCheck={this.state.buttonDisabled}
                  />
                ) : componentName === "pendingTransfer" ? (
                  <PendingTransferList
                    reCallClassGroupSearch={() => this.onSubmitHandler()}
                    classAndGroupValueObj={this.state.classAndGroupValueObj}
                    componentNameData={dataTableValue}
                  />
                ) : componentName === "transferredApplicants" ? (
                  <TransferredApplicantsList
                    reCallClassGroupSearch={() => this.onSubmitHandler()}
                    classConfigID={classAndGroupListValue}
                    componentNameData={dataTableValue}
                  />
                ) : componentName === "admissionPendingApplicants" ? (
                  <AdmissionPendingApplicantList
                    reCallClassGroupSearch={() => this.onSubmitHandler()}
                    classConfigID={classAndGroupListValue}
                    componentNameData={dataTableValue}
                  />
                ) : componentName === "admissionApprovedApplicants" ? (
                  <AdmissionApprovedApplicantList
                    reCallClassGroupSearch={() => this.onSubmitHandler()}
                    classConfigID={classAndGroupListValue}
                    componentNameData={dataTableValue}
                  />
                ) : componentName === "admissionWaitingApplicants" ? (
                  <AdmissionWaitingApplicantList
                    reCallClassGroupSearch={() => this.onSubmitHandler()}
                    classConfigID={classAndGroupListValue}
                    componentNameData={dataTableValue}
                  />
                ) : (
                  ""
                )
              ) : (
                this.NetiContentLoader.MyPointTableLoader()
              )}
            </div>
          </div>
        );
    }
}