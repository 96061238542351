import React, { Component } from 'react';

//custom imports
import { ApplicantPrevExamInfo } from './ApplicantPrevExamInfo';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import NetiContentLoader from '../../../common/NetiContentLoader';

export class ApplicantPersonalInfo extends Component {

    constructor(props) {
        super(props);

        this.netiContentLoader = new NetiContentLoader();

    }

    render() {

        let { inputFieldLoader, applicantDetailsInfo } = this.props;
        let applicantPersonalViewResponse = '';
        let applicantPrevExamInfo = '';

        let applicantStatus = '';
        let applicantStatusNm = 'Approved';
        let applicantStatusShortNm = 'A';
        let examType = '';

        if (applicantDetailsInfo) {
            applicantPersonalViewResponse = applicantDetailsInfo.applicantPersonalViewResponse;
            applicantPrevExamInfo = applicantDetailsInfo.applicantPreviousExamViewResponses;
        }

        if (applicantPersonalViewResponse) {
            applicantStatus = applicantPersonalViewResponse.applicantStatus;
        }

        if (applicantStatus === 0) { applicantStatusNm = 'Pending'; applicantStatusShortNm = 'P'; }
        if (applicantStatus === 1) { applicantStatusNm = 'Pending'; applicantStatusShortNm = 'P'; }
        else if (applicantStatus === 2) { applicantStatusNm = 'Rejected'; applicantStatusShortNm = 'R'; }
        else if (applicantStatus === 3) { applicantStatusNm = 'Rejected'; applicantStatusShortNm = 'R'; }
        else if (applicantStatus === 4) { applicantStatusNm = 'Waiting'; applicantStatusShortNm = 'W'; }
        else if (applicantStatus === 5) { applicantStatusNm = 'Approved'; applicantStatusShortNm = 'A'; }
        else if (applicantStatus === 10) { applicantStatusNm = 'Transferred'; applicantStatusShortNm = 'T'; }

        if (applicantStatus === 0 || applicantStatus === 2) { examType = 'Assessment' }
        else { examType = 'Admission' }

        let staticImg = '/assets/layout/images/userAvatar.png';

        return (

            <div className="p-col-12 p-xl-12">

                <div className="p-grid">

                    <div className="p-col-12 p-xl-12" style={{ borderTop: '1px solid #E8E8E8', borderLeft: '1px solid #E8E8E8', borderRight: '1px solid #E8E8E8', paddingTop: '5px' }}>

                        <div className="p-col-12 p-xl-12">
                            <div className='first-section-wrapper'>
                                <div className='image-part'> {applicantPersonalViewResponse && applicantPersonalViewResponse.fileContent ?
                                    <img src={"data:image/*;base64," + applicantPersonalViewResponse.fileContent} width="120" height='120' />
                                    : <img src={staticImg} width="120" height='120' />}
                                </div>
                                <div className="first-border">
                                    <span className="first-text">{applicantStatusNm} for</span>
                                </div>
                                <div className="circle-part">
                                    <span>Admission</span>
                                    <div className='inside-circle'>{applicantStatusShortNm}</div>
                                </div>
                                <div className='second-border'></div>
                            </div>
                        </div>

                        <div className="p-grid p-col-12 p-xl-12 nw-search-view" style={{ border: 'none', margin: 0, padding: 0, borderStyle: 'none' }}>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid">

                                    {/* <div className="p-col-12 p-xl-3" style={{ 'width': '120px', 'height': '120px', 'background': 'aliceblue' }}>
                                {applicantPersonalViewResponse && applicantPersonalViewResponse.fileContent ?
                                    <img src={"data:image/*;base64," + applicantPersonalViewResponse.fileContent} width="120px" />
                                    : <img src={staticImg} width="120px" />}
                            </div> */}

                                    <div className="p-grid p-col-12 p-xl-9">
                                        <div className="p-col-12 p-xl-12">
                                            <h1 className="text-center"><u>Applicant Information </u></h1>
                                        </div>
                                        <div className="p-col-12 p-xl-6">

                                        </div>
                                        {/* <div className="p-col-12 p-xl-6" style={{'text-align': 'right','font-weight':'bold'}}>
                            {this.props.eligibilityStatus}
                        </div> */}
                                        {/* </div> */}
                                    </div>

                                </div>

                                <br />
                                <br />

                            </div>

                            <div className="p-col-12 p-xl-6">

                                <div>
                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Registration No.</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.registrationId ? applicantPersonalViewResponse.registrationId : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Class</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.clasName ? applicantPersonalViewResponse.clasName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Group</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.groupName ? applicantPersonalViewResponse.groupName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Application Date</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.applicationDate ? NetiDateUtils.getDateFromString(applicantPersonalViewResponse.applicationDate) : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>

                            </div>
                            <div className="p-col-12 p-xl-6">

                                <div className="p-col-12 p-xl-12">
                                    {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Assessment</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                {applicantPersonalViewResponse && applicantPersonalViewResponse.admissionExamStatus == 0 ? "NO"
                                                    : applicantPersonalViewResponse && applicantPersonalViewResponse.admissionExamStatus == 1 ? "YES" : '-'}
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Approved Date</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                {applicantPersonalViewResponse && applicantPersonalViewResponse.statusUpdateDate ? NetiDateUtils.getDateFromString(applicantPersonalViewResponse.statusUpdateDate) : '-'}
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>

                        </div>
                        <br />
                        <br />

                        <div className="p-grid p-col-12 p-xl-12 nw-search-view" >
                            <div className="p-col-12 p-xl-6">

                                <div className="p-col-12 p-xl-12">

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Student Name</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.applicantName ? applicantPersonalViewResponse.applicantName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Gender</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.gender ? applicantPersonalViewResponse.gender : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Religion</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.religion ? applicantPersonalViewResponse.religion : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Date Of Birth</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.dob ? NetiDateUtils.getDateFromString(applicantPersonalViewResponse.dob) : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Guardian Contact No</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.mobileNo ? applicantPersonalViewResponse.mobileNo : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="p-col-12 p-xl-6">

                                <div className="p-col-12 p-xl-12">

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Address</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.addressDetails ? applicantPersonalViewResponse.addressDetails : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Father's Name</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.fatherName ? applicantPersonalViewResponse.fatherName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Mother's Name</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.motherName ? applicantPersonalViewResponse.motherName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Father's Occupation</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.fatherOccupation ? applicantPersonalViewResponse.fatherOccupation : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {inputFieldLoader ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Mother's Occupation</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantPersonalViewResponse && applicantPersonalViewResponse.motherOccupation ? applicantPersonalViewResponse.motherOccupation : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* <div className="p-col-12 p-xl-6">
                    {this.props.applicantInfo.admisiaApplicantPreviousExamInfoResponseDTOList ?
                        <AdmisiaApplicantPrevExam
                            applicantPrevExamInfo={this.props.applicantInfo.admisiaApplicantInfoDTO}
                            applicantPrevDataList={this.props.examInfo || this.state.examInfoValue}
                        />
                        : null
                    }
                    </div> */}

                </div>

                {applicantDetailsInfo && applicantPrevExamInfo ?
                    <ApplicantPrevExamInfo applicantPrevExamInfo={applicantPrevExamInfo} />
                    : ''}

            </div>

        )

    }
}