import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { Calendar } from 'primereact/calendar';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { AdmisiaPointService } from "../../service/admisiaPoint/AdmisiaPointService";
import { AdmisiaEligibilityStatus } from './AdmisiaEligibilityStatus';
import { UserCategoryService } from "../../service/UserCategoryService";
import { Link } from 'react-router-dom';


let cloneStateBeforeMount;
let eligibilityStatus;
let urlInfoID;

export class ApplicantUpdateInfo extends Component {
    constructor() {
        super();
        this.state = {
            regNo: '',
            errors: {},
            genderDropDownList:[],
            religionDropDownList:[],
            occupationDropDownList:[],
            eligibilityStatus: '',
            applicantFormVisibility: false,
            applicantPicture: '',
            regError: {},
            errorMsgVisible: false,
            homeReturnButton: true,
            topProgressBar: false,
            searchErrorMsgBody: '',
            errorMsgBody: '',
            searchViewError: false,
            dob: '',
            applicantUpdatedInfo: {
              admisiaApplicantInfoDTO: {
                admisiaClassConfigDTO: {
                    admissionTestStatus: ''
                },
                addressCoreCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                dateOfBirth: '',
                motherOccupationCategoryInfoDTO: {
                    categoryName: '',
                    coreCategoryID:''
                },
                fatherOccupationCategoryInfoDTO: {
                    categoryName: '',
                    coreCategoryID:''
                },
                religionCoreCategoryInfoDTO: {
                    categoryName: '',
                    coreCategoryID:''
                },
                genderCoreCategoryInfoDTO: {
                    categoryName: '',
                    coreCategoryID:''
                },
                admisiaClassConfigDTO: {
                    classCoreCategoryInfoDTO: {
                        categoryName: ''
                    },
                    groupCoreCategoryInfoDTO: {
                        categoryName: ''
                    }
                },
                admisiaCoreConfigDTO: {
                    coreUrlInfoDTO: {
                        urlInfoID: ''
                    }
                },
              }

            },

        };

        this.admisiaPointService = new AdmisiaPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.admisiaEligibilityStatus = new AdmisiaEligibilityStatus();
        this.userCategoryService = new UserCategoryService();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlInfoID = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlInfoID) {
            setTimeout(() => {
                this.componentWillMount();
            }, 1000);
        }
    }





    
    componentWillMount() {
        urlInfoID = this.admisiaPointService.getUrlInfoFromLocalStorage();

        if( urlInfoID ){

            this.setState({ homeReturnButton: false })
            this.getCategoryListByDefaultCode('T114');
            this.getCategoryListByDefaultCode('T117');
            this.getCategoryListByDefaultCode('T118');

        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
        } else {

            this.setState({ homeReturnButton: true })

        }
  
    }

   

    getCategoryListByDefaultCode = (defaultCode) => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });

        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if (defaultCode === 'T114') {
                            this.setState({ genderDropDownList: body });
                        }
                        else if (defaultCode === 'T117') {
                            this.setState({ religionDropDownList: body });
                        }
                        else if (defaultCode === 'T118') {
                            this.setState({ occupationDropDownList: body });
                        }
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });
                } else if (res.status == 404) {
                    return res.json().then((body) => {
                        // errors["dropDownClassError"] = body.message
                        // this.setState({ errors: errors })
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message });
                    });
                }
                else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, applicantFormVisibility: false   })
                    });
                }

            }).catch(error => {
                // errors["dropDownClassError"] = 'Connection Problem'
                // this.setState({ errors: errors })
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeRegNo = (e) => {
        let { regNo, regError } = this.state;
        regError["registrationNo"] = "";
        regNo = e.target.value;
        this.setState({ regNo, regError });
    }

    onSearchHandler = () => {
        this.setState({ topProgressBar: true, searchErrorMsgBody: '', applicantFormVisibility: false , errorMsgVisible: false, searchViewError: false });
        this.setState({applicantUpdatedInfo:cloneStateBeforeMount.applicantUpdatedInfo});
        let { applicantUpdatedInfo } = this.state;
        if (this.searchRegError()) {
            this.admisiaPointService.getApplicantInfoByRegNo( urlInfoID, this.state.regNo)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 302) {
                        return res.json().then((body) => {

                            console.log("body uuuuuuuuuuuuuuuuuuuuuuu", body);

                            
                            this.setState({ applicantFormVisibility: true });
                            this.setState({ applicantUpdatedInfo: body, searchViewError: false,  errorMsgVisible: false });
                            this.setState(({ eligibilityStatus }) => ({ eligibilityStatus: this.admisiaEligibilityStatus.checkStatus(body.applicantStatus) }));
                            this.setState({ dob: NetiDateUtils.getAnyShortForm(body.admisiaApplicantInfoDTO.dateOfBirth, 'DD/MM/YYYY')})
                            this.getProfileImg();
                        });
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, searchViewError: true, searchErrorMsgBody: responseBody, applicantFormVisibility: false   })
                            });
                    }
                }).catch(error => {
                    this.setState({ errorMsgVisible: true, applicantFormVisibility: false, searchViewError: false, errorMsgBody: "Please check your connection." })
                    // this.growl.show({severity: 'warn', summary: 'Warning', detail: 'Connection Problem'});
                });

        }

    }

    searchRegError = () => {
        let { regError } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (this.state.regNo === '') {
            formIsValid = false;
            regError["registrationNo"] = "Registration no can't left empty.";
        }

        this.setState({ regError });
        return formIsValid;
    }

    onChangeApplicantName = (e) => {
        let { applicantUpdatedInfo } = this.state;
        applicantUpdatedInfo.admisiaApplicantInfoDTO.applicantName = e.target.value;
        this.setState({ applicantUpdatedInfo });
    }


    onChangeGender = (e) => {
        let { applicantUpdatedInfo } = this.state;
        applicantUpdatedInfo.admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ applicantUpdatedInfo });
    }

    onChangeReligion = (e) => {
        let { applicantUpdatedInfo } = this.state;
        applicantUpdatedInfo.admisiaApplicantInfoDTO.religionCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ applicantUpdatedInfo });
    }


    onChangeDateOfBirth = (e) => {
        let { applicantUpdatedInfo, dob } = this.state;

        console.log("dob",e.target.value);
        
        // this.state.dob = e.target.value;
        this.setState({ dob:e.target.value });
        applicantUpdatedInfo.admisiaApplicantInfoDTO.dateOfBirth = e.target.value;
        this.setState({applicantUpdatedInfo});

        

        // this.setState({ applicantUpdatedInfo });
    }


    // issueDateHandler = (event, props) => {
    //     let { noticeInfo } = this.state;
    //     this.state.createDate = event.target.value;
    //     noticeInfo.noticeCreateDate = this.state.createDate;
    //     this.setState({ noticeInfo });
    // }


    onChangeAddressDetails = (e) => {
        let { applicantUpdatedInfo } = this.state;
        applicantUpdatedInfo.admisiaApplicantInfoDTO.addressDetails = e.target.value;
        this.setState({ applicantUpdatedInfo });
    }

    onChangeFatherName = (e) => {
        let { applicantUpdatedInfo } = this.state;
        applicantUpdatedInfo.admisiaApplicantInfoDTO.fatherName = e.target.value;
        this.setState({ applicantUpdatedInfo });
    }

    onChangeMotherName = (e) => {
        let { applicantUpdatedInfo } = this.state;
        applicantUpdatedInfo.admisiaApplicantInfoDTO.motherName = e.target.value;
        this.setState({ applicantUpdatedInfo });
    }

    onChangeFatherOccupation = (e) => {
        let { applicantUpdatedInfo } = this.state;
        applicantUpdatedInfo.admisiaApplicantInfoDTO.fatherOccupationCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ applicantUpdatedInfo });
    }

    onChangeMotherOccupation = (e) => {
        let { applicantUpdatedInfo } = this.state;
        applicantUpdatedInfo.admisiaApplicantInfoDTO.motherOccupationCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ applicantUpdatedInfo });
    }

    getProfileImg = () => {
        const { applicantUpdatedInfo } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';
        if (applicantUpdatedInfo.admisiaApplicantInfoDTO.photoPath !== null) {
            this.netiFileHandler.getByteImage(applicantUpdatedInfo.admisiaApplicantInfoDTO.photoPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(applicantUpdatedInfo.admisiaApplicantInfoDTO.photoName);
                                this.setState({ applicantPicture: contentType + body.fileContent });
                            })
                    } else {
                        return res.json()
                            .then((body) => {
                            })
                    }
                });
        } else {
            return null;
        }


    }


    onUpdateHandler = () => {
        let { applicantUpdatedInfo } = this.state;
        
        this.setState({ topProgressBar: true });
        if (this.applicantUpdateFormError()) {
            applicantUpdatedInfo.admisiaApplicantInfoDTO.dateOfBirth = NetiDateUtils.getDateFromString(applicantUpdatedInfo.admisiaApplicantInfoDTO.dateOfBirth);
            console.log("SSDD", applicantUpdatedInfo);
            this.admisiaPointService.updateApplicantInfo(applicantUpdatedInfo.admisiaApplicantInfoDTO)
                .then(res => {

                    this.setState({ topProgressBar: false });
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated!" });
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })

                            });
                    }
                }).catch(error => {
                    console.log(error);
                });
        }
    }



    applicantUpdateFormError = () => {
        let { errors, applicantUpdatedInfo } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });

        if (applicantUpdatedInfo.admisiaApplicantInfoDTO.applicantName === '') {
            formIsValid = false;
            errors["studentName"] = "Student Name can't left empty.";
        }
        if (applicantUpdatedInfo.admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName === '') {
            formIsValid = false;
            errors["gender"] = "Gender can't left empty.";
        }
        if (applicantUpdatedInfo.admisiaApplicantInfoDTO.religionCoreCategoryInfoDTO.categoryName === '') {
            formIsValid = false;
            errors["Religion"] = "Religion can't left empty.";
        }

        if (this.state.dob === '') {
            formIsValid = false;
            errors["dob"] = "Date Of Birth can't left empty.";
        }
        if (applicantUpdatedInfo.admisiaApplicantInfoDTO.addressDetails === '') {
            formIsValid = false;
            errors["address"] = "Address can't left empty.";
        }
        if (applicantUpdatedInfo.admisiaApplicantInfoDTO.fatherName === '') {
            formIsValid = false;
            errors["fatherName"] = "Father's Name can't left empty.";
        }
        if (applicantUpdatedInfo.admisiaApplicantInfoDTO.motherName === '') {
            formIsValid = false;
            errors["motherName"] = "Mother Name can't left empty.";
        }
        if (applicantUpdatedInfo.admisiaApplicantInfoDTO.fatherOccupationCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            errors["fatherOccupation"] = "Father's Occupation can't left empty.";
        }
        if (applicantUpdatedInfo.admisiaApplicantInfoDTO.motherOccupationCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            errors["motherOccupation"] = "Mother's Occupation can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    // dateOfBirth = () => {

    //     let { applicantUpdatedInfo } = this.state;
    //     applicantUpdatedInfo.admisiaApplicantInfoDTO.dateOfBirth = new Date(applicantUpdatedInfo.admisiaApplicantInfoDTO.dateOfBirth)
    //     this.setState({applicantUpdatedInfo})
  
    //   }



    render() {
        let { applicantUpdatedInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        console.log("urlInfoID render", this.state.applicantPicture);

        // console.log("applicantUpdatedInfo", this.state.applicantUpdatedInfo);

        let religionList = []
        if (this.state.religionDropDownList && this.state.religionDropDownList.length) {
            religionList = this.state.religionDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let genderList = []
        if (this.state.genderDropDownList && this.state.genderDropDownList.length) {
            genderList = this.state.genderDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let occupationList = []
        if (this.state.occupationDropDownList && this.state.occupationDropDownList.length) {
            occupationList = this.state.occupationDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

    
        console.log("applicantUpdatedInfo", applicantUpdatedInfo);
        console.log("genderList", genderList);
        console.log("date ofBirth:::::::", this.state.dob);

        
        

        // const gender = [
        //     { label: 'Select Gender', value: null },
        //     { label: 'Male', value: 'male' },
        //     { label: 'Female', value: 'female' },
        //     { label: 'Others', value: 'others' },
        // ]

        const pointType = [
            { label: 'DWS', value: 'DWS' },
            { label: 'Admisia', value: 'Admisia' },
            { label: 'Bijoy', value: 'Bijoy' }
        ]


        return (
            <div className="p-fluid">
                <div className="p-grid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    {this.state.homeReturnButton === false ?
                  
                    <div className="p-col-12 p-xl-12">
                        
                            <Growl ref={(el) => this.growl = el} />

                            <div className=" p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form-body">

                                        <div className="p-col-12 p-xl-9">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Registration No</span>
                                               
                                                    <InputText
                                                        value={this.state.regNo}
                                                        onChange={this.onChangeRegNo}
                                                        placeholder="Enter Registration No"
                                                        style={{ width: "100%" }}
                                                        type="text"
                                                        keyfilter="pint"
                                                        name="registrationNo"
                                                    />
                                                {/* </div> */}
                                            </div>
                                            <span className="error-message">{this.state.regError["registrationNo"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-3 nw-button-parent">
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-center"
                                                label="Search"
                                                icon="fas fa-search"
                                                onClick={this.onSearchHandler}
                                            />
                                        </div>


                                        <div className="p-col-12 p-xl-12" >
                                        {this.state.applicantFormVisibility ?
                                           <div>
                                            {/* // <Fieldset className="p-col-12 p-xl-12" legend={this.state.eligibilityStatus}> */}

                                                <div className="nw-search-view">
                                                    {this.state.searchViewError ?
                                                        <center className="error-message">{this.state.searchErrorMsgBody}</center> : ''}
                                                </div>

                                            
                                                <div className="p-grid">

                                                    <div className="p-col-12 p-xl-8 p-xl-offset-2">
                                                        <h1 className="text-center">
                                                            <u>Application Information </u>
                                                        </h1>
                                                    </div>

                                                    <div className="p-col-12 p-xl-2">
                                                        <center>
                                                            <img src={this.state.applicantPicture}
                                                                width="80px"
                                                            />
                                                        </center>
                                                    </div>
                                                </div>
                                                <div className="p-grid nw-form">
                                                    <div className="p-col-12 p-xl-6">
                                                        <div className="p-grid">

                                                            <div className="p-col-12 p-xl-12">
                                                                <div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-grid p-col-4">
                                                                            <label htmlFor="instituteContactNo">Registration No</label>
                                                                        </div>
                                                                        <span style={{ paddingRight: "5px" }}>:</span>
                                                                        <div className="p-grid p-col-8">
                                                                            {applicantUpdatedInfo.admisiaApplicantInfoDTO.registrationID}
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-grid p-col-4">
                                                                            <label htmlFor="instituteContactNo">Class</label>
                                                                        </div>
                                                                        <span style={{ paddingRight: "5px" }}>:</span>
                                                                        <div className="p-grid p-col-8">
                                                                            {applicantUpdatedInfo.admisiaApplicantInfoDTO.admisiaClassConfigDTO.classCoreCategoryInfoDTO.categoryName}
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-grid p-col-4">
                                                                            <label htmlFor="instituteContactNo">Group</label>
                                                                        </div>
                                                                        <span style={{ paddingRight: "5px" }}>:</span>
                                                                        <div className="p-grid p-col-8">
                                                                            {applicantUpdatedInfo.admisiaApplicantInfoDTO.admisiaClassConfigDTO.groupCoreCategoryInfoDTO.categoryName}
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-grid p-col-4">
                                                                            <label htmlFor="instituteContactNo">Application Date</label>
                                                                        </div>
                                                                        <span style={{ paddingRight: "5px" }}>:</span>
                                                                        <div className="p-grid p-col-8">
                                                                            {NetiDateUtils.getDateFromString(applicantUpdatedInfo.admisiaApplicantInfoDTO.applicationDate)}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="p-col-12 p-xl-6">
                                                        <div className="p-col-12 p-md-12">

                                                            <div className="p-grid p-col-12">
                                                                <div className="p-grid p-col-4">
                                                                    <label htmlFor="instituteContactNo">Assessment</label>
                                                                </div>
                                                                <span style={{ paddingRight: "5px" }}>:</span>
                                                                <div className="p-grid p-col-8">
                                                                    {applicantUpdatedInfo.admisiaApplicantInfoDTO.admisiaClassConfigDTO.admissionTestStatus == 0 ? "No" : "Yes"}
                                                                </div>
                                                            </div>

                                                            <div className="p-grid p-col-12">
                                                                <div className="p-grid p-col-4">
                                                                    <label htmlFor="instituteContactNo">Exam Date</label>
                                                                </div>
                                                                <span style={{ paddingRight: "5px" }}>:</span>
                                                                <div className="p-grid p-col-8">
                                                                    {"No"}
                                                                </div>
                                                            </div>

                                                            <div className="p-grid p-col-12">
                                                                <div className="p-grid p-col-4">
                                                                    <label htmlFor="instituteContactNo">Exam Mark</label>
                                                                </div>
                                                                <span style={{ paddingRight: "5px" }}>:</span>
                                                                <div className="p-grid p-col-8">
                                                                    {applicantUpdatedInfo.admisiaApplicantInfoDTO.examMark}
                                                                </div>
                                                            </div>

                                                            <div className="p-grid p-col-12">
                                                                <div className="p-grid p-col-4">
                                                                    <label htmlFor="instituteContactNo">Rejected Date</label>
                                                                </div>
                                                                <span style={{ paddingRight: "5px" }}>:</span>
                                                                <div className="p-grid p-col-8">
                                                                    {applicantUpdatedInfo.admisiaApplicantInfoDTO.applicantStatus == 2 ||
                                                                        applicantUpdatedInfo.admisiaApplicantInfoDTO.applicantStatus == 3 ? NetiDateUtils.getDateFromString(applicantUpdatedInfo.admisiaApplicantInfoDTO.lastDateExecuted) : null}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            
                                                <div className="p-col-12 p-xl-12">
                                                
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Student Name <span>*</span></span>
                                                            <InputText
                                                                value={applicantUpdatedInfo.admisiaApplicantInfoDTO.applicantName || ''}
                                                                onChange={this.onChangeApplicantName}
                                                                className="custom-form-control"
                                                                id="studentName"
                                                                name="studentName"
                                                                placeholder="Enter Student Name"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["studentName"]}</span>
                                                    </div>

                                                    
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Gender <span>*</span></span>
                                                            <Dropdown
                                                                placeholder="Select Gender "
                                                                value={applicantUpdatedInfo.admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.coreCategoryID || ''}
                                                                onChange={this.onChangeGender}
                                                                options={genderList}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="gender"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["gender"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Religion <span>*</span></span>
                                                            <Dropdown
                                                                placeholder="Select Religion"
                                                                value={applicantUpdatedInfo.admisiaApplicantInfoDTO.religionCoreCategoryInfoDTO.coreCategoryID || ''}
                                                                onChange={this.onChangeReligion}
                                                                options={religionList}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="religion"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["religion"]}</span>
                                                    </div>



                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Date Of Birth <span>*</span></span>
                                                            
                                                            <Calendar
                                                                value={this.state.dob || ""}
                                                                onChange={this.onChangeDateOfBirth}
                                                                dateFormat="dd/mm/yy"
                                                                id="dateOfBirth"
                                                                name="dateOfBirth"
                                                                yearRange="2010:2030"
                                                                monthNavigator={true}
                                                                yearNavigator={true}
                                                                showIcon={true}
                                                                name='dob'
                                                                className="custom-calender-input"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["dob"]}</span>
                                                    </div>


                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                              Guardian Contact No
                                                            </span>
                                                            <div className="nw-inputgroup-desc"> {applicantUpdatedInfo.admisiaApplicantInfoDTO.mobileNo}</div>
                                                        </div>
                                                    </div>


                                                    {/* 
                                                    <div className="p-grid p-col-12">
                                                        <div className="p-grid p-col-4">
                                                            <label htmlFor="guardianContactNo">Guardian Contact No</label>
                                                        </div>
                                                        <div className="p-grid p-col-8">
                                                            {applicantUpdatedInfo.admisiaApplicantInfoDTO.mobileNo}
                                                        </div>
                                                    </div> */}



                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Address <span>*</span></span>
                                                            <InputTextarea
                                                                className="custom-form-control"
                                                                id="address"
                                                                onChange={this.onChangeAddressDetails}
                                                                value={applicantUpdatedInfo.admisiaApplicantInfoDTO.addressDetails || ''}
                                                                name="address"
                                                                rows={5}
                                                                cols={30}
                                                                placeholder="Write Address"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["address"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Father's Name <span>*</span></span>
                                                            <InputText
                                                                value={applicantUpdatedInfo.admisiaApplicantInfoDTO.fatherName || ''}
                                                                onChange={this.onChangeFatherName}
                                                                className="custom-form-control"
                                                                id="fatherName"
                                                                name="fatherName"
                                                                placeholder="Enter Father's Name"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["fatherName"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Mother's Name <span>*</span></span>
                                                            <InputText
                                                                value={applicantUpdatedInfo.admisiaApplicantInfoDTO.motherName || ''}
                                                                onChange={this.onChangeMotherName}
                                                                className="custom-form-control"
                                                                id="motherName"
                                                                name="motherName"
                                                                placeholder="Enter Mother's Name"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["motherName"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Father's Occupation <span>*</span></span>
                                                            <Dropdown
                                                                id="fatherOccupation"
                                                                placeholder="Select Father's Occupation"
                                                                value={applicantUpdatedInfo.admisiaApplicantInfoDTO.fatherOccupationCategoryInfoDTO.coreCategoryID || ''}
                                                                onChange={this.onChangeFatherOccupation}
                                                                options={occupationList}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="fatherOccupation"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["fatherOccupation"]}</span>
                                                    </div>


                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Mother's Occupation <span>*</span></span>
                                                            <Dropdown
                                                                    placeholder="Select Mother's Occupation"
                                                                    value={applicantUpdatedInfo.admisiaApplicantInfoDTO.motherOccupationCategoryInfoDTO.coreCategoryID || ''}
                                                                    onChange={this.onChangeMotherOccupation}
                                                                    options={occupationList}
                                                                    showClear={true}
                                                                    autoWidth={false}
                                                                    name="motherOccupation"
                                                                />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["motherOccupation"]}</span>
                                                    </div>


                                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                                       
                                                        <Button
                                                            className="nw-button p-button-primary nw-button-right"
                                                            icon="fas fa-check"
                                                            label="Update"
                                                            onClick={this.onUpdateHandler}
                                                        />
                                                       
                                                    </div>

                                                </div>
                                             
                                            </div>
                                                

                                            // </Fieldset>
                                            : null}
                                    </div>






                                    </div>
                                </div>
                            </div>
                        
                    </div>

                    : 

                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/institute_config"
                                        className="rainbow-button"
                                        alt="Go Admisia Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                    }
                    
                </div>
            </div>
        );
    }
}