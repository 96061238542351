import React, { Component } from 'react';

import { Growl } from 'primereact/growl';
import { Chart } from "react-google-charts";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { primaryChartsColorArray, dangerChartsColorArray } from '../../common/ChartsColorArray';

let allChartHeader = ["title", "count"];
let emTotalIDArray = [];
let totalEdumanId = 0;

let emTotalLiveIDArray = [];
let totalLiveEdumanId = 0;

let emPackageWiseActiveArray = [];
let totalPackageWiseEdumanId = 0;

let emPackageWiseInActiveStatusFinal = [];
let totalPackageWiseInActiveEdumanId = 0;

let emPaymentTypeWiseActiveArray = [];
let totalemPaymentTypeWiseActive;

let emPaymentTypeWiseInActiveArray = [];
let totalemPaymentTypeWiseInActive;

let emInstiuteTypeWiseActiveArray = [];
let totalemInsTypeWiseActive;

let emInstiuteTypeWiseInActiveArray = [];
let totalemInsTypeWiseInActive;

let emBoardWiseActiveArray = [];
let totalboardWiseActiveInstitute;

let emBoardWiseInActiveArray = [];
let totalboardWiseInActiveInstitute;

let emDivisionWiseActiveArray = [];
let totalDivisionWiseActiveInstitute;

let emDivisionWiseInActiveArray = [];
let totalDivisionWiseInActiveInstitute;

let emRegionWiseActiveArray = [];
let totalregionWiseActiveInstitute;

let emRegionWiseInActiveArray = [];
let totalregionWiseInActiveInstitute;

let emRegionWiseActiveDueBillArray = [];
let regionWiseDueBillActive = [];
let totalregionWiseDueBillActiveInstitute;

let emRegionWiseInActiveDueBillArray = [];
let regionWiseDueBillInActive = [];
let totalregionWiseDueBillInActiveInstitute;

export class ManagementEMCurrentStatus extends Component {

    constructor() {
        super();
        this.state = {
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            emTotalInstitute: 0,
            emTotalID: [],
            emLiveTotal: 0,
            emLiveTotalID: [],
            emPackageWiseActiveTotal: 0,
            emPackageWiseInActiveTotal: 0,
            paymentTypeWiseActiveTotal: 0,
            paymentTypeWiseInActiveTotal: 0,
            insTypeWiseActiveTotal: 0,
            insTypeWiseInActiveTotal: 0,
            boardWiseActiveInstituteTotal: 0,
            boardWiseInActiveInstituteTotal: 0,
            divisionWiseActiveInstituteTotal: 0,
            divisionWiseInActiveInstituteTotal: 0,
            regionWiseActiveInstituteTotal: 0,
            regionWiseInActiveInstituteTotal: 0,
            regionWiseDueBillActiveInstituteTotal: 0,
            regionWiseDueBillInActiveInstituteTotal: 0,
            packageWiseActiveInstitute: [],
            packageWiseInActiveInstitute: [],
            instituteTypeWiseActiveInstitute: [],
            instituteTypeWiseInActiveInstitute: [],
            typeWiseActiveInstitute: [],
            typeWiseInActiveInstitute: [],
            boardWiseActiveInstitute: [],
            boardWiseInActiveInstitute: [],
            divisionWiseActiveInstitute: [],
            divisionWiseInActiveInstitute: [],
            regionWiseActiveInstitute: [],
            regionWiseInActiveInstitute: [],
            regionWiseActiveDuebillInstitute: [],
            regionWiseInActiveDuebillInstitute: []
        }

        this.CRMPointService = new CRMPointService();
        this.validatorUtility = new ValidatorUtility();
    }

    componentWillMount() {
        this.getEdumanDashboardDetails();

    }

    getEdumanDashboardDetails() {
        this.setState({ topProgressBar: true, errorMsgBody: false });
        this.CRMPointService.edumanDashboardChartsResults()
            .then(res => {
                if (res.status === 302) {
                    return res.json().then(body => {

                        this.setState({ topProgressBar: false, errorMsgBody: false, emTotalID: body.usedTypeWiseInstituteDashBoard, emLiveTotalID: body.liveInstituteDashBoard, packageWiseActiveInstitute: body.packageWiseActiveInstituteDashBoard, packageWiseInActiveInstitute: body.packageWiseInActiveInstituteDashBoard, instituteTypeWiseActiveInstitute: body.instituteTypeWiseActiveInstituteDashBoard, instituteTypeWiseInActiveInstitute: body.instituteTypeWiseInActiveInstituteDashBoard, typeWiseActiveInstitute: body.paymentWiseActiveInstituteDashBoard, typeWiseInActiveInstitute: body.paymentWiseInActiveInstituteDashBoard, boardWiseActiveInstitute: body.boardWiseActiveInstituteDashBoard, boardWiseInActiveInstitute: body.boardWiseInActiveInstituteDashBoard, divisionWiseActiveInstitute: body.divisionWiseActiveInstituteDashBoard, divisionWiseInActiveInstitute: body.divisionWiseInActiveInstituteDashBoard, regionWiseActiveInstitute: body.regionWiseActiveInstituteDashBoard, regionWiseInActiveInstitute: body.regionWiseInActiveInstituteDashBoard, regionWiseActiveDuebillInstitute: body.regionWiseActiveDueBillDashBoard, regionWiseInActiveDuebillInstitute: body.regionWiseInActiveDueBillDashBoard });
                        /***usedTypeWiseInstituteDashBoard start***/
                        totalEdumanId = body.usedTypeWiseInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ emTotalInstitute: totalEdumanId });
                        emTotalIDArray.push(allChartHeader, ...body.usedTypeWiseInstituteDashBoard);
                        /***usedTypeWiseInstituteDashBoard end***/

                        /***liveInstituteDashBoard start***/
                        totalLiveEdumanId = body.liveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ emLiveTotal: totalLiveEdumanId });
                        emTotalLiveIDArray.push(allChartHeader, ...body.liveInstituteDashBoard);
                        /***liveInstituteDashBoard end***/

                        /***packageWiseActiveInstituteDashBoard start***/
                        totalPackageWiseEdumanId = body.packageWiseActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ emPackageWiseActiveTotal: totalPackageWiseEdumanId });
                        emPackageWiseActiveArray.push(allChartHeader, ...body.packageWiseActiveInstituteDashBoard);

                        /***packageWiseActiveInstituteDashBoard end***/

                        /***packageWiseInActiveInstituteDashBoard start***/
                        totalPackageWiseInActiveEdumanId = body.packageWiseInActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ emPackageWiseInActiveTotal: totalPackageWiseInActiveEdumanId });
                        emPackageWiseInActiveStatusFinal.push(allChartHeader, ...body.packageWiseInActiveInstituteDashBoard);

                        /***packageWiseInActiveInstituteDashBoard end***/

                        /***paymentWiseActiveInstituteDashBoard start***/
                        totalemPaymentTypeWiseActive = body.paymentWiseActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ paymentTypeWiseActiveTotal: totalemPaymentTypeWiseActive });
                        emPaymentTypeWiseActiveArray.push(allChartHeader, ...body.paymentWiseActiveInstituteDashBoard);
                        /***paymentWiseActiveInstituteDashBoard end***/

                        /***paymentWiseInActiveInstituteDashBoard start***/
                        totalemPaymentTypeWiseInActive = body.paymentWiseInActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ paymentTypeWiseInActiveTotal: totalemPaymentTypeWiseInActive });
                        emPaymentTypeWiseInActiveArray.push(allChartHeader, ...body.paymentWiseInActiveInstituteDashBoard);
                        /***paymentWiseInActiveInstituteDashBoard end***/

                        /***instituteTypeWiseActiveInstituteDashBoard start***/
                        totalemInsTypeWiseActive = body.instituteTypeWiseActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ insTypeWiseActiveTotal: totalemInsTypeWiseActive });
                        emInstiuteTypeWiseActiveArray.push(allChartHeader, ...body.instituteTypeWiseActiveInstituteDashBoard);

                        /***instituteTypeWiseActiveInstituteDashBoard end***/

                        /***instituteTypeWiseInActiveInstituteDashBoard start***/
                        totalemInsTypeWiseInActive = body.instituteTypeWiseInActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ insTypeWiseInActiveTotal: totalemInsTypeWiseInActive });
                        emInstiuteTypeWiseInActiveArray.push(allChartHeader, ...body.instituteTypeWiseInActiveInstituteDashBoard);

                        /***instituteTypeWiseInActiveInstituteDashBoard end***/

                        /***boardWiseActiveInstituteDashBoard start***/
                        totalboardWiseActiveInstitute = body.boardWiseActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ boardWiseActiveInstituteTotal: totalboardWiseActiveInstitute });
                        emBoardWiseActiveArray.push(allChartHeader, ...body.boardWiseActiveInstituteDashBoard);
                        /***boardWiseActiveInstituteDashBoard end***/

                        /***boardWiseInActiveInstituteDashBoard start***/
                        totalboardWiseInActiveInstitute = body.boardWiseInActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ boardWiseInActiveInstituteTotal: totalboardWiseInActiveInstitute });
                        emBoardWiseInActiveArray.push(allChartHeader, ...body.boardWiseInActiveInstituteDashBoard);
                        /***boardWiseInActiveInstituteDashBoard end***/

                        /***divisionWiseActiveInstituteDashBoard start***/
                        totalDivisionWiseActiveInstitute = body.divisionWiseActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ divisionWiseActiveInstituteTotal: totalDivisionWiseActiveInstitute });
                        emDivisionWiseActiveArray.push(allChartHeader, ...body.divisionWiseActiveInstituteDashBoard);
                        /***divisionWiseActiveInstituteDashBoard end***/

                        /***divisionWiseInActiveInstituteDashBoard start***/
                        totalDivisionWiseInActiveInstitute = body.divisionWiseInActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ divisionWiseInActiveInstituteTotal: totalDivisionWiseInActiveInstitute });
                        emDivisionWiseInActiveArray.push(allChartHeader, ...body.divisionWiseInActiveInstituteDashBoard);

                        /***divisionWiseInActiveInstituteDashBoard end***/

                        /***regionWiseActiveInstituteDashBoard start***/
                        totalregionWiseActiveInstitute = body.regionWiseActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ regionWiseActiveInstituteTotal: totalregionWiseActiveInstitute });
                        emRegionWiseActiveArray.push(allChartHeader, ...body.regionWiseActiveInstituteDashBoard);
                        /***regionWiseActiveInstituteDashBoard end***/

                        /***regionWiseInActiveInstituteDashBoard start***/
                        totalregionWiseInActiveInstitute = body.regionWiseInActiveInstituteDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ regionWiseInActiveInstituteTotal: totalregionWiseInActiveInstitute });
                        emRegionWiseInActiveArray.push(allChartHeader, ...body.regionWiseInActiveInstituteDashBoard);

                        /***regionWiseInActiveInstituteDashBoard end***/

                        /***regionWiseActiveDueBillDashBoard start***/
                        regionWiseDueBillActive = body.regionWiseActiveDueBillDashBoard.map(item => [item[0], item[1]]);
                        totalregionWiseDueBillActiveInstitute = body.regionWiseActiveDueBillDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ regionWiseDueBillActiveInstituteTotal: totalregionWiseDueBillActiveInstitute });
                        emRegionWiseActiveDueBillArray.push(allChartHeader, ...regionWiseDueBillActive);
                        /***regionWiseActiveDueBillDashBoard end***/

                        /***regionWiseInActiveDueBillDashBoard start***/
                        regionWiseDueBillInActive = body.regionWiseInActiveDueBillDashBoard.map(item => [item[0], item[1]]);
                        totalregionWiseDueBillInActiveInstitute = body.regionWiseInActiveDueBillDashBoard.map(item => item[1]).reduce((a, c) => a + c, 0);
                        this.setState({ regionWiseDueBillInActiveInstituteTotal: totalregionWiseDueBillInActiveInstitute });
                        emRegionWiseInActiveDueBillArray.push(allChartHeader, ...regionWiseDueBillInActive);
                        /***regionWiseInActiveDueBillDashBoard end***/

                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then(resp => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let totalPackageWiseActiveInstitute = this.state;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section netiWorld-DashBoard-wrapper">
                    <div className="netiWorld-DashBoard">
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12 p-md-12 p-sm-12 p-t-0">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Eduman Current Status for Management</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-0">

                                    <div className="p-col-12 p-xl-6 p-md-12 p-sm-12 p-l-0">
                                        <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        {emTotalIDArray.length ?
                                                            <Chart
                                                                width={'196px'}
                                                                height={'196px'}
                                                                chartType="PieChart"
                                                                loader={<div>Loading Chart</div>}
                                                                data={emTotalIDArray}
                                                                legend={{
                                                                    position: "bottom",
                                                                    alignment: "center",
                                                                    // position: 'labeled',
                                                                    textStyle: { color: "233238", fontSize: 16 }
                                                                }}
                                                                options={{
                                                                    chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                    backgroundColor: 'transparent',
                                                                    tooltip: { trigger: 'block', showColorCode: true },
                                                                    // pieSliceText: 'none',
                                                                    legend: 'none',
                                                                    slices: {
                                                                        0: { color: '#2F3292' },
                                                                        1: { color: '#0099FF' },
                                                                    },
                                                                }}
                                                            />
                                                            : 'No data found yet'}

                                                    </div>
                                                    <span className="chart-box-title">Total Eduman ID</span>
                                                    <br />
                                                    <span className="chart-box-title">{this.state.emTotalInstitute != '' ? this.state.emTotalInstitute.toLocaleString('EN-IN') : '0'}</span>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span style={{ background: "#2F3292" }}></span><p>Live ID</p></td>
                                                                        <td><p>: {this.state.emTotalID.length ? this.state.emTotalID[0][1].toLocaleString('EN-IN') : '0'}</p></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span style={{ background: "#0099FF" }}></span><p>Practice ID</p></td>
                                                                        <td><p>: {this.state.emTotalID.length ? this.state.emTotalID[1][1].toLocaleString('EN-IN') : '0'}</p></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                        <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        {emTotalLiveIDArray.length ?
                                                            <Chart
                                                                width={'196px'}
                                                                height={'196px'}
                                                                chartType="PieChart"
                                                                loader={<div>Loading Chart</div>}
                                                                data={emTotalLiveIDArray}
                                                                legend={{
                                                                    position: "bottom",
                                                                    alignment: "center",
                                                                    position: 'labeled',
                                                                    textStyle: {
                                                                        color: "233238",
                                                                        fontSize: 16
                                                                    }
                                                                }}
                                                                options={{
                                                                    chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                    backgroundColor: 'transparent',
                                                                    tooltip: { trigger: 'block', showColorCode: true },
                                                                    // pieSliceText: 'none',
                                                                    legend: 'none',
                                                                    slices: {
                                                                        0: { color: '#5b2d90' },
                                                                        1: { color: '#3ebff8' },
                                                                        2: { color: '#f85050' },
                                                                    },
                                                                }}
                                                            />
                                                            : 'No data found yet'}
                                                    </div>
                                                    <span className="chart-box-title">Total Eduman Live ID</span>
                                                    <br /><span className="chart-box-title">{this.state.emLiveTotal ? this.state.emLiveTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                            </div>
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span style={{ background: "#5b2d90" }}></span><p>Active</p></td>
                                                                        <td><p>: {this.state.emLiveTotalID[0] && this.state.emLiveTotalID[0][1] ? this.state.emLiveTotalID[0][1].toLocaleString('EN-IN') : '0'}</p></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span style={{ background: "#3ebff8" }}></span><p>Inactive</p></td>
                                                                        <td><p>: {this.state.emLiveTotalID[1] && this.state.emLiveTotalID[1][1] ? this.state.emLiveTotalID[1][1].toLocaleString('EN-IN') : '0'}</p></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span style={{ background: "#f85050" }}></span><p>Terminat</p></td>
                                                                        <td><p>: {this.state.emLiveTotalID[2] && this.state.emLiveTotalID[2][1] ? this.state.emLiveTotalID[2][1].toLocaleString('EN-IN') : '0'}</p></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Package Wise <span className="active">Active</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emPackageWiseActiveArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emPackageWiseActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: primaryChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Active EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.emPackageWiseActiveTotal ? this.state.emPackageWiseActiveTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list">
                                                    <table>
                                                        <tbody>
                                                            {this.state.packageWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Package Wise <span className="inActive">Inactive</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emPackageWiseInActiveStatusFinal.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emPackageWiseInActiveStatusFinal}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: dangerChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Inactive EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.emPackageWiseInActiveTotal ? this.state.emPackageWiseInActiveTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list">
                                                    <table>
                                                        <tbody>
                                                            {this.state.packageWiseInActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: dangerChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Type Wise <span className="active">Active</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emPaymentTypeWiseActiveArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emPaymentTypeWiseActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: primaryChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Active EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.paymentTypeWiseActiveTotal ? this.state.paymentTypeWiseActiveTotal.toLocaleString('EN-IN') : ''}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list">
                                                    <table>
                                                        <tbody>
                                                            {this.state.typeWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Type Wise <span className="inActive">Inactive</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emPaymentTypeWiseInActiveArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emPaymentTypeWiseInActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: dangerChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Inactive EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.paymentTypeWiseInActiveTotal ? this.state.paymentTypeWiseInActiveTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list">
                                                    <table>
                                                        <tbody>
                                                            {this.state.typeWiseInActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: dangerChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Category Wise <span className="active">Active</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emInstiuteTypeWiseActiveArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emInstiuteTypeWiseActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: primaryChartsColorArray
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Active EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.insTypeWiseActiveTotal ? this.state.insTypeWiseActiveTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.instituteTypeWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Category Wise <span className="inActive">Inactive</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emInstiuteTypeWiseInActiveArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emInstiuteTypeWiseInActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: dangerChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Inactive EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.insTypeWiseInActiveTotal ? this.state.insTypeWiseInActiveTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.instituteTypeWiseInActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: dangerChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Board Wise <span className="active">Active</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emBoardWiseActiveArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emBoardWiseActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: primaryChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}
                                            </div>
                                            <span className="chart-box-title">Total Active EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.boardWiseActiveInstituteTotal ? this.state.boardWiseActiveInstituteTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.boardWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Board Wise <span className="inActive">Inactive</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emBoardWiseInActiveArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emBoardWiseInActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: dangerChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Inactive EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.boardWiseInActiveInstituteTotal ? this.state.boardWiseInActiveInstituteTotal.toLocaleString('EN-IN') : 0}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.boardWiseInActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: dangerChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Division Wise <span className="active">Active</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emDivisionWiseActiveArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emDivisionWiseActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: primaryChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Active EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.divisionWiseActiveInstituteTotal ? this.state.divisionWiseActiveInstituteTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.divisionWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Division Wise <span className="inActive">Inactive</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emDivisionWiseInActiveArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emDivisionWiseInActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: dangerChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Inactive EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.divisionWiseInActiveInstituteTotal ? this.state.divisionWiseInActiveInstituteTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.divisionWiseInActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: dangerChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Region Wise <span className="active">Active</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emRegionWiseActiveArray.length ?
                                                    <Chart
                                                        width={'800px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emRegionWiseActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: primaryChartsColorArray,
                                                        }}
                                                        rootProps={{ 'data-testid': '9' }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Active EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.regionWiseActiveInstituteTotal ? this.state.regionWiseActiveInstituteTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.regionWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Region Wise <span className="inActive">Inactive</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emRegionWiseInActiveArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emRegionWiseInActiveArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: dangerChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Inactive EM ID</span>
                                            <br /><span className="chart-box-title">{this.state.regionWiseInActiveInstituteTotal ? this.state.regionWiseInActiveInstituteTotal.toLocaleString('EN-IN') : '0'}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.regionWiseInActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {item[1].toLocaleString('EN-IN')}</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Region Wise <span className="active">Active</span> Eduman Due Bill</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emRegionWiseActiveDueBillArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emRegionWiseActiveDueBillArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: primaryChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}
                                            </div>
                                            <span className="chart-box-title">Total Active Due</span>
                                            <br /><span className="chart-box-title">{this.state.regionWiseDueBillActiveInstituteTotal ? this.validatorUtility.currencyFormatter(this.state.regionWiseDueBillActiveInstituteTotal.toFixed(2)) : '0'} Tk</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.regionWiseActiveDuebillInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {this.validatorUtility.currencyFormatter(item[1])} Tk</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Region Wise <span className="inActive">Inactive</span> Eduman Due Bill</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                {emRegionWiseInActiveDueBillArray.length ?
                                                    <Chart
                                                        width={'196px'}
                                                        height={'196px'}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={emRegionWiseInActiveDueBillArray}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            // position: 'labeled',
                                                            textStyle: { color: "233238", fontSize: 16 }
                                                        }}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            tooltip: { trigger: 'block', showColorCode: true },
                                                            // pieSliceText: 'none',
                                                            legend: 'none',
                                                            slices: dangerChartsColorArray,
                                                        }}
                                                    />
                                                    : 'No data found yet'}

                                            </div>
                                            <span className="chart-box-title">Total Inactive Due</span>
                                            <br /><span className="chart-box-title">{this.state.regionWiseDueBillInActiveInstituteTotal ? this.validatorUtility.currencyFormatter(this.state.regionWiseDueBillInActiveInstituteTotal.toFixed(2)) : '0'} Tk</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.regionWiseInActiveDuebillInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: dangerChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                        <td><p>: {this.validatorUtility.currencyFormatter(item[1])} Tk</p></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}