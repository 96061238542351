import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { DataView } from 'primereact/dataview';
import { UserCategoryService } from "../../service/UserCategoryService";
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import NetiContentLoader from '../common/NetiContentLoader';

let remainInstituteArray = [];

export class EdumanBillProcess extends Component {

    constructor() {
        super();
        this.state = {
            billingYearList: [],
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            billingYear: '',
            billingMonth: '',
            dataTableValue: [],
            remainInstituteList:[],
            visible: '',
            errors: [],
            firstDropDownIsLoading: false,
            dataTableIsLoading: false
        }
        this.onChangeBillingYear = this.onChangeBillingYear.bind(this);
        this.onChangeBillingMonth = this.onChangeBillingMonth.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.NetiContentLoader = new NetiContentLoader();

        this.userCategoryService = new UserCategoryService();
        this.crmPointService = new CRMPointService();
    }

    componentWillMount() {
        this.getAcademicYearListByTypeID()
    }

    getAcademicYearListByTypeID = () => {
        this.setState({ topProgressBar: true, firstDropDownIsLoading: true, errorMsgVisible: false })

        this.userCategoryService.fetchListByDefaultCode("T107")
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ billingYearList: body, firstDropDownIsLoading: false, topProgressBar: false })
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                };
            }).catch((error) => {
                this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeBillingYear(value) {
        let { errors } = this.state;
        errors["billingYear"] = '';
        this.setState({ billingYear: value, errors })
    };
    onChangeBillingMonth(value) {
        let { errors } = this.state;
        errors["billingMonth"] = '';
        this.setState({ billingMonth: value, errors })
    };

    submitSearch() {
        this.setState({ dataTableValue: [] });
        if (this.handleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            this.crmPointService.fetchEM_Bill_Processable_InstituteList(this.state.billingYear, this.state.billingMonth)
                .then(res => {
                    // if (res.status == 302) {
                        return res.json().then((body) => {
                            console.log("Hitting API",body);
                            
                            this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false })
                        });
                    // } else {
                    //     this.crmPointService.Auth.handleErrorStatus(res)
                    //         .then((resp) => {
                    //             this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                    //         });
                    // };
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }

    submitProcess = (e, remainInstitutes) => {
        console.log('submit', remainInstitutes);
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.crmPointService.edumanBillProcessSubmit(remainInstitutes, this.state.billingYear, this.state.billingMonth)
            .then(res => {
                console.log('submitProcess', res)
                if (res.status == 302 || res.status == 202) {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: 'Bill Processed Successfully' });
                    this.setState({ topProgressBar: false, errorMsgVisible: false });
                    this.submitSearch()
                } else {
                    this.crmPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    handleError = () => {

        let { errors } = this.state
        let formIsValid = true;

        if (this.state.billingYear === '' || this.state.billingYear === null) {
            formIsValid = false;
            errors["billingYear"] = "Billing Year can't left empty.";
        }

        if (this.state.billingMonth === '' || this.state.billingMonth === null) {
            formIsValid = false;
            errors["billingMonth"] = "Billing Month can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    popRemainInstitutes =(remainInstitutes)=>{

        this.setState({remainInstituteList: remainInstitutes + "," })
        }

    itemTemplate(dataTableValue) {

        remainInstituteArray = [];
        let disabledStatus = false;
        let remainInstituteID = '';

        if (!dataTableValue.remainInstitutes.length) {
            console.log('remainArrNull'); disabledStatus = true;
        } else {

            disabledStatus = false;
            for (let index = 0; index < dataTableValue.remainInstitutes.length; index++) {
                { index < (dataTableValue.remainInstitutes.length) - 1 ? remainInstituteID = `${dataTableValue.remainInstitutes[index]}, ` : remainInstituteID = dataTableValue.remainInstitutes[index] }
                remainInstituteArray.push(remainInstituteID)
            }
            // console.log('remainInstituteArray', remainInstituteArray);
        }

        return (
            <div className="p-col-12 p-md-4">
                {this.state.dataTableIsLoading ?
                    this.NetiContentLoader.MyPointTableLoader() :
                    <Card className='text-center'>

                        <label>INSTITUTE ID</label><br />
                        {dataTableValue.firstInstituteId} - {dataTableValue.lastInstituteId}({dataTableValue.totalInstitute})<br />
                        <span>Remain for process </span> <span><i className="far fa-eye" onMouseOver={(e) => {this.op.toggle(e); this.popRemainInstitutes(dataTableValue.remainInstitutes)}} onMouseLeave={(e) => {this.op.hide(e);  this.popRemainInstitutes([])}} ></i></span><br /><br />


                        <div className="p-grid">
                            <div className="p-col-12 p-md-4" />
                            {/* <Button type="button" label="Basic" onClick={(e) => this.op.toggle(e)} /> */}
                            {/* <span><Button icon='fas fa-eye' onClick={(e) => this.op.toggle(e)} /></span> */}

                            <div className="p-col-12 p-md-4"><Button label='Process' icon='fas fa-eye' onClick={e => this.submitProcess(e, dataTableValue.remainInstitutes)} disabled={disabledStatus} /></div>
                        </div>




                        <OverlayPanel ref={(el) => { this.op = el;}} style={{ width: '40vw',wordBreak: 'break-all' }} showCloseIcon={false} >
                            {/* <div className="p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12"> */}
                                        {this.state.remainInstituteList}
                                    {/* </div>
                                </div>
                            </div> */}
                        </OverlayPanel>
                    </Card>
                }

            </div>
        )
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let yearOptions = [];
        if (this.state.billingYearList != null && this.state.billingYearList.length) {
            yearOptions = this.state.billingYearList.map((item) => ({
                value: item.categoryName,
                label: item.categoryName,
            }));
        }

        let monthOptions = [
            {
                value: "January",
                label: "January"
            },
            {
                value: "February",
                label: "February"
            },
            {
                value: "March",
                label: "March"
            },
            {
                value: "April",
                label: "April"
            },
            {
                value: "May",
                label: "May"
            },
            {
                value: "June",
                label: "June"
            },
            {
                value: "July",
                label: "July"
            },
            {
                value: "August",
                label: "August"
            },
            {
                value: "September",
                label: "September"
            },
            {
                value: "October",
                label: "October"
            },
            {
                value: "November",
                label: "November"
            },
            {
                value: "December",
                label: "December"
            }
        ];

        return (

            <div className="p-fluid">
                <div className="p-grid nw-form">
                    <Growl ref={(el) => this.growl = el} />
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    
                    <div className="p-col-12 p-xl-12">

                        <div className="nw-form-body">

                            <div className="p-col-12 p-xl-12">
                                {this.state.firstDropDownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Billing Year <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Billing Year'
                                            name="billingYear"
                                            options={yearOptions}
                                            value={this.state.billingYear}
                                            onChange={(e) => this.onChangeBillingYear(e.target.value)}
                                            filter={true}
                                            autoWidth={false}
                                        />
                                    </div>
                                }
                                <span className='error-message'>{this.state.errors['billingYear']}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Billing Month <span>*</span></span>

                                    <Dropdown
                                        placeholder='Select Billing Month'
                                        name="status"
                                        options={monthOptions}
                                        value={this.state.billingMonth}
                                        onChange={(e) => this.onChangeBillingMonth(e.target.value)}
                                        filter={true}
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors['billingMonth']}</span>
                            </div>


                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                <div className="required-field">
                                    (<span>*</span>) required fields
                                </div>

                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Show"
                                    icon="fas fa-search"
                                    onClick={event => this.submitSearch(event)}
                                />
                            </div>
                        </div>
                    </div>

                   
                    <div className="p-col-12 p-xl-12">
                        <DataView value={this.state.dataTableValue} layout={'Grid'} itemTemplate={this.itemTemplate}></DataView>
                    </div>
                    

                </div>
                
            </div>

        )
    }
}