import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Row } from 'primereact/row';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import NetiContentLoader from '../../../common/NetiContentLoader';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { Link } from 'react-router-dom';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { Workbook } from 'react-excel-workbook';
import { pdfDownloadPortrait_TableOnly } from '../../../common/JsPdfDownload';
import { SplitButton } from 'primereact/splitbutton';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { Dialog } from 'primereact/dialog';

let type = '';
// let tokenInfo

export class PendingProcessingTokenListEmNw extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pendingTokenList: [],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            module: '',

            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ],
            visible:false,
            tokenDetails: ''

        }
        this.managementPointService = new ManagementPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    componentDidMount() {
        // let searchObjProps
        // let fromSession
        if (this.props.location.appName) {
            // let searchObjProps = {
            //     tokenStatus: this.props.location.tokenStatus,
            //     appName: this.props.location.appName,
            // }
            localStorage.setItem("tokenStatus", this.props.location.tokenStatus,);
            localStorage.setItem("appName", this.props.location.appName);
            // fromSession = localStorage.getItem("searchObj")
        }

         
        if(localStorage.getItem("tokenStatus") && localStorage.getItem("appName")){
            // console.log('fromSession', localStorage.getItem("searchObj"));
            this.getTokenInfoList(localStorage.getItem("tokenStatus"), localStorage.getItem("appName"));
        }

        
        

    }

    componentWillUnmount(){
        localStorage.removeItem('tokenStatus');
        localStorage.removeItem('appName');
    }

    getTokenInfoList = (tokenStatus, appName) => {
        // console.log('searchObj', searchObj);
        // const moduleInfo = JSON.parse(localStorage.getItem("moduleWiseTokenInfo"));

        // this.setState({
        //     module: moduleInfo.module
        // })

        // if (!moduleInfo) {
        //     return;
        // }
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.managementPointService.fetchPendingTokenInfo(tokenStatus, appName)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            body[i].createDate = NetiDateUtils.getAnyShortForm(body[i].createDate, 'DD-MMM-YYYY hh:mm:ss a');
                        }
                        this.setState({ pendingTokenList: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    tokenBody = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-info"
                title='Click to view details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
        
    }

    viewDialog = (rowData) => {
        
        this.setState({
            visible: true,
            tokenDetails: rowData.tokenDetails

        })
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    export = () => {
        this.dt.exportCSV();
    }

    exportPdf() {

        let pdfHeader = `${type} Wise Token List of ${this.state.module}`
        let pdfColumns = [
            { title: "Submit Date & Time", dataKey: "createDate" },
            { title: "Token ID", dataKey: "tokenInfoID" },
            { title: "Problem Module", dataKey: "parentCategoryName" },
            { title: "Problem Title", dataKey: "categoryName" },
            { title: "Contact No", dataKey: "tokenContact" },
            { title: "Submit Duration", dataKey: "submitDuration" },
        ]
        let tableColStyle = {};

        pdfDownloadPortrait_TableOnly(pdfHeader, 68, pdfColumns, this.state.pendingTokenList, tableColStyle, this.state.module + "Token_List.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    onHide = () => {
        this.setState({ visible: false })
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tokenInfo, appName
        if(localStorage.getItem("tokenStatus")){
            tokenInfo=localStorage.getItem("tokenStatus");
            appName=localStorage.getItem("appName");
        }

        // excel
        let excelColumns = "";
        let excelFileName = this.state.module + "_Token_List.xlsx";
        let excelSheetName = this.state.module + "_Token_List";

        excelColumns = [
            <Workbook.Column value="createDate" label="Submit Date & Time" />,
            <Workbook.Column value="tokenInfoID" label="Token ID" />,
            <Workbook.Column value="parentCategoryName" label="Problem Module" />,
            <Workbook.Column value="categoryName" label="Problem Title" />,
            <Workbook.Column value="tokenContact" label="Contact No" />,
            <Workbook.Column value="submitDuration" label="Submit Duration" />,
        ];
        // end excel

        if (appName == "nw") { type = 'Netiworld' }
        if (appName == "em") { type = 'Eduman' }

        let header = <div className="header-title">
            <div className="header-title-left" style={{textTransform: 'capitalize'}}>
                {type} {tokenInfo} Token List
            </div>
            
            <div className="header-title-right"><a>Total Found: {this.state.pendingTokenList.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section blur-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">

                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            header={header}
                                            responsive={true}
                                            selectionMode="single"
                                            paginator={true}
                                            ref={(el) => { this.dt = el; }}
                                            rows={10}
                                            value={this.state.pendingTokenList}
                                        >
                                            <Column field="createDate" header="Submit Date & Time" className="table-datetime" filter={true} />
                                            <Column field="tokenInfoID" header="Token ID" filter={true} />
                                            <Column field="parentCategoryName" header="Problem Module" filter={true} />
                                            <Column field="categoryName" header="Problem Title" filter={true} />
                                            <Column field="tokenContact" header="Contact No." filter={true} />
                                            <Column field="submitDuration" header="Submit Duration" filter={true} />
                                            <Column field="solverName" header="Processing At" filter={true} style={tokenInfo == 'processing' ? {display: 'table-cell'} :{display: 'none'}}/>
                                            <Column field="" header="Action" body={this.tokenBody} />
                                        </DataTable>
                                }
                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                <div style={{ display: 'none' }}>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                label="Download Excel"
                                                id="clickExcelBtn"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.pendingTokenList} name={excelSheetName}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>

                            </div >
                        </div>
                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog
                        header="Token Information"
                        className="nw-dialog"
                        onHide={this.onHide}
                        visible={this.state.visible}
                    >

                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-form-body">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="nw-search-view">
                                                    <div>
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Details</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc demo" id='demo'>
                                                                    {this.state.tokenDetails}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>

                {/* </div> */}

            </div>
        )
    }
}