import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService'

export class TeacherPortal {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    getStaffProfileInfo(instituteId,staffId) {

        let uri = this.baseURIHolder.getUser()+'/staff/profile?instituteId='+instituteId+'&staffId='+staffId;

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    console.log('No date found this Redirect Api ', uri);
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }


    getMonthWiseStaffAttendanceInfo(instituteId,monthName,staffId,year) {

        let uri = this.baseURIHolder.getUser()+'/staff/month-wise/single/staff/attendance/details?instituteId='+instituteId+'&monthName='+monthName+'&staffId='+staffId+'&year='+year;

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    console.log('No date found this Redirect Api ', uri);
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }



    getYearWiseStaffPayroll(instituteId,staffId,year) {

        let uri = this.baseURIHolder.getUser()+'/staff/yearly/single-staff/payroll/details?instituteId='+instituteId+'&staffId='+staffId+'&year='+year;

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    console.log('No date found this Redirect Api ', uri);
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }

    fetchStaffShiftList(instituteId, staffId) {

        let uri = this.baseURIHolder.getUser()+'/staff/assigned-shifts?instituteId='+instituteId+'&staffId='+staffId;
        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    console.log('No date found this Redirect Api ', uri);
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }

    getStaffRoutine(instituteId,shiftId,staffId) {

        let uri = this.baseURIHolder.getUser()+'/staff/class-routine?instituteId='+instituteId+'&shiftId='+shiftId+'&staffId='+staffId;

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    console.log('No date found this Redirect Api ', uri);
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }

    isStaffAsAUser(contactNo) {

        let uri = this.baseURIHolder.getUser()+'/staff/is/user?contactNo='+contactNo;

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    console.log('No date found this Redirect Api ', uri);
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }
}






