import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { InputMask } from 'primereact/inputmask';
import { ProfileService } from '../../../service/profile/ProfileService'; 
import {Dialog} from 'primereact/dialog';
import {Growl} from 'primereact/growl';
import { ProgressSpinner } from 'primereact/progressspinner';
import { PasswordRequired } from '../common/PasswordRequired';
import AuthService from '../../../auth/AuthService';
import { GUEST_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';

const Auth=new AuthService();

let errors = {};
export class ChangeUserNameForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            oldusernameerror: false,
            upperCaseCheck: false,
            otpOpen:false,
            commonError:false,
            otpError:false,
            passwordIcon: true,
            hidden: true,
            usernameState :{
                oldusername:'',
                oldusernamereturn:'hossain',
                newUsername:''
            },
            otpstate:{
                otp:''
            },
            changePassword: {
                passwordSet: '',
                passwordMatch: '12',
                emptyPassword: false,
            },
            ProgressSpinner: false,
            renderLogoutDialog: false,
            requiredPassword: true,
            errors:{}
        }
        this.profileService = new ProfileService();

        this.oldUsercheckHandle = this.oldUsercheckHandle.bind(this);
        this.newUsercheckHandle = this.newUsercheckHandle.bind(this);
        this.otpHandle = this.otpHandle.bind(this);
        this.userNameOnSubmit = this.userNameOnSubmit.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        // this.passwordOnSubmit = this.passwordOnSubmit.bind(this);
        this.otpOnSubmit = this.otpOnSubmit.bind(this);
        this.dialogOnHide = this.dialogOnHide.bind(this);
        this.logoutTimer = this.logoutTimer.bind(this);
        // this.handleError = this.handleError.bind.bind(this);

     

        
        
    }

    componentDidMount() {
            
    }

    logoutTimer(){
        this.setState({renderLogoutDialog: true})
        setTimeout(function() { //Start the timer
            this.setState({renderLogoutDialog: false}) //After 5 second, set render to true
            Auth.logout();
            window.location.href="/";
        }.bind(this), 5000)
    }
    
    oldUsercheckHandle = (event, props) => {
        let { usernameState } = this.state;

        usernameState[event.target.name] = event.target.value;
        this.setState({ usernameState });
        let oldusernamereturn = this.state.usernameState.oldusernamereturn
        let oldusername = this.state.usernameState.oldusername

    }

    newUsercheckHandle = (event, props) => {

        errors["newUserNameError"] = "";

        let { usernameState } = this.state;
        usernameState[event.target.name] = event.target.value;
        let newUsername = this.state.usernameState.newUsername
        if (newUsername.toLowerCase() != newUsername)
        {
            this.setState({upperCaseCheck: true});
        }else{
            this.setState({upperCaseCheck: false});
        }
    }

    dialogOnHide(event) {
        window.location.replace(GUEST_POINT.LOGIN);
    }

    onChangeHandlePassword = (event, props) => {
        let { changePassword } = this.state;
        changePassword[event.target.name] = event.target.value;
        this.setState({ changePassword });
    }
    togglePasswordShow(event, props) {
        this.setState({
            hidden: !this.state.hidden,
            passwordIcon: !this.state.passwordIcon
        });

    }

    otpHandle = (event, props) => {
        let { otpstate } = this.state;
        otpstate[event.target.name] = event.target.value;
    }

    handleError() {
        
        let formIsValid = true;

        if (this.state.usernameState.oldusername === '') {
            formIsValid = false;
            errors["oldUserNameError"] = "Field is required";
        }
        else if (this.state.usernameState.newUsername === '') {
            formIsValid = false;
            errors["newUserNameError"] = "Field is required";
        }
        else if (this.state.usernameState.oldusername != '' && this.state.usernameState.newUsername === '') {
            formIsValid = false;
            errors["oldUserNameError"] = "";
            errors["newUserNameError"] = "Field is required";
        }
        else if (this.state.usernameState.oldusername != '' && this.state.usernameState.newUsername != '' &&
            this.state.usernameState.newUsername === this.state.usernameState.oldusername) {
            formIsValid = false;
            errors["oldUserNameError"] = "";
            errors["newUserNameError"] = "New Username can't same as old";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    userNameOnSubmit(){
        if(this.handleError() === true){
            this.setState({topProgressBar: true, errorMsgVisible: false});

            let { usernameState } = this.state;

            let userInfo = {
                "previousUsername": usernameState.oldusername,
                "userName": usernameState.newUsername
            }

            this.profileService.checkUserNameForUpdate(userInfo)
            .then((res)=>{

                this.setState({ progressSpinner: true})

                if(res.status === 200){
                    errors['oldUserNameError'] = ""
                    this.setState({ otpOpen: true, progressSpinner: false })
                    this.setState({topProgressBar: false, errorMsgVisible: false});
                }
                // else if(res.status === 400){
                //     errors['oldUserNameError'] = "Please Check Username"
                //     this.setState({
                //         progressSpinner: false,
                //         errors: errors['oldUserNameError']
                //     })
                // }
                // else if(res.status === 500){
                //     errors['newUserNameError'] = "Unable to send code. Please try again."
                //     this.setState({
                //         progressSpinner: false,
                //         errors: errors['newUserNameError']
                //     })
                // }
                else{
                    this.profileService.Auth.handleErrorStatus(res)
                        .then((resp)=>{
                            errors['newUserNameError'] = resp
                            this.setState({progressSpinner: false, errors })
                            this.setState({ checkEligibility: false });
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
                    
            }).catch((error) => {
                this.setState({ topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
        }

    }

    otpOnSubmit = () => {
        let otp = this.state.otpstate.otp;

        if(otp == ''){
            this.setState({otpError: true})
        }
        let newUsernameInfo = {
                            "userName": this.state.usernameState.newUsername
                        }
        this.setState({topProgressBar: true, errorMsgVisible: false});
        this.profileService.checkOPTCode(otp)
          .then((res)=>{
              if(res.status === 200){
                    return res.json().then((body) => {
                        if(body === true){
                            this.profileService.changeUserName(newUsernameInfo)
                                .then((res)=>{
                                        if(res.status === 200){
                                            return res.json().then((body) => {
                                                errors['otpError'] = body.message
                                                this.setState({errors: errors['otpError']});
                                                this.setState({topProgressBar: false});
                                                this.showSuccess(errors['otpError']);

                                                this.logoutTimer();
                                            });
                                        }
                                        else if(res.status === 400){
                                            return res.json().then((body) => {
                                                errors['otpError'] = body.message
                                                this.showWarn(errors['otpError'])
                                                this.setState({topProgressBar: false});
                                            });
                                        }
                                        else{
                                            this.profileService.Auth.handleErrorStatus(res)
                                            .then((resp)=>{
                                                errors['otpError'] = resp
                                                this.setState({ checkEligibility: false });
                                                this.setState({ errors, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                                            });
                                        }
                                }).catch((error) => {
                                    this.setState({ topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                                });

                        }
                        else{
                            this.profileService.Auth.handleErrorStatus(res)
                            .then((resp)=>{
                                this.setState({ checkEligibility: false });
                                this.setState({ errors, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                        }
                    })
                }
                else{
                    errors['otpError'] = 'Unable to check OTP CODE'
                    this.setState({ errors: errors['otpError'] })
                    // this.showWarn("Unable to check OTP CODE")
                }
          }).catch((error) => {
            this.setState({ topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
        });
    }

    showWarn(msg) {
        this.growl.show({severity: 'warn', summary: 'Warning', detail: msg});
    }
    showSuccess(msg) {
        this.growl.show({severity: 'success', summary: 'Success Message', detail: msg});
    }

    render(){
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let renderLogoutDialogContainer = '' //By default don't render anything
        if(this.state.renderLogoutDialog) { //If this.state.render == true, which is set to true by the timer.
            renderLogoutDialogContainer = <div>
                <Dialog 
                    header="Please Login again" 
                    className="nonHideDialog"
                    visible={this.state.renderLogoutDialog}
                    closeOnEscape={false}
                    closable={false}
                    onHide={this.dialogOnHide}
                >
                    <div className="text-center">
                        {/* <br /> */}
                        Your new User Name : <b>{ this.state.usernameState.newUsername }</b>
                        <br />
                        <br/>
                        <Message 
                            severity="success" 
                            summary= 'Redirecting to Login'
                            text=" Please wait... " 
                        />
                        <br />
                        {/* {
                            this.state.checkEligibility === true ?
                                <Link to={MY_POINT.CHANGE_USER_NAME}>Change Username</Link>
                                :
                                <b>You have already reached your limit to change User Name</b>
                        } */}

                    </div>
                    <br />
                </Dialog>
            </div> //Add dom elements
        }

        else {
            renderLogoutDialogContainer = ''//Render the dom elements, or, when this.state == false, nothing.
        }
        
        return(
            <div className="p-fluid">
                
                { renderLogoutDialogContainer }

                { topProgressBar?
                    <ErrorMessageView 
                        topProgressBar={topProgressBar} 
                    />
                    : null
                }
                { errorMsgVisible ?
                    <ErrorMessageView 
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                
                <PasswordRequired />

                <div className="main-section">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Previous Username <span>*</span></span>
                                            <InputText type="text"
                                                id="prefullName"
                                                name="oldusername"
                                                placeholder="Enter previous username"
                                                onChange={(event) => this.oldUsercheckHandle(event, this.props)}
                                                keyfilter={/^[a-zA-Z&\s]/}
                                                className="custom-field-control"
                                            />
                                        </div>
                                        <span className='error-message'>{ errors['oldUserNameError'] }</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">New Username <span>*</span></span>
                                            <InputText type="text"
                                                id="currentfullName"
                                                name="newUsername"
                                                placeholder="Enter new username"
                                                onChange={(event) => this.newUsercheckHandle(event, this.props)}
                                                keyfilter={/^[a-zA-Z&\s]/}
                                                className="custom-field-control"
                                            />
                                        </div>
                                        <span className='error-message'>{ errors['newUserNameError'] }</span>
                                    </div>
                            
                                    <div className="p-col-12 nw-button-parent">
                                        <br />
                                        <Button
                                            className="p-button-primary nw-button nw-button-right"
                                            icon="fas fa-check"
                                            onClick={(event) => this.userNameOnSubmit(event, this.props)}
                                            label="Submit"
                                        />
                                    </div>
                                </div>
                            </div>
                        {
                            this.state.otpOpen == true ?
                                this.state.progressSpinner? 
                                    <ProgressSpinner/>:
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-form-body">
                                            <div className="p-col-12">
                                                <br/>
                                                <div className="text-center">
                                                    <Message severity="warn" text="N.B Please remember that, according to our compan policy, you are allowed to change your username only once." />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">OTP Code <span>*</span></span>
                                                    <InputMask 
                                                        id="otp"
                                                        name="otp"
                                                        placeholder="Enter OTP Code"
                                                        onChange={(event) => this.otpHandle(event, this.props)}
                                                        keyfilter="num"
                                                        mask="999999"
                                                        className="custom-field-control"
                                                    />
                                                </div>
                                                <span className='error-message'>{ errors['otpError'] }</span>
                                            </div>
                                            <div className="p-col-12 nw-button-parent">
                                                <Button
                                                    className="nw-button nw-button-center"
                                                    icon="fas fa-arrow-right"
                                                    iconPos="right"
                                                    label="Update"
                                                    onClick={this.otpOnSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                : ''
                        }
                    </div> 
                </div>

                
            </div>
        </div>
        )
    }
}