import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { DownloadButton } from "../../utils/DownloadButton";
import NetiContentLoader from '../common/NetiContentLoader';
import { pdfDownloadPortrait_TableOnly } from '../common/JsPdfDownload';

export class EdumanOrderList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edumanOrderDetails: {
                instituteBoardCoreCategoryInfoDTO: {
                    categoryName: ''
                },
                instituteTypeCoreCategoryInfoDTO: {
                    categoryName: ''
                }
            },
            memberStatus: '',
            dataTableValue: [],
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            homeReturnButton: false,
        }
        this.netiFileHandler = new NetiFileHandler();
        this.PartnerPointService = new PartnerPointService();
        this.netiContentLoader = new NetiContentLoader();
        this.exportPdf = this.exportPdf.bind(this);

    }

    componentWillMount() {
        this.viewEmOrderTableInfo();
    }

    viewEmOrderTableInfo = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.PartnerPointService.getEMOrderList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        for (let i = 0; i < body.length; i++) {

                            Object.assign(body[i], {basicInfoDTOcustomNetiID: body[i].assignedUserBasicInfoDTO.customNetiID.toString()});   // for pdf column
                            Object.assign(body[i], {basicInfoDTOfullName: body[i].assignedUserBasicInfoDTO.fullName});   // for pdf column

                            if (body[i].formApplyDate == null) {
                                body[i].formApplyDate = '';
                            } else {
                                body[i].formApplyDate = NetiDateUtils.getAnyShortForm(body[i].formApplyDate, 'DD-MMM-YYYY');
                            }
                        }
                        for (let j = 0; j < body.length; j++) {
                            if (body[j].instituteStatus == 0) {
                                body[j].instituteStatus = 'Pending';
                            } else if (body[j].instituteStatus == 1) {
                                body[j].instituteStatus = 'Active';
                            } else if (body[j].instituteStatus == 2) {
                                body[j].instituteStatus = 'Inactive';
                            } else if (body[j].instituteStatus == 3) {
                                body[j].instituteStatus = 'Terminate';
                            } else if (body[j].instituteStatus == 4) {
                                body[j].instituteStatus = 'Reject';
                            }
                        }

                        this.setState({ dataTableValue: body })
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    })
                } else {
                    this.PartnerPointService.Auth.handleErrorStatus(res)
                        .then((respon) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: respon });
                        }).catch(error => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Opps! Something went wrong' });
                        });
                }
            }).catch(error => {

                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check your connection.' });
            })
    }

    onHide = (e) => {
        this.setState({ visible: false });
    }

    viewDialog = (e, rowData) => {
        let { edumanOrderDetails } = this.state;
        this.setState({ visible: true, edumanOrderDetails: rowData });
    }

    actionTemplate = (rowData) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-info"
                className="nw-action-button info"
                onClick={(e) => this.viewDialog(e, rowData)}
            >
            </Button>
        </div>;
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Order Date", dataKey: "formApplyDate" },
            { title: "Order Status", dataKey: "instituteStatus" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "EM Admin Neti ID", dataKey: "basicInfoDTOcustomNetiID" },
            { title: "EM Admin Name", dataKey: "basicInfoDTOfullName" },
        ];

        let tableColStyle = { };

        
        pdfDownloadPortrait_TableOnly('Eduman Order List', 75, pdfColumns, this.state.dataTableValue, tableColStyle, "eduman_order.pdf");

    }

    render() {

        let { dataTableValue, edumanOrderDetails } = this.state;
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        var tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Eduman Order List
            <span style={{ 'float': 'right' }}>Total Found: {dataTableValue.length} </span>
        </div>;

        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />

                {this.state.dataTableIsLoading ? this.netiContentLoader.MyPointTableLoader() :
                    <div className="nw-data-table">
                        <DataTable
                            value={this.state.dataTableValue}
                            selectionMode="single"
                            paginator={true}
                            rowsPerPageOptions={[5, 10, 20]}
                            header={tableHeader}
                            selection={this.state.dataTableSelection}
                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                            responsive={true}
                            rows={10}
                            columnResizeMode="fit"
                            dragable={true}
                        >
                            <Column field="formApplyDate" header="Order Date" filter={true} />
                            <Column field="instituteStatus" header="Order Status" filter={true} />
                            <Column field="instituteName" header="Institute Name" filter={true} />
                            <Column field="assignedUserBasicInfoDTO.customNetiID" header="EM Admin Neti ID" filter={true} />
                            <Column field="assignedUserBasicInfoDTO.fullName" header="EM Admin Name" filter={true} />
                            <Column field="action" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '80px' }} />
                        </DataTable>

                        <div className="nw-button-parent m-t-8 p-r-0">
                            <Button
                                className="p-button p-button-primary nw-button nw-button-right"
                                label="Download PDF"
                                icon="fas fa-download"
                                onClick={this.exportPdf}
                            />
                        </div>

                    </div>
                }

                <Dialog
                    header="Eduman Order List Details"
                    visible={this.state.visible}
                    className="nw-dialog"
                    onHide={this.onHide}
                    closable
                >
                    <div className="p-fluid">
                        <div className="p-grid nw-form">

                            <div className="p-grid p-col-12 p-lg-12 p-xl-12">
                                <Fieldset className="p-col-12 p-xl-12" legend="Institute Info">

                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-12">

                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Institute Name
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {edumanOrderDetails.instituteName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Institute Address
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {edumanOrderDetails.instituteAddress}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Institute Contact No.
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {edumanOrderDetails.instituteContact}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Institute Email Address
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {edumanOrderDetails.instituteEmail}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Agreement Form
                                                            </span>
                                                        <div className="p-col-6 nw-button-parent">
                                                            <DownloadButton fileName={edumanOrderDetails.formAttachmentName} filePath={edumanOrderDetails.formAttachmentPath} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Institute Form Serial
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {edumanOrderDetails.formSerial}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Education Board
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {edumanOrderDetails.instituteBoardCoreCategoryInfoDTO.categoryName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Institute Type
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {edumanOrderDetails.instituteTypeCoreCategoryInfoDTO.categoryName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Institute Category
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {edumanOrderDetails.instituteCategoryCoreCategoryInfoDTO && edumanOrderDetails.instituteCategoryCoreCategoryInfoDTO.categoryName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Total Student
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {edumanOrderDetails.totalStudent}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Total Teacher
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {edumanOrderDetails.totalHR}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </Fieldset>

                                <Fieldset legend="Address Info" className="p-col-12 p-lg-12 p-xl-12">

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Address
                                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.instituteAddress}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Division
                                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.globalAreaCoreCategoryInfoDTO && edumanOrderDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO ? edumanOrderDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                District
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.globalAreaCoreCategoryInfoDTO ? edumanOrderDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Upazila/Thana
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.globalAreaCoreCategoryInfoDTO ? edumanOrderDetails.globalAreaCoreCategoryInfoDTO.categoryName : ''}
                                            </div>
                                        </div>
                                    </div>

                                </Fieldset>

                                <Fieldset legend="Payment Info" className="p-col-12 p-lg-12 p-xl-12">

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Package Name
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.packageCoreCategoryInfoDTO ? edumanOrderDetails.packageCoreCategoryInfoDTO.categoryName : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12  p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Payment Type
                                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.paymentType ? edumanOrderDetails.paymentType : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12  p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Service Charge
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.monthlyRate ? edumanOrderDetails.monthlyRate : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Registration Charge
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.developmentCharge ? edumanOrderDetails.developmentCharge : ''}
                                            </div>
                                        </div>
                                    </div>

                                </Fieldset>

                                <Fieldset legend="Admin Info" className="p-col-12 p-lg-12 p-xl-12">

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                EM Admin Neti ID
                                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.assignedUserBasicInfoDTO ? edumanOrderDetails.assignedUserBasicInfoDTO.customNetiID : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                EM Admin Name
                                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.assignedUserBasicInfoDTO ? edumanOrderDetails.assignedUserBasicInfoDTO.fullName : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Mobile No.
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.instituteAdminContact ? edumanOrderDetails.instituteAdminContact : ''}
                                            </div>
                                        </div>
                                    </div>

                                </Fieldset>

                                <Fieldset legend="Additional Info" className="p-col-12 p-lg-12 p-xl-12">

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Agreement Start Date
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.agreementStartDate ? NetiDateUtils.getAnyShortForm(edumanOrderDetails.agreementStartDate, 'DD-MMM-YYYY') : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12  p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Agreement Expiry Date
                                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.agreementExpiryDate ? NetiDateUtils.getAnyShortForm(edumanOrderDetails.agreementExpiryDate, 'DD-MMM-YYYY') : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12  p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Data Submission Date
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.dataSubmissionDate ? NetiDateUtils.getAnyShortForm(edumanOrderDetails.dataSubmissionDate, 'DD-MMM-YYYY') : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Handover Date
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {edumanOrderDetails.handoverDate ? NetiDateUtils.getAnyShortForm(edumanOrderDetails.handoverDate, 'DD-MMM-YYYY') : ''}
                                            </div>
                                        </div>
                                    </div>

                                </Fieldset>

                            </div>

                        </div>
                    </div>
                </Dialog>

            </div>
        );
    }
}