import React, { Component } from 'react';

import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import { Link } from 'react-router-dom'
import { GUEST_POINT } from '../../utils/PointWiseRouteConsts';
import { Button } from "primereact/button";
import { GuestPointService } from '../../service/guestAccessPoint/GuestPointService';
import { Card } from 'primereact/card';
import { InputMask } from 'primereact/inputmask';
import { Growl } from 'primereact/growl';
import NetiwordLogo from "../../assets/images/login/neti.png";
import NetizenLogo from "../../assets/images/login/logo.png";
import { ErrorMessageView } from '../common/ErrorMessageView';

let letterCheckedClass, numberCheckedClass, minCheckedClass, maxCheckedClass;


class Forgetpassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
            threeDigit1:'',
            threeDigit2: '',
            threeDigit3: '',
            userName: '',
            usernameSearchErrors: {},
            otpCodeSearchErrors: {},
            topProgressBar:false,
            userDetails: '',
            password: '',
            sendOtpCheck: true,
            firstPage: true,
            secondPage: true,
            thirdPage: true,
            fourthPage: true,
            hidden: true,
            passwordIcon: true,
            errors: {},
            verificationNumber1:'',
            verificationNumber2:'',
            verificationNumber3:'',
            verificationNumber4:'',
            verificationNumber5:'',
            verificationNumber6:'',
        };

        this.onSubmitHandler = this.onSubmitHandler.bind(this)
        this.onResetPassword = this.onResetPassword.bind(this)
        this.showWarn = this.showWarn.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this)
        this.onChangeRetypePassword = this.onChangeRetypePassword.bind(this)
        this.togglePasswordShow = this.togglePasswordShow.bind(this)
        this.GuestPointService = new GuestPointService();


    }

    onSubmitHandlerold = (event, props) => {
        if (this.handleUsernameSearch()) {

        }
        // let email = this.state.userName;

        // let emailValid = this.state.userName;
        // emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

        // emailValid ? this.showSuccess(this.state.userName) : this.showWarn();


        // if (email == '') {
        //     this.showWarn();
        // }
        // else {
        //     this.showSuccess(this.state.userName);
        // }
    }




    showWarn = () => {
        this.messages.show({ severity: 'warn', summary: 'Insert valid email address', detail: '', sticky: false });
    }

    showSuccess = email => {
        this.messages.show({ severity: 'success', summary: "Send email to " + email, detail: 'Successfully Send', sticky: false });
    }


    onSubmitHandler = (event, props) => {
        if (this.handleUsernameSearch()) {
            this.setState({ topProgressBar:true })
            this.GuestPointService.fetchUserDetails(this.state.userName)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ userDetails: body });
                            this.setState({ noUser: '' });
                            this.setState({ sendOtp: true });
                            this.setState({ finalMobile: '' });
                            this.setState({ threeDigit: '' });
                            this.setState({ otpCode: '' });
                            this.setState({
                                firstPage:false,
                                secondPage: true,
                                topProgressBar:false
                            })
                        });
                    } else {
                        return res.json().then((body,res) => {
                            this.setState({ noUser: "No user found" });
                            this.setState({ sendOtp: false });
                            this.setState({ finalMobile: '' });
                            this.setState({ threeDigit: '' });
                            this.setState({ topProgressBar:false });
                        });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar:false })
                });
        }
    }
    onSendOtp = () => {
        this.setState({ topProgressBar:true, errorMsgVisible: false })
        this.GuestPointService.sendOtpCheck(this.state.threeDigit, this.state.userDetails)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ secondPage: false });
                    this.setState({ thirdPage: true });
                    this.growl.show({
                        severity: "success",
                        summary: "Success Message",
                        detail: "Sending OTP code"
                    });
                    this.setState({ topProgressBar:false })
                } else if (res.status === 400) {
                    this.setState({ topProgressBar:false })
                    this.setState({ secondPage: true });
                    this.setState({ firstPage: false });
                    this.GuestPointService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ errorMsgVisible: true, errorMsgBody:  "No User Found" })
                    });
                } else {
                    this.GuestPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            })
            .catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });

    };
    onSendOtpValid = () => {
        if (this.handleOtpcodeSearch()) {
            this.setState({ topProgressBar:true })
            let verNumber = this.state.verificationNumber1 + this.state.verificationNumber2 + this.state.verificationNumber3 + this.state.verificationNumber4 + this.state.verificationNumber5 + this.state.verificationNumber6;
            let verifycode = parseInt(verNumber);
            this.GuestPointService.checkOTP(verNumber)
                .then(res => {
                    if (res.status == 200) {
                        return res.json().then((body) => {
                            this.setState({ notValidOtp: "" });
                            this.setState({ thirdPage: false });
                            this.setState({ fourthPage: true });
                            this.setState({ topProgressBar:false })
                        });
                    } else {
                        return res.json().then((body) => {
                            this.setState({ notValidOtp: "Invalid Verification Code" });
                            this.setState({ topProgressBar:false })
                        });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar:false })
                });
        }

    };

    onResetPassword = () => {
        let requestedObject = {
            "userName": this.state.userName,
            "password": this.state.password,
            "newPassword": this.state.password,
        }
        if (this.handleError()) {
            this.setState({ thirdPage: false,topProgressBar:true });
            this.GuestPointService.recoverUserPassword(requestedObject)
                .then(res => {
                    if (res.status === 200) {
                        this.setState({ fourthPage: false });
                        this.setState({ topProgressBar:false })
                    } else {
                        return res.json().then((body) => {
                           console.log("forget new password error body", body);
                        });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar:false })
                });
        }
    }
    handleUsernameSearch = () => {
        let usernameSearchErrors = {};
        let formIsValid = true;

        if (this.state.userName === "") {
            formIsValid = false;
            usernameSearchErrors["userName"] = "Please Insert username";
        }


        this.setState({ usernameSearchErrors: usernameSearchErrors });
        return formIsValid;
    }
    handleOtpcodeSearch = () => {
        let otpCodeSearchErrors = {};
        let formIsValid = true;

        if (this.state.verNumber === "") {
            formIsValid = false;
            otpCodeSearchErrors["otpCode"] = "Please Insert Otp Code";
        }


        this.setState({ otpCodeSearchErrors: otpCodeSearchErrors });
        return formIsValid;
    }
    handleError() {

        let errors = {};
        let formIsValid = true;

        if (this.state.password === '') {
            formIsValid = false;
            errors["password"] = "Password can't left empty.";
        } else if (this.state.password.length <= 8) {
            formIsValid = false;
            errors["password"] = "Use password more than 8 character's.";
        }
        if (this.state.reTypePassword === '') {
            formIsValid = false;
            errors["re-typePassword"] = "Re-type password can't left empty.";
        } else if (!this.state.passwordMatch) {
            formIsValid = false;
            errors["re-typePassword"] = "Password does't match.";
        } else if (this.state.reTypePassword.length <= 8) {
            formIsValid = false;
            errors["re-typePassword"] = "Use re-type Password more than 8 character's.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    onChangePassword = (event) => {
        this.setState({ password: event.target.value });
    }

    onChangeRetypePassword = (event) => {
        let errors = {};
        if (this.state.password.match(event.target.value)) {
            errors["re-typePassword"] = "";
            this.setState({
                reTypePassword: event.target.value,
                passwordMatch: true,
                errors: errors
            });
        } else {
            this.setState({ reTypePassword: event.target.value });
            errors["re-typePassword"] = "Password does't match.";
            this.setState({ passwordMatch: false });
            this.setState({ errors: errors });
        }
    }

    togglePasswordShow() {
        this.setState({
            hidden: !this.state.hidden,
            passwordIcon: !this.state.passwordIcon
        });
    }

    threeDigit1= (e) =>{

        this.setState({threeDigit1:e.target.value});
        if (e.target.value < 10 ) {
            e.preventDefault();
            // let next = this.refs[field.verify_code_1].nextSibling;
            // if (next && next.tagName === "INPUT") {
            //   this.refs[field.verify_code_1].nextSibling.focus();
            // }
          }

          if( e.target.value !== '' && this.state.threeDigit2 !== '' && this.state.threeDigit3 !== ''){
            this.setState({  sendOtpCheck: false})
        }
    }
    threeDigit2 = (e) =>{

        this.setState({threeDigit2:e.target.value});
        if(this.state.threeDigit1 !== '' && e.target.value !== '' && this.state.threeDigit3 !== ''){
            this.setState({  sendOtpCheck: false})
        }
    }
    threeDigit3 = (e) =>{

        this.setState({threeDigit3:e.target.value});

        let verNumber = this.state.threeDigit1 + this.state.threeDigit2 + e.target.value;
        // let verifycode = parseInt(verNumber);

        if(this.state.threeDigit1 !== '' && this.state.threeDigit2 !== '' && e.target.value !== ''){
            // this.setState({ threeDigit: verNumber, sendOtpCheck: isNaN(e.target.value)})
            this.setState({ threeDigit: verNumber, sendOtpCheck: false})

        }
    }

    verificationNumber1 = (e) =>{
        this.setState({verificationNumber1:e.target.value});
        if (e.target.value < 10 ) {
            e.preventDefault();
            // let next = this.refs[field.verify_code_1].nextSibling;
            // if (next && next.tagName === "INPUT") {
            //   this.refs[field.verify_code_1].nextSibling.focus();
            // }
          }

    }
    verificationNumber2 = (e) =>{
        this.setState({verificationNumber2:e.target.value});
    }
    verificationNumber3 = (e) =>{
        this.setState({verificationNumber3:e.target.value});
    }
    verificationNumber4 = (e) =>{
        this.setState({verificationNumber4:e.target.value});
    }
    verificationNumber5 = (e) =>{
        this.setState({verificationNumber5:e.target.value});
    }
    verificationNumber6 = (e) =>{
        this.setState({verificationNumber6:e.target.value});
    }

    focusInputValue(e, inputClassName){
        var container = document.getElementsByClassName(inputClassName)[0];
        // container.onkeyup = function(e) {
            console.log("focus info",e.target.value);

            console.log("threeDigit1",this.state.threeDigit1);
            console.log("threeDigit2",this.state.threeDigit2);
            console.log("threeDigit3",this.state.threeDigit3);
            
            var target = e.srcElement || e.target;
            var maxLength = parseInt(target.attributes["maxlength"].value, 10);
            var myLength = target.value.length;
                        console.log("myLength",myLength);

            if (myLength >= maxLength) {
                var next = target;
                while (next = next.nextElementSibling) {
                    if (next == null)
                        break;
                    if (next.tagName.toLowerCase() === "input") {
                        next.focus();
                        break;
                    }
                }
            }
            // Move to previous field if empty (user pressed backspace)

            else if (myLength === 0) {
                let { threeDigit1, threeDigit2, threeDigit3 } = this.state
                if( threeDigit1 === '' || threeDigit2 === '' || threeDigit3 === ''){
                    this.setState({ sendOtpCheck: true})
                }
                var previous = target;
                while (previous = previous.previousElementSibling) {
                    if (previous == null)
                        break;
                    if (previous.tagName.toLowerCase() === "input") {
                        previous.focus();
                        break;
                    }
                }
            }
        // }
    }

    checkPassword = (password) =>{
        letterCheckedClass = numberCheckedClass = minCheckedClass = maxCheckedClass = "pi pi-check-circle" ;
        // return !!password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/);
        // return !!password.match(/^(?=.*?[a-zA-Z]).{1,}$/);
        if(!!password.match(/^(?=.*?[a-zA-Z]).{1,}$/)){
            letterCheckedClass = "pi pi-check-circle text-success"
        }
        if(!!password.match(/^(?=.*?[0-9]).{1,}$/)){
            numberCheckedClass = "pi pi-check-circle text-success"
        }
        if(password.length >= 6 && password.length < 16){
            minCheckedClass = "pi pi-check-circle text-success"
        }
        if(password.length === 16){
            maxCheckedClass = "pi pi-check-circle text-success"

            if(password.length >= 6 && password.length <= 16){
                minCheckedClass = maxCheckedClass = "pi pi-check-circle text-success"
            }
        }
        // else if(password.length >= 6 && password.length <= 16){
        //     minCheckedClass = maxCheckedClass = "pi pi-check-circle text-success"
        // }
    };

    render() {
        let {topProgressBar,errorMsgVisible,errorMsgBody} = this.state;

        return (
            <div className="layout-wrapper layout-static layout-menu-dark nw-global-background">

            {topProgressBar ?
                <ErrorMessageView
                    topProgressBar={topProgressBar}
                />
                : null
            }
            {errorMsgVisible ?
                <ErrorMessageView
                    errorMsgVisible={errorMsgVisible}
                    errorMsgBody={errorMsgBody}
                />
                : null
            }

                <div className="nw-layout-content">
                    <Growl ref={(el) => this.growl = el} />

                        <div className="global-login-form text-center">
                            <img
                                id="layout-menu-logo"
                                src={NetiwordLogo}
                                alt="Netizen IT Limited"
                            />
                        
                            {this.state.firstPage ?

                            <div>
                            {/* start process step */}
                            <h3>FORGOT PASSWORD?</h3>
                            <p>Recover your account with few simple steps</p>
                            <ul className="process-step content-center">
                                <li className="active">1</li>
                                <li>2</li>
                                <li>3</li>
                                <li>4</li>
                            </ul>

                            {/* end process step */}
                                <span className="p-float-label">
                                    <div className="p-col-12">
                                        <InputText
                                            id="in"
                                            placeholder="Enter username"
                                            value={this.state.userName}
                                            keyfilter="alphanum"
                                            onChange={(e) => this.setState({ userName: e.target.value })}
                                        />
                                        <span className="required-icon">*</span>
                                    </div>

                                    <div className="p-col-12">
                                        <Button 
                                            className="custom-login-button gradiant-btn box-shadow"
                                            icon="fas fa-arrow-right"
                                            iconPos="right"
                                            label="Next"
                                            onClick={this.onSubmitHandler}
                                        />
                                    </div>

                                    <span className="error-message">
                                        {this.state.usernameSearchErrors["userName"]}
                                    </span>
                                    <span className="error-message">
                                        {this.state.noUser}
                                    </span>

                                </span>

                                </div> : this.state.secondPage ?

                                // Second Page Is here

                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12">
                                        {/* start process step */}
                                        <h3>FORGOT PASSWORD?</h3>
                                        {/* <p>Fill the mobile number with last 3 digits</p> */}
                                        <ul className="process-step content-center">
                                            <li className="finish"><i class="pi pi-check"></i></li>
                                            <li className="active">2</li>
                                            <li>3</li>
                                            <li>4</li>
                                        </ul>
                                        {/* end process step */}

                                        {this.state.sendOtp ?
                                            <div className="user-info">
                                                <Card>
                                                    <div className="content-left">
                                                        <span>Name</span><span className='text-bold' style={{ paddingLeft: "7px" }}> :&nbsp;{this.state.userDetails.fullName}</span>
                                                    </div>
                                                    <div className="content-left">
                                                        <span>Date of Birth:</span><span className='text-bold' style={{ paddingLeft: "7px" }}> :&nbsp;{this.state.userDetails.dateOfBirth}</span>
                                                    </div>

                                                    <div className="content-left">
                                                        <span>Mobile No</span>
                                                            <span className='text-bold' style={{ paddingLeft: "7px" }}>
                                                                :&nbsp;
                                                                <span className='text-bold mobile-last-three-digit' id="mobile-last-three-digit"> 
                                                                    {this.state.userDetails.basicMobile}&nbsp;
                                                                    -<InputText onKeyUp={(e) => this.focusInputValue(e, "mobile-last-three-digit")} onChange={(e) => this.threeDigit1(e)} maxLength="1" name="verify_code_1" ref="verify_code_1" id="in" autoFocus/>
                                                                    -<InputText onKeyUp={(e) => this.focusInputValue(e, "mobile-last-three-digit")} onChange={(e) => this.threeDigit2(e)} maxLength="1" name="verify_code_2" ref="verify_code_2" id="in"/>
                                                                    -<InputText onKeyUp={(e) => this.focusInputValue(e, "mobile-last-three-digit")} onChange={(e) => this.threeDigit3(e)} maxLength="1" name="verify_code_3" ref="verify_code_3" id="in"/>
                                                                </span>
                                                            </span>
                                                    </div>
                                                    
                                                    {/* <div className="">
                                                        <center>
                                                            <span className='text-bold mobile-last-three-digit' id="mobile-last-three-digit"> {this.state.userDetails.basicMobile}
                                                                <InputText onKeyUp={(e) => this.focusInputValue(e, "mobile-last-three-digit")} onChange={(e) => this.threeDigit1(e)} maxLength="1" name="verify_code_1" ref="verify_code_1" id="in"/>
                                                                <InputText onKeyUp={(e) => this.focusInputValue(e, "mobile-last-three-digit")} onChange={(e) => this.threeDigit2(e)} maxLength="1" name="verify_code_2" ref="verify_code_2" id="in"/>
                                                                <InputText onKeyUp={(e) => this.focusInputValue(e, "mobile-last-three-digit")} onChange={(e) => this.threeDigit3(e)} maxLength="1" name="verify_code_3" ref="verify_code_3" id="in"/>
                                                            </span>   
                                                        </center>   
                                                    </div>   */}
                                                    <div className='error-message'>
                                                        <center><p>Fill the mobile number with last 3 digits</p></center>     
                                                    </div>   
                                                    <Button 
                                                        style={{ marginTop:"10px" }}
                                                        className="custom-login-button gradiant-btn box-shadow" 
                                                        disabled={this.state.sendOtpCheck}
                                                        onClick={this.onSendOtp}
                                                        label ="SEND OTP NUMBER"
                                                        icon="fas fa-arrow-right"
                                                        iconPos="left"
                                                    />  
                                                    {/* <Button 
                                                        className="custom-login-button gradiant-btn box-shadow"
                                                        icon="fas fa-arrow-right"
                                                        iconPos="right"
                                                        label="Next"
                                                        onClick={this.onSubmitHandler}
                                                    />                                                  */}
                                                </Card>
                                                
                                            </div> : ''}
 
                                    </div>


                                </div> : this.state.thirdPage ?

                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-12">
                                            <h3>FORGOT PASSWORD?</h3>
                                            {/* <p>Fill the mobile number with last 3 digits</p> */}
                                            <ul className="process-step content-center">
                                                <li className="finish"><i class="pi pi-check"></i></li>
                                                <li className="finish"><i class="pi pi-check"></i></li>
                                                <li className="active">3</li>
                                                <li>4</li>
                                            </ul>
                                            {/* end process step */}
                                            <br />
                                            <p>A 6 digit verification code has been sent to this number: {this.state.Number} This code is active for next 15 minutes. </p>

                                            <span className="p-float-label">
                                                <h3 style={{ fontSize:'14px' }}>VERIFICATION CODE</h3>
                                                <div className="verify-code" id="verify-code">
                                                    <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} onChange={(e) => this.verificationNumber1(e)} maxLength="1" name="verify_code_1" ref="verify_code_1" id="in"/>
                                                    <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} onChange={(e) => this.verificationNumber2(e)} maxLength="1" name="verify_code_2" ref="verify_code_2" id="in"/>
                                                    <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} onChange={(e) => this.verificationNumber3(e)} maxLength="1" name="verify_code_3" ref="verify_code_3" id="in"/>
                                                    <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} onChange={(e) => this.verificationNumber4(e)} maxLength="1" name="verify_code_4" ref="verify_code_4" id="in"/>
                                                    <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} onChange={(e) => this.verificationNumber5(e)} maxLength="1" name="verify_code_5" ref="verify_code_5" id="in"/>
                                                    <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} onChange={(e) => this.verificationNumber6(e)} maxLength="1" name="verify_code_6" ref="verify_code_6" id="in"/>
                                                </div>
                                            </span> 

                                            <Button 
                                                className="custom-login-button gradiant-btn box-shadow content-center" 
                                                label="Next"
                                                disabled={this.state.otpCodeValid}
                                                icon="fas fa-arrow-right"
                                                onClick={this.onSendOtpValid}
                                            />
                                            <div className="resend-code">
                                                I didn't get any code <button type="button" onClick={this.onSendOtp}>Resend</button>
                                            </div>
                                            <br />
                                            <span className="error-message">
                                                {this.state.otpCodeSearchErrors["otpCode"]}
                                            </span>
                                            <span className="error-message">
                                                {this.state.notValidOtp}
                                            </span>`
                                            <br />

                                            <br />
                                        </div>
                                    </div> :this.state.fourthPage ?
                                                    
                                                    
                                    <div className="p-grid">   
                                        <div className="p-col-12 p-md-12">
                                            <h3>FORGOT PASSWORD?</h3>
                                            <p>Account Verification Successful!</p>
                                                <ul className="process-step content-center">
                                                    <li className="finish"><i class="pi pi-check"></i></li>
                                                    <li className="finish"><i class="pi pi-check"></i></li>
                                                    <li className="active">3</li>
                                                    <li>4</li>
                                                </ul>
                                                <br/>
                                                <h3>ENTER NEW PASSWORD</h3>    
                                                <span className="p-float-label">
                                                    <div className="p-inputgroup">
                                                        <InputText
                                                            type={this.state.hidden ? "password" : "text"}
                                                            name="password"
                                                            className="custom-field-control"
                                                            required="required"
                                                            keyfilter="alphanum"
                                                            placeholder="Enter Password"
                                                            onChange={(event) => this.onChangePassword(event)}
                                                            value={this.state.password}
                                                            maxLength="16"
                                                        />
                                                        <Button
                                                            icon={this.state.passwordMatch ? "fas fa-check-circle text-success" : "fas fa-check-circle"}
                                                            className="p-button-secondary-custom"
                                                            // onClick={(event) => this.togglePasswordShow(event)}
                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.errors["password"]}</span>
                                                </span>

                                                <br />
                                                <span className="p-float-label">
                                                    <div className="p-inputgroup">
                                                        <InputText
                                                            className="custom-field-control"
                                                            type={this.state.hidden ? "password" : "text"}
                                                            name="re-password"
                                                            placeholder="Re-Type Password"
                                                            onChange={(event) => this.onChangeRetypePassword(event)}
                                                            required="required"
                                                            maxLength="16"
                                                        />
                                                        <Button
                                                            icon={this.state.passwordMatch ? "fas fa-check-circle text-success" : "fas fa-check-circle text-danger"}
                                                            className="p-button-secondary-custom"
                                                            // onClick={(event) => this.togglePasswordShow(event)}
                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.errors["re-typePassword"]}</span>
                                                </span>
                                                
                                                <div className="password-condition">
                                                    <ul>
                                                        <li>A Letter <i class={ letterCheckedClass }></i></li>
                                                        <li>A number <i class={ numberCheckedClass }></i></li>
                                                        <li>Min 8 characters <i class={ minCheckedClass }></i></li>
                                                        <li>Max 16 characters <i class={ maxCheckedClass }></i></li>
                                                    </ul>
                                                </div>

                                                <button 
                                                    className="p-button p-component p-button custom-login-button gradiant-btn box-shadow content-center"
                                                     type="submit"
                                                     onClick={event => this.onResetPassword(event)}>
                                                    <span className="p-button-text p-c"><i className="pi pi-arrow-right"></i> RESET PASSWORD</span>
                                                </button>
                                        </div>

                                    </div>
                                :
                                <div className="p-col-12 p-md-12">
                                    <div className="success-message">
                                        <i class="pi pi-check-circle"></i>
                                        <h3>SUCCESS!</h3>
                                    </div>
                                    <div><p style={{ fontSize:'12px',marginBottom:'0px' }}>Awesome! Your password has been reset successfully. </p></div>
                                    <div className="resend-code">
                                        Go to <button type="button"><Link to={GUEST_POINT.LOGIN} >login</Link></button>
                                    </div>
                                    <br />
                                </div>

                            }
                            <div className="copyright">
                                <span>POWERED BY <strong>NETIZEN IT LIMITED </strong></span><img src={NetizenLogo} />
                                
                            </div>
                        </div>
                                    
                </div>
            </div>


        );
    }



    // this.state.userSumValue > 0 ? 
    // ((this.state.userSumValue === this.state.sumOfNumber)?
    //  <span className="p-inputgroup-addon"><i className="pi pi-user" /></span> :
    // <span className="p-inputgroup-addon"><i className="pi pi-check" /></span>)
    // : "not"
}

export default Forgetpassword;

