import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { PickList } from 'primereact/picklist';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { ImportantLinkUpdate } from './ImportantLinkUpdate';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { Link } from 'react-router-dom';
import NetiContentLoader from '../../common/NetiContentLoader';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';

let urlId;
export class ImportantLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogLoad: false,
            visible: false,
            urlInfoDTO: {
                urlInfoID: ''
            },
            value: '',
            rowInformation: {
                urlInfoDTO: {
                    urlInfoID: "",
                }
            },
            linkSubmitError: [],
            importantLinkInfo: {
                linkTitle: "",
                linkUrl: "",
                urlInfoDTO: {
                    urlInfoID: "",
                }
            },
            linkList: [],
            selectedList: [],
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
        }

        this.errorHandler = this.errorHandler.bind(this);
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        let { linkInformation } = this.state;
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            this.setState({ homeReturnButton: false });
            let { importantLinkInfo } = this.state;
            importantLinkInfo.urlInfoDTO.urlInfoID = urlId;
            this.setState({ importantLinkInfo });
            this.viewImportantLinkList();
            this.viewSelectedImportantLinkList();
        }
        else {
            this.setState({ homeReturnButton: true });
        }
    }

    // componentDidMount() {
    //     if (urlId) {
    //         this.setState({ homeReturnButton: false })
    //         let { importantLinkInfo } = this.state;
    //         importantLinkInfo.urlInfoDTO.urlInfoID = urlId;
    //         this.setState({ importantLinkInfo });
    //         this.viewImportantLinkList();
    //         this.viewSelectedImportantLinkList();
    //     }
    //     else {
    //         this.setState({ homeReturnButton: true })
    //     }
    // }

    viewImportantLinkList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        let { urlInfoDTO } = this.state;
        urlInfoDTO.urlInfoID = urlId;
        this.setState({ urlInfoDTO });
        this.DwsService.getTopLinkList(urlInfoDTO, '0')
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        this.setState({ linkList: body })
                        this.setState({ topProgressBar: false, dataTableIsLoading: false })
                    })
                }
                else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, dataTableIsLoading: false, errorMsgBody: 'Please check connection' });
            });

    }

    viewSelectedImportantLinkList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        let { urlInfoDTO } = this.state;
        urlInfoDTO.urlInfoID = urlId;
        this.setState({ urlInfoDTO });
        this.DwsService.getTopLinkList(urlInfoDTO, '1')
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        this.setState({ selectedList: body })
                        this.setState({ topProgressBar: false, dataTableIsLoading: false })
                    });
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, dataTableIsLoading: false, errorMsgBody: 'Please check connection' });
            });
    }

    speechTemplate = (source) => {

        console.log("SOURCE", source);
        
        return (
            <div className="p-clearfix">
                <div>
                    <label
                        htmlFor="cb1"
                        className="p-checkbox-label">
                        {source.linkTitle}
                    </label>

                    {source.linkTitle === "Admisia Link" ?
                        null:
                        <Button
                            className="pull-right"
                            icon="fas fa-pencil-alt"
                            onClick={(e) => this.viewLinkData(e, source)}
                        />
                    }
                    
                </div>
            </div>
        );
    }

    viewLinkData = (e, source) => {
        let { rowInformation } = this.state;
        rowInformation.linkCreateDate = source.linkCreateDate;
        rowInformation.linkID = source.linkID;
        rowInformation.linkTitle = source.linkTitle;
        rowInformation.linkUrl = source.linkUrl;
        rowInformation.topLink = source.topLink;
        rowInformation.linkSerial = source.linkSerial;
        rowInformation.urlInfoDTO.urlInfoID = source.urlInfoDTO.urlInfoID;
        this.setState({ rowInformation });
        this.setState({ visible: true });
        this.setState({ dialogLoad: true });
    }


    linkTitleHandler = (event, props) => {
        let { importantLinkInfo, linkSubmitError } = this.state;
        linkSubmitError["linkTitle"] = "";
        importantLinkInfo.linkTitle = event.target.value;
        this.setState({ importantLinkInfo, linkSubmitError });
    }

    linkUrlHandler = (event, props) => {
        let { importantLinkInfo, linkSubmitError } = this.state;
        linkSubmitError["linkUrl"] = "";
        importantLinkInfo.linkUrl = event.target.value;
        this.setState({ importantLinkInfo, linkSubmitError });
    }

    addNewImportantLink = (event, props) => {
        if (this.errorHandler()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.DwsService.createNewLink(this.state.importantLinkInfo)
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Link added successfully' });
                        let { importantLinkInfo } = this.state
                        importantLinkInfo.linkTitle = importantLinkInfo.linkUrl = '';
                        this.setState({ importantLinkInfo });
                        this.viewImportantLinkList();
                    }
                    else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }

    linkListSubmit = (event, props) => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        let { selectedList, linkList } = this.state;
        let selectedImportantLinkList = [];

        if (selectedList.length > 5) {
            this.growl.show({ severity: 'error', summary: 'Error Message', detail: 'You cross speech limit' });
            return;
        }

        for (let i = 0; i < selectedList.length; i++) {
            selectedList[i].topLink = 1;
            selectedList[i].linkSerial = i + 1;
            selectedImportantLinkList.push(selectedList[i]);
        }

        for (let i = 0; i < linkList.length; i++) {
            linkList[i].topLink = 0;
            selectedImportantLinkList.push(linkList[i]);
        }

        this.DwsService.updateTopLinkList(selectedImportantLinkList)
            .then(res => {
                if (res.status == 202) {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Important list updated successfully' });
                    this.viewImportantLinkList();
                    this.viewSelectedImportantLinkList();
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }

    errorHandler() {

        let { importantLinkInfo, linkSubmitError } = this.state;
        let formIsValid = true;
        if (importantLinkInfo.linkTitle === '') {
            formIsValid = false;
            linkSubmitError["linkTitle"] = "Link title can't left empty";
        }
        if (importantLinkInfo.linkUrl === '') {
            formIsValid = false;
            linkSubmitError["linkUrl"] = "Link URL can't left empty";
        }
        this.setState({ linkSubmitError });
        return formIsValid;
    }

    turnOffDialog = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                {this.state.homeReturnButton === false ?
                    <div>
                        <div className="main-section">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Link Title <span>*</span></span>
                                                <InputText
                                                    className="custom-form-control"
                                                    value={this.state.importantLinkInfo.linkTitle}
                                                    placeholder="Enter Link Title"
                                                    onChange={(e) => this.linkTitleHandler(e, this.props)}
                                                    id="linkTitle"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.linkSubmitError["linkTitle"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Link URL<span>*</span></span>
                                                <InputText
                                                    className="custom-form-control"
                                                    value={this.state.importantLinkInfo.linkUrl}
                                                    id="linkUrl"
                                                    onChange={(e) => this.linkUrlHandler(e, this.props)}
                                                    name="linkUrl"
                                                    placeholder="Enter Link URL"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.linkSubmitError["linkUrl"]}</span>
                                        </div>


                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                name="district"
                                                label="Save"
                                                icon="fas fa-check"
                                                onClick={(e) => this.addNewImportantLink(e, this.props)}
                                            />

                                        </div>
                                    </div>

                                </div>

                                <br/>

                                <div className="p-grid p-col-12 p-xl-12">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="content-section implementation nw-data-table">
                                            {this.state.dataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader() :
                                                <PickList
                                                    source={this.state.linkList}
                                                    target={this.state.selectedList}
                                                    itemTemplate={this.speechTemplate}
                                                    sourceHeader="Important Link list"
                                                    targetHeader="Selected List for Neti CMS Home (Max: 5)"
                                                    responsive={true}
                                                    sourceStyle={{ height: '300px' }}
                                                    targetStyle={{ height: '300px' }}
                                                    onChange={(e) => this.setState({ linkList: e.source, selectedList: e.target })}>
                                                        
                                                </PickList>
                                            }
                                        </div>
                                        <br />

                                        <div className="p-grid p-col-12 p-xl-12 nw-button-parent">
                                            <Button
                                                label="Save"
                                                icon="fas fa-check"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                onClick={(e) => this.linkListSubmit(e, this.props)}
                                            />
                                        </div>

                                    </div>
                                
                                </div>
                                
                            </div>
                            
                        </div>
                        <div className="dialog-section">
                            {this.state.dialogLoad == true ?
                                <Dialog 
                                    maximizable header="Update Important Link" 
                                    visible={this.state.visible} className="nw-dialog" 
                                    modal={true} 
                                    onHide={() => this.setState({ visible: false })}
                                >
                                    <ImportantLinkUpdate 
                                        viewImportantLinkList={this.viewImportantLinkList} 
                                        linkInformation={this.state.rowInformation} 
                                        turnOffDialog={this.turnOffDialog} 
                                        viewSelectedImportantLinkList={this.viewSelectedImportantLinkList} 

                                    />
                                </Dialog>
                                :
                                ''
                            }
                        </div>
                    </div>


                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}