import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { SeatInfoList } from './SeatInfoList';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';


let dialogStatus = false;
let errors = {};
let dropDownListName1 = '';
let dropDownListName2 = '';
let cloneStateBeforeMount;
let urlId;
export class SeatInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serialNumber: '',
            coreClassCategoryID: '',
            coreGroupCategoryID: '',
            totalSeat: '',
            errors: {},
            dataTableIsLoading: false,
            seatInfoObject: {
                totalSeat: '',
                seatSerial: '',
                classCategoryInfoDTO: {
                    coreCategoryID: 0,
                },
                groupCategoryInfoDTO: {
                    coreCategoryID: 0,
                },
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            homeReturnButton: false,
            firstDropDownIsLoading: false,
            secondDropDownIsLoading: false,
            totalDataFound: 0
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();

        this.onChangeClass = this.onChangeClass.bind(this);
        this.onChangeGroup = this.onChangeGroup.bind(this);
        this.onChangeSerialNo = this.onChangeSerialNo.bind(this);
        this.onChangeTotalSeat = this.onChangeTotalSeat.bind(this);
        this.reloadCoreUrlIdFromLocal();

    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {

        urlId = this.DwsService.getUrlInfoFromLocalStorage();

        if (urlId) {
            let { coreUrlInfoDTO } = this.state.seatInfoObject
            coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ coreUrlInfoDTO })
            this.setState({ homeReturnButton: false })

            this.getClassOrGroupListByDefaultCode('T108');
            this.getClassOrGroupListByDefaultCode('T109');
        }
        else {
            this.setState({ homeReturnButton: true })
        }
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }



    getClassOrGroupListByDefaultCode(defaultCode) {
        this.setState({ error: {} });
        this.setState({ firstDropDownIsLoading: true, secondDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {

                if (res.status == 302) {
                    return res.json().then((body) => {

                        if (defaultCode === 'T108') {
                            this.setState({ classDropDownList: body });
                        }
                        else if (defaultCode === 'T109') {
                            this.setState({ groupDropDownList: body });
                        }
                        errors['classError'] = ''
                        errors['groupError'] = ''
                        errors[dropDownListName1] = ''
                        this.setState({ errors: errors })
                        this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });

                }
                else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            errors[dropDownListName1] = resp.message
                            this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch(error => {
                errors['classError'] = 'Class List Not Found'
                errors['groupError'] = 'Group List Not Found'
                errors[dropDownListName1] = 'Connection Problem'
                this.setState({ errors: errors })
                this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

            });
    }

    onChangeSerialNo = (e) => {
        errors = {}
        this.setState({
            serialNumber: e.target.value
        })
    }

    onChangeClass = (e) => {
        errors = {}
        this.setState({ coreClassCategoryID: e.value })
    }

    onChangeGroup = (e) => {
        errors = {}
        this.setState({ coreGroupCategoryID: e.value })
    }

    onChangeTotalSeat = (e) => {
        errors = {}
        this.setState({
            totalSeat: e.target.value
        })
    }

    handleError() {
        let formIsValid = true;

        if (this.state.totalDataFound === '' || this.state.totalDataFound === null) {
            formIsValid = false;
            errors["serialNumber"] = "Serial can't left empty.";
        }

        if (this.state.coreClassCategoryID === '' || this.state.coreClassCategoryID === null) {
            formIsValid = false;
            errors["classError"] = "Class can't left empty.";
        }
        if (this.state.coreGroupCategoryID === '' || this.state.coreGroupCategoryID === null) {
            formIsValid = false;
            errors["groupError"] = "Group can't left empty.";
        }
        if (this.state.totalSeat === '') {
            formIsValid = false;
            errors["totalSeat"] = "Seat can't left empty.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    seatOnSubmitHandler = () => {

        if (this.handleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            let { seatInfoObject } = this.state;
            let { classCategoryInfoDTO, groupCategoryInfoDTO } = this.state.seatInfoObject;

            seatInfoObject.seatSerial = this.state.totalDataFound + 1;
            seatInfoObject.totalSeat = this.state.totalSeat;
            classCategoryInfoDTO.coreCategoryID = this.state.coreClassCategoryID;
            groupCategoryInfoDTO.coreCategoryID = this.state.coreGroupCategoryID;

            this.setState({ seatInfoObject })

            this.DwsService.createNewSeat(seatInfoObject)
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully Saved' });
                        this.setState({
                            seatInfoObject: cloneStateBeforeMount.seatInfoObject,
                            serialNumber: '',
                            coreClassCategoryID: '',
                            coreGroupCategoryID: '',
                            totalSeat: '',
                        });
                    }
                    else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });

                    }

                }).catch(error => {
                    // errors["dropDownClassError"] = 'Connection Problem'
                    this.setState({ errors: errors })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                });
        }
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let classList = [];
        if ((this.state.classDropDownList != null) && (this.state.classDropDownList.length > 0)) {
            classList = this.state.classDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let groupList = [];
        if ((this.state.groupDropDownList != null) && (this.state.groupDropDownList.length > 0)) {
            groupList = this.state.groupDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }



        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                {this.state.homeReturnButton === false ?
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">
                                <Growl ref={(el) => this.growl = el} />

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Serial No.</span>
                                        <div className="nw-inputgroup-desc">
                                            {this.state.totalDataFound + 1}
                                        </div>
                                    </div>
                                </div>


                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Class <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Class"
                                            value={this.state.coreClassCategoryID}
                                            options={classList}
                                            onChange={this.onChangeClass}
                                            filter={true}
                                            filterBy="label,value"
                                            showClear={true}
                                            autoWidth={false}
                                        />
                                    </div>
                                    <span className='error-message'>{errors['classError']}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Group <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Group"
                                            value={this.state.coreGroupCategoryID}
                                            options={groupList}
                                            onChange={this.onChangeGroup}
                                            filter={true}
                                            filterBy="label,value"
                                            showClear={true}
                                            autoWidth={false}
                                        />
                                    </div>
                                    <span className='error-message'>{errors['groupError']}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Total Seat <span>*</span></span>
                                        <InputText
                                            keyfilter="pint"
                                            id="seatNo"
                                            placeholder="Enter total seat number"
                                            showClear={true}
                                            style={{ width: "100%" }}
                                            type="text"
                                            name="seat"
                                            onChange={this.onChangeTotalSeat}
                                            value={this.state.totalSeat}
                                        />
                                    </div>
                                    <span className="error-message">{errors["totalSeat"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12" />

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                            </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-left"
                                        label="Save"
                                        icon="fas fa-check"
                                        onClick={this.seatOnSubmitHandler.bind(this)}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="p-col-12 p-xl-12 nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <SeatInfoList countTableSize={(l) => this.setState({ totalDataFound: l })
                                }
                                    classList={classList}
                                    groupList={groupList} />
                            }
                        </div>
                    </div>

                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}