import { BaseURIHolder } from "../utils/BaseURIHolder";
import AuthService from "../auth/AuthService";

export class UserCategoryService {
  constructor() {
    this.baseURIHolder = new BaseURIHolder();
    this.authService = new AuthService();
  }

  fetchListBy2ndParentTypeDefCode(typeDefaultCode) {
    let uri =
      this.baseURIHolder.getUser() +
      "/category/by/type/2nd_parent_type?typeDefaultCode=" +
      typeDefaultCode;
    return this.authService
      .getFetch(uri)
      .catch((error) => console.log("error", error));
  }

  fetchListByDefaultCode(defaultCode) {
    let uri =
      this.baseURIHolder.getUser() +
      "/category/by/typeDefaultCode?typeDefaultCode=" +
      defaultCode;
    return this.authService
      .getFetch(uri)
      .catch((error) => console.log("error", error));
  }
  fetchListByParentCategoryID(categoryId) {
    let uri =
      this.baseURIHolder.getUser() +
      "/category/by/parentCatID?parentCategoryID=" +
      categoryId;
    return this.authService
      .getFetch(uri)
      .catch((error) => console.log("error", error));
  }

  fetchAccountNumber(coreBankID) {
    console.log(coreBankID);
    let uri =
      this.baseURIHolder.getUser() +
      "/account/by/corebankid?coreBankID=" +
      coreBankID;
    return this.authService
      .getFetch(uri)
      .catch((error) => console.log("error", error));
    console.log(this.authService.getFetch(uri));
  }

  findByTypeDefaultCodeTypeStatus(typeDefaultCode, typeStatus) {
    let uri =
      this.baseURIHolder.getUser() +
      `/core/category-types/by/typeDefaultCode?typeDefaultCode=${typeDefaultCode}&typeStatus=${typeStatus}`;
    return this.authService
      .getFetch(uri)
      .catch((error) => console.log("error", error));
  }

  findByCategoryDefaultCode(catDefaultCode) {
    let uri =
      this.baseURIHolder.getUser() +
      `/core/categories/by/category-defaultCode?catDefaultCode=${catDefaultCode}`;
    return this.authService
      .getFetch(uri)
      .catch((error) => console.log("error", error));
  }

  fetchBankListByDefaultCode(defaultCode) {
    let uri =
      this.baseURIHolder.getUser() +
      "/core/bank/accounts/by?defaultCode=" +
      defaultCode;
    return this.authService
      .getFetch(uri)
      .catch((error) => console.log("error", error));
  }
  //fetch accounts by selected payment type and default code
  fetchBankListByDefaultCodeType(defaultCode, type) {
    let uri =
      this.baseURIHolder.getUser() +
      "/core/bkash-bank/accounts/by?defaultCode=" +
      defaultCode +
      "&type=" +
      type;
    return this.authService
      .getFetch(uri)
      .catch((error) => console.log("error", error));
  }
}
