import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import  { AdmisiaApplicantViewInfo }  from '../AdmisiaApplicantViewInfo';
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import { AdmisiaEligibilityStatus }  from '../AdmisiaEligibilityStatus';
import NetiContentLoader from '../../common/NetiContentLoader';

let cloneStateBeforeMount;
let eligibilityStatus;
let urlInfoID;

export class AdmisiaApplicantInfo extends Component {
    constructor() {
        super();
        this.state = {
            applicantPicture: '',
            searchView: true,
            firstInputFieldIsLoading: false,
            regNo: '',
            errors: {},
            searchViewError: false,
            searchErrorMsgBody: '',
            eligibilityStatus: '',
            applicantFormVisibility: false,
            regError: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            DOB: '',
            applicantInfo: {
              admisiaApplicantInfoDTO: {
                admisiaClassConfigDTO: {
                    admissionTestStatus: ''
                },
                addressCoreCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                dateOfBirth: '',
                motherOccupationCategoryInfoDTO: {
                    categoryName: ''
                },
                fatherOccupationCategoryInfoDTO: {
                    categoryName: ''
                },
                religionCoreCategoryInfoDTO: {
                    categoryName: ''
                },
                genderCoreCategoryInfoDTO: {
                    categoryName: ''
                },
                admisiaClassConfigDTO: {
                    classCoreCategoryInfoDTO: {
                        categoryName: ''
                    },
                    groupCoreCategoryInfoDTO: {
                        categoryName: ''
                    }
                },
                admisiaCoreConfigDTO: {
                    coreUrlInfoDTO: {
                        urlInfoID: ''
                    }
                },
            },
         }
        };

        this.admisiaPointService = new AdmisiaPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.admisiaEligibilityStatus = new AdmisiaEligibilityStatus();
        this.NetiContentLoader = new NetiContentLoader();
        this.getProfileImg = this.getProfileImg.bind(this);
        this.reloadCoreUrlIdFromLocal();

    }

    reloadCoreUrlIdFromLocal() {
        urlInfoID = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlInfoID) {
            setTimeout(() => {
                this.componentWillMount();
            }, 1000);
        }
    }
    

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
      }

      componentDidMount() {
        if (this.state.applicantInfo.admisiaApplicantInfoDTO.photoPath !== undefined) {
            this.getProfileImg();
        } else {
            return null;
        }
    }

    onChangeRegNo = (e) => {
        let { regNo, regError } = this.state;
        regError['registrationNo'] = '';
        regNo = e.target.value;
        this.setState({ regNo, regError });
    }

   

    getProfileImg() {
        if (this.state.applicantInfo.admisiaApplicantInfoDTO.photoPath !== null) {
            this.netiFileHandler.getByteImage(this.state.applicantInfo.admisiaApplicantInfoDTO.photoPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(this.state.applicantInfo.admisiaApplicantInfoDTO.photoName);
                                this.setState({ applicantPicture: contentType + body.fileContent });

                            })
                    } else {
                        return res.json()
                            .then((body) => {
                                console.log("image error", body);
                            })
                    }
                });
        }

    }





    onSearchHandler = () => {
        this.setState({ searchView: true,topProgressBar: true,searchErrorMsgBody: '',searchViewError: false, errorMsgVisible: false, applicantFormVisibility: false });
        this.setState({applicantInfo:cloneStateBeforeMount.applicantInfo, eligibilityStatus: ''});
        let { applicantInfo } = this.state;
        if (this.searchRegError()) {
            this.setState({firstInputFieldIsLoading: true});
            this.admisiaPointService.getApplicantInfoByRegNo(urlInfoID, this.state.regNo)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status === 302) {
                        return res.json().then((body) => {
                          
                            this.setState({ applicantFormVisibility: true });
                            this.setState({ applicantInfo: body, firstInputFieldIsLoading: false, searchViewError: false,  errorMsgVisible: false });
                            this.setState(({ eligibilityStatus }) => ({ eligibilityStatus: this.admisiaEligibilityStatus.checkStatus(body.admisiaApplicantInfoDTO.applicantStatus) }));
                            this.getProfileImg();
                        });
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ searchErrorMsgBody: responseBody, firstInputFieldIsLoading: false, errorMsgVisible: false, searchViewError: true})
                            });
                    }
                })
                .catch(error => {
                    this.setState({ topProgressBar: false ,errorMsgVisible: true,firstInputFieldIsLoading: false, searchViewError: false, errorMsgBody: "Please check your connection." })
                });

        }

    }

    searchRegError = () => {
        let { regError } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (this.state.regNo === '') {
            formIsValid = false;
            regError['registrationNo'] = 'Registration no can not left empty.';
        }

        this.setState({ regError });
        return formIsValid;
    }

    render() {

        console.log("Final Picture", this.state.applicantPicture);
        
        let { applicantInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        const pointType = [
            { label: 'DWS', value: 'DWS' },
            { label: 'Admisia', value: 'Admisia' },
            { label: 'Bijoy', value: 'Bijoy' }
        ]

        return (
            <div className="p-fluid">
                <div className="p-col-12 p-xl-12">
                    <div className="p-grid nw-form">
                        {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }
                        
                        <div className="p-col-12 p-xl-12">
                            <Growl ref={(el) => this.growl = el} />

                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-9">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Registration No</span>
                                            <InputText
                                                value={this.state.regNo}
                                                onChange={this.onChangeRegNo}
                                                placeholder="Enter Registration No"
                                                style={{ width: "100%" }}
                                                type="text"
                                                keyfilter="pint"
                                                name="registrationNo"
                                            />
                                    </div>
                                    <span className="error-message">{this.state.regError["registrationNo"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-3 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        onClick={this.onSearchHandler}
                                        icon="fas fa-search"
                                    />
                                </div>
                            </div>
                        </div>

                    { 

                        
                     this.state.searchView?
                        <div className="p-col-12 p-xl-12">
                             <div className="nw-search-view">
                             {  this.state.searchViewError ?
                                    <center className="error-message">{this.state.searchErrorMsgBody}</center> 
                                    :
                                    <AdmisiaApplicantViewInfo 
                                        firstInputFieldIsLoading={this.state.firstInputFieldIsLoading} 
                                        eligibilityStatus={this.state.eligibilityStatus} 
                                        applicantInfo={applicantInfo}
                                        photoInfo={this.state.applicantPicture}
                                    />
                                      
                                }
                            </div>
                   
                        </div>

                        :''}
                    </div>

                </div>
                    
            </div>
        );
    }
}