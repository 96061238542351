import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ErrorMessageView } from '../common/ErrorMessageView';
import CommonFuctionality from '../common/CommonFuctionality';
import { Calendar } from 'primereact/calendar';
import { UserCategoryService } from '../../service/UserCategoryService';
import { CUSTOM_MESSAGE } from '../common/CustomMessage';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';

let errors = {};
export class UpdateEMPaymentInfo extends Component {

    constructor() {
        super();
        this.state = {
            updatePaymentInfo: {
                paymentType: '',
                monthlyRate: '',
                developmentCharge: ''
            },
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            paymentInfoDialogHide: false,
            errorMsgVisible: false,
            errorMsgBody: null,
        }

        this.CommonFuctionality = new CommonFuctionality();
        this.UserOtherAccessService = new UserOtherAccessService();


    }

  
    paymentInfoUpdateDialog = () => {
        let { paymentInfoProps, basicInfoProps } = this.props

        console.log("paymentInfoProps",paymentInfoProps);
        
        this.setState({ paymentInfoDialogHide: true });
        this.CommonFuctionality.blurDialogBackgroundActive();

        this.setState({
            updatePaymentInfo: {
                emDetailsId: basicInfoProps.emDetailsId,
                paymentType: paymentInfoProps.paymentType,
                monthlyRate: paymentInfoProps.monthlyRate,
                developmentCharge: paymentInfoProps.developmentCharge
            }
        })
    }

    paymentInfoUpdateDialogHide = () => {
        this.setState({ paymentInfoDialogHide: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }



    onChangePaymentType = (e) => {
        let { updatePaymentInfo } = this.state;
        updatePaymentInfo.paymentType = e.target.value;
        errors["paymentType"] = ''
        this.setState({ updatePaymentInfo, errors });
    }

    onChangeMonthlyRate = (e) =>{
        let { updatePaymentInfo } = this.state;
        updatePaymentInfo.monthlyRate = e.target.value;
        errors["monthlyRate"] = ''
        this.setState({ updatePaymentInfo, errors });
    }

    onChangeDevelopmentCharge = (e) =>{
        let { updatePaymentInfo } = this.state;
        updatePaymentInfo.developmentCharge = e.target.value;
        errors["developmentCharge"] = ''
        this.setState({ updatePaymentInfo, errors });
    }

    updatePaymentInfoError = () =>{
        let { updatePaymentInfo, errors } = this.state;
        let formIsValid = true;

        if (updatePaymentInfo.paymentType === '') {
            formIsValid = false;
            errors["paymentType"] = "Payment Type can't left empty.";
        }

        if (updatePaymentInfo.monthlyRate === '') {
            formIsValid = false;
            errors["monthlyRate"] = "Monthly Rate can't left empty.";
        }
        if (updatePaymentInfo.developmentCharge === '') {
            formIsValid = false;
            errors["developmentCharge"] = "Development Charge can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    updatePaymentInfo = () =>{
        let { updatePaymentInfo } = this.state

        console.log('updateBasicInfo', updatePaymentInfo);

        
        if (this.updatePaymentInfoError()) {
            this.setState({ topProgressBar: true });
            this.UserOtherAccessService.updatePaymentInfo(updatePaymentInfo)
                .then(res => {
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated!" });
                        this.setState({ topProgressBar: false  });

                        setTimeout(() => {
                            this.paymentInfoUpdateDialogHide();
                        }, 800);
                    } else {
                        this.UserOtherAccessService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false , errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false , errorMsgVisible: true, errorMsgBody: "Please Check Your Connection !!!" })
                });
        }
        
    }

    render() {
        let { updatePaymentInfo, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        const paymentTypeList = [
            { label: 'PRE-PAID', value: 'PRE-PAID' },
            { label: 'POST-PAID', value: 'POST-PAID' }
        ];
       


        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                    <Growl ref={(el) => this.growl = el} />
                    <Dialog header="Update Payment Information" visible={this.state.paymentInfoDialogHide} onHide={this.paymentInfoUpdateDialogHide} className="nw-dialog product-info-dialog">
                        <div className="p-fluid">
                            <div className="p-grid  nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form-body">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Payment Type</span>
                                                <Dropdown
                                                    placeholder='Select Payment Type'
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                    options={paymentTypeList}
                                                    value={ updatePaymentInfo && updatePaymentInfo.paymentType}
                                                    onChange={this.onChangePaymentType}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["paymentType"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Monthly Rate<span>*</span></span>
                                                <InputText
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                    value={ updatePaymentInfo.monthlyRate }
                                                    onChange={this.onChangeMonthlyRate}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["monthlyRate"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Reg. Charge</span>
                                                <InputText
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                    value={ updatePaymentInfo.developmentCharge }
                                                    onChange={this.onChangeDevelopmentCharge}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["developmentCharge"] }</span>
                                        </div>
                                        <div className="p-col-12 p-col-12  nw-button-parent-multiple">
                                            <Button
                                                className="p-button-danger nw-button nw-button-multiple"
                                                label="Discard"
                                                icon="fas fa-times"
                                                onClick={this.paymentInfoUpdateDialogHide}
                                            />

                                            <Button
                                                className="p-button-primary nw-button nw-button-multiple"
                                                label="Update"
                                                icon="fas fa-check"
                                                onClick={this.updatePaymentInfo}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
            </div>


        )
    }
}