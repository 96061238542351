import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { BalanceService } from '../../../service/myPoint/BalanceService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { MY_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";


export class WalletBalanceWithdrawList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dialogRequestNote: '',
            viewDetails: false,
            dataTableSelection: null,
            rejectedStutas: 'Your Rejected Status Here',
            rowInformation: {},
            dataViewValue: [],
            dataTableValue: [],
            dataTableIsLoading: false,
            dateRequest: '',
            bank: '',
            accountNumber: '',
            requestedAmount: 0,
            dateTransaction: '',
            transactionNumber: '',
            approveDate: '',
            requestStatus: '',
            note: '',
            transactionAmount: '',

        };

        this.statusView = this.statusView.bind(this);
        this.Details = this.Details.bind(this);
        this.onHide = this.onHide.bind(this);
        this.balanceService = new BalanceService();
        this.NetiContentLoader = new NetiContentLoader();
        this.boldBody = this.boldBody.bind(this);
        this.validatorUtility = new ValidatorUtility();
    }

    componentWillMount() {
        this.viewTopTenBalanceWithdrawList()
    }

    viewTopTenBalanceWithdrawList = () => {
        this.setState({ dataTableIsLoading: true, errorMsgVisible: false })
        this.balanceService.getTopRTenBalanceListByType('withdraw')
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                                body[i].requestDate = NetiDateUtils.getAnyShortForm(body[i].requestDate, 'DD-MMM-YYYY hh:mm:ss a')
                        }
                        this.setState({
                            dataTableValue: body, dataTableIsLoading: false, errorMsgVisible: false
                        });
                    })
                } else {
                    this.balanceService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, dataTableIsLoading: false })
                        });
                }

            }).catch(error => {
                this.setState({ dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
    }



    statusView(rowData, column) {

        if (rowData['requestStatus'] === "Rejected") {
            return <span className="text-danger">
                <span
                    onClick={(e) => this.Details(rowData)}
                >
                    {rowData['requestStatus']}
                </span>
            </span>;
        } else if (rowData['requestStatus'] === "Approved") {
            return <span className="text-success">
                <span
                >
                    {rowData['requestStatus']}
                </span>
            </span>;
        } else if (rowData['requestStatus'] === "Pending") {
            return <span className="text-warning">
                <span
                >
                    {rowData['requestStatus']}
                </span>
            </span>;
        }
        else {
            return <span>{rowData['requestStatus']}</span>;
        }
    }

    Details = (rowData) => {
        let { rowInformation } = this.state
        rowInformation.requestNote = rowData.approveNote
        this.setState({ visible: true })
    }

    boldBody(rowData) {

        if (rowData['requestStatus'] === "Rejected") {
            return <div className="col-number text-right text-danger">{this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</div>
        } else if (rowData['requestStatus'] === "Approved") {
            return <div className="col-number text-right text-success">{this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</div>
        } else if (rowData['requestStatus'] === "Pending") {
            return <div className="col-number text-right text-warning">{this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</div>;
        }
        else {
            return (<b> {this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</b>);
        }

    }

    onHide() {
        this.setState({ visible: false })
    }

    onHideViewDetails = () => {
        this.setState({ viewDetails: false })
    }


    detailBody = (rowData, column) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title='Click to view withdraw details'
                onClick={(e) => this.viewDetailsDialog(rowData)}
            />
        </div>
    }

    viewDetailsDialog = (rowData) => {
        // console.log("ROWDATA", rowData);
        this.setState({ dialogRequestNote: rowData.requestNote });
        this.setState({ viewDetails: true })

        this.setState({ dateRequest: !rowData.requestDate ? "" : NetiDateUtils.getAnyShortForm(rowData['requestDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['requestDate']) })
        this.setState({ bank: rowData.bank })
        this.setState({ transactionType: rowData.accountNumber })
        this.setState({ requestedAmount: this.validatorUtility.currencyFormatter(rowData.requestedAmount) })
        this.setState({
            dateTransaction: !rowData.transactionDate ? "" :
                NetiDateUtils.getAnyShortForm(rowData['transactionDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['transactionDate'])
        })
        this.setState({ transactionNumber: rowData.transactionNumber })
        this.setState({ approveDate: !rowData.approveDate ? "" : NetiDateUtils.getAnyShortForm(rowData['approveDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['approveDate']) })
        this.setState({ requestStatus: rowData.requestStatus })
        this.setState({ note: rowData.approveNote })
        this.setState({ transactionAmount: this.validatorUtility.currencyFormatter(rowData.actualAmount) })
    }

    changeStatusFontColor = (status) => {
        if (status === "Rejected") {
            return <span className="text-danger">
                <span>
                    {status}
                </span>
            </span>;
        } else if (status === "Approved") {
            return <span className="text-success">
                <span>
                    {status}
                </span>
            </span>;
        } else if (status === "Pending") {
            return <span className="text-warn">
                <span>
                    {status}
                </span>
            </span>;
        }
        else {
            return <span>{status}</span>;

        }

    }

    changeRequestAmountFontColor = (requestedAmount) => {
        if (this.state.requestStatus === "Approved") {
            return <span className="text-success">
                <span>
                    {requestedAmount}
                </span>
            </span>;
        } else if (this.state.requestStatus === "Pending") {
            return <span className="text-warn">
                <span>
                    {requestedAmount}
                </span>
            </span>;
        } else if (this.state.requestStatus === "Rejected") {
            return <span className="text-danger">
                <span>
                    {requestedAmount}            
                </span>
            </span>;
        }
        else {
            return (<span>{requestedAmount}
            </span>);
        }

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let depositLinkText = "deposit-link-text";
        // let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
        //     Withdraw Short List
        //     <Link style={{ color: "white", display: "flex", float: "right" }} to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 1 }}>
        //         More<i className="fas fa-angle-right" style={{ "fontSize": "28px" }}></i>
        //     </Link>
        // </div>;

        let header = <div className="header-title">
            <div className="header-title-left">
                Balance Withdraw List
                        </div>


            <div className="header-title-right">

                <Link to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 1 }}>
                    More<i className="fas fa-angle-right" ></i>
                </Link>
            </div>
        </div>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid">

                    <Dialog header="Reject Information" className="nonHideDialog customDialogWidth" onHide={this.onHide} visible={this.state.visible}>
                        <h4>{this.state.rowInformation.requestNote}</h4>
                    </Dialog>
                    <Dialog header="Withdraw Details" className="nonHideDialog customDialogWidth nw-dialog" onHide={this.onHideViewDetails} visible={this.state.viewDetails}>
                        {/* <h4>{this.state.rowInformation.requestNote}</h4> */}
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12">

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Request Date
                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.dateRequest}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Bank
                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.bank}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Account Number
                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.transactionType}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Request Amount
                                        </span>
                                            <div className="nw-inputgroup-desc">


                                                {this.state.requestedAmount ? this.changeRequestAmountFontColor(this.state.requestedAmount) : ''}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Status
                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.requestStatus ? this.changeStatusFontColor(this.state.requestStatus) : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Note
                                        </span>
                                            <InputTextarea
                                                readOnly={true}
                                                name="text"
                                                value={this.state.dialogRequestNote}

                                            />
                                        </div>
                                    </div>




                                    {/* <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                        Transaction Date
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                         {this.state.dateTransaction} 
                                        </div>
                                    </div>
                                </div> */}

                                    {this.state.requestStatus === "Approved" ?
                                        <div className="formControl">

                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Transaction Date
                                            </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.dateTransaction}
                                                </div>
                                            </div>

                                        </div>

                                        : ''
                                    }


                                    {this.state.requestStatus === "Approved" ?
                                        <div className="formControl">

                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Transaction ID
                                            </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.transactionNumber}
                                                </div>
                                            </div>

                                        </div>

                                        : ''
                                    }

                                    {/* {this.state.requestStatus === "Approved" ?
                                    <div className="formControl">

                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Transaction Amount
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.transactionAmount}
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                } */}

                                    {/* {this.state.requestStatus === "Rejected" ?
                                    <div className="formControl">
                                        
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Transaction Amount
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                             {this.state.transactionAmount}
                                            </div>
                                        </div>
                                    
                                    </div>

                                    : ''
                                } */}


                                    {this.state.requestStatus === "Approved" ?
                                        <div className="formControl">

                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Approved Date
                                            </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.approveDate === "null" ? " " : this.state.approveDate}
                                                </div>
                                            </div>

                                        </div>

                                        : ''
                                    }

                                    {this.state.requestStatus === "Approved" ?
                                        <div className="formControl">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Approved Note
                                        </span>
                                                <InputTextarea
                                                    readOnly={true}
                                                    name="text"
                                                    value={this.state.note}

                                                />
                                            </div>
                                        </div>
                                        : ''
                                    }

                                    {this.state.requestStatus === "Rejected" ?
                                        <div className="formControl">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Reject Date
                                            </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.approveDate}
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                    }

                                    {this.state.requestStatus === "Rejected" ?

                                        <div className="formControl">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Reject Note
                                                </span>
                                                <InputTextarea
                                                    readOnly={true}
                                                    name="text"
                                                    value={this.state.note}
                                                />
                                            </div>
                                        </div>
                                        : ''
                                    }


                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <div className="p-col-12 p-xl-12">
                        {this.state.dataTableIsLoading ?
                            this.NetiContentLoader.MyPointTableLoader() :
                            <div className="nw-data-table nw-data-table-tabview">
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={header}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                >
                                    <Column field="requestDate" header="Request Date & Time" className="table-datetime"/>
                                    <Column field="bank" header="Bank" />
                                    <Column field="accountNumber" header="Account Number" />
                                    <Column field="requestedAmount" header="Request Amount" body={this.boldBody} />
                                    <Column field="requestStatus" header="Status" body={this.statusView} />
                                    <Column field="" header="Details" body={this.detailBody}  style={{ width: "96px" }}/>
                                </DataTable>
                            </div>
                        }
                    </div>
                </div>

            </div>
        );
    }
}