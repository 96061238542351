import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { BalanceService } from '../../../../service/myPoint/BalanceService';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import { InputTextarea } from "primereact/inputtextarea";

// pdf related import
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";

import {
  pdfmeta,
  pdfday,
  powerddBy_landscape,
  powerddBy_portrait,
  date_landscape,
  date_portrait,
  pfont,
  btmLine_landscape,
  btmLine_portrait,
  getPdfHeader
} from "../../../common/JsPdfEssentialFile";
import { pdfDownloadLandscape_TableWithSubTitle } from '../../../common/JsPdfDownload';
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
// pdf related import end

let maxDate = new Date();
export default class BalanceWithdrowLogDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dialogRequestNote: '',
            withDrawDateError: {},
            rowInformation: {
                requestNote: ''
            },

            dateRequest: '',
            bank: '',
            accountNumber: '',
            requestedAmount: '',
            dateTransaction: '',
            transactionNumber: '',
            approveDate: '',
            requestStatus: '',
            note: '',
            transactionAmount: '',
            searchData: {
                requestStartDate: '',
                requestEndDate: '',
                requestType: 'Withdraw',
                transactionType: 'Withdraw',
                status: '5',
                limit: 10
            },
            dataTableSelection: null,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataViewValue: [],
            tableView: false,
            inputErrorMessage: null,
            dataTableIsLoading: false
        };

        this.statusView = this.statusView.bind(this);
        this.startDateHandler = this.startDateHandler.bind(this);
        this.endDateHandler = this.endDateHandler.bind(this)
        this.withDrawHandleError = this.withDrawHandleError.bind(this)
        this.NetiDateUtils = new NetiDateUtils;
        this.BalanceService = new BalanceService;
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();

    }

    statusView = (rowData, column) => {
        if (rowData['requestStatus'] === "Rejected") {
            return <span className="text-danger">
                <span
                   onClick={(e) => this.viewDialog(rowData)}
                >
                    {rowData['requestStatus']}
                </span>
            </span>;
        } else if (rowData['requestStatus'] === "Approved") {
            return <span className="text-success">
                <span
                >
                    {rowData['requestStatus']}
                </span>
            </span>;
        } else if (rowData['requestStatus'] === "Pending") {
            return <span className="text-warn">
                <span
                >
                    {rowData['requestStatus']}
                </span>
            </span>;
        }
        else {
            return <span>{rowData['requestStatus']}</span>;
        }
    }

    viewDialog = (rowData) => {

        this.setState({ visible: true })
        this.setState({ dialogRequestNote: rowData.requestNote });
        this.setState({ dateRequest: !rowData.requestDate ? "" : NetiDateUtils.getAnyShortForm(rowData['requestDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['requestDate']) })
        this.setState({ bank: rowData.bank })
        this.setState({ transactionType: rowData.accountNumber })
        this.setState({ requestedAmount: this.validatorUtility.currencyFormatter(rowData.requestedAmount) })
        this.setState({
            dateTransaction: !rowData.transactionDate ? "" :
                NetiDateUtils.getAnyShortForm(rowData['transactionDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['transactionDate'])
        })
        this.setState({ transactionNumber: rowData.transactionNumber })
        this.setState({ approveDate: !rowData.approveDate ? "" : NetiDateUtils.getAnyShortForm(rowData['approveDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['approveDate']) })
        this.setState({ requestStatus: rowData.requestStatus })
        this.setState({ note: rowData.approveNote })
        this.setState({ transactionAmount: this.validatorUtility.currencyFormatter(rowData.actualAmount) })
    }


    onHide = (event) => {
        this.setState({ visible: false })
    }

    startDateHandler = (event, props) => {
        let searchData = this.state.searchData;
        searchData.requestStartDate = event.target.value;
        // this.setState({ searchData: searchData })
        this.clearDateError(event.target.name);
    }

    endDateHandler = (event, props) => {
        let searchData = this.state.searchData;
        searchData.requestEndDate = event.target.value;
        // this.setState({ searchData: searchData })
        this.clearDateError(event.target.name);
    }

    clearDateError = (name) => {
        let { withDrawDateError } = this.state;
        withDrawDateError[name] = ''
        this.setState({ withDrawDateError })
    }

    withdrawSearchRecode = (event, props) => {
        let { searchData } = this.state;
        this.setState({ dataViewValue: [] });

        if (this.withDrawHandleError()) {
            this.setState({ tableview: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(searchData.requestEndDate) >= Date.parse(searchData.requestStartDate)) {
                this.BalanceService.withDrawBalanceRequestByDate(this.state.searchData)
                    .then(res => {
                        if (res.status == 200) {
                            return res.json().then((body) => {
                                
                                if (body.length == 0) {
                                    return this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found.' })
                                }
                                for (let i = 0; i < body.length; i++) {
                                    body[i].requestDate = NetiDateUtils.getAnyShortForm(body[i].requestDate, 'DD-MMM-YYYY hh:mm:ss a')
                                }
                                this.setState({ dataViewValue: body, topProgressBar: false, tableView: true, dataTableIsLoading: false });
                            });
                        } else {
                            this.BalanceService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be greater than To Date." });
            }
        }
    }

    withDrawHandleError = () => {
        let { withDrawDateError } = this.state;
        let formIsValid = true;
        if (this.state.searchData.requestStartDate === '') {
            formIsValid = false;
            withDrawDateError["startDate"] = "Start Date can't left empty";
        }
        if (this.state.searchData.requestEndDate === '') {
            formIsValid = false;
            withDrawDateError["endDate"] = "End Date can't left empty";
        }
        this.setState({ withDrawDateError });
        return formIsValid;
    }

    detailBody = (rowData, column) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title='Click to view withdraw details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    requestedAmount = (rowData) => {
        if (rowData['requestStatus'] === "Rejected") {
            return <span className="text-danger">
                <span
                >
                    <b> {this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</b>
                </span>
            </span>;
        } else if (rowData['requestStatus'] === "Approved") {
            return <span className="text-success">
                <span
                >
                    <b> {this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</b>
                </span>
            </span>;
        } else if (rowData['requestStatus'] === "Pending") {
            return <span className="text-warn">
                <span
                >
                    <b> {this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</b>
                </span>
            </span>;
        }
        else {
            return (<b> {this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</b>);
        }
        // return (<b> {this.validatorUtility.currencyFormatter(rowData.requestedAmount)}</b>)

    }

    changeRequestAmountFontColor = (requestedAmount) => {
        if (this.state.requestStatus === "Approved") {
            return <span className="text-success">
                <span
                >
                    {requestedAmount}
                </span>
            </span>;
        } else if (this.state.requestStatus === "Pending") {
            return <span className="text-warn">
                <span
                >
                    {requestedAmount}
                </span>
            </span>;
        } else if (this.state.requestStatus === "Rejected") {
            return <span className="text-danger">
                <span
                >
                    {requestedAmount}            </span>
            </span>;
        }
        else {
            return (<span>{requestedAmount}
            </span>);
        }
    }

    changeStatusFontColor = (status) => {
        if (status === "Rejected") {
            return <span className="text-danger">
                <span
                >
                    {status}
                </span>
            </span>;
        } else if (status === "Approved") {
            return <span className="text-success">
                <span
                >
                    {status}
                </span>
            </span>;
        } else if (status === "Pending") {
            return <span className="text-warn">
                <span
                >
                    {status}
                </span>
            </span>;
        }
        else {
            return <span>{status}</span>;
        }
    }

   

    exportPdf = () => {
       
        let pdfColumns = [
            { title: "Request Date & Time", dataKey: "requestDate" },
            { title: "Bank", dataKey: "bank" },
            { title: "Account Number", dataKey: "accountNumber" },
            { title: "Requested Amount", dataKey: "requestedAmount"},
            { title: "Request Status", dataKey: "requestStatus" },
        ]
        
        let tableColumnStyles = {
            requestedAmount: { halign: "right"},
        }

        pdfDownloadLandscape_TableWithSubTitle("Wallet Logs Withdraw",120, pdfTitleHeader, pdfTitleHeaderData, pdfColumns, this.state.dataViewValue, tableColumnStyles,  "wallet_logs_withdraw.pdf");
          
    }



    render() {
        let { tableView, topProgressBar, errorMsgVisible, errorMsgBody, dataViewValue } = this.state;
        let tableHeader = <div className="header-title"><div className="header-title-left">Balance Withdraw List</div><div className="header-title-right"><a>Total Found: {dataViewValue.length.toLocaleString('EN-IN')}</a></div></div>;

        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" },
          ];
          pdfTitleHeaderData = [
            { A: "From Date", B: NetiDateUtils.getAnyShortForm(this.state.searchData.requestStartDate, 'DD-MMM-YYYY'), C: "End Date", D: NetiDateUtils.getAnyShortForm(this.state.searchData.requestEndDate, 'DD-MMM-YYYY') }
          ];


        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }


                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                    <Calendar
                                        value={this.state.searchData.requestStartDate}
                                        onChange={(e) => this.startDateHandler(e, this.props)}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                        name="startDate"
                                    />
                                </div>
                                <span className="error-message">{this.state.withDrawDateError["startDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date<span>*</span></span>
                                    <Calendar
                                        value={this.state.searchData.requestEndDate}
                                        onChange={(e) => this.endDateHandler(e, this.props)}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                        name="endDate"
                                    />
                                </div>
                                <span className="error-message">{this.state.withDrawDateError["endDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                <Button
                                    label="Search"
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    icon="fas fa-search"
                                    // iconPos="right"
                                    onClick={this.withdrawSearchRecode.bind(this)}
                                />
                            </div>
                        </div>

                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table nw-data-table-tabview">
                                    {
                                        tableView === true ?
                                            this.state.dataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader()
                                                :
                                                <div>
                                                    <DataTable
                                                        value={this.state.dataViewValue}
                                                        selectionMode="single"
                                                        header={tableHeader}
                                                        selection={this.state.dataTableSelection}
                                                        onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                        responsive={true}
                                                        paginator={true}
                                                        rows={10}
                                                    // rowsPerPageOptions={[10, 20, 30, 50, 100]}
                                                    >
                                                        <Column field="requestDate" header="Request Date & Time" filter={true} className="table-datetime"/>
                                                        <Column field="bank" header="Bank"  filter={true} />
                                                        <Column field="accountNumber" header="Account Number"  filter={true} />
                                                        <Column field="requestedAmount" header="Request Amount" className="text-right" body={this.requestedAmount} sortable={true} filter={true} />
                                                        <Column field="requestStatus" header="Status" body={this.statusView} sortable={true}  filter={true}  />
                                                        <Column field="" header="Details" body={this.detailBody} style={{width:"95px"}} />

                                                    </DataTable>
                                                    <div className="p-col-12 nw-button-parent" style={{paddingRight:"0px"}}>
                                                        <Button
                                                            label="Download PDF"
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            icon="fas fa-file-pdf"
                                                            onClick={this.exportPdf}
                                                        />
                                                    </div>
                                                </div>
                                            :
                                            <BlankDataTableAnim />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Dialog header="Withdraw Detail" className="nonHideDialog customDialogWidth nw-dialog" onHide={this.onHide} visible={this.state.visible}>
                    {/* <h4>{this.state.rowInformation.requestNote}</h4> */}
                    <div className="p-fluid">
                        <div className="p-grid nw-form">
                            <div className="p-col-12">

                                <div className="formControl">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Request Date
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                            {this.state.dateRequest}
                                        </div>
                                    </div>
                                </div>

                                <div className="formControl">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Bank
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                            {this.state.bank}
                                        </div>
                                    </div>
                                </div>

                                <div className="formControl">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Account Number
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                            {this.state.transactionType}
                                        </div>
                                    </div>
                                </div>

                                <div className="formControl">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Request Amount
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                        {this.state.requestedAmount ? this.changeRequestAmountFontColor(this.state.requestedAmount) : ''}
                                        </div>
                                    </div>
                                </div>

                                <div className="formControl">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Status
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                        {this.state.requestStatus ? this.changeStatusFontColor(this.state.requestStatus) : ''}
                                        </div>
                                    </div>
                                </div>

                                <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Note
                                        </span>
                                            <InputTextarea
                                                readOnly={true}
                                                name="text"
                                                value={this.state.dialogRequestNote}

                                            />
                                        </div>
                                    </div>




                                {/* <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                        Transaction Date
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                         {this.state.dateTransaction} 
                                        </div>
                                    </div>
                                </div> */}

                                {this.state.requestStatus === "Approved" ?
                                    <div className="formControl">

                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Transaction Date
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.dateTransaction}
                                            </div>
                                        </div>

                                    </div>

                                    : ''
                                }


                                {this.state.requestStatus === "Approved" ?
                                    <div className="formControl">

                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Transaction ID
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.transactionNumber}
                                            </div>
                                        </div>

                                    </div>

                                    : ''
                                }

                                {/* {this.state.requestStatus === "Approved" ?
                                    <div className="formControl">

                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Transaction Amount
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.transactionAmount}
                                            </div>
                                        </div>

                                    </div>

                                    : ''
                                } */}

                                {/* {this.state.requestStatus === "Rejected" ?
                                    <div className="formControl">
                                        
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Transaction Amount
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                             {this.state.transactionAmount}
                                            </div>
                                        </div>
                                    
                                    </div>

                                    : ''
                                } */}

                            

                                {this.state.requestStatus === "Approved" ?
                                    <div className="formControl">

                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Approved Date
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.approveDate === "null" ? " " : this.state.approveDate}
                                            </div>
                                        </div>

                                    </div>

                                    : ''
                                }

                                {this.state.requestStatus === "Approved" ?
                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Approved Note
                                        </span>
                                            <InputTextarea
                                                readOnly={true}
                                                name="text"
                                                value={this.state.note}

                                            />
                                        </div>
                                    </div>
                                    : ''
                                }

                                {this.state.requestStatus === "Rejected" ?
                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Reject Date
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.approveDate}
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }

                                {this.state.requestStatus === "Rejected" ?
                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Reject Note
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.note}
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }


                            </div>
                        </div>
                    </div>
                </Dialog>


            </div>
        );
    }
}