import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import CommonFuctionality from "../../common/CommonFuctionality";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { ProfileService } from '../../../service/profile/ProfileService';
import { TokenService } from '../../../service/token/TokenService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';
import NetiContentLoader from '../../common/NetiContentLoader';
import { Editor } from 'primereact/editor';
import { Paginator } from 'primereact/paginator';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { Fieldset } from 'primereact/fieldset';
import { CUSTOM_MESSAGE } from '../../common/CustomMessage';

let errors = {}
let btnDisabled;
let dialogType = '';
let activeInactiveDialogHeader = '';

export class PartnerList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            partnerUpdateBtnDisabled: true,
            dataTableValue: [],
            activeInactiveDialogVisible: false,
            partnerInfo: {},
            suspendTokenTypeID: '',
            suspendTokenDetails: '',
            parentPartnerPicture: '',
            partnerPicture: '',
            changingPartnerPurposeList: [],
            changeParentNetiIDError: {},
            parentTokenTypeID: '',
            netiIdSearchBtnDisabled: true,
            netiInformation: {
                netiID: '',
                fullName: '',
            },
            parentTokenDetails: '',
            areatokenTypeID: '',
            areaTokenDetails: '',
            changeAreaTokenInfo: {
                contentPic: '',
            },
            changePartnerTokenInfo: {
                contentPic: '',
            },
            changeParentTokenInfo: {
                contentPic: '',
            },
            suspendDetailsTokenInfo: {
                contentPic: '',
            },
            partnerTokenTypeID: '',
            partnerTokenDetails: '',
            partnerDetailsInfo: {
                userBasicInfoDTO: {
                    customNetiID: ''
                },
                userBasicInfoDTO: {
                    fullName: ''
                },
                userBasicInfoDTO: {
                    basicMobile: ''
                },
                userBasicInfoDTO: {
                    basicEmail: ''
                },
                partnerDesignationInfoDTO: {
                    categoryName: ''
                },
                partnerAreaInfoDTO: {
                    categoryName: ''
                }

            },
            customTokenError: {},
            suspendDetailsPurposeList: [],
            changeAreaInfoError: {},
            changeParentInfoError: {},
            suspendDetailsInfoError: {},
            changePartnerInfoError: {},
            changingAreaPurposeList: [],
            netiId: '',
            netiIdError: {},
            tokenId: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            parentAreaValueId: '',
            dataTableIsLoading: false,
            customTokenID: '',
            dataIsLoading: false,
            netiIdSearchIsLoading: false,
            tokenIdSearchIsLoading: false,
            areaDropdownIsLoading: false,
            parentDropdownIsLoading: false,
            disabled: false,
            searchDataObj: {
                pageLimit: 10,
                pageNo: 0
            },
            first: 0,
            rows: 10,
            updatePartnerObj: {
                "partnerAssignID": 0,
                "tokenInfoDTO": {
                    "customTokenID": ""
                },
                "userBasicInfoDTO": {
                    "netiID": 0
                }
            },
            updateAreaObj: {
                "partnerAssignID": 0,
                "tokenInfoDTO": {
                    "customTokenID": ""
                },
                "partnerAreaInfoDTO": {
                    "coreCategoryID": 0
                }
            },
            updateParentObj: {
                "partnerAssignID": 0,
                "tokenInfoDTO": {
                    "customTokenID": ""
                },
                "parentAssignDTO": {
                    "partnerAssignID": 0
                }
            },
            errors: {},
            changingParentPurposeList: [],
            unAssignAreaList: []
        };

        this.netiDateUtils = new NetiDateUtils();
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onchangePartner = this.onchangePartner.bind(this);
        this.onchangePartnerhide = this.onchangePartnerhide.bind(this);
        this.onchangeArea = this.onchangeArea.bind(this);
        this.onchangeAreaHide = this.onchangeAreaHide.bind(this);
        this.onchangeParent = this.onchangeParent.bind(this);
        this.onchangeParentHide = this.onchangeParentHide.bind(this);
        this.adminPointService = new AdminPointService();
        this.profileService = new ProfileService();
        this.tokenService = new TokenService();
        this.userCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
        this.netiFileHandler = new NetiFileHandler();
        this.CommonFuctionality = new CommonFuctionality();

        this.onPageChange = this.onPageChange.bind(this);

    }
    componentWillMount() {
        this.getAllPartnerAssignInfoList();
    }

    getChangingPurposeList = (defaultCode) => {
        this.setState({ topProgressBar: true, errorMsgBody: '', errorMsgVisible: false });
        this.userCategoryService.findByCategoryDefaultCode(defaultCode)
            .then(res => {
                this.setState({ topProgressBar: false, errorMsgVisible: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if (defaultCode === 'C11002032') {
                            this.setState({ changingAreaPurposeList: body });
                        } else if (defaultCode === 'C11002031') {
                            this.setState({ changingParentPurposeList: body });
                        }
                        else if (defaultCode === 'C11002030') {
                            this.setState({ changingPartnerPurposeList: body });
                        }
                        else if (defaultCode === 'C11002033' || defaultCode === 'C11002036') {
                            this.setState({ suspendDetailsPurposeList: body });
                        }
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection.", topProgressBar: false })
            });
    }

    onPageChange(event) {

        // console.log("event", event);

        let { searchDataObj } = this.state
        searchDataObj.pageNo = event.page

        this.setState({
            first: event.first,
            rows: event.rows
        });

        this.getAllPartnerAssignInfoList();

    }

    getAllPartnerAssignInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.adminPointService.getAllAssignedPartnersList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({
                            dataTableValue: body, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false
                        });
                    });
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }

    actionBody = (rowData) => {

        let activeInactiveBtn = '';

        if (rowData.status === 1) {

            activeInactiveBtn = < Button
                type="button"
                icon="fas fa-check"
                className="nw-action-button p-button-success"
                onClick={(e) => this.inactiveDialog(rowData)}
                tooltip="Click to Inactive"
            />

        } else if (rowData.status === 0) {

            activeInactiveBtn = < Button
                type="button"
                icon="fas fa-minus-circle"
                className="nw-action-button p-button-danger"
                onClick={(e) => this.activeDialog(rowData)}
                tooltip="Click to Active"
            />

        }

        return (
            <div className="text-center">
                <span style={{ marginRight: '5px' }}>
                    <Button
                        className="nw-action-button edit"
                        icon="fas fa-edit"
                        tooltip="Update"
                        onClick={e => this.viewDialog(rowData)}
                    />
                </span>
                <span>{activeInactiveBtn}</span>
            </div>
        );
    }

    activeDialog = (rowData) => {

        this.setState({ partnerInfo: rowData });
        this.setState({ activeInactiveDialogVisible: true });

        this.getChangingPurposeList('C11002036');

        this.CommonFuctionality.blurDialogBackgroundActive()

    }

    inactiveDialog = (rowData) => {

        this.setState({ partnerInfo: rowData });
        this.setState({ activeInactiveDialogVisible: true });

        this.getChangingPurposeList('C11002033');

        this.CommonFuctionality.blurDialogBackgroundActive()

    }

    clearDialogData = () => {
        this.setState({ suspendTokenTypeID: '', suspendTokenDetails: '', suspendDetailsTokenInfo: '' })
    }

    updatePartnerStatus = (rowData) => {
        let { partnerInfo } = this.state;
        let suspendPartnerInfo = {
            "partnerAssignID": partnerInfo.partnerAssignID,
            "partnerStatus": partnerInfo.status === 1 ? 0 : 1,
            "tokenInfoDTO": {
                "attachContent": this.state.suspendDetailsTokenInfo.contentPic,
                "attachSaveOrEditable": true,
                "attachmentName": Date.now() + this.netiFileHandler.getImageExtention(this.state.suspendDetailsTokenInfo.extention),
                "tokenDetails": this.state.suspendTokenDetails,
                "tokenTypeInfoDTO": {
                    "coreCategoryID": this.state.suspendTokenTypeID
                }
            }
        }

        if (this.updatePartnerStatusFormError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.adminPointService.updatePartnerActiveStatus(suspendPartnerInfo)
                .then(res => {
                    if (res.status === 202) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });

                        this.growl.show({ severity: 'success', summary: CUSTOM_MESSAGE.SUCCESS_MSG_SHORT, detail: CUSTOM_MESSAGE.SUCCESS_MSG_LONG_UPDATE })
                        this.onHideSuspendDetails();
                        setTimeout(() => {
                            this.getAllPartnerAssignInfoList();
                        }, 800);

                    } else {
                        this.dwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                });

        }
    }

    updatePartnerStatusFormError = () => {
        let { suspendDetailsInfoError } = this.state;
        let formIsValid = true;

        if (this.state.suspendTokenTypeID === "" || this.state.suspendTokenTypeID === null) {
            formIsValid = false;
            suspendDetailsInfoError["suspendTokenTypeID"] = "Changing Purpose can't left empty.";
        }
        if (this.state.suspendTokenDetails === "") {
            formIsValid = false;
            suspendDetailsInfoError["suspendTokenDetails"] = "Token Details can't left empty.";
        }
        if (this.state.suspendDetailsTokenInfo.contentPic === "") {
            formIsValid = false;
            suspendDetailsInfoError["suspendAttachments"] = "Attachment can't left empty.";
        }
        this.setState({ suspendDetailsInfoError });
        return formIsValid;
    };

    onchangePartner(event) {
        this.setState({ changePartner: true });
        this.getChangingPurposeList('C11002030');
    }
    onchangePartnerhide(event) {
        this.setState({ changePartner: false });
    }

    onchangeArea(event) {
        this.setState({ changeArea: true });
        this.getChangingPurposeList('C11002032');
        this.getPartnerUnassignedAreaList();
    }

    getPartnerUnassignedAreaList = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false, unAssignAreaList: [] })
        this.adminPointService.getUnAssignedAreaList(this.state.partnerDetailsInfo.partnerAreaInfoDTO.parentTypeInfoDTO.categoryDefaultCode)
            .then((res) => {
                if (res.status === 302) {
                    return res.json()
                        .then((body) => {
                            this.setState({ unAssignAreaList: body, topProgressBar: false, errorMsgVisible: false });
                        })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }
    onchangeAreaHide(event) {
        this.setState({ changeArea: false });
    }

    onchangeParent(event) {
        this.setState({ changeParent: true });
        this.getChangingPurposeList('C11002031');

        this.setState({ topProgressBar: true, parentDropdownIsLoading: true, errorMsgVisible: false })
        let designationID = this.state.partnerDetailsInfo.partnerDesignationInfoDTO.categoryDefaultCode;
        this.adminPointService.fetchParentPartnerByDesign(designationID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ parentPartnerList: body, topProgressBar: false, parentDropdownIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, parentDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, parentDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }

    onchangeParentHide(event) {
        this.setState({ changeParent: false });
    }

    onHide(event) {
        this.setState({ visible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive()

    }

    onHideSuspendDetails = () => {
        this.setState({ activeInactiveDialogVisible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.clearDialogData();
    }

    viewDialog(rowData) {
        this.getPartnerDetails(rowData.partnerAssignID);
        this.setState({ visible: true });
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    getPartnerDetails = (value) => {
        this.setState({ topProgressBar: true, dataIsLoading: true, errorMsgVisible: false })
        this.adminPointService.getpartnerAssignID(value)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log("partnerDetailsInfo", body)
                        this.setState({ partnerDetailsInfo: body });
                        this.setState({ diagNetiId: body.userBasicInfoDTO.customNetiID });
                        this.setState({ diagName: body.userBasicInfoDTO.fullName });
                        this.setState({ diagMobile: body.userBasicInfoDTO.basicMobile });
                        this.setState({ diagEmail: body.userBasicInfoDTO.basicEmail });
                        this.setState({ diagDesignation: body.partnerDesignationInfoDTO.categoryName });
                        this.setState({ diagPartnerAssignID: body.partnerAssignID });
                        this.setState({ diagArea: body.partnerAreaInfoDTO.categoryName });
                        this.setState({ diagCoreCategoryID: body.partnerAreaInfoDTO.coreCategoryID });

                        if (body.parentAssignDTO != null) {
                            this.setState({ diagPartnerAreaList: body.parentAssignDTO.partnerDesignationInfoDTO.categoryDefaultCode });
                            this.onSearchCustomParentID(this.state.diagPartnerAreaList)
                            this.getParentPartnerProfileImg();
                        }

                        this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: false })
                        this.getPartnerProfileImg();

                    });

                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeParentId = (e) => {
        this.setState({ parentId: e.target.value })
        // this.getTeamMemberInfoList(e.target.value)
    }

    handleTokenError = () => {
        let { customTokenError } = this.state;
        let formIsValid = true;

        if (this.state.tokenId === "") {
            formIsValid = false;
            customTokenError["customTokenID"] = "Please input Token Id";
        }
        this.setState({ customTokenError });
        return formIsValid;
    }

    handleNetiIdError = () => {
        let { netiIdError } = this.state;
        let formIsValid = true;

        if (this.state.netiId === "") {
            formIsValid = false;
            netiIdError["netiID"] = "Please input NetiId";
        }
        this.setState({ netiIdError });
        return formIsValid;
    }

    onSearchTokenId = () => {
        if (this.handleTokenError()) {
            this.setState({ topProgressBar: true, tokenIdSearchIsLoading: true, errorMsgVisible: false })
            this.tokenService.findTokenInfoByTokenID(this.state.tokenId)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            this.setState({ purchaseInfo: body });
                            this.setState({ tokenType: body.tokenTypeInfoDTO.categoryName });
                            this.setState({ attachmentPath: body.attachmentPath });
                            this.setState({ tokenInfoID: body.tokenInfoID });
                            this.setState({ customTokenID: body.customTokenID });
                            this.setState({ tokenCreateDate: NetiDateUtils.getAnyShortForm(body.createDate, 'DD-MMM-YYYY') });
                            this.setState({ topProgressBar: false, tokenIdSearchIsLoading: false })
                        });
                    } else {
                        this.tokenService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, tokenIdSearchIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                                this.setState({ tokenType: "" });
                                this.setState({ attachmentPath: "" });
                                this.setState({ tokenCreateDate: "" });
                                this.setState({ tokenInfoID: "" });
                                this.setState({ customTokenID: "" });
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, tokenIdSearchIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
        }
    }

    onSearchCustomNetiID = () => {
        if (this.handleNetiIdError()) {
            this.setState({ topProgressBar: true, netiIdSearchIsLoading: true, errorMsgVisible: false })
            this.profileService.findBasicInfoByCustomNetiID(this.state.netiId)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            //  this.setState({ userBasicInfo: body });
                            this.setState({ netiName: body.fullName });
                            this.setState({ netiMobile: body.basicMobile });
                            this.setState({ netiidSave: body.netiID });
                            this.setState({ customNetiID: body.customNetiID });
                            this.setState({ netiRegisterDate: NetiDateUtils.getAnyShortForm(body.registrationDate, 'DD-MMM-YYYY') });
                            this.setState({ partnerUpdateBtnDisabled: false, topProgressBar: false, netiIdSearchIsLoading: false })

                        });
                    } else {
                        this.profileService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ partnerUpdateBtnDisabled: true, topProgressBar: false, netiIdSearchIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                                this.setState({ netiName: "" });
                                this.setState({ netiMobile: "" });
                                this.setState({ netiRegisterDate: "" });
                                this.setState({ netiidSave: "" });

                            });
                    }
                }).catch(error =>
                    this.setState({ partnerUpdateBtnDisabled: true, topProgressBar: false, netiIdSearchIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
        }
    }

    onSearchCustomParentID = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false })
        this.adminPointService.fetchParentPartnerByDesign(this.state.diagPartnerAreaList)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ parentPartnerList: body });
                        this.setState({ topProgressBar: false, errorMsgVisible: false })
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    updatePartnerError = () => {
        let { changePartnerInfoError } = this.state;
        let formIsValid = true;

        if (this.state.partnerTokenTypeID === "" || this.state.partnerTokenTypeID === null) {
            formIsValid = false;
            changePartnerInfoError["partnerPurposeList"] = "Changing Purpose can't left empty.";
        }
        if (this.state.partnerTokenDetails === "") {
            formIsValid = false;
            changePartnerInfoError["partnerTokenDetails"] = "Token Details can't left empty.";
        }
        if (this.state.changePartnerTokenInfo.contentPic === "") {
            formIsValid = false;
            changePartnerInfoError["changingPartnerAttachments"] = "Attachment can't left empty.";
        }

        this.setState({ changePartnerInfoError });
        return formIsValid;
    }

    handleUpdatePartner = () => {
        if (this.updatePartnerError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })

            let updatePartnerInfo = {
                "partnerAssignID": this.state.diagPartnerAssignID,
                'userBasicInfoDTO': {
                    'netiID': this.state.netiidSave,
                },
                "tokenInfoDTO": {
                    "attachContent": this.state.changePartnerTokenInfo.contentPic,
                    "attachSaveOrEditable": true,
                    "attachmentName": Date.now() + this.netiFileHandler.getImageExtention(this.state.changePartnerTokenInfo.extention),
                    "tokenDetails": this.state.partnerTokenDetails,
                    "tokenTypeInfoDTO": {
                        "coreCategoryID": this.state.partnerTokenTypeID
                    }
                }
            }

            this.adminPointService.updatePartnerFromPartnerList(updatePartnerInfo)
                .then(res => {
                    if (res.status === 202) {
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully Created Category"
                        });
                        this.onchangePartnerhide();
                        this.getAllPartnerAssignInfoList();
                        this.setState({ topProgressBar: false, attachmentPath: '', tokenId: '', tokenType: '', tokenCreateDate: '', dataTableIsLoading: false })
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
        }

    }

    handleUpdateArea = () => {
        let { updateAreaObj } = this.state
        let updatePartnerAreaInfo = {
            "partnerAreaInfoDTO": {
                "coreCategoryID": this.state.parentAreaValueId
            },
            "partnerAssignID": this.state.diagPartnerAssignID
            ,
            "tokenInfoDTO": {
                "attachContent": this.state.changeAreaTokenInfo.contentPic,
                "attachSaveOrEditable": true,
                "attachmentName": Date.now() + this.netiFileHandler.getImageExtention(this.state.changeAreaTokenInfo.extention),
                "tokenDetails": this.state.areaTokenDetails,
                "tokenTypeInfoDTO": {
                    "coreCategoryID": this.state.areatokenTypeID
                }
            }
        }
        if (this.updatePartnerAreaError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false })
            this.adminPointService.updateAreaFromPartnerList(updatePartnerAreaInfo)
                .then(res => {
                    if (res.status === 202) {
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully Created Category"
                        });
                        this.onchangeAreaHide();
                        this.onHide();
                        this.getAllPartnerAssignInfoList();
                        this.setState({ topProgressBar: false, attachmentPath: '', tokenId: '', tokenType: '', tokenCreateDate: '', dataTableIsLoading: false })
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
        }
    }

    updatePartnerAreaError = () => {
        let { changeAreaInfoError } = this.state;
        let formIsValid = true;

        if (this.state.areatokenTypeID === "" || this.state.areatokenTypeID === null) {
            formIsValid = false;
            changeAreaInfoError["areaPurposeList"] = "Changing Purpose can't left empty.";
        }
        if (this.state.areaTokenDetails === "") {
            formIsValid = false;
            changeAreaInfoError["areaTokenDetails"] = "Token Details can't left empty.";
        }
        if (this.state.changeAreaTokenInfo.contentPic === "") {
            formIsValid = false;
            changeAreaInfoError["changeAreaTokenAttachments"] = "Attachment can't left empty.";
        }

        if (this.state.parentAreaValueId === "") {
            formIsValid = false;
            changeAreaInfoError["area"] = "Area can't left empty.";
        }

        this.setState({ changeAreaInfoError });
        return formIsValid;
    }

    handleUpdateParent = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        let updatePartnerParentInfo = {
            "partnerAssignID": this.state.diagPartnerAssignID
            ,
            "parentAssignDTO": this.state.netiInformation,
            "tokenInfoDTO": {
                "attachContent": this.state.changeParentTokenInfo.contentPic,
                "attachSaveOrEditable": true,
                "attachmentName": Date.now() + this.netiFileHandler.getImageExtention(this.state.changeParentTokenInfo.extention),
                "tokenDetails": this.state.parentTokenDetails,
                "tokenTypeInfoDTO": {
                    "coreCategoryID": this.state.parentTokenTypeID
                }
            }
        }

        this.adminPointService.updateParentFromPartnerList(updatePartnerParentInfo)
            .then(res => {
                if (res.status === 202) {
                    this.setState({ topProgressBar: false, attachmentPath: '', tokenId: '', tokenType: '', tokenCreateDate: '', dataTableIsLoading: false })
                    this.growl.show({
                        severity: "success",
                        summary: "Success Message",
                        detail: "Successfully Created Category"
                    });
                    this.onchangeParentHide();
                    this.getAllPartnerAssignInfoList();
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );

    }

    // extend search bar
    focusSearch = e => {
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white" });
    }

    blurSearch = e => {
        this.setState({ searchLabel: '', addSearchBtnClass: "" });
    }

    focusSearchToken = e => {
        this.setState({ searchLabelToken: "Search", addSearchBtnClassToken: "nw-button p-button-primary text-color-white" });
    }

    blurSearchToken = e => {
        this.setState({ searchLabelToken: '', addSearchBtnClassToken: "" });
    }

    focusSearchTokenId = e => {
        this.setState({ searchLabelTokenId: "Search", addSearchBtnClassTokenId: "nw-button p-button-primary text-color-white" });
    }

    blurSearchTokenId = e => {
        this.setState({ searchLabelTokenId: '', addSearchBtnClassTokenId: "" });
    }

    focusSearchTokenParent = e => {
        this.setState({ searchLabelTokenParent: "Search", addSearchBtnClassTokenParent: "nw-button p-button-primary text-color-white" });
    }

    blurSearchTokenParent = e => {
        this.setState({ searchLabelTokenParent: '', addSearchBtnClassTokenParent: "" });
    }

    onChangeAreaPurposeList = (e) => {
        let { changeAreaInfoError } = this.state;
        changeAreaInfoError["areaPurposeList"] = "";
        this.setState({ areatokenTypeID: e.target.value, changeAreaInfoError });
    }

    onChangeAreaTokenDetails = (e) => {
        let { changeAreaInfoError } = this.state;
        changeAreaInfoError["areaTokenDetails"] = "";
        this.setState({ areaTokenDetails: e.htmlValue, changeAreaInfoError });
    }

    onChangeParentArea = (e) => {
        let { changeAreaInfoError } = this.state;
        changeAreaInfoError["area"] = "";
        this.setState({ parentAreaValueId: e.target.value, changeAreaInfoError });
    }

    onChangeParentPurposeList = (e) => {
        let { changeParentInfoError } = this.state;
        changeParentInfoError["parentPurposeList"] = "";
        this.setState({ parentTokenTypeID: e.target.value, changeParentInfoError });
    }

    onChangeParentTokenDetails = (e) => {
        let { changeParentInfoError } = this.state;
        changeParentInfoError["parentTokenDetails"] = "";
        this.setState({ parentTokenDetails: e.htmlValue, changeParentInfoError });
    }

    onChangePartnerPurposeList = (e) => {
        let { changePartnerInfoError } = this.state;
        changePartnerInfoError["partnerPurposeList"] = "";
        this.setState({ partnerTokenTypeID: e.target.value, changePartnerInfoError });
    }

    onChangePartnerTokenDetails = (e) => {
        let { changePartnerInfoError } = this.state;
        changePartnerInfoError["partnerTokenDetails"] = "";
        this.setState({ partnerTokenDetails: e.htmlValue, changePartnerInfoError });
    }

    onChangeSuspendPurposeList = (e) => {
        let { suspendDetailsInfoError } = this.state;
        suspendDetailsInfoError["suspendTokenTypeID"] = "";
        this.setState({ suspendTokenTypeID: e.target.value, suspendDetailsInfoError });
    }

    onChangeSuspendTokenDetails = (e) => {
        let { suspendDetailsInfoError } = this.state;
        suspendDetailsInfoError["suspendTokenDetails"] = "";
        this.setState({ suspendTokenDetails: e.htmlValue, suspendDetailsInfoError });
    }

    removeButtonOnclick = () => {
        this.setState({ changeAreaTokenInfo: { contentPic: '' } });
        this.setState({ changeAreaTokenInfo: { contentName: '' } });

    }

    removeChangingParentFile = () => {
        this.setState({ changeParentTokenInfo: { contentPic: '' } });
        this.setState({ changeParentTokenInfo: { contentName: '' } });

    }

    removeChangingPartnerFile = () => {
        this.setState({ changePartnerTokenInfo: { contentPic: '' } });
        this.setState({ changePartnerTokenInfo: { contentName: '' } });

    }

    removeSuspendInfoAttachment = () => {
        this.setState({ suspendDetailsTokenInfo: { contentPic: '' } });
        this.setState({ suspendDetailsTokenInfo: { contentName: '' } });

    }

    areaTokenFileUpload(e) {
        let { changeAreaInfoError } = this.state;
        changeAreaInfoError["changeAreaTokenAttachments"] = "";
        this.setState({ changeAreaInfoError });

        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let tokenPic = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ changeAreaTokenInfo: tokenPic });
        }

    }

    changingParentTokenFileUpload(e) {
        let { changeParentInfoError } = this.state;
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let tokenPic = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ changeParentTokenInfo: tokenPic });
        }
        changeParentInfoError["changingParentAttachments"] = "";
        this.setState({ changeParentInfoError });

    }

    suspendTokenFileUpload(e) {
        let { suspendDetailsInfoError } = this.state;
        suspendDetailsInfoError["suspendAttachments"] = "";
        this.setState({ suspendDetailsInfoError });
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let tokenPic = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ suspendDetailsTokenInfo: tokenPic });
        }

    }

    changingPartnerTokenFileUpload(e) {
        let { changePartnerInfoError } = this.state;
        changePartnerInfoError["changingPartnerAttachments"] = "";
        this.setState({ changePartnerInfoError });
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let tokenPic = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ changePartnerTokenInfo: tokenPic });
        }
    }

    changeParentNetiIdCheckHandler = (e) => {
        let { changeParentNetiIDError } = this.state;
        this.setState({ searchViewNetiId: true, searchViewErrorNetiId: false });
        if (this.changeParentNetiIDCodeError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.adminPointService.getPartnerInfoByCustomNetiID(this.state.customNetiId)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ netiInformation: body, searchViewErrorNetiId: false, topProgressBar: false, netiIDSearchBtnDisabled: false });
                        });
                    }
                    else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ searchViewErrorNetiId: true, topProgressBar: false, errorMsgVisible: true, netiIDSearchBtnDisabled: true, errorMsgVisible: true, errorMsgBody: resp });
                            })
                    }
                }).catch(error => {
                    this.setState({ searchViewErrorNetiId: false, topProgressBar: false, secondInputFieldIsLoading: false, errorMsgVisible: true, netiIDSearchBtnDisabled: true, errorMsgBody: 'Unable to load. Please check your connection.' })
                });
        } else {
            changeParentNetiIDError["customNetiId"] = "Please input NetiId";
            this.setState({ searchViewNetiId: false, netiIdSearchBtnDisabled: true })
        }
    }

    changeParentNetiIDCodeError = () => {
        let { changeParentNetiIDError } = this.state;
        let formIsValid = true;
        if (this.state.customNetiId === '') {
            formIsValid = false;
            changeParentNetiIDError["customNetiId"] = "Neti ID can't left empty";
        } else {
            changeParentNetiIDError["customNetiId"] = "";
        }
        this.setState({ changeParentNetiIDError });
        return formIsValid;
    }

    getPartnerProfileImg = () => {

        let { partnerDetailsInfo } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        if (partnerDetailsInfo.userBasicInfoDTO.imagePath !== null) {
            this.netiFileHandler.getByteImage(partnerDetailsInfo.userBasicInfoDTO.imagePath)
                .then((res) => {

                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(partnerDetailsInfo.userBasicInfoDTO.imageName);
                                this.setState({ partnerPicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ partnerPicture: staticImg });
                    }
                });
        } else { this.setState({ partnerPicture: staticImg }) };

    }

    getParentPartnerProfileImg = () => {

        let { partnerDetailsInfo } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        if (partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.imagePath !== null) {
            this.netiFileHandler.getByteImage(partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.imagePath)
                .then((res) => {

                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.imageName);
                                this.setState({ parentPartnerPicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ parentPartnerPicture: staticImg });
                    }
                });
        } else { this.setState({ parentPartnerPicture: staticImg }) };

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, partnerInfo } = this.state;
        let headervalue = (
            <div className="p-clearfix">
                Partner List{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {this.state.dataTableValue.length}{" "}
                </span>
            </div>
        );

        let areaListOptions = [];
        if (this.state.unAssignAreaList && this.state.unAssignAreaList.length) {
            areaListOptions = this.state.unAssignAreaList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let parentPartnerOption = [];
        if (this.state.parentPartnerList && this.state.parentPartnerList.length) {
            parentPartnerOption = this.state.parentPartnerList.map(item => ({
                value: item.partnerAssignID,
                label: item.userBasicInfoDTO.fullName + ' (' + item.partnerAreaInfoDTO.categoryName + ')'
            }));
        }

        let areaPurposeList = []
        if (this.state.changingAreaPurposeList && this.state.changingAreaPurposeList.length) {
            areaPurposeList = this.state.changingAreaPurposeList.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        let parentPurposeList = []
        if (this.state.changingParentPurposeList && this.state.changingParentPurposeList.length) {
            parentPurposeList = this.state.changingParentPurposeList.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        let partnerPurposeList = []
        if (this.state.changingPartnerPurposeList && this.state.changingPartnerPurposeList.length) {
            partnerPurposeList = this.state.changingPartnerPurposeList.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        let partnerSuspendPurposeList = []
        if (this.state.suspendDetailsPurposeList && this.state.suspendDetailsPurposeList.length) {
            partnerSuspendPurposeList = this.state.suspendDetailsPurposeList.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <style jsx>{`
                    .p-col-12 {
                        margin: -10px auto;
                    }
                    `}</style>
                {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}

                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table blur-section">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <div>
                                            <DataTable
                                                header={headervalue}
                                                value={this.state.dataTableValue}
                                                responsive={true}
                                                rows={10}
                                                paginator={true}
                                            >
                                                <Column
                                                    field="designation"
                                                    header="Designation"
                                                    sortable={true}
                                                    filter={true}
                                                />
                                                <Column
                                                    field="area"
                                                    header="Area"
                                                    sortable={true}
                                                    filter={true}
                                                />
                                                <Column
                                                    field="customID"
                                                    header="Neti ID"
                                                    sortable={true}
                                                    filter={true}
                                                />
                                                <Column
                                                    field="name"
                                                    header="Name"
                                                    sortable={true}
                                                    filter={true}
                                                />
                                                <Column
                                                    field="mobileNo"
                                                    header="Mobile No."
                                                    sortable={true}
                                                    filter={true}
                                                />
                                                <Column body={this.actionBody} style={{ width: '120px' }} header="Action" />
                                            </DataTable>

                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        header="Partner Details "
                        visible={this.state.visible}
                        modal={true}
                        onHide={this.onHide}
                    >
                        <div className="nw-form partner-profile-wrapper">
                            <div className="nw-form-body">
                                <div className="p-grid p-col-12 p-lg-12 p-xl-12 m-t-25">
                                    <div className="p-col-12 p-md-4">
                                        <div className="profile-wrapper">

                                            <center>
                                                <div className="profile-pic m-b-20">
                                                    <img
                                                        src={this.state.partnerPicture}
                                                        alt="partner picture"
                                                    />
                                                </div>
                                                <div className="profile-button">
                                                    <Button
                                                        label="Update"
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        onClick={this.onchangePartner}
                                                    />
                                                </div>
                                            </center>

                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Fieldset className="p-col-12 p-xl-12" legend="Partner Info">
                                            <span className="profile-name"> {this.state.diagName}</span>
                                            <span className="profile-position">{this.state.diagDesignation}</span>
                                            <div className="detailslist-wrapper">
                                                <ul>
                                                    <li><i class="fas fa-book"></i> {this.state.diagNetiId}</li>
                                                    <li><i class="fas fa-phone-square"></i> {this.state.diagMobile}</li>
                                                    <li><i class="fas fa-envelope"></i> {this.state.diagEmail}</li>
                                                    <li><i class="fas fa-calendar-alt"></i> {NetiDateUtils.getAnyShortForm(this.state.partnerDetailsInfo.assignDate, 'DD-MMM-YYYY')}</li>
                                                    {/* <li><i class="fas fa-download"></i> File.jpg</li> */}
                                                </ul>
                                            </div>
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Area
                                            </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.diagArea}
                                                </div>
                                                {/* <Button
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            label="Change Area"
                                            onClick={this.onchangeArea}
                                        /> */}
                                                <Button
                                                    label="Change Area"
                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                    onClick={this.onchangeArea}
                                                />
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                                <div className="p-grid p-col-12 p-lg-12 p-xl-12 m-t-25 m-b-20">
                                    <div className="p-col-12 p-md-4">
                                        <div className="profile-wrapper">

                                            <center>
                                                <div className="profile-pic no-radius m-b-20">
                                                    <img
                                                        src={this.state.parentPartnerPicture}
                                                        alt="partner picture"
                                                    />
                                                </div>
                                                <div className="profile-button">
                                                    <Button
                                                        label="Replace Team Lead"
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        onClick={this.onchangeParent}
                                                    />
                                                </div>
                                            </center>

                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Fieldset className="p-col-12 p-xl-12" legend="Team Lead Info">
                                            <span className="profile-name">{this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.fullName}
                                            </span>
                                            <span className="profile-position">{this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.partnerDesignationInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.partnerDesignationInfoDTO.categoryName}</span>
                                            <div className="detailslist-wrapper">
                                                <ul>
                                                    <li><i class="fas fa-book"></i>  {this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.customNetiID}</li>
                                                    <li><i class="fas fa-phone-square"></i>{this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.basicMobile}
                                                    </li>
                                                    <li><i class="fas fa-envelope"></i>{this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.basicEmail}
                                                    </li>

                                                    {/* <li><i class="fas fa-download"></i> File.jpg</li> */}
                                                </ul>
                                            </div>
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Area
                                            </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.partnerAreaInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.partnerAreaInfoDTO.categoryName}

                                                </div>
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <Fieldset legend="Parent Info"> */}
                        {/* <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <h4>Parent Info</h4>
                                    <br />

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.dataIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div>
                                                <label>Neti ID</label>
                                                <div className="p-inputgroup padding-top">
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.customNetiID}
                                                    </div>
                                                    <Button
                                                        className="p-button p-button-primary nw-dialog-button"
                                                        label="Change Parent"
                                                        title="Change Parent"
                                                        onClick={this.onchangeParent}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div class="nw-search-view">
                                        <div>
                                            <div className="p-col-12 p-xl-12">
                                                {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() :
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon"> Name</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.fullName}
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon">  Mobile No </span>
                                                    <span className="p-inputgroup-colon">:</span>

                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.basicMobile}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon"> Email Address </span>
                                                    <span className="p-inputgroup-colon">:</span>

                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.userBasicInfoDTO.basicEmail}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon"> Designation </span>
                                                    <span className="p-inputgroup-colon">:</span>

                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.partnerDesignationInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.partnerDesignationInfoDTO.categoryName}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon"> Area </span>
                                                    <span className="p-inputgroup-colon">:</span>

                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.partnerDetailsInfo && this.state.partnerDetailsInfo.parentAssignDTO && this.state.partnerDetailsInfo.parentAssignDTO.partnerAreaInfoDTO && this.state.partnerDetailsInfo.parentAssignDTO.partnerAreaInfoDTO.categoryName}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div> */}

                    </Dialog>
                    <Dialog
                        className="nw-dialog"
                        header="Change Partner"
                        visible={this.state.changePartner}
                        modal={true}
                        onHide={this.onchangePartnerhide}
                    >

                        <br />

                        <div className="p-fluid">
                            <div ClassName="p-grid">
                                <div ClassName="p-col-12 p-xl-12">
                                    <div className="nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">purpose <span>*</span></span>
                                                        <Dropdown
                                                            placeholder='select purpose'
                                                            value={this.state.partnerTokenTypeID}
                                                            name="partnerPurposeList"
                                                            options={partnerPurposeList}
                                                            onChange={this.onChangePartnerPurposeList}
                                                            filter={true}
                                                            autoWidth={false}
                                                        />
                                                    </div>

                                                    <span className='error-message'>{this.state.changePartnerInfoError["partnerPurposeList"]}</span>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">DETAILS <span>*</span></span>
                                                        <Editor
                                                            className="nw-inputtexteditor"
                                                            value={this.state.partnerTokenDetails}
                                                            name="partnerTokenDetails"
                                                            onTextChange={this.onChangePartnerTokenDetails}
                                                            autoWidth={false}
                                                            maxLength={200}
                                                            placeholder="Enter token Details"

                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.changePartnerInfoError["partnerTokenDetails"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup nw-upload-button">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                            Upload Attachment <span>*</span>
                                                            <br />
                                                            <span>(PNG/JPG/PDF)</span>
                                                        </span>

                                                        <div className="nw-upload-button-inside">

                                                            {
                                                                this.state.changePartnerTokenInfo.contentPic ?
                                                                    <div className="image-view-main">
                                                                        <div className="upload-image-view">
                                                                            <Button
                                                                                className="delete-upload-button"
                                                                                icon="fas fa-times-circle"
                                                                                onClick={this.removeChangingPartnerFile}
                                                                            />
                                                                            {this.state.changePartnerTokenInfo.contentName ?
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.changePartnerTokenInfo.contentName) : ''}

                                                                        </div>
                                                                        <div className="image-title">{this.state.changePartnerTokenInfo.contentName}</div>
                                                                    </div>

                                                                    :
                                                                    <FileUpload
                                                                        id="attachments"
                                                                        name="changingPartnerAttachments"
                                                                        mode="basic"
                                                                        maxFileSize={1000000}
                                                                        onSelect={this.changingPartnerTokenFileUpload.bind(this)}
                                                                        auto={true}
                                                                        chooseLabel={this.state.changePartnerTokenInfo.contentName ? this.state.changePartnerTokenInfo.contentName : "Click / Drag Here To Upload"}

                                                                    />
                                                            }

                                                        </div>

                                                    </div>
                                                    <span className='error-message'>{this.state.changePartnerInfoError["changingPartnerAttachments"]}</span>
                                                </div>
                                                <div className="p-col-12 p-xl-12">

                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Neti ID <span>*</span></span>
                                                        <InputText
                                                            value={this.state.netiId}
                                                            onChange={(e) => this.setState({ netiId: e.target.value })}
                                                            keyfilter="pint"
                                                            placeholder="Enter Neti ID"
                                                            style={{ width: "15%" }}
                                                            type="text"
                                                            name="netiID"
                                                            onFocus={this.focusSearch}
                                                            onBlur={this.blurSearch}
                                                        />
                                                        <Button
                                                            className={"p-button-animation " + this.state.addSearchBtnClass}
                                                            label={this.state.searchLabel}
                                                            icon="fas fa-search"
                                                            onClick={this.onSearchCustomNetiID}
                                                            onFocus={this.focusSearch}
                                                            onBlur={this.blurSearch}
                                                        />
                                                    </div>
                                                </div>
                                                <span className="error-message">{this.state.netiIdError["netiID"]}</span>

                                                <br />
                                                <div class="nw-search-view">
                                                    <div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.dataIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon"> Name </span>
                                                                    <span className="p-inputgroup-colon">:</span>

                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.netiName}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.dataIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon"> Mobile No </span>
                                                                    <span className="p-inputgroup-colon">:</span>

                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.netiMobile}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.dataIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon"> Register Date </span>
                                                                    <span className="p-inputgroup-colon">:</span>

                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.netiRegisterDate}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                            <Button
                                                                label="Replace"
                                                                className="p-button p-button-primary nw-button nw-button-multiple"
                                                                onClick={this.handleUpdatePartner.bind(this)}
                                                                disabled={this.state.partnerUpdateBtnDisabled}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>

                    <Dialog
                        className="nw-dialog"
                        header={partnerInfo.status === 1 ? 'Suspend Details' : 'Active Details'}
                        visible={this.state.activeInactiveDialogVisible}
                        modal={true}
                        onHide={this.onHideSuspendDetails}
                    >

                        <div className="p-fluid">
                            <div ClassName="p-grid">
                                <div ClassName="p-col-12 p-xl-12">
                                    <div className="nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">
                                                <br />
                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.packageDropdownIsLoading ?
                                                        this.NetiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Change purpose <span>*</span></span>
                                                            <Dropdown
                                                                placeholder='select purpose'
                                                                value={this.state.suspendTokenTypeID}
                                                                name="suspendTokenTypeID"
                                                                options={partnerSuspendPurposeList}
                                                                onChange={this.onChangeSuspendPurposeList}
                                                                filter={true}
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    }
                                                    <span className='error-message'>{this.state.suspendDetailsInfoError["suspendTokenTypeID"]}</span>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">DETAILS <span>*</span></span>
                                                        <Editor
                                                            className="nw-inputtexteditor"
                                                            value={this.state.suspendTokenDetails}
                                                            name="suspendTokenDetails"
                                                            onTextChange={this.onChangeSuspendTokenDetails}
                                                            autoWidth={false}
                                                            maxLength={200}
                                                            placeholder="Enter token Details"

                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.suspendDetailsInfoError["suspendTokenDetails"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup nw-upload-button">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                            Upload Attachment <span>*</span>
                                                            <br />
                                                            <span>(PNG/JPG/PDF)</span>
                                                        </span>

                                                        <div className="nw-upload-button-inside">

                                                            {
                                                                this.state.suspendDetailsTokenInfo.contentPic ?
                                                                    <div className="image-view-main">
                                                                        <div className="upload-image-view">
                                                                            <Button
                                                                                className="delete-upload-button"
                                                                                icon="fas fa-times-circle"
                                                                                onClick={this.removeSuspendInfoAttachment}
                                                                            />
                                                                            {this.state.suspendDetailsTokenInfo.contentName ?
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.suspendDetailsTokenInfo.contentName) : ''}

                                                                        </div>
                                                                        <div className="image-title">{this.state.suspendDetailsTokenInfo.contentName}</div>
                                                                    </div>

                                                                    :
                                                                    <FileUpload
                                                                        id="attachments"
                                                                        name="suspendAttachments"
                                                                        mode="basic"
                                                                        maxFileSize={1000000}
                                                                        onSelect={this.suspendTokenFileUpload.bind(this)}
                                                                        auto={true}
                                                                        chooseLabel={this.state.suspendDetailsTokenInfo.contentName ? this.state.suspendDetailsTokenInfo.contentName : "Click / Drag Here To Upload"}

                                                                    />
                                                            }

                                                        </div>

                                                    </div>
                                                    <span className='error-message'>{this.state.suspendDetailsInfoError["suspendAttachments"]}</span>
                                                </div>

                                                <br />
                                                <div className="p-col-12 p-xl-12">
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                        <Button
                                                            label="Submit"
                                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                                            onClick={this.updatePartnerStatus.bind(this)}
                                                        />
                                                    </div>

                                                </div>
                                                <br />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>

                    <Dialog
                        className="nw-dialog nw-form"
                        header="Change Area"
                        visible={this.state.changeArea}
                        modal={true}
                        onHide={this.onchangeAreaHide}
                    >

                        <div className="p-fluid">
                            <div ClassName="p-grid">
                                <div ClassName="p-col-12 p-xl-12">
                                    <div className="nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">
                                                <br />
                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.packageDropdownIsLoading ?
                                                        this.NetiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Change purpose <span>*</span></span>
                                                            <Dropdown
                                                                placeholder='select purpose'
                                                                value={this.state.areatokenTypeID}
                                                                name="areaPurposeList"
                                                                options={areaPurposeList}
                                                                onChange={this.onChangeAreaPurposeList}
                                                                filter={true}
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    }
                                                    <span className='error-message'>{this.state.changeAreaInfoError["areaPurposeList"]}</span>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">DETAILS <span>*</span></span>
                                                        <Editor
                                                            className="nw-inputtexteditor"
                                                            value={this.state.areaTokenDetails}
                                                            name="areaTokenDetails"
                                                            onTextChange={this.onChangeAreaTokenDetails}
                                                            autoWidth={false}
                                                            maxLength={200}
                                                            placeholder="Enter token Details"

                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.changeAreaInfoError["areaTokenDetails"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup nw-upload-button">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                            Upload Attachment <span>*</span>
                                                            <br />
                                                            <span>(PNG/JPG/PDF)</span>
                                                        </span>

                                                        <div className="nw-upload-button-inside">

                                                            {
                                                                this.state.changeAreaTokenInfo.contentPic ?
                                                                    <div className="image-view-main">
                                                                        <div className="upload-image-view">
                                                                            <Button
                                                                                className="delete-upload-button"
                                                                                icon="fas fa-times-circle"
                                                                                onClick={this.removeButtonOnclick}
                                                                            />
                                                                            {this.state.changeAreaTokenInfo.contentName ?
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.changeAreaTokenInfo.contentName) : ''}

                                                                        </div>
                                                                        <div className="image-title">{this.state.changeAreaTokenInfo.contentName}</div>
                                                                    </div>

                                                                    :
                                                                    <FileUpload
                                                                        id="attachments"
                                                                        name="changeAreaTokenAttachments"
                                                                        mode="basic"
                                                                        maxFileSize={1000000}
                                                                        onSelect={this.areaTokenFileUpload.bind(this)}
                                                                        auto={true}
                                                                        chooseLabel={this.state.changeAreaTokenInfo.contentName ? this.state.changeAreaTokenInfo.contentName : "Click / Drag Here To Upload"}

                                                                    />
                                                            }

                                                        </div>

                                                    </div>
                                                    <span className='error-message'>{this.state.changeAreaInfoError["changeAreaTokenAttachments"]}</span>
                                                </div>
                                                {this.state.areaDropdownIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() :
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Area <span>*</span></span>
                                                            <Dropdown
                                                                placeholder='Select Area'
                                                                options={areaListOptions}
                                                                value={this.state.parentAreaValueId}
                                                                onChange={this.onChangeParentArea}
                                                                filter={true}
                                                                filterBy="label,value"
                                                                autoWidth={false}
                                                                name="area"
                                                                showClear={true}
                                                            />
                                                        </div>
                                                        <span className='error-message'>{this.state.changeAreaInfoError["area"]}</span>
                                                    </div>
                                                }
                                                <br />
                                                <div className="p-col-12 p-xl-12">
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                        <Button
                                                            label="Change"
                                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                                            onClick={this.handleUpdateArea.bind(this)}
                                                        />
                                                    </div>

                                                </div>
                                                <br />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>

                    <Dialog
                        className="nw-dialog nw-form"
                        header="Replace Team Lead"
                        visible={this.state.changeParent}
                        modal={true}
                        onHide={this.onchangeParentHide}
                    >
                        <div className="p-fluid">
                            <div ClassName="p-grid">
                                <div ClassName="p-col-12 p-xl-12">
                                    <div className="nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">
                                                <br />

                                                <div className="p-col-12 p-xl-12">

                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">purpose <span>*</span></span>
                                                        <Dropdown
                                                            placeholder='select purpose'
                                                            value={this.state.parentTokenTypeID}
                                                            name="parentPurposeList"
                                                            options={parentPurposeList}
                                                            onChange={this.onChangeParentPurposeList}
                                                            filter={true}
                                                            autoWidth={false}
                                                        />
                                                    </div>

                                                    <span className='error-message'>{this.state.changeParentInfoError["parentPurposeList"]}</span>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">DETAILS <span>*</span></span>
                                                        <Editor
                                                            className="nw-inputtexteditor"
                                                            value={this.state.parentTokenDetails}
                                                            name="parentTokenDetails"
                                                            onTextChange={this.onChangeParentTokenDetails}
                                                            autoWidth={false}
                                                            maxLength={200}
                                                            placeholder="Enter token Details"

                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.changeParentInfoError["parentTokenDetails"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup nw-upload-button">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                            Upload Attachment <span>*</span>
                                                            <br />
                                                            <span>(PNG/JPG/PDF)</span>
                                                        </span>

                                                        <div className="nw-upload-button-inside">

                                                            {
                                                                this.state.changeParentTokenInfo.contentPic ?
                                                                    <div className="image-view-main">
                                                                        <div className="upload-image-view">
                                                                            <Button
                                                                                className="delete-upload-button"
                                                                                icon="fas fa-times-circle"
                                                                                onClick={this.removeChangingParentFile}
                                                                            />
                                                                            {this.state.changeParentTokenInfo.contentName ?
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.changeParentTokenInfo.contentName) : ''}

                                                                        </div>
                                                                        <div className="image-title">{this.state.changeParentTokenInfo.contentName}</div>
                                                                    </div>

                                                                    :
                                                                    <FileUpload
                                                                        id="attachments"
                                                                        name="changingParentAttachments"
                                                                        mode="basic"
                                                                        maxFileSize={1000000}
                                                                        onSelect={this.changingParentTokenFileUpload.bind(this)}
                                                                        auto={true}
                                                                        chooseLabel={this.state.changeParentTokenInfo.contentName ? this.state.changeParentTokenInfo.contentName : "Click / Drag Here To Upload"}

                                                                    />
                                                            }

                                                        </div>

                                                    </div>
                                                    {/* <span className='error-message'>{this.state.changeParentInfoError["changingParentAttachments"]}</span> */}
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Neti Id <span>*</span></span>
                                                        <InputText
                                                            value={this.state.customNetiId}
                                                            onChange={(e) => this.setState({ customNetiId: e.target.value })}
                                                            keyfilter="pint"
                                                            placeholder="Enter Neti Id"
                                                            style={{ width: "15%" }}
                                                            type="text"
                                                            name="customNetiId"
                                                            onFocus={this.focusSearchTokenParent}
                                                            onBlur={this.blurSearchTokenParent}
                                                        />
                                                        <Button
                                                            className={"p-button-animation " + this.state.addSearchBtnClassTokenParent}
                                                            label={this.state.searchLabelTokenParent}
                                                            icon="fas fa-search"
                                                            onClick={this.changeParentNetiIdCheckHandler}
                                                            onFocus={this.focusSearchTokenParent}
                                                            onBlur={this.blurSearchTokenParent}
                                                        />
                                                    </div>
                                                    <span className="error-message">
                                                        {this.state.changeParentNetiIDError["customNetiId"]}
                                                    </span>

                                                    {this.state.searchViewNetiId ?

                                                        <div className="nw-search-view">
                                                            {this.state.searchViewErrorNetiId ?
                                                                <center className="error-message">{this.state.changeParentNetiIDError["Error"] || "No Data Found"}</center> :
                                                                <div>
                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.thirdSearchIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">
                                                                                    Name
                                                                    </span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {this.state.netiInformation && this.state.netiInformation.userBasicInfoDTO && this.state.netiInformation.userBasicInfoDTO.fullName}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.thirdSearchIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">
                                                                                    Mobile No
                                                                    </span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {this.state.netiInformation && this.state.netiInformation.userBasicInfoDTO && this.state.netiInformation.userBasicInfoDTO.basicMobile}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12" style={{ height: "50px" }}>
                                                                        {this.state.thirdSearchIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">
                                                                                    Register Date
                                                                    </span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">

                                                                                    {this.state.netiInformation && this.state.netiInformation.userBasicInfoDTO && this.state.netiInformation.userBasicInfoDTO.registrationDate ? NetiDateUtils.getAnyShortForm(this.state.netiInformation.userBasicInfoDTO.registrationDate, 'DD-MMM-YYYY') : ''}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        : ""
                                                    }
                                                </div>

                                                <div className="p-col-12 p-xl-12">

                                                    <br />

                                                    <br />

                                                    <br />

                                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                        <Button
                                                            label="Change"
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            onClick={this.handleUpdateParent.bind(this)}
                                                        />
                                                    </div>

                                                </div>

                                                {/* {this.state.parentDropdownIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() :
                                                    <div className="p-col-12 p-xl-12">

                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon"> Parent ID </span>
                                                            <Dropdown
                                                                placeholder='Select Parent'
                                                                options={parentPartnerOption}
                                                                value={this.state.parentId}
                                                                onChange={this.onChangeParentId.bind(this)}
                                                                filter={true}
                                                                filterBy="label,value"
                                                                style={{ width: "100%" }}
                                                                showClear={true}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                <span className="error-message">
                                                    {errors["netiId"]}
                                                </span>

                                                <br />

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <Button
                                                            className={"p-button-animation " + this.state.addSearchBtnClassTokenParent}
                                                            label={this.state.searchLabelTokenParent}
                                                            icon="fas fa-search"
                                                            onClick={this.onSearchTokenId}
                                                            onFocus={this.focusSearchTokenParent}
                                                            onBlur={this.blurSearchTokenParent}
                                                        />
                                                    </div>
                                                    <span className="error-message">
                                                        {this.state.customTokenError["customTokenID"]}
                                                    </span>
                                                    <br />

                                                    <div class="nw-search-view">
                                                        <div>
                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon"> Token Type </span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.tokenType}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon"> Attachment</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.attachmentPath}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon"> Create Date</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.tokenCreateDate}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div> */}

                                                <br />
                                                <br />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>

            </div>
        );
    }
}
