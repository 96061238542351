import decode from 'jwt-decode';
import { sessionService } from 'redux-react-session';
import { BaseURIHolder } from '../utils/BaseURIHolder';
import { NetiFileHandler } from '../utils/NetiFileHandler';
export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        this.baseURIHolder = new BaseURIHolder();
        this.netiFileHandler = new NetiFileHandler();
        this.domain = this.baseURIHolder.getHost();
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        this.token = {
            'accessToken': '',
            'refreshToken': ''
        }
    }

    login(username, password) {
        let url = this.domain + '/oauth/token?username=test&password=123&grant_type=test';
        const headers = {
            'Authorization': 'Basic ZGV2Z2xhbi1jbGllbnQ6ZGV2Z2xhbi1zZWNyZXQ=',
            'Content-Type': 'application/x-www-form-urlencoded',
            'NZUSER': username + ':' + password + ':password'
        }
        return fetch(url, {
            method: 'POST',
            headers
        })
            .then(this._checkStatus)
            .then(response => response.json())
            .then(res => {
                const decoded = decode(res.access_token);
                // console.log('Decoded', decoded)

                this.setUserName(username)
                this.setTokenObjectToStore(res.access_token, res.refresh_token) // Setting the token in localStorage
                return Promise.resolve(res);
            })
    }

    async loggedIn() {
        let token = this.getToken() // GEtting token from localstorage
        
        let isExp = this.isTokenExpired(token);
        // console.log('Token', isExp);
        if (isExp) {
            await this.reSetTokenInStore();
            let upToken = await this.getToken();
            let newTime = await this.isTokenExpired(upToken);
            return !!upToken && !newTime;
        } else {
            return !!token && !isExp // handwaiving here
        }
    }
    async reSetTokenInStore() {
        const Token = JSON.parse(localStorage.getItem('Token'));
        const refreshToken = Token.refreshToken;
        let url = this.domain + '/oauth/token?grant_type=refresh_token&refresh_token=' + refreshToken;
        const headers = {
            'Authorization': 'Basic ZGV2Z2xhbi1jbGllbnQ6ZGV2Z2xhbi1zZWNyZXQ=',
            'Content-Type': 'application/x-www-form-urlencoded',
            'NZUSER': 'test:123:refresh_token'
        }
        const res = await fetch(url, {
            method: 'POST',
            headers
        });
        const json = await res.json();
        const status = await this.setTokenObjectToStore(json.access_token, json.refresh_token)

    }
    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else {
                return false;
            }

        }
        catch (err) {
            return false;
        }
    }

    setTokenObjectToStore(accessToken, refreshToken) {
        // Saves user token to localStorage
        let token = {
            'accessToken': accessToken,
            'refreshToken': refreshToken
        }
        localStorage.setItem('Token', JSON.stringify(token));
    }

    getToken() {
        // Retrieves the user token from localStorage
        const Token = JSON.parse(localStorage.getItem('Token'));
        if (Token == null) {
            return null;
        }
        return Token.accessToken;
    }

    setUserName(username) {
        // Saves user name to localStorage
        localStorage.setItem('user_name', username)
    }

    getUserName() {
        // Retrieves the username from localStorage
        // console.log('Token', JSON.parse(localStorage.getItem('Token')));
        return localStorage.getItem('user_name')
    }

    logout() {
        localStorage.removeItem('Token');
        localStorage.clear();
    }

    async setProfile(profile) {
        const res=await this.netiFileHandler.getByteImage(profile.imagePath);
        const body=await res.json();
        const output=await this.netiFileHandler.getResizeByteImage(body.fileContent, profile.imageName);
        let userData = {
            'netiID': profile.netiID,
            'customNetiID': profile.customNetiID,
            'fullName': profile.fullName,
            'basicMobile': profile.basicMobile,
            'imageContent':output
        }
        localStorage.setItem('Profile', JSON.stringify(userData));
    }
    getProfile() {
        const Profile = JSON.parse(localStorage.getItem('Profile'));
        if (Profile == null) {
            return null;
        }
        return Profile;
    }

    getLoggedRoles() {
        let token = this.getToken();
        const decoded = decode(token);
        return decoded.authorities;
    }

    getFetch(url) {
        this.loggedIn();
        const headers = this.headers;
        headers['Authorization'] = 'bearer ' + this.getToken()
        return fetch(url, { method: 'GET', headers });
    }

    postFetch(url, data) {
        this.loggedIn();
        const headers = this.headers;
        headers['Authorization'] = 'bearer ' + this.getToken()
        return fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(data) });
    }

    putFetch(url, data) {
        this.loggedIn();
        const headers = this.headers;
        headers['Authorization'] = 'bearer ' + this.getToken()
        return fetch(url, { method: 'PUT', headers: headers, body: JSON.stringify(data) });
    }

    patchFetch(url, data) {
        this.loggedIn();
        const headers = this.headers;
        headers['Authorization'] = 'bearer ' + this.getToken()
        return fetch(url, { method: 'PATCH', headers: headers, body: JSON.stringify(data) });
    }

    deleteFetch(url) {
        this.loggedIn();
        const headers = this.headers;
        headers['Authorization'] = 'bearer ' + this.getToken()
        return fetch(url, { method: 'DELETE', headers });
    }


    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    handleErrorStatus(res) {
        return new Promise(function (resolve, reject) {
            try {
                if (res.status == 409) {
                    res.json().then((body) => {
                        if (body.message) {
                            resolve('Duplicate Entry.');
                        } else {
                            resolve('Opps! Something wrong');
                        }
                    })
                } else if (res.status == 500) {
                    res.json().then((body) => {
                        if (body.message) {
                            resolve('An Error Occurred.');
                        } else {
                            resolve('Opps! Something wrong.');
                        }
                    }).catch(error => console.log(error));
                } else {
                    res.json().then((body) => {
                        if (body.message) {
                            resolve(body.message);
                        } else {
                            resolve('Opps! Something wrong');
                        }
                    }).catch(error => console.log(error));
                }
            } catch (e) {
                reject(e);
            }

        })
    }
}