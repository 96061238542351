import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';
import { SaCoreSettingsService } from '../../../service/saPoint/SaCoreSettingsService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { Growl } from "primereact/growl";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';


let totalDay, workingDays, offDay, totalPresent, totalAbsent, totalJustTime, totalDelay, totalEarlyLeave, totalLeave;
totalDay = workingDays = offDay = totalPresent = totalAbsent = totalJustTime = totalDelay = totalEarlyLeave = totalLeave = "-";
export class MonthWiseTeacherAttendance extends Component {

    constructor() {
        super();

        this.state = {
            errors: {},
            attendanceDateErrorMessage: null,
            academicYear: null,
            monthName: null,
            academicYears: [],
            academicYearFiltering: false,
            staffAttendanceInfoList: [],
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            monthNameOptions: [
                {
                    value: "January",
                    label: "January"
                },
                {
                    value: "February",
                    label: "February"
                },
                {
                    value: "March",
                    label: "March"
                },
                {
                    value: "April",
                    label: "April"
                },
                {
                    value: "May",
                    label: "May"
                },
                {
                    value: "June",
                    label: "June"
                },
                {
                    value: "July",
                    label: "July"
                },
                {
                    value: "August",
                    label: "August"
                },
                {
                    value: "September",
                    label: "September"
                },
                {
                    value: "October",
                    label: "October"
                },
                {
                    value: "November",
                    label: "November"
                },
                {
                    value: "December",
                    label: "December"
                }
            ],
        };

        this.teacherPortal = new TeacherPortal();
        this.saCoreSettingsService = new SaCoreSettingsService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentDidMount() {
        this.fetchAcademicYearList()
        this.setCurrentAcademicYearToDropDown();

    }

    setCurrentAcademicYearToDropDown = () => {
        let academicYear = NetiDateUtils.getYear(new Date());
        this.setState({ academicYear })
    }

    fetchAcademicYearList() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.saCoreSettingsService.getGlobalInfoByTypeId(2101).then(res => {

            if (res.item) {
                return this.setState({ academicYears: res.item, topProgressBar: false, firstDropdownIsLoading: false })
            } else {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No data fund' })
            }

        }).catch(error => {
            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
        });
    }

    onSubmitHandler = (event, props) => {
        totalDay = workingDays = offDay = totalPresent = totalAbsent = totalJustTime = totalDelay = totalEarlyLeave = totalLeave = "-";
        if (this.onSubmitErrorHandler()) {
            this.setState({ attendanceDateErrorMessage: false, dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false })
            let staffId = this.props.teacherBasicInfo && this.props.teacherBasicInfo.item && this.props.teacherBasicInfo.item.staffId;
            this.teacherPortal.getMonthWiseStaffAttendanceInfo(this.props.hrInstitute, this.state.monthName, staffId, this.state.academicYear)
                .then(data => {

                    if (data) {
                        this.setState({ staffAttendanceInfoList: data, errors: {}, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                    } else {
                        this.setState({
                            dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Something went wrong.'
                        })

                    }

                })
                .catch(
                    error => this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
                );
        }
        else {
            this.setState({ attendanceDateErrorMessage: true })
        }
    }

    onSubmitErrorHandler = () => {
        let formIsValid = true;
        let { errors } = this.state;

        if (!this.state.academicYear) {
            formIsValid = false;
            errors['academicYear'] = "Academic Year Can't left empty."
        }
        if (!this.state.monthName) {
            formIsValid = false;
            errors['month'] = "Month Can't left empty."
        }

        this.setState({ errors })
        return formIsValid;
    }

    onAcademicYearFilter = () => {
        if (this.state.academicYearFiltering) this.setCurrentAcademicYearToDropDown();
        this.setState({ academicYearFiltering: !this.state.academicYearFiltering });
    }

    getInTimeByStatus = (rowData) => {
        if (rowData.inStatus === "Absent") {
            rowData.inTime = "-";
        }
        return rowData.inTime;
    }

    getOutTimeByStatus = (rowData) => {
        if (rowData.outStatus === "Absent") {
            rowData.outTime = "-";
        }
        return rowData.outTime;
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let academicYearList = []
        academicYearList = this.state.academicYears.map((item) => ({
            value: item.name,
            label: item.name,
        }));

        let attendanceInfoList = [];


        if (this.state.staffAttendanceInfoList.item) {

            attendanceInfoList = this.state.staffAttendanceInfoList.item.details;
            workingDays = this.state.staffAttendanceInfoList.item.workingDay;
            totalDay = this.state.staffAttendanceInfoList.item.totalDay;
            offDay = totalDay - workingDays;
            totalPresent = this.state.staffAttendanceInfoList.item.present;
            totalAbsent = this.state.staffAttendanceInfoList.item.absent;
            totalJustTime = this.state.staffAttendanceInfoList.item.justTime;
            totalDelay = this.state.staffAttendanceInfoList.item.delay;
            totalEarlyLeave = this.state.staffAttendanceInfoList.item.early;
            totalLeave = this.state.staffAttendanceInfoList.item.leave;
        }

        let headervalue = (
            <div className="p-clearfix">
                Attendance Information{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {attendanceInfoList.length}{" "}
                </span>
            </div>
        );

        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="">
                    <div className="p-col-12 p-xl-12">
                        {!this.state.inputErrorMessage ? null :
                            <div className="p-messages p-component p-messages-warn" style={{ margin: '0 0 1em 0', display: 'block' }}>
                                <div className="p-messages-wrapper">
                                    <ul>
                                        <li>
                                            <span className="p-messages-icon pi pi-fw pi-2x pi-exclamation-triangle"></span>
                                            <span className="p-messages-detail">
                                                Please Select Date
                                        </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="">
                    <div className="">
                        <div className="nw-form-body">
                            <div className="p-col-12">
                                <div className="p-md-2 p-md-offset-10 p-col-4 p-offset-8">
                                    <Button
                                        label="Academic Year"
                                        icon="fas fa-filter"
                                        iconPos="right"
                                        onClick={this.onAcademicYearFilter} />
                                </div>
                            </div>
                            <div className="p-col-12">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">{requiredSing}Academic Year</span>
                                        {this.state.academicYearFiltering ?
                                            <Dropdown
                                                options={academicYearList}
                                                placeholder="Select Academic Year"
                                                value={this.state.academicYear}
                                                onChange={event => this.setState({ academicYear: event.value })}
                                                autoWidth={false}
                                            />
                                            :
                                            <Dropdown
                                                disabled
                                                options={academicYearList}
                                                placeholder="Select Academic Year"
                                                value={this.state.academicYear}
                                                onChange={event => this.setState({ academicYear: event.value })}
                                                autoWidth={false}
                                            />}
                                    </div>
                                }
                                <span className="error-message"> {this.state.academicYear ? '' : this.state.errors['academicYear']} </span>
                            </div>
                            <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">{requiredSing}Month</span>
                                    <Dropdown options={this.state.monthNameOptions} placeholder="Select Month" value={this.state.monthName} onChange={event => this.setState({ monthName: event.value })} autoWidth={false} />
                                </div>
                                <span className="error-message"> {this.state.monthName ? '' : this.state.errors['month']} </span>
                            </div>
                            <div className="p-md-12 nw-button-parent">
                                <div className="required-field">
                                    (<span>*</span>) required fields
                                </div>

                                <Button
                                    label="Search"
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    icon="fas fa-search"
                                    iconPos="right"
                                    onClick={this.onSubmitHandler.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="">
                    <div className="p-col-12">
                        <div className="nw-circle-panel full-width-circle-pannel">

                            <div className="circle-panel circle-panel-first">
                                <span>{workingDays}</span>
                                <div className="circle-panel-label">Working Days</div>
                            </div>


                            <div className="circle-panel circle-panel-second">
                                <span>{offDay}</span>
                                <div className="circle-panel-label">Off Days</div>
                            </div>

                            <div className="circle-panel circle-panel-third">
                                <span>{totalPresent}</span>
                                <div className="circle-panel-label">Total Present</div>
                            </div>

                            <div className="circle-panel circle-panel-fourth">
                                <span>{totalAbsent}</span>
                                <div className="circle-panel-label">Total Absent</div>
                            </div>

                            <div className="circle-panel circle-panel-first">
                                <span>{totalJustTime}</span>
                                <div className="circle-panel-label">Just Time</div>
                            </div>


                            <div className="circle-panel circle-panel-second">
                                <span>{totalDelay}</span>
                                <div className="circle-panel-label">Total Delay</div>
                            </div>

                            <div className="circle-panel circle-panel-third">
                                <span>{totalEarlyLeave}</span>
                                <div className="circle-panel-label">Early Leave</div>
                            </div>

                            <div className="circle-panel circle-panel-fourth">
                                <span>{totalLeave}</span>
                                <div className="circle-panel-label">Total Leave</div>
                            </div>

                        </div>

                        {/* <div className="p-grid">
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                Working Days:
                            </div>
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                {workingDays}
                            </div>
                        </div>


                        <div className="p-grid">
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                Off Days:
                            </div>
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                {offDay}
                            </div>
                        </div> */}

                    </div>

                    {/* <div className="p-col-12 p-xl-3">

                        <div className="p-grid">
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                Total Present:
                            </div>
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                {totalPresent}
                            </div>
                        </div>

                        <div className="p-grid">
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                Total Absent:
                            </div>
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                {totalAbsent}
                            </div>
                        </div>

                    </div> */}

                    {/* <div className="p-col-12 p-xl-3">

                        <div className="p-grid">
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                Just Time:
                            </div>
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                {totalJustTime}
                            </div>
                        </div>

                        <div className="p-grid">
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                Total Delay:
                            </div>
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                {totalDelay}
                            </div>
                        </div>

                    </div> */}

                    {/* <div className="p-col-12 p-xl-3">

                        <div className="p-grid">
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                Total Early Leave:
                            </div>
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                {totalEarlyLeave}
                            </div>
                        </div>

                        <div className="p-grid">
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                Total Leave:
                            </div>
                            <div className="p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                {totalLeave}
                            </div>
                        </div>

                    </div> */}

                </div>


                <div className="p-col-12 p-xl-12">
                    <div className="nw-data-table">
                        {this.state.dataTableIsLoading ?
                            this.NetiContentLoader.MyPointTableLoader()
                            :
                            <DataTable
                                value={attendanceInfoList}
                                header={headervalue}
                                responsive={true}
                                paginator={true}
                                rows={10}
                                style={{ textAlign: 'center' }}
                            >
                                <Column field="attendanceDate" header="Date" filter={true} />
                                <Column field="dayName" header="Day" filter={true} />
                                <Column field="inTime" body={this.getInTimeByStatus} header="In-Time" filter={true} />
                                <Column field="inStatus" header="In-Time Status" filter={true} />
                                <Column field="outTime" body={this.getOutTimeByStatus} header="Out-Time" filter={true} />
                                <Column field="outStatus" header="Out-Time Status" filter={true} />
                            </DataTable>
                        }
                    </div>
                </div>
            </div>
        );
    }
}