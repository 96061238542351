import React, { Component } from 'react';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';

export class TeacherPersonalInfo extends Component {

    constructor(props) {
        super(props);
        this.teacherPortal = new TeacherPortal();
    }

    render() {

        let fathersName, mothersName, maritalStatus, spouseName, nationality, nationalId, passportNo, tinNo
        if (this.props.basicInfo.item) {

            fathersName = this.props.basicInfo.item.fatherName
            mothersName = this.props.basicInfo.item.matherName
            maritalStatus = this.props.basicInfo.item.maritalStatus
            spouseName = this.props.basicInfo.item.staffOthersInfo ? this.props.basicInfo.item.staffOthersInfo.spouseName : ''
            nationality = this.props.basicInfo.item.staffOthersInfo ? this.props.basicInfo.item.staffOthersInfo.nationality : ''
            nationalId = this.props.basicInfo.item.staffOthersInfo ? this.props.basicInfo.item.staffOthersInfo.nationalId : ''
            passportNo = this.props.basicInfo.item.staffOthersInfo ? this.props.basicInfo.item.staffOthersInfo.passportNo : ''
            tinNo = this.props.basicInfo.item.staffOthersInfo ? this.props.basicInfo.item.staffOthersInfo.tinNo : ''
        }

        return (
            <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                <div className="protal-single-info-box">
                    <div className="protal-single-info-title">
                        <h4>Personal Info</h4>
                    </div>
                    <div className="protal-single-info-content-box">
                        <div className="protal-single-info-content">
                            <h4>Father's Name</h4>
                            <p>{fathersName || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Mother's Name</h4>
                            <p>{mothersName || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Marital Status</h4>
                            <p>{maritalStatus || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Spouse Name</h4>
                            <p>{spouseName || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Nationality</h4>
                            <p>{nationality || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>NID</h4>
                            <p>{nationalId || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Passport No.</h4>
                            <p>{passportNo || '---'}</p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Tin No.</h4>
                            <p>{tinNo || '---'}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}