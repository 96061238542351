import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Growl } from "primereact/growl";
import { Rating } from 'primereact/rating';
import { TokenService } from '../../../service/myPoint/TokenService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { DownloadButton } from '../../../utils/DownloadButton';
import { InputTextarea } from 'primereact/inputtextarea';
import CommonFuctionality from '../../common/CommonFuctionality';


export class MyPointTokenTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rejectedStutas: "Custom Status",
            dataTableSelection: null,
            actionDetailsDialog: null,
            dialogDate: null,
            dialogProblemModule: null,
            dialogProblemType: null,
            dialogProblemDetails: null,
            dialogStatus: null,
            dialogAction: null,
            dataTableValue: [],
            tokenList:{},
            ratingObj:{
                ratingValue: '',
                commentValue:'',  
            },
            errors:{}
        };

        this.statusDetail = this.statusDetail.bind(this);
        this.actionDetails = this.actionDetails.bind(this);
        this.viewDialog = this.viewDialog.bind(this)
        this.onHide = this.onHide.bind(this)
        this.tokenService = new TokenService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();

    }

    componentDidMount() {
        this.showUserTopTenTokenList();
    }


    showUserTopTenTokenList = () => {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.tokenService.getUserTopTenTokens()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for(let i=0; i<body.length; i++){
                            if(body[i].tokenTypeInfoDTO.parentCoreCategoryInfoDTO==null){
                                body[i].tokenTypeInfoDTO.parentCoreCategoryInfoDTO={categoryName:''}
                                
                            }
                            body[i].createDate = NetiDateUtils.getAnyShortForm(body[i].createDate, 'DD-MMM-YYYY hh:mm:ss a')
                        }
                        this.setState({ dataTableValue: body });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.tokenService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                    });
                }
            }).catch(error => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }


    actionDetails(rowData, column) {
        
        return <center>
                    <Button
                        className="nw-action-button"
                        icon="fas fa-info"
                        title="View Purchase"
                        onClick={(e)=>this.viewDialog(rowData)}
                    />
                </center>;
    }

    viewDialog(rowData){

        // this.setState({ dialogDate: rowData.createDate })
        // this.setState({ dialogProblemModule: rowData.tokenTypeInfoDTO.categoryName })
        // this.setState({ dialogProblemType: rowData.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName })
        // this.setState({ dialogProblemDetails: rowData.tokenDetails })
        // this.setState({ dialogProblemAttachments: rowData.attachmentName })
        // this.setState({ dialogContactNo: rowData.tokenContact })
        // this.setState({ dialogStatus: rowData.tokenStatus })

        let { tokenList } = this.state;

        this.setState({ 
            visible: true,
            tokenList: rowData
        })

        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onHide() {
        this.setState({ visible: false })
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    // balanceDateFormat = (rowData, Column) => {
    //     return <span>{NetiDateUtils.getAnyShortForm(rowData['requestDate'], 'DD-MMM-YYYY')}</span>;

    // }

    createDateBody = (rowData) =>{
        return <span>
            {NetiDateUtils.getAnyShortForm(rowData['createDate'], 'DD-MMM-YYYY hh:mm:ss a')}
        </span>
    }

    statusDetail(rowData) {
        console.log('rowData', rowData);
        
        if (rowData.tokenStatus === 10) {
            return <span className="text-success">Solved</span>;
        }
        else if (rowData.tokenStatus === 2) {
            return <span className="text-danger">Rejected</span>;

        } 
        else if (rowData.tokenStatus === 0) {
            return <span className="text-warn">Pending</span>;
        }

        else if (rowData.tokenStatus === 1) {
            return <span className="text-info">Processing</span>;
        }
    }

    getRemainTime = (start, end) =>{
        let countValue 
        var countDownDate = new Date( start ).getTime()
        var now
        if(end != null){ 
            now = new Date( end ).getTime()
        }else{
            now = new Date().getTime();
        }
            
                
            var distance = now - countDownDate;
                
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            countValue =days + "d " + hours + "h "+ minutes + "m " + seconds + "s "

        return countValue
    }

    onChangeRatingValue = e =>{
        let { ratingObj, errors } = this.state
        errors["ratingMark"] = "";
        ratingObj.ratingValue = e.value
        this.setState({ ratingObj, ratingValue: e.value })
    }

    onChangeCommentValue = e =>{
        let { ratingObj, errors } = this.state
        errors["ratingComment"] = "";
        ratingObj.commentValue = e.target.value
        this.setState({ ratingObj })
    }

    ratingHandleError = () => {
        let { tokenRatingObj, ratingObj, errors } = this.state
        let formIsValid = true;

        if (ratingObj.ratingValue == '') {
            formIsValid = false;
            errors["ratingMark"] = "Rating Mark can't left empty.";
        }

        if (ratingObj.commentValue == '') {
            formIsValid = false;
            errors["ratingComment"] = "Rating Comment can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitRating = () =>{
        let { tokenList, ratingObj } = this.state

        tokenList.ratingMark = ratingObj.ratingValue
        tokenList.ratingMessage = ratingObj.commentValue

        console.log("tokenList::::", tokenList)
        if (this.ratingHandleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.tokenService.saveUserRating(tokenList)
              .then(res => {
                if (res.status == 202) {
                  this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                  this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted" });
                //   this.showUserTopTenMessageRechargeList();
                setTimeout(() => {
                    this.onHide();
                    this.showUserTopTenTokenList()
                }, 800);
                } else {
                  this.MessageRechargeService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                      this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                    });
                }
            }).catch(error => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
        }
    }

    render() {
        let { ratingObj, tokenList, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody} = this.state;

        let depositLinkText= "deposit-link-text";

        let progressMainClass1 = "info-box-wrapper progress-view step-1 "
        let progressMainClass2 = "info-box-wrapper progress-view step-2 "
        let progressMainClass3 = "info-box-wrapper progress-view step-3 "
        let progressMainClass4 = "info-box-wrapper progress-view step-4 "
        let progressMainClass5 = "info-box-wrapper progress-view step-5 ";

        if(tokenList && tokenList.tokenStatus == 0){
            progressMainClass1 = progressMainClass1+"active"
            progressMainClass2 = progressMainClass2+"active"
            // this.getRemainTime(tokenList && tokenList.createDate)
        }
        else if(tokenList && tokenList.tokenStatus == 1){
            progressMainClass1 = progressMainClass1+"active"
            progressMainClass2 = progressMainClass2+"active"
            progressMainClass3 = progressMainClass3+"active"
        }

        else if(tokenList && tokenList.tokenStatus == 10 && tokenList.ratingMark == 0){
            progressMainClass1 = progressMainClass1+"active"
            progressMainClass2 = progressMainClass2+"active"
            progressMainClass3 = progressMainClass3+"active"
            progressMainClass4 = progressMainClass4+"active"
        }

        else if(tokenList && tokenList.tokenStatus == 10 && tokenList.ratingMark > 0){
            progressMainClass1 = progressMainClass1+"active"
            progressMainClass2 = progressMainClass2+"active"
            progressMainClass3 = progressMainClass3+"active"
            progressMainClass4 = progressMainClass4+"active"
            progressMainClass5 = progressMainClass5+"active"
        }
        

        // let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
        //     Token List <span style={{ color: "white", display: "flex", float: "right" }}>
        //      Total Found: {this.state.dataTableValue.length}
        //     </span>
        // </div>;

        let header = <div className="header-title">
                            <div className="header-title-left">
                                Token List  
                            </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {this.state.dataTableValue.length}
                                </a>
                            </div>
                        </div>;
        
        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={el => (this.growl = el)} />
                <div className="main-section blur-section">
                    <div className="nw-data-table">
                        {this.state.dataTableIsLoading ?
                            this.NetiContentLoader.MyPointTableLoader() :
                            <DataTable
                                value={dataTableValue}
                                selectionMode="single"
                                activeIndex="0"
                                header={header}
                                // selection={this.state.dataTableSelection}
                                // onSelectionChange={event => this.setState({dataTableSelection: event.value})}
                                responsive={true}
                                paginator={true}
                                rows={10}
                            >
                                <Column sortable={true} field="createDate" header="Date" className="table-datetime" filter={true} />
                                <Column sortable={true} field="customTokenID" header="Token ID" filter={true} />
                                <Column sortable={true} field="tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName" header="Problem Module" filter={true} />
                                <Column sortable={true} field="tokenTypeInfoDTO.categoryName" header="Problem Type" filter={true} />
                                <Column sortable={true} field="tokenStatus" header="Status" body={this.statusDetail} filter={true} />
                                <Column field="action" header="Action" body={this.actionDetails} style={{ width: "95px" }} filter={true} />
                            </DataTable>
                        }


                    </div>

                </div>

                <div className="dialog-section">
                    <Dialog
                        header="Token Information"
                        className="nonHideDialog customDialogWidth text-center"
                        onHide={this.onHide}
                        visible=''
                        dismissableMask={true}
                    >

                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="card card-w-title">
                                        {/* <h1>Token Form</h1> */}
                                        <div className="p-grid">

                                            <div className="p-col-12 p-md-4">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup"><b>Date</b></span>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-8">
                                                <div className="p-inputgroup">
                                                    {this.state.dialogDate}
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup"><b>Problem Module</b></span>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-8">
                                                <div className="p-inputgroup">
                                                    {this.state.dialogProblemModule}
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup"><b>Problem Type</b></span>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-8">
                                                <div className="p-inputgroup">
                                                    {this.state.dialogProblemType}
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup"><b>Problem Details</b></span>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-8">
                                                <div className="p-inputgroup">
                                                    {this.state.dialogProblemDetails}
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup"><b>Attachments</b></span>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-8">
                                                <div className="p-inputgroup">
                                                    {this.state.dialogProblemAttachments}
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup"><b>Contact No</b></span>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-8">
                                                <div className="p-inputgroup">
                                                    {this.state.dialogContactNo}
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-md-4">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup"><b>Status</b></span>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-8">
                                                <div className="p-inputgroup">
                                                    <b className="p-message p-component p-message-warn">
                                                        {this.state.dialogStatus}
                                                    </b>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </Dialog>


                    <Dialog
                        header="Token Information"
                        className="nw-dialog"
                        onHide={this.onHide}
                        visible={this.state.visible}
                        dismissableMask={true}
                    >

                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-form-body">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="tracking-progress-view">
                                                    <div className={progressMainClass1}>
                                                        <div className="info-icon icon-bg-1">
                                                            <i class="fas fa-sign-in-alt"></i>
                                                        </div>
                                                        <div className="info-text">
                                                            <p>Submit</p>
                                                        </div>
                                                        <div className="info-button info-btn-1">
                                                            <i class="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>

                                                    <div className="next"><div></div></div>

                                                    <div className={progressMainClass2}>
                                                        <div className="info-icon icon-bg-1">
                                                            <i class="fas fa-spinner"></i>
                                                        </div>
                                                        <div className="info-text">
                                                            <p>Pending</p>
                                                        </div>
                                                        <div className="info-button info-btn-1">
                                                            <i class="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>

                                                    <div className="next"><div></div></div>

                                                    <div className={progressMainClass3}>
                                                        <div className="info-icon icon-bg-1">
                                                            <i class="fas fa-cogs"></i>
                                                        </div>
                                                        <div className="info-text">
                                                            <p>Process</p>
                                                        </div>
                                                        <div className="info-button info-btn-1">
                                                            <i class="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>

                                                    <div className="next"><div></div></div>

                                                    <div className={progressMainClass4}>
                                                        <div className="info-icon icon-bg-1">
                                                            <i class="fas fa-clipboard-check"></i>
                                                        </div>
                                                        <div className="info-text">
                                                            <p>Solved</p>
                                                        </div>
                                                        <div className="info-button info-btn-1">
                                                            <i class="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>

                                                    <div className="next"><div></div></div>

                                                    <div className={progressMainClass5}>
                                                        <div className="info-icon icon-bg-1">
                                                            <i class="fas fa-star"></i>
                                                        </div>
                                                        <div className="info-text">
                                                            <p>Rating</p>
                                                        </div>
                                                        <div className="info-button info-btn-1">
                                                            <i class="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-xl-12"></div>
                                            <div className="p-col-12 p-xl-12"></div>
                                            <div className="p-col-12 p-xl-12"></div>

                                            {tokenList && tokenList.tokenStatus == 10 && !tokenList.ratingMark ?

                                                <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Give Rating  <span>*</span></span>
                                                            <div className="nw-inputgroup-desc">
                                                                <Rating
                                                                    value={this.state.ratingValue}
                                                                    cancel={false}
                                                                    onChange={this.onChangeRatingValue}
                                                                />
                                                            </div>

                                                        </div>
                                                        {/* <span className="error-message">{this.state.errors["ratingMark"]}</span> */}
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Comments <span>*</span></span>
                                                            <InputTextarea
                                                                id="textarea"
                                                                rows={3}
                                                                cols={30}
                                                                autoResize={true}
                                                                name="tokenDetails"
                                                                value={ratingObj && ratingObj.commentValue}
                                                                onChange={this.onChangeCommentValue}
                                                            />
                                                        </div>
                                                        {/* <span className="error-message">{this.state.errors["ratingComment"]}</span> */}
                                                    </div>

                                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                                        <div className="required-field">
                                                            (<span>*</span>) required fields
                                                                </div>

                                                        <Button
                                                            className="p-button-primary nw-button nw-button-right token-submit-button" //p-button-primary 
                                                            label="Submit"
                                                            icon="fas fa-check" //fas fa-spinner fa-spin
                                                            onClick={this.onSubmitRating}
                                                            disabled={tokenList && tokenList.tokenStatus == 10 ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                                : null
                                            }


                                            <div className="p-col-12 p-xl-12">

                                                <div className="nw-search-view">
                                                    <div>
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Token ID</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {tokenList && tokenList.customTokenID}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Issue Date</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {tokenList && NetiDateUtils.getAnyShortForm(tokenList.createDate, 'DD-MMM-YYYY hh:mm:ss a')}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Contact No.</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {tokenList && tokenList.tokenContact}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Application</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {tokenList && tokenList.tokenSource}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Module</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {tokenList.tokenTypeInfoDTO && tokenList.tokenTypeInfoDTO.parentCoreCategoryInfoDTO && tokenList.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Problem Type</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {tokenList.tokenTypeInfoDTO && tokenList.tokenTypeInfoDTO.categoryName}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Problem Details</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {tokenList && tokenList.tokenDetails}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Attachment</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc attachment-download-button" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <DownloadButton fileName={tokenList && tokenList.attachmentName} filePath={tokenList && tokenList.attachmentPath} /> <span className="download-button-text">Attachment</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {tokenList && tokenList.tokenStatus <= 1 ?

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup details">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Pending Duration</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc demo" id='demo'>
                                                                        {this.getRemainTime(tokenList && tokenList.createDate, null)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }


                                                        {(tokenList && tokenList.tokenStatus == 10) || (tokenList && tokenList.tokenStatus == 2) ?
                                                            <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup details">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">
                                                                            {tokenList && tokenList.tokenStatus == 10 ? "Solved Date": "Rejected Date"}
                                                                        </span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc demo" id='demo'>
                                                                            {tokenList && NetiDateUtils.getAnyShortForm(tokenList.solveDate, 'DD-MMM-YYYY hh:mm:ss a')}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup details">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">
                                                                            {tokenList && tokenList.tokenStatus == 10 ? "Solved Duration": "Rejected Duration"}
                                                                        </span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc demo" id='demo'>
                                                                            {this.getRemainTime(tokenList && tokenList.createDate, tokenList && tokenList.solveDate)}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">
                                                                            {tokenList && tokenList.tokenStatus == 10 ? "Solved Note": "Rejected Note"}
                                                                        </span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc demo" id='demo'>
                                                                            {tokenList && tokenList.solveMessage}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }



                                                    </div>
                                                </div>
                                            </div>

                                            {/* { tokenList && tokenList.tokenStatus == 10?

                                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                                            <div className="required-field">
                                                                (<span>*</span>) required fields
                                                            </div>

                                                            <Button
                                                                className="p-button-primary nw-button nw-button-right token-submit-button" //p-button-primary 
                                                                label="Submit"
                                                                icon="fas fa-check" //fas fa-spinner fa-spin
                                                                onClick={this.onSubmitRating}
                                                                disabled={ tokenList && tokenList.tokenStatus == 10? false:true }
                                                            />
                                                        </div>
                                                        :null
                                                    } */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                    </div>
                </div>
        );
    }
}