import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import { UserCategoryService } from "../../../service/UserCategoryService";
import NetiContentLoader from '../../common/NetiContentLoader';
import { Link } from 'react-router-dom';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';


let urlId;
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";

export class AdmisiaCollectionAnalytics extends Component {

    constructor() {
        super();
        this.state = {
            errors: {},
            academicYearList: [],
            academicYear: '',
            dataTableIsLoading: false,
            errorMsgVisible: false,
            firstDropdownIsLoading: false,
            topProgressBar: false,
            errorMsgBody: '',
            academicYearErr:{},
            dataTableValue: []
        }
        this.submitSearch = this.submitSearch.bind(this);
        this.onChangeAcYear = this.onChangeAcYear.bind(this);
        this.export = this.export.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.admisiaPointService = new AdmisiaPointService();
        this.userCategoryService = new UserCategoryService();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount = () => {
        urlId=this.admisiaPointService.getUrlInfoFromLocalStorage();
       
        if (urlId) { 
            this.setState({ homeReturnButton: false })
            this.getAcademicYearListByTypeID();
        }
        else {
            this.setState({ homeReturnButton: true })
           
        }
    }

    getAcademicYearListByTypeID = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.userCategoryService.fetchListByDefaultCode("T107")
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("NN", body);
                        
                        this.setState({ academicYearList: body })
                        this.setState({ topProgressBar: false, errorMsgVisible: false, firstDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, firstDropdownIsLoading: false, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: false, firstDropdownIsLoading: false });
            });


    }

    submitSearch() {
        if (this.searchAcademicYearError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.admisiaPointService.fetchApplicantCollectionAnalytics(urlId, this.state.academicYear)
            .then(res => {
                
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ dataTableValue: body })
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    })
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection." })
            });

        }
       
    }

    searchAcademicYearError = () => {
        let { academicYearErr } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (this.state.academicYear === '') {
            formIsValid = false;
            academicYearErr["academicYear"] = 'Academic Year can not left empty.';
        }

        this.setState({ academicYearErr });
        return formIsValid;
    }

    export() { 

        this.dt.exportCSV();

    }

    exportPdf = () =>{

        let tableColumns = [
            { title: "Class", dataKey: "class" },
            { title: "Group", dataKey: "group" },
            { title: "Total Applicant", dataKey: "totalApplicant" },
            { title: "Collection Amount", dataKey: "collectionAmount" },
            { title: "Service Charge", dataKey: "serviceCharge" },
            { title: "MFS Charge", dataKey: "mfsCharge" },
            { title: "Receiveable Amount", dataKey: "receiveableAmount" }, 
          ];
  
          pdfDownloadPortrait_TableWithSubTitle("Collection Analytics Report",pdfTitleHeader, pdfTitleHeaderData, tableColumns, this.state.dataTableValue, "collection_analytics_Report.pdf");
        
    }

    onChangeAcYear(value) {
        let { academicYearErr } = this.state;
        academicYearErr["academicYear"] = '';
        this.setState({ academicYear: value, academicYearErr });

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Collection Analytics List
                        <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found:
                        </span>
        </div>;

        let academicYearOptions = [];
        if (this.state.academicYearList != null && this.state.academicYearList.length) {
            academicYearOptions = this.state.academicYearList.map((item) => ({
                value: item.categoryName,
                label: item.categoryName,
            }));
        }

        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" }
        ];
        pdfTitleHeaderData = [
        { A: "Academic year", B: this.state.academicYear}
        ];

        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                  
                        {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }


                        {this.state.homeReturnButton === false ?


                        <div>
                            <div className="p-col-12 p-xl-12">
                            <Growl ref={(el) => this.growl = el} />

                                <div className="p-grid nw-form-body">
                                    <div className="p-col-12 p-xl-9">
                                        {this.state.firstDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Academic year</span>
                                                <Dropdown
                                                    placeholder="Select Academic year"
                                                    value={this.state.academicYear}
                                                    options={academicYearOptions}
                                                    onChange={(e) => this.onChangeAcYear(e.target.value)}
                                                    filter={true}
                                                    filterBy="value"
                                                    showClear={false}
                                                    autoWidth={false}
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{this.state.academicYearErr["academicYear"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-3 nw-button-parent">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-center"
                                            label="Search"
                                            icon="fas fa-search"
                                            onClick={this.submitSearch}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table">
                                    {this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() : 
                                        <div>
                                            <DataTable
                                            value={this.state.dataTableValue}
                                            selectionMode="single"
                                            activeIndex="0"
                                            header={tableHeader}
                                            selection={this.state.dataTableSelection}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            ref={(el) => { this.dt = el; }}
                                            >
                                                <Column field="class" header="Class" filter={true} />
                                                <Column field="group" header="Group" filter={true} />
                                                <Column field="totalApplicant" header="Total Applicant" filter={true} />
                                                <Column field="collectionAmount" header="Collection Amount" filter={true} />
                                                <Column field="serviceCharge" header="Service Charge" filter={true} />
                                                <Column field="mfsCharge" header="MFS Charge" filter={true} />
                                                <Column field="receiveableAmount" header="Receiveable Amount" filter={true} />
                                            </DataTable>
                                            <div className="nw-button-parent-multiple m-t-8 p-r-0">
                                                <Button
                                                    label="Download CSV"
                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                    icon="fas fa-arrow-down"
                                                    onClick={this.export}
                                                />
                                                <Button
                                                    label="Download PDF"
                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                    icon="fas fa-arrow-down"
                                                    onClick={this.exportPdf}
                                                />
                                            </div>
                                        </div>
                                    }

                                </div>

                            </div>

                        </div>

                        : 
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/institute_config"
                                            className="rainbow-button"
                                            alt="Go Admisia Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>


                        }

                  
                    </div>

                <br />
                <br />

            </div>
        );
    }
}