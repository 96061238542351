import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { CoreSettings } from "../../../service/CoreSettings";
import { Panel } from 'primereact/panel';
import { StudentClassConfigInfo } from '../common/StudentClassConfigInfo';
import NetiContentLoader from "../../common/NetiContentLoader";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from "primereact/growl";

let errors = {};
export class ExamRoutine extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sessionList: [],
            sessionId: '',
            examList: '',
            examId: '',
            examRoutine: '',
            dataTableValue: '',
            errors: {},

            errorMsgBody: '',
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false
        }

        this.onChangeSession = this.onChangeSession.bind(this);
        this.onChangeExam = this.onChangeExam.bind(this);

        this.studentPortal = new StudentPortal();
        this.coreSettings = new CoreSettings();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.fetchSessionList();
        this.fetchStudentExamList();


    }

    fetchSessionList() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        let requestobject = this.props.basicInfo;
        this.studentPortal.fetchExamSessionList(requestobject).then(res => {
            if (res.status == 200) {
                return res.json().then((body) => {
                    this.setState({ sessionList: body, topProgressBar: false, firstDropdownIsLoading: false })
                });
            } else {
                this.studentPortal.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody.message })
                    });

            }
        }).catch(error => {
            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        });
    }

    fetchStudentExamList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false });
        let requestobject = this.props.basicInfo;
        this.studentPortal.fetchStudentExam(requestobject).then(res => {
            if (res.status == 200) {
                return res.json().then((body) => {
                    this.setState({
                        examList: body.item, topProgressBar: false, secondDropdownIsLoading: false
                    });
                });
            } else {
                this.studentPortal.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody.message });
                    });
            }
        }).catch(error => this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
        );
    }

    fetchExamRoutine(requestedObjct) {
        this.studentPortal.fetchStudentExamRoutine(requestedObjct).then(res => {
            if (res.status === 200) {
                return res.json().then((body) => {

                    if (body.msgType === 1) {
                        this.setState({ examRoutine: body.item })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    } else if (body.msgType === 0) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: body.message });
                    }
                });

            } else {
                return res.json().then((errorBody) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: errorBody.message })
                });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        })

    }

    onChangeSession = (e) => {
        errors["session"] = "";
        this.setState({ sessionId: e.value, errors: errors })
    }
    onChangeExam(e) {
        errors["exam"] = "";
        this.setState({ examId: e.value, errors: errors })
    }

    submitSearch() {

        if (this.handleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            let basicInfo = this.props.basicInfo;
            let requestedObjct = {
                'instituteId': basicInfo.instituteId,
                'studentId': basicInfo.studentId,
                'academicYear': basicInfo.academicYear,
                'sessionId': this.state.sessionId,
                'examId': this.state.examId,
            }

            this.fetchExamRoutine(requestedObjct);
        }
    }

    handleError = () => {

        errors = {};
        let formIsValid = true;

        if (this.state.sessionId === '') {
            formIsValid = false;
            errors["session"] = "Session can't left empty.";
        }
        if (this.state.examId === '') {
            formIsValid = false;
            errors["exam"] = "Exam can't left empty.";
        }
        else { return formIsValid };
        this.setState({ errors: errors });
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let sessionOptions = [];
        if (this.state.sessionList && this.state.sessionList.length) {
            sessionOptions = this.state.sessionList.map(item => ({
                value: item.sessionId,
                label: item.sessionName,
            }));
        }

        let examOptions = [];
        if (this.state.examList && this.state.examList.length) {
            examOptions = this.state.examList.map(item => ({
                value: item.examObject.id,
                label: item.examObject.name,
            }));
        }

        return (

            <div className="p-col-12 p-col-nogutter">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                {/* <Panel> */}
                <div className="p-grid nw-form">

                    <div className="p-col-12">
                        <StudentClassConfigInfo basicInfo={this.props.basicInfo} />
                    </div>

                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <p className='profile-custom-table-header'>Exam Routine</p>
                        <br />
                    </div>

                    <div className="p-col-12">
                        <div className="nw-form-body">
                            <div className="p-col-12">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Session <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Session'
                                            name="session"
                                            options={sessionOptions}
                                            value={this.state.sessionId}
                                            onChange={this.onChangeSession}
                                            filter={true}
                                            filterBy="label,value"
                                            autoWidth={false}
                                        />

                                    </div>
                                }
                                <span className='error-message'>{this.state.errors["session"]}</span>
                            </div>

                            <div className="p-col-12">
                                {this.state.secondDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Exam <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Exam'
                                            name="exam"
                                            options={examOptions}
                                            value={this.state.examId}
                                            onChange={this.onChangeExam}
                                            filter={true}
                                            filterBy="label,value"
                                            autoWidth={false}
                                        />

                                    </div>
                                }
                                <span className='error-message'>{this.state.errors["exam"]}</span>
                            </div>

                            <div className="p-col-12 nw-button-parent">
                                <label><span>&nbsp;</span></label>
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={event => this.submitSearch(event)}
                                />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="p-col-12 p-lg-12 p-xl-12"><br /><br />
                    {this.state.dataTableIsLoading ?
                        this.NetiContentLoader.MyPointTableLoader() :
                        this.state.examRoutine ?
                            <DataTable
                                value={this.state.examRoutine}
                                responsive={true}
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="examDate" header="Date" sortable={true} filter={true} />
                                <Column field="examDay" header="Day" sortable={true} filter={true} />
                                <Column field="examTime" header="Time" sortable={true} filter={true} />
                                <Column field="roomNo" header="Roon No." sortable={true} filter={true} />
                                <Column field="subjectName" header="Subject Name" sortable={true} filter={true} />

                            </DataTable>

                            : ''}
                </div>
                {/* </Panel> */}
            </div >
        )
    }
}