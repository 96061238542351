import React, { Component } from 'react';
import RegistrationPrimaryForm from './RegistrationPrimaryForm';

import NetiwordLogo from "../../assets/images/login/neti.png";

class Registration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            primaryform: true,
            regitrationadvancedform: false,
        }
    }
    render() {

        return (
            <div>
                {/* <div className="layout-wrapper layout-static layout-menu-dark custom-layout-wrapper">
                    <div className="layout-content">
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-4 p-xs-12"></div>
                                <div className="p-col-12 p-md-4 p-xs-12">
                                    <div className="p-md-12">
                                        
                                        <div className="card card-w-title custom-login-box">

                                            <div className="layout-dashboard">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                                        <div className="overview-box checkin">
                                                            <img 
                                                                id="layout-menu-logo" 
                                                                src="/assets/layout/images/NetiWorld-White--with-text.png" 
                                                                alt="Netizen IT Limited"
                                                                width="100%"
                                                                />
                                                            <div className="overview-subinfo custom-overview-subinfo">
                                                                Create an <b>Netiworld Account</b>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <RegistrationPrimaryForm click={this.registrationNextHendaler} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}


               
                    <div className="layout-wrapper layout-static layout-menu-dark nw-global-background">
                        <div className="nw-layout-content">
                            <div className="global-login-form text-center">



                                <img
                                    id="layout-menu-logo"
                                    src={NetiwordLogo}
                                    alt="Netizen IT Limited"
                                />
                            

                                <div>
                                {/* start process step */}
                                <h3>Sign Up</h3>
                                <p>Registration Page</p>

                                {/* end process step */}
                                    <RegistrationPrimaryForm click={this.registrationNextHendaler} />

                                </div>



                                
                            </div>
                        </div>
                    </div>
                
            
            </div>



            
        )

    }
}

export default Registration;