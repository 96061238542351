import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RadioButton } from "primereact/radiobutton";
import { SaSummaryService } from "../../../service/saPoint/SaSummaryService";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { NetiDateUtils } from "../../../utils/NetiDateUtils";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import NetiContentLoader from "../../common/NetiContentLoader";
import mapImage from "../../../assets/images/map-image.png";
import mapPin from "../../../assets/images/map-pin.png";
import ImageMapper from "react-image-mapper";
import { DatatableAttendanceInformation } from "./DatatableAttendanceInformation";
import { InstituteWiseAttendance } from "./InstituteWiseAttendance";

import { Card } from "primereact/card";
import Chart from "react-google-charts";
import { AttendancerChartsColorArray } from "../../common/ChartsColorArray";
import { SaCommonService } from "../../../service/saPoint/SaCommonService";
import {
  primaryChartsColorArray,
  dangerChartsColorArray,
} from "../../common/ChartsColorArray";

let maxDate = new Date();


let teacherAttendanceInfoArr = [];
let teacherAttendanceInfoFinalArr = [];
let totalAttendanceInfoArr = [];
let totalAttendanceInfoFinalArr = [];
export class DistrictWiseAttendance extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      viewDistrictInfoDetails: true,
      selectedInstituteDetails: {},
      viewSingleInstituteDetails: false,
      instituteWiseInfo: {},
      upazillaWiseInfo: {},
      upazillaWiseAttendanceInfoView: false,
      instituteWiseAttendanceInfoView: false,
      districtID: "",
      date: "",
      districtWiseInfo: {},
      hoveredArea: "",
      city: null,
      checked1: false,
      dateRangeObj: {
        startDate: "",
        endDate: "",
      },
      dashboardViewObj: {
        submitedToken: [
          {
            Title: "Value",
          },
        ],
        totalCall: [
          {
            Title: "Value",
          },
        ],
        personWisePendingToken: [
          {
            Title: "Value",
          },
          ["karima Islam", 12],
          ["Jahidul Islam", 3],
          ["Level Revenue Account", 1],
          ["MD Shawan", 6],
          ["Ab Hussain", 1],
          ["Md Imrul ", 3],
          ["SI Rayhan", 4],
          ["Noushin Tabassum", 2],
        ],
        personWiseSolvedToken: [
          {
            Title: "Value",
          },
        ],
        personWiseInboundCall: [
          {
            Title: "Value",
          },
        ],
        personWiseOutboundCall: [
          {
            Title: "Value",
          },
        ],
        applicationWiseToken: [
          {
            Title: "Value",
          },
        ],
        emModuleWiseToken: [
          {
            Title: "Value",
          },
        ],
        nwPointWiseToken: [
          {
            Title: "Value",
          },
        ],
        inboundCall: [
          {
            Title: "Value",
          },
        ],
        outboundCall: [
          {
            Title: "Value",
          },
        ],
        durationWiseToken: [
          {
            Title: "Value",
          },
        ],
      },
      dashboardViewObjMain: {},
      colorArray: [
        {
          color: "#FFD86D",
        },
        {
          color: "#7178AC",
        },
        {
          color: "#FF9191",
        },
        {
          color: "#85C2FF",
        },
        {
          color: "#65EEE1",
        },
        {
          color: "#EED86D",
        },
        {
          color: "#7168AC",
        },
        {
          color: "#FC9121",
        },
        {
          color: "#25C2FF",
        },
        {
          color: "#65EEF1",
        },
      ],
      errorMsgVisible: false,
      topProgressBar: false,
      dataTableIsLoading: false,
      errorMsgBody: "",
      errors: {},
    };
    this.saCommonService = new SaCommonService();

    // this.cssAnimation = this.cssAnimation.bind();
    // this.createMapDetails = this.createMapDetails.bind();
  }

  componentWillMount() {
    let { dashboardViewObj } = this.state;
    let columnsHeader = ["Title", "Value"];

    let body = {
      personWisePendingToken: {
        Dhaka: 2000,
        Tangail: 890,
        Narsingdi: 890,
        Madaripur: 500,
        Shariatpur: 900,
        Gazipur: 999,
        Narayangonj: 1500,
        Gopalgonj: 300,
        Sherpur: 1222,
      },
    };
    let personWisePendingTokenArray = [];
    let personWisePendingToken = Object.entries(body.personWisePendingToken);
    personWisePendingTokenArray.push(columnsHeader, ...personWisePendingToken);
    // personWisePendingTokenSum = personWisePendingToken.map(item => item[1]).reduce((a, b) => (a + b));
    dashboardViewObj.personWisePendingToken = personWisePendingTokenArray;
  }

  componentDidMount() {
    this.fetchDistrictWiseAttendanceInfo(this.props.requestDate);
  }

  // onChangeDate = (e) => {
  //     this.setState({ date: NetiDateUtils.getDateFromStringDDMMYYYY(e.target.value)});
  //     this.fetchDistrictWiseAttendanceInfo(NetiDateUtils.getDateFromStringDDMMYYYY(e.target.value));
  // }

  getDistrictIDFromRowData = (childData) => {
    this.setState({
      districtID: childData,
    });
    if (childData.parentDefaultCode === "T10202") {
      this.fetchUpazillaWiseAttendanceInfo(
        childData.id,
        this.props.requestDate
      );
    } else if (childData.parentDefaultCode === "T10203") {
      this.fetchInstituteWiseAttendanceInfo(
        childData.id,
        this.props.requestDate
      );
    }

    if(childData.instituteId) {
      this.fetchSingleInstituteInfo(childData.instituteId);
    }
  };

  fetchSingleInstituteInfo = (instituteID) => {
     teacherAttendanceInfoArr = [];
     teacherAttendanceInfoFinalArr = [];
     totalAttendanceInfoArr = [];
     totalAttendanceInfoFinalArr = [];
    this.setState({
      dataTableIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });
    this.saCommonService
      .findSingleInstituteWiseAttendanceInfo(this.props.requestDate,instituteID)
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            let commonChartHeader = ["title", "value"];
 
            if(body && body.item) {
                for (const property in body.item) {
                        if(property == 'maleTeacherPercent') {
                            teacherAttendanceInfoArr.push([property,body.item[property]])
                        }
                        if(property == 'femaleTeacherPercent') {
                            teacherAttendanceInfoArr.push([property,body.item[property]])
                        }
                        if(property == 'presentPercent') {
                            totalAttendanceInfoArr.push([property,body.item[property]])
                        }
                        if(property == 'absentPercent') {
                            totalAttendanceInfoArr.push([property,body.item[property]])
                        }
                        if(property == 'leavePercent') {
                            totalAttendanceInfoArr.push([property,body.item[property]])
                        }
                  }
                  teacherAttendanceInfoFinalArr.push(commonChartHeader,...teacherAttendanceInfoArr)
                  totalAttendanceInfoFinalArr.push(commonChartHeader,...totalAttendanceInfoArr)
             }

            this.setState({
              selectedInstituteDetails: body.item,
            });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.saCommonService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load. Please check your connection.",
        });
      });

  }

  

  fetchUpazillaWiseAttendanceInfo = (areaID, date) => {
    this.setState({
      dataTableIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });
    this.saCommonService
      .findObserverAttendanceInfoByArea(areaID, date)
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({
              upazillaWiseInfo: body.item,
            });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.saCommonService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load. Please check your connection.",
        });
      });
  };

  fetchInstituteWiseAttendanceInfo = (areaID, date) => {
    this.setState({
      dataTableIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });
    this.saCommonService
      .findInstituteWiseObserverAreaAttendance(areaID, date)
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({
              instituteWiseInfo: body.item,
            });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.saCommonService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load. Please check your connection.",
        });
      });
  };

  showUpazillaWiseAttendanceInfoView = () => {
    this.setState({
      upazillaWiseAttendanceInfoView: true,
    });
  };

  hideUpazillaWiseAttendanceInfoView = () => {
    this.setState({
      upazillaWiseAttendanceInfoView: false,
    });
  };

  showDistrictInfoViewComponent = () => {
    this.setState({
      viewDistrictInfoDetails: true,
    });
  };

  hideDistrictInfoViewComponent = () => {
    this.setState({
      viewDistrictInfoDetails: false,
    });
  };

  showInstituteWiseAttendanceInfoView = () => {
    this.setState({
      instituteWiseAttendanceInfoView: true,
    });
  };

  hideInstituteWiseAttendanceInfoView = () => {
    this.setState({
      instituteWiseAttendanceInfoView: false,
    });
  };

  showSingleInstituteAttendanceInfoView = () => {
    this.setState({
      viewSingleInstituteDetails: true,
    });
  };

  hideSingleInstituteAttendanceInfoView = () => {
    this.setState({
      viewSingleInstituteDetails: false,
    });
  };

  render() {
    console.log("dataTableValue district", this.state.districtWiseInfo);
    let {
      districtWiseInfo,
      upazillaWiseInfo,
      pendingTokenArray,
      dateRangeObj,
      dashboardViewObj,
      colorArray,
      topProgressBar,
      errorMsgVisible,
      errorMsgBody,
      staffAttendanceSummaryList,
    } = this.state;

    return (
      <div className="p-fluid">
        {" "}
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}{" "}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}{" "}
 
       
        {this.state.instituteWiseAttendanceInfoView === true ? (
          <DatatableAttendanceInformation
            showUpazillaWiseAttendanceInfoView={this.showUpazillaWiseAttendanceInfoView}
            showSingleInstituteAttendanceInfoView={this.showSingleInstituteAttendanceInfoView}
            getAreaIDFromRowData={this.getDistrictIDFromRowData}
            attendanceInfo={this.state.instituteWiseInfo}
            showInstituteWiseAttendanceInfoView={
              this.showInstituteWiseAttendanceInfoView
            }
            hideInstituteWiseAttendanceInfoView={
              this.hideInstituteWiseAttendanceInfoView
            }
          />
        ) : (
          ""
        )}{" "}
        {this.state.viewSingleInstituteDetails === true ? (
          <div className="p-col-12 p-xl-12">
            <InstituteWiseAttendance
              teacherAttendanceInfoFinalArr={teacherAttendanceInfoFinalArr}
              hideSingleInstituteAttendanceInfoView={this.hideSingleInstituteAttendanceInfoView}
              showInstituteWiseAttendanceInfoView={this.showInstituteWiseAttendanceInfoView}
              totalAttendanceInfoFinalArr={totalAttendanceInfoFinalArr}
              singleInstituteDetails={this.state.selectedInstituteDetails}
            />{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
