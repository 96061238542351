import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Editor } from 'primereact/editor';
import { AdminPointService } from "../../../service/adminPoint/AdminPointService";
import CommonFuctionality from "../../common/CommonFuctionality";
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';


export class CalendarEventList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[],
            proPic: {},
            errors:{},
            bannerCategory: "",
            calendarEventObj: {
                coreCategoryInfoDTO:{
                    coreCategoryID:''
                },
                taggingCode:'',
                eventTitle:'',
                eventDetails:'',
                fromDate:'',
                toDate:'',

            },
            firstDropdownIsLoading:false,
            dataTableIsLoading:false,
            singleEventInfo: {
                coreCategoryInfoDTO:{
                    coreCategoryID:''
                },
                taggingCode:'',
                eventTitle:'',
                eventDetails:'',
                fromDate:'',
                toDate:'',
              }
        }
        
        this.CommonFuctionality = new CommonFuctionality();
        this.adminPointService = new AdminPointService();
        this.NetiContentLoader = new NetiContentLoader();

    }

    componentWillMount() {
        this.getEventList();
    }

    viewCalendarEventList = () => {
        this.setState({ topProgressBar: true });

    }

    onChangeBannerCategory = (e) => {
        let {singleEventInfo,errors} = this.state;
        errors["calendarId"] = "";
        singleEventInfo.coreCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ singleEventInfo,errors })
    }

    onChangePartnerTagging = (e) => {
        let {singleEventInfo,errors} = this.state;
        errors["taggingCode"] = "";
        singleEventInfo.taggingCode = e.target.value;
        this.setState({ singleEventInfo,errors });
    }

    onChangeEventTitle = (e) => {
        let {singleEventInfo,errors} = this.state;
        errors["eventTitle"] = "";
        singleEventInfo.eventTitle = e.target.value;
        this.setState({ singleEventInfo,errors });
    }

    onChangeCalendarDetails = (e) => {
        let {singleEventInfo,errors} = this.state;
        singleEventInfo.eventDetails = e.htmlValue;
        errors["eventDetails"] = "";
        this.setState({ singleEventInfo,errors });
        
    }

    onChangeCalendarEventStartDate = (e) => {
        let {singleEventInfo,errors} = this.state;
        errors["fromDate"] = "";
        singleEventInfo.fromDate = e.target.value;
        this.setState({ singleEventInfo,errors });
    }

    onChangeCalendarEventEndDate = (e) => {
        let {singleEventInfo,errors} = this.state;
        errors["toDate"] = "";
        singleEventInfo.toDate = e.target.value;
        this.setState({ singleEventInfo,errors });
    }

    onChangeCalendarStatus = (e) => {
        let {singleEventInfo} = this.state;
        singleEventInfo.enableStatus =  parseInt(e.target.value);
        this.setState({ singleEventInfo });
    }

    


    onHide = (event) => {
        this.setState({ 
            visible: false,
            errors: {} 
        });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    viewDialog = (rowData) => {

        this.setState({ visible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.adminPointService.getEventListInfo(rowData.calendarId)
        .then(res=>{
            if (res.status == 302) {
                return res.json().then((body) => {
                    console.log("body from api",body);
                    body.taggingCode = parseInt(body.taggingCode);
                    body['fromDate'] = NetiDateUtils.getAnyShortForm(body.fromDate, 'DD/MM/YY');
                    body['toDate'] = NetiDateUtils.getAnyShortForm(body.toDate, 'DD/MM/YY');
                    this.setState({ 
                        singleEventInfo: body,
                        calendarEventObj: body
                    });

                  });
            }else {
                this.adminPointService.Auth.handleErrorStatus(res)
                  .then((resp) => {
                      this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                  });
            }
        }).catch(error =>{
            // this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
        })
        //  rowData.coreCategoryID;

    }

    actionTemplate = (rowData) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-edit"
                className="nw-action-button edit"
                style={{ marginRight: '.5em', 'fontSize': '1em' }}
                onClick={(e) => this.viewDialog(rowData)}
            >
            </Button>
        </div>;
    }

          // form validation
          updateCategoryTypeError = () => {
            let { singleEventInfo,errors } = this.state
            let formIsValid = true;
            
            if (singleEventInfo.coreCategoryInfoDTO.coreCategoryID === '' || singleEventInfo.coreCategoryInfoDTO.coreCategoryID === null) {
                formIsValid = false;
                errors["calendarId"] = "Calender category can't left empty";
            }
    
            if (singleEventInfo.taggingCode === '') {
              formIsValid = false;
              errors["taggingCode"] = "Partner tagging can't left empty";
            }

            if (singleEventInfo.eventDetails === null) {
              formIsValid = false;
              errors["eventDetails"] = "Event details can't left empty";
            }
    
            if (singleEventInfo.eventTitle === '') {
              formIsValid = false;
              errors["eventTitle"] = "Event title can't left empty";
            }

            if (singleEventInfo.fromDate === '') {
                formIsValid = false;
                errors["fromDate"] = "Event start can't left empty";
              }

              if (singleEventInfo.toDate === '') {
                formIsValid = false;
                errors["toDate"] = "Event end can't left empty";
              }

            this.setState({ errors });
            return formIsValid;

          }

    onUpdateHandler = () => {
        let { singleEventInfo, calendarEventObj } = this.state;
        if (this.updateCategoryTypeError()) {
        singleEventInfo.toDate = NetiDateUtils.getDateFromString(singleEventInfo.toDate)
        singleEventInfo.fromDate = NetiDateUtils.getDateFromString(singleEventInfo.fromDate)
        // this.setState({calendarEventObj});
            this.setState({ topProgressBar: true, errorMsgVisible: false })
                this.adminPointService.updateCaldenderEventListInfo(singleEventInfo)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated!" });
                        this.setState({ visible: false });
                        this.setState({ errors: {} });
                        this.getEventList();
                        this.CommonFuctionality.blurDialogBackgroundDeActive();
                        this.setState({ topProgressBar: false, errorMsgVisible: false })
    
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((res) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: res });
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                });
        }

    }

    updateDialogDiscard = () => {
        this.onHide();

    }

    // Event List
    getEventList(){
        this.setState({ dataTableIsLoading:true,topProgressBar:true,errorMsgVisible:false });
        this.adminPointService.fetchAllEventList()
        .then(res=>{
            if (res.status === 302) {
            return res.json().then(body =>{
                
                body.map(item => {
                    if (item.fromDate) {
                        item.fromDate = NetiDateUtils.getAnyShortForm(item.fromDate, 'DD-MMM-YYYY');
                    } else {
                        item.fromDate = ''
                    }

                    if (item.toDate) {
                        item.toDate = NetiDateUtils.getAnyShortForm(item.toDate, 'DD-MMM-YYYY');
                    } else {
                        item.toDate = ''
                    }

                    if (item.createDate) {
                        item.createDate = NetiDateUtils.getAnyShortForm(item.createDate, 'DD-MMM-YYYY');
                    } else {
                        item.createDate = ''
                    }

                })
                this.setState({dataTableValue:body,topProgressBar:false,errorMsgVisible:false,dataTableIsLoading:false});
                })
            }else {
            this.adminPointService.Auth.handleErrorStatus(res)
                .then((resp) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                });
            }
        }).catch(error => {
        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
        });
    }

    render() {
        let { singleEventInfo, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        console.log("singleEventInfo.fromDate",singleEventInfo.fromDate);
        let title = <span className="text-center">Update Global Calendar Info</span>
        var tableHeader = <div class="header-title"><div class="header-title-left">Event List (Calendar Wise)</div><div class="header-title-right"><a>Total Found: {this.state.dataTableValue.length}</a></div></div>

        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid blur-section">
                    <div className="nw-data-table">
                        { this.state.dataTableIsLoading ? (this.NetiContentLoader.MyPointTableLoader()) : (
                            <DataTable
                                value={this.state.dataTableValue}
                                selectionMode="single"
                                header={tableHeader}
                                selection={this.state.dataTableSelection}
                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                responsive={true}
                                rows={10}
                                columnResizeMode="fit"
                                paginator={true}
                                className="text-center"
                            >
                                <Column field="createDate" header="Date" filter={true} />
                                <Column field="coreCategoryInfoDTO.categoryName" header="Category" filter={true} />
                                <Column field="eventTitle" header="Event Title" filter={true} />
                                <Column field="fromDate" header="Event Starts" filter={true} />
                                <Column field="toDate" header="Event Ends" filter={true} />
                                <Column field="action" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '80px' }} />
                            </DataTable>
                        ) }
                    </div>
                </div>
                <div className="dialog-section">
                    <Dialog 
                        className="nw-dialog" 
                        header={title} 
                        visible={this.state.visible} 
                        onHide={this.onHide} 
                        closable
                    >
                        <div className="nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <Growl ref={(el) => this.growl = el} />
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Category <span>*</span></span>
                                            <Dropdown
                                                value={singleEventInfo.coreCategoryInfoDTO.coreCategoryID}
                                                onChange={(e) => this.onChangeBannerCategory(e)}
                                                options={this.props.bannerCategory}
                                                id="bannerCategory"
                                                showClear={true}
                                                type="text"
                                                name="calendarId"
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors["calendarId"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Partner Tagging <span>*</span></span>
                                            <Dropdown
                                                value={singleEventInfo.taggingCode}
                                                options={this.props.partnerTagging}
                                                onChange={(e) => this.onChangePartnerTagging(e)}
                                                autoWidth={false}
                                                type="text"
                                                id="taggingCode"
                                                name="taggingCode"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors["taggingCode"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Event Title <span>*</span></span>
                                            <InputText
                                                id="eventTitle"
                                                value={singleEventInfo.eventTitle}
                                                onChange={this.onChangeEventTitle}
                                                filter={true}
                                                filterBy="label,value"
                                                autoWidth={false}
                                                name="eventTitle"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors["eventTitle"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtexteditor-label">Event Details <span>*</span></span>
                                            <Editor
                                                id="eventDetails"
                                                value={singleEventInfo.eventDetails}
                                                onTextChange={this.onChangeCalendarDetails}
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="eventDetails"
                                            />
                                        </div>
                                        <span className="error-message">{ this.state.errors["eventDetails"] }</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Event Start <span>*</span></span>
                                            <Calendar
                                                id="fromDate"
                                                name="fromDate"
                                                showIcon={true}
                                                value={singleEventInfo.fromDate}
                                                onChange={this.onChangeCalendarEventStartDate}
                                                yearRange="2010:2030"
                                                dateFormat="dd/mm/yy"
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                dateOnly="true"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors["fromDate"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Event End <span>*</span></span>
                                            <Calendar
                                                id="toDate"
                                                name="toDate"
                                                showIcon={true}
                                                onChange={this.onChangeCalendarEventEndDate}
                                                value={singleEventInfo.toDate}
                                                yearRange="2010:2030"
                                                dateFormat="dd/mm/yy"
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                dateOnly="true"
                                            />
                                        </div>
                                        <span className="error-message">{ this.state.errors["toDate"] }</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <div className="input-radio-button">
                                                <span className="p-inputgroup-addon addon-no-style">Status <span>*</span></span>
                                                <div className="radio-button-inside">
                                                    <RadioButton
                                                        value="1"
                                                        name="enableStatus"
                                                        inputId="rb1"
                                                        checked={singleEventInfo.enableStatus === 1}
                                                        onChange={this.onChangeCalendarStatus}
                                                    />
                                                    <label htmlFor="rb1" className="p-radiobutton-label">Enable</label>
                                                </div>
                                                <div className="radio-button-inside">
                                                    <RadioButton
                                                        value="0"
                                                        inputId="rb1"
                                                        name="enableStatus"
                                                        checked={singleEventInfo.enableStatus === 0}
                                                        onChange={this.onChangeCalendarStatus}
                                                    />
                                                    <label htmlFor="rb2" className="p-radiobutton-label">Disable</label>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            label="Discard"
                                            icon="fas fa-times"
                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                            onClick={this.updateDialogDiscard}
                                        />
                                        <Button
                                            label="Update"
                                            icon="fas fa-check"
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            onClick={this.onUpdateHandler}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}
