import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import NetiContentLoader from '../../../common/NetiContentLoader';
import { pdfDownloadLandscape_TableWithSubTitle } from '../../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';
import CommonFuctionality from '../../../common/CommonFuctionality';

let maxDate = new Date();
let errors = {}

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class NetiChatHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            searchDataObj: {
                startDate: '',
                endDate: '',
                pageLimit: 0,
                pageNo: 0
            },
            messageDetailsDialog: {
                senderBasicInfoDTO: {},
                receiverBasicInfoDTO: {}
            },
            errors: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }
        this.onHide = this.onHide.bind(this);
        this.detailsBody = this.detailsBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);

        this.CommonFuctionality = new CommonFuctionality();
        this.NetiDateUtils = new NetiDateUtils();
        this.managementPointService = new ManagementPointService();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    viewDialog(rowData) {
        // let {rowInformation} = this.state
        // rowInformation.salesDate = rowData.salesDate
        // rowInformation.productName = rowData.productName
        // rowInformation.purchaseCode = rowData.purchaseCode
        // rowInformation.salesPrice = rowData.salesPrice
        // rowInformation.customerName = rowData.customerName
        // rowInformation.customerContact = rowData.customerContact
        // rowInformation.salesNote = rowData.salesNote
        // this.setState({rowInformation})
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visible: true, messageDetailsDialog: rowData });
    }

    onHide(event) {

        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visible: false });

    }

    detailsBody = rowData => {

        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-envelope-open-text"
                title='Click to view deposit details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>

    }

    // componentDidMount() {
    //     let { dataTableValue } = this.state;
    //     dataTableValue = dataTableValue.map(item => this.NetiDateUtils.getDateFromStringDDMMYYYY(item.chatboxDate))
    // }

    startDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["startDate"] = "";
        searchDataObj.startDate = event.target.value;
        this.setState({ searchDataObj, errors })
    }

    endDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["endDate"] = "";
        searchDataObj.endDate = event.target.value;
        this.setState({ searchDataObj, errors })
    }

    searchHandleError = () => {
        let { errors, searchDataObj } = this.state;
        let formIsValid = true;
        if (searchDataObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start date can't left empty";
        }
        if (searchDataObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End date can't left empty";
        }
        this.setState({ errors });
        return formIsValid;
    }

    onDateWiseSearchHandler = () => {
        let { searchDataObj, errors } = this.state;
        this.setState({ errors: {} })
        if (this.searchHandleError()) {
            if (Date.parse(searchDataObj.endDate) >= Date.parse(searchDataObj.startDate)) {
                this.setState({ tableview: true, topProgressBar: true, dataTableIsLoading: true });
                let saveSearchObj = { ...searchDataObj }
                saveSearchObj.startDate = NetiDateUtils.getDateFromString(searchDataObj.startDate);
                saveSearchObj.endDate = NetiDateUtils.getDateFromString(searchDataObj.endDate);
                this.managementPointService.fetchChatHistoryByDate(saveSearchObj)
                    .then(res => {
                        if (res.status == 200) {
                            return res.json().then((body) => {

                                if (body.length == 0) {
                                    return this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                                }
                                body.map(item => {
                                    item.chatboxDate = NetiDateUtils.getAnyShortForm(item.chatboxDate, 'DD-MMM-YYYY hh:mm:ss a');
                                    item.senderID = item.senderID.toString();
                                    item.receiverID = item.receiverID.toString();
                                });

                                this.setState({ dataTableValue: body, topProgressBar: false, tableView: true, dataTableIsLoading: false });
                            });
                        } else {
                            this.managementPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "To Date can't be earlier than From Date." });
                this.setState({ topProgressBar: false, errorMsgVisible: false, errorMsgBody: '' })
            }
        }
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Date & Time", dataKey: "chatboxDate" },
            { title: "Message ID", dataKey: "id" },
            { title: "Sender Neti ID", dataKey: "senderID" },
            { title: "Sender Name", dataKey: "senderName" },
            { title: "Receiver Neti ID", dataKey: "receiverID" },
            { title: "Receiver Name", dataKey: "receiverName" },
            { title: "Message Details", dataKey: "msg" },
        ]

        let tableDataStyle = {
            msg: { cellWidth: 100, overflow: "linebreak", },
        }

        pdfDownloadLandscape_TableWithSubTitle("Neti Chat History", 113, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableDataStyle, "Neti_Chat_History.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, searchDataObj, messageDetailsDialog } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';

        if (searchDataObj.startDate && searchDataObj.endDate) {
            formatedStartDate = (searchDataObj.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (searchDataObj.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = formatedStartDate + ' to ' + formatedEndDate + "_Neti_Chat_History.xlsx";
        let excelSheetName = "Neti_Chat_History";
        excelColumns = [
            <Workbook.Column value="chatboxDate" label="Date & Time" />,
            <Workbook.Column value="id" label="Message ID" />,
            <Workbook.Column value="senderID" label="Sender Neti ID" />,
            <Workbook.Column value="senderName" label="Sender Name" />,
            <Workbook.Column value="receiverID" label="Receiver Neti ID" />,
            <Workbook.Column value="receiverName" label="Receiver Name" />,
            <Workbook.Column value="msg" label="Message Details" />,
        ];
        // end excel

        let header = <div className="header-title">
            <div className="header-title-left">Netichat History</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        return (
            <div className="p-fluid">
                {/* <div className="p-grid"> */}
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="nw-form">
                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <Growl ref={(el) => this.growl = el} />

                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={searchDataObj.startDate}
                                        dateFormat="dd/mm/yy"
                                        onChange={this.startDateHandler}
                                        showIcon={true}
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />

                                </div>
                                <span className="error-message">{this.state.errors["startDate"]}</span>

                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={searchDataObj.endDate}
                                        onChange={this.endDateHandler}
                                        dateFormat="dd/mm/yy"
                                        showIcon={true}
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["endDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                <Button
                                    label="Search"
                                    className="p-button-primary nw-button nw-button-center"
                                    icon="fas fa-search"
                                    onClick={this.onDateWiseSearchHandler}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="nw-data-table">
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            {/* {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                            <DataTable
                                header={header}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                ref={(el) => { this.dt = el; }}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 30]}
                                value={this.state.dataTableValue}
                            //selection={this.state.dataTableSelection}
                            >
                                <Column field="chatboxDate" header="Date & Time" filter={true} style={{ width: '110px' }} />
                                <Column field="id" header="Message ID" filter={true} />
                                <Column field="senderID" header="Sender Neti ID" filter={true} />
                                <Column field="senderName" header="Sender Name" filter={true} />
                                <Column field="receiverID" header="Receiver Neti ID" filter={true} />
                                <Column field="receiverName" header="Receiver Name" filter={true} />
                                <Column field="msg" header="Message Details" body={this.detailsBody} style={{ width: '95px' }} />

                            </DataTable>

                            {/* :
                                    
                                    <BlankDataTableAnim/> 
                            } */}
                        </div>

                        <div className="p-col-12 p-xl-12 nw-button-parent">

                            <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                            <div style={{ display: 'none' }}>

                                <Workbook
                                    filename={excelFileName}
                                    element={
                                        <Button
                                            label="Download Excel"
                                            id="clickExcelBtn"
                                            icon="fas fa-download"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                        />
                                    }
                                >
                                    <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                        {excelColumns}
                                    </Workbook.Sheet>
                                </Workbook>

                            </div>

                        </div >

                    </div>

                </div>

                <Dialog className="nw-dialog" header="Message Information" visible={this.state.visible} onHide={this.onHide} maximizable>

                    <div className="p-col-12 p-xl-12">

                        <div className="nw-search-view">
                            <div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Date & Time</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{messageDetailsDialog.chatboxDate}</div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Message ID</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{messageDetailsDialog.id}</div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Sender Neti ID</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{messageDetailsDialog.senderID}</div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Sender Name</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{messageDetailsDialog.senderName}</div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Receiver Neti ID</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{messageDetailsDialog.receiverID}</div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Receiver Name</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{messageDetailsDialog.receiverName}</div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Message Details</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{messageDetailsDialog.msg}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </Dialog>

            </div>

        )
    }
}