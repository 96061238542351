import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SaSummaryService } from '../../../service/saPoint/SaSummaryService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { SelectButton } from 'primereact/selectbutton';
import { ErrorMessageView } from '../../common/ErrorMessageView';

export class GenderAndReligionWiseStaffInfo extends Component {

    constructor() {
        super();

        this.state = {
            genderWiseStaffList: [],
            religionWiseStaffList: [],
            dataViewValue: [],
            type: null,
            dataTableSelection: true,
            topProgressBar: false,
            firstDataTableIsLoading: false,
            secondDataTableIsLoading: false
        }
        this.saSummaryService = new SaSummaryService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.getGenderWiseStaffInfoList();
    }


    getGenderWiseStaffInfoList = () => {
        this.setState({ topProgressBar: true, firstDataTableIsLoading: true, errorMsgVisible: false })
        this.saSummaryService.getGenderWiseStaffInfo()
            .then(data => {
                if (data.item) {
                    if (data.item.length == 0) {
                        return this.setState({ topProgressBar: false, firstDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                    }
                    data.item.map(item => {
                        item.maleInfo = `${item.totalMale} (${item.totalMalePercent}%)`;
                        item.femaleInfo = `${item.totalFemale} (${item.totalFemalePercent}%)`;
                        item.otherInfo = `${item.totalOther} (${item.totalOtherPercent}%)`;
                    })
                    this.setState({ genderWiseStaffList: data.item })
                    this.setState({ topProgressBar: false, firstDataTableIsLoading: false })
                } else {
                    // this.saSummaryService.Auth.handleErrorStatus(data)
                    //     .then((responseBody) => {
                            this.setState({ topProgressBar: false, firstDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                        // });

                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
            });
    }

    getReligionWiseStaffInfoList = () => {
        this.setState({ topProgressBar: true, secondDataTableIsLoading: true, errorMsgVisible: false })
        this.saSummaryService.getReligionWiseStaffInfo()
            .then(data => {
                if (data.item) {
                    if (data.item.length == 0) {
                        return this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                    }
                    data.item.map(item => {
                        item.islamInfo = `${item.totalIslam} (${item.totalIslamPercent}%)`;
                        item.hinduInfo = `${item.totalHindu} (${item.totalHinduPercent}%)`;
                        item.buddhistInfo = `${item.totalBouddha} (${item.totalBouddhaPercent}%)`;
                        item.cristianInfo = `${item.totalChristian} (${item.totalChristianPercent}%)`;
                        item.othersInfo = `${item.totalOtherReligions} (${item.totalOtherReligionsPercent}%)`;
                    })
                    this.setState({ religionWiseStaffList: data.item })
                    this.setState({ topProgressBar: false, secondDataTableIsLoading: false })
                } else {
                    // this.saSummaryService.Auth.handleErrorStatus(data)
                    //     .then((responseBody) => {
                            this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                        // });

                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
            });
    }

    buttonSelector(e) {
        if (e.value !== null) {
            this.setState({ dataTableSelection: e.value })
        }
    }

    toggleButton(e) {
        if (e.value === true) {
            this.getGenderWiseStaffInfoList();
        } else {
            this.getReligionWiseStaffInfoList();
        }

    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, genderWiseStaffList, religionWiseStaffList } = this.state;

        const options = [
            { label: 'Gender', value: true },
            { label: 'Religion', value: false },
        ];

        let staffInfoSummary = null;
        // let content = "";

        let tableHeaderGender = (
            <div className="p-clearfix">
                Institute Wise Gender Information{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {genderWiseStaffList.length}{" "}
                </span>
            </div>
        );

        let tableHeaderReligion = (
            <div className="p-clearfix">
                Institute Wise Religion Information{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {religionWiseStaffList.length}{" "}
                </span>
            </div>
        );



        // let tableHeaderGender = <span> <span>Gender Wise Staff Information</span><span className='float-right text-bold'></span></span>
        // let tableHeaderReligion = <span> <span>Religion Wise Staff Information</span><span className='float-right text-bold'></span></span>

        // let item.maleInfo = (rowData) => {
        //     return <div>{item.totalMale} ({item.totalMalePercent}%)</div>
        // }

        // let item.femaleInfo = (rowData) => {
        //     return <div>{item.totalFemale} ({item.totalFemalePercent}%)</div>
        // }

        // let item.islamInfo = (rowData) => {
        //     return <div>{item.totalIslam} ({item.totalIslamPercent}%)</div>
        // }

        // let item.hinduInfo = (rowData) => {
        //     return <div>{item.totalHindu} ({item.totalHinduPercent}%)</div>
        // }

        // let item.buddhistInfo = (rowData) => {
        //     return <div>{item.totalBouddha} ({item.totalBouddhaPercent}%)</div>
        // }

        // let item.cristianInfo = (rowData) => {
        //     return <div>{item.totalChristian} ({item.totalChristianPercent}%)</div>
        // }

        // let item.othersInfo = (rowData) => {
        //     return <div>{item.totalOtherReligions} ({item.totalOtherReligionsPercent}%)</div>
        // }



        return (
            <div className="p-grid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-col-12">

                    <div className="p-col-12 p-xl-12">
                        <div className="dataTable-change">
                            <SelectButton
                                value={this.state.dataTableSelection}
                                options={options}
                                onChange={(e) => { this.buttonSelector(e); this.toggleButton(e) }}
                            />
                        </div>
                    </div>


                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.dataTableSelection ?
                                <div className="nw-data-table">
                                    {this.state.firstDataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <DataTable
                                            header={tableHeaderGender}
                                            value={genderWiseStaffList}
                                            responsive={true}
                                            paginator={true}
                                            rowsPerPageOptions={[5, 10, 20]}
                                            rows={10} >
                                            <Column field="instituteId" header="Institute ID" filter={true} />
                                            <Column field="instituteName" header="Institute Name" filter={true} />
                                            <Column field="totalStaffs" header="Total HR" filter={true} />
                                            <Column field="maleInfo" header="Male" filter={true} />
                                            <Column field="femaleInfo" header="Female" filter={true} />
                                            <Column field="otherInfo" header="Others" filter={true} />
                                        </DataTable>
                                    }
                                </div>
                                :

                                <div className="nw-data-table">
                                    {this.state.secondDataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <DataTable
                                            header={tableHeaderReligion}
                                            value={religionWiseStaffList}
                                            responsive={true}
                                            paginator={true}
                                            rowsPerPageOptions={[5, 10, 20]}
                                            rows={10} >
                                            <Column field="instituteId" header="Institute ID" filter={true} />
                                            <Column field="instituteName" header="Institute Name" filter={true} />
                                            <Column field="totalStaffs" header="Total HR" filter={true} />
                                            <Column field="islamInfo" header="Islam" filter={true} />
                                            <Column field="hinduInfo" header="Hinduism" filter={true} />
                                            <Column field="buddhistInfo" header="Buddhism" filter={true} />
                                            <Column field="cristianInfo" header="Christianity" filter={true} />
                                            <Column field="othersInfo" header="Others" filter={true} />
                                        </DataTable>

                                    }
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}