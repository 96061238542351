import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class AppBreadcrumb extends Component {

	static propTypes = {
		match: PropTypes.object
	}

	render() {
		const {location} = this.props;
		// console.log("path name::", location.pathname);
		
		const paths = location.pathname.split('/');
		return (
			<div className="nw-breadcrumb">
				{location.pathname === '/' ? 
					<div className="route-bar">
						<div className="route-bar-breadcrumb">
							<ul key='1'>
								<li key='11'>Dashboard</li>
							</ul>
						</div>
					</div>
					:<div className="route-bar">

						<div className="route-bar-breadcrumb">
							<ul key='2'>
								{/* <li key='21'> */}
									{
										paths.map((path, index) => 
											<li key={index}>
												{
													path === '' ? 
													' ' : 
													path.replace(/_/g, ' ') === "hr summary" ?
													"HR Summary":
													path.replace(/_/g, ' ') === "mbp due statement" ?
													"MBP Due Statement":
													path.replace(/_/g, ' ') === "zbp due statement" ?
													"ZBP Due Statement":
													path.replace(/_/g, ' ') === "bdp due statement" ?
													"BDP Due Statement":
													path.replace(/_/g, ' ') === "bep due statement" ?
													"BEP Due Statement":
													path.replace(/_/g, ' ') === "cms global book add" ?
													"CMS Global Book Add":
													path.replace(/_/g, ' ') === "cms menu config" ?
													"CMS Menu Config":
													path.replace(/_/g, ' ') === "device id replace" ?
													"Device ID Replace":
													path.replace(/_/g, ' ') === "eduman sms transfer" ?
													"Eduman SMS Transfer":
													path.replace(/_/g, ' ') === "eduman yss config" ?
													"Eduman YSS Config":
													path.replace(/_/g, ' ') === "crm current info dashboard" ?
													"CRM Current Info Dashboard":
													path.replace(/_/g, ' ') === "crm token and call info dashboard" ?
													"CRM Token & Call Info Dashboard":
													path.replace(/_/g, ' ') === "crm date wise status info dashboard" ?
													"CRM Date Wise Status Info Dashboard":
													path.replace(/_/g, ' ') === "prepaid disbursement" ?
													"Pre-Paid Disbursement":
													path.replace(/_/g, ' ') === "dfps settlement payment" ?
													"DFPS Settlement Payment":
													path.replace(/_/g, ' ') === "admisia request" ?
													"Admisia Refund Request":
													path.replace(/_/g, ' ') === "deposit balance reports" ?
													"Deposit Balance Reports":
													path.replace(/_/g, ' ') === "withdraw balance reports" ?
													"Withdraw Balance Reports":
													path.replace(/_/g, ' ') === "eduman id approve reports" ?
													"Eduman ID Approve Reports":
													path.replace(/_/g, ' ') === "admin assign point" ?
													"Point Assign for Admin":
													path.replace(/_/g, ' ') === "dfp bank info" ?
													"DFP Bank Info":
													path.replace(/_/g, ' ') === "date wise dfps collection" ?
													"Date Wise DFPS Collection":
													path.replace(/_/g, ' ') === "institute wise dfps collection" ?
													"Institute Wise DFPS Collection":
													path.replace(/_/g, ' ') === "transaction id wise dfps collection" ?
													"Transaction ID Wise DFPS Collection":
													path.replace(/_/g, ' ')
												}
											</li>)
									}
								{/* </li> */}
								{/* <li><a className="p-link" href="/"><i className="pi pi-home" /></a></li>
								{
									location.pathname === '/' ? <li>/</li> : paths.map((path, index) => <li key={index}>{path === '' ? '/' : path.replace(/_/g, ' ')}</li>)
								} */}
							</ul>
						</div>
					</div>
				}
			</div>
		);
	}
}