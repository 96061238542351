import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { ProfileService } from '../../../service/profile/ProfileService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { UserCategoryService } from '../../../service/UserCategoryService';



export class PersonalTrainingInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userTrainingInfoObj: [],
            updateUserObj:{
                employmentStart: '',
                employmentEnd: '',
                countryInfoDTO:{
                    coreCategoryID: ''
                },
                trainingYearInfoDTO: {
                    coreCategoryID: '',
                }
            },
            insertUserObj:{
                countryInfoDTO:{
                    coreCategoryID: ''
                },
                trainingYearInfoDTO: {
                    coreCategoryID: '',
                }
            },
            countryList: [],
            yearList: [],
            visibleDialog: false,
            visibleEditDialog: false,
            errors: {},
        }
        this.CommonFuctionality = new CommonFuctionality();
        this.ProfileService = new ProfileService();
        this.UserCategoryService = new UserCategoryService();
    }

    componentWillMount(){
        this.getUserTrainingInfo();
        this.getValueByDefaultCode('T10201');
        this.getValueByDefaultCode('T141');
    }

    getValueByDefaultCode = (defaultCode) => {
        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if(defaultCode == 'T10201'){
                            this.setState({ countryList: body });
                        }
                        else if(defaultCode == 'T141'){
                            this.setState({ yearList: body });
                        }
                        this.setState({ topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    getUserTrainingInfo = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ProfileService.fetchUserBasicInfo('training')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ userTrainingInfoObj: body, topProgressBar: false, errorMsgVisible: false });
                        // this.getSlipFile(rowData.attachFilePath,rowData.attachFileName);
                    })
                }
                else {
                    this.ProfileService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeDialogHide = () => {
        this.setState({ visibleDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showDialog = () => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visibleDialog: true, errors: {} });
    }

    onChangeEditDialogHide = () => {
        this.setState({ visibleEditDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showEditDialog = (itemData) => {
        // if (itemData.achieveDate == null) {
        //     itemData.achieveDate = '';
        // } 
        // else {
        //     itemData.achieveDate = NetiDateUtils.getAnyShortForm(itemData.achieveDate, 'YYYY-MM-DD');
        // }
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ updateUserObj: {...itemData}, visibleEditDialog: true, errors: {} });
    }

    onChageTranningTitle = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.trainingTitle = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.trainingTitle = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageTopicCovered = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.topicCoveredDetails = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.topicCoveredDetails = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageInstituteName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.instituteName = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.instituteName = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageInstituteLocation = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.instituteLocation = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.instituteLocation = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageCountryName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.countryInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.countryInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageTranningYear = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.trainingYearInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.trainingYearInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageTrainingDuration = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.trainingDuration = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.trainingDuration = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    handleError = (objectHandle) => {
        let { errors } = this.state;
        let formIsValid = true;

        if (!objectHandle.trainingTitle) {
            formIsValid = false;
            errors["trainingTitle"] = "Training title can't left empty.";
        }

        if (!objectHandle.topicCoveredDetails) {
            formIsValid = false;
            errors["topicCoveredDetails"] = "Topic covered details can't left empty.";
        }

        if (!objectHandle.instituteName) {
            formIsValid = false;
            errors["instituteName"] = "Institute name can't left empty.";
        }

        if (!objectHandle.instituteLocation) {
            formIsValid = false;
            errors["instituteLocation"] = "Institute location can't left empty.";
        }

        if (!objectHandle.countryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["countryName"] = "Country name can't left empty.";
        }

        if (!objectHandle.trainingYearInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["trainingYear"] = "Training year can't left empty.";
        }

        if (!objectHandle.trainingDuration) {
            formIsValid = false;
            errors["trainingDuration"] = "Training duration can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onUpdateUserTranningInfo = () => {
        let { updateUserObj } = this.state;
        if (this.handleError(updateUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
            this.ProfileService.updateUserBasicInfo( updateUserObj, 'training')
                .then(res => {
                    if (res.status == 202) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                        this.onChangeEditDialogHide();
                        setTimeout(() => {
                            this.getUserTrainingInfo();
                        }, 1000);
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' })
                });
        }
    }

    onInsertUserTranningInfo = () => {
        let { insertUserObj } = this.state;
        if (this.handleError(insertUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
            this.ProfileService.insertUserBasicInfo( insertUserObj, 'training')
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                        this.onChangeDialogHide();
                        setTimeout(() => {
                            this.getUserTrainingInfo();
                        }, 1000);
                        
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' })
                });
        }
    }

    render() {
        let { userTrainingInfoObj, updateUserObj, insertUserObj, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let uploadFileLabel = <div>Click / Drag Here To Upload</div>

        let countryName = []
        if (this.state.countryList && this.state.countryList.length) {
            countryName = this.state.countryList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let year = []
        if (this.state.yearList && this.state.yearList.length) {
            year = this.state.yearList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
        return (
            <div>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section blur-section">
                    <div className="p-grid">
                        {
                            userTrainingInfoObj.map((item, index) =>
                                <div className="p-col-12 p-md-6">
                                    <div className="personal-box-wrapper m-t-30">
                                        <div className="personal-box-title-wrapper">
                                            <div className="personal-box-title">
                                                <h4>Training - {index + 1}</h4>
                                            </div>
                                            <div className="edit-button">
                                                <Button icon="fas fa-edit" onClick={e => this.showEditDialog(item)} />
                                            </div>
                                        </div>
                                        <div className="personal-box">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Training Title</td>
                                                        <td>{ item.trainingTitle || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Topic Covered</td>
                                                        <td>{ item.topicCoveredDetails || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Major / Group</td>
                                                        <td>---</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Institute</td>
                                                        <td>{ item.instituteName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Location</td>
                                                        <td>{ item.instituteLocation || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Country</td>
                                                        <td>{ item.countryInfoDTO.categoryName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Training Year</td>
                                                        <td>{ item.trainingYearInfoDTO.categoryName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Duration</td>
                                                        <td>{ item.trainingDuration || '---' }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="add-button">
                        <Button
                            icon="fas fa-plus"
                            tooltip="Add Training Info"
                            tooltipOptions={{ position: 'bottom' }}
                            onClick={this.showDialog}
                        />
                    </div>
                </div>
                <div className="dialog-section">
                    <Dialog id="dialog" className="nw-dialog" header="Add Training Information" visible={this.state.visibleDialog} onHide={this.onChangeDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Training Title <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Tranning Title"
                                                    name="trainingTitle"
                                                    value={ insertUserObj.trainingTitle }
                                                    onChange= { e => this.onChageTranningTitle(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['trainingTitle']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Topic Covered<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Topic Covered"
                                                    name="topicCoveredDetails"
                                                    value={ insertUserObj.topicCoveredDetails }
                                                    onChange= { e => this.onChageTopicCovered(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['topicCoveredDetails']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institute<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute name"
                                                    name="instituteName"
                                                    value={ insertUserObj.instituteName }
                                                    onChange= { e => this.onChageInstituteName(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['instituteName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Location <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute Location"
                                                    name="instituteLocation"
                                                    value={ insertUserObj.instituteLocation }
                                                    onChange= { e => this.onChageInstituteLocation(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['instituteLocation']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Country <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Country"
                                                    onChange= { e => this.onChageCountryName(e, 'insert') }
                                                    value={ insertUserObj.countryInfoDTO.coreCategoryID }
                                                    options={countryName}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="countryName"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['countryName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Training Year  <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Year"
                                                    onChange= { e => this.onChageTranningYear(e, 'insert') }
                                                    value={ insertUserObj.trainingYearInfoDTO.coreCategoryID }
                                                    options={year}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="trainingYear"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['trainingYear']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Duration <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute Location"
                                                    name="trainingDuration"
                                                    value={ insertUserObj.trainingDuration }
                                                    onChange= { e => this.onChageTrainingDuration(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['trainingDuration']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={ this.onInsertUserTranningInfo }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog id="dialog" className="nw-dialog" header="Edit Training Information" visible={this.state.visibleEditDialog} onHide={this.onChangeEditDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Training Title <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Tranning Title"
                                                    name="trainingTitle"
                                                    value={ updateUserObj.trainingTitle }
                                                    onChange= { e => this.onChageTranningTitle(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['trainingTitle']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Topic Covered<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Topic Covered"
                                                    name="topicCoveredDetails"
                                                    value={ updateUserObj.topicCoveredDetails }
                                                    onChange= { e => this.onChageTopicCovered(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['topicCoveredDetails']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institute<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute name"
                                                    name="instituteName"
                                                    value={ updateUserObj.instituteName }
                                                    onChange= { e => this.onChageInstituteName(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['instituteName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Location <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute Location"
                                                    name="instituteLocation"
                                                    value={ updateUserObj.instituteLocation }
                                                    onChange= { e => this.onChageInstituteLocation(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['instituteLocation']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Country <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Country"
                                                    onChange= { e => this.onChageCountryName(e, 'update') }
                                                    value={ updateUserObj.countryInfoDTO.coreCategoryID }
                                                    options={countryName}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="countryName"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['countryName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Training Year  <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Year"
                                                    onChange= { e => this.onChageTranningYear(e, 'update') }
                                                    value={ updateUserObj.trainingYearInfoDTO.coreCategoryID }
                                                    options={year}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="trainingYear"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['trainingYear']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Duration <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute Location"
                                                    name="trainingDuration"
                                                    value={ updateUserObj.trainingDuration }
                                                    onChange= { e => this.onChageTrainingDuration(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['trainingDuration']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={ this.onUpdateUserTranningInfo }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>
            </div>
        )
    }
} 