import React, { Component } from 'react';

export class StudentClassConfigInfo extends Component {

    render() {

        return (
            <div className="p-fluid portal-dashboard">

                <div className="layout-dashboard">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-xl-3">
                            <div className="overview-box">
                                {/* <i className="overview-icon fas fa-chalkboard-teacher" /> */}
                                <span className="overview-title">Class</span>
                                <br />
                                {/* <i className="overview-arrow fas fa-chevron-circle-down" /> */}
                                <div className="overview-numbers class-config-text">{this.props.basicInfo.className}</div>
                                {/* <div className="overview-subinfo">
                                21% more than yesterday
						</div> */}
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-12 p-xl-3">
                            <div className="overview-box">
                                <span className="overview-title">Shift</span>
                                <br />
                                <div className="overview-numbers class-config-text">{this.props.basicInfo.shiftName}</div>
                                {/* <div className="overview-subinfo">
                                2% more than yesterday
						</div> */}
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-12 p-xl-3">
                            <div className="overview-box">
                                <span className="overview-title">Section</span>
                                <br />
                                <div className="overview-numbers class-config-text">{this.props.basicInfo.sectionName}</div>
                                {/* <div className="overview-subinfo">
                                7% more than yesterday
						</div> */}
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-12 p-xl-3">
                            <div className="overview-box">
                                <span className="overview-title">Group</span>
                                <br />
                                <div className="overview-numbers class-config-text" >{this.props.basicInfo.groupName}</div>
                                {/*  */}
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
} 