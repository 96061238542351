import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import CommonFuctionality from '../common/CommonFuctionality';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { ProfileService } from '../../service/profile/ProfileService'
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { UserCategoryService } from '../../service/UserCategoryService';
import { NetiFileHandler } from '../../utils/NetiFileHandler';

let cloneStateBeforeMount;
export class EmChangePartner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            emDetailsId: '',
            netiIDError: {},
            btnDisabled: true,
            netiID: '',
            tokenPicInfo: {
                contentPic: '',
            },
            changePartnerDialog: false,
            dataTableIsLoading: false,
            changingPurposeDropdownList: [],
            tokenDetails: '',
            discountNoteView: false,
            topProgressBar: false,
            netiInformation: {
                netiID: '',
                fullName: '',
            },
            discountNote: '',
            billDetailsLogDialog: false,
            tokenTypeID: '',
            errorMsgVisible: false,
            errorMsgBody: null,
            emBillDetailsLogList: [],
        }
        this.commonFuctionality = new CommonFuctionality();
        this.CRMPointService = new CRMPointService();
        this.validatorUtility = new ValidatorUtility();
        this.userCategoryService = new UserCategoryService();
        this.profileService = new ProfileService();
        this.netiFileHandler = new NetiFileHandler();

    }

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    changePartnerDialog = (param) => {
        this.setState({ changePartnerDialog: true });
        this.setState({ emDetailsId: param });
        this.getChangingPurposeList();
        this.commonFuctionality.blurDialogBackgroundActive();
    }

    changePartnerDialogHide = () => {
        this.setState({ changePartnerDialog: false });
        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    getChangingPurposeList = () => {
        this.setState({ topProgressBar: true, errorMsgBody: '', errorMsgVisible: false });
        this.userCategoryService.findByCategoryDefaultCode('C11002028')
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ changingPurposeDropdownList: body });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection.", topProgressBar: false })
            });
    }

    onFileUpload(e) {
        let { errors } = this.state;
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let tokenPic = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ tokenPicInfo: tokenPic });
        }
        errors["attachments"] = "";
        this.setState({ errors });

    }

    removeButtonOnclick = () => {
        this.setState({ tokenPicInfo: { contentPic: '' } });
        this.setState({ tokenPicInfo: { contentName: '' } });

    }

    onChangePurposeList = (e) => {
        let { errors } = this.state;
        errors["purposeList"] = "";
        this.setState({ tokenTypeID: e.target.value, errors });
    }

    onChangeTokenDetails = (e) => {
        let { errors } = this.state;
        errors["tokenDetails"] = "";
        this.setState({ tokenDetails: e.htmlValue, errors });
    }

    netiIDHandler = (e) => {
        let { netiIDError } = this.state;
        netiIDError["netiIDError"] = "";
        this.setState({ netiID: e.target.value, netiIDError });
    }

    netiIDCodeError = () => {
        let { purchaseCode, netiIDError } = this.state;
        let formIsValid = true;
        if (this.state.netiID === '') {
            formIsValid = false;
            netiIDError["netiIDError"] = "Neti ID can't left empty";
        } else {
            netiIDError["netiIDError"] = "";
        }
        this.setState({ netiIDError });
        return formIsValid;
    }

    focusSearch = e => {
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white" });
    }

    blurSearch = e => {
        this.setState({ searchLabel: '', addSearchBtnClass: "" });
    }

    netiIDFocusSearch = e => {
        this.setState({ netiIDLabel: "Search", netiIDBtnClass: "nw-button p-button-primary text-color-white" });
    }

    netiIDBlurSearch = e => {
        this.setState({ netiIDLabel: '', netiIDBtnClass: "" });
    }

    netiIdCheckHandler = (e) => {
        this.setState({ btnDisabled: true, netiInformation: cloneStateBeforeMount.netiInformation, });
        if (this.netiIDCodeError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, secondInputFieldIsLoading: true });
            this.CRMPointService.findPartnerInfoByNeiID(this.state.netiID)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ btnDisabled: false, netiInformation: body, topProgressBar: false, secondInputFieldIsLoading: false, netiIDSearchBtnDisabled: false });
                        });
                    }
                    else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ btnDisabled: true, secondInputFieldIsLoading: false, topProgressBar: false, errorMsgVisible: true, netiIDSearchBtnDisabled: true, secondInputFieldIsLoading: false, errorMsgVisible: true, errorMsgBody: `No partner associated with this "${this.state.netiID}" Neti ID.`});
                            })
                    }
                }).catch(error => {
                    this.setState({ btnDisabled: true, topProgressBar: false, secondInputFieldIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })
                });
        }
    }

    onSubmitUpdate = () => {

        let emPartnerInfo = {
            "userBasicInfoDTO": {
                "customNetiID": this.state.netiID
            },
            "edumanDetailsInfoDTO": {
                "emDetailsId": this.state.emDetailsId
            },
            "supportTokenInfoDTO": {
                "attachContent": this.state.tokenPicInfo.contentPic,
                "attachSaveOrEditable": true,
                "attachmentName": Date.now() + this.netiFileHandler.getImageExtention(this.state.tokenPicInfo.extention),
                "tokenDetails": this.state.tokenDetails,
                "tokenTypeInfoDTO": {
                    "coreCategoryID": this.state.tokenTypeID
                }
            }
        }

        if (this.onSubmitUpdateError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.CRMPointService.updateEmPartner(emPartnerInfo)
                .then(res => {
                    if (res.status === 202) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Updated successfully', life: 800 })
                        setTimeout(() => {
                            this.changePartnerDialogHide();
                        }, 800);

                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        }
    }

    onSubmitUpdateError = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.tokenTypeID === "" || this.state.tokenTypeID === null) {
            formIsValid = false;
            errors["purposeList"] = "Changing Purpose can't left empty.";
        }
        if (this.state.tokenDetails === "") {
            formIsValid = false;
            errors["tokenDetails"] = "Token Details can't left empty.";
        }
        if (this.state.tokenPicInfo.contentPic === "") {
            formIsValid = false;
            errors["attachments"] = "Attachment can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;

    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;


        let purposeList = []
        if (this.state.changingPurposeDropdownList && this.state.changingPurposeDropdownList.length) {
            purposeList = this.state.changingPurposeDropdownList.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        return (
            <>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />

                <Dialog header="Change Owner" visible={this.state.changePartnerDialog} onHide={this.changePartnerDialogHide} className="nw-dialog product-info-dialog admin-point">
                    <div className="p-fluid">
                        <div className="p-grid  nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form-body">


                                    <div className="p-col-12 p-xl-12">
                                        {this.state.packageDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Change purpose</span>
                                                <Dropdown
                                                    placeholder='select purpose'
                                                    value={this.state.tokenTypeID}
                                                    name="purposeList"
                                                    options={purposeList}
                                                    onChange={this.onChangePurposeList}
                                                    filter={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                        }
                                        <span className='error-message'>{this.state.errors["purposeList"]}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtexteditor-label">DETAILS</span>
                                            <Editor
                                                className="nw-inputtexteditor"
                                                value={this.state.tokenDetails}
                                                name="tokenDetails"
                                                onTextChange={this.onChangeTokenDetails}
                                                autoWidth={false}
                                                maxLength={200}
                                                placeholder="Enter token Details"

                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["tokenDetails"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload Attachment <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/PDF)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                {
                                                    this.state.tokenPicInfo.contentPic ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={this.removeButtonOnclick}
                                                                />
                                                                {this.state.tokenPicInfo.contentName ?
                                                                    this.commonFuctionality.getFileContentTypeIcon(this.state.tokenPicInfo.contentName) : ''}

                                                            </div>
                                                            <div className="image-title">{this.state.tokenPicInfo.contentName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            id="attachments"
                                                            name="attachments"
                                                            mode="basic"
                                                            maxFileSize={1000000}
                                                            onSelect={this.onFileUpload.bind(this)}
                                                            auto={true}
                                                            chooseLabel={this.state.tokenPicInfo.contentName ? this.state.tokenPicInfo.contentName : "Click / Drag Here To Upload"}

                                                        />
                                                }

                                            </div>

                                        </div>
                                        <span className='error-message'>{this.state.errors["attachments"]}</span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <br />
                                        <br />
                                        <br />
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">NETI ID <span>*</span></span>
                                            <InputText
                                                value={this.state.netiID}
                                                placeholder="Enter Neti ID"
                                                style={{ width: "15%" }}
                                                type="text"
                                                name="netiID"
                                                onChange={this.netiIDHandler}
                                                keyfilter="num"
                                                onFocus={this.netiIDFocusSearch}
                                                onBlur={this.netiIDBlurSearch}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.netiIDBtnClass}
                                                label={this.state.netiIDLabel}
                                                icon="fas fa-search"
                                                onClick={(e) => this.netiIdCheckHandler(e)}
                                                onFocus={this.netiIDFocusSearch}
                                                onBlur={this.netiIDBlurSearch}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.netiIDError["netiIDError"]}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-search-view no-border p-0">
                                            <div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Name</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{this.state.netiInformation && this.state.netiInformation.userBasicInfoDTO && this.state.netiInformation.userBasicInfoDTO.fullName}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">MOBILE NO.</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{this.state.netiInformation && this.state.netiInformation.userBasicInfoDTO && this.state.netiInformation.userBasicInfoDTO.basicMobile}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">DESIGNATION</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{this.state.netiInformation && this.state.netiInformation.partnerAreaInfoDTO && this.state.netiInformation.partnerAreaInfoDTO.categoryName}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">AREA</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{this.state.netiInformation && this.state.netiInformation.partnerDesignationInfoDTO && this.state.netiInformation.partnerDesignationInfoDTO.categoryName}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-col-12  nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={this.changePartnerDialogHide}
                                        />
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Confirm"
                                            icon="fas fa-check"
                                            disabled={this.state.btnDisabled}
                                            onClick={this.onSubmitUpdate}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>


            </>
        )
    }
}