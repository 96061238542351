import React from "react";
import { WalletBalance } from "../common/WalletBalance";
import bkash from "../../../assets/images/bkash.png";
import bank from "../../../assets/images/bank.png";
import direct from "../../../assets/images/direct.png";
const BlurSection = ({ selectedWallet, selectedHandler }) => {
  return (
    <div className="p-grid nw-form blur-section">
      <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
        <div className="walletContainer__inner__row__left p-col-nogutter seprator">
          <div style={{ paddingRight: "3.5rem" }}>
            <div className="walletContainer__inner__row__left__icons seprator-insidediff">
              <div
                onClick={() => selectedHandler("Bkash")}
                className={`walletContainer__inner__row__left__icons__icon ${
                  selectedWallet === "Bkash" ? "active" : ""
                }`}
              >
                <div className="circle">
                  <img src={bkash} alt="" />
                </div>
                <div className="wallet-title">Existing BKash</div>
              </div>
              <div
                onClick={() => selectedHandler("Bank")}
                className={`walletContainer__inner__row__left__icons__icon ${
                  selectedWallet === "Bank" ? "active" : ""
                }`}
              >
                <div className="circle">
                  {" "}
                  <img src={bank} alt="" />
                </div>
                <div className="wallet-title">Bank or Cheque</div>
              </div>
              <div
                onClick={() => selectedHandler("direct")}
                className={`walletContainer__inner__row__left__icons__icon ${
                  selectedWallet === "direct" ? "active" : ""
                }`}
              >
                <div className="circle">
                  <img src={direct} alt="" />
                </div>
                <div className="wallet-title">Direct Recharge</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WalletBalance walletBalanceName="Wallet" />
    </div>
  );
};

export default BlurSection;
