import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { WalletBalance } from '../common/WalletBalance';
import { WalletBalanceTransferList } from './WalletBalanceTransferList';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Dialog } from 'primereact/dialog';
import { ProfileService } from '../../../service/profile/ProfileService';
import { BalanceService } from '../../../service/myPoint/BalanceService';
import { Growl } from 'primereact/growl';
import NetiContentLoader from '../../common/NetiContentLoader';
import Formatter from '../../../utils/Formatter';


let walletBalance = 0;
let dialogStatus = false;
let cloneStateBeforeMount;
let verificationNumber = '';
export class WalletBalanceTransfer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customNetiID: '',
            receiver: {},
            balanceInfoError: {},
            OTPcode: '',
            balanceTransferInfo: {
                requestedAmmount: '',
                receiver: {
                    netiID: null
                },
                requestNote: '',
            },
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            errors: {},
            checkedRetriveObj: {},
            searchIsLoadingFirst: false,
            searchIsLoadingSecond: false,
            searchView: false,
            searchViewError: false,
            buttonDisabled: true,
            verificationNumber1: '',
            verificationNumber2: '',
            verificationNumber3: '',
            verificationNumber4: '',
            verificationNumber5: '',
            verificationNumber6: '',


        }
        this.profileService = new ProfileService();
        this.balanceService = new BalanceService();
        this.onChangeTransferAmount = this.onChangeTransferAmount.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.formatter = new Formatter();


    }

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    onChangeCustomNetiID = (e) => {
        this.setState({
            customNetiID: e.target.value,
            balanceInfoError: {}
        });
        this.setState({ receiver: {}, searchViewError: false, searchView: false, buttonDisabled: true });
    }

    onChangeTransferAmount(e) {
        let { balanceTransferInfo, balanceInfoError } = this.state;
        balanceInfoError["requestedAmmount"] = "";
        e.target.value = (e.target.value.indexOf(".") >= 0) ? (e.target.value.substr(0, e.target.value.indexOf(".")) + e.target.value.substr(e.target.value.indexOf("."), 3)) : e.target.value;
        balanceTransferInfo[e.target.name] = e.target.value;
        this.setState({ balanceTransferInfo, balanceInfoError });
    }

    onChangeNote = (e) => {
        let { balanceTransferInfo, receiver, balanceInfoError } = this.state;
        balanceTransferInfo[e.target.name] = e.target.value;
        balanceInfoError["requestNote"] = "";
        this.setState({ balanceTransferInfo, balanceInfoError });
    }



    onNextBtn = (e) => {
        this.setState({ checkedRetriveObj: {} });
        verificationNumber = '';
        this.setState({verificationNumber1: '',verificationNumber2: '',verificationNumber3: '',verificationNumber4: '',verificationNumber5: '',verificationNumber6: ''  });
        if (this.transferFormHandleError()) {
            this.setState({
                topProgressBar: true, errorMsgBody: '', errorMsgVisible: false
            })
            let { balanceTransferInfo } = this.state;
            this.balanceService.checkBalanceTransfer(balanceTransferInfo)
                .then((res) => {
                    if (res.status == 200) {
                        this.setState({
                            topProgressBar: false, errorMsgBody: '', errorMsgVisible: false
                        })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully sent OTP code" });
                        return res.json().then((body) => {
                            this.setState({ checkedRetriveObj: body });
                        })
                    } else {
                        this.balanceService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({
                                topProgressBar: false, errorMsgBody: responseBody, errorMsgVisible: true
                            })
                        });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                });

            this.viewDialog(e);
        }
    }

    viewDialog = (e) => {
        this.setState({verificationNumber1: '',verificationNumber2: '',verificationNumber3: '',verificationNumber4: '',verificationNumber5: '',verificationNumber6: ''  });
        verificationNumber = '';
        dialogStatus = true;
        this.setState({ visible: true });

    }

    onHide = (event) => {
        verificationNumber = '';
        this.setState({ visible: false });
        this.setState({verificationNumber1: '',verificationNumber2: '',verificationNumber3: '',verificationNumber4: '',verificationNumber5: '',verificationNumber6: ''  });
    }

    onChangeOTPcode = (e) => {
        this.setState({
            OTPcode: e.target.value
        });
    }

    handleErrorNetiId = () => {
        let { balanceInfoError } = this.state;
        let formIsValid = true;

        if (this.state.customNetiID === '') {
            formIsValid = false;
            balanceInfoError["customNetiID"] = "Custom neti ID can't left empty.";
            this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
        }

        this.setState({ balanceInfoError });
        return formIsValid;
    }

    onChangeSearchByCustomNetiID = (e, props) => {
        if (this.handleErrorNetiId()) {
            this.setState({ topProgressBar: true, searchIsLoadingFirst: true, searchIsLoadingSecond: true, errorMsgVisible: false, searchView: true, searchViewError: false });
            let { balanceTransferInfo, balanceInfoError } = this.state;
            this.profileService.findBasicInfoByCustomNetiID(this.state.customNetiID)
                .then((res) => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            let userProfile = JSON.parse(localStorage.getItem('Profile'));
                            if (body.customNetiID === userProfile.customNetiID) {
                                balanceInfoError["customNetiID"] = "You can't transfer balance to your own account";
                                this.setState({ buttonDisabled: true, balanceInfoError })
                                balanceTransferInfo.receiver.netiID = body.netiID;
                                this.setState({
                                    receiver: body, balanceTransferInfo,
                                    errorMsgVisible: false,
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false,
                                    searchViewError: false
                                });
                            } else {
                                balanceTransferInfo.receiver.netiID = body.netiID;
                                this.setState({
                                    receiver: body, balanceTransferInfo,
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false, errorMsgVisible: false,
                                    searchViewError: false
                                });

                                this.setState({ buttonDisabled: false })
                            }

                        });
                    } else {
                        this.profileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({
                                    receiver: {},
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false, errorMsgBody: responseBody, errorMsgVisible: true,
                                    searchViewError: true
                                })
                                this.setState({ buttonDisabled: true })

                            });
                    }
                }).catch(error => {
                    this.setState({
                        topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!",
                        searchViewError: false, searchView: false
                    })
                });
        }
    }

    submitBalanceTransfer = (e) => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        let { balanceTransferInfo } = this.state;
        this.balanceService.userBalanceTransfer(balanceTransferInfo)
            .then((res) => {
                if (res.status == 202) {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    this.setState({
                        customNetiID: '',
                        searchView: false,
                        receiver: {},
                        balanceTransferInfo: {
                            requestedAmmount: '',
                            receiver: {
                                netiID: null
                            },
                            requestNote: '',
                        }
                    });
                    this.setState({verificationNumber1: '',verificationNumber2: '',verificationNumber3: '',verificationNumber4: '',verificationNumber5: '',verificationNumber6: ''  });
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully submitted' });
                    setTimeout(() => {
                        this.onHide(e);
                    }, 1000);
                } else {
                    this.balanceService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({topProgressBar: false, errorMsgVisible: true,dataTableIsLoading: false, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true,dataTableIsLoading: false, errorMsgBody: "Please check your connection !!!" })
            });

    }


    onTransferBtn = (e) => {
        verificationNumber = '' ;
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        verificationNumber = this.state.verificationNumber1 + this.state.verificationNumber2 + this.state.verificationNumber3 + this.state.verificationNumber4 + this.state.verificationNumber5 + this.state.verificationNumber6;

        this.profileService.checkOPTCode(verificationNumber)
            .then((res) => {
                if (res.status == 200) {
                    this.submitBalanceTransfer(e);
                    verificationNumber = ''
                    this.setState({ topProgressBar: false,  errorMsgVisible: false });
                    this.setState({verificationNumber1: '',verificationNumber2: '',verificationNumber3: '',verificationNumber4: '',verificationNumber5: '',verificationNumber6: ''  });
                } else {
                    this.profileService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false ,errorMsgVisible: true, errorMsgBody: responseBody });
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });

    }

    reSendOtp = () => {
        this.onNextBtn();
    }

    transferFormHandleError = () => {
        let { balanceInfoError } = this.state;
        let formIsValid = true;

        if (this.state.customNetiID === '') {
            formIsValid = false;
            balanceInfoError["customNetiID"] = "To Neti ID can't left empty.";
        }
        if (this.state.balanceTransferInfo.requestedAmmount === '' || this.state.balanceTransferInfo.requestedAmmount == 0) {
            formIsValid = false;
            balanceInfoError["requestedAmmount"] = "Transfer Amount can't left empty.";
        } else if (this.state.balanceTransferInfo.requestedAmmount > walletBalance) {
            formIsValid = false;
            balanceInfoError["requestedAmmount"] = "Insufficient Wallet Balance";
        }
        if (this.state.balanceTransferInfo.requestNote === '') {
            formIsValid = false;
            balanceInfoError["requestNote"] = "Note can't left empty.";
        }
        this.setState({ balanceInfoError });
        return formIsValid;
    }

    focusSearch = e => {
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white" });
    }

    blurSearch = e => {
        this.setState({ searchLabel: '', addSearchBtnClass: "" });
    }

    verificationNumber1 = (e) => {
        this.setState({ verificationNumber1: e.target.value });
        if (e.target.value < 10) {
            e.preventDefault();
            // let next = this.refs[field.verify_code_1].nextSibling;
            // if (next && next.tagName === "INPUT") {
            //   this.refs[field.verify_code_1].nextSibling.focus();
            // }
        }

    }
    verificationNumber2 = (e) => {
        this.setState({ verificationNumber2: e.target.value });
    }
    verificationNumber3 = (e) => {
        this.setState({ verificationNumber3: e.target.value });
    }
    verificationNumber4 = (e) => {
        this.setState({ verificationNumber4: e.target.value });
    }
    verificationNumber5 = (e) => {
        this.setState({ verificationNumber5: e.target.value });
    }
    verificationNumber6 = (e) => {
        this.setState({ verificationNumber6: e.target.value });
    }

    focusInputValue = (e, inputClassName) => {
        var container = document.getElementsByClassName(inputClassName)[0];


        // container.onkeyup = function(e) {
        console.log("focus info", e.target.value);

        console.log("threeDigit1", this.state.threeDigit1);
        console.log("threeDigit2", this.state.threeDigit2);
        console.log("threeDigit3", this.state.threeDigit3);

        var target = e.srcElement || e.target;
        var maxLength = parseInt(target.attributes["maxlength"].value, 10);
        var myLength = target.value.length;
        console.log("myLength", myLength);

        if (myLength >= maxLength) {
            var next = target;
            while (next = next.nextElementSibling) {
                if (next == null)
                    break;
                if (next.tagName.toLowerCase() === "input") {
                    next.focus();
                    break;
                }
            }
        }
        // Move to previous field if empty (user pressed backspace)

        else if (myLength === 0) {
            let { threeDigit1, threeDigit2, threeDigit3 } = this.state
            if (threeDigit1 === '' || threeDigit2 === '' || threeDigit3 === '') {
                this.setState({ sendOtpCheck: true })
            }
            var previous = target;
            while (previous = previous.previousElementSibling) {
                if (previous == null)
                    break;
                if (previous.tagName.toLowerCase() === "input") {
                    previous.focus();
                    break;
                }
            }
        }
        // }
    }



    onSendOtpValid = () => {
        if (this.handleOtpcodeSearch()) {
            this.setState({ topProgressBar:true })
            let verNumber = this.state.verificationNumber1 + this.state.verificationNumber2 + this.state.verificationNumber3 + this.state.verificationNumber4 + this.state.verificationNumber5 + this.state.verificationNumber6;
            let verifycode = parseInt(verNumber);
            this.GuestPointService.checkOTP(verNumber)
                .then(res => {
                    if (res.status == 200) {
                        return res.json().then((body) => {
                            this.setState({ notValidOtp: "" });
                            this.setState({ thirdPage: false });
                            this.setState({ fourthPage: true });
                            this.setState({ topProgressBar:false })
                        });
                    } else {
                        return res.json().then((body) => {
                            this.setState({ notValidOtp: "Invalid Verification Code" });
                            this.setState({ topProgressBar:false })
                        });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar:false })
                });
        }

    };



    render() {
    


        let { receiver, topProgressBar, errorMsgVisible, errorMsgBody, } = this.state;
        walletBalance = this.refs.walletBalance && this.refs.walletBalance.state && this.refs.walletBalance.state.userWalletBalance && this.refs.walletBalance.state.userWalletBalance.walletBalance;

        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid nw-form ">
                    <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                        <Growl ref={(el) => this.growl = el} />
                        <div className='nw-form-body'>
                            <div className="p-grid seprator-inside">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">To Neti ID <span>*</span></span>
                                            <InputText
                                                value={this.state.customNetiID}
                                                onChange={this.onChangeCustomNetiID}
                                                placeholder="Enter Transfer Neti ID"
                                                name="customNetiID"
                                                showClear={true}
                                                style={{ width: "15%" }}
                                                type="text"
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                label={this.state.searchLabel}
                                                icon="fas fa-search"
                                                onClick={(e) => this.onChangeSearchByCustomNetiID(e, this.props)}
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.balanceInfoError["customNetiID"]}</span>
                                    </div>

                                    {this.state.searchView ?
                                        <div className="p-col-12 p-xl-12">

                                            <div className="nw-search-view">

                                                {this.state.searchViewError ?
                                                    <center className="error-message">{this.state.errorMsgBody || "No Data Found"}</center> :
                                                    <div>
                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.searchIsLoadingFirst ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {receiver.fullName}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.searchIsLoadingSecond ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Mobile No</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {receiver.basicMobile ? this.formatter.getMaskedNumber(receiver.basicMobile) : ''}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>



                                        </div>
                                        :
                                        ''
                                    }


                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Transfer Amount <span>*</span></span>
                                            <InputText
                                                onChange={this.onChangeTransferAmount}
                                                value={this.state.balanceTransferInfo.requestedAmmount}
                                                keyfilter="num"
                                                placeholder="Enter Transfer Amount"
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="requestedAmmount"

                                            />
                                        </div>
                                        <span className="error-message">{this.state.balanceInfoError["requestedAmmount"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                            <InputTextarea
                                                value={this.state.balanceTransferInfo.requestNote}
                                                onChange={(e) => this.onChangeNote(e)}
                                                placeholder="Enter Transfer Note"
                                                rows={3}
                                                cols={30}
                                                name="requestNote"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.balanceInfoError["requestNote"]}</span>

                                    </div>

                                    <div className="p-col-12">

                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            className="nw-button nw-button-right p-button-primary"
                                            label="Next"
                                            icon="fas fa-arrow-right"
                                            iconPos="right"
                                            onClick={(e) => this.onNextBtn(e, this.props)}
                                            disabled={this.state.buttonDisabled}
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-4">
                        {this.state.dataTableIsLoading ? "" :
                            <WalletBalance
                                walletBalanceName='Wallet'
                                ref="walletBalance"
                            />
                        }
                    </div>

                    <div className="p-col-12">
                        {this.state.dataTableIsLoading ? "" :
                            <WalletBalanceTransferList />
                        }
                    </div>

                    <Dialog className="nw-dialog" header="OTP Verification" visible={this.state.visible} onHide={this.onHide} maximizable>
                        <div className="nw-global-background otp-layout"  >
                            <div className="nw-layout-content">
                                <Growl ref={(el) => this.growl = el} />
                                <div className="global-login-form text-center">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-12">
                                                        <p>A 6 Digit Verification Code has been sent to this number: {this.state.checkedRetriveObj.contactNo} This code is active for next 1 minute. </p>
                                                        <span className="p-float-label">
                                                            <h3>VERIFICATION CODE</h3>
                                                            <div className="verify-code" id="verify-code">
                                                                <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} value={this.state.verificationNumber1} onChange={this.verificationNumber1} maxLength="1" name="verify_code_1" ref="verify_code_1" id="in" />
                                                                <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} value={this.state.verificationNumber2} onChange={(e) => this.verificationNumber2(e)} maxLength="1" name="verify_code_2" ref="verify_code_2" id="in" />
                                                                <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} value={this.state.verificationNumber3} onChange={(e) => this.verificationNumber3(e)} maxLength="1" name="verify_code_3" ref="verify_code_3" id="in" />
                                                                <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} value={this.state.verificationNumber4} onChange={(e) => this.verificationNumber4(e)} maxLength="1" name="verify_code_4" ref="verify_code_4" id="in" />
                                                                <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} value={this.state.verificationNumber5} onChange={(e) => this.verificationNumber5(e)} maxLength="1" name="verify_code_5" ref="verify_code_5" id="in" />
                                                                <InputText onKeyUp={(e) => this.focusInputValue(e, "verify-code")} value={this.state.verificationNumber6} onChange={(e) => this.verificationNumber6(e)} maxLength="1" name="verify_code_6" ref="verify_code_6" id="in" />
                                                            </div>
                                                        </span>
                                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                                        <Button
                                                            // className="custom-login-button gradiant-btn box-shadow content-center"
                                                            className="nw-button nw-button-center p-button-primary content-center"

                                                            label="Submit"
                                                            // disabled={this.state.otpCodeValid}
                                                            icon="fas fa-arrow-right"
                                                            onClick={(e) => this.onTransferBtn(e)}
                                                            // onClick={this.onSendOtpValid}
                                                        />
                                                         </div>
                                                        <div className="resend-code">
                                                            I didn't get any code <button type="button" onClick={this.reSendOtp}>Resend</button>
                                                        </div>
                                                        <br />
                                                        <span className="error-message">
                                                            {/* {this.state.otpCodeSearchErrors["otpCode"]} */}
                                                        </span>
                                                        <span className="error-message">
                                                            {/* {this.state.notValidOtp} */}
                                                        </span>`
                                <br />

                                                        <br />
                                                    </div>
                                                </div>

                                  
                                
                                </div>

                            </div>
                        </div>


                        {/* <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-12 text-center">
                                    <span>A 6-digit verification code has been sent to '{this.state.checkedRetriveObj.contactNo}' contact number.</span>
                                </div>
                                <div className="p-col-12 p-md-12 text-center">
                                    <span>OTP Code : </span>
                                    <InputText
                                        onChange={(e) => this.onChangeOTPcode(e)}
                                        value={this.state.OTPcode}
                                        placeholder="Enter OTP Code"
                                        style={{ width: "50%" }}
                                        type="text"
                                    />
                                </div>
                                <div className="p-col-12 p-md-4"></div>
                                <div className="p-col-12 p-md-4 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Transfer"
                                        onClick={(e) => this.onTransferBtn(e)}
                                    />
                                </div>
                                <div className="p-col-12 p-md-4"></div>
                            </div>
                        </div> */}

                    </Dialog>


                </div>
            </div>
        );
    }
}