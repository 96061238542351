import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { SocialLinkFooter } from "./SocialLinkFooter";
import { DwsService } from "../../../service/dwsPoint/DwsService";
import { ProfileService } from "../../../service/profile/ProfileService";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { FileUpload } from "primereact/fileupload";
import { NetiFileHandler } from "../../../utils/NetiFileHandler";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
import { UserCoreUrlInfoService } from "../../../service/UserCoreUrlInfoService";
import { ProgressBar } from "primereact/progressbar";
import { NetiCMSSetupProgress } from "../common/NetiCMSSetupProgress";
import { AdminViewInstitute } from "./AdminViewInstitute";

let errors = {};
let roleAssignId = null;
const languageList = [
  { label: "English", value: "English" },
  { label: "Bangla", value: "Bangla" },
];
export class AdminDwsHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proPic: {
        contentName: "",
      },
      institutePicture: "",
      urlValue: "",
      dwsUrlInfo: {
        // applicationType: "dws",
        // applicationPackage: "dws",
        applicationTheme: "rebirth",
        defaultLanguage: "English",
        instituteName: "",
        instituteAddress: "",
        instituteContact: "",
        instituteEmail: "",
        urlName: "",
        facebookLink: "",
        twitterLink: "",
        linkedinLink: "",
        youtubeLink: "",
        logoName: "",
        logoPath: "",
        logoContent: "",
        logoSaveOrEditable: false,
        userRoleAssignId: 0,
      },
      instituteInfo: {
        cmsId: 0,
        urlName: "",
        logoName: "",
        instituteName: "",
        instituteAddress: "",
        instituteContact: "",
        instituteEmail: "",

        mappedStatus: 0,
        cmsCreateDate: "",
        logoContent: "",
        // "storePasswd": '',
        // urlInfoID: '',
        // defaultLanguage: '',
        // instituteName: '',
        // instituteAddress: '',
        // instituteContact: '',
        // instituteEmail: '',
        // urlName: '',
        // urlCreateDate: '',
        // imageContent: ''
      },
      dwsThemeName: {
        urlTheme: "",
      },
      importantLinkInfo: {
        linkTitle: "Admisia Link",
        linkUrl: "",
        urlInfoDTO: {
          urlInfoID: "",
        },
      },
      checkEligibility: false,
      errorMsgVisible: false,
      errorMsgBody: "",
      errors: {},
      linkSubmitError: [],
      instituteInformation: {
        defaultLanguage: "",
      },
      progressbarStartValue: 55,
    };
    this.netiFileHandler = new NetiFileHandler();
    this.dwsService = new DwsService();
    this.validatorUtility = new ValidatorUtility();
    this.profileService = new ProfileService();
    this.userCoreUrlInfoService = new UserCoreUrlInfoService();
    this.onBasicUploadAuto = this.onBasicUploadAuto.bind(this);
    this.reloadCoreUrlIdFromLocal();
  }

  async reloadCoreUrlIdFromLocal() {
    let { dwsUrlInfo } = this.state;
    await this.userCoreUrlInfoService.fetchUserRoleAssignId().then((res) => {
      // console.log('roleAssignId_res', res);
      if (res.status == 302) {
        return res.json().then((body) => {
          // console.log('roleAssignId_body', body);
          dwsUrlInfo.userRoleAssignId = body.roleAssignId;

          this.getDwsInstituteDetails(body.roleAssignId);

          // return this.getUrlId(url, header, bodyData)
        });
      }
    });

    // urlId = await this.DwsService.getCmsIdFromLocalStorage();
    // console.log("urlId .>", urlId);

    // if (typeof urlId === 'string' || typeof urlId === 'number') {
    //     let {insertUserObj, updateUserObj } = this.state
    //     insertUserObj.cmsId = urlId;
    //     updateUserObj.cmsId = urlId;
    //     this.setState({homeReturnButton: false, insertUserObj, updateUserObj })

    //     this.viewSpeechInfoList();
    // }
    // else {
    //     this.setState({ homeReturnButton: true })
    // }
  }

  // reloadCoreUrlIdFromLocal() {
  //     roleAssignId = this.UserCoreUrlInfoService.fetchUserRoleAssignId();
  //     if (!roleAssignId) {
  //         setTimeout(() => {
  //             this.componentWillMount();
  //         }, 2000);
  //     }
  // }

  // componentDidMount() {
  //     this.getDwsInstituteDetails();

  //     // this.checkAdmisiaUrlAndTitle();
  // }

  getDwsInstituteDetails = (userRoleAssignId) => {
    this.setState({
      checkEligibility: false,
      dataTableIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });
    this.userCoreUrlInfoService
      .fetchNetiCmsInstituteDetails(userRoleAssignId)
      .then((res) => {
        // console.log("instituteInfo RES", res);
        if (res.status == 200) {
          return res.json().then((body) => {
            if (body.messageType == 1) {
              // console.log("instituteInfo", body);

              this.setState({ instituteInfo: body.item });
              this.setState({ checkEligibility: true });
              this.setState({
                dataTableIsLoading: false,
                topProgressBar: false,
                errorMsgVisible: false,
              });

              // this.checkAdmisiaUrlAndTitle();
            } else {
              this.setState({ checkEligibility: false });
              this.setState({
                dataTableIsLoading: false,
                topProgressBar: false,
                errorMsgVisible: false,
              });
            }
          });
        } else if (res.status == 404) {
          return res.json().then((body) => {
            this.setState({ checkEligibility: false });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.dwsService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  };

  handleUrlError() {
    errors = {};
    let formIsValid = true;

    if (this.state.dwsUrlInfo.urlName === "") {
      formIsValid = false;
      this.setState({
        urlMsgView: "text-danger",
        urlErrorMsgBody: "Please fill url ",
      });
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  onChangeCheckDwsUrl = () => {
    if (this.handleUrlError()) {
      this.setState({
        dataTableIsLoading: true,
        topProgressBar: true,
        errorMsgVisible: false,
      });

      this.userCoreUrlInfoService
        .checkExistanceOfNetiCMSUrl(this.state.dwsUrlInfo.urlName)
        .then((res) => {
          return res.json().then((body) => {
            if (body == true) {
              this.setState({
                urlMsgView: "text-danger",
                urlErrorMsgBody:
                  "This URL is unavailable. Please try another one.",
              });
              this.setState({
                dataTableIsLoading: false,
                topProgressBar: false,
                errorMsgVisible: false,
              });
            } else {
              this.setState({
                urlMsgView: "text-success",
                urlErrorMsgBody: "This URL is available.",
              });
              this.setState({
                dataTableIsLoading: false,
                topProgressBar: false,
                errorMsgVisible: false,
              });
            }
          });
        })
        .catch((error) => {
          this.setState({
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection",
          });
        });
    }
  };

  onChangeInstituteName = (e) => {
    let { dwsUrlInfo, errors } = this.state;
    errors["instituteName"] = "";
    dwsUrlInfo.instituteName = e.target.value;
    this.setState({ dwsUrlInfo, errors });
  };

  onChangeInstituteAddress = (e) => {
    let { dwsUrlInfo, errors } = this.state;
    errors["instituteAddress"] = "";
    dwsUrlInfo.instituteAddress = e.target.value;
    this.setState({ dwsUrlInfo, errors });
  };

  onChangeInstituteContact = (e) => {
    let { dwsUrlInfo, errors } = this.state;
    errors["instituteContact"] = "";
    // if(e.target.value.match(/^[0-9]{11}$/)) {
    dwsUrlInfo.instituteContact = e.target.value;
    this.setState({ dwsUrlInfo, errors });
    // }
  };

  onChangeInstituteEmail = (e) => {
    let { dwsUrlInfo, errors } = this.state;
    errors["instituteEmail"] = "";
    dwsUrlInfo.instituteEmail = e.target.value;
    this.setState({ dwsUrlInfo, errors });
  };

  onChangeUrlName = (e) => {
    let { dwsUrlInfo, errors } = this.state;
    errors["instituteUrlName"] = "";
    dwsUrlInfo.urlName = e.target.value;
    this.setState({ dwsUrlInfo, urlErrorMsgBody: "", errors });
  };

  onChangeFacebookLink = (e) => {
    debugger;
    let { dwsUrlInfo, errors } = this.state;
    dwsUrlInfo.facebookLink = e.target.value;
    this.setState({ dwsUrlInfo, urlErrorMsgBody: "", errors });
  };
  onChangeTwitterLink = (e) => {
    let { dwsUrlInfo, errors } = this.state;
    dwsUrlInfo.twitterLink = e.target.value;
    this.setState({ dwsUrlInfo, urlErrorMsgBody: "", errors });
  };
  onChangeLinkedinLink = (e) => {
    let { dwsUrlInfo, errors } = this.state;
    dwsUrlInfo.linkedinLink = e.target.value;
    this.setState({ dwsUrlInfo, urlErrorMsgBody: "", errors });
  };
  onChangeYoutubeLink = (e) => {
    let { dwsUrlInfo, errors } = this.state;
    dwsUrlInfo.youtubeLink = e.target.value;
    this.setState({ dwsUrlInfo, urlErrorMsgBody: "", errors });
  };

  onChangeDwsTheme = (e) => {
    let { dwsThemeName, errors } = this.state;
    errors["instituteDwsTheme"] = "";
    dwsThemeName.urlTheme = e.value;
    this.setState({ dwsThemeName, errors });
    this.onUpdateDefaultTheme(e.value);
  };

  handleError() {
    let { dwsUrlInfo, errors } = this.state;

    let formIsValid = true;
    this.setState({ topProgressBar: false });

    if (dwsUrlInfo.instituteName === "") {
      formIsValid = false;
      errors["instituteName"] = "Institute Name can't left empty.";
    }
    if (dwsUrlInfo.instituteAddress === "") {
      formIsValid = false;
      errors["instituteAddress"] = "Address can't left empty.";
    }
    if (
      dwsUrlInfo.instituteContact === "" ||
      dwsUrlInfo.instituteContact.length == 0
    ) {
      formIsValid = false;
      errors["instituteContact"] = "Mobile No. can't left empty.";
    }

    if (
      dwsUrlInfo.instituteContact.length > 0 &&
      dwsUrlInfo.instituteContact.length < 11
    ) {
      formIsValid = false;
      errors["instituteContact"] = "Invalid Mobile No.";
    }

    if (dwsUrlInfo.instituteEmail === "") {
      formIsValid = false;
      errors["instituteEmail"] = "E-mail can't left empty.";
    }
    if (dwsUrlInfo.urlName === "") {
      formIsValid = false;
      errors["urlName"] = "Please fill up URL.";
    }
    if (dwsUrlInfo.logoSaveOrEditable === false) {
      formIsValid = false;
      errors["logo"] = "Logo can't left empty.";
    }

    this.setState({ errors });
    return formIsValid;
  }

  onSubmitUrlInfo = () => {
    debugger;
    let { errors } = this.state;
    this.setState({
      dataTableIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });

    if (this.handleError()) {
      let { dwsUrlInfo } = this.state;

      if (dwsUrlInfo.logoSaveOrEditable === true) {
        dwsUrlInfo.logoContent = this.state.proPic.contentPic;
        // let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
        // dwsUrlInfo.logoName = dwsUrlInfo.urlName + extention;
        // dwsUrlInfo.logoName = 'cms_institute_photo_' + urlId + '_' + Date.now() + extention;
        dwsUrlInfo.logoName = this.state.proPic.contentName;
      }
      if (
        this.validatorUtility.emailIsValid(dwsUrlInfo.instituteEmail) === true
      ) {
        const facebookUrl = dwsUrlInfo.facebookLink;
        const facebookNewUrl = facebookUrl
          .replace("https://", "")
          .replace("http://", "");
        dwsUrlInfo.facebookLink = "https://" + facebookNewUrl;

        const twitterUrl = dwsUrlInfo.twitterLink;
        const twitterNewUrl = twitterUrl
          .replace("https://", "")
          .replace("http://", "");
        dwsUrlInfo.twitterLink = "https://" + twitterNewUrl;

        const youtubeUrl = dwsUrlInfo.youtubeLink;
        const youtubeNewUrl = youtubeUrl
          .replace("https://", "")
          .replace("http://", "");
        dwsUrlInfo.youtubeLink = "https://" + youtubeNewUrl;

        const linkedinUrl = dwsUrlInfo.linkedinLink;
        const linkedinNewUrl = linkedinUrl
          .replace("https://", "")
          .replace("http://", "");
        dwsUrlInfo.linkedinLink = "https://" + linkedinNewUrl;

        this.setState({ dwsUrlInfo });
        this.userCoreUrlInfoService
          .adminCreateNewUrlInfo(dwsUrlInfo)
          .then((res) => {
            if (res.status == 201) {
              return res.json().then((body) => {
                this.growl.show({
                  severity: "success",
                  summary: "Success Message",
                  detail: "Successfully Created!",
                });
                this.setState({
                  dataTableIsLoading: false,
                  topProgressBar: false,
                  errorMsgVisible: false,
                });
                this.reloadCoreUrlIdFromLocal();
              });
            } else {
              this.dwsService.Auth.handleErrorStatus(res).then((resp) => {
                this.setState({
                  topProgressBar: false,
                  errorMsgVisible: true,
                  errorMsgBody: resp,
                });
              });
            }
          })
          .catch((error) => {
            this.setState({
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: "Please check your connection",
            });
          });
      } else {
        errors["instituteEmail"] = "Invalid Email format.";
        this.setState({ errors });
      }
    }
  };

  onBasicUploadAuto(e) {
    let { dwsUrlInfo } = this.state;
    errors = {};

    var reader = new FileReader();
    let photo = e.files[0];
    const scope = this;
    var urlStr;

    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = "data:" + photo.type + ";base64,"; //link will be same from the word webapps in URL
      urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      let album = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name,
      };
      scope.setState({ proPic: album });
      dwsUrlInfo.logoSaveOrEditable = true;

      errors["logo"] = "";
    };
    // this.growl.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Auto Mode' });
  }

  linkUrlHandler = (event, props) => {
    let { importantLinkInfo, linkSubmitError } = this.state;
    linkSubmitError["linkUrl"] = "";
    importantLinkInfo.linkUrl = event.target.value;
    this.setState({ importantLinkInfo, linkSubmitError });
  };

  errorHandler() {
    let { importantLinkInfo, linkSubmitError } = this.state;
    let formIsValid = true;

    if (importantLinkInfo.linkUrl === "") {
      formIsValid = false;
      linkSubmitError["linkUrl"] = "Link URL can't left empty";
    }
    this.setState({ linkSubmitError });
    return formIsValid;
  }

  checkAdmisiaUrlAndTitle() {
    let { linkSubmitError, importantLinkInfo } = this.state;

    let setUrl = {
      urlInfoID: this.state.importantLinkInfo.urlInfoDTO.urlInfoID,
    };
    // if (this.errorHandler()) {
    this.setState({
      topProgressBar: true,
      dataTableIsLoading: true,
      errorMsgVisible: false,
    });
    this.dwsService
      .checkAdmisiaUrl(this.state.importantLinkInfo.linkTitle, setUrl)
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            // console.log("body", body);

            importantLinkInfo.linkUrl = body.linkUrl;

            // linkSubmitError["linkUrl"] = "Institute Already Linked Up";

            this.setState({
              linkUpInfo: "Institute Already Linked Up",
              importantLinkInfo,
              checkUrlAndTitle: false,
              linkSubmitError,
              value: false,
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else if (res.status == 404) {
          linkSubmitError["linkUrl"] = "";

          // this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfuly Saved' });
          this.setState({
            checkUrlAndTitle: true,
            linkSubmitError,
            value: true,
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
          });
        } else {
          this.dwsService.Auth.handleErrorStatus(res).then((responseBody) => {
            linkSubmitError["linkUrl"] = "";
            this.setState({
              checkUrlAndTitle: true,
              linkSubmitError,
              topProgressBar: false,
              dataTableIsLoading: false,
              errorMsgVisible: true,
              errorMsgBody: responseBody,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
    // }
  }

  addAdmisiaUrlLink = () => {
    if (this.errorHandler()) {
      // this.checkAdmisiaUrlAndTitle()

      // if(this.state.checkUrlAndTitle){
      this.setState({
        topProgressBar: true,
        dataTableIsLoading: true,
        errorMsgVisible: false,
      });
      this.dwsService
        .createNewLink(this.state.importantLinkInfo)
        .then((res) => {
          // console.log("addAdmisiaUrlLinkRES", res);

          if (res.status == 201) {
            this.setState({
              topProgressBar: false,
              dataTableIsLoading: false,
              value: true,
            });
            this.growl.show({
              severity: "success",
              summary: "Success Message",
              detail: "Link added successfully",
            });
          } else {
            this.dwsService.Auth.handleErrorStatus(res).then((responseBody) => {
              this.setState({
                topProgressBar: false,
                dataTableIsLoading: false,
                errorMsgVisible: true,
                errorMsgBody: responseBody,
              });
            });
          }
        })
        .catch((error) => {
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection",
          });
        });
      // }
    }
  };

  // onChangeDefaultLanguage = (e) =>{
  //     let { instituteInformation } = this.state;
  //     this.setState({ defaultLanguageValue: e.target.value})
  //     Object.assign(instituteInformation, this.state.instituteInfo)
  //     instituteInformation.defaultLanguage = e.target.value;
  //     this.setState({instituteInformation });
  //     this.onUpdateDefaultLanguage()
  // }

  // onUpdateDefaultLanguage = () => {
  //     let {instituteInformation} = this.state;
  //     this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

  //     this.userCoreUrlInfoService.updateInstituteInfo(instituteInformation)
  //     .then(res=>{
  //         if(res.status == 202){
  //             return res.json().then((body) => {
  //                 this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
  //                 this.growl.show({severity: 'success', summary: 'Success', detail: 'Successfully submitted'});
  //             })
  //         }
  //         if(res.status == 404){
  //             this.userCoreUrlInfoService.Auth.handleErrorStatus(res)
  //             .then((resp)=>{
  //                 this.setState({checkEligibility: false});
  //                 this.setState({dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please insert your institute details"})
  //             });
  //         }
  //         else {
  //             this.userCoreUrlInfoService.Auth.handleErrorStatus(res)
  //             .then((resp)=>{
  //                 this.setState({dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
  //             });
  //         }

  //     }).catch(error => {
  //         console.log(error);
  //         this.setState({topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
  //     })
  // }

  reloadInstituteInfo = () => {
    // console.log("reload");
    // window.location.reload(true);

    this.getDwsInstituteDetails(this.state.dwsUrlInfo.userRoleAssignId);
  };

  render() {
    let {
      dwsUrlInfo,
      topProgressBar,
      errorMsgVisible,
      errorMsgBody,
      errors,
    } = this.state;

    let uploadFileLabel = (
      <div>
        Click / Drag Image Here To Upload <br />
        <span className="upload-file-label">370 x 240 px (recommended)</span>
      </div>
    );

    let themeColor = [
      { label: "Absolution", value: "absolution" },
      { label: "Bliss", value: "bliss" },
      { label: "Clarity", value: "clarity" },
      { label: "Comfort", value: "comfort" },
      { label: "Eternity", value: "eternity" },
      { label: "Euclid", value: "euclid" },
      { label: "Faith", value: "faith" },
      { label: "Concord", value: "concord" },
      { label: "Dulce", value: "dulce" },
      { label: "Dusk", value: "dusk" },
      { label: "Elegance", value: "elegance" },
      { label: "Esprit", value: "esprit" },
      { label: "Essence", value: "essence" },
      { label: "Fate", value: "fate" },
      { label: "Grace", value: "grace" },
      { label: "Hazel", value: "hazel" },
      { label: "Honor", value: "honor" },
      { label: "Hope", value: "hope" },
      { label: "Infinity", value: "infinity" },
      { label: "Joy", value: "joy" },
      { label: "Merit", value: "merit" },
      { label: "Navy", value: "navy" },
      { label: "Purity", value: "purity" },
      { label: "Rebel", value: "rebel" },
      { label: "Rebirth", value: "rebirth" },
      { label: "Royal", value: "royal" },
      { label: "Ruby", value: "ruby" },
      { label: "Solace", value: "solace" },
      { label: "Tranquil", value: "tranquil" },
      { label: "Valor", value: "valor" },
      { label: "Vanity", value: "vanity" },
      { label: "Violet", value: "violet" },
    ];

    return (
      <div className="p-fluid">
        <NetiCMSSetupProgress />

        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}

        <Growl ref={(el) => (this.growl = el)} />

        <div className="accrodion-section">
          <Accordion
            activeIndex={this.state.activeIndex || 0}
            onTabChange={(e) => this.setState({ activeIndex: e.index })}
          >
            <AccordionTab header="Institution's Information">
              {this.state.checkEligibility === true ? (
                <AdminViewInstitute
                  // getDwsInstituteDetails={ this.getDwsInstituteDetails }
                  instituteInfo={this.state.instituteInfo}
                  logo={this.state.institutePicture}
                  reloadInstituteInfo={this.reloadInstituteInfo}
                />
              ) : (
                <div className="main-section">
                  <div className="p-grid nw-form blur-section">
                    <div className="p-col-12 p-xl-12">
                      <div className="nw-form-body">
                        <div className="p-col-12 p-xl-12">
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon nw-inputgroup-label">
                                CMS ID
                              </span>
                              <div className="nw-inputgroup-desc">
                                {this.state.instituteInfo.customCmsId}
                              </div>
                            </div>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Institute Name <span>*</span>
                              </span>
                              <InputText
                                value={dwsUrlInfo.instituteName}
                                id="instituteName"
                                onChange={this.onChangeInstituteName}
                                placeholder="Enter Institute Name"
                                style={{ width: "100%" }}
                                type="text"
                                name="instituteName"
                              />
                            </div>
                            <span className="error-message">
                              {errors["instituteName"]}
                            </span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Address <span>*</span>
                              </span>
                              <InputText
                                value={dwsUrlInfo.instituteAddress}
                                onChange={this.onChangeInstituteAddress}
                                id="address"
                                placeholder="Enter Address"
                                style={{ width: "100%" }}
                                type="text"
                                name="address"
                              />
                            </div>
                            <span className="error-message">
                              {errors["instituteAddress"]}
                            </span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Mobile No. <span>*</span>
                              </span>
                              <InputText
                                value={dwsUrlInfo.instituteContact}
                                onChange={this.onChangeInstituteContact}
                                placeholder="Enter mobile no."
                                maxLength={11}
                                keyfilter="num"
                                style={{ width: "100%" }}
                                type="text"
                                mask="09999999999"
                                id="mobileNo"
                                name="mobileNo"
                              />
                            </div>
                            <span className="error-message">
                              {errors["instituteContact"]}
                            </span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Email Address <span>*</span>
                              </span>
                              <InputText
                                value={dwsUrlInfo.instituteEmail}
                                onChange={this.onChangeInstituteEmail}
                                placeholder="Enter email address"
                                style={{ width: "100%" }}
                                type="text"
                                id="email"
                                keyfilter="email"
                                name="email"
                              />
                            </div>
                            <span className="error-message">
                              {errors["instituteEmail"]}
                            </span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span
                                className="p-inputgroup-addon"
                                style={{ fontStyle: "bold" }}
                              >
                                Desire URl <span>*</span>
                              </span>
                              {/* <span className="p-inputgroup-addon link">
                                http://test.dwsbd.com/p2/
                              </span> */}
                              <InputText
                                placeholder={"ex: link-name"}
                                value={this.state.urlName}
                                onChange={this.onChangeUrlName}
                                style={{ width: "15%" }}
                                type="text"
                                id="urlName"
                                name="urlName"
                                keyfilter={/^[^+/#<>*!@$%^&()A-Z]+$/}
                              />
                              <Button
                                className="p-button-primary p-button-text-only"
                                label="Check"
                                onClick={this.onChangeCheckDwsUrl}
                                icon="fas fa-check"
                              />
                            </div>
                            <span className="error-message">
                              {errors["urlName"]}
                            </span>
                            <span className={this.state.urlMsgView}>
                              {this.state.urlErrorMsgBody
                                ? this.state.urlErrorMsgBody
                                : null}
                            </span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span
                                className="p-inputgroup-addon"
                                style={{ fontStyle: "bold" }}
                              >
                                Facebook Link
                              </span>
                              {/* <span className="p-inputgroup-addon link">
                                http://test.dwsbd.com/p2/
                              </span> */}
                              <InputText
                                placeholder={"ex: facebook-link"}
                                value={this.state.facebookLink}
                                onChange={this.onChangeFacebookLink}
                                style={{ width: "15%" }}
                                type="text"
                                id="urlName"
                                name="facebookLink"
                                keyfilter={/^[^+/#<>*!@$%^&()A-Z]+$/}
                              />
                            </div>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span
                                className="p-inputgroup-addon"
                                style={{ fontStyle: "bold" }}
                              >
                                Twitter Link
                              </span>
                              {/* <span className="p-inputgroup-addon link">
                                http://test.dwsbd.com/p2/
                              </span> */}
                              <InputText
                                placeholder={"ex: twitter-link"}
                                value={this.state.twitterLink}
                                onChange={this.onChangeTwitterLink}
                                style={{ width: "15%" }}
                                type="text"
                                id="urlName"
                                name="twitterLink"
                                keyfilter={/^[^+/#<>*!@$%^&()A-Z]+$/}
                              />
                            </div>
                            <span className="error-message">
                              {errors["twitterLink"]}
                            </span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span
                                className="p-inputgroup-addon"
                                style={{ fontStyle: "bold" }}
                              >
                                Youtube Link
                              </span>
                              {/* <span className="p-inputgroup-addon link">
                                http://test.dwsbd.com/p2/
                              </span> */}
                              <InputText
                                placeholder={"ex: youtube-link"}
                                value={this.state.youtubeLink}
                                onChange={this.onChangeYoutubeLink}
                                style={{ width: "15%" }}
                                type="text"
                                id="urlName"
                                name="youtubeLink"
                                keyfilter={/^[^+/#<>*!@$%^&()A-Z]+$/}
                              />
                            </div>
                            <span className="error-message">
                              {errors["youtubeLink"]}
                            </span>
                          </div>
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span
                                className="p-inputgroup-addon"
                                style={{ fontStyle: "bold" }}
                              >
                                Linkedin Link
                              </span>
                              {/* <span className="p-inputgroup-addon link">
                                http://test.dwsbd.com/p2/
                              </span> */}
                              <InputText
                                placeholder={"ex: linkedin-link"}
                                value={this.state.linkedinLink}
                                onChange={this.onChangeLinkedinLink}
                                style={{ width: "15%" }}
                                type="text"
                                id="urlName"
                                name="linkedinLink"
                                keyfilter={/^[^+/#<>*!@$%^&()A-Z]+$/}
                              />
                            </div>
                            <span className="error-message">
                              {errors["linkedinLink"]}
                            </span>
                          </div>
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup nw-upload-button">
                              <span className="p-inputgroup-addon nw-inputtextarea-label">
                                Upload Image <span>*</span>
                                <br />
                                <span>(PNG/JPG/JPEG/GIF)</span>
                              </span>

                              <div className="nw-upload-button-inside">
                                {this.state.proPic.contentPic ? (
                                  <div className="image-view-main">
                                    <div className="upload-image-view">
                                      <Button
                                        className="delete-upload-button"
                                        icon="fas fa-times-circle"
                                        onClick={(e) =>
                                          this.setState({
                                            proPic: { contentPic: "" },
                                          })
                                        }
                                      />
                                      <img
                                        src={
                                          this.state.proPic.contentPic === ""
                                            ? "assets/images/demo.jpg"
                                            : "data:image/png;base64," +
                                              this.state.proPic.contentPic
                                        }
                                        style={{ height: "80px" }}
                                      />
                                    </div>
                                    <div className="image-title">
                                      {this.state.proPic.contentName}
                                    </div>
                                  </div>
                                ) : (
                                  <FileUpload
                                    chooseLabel={
                                      this.state.proPic.contentName ||
                                      uploadFileLabel
                                    }
                                    mode="basic"
                                    accept="image/*"
                                    maxFileSize={1000000}
                                    onSelect={this.onBasicUploadAuto.bind(this)}
                                    auto={true}
                                  />
                                )}
                              </div>

                              {/* <center>
                                                                {this.state.uploadImage.contentName}
                                                            </center> */}
                            </div>
                            <span className="error-message">
                              {this.state.proPic.contentName
                                ? null
                                : errors["logo"]}
                            </span>
                          </div>

                          <div className="p-col-12 p-xl-12 nw-button-parent">
                            <Button
                              className="nw-button nw-button-right p-button-primary"
                              label="Save"
                              icon="fas fa-check"
                              onClick={this.onSubmitUrlInfo}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </AccordionTab>

            {/* <AccordionTab header="Theme Setup">
                            <div className="main-section">
                                <div className="p-grid nw-form">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-form-body">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Choose Your Theme</span>
                                                    <Dropdown
                                                        value={this.state.dwsThemeName.urlTheme}
                                                        className="ui-dropdown"
                                                        options={themeColor}
                                                        onChange={(e) => this.onChangeDwsTheme(e)}
                                                        autoWidth={false}
                                                        filter={true}
                                                        filterPlaceholder="Select Theme"
                                                        filterBy="label,value"
                                                        placeholder={this.state.instituteInfo.applicationTheme || "Select theme"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </AccordionTab> */}

            {/* <AccordionTab header="Linkup Admisia">
                            <h2 className="padding-top-medium">Admisia</h2>
                            <p>  A complete and independent online admission system, widely used for the academic institution. Easy to provide admission instruction, admission form and admission result including mark. Manage online application through online payment and approve application form. Moreover effective to maintain the online payment procedure, reports and other features</p>

                            <div className="p-grid ">
                                <div className="p-col-12 p-xl-12">
                                    <h2 className="padding-top-medium"><span className='error-message' style={{fontSize: "22px"}}>*</span> Do you want to linkup ( URL ) Admisia on your Neti CMS? </h2>
                                    <div className="p-col-12 p-xl-12">
                                        <InputSwitch
                                            checked={this.state.togglValue}
                                            onChange={(e) => this.setState({ togglValue: e.value })} 
                                        />
                                    </div>
                                </div>
                            </div>

                            {this.state.togglValue ?
                                <div className="p-grid nw-form">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-grid nw-form-body">

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Admisia URL <span>*</span></span>
                                                    <InputText
                                                        className="custom-form-control"
                                                        value={this.state.importantLinkInfo.linkUrl}
                                                        id="linkUrl"
                                                        onChange={(e) => this.linkUrlHandler(e, this.props)}
                                                        name="linkUrl"
                                                        placeholder="Enter URL"
                                                        disabled={!this.state.value}
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.linkSubmitError["linkUrl"]}</span>
                                                <span className="text-success">
                                                    {
                                                        this.state.linkUpInfo ? this.state.linkUpInfo : null
                                                    }
                                                </span>

                                            </div>

                                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={(e) => this.addAdmisiaUrlLink()}
                                                    disabled={!this.state.value}
                                                />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </AccordionTab>

                        <AccordionTab header="Social Links">
                            <div className="p-col-12">
                                {this.state.instituteInfo ? <SocialLinkFooter defaultLanguage={this.state.instituteInfo.defaultLanguage} instituteDetails={this.state.instituteInfo} /> : ''}
                            </div>
                        </AccordionTab> */}
          </Accordion>

          {/* <div className="main-section blur-section">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Language</span>
                                            <Dropdown
                                                id="language"
                                                placeholder={this.state.instituteInfo.defaultLanguage || "Select"}
                                                autoWidth={false}
                                                name="language"
                                                options={languageList}
                                                value={this.state.defaultLanguageValue}
                                                onChange={this.onChangeDefaultLanguage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    );
  }
}
