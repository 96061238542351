import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';

export class TransferredApplicantsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rejectedStutas: "Custom Status",
            dataTableSelection: null,
            dataViewValue: [],
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            dataTableSelection: null,
            dataTableValue: props.componentNameData
        };
        this.NetiContentLoader = new NetiContentLoader();
        this.admisiaPointService = new AdmisiaPointService();

    }

    export = () => {

        this.dt.exportCSV();

    }
    dateFormat = (rowData) => {
        return <span>{NetiDateUtils.getAnyShortForm(rowData['admisiaApplicantInfoDTO.dateOfBirth'], 'DD-MMM-YYYY')}</span>;
    }

    render() {

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Transferred Applicant List
                        <span
                style={{ color: "white", display: "flex", float: "right" }}
            >
                Total Found: {this.state.dataTableValue.length}
            </span>
        </div>;

        return (
            <div className="p-fluid">
                <div className="p-grid">

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table nw-data-table-tabview">

                            <DataTable
                                value={this.state.dataTableValue}
                                selectionMode="single"
                                header={tableHeader}
                                selection={this.state.dataTableSelection}
                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                responsive={true}
                                paginator={true}
                                rows={10}
                                ref={(el) => { this.dt = el; }}
                            >
                                <Column field="admisiaApplicantInfoDTO.registrationID" header="Registration No." filter={true} />
                                <Column field="admisiaApplicantInfoDTO.applicantName" header="Name" filter={true} />
                                <Column field="admisiaApplicantInfoDTO.dateOfBirth" header="Date Of Birth" body={this.dateFormat} filter={true} />
                                <Column field="admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName" header="Gender" filter={true} />
                                <Column field="admisiaApplicantInfoDTO.religionCoreCategoryInfoDTO.categoryName" header="Religion" filter={true} />
                                <Column field="admisiaApplicantInfoDTO.mobileNo" header="Mobile No." filter={true} />
                                <Column field="admisiaApplicantInfoDTO.fatherName" header="Father's Name" filter={true} />
                                <Column field="admisiaApplicantInfoDTO.motherName" header="Mother's Name" filter={true} />
                                <Column field="admisiaApplicantInfoDTO.examMark" header="Mark" filter={true} />
                                {/* <Column field="" header="Transfer Date" body={this.dateFormatTempl} filter={true} /> */}

                            </DataTable>

                        </div>

                        <div className="p-col-12 p-xl-12 nw-button-parent">
                            <Button
                                label="Download"
                                className="p-button p-button-primary nw-button nw-button-right"
                                icon="fas fa-arrow-down"
                                onClick={this.export}
                            />
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}