import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';
import UnAuthService from '../../auth/UnAuthService';

export class TokenService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth=new AuthService();
        this.unAuth = new UnAuthService();
    }
    
findTokenInfoByTokenID(customTokenID) {
        let uri = this.baseURIHolder.getUserToken() + '/by/token-id?customTokenID='+customTokenID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }  
    
}