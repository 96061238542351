import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { RegistrationService } from '../../service/registration/RegistrationService';
import Registrationsuccessmessage from './Registrationsuccess';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { GUEST_POINT } from '../../utils/PointWiseRouteConsts';
import NetizenLogo from "../../assets/images/login/logo.png";
import { ErrorMessageView } from '../common/ErrorMessageView';

let errors = {};
let maxDate = new Date();
        
export default class Registrationadvancedform extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fullName: '',
            religion: '',
            dateOfBirth: '',
            districtList: '',
            districtId: '',
            areaList: '',
            areaId: '',
            areaSet: '',
            gender: '',
            topProgressBar:false,
            mobile: '',
            email: '',
            successmessage: false,
            fetchError: true,
            errors: {},
            roleValue: '',
        }
        this.onChangeFullName = this.onChangeFullName.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
        this.onChangeReligion = this.onChangeReligion.bind(this);
        this.mobileNUmberHandler = this.mobileNUmberHandler.bind(this);
        this.emailHandler = this.emailHandler.bind(this);
        this.onChangeDistrict = this.onChangeDistrict.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        this.registrationService = new RegistrationService();
    }

    componentWillMount() {

        this.registrationService.fetchDistrictList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ districtList: body });
                    });
                } else {
                    return res.json().then((body) => {
                        // console.log(body);
                    });
                }
            });
    }

    onChangeFullName = (event) => {
        errors["fullName"] = "";
        this.setState({
            fullName: event.target.value,
            errors: errors
        })
    }

    onChangeGender = (event) => {
        errors["gender"] = "";
        this.setState({
            gender: event.value,
            errors: errors
        })
    }

    onChangeReligion = (event) => { 
        errors["religion"] = "";
        this.setState({
            religion: event.value,
            errors: errors,
        })
    }

    onChangeBirthDate = (event) => { 
        errors["dateOfBirth"] = "";
        this.setState({
            dateOfBirth: event.value,
            errors: errors
        })
    }

    mobileNUmberHandler = (event, props) => {
        errors["mobile"] = "";
        this.setState({
            mobile: event.target.value,
            errors: errors
        })
    }

    emailHandler = (event) => {

        // if ((/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email))) {
        //     this.setState({ email: event.target.value })
        //     errors["email"] = "";
        // } else if (this.state.email === '') {
        //     this.setState({ email: event.target.value })
        //     errors["email"] = "";
        // } else {

        //     this.setState({ email: event.target.value })
        //     errors["email"] = "Invalid email format.";
        // }
        this.setState({ email: event.target.value })
    }

    onChangeDistrict(e) {
        this.setState({areaList:''});
        errors["districtId"] = "";
        this.setState({
            districtId: e.value,
            errors: errors
        });
        this.setState({ areaId: '' });
            this.registrationService.fetchAreaList(e.value)
                .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ areaList: body });
                    });
                } else {
                    return res.json().then((body) => {
                    });
                }
            });
    }

    onChangeArea(event) { 
        errors["areaId"] = "";
        this.setState({
            areaId: event.value,
            errors: errors})
    }

    rolehandleChange = (e) =>{
        this.setState({roleValue: e.target.value});
    }

    onSubmit = (event) => {
    
        if (this.handleError()) {
            this.setState({ topProgressBar:true })
            let userBasicInfo = {
                fullName: this.state.fullName,
                gender: this.state.gender,
                religion: this.state.religion,
                dateOfBirth: NetiDateUtils.getDateFromString(this.state.dateOfBirth),
                basicMobile: this.state.mobile,
                basicEmail: this.state.email,
                userName: this.props.userName,
                userPassword: this.props.password,
                userRole: this.state.roleValue,
                district: this.state.districtList,
                globalAreaInfoDTO: {
                    coreCategoryID: this.state.areaId
                }
            }
            this.registrationService.registerNewUser(userBasicInfo)
                .then((res) => {
                    if (res == true) {
                        this.setState({ successmessage: true,topProgressBar:false });
                    } else {

                    }
                }).catch(error => {
                    this.setState({ fetchError: false,topProgressBar:false });
                });
        }
    }

    handleError() {

        errors = {};
        let formIsValid = true;

        if (this.state.fullName === '') {
            formIsValid = false;
            errors["fullName"] = "Full name can't left empty.";
        } 
        if (this.state.gender === '') {
            formIsValid = false;
            errors["gender"] = "Gender can't left empty.";
        }
        if (this.state.religion === '') {
            formIsValid = false;
            errors["religion"] = "Religion can't left empty.";
        }
        if (this.state.dateOfBirth === '') {
            formIsValid = false;
            errors["dateOfBirth"] = "Birth Date can't left empty.";
        }
        if (this.state.mobile === '') {
            formIsValid = false;
            errors["mobile"] = "Mobile number can't left empty.";
        }
        if (this.state.roleValue === '') {
            formIsValid = false;
            errors["role"] = "Role can't left empty.";
        }
        
        // if ((/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email))) {
        //     errors["email"] = ""; 
        // }else if (this.state.email === '') {
        //     errors["email"] = ""; 
        // } else {
        //     formIsValid = false;
        //     errors["email"] = "Invalid email format.";
        // }

        if (this.state.districtId === '') {
            formIsValid = false;
            errors["districtId"] = "District can't left empty.";
        }
        if (this.state.areaId === '') {
            formIsValid = false;
            errors["areaId"] = "Area can't left empty.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        let {topProgressBar} = this.state;
        let religion = [
          { label: "Muslim", value: "Muslim" },
          { label: "Hindu", value: "Hindu" },
          { label: "Christian", value: "Christian" },
          { label: "Buddhist", value: "Buddhist" }
        ];

        let districtOptions = []
        if (this.state.districtList && this.state.districtList.length) {
            districtOptions = this.state.districtList.map((item) => ({
              value: item.coreCategoryID,
              label: item.categoryName,
            }));
        }

        let areaOptions = []
        if (this.state.areaList && this.state.areaList.length) {
            areaOptions = this.state.areaList.map((item) => ({
              value: item.coreCategoryID,
              label: item.categoryName,
            }));
        }


       let  roleOptions = [
        {
            "value": "student",
            "label": "Student"
        },
        {
            "value": "parent",
            "label": "Parent"
        },
        {
            "value": "teacher",
            "label": "Teacher"
        },
        {
            "value": "merchant",
            "label": "Merchant"
        },
        {
            "value": "noneofthose",
            "label": "None of those"
        }

       ]

      
        return (
            
            <div>
                {this.state.successmessage == false ?
                    <div className="p-grid">

                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }

                        <span className="p-float-label">
                            <div className="p-col-12 p-xl-12">
                                {/* <div className="p-inputgroup"> */}
                                    <InputText type="text"
                                        placeholder="Enter Full Name"
                                        keyfilter={/^[a-zA-Z&\s]/}
                                        onChange={(event) => this.onChangeFullName(event, this.props)}
                                        value={this.state.fullName}
                                    />
                                    {/* <span className="p-inputgroup-addon"><i className="pi pi-user" /></span> */}
                                {/* </div> */}
                                <span className='error-message'>{this.state.errors["fullName"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid">
                                    <div className="p-col-4">
                                        <RadioButton
                                            value="Male"
                                            inputId="rb1"
                                            onChange={event => this.onChangeGender(event)}
                                            checked={this.state.gender === "Male"}
                                        />
                                        <label htmlFor="rb1" className="p-radiobutton-label">Male</label>
                                    </div>
                                    <div className="p-col-4">
                                        <RadioButton
                                            value="Female"
                                            inputId="rb2"
                                            onChange={event => this.onChangeGender(event)}
                                            checked={this.state.gender === "Female"}
                                        />
                                        <label htmlFor="rb2" className="p-radiobutton-label">Female</label>
                                    </div>
                                    <div className="p-col-4">
                                        <RadioButton
                                            value="Others"
                                            inputId="rb3"
                                            onChange={event => this.onChangeGender(event)}
                                            checked={this.state.gender === "Others"}
                                        />
                                        <label htmlFor="rb3" className="p-radiobutton-label">Others</label>
                                    </div>
                                    <span className='error-message'>{this.state.errors["gender"]}</span>
                                </div>
                            </div>
                            <div className="p-col-12 p-xl-12">
                                <Dropdown
                                    placeholder='Select Religion'
                                    options={religion}
                                    onChange={event => this.onChangeReligion(event)}
                                    value={this.state.religion}
                                    autoWidth={false}
                                />
                                <span className='error-message'>{this.state.errors["religion"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-12">
                                <Calendar
                                    name="dateOfBirth"
                                    maxDate={maxDate}
                                    monthNavigator={true} 
                                    yearNavigator={true} 
                                    yearRange={NetiDateUtils.getYearRange()}
                                    showIcon={true}
                                    onChange={(event) => this.onChangeBirthDate(event)}
                                    dateFormat="dd/mm/yy"
                                    placeholder="Birth Date (DD/MM/YY)"
                                    value={this.state.dateOfBirth}
                                />
                                <span className='error-message'>{this.state.errors["dateOfBirth"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-12">
                                {/* <div className="p-inputgroup"> */}
                                    <InputText
                                        type="text"
                                        id="mobileNumber"
                                        placeholder="Enter Your Mobile Number"
                                        onChange={(event) => this.mobileNUmberHandler(event, this.props)}
                                        keyfilter="pint"
                                        maxlength={11}
                                    />
                                    {/* <span className="p-inputgroup-addon"><i className="pi pi-mobile" /></span> */}
                                {/* </div> */}
                                <span className='error-message'>{this.state.errors["mobile"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-12">
                                {/* <div className="p-inputgroup"> */}
                                    <InputText
                                        type="email"
                                        id="emailAddress"
                                        placeholder="Enter Email Address"
                                        value={this.state.email}
                                        onChange={(event) => this.emailHandler(event, this.props)}
                                    />
                                    {/* <span className="p-inputgroup-addon"><i className="pi pi-envelope" /></span> */}
                                {/* </div> */}
                                <span className='error-message'>{this.state.errors["email"]}</span>
                            </div>
                            <div className="p-col-12 p-md-12">
                                {/* <div className="p-inputgroup"> */}
                                    <Dropdown
                                        placeholder='Select District'
                                        options={districtOptions}
                                        value={this.state.districtId}
                                        onChange={event => this.onChangeDistrict(event)}
                                        autoWidth={false}
                                        filterBy="label,value"
                                        filter={true}
                                    />
                                {/* </div> */}
                                <span className='error-message'>{this.state.errors["districtId"]}</span>
                            </div>
                            <div className="p-col-12 p-md-12">
                                {/* <div className="p-inputgroup"> */}
                                    <Dropdown
                                        placeholder='Select Area'
                                        options={areaOptions}
                                        value={this.state.areaId}
                                        onChange={event => this.onChangeArea(event)}
                                        autoWidth={false}
                                        filterBy="label,value"
                                        filter={true}
                                    />
                                {/* </div> */}
                                <span className='error-message'>{this.state.errors["areaId"]}</span>
                            </div>


                            
                            <div className="p-col-12 p-md-12">
                                {/* <div className="p-inputgroup"> */}
                                    <Dropdown
                                        placeholder='Select role'
                                        options={roleOptions}
                                        value={this.state.roleValue}
                                        onChange={event => this.rolehandleChange(event)}
                                        autoWidth={false}
                                        filterBy="label,value"
                                        filter={true}
                                    />
                                {/* </div> */}
                                <span className="error-message">{this.state.errors["role"]}</span>
                            </div>

{/* 
                              <div className="p-col-12">
                                <select value={this.state.roleValue} onChange={this.rolehandleChange}  className="role-dropdown">
                                         <option selected disabled hidden value=''> Select from Dropdown </option>
                                          <option value="student"> Student </option>
                                          <option value="teacher"> Teacher </option>
                                          <option value="guardian"> Guardian </option>     
                                          <option value="noneofthose"> None of Those </option>          
                                </select>
                              </div>
                            <span className="error-message">{this.state.errors["role"]}</span> */}
                            
                            <br />
                            <div className="p-col-12 nw-button-parent">
                                <button 
                                    className="p-button p-component p-button p-button-text-icon-left custom-login-button gradiant-btn box-shadow" 
                                    onClick={(event) => this.onSubmit(event, this.props)}>
                                    <span className="p-button-text p-c">Sign Up</span>
                                </button>
                            </div>
                            <br/>

                        </span>
                        <div className="p-col-12 p-md-12">
                            <hr />
                            <br />
                            {
                                this.state.registration ?
                                    <h4>Have an account? <a href="#" onClick={this.loginHandler}>Log In</a> now.</h4>
                                    : <h4>Already have an account? <a href={GUEST_POINT.LOGIN} >Login</a> now.</h4>
                            }

                            <div className="copyright">
                                <img src={NetizenLogo} />
                                <span>POWERED BY <strong>NETIZEN IT LIMITED</strong></span>
                            </div>
                        </div>
                    </div> :
                    <Registrationsuccessmessage name={this.state.fullName} />}
            </div>
        )
    }
}