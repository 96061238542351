import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { Editor } from 'primereact/editor';
import CommonFuctionality from "../../common/CommonFuctionality";
import { DownloadButton } from "../../../utils/DownloadButton";


let errors = {};
let cloneStateBeforeMount;
export class CreateNotice extends Component {

    constructor() {
        super();
        this.state = {
            serialNo: '',
            noticeTitle: '',
            description: '',
            publishDate: '',
            expiryDate: '',
            application: '',
            attachment: '',
            noticeFile: {
                extention: '',
                contentPic: '',
                contentName: '',
            },
            updateNoticeFile: {
                extention: '',
                contentPic: '',
                contentName: '',
            },
            updateNoticeInformation: {
                publishDate: '',
                expiryDate: '',
            },
            dataTableValue: [],
            selectedRow: {
                attachmentFileName: ''
            },
            errors: {},
            updateFormErrors: {},
            visible: false,
            topProgressBar: false,
            dataTableIsLoading: false
        }


        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangePublishDate = this.onChangePublishDate.bind(this);
        this.onChangeExpiryDate = this.onChangeExpiryDate.bind(this);
        this.onChangeApplication = this.onChangeApplication.bind(this);
        this.submitSave = this.submitSave.bind(this);

        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);

        this.onChangeUpdateSerialNo = this.onChangeUpdateSerialNo.bind(this);
        this.onChangeUpdateTitle = this.onChangeUpdateTitle.bind(this);
        this.onChangeUpdateDescription = this.onChangeUpdateDescription.bind(this);
        this.onChangeUpdatePublishDate = this.onChangeUpdatePublishDate.bind(this);
        this.onChangeUpdateExpiryDate = this.onChangeUpdateExpiryDate.bind(this);
        this.onChangeUpdateApplication = this.onChangeUpdateApplication.bind(this);
        this.onChangeUpdateStatus = this.onChangeUpdateStatus.bind(this);
        this.NetiContentLoader = new NetiContentLoader();

        this.adminPointService = new AdminPointService();
        this.commonFuctionality = new CommonFuctionality();
        this.netiFileHandler = new NetiFileHandler();
    }

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
        this.fetchNoticeList();
    }

    onChangeSerialNo = (e) => {
        let {errors} = this.state;
        errors["serialNo"] = "";
        this.setState({ serialNo: e.target.value, errors: errors });
    }
    onChangeTitle(value) {
        let {errors} = this.state;
        errors["noticeTitle"] = "";
        this.setState({ noticeTitle: value, errors: errors });
    }
    onChangeDescription(e) {
        let {errors} = this.state;
        errors["description"] = "";
        this.setState({ description: e.htmlValue, errors: errors });
    }
    onChangePublishDate(value) {
        let {errors} = this.state;
        errors["publishDate"] = "";
        this.setState({ publishDate: value, errors: errors });
    }
    onChangeExpiryDate(value) {
        let {errors} = this.state;
        errors["expiryDate"] = "";
        this.setState({ expiryDate: value, errors: errors });
    }
    onChangeApplication(value) {
        let {errors} = this.state;
        errors["application"] = "";
        this.setState({ application: value, errors: errors });
    }

    onFileUpload(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let notice = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ noticeFile: notice });
        }
    }

    onUpdateFileUpload(e) {
        this.state.updateFormErrors["attachment"] = '';

        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let file = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            let { updateNoticeFile } = scope.state;
            updateNoticeFile.contentName = file.contentName;
            updateNoticeFile.contentPic = file.contentPic;
            updateNoticeFile.extention = file.extention;
            scope.setState({
                updateNoticeFile
            });
        }
    }

    submitSave() {

        if (this.handleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            if (Date.parse(this.state.expiryDate) > Date.parse(this.state.publishDate)) {
                let extention = this.netiFileHandler.getImageExtention(this.state.noticeFile.extention);
                let requestedObject = {
                    "noticeSerial": this.state.serialNo,
                    "noticeTitle": this.state.noticeTitle,
                    "noticeDetails": this.state.description,
                    "publishDate": NetiDateUtils.getDateFromString(this.state.publishDate),
                    "expireDate": NetiDateUtils.getDateFromString(this.state.expiryDate),
                    "applicationStatus": this.state.application,
                    "attachmentFileName": Date.now() + extention,
                    "fileContent": this.state.noticeFile.contentPic,
                    "fileSaveOrEditable": true,
                }
                this.adminPointService.createNoticeInfo(requestedObject).then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data save successful.' });
                        this.setState({
                            serialNo: '', noticeTitle: '', description: '', publishDate: '', expiryDate: '', application: '', attachment: '',
                            noticeFile: cloneStateBeforeMount.noticeFile,
                        });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false })
                        this.fetchNoticeList();

                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
            }
        }
    }

    handleError() {

        let { errors } = this.state
        let formIsValid = true;

        if (this.state.serialNo === '' || this.state.serialNo === null) {
            formIsValid = false;
            errors["serialNo"] = "Serial No can't left empty.";
        }
        if (this.state.noticeTitle === '') {
            formIsValid = false;
            errors["noticeTitle"] = "Notice Title can't left empty.";
        }
        if (this.state.description === '') {
            formIsValid = false;
            errors["description"] = "Description can't left empty.";
        }
        if (this.state.publishDate === '') {
            formIsValid = false;
            errors["publishDate"] = "Publish Date can't left empty.";
        }
        if (this.state.expiryDate === '') {
            formIsValid = false;
            errors["expiryDate"] = "Expiry Date can't left empty.";
        }
        if (this.state.application === '') {
            formIsValid = false;
            errors["application"] = "Application can't left empty.";
        }
        if (this.state.noticeFile.contentName === '') {
            formIsValid = false;
            errors["attachment"] = "Attachment can't left empty.";
        }
        else {
            return formIsValid;
        }
        this.setState({ errors });
    }

    handleUpdateFormError() {

        let { updateFormErrors } = this.state
        let formIsValid = true;

        if (this.state.selectedRow.noticeSerial === '' || this.state.selectedRow.noticeSerial === null) {
            formIsValid = false;
            updateFormErrors["serialNo"] = "Serial No can't left empty.";
        }
        if (this.state.selectedRow.noticeTitle === '') {
            formIsValid = false;
            updateFormErrors["noticeTitle"] = "Notice Title can't left empty.";
        }
        if (!this.state.selectedRow.noticeDetails) {
            formIsValid = false;
            updateFormErrors["description"] = "Description can't left empty.";
        }
        if (this.state.selectedRow.publishDate === '') {
            formIsValid = false;
            updateFormErrors["publishDate"] = "Publish Date can't left empty.";
        }
        if (this.state.selectedRow.expiryDate === '') {
            formIsValid = false;
            updateFormErrors["expiryDate"] = "Expiry Date can't left empty.";
        }
        if (this.state.selectedRow.applicationStatus === '' || this.state.selectedRow.applicationStatus === null) {
            formIsValid = false;
            updateFormErrors["application"] = "Application can't left empty.";
        }
        if (this.state.selectedRow.attachmentFileName === '' && this.state.updateNoticeFile.contentName === '') {
            formIsValid = false;
            updateFormErrors["attachment"] = "Attachment can't left empty.";
        }
        else {
            return formIsValid;
        }
        this.setState({ errors });
    }

    fetchNoticeList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchNoticeList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("Body", body);
                        
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].publishDate == null) {
                                body[i].publishDate = '';
                            } else {
                                body[i].publishDate = NetiDateUtils.getAnyShortForm(body[i].publishDate, 'DD-MMM-YYYY');
                            }

                            if (body[i].expireDate == null) {
                                body[i].expireDate = '';
                            } else {
                                body[i].expireDate = NetiDateUtils.getAnyShortForm(body[i].expireDate, 'DD-MMM-YYYY');
                            }
                        }
                        this.setState({ dataTableValue: body, dataTableIsLoading: false, topProgressBar: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    viewDialog(rowData) {
        this.fetchNoticeListForUpdate(rowData.noticeID);
        this.commonFuctionality.blurDialogBackgroundActive();
        this.setState({ visible: true });
    }

    fetchNoticeListForUpdate(noticeId) {

        this.adminPointService.fetchNoticeListByNoticeID(noticeId)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ selectedRow: body });
                        console.log("selectedRow", body);
                    })
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onHide(event) {
        this.commonFuctionality.blurDialogBackgroundDeActive();
        this.setState({
            visible: false,
            updateFormErrors: {},
            selectedRow: cloneStateBeforeMount.selectedRow,
            updateNoticeFile: cloneStateBeforeMount.updateNoticeFile,
            updateNoticeInformation: {
                publishDate: '',
                expiryDate: '',
            },
        });
    }

    onChangeUpdateSerialNo(value) {
        this.state.updateFormErrors["serialNo"] = '';
        let { selectedRow } = this.state;
        selectedRow.noticeSerial = value;
        this.setState({ selectedRow });
    }
    onChangeUpdateTitle(value) {
        this.state.updateFormErrors["noticeTitle"] = '';
        let { selectedRow } = this.state;
        selectedRow.noticeTitle = value;
        this.setState({ selectedRow });
    }
    onChangeUpdateDescription(e) {
        this.state.updateFormErrors["description"] = '';
        let { selectedRow } = this.state;
        selectedRow.noticeDetails = e.htmlValue;
        this.setState({ selectedRow });
    }
    onChangeUpdatePublishDate(value) {
        this.state.updateFormErrors["publishDate"] = '';
        let { updateNoticeInformation } = this.state;
        updateNoticeInformation.publishDate = value;
        this.setState({ updateNoticeInformation });
    }
    onChangeUpdateExpiryDate(value) {
        this.state.updateFormErrors["expireDate"] = '';
        let { updateNoticeInformation } = this.state;
        updateNoticeInformation.expiryDate = value;
        this.setState({ updateNoticeInformation });
    }
    onChangeUpdateApplication(value) {
        this.state.updateFormErrors["application"] = '';
        let { selectedRow } = this.state;
        selectedRow.applicationStatus = value;
        this.setState({ selectedRow });
    }
    onChangeUpdateStatus(value) {
        let { selectedRow } = this.state;
        selectedRow.noticeStatus = value;
        this.setState({ selectedRow });
    }

    submitUpdate() {

        let { selectedRow, updateNoticeFile, updateNoticeInformation } = this.state;
        if (this.handleUpdateFormError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            if (updateNoticeInformation.publishDate != '') {
                selectedRow.publishDate = Date.parse(NetiDateUtils.getDateFromString(this.state.updateNoticeInformation.publishDate));
            }

            if (updateNoticeInformation.expiryDate != '') {
                selectedRow.expireDate = Date.parse(NetiDateUtils.getDateFromString(this.state.updateNoticeInformation.expiryDate));
            }


            if (this.state.selectedRow.expireDate > this.state.selectedRow.publishDate) {
                if (updateNoticeFile.contentName != '') {
                    let extention = this.netiFileHandler.getImageExtention(updateNoticeFile.extention);
                    selectedRow.attachmentFileName = Date.now() + extention;
                    selectedRow.fileContent = this.state.updateNoticeFile.contentPic;
                    selectedRow.fileSaveOrEditable = true;
                }

                this.adminPointService.updateNoticeInfo(selectedRow)
                    .then(res => {
                        if (res.status == 202) {
                            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data updated successfully.' });
                            this.onHide();
                            this.fetchNoticeList();
                            this.setState({ topProgressBar: false, dataTableIsLoading: false })
                            this.setState({
                                selectedRow: cloneStateBeforeMount.selectedRow,
                                updateNoticeFile: cloneStateBeforeMount.updateNoticeFile,
                                updateNoticeInformation: {
                                    publishDate: '',
                                    expiryDate: '',
                                },
                            });
                        } else {
                            this.adminPointService.Auth.handleErrorStatus(res)
                                .then((resp) => {
                                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                                });
                        }
                    }).catch((error) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });
            }
            else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "Invalid Date Format." });
            }
        }
    }

    updateDialogDiscard = () => {
        this.onHide();
    }

    removeButtonOnclick = () => {
        this.setState({ noticeFile: { contentPic: '' } });
        this.setState({ noticeFile: { contentName: '' } });

    }

    removeUpdateNotice = () => {
        let { selectedRow, updateNoticeFile } = this.state;

        console.log("updateNoticeFile onchange", this.state.updateNoticeFile);
        console.log("selectedrow onchange", this.state.selectedRow);

        selectedRow.fileContent = '';
        selectedRow.attachmentFileName = '';
        selectedRow.extention = '';


        updateNoticeFile.contentName = '';
        updateNoticeFile.contentPic = '';
        updateNoticeFile.extention = '';

        this.setState({ selectedRow, updateNoticeFile })
    }

    render() {
        console.log("updateNoticeFile render", this.state.updateNoticeFile);
        console.log("selectedrow render", this.state.selectedRow);

        let serialNoOptions = [
            {
                label: 1,
                value: 1
            },
            {
                label: 2,
                value: 2
            },
            {
                label: 3,
                value: 3
            }
        ];
        let applicationOptions = [
            {
                label: 'Eduman',
                value: 1
            },
            {
                label: 'Netiworld',
                value: 2
            },
            {
                label: 'Both',
                value: 3
            }
        ];

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Notice List
        <span style={{ 'float': 'right' }}>Total Found: {this.state.dataTableValue.length}</span>
        </div>;
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;



        let editBody = (rowData) => {

            return <div className='text-center'>
                <Button
                    className="nw-action-button edit"                
                    icon="fas fa-edit"
                    tooltip="Update"
                    onClick={(e) => this.viewDialog(rowData)}
                />
                 <Button
                    className="nw-action-button info"
                    icon="fas fa-eye"
                    tooltip="View"
                />
            </div>
        }

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section">
                <div className="p-grid nw-form">
                    <Growl ref={(el) => this.growl = el} />
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-form-body">

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Serial <span>*</span></span>
                                    <Dropdown
                                        placeholder='Select Serial No'
                                        name="serialNo"
                                        options={serialNoOptions}
                                        value={this.state.serialNo}
                                        onChange={this.onChangeSerialNo}
                                        showClear={true}
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["serialNo"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Title <span>*</span></span>
                                    <InputText
                                        placeholder='Enter Title'
                                        name="noticeTitle"
                                        value={this.state.noticeTitle}
                                        onChange={(e) => this.onChangeTitle(e.target.value)}
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["noticeTitle"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputtexteditor-label">Description <span>*</span></span>
                                    <Editor
                                        value={this.state.description}
                                        onTextChange={(e) => this.onChangeDescription(e)}
                                        className="nw-inputtexteditor"
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["description"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Publish Date <span>*</span></span>
                                    <Calendar
                                        placeholder='Select Publish Date'
                                        name="publishDate"
                                        value={this.state.publishDate}
                                        onChange={(e) => this.onChangePublishDate(e.value)}
                                        autoWidth={false}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["publishDate"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Expiry Date <span>*</span></span>
                                    <Calendar
                                        style={{ marginRight: "0px !important" }}
                                        placeholder='Select Expiry Date'
                                        name="expiryDate"
                                        value={this.state.expiryDate}
                                        onChange={(e) => this.onChangeExpiryDate(e.value)}
                                        autoWidth={false}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                    />

                                </div>
                                <span className='error-message'>{this.state.errors["expiryDate"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Application <span>*</span></span>
                                    <Dropdown
                                        placeholder='Select Application'
                                        name="application"
                                        options={applicationOptions}
                                        value={this.state.application}
                                        onChange={(e) => this.onChangeApplication(e.value)}
                                        filter={true}
                                        filterBy="label,value"
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["application"]}</span>
                            </div>



                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup nw-upload-button">
                                    <span className="p-inputgroup-addon nw-inputtextarea-label">
                                        Upload Attachment <span>*</span>
                                        <br />
                                        <span>(PNG/JPG/PDF)</span>
                                    </span>

                                    <div className="nw-upload-button-inside">

                                        {
                                            this.state.noticeFile.contentPic ?
                                                <div className="image-view-main">
                                                    <div className="upload-image-view">
                                                        <Button
                                                            className="delete-upload-button"
                                                            icon="fas fa-times-circle"
                                                            onClick={this.removeButtonOnclick}
                                                        />
                                                        {this.state.noticeFile.contentName ?
                                                            this.commonFuctionality.getFileContentTypeIcon(this.state.noticeFile.contentName) : ''}

                                                    </div>
                                                    <div className="image-title">{this.state.noticeFile.contentName}</div>
                                                </div>

                                                :
                                                <FileUpload
                                                    id="attachments"
                                                    mode="basic"
                                                    maxFileSize={1000000}
                                                    onSelect={this.onFileUpload.bind(this)}
                                                    auto={true}
                                                    chooseLabel={this.state.noticeFile.contentName ? this.state.noticeFile.contentName : "Click / Drag Here To Upload"}

                                                />
                                        }

                                    </div>



                                </div>
                                <center><span className='error-message'>{this.state.noticeFile.contentName === '' && this.state.errors["attachment"]}</span></center>
                            </div>


                            <div className="p-col-12 p-xl-12" />

                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <div className="required-field">
                                    (<span>*</span>) required fields
                                </div>

                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Save"
                                    icon="fas fa-check"
                                    onClick={event => this.submitSave(event)}
                                />
                            </div>
                        </div>

                    </div>


                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table blur-section">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    header={tableHeader}
                                    value={this.state.dataTableValue}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column field="noticeSerial" header="Serial No." sortable={true} filter={true} />
                                    <Column field="noticeTitle" header="Title" sortable={true} filter={true} />
                                    <Column field="application" header="Application" sortable={true} filter={true} />
                                    <Column field="publishDate" header="Publish Date" filter={true} sortable={true} />
                                    <Column field="expireDate" header="Expiry Date" filter={true} sortable={true} />
                                    <Column field="status" header="Status" sortable={true} filter={true} />
                                    <Column field="" header="Action" style={{ width: "110px" }} body={editBody} />

                                </DataTable>
                            }
                        </div>
                    </div>
                </div>
                </div>
                <div className="dialog-section">
                <Dialog className="nw-dialog" header="Notice Info (Update)" visible={this.state.visible} onHide={this.onHide}>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">


                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Serial No  <span>*</span></span>
                                                        <Dropdown
                                                            placeholder={this.state.selectedRow.noticeSerial ? this.state.selectedRow.noticeSerial : 'Select Serial No.'}
                                                            name="serialNo"
                                                            options={serialNoOptions}
                                                            value={this.state.selectedRow.noticeSerial || ''}
                                                            onChange={(e) => this.onChangeUpdateSerialNo(e.target.value)}
                                                            autoWidth={false}
                                                            showClear={true}
                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.updateFormErrors["serialNo"]}</span>
                                                </div>


                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Title <span>*</span></span>
                                                        <InputText
                                                            placeholder='Enter Title'
                                                            name="title"
                                                            value={this.state.selectedRow.noticeTitle || ''}
                                                            onChange={(e) => this.onChangeUpdateTitle(e.target.value)}
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.updateFormErrors["noticeTitle"]}</span>
                                                </div>





                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Description <span>*</span></span>
                                                        <Editor
                                                            value={this.state.selectedRow.noticeDetails || ''}
                                                            onTextChange={(e) => this.onChangeUpdateDescription(e)}
                                                            className="nw-inputtexteditor"
                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.updateFormErrors["description"]}</span>
                                                </div>



                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Publish Date <span>*</span></span>
                                                        <Calendar
                                                            placeholder={this.state.selectedRow.publishDate ? NetiDateUtils.getAnyShortForm(this.state.selectedRow.publishDate, 'DD/MM/YY') : "Select Publish Date"}
                                                            name="publishDate"
                                                            value={this.state.updateNoticeInformation.publishDate || ''}
                                                            onChange={(e) => this.onChangeUpdatePublishDate(e.target.value)}
                                                            autoWidth={false}
                                                            yearRange="2010:2030"
                                                            showIcon={true}
                                                            dateFormat="dd/mm/yy"
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            dateOnly="true"
                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.updateFormErrors["publishDate"]}</span>
                                                </div>


                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Expiry Date <span>*</span></span>
                                                        <Calendar
                                                            placeholder={this.state.selectedRow.expireDate ? NetiDateUtils.getAnyShortForm(this.state.selectedRow.expireDate, 'DD/MM/YY') : "Select Expiry Date"}
                                                            name="expiryDate"
                                                            value={this.state.updateNoticeInformation.expiryDate || ''}
                                                            onChange={(e) => this.onChangeUpdateExpiryDate(e.target.value)}
                                                            autoWidth={false}
                                                            yearRange="2010:2030"
                                                            showIcon={true}
                                                            dateFormat="dd/mm/yy"
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            dateOnly="true"
                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.updateFormErrors["expireDate"]}</span>
                                                </div>



                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Application <span>*</span></span>
                                                        <Dropdown
                                                            placeholder='Select Period'
                                                            name="application"
                                                            options={applicationOptions}
                                                            value={this.state.selectedRow.applicationStatus || ''}
                                                            onChange={(e) => this.onChangeUpdateApplication(e.value)}
                                                            filter={true}
                                                            filterBy="label,value"
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                    <span className='error-message'>{this.state.updateFormErrors["application"]}</span>
                                                </div>


                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup nw-upload-button">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                            Upload Attachment <span>*</span>
                                                            <br />
                                                            <span>(PNG/JPG/PDF)</span>
                                                        </span>

                                                        <div className="nw-upload-button-inside">

                                                            {
                                                                this.state.updateNoticeFile.contentName || this.state.selectedRow.attachmentFileName ?
                                                                    <div className="image-view-main">
                                                                        <div className="upload-image-view">
                                                                            <Button
                                                                                className="delete-upload-button"
                                                                                icon="fas fa-times-circle"
                                                                                onClick={this.removeUpdateNotice}
                                                                            />
                                                                            {this.state.updateNoticeFile.contentName ?
                                                                                this.commonFuctionality.getFileContentTypeIcon(this.state.updateNoticeFile.contentName) : this.commonFuctionality.getFileContentTypeIcon(this.state.selectedRow.attachmentFileName)}
                                                                            <DownloadButton fileName={this.state.selectedRow.attachmentFileName} filePath={this.state.selectedRow.attachmentPath} />
                                                                        </div>
                                                                        <div className="image-title">{this.state.updateNoticeFile.contentName ? this.state.updateNoticeFile.contentName : this.state.selectedRow.attachmentFileName}</div>
                                                                    </div>

                                                                    :
                                                                    <FileUpload
                                                                        id="attachments"
                                                                        name="attachment"
                                                                        mode="basic"
                                                                        maxFileSize={1000000}
                                                                        chooseLabel={this.state.updateNoticeFile.contentName ? this.state.updateNoticeFile.contentName : "Click / Drag Here To Upload"}
                                                                        auto={true}
                                                                        onSelect={this.onUpdateFileUpload.bind(this)}
                                                                    />
                                                            }

                                                        </div>
                                                    </div>
                                                    <span className='error-message'>{this.state.updateFormErrors["attachment"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <div className="input-radio-button">
                                                            <span className="p-inputgroup-addon addon-no-style">Status <span>*</span></span>
                                                            <div className="radio-button-inside">
                                                                <RadioButton
                                                                    value={1}
                                                                    inputId="rb1"
                                                                    onChange={e => this.onChangeUpdateStatus(e.target.value)}
                                                                    checked={this.state.selectedRow.noticeStatus == 1}
                                                                />
                                                                <label htmlFor="rb1" className="p-radiobutton-label">
                                                                    Enable
                                                             </label>
                                                            </div>
                                                            <div className="radio-button-inside">
                                                                <RadioButton
                                                                    value={0}
                                                                    inputId="rb2"
                                                                    onChange={e => this.onChangeUpdateStatus(e.target.value)}
                                                                    checked={this.state.selectedRow.noticeStatus == 0}
                                                                />
                                                                <label htmlFor="rb1" className="p-radiobutton-label">
                                                                    Disable
                                                              </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        onClick={this.updateDialogDiscard}
                                                    />
                                                    <Button
                                                        label="Update"
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        icon="fas fa-check"
                                                        onClick={event => this.submitUpdate(event)}
                                                    />
                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }
}