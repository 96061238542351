import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { RadioButton } from 'primereact/radiobutton';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import {Growl} from 'primereact/growl';
import CommonFuctionality from '../../common/CommonFuctionality';

let urlId;
export class SpeechUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            speechInformation:{
                speechID: '',
                speechDetails: '',
                speechImgName : '',
                speechImgPath : '',
                speechSerial: '',
                speechStatus: '',
                speechDate : '',
                welcomeSpeechStatus : '',
                speakerName : '',
                speakerDesignation: '',
                speakerMobile : '',
                speakerEmail : '',
                speakerFacebookLink : '',
                speakerLinkedinLink: '',
                speakerTwitterLink: '',
                imageContent: '',
                imageSaveOrEditable : '',
                urlInfoDTO: {
                    urlInfoID: ''
                },
            },
            speechSubmitError: {
                
            },
            uploadImage: {

            },
            wrongEmail:true,
            dataTableIsLoading: false,
            errorMsgVisible:false,
            topProgressBar: false,
            errorMsgBody: '',
            homeReturnButton: false
        }
        this.DwsService = new DwsService();
        this.netiFileHandler = new NetiFileHandler();
        this.validatorUtility = new ValidatorUtility();
        this.commonFuctionality = new CommonFuctionality();
        // this.reloadCoreUrlIdFromLocal();

        
        this.getImg = this.getImg.bind(this);
        this.getImg();
    }

    propsLoad = () => {
        let { speechInformation } = this.state;
        this.setState({ speechInformation: this.props.speechInformation });
        this.reloadCoreUrlIdFromLocal();

        console.log("this.props.speechInformation", this.props.speechInformation);
        
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => { 
                this.componentWillMount();
            }, 2000);
        }
    } 

    componentWillMount(){
        let {speechInformation} = this.state;
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
     
        if( urlId ){
            this.setState({ homeReturnButton: false})
            let {speechInformation} = this.state;
            speechInformation.urlInfoDTO.urlInfoID = urlId;
            this.setState({speechInformation});
            this.setState({ speechInformation: this.props.speechInformation });
        }
        else{
            this.setState({ homeReturnButton: true})
        }

        this.propsLoad()
        // this.getImg();
    }
    speakerNameHandler = (event, props) => {
        let {speechInformation} = this.state;
        this.props.speechInformation.speakerName = event.target.value;
        speechInformation.speakerName = this.props.speechInformation.speakerName;
        this.setState({speechInformation});
    }

    designationHandler = (event, props) => {
        let {speechInformation} = this.state;
        this.props.speechInformation.speakerDesignation = event.target.value;
        speechInformation.speakerDesignation = this.props.speechInformation.speakerDesignation;
        this.setState({speechInformation});
    }

    mobileNumberHandler = (event, props) => {
        let {speechInformation} = this.state;
        this.props.speechInformation.speakerMobile = event.target.value;
        speechInformation.speakerMobile =   this.props.speechInformation.speakerMobile;
        this.setState({speechInformation});
    }

    emailHandler = (event, props) => {
        let {speechInformation} = this.state;
        this.props.speechInformation.speakerEmail = event.target.value;
        speechInformation.speakerEmail = this.props.speechInformation.speakerEmail;
        this.setState({speechInformation});
    }

    speakerDetails = (event, props) => {
        let {speechInformation} = this.state;
        this.props.speechInformation.speechDetails = event.target.value;
        speechInformation.speechDetails = this.props.speechInformation.speechDetails;
        this.setState({speechInformation});
    }   

    facebookUrl = (event, props) => {
        let {speechInformation} = this.state;
        this.props.speechInformation.facebookUrl = event.target.value;
        speechInformation.speakerFacebookLink = this.props.speechInformation.facebookUrl;
        this.setState({speechInformation});
    }

    linkedinkUrl = (event, props) => {
        let {speechInformation} = this.state;
        this.props.speechInformation.linkedinkUrl = event.target.value;
        speechInformation.speakerLinkedinLink = this.props.speechInformation.linkedinkUrl;
        this.setState({speechInformation});
    }

    twitterUrl = (event, props) => {
        let {speechInformation} = this.state;
        this.props.speechInformation.twitterUrl = event.target.value;
        speechInformation.speakerTwitterLink = this.props.speechInformation.twitterUrl;
        this.setState({speechInformation});
    }

    statusCheck = (event, props) => {
        let {speechInformation} = this.state
        this.props.speechInformation.speechStatus = event.target.value;
        speechInformation.speechStatus = this.props.speechInformation.speechStatus;
        this.setState({speechInformation});
    }

    onLoadPic(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name,
                photoContent: content
            };
            scope.setState({ uploadImage: album });

        }
    }


    getImg() {
        let { speechInformation } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        let photoMainPath;

        // if (this.state.photoName) {
            photoMainPath = this.props.speechInformation.speechImgPath
        // }
        // else {
        //     photoMainPath = this.props.speechInformation.speechImgPath + ".jpg"
        // }

        // console.log("speechInformation", speechInformation);
        

        if (photoMainPath !== null) {
            this.netiFileHandler.getByteImage(photoMainPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                console.log("BODY", body);

                                let contentType = this.netiFileHandler.getImageContentType(speechInformation.speechImgName);
                                this.setState({ viewUpdatePicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ viewUpdatePicture: staticImg });
                    }
                });
        } else {
            this.setState({ viewUpdatePicture: staticImg });
        }

    }

    removeButtonOnclick = () =>{
        this.setState({ uploadImage: { contentPic: '' }, viewUpdatePicture: '' })
    }

    updateSpeech = (event, props) => {
        let {speechInformation, speechSubmitError} = this.state;

        if (this.state.uploadImage.contentPic != null) {
            speechInformation.imageContent = this.state.uploadImage.contentPic;
            speechInformation.imageSaveOrEditable = true;
            // let extention = this.netiFileHandler.getImageExtention(this.state.uploadImage.extention);
            // speechInformation.speechImgName = 'speech_pic_' + urlId + '_' + Date.now() + extention;

            speechInformation .speechImgName = this.state.uploadImage.contentName;
        }

        if(this.errorHandler()){
            if (this.validatorUtility.emailIsValid(speechInformation.speakerEmail) == true) {
                this.setState({ speechInformation })
                this.DwsService.updateSpeech(speechInformation)
                .then(res => {
                    if(res.status == 202){
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Speech Update Successfully",
                            life: 800
                        });
                        setTimeout(() => {
                            this.props.loadDialog();
                        }, 800);
                        // this.props.viewTopSpeechList();
                    }else{
                        this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                           
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                        });
                    }
                });
            } else {
                speechSubmitError["speakerEmail"] = "Invalid Email format.";
            }
          
        }
    }

    errorHandler() {
        let { speechSubmitError } = this.state;
        let formIsValid = true;
        if (this.props.speechInformation.speakerName === '') {
            formIsValid = false;
            speechSubmitError["speakerName"] = "Speaker name can't left empty";
        }
        if (this.props.speechInformation.speakerDesignation === '') {
            formIsValid = false;
            speechSubmitError["speakerDesignation"] = "Speaker designation can't left empty";
        }
        if (this.props.speechInformation.speakerMobile === '') {
            formIsValid = false;
            speechSubmitError["speakerMobile"] = "Mobile number can't left empty.";
        }
        if (this.props.speechInformation.speakerEmail === '') {
            formIsValid = false;
            speechSubmitError["speakerEmail"] = "Email address can't left empty.";
        }
        if (this.props.speechInformation.speechDetails === '') {
            formIsValid = false;
            speechSubmitError["speechDetails"] = "Speech details can't left empty.";
        }
        if (this.props.speechInformation.imageContent === '') {
            formIsValid = false;
            speechSubmitError["imageContent"] = "Image can't left empty.";
        }
        this.setState({ speechSubmitError });
        return formIsValid;
    }

    speechStatus = (speechStatus) => {
        return (
            <div className="input-radio-button">
                <div className="radio-button-inside">
                    <RadioButton
                        value={1}
                        id="enable"
                        name="speechEnable"
                        onChange={(e)=> this.statusCheck(e, this.props)}
                        checked={this.props.speechInformation.speechStatus === 1}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Enable</label>
                </div>

                <div className="radio-button-inside">
                    <RadioButton
                        value={0}
                        id="enable"
                        name="speechEnable"
                        onChange={(e)=> this.statusCheck(e, this.props)}
                        checked={this.props.speechInformation.speechStatus === 0}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Disable</label>
                </div>
            </div>
        )
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">370 x 240 px (recommended)</span></div>
        return (

            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                { topProgressBar?
                    <ErrorMessageView 
                        topProgressBar={topProgressBar} 
                    />
                    : null
                }
                { errorMsgVisible ?
                    <ErrorMessageView 
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">



                        <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Speaker Name <span>*</span></span>
                                <InputText
                                    value={this.props.speechInformation.speakerName}
                                    placeholder="Enter Speaker Name"
                                    id="speakerName"
                                    onChange={(e) => this.speakerNameHandler(e, this.props)}
                                    name="speakerName"
                                />
                                
                            </div>
                            <span className="error-message">{this.state.speechSubmitError.speakerName}</span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Designation <span>*</span></span>
                                <InputText
                                    value={this.props.speechInformation.speakerDesignation}
                                    placeholder="Enter Speaker Designation"
                                    id="designation"
                                    onChange={(e) => this.designationHandler(e, this.props)}
                                    name="designations"
                                />
                                
                            </div>
                            <span className="error-message">{this.state.speechSubmitError.speakerDesignation}</span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Mobile No <span>*</span></span>
                                <InputText
                                    type="text"
                                    name="CustomerContactCheck"
                                    id="contactCheck"
                                    placeholder="Enter Your Mobile Number"
                                    value={this.props.speechInformation.speakerMobile}
                                    onChange={(e) => this.mobileNumberHandler(e, this.props)}
                                    keyfilter="pint"
                                    maxlength={11}
                                />
                                
                            </div>
                            <span className="error-message">{this.state.speechSubmitError.speakerMobile}</span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Email Address <span>*</span></span>
                                <InputText
                                    value={this.props.speechInformation.speakerEmail}
                                    placeholder="Enter Your Email Address"
                                    id="emailAddress"
                                    onChange={(e) => this.emailHandler(e, this.props)}
                                    name="emailAddress"
                                />
                                
                            </div>
                            <span className="error-message">{this.state.speechSubmitError.speakerEmail}</span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                <InputTextarea
                                    value={this.props.speechInformation.speechDetails}
                                    placeholder="Enter Your Speech Details"
                                    id="details"
                                    onChange={(e) => this.speakerDetails(e, this.props)}
                                    name="details"
                                    rows={5}
                                    cols={30}
                                />
                                
                            </div>
                            <span className="error-message">{this.state.speechSubmitError.speechDetails}</span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Facebook Link </span>
                                <InputText
                                    value={this.props.speechInformation.facebookUrl}
                                    type="text"
                                    name="facebookUrl"
                                    id="facebookUrl"
                                    placeholder="Enter Facebook Link"
                                    onChange={(e) => this.facebookUrl(e, this.props)}
                                />
                                
                            </div>
                            <span className="error-message"></span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Linkedin Link </span>
                                <InputText
                                    value={this.props.speechInformation.linkedinkUrl}
                                    type="text"
                                    name="linkedin"
                                    id="linkedin"
                                    placeholder="Enter Linkedin Link"
                                    onChange={(e) => this.linkedinkUrl(e, this.props)}
                                />
                                
                            </div>
                            <span className="error-message"></span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Twitter Link </span>
                                <InputText
                                    value={this.props.speechInformation.twitterUrl}
                                    type="text"
                                    name="twitter"
                                    id="twitter"
                                    placeholder="Enter Twitter Link"
                                    onChange={(e) => this.twitterUrl(e, this.props)}
                                />
                                
                            </div>
                            <span className="error-message"></span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon addon-no-style">Speech Status <span></span></span>
                                
                                <div className="nw-inputgroup-desc desc-no-style">{this.speechStatus(this.props.speechInformation.speechStatus)}</div>

                            </div>
                            <span className="error-message"></span>
                        </div>



                        <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup nw-upload-button">
                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                    Upload Image <span>*</span>
                                    <br />
                                    <span>(PNG/JPG/JPEG/GIF)</span>
                                </span>

                                <div className="nw-upload-button-inside">

                                    {
                                        this.state.uploadImage.contentPic || this.state.viewUpdatePicture ?
                                            <div className="image-view-main">
                                                <div className="upload-image-view">
                                                    <Button
                                                        className="delete-upload-button"
                                                        icon="fas fa-times-circle"
                                                        onClick={this.removeButtonOnclick}
                                                    />
                                                    <img
                                                        src={
                                                            this.state.viewUpdatePicture === '' ?
                                                                "data:" + this.state.uploadImage.extention + ";base64," + this.state.uploadImage.contentPic
                                                                :
                                                                this.state.viewUpdatePicture
                                                        }
                                                        
                                                        style={{ "height": "80px" }}
                                                    />


                                                </div>
                                                <div className="image-title">{this.state.uploadImage.contentName }</div>
                                            </div>

                                            :
                                            <FileUpload
                                                chooseLabel={this.state.uploadImage.contentName || uploadFileLabel}
                                                mode="basic"
                                                accept="image/*"
                                                maxFileSize={1000000}
                                                onSelect={this.onLoadPic.bind(this)}
                                                auto={true}
                                            />
                                    }

                                </div>
                            </div>
                            <span className="error-message">{this.state.speechSubmitError.imageContent}</span>
                        </div>
                        

                        <div className="p-col-12 nw-button-parent-multiple">
                            <Button
                                className="p-button p-button-danger nw-button nw-button-multiple"
                                label="Discard"
                                icon="fas fa-times"
                                onClick={this.props.turnOffDialog}
                            />
                            <Button
                                className="p-button p-button-primary nw-button nw-button-multiple"
                                label="Update"
                                icon="fas fa-check"
                                onClick={(e) => this.updateSpeech(e, this.props)}
                            />
                        </div>
                        {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}