import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { Workbook } from 'react-excel-workbook';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';
import { SplitButton } from 'primereact/splitbutton';

let maxDate = new Date();

// pdf
let subTitleColumn = [];
let subTitleList = [];
export class ManagementPointPrepaidDepositBalance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            emModuleList: '',
            dataTableSelection: null,
            dataTableValue: [],
            dateRangeParamFormat: {
                startDate: "",
                endDate: "",
            },
            productList: [],
            datatableValue: [],
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        };

        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.ManagementPointService = new ManagementPointService();

        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    onChangeStartDate = (e) => {
        let { dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        this.clearDateError(e.target.name);
    }

    // onChangePackage = (e) => {
    //     let { errors, dateRangeParamFormat } = this.state;
    //     dateRangeParamFormat[e.target.name] = e.target.value;
    //     this.setState({ dateRangeParamFormat });
    //     errors["productID"] = "";
    //     // this.getEmModuleList(e.target.value);
    // }

    onSubmitHandler = () => {
        let { dateRangeParamFormat, emModuleList } = this.state;

        if (this.searchHandleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.ManagementPointService.fetchPrepaidDepositBalance(dateRangeParamFormat)
                .then((res) => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ datatableValue: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                        });
                    }
                    else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection.' });
                });
        }
    }

    searchHandleError = () => {
        let { errors, dateRangeParamFormat } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (dateRangeParamFormat.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start Date can't left empty.";
        }
        if (dateRangeParamFormat.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End Date can't left empty.";
        }
        if (dateRangeParamFormat.productID === '') {
            formIsValid = false;
            errors["productID"] = "Product can't left empty.";
        }
        if (dateRangeParamFormat.activityType === '') {
            formIsValid = false;
            errors["activityType"] = "Activity Type can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    amountBody = (rowData) => {
        return <div className="col-number text-right"> {rowData.amount}</div>
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Area", dataKey: "area" },
            { title: "Designation", dataKey: "designation" },
            { title: "Neti ID", dataKey: "customNetiID" },
            { title: "Name", dataKey: "fullName" },
            { title: "Mobile No.", dataKey: "basicMobile" },
            { title: "Paid Amount", dataKey: "amount" },
        ]

        let tableColStyles = { amount: { halign: "right" }, }

        pdfDownloadPortrait_TableWithSubTitle("Pre-Paid Deposit Balance Summary", 70, subTitleColumn, subTitleList, pdfColumns, this.state.datatableValue, tableColStyles, "Pre-Paid_DepositSummary.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { dateRangeParamFormat, datatableValue } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (dateRangeParamFormat.startDate && dateRangeParamFormat.endDate) {
            formatedStartDate = (dateRangeParamFormat.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (dateRangeParamFormat.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = formatedStartDate + ' to ' + formatedEndDate + "_Pre-Paid_Deposit_Summary.xlsx";
        let excelSheetName = "Pre-Paid_Deposit";
        excelColumns = [
            <Workbook.Column value="area" label="Area" />,
            <Workbook.Column value="designation" label="Designation" />,
            <Workbook.Column value="customNetiID" label="Neti ID" />,
            <Workbook.Column value="fullName" label="Name" />,
            <Workbook.Column value="basicMobile" label="Mobile No." />,
            <Workbook.Column value="amount" label="Paid Amount" />,
        ];
        // end excel

        let tableHeader = <div className="header-title">
            <div className="header-title-left"> Pre-Paid Deposit Balance Summary</div><div className="header-title-right"><a>Total Found: {datatableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let productList = []
        if (this.state.productList && this.state.productList.length) {
            productList = this.state.productList.map((item) => ({
                value: item.productID,
                label: item.productName,
            }));
        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            name='startDate'
                                            value={dateRangeParamFormat.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            name='endDate'
                                            value={dateRangeParamFormat.endDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            dateFormat='dd/mm/yy'
                                            yearRange="2010:2030"
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>
                                {/* <div className="p-col-12 p-lg-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Product Item <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Product"
                                            name="productID"
                                            options={productList}
                                            value={dateRangeParamFormat.productID}
                                            onChange={this.onChangePackage}
                                            autoWidth={false}
                                        />
                                    </div>
                                    <span className='error-message'>{this.state.errors["productID"]}</span>
                                </div> */}
                                <div className="p-col-12 p-xl-2 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-12"></div>
                            </div>
                        </div>

                        <div className="nw-data-table">
                            <div className="p-col-12 p-lg-12 p-xl-12">

                                {this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() : <div>
                                        <DataTable
                                            value={datatableValue}
                                            selectionMode="single"
                                            header={tableHeader}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            rowsPerPageOptions={[10, 20, 30]}
                                        >
                                            <Column field="area" header="Area" sortable={true} filter={true} />
                                            <Column field="designation" header="Designation" sortable={true} filter={true} />
                                            <Column field="customNetiID" header="Neti ID" sortable={true} filter={true} />
                                            <Column field="fullName" header="Name" sortable={true} filter={true} />
                                            <Column field="basicMobile" header="Mobile No." sortable={true} filter={true} />
                                            <Column field="amount" header="Paid Amount" body={this.amountBody} sortable={true} filter={true} />
                                        </DataTable>
                                    </div>

                                }
                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                <div style={{ display: 'none' }}>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                label="Download Excel"
                                                id="clickExcelBtn"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={datatableValue} name={excelSheetName}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>
                            </div>

                        </div >

                    </div>
                </div>

            </div>
        );
    }
}