import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DwsService } from "../../../service/dwsPoint/DwsService";
import { Growl } from "primereact/growl";
import { NetiDateUtils } from "../../../utils/NetiDateUtils";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { ProgressBar } from "primereact/progressbar";
import { NetiFileHandler } from "../../../utils/NetiFileHandler";
import { FileUpload } from "primereact/fileupload";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
import { UserCoreUrlInfoService } from "../../../service/UserCoreUrlInfoService";
import CommonFuctionality from "../../common/CommonFuctionality";

let urlId = null;

let errors = {};
export class AdminViewInstitute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proPic: {
        contentName: "",
        contentPic: "",
      },
      instituteInfo: {},
      instituteInfoObj: props.instituteInfo,
      institutePicture: "",
      errors: {},
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: "",
    };

    this.dwsService = new DwsService();
    this.netiFileHandler = new NetiFileHandler();
    this.userCoreUrlInfoService = new UserCoreUrlInfoService();

    // this.getProfileImg = this.getProfileImg.bind(this);
    this.validatorUtility = new ValidatorUtility();
    this.onBasicUploadAuto = this.onBasicUploadAuto.bind(this);
    // this.getProfileImg();
    this.CommonFuctionality = new CommonFuctionality();
  }

  componentWillMount() {
    urlId = this.dwsService.getUrlInfoFromLocalStorage();
  }

  onHide = (event) => {
    this.clearErrorMessage("instituteName");
    this.clearErrorMessage("instituteAddress");
    this.clearErrorMessage("instituteContact");
    this.clearErrorMessage("instituteEmail");
    this.clearErrorMessage("errorLogo");
    this.setState({ visible: false });
    if (this.state.proPic.contentPic === "") {
      // this.getProfileImg();
    }

    this.CommonFuctionality.blurDialogBackgroundDeActive();
  };

  viewDialog = (e) => {
    this.setState({ visible: true });
    this.CommonFuctionality.blurDialogBackgroundActive();
  };

  onChangeEditHandler = (e) => {
    this.setState({
      cloneInstitutePicture: this.state.institutePicture,
      instituteInfo: { ...this.state.instituteInfoObj },
    });
    this.viewDialog(e);
  };

  onChangeInstituteName = (e) => {
    let { instituteInfo } = this.state;
    instituteInfo.instituteName = e.target.value;
    this.setState({ instituteInfo });
    this.clearErrorMessage(e.target.name);
  };

  onChangeInstituteAddress = (e) => {
    let { instituteInfo } = this.state;
    instituteInfo.instituteAddress = e.target.value;
    this.setState({ instituteInfo });
    this.clearErrorMessage(e.target.name);
  };

  onChangeInstituteContact = (e) => {
    let { instituteInfo } = this.state;
    instituteInfo.instituteContact = e.target.value;
    this.setState({ instituteInfo });
    this.clearErrorMessage(e.target.name);
  };

  onChangeInstituteEmail = (e) => {
    let { instituteInfo } = this.state;
    instituteInfo.instituteEmail = e.target.value;
    this.setState({ instituteInfo });
    this.clearErrorMessage(e.target.name);
  };

  onChangeFacebookLink = (e) => {
    let { instituteInfo } = this.state;
    instituteInfo.facebookLink = e.target.value;
    this.setState({ instituteInfo });
    this.clearErrorMessage(e.target.name);
  };
  onChangeTwitterLink = (e) => {
    let { instituteInfo } = this.state;
    instituteInfo.twitterLink = e.target.value;
    this.setState({ instituteInfo });
    this.clearErrorMessage(e.target.name);
  };
  onChangeLinkedinLink = (e) => {
    let { instituteInfo } = this.state;
    instituteInfo.linkedinLink = e.target.value;
    this.setState({ instituteInfo });
    this.clearErrorMessage(e.target.name);
  };
  onChangeYoutubeLink = (e) => {
    let { instituteInfo } = this.state;
    instituteInfo.youtubeLink = e.target.value;
    this.setState({ instituteInfo });
    this.clearErrorMessage(e.target.name);
  };

  onChangeStorePassword = (e) => {
    let { instituteInfo } = this.state;
    instituteInfo.storePasswd = e.target.value;
    this.setState({ instituteInfo });
  };

  clearErrorMessage = (name) => {
    let { errors } = this.state;
    errors[name] = "";
    this.setState({ errors });
  };

  // getProfileImg() {
  //     let { instituteInfo } = this.props;
  //     let staticImg = 'assets/layout/images/avatar.png';

  //     let photoMainPath;

  //     if (this.state.photoName) {
  //         photoMainPath = "/images/nw/dws/logo/" + this.state.photoName
  //     }
  //     else {
  //         photoMainPath = instituteInfo.logoPath
  //     }

  //     if (photoMainPath !== null) {
  //         this.netiFileHandler.getByteImage(photoMainPath)
  //             .then((res) => {
  //                 if (res.status == 200) {
  //                     return res.json()
  //                         .then((body) => {
  //                             console.log("BODY", body);

  //                             let contentType = this.netiFileHandler.getImageContentType(instituteInfo.logoName);
  //                             this.setState({ institutePicture: contentType + body.fileContent });
  //                         })
  //                 } else {
  //                     this.setState({ institutePicture: staticImg });
  //                 }
  //             });
  //     } else {
  //         this.setState({ institutePicture: staticImg });
  //     }

  // }

  onBasicUploadAuto(e) {
    let { instituteInfo } = this.state;

    var reader = new FileReader();
    let photo = e.files[0];

    this.setState({
      photoName: photo.name,
    });

    const scope = this;
    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = "data:" + photo.type + ";base64,"; //link will be same from the word webapps in URL
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      let album = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name,
      };
      scope.setState({ proPic: album });
      instituteInfo.logoContent = album.contentPic;
      instituteInfo.logoSaveOrEditable = true;
    };
  }

  handleError() {
    let { instituteInfo } = this.state;

    errors = {};
    let formIsValid = true;

    if (!instituteInfo.instituteName) {
      formIsValid = false;
      errors["instituteName"] = "Institute Name can't left empty.";
    }
    // if (!instituteInfo.facebookLink) {
    //   formIsValid = false;
    //   errors["facebookLink"] = "Facebook link can't left empty.";
    // }
    if (!instituteInfo.instituteAddress) {
      formIsValid = false;
      errors["instituteAddress"] = "Address can't left empty.";
    }
    if (!instituteInfo.instituteContact) {
      formIsValid = false;
      errors["instituteContact"] = "Mobile No. can't left empty.";
    }

    // if (instituteInfo.instituteContact.length > 0 && instituteInfo.instituteContact.length < 11 ) {
    //     formIsValid = false;
    //     errors["instituteContact"] = "Invalid Mobile No.";
    // }

    if (!instituteInfo.instituteEmail) {
      formIsValid = false;
      errors["instituteEmail"] = "Email Address can't left empty.";
    }
    // if (!instituteInfo.logoContent) {
    //   formIsValid = false;
    //   errors["errorLogo"] = "Logo can't left empty";
    // }
    // if (this.state.instituteInfoObj.logoSaveOrEditable === false) {
    //     formIsValid = false;
    //     errors["logo"] = "Logo can't left empty.";
    // }

    this.setState({ errors: errors });
    return formIsValid;
  }
  linkUpdate = (link, setLink) => {
    let { instituteInfo } = this.state;
    const url = link;
    const newUrl = url.replace("https://", "").replace("http://", "");
    instituteInfo.setLink = "https://" + newUrl;
  };
  onUpdateInstitute = () => {
    if (this.handleError()) {
      this.setState({ errorMsgVisible: false, errors, topProgressBar: true });
      let { instituteInfo } = this.state;

      if (instituteInfo.logoSaveOrEditable === true) {
        instituteInfo.logoContent =
          this.state.proPic.contentPic ||
          this.state.instituteInfoObj.logoContent;
        // let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
        // instituteInfo.logoName = 'cms_institute_photo_' + urlId + '_' + Date.now() + extention;
        instituteInfo.logoName = this.state.proPic.contentName;
      }
      if (
        this.validatorUtility.emailIsValid(instituteInfo.instituteEmail) ===
        true
      ) {
        let facebookUrl = instituteInfo.facebookLink;
        if (facebookUrl == null) {
          facebookUrl = "";
        }
        const facebookNewUrl = facebookUrl
          .replace("https://", "")
          .replace("http://", "");
        instituteInfo.facebookLink = "https://" + facebookNewUrl;

        let twitterUrl = instituteInfo.twitterLink;
        if (twitterUrl == null) {
          twitterUrl = "";
        }
        const twitterNewUrl = twitterUrl
          .replace("https://", "")
          .replace("http://", "");
        instituteInfo.twitterLink = "https://" + twitterNewUrl;

        let youtubeUrl = instituteInfo.youtubeLink;
        if (youtubeUrl == null) {
          youtubeUrl = "";
        }
        const youtubeNewUrl = youtubeUrl
          .replace("https://", "")
          .replace("http://", "");
        instituteInfo.youtubeLink = "https://" + youtubeNewUrl;

        let linkedinUrl = instituteInfo.linkedinLink;
        if (linkedinUrl == null) {
          linkedinUrl = "";
        }
        const linkedinNewUrl = linkedinUrl
          .replace("https://", "")
          .replace("http://", "");
        instituteInfo.linkedinLink = "https://" + linkedinNewUrl;
        this.setState({ instituteInfo });
        this.userCoreUrlInfoService
          .updateNetiCmsInstituteInfo(instituteInfo)
          .then((res) => {
            if (res.status == 202) {
              return res.json().then((body) => {
                // this.getProfileImg();
                this.setState({ topProgressBar: false });
                this.growl.show({
                  severity: "success",
                  summary: "Success",
                  detail: "Successfully Updated",
                });
                // this.setState({ visible: false });

                setTimeout(() => {
                  this.onHide();
                  this.props.reloadInstituteInfo();
                }, 800);

                // window.location.reload(true);
              });
            } else {
              this.dwsService.Auth.handleErrorStatus(res).then((resp) => {
                this.setState({ topProgressBar: false });
                this.setState({ errorMsgVisible: true, errorMsgBody: resp });
              });
            }
          })
          .catch((error) => {
            // errors["dropDownClassError"] = 'Connection Problem'
            this.setState({
              errors: errors,
              dataTableIsLoading: false,
              topProgressBar: false,
            });
          });
      } else {
        errors["instituteEmail"] = "Invalid Email format.";
      }
    }
  };

  onResetInstitute = () => {
    this.setState({ instituteInfo: { ...this.state.instituteInfoObj } });
  };

  removeButtonOnclick = () => {
    let { instituteInfo } = this.state;
    instituteInfo.logoContent = "";
    this.setState({ proPic: { contentPic: "" }, instituteInfo });
  };

  render() {
    // console.log("props.instituteInfo", this.props.instituteInfo);
    let uploadFileLabel = (
      <div>
        Click / Drag Image Here To Upload <br />
        <span className="upload-file-label">370 x 240 px (recommended)</span>
      </div>
    );

    return (
      <div className="p-col-12 p-xl-12">
        <div className="main-section">
          <div className="p-grid nw-form blur-section">
            <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
              <div className="p-col-12 p-xl-12 nw-form-body">
                <div className="p-grid seprator-inside">
                  {/* <div className="p-col-12 p-xl-12" style={{ paddingTop: "30px" }}>
                                        <h1 className="text-center" style={{ paddingLeft: "10px", fontStyle: "bold", fontSize: "1.5rem" }}>Institute Information</h1>
                                    </div> */}

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        CMS ID
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.props.instituteInfo.customCmsId}
                      </div>
                    </div>
                  </div>

                  {/* <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                SSL ID
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.instituteInfoObj.storeId}
                                            </div>
                                        </div>
                                    </div> */}

                  {/* <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                SSL Password
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.instituteInfoObj.storePasswd}
                                            </div>
                                        </div>
                                    </div> */}

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Institute Name
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.state.instituteInfoObj.instituteName}
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Address
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.state.instituteInfoObj.instituteAddress}
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Mobile No.
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.state.instituteInfoObj.instituteContact}
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Email Address
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.state.instituteInfoObj.instituteEmail}
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Created URL
                      </span>
                      <div className="nw-inputgroup-desc">
                        <a href={this.state.instituteInfoObj.urlName}>
                          {this.state.instituteInfoObj.urlName}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Facebook Link
                      </span>
                      <div className="nw-inputgroup-desc">
                        <a
                          href={this.state.instituteInfoObj.facebookLink}
                          target="_blank"
                        >
                          {this.state.instituteInfoObj.facebookLink}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Twitter Link
                      </span>
                      <div className="nw-inputgroup-desc">
                        <a
                          href={this.state.instituteInfoObj.twitterLink}
                          target="_blank"
                        >
                          {this.state.instituteInfoObj.twitterLink}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Linkedin Link
                      </span>
                      <div className="nw-inputgroup-desc">
                        <a
                          href={this.state.instituteInfoObj.linkedinLink}
                          target="_blank"
                        >
                          {this.state.instituteInfoObj.linkedinLink}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Youtube Link
                      </span>
                      <div className="nw-inputgroup-desc">
                        <a
                          href={this.state.instituteInfoObj.youtubeLink}
                          target="_blank"
                        >
                          {this.state.instituteInfoObj.youtubeLink}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Created Date
                      </span>
                      <div className="nw-inputgroup-desc">
                        {NetiDateUtils.getAnyShortForm(
                          this.state.instituteInfoObj.urlCreateDate,
                          "DD-MMM-YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-col-12 p-xl-4 p-col-nogutter ">
              <div className="layout-dashboard">
                <div className="p-col-12 p-xl-12">
                  <div className="institute-image-view">
                    <img
                      src={
                        this.state.institutePicture ||
                        "data:image/*;base64," +
                          this.state.instituteInfoObj.logoContent
                      }
                      alt="Institute Logo"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="p-col-12 p-xl-12 nw-button-parent">
              <Button
                className="p-button-primary nw-button nw-button-right"
                label="Edit"
                icon="fas fa-check"
                onClick={this.onChangeEditHandler}
              />
            </div>
          </div>
        </div>

        <div className="dialog-section">
          <Dialog
            header="Edit Institute Information"
            visible={this.state.visible}
            className="nw-dialog"
            onHide={this.onHide}
            closable
          >
            <React.Fragment>
              {this.state.topProgressBar ? (
                <div>
                  <ProgressBar
                    mode="indeterminate"
                    showValue={true}
                    style={{ height: "4px" }}
                  />
                </div>
              ) : null}
              {this.state.errorMsgVisible ? (
                <ErrorMessageView errorMsgBody={this.state.errorMsgBody} />
              ) : null}
              <Growl ref={(el) => (this.growl = el)} />

              {/* <div className="p-fluid"> */}
              {/* <div className="p-grid nw-form"> */}
              {/* <div className="p-col-10 p-xl-2"> */}

              {/* <div className="p-col-12 p-xl-12 p-md-12"> */}
              {/* <div className="card">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12"> */}

              {/* <div className="p-col-10 p-xl-12 p-md-12">
                                                    <center>
                                                        <img
                                                            src={this.state.proPic.contentPic ? "data:" + this.state.proPic.extention + ";base64, " + this.state.proPic.contentPic : this.state.institutePicture}
                                                            height="200px"
                                                            style={{ objectFit: "contain" }}
                                                            alt="Institute Image"
                                                            width="150px"
                                                        />
                                                    </center>
                                                    <div className="p-col-12 p-md-12 nw-upload-button">
                                                        <FileUpload
                                                            className="p-button-raised p-button-secondary"
                                                            mode="basic"
                                                            accept="image/*"
                                                            maxFileSize={1000000}
                                                            onSelect={this.onBasicUploadAuto.bind(this)}
                                                            auto={true}
                                                            chooseLabel="Choose"
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                    <center>{this.state.proPic.contentName}</center>
                                                    <span className='error-message'>{errors["logo"]}</span>
                                                </div> */}

              <div className="p-grid nw-form">
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputgroup-label">
                      CMS ID
                    </span>
                    <div className="nw-inputgroup-desc">
                      {this.props.instituteInfo.customCmsId}
                    </div>
                  </div>
                </div>

                {/* <div className="p-col-12 p-md-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">SSL Password</span>
                                        <InputText
                                            placeholder="Enter SSL Password"
                                            style={{ width: "100%" }}
                                            type="text"
                                            onChange={this.onChangeStorePassword}
                                            value={this.state.instituteInfo.storePasswd}
                                        />
                                    </div>
                                </div> */}

                <div className="p-col-12 p-md-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Institute Name <span>*</span>
                    </span>
                    <InputText
                      id="instituteName"
                      placeholder="Enter Institute Name"
                      style={{ width: "100%" }}
                      type="text"
                      name="instituteName"
                      onChange={this.onChangeInstituteName}
                      value={this.state.instituteInfo.instituteName}
                    />
                  </div>
                  <span className="error-message">
                    {errors["instituteName"]}
                  </span>
                </div>

                <div className="p-col-12 p-md-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Address <span>*</span>
                    </span>
                    <InputText
                      maxLength={150}
                      id="address"
                      placeholder="Enter Address"
                      style={{ width: "100%" }}
                      type="text"
                      name="instituteAddress"
                      onChange={this.onChangeInstituteAddress}
                      value={this.state.instituteInfo.instituteAddress}
                    />
                  </div>
                  <span className="error-message">
                    {errors["instituteAddress"]}
                  </span>
                </div>

                <div className="p-col-12 p-md-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Mobile No. <span>*</span>
                    </span>
                    <InputText
                      placeholder="Enter Mobile No."
                      style={{ width: "100%" }}
                      type="text"
                      keyfilter="num"
                      id="mobileNo"
                      maxlength={11}
                      mask="09999999999"
                      name="instituteContact"
                      onChange={this.onChangeInstituteContact}
                      value={this.state.instituteInfo.instituteContact}
                    />
                  </div>
                  <span className="error-message">
                    {errors["instituteContact"]}
                  </span>
                </div>

                <div className="p-col-12 p-md-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Email Address <span>*</span>
                    </span>
                    <InputText
                      keyfilter="email"
                      placeholder="Enter Email Address"
                      style={{ width: "100%" }}
                      type="text"
                      name="instituteEmail"
                      onChange={this.onChangeInstituteEmail}
                      value={this.state.instituteInfo.instituteEmail}
                    />
                  </div>
                  <span className="error-message">
                    {errors["instituteEmail"]}
                  </span>
                </div>

                <div className="p-col-12 p-md-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Facebook Link</span>
                    <InputText
                      keyfilter="text"
                      placeholder="Enter Facebook"
                      style={{ width: "100%" }}
                      type="text"
                      name="facebookLink"
                      onChange={this.onChangeFacebookLink}
                      value={this.state.instituteInfo.facebookLink}
                    />
                  </div>
                  <span className="error-message">
                    {errors["facebookLink"]}
                  </span>
                </div>
                <div className="p-col-12 p-md-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Twitter Link</span>
                    <InputText
                      keyfilter="text"
                      placeholder="Enter Twitter"
                      style={{ width: "100%" }}
                      type="text"
                      name="twitterLink"
                      onChange={this.onChangeTwitterLink}
                      value={this.state.instituteInfo.twitterLink}
                    />
                  </div>
                  <span className="error-message">{errors["twitterLink"]}</span>
                </div>
                <div className="p-col-12 p-md-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Linkedin Link</span>
                    <InputText
                      keyfilter="text"
                      placeholder="Enter Linkedin"
                      style={{ width: "100%" }}
                      type="text"
                      name="linkedinLink"
                      onChange={this.onChangeLinkedinLink}
                      value={this.state.instituteInfo.linkedinLink}
                    />
                  </div>
                  <span className="error-message">
                    {errors["linkedinLink"]}
                  </span>
                </div>
                <div className="p-col-12 p-md-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Youtube Link</span>
                    <InputText
                      keyfilter="text"
                      placeholder="Enter Youtube"
                      style={{ width: "100%" }}
                      type="text"
                      name="youtubeLink"
                      onChange={this.onChangeYoutubeLink}
                      value={this.state.instituteInfo.youtubeLink}
                    />
                  </div>
                  <span className="error-message">{errors["youtubeLink"]}</span>
                </div>

                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup nw-upload-button">
                    <span className="p-inputgroup-addon nw-inputtextarea-label">
                      Upload Image
                      <br />
                      {/* <span>(PNG/JPG/JPEG/GIF)</span> */}
                      <span>(PNG/JPG/JPEG)</span>
                    </span>

                    <div className="nw-upload-button-inside">
                      {this.state.proPic.contentPic ||
                      this.state.instituteInfo.logoContent ? (
                        <div className="image-view-main">
                          <div className="upload-image-view">
                            <Button
                              className="delete-upload-button"
                              icon="fas fa-times-circle"
                              onClick={this.removeButtonOnclick}
                            />
                            <img
                              src={
                                this.state.instituteInfo.logoContent === ""
                                  ? "data:" +
                                    this.state.proPic.extention +
                                    ";base64," +
                                    this.state.proPic.contentPic
                                  : "data:image/*;base64," +
                                    this.state.instituteInfo.logoContent
                              }
                              style={{ height: "80px" }}
                            />
                          </div>
                          <div className="image-title">
                            {this.state.proPic.contentName ||
                              this.state.photoName}
                          </div>
                        </div>
                      ) : (
                        <FileUpload
                          chooseLabel={
                            this.state.proPic.contentName || uploadFileLabel
                          }
                          mode="basic"
                          accept="image/*"
                          maxFileSize={1000000}
                          onSelect={this.onBasicUploadAuto.bind(this)}
                          auto={true}
                        />
                      )}
                    </div>

                    {/* <center>
                                                            {this.state.uploadImage.contentName}
                                                        </center> */}
                  </div>
                  <span className="error-message">
                    {this.state.proPic.contentName ? null : errors["errorLogo"]}
                  </span>
                </div>

                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                  <Button
                    className="p-button-danger nw-button nw-button-multiple"
                    label="Discard"
                    icon="fas fa-times"
                    onClick={this.onHide}
                  />
                  <Button
                    className="p-button-primary nw-button nw-button-multiple"
                    label="Update"
                    icon="fas fa-check"
                    onClick={this.onUpdateInstitute}
                  />
                </div>

                {/* </div> */}
                {/* </div>
                                    </div>
                                </div> */}
              </div>
              {/* </div> */}
            </React.Fragment>
          </Dialog>
        </div>
      </div>
    );
  }
}
