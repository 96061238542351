import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import {Growl} from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import CommonFuctionality from '../../common/CommonFuctionality';
import { DownloadButton } from "../../../utils/DownloadButton";


let urlId;
export class DownloadCornerUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadFile:{

            },
            downloadSubmitError:[],
            downloadData: {
                fileCreateDate:'',
                fileID:'',
                fileTitle: '',
                fileSerial: '',
                fileName: '',
                filePath: '',
                fileEnableStatus: '',
                fileContent:'',
                fileSaveOrEditable:'',
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            checkEligibility: true,
            errorMsgVisible:false,
            errorMsgBody: '',
            
        }
        this.DwsService = new DwsService();
        this.netiFileHandler = new NetiFileHandler();
        this.commonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => { 
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount (){
        let {downloadData} = this.state;
        
         urlId=this.DwsService.getUrlInfoFromLocalStorage();

        if( urlId ){
            this.setState({ homeReturnButton: false});
            let {downloadData} = this.state;
            downloadData.coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({downloadData});
            this.setState({downloadData: this.props.downloadData});
        }
        else{
            this.setState({ homeReturnButton: true})
        }
    }


    serialNumberHandler = (event, props) => {
        let {downloadData} = this.state;
        this.props.downloadData.fileSerial = event.target.value;
        downloadData.fileSerial = this.props.downloadData.fileSerial ;
        this.setState({downloadData});
    }

    titleHandler = (event, props) => {
        let {downloadData} = this.state;
        this.props.downloadData.fileTitle = event.target.value;
        downloadData.fileTitle = this.props.downloadData.fileTitle;
        this.setState({downloadData}); 
    }

    noticeStatushandler = (event, props) =>{
        let {downloadData} = this.state;
        this.props.downloadData.fileEnableStatus = event.target.value;
        downloadData.fileEnableStatus = this.props.downloadData.fileEnableStatus;
        this.setState({downloadData});
    }

    onLoadFile(e) {
        var reader = new FileReader();
        let upFile = e.files[0];
        const scope = this
        reader.readAsDataURL(upFile);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + upFile.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: upFile.type,
                contentFile: urlStr,
                contentName: upFile.name
            };
            scope.setState({ uploadFile: album });

        }

        console.log("uploadFile", this.state.uploadFile);
        
    }


    updateDownloadFile = (event, props) => {
        let {downloadData} = this.state;
        if (this.errorHandler()) {
            if (this.state.uploadFile.contentFile != null) {
                downloadData.fileContent = this.state.uploadFile.contentFile;
                downloadData.fileSaveOrEditable = true;
                let extention = this.netiFileHandler.getImageExtention(this.state.uploadFile.extention);
                // downloadData.fileName = 'download_file_' + urlId + '_' + Date.now() + extention;
                downloadData.fileName = this.state.uploadFile.contentName;   
                
            }
            downloadData.coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ downloadData});
            this.DwsService.updateDownloadFile(downloadData)
            .then(res => {
                if(res.status === 202){
                    this.growl.show({
                        severity: "success",
                        summary: "Success Message",
                        detail: "Successfully Updated",
                        life: 800
                    });
                    this.props.viewDownloadFileList();
                    setTimeout(() => {
                        this.props.turnOffDialog();
                    }, 800);
                }else {
                    return res.json().then((erbody)=>{
                        this.setState({topProgressBar: false,  errorMsgVisible: true, errorMsgBody: erbody.message });
                    })
                }
                
            })
        }
    }

    errorHandler() {
        let { downloadData, downloadSubmitError } = this.state;
        let formIsValid = true;
        if (this.props.downloadData.fileSerial === '') {
            formIsValid = false;
            downloadSubmitError["fileSerial"] = "File serial can't left empty";
        }
        if (this.props.downloadData.fileTitle === '') {
            formIsValid = false;
            downloadSubmitError["fileTitle"] = "File title can't left empty";
        }
        if (this.props.downloadData.fileContent === '') {
            formIsValid = false;
            downloadSubmitError["fileContent"] = "File can't left empty";
        }
        this.setState({ downloadSubmitError });
        return formIsValid;
    }

    render() {
        let {topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        console.log("this.props.downloadData",this.props.downloadData);
        const searialNumber = [
            { label: '13', value: '13' },
            { label: '14', value: '14' }
        ]
        return (

            <div className="p-fluid">
                <div className="p-grid">
                   <div className="p-col-12 p-xl-12">
                      <div className="p-grid nw-form">
                            { topProgressBar?
                                <ErrorMessageView 
                                    topProgressBar={topProgressBar} 
                                />
                                : null
                            }
                            { errorMsgVisible ?
                                <ErrorMessageView 
                                    errorMsgVisible={errorMsgVisible}
                                    errorMsgBody={errorMsgBody}
                                />
                                : null
                            }
                            <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Serial No.</span>
                                            <div className="nw-inputgroup-desc">
                                                {this.props.downloadData.fileSerial}
                                            </div>
                                        </div>
                                        <span className="error-message">{this.state.downloadSubmitError.fileSerial}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Title <span>*</span></span>
                                            <InputText
                                                id="title"
                                                onChange={(event) => this.titleHandler(event, this.props)}
                                                value={this.props.downloadData.fileTitle}
                                                name="title"
                                                placeholder="Enter Title"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.downloadSubmitError.fileTitle}</span>
                                    </div>

                                    {/* <div className="formControl">
                                        <label htmlFor="serialNumber">Serial No</label>
                                        <InputText
                                            value={this.props.downloadData.fileSerial}
                                            id="serialNumber"
                                            onChange={(event) => this.serialNumberHandler(event, this.props)}
                                            name="serialNumber"
                                            placeholder="Enter Serial No."
                                            keyfilter="pint"
                                        />
                                        <span className="error-message">{this.state.downloadSubmitError.fileSerial}</span>
                                    </div> */}
                                    {/* <div className="formControl">
                                        <label htmlFor="title">Title</label>
                                        <InputText
                                            id="title"
                                            onChange={(event) => this.titleHandler(event, this.props)}
                                            value={this.props.downloadData.fileTitle}
                                            name="title"
                                            placeholder="Enter Title"
                                        />
                                        <span className="error-message">{this.state.downloadSubmitError.fileTitle}</span>
                                    </div> */}

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload File <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/DOC/PDF)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                <div className="image-view-main">
                                                    <div className="upload-image-view">
                                                        <Button
                                                            className="delete-upload-button"
                                                            icon="fas fa-times-circle"
                                                            onClick={e => this.setState({ uploadFile: { contentPic: '' } })}
                                                        />
                                                        {this.state.uploadFile.contentName ? 
                                                                    this.commonFuctionality.getFileContentTypeIcon(this.state.uploadFile.contentName) :    this.commonFuctionality.getFileContentTypeIcon(this.props.downloadData.fileName)}
                                                        
                                                        <DownloadButton fileName={this.props.downloadData.fileName} filePath={this.props.downloadData.filePath} />
                                                    </div>
                                                </div>
                                                <FileUpload
                                                    chooseLabel={this.state.uploadFile.contentName ? this.state.uploadFile.contentName : this.props.downloadData.fileName}
                                                    mode="basic"
                                                    maxFileSize={1000000}
                                                    onSelect={this.onLoadFile.bind(this)}
                                                    auto={true}
                                                />
                                            </div>
                                        </div>
                                        <span className="error-message">{this.state.downloadSubmitError.fileContent}</span>
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        <div className="formControl p-grid">
                                            <div className="p-col-12 p-md-12">
                                                <label>Status</label>
                                            </div>
                                            <div className="p-col-6 p-md-6">
                                                    <RadioButton
                                                    value={1}
                                                    inputId="enable"
                                                    name="photoEnable"
                                                    onChange={(e) => this.noticeStatushandler(e, this.props)}
                                                    checked={this.props.downloadData.fileEnableStatus === 1}
                                                />
                                            <label htmlFor="enable" className="p-radiobutton-label">Enable</label>
                                            </div>
                                            <div className="p-col-6 p-md-6">
                                                <RadioButton
                                                    value={0}
                                                    inputId="disable"
                                                    name="photoEnable"
                                                    onChange={(e) => this.noticeStatushandler(e, this.props)}
                                                    checked={this.props.downloadData.fileEnableStatus === 0}
                                                />
                                                <label htmlFor="disable" className="p-radiobutton-label"><b>Disable</b></label>
                                            </div>
                                            <span className="error-message"></span>
                                        </div>
                                    </div>
                                
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={this.props.turnOffDialog}
                                        />
                                        <Button
                                            name="update"
                                            label="Update"
                                            icon="fas fa-check" 
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            onClick={(e)=>this.updateDownloadFile(e, this.props)}
                                        />
                                    
                                    </div>
                                </div> 

                            </div>
                        </div>
                   </div>
                </div>
            </div>



            
        );
    }
}