import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { StudentClassConfigInfo } from '../common/StudentClassConfigInfo';
import { Panel } from 'primereact/panel';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { EmUserPointService } from '../../../service/emUserPoint/EmUserPointService';


export class StudentPortalSubject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subjectList: '',
            errorMsgBody: '',
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            userList: [],
            userID: '',
            userLength: '',
            insID: '',
            basicInfo: [],
            stId: '',
            }
        this.studentPortal = new StudentPortal();
        this.NetiContentLoader = new NetiContentLoader();
        this.emUserPointService = new EmUserPointService();
    }

    // componentWillMount() {
    //     this.loadStudentInfo();
    //    // this.getStudentSubjectInfo();

    //     // this.loadStudentInfo();

    // }
    componentWillMount(){
        this.loadStudentInfo();
        // this.getStudentSubjectInfo(); 
    }


    handledropdwon = (id, insId) => {
        
        this.setState({userID: id})
        this.setState({insID:insId})
    
        this.loadStudentInfo(); 
     }
    
    
    
    
    loadStudentInfo = () => {
    
        setTimeout(() => {
            [...document.querySelectorAll('.protal-single-info-content')].map(item => {
                if (!item.querySelector('p').innerHTML.trim()) {
                    item.querySelector('p').innerHTML = '---'
                }
            })
        }, 1500);
    
        let userListtt = [];
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.emUserPointService.fetchMappedPortalList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log('Log: GoToPortal -> componentWillMount -> body......', body[0].idForStudent)
                        console.log('Log: GoToPortal -> componentWillMount -> body......', body)
                        
    
                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({userList: userListtt})
                        this.setState({userLength:body.length})
                        this.setState({stId:body[0].idForStudent})
    
                        console.log("user id inside : ", this.state.userID)
                        
                        this.studentPortal
                        .fetchStudentProfileDetailsList(
                            this.state.insID || body[0]["emDetailsInfoDTO"].instituteId ,
                            this.state.userID || body[0].idForStudent
                            // body[this.state.userID]["emDetailsInfoDTO"].instituteId,
                            // body[this.state.userID].idForStudent,
                        )
                        .then(res => {
                            if (res.status == 200) {
                              
                                return res.json().then(body => {
                                    this.setState({ topProgressBar: false, errorMsgVisible: false })
                                    this.setState({ basicInfo: body });
                                    this.getStudentSubjectInfo();
                        
                                });
                            } else {
                                this.studentPortal.Auth.handleErrorStatus(res)
                                    .then((responseBody) => {
                                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                    });
                            }
                        })
                        .catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));
    
                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                    })
                } else {
                    this.emUserPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch((error) => {
                console.log(error)
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    
    
        this.setState({ topProgressBar: true, errorMsgVisible: false })
    };

    getStudentSubjectInfo() {
        let requestobject = this.state.basicInfo;
        console.log("subject.................", this.state.basicInfo)
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.studentPortal.fetchStudentSubjectList(requestobject).then(res => {
            if (res.status == 200) {
                return res.json().then((body) => {
                    this.setState({ subjectList: body.item });
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false })
                });
            } else {
                this.studentPortal.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error =>
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' }));

    }

    render() {
        var rows = [];
        let l = this.state.userLength;
        this.state.userList.map((item) =>{
            if(l > rows.length){
                rows.push(<a  onClick={() => this.handledropdwon(item.idForStudent, item["emDetailsInfoDTO"].instituteId)} >    {item.idForStudent}  </a>)
            }
        })

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
                        <div className="header-title-left">
                            Subject List  
                        </div>
                        <div className="header-title-right">
                            <a>
                                Total Subject Found: {this.state.subjectList && this.state.subjectList.length || 0}
                            </a>
                        </div>
                    </div>;

        return (
            <div className="p-col-12 p-col-nogutter">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid nw-form">
                    <div className="p-col-12"   style={{position: "relative"}}>
                        <StudentClassConfigInfo basicInfo={this.state.basicInfo} />

                        <div className='dropdown'>
            
                                <button className="dropbtn">
                                <span class="fas fa-align-right p-c p-button-icon-left"></span>
                                {/* <i className="fa fa-caret-down"></i> */}
                                </button>
                                <div className="dropdown-content">
                                    
                                    {rows}

                                </div>
                        
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-12 p-xl-12">
                        {this.state.dataTableIsLoading ?
                            this.NetiContentLoader.MyPointTableLoader() :
                            this.state.subjectList ?
                                <div className="nw-data-table">
                                    <DataTable
                                        header={tableHeader}
                                        value={this.state.subjectList}
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="subjectName" header="Subject Name" sortable={true} filter={true} />
                                        <Column field="subjectType" header="Subject Type" sortable={true} filter={true} />

                                    </DataTable>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}