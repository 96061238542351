import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';

export class NetiCMSSetupProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progressbarStartValue: 44,
        }
    }

	render() {
        let {progressbarStartValue} = this.state

        let processBarStep = 0;
        if(progressbarStartValue <= 20){
            processBarStep = 20
        }
        else if(progressbarStartValue > 20 && progressbarStartValue <= 40){
            processBarStep = 40
        }
        else if(progressbarStartValue > 40 && progressbarStartValue <= 60){
            processBarStep = 60
        }
        else if(progressbarStartValue > 60 && progressbarStartValue <= 80){
            processBarStep = 80
        }
        else if(progressbarStartValue > 80 && progressbarStartValue <= 100){
            processBarStep = 100
        }
         
		return (
            <React.Fragment>
                <div className="p-col-12 p-md-12 p-xl-12 complete-process-bar gray-bg m-b-30 blur-section">
                    <div>
                        <h5>Complete your Neti CMS Setting</h5>
                    </div>
                    <div class={ "panel-box p-10 process-bar-step-" + processBarStep}>
                        <ProgressBar value={ progressbarStartValue } className="profile-progress-bar"></ProgressBar>
                    </div>
                </div>
            </React.Fragment>
        );
	}
}