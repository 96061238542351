import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import NetiContentLoader from '../common/NetiContentLoader';
import { WalletBalance } from '../myPoint/common/WalletBalance';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { ValidatorUtility } from '../../utils/ValidatorUtility';

let cloneStateBeforeMount;
export class PrePaidBill extends Component {
    constructor(props) {
        super(props);

        this.state = {
            payabaleInstituteList: [],
            reloadWalletBalance: true,
            prePaidBillError: {},
            prepaidBillInfo: {
                paymentAmount: '',
                paymentDetails: '',
                emDetailsInfoDTO: {
                    emDetailsId: ''
                }
            },
            payableInstituteID: '',
            totalStudent: '',
            payableInstituteInfo: {
                packageCoreCategoryInfoDTO:{
                    categoryName:''
                }
            },
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            firstDropdownIsLoading: false,
            errorMsgBody: '',
            perMonthRate: '',
            payableAmount: '',
            billMonth: ''
        }

        this.partnerPointService = new PartnerPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();

    }

    componentWillMount() {
        this.getPayableInstituteList();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    getPayableInstituteList = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.partnerPointService.getPayableInstituteByPartner('PRE-PAID')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ payabaleInstituteList: body });
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })

            });

    }

    onChangePayableInstitute = (event) => {
        let { payableInstituteID, payabaleInstituteList, payableInstituteInfo,prePaidBillError } = this.state;
        prePaidBillError["payableInstitute"] = "";
        this.setState({ payableInstituteID: event.target.value, prePaidBillError });
        let insID = event.target.value;
        for (let i = 0; i < payabaleInstituteList.length; i++) {
            if (payabaleInstituteList[i].emDetailsId == insID) {
                payableInstituteInfo = payabaleInstituteList[i];
                console.log('Institute', payableInstituteInfo);
                
                this.setState({ payableInstituteInfo });
            }
        }
    }

    onChangeTotalStudent = (e) => {
        let { payableInstituteInfo, prePaidBillError } = this.state;
        prePaidBillError["totalStudent"] = "";

        let billMonthRate = payableInstituteInfo.monthlyRate * e.target.value;
        let billPayableAmount = billMonthRate * this.state.billMonth;


        this.setState({ totalStudent: e.target.value, prePaidBillError });
        this.setState({ perMonthRate: billMonthRate });
        this.setState({ payableAmount: billPayableAmount });

    }

    onChangeBillMonth = (e) => {
        let { payableInstituteInfo, totalStudent,prePaidBillError } = this.state;
        prePaidBillError["billMonth"] = "";
        this.setState({ billMonth: e.target.value });

        let billMonthRate = payableInstituteInfo.monthlyRate * totalStudent;
        let billPayableAmount = billMonthRate * e.target.value;

        this.setState({ perMonthRate: billMonthRate, prePaidBillError });
        this.setState({ payableAmount: billPayableAmount });
    }

    onChangePaymentDetails = (e) => {
        let { prepaidBillInfo, prePaidBillError } = this.state;
        prePaidBillError["requestNote"] = "";
        prepaidBillInfo.paymentDetails = e.target.value;
        this.setState({ prepaidBillInfo, prePaidBillError });
    }



    onSubmitHandler = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        let { prepaidBillInfo, payableInstituteInfo } = this.state;
        prepaidBillInfo.emDetailsInfoDTO.emDetailsId = payableInstituteInfo.emDetailsId;
        prepaidBillInfo.paymentAmount = this.state.payableAmount;
        this.setState({ prepaidBillInfo });
        if (this.prePaidBillFormError()) {
            this.partnerPointService.payPrepaidBillByPartner(prepaidBillInfo)
                .then(res => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: true, errorMsgVisible: false });
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                        this.setState({
                            prepaidBillInfo: cloneStateBeforeMount.prepaidBillInfo,
                            payableInstituteInfo: cloneStateBeforeMount.payableInstituteInfo,
                            billMonth: '',
                            monthlyRate: '',
                            billMonth: '',
                            payableAmount: '',
                            totalStudent: '',
                            perMonthRate: '',
                            payableInstituteID: '',
                            errorMsgVisible: false,
                            dataTableIsLoading: false
                        });
                    }else if(res.status == 400){
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resBody, dataTableIsLoading: false })
                        });
                    } 
                    else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resBody, dataTableIsLoading: false })
                        });
                    }
                }).catch(error => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, dataTableIsLoading: false })
                }
                );
        }

    }

    prePaidBillFormError = () => {
        let { prePaidBillError, prepaidBillInfo } = this.state;
        this.setState({ topProgressBar: false, dataTableIsLoading: false });
        let formIsValid = true;

        if (this.state.payableInstituteID === '' || this.state.payableInstituteID === null) {
            formIsValid = false;
            prePaidBillError["payableInstitute"] = "Institute can't left empty.";
        }
        if (this.state.totalStudent === '') {
            formIsValid = false;
            prePaidBillError["totalStudent"] = "Total Student can't left empty.";
        }
        if (this.state.billMonth === '') {
            formIsValid = false;
            prePaidBillError["billMonth"] = "Bill Month can't left empty.";
        }
        if (prepaidBillInfo.paymentDetails === '') {
            formIsValid = false;
            prePaidBillError["requestNote"] = "Note can't left empty.";
        }
        this.setState({ prePaidBillError });
        return formIsValid;
    }


    render() {
        let { payableInstituteInfo, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let billMonthList = [
            { label: '6', value: '6' },
            { label: '12', value: '12' },
            { label: '18', value: '18' },
            { label: '24', value: '24' },
            { label: '30', value: '30' }

        ];

        let payabaleInstitute = [];
        if ((this.state.payabaleInstituteList != null) && (this.state.payabaleInstituteList.length > 0)) {
            payabaleInstitute = this.state.payabaleInstituteList.map((item) => ({
                value: item.emDetailsId,
                label: item.instituteId + " - " + item.instituteName,
            }));
        }

        return (

            <div className="p-fluid">
                {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                <Growl ref={(el) => this.growl = el} />


                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                        <div className="p-grid seprator-inside">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        {this.state.firstDropdownIsLoading ? this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Payable Institute <span>*</span></span>
                                                <Dropdown
                                                    value={this.state.payableInstituteID}
                                                    options={payabaleInstitute}
                                                    onChange={this.onChangePayableInstitute}
                                                    placeholder="Select Institute"
                                                    filter={true}
                                                    filterBy="value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="payableInstitute"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{this.state.prePaidBillError["payableInstitute"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Institute ID
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {payableInstituteInfo.instituteId}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Institute Name
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {payableInstituteInfo.instituteName}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Package
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {payableInstituteInfo.packageCoreCategoryInfoDTO.categoryName}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Rate Per Student
                                                    </span>
                                            <div className="nw-inputgroup-desc">
                                                {payableInstituteInfo.monthlyRate}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Total Student <span>*</span></span>
                                            <InputText
                                                onChange={this.onChangeTotalStudent}
                                                value={this.state.totalStudent}
                                                keyfilter="pint"
                                                placeholder="Enter Total Student"
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="totalStudent"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.prePaidBillError["totalStudent"]}</span>

                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Bill Month <span>*</span></span>
                                            <Dropdown
                                                value={this.state.billMonth}
                                                options={billMonthList}
                                                onChange={this.onChangeBillMonth}
                                                placeholder="Select Bill Month"
                                                filter={true}
                                                showClear={true}
                                                name="billMonth"
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.prePaidBillError["billMonth"]}</span>

                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Bill Amount
                                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.perMonthRate ? `(${this.state.perMonthRate}/m) (${this.ValidatorUtility.currencyFormatter(this.state.payableAmount)}/${this.state.billMonth}m)` : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Payable Amount
                                                        </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.payableAmount ? `${this.state.payableAmount.toLocaleString('EN-IN')} Tk.` : ''}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                            <InputTextarea
                                                value={this.state.prepaidBillInfo.paymentDetails}
                                                onChange={this.onChangePaymentDetails}
                                                placeholder="Enter Note"
                                                rows={3}
                                                cols={30}
                                                autoResize={true}
                                                name="requestNote"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.prePaidBillError["requestNote"]}</span>

                                    </div>

                                    <div className="p-col-12 p-xl-12" />


                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Pay Bill"
                                            icon="fas fa-hand-holding-usd"
                                            onClick={this.onSubmitHandler}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>




                    </div>
                    <div className="p-col-12 p-xl-4">
                        {!this.state.dataTableIsLoading ? (
                            <div className="layout-dashboard">
                                <WalletBalance
                                    walletBalanceName='Wallet'
                                />
                            </div>
                        ) : (
                                this.NetiContentLoader.WalletLoaderContent()
                            )}
                    </div>
                </div>
            </div>
        );
    }
}