import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { Growl } from 'primereact/growl';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { DownloadButton } from "../../../utils/DownloadButton";
import { pdfDownloadLandscape_FullTable } from '../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import CommonFuctionality from '../../common/CommonFuctionality';
import { SplitButton } from 'primereact/splitbutton';

let totalDiscountAmount;
let maxDate = new Date();

// for pdf
let subTitleColumn = [];
let subTitleList = [];
export class CollectiveStatementDiscount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            moneyReceipt: '',
            discountDetailsInfo: {},
            discountSubmitInfo: {
                startDate: '',
                endDate: ''
            },
            discountInstituteList: [],
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,

            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }

        this.netiFileHandler = new NetiFileHandler();
        this.onHide = this.onHide.bind(this);
        this.discountDetailsBody = this.discountDetailsBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.managementPointService = new ManagementPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
        this.CommonFuctionality = new CommonFuctionality();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);
    }

    onChangeFromDate = (e) => {
        let { discountSubmitInfo, errors } = this.state;
        errors["fromDate"] = "";
        discountSubmitInfo.startDate = e.target.value
        this.setState({ discountSubmitInfo, errors });
    }

    onChangeToDate = (e) => {
        let { discountSubmitInfo, errors } = this.state;
        errors["toDate"] = "";
        discountSubmitInfo.endDate = e.target.value
        this.setState({ discountSubmitInfo, errors });
    }

    onSubmitDiscount = () => {
        let submitObj = { ...this.state.discountSubmitInfo }
        totalDiscountAmount = 0;
        submitObj.pageLimit = 10;
        submitObj.pageNo = 0;
        submitObj.startDate = NetiDateUtils.getDateFromString(this.state.discountSubmitInfo.startDate)
        submitObj.endDate = NetiDateUtils.getDateFromString(this.state.discountSubmitInfo.endDate)

        if (this.onSubmitDiscountFormError()) {
            if (Date.parse(this.state.discountSubmitInfo.endDate) >= Date.parse(this.state.discountSubmitInfo.startDate)) {
                this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
                this.managementPointService.getEdumanCollectiveStatementDiscountByDate(submitObj)
                    .then(res => {
                        if (res.status == 302) {
                            return res.json().then((body) => {
                                body.map(item => {
                                    item.partnerNetiID = item.partnerNetiID.toString();
                                    item.date = NetiDateUtils.getAnyShortForm(item.date, 'DD-MMM-YYYY hh:mm:ss a');
                                    item.discountAmount = item.discountAmount.toFixed(2);
                                })
                                totalDiscountAmount = body.map(item => +item.discountAmount).reduce((a, b) => { return (a + b) }, 0);
                                this.setState({ discountInstituteList: body });
                                this.setState({ topProgressBar: false, dataTableIsLoading: false });
                            })
                        }
                        else {
                            this.managementPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                                });
                        }
                    }).catch((error) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
                    });
            } else {
                this.growl.show({ severity: 'warn', summary: 'Warning', detail: "To Date can not be earlier than From Date." });
            }
        }
    }

    onSubmitDiscountFormError = () => {
        let { errors, discountSubmitInfo } = this.state;
        let formIsValid = true;

        if (discountSubmitInfo.startDate === '') {
            formIsValid = false;
            errors["fromDate"] = "Start Date can't left empty.";
        }
        if (discountSubmitInfo.endDate === '') {
            formIsValid = false;
            errors["toDate"] = "End Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    viewDialog(rowData) {
        this.setState({ discountDetailsInfo: rowData });
        // if (rowData.attachmentPath !== null) {
        //     this.netiFileHandler.getByteImage(rowData.attachmentPath)
        //         .then((res) => {
        //             if (res.status == 200) {
        //                 return res.json()
        //                     .then((body) => {
        //                         let contentType = this.netiFileHandler.getImageContentType(rowData.attachmentName);
        //                         this.setState({ moneyReceipt: contentType + body.fileContent });

        //                     })
        //             } else {
        //             }
        //         });
        // }
        this.setState({ visible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();

    }

    onHide(event) {
        this.setState({ visible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();

    }

    discountDetailsBody(rowData, column) {

        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-info"
                title='Click to view deposit details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    discountAmountBody = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData.discountAmount)} </div>)
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Date & Time", dataKey: "date" },
            { title: "Institute ID", dataKey: "instituteId" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Discount Amount", dataKey: "discountAmount" },
            { title: "Partner Neti ID", dataKey: "partnerNetiID" },
            { title: "Partner Name", dataKey: "partnerName" },
        ];

        let tableColStyle = { discountAmount: { halign: 'right' } };

        var totalArrayList = [
            {
                id: "1",
                totalPdf: "Total Discount Amount : " + totalDiscountAmount,
            }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }];

        let totalColumnStyles = {
            totalPdf: {
                halign: "center",
                fontSize: 10,
                fontStyle: "bold", overflow: "linebreak"
            },

        }

        pdfDownloadLandscape_FullTable("EM Collective Discount Bill Report", 110, subTitleColumn, subTitleList, pdfColumns, this.state.discountInstituteList, tableColStyle, totalColumns, totalArrayList, totalColumnStyles, "EM_Discount_Paid_Bill_Report.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, discountDetailsInfo, discountInstituteList } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (this.state.discountSubmitInfo.startDate && this.state.discountSubmitInfo.endDate) {
            formatedStartDate = (this.state.discountSubmitInfo.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (this.state.discountSubmitInfo.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = formatedStartDate + ' to ' + formatedEndDate + " Collective_Discount_Em_Bill.xlsx";
        excelColumns = [
            <Workbook.Column value="date" label="Date & Time" />,
            <Workbook.Column value="instituteId" label="Institute ID" />,
            <Workbook.Column value="instituteName" label="Institute Name" />,
            <Workbook.Column value="discountAmount" label="Discount Amount" />,
            <Workbook.Column value="partnerNetiID" label="Partner Neti ID" />,
            <Workbook.Column value="partnerName" label="Partner Name" />,
        ];
        // end excel

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Institute List</div><div className="header-title-right"><a>Total Found: {this.state.discountInstituteList.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        if (discountInstituteList.length == 0) {
            totalDiscountAmount = 0;
        }

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
                <Column footer={this.ValidatorUtility.currencyFormatter(totalDiscountAmount)} />
                <Column footer="" colSpan={3} />
            </Row>
        </ColumnGroup>;
        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-5 p-lg-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={this.state.discountSubmitInfo.startDate}
                                        onChange={this.onChangeFromDate}
                                        showIcon={true}
                                        dateFormat="dd/mm/yy"
                                        name="fromDate"
                                        showIcon={true}
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["fromDate"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-5 p-lg-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={this.state.discountSubmitInfo.endDate}
                                        onChange={this.onChangeToDate}
                                        showIcon={true}
                                        dateFormat="dd/mm/yy"
                                        name="toDate"
                                        showIcon={true}
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["toDate"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-12 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitDiscount} />
                            </div>
                        </div>

                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table nw-data-table-tabview">
                                    {
                                        this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            :
                                            <div>
                                                <DataTable
                                                    header={tableHeader}
                                                    footerColumnGroup={footer}
                                                    responsive={true}
                                                    selectionMode="single"
                                                    value={this.state.discountInstituteList}
                                                    //selection={this.state.dataTableSelection}
                                                    paginator={true}
                                                    rows={20}
                                                    rowsPerPageOptions={[20, 50, 100]}
                                                >
                                                    <Column field="date" header="Date & Time" className="table-datetime" filter={true} />
                                                    <Column field="instituteId" header="Institute ID" filter={true} />
                                                    <Column field="instituteName" header="Institute Name" filter={true} />
                                                    <Column field="discountAmount" header="Discount Amount" body={this.discountAmountBody} filter={true} />
                                                    <Column field="partnerNetiID" header="Partner Neti ID" filter={true} />
                                                    <Column field="partnerName" header="Partner Name" filter={true} />
                                                    <Column field="details" header="Details" body={this.discountDetailsBody} style={{ width: '95px' }} />

                                                </DataTable>

                                                {/* <div className="nw-button-parent m-t-8 p-r-0">

                                                    <Workbook
                                                        filename={excelFileName}
                                                        element={
                                                            <Button
                                                                label="Download Excel"
                                                                icon="fas fa-download"
                                                                className="p-button p-button-primary nw-button nw-button-right"
                                                            />
                                                        }
                                                    >
                                                        <Workbook.Sheet data={this.state.discountInstituteList} name={'collective_discount_institutes'}>
                                                            {excelColumns}
                                                        </Workbook.Sheet>
                                                    </Workbook>

                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                        label="Download PDF"
                                                        icon="fas fa-download"
                                                        onClick={this.exportPdf}
                                                    />
                                                </div> */}
                                            </div>
                                    }

                                </div>
                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                <div style={{ display: 'none' }}>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                label="Download Excel"
                                                id="clickExcelBtn"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.discountInstituteList} name={'collective_discount_institutes'}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                id="clickExcelBtn"
                                                label="Download Excel"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.collectivePaidInstituteList} name={'collective_paid_institutes'}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>

                            </div >

                        </div>
                    </div>
                </div>

                <Dialog className="nw-dialog" header="Payment Information" visible={this.state.visible} onHide={this.onHide} maximizable>
                    <div className="nw-dataview-small-panel">
                        <div>
                            <div>Bill Month</div>
                            <div>:</div>
                            <div> {discountDetailsInfo.billMonth}</div>
                        </div>

                        <div>
                            <div>Payable Amount</div>
                            <div>:</div>
                            <div> {discountDetailsInfo.payableBill}</div>
                        </div>

                        <div>
                            <div>Discount Amount</div>
                            <div>:</div>
                            <div> {discountDetailsInfo.discountAmount}</div>
                        </div>

                        <div>
                            <div>Paid Amount</div>
                            <div>:</div>
                            <div> {discountDetailsInfo.paidAmount}</div>
                        </div>

                        <div>
                            <div>Paid By(Neti ID)</div>
                            <div>:</div>
                            <div> {discountDetailsInfo.partnerNetiID}</div>
                        </div>

                        <div>
                            <div>Paid By(Name)</div>
                            <div>:</div>
                            <div> {discountDetailsInfo.partnerName}</div>
                        </div>

                        <div>
                            <div>Money Receipt</div>
                            <div>:</div>
                            {/* <div> <a download={discountDetailsInfo.attachmentName} href={this.state.moneyReceipt ? this.state.moneyReceipt : null}>Download</a></div> */}
                            <div>Download <DownloadButton fileName={discountDetailsInfo.attachmentName} filePath={discountDetailsInfo.attachmentPath} /></div>
                        </div>

                    </div>
                </Dialog>

            </div>
        )
    }
}