import React, { Component } from "react";
import { AppTopbar } from '../../../../AppTopbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from "primereact/dropdown";
import { TabView } from 'primereact/tabview';
class PaymentStatus extends Component {
    constructor(){
        super();
        this.state = {

        }
    }

    redirectMarcentPage = (e) => { 
        this.props.history.replace("/marchant");
    }
    render(){
        return(
            <div className="p-fluid">
                <div className="p-fluid blur-section">
                    <div className="paymentStatus">
                        <h1>Eduman Portal</h1>
                    </div>
                </div>
                <br />
                <div className="successPayment">
                    <div className="successTop">
                        <div className="iconCentralized">
                            <i class="fas fa-check"></i>
                        </div>
                        <h3>Success</h3>
                    </div>
                    <div className="successBottom">
                        <br />
                        <h2>Congratulations, Payment Successful</h2>

                        <button className="p-link" onClick={this.redirectMarcentPage.bind(this)}>
                            <h2>Go back Home</h2>
						</button>            
                    </div>
                    <br />
                </div>
            </div>
        )
    }
}
export default PaymentStatus;