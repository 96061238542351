import React, { Component } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { Link } from "react-router-dom";
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { pdfDownloadPortrait_TableOnly, pdfDownloadLandscape_TableOnly } from '../common/JsPdfDownload';
import { ValidatorUtility } from '../../utils/ValidatorUtility';

export class PartnerPointRevenueDistribution extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emBillGenerateLogIds: '',
            searchObjInfo:{
                fromDate: '',
                toDate: ''
            },
            viewDetailsObject:{

            },
            dataTableValue: [],
            tableView: false,
            errors: {},
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody:false
        };

        this.NetiContentLoader = new NetiContentLoader();
        this.partnerPointService = new PartnerPointService();
        this.validatorUtility = new ValidatorUtility();

    }

    componentWillMount(){
        this.fetchProductList();
    }

    fetchProductList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true,  errorMsgVisible: false });
        this.partnerPointService.fetchPartnerProductList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for(let i; i< body.length; i++) {
                            body[i]['actualRevenue'] = this.validatorUtility.currencyFormatter(body[i]['actualRevenue']);
                            body[i]['myRevenue'] = this.validatorUtility.currencyFormatter(body[i]['myRevenue']);
                            body[i]['priceWithVat'] = this.validatorUtility.currencyFormatter(body[i]['priceWithVat']);
                            body[i]['unitPrice'] = this.validatorUtility.currencyFormatter(body[i]['unitPrice']);
                            body[i]['unitPrice'] = this.validatorUtility.currencyFormatter(body[i]['unitPrice']);
                            body[i]['vatPercentage'] = this.validatorUtility.currencyFormatter(body[i]['unitPrice']`%`); 

                        }
                        setTimeout(() => {
                            this.setState({dataTableValue: body});
                        }, 500);
                        
                        this.setState({  topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }


    // exportPdf = () => {
       
    //     let pdfColumns = [
    //         { title: "Item Name", dataKey: "itemName" },
    //         { title: "Unit Price", dataKey: "unitPrice" },
    //         { title: "Vat Percent", dataKey: "vatPercentage" },
    //         { title: "Price With Vat", dataKey: "priceWithVat"},
    //         { title: "My Revenue", dataKey: "myRevenue" },
    //         { title: "AIT Diduction", dataKey: "aitDeduction" },
    //         { title: "Actual Revenue", dataKey: "actualRevenue" },

    //     ]
        
    //     let tableColumnStyles = {
    //         requestedAmount: { halign: "right"},
    //     }

    //     pdfDownloadLandscape_TableOnly("Wallet Logs Deposite",120, pdfTitleHeader, pdfTitleHeaderData, pdfColumns, this.state.dataTableValue, tableColumnStyles,  "wallet_logs_deposite.pdf");
          
    // }



    render() {
        let {  dataTableValue } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="header-title">
            <div className="header-title-left">
                Product List
            </div>

            <div className="header-title-right">
                <Link to="#">
                    Total Found: {dataTableValue.length.toLocaleString('EN-IN')}
                </Link>
            </div>
        </div>;


        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="p-grid nw-form">
                      

                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                            <div className="nw-data-table">
                                {
                                    
                                        this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            :
                                            <DataTable
                                                value={dataTableValue}
                                                header={tableHeader}
                                                responsive={true}
                                                paginator={true}
                                                rows={10}
                                            >
                                                <Column field="itemName" header="Item Name" />
                                                <Column field="unitPrice" className="text-right" header="Unit Price" />
                                                <Column field="vatPercentage" header="Vat Percent" className="text-right" />
                                                <Column field="priceWithVat" header="Price With Vat" className="text-right" />
                                                <Column field="myRevenue" header="My Revenue" className="text-right"/>
                                                <Column field="aitDeduction" header="AIT Diduction" />
                                                <Column field="actualRevenue" header="Actual Revenue" className="text-right"/>
                                            </DataTable>
                                       
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
