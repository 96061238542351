import { BaseURIHolder } from '../../utils/BaseURIHolder';
import UnAuthService from '../../auth/UnAuthService';



export class EdumanActionLogService {
    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.unAuth = new UnAuthService();
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    getHost() {
        var HOST = 'https://search-eduman6-es-ekmj5u3tgt7nn4zdfi7twocilm.ap-northeast-1.es.amazonaws.com/_opendistro/_sql?format=jdbc';
        return HOST;
    }

    postFetch(url, data) {
        const headers = this.headers;
        return fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(data) });
    }

    getActionTypeWiseEmModuleLog(searchQueryInfo) {
        let uri = this.getHost();
        return this.postFetch(uri, searchQueryInfo).then(res => {
            return res.json();
        }).then(res => {
            if (res.status === 200) {
                let objSet = {};
                let dataset = res.datarows.map((item) => {
                    objSet.moduleName = item[0];
                    objSet.actions = parseInt(item[1]);
                    return Object.assign({}, objSet);
                });
                return dataset;
            }
        });
    }

    getEmModuleWiseInstituteActionLog(searchQueryInfo) {
        let uri = this.getHost();
        return this.postFetch(uri, searchQueryInfo).then(res => {
            return res.json();
        }).then(res => {
            if (res.status === 200) {
                console.log("res", res);
                let objSet = {};
                let dataset = res.datarows.map((item) => {
                    objSet.moduleName = item[0];
                    objSet.actions = parseInt(item[1]);
                    return Object.assign({}, objSet);
                });
                return dataset;
            }
        });
    }

    getEmModuleUsedUnusedActionLog(searchQueryInfo) {
        let uri = this.getHost();
        return this.postFetch(uri, searchQueryInfo).then(res => {
            return res.json();
        }).then(res => {
            if (res.status === 200) {
                console.log("res", res);
                let objSet = {};
                let dataset = res.datarows.map((item) => {
                    objSet.instituteID = parseInt(item[0]);
                    objSet.userID = item[1];
                    objSet.actions = parseInt(item[2]);
                    return Object.assign({}, objSet);
                });
                return dataset;
            }
        });
    }

    getEmInstituteWiseActionLog(searchQueryInfo) {
        let uri = this.getHost();
        return this.postFetch(uri, searchQueryInfo).then(res => {
            return res.json();
        }).then(res => {
            if (res.status === 200) {
                console.log("res", res);
                let objSet = {};
                let dataset = res.datarows.map((item) => {
                    objSet.module = item[0];
                    objSet[item[1]] = parseInt(item[2]);
                    return Object.assign({}, objSet);
                });
                return dataset;
            }
        });
    }

    getEmModuleActionTypeWiseDetailsLog(searchQueryInfo) {
        let uri = this.getHost();
        return this.postFetch(uri, searchQueryInfo).then(res => {
            return res.json();
        }).then(res => {
            if (res.status === 200) {
                console.log("res", res);
                let objSet = {};
                let dataset = res.datarows.map((item) => {
                    objSet.dateTime = item[0];
                    objSet.pageName = item[1];
                    objSet.userName = item[2];
                    objSet.userIP = item[3];
                    objSet.browser = item[4];
                    objSet.os = item[5];
                    return Object.assign({}, objSet);
                });
                return dataset;
            }
        });
    }

    getEdumanInstituteNames(packageName) {
        let uri = this.baseURIHolder.getEm() + `/institutes/by?packageName=${packageName}`;
        return this.unAuth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEmLoginInfo(searchQueryInfo) {
        let uri = this.getHost();
        return this.postFetch(uri, searchQueryInfo).then(res => {
            return res.json();
        }).then(res => {
            if (res.status === 200) {
                let objSet = {};
                let countTotalAllId
                let countId = 1

                countTotalAllId = res.datarows.map(item => parseInt(item[1])).reduce((a, b) => (a + b));
                
                objSet={
                    totalEdumanLoginUser: res.datarows.length,
                    totalEdumanLoginTime: countTotalAllId,
                }
                
                return objSet;
            }
        });
    }


}

