import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../myPoint/common/NetiContentLoader';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import CommonFuctionality from '../../common/CommonFuctionality';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import demoImage from '../../../assets/images/avatar.png'
import { log } from 'fullcalendar';
import { Editor } from 'primereact/editor';

import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ImageCropper } from '../../common/ImageCropper';

let urlId;
let imageType = '';
let errors = {};
export class AdminAboutUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            visible: false,
            listVisible: false,
            updateVisible: false,
            dialogLoad: false,
            deleteVisible: false,
            dataTableIsLoading: false,
            aboutUsContentList: [],
            uploadImage: {
                fileContent: '',
                fileName: '',
                fileSaveOrEditable: false
            },
            uploadFile: {
                fileContent: '',
                fileName: '',
                fileSaveOrEditable: false
            },
            reqObject: {
                aboutusDetails: '',
                aboutusNote: '',
                aboutusType: '',
                cmsId: '',
                fileContent: '',
                fileName: '',
                fileSaveOrEditable: true
            },
            errors: {},
            photoGalleryId: "",
            imageType: "",
            urlId: "",
            imageInputFieldIsLoading: false,
            homeReturnButton: true,
            dialogType: '',
            dialogTitle: '',

            cropperVisible: false,
            cropperObject: {
                //main class parameter
                main: {
                    viewport: { width: 620, height: 380 },
                    boundary: { width: 720, height: 580 },
                    showZoomer: true,
                    enableOrientation: false,
                },
                //bind parameter
                bind: {
                    url: '',
                    orientation: 4
                }

            }
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.NetiFileHandler = new NetiFileHandler();
        this.reloadCoreUrlIdFromLocal();
    }

    async reloadCoreUrlIdFromLocal() {
        urlId = await this.DwsService.getCmsIdFromLocalStorage();
        if (urlId) {
            this.setState({ homeReturnButton: false, urlId: urlId });
            this.fetchAboutUsContentList(urlId);
        } else {
            this.setState({ homeReturnButton: true });
        }
    }

    fetchAboutUsContentList = (urlId) => {
        if (urlId) {
            this.setState({ topProgressBar: true, errorMsgVisible: false })
            this.DwsService.cmsFetchAboutUsList(urlId)
                .then(res => {
                    return res.json().then((body) => {
                        if (body.messageType === 1) {
                            this.setState({ aboutUsContentList: body.item });
                            this.setState({ topProgressBar: false, errorMsgVisible: false })

                            let divId = document.getElementsByClassName('about_us')
                            // console.log('divId', divId);

                            if (divId) {
                                for (let i = 0; i < divId.length; i++) {
                                    if (divId[i].children.length > 2) {
                                        for (let j = 0; j < divId[i].children.length; j++) {
                                            if (divId[i].children[3] || divId[i].children[2]) {
                                                divId[i].children[3].remove();
                                                divId[i].children[2].remove();
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }

                    });
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }

    openDialog = (dialogType, dialogTitle, item) => {

        // console.log('dialogType......', dialogType, dialogTitle, item, urlId);

        let { reqObject, uploadFile, errors } = this.state;
        this.setState({ topProgressBar: false, errorMsgVisible: false, visible: true, dialogLoad: true, dialogType: dialogType, dialogTitle: dialogTitle });
        this.CommonFuctionality.blurDialogBackgroundActive();

        if (dialogType == 'view') {
            // this.fetchAboutUsSingleContent(item);
            reqObject = { ...item }
            this.setState({ reqObject });
        }
        if (dialogType == 'add') {
            // reqObject = {...item}
            reqObject.aboutusDetails = '';
            reqObject.fileContent = '';
            reqObject.fileName = '';
            reqObject.aboutusNote = '';
            reqObject.cmsId = urlId;
            reqObject.aboutusType = item;

            uploadFile.fileName = '';
            uploadFile.fileContent = '';
            uploadFile.fileSaveOrEditable = false;
            this.setState({ reqObject, uploadFile });
        }

        if (dialogType == 'update') {
            this.setState({ updateVisible: true, });
            // this.fetchAboutUsSingleContent(item);aboutusId
            reqObject.aboutusId = item.aboutusId;
            reqObject.aboutusDetails = item.aboutusDetails;
            reqObject.aboutusNote = item.aboutusNote;
            reqObject.aboutusType = item.aboutusType;
            reqObject.fileContent = item.fileContent
            reqObject.fileName = item.fileName
            reqObject.fileSaveOrEditable = true

            uploadFile.fileContent = item.fileContent
            uploadFile.fileName = item.fileName
            uploadFile.fileSaveOrEditable = true

            // reqObject = {...item}
            reqObject.cmsId = urlId
            this.setState({ reqObject, uploadFile });
        }
        errors = {}
        this.setState({ errors });
    }

    hideDialog = (value) => {
        if (value == 'updateImage') {
            this.setState({ visible: false, updateVisible: false, dialogLoad: false });
        } else {
            this.setState({ visible: false, updateVisible: false, dialogLoad: false });
            this.CommonFuctionality.blurDialogBackgroundDeActive()
        }
        this.hideImageCropper();
        //this.setState({ visible: false, dialogLoad: false });
    }

    openDeleteDialog = (contentType, item) => {
        let { reqObject } = this.state;
        this.setState({ deleteVisible: true, dialogLoad: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
        reqObject = { ...item }
        this.setState({ reqObject });
        // console.log('contentType-', contentType, 'urlId-', urlId);
        // this.fetchAboutUsSingleContent( contentType, item )
    }

    hideDeleteDialog = () => {
        this.setState({ deleteVisible: false, dialogLoad: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();

    }

    // onChangeGalleryDetails = (e) => {
    //     let { reqObject, errors } = this.state;
    //     let value = e.target.value;
    //     reqObject.aboutusDetails = value;
    //     errors["aboutusDetails"] = '';
    //     this.setState({ reqObject, errors });
    // }

    onChangeDescription = (e) => {
        let { reqObject, errors } = this.state;
        errors["aboutusDetails"] = "";

        reqObject["aboutusDetails"] = e.htmlValue;

        this.setState({ reqObject, errors })
        // this.onClearErrorMsg("speechDetails");
    }

    onLoadPic = (e) => {

        let { errors, cropperObject } = this.state;

        let fileType = this.NetiFileHandler.getFileContentType(e.files[0].name);
        let supportedExtention = ['image/jpeg', 'image/jpg', 'image/png'];
        // console.log('fileType', fileType);
        if (supportedExtention.includes(fileType)) {

            var reader = new FileReader();
            let photo = e.files[0];
            const scope = this
            reader.readAsDataURL(photo);
            reader.onload = () => {
                let content = reader.result;
                var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                let album = {
                    fileContent: urlStr,
                    fileName: photo.name,
                    fileSaveOrEditable: true
                };

                Object.assign(this.state.reqObject, album)
                errors['uploadImage'] = ''
                scope.setState({ uploadFile: album, errors });
            }

            errors["uploadImage"] = '';
            cropperObject.bind.url = photo.objectURL;
            this.setState({ errors, cropperObject, cropperVisible: true });

        } else {
            errors["uploadImage"] = "File format not supported";
            this.setState({ errors });
        }

    }

    getCroppedResult = (imageObj) => {

        let { uploadFile, reqObject, errors } = this.state;
        let maxSize = this.NetiFileHandler.getMaxFileSizeIsValid(imageObj.photoBlob.size, 500000);
        if (maxSize) {
            uploadFile.fileContent = imageObj.contentPic;
            reqObject.fileContent = imageObj.contentPic;
            errors["uploadImage"] = '';
        } else {
            errors["uploadImage"] = "Image size can't be more than 500 KB";
            this.removeCropSection();
        }
        this.hideImageCropper();
        this.setState({ uploadFile, reqObject, errors });
    }

    hideImageCropper = () => {
        this.setState({ cropperVisible: false });
    }

    removeCropSection = () => {

        let { uploadFile } = this.state;
        uploadFile.fileContent = '';
        uploadFile.fileName = '';
        uploadFile.fileSaveOrEditable = false;

        this.setState({ cropperVisible: false, uploadFile });
    }

    photoView = rowData => {
        let staticImg = '../assets/layout/images/avatar.png';
        return (
            <div className="p-grid download-software">
                <div className="p-col-12 p-xl-3">
                    <div className="table-image">
                        {
                            rowData.photoContent ?
                                <img src={("data:image/png;base64," + rowData.photoContent)} style={{ width: "80px" }} /> :
                                <img src={staticImg} style={{ width: "80px" }} />
                        }
                    </div>
                </div>
                <div className="p-col-12 p-xl-9">
                    <h4>{rowData.photoTitle}</h4>
                    <p>{rowData.photoDetails}</p>
                </div>
            </div>
        )
    }

    errorHandler = () => {
        let { reqObject, errors } = this.state;
        let formIsValid = true;

        if (!reqObject.aboutusDetails) {
            formIsValid = false;
            errors["aboutusDetails"] = "Details Info can't left empty.";
        }
        if (!reqObject.fileContent || !this.state.uploadFile.fileContent) {
            formIsValid = false;
            errors["uploadImage"] = "Upload image can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onclickSubmitBtn = (value) => {
        let { reqObject } = this.state;
        // console.log('reqObject send', reqObject, value);
        if (this.errorHandler()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.DwsService.cmsInserAboutUs(reqObject, value)
                .then(res => {
                    if (res.status == 201 && value == "submit") {
                        this.CommonFuctionality.blurDialogBackgroundDeActive();
                        this.setState({ topProgressBar: false, errorMsgVisible: false, visible: false, dialogLoad: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: reqObject.aboutusType + ' Successfully Saved' });
                        this.fetchAboutUsContentList(urlId);
                    }
                    else if (res.status == 201 && value == "update") {
                        this.CommonFuctionality.blurDialogBackgroundDeActive();
                        this.setState({ topProgressBar: false, errorMsgVisible: false, visible: false, dialogLoad: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: reqObject.aboutusType + ' Successfully Updated' });
                        this.fetchAboutUsContentList(urlId);
                    }
                    else {
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'error', summary: 'error', detail: 'Unsuccessfully Saved' });
                    }

                }).catch(error => {
                    this.setState({ errors: errors })
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                })
        }

    }

    deleteSubmit = (aboutUsId) => {
        // console.log('aboutUsId', aboutUsId);
        if (aboutUsId) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.DwsService.cmsDeleteAboutUs(aboutUsId)
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, deleteVisible: false, dialogLoad: false });
                        this.CommonFuctionality.blurDialogBackgroundDeActive();
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully Deleted' });
                        this.fetchAboutUsContentList(urlId);
                    } else {
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'error', summary: 'error', detail: 'Unsuccessfully Delete' });
                    }
                }).catch(error => {
                    this.setState({ errors: errors })
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                })
        }
    }

    removeUploadFile = () => {
        let { reqObject, uploadFile } = this.state

        uploadFile.fileContent = ''
        uploadFile.fileName = ''
        uploadFile.fileSaveOrEditable = false

        this.setState({ uploadFile })

        // Object.assign(insertUserObj, uploadFile)
        // Object.assign(updateUserObj, uploadFile)
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, updateVisible, dialogType, reqObject, errors, photoGalleryId, urlId, aboutUsContentList } = this.state;

        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">(Max 500 KB (recommended)</span></div>

        let data = [
            { aboutusType: "History" },
            { aboutusType: "Infrastructure" },
            { aboutusType: "Class Room" },
            { aboutusType: "Library" },
            { aboutusType: "Laboratory" },
            { aboutusType: "Computer Lab" },
            { aboutusType: "Playground" },
        ];

        return (
            <div className="p-fluid" >
                <NetiCMSSetupProgress />

                <Growl ref={(el) => this.growl = el} />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="layout-dashboard">
                    {this.state.homeReturnButton === false ?
                        <div className="p-fluid">
                            <div className="main-section download blur-section">
                                <div className="p-grid download-software">

                                    {
                                        data.map((typeItem, typeIndex) =>
                                            <div className="p-col-12 p-xl-6">
                                                <div class="description">
                                                    {
                                                        this.state.topProgressBar ?
                                                            '' :

                                                            <div class="p-col-12 p-xl-12 ui-g-nopad description-inside about_us" id={'about_us_' + typeIndex}>

                                                                {
                                                                    aboutUsContentList && aboutUsContentList.map((item, index) =>

                                                                        item.aboutusType != typeItem.aboutusType ?
                                                                            '' :

                                                                            <React.Fragment>
                                                                                <div class="p-col-2 ui-g-nopad">
                                                                                    <img src={item.fileContent ? (`data:image/*;base64, ${item.fileContent}`) : demoImage} style={{ width: "100%" }} />
                                                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                        {/* <Link /*onClick={() => this.openDialog('view', `View ${item.aboutusType} Content`, item)}*//* to="#"><i className="fas fa-eye"></i></Link> */}
                                                                                        <a><i className="fas fa-chevron-circle-right"></i></a>
                                                                                        <Link
                                                                                            to="#"
                                                                                            onClick={() => this.openDialog('update', `Update ${item.aboutusType} Content`, item)}
                                                                                            tooltip={item.aboutusType + " Update"}
                                                                                        >
                                                                                            <i className="fas fa-pencil-alt"></i>
                                                                                        </Link>
                                                                                        <Link
                                                                                            to="#"
                                                                                            onClick={() => this.openDeleteDialog(item.aboutusType, item)}
                                                                                            tooltip={item.aboutusType + " Delete"}
                                                                                        >
                                                                                            <i className="fas fa-minus-circle text-danger"></i>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="p-col-10">
                                                                                    <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                                                        <label>{typeItem.aboutusType}</label>
                                                                                        {!item.aboutusDetails == '' ?
                                                                                            // <p>{item.aboutusDetails}</p>
                                                                                            <div className="p-col-12 p-xl-12">
                                                                                                <div className="p-inputgroup">
                                                                                                    <div className="nw-inputgroup-desc" style={{ height: "auto" }}>
                                                                                                        <Editor
                                                                                                            value={item.aboutusDetails}
                                                                                                            className="nw-inputtexteditor editor-only-view cms-aboutus"
                                                                                                            readOnly={true}
                                                                                                            headerTemplate={e => { return null }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            : ''}
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                    )
                                                                }
                                                                <React.Fragment>
                                                                    <div class="p-col-2 ui-g-nopad">
                                                                        <Button
                                                                            className=""
                                                                            icon="fas fa-plus"
                                                                            onClick={() => this.openDialog('add', `Add ${typeItem.aboutusType} Content`, typeItem.aboutusType, urlId)}
                                                                            tooltip={typeItem.aboutusType + " Add"}
                                                                            tooltipOptions={{ position: "bottom" }}
                                                                        />
                                                                    </div>
                                                                    <div class="p-col-10">
                                                                        <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                                            <label>{typeItem.aboutusType}</label>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>

                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="dialog-section">
                    <Dialog
                        header={this.state.dialogTitle}
                        visible={this.state.visible}
                        modal={true}
                        onHide={() => this.hideDialog('uploadImage')}
                        className="nw-dialog"
                        maximizable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12">
                                    {/* <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Detailssss <span>*</span></span>
                                            <InputTextarea
                                                id="details"
                                                onChange={(e) => this.onChangeGalleryDetails(e)}
                                                value={reqObject.aboutusDetails}
                                                name="aboutusDetails"
                                                rows={5}
                                                cols={30}
                                                placeholder="Write Image Details"
                                                readOnly={dialogType == 'view' ? true : false}
                                            />
                                        </div>
                                        <span className='error-message'>{errors['aboutusDetails']}</span>
                                    </div> */}

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtexteditor-label">Details <span>*</span></span>
                                            <Editor
                                                className="nw-inputtexteditor"
                                                name="aboutusDetails"
                                                value={reqObject.aboutusDetails}
                                                onTextChange={(e) => this.onChangeDescription(e, dialogType)}
                                            />
                                        </div>
                                        <span className="error-message">{errors["aboutusDetails"]}</span>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload Image <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/JPEG)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                {
                                                    this.state.uploadFile.fileContent ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={this.removeUploadFile}
                                                                />

                                                                {/* {
                                                                    this.state.uploadFile.fileName ?
                                                                        this.CommonFuctionality.getFileContentTypeIcon( this.state.uploadFile.fileName)
                                                                        : 
                                                                        this.CommonFuctionality.getFileContentTypeIcon(this.state.updateUserObj.fileName)
                                                                } */}
                                                                <img
                                                                    src={
                                                                        this.state.uploadFile.fileName ?
                                                                            "data:image/*;base64," + this.state.uploadFile.fileContent
                                                                            :
                                                                            "data:image/*;base64," + this.state.reqObject.fileContent
                                                                    }
                                                                    style={{ "height": "80px" }}
                                                                />

                                                            </div>
                                                            <div className="image-title">{this.state.uploadFile.fileName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            chooseLabel={this.state.uploadFile.fileName || uploadFileLabel}
                                                            id="fileUpload"
                                                            mode="basic"
                                                            accept="image/*"
                                                            name="uploadFile"
                                                            onSelect={e => this.onLoadPic(e, dialogType)}
                                                            auto={true}
                                                        />

                                                }

                                            </div>
                                        </div>

                                        {
                                            this.state.uploadFile && this.state.uploadFile.fileContent && this.state.cropperVisible ?
                                                <ImageCropper
                                                    cropperObject={this.state.cropperObject}
                                                    getCroppedResult={this.getCroppedResult}
                                                    removeCropSection={this.removeCropSection}
                                                />
                                                : ''
                                        }
                                        <span className='error-message'>{errors['uploadImage']}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={() => this.hideDialog()}
                                        />

                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            name="district"
                                            label={dialogType == 'add' ? "Save" : "Update"}
                                            icon="fas fa-check"
                                            disabled={this.state.cropperVisible ? true : false}
                                            onClick={() => this.onclickSubmitBtn(updateVisible ? 'update' : 'submit')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header="Delete Info"
                        visible={this.state.deleteVisible}
                        modal={true}
                        onHide={() => this.hideDeleteDialog()}
                        className="nw-confirm-dialog"
                        maximizable
                    >
                        <div className="p-fluid">
                            <div className="p-col-12 p-xl-12">
                                <div className="confirm-wrapper">
                                    <h3 className="text-center">Are you want to delete?</h3>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                            <Button
                                                label='No'
                                                icon='fas fa-times'
                                                className="p-button p-button-danger nw-button nw-button-multiple"
                                                onClick={() => this.hideDeleteDialog()}
                                            />
                                            <Button
                                                label='Yes'
                                                icon='fas fa-check'
                                                className="p-button p-button-primary nw-button nw-button-multiple"
                                                name={reqObject.aboutusId}
                                                onClick={() => this.deleteSubmit(reqObject.aboutusId !== '' ? reqObject.aboutusId : '')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }

}