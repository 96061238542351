import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { CommonProfile } from '../myProfile/CommonProfile';
import { PersonalProfile } from './PersonalProfile';
import { PersonalBankInfo } from './PersonalBankInfo';
import { PersonalEducationInfo } from './PersonalEducationInfo';
import { PersonalTrainingInfo } from './PersonalTrainingInfo';
import { PersonalCertificateInfo } from './PersonalCertificateInfo';
import { PersonalExprienceInfo } from './PersonalExprienceInfo';
import { PersonalReferenceInfo } from './PersonalReferenceInfo';
import { ProfileService } from '../../../service/profile/ProfileService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';


export class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commonProfileInfo:{},
            activeIndex: 0,
            updateImg: ''
        }

        this.ProfileService = new ProfileService();
        this.netiFileHandler = new NetiFileHandler();
        this.getCommonProfileInfo()
    }

    componentWillMount(){
        
    }

    getCommonProfileInfo = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ProfileService.fetchCommonProfileInfo()
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        this.setState({ commonProfileInfo: body, topProgressBar: false, errorMsgVisible: false });
                        this.getImg();
                    })
                }
                else {
                    this.ProfileService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    getImg() {
        let { commonProfileInfo } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        let photoMainPath;

        if (this.state.photoName) {
            photoMainPath = "/images/nw/dws/gallery/" + this.state.photoName
        }
        else {
            photoMainPath = commonProfileInfo.imagePath
        }

        if (photoMainPath !== null) {
            this.netiFileHandler.getByteImage(photoMainPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(commonProfileInfo.imageName);
                                
                                commonProfileInfo.imageContent = body.fileContent;
                                this.setState({ updateImg: body.fileContent, commonProfileInfo });
                            })
                    } else {
                        this.setState({ updateImg: staticImg });
                    }
                });
        } else {
            this.setState({ updateImg: staticImg });
        }

    }

    getProfileInfo = () =>{
        return this.getCommonProfileInfo()
    }

    render() {
        let { commonProfileInfo, activeIndex } = this.state
        console.log("STATE", this.state);
        
        return (
            <div className="layout-dashboard" id="myProfile">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView activeIndex={activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                            <TabPanel header="Personal Info">
                                <CommonProfile commonProfileInfo={ commonProfileInfo } getCommonProfileInfo={this.getProfileInfo}/>
                                <PersonalProfile />
                            </TabPanel>
                            <TabPanel header="Bank Info">
                                <CommonProfile commonProfileInfo={ commonProfileInfo }/>
                                <PersonalBankInfo />
                            </TabPanel>
                            <TabPanel header="Education Info">
                                <CommonProfile commonProfileInfo={ commonProfileInfo }/>
                                <PersonalEducationInfo />
                            </TabPanel>
                            <TabPanel header="Training Info">
                                <CommonProfile commonProfileInfo={ commonProfileInfo }/>
                                <PersonalTrainingInfo />
                            </TabPanel>
                            <TabPanel header="Certification Info">
                                <CommonProfile commonProfileInfo={ commonProfileInfo }/>
                                <PersonalCertificateInfo />
                            </TabPanel>
                            <TabPanel header="Experience Info">
                                <CommonProfile commonProfileInfo={ commonProfileInfo }/>
                                <PersonalExprienceInfo />
                            </TabPanel>
                            <TabPanel header="Reference Info">
                                <CommonProfile commonProfileInfo={ commonProfileInfo }/>
                                <PersonalReferenceInfo />
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }
}