import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader'

let errors = {};
const userUrlInfo = localStorage.getItem('dwsUrlInfo');
let urlId;
let rows = 10;
let page = 0;
export class SeatInfoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberStatus: '',
            first: 0,
            totalRecords: 0,
            dataTableValue: [],
            updateSeatInfoDetails: {
                seatSerial: '',
                totalSeat: '',
                classCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                groupCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            errors: {},
        }
        this.DwsService = new DwsService();
        this.reloadCoreUrlIdFromLocal();
        this.NetiContentLoader = new NetiContentLoader();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        this.viewSeatInfoList();
    }

    viewSeatInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        let coreUrl = {
            coreUrlInfoID: userUrlInfo,
            pageNo: page,
            limit: rows
        }

        this.DwsService.fetchSeatInfoList(coreUrl)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.props.countTableSize(body.length);
                        this.setState({ dataTableValue: body });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    onPage = (e) => {
        rows = e.rows;
        page = e.page
        this.viewSeatInfoList();
    }

    countTableSize() {
        const { dataTableValue } = this.state;
        return dataTableValue.length;
    }

    onHide = (event) => {
        this.setState({ visible: false });
        this.viewSeatInfoList();
    }

    viewDialog = (rowData) => {
        this.setState({ visible: true });
    }

   
    seatInfoDetails = (rowData) => {
        this.setState({ visible: true });
        let { updateSeatInfoDetails } = this.state;
        this.setState({ updateSeatInfoDetails: rowData });
    }

    actionTemplate = (rowData, column) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                onClick={(e) => this.seatInfoDetails(rowData)}
            >
            </Button>
        </div>;
    }


    onChangeSeatSerial = (e) => {
        errors = {}
        let { updateSeatInfoDetails } = this.state;
        updateSeatInfoDetails.seatSerial = e.target.value
        this.setState({ updateSeatInfoDetails })
        
        
    }

    onChangeTotalSeat = (e) => {
        let { updateSeatInfoDetails, errors } = this.state;
        errors["totalSeat"] = "" 
        updateSeatInfoDetails.totalSeat = e.target.value
        this.setState({ updateSeatInfoDetails })
    }

    onChangeClass = (e) => {
        let { updateSeatInfoDetails, errors } = this.state;
        errors["classError"] = "" 
        updateSeatInfoDetails.classCategoryInfoDTO.coreCategoryID = e.target.value
        this.setState({ updateSeatInfoDetails })
    }

    onChangeGroup = (e) => {
        let { updateSeatInfoDetails, errors } = this.state;
        errors["groupError"] = "" 
        updateSeatInfoDetails.groupCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ updateSeatInfoDetails })
    }

    handleError() {
        let { updateSeatInfoDetails } = this.state
        let formIsValid = true;
        this.setState({ topProgressBar: false });

        if (!updateSeatInfoDetails.seatSerial) {
            formIsValid = false;
            errors["serialNumber"] = "Serial can't left empty.";
        }
        if (!updateSeatInfoDetails.classCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["classError"] = "Class can't left empty.";
        }
        if (!updateSeatInfoDetails.groupCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["groupError"] = "Group can't left empty.";
        }
        if (!updateSeatInfoDetails.totalSeat) {
            formIsValid = false;
            errors["totalSeat"] = "Seat can't left empty.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    updateSeatDetails = (event, props) => {
        let { updateSeatInfoDetails } = this.state;
        this.setState({ updateSeatInfoDetails })

        if (this.handleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            this.DwsService.updateSeatInfo(updateSeatInfoDetails)
                .then(res => {
                    if (res.status === 202) {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully Updated",
                            life: 800
                        });
                        setTimeout(() => {
                            this.onHide();
                        }, 800);
                    } else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        }
    }

    cancelSeatDetails = (e) => {
        this.setState({ visible: false });
    }

    

    render() {
        let { dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { updateSeatInfoDetails } = this.state

        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                                Seat Info List
                                    </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length.toLocaleString("EN-IN")}
                                </a>
                            </div>
                        </div>;
        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={rows}
                                    paginator={true}
                                    columnResizeMode="fit"
                                >
                                    <Column field="seatSerial" header="Serial No." filter={true} />
                                    <Column field="classCategoryInfoDTO.categoryName" header="Class" filter={true} />
                                    <Column field="groupCategoryInfoDTO.categoryName" header="Group" filter={true} />
                                    <Column field="totalSeat" header="Total Seat" filter={true} />
                                    <Column field="action" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                </DataTable>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>
                    </div>

                    <Dialog 
                        className="nw-dialog" 
                        header="Update Seat Information" 
                        visible={this.state.visible} 
                        onHide={this.onHide} 
                        closable
                    >
                        <div className="p-fluid">

                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No. <span>*</span></span>

                                                <div className="nw-inputgroup-desc">
                                                    { updateSeatInfoDetails.seatSerial }
                                                </div>
                                            </div>
                                            <span className='error-message'></span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Class <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Class"
                                                    value={updateSeatInfoDetails.classCategoryInfoDTO.coreCategoryID}
                                                    options={this.props.classList}
                                                    onChange={this.onChangeClass}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["classError"]}</span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Group <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Group"
                                                    value={updateSeatInfoDetails.groupCategoryInfoDTO.coreCategoryID}
                                                    options={this.props.groupList}
                                                    onChange={this.onChangeGroup}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["groupError"]}</span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Total Seat <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Total Seat Number"
                                                    id="totalSeat"
                                                    keyfilter="pint"
                                                    value={updateSeatInfoDetails.totalSeat}
                                                    placeholder="Enter seat"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="totalSeat"
                                                    onChange={this.onChangeTotalSeat}
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors["totalSeat"]}</span>
                                        </div>

                                        <div className="p-col-12 nw-button-parent-multiple">
                                            <Button
                                                className="p-button p-button-danger nw-button nw-button-multiple"
                                                label="Discard"
                                                icon="fas fa-times"
                                                onClick={this.onHide}
                                            />
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-multiple"
                                                label="Update"
                                                icon="fas fa-check"
                                                onClick={this.updateSeatDetails}
                                            />
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}