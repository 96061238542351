import React, { Component } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { TeacherBasicInfo } from './TeacherBasicInfo';
import { TeacherPersonalInfo } from './TeacherPersonalInfo';
import { TeacherAddressInfo } from './TeacherAddressInfo';
import { TeacherEducationInfo } from './TeacherEducationInfo';
import { TeacherExperienceInfo } from './TeacherExperienceInfo';
import { TeacherTrainingInfo } from './TeacherTrainingInfo';
import { TeacherReferenceInfo } from './TeacherReferenceInfo';
import { TeacherBankInfo } from './TeacherBankInfo';
import { Panel } from 'primereact/panel';

export class TeacherProfile extends Component {

    componentDidMount() {
        //if Data value doesn't exist, fill the value field with three dots (...)
        //This is only for exceptional empty/null/undefined output
        setTimeout(() => {
            [...document.querySelectorAll('.protal-single-info-content')].map(item => {
                if (!item.querySelector('p').innerHTML.trim()) {
                    item.querySelector('p').innerHTML = '---'
                }
            })
        }, 1500);
    }

    render() {

        return (

            <div className="p-grid m-l-m-35 m-r-m-35">
                <TeacherBasicInfo basicInfo={this.props.teacherBasicInfo} />
                <TeacherPersonalInfo basicInfo={this.props.teacherBasicInfo} />
                <TeacherAddressInfo basicInfo={this.props.teacherBasicInfo} />
                <TeacherEducationInfo basicInfo={this.props.teacherBasicInfo} />
                <TeacherExperienceInfo basicInfo={this.props.teacherBasicInfo} />
                <TeacherTrainingInfo basicInfo={this.props.teacherBasicInfo} />
                <TeacherReferenceInfo basicInfo={this.props.teacherBasicInfo} />
                <TeacherBankInfo basicInfo={this.props.teacherBasicInfo} />
            </div>

        )
    }
}