import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';

import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import Formatter from '../../../utils/Formatter';
import { Workbook } from 'react-excel-workbook';

let maxDate = new Date();
let minDate = new Date();
let minToDate = new Date();
let maxToDate = new Date();
let errors = {}

export class DateRangeWisePurchaseReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            searchDataObj: {
                startDate: '',
                endDate: '',
            },

            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            errors: {},
        }

        this.NetiDateUtils = new NetiDateUtils();
        this.managementPointService = new ManagementPointService();
        this.ValidatorUtility = new ValidatorUtility();
        this.Formatter = new Formatter();
        this.NetiContentLoader = new NetiContentLoader();

    }

    startDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["startDate"] = "";
        searchDataObj.startDate = event.target.value;
        minToDate = event.target.value;
        maxToDate = new Date(event.target.value)
        maxToDate.setDate(minToDate.getDate() + 9);
        this.setState({ searchDataObj, errors })
    }

    endDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["endDate"] = "";
        searchDataObj.endDate = event.target.value;
        this.setState({ searchDataObj, errors })
    }

    searchHandleError = () => {

        let { errors, searchDataObj } = this.state;
        let formIsValid = true;
        if (searchDataObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start Date can't left empty";
        }
        if (searchDataObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End Date can't left empty";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onDateWiseSearchHandler = () => {

        let { searchDataObj, errors } = this.state;

        if (this.searchHandleError()) {

            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(searchDataObj.endDate) >= Date.parse(searchDataObj.startDate)) {

                this.managementPointService.fetchDateRangeWisePurchaseList(searchDataObj)
                    .then(res => {

                        if (res.status == 302) {

                            return res.json().then((body) => {

                                if (body.length === 0) {
                                    return this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
                                }
                                else {

                                    body.map(item => {
                                        item.netiId = item.netiId.toString();
                                        item.trnsDateAndTime = NetiDateUtils.getAnyShortForm(item.trnsDateAndTime, 'DD-MMM-YYYY') + ' ' + NetiDateUtils.getHHMMSSFForHome(item.trnsDateAndTime);
                                    });

                                    this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                                }
                            });
                        }

                    }).catch(error => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });
            } else {
                this.growl.show({ severity: 'warn', summary: 'Warning', detail: "To Date can not be earlier than From Date." });
                this.setState({ topProgressBar: false, errorMsgVisible: false, })

            }
        }
    }

    unitPriceTemplet = (rowData) => {
        return <div className="col-number text-right" >
            {this.ValidatorUtility.currencyFormatter(rowData.unitPrice)}
        </div>
    }

    discountTemplet = (rowData) => {
        return <div className="col-number text-right" >{this.ValidatorUtility.currencyFormatter(rowData.discountAmount)}</div>
    }

    amountTemplet = (rowData) => {
        return <div className="col-number text-right" >
            {this.ValidatorUtility.currencyFormatter(rowData.paidAmount)}
        </div>
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, searchDataObj, dataTableValue } = this.state;
        let tableHeader = <div className="header-title">
            <div className="header-title-left">Date Wise Purchase Details</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let formatedStartDate = '';
        let formatedEndDate = '';

        if (searchDataObj.startDate && searchDataObj.endDate) {
            formatedStartDate = (searchDataObj.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (searchDataObj.endDate.toLocaleDateString('en-GB'));
        }

        // excel
        let excelColumns = "";
        let excelFileName = `${formatedStartDate} To ${formatedEndDate} Purchase_Report.xlsx`;
        let excelSheetName = "DateWise_Purchase_List";
        excelColumns = [
            <Workbook.Column value="trnsDateAndTime" label="Purchase Date & Time" />,
            <Workbook.Column value="netiId" label="Neti ID" />,
            <Workbook.Column value="name" label="Name" />,

            <Workbook.Column value="parentNetiId" label="Parent Neti ID" />,
            <Workbook.Column value="parentName" label="Parent Name" />,
            <Workbook.Column value="parentArea" label="Parent Area" />,

            <Workbook.Column value="productName" label="Purchase Product" />,
            <Workbook.Column value="unitPrice" label="Unit Price" />,
            <Workbook.Column value="purchaseQuantity" label="Quantity" />,
            <Workbook.Column value="discountAmount" label="Discount" />,
            <Workbook.Column value="paidAmount" label="Purchase Amount" />,
        ];
        // end excel

        return (
            <div className="p-fluid">
                {/* <div className="p-grid"> */}
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className='main-section'>
                    <div className="nw-form">
                        <div className="p-col-12 p-lg-12 p-xl-12">

                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            value={searchDataObj.startDate}
                                            onChange={this.startDateHandler}
                                            dateFormat="dd/mm/yy"
                                            showIcon={true}
                                            placeholder="Select Date"
                                            autoWidth={false}
                                            yearRange="2010:2030"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            showButtonBar={true}
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            value={searchDataObj.endDate}
                                            onChange={this.endDateHandler}
                                            dateFormat="dd/mm/yy"
                                            showIcon={true}
                                            placeholder="Select Date"
                                            autoWidth={false}
                                            yearRange="2010:2030"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            showButtonBar={true}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button-primary nw-button nw-button-center"
                                        icon="fas fa-search"
                                        onClick={this.onDateWiseSearchHandler}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="nw-data-table">
                            <div className="p-col-12 p-lg-12 p-xl-12">

                                {this.state.dataTableIsLoading ? this.NetiContentLoader.MyPointTableLoader() :

                                    <DataTable
                                        header={tableHeader}
                                        // footerColumnGroup={footer}
                                        responsive={true}
                                        // selectionMode="single"
                                        rowsPerPageOptions={[10, 20, 30]}
                                        paginator={true}
                                        rows={10}
                                        value={this.state.dataTableValue}

                                    >
                                        <Column field="trnsDateAndTime" header="Date & Time" filter={true} />
                                        <Column field="netiId" header="Neti ID" filter={true} />
                                        <Column field="name" header="Name" filter={true} />

                                        <Column field="parentNetiId" header="Parent Neti ID" filter={true} />
                                        <Column field="parentName" header="Parent Name" filter={true} />
                                        <Column field="parentArea" header="Parent Area" filter={true} />

                                        <Column field="productName" header="Purchase Product" filter={true} />
                                        <Column field="unitPrice" header="Unit Price" filter={true} body={this.unitPriceTemplet} />

                                        <Column field="purchaseQuantity" header="Quantity" filter={true} />
                                        <Column field="discountAmount" header="Discount" filter={true} body={this.discountTemplet} />
                                        <Column field="paidAmount" header="Purchase Amount" filter={true} body={this.amountTemplet} />

                                    </DataTable>
                                }
                            </div>

                            <div className="p-col-12 p-xl-12">

                                <div>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                label="Download Excel"
                                                id="clickExcelBtn"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                style={{ float: 'right' }}
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>
                            </div >

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}