import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { MANAGEMENT_POINT } from "../../../../utils/PointWiseRouteConsts";
        
export class MpNetiCMSDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsgVisible: false,
            errorMsgBody: '',
            errors: {},
        }
    }
    
    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />

                <div className="accrodion-section">

                    <Accordion activeIndex={this.state.activeIndex || 0} onTabChange={(e) => this.setState({activeIndex: e.index})} >
                        <AccordionTab header="Neti CMS Information Dashboard">

                            <Panel header="Neti CMS Point Info" className="bg-primary">

                                <div className="main-section">
                                    <div className="p-grid">

                                        <div className="p-col-12">
                                            <div className="p-col-12 p-xl-12 ">
                                                <div className="nw-circle-panel">
                                                    <div className="circle-panel circle-panel-first bg-primary">
                                                        <br />
                                                        1140
                                                        <div className="circle-panel-label">Total Neti CMS</div>
                                                    </div>

                                                    <Link
                                                        to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_CONFIGURED_URL  }}
                                                        style={{ color: '#ffffff' }}
                                                    >
                                                        <div className="circle-panel circle-panel-second bg-success">
                                                            <br />
                                                            <u>1100</u>
                                                            <div className="circle-panel-label">Total Configured Url</div>
                                                        </div>
                                                    </Link>

                                                    <Link
                                                        to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_NOT_CONFIGURED_URL  }}
                                                        style={{ color: '#ffffff' }}
                                                    >
                                                        <div className="circle-panel circle-panel-third bg-danger">
                                                            <br />
                                                            <u>40</u>
                                                            <div className="circle-panel-label">Total Not Configured Url</div>
                                                        </div>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                            <br/>
                            <Panel header="Information Percent of Configured Neti CMS">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-6 ">
                                        <Panel header="Information Filled Up Percent" className="bg-success">
                                            <center>
                                                <Link
                                                    to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_INFO_FILLED_PERCENT  }}
                                                    style={{ color: '#002171' }}
                                                >
                                                    <div>
                                                        <h2><u>1027</u> <span className="font-size-12">(93.36%)</span></h2>
                                                    </div>
                                                    <h3>Total Filled Up</h3>
                                                </Link>
                                            </center>
                                            
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-xl-6 ">
                                        <Panel header="Information Not Filled Up Percent" className="bg-danger">
                                            <center>
                                                <Link
                                                    to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_INFO_NOT_FILLED_PERCENT  }}
                                                    style={{ color: '#002171' }}
                                                >
                                                    <div>
                                                        <h2><u>73</u> <span className="font-size-12">(6.64%)</span></h2>
                                                    </div>
                                                    <h3>Total Not Filled Up</h3>
                                                </Link>
                                            </center>
                                        </Panel>
                                    </div>
                                </div>
                            </Panel>
                            <br/>
                            <Panel header="Eduman Mapped Neti CMS Info">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-6 ">
                                        <Panel header="Mapped Institute" className="bg-success">
                                            <center>
                                                <Link
                                                    to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_EDUMAN_MAPPED  }}
                                                    style={{ color: '#002171' }}
                                                >
                                                    <div>
                                                        <h2><u>1000</u> <span className="font-size-12">(93.36%)</span></h2>
                                                    </div>
                                                    <h3>Total Mapped</h3>
                                                </Link>
                                            </center>
                                        </Panel>
                                    </div>
                                    <div className="p-col-12 p-xl-6 ">
                                        <Panel header="Unmapped Institute" className="bg-danger">
                                            <center>
                                                <Link
                                                    to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_EDUMAN_UNMAPPED  }}
                                                    style={{ color: '#002171' }}
                                                >
                                                    <div>
                                                        <h2><u>100</u> <span className="font-size-12">(6.54%)</span></h2>
                                                    </div>
                                                    <h3>Total Unmapped</h3>
                                                </Link>
                                            </center>
                                        </Panel>
                                    </div>
                                </div>
                            </Panel>
                        </AccordionTab>
                    </Accordion>

                </div>
            </div>
        );
    }
}