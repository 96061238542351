import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';
import { downloadJasperFile } from '../../utils/JasperFileDownloader';

export class StudentPortal {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    fetchStudentProfileDetailsList(instituteId, studentId) {
        let uri = this.baseURIHolder.getUser() + '/student/profile'.concat('?instituteId=').concat(instituteId).concat('&studentId=').concat(studentId);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    

    fetchStudentAttendanceList(requestObjct) {
        let uri = this.baseURIHolder.getUser() + '/student/attendance'.concat('?instituteId=').concat(requestObjct.instituteId).concat('&studentId=').concat(requestObjct.studentId).concat('&academicYear=').concat(requestObjct.academicYear).concat('&year=').concat(requestObjct.year).concat('&monthName=').concat(requestObjct.monthName).concat('&periodId=').concat(requestObjct.periodId);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchStudentSubjectList(requestObjct) {
        let uri = this.baseURIHolder.getUser() + '/student/subjects'.concat('?instituteId=').concat(requestObjct.instituteId).concat('&studentId=').concat(requestObjct.studentId).concat('&academicYear=').concat(requestObjct.academicYear);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchStudentClassRoutine(requestObjct) {
        console.log(requestObjct)
        let uri = this.baseURIHolder.getUser() + '/student/class-routine'.concat('?instituteId=').concat(requestObjct.instituteId).concat('&studentId=').concat(requestObjct.studentId).concat('&academicYear=').concat(requestObjct.academicYear);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }


    fetchExamSessionList(requestObjct) {
        console.log(requestObjct)
        let uri = this.baseURIHolder.getUser() + '/student/sessions'.concat('?instituteId=').concat(requestObjct.instituteId);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchStudentExam(requestObjct) {
        console.log(requestObjct)
        let uri = this.baseURIHolder.getUser() + '/student/exams'.concat('?instituteId=').concat(requestObjct.instituteId).concat('&studentId=').concat(requestObjct.studentId).concat('&academicYear=').concat(requestObjct.academicYear);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchStudentExamRoutine(requestObjct) {
        console.log(requestObjct)
        let uri = this.baseURIHolder.getUser() + '/student/exam-routine'.concat('?instituteId=').concat(requestObjct.instituteId).concat('&studentId=').concat(requestObjct.studentId).concat('&academicYear=').concat(requestObjct.academicYear).concat('&sessionId=').concat(requestObjct.sessionId).concat('&examId=').concat(requestObjct.examId);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchfindPaidInvoiceByStudentId(instituteId, studentId, academicYear) {
        let uri = this.baseURIHolder.getUser() + '/student/accounts/invoice/paid/list?'.concat('instituteId=').concat(instituteId).concat('&studentId=').concat(studentId).concat('&academicYear=').concat(academicYear);
        //     console.log(uri);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    fetchfindunPaidInvoiceByStudentId(instituteId, studentId, academicYear) {
        let uri = this.baseURIHolder.getUser() + '/student/accounts/invoice/unpaid/list?'.concat('instituteId=').concat(instituteId).concat('&studentId=').concat(studentId).concat('&academicYear=').concat(academicYear);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchfindunPaidInvoiceByInstituteStudentId(instituteIdStudentId) {
        let uri = this.baseURIHolder.getUser() + '/student/accounts/invoice/banking?instituteIdStudentId='.concat(instituteIdStudentId);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchpayemntHostoryByListOfInvoiceIdForpaymentButtondisable(ListOfinvoiceId) {
        let uri = this.baseURIHolder.getHost() +'/payment'+ '/eduman/paidlist';
        return this.Auth.postFetch(uri,ListOfinvoiceId)
            .catch((error) => console.log('error', error));
    }
    fetchfindPaidInvoiceByInstituteStudentId(instituteIdStudentId) {
        let uri = this.baseURIHolder.getUser() + '/student/accounts/invoice/banking/paid?instituteIdStudentId='.concat(instituteIdStudentId);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchpayemntByInvoiceByStudentId(invoiceId, amount,instituteID) {
        let uri = this.baseURIHolder.getHost() +'/payment'+ '/sslcommerz?'.concat('invoiceId=').concat(invoiceId).concat('&amount=').concat(amount).concat('&instituteID=').concat(instituteID);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    fetchfindPayslipDownload(invoiceIds,instituteId, studentId) {
       
            var Token = JSON.parse(localStorage.getItem('Token'));
            let uri = this.baseURIHolder.getPartnerJasper() + `/em/pay-slip/download?invoiceIds=${invoiceIds}&instituteId=${instituteId}&studentId=${studentId}&access_token=${Token.accessToken}`;
            return downloadJasperFile(uri);
    }
    

    fetchExamsOfStudent(instituteId, studentId, academicYear) {
        let uri = this.baseURIHolder.getUser() + '/student/exams?'.concat('instituteId=').concat(instituteId).concat('&studentId=').concat(studentId).concat('&academicYear=').concat(academicYear);
        //  console.log(uri);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchfindExamMarksByStudentId(instituteId, studentId, academicYear, examConfigId) {
        let uri = this.baseURIHolder.getUser() + '/student/exam/marks?'.concat('instituteId=').concat(instituteId).concat('&studentId=').concat(studentId).concat('&academicYear=').concat(academicYear).concat('&examConfigId=').concat(examConfigId);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchfindExamResultsByStudentId(instituteId, customStudentId, academicYear, examId) {
        let uri = this.baseURIHolder.getUser() + `/student/exam/results?academicYear=${academicYear}&customStudentId=${customStudentId}&examId=${examId}&instituteId=${instituteId}`
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchClassTestList(instituteId, studentId, academicYear) {
        let uri = this.baseURIHolder.getUser() + '/student/class-tests?'.concat('instituteId=').concat(instituteId).concat('&studentId=').concat(studentId).concat('&academicYear=').concat(academicYear);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    fetchClassTestMarksList(instituteId, studentId, academicYear, classTestConfigId) {
        let uri = this.baseURIHolder.getUser() + '/student/class-test/marks?'.concat('instituteId=').concat(instituteId).concat('&studentId=').concat(studentId).concat('&academicYear=').concat(academicYear).concat('&classTestConfigId=').concat(classTestConfigId);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    fetchfindPaidInventoryDetailsByStudentId(instituteId, studentId, academicYear, monthName) {
        let uri = this.baseURIHolder.getUser() + '/student/accounts/inventory/details?'.concat('instituteId=').concat(instituteId).concat('&studentId=').concat(studentId).concat('&year=').concat(academicYear).concat('&monthName=').concat(monthName);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

}