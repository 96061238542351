import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { CoreConfigView } from './CoreConfigView';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { FileUpload } from 'primereact/fileupload';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { UserCategoryService } from "../../../service/UserCategoryService";
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import { Link } from 'react-router-dom';


let urlId;
export class CoreConfigHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            academicYearList: [],
            checkEligibility: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            circularPic: {},
            signaturePic: {},
            homeReturnButton: false,
            admisiaCoreConfigInfo: {
                academicYearCoreCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                circularName: '',
                circularTitle: '',
                circularFileContent: '',
                circularFileSaveOrEditable: false,
                signatureTitle: '',
                signatureName: '',
                signatureFileContent: "",
                signatureFileSaveOrEditable: false,
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            }
        }

        this.userCategoryService = new UserCategoryService();
        this.admisiaPointService = new AdmisiaPointService();
        this.netiFileHandler = new NetiFileHandler();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 1000);
        }
    }

    componentWillMount = () => {
        const urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (urlId) {
            let { coreUrlInfoDTO } = this.state.admisiaCoreConfigInfo;
            coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ coreUrlInfoDTO });
            this.setState({ homeReturnButton: false })

            this.admisiaPointService.getCoreConfigInfo(urlId)
                .then((res) => {
                    if (res.status == 302) {
                        this.setState({ checkEligibility: true })
                    } else {
                        this.setState({ checkEligibility: false })
                    }
                })
                .catch(errorBody => {console.log("Error", errorBody)})
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    componentDidMount = () => {
        this.getAcademicYearListByTypeID();
    }

    getAcademicYearListByTypeID = () => {
        this.setState({ topProgressBar: true });
        this.userCategoryService.fetchListByDefaultCode("T107")
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ academicYearList: body });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })

                        });
                }
            }).catch(error => {
                console.log("error", error)
                // this.growl.show({severity: 'warn', summary: 'Warning', detail: 'Connection Problem'});
            });
    }

    onChangeAcademicYear = (e) => {
        let { admisiaCoreConfigInfo } = this.state;
        admisiaCoreConfigInfo.academicYearCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ admisiaCoreConfigInfo });

    }

    onChangeCircularTitle = (e) => {
        let { admisiaCoreConfigInfo } = this.state;
        admisiaCoreConfigInfo.circularTitle = e.target.value;
        this.setState({ admisiaCoreConfigInfo });
    }

    circularImageUpload = (e) => {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = () => {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ circularPic: album });

        }
    }

    onChangeSignatureTitle = (e) => {
        let { admisiaCoreConfigInfo } = this.state;
        admisiaCoreConfigInfo.signatureTitle = e.target.value;
        this.setState({ admisiaCoreConfigInfo });

    }

    signatureImageUpload = (e) => {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = () => {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ signaturePic: album });

        }
    }

    onSubmitHandler = () => {
        let { admisiaCoreConfigInfo } = this.state;
        this.setState({ topProgressBar: true });
        if (this.state.circularPic.contentPic !== null) {
            admisiaCoreConfigInfo.circularFileContent = this.state.circularPic.contentPic;
            admisiaCoreConfigInfo.circularFileSaveOrEditable = true;
            // let circularExtention = this.netiFileHandler.getImageExtention(this.state.circularPic.extention);
            // admisiaCoreConfigInfo.circularName = this.state.circularPic.contentName + circularExtention;
            admisiaCoreConfigInfo.circularName = this.state.circularPic.contentName;
        }

        if (this.state.signaturePic.contentPic !== null) {
            admisiaCoreConfigInfo.signatureFileContent = this.state.signaturePic.contentPic;
            admisiaCoreConfigInfo.signatureFileSaveOrEditable = true;
            // let signatureExtention = this.netiFileHandler.getImageExtention(this.state.signaturePic.extention);
            // admisiaCoreConfigInfo.signatureName = Date.now() + signatureExtention;
            admisiaCoreConfigInfo.signatureName = this.state.signaturePic.contentName;
        }
        if (this.coreConfigSaveError()) {
            this.admisiaPointService.createAdmisiaCoreConfig(admisiaCoreConfigInfo)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 201) {
                        this.setState({ checkEligibility: true })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Created!" });
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })

                            });
                    }
                }).catch(error => {
                    console.log(error);
                    // this.growl.show({severity: 'warn', summary: 'Warning', detail: 'Connection Problem'});
                });
        }
    }

    coreConfigSaveError() {

        let { admisiaCoreConfigInfo, errors } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (admisiaCoreConfigInfo.academicYearCoreCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            errors["academicYear"] = "Academic Year can't left empty";
        }
        if (admisiaCoreConfigInfo.circularTitle === '') {
            formIsValid = false;
            errors["circularName"] = "Circular Title can't left empty";
        }

        if (!this.state.circularPic.contentName) {
            formIsValid = false;
            errors["circularImage"] = "Circular File can't left empty";
        }

        if (admisiaCoreConfigInfo.signatureTitle === '') {
            formIsValid = false;
            errors["signatureTitle"] = "Signature Title can't left empty";
        }

        if (!this.state.signaturePic.contentName) {
            formIsValid = false;
            errors["signatureImage"] = "Signature File can't left empty";
        }

        this.setState({ errors });
        return formIsValid;
    }

    render() {
        let { dwsUrlInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let academicYear = [];
        if ((this.state.academicYearList != null) && (this.state.academicYearList.length > 0)) {
            academicYear = this.state.academicYearList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
        return (
            <div className="p-fluid">
                <div className="">

                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    {this.state.homeReturnButton === false ?
                        <div>
                            {
                                this.state.checkEligibility ?
                                    <CoreConfigView ref="CoreConfigView" />
                                    :
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">
                                                <Growl ref={(el) => this.growl = el} />
                                              
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="formControl">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Academic Year <span>*</span></span>
                                                                <Dropdown
                                                                    placeholder="Select Academic Year"
                                                                    value={this.state.admisiaCoreConfigInfo.academicYearCoreCategoryInfoDTO.coreCategoryID}
                                                                    options={academicYear}
                                                                    onChange={this.onChangeAcademicYear}
                                                                    showClear={true}
                                                                    autoWidth={false}
                                                                    name="academicYear"
                                                                    id="academicYear"
                                                                />
                                                            </div>
                                                            <span className="error-message">{this.state.admisiaCoreConfigInfo.academicYearCoreCategoryInfoDTO.coreCategoryID ? '' : errors['academicYear']}</span>
                                                        </div>

                                                        <div className="formControl">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Circular Title <span>*</span></span>
                                                                <InputText
                                                                    value={this.state.admisiaCoreConfigInfo.circularTitle}
                                                                    id="circularName"
                                                                    placeholder="Enter Circular Title"
                                                                    style={{ width: "100%" }}
                                                                    type="text"
                                                                    name="circularName"
                                                                    onChange={this.onChangeCircularTitle}
                                                                />
                                                            </div>
                                                            <span className="error-message">{this.state.admisiaCoreConfigInfo.circularTitle ? '' : errors['circularName']}</span>
                                                        </div>

                                                        <div className="formControl">
                                                            <div className="nw-upload-button">
                                                                <center><label htmlFor="signatureTitle">Circular Image <span>*</span></label></center>
                                                                <FileUpload
                                                                    className="p-button-raised p-button-secondary"
                                                                    mode="basic"
                                                                    accept="image/*"
                                                                    maxFileSize={1000000}
                                                                    onSelect={this.circularImageUpload}
                                                                    auto={true}
                                                                    chooseLabel="Choose"
                                                                    autoWidth={false}
                                                                />
                                                                <center>{this.state.circularPic.contentName}</center>
                                                            </div>
                                                            <center><span className="error-message">{this.state.circularPic.contentName ? '' : errors['circularImage']}</span></center>
                                                        </div>

                                                        <div className="formControl">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Signature Title <span>*</span></span>
                                                                <InputText
                                                                    value={this.state.admisiaCoreConfigInfo.signatureTitle}
                                                                    id="signatureTitle"
                                                                    placeholder="Enter Signature Title"
                                                                    type="text"
                                                                    name="signatureTitle"
                                                                    onChange={this.onChangeSignatureTitle}
                                                                />
                                                            </div>
                                                            <span className="error-message">{this.state.admisiaCoreConfigInfo.signatureTitle ? '' : errors['signatureTitle']}</span>
                                                        </div>

                                                        <div className="formControl">
                                                            <center><label htmlFor="signatureTitle">Signature Image <span>*</span></label></center>
                                                            <div className="nw-upload-button">
                                                                <FileUpload
                                                                    className="p-button-raised p-button-secondary"
                                                                    mode="basic"
                                                                    accept="image/*"
                                                                    maxFileSize={1000000}
                                                                    onSelect={this.signatureImageUpload}
                                                                    auto={true}
                                                                    chooseLabel="Choose"
                                                                    autoWidth={false}
                                                                />
                                                                <center >{this.state.signaturePic.contentName}</center>
                                                            </div>
                                                            <center><span className="error-message">{this.state.signaturePic.contentName ? '' : errors['signatureImage']}</span></center>
                                                        </div>

                                                        <div className="nw-button-parent" >
                                                            <Button
                                                                className="p-button-primary nw-button nw-button-right"
                                                                label="Save"
                                                                icon="fas fa-check"
                                                                onClick={this.onSubmitHandler}
                                                            />
                                                        </div>
                                                    </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                            }
                        </div>
                        :

                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/institute_config"
                                            className="rainbow-button"
                                            alt="Go Admisia Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>


                    }


                </div>
            </div>

        );
    }
}