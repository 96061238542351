import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { DwsService } from '../../../service/dwsPoint/DwsService'
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { Paginator } from 'primereact/paginator';

const paymentMode = [
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Half Yearly", value: "Half Yearly" },
    { label: "Yearly", value: "Yearly" },
    { label: "Exam Wise", value: "Exam Wise" }
]

let urlId;

let errors = {};
export class FeesInfoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberStatus: '',
            dataTableValue: [],
            coreClassCategoryID: 0,
            coreGroupCategoryID: 0,
            checkEligibility: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            searchDataObj: {
                coreUrlInfoID: '',
                limit: 10,
                pageNo: 0
            },
            dataTableIsLoading: false,
            errors: {},
            first: 0, 
            rows: 10, 
            feeInfoUpdateDetails: {
                feeName: "",
                feeDetails: "",
                feeAmount: '',
                feeSerial: '',
                feeCreateDate: "",
                feePaymentMode: "",
                classCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                groupCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                coreUrlInfoDTO: {
                    urlInfoID: ''
                },
            }
        }

        this.DwsService = new DwsService();
        // this.groupNameTemplate = this.groupNameTemplate.bind(this);
        this.onChangeClass = this.onChangeClass.bind(this);
        this.onChangeGroup = this.onChangeGroup.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        this.viewFeeInfoList();
    }

    viewFeeInfoList = () => {
        let { searchDataObj } = this.state;
        this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: "" })
        searchDataObj.coreUrlInfoID = this.props.coreUrl;
        this.DwsService.fetchStudentFeeList(this.state.searchDataObj)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // if(body.length === 0) {
                        //     this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "No data found on datatable" })
                        // } else {
                            this.setState({ dataTableValue: body })
                            this.props.countTableSize(body.length);
                            this.setState({ topProgressBar: false, errorMsgVisible: false })
                        // }
                    });
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    onHide = (event) => {
        this.setState({ dialogVisible: false });
    }

    viewDialog = (rowData) => {
        this.setState({ dialogVisible: true });
    }

    viewDownloadData = (rowData) => {
        this.setState({ dialogVisible: true, errors: {} });

        let { feeInfoUpdateDetails } = this.state
        let { classCategoryInfoDTO, groupCategoryInfoDTO, coreUrlInfoDTO } = this.state.feeInfoUpdateDetails

        feeInfoUpdateDetails.feeId = rowData.feeId
        feeInfoUpdateDetails.feeName = rowData.feeName
        feeInfoUpdateDetails.feeDetails = rowData.feeDetails
        feeInfoUpdateDetails.feeAmount = rowData.feeAmount
        feeInfoUpdateDetails.feeSerial = rowData.feeSerial
        feeInfoUpdateDetails.feePaymentMode = rowData.feePaymentMode
        feeInfoUpdateDetails.classCategoryInfoDTO.coreCategoryID = rowData.classCategoryInfoDTO.coreCategoryID
        feeInfoUpdateDetails.groupCategoryInfoDTO.coreCategoryID = rowData.groupCategoryInfoDTO.coreCategoryID

        classCategoryInfoDTO.coreCategoryID = rowData.classCategoryInfoDTO.coreCategoryID
        classCategoryInfoDTO.categoryName = rowData.classCategoryInfoDTO.categoryName
        groupCategoryInfoDTO.coreCategoryID = rowData.groupCategoryInfoDTO.coreCategoryID
        groupCategoryInfoDTO.categoryName = rowData.groupCategoryInfoDTO.categoryName
        coreUrlInfoDTO.urlInfoID = rowData.coreUrlInfoDTO.urlInfoID

        this.setState({ feeInfoUpdateDetails });
        this.setState({ classCategoryInfoDTO });
        this.setState({ groupCategoryInfoDTO });
        this.setState({ coreUrlInfoDTO });

    }

    countTableSize() {
        const { dataTableValue } = this.state;
        return dataTableValue.length;
    }

    actionTemplate = (rowData) => {
        return <div className="text-center">
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                onClick={(e) => this.viewDownloadData(rowData)}
            >
            </Button>
        </div>;
    }

  
    onChangeFeeSerial = (e) => {
        errors = {}
        let { feeInfoUpdateDetails } = this.state;
        feeInfoUpdateDetails.feeSerial = e.target.value
        this.setState({ feeInfoUpdateDetails, errors })
    }

    onChangeFeeName = (e) => {
        errors = {}
        let { feeInfoUpdateDetails } = this.state;
        feeInfoUpdateDetails.feeName = e.target.value
        this.setState({ feeInfoUpdateDetails, errors })
    }

    onChangeFeeDetails = (e) => {
        errors = {}
        let { feeInfoUpdateDetails } = this.state;
        feeInfoUpdateDetails.feeDetails = e.target.value
        this.setState({ feeInfoUpdateDetails, errors })
    }

    onChangeFeeAmount = (e) => {
        errors = {}
        let { feeInfoUpdateDetails } = this.state;
        feeInfoUpdateDetails.feeAmount = e.target.value
        this.setState({ feeInfoUpdateDetails, errors })
    }

    onChangePaymentMode = (e) => {
        errors = {}
        let { feeInfoUpdateDetails } = this.state;
        feeInfoUpdateDetails.feePaymentMode = e.target.value
        this.setState({ paymentMode: e.target.value, errors })

    }

    onChangeClass = (e) => {
        let { feeInfoUpdateDetails } = this.state;
        errors = {}
        feeInfoUpdateDetails.classCategoryInfoDTO.coreCategoryID = e.target.value
        this.setState({ feeInfoUpdateDetails, errors })
    }

    onChangeGroup = (e) => {
        let { feeInfoUpdateDetails } = this.state;
        errors = {}
        feeInfoUpdateDetails.groupCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ feeInfoUpdateDetails, errors })
    }

    handleError() {
        let { feeInfoUpdateDetails } = this.state

        let formIsValid = true;
        this.setState({ topProgressBar: false });


        if (feeInfoUpdateDetails.feeSerial === '') {
            formIsValid = false;
            errors["serialNumber"] = "Serial can't left empty.";
        }
        if (!feeInfoUpdateDetails.classCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["classError"] = "Class can't left empty.";
        }
        if (!feeInfoUpdateDetails.groupCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["groupError"] = "Group can't left empty.";
        }
        if (feeInfoUpdateDetails.feeName === '') {
            formIsValid = false;
            errors["feeName"] = "Fee Name can't left empty.";
        }
        if (feeInfoUpdateDetails.feeDetails === '') {
            formIsValid = false;
            errors["details"] = "Details can't left empty.";
        }
        if (feeInfoUpdateDetails.feeAmount === '') {
            formIsValid = false;
            errors["feeAmount"] = "Amount can't left empty.";
        }
        if (!feeInfoUpdateDetails.feePaymentMode) {
            formIsValid = false;
            errors["paymentMode"] = "Mode can't left empty.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    updateFeeInfo = (event, props) => {
        let { feeInfoUpdateDetails } = this.state;

        if (this.handleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.setState({ feeInfoUpdateDetails });
            this.DwsService.updateFeeInfo(feeInfoUpdateDetails)
                .then(res => {
                    if (res.status === 201) {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully Updated",
                            life: 800
                        });
                        setTimeout(() => {
                            this.onHide();
                        }, 800);
                        this.viewFeeInfoList();
                    } else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        }
    }

    cancelFeeInfo = (e) => {
        this.setState({ dialogVisible: false, errors: {} });
    }

    onPageChange = (event) => {

        let { searchDataObj } = this.state
        searchDataObj.pageNo = event.page

        this.setState({
            first: event.first,
            rows: event.rows
        });

        this.viewFeeInfoList();
    }

    render() {
        let { dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { feeInfoUpdateDetails } = this.state

        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                                Fee Info List
                                    </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length}
                                </a>
                            </div>
                        </div>;
        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12 blur-section">
                        <div className="nw-data-table">
                            <Growl ref={(el) => this.growl = el} />
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader()
                                :
                                <div>
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                    columnResizeMode="fit"
                                >
                                    <Column field="feeSerial" header="Serial No." filter={true} />
                                    <Column field="classCategoryInfoDTO.categoryName" header="Class" filter={true} />
                                    <Column field="groupCategoryInfoDTO.categoryName" header="Group" filter={true} />
                                    <Column field="feeName" header="Fee Name" filter={true} />
                                    <Column field="feeAmount" header="Fee Amount" filter={true} />
                                    <Column field="feePaymentMode" header="Payment Mode" filter={true} />
                                    <Column header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                </DataTable>
                                    {/* <Paginator
                                        first={this.state.first}
                                        rows={this.state.rows}
                                        totalRecords={this.state.first + 20}
                                        onPageChange={this.onPageChange}>
                                    </Paginator> */}
                            </div>
                            }
                        </div>
                    </div>


                    <div className="dialog-section">
                        <Dialog 
                            className="nw-dialog" 
                            header="Update Fee Information" 
                            maximizable 
                            visible={this.state.dialogVisible}
                            onHide={this.onHide} 
                            closable
                        >
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No. <span>*</span></span>

                                                <div className="nw-inputgroup-desc">
                                                    { feeInfoUpdateDetails.feeSerial }
                                                </div>
                                                {/* <InputText
                                                    value={feeInfoUpdateDetails.feeSerial}
                                                    id="serialNo"
                                                    placeholder="Enter serial number"
                                                    showClear={true}
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="serialNumber"
                                                    onChange={this.onChangeFeeSerial}
                                                /> */}
                                            </div>
                                            <span className='error-message'></span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Class <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Class"
                                                    value={feeInfoUpdateDetails.classCategoryInfoDTO.coreCategoryID}
                                                    options={this.props.classList}
                                                    onChange={this.onChangeClass}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["classError"]}</span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Group <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Group"
                                                    value={feeInfoUpdateDetails.groupCategoryInfoDTO.coreCategoryID}
                                                    options={this.props.groupList}
                                                    onChange={this.onChangeGroup}
                                                    // itemTemplate={this.bankTemplate}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["groupError"]}</span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Fee Name <span>*</span></span>
                                                <InputText
                                                    id="feeName"
                                                    value={feeInfoUpdateDetails.feeName}
                                                    placeholder="Enter fee name"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="feeName"
                                                    onChange={this.onChangeFeeName}
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors["feeName"]}</span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                                <InputTextarea
                                                    placeholder="Enter fee details"
                                                    rows={3}
                                                    cols={30}
                                                    autoResize={true}
                                                    id="detail"
                                                    name="feeDetails"
                                                    onChange={this.onChangeFeeDetails}
                                                    value={feeInfoUpdateDetails.feeDetails}
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors['details']}</span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Fee Amount <span>*</span></span>
                                                <InputText
                                                    id="feeAmount"
                                                    // onChange={this.onChangeTransferAmount}
                                                    value={feeInfoUpdateDetails.feeAmount}
                                                    keyfilter="pint"
                                                    placeholder="Enter fee amount"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="feeAmount"
                                                    onChange={this.onChangeFeeAmount}
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors["feeAmount"]}</span>
                                        </div>

                                        <div className="p-col-12 p-md-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Payment Mode <span>*</span></span>
                                                <Dropdown
                                                    id="paymentMode"
                                                    placeholder="Select Payment Mode"
                                                    options={paymentMode}
                                                    value={feeInfoUpdateDetails.feePaymentMode}
                                                    onChange={this.onChangePaymentMode}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="paymentMode"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["paymentMode"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                            <Button
                                                className="p-button p-button-danger nw-button nw-button-multiple"
                                                label="Discard"
                                                icon="fas fa-times"
                                                onClick={this.cancelFeeInfo.bind(this)}
                                            />
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-multiple"
                                                label="Update"
                                                icon="fas fa-check"
                                                onClick={this.updateFeeInfo.bind(this)}
                                            />
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        );
    }
}