import React, {Component} from 'react';
import {CategoryType} from "./CategoryType"
import {CategoryName} from "./CategoryName"
import {TabView,TabPanel} from 'primereact/tabview';
export class CategoryInfo extends Component {

    // constructor(props) {
	// 	super(props);
	// 	this.state = {
    //     }
    // }
        
    render() {

        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview nw-tabview-new">
                        <TabView>
                            <TabPanel header="Category Type">
                                <CategoryType/>  
                            </TabPanel>
                            <TabPanel header="Category Name" >
                                <CategoryName/>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }
}