import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { BalanceService } from '../../../service/myPoint/BalanceService';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
// pdf related import
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import { pdfDownloadLandscape_FullTable, pdfDownloadLandscape_TableWithSubTitle } from '../../common/JsPdfDownload';

import {
  pdfmeta,
  pdfday,
  powerddBy_landscape,
  powerddBy_portrait,
  date_landscape,
  date_portrait,
  pfont,
  btmLine_landscape,
  btmLine_portrait,
  getPdfHeader
} from "../../common/JsPdfEssentialFile";
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
// pdf related import end

let maxDate = new Date();
export default class BalanceWalletLogDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {

            dateRequest: '',
            bank: '',
            accountNumber: '',
            requestedAmount: '',
            transactionDate: '',
            transactionNumber: '',
            approveDate: '',
            requestStatus: '',
            note: '',

            startDate: null,
            errors: {},
            endDate: null,
            rejectedStutas: 'Your Rejected Status Here',
            dataTableSelection: null,
            dataViewValue: [],
            tableView: false,
            inputErrorMessage: null,
            dataTableValue: [],
            dateRangeParamFormat: {
                requestStartDate: "",
                requestEndDate: "",
                requestType: "Deposit",
                transactionType: "",
                status: 5,
                limit: 100
            },
            pdfFooterArray: [],
            visible: false,
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            rejectNote: ''

        };

        this.statusBody = this.statusBody.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.balanceService = new BalanceService();
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
    }

    // statusBody(rowData) {

    //     if (rowData['status'] === "Rejected") {
    //         return <span style={{ color: "red" }} tooltip="Insufficient Balance To Withdraw">
    //             <u>{rowData['status']}</u>
    //         </span>;
    //     }
    //     else {
    //         return <span>{rowData['status']}</span>;
    //     }
    // }

    onSubmitHandler = (event, props) => {
        let { dateRangeParamFormat } = this.state
        this.setState({ dataTableValue: [] });

        if (this.searchHandleError()) {
            this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(dateRangeParamFormat.requestEndDate) >= Date.parse(dateRangeParamFormat.requestStartDate)) {
                this.balanceService.fetchBalanceDepositListByDateRange(dateRangeParamFormat)
                    .then(res => {
                        if (res.status == 200) {
                            return res.json().then((body) => {
                                console.log("BODYYYYY", body);
                                body.map((item) => {
                                   
                                    //item['requestTime'] = NetiDateUtils.getHHMMSSFForHome(item.requestDate);
                                    item['requestDate'] = NetiDateUtils.getAnyShortForm(item['requestDate'], 'DD-MMM-YYYY hh:mm:ss a');
                                    item['transactionTime'] = NetiDateUtils.getHHMMSSFForHome(item.transactionDate);
                                    item['transactionDate'] = NetiDateUtils.getAnyShortForm(item['transactionDate'], 'DD-MMM-YYYY');
                                   
                                });

                                if (body.length == 0) {
                                    return this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found.' })
                                }

                                this.setState({ dataTableValue: body, topProgressBar: false, tableView: true, dataTableIsLoading: false });
                                console.log('dataTableValue', this.state.dataTableValue);
                                
                            });
                        } else {
                            this.balanceService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });

            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be greater than To Date." });
                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: false })
            }

        }
    }

    searchHandleError = () => {
        let { errors, dateRangeParamFormat } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (dateRangeParamFormat.requestStartDate === '') {
            formIsValid = false;
            errors["requestStartDate"] = "Start Date can't left empty.";
        }
        if (dateRangeParamFormat.requestEndDate === '') {
            formIsValid = false;
            errors["requestEndDate"] = "End Date can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onClick = () => {
        this.setState({ visible: true })
    }

    onHide() {
        this.setState({ visible: false })
    }

    onChangeStartDate = (e) => {
        let { dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        this.clearDateError(e.target.name);

    }

    statusBody(rowData) {
        if (rowData['requestStatus'] === "Rejected") {
            return <span className="text-danger">
                <span 
                >
                    {rowData['requestStatus']}
                </span>
            </span>;
        }   else if (rowData['requestStatus'] === "Approved") {
            return <span className="text-success">
                <span 
                >
                    {rowData['requestStatus']}
                </span>
            </span>;
        }  else if (rowData['requestStatus'] === "Pending") {
            return <span className="text-warn">
                <span 
                >
                    {rowData['requestStatus']}
                </span>
            </span>;
        }
        else {
            return <span>{rowData['requestStatus']}</span>;

        }




        // if (rowData['requestStatus'] === "Rejected") {
        //     return <span 
        //     // onClick={(e) => this.viewDialog(rowData)}
        //         style={{ color: "red" }} tooltip="Insufficient Balance To Withdraw">
        //         {rowData['requestStatus']}
        //     </span>;
        // }
        // else if (rowData['requestStatus'] === "approved") {
        //     return <span>
        //         {rowData['requestStatus']}
        //     </span>;
        // }
        // return <span>
        //     {rowData['requestStatus']}
        // </span>;
    }


    viewDialog = (rowData) => {
        this.setState({
            visible: true,
            rejectNote: rowData.approveNote

        })

        this.setState({ dateRequest: rowData.requestDate === "null" ? " " : rowData.requestDate})
        this.setState({ bank: rowData.bank })
        this.setState({ transactionType: rowData.accountNumber })
        this.setState({ requestedAmount: this.validatorUtility.currencyFormatter(rowData.requestedAmount) })

        this.setState({ transactionDate: rowData.transactionDate});
        this.setState({ transactionNumber: rowData.transactionNumber })
        this.setState({ approveDate: rowData.approveDate === "null" ? " " : NetiDateUtils.getAnyShortForm(rowData['approveDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['approveDate']) })
        this.setState({ requestStatus: rowData.requestStatus })
        this.setState({ note: rowData.approveNote })
    }

    requestedAmount = (rowData) => {
        
        if (rowData['requestStatus'] === "Rejected") {
            return <span className="text-danger">
                <span
                >
                    <b> {this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</b>
                </span>
            </span>;
        } else if (rowData['requestStatus'] === "Approved") {
            return <span className="text-success">
                <span
                >
                    <b> {this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</b>
                </span>
            </span>;
        } else if (rowData['requestStatus'] === "Pending") {
            return <span className="text-warn">
                <span
                >
                    <b> {this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</b>
                </span>
            </span>;
        }
        else {
            return (<b> {this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</b>);
        }
    }

    detailBody = (rowData, column) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title='Click to view deposit details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    onChangeStatus(value) {
        this.setState({ requestStatus: value });
    }

    changeStatusFontColor = (status) => {
        if (status === "Rejected") {
            return <span className="text-danger">
                <span 
                >
                    {status}
                </span>
            </span>;
        }   else if (status === "Approved") {
            return <span className="text-success">
                <span 
                >
                    {status}
                </span>
            </span>;
        }  else if (status === "Pending") {
            return <span className="text-warn">
                <span 
                >
                    {status}
                </span>
            </span>;
        }
        else {
            return <span>{status}</span>;

        }

    }

    changeRequestAmountFontColor = (requestedAmount) => {
        if (this.state.requestStatus === "Approved") {
            return <span className="text-success">
                <span
                >
                    {requestedAmount}
                </span>
            </span>;
        } else if (this.state.requestStatus === "Pending") {
            return <span className="text-warn">
                <span
                >
                    {requestedAmount}
                </span>
            </span>;
        } else if (this.state.requestStatus === "Rejected") {
            return <span className="text-danger">
                <span
                >
                    {requestedAmount}            </span>
            </span>;
        }
        else {
            return (<span>{requestedAmount}
            </span>);
        }

    }

    exportPdf = () => {
       
        let pdfColumns = [
            { title: "Request Date", dataKey: "requestDate" },
            { title: "Bank", dataKey: "bank" },
            { title: "Account Number", dataKey: "accountNumber" },
            { title: "Requested Amount", dataKey: "requestedAmount"},
            { title: "Request Status", dataKey: "requestStatus" },
        ]
        
        let tableColumnStyles = {
            requestedAmount: { halign: "right"},
        }

        pdfDownloadLandscape_TableWithSubTitle("Wallet Logs Deposite",120, pdfTitleHeader, pdfTitleHeaderData, pdfColumns, this.state.dataTableValue, tableColumnStyles,  "wallet_logs_deposite.pdf");
          
    }


    render() {
        let { dateRangeParamFormat, dataTableValue } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="header-title"><div className="header-title-left">Balance Deposit List</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div></div>;
        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" },
          ];
          pdfTitleHeaderData = [
            { A: "From Date", B: NetiDateUtils.getAnyShortForm(dateRangeParamFormat.requestStartDate, 'DD-MMM-YYYY'), C: "End Date", D: NetiDateUtils.getAnyShortForm(dateRangeParamFormat.requestEndDate, 'DD-MMM-YYYY') }
          ];


        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <Growl ref={(el) => this.growl = el} />
                            {topProgressBar ?
                                <ErrorMessageView
                                    topProgressBar={topProgressBar}
                                />
                                : null
                            }
                            {errorMsgVisible ?
                                <ErrorMessageView
                                    errorMsgVisible={errorMsgVisible}
                                    errorMsgBody={errorMsgBody}
                                />
                                : null
                            }
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                    <Calendar
                                        name='requestStartDate'
                                        value={dateRangeParamFormat.requestStartDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["requestStartDate"]}</span>

                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        name='requestEndDate'
                                        value={dateRangeParamFormat.requestEndDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        dateFormat='dd/mm/yy'
                                        yearRange="2010:2030"
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["requestEndDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                <Button
                                    label="Search"
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitHandler}
                                />
                            </div>
                        </div>


                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table nw-data-table-tabview">

                                    {
                                        this.state.tableView === true ?
                                            this.state.dataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader()
                                                :
                                                <div>
                                                    <DataTable
                                                        value={this.state.dataTableValue}
                                                        selectionMode="single"
                                                        header={tableHeader}
                                                        selection={this.state.dataTableSelection}
                                                        onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                        responsive={true}
                                                        paginator={true}
                                                        rows={10}
                                                    >
                                                        <Column field="requestDate" header="Request Date & Time" filter={true} className="table-datetime" />
                                                        <Column field="bank" header="Bank" filter={true} />
                                                        <Column field="accountNumber" header="Account Number" filter={true} />
                                                        <Column field="requestedAmount" header="Request Amount" className="text-right" body={this.requestedAmount} sortable={true} filter={true} />
                                                        <Column field="requestStatus" header="Status" body={this.statusBody} filter={true} />
                                                        <Column field="" header="Details" body={this.detailBody}  style={{width:"95px"}}/>
                                                    </DataTable>
                                                    <div className="p-col-12 nw-button-parent" style={{paddingRight:"0px"}}>
                                                        <Button
                                                            label="Download PDF"
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            icon="fas fa-file-pdf"
                                                            onClick={this.exportPdf}
                                                        />
                                                    </div>
                                                </div>
                                            :
                                            <BlankDataTableAnim />

                                    }

                                </div>
                            </div>

                            <Dialog 
                                className="nw-dialog" 
                                header="Deposit Details" 
                                visible={this.state.visible} 
                                onHide={this.onHide} 
                                closable
                            >

                                <div className="p-fluid">
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12">

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Request Date
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.dateRequest}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Bank
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.bank}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Account Number
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.transactionType}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Request Amount
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                    {this.state.requestedAmount ? this.changeRequestAmountFontColor(this.state.requestedAmount) : ''}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Transaction Date
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.transactionDate == 'Invalid date' ? "N/A" : this.state.transactionDate}
                                                    </div>
                                                </div>
                                            </div>


                                            {this.state.requestStatus === "Approved" ?
                                                <div className="formControl">

                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Transaction ID
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.transactionNumber}
                                                        </div>
                                                    </div>

                                                </div>

                                                : ''
                                            }

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Status
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                    {this.state.requestStatus ? this.changeStatusFontColor(this.state.requestStatus) : ''}
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.requestStatus === "Approved" ?
                                                <div className="formControl">

                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Approved Date
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.approveDate === "null" ? " " : this.state.approveDate}
                                                        </div>
                                                    </div>

                                                </div>

                                                : ''
                                            }

                                            {this.state.requestStatus === "Rejected" ?
                                                <div className="formControl">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Reject Date
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.approveDate}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                            }


                                            {this.state.requestStatus === "Rejected" ?
                                                <div className="formControl">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Reject Note
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.note}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                            }


                                        </div>
                                    </div>
                                </div>





                            </Dialog>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}