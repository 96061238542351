import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';


export class BalanceService {
    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    checkBalanceTransfer(checkbalance) {
        let uri = this.baseURIHolder.getUser() + '/transfer/check/balance';
        return this.Auth.postFetch(uri, checkbalance)
            .catch((error) => console.log("error", error));

    }

    getUserWalletBalance() {
        let uri = this.baseURIHolder.getUser() + '/balance';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }


    balanceDepositeSave(depositBalance) {
        let uri = this.baseURIHolder.getUser() + '/balance/deposit';
        return this.Auth.postFetch(uri, depositBalance)
            .catch((error) => console.log("error", error));
    }

    fetchTopTenBalanceDepositList(type) {
        let uri = this.baseURIHolder.getUser() + '/balance/requests/top10?type=' + type;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchBalanceDepositListByDateRange(dateRangeParamFormat) {
        let uri = this.baseURIHolder.getUser() + '/balance/requests/by/date_range';
        return this.Auth.postFetch(uri, dateRangeParamFormat)
            .catch((error) => console.log("error", error));
    }




    // getTopTenBalanceDepositList(type) {
    //     let
    //         uri = this.baseURIHolder.getUser() + '/get-top-requests';
    // }

    // sendUserBalanceTransferInfo(userBalanceTransferInfo) {
    //     let uri = this.baseURIHolder.getUser() + '/transfer-balance';
    //     return this.Auth.fetch(uri,userBalanceTransferInfo)
    //     .catch((error) => console.log("error",error));
    userBalanceTransfer(balanceTransfer) {
        let uri = this.baseURIHolder.getUser() + '/balance/transfer';
        return this.Auth.postFetch(uri, balanceTransfer)
            .catch((error) => console.log("error", error));

    }

    getUserTopTenBalanceTransferList() {
        let uri = this.baseURIHolder.getUser() + '/get-top-records';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserBalanceTransferRecords(balanceTransferRecords) {
        let uri = this.baseURIHolder.getUser() + '/balance/transfer/by/date';
        return this.Auth.postFetch(uri, balanceTransferRecords)
            .catch((error) => console.log("error", error));

    }

    addNewBankInfo(bankInfo) {
        let uri = this.baseURIHolder.getUser() + '/bank/add-account';
        return this.Auth.postFetch(uri, bankInfo)
            .catch((error) => console.log("error", error));
    }

    findUserBankAccByCoreBankID(coreBankID) {
        console.log(coreBankID);
        let uri = this.baseURIHolder.getUser() + '/bank/accounts/get?bankID=' + coreBankID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserBalanceStatementRecords(balanceStatement) {
        let uri = this.baseURIHolder.getUser() + '/balance/statement';
        return this.Auth.postFetch(uri, balanceStatement)
            .catch((error) => console.log("error", error));

    }
    withdrawRequestByAccountID(withdrawInfo) {
        let uri = this.baseURIHolder.getUser() + '/balance/withdraw';
        return this.Auth.postFetch(uri, withdrawInfo)
            .catch((error) => console.log("error", error));
    }

    getTopRTenBalanceListByType(balanceType) {
        let uri = this.baseURIHolder.getUser() + '/balance/requests/top10?type=' + balanceType;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    withDrawBalanceRequestByDate(withdrawSearchData) {
        let uri = this.baseURIHolder.getUser() + '/balance/requests/by/date_range';
        return this.Auth.postFetch(uri, withdrawSearchData)
            .catch((error) => '')
    }

    getUserBalanceStatementRecords(balanceStatement) {
        let uri = this.baseURIHolder.getUser() + '/balance/statement';
        return this.Auth.postFetch(uri, balanceStatement)
            .catch((error) => console.log("error", error));

    }

    fetchBankInfoList() {
        let uri = this.baseURIHolder.getUser() + '/bank/accounts/by/user';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    
}


