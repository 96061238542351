import React, { Component } from 'react';
import { EmYssConfig } from './EmYssConfig';
import { EmYssDevice } from './EmYssDevice';
import { TabView, TabPanel } from 'primereact/tabview';

export class EmYssConfigMain extends Component {
    
    render() {
        return (
            <div className="p-fluid">
                <div className="p-grid">

                    <div className="p-col-12 p-lg-12 p-xl-12 nw-tabview">
                        <TabView >
                            <TabPanel header="Institute List">
                                <EmYssConfig />
                            </TabPanel>
                            <TabPanel header="Device List">
                                <EmYssDevice />
                            </TabPanel>
                        </TabView>
                    </div>

                </div>
            </div>
        )
    }
}