import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Editor } from 'primereact/editor';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { UserCategoryService } from '../../service/UserCategoryService';
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';
import CommonFuctionality from '../common/CommonFuctionality';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { NetiFileHandler } from '../../utils/NetiFileHandler';

export class EMBillRegenerateInfo extends Component {

    constructor() {
        super();
        this.state = {
            tokenPicInfo: {
                contentPic: ''
            },
            errors: {},
            tokenTypeID: '',
            billableStudent: '',
            getEmBillRegenerateDiffObj: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            changingPurposeDropdownList: [],
            errorMsgVisible: false,
            tokenDetails: '',
            emBillGeneratedLogList: [],
            regenerateDialogHide: false,
            errorMsgBody: null,
            generateBillId: ''
        }

        this.commonFuctionality = new CommonFuctionality();
        this.CRMPointService = new CRMPointService();
        this.netiFileHandler = new NetiFileHandler();
        this.userCategoryService = new UserCategoryService();
        this.validatorUtility = new ValidatorUtility();
    }

    getChangingPurposeList = () => {
        this.setState({ topProgressBar: true, errorMsgBody: '', errorMsgVisible: false });
        this.userCategoryService.findByCategoryDefaultCode('C11002026')
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ changingPurposeDropdownList: body });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection.", topProgressBar: false })
            });
    }


    regenerateUpdateDialog = (param) => {
        this.setState({ regenerateDialogHide: true });
        this.setState({ emDetailsID: param });
        this.getEmBillGeneratedDetailsLog(param);
        this.getChangingPurposeList();
        this.commonFuctionality.blurDialogBackgroundActive();
    }

    regenerateUpdateDialogHide = () => {
        this.setState({ regenerateDialogHide: false });
        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    onChangePurposeList = (e) => {
        let { errors } = this.state;
        errors["purposeList"] = "";
        this.setState({ tokenTypeID: e.target.value, errors });
    }

    onChangeTokenDetails = (e) => {
        let { errors } = this.state;
        errors["tokenDetails"] = "";
        this.setState({ tokenDetails: e.htmlValue, errors });
    }



    getEmBillGeneratedDetailsLog = (param) => {
        this.setState({ topProgressBar: true, errorMsgBody: '', errorMsgVisible: false });
        this.CRMPointService.getEdumanBillLogByBillType("Bill Generate", param)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ emBillGeneratedLogList: body });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection.", topProgressBar: false })
            });
    }


    getEmBillRegenerateDiff = (param) => {
        this.setState({ topProgressBar: true, errorMsgBody: '', errorMsgVisible: false });
        this.CRMPointService.getEmBillDiffForRegenerate(param, this.state.emDetailsID)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("em bill body", body);
                        this.setState({ getEmBillRegenerateDiffObj: body });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection.", topProgressBar: false })
            });
    }

    onChangeEmBillLogId = (e) => {
        let { errors } = this.state;
        errors["generateBillID"] = "";
        this.setState({ generateBillId: e.target.value, errors });
        this.getEmBillRegenerateDiff(e.target.value);
    }

    onChangeBillableStudent = (e) => {
        this.setState({ billableStudent: e.target.value });

    }

    handleSubmit = () => {
        if (this.handleSubmitError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false })
            let regenerateEmBillObj = {
                "emBillGenerateLogDTO": {
                    "generateBillId": this.state.generateBillId
                },
                "billableStudent": this.state.billableStudent,
                "tokenInfoDTO": {
                    "attachContent": this.state.tokenPicInfo.contentPic,
                    "attachSaveOrEditable": true,
                    "attachmentName": Date.now() + this.netiFileHandler.getImageExtention(this.state.tokenPicInfo.extention),
                    "tokenDetails": this.state.tokenDetails,
                    "tokenTypeInfoDTO": {
                        "coreCategoryID": this.state.tokenTypeID
                    }
                }
            }
            this.CRMPointService.regenerateEdumanBill(regenerateEmBillObj)
                .then(res => {
                    if (res.status === 202) {
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully Submitted"
                        });
                        this.regenerateUpdateDialogHide();
                        this.setState({ topProgressBar: false })
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                })
                .catch(error =>
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                );
        }

    }



    handleSubmitError() {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.tokenTypeID === "" || this.state.tokenTypeID === null) {
            formIsValid = false;
            errors["purposeList"] = "Changing Purpose can't left empty.";
        }
        if (this.state.tokenDetails === "") {
            formIsValid = false;
            errors["tokenDetails"] = "Token Details can't left empty.";
        }
        if (this.state.tokenPicInfo.contentPic === "") {
            formIsValid = false;
            errors["attachments"] = "Attachment can't left empty.";
        }
        if (this.state.generateBillId === "") {
            formIsValid = false;
            errors["generateBillID"] = "Bill Year month can't left empty.";
        }
        if (this.state.billableStudent === "") {
            formIsValid = false;
            errors["billableStudent"] = "Billable Student can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;
    }





    onFileUpload(e) {
        let { errors } = this.state;
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let tokenPic = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ tokenPicInfo: tokenPic });
        }
        errors["attachments"] = "";
        this.setState({ errors });
    }

    removeButtonOnclick = () => {
        this.setState({ tokenPicInfo: { contentPic: '' } });
        this.setState({ tokenPicInfo: { contentName: '' } });

    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, emAccessInfoObj, getEmBillRegenerateDiffObj } = this.state;
        let packageList = [];
        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">370 x 240 px (recommended)</span></div>

        let purposeList = []
        if (this.state.changingPurposeDropdownList && this.state.changingPurposeDropdownList.length) {
            purposeList = this.state.changingPurposeDropdownList.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        let billGeneratedLogList = []
        if (this.state.emBillGeneratedLogList && this.state.emBillGeneratedLogList.length) {
            billGeneratedLogList = this.state.emBillGeneratedLogList.map((item) => ({
                label: item.emBillGenerateLogDTO.billYear + ' - ' + item.emBillGenerateLogDTO.billMonth,
                value: item.emBillGenerateLogDTO.generateBillId,
            }));
        }

        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />
                <Dialog header="Bill Regenerate" visible={this.state.regenerateDialogHide} onHide={this.regenerateUpdateDialogHide} className="nw-dialog product-info-dialog">
                    <div className="p-fluid">
                        <div className="p-grid  nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        {this.state.packageDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Change purpose</span>
                                                <Dropdown
                                                    placeholder='select purpose'
                                                    value={this.state.tokenTypeID}
                                                    name="purposeList"
                                                    options={purposeList}
                                                    onChange={this.onChangePurposeList}
                                                    filter={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                        }
                                        <span className='error-message'>{this.state.errors["purposeList"]}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtexteditor-label">DETAILS</span>
                                            <Editor
                                                className="nw-inputtexteditor"
                                                value={this.state.tokenDetails}
                                                name="tokenDetails"
                                                onTextChange={this.onChangeTokenDetails}
                                                autoWidth={false}
                                                maxLength={200}
                                                placeholder="Enter token Details"

                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["tokenDetails"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload Attachment <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/PDF)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                {
                                                    this.state.tokenPicInfo.contentPic ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={this.removeButtonOnclick}
                                                                />
                                                                {this.state.tokenPicInfo.contentName ?
                                                                    this.commonFuctionality.getFileContentTypeIcon(this.state.tokenPicInfo.contentName) : ''}

                                                            </div>
                                                            <div className="image-title">{this.state.tokenPicInfo.contentName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            id="attachments"
                                                            name="attachments"
                                                            mode="basic"
                                                            maxFileSize={1000000}
                                                            onSelect={this.onFileUpload.bind(this)}
                                                            auto={true}
                                                            chooseLabel={this.state.tokenPicInfo.contentName ? this.state.tokenPicInfo.contentName : "Click / Drag Here To Upload"}

                                                        />
                                                }

                                            </div>

                                        </div>
                                        <span className='error-message'>{this.state.errors["attachments"]}</span>
                                    </div>



                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Year - Month</span>
                                            <Dropdown
                                                placeholder='Select bill year month'
                                                name='generateBillID'
                                                value={this.state.generateBillId}
                                                options={billGeneratedLogList}
                                                onChange={this.onChangeEmBillLogId}
                                                filterBy="value"
                                                autoWidth={false}
                                                filter={true}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["generateBillID"]}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <br />
                                        <div className="custom-header">
                                            <i className="fa fa-wallet"></i>
                                            <h1>Processed Bill Info</h1>
                                        </div>
                                    </div>
                                    <div className="p-sm-12 p-xl-6 p-col-md">
                                        <div className="nw-search-view no-border p-0">
                                            <div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">ACADEMIC YEAR</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.processedBill && getEmBillRegenerateDiffObj.processedBill.billGenerateLogDTO && getEmBillRegenerateDiffObj.processedBill.billGenerateLogDTO.academicYear}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">BILLABLE STUDENT</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.processedBill && getEmBillRegenerateDiffObj.processedBill.billGenerateLogDTO && getEmBillRegenerateDiffObj.processedBill.billGenerateLogDTO.billableStudent}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">DISABLE STUDENT</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.processedBill && getEmBillRegenerateDiffObj.processedBill.billGenerateLogDTO && getEmBillRegenerateDiffObj.processedBill.billGenerateLogDTO.disableStudent}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">MONTHLY RATE</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.currentBill && getEmBillRegenerateDiffObj.currentBill.monthlyRate}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-sm-12 p-xl-6 p-col-md">
                                        <div className="nw-search-view no-border p-0">
                                            <div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Payable</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.processedBill && getEmBillRegenerateDiffObj.processedBill.billGenerateLogDTO && getEmBillRegenerateDiffObj.processedBill.billGenerateLogDTO.payableBill}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Paid</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.processedBill && getEmBillRegenerateDiffObj.processedBill.paidAmount}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Discount</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.processedBill && getEmBillRegenerateDiffObj.processedBill.discountAmount}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Due</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc danger">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.processedBill && getEmBillRegenerateDiffObj.processedBill.dueAmount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <br />
                                        <div className="custom-header">
                                            <           i class="far fa-id-card"></i>
                                            <h1>Current Status</h1>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-search-view no-border p-0">
                                            <div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Monthly Rate</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.currentBill && getEmBillRegenerateDiffObj.currentBill.monthlyRate}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found active"></span>
                                                        <span className="p-inputgroup-addon">ACADEMIC YEAR</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.currentBill && getEmBillRegenerateDiffObj.currentBill.academicYear}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found active"></span>
                                                        <span className="p-inputgroup-addon">ACTIVE STUDENT</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.currentBill && getEmBillRegenerateDiffObj.currentBill.billAbleStudent}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found active"></span>
                                                        <span className="p-inputgroup-addon">DISABLE STUDENT</span>
                                                        <span className="p-inputgroup-colon">:</span>
                                                        <div className="nw-inputgroup-desc">{getEmBillRegenerateDiffObj && getEmBillRegenerateDiffObj.currentBill && getEmBillRegenerateDiffObj.currentBill.disableStudent}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">BILLABLE STUDENT</span>
                                            <InputText
                                                placeholder='Select billable student'
                                                name='billableStudent'
                                                value={this.state.billableStudent}
                                                onChange={this.onChangeBillableStudent}
                                                filterBy="value"
                                                autoWidth={false}
                                                filter={true}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["billableStudent"]}</span>
                                    </div>
                                    <div className="p-col-12 p-col-12  nw-button-parent-multiple">
                                        <br />
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={this.regenerateUpdateDialogHide}
                                        />

                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Regenerate"
                                            onClick={this.handleSubmit}
                                            icon="fas fa-check"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>


        )
    }
}