import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { TreeTable } from 'primereact/treetable';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { PartnerPointService } from '../../../service/partnerPoint/PartnerPointService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Calendar } from 'primereact/calendar';
import { Workbook } from 'react-excel-workbook';

let assignedPartnerListArr = [];
let status = { display: 'table-cell' };
let maxDate = new Date();
let profile;

export class PartnerAreaWisePrepaidDeposit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableView: false,
            errors: {},
            onExpander: true,
            searchObj: {
                startDate: "",
                endDate: "",
                netiID: ''
            },
            assignedPartnerList: [],
            productTypeErr: {},
            productType: '',
            dataTableIsLoading: false,
            mbpListObj: {},
            zbpListObj: {},
            bdpListObj: {},
            assignListObj: {},
            assignListArr: [],
            expandedKeys: {},
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            first: 0,
            rows: 10,
            totalRecords: 0,
            loading: false,
            pdfTableData: []
        }

        this.managementPointService = new ManagementPointService;
        this.partnerPointService = new PartnerPointService;
        this.onExpand = this.onExpand.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.commonFuctionality = new CommonFuctionality();
    }

    onChangeStartDate = (e) => {
        let { searchObj } = this.state;
        searchObj.startDate = e.target.value
        this.setState({ searchObj });
        this.clearDateError(e.target.name);
    }

    onChangeEndDate = (e) => {
        let { searchObj } = this.state;
        searchObj.endDate = e.target.value
        this.setState({ searchObj });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onSearchHandler = () => {
        let { assignListObj, searchObj } = this.state;
        assignedPartnerListArr = []
        this.searchPrepaidCollectionSummary(null, assignListObj, false)
    }

    searchPrepaidCollectionSummary(lazyNode, objectName, isLeaf) {
        let { searchObj } = this.state;
        profile = JSON.parse(localStorage.getItem('Profile'));

        searchObj.netiID = (lazyNode != null ? lazyNode.data.netiID : profile.netiID)
        this.setState({ searchObj });

        if (this.searchErrorHandler()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, tableView: true, });
            this.partnerPointService.findPrepaidDepositSummary(searchObj)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {

                            // console.log('body', body);
                            this.setState({pdfTableData: body});
                            if (lazyNode && lazyNode.children.length === 0) {
                                body.map((item, i) => {
                                    objectName.data = item;
                                    objectName.children = [];
                                    objectName.leaf = isLeaf
                                    objectName.key = `${lazyNode['key']}-${i}`
                                    lazyNode.children.push(Object.assign({}, objectName))
                                })
                            }
                            else if (assignedPartnerListArr.length === 0) {
                                body.map((item, i) => {
                                    if (item.designation === "BEP") {
                                        this.setState({ onExpander: false });
                                    }
                                    objectName.data = item;
                                    objectName.children = []
                                    objectName.leaf = isLeaf
                                    objectName.key = i
                                    assignedPartnerListArr.push(Object.assign({}, objectName))
                                })
                            }

                            this.setState({ dataTableIsLoading: false, tableView: true, errorMsgVisible: false, topProgressBar: false });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection.' });
                });

            setTimeout(() => {
                this.commonFuctionality.setCustomizedTreeDatatable('pType', assignedPartnerListArr && assignedPartnerListArr[0] && assignedPartnerListArr[0].data && assignedPartnerListArr[0].data.designation);
            }, 500);
        }
    }

    searchErrorHandler = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.searchObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start Date can't left empty.";
        }
        if (this.state.searchObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onExpand(event) {
        let { mbpListObj, zbpListObj, bdpListObj } = this.state;

        let lazyNode = { ...event.node };
        if (lazyNode.data.designation === "MBP") {
            this.searchPrepaidCollectionSummary(lazyNode, mbpListObj, false)
        }
        else if (lazyNode.data.designation === "ZBP") {
            this.searchPrepaidCollectionSummary(lazyNode, zbpListObj, false)
        }
        else if (lazyNode.data.designation === "BDP") {
            this.searchPrepaidCollectionSummary(lazyNode, bdpListObj, true)
        }
    }

    onExpandToggle = (e) => {
        this.setState({ expandedKeys: e.value })
    }

    onChangeStatus = (rowData) => {
        return (<span>{rowData.data["partnerStatus"] === 1 ? "Active" : "Inactive"}</span>);
    }

    onChangeProductType = e => {
        this.setState({ productType: e.target.value });
    }

    amountBodyTemplate = (rowData) => {
        return <div className="col-number text-right">{rowData && rowData.data.amount}</div>
    }

    export() { 

        this.dt.exportCSV();

    }
    
    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        // excel
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (this.state.searchObj.startDate && this.state.searchObj.endDate) {
            formatedStartDate = (this.state.searchObj.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (this.state.searchObj.endDate.toLocaleDateString('en-GB'));
        }

        let excelColumns = "";
        let excelFileName = formatedStartDate+ "_to_"+ formatedEndDate+ "_PrePaid_Bill_Collection.xlsx";
        let excelSheetName = "PrePaid_Bill_Collection";
        excelColumns = [
            <Workbook.Column value="designation" label="Partner Type" />,
            <Workbook.Column value="area" label="Area" />,
            <Workbook.Column value="customNetiID" label="Neti ID" />,
            <Workbook.Column value="name" label="Name" />,
            <Workbook.Column value="amount" label="Amount" />,
        ];
        // end excel

        // console.log('assignedPartnerListArr', assignedPartnerListArr);
        
        let header = <div className="header-title">
        <div className="header-title-left">
            Area Wise Pre-Paid Deposit Report
        </div>
        <div className="header-title-right">
            <a>
                Total Found: {assignedPartnerListArr.length.toLocaleString("EN-IN")}
            </a>
        </div>
    </div>;
        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.searchObj.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            name="startDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.searchObj.endDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            name="endDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>

                                </div>

                                <div className="p-col-12 p-xl-2 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSearchHandler}
                                    />
                                </div>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-12 p-col-nogutter nw-data-table">
                                {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            : <div>
                                                <TreeTable
                                                    header={header}
                                                    lazy={true}
                                                    value={assignedPartnerListArr}
                                                    onExpand={this.onExpand}
                                                    expandedKeys={this.state.expandedKeys}
                                                    onToggle={this.onExpandToggle}
                                                    style={{ marginTop: '.5em' }}>
                                                    <Column field="designation" header="Partner Type" className='pType' expander={this.state.onExpander} />
                                                    <Column field="area" header="Area" />
                                                    <Column field="customNetiID" header="Neti ID" />
                                                    <Column field="name" header="Name" />
                                                    <Column field="" header="Amount" body={this.amountBodyTemplate} />
                                                </TreeTable>
                                                {/* <div className="nw-button-parent m-t-8 p-r-0">

                                                    <Workbook
                                                        filename={excelFileName}
                                                        element={
                                                            <Button
                                                                label="Download Excel"
                                                                icon="fas fa-download"
                                                                className="p-button p-button-primary nw-button nw-button-right"
                                                            />
                                                        }
                                                    >
                                                        <Workbook.Sheet data={this.state.pdfTableData} name={excelSheetName}>
                                                            {excelColumns}
                                                        </Workbook.Sheet>
                                                    </Workbook>

                                                </div> */}
                                            </div>
                                        :
                                        <BlankDataTableAnim />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}