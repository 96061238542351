import React, { component, Component } from "react";
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { InputText } from 'primereact/inputtext';
import NetiContentLoader from "../../common/NetiContentLoader";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { EmAdminPointService } from "../../../service/emAdminPoint/EmAdminPointService";

let totalLengthOfDT = 0;
export class OpsCollectionRefundReport extends Component {

    constructor() {
        super();
        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            firstDropdownIsLoading: false,
            errors: {},
            requestedSearchObj: { trnID: '' },
            searchResDetails: [],
        }

        this.ValidatorUtility = new ValidatorUtility();
        this.NetiContentLoader = new NetiContentLoader();
        this.emAdminPointService = new EmAdminPointService();
        this.clearEmptyErrorMsg = this.clearEmptyErrorMsg.bind(this);

    }

    onChangeTransactionID = (e) => {

        let { requestedSearchObj } = this.state;
        requestedSearchObj[e.target.name] = e.target.value;
        this.setState({ requestedSearchObj });
        this.clearEmptyErrorMsg(e.target.name);

    }

    searchHandleError = () => {

        let { errors, requestedSearchObj } = this.state;
        let formIsValid = true;

        if (requestedSearchObj.trnID === '') {
            formIsValid = false;
            errors["trnID"] = "Transaction ID can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    clearEmptyErrorMsg(name) {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onSubmitHandler = () => {

        if (this.searchHandleError()) {

            let { requestedSearchObj } = this.state;

            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.emAdminPointService.fetchOpsRefundDetailsListByTrnId(requestedSearchObj.trnID)
                .then((res) => {

                    if (res.status == 302) {
                        return res.json().then((body) => {
                            totalLengthOfDT = body.item.moneyReceiptSubList.length;
                            this.setState({ searchResDetails: body.item });
                            this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        });

                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                    }

                }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

                );
        }
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, requestedSearchObj, searchResDetails } = this.state;

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Invoice Details Info <span style={{ 'float': 'right' }}> Total Found: {totalLengthOfDT} </span></div>;
        let totalPaidAmount = 0;

        if (searchResDetails && searchResDetails.moneyReceiptSubList) {
            searchResDetails.moneyReceiptSubList.forEach(element => { totalPaidAmount += +element.paid });
        }

        let footerGroup = <ColumnGroup>
            <Row><Column colSpan={3} footer={'Total ' + this.ValidatorUtility.currencyFormatter(totalPaidAmount) + '/-'} /></Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section crm-dashboard">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                    {this.state.firstDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-small-span">Transaction ID <span>*</span></span>
                                            <InputText
                                                name='trnID'
                                                placeholder="Enter Transaction ID"
                                                value={requestedSearchObj.trnID}
                                                onChange={(e) => { this.onChangeTransactionID(e) }}
                                            />
                                        </div>
                                    }
                                    <span className='error-message'>{this.state.errors["trnID"]}</span>
                                </div>

                                <div className="p-col-12 p-lg-12 nw-button-parent">
                                    <div class="required-field">(<span>*</span>) required fields</div>
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid">
                            <div className="nw-data-table nw-data-table-tabview">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6 p-xl-4 m-b-20">
                                        <div className="at-glance-single-info-box payment-refundtable-review">
                                            <div className="protal-single-info-title">
                                                <h4>Refund Transaction Info</h4>
                                            </div>
                                            <div className="protal-single-info-content-box">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Date</td><td>{searchResDetails.paymentDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Paid Amount</td><td>{searchResDetails.totalPaid}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Student ID</td><td>{searchResDetails.customStudentId}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Student Name</td><td>{searchResDetails.studentName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gender</td><td>{searchResDetails.gender}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Religion</td><td>{searchResDetails.religion}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Father's Name</td><td>{searchResDetails.fatherName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mother's Name</td><td>{searchResDetails.motherName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Guardian Mobile No.</td><td>{searchResDetails.guardianMobile}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Section</td><td>{searchResDetails.section}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Roll No</td><td>{searchResDetails.roll}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Photo</td> {/* <td style={{ textDecoration: "underline", color: "rgb(0, 120, 255)" }}>Samsul_Islam,jpg</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>Invoice ID</td><td>{searchResDetails.invoiceId}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Invoice Amount</td><td>{searchResDetails.totalPayable}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6 p-xl-8 m-b-20">
                                        <DataTable header={tableHeader} value={searchResDetails.moneyReceiptSubList} footerColumnGroup={footerGroup}>
                                            <Column field="feeHeadName" header="Fee Head" />
                                            <Column field="details" header="Fee Sub Head" />
                                            <Column field="paid" className="text-right" header="Amount" />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}