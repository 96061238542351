import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import NetiContentLoader from '../common/NetiContentLoader';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import { InputText } from "primereact/components/inputtext/InputText";
import { DownloadButton } from "../../utils/DownloadButton";
import { NetiFileHandler } from './../../utils/NetiFileHandler';
import CommonFuctionality from '../common/CommonFuctionality';
import { ErrorMessageView } from '../common/ErrorMessageView';

export class ManageTokenPendingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingTokenList: [],
            pendingDownloadInfo: {
                creatorBasicInfoDTO: {
                    fullName: '',
                    basicMobile: ''
                },
            },
            pendingAssignInfo: {
                creatorBasicInfoDTO: {
                    fullName: '',
                    basicMobile: ''
                },
            },
            tokenRequirementObj: {
                tokenInfoID: '',
                requirementStatus: ''
            },
            tokenSolveObj: {
                tokenInfoID: '',
                tokenStatus: ''
            },
            tokenCancelObj: {
                tokenInfoID: '',
                tokenStatus: ''
            },
            tokenAssignDetailsObj: {
                tokenInfoID: '',
                tokenStatus: '',
                priorityStatus: '',
                solverBasicInfoDTO: {
                    netiID: ''
                }
            },
            solvedAssignInfo: {},
            dataTableIsLoading: false,
            layout: 'list',
            tokenID: '',
            checked: false,
            tokenType: '',
            hasSolved: false,
            applicantFormVisibility: true,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
        };


        this.supportPointService = new SupportPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.commonFuctionality = new CommonFuctionality();

    }

    async componentWillMount() {
        await this.viewAllPendingToken();
    }


    componentDidMount() {
        this.interval = setInterval(() => { 
            this.viewAllPendingToken();
        }, 30000);

    }

    componentWillUnmount() {
        clearInterval(this.interval);
      }

    viewAllPendingToken = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.setState({ pendingTokenList: [] });

        this.supportPointService.getAllPendingToken()
            .then(res => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ pendingTokenList: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection." })
            });
    }

    renderPendingList = (pendingData) => {
        return (
                <div className="pending-list">
                    <div className="p-grid p-col-12 p-col-nogutter">
                        {/* <div className="p-col-1 first">
                            
                        </div> */}

                        <div className="p-col-10 second">
                            <div className="nw-search-view">
                                <div>
                                    <div className="p-col-12 p-xl-12">
                                            <label>
                                                <h2>
                                                    { 
                                                        pendingData.tokenApplication == "Eduman" ? 
                                                            <span style={{color: "#0036B8"}}>{pendingData.tokenApplication}</span> 
                                                            : pendingData.tokenApplication == "NetiWorld" ? 
                                                            <span style={{color: "#0078FF"}}>{pendingData.tokenApplication}</span> 
                                                            : '---'
                                                    }
                                                </h2>
                                            </label>
                                        <br />
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Module</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                { pendingData.tokenModule || '---' }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Type</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                { pendingData.tokenType || '---' }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Token ID</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                { pendingData.customTokenID || '---' }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Issue Date</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                { NetiDateUtils.getAnyShortForm(pendingData.createDate, 'DD-MMM-YYYY') || '---' }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Contact</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                { pendingData.tokenContact || '---' }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Attachment</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                { pendingData.attachmentPath ? "YES" : "NO" }
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>






                            {/* <div className="p-lg-7"><h2>{pendingData.tokenApplication}{pendingData.requirementStatus == 0 ? '(Problem)' : '(Requirement)'}</h2></div>
                            <div className="p-lg-7"><h3>{'#' + pendingData.tokenType}</h3></div>
                            <div className="p-lg-9">Token ID:<span style={{ paddingLeft: "5px" }}>{pendingData.customTokenID}</span></div>
                            <div className="p-lg-9">Issue Date:<span style={{ paddingLeft: "5px" }}>{NetiDateUtils.getAnyShortForm(pendingData.createDate, 'DD-MMM-YYYY')}</span></div>
                            <div className="p-lg-9">Contact:<span style={{ paddingLeft: "5px" }}>{pendingData.tokenContact}</span></div> */}
                            {/* <div className="p-lg-8">
                                <span onClick={e => this.viewPendingDownloadDialog(e, pendingData.tokenInfoID)}><i className="fa fa-download" style={{ fontSize: "1rem", paddingRight: "5px" }}></i></span>
                                <span onClick={e => this.viewPendingAssignDialog(e, pendingData.tokenInfoID)}><i className="fa fa-user-plus" style={{ fontSize: "1rem", paddingRight: "5px" }}></i></span>
                                <span onClick={e => this.viewPendingRequirementDialog(e, pendingData)}><i className="fa fa-paper-plane" style={{ fontSize: "1rem", paddingRight: "5px" }}></i></span>
                                <span onClick={e => this.viewPendingSolveDialog(e, pendingData)}><i className="fa fa-check" style={{ fontSize: "1rem", paddingRight: "5px" }}></i></span>
                                <span onClick={e => this.viewPendingCancelDialog(e, pendingData)}><i className="fa fa-times" style={{ fontSize: "1rem" }}></i></span>
                            </div> */}
                        </div>

                        <div className="p-col-2 third p-col-nogutter">
                            <center>
                                <Button
                                    icon="fas fa-eye"
                                    onClick={ e => this.viewPendingDownloadDialog(e, pendingData.tokenInfoID) }
                                    className="p-button p-button-primary nw-action-button p-button-icon-only"
                                    tooltip="View"
                                />

                                <Button
                                    icon="fas fa-user-plus"
                                    onClick={ e => this.viewPendingAssignDialog(e, pendingData.tokenInfoID) }
                                    className="p-button p-button-primary nw-action-button p-button-icon-only"
                                    tooltip="Assign"
                                />
                                <Button
                                    icon="fas fa-paper-plane"
                                    onClick={ e => this.viewPendingRequirementDialog(e, pendingData) }
                                    className="p-button p-button-primary nw-action-button p-button-icon-only"
                                    tooltip="Requirement"
                                />

                                <Button
                                    icon="fas fa-check"
                                    onClick={ e => this.viewPendingSolveDialog(e, pendingData) }
                                    className="p-button p-button-primary nw-action-button p-button-icon-only"
                                    tooltip="Solved"
                                />
                                <Button
                                    icon="fas fa-times"
                                    onClick={ e => this.viewPendingCancelDialog(e, pendingData) }
                                    className="p-button p-button-primary nw-action-button p-button-icon-only"
                                    tooltip="Cancel"
                                />
                            </center>
                        </div>
                        
                    </div>
                </div>
        );
    }

    pendingDataTemplate = (pendingData) => {
        if (!pendingData) {
            return null;
        }
        else {
            return this.renderPendingList(pendingData);
        }
    }

    onHidePendingDownload = () => {
        this.setState({ pendingDownload: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }


    onHidePendingAssign = () => {
        this.setState({ pendingAssign: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    onHidePendingRequirement = () => {
        this.setState({ pendingRequirement: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    onHidePendingTokenSolve = () => {
        this.setState({ pendingTokenSolve: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    onHidePendingTokenCancel = () => {
        this.setState({ pendingTokenCancel: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }




    viewPendingDownloadDialog = (e, tokenInfoID) => {
        this.commonFuctionality.blurDialogBackgroundActive();

        this.setState({ pendingDownload: true });
        this.supportPointService.getTokensByTokenInfoID(tokenInfoID)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ pendingDownloadInfo: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection Problem');
            });
    }

    viewPendingAssignDialog = (e, tokenInfoID) => {
        this.commonFuctionality.blurDialogBackgroundActive();

        this.setState({ pendingAssign: true });
        this.supportPointService.getTokensByTokenInfoID(tokenInfoID)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ pendingAssignInfo: body });
                    });   
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection Problem');
            });

        this.getTokenSolverList();
    }


    viewPendingRequirementDialog = (e, pendingData) => {
        this.commonFuctionality.blurDialogBackgroundActive();

        let { tokenRequirementObj } = this.state;
        this.setState({ pendingRequirement: true });
        tokenRequirementObj.tokenInfoID = pendingData.tokenInfoID;
        tokenRequirementObj.requirementStatus = pendingData.requirementStatus;
        this.setState({ tokenRequirementObj });

    }

    viewPendingSolveDialog = (e, pendingData) => {
        this.commonFuctionality.blurDialogBackgroundActive();

        let { tokenSolveObj } = this.state;
        this.setState({ pendingTokenSolve: true });
        tokenSolveObj.tokenInfoID = pendingData.tokenInfoID;
        tokenSolveObj.tokenStatus = pendingData.tokenStatus;
        this.setState({ tokenSolveObj });
    }

    viewPendingCancelDialog = (e, pendingData) => {
        this.commonFuctionality.blurDialogBackgroundActive();

        let { tokenCancelObj } = this.state;
        this.setState({ pendingTokenCancel: true });
        tokenCancelObj.tokenInfoID = pendingData.tokenInfoID;
        tokenCancelObj.tokenStatus = pendingData.tokenStatus;
        this.setState({ tokenCancelObj });
    }

    getTokenSolverList = () => {
        this.supportPointService.fetchUserByrole()
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ tokenSolverList: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection." })
            });

    }

    tokenAssignHandler = () => {
        let { tokenAssignDetailsObj, pendingAssignInfo } = this.state;
        this.setState({ topProgressBar: true,dataTableIsLoading: true });
        tokenAssignDetailsObj.tokenInfoID = pendingAssignInfo.tokenInfoID;
        tokenAssignDetailsObj.tokenStatus = 1;
        this.supportPointService.editTokenInfo(tokenAssignDetailsObj)
            .then(res => {
                if (res.status == 202) {
                    this.onHidePendingAssign();
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Assigned!" });
                    this.viewAllPendingToken();
                    this.props.loadProcessingTokenDataTable();
                    this.setState({ topProgressBar: false, dataTableIsLoading: false })
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody,dataTableIsLoading: false })

                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection." })
            });
    }

    onSubmitRequirement = (e) => {
        let { tokenRequirementObj } = this.state;
        this.setState({ topProgressBar: true, dataTableIsLoading: true  });
        tokenRequirementObj.requirementStatus = 1;
        this.setState({ tokenRequirementObj });
        this.supportPointService.editTokenInfo(tokenRequirementObj)
            .then(res => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false  });
                if (res.status == 202) {
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Sent!" });
                    this.onHidePendingRequirement()

                    setTimeout(() => {
                        this.viewAllPendingToken();
                    }, 800);

                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })

                        });
                }
            }).catch(error => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection." });
            });


    }

    onSubmitSolve = (e) => {
        let { tokenSolveObj } = this.state;
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        tokenSolveObj.tokenStatus = 10;
        this.setState({ tokenSolveObj });
        this.supportPointService.editTokenInfo(tokenSolveObj)
            .then(res => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false });
                if (res.status == 202) {
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Sent!" });
                    
                    this.onHidePendingTokenSolve()
                    
                    setTimeout(() => {
                        this.viewAllPendingToken();
                    }, 800);
                    
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })

                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection.", dataTableIsLoading: false  });
            });


    }

    onSubmitCancel = (e) => {
        let { tokenCancelObj } = this.state;
        this.setState({ topProgressBar: true, dataTableIsLoading: true  });
        tokenCancelObj.tokenStatus = 2;
        this.setState({ tokenCancelObj });
        this.supportPointService.editTokenInfo(tokenCancelObj)
            .then(res => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false  });
                if (res.status == 202) {
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Sent!" });
                    
                    this.onHidePendingTokenCancel()

                    setTimeout(() => {
                        this.viewAllPendingToken();
                    }, 800);
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ dataTableIsLoading: false , topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection." });
            });

        // this.commonFuctionality.blurDialogBackgroundDeActive();
    }


    onRejectRequirement = () => {
        this.setState({ pendingRequirement: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    onRejectSolve = () => {
        this.setState({ pendingTokenSolve: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    onRejectCancel = () => {
        this.setState({ pendingTokenCancel: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    onChangeTokenPriority = (e) => {
        let { tokenAssignDetailsObj } = this.state;
        tokenAssignDetailsObj.priorityStatus = e.target.value;
        this.setState({ tokenAssignDetailsObj });
    }

    onChangeTokenSolver = (e) => {
        let { tokenAssignDetailsObj } = this.state;
        tokenAssignDetailsObj.solverBasicInfoDTO.netiID = e.target.value;
        this.setState({ tokenAssignDetailsObj });
    }

    hidePendingStageDownloadDialog = () => {
        this.onHidePendingDownload();

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    pendingStageDownload = () => {
        let { pendingDownloadInfo } = this.state;
        if (pendingDownloadInfo.attachmentPath !== null && pendingDownloadInfo.attachmentName !== null) {
            this.netiFileHandler.getByteImage(pendingDownloadInfo.attachmentPath).then(res => {
                if (res.status == 200) {
                    return res.json().then(body => {
                        let contentType = this.netiFileHandler.getImageContentType(
                            pendingDownloadInfo.attachmentName
                        );
                        let a = document.createElement("a");
                        a.href = contentType + body.fileContent;
                        a.download = pendingDownloadInfo.attachmentName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    });
                } else {
                }
            });
        }

    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        const priorityList = [
            { label: 'Normal', value: '0' },
            { label: 'Medium', value: '1' },
            { label: 'High', value: '2' },
            { label: 'Emergency', value: '3' },
        ]

        let solverNameList = [];
        if ((this.state.tokenSolverList != null) && (this.state.tokenSolverList.length > 0)) {
            solverNameList = this.state.tokenSolverList.map((item) => ({
                value: item.netiID,
                label: item.fullName,
            }));
        }

        let pendingHeader = `Pending (${this.state.pendingTokenList.length})`;

        // console.log("pendingTokenList", this.state.pendingTokenList);
        


        return (
            <div>
                <div className="blur-section">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} />
                    <div>
                    {
                        this.state.dataTableIsLoading ? this.NetiContentLoader.ThreeDotsLoader() :
                        <div className="token-info">
                            <DataScroller
                                value={this.state.pendingTokenList}
                                inline={true}
                                layout={this.state.layout}
                                header={pendingHeader}
                                itemTemplate={this.pendingDataTemplate}
                                rows={10}
                                // scrollHeight="100vh"
                            ></DataScroller>
                        </div>
                    } 
                    </div>
                </div>
                
                

                <div className="dialog-section">
                    <Dialog 
                        className="nw-dialog" 
                        maximizable 
                        header={this.state.pendingDownloadInfo.tokenType ? this.state.pendingDownloadInfo.tokenType : 'Pending Stage Download'} 
                        visible={this.state.pendingDownload} 
                        modal={true} 
                        onHide={this.onHidePendingDownload}
                    >
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token ID</span>
                                                        <div class="nw-inputgroup-desc">
                                                            {this.state.pendingDownloadInfo.customTokenID}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label"> Note</span>
                                                        {/* <div class="nw-inputgroup-desc">
                                                            {}
                                                        </div> */}
                                                        <div class="nw-inputgroup-desc">{this.state.pendingDownloadInfo.tokenDetails}</div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon"> Token Source</span>
                                                        <div class="nw-inputgroup-desc">
                                                            {this.state.pendingDownloadInfo.tokenSource}
                                                        </div>
                                                    </div>
                                                </div>

                                                { this.state.pendingDownloadInfo.edumanDetailsInfoDTO ?
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Institute</span>
                                                            <div class="nw-inputgroup-desc">
                                                                {this.state.pendingDownloadInfo.edumanDetailsInfoDTO.instituteId} - {this.state.pendingDownloadInfo.edumanDetailsInfoDTO.instituteName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :null
                                                }

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon"> Issue Date</span>
                                                        <div class="nw-inputgroup-desc">
                                                            {this.state.pendingDownloadInfo.createDate ? NetiDateUtils.getAnyShortForm(this.state.pendingDownloadInfo.createDate, 'DD-MMM-YYYY') : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon"> Issue By</span>
                                                        <div class="nw-inputgroup-desc">
                                                            {this.state.pendingDownloadInfo.creatorBasicInfoDTO.fullName + ' ' + '(' + this.state.pendingDownloadInfo.creatorBasicInfoDTO.basicMobile + ')'}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Download Attachment</span>
                                                        <div class="nw-inputgroup-desc nw-form-download-button">
                                                            <Button
                                                                label={ this.state.pendingDownloadInfo.attachmentName}
                                                                icon="fas fa-download"
                                                                onClick={this.pendingStageDownload}
                                                                className="nw-action-button info"
                                                                style={{ border: "1px solid #D2EAFF" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        onClick={this.hidePendingStageDownloadDialog}
                                                    />
                                                    {/* <Button
                                                        label="Download"
                                                        icon="fas fa-check"
                                                        onClick={this.pendingStageDownload}
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                    /> */}
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>


                    <Dialog 
                        maximizable 
                        header={this.state.pendingAssignInfo.tokenType ? this.state.pendingAssignInfo.tokenType : 'Pending Stage Assign'} 
                        visible={this.state.pendingAssign} 
                        className="nw-dialog" 
                        modal={true} 
                        onHide={this.onHidePendingAssign}
                    >

                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token ID</span>
                                                        <div class="nw-inputgroup-desc">
                                                            {this.state.pendingAssignInfo.customTokenID}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Priority</span>
                                                        <Dropdown
                                                            placeholder="Select priority"
                                                            value={this.state.tokenAssignDetailsObj.priorityStatus}
                                                            options={priorityList}
                                                            onChange={this.onChangeTokenPriority}
                                                            showClear={true}
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">Note</span>
                                                        <div class="nw-inputgroup-desc">
                                                            {this.state.pendingAssignInfo.tokenDetails}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token Source</span>
                                                        <div class="nw-inputgroup-desc">
                                                            {this.state.pendingAssignInfo.tokenSource}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Issue Date</span>
                                                        <div class="nw-inputgroup-desc">
                                                            {this.state.pendingAssignInfo.createDate ? NetiDateUtils.getAnyShortForm(this.state.pendingAssignInfo.createDate, 'DD-MMM-YYYY') : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Issue By</span>
                                                        <div class="nw-inputgroup-desc">
                                                            {this.state.pendingAssignInfo.creatorBasicInfoDTO.fullName + ' ' + '(' + this.state.pendingAssignInfo.creatorBasicInfoDTO.basicMobile + ')'}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Assign To Solver</span>
                                                        <Dropdown
                                                            placeholder="Select Solver Name"
                                                            value={this.state.tokenAssignDetailsObj.solverBasicInfoDTO.netiID}
                                                            options={solverNameList}
                                                            onChange={this.onChangeTokenSolver}
                                                            showClear={true}
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        onClick={this.onHidePendingAssign}
                                                    />

                                                    <Button
                                                        icon="fas fa-check"
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        label="Assign"
                                                        onClick={this.tokenAssignHandler}
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Dialog>

                    <Dialog header="Pending Stage Send as Requirement" className="nw-confirm-dialog" visible={this.state.pendingRequirement} onHide={this.onHidePendingRequirement} closable>
                        <div className="p-grid ">
                            <div className="p-col-12 p-xl-12">
                                <div className="confirm-wrapper">
                                    <h3>Do you want to send as a Requirement?</h3>
                                    <br/>
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            icon="fas fa-times"
                                            label="No"
                                            onClick={this.onRejectRequirement}
                                        />
                                        
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Yes"
                                            icon="fas fa-check"
                                            onClick={(e) => this.onSubmitRequirement(e)}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header="Pending Stage Solve" className="nw-confirm-dialog" visible={this.state.pendingTokenSolve} onHide={this.onHidePendingTokenSolve} closable>
                        <div className="p-grid ">
                            <div className="p-col-12 p-xl-12">
                                <div className="confirm-wrapper">
                                    <h3>Do you want to solve?</h3>
                                    <br/>
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            icon="fas fa-times"
                                            label="No"
                                            onClick={this.onRejectSolve}
                                        />
                                        
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Yes"
                                            icon="fas fa-check"
                                            onClick={(e) => this.onSubmitSolve(e)}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header="Pending Stage Cancel" className="nw-confirm-dialog" visible={this.state.pendingTokenCancel} onHide={this.onHidePendingTokenCancel} closable>
                        <div className="p-grid ">
                            <div className="p-col-12 p-xl-12">
                                <div className="confirm-wrapper">
                                    <h3>Do you want to remove?</h3>
                                    <br/>
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            icon="fas fa-times"
                                            label="No"
                                            onClick={this.onRejectCancel}
                                        />
                                        
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Yes"
                                            icon="fas fa-check"
                                            onClick={(e) => this.onSubmitCancel(e)}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                </div>
            </div>
        );
    }
}