import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import Formatter from '../../../../utils/Formatter';




let sumOfInstitute = 0;
let totalActiveInstitute = 0;
let totalInactiveInstitute = 0;
let totalActiveInstitutePercentage = 0;
let totalInactiveInstitutePercentage = 0;
let totalActiveRate = 0;
let totalInactiveRate = 0;
let totalActiveDue = 0;
let totalInactiveDue = 0;

export class MbpDueStatement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partnerAssignInfo: {},
            dataTableValue: [],
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            dataTableIsLoading: false,
            rowDataObj: this.props.location.searchObj
        }

        this.paidDetailsBody = this.paidDetailsBody.bind(this);
        this.ManagementPointService = new ManagementPointService;
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
        this.Formatter = new Formatter();

    }

    componentWillMount() {
        if (this.props.location.searchObj) {
            let partnerAssignID = this.props.location.searchObj.partnerAssignID
            localStorage.setItem("partnerAssignIDMBP", JSON.stringify(partnerAssignID));
        }

        this.fetchZbpInfo();
    }


    fetchZbpInfo = () => {
        const partnerAssignID = JSON.parse(localStorage.getItem("partnerAssignIDMBP"));
        if (!partnerAssignID) {
            return;
        }
        sumOfInstitute = 0;
        totalActiveInstitute = 0;
        totalInactiveInstitute = 0;
        totalActiveInstitutePercentage = 0;
        totalInactiveInstitutePercentage = 0;
        totalActiveRate = 0;
        totalInactiveRate = 0;
        totalActiveDue = 0;
        totalInactiveDue = 0;
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });

        this.ManagementPointService.fetchPartnerInfoList("T1120203", partnerAssignID)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        this.setState({
                            dataTableValue: body.instituteReportDTOs,
                            partnerAssignInfo: body.partnerAssignInfoDTO
                        });

                        if (!body.partnerAssignInfoDTO || !body.instituteReportDTOs.length) {
                            return this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' })
                        } else {
                            sumOfInstitute = body.instituteReportDTOs.map(item => item.totalInstitute).reduce((a, b) => (a + b));
                            totalActiveInstitute = body.instituteReportDTOs.map(item => item.activeInstitute).reduce((a, b) => (a + b));
                            totalInactiveInstitute = body.instituteReportDTOs.map(item => item.inActiveInstitute).reduce((a, b) => (a + b));
                            totalActiveInstitutePercentage = body.instituteReportDTOs.map(item => item.activeInstitutePercentage).reduce((a, b) => (a + b));
                            totalInactiveInstitutePercentage = body.instituteReportDTOs.map(item => item.inActiveInstitutePercentage).reduce((a, b) => (a + b));
                            totalActiveRate = body.instituteReportDTOs.map(item => item.activeRate).reduce((a, b) => (a + b));
                            totalInactiveRate = body.instituteReportDTOs.map(item => item.inActiveRate).reduce((a, b) => (a + b));
                            totalActiveDue = body.instituteReportDTOs.map(item => item.activePayble).reduce((a, b) => (a + b));
                            totalInactiveDue = body.instituteReportDTOs.map(item => item.inActivePayble).reduce((a, b) => (a + b));

                            this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        }

                    });
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection!!!' })

            );
    }


    paidDetailsBody(rowData, column) {

        return <div className='text-center'>
            <Link to={{
                pathname: MANAGEMENT_POINT.ZBP_DUE_STATEMENT,
                searchObj: { ...rowData }
            }}>
                <i class="fas fa-arrow-circle-right fa-2x"></i>
                <u>{rowData.dataTableValue}</u>
            </Link>
        </div>
    }


    zbpActiveInstituteBody(rowData) {
        return <span >
            <span className="text-success">{rowData.activeInstitute} {"[" + this.Formatter.getFixedDecimalNumber(rowData.activeInstitutePercentage) + "%" + "]"} {"[" + this.validatorUtility.currencyFormatter(rowData["activeRate"]) + "]"}</span><br />

            <span className="text-danger">{rowData.inActiveInstitute} {"[" + this.Formatter.getFixedDecimalNumber(rowData.inActiveInstitutePercentage) + "%" + "]"} {"[" + this.validatorUtility.currencyFormatter(rowData["inActiveRate"]) + "]"}</span>
        </span>;
    }

    zbpDueActiveInstituteBody(rowData) {

        return <div className="text-success col-number text-right" >

            <span className="text-success">{this.validatorUtility.currencyFormatter(rowData["activePayble"])}</span>
            <br />
            <span className="text-danger">{this.validatorUtility.currencyFormatter(rowData["inActivePayble"])}</span>

        </div>;
    }


    partnerDetailInfo(rowData) {
        return <span>
            <span style={{ color: "blue" }}>{rowData.partnerMobile}</span> <br />
            <span style={{ color: "#bb14bbc9" }}>{rowData.areaName}</span>
        </span>
    }

    totalActiveInstitutePercentageInfo() {
        console.log("Total", totalActiveInstitutePercentage);

        let totalActiveInstitutePercentage = (totalActiveInstitute / sumOfInstitute) * 100
        return isNaN(totalActiveInstitutePercentage) || !totalActiveInstitutePercentage ? 0 : totalActiveInstitutePercentage;

    }

    totalInactiveInstitutePercentageInfo() {
        console.log("Total", totalInactiveInstitutePercentage);

        let totalInactiveInstitutePercentage = (totalInactiveInstitute / sumOfInstitute) * 100
        return isNaN(totalInactiveInstitutePercentage) || !totalInactiveInstitutePercentage ? 0 : totalInactiveInstitutePercentage;

    }

    sumOfActiveInactive() {
        return <span>

            <span className="text-success">{totalActiveInstitute + "[" + this.Formatter.getFixedDecimalNumber(this.totalActiveInstitutePercentageInfo()) + "%" + "]" + "[" + this.validatorUtility.currencyFormatter(totalActiveRate) + "]"}</span>
            <br />
            <span className="text-danger">{totalInactiveInstitute + "[" + this.Formatter.getFixedDecimalNumber(this.totalInactiveInstitutePercentageInfo()) + "%" + "]" + "[" + this.validatorUtility.currencyFormatter(totalInactiveRate) + "]"}</span>

        </span>
    }


    sumOfActiveInactiveDue() {
        return <span>

            <span className="text-success">{this.validatorUtility.currencyFormatter(totalActiveDue)}</span>
            <br />
            <span className="text-danger">{this.validatorUtility.currencyFormatter(totalInactiveDue)}</span>

        </span>

    }


    render() {

        let { partnerAssignInfo, topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody } = this.state;
        let totalFound = dataTableValue ? dataTableValue.length : 0;
        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Total ZBP Found: {totalFound}<span style={{ 'float': 'right' }}> </span></div>;

        let { rowDataObj } = this.state

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
                <Column footer={sumOfInstitute} footerStyle={{ textAlign: 'left' }}  />
                <Column footer={this.sumOfActiveInactive()} footerStyle={{ textAlign: 'left' }}  />
                <Column footer={this.sumOfActiveInactiveDue()} />
                <Column footer="" colSpan={1} />

            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                <div>
                                    <div>Neti ID</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo && partnerAssignInfo.userBasicInfoDTO ? partnerAssignInfo.userBasicInfoDTO.customNetiID : ''}</div>
                                </div>

                                <div>
                                    <div>Name</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo && partnerAssignInfo.userBasicInfoDTO ? partnerAssignInfo.userBasicInfoDTO.fullName : ''}</div>
                                </div>

                                <div>
                                    <div>Mobile No.</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo && partnerAssignInfo.userBasicInfoDTO ? partnerAssignInfo.userBasicInfoDTO.basicMobile : ''}</div>
                                </div>

                                <div>
                                    <div>Area</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo && partnerAssignInfo.partnerAreaInfoDTO ? partnerAssignInfo.partnerAreaInfoDTO.categoryName : ''}</div>
                                </div>

                            </div>


                            <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                <div>
                                    <div>Type</div>
                                    <div>:</div>
                                    <div> {partnerAssignInfo && partnerAssignInfo.partnerDesignationInfoDTO ? partnerAssignInfo.partnerDesignationInfoDTO.categoryName : ''}</div>
                                </div>

                                <div>
                                    <div>Team Member</div>
                                    <div>:</div>
                                    <div>{dataTableValue ? dataTableValue.length : ''}</div>
                                </div>

                                <div>
                                    <div>Total Institute</div>
                                    <div>:</div>
                                    <div> {sumOfInstitute}</div>
                                </div>


                            </div>


                            <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                <div>
                                    <div>Active Institute</div>
                                    <div>:</div>
                                    <div>{totalActiveInstitute}</div>
                                </div>

                                <div>
                                    <div>Active Bill</div>
                                    <div>:</div>

                                    <div>{this.validatorUtility.currencyFormatter(totalActiveDue)}</div>
                                </div>

                                <div>
                                    <div>Inactive Institute</div>
                                    <div>:</div>
                                    <div>{totalInactiveInstitute}</div>
                                </div>

                                <div>
                                    <div>Inactive Bill</div>
                                    <div>:</div>
                                    <div>{this.validatorUtility.currencyFormatter(totalInactiveDue)}</div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="nw-data-table">
                        {
                            this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader()
                                :
                                <DataTable
                                    header={header}
                                    footerColumnGroup={footer}
                                    responsive={true}
                                    selectionMode="single"
                                    value={this.state.dataTableValue}
                                >
                                    <Column field="partnerNetiID" header="Neti ID" filter={true} />
                                    <Column field="partnerName" header="Name" filter={true} />
                                    <Column field="" header="Mobile No. / Area" body={this.partnerDetailInfo.bind(this)} filter={true} />
                                    <Column field="totalInstitute" header="Total Institute" />
                                    <Column field="" header="Active / Inactive" body={this.zbpActiveInstituteBody.bind(this)} />
                                    <Column field="" header="Active Due / Inactive Due" body={this.zbpDueActiveInstituteBody.bind(this)} />
                                    <Column field="details" header="Details" body={this.paidDetailsBody} style={{ width: "100px" }} />

                                </DataTable>
                        }


                    </div>

                </div>
                
            </div>
        )
    }
}