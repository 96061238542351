import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';

export class PartnerBasicInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {

            activeIndex: 0,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,

            regionDatatableValue: [],
            zoneDatatableValue: [],
            districtDatatableValue: [],
            areaDatatableValue: [],

        }
        this.ManagementPointService = new ManagementPointService();
        this.netiContentLoader = new NetiContentLoader();

        this.fetchRegionWisePartnerInfo = this.fetchRegionWisePartnerInfo.bind(this);
    }

    componentWillMount() { this.fetchRegionWisePartnerInfo('MBP') }

    fetchRegionWisePartnerInfo(designation) {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.ManagementPointService.fetchRegionWise_PartnerInfo(designation)
            .then((res) => {

                if (res.status == 302) {

                    return res.json().then((body) => {

                        if (designation === 'MBP') { this.setState({ regionDatatableValue: body }); }
                        if (designation === 'ZBP') { this.setState({ zoneDatatableValue: body }); }
                        if (designation === 'BDP') { this.setState({ districtDatatableValue: body }); }
                        if (designation === 'BEP') { this.setState({ areaDatatableValue: body }); }

                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }

            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    onChangeTab = (e) => {

        if (e.index === 1) {
            this.fetchRegionWisePartnerInfo('ZBP');
        }
        if (e.index === 2) {
            this.fetchRegionWisePartnerInfo('BDP');
        }
        if (e.index === 3) {
            this.fetchRegionWisePartnerInfo('BEP');
        }

        this.setState({ activeIndex: e.index });

    }

    onTabClosed = (e) => {

        if (e.index === 1) {
            this.setState({ zoneDatatableValue: [] });
        }
        if (e.index === 2) {
            this.setState({ districtDatatableValue: [] });
        }
        if (e.index === 3) {
            this.setState({ areaDatatableValue: [] });
        }

    }

    statusBody = (rowData) => {

        if (rowData.status === "Active") {
            return <span style={{ color: "green" }} >{rowData.status}</span>;
        }
        if (rowData.status === "Inactive") {
            return <span style={{ color: "red" }}> {rowData.status} </span>;
        }

    }

    dateTimeBody = (rowData) => {

        return <span> {NetiDateUtils.getAnyShortForm(rowData.assignDate, 'DD-MMM-YYYY hh:mm:ss a')} </span>

    }

    render() {

        let { regionDatatableValue, zoneDatatableValue, districtDatatableValue, areaDatatableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />

                <div className="accrodion-section">

                    <Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.onChangeTab(e)} onTabClose={(e) => this.onTabClosed(e)}>
                        <AccordionTab header="Region Wise Partner Info">
                            <div className="main-section">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-data-table">
                                            {this.state.dataTableIsLoading ?
                                                this.netiContentLoader.MyPointTableLoader() :
                                                <DataTable
                                                    value={regionDatatableValue}
                                                    // header="Download File Info List"
                                                    responsive={true}
                                                    selectionMode="single"
                                                    paginator={true}
                                                    rows={10}
                                                    rowsPerPageOptions={[5, 10, 20]}
                                                >
                                                    <Column field="netiID" header="Partner Neti ID" filter={true} />
                                                    <Column field="name" header="Partner Name" filter={true} />
                                                    <Column field="mobile" header="Mobile No" filter={true} />
                                                    <Column field="region" header="Region" filter={true} />
                                                    <Column field="status" header="Status" filter={true} body={this.statusBody} />
                                                    <Column field="assignDate" header="Assign Date" filter={true} style={{ width: '110px' }} body={this.dateTimeBody} />
                                                </DataTable>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>

                        <AccordionTab header="Zone Wise Partner Info">
                            <div className="main-section">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-data-table">
                                            {this.state.dataTableIsLoading ?
                                                this.netiContentLoader.MyPointTableLoader() :
                                                <DataTable
                                                    value={zoneDatatableValue}
                                                    // header="Download File Info List"
                                                    responsive={true}
                                                    selectionMode="single"
                                                    paginator={true}
                                                    rows={10}
                                                    rowsPerPageOptions={[5, 10, 20]}
                                                >
                                                    <Column field="netiID" header="Partner Neti ID" filter={true} />
                                                    <Column field="name" header="Partner Name" filter={true} />
                                                    <Column field="mobile" header="Mobile No" filter={true} />
                                                    <Column field="region" header="Region" filter={true} />
                                                    <Column field="zone" header="Zone" filter={true} />
                                                    <Column field="status" header="Status" filter={true} body={this.statusBody} />
                                                    <Column field="assignDate" header="Assign Date" filter={true} body={this.dateTimeBody} style={{ width: '110px' }} />
                                                </DataTable>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>

                        <AccordionTab header="District Wise Partner Info">
                            <div className="main-section">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-data-table">
                                            {this.state.dataTableIsLoading ?
                                                this.netiContentLoader.MyPointTableLoader() :
                                                <DataTable
                                                    value={districtDatatableValue}
                                                    // header="Download File Info List"
                                                    responsive={true}
                                                    selectionMode="single"
                                                    paginator={true}
                                                    rows={10}
                                                    rowsPerPageOptions={[5, 10, 20]}
                                                >
                                                    <Column field="netiID" header="Partner Neti ID" filter={true} />
                                                    <Column field="name" header="Partner Name" filter={true} />
                                                    <Column field="mobile" header="Mobile No" filter={true} />
                                                    <Column field="region" header="Region" filter={true} />
                                                    <Column field="zone" header="Zone" filter={true} />
                                                    <Column field="district" header="District" filter={true} />
                                                    <Column field="status" header="Status" filter={true} body={this.statusBody} />
                                                    <Column field="assignDate" header="Assign Date" filter={true} body={this.dateTimeBody} style={{ width: '110px' }} />
                                                </DataTable>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>

                        <AccordionTab header="Area Wise Partner Info">
                            <div className="main-section">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-data-table">
                                            {this.state.dataTableIsLoading ?
                                                this.netiContentLoader.MyPointTableLoader() :
                                                <DataTable
                                                    value={areaDatatableValue}
                                                    // header="Download File Info List"
                                                    responsive={true}
                                                    selectionMode="single"
                                                    paginator={true}
                                                    rows={10}
                                                    rowsPerPageOptions={[5, 10, 20]}
                                                >
                                                    <Column field="netiID" header="Partner Neti ID" filter={true} />
                                                    <Column field="name" header="Partner Name" filter={true} />
                                                    <Column field="mobile" header="Mobile No" filter={true} />
                                                    <Column field="region" header="Region" filter={true} />
                                                    <Column field="zone" header="Zone" filter={true} />
                                                    <Column field="district" header="District" filter={true} />
                                                    <Column field="area" header="Area" filter={true} />
                                                    <Column field="status" header="Status" filter={true} body={this.statusBody} />
                                                    <Column field="assignDate & Time " header="Assign Date" filter={true} body={this.dateTimeBody} style={{ width: '110px' }} />
                                                </DataTable>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>

                    </Accordion>
                </div>
            </div>
        );
    }
}