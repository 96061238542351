import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { CoreSettings } from "../../../service/CoreSettings";
import { Panel } from 'primereact/panel';
import { StudentClassConfigInfo } from '../common/StudentClassConfigInfo';
import NetiContentLoader from "../../common/NetiContentLoader";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from "primereact/growl";
import { EmUserPointService } from '../../../service/emUserPoint/EmUserPointService';

let errors = {};
export class StudentPortalExamRoutine extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sessionList: [],
            sessionId: '',
            examList: '',
            examId: '',
            examRoutine: '',
            dataTableValue: '',
            errors: {},

            errorMsgBody: '',
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            userList: [],
            userID: '',
            userLength: '',
            insID: '',
            basicInfo: [],
            stId: '',
        }

        this.onChangeSession = this.onChangeSession.bind(this);
        this.onChangeExam = this.onChangeExam.bind(this);

        this.studentPortal = new StudentPortal();
        this.coreSettings = new CoreSettings();
        this.NetiContentLoader = new NetiContentLoader();
        this.emUserPointService = new EmUserPointService();
    }

    componentWillMount() { 
        this.loadStudentInfo();
    }

    handledropdwon = (id, insId) => {

        this.setState({userID: id})
        this.setState({insID:insId})
    
        this.loadStudentInfo(); 
    }
    
    loadStudentInfo = () => {
    
        setTimeout(() => {
            [...document.querySelectorAll('.protal-single-info-content')].map(item => {
                if (!item.querySelector('p').innerHTML.trim()) {
                    item.querySelector('p').innerHTML = '---'
                }
            })
        }, 1500);
    
        let userListtt = [];
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.emUserPointService.fetchMappedPortalList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log('Log: GoToPortal -> componentWillMount -> body......', body[0].idForStudent)
                        console.log('Log: GoToPortal -> componentWillMount -> body......', body)
                        
    
                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({userList: userListtt})
                        this.setState({userLength:body.length})
                        this.setState({stId:body[0].idForStudent})
    
                        console.log("user id inside : ", this.state.userID)
                        
                        this.studentPortal
                        .fetchStudentProfileDetailsList(
                            this.state.insID || body[0]["emDetailsInfoDTO"].instituteId ,
                            this.state.userID || body[0].idForStudent
                            // body[this.state.userID]["emDetailsInfoDTO"].instituteId,
                            // body[this.state.userID].idForStudent,
                        )
                        .then(res => {
                            if (res.status == 200) {
                              
                                return res.json().then(body => {
                                    this.setState({ topProgressBar: false, errorMsgVisible: false })
                                    this.setState({ basicInfo: body });
                                    this.fetchSessionList();
                                    this.fetchStudentExamList();
                        
                                });
                            } else {
                                this.studentPortal.Auth.handleErrorStatus(res)
                                    .then((responseBody) => {
                                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                    });
                            }
                        })
                        .catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));
    
                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                    })
                } else {
                    this.emUserPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch((error) => {
                console.log(error)
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    
    
        this.setState({ topProgressBar: true, errorMsgVisible: false })
    };

    fetchSessionList() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        let requestobject = this.state.basicInfo;
        this.studentPortal.fetchExamSessionList(requestobject).then(res => {
            if (res.status == 200) {
                return res.json().then((body) => {
                    this.setState({ sessionList: body, topProgressBar: false, firstDropdownIsLoading: false })
                });
            } else {
                this.studentPortal.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody.message })
                    });

            }
        }).catch(error => {
            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        });
    }

    fetchStudentExamList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false });
        let requestobject = this.state.basicInfo;
        this.studentPortal.fetchStudentExam(requestobject).then(res => {
            if (res.status == 200) {
                return res.json().then((body) => {
                    this.setState({
                        examList: body.item, topProgressBar: false, secondDropdownIsLoading: false
                    });
                });
            } else {
                this.studentPortal.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody.message });
                    });
            }
        }).catch(error => this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
        );
    }

    fetchExamRoutine(requestedObjct) {
        this.studentPortal.fetchStudentExamRoutine(requestedObjct).then(res => {
            if (res.status === 200) {
                return res.json().then((body) => {

                    if (body.msgType === 1) {
                        this.setState({ examRoutine: body.item })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    } else if (body.msgType === 0) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: body.message });
                    }
                });

            } else {
                return res.json().then((errorBody) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: errorBody.message })
                });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        })

    }

    onChangeSession = (e) => {
        errors["session"] = "";
        this.setState({ sessionId: e.value, errors: errors })
    }
    onChangeExam(e) {
        errors["exam"] = "";
        this.setState({ examId: e.value, errors: errors })
    }

    submitSearch() {

        if (this.handleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            let basicInfo = this.state.basicInfo;
            let requestedObjct = {
                'instituteId': basicInfo.instituteId,
                'studentId': basicInfo.studentId,
                'academicYear': basicInfo.academicYear,
                'sessionId': this.state.sessionId,
                'examId': this.state.examId,
            }

            this.fetchExamRoutine(requestedObjct);
        }
    }

    handleError = () => {

        errors = {};
        let formIsValid = true;

        if (this.state.sessionId === '') {
            formIsValid = false;
            errors["session"] = "Session can't left empty.";
        }
        if (this.state.examId === '') {
            formIsValid = false;
            errors["exam"] = "Exam can't left empty.";
        }
        else { return formIsValid };
        this.setState({ errors: errors });
    }

    render() {

        var rows = [];
        let l = this.state.userLength;
        this.state.userList.map((item) =>{
            if(l > rows.length){
                rows.push(<a  onClick={() => this.handledropdwon(item.idForStudent, item["emDetailsInfoDTO"].instituteId)} >    {item.idForStudent}  </a>)
            }
        })

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let sessionOptions = [];
        if (this.state.sessionList && this.state.sessionList.length) {
            sessionOptions = this.state.sessionList.map(item => ({
                value: item.sessionId,
                label: item.sessionName,
            }));
        }

        let examOptions = [];
        if (this.state.examList && this.state.examList.length) {
            examOptions = this.state.examList.map(item => ({
                value: item.examObject.id,
                label: item.examObject.name,
            }));
        }

        return (

            <div className="p-col-12 p-col-nogutter">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                {/* <Panel> */}
                <div className="p-grid nw-form">

                    <div className="p-col-12" style={{position: "relative"}}>
                        <StudentClassConfigInfo basicInfo={this.state.basicInfo} />
                        <div className='dropdown'>
                        <button className="dropbtn">
                        {/* <i className="fa fa-caret-down"></i> */}
                        <span class="fas fa-align-right p-c p-button-icon-left"></span>
                        </button>
                        <div className="dropdown-content">  
                             {rows}
                        </div>
                    </div>
                    </div>

                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <p className='profile-custom-table-header'>Exam Routine</p>
                        <br />
                    </div>

                    <div className="p-col-12">
                        <div className="nw-form-body">
                            <div className="p-col-12">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Session <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Session'
                                            name="session"
                                            options={sessionOptions}
                                            value={this.state.sessionId}
                                            onChange={this.onChangeSession}
                                            filter={true}
                                            filterBy="label,value"
                                            autoWidth={false}
                                        />

                                    </div>
                                }
                                <span className='error-message'>{this.state.errors["session"]}</span>
                            </div>

                            <div className="p-col-12">
                                {this.state.secondDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Exam <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Exam'
                                            name="exam"
                                            options={examOptions}
                                            value={this.state.examId}
                                            onChange={this.onChangeExam}
                                            filter={true}
                                            filterBy="label,value"
                                            autoWidth={false}
                                        />

                                    </div>
                                }
                                <span className='error-message'>{this.state.errors["exam"]}</span>
                            </div>

                            <div className="p-col-12 nw-button-parent">
                                <label><span>&nbsp;</span></label>
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={event => this.submitSearch(event)}
                                />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="p-col-12 p-lg-12 p-xl-12"><br /><br />
                    {this.state.dataTableIsLoading ?
                        this.NetiContentLoader.MyPointTableLoader() :
                        this.state.examRoutine ?
                            <DataTable
                                value={this.state.examRoutine}
                                responsive={true}
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="examDate" header="Date" sortable={true} filter={true} />
                                <Column field="examDay" header="Day" sortable={true} filter={true} />
                                <Column field="examTime" header="Time" sortable={true} filter={true} />
                                <Column field="roomNo" header="Roon No." sortable={true} filter={true} />
                                <Column field="subjectName" header="Subject Name" sortable={true} filter={true} />

                            </DataTable>

                            : ''}
                </div>
                {/* </Panel> */}
            </div >
        )
    }
}