import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import CommonFuctionality from '../../common/CommonFuctionality';
import { ProfileService } from '../../../service/profile/ProfileService';
import { RadioButton } from 'primereact/radiobutton';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import NetiContentLoader from '../../common/NetiContentLoader';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';

export class PersonalProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commonProfileInfo: {
                globalAreaInfoDTO: {
                    coreCategoryID: ''
                },
                bloodGroupInfoDTO:{
                    coreCategoryID: ''
                }
            },
            updateUserObj: {
                userDetailsInfoResponseDTO: {},
                globalAreaInfoDTO: {
                    coreCategoryID: ''
                },
                bloodGroupInfoDTO:{
                    coreCategoryID: ''
                }
            },
            visibleMapDialog: false,
            visibleChangePswDialog: false,
            visiblePersonalInfoDialog: false,
            visibleAddressInfoDialog: false,
            bloodGroupList: [],
            divisionDropDownList:[],
            districtDropDownList: [],
            upazillaDropDownList: [],
            firstDropDownIsLoading: false,
            errors: {}
        }
        this.CommonFuctionality = new CommonFuctionality();
        this.ProfileService = new ProfileService();
        this.NetiContentLoader = new NetiContentLoader();
        this.userCategoryService = new UserCategoryService();

    }

    componentDidMount(){
        this.getCommonProfileInfo()
        this.getBloodGroup();

        this.getByDefCode('T10202');
    }

    getByDefCode(defaultCode) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if (defaultCode === 'T10202') {
                            this.setState({ divisionDropDownList: body });
                        }
                        else if (defaultCode === 'T106') {
                            this.setState({ boardDropDownList: body });
                        }
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
                    });

                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }

    getBloodGroup() {
        this.setState({ firstDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode('T139')
            .then(res => {
                if (res.status === 302) {
                    this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    return res.json().then((body) => { this.setState({ bloodGroupList: body }) })
                } else {
                    return res.json().then((errorBody) => {
                        this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorBody.message });
                    })
                };
            }).catch(error => {
                this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
            });
    }

    getCommonProfileInfo = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ProfileService.fetchCommonProfileInfo()
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        
                        this.setState({ commonProfileInfo: body, topProgressBar: false, errorMsgVisible: false });
                        // this.getImg();
                        // this.setState({ visibleAddressInfoDialog: true });
                        // Object.assign(this.state.commonProfileInfo, body)
                    })
                }
                else {
                    this.ProfileService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    getImg() {
        let { commonProfileInfo } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        let photoMainPath;

        if (this.state.photoName) {
            photoMainPath = "/images/nw/dws/gallery/" + this.state.photoName
        }
        else {
            photoMainPath = commonProfileInfo.imagePath
        }

        if (photoMainPath !== null) {
            this.netiFileHandler.getByteImage(photoMainPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(commonProfileInfo.imageName);
                                
                                commonProfileInfo.imageContent = body.fileContent;
                                this.setState({ updateImg: body.fileContent, commonProfileInfo });
                            })
                    } else {
                        this.setState({ updateImg: staticImg });
                    }
                });
        } else {
            this.setState({ updateImg: staticImg });
        }

    }

    // componentWillReceiveProps(nextProps) {
    //     let { commonProfileInfo } = this.state
    //     // if (nextProps.commonProfileInfo !== this.props.commonProfileInfo) {
    //     //     console.log('nextProps', nextProps);
    //         // this.setState({ commonProfileInfo: nextProps.commonProfileInfo })
    //     // }

    //     if(commonProfileInfo.length == 0){
    //         commonProfileInfo.push(nextProps.commonProfileInfo)
    //     }
        
    // }

    onHideMapDialog = () => {
        this.setState({ visibleMapDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showMapDialog = () => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visibleMapDialog: true });
    }

    onChangePSWDialogHide = () => {
        this.setState({ visibleChangePswDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showChangePSWDialog = () => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visibleChangePswDialog: true });
    }

    showPersonalInfoDialog = (userData) => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visiblePersonalInfoDialog: true, updateUserObj: {...userData} });
    }

    onHidePersonalInfoDialog = () => {
        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visiblePersonalInfoDialog: false });
        this.getCommonProfileInfo();
    }

    showAddressInfoDialog = (userData) => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visibleAddressInfoDialog: true, updateUserObj: {...userData} });
    }

    onHideAddressInfoDialog = () => {
        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visibleAddressInfoDialog: false });
        this.getCommonProfileInfo();
    }

    actionTemplate = () => {
        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-download"
                title='Click to view deposit details'
            />
        </div>
    }

    onChageFatherName = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.fatherName = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageMotherName = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.motherName = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageBasicMobile = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.basicMobile = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageDateOfBirth = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.dateOfBirth = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    genderHandle = (event, props) => {
        let { updateUserObj } = this.state;
        updateUserObj.gender = event.target.value;
        this.setState({ updateUserObj });
    }

    onChangeBloogGroup = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.bloodGroupInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageNumberOfChild = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.numberOfChild = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageNationality = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.nationality = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageNationalId = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.nationalID = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChagePassportNo = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.passportNo = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }
    
    onChageBirthCertificateNo = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.birthCertificateNo = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageHobby = (e, action) =>{
        let { updateUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.hobby = e.target.value
        }
        
        this.setState({ updateUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChangeMaritalStatus = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.maritalStatus = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }






    onChageAddress = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.addressDetails = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChangeDivision = (e) => {
        this.setState({ districtDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.setState({ division: e.target.value })
        this.userCategoryService.fetchListByParentCategoryID(e.target.value)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ districtDropDownList: body });
                        this.setState({ districtDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false })

                    });
                } else {
                    return res.json().then(errorMsg => {
                        this.setState({ districtDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Opps! Something went wrong' })
                    });
                }
            }).catch(error => {
                this.setState({ districtDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })

            });
        this.onClearErrorMsg(e.target.name);
    }

    onChangeDistrict = (e) => {

        this.setState({ districtId: e.target.value })
        this.setState({ upazillaDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false })

        if (e.target.value) {
            this.userCategoryService.fetchListByParentCategoryID(e.target.value)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ upazillaDropDownList: body });
                            this.setState({ upazillaDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                        });
                    } else {
                        this.userCategoryService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ upazillaDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ upazillaDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })
                });
        }
        this.onClearErrorMsg(e.target.name);
    }

    onChangeUpazilla = (e, action) => {
        let { updateUserObj, insertUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.globalAreaInfoDTO.coreCategoryID = e.target.value;
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);

    }

    onChageLongitude = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.longitude = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageLatitude = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;
        if(action == 'update'){
            updateUserObj.userDetailsInfoResponseDTO.latitude = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }







    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    onUpdateProfileInfo = (section) => {
        let { updateUserObj } = this.state;
        if (true) {
            this.setState({ topProgressBar: true, errorMsgVisible: false })

            // if(section == 'profile'){
            //     delete updateUserObj.globalAreaInfoDTO;
            // }
            
            this.ProfileService.updateUserbasicInfo(updateUserObj)
                .then(res => {
                    if (res.status === 200) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfuly submitted.' });
                        this.setState({ topProgressBar: false, errorMsgVisible: false })
                        // setTimeout(() => {
                        //     this.getCommonProfileInfo();
                        // }, 800);
                        

                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: resp })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: 'Please check your connection.' })
                })
        }

    }




    render() {
        let { commonProfileInfo, updateUserObj, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state
        
        let dataTableValue = [
            { "title": "National Identification", "value": "5545332321009" },
            { "title": "Passport Number", "value": "B-254567212" },
            { "title": "Birth Certification ", "value": "44434345665654" },
        ]

        let maritalStatusOptions = [
            {label: 'Married', value: 'Married'},
            {label: 'Unmarried', value: 'Unmarried'},
        ]

        let bloodGroupOptions = []
        //     {label: 'A+', value: 'A+'},
        //     {label: 'A-', value: 'A-'},
        //     {label: 'B+', value: 'B+'},
        //     {label: 'B-', value: 'B-'},
        // ]
        if (this.state.bloodGroupList && this.state.bloodGroupList.length) {
            bloodGroupOptions = this.state.bloodGroupList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let divisionList = [];
        if ((this.state.divisionDropDownList != null) && (this.state.divisionDropDownList.length > 0)) {
            divisionList = this.state.divisionDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let districtList = []
        if (this.state.districtDropDownList && this.state.districtDropDownList.length) {
            districtList = this.state.districtDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let upazillaList = []
        if (this.state.upazillaDropDownList && this.state.upazillaDropDownList.length) {
            upazillaList = this.state.upazillaDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }


        return (
            <div>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                
                <div className="main-section blur-section">
                    {/* {
                        commonProfileInfo.map((commonProfileInfo, index) => */}
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <div className="personal-box-wrapper m-t-30">
                                        <div className="personal-box-title-wrapper">
                                            <div className="personal-box-title">
                                                <h4>Personal Info</h4>
                                            </div>
                                            <div className="edit-button">
                                                <Button
                                                    icon="fas fa-edit"
                                                    onClick={e => this.showPersonalInfoDialog(commonProfileInfo)}
                                                />
                                            </div>
                                        </div>
                                        <div className="personal-box p-b-75">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Father's Name</td>
                                                        <td>{commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.fatherName || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mother's Name</td>
                                                        <td>{commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.motherName || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mobile No.</td>
                                                        <td>{commonProfileInfo.basicMobile || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Age</td>
                                                        <td>{commonProfileInfo.age || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gender</td>
                                                        <td>{commonProfileInfo.gender || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Blood Group</td>
                                                        <td>{commonProfileInfo.bloodGroupInfoDTO.categoryName || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Marital Status</td>
                                                        <td>{commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.maritalStatus || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Number of Child</td>
                                                        <td>{commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.numberOfChild || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nationality</td>
                                                        <td>{commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.nationality || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>National ID</td>
                                                        <td>{commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.nationalID || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Passport No.</td>
                                                        <td>{commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.passportNo || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Birth Certification</td>
                                                        <td>{commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.birthCertificateNo || "---"}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td>Hobby</td>
                                                        <td>{commonProfileInfo.hobby || "---"}</td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="personal-box-wrapper m-t-30">
                                        <div className="personal-box-title-wrapper">
                                            <div className="personal-box-title">
                                                <h4>Address Info</h4>
                                            </div>
                                            <div className="edit-button">
                                                <Button 
                                                    icon="fas fa-edit" 
                                                    onClick={e => this.showAddressInfoDialog(commonProfileInfo)}
                                                />
                                            </div>
                                        </div>
                                        <div className="personal-box">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Address Details</td>
                                                        <td>{commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.addressDetails || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Upozilla</td>
                                                        <td>{commonProfileInfo.upazilla || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>District</td>
                                                        <td>{commonProfileInfo.district || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Division</td>
                                                        <td>{commonProfileInfo.division || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Longitude</td>
                                                        <td>
                                                            <span className="click-link" onClick={this.showMapDialog} >
                                                                {commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.longitude || "---"}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Latitude</td>
                                                        <td>
                                                            <span className="click-link" onClick={this.showMapDialog} >
                                                                {commonProfileInfo.userDetailsInfoResponseDTO && commonProfileInfo.userDetailsInfoResponseDTO.latitude || "---"}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="personal-box-wrapper m-t-30">
                                        <div className="personal-box-title-wrapper">
                                            <div className="personal-box-title">
                                                <h4>User Info</h4>
                                            </div>
                                            {/* <div className="edit-button">
                                                <Button icon="fas fa-edit" />
                                            </div> */}
                                        </div>
                                        <div className="personal-box">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>User Name</td>
                                                        <td>{commonProfileInfo.username || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Password</td>
                                                        <td><span className="click-link" /**onClick={this.showChangePSWDialog}**/ ><a href="/change_password">Change</a></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Register Date</td>
                                                        <td>{ NetiDateUtils.getAnyShortForm(commonProfileInfo.registrationDate, 'DD-MM-YYYY HH:MM:SS') || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Register From</td>
                                                        <td>{commonProfileInfo.registerFrom || "---"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Point Found</td>
                                                        <td>{commonProfileInfo.numOfPoint || "---"}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="p-col-12">
                                    <div className="m-t-30">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2580.5429455085587!2d90.36608692475264!3d23.838546130129284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c13b11f4315d%3A0x37bd2cf4504a1e8!2sNetizen%20IT%20Limited!5e0!3m2!1sen!2sbd!4v1588654963663!5m2!1sen!2sbd" width="100%" height="250" frameborder="0" style={{ border: "0" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                    </div>
                                </div> */}
                                {/* <div className="p-col-12 m-t-30">
                                    <div className="nw-data-table nw-data-table-tabview m-0">
                                        <DataTable
                                            value={dataTableValue}
                                            header="Uploaded Documents"
                                            responsive={true}
                                        >
                                            <Column field="title" />
                                            <Column field="value" />
                                            <Column field="" body={this.actionTemplate} />
                                        </DataTable>
                                    </div>
                                </div> */}
                            </div>

                        {/* )
                    } */}
                    </div>
                <div className="dialog-section">
                    <Dialog id="dialog" className="nw-dialog height-auto" header="Change Longitude/Latitude" visible={this.state.visibleMapDialog} onHide={this.onHideMapDialog} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2580.5429455085587!2d90.36608692475264!3d23.838546130129284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c13b11f4315d%3A0x37bd2cf4504a1e8!2sNetizen%20IT%20Limited!5e0!3m2!1sen!2sbd!4v1588654963663!5m2!1sen!2sbd" width="100%" height="250" frameborder="0" style={{ border: "0" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog id="dialog" className="nw-dialog" header="Change Password" visible={this.state.visibleChangePswDialog} onHide={this.onChangePSWDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="change-password-steps">
                                    <ul>
                                        <li><span className="active">1</span></li>
                                        <li><span className="active">2</span></li>
                                        <li><span className="active">3</span></li>
                                        <li><span className="active">4</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="nw-form">
                                <div className='nw-form-body'>
                                    <div className="m-t-30 m-b-30">
                                        <div className="change-password-steps-wrapper">
                                            <div className="step-1">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">User Name </span>
                                                            <InputText
                                                                placeholder="Enter User Name"
                                                                id="deposit_amount"
                                                                keyfilter="num"
                                                                name="depositAmount"
                                                                value=""
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                                        <Button
                                                            className="nw-button nw-button-right p-button-primary" //p-button-primary 
                                                            label="Next"
                                                            icon="fas fa-check"//"fas fa-check" //fas fa-spinner fa-spin
                                                            onClick=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="step-2">
                                                <div className="nw-search-view">
                                                    <div className="p-grid">
                                                        <div class="p-col-12 p-xl-12">
                                                            <div class="p-inputgroup">
                                                                <span class="task-badge found"></span>
                                                                <span class="p-inputgroup-addon">Name</span>
                                                                <span class="p-inputgroup-colon">:</span>
                                                                <div class="nw-inputgroup-desc">Mamun Rahman</div>
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-xl-12">
                                                            <div class="p-inputgroup">
                                                                <span class="task-badge found"></span>
                                                                <span class="p-inputgroup-addon">Date of Birth</span>
                                                                <span class="p-inputgroup-colon">:</span>
                                                                <div class="nw-inputgroup-desc">1988 - 02 - 12</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Mobile NO. </span>
                                                            <InputText
                                                                placeholder="Enter Mobile Number"
                                                                id="deposit_amount"
                                                                keyfilter="num"
                                                                name="depositAmount"
                                                                value=""
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                                        <Button
                                                            className="p-button-primary nw-button nw-button-right " //p-button-primary 
                                                            label="Next"
                                                            icon="fas fa-check"//"fas fa-check" //fas fa-spinner fa-spin
                                                            onClick=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="step-3">
                                                <div className="m-t-30 m-b-30">
                                                    <div className="p-grid">
                                                        <div className="p-col-3 p-0"></div>
                                                        <div className="p-col-6">
                                                            <div className="text-center">
                                                                <p className="text-info">A 6 Digit Verification Code has been sent to this number:
                                                                This Code is Active for Next 5 Minutes</p>
                                                            </div>
                                                            <div className="verifiation-wrapper">
                                                                <h1 className="title">VERIFICATION CODE</h1>
                                                                <div className="verify-code-input">
                                                                    <InputText
                                                                        keyfilter="num"
                                                                        name="depositAmount"
                                                                        value=""
                                                                    />
                                                                    <InputText
                                                                        keyfilter="num"
                                                                        name="depositAmount"
                                                                        value=""
                                                                    />
                                                                    <InputText
                                                                        keyfilter="num"
                                                                        name="depositAmount"
                                                                        value=""
                                                                    />
                                                                    <InputText
                                                                        keyfilter="num"
                                                                        name="depositAmount"
                                                                        value=""
                                                                    />
                                                                    <InputText
                                                                        keyfilter="num"
                                                                        name="depositAmount"
                                                                        value=""
                                                                    />
                                                                    <InputText
                                                                        keyfilter="num"
                                                                        name="depositAmount"
                                                                        value=""
                                                                    />
                                                                </div>
                                                                <div className="nw-button-parent m-t-30 m-b-30">
                                                                    <Button
                                                                        className="p-button-primary nw-button nw-button-center"
                                                                        label="Next"
                                                                        icon="fas fa-check"
                                                                        onClick=""
                                                                    />
                                                                </div>
                                                                <div className="text-center">
                                                                    <p className="text-info">I didn't get any Code,<b> RESEND (59) </b></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="step-4">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">NEW PASSWORD </span>
                                                            <InputText
                                                                placeholder="enter new password"
                                                                id="deposit_amount"
                                                                keyfilter="num"
                                                                name="depositAmount"
                                                                value=""
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">RE-TYPE PASSWORD </span>
                                                            <InputText
                                                                placeholder="Enter Re-type Password"
                                                                id="deposit_amount"
                                                                keyfilter="num"
                                                                name="depositAmount"
                                                                value=""
                                                                autoWidth={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                                        <div className="nw-button-parent">
                                                            <div class="required-field">( ) Minimum 6 Digit on Passwords</div>
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-xl-12 text-center">
                                                        <div className="nw-button-parent m-t-30">
                                                            <Button
                                                                className="p-button-primary nw-button nw-button-center"
                                                                label="Reset Password"
                                                                icon="fas fa-check"
                                                                onClick=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                
                    <Dialog id="dialog" className="nw-dialog" header="Change Personal Info" visible={this.state.visiblePersonalInfoDialog} onHide={this.onHidePersonalInfoDialog} maximizable>
                        <div className="p-col-12">
                            <div className="nw-form">
                                <div className="nw-form-body p-grid">
                                    
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Father's Name </span>
                                            <InputText
                                                placeholder="Enter Father's Name"
                                                name="fatherName"
                                                value={updateUserObj.userDetailsInfoResponseDTO.fatherName}
                                                onChange={e => this.onChageFatherName(e, 'update')}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{errors['fatherName']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Mothers's Name </span>
                                            <InputText
                                                placeholder="Enter Mother's Name"
                                                name="motherName"
                                                value={updateUserObj.userDetailsInfoResponseDTO.motherName}
                                                onChange={e => this.onChageMotherName(e, 'update')}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{errors['motherName']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Mobile No. </span>
                                            <InputText
                                                placeholder="Enter Mobile No"
                                                name="basicMobile"
                                                value={updateUserObj.basicMobile}
                                                onChange={e => this.onChageBasicMobile(e, 'update')}
                                                autoWidth={false}
                                                keyfilter="num"
                                            />
                                        </div>
                                        <span className="error-message">{errors['basicMobile']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Date of birth</span>
                                            <Calendar
                                                id="dateOfBirth"
                                                name="dateOfBirth"
                                                showIcon={true}
                                                onChange={e => this.onChageDateOfBirth(e, 'update')}
                                                placeholder="Enter your date of birth"
                                                value={updateUserObj.dateOfBirth}
                                                className="custom-calender-input"
                                                dateFormat="dd/mm/yy"
                                                yearRange="1900:2050"
                                                monthNavigator={true}
                                                yearNavigator={true}
                                            />
                                        </div>
                                        <span className="error-message">{errors['dateOfBirth']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <div className="input-radio-button">
                                                <span className="p-inputgroup-addon addon-no-style">Gender </span>
                                                <div className="radio-button-inside">
                                                    <RadioButton
                                                        value="Male"
                                                        inputId="male"
                                                        name="gender"
                                                        onChange={(e) => this.genderHandle(e, 'update')}
                                                        checked={updateUserObj.gender === "Male"}
                                                    />
                                                    <label htmlFor="male" className="p-radiobutton-label"><b>Male</b></label>
                                                </div>
                                                <div className="radio-button-inside">
                                                    <RadioButton
                                                        value="Female"
                                                        inputId="female"
                                                        name="gender"
                                                        onChange={(e) => this.genderHandle(e, 'update')}
                                                        checked={updateUserObj.gender === "Female"}
                                                    />
                                                    <label htmlFor="female" className="p-radiobutton-label"><b>Female</b></label>
                                                </div>
                                                <div className="radio-button-inside">
                                                    <RadioButton
                                                        value="Others"
                                                        inputId="Others"
                                                        name="gender"
                                                        onChange={(e) => this.genderHandle(e, 'update')}
                                                        checked={updateUserObj.gender === "Others"}
                                                    />
                                                    <label htmlFor="others" className="p-radiobutton-label"><b>Others</b></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.firstDropDownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Blood Group  </span>
                                                <Dropdown
                                                    placeholder="Select Blood Group"
                                                    options={bloodGroupOptions}
                                                    onChange={e => this.onChangeBloogGroup(e, 'update')}
                                                    value={updateUserObj.bloodGroupInfoDTO.coreCategoryID}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name='bloodGroup'
                                                />
                                            </div>
                                        }
                                        <span className='error-message'>{this.state.errors['bloodGroup']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Marital Status  </span>
                                            <Dropdown
                                                placeholder="Select Marital Status"
                                                options={maritalStatusOptions}
                                                onChange={e => this.onChangeMaritalStatus(e, 'update')}
                                                value={updateUserObj.userDetailsInfoResponseDTO.maritalStatus}
                                                filter={true}
                                                autoWidth={false}
                                                name='maritalStatus'
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors['maritalStatus']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Number of Child  </span>
                                            <InputText
                                                placeholder="Enter Number of Child"
                                                name="numberOfChild"
                                                value={updateUserObj.userDetailsInfoResponseDTO.numberOfChild}
                                                onChange={e => this.onChageNumberOfChild(e, 'update')}
                                                autoWidth={false}
                                                keyfilter="num"
                                            />
                                        </div>
                                        <span className="error-message">{errors['numberOfChild']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Nationality  </span>
                                            <InputText
                                                placeholder="Enter Nationality, ex: Bangladeshi"
                                                name="nationality"
                                                value={updateUserObj.userDetailsInfoResponseDTO.nationality}
                                                onChange={e => this.onChageNationality(e, 'update')}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{errors['nationality']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">National ID.  </span>
                                            <InputText
                                                placeholder="Enter National ID"
                                                name="nationalID"
                                                value={updateUserObj.userDetailsInfoResponseDTO.nationalID}
                                                onChange={e => this.onChageNationalId(e, 'update')}
                                                autoWidth={false}
                                                keyfilter="num"
                                            />
                                        </div>
                                        <span className="error-message">{errors['nationalID']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Passport No.  </span>
                                            <InputText
                                                placeholder="Enter Passport No."
                                                name="passportNo"
                                                value={updateUserObj.userDetailsInfoResponseDTO.passportNo}
                                                onChange={e => this.onChagePassportNo(e, 'update')}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{errors['passportNo']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Birth Certificate No.  </span>
                                            <InputText
                                                placeholder="Enter Birth Certificate No."
                                                name="birthCertificateNo"
                                                value={updateUserObj.userDetailsInfoResponseDTO.birthCertificateNo}
                                                onChange={e => this.onChageBirthCertificateNo(e, 'update')}
                                                autoWidth={false}
                                                keyfilter="num"
                                            />
                                        </div>
                                        <span className="error-message">{errors['birthCertificateNo']}</span>
                                    </div>

                                    {/* <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Hobby  </span>
                                            <InputText
                                                placeholder="Enter Birth Certificate No."
                                                name="hobby"
                                                value={updateUserObj.hobby}
                                                onChange={e => this.onChageHobby(e, 'update')}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{errors['hobby']}</span>
                                    </div> */}

                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-button-parent">
                                            <Button
                                                className="p-button-primary nw-button nw-button-right"
                                                label="Save"
                                                icon="fas fa-check"
                                                onClick={ e => this.onUpdateProfileInfo('profile')}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog id="dialog" className="nw-dialog" header="Change Address Info" visible={this.state.visibleAddressInfoDialog} onHide={this.onHideAddressInfoDialog} maximizable>
                        <div className="p-col-12">
                            <div className="nw-form">
                                <div className="nw-form-body p-grid">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Address Details  </span>
                                            <InputText
                                                placeholder="Enter Address"
                                                name="addressDetails"
                                                value={updateUserObj.userDetailsInfoResponseDTO.addressDetails}
                                                onChange={e => this.onChageAddress(e, 'update')}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{errors['addressDetails']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.firstDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Division  </span>
                                                <Dropdown
                                                    placeholder={ updateUserObj.division }
                                                    value={this.state.division}
                                                    onChange={this.onChangeDivision}
                                                    options={divisionList}
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="division"
                                                    className="custom-dropdown"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{this.state.errors["division"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.districtDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">District  </span>
                                                <Dropdown
                                                    placeholder={ updateUserObj.district }
                                                    options={districtList}
                                                    value={this.state.districtId}
                                                    onChange={this.onChangeDistrict}
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="district"
                                                    className="custom-dropdown"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{this.state.errors['district']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.upazillaDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Upazilla/Thana  </span>
                                                <Dropdown
                                                    placeholder={ updateUserObj.globalAreaInfoDTO.categoryName }
                                                    value={updateUserObj.globalAreaInfoDTO && updateUserObj.globalAreaInfoDTO.coreCategoryID}
                                                    options={upazillaList}
                                                    onChange={ e => this.onChangeUpazilla( e, 'update')}
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="upazilla"
                                                    className="custom-dropdown"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{this.state.errors["upazilla"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Longitude  </span>
                                            <InputText
                                                placeholder="Enter Longitude"
                                                name="longitude"
                                                value={updateUserObj.userDetailsInfoResponseDTO.longitude}
                                                onChange={e => this.onChageLongitude(e, 'update')}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{errors['longitude']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Latitude  </span>
                                            <InputText
                                                placeholder="Enter Address"
                                                name="latitude"
                                                value={updateUserObj.userDetailsInfoResponseDTO.latitude}
                                                onChange={e => this.onChageLatitude(e, 'update')}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className="error-message">{errors['latitude']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-button-parent">
                                            <Button
                                                className="p-button-primary nw-button nw-button-right"
                                                label="Save"
                                                icon="fas fa-check"
                                                onClick={ e => this.onUpdateProfileInfo('address')}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }
}