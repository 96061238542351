
export class NetiDateUtils {
    static getMoment() {
        var moment = require('moment/moment');
        return moment;
    }

    static getCurrentDate() {
        var moment = require('moment/moment');
        var currentDate = new Date();
        var currentDateeeee = moment(currentDate).format('YYYY-MM-DD');
        return currentDateeeee;
    }

    static getMonthName(date) {
        var moment = require('moment/moment');
        var monthName = moment(date).format('MMMM');
        return monthName;
    }
    static getMonth(date) {
        var moment = require('moment/moment');
        var month = moment(date).format('MM');
        return month;
    }

    static getDay(date) {
        var moment = require('moment/moment');
        var day = moment(date).format('DD');
        return day;
    }
    static getDayName(date) {
        var moment = require('moment/moment');
        var dayName = moment(date).format('dddd');
        return dayName;
    }
    static getYear(date) {
        var moment = require('moment/moment');
        var year = moment(date).format('YYYY');
        return year;
    }
    static getYY(date) {
        var moment = require('moment/moment');
        var yy = moment(date).format('YY');
        return yy;
    }
    static getAnyShortForm(date, format) {
        var moment = require('moment/moment');
        var anyShortForm = moment(date).format(format);
        return anyShortForm;
    }
    static getDateFromString(date) {
        var moment = require('moment/moment');
        var dateFromString = moment(date).format('YYYY-MM-DD');
        return dateFromString;
    }

    static getDateFromStringYYYYMMDD(date) {
        var moment = require('moment/moment');
        var dateFromString = moment(date).format('YYYY/MM/DD');
        return dateFromString;
    }

    static getDateFromStringDDMMYYYY(date) {
        var moment = require('moment/moment');
        var dateFromString = moment(date).format('DD/MM/YYYY');
        return dateFromString;
    }

    static getYYMMDD(date) {
        var moment = require('moment/moment');
        var yy = moment(date).format('YY');
        var mm = moment(date).format('MM');
        var dd = moment(date).format('DD');
        var yymmdd = yy + mm + dd;
        return yymmdd;
    }
    static getHHMMSS(date) {
        var moment = require('moment/moment');
        var hh = moment(date).format('h');
        var mm = moment(date).format('mm');
        var ss = moment(date).format('ss');
        // var hhmmss = hh + mm + ss;
        var hhmmss = hh + mm + ss;
        return hhmmss;
    }
    static getHHMMSSFForHome(date) {
        var moment = require('moment/moment');
        var hh = moment(date).format('h');
        var mm = moment(date).format('mm');
        var ss = moment(date).format('ss');
        var aa = moment(date).format('a');
        // var hhmmss = hh + mm + ss;
        var hhmmss = hh + ':' + mm + ':' + ss + ' ' + aa;
        return hhmmss;
    }
    static getHHMMSSFForDashboard() {
        var moment = require('moment/moment');
        var hh = moment().format('h:mm:ss a');
        return hh;
    }
    static getDateWithTime(date) {
        var dateTime = this.getDateFromString(date) + ' ' + this.getHHMMSS(date);
        return dateTime;
    }
    static getDateWithHHMMSSA(date) {
        var dateTime = this.getAnyShortForm(date, 'DD-MMM-YYYY') + ' ' + this.getHHMMSSFForHome(date);
        return dateTime;
    }

    static getHHMMSS24H(date) {
        var moment = require('moment/moment');
        var hh = moment(date).format('H');
        var mm = moment(date).format('mm');
        var ss = moment(date).format('ss');
        var hhmmss = hh + ':' + mm + ':' + ss;
        return hhmmss;
    }

    static getHHMMSS12H(date) {
        var moment = require('moment/moment');
        var hh = moment(date).format('h');
        var mm = moment(date).format('mm');
        var ss = moment(date).format('ss');
        var hhmmss = hh + ':' + mm + ':' + ss;
        return hhmmss;
    }

    static getDDhypMMhypYY(date) {
        var dateTime = this.getAnyShortForm(date, 'DD-MMM-YYYY');
        return dateTime;
    }

    static getplusDaysWithSpecificDate(date, numb) {
        var moment = require('moment/moment');
        var plusDaysWithSpecificDate = moment(date).add(numb, 'days').calendar();
        return plusDaysWithSpecificDate;
    }

    static getAddDayWithToDate(date, numb) {
        var moment = require('moment/moment');
        // var plusDaysWithSpecificDate = moment(date).add(numb, 'day').calendar();
        var plusDaysWithSpecificDate = moment(date).add(numb, 'd').format('YYYY-MM-DD');
        return plusDaysWithSpecificDate;
    }

    static getDateFromStringWithMinSec(date) {
        var moment = require('moment/moment');
        var datewithMinSec = moment(date).format('YYYY-MM-DDTHH:mm:ss:SSSZ');
        return datewithMinSec;
    }
    static getDateFromStringWithHMS(date) {
        var moment = require('moment/moment');
        var datewithMinSec = moment(date).format('YYYY-MM-DDTHH:mm:ss');
        return datewithMinSec;
    }
    static getDateFrom(date) {
        var moment = require('moment/moment');
        var datewithMin = moment.utc(date).format();
        // var datewithMinSec = moment(date).format('YYYY-MM-DD LTS');
        // var datewithMinSec = moment(date).format('YYYY-MM-DD, h:mm:ss');
        return datewithMin;
    }

    static getHHcloneMMcloneSS(date) {
        var moment = require('moment/moment');
        var hh = moment(date).format('h');
        var mm = moment(date).format('mm');
        var ss = moment(date).format('ss');
        // var hhmmss = hh + mm + ss;
        var hhmmss = hh + ':' + mm + ':' + ss;
        return hhmmss;
    }

    static getYearList() {
        let yrList = [];
        for (var i = 2010; i < 2031; i++) {
            yrList = [...yrList, i];
        }
        return yrList;
    }

    static getDateTimeShortForm(date) {
        var moment = require('moment/moment');
        var datewithMinSec = moment(date).format('YYMMDDHHmmss');
        return datewithMinSec;
    }
    static getGreetingForUser(date, netiID) {
        var greeting = "";
        var moment = require('moment/moment');
        var hh = moment(date).format('h');
        var aa = moment(date).format('a');

        if (hh >= 5 && hh <= 11 && aa === 'am') {
            if (localStorage.getItem('morning') === 'done' + netiID) {
                greeting = "AlreadyShown";
            } else {
                greeting = "Good Morning";
                localStorage.clear();
                localStorage.setItem('morning', 'done' + netiID);
            }
        } else if ((hh >= 12 && aa === 'pm') || ((hh >= 1 && hh <= 2) && aa === 'pm')) {
            if (localStorage.getItem('noon') === 'done' + netiID + "") {
                greeting = "AlreadyShown";
            } else {
                greeting = "Good Noon";
                localStorage.clear();
                localStorage.setItem('noon', ('done' + netiID));
            }
        } else if ((hh >= 2 && hh < 5) && aa === 'pm') {
            if (localStorage.getItem('afternoon') === 'done' + netiID) {
                greeting = "AlreadyShown";
            } else {
                greeting = "Good Afternoon";
                localStorage.clear();
                localStorage.setItem('afternoon', 'done' + netiID);
            }
        } else if ((hh >= 5 && hh < 7) && aa === 'pm') {
            if (localStorage.getItem('evening') === 'done' + netiID) {
                greeting = "AlreadyShown";
            } else {
                greeting = "Good Evening";
                localStorage.clear();
                localStorage.setItem('evening', 'done' + netiID);
            }
        }
        else if (((hh >= 6 && hh <= 11) && aa === 'pm') || ((hh === 12 || (hh >= 1 && hh <= 4)) && aa === 'am')) {
            if (localStorage.getItem('night') === 'done' + netiID) {
                greeting = "AlreadyShown";
            } else {
                greeting = "Good Night";
                localStorage.clear();
                localStorage.setItem('night', 'done' + netiID);
            }
        }
        else {
            greeting = "Hello";
        }
        return greeting;
    }

    static getYearRange() {
        let currentDate = new Date();
        let currentYear = "1970:" + currentDate.getFullYear();
        return currentYear;
    }
    static reverseToYYYYMMDD(date) {
        let regex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
        if (regex.test(date)) {
            date = date.split("-").reverse().join("-").toString();
        }
        return date;
    }

    static getDurationYearMonthAndDayStringFromDateRange(fromDate, toDate) {
        let moment = require('moment/moment');
        let startDate = moment(fromDate);
        let endDate = moment(toDate);

        let years = endDate.diff(startDate, 'years')
        startDate.add(years, 'years')

        let months = endDate.diff(startDate, 'months')
        startDate.add(months, 'months')

        let days = endDate.diff(startDate, 'days');
        // console.log("Duration", years, months);

        let yearString = '';
        let monthString = '';
        let dayString = '';

        if (years > 0) {
            years > 1 ? yearString = 'Years' : yearString = 'Year';
        } else {
            years = ''
        }

        if (months > 0) {
            months > 1 ? monthString = 'Months' : monthString = 'Month';
        } else {
            months = ''
        }

        if (days > 0) {
            days > 1 ? dayString = 'Days' : dayString = 'Day';
        } else {
            days = ''
        }

        if (!yearString && !monthString && !dayString) {
            return 0 // 0 days
        } else {
            return `${years} ${yearString} ${months} ${monthString} ${days} ${dayString}`;
        }

    }

}