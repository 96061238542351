import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';

export class CRMPointService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    addNewProductAtInventory(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + '/inventory/product/new';
        return this.Auth.postFetch(uri, requestedObject)
            .catch((error) => console.log("error", error));
    }

    fetchProductListFromInventory(searchObjInfo) {
        let uri = this.baseURIHolder.getCRM() + '/inventory/products';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => console.log("error", error));
    }

    fetchProductListFromInventoryByInventoryID(inventoryID) {
        let uri = this.baseURIHolder.getCRM() + `/inventory/product/get?inventoryID=${inventoryID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    updateProductInfoFromInventory(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + '/inventory/product/update';
        return this.Auth.putFetch(uri, requestedObject)
            .catch((error) => console.log("error", error));
    }

    fetchProductUnApprovedDeviceListByProductID(productID) {
        let uri = this.baseURIHolder.getCRM() + `/inventory/product/un-approved/list/by?productID=${productID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error))
    }

    approveInventoryDevice(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + '/inventory/product/approve';
        return this.Auth.putFetch(uri, requestedObject)
            .catch((error) => console.log("error", error))
    }

    fetchDepositPendingList(requestType, requestStatus) {
        let uri = this.baseURIHolder.getCRM() + "/balance/requests/by?requestStatus=" + requestStatus + "&requestType=" + requestType;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    updateDepositBalanceApprove(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + "/balance/deposit/approve";
        return this.Auth.putFetch(uri, requestedObject)
            .catch((error) => console.log("error", error))
    }

    updateBalanceWithdrawApprove(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + "/balance/withdraw/approve";
        return this.Auth.putFetch(uri, requestedObject)
            .catch((error) => console.log("error", error))
    }

    fetchBankList(defaultCode) {
        let uri = this.baseURIHolder.getCRM() + "/core/bank/accounts/by?defaultCode=" + defaultCode;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchPre_PaidDisbursementInstituteList() {
        let uri = this.baseURIHolder.getCRM() + `/em/billable/institute/by?paymentType=PRE-PAID`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchAdvanceBill_LogByEmDetailsId(emDetailsID) {
        let uri = this.baseURIHolder.getCRM() + `/em/advance/billlog/by?emDetailsID=${emDetailsID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchPaidBill_LogByEmDetailsId(emDetailsID) {
        let uri = this.baseURIHolder.getCRM() + `/em/paid/billlog/by?emDetailsID=${emDetailsID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchGeneratedDueBill_LogByEmDetailsId(emDetailsID) {
        let uri = this.baseURIHolder.getCRM() + `/em/due/billlog/by?emDetailsID=${emDetailsID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchEmBillProductListByProductDefaultCode(defaultCode) {
        let uri = this.baseURIHolder.getCRM() + `/em/bill/product/by?productDefaultCode=${defaultCode}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchPendingEM_OrderList(searchObjInfo) {
        let uri = this.baseURIHolder.getCRM() + '/em/order/pending';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => { console.log(error) })
    }

    fetchPendingEM_OrderDetailsByEmDetailsID(emDetailsID) {
        let uri = this.baseURIHolder.getCRM() + `/em/pending/order/get?emDetailsID=${emDetailsID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => { console.log(error) })
    }

    edumanOrderApproveSubmit(requestObject) {
        let uri = this.baseURIHolder.getCRM() + `/em/order/approve`;
        return this.Auth.postFetch(uri, requestObject)
            .catch((error) => { console.log(error) })
    }

    fetchEM_Bill_Processable_InstituteList(year, month) {
        let uri = this.baseURIHolder.getCRM() + `/em/bill/processable/institutes?month=${month}&year=${year}`;
        return this.Auth.getFetch(uri)
            .catch((error) => { console.log(error) })
    }

    edumanBillProcessSubmit(remainInstitutes, year, month) {
        let uri = this.baseURIHolder.getCRM() + `/em/bill/generate?month=${month}&year=${year}`;
        return this.Auth.postFetch(uri, remainInstitutes)
            .catch((error) => { console.log(error) })
    }

    getAllPendingInstitutesToMapByUrlID(searchObjInfo) {
        let uri = this.baseURIHolder.getCRM() + '/institute/mapped/url/by';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => { console.log(error) })
    }

    changeMappedInstituteStatus(mappingID, status) {
        let uri = this.baseURIHolder.getCRM() + `/change/mapped/institute/status?mappingID=${mappingID}&status=${status}`;
        return this.Auth.postFetch(uri)
            .catch((error) => { console.log(error) })
    }

    getBalanceRequestsInfoByDateRange(searchObjInfo) {
        let uri = this.baseURIHolder.getCRM() + '/balance/requests/by/date_range';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => { console.log(error) })
    }

    payPrepaidBill(prepaidBillInfo) {
        let uri = this.baseURIHolder.getCRM() + '/em/prepaid/bill/pay';
        return this.Auth.postFetch(uri, prepaidBillInfo)
            .catch((error) => { console.log(error) })
    }

    createCrmPointAssign(pointAssignInfo) {
        let uri = this.baseURIHolder.getCRM() + '/assign/point';
        return this.Auth.postFetch(uri, pointAssignInfo)
            .catch((error) => console.log('error', error));
    }

    getEdumanInfoByDateRange(searchObjInfo) {
        let uri = this.baseURIHolder.getCRM() + '/em/order/list/by/date_range';
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => { console.log(error) })
    }

    approveDomainAndHosting(status, approveObjInfo) {
        let uri = this.baseURIHolder.getCRM() + `/domain-hosting/approve?status=${status}`;
        return this.Auth.postFetch(uri, approveObjInfo)
            .catch((error) => { console.log(error) })
    }

    getDomainAndHostingListByStatus(orderStatus, orderType, searchObjInfo) {
        let uri = this.baseURIHolder.getCRM() + `/domain-hosting/by/status?orderStatus=${orderStatus}&orderType=${orderType}`;
        return this.Auth.postFetch(uri, searchObjInfo)
            .catch((error) => { console.log(error) })
    }

    getUserInstituteId(instituteId) {
        let uri = this.baseURIHolder.getUser() + '/temp/billDueDiff?instituteID=' + instituteId;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createBillAdjustment(requestedObject) {
        let uri = this.baseURIHolder.getUser() + '/temp/save/billDueDiff';
        return this.Auth.postFetch(uri, requestedObject)
            .catch((error) => console.log("error", error));
    }

    findBasicInfoByinstituteID(instituteID) {
        let uri = this.baseURIHolder.getUser() + '/temp/allBillDiff?instituteID=' + instituteID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createBillPaidDiff(requestedObject) {
        let uri = this.baseURIHolder.getUser() + '/temp/save/billPaidDiff';
        return this.Auth.postFetch(uri, requestedObject)
            .catch((error) => console.log("error", error));
    }

    getRefundableInsititute() {
        let uri = this.baseURIHolder.getCRM() + '/admisia/refundable/institutes';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEdumanAccessInfo(instituteID) {
        let uri = this.baseURIHolder.getCRM() + `/eduman/at-a-glance/by?instituteID=${instituteID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    sendAdmisiaRefundRequest(refundRequestInfo) {
        let uri = this.baseURIHolder.getCRM() + '/admisia/refund/request';
        return this.Auth.postFetch(uri, refundRequestInfo)
            .catch((error) => console.log("error", error));
    }

    findRefundRequestByRefundStatus(refundStatus) {
        let uri = this.baseURIHolder.getCRM() + `/admisia/refund/request/by-refundStatus?refundStatus=${refundStatus}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    approvedRefundRequest(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + '/admisia/refund/request/approved';
        return this.Auth.putFetch(uri, requestedObject)
            .catch((error) => console.log("error", error));
    }

    getEdumanBillLogByBillType(billType, emDetailsID) {
        let uri = this.baseURIHolder.getCRM() + `/eduman/at-a-glance/billlog/by-billtype?billType=${billType}&emDetailsID=${emDetailsID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEmBillDetailsByEmdetailsID(emDetailsID) {
        let uri = this.baseURIHolder.getCRM() + `/eduman/at-a-glance/bill-details?emDetailsID=${emDetailsID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEmBillDiffForRegenerate(billGenerateID, emDetailsID) {
        let uri = this.baseURIHolder.getCRM() + `/eduman/at-a-glance/bill/regen/diff?billGenerateID=${billGenerateID}&emDetailsID=${emDetailsID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    regenerateEdumanBill(emBillInfo) {
        let uri = this.baseURIHolder.getCRM() + '/em/bill/regenerate';
        return this.Auth.postFetch(uri, emBillInfo)
            .catch((error) => console.log("error", error));
    }

    updateEmPartner(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + '/change/em/partner';
        return this.Auth.putFetch(uri, requestedObject)
            .catch((error) => console.log("error", error));
    }

    updateEmAdmin(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + '/change/em/admin';
        return this.Auth.putFetch(uri, requestedObject)
            .catch((error) => console.log("error", error));
    }

    findPartnerInfoByNeiID(customNetiID) {
        let uri = this.baseURIHolder.getCRM() + `/partner-info/by?customNetiID=${customNetiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    terminateEmID(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + '/terminate/em-id';
        return this.Auth.putFetch(uri, requestedObject)
            .catch((error) => console.log("error", error));
    }

    getDateWiseAdvanceBill(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + '/em/advance/billlog/by/date-range';
        return this.Auth.postFetch(uri, requestedObject)
            .catch((error) => console.log('error', error));
    }

    fetchDiscountApprovedList() {
        let uri = this.baseURIHolder.getCRM() + `/eduman/bill/discount/pending-request`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateEdumanBillDiscount(emBillInfo) {
        let uri = this.baseURIHolder.getCRM() + '/eduman/bill/discount/request/status/update';
        return this.Auth.postFetch(uri, emBillInfo)
            .catch((error) => console.log("error", error));
    }

    getDisabledUser() {
        let uri = this.baseURIHolder.getCRM() + `/users/disabled`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateUserStatus(userObj) {
        let uri = this.baseURIHolder.getCRM() + '/users/disable';
        return this.Auth.putFetch(uri, userObj)
            .catch((error) => console.log("error", error));
    }

    generateEdumanBillManually(emBillInfo) {
        let uri = this.baseURIHolder.getCRM() + '/em/bill/generate/manually';
        return this.Auth.postFetch(uri, emBillInfo)
            .catch((error) => console.log("error", error));
    }

    findEmDetailsByInstituteId(instituteID) {
        let uri = this.baseURIHolder.getCRM() + `/em/basic-info/by?instituteId=${instituteID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findCurrentStatusDashboardInfo() {
        let uri = this.baseURIHolder.getCRM() + '/current-status/dashboard/info';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchDashboardTokenAndCallDetails(dateRange) {
        let uri = this.baseURIHolder.getCRM() + `/token/dashboard/info`;
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    findEmDetailsByNetiId(customID) {
        let uri = this.baseURIHolder.getCRM() + `/partner/at-a-glance?customID=${customID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    partnerBankingoByNetiId(netiID) {
        let uri = this.baseURIHolder.getCRM() + `/partner/at-a-glance/accounts?netiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    partnerPointsByNetiId(loginID) {
        let uri = this.baseURIHolder.getCRM() + `/partner/at-a-glance/points?loginID=${loginID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    partnerDeviceStockOut(netiID) {
        let uri = this.baseURIHolder.getCRM() + `/partner/at-a-glance/all-stockout-device?netiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    partnerDeviceTagged(netiID) {
        let uri = this.baseURIHolder.getCRM() + `/partner/at-a-glance/tagged-device?netiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    partnerDeviceNonTagged(netiID) {
        let uri = this.baseURIHolder.getCRM() + `/partner/at-a-glance/non-tagged-device?netiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    getUsedPurchaseCode(netiID) {
        let uri = this.baseURIHolder.getCRM() + `/partner/at-a-glance/purchase-code?code=1&netiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    getUnusedPurchaseCode(netiID) {
        let uri = this.baseURIHolder.getCRM() + `/partner/at-a-glance/purchase-code?code=0&netiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchDashboardStatusDetails(dateRange) {
        let uri = this.baseURIHolder.getManagement() + `/balance/dashboard/info`;
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    edumanDashboardChartsResults() {
        let uri = this.baseURIHolder.getManagement() + `/eduman/current-status-dashboard`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findPurchaseAtAGlanceByInvoiceId(invoiceId) {
        let uri = this.baseURIHolder.getCRM() + `/purchase/glance/by?invoiceId=${invoiceId}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findAllCoreObserverTaggings() {
        let uri = this.baseURIHolder.getCRM() + `/core-observer/tag`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    tagCoreObserverPoint(requestInfo) {
        let uri = this.baseURIHolder.getCRM() + `/core-observer/tag`;
        return this.Auth.postFetch(uri, requestInfo)
            .catch((error) => console.log("error", error));
    }

    findUserForObserverTagging(customNetiID, roleName) {
        let uri = this.baseURIHolder.getCRM() + `/core-observer/find/by?customNetiID=${customNetiID}&roleName=${roleName}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getDFPSsettlementBankInfoByInstituteID(instituteID) {
        let uri = this.baseURIHolder.getCRM() + `/dfps/settlement/bank/by?instituteID=${instituteID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    submitPartnerOccurrence(obj) {
        let uri = this.baseURIHolder.getUser() + `/new/user-occurrence`;
        return this.Auth.postFetch(uri, obj)
            .catch((error) => console.log("error", error));
    }

    getFetchOccurrenceList() {
        let uri = this.baseURIHolder.getUser() + `/user-occurrence/info`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getPartnerDesignationListForSendSms(defaultCode) {
        let uri = this.baseURIHolder.getUser() + `/core/category-types/by/typeDefaultCode?typeDefaultCode=${defaultCode}&typeStatus=1`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getPartnerListForSendSms(defaultCode) {
        let uri = this.baseURIHolder.getCRM() + `/partner/info/by?designationDefaultCode=${defaultCode}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getPointTypeList() {
        let uri = this.baseURIHolder.getCRM() + `/all-user/role`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserDetailsPointWise(url, role) {
        let uri = this.baseURIHolder.getCRM() + `/${url}/info/by?userRole=${role}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserDetailsForDueDetailsList(role) {
        let uri = this.baseURIHolder.getCRM() + `/eduman-due/info/by?userType=${role}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserDetailsForExpireList(pointType, role) {
        let uri = this.baseURIHolder.getCRM() + `/point-expire/info/by?userRole=${pointType}&userType=${role}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findCrmUserWalletDetails(walletType) {
        let uri = this.baseURIHolder.getCRM() + `/different-type/wallet/details?walletType=${walletType}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateCoreObserverTagging(requestedObject) {
        let uri = this.baseURIHolder.getCRM() + '/core-observer/tag/update';
        return this.Auth.putFetch(uri, requestedObject)
            .catch((error) => console.log("error", error));
    }

    fetchPendingTokenList() {
        let uri = this.baseURIHolder.getCRM() + `/pending-token/details`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchEmOrderDetailsList() {
        let uri = this.baseURIHolder.getCRM() + `/pending/eduman-order/details`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchPendignSmsDetailsList() {
        let uri = this.baseURIHolder.getCRM() + `/netiworld/sms/remain-balance/details`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchRemainDeviceDetailsList() {
        let uri = this.baseURIHolder.getCRM() + `/device/remain-stock/details`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchPendingDeviceReqDetailsList() {
        let uri = this.baseURIHolder.getCRM() + `/device/pending-request/details`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getProductConfigurationByproductID(productID) {
        let uri = this.baseURIHolder.getCRM() + `/product/glance/by?productID=${productID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    findUserBalanceRevenueAffectedLogs(balanceRevLogID) {
        let uri = this.baseURIHolder.getCRM() + `/user-balance/revenue/affected?balanceRevLogID=${balanceRevLogID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    saveEdumanBillDiscount(obj) {
        let uri = this.baseURIHolder.getCRM() + `/eduman/bill/discount/request/save/and/approve`;
        return this.Auth.postFetch(uri, obj)
            .catch((error) => console.log("error", error));
    }

    getPostpaidBillSummary(emID) {
        let uri = this.baseURIHolder.getCRM() + `/postpaid/bill/summary?emID=${emID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }
}   
