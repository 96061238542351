import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { BalanceService } from '../../../service/myPoint/BalanceService';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { EdumanActionLogService } from '../../../service/actionLog/EdumanActionLogService';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { pdfDownloadLandscape_FullTable, pdfDownloadLandscape_TableWithSubTitle, pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';
import CommonFuctionality from '../../common/CommonFuctionality';

let maxDate = new Date();
let totalActions = 0;
let totalPercentage = 0;
let totalSearchActions = 0;
let totalInsertActions = 0;
let totalUpdateActions = 0;
let totalDeleteActions = 0;
let totalDownloadActions = 0;

let selectedActionNm = '';
// pdf
let subTitleColumn = [];
let subTitleList = [];

let dialogSubTitleColumn = [];
let dialogSubTitleList = [];

export class EmInstituteWiseActionLogDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            dataTableSelection: null,
            dialogModuleName: '',
            dialogActivityType: '',
            dialogDataTableIsLoading: false,
            dialogDataTableValue: [],
            dataTableValue: [],
            dateRangeParamFormat: {
                requestStartDate: "",
                requestEndDate: "",
                instituteID: '',
            },
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            pdfDataTable: [],
            pdfFooterArray: [],

        };

        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.balanceService = new BalanceService();
        this.CRMPointService = new CRMPointService();
        this.edumanActionLogService = new EdumanActionLogService();

        this.CommonFuctionality = new CommonFuctionality();
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportPdfOfDialog = this.exportPdfOfDialog.bind(this);

    }

    onChangeInstituteID = (e) => {
        let { errors, dateRangeParamFormat } = this.state;
        dateRangeParamFormat.instituteID = e.target.value;
        this.setState({ dateRangeParamFormat });
        errors["instituteID"] = "";
    }

    onSubmitHandler = () => {
        let { dateRangeParamFormat } = this.state;
        let startDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestStartDate);
        let endDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestEndDate);
        let searchQueryInfo = {
            "query": `SELECT moduleName,COUNT(*) action  FROM eduman where logDate between '${startDate}' and '${endDate}' and instituteId='${dateRangeParamFormat.instituteID}' GROUP BY moduleName, activityType`
        }
        let uniqueModuleNames = new Set();
        let instituteActionLogArr = [];
        if (this.searchHandleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.edumanActionLogService.getEmInstituteWiseActionLog(searchQueryInfo).then((body) => {

                if (body.length !== 0) {
                    for (let i = 0; i < body.length; i++) {
                        uniqueModuleNames.add(body[i].module);
                    }
                }
                for (let id of uniqueModuleNames) {
                    let moduleBody = body.filter(b => b.module == id);
                    if (moduleBody != undefined) {
                        instituteActionLogArr.push(moduleBody[moduleBody.length - 1]);
                    }
                }

                if (instituteActionLogArr.length !== 0) {
                    totalSearchActions = instituteActionLogArr.map(item => item.Fetch || 0).reduce((a, b) => a + b);
                    totalInsertActions = instituteActionLogArr.map(item => item.Insert || 0).reduce((a, b) => a + b);
                    totalUpdateActions = instituteActionLogArr.map(item => item.Update || 0).reduce((a, b) => a + b);
                    totalDeleteActions = instituteActionLogArr.map(item => item.Delete || 0).reduce((a, b) => a + b);
                    totalDownloadActions = instituteActionLogArr.map(item => item.Download || 0).reduce((a, b) => a + b);
                }

                this.setState({ dataTableValue: instituteActionLogArr });

                // for pdf 
                let instUsedModuleInfo = [];
                let totalfooterArray = []
                let totalSearch = 0; let totalInsert = 0; let totalUpdate = 0; let totalDelete = 0; let totalDownload = 0; let totalAction = 0;

                instituteActionLogArr.forEach(moduleInfo => {

                    let FetchAction = 0; let insertAction = 0; let updateAction = 0; let deleteAction = 0; let uploadAction = 0; let downloadAction = 0;

                    if (moduleInfo.Fetch == undefined) { FetchAction = 0; } else { FetchAction = moduleInfo.Fetch }
                    if (moduleInfo.Insert == undefined) { insertAction = 0 } else { insertAction = moduleInfo.Insert }
                    if (moduleInfo.Update == undefined) { updateAction = 0 } else { updateAction = moduleInfo.Update }
                    if (moduleInfo.Delete == undefined) { deleteAction = 0 } else { deleteAction = moduleInfo.Delete }
                    if (moduleInfo.Upload == undefined) { uploadAction = 0 } else { uploadAction = moduleInfo.Upload }
                    if (moduleInfo.Download == undefined) { downloadAction = 0 } else { downloadAction = moduleInfo.Download }

                    totalAction += FetchAction + insertAction + updateAction + deleteAction + uploadAction + downloadAction;
                    totalSearch += FetchAction;
                    totalInsert += insertAction;
                    totalUpdate += updateAction;
                    totalDelete += deleteAction;
                    totalDownload += downloadAction;

                    let myDesireFormatObj = {
                        module: moduleInfo.module,
                        Fetch: FetchAction,
                        Insert: insertAction,
                        Update: updateAction,
                        Delete: deleteAction,
                        Upload: uploadAction,
                        Download: downloadAction,
                        numOfaction: totalAction
                    }
                    instUsedModuleInfo.push(myDesireFormatObj);
                });

                totalfooterArray = [
                    {
                        id: "1", totalPdf: "Total", totalAmount1: totalAction, totalAmount2: totalSearch, totalAmount3: totalInsert, totalAmount4: totalUpdate, totalAmount5: totalDelete, totalAmount6: totalDownload,
                    }
                ]
                this.setState({ pdfDataTable: instUsedModuleInfo });
                this.setState({ pdfFooterArray: totalfooterArray });

                // end for pdf

                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });

            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
        }
    }

    searchHandleError = () => {
        let { errors, dateRangeParamFormat } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (dateRangeParamFormat.requestStartDate === '') {
            formIsValid = false;
            errors["requestStartDate"] = "Start Date can't left empty.";
        }
        if (dateRangeParamFormat.requestEndDate === '') {
            formIsValid = false;
            errors["requestEndDate"] = "End Date can't left empty.";
        }
        if (dateRangeParamFormat.instituteID === '') {
            formIsValid = false;
            errors["instituteID"] = "Institute ID can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onChangeStartDate = (e) => {
        let { dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        this.clearDateError(e.target.name);

    }

    bodyPercentageTemplate = (rowData) => {
        return (<span>{((rowData['actions'] * 100) / totalActions).toFixed(2) + '%'}</span>);

    }

    bodyNoOfActionsTemplate = (rowData) => {
        return (<span>{(rowData['Fetch'] !== undefined ? rowData['Fetch'] : 0) + (rowData['Update'] !== undefined ? rowData['Update'] : 0) + (rowData['Insert'] !== undefined ? rowData['Insert'] : 0) + (rowData['Delete'] !== undefined ? rowData['Delete'] : 0) + (rowData['Download'] !== undefined ? rowData['Download'] : 0)}</span>);
    }

    bodyFetchTemplate = (rowData) => {
        let moduleName = Object.entries(rowData)[0] && Object.entries(rowData)[0][1];
        let activityTypeFetch = Object.entries(rowData)[3] && Object.entries(rowData)[3][0];
        return (<span onClick={e => this.viewActionLogDetailsDialog(moduleName, activityTypeFetch, 'Fetch', e)}><Link to="#"><u>{rowData['Fetch'] + ' ' + '(' + ((rowData['Fetch'] * 100) / totalSearchActions).toFixed(2) + '%' + ')'}</u></Link></span>);
    }

    bodyInsertTemplate = (rowData) => {
        let moduleName = Object.entries(rowData)[0] && Object.entries(rowData)[0][1];
        let activityTypeFetch = Object.entries(rowData)[2] && Object.entries(rowData)[2][0];
        return (<span onClick={e => this.viewActionLogDetailsDialog(moduleName, activityTypeFetch, 'Insert', e)}><Link to="#"><u>{rowData['Insert'] + ' ' + '(' + ((rowData['Insert'] * 100) / totalInsertActions).toFixed(2) + '%' + ')'}</u></Link></span>);
    }

    bodyUpdateTemplate = (rowData) => {
        let moduleName = Object.entries(rowData)[0] && Object.entries(rowData)[0][1];
        let activityTypeFetch = Object.entries(rowData)[1] && Object.entries(rowData)[1][0];
        return (<span onClick={e => this.viewActionLogDetailsDialog(moduleName, activityTypeFetch, 'Update', e)}><Link to="#"><u>{rowData['Update'] + ' ' + '(' + ((rowData['Update'] * 100) / totalUpdateActions).toFixed(2) + '%' + ')'}</u></Link></span>);
    }

    bodyDeleteTemplate = (rowData) => {
        let moduleName = Object.entries(rowData)[0] && Object.entries(rowData)[0][1];
        let activityTypeFetch = Object.entries(rowData)[5] && Object.entries(rowData)[5][0];
        return (<span onClick={e => this.viewActionLogDetailsDialog(moduleName, activityTypeFetch, 'Delete', e)}><Link to="#"><u>{rowData['Delete'] ? rowData['Delete'] + ' ' + '(' + ((rowData['Delete'] * 100) / totalDeleteActions).toFixed(2) + '%' + ')' : 0}</u></Link></span>);
    }

    bodyDownloadTemplate = (rowData) => {
        let moduleName = Object.entries(rowData)[0] && Object.entries(rowData)[0][1];
        let activityTypeFetch = Object.entries(rowData)[4] && Object.entries(rowData)[4][0];
        return (<span onClick={e => this.viewActionLogDetailsDialog(moduleName, activityTypeFetch, 'Download', e)}><Link to="#"><u>{rowData['Download'] ? rowData['Download'] + ' ' + '(' + ((rowData['Download'] * 100) / totalDownloadActions).toFixed(2) + '%' + ')' : 0}</u></Link></span>);
    }

    viewActionLogDetailsDialog = (moduleName, activityTypeFetch, actionName) => {

        selectedActionNm = actionName;
        this.CommonFuctionality.blurDialogBackgroundActive();

        this.setState({ dialogDataTableValue: [] });
        this.setState({ dialogModuleName: moduleName, dialogActivityType: activityTypeFetch });
        this.setState({ dialogDataTableIsLoading: true, visible: true })
        this.setState({ dialogDataTableIsLoading: false })
        let { dateRangeParamFormat } = this.state;
        let startDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestStartDate);
        let endDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestEndDate);
        let searchQueryInfo = {
            "query": `SELECT logTime, pageName, userId, userIp, browser, os FROM eduman where instituteId='${dateRangeParamFormat.instituteID}' and logDate between '${startDate}' and '${endDate}' and activityType='${activityTypeFetch}' and moduleName='${moduleName}'`
        }
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.edumanActionLogService.getEmModuleActionTypeWiseDetailsLog(searchQueryInfo).then((body) => {

            body.map(item => { item.dateTime = NetiDateUtils.getAnyShortForm(item.dateTime, 'DD-MMM-YYYY hh:mm:ss a'); })
            this.setState({ dialogDataTableValue: body });
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });

        }).catch(error => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
        });

    }

    onHide = (event) => {
        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visible: false });
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Module", dataKey: "module" },
            { title: "No. of Action", dataKey: "numOfaction" },
            { title: "Search", dataKey: "Fetch" },
            { title: "Insert", dataKey: "Insert" },
            { title: "Update", dataKey: "Update" },
            { title: "Delete", dataKey: "Delete" },
            { title: "Download", dataKey: "Download" },
        ]

        let tableColStyle = {
            module: { cellWidth: 57 },
            numOfaction: { cellWidth: 51.7 },
            Fetch: { cellWidth: 31 },
            Insert: { cellWidth: 26.5 },
            Update: { cellWidth: 29 },
            Delete: { cellWidth: 28.5 },
            Download: { cellWidth: 41.5 },
        }

        let totalColumns = [
            { dataKey: "totalPdf" }, { dataKey: "totalAmount1" }, { dataKey: "totalAmount2" }, { dataKey: "totalAmount3" }, { dataKey: "totalAmount4" }, { dataKey: "totalAmount5" }, { dataKey: "totalAmount6" }
        ];

        let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, cellWidth: 57, fontStyle: "bold", overflow: "linebreak" },
            totalAmount1: { halign: "right", fontStyle: "bold", fontSize: 10, cellWidth: 51.7 },
            totalAmount2: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 31 },
            totalAmount3: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 26.5 },
            totalAmount4: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 29 },
            totalAmount5: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 28.5 },
            totalAmount6: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 41.5 },
        }

        pdfDownloadLandscape_FullTable("EM Institute Wise Action Log Details", 110, subTitleColumn, subTitleList, pdfColumns, this.state.pdfDataTable, tableColStyle, totalColumns, this.state.pdfFooterArray, totalColumnStyles, "EM_Institute_Module_Wise_Action_Log_Details.pdf");

    }

    exportPdfOfDialog() {

        let tableHeader =  this.state.dialogModuleName +" Module Action Log Details";
        let pdfColumns = [
            { title: "Date & Time", dataKey: "dateTime" },
            { title: "Page Name", dataKey: "pageName" },
            { title: "User Name", dataKey: "userName" },
            { title: "User IP", dataKey: "userIP" },
            { title: "Browser", dataKey: "browser" },
            { title: "Operating System", dataKey: "os" },
        ]

        let tableColStyle = {}

        pdfDownloadPortrait_TableWithSubTitle(tableHeader, 64, dialogSubTitleColumn, dialogSubTitleList, pdfColumns, this.state.dialogDataTableValue, tableColStyle, "EM_Institute_Wise_Action_Log_Details.pdf");

    }

    render() {

        let { dateRangeParamFormat, dataTableValue, dialogDataTableValue, dialogModuleName, dialogActivityType } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        let instituteID = '';

        if (dateRangeParamFormat.requestStartDate && dateRangeParamFormat.requestEndDate) {
            formatedStartDate = (dateRangeParamFormat.requestStartDate.toLocaleDateString('en-GB'));
            formatedEndDate = (dateRangeParamFormat.requestEndDate.toLocaleDateString('en-GB'));
            instituteID = dateRangeParamFormat.instituteID;
        }
        subTitleColumn = [
            { dataKey: "A" }, { dataKey: "B" },
            { dataKey: "C" }, { dataKey: "D" },
            { dataKey: "E" }, { dataKey: "F" },
        ];
        subTitleList = [
            { A: "From Date", B: formatedStartDate, C: "To Date", D: formatedEndDate, E: "Institute ID", F: instituteID, }
        ];

        dialogSubTitleColumn = [
            { dataKey: "A" }, { dataKey: "B" },
            { dataKey: "C" }, { dataKey: "D" }
        ];
        dialogSubTitleList = [
            { A: "Action Type", B: selectedActionNm, C: "Institute ID", D: instituteID, }
        ];
        // pdf reletaed task end

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Institute Wise Action Log Details</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let dialogHeader = <div className="header-title">
            <div className="header-title-left">{dialogModuleName} Module {selectedActionNm} Action Log Details</div><div className="header-title-right"><a>Total Found: {dialogDataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let totalActions = totalSearchActions + totalInsertActions + totalUpdateActions + totalDeleteActions + totalDownloadActions;
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="Total" />
                <Column footer={totalActions ? totalActions.toLocaleString('EN-IN') : 0} style={{ textAlign: 'left' }} />
                <Column footer={totalSearchActions ? totalSearchActions.toLocaleString('EN-IN') : 0} style={{ textAlign: 'left' }} />
                <Column footer={totalInsertActions ? totalInsertActions.toLocaleString('EN-IN') : 0} style={{ textAlign: 'left' }} />
                <Column footer={totalUpdateActions ? totalUpdateActions.toLocaleString('EN-IN') : 0} style={{ textAlign: 'left' }} />
                <Column footer={totalDeleteActions ? totalDeleteActions.toLocaleString('EN-IN') : 0} style={{ textAlign: 'left' }} />
                <Column footer={totalDownloadActions ? totalDownloadActions.toLocaleString('EN-IN') : 0} style={{ textAlign: 'left' }} />
            </Row>
        </ColumnGroup>;
        const actionTypes = [
            { label: 'Insert', value: 'Insert' },
            { label: 'Upload', value: 'Upload' },
            { label: 'Update', value: 'Update' },
            { label: 'Fetch', value: 'Fetch' },
            { label: 'Delete', value: 'Delete' },
            { label: 'Download', value: 'Download' },
            { label: 'Login', value: 'Login' },
            { label: 'Logout', value: 'Logout' },
        ]

        return (
            <div className="p-fluid">
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <Growl ref={(el) => this.growl = el} />
                                {topProgressBar ?
                                    <ErrorMessageView
                                        topProgressBar={topProgressBar}
                                    />
                                    : null
                                }
                                {errorMsgVisible ?
                                    <ErrorMessageView
                                        errorMsgVisible={errorMsgVisible}
                                        errorMsgBody={errorMsgBody}
                                    />
                                    : null
                                }
                                <div className="p-col-12 p-lg-12 p-xl-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">START DATE <span>*</span></span>
                                        <Calendar
                                            name='requestStartDate'
                                            value={dateRangeParamFormat.requestStartDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["requestStartDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">END DATE <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            name='requestEndDate'
                                            value={dateRangeParamFormat.requestEndDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            dateFormat='dd/mm/yy'
                                            yearRange="2010:2030"
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["requestEndDate"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                        <InputText
                                            placeholder="Enter Institute ID"
                                            name="instituteID"
                                            value={dateRangeParamFormat.instituteID}
                                            onChange={this.onChangeInstituteID}
                                            autoWidth={false}
                                        />
                                    </div>
                                    <span className='error-message'>{this.state.errors["instituteID"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-data-table">
                                        {this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader() :
                                            <DataTable
                                                value={this.state.dataTableValue}
                                                selectionMode="single"
                                                header={tableHeader}
                                                selection={this.state.dataTableSelection}
                                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                responsive={true}
                                                footerColumnGroup={footerGroup}
                                            >
                                                <Column field="module" header="Module" sortable={true} />
                                                <Column field="" header="No. of Action" body={this.bodyNoOfActionsTemplate} sortable={true} />
                                                <Column field="Fetch" header="Search" body={this.bodyFetchTemplate} sortable={true} />
                                                <Column field="Insert" header="Insert" body={this.bodyInsertTemplate} sortable={true} />
                                                <Column field="Update" header="Update" body={this.bodyUpdateTemplate} sortable={true} />
                                                <Column field="Delete" header="Delete" body={this.bodyDeleteTemplate} sortable={true} />
                                                <Column field="Download" header="Download" body={this.bodyDownloadTemplate} sortable={true} />
                                                {/* <Column field="" header="Percentage" body={this.bodyPercentageTemplate}  sortable={true} /> */}
                                            </DataTable>

                                        }
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-button-parent p-r-0">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Download PDF"
                                            icon="fas fa-download"
                                            onClick={this.exportPdf}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="dialog-section">
                    <Dialog className="nw-dialog" header='Action Log Details' style={{ width: '70vw' }} visible={this.state.visible} onHide={this.onHide} maximizable>

                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 nw-data-table">
                                    {this.state.dialogDataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            header={dialogHeader}
                                            value={this.state.dialogDataTableValue}
                                            selectionMode="single"
                                            selection={this.state.dataTableSelection}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            paginator={true}
                                            // rowsPerPageOptions={[20, 50]}
                                            rows={10}
                                        >
                                            <Column field="dateTime" header="Date & Time" sortable={true} filter={true} style={{ width: '110px' }} />
                                            <Column field="pageName" header="Page Name" sortable={true} filter={true} />
                                            <Column field="userName" header="User Name" sortable={true} filter={true} />
                                            <Column field="userIP" header="User IP" sortable={true} filter={true} />
                                            <Column field="browser" header="Browser" sortable={true} filter={true} />
                                            <Column field="os" header="Operating System" sortable={true} filter={true} />
                                        </DataTable>
                                    }
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-button-parent p-r-0">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Download PDF"
                                            icon="fas fa-download"
                                            onClick={this.exportPdfOfDialog}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Dialog>

                </div>

            </div >
        );
    }
}