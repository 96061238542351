import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { WalletBalance } from '../myPoint/common/WalletBalance';
import { Growl } from 'primereact/growl';
import { MessageTransferInfoList } from './MessageTransferInfoList';
import { Dropdown } from 'primereact/dropdown';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { ProfileService } from '../../service/profile/ProfileService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';

let totalMessageBalance = 0;
export class MessageTransfer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            payabaleInstituteList: [],
            messageTypeList: [],
            checkedRetriveObj: {},
            payableInstituteID: '',
            instituteSmsBalance: '',
            payableInstituteInfo: {},
            messageTransferError: {},
            messageTransferInfo: {
                quantity: '',
                note: ''
            },
            messagetypeInfoList: {},
            OTPcode: '',
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            isTableViewable: true
        }

        this.partnerPointService = new PartnerPointService();
        this.profileService = new ProfileService();
        this.netiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.getPayableInstituteList();
        //this.getMessageTypeList();
    }

    getPayableInstituteList = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.partnerPointService.getInstituteByPartner()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false, payabaleInstituteList: body });
                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })

                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check your connection.' })
            });
    }

    getInstituteBalance(payableInstituteInfo) {
        //let { payableInstituteInfo } = this.state
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.partnerPointService.getInstituteBalanceByInstitute(payableInstituteInfo.instituteId)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ topProgressBar: false, instituteSmsBalance: body.messageBalance });

                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })

                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
            });
    }

    onChangePayableInstitute = (event) => {
        this.setState({ instituteSmsBalance: '' })
        let { payableInstituteID, payabaleInstituteList, payableInstituteInfo, messageTransferError } = this.state;
        for (let i = 0; i < payabaleInstituteList.length; i++) {
            if (payabaleInstituteList[i].emDetailsId == event.target.value) {
                payableInstituteInfo = payabaleInstituteList[i];
                this.setState({ payableInstituteInfo });
                this.getInstituteBalance(payableInstituteInfo);
            }
        }
        this.setState({ payableInstituteID: event.target.value });

        messageTransferError['institute'] = ''
        this.setState({ messageTransferError });

    }

    // getMessageTypeList = () => {
    //     this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false });
    //     let { messageTypeList } = this.state;
    //     this.partnerPointService.getProductTypeByDefCode('C104006')
    //         .then(res => {
    //             if (res.status == 302) {
    //                 return res.json().then((body) => {
    //                     this.setState({ messageTypeList: body });
    //                     this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: false });
    //                 });
    //             } else {
    //                 this.partnerPointService.Auth.handleErrorStatus(res)
    //                     .then((responseBody) => {
    //                         this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
    //                     });
    //             }
    //         }).catch(error => {
    //             this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check your connection.' })
    //         });

    // }

    // onChangeMessageType = (event) => {
    //     let { messageTransferInfo, messageTypeList, messagetypeInfoList } = this.state;
    //     messageTransferInfo.productId = event.target.value;
    //     this.setState({ messageTransferInfo });
    //     for (let i = 0; i < messageTypeList.length; i++) {
    //         if (messageTypeList[i].productID == event.target.value) {
    //             messagetypeInfoList = messageTypeList[i];
    //             this.setState({ messagetypeInfoList });

    //         }
    //     }
    // }

    onChangeMessageQuantity = (e) => {
        let { messageTransferInfo, messageTransferError } = this.state;
        messageTransferInfo.quantity = e.target.value;
        this.setState({ messageTransferInfo });

        messageTransferError['messageQuantity'] = ''
        this.setState({ messageTransferError });
    }

    onChangeMessageNote = (e) => {
        let { messageTransferInfo, messageTransferError } = this.state;
        messageTransferInfo.note = e.target.value;
        this.setState({ messageTransferInfo });

        messageTransferError['requestNote'] = ''
        this.setState({ messageTransferError });
    }


    onTransferBtn = (e) => {
        let { messageTransferInfo, messagetypeInfoList, payableInstituteInfo } = this.state;
        //messageTransferInfo.productDefaultCode = messagetypeInfoList.productDefaultCode;
        messageTransferInfo.emDetailsId = payableInstituteInfo.emDetailsId;
        messageTransferInfo.instituteId = payableInstituteInfo.instituteId;
        if (this.messageTransferFormError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.partnerPointService.sendPartnerMessageTransferRequest(messageTransferInfo)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json().then((body) => {
                            this.setState({ checkedRetriveObj: body });
                            this.growl.show({ severity: "success", summary: "Success Message", detail: 'Message successfully Transferred.' });
                            // setTimeout(() => {
                            //     this.viewDialog(e)
                            // }, 800);
                            this.setState({
                                messageTransferInfo: {
                                    quantity: '',
                                    note: ''
                                },
                                payableInstituteID: '',
                                payableInstituteInfo: {},
                            });
                            this.setState({ instituteSmsBalance: '' })
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });

                        });

                    } else {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Opps! Something went wrong!' })
                        // this.partnerPointService.Auth.handleErrorStatus(res)
                        //     .then((responseBody) => {
                        //         this.setState({ topProgressBar: false, dataTableIsLoading: false, });
                        //         this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        //     });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
                });
        }
    }

    // onTransferBtn = (e) => {
    //     this.profileService.checkOPTCode(this.state.OTPcode)
    //         .then((res) => {
    //             if (res.status == 200) {
    //                 this.submitMessageTransfer(e);
    //             } else {
    //                 return res.json().then((body) => {
    //                     this.growl.show({ severity: 'error', summary: 'Error Message', detail: body.message });
    //                 });
    //             }

    //         }).catch(error => {
    //             this.setState({ dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error })
    //         });

    // }

    // submitMessageTransfer = (e) => {
    //     let { messageTransferInfo } = this.state;

    //     this.partnerPointService.sendPartnerMessageTransferRequest(messageTransferInfo)
    //         .then((res) => {
    //             if (res.status == 200) {
    //                 this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully submitted' });
    //                 this.onHide(e);
    //                 this.refs.messageTransferInfoList.viewMessageTransferList();
    //             } else {
    //                 return res.json().then((body) => {
    //                     this.growl.show({ severity: 'error', summary: 'Error Message', detail: body.message });
    //                 });
    //             }
    //         }).catch(error => {
    //             this.setState({ dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
    //         });

    // }

    // viewDialog = (e) => {
    //     this.setState({ visible: true });
    // }

    // onHide = (event) => {
    //     this.setState({ visible: false });
    // }

    // // onChangeOTPcode = (e) => {
    // //     this.setState({
    // //         OTPcode: e.target.value
    // //     });
    // // }

    // submitBalanceTransfer = (e) => {
    //     this.onHide(e);
    // }

    messageTransferFormError = () => {
        let { messageTransferError } = this.state;
        let formIsValid = true;

        if (!this.state.payableInstituteID) {
            formIsValid = false;
            messageTransferError["institute"] = "Institute can't left empty";
        }
        // if (!this.state.messageTransferInfo.productId) {
        //     formIsValid = false;
        //     messageTransferError["messageType"] = "Message Type can't left empty";
        // }
        if (!this.state.messageTransferInfo.quantity || this.state.messageTransferInfo.quantity == 0) {
            formIsValid = false;
            messageTransferError["messageQuantity"] = "Message Quantity can't left empty";
            
        } else if ((this.state.messageTransferInfo.quantity > totalMessageBalance) || totalMessageBalance <= 5) {
            formIsValid = false;
            messageTransferError["messageQuantity"] = "Insufficient Message Balance";
        }

        if (this.state.messageTransferInfo.note === '') {
            formIsValid = false;
            messageTransferError["requestNote"] = "Note can't left empty";
        }
        this.setState({ messageTransferError });
        return formIsValid;
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { messageTransferError, messageTransferInfo } = this.state;
        let payabaleInstitute = [];
        if ((this.state.payabaleInstituteList != null) && (this.state.payabaleInstituteList.length > 0)) {
            payabaleInstitute = this.state.payabaleInstituteList.map((item) => ({
                value: item.emDetailsId,
                label: item.instituteId + " - " + item.instituteName,
            }));
        }

        let messageList = [];
        if ((this.state.messageTypeList != null) && (this.state.messageTypeList.length > 0)) {
            messageList = this.state.messageTypeList.map((item) => ({
                value: item.productID,
                label: item.productName,
            }));
        }



        totalMessageBalance = this.refs.walletBalance && this.refs.walletBalance.state && this.refs.walletBalance.state.userWalletBalance && this.refs.walletBalance.state.userWalletBalance.smsBalance;

        

        return (

            <div className="p-fluid">
                {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                        <div className="p-grid seprator-inside">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        {this.state.firstDropdownIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institute Name <span>*</span></span>
                                                <Dropdown
                                                    value={this.state.payableInstituteID}
                                                    options={payabaleInstitute}
                                                    onChange={this.onChangePayableInstitute}
                                                    placeholder="Select Institute"
                                                    showClear={true}
                                                    filter={true}
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    name="institute"
                                                />
                                            </div>
                                        }
                                        <div>
                                            <span style={{ float: 'right' }}>{"Institute SMS Balance : " + (this.state.instituteSmsBalance || 0)}</span> <br />
                                            <span className="error-message">{messageTransferError["institute"]}</span>
                                        </div>
                                    </div>

                                    

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Message Quantity  <span>*</span></span>
                                            <InputText
                                                value={messageTransferInfo.quantity}
                                                keyfilter="pint"
                                                onChange={this.onChangeMessageQuantity}
                                                placeholder="Enter Message Quantity"
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="messageQuantity"
                                                // tooltip="Minimum balance of message 5"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.messageTransferError["messageQuantity"]}</span>

                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Note  <span>*</span></span>
                                            <InputTextarea
                                                value={messageTransferInfo.note}
                                                onChange={this.onChangeMessageNote}
                                                placeholder="Enter Note"
                                                rows={3}
                                                cols={30}
                                                autoResize={true}
                                                name="requestNote"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.messageTransferError["requestNote"]}</span>

                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent" />


                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Transfer"
                                            icon="fas fa-arrow-right"
                                            iconPos="right"
                                            onClick={this.onTransferBtn}
                                            disabled={this.state.payableInstituteID ? false : true}
                                        />
                                    </div>
                                   

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-xl-4">
                        <div className="layout-dashboard">
                            {!this.state.dataTableIsLoading ?
                                <WalletBalance
                                    messageBalanceName='Message' 
                                    ref="walletBalance"
                                />
                                : ''
                            }
                        </div>
                    </div>

                    <div className="p-col-12">
                        {this.state.dataTableIsLoading ? this.netiContentLoader.MyPointTableLoader() :
                            <MessageTransferInfoList ref="messageTransferInfoList" />
                        }
                    </div>

                    {/* <Dialog className="text-center" header="Balance Wallet Transfer" visible={this.state.visible} style={{ width: '50vw' }} onHide={this.onHide} maximizable>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-12 text-center">
                                    <span>A 6-digit verification code has been sent to '{this.state.checkedRetriveObj.contactNo}' contact number.</span>
                                </div>
                                <div className="p-col-12 p-md-12 text-center">
                                    <span>OTP Code : </span>
                                    <InputText
                                        onChange={this.onChangeOTPcode}
                                        keyfilter="pint"
                                        value={this.state.OTPcode}
                                        placeholder="Enter OTP Code"
                                        style={{ width: "50%" }}
                                        type="text"
                                    />
                                </div>
                                <div className="p-col-12 p-md-4"></div>
                                <div className="p-col-12 p-md-4 nw-button-parent">
                                    <Button
                                        className="p-button p-component p-button-success p-button-text-only"
                                        label="Transfer"
                                        onClick={this.onTransferBtn}
                                    />
                                </div>
                                <div className="p-col-12 p-md-4"></div>
                            </div>
                        </div>

                    </Dialog> */}


                </div>
            </div>
        );
    }
}