export const primaryChartsColorArray = [
    {color: '#6079FF'},
    {color: '#0099FF'},
    {color: '#6dffac'},
    {color: '#53ACBD'},
    {color: '#6D3B7B'},
    {color: '#473D35'},
    {color: '#D36268'},
    {color: '#FBC4A3'},
    {color: '#F8EB32'},
    {color: '#ACCAC1'},
    {color: '#7D2D71'},
    {color: '#B2ACAB'},
    {color: '#B66945'},
    {color: '#E8542F'},
    {color: '#AEC96A'},
    {color: '#90A5CDi'},
    {color: '#C05D98'},
    {color: '#918A90'},
    {color: '#D45338'},
    {color: '#F18D2F'},
    {color: '#D7DD51'},
    {color: '#4D9DC8'},
    {color: '#DAB3BC'},
    {color: '#E2D8D6'},
    {color: '#E85054'},
    {color: '#F49C2F'},
    {color: '#4C4441'},
    {color: '#E76082'},
    {color: '#F7C691'},
    {color: '#3A9241'},
    {color: '#385B82'},
    {color: '#EBA72E'},
    {color: '#4C4441'},
    {color: '#E76082'},
    {color: '#F7C691'},
    {color: '#3EC149'},
    {color: '#38406B'},
    {color: '#AD9669'},
    {color: '#513322'},
    {color: '#D36268'},
    {color: '#FBE11F'},
    {color: '#556530'},
    {color: '#4657B1'},
    {color: '#AC6046'},
    {color: '#1A1915'},
    {color: '#E72983'},
    {color: '#FFF675'},
    {color: '#C2EE60'},
    {color: '#4B2B38'},
    {color: '#675348'},    
]

export const dangerChartsColorArray = [
    {color: '#F6B0AE'},
    {color: '#F4857E'}, 
    {color: '#F46159'},
    {color: '#F24326'},
    {color: '#DC4029'},
    {color: '#BC3B28'},
    {color: '#782E23'},
    {color: '#F6A0A3'},
    {color: '#F65259'},
    {color: '#F5868D'},
    {color: '#F96F6D'},
    {color: '#AA2826'},
    {color: '#CF2B29'},
    {color: '#78231E'},
    {color: '#F6B4B8'},
    {color: '#F76975'},
    {color: '#ED434E'},
    {color: '#E72F2D'},
    {color: '#C63031'},
    {color: '#9C3234'},
    {color: '#EE4A6C'},
    {color: '#BE0C77'},
    {color: '#FF436F'},
    {color: '#B80D8A'},
    {color: '#DF152D'},
    {color: '#C71427'},
    {color: '#A9212D'},
    {color: '#782328'},
    {color: '#FBA5B2'},
    {color: '#F7778E'},
    {color: '#D14B66'},
    {color: '#DD093B'},
    {color: '#B80D2F'},
    {color: '#942335'},
    {color: '#742E36'},
    {color: '#F9C0C9'},
    {color: '#F99DB2'},
    {color: '#EE567C'},
    {color: '#D80B46'},
    {color: '#BA0838'},
    {color: '#A30E34'},
    {color: '#8E1838'},
    {color: '#F2CACA'},
    {color: '#EC9BA2'},
    {color: '#E0586C'},
    {color: '#D11F3F'},
    {color: '#BE223A'},
    {color: '#9E2838'},
    {color: '#882833'},
    {color: '#CB0946'}
]

export const AttendancerChartsColorArray = [
    {color: '#D7DF23'},
    {color: '#8DC63F'}, 
    {color: '#7BC408'},
    {color: '#39B54A'},
    {color: '#0FA31D'},
    {color: '#009444'},
    {color: '#047A37'},
    {color: '#02682C'},
    {color: '#024C20'},
    {color: '#003817'},
    {color: '#F96F6D'},
    {color: '#AA2826'},
    {color: '#CF2B29'},
    {color: '#78231E'},
    {color: '#F6B4B8'},
    {color: '#F76975'},
    {color: '#ED434E'},
    {color: '#E72F2D'},
    {color: '#C63031'},
    {color: '#9C3234'},
    {color: '#EE4A6C'},
    {color: '#BE0C77'},
    {color: '#FF436F'},
    {color: '#B80D8A'},
    {color: '#DF152D'},
    {color: '#C71427'},
    {color: '#A9212D'},
    {color: '#782328'},
    {color: '#FBA5B2'},
    {color: '#F7778E'},
    {color: '#D14B66'},
    {color: '#DD093B'},
    {color: '#B80D2F'},
    {color: '#942335'},
    {color: '#742E36'},
    {color: '#F9C0C9'},
    {color: '#F99DB2'},
    {color: '#EE567C'},
    {color: '#D80B46'},
    {color: '#BA0838'},
    {color: '#A30E34'},
    {color: '#8E1838'},
    {color: '#F2CACA'},
    {color: '#EC9BA2'},
    {color: '#E0586C'},
    {color: '#D11F3F'},
    {color: '#BE223A'},
    {color: '#9E2838'},
    {color: '#882833'},
    {color: '#CB0946'}
]