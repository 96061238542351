import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Chart } from "react-google-charts";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { PartnerPointService } from '../../../service/partnerPoint/PartnerPointService';
import { primaryChartsColorArray, dangerChartsColorArray } from '../../common/ChartsColorArray';


let emTotalIDArray = [];
let emTotalID = 0;

let emTotalLiveIDArray = [];
let emTotalLiveID = 0;

let emPackageWiseActiveArray = [];
let emTotalPackageWiseActive = 0;

let emPackageWiseInActiveStatusFinal = [];
let emTotalPackageWiseInActiveStatus = 0;

let emInstiuteTypeWiseActiveArray = [];
let emTotalInstiuteTypeWiseActive = 0;

let emInstiuteTypeWiseInActiveArray = [];
let emTotalInstiuteTypeWiseInActive = 0;

let emTypeWiseActiveArray = [];
let emTotalTypeWiseActive = 0;

let emTypeWiseInActiveArray = [];
let emTotalTypeWiseInActive = 0;

let emBoardWiseActiveArray = [];
let emTotalBoardWiseActive = 0;

let emBoardWiseInActiveArray = [];
let emTotalBoardWiseInActive = 0;



export class EmCurrentStatus extends Component {
    constructor() {
        super();
        this.state = {
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            emTotalID: [],
            emLiveTotalID: [],
            packageWiseActiveInstitute: [],
            packageWiseInActiveInstitute: [],
            instituteTypeWiseActiveInstitute: [],
            instituteTypeWiseInActiveInstitute: [],
            typeWiseActiveInstitute: [],
            typeWiseInActiveInstitute: [],
            boardWiseActiveInstitute: [],
            boardWiseInActiveInstitute: [],
            divisionWiseActiveInstitute: [],
            divisionWiseInActiveInstitute: [],
            regionWiseActiveInstitute: [],
            regionWiseInActiveInstitute: [],
            regionWiseActiveDuebillInstitute: [],
            regionWiseInActiveDuebillInstitute: []
        }

        this.partnerPointService = new PartnerPointService();
    }

    componentWillMount() {
        this.getEmCurrentStatusDashboardDetails();
    }

    getEmCurrentStatusDashboardDetails() {
        this.setState({ topProgressBar: true, errorMsgBody: false });
        this.partnerPointService.getEdumanCurrentStatusInfo()
            .then(res => {
                if (res.status === 302) {
                    return res.json().then(body => {
                        let chartColumnHeader = ["title", "count"];
                        
                        emTotalIDArray.push(chartColumnHeader, ...body.usedTypeWiseInstituteDashBoard);
                        emTotalID = body.usedTypeWiseInstituteDashBoard.map(item => item[1]).reduce((a, b) => (a + b));

                        emTotalLiveIDArray.push(chartColumnHeader, ...body.statusWiseInstituteDashBoard);
                        emTotalLiveID = body.statusWiseInstituteDashBoard.map(item => item[1]).reduce((a, b) => (a + b));

                        emPackageWiseActiveArray.push(chartColumnHeader, ...body.packageWiseActiveInstituteDashBoard);
                        emTotalPackageWiseActive = body.packageWiseActiveInstituteDashBoard.map(item => item[1]).reduce((a, b) => (a + b));

                        emPackageWiseInActiveStatusFinal.push(chartColumnHeader, ...body.packageWiseInActiveInstituteDashBoard);
                        emTotalPackageWiseInActiveStatus = body.packageWiseInActiveInstituteDashBoard.map(item => item[1]).reduce((a, b) => (a + b));

                        emTypeWiseActiveArray.push(chartColumnHeader, ...body.paymentWiseActiveInstituteDashBoard);
                        emTotalTypeWiseActive = body.paymentWiseActiveInstituteDashBoard.map(item => item[1]).reduce((a, b) => (a + b));

                        emTypeWiseInActiveArray.push(chartColumnHeader, ...body.paymentWiseInActiveInstituteDashBoard);
                        emTotalTypeWiseInActive = body.paymentWiseInActiveInstituteDashBoard.map(item => item[1]).reduce((a, b) => (a + b));

                        emInstiuteTypeWiseActiveArray.push(chartColumnHeader, ...body.instituteTypeWiseActiveInstituteDashBoard);
                        emTotalInstiuteTypeWiseActive = body.instituteTypeWiseActiveInstituteDashBoard.map(item => item[1]).reduce((a, b) => (a + b));

                        emInstiuteTypeWiseInActiveArray.push(chartColumnHeader, ...body.instituteTypeWiseInActiveInstituteDashBoard);
                        emTotalInstiuteTypeWiseInActive = body.instituteTypeWiseInActiveInstituteDashBoard.map(item => item[1]).reduce((a, b) => (a + b));

                        emBoardWiseActiveArray.push(chartColumnHeader, ...body.boardWiseActiveInstituteDashBoard);
                        emTotalBoardWiseActive = body.boardWiseActiveInstituteDashBoard.map(item => item[1]).reduce((a, b) => (a + b));

                        emBoardWiseInActiveArray.push(chartColumnHeader, ...body.boardWiseInActiveInstituteDashBoard);
                        emTotalBoardWiseInActive = body.boardWiseInActiveInstituteDashBoard.map(item => item[1]).reduce((a, b) => (a + b));

                        this.setState({ topProgressBar: false, errorMsgBody: false, emTotalID: body.usedTypeWiseInstituteDashBoard, emLiveTotalID: body.statusWiseInstituteDashBoard, packageWiseActiveInstitute: body.packageWiseActiveInstituteDashBoard, packageWiseInActiveInstitute: body.packageWiseInActiveInstituteDashBoard, instituteTypeWiseActiveInstitute: body.instituteTypeWiseActiveInstituteDashBoard, instituteTypeWiseInActiveInstitute: body.instituteTypeWiseInActiveInstituteDashBoard, typeWiseActiveInstitute: body.paymentWiseActiveInstituteDashBoard, typeWiseInActiveInstitute: body.paymentWiseInActiveInstituteDashBoard, boardWiseActiveInstitute: body.boardWiseActiveInstituteDashBoard, boardWiseInActiveInstitute: body.boardWiseInActiveInstituteDashBoard, divisionWiseActiveInstitute: body.divisionWiseActiveInstituteDashBoard, divisionWiseInActiveInstitute: body.divisionWiseInActiveInstituteDashBoard, regionWiseActiveInstitute: body.regionWiseActiveInstituteDashBoard, regionWiseInActiveInstitute: body.regionWiseInActiveInstituteDashBoard, regionWiseActiveDuebillInstitute: body.regionWiseActiveDueBillDashBoard, regionWiseInActiveDuebillInstitute: body.regionWiseInActiveDueBillDashBoard });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then(resp => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section netiWorld-DashBoard-wrapper">
                    <div className="netiWorld-DashBoard">
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12 p-md-12 p-sm-12 p-t-0">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Eduman Current Status</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-0">
                                    <div className="p-col-12 p-xl-6 p-md-12 p-sm-12 p-l-0">
                                        <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={'196px'}
                                                            height={'196px'}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={emTotalIDArray}
                                                            legend={{
                                                                position: "bottom",
                                                                alignment: "center",
                                                                // position: 'labeled',
                                                                textStyle: {
                                                                    color: "233238",
                                                                    fontSize: 16
                                                                }
                                                            }}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                tooltip: { trigger: 'block', showColorCode: true },
                                                                // pieSliceText: 'none',
                                                                legend: 'none',
                                                                slices: {
                                                                    0: { color: '#2F3292' },
                                                                    1: { color: '#0099FF' },
                                                                },
                                                            }}
                                                        />

                                                    </div>
                                                    <span className="chart-box-title">Total Eduman ID</span>
                                                    <br />
                                                    <span className="chart-box-title">{emTotalID ? emTotalID.toLocaleString('EN-IN') : 0}</span>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span style={{ background: "#2F3292" }}></span><p>Live ID</p></td>
                                                                        <td><p>: {this.state.emTotalID[0] && this.state.emTotalID[0][1] ? this.state.emTotalID[0][1] : '0'}</p></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span style={{ background: "#0099FF" }}></span><p>Practice</p></td>
                                                                        <td><p>: {this.state.emTotalID[1] && this.state.emTotalID[1][1] ? this.state.emTotalID[1][1] : '0'}</p></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                        <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={'196px'}
                                                            height={'196px'}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={emTotalLiveIDArray}
                                                            legend={{
                                                                position: "bottom",
                                                                alignment: "center",
                                                                position: 'labeled',
                                                                textStyle: {
                                                                    color: "233238",
                                                                    fontSize: 16
                                                                }
                                                            }}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                tooltip: { trigger: 'block', showColorCode: true },
                                                                // pieSliceText: 'none',
                                                                legend: 'none',
                                                                slices: {
                                                                    0: { color: '#27AE5F' },
                                                                    1: { color: '#FFDD00' },
                                                                    2: { color: '#FF3A27' },
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Total Eduman Live ID</span>
                                                    <br /><span className="chart-box-title">{emTotalLiveID ? emTotalLiveID.toLocaleString('EN-IN') : 0}</span></div>
                                            </div>
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span style={{ background: "#27AE5F" }}></span><p>Active</p></td>
                                                                        <td><p>: {this.state.emLiveTotalID[0] && this.state.emLiveTotalID[0][1] ? this.state.emLiveTotalID[0][1] : '0'}</p></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span style={{ background: "#FFDD00" }}></span><p>Inactive</p></td>
                                                                        <td><p>: {this.state.emLiveTotalID[1] && this.state.emLiveTotalID[1][1] ? this.state.emLiveTotalID[1][1] : '0'}</p></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span style={{ background: "#FF3A27" }}></span><p>Terminate</p></td>
                                                                        <td><p>: {this.state.emLiveTotalID[2] && this.state.emLiveTotalID[2][1] ? this.state.emLiveTotalID[2][1] : '0'}</p></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Package Wise <span className="active">Active</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                <Chart
                                                    width={'196px'}
                                                    height={'196px'}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={emPackageWiseActiveArray}
                                                    legend={{
                                                        position: "bottom",
                                                        alignment: "center",
                                                        // position: 'labeled',
                                                        textStyle: {
                                                            color: "233238",
                                                            fontSize: 16
                                                        }
                                                    }}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'block', showColorCode: true },
                                                        // pieSliceText: 'none',
                                                        legend: 'none',
                                                        slices: primaryChartsColorArray
                                                    }}
                                                />
                                            </div>
                                            <span className="chart-box-title">Total Active Eduman</span>
                                            <br /><span className="chart-box-title">{emTotalPackageWiseActive ? emTotalPackageWiseActive.toLocaleString('EN-IN') : 0}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list">
                                                    <table>
                                                        <tbody>
                                                            {this.state.packageWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p>{item[0]}</p></td>
                                                                        <td><p>: {item[1]}</p></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Package Wise <span className="inActive">Inactive</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                <Chart
                                                    width={'196px'}
                                                    height={'196px'}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={emPackageWiseInActiveStatusFinal}
                                                    legend={{
                                                        position: "bottom",
                                                        alignment: "center",
                                                        // position: 'labeled',
                                                        textStyle: {
                                                            color: "233238",
                                                            fontSize: 16
                                                        }
                                                    }}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'block', showColorCode: true },
                                                        // pieSliceText: 'none',
                                                        legend: 'none',
                                                        slices: dangerChartsColorArray,
                                                    }}
                                                />
                                            </div>
                                            <span className="chart-box-title">Total Inactive Eduman ID</span>
                                            <br /><span className="chart-box-title">{emTotalPackageWiseInActiveStatus ? emTotalPackageWiseInActiveStatus.toLocaleString('EN-IN') : 0}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list">
                                                    <table>
                                                        <tbody>
                                                            {this.state.packageWiseInActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: dangerChartsColorArray[index].color }}></span><p>{item[0]}</p></td>
                                                                        <td><p>: {item[1]}</p></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Type Wise <span className="active">Active</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                <Chart
                                                    width={'196px'}
                                                    height={'196px'}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={emTypeWiseActiveArray}
                                                    legend={{
                                                        position: "bottom",
                                                        alignment: "center",
                                                        // position: 'labeled',
                                                        textStyle: {
                                                            color: "233238",
                                                            fontSize: 16
                                                        }
                                                    }}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'block', showColorCode: true },
                                                        // pieSliceText: 'none',
                                                        legend: 'none',
                                                        slices: primaryChartsColorArray,
                                                    }}
                                                />
                                            </div>
                                            <span className="chart-box-title">Total Active Eduman</span>
                                            <br /><span className="chart-box-title">{emTotalTypeWiseActive ? emTotalTypeWiseActive.toLocaleString('EN-IN') : 0}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list">
                                                    <table>
                                                        <tbody>
                                                            {this.state.typeWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p>{item[0]}</p></td>
                                                                        <td><p>: {item[1]}</p></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Type Wise <span className="inActive">Inactive</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                <Chart
                                                    width={'196px'}
                                                    height={'196px'}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={emTypeWiseInActiveArray}
                                                    legend={{
                                                        position: "bottom",
                                                        alignment: "center",
                                                        // position: 'labeled',
                                                        textStyle: {
                                                            color: "233238",
                                                            fontSize: 16
                                                        }
                                                    }}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'block', showColorCode: true },
                                                        // pieSliceText: 'none',
                                                        legend: 'none',
                                                        slices: dangerChartsColorArray,
                                                    }}
                                                />
                                            </div>
                                            <span className="chart-box-title">Total Inactive Eduman</span>
                                            <br /><span className="chart-box-title">{emTotalTypeWiseInActive ? emTotalTypeWiseInActive.toLocaleString('EN-IN') : 0}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list">
                                                    <table>
                                                        <tbody>
                                                            {this.state.typeWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: dangerChartsColorArray[index].color }}></span><p>{item[0]}</p></td>
                                                                        <td><p>: {item[1]}</p></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Category Wise <span className="active">Active</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                <Chart
                                                    width={'196px'}
                                                    height={'196px'}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={emInstiuteTypeWiseActiveArray}
                                                    legend={{
                                                        position: "bottom",
                                                        alignment: "center",
                                                        // position: 'labeled',
                                                        textStyle: {
                                                            color: "233238",
                                                            fontSize: 16
                                                        }
                                                    }}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'block', showColorCode: true },
                                                        // pieSliceText: 'none',
                                                        legend: 'none',
                                                        slices: primaryChartsColorArray,
                                                    }}
                                                />
                                            </div>
                                            <span className="chart-box-title">Total Active Eduman</span>
                                            <br /><span className="chart-box-title">{emTotalInstiuteTypeWiseActive ? emTotalInstiuteTypeWiseActive.toLocaleString('EN-IN') : 0}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.instituteTypeWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p>{item[0]}</p></td>
                                                                        <td><p>: {item[1]}</p></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Category Wise <span className="inActive">Inactive</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                <Chart
                                                    width={'196px'}
                                                    height={'196px'}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={emInstiuteTypeWiseInActiveArray}
                                                    legend={{
                                                        position: "bottom",
                                                        alignment: "center",
                                                        // position: 'labeled',
                                                        textStyle: {
                                                            color: "233238",
                                                            fontSize: 16
                                                        }
                                                    }}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'block', showColorCode: true },
                                                        // pieSliceText: 'none',
                                                        legend: 'none',
                                                        slices: dangerChartsColorArray,
                                                    }}
                                                />
                                            </div>
                                            <span className="chart-box-title">Total Inactive Eduman ID</span>
                                            <br /><span className="chart-box-title">{emTotalInstiuteTypeWiseInActive ? emTotalInstiuteTypeWiseInActive.toLocaleString('EN-IN') : 0}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.instituteTypeWiseInActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: dangerChartsColorArray[index].color }}></span><p>{item[0]}</p></td>
                                                                        <td><p>: {item[1]}</p></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid m-t-20">
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Board Wise <span className="active">Active</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                <Chart
                                                    width={'196px'}
                                                    height={'196px'}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={emBoardWiseActiveArray}
                                                    legend={{
                                                        position: "bottom",
                                                        alignment: "center",
                                                        // position: 'labeled',
                                                        textStyle: {
                                                            color: "233238",
                                                            fontSize: 16
                                                        }
                                                    }}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'block', showColorCode: true },
                                                        // pieSliceText: 'none',
                                                        legend: 'none',
                                                        slices: primaryChartsColorArray,
                                                    }}
                                                />
                                            </div>
                                            <span className="chart-box-title">Total Active Eduman</span>
                                            <br /><span className="chart-box-title">{emTotalBoardWiseActive ? emTotalBoardWiseActive.toLocaleString('EN-IN') : 0}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.boardWiseActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p>{item[0]}</p></td>
                                                                        <td><p>: {item[1]}</p></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                <div className="panel-box p-l-0">
                                    <div className="panel-box-header">
                                        <h1>Board Wise <span className="inActive">Inactive</span> Eduman</h1>
                                    </div>
                                </div>
                                <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover">
                                            <div className="chart-box">
                                                <Chart
                                                    width={'196px'}
                                                    height={'196px'}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={emBoardWiseInActiveArray}
                                                    legend={{
                                                        position: "bottom",
                                                        alignment: "center",
                                                        // position: 'labeled',
                                                        textStyle: {
                                                            color: "233238",
                                                            fontSize: 16
                                                        }
                                                    }}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'block', showColorCode: true },
                                                        // pieSliceText: 'none',
                                                        legend: 'none',
                                                        slices: dangerChartsColorArray,
                                                    }}
                                                />
                                            </div>
                                            <span className="chart-box-title">Total Inactive Eduman ID</span>
                                            <br /><span className="chart-box-title">{emTotalBoardWiseInActive ? emTotalBoardWiseInActive.toLocaleString('EN-IN') : 0}</span></div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-6">
                                        <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                            <div className="chart-box-wrapper">
                                                <div className="point-list no-height">
                                                    <table>
                                                        <tbody>
                                                            {this.state.boardWiseInActiveInstitute.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td><span style={{ background: dangerChartsColorArray[index].color }}></span><p>{item[0]}</p></td>
                                                                        <td><p>: {item[1]}</p></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}