import { BaseURIHolder } from "../utils/BaseURIHolder";
import AuthService from "../auth/AuthService";

export class UserCoreUrlInfoService {
  constructor() {
    this.baseURIHolder = new BaseURIHolder();
    this.Auth = new AuthService();
  }

  async fetchUserRoleAssignId() {
    let url = this.baseURIHolder.getDWS() + "/neti-cms/user/role-assign-id";

    return await this.Auth.getFetch(url).catch((error) =>
      console.log("error", error)
    );
  }

  async getUrlId(url, header, bodyData) {
    return await fetch(url, {
      method: "POST",
      headers: header,
      body: JSON.stringify(bodyData),
    }).catch((error) => console.log("error", error));
  }

  async fetchCmsId() {
    let token = "neticms-web-user" + ":" + "neti-cms-web-user-pwd";
    let hash = btoa(token);
    let basicToken = "Basic " + hash;
    console.log("basicToken::::", basicToken);
    let header = {
      // 'Accept': 'application/json',
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      // "Authorization": basicToken
    };
    let bodyData = {
      roleAssignId: 0,
    };
    let url = this.baseURIHolder.getHostNetiCMS() + "/user/login";

    return this.fetchUserRoleAssignId().then((res) => {
      console.log('from userCoreUrlInfoService')
      console.log(res)
      if (res.status == 302) {
        return res.json().then((body) => {
          bodyData.roleAssignId = body.roleAssignId;

          return this.getUrlId(url, header, bodyData);
        });
      }
    });

    //     )
    // .then(res => res.json())
    // .then(body => {
    //     console.log("RES CMS body fetchUserRoleAssignId::::::", body);
    //     bodyData.roleAssignId = body.roleAssignId
    //     return fetch(url, { method: 'POST', headers: header, body: JSON.stringify(bodyData) })
    //         .catch((error) => console.log('error', error));
    //  })

    // fetch(url, { method: 'POST', headers: header, body: JSON.stringify(bodyData) })

    // return fetch(url, { method: 'POST', headers: header, body: JSON.stringify(bodyData) })
    //     .catch((error) => console.log('error', error));
  }

  fetchInstituteDetails(appType, appPackage) {
    let uri =
      this.baseURIHolder.getUser() +
      "/url-info/" +
      appType +
      "/" +
      appPackage +
      "/by/user";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  createNewUrlInfo(createUrlInfo) {
    let uri = this.baseURIHolder.getUser() + "/url-info/new/url";
    return this.Auth.postFetch(uri, createUrlInfo).catch((error) =>
      console.log("error", error)
    );
  }

  checkExistanceOfUrl(appType, appPackageName, homeUrl) {
    let uri =
      this.baseURIHolder.getUser() +
      "/url-info/"
        .concat(appType)
        .concat("/")
        .concat(appPackageName)
        .concat("/exists?urlName=")
        .concat(homeUrl);
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updateInstituteInfo(instituteInfoObject) {
    let uri = this.baseURIHolder.getUser() + "/url-info/edit/url";
    return this.Auth.putFetch(uri, instituteInfoObject).catch((error) =>
      console.log("error", error)
    );
  }

  // NetiCMS

  checkExistanceOfNetiCMSUrl(homeUrl) {
    let uri =
      this.baseURIHolder.getHostNetiCMS() +
      "/cms-info/check-url/existence?urlName=" +
      homeUrl;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  adminCreateNewUrlInfo(createUrlInfo) {
    let uri = this.baseURIHolder.getHostNetiCMS() + "/cms-info/save";
    return this.Auth.postFetch(uri, createUrlInfo).catch((error) =>
      console.log("error", error)
    );
  }

  fetchNetiCmsInstituteDetails(userRoleAssignId) {
    let uri =
      this.baseURIHolder.getHostNetiCMS() +
      "/cms-info/find/urlInfo?userRoleAssignId=" +
      userRoleAssignId;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updateNetiCmsInstituteInfo(instituteInfoObject) {
    let uri = this.baseURIHolder.getHostNetiCMS() + "/cms-info/update";
    return this.Auth.putFetch(uri, instituteInfoObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchNetiCmsMenuItems(cmsId) {
    let uri =
      this.baseURIHolder.getHostNetiCMS() +
      "/menu/item/parent-child/tree?cmsId=" +
      cmsId;
    // let uri =
    //   "http://192.168.0.7:8080/menu/item/parent-child/tree?cmsId=" + cmsId;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  //save cms menu config
  saveCmsMenuConfig(data) {
    let uri = this.baseURIHolder.getHostNetiCMS() + "/menu/config/save";
    // let uri = "http://192.168.0.7:8080/menu/config/save";
    return this.Auth.postFetch(uri, data).catch((error) =>
      console.log("error", error)
    );
  }
}
