import UnAuthService from '../../auth/UnAuthService';
import { BaseURIHolder } from '../../utils/BaseURIHolder';

export class GuestPointService {

    constructor() {
        this.state = {   
        }
        this.baseURIHolder = new BaseURIHolder();
        this.unAuthService = new UnAuthService();
    }
    fetchUserDetails(username) {
        let uri = this.baseURIHolder.getGuestUser() + '/profile/by'.concat('?username=').concat(username);
        return this.unAuthService.getFetch(uri)
            .catch((error) => console.log('error', error)); 
    }
    sendOtpCheck(userContactNo, requestedObject) {
        let uri = this.baseURIHolder.getGuest() +   '/recover/password/check_contact'.concat('?userContactNo=').concat(userContactNo);
        return this.unAuthService.postFetch(uri,requestedObject)
        .catch((error) => console.log("error",error));
    }
    checkOTP(code) {
        let uri = this.baseURIHolder.getGuest() + '/core/check-otp'.concat('?code=').concat(code);
        return this.unAuthService.getFetch(uri)
            .catch((error) => console.log('error', error)); 
    }
    recoverUserPassword( requestedObject) {
        let uri = this.baseURIHolder.getGuest() +  '/recover/password/reset'
        return this.unAuthService.postFetch(uri,requestedObject)
        .catch((error) => console.log("error",error));
    }

    getCoreBanner(defCode) {
        let uri = this.baseURIHolder.getGuest() + `/core-banner/by?defaultCode=${defCode}`;
        return this.unAuthService.getFetch(uri)
            .catch((error) => console.log('error', error)); 
    }

    unlockBijoyByGuest( requestedObject) {
        let uri = this.baseURIHolder.getGuest() +  '/bijoy/unlock/password/new'
        return this.unAuthService.postFetch(uri,requestedObject)
        .catch((error) => console.log("error",error));
    }

    findPurchaseCodeInfoByGuestUser(purchaseCode) {
        let uri = this.baseURIHolder.getGuest() + `/bijoy/purchase-code-info/by?code=${purchaseCode}`;
        return this.unAuthService.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    
    getBijoyPasswordByPurchaseCode(purchaseCode) {
        let uri = this.baseURIHolder.getGuest() + `/bijoy/unlock-password/by?code=${purchaseCode}`;
        return this.unAuthService.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
}