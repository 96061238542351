import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService'

export class StudentAccountService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    getDateWiseStudentFeeSummary(fromDate,toDate) {

        let uri = this.baseURIHolder.getUser()+'/sa/accounts/multiple-institute/fee/collection/info?fromDate='+fromDate+'&toDate='+toDate;

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    console.log("fee", response);
                    
                    return response.json()
                } else {
                    return response
                }
            }).catch(error => console.log('responseErrorData:::' + error));
    }

}