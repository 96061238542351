import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { WalletBalance } from '../myPoint/common/WalletBalance';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { EmSmsTransferInfoList } from './EmSmsTransferInfoList';
import { Dropdown } from 'primereact/dropdown';
import { ProfileService } from '../../service/profile/ProfileService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';
import { EmAdminPointService } from '../../service/emAdminPoint/EmAdminPointService';

//let dialogStatus = false;
let totalMessageBalance = 0;
export class EmSmsTransfer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            instituteDropdownList: [],
            messageTypeList: [],
            errorMsgVisible: false,
            topProgressBar: false,
            payableInstituteID: '',
            payableInstituteInfo: {},
            instituteSmsBalance: '',
            checkedRetriveObj: {},
            messageTransferError: {},
            messageTransferInfo: {
                productId: '',
                quantity: '',
                note: ''
            },
            messagetypeInfoList: {},
            OTPcode: '',
            dataTableIsLoading: false,
            topProgressBar: false,
            firstDropDownIsLoading: false,
            secondDropDownIsLoading: false,
            visible: false

        }

        this.emAdminPointService = new EmAdminPointService();
        this.profileService = new ProfileService();
        this.partnerPointService = new PartnerPointService();
        this.NetiContentLoader = new NetiContentLoader();


    }

    componentWillMount() {
        this.getPayableInstituteList();
        //this.getMessageTypeList();
    }

    getPayableInstituteList = () => {
        this.setState({ topProgressBar: true, firstDropDownIsLoading: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.emAdminPointService.getInstituteByUser().then(res => {
            this.setState({ topProgressBar: false });
            if (res.status == 302) {
                return res.json().then((body) => {
                    console.log("data", body);
                    this.setState({ instituteDropdownList: body, topProgressBar: false, firstDropDownIsLoading: false, dataTableIsLoading: false })
                })
            } else {
                this.emAdminPointService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ topProgressBar: false, firstDropDownIsLoading: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                    });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
        })
    }

    getInstituteBalance(payableInstituteInfo) {
        //let { payableInstituteInfo } = this.state
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.partnerPointService.getInstituteBalanceByInstitute(payableInstituteInfo.instituteId)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ topProgressBar: false, instituteSmsBalance: body.messageBalance });

                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })

                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
            });
    }

    onChangePayableInstitute = (event) => {
        let { payableInstituteID, instituteDropdownList, payableInstituteInfo, messageTransferError } = this.state;
        for (let i = 0; i < instituteDropdownList.length; i++) {
            if (instituteDropdownList[i].emDetailsId == event.target.value) {
                payableInstituteInfo = instituteDropdownList[i];
                this.setState({ payableInstituteInfo });
                this.getInstituteBalance(payableInstituteInfo);
            }
        }
        this.setState({ payableInstituteID: event.target.value });

        messageTransferError['institute'] = ''
        this.setState({ messageTransferError });

    }

    // getMessageTypeList = () => {
    //     this.setState({ topProgressBar: true, secondDropDownIsLoading: true, dataTableIsLoading: true, errorMsgVisible: false });
    //     let { messageTypeList } = this.state;
    //     this.partnerPointService.getProductTypeByDefCode('C104006')
    //         .then(res => {
    //             this.setState({ topProgressBar: false });
    //             if (res.status == 302) {
    //                 return res.json().then((body) => {
    //                     this.setState({ messageTypeList: body, topProgressBar: false, secondDropDownIsLoading: false, dataTableIsLoading: false })

    //                 });
    //             } else {
    //                 this.partnerPointService.Auth.handleErrorStatus(res)
    //                     .then((responseBody) => {
    //                         this.setState({ topProgressBar: false, secondDropDownIsLoading: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
    //                     });
    //             }
    //         }).catch(error =>
    //             this.setState({ topProgressBar: false, dataTableIsLoading: false, secondDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })

    //         );

    // }

    onChangeMessageType = (event) => {
        let { messageTransferInfo, messageTypeList, messagetypeInfoList } = this.state;
        messageTransferInfo.productId = event.target.value;
        this.setState({ messageTransferInfo });
        for (let i = 0; i < messageTypeList.length; i++) {
            if (messageTypeList[i].productID == event.target.value) {
                messagetypeInfoList = messageTypeList[i];
                this.setState({ messagetypeInfoList });
            }
        }
    }

    onChangeMessageQuantity = (e) => {
        let { messageTransferInfo, messageTransferError } = this.state;
        messageTransferInfo.quantity = e.target.value;
        this.setState({ messageTransferInfo });

        messageTransferError['messageQuantity'] = ''
        this.setState({ messageTransferError });
    }

    onChangeMessageNote = (e) => {
        let { messageTransferInfo, messageTransferError } = this.state;
        messageTransferInfo.note = e.target.value;
        this.setState({ messageTransferInfo });

        messageTransferError['requestNote'] = ''
        this.setState({ messageTransferError });
    }


    // onSubmitSmsTransfer = (e) => {
    //     let { messageTransferInfo, messagetypeInfoList, payableInstituteInfo } = this.state;

    //     messageTransferInfo.productDefaultCode = messagetypeInfoList.productDefaultCode;
    //     messageTransferInfo.emDetailsId = payableInstituteInfo.emDetailsId;
    //     messageTransferInfo.instituteId = payableInstituteInfo.instituteId;
    //     if (this.messageTransferFormError()) {
    //         this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })

    //         this.setState({ messageTransferInfo });
    //         this.emAdminPointService.checkEmadminMessageRequest(messageTransferInfo)
    //             .then((res) => {
    //                 console.log("response", res);

    //                 if (res.status == 200) {
    //                     return res.json().then((body) => {
    //                         this.setState({ checkedRetriveObj: body });
    //                         this.growl.show({
    //                             severity: "success",
    //                             summary: "Success Message",
    //                             detail: 'OTP Send to your contact no',
    //                             life: 800
    //                         });

    //                         setTimeout(() => {
    //                             this.viewDialog(e);
    //                         }, 800);
    //                         this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false })
    //                     })

    //                 } else {
    //                     this.emAdminPointService.Auth.handleErrorStatus(res)
    //                         .then((responseBody) => {
    //                             this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
    //                         });
    //                 }
    //             }).catch(error => {
    //                 this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })
    //             });

    //     }
    // }

    // smsTranferOtp = (e) => {
    //     this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
    //     this.profileService.checkOPTCode(this.state.OTPcode)
    //         .then((res) => {
    //             if (res.status == 200) {
    //                 this.setState({ topProgressBar: false, dataTableIsLoading: false });
    //                 this.submitMessageTransfer(e);
    //             } else {
    //                 this.profileService.Auth.handleErrorStatus(res)
    //                     .then((responseBody) => {
    //                         this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
    //                         this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody, dataTableIsLoading: false })
    //                     });
    //             }

    //         }).catch(error => {
    //             this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })
    //         });

    // }

    submitMessageTransfer = (e) => {
        let { messageTransferInfo, payableInstituteInfo } = this.state;
        messageTransferInfo.emDetailsId = payableInstituteInfo.emDetailsId;
        messageTransferInfo.instituteId = payableInstituteInfo.instituteId;
        if (this.messageTransferFormError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })

            this.emAdminPointService.sendEmAdminMessageTransferRequest(messageTransferInfo)
                .then((res) => {
                    if (res.status == 200) {
                        this.growl.show({ severity: "success", summary: "Success Message", detail: 'Successfully submitted' });
                        //this.refs.emSmsTransferInfoList.viewMessageTransferList();
                        this.setState({
                            messageTransferInfo: {
                                quantity: '',
                                note: ''
                            },
                            payableInstituteID: '',
                            payableInstituteInfo: {},
                        });
                        this.setState({ instituteSmsBalance: '' })
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false })
                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error })
                });
        }
    }

    // viewDialog = (e) => {
    //     dialogStatus = true;
    //     this.setState({ visible: true });
    // }

    // onHide = (event) => {
    //     this.setState({ visible: false });
    // }

    // onChangeOTPcode = (e) => {
    //     this.setState({
    //         OTPcode: e.target.value
    //     });
    // }

    // submitBalanceTransfer = (e) => {
    //     this.onHide(e);
    // }

    messageTransferFormError = () => {
        let { messageTransferError } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });

        if (this.state.payableInstituteID === '') {
            formIsValid = false;
            messageTransferError["institute"] = "Institute can't left empty.";
        }
        // if (this.state.messageTransferInfo.productId === '') {
        //     formIsValid = false;
        //     messageTransferError["messageType"] = "Message type can't left empty.";
        // }
        if (!this.state.messageTransferInfo.quantity || this.state.messageTransferInfo.quantity == 0) {
            formIsValid = false;
            messageTransferError["messageQuantity"] = "Quantity can't left empty.";
        } else if (( this.state.messageTransferInfo.quantity > totalMessageBalance) || totalMessageBalance <= 0) {
            formIsValid = false;
            messageTransferError["messageQuantity"] = "Insufficient Message Balance";
        }

        if (this.state.messageTransferInfo.note === '') {
            formIsValid = false;
            messageTransferError["requestNote"] = "Note can't left empty.";
        }
        this.setState({ messageTransferError });
        return formIsValid;
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, messageTransferError, messageTransferInfo } = this.state;
        let payabaleInstitute = [];

        console.log("instituteDropdownList", this.state.instituteDropdownList);

        if ((this.state.instituteDropdownList != null) && (this.state.instituteDropdownList.length > 0)) {
            payabaleInstitute = this.state.instituteDropdownList.map((item) => ({
                value: item.emDetailsId,
                label: item.instituteId + " - " + item.instituteName,
            }));
        }

        let messageList = [];
        if ((this.state.messageTypeList != null) && (this.state.messageTypeList.length > 0)) {
            messageList = this.state.messageTypeList.map((item) => ({
                value: item.productID,
                label: item.productName,
            }));
        }

        totalMessageBalance = this.refs.walletBalance && this.refs.walletBalance.state && this.refs.walletBalance.state.userWalletBalance && this.refs.walletBalance.state.userWalletBalance.smsBalance;


        return (

            <div className="p-fluid">
                <div className="p-grid nw-form">
                    {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                    {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                    <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                        <div className="p-grid seprator-inside">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <Growl ref={(el) => this.growl = el} />

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.firstDropDownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institute Name <span>*</span></span>
                                                <Dropdown
                                                    value={this.state.payableInstituteID}
                                                    options={payabaleInstitute}
                                                    onChange={this.onChangePayableInstitute}
                                                    placeholder="Select Institute"
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="institute"
                                                />
                                            </div>
                                        }
                                        <div>
                                            <span style={{ float: 'right' }}>{"Institute SMS Balance : " + (this.state.instituteSmsBalance || 0)}</span> <br />
                                            <span className="error-message">{messageTransferError["institute"]}</span>
                                        </div>
                                    </div>

                                    {/* <div className="p-col-12 p-xl-12">
                                        {this.state.secondDropDownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Message Type <span>*</span></span>
                                                <Dropdown
                                                    value={this.state.messageTransferInfo.productId}
                                                    options={messageList}
                                                    onChange={this.onChangeMessageType}
                                                    placeholder="Select Message Type"
                                                    filter={true}
                                                    filterBy="value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="messageType"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{messageTransferError["messageType"]}</span>
                                    </div> */}

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Message Quantity <span>*</span></span>
                                            <InputText
                                                value={messageTransferInfo.quantity}
                                                keyfilter="pint"
                                                onChange={this.onChangeMessageQuantity}
                                                placeholder="Enter Message Quantity"
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="messageQuantity"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.messageTransferError["messageQuantity"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                            <InputTextarea
                                                value={messageTransferInfo.note}
                                                onChange={this.onChangeMessageNote}
                                                placeholder="Enter Note"
                                                rows={3}
                                                cols={30}
                                                autoResize={true}
                                                name="requestNote"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.messageTransferError["requestNote"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12" />


                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Transfer"
                                            icon="fas fa-arrow-right"
                                            iconPos="right"
                                            onClick={this.submitMessageTransfer}
                                            disabled={this.state.payableInstituteID ? false : true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-xl-4">
                        <div className="layout-dashboard">
                            {this.state.dataTableIsLoading ? "" :
                                <WalletBalance
                                    messageBalanceName='Message' ref="walletBalance"
                                />

                            }
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className='nw-data-table'>
                            {!this.state.dataTableIsLoading ?
                                <EmSmsTransferInfoList ref="emSmsTransferInfoList" />
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>
                    </div>

                    {/* <Dialog
                        className="nw-dialog"
                        header="SMS Transfer"
                        visible={this.state.visible}
                        onHide={this.onHide}
                        maximizable
                    >
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 text-center">
                                    <span>A 6-digit verification code has been sent to '{this.state.checkedRetriveObj.contactNo}' contact number.</span>
                                </div>
                                <div className="p-col-12 p-xl-12 text-center">
                                    <span>OTP Code : </span>
                                    <InputText
                                        onChange={(e) => this.onChangeOTPcode(e)}
                                        value={this.state.OTPcode}
                                        placeholder="Enter OTP Code"
                                        style={{ width: "50%" }}
                                        type="text"
                                    />
                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Transfer"
                                        onClick={(e) => this.smsTranferOtp(e)}
                                    />
                                </div>

                            </div>
                        </div>
                    </Dialog> */}
                </div>
            </div>
        );
    }
}