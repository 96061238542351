import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { MessageRechargeService } from '../../../../service/myPoint/MessageRechargeService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import { PartnerPointService } from '../../../../service/partnerPoint/PartnerPointService';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { Dialog } from 'primereact/dialog';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../../common/JsPdfDownload';
import { Link } from 'react-router-dom';

let maxDate = new Date();
// pdf
let subTitleColumn = [];
let subTitleList = [];

export class PrepaidBillCollection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableSelection: null,
            billInfoDetails: {},
            purchaseInfoDetails: {},
            viewDialogShow: false,
            messageRechargeError: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            tableView: false,
            inputErrorMessage: null,
            dataTableValue: [],
            billCollectionSearchInfo: {
                startDate: "",
                endDate: "",
            },
            dataTableIsLoading: false
        };

        this.messageRechargeService = new MessageRechargeService();
        this.partnerPointService = new PartnerPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
        this.commonFuctionality = new CommonFuctionality();
        this.exportPdf = this.exportPdf.bind(this);
    }

    onChangeStartDate = (e) => {
        let { billCollectionSearchInfo } = this.state;
        billCollectionSearchInfo.startDate = e.target.value
        this.setState({ billCollectionSearchInfo });
        this.clearDateError(e.target.name);
    }

    onChangeEndDate = (e) => {
        let { billCollectionSearchInfo } = this.state;
        billCollectionSearchInfo.endDate = e.target.value
        this.setState({ billCollectionSearchInfo });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { messageRechargeError } = this.state;
        messageRechargeError[name] = ''
        this.setState({ messageRechargeError })
    }

    onSubmitHandler = (e, props) => {

        let { billCollectionSearchInfo } = this.state;
        if (this.messageRechargeFormHandleError()) {
            if (Date.parse(billCollectionSearchInfo.endDate) >= Date.parse(billCollectionSearchInfo.startDate)) {
                this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
                this.partnerPointService.findPrepaidBillCollectionDetails(billCollectionSearchInfo)
                    .then((res) => {
                        if (res.status == 302) {
                            return res.json().then((body) => {
                                for (let i = 0; i < body.length; i++) {
                                    if (body[i].billDate == null) {
                                        body[i].billDate = '';
                                    } else {
                                        body[i].billDate = NetiDateUtils.getAnyShortForm(body[i].billDate, 'DD-MMM-YYYY');
                                    }
                                }
                                this.setState({ dataTableValue: body, errorMsgVisible: false, topProgressBar: false, dataTableIsLoading: false })
                            });
                        } else {
                            this.partnerPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });

            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be ahead of To Date." });
            }

        }

    }

    messageRechargeFormHandleError = () => {
        let { messageRechargeError } = this.state;
        let formIsValid = true;

        if (this.state.billCollectionSearchInfo.startDate === '') {
            formIsValid = false;
            messageRechargeError["startDate"] = "From Date can't left empty.";
        }
        if (this.state.billCollectionSearchInfo.endDate === '') {
            formIsValid = false;
            messageRechargeError["endDate"] = "To Date can't left empty.";
        }

        this.setState({ messageRechargeError });
        return formIsValid;
    }

    paidAmountFormat = (rowData) => {
        return <div>{this.ValidatorUtility.currencyFormatter(rowData.billAmount)}</div>
    }

    actionBody = (rowData) => {
        return (
            <div className="text-center">
                <Button
                    className="nw-action-button edit"
                    icon="fas fa-eye"
                    onClick={e => this.viewDialog(rowData)}
                />
            </div>
        );
    }

    viewDialog = (rowData) => {
        this.commonFuctionality.blurDialogBackgroundActive();
        this.getPartnerPurchaseLog(rowData.billLogID);
    }

    getPartnerPurchaseLog = (param) => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.setState({ viewDialogShow: true });

        this.partnerPointService.findEmBillDetailsByBillLogID(param)
            .then((res) => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ billInfoDetails: body, errorMsgVisible: false });
                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    onHideViewDialog = () => {
        this.setState({ viewDialogShow: false });
        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Date", dataKey: "billDate" },
            { title: "Institute ID", dataKey: "instituteID" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Paid Amount", dataKey: "billAmount" },
            { title: "Neti ID", dataKey: "customNetiID" },
            { title: "Name", dataKey: "name" },
            { title: "Area", dataKey: "area" },
        ]

        let columnStyle = {
            billAmount : { halign: 'right' }
        }

        pdfDownloadPortrait_TableWithSubTitle("Partner Pre-Paid Bill Collection", 75, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, columnStyle, "Partner_Pre-Paid_Bill_Collection.pdf");

    }

    render() {

        let { dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody, billInfoDetails, billCollectionSearchInfo } = this.state;
        let header = <div className="header-title">
            <div className="header-title-left">
                Partner Pre-paid Bill Collection List
            </div>

            <div className="header-title-right">
                <Link to="#">
                    Total Found: {dataTableValue.length.toLocaleString('EN-IN')}
                </Link>
            </div>
        </div>;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (billCollectionSearchInfo.startDate && billCollectionSearchInfo.endDate) {
            formatedStartDate = (billCollectionSearchInfo.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (billCollectionSearchInfo.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={el => (this.growl = el)} />

                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">From Date<span>*</span></span>
                                        <Calendar
                                            value={this.state.billCollectionSearchInfo.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            name="startDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.messageRechargeError["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">To Date<span>*</span></span>
                                        <Calendar
                                            value={this.state.billCollectionSearchInfo.endDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            name="endDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.messageRechargeError["endDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parrent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table">
                                {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            : <div>
                                                <DataTable
                                                    value={this.state.dataTableValue}
                                                    header={header}
                                                    responsive={true}
                                                    paginator={true}
                                                    rows={10}
                                                    rowsPerPageOptions={[10, 20, 30]}
                                                >
                                                    <Column field="billDate" header="Date" filter={true} />
                                                    <Column field="instituteID" header="Institute ID" filter={true} />
                                                    <Column field="instituteName" header="Institute Name" filter={true} />
                                                    <Column field="billAmount" header="Paid Amount" className="text-right" body={this.paidAmountFormat} filter={true} />
                                                    <Column field="customNetiID" header="Neti ID" filter={true} />
                                                    <Column field="name" header="Name" filter={true} />
                                                    <Column field="area" header="Area" filter={true} />
                                                    <Column body={this.actionBody} header="Details" style={{ width: "80px" }} />
                                                </DataTable>

                                                <div className="nw-button-parent m-t-8 p-r-0">
                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                        label="Download PDF"
                                                        icon="fas fa-download"
                                                        onClick={this.exportPdf}
                                                    />
                                                </div>

                                            </div>
                                        :
                                        <BlankDataTableAnim />
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className="dialog-section">
                    <Dialog header="Pre-Paid Bill Collection Details Info" visible={this.state.viewDialogShow} onHide={this.onHideViewDialog} className="nw-dialog product-info-dialog">
                        <div className="p-fluid">
                            <div className="p-grid">
                                {this.state.viewDialogShow ?
                                    <div className="p-grid p-col-12">
                                        <div class="nw-search-view no-border p-0">
                                            <div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Date </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.billDate ? NetiDateUtils.getAnyShortForm(billInfoDetails.billDate, 'DD-MMM-YYYY') : 0}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Institute ID  </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.emDetailsInfoDTO && billInfoDetails.emDetailsInfoDTO.instituteId}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Institute Name </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.emDetailsInfoDTO && billInfoDetails.emDetailsInfoDTO.instituteName}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Package  </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.emDetailsInfoDTO && billInfoDetails.emDetailsInfoDTO.packageCoreCategoryInfoDTO && billInfoDetails.emDetailsInfoDTO.packageCoreCategoryInfoDTO.categoryName}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Rate Per Student </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.emDetailsInfoDTO && billInfoDetails.emDetailsInfoDTO.monthlyRate}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Total Student </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.emDetailsInfoDTO && billInfoDetails.emDetailsInfoDTO.totalStudent}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Bill Month </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.emBillGenerateLogDTO && billInfoDetails.emBillGenerateLogDTO.billMonth}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Bill Year </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.emBillGenerateLogDTO && billInfoDetails.emBillGenerateLogDTO.billYear}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Paid Amount </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.billAmount ? this.ValidatorUtility.currencyFormatter(billInfoDetails.billAmount) : 0}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Neti ID </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.emDetailsInfoDTO && billInfoDetails.emDetailsInfoDTO.assignedUserBasicInfoDTO && billInfoDetails.emDetailsInfoDTO.assignedUserBasicInfoDTO.customNetiID}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Name </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.emDetailsInfoDTO && billInfoDetails.emDetailsInfoDTO.assignedUserBasicInfoDTO && billInfoDetails.emDetailsInfoDTO.assignedUserBasicInfoDTO.fullName}</div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-xl-12">
                                                    <div class="p-inputgroup">
                                                        <span class="task-badge found"></span>
                                                        <span class="p-inputgroup-addon"> Area </span>
                                                        <span class="p-inputgroup-colon">:</span>
                                                        <div class="nw-inputgroup-desc">{billInfoDetails.emDetailsInfoDTO && billInfoDetails.emDetailsInfoDTO.partnerAssignDTO && billInfoDetails.emDetailsInfoDTO.partnerAssignDTO.partnerAreaInfoDTO && billInfoDetails.emDetailsInfoDTO.partnerAssignDTO.partnerAreaInfoDTO.categoryName}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>

                    </Dialog>
                </div>

            </div>
        );
    }
}