import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import Formatter from '../../../utils/Formatter';
import { TreeTable } from 'primereact/treetable';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';

let datatableListArr = [];
let sumOfInstitute = 0;
let totalActiveInstitute = 0;
let totalInactiveInstitute = 0;
let totalActiveInstitutePercentage = 0;
let totalInactiveInstitutePercentage = 0;
let totalActiveRate = 0;
let totalInactiveRate = 0;
let totalActiveDue = 0;
let totalInactiveDue = 0;
let status = { display: 'none' };
let userProfile; 


let mbpListObj = {};
let zbpListObj = {};
let bdpListObj = {};
let bepListObj = {};

let maxDate = new Date();
export class MPProductSellTreeView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewAtaGlance: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            productName:'',
            dataTableIsLoading: false,
            searchObj: {
                startDate: "",
                endDate: "",
                netiID: ""
            },
            errors: {},
        }

        this.ManagementPointService = new ManagementPointService;
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
        this.Formatter = new Formatter();
        this.CommonFuctionality = new CommonFuctionality();
    }

    componentDidMount() {
        this.fetchProductList();
    }

    fetchProductList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.ManagementPointService.fetchProductList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productList: body, topProgressBar: false, secondDropdownIsLoading: false });

                    })
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchMBPInfo = () => {
        sumOfInstitute = 0;
        totalActiveInstitute = 0;
        totalInactiveInstitute = 0;
        totalActiveRate = 0;
        totalInactiveRate = 0;
        totalActiveDue = 0;
        totalInactiveDue = 0;

        datatableListArr = [];
        mbpListObj = {}
         userProfile = JSON.parse(localStorage.getItem('Profile'));

        let requestObj = {
        "startDate": this.state.searchObj.startDate,
        "endDate": this.state.searchObj.endDate,
        "netiID": userProfile.netiID,
        "productID": this.state.productName
        }
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.ManagementPointService.findDesignationWisePurchaseInfo(requestObj) /*this.state.rowDataObj.partnerAssignID*/
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("mbp list", body);
                        body.map((item, i) => {
                            mbpListObj.data = item;
                            mbpListObj.key = i;
                            mbpListObj.children = [];
                            mbpListObj.leaf = false;
                            datatableListArr.push(Object.assign({}, mbpListObj));
                        });

                    

                        // sumOfInstitute = body.map(item => item.totalInstitute).reduce((a, b) => (a + b));
                        // totalActiveInstitute = body.map(item => item.activeInstitute).reduce((a, b) => (a + b));
                        // totalInactiveInstitute = body.map(item => item.inActiveInstitute).reduce((a, b) => (a + b));
                        // totalActiveRate = body.map(item => item.activeRate).reduce((a, b) => (a + b));
                        // totalInactiveRate = body.map(item => item.inActiveRate).reduce((a, b) => (a + b));
                        // totalActiveDue = body.map(item => item.activePayble).reduce((a, b) => (a + b));
                        // totalInactiveDue = body.map(item => item.inActivePayble).reduce((a, b) => (a + b));


                        this.setState({ topProgressBar: false, dataTableIsLoading: false });

                    });
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection!!!' })

            );
    }





  

    amountBody(rowData) {
        return <div >
            <span className="col-number text-right">{rowData.data.amount}</span> <br />
        </div>;
    }

    quantityBody(rowData) {
        return <div >
            <span className="col-number">{rowData && rowData.data && rowData.data.quantity ? rowData.data.quantity.toLocaleString('EN-IN') : 0}</span> <br />
        </div>;
    }



    mbpDueActiveInstituteBody(rowData) {

        return <div className="text-success col-number text-right" >

            <span className="text-success">{this.validatorUtility.currencyFormatter(rowData.data["activePayble"])}</span><br />

            <span className="text-danger">{this.validatorUtility.currencyFormatter(rowData.data["inActivePayble"])}</span>

        </div>;
    }

    partnerAreaInfo(rowData) {

        return <span>
            {/* <span style={{ color: "blue" }}>{rowData.data.partnerMobile}</span><br /> */}
            <span>{rowData.data.area}</span>
        </span>
    }

    partnerDetailInfo = (rowData) => {
        return <span>
            <span >{rowData.data.partnerName}</span><br />
            <span >{rowData.data.partnerNetiID}</span>
        </span>
    }

    // userBasicMobile = (rowData) => {
    //     console.log("rowData", rowData);
        
    //     return <span>
    //         {/* <span >{rowData.userBasicInfoDTO.basicMobile}</span> */}
    //         {/* <span >{rowData.data.partnerNetiID}</span> */}
    //     </span>
    // }

    sumOfActiveInactive = () => {
        return <span>
            <span className="text-success">{totalActiveInstitute + "[" + this.Formatter.getFixedDecimalNumber(this.totalActiveInstitutePercentageInfo()) + "%" + "]" + "[" + this.validatorUtility.currencyFormatter(totalActiveRate) + "]"}</span><br />

            <span className="text-danger">{totalInactiveInstitute + "[" + this.Formatter.getFixedDecimalNumber(this.totalInactiveInstitutePercentageInfo()) + "%" + "]" + "[" + this.validatorUtility.currencyFormatter(totalInactiveRate) + "]"}</span>

        </span>

    }

    sumOfActiveInactiveDue = () => {
        return <span>

            <span className="text-success">{this.validatorUtility.currencyFormatter(totalActiveDue)}</span><br />

            <span className="text-danger">{this.validatorUtility.currencyFormatter(totalInactiveDue)}</span>

        </span>

    }

    totalActiveInstitutePercentageInfo = () => {

        let totalActiveInstitutePercentage = (totalActiveInstitute / sumOfInstitute) * 100
        if (totalActiveInstitutePercentage) {
            return isNaN(totalActiveInstitutePercentage) ? 0 : totalActiveInstitutePercentage;
        }
    }

    totalInactiveInstitutePercentageInfo = () => {

        let totalInactiveInstitutePercentage = (totalInactiveInstitute / sumOfInstitute) * 100
        if (totalInactiveInstitutePercentage) {
            return isNaN(totalInactiveInstitutePercentage) ? 0 : totalInactiveInstitutePercentage;
        }
    }

    onExpand = (event) => {
        zbpListObj = {};
        bdpListObj = {};
        bepListObj = {};
        let lazyNode = { ...event.node };
        console.log('onexpand lazyNode.data',lazyNode.data);
        
        if (lazyNode.data.designation === "MBP") {
            if(lazyNode.children.length === 0) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            let requestObj = {
                "startDate": this.state.searchObj.startDate,
                "endDate": this.state.searchObj.endDate,
                "netiID": lazyNode.data.netiID,
                "productID": this.state.productName
                }
            this.ManagementPointService.findDesignationWisePurchaseInfo(requestObj).then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        console.log('zbp list',body);

                        body.map((item, i) => {
                            zbpListObj.data = item;
                            zbpListObj.key = `${lazyNode['key']}-${i}`;
                            zbpListObj.children = [];
                            zbpListObj.leaf = false;
                            lazyNode.children.push(Object.assign({}, zbpListObj));
                        });

                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    })
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
            });
            }
        }

        else if (lazyNode.data.designation === "ZBP") {
            if(lazyNode.children.length === 0) {

            this.setState({ topProgressBar: true, errorMsgVisible: false });
            let requestObj = {
                "startDate": this.state.searchObj.startDate,
                "endDate": this.state.searchObj.endDate,
                "netiID": lazyNode.data.netiID,
                "productID": this.state.productName
                }
            this.ManagementPointService.findDesignationWisePurchaseInfo(requestObj).then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        console.log('bdp list',body);
                        body.map((item, i) => {
                            bdpListObj.data = item;
                            bdpListObj.key = `${lazyNode['key']}-${i}`;
                            bdpListObj.children = [];
                            bdpListObj.leaf = false;
                            lazyNode.children.push(Object.assign({}, bdpListObj));
                        });

                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    })
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
            });
            }
        }
        else if (lazyNode.data.designation === "BDP") {
            if(lazyNode.children.length === 0) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            let requestObj = {
                "startDate": this.state.searchObj.startDate,
                "endDate": this.state.searchObj.endDate,
                "netiID": lazyNode.data.netiID,
                "productID": this.state.productName
                }
            this.ManagementPointService.findDesignationWisePurchaseInfo(requestObj).then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        console.log('bdp list',body);
                        body.map((item, i) => {
                            bepListObj.data = item;
                            bepListObj.key = `${lazyNode['key']}-${i}`;
                            bepListObj.children = [];
                            lazyNode.children.push(Object.assign({}, bepListObj));
                        });
                        status = { display: 'table-cell' }
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    })
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
            }
        }

        

        setTimeout(() => {
            this.CommonFuctionality.setCustomizedTreeDatatable('pType', datatableListArr && datatableListArr[0] && datatableListArr[0].data && datatableListArr[0].data.designation);
        }, 500);
        
    }

    onSearchPaidHandler = () =>{
        if(this.onSubmitFormError()) {
        this.fetchMBPInfo();
    }
}

    onSubmitFormError = () => {
        let { errors, searchObj } = this.state;
  
        let formIsValid = true;

        if (searchObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start Date can't left empty.";
        }
        if (searchObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End Date can't left empty.";
        }
        if (this.state.productName === '') {
            formIsValid = false;
            errors["productName"] = "Product Name can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onChangeProductName = (value) => {
        let {errors} = this.state;
        errors["productName"] = "";
        this.setState({ productName: value, errors: errors });
    }

    onChangeStartDate = (e) => {
        let { searchObj, errors } = this.state;
        errors["startDate"] = "";
        searchObj[e.target.name] = e.target.value;
        this.setState({ searchObj,errors });

    }

    onChangeEndDate = (e) => {
        let { searchObj, errors } = this.state;
        errors["endDate"] = "";
        searchObj[e.target.name] = e.target.value;
        this.setState({ searchObj,errors });

    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let header = <div className="header-title">
                        <div className="header-title-left">
                        Product Sell Report
                        </div>


                        <div className="header-title-right">

                            {/* <Link to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 0 }}>
                                More<i className="fas fa-angle-right" ></i>
                            </Link> */}
                        </div>
                    </div>;
        let yearSelection = [
            { label: "2020", value: 2020 },
            { label: "2019", value: 2019 },
            { label: "2018", value: 2018 },
            { label: "2017", value: 2017 },
            { label: "2016", value: 2016 },
            { label: "2015", value: 2015 },
            { label: "2014", value: 2014 },
            { label: "2013", value: 2013 }
          ];

          let productListOptions = [];
          if (this.state.productList && this.state.productList.length) {
              productListOptions = this.state.productList.map(item => ({
                  value: item.productID,
                  label: item.productName
              }));
          }
  

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-lg-12 p-xl-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.searchObj.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            name="startDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.searchObj.endDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            name="endDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>

                                </div>

                

                                  <div className="p-col-12 p-xl-12">
                                {this.state.secondDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Product Name <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Product Name'
                                            name='productName'
                                            options={productListOptions}
                                            value={this.state.productName}
                                            onChange={(e) => this.onChangeProductName(e.target.value)}
                                            autoWidth={false}
                                            filter={true}
                                        />
                                    </div>
                                }
                                <span className='error-message'>{this.state.errors["productName"]}</span>
                            </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSearchPaidHandler}
                                    />
                                </div>

                                
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12 p-col-nogutter nw-data-table">
                            {
                                this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    :
                                    <TreeTable
                                        header={header}
                                        lazy={true}
                                        value={datatableListArr}
                                        onExpand={this.onExpand}
                                        expandedKeys={this.state.expandedKeys}
                                        onToggle={this.onExpandToggle}
                                        >
                                        <Column field="" header="Area" body={this.partnerAreaInfo.bind(this)} className='pType' expander />
                                        <Column field="quantity" header="Quantity" body={this.quantityBody} />
                                        <Column field="" header="Amount" body={this.amountBody.bind(this)} />
                                    </TreeTable>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}