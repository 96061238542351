import { EM_USER_POINT } from './PointWiseRouteConsts';
import { StudentProfile } from '../components/emUserPoint/studentPortal/StudentProfile';
import { MyPointMenu } from './MyPointMenu';

export const EmParentPointMenu = {

    items: [
        
        // {label: 'User Point', icon: 'fas fa-users',

        //     items: [
        //         { label: 'Settings', icon: '',
        //         items: [
        //             { label: 'Add Portal', icon: '', to: EM_USER_POINT.ADD_PORTAL}, //pi pi-fw pi-money-bill
        //         ] 
            
        //     },
        //     //     { label: 'Add Portal', icon: '',
        //     //     items: [
        //     //         { label: 'Student', icon: '', to: EM_USER_POINT.ADD_STUDENT_PORTAL}, //pi pi-fw pi-money-bill
        //     //         { label: 'Guardian', icon: '', to: EM_USER_POINT.ADD_GUARDIAN_PORTAL}, //pi pi-fw pi-envelope
        //     //         { label: 'Employee', icon: '', to: EM_USER_POINT.ADD_EMPLOYEE_PORTAL}, //pi pi-fw pi-envelope
        //     //     ] 
            
        //     // }, 
        //     // { label: 'Go to Portal', icon: '',
        //     // items: [
        //     //     { label: 'Student', icon: '', to: EM_USER_POINT.STUDENT_LIST_INFO}, //pi pi-fw pi-money-bill
        //     //     { label: 'Guardian', icon: '', to: EM_USER_POINT.GUARDIAN_LIST_INFO}, //pi pi-fw pi-envelope
        //     //     { label: 'Teacher', icon: '', to: EM_USER_POINT.TEACHER_LIST_INFO}, //pi pi-fw pi-envelope
        //     // ] 
        
        //     // },
        //         // pi pi-fw pi-user
        //         // { label: 'Go to Portal', icon: '', to: EM_USER_POINT.GO_TO_PORTAL } // pi pi-fw pi-arrow-right
        //     ]
        // }
       
        // { label: 'Student Portal', icon: '',
    
        //         items: [
        //             { label: 'Profile', icon: '', to: '/profile'},
        //             { label: 'Attendance', icon: '', to: '/attendance'},
        //             { label: 'Subject', icon: '', to: '/subject'},
        //             { label: 'Class Test', icon: '', to: '/class-test'},
        //             { label: 'Exam', icon: '', to: '/exam'},
        //             { label: 'Class Routine', icon: '', to: '/class-routine'},
        //             { label: 'Exam Routine', icon: '', to: '/exam-routine'},
        //             { label: 'Accounts', icon: '', to: '/accounts'},
        //             { label: 'Inventory', icon: '', to:'/inventory'}, 
        //         ] 
            
        // },
        
        {
            label: "Parent Portal", icon: "fas fa-user-tie",
            items: [
                {
                    label: 'Eduman', icon: 'fa fa-users-cog',
                    items: [
                        { label: "Take Action", icon: 'fa ' },
                        { label: 'Fees Payment', icon: '', to: '/fees_payment' },
                        { label: 'Leave Apply', icon: '', to: '/Leave_Apply' },
                        {
                            label: 'Connect Profile', icon: '',
                            items: [
                                { label: 'Parent', icon: '', to: EM_USER_POINT.PARENT_ADD_PORTAL }, //pi pi-fw pi-money-bill
                            ]

                        },
                        {
                            label: 'Reports', icon: 'fa ',
                        },
                        {
                            label: 'Notification', icon: '', to: "/Notification",
                        },
                        {
                            label: 'Institute Notice', icon: '', to: "/Institute_Notice",
                        }, 
                        {
                            label: 'Attendance', icon: '', to: "/attendance",
                        }, 
                        { label: 'Semester Exam', icon: '', to: '/exam' },
                        { label: 'Class Test', icon: '', to: '/class-test' },
                        { label: 'Online Class', icon: '', to: '/Online_Class' },
                        { label: 'Class Routine', icon: '', to: '/class-routine' },
                        { label: 'Exam Routine', icon: '', to: '/exam-routine' },
                        { label: 'Fees Details', icon: '', to: '/accounts' },
                        { label: 'Purchase Details', icon: '', to: '/inventory' },
                        { label: 'Subject Details', icon: '', to: '/subject' },
                        { label: 'My Profile', icon: '', to: '/profile' },

                        // {
                        //     label: 'Settings', icon: '',
                        //     items: [
                        //         { label: 'Add Portal', icon: '', to: EM_USER_POINT.ADD_PORTAL }, //pi pi-fw pi-money-bill
                        //     ]

                        // },
                        // {
                        //     label: 'Basic Info', icon: '',
                        //     items: [
                        //         { label: 'Linked Profile', icon: '', to: EM_USER_POINT.STUDENT_LIST_INFO },
                        //         { label: 'My Profile', icon: '', to: '/profile' },
                        //         { label: 'Subject Info', icon: '', to: '/subject' },
                        //     ]

                        // },
                        // {
                        //     label: 'Attendance', icon: '',
                        //     items: [
                        //         { label: 'Month Wise', icon: '', to: '/attendance' },
                        //     ]
                        // },
                        // {
                        //     label: 'Class Test', icon: '',
                        //     items: [
                        //         { label: 'Details View', icon: '', to: '/class-test' },
                        //     ]
                        // },
                        // {
                        //     label: 'Semester Exam', icon: '',
                        //     items: [
                        //         { label: 'Details View', icon: '', to: '/exam' },
                        //     ]
                        // },
                        // {
                        //     label: 'Fees Info', icon: '',
                        //     items: [
                        //         { label: 'Status Wise', icon: '', to: '/accounts' },
                        //     ]
                        // },
                        // {
                        //     label: 'Routine Info', icon: '',
                        //     items: [
                        //         { label: 'Class Routine', icon: '', to: '/class-routine' },
                        //         { label: 'Exam Routine', icon: '', to: '/exam-routine' },
                        //     ]
                        // },
                        // {
                        //     label: 'Inventory Info', icon: '',
                        //     items: [
                        //         { label: 'Purchase Details', icon: '', to: '/inventory' },

                        //     ]
                        // },
                        // {
                        //     label: 'Settings', icon: '',
                        //     items: [
                        //         { label: 'Add Portal', icon: '', to: EM_USER_POINT.ADD_PORTAL }, //pi pi-fw pi-money-bill
                        //     ]

                        // },
                        {
                            label: 'Basic Info', icon: '',
                            items: [
                                { label: 'Linked Profile', icon: '', to: EM_USER_POINT.STUDENT_LIST_INFO },
                                // { label: 'My Profile', icon: '', to: '/profile' },
                                // { label: 'Subject Info', icon: '', to: '/subject' },
                            ]

                        },

                    ]
                },
                {
                    label: "Digital Content ", icon: "fas fa-user-tie",
                    items: [
                        {
                            label: "Comming Soon", icon: "fas fa-user-tie", to: '/Comming_Soon',
                        }
                    ]
                }
                
                
                
            ]
            
        }
    ]
        
}