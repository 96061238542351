import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ChatBoxService } from '../../../service/chatBox/chatBoxService';
import Formatter from '../../../utils/Formatter';
import CommonFuctionality from '../../common/CommonFuctionality';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ProfileService } from '../../../service/profile/ProfileService';
import { SendSmsAndNetiMail } from "../../common/SendSmsAndNetiMail";



export class AnyoneMessageSend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            netiId: "",
            messageBody: "",
            userInfo: {},
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            searchIsLoading: false,
            dataTableValue: [],
            searchViewError: false,
            searchIsLoadingFirst: false,
            searchIsLoadingSecond: false,
            uploadImage: {},
            secondSearchIsLoading: false,
            secondSearchErrorMsg: '',
            secondfirstSearchView: false,
            secondSearchErrorVisible: false,
            netiInformation:{},
            disableAddButton: true
            
        };
        
        this.NetiContentLoader = new NetiContentLoader();
        this.chatBoxService = new ChatBoxService();
        this.formatter = new Formatter();
        this.CommonFuctionality = new CommonFuctionality();
        this.CRMPointService = new CRMPointService();
        this.ProfileService = new ProfileService();

    }

    netiIDHandler = (event) => {
        let { netiID } = this.state;
        this.setState({ errors: {}, netiId: event.target.value, userExist: '' });
    }


    netiIdCheckHandler = () =>{
        let { netiInformation, dataTableValue } = this.state;

        if (this.netiIDCodeError()) {
            this.setState({ userExist: "", disableAddButton: true, topProgressBar: true, secondSearchView: true, secondSearchIsLoading: true, errorMsgVisible: false, secondSearchErrorVisible: false });
            this.ProfileService.findBasicInfoByCustomNetiID(this.state.netiId)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ netiInformation: body, disableAddButton: false, });
                            
                            setTimeout(() => {
                                if( dataTableValue.includes( body )){
                                    this.setState({ userExist: "User already exist in list", disableAddButton: true })
                                }
                                else{
                                    this.setState({ disableAddButton: false, });
                                }
                            }, 500);
                            this.setState({ topProgressBar: false, secondSearchIsLoading: false });
                        });

                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, disableAddButton: true, secondSearchIsLoading: false, secondSearchErrorVisible: true, secondSearchErrorMsg: resBody })
                            });
                    }
                }).catch(error =>
                    this.setState({ disableAddButton: true, topProgressBar: false, secondSearchView: false, secondSearchIsLoading: false, secondSearchErrorVisible: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
        }
    }

    netiIDCodeError() {
        let { purchaseCode, errors, netiId } = this.state;
        let formIsValid = true;
        this.setState({ secondSearchView: false })

        if (netiId === '') {
            formIsValid = false;
            errors["netiIDError"] = "Neti ID can't left empty";
        }

        if (netiId.length < 10) {
            formIsValid = false;
            errors["netiIDError"] = "Neti ID is too sort";
        } 
        if (netiId == '' && netiId.length < 10) {
            formIsValid = false
            errors['netiIDError'] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10';
        }
        
        this.setState({ errors });
        return formIsValid;
    }

    netiIdHandleError() {
        let errors = {};
        let formIsValid = true;

        if (this.state.netiId === "") {
            formIsValid = false;
            errors["netiId"] = "Please input atleast one ID.";
            this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onChangeCustomNetiID = (e) => {
        let { errors } = this.state
        errors['netiId'] = ''
        this.setState({ netiId: e.target.value, searchViewError: false, searchView: false, buttonDisabled: true, errors });
    }

    focusSearchNetiId = e => {
        this.setState({ searchLabelNetiId: "Search", addSearchBtnClassNetiId: "nw-button p-button-primary text-color-white" });
    }

    blurSearchNetiId = e => {
        this.setState({ searchLabelNetiId: '', addSearchBtnClassNetiId: "" });
    }

    addSenderInformation = () =>{
        let { dataTableValue, netiInformation, disableAddButton } = this.state

        if( dataTableValue.includes( netiInformation )){
            this.setState({ userExist: "User already exist in list", disableAddButton: true })
        }
        else{
            dataTableValue.push(netiInformation)
            this.setState({ dataTableValue })
        }
    }

    viewActionBody = (rowData) =>{
        return <div>
                    {/* <span>{rowData.basicMobile}</span> */}
                    <Button
                        className="p-button-danger nw-action-button"
                        icon="fas fa-times"
                        onClick={e=>this.onDeleteRow(rowData)}
                    />
                </div>
    }

    onDeleteRow = (rowData) =>{
        let { dataTableValue } = this.state
        const filteredItems = dataTableValue.filter(function(item) {
            return item !== rowData
        })

        this.setState({ dataTableValue: filteredItems })
    }

    

    render() {
        let { smsCounterObj, messageBodyMaxlength, sendSMSorMailObj, userExist, netiInformation, dataTableValue, disableAddButton, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let header = <div className="header-title">
                        <div className="header-title-left">
                            Added Contact List
                        </div>


                        <div className="header-title-right">
                        </div>
                    </div>;
        
        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={el => (this.growl = el)} />
                <div className="main-section">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                            <div className="p-grid seprator-inside">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Neti ID <span>*</span></span>
                                                <InputText
                                                    value={this.state.netiID}
                                                    placeholder="Enter Neti ID"
                                                    style={{ width: "15%" }}
                                                    type="text"
                                                    name="netiID"
                                                    onChange={this.netiIDHandler}
                                                    keyfilter="num"
                                                    onFocus={this.focusSearchNetiId}
                                                    onBlur={this.blurSearchNetiId}
                                                    maxLength={10}
                                                />
                                                <Button
                                                    className={"p-button-animation " + this.state.addSearchBtnClassNetiId}
                                                    label={this.state.searchLabelNetiId}
                                                    icon="fas fa-search"
                                                    onClick={this.netiIdCheckHandler}
                                                    onFocus={this.focusSearchNetiId}
                                                    onBlur={this.blurSearchNetiId}
                                                />
                                            </div>

                                            <span className="error-message">{this.state.errors["netiIDError"]}</span>
                                        </div>


                                        {this.state.secondSearchView ?
                                            <div className="p-col-12 p-xl-12">
                                                <div className="nw-search-view">
                                                    {this.state.secondSearchErrorVisible ?
                                                        <center className="error-message">{this.state.secondSearchErrorMsg || 'No Data Found'}</center> :

                                                        <div>
                                                            {
                                                                this.state.secondSearchIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    this.state.netiInformation.fullName === '' ? '' :

                                                                        <div className="p-col-12 p-xl-12">
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon nw-inputgroup-label">Name</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {this.state.netiInformation.fullName || '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                            }

                                                            {
                                                                this.state.secondSearchIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    this.state.netiInformation.fullName === '' ? '' :

                                                                        <div className="p-col-12 p-xl-12">
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon nw-inputgroup-label"> Mobile No</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {this.state.netiInformation.basicMobile || '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div> : ''
                                        }

                                        <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                            <div className="required-field">
                                                <span>{ userExist }</span>
                                            </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Add"
                                                onClick={this.addSenderInformation}
                                                icon="fas fa-check"
                                                disabled={ disableAddButton }
                                            />
                                        </div>

                                        <div className="p-col-12"/>
                                        <div className="p-col-12"/>
                                        <div className="p-col-12"/>

                                        <div className="p-col-12 p-lg-12 p-xl-12 p-col-nogutter">
                                            {
                                                this.state.dataTableIsLoading ?
                                                    this.NetiContentLoader.MyPointTableLoader():
                                                    <div className="nw-data-table">
                                                        <DataTable
                                                            value={dataTableValue}
                                                            header={header}
                                                            responsive={true}
                                                        >
                                                            <Column field="customNetiID" header="Neti ID" filter={true} />
                                                            <Column field="fullName" header="Name" filter={true} />
                                                            <Column field="basicMobile" header="Mobile No." filter={true} />
                                                            <Column field="" header="Action" body={this.viewActionBody} style={{width: '80px'}} />
                                                        </DataTable>

                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-4">
                            <SendSmsAndNetiMail smsActive={ true } netiMailActive={ true } userInfo={ dataTableValue }/>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
