import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';

// custom imports
import { CommonClassGroupDropdown } from "../CommonClassGroupDropdown";
import { NetiFileHandler } from '../../../../utils/NetiFileHandler';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { DwsService } from '../../../../service/dwsPoint/DwsService';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { NetiDateUtils } from "../../../../utils/NetiDateUtils";
import staticImg from '../../../../assets/images/avatar.png';

let cmsId = 0;
export class PendingApplicant extends Component {

    constructor(props) {
        super(props);

        this.state = {

            dataIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,

            forSearchObj: [],
            dataTableValue: [],
            selectedRowsData: [],

            viewDialogVisible: false,
            selectedViewRowData: [],
            visibleConfirmationDialog: false,
            submitStatus: 0

            // classId: '',
            // groupId: '',
            // errors: {},

        }

        this.DwsService = new DwsService();
        this.CommonFuctionality = new CommonFuctionality();
        this.NetiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();

        this.submitSearchHandle = this.submitSearchHandle.bind(this);
        this.viewConfirmationDialog = this.viewConfirmationDialog.bind(this);
    }

    async reloadCoreUrlIdFromLocal() {

        cmsId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("cmsId .>", cmsId);

        if (typeof cmsId === 'string' || typeof cmsId === 'number') {
            this.setState({ homeReturnButton: false });
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    submitSearchHandle(commonDataObj) {

        // console.log('search-data', commonDataObj);

        let searchObj = {
            cmsId: cmsId,
            classId: parseInt(commonDataObj.classId),
            groupId: parseInt(commonDataObj.groupId),
            approvalType: 'pending'
        };

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false, forSearchObj: searchObj });
        this.DwsService.getAdmisiaApplicantAdmisionApprovalList(searchObj)
            .then(res => {

                // console.log('res-body', res);
                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('pendign-body', body);

                        if (body.messageType == 1) {
                            this.setState({ dataTableValue: body.item });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                        } else {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    }))
                }

            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    bodyActionTemplate = (rowData) => {
        return (
            <div className="text-center">
                <Button
                    className="nw-action-button info"
                    icon="fas fa-eye"
                    tooltip="View Details Info"
                    onClick={(e) => this.viewDialog(rowData)}
                />
            </div>
        );
    }

    viewDialog = (rowData, source) => {
        this.setState({ viewDialogVisible: true, selectedViewRowData: rowData });
    }

    onHideViewDialog = () => {
        this.setState({ viewDialogVisible: false, selectedViewRowData: '' });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    viewConfirmationDialog(submitStatus) {

        if (this.state.selectedRowsData && this.state.selectedRowsData.length) {
            this.setState({ visibleConfirmationDialog: true, submitStatus: submitStatus });
            this.CommonFuctionality.blurDialogBackgroundActive();
        } else {
            this.growl.show({ severity: 'warn', detail: 'Please select row first .' });
        }

    }

    onHideConfirmationDialog = () => {
        this.setState({ visibleConfirmationDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    handleApprovedOrRejectOrWaitingSubmit() {

        let { selectedRowsData, submitStatus } = this.state;
        // console.log('selectedRDatas', selectedRowsData);
        let applicantIdsArray = [];

        selectedRowsData.forEach(element => {
            applicantIdsArray.push(element.applicantId)
        });

        let requestedBody = {
            "applicantIds": applicantIdsArray,
            "applicantStatus": submitStatus,
            "cmsId": cmsId
        }

        // console.log('update-res', requestedBody);
        this.setState({ topProgressBar: true });
        this.DwsService.admisiaUpdateApplicantsStatus(requestedBody)
            .then(res => {
                // console.log('update-res', res);
                return res.json();
            }).then(body => {

                // console.log('update-body', body);
                if (body.messageType == 1) {
                    // console.log('body.message', body.message);
                    this.onHideConfirmationDialog();
                    this.setState({ dataTableIsLoading: false, topProgressBar: false });
                    this.growl.show({ life: 800, severity: 'success', summary: 'Success', detail: body.message });
                    this.setState({ selectedRowsData: [] });
                    this.submitSearchHandle(this.state.forSearchObj);
                } else { this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message }) }

            })
            .catch(error => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    render() {

        let { requestedBodyObj, dataTableValue, selectedViewRowData, submitStatus, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Admission Pending List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>;

        return (
            <div>

                <div className="p-fluid">
                    {/* <NetiCMSSetupProgress /> */}

                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} />

                    <CommonClassGroupDropdown onSubmitSearch={this.submitSearchHandle} />

                    <div className="main-section blur-section">

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selection={this.state.selectedRowsData}
                                    onSelectionChange={e => this.setState({ selectedRowsData: e.value })}
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column selectionMode="multiple" header="Mark" headerStyle={{ width: '3em' }}></Column>
                                    <Column field="registrationId" header="Registration No." filter={true} />
                                    <Column field="applicantName" header="Name" filter={true} />
                                    <Column field="gender" header="Gender" filter={true} />
                                    <Column field="mobileNo" header="Contact No." filter={true} />
                                    <Column field="examMarks" header="Exam Mark" filter={true} />
                                    <Column field="action" header="Action" body={this.bodyActionTemplate} />
                                </DataTable>

                                : this.NetiContentLoader.MyPointTableLoader()
                            }

                        </div>

                        {dataTableValue && dataTableValue.length ?

                            <div className="p-grid nw-form">
                                <div className="p-xl-3"></div>
                                <div className="p-col-12 p-xl-3 nw-button-parent">
                                    <Button
                                        label="Reject"
                                        icon="fas fa-times"
                                        className="p-button p-button-danger nw-button nw-button-center"
                                        onClick={() => this.viewConfirmationDialog(3)}
                                    // onClick={() => this.handleApprovedOrRejectOrWaitingSubmit(3)}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-3 nw-button-parent">
                                    <Button
                                        label="Waiting"
                                        icon="far fa-clock"
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        onClick={() => this.viewConfirmationDialog(4)}
                                    // onClick={() => this.handleApprovedOrRejectOrWaitingSubmit(4)}
                                    />
                                </div>

                                <div className="p-col-12 p-xl-3 nw-button-parent">
                                    <Button
                                        label="Approve"
                                        icon="fas fa-check"
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        onClick={() => this.viewConfirmationDialog(5)}
                                    // onClick={() => this.handleApprovedOrRejectOrWaitingSubmit(5)}
                                    />
                                </div>
                            </div>
                            : ""
                        }

                    </div>

                    <Dialog
                        className="nw-dialog"
                        header='View Pending Applicant Details'
                        visible={this.state.viewDialogVisible}
                        onHide={this.onHideViewDialog}
                        closable
                    >

                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-7 p-col-nogutter seprator">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-form-body">
                                            <div className="p-grid seprator-inside">

                                                <div className="p-col-12 p-lg-12 p-xl-12 product-info-dialog p-t-0">

                                                    <div class="nw-search-view no-border">
                                                        <div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Student Image</span>
                                                                    <span className="p-inputgroup-colon">:</span>

                                                                    <div style={{ paddingLeft: '22px' }}>
                                                                        {
                                                                            selectedViewRowData.fileName && selectedViewRowData.fileContent ?
                                                                                <img src={("data:image/*;base64," + selectedViewRowData.fileContent)} height='80px' width='80px' /> :
                                                                                <img src={staticImg} height='80px' width='80px' />
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Student Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{selectedViewRowData.applicantName}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon"> Gender </span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{selectedViewRowData.gender}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Religion</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{selectedViewRowData.religion}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Date of Birth</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc"> {selectedViewRowData.dob} </div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Father's Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{selectedViewRowData.fatherName}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Father's Occupation</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{selectedViewRowData.fatherOccupation}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Mother's Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{selectedViewRowData.motherName}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Mother's Occupation</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{selectedViewRowData.motherOccupation}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Guardian Mobile No. </span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{selectedViewRowData.mobileNo}</div>
                                                                </div>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon" >Address</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{selectedViewRowData.addressDetails} </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-5">

                                    <div className="p-grid inside-dialog-right-wrapper">

                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                            <div className="data-wrapper-box">

                                                <div className="nw-datatable-dataview-small-panel productInfo">

                                                    <div>
                                                        <span className="first-box-level">Registration</span>
                                                        <span>:</span>
                                                        <span className="m-l-10" > {selectedViewRowData.registrationId}</span>
                                                    </div>

                                                    <div>
                                                        <span className="first-box-level">Applicant Status</span>
                                                        <span>:</span>
                                                        <div className="badge m-l-10" >
                                                            <div className="first-box-level color-white"> Pending <span className="task-badge pending"> </span> </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                            <div className="data-wrapper-box">

                                                <div className="nw-datatable-dataview-small-panel productInfo">

                                                    <div>
                                                        <span className="second-box-level">Class</span>
                                                        <span>:</span>
                                                        <span className="m-l-10" > {selectedViewRowData.clasName}</span>
                                                    </div>

                                                    <div>
                                                        <span className="second-box-level">Group</span>
                                                        <span>:</span>
                                                        <span className="m-l-10"> {selectedViewRowData.groupName}</span>
                                                    </div>

                                                    <div>
                                                        <span className="second-box-level">Application Date</span>
                                                        <span>:</span>
                                                        <span className="m-l-10"> {NetiDateUtils.getDDhypMMhypYY(selectedViewRowData.applicationDate)}</span>
                                                    </div>

                                                    <div>
                                                        <span className="second-box-level">Application End Date</span>
                                                        <span>:</span>
                                                        <span className="m-l-10" > {NetiDateUtils.getDDhypMMhypYY(selectedViewRowData.applicationEndDate)}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </Dialog>

                    <Dialog header={submitStatus === 3 ? "Reject Applicant" : submitStatus === 4 ?
                        "Waiting Applicant" : submitStatus === 5 ? 'Approve Applicant' : ''} visible={this.state.visibleConfirmationDialog} onHide={this.onHideConfirmationDialog} className="nw-confirm-dialog">
                        <div className="p-fluid">
                            <div className="p-col-12 p-col-12">
                                <div className="confirm-wrapper">

                                    <h1>Are you want to  {submitStatus === 3 ? "permanently reject" : submitStatus === 4 ?
                                        "waiting" : submitStatus === 5 ? 'approve' : ''}
                                        <br /> these {this.state.selectedRowsData && this.state.selectedRowsData.length} Applicants?</h1>

                                    <div className="nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="No"
                                            icon="fas fa-times"
                                            onClick={this.onHideConfirmationDialog}
                                        />
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Yes"
                                            icon="fas fa-check"
                                            onClick={() => this.handleApprovedOrRejectOrWaitingSubmit()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                </div>
            </div >
        )
    }
}