import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SaSummaryService } from '../../../service/saPoint/SaSummaryService';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';


let maxDate = new Date();
export class StaffAttendanceSummary extends Component {

    constructor() {
        super();

        this.state = {
            attendanceDate: null,
            attendanceDateErrorMessage: null,
            staffAttendanceSummaryList: [],
            topProgressBar: false,
            dataTableIsLoading: false,
            errors: {}
        }
        this.saSummaryService = new SaSummaryService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    onSubmitHandler = (event, props) => {
        if (this.onSubmitErrorHandle()) {
            let selectedDate = NetiDateUtils.getDateFromStringDDMMYYYY(this.state.attendanceDate);

            if (this.state.attendanceDate != null) {
                this.setState({ attendanceDateErrorMessage: false, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })

                this.saSummaryService.getStaffAttendanceSummaryInfo(selectedDate)
                    .then(data => {

                        console.log("Data", data);


                        if (data.item) {

                            this.setState({ staffAttendanceSummaryList: data.item })
                            this.setState({ topProgressBar: false, dataTableIsLoading: false })
                        }
                        else {
                            // this.saSummaryService.Auth.handleErrorStatus(data).then(errMsg => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                            // })
                        }

                    }).catch(error => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })
                    });

            }
            else {
                this.setState({
                    attendanceDateErrorMessage: true
                })
            }
        }
    }


    onSubmitErrorHandle = () => {
        let formIsValid = true;
        let { errors } = this.state;

        if (!this.state.attendanceDate) {
            formIsValid = false;
            errors['date'] = "Date can't left empty"
        }
        else if (this.state.attendanceDate && !Date.parse(this.state.attendanceDate)) {
            formIsValid = false;
            errors['date'] = 'Invalid Date format'
        }

        this.setState({ errors })
        return formIsValid;
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, staffAttendanceSummaryList } = this.state;


        let tableHeader = (
            <div className="p-clearfix">
                HR Attendance Summary{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {staffAttendanceSummaryList ? staffAttendanceSummaryList.length : ''}{" "}
                </span>
            </div>
        );


        // let tableHeader = <span><span>Staff Attendance Summary</span><span className='float-right text-bold'></span></span>
        // let staffAttendanceSummaryInfo = []

        // if(this.state.staffAttendanceSummaryList){
        //     staffAttendanceSummaryInfo = this.state.staffAttendanceSummaryList.item 
        // }

        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>
        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12">
                                {!this.state.inputErrorMessage ? null :
                                    <div className="p-messages p-component p-messages-warn" style={{ margin: '0 0 1em 0', display: 'block' }}>
                                        <div className="p-messages-wrapper">
                                            <ul>
                                                <li>
                                                    <span className="p-messages-icon pi pi-fw pi-2x pi-exclamation-triangle"></span>
                                                    <span className="p-messages-detail">
                                                        Please Select Date
                                                </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="p-col-12 p-xl-6 p-xl-offset-2">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Date {requiredSing}</span>
                                    <Calendar
                                        value={this.state.attendanceDate}
                                        onChange={(e) => this.setState({ attendanceDate: e.value, errors: {} })}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message" >{this.state.errors['date']}</span>
                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                {/* <div> */}
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitHandler.bind(this)}
                                />
                                {/* </div>  */}
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    header={tableHeader}
                                    value={staffAttendanceSummaryList}
                                    responsive={true}
                                    paginator={true}
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 20]}
                                >

                                    <Column field="instituteId" header="Institute ID" filter={true} />
                                    <Column field="instituteName" header="Institute Name" filter={true} />
                                    <Column field="totalHr" header="Total HR" filter={true} />
                                    <Column field="attendanceTaken" header="Attendance Taken" filter={true} />
                                    <Column field="onTime" header="On Time" filter={true} />
                                    <Column field="delay" header="Delay" filter={true} />
                                    <Column field="absent" header="Total Absent" filter={true} />
                                    <Column field="onLeave" header="On Leave" filter={true} />
                                </DataTable>
                            }
                        </div>
                    </div>


                </div>

            </div>
        );
    }
}