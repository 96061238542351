import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';

// let minutes = 1000 * 60;
// let hours = minutes * 60;
// let days = hours * 24;
// let years = days * 365;

export class TeacherExperienceInfo extends Component {

    constructor(props) {
        super(props);
        this.teacherPortal = new TeacherPortal();
    }

    render() {

        let experienceContent = ''
        if (this.props.basicInfo.item) {
            console.log(this.props.basicInfo.item);
            if (this.props.basicInfo.item.staffExperience) {
                let i = 0;
                experienceContent = this.props.basicInfo.item.staffExperience.map((item) => {

                    i++
                    return (
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2" >
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Experience {i}</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Org Name</h4>
                                        <p>{item.organizationName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Address Info</h4>
                                        <p>{item.address || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Org Type</h4>
                                        <p>{item.organizationType || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Position</h4>
                                        <p>{item.position || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Responsibilities</h4>
                                        <p>{item.responsibilities || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Duration</h4>
                                        <p>{item.duration || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )



                }


                )
            }


        }

        return experienceContent
    }
}