import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { CoreSettings } from "../../../service/CoreSettings";
import { SaCoreSettingsService } from "../../../service/saPoint/SaCoreSettingsService";
import { StudentClassConfigInfo } from '../common/StudentClassConfigInfo';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { EmUserPointService } from '../../../service/emUserPoint/EmUserPointService';

let errors = {};

let workingDays = "-";
let offDays = "-";
let totalPresent = "-";
let totalAbsent = "-";
export class StudentPortalAttendance extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            yearName: '',
            academicYearList: [],
            academicYear: '',
            monthName: '',
            periodList: [],
            periodId: '',
            attendanceList: [],
            tableView: false,
            errorMsgBody: '',
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            userList: [],
            userID: '',
            userLength: '',
            insID: '',
            basicInfo: [],
            stId: '',

        }

        this.onChangeAcYear = this.onChangeAcYear.bind(this);
        this.onChangeYear = this.onChangeYear.bind(this);
        this.onChangeMonth = this.onChangeMonth.bind(this);
        this.onChangePeriod = this.onChangePeriod.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.studentPortal = new StudentPortal();
        this.coreSettings = new CoreSettings();
        this.saCoreSettingsService = new SaCoreSettingsService();

        this.emUserPointService = new EmUserPointService();
    }

    // componentWillMount() {
    //     this.fetchAcademicYearList();
    //     this.setCurrentAcademicYearToDropDown();
    //     this.fetchPeriodList();

    //     this.loadStudentInfo();
    // }
    componentWillMount(){
        this.loadStudentInfo();
    }

    loadStudentInfo = () => {

        setTimeout(() => {
            [...document.querySelectorAll('.protal-single-info-content')].map(item => {
                if (!item.querySelector('p').innerHTML.trim()) {
                    item.querySelector('p').innerHTML = '---'
                }
            })
        }, 1500);
    
        let userListtt = [];
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.emUserPointService.fetchMappedPortalList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log('Log: GoToPortal -> componentWillMount -> body......', body[0].idForStudent)
                        console.log('Log: GoToPortal -> componentWillMount -> body......', body)
                        
    
                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({userList: userListtt})
                        this.setState({userLength: body.length})
                        this.setState({stId:body[0].idForStudent})
    
                        console.log("user id inside : ", this.state.userID)
                        
                        this.studentPortal
                        .fetchStudentProfileDetailsList(
                            this.state.insID || body[0]["emDetailsInfoDTO"].instituteId ,
                            this.state.userID || body[0].idForStudent
                            // body[this.state.userID]["emDetailsInfoDTO"].instituteId,
                            // body[this.state.userID].idForStudent,
                        )
                        .then(res => {
                            if (res.status == 200) {
                              
                                return res.json().then(body => {
                                    this.setState({ topProgressBar: false, errorMsgVisible: false })
                                    this.setState({ basicInfo: body });
                                    this.fetchAcademicYearList();
                                 this.setCurrentAcademicYearToDropDown();
                                 this.fetchPeriodList();
                        
                                });
                            } else {
                                this.studentPortal.Auth.handleErrorStatus(res)
                                    .then((responseBody) => {
                                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                    });
                            }
                        })
                        .catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));
    
                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                    })
                } else {
                    this.emUserPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch((error) => {
                console.log(error)
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    
    
        this.setState({ topProgressBar: true, errorMsgVisible: false })
    };
    

    handledropdwon = (id, insId) => {
      
        this.setState({userID: id})
        this.setState({insID:insId})
    
        this.loadStudentInfo(); 
     }


    setCurrentAcademicYearToDropDown = () => {
        let academicYear = NetiDateUtils.getYear(new Date());
        this.setState({ academicYear })
    }

    fetchAcademicYearList() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.saCoreSettingsService.getGlobalInfoByTypeId(2101).then(res => {

            if (res.item) {
                this.setState({ academicYearList: res.item, academicYear: this.state.basicInfo.academicYear });
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
                this.fetchPeriodList()
            }
            else {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No data fund' })
            }


        }).catch(error => {
            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })
        });
    }

    fetchPeriodList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false });
        this.coreSettings.fetchGlobalInfoListByTypeId(2301, this.state.basicInfo.instituteId).then(res => {

            if (res.item) {
                this.setState({ periodList: res.item })
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false });
            }
            else {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No data found' })
            }

        }).catch(error =>
            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })
        );
    }

    onChangeAcYear = (e) => {
        errors["academicYear"] = "";
        this.setState({ academicYear: e.target.value, errors: errors })
    }

    onChangeYear = (e) => {
        errors["yearName"] = "";
        this.setState({ yearName: e.target.value, errors: errors })
    }

    onChangeMonth = (e) => {
        errors["monthName"] = "";
        this.setState({ monthName: e.target.value, errors: errors })
    }
    onChangePeriod(e) {
        errors["period"] = "";
        this.setState({ periodId: e.target.value, errors: errors })
    }

    submitSearch() {
        this.setState({ attendanceList: [] });

        workingDays = "-";
        offDays = "-";
        totalPresent = "-";
        totalAbsent = "-";

        if (this.handleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });

            let requestObjct = {
                'instituteId': this.state.basicInfo.instituteId,
                'studentId': this.state.basicInfo.studentId,
                'academicYear': this.state.academicYear,
                'year': this.state.yearName,
                'monthName': this.state.monthName,
                'periodId': this.state.periodId,
            }

            this.studentPortal.fetchStudentAttendanceList(requestObjct).then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        console.log(body.item)
                        if (body.item == null || body.item.details.length === 0) {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No data found' });
                        } else {
                            workingDays = body.item.workingDay;
                            totalPresent = body.item.present;
                            totalAbsent = body.item.absent;
                            offDays = body.item.totalDay - body.item.workingDay;
                            this.setState({ attendanceList: body.item.details });
                            this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        }
                    });
                }
                else {
                    this.studentPortal.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }

            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            })
        }
    }

    handleError() {

        errors = {};
        let formIsValid = true;

        if (!this.state.academicYear) {
            formIsValid = false;
            errors["academicYear"] = "Academic Year can't left empty.";
        }
        if (this.state.yearName === '') {
            formIsValid = false;
            errors["yearName"] = "Year can't left empty.";
        }
        if (this.state.monthName === '') {
            formIsValid = false;
            errors["monthName"] = "Month can't left empty.";
        }
        if (this.state.periodId === '') {
            formIsValid = false;
            errors["period"] = "Period can't left empty.";
        }
        else {
            return formIsValid;
        }
        this.setState({ errors: errors });
    }

    onAcademicYearFilter = () => {
        if (this.state.academicYearFiltering) this.setCurrentAcademicYearToDropDown();
        this.setState({ academicYearFiltering: !this.state.academicYearFiltering });
    }

    render() {

        var rows = [];
         let l = this.state.userLength;
         this.state.userList.map((item) =>{
            if(l > rows.length){
                rows.push(<a  onClick={() => this.handledropdwon(item.idForStudent, item["emDetailsInfoDTO"].instituteId)} >    {item.idForStudent}  </a>)
            }
        })
   

        let monthNameOptions = [
            {
                value: "January",
                label: "January"
            },
            {
                value: "February",
                label: "February"
            },
            {
                value: "March",
                label: "March"
            },
            {
                value: "April",
                label: "April"
            },
            {
                value: "May",
                label: "May"
            },
            {
                value: "June",
                label: "June"
            },
            {
                value: "July",
                label: "July"
            },
            {
                value: "August",
                label: "August"
            },
            {
                value: "September",
                label: "September"
            },
            {
                value: "October",
                label: "October"
            },
            {
                value: "November",
                label: "November"
            },
            {
                value: "December",
                label: "December"
            }
        ];

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let periodOptions = [];
        if (this.state.periodList && this.state.periodList.length) {
            periodOptions = this.state.periodList.map(item => ({
                value: item.id,
                label: item.name
            }));
        }

        let acYearOptions = [];
        if (this.state.academicYearList && this.state.academicYearList.length) {
            acYearOptions = this.state.academicYearList.map(yearItem => ({
                value: yearItem.name,
                label: yearItem.name
            }));
        }

        let yearOptions = [];
        let currentYear = this.state.basicInfo.academicYear;


        let futureYear = parseInt(currentYear) + 1;
        let previousYear = parseInt(currentYear) - 1;

        if (this.state.academicYear !== "") {
            yearOptions.push(
                {
                    key: 1,
                    label: futureYear,
                    value: futureYear
                },
                {
                    key: 2,
                    label: currentYear,
                    value: currentYear
                },
                {
                    key: 3,
                    label: previousYear,
                    value: previousYear
                }
            );
        }

        let tableHeader = <div className="header-title">
                        <div className="header-title-left">
                            Attendance Information 
                        </div>
                        <div className="header-title-right">
                            
                        </div>
                    </div>;


         
        return (
           

            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid nw-form">

                    <div className="p-col-12" style={{position: "relative"}}>
                        <StudentClassConfigInfo basicInfo={this.state.basicInfo} />
                        <div className='dropdown'>
                                <button className="dropbtn">
                                {/* <i className="fa fa-caret-down"></i> */}
                                <span class="fas fa-align-right p-c p-button-icon-left"></span>
                                </button>
                                <div className="dropdown-content">
                                    {rows}
                                </div>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <p className='profile-custom-table-header'>Student Attendance</p>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 seprator">
                            <div className="nw-form-body">
                                <div className="p-grid seprator-inside">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-xl-4 p-xl-offset-8 p-col-5 p-offset-7">
                                            <Button
                                                label="Academic Year"
                                                icon="fas fa-filter"
                                                iconPos="right"
                                                onClick={this.onAcademicYearFilter}
                                            />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        {this.state.firstDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Academic Year <span>*</span></span>
                                                {this.state.academicYearFiltering ?
                                                    <Dropdown
                                                        options={acYearOptions}
                                                        placeholder="Select Academic Year"
                                                        value={this.state.academicYear}
                                                        onChange={this.onChangeAcYear}
                                                        autoWidth={false}
                                                        name='academicYear'
                                                        filter={true}
                                                    />
                                                    :
                                                    <Dropdown
                                                        disabled
                                                        options={acYearOptions}
                                                        placeholder="Select Academic Year"
                                                        value={this.state.academicYear}
                                                        onChange={this.onChangeAcYear}
                                                        autoWidth={false}
                                                        name='academicYear'
                                                    />
                                                }
                                            </div>
                                        }
                                        <span className="error-message"> {this.state.academicYear ? '' : this.state.errors['academicYear']} </span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Year <span>*</span></span>
                                            <Dropdown
                                                placeholder='Select Year'
                                                name="yearName"
                                                options={yearOptions}
                                                filterBy="label,value"
                                                value={this.state.yearName}
                                                onChange={this.onChangeYear}
                                                filter={true}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["yearName"]}</span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Month <span>*</span></span>
                                            <Dropdown
                                                placeholder='Select Month'
                                                name="monthName"
                                                options={monthNameOptions}
                                                value={this.state.monthName}
                                                onChange={this.onChangeMonth}
                                                filter={true}
                                                autoWidth={false}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["monthName"]}</span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        {this.state.secondDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Period <span>*</span></span>
                                                <Dropdown
                                                    placeholder='Select Period'
                                                    name="period"
                                                    options={periodOptions}
                                                    value={this.state.periodId}
                                                    onChange={this.onChangePeriod}
                                                    filter={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                        }
                                        <span className='error-message'>{this.state.errors["period"]}</span>
                                    </div>

                                    <div className="p-col-12 -xl-12">

                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Search"
                                            icon="fas fa-search"
                                            onClick={event => this.submitSearch(event)}
                                        />
                                        <br />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-lg-6">
                            <div className="nw-circle-panel right-sided-circle-pannel">

                                <div className="circle-panel circle-panel-first">
                                    <span>{workingDays}</span>
                                    <div className="circle-panel-label">Working Days</div>
                                </div>


                                <div className="circle-panel circle-panel-second">
                                    <span>{offDays}</span>
                                    <div className="circle-panel-label">Off Days</div>
                                </div>

                                <div className="circle-panel circle-panel-third">
                                    <span>{totalPresent}</span>
                                    <div className="circle-panel-label">Total Present</div>
                                </div>

                                <div className="circle-panel circle-panel-fourth">
                                    <span>{totalAbsent}</span>
                                    <div className="circle-panel-label">Total Absent</div>
                                </div>

                            </div>
                        </div>

                    </div>



                    {/* <div className="card p-col-12 p-lg-xl p-xl-12">

                        <div className="p-grid">
                            <div className="p-grid p-col-3" >
                                <div className="p-col-6"> Working Days:</div>
                                <div className="p-col-6" > {workingDays}</div>
                            </div>
                            <div className="p-grid p-col-3" >
                                <div className="p-col-6"> Off Days:</div>
                                <div className="p-col-6" > {offDays}</div>
                            </div>
                            <div className="p-grid p-col-3" >
                                <div className="p-col-6"> Total Present:</div>
                                <div className="p-col-6" > {totalPresent}</div>
                            </div>
                            <div className="p-grid p-col-3" >
                                <div className="p-col-6"> Total Absent:</div>
                                <div className="p-col-6" > {totalAbsent}</div>
                            </div>

                        </div>
                    </div> */}


                    <div className="p-col-12 p-xl-12 p-0">
                        {this.state.attendanceList ?
                            this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader()
                                :
                                <div className="nw-data-table">
                                    <DataTable
                                        header={ tableHeader}
                                        value={this.state.attendanceList}
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="date" header="Date" sortable={true} filter={true} />
                                        <Column field="dayName" header="Day" sortable={true} filter={true} />
                                        <Column field="status" header="Status" sortable={true} filter={true} />
                                    </DataTable>

                                </div>
                            : ''
                        }
                    </div>

                </div>
            </div >
        );
    }
}