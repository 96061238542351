import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { AdminPointDownloadCornerList } from './AdminPointDownloadCornerList';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';


export class AdminPointDownloadCorner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roleIDList: [],
            errors: {},
            proPic: {
                extention: '',
                contentPic: '',
                contentName: '',
            },
            downloadCornerInfo: {
                downloadCornerID: '',
                fileTitle: "",
                fileDetails: "",
                fileDownloadAddress: "",
                fileSerial: '',
                expiryDate: "",
                iconSaveOrEditable: false,
                coreUserRoleDTO: {
                    coreRoleID: ''
                }
            },
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            firstDropdownIsLoading: false
        }

        this.NetiContentLoader = new NetiContentLoader();
        this.AdminPointService = new AdminPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.ValidatorUtility = new ValidatorUtility();
    }


    componentWillMount = () => {
        this.fetchUserRole();
    }

    fetchUserRole = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.AdminPointService.fetchUserRoleList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ roleIDList: body, firstDropdownIsLoading: false, topProgressBar: false });
                    })
                } else {
                    this.AdminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeFileTitle = (e) => {
        let { downloadCornerInfo, errors } = this.state;
        errors["title"] = '';
        downloadCornerInfo.fileTitle = e.target.value;
        this.setState({ downloadCornerInfo, errors });
    }

    onChangeFileDetails = (e) => {
        let { downloadCornerInfo, errors } = this.state;
        errors["details"] = '';
        downloadCornerInfo.fileDetails = e.target.value;
        this.setState({ downloadCornerInfo, errors });
    }

    onChangeUrl = (e) => {
        let { downloadCornerInfo, errors } = this.state;
        errors["url"] = '';
        downloadCornerInfo.fileDownloadAddress = e.target.value;
        this.setState({ downloadCornerInfo, errors });
    }

    pointTypeHandler = (event) => {
        let { downloadCornerInfo, errors } = this.state;
        errors["pointFor"] = '';
        downloadCornerInfo.coreUserRoleDTO.coreRoleID = event.target.value;
        this.setState({ downloadCornerInfo, errors });
    }

    onChangeExpiryDate = (e) => {
        let { downloadCornerInfo, errors } = this.state;
        errors["expiryDate"] = '';
        downloadCornerInfo.expiryDate = e.target.value


        this.setState({ downloadCornerInfo, errors });

    }

    onChangeFileSerial = (e) => {
        let { downloadCornerInfo, errors } = this.state;
        errors["serial"] = '';
        downloadCornerInfo.fileSerial = e.target.value;
        this.setState({ downloadCornerInfo, errors });

    }

    onImageUpload(e) {
        this.state.errors["attachment"] = '';

        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });

        }
    }

    onSubmitHandler = () => {
        let { downloadCornerInfo } = this.state;
        if (this.state.proPic.contentPic !== null) {
            downloadCornerInfo.fileIconContent = this.state.proPic.contentPic;
            downloadCornerInfo.iconSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            downloadCornerInfo.fileIconName = Date.now() + extention;
        }

        console.log("downloadCornerInfo", downloadCornerInfo);
        
        if (this.downloadCornerSaveFormError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.AdminPointService.createDownloadCorner(downloadCornerInfo)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                    } else {
                        this.AdminPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }

    }

    downloadCornerSaveFormError = () => {
        let { errors, downloadCornerInfo } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (downloadCornerInfo.fileTitle === '') {
            formIsValid = false;
            errors["title"] = "Title can't left empty";
        }
        if (downloadCornerInfo.fileDetails === '') {
            formIsValid = false;
            errors["details"] = "Details can't left empty";
        }
        if (downloadCornerInfo.fileDownloadAddress === '') {
            formIsValid = false;
            errors["url"] = "URL can't left empty.";
        }
        else if (!this.ValidatorUtility.isUrlValid(downloadCornerInfo.fileDownloadAddress) || downloadCornerInfo.fileDownloadAddress.includes("..")) {
            formIsValid = false;
            errors["url"] = "Invalid URL";
        }
        if (downloadCornerInfo.coreUserRoleDTO.coreRoleID === '') {
            formIsValid = false;
            errors["pointFor"] = "Point type can't left empty";
        }
        if (downloadCornerInfo.expiryDate === '') {
            formIsValid = false;
            errors["expiryDate"] = "Expiry date can't left empty";
        }
        if (downloadCornerInfo.fileSerial === '') {
            formIsValid = false;
            errors["serial"] = "Serial can't left empty";
        }
        if (this.state.proPic.contentName === '') {
            formIsValid = false;
            errors["attachment"] = "Attachment can't left empty";
        }
        this.setState({ errors });
        return formIsValid;
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let pointType = [];
        if (this.state.roleIDList && this.state.roleIDList.length) {
            pointType = this.state.roleIDList.map(item => ({
                value: item.coreRoleID,
                label: item.coreRoleNote
            }));
        }
        return (
            <div className="p-fluid" >
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-form-body">

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Title <span>*</span></span>
                                    <InputText
                                        id="title"
                                        placeholder="Enter Title"
                                        onChange={this.onChangeFileTitle}
                                        value={this.state.downloadCornerInfo.fileTitle}
                                        showClear={true}
                                        style={{ width: "100%" }}
                                        type="text"
                                        name="title"
                                    />
                                </div>
                                <span className="error-message">{errors["title"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                    <InputTextarea
                                        placeholder="Enter Details"
                                        onChange={this.onChangeFileDetails}
                                        value={this.state.downloadCornerInfo.fileDetails}
                                        rows={3}
                                        cols={30}
                                        autoResize={true}
                                        id="detail"
                                        name="details"
                                    />
                                </div>
                                <span className='error-message'>{errors['details']}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">URL <span>*</span></span>
                                    <InputText
                                        id="url"
                                        onChange={this.onChangeUrl}
                                        value={this.state.downloadCornerInfo.fileDownloadAddress}
                                        placeholder="Enter URl"
                                        style={{ width: "100%" }}
                                        type="text"
                                        name="url"
                                    />
                                </div>
                                <span className='error-message'>{errors['url']}</span>
                            </div>


                            <div className="p-col-12 p-xl-12">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Point For <span>*</span></span>
                                        <Dropdown
                                            id="pointFor"
                                            placeholder="Select Point"
                                            value={this.state.downloadCornerInfo.coreUserRoleDTO.coreRoleID}
                                            options={pointType}
                                            onChange={(e) => this.pointTypeHandler(e)}
                                            showClear={true}
                                            autoWidth={false}
                                            name="pointFor"
                                        />
                                    </div>
                                }
                                <span className='error-message'>{errors['pointFor']}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Expiry Date <span>*</span></span>
                                    <Calendar
                                        id="expiryDate"
                                        name="expiryDate"
                                        dateFormat="dd/mm/yy"
                                        showIcon={true}
                                        value={this.state.downloadCornerInfo.expiryDate}
                                        onChange={(e) => this.onChangeExpiryDate(e, this.props)}
                                        placeholder="Enter Expiry Date"
                                        className="custom-calender-input"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        readOnlyInput={true}
                                    />
                                </div>
                                <span className="error-message">{errors['expiryDate']}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Serial <span>*</span></span>
                                    <InputText
                                        id="serial"
                                        onChange={this.onChangeFileSerial}
                                        value={this.state.downloadCornerInfo.fileSerial}
                                        keyfilter="pint"
                                        placeholder="Enter serial"
                                        style={{ width: "100%" }}
                                        type="text"
                                        name="serial"
                                    />
                                </div>
                                <span className='error-message'>{errors['serial']}</span>

                            </div>

                            <div className="p-col-12 p-xl-12 nw-upload-button">
                                <FileUpload
                                    id="attachments"
                                    chooseLabel="Click to Attach File"
                                    mode="basic"
                                    accept="image/*"
                                    maxFileSize={1000000}
                                    onSelect={this.onImageUpload.bind(this)}
                                    auto={true}
                                    style={{ height: '30px', textAlign: 'left', position: 'relative' }} />
                                <center>
                                    {this.state.proPic.contentName}
                                </center>
                                <center><span className='error-message'>{errors['attachment']}</span></center>

                            </div>

                            <div className="p-col-12 p-xl-12" />

                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                <div className="required-field">
                                    (<span>*</span>) required fields
                                    </div>

                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Save"
                                    icon="fas fa-check"
                                    onClick={this.onSubmitHandler}

                                />
                            </div>

                        </div>

                    </div>
                    <div className="p-col-12">
                        {this.state.dataTableIsLoading ? "" :
                            <AdminPointDownloadCornerList />
                        }
                    </div>
                </div>
            </div>
        );
    }
}