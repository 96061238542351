import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { Workbook } from 'react-excel-workbook';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';
import { SplitButton } from 'primereact/splitbutton';

// pdf
let subTitleColumn = [];
let subTitleList = [];
let selectedPointName = '';

export class PointExpiryInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            dataTableValue: [],
            pointTypeId: '',
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }

        this.adminPointService = new AdminPointService();
        this.managementPointService = new ManagementPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.NetiContentLoader = new NetiContentLoader();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    componentWillMount() {
        this.fetchPointType();
    }

    fetchPointType() {

        this.adminPointService.fetchUserRoleList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ pointTypeList: body });
                    })
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangePointType = e => {
        let { errors } = this.state;
        errors['pointType'] = '';
        this.setState({ pointTypeId: e.target.value, errors });
    }

    onSubmitGetPointInfo() {

        if (this.searchHandleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true });
            this.managementPointService.fetchExpiryPointInfoList(this.state.pointTypeId)
                .then(res => {

                    if (res.status == 200) {

                        return res.json().then((body) => {

                            for (let i = 0; i < body.length; i++) {

                                body[i].netiId = body[i].netiId.toString(); // for pdf & excel

                                if (body[i].mobileNo == null) {
                                    body[i].mobileNoDownload = '';
                                } else {
                                    body[i].mobileNoDownload = "+88 " + body[i].mobileNo;
                                }

                                if (body[i].createDate == null) {
                                    body[i].createDate = '';
                                } else {
                                    body[i].createDate = NetiDateUtils.getAnyShortForm(body[i].createDate, 'DD-MMM-YYYY');
                                }

                                if (body[i].expireDate == null) {
                                    body[i].expireDate = '';
                                } else {
                                    body[i].expireDate = NetiDateUtils.getAnyShortForm(body[i].expireDate, 'DD-MMM-YYYY');
                                }

                            }

                            // for (let i = 0; i < body.length; i++) {
                            //     if (body[i].assignEnableStatus == 1) {
                            //         body[i].assignEnableStatus = 'Yes';
                            //     } else if (body[i].assignEnableStatus == 0) {
                            //         body[i].assignEnableStatus = 'No';
                            //     }
                            // }
                            this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                        })
                    }
                    else {
                        this.managementPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                            })
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }

    searchHandleError = () => {
        let { errors } = this.state;
        let formIsValid = true;
        if (this.state.pointTypeId === '') {
            formIsValid = false;
            errors["pointType"] = "Point Type can't left empty";
        }
        this.setState({ errors });
        return formIsValid;
    }

    export = () => {
        this.dt.exportCSV();
    }

    mobileNoTemplet = rowData => {
        return rowData.mobileNo
    }

    exportPdf() {

        let mainTitle = "Point Expiry Info";
        let pdfColumns = [
            { title: "Neti ID", dataKey: "netiId" },
            { title: "Name", dataKey: "name" },
            { title: "Mobile No.", dataKey: "mobileNoDownload" },
            { title: "Assign Date", dataKey: "createDate" },
            { title: "Expiry Date", dataKey: "expireDate" },
            { title: "Days Left", dataKey: "daysLeft" },
        ]
        let tableColStyles = {};

        pdfDownloadPortrait_TableWithSubTitle(mainTitle, 76, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableColStyles, "Point_Info.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue } = this.state;

        // pdf reletaed task
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" }
        ];
        subTitleList = [
            { A: "Point Type", B: selectedPointName }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = selectedPointName + "_Info.xlsx";
        let excelSheetName = selectedPointName + "_Info";
        excelColumns = [
            <Workbook.Column value="netiId" label="Neti ID" />,
            <Workbook.Column value="name" label="Name" />,
            <Workbook.Column value="mobileNoDownload" label="Mobile No." />,
            <Workbook.Column value="createDate" label="Assign Date" />,
            <Workbook.Column value="expireDate" label="Expiry Date" />,
            <Workbook.Column value="daysLeft" label="Days Left" />,
        ];
        // end excel

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Point Expiry Info</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let pointTypeOptions = [];
        if (this.state.pointTypeList && this.state.pointTypeList.length) {
            pointTypeOptions = this.state.pointTypeList.map(item => ({
                value: item.coreRoleID,
                label: item.coreRoleNote
            }));

            // for pdf & excel
            this.state.pointTypeList.filter(item => {
                if (item.coreRoleID == this.state.pointTypeId) { selectedPointName = item.coreRoleNote; }
            })

        }

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className='main-section'>

                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-9 p-lg-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Point Type <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Point Type'
                                            options={pointTypeOptions}
                                            name="pointType"
                                            value={this.state.pointTypeId}
                                            onChange={this.onChangePointType}
                                            autoWidth={false}
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["pointType"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-3 p-lg-12 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitGetPointInfo.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="nw-data-table">
                            <div className="p-col-12 p-lg-12 p-xl-12">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        : <div>
                                            <DataTable
                                                header={tableHeader}
                                                responsive={true}
                                                selectionMode="single"
                                                paginator={true}
                                                rowsPerPageOptions={[10, 20, 30]}
                                                rows={10}
                                                value={this.state.dataTableValue}
                                                ref={(el) => { this.dt = el; }}
                                            //selection={this.state.dataTableSelection}
                                            >
                                                <Column field="netiId" header="Neti ID" filter={true} />
                                                <Column field="name" header="Name" filter={true} />
                                                <Column field="mobileNoDownload" header="Mobile No." body={this.mobileNoTemplet} filter={true} />
                                                <Column field="createDate" header="Assign Date" filter={true} />
                                                <Column field="expireDate" header="Expiry Date" filter={true} />
                                                <Column field="daysLeft" header="Days Left" filter={true} />

                                            </DataTable>
                                        </div>
                                }
                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                <div style={{ display: 'none' }}>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                label="Download Excel"
                                                id="clickExcelBtn"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>

                            </div >

                            {/* <DataTable
                                header={header}
                                value={this.state.dataTableValue}
                                ref={(el) => { this.dt = el; }}
                                style={{ display: "none" }}
                            >
                                <Column field="netiId" header="Neti ID" filter={true} />
                                <Column field="name" header="Name" filter={true} />
                                <Column field="mobileNoDownload" header="Mobile No." filter={true} />
                                <Column field="createDate" header="Assign Date"  filter={true} />
                                <Column field="expireDate" header="Expiry Date" filter={true} />
                                <Column field="daysLeft" header="Days Left" filter={true} />

                            </DataTable> */}

                        </div>
                    </div>

                    {/* </div> */}

                </div>
            </div>
        )
    }
}