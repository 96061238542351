
import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import { DwsService } from '../../service/dwsPoint/DwsService';

let errors = {};

export class NewInstituteUrlMap extends React.Component {

    constructor() {
        super();
        this.state = {
            status: 0,
            approvedStatus: '',
            dataTableValue: [],
            mapInstituteInfoObj: {
                approvedStatus: '',
            },
            visible: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            errors: {}

        }

        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.supportPointService = new SupportPointService();
        this.DwsService = new DwsService();

    }

    componentWillMount() {
        this.getAllPendingInstituteList();
    }

    getAllPendingInstituteList() {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.DwsService.getNetiCMSPendingList()
            .then(res => {
                if (res.status == 200) {
                    console.log('res-', res);
                    return res.json().then((body) => {
                        console.log('res-body', body);

                        for (let i = 0; i < body.item.length; i++) {
                            if (body.item[i].approvedStatus === 0) {
                                body.item[i].approvedStatus = 'Pending';
                            }
                        }
                        this.setState({ dataTableValue: body.item });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                    });
                }
                else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    onChangeStatus = (e) => {
        let { mapInstituteInfoObj } = this.state
        errors["transactionAndApproveError"] = ''
        this.setState({ approvedStatus: e.target.value });
        this.setState({ errors });
    }

    actionBodyTemplete = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-edit"
                tooltip="Update"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    viewDialog(rowData) {
        let { approvedStatus } = this.state;
        this.setState({ mapInstituteInfoObj: {} })
        this.setState({ visible: true });
        if (rowData.approvedStatus === 'Pending') {
            approvedStatus = 0;
            this.setState({ approvedStatus });
        }
        this.setState({ mapInstituteInfoObj: rowData })
    }

    onHide() {
        this.setState({ visible: false });
    }

    onSubmitUpdate() {

        let { mapInstituteInfoObj } = this.state;

        this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' });
        mapInstituteInfoObj.approvedStatus = this.state.approvedStatus;
        this.setState({ mapInstituteInfoObj });
        console.log('mapInstituteInfoObj', mapInstituteInfoObj);
        this.DwsService.updateInstituteMapStatus({ 'cmsMappingId': mapInstituteInfoObj.cmsMappingId, 'approvedStatus': mapInstituteInfoObj.approvedStatus })
            .then(res => {

                if (res.status === 202) {
                    this.setState({ topProgressBar: false, errorMsgVisible: false, errorMsgBody: '' });
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Mapping status successfully updated.' })
                    this.onHide();
                    this.getAllPendingInstituteList();
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }

            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
            });

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, mapInstituteInfoObj } = this.state;

        let statusOptions = [
            { label: 'Pending', value: 0 },
            { label: 'Approve', value: 1 },
            { label: 'Reject', value: 2 },
        ]

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> All NetiCMS Mapping Request List <span style={{ float: 'right' }}> Total Found: {this.state.dataTableValue.length} </span></div>

        return (

            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid">
                    <div className="nw-form">

                        <div className="p-col-12 p-xl-12">
                            <Growl ref={(el) => this.growl = el} />

                            <div className="nw-data-table nw-data-table-tabview">
                                {!this.state.dataTableIsLoading ?
                                    <div>
                                        <DataTable
                                            header={tableHeader}
                                            value={this.state.dataTableValue}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                        >
                                            <Column field="urlName" header="Mapping Title" filter={true} />
                                            <Column field="instituteName" header="Institute Name" filter={true} />
                                            <Column field="instituteId" header="Institute ID" filter={true} />
                                            <Column field="instituteContact" header="Institute Contact No." filter={true} />
                                            <Column field="approvedStatus" header="Status" filter={true} />
                                            <Column field="" header="Action" style={{ width: "80px" }} body={this.actionBodyTemplete} />
                                        </DataTable>
                                    </div>
                                    : this.NetiContentLoader.MyPointTableLoader()
                                }
                            </div>
                        </div>

                        <Dialog
                            className="nw-dialog"
                            header="NetiCMS Map Request"
                            visible={this.state.visible}
                            onHide={this.onHide}
                        >
                            <div className="p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-col-12">
                                            <Fieldset legend="Institute Information">
                                                <div className="p-grid nw-form">

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">Mapping Title</span>
                                                            <div className="nw-inputgroup-desc">{mapInstituteInfoObj.urlName}</div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">Institute Name</span>
                                                            <div className="nw-inputgroup-desc">{mapInstituteInfoObj.instituteName}</div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">Institute ID</span>
                                                            <div className="nw-inputgroup-desc">{mapInstituteInfoObj.instituteId}</div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">Mobile No.</span>
                                                            <div className="nw-inputgroup-desc">{mapInstituteInfoObj.instituteContact}</div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </Fieldset>

                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-grid nw-form">
                                                <div className="p-col-12 p-lg-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Status <span>*</span></span>
                                                        <Dropdown
                                                            name="status"
                                                            options={statusOptions}
                                                            value={this.state.approvedStatus}
                                                            onChange={this.onChangeStatus}
                                                            filter={true}
                                                            style={{ "width": "100%" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.mapInstituteInfoObj && this.state.approvedStatus ?
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-grid nw-form">
                                                    <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            label="Update"
                                                            icon="far fa-save"
                                                            onClick={e => this.onSubmitUpdate(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            : ''
                                        }

                                    </div>
                                </div>
                            </div>
                        </Dialog>

                    </div>

                </div>
            </div>
        )
    }
}