import React, { Component } from "react";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { SaCoreSettingsService } from "../../../service/saPoint/SaCoreSettingsService";
import { StudentClassConfigInfo } from "../common/StudentClassConfigInfo";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';

import { Panel } from "primereact/panel";
export class Inventory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      academicYearList: [],
      academicYear: "",
      errors: {},
      tableDetails: [],
      monthName: "",
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',
      firstDropdownIsLoading: false
    };
    this.onChangeMonth = this.onChangeMonth.bind(this);
    this.onChangeAcYear = this.onChangeAcYear.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.studentPortal = new StudentPortal();
    this.saCoreSettingsService = new SaCoreSettingsService();
    this.NetiContentLoader = new NetiContentLoader();
  }

  onChangeMonth = e => {
    let { errors } = this.state;
    errors["monthName"] = "";
    this.setState({ monthName: e.target.value, errors })
  }
  onChangeAcYear = e => {
    let { errors } = this.state;
    errors["academicYear"] = "";
    this.setState({ academicYear: e.target.value, errors })
  };

  componentWillMount() {
    this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
    this.saCoreSettingsService.getGlobalInfoByTypeId(2101).then(res => {
      if (res.item) {
        this.setState({ academicYearList: res.item });
        this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
      } else {
        this.saCoreSettingsService.Auth.handleErrorStatus(res)
          .then((responseBody) => {
            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
          });

      }
    }).catch(error => {
      this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Unable to load data table. Please check connection" })
    });
    this.setCurrentAcademicYearToDropDown();
  }



  setCurrentAcademicYearToDropDown = () => {
    let academicYear = NetiDateUtils.getYear(new Date());
    this.setState({ academicYear })
  }


  handleSubmit(e) {
    this.setState({ tableDetails: [] });
    if (this.handleError()) {
      this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
      this.studentPortal.fetchfindPaidInventoryDetailsByStudentId(this.props.basicInfo.instituteId, this.props.basicInfo.studentId, this.state.academicYear, this.state.monthName)
        .then(res => {
          if (res.status == 200) {
            return res.json().then(body => {
              if (body.msgType === 0) {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: "No data found" });
              } else if (body.msgType === 1) {
                this.setState({ tableDetails: body.item, topProgressBar: false, dataTableIsLoading: false })
              }
            });
          } else {
            return res.json().then(errBody => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: errBody.message })
            )
          }
        }).catch(error => {
          this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Unable to load data table. Please check connection" })
        });
    }
  }

  handleError = () => {

    let { errors } = this.state;
    let formIsValid = true;

    if (!this.state.academicYear) {
      formIsValid = false;
      errors["academicYear"] = "Academic Year can't left empty.";
    }
    if (this.state.monthName === '') {
      formIsValid = false;
      errors["monthName"] = "Month can't left empty.";
    }
    else {
      return formIsValid;
    }
    this.setState({ errors });
  }

  onAcademicYearFilter = () => {
    if (this.state.academicYearFiltering) this.setCurrentAcademicYearToDropDown();
    this.setState({ academicYearFiltering: !this.state.academicYearFiltering });
  }

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    let itemTableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
      Item List
  <div
        style={{ color: "white", display: "flex", float: "right" }}
      >
        Total Found: {this.state.tableDetails.length}
      </div>
    </div>;
    let monthNameOptions = [
      {
        value: "January",
        label: "January"
      },
      {
        value: "February",
        label: "February"
      },
      {
        value: "March",
        label: "March"
      },
      {
        value: "April",
        label: "April"
      },
      {
        value: "May",
        label: "May"
      },
      {
        value: "June",
        label: "June"
      },
      {
        value: "July",
        label: "July"
      },
      {
        value: "August",
        label: "August"
      },
      {
        value: "September",
        label: "September"
      },
      {
        value: "October",
        label: "October"
      },
      {
        value: "November",
        label: "November"
      },
      {
        value: "December",
        label: "December"
      }
    ];

    let acYearOptions = [];
    if (this.state.academicYearList && this.state.academicYearList.length) {
      acYearOptions = this.state.academicYearList.map(item => ({
        value: item.name,
        label: item.name
      }));
    }

    return (
      <div className="p-col-12 p-col-nogutter">
        <Growl ref={(el) => this.growl = el} />
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <div className="p-grid nw-form">

          <div className="p-col-12">
            <StudentClassConfigInfo basicInfo={this.props.basicInfo} />
          </div>

          <div className="p-col-12">
            <p className='profile-custom-table-header'>Inventory</p>
          </div>

          <div className="p-col-12 p-col-nogutter">
            <div className="nw-form-body">

              <div className="p-col-12">
                <div className="p-md-2 p-md-offset-10 p-col-4 p-offset-8">
                  <Button
                    label="Academic Year"
                    icon="fas fa-filter"
                    iconPos="right"
                    onClick={this.onAcademicYearFilter}
                  />
                </div>
              </div>

              <div className="p-col-12">
                {this.state.firstDropdownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Academic Year <span>*</span>
                    </span>
                    {this.state.academicYearFiltering ?
                      <Dropdown
                        options={acYearOptions}
                        placeholder="Select Academic Year"
                        value={this.state.academicYear}
                        onChange={this.onChangeAcYear}
                        autoWidth={false}
                        name='academicYear'
                        filter={true}
                      />
                      :
                      <Dropdown
                        disabled
                        options={acYearOptions}
                        placeholder="Select Academic Year"
                        value={this.state.academicYear}
                        onChange={this.onChangeAcYear}
                        autoWidth={false}
                        name='academicYear'
                      />}
                  </div>
                }
                <span className="error-message"> {this.state.academicYear ? '' : this.state.errors['academicYear']} </span>
              </div>

              <div className="p-col-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    Month <span>*</span>
                  </span>
                  <Dropdown
                    placeholder="Select Month"
                    name="monthName"
                    options={monthNameOptions}
                    value={this.state.monthName}
                    onChange={this.onChangeMonth}
                    filter={true}
                    filterBy="label,value"
                    autoWidth={false}
                  />
                </div>
                <span className='error-message'>{this.state.errors["monthName"]}</span>
              </div>

              <div className="p-col-12 nw-button-parent">
                <Button
                  className="p-button p-button-primary nw-button nw-button-right"
                  label="Search"
                  icon="fas fa-search"
                  onClick={this.handleSubmit}
                />
                <br />
              </div>

            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            {this.state.dataTableIsLoading ?
              this.NetiContentLoader.MyPointTableLoader() :
              <div className="nw-data-table">
                <DataTable
                  header={itemTableHeader}
                  value={this.state.tableDetails}
                  responsive={true}
                  paginator={true}
                  rows={10}
                >
                  <Column field="date" header="Purchase Date" sortable={true} filter={true} />
                  <Column field="category" header="Category" sortable={true} filter={true} />
                  <Column field="item" header="Item" sortable={true} filter={true} />
                  <Column field="unitPrice" header="Unit Price" sortable={true} filter={true} />
                  <Column field="quantity" header="Quantity" sortable={true} filter={true} />
                  <Column field="totalPrice" header="Total Price" sortable={true} filter={true} />
                </DataTable>
              </div>
            }

          </div>

        </div>
      </div>

    );
  }
}
