import React, { Component } from 'react';
import { NetiFileHandler } from '../../utils/NetiFileHandler';

var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

export default class CommonFuctionality {
    constructor() {

        this.netiFileHandler = new NetiFileHandler();

    }

    blurContentArray = (contentArray, blurValue, border) =>{
        for (let i = 0 ; i < contentArray.length; i++ ){
            if(contentArray[i] != undefined){
                contentArray[i].style.filter =  blurValue
                contentArray[i].style.border =  border
            }
        }
    }


    blurDialogBackgroundActive = () => {

        let dialog = document.getElementsByClassName("p-dialog")

        for (let i = 0 ; i < dialog.length; i++ ){
            if(dialog[i] != undefined){

                // console.log(" dialog[i]",  dialog[i].id);

                this.dragElement(dialog[i], dialog[i].id);
                // this.draggable(dialog[i]);
            }
        }

        

        // console.log("p-accordion-header", document.getElementsByClassName('p-accordion-header').length);
        
        let blurEffectTabView = document.getElementsByClassName('p-tabview-nav')[0];
        let blurEffectBlurSection = document.getElementsByClassName('blur-section');
        let blurEffectSideBar = document.getElementsByClassName('layout-menu-container')[0];
        let blurEffectTopBar = document.getElementsByClassName('nw-topbar')[0];
        let blurEffectFooter = document.getElementsByClassName('layout-footer')[0];     
        let blurEffectAccordionHeader = document.getElementsByClassName('p-accordion-header');     //p-accordion-header
        let blurEffectAccordionContent = document.getElementsByClassName('p-accordion-content');     //p-accordion-header


        if(blurEffectTabView != undefined){
            blurEffectTabView.style.filter =  "blur(10px)"
        }

        this.blurContentArray(blurEffectBlurSection, "blur(10px)", null)
        this.blurContentArray(blurEffectAccordionHeader, "blur(10px)", null)
        this.blurContentArray(blurEffectAccordionContent, null, "none")

        // for (let i = 0 ; i < blurEffectAccordionHeader.length; i++ ){
        //     if(blurEffectAccordionHeader[i] != undefined){
        //         blurEffectAccordionHeader[i].style.filter =  "blur(10px)"
        //     }
        // }

        


        // blurEffectBlurSection.style.filter =  "blur(10px)"
        blurEffectSideBar.style.filter =  "blur(10px)"
        blurEffectTopBar.style.filter =  "blur(10px)"
        blurEffectFooter.style.filter =  "blur(10px)"
    }

    blurDialogBackgroundDeActive = () => {

        // console.log("nw-tabview Deactive", document.getElementsByClassName('nw-tabview')[0]);

        let blurEffectTabView = document.getElementsByClassName('p-tabview-nav')[0];
        let blurEffectBlurSection = document.getElementsByClassName('blur-section');
        let blurEffectSideBar = document.getElementsByClassName('layout-menu-container')[0];
        let blurEffectTopBar = document.getElementsByClassName('nw-topbar')[0];
        let blurEffectFooter = document.getElementsByClassName('layout-footer')[0];
        let blurEffectAccordionHeader = document.getElementsByClassName('p-accordion-header');
        let blurEffectAccordionContent = document.getElementsByClassName('p-accordion-content');     //p-accordion-header

        if(blurEffectTabView != undefined){
            blurEffectTabView.style.filter =  "blur(0px)"
        }
        this.blurContentArray(blurEffectBlurSection, "blur(0px)", null)
        this.blurContentArray(blurEffectAccordionHeader, "blur(0px)", null)
        this.blurContentArray(blurEffectAccordionContent, null, "1px solid #e0e0e0")

        blurEffectSideBar.style.filter =  "blur(0px)"
        blurEffectTopBar.style.filter =  "blur(0px)"
        blurEffectFooter.style.filter =  "blur(0px)"
    }

    dragMouseDown = (e, elmnt) => {

        // console.log("e::::::", e);
        // console.log("e::::::", elmnt);

        this.blurDialogBackgroundDeActive()
        
        e = e || window.event;
        // e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = this.closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = ((e)=>this.elementDrag(e, elmnt));;
    }

    elementDrag = (e, elmnt) => {
        e = e || window.event;
        // e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";

        // console.log(" elmnt.style.top",  elmnt.style.top);
        // console.log(" elmnt.style.left",  elmnt.style.left);
        
    }

    closeDragElement = () => {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
        this.blurDialogBackgroundActive()

    }

    

    dragElement(elmnt, dialogId) {

        // console.log(" dialogId",  dialogId);

        
        let value = document.getElementById(dialogId)

        // console.log(" value",  value.firstChild);
        

        if (value.firstChild) {
            /* if present, the header is where you move the DIV from:*/
            // document.getElementsByClassName(elmnt.id + "header").onmousedown = dragMouseDown;
            value.firstChild.onmousedown = ((e)=>this.dragMouseDown(e, elmnt));
            // console.log(" true:::::");

        } else {
            /* otherwise, move the DIV from anywhere inside the DIV:*/
            elmnt.onmousedown = ((e)=>this.dragMouseDown(e, elmnt))
            // console.log(" false:::");

        }
    }

    draggable = (el) => {
        el.addEventListener('mousedown', function(e) {
          var offsetX = e.clientX - parseInt(window.getComputedStyle(this).left);
          var offsetY = e.clientY - parseInt(window.getComputedStyle(this).top);
          
          function mouseMoveHandler(e) {
            el.style.top = (e.clientY - offsetY) + 'px';
            el.style.left = (e.clientX - offsetX) + 'px';
          }
      
          function reset() {
            window.removeEventListener('mousemove', mouseMoveHandler);
            window.removeEventListener('mouseup', reset);
          }
      
          window.addEventListener('mousemove', mouseMoveHandler);
          window.addEventListener('mouseup', reset);
        });
    }

    getFileContentTypeIcon(fileName) {
        // console.log("fileName", fileName);

        if (fileName === undefined || fileName === null) {
            return null;
        }
        let status = this.netiFileHandler.getFileContentType(fileName);
        // console.log("status", status);
        if (status === undefined) {
            return null;
        }
        let contentTypeIcon = '';
        if (status === 'application/pdf') {
            contentTypeIcon = <i class="far fa-file-pdf"></i>;
        }
        else if ((status === 'image/jpeg') || (status === 'image/jpg') || (status === 'image/png')) {
            contentTypeIcon = <i class="fas fa-file-image"></i>;
        }
        else if (status === 'application/doc' || status === 'application/docx' ) {
            contentTypeIcon = <i class="fas fa-file-word"></i>;
        }
        else if (status === 'application/ppt' || status === 'application/pptx') {
            contentTypeIcon = <i class="fas fa-file-powerpoint"></i>;
        }
        else if (status === 'application/xls' || status === 'application/xlsx') {
            contentTypeIcon = <i class="fas fa-file-excel"></i>;
        }
        else {
            contentTypeIcon = <i class="fas fa-file-upload"></i>;
        }
        return contentTypeIcon;
    }

    async getImg(photoPath){
        // let { instituteInfo } = this.props;
        let staticImg = 'assets/layout/images/avatar.png';

        let photoMainPath;

        if (photoPath) {
            photoMainPath = photoPath
        }
        // else {
        //     photoMainPath = instituteInfo.logoPath
        // }

        if (photoMainPath !== null) {

            const res = await this.netiFileHandler.getByteImage(photoMainPath)
            const status = await res.status;

            // this.netiFileHandler.getByteImage(photoMainPath)
            //     .then((res) => {
                    if (status == 200) {
                        return res.json()
                            .then((body) => {
                                // console.log("BODY", body);

                                let contentType = this.netiFileHandler.getImageContentType(photoMainPath);
                                // this.setState({ listPhotoView: contentType + body.fileContent });
                                return contentType + body.fileContent
                            })
                    } else {
                        // this.setState({ listPhotoView: staticImg });
                        return staticImg
                    }
                // });
        } else {
            // this.setState({ listPhotoView: staticImg });
            return staticImg
        }

    }

    successMessage(){
        let msg = {
            successMsgShort: "SUCCESS",
            successMsgLong: "Successsssssssssssssss",
        }
        return msg
    }







    


    sliderEffect(elmnt, dialogId) {

        // console.log(" elmnt",  elmnt);
        // console.log(" dialogId",  dialogId);

        
        // let value = document.getElementById(dialogId)

        // // console.log(" value",  value.firstChild);
        

        // if (value.firstChild) {
        //     /* if present, the header is where you move the DIV from:*/
        //     // document.getElementsByClassName(elmnt.id + "header").onmousedown = dragMouseDown;
        //     value.firstChild.onmousedown = ((e)=>this.dragMouseDown(e, elmnt));
        //     // console.log(" true:::::");

        // } else {
        //     /* otherwise, move the DIV from anywhere inside the DIV:*/
        //     elmnt.onmousedown = ((e)=>this.dragMouseDown(e, elmnt))
        //     // console.log(" false:::");

        // }
    }

    setCustomizedTreeDatatable = (customClass, degination) =>{
        let tableRow = document.getElementsByClassName(customClass);

        for(let i=0 ; i<tableRow.length ; i++ ){
            let deginationType = tableRow[i] != undefined && tableRow[i].innerText.toLowerCase();

            if( tableRow[i] != undefined && ( tableRow[i].innerText == degination )){
                tableRow[i].parentNode.classList.remove( degination.toLowerCase() + '-style' )
            }
            else{
                if(deginationType == "mbp" ){
                    tableRow[i].parentNode.classList.add(deginationType + '-style')
                }

                if(deginationType == "zbp" ){
                    tableRow[i].parentNode.classList.add(deginationType + '-style')
                }

                if(deginationType == "bdp" ){
                    tableRow[i].parentNode.classList.add(deginationType + '-style')
                }

                if(deginationType == "bep" ){
                    tableRow[i].parentNode.classList.add(deginationType + '-style')
                }
            }

            
            
        }
    }


}