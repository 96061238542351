import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Button } from 'primereact/button';
import { Workbook } from 'react-excel-workbook';
import { pdfDownloadPortrait_TableWithSubTitle, pdfDownloadLandscape_TableWithSubTitle, pdfDownloadLandscape_TableWithFooter } from "../../common/JsPdfDownload";

let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
export class AdmisiaPaymentInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPaidAmount: 0.0,
            totalUnPaidAmount: 0.0,
            totalPendingAmount: 0.0,
            totalPaidPdf:'',
            totalPendingPdf:'',
            paymentLogs: [],
            dataTableIsLoading: false,
        }
        this.admisiaPointService = new AdmisiaPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
    }

    componentDidMount() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        let totalPendingAmount = 0.0;
        let totalPaidAmount = 0.0;
        let totalUnPaidAmount = 0.0;

        this.admisiaPointService.getPaymentInfo()
            .then((res) => {
                if (res.status === 302) {
                    res.json()
                        .then((body) => {
                            for (let i = 0; i < body.length; i++) {
                                if (body[i].requestStatus === 0) {
                                    body[i].requestStatus = 'Pending';
                                    totalPendingAmount = totalPendingAmount + body[i].refundAmount;
                                }
                                else if (body[i].requestStatus === 1) {
                                    body[i].requestStatus = 'Approved';
                                    totalPaidAmount = totalPaidAmount + body[i].refundAmount;
                                }
                                else if (body[i].requestStatus === 2) {
                                    body[i].requestStatus = 'Rejected';
                                    totalUnPaidAmount = totalUnPaidAmount + body[i].refundAmount;
                                }
                            }
                            this.setState({
                                paymentLogs: body, totalPaidAmount: totalPaidAmount, totalUnPaidAmount: totalUnPaidAmount, totalPendingAmount: totalPendingAmount,
                                topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false
                            });
                        });
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }

    requestStatusBody = rowData => {
        if (rowData.requestStatus === "Pending") {
            return <span className="text-warning">{rowData.requestStatus}</span>
        }
        else if (rowData.requestStatus === "Approved") {
            return <span className="text-success">{rowData.requestStatus}</span>
        }
        else if (rowData.requestStatus === "Rejected") {
            return <span className="text-danger">{rowData.requestStatus}</span>;
        }
    }

    exportPdf = () => {
        let tableColumns = [
            { title: "Total Applicant", dataKey: "totalApplicant" },
            { title: "Total Amount", dataKey: "totalAmount" },
            { title: "Paid Amount", dataKey: "refundAmount" },
            { title: "Message Quantity", dataKey: "serviceAmount" },
            { title: "MFS Charge", dataKey: "mfsAmount" },
            { title: "Status", dataKey: "requestStatus" },
            { title: "Approve Date", dataKey: "approveDate" }, 

          ];
          let totalApproved = "Total Approved Amount";
          let totalPandding = "Total Pending Amount";
          var footerDataList = [
            {
              id: "1",totalApprovedText: totalApproved, approvedAmount: isNaN(this.state.totalPaidAmount) ? '0.00' : this.validatorUtility.currencyFormatter(this.state.totalPaidAmount), totalPanddingText: totalPandding, pendingAmount: isNaN(this.state.totalUnPaidAmount) ? '0.00' : this.validatorUtility.currencyFormatter(this.state.totalPendingAmount)
            }
          ];

        let footerColumns = [{ dataKey: "totalApprovedText" }, { dataKey: "approvedAmount" },{ dataKey: "totalPanddingText" }, { dataKey: "pendingAmount" }];
    
        let footerColumnStyles= {
            totalApprovedText: {
                halign: "right",
                fontSize: 10,
                // columnWidth: 75,
                fontStyle: "bold",
                overflow: "linebreak"
            },
            approvedAmount: {
                halign: "right",
                fontStyle: "bold",
                fontSize: 10,
                // fontStyle: "bold",
                // columnWidth: 53.4
            },
            totalPanddingText: {
                halign: "right",
                fontSize: 10,
                // columnWidth: 75,
                fontStyle: "bold",
                overflow: "linebreak"
            },
            pendingAmount: {
                halign: "right",
                fontStyle: "bold",
                fontSize: 10,
                // fontStyle: "bold",
                // columnWidth: 53.4
            },
          
        }
  
          pdfDownloadLandscape_TableWithFooter("Admisia Payment Report", tableColumns, this.state.paymentLogs, footerColumns, footerDataList,footerColumnStyles, "admesia_payment_info.pdf");
        
    }


    render() {

        
        // excel
        let excelColumns = "";
        excelColumns = [
            <Workbook.Column value="totalApplicant" label="Total Applicant" />,
            <Workbook.Column value="totalAmount" label="Total Amount" />,
            <Workbook.Column value="refundAmount" label="Paid Amount" />,
            <Workbook.Column value="serviceAmount" label="Message Quantity" />,
            <Workbook.Column value="mfsAmount" label="MFS Charge" />,
            <Workbook.Column value="requestStatus" label="Status" />,
            <Workbook.Column value="approveDate" label="Approve Date" />,

        ];
        // end excel

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let headervalue = (
            <div className="p-clearfix">
                Admisia Payment Info{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {this.state.paymentLogs.length}
                </span>
            </div>
        );

        //this.setState({totalPaidPdf : isNaN(this.state.totalPaidAmount) ? '0.00' : this.validatorUtility.currencyFormatter(this.state.totalPaidAmount), totalPendingPdf:isNaN(this.state.totalPendingAmount) ? '0.00' : this.validatorUtility.currencyFormatter(this.state.totalPendingAmount)})
        return (
            <div className="p-fluid">

                {/* <Growl ref={(el) => this.growl = el} /> */}
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    {/* Total Approved Amount: {this.state.totalPaidAmount} <br />Total Pending Amount: {this.state.totalUnPaidAmount} */}
                    <div className="p-col-12 p-xl-12 ">
                        <div className="nw-circle-panel" style={{ border: "unset" }}>
                            <div className="circle-panel circle-panel-fourth">
                                ৳<br />{isNaN(this.state.totalPaidAmount) ? '0.00' : this.validatorUtility.currencyFormatter(this.state.totalPaidAmount)}
                                <div className="circle-panel-label">Total Approved Amount</div>
                            </div>
                            <div className="circle-panel circle-panel-third">
                                ৳<br />{isNaN(this.state.totalUnPaidAmount) ? '0.00' : this.validatorUtility.currencyFormatter(this.state.totalPendingAmount)}
                                <div className="circle-panel-label">Total Pending Amount</div>
                            </div>

                            {/* <div className="circle-panel-hr"></div> */}


                        </div>
                    </div>
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <div>
                                    <DataTable
                                        header={headervalue}
                                        value={this.state.paymentLogs}
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[20, 30, 50]}
                                    >
                                        <Column field="totalApplicant" header="Total Applicant" sortable={true} filter={true} />
                                        <Column field="totalAmount" header="Total Amount" sortable={true} filter={true} />
                                        <Column field="refundAmount" header="Paid Amount" sortable={true} filter={true} />
                                        <Column field="serviceAmount" header="Service Charge" sortable={true} filter={true} />
                                        <Column field="mfsAmount" header="MFS Charge" sortable={true} filter={true} />
                                        <Column field="requestStatus" header="Status" sortable={true} filter={true} body={this.requestStatusBody} />
                                        <Column field="approveDate" header="Approve Date" sortable={true} filter={true} />
                                    </DataTable>
                                    <div className="nw-button-parent-multiple m-t-8 p-r-0">

                                        <Workbook
                                            filename={"Message Transfer Report.xlsx"}
                                            element={
                                                <Button
                                                    label="Download Excel"
                                                    icon="fas fa-download"
                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                />
                                            }
                                        >
                                            <Workbook.Sheet data={this.state.paymentLogs} name="Message Transfer">
                                                {excelColumns}
                                            </Workbook.Sheet>
                                        </Workbook>

                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            label="Download PDF"
                                            icon="fas fa-download"
                                            onClick={this.exportPdf}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}