import React, { Component } from "react";
//import './Login.css';
import AuthService from "./auth/AuthService";

import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Messages } from "primereact/messages";
import { Link } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { GUEST_POINT } from "./utils/PointWiseRouteConsts";
// import Lottie from 'lottie-react-web'
// import rightAnimationLoader from './rightAnimationLoader.json'
import NetiwordLogo from "./assets/images/login/neti.png";
import NetizenLogo from "./assets/images/login/logo.png";
import Logo from "./assets/images/login/eduman.png";
import { ErrorMessageView } from "./components/common/ErrorMessageView";
import { BIJOY_POINT } from "./utils/PointWiseRouteConsts";
import { GuestPointService } from "./service/guestAccessPoint/GuestPointService";
import { BaseURIHolder } from "./utils/BaseURIHolder";

//react-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.Auth = new AuthService();
    this.state = {
      registration: false,
      login: true,
      forgetpassword: false,
      value: "",
      userName: "",
      password: "",
      numberOne: 0,
      numberTwo: 0,
      sumOfNumber: 0,
      userSumValue: "",
      isLoggedIn: false,
      isToggleOn: false,
      btnlogin: "",
      checkIcon: "times",
      passwordIcon: true,
      hidden: true,
      checked: false,
      loginValid: true,
      topProgressBar: false,
      value1: null,
      isToggleOn: true,
      coreBannerInfo: [],
      bannerObj: {},
    };

    this.forgetpasswordHandler = this.forgetpasswordHandler.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.sumOfRandomNumber = this.sumOfRandomNumber.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.showWarn = this.showWarn.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.baseURIHolder = new BaseURIHolder();
    this.guestPointService = new GuestPointService();
  }

  componentWillMount() {
    this.sumOfRandomNumber();
    this.getNetiworldCoreBanner();
    // if(this.Auth.loggedIn())
    //     this.props.history.replace('/');
    // this.leftBottomAnimationLoader();
    // this.leftBottomAnimationLoader();
  }

  getNetiworldCoreBanner() {
    this.setState({ topProgressBar: true, errorMsgVisible: false });
    this.guestPointService
      .getCoreBanner("C1190002")
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            console.log("banner", body);
            this.setState({ coreBannerInfo: body });
            this.setState({ topProgressBar: false, errorMsgVisible: false });
          });
        } else {
          this.UserOtherAccessService.Auth.handleErrorStatus(res).then(
            (resp) => {
              this.setState({
                topProgressBar: false,
                errorMsgVisible: true,
                errorMsgBody: resp,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load data table. Please check connection",
        });
      });
  }

  handleClick(e) {
    this.setState((state) => ({
      isToggleOn: true,
    }));
  }

  handleLoginClick() {
    this.setState({ isLoggedIn: true });
  }

  handleLogoutClick() {
    this.setState({ isLoggedIn: false });
  }

  toggle() {
    this.setState({ disabled: !this.state.disabled });
  }

  sumOfRandomNumber() {
    let numberOne = Math.floor(Math.random() * 10 + 1);
    let numberTwo = Math.floor(Math.random() * 10 + 1);

    this.setState({
      numberOne: numberOne,
      numberTwo: numberTwo,
      sumOfNumber: numberOne + numberTwo,
    });
  }

  handleChange(e) {
    this.setState({
      userSumValue: e.target.value,
    });

    var answer = document.getElementById("answer").value;

    if (answer != this.state.sumOfNumber) {
      this.setState({
        btnlogin: false,
        checkIcon: "times",
      });
    } else {
      this.setState({
        btnlogin: true,
        checkIcon: "check",
      });
    }
  }

  onChangeHandle = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleKeyPress = (event) => {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
  };

  toggleShow = () => {
    this.setState({
      hidden: !this.state.hidden,
      passwordIcon: !this.state.passwordIcon,
    });
  };

  onSubmitHandler = (event, props) => {
    // let {userName, password} = this.state;
    // if (this.state.userName === '' || this.state.password === '') {
    //     this.showWarn("Insert Username and Password");
    // }
    if (this.onSubmitErrorHandler()) {
      this.setState({ topProgressBar: true });
      this.Auth.login(this.state.userName, this.state.password)
        .then((res) => {
          // console.log("log in submit clicked", res);
          this.props.history.replace("/");
          this.setState({ topProgressBar: false });
        })
        .catch((err) => {
          this.showWarn("Invalid Username or Password");
          this.setState({ topProgressBar: false });
        });
    }
  };

  onSubmitErrorHandler = () => {
    let formIsValid = true;

    if (this.state.userName === "" || this.state.password === "") {
      formIsValid = false;
      this.showWarn("Insert Username and Password");
    }
    return formIsValid;
  };

  showWarn = (msg) => {
    this.messages.show({
      severity: "warn",
      summary: msg,
      detail: "",
      sticky: false,
    });
  };

  showSuccess = (name) => {
    this.messages.show({
      severity: "success",
      summary: "Welcome " + name,
      detail: "Login Successful",
      sticky: true,
    });
  };

  forgetpasswordHandler = () => {
    this.setState({ forgetpassword: true, login: false });
  };

  leftBottomAnimationLoader = () => {
    const script = document.createElement("script");

    script.src = "/assets/js/anim/LeftBottomAnimLogin.js";
    script.async = true;

    document.body.appendChild(script);
  };

  loadingAnimationLoader = () => {
    const script = document.createElement("script");

    script.src = "./LoadingAnimLogin.js";
    script.async = true;

    document.body.appendChild(script);
  };

  toggle() {
    this.setState({ disabled: !this.state.disabled });
  }

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

    const options = [
      { label: "English", value: "english" },
      { label: "Bengali", value: "bangla" },
    ];

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
    };

    return (
      <div className="layout-wrapper layout-static nw-login-layout-wrapper">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}

        <div id="login-left-anim"></div>

        <div className="p-grid">
          <div className="p-col nw-hidden">
            <div className="login-menu">
              <ul className="mobile-login-menu">
                <li>
                  <a href="#">
                    <img src={Logo} alt="eduman-logo" /> Eduman
                  </a>
                </li>
                {/* <li><a href="#"><i className="fa fa-download"></i> Download Zone</a></li> */}
                <li>
                  <Link to={BIJOY_POINT.EXPLORE_BIJOY_CONTENT}>
                    <i className="fa fa-download"></i> Download Zone
                  </Link>
                </li>
                <li>
                  <Link to={BIJOY_POINT.GUEST_USER_DEVICE_UNLOCK}>
                    <i className="fa fa-download"></i> Bijoy Device Unlock
                  </Link>
                </li>
              </ul>
            </div>
            <div className="nw-login-banner p-grid">
              {/* <div className="p-col-12" style={{ height: "50%" }}>
                            </div>
                            {this.state.coreBannerInfo && this.state.coreBannerInfo.url !== undefined ?
                                <div className="p-col-12" 
                                    style={{ height: "50%" }}  
                                    >
                                    <center>
                                        <img
                                            src={`https://drive.google.com/uc?export=view&id=${this.state.coreBannerInfo.url}`}
                                            alt="image"
                                            width="100%" height="auto"
                                        />
                                    </center>
                                </div>
                                : ''} */}
              <div
                style={{
                  position: "absolute",
                  zIndex: "90",
                  height: "100%",
                  width: "100%",
                }}
              >
                {this.state.coreBannerInfo && (
                  <Slider {...settings}>
                    {this.state.coreBannerInfo.map((item) => (
                      <div
                        style={{
                          height: "100vh",
                          width: "100%",
                        }}
                      >
                        <img
                          src={"data:image/png;base64," + item.fileContent}
                          alt=""
                          style={{
                            height: "100vh",
                            width: "100%",
                            maxWidth: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>

              {/* <p className="text-bottom"><canvas></canvas>Exciting Feature Integrated! Now you can pay with "Nagad" in Eduman School Management System.</p> */}
            </div>
          </div>
          <div className="p-col">
            <div className="nw-login-layout">
              <div className="login-mobile-menu">
                <ul className="mobile-login-menu">
                  <li>
                    <a href="#">
                      <img src={Logo} alt="eduman-logo" /> Eduman
                    </a>
                  </li>
                  {/* <li><a href="#"><i className="fa fa-download"></i> Download Zone</a></li> */}
                  <li>
                    <Link to={BIJOY_POINT.EXPLORE_BIJOY_CONTENT}>
                      <i className="fa fa-download"></i> Download Zone
                    </Link>
                  </li>
                  <li>
                    <Link to={BIJOY_POINT.BIJOY_NEW_USER}>
                      <i className="fa fa-download"></i> Bijoy Device Unlock
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="nw-login-form">
                <div
                  className="p-lg-8 p-md-12 p-sm-12"
                  style={{ margin: "0 auto" }}
                >
                  <div className="login-form-layout">
                    <div className="language-change">
                      <ul>
                        <li>
                          {/* <SelectButton className="language"
                                                        value={this.state.value1}
                                                        options={options}
                                                        onChange={(e) => this.setState({ value1: e.value })} /> */}
                        </li>
                      </ul>
                    </div>

                    <img src={NetiwordLogo} alt="Netiworld Logo" />
                    <h3>Sign In</h3>
                    <p>Welcome to Netiworld! Please login to your account.</p>

                    <div className="login-form-wrapper">
                      <div className="p-grid">
                        <div className="p-col-12 p-md-12">
                          <Messages ref={(el) => (this.messages = el)} />
                          <span className="p-float-label">
                            <div className="p-inputgroup">
                              <InputText
                                id="in"
                                name="userName"
                                onChange={(event) =>
                                  this.onChangeHandle(event, this.props)
                                }
                                onKeyPress={this.handleKeyPress}
                                keyfilter="alphanum"
                                placeholder="Enter Username"
                              />
                              {/* <label className="custom-label" htmlFor="in">Username</label> */}
                              <span className="required-icon">*</span>
                            </div>
                          </span>
                        </div>

                        <div className="p-col-12 p-md-12">
                          <span className="p-float-label">
                            <div className="p-inputgroup">
                              <InputText
                                type={this.state.hidden ? "password" : "text"}
                                placeholder="Enter Password"
                                name="password"
                                tooltip="Enter your password"
                                tooltipOptions={{ position: "top" }}
                                onChange={(event) =>
                                  this.onChangeHandle(event, this.props)
                                }
                              />

                              <span className="required-icon">*</span>
                            </div>
                          </span>
                        </div>

                        <div className="p-col-12">
                          <div className="p-inputgroup">
                            <p className="p-inputgroup-addon p-col-3">
                              <span>
                                {this.state.numberOne} + {this.state.numberTwo}{" "}
                                ={" "}
                              </span>
                            </p>
                            <InputText
                              style={{ borderLeft: "1px solid #ffffff" }}
                              id="answer"
                              value={this.state.userSumValue}
                              name="userSumValue"
                              onChange={this.handleChange}
                              keyfilter="pnum"
                            />
                            {/* <span className="p-inputgroup-addon">
                                                        <i className={"pi pi-" + this.state.checkIcon} />
                                                    </span> */}
                            <span className="required-icon">*</span>
                          </div>
                        </div>

                        <div
                          className="p-col-12"
                          style={{ "padding-bottom": "0px" }}
                        >
                          <Button
                            className="custom-login-button gradiant-btn box-shadow"
                            icon="fas fa-sign-in-alt"
                            iconPos="right"
                            label="Login"
                            disabled={!this.state.btnlogin}
                            onClick={this.onSubmitHandler}
                          >
                            {/* <span className="p-button-text p-c"><i className="pi pi-user"></i>Login</span> */}
                          </Button>
                        </div>

                        <div className="p-col-12">
                          <div className="nw-forget-password p-inputgroup">
                            <Checkbox
                              onChange={(e) =>
                                this.setState({ checked: e.checked })
                              }
                              checked={this.state.checked}
                            ></Checkbox>

                            <label
                              htmlFor="cb2"
                              className="p-checkbox-label mr-25"
                            >
                              <b>Remember Me</b>
                            </label>
                            <span className="mr-25">|</span>
                            <div className="forget-password-link">
                              <Link
                                to={GUEST_POINT.FORGET_PASSWORD}
                                className="p-right"
                              >
                                <u>Forget Password ?</u>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="p-col-12 p-md-12 nw-signup">
                          <p>
                            Don't have an account?{" "}
                            <Link
                              to={GUEST_POINT.SIGN_UP}
                              style={{ color: "#FF569F", fontWeight: "700" }}
                            >
                              Sign Up
                            </Link>{" "}
                            now.
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* start copyright */}
                    <div className="login-footer">
                      <img src={NetizenLogo} />
                      <span>
                        POWERED BY <strong>NETIZEN IT LIMITED</strong>
                      </span>
                    </div>
                    {/* end copyright */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // handleChange(e){
  //     this.setState(
  //         {
  //             [e.target.name]: e.target.value
  //         }
  //     )
  // }
}

export default Login;
