import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { EmAdminPointService } from '../../service/emAdminPoint/EmAdminPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { Button } from 'primereact/button';
import { pdfDownloadPortrait_TableOnly } from '../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';

let angle = '';
export class EmSmsTransferInfoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            topProgressBar: false,
            messageLog: {
                pageNo: 0
            },
            first: 0,
            rows: 10,
            errorMsgVisible: false,
            errorMsgBody: null,
            dataTableValue: [],
        }
        this.emAdminPointService = new EmAdminPointService();
        this.exportPdf = this.exportPdf.bind(this);
    }

    componentWillMount() {
        this.viewMessageTransferList();
    }

    onPageChange = (event) => {

        let { messageLog } = this.state
        messageLog.pageNo = event.page

        this.setState({
            first: event.first,
            rows: event.rows
        });

        this.viewMessageTransferList();
    }

    viewMessageTransferList = () => {
        this.setState({ topProgressBar: true });
        this.emAdminPointService.getEmAdminMessageTransferLogs(this.state.messageLog)
            .then((res) => {
                this.setState({ topProgressBar: false });
                if (res.status == 200) {
                    return res.json().then((body) => {

                        for (let i = 0; i < body.length; i++) {
                            if (body[i].transactionDate == null) {
                                body[i].transactionDate = '';
                            } else {
                                body[i].transactionDate = NetiDateUtils.getAnyShortForm(body[i].transactionDate, 'DD-MMM-YYYY hh:mm:ss a');
                            }
                        }
                        this.setState({ dataTableValue: body });
                    });
                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    exportPdf() {

        let tableColumns = [
            { title: "Transfer Date", dataKey: "transactionDate" },
            { title: "Institute ID", dataKey: "instituteId" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Message Type", dataKey: "messageType" },
            { title: "Message Quantity", dataKey: "messageQuantity" },
            { title: "Note", dataKey: "note" }
        ];

        pdfDownloadPortrait_TableOnly("Message Transfer Report", tableColumns, this.state.dataTableValue, "MessageTransferReport.pdf");

    }

    render() {

        // excel
        let excelColumns = "";
        excelColumns = [
            <Workbook.Column value="transactionDate" label="Transfer Date" />,
            <Workbook.Column value="instituteId" label="Institute ID" />,
            <Workbook.Column value="instituteName" label="Institute Name" />,
            <Workbook.Column value="messageType" label="Message Type" />,
            <Workbook.Column value="messageQuantity" label="Message Quantity" />,
            <Workbook.Column value="note" label="Note" />,
        ];
        // end excel

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Message Transfer List
                                <span style={{ color: "white", display: "flex", float: "right" }}> Total Found:{this.state.dataTableValue.length}</span>
        </div>;
        return (

            <div className="p-fluid">
                <div className="nw-data-table">
                    {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                    {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}

                    <Growl ref={(el) => this.growl = el} />
                    <DataTable
                        value={this.state.dataTableValue}
                        selectionMode="single"
                        header={tableHeader}
                        // selection={this.state.dataTableSelection}
                        // onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                        responsive={true}
                        paginator={true}
                        // rowsPerPageOptions={[10, 20, 30]}
                        rows={10}
                        columnResizeMode="fit"
                    >
                        <Column field="transactionDate" header="Transfer Date" filter={true} style={{ width: '110px' }}/>
                        <Column field="instituteId" header="Institute ID" filter={true} />
                        <Column field="instituteName" header="Institute Name" filter={true} />
                        <Column field="messageType" header="Message Type" filter={true} />
                        <Column field="messageQuantity" header="Message Quantity" filter={true} />
                    </DataTable>
                    <div className="nw-button-parent-multiple m-t-8 p-r-0">

                        <Workbook
                            filename={"Message Transfer Report.xlsx"}
                            element={
                                <Button
                                    label="Excel Download"
                                    icon="ui-icon-cloud-download"
                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                />
                            }
                        >
                            <Workbook.Sheet data={this.state.dataTableValue} name="Message Transfer">
                                {excelColumns}
                            </Workbook.Sheet>
                        </Workbook>

                        <Button
                            className="p-button p-button-primary nw-button nw-button-multiple"
                            label="Download"
                            icon="fas fa-download"
                            onClick={this.exportPdf}
                        />
                    </div>

                </div>
            </div>
        );
    }
}