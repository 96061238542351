import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import Workbook from "react-excel-workbook";
import XLSX from 'xlsx';
import { downloadJasperFile } from '../../../utils/JasperFileDownloader';

let errors = {}
const userUrlInfo = localStorage.getItem('admUrlInfo');
let urlInfoID;
export class AssessmentExamMarkList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rejectedStutas: "Custom Status",
            dataTableSelection: '',
            dataViewValue: [],
            dataTableIsLoading: false,
            admitCardBtn: true,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            admissionPendingBtn: false,
            admissionBtn: false,
            dataTableValue: props.componentNameData,
            classConfigID: this.props.classConfigID,
            errors: {},
            buttonDisabled: true,
            fileName: "Browse File...",
            excelFileRows: '',
            workSheetbook: []
        };

        this.marksEditor = this.marksEditor.bind(this);
        this.requiredValidator = this.requiredValidator.bind(this);
        this.admisiaPointService = new AdmisiaPointService();

        this.onSubmitUpdatedMarks = this.onSubmitUpdatedMarks.bind(this);
        this.onBasicUploadAuto = this.onBasicUploadAuto.bind(this);
        this.ProcessExcel = this.ProcessExcel.bind(this);
        this.setExcelRowsIntoDataTable = this.setExcelRowsIntoDataTable.bind(this);
        this.reloadCoreUrlIdFromLocal();

    }

    reloadCoreUrlIdFromLocal() {
        urlInfoID = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlInfoID) {
            setTimeout(() => {
                this.componentWillMount();
            }, 1000);
        }
    }

    componentWillMount() {
        urlInfoID = this.admisiaPointService.getUrlInfoFromLocalStorage();
        this.prevExamInfoCol();
    }

    displaySelection(data) {
        if (!data || data.length === 0) {
            return <div style={{ textAlign: 'left' }}>No Selection</div>;
        }
        else {
            errors['submitApproveError'] = ""
        }
    }

    onEditorValueChange(props, value) {
        let updatedCars = [...props.value];
        updatedCars[props.rowIndex].admisiaApplicantInfoDTO[props.field] = value;
        this.setState({ dataTableValue: updatedCars });
    }

    inputTextEditor(props, field) {
        return <InputText 
        type="text" 
        value={props.rowData.admisiaApplicantInfoDTO.examMark || ''} 
        onChange={(e) => this.onEditorValueChange(props, e.target.value)} 
        />;
    }

    marksEditor(props) {
        return this.inputTextEditor(props, 'examMark');
    }

    requiredValidator(props) {

        let value = props.rowData[props.field];
        return value && value.length > 0;
    }

    // dateFormatTemplet = (rowData) => {
    //     return <span>{NetiDateUtils.getAnyShortForm(rowData['requestDate'], 'DD-MMM-YYYY')}</span>;
    // }

    prevExamInfoTemplet = (rowData) => {

        if (rowData.admisiaApplicantPrevExamInfoDTOList) {

            return (
                    <DataTable
                        value={rowData.admisiaApplicantPrevExamInfoDTOList}
                        responsive={false}
                        className="pre-exam-info-datatable"
                    >
                        <Column field="prevInstituteName" header="Institute Name"/>
                        <Column field="examGradeCategoryInfoDTO.categoryName" header="Grade"/>
                        <Column field="examGPA" header="GPA"/>
                    </DataTable>
            //  <ol>
            //      {rowData.admisiaApplicantPrevExamInfoDTOList.map((item) => {
            //          return <li>{item.prevInstituteName}; {item.examGradeCategoryInfoDTO.categoryName}; {item.examGPA}</li>;
            //         })
            //     }
            //  </ol>
            );
        }
        else { return <center>-</center> }
    }


    prevExamInfoCol = () => {
        const {dataTableValue} =this.state;
        let workShet=[];
        for(let i=0; i<dataTableValue.length; i++){
            let prevInfo='';
            let prevExamInfoList=dataTableValue[i].admisiaApplicantPrevExamInfoDTOList;
            if (prevExamInfoList) {
                for(let e=0; e<prevExamInfoList.length; e++){
                    prevInfo += '(' + prevExamInfoList[e].prevInstituteName + ' | ' + prevExamInfoList[e].examGradeCategoryInfoDTO.categoryName + ' | ' + prevExamInfoList[e].examGPA + ')\n'
                }
            }
            let obj={
                registrationID: dataTableValue[i].admisiaApplicantInfoDTO.registrationID,
                applicantName: dataTableValue[i].admisiaApplicantInfoDTO.applicantName,
                gender: dataTableValue[i].admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName,
                mobileNo: dataTableValue[i].admisiaApplicantInfoDTO.mobileNo,
                applicationDate: dataTableValue[i].admisiaApplicantInfoDTO.applicationDate,
                prevExamInfo: prevInfo,
                examMark: dataTableValue[i].admisiaApplicantInfoDTO.examMark
            }

            workShet.push(obj);
        } 
        console.log('Work', workShet);

        this.setState({workSheetbook: workShet});
    }

    examMarkBodyTemplet = (rowData) => {
        return <InputText type="text" placeholder="Ex: 99" value={rowData.admisiaApplicantInfoDTO.examMark || ''} />
    }

    onSubmitUpdatedMarks() {

        let { dataTableValue } = this.state
        var markMapValue = {}
        var i;

        for (i = 0; i < dataTableValue.length; i++) {
            markMapValue[dataTableValue[i].admisiaApplicantInfoDTO.admisiaApplicantInfoId] = dataTableValue[i].admisiaApplicantInfoDTO.examMark;
        }

        if (Object.getOwnPropertyNames(markMapValue).length === 0) {
            errors['submitApproveError'] = "Please input exam marks."
        }
        else {

            let updatedMarksObj = {
                "urlInfoID": urlInfoID,
                "classConfigID": this.state.classConfigID,
                "marksMap": markMapValue
            }

            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.admisiaPointService.updateStudentAdmissionExamMarks(updatedMarksObj)
                .then(res => {
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated!" });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false});

                        setTimeout(() => {
                            this.props.reCallClassGroupSearch();
                        }, 1000);

                    
                    }
                    else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                });
        }
    }

    onBasicUploadAuto(file) {

        let reader = new FileReader();

        this.setState({
            fileName: file.name
        })

        //For Browsers other than IE.
        if (reader.readAsBinaryString) {
            reader.onload = (e) => { this.ProcessExcel(e.target.result) }
            reader.readAsBinaryString(file);
        } else {
            //For IE Browser.
            reader.onload = function (e) {
                let data = "";
                let bytes = new Uint8Array(e.target.result);
                for (let i = 0; i < bytes.byteLength; i++) {
                    data += String.fromCharCode(bytes[i]);
                }
                this.ProcessExcel(data);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    ProcessExcel(data) {

        let workbook = XLSX.read(data, { type: 'binary' });
        const firstSheet = workbook.SheetNames[0];
        let excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
        // this.setExcelRowsIntoDataTable(excelRows);
        this.setState({excelFileRows: excelRows});

    }

    setExcelRowsIntoDataTable() {

        let { dataTableValue } = this.state;

        let newDataList = [];

        let excelfileRows = this.state.excelFileRows;

        // if (!this.state.excelFileRows) {
            for (const i in excelfileRows) {

                let dataTableObject = {};
                const rowValue =  excelfileRows[i];

                console.log("rowValue", rowValue);
                
    
                for (let index = i; index <= i; index++) {
                    const mainRowData = dataTableValue[index];

                    console.log("mainRowData", mainRowData );

    
                    dataTableObject = {
    
                        'admisiaApplicantInfoDTO': {
                            'admisiaApplicantInfoId': mainRowData.admisiaApplicantInfoDTO.admisiaApplicantInfoId,
                            'registrationID': rowValue.Registration_No,
                            'applicantName': rowValue.Name,
                            'genderCoreCategoryInfoDTO': {
                                'categoryName': rowValue.Gender,
                            },
                            'mobileNo': rowValue.Mobile_No,
                            'applicationDate': rowValue.Application_Date,
                           
                            'examMark': rowValue.Exam_Marks,
                        },
                        'admisiaApplicantPrevExamInfoDTOList': mainRowData.admisiaApplicantPrevExamInfoDTOList
                    }
                }
                newDataList.push(dataTableObject);
            }
        
            this.setState({ dataTableValue: newDataList });

            // this.prevExamInfoCol();

        // } else {
        //     return '';
            
        // }

       
    }
    


    downloadApplicantAdmitCard = () => {

        let uri = this.admisiaPointService.downloadAllApplicantAdmitCard((this.props.classConfigID ? this.props.classConfigID: ''), 
        (this.props.admisiaStatus ? this.props.admisiaStatus: ''), (urlInfoID ? urlInfoID: ""));
        downloadJasperFile(uri);

    }

    downloadApplicantSeatPlan = () => {

        let uri = this.admisiaPointService.downloadAllApplicantSeatPlan((this.props.classConfigID ? this.props.classConfigID: ''), 
        (urlInfoID ? urlInfoID: ""));
        downloadJasperFile(uri);
    }

    downloadApplicantExamAttendance = () => {

        let uri = this.admisiaPointService.downloadAllApplicantExamAttendance((this.props.classConfigID ? this.props.classConfigID: ''), 
        (urlInfoID ? urlInfoID: ""));
        downloadJasperFile(uri);
    }


    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Assessment Exam Mark List
        <div style={{ color: "white", display: "flex", float: "right" }}> Total: {this.state.dataTableValue.length}</div>
        </div>;

        return (

            <div className="p-fluid">
                <div className="p-grid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    <Growl ref={(el) => this.growl = el} />

                    <div className="p-col-12 p-xl-12">

                        <div className="p-grid">
                            <div className="p-col-12 p-xl-4 nw-button-parent">
                                <Button
                                    label="Admit Card"
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    icon="fas fa-download"
                                    onClick={this.downloadApplicantAdmitCard}
                                    disabled={this.props.buttonCheck}
                                />
                            </div>

                            <div className="p-col-12 p-xl-4 nw-button-parent">
                                <Button
                                    label="Seat Plan"
                                    icon="fas fa-download"
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    onClick={this.downloadApplicantSeatPlan}
                                    disabled={this.props.buttonCheck}
                                />
                            </div>

                            <div className="p-col-12 p-xl-4 nw-button-parent" >
                                <Button
                                    label="Exam Attendance"
                                    icon="fas fa-download"
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    onClick={this.downloadApplicantExamAttendance}
                                    disabled={this.props.buttonCheck}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="nw-data-table nw-data-table-tabview">

                        <DataTable
                            value={this.state.dataTableValue}
                            editable={true}
                            header={tableHeader}
                            responsive={true}
                            paginator={true}
                            rows={10}
                            footer={this.displaySelection(this.state.dataTableSelection)}
                        >

                            <Column field="admisiaApplicantInfoDTO.registrationID" header="Registration No." sortable={true} filter={true} />
                            <Column field="admisiaApplicantInfoDTO.applicantName" header="Name" sortable={true} filter={true} />
                            <Column field="admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName" header="Gender" sortable={true} filter={true} />
                            <Column field="admisiaApplicantInfoDTO.mobileNo" header="Mobile No." sortable={true} filter={true} />
                            <Column field="admisiaApplicantInfoDTO.applicationDate" header="Application Date" sortable={true} filter={true} />
                            <Column field="" header="Previous Exam Info" body={this.prevExamInfoTemplet}  style={{width:"250px"}} />
                            <Column field="examMark" header="Exam Marks" editor={this.marksEditor} body={this.examMarkBodyTemplet} />

                        </DataTable>


                        <span className="error-message">{errors['submitApproveError']}</span>
                    </div>


                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid">

                            <div className="p-col-12 p-xl-3">

                                <Workbook filename={"Mark_Sheet_Template.xlsx"}
                                    element=
                                    {
                                        <Button
                                            label="Mark Sheet Template"
                                            className="p-button p-button-primary nw-button nw-button-center"
                                            icon="fas fa-arrow-down"
                                        />}
                                >
                                    <Workbook.Sheet data={this.state.workSheetbook} name="Mark Sheet Template">
                                        <Workbook.Column value={row => row.registrationID} label="Registration_No" />
                                        <Workbook.Column value={row => row.applicantName} label="Name" />
                                        <Workbook.Column value={row => row.gender} label="Gender" />
                                        <Workbook.Column value={row => row.mobileNo} label="Mobile_No" />
                                        <Workbook.Column value={row => row.applicationDate} label="Application_Date" />
                                        <Workbook.Column value={row => row.prevExamInfo} label="Previous_Exam_Info" />
                                        <Workbook.Column value={row => row.examMark} label="Exam_Marks" />
                                    </Workbook.Sheet>
                                </Workbook>

                            </div>
                            <div className="p-col-12 p-xl-3 nw-upload-button" style={{ textAlign: "center" }}>

                                <label class="file">
                                    <input
                                        type="file"
                                        id="file"
                                        aria-label="File browser example"
                                        onChange={(evt) => { this.onBasicUploadAuto(evt.target.files[0]) }}
                                    />
                                    <span class="file-custom">{this.state.fileName}</span>
                                </label>
                            </div>


                            <div className="p-col-12 p-xl-3">
                                <Button
                                    label="Upload"
                                    icon="fas fa-upload"
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    onClick={this.setExcelRowsIntoDataTable}
                                />
                            </div>


                            {this.props.autoApproveStatus === "(Manual Approve)" ?
                                <div className="p-col-12 p-xl-3">
                                    <Button
                                        label="Send to Admision Pending List"
                                        icon="fas fa-check"
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        onClick={this.onSubmitUpdatedMarks}
                                    />
                                </div>
                                : ""
                            }


                            {this.props.autoApproveStatus === "(Auto Approve)" ?
                                <div className="p-col-12 p-xl-3">
                                    <Button
                                        label="Send to Admision"
                                        icon="fas fa-check"
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        onClick={this.onSubmitUpdatedMarks}
                                    />
                                </div>
                                : ""

                            }

                        </div>

                    </div>


                </div>
            </div>
        );
    }
}