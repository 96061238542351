import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { SeatInfoList } from './SeatInfoList';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import CommonFuctionality from '../../common/CommonFuctionality';


let dialogStatus = false;
let errors = {};
let dropDownListName1 = '';
let dropDownListName2 = '';
let cloneStateBeforeMount;
let urlId;
export class AdminSeatInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serialNumber: '',
            dataTableValue: [],
            classDropDownList: [],
            groupDropDownList: [],
            insertUserObj:{
                totalSeat: '',
                seatSerial: '',
                classId: 0,
                groupId: 0,
                cmsId: 0
            },
            updateUserObj:{
                totalSeat: '',
                seatSerial: '',
                classId: 0,
                groupId: 0,
                cmsId: 0,
                seatId:0
            },
            deleteUserObj:{
                seatId:0
            },
            totalSeat: '',
            errors: {},
            homeReturnButton: true,
            dataTableIsLoading: false,
            firstDropDownIsLoading: false,
            secondDropDownIsLoading: false,
            totalDataFound: 0,
            deleteDialogVisible: false
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {
        urlId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {
            let {insertUserObj, updateUserObj } = this.state
            insertUserObj.cmsId = urlId;
            updateUserObj.cmsId = urlId;
            this.setState({homeReturnButton: false, insertUserObj, updateUserObj })

            this.viewSeatInfoList();
            this.getClassOrGroupListByDefaultCode('class');
            this.getClassOrGroupListByDefaultCode('group');
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    viewSeatInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })

        this.DwsService.cmsSeatInfoList(urlId)
            .then((res) => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        // this.props.countTableSize(body.length);
                        this.setState({ dataTableValue: body.item });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }



    getClassOrGroupListByDefaultCode(type) {
        this.setState({ error: {} });
        this.setState({ firstDropDownIsLoading: true, secondDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.DwsService.cmsClassOrGroupList(type, urlId)
            .then(res => {

                if (res.status == 200) {
                    return res.json().then((body) => {

                        if (type === 'class') {
                            this.setState({ classDropDownList: body.item });
                        }
                        else if (type === 'group') {
                            this.setState({ groupDropDownList: body.item });
                        }
                        errors['classError'] = ''
                        errors['groupError'] = ''
                        errors[dropDownListName1] = ''
                        this.setState({ errors: errors })
                        this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });

                }
                else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            errors[dropDownListName1] = resp.message
                            this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch(error => {
                errors['classError'] = 'Class List Not Found'
                errors['groupError'] = 'Group List Not Found'
                errors[dropDownListName1] = 'Connection Problem'
                this.setState({ errors: errors })
                this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

            });
    }

    showMainForm = (dialogType, rowData) => {
        console.log(dialogType, rowData);

        Object.assign(this.state.updateUserObj, rowData)
        this.setState({ visible: true, dialogType: dialogType, errors: {}});
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    showDeleteDialog = (dialogType, rowData) =>{
        this.setState({ deleteDialogVisible: true, deleteUserObj: rowData });
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    onHide = () =>{
        this.setState({ visible: false, deleteDialogVisible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive()
    }

    actionTemplate = (rowData, column) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                tooltip="Update"
                onClick={(e) => this.showMainForm('update', rowData)}
            >
            </Button>

            <Button
                type="button"
                icon="fas fa-trash"
                tooltip="Delete"
                className="nw-action-button p-button-danger"
                onClick={(e) => this.showDeleteDialog('delete', rowData)}
            >
            </Button>
        </div>;
    }

    formDialog = (classList, groupList) =>{
        let {insertUserObj, updateUserObj, dialogType, errors} = this.state
        let userObj = {...this.state.insertUserObj}
        if( this.state.dialogType == 'insert' ){
            userObj = {...insertUserObj}
        }
        else if( this.state.dialogType == 'update' ){
            userObj = {...updateUserObj}
        }
        return (
            <Dialog
                className="nw-dialog"
                header={this.state.dialogType == 'insert' ? 'Add Seat Information' : 'Update Seat Information'}
                visible={this.state.visible}
                onHide={this.onHide}
                closable
            >
                <div className="p-fluid">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">

                                {
                                    dialogType == 'insert' ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>
                                                <div className="nw-inputgroup-desc">
                                                    {userObj.seatSerial || this.state.dataTableValue.length + 1}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No. <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Serial"
                                                    name="seatSerial"
                                                    value={userObj.seatSerial}
                                                    onChange={e => this.onChageInputValue(e, dialogType)}
                                                />
                                            </div>
                                            <span className="error-message">{errors["seatSerial"]}</span>
                                        </div>
                                }


                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Class <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Class"
                                            filter={true}
                                            autoWidth={false}
                                            showClear={true}
                                            name="className"
                                            filterBy="label,value"
                                            options={classList}
                                            value={userObj.classId}
                                            onChange={e => this.onChageClassName(e, dialogType)}
                                        />
                                    </div>
                                    <span className='error-message'>{errors['className']}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Group <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Group"
                                            filter={true}
                                            autoWidth={false}
                                            showClear={true}
                                            name="groupName"
                                            filterBy="label,value"
                                            options={groupList}
                                            value={userObj.groupId}
                                            onChange={e => this.onChageGroupName(e, dialogType)}
                                        />
                                    </div>
                                    <span className='error-message'>{errors['groupName']}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Total Seat <span>*</span></span>
                                        {/* <InputText
                                                    keyfilter="pint"
                                                    id="seatNo"
                                                    placeholder="Enter total seat number"
                                                    showClear={true}
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="seat"
                                                    onChange={this.onChangeTotalSeat}
                                                    value={this.state.totalSeat}
                                                /> */}
                                        <InputText
                                            placeholder="Enter Total Seat Number"
                                            keyfilter="pint"
                                            name="totalSeat"
                                            value={userObj.totalSeat}
                                            onChange={e => this.onChageTotalSeat(e, dialogType)}

                                        />
                                    </div>
                                    <span className="error-message">{errors["totalSeat"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12" />

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                            </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-left"
                                        label={ dialogType == "insert" ? "Save" : "Update" }
                                        icon="fas fa-check"
                                        onClick={ () => this.seatOnSubmitHandler(dialogType) }
                                    />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </Dialog>
        )
    }

    deleteDialogView = () =>{
        return (
            <Dialog header="Delete Seat Info" visible={this.state.deleteDialogVisible} onHide={this.onHide} className="nw-confirm-dialog">
                <div className="p-fluid">
                    <div className="p-col-12 p-col-12">
                        <div className="confirm-wrapper">
                            {/* <div className="body-wrapper">
                                <header>Total messages you are about to send</header>
                                <h1>{selectedCustomers.length}</h1>
                                <footer>{selectedCustomers.length}  Person x {selectedCustomers.length} Message</footer>
                            </div> */}
                            <h1>Are You Sure?</h1>
                            <div className="nw-button-parent-multiple">
                                <Button
                                    className="p-button-danger nw-button nw-button-multiple"
                                    label="No"
                                    icon="fas fa-times"
                                    onClick={this.onHide}
                                />
                                <Button
                                    className="p-button-primary nw-button nw-button-multiple"
                                    label="Yes"
                                    icon="fas fa-check"
                                    onClick={ e => this.deleteRow(this.state.deleteUserObj)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }

    onChageInputValue = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj[e.target.name] = e.target.value
        }
        else if(action == 'update'){
            updateUserObj[e.target.name] = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageClassName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.classId = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.classId = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageGroupName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.groupId = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.groupId = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageTotalSeat = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.totalSeat = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.totalSeat = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }


    handleError = (objectHandle) => {
        let { errors } = this.state;
        let formIsValid = true;

        if (!objectHandle.seatSerial) {
            formIsValid = false;
            errors["seatSerial"] = "Serial Number can't left empty.";
        }

        if (!objectHandle.classId) {
            formIsValid = false;
            errors["className"] = "Class can't left empty.";
        }
        
        if (!objectHandle.groupId) {
            formIsValid = false;
            errors["groupName"] = "Group can't left empty.";
        }

        if (!objectHandle.totalSeat) {
            formIsValid = false;
            errors["totalSeat"] = "Total Seat can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    seatOnSubmitHandler = (dialogType) => {
        let {insertUserObj, updateUserObj} = this.state

        let userObj={}
        if( dialogType == 'insert' ){
            userObj = {...insertUserObj}
            userObj.seatSerial = this.state.dataTableValue.length + 1;
        }
        else if( dialogType == 'update' ){
            userObj = {...updateUserObj}
        }
        console.log( dialogType, userObj );

        if (this.handleError(userObj)) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            this.DwsService.cmsInserAndUpdateSeat( dialogType, userObj, urlId )
                .then(res => {
                    return res.json().then((body) => {
                        console.log('Body', body);
                        if(body.messageType === 1){
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                            this.growl.show({ severity: 'success', summary: 'Success', detail: body.message });

                            this.onHide()
                            setTimeout(() => {
                                this.viewSeatInfoList()
                            }, 1000);
                        }
                        else{
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    })
                }).catch(error => {
                    // errors["dropDownClassError"] = 'Connection Problem'
                    this.setState({ errors: errors })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                });
        }
    }

    deleteRow = (userObj) =>{
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.cmsDeleteSeat( userObj, urlId )
                .then(res => {
                    return res.json().then((body) => {
                        console.log('Body', body);
                        if(body.messageType === 1){
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                            this.growl.show({ severity: 'success', summary: 'Success', detail: body.message });
                            
                            this.onHide()
                            setTimeout(() => {
                                this.viewSeatInfoList()
                            }, 1000);
                        }
                        else{
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    })
                }).catch(error => {
                    // errors["dropDownClassError"] = 'Connection Problem'
                    this.setState({ errors: errors })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                });
    }

    render() {
        let { insertUserObj, updateUserObj, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let classList = [];
        if ((this.state.classDropDownList != null) && (this.state.classDropDownList.length > 0)) {
            classList = this.state.classDropDownList.map((item) => ({
                value: item.classId,
                label: item.className,
            }));
        }

        let groupList = [];
        if ((this.state.groupDropDownList != null) && (this.state.groupDropDownList.length > 0)) {
            groupList = this.state.groupDropDownList.map((item) => ({
                value: item.groupId,
                label: item.groupName,
            }));
        }

        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                                Seat Info List
                                    </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length.toLocaleString("EN-IN")}
                                </a>

                                <Button
                                    className="nw-button nw-button-center p-button-success"
                                    name="district"
                                    label="Add New"
                                    icon="fas fa-plus"
                                    iconPos="right"
                                    onClick={ () => this.showMainForm('insert', null)}
                                />
                            </div>
                        </div>;



        return (

            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section blur-section">
                    {this.state.homeReturnButton === false ?
                        

                            <div className="nw-data-table">
                                {!this.state.dataTableIsLoading ?
                                    <DataTable
                                        value={this.state.dataTableValue}
                                        selectionMode="single"
                                        header={tableHeader}
                                        responsive={true}
                                        rows={10}
                                        paginator={true}
                                        columnResizeMode="fit"
                                    >
                                        <Column field="seatSerial" header="Serial No." filter={true} />
                                        <Column field="className" header="Class" filter={true} />
                                        <Column field="groupName" header="Group" filter={true} />
                                        <Column field="totalSeat" header="Total Seat" filter={true} />
                                        <Column field="action" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                    </DataTable>
                                    : this.NetiContentLoader.MyPointTableLoader()
                                }
                            </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    { this.formDialog(classList, groupList) }
                    { this.deleteDialogView() }
                </div>
            </div>
        )
    }
}