import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { NetiFileHandler } from "../../../utils/NetiFileHandler";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import CommonFuctionality from "../../common/CommonFuctionality";
import NetiContentLoader from "../../common/NetiContentLoader";
import { AdminPointService } from "../../../service/adminPoint/AdminPointService";
import { Editor } from "primereact/editor";
import { UserCategoryService } from "../../../service/UserCategoryService";
let errors = {};
export class BannerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      selectedViewRowData: "",
      viewDialogVisible: false,
      errors: {},
      dataTableValue: [],
      bannerTitleList: [],
      bannerTitleInfo: {},
      proPic: {
        extention: "",
        contentPic: "",
        contentName: "",
      },
      updateBannerInfoError: {},
      updateBannerDetails: {
        coreCategoryInfoDTO: {
          coreCategoryID: "",
        },
        bannerTitle: "",
        enableStatus: "",
        url: "",
        serial: "",
        bannerDetails: "",
      },
      topProgressBar: false,
      errorMsgVisible: false,
      errorMsgBody: null,
    };
    this.netiFileHandler = new NetiFileHandler();
    this.NetiContentLoader = new NetiContentLoader();
    this.UserCategoryService = new UserCategoryService();
    this.AdminPointService = new AdminPointService();
    this.CommonFuctionality = new CommonFuctionality();
    this.onChangeBannerDetails = this.onChangeBannerDetails.bind(this);
  }
  componentWillMount() {
    this.getBannerList();
  }

  getBannerList() {
    this.setState({ topProgressBar: true, errorMsgVisible: false });
    this.AdminPointService.getAdminBannerList()
      .then((res) => {
        console.log("res", res);

        if (res.status == 302) {
          return res.json().then((body) => {
            console.log("Body", body);
            for (let i = 0; i < body.length; i++) {
              if (body[i].enableStatus == 1) {
                body[i].bannerStatus = "Enable";
              } else if (body[i].enableStatus == 0) {
                body[i].bannerStatus = "Disable";
              }
            }

            this.setState({ dataTableValue: body });
            this.setState({ topProgressBar: false, errorMsgVisible: false });
          });
        } else {
          this.AdminPointService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              topProgressBar: false,
              dataTableIsLoading: false,
              errorMsgVisible: false,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection !!!",
        });
      });
  }

  onHide = (event) => {
    this.setState({ visible: false });
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  };

  bannerDetails = (rowData) => {
    console.log(rowData);
    this.AdminPointService.getBannerFileContent(rowData.bannerId)
      .then((res) => {
        console.log("res", res);
        if (res.status == 302) {
          return res.json().then((body) => {
            console.log(body);
            let contentType = this.netiFileHandler.getImageContentType(
              rowData.attachmentFileName
            );
            console.log(contentType);
            const fileContent = body.fileContent;
            const attachmentFilePath = body.attachmentFilePath;
            const attachmentFileName = body.attachmentFileName;

            const image = contentType + body.fileContent;
            this.setState({
              image,
              fileContent,
              attachmentFilePath,
              attachmentFileName,
            });
          });
        }
      })
      .catch((error) => {});
    console.log("image", this.state.image);
    this.viewDialog();
    this.setState({ updateBannerDetails: rowData });
  };

  viewDialog = () => {
    this.setState({ visible: true });
    this.CommonFuctionality.blurDialogBackgroundActive();
  };

  getSlipFile = (bannerId, fileName) => {
    this.AdminPointService.getBannerFileContent(bannerId)
      .then((res) => {
        console.log("res", res);
        if (res.status == 302) {
          return res.json().then((body) => {
            console.log(body);
            let contentType = this.netiFileHandler.getImageContentType(
              fileName
            );

            let a = document.createElement("a");
            a.href = contentType + body.fileContent;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          });
        }
      })
      .catch((error) => {});
  };

  actionTemplate = (rowData) => {
    return (
      <div>
        <Button
          type="button"
          className="nw-action-button edit"
          tooltip="Update"
          icon="fas fa-edit"
          onClick={(e) => this.bannerDetails(rowData)}
        ></Button>
        <Button
          type="button"
          className="nw-action-button info"
          tooltip="View"
          icon="fas fa-eye"
          onClick={(e) => this.viewUpdateDialog(rowData, "enable")}
        ></Button>
      </div>
    );
  };

  viewUpdateDialog = (rowData, source) => {
    this.setState({ btnDisabled: false });

    this.setState({ viewDialogVisible: true, selectedViewRowData: rowData });
    this.CommonFuctionality.blurDialogBackgroundActive();
  };
  onHideViewDialog = () => {
    this.setState({ viewDialogVisible: false, selectedViewRowData: "" });

    this.CommonFuctionality.blurDialogBackgroundDeActive();
  };
  onChangeBannerCategory = (e) => {
    let { updateBannerDetails, updateBannerInfoError } = this.state;
    updateBannerDetails.coreCategoryInfoDTO.coreCategoryID = e.target.value;
    updateBannerInfoError["bannerId"] = "";
    this.setState({ updateBannerDetails, updateBannerInfoError });
    console.log("banner caterogy e", e);
    if (e.target.value === null) {
      updateBannerDetails.coreCategoryInfoDTO.coreCategoryID = "";
      this.setState({ updateBannerDetails });
    }

    this.setState({
      secondDropDownIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });
    this.UserCategoryService.fetchListByParentCategoryID(e.target.value)
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            console.log("bannerTitleList", body);
            this.setState({
              bannerTitleList: body,
              secondDropDownIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.UserCategoryService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              secondDropDownIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          secondDropDownIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  };

  onChangeBannerTitle = (e) => {
    let {
      updateBannerDetails,
      bannerTitleList,
      bannerTitleInfo,
      updateBannerInfoError,
    } = this.state;
    updateBannerDetails.bannerTitle = e.target.value;
    updateBannerInfoError["bannerTitle"] = "";
    this.setState({ updateBannerDetails, updateBannerInfoError });
  };

  onChangeBannerUrl = (e) => {
    let { updateBannerDetails, updateBannerInfoError } = this.state;
    updateBannerDetails.url = e.target.value;
    updateBannerInfoError["url"] = "";
    this.setState({ updateBannerDetails, updateBannerInfoError });
  };

  onChangeBannerDetails = (e) => {
    let { updateBannerDetails, updateBannerInfoError } = this.state;
    updateBannerDetails.bannerDetails = e.htmlValue;
    updateBannerInfoError["bannerDetails"] = "";
    this.setState({ updateBannerDetails, updateBannerInfoError });
  };

  onChangeBannerSerial = (e) => {
    let { updateBannerDetails, updateBannerInfoError } = this.state;
    updateBannerDetails.serial = e.target.value;
    updateBannerInfoError["bannerSerial"] = "";
    this.setState({ updateBannerDetails, updateBannerInfoError });
  };

  onChangeBannerStatus = (e) => {
    let { updateBannerDetails } = this.state;
    updateBannerDetails.enableStatus = e.target.value;
    this.setState({ updateBannerDetails });
  };

  onUpdateHandler = () => {
    let { updateBannerDetails } = this.state;
    updateBannerDetails.attachmentFileName = this.state.proPic.contentName;
    if (updateBannerDetails.attachmentFileName) {
      updateBannerDetails.fileContent = this.state.proPic.contentPic;
      updateBannerDetails.attachmentFilePath = `/images/nw/core/banner/${updateBannerDetails.attachmentFileName}`;
      updateBannerDetails.fileSaveOrEditable = true;
    } else {
      updateBannerDetails.fileContent = this.state.fileContent;
      updateBannerDetails.attachmentFilePath = this.state.attachmentFilePath;
      updateBannerDetails.attachmentFileName = this.state.attachmentFileName;
      updateBannerDetails.fileSaveOrEditable = true;
    }
    if (this.updateBannerFormHandleError()) {
      this.setState({ topProgressBar: true, errorMsgVisible: false });
      this.setState({ updateBannerDetails });
      this.AdminPointService.updateCoreBanner(updateBannerDetails)
        .then((res) => {
          console.log("response", res);
          if (res.status == 202) {
            this.setState({ proPic: {} });
            this.growl.show({
              severity: "success",
              summary: "Success Message",
              detail: "Successfully Updated",
              life: 800,
            });
            this.setState({ topProgressBar: false, errorMsgVisible: false });
            setTimeout(() => {
              this.onHide();
            }, 800);
            this.getBannerList();
          } else {
            this.AdminPointService.Auth.handleErrorStatus(res).then((resp) => {
              this.setState({
                topProgressBar: false,
                errorMsgVisible: true,
                errorMsgBody: resp,
              });
            });
          }
        })
        .catch((error) => {
          this.setState({
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Unable to update data. Please check connection",
          });
        });
    }
  };

  updateDialogDiscard = () => {
    this.onHide();
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  };

  updateBannerFormHandleError = () => {
    let { updateBannerInfoError, updateBannerDetails, proPic } = this.state;
    let formIsValid = true;

    if (
      updateBannerDetails.coreCategoryInfoDTO.coreCategoryID === "" ||
      updateBannerDetails.coreCategoryInfoDTO.coreCategoryID === null
    ) {
      formIsValid = false;
      updateBannerInfoError["bannerId"] = "Category can't left empty.";
    }
    if (
      updateBannerDetails.bannerTitle === "" ||
      updateBannerDetails.bannerTitle === null
    ) {
      formIsValid = false;
      updateBannerInfoError["bannerTitle"] = "Title can't left empty.";
    }
    if (updateBannerDetails.url === "") {
      formIsValid = false;
      updateBannerInfoError["url"] = "URL can't left empty.";
    }
    if (updateBannerDetails.bannerDetails === "") {
      formIsValid = false;
      updateBannerInfoError["bannerDetails"] = "Details can't left empty.";
    }
    if (
      updateBannerDetails.serial === "" ||
      updateBannerDetails.serial === null
    ) {
      formIsValid = false;
      updateBannerInfoError["bannerSerial"] = "Serial can't left empty.";
    }

    this.setState({ updateBannerInfoError });
    return formIsValid;
  };

  removeButtonOnclick = () => {
    // if(this.state.viewUpdatePicture != ''){
    //     this.setState({ uploadImage: { contentPic: '' } })
    // }
    // else if(this.state.uploadImage.contentPic != ''){
    this.setState({ proPic: { contentPic: "" } });
    // this.getImg()

    // }
  };

  onBasicUploadAuto(e) {
    let { errors } = this.state;
    errors["depositSlip"] = "";
    var reader = new FileReader();
    let photo = e.files[0];
    const scope = this;
    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = "data:" + photo.type + ";base64,"; //link will be same from the word webapps in URL
      console.log(keyw);
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      console.log(urlStr);
      let album = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name,
        fileSaveOrEditable: true,
      };
      scope.setState({ proPic: album });
    };
    console.log("propic", this.state.proPic.contentPic);
    this.setState({ errors });
  }
  render() {
    let {
      updateBannerDetails,
      topProgressBar,
      errorMsgVisible,
      errorMsgBody,
      selectedViewRowData,
    } = this.state;
    console.log(
      "updateBannerDetails.bannerTitle ",
      this.state.selectedViewRowData
    );

    let requiredSing = (
      <span style={{ color: "red", marginRight: "2px" }}>*</span>
    );

    let title = (
      <span className="text-center">
        <span>Update Banner Info</span>
      </span>
    );
    var tableHeader = (
      <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
        Banner List
        <span style={{ float: "right" }}>
          Total Found: {this.state.dataTableValue.length}
        </span>
      </div>
    );

    let bannerList = [];
    if (this.state.bannerTitleList && this.state.bannerTitleList.length) {
      bannerList = this.state.bannerTitleList.map((item) => ({
        value: item.coreCategoryID,
        label: item.categoryName,
      }));
    }

    let bannerSerialField = [];
    ((getBannerSerialFieldData) => {
      for (let i = 0; i < 50; i++) {
        bannerSerialField[i] = { label: i + 1, value: i + 1 };
      }
    })();

    let uploadFileLabel = (
      <div>
        Click / Drag Image Here To Upload <br />
        <span className="upload-file-label">(370 x 240 px (recommended)</span>
      </div>
    );

    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <div className="nw-data-table blur-section">
          <Growl ref={(el) => (this.growl = el)} />
          <DataTable
            value={this.state.dataTableValue}
            selectionMode="single"
            header={tableHeader}
            responsive={true}
            rows={10}
            columnResizeMode="fit"
            paginator={true}
            rowsPerPageOptions={[5, 10, 20]}
          >
            <Column field="serial" header="Serial" filter={true} />
            <Column
              field="coreCategoryInfoDTO.categoryName"
              header="Banner Category"
              filter={true}
            />
            <Column field="bannerTitle" header="Banner Title" filter={true} />
            <Column field="url" header="Banner URL" filter={true} />
            <Column
              field="bannerStatus"
              header="Status"
              body={this.statusTemplate}
              filter={true}
            />
            <Column
              field="action"
              header="Action"
              body={this.actionTemplate}
              style={{ textAlign: "center", width: "110px" }}
            />
          </DataTable>
        </div>
        <div className="dialog-section">
          <Dialog
            header={title}
            visible={this.state.visible}
            className="nw-dialog"
            onHide={this.onHide}
            closable
          >
            <div className="nw-form">
              <div className="p-col-12 p-xl-12">
                <div className="nw-form-body">
                  <Growl ref={(el) => (this.growl = el)} />

                  <div className="p-col-12 p-xl-12">
                    {this.state.firstDropDownIsLoading ? (
                      this.NetiContentLoader.normalFormInputField()
                    ) : (
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Banner Category <span>*</span>
                        </span>
                        <Dropdown
                          value={
                            updateBannerDetails.coreCategoryInfoDTO
                              .coreCategoryID
                          }
                          id="bannerCategory"
                          placeholder="Select Banner Category"
                          showClear={true}
                          options={this.props.categoryListOptions}
                          type="text"
                          autoWidth={false}
                          name="bannerId"
                          onChange={(e) => this.onChangeBannerCategory(e)}
                        />
                      </div>
                    )}
                    <span className="error-message">
                      {this.state.updateBannerInfoError["bannerId"]}
                    </span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    {this.state.secondDropDownIsLoading ? (
                      this.NetiContentLoader.normalFormInputField()
                    ) : (
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Banner Title <span>*</span>
                        </span>
                        <InputText
                          value={updateBannerDetails.bannerTitle}
                          onChange={(e) => this.onChangeBannerTitle(e)}
                          options={bannerList}
                          // placeholder="Select Banner Title"
                          placeholder={updateBannerDetails.bannerTitle}
                          autoWidth={false}
                          showClear={true}
                          id="name"
                          name="bannerTitle"
                          className="custom-dropdown"
                        />
                      </div>
                    )}
                    <span className="error-message">
                      {this.state.updateBannerInfoError["bannerTitle"]}
                    </span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Banner URL <span>*</span>
                      </span>
                      <InputText
                        id="bannerUrl"
                        placeholder="Select Banner Url"
                        value={updateBannerDetails.url}
                        onChange={(e) => this.onChangeBannerUrl(e)}
                        autoWidth={false}
                        name="url"
                      />
                    </div>
                    <span className="error-message">
                      {this.state.updateBannerInfoError["url"]}
                    </span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputtexteditor-label">
                        Banner Details <span>*</span>
                      </span>
                      <Editor
                        id="bannerDetails"
                        onTextChange={(e) => {
                          this.onChangeBannerDetails(e);
                        }}
                        value={updateBannerDetails.bannerDetails}
                        placeholder="Enter Banner Details"
                        style={{ width: "100%" }}
                        type="text"
                        // rows={3}
                        maxLength={200}
                        // cols={30}
                        name="bannerDetails"
                      />
                    </div>
                    <span className="error-message">
                      {this.state.updateBannerInfoError["bannerDetails"]}
                    </span>
                  </div>

                  <div className="formControl">
                    <div className="p-grid">
                      <div className="p-col-12 p-md-4">
                        <div className="p-inputgroup">
                          <label>
                            Status <span>*</span>
                          </label>
                        </div>
                      </div>

                      <div className="p-col-12 p-md-4">
                        <RadioButton
                          value={1}
                          name="status"
                          checked={updateBannerDetails.enableStatus === 1}
                          onChange={this.onChangeBannerStatus}
                        />
                        <label htmlFor="rb2" className="p-radiobutton-label">
                          Enable
                        </label>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <RadioButton
                          value={0}
                          name="status"
                          checked={updateBannerDetails.enableStatus === 0}
                          onChange={this.onChangeBannerStatus}
                        />
                        <label htmlFor="rb2" className="p-radiobutton-label">
                          Disable
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Serial {requiredSing}
                      </span>
                      <Dropdown
                        options={bannerSerialField}
                        keyfilter="pint"
                        id="bannerSerial"
                        onChange={this.onChangeBannerSerial}
                        value={updateBannerDetails.serial}
                        placeholder="Select Serial"
                        style={{ width: "100%" }}
                        showClear={true}
                        type="number"
                        name="bannerSerial"
                      />
                    </div>
                    <span className="error-message">
                      {this.state.updateBannerInfoError["bannerSerial"]}
                    </span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup nw-upload-button">
                      <span className="p-inputgroup-addon nw-inputtextarea-label">
                        Upload Image <span>*</span>
                        <br />
                        <span>(PNG/JPG/JPEG/GIF) </span>
                      </span>

                      <div className="nw-upload-button-inside">
                        {this.state.proPic.contentPic ||
                        this.state.institutePicture ? (
                          <div className="image-view-main">
                            <div className="upload-image-view">
                              <Button
                                className="delete-upload-button"
                                icon="fas fa-times-circle"
                                onClick={() =>
                                  this.setState({
                                    proPic: { contentPic: "" },
                                  })
                                }
                              />
                              <img
                                src={`data: ${this.state.proPic.extention};base64,${this.state.proPic.contentPic}`}
                                style={{ height: "80px" }}
                              />
                            </div>
                            <div className="image-title">
                              {/* {this.state.proPic.contentName ||
                                              this.state.instituteInfo.logoName} */}
                            </div>
                          </div>
                        ) : (
                          <>
                            {this.state.image && (
                              <div className="image-view-main">
                                <div className="upload-image-view">
                                  <Button
                                    className="delete-upload-button"
                                    icon="fas fa-times-circle"
                                    onClick={() =>
                                      this.setState({
                                        image: "",
                                      })
                                    }
                                  />
                                  <img
                                    src={this.state.image}
                                    style={{ height: "80px" }}
                                  />
                                </div>

                                <div className="image-title">
                                  {/* {this.state.proPic.contentName ||
                                              this.state.instituteInfo.logoName} */}
                                </div>
                              </div>
                            )}
                            <FileUpload
                              chooseLabel={
                                this.state.proPic.contentName || uploadFileLabel
                              }
                              mode="basic"
                              accept="image/*"
                              maxFileSize={1000000}
                              onSelect={this.onBasicUploadAuto.bind(this)}
                              auto={true}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {/* <span className="error-message">
                                    {errors["logo"]}
                                  </span> */}

                    <span className="error-message">
                      {this.state.proPic.contentName ? "" : errors["logo"]}
                    </span>
                  </div>

                  <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                    <Button
                      label="Discard"
                      icon="fas fa-times"
                      className="p-button p-button-danger nw-button nw-button-multiple"
                      onClick={this.updateDialogDiscard}
                    />
                    <Button
                      label="Update"
                      icon="fas fa-check"
                      className="p-button p-button-primary nw-button nw-button-multiple"
                      onClick={this.onUpdateHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            className="nw-dialog"
            header="View Notice Info"
            visible={this.state.viewDialogVisible}
            onHide={this.onHideViewDialog}
            closable
          >
            <div className="p-fluid">
              <div className="p-grid nw-form">
                <div className="p-col-12 p-xl-12">
                  <div className="nw-form-body">
                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Serial No.
                        </span>
                        <div className="nw-inputgroup-desc">
                          {" "}
                          {selectedViewRowData.serial}{" "}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Banner Catagory
                        </span>
                        {selectedViewRowData.coreCategoryInfoDTO && (
                          <div className="nw-inputgroup-desc">
                            {" "}
                            {
                              selectedViewRowData.coreCategoryInfoDTO
                                .categoryName
                            }{" "}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Banner Title
                        </span>
                        <div className="nw-inputgroup-desc">
                          {selectedViewRowData.bannerTitle}{" "}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Banner URL
                        </span>
                        <div className="nw-inputgroup-desc">
                          <a target="_blank" href={selectedViewRowData.url}>
                            {selectedViewRowData.url}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputtexteditor-label">
                          Details
                        </span>
                        <Editor
                          value={selectedViewRowData.bannerDetails}
                          className="nw-inputtexteditor"
                          readOnly={true}
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Upload File
                        </span>
                        {/* <div className="nw-inputgroup-desc">{selectedViewRowData.noticeFileName} </div> */}
                        <div className="nw-inputgroup-desc">
                          {" "}
                          <span className="">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                this.getSlipFile(
                                  selectedViewRowData.bannerId,
                                  selectedViewRowData.attachmentFileName
                                )
                              }
                            >
                              click for download banner image
                            </a>
                          </span>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Enable Status
                        </span>
                        <div className="nw-inputgroup-desc">
                          {selectedViewRowData.enableStatus == 1
                            ? "Enable"
                            : "Disable"}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
