import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';
import { MonthWiseTeacherAttendance } from './MonthWiseTeacherAttendance';
import { TeacherProfile } from './TeacherProfile';
import { TeacherPayroll } from './TeacherPayroll';
import { TeacherRoutine } from './TeacherRoutine';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';

export class TeacherPortalMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userHrDetails: { ...props },
            staffProfileInfo: '',
            hrInstitute: '',
            profileRender: true,
            attendanceRender: false,
            routineRender: false,
            paroll: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',

            menuItems: [
                { label: 'Profile', icon: "fas fa-square", className: "menu-item-active", command: (e) => this.goToTeacherPortal(e, 'profile') },
                { label: 'Attendance', icon: "fas fa-square", className: "", command: (e) => this.goToTeacherPortal(e, 'attendance') },
                { label: 'Routine', icon: "fas fa-square", className: "", command: (e) => this.goToTeacherPortal(e, 'routine') },
                { label: 'Payroll', icon: "fas fa-square", className: "", command: (e) => this.goToTeacherPortal(e, 'paroll') },
            ]
        }

        this.teacherPortal = new TeacherPortal();
    }

    async goToTeacherPortal(e, labelStatus) {
        let menuItems = this.state.menuItems;
        menuItems.map(item => { item.className = "" });
        this.setState({ menuItems });
        e.item.className = "menu-item-active";

        { labelStatus === 'profile' ? this.setState({ profileRender: true }) : this.setState({ profileRender: false }) };
        { labelStatus === 'attendance' ? this.setState({ attendanceRender: true }) : this.setState({ attendanceRender: false }) };
        { labelStatus === 'routine' ? this.setState({ routineRender: true }) : this.setState({ routineRender: false }) };
        { labelStatus === 'paroll' ? this.setState({ parollRender: true }) : this.setState({ parollRender: false }) };

    }

    componentWillMount() {
        if (this.props.location.userHRDetails) {
            let idForHr = this.props.location.userHRDetails.idForHr;
            let hrProps = {
                instituteId: this.props.location.userHRDetails.emDetailsInfoDTO.instituteId,
                idForHr
            };
            localStorage.setItem("hrProps", JSON.stringify(hrProps));

        }

        this.loadHrInfo();
    }

    loadHrInfo = () => {
        const hrProps = JSON.parse(localStorage.getItem("hrProps"));
        if (!hrProps) {
            return;
        }
        this.setState({ topProgressBar: true, errorMsgVisible: false })
        this.teacherPortal.getStaffProfileInfo(hrProps.instituteId, hrProps.idForHr)
            .then(data => {
                console.log('Log: loadHrInfo -> data', data)
                if (data.item == null) {
                    return this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: data.message })
                }
                if (data) {
                    this.setState({ staffProfileInfo: data, hrInstitute: hrProps.instituteId })
                    this.setState({ topProgressBar: false, errorMsgVisible: false })
                }
            }).catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));

    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, staffProfileInfo } = this.state;
        let staffName, staffCustomId, staffImage, instituteID;
        let staticImg = 'assets/layout/images/userAvatar.png';

        if (this.state.staffProfileInfo && this.state.staffProfileInfo.item) {
            console.log('Log: render -> this.state.staffProfileInfo.item', this.state.staffProfileInfo.item)
            staffName = this.state.staffProfileInfo.item.staffName;
            staffCustomId = this.state.staffProfileInfo.item.customStaffId;
            instituteID = this.state.hrInstitute;
            staffImage = this.state.staffProfileInfo.item.image ? "data:image/jpeg;base64," + this.state.staffProfileInfo.item.image : '';
        }

        return (
            <div className="main-section">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid nw-form teacher-portal" style={{ position: "relative"}}>
                    <div className="portal-menu ">
                        <Menu
                            model={this.state.menuItems}
                            popup={true}
                            ref={el => (this.menu = el)}
                            appendTo={document.body}
                            className="portal-menu-component-teacher"
                        />
                        <Button
                            icon="fas fa-align-right"
                            onClick={event => this.menu.toggle(event)}
                            //title="Menu"
                            className="portal-menu-button"
                            tooltip="View Menu"
                        />
                    </div>
                    <div className="p-col-12">
                        <div className="p-fluid">
                            <div className="layout-dashboard">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="portal-dashboard-profile  portal-dashboard p-0">
                                            <div className="layout-dashboard">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                                        <div className="portal-profile-info-wrapper">
                                                            <div className="portal-profile-image">
                                                                <img
                                                                    src={staffImage || staticImg}
                                                                    alt="teacher Image"
                                                                    width="100px"
                                                                />
                                                            </div>
                                                            <div className="portal-profile-info">
                                                                <h1>{staffName || ''}</h1>
                                                                <h3>{staffCustomId || ''}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="p-col-12 p-lg-12 p-xl-12 nw-m-t-80">
                        {this.state.profileRender ? <TeacherProfile teacherBasicInfo={this.state.staffProfileInfo} /> : ''}
                        {this.state.attendanceRender ? <MonthWiseTeacherAttendance teacherBasicInfo={this.state.staffProfileInfo} hrInstitute={this.state.hrInstitute} /> : ''}
                        {this.state.parollRender ? <TeacherPayroll teacherBasicInfo={this.state.staffProfileInfo} hrInstitute={this.state.hrInstitute} /> : ''}
                        {this.state.routineRender ? <TeacherRoutine teacherBasicInfo={this.state.staffProfileInfo} hrInstitute={this.state.hrInstitute} /> : ''}
                    </div>

                </div>
            </div >

        );
    }
}
