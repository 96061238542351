import React, { Component } from 'react';
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from "../../common/ErrorMessageView";
import CommonFuctionality from "../../common/CommonFuctionality";
import { BankBranchList } from '../BankBranchList';

export class BankBranchInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateErrors: {},
            bankName: '',
            branchName: '',
            districtList: [],
            details: '',
            saveErrors: {},
            district: '',
            routingNum: '',
            topProgressBar: false,
            dataTableIsLoading: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
        }
        this.userCategoryService = new UserCategoryService();
        this.adminPointService = new AdminPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();

    }
    componentDidMount() {
        this.getBankListInfo();
        this.getDistrictList();
    }

    getBankListInfo() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListBy2ndParentTypeDefCode('T100')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ bankList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });

    }

    getDistrictList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode('T10203')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ districtList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    handleSave = () => {
        let requestedObject = {
            "branchName": this.state.branchName,
            "detailsNote": this.state.details,
            "routingNumber": this.state.routingNum,
            "coreBankInfoDTO": {
                "coreCategoryID": this.state.bankName
            },
            "coreDistrictInfoDTO": {
                "coreCategoryID": this.state.district
            }
        }

        if (this.handleSaveError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' })
            this.adminPointService.saveAdminBankBranch(requestedObject)
                .then(res => {

                    if (res.status === 201) {
                        this.growl.show({ severity: "success", summary: "Success Message", detail: "Successfully Saved." });
                        this.fetchUpdatedBranchs();
                        this.setState({ bankName: '', branchName: '', details: '', district: '', routingNum: '' });
                        this.setState({ topProgressBar: false, errorMsgVisible: false })
                    }
                    else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => { this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp }) });
                    }

                }).catch(error =>
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                );
        }
    };

    fetchUpdatedBranchs = () => {
        this.refs.branchListRef.fetchBankBranchList();
    }

    updateDialogDiscard = () => {
        this.onHide();
    }

    handleSaveError() {
        let { saveErrors } = this.state;
        let formIsValid = true;

        if (this.state.bankName === "") {
            formIsValid = false;
            saveErrors["bankName"] = "Bank Name can't left empty";
        }
        if (this.state.district === "") {
            formIsValid = false;
            saveErrors["district"] = "District can't left empty";
        }
        if (this.state.branchName === "") {
            formIsValid = false;
            saveErrors["branchName"] = "Branch Name can't left empty";
        }
        if (this.state.routingNum === "") {
            formIsValid = false;
            saveErrors["routingNum"] = "Routing Number can't left empty";
        }
        if (this.state.details === "") {
            formIsValid = false;
            saveErrors["details"] = "Details can't left empty";
        }
        this.setState({ saveErrors });
        return formIsValid;
    }

    getListDetailData(coreBankAccId) {
        this.adminPointService.fetchBankAccountListDetailData(coreBankAccId)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ bankAccountDetails: body });
                    });
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let bankListOptions = []
        if (this.state.bankList && this.state.bankList.length) {
            bankListOptions = this.state.bankList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
        let districtItemList = []
        if (this.state.districtList && this.state.districtList.length) {
            districtItemList = this.state.districtList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                                    {this.state.firstDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Bank Name <span>*</span></span>
                                            <Dropdown
                                                placeholder='Select Bank'
                                                options={bankListOptions}
                                                value={this.state.bankName}
                                                onChange={(e) => this.setState({ bankName: e.value, saveErrors: {} })}
                                                filter={true}
                                                filterBy="label,value"
                                                autoWidth={false}
                                                showClear={true}
                                            />
                                        </div>
                                    }
                                    <span className="error-message">
                                        {this.state.saveErrors["bankName"]}
                                    </span>

                                </div>

                                <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                                    {this.state.firstDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">District<span>*</span></span>
                                            <Dropdown
                                                placeholder="Enter District"
                                                onChange={(e) => this.setState({ district: e.target.value, saveErrors: {} })}
                                                value={this.state.district}
                                                options={districtItemList}
                                                filter={true}
                                                autoWidth={false}
                                                name="district"
                                                showClear={true}
                                            />
                                        </div>
                                    }
                                    <span className="error-message">
                                        {this.state.saveErrors["district"]}
                                    </span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">branch Name <span>*</span></span>
                                        <InputText
                                            name="text"
                                            placeholder="Enter branch name"
                                            name='branchName'
                                            onChange={(e) => this.setState({ branchName: e.target.value, saveErrors: {} })}
                                            value={this.state.branchName}
                                        />
                                    </div>
                                    <span className="error-message">
                                        {this.state.saveErrors["branchName"]}
                                    </span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Routing Number <span>*</span></span>
                                        <InputText
                                            name="text"
                                            placeholder="Enter Routing Number"
                                            keyfilter="pint"
                                            name='routingNum'
                                            onChange={(e) => this.setState({ routingNum: e.target.value, saveErrors: {} })}
                                            value={this.state.routingNum}
                                        />
                                    </div>
                                    <span className="error-message">
                                        {this.state.saveErrors["routingNum"]}
                                    </span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                        <InputTextarea
                                            name="text"
                                            name='details'
                                            placeholder="Enter Details"
                                            onChange={(e) => this.setState({ details: e.target.value, saveErrors: {} })}
                                            value={this.state.details}
                                        />
                                    </div>
                                    <span className="error-message">
                                        {this.state.saveErrors["details"]}
                                    </span>

                                </div>

                                <div className="p-col-12 p-xl-12">

                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-check"
                                        label="Save"
                                        onClick={this.handleSave}
                                    />
                                </div>
                                <div className="p-col-5"> </div>
                            </div>

                        </div>

                        <div className="p-col-12 p-xl-12 nw-data-table"><BankBranchList ref="branchListRef" /></div>

                    </div>
                </div>

            </div>

        )
    }
}