import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';
import Formatter from '../../../utils/Formatter';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';

let statusListOptions = [
    { label: "Pending", value: 'pending' },
    { label: "Approve", value: 1 },
    { label: "Reject", value: 2 }
]

let totalRequestAmount = 0;
let requestDateTime;

let errors = {}
export class Withdraw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerPicture: '',
            dataTableValue: [],
            netiIdAndNameBody: {
                customNetiID: ''
            },
            approvalValue: 'pending',
            withdrawApproveObj: {
                approveNote: '',
                transactionNumber: 0,
                userBasicReportDTO: {
                    userBasicInfoDTO: {
                        customNetiID: '',
                        globalAreaInfoDTO: {
                            categoryName: ''
                        }
                    },
                    partnerDesignation: '',
                    partnerStatus: '',
                    assignedArea: ''
                },
                userBankAccountInfoDTO: {
                    coreCategoryInfoDTO: {
                        categoryName: '',
                        coreCategoryID: ''
                    }
                }
            },
            bankList: '',
            visible: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            bankName: '',
            errors: {}
        };

        this.actionDetails = this.actionDetails.bind(this);
        this.viewDialog = this.viewDialog.bind(this)
        this.onHide = this.onHide.bind(this)
        //this.netiIdAndNameBodyTemplet = this.netiIdAndNameBodyTemplet.bind(this)
        this.NetiContentLoader = new NetiContentLoader();
        this.CRMPointService = new CRMPointService();
        this.netiFileHandler = new NetiFileHandler();
        this.formatter = new Formatter();
        this.getProfileImg = this.getProfileImg.bind(this);
        this.ValidatorUtility = new ValidatorUtility();

    }

    componentWillMount() {
        this.getWithdrawPendingList("withdraw", 0);
    }

    getWithdrawPendingList(type, status) {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        totalRequestAmount = 0

        this.CRMPointService.fetchDepositPendingList(type, status)
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        totalRequestAmount = body.map(item => item.requestedAmount).reduce((a, b) => { return (a + b) }, 0);
                        body.map((item) => {
                            item.userBasicReportDTO.userBasicInfoDTO.basicMobile = this.formatter.formatContactNo(item.userBasicReportDTO.userBasicInfoDTO.basicMobile);
                            // item['mobNoAndArea'] = `${item.userBasicInfoDTO.basicMobile} ${item.userBasicInfoDTO.globalAreaInfoDTO.categoryName}`
                            // item['netiID&name'] = `${item.userBasicInfoDTO.customNetiID} ${item.userBasicInfoDTO.fullName}`
                            item['requestTime'] = NetiDateUtils.getHHMMSSFForHome(item.requestDate);
                            item['requestDate'] = NetiDateUtils.getAnyShortForm(item['requestDate'], 'DD-MMM-YYYY');
                            item['requestedAmount'] = item['requestedAmount'].toFixed(2)
                        });
                        this.setState({ dataTableValue: body })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });

    }

    getBankList(defaultCode) {
        this.setState({ dataTableIsLoading: true, errorMsgVisible: false });
        this.CRMPointService.fetchBankList(defaultCode)
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        this.setState({ bankList: body, dataTableIsLoading: false });
                    });
                } else {
                    return res.json().then((body) => {
                        this.setState({ bankList: body, dataTableIsLoading: false });
                        this.growl.show({ severity: 'warn', summary: 'Warning', detail: 'Connection Problem' });
                    });
                }
            }).catch(error => {
                this.setState({ dataTableIsLoading: false });
            });
    }

    onChangeStatus(value) {
        errors["transactionAndApproveError"] = ''
        this.setState({ errors, approvalValue: value });
    }

    onChangeBank(e) {

        this.setState({ bankName: e.target.value })

        let { withdrawApproveObj } = this.state;

        withdrawApproveObj.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID = e.target.value

        this.setState({ withdrawApproveObj });

    }

    onChangeTransaction(value) {
        let { withdrawApproveObj } = this.state
        errors["transactionID"] = ''
        withdrawApproveObj.transactionNumber = value
        this.setState({ errors, withdrawApproveObj });
    }

    onChangeReject(value) {
        let { withdrawApproveObj } = this.state
        errors["note"] = ''
        withdrawApproveObj.approveNote = value
        this.setState({ errors, withdrawApproveObj });
    }

    onChangeUserName = (e) => {
        let { domainApproveObj, domainErrorInfo } = this.state
        domainErrorInfo["username"] = ''
        domainApproveObj.username = e.target.value;
        this.setState({ errors, domainApproveObj, domainErrorInfo });
    }

    onChangePassword = (e) => {
        let { domainApproveObj, domainErrorInfo } = this.state
        domainErrorInfo["password"] = ''
        domainApproveObj.password = e.target.value;
        this.setState({ errors, domainApproveObj, domainErrorInfo });
    }

    onChangePurchaseDate = (e) => {
        let { domainApproveObj, domainErrorInfo } = this.state
        domainErrorInfo["purchaseDate"] = ''
        domainApproveObj.purchaseDate = e.target.value;
        this.setState({ errors, domainApproveObj, domainErrorInfo });
    }

    onChangeExpiryDate = (e) => {
        let { domainApproveObj, domainErrorInfo } = this.state
        domainErrorInfo["expireDate"] = ''
        domainApproveObj.expiryDate = e.target.value;
        this.setState({ errors, domainApproveObj, domainErrorInfo });
    }

    onChangeReminderDate = (e) => {
        let { domainApproveObj, domainErrorInfo } = this.state
        domainErrorInfo["reminderDate"] = ''
        domainApproveObj.reminderDate = e.target.value;
        this.setState({ errors, domainApproveObj, domainErrorInfo });
    }

    onChangeDomainProvider = (e) => {
        let { domainApproveObj, domainErrorInfo } = this.state
        domainErrorInfo["domainProvider"] = ''
        domainApproveObj.purchaseFrom = e.target.value;
        this.setState({ errors, domainApproveObj, domainErrorInfo });
    }

    onChangeEmail = (e) => {
        let { domainApproveObj, domainErrorInfo } = this.state
        domainErrorInfo["email"] = ''
        domainApproveObj.email = e.target.value;
        this.setState({ errors, domainApproveObj, domainErrorInfo });
    }

    onChangeContact = (e) => {
        let { domainApproveObj, domainErrorInfo } = this.state
        domainErrorInfo["contact"] = ''
        domainApproveObj.contact = e.target.value;
        this.setState({ errors, domainApproveObj, domainErrorInfo });
    }

    actionDetails(rowData, column) {
        return <center>
            <Button
                className="p-button p-button-info p-button-text-only"
                icon="fas fa-info-circle"
                title="View Purchase"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </center>;
    }

    viewDialog(rowData) {
        this.getBankList("T10104")
        let { withdrawApproveObj } = this.state
        this.setState({ selectedRow: rowData, visible: true });

        withdrawApproveObj.balanceRequestLogId = rowData.balanceRequestLogId

        if (!rowData.transactionNumber) {
            withdrawApproveObj.transactionNumber = ''
        } else {
            withdrawApproveObj.transactionNumber = rowData.transactionNumber
        }

        withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.customNetiID = rowData.userBasicReportDTO.userBasicInfoDTO.customNetiID
        withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.fullName = rowData.userBasicReportDTO.userBasicInfoDTO.fullName
        withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.basicMobile = rowData.userBasicReportDTO.userBasicInfoDTO.basicMobile
        withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.globalAreaInfoDTO.categoryName = rowData.userBasicReportDTO.userBasicInfoDTO.globalAreaInfoDTO.categoryName
        withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.userWalletBalance = rowData.userBasicReportDTO.userBasicInfoDTO.userWalletBalance
        withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.imagePath = rowData.userBasicReportDTO.userBasicInfoDTO.imagePath
        withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.imageName = rowData.userBasicReportDTO.userBasicInfoDTO.imageName
        withdrawApproveObj.requestDate = rowData.requestDate;
        withdrawApproveObj.userBankAccountInfoDTO.coreCategoryInfoDTO.categoryName = rowData.userBankAccountInfoDTO.coreCategoryInfoDTO.categoryName
        withdrawApproveObj.userBankAccountInfoDTO.bankAccNumber = rowData.userBankAccountInfoDTO.bankAccNumber

        withdrawApproveObj.requestType = rowData.requestType
        withdrawApproveObj.attachFilePath = rowData.attachFilePath
        withdrawApproveObj.transactionDate = NetiDateUtils.getAnyShortForm(rowData['transactionDate'], 'DD-MMM-YYYY')
        withdrawApproveObj.fromWhere = rowData.fromWhere
        withdrawApproveObj.requestedAmount = rowData.requestedAmount
        withdrawApproveObj.requestNote = rowData.requestNote

        if (rowData.userBasicReportDTO !== null) {
            // withdrawApproveObj.userBasicInfoDTO.userBasicReportDTO.partnerDesignation = rowData.userBasicInfoDTO.userBasicReportDTO.partnerDesignation
            withdrawApproveObj.userBasicReportDTO.partnerStatus = rowData.userBasicReportDTO.partnerStatus
            withdrawApproveObj.userBasicReportDTO.assignedArea = rowData.userBasicReportDTO.assignedArea
        }
        // requestDateTime = rowData.requestTime
        this.setState({ withdrawApproveObj })
        this.getProfileImg()
    }

    onHide() {
        let { withdrawApproveObj } = this.state;
        withdrawApproveObj.approveNote = '';
        withdrawApproveObj.transactionNumber = ''
        this.setState({ visible: false, approvalValue: 'pending', bankName: '' })
        this.setState({
            withdrawApproveObj: {
                approveNote: '',
                transactionNumber: 0,
                userBasicReportDTO: {
                    userBasicInfoDTO: {
                        customNetiID: '',
                        globalAreaInfoDTO: {
                            categoryName: ''
                        },
                    },
                    partnerDesignation: '',
                    partnerStatus: '',
                    assignedArea: ''
                },
                userBankAccountInfoDTO: {
                    coreCategoryInfoDTO: {
                        categoryName: '',
                        coreCategoryID: ''
                    }
                }
            },
        });
    }

    actionBodyTemplet = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-edit"
                tooltip="Update"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    getProfileImg() {

        let { withdrawApproveObj } = this.state;
        let staticImg = 'assets/layout/images/userAvatar.png';

        if (withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.imagePath !== null) {
            this.netiFileHandler.getByteImage(withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.imagePath)
                .then((res) => {

                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.imageName);
                                this.setState({ partnerPicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ partnerPicture: staticImg });
                    }
                });
        } else { this.setState({ partnerPicture: staticImg }) };

    }

    handleError = () => {
        let { errors, withdrawApproveObj } = this.state;
        let formIsValid = true;

        if (this.state.approvalValue === 1) {
            if (this.state.bankName === '') {
                formIsValid = false;
                errors["fromAccount"] = "From Account can't left empty.";
            }
            if (withdrawApproveObj.transactionNumber === '') {
                formIsValid = false;
                errors["transactionID"] = "Transaction Reference No. can't left empty.";
            }
        }
        else if (this.state.approvalValue === 2) {
            if (withdrawApproveObj.approveNote === '') {
                formIsValid = false;
                errors["note"] = "Reject Note can't left empty.";
            }
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitUpdate() {
        let { withdrawApproveObj } = this.state

        let updateWithdrawObj = {
            "requestedAmount": withdrawApproveObj.requestedAmount,
            "balanceRequestLogId": withdrawApproveObj.balanceRequestLogId,
            "requestStatus": this.state.approvalValue,
            "transactionNumber": withdrawApproveObj.transactionNumber,
            "approveNote": withdrawApproveObj.approveNote,
            "coreBankAccountInfoDTO": {
                coreBankAccId: withdrawApproveObj.userBankAccountInfoDTO.coreCategoryInfoDTO.coreCategoryID
            }
        }

        if (this.handleError()) {

            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            this.CRMPointService.updateBalanceWithdrawApprove(updateWithdrawObj)
                .then(res => {

                    if (res.status === 202) {
                        this.setState({ dataTableIsLoading: false, bankName: '', transactionNumber: '', approveNote: '', approvalValue: 'pending', topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Update successfully' });
                        this.onHide();
                        this.getWithdrawPendingList("withdraw", 0);
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        }

    }

    requestedAmountTemplate = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.requestedAmount)
    }

    availableBalanceTemplate = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.userBasicReportDTO.userBasicInfoDTO.userWalletBalance)
    }

    netiIDandNameTemplate = (rowData) => {
        return <span>{rowData.userBasicReportDTO.userBasicInfoDTO.customNetiID} <br /> {rowData.userBasicReportDTO.userBasicInfoDTO.fullName}</span>;

    }
    mobNoAndAreaTemplate = (rowData) => {
        return <span>{rowData.userBasicReportDTO.userBasicInfoDTO.basicMobile} <br /> {rowData.userBasicReportDTO.userBasicInfoDTO.globalAreaInfoDTO.categoryName}</span>;
    }

    getDateFromTemplate = (rowData) => {
        return <center><div>{rowData.requestDate} <br /> {rowData.requestTime}</div></center>
    }

    export = () => {
        this.dt.exportCSV();
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, withdrawApproveObj, dataTableValue } = this.state;
        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Pending Withdraw Request List <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {this.state.dataTableValue.length}
            </span>
        </div>;

        let bankListOptions = []
        if (this.state.bankList && this.state.bankList.length) {
            bankListOptions = this.state.bankList.map((item) => ({
                value: item.coreBankAccId,
                label: item.accShortName
            }));
        }

        let footer = <ColumnGroup>
            <Row>
                <Column footer="" />
                <Column footer="" />
                <Column footer="" />
                <Column footer='' />
                <Column footer='' />
                <Column footer='' />
                <Column footer="Total:" className="nw-tfoot-total-text" />
                <Column footer={this.ValidatorUtility.currencyFormatter(totalRequestAmount)} />
                <Column footer='' />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        <Growl ref={(el) => this.growl = el} />
                        <div className="nw-data-table nw-data-table-tabview">
                            {!this.state.dataTableIsLoading ?
                                <div>
                                    <DataTable
                                        header={header}
                                        value={this.state.dataTableValue}
                                        responsive={true}
                                        ref={(el) => { this.dt = el; }}
                                        footerColumnGroup={footer}
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="requestDate" header="Request Date" body={this.getDateFromTemplate} filter={true} />
                                        <Column field="userBasicReportDTO.userBasicInfoDTO.customNetiID" header="Neti ID" filter={true} />
                                        <Column field="userBasicReportDTO.userBasicInfoDTO.fullName" header="Name" filter={true} />
                                        <Column field="userBasicReportDTO.userBasicInfoDTO.basicMobile" header="Mobile No." filter={true} />
                                        <Column field="userBankAccountInfoDTO.coreCategoryInfoDTO.categoryName" header="Bank" filter={true} />
                                        <Column field="userBankAccountInfoDTO.bankAccNumber" header="Account Number" filter={true} />
                                        <Column field="userBankAccountInfoDTO.coreBankBranchInfoDTO.branchName" header="Branch Name" filter={true} />
                                        <Column field="userBankAccountInfoDTO.coreBankBranchInfoDTO.routingNumber" header="Routing Number" filter={true} />
                                        <Column field="userBasicReportDTO.userBasicInfoDTO.userWalletBalance" header="Available Balance" body={this.availableBalanceTemplate} filter={true} />
                                        <Column field="requestedAmount" header="Request Amount" body={this.requestedAmountTemplate} filter={true} />
                                        <Column field="" header="Action" body={this.actionBodyTemplet} style={{ textAlign: "center", width: "80px" }} />

                                    </DataTable>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                            <Button
                                                label="Download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                icon="fas fa-arrow-down"
                                                onClick={this.export}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }

                        </div>
                    </div>

                    <Dialog
                        className="nw-dialog"
                        header="Withdraw Request"
                        // className="nonHideDialog customDialogWidth " 
                        onHide={this.onHide}
                        visible={this.state.visible}
                        dismissableMask={true}
                        blockScroll={true}
                    >

                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">

                                    <div className="p-col-12 p-xl-12">
                                        <Fieldset legend="Requester Information" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <center>
                                                        <img
                                                            src={this.state.partnerPicture}
                                                            alt="partner picture"
                                                            style={{ height: "80px" }}
                                                        />
                                                    </center>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Neti ID
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.customNetiID}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Name
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.fullName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Mobile No
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.basicMobile}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Wallet Balance
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.ValidatorUtility.currencyFormatter(withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.userWalletBalance)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Address
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.globalAreaInfoDTO.categoryName}
                                                        </div>
                                                    </div>
                                                </div>

                                                {withdrawApproveObj.userBasicReportDTO && withdrawApproveObj.userBasicReportDTO.partnerDesignation !== '' ?

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Partner Type
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {withdrawApproveObj.userBasicReportDTO.partnerDesignation}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                                }
                                                {withdrawApproveObj.userBasicReportDTO && withdrawApproveObj.userBasicReportDTO.partnerStatus !== '' ?

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Partner Status
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {withdrawApproveObj.userBasicReportDTO.partnerStatus === 1 ? "Active" : 'Inactive'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''}

                                                {withdrawApproveObj.userBasicReportDTO && withdrawApproveObj.userBasicReportDTO.assignedArea !== '' ?

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Partner Area
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {withdrawApproveObj.userBasicReportDTO.assignedArea}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''}

                                            </div>

                                        </Fieldset>

                                        <Fieldset legend="Withdraw Information" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Request Date
                                                            </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {withdrawApproveObj.requestDate}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Bank
                                                            </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {withdrawApproveObj.userBankAccountInfoDTO.coreCategoryInfoDTO.categoryName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Account Number
                                                            </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {withdrawApproveObj.userBankAccountInfoDTO.bankAccNumber}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Available Amount
                                                            </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.ValidatorUtility.currencyFormatter(withdrawApproveObj.userBasicReportDTO.userBasicInfoDTO.userWalletBalance)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Request Amount
                                                            </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.ValidatorUtility.currencyFormatter(withdrawApproveObj.requestedAmount)}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="p-grid p-col-12">
                                                            <div className="p-col-4" >  <label htmlFor="input">Request Date </label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    {withdrawApproveObj.requestDate}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                {/* <div className="p-grid p-col-12">
                                                            <div className="p-col-4" >  <label htmlFor="input">Bank</label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    {withdrawApproveObj.userBankAccountInfoDTO.coreCategoryInfoDTO.categoryName}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                {/* <div className="p-grid p-col-12">
                                                            <div className="p-col-4" >  <label htmlFor="input">Account Number </label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    {withdrawApproveObj.userBankAccountInfoDTO.bankAccNumber}
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                {/* <div className="p-grid p-col-12">
                                                            <div className="p-col-4" >  <label htmlFor="input">Available Amount </label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                   { withdrawApproveObj.userBasicInfoDTO.userWalletBalance }
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                {/* <div className="p-grid p-col-12">
                                                            <div className="p-col-4" >  <label htmlFor="input">Request Amount </label></div>
                                                            <span style={{ paddingTop: "5px", paddingRight: "10px" }}>:</span>
                                                            <div className="p-col-6" >
                                                                <div className="p-inputgroup">
                                                                    { withdrawApproveObj.requestedAmount }
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                <div className="p-col-12 p-xl-12">
                                                    {/* <div className="p-col-6" > */}
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Status</span>
                                                        <Dropdown
                                                            placeholder='Select Status'
                                                            options={statusListOptions}
                                                            value={this.state.approvalValue}
                                                            onChange={(e) => this.onChangeStatus(e.target.value)}
                                                            filter={true}
                                                            filterBy="label,value"
                                                            autoWidth={false}
                                                        />
                                                        {/* </div> */}
                                                    </div>
                                                </div>

                                                {
                                                    this.state.approvalValue === 2 ?
                                                        <div className="">
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon nw-inputtextarea-label">Reject Note <span>*</span></span>
                                                                    <InputTextarea
                                                                        name="note"
                                                                        placeholder='Enter Reject Note'
                                                                        value={withdrawApproveObj.approveNote}
                                                                        onChange={(e) => this.onChangeReject(e.target.value)}
                                                                    />
                                                                </div>
                                                                <span className="error-message">{this.state.errors["note"]}</span>
                                                            </div>
                                                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                                                <Button
                                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                                    label="Submit"
                                                                    icon="fa fa-save"
                                                                    onClick={e => this.onSubmitUpdate(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        : ""
                                                }

                                                {
                                                    this.state.approvalValue === 1 ?
                                                        <div>
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">From Account</span>

                                                                    <Dropdown
                                                                        placeholder="Select From Account"
                                                                        value={this.state.bankName}
                                                                        options={bankListOptions}
                                                                        onChange={(e) => this.onChangeBank(e)}
                                                                        filter={true}
                                                                        filterBy="label,value"
                                                                        showClear={true}
                                                                        autoWidth={false}
                                                                        name="fromAccount"
                                                                    />
                                                                </div>
                                                                <span className="error-message">{this.state.errors["fromAccount"]}</span>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Transaction ID</span>
                                                                    <InputText
                                                                        placeholder="Enter Transaction ID"
                                                                        name="transactionID"
                                                                        value={withdrawApproveObj.transactionNumber}
                                                                        onChange={(e) => this.onChangeTransaction(e.target.value)}
                                                                    />
                                                                </div>
                                                                <span className="error-message">{this.state.errors["transactionID"]}</span>
                                                            </div>

                                                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                                                <Button
                                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                                    label="Submit"
                                                                    icon="fa fa-save"
                                                                    onClick={e => this.onSubmitUpdate(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        : ""
                                                }

                                            </div>

                                        </Fieldset>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </Dialog>

                </div>
            </div>
        )
    }
}