import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import AddMenuForm from "./AddMenuForm";

const CmsMenuItem = () => {
  return (
    <div className="layout-dashboard">
      <div className="p-grid">
        <div className="p-col-12 p-lg-12 p-md-12 nw-tabview nw-tabview-new">
          {/* <TabView>
            <TabPanel header="Category Type">
              <CategoryType />
            </TabPanel>
            <TabPanel header="Category Name">
              <CategoryName />
            </TabPanel>
          </TabView> */}
          <div className="cmsMenuItemContainer">
            <h2>Add CMS Menu</h2>
            <AddMenuForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export { CmsMenuItem };
