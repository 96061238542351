import React, { Component } from 'react';
import { PrepaidBillCollection } from './PrepaidBillCollection';
import { PostpaidBillCollection } from './PostpaidBillCollection';
import { TabView, TabPanel } from 'primereact/tabview';

export class partnerBillCollectionListMain extends Component {
    
    render() {
        return (
            <div className="p-fluid">
                <div className="p-grid">

                    <div className="p-col-12 p-lg-12 p-xl-12 nw-tabview">
                        <TabView >
                            <TabPanel header="Postpaid">
                                <PostpaidBillCollection />
                            </TabPanel>
                            <TabPanel header="Prepaid">
                                <PrepaidBillCollection />
                            </TabPanel>
                        </TabView>
                    </div>

                </div>
            </div>
        )
    }
}