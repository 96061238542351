import React, { Component } from 'react';

import { Growl } from 'primereact/growl';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { ErrorMessageView } from '../../../common/ErrorMessageView';

export class ModuleAndPointWiseTokenSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tokenSummary: {
                em: { pending: 0, processing: 0, solved: 0 },
                nw: { pending: 0, processing: 0, solved: 0 }
            },
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            errors: {},
        }
        this.managementPointService = new ManagementPointService();
    }

    componentWillMount() {
        this.fetchTokenInfo();
        this.removeLocalStorageVariable('moduleWiseSolvedTokenInfo');
        this.removeLocalStorageVariable('problemWiseSolvedTokenInfo');
        this.removeLocalStorageVariable('titleWiseSolvedTokenInfo');
    }

    removeLocalStorageVariable = (tokenVariable) =>{
        localStorage.removeItem(tokenVariable);
    }

    fetchTokenInfo() {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.managementPointService.fetchTokenInfo()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ tokenSummary: body, topProgressBar: false, errorMsgVisible: false });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    render() {
        let { tokenSummary, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        return (
            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />

                <div className="accrodion-section">
                    <Accordion activeIndex={this.state.activeIndex || 0} onTabChange={(e) => this.setState({ activeIndex: e.index })} >
                        <AccordionTab header="Application Wise Token Summary">

                            <div className="p-grid token-summary">
                                <div className="p-col-6">
                                    <Card>
                                        <h4><header>Eduman Token Info</header></h4>
                                        <center>
                                            <h1>{tokenSummary.em.pending + tokenSummary.em.processing + tokenSummary.em.solved}</h1>
                                        </center>
                                        <br />
                                        {/* <hr/> */}

                                        <div className="p-grid token-numbers text-center">

                                            <div className="p-col-12"><hr /><h5>Total Token</h5><hr /></div>

                                            <div className="p-col-4">
                                                <Link className="text-warning" to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_TOKEN_LIST, appName: 'em', tokenStatus: 'pending' }} >
                                                    <u>{tokenSummary.em.pending}</u>
                                                </Link>
                                            </div>
                                            <div className="p-col-4">
                                                <Link className="text-primary" to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_TOKEN_LIST, appName: 'em', tokenStatus: 'processing' }} >
                                                    <u>{tokenSummary.em.processing}</u>
                                                </Link>
                                            </div>
                                            <div className="p-col-4">
                                                <Link className="text-success" to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_SOLVED_MODULE_WISE_TOKEN, appName: 'Eduman', appDefCode: 'C11001001' }} >
                                                    <u>{tokenSummary.em.solved}</u>
                                                </Link>
                                            </div>

                                            <div className="p-col-4 bg-warning">Pending</div>
                                            <div className="p-col-4 bg-primary">Processing</div>
                                            <div className="p-col-4 bg-success">Solved</div>

                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-6">
                                    <Card>
                                        <h4><header>Netiworld Token Info</header></h4>
                                        <center>
                                            <h1>{tokenSummary.nw.pending + tokenSummary.nw.processing + tokenSummary.nw.solved}</h1>
                                        </center>
                                        <br />
                                        {/* <hr/> */}

                                        <div className="p-grid token-numbers text-center">

                                            <div className="p-col-12"><hr /><h5>Total Token</h5><hr /></div>

                                            <div className="p-col-4">
                                                <Link className="text-warning" to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_TOKEN_LIST, appName: 'nw', tokenStatus: 'pending'  }} >
                                                    <u>{tokenSummary.nw.pending}</u>
                                                </Link>
                                            </div>
                                            <div className="p-col-4">
                                                <Link className="text-primary" to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_TOKEN_LIST, appName: 'nw', tokenStatus: 'processing' }} >
                                                    <u>{tokenSummary.nw.processing}</u>
                                                </Link>
                                            </div>
                                            <div className="p-col-4">
                                                <Link className="text-success" to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_SOLVED_MODULE_WISE_TOKEN, appName: 'Netiworld', appDefCode: 'C11001002'}} >
                                                    <u>{tokenSummary.nw.solved}</u>
                                                </Link>
                                            </div>

                                            <div className="p-col-4 bg-warning">Pending</div>
                                            <div className="p-col-4 bg-primary">Processing</div>
                                            <div className="p-col-4 bg-success">Solved</div>

                                        </div>
                                    </Card>
                                </div>
                            </div>

                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        )
    }

}