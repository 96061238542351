
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { Dropdown } from 'primereact/dropdown';

// custom imports
import { DwsService } from '../../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { NetiCMSSetupProgress } from '../../common/NetiCMSSetupProgress';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import staticImg from '../../../../assets/images/avatar.png';
import Workbook from "react-excel-workbook";
import XLSX from 'xlsx';

let cmsId = 0;
let searching = false;

export class ApplicantStatus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,

            classAndGroupList: [],
            classConfigId: '',
            applicantStatus: '',

            dataTableValue: [],
            totalCountObj: {}
        }

        this.emptyHandleError = this.emptyHandleError.bind(this);

        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });
            this.getFetchClassAndGroupList(urlId)
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    getFetchClassAndGroupList(urlId) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.DwsService.getAdmisiaClassConfigurationList(urlId)
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        // console.log('body-cgList', body);
                        this.setState({ classAndGroupList: body.item });
                        this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                    });
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })

            });
    }

    onSearchHandler = () => {

        if (this.emptyHandleError()) {
            searching = true;
            this.getApplicantReportByStatusWise();
        }

    }

    getApplicantReportByStatusWise() {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.getAdmisiaApplicantReportsByStatusWise({ cmsId: cmsId, classConfigId: this.state.classConfigId, applicantStatus: this.state.applicantStatus })
            .then(res => {

                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('dataTable-body', body);

                        if (body.messageType == 1) {
                            this.setState({ dataTableValue: body.item });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        } else {
                            this.setState({ dataTableValue: [] });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    }))
                }

            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    onChangeClassAndGroupList = (e) => {
        this.setState({ classConfigId: e.target.value });
        this.clearErrorMsg('classAndgroup');
    }

    onChangeApplicantStatusOptions = (e) => {
        this.setState({ applicantStatus: parseInt(e.target.value) });
        this.clearErrorMsg('status');
        searching = false;
    }

    emptyHandleError() {

        let { classConfigId, applicantStatus, errors } = this.state;
        let formIsValid = true;

        if (classConfigId === '' || classConfigId === null) {
            formIsValid = false;
            errors["classAndgroup"] = "Class & Group can't left empty.";
        }

        if (applicantStatus === '' || applicantStatus === null) {
            formIsValid = false;
            errors["status"] = "Status can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;

    }

    clearErrorMsg = (name) => {
        let { errors } = this.state;
        errors[name] = '';
        this.setState({ errors })
    }

    bodyActionTemplate = (rowData) => {
        return (
            <div className="text-center">
                <Button
                    className="nw-action-button info"
                    icon="fas fa-eye"
                    tooltip="View Details Info"
                    onClick={(e) => this.viewDialog(rowData)}
                />
            </div>
        );
    }

    viewDialog = (rowData) => {
        this.setState({ viewDialogVisible: true, selectedViewRowData: rowData });
    }

    onHideViewDialog = () => { this.setState({ viewDialogVisible: false, selectedViewRowData: '' }); }

    dateFormatBody = (rowData) => {
        return NetiDateUtils.getDDhypMMhypYY(rowData.applicationDate);
    }

    dateFormatBody2 = (rowData) => {
        return NetiDateUtils.getDDhypMMhypYY(rowData.statusUpdateDate);
    }

    render() {

        let { classAndGroupList, applicantStatus, dataTableValue, selectedViewRowData, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let classConfigOptions = [];
        if (classAndGroupList && classAndGroupList.length) {
            classConfigOptions = classAndGroupList.map(item => ({ label: item.class + " (" + item.group + ")", value: item.classConfigId }))
        }

        let statusOptions = [];
        statusOptions = [
            { label: 'PENDING_FOR_ASSESSMENT', value: 0 },
            { label: 'REJECTED_FOR_ASSESSMENT', value: 2 },

            { label: 'PENDING_FOR_ADMISSION', value: 1 },
            { label: 'REJECTED_FOR_ADMISSION', value: 3 },
            { label: 'WAITING_FOR_ADMISSION', value: 4 },
            { label: 'APPROVED_FOR_ADMISSION', value: 5 },
            { label: 'TRANSFERRED_FOR_ADMISSION', value: 10 },
        ];

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Applicant Status Wise Applicant  List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>;

        let updateStatusHeaderNm = '';
        let selectedStatusNm = '';

        if (dataTableValue && dataTableValue.length) {

            statusOptions.forEach(element => {
                if (applicantStatus == element.value) {
                    let desireValue = element.label.split("_");
                    selectedStatusNm = desireValue[0]
                    updateStatusHeaderNm = <span style={{ textTransform: 'capitalize' }}>{desireValue[0].toLowerCase() + ' Date'}</span>
                }
            });

        }

        let dialogHeader = `View ${selectedStatusNm} Applicant Details`

        let tableColumns = [
            <Column field="registrationId" header="Registration No." filter={true} />,
            <Column field="applicantName" header="Name" filter={true} />,
            <Column field="gender" header="Gender" filter={true} />,
            <Column field="mobileNo" header="Contact No." filter={true} />,
            <Column field="applicationDate" header="Application Date" body={this.dateFormatBody} filter={true} />,
        ]

        if (searching === true && (applicantStatus == 0 || applicantStatus == 1)) {
            tableColumns.push(<Column field="action" header="Action" body={this.bodyActionTemplate} />);
        }
        else if (searching === true && (applicantStatus === 2 || applicantStatus === 3 || applicantStatus === 4 || applicantStatus === 5 || applicantStatus === 10)) {
            tableColumns.push(
                <Column field="statusUpdateDate" header={updateStatusHeaderNm} body={this.dateFormatBody2} filter={true} />,
                <Column field="action" header="Action" body={this.bodyActionTemplate} />);
        } else {
            tableColumns.push(<Column field="action" header="Action" body={this.bodyActionTemplate} />);
        }

        return (

            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                {this.state.homeReturnButton === false ?

                    <div className="main-section blur-section">

                        <div className="p-grid nw-form">

                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="p-grid nw-form-body">
                                    <div className="p-col-12 p-lg-12 p-xl-5">
                                        {this.state.firstDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-small-span">Class & Group <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Class & Group"
                                                    filter={true}
                                                    filterBy="value"
                                                    value={this.state.classConfigId}
                                                    options={classConfigOptions}
                                                    onChange={this.onChangeClassAndGroupList}
                                                    showClear={false}
                                                    autoWidth={false}
                                                    name="classAndgroup"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{errors["classAndgroup"]}</span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-5">
                                        {this.state.secondDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-small-span">Status <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Status"
                                                    filter={true}
                                                    options={statusOptions}
                                                    value={this.state.applicantStatus}
                                                    onChange={this.onChangeApplicantStatusOptions}
                                                    showClear={false}
                                                    autoWidth={false}
                                                    name='status'
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{errors["status"]}</span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-center"
                                            label="Search"
                                            icon="fas fa-search"
                                            onClick={this.onSearchHandler}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    {tableColumns}
                                </DataTable>

                                : this.NetiContentLoader.MyPointTableLoader()
                            }

                        </div>

                        <Workbook filename={this.state.applicantStatus==0 ? "PENDING_FOR_ASSESSMENT.xlsx" : this.state.applicantStatus==1 ? "PENDING_FOR_ADMISSION.xlsx" : this.state.applicantStatus==2 ? "REJECTED_FOR_ASSESSMENT.xlsx" : this.state.applicantStatus==3 ? "REJECTED_FOR_ADMISSION.xlsx" : this.state.applicantStatus==4 ? "WAITING_FOR_ADMISSION.xlsx" : this.state.applicantStatus==5 ? "APPROVED_FOR_ADMISSION.xlsx" : "TRANSFERRED_FOR_ADMISSION.xlsx"}
                                        element=
                                        {
                                            <div className="nw-button-parent">
                                                <Button
                                                    label="Download Excel"
                                                    icon="fas fa-download"
                                                    className="p-button p-button-primary nw-button nw-button-multiple"

                                                />
                                            </div>

                                        }
                                    >
                                        <Workbook.Sheet data={this.state.dataTableValue} name={this.state.applicantStatus==0 ? "PENDING_FOR_ASSESSMENT" : this.state.applicantStatus==1 ? "PENDING_FOR_ADMISSION" : this.state.applicantStatus==2 ? "REJECTED_FOR_ASSESSMENT" : this.state.applicantStatus==3 ? "REJECTED_FOR_ADMISSION" : this.state.applicantStatus==4 ? "WAITING_FOR_ADMISSION" : this.state.applicantStatus==5 ? "APPROVED_FOR_ADMISSION" : "TRANSFERRED_FOR_ADMISSION"}>
                                            <Workbook.Column value={row => row.registrationId} label="Registration No" />
                                            <Workbook.Column value={row => row.applicantName} label="Applicant Name" />
                                            <Workbook.Column value={row => row.gender} label="Gender" />
                                            <Workbook.Column value={row => row.religion} label="Religion" />
                                            <Workbook.Column value={row => row.mobileNo} label="Contact No" />
                                            <Workbook.Column value={row => row.dob} label="Date of Birth" />
                                            <Workbook.Column value={row => row.birthCertificateNo} label="Birth Certificate No" />
                                            <Workbook.Column value={row => row.applicationDate} label="Application Date" />
                                            <Workbook.Column value={row => row.academicYear} label="Academic Year" />                                            
                                            <Workbook.Column value={row => row.fatherName} label="Father Name" />
                                            <Workbook.Column value={row => row.fatherOccupation} label="Father Occupation" />
                                            <Workbook.Column value={row => row.fatherNidNo} label="Father Nid No" />
                                            <Workbook.Column value={row => row.motherName} label="Mother Name" />
                                            <Workbook.Column value={row => row.motherOccupation} label="Mother Occupation" />                                            
                                            <Workbook.Column value={row => row.motherNidNo} label="Mother Nid No" />                                       
                                            <Workbook.Column value={row => row.addressDetails} label="Address Details" />
                                            <Workbook.Column value={row => row.examMarks} label="Exam Marks" />
                                            <Workbook.Column value={row => row.rollNo} label="Roll No" />
                                            <Workbook.Column value={row => row.examResult} label="Exam Result" />
                                            <Workbook.Column value={row => row.districtName} label="District Name" />
                                            <Workbook.Column value={row => row.divisionName} label="Division Name" />
                                            <Workbook.Column value={row => row.quota} label="quota" />
                                           
                                        </Workbook.Sheet>
                                    </Workbook>


                        {this.state.viewDialogVisible ?
                            <Dialog
                                className="nw-dialog"
                                header={dialogHeader}
                                visible={this.state.viewDialogVisible}
                                onHide={this.onHideViewDialog}
                                closable
                            >

                                <div className="p-fluid">
                                    <div className="p-grid nw-form">
                                        <Growl ref={(el) => this.growl = el} />
                                        <div className="p-col-12 p-xl-7 p-col-nogutter seprator">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="nw-form-body">
                                                    <div className="p-grid seprator-inside">

                                                        <div className="p-col-12 p-lg-12 p-xl-12 product-info-dialog p-t-0">

                                                            <div class="nw-search-view no-border">
                                                                <div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon">Student Image</span>
                                                                            <span className="p-inputgroup-colon">:</span>

                                                                            <div style={{ paddingLeft: '22px' }}>
                                                                                {
                                                                                    selectedViewRowData.fileName && selectedViewRowData.fileContent ?
                                                                                        <img src={("data:image/*;base64," + selectedViewRowData.fileContent)} height='80px' width='80px' /> :
                                                                                        <img src={staticImg} height='80px' width='80px' />
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon" >Student Name</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">{selectedViewRowData.applicantName}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon"> Gender </span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">{selectedViewRowData.gender}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon" >Religion</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">{selectedViewRowData.religion}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon" >Date of Birth</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc"> {selectedViewRowData.dob} </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon" >Father's Name</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">{selectedViewRowData.fatherName}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon" >Father's Occupation</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">{selectedViewRowData.fatherOccupation}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon" >Mother's Name</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">{selectedViewRowData.motherName}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon" >Mother's Occupation</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">{selectedViewRowData.motherOccupation}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon" >Guardian Mobile No. </span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">{selectedViewRowData.mobileNo}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon" >Address</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">{selectedViewRowData.addressDetails} </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-5">

                                            <div className="p-grid inside-dialog-right-wrapper">

                                                <div className="p-col-12 p-lg-12 p-xl-12">
                                                    <div className="data-wrapper-box">

                                                        <div className="nw-datatable-dataview-small-panel productInfo">

                                                            <div>
                                                                <span className="first-box-level">Registration</span>
                                                                <span>:</span>
                                                                <span className="m-l-10" > {selectedViewRowData.registrationId}</span>
                                                            </div>

                                                            <div>
                                                                <span className="first-box-level">Applicant Status</span>
                                                                <span>:</span>
                                                                <div className="badge m-l-10" >
                                                                    <div className="first-box-level color-white"> {selectedStatusNm}
                                                                        {/* <span className="task-badge pending"> </span>  */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="p-col-12 p-lg-12 p-xl-12">
                                                    <div className="data-wrapper-box">

                                                        <div className="nw-datatable-dataview-small-panel productInfo">

                                                            <div>
                                                                <span className="second-box-level">Class</span>
                                                                <span>:</span>
                                                                <span className="m-l-10" > {selectedViewRowData.clasName}</span>
                                                            </div>

                                                            <div>
                                                                <span className="second-box-level">Group</span>
                                                                <span>:</span>
                                                                <span className="m-l-10"> {selectedViewRowData.groupName}</span>
                                                            </div>

                                                            <div>
                                                                <span className="second-box-level">Application Date</span>
                                                                <span>:</span>
                                                                <span className="m-l-10"> {NetiDateUtils.getDDhypMMhypYY(selectedViewRowData.applicationDate)}</span>
                                                            </div>

                                                            <div>
                                                                <span className="second-box-level">Application End Date</span>
                                                                <span>:</span>
                                                                <span className="m-l-10" > {NetiDateUtils.getDDhypMMhypYY(selectedViewRowData.applicationEndDate)}</span>
                                                            </div>

                                                            {selectedStatusNm.toLowerCase() !== 'pending' ?
                                                                <div>
                                                                    <span className="second-box-level">{updateStatusHeaderNm}</span>
                                                                    <span>:</span>
                                                                    <span className="m-l-10" > {NetiDateUtils.getDDhypMMhypYY(selectedViewRowData.statusUpdateDate)}</span>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </Dialog>
                            : ''}

                    </div>

                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>



                    
                }
            </div>
        )
    }
}