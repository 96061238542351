import React, { Component } from 'react';

import { Chart } from "react-google-charts";
import { Calendar } from 'primereact/calendar';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { primaryChartsColorArray, dangerChartsColorArray } from '../../common/ChartsColorArray';

let maxDate = new Date();
let visibleChart = false;
export class EmTotalActionLogSummaryChart extends Component {

    constructor(props) {
        super(props);

        this.state = {

            requestedObj: {
                startDate: '',
                endDate: '',
            },
            errors: {},
            dataTableValue: [],
            typeWiseActiveInstitute: [
                ["Search", '37%'],
                ["Insert", '13%'],
                ["Update", '33%'],
                ["Delete", '10%'],
                ["Download", '7%'],
            ],
            paymentTypeWiseInActiveTotal: 0,
            typeWiseInActiveInstitute: [
                ['title', 'count'],
                ['Student', 16],
                ['HR Management', 10],
                ['Student Attendance', 10],
                ['HR Attendance', 7],
                ['Semester Exam', 6],
                ['Class Test', 6],
                ['Routine', 5],
                ['Student Accounts', 5],
                ['General Accounts', 11],
                ['Layout', 4],
                ['Messaging', 10],
                ['Core Settings', 10],
            ]
        }

        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.onChangeEndDate = this.onChangeEndDate.bind(this);
        this.clearEmptyErrorMsg = this.clearEmptyErrorMsg.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);

    }

    onChangeStartDate(e) {

        let { requestedObj } = this.state;
        requestedObj[e.target.name] = e.target.value;
        this.setState({ requestedObj });
        this.clearEmptyErrorMsg(e.target.name);

    }

    onChangeEndDate(e) {

        let { requestedObj } = this.state;
        requestedObj[e.target.name] = e.target.value;
        this.setState({ requestedObj });
        this.clearEmptyErrorMsg(e.target.name);

    }

    searchHandleError = () => {

        let { errors, requestedObj } = this.state;
        let formIsValid = true;
        // this.setState({ topProgressBar: false });

        if (requestedObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start Date can't left empty.";
        }
        if (requestedObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    clearEmptyErrorMsg(name) {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onSubmitHandler() {

        let { requestedObj } = this.state;

        if (this.searchHandleError()) {

            visibleChart = true;
            console.log('requestedObj', requestedObj);

        } else { visibleChart = false }
    }

    render() {

        let { requestedObj } = this.state;

        return (

            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {/* {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                } */}
                <div className="main-section netiWorld-DashBoard-wrapper">

                    <div className="nw-form">

                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            name='startDate'
                                            value={requestedObj.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>
                                </div>

                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date<span>*</span></span>
                                        <Calendar
                                            name='endDate'
                                            value={requestedObj.endDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-2 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>

                            </div>
                        </div>

                    </div>

                    {visibleChart ?
                        <div className="netiWorld-DashBoard">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 p-md-12 p-sm-12 p-t-0  m-t-20">

                                    <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                        {/* <div className="panel-box p-l-0">
                                        <div className="panel-box-header">
                                            <h1>Type Wise <span className="active">Active</span> Eduman</h1>
                                        </div>
                                    </div> */}
                                        <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">

                                                        <Chart
                                                            width={'205px'}
                                                            height={'205px'}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={[
                                                                ['title', 'count'],
                                                                ['Search', 37],
                                                                ['Insert', 13],
                                                                ['Update', 33],
                                                                ['Delete', 10],
                                                                ['Download', 7],
                                                            ]}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                tooltip: { trigger: 'block', showColorCode: true },
                                                                // pieSliceText: 'none',
                                                                legend: 'none',
                                                                slices: primaryChartsColorArray,
                                                            }}
                                                            rootProps={{ 'data-testid': '1' }}
                                                        />

                                                    </div>
                                                    <span className="chart-box-title">Action Type Percentage</span>
                                                    {/* <br /><span className="chart-box-title">{this.state.paymentTypeWiseActiveTotal ? this.state.paymentTypeWiseActiveTotal : ''}</span> */}
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                <tbody>
                                                                    {this.state.typeWiseActiveInstitute.map((item, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                                <td><p>: {item[1]}</p></td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                        {/* <div className="panel-box p-l-0">
                                        <div className="panel-box-header">
                                            <h1>Type Wise <span className="inActive">Inactive</span> Eduman</h1>
                                        </div>
                                    </div> */}
                                        <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover">
                                                    <div className="chart-box">
                                                        <Chart
                                                            width={'205px'}
                                                            height={'205px'}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={
                                                                [
                                                                    ['title', 'count'],
                                                                    ['Student', 16],
                                                                    ['HR Management', 10],
                                                                    ['Student Attendance', 10],
                                                                    ['HR Attendance', 7],
                                                                    ['Semester Exam', 6],
                                                                    ['Class Test', 6],
                                                                    ['Routine', 5],
                                                                    ['Student Accounts', 5],
                                                                    ['General Accounts', 11],
                                                                    ['Layout', 4],
                                                                    ['Messaging', 10],
                                                                    ['Core Settings', 10],
                                                                ]}
                                                            legend={{
                                                                position: "bottom",
                                                                alignment: "center",
                                                                // position: 'labeled',
                                                                textStyle: { color: "233238", fontSize: 16 }
                                                            }}
                                                            options={{
                                                                chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                backgroundColor: 'transparent',
                                                                tooltip: { trigger: 'block', showColorCode: true },
                                                                // pieSliceText: 'none',
                                                                legend: 'none',
                                                                slices: primaryChartsColorArray,
                                                            }}
                                                        />
                                                    </div>
                                                    <span className="chart-box-title">Module Action Percentage</span>

                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-6 p-md-6">
                                                <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                    <div className="chart-box-wrapper">
                                                        <div className="point-list">
                                                            <table>
                                                                <tbody>
                                                                    {this.state.typeWiseInActiveInstitute.map((item, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                                <td><p>: {item[1]}</p></td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        : ''}

                </div>

            </div>
        )
    }
}