import React, { Component } from 'react';
import { Button } from 'primereact/button';
import mostofaJobber from '../../../assets/images/mostofa-jobber.png';
import { Link } from 'react-router-dom';

let profile;
export class BijoyContentDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBackBtn: false
        }
    }
    componentDidMount() {
        profile = JSON.parse(localStorage.getItem('Profile'));
        if(!profile) {
            this.setState({showBackBtn: true});
        }
    }

    getDownloadContent(linkAd) {
        setTimeout(() => {
            const response = {
                file: linkAd
            };
            window.location.href = response.file;
        }, 100);
    }

    onClickHandler = () => {
        window.location.href="/";
    }

    render() {
       let domainName = 'https://bijoy-content.s3-ap-northeast-1.amazonaws.com/';

       let shishuShikkha =          domainName + 'Desktop_Bijoy_Shishu_Shikkha.exe';
       let shishuShikkhaAndroid =   domainName + 'Android_Bijoy_Shishu_Shikkha.apk';

       let shishuShikhkhaOne =        domainName + 'Desktop_Bijoy_Shishu_Shikkha_1.exe';
       let shishuShikhkhaOneAndroid = domainName + 'Android_Bijoy_Shishu_Shikkha_1.apk';

       let shishuShikhkhaTwo =        domainName + 'Desktop_Bijoy_Shishu_Shikkha_2.exe';
       let shishuShikhkhaTwoAndroid = domainName + 'Android_Bijoy_Shishu_Shikkha_2.apk';

       let prakPrathomik =          domainName + 'Desktop_Bijoy_Prak+Prathomik_Shikkha.exe';
       let prakPrathomikAndroid =   domainName + 'Android_Bijoy_Prak+Prathomik_Shikkha.apk';

       let classOne =               domainName + 'Desktop_Bijoy_Prathomik_Shikkha_1.exe';
       let classOneAndroid =        domainName + 'Android_Bijoy_Prathomik_Shikkha_1.apk';

       let classTwo =               domainName + 'Desktop_Bijoy_Prathomik_Shikkha_2.exe';
       let classTwoAndroid =        domainName + 'Android_Bijoy_Prathomik_Shikkha_2.apk';

       let classThree =             domainName + 'Desktop_Bijoy_Prathomik_Shikkha_3.exe';
       let classThreeAndroid =      domainName + 'Android_Bijoy_Prathomik_Shikkha_3.apk';

       let classFour =              domainName + 'Desktop_Bijoy_Prathomik_Shikkha_4.exe';
       let classFourAndroid =       domainName + 'Android_Bijoy_Prathomik_Shikkha_4.apk';


        return (
            <div className="layout-dashboard">


                <div className="p-fluid">
                    <div className="main-section bijoy">

                        <div className="p-grid p-col-12 download-book">

                            <div className="p-col-12 p-xl-12 p-col-nogutter download-book-header">
                                <div className="header-title">শিক্ষার ডিজিটাল রুপান্তর
                                  {this.state.showBackBtn ?
                                    <div className="header-title-right">
                                        <Button
                                            className="p-button-primary nw-button nw-button-right"
                                            label="Back"
                                            icon="fas fa-chevron-left"
                                            onClick={this.onClickHandler}
                                        />
                                    </div>
                                    : '' }
                                </div>;
                           
                                <div className="header-image">
                                    <img src={mostofaJobber} />
                                </div>
                                <div className="text">স্বত্ত্বাধিকারী: মোস্তাফা জব্বার</div>
                            </div>

                            <div className="p-col-12 p-xl-4 p-lg-6 p-md-6">
                                <div className="book-main-cover">
                                    <div className="book-img shishu0"></div>
                                    <div className="download-button">
                                        <Button label={"Download Desktop Version Size: 156 MB"} icon="fas fa-desktop" className="desktop-button" onClick={this.getDownloadContent.bind(this, shishuShikkha)}/>
                                        <Button label={"Download Android Version Size: 110 MB"} icon="fab fa-android" className="android-button" onClick={this.getDownloadContent.bind(this, shishuShikkhaAndroid)}/>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-4 p-lg-6 p-md-6">
                                <div className="book-main-cover">
                                    <div className="book-img shishu1"></div>
                                    <div className="download-button">
                                        <Button label={"Download Desktop Version Size: 211 MB"} icon="fas fa-desktop" className="desktop-button" onClick={this.getDownloadContent.bind(this, shishuShikhkhaOne)}/>
                                        <Button label={"Download Android Version Size: 164 MB"} icon="fab fa-android" className="android-button" onClick={this.getDownloadContent.bind(this, shishuShikhkhaOneAndroid)}/>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-lg-4 p-md-4">
                                <div className="book-main-cover">
                                    <div className="book-img shishu2"></div>
                                    <div className="download-button">
                                        <Button label={"Download Desktop Version Size: 261 MB"} icon="fas fa-desktop" className="desktop-button" onClick={this.getDownloadContent.bind(this, shishuShikhkhaTwo)}/>
                                        <Button label={"Download Android Version Size: 223 MB"} icon="fab fa-android" className="android-button" onClick={this.getDownloadContent.bind(this, shishuShikhkhaTwoAndroid)}/>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-lg-4 p-md-4">
                                <div className="book-main-cover">
                                    <div className="book-img class0-prak"></div>
                                    <div className="download-button">
                                        <Button label={"Download Desktop Version Size: 134 MB"} icon="fas fa-desktop" className="desktop-button" onClick={this.getDownloadContent.bind(this, prakPrathomik)}/>
                                        <Button label={"Download Android Version Size: 195 MB"} icon="fab fa-android" className="android-button" onClick={this.getDownloadContent.bind(this, prakPrathomikAndroid)}/>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-lg-4 p-md-4">
                                <div className="book-main-cover">
                                    <div className="book-img class1"></div>
                                    <div className="download-button">
                                        <Button label={"Download Desktop Version Size: 231 MB"} icon="fas fa-desktop" className="desktop-button" onClick={this.getDownloadContent.bind(this, classOne)}/>
                                        <Button label={"Download Android Version Size: 199 MB"} icon="fab fa-android" className="android-button" onClick={this.getDownloadContent.bind(this, classOneAndroid)}/>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-lg-4 p-md-4">
                                <div className="book-main-cover">
                                    <div className="book-img class2"></div>
                                    <div className="download-button">
                                        <Button label={"Download Desktop Version Size: 304 MB"} icon="fas fa-desktop" className="desktop-button" onClick={this.getDownloadContent.bind(this, classTwo)}/>
                                        <Button label={"Download Android Version Size: 270 MB"} icon="fab fa-android" className="android-button" onClick={this.getDownloadContent.bind(this, classTwoAndroid)}/>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-lg-4 p-md-4">
                                <div className="book-main-cover">
                                    <div className="book-img class3"></div>
                                    <div className="download-button">
                                        <Button label={"Download Desktop Version Size: 912 MB"} icon="fas fa-desktop" className="desktop-button" onClick={this.getDownloadContent.bind(this, classThree)}/>
                                        <Button label={"Download Android Version Size: 893 MB"} icon="fab fa-android" className="android-button" onClick={this.getDownloadContent.bind(this, classThreeAndroid)}/>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-lg-4 p-md-4">
                                <div className="book-main-cover">
                                    <div className="book-img class4"></div>
                                    <div className="download-button">
                                        <Button label={"Download Desktop Version Size: 1.1 GB"} icon="fas fa-desktop" className="desktop-button" onClick={this.getDownloadContent.bind(this, classFour)}/>
                                        <Button label={"Download Android Version Size: 1 GB"} icon="fab fa-android" className="android-button" onClick={this.getDownloadContent.bind(this, classFourAndroid)}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                {/* <div className="p-grid">

                    <div className="p-col-12 p-lg-3 p-md-3">
                        <div id="book" className="view-cover">
                            <div className="main">
                                <div className="book-front">
                                    <div className="book-cover shishu0">
                                        <h1 className="title">Shishu Shikhkha</h1>
                                        <div className="publisher">
                                            <Button label="Desktop (152MB)" icon="fa fa-desktop" className="book-button" onClick={this.getDownloadContent.bind(this, shishuShikkha)}/>
                                            <Button label="Android (150MB)" icon="fa fa-android" className="book-button" onClick={this.getDownloadContent.bind(this, shishuShikkhaAndroid)}/>
                                        </div>
                                    </div>
                                    <div className="book-cover-back"></div>
                                </div>
                                <div className="book-back"></div>
                                <div className="book-bone"></div>
                                <div className="book-top"></div>
                                <div className="book-right"></div>
                                <div className="book-bottom"></div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-3">
                        <div id="book" class="view-cover">
                            <div className="main">
                                <div className="book-front">
                                    <div className="book-cover shishu1">
                                        <h1 className="title">Shishu Shikhkha-1</h1>
                                        <div className="publisher">
                                            <Button label="Desktop (211MB)" icon="fa fa-desktop" className="book-button" onClick={this.getDownloadContent.bind(this, shishuShikhkhaOne)}/>
                                            <Button label="Android (164MB)" icon="fa fa-android" className="book-button" onClick={this.getDownloadContent.bind(this, shishuShikhkhaOneAndroid)}/>
                                        </div>
                                    </div>
                                    <div className="book-cover-back"></div>
                                </div>
                                <div className="book-back"></div>
                                <div className="book-bone"></div>
                                <div className="book-top"></div>
                                <div className="book-right"></div>
                                <div className="book-bottom"></div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-3">
                        <div id="book" class="view-cover">
                            <div className="main">
                                <div className="book-front">
                                    <div className="book-cover shishu2">
                                        <h1 className="title">Shishu Shikhkha-2</h1>
                                        <div className="publisher">
                                            <Button label="Desktop (260MB)" icon="fa fa-desktop" className="book-button" onClick={this.getDownloadContent.bind(this, shishuShikhkhaTwo)}/>
                                            <Button label="Android (223MB)" icon="fa fa-android" className="book-button" onClick={this.getDownloadContent.bind(this, shishuShikhkhaTwoAndroid)}/>
                                        </div>
                                    </div>
                                    <div className="book-cover-back"></div>
                                </div>
                                <div className="book-back"></div>
                                <div className="book-bone"></div>
                                <div className="book-top"></div>
                                <div className="book-right"></div>
                                <div className="book-bottom"></div>
                            </div>
                        </div>

                    </div>
                    <div className="p-col-12 p-lg-3 p-md-3">
                        <div id="book" class="view-cover">
                            <div className="main">
                                <div className="book-front">
                                    <div className="book-cover class0-prak">
                                        <h1 className="title">Prak Shikhkha</h1>
                                        <div className="publisher">
                                            <Button label="Desktop (133MB)" icon="fa fa-desktop" className="book-button" onClick={this.getDownloadContent.bind(this, prakPrathomik)}/>
                                            <Button label="Android (195MB)" icon="fa fa-android" className="book-button" onClick={this.getDownloadContent.bind(this, prakPrathomikAndroid)}/>
                                        </div>
                                    </div>
                                    <div className="book-cover-back"></div>
                                </div>
                                <div className="book-back"></div>
                                <div className="book-bone"></div>
                                <div className="book-top"></div>
                                <div className="book-right"></div>
                                <div className="book-bottom"></div>
                            </div>
                        </div>

                    </div>
                    <div className="p-col-12 p-lg-3 p-md-3">
                        <div id="book" class="view-cover">
                            <div className="main">
                                <div className="book-front">
                                    <div className="book-cover class1">
                                        <h1 className="title">Class One</h1>
                                        <div className="publisher">
                                            <Button label="Desktop (230MB)" icon="fa fa-desktop" className="book-button" onClick={this.getDownloadContent.bind(this, classOne)}/>
                                            <Button label="Android (195MB)" icon="fa fa-android" className="book-button" onClick={this.getDownloadContent.bind(this, classOneAndroid)}/>
                                        </div>
                                    </div>
                                    <div className="book-cover-back"></div>
                                </div>
                                <div className="book-back"></div>
                                <div className="book-bone"></div>
                                <div className="book-top"></div>
                                <div className="book-right"></div>
                                <div className="book-bottom"></div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-3">
                        <div id="book" class="view-cover">
                            <div className="main">
                                <div className="book-front">
                                    <div className="book-cover class2">
                                        <h1 className="title">Class Two</h1>
                                        <div className="publisher">
                                            <Button label="Desktop (304MB)" icon="fa fa-desktop" className="book-button" onClick={this.getDownloadContent.bind(this, classTwo)}/>
                                            <Button label="Android (275MB)" icon="fa fa-android" className="book-button" onClick={this.getDownloadContent.bind(this, classTwoAndroid)}/>
                                        </div>
                                    </div>
                                    <div className="book-cover-back"></div>
                                </div>
                                <div className="book-back"></div>
                                <div className="book-bone"></div>
                                <div className="book-top"></div>
                                <div className="book-right"></div>
                                <div className="book-bottom"></div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3 p-md-3">
                        <div id="book" class="view-cover">
                            <div className="main">
                                <div className="book-front">
                                    <div className="book-cover class3">
                                        <h1 className="title">Class Three</h1>
                                        <div className="publisher">
                                            <Button label="Desktop (898MB)" icon="fa fa-desktop" className="book-button" onClick={this.getDownloadContent.bind(this, classThree)}/>
                                            <Button label="Android (875MB)" icon="fa fa-android" className="book-button" onClick={this.getDownloadContent.bind(this, classThreeAndroid)}/>
                                        </div>
                                    </div>
                                    <div className="book-cover-back"></div>
                                </div>
                                <div className="book-back"></div>
                                <div className="book-bone"></div>
                                <div className="book-top"></div>
                                <div className="book-right"></div>
                                <div className="book-bottom"></div>
                            </div>
                        </div>

                    </div>
                    <div className="p-col-12 p-lg-3 p-md-3">
                        <div id="book" class="view-cover">
                            <div className="main">
                                <div className="book-front">
                                    <div className="book-cover class4">
                                        <h1 className="title">Class Four</h1>
                                        <div className="publisher">
                                            <Button label="Desktop (1.1GB)" icon="fa fa-desktop" className="book-button" onClick={this.getDownloadContent.bind(this, classFour)}/>
                                            <Button label="Android (1.1GB)" icon="fa fa-android" className="book-button" onClick={this.getDownloadContent.bind(this, classFourAndroid)}/>
                                        </div>
                                    </div>
                                    <div className="book-cover-back"></div>
                                </div>
                                <div className="book-back"></div>
                                <div className="book-bone"></div>
                                <div className="book-top"></div>
                                <div className="book-right"></div>
                                <div className="book-bottom"></div>
                            </div>
                        </div>

                    </div>
                
                </div> */}
            </div>
        );
    }
}