import React, { Component } from 'react';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { AdmisiaApplicantPrevExam } from './AdmisiaApplicantPrevExam';
import NetiContentLoader from '../common/NetiContentLoader';
import { AdmisiaPointService } from "../../service/admisiaPoint/AdmisiaPointService";

export class AdmisiaApplicantViewInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            applicantPicture: '',
            examInfoValue: []
            
        };

        this.netiContentLoader = new NetiContentLoader();
        this.netiFileHandler = new NetiFileHandler();
        this.admisiaPointService = new AdmisiaPointService();

    }

    componentWillMount () {
       
        this.getExamInfoList();
    }


    getExamInfoList = () => {
        
        this.admisiaPointService.getPreviousInfoByID(this.props.applicantInfo.admisiaApplicantInfoDTO.admisiaApplicantInfoId)  
        .then(res => {
            this.setState({ topProgressBar: false });
            if (res.status == 302) {
                return res.json().then((body) => {
                    
                    console.log("ExamBody", body);
                    this.setState({ examInfoValue: body });  
                    
                });
            } else {
                this.admisiaPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false });

            console.log(error);
        });

    }


   

    render() {

        let {firstInputFieldIsLoading} = this.props;
        let applicantDetails = this.props.applicantInfo.admisiaApplicantInfoDTO;



        console.log("Exam", this.state.examInfoValue);
        
        
        
        let staticImg = 'assets/layout/images/userAvatar.png';

        return (

            <div className="p-col-12 p-xl-12">
                <div className="p-grid">

                    <div className="p-grid p-col-12 p-xl-9">
                        {/* <div className="p-grid"> */}
                        <div className="p-col-12 p-xl-12">
                            <h1 className="text-center">
                                <u>Application Information </u>
                            </h1>
                        </div>
                        <div className="p-col-12 p-xl-6">
                            
                        </div>
                        {/* <div className="p-col-12 p-xl-6" style={{'text-align': 'right','font-weight':'bold'}}>
                            {this.props.eligibilityStatus}
                        </div> */}
                        {/* </div> */}
                    </div>
                    <div className="p-col-12 p-xl-3" style={{'width': '120px','height': '120px','background': 'aliceblue'}}>
                        <img src={this.props.photoInfo || staticImg } width="120px" />
                    </div>

                </div>

                <br/>
                <br/>

                <div className="p-grid p-col-12 p-xl-12 nw-search-view">
                    <div className="p-col-12 p-xl-6">

                                <div>
                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Registration No.</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantDetails && applicantDetails.registrationID ? applicantDetails.registrationID : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>
                          

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Class</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails && applicantDetails.admisiaClassConfigDTO.classCoreCategoryInfoDTO ? applicantDetails.admisiaClassConfigDTO.classCoreCategoryInfoDTO.categoryName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

       

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Group</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails && applicantDetails.admisiaClassConfigDTO.groupCoreCategoryInfoDTO ? applicantDetails.admisiaClassConfigDTO.groupCoreCategoryInfoDTO.categoryName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Application Date</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails.applicationDate ? NetiDateUtils.getDateFromString(applicantDetails.applicationDate) : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>


                                </div>

                    </div>
                    <div className="p-col-12 p-xl-6">

                            <div className="p-col-12 p-xl-12">
                                {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Assessment</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                        {applicantDetails.admisiaClassConfigDTO.admissionTestStatus == 0 ? "No" : "Yes"}
                                        </div>
                                    </div>
                                }
                            </div>

                     

                            <div className="p-col-12 p-xl-12">
                                {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Approved Date</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                        {""}                                       
                                         </div>
                                    </div>
                                }
                            </div>

                        {/* </div> */}


                    </div>

                </div>
                <br/>
                <br/>
                <div className="p-grid p-col-12 p-xl-12 nw-search-view" >
                    <div className="p-col-12 p-xl-6">
                        {/* <div className=""> */}

                            <div className="p-col-12 p-xl-12">
                                {/* <div className="p-col-12 p-md-12 p-md-offset-10">
                                </div> */}
                               

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Student Name</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                   {applicantDetails.applicantName ? applicantDetails.applicantName : '-'} 
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Gender</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails.genderCoreCategoryInfoDTO ? applicantDetails.genderCoreCategoryInfoDTO.categoryName : '-'}
                                               </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Religion</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails.religionCoreCategoryInfoDTO && applicantDetails.religionCoreCategoryInfoDTO.categoryName ? applicantDetails.religionCoreCategoryInfoDTO.categoryName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Date Of Birth</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails.dateOfBirth ? NetiDateUtils.getDateFromString(applicantDetails.dateOfBirth) : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Guardian Contact No</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails.mobileNo ? applicantDetails.mobileNo : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Address</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails.addressCoreCategoryInfoDTO && applicantDetails.addressCoreCategoryInfoDTO.categoryName ? applicantDetails.addressCoreCategoryInfoDTO.categoryName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Father's Name</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails.fatherName ? applicantDetails.fatherName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Mother's Name</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                    {applicantDetails.motherName ? applicantDetails.motherName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Father's Occupation</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails.fatherOccupationCategoryInfoDTO ? applicantDetails.fatherOccupationCategoryInfoDTO.categoryName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Mother's Occupation</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">
                                                {applicantDetails.motherOccupationCategoryInfoDTO ? applicantDetails.motherOccupationCategoryInfoDTO.categoryName : '-'}
                                                </div>
                                            </div>
                                        }
                                    </div>

                           
                            </div>
                        {/* </div> */}
                    </div>

                    <div className="p-col-12 p-xl-6">
                        { this.props.applicantInfo.admisiaApplicantPreviousExamInfoResponseDTOList ? 
                        //    Object.keys(this.props.applicantInfo.admisiaApplicantPreviousExamInfoResponseDTOList).map(key => (
                        //     <AdmisiaApplicantPrevExam
                        //         key={key}
                        //         index={key}
                        //         applicantPrevExamInfo={this.props.applicantInfo.admisiaApplicantPreviousExamInfoResponseDTOList[key]}
                        //     />
                        //  ))

                        <AdmisiaApplicantPrevExam
                            applicantPrevExamInfo = {this.props.applicantInfo.admisiaApplicantInfoDTO}
                            applicantPrevDataList={this.props.examInfo || this.state.examInfoValue}
                        />
                         : null
                         }
                    </div>
                </div>
            </div>




        );

    }

}

