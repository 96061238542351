import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { Panel } from 'primereact/panel';
import { EmAuditPointUserList } from './EmAuditPointUserList';
import { ProfileService } from '../../service/profile/ProfileService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import NetiContentLoader from '../common/NetiContentLoader';
import { EmAdminPointService } from '../../service/emAdminPoint/EmAdminPointService';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';

let edumanInstituteId = '';
let edumanDetailsID;
export class EmAuditPointTagging extends Component {
    constructor() {
        super();
        this.state = {
            errors: {},
            checked: false,
            showModuleCheckBoxAll: false,
            errorMsgVisible: false,
            insitituteID: '',
            errorMsgBody: '',
            pointList: [],
            assignModuleObj: {
                // edumanDetailsInfoDTO: {
                //     emDetailsId: ''
                // },
                // moduleStudent: 0,
                // moduleHrManagement: 0,
                // moduleSemesterExam: 0,
                // moduleClassTest: 0,
                // moduleRoutine: 0,
                // moduleStudentAccounts: 0,
                // moduleStudentAttendance: 0,

                // moduleHrAttendance: 0,
                // modulePayroll: 0,
                // moduleInventory: 0,
                // moduleGeneralAccounts: 0,
                // moduleMessaging: 0,
            },
            userTaggingList: [
            ],
            instituteDropdownList: [],
            customNetiID: '',
            userBasicInfo: {
                netiID: '',
                basicMobile: ''
            },
            moduleEligibility: {},
            searchUserDataIsLoading: false,
            firstDropDownIsLoading: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            searchView: false,
            searchViewError: false,
            searchViewErrorMsg: '',
            userModuleList: [],
            selectAllModule: 0

        };

        this.profileService = new ProfileService();
        this.emAdminPointService = new EmAdminPointService();
        this.userOtherAccessService = new UserOtherAccessService();
        this.netiDateUtils = new NetiDateUtils();
        this.NetiContentLoader = new NetiContentLoader();

    }

    componentWillMount() {
        this.viewInstituteList();
    }

    onChangeModule = (e) => {
        let { assignModuleObj } = this.state;
        let selectValue = assignModuleObj[e.target.name] == 1 ? 0 : 1
        if (selectValue == 0) this.setState({ selectAllModule: 0 })
        assignModuleObj[e.target.name] = selectValue
        this.setState({ assignModuleObj })
    }

    onChangeAllModule = (e) => {
        let { assignModuleObj } = this.state
        let selectValue = this.state.selectAllModule == 0 ? 1 : 0
        this.setState({ selectAllModule: selectValue })
        assignModuleObj.moduleStudent = assignModuleObj.moduleHrManagement = assignModuleObj.moduleSemesterExam = assignModuleObj.moduleClassTest = assignModuleObj.moduleRoutine = assignModuleObj.moduleStudentAccounts = assignModuleObj.moduleStudentAttendance = assignModuleObj.moduleHrAttendance = assignModuleObj.modulePayroll = assignModuleObj.moduleInventory = assignModuleObj.moduleGeneralAccounts = assignModuleObj.moduleMessaging = selectValue
        this.setState({ assignModuleObj })
    }

    //don't delete this code, can use later

    // onCheckedStudentModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleStudent = assignModuleObj.moduleStudent == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });
    // }

    // onCheckedHrManagementModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleHrManagement = assignModuleObj.moduleHrManagement == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });
    // }

    // onCheckedSemesterExamModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleSemesterExam = assignModuleObj.moduleSemesterExam == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });
    // }

    // onCheckedClassTestModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleClassTest = assignModuleObj.moduleClassTest == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });
    // }

    // onCheckedStudentAttendanceModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleStudentAttendance = assignModuleObj.moduleStudentAttendance == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });
    // }

    // onCheckedRoutineModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleRoutine = assignModuleObj.moduleRoutine == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });
    // }

    // onCheckedStudentAccountsModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleStudentAccounts = assignModuleObj.moduleStudentAccounts == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });
    // }

    // onCheckedHrAttendanceModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleHrAttendance = assignModuleObj.moduleHrAttendance == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });
    // }

    // onCheckedPayrollModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.modulePayroll = assignModuleObj.modulePayroll == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });

    // }

    // onCheckedInventoryModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleInventory = assignModuleObj.moduleInventory == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });

    // }

    // onCheckedGeneralAccountsModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleGeneralAccounts = assignModuleObj.moduleGeneralAccounts == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });
    // }

    // onCheckedMessagingModule = (e) => {
    //     let { assignModuleObj } = this.state;
    //     assignModuleObj.moduleMessaging = assignModuleObj.moduleMessaging == 0 ? 1 : 0;
    //     this.setState({ assignModuleObj });
    // }

    onChangeCustomNetiID = (e) => {
        let { errors } = this.state;
        errors['netiID'] = '';
        this.setState({ errors })
        this.setState({ customNetiID: e.target.value });
    }

    onChangeInstitute = (e) => {
        let {assignModuleObj} = this.state;
        
        let instituteValue = e.target.value
        
        this.setState({ userTaggingList: [] });
        this.setState({ insitituteID: e.target.value });
        
        edumanDetailsID = instituteValue.emDetailsId;

        //Don't Remove this (It will be implemented leter)
        // for (let i = 0; i < this.state.instituteDropdownList.length; i++) {
        //     console.log("instituteDropdownList[i]", this.state.instituteDropdownList[i]);
        //     console.log("e.target.value", e.target.value);

        //     if (e.target.value === this.state.instituteDropdownList[i].emDetailsId) {
        //         edumanInstituteId = this.state.instituteDropdownList[i].instituteId
        //         console.log("edumanInstituteId", edumanInstituteId);
        //         this.getEdumanModuleList(edumanInstituteId);
        //     }
        // }

        if (instituteValue.instituteId) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.emAdminPointService.getInstitutePermissionList(instituteValue.instituteId)
                .then(res => {
                    // if (res.status == 302) {
                    return res.json().then(body => {
                        this.setState({moduleEligibility:body,
                            showModuleCheckBoxAll: true });
                        const entries = Object.entries(body)
                        let moduleObj = {
                            edumanDetailsInfoDTO: {
                                emDetailsId: ''
                             }
                        };
                        for (let [key, val] of entries) {
                            if(val === 9) {
                                moduleObj[key] = 0;
                            }
                        }
                        this.setState({assignModuleObj:moduleObj });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    });
                    // } else {
                    //     this.emAdminPointService.Auth.handleErrorStatus(res)
                    //         .then((resp) => {
                    //             console.log("userModuleList error body", resp);
                    //             this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                    //         });
                    // }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load datatable. Please check connection' });
                });
        }

        if (instituteValue.emDetailsId) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.emAdminPointService.getUserTaggingListByEmID(instituteValue.emDetailsId)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            this.setState({ userTaggingList: body });
                            this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        });
                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load datatable. Please check connection' });
                });
        }
    }

    onSearchCustomNetiID = () => {
        let { userBasicInfo } = this.state;
        userBasicInfo.netiID = userBasicInfo.basicMobile = null;
        this.setState({ userBasicInfo });

        if (this.onSearchCustomNetiIDErrorHandle()) {
            this.setState({ topProgressBar: true, searchView: true, searchViewError: false, searchUserDataIsLoading: true, errorMsgVisible: false });
            this.emAdminPointService.getUserByCustomIDAndRole(this.state.customNetiID, 'ROLE_SA')
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            console.log("custom id body",body);
                            this.setState({ userBasicInfo: body, topProgressBar: false, searchUserDataIsLoading: false, errorMsgVisible: false });
                        });
                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ searchViewError: true, searchViewErrorMsg: resp, topProgressBar: false, searchUserDataIsLoading: false })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, searchView: false, searchUserDataIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
        }
    }

    onSearchCustomNetiIDErrorHandle = () => {
        let { errors, customNetiID } = this.state;
        let formIsValid = true;
        this.setState({ searchView: false })

        if (!this.state.customNetiID) {
            formIsValid = false;
            errors['netiID'] = 'Neti ID can\'t left empty'
        }

        if( customNetiID.length < 10){
            formIsValid = false
            errors['netiID'] = 'Neti ID is too sort';
        }

        if (customNetiID == '' && customNetiID.length < 10) {
            formIsValid = false
            errors['netiID'] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10';
        }

        this.setState({ errors })

        return formIsValid;
    }

    viewInstituteList = () => {
        let { userBasicInfo } = this.state; 
        this.setState({ topProgressBar: true, firstDropDownIsLoading: true, errorMsgVisible: false });
        this.emAdminPointService.getInstituteByUser().then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    this.setState({ instituteDropdownList: body })
                    this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: false });
                })
            } else {
                this.emAdminPointService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                    });
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
        });
    }

    getEdumanModuleList = (value) => {
        this.setState({ topProgressBar: true, firstDropDownIsLoading: true, errorMsgVisible: false });
        this.userOtherAccessService.findEdumanModulesByInstituteId(value).then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    // this.setState({ edumanModuleList: body })
                    console.log("edumanModuleList", body)
                    this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: false });
                })
            } else {
                this.userOtherAccessService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        console.log("error body", resp);
                        this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                    });
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
        });
    }

    saveTagModuleByAdmin = (e) => {
        if (this.onSaveErrorHandle()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            let { assignModuleObj, userBasicInfo } = this.state;
            assignModuleObj.saUserBasicInfoDTO = userBasicInfo;
            assignModuleObj.edumanDetailsInfoDTO.emDetailsId = edumanDetailsID;
            this.setState({ assignModuleObj });
            this.emAdminPointService.saveModuleTaggingByAdmin(assignModuleObj)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status === 201) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Data Saved Successfully." });
                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resBody })

                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
        }
    }

    onSaveErrorHandle = () => {
        let formIsValid = true;
        let { errors } = this.state;

        if (!this.onSearchCustomNetiIDErrorHandle()) {
            formIsValid = false;
        }
        else if (this.onSearchCustomNetiIDErrorHandle() && !this.state.userBasicInfo.netiID) {
            formIsValid = false;
            console.error("User Basic Info Not Found");
            this.growl.show({ severity: 'error', summary: 'Error Message', detail: "User's Basic Info not found. Please search with a valid Neti-ID" });
        }

        if (!this.state.insitituteID) {
            formIsValid = false;
            errors['insititute'] = 'Insititute can\'t left empty'
        }

        this.setState({ errors })
        return formIsValid;
    }

    focusSearchNetiId = e => {
        this.setState({
            searchLabelNetiId: "Search",
            addSearchBtnClassNetiId: "nw-button p-button-primary text-color-white"
        });
    }

    blurSearchNetiId = e => {
        this.setState({
            searchLabelNetiId: "",
            addSearchBtnClassNetiId: ""
        });
    }

    render() {
     
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let { userBasicInfo } = this.state;
        let formatContactNo = (userBasicInfo.basicMobile ? userBasicInfo.basicMobile.replace(/-/g, '').replace(/\s/g, '').replace(/[+]/g, '') : '');
        let formatRegisterDate = (userBasicInfo.registrationDate ? NetiDateUtils.getDateFromString(userBasicInfo.registrationDate) : '');

        let instituteList = [];
        if ((this.state.instituteDropdownList != null) && (this.state.instituteDropdownList.length > 0)) {
            instituteList = this.state.instituteDropdownList.map((item) => ({
                value: item,
                label: item.instituteId + " - " + item.instituteName
            }));
        }

        let moduleHeader = <h3 className="text-center">Assign Module Permission</h3>

        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-3 p-md-3"></div>
                    <div className="p-col-12 p-xl-8 p-xl-offset-2">
                        <div className="card card-w-title">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Neti ID <span>*</span></span>
                                                <InputText
                                                    value={this.state.customNetiID}
                                                    onChange={this.onChangeCustomNetiID}
                                                    placeholder="Enter Neti ID"
                                                    style={{ width: "15%" }}
                                                    type="text"
                                                    keyfilter="pint"
                                                    name="netiID"
                                                    onFocus={this.focusSearchNetiId}
                                                    onBlur={this.blurSearchNetiId}
                                                    maxlength={10}
                                                />
                                                <Button
                                                    className={"p-button-animation " + this.state.addSearchBtnClassNetiId}
                                                    label={this.state.searchLabelNetiId}
                                                    icon="fas fa-search"
                                                    onClick={this.onSearchCustomNetiID}
                                                    onFocus={this.focusSearchNetiId}
                                                    onBlur={this.blurSearchNetiId}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors['netiID']}</span>
                                        </div>

                                        {this.state.searchView ?
                                            <div className="p-col-12 p-xl-12">

                                                <div className="nw-search-view">

                                                    {this.state.searchViewError ?
                                                        <center className="error-message">{this.state.searchViewErrorMsg || 'No Data Found'}</center> :
                                                        <div>

                                                            {this.state.searchUserDataIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Name</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {userBasicInfo.fullName || '-'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {this.state.searchUserDataIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :

                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Mobile No.</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {formatContactNo || '-'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {this.state.searchUserDataIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :

                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Email Address</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {userBasicInfo.basicEmail || '-'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {this.state.searchUserDataIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :

                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Register Date</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {formatRegisterDate || '-'}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            }
                                                        </div>
                                                    }
                                                </div>

                                            </div>
                                            :
                                            ''
                                        }

                                        <div>

                                            {/* <div className="p-col-12 no-padding-top-bottom">
                                                <div className="p-col-12 p-lg-5 p-xl-6">
                                                    <span className='text-bold'>Name :</span><span style={{ paddingLeft: "7px" }}> {userBasicInfo.fullName}</span>
                                                    </div>
                                                </div> */}
                                            {/* <div className="p-col-12 p-md-12 no-padding-top-bottom">
                                                <div className="p-col-12 p-lg-5 p-xl-6">
                                                <span className='text-bold'>Mobile No :</span><span style={{ paddingLeft: "7px" }}>{formatContactNo}</span>
                                                </div>
                                            </div> */}
                                            {/* <div className="p-col-12 p-xl-12 no-padding-top-bottom">
                                                <div className="p-col-12 p-lg-6 p-xl-7">
                                                    <span className='text-bold'>Email Address :</span><span style={{ paddingLeft: "7px" }}>{userBasicInfo.basicEmail}</span>
                                                    </div>
                                            </div> */}
                                            {/* <div className="p-col-12 p-xl-12 no-padding-top-bottom">
                                                <div className="p-col-12 p-lg-5 p-xl-6">
                                                <span className='text-bold'>Register Date :</span><span style={{ paddingLeft: "7px" }}>{formatRegisterDate}</span>
                                                </div>
                                            </div> */}

                                            <div className="p-col-12 p-xl-12">
                                                {this.state.firstDropDownIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() :
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Institute <span>*</span></span>
                                                        <Dropdown
                                                            placeholder="Select Institute"
                                                            filter={true}
                                                            filterBy="value"
                                                            value={this.state.insitituteID}
                                                            options={instituteList}
                                                            showClear={true}
                                                            onChange={this.onChangeInstitute}
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                }
                                                <span className="error-message">{this.state.insitituteID ? '' : this.state.errors['insititute']}</span>
                                            </div>
                                            <div className="p-col-12 p-xl-12"></div>

                                            <Panel header={moduleHeader}>
                                                {this.state.showModuleCheckBoxAll ?
                                                    <div>
                                                        <div className="p-grid p-col-12 p-xl-12" >
                                                            <div className="p-col-12 p-xl-12">
                                                                <Checkbox inputId="cb1" value="Module" onChange={this.onChangeAllModule} checked={this.state.selectAllModule}></Checkbox>
                                                                <label className="p-checkbox-label">Modules</label>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>

                                                    : ''}
                                                {/* <div className="p-col-12">
                                                    <Checkbox
                                                        inputId="cb1"
                                                        value="Student"
                                                        onChange={this.onChangePoint}
                                                        checked={this.state.pointList.indexOf('Module') !== -1}
                                                    />
                                                    <label style={{ fontSize: "1rem" }} htmlFor="cb1" className="p-checkbox-label">Module</label>
                                                </div> */}

                                                {/* <div> */}
                                                {/* {this.state.userModuleList.map((item) => (
                                                        <div className="p-col-12">
                                                            <Checkbox
                                                                inputId="cb1"
                                                                value={item.moduleName}
                                                                onChange={this.onCheckedStudentModule}
                                                                checked={this.state.assignModuleObj.moduleStudent}
                                                            />
                                                            <label className="p-checkbox-label">{item.moduleName}</label>
                                                        </div>
                                                    ))} */}
                                                {/* </div> */}

                                                {this.state.moduleEligibility.moduleStudent == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox
                                                            inputId="cb1"
                                                            value="Student"
                                                            name="moduleStudent"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.moduleStudent}
                                                        />
                                                        <label className="p-checkbox-label">Student</label>
                                                    </div>
                                                    : ''}

                                                {this.state.moduleEligibility.moduleHrManagement == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox
                                                            inputId="cb2"
                                                            value="HR"
                                                            name="moduleHrManagement"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.moduleHrManagement}
                                                        />
                                                        <label className="p-checkbox-label">HR Management</label>
                                                    </div>
                                                    : ''}

                                                {this.state.moduleEligibility.moduleSemesterExam == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox
                                                            inputId="cb3"
                                                            value="SemesterExam"
                                                            onChange={this.onChangeModule}
                                                            name="moduleSemesterExam"
                                                            checked={this.state.assignModuleObj.moduleSemesterExam}
                                                        />
                                                        <label className="p-checkbox-label">Semester Exam</label>
                                                    </div>
                                                    : ''}

                                                {this.state.moduleEligibility.moduleClassTest == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cb4"
                                                            value="Class Test"
                                                            name="moduleClassTest"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.moduleClassTest}
                                                        />
                                                        <label className="p-checkbox-label">Class Test</label>
                                                    </div>
                                                    : ''}

                                                {this.state.moduleEligibility.moduleRoutine == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox
                                                            inputId="cb5"
                                                            value="Routine"
                                                            name="moduleRoutine"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.moduleRoutine}
                                                        />
                                                        <label className="p-checkbox-label">Routine</label>
                                                    </div>
                                                    : ''}

                                                {this.state.moduleEligibility.moduleStudentAccounts == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cb6"
                                                            value="Student Accounts"
                                                            name="moduleStudentAccounts"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.moduleStudentAccounts}
                                                        />
                                                        <label htmlFor="cb6" className="p-checkbox-label">Student Accounts</label>
                                                    </div>
                                                    : ''}

                                                {this.state.moduleEligibility.moduleStudentAttendance == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cb7"
                                                            value="Student Attendance"
                                                            name="moduleStudentAttendance"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.moduleStudentAttendance}
                                                        />
                                                        <label htmlFor="cb7" className="p-checkbox-label">Student Attendance</label>
                                                    </div>
                                                    : ''}

                                                {this.state.moduleEligibility.moduleHrAttendance == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cb8"
                                                            value="Hr Attendance"
                                                            name="moduleHrAttendance"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.moduleHrAttendance}
                                                        />
                                                        <label htmlFor="cb8" className="p-checkbox-label">HR Attendance</label>
                                                    </div>
                                                    : ""}

                                                {this.state.moduleEligibility.modulePayroll == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cb9"
                                                            value="Payroll"
                                                            name="modulePayroll"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.modulePayroll}
                                                        />
                                                        <label htmlFor="cb9" className="p-checkbox-label">Payroll</label>
                                                    </div>
                                                    : ''}

                                                {this.state.moduleEligibility.moduleInventory == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cb10"
                                                            value="Inventory"
                                                            name="moduleInventory"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.moduleInventory}
                                                        />
                                                        <label htmlFor="cb10" className="p-checkbox-label">Inventory</label>
                                                    </div>
                                                    : ''}

                                                {this.state.moduleEligibility.moduleGeneralAccounts == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cb11"
                                                            value="General Accounts"
                                                            name="moduleGeneralAccounts"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.moduleGeneralAccounts}
                                                        />
                                                        <label htmlFor="cb11" className="p-checkbox-label">General Accounts</label>
                                                    </div>
                                                    : ''}

                                                {this.state.moduleEligibility.moduleMessaging == 9 ?

                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cb12"
                                                            value="Messaging"
                                                            name="moduleMessaging"
                                                            onChange={this.onChangeModule}
                                                            checked={this.state.assignModuleObj.moduleMessaging}
                                                        />
                                                        <label htmlFor="cb12" className="p-checkbox-label">Messaging</label>
                                                    </div>
                                                    : ''}
                                            </Panel>

                                            <div className="p-col-12 p-xl-12 nw-button-parent" >

                                                <div className="required-field">
                                                    (<span>*</span>) required fields
                                                </div>

                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={this.saveTagModuleByAdmin}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className='nw-data-table'>
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <EmAuditPointUserList userTaggingList={this.state.userTaggingList} instituteName ={this.state.insitituteID.instituteName} />
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}