import React, { useEffect, useRef, useState } from "react";
import { DwsService } from '../../service/dwsPoint/DwsService'

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { FileUpload } from "primereact/fileupload";
import ContactListTable from "./ContactListTable";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { result } from "lodash";
import { Growl } from "primereact/growl";

const InstituteContactInfo = () => {
  // states
  const [errors, setErrors] = useState({idInputError: "",});
  const [applicationType, setApplicationType] = useState(false);
  const [loadContactList, setLoadContactList] = useState(false);
  const [data, setData] = useState({
    applicationID: "",
    name: "",
    mobile: "",
    email: "",
    note: "",
  });
  const [showTags, setShowTags] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [instituteInfo, setInstituteInfo] = useState({});
  const [contactList, setContactList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  useEffect(() => {
    dws.getDesignationList()
    .then(response => response.json())
    .then(result => {
      setDesignationList(result);
    });

    dws.getTagList()
    .then(response => response.json())
    .then(result => {
      setTagList(result);
    });
  }, [])

  useEffect(() => {
    if(loadContactList){
      handleLoadContactList();
    }
  }, [loadContactList])

  const growl = useRef(null);

  // constant variables
  const requiredSign = (
    <span style={{ color: "red", marginRight: "2px" }}>*</span>
  );

  const applicationTypeList = [
    { label: "NetiCMS", value: "0", fetch: (id) =>  dws.getInstituteInfoByCMSID(id)},
    { label: "Eduman", value: "1", fetch: (id) =>  dws.getInstituteInfoByInstituteID(id)}
  ];

  /*===============
      Functions
  =================*/
  const dws = new DwsService();

  function handleSearch() {    
    if (data.applicationID) {
      setErrors({ ...errors, idInputError: "" });
      setInstituteInfo({});
      applicationTypeList[applicationType].fetch(data.applicationID)
        .then(res => res.json())
        .then(result => {
          if(result.item || result.instituteId){
              setInstituteInfo(result.item || result)
            } else{
              setErrors({ ...errors, idInputError: "Not Found" })
            }
        })
        setLoadContactList(true);
    } else {
      setErrors({ ...errors, idInputError: "Please Enter a Valid ID" });
    }
  }

  function handleLoadContactList(){
    dws.getAllContacts(data.applicationID)
        .then(res => res.json())
        .then(result => {
          setContactList(result.message? [] : result);
          setLoadContactList(false);
        })
  }

  function handleInput(e) {
    if (e.target.value) {
      const newData = { ...data };
      newData[e.target.name] = e.target.value;
      setData(newData);
      setErrors({ ...errors, [e.target.name]: "" });
    } else {
      setErrors({ ...errors, [e.target.name]: "Please Enter Data" });
    }
  }

  function handleSubmit() {
    const err = validateInput(data);
    setErrors(err);
    if(Object.keys(err).length === 0) {
      const body = {...data, instituteName: instituteInfo.instituteName};
      dws.addNewContact(body)
      .then(response => response.json())
      .then(result => {
        if(result.message) {
          growl.current.show({severity:'error', summary: 'Error Message', detail: result.message, life: 3000});
        } else{
          growl.current.show({severity:'success', summary: 'Success Message', detail: "Contact Added Successfully", life: 3000});
          setLoadContactList(true);
          setData({applicationID: data.applicationID, name: "", mobile: "", email: "", note: ""});
        }
      })
    } else{
      console.log(err);
    }
  }

  function handleSaveTag(){
    const body = {
      contactInfoDTOs: selectedRowsData,
      purposeDTOs: selectedTags,
    }
    
    dws.saveContactTag(body)
    .then(response => response.json())
    .then(result => {
      growl.current.show({severity: result.msgType ? 'success' : 'error', summary: result.msgType ? 'Success Message' : 'Error Message', detail: result.message, life: 3000});
      handleHideTags();
    })
  }

  function handleHideTags(){
    setShowTags(false);
    setSelectedRowsData([]);
    setSelectedTags([]);
  }

  function validateInput({name = "", mobile = "", email = "", note = "", designationDTO = false}) {
    const errors = {};
    const regex = /^01/;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(name.length < 3){
      errors.name = "Please Enter a Valid Name";
    }
    if(!regex.test(mobile) || mobile.length !== 11){
      errors.mobile = "Please Enter a 11 Digit Phone Number.";
    }
    if(!emailRegex.test(email)){
      errors.email = "Please Enter a Valid Email Address.";
    }
    if(note.length < 1){
      errors.note = "Please Enter Note.";
    }
    if(!designationDTO){
      errors.designation = "Please Select a Designation.";
    }

    return errors;
  }

  return (
    <>
      <Growl ref={growl} />
      <div className="p-grid nw-form blur-section">
        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
          <div className="p-col-12 p-xl-12">
            <div className="nw-form-body">
              {/* application type selector dropdown */}
              <div className="p-col-12 p-xl-12">
                <div
                  className="p-inputgroup"
                  style={{ display: !applicationType ? "" : "none" }}
                >
                  <span className="p-inputgroup-addon">
                    Application Type{requiredSign}
                  </span>
                  <Dropdown
                    options={applicationTypeList}
                    value={applicationType}
                    onChange={(e) => {
                      setApplicationType(e.value);
                      setData({...data, applicationType: e.value == 0 ? "NetiCms" : "Eduman"});
                    }}
                    resetFilterOnHide={false}
                    placeholder="Select application type"
                    style={{ width: "100%" }}
                    type="number"
                  />
                </div>

                {applicationType && (
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      {applicationTypeList[applicationType].label}{" "}
                      {requiredSign}
                    </span>
                    <InputText
                      name={applicationType ? "cms_id" : "institute_id"}
                      placeholder={applicationTypeList[applicationType].label}
                      onBlur={(e) =>
                        setData({ ...data, applicationID: e.target.value })
                      }
                      type="text"
                    />
                    <Button
                      className=""
                      icon="fas fa-search"
                      onClick={handleSearch}
                    ></Button>
                  </div>
                )}
                <span className="error-message">{errors.idInputError}</span>
              </div>

              <div
                style={{
                  height: "10px",
                  margin: "20px 7px",
                  borderTop: "1px dashed lightgray",
                  borderBottom: "1px dashed lightgray",
                }}
              >
                {/* for border */}
              </div>

              {instituteInfo.instituteName && (
                <div className="p-col-12 p-xl-12">
                  <div className="institute-info">
                    <ul>
                      <li>
                        <span>Institute ID</span>
                        <span>:</span>
                        <span>{instituteInfo.instituteId}</span>
                      </li>
                      <li>
                        <span>Institute Name</span>
                        <span>:</span>
                        <span>{instituteInfo.instituteName}</span>
                      </li>
                      <li>
                        <span>Institute Address</span>
                        <span>:</span>
                        <span>{instituteInfo.instituteAddress}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    Name {requiredSign}
                  </span>
                  <InputText
                    name="name"
                    placeholder="enter name"
                    onChange={(e) => handleInput(e)}
                    value={data.name}
                    type="text"
                  />
                </div>
                <span className="error-message">{errors.name}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    Designation {requiredSign}
                  </span>
                  <Dropdown
                    options={designationList}
                    optionLabel="categoryName"
                    value={data.designationDTO}
                    onChange={(e) => handleInput(e)}
                    resetFilterOnHide={false}
                    placeholder="enter designation"
                    style={{ width: "100%" }}
                    name="designationDTO"

                    filterBy="categoryName"
                    filter={true}
                  />
                </div>
                <span className="error-message">{errors.designation}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    Mobile No. {requiredSign}
                  </span>
                  <InputText
                    name="mobile"
                    placeholder="enter mobile number"
                    onChange={(e) => handleInput(e)}
                    value={data.mobile}
                    type="text"
                  />
                </div>
                <span className="error-message">{errors.mobile}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    Email {requiredSign}
                  </span>
                  <InputText
                    name="email"
                    placeholder="enter email address"
                    onChange={(e) => handleInput(e)}
                    value={data.email}
                    type="email"
                  />
                </div>
                <span className="error-message">{errors.email}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon nw-inputtextarea-label">
                    Note {requiredSign}
                  </span>
                  <InputTextarea
                    name="note"
                    placeholder="enter note"
                    onChange={(e) => handleInput(e)}
                    value={data.note}
                    type="text"
                    rows={3}
                    cols={30}
                    autoResize={true}
                  />
                </div>
                <span className="error-message">{errors.note}</span>
              </div>

              <div className="p-col-12 p-xl-12 nw-button-parent">
                <Button
                  className="p-button-primary nw-button nw-button-right"
                  label="Save"
                  disabled={instituteInfo.instituteId ? false : true}
                  icon="fas fa-check"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>

        <ContactListTable selectedRowsData={selectedRowsData} setSelectedRowsData={setSelectedRowsData} contactList={contactList} validateInput={validateInput} instituteAddress={instituteInfo.instituteAddress} designationList={designationList} setLoadContactList={setLoadContactList} />

        <div className="p-col-12 p-xl-12 nw-button-parent">
          <Button
            className="p-button-primary nw-button nw-button-right"
            label="Tag"
            icon="fa fa-tags"
            disabled={selectedRowsData.length > 0 ? false: true}
            onClick={() => setShowTags(true)}
          />
        </div>

        <Dialog
                className="nw-dialog"
                header='Contact Tag Info'
                visible={showTags}
                onHide={handleHideTags}
                closable
                style={{height: 'auto', width: '350px'}}
            >
              <div className="nw-data-table tag-table">
                <DataTable
                  value={tagList}
                  selection={selectedTags}
                  onSelectionChange={e => setSelectedTags(e.value) }
                >
                  <Column selectionMode="multiple" header="Select All" headerStyle={{width: '4.2em', textAlign: 'center', justifyContent: 'center'}} bodyStyle={{ textAlign: 'center' }} />
                  <Column field="categoryName" header="Tag Name" headerStyle={{width: '10em', textAlign: 'center'}} />
              </DataTable>
              <div className="p-col-12 p-xl-12 nw-button-parent">
                <Button
                  className="p-button-primary nw-button nw-button-right"
                  label="Save"
                  icon="fas fa-check"
                  disabled={selectedTags.length > 0 ? false: true}
                  onClick={handleSaveTag}
                />
              </div>
            </div>
            
        </Dialog>

      </div>
    </>
  );
};

export default InstituteContactInfo;
