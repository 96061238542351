import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { PartnerPointService } from '../../../service/partnerPoint/PartnerPointService';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import NetiContentLoader from '../../common/NetiContentLoader';
import { Accordion, AccordionTab } from 'primereact/accordion';

let cloneStateBeforeMount;

let billTotalMonth = 0;
let totalPayableAmount = 0;
let totalPaidAmount = 0;
let totalDueAmount = 0;
let instituteDueAmount = 0;
let instituteInfoArr = [];
let errors = {}
export class TempInstituteWiseBill extends Component {

    constructor() {
        super();
        this.state = {
            instituteID: '',
            generateBillId: '',
            billAmount:'',
            instituteIdSearchView: false,
            instituteIdSearchViewError: false,
            firstInputFieldIsLoading: false,
            firstDropdownIsLoading:false,
            instituteIdSearchBtnDisabled:false,
            instituteIdSearchViewErrorMsgBody:'',
            postpaidBillList: [],
            payabaleInstituteList:[],
            buttonDisabled:false,
            instituteError: [],
            billAssignmentError:[],
            assignPointInfo: {
                instituteId: '',
            },
            instituteInfo:{
                instituteName:'',
            },
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: '',
            errors:{},
            receiver:{
                prevGeneratedAmount: 0,
                newGeneratedAmount: 0,
                prevPaidAmount: 0,
                newPaidAmount: 0,
                prevDiscountAmount: 0,
                newDiscountAmount: 0
            },
            adjustPaidAmount: 0,
            adjustDiscountAmount: 0,
        };
        this.netiContentLoader = new NetiContentLoader();
        this.ErrorMessageView = new ErrorMessageView();
        this.CRMPointService = new CRMPointService();
        this.validatorUtility = new ValidatorUtility();
        this.PartnerPointService = new PartnerPointService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    focusSearch = e =>{
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
    }

    blurSearch = e =>{
        this.setState({ searchLabel: '', addSearchBtnClass: ""  });
    }

    onChangeinstituteID = (e) => {
        this.setState({
            instituteID: e.target.value,
            balanceInfoError: {},
            adjustPaidAmount: 0,
            adjustDiscountAmount: 0
        });
        this.setState({ receiver: {}, searchViewError: false, searchView: false, buttonDisabled: true });
    }

    viewPostpaidBillSummaryList = (value) => {
        
        this.setState({ postpaidBillList: [] })
        this.setState({ topProgressBar: true,errorMsgVisible: false });
        billTotalMonth = 0;
        totalPayableAmount = 0;
        totalPaidAmount = 0;
        totalDueAmount = 0;
        const {receiver} = this.state;
        if(receiver.paymentType=='POST-PAID'){
        this.PartnerPointService.getPostpaidBillSummary(value)
        .then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    console.log("bill summary body", body);
                    if(body.length !== 0) {
                        billTotalMonth = body.map(item => item.month).length;
                        totalPayableAmount = body.map(item => item.payableAmount).reduce((a, b) => (a + b));
                        totalPaidAmount = body.map(item => item.paidAmount).reduce((a, b) => (a + b));
                        totalDueAmount = body.map(item => item.dueAmount).reduce((a, b) => (a + b));
                        this.setState({ topProgressBar: false,postpaidBillList: body, errorMsgVisible: false });
                    } else {
                        this.setState({ topProgressBar: false,errorMsgVisible: true, errorMsgBody: 'No bill amount found' });
                    }
                   
                });
            } else {
                this.PartnerPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ errorMsgVisible: true, errorMsgBody: responseBody,topProgressBar: false, })
                    });
            }
        }).catch(error => {
            this.setState({ errorMsgVisible: true, topProgressBar: false, errorMsgBody: 'Please check your connection.' })
        });
    }else if(receiver.paymentType=='PRE-PAID' ){
        this.CRMPointService.fetchGeneratedDueBill_LogByEmDetailsId(value)
        .then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    console.log("bill summary body", body);
                    if(body.length !== 0) {
                        billTotalMonth = body.map(item => item.month).length;
                        totalPayableAmount = body.map(item => item.payableAmount).reduce((a, b) => (a + b));
                        totalPaidAmount = body.map(item => item.paidAmount).reduce((a, b) => (a + b));
                        totalDueAmount = body.map(item => item.dueAmount).reduce((a, b) => (a + b));
                        this.setState({ topProgressBar: false,postpaidBillList: body, errorMsgVisible: false });
                    } else {
                        this.setState({ topProgressBar: false,errorMsgVisible: true, errorMsgBody: 'No bill amount found' });
                    }
                   
                });
            } else {
                this.PartnerPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ errorMsgVisible: true, errorMsgBody: responseBody,topProgressBar: false, })
                    });
            }
        }).catch(error => {
            this.setState({ errorMsgVisible: true, topProgressBar: false, errorMsgBody: 'Please check your connection.' })
        });
    }

    }

    handleErrorNetiId = () => {
        let { balanceInfoError } = this.state;
        let formIsValid = true;

        if (this.state.instituteID === '') {
            formIsValid = false;
            errors["instituteID"] = "Custom neti ID can't left empty.";
            this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
        }

        this.setState({ balanceInfoError });
        return formIsValid;
    }

    onChangeSearchByinstituteID = () => {
        if (this.handleErrorNetiId()) {
            this.setState({ topProgressBar: true, searchIsLoadingFirst: true, searchIsLoadingSecond: true, errorMsgVisible: false, searchView: true, searchViewError: false });
            let { balanceTransferInfo, balanceInfoError } = this.state;
            this.CRMPointService.findBasicInfoByinstituteID(this.state.instituteID)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json().then((body) => {
                            // let userProfile = JSON.parse(localStorage.getItem('Profile'));
                            // if (body.instituteID === userProfile.instituteID) {
                            //     errors["instituteID"] = "You can't transfer balance to your own account";
                            //     this.setState({ buttonDisabled: true, balanceInfoError })
                            //     balanceTransferInfo.receiver.netiID = body.netiID;
                            //     this.setState({
                            //         receiver: body, balanceTransferInfo,
                            //         topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false,
                            //         searchViewError: false
                            //     });
                            // } else {
                                // balanceTransferInfo.receiver.netiID = body.netiID;
                                this.setState({
                                    receiver: body,
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false,
                                    searchViewError: false,
                                    adjustPaidAmount: body.prevPaidAmount-body.newPaidAmount,
                                    adjustDiscountAmount: body.prevDiscountAmount-body.newDiscountAmount
                                });

                                this.setState({ buttonDisabled: false })
                            // }

                        });
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({
                                    receiver: {},
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false, errorMsgBody: responseBody,
                                    searchViewError: true
                                })
                                this.setState({ buttonDisabled: true })

                            });
                    }
                }).catch(error => {
                    this.setState({
                        topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!",
                        searchViewError: false, searchView: false
                    })
                });
        }
    }


    instituteIdError = (e) =>{
        let {instituteError,assignPointInfo} = this.state;
        let formIsValid = true;
        if (this.state.assignPointInfo.instituteId === '') {
            formIsValid = false;
            instituteError["instituteError"] = "Institute ID can't left empty";
        } else {
            instituteError["instituteError"] = "";
        }
        this.setState({ instituteError });
        return formIsValid;
    }

    billAssignmentSubmitHandlerError = (e) => {
        let {errors ,assignPointInfo} = this.state;
        let formIsValid = true;
        if(this.state.generateBillId === ''){
            formIsValid = false;
            errors["generateBillIdError"] = "Monthly Bill can't left empty";
        }

        if(this.state.billAmount === '' || this.state.billAmount === null){
            formIsValid = false;
            errors["billAmountError"] = "Bill Amount can't left empty";
        } 
        if (this.state.billAmount > instituteDueAmount) {
            formIsValid = false;
            errors["billAmountError"] = "Exceeding the monthly bill due amount";
        }

        if (this.state.billAmount > this.state.adjustPaidAmount) {
            formIsValid = false;
            errors["billAmountError"] = "Bill amount exceed payable amount";
        }
        
        this.setState({ errors });
        return formIsValid;
    }

    discountAssignmentSubmitHandlerError = (e) => {
        let {errors ,assignPointInfo} = this.state;
        let formIsValid = true;
        if(this.state.generateBillId === ''){
            formIsValid = false;
            errors["generateBillIdError"] = "Monthly Bill can't left empty";
        }

        if(this.state.billAmount === '' || this.state.billAmount === null){
            errors["billAmountError"] = "Bill Amount can't left empty";
        } 
        if (this.state.billAmount > instituteDueAmount) {
            formIsValid = false;
            errors["billAmountError"] = "Exceeding the monthly bill due amount";
        }

        if (this.state.billAmount > this.state.adjustDiscountAmount) {
            formIsValid = false;
            errors["billAmountError"] = "Bill amount exceed payable amount";
        }
        
        this.setState({ errors });
        return formIsValid;
    }

    onChangeMonthlyBill = (e) => {
        let {postpaidBillList,billAssignmentError} = this.state;
        billAssignmentError["generateBillIdError"] = "";
        this.setState({
            generateBillId: e.target.value,
            billAssignmentError
        })

        for (let i = 0; i < postpaidBillList.length; i++) {
            if (postpaidBillList[i].generateBillId == e.target.value) {
                instituteDueAmount = postpaidBillList[i].dueAmount;
            }
        }
        
        
        this.setState({
            instituteIdSearchBtnDisabled: true,
            billAmount: instituteDueAmount
        })
    }

    onChangeBillAmount = (e) =>{
        let {billAssignmentError} = this.state;
        errors["billAmountError"] = "";
        

        if(e.target.value > this.state.adjustPaidAmount){
            errors["billAmountError"] = "Bill amount exceed";
        }
        else{
            errors["billAmountError"] = "";
        }
        
        this.setState({
            billAmount: e.target.value,
            errors
        })
    }

    onChangeDiscountBillAmount = (e) =>{
        let {billAssignmentError} = this.state;
        errors["billAmountError"] = "";
        

        if(e.target.value > this.state.adjustDiscountAmount){
            errors["billAmountError"] = "Bill amount exceed";
        }
        else{
            errors["billAmountError"] = "";
        }
        
        this.setState({
            billAmount: e.target.value,
            errors
        })
    }

    differenceAmount = (rowData) => {
        return(<span>{rowData['logDue'] - rowData['originalDue']}</span>)
    }

    viewDialog = (emId) => {

        this.viewPostpaidBillSummaryList(emId);
        this.setState({ visible: true, errors:{} });
    }

    viewDialogDiscount = (emId) => {

        this.viewPostpaidBillSummaryList(emId);
        this.setState({ discountVisible: true, errors:{} });
    }

    onHide = (event) => {
        this.setState({ visible: false, discountVisible: false });
    }

    processDueBill = emId => {
        // console.log("emId", emId);

        let {instituteInfo} = this.state;
        if(this.billAssignmentSubmitHandlerError()){
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            let requestedObject = {
                "emDetailsId": emId,
                "generateBillId":this.state.generateBillId,
                "billAmount": this.state.billAmount
            }
            console.log("request object",requestedObject);
            
            this.CRMPointService.createBillPaidDiff(requestedObject)
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });

                        setTimeout( ()=> {
                            this.onChangeSearchByinstituteID();
                            this.viewPostpaidBillSummaryList(emId);
                            this.setState({
                                billAmount: ''
                            })
                        },500)
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' });
                });
        }
        
    }

    processDiscountAmount = emId => {
        console.log("emId", emId);

        let {instituteInfo} = this.state;
        if(this.discountAssignmentSubmitHandlerError()){
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            let requestedObject = {
                "emDetailsId": emId,
                "generateBillId":this.state.generateBillId,
                "billAmount": this.state.billAmount
            }
            console.log("request object",requestedObject);
            
            this.CRMPointService.createBillAdjustment(requestedObject)
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });

                        setTimeout( ()=> {
                            this.onChangeSearchByinstituteID()
                        },500)
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' });
                });
        }
        
    }
    


    render() {
        let { receiver, firstInputFieldIsLoading,topProgressBar,firstDropdownIsLoading,errorMsgVisible,instituteError,assignPointInfo} = this.state;
        let {errorMsgBody,instituteInfo} = this.state

        let billSummaryList = [];
        if ((this.state.postpaidBillList != null) && (this.state.postpaidBillList.length > 0)) {
            billSummaryList = this.state.postpaidBillList.map((item) => ({
                value: item.generateBillId,
                label: `${item.year} - ${item.month} - ${item.dueAmount} Tk`
            }));
        }

        let logDue = receiver.newGeneratedAmount - ( receiver.newPaidAmount + receiver.newDiscountAmount)

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }


                <div className="accrodion-section">
                    <div className="p-grid nw-form">
                        <div  className="p-col-12 p-xl-12">
                            <div className="nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                        <InputText
                                            value={this.state.instituteID}
                                            onChange={this.onChangeinstituteID}
                                            placeholder="Enter Transfer Neti ID"
                                            name="instituteID"
                                            showClear={true}
                                            style={{ width: "15%" }}
                                            type="text"
                                            onFocus={ this.focusSearch }
                                            onBlur={ this.blurSearch }
                                        />
                                        <Button
                                            className={"p-button-animation " + this.state.addSearchBtnClass}
                                            label={ this.state.searchLabel}
                                            icon="fas fa-search"
                                            onClick={this.onChangeSearchByinstituteID}
                                            onFocus={ this.focusSearch }
                                            onBlur={ this.blurSearch }
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["instituteID"]}</span>
                                </div>

                                {this.state.searchView ?
                                    <div className="p-col-12 p-xl-12">

                                        <div className="nw-search-view">

                                            {this.state.searchViewError ?
                                                <center className="error-message">{this.state.errorMsgBody || "No Data Found"}</center> :
                                                <div>
                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.searchIsLoadingFirst ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Institute Name</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {receiver.instituteName}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.searchIsLoadingSecond ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Address</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {receiver.instituteAddress}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.searchIsLoadingSecond ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Status</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {receiver.instituteStatus === 1? "Active" : "Inactive"}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.searchIsLoadingSecond ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Payment Type</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {receiver.paymentType}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.searchIsLoadingSecond ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Original Due</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {receiver.originalDue}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.searchIsLoadingSecond ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Log Due</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    { logDue }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.searchIsLoadingSecond ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge "></span>
                                                                <span className="p-inputgroup-addon"></span>
                                                                <span className="p-inputgroup-colon">(-)</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    <span className="error-message">{ logDue - receiver.originalDue}</span>
                                                                </div>
                                                                {/* <div className="nw-inputgroup-desc">
                                                                    { receiver.originalDue - (receiver.newGeneratedAmount - ( receiver.newPaidAmount + receiver.newDiscountAmount))}
                                                                </div> */}
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                            }
                                        </div>



                                    </div>
                                    :
                                    ''
                                }
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-4">

                                        <Accordion activeIndex={0} >
                                            <AccordionTab header="Generaterd Bill">
                                                <div className="p-col-12">

                                                    <div className="nw-search-view">
                                                        <div>
                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.searchIsLoadingFirst ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Previous</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {receiver.prevGeneratedAmount}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.searchIsLoadingSecond ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">New</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {receiver.newGeneratedAmount}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            {/* <div className="p-col-12 p-xl-12">
                                                            {this.state.searchIsLoadingSecond ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Address</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {receiver.instituteStatus === 1? "Active" : "Inactive"}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div> */}

                                                        </div>
                                                    </div>

                                                </div>
                                            </AccordionTab>
                                        </Accordion>
                                    </div>
                                    <div className="p-col-12 p-xl-4">
                                        <Accordion activeIndex={0} >
                                            <AccordionTab header="Paid">
                                                <div className="p-col-12">

                                                    <div className="nw-search-view">
                                                        <div>
                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.searchIsLoadingFirst ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Previous</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {receiver.prevPaidAmount}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.searchIsLoadingSecond ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">New</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {receiver.newPaidAmount}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.searchIsLoadingSecond ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge "></span>
                                                                        <span className="p-inputgroup-addon"></span>
                                                                        <span className="p-inputgroup-colon">(-)</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            <span className="error-message">{ this.state.adjustPaidAmount }</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                        </div>

                                                        { this.state.searchView ?
                                                            receiver.prevPaidAmount <= receiver.newPaidAmount ?
                                                                "Paid Bill is Up to date": 
                                                                <div className="p-col-12 nw-button-parent">
                                                                    <Button
                                                                        className="p-button-primary nw-button nw-button-right"
                                                                        label="Adjust"
                                                                        icon="fas fa-check"
                                                                        onClick={(e) => this.viewDialog(receiver.emDetailsId)}
                                                                    />
                                                                </div>

                                                            :''
                                                        }
                                                    </div>

                                                </div>
                                            </AccordionTab>
                                        </Accordion>
                                    </div>
                                    <div className="p-col-12 p-xl-4">
                                        <Accordion activeIndex={0} >
                                            <AccordionTab header="Discount">
                                                <div className="p-col-12">

                                                    <div className="nw-search-view">
                                                        <div>
                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.searchIsLoadingFirst ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Previous</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {receiver.prevDiscountAmount}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.searchIsLoadingSecond ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">New</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {receiver.newDiscountAmount}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        { this.state.searchView ?
                                                            receiver.prevDiscountAmount <= receiver.newDiscountAmount ?
                                                                "Discount Bill is Up to date": 
                                                                <div className="p-col-12 nw-button-parent">
                                                                    <Button
                                                                        className="p-button-primary nw-button nw-button-right"
                                                                        label="Adjust"
                                                                        icon="fas fa-check"
                                                                        onClick={(e) => this.viewDialogDiscount(receiver.emDetailsId)}
                                                                    />
                                                                </div>

                                                             :''
                                                        } 
                                                    </div>

                                                </div>
                                            </AccordionTab>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>

                        

                        
                        </div>

                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog className="nw-dialog" 
                        header="Adjust Bill"
                        visible={this.state.visible}
                        onHide={this.onHide} 
                        maximizable
                    >
                         <div className="p-grid nw-form">
                            <div  className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <b>"{ receiver.instituteName }" need to pay: <span className="error-message">{ this.state.adjustPaidAmount }</span></b> 
                                        <span className="error-message">{this.state.billAssignmentError["generateBillIdError"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Monthly Bill<span>*</span></span>
                                                <Dropdown
                                                    value={this.state.generateBillId}
                                                    options={billSummaryList}
                                                    onChange={this.onChangeMonthlyBill}
                                                    placeholder='Select Institute'
                                                    name="device"
                                                    autoWidth={false}
                                                />
                                        </div>
                                        <span className="error-message">{this.state.billAssignmentError["generateBillIdError"]}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Bill Amount <span>*</span></span>
                                            <InputText 
                                                value={this.state.billAmount}
                                                type="text" 
                                                keyfilter="pint"
                                                name="billAmountError"
                                                onChange={this.onChangeBillAmount}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors["billAmountError"]}</span>
                                    </div>

                                    <div className="p-col-12 nw-button-parent">
                                        <Button
                                            className="p-button-primary nw-button nw-button-right"
                                            label="Process"
                                            icon="fas fa-check"
                                            onClick={ e => this.processDueBill(receiver.emDetailsId)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>

                    <Dialog className="nw-dialog" 
                        header="Adjust Discount"
                        visible={this.state.discountVisible}
                        onHide={this.onHide} 
                        maximizable
                    >
                         <div className="p-grid nw-form">
                            <div  className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <b>"{ receiver.instituteName }" need to pay: <span className="error-message">{ this.state.adjustDiscountAmount }</span></b> 
                                        <span className="error-message">{this.state.billAssignmentError["generateBillIdError"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Monthly Bill<span>*</span></span>
                                                <Dropdown
                                                    value={this.state.generateBillId}
                                                    options={billSummaryList}
                                                    onChange={this.onChangeMonthlyBill}
                                                    placeholder='Select Institute'
                                                    name="device"
                                                    autoWidth={false}
                                                />
                                        </div>
                                        <span className="error-message">{this.state.billAssignmentError["generateBillIdError"]}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Bill Amount <span>*</span></span>
                                            <InputText 
                                                value={this.state.billAmount}
                                                type="text" 
                                                keyfilter="pint"
                                                name="billAmountError"
                                                onChange={this.onChangeDiscountBillAmount}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors["billAmountError"]}</span>
                                    </div>

                                    <div className="p-col-12 nw-button-parent">
                                        <Button
                                            className="p-button-primary nw-button nw-button-right"
                                            label="Process"
                                            icon="fas fa-check"
                                            onClick={ e => this.processDiscountAmount(receiver.emDetailsId)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>
            </div>
        );
    }
}