import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { UserCoreUrlInfoService } from '../../../service/UserCoreUrlInfoService';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import { UserCategoryService } from "../../../service/UserCategoryService";
import { Link } from 'react-router-dom';
import NetiContentLoader from '../../common/NetiContentLoader';

let urlId;
let errors = {}
let cloneStateBeforeMount;
export class CoreConfigView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            academicYearList: [],
            circularPic: {
                extention: '',
                contentPic: '',
                contentName: ''
            },
            signaturePic: {},
            signatureImg: '',
            proPic: {
                contentName: ''
            },
            instituteInfo: {
                academicYearCoreCategoryInfoDTO: {
                    categoryName: '',
                    coreCategoryID: ''
                },
                circularName: '',
                circularTitle: '',
                circularFileContent: '',
                circularFileSaveOrEditable: false,
                signatureTitle: '',
                signatureName: '',
                signaturePath: '',
                signatureFileContent: "",
                signatureFileSaveOrEditable: false,
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            updateInstituteInfo: {
                academicYearCoreCategoryInfoDTO: {
                    categoryName: '',
                    coreCategoryID: ''
                },
                circularName: '',
                circularTitle: '',
                circularFileContent: '',
                circularFileSaveOrEditable: false,
                signatureTitle: '',
                signatureName: '',
                signaturePath: '',
                signatureFileContent: "",
                signatureFileSaveOrEditable: false,
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            errors: {},
            dataTableIsLoading: false,
            dataIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            checkEligibility: false,
            errorMsgBody: '',

            updatedCircularImg: '',
            updatedSignatureImg: '',
            clonecircularImg:''
        };
        this.admisiaPointService = new AdmisiaPointService();
        this.userCoreUrlInfoService = new UserCoreUrlInfoService();
        this.userCategoryService = new UserCategoryService();
        this.netiFileHandler = new NetiFileHandler();
        this.getProfileImg = this.getProfileImg.bind(this);
        this.viewInstituteDetails = this.viewInstituteDetails.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();

    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 1000);
        }
    }

    componentWillMount() {
        
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        console.log("coreconfigview",urlId);
        if (urlId) {
            this.setState({ homeReturnButton: false })
            this.viewInstituteDetails();
        }
        else {
            this.setState({ homeReturnButton: true })
        }
      
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));

        this.getAcademicYearListByTypeID();
    }


    getProfileImg() {

        const { instituteInfo } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';
        if (instituteInfo.signaturePath !== null) {
            this.netiFileHandler.getByteImage(instituteInfo.signaturePath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(instituteInfo.signaturePath);
                                this.setState({
                                    signatureImg: contentType + body.fileContent,
                                    updatedSignatureImg: body.fileContent,
                                    cloneSignatureImg: contentType + body.fileContent,
                                });
                            })
                    } else {
                        return res.json()
                            .then((body) => {
                                console.log("image error body", body);
                            })

                    }
                });
        }
        if (instituteInfo.circularPath !== null) {
            this.netiFileHandler.getByteImage(instituteInfo.circularPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let circularImage = this.netiFileHandler.getImageContentType(instituteInfo.circularPath);
                                this.setState({
                                    circularImg: circularImage + body.fileContent,
                                    updatedCircularImg: body.fileContent,
                                    clonecircularImg:circularImage + body.fileContent,
                                });
                            })
                    } else {
                        return res.json()
                            .then((body) => {
                                console.log("image error body", body);
                            })

                    }
                });
        }

        
    }

    viewInstituteDetails() {
        this.setState({ dataIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.admisiaPointService.getCoreConfigInfo(urlId)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ instituteInfo: body });
                        console.log('instituteInfo', body)
                        //this.setState({ updateInstituteInfo: JSON.parse(JSON.stringify(body)) });
                        this.setState({ checkEligibility: true });
                        this.getProfileImg();
                        this.setState({ dataIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else if (res.status == 404) {
                    return res.json().then((body) => {
                        this.setState({ checkEligibility: false });
                        this.setState({ dataIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });


    }


    onHide = (event) => {

       
        this.getProfileImg();
        
        this.setState({
            // circularPic: {},
            // signaturePic: {},
            updateInstituteInfo: cloneStateBeforeMount.updateInstituteInfo,
            // circularImage: this.state.clonecircularImg
        });
        this.setState({ errors: {} });
        this.setState({ visible: false });
        
    }

    viewDialog = (e) => {
        this.setState({ visible: true });
    }

    onChangeEditHandler = (e) => {

        let { instituteInfo } = this.state;
        errors = {};
        this.setState({ updateInstituteInfo: JSON.parse(JSON.stringify(instituteInfo)) });
        this.viewDialog(e);
      
    }


    getAcademicYearListByTypeID = () => {
        this.setState({ topProgressBar: true });
        this.userCategoryService.fetchListByDefaultCode("T107")
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ academicYearList: body });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.growl.show({ severity: 'warn', summary: 'Warning', detail: 'Connection Problem' });
            });
    }


    onUpdateAcademicYear = (e) => {
        let { updateInstituteInfo } = this.state;
        updateInstituteInfo.academicYearCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ updateInstituteInfo });
    }

    onUpdateCircularTitle = (e) => {
        let { updateInstituteInfo } = this.state;
        updateInstituteInfo.circularTitle = e.target.value;
        this.setState({ updateInstituteInfo });
    }

    circularImageUpload = (e) => {
        let { updateInstituteInfo } = this.state;
        errors = {};
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = () => {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ circularPic: album });
            updateInstituteInfo.circularFileSaveOrEditable = true
            errors["circularImage"] = ''
        }

    }

    onUpdateSignatureTitle = (e) => {
        let { updateInstituteInfo } = this.state;
        updateInstituteInfo.signatureTitle = e.target.value;
        this.setState({ updateInstituteInfo });
    }

    signatureImageUpload = (e) => {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = () => {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ signaturePic: album });
        }
    }

    onUpdateHandler = () => {

        let { updateInstituteInfo } = this.state;
        this.setState({ topProgressBar: true });
        if (this.state.circularPic.contentPic) {
            updateInstituteInfo.circularFileContent = this.state.circularPic.contentPic;
            updateInstituteInfo.circularFileSaveOrEditable = true;
            // let circularExtention = this.netiFileHandler.getImageExtention(this.state.circularPic.extention);
            updateInstituteInfo.circularName = this.state.circularPic.contentName;
        }
        else {
            updateInstituteInfo.circularFileContent = this.state.updatedCircularImg;
            updateInstituteInfo.circularFileSaveOrEditable = true;
        }

        if (this.state.signaturePic.contentPic) {
            updateInstituteInfo.signatureFileContent = this.state.signaturePic.contentPic;
            updateInstituteInfo.signatureFileSaveOrEditable = true;
            // let signatureExtention = this.netiFileHandler.getImageExtention(this.state.signaturePic.extention);
            updateInstituteInfo.signatureName = this.state.signaturePic.contentName;
        } else {
            updateInstituteInfo.signatureFileContent = this.state.updatedSignatureImg;
            updateInstituteInfo.signatureFileSaveOrEditable = true;
        }
        this.setState({ updateInstituteInfo });
        if (this.coreConfigUpdateError()) {
            this.setState({ topProgressBar: true, dataIsLoading: true, errorMsgVisible: false })
            this.admisiaPointService.updateCoreConfigInfo(updateInstituteInfo)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated!" });
                        this.setState({ visible: false });
                        this.viewInstituteDetails();
                        this.setState({ errors: {} });
                        this.setState({
                            circularPic: {},
                            signaturePic: {},
                        })
                        this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: false })

                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody });
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                });
        }
    }

    onResetHandler = () => {
        this.setState({ updateInstituteInfo: JSON.parse(JSON.stringify(this.state.instituteInfo)) });
        this.setState({
            circularPic: {},
            signaturePic: {},
        })
    }

    coreConfigUpdateError() {

        let { updateInstituteInfo, errors } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (!updateInstituteInfo.academicYearCoreCategoryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["academicYear"] = "Academic Year can't left empty.";
        }

        if (!updateInstituteInfo.circularTitle) {
            formIsValid = false;
            errors["circularTitle"] = "Circular Title can't left empty.";
        }

        if (this.state.circularPic.contentName === '' && this.state.updateInstituteInfo.circularName === '') {
            formIsValid = false;
            errors["circularImage"] = "Circular File can't left empty.";
        }

        if (updateInstituteInfo.signatureTitle === '') {
            formIsValid = false;
            errors["signatureTitle"] = "Signature Title can't left empty.";
        }

        if (this.state.signaturePic.contentName === '' && this.state.updateInstituteInfo.signatureName === '') {
            formIsValid = false;
            errors["signatureImage"] = "Signature File can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }


    formatString = (str) => {
        if (str !== null && str !== '') {
            var n = str.indexOf(".");
            if (n < 0) {
                return str;
            } else {
                return str.substring(0, n);
            }
        }
    }

    turnOffDialog = () => {

       this.onHide();

    }



    removeButtonOnclick = () =>{
    /* wip*/
    let {updateInstituteInfo , circularPic} = this.state;


    updateInstituteInfo.circularFileContent = '';
    updateInstituteInfo.circularName = '';
    circularPic.extention = ''
    circularPic.contentPic = ''
    circularPic.contentName = ''

    this.setState({updateInstituteInfo, circularPic, circularImg: ''});
           
    }

    sigRemoveButtonOnclick = () =>{

        let {updateInstituteInfo , signaturePic} = this.state;

        updateInstituteInfo.signatureFileContent = '';
        updateInstituteInfo.signatureName= '';
        signaturePic.extention = ''
        signaturePic.contentPic = ''
        signaturePic.contentName = ''

    
        // this.setState({ signaturePic: { signaturePic: '' }, signatureImg: '', updateInstituteInfo })
        this.setState({ signaturePic, signatureImg: '', updateInstituteInfo })
           
    }



    render() {
        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">(370 x 240 px (recommended)</span></div>
        let { instituteInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let academicYear = [];
        if ((this.state.academicYearList != null) && (this.state.academicYearList.length > 0)) {
            academicYear = this.state.academicYearList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        return (
            <div className="p-fluid">
                {this.state.topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {this.state.errorMsgVisible ?
                    <ErrorMessageView errorMsgBody={this.state.errorMsgBody} />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">
                    <div className="p-grid nw-form">

                        {this.state.homeReturnButton === false ?
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.dataIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Academic Year
                                                    </span>
                                                <div className="nw-inputgroup-desc">
                                                    {instituteInfo.academicYearCoreCategoryInfoDTO.categoryName}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.dataIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Circular Title
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                    {instituteInfo.circularTitle}
                                                </div>
                                            </div>
                                        }
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Circular <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/JPEG/GIF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">
                                                    <div className="image-view-main">
                                                        <div className="upload-image-view">
                                                            <img
                                                                src={this.state.circularImg || this.state.clonecircularImg}
                                                                alt="Institute Logo"
                                                                style={{ height: "80px" }}
                                                            />
                                                          
                                                        </div>
                                                    <div className="image-title">
                                                        {this.state.circularPic.contentName || this.state.instituteInfo.circularName}
                                                    </div>
                                                    </div>
                                             
                                                </div>
                                            
                                            </div>
                                        }
                                     
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        {this.state.dataIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Signature Title
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                    {instituteInfo.signatureTitle}
                                                </div>
                                            </div>
                                        }
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Signature <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/JPEG/GIF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">
                                                    <div className="image-view-main">
                                                        <div className="upload-image-view">
                                                            <img src={ this.state.signatureImg || this.state.cloneSignatureImg }
                                                            style={{ height: "80px" }}
                                                            />
                                                        </div>
                                                    <div className="image-title">
                                                        {this.state.signaturePic.contentName || this.state.instituteInfo.signatureName}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                      
                                    </div>


                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                        <Button
                                            className="p-button-primary nw-button nw-button-right"
                                            label="Edit"
                                            icon="fas fa-check"
                                            onClick={this.onChangeEditHandler}
                                        />

                                    </div>
                                </div>
                            </div>

                            :
                            <div className="">
                                <div className="p-grid">
                                    <div className="card p-col-12 p-xl-3">
                                        <center className="custom-center">
                                            <Link
                                                to="/institute_config"
                                                className="rainbow-button"
                                                alt="Go Admisia Home"
                                            />
                                        </center>
                                    </div>
                                    <div className="card p-col-12 p-xl-3">
                                        <center className="custom-center">
                                            <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                        </center>
                                    </div>
                                    <div className="card p-col-12 p-xl-6">
                                        <img src="assets/images/dws_home.png" width="100%" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="dialog-section">


                        <Dialog
                            className="nw-dialog"
                            header="Core Config (Update)"
                            visible={this.state.visible}
                            onHide={this.onHide}
                            closable
                        >
                            <div className="p-fluid">

                                {topProgressBar ?
                                    <ErrorMessageView
                                        topProgressBar={topProgressBar}
                                    />
                                    : null
                                }
                                {errorMsgVisible ?
                                    <ErrorMessageView
                                        errorMsgVisible={errorMsgVisible}
                                        errorMsgBody={errorMsgBody}
                                    />
                                    : null
                                }
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-grid nw-form">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="nw-form-body">

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">
                                                             Academic Year <span>*</span>
                                                            </span>
                                                            <Dropdown
                                                                placeholder="Select Academic Year"
                                                                value={this.state.updateInstituteInfo.academicYearCoreCategoryInfoDTO.coreCategoryID}
                                                                options={academicYear}
                                                                onChange={this.onUpdateAcademicYear}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="academicYear"
                                                                id="academicYear"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateInstituteInfo.academicYearCoreCategoryInfoDTO.coreCategoryID ? '' : errors['academicYear']}</span>
                                                    </div>


                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">
                                                             Circular Title <span>*</span>
                                                            </span>
                                                            <InputText
                                                                value={this.state.updateInstituteInfo.circularTitle}
                                                                id="circularName"
                                                                placeholder="Enter Circular Name"
                                                                style={{ width: "100%" }}
                                                                type="text"
                                                                name="circularName"
                                                                onChange={this.onUpdateCircularTitle}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateInstituteInfo.circularTitle ? '' : errors['circularTitle']}</span>
                                                    </div>



                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup nw-upload-button">
                                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                            Circular <span>*</span>
                                                            <br />
                                                            <span>(PNG/JPG/JPEG/GIF)</span>
                                                            </span>

                                                            <div className="nw-upload-button-inside">
                                                                {this.state.circularPic.contentPic ||this.state.circularImg ? (
                                                                <div className="image-view-main">
                                                                    <div className="upload-image-view">
                                                                        <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={this.removeButtonOnclick}
                                                                        />
                                                                        <img src={ this.state.circularImg === "" ?
                                                                         "data:" + this.state.circularPic.extention + ";base64," + this.state.circularPic.contentPic : this.state.circularImg
                                                                        }
                                                                        style={{ height: "80px" }}
                                                                        />
                                                                    </div>
                                                                <div className="image-title">
                                                                    {this.state.circularPic.contentName || this.state.instituteInfo.circularName}
                                                                </div>
                                                                </div>
                                                            ) : (
                                                                
                                                                <FileUpload
                                                                    name="circularImage"
                                                                    chooseLabel={
                                                                        this.state.circularPic.contentName || uploadFileLabel
                                                                    }

                                                                    mode="basic"
                                                                    accept="image/*"
                                                                    maxFileSize={1000000}
                                                                    onSelect={this.circularImageUpload}
                                                                    auto={true}
                                                                />
                                                            )}
                                                            </div>
                                                        </div>
                                                         <span className='error-message'>{this.state.circularPic.contentName ? "" : errors["circularImage"]}</span>
                                                    </div>




                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">
                                                            Signature Title <span>*</span>
                                                            </span>
                                                            <InputText
                                                                value={this.state.updateInstituteInfo.signatureTitle}
                                                                id="signatureTitle"
                                                                placeholder="Enter Signature Title"
                                                                style={{ width: "100%" }}
                                                                type="text"
                                                                name="signatureTitle"
                                                                onChange={this.onUpdateSignatureTitle}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.updateInstituteInfo.signatureTitle ? '' : errors['signatureTitle']}</span>
                                                    </div>


                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.dataIsLoading ?
                                                        this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup nw-upload-button">
                                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                                   Signature <span>*</span>
                                                                <br />
                                                                   <span>(PNG/JPG/JPEG/GIF)</span>
                                                                </span>

                                                                <div className="nw-upload-button-inside">
                                                                {this.state.signaturePic.contentPic ||this.state.signatureImg ? (
                                                                    <div className="image-view-main">
                                                                        <div className="upload-image-view">
                                                                            <Button
                                                                            className="delete-upload-button"
                                                                            icon="fas fa-times-circle"
                                                                            onClick={this.sigRemoveButtonOnclick}
                                                                            />
                                                                        
                                                                            <img src={ this.state.signatureImg === "" ?
                                                                                "data:" + this.state.signaturePic.extention + ";base64," + this.state.signaturePic.contentPic : this.state.signatureImg
                                                                            }
                                                                            style={{ height: "80px" }}
                                                                            />
                                                                        </div>
                                                                    <div className="image-title">
                                                                        {this.state.signaturePic.contentName || this.state.instituteInfo.signatureName}
                                                                    </div>
                                                                    </div>
                                                                ) : (
                                                                    
                                                                    <FileUpload
                                                                        chooseLabel={
                                                                            this.state.signaturePic.contentName || uploadFileLabel
                                                                        }

                                                                        mode="basic"
                                                                        accept="image/*"
                                                                        maxFileSize={1000000}
                                                                        onSelect={this.signatureImageUpload}
                                                                        auto={true}
                                                                    />
                                                                )}
                                                                </div>
                                                            
                                                            </div>
                                                        }
                                                       
                                                        <span className='error-message'>{this.state.signaturePic.contentName ? "" : errors["signatureImage"]}</span>
                                                            {/* {errors["logo"]} */}
                                                      
                                                    </div>
                                                    

                                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">

                                                        <Button
                                                            className="p-button-danger nw-button nw-button-multiple"
                                                            label="Discard"
                                                            icon="fas fa-times"
                                                            onClick={e => this.turnOffDialog()}
                                                        />
                                                       
                                                        <Button
                                                            className="p-button-primary nw-button nw-button-multiple"
                                                            label="Update"
                                                            icon="fas fa-check"
                                                            onClick={this.onUpdateHandler}
                                                        />
                                                    
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                           </Dialog>
                        </div>

                    </div>
            </div>

        );
    }
}
