import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { MessageRechargeService } from '../../../../service/myPoint/MessageRechargeService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';

// pdf related import
import { pdfDownloadPortrait_TableWithSubTitle } from '../../../common/JsPdfDownload';
let pdfSubTitleColumn = "";
let pdfSubTitleList = "";
// pdf related import end

let maxDate = new Date();
export class MessageLogRecharge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableSelection: null,
            messageRechargeError: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            tableView: false,
            inputErrorMessage: null,
            dataTableValue: [],
            messageRechargeRecords: {
                requestStartDate: "",
                requestEndDate: "",
                limit: 10,
                transactionType: "Recharge",
            },

            dataTableIsLoading: false

        };

        this.messageRechargeService = new MessageRechargeService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();

    }

    onChangeStartDate = (e) => {
        let { messageRechargeRecords } = this.state;
        messageRechargeRecords.requestStartDate = e.target.value
        this.setState({ messageRechargeRecords });
        this.clearDateError(e.target.name);

    }

    onChangeEndDate = (e) => {
        let { messageRechargeRecords } = this.state;
        messageRechargeRecords.requestEndDate = e.target.value
        this.setState({ messageRechargeRecords });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { messageRechargeError } = this.state;
        messageRechargeError[name] = ''
        this.setState({ messageRechargeError })
    }

    onSubmitHandler = (e, props) => {

        let { messageRechargeRecords } = this.state;
        if (this.messageRechargeFormHandleError()) {
            this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(messageRechargeRecords.requestEndDate) >= Date.parse(messageRechargeRecords.requestStartDate)) {
                this.messageRechargeService.getUserMessageRechargesListByDate(messageRechargeRecords)
                    .then((res) => {
                        if (res.status == 302) {
                            return res.json().then((body) => {

                                if (body.length == 0) {
                                    return this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: 'No Data Found.' })
                                } else {
                                    body.map(item => {
                                        let totalAmount = Number(item.productPurchaseLogDTO.totalAmount.toFixed(2)); 
                                        let trxDate = String(item.productPurchaseLogDTO.totalAmount);
                                        item.trxDate = NetiDateUtils.getAnyShortForm(item.trxDate, 'DD-MMM-YYYY hh:mm:ss a');
                                        item.productPurchaseLogDTO.totalAmount = item.productPurchaseLogDTO.totalAmount.toFixed(2);
                                        item.productPurchaseLogDTO.trxDate = item.productPurchaseLogDTO.trxDate;

                                        Object.assign(item, {purchaseLogDTOtotalAmount: totalAmount, trxDateCustomaize: trxDate});   // for pdf column
                                    })

                                    this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                                       
                                }

                            });
                        } else {
                            this.messageRechargeService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });

            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be ahead of To Date." });
            }

        }

    }

    messageRechargeFormHandleError = () => {
        let { messageRechargeError } = this.state;
        let formIsValid = true;

        if (this.state.messageRechargeRecords.requestStartDate === '') {
            formIsValid = false;
            messageRechargeError["requestStartDate"] = "Start Date can't left empty.";
        }
        if (this.state.messageRechargeRecords.requestEndDate === '') {
            formIsValid = false;
            messageRechargeError["requestEndDate"] = "End Date can't left empty.";
        }

        this.setState({ messageRechargeError });
        return formIsValid;
    }

    payableAmountTemplate = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.productPurchaseLogDTO.totalAmount)
    }

    msgQuantityBody = (rowData) => {
    return (<b>{rowData.quantity.toLocaleString('EN-IN')}</b>)
      }

    exportPdf = () => {

        let tableColumns = [
          { title: "Date", dataKey: "trxDate" },
          { title: "Message Type", dataKey: "trxType" },
          { title: "Message Quantity", dataKey: "quantity" },
          { title: "Payable Amount", dataKey: "purchaseLogDTOtotalAmount" },
        ];

        let tableColumnStyles = {
            purchaseLogDTOtotalAmount: { halign: "right"},
        }

        pdfDownloadPortrait_TableWithSubTitle('Message Log Recharge',80, pdfSubTitleColumn, pdfSubTitleList, tableColumns, this.state.dataTableValue, tableColumnStyles, "message_log_recharge.pdf")
      
    }

    render() {

        let { dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let header = <div className="header-title">
                            <div className="header-title-left">
                                Message Recharge Log
                            </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length.toLocaleString('EN-IN')}
                                </a>
                            </div>
                        </div>;
        // pdf 
        pdfSubTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" },
          ];
          pdfSubTitleList = [
            { A: "From Date", B: NetiDateUtils.getAnyShortForm(this.state.messageRechargeRecords.requestStartDate, 'DD-MMM-YYYY'), C: "End Date", D: NetiDateUtils.getAnyShortForm(this.state.messageRechargeRecords.requestEndDate, 'DD-MMM-YYYY') }
          ];
        // pdf end

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                    <Calendar
                                        value={this.state.messageRechargeRecords.requestStartDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        name="requestStartDate"
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.messageRechargeError["requestStartDate"]}</span>

                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                    <Calendar
                                        value={this.state.messageRechargeRecords.requestEndDate}
                                        onChange={this.onChangeEndDate}
                                        showIcon={true}
                                        name="requestEndDate"
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.messageRechargeError["requestEndDate"]}</span>

                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parrent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitHandler.bind(this)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <div>
                                            <DataTable
                                                value={this.state.dataTableValue}
                                                header={header}
                                                responsive={true}
                                                paginator={true}
                                                rows={10}
                                                rowsPerPageOptions={[10, 20, 30]}
                                            >
                                                <Column field="trxDate" header="Date & Time" className="table-datetime" filter={true} />
                                                <Column field="productInfoDTO.productName" header="Message Type" filter={true} />
                                                <Column field="quantity" header="Message Quantity" body={this.msgQuantityBody} filter={true} />
                                                <Column field="productPurchaseLogDTO.totalAmount" className="text-right" header="Payable Amount (with VAT)" body={this.payableAmountTemplate} filter={true} />
                                            </DataTable>
                                            <div className="p-col-12 nw-button-parent" style={{paddingRight:"0px"}}>
                                                <Button
                                                    label="Download PDF"
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                    icon="fas fa-file-pdf"
                                                    onClick={this.exportPdf}
                                                />
                                            </div>
                                        </div>
                                    :
                                    <BlankDataTableAnim />
                            }
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}