import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { SyllabusInfoList } from './SyllabusInfoList';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService'
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';


let errors = {};
let urlId;
let cloneStateBeforeMount;
export class SyllabusInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            syllabusInfo: {
                syllabusSerial: '',
                classCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                groupCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                coreUrlInfoDTO: {
                    urlInfoID: 1
                }

            },
            proPic: {
                extention: '',
                contentPic: '',
                contentName: ''
            },
            syllabusInfoError: {},
            classDropDownList: null,
            groupDropDownList: null,
            dataTableIsLoading: false,
            firstDropDownIsLoading: false,
            secondDropDownIsLoading: false,
            totalDataFound: 0
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();

    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            let { coreUrlInfoDTO } = this.state.syllabusInfo
            coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ coreUrlInfoDTO })
            this.setState({ homeReturnButton: false })
            this.getClassOrGroupListByDefaultCode('T108');
            this.getClassOrGroupListByDefaultCode('T109');

        }
        else {
            this.setState({ homeReturnButton: true })
        }

        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    onChangeSyllabusSerial = (e) => {
        let { syllabusInfo } = this.state;
        syllabusInfo.syllabusSerial = this.state.totalDataFound + 1;
        this.setState({ syllabusInfo, syllabusInfoError: {} });
    }

    onChangeClass = (e) => {
        let { syllabusInfo } = this.state;
        syllabusInfo.classCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ syllabusInfo, syllabusInfoError: {} });
    }

    onChangeGroup = (e) => {
        let { syllabusInfo } = this.state;
        syllabusInfo.groupCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ syllabusInfo, syllabusInfoError: {} });
    }

    getClassOrGroupListByDefaultCode(defaultCode) {
        this.setState({ firstDropDownIsLoading: true, secondDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        if (defaultCode === 'T108') {
                            this.setState({ classDropDownList: body });
                        }
                        else if (defaultCode === 'T109') {
                            this.setState({ groupDropDownList: body });
                        }
                        this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp.message })
                        });
                }
            }).catch(error =>
                this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }

    onSubmitHandler = () => {
        let { syllabusInfo, totalDataFound } = this.state;


        if (this.state.proPic.contentPic !== null) {
            syllabusInfo.syllabusFileContent = this.state.proPic.contentPic;
            syllabusInfo.syllabusFileSaveOrEditable = true;
            syllabusInfo.syllabusSerial = totalDataFound + 1;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            // syllabusInfo.syllabusFileName = Date.now() + extention;
            syllabusInfo.syllabusFileName = this.state.proPic.contentName;
        }

        if (this.syllabusSaveFormHandleError()) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            this.DwsService.createDwsSyllabusInfo(syllabusInfo)
                .then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Saved" });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        let { syllabusInfo } = this.state;
                        syllabusInfo.syllabusSerial = syllabusInfo.classCategoryInfoDTO.coreCategoryID = syllabusInfo.groupCategoryInfoDTO.coreCategoryID = ''

                        this.setState({
                            syllabusInfo,
                            proPic: cloneStateBeforeMount.proPic
                        });
                    } else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
                            });

                    }
                }).catch(error => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                });

        }

    }

    onImageUpload(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album, syllabusInfoError: {} });

        }
    }

    countTableSize() {
        const { dataTableValue } = this.state;
        return dataTableValue.length;
    }

    syllabusSaveFormHandleError = () => {
        let { syllabusInfoError } = this.state;
        let formIsValid = true;

        // if (this.state.syllabusInfo.syllabusSerial === '' || this.state.syllabusInfo.syllabusSerial === null) {
        //     formIsValid = false;
        //     syllabusInfoError["serialNumber"] = "Serial number can't left empty.";
        // } 
        if (this.state.syllabusInfo.classCategoryInfoDTO.coreCategoryID === '' || this.state.syllabusInfo.classCategoryInfoDTO.coreCategoryID === null) {
            formIsValid = false;
            syllabusInfoError["class"] = "Class can't left empty.";
        }
        if (this.state.syllabusInfo.groupCategoryInfoDTO.coreCategoryID === '' || this.state.syllabusInfo.groupCategoryInfoDTO.coreCategoryID === null) {
            formIsValid = false;
            syllabusInfoError["group"] = "Group can't left empty.";
        }
        if (this.state.proPic.contentName === '') {
            formIsValid = false;
            syllabusInfoError["attachment"] = "Attachment file missing.";
        }

        this.setState({ syllabusInfoError });
        return formIsValid;
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { syllabusInfo } = this.state;

        let classList = [];
        if ((this.state.classDropDownList != null) && (this.state.classDropDownList.length > 0)) {
            classList = this.state.classDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let groupList = [];
        if ((this.state.groupDropDownList != null) && (this.state.groupDropDownList.length > 0)) {
            groupList = this.state.groupDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let syllabusSerialNo = [];
        (getSyllabusSerialFieldData => {
            for (let i = 0; i < 50; i++) {
                syllabusSerialNo[i] = { label: i + 1, value: i + 1 }
            }
        })()

        // console.log('Serial', syllabusInfo.syllabusSerial)

        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                {this.state.homeReturnButton === false ?
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">
                                <Growl ref={(el) => this.growl = el} />

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Serial No.</span>
                                        <div className="nw-inputgroup-desc">
                                            {this.state.totalDataFound ? (this.state.totalDataFound + 1) : 1}
                                        </div>
                                    </div>
                                    <span className="error-message">{this.state.syllabusInfoError["serialNumber"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.firstDropDownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Class <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Class"
                                                value={syllabusInfo.classCategoryInfoDTO.coreCategoryID}
                                                options={classList}
                                                onChange={this.onChangeClass}
                                                // itemTemplate={this.bankTemplate}
                                                filter={true}
                                                filterBy="label,value"
                                                showClear={true}
                                                autoWidth={false}
                                                name="class"
                                            />
                                        </div>
                                    }
                                    <span className="error-message">{this.state.syllabusInfoError["class"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.secondDropDownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Group <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Group"
                                                value={syllabusInfo.groupCategoryInfoDTO.coreCategoryID}
                                                options={groupList}
                                                onChange={this.onChangeGroup}
                                                // itemTemplate={this.bankTemplate}
                                                filter={true}
                                                filterBy="label,value"
                                                showClear={true}
                                                autoWidth={false}
                                                name="group"
                                            />
                                        </div>
                                    }
                                    <span className="error-message">{this.state.syllabusInfoError["group"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12  nw-upload-button">
                                    <FileUpload
                                        chooseLabel="Upload File"
                                        id="Attachments"
                                        mode="basic"
                                        // accept="image/*"
                                        maxFileSize={1000000}
                                        onSelect={this.onImageUpload.bind(this)}
                                        auto={true}
                                        invalidFileSizeMessageSummary="{0}: Invalid file size, "
                                    />
                                    <center>{this.state.syllabusInfoError["attachment"] !== '' && this.state.proPic.contentName}</center>
                                    {/* <label style={{ marginLeft: "10%", marginBottom:"1em", position: "absolute", width:"22vw", wordWrap:"break-word"}}>{this.state.syllabusInfoError["attachment"] !== '' && this.state.proPic.contentName}</label>     */}

                                    <center><span className="error-message">{this.state.syllabusInfoError["attachment"]}</span></center>
                                </div>

                                <div className="p-col-12 p-xl-12" />


                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                            </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-left"
                                        label="Save"
                                        icon="fas fa-check"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                                
                            </div>
                        </div>
                        <div className="p-col-12 p-xl-12 nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <SyllabusInfoList
                                    countTableSize={(l) => this.setState({ totalDataFound: l })}
                                    classList={classList}
                                    groupList={groupList}
                                />
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>
                    </div>
                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}