import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';

import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Workbook } from 'react-excel-workbook';
import { pdfDownloadPortrait_FullTable } from '../../common/JsPdfDownload';
import { SplitButton } from 'primereact/splitbutton';

//pdf
let subTitleColumn = [];
let subTitleList = [];
export class MonthlyTransferAmountList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            searchObj: { ...props.location.searchObj },
            totalCount: 0,
            totalTransferAmount: 0,

            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportTransferPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]

        }

        this.managementPointService = new ManagementPointService();
        this.ValidatorUtility = new ValidatorUtility();

        this.exportTransferPdf = this.exportTransferPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    componentWillMount() {

        if (this.props.location.searchObj) {
            let transferSearchInfo = this.props.location.searchObj
            localStorage.setItem("transferSearchInfo", JSON.stringify(transferSearchInfo));
        }
        this.getMonthlyTransferAmountList();
    }

    getMonthlyTransferAmountList = () => {
        const transferSearchInfo = JSON.parse(localStorage.getItem("transferSearchInfo"));
        if (!transferSearchInfo) {
            return;
        }
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.managementPointService.fetchMonthlyBalanceTransactionListByType(transferSearchInfo.urlType, transferSearchInfo.year, transferSearchInfo.month, transferSearchInfo.type)
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        body.map(item => {
                            item.date = NetiDateUtils.getAnyShortForm(item.date, 'DD-MMM-YYYY hh:mm:ss a');
                        })
                        this.setState({ dataTableValue: body });

                        this.setState({
                            totalCount: body.map(item => item.count).reduce((a, b) => (a + b)),
                            totalTransferAmount: body.map(item => item.amount).reduce((a, b) => (a + b))
                        })

                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    })
                } else if (res.status == 404) {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    transferTemplate = (rowData) => {
        return <div className="col-number text-right" >
            {this.ValidatorUtility.currencyFormatter(rowData.amount)}
        </div>
    }

    exportTransferPdf() {

        let pdfColumns = [
            { title: "Date & Time", dataKey: "date" },
            { title: "Transfer Time", dataKey: "count" },
            { title: "Transfer Amount", dataKey: "amount" },

        ];

        let tableColStyle = { amount: { halign: 'right', cellWidth: 75.5 }, count: { cellWidth: 60.2 }, date: { cellWidth: 45.5 } }

        var totalArrayList = [
            {
                id: "1",
                totalPdf: "Total",
                totalAmount1: this.state.totalCount,
                totalAmount2: this.state.totalTransferAmount,
            }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }, { dataKey: "totalAmount1" }, { dataKey: "totalAmount2" }];

        let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, cellWidth: 45.5, fontStyle: "bold", overflow: "linebreak" },
            totalAmount1: { halign: "center", fontStyle: "bold", fontSize: 10, cellWidth: 60.2 },
            totalAmount2: { halign: "center", fontSize: 10, fontStyle: "bold", cellWidth: 75.5 },
        }

        pdfDownloadPortrait_FullTable("Transfer List (Month Wise)", 70, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableColStyle, totalColumns, totalArrayList, totalColumnStyles, "TransferList(Monthwise)__Report.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalDepositAmount } = this.state;

        // pdf
        let transferSearchInfo = JSON.parse(localStorage.getItem("transferSearchInfo"));

        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Year", B: transferSearchInfo.year, C: "Month", D: transferSearchInfo.month }
        ]
        // pdf

        // excel
        let excelColumns = "";
        let excelFileName = "Transfer List (Monthwise).xlsx";
        let excelSheetName = transferSearchInfo.month + '_' + transferSearchInfo.year + "_Transfer List";
        excelColumns = [
            <Workbook.Column value="date" label="Date & Time" />,
            <Workbook.Column value="count" label="Transfer Time" />,
            <Workbook.Column value="amount" label="Transfer Amount" />,
        ];
        // end excel

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Transfer List (Month Wise)</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={1} />
                <Column footer={this.state.totalCount} style={{ textAlign: "left" }} />
                <Column footer={this.ValidatorUtility.currencyFormatter(this.state.totalTransferAmount)} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className='main-section'>

                    <div className="p-grid">
                        {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }

                        <div className="p-col-12 p-lg-12 p-xl-12">
                            <div className="nw-data-table">
                                {/* {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                                <DataTable
                                    header={tableHeader}
                                    footerColumnGroup={footer}
                                    responsive={true}
                                    selectionMode="single"
                                    paginator={true}
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    value={this.state.dataTableValue}
                                //selection={this.state.dataTableSelection}
                                >
                                    <Column field="date" header="Date & Time" className="table-datetime" filter={true} />
                                    <Column field="count" header="Transfer Time" filter={true} />
                                    <Column field="amount" header="Transfer Amount" body={this.transferTemplate} filter={true} />

                                </DataTable>

                                {/* :
                                    
                                    <BlankDataTableAnim/> 
                            } */}
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12 nw-button-parent">

                            <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                            <div style={{ display: 'none' }}>

                                <Workbook
                                    filename={excelFileName}
                                    element={
                                        <Button
                                            label="Download Excel"
                                            id="clickExcelBtn"
                                            icon="fas fa-download"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                        />
                                    }
                                >
                                    <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                        {excelColumns}
                                    </Workbook.Sheet>
                                </Workbook>

                            </div>

                        </div >

                    </div>
                </div>
            </div>
        )
    }
}