import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { DwsService } from "../../../service/dwsPoint/DwsService";

let urlId;
export class ImportantLinkUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkSubmitError: [],
      linkInformation: {
        linkCreateDate: '',
        linkID: '',
        linkSerial: '',
        linkTitle: '',
        linkUrl: '',
        topLink: '',
        urlInfoDTO: {
          urlInfoID: ''
        }
      },
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',
      homeReturnButton: false,
    }
    this.DwsService = new DwsService();
    this.reloadCoreUrlIdFromLocal();
  }

  reloadCoreUrlIdFromLocal() {
    urlId = this.DwsService.getUrlInfoFromLocalStorage();
    if (!urlId) {
        setTimeout(() => { 
            this.componentWillMount();
        }, 2000);
    }
}

  componentWillMount() {
    let { linkInformation } = this.state;
    urlId = this.DwsService.getUrlInfoFromLocalStorage();
    if (urlId) {
      this.setState({ homeReturnButton: false });
      let { linkInformation } = this.state;
      linkInformation.urlInfoDTO.urlInfoID = urlId;
      this.setState({ linkInformation });
      this.setState({ linkInformation: this.props.linkInformation });
    } else {
      this.setState({ homeReturnButton: true });
    }
  }

  linkTitleHandler = (event, props) => {
    let { linkInformation, linkSubmitError } = this.state;
    linkSubmitError["linkTitle"]=''
    this.props.linkInformation.linkTitle = event.target.value;
    linkInformation.linkTitle = this.props.linkInformation.linkTitle;
    this.setState({ linkInformation, linkSubmitError });
  };

  linkUrlHandler = (event, props) => {
    let { linkInformation, linkSubmitError } = this.state;
    linkSubmitError["linkUrl"]=''
    this.props.linkInformation.linkUrl = event.target.value;
    linkInformation.linkUrl = this.props.linkInformation.linkUrl;
    this.setState({ linkInformation, linkSubmitError});
  };

  updateHandler = (event, props) => {
    let { linkInformation } = this.state;
    if (this.errorHandler()) {
      this.DwsService.updateImportantLink(linkInformation).then(res => {

        if (res.status == 202) {
          this.growl.show({
            severity: "success",
            summary: "Success Message",
            detail: "Link Update successfully",
            life: 800
          });
          setTimeout(() => {
            this.props.turnOffDialog();
          }, 800);
          this.props.viewImportantLinkList();
          this.props.viewSelectedImportantLinkList();
        } else {
          this.DwsService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ errorMsgVisible: true, errorMsgBody: resp })
            });
        }
      }).catch(error => {
        this.setState({ errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
    });
    }
  };

  errorHandler() {
    let { linkInformation, linkSubmitError } = this.state;
    let formIsValid = true;
    if (this.props.linkInformation.linkTitle === "") {
      formIsValid = false;
      linkSubmitError["linkTitle"] = "Link title can't left empty";
    }
    if (this.props.linkInformation.linkUrl === "") {
      formIsValid = false;
      linkSubmitError["linkUrl"] = "Link URL can't left empty";
    }
    this.setState({ linkSubmitError });
    return formIsValid;
  }

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    return (
     
      <div className="p-grid nw-form">
        <Growl ref={el => (this.growl = el)} position="topright" />
            
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
          <div className="p-col-12 nw-form-body">

                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Link Title <span>*</span></span>
                    <InputText
                      value={this.props.linkInformation.linkTitle}
                      className="custom-form-control"
                      placeholder="Enter Link Title"
                      onChange={e => this.linkTitleHandler(e, this.props)}
                      id="linkTitle"
                    />
                  </div>
                  <span className="error-message">{this.state.linkSubmitError["linkTitle"]}</span>
                </div>

                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Link URL <span>*</span></span>
                    <InputText
                      value={this.props.linkInformation.linkUrl}
                      className="custom-form-control"
                      id="linkUrl"
                      onChange={e => this.linkUrlHandler(e, this.props)}
                      name="linkUrl"
                      placeholder="Enter Link URL"
                    />
                  </div>
                  <span className="error-message">{this.state.linkSubmitError["linkUrl"]}</span>
                </div>

                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                  <Button
                    className="p-button p-button-danger nw-button nw-button-multiple"
                    label="Discard"
                    icon="fas fa-times"
                    onClick={this.props.turnOffDialog}
                  />

                  <Button
                    label="Update"
                    icon="fas fa-check"
                    className="p-button p-button-primary nw-button nw-button-multiple"
                    onClick={e => this.updateHandler(e, this.props)}
                  />
                </div>
              </div>
            </div>



         
    );
  }
}
