import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import NetiContentLoader from '../common/NetiContentLoader';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { pdfDownloadPortrait_TableOnly } from '../common/JsPdfDownload';

export class PartnerYssConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberStatus: '',
            deviceDetails: {},
            dataTableValue: [],
            instituteFormError: {},
            deviceInfo: {
                deviceSerial: '',
                deviceIdentification: '',
                userRegistrationDTO: {
                    userID: ''
                },
                activationCode: ''
            },
            yssConfigRequestInfo: {
                instituteInfoDTO: {
                    emDetailsId: ''
                }
            },
            payabaleInstituteList: [],
            instituteList: [],
            payableInstituteID: '',
            payableInstituteInfo: {},
            errorMsgBody: null,
            yssDeviceData: [
                { "orderDate": "Netiworld", "orderStatus": '4.0', "urlInfo": "30-06-2019", "desireUrl": '1', "domain": "30-06-2019", "hosting": 'new version' },
                { "orderDate": "Eduman", "orderStatus": '5.1', "urlInfo": "03-05-2019", "desireUrl": '2', "domain": "30-06-2019", "hosting": 'new version' },
                { "orderDate": "Eduman", "orderStatus": '5.1', "urlInfo": "03-05-2019", "desireUrl": '3', "domain": "30-06-2019", "hosting": 'new version' },
            ],
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
        }
        this.partnerPointService = new PartnerPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.exportPdf = this.exportPdf.bind(this);

    }

    componentWillMount() {
        this.viewYssDeviceInfoList();
        this.allInstituteList();
    }
    viewYssDeviceInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.partnerPointService.getYssPartnerPointsAllinstitute(5, 0)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].yssExpireDate == null) {
                                body[i].yssExpireDate = '';
                            } else {
                                body[i].yssExpireDate = NetiDateUtils.getAnyShortForm(body[i].yssExpireDate, 'DD-MMM-YYYY');
                            }
                        }
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    getPayableInstituteList = () => {
        this.setState({ topProgressBar: true });
        this.partnerPointService.getInstituteByPartner()
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {

                        this.setState({ payabaleInstituteList: body, topProgressBar: false });
                    
                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });

            });

    }

    onChangePayableInstitute = (event) => {
        let { yssConfigRequestInfo, payabaleInstituteList, payableInstituteInfo, instituteFormError } = this.state;
        this.setState({ payableInstituteInfo });
        for (let i = 0; i < payabaleInstituteList.length; i++) {
            if (payabaleInstituteList[i].emDetailsId == event.target.value) {
                payableInstituteInfo = payabaleInstituteList[i];
                yssConfigRequestInfo.instituteInfoDTO.emDetailsId = event.target.value;
                this.setState({ payableInstituteInfo });
                this.setState({ yssConfigRequestInfo });
            }
            else if (event.target.value === null) {
                this.setState({
                    yssConfigRequestInfo: {
                        purchaseCode: '',
                        instituteInfoDTO: {
                            emDetailsId: ''
                        }
                    },
                    payableInstituteInfo: {}
                })
            }
        }

        instituteFormError['payableInstitute'] = ''
        this.setState({ instituteFormError })

    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    onHideInstitute = (event) => {
        this.setState({ instituteVisible: false });
    }

    onChangeDevice = (rowData) => {
        return <div>
            <span onClick={this.onChangeViewDevice}>{rowData.desireUrl}</span>
        </div>;

    }

    onHideYssDevice = (event) => {
        this.setState({ viewYssDevice: false });
    }

    onChangeViewDevice = () => {
        this.setState({ viewYssDevice: true });
    }
    componentDidMount() {
        // this.allInstituteList();
    }
    allInstituteList = () => {
        this.setState({ topProgressBar: true });
        this.partnerPointService.getInstitute()
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ instituteList: body, topProgressBar: false });
                    
                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });

            });
    }
    viewInstituteDialog = () => {
        this.getPayableInstituteList();
        this.setState({ instituteVisible: true });
    }

    onChangeAddInstitute = () => {
        this.viewInstituteDialog();
    }

    onChangePurchaseCode = (e) => {
        let { instituteFormError, yssConfigRequestInfo } = this.state;
        yssConfigRequestInfo.purchaseCode = e.target.value;
        this.setState({ yssConfigRequestInfo });
        instituteFormError["purchaseCode"] = '';
        this.setState({ instituteFormError });
    }

    onSubmitHandler = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        let { yssConfigRequestInfo } = this.state;
        if (this.addInstituteFormError()) {
            this.partnerPointService.createPartnerYssConfig1(yssConfigRequestInfo)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 201) {
                        this.onHideInstitute();
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: false });
                    this.growl.show({ severity: 'warn', summary: 'Warning', detail: 'Connection Problem' });
                });
        }

    }

    addInstituteFormError = () => {
        let { instituteFormError, yssConfigRequestInfo } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        // if (yssConfigRequestInfo.purchaseCode === '') {
        //     formIsValid = false;
        //     instituteFormError["purchaseCode"] = "Purchase Code can't left empty.";
        // }
        if (!yssConfigRequestInfo.instituteInfoDTO.emDetailsId) {
            formIsValid = false;
            instituteFormError["payableInstitute"] = "Institute can't left empty.";
        }
        this.setState({ instituteFormError });
        return formIsValid;
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Institute ID", dataKey: "instituteID" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "YSS Expiry Date", dataKey: "yssExpireDate" },
        ]

        let tableColumnStyle = {}

        pdfDownloadPortrait_TableOnly('Device Institute List',80, pdfColumns, this.state.dataTableValue, tableColumnStyle, "device_institute_list.pdf");

    }

    render() {
       
        let { topProgressBar, yssConfigRequestInfo, errorMsgVisible, errorMsgBody, dataTableValue,instituteList } = this.state;

      
        let tableHeader = <div className="header-title">
            <div className="header-title-left">Institute List</div>
            <div className="header-title-right">
                <span>
                    Total Found: {this.state.dataTableValue.length}
                </span>

                <Button
                    label="Add Institute"
                    icon="fas fa-plus"
                    className="p-button p-button-primary nw-button nw-button-right"
                    onClick={this.onChangeAddInstitute}
                    iconPos="right"
                />
            </div>
        </div>;

        var yssDeviceHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            YSS Device List
            <span style={{ 'float': 'right' }}>Total Found: {this.state.payabaleInstituteList.length} </span>
        </div>;

        let payabaleInstitute = [];
        if ((this.state.payabaleInstituteList != null) && (this.state.payabaleInstituteList.length > 0)) {
            payabaleInstitute = this.state.payabaleInstituteList.map((item) => ({
                value: item.emDetailsId,
                label: `${`${item.instituteName} (${item.instituteId})`}`,
            }));
        }

        return (
            <div className="p-fluid">
                 <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid">

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    columnResizeMode="fit"
                                >
                                    <Column field="instituteID" header="Institute ID" filter={true} />
                                    <Column field="instituteName" header="Institute Name" filter={true} />
                                    <Column field="yssExpireDate" header="YSS Expiry Date" filter={true} />     
                                </DataTable>
                            }
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12 nw-button-parent">
                        <Button
                            className="p-button p-button-primary nw-button nw-button-right"
                            label="Download PDF"
                            icon="fas fa-download"
                            onClick={this.exportPdf}
                        />
                    </div>

                    {/* <Dialog className="nw-dialog" header="YSS Config" visible={this.state.viewYssDevice} onHide={this.onHideYssDevice} closable>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <DataTable
                                        value={this.state.yssDeviceData}
                                        selectionMode="single"
                                        header={yssDeviceHeader}
                                        selection={this.state.dataTableSelection}
                                        onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                        responsive={true}
                                        rows={10}
                                        columnResizeMode="fit"
                                    >
                                        <Column field="orderDate" header="Serial" />
                                        <Column field="orderStatus" header="Add Date" />
                                        <Column field="urlInfo" header="Device ID" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </Dialog> */}

                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        header="Add Institute"
                        visible={this.state.instituteVisible}
                        onHide={this.onHideInstitute}
                        maximizable
                        closable>
                        <div className="nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Purchase Code</span>
                                            <InputText
                                                value={yssConfigRequestInfo.purchaseCode}
                                                onChange={this.onChangePurchaseCode}
                                                placeholder="Enter Purchase Code"
                                                name="purchaseCode"
                                                showClear={true}
                                                style={{ width: "100%" }}
                                                type="text"
                                                keyfilter="pint"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.instituteFormError["purchaseCode"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Institute <span>*</span></span>
                                            <Dropdown
                                                value={yssConfigRequestInfo.instituteInfoDTO.emDetailsId}
                                                options={payabaleInstitute}
                                                onChange={this.onChangePayableInstitute}
                                                placeholder="Select Institute"
                                                showClear={true}
                                                autoWidth={false}
                                                name="payableInstitute"
                                                filterBy="value"
                                                filter
                                            />
                                        </div>
                                        <span className="error-message">{this.state.instituteFormError["payableInstitute"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            label="Save"
                                            icon="fas fa-check"
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            onClick={this.onSubmitHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>
            </div>
        );
    }
}