import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { TreeTable } from 'primereact/treetable';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { PartnerPointService } from '../../../service/partnerPoint/PartnerPointService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import CommonFuctionality from '../../common/CommonFuctionality';

let assignedPartnerListArr = [];
let status = { display: 'table-cell' };
let profile;

export class PartnerAreaWiseProductList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableView: false,
            onExpander: true,
            searchObj: {
                netiID: ""
            },
            assignedPartnerList: [],
            productTypeErr: {},
            productType: '',
            dataTableIsLoading: false,
            mbpListObj: {},
            zbpListObj: {},
            bdpListObj: {},
            assignListObj: {},
            assignListArr: [],
            expandedKeys: {},
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            first: 0,
            rows: 10,
            totalRecords: 0,
            loading: false
        }

        this.managementPointService = new ManagementPointService;
        this.partnerPointService = new PartnerPointService;
        this.onExpand = this.onExpand.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.commonFuctionality = new CommonFuctionality();
    }

    onSearchHandler = () => {
        let { assignListObj, searchObj } = this.state;
        assignedPartnerListArr = []
        this.searchProductTypeWiseReport(null, assignListObj, false)
    }

    searchProductTypeWiseReport(lazyNode, objectName, isLeaf) {
        let { searchObj } = this.state;
        profile = JSON.parse(localStorage.getItem('Profile'));

        searchObj.netiID = (lazyNode != null ? lazyNode.data.netiID : profile.netiID)
        this.setState({ searchObj });

        if (this.searchErrorHandler()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, tableView: true, });
            this.partnerPointService.findProductTypeWiseReport(searchObj.netiID, this.state.productType)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            if (lazyNode && lazyNode.children.length === 0) {
                                body.map((item, i) => {
                                    objectName.data = item;
                                    objectName.children = [];
                                    objectName.leaf = isLeaf
                                    objectName.key = `${lazyNode['key']}-${i}`
                                    lazyNode.children.push(Object.assign({}, objectName))
                                })
                            }
                            else if (assignedPartnerListArr.length === 0) {
                                body.map((item, i) => {
                                    if (item.designation === "BEP") {
                                        this.setState({ onExpander: false });
                                    }
                                    objectName.data = item;
                                    objectName.children = []
                                    objectName.leaf = isLeaf
                                    objectName.key = i
                                    assignedPartnerListArr.push(Object.assign({}, objectName))
                                })
                                if (this.state.productType == "Eduman") {
                                    status = { display: 'table-cell' }
                                } else {
                                    status = { display: 'none' }
                                }
                            }

                            this.setState({ dataTableIsLoading: false, tableView: true, errorMsgVisible: false, topProgressBar: false });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection.' });
                });

            setTimeout(() => {
                this.commonFuctionality.setCustomizedTreeDatatable('pType', assignedPartnerListArr && assignedPartnerListArr[0] && assignedPartnerListArr[0].data && assignedPartnerListArr[0].data.designation);
            }, 500);
        }

    }

    onExpand(event) {
        let { mbpListObj, zbpListObj, bdpListObj } = this.state;

        let lazyNode = { ...event.node };
        if (lazyNode.data.designation === "MBP") {
            this.searchProductTypeWiseReport(lazyNode, mbpListObj, false)
        }
        else if (lazyNode.data.designation === "ZBP") {
            this.searchProductTypeWiseReport(lazyNode, zbpListObj, false)
        }
        else if (lazyNode.data.designation === "BDP") {
            this.searchProductTypeWiseReport(lazyNode, bdpListObj, true)
        }
    }

    searchErrorHandler = () => {
        let { productTypeErr } = this.state;
        let formIsValid = true;
        if (this.state.productType === '') {
            formIsValid = false;
            productTypeErr["productType"] = 'Product Type can not left empty.';
        }
        this.setState({ productTypeErr });
        return formIsValid;
    }

    onExpand(event) {
        let { mbpListObj, zbpListObj, bdpListObj, assignListObj } = this.state;
        this.setState({ mbpListObj: {} });
        this.setState({ zbpListObj: {} });
        this.setState({ bdpListObj: {} });
        let lazyNode = { ...event.node };
        if (lazyNode.data.designation === "MBP") {
            if (lazyNode.children.length === 0) {
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.partnerPointService.findProductTypeWiseReport(lazyNode.data.netiID, this.state.productType).then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            body.map((item, i) => {
                                mbpListObj.data = item;
                                mbpListObj.key = `${lazyNode['key']}-${i}`;
                                mbpListObj.children = [];
                                mbpListObj.leaf = false;
                                lazyNode.children.push(Object.assign({}, mbpListObj));
                            });
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
            }
        }

        else if (lazyNode.data.designation === "ZBP") {
            if (lazyNode.children.length === 0) {
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.partnerPointService.findProductTypeWiseReport(lazyNode.data.netiID, this.state.productType).then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            body.map((item, i) => {
                                zbpListObj.data = item;
                                zbpListObj.key = `${lazyNode['key']}-${i}`;
                                zbpListObj.children = [];
                                zbpListObj.leaf = false;
                                lazyNode.children.push(Object.assign({}, zbpListObj));
                            });

                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
            }
        }

        else if (lazyNode.data.designation === "BDP") {
            if (lazyNode.children.length === 0) {
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.partnerPointService.findProductTypeWiseReport(lazyNode.data.netiID, this.state.productType).then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            body.map((item, i) => {
                                bdpListObj.data = item;
                                bdpListObj.key = `${lazyNode['key']}-${i}`;
                                lazyNode.children.push(Object.assign({}, bdpListObj));
                            });
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
            }
        }

        setTimeout(() => {
            this.commonFuctionality.setCustomizedTreeDatatable('pType', assignedPartnerListArr && assignedPartnerListArr[0] && assignedPartnerListArr[0].data && assignedPartnerListArr[0].data.designation);
        }, 500);

    }

    onExpandToggle = (e) => {
        this.setState({ expandedKeys: e.value })
    }

    onChangeStatus = (rowData) => {
        return (<span>{rowData.data["partnerStatus"] === 1 ? "Active" : "Inactive"}</span>);
    }

    onChangeProductType = e => {
        
        this.setState({ productType: e.target.value, productTypeErr: "" });
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let header = <div className="header-title">
            <div className="header-title-left">Product Purchase List</div>
        </div>;
        
        let productTypeList = [
            { label: 'Eduman', value: 'Eduman' },
            { label: 'Admisia', value: 'Admisia' },
            { label: 'Neti CMS', value: 'Neti_CMS' },
            { label: 'Device', value: 'Device' }
        ]

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-10">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Product Type</span>
                                        <Dropdown
                                            placeholder="Select Product Type"
                                            value={this.state.productType}
                                            options={productTypeList}
                                            onChange={this.onChangeProductType}
                                            showClear={false}
                                            autoWidth={false}
                                            name="productType"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.productTypeErr["productType"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-2 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSearchHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-12 p-xl-12 p-col-nogutter nw-data-table">
                            {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <TreeTable
                                            header={header}
                                            lazy={true}
                                            value={assignedPartnerListArr}
                                            onExpand={this.onExpand}
                                            expandedKeys={this.state.expandedKeys}
                                            onToggle={this.onExpandToggle}
                                            style={{ marginTop: '.5em' }}>
                                            <Column field="designation" header="Partner Type" className='pType' expander={this.state.onExpander} />
                                            <Column field="area" header="Area" />
                                            <Column field="customNetiID" header="Neti ID" />
                                            <Column field="name" header="Name" />
                                            <Column field="quantity" header="Quantity" />
                                            <Column field="activeID" header="Active ID" style={status} />
                                            <Column field="inActiveID" header="Inactive ID" style={status} />
                                        </TreeTable>
                                    :
                                    <BlankDataTableAnim />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}