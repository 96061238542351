import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import { Growl } from 'primereact/growl';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import NetiContentLoader from '../../myPoint/common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import {NetiDateUtils} from '../../../utils/NetiDateUtils';
import { AdmisiaApplicantViewInfo } from '../AdmisiaApplicantViewInfo';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';

let errors = {}
export class AssessmentPendingApplicantList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rejectedStutas: "Custom Status",
            dataTableSelection: null,
            dataViewValue: [],
            examInfoValue: [],
            dataTableValue: props.componentNameData,
            updateForApproveAssessmentObj: {},
            applicantDetailObj: {
                admisiaApplicantInfoDTO:{
                    registrationID: '',
                    applicationDate:'',
                    applicantName:'',
                    dateOfBirth:'',
                    mobileNo:'',
                    addressDetails:'',
                    addressCoreCategoryInfoDTO:{
                        categoryName: ''
                    },
                    fatherName:'',
                    motherName:'',
                    fatherOccupationCategoryInfoDTO:{
                        categoryName:''
                    },
                    motherOccupationCategoryInfoDTO:{
                        categoryName:''
                    },
                    admisiaClassConfigDTO:{
                        classCoreCategoryInfoDTO:{
                            categoryName:''
                        },
                        groupCoreCategoryInfoDTO:{
                            categoryName:''
                        }
                    },
                    genderCoreCategoryInfoDTO:{
                        categoryName:''
                    },
                    religionCoreCategoryInfoDTO:{
                        categoryName:''
                    }
                }
            },
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible:false,
            errorMsgBody: null,
            errors:{}
        };

        this.admisiaPointService=new AdmisiaPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.netiFileHandler = new NetiFileHandler();
        this.onSubmitApproveForAssessment = this.onSubmitApproveForAssessment.bind(this)
        this.onSubmitRejectForAssessment = this.onSubmitRejectForAssessment.bind(this)
       
    }

    // dateFormatTemplet = (rowData) =>{
    //     return <span>{NetiDateUtils.getAnyShortForm(rowData['requestDate'], 'DD-MMM-YYYY')}</span>;
    // }

    statusBodyTemplete = (rowData) => {
                return <center><Button
                    className="nw-action-button info"
                    icon="far fa-eye"
                    title="View Purchase"
                    onClick={(e) => this.viewDialog(rowData)}
                />
                </center>
        
    }

    viewDialog = (rowData) => {
        this.setState({ visible: true });

        this.getApplicantDetailInfo( rowData.admisiaApplicantInfoDTO.admisiaApplicantInfoId )
    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    getProfileImg = () => {
        if (this.state.applicantDetailObj.admisiaApplicantInfoDTO.photoPath !== null) {
            this.netiFileHandler.getByteImage(this.state.applicantDetailObj.admisiaApplicantInfoDTO.photoPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(this.state.applicantDetailObj.admisiaApplicantInfoDTO.photoName);
                                this.setState({ applicantPicture: contentType + body.fileContent });
                                console.log("applicantPicture", this.state.applicantPicture);
                                
                            })
                    } else {
                        return res.json()
                            .then((body) => {
                                console.log("image error", body);
                            })
                    }
                });
        }

    }

    getApplicantDetailInfo(applicantID){
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.admisiaPointService.getApplicantInfoByID(applicantID)
                .then(res => {
                    if(res.status == 302) {
                        return res.json().then((body) => {
                        this.setState({ applicantDetailObj:  body});
                        console.log("Body99", body);
                        
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                        setTimeout(() => { 
                          this.getExamInfoList();
                        }, 1000);

                        setTimeout(() => { 
                          this.getProfileImg();

                        }, 2000);

                        })
                    }
                    else{
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp)=>{
                            this.setState({dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                        });
                    }
                }).catch((error) => {
                    this.setState({dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                });
    }



    getExamInfoList = () => {
        
        this.admisiaPointService.getPreviousInfoByID(this.state.applicantDetailObj.admisiaApplicantInfoDTO.admisiaApplicantInfoId)  
        .then(res => {
            this.setState({ topProgressBar: false });
            if (res.status == 302) {
                return res.json().then((body) => {
                    
                    console.log("ExamInfoList3333", body);
                    this.setState({ examInfoValue: body });  
                    
                });
            } else {
                this.admisiaPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false });

            
        });

    }



    displaySelection(data) {
        if(!data || data.length === 0) {
            return <div style={{textAlign: 'left'}}>No Selection</div>;
        }
        else {
            errors['submitApproveError'] = ""
        }
    }

    getUpdateObj(updateStatus){
        let { dataTableSelection } = this.state

            let markMapValue = []
            if (dataTableSelection && dataTableSelection.length) {
                markMapValue = dataTableSelection.map((item) => ({
                    admisiaApplicantInfoId: item.admisiaApplicantInfoDTO.admisiaApplicantInfoId
                }));
            }
            let approveAssessmentObj = {
                "applicantInfoDTOList": markMapValue,
                "status": updateStatus
               }

        
        return approveAssessmentObj
    }

    getConnectToUpdateStatusApi(updateStatus){
        if(!this.state.dataTableSelection){
            errors['submitApproveError'] = "Please select at least one ROW"
        }
        else{
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.admisiaPointService.updateForApproveAssessment( this.getUpdateObj(updateStatus) )
                .then(res => {
                    if(res.status == 202) {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                            this.growl.show({severity: 'success', summary: 'Success', detail: ""});
                            this.props.reCallClassGroupSearch();
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp)=>{
                            this.setState({dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                        });
                    }
                }).catch((error) => {
                    this.setState({dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                });
        }
    }

    onSubmitApproveForAssessment(){
        this.getConnectToUpdateStatusApi(1)
    }

    onSubmitRejectForAssessment(){
        this.getConnectToUpdateStatusApi(2)
    }
    



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableIsLoading, applicantDetailObj } = this.state;
  
        

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                        Assessment Pending Applicant List
                        <div
                            style={{ color: "white", display: "flex", float: "right" }}
                        >
                            Total: { this.state.dataTableValue.length }
                            
                        </div>
                    </div>;
        
        return (

          <div className="p-fluid">
            <Growl
              ref={el => {
                this.growl = el;
              }}
            ></Growl>
            {topProgressBar ? (
              <ErrorMessageView topProgressBar={topProgressBar} />
            ) : null}
            {errorMsgVisible ? (
              <ErrorMessageView
                errorMsgVisible={errorMsgVisible}
                errorMsgBody={errorMsgBody}
              />
            ) : null}
            <div className="p-grid">
              <div className="nw-data-table nw-data-table-tabview">
               
                  <DataTable
                    value={this.state.dataTableValue}
                    header={tableHeader}
                    responsive={true}
                    paginator={true}
                    rows={10}
                    selection={this.state.dataTableSelection}
                    onSelectionChange={event =>
                      this.setState({ dataTableSelection: event.value })
                    }
                    footer={this.displaySelection(
                      this.state.dataTableSelection
                    )}
                  >
                    <Column selectionMode="multiple" style={{ width: "4em" }} />
                    <Column
                      field="admisiaApplicantInfoDTO.registrationID"
                      header="Registration No."
                      sortable={true}
                      filter={true}
                    />
                    <Column
                      field="admisiaApplicantInfoDTO.applicantName"
                      header="Name"
                      sortable={true}
                      filter={true}
                    />
                    <Column
                      field="admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName"
                      header="Gender"
                      sortable={true}
                      filter={true}
                    />
                    <Column
                      field="admisiaApplicantInfoDTO.mobileNo"
                      header="Mobile No."
                      sortable={true}
                      filter={true}
                    />
                    <Column
                      field="admisiaApplicantInfoDTO.applicationDate"
                      header="Application Date"
                      // body={this.dateFormatTemplet}
                      sortable={true}
                      filter={true}
                    />
                    <Column
                      field="requestStatus"
                      style={{ width: "6em" }}
                      header="Action"
                      body={this.statusBodyTemplete}
                    />
                  </DataTable>
                  
          
                <span className="error-message">
                  {errors["submitApproveError"]}
                </span>
              </div>

              <div className="p-grid p-col-12 p-xl-12">
                <div className="p-col-12 p-xl-6 nw-button-parent">
                  <Button
                    className="p-button p-button-primary nw-button nw-button-center"
                    label="Reject For Assessment"
                    icon="fas fa-times"
                    onClick={this.onSubmitRejectForAssessment}
                  />
                </div>

                <div className="p-col-12 p-xl-6 nw-button-parent">
                  <Button
                    className="p-button p-button-primary nw-button nw-button-center"
                    label="Approve For Assessment"
                    icon="fas fa-check"
                    onClick={this.onSubmitApproveForAssessment}
                  />
                </div>
              </div>

              <Dialog
                className="nw-dialog"
                header="Application Details"
                visible={this.state.visible}
                onHide={this.onHide}
                maximizable
                style = {{"width": "90%"}}
              >
                <AdmisiaApplicantViewInfo
                  applicantInfo={this.state.applicantDetailObj}
                  examInfo={this.state.examInfoValue}
                  photoInfo={this.state.applicantPicture}
                />
              </Dialog>
            </div>
          </div>
        );
    }
}