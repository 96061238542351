import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "primereact/dropdown";
import { NetiFileHandler } from '../../../utils/NetiFileHandler';


let statusListOptions = [
    { label: "Pending", value: 'pending' },
    { label: "Approve", value: 1 },
    { label: "Reject", value: 2 }
]
export class EdumanDiscountApproval extends Component {

    constructor(props) {
        super(props);
        this.state = {
            approvedAmount: '',
            rejectNote: '',
            approvalValue: 'pending',
            approvedNote: '',
            viewDetailsObject: {
                discountRequestId: '',

            },
            dataTableValue: [],
            errors: {}
        };

        this.CRMPointService = new CRMPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.netiFileHandler = new NetiFileHandler();

    }

    componentWillMount() {
        this.getDiscountApprovedList()
    }

    getDiscountApprovedList = () => {
        this.CRMPointService.fetchDiscountApprovedList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].requestDate == null) {
                                body[i].requestDate = '';
                            } else {
                                body[i].requestDate = NetiDateUtils.getAnyShortForm(body[i].requestDate, 'DD-MMM-YYYY');
                            }
                        }
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
            });
    }

    downloadexportPdf = (rowdata) => {
        console.log("insdie ss", rowdata.attachmentPath);
        // this.netiFileHandler.getByteImage(rowdata.attachmentPath);

        if (rowdata.attachmentPath !== null && rowdata.attachmentFileName !== null) {
            this.netiFileHandler.getByteImage(rowdata.attachmentPath).then(res => {
                if (res.status == 200) {
                    return res.json().then(body => {
                        let contentType = this.netiFileHandler.getImageContentType(
                            rowdata.attachmentFileName
                        );
                        let a = document.createElement("a");
                        a.href = contentType + body.fileContent;
                        a.download = rowdata.attachmentFileName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    });
                } else {
                }
            });
        }

    }
        
    detailsBody = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-edit"
                tooltip="Edit"
                onClick={(e) => this.viewDialog(rowData)}
            />
            <Button
            
                icon="fas fa-download"
                className="nw-action-button edit"
                
                onClick={(e) => this.downloadexportPdf(rowData)}
               
             />
                                    
        </div>
    }

    viewDialog = (rowData) => {
        this.setState({ visible: true, viewDetailsObject: rowData });
    }

    onHide = (event) => {
        this.setState({ visible: false });
        this.setState({ approvalValue: 'Pending', approvedNote: '', rejectNote: '', approvedAmount: '' });
    }

    onChangeApprovedNote = (e) => {
        let { errors } = this.state;
        errors["approvedNote"] = "";
        this.setState({ approvedNote: e.target.value, errors });
    }

    onChangeApprovedAmount = (e) => {
        let { errors } = this.state;
        errors["approvedAmount"] = "";
        this.setState({ approvedAmount: e.target.value, errors });

    }

    onSubmitUpdate = () => {
        console.log("rowdata", this.state.viewDetailsObject);
        let note = ''
        if (this.state.approvalValue === 1) {
            note = this.state.approvedNote
        } else if (this.state.approvalValue === 2) {
            note = this.state.rejectNote
        }

        let discountApprovedRequestInfo = {
            "discountRequestId": this.state.viewDetailsObject && this.state.viewDetailsObject.discountRequestId,
            "productPurchaseCode": {
                "productPurchaseCodeID": this.state.viewDetailsObject && this.state.viewDetailsObject.productPurchaseCodeDTO && this.state.viewDetailsObject.productPurchaseCodeDTO.productPurchaseCodeID,
            },
            "approvedNote": note,
            "approvedAmount": this.state.approvedAmount,
            "requestStatus": this.state.approvalValue
        }

        if (this.onSubmitUpdateError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.CRMPointService.updateEdumanBillDiscount(discountApprovedRequestInfo)
                .then(res => {
                    if (res.status === 202) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Submitted successfully', life: 800 })
                        setTimeout(() => {
                            this.onHide();
                        }, 800);
                        this.getDiscountApprovedList()
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        }
    }

    onSubmitUpdateError = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.approvalValue === 1) {
            if (this.state.approvedAmount === "") {
                formIsValid = false;
                errors["approvedAmount"] = "Approved Amount can't left empty.";
            }
            if (this.state.approvedNote === "") {
                formIsValid = false;
                errors["approvedNote"] = "Approved Note can't left empty.";
            }
            if (this.state.approvalValue === "") {
                formIsValid = false;
                errors["status"] = "Status can't left empty.";
            }
        }

        if (this.state.approvalValue === 2) {
            if (this.state.rejectNote === "") {
                formIsValid = false;
                errors["rejectNote"] = "Reject Note can't left empty.";
            }
        }


        this.setState({ errors });
        return formIsValid;

    }



    onChangeStatus = (e) => {
        let { errors } = this.state;
        errors["status"] = ''
        this.setState({ errors, approvalValue: e.target.value });
    }

    onChangeRejectNote = (e) => {
        let { errors } = this.state;
        errors["rejectNote"] = ''
        this.setState({ errors, rejectNote: e.target.value });
    }

    render() {
        let { viewDetailsObject, dataTableValue, errors } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="header-title">
            <div className="header-title-left">
                Pending Discount Approval List
                            </div>


            <div className="header-title-right">
                <Link to="#">
                    Total Found: {dataTableValue.length}
                </Link>
            </div>
        </div>;




        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="nw-data-table">
                        {/* {
                            this.state.tableView === true ?
                                this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                        <DataTable
                            value={dataTableValue}
                            header={tableHeader}
                            responsive={true}
                        >
                            <Column field="requestDate" header="Request Date" filter={true} />
                            <Column field="emDetailsInfoDTO.instituteId" header="Institute ID" filter={true} />
                            <Column field="emDetailsInfoDTO.instituteName" header="Institute Name" filter={true} />
                            <Column field="" header="Action" body={this.detailsBody} />
                        </DataTable>
                        {/* :
                                <BlankDataTableAnim />
                        } */}
                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog header="Bill Discount Request Info" visible={this.state.visible} className="nw-dialog" onHide={this.onHide} closable>
                        <div className="nw-search-view no-border p-0">
                            <div>
                                <div className="p-col-12 p-xl-12">
                                    <br />
                                    <div className="custom-header">
                                        <i className="fa fa-wallet"></i>
                                        <h1>Bill Discount Request Details</h1>
                                    </div>
                                </div>
                                <br />
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Request Date</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{NetiDateUtils.getAnyShortForm(viewDetailsObject.requestDate, "DD-MMM-YYYY") || "---"}</div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Institute ID</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {viewDetailsObject.emDetailsInfoDTO && viewDetailsObject.emDetailsInfoDTO.instituteId || "---"}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Institute Name</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {viewDetailsObject.emDetailsInfoDTO && viewDetailsObject.emDetailsInfoDTO.instituteName || "---"}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Institute Address</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {viewDetailsObject.emDetailsInfoDTO && viewDetailsObject.emDetailsInfoDTO.instituteAddress || "---"}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Institite Phone No.</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {viewDetailsObject.emDetailsInfoDTO && viewDetailsObject.emDetailsInfoDTO.instituteContact || "---"}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Institite Email</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {viewDetailsObject.emDetailsInfoDTO && viewDetailsObject.emDetailsInfoDTO.instituteEmail || "---"}
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Request User Neti ID</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {viewDetailsObject.requestByUserDTO && viewDetailsObject.requestByUserDTO.customNetiID || "---"}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Request User Name</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {viewDetailsObject.requestByUserDTO && viewDetailsObject.requestByUserDTO.fullName || "---"}
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Request Amount</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {viewDetailsObject.requestAmount || "---"}
                                        </div>
                                    </div>
                                </div>
                                <br />




                            </div>
                        </div>
                        <div className="p-grid nw-form">

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Status</span>
                                    <Dropdown
                                        placeholder='Select Status'
                                        options={statusListOptions}
                                        name="status"
                                        value={this.state.approvalValue}
                                        onChange={this.onChangeStatus}
                                        filter={true}
                                        filterBy="label,value"
                                        autoWidth={false}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["status"]}</span>

                            </div>

                            {this.state.approvalValue === 1 ?
                                <>
                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Approved Amount <span>*</span></span>
                                            <InputText
                                                name="approvedAmount"
                                                placeholder="Enter Approved Amount"
                                                value={this.state.approvedAmount}
                                                onChange={this.onChangeApprovedAmount}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors["approvedAmount"]}</span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Approved Note</span>
                                            <InputTextarea
                                                id="textarea"
                                                autoResize={true}
                                                name="approvedNote"
                                                value={this.state.approvedNote}
                                                onChange={this.onChangeApprovedNote}
                                                placeholder="Enter Approved Note"
                                                maxLength={300}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors['approvedNote']}</span>
                                    </div>
                                    <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Submit"
                                            icon="far fa-save"
                                            onClick={this.onSubmitUpdate}
                                        />
                                    </div>
                                </>

                                : ''}

                            {this.state.approvalValue === 2 ?
                                <>

                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Reject Note</span>
                                            <InputTextarea
                                                id="textarea"
                                                autoResize={true}
                                                name="rejectNote"
                                                value={this.state.rejectNote}
                                                onChange={this.onChangeRejectNote}
                                                placeholder="Enter Reject Note"
                                                maxLength={300}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors['rejectNote']}</span>
                                    </div>
                                    <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Submit"
                                            icon="far fa-save"
                                            onClick={this.onSubmitUpdate}
                                        />
                                    </div>
                                </>
                                : ''}
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }

}