import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Editor } from 'primereact/editor';
import AuthService from "../../../auth/AuthService";
import { BaseURIHolder } from "../../../utils/BaseURIHolder";
import { Growl } from "primereact/growl";
import { FileUpload } from "primereact/fileupload";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ChatBoxService } from '../../../service/chatBox/chatBoxService';
import { ChatBoxList } from './ChatBoxList';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import Formatter from '../../../utils/Formatter';
import CommonFuctionality from '../../common/CommonFuctionality';
import { DownloadButton } from "../../../utils/DownloadButton";
import ChatBoxCommonBody from './ChatBoxCommonBody';


export class ChatBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchesPoint: null,
      productType: null,
      productName: null,
      netiId: "",
      messageBody: "",
      userInfo: {},
      postInfo: {},
      sender: [],
      receiver: [],
      errors: {},
      idcheck: true,
      chatboxDate: null,
      dataTableIsLoading: false,
      secondDataTableIsLoading: false,
      topProgressBar: false,
      searchIsLoading: false,
      chatboxBody: null,
      fullName: null,
      netiIDvalue: null,
      chatboxDate2: null,
      chatboxBody2: null,
      fullName2: null,
      netiIDvalue2: null,
      dataTableSelection: null,
      dataViewValue: [],
      
      dataTableValue: [
        // {
        //   date: "23-01-2019",
        //   netiId: "54484418",
        //   name: "Abdur",
        //   mobile: "01710486868",
        //   readStatus: "Unread"
        // },
        // {
        //   date: "23-01-2019",
        //   netiId: "54484418",
        //   name: "Mamun",
        //   mobile: "01710486868",
        //   readStatus: "Read"
        // }
      ],
      unreadMessageCount: 0,
      unreadMessageList: [],
      unreadMessageDetails: {
        senderBasicInfoDTO:{}
      },
      searchView: false,
      searchViewError: false,
      searchIsLoadingFirst: false,
      searchIsLoadingSecond: false,
      chatBoxObj:{
        receiverBasicInfoDTO: {
          netiID:''
        }
      },
      uploadImage:{},
      visibleReplySection:false,
      visibleForwardSection:false,
      dialogUserInfo:{}
    };
    this.actionBody = this.actionBody.bind(this);
    this.actionBody2 = this.actionBody2.bind(this);
    this.viewDialog = this.viewDialog.bind(this);
    this.viewDialog2 = this.viewDialog2.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onHide2 = this.onHide2.bind(this);
    this.onBasicUpload = this.onBasicUpload.bind(this);
    this.AuthService = new AuthService();
    this.baseURIHolder = new BaseURIHolder();
    this.NetiContentLoader = new NetiContentLoader();
    this.chatBoxService = new ChatBoxService();
    this.formatter = new Formatter();
    this.CommonFuctionality = new CommonFuctionality();

  }
  
   componentDidMount() {
      // this.getReceivedMessageList();
      // this.getSendMessageList();
      this.getUnreadMessageList();
  
    } 
  
    getUnreadMessageList = () => {
      this.setState({ topProgressBar:true, dataTableIsLoading: true, errorMsgVisible: false});
      this.chatBoxService.fetchUnreadMessageInfo()
          .then(res => {
              if (res.status === 302) {
                  return res.json().then((body) => {
                      console.log("unreadMessageList", body);
                      this.setState({ unreadMessageList: body, unreadMessageCount: body.length, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                  });
              } else {
                  this.chatBoxService.Auth.handleErrorStatus(res)
                      .then((responseBody) => {
                          this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                      });
              }
          }).catch(error => 
            this.setState({ topProgressBar:false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
        );
    }

  // getSendMessageList () {

  //   this.setState({ topProgressBar:true, dataTableIsLoading: true, errorMsgVisible: false});

  //   this.chatBoxService.fetchSendMessageInfo()
  //       .then(res => {
  //           if (res.status === 302) {
  //               return res.json().then((body) => {
  //                   this.setState({ sender: body, topProgressBar: false, dataTableIsLoading: false });
                    
  //               });
  //           } else {
  //               this.chatBoxService.Auth.handleErrorStatus(res)
  //                   .then((responseBody) => {
  //                       this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
  //                   });
  //           }
  //       }).catch(error => 
  //         this.setState({ topProgressBar:false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
  //     );
  // }



  // getReceivedMessageList () {
   
  //   this.setState({ topProgressBar:true, secondDataTableIsLoading: true, errorMsgVisible: false});
  //   this.chatBoxService.fetchReceivedMessageInfo()
  //       .then(res => {
  //           if (res.status === 302) {
  //               return res.json().then((body) => {
  //                   this.setState({ receiver: body, topProgressBar: false, secondDataTableIsLoading: false });
                    
  //               });
  //           } else {
  //               this.chatBoxService.Auth.handleErrorStatus(res)
  //                   .then((responseBody) => {
  //                       this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
  //                   });
  //           }
  //       }).catch(error => 
  //         this.setState({ topProgressBar:false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
  //     );

  // }


  handleProfileInfo = (searchUserFrom) => {

    console.log("searchUserFrom", searchUserFrom);
    

    let handleError
    let customNetiId
    if(searchUserFrom == 'main-section'){
      handleError = this.netiIdHandleError()
      customNetiId = this.state.netiId
    }
    else if(searchUserFrom == 'dialog-section'){
      handleError = this.dialogNetiIdHandleError()
      customNetiId = this.state.forWardNetiId
    }


    if (handleError) 
    this.setState({ searchIsLoadingFirst: true, searchIsLoadingSecond: true, searchView: true, searchViewError: false, topProgressBar:true, searchIsLoading: true, errorMsgVisible: false});
    this.chatBoxService.fetchProfileInfo(customNetiId)
        .then(res => {
            if (res.status === 302) {
                return res.json().then((body) => {
                  if(searchUserFrom == 'main-section'){
                    this.setState({ userInfo: body, dialogUserInfo: {} })
                  }
                  else if(searchUserFrom == 'dialog-section'){
                    this.setState({ userInfo: {}, dialogUserInfo: body })
                  }
                  this.setState({ searchViewError: false, topProgressBar: false, searchIsLoading: false,  searchIsLoadingFirst: false, searchIsLoadingSecond: false, });
                    
                });
            } else {
                this.chatBoxService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({  searchIsLoadingFirst: false, searchViewError: true, searchIsLoadingSecond: false, topProgressBar: false, searchIsLoading: false, errorMsgVisible: false, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => 
          this.setState({  searchIsLoadingFirst: false, searchView: false, searchIsLoadingSecond: false, topProgressBar:false, searchIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
      );

  };



  handlepostData = (postFrom) => {

    let { chatBoxObj, unreadMessageDetails, userInfo, dialogUserInfo } = this.state

    let errorsCheck;

    if( postFrom == "save" ){
      chatBoxObj.chatboxBody = this.state.messageBody
      chatBoxObj.receiverBasicInfoDTO.netiID = userInfo.netiID

      if (this.state.uploadImage.contentPic != null) {
        chatBoxObj.attachContent = this.state.uploadImage.contentPic;
        chatBoxObj.attachSaveOrEditable = true;
        chatBoxObj.chatboxAttachmentName = this.state.uploadImage.contentName;
      }

      errorsCheck = this.handleError()
    }

    else if( postFrom == "reply" ){
      chatBoxObj.chatboxBody = this.state.replyMessageBody
      chatBoxObj.receiverBasicInfoDTO.netiID = unreadMessageDetails.senderBasicInfoDTO.netiID

      if (this.state.uploadImage.contentPic != null) {
        chatBoxObj.attachContent = this.state.uploadImage.contentPic;
        chatBoxObj.attachSaveOrEditable = true;
        chatBoxObj.chatboxAttachmentName = this.state.uploadImage.contentName;
      }

      errorsCheck = this.handleReplyError()
    }

    else if( postFrom == "forward" ){
      chatBoxObj.chatboxBody = unreadMessageDetails.chatboxBody
      chatBoxObj.receiverBasicInfoDTO.netiID = dialogUserInfo.netiID

      if (this.state.uploadImage.contentPic != null) {
        chatBoxObj.attachContent = this.state.uploadImage.contentPic;
        chatBoxObj.attachSaveOrEditable = true;
        chatBoxObj.chatboxAttachmentName = this.state.uploadImage.contentName;
      }

      errorsCheck = dialogUserInfo.netiID ? true : false
    }

    
    this.setState({ chatBoxObj })
    if (errorsCheck) {
      this.setState({ topProgressBar:true, errorMsgVisible: false,  errorMsgBody: ''});
      this.chatBoxService.sendProfileInfo(chatBoxObj)
        .then(res => {
          
          if (res.status === 201) {
            this.growl.show({
              severity: "success",
              summary: "Success Message",
              detail: "Message Sent"
            });
            // this.getSendMessageList();
            this.setState({ topProgressBar:false,  errorMsgVisible: false, errorMsgBody: ''});
          } else {
            this.chatBoxService.Auth.handleErrorStatus(res)
            .then((resp) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true,   errorMsgBody: resp })
            });
          }
        }).catch(error => 
          this.setState({ topProgressBar:false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
      );
    }
  };

  netiIdHandleError() {
    let errors = {};
    let formIsValid = true;

    if (this.state.netiId === "" ) {
      formIsValid = false;
      errors["netiId"] = "Please input atleast one ID.";
      this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  dialogNetiIdHandleError() {
    let errors = {};
    let formIsValid = true;

    if (this.state.forWardNetiId == "" ) {
      formIsValid = false;
      errors["netiId"] = "Please input atleast one ID.";
      this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleError() {
    let errors = {};
    let formIsValid = true;

    if (this.state.netiId === "" ) {
      formIsValid = false;
      errors["netiId"] = "Please input atleast one ID.";
      this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
    }

    if (this.state.messageBody === "") {
      formIsValid = false;
      errors["messageBody"] = "Message Body can't left empty.";
      this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleReplyError() {
    let errors = {};
    let formIsValid = true;

    if ( !this.state.replyMessageBody) {
      formIsValid = false;
      errors["replyMessageBody"] = "Message Body can't left empty.";
      this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  onBasicUpload = (e) => {
    var reader = new FileReader();
    let photo = e.files[0];
    const scope = this
    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      let album = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name
      };
      scope.setState({ uploadImage: album });
    }
  }


  viewDialog(rowData) {
    this.setState({ chatboxDate: rowData.chatboxDate });
    this.setState({ chatboxBody: rowData.chatboxBody });
    this.setState({ fullName: rowData.senderBasicInfoDTO.fullName });
    this.setState({ netiIDvalue: rowData.senderBasicInfoDTO.netiID });
    this.setState({ visible: true });
    this.CommonFuctionality.blurDialogBackgroundActive();
  }
  
  viewDialog2(rowData) {
    this.setState({ chatboxDate2: rowData.chatboxDate });
    this.setState({ chatboxBody2: rowData.chatboxBody });
    this.setState({ fullName2: rowData.receiverBasicInfoDTO.fullName });
    this.setState({ netiIDvalue2: rowData.receiverBasicInfoDTO.netiID });
    this.setState({ visible2: true });
  }

  onHide(event) {

    this.getUnreadMessageList();
    this.setState({ visibleUnreadMSG: false, uploadImage:{} });
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  }
  onHide2(event) {
    this.setState({ visible2: false });
  }

  actionBody(rowData, column) {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button p-button-text-only"
          icon="fas fa-info"
          title="View Purchase"
          onClick={e => this.viewDialog(rowData)}
        />
      </div>
    );
  }
  actionBody2(rowData, column) {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button p-button-text-only"
          icon="fas fa-info"
          title="View Purchase"
          onClick={e => this.viewDialog2(rowData)}
        />
      </div>
    );
  }

  handleChange(e) {
    this.setState({ messageBody: e.target.value });
  }

  viewUnreadMSGdetails = (unreadMessage) =>{
    console.log("unreadMessage on click",unreadMessage);
    this.setState({ visibleUnreadMSG: true, unreadMessageDetails: unreadMessage})
    this.CommonFuctionality.blurDialogBackgroundActive();
    this.changeUnreadStatus(unreadMessage)
  }

  changeUnreadStatus = (unreadMessageObj) =>{
    this.setState({  errorMsgVisible: false, errorMsgBody: '' })
    this.chatBoxService.changeUnreadStatus(unreadMessageObj)
      .then(res => {
          if (res.status === 201) {
              this.growl.show({
                  severity: "success",
                  summary: "Success Message",
                  detail: "Successfully Updated",
                  life: 800
              });
              this.setState({  errorMsgVisible: false, errorMsgBody: '' })
              this.getUnreadMessageList();
          } else {
              this.chatBoxService.Auth.handleErrorStatus(res)
                  .then((resp) => {
                      this.setState({  errorMsgVisible: true, errorMsgBody: resp })
                  });
          }
      }).catch(error => {
          this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
      });
  }

  onChangeCustomNetiID = (e) => {
    let { errors } = this.state
    errors['netiId'] = ''
    this.setState({ netiId: e.target.value, searchViewError: false, searchView: false, buttonDisabled: true, errors });
  }

  onChangeForwardNetiId = (e) => {
    let { errors } = this.state
    errors['forWardNetiId'] = ''
    this.setState({ forWardNetiId: e.target.value, searchViewError: false, searchView: false, buttonDisabled: true, errors });
  }

  focusSearch = e =>{
    this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
  }

  blurSearch = e =>{
    this.setState({ searchLabel: '', addSearchBtnClass: ""  });
  }

  onChangeDescription = e => {
    let {errors} = this.state;
    errors["messageBody"] = "";
    this.setState({ messageBody: e.htmlValue, errors });
  }

  onChangeDialogDescription = e =>{
    let {errors} = this.state;
    errors["replyMessageBody"] = "";
    this.setState({ replyMessageBody: e.htmlValue, errors });
  }

  onClickReplyButton = (unreadMessageDetails) =>{

    this.setState({ visibleForwardSection: true })

  }

  render() {
    let { unreadMessageList, unreadMessageDetails, topProgressBar, errorMsgVisible, errorMsgBody, dialogUserInfo, userInfo} = this.state;
    let tableHeadersend = (
      <span>
        {" "}
        <span>Sent Message List </span>
        <span className="float-right text-bold"> </span>
      </span>
    );
    let tableHeaderreceive = (
      <span>
        {" "}
        <span> Received Message List</span>
        <span className="float-right text-bold"> </span>
      </span>
    );

    let name;
    let mobile;
    if (this.state.userInfo) {
      name = this.state.userInfo.fullName;
      mobile = this.state.userInfo.basicMobile;
    }

    let readStatusvalue = rowData => {
      if (rowData.readStatus == 0) {
        return <div className="text-center"> Unread </div>;
      } else return <div className="text-center"> Read </div>;
    };

    let dialogHeader = <div className="header">
                          <img src="/assets/images/demo.jpg" width="35px"/>
                          <div>
                            <div className="username">{unreadMessageDetails.senderBasicInfoDTO && unreadMessageDetails.senderBasicInfoDTO.fullName}</div>
                            <div className="date">{NetiDateUtils.getAnyShortForm(unreadMessageDetails.chatboxDate, 'DD-MMM-YYYY hh:mm:ss a')}</div>
                          </div>
                          
                        
                      </div>

    let uploadFileLabel = <div>Click / Drag File Here To Upload <br/><span className="upload-file-label">(Max 10MB (recommended)</span></div>

    
    const msg =  this.props.location.unreadMessage ? unreadMessageList.filter( unreadMessageList => unreadMessageList.chatboxLogID == (this.props.location.unreadMessage && this.props.location.unreadMessage.chatboxLogID) ): null;
    


    // console.log("msg", msg);
    // console.log("msg[0]", msg && msg[0]);
    // console.log("msg.chatboxLogID", msg && msg[0] && msg[0].chatboxLogID);
    
    if( (msg !== null) && ( msg && msg[0] && (msg && msg[0] && msg[0].chatboxLogID) )  ) {
      let logID = document.getElementById(msg[0].chatboxLogID)

      // console.log("logID && logID.scrollIntoView()", logID);
      
      if(logID !== null){
        logID.scrollIntoView()
      }
    }
    

    return (
      <div className="p-fluid">
         {topProgressBar ?
              <ErrorMessageView
                  topProgressBar={topProgressBar}
              />
              : null
          }
          {errorMsgVisible ?
              <ErrorMessageView
                  errorMsgVisible={errorMsgVisible}
                  errorMsgBody={errorMsgBody}
              />
              : null
          }
        <Growl ref={el => (this.growl = el)} />
        <div className="main-section blur-section">
          
          <div className="p-grid nw-form">
            <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
              <div className="p-grid seprator-inside">
                <div className="p-col-12 p-xl-12">
                  <div className="nw-form-body">

                    <div className="p-col-12 p-lg-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Neti Id  <span>*</span></span>
                        <InputText
                          keyfilter="pint"
                          name="productName"
                          value={this.state.netiId}
                          onChange={this.onChangeCustomNetiID}    
                          style={{ width: "15%" }}
                          onFocus={this.focusSearch}
                          onBlur={this.blurSearch}
                        />
                        <Button
                          className={"p-button-animation " + this.state.addSearchBtnClass}
                          label={this.state.searchLabel}
                          onClick={ e => this.handleProfileInfo("main-section")}
                          icon="fas fa-search"
                          onFocus={this.focusSearch}
                          onBlur={this.blurSearch}
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["netiId"]}
                      </span>
                    </div>


                    {this.state.searchView && Object.keys(userInfo).length !== 0 ?
                      <div className="p-col-12 p-xl-12">

                          <div className="nw-search-view">

                              {this.state.searchViewError ?
                                  <center className="error-message">{this.state.errorMsgBody || "No Data Found"}</center> :
                                  <div>
                                      <div className="p-col-12 p-xl-12">
                                          {this.state.searchIsLoadingFirst ?
                                              this.NetiContentLoader.normalFormInputField() :
                                              <div className="p-inputgroup">
                                                  <span className="task-badge found"></span>
                                                  <span className="p-inputgroup-addon">Name</span>
                                                  <span className="p-inputgroup-colon">:</span>
                                                  <div className="nw-inputgroup-desc">
                                                      {name}
                                                  </div>
                                              </div>
                                          }
                                      </div>

                                      <div className="p-col-12 p-xl-12">
                                          {this.state.searchIsLoadingSecond ?
                                              this.NetiContentLoader.normalFormInputField() :
                                              <div className="p-inputgroup">
                                                  <span className="task-badge found"></span>
                                                  <span className="p-inputgroup-addon">Mobile No</span>
                                                  <span className="p-inputgroup-colon">:</span>
                                                  <div className="nw-inputgroup-desc">
                                                      { mobile ? this.formatter.getMaskedNumber(mobile) : ''}
                                                  </div>
                                              </div>
                                          }
                                      </div>
                                  </div>
                              }
                          </div>
                      </div>
                      :
                      ''
                  }

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Message Body <span>*</span></span>
                        <Editor
                            value={ this.state.messageBody }
                            onTextChange={ this.onChangeDescription }
                            className="nw-inputtexteditor"
                        />
                      </div>
                      <span className='error-message'>{this.state.errors["messageBody"]}</span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup nw-upload-button">
                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                          Upload File 
                          <br />
                          <span>(PNG/JPG/DOC/PDF)</span>
                        </span>

                        <div className="nw-upload-button-inside">

                          {
                            this.state.uploadImage.contentPic ?
                              <div className="image-view-main">
                                <div className="upload-image-view">
                                  <Button
                                    className="delete-upload-button"
                                    icon="fas fa-times-circle"
                                    onClick={e => this.setState({ uploadImage: { contentPic: '' } })}
                                  />

                                  {
                                    this.state.uploadImage.contentName ?
                                      this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadImage.contentName) : this.commonFuctionality.getFileContentTypeIcon(this.props.noticeInfo.noticeImgName)
                                  }

                                </div>
                                <div className="image-title">{this.state.uploadImage.contentName}</div>
                              </div>

                              :
                              <FileUpload
                                chooseLabel={this.state.uploadImage.contentName || uploadFileLabel}
                                id="fileUpload"
                                mode="basic"
                                maxFileSize={1000000}
                                onSelect={this.onBasicUpload}
                                auto={true}
                              />
                          }

                        </div>

                      </div>
                    </div>
                    
                    <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                      <div className="required-field">
                        (<span>*</span>) required fields
                      </div> 

                      <Button
                        className="p-button p-button-primary nw-button nw-button-right"
                        label="Send"
                        onClick={ e => this.handlepostData("save")}
                        icon="fas fa-check"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-col-12 p-xl-4">
              <div className="p-col-12 p-xl-12 nw-chatbox-view-main">
                <div className="p-col-12 p-xl-12 nw-chatbox-view header">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon"><i className="fas fa-eye-slash"></i> Unread Message </span>
                    <Button
                        label={this.state.unreadMessageCount || "0"}
                        onClick={(e) => window.location.href="/chat_box_list"}
                    >View All <i className="fas fa-angle-right"/><i className="fas fa-angle-right"/></Button>
                  </div>
                </div>

                {/* {
                  unreadMessageList.filter
                }
                test */}

                <div className="p-col-12 p-xl-12 p-col-nogutter nw-chatbox-view body">
                  {
                    unreadMessageList.map((unreadMessage, index) =>

                    
                      <div 
                        id={ unreadMessage && unreadMessage.chatboxLogID }
                        className="p-col-12 p-xl-12 inside" 
                        style={ msg && msg[0] && msg[0].chatboxLogID == (unreadMessage && unreadMessage.chatboxLogID) ? {backgroundColor: '#ffffcc'} : {backgroundColor: 'white'} }
                      >
                          
                        <ChatBoxCommonBody viewUnreadMSGdetails={this.viewUnreadMSGdetails} unreadMessage={unreadMessage}/>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        
        
        <div className="dialog-section">
          <Dialog 
            header={ dialogHeader }
            visible={this.state.visibleUnreadMSG}
            className="nw-dialog chat-box-dialog"
            onHide={this.onHide}
            maximizable
          >

            <div className="p-grid body nw-form">
              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <div className="nw-inputgroup-desc" style={{ height: "auto" }}>
                    <Editor
                      value={unreadMessageDetails.chatboxBody}
                      className="nw-inputtexteditor editor-only-view"
                      readOnly={true}
                      headerTemplate={e => { return null }}
                    />
                  </div>
                </div>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-grid">
                  <div className="p-col-6">
                    <DownloadButton fileName={unreadMessageDetails.chatboxAttachmentName} filePath={unreadMessageDetails.chatboxAttachmentPath} />
                  </div>

                  <div className="p-col-6 nw-button-parent-multiple">
                    <span></span>
                    <Button
                      className="nw-button nw-button-multiple nw-button-no-style"
                      label="Reply"
                      icon="fas fa-reply"
                      iconPos='left'
                      onClick={ (e) => this.setState({ visibleReplySection: !this.state.visibleReplySection, visibleForwardSection: false }) }
                    ></Button>
                    <Button
                      className="nw-button nw-button-multiple nw-button-no-style"
                      label="Forward"
                      icon="fas fa-share-square"
                      iconPos='left'
                      onClick={(e) => this.setState({ visibleReplySection: false, visibleForwardSection: !this.state.visibleForwardSection })}
                    ></Button>
                  </div>
                </div>
                
              </div>

             { this.state.visibleReplySection?
                <div className="p-grid p-col-12">
                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputtexteditor-label">
                        Message Reply To <span>*</span>
                        <br />
                        <div className="message-reply-to">
                          <i className="fas fa-reply"></i>
                          <span className="username">
                            <span className="name">{unreadMessageDetails.senderBasicInfoDTO && unreadMessageDetails.senderBasicInfoDTO.fullName} </span>
                            <span className="netiid">({unreadMessageDetails.senderBasicInfoDTO && unreadMessageDetails.senderBasicInfoDTO.customNetiID})</span>
                          </span>
                        </div>
                      </span>
                      <Editor
                        value={this.state.replyMessageBody}
                        onTextChange={this.onChangeDialogDescription}
                        className="nw-inputtexteditor"
                      />
                    </div>
                    <span className='error-message'>{this.state.errors["replyMessageBody"]}</span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup nw-upload-button">
                      <span className="p-inputgroup-addon nw-inputtextarea-label">
                        Upload File
                      <br />
                        <span>(PNG/JPG/DOC/PDF)</span>
                      </span>

                      <div className="nw-upload-button-inside">

                        {
                          this.state.uploadImage.contentPic ?
                            <div className="image-view-main">
                              <div className="upload-image-view">
                                <Button
                                  className="delete-upload-button"
                                  icon="fas fa-times-circle"
                                  onClick={e => this.setState({ uploadImage: { contentPic: '' } })}
                                />

                                {
                                  this.state.uploadImage.contentName ?
                                    this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadImage.contentName) : this.commonFuctionality.getFileContentTypeIcon(this.props.noticeInfo.noticeImgName)
                                }
                              </div>
                              <div className="image-title">{this.state.uploadImage.contentName}</div>
                            </div>

                            :
                            <FileUpload
                              chooseLabel={this.state.uploadImage.contentName || uploadFileLabel}
                              id="fileUpload"
                              mode="basic"
                              maxFileSize={1000000}
                              onSelect={this.onBasicUpload}
                              auto={true}
                            />
                        }

                      </div>
                    </div>
                  </div>

                  <div className="p-col-12 nw-button-parent">
                    <span></span>

                    <Button
                      className=" p-button-primary nw-button"
                      label="Send"
                      icon="fas fa-check"
                      iconPos='left'
                      onClick={e => this.handlepostData("reply")}
                    ></Button>
                  </div>

                </div>
                : null
              }

              {this.state.visibleForwardSection ?
                <div className="p-grid p-col-12">
                  <div className="p-col-12 p-lg-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Neti Id  <span>*</span></span>
                        <InputText
                          keyfilter="pint"
                          name="productName"
                          value={this.state.forWardNetiId}
                          onChange={this.onChangeForwardNetiId}   
                          style={{ width: "15%" }}
                          onFocus={this.focusSearch}
                          onBlur={this.blurSearch}
                        />
                        <Button
                          className={"p-button-animation " + this.state.addSearchBtnClass}
                          label={this.state.searchLabel}
                          onClick={ e => this.handleProfileInfo("dialog-section")}
                          icon="fas fa-search"
                          onFocus={this.focusSearch}
                          onBlur={this.blurSearch}
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["netiId"]}
                      </span>
                    </div>

                    {this.state.searchView && Object.keys(dialogUserInfo).length !== 0 ?
                      <div className="p-col-12 p-xl-12">
                          <div className="nw-search-view">
                              {this.state.searchViewError ?
                                  <center className="error-message">{this.state.errorMsgBody || "No Data Found"}</center> :
                                  <div>
                                      <div className="p-col-12 p-xl-12">
                                          {this.state.searchIsLoadingFirst ?
                                              this.NetiContentLoader.normalFormInputField() :
                                              <div className="p-inputgroup">
                                                  <span className="task-badge found"></span>
                                                  <span className="p-inputgroup-addon">Name</span>
                                                  <span className="p-inputgroup-colon">:</span>
                                                  <div className="nw-inputgroup-desc">
                                                      { dialogUserInfo.fullName }
                                                  </div>
                                              </div>
                                          }
                                      </div>

                                      <div className="p-col-12 p-xl-12">
                                          {this.state.searchIsLoadingSecond ?
                                              this.NetiContentLoader.normalFormInputField() :
                                              <div className="p-inputgroup">
                                                  <span className="task-badge found"></span>
                                                  <span className="p-inputgroup-addon">Mobile No</span>
                                                  <span className="p-inputgroup-colon">:</span>
                                                  <div className="nw-inputgroup-desc">
                                                      { dialogUserInfo.basicMobile ? this.formatter.getMaskedNumber(dialogUserInfo.basicMobile) : ''}
                                                  </div>
                                              </div>
                                          }
                                      </div>
                                  </div>
                              }
                          </div>
                      </div>
                      :
                      ''
                  }


                  <div className="p-col-12 nw-button-parent">
                    <span></span>

                    <Button
                      className=" p-button-primary nw-button"
                      label="Send"
                      icon="fas fa-check"
                      iconPos='left'
                      onClick={e => this.handlepostData("forward")}
                    ></Button>
                  </div>

                </div>
                : null
              }

            </div>
          </Dialog>

          <Dialog
            header="Received Message Information"
            visible={this.state.visible2}
            className="nw-dialog"
            onHide={this.onHide2}
            maximizable
          >
            <div className="p-fluid">
              <div className="p-grid">
                <div className="p-col-6 p-xl-6">
                  <span>Date : {this.state.chatboxDate2}</span>
                  <br />
                  <span>Neti Id : {this.state.netiIDvalue2}</span>
                  <br />
                  <span>Name : {this.state.fullName2}</span>
                  <br />
                  <span>Message Body : {this.state.chatboxBody2} </span>
                  <br />
                  <span>
                    Attachement : <a href="file:///C:/Temp/test.pdf">File.pdf</a>{" "}
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </Dialog>
        </div>
        
      </div>
    );
  }
}
