import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { WalletBalance } from '../myPoint/common/WalletBalance';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { EmPostPaidBillSummary } from './EmPostPaidBillSummary';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { EmAdminPointService } from '../../service/emAdminPoint/EmAdminPointService';
import NetiContentLoader from '../common/NetiContentLoader';
let walletBalance = 0;

let cloneStateBeforeMount;
export class EmPostPaidBill extends Component {
    constructor(props) {
        super(props);

        this.state = {
            payabaleInstituteList: [],
            billTotalMonth:'',
            totalPayableAmount: '',
            totalPaidAmount: '',
            totalDueAmount: '',
            postpaidBillList: [],
            postPaidBillError: {},
            errorMsgVisible: false,
            errorMsgBody: '',
            payableInstituteID: '',
            payableInstituteInfo: {},
            postPaidBillInfo: {
                billAmount: '',
                billNote: '',
                emBillGenerateLogDTO: {
                    generateBillId: ''
                },
                emDetailsInfoDTO: {
                    emDetailsId: ''
                }

            },
            topProgressBar: false,
            firstDropDownIsLoading: false,
            secondDropDownIsLoading: false,
            dataIsLoading: false,
            dataTableIsLoading:false,
            walletBalanceLoad: false,
            billSummaryID: ''
        }

        this.emAdminPointService = new EmAdminPointService();
        this.NetiContentLoader = new NetiContentLoader();

    }

    componentWillMount() {
        this.getPayableInstituteList();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    getPayableInstituteList = () => {
        this.setState({ topProgressBar: true, firstDropDownIsLoading:true, errorMsgVisible: false});
        this.emAdminPointService.getEmInstituteListByType("POST-PAID")
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ payabaleInstituteList: body, topProgressBar: false, firstDropDownIsLoading:false });

                    });
                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, firstDropDownIsLoading:false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropDownIsLoading:false,  errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    viewPostpaidBillSummaryList = (value) => {
        let { billTotalMonth,totalPayableAmount,totalPaidAmount,totalDueAmount } = this.state;
        this.setState({totalPayableAmount:'',totalPaidAmount:'',totalDueAmount:'', billTotalMonth:''});
        this.setState({ topProgressBar: true, dataTableIsLoading:true, errorMsgVisible: false});

        this.emAdminPointService.getEmAdminPostpaidBillSummary(value)
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        if(body.length === 0) {
                            this.setState({ topProgressBar: false, errorMsgVisible: true,  dataTableIsLoading:false,errorMsgBody: 'No Monthly Bill Found' });
                        } else {
                            this.setState({ topProgressBar: false, errorMsgVisible: false,  dataTableIsLoading:false });
                            this.setState({ postpaidBillList: body });
                            billTotalMonth = body.map(item => item.month).length;
                            this.setState({billTotalMonth});
                            totalPayableAmount = body.map(item => item.payableAmount).reduce((a, b) => (a + b));
                            this.setState({totalPayableAmount});
                            totalPaidAmount = body.map(item => item.paidAmount).reduce((a, b) => (a + b));
                            this.setState({totalPaidAmount});
                            totalDueAmount = body.map(item => item.dueAmount).reduce((a, b) => (a + b));
                            this.setState({totalDueAmount});
                        }
                    });
                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false,  dataTableIsLoading:false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false,  errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                
            });

    }

    onChangePayableInstitute = (event) => {
        let {  payabaleInstituteList, postPaidBillError,postpaidBillList } = this.state;
        this.setState({postpaidBillList: []});
        postPaidBillError["payableInstitute"] = "";

        this.setState({ payableInstituteID: event.target.value, postPaidBillError, payableInstituteInfo:{} });

        if ((this.state.payabaleInstituteList != null) && (this.state.payabaleInstituteList.length > 0)) {
            for (let i = 0; i < payabaleInstituteList.length; i++) {
                if (payabaleInstituteList[i].emDetailsId === event.target.value) {
                    this.setState({ payableInstituteInfo: payabaleInstituteList[i] });
                    this.viewPostpaidBillSummaryList(event.target.value);
                }
            }
        }

        if(event.target.value === null) {
            this.setState({ payableInstituteInfo:cloneStateBeforeMount.payableInstituteInfo  });
        }

    }
    onChangeBillSummary = (e) => {
        let { postPaidBillInfo,postPaidBillError } = this.state;
        postPaidBillError["billMonth"] = "";
        postPaidBillInfo.emBillGenerateLogDTO.generateBillId = e.target.value;
        this.setState({ postPaidBillInfo, postPaidBillError });
    }

    onChangeBillAmount = (e) => {
        let { postPaidBillInfo, postPaidBillError } = this.state;
        postPaidBillError["pay"] = "";
        postPaidBillInfo.billAmount = e.target.value;
        this.setState({ postPaidBillInfo, postPaidBillError });
    }

    onChangeBillNote = (e) => {
        let { postPaidBillInfo, postPaidBillError } = this.state;
        postPaidBillError["requestNote"] = "";
        postPaidBillInfo.billNote = e.target.value;
        this.setState({ postPaidBillInfo, postPaidBillError });
    }

    onSubmitHandler = () => {
        
        let { postPaidBillInfo, payableInstituteInfo } = this.state;
        postPaidBillInfo.emDetailsInfoDTO.emDetailsId = payableInstituteInfo.emDetailsId;
        if (this.postPaidBillFormError()) {
            this.setState({ topProgressBar: true, firstDropDownIsLoading:true, dataTableIsLoading:true, walletBalanceLoad: true });
            this.setState({ postPaidBillInfo });
            this.emAdminPointService.payPostpaidBillByEmAdmin(postPaidBillInfo)
                .then(res => {
                    
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                        this.setState({
                            billMonth: '',
                            payableAmount: '',
                            billTotalMonth:'',
                            postpaidBillList: [],
                            totalDueAmount:'',
                            totalPayableAmount:'',
                            totalPaidAmount:'',
                            payableInstituteID: '',
                            payableInstituteInfo: cloneStateBeforeMount.payableInstituteInfo,
                            postPaidBillInfo: cloneStateBeforeMount.postPaidBillInfo
                        });
                        this.getPayableInstituteList();
                        this.setState({ topProgressBar: false, firstDropDownIsLoading:false, dataTableIsLoading:false, walletBalanceLoad: false });
                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, firstDropDownIsLoading:false, dataTableIsLoading:false,walletBalanceLoad: false, errorMsgVisible: true, errorMsgBody: resBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, walletBalanceLoad: false,firstDropDownIsLoading:false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                });
        }

    }

    postPaidBillFormError = () => {
        let { postPaidBillError, postPaidBillInfo } = this.state;
        let formIsValid = true;

        if (this.state.payableInstituteID === '') {
            formIsValid = false;
            postPaidBillError["payableInstitute"] = "Institute can't left empty.";
        }
        if (postPaidBillInfo.emBillGenerateLogDTO.generateBillId === '') {
            formIsValid = false;
            postPaidBillError["billMonth"] = "Bill month can't left empty.";
        }
        if (postPaidBillInfo.billAmount === '' || postPaidBillInfo.billAmount == 0) {
            formIsValid = false;
            postPaidBillError["pay"] = "Payment can't left empty.";
        } else if(postPaidBillInfo.billAmount > walletBalance) {
            formIsValid = false;
            postPaidBillError["pay"] = "Insufficient Wallet Balance";

        }
        if (postPaidBillInfo.billNote === '') {
            formIsValid = false;
            postPaidBillError["requestNote"] = "Note can't left empty.";
        }
        this.setState({ postPaidBillError });
        return formIsValid;
    }

    onChangeDueAmount = (rowData) => {
        return <span>{(rowData['payableAmount'] - rowData['paidAmount'])}</span>
    }

    render() {
     
        // console.log("walletBalance",this.refs.walletBalance && this.refs.walletBalance.state && this.refs.walletBalance.state.userWalletBalance && this.refs.walletBalance.state.userWalletBalance.walletBalance);
        // console.log("this.refs.walletBalance",this.refs.walletBalance);
        walletBalance = this.refs.walletBalance && this.refs.walletBalance.state && this.refs.walletBalance.state.userWalletBalance && this.refs.walletBalance.state.userWalletBalance.walletBalance;

        let { payableInstituteInfo, postPaidBillInfo, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let payabaleInstitute = [];
        if ((this.state.payabaleInstituteList != null) && (this.state.payabaleInstituteList.length > 0)) {
            payabaleInstitute = this.state.payabaleInstituteList.map((item) => ({
                value: item.emDetailsId,
                label: item.instituteId + " - " + item.instituteName,
            }));
        }

        let billSummaryList = [];
        if ((this.state.postpaidBillList != null) && (this.state.postpaidBillList.length > 0)) {
            billSummaryList = this.state.postpaidBillList.map((item) => ({
                value: item.generateBillId,
                label: `${item.year} - ${item.month} - ${item.dueAmount} Tk`
            }));
        }

        return (

            <div className="p-fluid">
                <div className="p-grid nw-form">
                    {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                    {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                    <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                        <Growl ref={(el) => this.growl = el} />
                        <div className="p-grid seprator-inside">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                    { this.state.firstDropDownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField():
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Payable Institute <span>*</span></span>
                                            <Dropdown
                                                value={this.state.payableInstituteID}
                                                options={payabaleInstitute}
                                                onChange={this.onChangePayableInstitute}
                                                placeholder="Select Institute"
                                                showClear={true}
                                                autoWidth={false}
                                                name="payableInstitute"
                                            />
                                        </div>
                                    }
                                        <span className="error-message">{this.state.postPaidBillError["payableInstitute"]}</span>
                                    </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Institute ID
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                {payableInstituteInfo.instituteId}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Institute Name
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                {payableInstituteInfo.instituteName}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                  Total Due
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                 {payableInstituteInfo.dueAmount}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                        { this.state.secondDropDownIsLoading ?
                                          this.NetiContentLoader.normalFormInputField():
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Bill Month <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Bill Month"
                                                    value={postPaidBillInfo.emBillGenerateLogDTO.generateBillId || ''}
                                                    options={billSummaryList}
                                                    onChange={this.onChangeBillSummary}
                                                    filter={true}
                                                    filterBy="value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="billMonth"
                                                />
                                            </div>
                                        }
                                            <span className="error-message">{this.state.postPaidBillError["billMonth"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Pay <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Pay"
                                                    keyfilter="money"
                                                    name="pay"
                                                    value={postPaidBillInfo.billAmount || ''}
                                                    onChange={this.onChangeBillAmount}
                                                    autoWidth={false}
                                                    name="pay"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.postPaidBillError["pay"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup padding-top">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                                <InputTextarea
                                                    value={postPaidBillInfo.billNote || ''}
                                                    onChange={this.onChangeBillNote}
                                                    placeholder="Enter Note"
                                                    rows={3}
                                                    cols={30}
                                                    autoResize={true}
                                                    name="requestNote"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.postPaidBillError["requestNote"]}</span>

                                        </div>

                                        <div className="p-col-12 p-xl-12" />

                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div> 

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Pay Bill"
                                                icon="fas fa-hand-holding-usd"
                                                onClick={this.onSubmitHandler}
                                            />
                                        </div>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="p-col-12 p-xl-4">
                        <div className="layout-dashboard">
                        { this.state.walletBalanceLoad ? "": 
                            <WalletBalance
                                ref="walletBalance"
                                walletBalanceName='Wallet'
                            />
                       
                        }
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        { !this.state.dataTableIsLoading ?
                        <EmPostPaidBillSummary 
                            payableInstitute={payableInstituteInfo.instituteName}
                            totalDueAmount={this.state.totalDueAmount} 
                            totalPaidAmount={this.state.totalPaidAmount} 
                            billTotalMonth={this.state.billTotalMonth} 
                            totalPayableAmount={this.state.totalPayableAmount} 
                            postpaidBillList={this.state.postpaidBillList} 
                        />
                        : this.NetiContentLoader.MyPointTableLoader()
                        }
                       
                    </div>

                </div>
            </div>
        );
    }
}