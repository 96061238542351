import React, {Component} from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import {DomainOrder} from './DomainOrder';
import {HostingOrder} from './HostingOrder';

export class DomainOrderMain extends Component {

        
    render() {

        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView>
                            <TabPanel header="Domain Order">
                                <DomainOrder domain={true}/>
                            </TabPanel>
                            <TabPanel header="Hosting Order">
                                <HostingOrder hosting={true}/>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }
}