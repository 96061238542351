import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Button } from 'primereact/button';
import { pdfDownloadPortrait_FullTable } from '../../common/JsPdfDownload';

let bankInfoListArr = [];
let uniqueBankNames;
let bankTotalAmount = [];
let bkashTotalArr = [];
let bkashTotal = 0;

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class MonthlyDepositAmountList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            searchObj: { ...props.location.searchObj },
            totalDepositAmount: 0,
            totalWithdrawAmount: 0,
            totalTransferAmount: 0,
            totalPurchaseAmount: 0
        }

        this.managementPointService = new ManagementPointService();
        this.ValidatorUtility = new ValidatorUtility();
        this.exportDepositPdf = this.exportDepositPdf.bind(this);
    }

    componentWillMount() {

        if (this.props.location.searchObj) {
            let depositSearchInfo = this.props.location.searchObj
            localStorage.setItem("depositSearchInfo", JSON.stringify(depositSearchInfo));
        }

        this.getMonthlyDepositAmountList();
    }

    getMonthlyDepositAmountList = () => {
        const depositSearchInfo = JSON.parse(localStorage.getItem("depositSearchInfo"));
        if (!depositSearchInfo) {
            return;
        }
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.managementPointService.fetchMonthlyBalanceTransactionListByType(depositSearchInfo.urlType, depositSearchInfo.year, depositSearchInfo.month, depositSearchInfo.type)
            .then(res => {
                if (res.status == 200) {
                    // console.log('res',res)
                    return res.json().then((body) => {
                        // console.log('monthly deposit body',body)
                        body.map(item => {
                            item.date = NetiDateUtils.getAnyShortForm(item.date, 'DD-MMM-YYYY hh:mm:ss a');
                        })
                        this.setState({ dataTableValue: body });

                        // console.log('BankInfo BKash', body.map(item => item.bankInfo.BKash ? item.bankInfo.BKash: 0 ).reduce((a, b) => (a + b)))
                        // console.log('BankInfo SureCash', body.map(item => item.bankInfo.SureCash ? item.bankInfo.SureCash: 0 ).reduce((a, b) => (a + b)))

                        this.setState({
                            totalDepositAmount: body.map(item => item.amount).reduce((a, b) => (a + b)),
                            // totalWithdrawAmount: this.calculateTotalAmount(body, "withdrawAmount"),
                            // totalTransferAmount:this.calculateTotalAmount(body, "transferAmount"),
                            // totalPurchaseAmount: this.calculateTotalAmount(body, "purchaseAmount")
                        })

                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    })
                } else if (res.status == 404) {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    // calculateTotalAmount(body, amountCategory){
    //     return body.map(item => item.amount).reduce((a, b) => (a + b));
    // }

    cityBank = rowData => {
        return (
            <div>{rowData.cityBank}</div>
        )
    }

    bracBank = rowData => {
        return (
            <div>-</div>
        )
    }

    dutchBank = rowData => {
        return (
            <div>-</div>
        )
    }

    islamiBank = rowData => {
        return (
            <div>-</div>
        )
    }

    bankBody = rowData => {
        return rowData.amount
    }

    columnTemplate = (headerName, body) => {
        // console.log("body",body);
        let amount = 0;
        if (body) {
            let headerList = Object.entries(body.rowData.bankInfo);
            // console.log("headerList",headerList);
            for (let i = 0; i < headerList.length; i++) {
                if (body.header === headerList[i][0]) {
                    amount = headerList[i][1]
                    bkashTotal = amount;
                }
            }
        }
        if (amount !== 0) {
            return <div className="col-number text-right" >
                {this.ValidatorUtility.currencyFormatter(amount)}
            </div>
        } else {
            return <div className="col-number text-right" >
                ---
            </div>
        }
    }

    depositTemplate = (rowData) => {
        return <div className="col-number text-right" >
            {this.ValidatorUtility.currencyFormatter(rowData.amount)}
        </div>
    }

    sumOfBankAmount = (col, row) => {
        // console.log('Cal', col);
        // console.log('row', row);

    }
    // footerTemplate (data, index){
    //     console.log('data', data);
    //     return ([
    //                 <td key={index + '_footerTotalLabel'} colSpan="3" style={{textAlign: 'right'}}>Total Price</td>,
    //                 <td key={index + '_footerTotalValue'}>0</td>
    //         ]
    //     );
    // }

    exportDepositPdf() {

        // let pdfColumns = [
        //   { title: "Date", dataKey: "date" },
        //   { title: "Deposit Amount", dataKey: "amount" },
        // ]

        // var totalArrayList = [
        //   {
        //     id: "1",
        //     totalPdf: "Total",
        //     totalAmount1: this.state.totalDepositAmount,
        //     totalAmount2: this.state.totalWithdrawAmount,
        //     totalAmount3: this.state.totalTransferAmount,
        //     totalAmount4: this.state.totalPurchaseAmount,
        //     totalAmount5: this.state.totalRevenueAmount,
        //   }
        // ];

        // let totalColumns = [{ dataKey: "totalPdf" }, { dataKey: "totalAmount1" }, { dataKey: "totalAmount2" }, { dataKey: "totalAmount3" }, { dataKey: "totalAmount4" }, { dataKey: "totalAmount5" }, ];

        // let totalColumnStyles= {
        //   totalPdf: {
        //     halign: "right",
        //     fontSize: 10,
        //     cellWidth: 17,
        //     fontStyle: "bold",overflow: "linebreak"
        //   },
        //   totalAmount1: {
        //     halign: "right",
        //     fontStyle: "bold",
        //     fontSize: 10,
        //     cellWidth: 31.5
        //   },
        //   totalAmount2: {
        //     halign: "right",
        //     fontSize: 10,
        //     fontStyle: "bold",
        //     cellWidth: 35
        //   },
        //   totalAmount3: {
        //     halign: "right",
        //     fontSize: 10,
        //     fontStyle: "bold",
        //     cellWidth: 34.5
        //   },
        //   totalAmount4: {
        //     halign: "right",
        //     fontSize: 10,
        //     fontStyle: "bold",
        //     cellWidth: 33.5
        //   },
        //   totalAmount5: {
        //     halign: "right",
        //     fontSize: 10,
        //     fontStyle: "bold",
        //     cellWidth: 29
        //   }

        // }

        // pdfDownloadPortrait_FullTable("Monthly Balance Transaction Report", subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, totalColumns, totalArrayList, totalColumnStyles, "Monthly_Balance_Transaction_Report.pdf");

    }

    render() {

        // console.log('this.state.dataTableValue', this.state.dataTableValue);

        // excel
        let excelColumns = "";
        //   let excelFileName = "Balance Transaction of "+ this.state.yearValue+".xlsx";
        //   let excelSheetName = "balance_transaction_"+ this.state.yearValue;

        //   { title: "Date", dataKey: "date" },
        //   { title: "Deposit Amount", dataKey: "amount" },

        //   excelColumns = [
        //     <Workbook.Column value="date" label="Date" />,
        //     <Workbook.Column value="amount" label="Deposit Amount" />,
        //   ];

        // end excel

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalDepositAmount } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Deposit List (Month Wise)</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let bankNameColumn = [];
        let bankName = [];

        let bankListFullColumn = [];
        let uniqueItems = [];

        if ((this.state.dataTableValue != null) && (this.state.dataTableValue.length > 0)) {
            bankNameColumn = this.state.dataTableValue.map((item, index) => ({
                value: item.bankInfo,
                key: Object.keys(item.bankInfo),
                date: item.date
            }));
        }

        // console.log("dataTableValue-Deposite",this.state.dataTableValue);

        // for(let i=0 ; i<bankNameColumn.length; i++){
        //     bankListFullColumn = <Column field={bankNameColumn[i].value} header={bankNameColumn[i].key} filter={true} />
        // }
        // console.log('uniqItms',uniqueItems);
        // console.log('bankNameColumn',bankNameColumn);

        if (bankNameColumn) {
            for (let i = 0; i < bankNameColumn.length; i++) {
                for (let j = 0; j < bankNameColumn[i].key.length; j++) {
                    bankName.push(bankNameColumn[i].key[j])
                    uniqueItems = Array.from(new Set(bankName))
                }
            }
        }

        // if (uniqueItems) {
        //     for (let k = 0; k < uniqueItems.length; k++) {
        //         bankListFullColumn.push(<Column header={(uniqueItems[k])} body={this.columnTemplate(uniqueItems[k])} filter={true}/>)
        //     }
        // }

        let footer = <ColumnGroup>{/*this.state.totalDepositAmount*/}
            <Row>
                <Column footer="Total:" colSpan={1} />
                <Column footer={this.ValidatorUtility.currencyFormatter(this.state.totalDepositAmount)} />
                {uniqueItems ? uniqueItems.map(k => {
                    return (<Column footer="---" />)
                })
                    : ''
                }
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className="p-grid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <div className="nw-data-table">
                            {/* {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                            <DataTable
                                header={tableHeader}
                                footerColumnGroup={footer}
                                // rowGroupFooterTemplate={this.footerTemplate.bind(this)}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={this.state.dataTableValue}
                            >
                                <Column field="date" header="Date & Time" className="table-datetime" filter={true} />
                                <Column field="amount" header="Deposit Amount" filter={true} body={this.depositTemplate} />
                                {/* {bankListFullColumn} */}
                                {uniqueItems ? uniqueItems.map(k => {
                                    return (<Column columnKey={k} header={k} filter={true} body={this.columnTemplate} />)
                                })
                                    : ''}
                            </DataTable>

                            <div className="nw-button-parent m-t-8 p-r-0">

                                {/* <Workbook
                                    filename={excelFileName}
                                    element={
                                        <Button
                                            label="Download Excel"
                                            icon="fas fa-download"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                        />
                                    }
                                >
                                    <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                        {excelColumns}
                                    </Workbook.Sheet>
                                </Workbook> */}

                                {/* <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Download PDF"
                                    icon="fas fa-download"
                                    onClick={this.exportDepositPdf}
                                /> */}

                            </div>

                            {/* :
                                    
                                    <BlankDataTableAnim/> 
                            } */}
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}