import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';

import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import Formatter from '../../../utils/Formatter';
import { Workbook } from 'react-excel-workbook';
import { pdfDownloadPortrait_FullTable } from '../../common/JsPdfDownload';
import { SplitButton } from 'primereact/splitbutton';

let maxDate = new Date();
let minDate = new Date();
let minToDate = new Date();
let maxToDate = new Date();
let errors = {}

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class DailyTransaction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            searchDataObj: {
                startDate: '',
                endDate: '',
                pageLimit: 0,
                pageNo: 0
            },
            totalDepositAmount: 0,
            totalWithdrawAmount: 0,
            totalTransferAmount: 0,
            totalPurchaseAmount: 0,
            totalDisbursmentAmount: 0,
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            errors: {},
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }

        this.NetiDateUtils = new NetiDateUtils();
        this.managementPointService = new ManagementPointService();
        this.ValidatorUtility = new ValidatorUtility();
        this.Formatter = new Formatter();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    startDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["startDate"] = "";
        searchDataObj.startDate = event.target.value;
        minToDate = event.target.value;
        maxToDate = new Date(event.target.value)
        maxToDate.setDate(minToDate.getDate() + 9);
        this.setState({ searchDataObj, errors })
    }

    endDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["endDate"] = "";
        searchDataObj.endDate = event.target.value;
        this.setState({ searchDataObj, errors })
    }

    searchHandleError = () => {
        let { errors, searchDataObj } = this.state;
        let formIsValid = true;
        if (searchDataObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start Date can't left empty";
        }
        if (searchDataObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End Date can't left empty";
        }

        if (searchDataObj.startDate.getDate)
            this.setState({ errors });
        return formIsValid;
    }

    onDateWiseSearchHandler = () => {
        let { searchDataObj, errors } = this.state;
        // this.setState({ errors: {} })

        if (this.searchHandleError()) {

            this.setState({ tableview: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(searchDataObj.endDate) >= Date.parse(searchDataObj.startDate)) {
                this.setState({
                    dataTableValue: [], totalDepositAmount: 0, totalWithdrawAmount: 0, totalTransferAmount: 0, totalPurchaseAmount: 0,
                })
                let saveSearchObj = { ...searchDataObj }
                saveSearchObj.startDate = NetiDateUtils.getDateFromString(searchDataObj.startDate);
                saveSearchObj.endDate = NetiDateUtils.getDateFromString(searchDataObj.endDate);
                this.managementPointService.fetchDailyTransactionSummary(saveSearchObj)
                    .then(res => {
                        if (res.status == 200) {
                            return res.json().then((body) => {

                                if (body.length == 0) {
                                    return this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
                                }
                                else {
                                    body.map(item => {
                                        item.depositAmount = item.depositAmount.toFixed(2);
                                        item.withdrawAmount = item.withdrawAmount.toFixed(2);
                                        item.transferAmount = item.transferAmount.toFixed(2);
                                        item.purchaseAmount = item.purchaseAmount.toFixed(2);
                                        item.revenueAmount = item.revenueAmount.toFixed(2);
                                        Object.assign(item, { formatedDate: NetiDateUtils.getAnyShortForm(item.date, 'DD-MMM-YYYY hh:mm:ss a') });  // for pdf column
                                    })
                                    this.setState({ dataTableValue: body, topProgressBar: false, tableView: true, dataTableIsLoading: false });

                                    this.setState({
                                        totalDepositAmount: this.ValidatorUtility.currencyFormatter(this.calculateTotalAmount(body, "depositAmount")),
                                        totalWithdrawAmount: this.ValidatorUtility.currencyFormatter(this.calculateTotalAmount(body, "withdrawAmount")),
                                        totalTransferAmount: this.ValidatorUtility.currencyFormatter(this.calculateTotalAmount(body, "transferAmount")),
                                        totalPurchaseAmount: this.ValidatorUtility.currencyFormatter(this.calculateTotalAmount(body, "purchaseAmount")),
                                        totalDisbursmentAmount: this.ValidatorUtility.currencyFormatter(this.calculateTotalAmount(body, "revenueAmount"))
                                    })
                                }
                            });
                        }
                        else if (res.status == 404) {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
                        }
                        else {
                            this.managementPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });
            } else {
                this.growl.show({ severity: 'warn', summary: 'Warning', detail: "To Date can not be earlier than From Date." });
                this.setState({ topProgressBar: false, errorMsgVisible: false, })

            }
        }
    }

    calculateTotalAmount(body, amountCategory) {
        return body.map(item => +item[`${amountCategory}`]).reduce((a, b) => (a + b));
    }

    depositAmountBody = (rowData) => {
        return (
            <div className="col-number text-right" >
                <Link to={{
                    pathname: MANAGEMENT_POINT.DAILY_DEPOSIT_AMOUNT_LIST,
                    searchObj: {
                        date: rowData.date,
                        type: 'deposit',
                        transType: 'deposit'
                    }
                }}>
                    <u>{this.ValidatorUtility.currencyFormatter(rowData.depositAmount)}</u>
                </Link>
            </div>
        )
    }

    withdrawAmountBody = (rowData) => {
        return (
            <div className="col-number text-right" >
                <Link to={{
                    pathname: MANAGEMENT_POINT.DAILY_WITHDRAW_AMOUNT_LIST,
                    searchObj: {
                        date: rowData.date,
                        type: 'withdraw',
                        transType: 'withdraw'
                    }

                }}>
                    <u>{this.ValidatorUtility.currencyFormatter(rowData.withdrawAmount)}</u>
                </Link>
            </div>
        )
    }

    transferAmountBody = (rowData) => {
        return (
            <div className="col-number text-right" >
                <Link to={{
                    pathname: MANAGEMENT_POINT.DAILY_TRANSFER_AMOUNT_LIST,
                    searchObj: {
                        date: rowData.date,
                        type: 'transfer',
                        transType: 'send',
                    }
                }}>
                    <u>{this.ValidatorUtility.currencyFormatter(rowData.transferAmount)}</u>
                </Link>
            </div>
        )
    }

    purchaseAmountBody = (rowData) => {
        return (
            <div className="col-number text-right" >
                <Link to={{
                    pathname: MANAGEMENT_POINT.DAILY_PURCHASE_AMOUNT_LIST,
                    searchObj: {
                        date: rowData.date,
                        type: 'purchase',
                        transType: 'purchase',
                    }
                }}>
                    <u>{this.ValidatorUtility.currencyFormatter(rowData.purchaseAmount)}</u>
                </Link>
            </div>
        )
    }

    disbursmentAmountBody = (rowData) => {
        return (
            <div className="col-number text-right" >
                {/* <Link to={{ pathname: MANAGEMENT_POINT.DAILY_DISBURSEMENT_AMOUNT_LIST }}>
                    <u> */}
                {this.ValidatorUtility.currencyFormatter(rowData.revenueAmount)}
                {/* </u>
                </Link> */}
            </div>
        )
    }

    getDateFromTemplate = (rowData) => {
        return NetiDateUtils.getAnyShortForm(rowData.date, 'DD-MMM-YYYY hh:mm:ss a');
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Date & Time", dataKey: "formatedDate" },
            { title: "Deposit Amount", dataKey: "depositAmount" },
            { title: "Withdraw Amount", dataKey: "withdrawAmount" },
            { title: "Transfer Amount", dataKey: "transferAmount" },
            { title: "Purchase Amount", dataKey: "purchaseAmount" },
            { title: "Disbursement Amount", dataKey: "revenueAmount" },
        ]

        let tableColStyles = {
            date: { cellWidth: 20.3, },
            depositAmount: { halign: "right", cellWidth: 30.5, },
            withdrawAmount: { halign: "right", cellWidth: 34, },
            transferAmount: { halign: "right", cellWidth: 32.7, },
            purchaseAmount: { halign: "right", cellWidth: 34.3, },
            revenueAmount: { halign: "right", cellWidth: 28.3, },
        }

        var totalArrayList = [
            {
                id: "1",
                totalPdf: "Total",
                totalAmount1: this.state.totalDepositAmount,
                totalAmount2: this.state.totalWithdrawAmount,
                totalAmount3: this.state.totalTransferAmount,
                totalAmount4: this.state.totalPurchaseAmount,
                totalAmount5: this.state.totalDisbursmentAmount,
            }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }, { dataKey: "totalAmount1" }, { dataKey: "totalAmount2" }, { dataKey: "totalAmount3" }, { dataKey: "totalAmount4" }, { dataKey: "totalAmount5" },];

        let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, cellWidth: 20.3, fontStyle: "bold", overflow: "linebreak" },
            totalAmount1: { halign: "right", fontStyle: "bold", fontSize: 10, cellWidth: 30.5 },
            totalAmount2: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 34 },
            totalAmount3: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 32.7 },
            totalAmount4: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 34.3 },
            totalAmount5: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 28.3 }
        }

        pdfDownloadPortrait_FullTable("Balance Transactions (Date Wise)", 70, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableColStyles, totalColumns, totalArrayList, totalColumnStyles, "Daily_Balance_Transaction_Report.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, searchDataObj, messageDetailsDialog } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (searchDataObj.startDate && searchDataObj.endDate) {
            formatedStartDate = (searchDataObj.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (searchDataObj.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = formatedStartDate + ' to ' + formatedEndDate + "_Daily_Transactions.xlsx";
        let excelSheetName = "Daily_Transactions";
        excelColumns = [
            <Workbook.Column value="formatedDate" label="Date & Time" />,
            <Workbook.Column value="depositAmount" label="Discount Amount" />,
            <Workbook.Column value="withdrawAmount" label="Withdraw Amount" />,
            <Workbook.Column value="transferAmount" label="Transfe Amount" />,
            <Workbook.Column value="purchaseAmount" label="Purchase Amount" />,
            <Workbook.Column value="revenueAmount" label="Disbursement Amount" />,
        ];
        // end excel

        if (Date.parse(maxToDate) >= Date.parse(maxDate)) {
            maxToDate = new Date()
        }

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Balance Transaction (Date Wise)</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" className="nw-tfoot-total-text" />
                <Column footer={this.state.totalDepositAmount} />
                <Column footer={this.state.totalWithdrawAmount} />
                <Column footer={this.state.totalTransferAmount} />
                <Column footer={this.state.totalPurchaseAmount} />
                <Column footer={this.state.totalDisbursmentAmount} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                {/* <div className="p-grid"> */}
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className='main-section'>
                    <div className="nw-form">
                        <div className="p-col-12 p-lg-12 p-xl-12">

                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            value={searchDataObj.startDate}
                                            onChange={this.startDateHandler}
                                            dateFormat="dd/mm/yy"
                                            showIcon={true}
                                            placeholder="Select Date"
                                            autoWidth={false}
                                            yearRange="2010:2030"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            showButtonBar={true}
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            value={searchDataObj.endDate}
                                            onChange={this.endDateHandler}
                                            dateFormat="dd/mm/yy"
                                            showIcon={true}
                                            placeholder="Select Date"
                                            autoWidth={false}
                                            yearRange="2010:2030"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            showButtonBar={true}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button-primary nw-button nw-button-center"
                                        icon="fas fa-search"
                                        onClick={this.onDateWiseSearchHandler}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="nw-data-table">
                            <div className="p-col-12 p-lg-12 p-xl-12">

                                <DataTable
                                    header={tableHeader}
                                    footerColumnGroup={footer}
                                    responsive={true}
                                    selectionMode="single"
                                    rowsPerPageOptions={[10, 20, 30]}
                                    paginator={true}
                                    rows={10}
                                    value={this.state.dataTableValue}

                                >
                                    <Column field="date" header="Date & Time" className="table-datetime" body={this.getDateFromTemplate} filter={true} />
                                    <Column className="col-number" field="depositAmount" header="Deposit Amount" body={this.depositAmountBody} filter={true} />
                                    <Column className="col-number" field="withdrawAmount" header="Withdraw Amount" body={this.withdrawAmountBody} filter={true} />
                                    <Column className="col-number" field="transferAmount" header="Transfer Amount" body={this.transferAmountBody} filter={true} />
                                    <Column className="col-number" field="purchaseAmount" header="Purchase Amount" body={this.purchaseAmountBody} filter={true} />
                                    <Column className="col-number" field="revenueAmount" header="Disbursement Amount" body={this.disbursmentAmountBody} filter={true} />

                                </DataTable>

                                {/* :
                                        
                                        <BlankDataTableAnim/> 
                                } */}
                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                <div style={{ display: 'none' }}>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                label="Download Excel"
                                                id="clickExcelBtn"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>

                            </div >

                        </div>

                    </div>

                </div>
            </div>

            // </div>
        )
    }
}