import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService'

export class SaCommonService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    getDateWiseSmsBalance(fromDate,toDate) {

        let uri = this.baseURIHolder.getUser()+'/sa/sms/multiple-institute/sms-info?fromDate='+fromDate+'&toDate='+toDate;

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    console.log('No date found this Redirect Api ', uri);
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }


    findObserverAttendanceInfoByDate(requestDate) {
        let uri = this.baseURIHolder.getEm() + `/observer/single-date/attendance/by?date=${requestDate}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findObserverAttendanceInfoByArea(areaID,date) {
        let uri = this.baseURIHolder.getEm() + `/observer/single-area/attendance/by?areaID=${areaID}&date=${date}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findInstituteWiseObserverAreaAttendance(areaID,date) {
        let uri = this.baseURIHolder.getEm() + `/observer/institute-wise/attendance/by?areaID=${areaID}&date=${date}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findSingleInstituteWiseAttendanceInfo(date,instituteID) {
        let uri = this.baseURIHolder.getEm() + `/observer/single-institute/attendance/by?date=${date}&instituteID=${instituteID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findObserverLayerType() {
        let uri = this.baseURIHolder.getEm() + '/tagged/observer';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
}