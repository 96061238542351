import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { InputText } from 'primereact/inputtext';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { Dialog } from 'primereact/dialog';
import CommonFuctionality from '../common/CommonFuctionality';
import NetiContentLoader from '../common/NetiContentLoader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { NetiFileHandler } from '../../utils/NetiFileHandler';

export class PartnerAtAGlance extends Component {
    constructor() {
        super();
        this.state = {
            foundBankAccountDialog:false,
            foundBankAccountDetails:[],
            pointTableDetails:[],
            totalStockOutDetails:[],
            deviceTaggedDetails:[],
            deviceNonTaggedDetails:[],
            usedPurchaseCodeDetails:[],
            unUsedPurchaseCodeDetails:[],
            pointInfoDialog:false,
            stockView:false,
            tagged:false,
            nonTagged:false,
            usedCode:false,
            unUsedCode:false,
            errors: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            partnerID:'',
            showInformation:false,
            profilePicture:'',
            partnerInfoObjects:{

            }
        }

        this.CRMPointService = new CRMPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.getProfileImg = this.getProfileImg.bind(this);
        this.CommonFuctionality = new CommonFuctionality();
        this.NetiContentLoader = new NetiContentLoader();
        this.netiFileHandler = new NetiFileHandler();
        
    }

    /**Date formet**/
    tableDateFormet = (rowData) =>{
        return <span>{NetiDateUtils.getDateFromStringDDMMYYYY(rowData['tag_date'])}</span> 
    }
    /*Get Bank Info*/
    bankAccountFoundDialog = (e) => {
        this.CRMPointService.partnerBankingoByNetiId(e).then(res => {
            if(res.status == 302){
                return res.json().then((body) => {
                    this.CommonFuctionality.blurDialogBackgroundActive();
                    this.setState({foundBankAccountDialog:true, foundBankAccountDetails: body});
                });
            }else{
                    this.setState({ foundBankAccountDialog:false, topProgressBar: false });
                    this.growl.show({severity: 'error', summary: 'Error', detail: 'No data found'});
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, foundBankAccountDialog:false, errorMsgVisible: true, errorMsgBody: "Please check your connection.",  })
        });
        
    }
    bankAccountFoundDialogHide = () => {
        this.setState({foundBankAccountDialog:false});
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    pointViewDialog = (e) => {
        this.CRMPointService.partnerPointsByNetiId(e).then(res => {
            if(res.status == 302){
                return res.json().then((body) => {
                    this.setState({pointInfoDialog:true, pointTableDetails: body});
                    this.CommonFuctionality.blurDialogBackgroundActive();
                    
                });
            }else{
                    this.setState({ pointInfoDialog:false, topProgressBar: false });
                    this.growl.show({severity: 'error', summary: 'Error', detail: 'No data found'});
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, pointInfoDialog:false, errorMsgVisible: true, errorMsgBody: "Please check your connection.",  })
        });
    }

    pointViewDialogHide = () => {
        this.setState({pointInfoDialog:false});
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    onChangepartnerID = (e) => {
        let { errors } = this.state;
        errors["partnerID"] = "";
        this.setState({ partnerID: e.target.value, errors });
    }
    
    stockViewDialog = (e) => {
        this.CRMPointService.partnerDeviceStockOut(e).then(res => {
            if(res.status == 302){
                return res.json().then((body) => {
                    this.setState({stockView:true, totalStockOutDetails: body});
                    this.CommonFuctionality.blurDialogBackgroundActive();
                });
            }else{
                    this.setState({ stockView:false, topProgressBar: false });
                    this.growl.show({severity: 'error', summary: 'Error', detail: 'No data found'});
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, stockView:false, errorMsgVisible: true, errorMsgBody: "Please check your connection.",  })
        });
    }

    stockViewDialogHide = () => {
        this.setState({stockView:false});
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    taggedViewDialog = (e) => {
        this.CRMPointService.partnerDeviceTagged(e).then(res => {
            if(res.status == 302){
                return res.json().then((body) => {
                    this.setState({tagged:true, deviceTaggedDetails: body});
                    this.CommonFuctionality.blurDialogBackgroundActive();
                });
            }else{
                    this.setState({ tagged:false, topProgressBar: false });
                    this.growl.show({severity: 'error', summary: 'Error', detail: 'No data found'});
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, tagged:false, errorMsgVisible: true, errorMsgBody: "Please check your connection.",  })
        });
    }

    taggedViewDialogHide = () => {
        this.setState({tagged:false});
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    nonTaggedViewDialog = (e) => {
        this.CRMPointService.partnerDeviceNonTagged(e).then(res => {
            if(res.status == 302){
                return res.json().then((body) => {
                    this.setState({nonTagged:true, deviceNonTaggedDetails: body});
                    this.CommonFuctionality.blurDialogBackgroundActive();
                });
            }else{
                    this.setState({ nonTagged:false, topProgressBar: false });
                    this.growl.show({severity: 'error', summary: 'Error', detail: 'No data found'});
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, nonTagged:false, errorMsgVisible: true, errorMsgBody: "Please check your connection.",  })
        });
    }

    nonTaggedViewDialogHide = (e) => {
        this.setState({nonTagged:false});
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    usedCodeViewDialog = (e) => {
        this.CRMPointService.getUsedPurchaseCode(e).then(res => {
            if(res.status == 302){
                return res.json().then((body) => {
                    this.setState({usedCode:true, usedPurchaseCodeDetails: body});
                    this.CommonFuctionality.blurDialogBackgroundActive();
                });
            }else{
                    this.setState({ usedCode:false, topProgressBar: false });
                    this.growl.show({severity: 'error', summary: 'Error', detail: 'No data found'});
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, usedCode:false, errorMsgVisible: true, errorMsgBody: "Please check your connection.",  })
        });
    }

    usedCodeViewDialogHide = () => {
        this.setState({usedCode:false});
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    unUsedCodeViewDialog = (e) => {
        this.CRMPointService.getUnusedPurchaseCode(e).then(res => {
            if(res.status == 302){
                return res.json().then((body) => {
                    this.setState({unUsedCode:true, unUsedPurchaseCodeDetails: body});
                    this.CommonFuctionality.blurDialogBackgroundActive();
                });
            }else{
                    this.setState({ unUsedCode:false, topProgressBar: false });
                    this.growl.show({severity: 'error', summary: 'Error', detail: 'No data found'});
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, unUsedCode:false, errorMsgVisible: true, errorMsgBody: "Please check your connection.",  })
        });
    }

    unUsedCodeViewDialogHide = () => {
        this.setState({unUsedCode:false});
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    onSearchHandler = () => {
        if (this.searchRegError()) {
            this.setState({ topProgressBar: true,  errorMsgVisible: false });
            this.CRMPointService.findEmDetailsByNetiId(this.state.partnerID)
                .then(res => {

                    if (res.status == 302) {
                        return res.json().then((body) => {
                            if (body.userInfoInfoMap) {
                                this.setState({ partnerInfoObjects: body, showInformation: true, topProgressBar: false, errorMsgVisible: false  });
                                this.getProfileImg();
                            } else{
                                this.setState({ partnerInfoObjects: "", showInformation: false, topProgressBar: false, errorMsgVisible: false  });
                                this.growl.show({severity: 'error', summary: 'Error', detail: 'No data found'});
                            }
                        });

                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ showInformation: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody,  });
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, showInformation: false, errorMsgVisible: true, errorMsgBody: "Please check your connection.",  })
                });

        }

    }

    /****find profile image*/
    getProfileImg() {
        let { partnerInfoObjects } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        let photoMainPath;

        if (partnerInfoObjects.userInfoInfoMap.imagePath) {
           photoMainPath = "/images/nw/user/profile/" + partnerInfoObjects.userInfoInfoMap.image
        }
        else {
            photoMainPath = partnerInfoObjects.userInfoInfoMap.imagePath
        }
        
        if (photoMainPath !== null) {
            this.netiFileHandler.getByteImage(photoMainPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                console.log("BODY", body);

                                let contentType = this.netiFileHandler.getImageContentType(partnerInfoObjects.userInfoInfoMap.image);
                                this.setState({ profilePicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ profilePicture: staticImg });
                    }
                });
        } else {
            this.setState({ profilePicture: staticImg });
        }

    }

    searchRegError = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.partnerID === '') {
            formIsValid = false;
            errors["partnerID"] = "Partner ID can't left empty.";
        }
        if(this.state.partnerID.length < 10){
            formIsValid = false;
            errors["partnerID"] = "Partner ID Is Too Sort";
        }

        if(this.state.partnerID.length > 10){
            formIsValid = false;
            errors["partnerID"] = "Partner ID Is Invalid";
        }

        this.setState({ errors });
        return formIsValid;
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, partnerInfoObjects, totalStockOutDetails, foundBankAccountDetails, pointTableDetails, deviceTaggedDetails, usedPurchaseCodeDetails, unUsedPurchaseCodeDetails } = this.state;

        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Account Info<span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {foundBankAccountDetails.length}
            </span>
        </div>;

        let pointInfoHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Point info <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {pointTableDetails.length}
            </span>
        </div>;

        let stockInfoHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Total Stock Out <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {totalStockOutDetails.length}
            </span>
        </div>;

        let taggedHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Tagged <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {deviceTaggedDetails.length}
            </span>
        </div>;

        let usedHeader = <div className="header-title">
                            <div className="header-title-left">
                            Used Code List
                            </div>
                            <div className="header-title-right">
                                <a>Total Found: {usedPurchaseCodeDetails.length}</a>
                            </div>
                        </div>;

        let unUsedHeader = <div className="header-title">
                                <div className="header-title-left">
                                Unused Code List
                                </div>
                                <div className="header-title-right">
                                    <a>Total Found: {unUsedPurchaseCodeDetails.length}</a>
                                </div>
                            </div>;

        return (
            <div className="p-fluid partner-at-a-glance">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section blur-section">
                    <div className="nw-form">
                        <div className="p-col-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Neti Id <span>*</span></span>
                                        <InputText
                                            value={this.state.partnerID}
                                            onChange={this.onChangepartnerID}
                                            placeholder="Enter Neti ID"
                                            style={{ width: "100%" }}
                                            type="text"
                                            keyfilter="pint"
                                            name="partnerID"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["partnerID"]}</span>
                                </div>
                                <div className="p-col-12"></div>
                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                            </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-left"
                                        name="submit"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSearchHandler}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                    { this.state.showInformation === true?
                        <div className="p-col-12 nw-m-t-60">
                            <div className="portal-dashboard-profile at-a-glance partner-at-a-glance gradient-parpel">
                                <div className="layout-dashboard">
                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="portal-profile-info-wrapper">
                                            <div className={ partnerInfoObjects.userInfoInfoMap.user_status === String("Enable") ? "portal-profile-image user-active" : "portal-profile-image"}>
                                                <img
                                                    src={partnerInfoObjects && partnerInfoObjects.userInfoInfoMap.imagePath ? this.state.profilePicture : ''}
                                                />
                                            </div>
                                            <div className="portal-profile-info">
                                                <h1>{partnerInfoObjects.userInfoInfoMap.name ? partnerInfoObjects.userInfoInfoMap.name : ''}</h1>
                                                <h3>{partnerInfoObjects.userInfoInfoMap.customID ? `NETI ID : ${partnerInfoObjects.userInfoInfoMap.customID}` : ''} </h3>
                                            </div>
                                            {partnerInfoObjects.userInfoInfoMap.wallet_balance !== '' ?
                                                <div className="basic-detais-wrapper">
                                                    <div className="netiWorld-DashBoard-wrapper">
                                                        <div class="notify-box-title">
                                                            <div class="notify-box-icon wallet-icon"></div>
                                                            <div>
                                                                <p>Wallet Balance</p>
                                                                <h1 class="font-16 color-white">{Math.floor(partnerInfoObjects.userInfoInfoMap.wallet_balance)}</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : '' }
                                            {partnerInfoObjects.userInfoInfoMap.sms_balance !== '' ?
                                                <div className="basic-detais-wrapper">
                                                    <div class="netiWorld-DashBoard-wrapper">
                                                        <div class="notify-box-title">
                                                            <div class="notify-box-icon msg-icon"></div>
                                                            <div>
                                                                <p>SMS Balance</p>
                                                                <h1 class="font-16 color-white">{Math.floor(partnerInfoObjects.userInfoInfoMap.sms_balance)}</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : '' }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nw-m-t-45">
                                <div className="p-grid">
                                    <div className="p-xs-12 p-sm-12 p-lg-4 p-xl-4">
                                        <div className="at-glance-single-info-box">
                                            <div className="protal-single-info-title gradient-parpel">
                                                <h4>Basic Info:</h4>
                                            </div>
                                            
                                            <div className="protal-single-info-content-box">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td>Date of Birth</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.dob ? partnerInfoObjects.userInfoInfoMap.dob : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Age</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.age ? `${partnerInfoObjects.userInfoInfoMap.age} Years` : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mobile</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.mobile ? partnerInfoObjects.userInfoInfoMap.mobile : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.email ? partnerInfoObjects.userInfoInfoMap.email : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gender</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.gender ? partnerInfoObjects.userInfoInfoMap.gender : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Religion</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.religion ? partnerInfoObjects.userInfoInfoMap.religion : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Country</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.country ? partnerInfoObjects.userInfoInfoMap.country : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Division</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.division ? partnerInfoObjects.userInfoInfoMap.division : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>District</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.district ? partnerInfoObjects.userInfoInfoMap.district : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Upozilla</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.upozila ? partnerInfoObjects.userInfoInfoMap.upozila : ''}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <table style={{marginTop:"60px"}}> 
                                                    <tbody>
                                                    <tr>
                                                        <td>Profile Lock</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.profile_lock ? partnerInfoObjects.userInfoInfoMap.profile_lock : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>User Name</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.userName ? partnerInfoObjects.userInfoInfoMap.userName : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Last Login</td>
                                                        <td>{partnerInfoObjects.userLastLoginMap.last_Login_Date ? partnerInfoObjects.userLastLoginMap.last_Login_Date : ''} at {partnerInfoObjects.userLastLoginMap.last_Login_Time ? partnerInfoObjects.userLastLoginMap.last_Login_Time : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>User From</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.useFrom ? partnerInfoObjects.userInfoInfoMap.useFrom : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Duration</td>
                                                        <td>{partnerInfoObjects.userInfoInfoMap.duration ? `${partnerInfoObjects.userInfoInfoMap.duration} Years` : '0'}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>User Status</td>
                                                        {/* <td>{partnerInfoObjects.userInfoInfoMap.user_status ?  partnerInfoObjects.userInfoInfoMap.user_status  : ''} {partnerInfoObjects.userInfoInfoMap.user_status ?  <span> (Edit)</span>  : ''}</td> */}
                                                        <td>{partnerInfoObjects.userInfoInfoMap.user_status ?  partnerInfoObjects.userInfoInfoMap.user_status  : ''} {partnerInfoObjects.userInfoInfoMap.user_status ?  <span> (Edit)</span>  : ''}</td>

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-xs-12 p-sm-12 p-lg-4 p-xl-4">
                                        <div className="at-glance-single-info-box">
                                            <div className="protal-single-info-title gradient-parpel">
                                                <h4>Partner Other's Info</h4>
                                            </div>
                                            <div className="protal-single-info-content-box">
                                                <div class="inner-title m-b-10">
                                                    <p>Bank Account Found : <span id={partnerInfoObjects.userInfoInfoMap.netiID ? partnerInfoObjects.userInfoInfoMap.netiID : ''} onClick={ (e) => this.bankAccountFoundDialog(e.target.id)}>{partnerInfoObjects.userAccountMap.total_Account ?  partnerInfoObjects.userAccountMap.total_Account  : ''}</span></p>
                                                </div>
                                                <div class="inner-title m-b-10">
                                                    <p>Point Found : <span id={partnerInfoObjects.userInfoInfoMap.netiID ? partnerInfoObjects.userInfoInfoMap.netiID : ''} onClick={ (e) => this.pointViewDialog(e.target.id)}>{partnerInfoObjects.userInfoInfoMap.total_point ?  partnerInfoObjects.userInfoInfoMap.total_point  : ''}</span></p>
                                                </div>
                                                <div class="inner-title">
                                                    <p>Attendance Device Information</p>
                                                </div>
                                                <table className="m-b-20">
                                                    <tbody>
                                                    <tr>
                                                        <td>Total Stock Out</td>
                                                        <td><span id={partnerInfoObjects.userInfoInfoMap.netiID ? partnerInfoObjects.userInfoInfoMap.netiID : ''} onClick={(e) => this.stockViewDialog(e.target.id)}>{partnerInfoObjects.attandenceDeviceMap.total_stock_out ?  partnerInfoObjects.attandenceDeviceMap.total_stock_out  : ''}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tagged</td>
                                                        <td><span id={partnerInfoObjects.userInfoInfoMap.netiID ? partnerInfoObjects.userInfoInfoMap.netiID : ''} onClick={(e) => this.taggedViewDialog(e.target.id)}>{partnerInfoObjects.attandenceDeviceMap.tagged ?  partnerInfoObjects.attandenceDeviceMap.tagged  : ''}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Non Tagged</td>
                                                        <td><span id={partnerInfoObjects.userInfoInfoMap.netiID ? partnerInfoObjects.userInfoInfoMap.netiID : ''} onClick={(e) => this.nonTaggedViewDialog(e.target.id)}>{partnerInfoObjects.attandenceDeviceMap.non_tagged ?  partnerInfoObjects.attandenceDeviceMap.non_tagged  : ''}</span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <div class="inner-title">
                                                    <p>Token Information</p>
                                                </div>
                                                <table className="m-b-20">
                                                    <tbody>
                                                    <tr>
                                                        <td>Submit</td>
                                                        <td>{partnerInfoObjects.userTokenMap.submit ?  partnerInfoObjects.userTokenMap.submit  : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Processing</td>
                                                        <td>{partnerInfoObjects.userTokenMap.processing ?  partnerInfoObjects.userTokenMap.processing  : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Solved</td>
                                                        <td>{partnerInfoObjects.userTokenMap.solve ?  partnerInfoObjects.userTokenMap.solve  : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pending</td>
                                                        <td>{partnerInfoObjects.userTokenMap.pending ?  partnerInfoObjects.userTokenMap.pending  : ''}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <div class="inner-title">
                                                    <p>Purchase Code Information</p>
                                                </div>
                                                <table className="m-b-20">
                                                <tbody>
                                                <tr>
                                                        <td>Used Code</td>
                                                        <td><span id={partnerInfoObjects.userInfoInfoMap.netiID ? partnerInfoObjects.userInfoInfoMap.netiID : ''} onClick={(e) => this.usedCodeViewDialog(e.target.id)}>{partnerInfoObjects.purchaseCodeMap.used_code ?  partnerInfoObjects.purchaseCodeMap.used_code  : ''}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Unused Code</td>
                                                        <td><span id={partnerInfoObjects.userInfoInfoMap.netiID ? partnerInfoObjects.userInfoInfoMap.netiID : ''} onClick={(e) => this.unUsedCodeViewDialog(e.target.id) }>{partnerInfoObjects.purchaseCodeMap.unUsed_code ?  partnerInfoObjects.purchaseCodeMap.unUsed_code  : ''}</span></td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                                <div class="inner-title">
                                                    <p>Chat Information</p>
                                                </div>
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td>Send</td>
                                                        <td>{partnerInfoObjects.userChatMap.send_chat ?  partnerInfoObjects.userChatMap.send_chat  : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Recieved</td>
                                                        <td>{partnerInfoObjects.userChatMap.receive_chat ?  partnerInfoObjects.userChatMap.receive_chat  : ''}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-xs-12 p-sm-12 p-lg-4 p-xl-4">
                                        <div className="wallet-information-wrapper m-b-10 gradient-parpel">
                                            <div className="wallet-information">
                                                <div className="wallet-title">
                                                    <h3>Wallet Report</h3>
                                                    <hr />
                                                </div>
                                                <div className="wallet-info-wrapper">
                                                    <div className="wallet-info">
                                                        <span>Deposit</span>
                                                        <span>Widthdraw</span>
                                                    </div>
                                                </div>
                                                <div className="wallet-info-wrapper">
                                                    <div className="wallet-info">
                                                        <span className="color-yellow">Transfer</span>
                                                        <span>Balance Statement</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wallet-information">
                                                <div className="wallet-title">
                                                    <h3>SMS Report</h3>
                                                    <hr />
                                                </div>
                                                <div className="wallet-info-wrapper">
                                                    <div className="wallet-info">
                                                        <span>Recharge</span>
                                                        <span>Send Eduman</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wallet-information m-b-0">
                                                <div className="wallet-title">
                                                    <h3>Product Purchase Report</h3>
                                                    <hr />
                                                </div>
                                                <div className="wallet-info-wrapper">
                                                    <div className="wallet-info">
                                                        <span>General Product</span>
                                                        <span>Offer Product</span>
                                                    </div>
                                                </div>
                                                <div className="wallet-info-wrapper">
                                                    <div className="wallet-info">
                                                        <span>Code Log</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {partnerInfoObjects.partnerInfoMap.partner_status === String("Enable") ?   
                                        <div className="at-glance-single-info-box">
                                            <div className="protal-single-info-title">
                                                <h4>Partner Status</h4>
                                            </div>
                                            <div className="protal-single-info-content-box">
                                                <table>
                                                    <tbody>
                                                    <tr> 
                                                        <td>Partner Point Found</td>
                                                        <td>{partnerInfoObjects.partnerInfoMap.Partner_Point_found ? partnerInfoObjects.partnerInfoMap.Partner_Point_found : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Partner Designation</td>
                                                        <td>{partnerInfoObjects.partnerInfoMap.partner_designation ? partnerInfoObjects.partnerInfoMap.partner_designation : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Partner Grade</td>
                                                        <td>{partnerInfoObjects.partnerInfoMap.partner_grade ? partnerInfoObjects.partnerInfoMap.partner_grade : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Partner Area</td>
                                                        <td>{partnerInfoObjects.partnerInfoMap.partner_area ? partnerInfoObjects.partnerInfoMap.partner_area : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Assing Date</td>
                                                        <td>{partnerInfoObjects.partnerInfoMap.assign_date ? partnerInfoObjects.partnerInfoMap.assign_date : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Partner Status</td>
                                                        <td>{partnerInfoObjects.partnerInfoMap.partner_status ? partnerInfoObjects.partnerInfoMap.partner_status : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Team Lead Info</td>
                                                        <td>{partnerInfoObjects.partnerInfoMap.parent_partnerID ? partnerInfoObjects.partnerInfoMap.parent_partnerID : ''} <span>View</span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }

                </div>
                <div className="dialog-section">
                    <Dialog header="Bank Account info" visible={this.state.foundBankAccountDialog} onHide={this.bankAccountFoundDialogHide} className="nw-dialog product-info-dialog admin-point">
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-data-table m-t-20">
                                        <DataTable
                                            header={header}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            value={this.state.foundBankAccountDetails}

                                        >
                                            <Column field="bnak_name"  header="Bank Name"  filter={true} />
                                            <Column field="account_name" header="Account Name" filter={true} />
                                            <Column field="account_no" header="Account No." filter={true} />
                                            <Column field="account_details" header="Account Details" filter={true} />
                                            <Column field="account_status" header="Status" filter={true} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog> 
                    <Dialog header="Points" visible={this.state.pointInfoDialog} onHide={this.pointViewDialogHide} className="nw-dialog product-info-dialog admin-point">
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-data-table m-t-20">
                                    <DataTable
                                            header={pointInfoHeader}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            value={this.state.pointTableDetails}

                                        >
                                            <Column field="role_name"  header="Role Name"  filter={true} />
                                            <Column field="create_date" header="Create Date" filter={true} />
                                            <Column field="duration" header="Duration" filter={true} />
                                            <Column field="expire_date" header="Expire Date" filter={true} />
                                            <Column field="remain_expire_day" header="Remain Exp. Day" filter={true} />
                                            <Column field="lead_netiID" header="Lead Neti ID." filter={true} />
                                            <Column field="lead_name" header="Lead Name" filter={true} />

                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header="Stock Out" visible={this.state.stockView} onHide={this.stockViewDialogHide} className="nw-dialog product-info-dialog admin-point">
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-data-table m-t-20">
                                    <DataTable
                                            header={stockInfoHeader}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            value={this.state.totalStockOutDetails}

                                        >
                                            <Column field="device_ID"  header="Device ID"  filter={true} />
                                            <Column field="stock_out_date" header="Stock Out Date" filter={true} />
                                            <Column field="purchase_code" header="Used Purchased Code" filter={true} />
                                            <Column field="status" header="Status" filter={true} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header="Tagged" visible={this.state.tagged} onHide={this.taggedViewDialogHide} className="nw-dialog product-info-dialog admin-point">
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-data-table m-t-20">
                                    <DataTable
                                            header={taggedHeader}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            value={this.state.deviceTaggedDetails}
                                        >
                                            <Column field="device_id"  header="Device ID"  filter={true} />
                                            <Column field="institute_id" header="Institute ID" filter={true} />
                                            <Column field="institute_name" header="Institute Name" filter={true} />
                                            <Column field="tag_date" body={this.tableDateFormet} header="Tag Date" filter={true} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header="Non Tagged" visible={this.state.nonTagged} onHide={this.nonTaggedViewDialogHide} className="nw-dialog product-info-dialog admin-point">
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-data-table m-t-20">
                                        {/* {console.log('deviceNonTaggedDetails', this.state.deviceNonTaggedDetails)} */}
                                    <DataTable
                                            header={taggedHeader}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            value={this.state.deviceNonTaggedDetails}
                                        >
                                            <Column field="device_ID"  header="Device ID"  filter={true} />
                                            <Column field="stock_out_date" header="Stock Out Date" filter={true} />
                                            <Column field="duration" header="Srock Out Duration" filter={true} />
                                            <Column field="purchase_code" header="Used Purchased Code" filter={true} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header="Used Code" visible={this.state.usedCode} onHide={this.usedCodeViewDialogHide} className="nw-dialog product-info-dialog admin-point">
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-data-table m-t-20">
                                    <DataTable
                                            header={usedHeader}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            value={this.state.usedPurchaseCodeDetails}

                                        >
                                            <Column field="generate_date"  header="Generate Date"  filter={true} />
                                            <Column field="quantity_serial" header="Quantity Serial" filter={true} />
                                            <Column field="purchase_code" header="Purchased Code" filter={true} />
                                            <Column field="purchse_codeID" header="Purchased Code ID" filter={true} />
                                            <Column field="used_date" header="Use Date" filter={true} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header="Unused Code" visible={this.state.unUsedCode} onHide={this.unUsedCodeViewDialogHide} className="nw-dialog product-info-dialog admin-point">
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-data-table m-t-20">
                                        <DataTable
                                            header={unUsedHeader}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            value={this.state.unUsedPurchaseCodeDetails}
                                        >
                                            <Column field="generate_date"  header="Generate Date"  filter={true} />
                                            <Column field="quantity_serial" header="Quantity Serial" filter={true} />
                                            <Column field="purchase_code" header="Purchased Code" filter={true} />
                                            <Column field="purchse_codeID" header="Purchased Code ID" filter={true} />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                {/*Bank Account Found dialog end */}
            </div >
        )
    }
}
