import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { Growl } from 'primereact/growl';

import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { Workbook } from 'react-excel-workbook';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';
import { SplitButton } from 'primereact/splitbutton';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';

let maxDate = new Date();
let errors = {};

// pdf
let subTitleColumn = [];
let subTitleList = [];
export class ProductPurchaseRevenue extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchDataObj: {
                startDate: '',
                endDate: '',
                pageLimit: 0,
                pageNo: 0
            },
            dataTableValue: [
            ],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            errors: {},
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }

        this.managementPointService = new ManagementPointService();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);
        this.validatorUtility = new ValidatorUtility();


    }

    startDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["startDate"] = "";
        searchDataObj.startDate = event.target.value;
        this.setState({ searchDataObj, errors })
    }

    endDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["endDate"] = "";
        searchDataObj.endDate = event.target.value;
        this.setState({ searchDataObj, errors })
    }

    searchHandleError = () => {
        let { errors, searchDataObj } = this.state;
        let formIsValid = true;
        if (searchDataObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start date can't left empty";
        }
        if (searchDataObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End date can't left empty";
        }
        this.setState({ errors });
        return formIsValid;
    }

    calculateTotalAmount = (body, amountCategory) => {
        return body.map(item => item[`${amountCategory}`]).reduce((a, b) => (a + b));
    }

    onDateWiseSearchHandler = () => {
        let { searchDataObj, errors } = this.state;
        this.setState({ errors: {} })
        
        if (this.searchHandleError()) {
            if (Date.parse(searchDataObj.endDate) >= Date.parse(searchDataObj.startDate)) {
                this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
                this.managementPointService.findProductPurchseRevenueDistribution(searchDataObj)
                .then((res) => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            for(let i=0; i< body.length; i++) {
                                body[i]['totalQuantity'] = body[i]['totalQuantity'].toLocaleString('EN-IN');
                                body[i]['totalAmount'] = this.validatorUtility.currencyFormatter(body[i]['totalAmount']);
                                body[i]['revenueDistribution'] = this.validatorUtility.currencyFormatter(body[i]['revenueDistribution']);
                                body[i]['levelRevenue'] = this.validatorUtility.currencyFormatter(body[i]['levelRevenue']);
                                body[i]['netizenRevenue'] = this.validatorUtility.currencyFormatter(body[i]['netizenRevenue']); 
    
                            }
                            setTimeout(() => {
                                console.log('body',body);
                                this.setState({dataTableValue: body});
                            }, 500);
                            this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        });
                    } else {
                        this.managementPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                    }
                }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })
    
                );






            } else {
                this.growl.show({ severity: 'warn', summary: 'Warning', detail: "To Date can not be earlier than From Date." });
            }
        }
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Product Name", dataKey: "productName" },
            { title: "Total Quantity", dataKey: "totalQuantity" },
            { title: "Total Amount", dataKey: "totalAmount" },
            { title: "Revenue Distribution", dataKey: "revenueDistribution" },
            { title: "Level Distribution", dataKey: "levelRevenue" },
            { title: "Netizen Revenue", dataKey: "netizenRevenue" },
        ]

        let tableColStyle = { amount: { halign: 'right' } }

        pdfDownloadPortrait_TableWithSubTitle("Product Purchase Revenue", 72, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableColStyle, "Product_Purchase_Revenue.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody, searchDataObj } = this.state;

        let tableHeader = <div className="header-title">
        <div className="header-title-left"> Product Purchase Revenue List</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (searchDataObj.startDate && searchDataObj.endDate) {
            formatedStartDate = (searchDataObj.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (searchDataObj.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = formatedStartDate + ' to ' + formatedEndDate + "_Product Purchase Revenue.xlsx";
        let excelSheetName = "Product_Purchase_Revenue";
        excelColumns = [
            <Workbook.Column value="productName" label="Product Name" />,
            <Workbook.Column value="totalQuantity" label="Total Quantity" />,
            <Workbook.Column value="totalAmount" label="Total Amount" />,
            <Workbook.Column value="revenueDistribution" label="Revenue Distribution" />,
            <Workbook.Column value="levelRevenue" label="Level Distribution" />,
            <Workbook.Column value="netizenRevenue" label="Netizen Revenue" />,
        ];
        // end excel

        return (
            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-lg-12 p-xl-12">

                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            value={searchDataObj.startDate}
                                            dateFormat="dd/mm/yy"
                                            onChange={this.startDateHandler}
                                            showIcon={true}
                                            placeholder="Select Date"
                                            autoWidth={false}
                                            yearRange="2010:2030"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            showButtonBar={true}
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            value={searchDataObj.endDate}
                                            dateFormat="dd/mm/yy"
                                            onChange={this.endDateHandler}
                                            showIcon={true}
                                            placeholder="Select Date"
                                            autoWidth={false}
                                            yearRange="2010:2030"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            showButtonBar={true}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button-primary nw-button nw-button-center"
                                        icon="fas fa-search"
                                        onClick={this.onDateWiseSearchHandler}
                                    />
                                </div>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="nw-data-table">
                            <div className="p-col-12 p-lg-12 p-xl-12">                            {/* {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ? 
                                        this.NetiContentLoader.MyPointTableLoader()
                                        : */}
                                <DataTable
                                    header={tableHeader}
                                    responsive={true}
                                    selectionMode="single"
                                    paginator={true}
                                    rows={10}
                                    value={this.state.dataTableValue}
                                //selection={this.state.dataTableSelection}
                                >

                                    <Column field="productName" header="Product Name" filter={true} />
                                    <Column field="totalQuantity" header="Total Quantity" filter={true} />
                                    <Column field="totalAmount" header="Total Amount" filter={true} />
                                    <Column field="revenueDistribution" header="Revenue Distribution" filter={true} />
                                    <Column field="levelRevenue" header="Level Distribution" filter={true} />
                                    <Column field="netizenRevenue" header="Netizen Revenue" filter={true} />

                                </DataTable>

                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                <div style={{ display: 'none' }}>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                label="Download Excel"
                                                id="clickExcelBtn"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>

                            </div >

                    </div>

                </div>

            </div>

            // </div>
        )
    }
}