import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { BannerList } from "./BannerList";
import { UserCategoryService } from "../../../service/UserCategoryService";
import { AdminPointService } from "../../../service/adminPoint/AdminPointService";

import { NetiFileHandler } from "../../../utils/NetiFileHandler";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { Editor } from "primereact/editor";
import NetiContentLoader from "../../common/NetiContentLoader";

let dialogStatus = false;
let cloneStateBeforeMount;
let errors = {};
export class CreateBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      bannerCategory: "",
      bannerCategoryInfo: {},
      bannerTitleInfo: {},
      bannerCategoryList: [],
      bannerTitleList: [],
      firstDropdownIsLoading: false,
      bannerInfo: {
        coreCategoryInfoDTO: {
          coreCategoryID: "",
        },
        bannerTitle: "",
        url: "",
        serial: "",
        bannerDetails: "",
      },
      uploadImage: {
        extention: "",
        contentName: "",
        contentPic: "",
      },
      proPic: {
        extention: "",
        contentPic: "",
        contentName: "",
      },
      errors: {},
      dataTableIsLoading: false,
      secondDropDownIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: "",
    };

    this.netiFileHandler = new NetiFileHandler();
    this.NetiContentLoader = new NetiContentLoader();
    this.UserCategoryService = new UserCategoryService();
    this.AdminPointService = new AdminPointService();
    this.onBasicUploadAuto = this.onBasicUploadAuto.bind(this);
  }

  componentWillMount() {
    this.getCategoryList();
    cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
  }

  onChangeBannerCategory = (e) => {
    let {
      bannerInfo,
      errors,
      bannerCategoryList,
      bannerCategoryInfo,
    } = this.state;
    bannerInfo.coreCategoryInfoDTO.coreCategoryID = e.target.value;
    errors["bannerId"] = "";

    for (let i = 0; i < bannerCategoryList.length; i++) {
      if (bannerCategoryList[i].coreCategoryID == e.target.value) {
        bannerCategoryInfo = bannerCategoryList[i];
        this.setState({ bannerCategoryInfo });
      }
    }

    this.setState({ bannerInfo, errors });
  };

  onChangeBannerTitle = (e) => {
    let { bannerInfo, errors, bannerTitleList, bannerTitleInfo } = this.state;
    bannerInfo.bannerTitle = e.target.value;
    this.setState({ bannerInfo });
    errors["bannerTitle"] = "";
  };

  onChangeBannerUrl = (e) => {
    let { bannerInfo, errors } = this.state;
    // let url = e.target.value;
    // let trimUrlStart = url.replace("https://drive.google.com/file/d/",'');
    // let trimUrlEnd = trimUrlStart.replace('/view','');
    // console.log('onchange',trimUrlEnd);
    // bannerInfo.url = trimUrlEnd;
    this.setState({ url: e.target.value });
    errors["url"] = "";
  };

  onChangeBannerDetails = (e) => {
    let { bannerInfo, errors } = this.state;
    bannerInfo.bannerDetails = e.htmlValue;
    this.setState({ bannerInfo });
    errors["bannerDetails"] = "";
  };

  onChangeBannerSerial = (e) => {
    let { bannerInfo, errors } = this.state;
    bannerInfo.serial = e.target.value;
    this.setState({ bannerInfo });
    errors["bannerSerial"] = "";
  };

  onSubmitHandler = () => {
    let {
      bannerInfo,
      bannerCategoryInfo,
      errors,
      bannerTitleInfo,
    } = this.state;
    bannerInfo.defaultCode = bannerCategoryInfo.categoryDefaultCode;
    let trimUrlStart = this.state.url.replace(
      "https://drive.google.com/file/d/",
      ""
    );
    let trimUrlEnd = trimUrlStart.replace("/view", "");
    bannerInfo.url = trimUrlEnd;
    bannerInfo.fileContent = this.state.proPic.contentPic;
    bannerInfo.attachmentFileName = this.state.proPic.contentName;
    bannerInfo.fileSaveOrEditable = true;

    this.setState({ bannerInfo });
    console.log(bannerInfo);

    if (this.addBannerFormHandleError()) {
      this.setState({
        topProgressBar: true,
        errorMsgVisible: false,
      });
      this.setState({ dataTableIsLoading: true });

      this.AdminPointService.createAdminPointBanner(bannerInfo)
        .then((res) => {
          if (res.status == 201) {
            this.growl.show({
              severity: "success",
              summary: "Success Message",
              detail: "Banner Successfully Created!",
            });
            this.setState({ dataTableIsLoading: false });
            this.setState({
              proPic: cloneStateBeforeMount.proPic,
              bannerInfo: cloneStateBeforeMount.bannerInfo,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          } else {
            this.dwsService.Auth.handleErrorStatus(res).then((resp) => {
              this.setState({
                topProgressBar: false,
                errorMsgVisible: true,
                errorMsgBody: resp,
                dataTableIsLoading: false,
              });
            });
          }
        })
        .catch((error) => {
          this.setState({
            topProgressBar: false,
            errorMsgVisible: true,
            dataTableIsLoading: false,
            errorMsgBody: "Please check your connection",
          });
        });
    }
  };

  getCategoryList() {
    this.setState({
      topProgressBar: true,
      firstDropdownIsLoading: true,
      errorMsgVisible: false,
    });
    this.UserCategoryService.fetchListByDefaultCode("T119")
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            errors["bannerId"] = "";
            this.setState({
              errors,
              bannerCategoryList: body,
              topProgressBar: false,
              firstDropdownIsLoading: false,
              errorMsgVisible: false,
            });
            // this.setState({ bankList: body });
          });
        } else {
          this.UserCategoryService.authService
            .handleErrorStatus(res)
            .then((resp) => {
              errors["bannerId"] = "Banner Category is empty.";
              this.setState({
                errors,
                topProgressBar: false,
                errorMsgVisible: false,
                errorMsgBody: resp,
                firstDropdownIsLoading: false,
              });
            });
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          firstDropdownIsLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection !!!",
        });
      });
  }

  addBannerFormHandleError = () => {
    let { errors, bannerInfo } = this.state;
    let formIsValid = true;
    console.log(bannerInfo);
    if (
      bannerInfo.coreCategoryInfoDTO.coreCategoryID === "" ||
      bannerInfo.coreCategoryInfoDTO.coreCategoryID === null
    ) {
      formIsValid = false;
      errors["bannerId"] = "Category can't left empty.";
    }
    if (bannerInfo.bannerTitle === "" || bannerInfo.bannerTitle === null) {
      formIsValid = false;
      errors["bannerTitle"] = "Title can't left empty.";
    }
    if (this.state.url === "") {
      formIsValid = false;
      errors["url"] = "URL can't left empty.";
    }
    if (bannerInfo.bannerDetails === "") {
      formIsValid = false;
      errors["bannerDetails"] = "Details can't left empty.";
    }
    if (bannerInfo.serial === "" || bannerInfo.serial === null) {
      formIsValid = false;
      errors["bannerSerial"] = "Serial can't left empty.";
    }

    if (bannerInfo.fileContent === "") {
      formIsValid = false;
      errors["depositSlip"] = "Image can't left empty.";
    }
    this.setState({ errors });
    return formIsValid;
  };

  onBasicUploadAuto(e) {
    let { errors } = this.state;
    errors["depositSlip"] = "";
    var reader = new FileReader();
    let photo = e.files[0];
    const scope = this;
    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = "data:" + photo.type + ";base64,"; //link will be same from the word webapps in URL
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      let album = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name,
      };
      scope.setState({ proPic: album });
    };
    console.log(this.state.proPic);
    this.setState({ errors });
  }

  render() {
    let uploadFileLabel = (
      <div>
        Click / Drag Image Here To Upload <br />
        <span className="upload-file-label">370 x 240 px (recommended)</span>
      </div>
    );

    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    console.log("!this.state.", !this.state.dataTableIsLoading);

    let { bannerInfo } = this.state;

    let categoryListOptions = [];
    if (this.state.bannerCategoryList && this.state.bannerCategoryList.length) {
      categoryListOptions = this.state.bannerCategoryList.map((item) => ({
        value: item.coreCategoryID,
        label: item.categoryName,
      }));
    }

    let bannerList = [];
    if (this.state.bannerTitleList && this.state.bannerTitleList.length) {
      bannerList = this.state.bannerTitleList.map((item) => ({
        value: item.coreCategoryID,
        label: item.categoryName,
      }));
    }

    let bannerSerialField = [];
    ((getBannerSerialFieldData) => {
      for (let i = 0; i < 50; i++) {
        bannerSerialField[i] = { label: i + 1, value: i + 1 };
      }
    })();

    let requiredSign = (
      <span style={{ color: "red", marginRight: "2px" }}>*</span>
    );

    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}

        <Growl ref={(el) => (this.growl = el)} />
        <div className="main-section">
          <div className="p-grid nw-form blur-section">
            <div className="p-col-12 p-xl-12">
              <div className="nw-form-body">
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Serial {requiredSign}
                    </span>
                    <Dropdown
                      options={bannerSerialField}
                      keyfilter="pint"
                      id="bannerSerial"
                      onChange={this.onChangeBannerSerial}
                      value={bannerInfo.serial}
                      placeholder="Select Serial"
                      style={{ width: "100%" }}
                      type="number"
                      name="bannerSerial"
                    />
                  </div>
                  <span className="error-message">
                    {this.state.errors["bannerSerial"]}
                  </span>
                </div>

                <div className="p-col-12 p-xl-12">
                  {this.state.firstDropDownIsLoading ? (
                    this.NetiContentLoader.normalFormInputField()
                  ) : (
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Banner Category {requiredSign}
                      </span>
                      <Dropdown
                        value={bannerInfo.coreCategoryInfoDTO.coreCategoryID}
                        options={categoryListOptions}
                        onChange={this.onChangeBannerCategory}
                        id="bannerId"
                        placeholder="Select Banner Category"
                        showClear={true}
                        type="text"
                        name="bannerId"
                        autoWidth={false}
                      />
                    </div>
                  )}
                  <span className="error-message">
                    {this.state.errors["bannerId"]}
                  </span>
                </div>

                <div className="p-col-12 p-xl-12">
                  {this.state.secondDropDownIsLoading ? (
                    this.NetiContentLoader.normalFormInputField()
                  ) : (
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Banner Title {requiredSign}
                      </span>
                      <InputText
                        id="bannerTitle"
                        name="bannerTitle"
                        placeholder="Select Banner Title"
                        value={bannerInfo.bannerTitle}
                        onChange={this.onChangeBannerTitle}
                        type="text"
                      />
                    </div>
                  )}
                  <span className="error-message">
                    {this.state.errors["bannerTitle"]}
                  </span>
                </div>

                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Banner URL {requiredSign}
                    </span>
                    <InputText
                      onChange={this.onChangeBannerUrl}
                      value={this.state.url}
                      placeholder="Enter Banner URL"
                      type="text"
                      id="url"
                      name="url"
                    />
                  </div>
                  <span className="error-message">
                    {this.state.errors["url"]}
                  </span>
                </div>

                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputtexteditor-label">
                      Banner Details {requiredSign}
                    </span>
                    <Editor
                      id="bannerDetails"
                      name="bannerDetails"
                      placeholder="Enter Banner Details"
                      value={bannerInfo.bannerDetails}
                      onTextChange={(e) => this.onChangeBannerDetails(e)}
                      autoWidth={false}
                      maxLength={200}
                    />
                  </div>
                  <span className="error-message">
                    {this.state.errors["bannerDetails"]}
                  </span>
                </div>

                {/* upload */}
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup nw-upload-button">
                    <span className="p-inputgroup-addon nw-inputtextarea-label">
                      Upload Image <span>*</span>
                      <br />
                      <span>(PNG/JPG/JPEG/GIF)</span>
                    </span>

                    <div className="nw-upload-button-inside">
                      {this.state.proPic.contentPic ||
                      this.state.institutePicture ? (
                        <div className="image-view-main">
                          <div className="upload-image-view">
                            <Button
                              className="delete-upload-button"
                              icon="fas fa-times-circle"
                              onClick={() =>
                                this.setState({
                                  proPic: { contentPic: "" },
                                })
                              }
                            />
                            <img
                              src={`data: ${this.state.proPic.extention};base64,${this.state.proPic.contentPic}`}
                              style={{ height: "80px" }}
                            />
                          </div>
                          <div className="image-title">
                            {this.state.proPic.contentName ||
                              this.state.instituteInfo.logoName}
                          </div>
                        </div>
                      ) : (
                        <FileUpload
                          chooseLabel={
                            this.state.proPic.contentName || uploadFileLabel
                          }
                          mode="basic"
                          accept="image/*"
                          maxFileSize={1000000}
                          onSelect={this.onBasicUploadAuto.bind(this)}
                          auto={true}
                        />
                      )}
                    </div>
                  </div>
                  <span className="error-message">
                    {this.state.errors["depositSlip"]}
                  </span>
                  {/* <span className="error-message">
                                    {errors["logo"]}
                                  </span> */}

                  <span className="error-message">
                    {this.state.proPic.contentName ? "" : errors["logo"]}
                  </span>
                </div>

                <div className="p-col-12 p-xl-12" />

                <div className="p-col-12 p-xl-12 nw-button-parent">
                  <div className="required-field">
                    (<span>*</span>) required fields
                  </div>

                  <Button
                    className="p-button p-button-primary nw-button nw-button-right"
                    label="Save"
                    icon="fas fa-check"
                    onClick={this.onSubmitHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-col-12 p-xl-12">
            <div>
              {this.state.dataTableIsLoading ? (
                this.NetiContentLoader.MyPointTableLoader()
              ) : (
                <div>
                  {<BannerList categoryListOptions={categoryListOptions} />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
