import React, { component, Component } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";

import { EmAdminPointService } from "../../../service/emAdminPoint/EmAdminPointService";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import NetiContentLoader from "../../common/NetiContentLoader";

// dialog content related
let selectedInstitute = '';
let dialogHeader = '';
let dialogTableHeader = '';
let dialogTableValue = [];
let dialogTableColumns = [];
let backBtnVisible = false;

let totalLengthOfDT = 0;
export class OnlinePaymentSystemEMAdminPoint extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,
            dataTableIsLoading2: false,
            
            visible: false,
            dataTableValue: [],
            dataWiseCollectionTableValue: [],
        }

        this.emAdminPointService = new EmAdminPointService();
        this.netiContentLoader = new NetiContentLoader();

        this.fetchOpsSummaryOf_InstituteList = this.fetchOpsSummaryOf_InstituteList.bind(this);
        this.fetchDrillDownDataOf_TotalCollection = this.fetchDrillDownDataOf_TotalCollection.bind(this);
        this.fetchDrillDownDataOf_CollectionAmnt = this.fetchDrillDownDataOf_CollectionAmnt.bind(this);
        this.fetch_DrillDownDataOf_TotalSettlement = this.fetch_DrillDownDataOf_TotalSettlement.bind(this);
        this.backOnPreviousTable = this.backOnPreviousTable.bind(this);
        this.resetDialogAndTableData = this.resetDialogAndTableData.bind(this);

        this.makeDialogVisible = this.makeDialogVisible.bind(this);
        this.makeDialogHide = this.makeDialogHide.bind(this);

    }

    componentWillMount() { this.fetchOpsSummaryOf_InstituteList(); }

    fetchOpsSummaryOf_InstituteList() {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.emAdminPointService.fetchDfpsInstituteWiseSummaryInfo()
            .then((res) => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        totalLengthOfDT = body.item.length;
                        this.setState({ dataTableValue: body.item });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    });
                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }

            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    fetchDrillDownDataOf_TotalCollection(instituteId) {

        this.setState({ topProgressBar: true, dataTableIsLoading2: true, errorMsgVisible: false });
        this.emAdminPointService.fetchOpsDateWiseCollectionList(instituteId)
            .then((res) => {

                if (res.status == 302) {
                    return res.json().then((body) => {

                        this.setState({ dataWiseCollectionTableValue: body.item })
                        // dialog content set
                        dialogTableHeader = <div className="header-title"><div className="header-title-left">Collection Amount Details List</div><div className="header-title-right" style={{ paddingRight: "10px" }}>Total Found : {body.item.length}</div></div>;
                        Array.prototype.push.apply(dialogTableValue, body.item);
                        dialogTableColumns.push(
                            <Column field="collectionDate" header="Date" />,
                            <Column field="collectionDay" header="Day" />,
                            <Column field="collectionAmount" header="Collection Amount" body={this.collectionAmountBody} />
                        )
                        // dialog content set end

                        this.setState({ topProgressBar: false, dataTableIsLoading2: false });
                    });

                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    fetchDrillDownDataOf_CollectionAmnt(collectionDate) {

        dialogHeader = 'Student Wise Total Collection';
        let splitDate = collectionDate.split("-");
        let formatedDate = splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0];

        this.setState({ topProgressBar: true, dataTableIsLoading2: true, errorMsgVisible: false });
        this.emAdminPointService.fetchOpsStudentWiseCollectionList(formatedDate, selectedInstitute)
            .then((res) => {

                if (res.status == 302) {

                    return res.json().then((body) => {
                        // dialog content set
                        dialogTableHeader = <div className="header-title"><div className="header-title-left">Collection Amount Details List</div><div className="header-title-right" style={{ paddingRight: "10px" }}>Total Found : {body.item.length}</div></div>;
                        Array.prototype.push.apply(dialogTableValue, body.item);
                        dialogTableColumns.push(
                            <Column field="customStudentId" header="Student ID" />,
                            <Column field="studentName" header="Student Name" />,
                            <Column field="sectionName" header="Section" />,
                            <Column field="invoiceId" header="Invoice ID" />,
                            <Column field="paidAmount" header="Paid Amount" />,
                            <Column field="opsTransactionId" header="Transaction ID" />,
                            <Column field="toAccount" header="To Account" />
                        )
                        // dialog content set end

                        this.setState({ topProgressBar: false, dataTableIsLoading2: false });
                    });

                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    fetch_DrillDownDataOf_TotalSettlement(instituteId) {

        this.setState({ topProgressBar: true, dataTableIsLoading2: true, errorMsgVisible: false });
        this.emAdminPointService.fetchOpsInstituteTotalSettlementList(instituteId)
            .then((res) => {

                if (res.status == 302) {

                    return res.json().then((body) => {

                        // dialog content set
                        dialogTableHeader = <div className="header-title"><div className="header-title-left">Settlement Amount List</div><div className="header-title-right" style={{ paddingRight: "10px" }}>Total Found : {body.length}</div></div>;
                        Array.prototype.push.apply(dialogTableValue, body);
                        dialogTableColumns.push(
                            <Column field="settlementDate" header="Date" />,
                            <Column field="instituteBank" header="Settlement Bank" />,
                            <Column field="accountNumber" header="Settlement Account" />,
                            <Column field="amount" header="Amount" />,
                            <Column field="settlementBy" header="Settlement By" />
                        )
                        // dialog content set end

                        this.setState({ topProgressBar: false, dataTableIsLoading2: false });
                    });

                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    makeDialogVisible() { this.setState({ visible: true }) }
    makeDialogHide() {
        this.setState({ visible: false });
        this.resetDialogAndTableData();
        backBtnVisible = false;
    }

    resetDialogAndTableData() {
        dialogHeader = ''
        dialogTableHeader = '';
        dialogTableValue = []
        dialogTableColumns = [];
    }

    onClickTotalCollectionRData = (rowdata) => {

        this.makeDialogVisible();
        dialogHeader = 'Date Wise Total Collection';
        this.fetchDrillDownDataOf_TotalCollection(rowdata.instituteId);
        selectedInstitute = rowdata.instituteId;

    }

    onClickCollectionAmountRData = (rowData) => {

        backBtnVisible = true;
        this.resetDialogAndTableData();
        this.fetchDrillDownDataOf_CollectionAmnt(rowData.collectionDate);

    }

    onClickTotalSettlementRData = (rowData) => {

        this.makeDialogVisible();
        dialogHeader = 'Total Settlement Amount Details';
        this.fetch_DrillDownDataOf_TotalSettlement(rowData.instituteId);

    }

    totalCollectionBody = (rowData) => {
        return <span style={{ color: "#0078ff", cursor: "pointer", textDecoration: "underline" }} onClick={(e) => this.onClickTotalCollectionRData(rowData)} >{rowData.totalCollection}</span>
    }

    collectionAmountBody = (rowData) => {
        return <span style={{ color: "#0078ff", cursor: "pointer", textDecoration: "underline" }} onClick={(e) => this.onClickCollectionAmountRData(rowData)} >{rowData.collectionAmount}</span>
    }

    totalSettlementBody = (rowData) => {
        return <span style={{ color: "#0078ff", cursor: "pointer", textDecoration: "underline" }} onClick={(e) => this.onClickTotalSettlementRData(rowData)} >{rowData.totalSettlement}</span>
    }

    backOnPreviousTable() {

        this.resetDialogAndTableData();
        backBtnVisible = false;
        // dialog content set
        dialogHeader = 'Date Wise Total Collection';
        dialogTableHeader = <div className="header-title"><div className="header-title-left">Collection Amount Details List</div><div className="header-title-right" style={{ paddingRight: "10px" }}>Total Found : {this.state.dataWiseCollectionTableValue.length}</div></div>;
        Array.prototype.push.apply(dialogTableValue, this.state.dataWiseCollectionTableValue);
        dialogTableColumns.push(
            <Column field="collectionDate" header="Date" />,
            <Column field="collectionDay" header="Day" />,
            <Column field="collectionAmount" header="Collection Amount" body={this.collectionAmountBody} />
        )
        // dialog content set end
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title"><div className="header-title-left">Instiute Wise OPS Summary Report</div><div className="header-title-right" style={{ paddingRight: "10px" }}>Total Found : {totalLengthOfDT}</div></div>;
        let dialogContent = <div className="nw-data-table nw-data-table-tabview">
            <DataTable
                value={dialogTableValue}
                header={dialogTableHeader}
                responsive={true}
                paginator={true}
                rows={10}
            >
                {dialogTableColumns}
            </DataTable>
        </div>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid">
                            <div className="nw-data-table nw-data-table-tabview">

                                <div className="p-col-12 p-xl-12 m-b-20">
                                    {
                                        this.state.dataTableIsLoading ?
                                            this.netiContentLoader.MyPointTableLoader()
                                            :
                                            <DataTable
                                                value={this.state.dataTableValue}
                                                header={tableHeader}
                                                responsive={true}
                                                paginator={true}
                                                rows={10}
                                            >
                                                <Column field="instituteId" header="Instiute ID" />
                                                <Column field="instituteName" header="Instiute Name" />
                                                <Column field="totalCollection" header="Total Collection" body={this.totalCollectionBody} />
                                                <Column field="totalSettlement" header="Total Settlement" body={this.totalSettlementBody} />
                                                <Column field="refundableBalance" header="Refundable Balance" />
                                            </DataTable>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <Dialog className="nw-dialog" header={dialogHeader} visible={this.state.visible} onHide={this.makeDialogHide} maximizable>

                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12 m-b-20">
                                {this.state.dataTableIsLoading2 ? this.netiContentLoader.MyPointTableLoader() : dialogContent}
                                {backBtnVisible ?
                                    <div className="nw-button-parent m-t-8 p-r-0">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Back"
                                            icon="fas fa-chevron-left"
                                            onClick={this.backOnPreviousTable}
                                        />
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </Dialog>

            </div>
        )
    }
}