export class BaseURIHolder {

    getHost() {
        // var HOST = process.env.REACT_APP_API_HOST_SERVER;
        var HOST = process.env.REACT_APP_API_HOST_DEV;
        return HOST;
    }
    getGuest() {
        return this.getHost() + '/guest';
    }
    getGuestFile() {
        return this.getHost() + '/guest/file';
    }
    getGuestUser() {
        return this.getHost() + '/guest/user';
    }

    getGuestCore() {
        return this.getHost() + '/guest/core';
    }

    getUser() {
        return this.getHost() + '/user';
    }
    getUserProfile() {
        return this.getHost() + '/user/profile';
    }
    getSA() {
        return this.getHost() + '/guest/sa';
    }
    getDWS() {
        return this.getHost() + '/dws';
    }
    getAdmin() {
        return this.getHost() + '/admin';
    }
    getBijoy() {
        return this.getHost() + '/bijoy/unlock';
    }
    getUserToken() {
        return this.getHost() + '/user/tokens';
    }
    getPartner() {
        return this.getHost() + '/partner';
    }
    getCRM() {
        return this.getHost() + '/crm';
    }
    getAdmisia() {
        return this.getHost() + '/admisia';
    }
    getAdmisiaJasper() {
        return this.getHost() + '/js/admisia';
    }
    getPartnerJasper() {
        return this.getHost() + '/js/partner';
    }
    getSupport() {
        return this.getHost() + '/support';
    }
    getEmAdmin() {
        return this.getHost() + '/emadmin';
    }
    getManagement() {
        return this.getHost() + '/management';
    }
    getClientDws() {
        return this.getHost() + '/client_dws';
    }
    getJasper() {
        return this.getHost() + '/js';
    }
    getEm() {
        return this.getHost() + '/em';
    }

    //NETI CMS HOST
    getHostNetiCMS() {
        var HOST = process.env.REACT_APP_API_NETI_CMS_ADMIN;
        return HOST;
    }
}