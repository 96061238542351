import React, { Component } from 'react';
import { Message } from 'primereact/message';
import { ProgressBar } from 'primereact/progressbar';

export class ErrorMessageView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMsgBody: 'Error',
            renderErrorView: true
        }
    }

    logoutTimer(){
        
        setTimeout(function() { 
            this.setState({renderErrorView: false}) 
        }.bind(this), 5000)
    }

	render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.props
        let {renderErrorView} = this.state

        if(errorMsgVisible){
            this.logoutTimer();
        }
         
		return (
            <React.Fragment>
                { topProgressBar ?
                    <div className='custom-div-process-bar'>
                        <ProgressBar mode="indeterminate" showValue={true} style={{height: '4px'}}/>
                    </div>
                    : null
                }
                
                { 
                    errorMsgVisible?
                        renderErrorView && (errorMsgBody !== '')?
                            <div className='custom-div-error-message'>
                                <Message id="custom-msg-red" severity="error" text={errorMsgBody} />
                            </div>
                        :null
                    :null
                }
            </React.Fragment>
        );
	}
}