import React, { Component } from 'react';
import { AdmisiaRefundRequest } from './admisiaRequest/AdmisiaRefundRequest';
import { AdmisiaApproveRequest } from './admisiaRequest/AdmisiaApproveRequest';
import { TabView, TabPanel } from 'primereact/tabview';

export class AdmisiaRequest extends Component {
    
    render() {
        return (
            <div className="p-fluid">
                <div className="p-grid">

                    <div className="p-col-12 p-lg-12 p-xl-12 nw-tabview">
                        <TabView >
                            <TabPanel header="Refund Request">
                                <AdmisiaRefundRequest />
                            </TabPanel>
                            <TabPanel header="Approve Request">
                                <AdmisiaApproveRequest />
                            </TabPanel>
                        </TabView>
                    </div>

                </div>
            </div>
        )
    }
}