import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Row } from 'primereact/row';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import NetiContentLoader from '../../../common/NetiContentLoader';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { Link } from 'react-router-dom';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { Workbook } from 'react-excel-workbook';
import { pdfDownloadPortrait_TableOnly } from '../../../common/JsPdfDownload';
import { SplitButton } from 'primereact/splitbutton';

let type = '';

export class ModuleWiseToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tokenList: [],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            module: '',

            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]

        }
        this.managementPointService = new ManagementPointService();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    componentWillMount() {

        if (this.props.location.tokenInfo) {
            let moduleWiseTokenInfo = {
                "startDate": this.props.location.startDate,
                "endDate": this.props.location.endDate,
                "defCode": this.props.location.tokenInfo.defCode,
                "module": this.props.location.tokenInfo.module,
            }
            localStorage.setItem("moduleWiseTokenInfo", JSON.stringify(moduleWiseTokenInfo));
        }
        this.getEdumanTokenInfoList();

    }

    getEdumanTokenInfoList = () => {
        const moduleInfo = JSON.parse(localStorage.getItem("moduleWiseTokenInfo"));

        this.setState({
            module: moduleInfo.module
        })

        if (!moduleInfo) {
            return;
        }
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.managementPointService.getTokenListByApplicationModule(moduleInfo)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ tokenList: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    tokenBody = (rowData) => {
        return (
            <Link to={{ pathname: MANAGEMENT_POINT.PROBLEM_WISE_TOKEN, moduleWiseTokenInfo: rowData, startDate: this.props.location.startDate, endDate: this.props.location.endDate }}>
                <u>{rowData.numberOfToken}</u>
            </Link>
        )
    }

    export = () => {
        this.dt.exportCSV();
    }

    exportPdf() {

        let pdfHeader = `${type} Wise Token List of ${this.state.module}`
        let pdfColumns = [
            { title: "Module/Point", dataKey: "module" },
            { title: "Number of Token", dataKey: "numberOfToken" },
        ]
        let tableColStyle = {};

        pdfDownloadPortrait_TableOnly(pdfHeader, 68, pdfColumns, this.state.tokenList, tableColStyle, this.state.module + "Token_List.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        // excel
        let excelColumns = "";
        let excelFileName = this.state.module + "_Token_List.xlsx";
        let excelSheetName = this.state.module + "_Token_List";
        excelColumns = [
            <Workbook.Column value="module" label="Module/Point" />,
            <Workbook.Column value="numberOfToken" label="Number of Token" />,
        ];
        // end excel

        if (this.state.module === "NetiWorld") { type = 'Point' }
        if (this.state.module === "Eduman") { type = 'Module' }

        let header = <div className="header-title">
            <div className="header-title-left">{type} Wise Token List of {this.state.module}</div><div className="header-title-right"><a>Total Found: {this.state.tokenList.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">

                            <div className="nw-data-table">
                                {/* {
                        this.state.tableView === true ?
                            this.state.dataTableIsLoading ? 
                            this.NetiContentLoader.MyPointTableLoader()
                            : */}
                                <DataTable
                                    header={header}
                                    responsive={true}
                                    selectionMode="single"
                                    paginator={true}
                                    ref={(el) => { this.dt = el; }}
                                    rows={10}
                                    value={this.state.tokenList}
                                >
                                    <Column field="module" header="Module/Point" filter={true} />
                                    <Column field="numberOfToken" header="Number of Token" body={this.tokenBody} filter={true} />

                                </DataTable>

                                {/* :
                            
                            <BlankDataTableAnim/> 
                    } */}
                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                <div style={{ display: 'none' }}>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                label="Download Excel"
                                                id="clickExcelBtn"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.tokenList} name={excelSheetName}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>

                            </div >
                        </div>
                    </div>
                </div>

                {/* </div> */}

            </div>
        )
    }
}