import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import { ErrorMessageView } from "../../components/common/ErrorMessageView";
import { PartnerPointService } from "./../../service/partnerPoint/PartnerPointService";
import { NetiDateUtils } from "../../utils/NetiDateUtils";
import { NetiFileHandler } from "../../utils/NetiFileHandler";
import { DownloadButton } from "../../utils/DownloadButton";
import NetiContentLoader from '../common/NetiContentLoader';
import { pdfDownloadPortrait_TableOnly, pdfDownloadLandscape_TableOnly } from "../common/JsPdfDownload";
import { Button } from "primereact/button";

export class DomainOrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domainOrderAttachFile: "",
            domainOrderFileName: "",
            memberStatus: "",
            dataTableValue: [],
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: "",
            homeReturnButton: false
        };

        this.PartnerPointService = new PartnerPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.netiContentLoader = new NetiContentLoader();
        this.exportPdf = this.exportPdf.bind(this);

    }

    componentWillMount() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.PartnerPointService.getDomainlist("domain")
            .then(res => {

                if (res.status == 302) {
                    return res.json().then(body => {

                        for (let i = 0; i < body.length; i++) {

                            Object.assign(body[i], {productName: body[i].productPurchaseCodeDTO.productPurchaseLogDTO.productInfoDTO.productName});   // for pdf column
                            Object.assign(body[i], {purchaseCode: body[i].productPurchaseCodeDTO.purchaseCode});   // for pdf column
                            Object.assign(body[i], {purchaseDate: body[i].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate});   // for pdf column

                            if (body[i].domainhostingOrderDate == null) {
                                body[i].domainhostingOrderDate = '';
                            } else {
                                body[i].domainhostingOrderDate = NetiDateUtils.getAnyShortForm(body[i].domainhostingOrderDate, 'DD-MMM-YYYY hh:mm:ss a');
                            }

                        }
                        for (let k = 0; k < body.length; k++) {
                            if (body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate == null) {
                                body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate = '';
                            } else {
                                body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate = NetiDateUtils.getAnyShortForm(body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
                            }
                        }
                        for (let j = 0; j < body.length; j++) {
                            if (body[j].domainhostingOrderStatus == 0) {
                                body[j].domainhostingOrderStatus = 'Pending';
                            } else if (body[j].domainhostingOrderStatus == 1) {
                                body[j].domainhostingOrderStatus = 'Approve';
                            } else if (body[j].domainhostingOrderStatus == 2) {
                                body[j].domainhostingOrderStatus = 'Reject';
                            }

                        }
                        this.setState({ dataTableValue: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });

                    });
                } else {
                    this.PartnerPointService.Auth.handleErrorStatus(res).then(respon => {
                        this.setState({
                            topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: respon
                        });
                    });
                }
            })
            .catch(err => {
                this.setState({
                    topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Unable to load table"
                });
            });
    }

    actionTemplate = rowData => {
        return (
            <DownloadButton fileName={rowData.attachFileName} filePath={rowData.attachFilePath} />
        );
    };

    actionTemplateProductName = rowData => {
        return (
            <span>
                {
                    rowData.productPurchaseCodeDTO.productPurchaseLogDTO.productInfoDTO
                        .productName
                }
            </span>
        );
    };

    actionTemplateProductCode = rowData => {
        return <span>{rowData.productPurchaseCodeDTO.purchaseCode}</span>;
    };

    exportPdf() {

        let pdfColumns = [
            { title: "Order Date", dataKey: "domainhostingOrderDate" },
            { title: "Order Status", dataKey: "domainhostingOrderStatus" },
            { title: "Product Name", dataKey: "productName" },
            { title: "Purchase Code", dataKey: "purchaseCode" },
            { title: "Purchase Date", dataKey: "purchaseDate" },
            { title: "Desire URL", dataKey: "domainName" },
        ]
        let tableColStyle = [];
        pdfDownloadLandscape_TableOnly('Domain Order List', 120,  pdfColumns, this.state.dataTableValue,tableColStyle, "domain_order_list.pdf");

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { dataTableValue } = this.state;

        let headerName;

        if (this.props.domain === true) {
            headerName = "Domain";
        } else if (this.props.hosting === true) {
            headerName = "Hosting";
        }
        let tableHeader = <div className="header-title"><div className="header-title-left">{headerName} Order List</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div></div>;


        return (
            <div className="p-fluid">
                <Growl ref={el => (this.growl = el)} position="topright"></Growl>
                {topProgressBar ? (
                    <ErrorMessageView topProgressBar={topProgressBar} />
                ) : null}
                {errorMsgVisible ? (
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                ) : null}
                <Growl ref={el => (this.growl = el)} />

                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        {this.state.dataTableIsLoading ? this.netiContentLoader.MyPointTableLoader() :
                            <div className="nw-data-table nw-data-table-tabview">
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event =>this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    columnResizeMode="fit"
                                >
                                    <Column field="domainhostingOrderDate" header="Order Date" filter={true} />
                                    <Column field="domainhostingOrderStatus" header="Order Status" filter={true} />
                                    <Column field="productPurchaseCodeDTO.productPurchaseLogDTO.productInfoDTO.productName" header="Product Name" body={this.actionTemplateProductName} filter={true} />
                                    <Column field="productPurchaseCodeDTO.purchaseCode" header="Purchase Code" filter={true} />
                                    <Column field="productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate" header="Purchase Date" filter={true} />
                                    <Column field="domainName" header="Desire URL" filter={true} />
                                    <Column field="" header="Attachment" body={this.actionTemplate} style={{ textAlign: "center", width: "8em" }} />
                                </DataTable>

                                <div className="nw-button-parent m-t-8 p-r-0">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Download PDF"
                                        icon="fas fa-download"
                                        onClick={this.exportPdf}
                                    />
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
