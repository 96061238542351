import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ReportService } from "../../../../service/myPoint/ReportService";
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Button } from "primereact/button";
import { pdfDownloadLandscape_TableOnly } from "../../../common/JsPdfDownload";

export class UsedPurchaseCodeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectedStutas: "Custom Status",
      dataTableSelection: null,
      dataViewValue: [],
      usedPurchaseCodesList: [],
      topProgressBar: false,
      dataTableIsLoading: false,
      errorMsgVisible: false
    }
    this.ReportService = new ReportService();
    this.NetiDateUtils = new NetiDateUtils();
    this.NetiContentLoader = new NetiContentLoader();
  }

  componentWillMount() {

    this.fetchUsedPurchaseList();

  }

  fetchUsedPurchaseList() {
    this.setState({ topProgressBar: true, dataTableIsLoading: true });
    this.ReportService.getUsedUnusedPurchaseCodeByStatus("1")
      .then(res => {

        if (res.status === 302) {
          return res.json().then(body => {

            // console.log('used purchase code res', body);
            for (let i = 0; i < body.length; i++) {

              if (body[i].purchseDate == null) {
                body[i].purchseDate = '';
              } else {
                body[i].purchseDate = NetiDateUtils.getAnyShortForm(body[i].purchseDate, 'DD-MMM-YYYY hh:mm:ss a');
              }

              if (body[i].useDate == null) {
                body[i].useDate = '-';
              } else {
                body[i].useDate = NetiDateUtils.getAnyShortForm(body[i].useDate, 'DD-MMM-YYYY hh:mm:ss a');
              }

            }

            this.setState({ usedPurchaseCodesList: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
          });
        } else {
          this.ReportService.Auth.handleErrorStatus(res)
            .then((responseBody) => {
              this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
            });
        }
      }).catch(err => {
        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false, errorMsgBody: 'Please check your connection' });
      });

  }

  exportPdf = () => {

    let tableColumns = [
      { title: "Purchase Date", dataKey: "purchseDate" },
      { title: "Used Date", dataKey: "useDate" },
      { title: "Purchase Point", dataKey: "purchasePoint" },
      { title: "Product Type", dataKey: "productType" },
      { title: "Product Name", dataKey: "productName" },
      { title: "Purchase Code", dataKey: "puchaseCode" },
    ];

    let columnStyle = {}

    pdfDownloadLandscape_TableOnly("Used Purchase Code Report", 110, tableColumns, this.state.usedPurchaseCodesList, columnStyle, "used_purchase_code_log.pdf");

  }

  render() {

    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

    let header = (
      <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
        Used Purchase Code List
        <span style={{ 'float': 'right' }}> Total Found: {this.state.usedPurchaseCodesList.length} </span>
      </div>
    );
    return (
      <div className="p-fluid">
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }

        <Growl ref={(el) => this.growl = el} />

        <div className="layout-dashboard">
          <div className="nw-data-table nw-data-table-tabview">
            {this.state.dataTableIsLoading ?
              this.NetiContentLoader.MyPointTableLoader()
              :
              <div>
                <DataTable
                  value={this.state.usedPurchaseCodesList}
                  selectionMode="single"
                  header={header}
                  selection={this.state.dataTableSelection}
                  onSelectionChange={event =>
                    this.setState({ dataTableSelection: event.value })
                  }
                  responsive={true}
                  paginator={true}
                  rows={10}
                >
                  <Column field="purchseDate" header="Purchase Date" sortable={true} filter={true} />
                  <Column field="useDate" header="Used Date" sortable={true} filter={true} />
                  <Column field="purchasePoint" header="Purchase Point" sortable={true} filter={true} />
                  <Column field="productType" header="Product Type" sortable={true} filter={true} />
                  <Column field="productName" header="Product Name" sortable={true} filter={true} />
                  <Column field="puchaseCode" header="Purchase Code" sortable={true} filter={true} />

                </DataTable>
                <div className="nw-button-parent m-t-8 p-r-0">
                  <Button
                    className="p-button p-button-primary nw-button nw-button-right"
                    label="Download"
                    icon="fas fa-download"
                    onClick={this.exportPdf}
                  />
                </div><br />
              </div>
            }

          </div>
        </div>

      </div>

    );
  }
}
