import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { BalanceService } from "../../../service/myPoint/BalanceService";
import { NetiDateUtils } from "../../../utils/NetiDateUtils";
import { MY_POINT } from "../../../utils/PointWiseRouteConsts";
import NetiContentLoader from "../../common/NetiContentLoader";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
import { Button } from "primereact/button";
import CommonFuctionality from "../../common/CommonFuctionality";
import { InputTextarea } from "primereact/inputtextarea";

export class WalletBalanceDepositList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRequest: "",
      bank: "",
      accountNumber: "",
      requestedAmount: "",
      transactionDate: "",
      transactionNumber: "",
      approveDate: "",
      requestStatus: "",
      note: "",
      rejectedNote: "",
      dataTableSelection: null,
      dataViewValue: [],
      dataTableValue: [],
      dataTableIsLoading: false,
      dialogRequestNote: "",
    };
    this.statusBody = this.statusBody.bind(this);
    this.boldBody = this.boldBody.bind(this);
    this.viewDialog = this.viewDialog.bind(this);
    this.onHide = this.onHide.bind(this);
    this.balanceService = new BalanceService();
    this.NetiContentLoader = new NetiContentLoader();
    this.validatorUtility = new ValidatorUtility();
    this.CommonFuctionality = new CommonFuctionality();
  }

  componentDidMount() {
    this.viewTopTenBalanceDepositList();
  }

  viewTopTenBalanceDepositList = () => {
    this.setState({
      dataTableIsLoading: true,
      errorMsgVisible: false,
      topProgressBar: true,
    });
    this.balanceService
      .fetchTopTenBalanceDepositList("Deposit")
      .then((res) => {
        if (res.status === 200) {
          return res.json().then((body) => {
            for (let i = 0; i < body.length; i++) {
              body[i].requestDate = NetiDateUtils.getAnyShortForm(
                body[i].requestDate,
                "DD-MMM-YYYY hh:mm:ss a"
              );
            }
            this.setState({
              dataTableValue: body,
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.balanceService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              topProgressBar: false,
              dataTableIsLoading: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          dataTableIsLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection !!!",
        });
      });
  };

  statusBody(rowData) {
    if (rowData["requestStatus"] === "Rejected") {
      return (
        <span className="text-danger">
          <span>{rowData["requestStatus"]}</span>
        </span>
      );
    } else if (rowData["requestStatus"] === "Approved") {
      return (
        <span className="text-success">
          <span>{rowData["requestStatus"]}</span>
        </span>
      );
    } else if (rowData["requestStatus"] === "Pending") {
      return (
        <span className="text-warning">
          <span>{rowData["requestStatus"]}</span>
        </span>
      );
    } else {
      return <span>{rowData["requestStatus"]}</span>;
    }
  }

  viewDialog = (rowData) => {
    this.setState({
      visible: true,
      rejectNote: rowData.approveNote,
    });

    this.setState({
      dateRequest:
        this.state.requestDate === "null"
          ? " "
          : NetiDateUtils.getAnyShortForm(
              rowData["requestDate"],
              "DD-MMM-YYYY"
            ) +
            " " +
            NetiDateUtils.getHHMMSSFForHome(rowData["requestDate"]),
    });
    this.setState({ bank: rowData.bank });
    this.setState({ transactionType: rowData.accountNumber });
    this.setState({
      requestedAmount: this.validatorUtility.currencyFormatter(
        rowData.requestedAmount
      ),
    });
    this.setState({
      transactionDate:
        this.state.transactionDate === "null"
          ? " "
          : NetiDateUtils.getAnyShortForm(
              rowData["transactionDate"],
              "DD-MMM-YYYY"
            ) +
            " " +
            NetiDateUtils.getHHMMSSFForHome(rowData["transactionDate"]),
    });
    this.setState({ transactionNumber: rowData.transactionNumber });
    this.setState({
      approveDate:
        this.state.approveDate === "null"
          ? " "
          : NetiDateUtils.getAnyShortForm(
              rowData["approveDate"],
              "DD-MMM-YYYY"
            ) +
            " " +
            NetiDateUtils.getHHMMSSFForHome(rowData["approveDate"]),
    });
    this.setState({ requestStatus: rowData.requestStatus });
    this.setState({ note: rowData.approveNote });
    this.setState({ dialogRequestNote: rowData.requestNote });

    this.CommonFuctionality.blurDialogBackgroundActive();
  };

  detailBody = (rowData, column) => {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button note"
          icon="fas fa-info"
          title="Click to view deposit details"
          onClick={(e) => this.viewDialog(rowData)}
        />
      </div>
    );
  };

  boldBody(rowData) {
    if (rowData["requestStatus"] === "Rejected") {
      return (
        <div className="col-number text-right text-danger">
          {" "}
          {this.validatorUtility.currencyFormatter(
            rowData["requestedAmount"]
          )}{" "}
        </div>
      );
    } else if (rowData["requestStatus"] === "Approved") {
      return (
        <div className="col-number text-right text-success">
          {this.validatorUtility.currencyFormatter(rowData["requestedAmount"])}
        </div>
      );
    } else if (rowData["requestStatus"] === "Pending") {
      return (
        <div className="col-number text-right text-warning">
          {this.validatorUtility.currencyFormatter(rowData["requestedAmount"])}
        </div>
      );
    } else {
      return (
        <div className="col-number text-right">
          {" "}
          {this.validatorUtility.currencyFormatter(rowData["requestedAmount"])}
        </div>
      );
    }
    // return(<div className="col-number text-right"> {this.validatorUtility.currencyFormatter(rowData['requestedAmount'])}</div>)
  }

  // viewDialog = (rowData) => {
  //     this.setState({ rejectedNote: rowData.approveNote})
  //     this.setState({ visible: true })
  // }

  // viewDialog = (rowData) => {
  //     this.setState({
  //         visible: true,
  //         rejectNote: rowData.approveNote

  //     })

  onHide() {
    this.setState({ visible: false });
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  }

  changeStatusFontColor = (status) => {
    if (status === "Rejected") {
      return (
        <span className="text-danger">
          <span>{status}</span>
        </span>
      );
    } else if (status === "Approved") {
      return (
        <span className="text-success">
          <span>{status}</span>
        </span>
      );
    } else if (status === "Pending") {
      return (
        <span className="text-warning">
          <span>{status}</span>
        </span>
      );
    } else {
      return <span>{status}</span>;
    }
  };

  changeRequestAmountFontColor = (requestedAmount) => {
    if (this.state.requestStatus === "Approved") {
      return (
        <span className="text-success">
          <span>{requestedAmount}</span>
        </span>
      );
    } else if (this.state.requestStatus === "Pending") {
      return (
        <span className="text-warning">
          <span>{requestedAmount}</span>
        </span>
      );
    } else if (this.state.requestStatus === "Rejected") {
      return (
        <span className="text-danger">
          <span>{requestedAmount}</span>
        </span>
      );
    } else {
      return <span>{requestedAmount}</span>;
    }
  };

  render() {
    let tableHeader = (
      <div className="header-title">
        <div className="header-title-left">Balance Deposit List</div>

        <div className="header-title-right">
          <Link to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 0 }}>
            More<i className="fas fa-angle-right"></i>
          </Link>
        </div>
      </div>
    );

    return (
      <div className="p-fluid">
        <div className="blur-section diff">
          <div className="p-grid">
            <div className="p-col-12 p-xl-12">
              {this.state.dataTableIsLoading ? (
                this.NetiContentLoader.MyPointTableLoader()
              ) : (
                <div className="nw-data-table nw-data-table-tabview">
                  {this.state.dataTableValue ? (
                    <DataTable
                      value={this.state.dataTableValue}
                      selectionMode="single"
                      activeIndex="0"
                      header={tableHeader}
                      selection={this.state.dataTableSelection}
                      onSelectionChange={(event) =>
                        this.setState({ dataTableSelection: event.value })
                      }
                      responsive={true}
                      rows={10}
                    >
                      <Column
                        field="requestDate"
                        header="Request Date"
                        className="table-datetime"
                      />
                      <Column field="bank" header="Bank" />
                      <Column field="accountNumber" header="Account Number" />
                      <Column
                        field="requestedAmount"
                        header="Request Amount"
                        sortable={true}
                        body={this.boldBody}
                      />
                      <Column
                        field="requestStatus"
                        header="Status"
                        body={this.statusBody}
                      />
                      <Column
                        field=""
                        header="Details"
                        body={this.detailBody}
                        style={{ width: "96px" }}
                      />
                    </DataTable>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="dialog-section">
          <Dialog
            className="nw-dialog"
            header="Deposit Details"
            visible={this.state.visible}
            onHide={this.onHide}
            closable
          >
            <div className="p-fluid">
              <div className="p-grid nw-form">
                <div className="p-col-12">
                  <div className="formControl">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Request Date
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.state.dateRequest}
                      </div>
                    </div>
                  </div>

                  <div className="formControl">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Bank
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.state.bank}
                      </div>
                    </div>
                  </div>

                  <div className="formControl">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Account Number
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.state.transactionType}
                      </div>
                    </div>
                  </div>

                  <div className="formControl">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Request Amount
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.state.requestedAmount
                          ? this.changeRequestAmountFontColor(
                              this.state.requestedAmount
                            )
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="formControl">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputtextarea-label">
                        Note
                      </span>
                      <InputTextarea
                        readOnly={true}
                        name="text"
                        value={this.state.dialogRequestNote}
                      />
                    </div>
                  </div>

                  <div className="formControl">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Transaction Date
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.state.transactionDate}
                      </div>
                    </div>
                  </div>

                  {this.state.requestStatus === "Approved" ? (
                    <div className="formControl">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Transaction ID
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.state.transactionNumber}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="formControl">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputgroup-label">
                        Status
                      </span>
                      <div className="nw-inputgroup-desc">
                        {this.state.requestStatus
                          ? this.changeStatusFontColor(this.state.requestStatus)
                          : ""}
                      </div>
                    </div>
                  </div>

                  {this.state.requestStatus === "Approved" ? (
                    <div className="formControl">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Approved Date
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.state.approveDate === "null"
                            ? " "
                            : this.state.approveDate}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.requestStatus === "Rejected" ? (
                    <div className="formControl">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Reject Date
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.state.approveDate}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.requestStatus === "Rejected" ? (
                    <div className="formControl">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Reject Note
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.state.note}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
