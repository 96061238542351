import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';

import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import NetiContentLoader from '../../../common/NetiContentLoader';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { pdfDownloadLandscape_TableWithSubTitle } from '../../../common/JsPdfDownload';

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class PersonWiseTokenSolvedList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            solvedTokenList: [],
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            visible: false,
            dialogObj: {
                details: ''
            }
        }
        this.managementPointService = new ManagementPointService();
        this.CommonFuctionality = new CommonFuctionality();
        this.NetiContentLoader = new NetiContentLoader();
        this.exportPdf = this.exportPdf.bind(this);

    }

    componentWillMount() {

        if (this.props.location.personWiseTokenInfo) {
            let solvedTokenInfo = {
                "startDate": this.props.location.startDate,
                "endDate": this.props.location.endDate,
                "netiID": this.props.location.personWiseTokenInfo.netiID
            }
            localStorage.setItem("solvedTokenInfo", JSON.stringify(solvedTokenInfo));
        }
        this.getSolvedTokenInfoList();
    }

    getSolvedTokenInfoList = () => {
        let solvedTokenInfo = JSON.parse(localStorage.getItem("solvedTokenInfo"));
        if (!solvedTokenInfo) {
            return;
        }

        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.managementPointService.getPersonWiseSolvedTokenList(solvedTokenInfo)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if (body.length == 0) {
                            this.setState({ errorMsgVisible: true, errorMsgBody: "No Data Found", topProgressBar: false, dataTableIsLoading: false })
                        } else {
                            for (let i = 0; i < body.length; i++) {
                                if (body[i].submitDate == null) {
                                    body[i].submitDate = '';
                                } else {
                                    body[i].submitDate = NetiDateUtils.getDateWithHHMMSSA(body[i].submitDate);
                                }
                                if (body[i].solvedDate == null) {
                                    body[i].solvedDate = '';
                                } else {
                                    body[i].solvedDate = NetiDateUtils.getDateWithHHMMSSA(body[i].solvedDate);
                                }
                            }

                            this.setState({ solvedTokenList: body });
                            this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        }
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    problemDetailsBody = rowData => {

        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-info"
                title='Click to view deposit details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>

    }

    viewDialog = (rowData) => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visible: true, dialogObj: rowData });
    }

    onHide = (event) => {
        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visible: false });
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Submit Date & Time", dataKey: "submitDate" },
            { title: "Solved Date & Time", dataKey: "solvedDate" },
            { title: "Token ID", dataKey: "customTokenID" },
            { title: "Application", dataKey: "application" },
            { title: "Problem Module", dataKey: "module" },
            { title: "Problem Title", dataKey: "problem" },
        ]

        let tableColStyle = {};

        pdfDownloadLandscape_TableWithSubTitle("Solved Token Info", 114, subTitleColumn, subTitleList, pdfColumns, this.state.solvedTokenList, tableColStyle, "Solved_Token_List.pdf");

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, solvedTokenList, dialogObj } = this.state;

        // pdf reletaed task
        let getName = '';
        let getNetiId = '';

        if (this.props.location.personWiseTokenInfo) {
            getName = this.props.location.personWiseTokenInfo.name;
            getNetiId = this.props.location.personWiseTokenInfo.customID;
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Neti ID", B: getNetiId, C: "Name", D: getName }
        ];
        // pdf reletaed task end

        let header = <div className="header-title">
            <div className="header-title-left">Solved Token Info</div><div className="header-title-right"><a>Total Found: {solvedTokenList.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        return (
            <div className="p-fluid">
                {/* <div className="p-grid"> */}
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section">
                    <div className="nw-data-table">
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            {
                                this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    :
                                    <DataTable
                                        header={header}
                                        responsive={true}
                                        selectionMode="single"
                                        paginator={true}
                                        rows={10}
                                        value={this.state.solvedTokenList}
                                    >
                                        <Column field="submitDate" className="table-datetime"  header="Submit Date & Time" filter={true} />
                                        <Column field="solvedDate" className="table-datetime" header="Solved Date & Time" filter={true} />
                                        <Column field="customTokenID" header="Token ID" filter={true} />
                                        <Column field="application" header="Application" filter={true} />
                                        <Column field="module" header="Problem Module" filter={true} />
                                        <Column field="problem" header="Problem Title" filter={true} />
                                        <Column field="details" header="Problem Details" body={this.problemDetailsBody} style={{ width: '95px' }} />
                                    </DataTable>
                            }
                        </div>

                        <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                            <Button
                                className="p-button p-button-primary nw-button nw-button-right"
                                label="Download PDF"
                                icon="fas fa-download"
                                onClick={this.exportPdf}
                            />
                        </div>

                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        maximizable
                        header={"Problem Details"}
                        visible={this.state.visible}
                        modal={true}
                        onHide={this.onHide}
                        closable
                    >
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">

                                <div className="nw-search-view">
                                    <div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="task-badge found"></span>
                                                <span className="p-inputgroup-addon">Details</span>
                                                <span className="p-inputgroup-colon">:</span>
                                                <div className="nw-inputgroup-desc">{dialogObj.details}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </Dialog>
                </div>

            </div>

        )
    }
}