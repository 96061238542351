import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { WalletBalance } from '../common/WalletBalance';
import { SellProductCodeInfoTable } from './SellProductCodeInfoTable';
import { SalesServices } from '../../../service/sales/SalesServices';
import { NetiDateUtils } from '../../../utils/NetiDateUtils'
import { MY_POINT } from '../../../utils/PointWiseRouteConsts';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility'

let cloneStateBeforeMount;
let totalMessageBalance = 0;
let totalVoiceBalance = 0;
let totalEmailBalance = 0;
let totalBalance = 0;

export class SellProduct extends Component {
    constructor() {
        super();
        this.state = {
            visible: false,
            dataViewValue: [],
            customerName: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            customerMobile: '',
            newCustomerName: '',
            newCustomerContact: '',
            checked: false,
            productList: '',
            purchaseCodeList: '',
            sendSms: '',
            customerID: '',
            rowInformation: {

            },
            newCustomerInfo: {

            },
            customerInfo: {

            },
            sendingSmsStatus: 0,
            salesInfo: {
                productName: null,
                salesPrice: '',
                salesNote: '',
                productPurchaseCodeDTO: {
                    productPurchaseCodeID: null
                },

                customerInfoDTO: {
                    customerID: '',
                    customerContact: ''
                }
            },
            errors: {},
            mobileCheckError: {},
            productSalesError: {},
            dataTableValue: [],
            totalSalesTableValue: [],
            searchData: {
                requestStartDate: '',
                requestEndDate: '',
                requestType: 'sales',
                transactionType: 'sales',
                pageNo: 0,
                limit: 100
            },
            totalSalesPrice: 0,
            dataTableIsLoading: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            searchView: false,
            searchViewError: false,
            buttonDisabled: true,
            searchIsLoading: false
        }

        this.sellCodeBody = this.sellCodeBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.sellProduct = this.sellProduct.bind(this);
        this.mobileNUmberHandler = this.mobileNUmberHandler.bind(this)
        this.customerNameHandeler = this.customerNameHandeler.bind(this)
        this.addMobileNumberHandle = this.addMobileNumberHandle.bind(this)
        this.customeMobileNUmberHandler = this.customeMobileNUmberHandler.bind(this)
        this.checkMobileNumber = this.checkMobileNumber.bind(this)
        this.mobileCheckHandleError = this.mobileCheckHandleError.bind(this)
        this.productNameHandle = this.productNameHandle.bind(this)
        this.purchaseCodeHandle = this.purchaseCodeHandle.bind(this)
        this.salesPriceHandle = this.salesPriceHandle.bind(this)
        this.productHandleError = this.productHandleError.bind(this)
        this.salesNote = this.salesNote.bind(this)
        this.salesServices = new SalesServices();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();

    }

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));

        this.fetchTotalSalesAmount();
        this.getTopTenProductList();
        this.fetchProductNameInfo();
    }

    componentDidMount() {
       
    }

    fetchTotalSalesAmount() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        let today = new Date();
        let tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
        let { searchData } = this.state;
        searchData.requestStartDate = NetiDateUtils.getDateFromString(today);
        searchData.requestEndDate = NetiDateUtils.getDateFromString(tomorrow);
        this.setState({ searchData: searchData });
        let todaySalesData = 0;

        this.salesServices.getSalesListByDateRange(this.state.searchData)
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        body.map((item) => {
                            todaySalesData += item.salesPrice;
                            this.setState({ totalSalesPrice: todaySalesData });
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false })
                        })
                    })
                } else {
                    this.salesServices.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorInfo: true, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }

            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });

    }

    fetchProductNameInfo() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.salesServices.getProductNameList()
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        this.setState({ productList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    });
                } else {
                    this.salesServices.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });

    }


    submitButtonCheck() {
        if (this.state.customerMobile) {
            this.setState({ buttonDisabled: false })
        }
        else {
            this.setState({ buttonDisabled: true })
        }
    }

    getTopTenProductList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.salesServices.getTopSalesList()
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].salesDate == null) {
                                body[i].salesDate = '';
                            } else {
                                body[i].salesDate = NetiDateUtils.getAnyShortForm(body[i].salesDate, 'DD-MMM-YYYY hh:mm:ss a'); 
                            }
                            body[i].salesPrice = isNaN(body[i].salesPrice) ? '0.00' : this.ValidatorUtility.currencyFormatter(body[i].salesPrice);
                        }
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false })
                    })
                } else {
                    this.salesServices.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
    }

    dateFormatTempl(rowData, Column) {

        return <span>{NetiDateUtils.getAnyShortForm(rowData['salesDate'], 'DD-MMM-YYYY')}</span>;
    }

    viewDialog(rowData) {
        /*this.setState({ rowInformation: rowData.productName })*/
        let { rowInformation } = this.state
        rowInformation.salesDate = rowData.salesDate
        rowInformation.productName = rowData.productName
        rowInformation.purchaseCode = rowData.purchaseCode
        rowInformation.salesPrice = rowData.salesPrice
        rowInformation.customerName = rowData.customerName
        rowInformation.customerContact = rowData.customerContact
        rowInformation.salesNote = rowData.salesNote
        this.setState({ rowInformation })
        this.setState({ visible: true });
    }

    sellCodeBody(rowData, column) {

        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title="View Purchase"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    productNameHandle = (event, props) => {
        let { salesInfo } = this.state
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })

        salesInfo.productName = event.target.value;
        this.setState({ salesInfo })
        this.salesServices.getPurchaseCodeByProductID(event.target.value)
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        this.setState({ purchaseCodeList: body, topProgressBar: false, secondDropdownIsLoading: false })
                    })
                } else {
                    this.salesServices.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
    }

    purchaseCodeHandle = (event, props) => {
        let { salesInfo } = this.state
        salesInfo.productPurchaseCodeDTO.productPurchaseCodeID = event.target.value
        this.setState({ salesInfo })
    }

    salesPriceHandle = (event, props) => {
        let { salesInfo } = this.state
        salesInfo.salesPrice = event.target.value
        this.setState({ salesInfo })
    }

    salesNote = (event, props) => {
        let { salesInfo } = this.state
        salesInfo.salesNote = event.target.value
        this.setState({ salesInfo })
    }

    checkSelection = (event) => {

        let { sendingSmsStatus, checked } = this.state

        this.setState({ checked :event.checked})
    
        event.checked? sendingSmsStatus = 1:sendingSmsStatus = 0

        this.setState({sendingSmsStatus})
        
        


    }

    /*new customer handelers start*/
    customerNameHandeler = (event, props) => {
        this.setState({ newCustomerName: event.target.value })
        let { newCustomerInfo, errors } = this.state;
        newCustomerInfo[event.target.name] = event.target.value;
        errors['newCustomerName'] = '';
        this.setState({ newCustomerInfo, errors });
    }

    mobileNUmberHandler = (event, props) => {
        this.setState({ newCustomerContact: event.target.value })
        let { newCustomerInfo, errors } = this.state;
        newCustomerInfo[event.target.name] = event.target.value;
        errors['newCustomerContact'] = '';
        this.setState({ newCustomerInfo, errors });
    }

    addMobileNumberHandle = (event, props) => {
        let errors = {};
        let formIsValid = true;
        if (this.handleError()) {
            this.salesServices.addNewCustomer(this.state.newCustomerInfo)
                .then((res) => {
                    if (res.status === 201) {
                        this.setState({ mobileDialog: false })
                    } else {
                        return res.json().then((body) => {
                            formIsValid = false;
                            errors["newCustomerContact"] = "Ops! You have already saved this customer mobile number.";
                            this.setState({ errors: errors });
                        })
                    }
                })
        }
    }

    customeMobileNUmberHandler = (event, props) => {
        let { salesInfo } = this.state
        this.setState({ customerMobile: event.target.value })
        this.setState({ buttonDisabled: true })

        this.setState({ customerName: '', searchViewError: false, searchView: false })
        this.setState({ customerID: '' })
        salesInfo.customerInfoDTO.customerID = ''
        this.setState({ salesInfo })

    }

    checkMobileNumber = (event, props) => {
        let mobileCheckError = {};
        let formIsValid = true;
        if (this.mobileCheckHandleError()) {
            this.setState({ topProgressBar: true, searchIsLoading: true, errorMsgVisible: false, searchView: true, searchViewError: false });

            // let findCustomerMobile = this.state.customerMobile.replace(/-/g, '').replace(/\s/g, '').replace(/[+]/g, '')
            this.salesServices.findCustomerMobileNumber(this.state.customerMobile)
                .then((res) => {
                    if (res.status === 200) {
                        return res.json().then((resBody) => {

                            
                            this.setState({ topProgressBar: false, searchIsLoading: false, errorMsgVisible: false, searchViewError: false })
                            this.setState({ customerName: resBody.customerName })
                            this.setState({ customerID: resBody.customerID })
                            let { salesInfo } = this.state
                            salesInfo.customerInfoDTO.customerID = this.state.customerID
                            this.setState({ salesInfo })

                            this.submitButtonCheck()
                        })
                    }
                    else {

                        this.salesServices.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                formIsValid = false;
                                mobileCheckError["mobileDoesNotFound"] = responseBody;
                                this.setState({
                                    mobileCheckError,
                                    topProgressBar: false, searchIsLoading: false, errorMsgVisible: false,
                                    searchViewError: true
                                })

                                // this.submitButtonCheck()
                                // return formIsValid;

                            });

                    }
                }).catch(error => {
                    this.setState({
                        topProgressBar: false, searchIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!",
                        searchViewError: false, searchView: false
                    })
                });
        }
    }

    sellProduct = (event, props) => {

        let {salesInfo} = this.state;
        salesInfo.customerInfoDTO.customerContact = this.state.customerMobile
        this.setState({salesInfo})
    
        
        if (this.productHandleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, })
            this.salesServices.addNewSale(this.state.salesInfo, this.state.sendingSmsStatus)
                .then(res => {
                    if (res.status === 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Submitted successfully' })
                        this.setState({
                            salesInfo: cloneStateBeforeMount.salesInfo,
                            customerMobile: '',
                            customerName: '',
                            customerID: '',
                            topProgressBar: false, 
                            dataTableIsLoading: false,
                            searchView: false 
                        });
                        this.getTopTenProductList();
                        this.fetchTotalSalesAmount();

                    } else {
                        this.salesServices.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
                });
        }
    }


    productHandleError() {
        let productSalesError = {};
        let formIsValid = true;

        if (this.state.salesInfo.productName == null) {
            formIsValid = false;
            productSalesError["salesProductName"] = "Product Name Doesn't Selected.";
        }
        if (this.state.salesInfo.productPurchaseCodeDTO.productPurchaseCodeID === null) {
            formIsValid = false;
            productSalesError["productDefaultCode"] = "Purchase Code Doesn't Selected.";
        }
        if (this.state.salesInfo.customerInfoDTO.customerID === '') {
            formIsValid = false;
            productSalesError["productSalesCustomerMobile"] = "Search your customer name by mobile number";
        }
        if (this.state.salesInfo.salesPrice === '') {
            formIsValid = false;
            productSalesError["productSalesPrice"] = "Sales Price can't left empty.";
        }
        if (this.state.salesInfo.salesNote === '') {
            formIsValid = false;
            productSalesError["productSalesNote"] = "Sales Note can't left empty.";
        }
        this.setState({ productSalesError: productSalesError });
        return formIsValid;
    }

    /*product sales error end*/

    /*Error Handle start*/
    showSuccess(successMessage) {
        this.messages.show({ severity: 'success', summary: 'Success Message', detail: successMessage });
    }
    showError(errorMessage) {
        this.messages.show({ severity: 'error', summary: 'Error Message', detail: errorMessage });
    }
    clear() {
        this.messages.clear();
    }

    handleError() {
        let errors = {};
        let formIsValid = true;
        if (this.state.newCustomerName === '') {
            formIsValid = false;
            errors["newCustomerName"] = "Customer Name can't left empty.";
        }
        if (this.state.newCustomerContact === '') {
            formIsValid = false;
            errors["newCustomerContact"] = "Mobile can't left empty.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    /*Error Handle end*/

    /*check mobile number error handler start*/
    mobileCheckHandleError() {
        let mobileCheckError = {};
        let formIsValid = true;
        if (this.state.customerMobile === '') {
            formIsValid = false;
            this.state.searchView = false;
            this.state.buttonDisabled = true;
            mobileCheckError["mobileNumberCheck"] = "Mobile Number can't left empty.";
        }
        this.setState({ mobileCheckError: mobileCheckError });
        return formIsValid;
    }
    /*check mobile number error handler end*/

  

    render() {

        totalMessageBalance = this.refs.walletBalance && this.refs.walletBalance.state && this.refs.walletBalance.state.userWalletBalance && this.refs.walletBalance.state.userWalletBalance.smsBalance;

        totalVoiceBalance = this.refs.walletBalance && this.refs.walletBalance.state && this.refs.walletBalance.state.userWalletBalance && this.refs.walletBalance.state.userWalletBalance.voiceBalance;

        totalEmailBalance = this.refs.walletBalance && this.refs.walletBalance.state && this.refs.walletBalance.state.userWalletBalance && this.refs.walletBalance.state.userWalletBalance.emailBalance;

        totalBalance = totalMessageBalance + totalVoiceBalance + totalEmailBalance;
        
    
        
        // let tableHeader = <span> <span>Product sell List</span><span className='float-right text-bold'> <i className='pi pi-eye'> <Link to={{ pathname: MY_POINT.SALES_PRODUCT_LOG}} className='link'> View More </Link></i> </span></span>

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Product Sell List</div>
            <div className="header-title-right">
                <Link to={{ pathname: MY_POINT.SALES_PRODUCT_LOG }}>
                    More<i className="fas fa-angle-right" style={{ "fontSize": "28px" }}></i>
                </Link>
            </div>
        </div>;



        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let productName = []
        if (this.state.productList && this.state.productList.length) {
            productName = this.state.productList.map((item) => ({
                value: item.productID,
                label: item.product,
            }));
        }
        let purchaseCode = []
        if (this.state.purchaseCodeList && this.state.purchaseCodeList.length) {
            purchaseCode = this.state.purchaseCodeList.map((item) => ({
                value: item.productPurchaseCodeID,
                label: item.purchaseCode,
            }));
        }


        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="nw-form">
                    <div className="p-grid p-col-12 p-xl-12">
                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                            <div className="nw-form-body">
                                <div className="p-grid seprator-inside">
                                    <div className="p-col-12 p-xl-12">

                                        <div className="p-col-12 p-xl-12">
                                            {this.state.firstDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Product Name <span>*</span></span>
                                                    <Dropdown
                                                        placeholder="Select Product Name"
                                                        value={this.state.salesInfo.productName}
                                                        options={productName}
                                                        autoWidth={false}
                                                        onChange={(e) => this.productNameHandle(e, this.props)}
                                                    />

                                                </div>
                                            }
                                            <span className="error-message">{this.state.productSalesError["salesProductName"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            {this.state.secondDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                                    <Dropdown
                                                        placeholder="Select Purchase Code"
                                                        value={this.state.salesInfo.productPurchaseCodeDTO.productPurchaseCodeID}
                                                        options={purchaseCode} autoWidth={false}
                                                        onChange={(event) => this.purchaseCodeHandle(event, this.props)}
                                                    />

                                                </div>
                                            }
                                            <span className="error-message">{this.state.productSalesError["productDefaultCode"]}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Mobile Number <span>*</span></span>
                                                <InputText
                                                    type="text"
                                                    name="CustomerContactCheck"
                                                    id="contactCheck"
                                                    placeholder="enter customer mobile number"
                                                    onChange={(event) => this.customeMobileNUmberHandler(event, this.props)}
                                                    keyfilter="pint"
                                                    maxlength={11}
                                                    value={this.state.customerMobile}
                                                />
                                                <button
                                                    style={{ marginRight: '2px' }}
                                                    className="p-button p-component p-button-icon-only"
                                                    onClick={(event) => this.checkMobileNumber(event, this.props)}><span className="fas fa-search p-c p-button-icon-left"></span><span className="p-button-text p-c">p-btn</span></button>
                                                <button
                                                    className="p-button p-component p-button-icon-only"
                                                    onClick={(e) => this.setState({ mobileDialog: true })}><span className="fas fa-plus p-c p-button-icon-left"></span><span className="p-button-text p-c">p-btn</span></button>
                                            </div>
                                            {/* <span className="error-message">{this.state.productSalesError["productSalesCustomerMobile"]}</span> */}


                                            <span className="error-message">{this.state.mobileCheckError["mobileNumberCheck"]}</span>
                                        </div>


                                        {this.state.searchView ?

                                            <div className="p-col-12 p-xl-12">
                                                <div className="nw-search-view">
                                                    {this.state.searchViewError ?
                                                        <center className="error-message">{this.state.mobileCheckError["mobileDoesNotFound"] || "No Data Found"}</center> :
                                                        <div>
                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.searchIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    this.state.customerName === '' ? '' :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Customer Name</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.customerName}
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>

                                                    }
                                                </div>
                                            </div>

                                            : ""
                                        }


                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Sales Price <span>*</span></span>
                                                <InputText
                                                    keyfilter="num"
                                                    placeholder="Enter Sales Price"
                                                    name="salePrice"
                                                    onChange={(e) => this.salesPriceHandle(e, this.props)}
                                                    value={this.state.salesInfo.salesPrice} />

                                            </div>
                                            <span className="error-message">{this.state.productSalesError["productSalesPrice"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Sales Note <span>*</span></span>
                                                <InputTextarea
                                                    rows={5}
                                                    cols={30}
                                                    onChange={(e) => this.salesNote(e, this.props)}
                                                    value={this.state.salesInfo.salesNote}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.productSalesError["productSalesNote"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                        {totalBalance >= 1 ?
                                            <div className="formControl">
                                                <Checkbox
                                                    id="smsCheck"
                                                    value="sendSms"
                                                    onChange={(e) => this.checkSelection(e)}

                                                    // onChange={e => this.setState({ checked: e.checked })}
                                                    checked={this.state.checked}
                                                >
                                                </Checkbox>
                                                 <label htmlFor="smsCheck" className="p-checkbox-label">Do you want to send SMS ?</label>
                                            
                                            </div>
                                            : ""
                                        }
                                        </div>

                                        <div className="p-col-12 p-xl-12 ">

                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                                </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Sell"
                                                icon="fas fa-external-link-alt"
                                                onClick={(event) => this.sellProduct(event, this.props)}
                                                disabled={this.state.buttonDisabled}
                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="p-col-12 p-xl-4">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    {!this.state.dataTableIsLoading ?
                                        <WalletBalance
                                            walletBalanceName='Wallet'
                                            messageBalanceName='Message'
                                            ref='walletBalance'
                                        />
                                        :
                                         ''
                                    } 

                                </div>
                                <div className="p-col-12 p-xl-12">
                                    {this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.WalletLoaderContent() :
                                        <div className="nw-wallet">
                                            <center className="today-purchase-balance">
                                                <h2><i className="fa fa-money fa-2x" /></h2>
                                                <div className="nw-wallet-title">
                                                    Today's Sell Amount
                                            </div>

                                                <div className="nw-wallet-balance">

                                                    {/* <div><span> &#2547; </span> {this.state.totalSalesPrice === '' ? '00' :this.state.totalSalesPrice}.00  </div>  */}
                                                    <div><span> &#2547; </span> {parseFloat(this.state.totalSalesPrice).toLocaleString({ maximumFractionDigits: 2 })}  </div>
                                                </div>

                                            </center>
                                        </div>
                                    }

                                </div>
                            </div>
                            <br />
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :

                                <DataTable
                                    header={tableHeader}
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    // paginator={true} 
                                    rows={10}
                                >
                                    <Column field="salesDate" header="Sales Date" className="table-datetime" />
                                    <Column field="productName" header="Product Name" />
                                    <Column field="purchaseCode" header="Purchase Code" />
                                    <Column field="salesPrice" header="Sales Price" className="text-right"/>
                                    <Column field="customerName" header="Customer Name" />
                                    <Column field="customerContact" header="Customer Mobile" />
                                    <Column field="processDate" header="Action" style={{width:"96px"}} body={this.sellCodeBody} />
                                </DataTable>

                            }
                        </div>
                    </div>

                </div>

                <Dialog
                    header="Sales Information"
                    className="nw-dialog"
                    modal={true}
                    visible={this.state.visible}
                    onHide={(e) => this.setState({ visible: false })}
                    maximizable
                >
                    <div className="p-fluid">
                        <div className="p-grid nw-form">
                            <SellProductCodeInfoTable getSalesProductInfo={this.state.rowInformation} />
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Add New Customer" className="nw-dialog nw-form" visible={this.state.mobileDialog} modal={true} onHide={(e) => this.setState({ mobileDialog: false })}>
                    <div className="p-grid">

                        <div className="p-col-12 p-xl-12">
                            <label htmlFor='serialNo'>Customer Name <span>*</span></label>
                            <div className="p-inputgroup">
                                <InputText
                                    placeholder="Enter Customer Name"
                                    id="name"
                                    keyfilter={/^[a-zA-Z&\s]/}
                                    name="customerName"
                                    onChange={(event) => this.customerNameHandeler(event, this.props)}
                                />
                            </div>
                        </div>
                        <span className="error-message">{this.state.errors["newCustomerName"]}</span>


                        <div className="p-col-12 p-xl-12">
                            <label htmlFor='serialNo'>Customer Mobile No. <span>*</span></label>
                            <div className="p-inputgroup">
                                <InputText
                                    type="text"
                                    name="customerContact"
                                    id="contact"
                                    placeholder="Enter Customer Mobile No."
                                    onChange={(event) => this.mobileNUmberHandler(event, this.props)}
                                    keyfilter="pint"
                                    maxlength={11}
                                />
                            </div>
                        </div>
                        <span className="error-message">{this.state.errors["newCustomerContact"]}</span>

                        <div className="p-col-12 nw-button-parent">
                            <Button
                                name="district"
                                label="Save"
                                icon="fas fa-check"
                                className="p-button p-button-primary nw-button nw-button-right"
                                onClick={(event) => this.addMobileNumberHandle(event, this.props)}
                            />
                        </div>
                    </div>
                </Dialog>
            </div>

        )
    }

}