import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { UpdateInventory } from './UpdateInventory';
import { UserCategoryService } from '../../service/UserCategoryService';
import { AdminPointService } from '../../service/adminPoint/AdminPointService';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';

let errors = {};

export class AddInventory extends Component {

    constructor() {
        super();
        this.state = {
            productTypeList: '',
            productType: '',
            productList: '',
            productName: '',
            productId: '',
            note: '',
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: '',
            firstDropDownIsLoading: false,
            secondDropDownIsLoading: false
        }

        this.fetchProductTypeList = this.fetchProductTypeList.bind(this);
        this.onChangeProductType = this.onChangeProductType.bind(this);
        this.onChangeProductName = this.onChangeProductName.bind(this);
        this.onChangeProductID = this.onChangeProductID.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.submitSave = this.submitSave.bind(this);
        this.reloadDataTableValue = this.reloadDataTableValue.bind(this);
        this.userCategoryService = new UserCategoryService();
        this.adminPointService = new AdminPointService();
        this.crmPointService = new CRMPointService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.fetchProductTypeList('T104');
    }

    fetchProductTypeList(defaultCode) {

        this.setState({ topProgressBar: true, firstDropDownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productTypeList: body, topProgressBar: false, firstDropDownIsLoading: false });
                    })
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchProductList(enableStatus, productTypeId) {
        let productTypeInfoDTO = {
            "coreCategoryID": productTypeId,
        }
        this.setState({ topProgressBar: true, secondDropDownIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchProductListByProductType(enableStatus, productTypeInfoDTO)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productList: body, topProgressBar: false, secondDropDownIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, secondDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeProductType(value) {
        errors["productType"] = "";
        this.setState({ productType: value, errors: errors });
        this.fetchProductList(1, value);
    }

    onChangeProductName(value) {
        errors["productName"] = "";
        this.setState({ productName: value, errors: errors });
    }

    onChangeProductID(value) {
        errors["productId"] = "";
        this.setState({ productId: value, errors: errors });
    }

    onChangeNote(value) {
        this.setState({ note: value});
    }

    reloadDataTableValue() {
        this.refs.updateInventory.fetchProductListFromInventory();
    }

    submitSave() {

        if (this.handleError()) {

            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })

            let requestedObj = {
                "productUniqueID": this.state.productId,
                "inventoryNote": this.state.note,
                "productInfoDTO": {
                    "productID": this.state.productName,
                    "productTypeInfoDTO": {
                        "coreCategoryID": this.state.productType,
                    },
                },
            }
            this.crmPointService.addNewProductAtInventory(requestedObj)
                .then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data save successful.' });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false })
                        this.setState({
                            productType: '',
                            productName: '',
                            productList: '',
                            productId: '',
                            note: '',
                        })
                        this.reloadDataTableValue();
                    } else {
                        this.crmPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }

    handleError() {

        errors = {};
        let formIsValid = true;

        if (this.state.productType === '') {
            formIsValid = false;
            errors["productType"] = "Product Type can't left empty.";
        }
        if (this.state.productName === '') {
            formIsValid = false;
            errors["productName"] = "Product Name can't left empty.";
        }
        if (this.state.productId === '') {
            formIsValid = false;
            errors["productId"] = "Product Unique ID can't left empty.";
        } 
        // if (this.state.note === '') {
        //     formIsValid = false;
        //     errors["note"] = "Note can't left empty.";
        // }
        else {
            return formIsValid;
        }
        this.setState({ errors: errors });
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let productTypeOptions = [];
        if (this.state.productTypeList && this.state.productTypeList.length) {
            productTypeOptions = this.state.productTypeList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let productListOptions = [];
        if (this.state.productList && this.state.productList.length) {
            productListOptions = this.state.productList.map(item => ({
                value: item.productID,
                label: item.productName
            }));
        }

        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid nw-form">
                   
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-form-body blur-section">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-col-12 p-xl-12">
                                    {this.state.firstDropDownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Product Type <span>*</span></span>

                                            <Dropdown
                                                placeholder='Select Product Type'
                                                name="productType"
                                                options={productTypeOptions}
                                                value={this.state.productType}
                                                onChange={(e) => this.onChangeProductType(e.target.value)}
                                                filter={true}
                                                autoWidth={false}
                                            />

                                        </div>
                                    }
                                    <span className='error-message'>{this.state.errors["productType"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Product Name <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Product Name'
                                            name="productName"
                                            options={productListOptions}
                                            value={this.state.productName}
                                            onChange={(e) => this.onChangeProductName(e.target.value)}
                                            filter={true}
                                            autoWidth={false}
                                        />
                                    </div>
                                    <span className='error-message'>{this.state.errors["productName"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Product Unique ID <span>*</span></span>
                                        <InputText
                                            name='productId'
                                            placeholder='Enter Product Unique ID'
                                            value={this.state.productId}
                                            onChange={(e) => this.onChangeProductID(e.target.value)}
                                            autoWidth={false}
                                        />

                                    </div>
                                    <span className='error-message'>{this.state.errors["productId"]}</span>
                                </div>
                                


                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtextarea-label">Note</span>
                                        <InputTextarea
                                            name='note'
                                            placeholder='Enter Note'
                                            value={this.state.note}
                                            onChange={(e) => this.onChangeNote(e.target.value)}
                                        />
                                    </div>
                                    {/* <span className='error-message'>{this.state.errors["note"]}</span> */}
                                </div>

                                <div className="p-col-12 p-xl-12" />

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                        </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Save"
                                        icon="fas fa-check"
                                        onClick={event => this.submitSave(event)}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="p-col-12 p-lg-12 p-xl-12" >
                        {this.state.dataTableIsLoading ?
                            this.NetiContentLoader.MyPointTableLoader() :
                            <UpdateInventory ref="updateInventory" />
                        }
                    </div>


                </div>
            </div>


        )
    }
}