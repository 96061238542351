import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { SaSummaryService } from '../../../service/saPoint/SaSummaryService';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import mapImage from '../../../assets/images/map-image.png';
import ImageMapper from 'react-image-mapper';
import GoogleMapReact from 'google-map-react'
import {DatatableAttendanceInformation} from './DatatableAttendanceInformation';


let maxDate = new Date();
export class DevisionWiseAttendenceMap extends Component {

    constructor() {
        super();
        this.mapRef = React.createRef();
        this.state = {
            hoveredArea: '',
            city: null,
            checked1: false,
        }

        this.handleApiLoaded = this.handleApiLoaded.bind();
        this.onMapClicked = this.onMapClicked.bind();

    }

    componentDidMount(){
        let mapMenu = document.querySelector(".map-menu-wrapper");
        let mapMenuHeight = mapMenu.offsetHeight;
        let mapSelector = document.querySelector(".map-wrapper");
        if(mapSelector) mapSelector.style.height = mapMenuHeight+"px";
        let elementList = document.querySelectorAll('div.mapPin');
        let createElement =  document.createElement("div");
        for(let i = 0; i < elementList.length; i++){
            // let element = document.querySelector('div.mapPin');
            // element.appendChild(createElement);
            //document.getElementsByClassName("mapPin").addEventListener("click", (e) => this.onMapClicked(e));
        }
    }




    handleApiLoaded = (map, maps) => {
        //console.log('this is demo');
    }

    AnyReactComponent = ({ text }) => <div className="mapPin" title={text}>{}</div>;

    onMapClicked =  (e) => {
        let pinIcon = this.mapRef.current.children[0];
        let createElement = document.createElement("div");
        let removeDiv = pinIcon.children[1];
        if(removeDiv)removeDiv.remove();
        pinIcon.appendChild(createElement);
        pinIcon.children[1].classList.add("map-details-wrapper");
        let html = '<div class="map-details">';
        html += '<i class="fas fa-times closeBtn"></i>';
        html += '</i>';
        html += '<div class="map-data-left">';
        html += '<div>';
        html += '</div>';
        html +=  '<p>+pinDiv.name+<p>';
        html += '</div>';
        html += '<div class="map-data-right">';
        html += '<ul>';
        html += '<li>Institute : <span>380</span></li>';
        html += '<li>Total Teacher : <span>4,485</span></li>';
        html += '<li>Total Present : <span>3,000</span></li>';
        html += '<li>Total Absent : <span>1,400</span></li>';
        html += '<li>Total Leave : <span>485</span></li>';
        html += '</ul>';
        html += '</div>';
        html += '</div>';
        document.getElementsByClassName('map-details-wrapper')[0].insertAdjacentHTML('afterbegin', html);
        let mapWrapper = document.querySelector(".map-details-wrapper");
        let x =  `${e.clientX + e.target.offsetX}px`;
        let y =  `${e.clientY + e.target.offsetY}px`;
        mapWrapper.style.backgroundColor = "#fff";
        mapWrapper.style.height = "auto";
        mapWrapper.style.minWidth = "300px";
        mapWrapper.style.left = x;
        mapWrapper.style.top =  y;
        mapWrapper.style.zIndex =  1;
        mapWrapper.style.position = "absolute";
        console.log('target e', e)
        console.log('this', this)
        console.log('this offsetHeight', this.mapRef.current.offsetHeight)
        console.log('this offsetWidth', this.mapRef.current.offsetWidth)



        console.log('target', e.clientX);
    }

   

    render() {

        
        
        let { topProgressBar, errorMsgVisible, errorMsgBody, staffAttendanceSummaryList } = this.state;
        let tableHeader = (
            <div className="p-clearfix">
                HR Attendance Summary{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {staffAttendanceSummaryList ? staffAttendanceSummaryList.length : ''}{" "}
                </span>
            </div>
        );

        let MAP = {
            name: "my-map",
            areas: [
                // { name: "Mymenshingh", shape: "poly", coords: [183, 132, 172, 134, 166, 143, 164, 153, 164, 159, 173, 163, 178, 169, 172, 176, 167, 183, 168, 191, 171, 198, 172, 204, 181, 201, 190, 200, 198, 198, 206, 195, 212, 195, 218, 195, 225, 199, 232, 203, 237, 206, 244, 210, 252, 211, 261, 212, 270, 213, 278, 211, 286, 211, 292, 209, 298, 205, 291, 199, 297, 196, 303, 193, 310, 191, 309, 183, 309, 176, 308, 168, 303, 162, 298, 156, 288, 155, 280, 151, 280, 145, 269, 144, 260, 144, 245, 144, 231, 145, 204, 139, 194, 138, 186, 137], strokeColor: "rgb(255, 255, 255)", preFillColor: "rgba(255, 255, 255, 0.0)", fillColor: "rgba(255, 255, 255, 0)" },
                // { name: "Mymenshingh", shape: "poly", coords: [280, 144, 286, 143, 293, 143, 301, 142, 311, 142, 320, 143, 329, 145, 338, 146, 348, 145, 358, 145, 370, 143, 384, 143, 394, 146, 403, 147, 411, 151, 419, 153, 428, 158, 423, 160, 414, 161, 407, 161, 408, 168, 404, 175, 401, 182, 396, 187, 389, 190, 384, 199, 377, 198, 367, 197, 367, 203, 361, 198, 358, 203, 350, 205, 341, 204, 333, 203, 333, 211, 326, 209, 321, 203, 324, 197, 318, 195, 312, 195, 311, 188, 308, 181, 309, 174, 307, 167, 301, 161, 297, 157, 290, 155, 284, 153], strokeColor: "rgb(255, 255, 255)", preFillColor: "rgba(255, 255, 255, 0.0)", fillColor: "rgba(255, 255, 255, 0)" },
                // { name: "Mymenshingh", shape: "poly", coords: [526, 515, 520, 503, 504, 483, 496, 473, 490, 464, 489, 440, 497, 431, 507, 428, 514, 434, 521, 438, 532, 438, 544, 451, 550, 445, 544, 433, 535, 417, 526, 398, 520, 381, 509, 360, 508, 351, 497, 332, 487, 315, 469, 292, 459, 283, 455, 274, 451, 264, 449, 255, 441, 246, 435, 238, 430, 230, 426, 235, 419, 229, 412, 234, 403, 231, 396, 228, 399, 238, 401, 247, 391, 252, 387, 261, 390, 270, 390, 278, 382, 283, 372, 287, 365, 282, 360, 275, 355, 268, 348, 261, 345, 268, 348, 277, 341, 275, 336, 267, 330, 256, 325, 248, 320, 240, 315, 236, 314, 230, 320, 224, 322, 216, 329, 212, 325, 207, 322, 201, 326, 196, 318, 195, 310, 192, 300, 196, 291, 199, 300, 206, 293, 209, 290, 215, 283, 219, 275, 219, 268, 219, 268, 226, 259, 231, 266, 241, 258, 245, 258, 252, 266, 260, 263, 266, 261, 274, 263, 282, 270, 291, 277, 297, 282, 304, 290, 309, 297, 313, 310, 319, 323, 327, 332, 321, 338, 314, 337, 306, 347, 300, 356, 302, 370, 309, 378, 314, 387, 320, 432, 384, 431, 390, 426, 379, 422, 387, 424, 396, 431, 396, 431, 411, 437, 426, 450, 419, 448, 426, 454, 435, 465, 451, 476, 466, 491, 479], strokeColor: "rgb(255, 255, 255)", preFillColor: "rgba(255, 255, 255, 0.0)", fillColor: "rgba(255, 255, 255, 0)" },
                // { name: "Mymenshingh", shape: "poly", coords: [341, 305, 348, 312, 348, 306], strokeColor: "rgb(255, 255, 255)", preFillColor: "rgba(255, 255, 255, 0.0)", fillColor: "rgba(255, 255, 255, 0)" },

            ]
        }



        // let tableHeader = <span><span>Staff Attendance Summary</span><span className='float-right text-bold'></span></span>
        // let staffAttendanceSummaryInfo = []

        // if(this.state.staffAttendanceSummaryList){
        //     staffAttendanceSummaryInfo = this.state.staffAttendanceSummaryList.item 
        // }




        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>
        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="nw-form">
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="divisionAttendenceWrapper">
                                <div className="google-map-wrapper map-wrapper" >
                                    <div clasName="map-image-wrapper" style={{ height: '100%', width: '100%', marginTop: '0' }} ref={this.mapRef}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: 'AIzaSyCNXYN2bqDIEocOBF2cIraP1ML_cMRjAVE' }}
                                            defaultCenter={{
                                                lat: 23.7788759,
                                                lng: 90.3959633
                                            }}
                                            defaultZoom={12}
                                            yesIWantToUseGoogleMapApiInternals
                                            onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                                        >
                                            <this.AnyReactComponent
                                                lat={23.804762}
                                                lng={90.411076}
                                                text="Place 1"
                                            />
                                            <this.AnyReactComponent
                                                lat={23.768320}
                                                lng={90.444721}
                                                text="Place 2"
                                            />
                                            <this.AnyReactComponent
                                                lat={23.783675}
                                                lng={90.343816}
                                                text="Place 3"
                                            />
                                        </GoogleMapReact>
                                    </div>
                                </div>
                                <div className="map-menu-wrapper">
                                    <div className="title-wrapper">
                                        <div className="title-icon">
                                            <i className="fas fa-map-marker-alt"></i>
                                        </div>
                                        <div className="title">
                                            <div className="main-title">
                                                <h1>Division</h1>
                                            </div>
                                            <div className="sub-title">
                                                <h3>Wise Info</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="calender-wrapper">
                                        <div className="nw-w-100 p-relative">
                                            <Calendar
                                                name='requestStartDate'
                                                yearRange="2010:2030"
                                                dateFormat="dd/mm/yy"
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                dateOnly="true"
                                                placeholder="Select Date"
                                            />
                                            <span className="pi pi-calendar emptyDropdown"></span>
                                        </div>
                                    </div>
                                    <div className="radio-button-wrapper">
                                        <div>
                                            <RadioButton inputId="rb1" value="val1" name="city" onChange={(e) => this.setState({ value: e.value })} checked={this.state.value === 'val1'} />
                                            <label htmlFor="rb1" className="p-radiobutton-label">Institute Info</label>
                                        </div>
                                        <div>
                                            <RadioButton inputId="rb1" value="val1" name="city" onChange={(e) => this.setState({ value: e.value })} checked={this.state.value === 'val1'} />
                                            <label htmlFor="rb1" className="p-radiobutton-label">Teacher Info</label>
                                        </div>
                                        <div>
                                            <RadioButton inputId="rb1" value="val1" name="city" onChange={(e) => this.setState({ value: e.value })} checked={this.state.value === 'val1'} />
                                            <label htmlFor="rb1" className="p-radiobutton-label">Attendence Info</label>
                                        </div>
                                    </div>
                                    <div className="attendence-filters">
                                        <div className="custom-inputswitch">
                                            <span className="color-name-1"></span>
                                            <span className="attendaence-cat">Total Present</span>
                                            <InputSwitch checked={this.state.checked1} onChange={(e) => this.setState({ checked1: e.value })} />
                                        </div>
                                        <div className="custom-inputswitch">
                                            <span className="color-name-2"></span>
                                            <span className="attendaence-cat">Total Absent</span>
                                            <InputSwitch checked={this.state.checked1} onChange={(e) => this.setState({ checked1: e.value })} />
                                        </div>
                                        <div className="custom-inputswitch">
                                            <span className="color-name-3"></span>
                                            <span className="attendaence-cat">Total Leave</span>
                                            <InputSwitch checked={this.state.checked1} onChange={(e) => this.setState({ checked1: e.value })} />
                                        </div>
                                    </div>
                                    <div className="division-wrapper">
                                        <ul className="division-list">
                                            <li className="active">
                                                <h1>Rangpur</h1>
                                                <h3>380 Institute</h3>
                                            </li>
                                            <li className="">
                                                <h1>Rangpur</h1>
                                                <h3>380 Institute</h3>
                                            </li>
                                            <li className="">
                                                <h1>Rangpur</h1>
                                                <h3>380 Institute</h3>
                                            </li>
                                            <li className="">
                                                <h1>Rangpur</h1>
                                                <h3>380 Institute</h3>
                                            </li>
                                            <li className="">
                                                <h1>Rangpur</h1>
                                                <h3>380 Institute</h3>
                                            </li>
                                            <li className="">
                                                <h1>Rangpur</h1>
                                                <h3>380 Institute</h3>
                                            </li>
                                            <li className="">
                                                <h1>Rangpur</h1>
                                                <h3>380 Institute</h3>
                                            </li>
                                            <li className="">
                                                <h1>Rangpur</h1>
                                                <h3>380 Institute</h3>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <DatatableAttendanceInformation />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}