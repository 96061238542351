import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { EmAdminPointService } from '../../service/emAdminPoint/EmAdminPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import NetiContentLoader from '../common/NetiContentLoader';
import { NetiDateUtils } from '../../utils/NetiDateUtils';


let cloneStateBeforeMount;
let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>
export class EmYssConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberStatus: '',
            dataTableValue: [],
            instituteFormError: {},
            deviceInfo: {
                deviceSerial: '',
                deviceIdentification: '',
                userRegistrationDTO: {
                    userID: ''
                }
            },
            yssConfigRequestInfo: {
                purchaseCode: '',
                instituteInfoDTO: {
                    emDetailsId: ''
                }
            },
            payabaleInstituteList: [],
            payableInstituteID: '',
            dataTableIsLoading: false,
            firstDropdownIsLoading: false,
            payableInstituteInfo: {},
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
        }
        this.partnerPointService = new PartnerPointService();
        this.emAdminPointService = new EmAdminPointService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.viewYssDeviceInfoList();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    viewYssDeviceInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.emAdminPointService.getEmAdminYssPartnerPoints(5, 0)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].yssExpireDate == null) {
                                body[i].yssExpireDate = '';
                            } else {
                                body[i].yssExpireDate = NetiDateUtils.getAnyShortForm(body[i].yssExpireDate, 'DD-MMM-YYYY');
                            }
                        }
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false,errorMsgVisible: false  });
                    });
                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    getPayableInstituteList = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.emAdminPointService.getInstituteByUser()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ payabaleInstituteList: body });
                        this.setState({ topProgressBar: false, errorMsgVisible: false,firstDropdownIsLoading: false });
                    });
                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })
            );
    }


    onChangePayableInstitute = (event) => {
        let { yssConfigRequestInfo, payabaleInstituteList, payableInstituteInfo, instituteFormError } = this.state;
        for (let i = 0; i < payabaleInstituteList.length; i++) {
            if (payabaleInstituteList[i].emDetailsId == event.target.value) {
                payableInstituteInfo = payabaleInstituteList[i];
                this.setState({ payableInstituteInfo });
            }
        }
        yssConfigRequestInfo.instituteInfoDTO.emDetailsId = event.target.value;
        this.setState({ yssConfigRequestInfo });

        instituteFormError["payableInstitute"] = ''
        this.setState({ instituteFormError });

    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    onHideInstitute = (event) => {
        this.setState({ instituteVisible: false });
    }

    onChangeDevice = (rowData) => {
        return <div>
            <span onClick={this.onChangeViewDevice}>{rowData.desireUrl}</span>
        </div>;

    }

    onHideYssDevice = (event) => {
        this.setState({ viewYssDevice: false });
    }

    onChangeViewDevice = () => {
        this.setState({ viewYssDevice: true });
    }

    viewInstituteDialog = () => {
        this.setState({ instituteVisible: true });
    }

    onChangeAddInstitute = () => {
        this.viewInstituteDialog();
    }

    onChangeAddInstitute = () => {
        this.viewInstituteDialog();
        this.getPayableInstituteList();
    }

    onChangePurchaseCode = (e) => {
        let { yssConfigRequestInfo, instituteFormError } = this.state;
        yssConfigRequestInfo.purchaseCode = e.target.value;
        this.setState({ yssConfigRequestInfo });

        instituteFormError["purchaseCode"] = ''
        this.setState({ instituteFormError });
    }

    addNewInstitute = () => {
        let { yssConfigRequestInfo } = this.state;
        if (this.addInstituteFormError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.emAdminPointService.createEmAdminPartnerYssConfig(yssConfigRequestInfo)
                .then(res => {
                    if (res.status === 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                        this.setState({
                            yssConfigRequestInfo: {
                                purchaseCode: '',
                                instituteInfoDTO: {
                                    emDetailsId: ''
                                }
                            }
                        })
                        this.viewYssDeviceInfoList();
                        this.onHideInstitute();
                        this.setState({ topProgressBar: false, errorMsgVisible: false  })
                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })
                });
        }

    }

    addInstituteFormError = () => {
        let { instituteFormError, yssConfigRequestInfo } = this.state;
        let formIsValid = true;

        if (yssConfigRequestInfo.purchaseCode === '') {
            formIsValid = false;
            instituteFormError["purchaseCode"] = "Purchase code can't left empty.";
        }
        if (!yssConfigRequestInfo.instituteInfoDTO.emDetailsId) {
            formIsValid = false;
            instituteFormError["payableInstitute"] = "Institute can't left empty.";
        }
        this.setState({ instituteFormError });
        return formIsValid;
    }

    addDeviceFormError = () => {
        let { deviceInfoError, deviceInfo } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (!deviceInfo.deviceSerial) {
            formIsValid = false;
            deviceInfoError["deviceSerial"] = "Serial can't left empty.";
        }
        if (!deviceInfo.deviceIdentification) {
            formIsValid = false;
            deviceInfoError["deviceID"] = "Device ID can't left empty.";
        }
        this.setState({ deviceInfoError });
        return formIsValid;
    }

    render() {
        let { topProgressBar, yssConfigRequestInfo, errorMsgVisible, errorMsgBody, dataTableValue } = this.state;


        let tableHeader = <div className="header-title">
            <div className="header-title-left">
                Institute List
</div>


            <div className="header-title-right">
                <span>
                    Total Found: {this.state.dataTableValue.length}
                </span>

                <Button
                    label="Add Institute"
                    icon="fas fa-plus"
                    className="p-button p-button-success nw-button nw-button-right"
                    onClick={this.onChangeAddInstitute}
                    iconPos="right"
                />
            </div>
        </div>;


        let payabaleInstitute = [];
        if ((this.state.payabaleInstituteList != null) && (this.state.payabaleInstituteList.length > 0)) {
            payabaleInstitute = this.state.payabaleInstituteList.map((item) => ({
                value: item.emDetailsId,
                label: item.instituteId + " - " + item.instituteName,
            }));
        }

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        <Growl ref={(el) => this.growl = el} />
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    columnResizeMode="fit"
                                >
                                    <Column field="instituteID" header="Insitute ID" filter={true} />
                                    <Column field="instituteName" header="Institute Name" filter={true} />
                                    <Column field="yssExpireDate" header="YSS Expiry Date" filter={true} />
                                </DataTable>
                            }
                        </div>


                    </div>


                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        header="Add Institute"
                        visible={this.state.instituteVisible}
                        onHide={this.onHideInstitute}
                        maximizable
                        closable>
                        <div className="nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                            <InputText
                                                value={yssConfigRequestInfo.purchaseCode}
                                                keyfilter="pint"
                                                onChange={this.onChangePurchaseCode}
                                                placeholder="Enter Purchase Code"
                                                name="purchaseCode"
                                                style={{ width: "100%" }}
                                                type="text"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.instituteFormError["purchaseCode"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Institute <span>*</span></span>
                                            <Dropdown
                                                value={yssConfigRequestInfo.instituteInfoDTO.emDetailsId}
                                                options={payabaleInstitute}
                                                onChange={this.onChangePayableInstitute}
                                                placeholder="Select Institute"
                                                showClear={true}
                                                autoWidth={false}
                                                name="payableInstitute"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.instituteFormError["payableInstitute"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            label="Save"
                                            icon="fas fa-check"
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            onClick={this.addNewInstitute}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>
            </div>
        );
    }
}