import React, { Component } from 'react';

import { Growl } from 'primereact/growl';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

export class MpCallInfoDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateRange: {
                year: '',
                month: ''
            },
            callSummary: {
                inboundCall: 0,
                outboundCall: 0
            },
            callAttendantInfo:[{
                inboundCall: 0, 
                name: "", 
                outboundCall: 0, 
                netiID: 0, 
                totalCall: 0
            }],
            applicationWiseCallInfo:{
                nwCalls:[],
                emCalls:[],
                Unknown: 0
            },
            yearValue: '',
            monthValue: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            errors: {},
        }
        this.managementPointService = new ManagementPointService();
    }

    componentWillMount() {
        // this.fetchCallInfo();
    }

    // removeLocalStorageVariable = (tokenVariable) =>{
    //     localStorage.removeItem(tokenVariable);
    // }

    onChangeYearValue = (e) => {
        let { dateRange, errors } = this.state;
        errors['year'] = '';
        dateRange.year = e.target.value;
        this.setState({ dateRange, errors })
    }

    onChangeMonth = (e) => {
        let { dateRange, errors } = this.state;
        errors['month'] = '';
        dateRange.month = e.target.value;
        this.setState({ dateRange, errors })
    }
    
    getDashboardData = () =>{
        let { dateRange } = this.state;
        this.getCallInfo(dateRange)
        this.getCallAttendantInfo(dateRange)
        this.getApplicationWiseCallInfo(dateRange)
    }

    getCallInfo = (dateRange) => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.managementPointService.fetchCallInfo(dateRange)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ callSummary: body, topProgressBar: false, errorMsgVisible: false });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    getCallAttendantInfo = (dateRange) =>{
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.managementPointService.fetchCallAttendantInfo(dateRange)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ callAttendantInfo: body, topProgressBar: false, errorMsgVisible: false });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    getApplicationWiseCallInfo = (dateRange) =>{
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.managementPointService.fetchApplicationWiseCallInfo(dateRange)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ applicationWiseCallInfo: body, topProgressBar: false, errorMsgVisible: false });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    render() {
        let { dateRange, callSummary, callAttendantInfo, applicationWiseCallInfo, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        // let elements = ['one', 'two', 'three','one', 'two', 'three','one', 'two', 'three',]

        let currentFullDate = new Date();
        let currentYear = currentFullDate.getFullYear('yyyy');
        let yearSelection = [];
        let monthList = [
            { "label": "January", "value": "January" },
            { "label": "February", "value": "February" },
            { "label": "March", "value": "March" },
            { "label": "April", "value": "April" },
            { "label": "May", "value": "May" },
            { "label": "June", "value": "June" },
            { "label": "July", "value": "July" },
            { "label": "August", "value": "August" },
            { "label": "September", "value": "September" },
            { "label": "October", "value": "October" },
            { "label": "November", "value": "November" },
            { "label": "December", "value": "December" },
        ]

        ///year generator
        for (let index = 2013; index <= currentYear; index++) {
            yearSelection.unshift({ label: `${index}`, value: `${index}` });
        }
        return (
            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />

                <div className="main-section mp-call-info-dashboard">
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12 nw-form">
                            <div className="p-grid p-col-12 p-xl-12 nw-form-body">
                                <div className="p-col-12 p-xl-5 p-lg-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Year <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Year"
                                            value={dateRange.year}
                                            options={yearSelection}
                                            name="year"
                                            autoWidth={false}
                                            onChange={this.onChangeYearValue}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["year"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-5 p-lg-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Month <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Month"
                                            value={dateRange.month}
                                            options={monthList}
                                            autoWidth={false}
                                            name="month"
                                            onChange={this.onChangeMonth}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["month"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-2 p-lg-12 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.getDashboardData}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-4">
                            <Panel header="Call Info">
                                <div className="p-grid call-info-dashboard-panel">
                                    <div className="p-col-12">
                                        <Card>
                                            {/* <h4><header>Eduman Token Info</header></h4> */}
                                            <center>
                                                <h1>{callSummary.inboundCall + callSummary.outboundCall}</h1>
                                            </center>
                                            <br />
                                            {/* <hr/> */}

                                            <div className="p-grid token-numbers text-center">

                                                <div className="p-col-12"><hr /><h5>Total Call</h5><hr /></div>

                                                <div className="p-col-6">
                                                    <Link className="text-success" to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_TOKEN_LIST, appName: 'em', tokenStatus: 'pending' }} >
                                                        <u>{callSummary.inboundCall}</u>
                                                    </Link>
                                                </div>
                                                <div className="p-col-6">
                                                    <Link className="text-danger" to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_SOLVED_MODULE_WISE_TOKEN, appName: 'Eduman', appDefCode: 'C11001001' }} >
                                                        <u>{callSummary.outboundCall}</u>
                                                    </Link>
                                                </div>

                                                <div className="p-col-6 bg-success">Inbound</div>
                                                <div className="p-col-6 bg-danger">Outbound</div>

                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </Panel>
                        </div>

                        <div className="p-col-12 p-xl-8">
                            <Panel header="Call Attendant Info">
                                <div className="p-grid call-info-dashboard-panel">
                                    <div className="p-col-12 hr-scroll-view">

                                        {
                                            callAttendantInfo.map((item, index) => {
                                                return <Card>
                                                <h4><header>{ item.name }</header></h4>
                                                <center>
                                                    <h1>{ item.totalCall }</h1>
                                                </center>
                                                <br />
                                                {/* <hr/> */}
    
                                                <div className="p-grid token-numbers text-center">
    
                                                    <div className="p-col-12"><hr /><h5>Total Call</h5><hr /></div>
    
                                                    <div className="p-col-12">
                                                        <div class="nw-datatable-dataview-small-panel">
                                                            <div>
                                                                <div className="text-success">Inbound</div>
                                                                <div>:</div>
                                                                <div> 
                                                                    <Link className="text-success" to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_SOLVED_MODULE_WISE_TOKEN, appName: 'Eduman', appDefCode: 'C11001001' }} >
                                                                        <u>{item.inboundCall}</u>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="text-danger">Outbound</div>
                                                                <div>:</div>
                                                                <div>
                                                                    <Link className="text-danger" to={{ pathname: MANAGEMENT_POINT.MANAGEMENT_POINT_SOLVED_MODULE_WISE_TOKEN, appName: 'Eduman', appDefCode: 'C11001001' }} >
                                                                        <u>{ item.inboundCall }</u>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
    
                                            })
                                        }
                                        
                                    </div>
                                </div>
                            </Panel>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <Panel header="Application Wise Call Info">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-6">
                                        <h2>Eduman Module Wise Call</h2>
                                        <br/>
                                        <hr/>
                                        <div className="p-grid">
                                        <div class="p-col-12"></div>
                                            { applicationWiseCallInfo.emCalls.length > 0 ?
                                                applicationWiseCallInfo.emCalls.map((item, index) => {
                                                    return <React.Fragment>
                                                        <div class="p-col-12 p-xl-6 nw-datatable-dataview-small-panel flex-number-right">
                                                            <div>
                                                                <div>{item.moduleName}</div>
                                                                <div>:</div>
                                                                <div>{item.numOfCalls}</div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                    
                                                })
                                                :
                                                <div class="p-col-12">No Data Found</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-xl-6">
                                        <h2>Netiworld Module Wise Call</h2>
                                        <br/>
                                        <hr/>
                                        <div className="p-grid">
                                            <div class="p-col-12"></div>
                                            { applicationWiseCallInfo.nwCalls.length > 0 ?
                                                applicationWiseCallInfo.nwCalls.map((item, index) => {
                                                    return <React.Fragment>
                                                        <div class="p-col-12 p-xl-6 nw-datatable-dataview-small-panel flex-number-right">
                                                            <div>
                                                                <div>{item.moduleName}</div>
                                                                <div>:</div>
                                                                <div>{item.numOfCalls}</div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                    
                                                })
                                                :
                                                <div class="p-col-12">No Data Found</div>
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}