
export const AppPointMenu = {

    items: [
        {
            label: 'AP Point', icon: 'fas fa-users-cog',

            items: [
                {
                    label: 'AP Assign', icon: '',//pi pi-fw pi-dollar
            
                },
     
            ]
        }
    ]
}