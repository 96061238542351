import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { pdfDownloadPortrait_TableWithFooter } from '../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { Button } from 'primereact/button';
import { formatToCurrency } from '../../common/JsPdfEssentialFile';
import { SplitButton } from 'primereact/splitbutton';

export class DailyDepositAmountList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            pdfExcelTableValue: [],
            searchObj: { ...this.props.location.searchObj },
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
                items: [
                    { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportDepositPdf() },
                    { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
                ]
        }

        this.managementPointService = new ManagementPointService();
        this.ValidatorUtility = new ValidatorUtility();

        this.exportDepositPdf = this.exportDepositPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);
    }

    componentWillMount() {
        this.getDailyBasisDepositAmountList();
    }

    getDailyBasisDepositAmountList = () => {
        let { searchObj } = this.state;

        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.managementPointService.fetchDailyTransectionListByType(searchObj.type, searchObj.date, searchObj.transType)
            .then(res => {
                if (res.status === 200) {
                    return res.json().then(body => {
 
                        if (body.length === 0) {
                            return this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' })
                        }

                        this.setState({ dataTableValue: body });
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.setState({ totalPaidAmountBody: this.calculateTotalAmount(body, 'amount') });

                        //for pdf
                        let tempDataList = []; tempDataList = body;
                        tempDataList.map(item => {
                            item['dateTime'] = item.date = NetiDateUtils.getAnyShortForm(item.date, 'DD-MMM-YYYY hh:mm:ss a');
                            item.netiId = item.netiId.toString();
                        });
                        this.setState({ pdfExcelTableValue: tempDataList });
                    })
                }
                else if (res.status == 404) {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        })
                }
            })
            .catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    calculateTotalAmount(body, amountCategory) {
        return body.map(item => item[`${amountCategory}`]).reduce((a, b) => { return (a + b) }, 0);
    }

    getDataTableDate = (rowData) => {
        return NetiDateUtils.getAnyShortForm(rowData.dateTime, 'DD-MMM-YYYY hh:mm:ss a');
        // return <span>{NetiDateUtils.getAnyShortForm(rowData.dateTime, 'DD-MMM-YYYY')} {NetiDateUtils.getHHMMSSFForHome(rowData.dateTime)}</span>

    }

    depositTemplet = (rowData) => {
        return <div className="col-number text-right" >
            {this.ValidatorUtility.currencyFormatter(rowData.amount)}
        </div>
    }

    exportDepositPdf() {

        let pdfColumns = [
            { title: "Date & Time", dataKey: "dateTime" },
            { title: "Neti ID", dataKey: "netiId" },
            { title: "Name", dataKey: "name" },
            { title: "Bank", dataKey: "bankName" },
            { title: "Transaction ID", dataKey: "transId" },
            { title: "Deposit Amount", dataKey: "amount" },
        ]

        let tableColStyles = { amount: { halign: "right" }, }

        var totalArrayList = [
            { id: "1", totalPdf: "Total Deposit : " + formatToCurrency(this.state.totalPaidAmountBody), }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }];

        let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, fontStyle: "bold", overflow: "linebreak" },
        }

        pdfDownloadPortrait_TableWithFooter("Deposit List (Date Wise)", 70, pdfColumns, this.state.pdfExcelTableValue, tableColStyles, totalColumns, totalArrayList, totalColumnStyles, "Daily_DepositList_Report.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        // excel
        let excelColumns = "";
        let excelFileName = "Daily_Deposit_List_Report.xlsx";
        let excelSheetName = "Daily_Deposit_List";
        excelColumns = [
            <Workbook.Column value="dateTime" label="Date & Time" />,
            <Workbook.Column value="netiId" label="Neti ID" />,
            <Workbook.Column value="name" label="Name" />,
            <Workbook.Column value="bankName" label="Bank" />,
            <Workbook.Column value="transId" label="Transaction ID" />,
            <Workbook.Column value="amount" label="Deposit Amount" />,
        ];
        // end excel

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Deposit List (Date Wise)</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={5} />
                <Column footer={this.ValidatorUtility.currencyFormatter(totalPaidAmountBody)} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className='main-section'>

                    <div className="p-grid">
                        {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }

                            <div className="nw-data-table">
                        <div className="p-col-12 p-lg-12 p-xl-12">
                                {/* {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                                <DataTable
                                    header={tableHeader}
                                    footerColumnGroup={footer}
                                    responsive={true}
                                    selectionMode="single"
                                    paginator={true}
                                    rowsPerPageOptions={[10, 20, 30]}
                                    rows={10}
                                    value={this.state.dataTableValue}
                                //selection={this.state.dataTableSelection}
                                >
                                    <Column field="" header="Date & Time" className="table-datetime" body={this.getDataTableDate} filter={true} />
                                    <Column field="netiId" header="Neti ID" filter={true} />
                                    <Column field="name" header="Name" filter={true} />
                                    <Column field="bankName" header="Bank" filter={true} />
                                    <Column field="transId" header="Transaction ID" filter={true} />
                                    <Column field="amount" header="Deposit Amount" body={this.depositTemplet} filter={true} />

                                </DataTable>

                                {/* :
                                    
                                    <BlankDataTableAnim/> 
                            } */}
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12 nw-button-parent">

                            <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                            <div style={{ display: 'none' }}>

                                <Workbook
                                    filename={excelFileName}
                                    element={
                                        <Button
                                            label="Download Excel"
                                            id="clickExcelBtn"
                                            icon="fas fa-download"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                        />
                                    }
                                >
                                    <Workbook.Sheet data={this.state.pdfExcelTableValue} name={excelSheetName}>
                                        {excelColumns}
                                    </Workbook.Sheet>
                                </Workbook>

                            </div>

                        </div >

                    </div>

                </div>
            </div>
        )
    }
}