import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { ProfileService } from '../../../service/profile/ProfileService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { UserCoreUrlInfoService } from '../../../service/UserCoreUrlInfoService';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';

let errors = {};
let urlId = null;
export class CmsThemeSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dwsUrlInfo: {
                applicationTheme: "rebirth"
            },
            instituteInfo: {
                urlInfoID: '',
                defaultLanguage: '',
                instituteName: '',
                instituteAddress: '',
                instituteContact: '',
                instituteEmail: '',
                urlName: '',
                urlCreateDate: '',
                imageContent: '',
                applicationTheme: ""
            },
            importantLinkInfo: {
                linkUrl: "",
                urlInfoDTO: {
                    urlInfoID: "",
                }
            },
            dwsThemeName: {
                urlTheme: ''
            },
            checkEligibility: false,
            errorMsgVisible: false,
            errorMsgBody: '',
            errors: {}
        }
        this.dwsService = new DwsService();
        this.validatorUtility = new ValidatorUtility();
        this.profileService = new ProfileService();
        this.userCoreUrlInfoService = new UserCoreUrlInfoService();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.dwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        // urlId = this.dwsService.getUrlInfoFromLocalStorage();

        urlId = this.dwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            let { importantLinkInfo } = this.state;
            importantLinkInfo.urlInfoDTO.urlInfoID = urlId;
            this.setState({ importantLinkInfo });
        }

        else {
            this.setState({ value: true });
        }
    }

    componentDidMount() {
        this.getDwsInstituteDetails();

        // this.checkAdmisiaUrlAndTitle();
    }

    getDwsInstituteDetails = () => {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.userCoreUrlInfoService.fetchInstituteDetails("dws", "dws")
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("instituteInfo", body);
                        
                        this.setState({ instituteInfo: body });
                        this.setState({ checkEligibility: true });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                        // this.checkAdmisiaUrlAndTitle();
                    });
                }
                else if (res.status == 404) {
                    return res.json().then((body) => {
                        this.setState({ checkEligibility: false });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }

            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeDwsTheme = (e) => {
        let { dwsThemeName, errors } = this.state;
        errors["instituteDwsTheme"] = "";
        dwsThemeName.urlTheme = e.value;
        this.setState({ dwsThemeName, errors });
        this.onUpdateDefaultTheme(e.value)
    }

    onUpdateDefaultTheme = themeValue => {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.dwsService.updateDwsApplicationTheme(themeValue, urlId)
            .then(res => {
                if (res.status == 202) {
                    return res.json().then((body) => {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully Saved' });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    })
                }
                else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }

            }).catch(error => {
                console.log(error);
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            })
    }


    render() {
        let { dwsUrlInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;

        let themeColor = [

            { label: 'Absolution', value: 'absolution' }, { label: 'Bliss', value: 'bliss' }, { label: 'Clarity', value: 'clarity' }, 
            { label: 'Comfort', value: 'comfort' },{ label: 'Eternity', value: 'eternity' }, { label: 'Euclid', value: 'euclid' }, 
            { label: 'Faith', value: 'faith' }, { label: 'Concord', value: 'concord' },{ label: 'Dulce', value: 'dulce' }, 
            { label: 'Dusk', value: 'dusk' }, { label: 'Elegance', value: 'elegance' }, { label: 'Esprit', value: 'esprit' },
            // { label: 'Essence', value: 'essence' }, { label: 'Fate', value: 'fate' }, { label: 'Grace', value: 'grace' }, { label: 'Hazel', value: 'hazel' },
            // { label: 'Honor', value: 'honor' }, { label: 'Hope', value: 'hope' }, { label: 'Infinity', value: 'infinity' }, { label: 'Joy', value: 'joy' },
            // { label: 'Merit', value: 'merit' }, { label: 'Navy', value: 'navy' }, { label: 'Purity', value: 'purity' }, { label: 'Rebel', value: 'rebel' },
            // { label: 'Rebirth', value: 'rebirth' }, { label: 'Royal', value: 'royal' }, { label: 'Ruby', value: 'ruby' }, { label: 'Solace', value: 'solace' },
            // { label: 'Tranquil', value: 'tranquil' }, { label: 'Valor', value: 'valor' }, { label: 'Vanity', value: 'vanity' }, { label: 'Violet', value: 'violet' },
        ]

        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Choose Your Theme</span>
                                        <Dropdown
                                            value={this.state.dwsThemeName.urlTheme}
                                            className="ui-dropdown"
                                            options={themeColor}
                                            onChange={(e) => this.onChangeDwsTheme(e)}
                                            autoWidth={false}
                                            filter={true}
                                            filterPlaceholder="Select Theme"
                                            filterBy="label,value"
                                            placeholder={this.state.instituteInfo.applicationTheme || "Select theme"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}