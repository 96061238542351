import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Growl } from 'primereact/growl';
import { Link } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';

import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import NetiContentLoader from '../../../common/NetiContentLoader';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';

let maxDate = new Date();

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class PersonWiseToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            personWiseTokenList: [],
            errors: {},
            endDate: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }
        this.managementPointService = new ManagementPointService();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    onChangeFromDate = (e) => {
        let { startDate, errors } = this.state;
        errors["fromDate"] = "";
        startDate = e.target.value;
        this.setState({ startDate, errors });
    }

    onChangeToDate = (e) => {
        let { endDate, errors } = this.state;
        errors["toDate"] = "";
        endDate = e.target.value;
        this.setState({ endDate, errors });
    }

    onSearchPersonWiseToken = () => {
        this.setState({ personWiseTokenList: [] });
        if (this.onSearchFormError()) {
            let fromDate = NetiDateUtils.getDateFromString(this.state.startDate);
            let toDate = NetiDateUtils.getDateFromString(this.state.endDate);
            if (Date.parse(toDate) >= Date.parse(fromDate)) {
                this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false, errorMsgBody: "", });
                this.managementPointService.getPersonWiseTokenList(fromDate, toDate)
                    .then(res => {
                        if (res.status == 302) {
                            return res.json().then((body) => {
                                if (body.length == 0) {
                                    this.setState({ errorMsgVisible: true, errorMsgBody: "No Data Found", topProgressBar: false, dataTableIsLoading: false })
                                } else {
                                    this.setState({ personWiseTokenList: body });
                                    this.setState({ topProgressBar: false, dataTableIsLoading: false });
                                }
                            })
                        }
                        else {
                            this.managementPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                                });
                        }
                    }).catch((error) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
                    });
            }
            else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "To Date can't be earlier than From Date." });
            }
        }
    }

    onSearchFormError = () => {
        let { errors, startDate, endDate } = this.state;
        let formIsValid = true;

        if (startDate === '') {
            formIsValid = false;
            errors["fromDate"] = "Start Date can't left empty.";
        }
        if (endDate === '') {
            formIsValid = false;
            errors["toDate"] = "End Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    solvedTokenBody = rowData => {
        return (
            <Link to={{ pathname: MANAGEMENT_POINT.PERSON_WISE_TOKEN_SOLVED_LIST, personWiseTokenInfo: rowData, startDate: this.state.startDate, endDate: this.state.endDate }}>
                <u>{rowData.solvedToken}</u>
            </Link>
        )
    }

    receivedCallBody = rowData => {
        return (
            <Link to={{ pathname: MANAGEMENT_POINT.PERSON_WISE_RECEIVED_CALL_LIST, personWiseReceivedCallInfo: rowData, startDate: this.state.startDate, endDate: this.state.endDate }}>
                <u>{rowData.receivedCall}</u>
            </Link>
        )
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Neti ID", dataKey: "customID" },
            { title: "Name", dataKey: "name" },
            { title: "Solved Token", dataKey: "solvedToken" },
            { title: "Received Call", dataKey: "receivedCall" },
        ]
        let tableColStyle = {}

        pdfDownloadPortrait_TableWithSubTitle("Person Wise Token Info", 71, subTitleColumn, subTitleList, pdfColumns, this.state.personWiseTokenList, tableColStyle, "PersonWise_Token_List.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, personWiseTokenList } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (this.state.startDate && this.state.endDate) {
            formatedStartDate = (this.state.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (this.state.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = formatedStartDate + ' to ' + formatedEndDate + "_PersonWise_Token_List.xlsx";
        let excelSheetName = "Person_Wise_Tokens";
        excelColumns = [
            <Workbook.Column value="customID" label="Neti ID" />,
            <Workbook.Column value="name" label="Name" />,
            <Workbook.Column value="solvedToken" label="Solved Token" />,
            <Workbook.Column value="receivedCall" label="Received Call" />,
        ];
        // end excel

        let header = <div className="header-title">
            <div className="header-title-left">Person Wise Token Info</div><div className="header-title-right"><a>Total Found: {personWiseTokenList.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        return (
            <div className="p-fluid">
                {/* <div className="p-grid"> */}
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="nw-form">
                    <div className="p-col-12 p-lg-12 p-xl-12">

                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={this.state.startDate}
                                        onChange={this.onChangeFromDate}
                                        showIcon={true}
                                        dateFormat="dd/mm/yy"
                                        name="fromDate"
                                        showIcon={true}
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["fromDate"]}</span>

                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={this.state.endDate}
                                        onChange={this.onChangeToDate}
                                        showIcon={true}
                                        dateFormat="dd/mm/yy"
                                        name="toDate"
                                        showIcon={true}
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["toDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                <Button
                                    label="Search"
                                    className="p-button-primary nw-button nw-button-center"
                                    icon="fas fa-search"
                                    onClick={this.onSearchPersonWiseToken}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="nw-data-table">
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            {/* {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                            <DataTable
                                header={header}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                rowsPerPageOptions={[10, 20, 30]}

                                rows={10}
                                value={this.state.personWiseTokenList}
                            >
                                <Column field="customID" header="Neti ID" filter={true} />
                                <Column field="name" header="Name" filter={true} />
                                <Column field="solvedToken" header="Solved Token" body={this.solvedTokenBody} filter={true} />
                                <Column field="receivedCall" header="Received Call" body={this.receivedCallBody} filter={true} />

                            </DataTable>

                            {/* :
                                    
                                    <BlankDataTableAnim/> 
                            } */}
                        </div>

                    </div>

                    <div className="p-col-12 p-xl-12 nw-button-parent">

                        <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                        <div style={{ display: 'none' }}>

                            <Workbook
                                filename={excelFileName}
                                element={
                                    <Button
                                        label="Download Excel"
                                        id="clickExcelBtn"
                                        icon="fas fa-download"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                    />
                                }
                            >
                                <Workbook.Sheet data={this.state.personWiseTokenList} name={excelSheetName}>
                                    {excelColumns}
                                </Workbook.Sheet>
                            </Workbook>

                        </div>

                    </div >

                </div>

            </div>

        )
    }
}