import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Dropdown } from 'primereact/dropdown';

// custom imports
import { DwsService } from '../../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { NetiCMSSetupProgress } from '../../common/NetiCMSSetupProgress';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { pdfDownloadPortrait_FullTable } from '../../../common/JsPdfDownload';

let cmsId = 0;
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
let totalApplicantsCount = 0;
let totalCollectionAmount = 0;
let totalServiceCharge = 0;
let totalReceiveableAmount = 0;

export class PaymentCollectionAnalytics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            submitSearch: false,

            academicYear: '',
            dataTableValue: [],
            totalCountObj: {}
        }

        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    onSubmitHandler() {
        this.setState({ submitSearch: true });
        if (this.state.academicYear) {
            this.getPaymentCollectionAnalyticsList();
        }
    }

    getPaymentCollectionAnalyticsList() {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.getPaymentCollectionAnalyticsByAcademicYear({ cmsId: cmsId, academicYear: this.state.academicYear })
            .then(res => {

                // console.log('res-body', res);
                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('body', body);
                        if (body.messageType == 1) {
                            this.setState({ dataTableValue: body.item });
                            this.totalCounterFunc(body.item);
                            this.setState({ dataTableIsLoading: false, topProgressBar: false });
                        } else {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    }))
                }

            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });

    }

    exportPdf = () => {

        let tableColumns = [
            { title: "Class", dataKey: "class" },
            { title: "Group.", dataKey: "group" },
            { title: "Total Applicant", dataKey: "totalApplicant" },
            { title: "Collection Amount", dataKey: "collectionAmount" },
            { title: "Service Charge", dataKey: "serviceCharge" },
            { title: "Receivable Amount", dataKey: "receiveableAmount" }
        ];

        const tdColumnStyle = {};
        var totalArrayList = [
            {
              id: "1",
              totalPdf: "Total",
              totalApplicant: totalApplicantsCount,
              totalCollectionAmount: totalCollectionAmount,
              totalServiceCharge: totalServiceCharge,
              totalReceiveableAmount: totalReceiveableAmount,
            }
          ];

          let totalColumns = [{ dataKey: "totalPdf" },{ dataKey: "totalApplicant" }, { dataKey: "totalCollectionAmount" }, { dataKey: "totalServiceCharge" }, { dataKey: "totalReceiveableAmount" }];

          let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, cellWidth: 49, fontStyle: "bold", },
            totalApplicant: { halign: "right", fontStyle: "bold", fontSize: 9, cellWidth: 29 },
            totalCollectionAmount: { halign: "right", fontSize: 9, fontStyle: "bold", cellWidth: 35.5 },
            totalServiceCharge: { halign: "right", fontSize: 9, fontStyle: "bold", cellWidth: 29.5},
            totalReceiveableAmount: { halign: "right", fontSize: 9, fontStyle: "bold", cellWidth: 37 }
          }

        pdfDownloadPortrait_FullTable("Collection Analytics List Report", 75, pdfTitleHeader, pdfTitleHeaderData, tableColumns, this.state.dataTableValue, tdColumnStyle, totalColumns, totalArrayList, totalColumnStyles, "Collection_Analytics.pdf");


    }


    totalCounterFunc = (dataTableList) => {

        // let totalApplicantsCount = 0;
        // let totalCollectionAmount = 0;
        // let totalServiceCharge = 0;
        // let totalReceiveableAmount = 0;

        dataTableList.forEach(element => {
            totalApplicantsCount += element.totalApplicant;
            totalCollectionAmount += element.collectionAmount;
            totalServiceCharge += element.serviceCharge;
            totalReceiveableAmount += element.receiveableAmount;
        });

        let totalCountObj = {
            abc: parseInt(totalApplicantsCount),
            totalCollectionAmount: totalCollectionAmount,
            totalServiceCharge: totalServiceCharge,
            totalReceiveableAmount: totalReceiveableAmount,
        }
        // console.log('totalCountObj', totalCountObj);

        this.setState({ totalCountObj: totalCountObj });

    }

    render() {

        let { dataTableValue, totalCountObj, errors, submitSearch, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let currentYear = new Date().getFullYear();
        console.log(dataTableValue);
        let academicYearOptions = [
            { label: currentYear + 1, value: currentYear + 1 },
            { label: currentYear, value: currentYear },
            { label: currentYear - 1, value: currentYear - 1 },
            { label: currentYear - 2, value: currentYear - 2 },
            { label: currentYear - 3, value: currentYear - 3 },
            { label: currentYear - 4, value: currentYear - 4 },
        ];
        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
        ];
        pdfTitleHeaderData = [
            { A: "Year", B: this.state.academicYear }
        ];

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Collection Analytics List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>;

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
                <Column footer={totalCountObj.abc} />
                <Column footer={totalCountObj.totalCollectionAmount} />
                <Column footer={totalCountObj.totalServiceCharge} />
                <Column footer={totalCountObj.totalReceiveableAmount} />
            </Row>
        </ColumnGroup>;

        return (

            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                {this.state.homeReturnButton === false ?

                    <div className="main-section blur-section">

                        <div className="p-grid nw-form">
                            {/* <div className="p-grid "> */}
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form-body">
                                    <div className="p-col-12 p-xl-10">

                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Academic Year <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Academic Year"
                                                filter={true}
                                                filterBy="label,value"
                                                options={academicYearOptions}
                                                value={this.state.academicYear}
                                                onChange={e => this.setState({ academicYear: e.value })}
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.academicYear === '' && submitSearch ? "Academic Year can't left empty" : ''}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-2 nw-button-parent">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-center"
                                            label="Search"
                                            icon="fas fa-search"
                                            onClick={this.onSubmitHandler.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    footerColumnGroup={footerGroup}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column field="class" header="Class" filter={true} />
                                    <Column field="group" header="Group" filter={true} />
                                    <Column field="totalApplicant" header="Total Applicant" filter={true} />

                                    <Column field="collectionAmount" header="Collection Amount" filter={true} />
                                    <Column field="serviceCharge" header="Service Charge" filter={true} />
                                    <Column field="receiveableAmount" header="Receivable Amount" filter={true} />
                                </DataTable>

                                : this.NetiContentLoader.MyPointTableLoader()
                            }

                        </div>
                        <div className="col-12   p-xl-12 nw-button-parent">
                            <Button
                                style={{ float: 'right' }}
                                label="Download PDF"
                                className="p-button p-button-primary nw-button nw-button-right"
                                icon="fas fa-arrow-down"
                                onClick={this.exportPdf}
                            />
                        </div>

                    </div>
                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}
