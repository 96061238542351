import React, { Component } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { StudentProfile } from "./StudentProfile";
import { Attendance } from "./Attendance";
import { Subject } from "./Subject";
import { ClassTest } from "./ClassTest";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Exam } from "./Exam";
import { ClassRoutine } from "./ClassRoutine";
import { ExamRoutine } from "./ExamRoutine";
import { Accounts } from "./Accounts";
import { Inventory } from "./Inventory";

import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { Panel } from "primereact/panel";

export class StudentPortalMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userStudentDetails: { ...props },
            basicInfo: {},
            profileRender: true,
            attendanceRender: false,
            subjectRender: false,
            classTestRender: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            examRender: false,
            classRoutineRender: false,
            examRoutineRender: false,
            accountsRender: false,
            inventoryRender: false,
            studentInfo: [],
            menuItems: [
                {
                    label: "Profile",
                    icon: "fas fa-square",
                    className: "menu-item-active",
                    command: (e) => this.goToStudentPortal(e, "profile")
                },
                {
                    label: "Attendance",
                    icon: "fas fa-square",
                    className: "",
                    command: (e) => this.goToStudentPortal(e, "attendance")
                },
                {
                    label: "Subject",
                    icon: "fas fa-square",
                    className: "",
                    command: (e) => this.goToStudentPortal(e, "subject")
                },
                {
                    label: "Class Test",
                    icon: "fas fa-square",
                    className: "",
                    command: (e) => this.goToStudentPortal(e, "classTest")
                },
                {
                    label: "Exam",
                    icon: "fas fa-square",
                    className: "",
                    command: (e) => this.goToStudentPortal(e, "exam")
                },
                {
                    label: "Class Routine",
                    icon: "fas fa-square",
                    className: "",
                    command: (e) => this.goToStudentPortal(e, "classRoutine")
                },
                {
                    label: "Exam Routine",
                    icon: "fas fa-square",
                    className: "",
                    command: (e) => this.goToStudentPortal(e, "examRoutine")
                },
                {
                    label: "Accounts",
                    icon: "fas fa-square",
                    className: "",
                    command: (e) => this.goToStudentPortal(e, "accounts")
                },
                {
                    label: "Inventory",
                    icon: "fas fa-square",
                    className: "",
                    command: (e) => this.goToStudentPortal(e, "inventory")
                }
            ]
        };

        this.studentPortal = new StudentPortal();
    }

    componentWillMount() {

        if (this.props.location.userStudentDetails) {
            let studentId;

            if (this.props.location.userStudentDetails.idForGuardian === null) {
                studentId = this.props.location.userStudentDetails.idForStudent;
            } else if (this.props.location.userStudentDetails.idForStudent === null) {
                studentId = this.props.location.userStudentDetails.idForGuardian;
            }
            let studentProps = {
                instituteId: this.props.location.userStudentDetails.emDetailsInfoDTO.instituteId,
                studentId: studentId
            };
            localStorage.setItem("studentProps", JSON.stringify(studentProps));
        }
        this.loadStudentInfo();
    }

    loadStudentInfo = () => {
        const studentProps = JSON.parse(localStorage.getItem("studentProps"));
        if (!studentProps) {
            return;
        }
        this.setState({ topProgressBar: true, errorMsgVisible: false })
        this.studentPortal
            .fetchStudentProfileDetailsList(
                studentProps.instituteId,
                studentProps.studentId
            )
            .then(res => {
                if (res.status == 200) {
                    return res.json().then(body => {
                        this.setState({ topProgressBar: false, errorMsgVisible: false })
                        this.setState({ basicInfo: body });
                    });
                } else {
                    this.studentPortal.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            })
            .catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));
    };

    async goToStudentPortal(e, labelStatus) {

        let menuItems = this.state.menuItems;
        menuItems.map(item => { item.className = "" });
        this.setState({ menuItems });

        e.item.className = "menu-item-active";


        {
            labelStatus === "profile"
                ? this.setState({ profileRender: true })
                : this.setState({ profileRender: false });
        }
        {
            labelStatus === "attendance"
                ? this.setState({ attendanceRender: true })
                : this.setState({ attendanceRender: false });
        }
        {
            labelStatus === "subject"
                ? this.setState({ subjectRender: true })
                : this.setState({ subjectRender: false });
        }
        {
            labelStatus === "classTest"
                ? this.setState({ classTestRender: true })
                : this.setState({ classTestRender: false });
        }
        {
            labelStatus === "exam"
                ? this.setState({ examRender: true })
                : this.setState({ examRender: false });
        }
        {
            labelStatus === "classRoutine"
                ? this.setState({ classRoutineRender: true })
                : this.setState({ classRoutineRender: false });
        }
        {
            labelStatus === "examRoutine"
                ? this.setState({ examRoutineRender: true })
                : this.setState({ examRoutineRender: false });
        }
        {
            labelStatus === "accounts"
                ? this.setState({ accountsRender: true })
                : this.setState({ accountsRender: false });
        }
        {
            labelStatus === "inventory"
                ? this.setState({ inventoryRender: true })
                : this.setState({ inventoryRender: false });
        }
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        const { basicInfo } = this.state;
        // let staticImg = 'assets/layout/images/userAvatar.png';
        // let stdName, stdCustomId, stdImage, instituteID;
        // if (basicInfo) {
        //     stdName = basicInfo.studentName;
        //     stdCustomId = basicInfo.customStudentId;
        //     instituteID = basicInfo.instituteId;
        //     stdImage = this.state.basicInfo.studentImageForView ? "data:image/jpeg;base64," + this.state.basicInfo.studentImageForView : null;
        // }

        // if (this.state.profileRender) {
        //     this.state.menuItems[0].className = "menu-item-active";
        // }

        return (
            <div className="main-section">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-col-12 p-lg-12 p-xl-12" style={{ position: "relative"}}>

                    <div className="portal-menu">
                        <Menu
                            model={this.state.menuItems}
                            popup={true}
                            ref={el => (this.menu = el)}
                            appendTo={document.body}
                            className="portal-menu-component"
                        />
                        <Button
                            icon="fas fa-align-right"
                            onClick={event => this.menu.toggle(event)}
                            //title="Menu"
                            className="portal-menu-button"
                            tooltip="View Menu"
                        />
                    </div>

                    {/* <Panel> */}
                    {/* <div className="p-grid pull-right"> */}

                    {/* <div className="p-col-12 p-lg-10 p-xl-10">
                                <div className="layout-profile-menu">
                                    <center>
                                        <img
                                            src={stdImage || staticImg}
                                            alt="Institute Image"
                                            width="100px"
                                        />
                                    </center>
                                    <h1 className="text-center" style={{ paddingLeft: "10px", fontStyle: "bold", fontSize: "1.5rem" }}>{stdName}</h1>
                                    <h1 className="text-center" style={{ paddingLeft: "10px", fontStyle: "bold", fontSize: "1.0rem" }}>{stdCustomId ? "Custom ID: " + stdCustomId : ''}</h1>
                                    <h1 className="text-center" style={{ paddingLeft: "10px", fontStyle: "bold", fontSize: "1.0rem" }}>{instituteID ? "Institute ID: " + instituteID : ''}</h1>

                                </div>

                            </div> */}



                    {/* </div> */}
                    {/* </Panel> */}

                    {basicInfo ? (
                        <div className="p-grid">
                            {this.state.profileRender ? (
                                <StudentProfile basicInfo={basicInfo} />
                            ) : (
                                    ""
                                )}
                            {this.state.attendanceRender ? (
                                <Attendance basicInfo={basicInfo} />
                            ) : (
                                    ""
                                )}
                            {this.state.subjectRender ? (
                                <Subject basicInfo={basicInfo} />
                            ) : (
                                    ""
                                )}
                            {this.state.classTestRender ? (
                                <ClassTest basicInfo={basicInfo} />
                            ) : (
                                    ""
                                )}
                            {this.state.examRender ? (
                                <Exam basicInfo={basicInfo} />
                            ) : (
                                    ""
                                )}
                            {this.state.classRoutineRender ? (
                                <ClassRoutine basicInfo={basicInfo} />
                            ) : (
                                    ""
                                )}
                            {this.state.examRoutineRender ? (
                                <ExamRoutine basicInfo={basicInfo} />
                            ) : (
                                    ""
                                )}
                            {this.state.accountsRender ? (
                                <Accounts basicInfo={basicInfo} />
                            ) : (
                                    ""
                                )}
                            {this.state.inventoryRender ? (
                                <Inventory basicInfo={basicInfo} />
                            ) : (
                                    ""
                                )}
                        </div>
                    ) : (
                            ""
                        )}
                </div>
            </div>
        );
    }
}
