import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export class ApplicantPrevExamInfo extends Component {

    render() {

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Previous Exam Info</div>
            <div className="header-title-right">
                <a>Total Found: {this.props.applicantPrevExamInfo && this.props.applicantPrevExamInfo.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>;

        return (

            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">

                            <DataTable
                                header={tableHeader}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                value={this.props.applicantPrevExamInfo}
                            >
                                <Column field="instituteName" header="Institute Name" filter={true} />
                                <Column field="instituteType" header="Institute Type" filter={true} />
                                <Column field="boardName" header="Board" filter={true} />
                                <Column field="className" header="Class" filter={true} />
                                <Column field="rollNo" header="Roll No." filter={true} />
                                <Column field="registrationNo" header="Reg. No." filter={true} />
                                <Column field="examName" header="Exam" filter={true} />
                                <Column field="examGrade" header="Grade" filter={true} />
                                <Column field="examGpa" header="GPA" filter={true} />
                                <Column field="passingYear" header="Passing Year" filter={true} />
                            </DataTable>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}