import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService'

export class SaSummaryService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder(); 
        this.Auth = new AuthService();
    }

//*******************Student Info summary *********************/

    getGenderWiseStudentInfo() {

        let uri = this.baseURIHolder.getUser() + '/sa/count/institute-wise/student/gender-info';

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    return response
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }



    getReligionWiseStudentInfo() {

        let uri = this.baseURIHolder.getUser() + '/sa/count/institute-wise/student/religion-info';

        return this.Auth.getFetch(uri)
            .then(function (response) {
                console.log("response", response);
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    return response
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }


    //*******************Staff Info summary *********************/



    getGenderWiseStaffInfo() {

        let uri = this.baseURIHolder.getUser() + '/sa/count/institute-wise/staff/gender-info';

        return this.Auth.getFetch(uri)
            .then(function (response) {
                console.log("response", response);
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    return response
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }



    getReligionWiseStaffInfo() {

        let uri = this.baseURIHolder.getUser() + '/sa/count/institute-wise/staff/religion-info';

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    return response
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }


   


     //*******************Attendance Summary Info *********************/


     getStudentAttendanceSummaryInfo(date,academicYear,periodDefaultId) {

        let uri = this.baseURIHolder.getUser() + '/sa/count/institute-wise/student/attendance-info?date=' + date +'&academicYear='+ academicYear +'&periodDefaultId='+periodDefaultId;

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200 || response === 'undefined') {
                    return response.json()
                } else {
                    return response
                    // console.log('No date found this Redirect Api ', uri);
                    // return null;
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }




     getStaffAttendanceSummaryInfo(date) {

        let uri = this.baseURIHolder.getUser() + '/sa/count/institute-wise/staff/attendance-info?date=' + date;

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200 || response === 'undefined') {
                    return response.json()
                } else {
                    return response
                    // console.log('No date found this Redirect Api ', uri);
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }



    // getGlobalInfoByTypeId() {

    //     let uri = this.baseURIHolder.getUser()+'/sa/find/global-info/list/by/type-id?typeId=2301';

    //     return this.Auth.getFetch(uri)
    //         .then(function (response) {
    //             if (response.status === 302 || response.status === 200) {
    //                 return response.json()
    //             } else {
    //                 console.log('No date found this Redirect Api ', uri);
    //             }
    //         })
    //         .catch(error => console.log('responseErrorData:::' + error));
    // }



    

    // getGenderWiseStudentInfo(instituteIds) {
    //     return axios.get(this.baseURIHolder.getEmBaseUrl()+'/nw/sa-point/find/all-institute/gender-info/for/student?instituteIds='+instituteIds+'&access_token='+this.baseURIHolder.getEmToken())
    //             .then(res => res);
    // }


    // getReligionWiseStudentInfo(instituteIds) {
    //     return axios.get(this.baseURIHolder.getEmBaseUrl() + '/nw/sa-point/find/all-institute/religion-info/for/student?instituteIds=' + instituteIds + '&access_token=' + this.baseURIHolder.getEmToken())
    //         .then(res => res);
    // }


    //***********************Staff Info ****************************/

    // getGenderWiseStaffInfo(instituteIds) {
    //     return axios.get(this.baseURIHolder.getEmBaseUrl() + '/nw/sa-point/find/all-institute/gender-info/for/hr?instituteIds=' + instituteIds + '&access_token=' + this.baseURIHolder.getEmToken())
    //         .then(res => res);
    // }


    // getReligionWiseStaffInfo(instituteIds) {
    //     return axios.get(this.baseURIHolder.getEmBaseUrl() + '/nw/sa-point/find/all-institute/religion-info/for/hr?instituteIds=' + instituteIds + '&access_token=' + this.baseURIHolder.getEmToken())
    //         .then(res => res);
    // }


    //***********************Attendance Info ****************************/

    // getStudentAttendanceSummaryInfo(date, instituteIds, academicYear) {
    //     return axios.get(this.baseURIHolder.getEmBaseUrl() + '/nw/sa-point/student/attendance-info?date=' + date + '&academicYear=' + academicYear + ' &instituteIds=' + instituteIds + '&access_token=' + this.baseURIHolder.getEmToken())
    //         .then(res => res);
    // }


    // getStaffAttendanceSummaryInfo(date, instituteIds) {
    //     return axios.get(this.baseURIHolder.getEmBaseUrl() + '/nw/sa-point/staff/attendance-info?date=' + date + ' &instituteIds=' + instituteIds + '&access_token=' + this.baseURIHolder.getEmToken())
    //         .then(res => res);
    // }





    // ++++++++++++++++++++++Api Consume derict eduman +++++++++++++++++++++++++++++++









} 