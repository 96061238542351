import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import CommonFuctionality from '../common/CommonFuctionality';
import { ValidatorUtility } from '../../utils/ValidatorUtility';

let errors = {};
let totalBillableAmount = 0;
let totalPaidAmount = 0;
let totalDiscountAmount = 0;
let totalDueAmount = 0;
export class EmBillDetailsLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            dataTableIsLoading: false,
            discountNoteView: false,
            topProgressBar: false,
            discountNote: '',
            billDetailsLogDialog: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            emBillDetailsLogList: [],
        }
        this.CommonFuctionality = new CommonFuctionality();
        this.CRMPointService = new CRMPointService();
        this.validatorUtility = new ValidatorUtility();
    }



    getEmBillDetailsLog = (param) => {
        this.setState({ topProgressBar: true, errorMsgBody: '', errorMsgVisible: false });
        this.CRMPointService.getEmBillDetailsByEmdetailsID(param)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        totalBillableAmount = body.map(item => item.billableAmount).reduce((a, b) => (a + b));
                        totalPaidAmount = body.map(item => item.paidAmount).reduce((a, b) => (a + b));
                        totalDiscountAmount = body.map(item => item.discountAmount).reduce((a, b) => (a + b));
                        totalDueAmount = body.map(item => item.dueAmount).reduce((a, b) => (a + b));
                        this.setState({emBillDetailsLogList: body});
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection.", topProgressBar: false })
            });
    }

    billDetailsLogDialog = (param) => {
        this.setState({ billDetailsLogDialog: true });
        this.getEmBillDetailsLog(param);
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    billDetailsLogDialogHide = () => {
        this.setState({ billDetailsLogDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    billInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo">
                <div>
                    <div>Year</div>
                    <div>:</div>
                    <div>{rowData.billGenerateLogDTO.billYear}</div>
                </div>

                <div>
                    <div>Month</div>
                    <div>:</div>
                    <div>{rowData.billGenerateLogDTO.billMonth}</div>
                </div>
                <div>
                    <div>Monthly Rate</div>
                    <div>:</div>
                    <div>{rowData.billGenerateLogDTO.ratePerStudent}</div>
                </div>
            </div>
        );
    }
    studentInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo admin-point">
             <div>
                    <div>Academic Year</div>
                    <div>:</div>
                    <div>{rowData.billGenerateLogDTO.academicYear}</div>
                </div>
                <div>
                    <div>Billable Student</div>
                    <div>:</div>
                    <div>{rowData.billGenerateLogDTO.billableStudent}</div>
                </div>

                <div>
                    <div>Disable Student</div>
                    <div>:</div>
                    <div>{rowData.billGenerateLogDTO.disableStudent}</div>
                </div>
            </div>
        );
    }

    billAmountInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo admin-point">
             <div>
                    <div>Billable</div>
                    <div>:</div>
                    <div>{rowData.billableAmount}</div>
                </div>
                <div>
                    <div>Paid</div>
                    <div>:</div>
                    <div>{rowData.paidAmount}</div>
                </div>

                <div>
                    <div>Discount</div>
                    <div>:</div>
                    <div>{rowData.discountAmount}</div>
                </div>
            </div>
        );
    }

  

    dueAmount = (rowData) => {
        return (
            <div className="nw-datatable-dataview-small-panel priceInfo">
                <div>
                    <div>DUE</div>
                </div>
                <h2> <span className="symbol-taka">৳ </span>{this.validatorUtility.currencyFormatter(rowData.dueAmount)}</h2>
            </div>
        );
    }



    render() {
        console.log("totalBillableAmount",totalBillableAmount)
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let detailsTableHeader = <div className="header-title">
        <div className="header-title-left">Bill Log Details</div>

        <div className="header-title-right">
            <Button
                className="nw-button nw-button-center p-button-success"
                name="district"
                label="Download"
                icon="fas fa-plus"
                iconPos="right"
            />
        </div>
    </div>

        return (
<>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                    <Growl ref={(el) => this.growl = el} />
                   
                    <Dialog header="Bill Details" style={{width: '70vw'}} visible={this.state.billDetailsLogDialog} onHide={this.billDetailsLogDialogHide} className="nw-dialog product-info-dialog admin-point">
                        <br />
                        <div className="p-fluid">
                            <div class="p-col-12">
                                <div className="custom-header no-border blue-text">
                                    <i className="fa fa-wallet"></i>
                                    <h1>Total Amount Summary </h1>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="calculation-box-wrapper">
                                    <div className="p-col-12 p-md-6 p-lg-6 p-xl-3">
                                        <div className="calculation-total-box">
                                            <div className="calculation-box-inner-wrapper">
                                                <div className="calculation-box-title"><span className="p-inputgroup-addon">Billable Amount</span></div>
                                                <div className="calculation-box-amount">{this.validatorUtility.currencyFormatter(totalBillableAmount)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6 p-lg-6 p-xl-3">
                                        <div className="calculation-total-box">
                                            <div className="calculation-box-inner-wrapper">
                                                <div className="calculation-box-title"><span className="p-inputgroup-addon">Paid Amount</span></div>
                                                <div className="calculation-box-amount">{this.validatorUtility.currencyFormatter(totalPaidAmount)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6 p-lg-6 p-xl-3">
                                        <div className="calculation-total-box">
                                            <div className="calculation-box-inner-wrapper">
                                                <div className="calculation-box-title"><span className="p-inputgroup-addon">Discount Amount</span></div>
                                                <div className="calculation-box-amount">{this.validatorUtility.currencyFormatter(totalDiscountAmount)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6 p-lg-6 p-xl-3">
                                        <div className="calculation-total-box">
                                            <div className="calculation-box-inner-wrapper">
                                                <div className="calculation-box-title"><span className="p-inputgroup-addon">Due Amount</span></div>
                                                <div className="calculation-box-amount">{this.validatorUtility.currencyFormatter(totalDueAmount)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-col-12 p-xl-12 nw-datatable-detail-view product-list-datatable-detail-view">
                                    <DataTable
                                        header={detailsTableHeader}
                                        selectionMode="single"
                                        responsive={true}
                                        value={this.state.emBillDetailsLogList}
                                        globalFilter={this.state.globalFilter}
                                        paginator={true}
                                        rows={5}
                                    >
                                        <Column field="billInfo" header="Bill Info" body={this.billInfo} />
                                        <Column field="studentInfo" header="Student Info" body={this.studentInfo} />
                                        <Column field="billAmount" header="Bill Amount Info" body={this.billAmountInfo}/>
                                        <Column field="dueAmount" header="Due Amount" body={this.dueAmount}/>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                

</>
        )
    }
}