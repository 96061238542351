import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';

export class BijoyPointService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    bijoyDeviceUnlock(userInfo) {
        let uri = this.baseURIHolder.getBijoy() + '/password/new';
        return this.Auth.postFetch(uri, userInfo)
        .catch((error) => console.log("error",error));
    }

    getBijoyDeviceInfo(purchaseCode) {
        let uri = this.baseURIHolder.getBijoy() + '/password/by/purchasecode?code='+purchaseCode;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log("error",error));
    }

}