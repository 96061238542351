import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DownloadCornerUpdate } from './DownloadCornerUpdate';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import { CUSTOM_MESSAGE } from '../../common/CustomMessage';

import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';

let urlId;
let cloneStateBeforeMount;
export class DownloadCorner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogLoad: false,
            visible: false,
            uploadFile: {
                extention: '',
                contentFile: '',
                contentName: ''
            },
            rowInformation: {
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            downloadSubmitError: [],
            downloadCornerInfo: {
                fileTitle: '',
                fileSerial: '',
                fileName: '',
                filePath: '',
                fileEnableStatus: '',
                fileContent: '',
                fileSaveOrEditable: '',
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            listFetch: {
                coreUrlInfoID: '',
                pageNo: 0,
                limit: 10
            },
            checkEligibility: true,
            errorMsgVisible: false,
            errorMsgBody: '',
            downloadList: [],
            dataTableIsLoading: false,
            totalDataFound: 0,
            rowLimit: 10
        }
        this.DwsService = new DwsService();
        this.netiFileHandler = new NetiFileHandler();
        this.reloadCoreUrlIdFromLocal();
        this.NetiContentLoader = new NetiContentLoader();
        this.commonFuctionality = new CommonFuctionality();

    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {

        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            let { downloadCornerInfo, listFetch } = this.state;
            downloadCornerInfo.coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ downloadCornerInfo });
            listFetch.coreUrlInfoID = urlId;
            this.setState({ listFetch });
            this.viewDownloadFileList();
            this.setState({ homeReturnButton: false });
        }
        else {
            this.setState({ homeReturnButton: true })
        }
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    viewDownloadFileList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        let { listFetch } = this.state;
        this.DwsService.getTopListofDownloadFile(listFetch)
            .then(res => {
                if (res.status === 201) {
                    return res.json().then((body) => {
                        this.setState({ downloadList: body })
                        this.setState({ totalDataFound: body.length });
                        // this.countTableSize(body.length);
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    })
                }
                else {
                    this.DwsService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody });
                    });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            })
    }

    viewDownloadData = (sourch) => {
        let { rowInformation } = this.state;
        rowInformation.fileCreateDate = sourch.fileCreateDate;
        rowInformation.fileTitle = sourch.fileTitle;
        rowInformation.fileSerial = sourch.fileSerial;
        rowInformation.fileName = sourch.fileName;
        rowInformation.fileEnableStatus = sourch.fileEnableStatus;
        rowInformation.fileContent = sourch.fileContent;
        rowInformation.fileSaveOrEditable = sourch.fileSaveOrEditable;
        rowInformation.fileID = sourch.fileID;
        rowInformation.filePath = sourch.filePath;
        rowInformation.coreUrlInfoDTO.urlInfoID = urlId;

        this.setState({ rowInformation });
        this.setState({ visible: true });
        this.setState({ dialogLoad: true });
    }

    turnOffDialog = () => {
        this.setState({ dialogLoad: false });
    }

    downloadCornerHideDialog = () => {
        this.setState({ visible: false })
    }

    actionTemplate = (rowData) => {

        let deleteBtn = ''
        let btnIcon= ''
        let tooltipMsg=''
        if(rowData.fileEnableStatus === 1){
            deleteBtn = "p-button-success";
            btnIcon= "fas fa-check"
            tooltipMsg="Click to Inactive"
        }
        else if(rowData.fileEnableStatus === 0){
            deleteBtn = "p-button-danger";
            btnIcon= "fas fa-minus-circle"
            tooltipMsg='Click to Active'
        }
        
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                style={{ marginRight: '.5em', 'fontSize': '1em' }}
                onClick={(e) => this.viewDownloadData(rowData)}
                tooltip="Edit"
            />
            <Button
                type="button"
                icon={btnIcon}
                className={deleteBtn + " nw-action-button"}
                onClick={(e) => this.deleteFileRowData(rowData)}
                tooltip={tooltipMsg}
            />
        </div>;


        // return <div className="text-center">
        //     <Button
        //         type="button"
        //         icon="fas fa-pencil-alt"
        //         className="p-button"
        //         style={{ marginRight: '.5em', 'fontSize': '1em' }}
        //         onClick={(e) => this.viewDownloadData(sourch)}
        //     >
        //     </Button>
        //     <Button
        //         type="button"
        //         icon="fas fa-times"
        //         className="p-button-danger"
        //         onClick={(e) => this.deleteFileRowData(sourch)}
        //     >
        //     </Button>
        // </div>;
    }

    deleteFileRowData = (rowData) => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.DwsService.deleteDownloadableFile(rowData.fileID)
            .then(res => {
                if (res.status === 202) {
                    this.growl.show({ severity: 'success', summary: CUSTOM_MESSAGE.SUCCESS_MSG_SHORT, detail: CUSTOM_MESSAGE.SUCCESS_MSG_LONG_UPDATE })
                    this.setState({ topProgressBar: false, errorMsgVisible: false });

                    setTimeout(() => {
                        this.viewDownloadFileList();
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    }, 1000);
                    
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: CUSTOM_MESSAGE.ERROR_CONNECTION_MSG })
            );

    }

    viewDialog = (rowData) => {
        this.setState({ visible: true });
    }

    serialNumberHandler = (event, props) => {
        this.setState({ totalDataFound: this.state.totalData })
        this.setState({ downloadSubmitError: [] })
    }

    titleHandler = (event) => {
        let { downloadCornerInfo } = this.state;
        downloadCornerInfo.fileTitle = event.target.value;
        this.setState({ downloadCornerInfo, downloadSubmitError: [] })
    }

    onLoadFile(e) {
        var reader = new FileReader();
        let upFile = e.files[0];

        console.log("e", e);
        console.log("FILE SIZE", upFile);
        
        const scope = this
        reader.readAsDataURL(upFile);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + upFile.type + ';base64,';
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: upFile.type,
                contentFile: urlStr,
                contentName: upFile.name
            };
            scope.setState({ uploadFile: album, downloadSubmitError: [] });

        }
    }

    countTableSize() {
        const { dataTableValue } = this.state;
        return dataTableValue.length;
    }

    fileSubmitHandler = () => {

        let { downloadCornerInfo, totalDataFound } = this.state;

        if (this.errorHandler()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (this.state.uploadFile.contentFile != null) {
                downloadCornerInfo.fileContent = this.state.uploadFile.contentFile;
                downloadCornerInfo.fileSaveOrEditable = true;
                let extention = this.netiFileHandler.getImageExtention(this.state.uploadFile.extention);
                // downloadCornerInfo.fileName = 'download_file_' + urlId + '_' + Date.now() + extention;
                downloadCornerInfo.fileName = this.state.uploadFile.contentName;   
            }

            downloadCornerInfo.fileSerial = totalDataFound + 1;

            this.setState({ downloadCornerInfo });
            this.DwsService.createNewDownloadFile(downloadCornerInfo)
                .then(res => {
                    if (res.status === 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully submitted' })

                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                        this.viewDownloadFileList();
                        let { downloadCornerInfo } = this.state
                        downloadCornerInfo.fileTitle = downloadCornerInfo.fileSerial = downloadCornerInfo.fileName = downloadCornerInfo.filePath = downloadCornerInfo.fileEnableStatus = downloadCornerInfo.fileContent = downloadCornerInfo.fileSaveOrEditable = ''
                        this.setState({
                            downloadCornerInfo,
                            uploadFile: cloneStateBeforeMount.uploadFile
                        });
                    } else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })
                );
        }
    }

    errorHandler() {
        let { downloadCornerInfo, downloadSubmitError } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });

        if (downloadCornerInfo.fileTitle === '') {
            formIsValid = false;
            downloadSubmitError["fileTitle"] = "Title can't left empty";
        }
        if (this.state.uploadFile.contentName === '') {
            formIsValid = false;
            downloadSubmitError["fileContent"] = "File can't left empty";
        }
        this.setState({ downloadSubmitError });
        return formIsValid;
    }

    render() {
        let { downloadList, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let uploadFileLabel = <div>Click / Drag File Here To Upload <br /><span className="upload-file-label">1 MB (recommended)</span></div>

        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                                Download File List
                                    </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: { downloadList.length }
                                </a>
                            </div>
                        </div>;

        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }


                <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                {this.state.homeReturnButton === false ?
                    <div>
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Serial No.</span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.totalDataFound + 1}
                                            </div>
                                        </div>
                                        <span className="error-message">{this.state.downloadSubmitError.fileSerial}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Title <span>*</span></span>
                                            <InputText
                                                id="fileTitle"
                                                onChange={this.titleHandler}
                                                name="fileTitle"
                                                placeholder="Enter Download File Title"
                                                value={this.state.downloadCornerInfo.fileTitle}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.downloadSubmitError.fileTitle}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload File <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/DOC/PDF)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                <div className="image-view-main">
                                                    {this.state.uploadFile.contentName ? 
                                                        <div className="upload-image-view">
                                                            <Button
                                                                className="delete-upload-button"
                                                                icon="fas fa-times-circle"
                                                                onClick={e => this.setState({ uploadFile: { contentPic: '' } })}
                                                            />
                                                            
                                                                        {this.commonFuctionality.getFileContentTypeIcon(this.state.uploadFile.contentName)}
                                                            
                                                            {/* <DownloadButton fileName={this.props.downloadData.fileName} filePath={this.props.downloadData.filePath} /> */}
                                                        </div> : ''
                                                    }
                                                </div>
                                                <FileUpload
                                                    chooseLabel={ this.state.uploadFile.contentName || uploadFileLabel }
                                                    mode="basic"
                                                    maxFileSize={1000000}
                                                    onSelect={this.onLoadFile.bind(this)}
                                                    auto={true}
                                                />
                                            </div>
                                        </div>
                                        {/* <span className="error-message">{this.state.downloadSubmitError.fileContent}</span> */}
                                        {/* <center>{this.state.downloadSubmitError["fileContent"] !== '' && this.state.uploadFile.contentName}</center> */}

                                        <span className="error-message">{this.state.downloadSubmitError.fileContent}</span>
                                    </div>

                                    {/* <div className="p-col-12 p-xl-12 nw-upload-button">
                                        <FileUpload
                                            mode="basic"
                                            maxFileSize={1000000}
                                            onSelect={this.onLoadFile.bind(this)}
                                            auto={true}
                                            chooseLabel="Upload File"
                                        />
                                        <center>{this.state.downloadSubmitError["fileContent"] !== '' && this.state.uploadFile.contentName}</center>

                                        <center><span className="error-message">{this.state.downloadSubmitError.fileContent}</span></center>

                                    </div> */}

                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            name="district"
                                            label="Save"
                                            icon="fas fa-check"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            onClick={(e) => this.fileSubmitHandler(e, this.props)}
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table">
                                    {this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <DataTable
                                            value={this.state.downloadList}
                                            header={ tableHeader }
                                            responsive={true}
                                            selectionMode="single"
                                            paginator={true}
                                            rows={10}
                                            rowsPerPageOptions={[5, 10, 20]}
                                            // selection={this.state.dataTableSelection}
                                            // onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            countTableSize={(l) => this.setState({ totalDataFound: l })}
                                        // first={this.state.rowLimit}
                                        // onPage={(e) => {
                                        //     console.log('Page limit:', e);
                                        //     this.setState({ rowLimit: e.first })
                                        // }}
                                        >
                                            <Column field="fileSerial" header="Serial No." filter={true} style={{ width: '100px' }}/>
                                            <Column field="fileTitle" header="Title" filter={true} />
                                            <Column field="className" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '129px' }}/>
                                        </DataTable>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" alt="dws_home_pic" />
                            </div>
                        </div>
                    </div>
                }
                {this.state.dialogLoad === true ?
                    <Dialog className="nw-dialog" maximizable header="Update Download Corner Information" visible={this.state.visible} modal={true} onHide={this.downloadCornerHideDialog}>
                     
                
                        <DownloadCornerUpdate viewDownloadFileList={this.viewDownloadFileList} turnOffDialog={this.turnOffDialog} downloadData={this.state.rowInformation} />
                    </Dialog>
                    :
                    ''
                }
            </div>
        );
    }
}