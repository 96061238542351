import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Growl } from 'primereact/growl';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { ErrorMessageView } from '../../../common/ErrorMessageView';


export class MpNetiCMSNotConfiguredUrlList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datatableValue: [
                {
                    purchaseDate: "02-12-2019 10:10:00 AM",
                    assignDate: "02-01-2020 10:10:00 AM",
                    customNetiId: "1000001001",
                    name: "Shahrear Kabir",
                    basicMobile: "01675886072",
                    area: "Bogura",
                },
            ],
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
        }

        this.NetiContentLoader = new NetiContentLoader();
    }

    render() {
        let { datatableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let header = <div className="header-title">
            <div className="header-title-left">
                Neti CMS Not Configured URL List
            </div>

            <div className="header-title-right">
                <Link to="#">
                    Total Found: { datatableValue.length }
                </Link>
            </div>
        </div>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {/* {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() : */}
                                <DataTable value={datatableValue} header={header}>
                                    <Column field="purchaseDate" header="Purchase Date-Time" />
                                    <Column field="assignDate" header="Assign Date-Time" />
                                    <Column field="customNetiId" header="Partner Neti ID." />
                                    <Column field="name" header="Partner Name" />
                                    <Column field="basicMobile" header="Mobile No." />
                                    <Column field="area" header="Area" />
                                    {/* <Column field="createdUrl" header="Created URL" body={this.urlBody}/> */}
                                </DataTable>
                            {/* } */}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
