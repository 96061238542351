// pdf related import
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";

import {
  pdfmeta,
  pdfday,
  powerddBy_landscape,
  date_landscape,
  pfont,
  btmLine_landscape,

  powerddBy_portrait,
  date_portrait,
  btmLine_portrait,
  formatToCurrency,
} from "./JsPdfEssentialFile";

// *****start landscape pdf download related all functions****

// without subTitle and footer, if need to download table_only 
export function pdfDownloadLandscape_TableOnly(
  mainTitle,
  mainTitleStartPosition,
  tableColumn,
  tableList,
  tableColStyle,
  downloadTitle
) {
  let doc = new jsPDF("l", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // Footer Contents
  var pageContent = function (data) {
    doc.setFontStyle("normal");
    var str = powerddBy_landscape + data.pageCount;
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp + date_landscape + pdfday;
    }
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(str, data.settings.margin.right, doc.internal.pageSize.getHeight() - 10);
    doc.text(btmLine_landscape, data.settings.margin.right, doc.internal.pageSize.getHeight() - 14.5);
  };
  // Footer Contents end

  doc.setProperties(pdfmeta);
  doc.addFileToVFS("PTC55F.ttf", pfont);
  doc.addFont("PTC55F.ttf", "custom", "normal");
  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("custom");
  doc.setDrawColor(100, 100, 100);
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.setFontType("bold");
  doc.text('Title : ' + mainTitle, mainTitleStartPosition, 20);

  doc.autoTable(tableColumn, tableList, {
    startY: 23,
    theme: "grid",
    pageBreak: "auto",
    headStyles: {
      halign: "center",
      fillColor: [105, 124, 171],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "left",
      overflow: "linebreak",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: tableColStyle,
    didParseCell: function (data, opts) {
      // console.log("pdfData", data, "Opts", opts);
      if (typeof data.cell.raw == "number") {
        data.cell.text = formatToCurrency(data.cell.raw);
      }
    },

    didDrawPage: pageContent,
    margin: { bottom: 15 }
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }
  doc.save(downloadTitle);
}

// without footer, if need to download table with subTitle 
export function pdfDownloadLandscape_TableWithSubTitle(
  mainTitle,
  mainTitleStartPosition,
  subTitleColumn,
  subTitleList,
  tableColumn,
  tableList,
  tdColumnStyle,
  downloadTitle,

) {

  let doc = new jsPDF("l", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // Footer Contents
  var pageContent = function (data) {
    doc.setFontStyle("normal");
    var str = powerddBy_landscape + data.pageCount;
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp + date_landscape + pdfday;
    }
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(str, data.settings.margin.right, doc.internal.pageSize.getHeight() - 10);
    doc.text(btmLine_landscape, data.settings.margin.right, doc.internal.pageSize.getHeight() - 14.5);
  };

  // Footer Contents end

  // title & header Content
  doc.setProperties(pdfmeta);
  doc.addFileToVFS("PTC55F.ttf", pfont);
  doc.addFont("PTC55F.ttf", "custom", "normal");
  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("custom");
  doc.setDrawColor(100, 100, 100);
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.setFontType("bold");
  doc.text('Title : ' + mainTitle, mainTitleStartPosition, 20);

  doc.autoTable(subTitleColumn, subTitleList, {
    startY: 23,
    showHead: "never",
    theme: "grid",
    headStyles: { overflow: "linebreak", halign: "center", fillColor: [105, 124, 171] },
    styles: {
      lineColor: [2, 5, 8],
      textColor: [0, 0, 0],
      fontSize: 9
    },
    columnStyles: {
      A: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      C: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      E: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      G: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
    }
  });

  // title & header Content end

  let first = doc.autoTable.previous;
  doc.autoTable(tableColumn, tableList, {
    startY: first.finalY + 2,
    theme: "grid",
    pageBreak: "auto",
    headStyles: {
      halign: "center",
      fillColor: [105, 124, 171],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "left",
      overflow: "linebreak",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: tdColumnStyle,
    didParseCell: function (data, opts) {

      // console.log("pdfData", data, "Opts", opts);
      if (typeof data.cell.raw == "number") {
        data.cell.text = formatToCurrency(data.cell.raw);
      }

      if (data.section === 'body' && data.column.index === 0 && data.column.dataKey == "fileContent") {
        data.cell.text = ""
        data.cell.styles.fillColor = [240, 240, 240];
        data.cell.styles.minCellHeight = 14;
        data.cell.styles.cellWidth = 18;
      }

    },

    didDrawPage: pageContent,
    didDrawCell: (data) => {

      if (data.section === 'body' && data.column.index === 0 && data.column.dataKey == "fileContent") {

        if (data.row.raw.fileContent != "") {
          var base64Img = 'data:image/jpeg;base64,' + data.row.raw.fileContent;
          doc.addImage(base64Img, 'JPEG', data.cell.x, data.cell.y, 18, 14);
        } else {
          data.cell.text = "";
        }

      }

    },

    margin: { bottom: 18 }
  });

  if (typeof doc.putTotalPages === "function") { doc.putTotalPages(totalPagesExp); }
  doc.save(downloadTitle);

}

// without subTitle, if need to download table with footer
export function pdfDownloadLandscape_TableWithFooter(
  mainTitle,
  mainTitleStartPosition,
  tableColumn,
  tableList,
  tableColStyle,
  footerColumn,
  footerList,
  footerColumnStyle,
  downloadTitle,

) {

  let doc = new jsPDF("l", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // Footer Contents
  var pageContent = function (data) {
    doc.setFontStyle("normal");
    var str = powerddBy_landscape + data.pageCount;
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp + date_landscape + pdfday;
    }
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(str, data.settings.margin.right, doc.internal.pageSize.getHeight() - 10);
    doc.text(btmLine_landscape, data.settings.margin.right, doc.internal.pageSize.getHeight() - 14.5);
  };

  // Footer Contents end

  // title & header Content
  doc.setProperties(pdfmeta);
  doc.addFileToVFS("PTC55F.ttf", pfont);
  doc.addFont("PTC55F.ttf", "custom", "normal");
  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("custom");
  doc.setDrawColor(100, 100, 100);
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.setFontType("bold");
  doc.text('Title : ' + mainTitle, mainTitleStartPosition, 20);

  // title & header Content end

  doc.autoTable(tableColumn, tableList, {
    startY: 23,
    theme: "grid",
    pageBreak: "auto",
    headStyles: {
      halign: "center",
      fillColor: [105, 124, 171],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "left",
      overflow: "linebreak",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: tableColStyle,
    didParseCell: function (data, opts) {
      // console.log("pdfData", data, "Opts", opts);
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    },

    didDrawPage: pageContent,
    margin: { bottom: 15 }
  });

  //for table footer

  let first = doc.autoTable.previous;
  doc.autoTable(footerColumn, footerList, {
    startY: first.finalY + 0,
    showHead: "never",
    theme: "grid",
    styles: {
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillColor: [216, 255, 232],
      fontSize: 10
    },
    columnStyles: footerColumnStyle,
    didParseCell: function (data) {
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    }
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }
  doc.save(downloadTitle);

}

// if need to download table with subTitle and footer both
export function pdfDownloadLandscape_FullTable(
  mainTitle,
  mainTitleStartPosition,
  subTitleColumn,
  subTitleList,
  tableColumn,
  tableList,
  tableColStyle,
  footerColumn,
  footerList,
  footerColumnStyle,
  downloadTitle
) {
  let doc = new jsPDF("l", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // Footer Contents
  var pageContent = function (data) {
    doc.setFontStyle("normal");
    var str = powerddBy_landscape + data.pageCount;
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp + date_landscape + pdfday;
    }
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(str, data.settings.margin.right, doc.internal.pageSize.getHeight() - 10);
    doc.text(btmLine_landscape, data.settings.margin.right, doc.internal.pageSize.getHeight() - 14.5
    );
  };

  // Footer Contents end

  // title & header Content
  doc.setProperties(pdfmeta);
  doc.addFileToVFS("PTC55F.ttf", pfont);
  doc.addFont("PTC55F.ttf", "custom", "normal");
  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("custom");
  doc.setDrawColor(100, 100, 100);
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.setFontType("bold");
  doc.text('Title : ' + mainTitle, mainTitleStartPosition, 20);

  doc.autoTable(subTitleColumn, subTitleList, {
    startY: 23,
    showHead: "never",
    theme: "grid",
    headStyles: { overflow: "linebreak", halign: "center", fillColor: [105, 124, 171] },
    styles: {
      lineColor: [2, 5, 8],
      textColor: [0, 0, 0],
      fontSize: 9
    },
    columnStyles: {
      A: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      C: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      E: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      G: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
    }
  });

  // title & header Content end

  //main table

  let first = doc.autoTable.previous;
  doc.autoTable(tableColumn, tableList, {
    startY: first.finalY + 2,
    theme: "grid",
    pageBreak: "auto",
    headStyles: {
      halign: "center",
      fillColor: [105, 124, 171],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "left",
      overflow: "linebreak",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: tableColStyle,
    didParseCell: function (data, opts) {
      // console.log("pdfData", data, "Opts", opts);
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    },

    didDrawPage: pageContent,
    margin: { bottom: 15 }
  });

  //for total footer

  let second = doc.autoTable.previous;
  doc.autoTable(footerColumn, footerList, {
    startY: second.finalY + 0,
    showHead: "never",
    theme: "grid",
    styles: {
      fontStyle: "bold",
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillColor: [216, 255, 232],
      fontSize: 10
    },
    columnStyles: footerColumnStyle,
    didParseCell: function (data) {
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    }
  });

  if (typeof doc.putTotalPages === "function") { doc.putTotalPages(totalPagesExp); }
  doc.save(downloadTitle);
}

// without footer, if need to download Two table with subTitle 
export function pdfDownloadLandscape_Two_TableWithSubTitle(
  mainTitle,
  mainTitleStartPosition,
  subTitleColumn,
  subTitleList,
  subTitleColStyle,
  tableColumn,
  tableList,
  tableColStyle,
  downloadTitle,
) {

  let doc = new jsPDF("l", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // Footer Contents
  var pageContent = function (data) {
    doc.setFontStyle("normal");
    var str = powerddBy_landscape + data.pageCount;
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp + date_landscape + pdfday;
    }
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(str, data.settings.margin.right, doc.internal.pageSize.getHeight() - 10);
    doc.text(btmLine_landscape, data.settings.margin.right, doc.internal.pageSize.getHeight() - 14.5);
  };

  // Footer Contents end

  // title & header Content
  doc.setProperties(pdfmeta);
  doc.addFileToVFS("PTC55F.ttf", pfont);
  doc.addFont("PTC55F.ttf", "custom", "normal");
  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("custom");
  doc.setDrawColor(100, 100, 100);
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.setFontType("bold");
  doc.text('Title : ' + mainTitle, mainTitleStartPosition, 20);

  doc.autoTable(subTitleColumn, subTitleList, {
    fillColor: [189, 195, 199],
    startY: 23,
    showHead: "firstPage",
    theme: "grid",
    headStyles: {
      halign: "center",
      fillColor: [105, 124, 171],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "left",
      overflow: "linebreak",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: subTitleColStyle,
  });

  // title & header Content end

  let first = doc.autoTable.previous;
  doc.autoTable(tableColumn, tableList, {
    startY: first.finalY + 2,
    theme: "grid",
    pageBreak: "auto",
    headStyles: {
      halign: "center",
      fillColor: [105, 124, 171],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "left",
      overflow: "linebreak",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: tableColStyle,
    didParseCell: function (data, opts) {

      if (typeof data.cell.raw == "number") {
        data.cell.text = formatToCurrency(data.cell.raw);
      }

      if (data.column.dataKey == "status") {

        if (data.row.raw.status == "Active") {
          data.cell.styles.textColor = [0, 128, 0];
        }
        if (data.row.raw.status == "Inactive") {
          data.cell.styles.textColor = [255, 0, 0];
        }

      }

    },

    didDrawPage: pageContent,
    margin: { bottom: 15 }
  });

  if (typeof doc.putTotalPages === "function") { doc.putTotalPages(totalPagesExp); }
  doc.save(downloadTitle);

}

//  *****end of landscape pdf download related all functions****

// *********start of Portrait pdf download related all functions*********

// without subTitle and footer, if need to download table_only 
export function pdfDownloadPortrait_TableOnly(
  mainTitle,
  mainTitleStartPosition,
  tableColumn,
  tableList,
  tableColStyle,
  downloadTitle
) {
  let doc = new jsPDF("p", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // Footer Contents
  var pageContent = function (data) {
    doc.setFontStyle("normal");
    var str = powerddBy_portrait + data.pageCount;
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp + date_portrait + pdfday;
    }
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(str, data.settings.margin.right, doc.internal.pageSize.getHeight() - 10);
    doc.text(btmLine_portrait, data.settings.margin.right, doc.internal.pageSize.getHeight() - 14.5);
  };

  // Footer Contents end

  // title & header Content
  doc.setProperties(pdfmeta);
  doc.addFileToVFS("PTC55F.ttf", pfont);
  doc.addFont("PTC55F.ttf", "custom", "normal");
  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("custom");
  doc.setDrawColor(100, 100, 100);
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.setFontType("bold");
  doc.text('Title : ' + mainTitle, mainTitleStartPosition, 20);

  // title & header Content end

  doc.autoTable(tableColumn, tableList, {
    startY: 23,
    theme: "grid",
    pageBreak: "auto",
    headStyles: {
      halign: "center",
      fillColor: [105, 124, 171],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "left",
      overflow: "linebreak",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: tableColStyle,
    didParseCell: function (data) {
      //   console.log("pdfData", data, "Opts", opts);
      if (typeof data.cell.raw == "number") {
        data.cell.text = formatToCurrency(data.cell.raw);
      }
    },

    didDrawPage: pageContent,
    margin: { bottom: 15 }
  });

  if (typeof doc.putTotalPages === "function") { doc.putTotalPages(totalPagesExp); }
  doc.save(downloadTitle);
}

// without footer, if need to download table with subTitle 
export function pdfDownloadPortrait_TableWithSubTitle(
  mainTitle,
  mainTitleStartPosition,
  subTitleColumn,
  subTitleList,
  tableColumn,
  tableList,
  tableColStyle,
  downloadTitle,
) {

  let doc = new jsPDF("p", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // Footer Contents
  var pageContent = function (data) {
    doc.setFontStyle("normal");
    var str = powerddBy_portrait + data.pageCount;
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp + date_portrait + pdfday;
    }
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(str, data.settings.margin.right, doc.internal.pageSize.getHeight() - 10);
    doc.text(btmLine_portrait, data.settings.margin.right, doc.internal.pageSize.getHeight() - 14.5);
  };

  // Footer Contents end

  // title & header Content
  doc.setProperties(pdfmeta);
  doc.addFileToVFS("PTC55F.ttf", pfont);
  doc.addFont("PTC55F.ttf", "custom", "normal");
  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("custom");
  doc.setDrawColor(100, 100, 100);
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.setFontType("bold");
  doc.text('Title : ' + mainTitle, mainTitleStartPosition, 20);

  doc.autoTable(subTitleColumn, subTitleList, {
    startY: 23,
    showHead: "never",
    theme: "grid",
    headStyles: { overflow: "linebreak", halign: "center", fillColor: [105, 124, 171] },
    styles: {
      lineColor: [2, 5, 8],
      textColor: [0, 0, 0],
      fontSize: 9
    },
    columnStyles: {
      A: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      C: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      E: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      G: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
    }
  });

  // title & header Content end

  let first = doc.autoTable.previous;
  doc.autoTable(tableColumn, tableList, {
    startY: first.finalY + 2,
    theme: "grid",
    pageBreak: "auto",
    headStyles: {
      halign: "center",
      fillColor: [105, 124, 171],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "left",
      overflow: "linebreak",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: tableColStyle,
    didParseCell: function (data) {
      // console.log("pdfData", data);
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    },

    didDrawPage: pageContent,
    margin: { bottom: 15 }
  });

  if (typeof doc.putTotalPages === "function") { doc.putTotalPages(totalPagesExp); }
  doc.save(downloadTitle);

}

// without subTitle, if need to download table with footer
export function pdfDownloadPortrait_TableWithFooter(
  mainTitle,
  mainTitleStartPosition,
  tableColumn,
  tableList,
  tableColStyle,
  footerColumn,
  footerList,
  footerColumnStyle,
  downloadTitle
) {

  let doc = new jsPDF("p", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // Footer Contents
  var pageContent = function (data) {
    doc.setFontStyle("normal");
    var str = powerddBy_portrait + data.pageCount;
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp + date_portrait + pdfday;
    }
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(str, data.settings.margin.right, doc.internal.pageSize.getHeight() - 10);
    doc.text(btmLine_portrait, data.settings.margin.right, doc.internal.pageSize.getHeight() - 14.5);
  };

  // Footer Contents end

  // title & header Content
  doc.setProperties(pdfmeta);
  doc.addFileToVFS("PTC55F.ttf", pfont);
  doc.addFont("PTC55F.ttf", "custom", "normal");
  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("custom");
  doc.setDrawColor(100, 100, 100);
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.setFontType("bold");
  doc.text('Title : ' + mainTitle, mainTitleStartPosition, 20);

  // title & header Content end

  doc.autoTable(tableColumn, tableList, {
    startY: 23,
    theme: "grid",
    pageBreak: "auto",
    headStyles: {
      halign: "center",
      fillColor: [105, 124, 171],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "left",
      overflow: "linebreak",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: tableColStyle,
    didParseCell: function (data) {
      //   console.log("pdfData", data, "Opts", opts);
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    },

    didDrawPage: pageContent,
    margin: { bottom: 15 }
  });

  //for total footer
  let first = doc.autoTable.previous;
  doc.autoTable(footerColumn, footerList, {
    startY: first.finalY + 2,
    showHead: "never",
    theme: "grid",
    styles: {
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillColor: [216, 255, 232],
      fontSize: 10
    },
    columnStyles: footerColumnStyle,
    didParseCell: function (data) {
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    }
  });

  if (typeof doc.putTotalPages === "function") { doc.putTotalPages(totalPagesExp); }
  doc.save(downloadTitle);

}

// if need to download table with subTitle and footer both
export function pdfDownloadPortrait_FullTable(
  mainTitle,
  mainTitleStartPosition,
  subTitleColumn,
  subTitleList,
  tableColumn,
  tableList,
  tableColStyle,
  footerColumn,
  footerList,
  footerColumnStyle,
  downloadTitle
) {

  let doc = new jsPDF("p", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // Footer Contents
  var pageContent = function (data) {
    doc.setFontStyle("normal");
    var str = powerddBy_portrait + data.pageCount;
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp + date_portrait + pdfday;
    }
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(str, data.settings.margin.right, doc.internal.pageSize.getHeight() - 10);
    doc.text(btmLine_portrait, data.settings.margin.right, doc.internal.pageSize.getHeight() - 14.5);
  };

  // Footer Contents end

  // title & header Content
  doc.setProperties(pdfmeta);
  doc.addFileToVFS("PTC55F.ttf", pfont);
  doc.addFont("PTC55F.ttf", "custom", "normal");
  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("custom");
  doc.setDrawColor(100, 100, 100);
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.setFontType("bold");
  doc.text('Title : ' + mainTitle, mainTitleStartPosition, 20);

  doc.autoTable(subTitleColumn, subTitleList, {
    startY: 23,
    showHead: "never",
    theme: "grid",
    headStyles: { overflow: "linebreak", halign: "center", fillColor: [105, 124, 171] },
    styles: {
      lineColor: [2, 5, 8],
      textColor: [0, 0, 0],
      fontSize: 9
    },
    columnStyles: {
      A: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      C: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      E: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
      G: { fontStyle: "bold", fillColor: [236, 246, 255], textColor: [10, 70, 117], },
    }
  });

  // title & header Content end

  let first = doc.autoTable.previous;
  doc.autoTable(tableColumn, tableList, {
    startY: first.finalY + 2,
    theme: "grid",
    pageBreak: "auto",
    headStyles: {
      halign: "center",
      fillColor: [105, 124, 171],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "left",
      overflow: "linebreak",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: tableColStyle,
    didParseCell: function (data) {
      //   console.log("pdfData", data, "Opts", opts);
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    },

    didDrawPage: pageContent,
    margin: { bottom: 15 }
  });

  //for total footer

  let second = doc.autoTable.previous;
  doc.autoTable(footerColumn, footerList, {
    startY: second.finalY + 2,
    showHead: "never",
    theme: "grid",
    styles: {
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillColor: [216, 255, 232],
      fontSize: 10
    },
    columnStyles: footerColumnStyle,
    didParseCell: function (data) {
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    }
  });

  if (typeof doc.putTotalPages === "function") { doc.putTotalPages(totalPagesExp); }
  doc.save(downloadTitle);
}

// if need to download 2 table with subTitle and footer both
export function pdfDownloadPortrait_Full_Two_Table(
  mainTitle,
  firstTableColumn,
  firstTableList,
  secondTableColumn,
  secondTableList,
  footerColumn,
  footerList,
  footerColumnStyle,
  downloadTitle
) {

  let doc = new jsPDF("p", "mm", "a4");
  var totalPagesExp = "{total_pages_count_string}";

  // Footer Contents
  var pageContent = function (data) {
    doc.setFontStyle("normal");
    var str = powerddBy_portrait + data.pageCount;
    if (typeof doc.putTotalPages === "function") {
      str = str + " of " + totalPagesExp + date_portrait + pdfday;
    }
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(str, data.settings.margin.right, doc.internal.pageSize.getHeight() - 10);
    doc.text(btmLine_portrait, data.settings.margin.right, doc.internal.pageSize.getHeight() - 14.5);
  };

  // Footer Contents end

  // title & header Content
  doc.setProperties(pdfmeta);
  doc.addFileToVFS("PTC55F.ttf", pfont);
  doc.addFont("PTC55F.ttf", "custom", "normal");
  doc.setFontSize(15);
  doc.setTextColor(100);
  doc.setFont("custom");
  doc.setDrawColor(100, 100, 100);
  doc.setFont("helvetica");
  doc.setFontSize(12);
  doc.setFontType("bold");
  doc.text(mainTitle, 77, 35);
  doc.text("__________________________________________", 108, 36, { align: "center" });

  doc.autoTable(firstTableColumn, firstTableList, {
    fillColor: [189, 195, 199],
    startY: 43,
    showHead: "firstPage",
    theme: "grid",
    headStyles: {
      halign: "center",
      fillColor: [105, 105, 105],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "center",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: {
      B: { fontStyle: "bold" },
      D: { fontStyle: "bold" },
      F: { fontStyle: "bold" },
      H: { fontStyle: "bold" },
    }
  });

  // title & header Content end
  let first = doc.autoTable.previous;
  doc.autoTable(secondTableColumn, secondTableList, {
    startY: first.finalY + 2,
    theme: "grid",
    pageBreak: "auto",
    headStyles: {
      halign: "center",
      fillColor: [105, 105, 105],
      textColor: [255, 255, 255],
      overflow: "linebreak",
      fontSize: 10
    },
    styles: {
      halign: "center",
      fontSize: 8,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillStyle: "F"
    },
    columnStyles: {},
    didParseCell: function (data) {
      //   console.log("pdfData", data, "Opts", opts);
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    },

    didDrawPage: pageContent,
    margin: { bottom: 15 }
  });

  //for total footer

  let second = doc.autoTable.previous;
  doc.autoTable(footerColumn, footerList, {
    startY: second.finalY + 2,
    showHead: "never",
    theme: "grid",
    styles: {
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      fillColor: [220, 220, 220],
      fontSize: 10
    },
    columnStyles: footerColumnStyle,
    didParseCell: function (data) {
      if (typeof data.cell.raw == "number") { data.cell.text = formatToCurrency(data.cell.raw); }
    }
  });

  if (typeof doc.putTotalPages === "function") { doc.putTotalPages(totalPagesExp); }
  doc.save(downloadTitle);
}