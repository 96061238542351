import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { StudentAccountService } from '../../../service/saPoint/StudentAccountService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';


let maxDate = new Date();
export class DateWiseStudentFeeCollectionSummary extends Component {

    constructor() {
        super();

        this.state = {
            fromDate: null,
            toDate: null,
            inputErrorMessage: null,
            studentFeeCollectionSummary: [],
            topProgressBar: false,
            dataTableIsLoading: false,
            tableView: false,
            errors: {}
        }
        this.studentAccountService = new StudentAccountService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
    }

    onFromDate = (e) => {
        let { errors } = this.state;
        errors['fromDate'] = ''
        this.setState({ fromDate: e.value, errors })
    }

    onToDate = (e) => {
        let { errors } = this.state;
        errors['endDate'] = ''
        this.setState({ toDate: e.value, errors })
    }

    onSubmitHandler = (event, props) => {

        if (this.onSubmitErrorHandle()) {
            if (Date.parse(this.state.toDate) >= Date.parse(this.state.fromDate)) {
                this.getDateWiseStudentFeeSummary();
            }
            else {
                this.growl.show({ severity: 'warn', summary: 'Warn', detail: "From Date can't be ahead of TO Date." });
            }

        }
    }

    getDateWiseStudentFeeSummary = () => {
        let fDate = NetiDateUtils.getDateFromStringDDMMYYYY(this.state.fromDate);
        let tDate = NetiDateUtils.getDateFromStringDDMMYYYY(this.state.toDate);

        if (this.state.fromDate != null && this.state.toDate != null) {
            this.setState({
                fDateErrorMessage: false,
                tDateErrorMessage: false,
                topProgressBar: true,
                dataTableIsLoading: true,
                tableView: true,
                errorMsgVisible: false
            })

            this.studentAccountService.getDateWiseStudentFeeSummary(fDate, tDate)
                .then(data => {
                    console.log("data",data);
                    if (data.item) {
                        this.setState({ studentFeeCollectionSummary: data.item })
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, tableView: true })
                    }
                    else {
                        // this.studentAccountService.Auth.handleErrorStatus(data)
                        //     .then((resp) => {
                                this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: data.message})
                            // });
                    }

                }).catch(error => {
                    this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load datatable. Please check connection' })
                });


        }
        else {
            this.setState({
                fDateErrorMessage: true,
                tDateErrorMessage: true

            })
        }



    }


    onSubmitErrorHandle() {
        let { errors } = this.state;
        let formIsValid = true;


        if (!this.state.fromDate) {
            formIsValid = false;
            errors["fromDate"] = "Start Date can't left empty.";
        }
        else if (!Date.parse(this.state.fromDate)) {
            formIsValid = false;
            errors["fromDate"] = "Invalid Date format ";
        }

        if (!this.state.toDate) {
            formIsValid = false;
            errors["endDate"] = "End Date can't left empty.";
        }
        else if (!Date.parse(this.state.toDate)) {
            formIsValid = false;
            errors["endDate"] = "Invalid Date format";
        }

        this.setState({ errors });
        return formIsValid;
    }

    totalCollection = rowData => {
        return <div class="col-number text-right">{ this.ValidatorUtility.currencyFormatter(rowData.totalCollection) }</div>
    }

    totalWaiver = rowData => {
        return <div class="col-number text-right">{ this.ValidatorUtility.currencyFormatter(rowData.totalWaiver) }</div>
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, studentFeeCollectionSummary } = this.state;

        // let studentFeeCollectionSummaryInfo = []

        let headervalue = (
            <div className="p-clearfix">
                Fee Collection Summary{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {studentFeeCollectionSummary ? studentFeeCollectionSummary.length : ''}{" "}
                </span>
            </div>
        );

        // if(this.state.studentFeeCollectionSummary){
        //     studentFeeCollectionSummaryInfo = this.state.studentFeeCollectionSummary.item 
        // }

        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>
        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                {/* <Growl ref={el => (this.growl = el)} /> */}


                <div className="nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-5 p-lg-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Start Date {requiredSing}</span>
                                    <Calendar
                                        value={this.state.fromDate}
                                        onChange={this.onFromDate}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                        showButtonBar={true} 
                                    />
                                </div>
                                <span className="error-message" >{this.state.errors['fromDate']}</span>

                            </div>

                            <div className="p-col-12 p-xl-5 p-lg-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">End Date {requiredSing}</span>
                                    <Calendar
                                        value={this.state.toDate}
                                        onChange={this.onToDate}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                        showButtonBar={true} 
                                    />
                                </div>
                                <span className="error-message" >{this.state.errors['endDate']}</span>

                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitHandler.bind(this)} />
                            </div>
                        </div>
                    </div>


                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <DataTable
                                            header={headervalue}
                                            value={studentFeeCollectionSummary}
                                            responsive={true}
                                            paginator={true}
                                            rows={5}
                                        >
                                            <Column field="instituteId" header="Institute ID" sortable={true} filter={true} />
                                            <Column field="instituteName" header="Institute Name" sortable={true} filter={true} />
                                            <Column field="totalStudent" header="Total Student" sortable={true} filter={true} />
                                            <Column field="totalInvoice" header="Total Invoice" sortable={true} filter={true} />
                                            <Column field="totalCollection" header="Total Collection" body={this.totalCollection} sortable={true} filter={true} />
                                            <Column field="totalWaiver" header="Total Waiver" body={this.totalWaiver} sortable={true} filter={true} />
                                            {/* <Column field="" header="Action" /> */}
                                        </DataTable>
                                    :
                                    <BlankDataTableAnim />
                            }

                        </div>
                    </div>


                </div>

            </div>
        );
    }
}