import React, { component, Component } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { Dialog } from "primereact/dialog";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import NetiContentLoader from '../../common/NetiContentLoader';

import { ManagementPointService } from "../../../service/managementPoint/ManagementPointService";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
import { NetiDateUtils } from "../../../utils/NetiDateUtils";
import CommonFuctionality from "../../common/CommonFuctionality";
import { Calendar } from "primereact/calendar";
import { Growl } from "primereact/growl";
import { InputText } from 'primereact/inputtext';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';


// dialog content related
let selectedInstitute = '';
let dialogHeader = '';
let dialogTableHeader = '';
let dialogTableValue = [];
let dialogTableColumns = [];
let backBtnVisible = false;

let pdfTitle = '';
let pdfDialogTableColumns = [];

let totalStudent = 0;
let totalInvoice = 0;
let totalCollectionAmount = 0;
let institutionCollectionListFooter = '';

let maxDate = new Date();

export class InstituteWiseCollection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            instituteID: '',
            dialogTableFooter: '',
            errorMsgVisible: false,
            collectionDetailsDialogInfo: {},
            viewCollectionInfo: false,
            topProgressBar: false,
            errorMsgBody: '',
            errors: {},
            dateRangeParamFormat: {
                requestStartDate: "",
                requestEndDate: ""
            },
            requestedDateObj: {
                "startDate": '',
                "endDate": ''
            },
            dataTableIsLoading: false,
            dataTableIsLoading2: false,

            visible: false,
            dataTableValue: [],
            dataWiseCollectionTableValue: [],
            totalCollectionAmountDetailsTableValue: [],
            collectionAccountListTableValue: []
        }

        this.ManagementPointService = new ManagementPointService();
        this.CommonFuctionality = new CommonFuctionality();
        this.netiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();

        this.makeDialogVisible = this.makeDialogVisible.bind(this);
        this.makeDialogHide = this.makeDialogHide.bind(this);

        // this.backOnPreviousTable = this.backOnPreviousTable.bind(this);
        this.resetDialogAndTableData = this.resetDialogAndTableData.bind(this);

    }

    makeDialogVisible() {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visible: true });
    }

    makeDialogHide() {
        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visible: false });
        // this.resetDialogAndTableData();
        backBtnVisible = false;
    }

    resetDialogAndTableData() {
        dialogHeader = ''
        dialogTableHeader = '';
        dialogTableValue = []
        dialogTableColumns = [];
        pdfDialogTableColumns = [];
    }

    // backOnPreviousTable() {

    //     this.resetDialogAndTableData();
    //     backBtnVisible = false;

    //     // dialog content set
    //     dialogHeader = 'Date Wise Total Collection';

    //     dialogTableHeader = <div className="header-title">
    //         <div className="header-title-left">Date Wise Total Collection Details List</div>
    //         <div className="header-title-right"><a>Total Found: {this.state.dataWiseCollectionTableValue.length.toLocaleString('EN-IN')}</a></div>
    //     </div>;

    //     Array.prototype.push.apply(dialogTableValue, this.state.dataWiseCollectionTableValue);
    //     dialogTableColumns.push(
    //         <Column field="formatedCollectionDate" header="Date & Time" style={{ width: '110px' }} />,
    //         <Column field="collectionDay" header="Day" />,
    //         <Column field="collectionAmount" header="Collection Amount" body={this.collectionAmountBody} />
    //     )
    //     // dialog content set end

    //     //pdf column set
    //     pdfTitle = "Date Wise Total Collection Details List of " + selectedInstitute;
    //     pdfDialogTableColumns.push(
    //         { title: "Date & Time", dataKey: "formatedCollectionDate" },
    //         { title: "Day", dataKey: "collectionDay" },
    //         { title: "Collection Amount", dataKey: "collectionAmount" },
    //     );
    //     //pdf column set end

    // }

    onSubmitHandler = () => {
        this.resetDialogAndTableData();
        let { dateRangeParamFormat } = this.state;
        let startDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestStartDate);
        let endDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestEndDate);
        let requestedDateObj = {
            "startDate": startDate,
            "endDate": endDate
        }
        this.setState({ requestedDateObj: requestedDateObj });

        if (this.searchHandleError()) {

            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.ManagementPointService.fetchMfsCollectionByInstituteId(this.state.instituteID, requestedDateObj)
                .then((res) => {

                    if (res.status == 302) {
                        return res.json().then((body) => {
                            body.item.map(item => {
                                Object.assign(item, { formattedTransactionDate: NetiDateUtils.getAnyShortForm(item.date, 'DD-MMM-YYYY'), transactionDate: item.date });  // for pdf column
                            });
                            console.log('mpfResBody', body);
                            totalStudent = body.item.map(item => +item.totalStudent)
                                .reduce((a, b) => {
                                    return a + b;
                                }, 0);
                            totalInvoice = body.item.map(item => +item.totalInvoice)
                                .reduce((a, b) => {
                                    return a + b;
                                }, 0);
                            totalCollectionAmount = body.item.map(item => +item.totalCollection)
                                .reduce((a, b) => {
                                    return a + b;
                                }, 0);
                            this.setState({ dataTableValue: body.item });
                            this.setState({ dialogTableFooter: institutionCollectionListFooter  });


                            // dialog content set
                            dialogTableHeader = <div className="header-title">
                                <div className="header-title-left">Institute Collection List</div>
                                <div className="header-title-right"><a>Total Found: {body.item.length.toLocaleString('EN-IN')}</a></div>
                            </div>;
    
                            Array.prototype.push.apply(dialogTableValue, body.item);
                            dialogTableColumns.push(
                                <Column field="formattedTransactionDate" header="Date" style={{ width: '110px' }} />,
                                <Column field="totalStudent" header="Total Student" />,
                                <Column field="totalInvoice" header="Total Invoice" />,
                                <Column field="totalCollection" header="Total Collection" body={this.dateWiseTotalCollectionBody} />,
                                <Column field="collectionAccount" header="Collection Account" body={this.dateWiseCollectionAccountBody}/>
                            );
                            // dialog content set end
                            this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        });

                    } else {
                        this.ManagementPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                    }

                }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

                );

        }

    }

    fetchDrillDownDataOf_TotalCollection = (instituteId) => {
        console.log('instituteId', instituteId, this.state.requestedDateObj);

        this.setState({ topProgressBar: true, dataTableIsLoading2: true, errorMsgVisible: false });
        this.ManagementPointService.fetchMfsCollectionByInstituteId(this.state.instituteID, this.state.requestedDateObj)
            .then((res) => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log('resOfCollBody', body);

                        body.item.map(item => {
                            item.collectionAmount = this.validatorUtility.currencyFormatter(item.collectionAmount);
                            Object.assign(item, { formatedCollectionDate: NetiDateUtils.getAnyShortForm(item.date, 'DD-MMM-YYYY hh:mm:ss a'), transactionDate: item.date });  // for pdf column
                        });
                        this.setState({ dataWiseCollectionTableValue: body.item });

                        // dialog content set
                        dialogTableHeader = <div className="header-title">
                            <div className="header-title-left">Date Wise Total Collection Details List</div>
                            <div className="header-title-right"><a>Total Found: {body.item.length.toLocaleString('EN-IN')}</a></div>
                        </div>;

                        Array.prototype.push.apply(dialogTableValue, body.item);
                        dialogTableColumns.push(
                            <Column field="formatedCollectionDate" header="Date & Time" style={{ width: '110px' }} />,
                            <Column field="totalStudent" header="Total Student" />,
                            <Column field="totalInvoice" header="Total Invoice" />,
                            <Column field="totalCollection" header="Total Collection" body={this.dateWiseTotalCollectionBody} />,
                            <Column field="collectionAccount" header="Collection Account" body={this.dateWiseCollectionAccountBody}/>
                        );
                        // dialog content set end

                        // //pdf column set
                        // pdfTitle = "Date Wise Total Collection Details List of " + instituteId;
                        // pdfDialogTableColumns.push(
                        //     { title: "Date & Time", dataKey: "formatedCollectionDate" },
                        //     { title: "Day", dataKey: "collectionDay" },
                        //     { title: "Collection Amount", dataKey: "collectionAmount" },
                        // );
                        // //pdf column set end

                        this.setState({ topProgressBar: false, dataTableIsLoading2: false });
                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    dateWiseCollectionAccountBody = (rowData) => {
        return <span style={{ color: "#0078ff", cursor: "pointer", textDecoration: "underline" }} onClick={(e) => this.onClickCollectionAccountRData(rowData)} ><div className="col-number text-right">{rowData.collectionAccount}</div></span>
    }

    onClickCollectionAccountRData = (rowData) => {
        this.resetDialogAndTableData();
        this.fetchDrillDownDataOf_CollectionAccount(rowData.date);
    }

    fetchDrillDownDataOf_CollectionAccount(transactionDate) {
        let formattedDate = NetiDateUtils.getDateFromString(transactionDate)
        let dateRange = {
            'startDate': formattedDate
        }

        this.setState({ topProgressBar: true, dataTableIsLoading2: true, errorMsgVisible: false });
        this.ManagementPointService.fetchMfsCollectionAccountList(this.state.instituteID,dateRange)
            .then((res) => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log(' collection account body',body);

                        body.item.map(item => {
                            item.amount = this.validatorUtility.currencyFormatter(item.amount);
                            Object.assign(item, { formatedCollectionDate: NetiDateUtils.getAnyShortForm(item.logTime, 'DD-MMM-YYYY hh:mm:ss a') });  // for pdf column
                        });
                        this.setState({ collectionAccountListTableValue: body.item });

                        // dialog content set
                        dialogTableHeader = <div className="header-title">
                            <div className="header-title-left">Collection Account List</div>
                            <div className="header-title-right">
                                <a>Total Found: {body.item.length.toLocaleString('EN-IN')}</a>
                                <Button
                                label="Back"
                                icon="fas fa-angle-left"
                                className="p-button p-button-success nw-button nw-button-right"
                                onClick={e => this.goBackToDateWiseTotalCollection(e)}
                                iconPos="right"
                            />
                                </div>
                        </div>;

                        Array.prototype.push.apply(dialogTableValue, body.item);
                        dialogTableColumns.push(
                            <Column field="accountTitle" header="Account Title"  filter={true} />,
                            <Column field="accountNo" header="Account No."  filter={true} />,
                            <Column field="amount" header="Amount" body={this.collectionAccountAmountBody}  filter={true}/>,
                        );
                        // dialog content set end

                        //pdf column set
                        // pdfTitle = "Date Wise Total Collection Details List of " + instituteId;
                        // pdfDialogTableColumns.push(
                        //     { title: "Date & Time", dataKey: "formatedCollectionDate" },
                        //     { title: "Day", dataKey: "collectionDay" },
                        //     { title: "Collection Amount", dataKey: "collectionAmount" },
                        // );
                        //pdf column set end

                        this.setState({ topProgressBar: false, dataTableIsLoading2: false });
                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    collectionAccountAmountBody = (rowData) => {
        return <span  ><div className="col-number text-right"> {rowData.amount} </div></span>
    }




    searchHandleError = () => {

        let { errors, dateRangeParamFormat } = this.state;

        let formIsValid = true;
        if (this.state.instituteID === '') {
            formIsValid = false;
            errors["instituteID"] = "Institute ID can't left empty.";
        }
        if (dateRangeParamFormat.requestStartDate === '') {
            formIsValid = false;
            errors["requestStartDate"] = "Start Date can't left empty.";
        }
        if (dateRangeParamFormat.requestEndDate === '') {
            formIsValid = false;
            errors["requestEndDate"] = "End Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onChangeDate = (e) => {

        let { dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        this.clearDateError(e.target.name);

    }

    makeDialogVisible() {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visible: true });
    }

    makeDialogHide() {
        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visible: false });
        this.resetDialogAndTableData();
        backBtnVisible = false;
    }

    onClickTotalCollectionRData = (rowdata) => {

        this.makeDialogVisible();
        dialogHeader = 'Date Wise Total Collection Details';
        this.fetchDrillDownDataOf_TotalCollection(rowdata.instituteId);
        selectedInstitute = rowdata.instituteId;

    }

    totalCollectionBody = (rowData) => {
        return <span style={{ color: "#0078ff", cursor: "pointer", textDecoration: "underline" }} onClick={(e) => this.onClickTotalCollectionRData(rowData)} ><div className="col-number text-right"> {rowData.totalCollection} </div></span>
    }

    dateWiseTotalCollectionBody = (rowData) => {
        return <span style={{ color: "#0078ff", cursor: "pointer", textDecoration: "underline" }} onClick={(e) => this.onClickDateWiseTotalCollectionRData(rowData)} ><div className="col-number text-right">{rowData.totalCollection}</div></span>
    }

    onClickDateWiseTotalCollectionRData = (rowData) => {
        this.resetDialogAndTableData();
        console.log('  rowData',rowData);

        this.fetchDrillDownDataOf_dateWiseTotalCollection(rowData.transactionDate);
    }

    fetchDrillDownDataOf_dateWiseTotalCollection(transactionDate) {
        console.log('  transactionDate',transactionDate);
        this.setState({ dialogTableFooter: ''  });

        let transactionFormattedDate = NetiDateUtils.getDateFromStringDDMMYYYY(transactionDate)

        this.setState({ topProgressBar: true, dataTableIsLoading2: true, errorMsgVisible: false });
        this.ManagementPointService.fetchDateWiseMfsCollectionByInstituteId(this.state.instituteID,transactionFormattedDate)
            .then((res) => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log(' dateWiseTotalCollection body',body);

                        body.item.map(item => {
                            item.trnAmount = this.validatorUtility.currencyFormatter(item.trnAmount);
                            Object.assign(item, { formatedCollectionDate: NetiDateUtils.getAnyShortForm(item.logTime, 'DD-MMM-YYYY hh:mm:ss a') });  // for pdf column
                        });
                        this.setState({ totalCollectionAmountDetailsTableValue: body.item });

                        // dialog content set
                        dialogTableHeader = <div className="header-title">
                            <div className="header-title-left">Collection Amount Details List</div>
                          
                            <div className="header-title-right">
                                <a>Total Found: {body.item.length.toLocaleString('EN-IN')}</a>
                                <Button
                                label="Back"
                                icon="fas fa-angle-left"
                                className="p-button p-button-success nw-button nw-button-right"
                                onClick={e => this.goBackToDateWiseTotalCollection(e)}
                                iconPos="right"
                            />
                                </div>
                         
                        </div>;

                        Array.prototype.push.apply(dialogTableValue, body.item);
                        dialogTableColumns.push(
                            <Column field="formatedCollectionDate" header="Transaction Date"  filter={true} style={{ width: '110px' }} />,
                            <Column field="studentId" header="Student ID"  filter={true} />,
                            <Column field="studentName" header="Student Name"  filter={true} />,
                            <Column field="trnId" header="Transaction ID"   filter={true}/>,
                            <Column field="trnAmount" header="Amount" body={this.collectionAmountBody}  filter={true}/>,
                            <Column header="Details" body={this.detailsBody}/>
                        );
                        // dialog content set end

                        //pdf column set
                        // pdfTitle = "Date Wise Total Collection Details List of " + instituteId;
                        // pdfDialogTableColumns.push(
                        //     { title: "Date & Time", dataKey: "formatedCollectionDate" },
                        //     { title: "Day", dataKey: "collectionDay" },
                        //     { title: "Collection Amount", dataKey: "collectionAmount" },
                        // );
                        //pdf column set end

                        this.setState({ topProgressBar: false, dataTableIsLoading2: false });
                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }
    
    goBackToDateWiseTotalCollection = () => {
        this.resetDialogAndTableData();
          // dialog content set
          dialogTableHeader = <div className="header-title">
          <div className="header-title-left">Institution Collection List</div>
          <div className="header-title-right"><a>Total Found: {this.state.dataTableValue.length.toLocaleString('EN-IN')}</a></div>
      </div>;

      Array.prototype.push.apply(dialogTableValue,this.state.dataTableValue );
      dialogTableColumns.push(
          <Column field="formattedTransactionDate" header="Date & Time" style={{ width: '110px' }} />,
          <Column field="totalStudent" header="Total Student" />,
          <Column field="totalInvoice" header="Total Invoice" />,
          <Column field="totalCollection" header="Total Collection" body={this.dateWiseTotalCollectionBody} />,
          <Column field="collectionAccount" header="Collection Account" body={this.dateWiseCollectionAccountBody}/>
      );
      // dialog content set end

    }
    detailsBody = (rowData) => {
        return <div className='text-center'>
        <Button
            className="nw-action-button"
            icon="far fa-eye"
            title="View"
            onClick={(e) => this.viewDialog(rowData)}
            tooltip="View"
            tooltipOptions={{position: 'top'}}
        />
        </div>

    }

    viewDialog = (rowData) => {
     this.setState({ viewCollectionInfo: true });

     this.setState({  errorMsgVisible:false,  topProgressBar:true,});
     this.ManagementPointService.fetchMfsCollectionInfoByTransactionId(rowData.trnId)
     .then(res => {
         if (res.status === 302) {
             return res.json().then((body) => {
                 console.log("collection details body",body.item);
                 this.setState({ collectionDetailsDialogInfo: body.item, topProgressBar:false, errorMsgVisible:false });
             });
         } else {
             this.ManagementPointService.Auth.handleErrorStatus(res)
             .then((resp)=>{
                 this.setState({topProgressBar:false, errorMsgVisible: true, errorMsgBody: resp})
             });
         }
     }).catch(error => { 
         this.setState({ topProgressBar:false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
 });
    }

    collectionAmountBody = (rowData) => {
        return <span ><div className="col-number text-right">{rowData.trnAmount}</div></span>
    }

    onChangeInstituteID = (e) => {
        this.setState({instituteID: e.target.value});
        this.clearDateError(e.target.name);
    }

    render() {

        let { dateRangeParamFormat, dataTableValue, dialogDataTableValue, dialogModuleName, dialogActivityType,collectionDetailsDialogInfo } = this.state;
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Institution Collection List</div>
            <div className="header-title-right"><a>Total Found: {this.state.dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let dialogContent = <div className="p-col-12 p-xl-12 m-b-20 nw-data-table nw-data-table-tabview">
            <DataTable
                value={dialogTableValue}
                header={dialogTableHeader}
                responsive={true}
                paginator={true}
                rows={10}
            >
                {dialogTableColumns}
            </DataTable>
        </div>

     institutionCollectionListFooter = <ColumnGroup>
            <Row>
                <Column footer="Total:" className="nw-tfoot-total-text" />
                <Column style={{ textAlign: 'left' }} footer={totalStudent ? totalStudent.toLocaleString('EN-IN') : 0} />
                <Column style={{ textAlign: 'left' }} footer={totalInvoice ? totalInvoice.toLocaleString('EN-IN') : 0} />
                <Column footer={totalCollectionAmount ? this.validatorUtility.currencyFormatter(totalCollectionAmount) : 0} />
                <Column footer='' />
            </Row>
        </ColumnGroup>;

        return (

            <div className="p-fluid">
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <Growl ref={(el) => this.growl = el} />
                                {topProgressBar ?
                                    <ErrorMessageView
                                        topProgressBar={topProgressBar}
                                    />
                                    : null
                                }
                                {errorMsgVisible ?
                                    <ErrorMessageView
                                        errorMsgVisible={errorMsgVisible}
                                        errorMsgBody={errorMsgBody}
                                    />
                                    : null
                                }
                                <div className="p-col-12 p-xl-5 p-lg-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                        <InputText
                                            name="instituteID"
                                            placeholder="Enter Institute ID"
                                            onChange={this.onChangeInstituteID}
                                            value={this.state.instituteID}

                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["instituteID"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-5 p-lg-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">START DATE <span>*</span></span>
                                        <Calendar
                                            name='requestStartDate'
                                            value={dateRangeParamFormat.requestStartDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["requestStartDate"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-5 p-lg-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">END DATE <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            name='requestEndDate'
                                            value={dateRangeParamFormat.requestEndDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            dateFormat='dd/mm/yy'
                                            yearRange="2010:2030"
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["requestEndDate"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-2 p-lg-12 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-left"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>

                            </div>

                        </div>
                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                        <div className="nw-data-table">

                           
                                {
                                    this.state.dataTableIsLoading ? this.netiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={dialogTableValue}
                                            header={dialogTableHeader ? dialogTableHeader : tableHeader}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            footerColumnGroup={this.state.dialogTableFooter}
                                            rowsPerPageOptions={[10, 50,100]}
                                        >
                                            {dialogTableColumns}
                                        </DataTable>
                                }

</div>
                        </div>

                    </div>

                    <Dialog className="nw-dialog" header={dialogHeader} visible={this.state.visible} onHide={this.makeDialogHide} maximizable>

                        <div className="p-fluid">
                            <div className="p-grid">

                                {this.state.dataTableIsLoading2 ? this.netiContentLoader.MyPointTableLoader() : dialogContent}

                                <div class="p-col-12 p-xl-12 nw-button-parent-multiple p-r-0">

                                    {/* <Button
                                        className="p-button-primary nw-button nw-button-multiple"
                                        label="Download PDF"
                                        icon="fas fa-download"
                                        onClick={this.exportPdfOfDrillDown}
                                    /> */}

                                    {backBtnVisible ?
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Back"
                                            icon="fas fa-chevron-left"
                                            onClick={this.backOnPreviousTable}
                                        />
                                        : ''}
                                </div>

                            </div>
                        </div>
                    </Dialog>

                    <Dialog
                        header="Collection Details Info"
                        className="nw-dialog"
                        modal={true}
                        visible={this.state.viewCollectionInfo}
                        onHide={(e) => this.setState({ viewCollectionInfo: false })}
                        maximizable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Transaction Date
                      </span>
                                            <div className="nw-inputgroup-desc">
                                                {collectionDetailsDialogInfo && collectionDetailsDialogInfo.transactionDate ?  NetiDateUtils.getAnyShortForm(collectionDetailsDialogInfo.transactionDate, 'DD-MMM-YYYY hh:mm:ss a') : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Log Date
                      </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.logDate ?  NetiDateUtils.getAnyShortForm(collectionDetailsDialogInfo.logDate, 'DD-MMM-YYYY hh:mm:ss a') : ''}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Student ID
                      </span>
                                            <div className="nw-inputgroup-desc">
                                                {collectionDetailsDialogInfo && collectionDetailsDialogInfo.studentId}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Student Name
                      </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.studentName}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Student Mobile No.
                    </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.mobileNo}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Class
                    </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.className}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Invoice ID
                    </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.invoiceId}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Invoice Amount
                    </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.invoiceAmount ? this.validatorUtility.currencyFormatter(collectionDetailsDialogInfo.invoiceAmount) : ''}

                                            </div>
                                        </div>
                                    </div>   <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Transaction By
                    </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.transactionBy}
                                            </div>
                                        </div>
                                    </div>   <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Sender Mobile No.
                    </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.senderMobileNo}

                                            </div>
                                        </div>
                                    </div>   <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Account Mobile No.
                    </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.accountMobileNo}

                                            </div>
                                        </div>
                                    </div>   <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Transaction ID
                    </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.trnId}

                                            </div>
                                        </div>
                                    </div>   <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Paid Amount
                    </span>
                                            <div className="nw-inputgroup-desc">
                                            {collectionDetailsDialogInfo && collectionDetailsDialogInfo.paidAmount ? this.validatorUtility.currencyFormatter(collectionDetailsDialogInfo.paidAmount) : ''}

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </Dialog>

                </div>
            </div>
        )
    }

}
