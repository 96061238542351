import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { RadioButton } from "primereact/radiobutton";
import { CoreConfigView } from "./CoreConfigView";
import { Dropdown } from "primereact/dropdown";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { UserCategoryService } from "../../../service/UserCategoryService";
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import NetiContentLoader from '../../common/NetiContentLoader';

let urlId;
export class ApplicationConfigClassWise extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultLanguage: "English",
            errors: {},
            checkEligibility: false,
            errorMsgVisible: false,
            topProgressBar: false,
            dataIsLoading: false,
            secondDataIsLoading: false,
            errorMsgBody: "",
            formFee: "",
            previousExamInfo: false,
            yesAutoApprove: false,
            noAutoApprove: false,
            previousAdmissionInfo: false,
            autoApproveStatus: 0,
            prevExamInfoRequired: 0,
            admissionTestStatus: 0,
            saveErrors: {},
            examInstruction: '',
            instituteInfo: {
                coreConfigID: ''
            }
        };
        this.handlefeeValue = this.handlefeeValue.bind(this);
        this.handlepreviousExamInfo = this.handlepreviousExamInfo.bind(this);
        this.handlenoAutoApprove = this.handlenoAutoApprove.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.handlepreviousAdmissionInfo = this.handlepreviousAdmissionInfo.bind(this);
        this.handleyesAutoApproveOption = this.handleyesAutoApproveOption.bind(this);
        this.userCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
        this.admisiaPointService = new AdmisiaPointService();

    }

    componentWillMount() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (urlId) {
            this.setState({ homeReturnButton: false })
            this.viewInstituteDetails();
        }
        else {
            this.setState({ homeReturnButton: true })
        }

        this.getClassListByDefaultCode("T108");
        this.getGroupListByDefaultCode("T109");

    }

    viewInstituteDetails() {
        this.setState({ dataIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.admisiaPointService.getCoreConfigInfo(urlId)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("instituteInfo", body);
                        this.setState({ instituteInfo: body });
                        
                        
                        this.setState({ dataIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else if (res.status == 404) {
                    return res.json().then((body) => {
                        this.setState({ dataIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });


    }

    handlefeeValue = e => {
        this.setState({ formFee: e.target.value });
    };

    handlepreviousExamInfo = e => {
        this.setState({ previousExamInfo: e.value });
        if (e.value == true) {
            this.setState({ prevExamInfoRequired: 1 });
        } else if (e.value == false) {
            this.setState({ prevExamInfoRequired: 0 });
        }

    };

    handlepreviousAdmissionInfo = e => {
        this.setState({
            previousAdmissionInfo: e.value, autoApproveStatus: 0, yesAutoApprove: false
        })
        if (e.value == true) {
            this.setState({ admissionTestStatus: 1 });
        } else if (e.value == false) {
            this.setState({ admissionTestStatus: 0 });
        }
    };

    handlenoAutoApprove = e => {
        this.setState({ noAutoApprove: e.value });
        if (e.value == true) {
            this.setState({ autoApproveStatus: 1 });
        } else if (e.value == false) {
            this.setState({ autoApproveStatus: 0 });
        }
    };

    handleyesAutoApproveOption = e => {
        this.setState({ yesAutoApprove: e.value });
        if (e.value == true) {
            this.setState({ autoApproveStatus: 1 });
        } else if (e.value == false) {
            this.setState({ autoApproveStatus: 0 });
        }
    };

    getClassListByDefaultCode(defaultCode) {
        this.setState({ dataIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status === 302) {
                    return res.json().then(body => {
                        this.setState({ classDropDownList: body, dataIsLoading: false, topProgressBar: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            })
            .catch(error => {
                this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })

            });
    }


    getGroupListByDefaultCode(defaultCode) {
        this.setState({ secondDataIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status === 302) {
                    return res.json().then(body => {
                        this.setState({ groupDropDownList: body, secondDataIsLoading: false, topProgressBar: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, secondDataIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });

                }
            })
            .catch(error => {
                this.setState({ topProgressBar: false, secondDataIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    handleSave() {
        let admissionTestInstruction;
        let requestedObject;

        console.log("Start Date", Date.parse(this.state.startDate));
        
        if (this.handleSaveError()) {
            // this.setState({ dataIsLoading: true, secondDataIsLoading: true, topProgressBar: true, errorMsgVisible: false })
            if (Date.parse(this.state.endDate) >= Date.parse(this.state.startDate)) {
                this.setState({ dataIsLoading: true, secondDataIsLoading: true, topProgressBar: true, errorMsgVisible: false })

                admissionTestInstruction = this.state.examInstruction.trim();
                requestedObject = {
                    "startDate": this.state.startDate,
                    "endDate": this.state.endDate,
                    "applicantLimit": this.state.requiredApplicant,
                    "feeAmount": +this.state.formFee + 25,
                    "admissionTestStatus": this.state.admissionTestStatus,
                    "admissionTestDate": this.state.examDateTime,
                    "admissionTestInstruction": admissionTestInstruction,
                    "autoApproveStatus": this.state.autoApproveStatus,
                    "prevExamInfoRequired": this.state.prevExamInfoRequired,
                    "othersInfoRequired": 0,
                    "othersInfoData": "string",
                    "admisiaCoreConfigDTO": {
                        "coreConfigID": this.state.instituteInfo.coreConfigID
                    },
                    "classCoreCategoryInfoDTO": {
                        "coreCategoryID": this.state.className
                    },
                    "groupCoreCategoryInfoDTO": {
                        "coreCategoryID": this.state.groupName
                    }
                }

                this.admisiaPointService.createAdmisiaClassConfig(requestedObject).then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data save successfully.' });
                        this.setState({ dataIsLoading: false, secondDataIsLoading: false, topProgressBar: false });
                        this.setState({
                            startDate: '',
                            endDate: '',
                            requiredApplicant: '',
                            examDateTime: '',
                            // instituteInfo: { coreConfigID: '' },
                            className: '',
                            groupName: '',
                            formFee: "",
                            previousExamInfo: false,
                            yesAutoApprove: false,
                            noAutoApprove: false,
                            previousAdmissionInfo: false,
                            autoApproveStatus: 0,
                            prevExamInfoRequired: 0,
                            admissionTestStatus: 0,
                            examInstruction: '',
                        })
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ dataIsLoading: false, secondDataIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataIsLoading: false, secondDataIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "Start Date can't be greater than End Date." });
                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: false })
            }

        }
    }

    handleSaveError() {
        let saveErrors = {};
        let formIsValid = true;

        if (this.state.className=== "" || this.state.className === undefined) {
            formIsValid = false;
            saveErrors["className"] = "Class Name can't left empty.";
        }
        if (this.state.groupName === "" || this.state.groupName === undefined) {
            formIsValid = false;
            saveErrors["groupName"] = "Group Name can't left empty.";
        }
        if ( this.state.startDate=== "" || !this.state.startDate) {
            formIsValid = false;
            saveErrors["startDate"] = "Start Date can't left empty.";
        }
        else if (!Date.parse(this.state.startDate)) {
            formIsValid = false;
            saveErrors["startDate"] = "Invalid Date format";
        }

        if ( this.state.endDate === "" || !this.state.endDate) {
            formIsValid = false;
            saveErrors["endDate"] = "End Date can't left empty.";
        }
        else if (!Date.parse(this.state.endDate)) {
            formIsValid = false;
            saveErrors["endDate"] = "Invalid Date format";
        }
        if (this.state.requiredApplicant === "" || this.state.requiredApplicant === undefined) {
            formIsValid = false;
            saveErrors["requiredApplicant"] = "Please Input Value";
        }
        if (this.state.formFee === "" || this.state.formFee === undefined) {
            formIsValid = false;
            saveErrors["formFee"] = "Please Input Value";
        }
        if (this.state.previousAdmissionInfo === true) {
            if (!this.state.examDateTime) {
                formIsValid = false;
                saveErrors["examDateTime"] = "Exam Date can't left empty.";
            }
            if (this.state.examDateTime && !Date.parse(this.state.examDateTime)) {
                formIsValid = false;
                saveErrors["examDateTime"] = "Invalid Date format";
            }
            if (!this.state.examInstruction) {
                formIsValid = false;
                saveErrors["examInstruction"] = "Exam Instruction can't left empty.";
            }
        }

        this.setState({ saveErrors: saveErrors });
        return formIsValid;
    }


    render() {
        let classList = [];
        if (
            this.state.classDropDownList != null &&
            this.state.classDropDownList.length > 0
        ) {
            classList = this.state.classDropDownList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }
        let groupList = [];
        if (
            this.state.groupDropDownList != null &&
            this.state.groupDropDownList.length > 0
        ) {
            groupList = this.state.groupDropDownList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }
        const yesAutoApproveOption = [
            { label: "Yes", value: true, },
            { label: "No", value: false, id: 0 }
        ];
        const noAutoApproveOption = [
            { label: "Yes", value: true, },
            { label: "No", value: false, }
        ];
        let feeGrandValue = parseFloat(this.state.formFee);
        let feeTax = parseFloat(25);
        if (feeGrandValue == 0) {
            feeGrandValue += feeTax;
        } else if (feeGrandValue !== 0 && feeGrandValue !== "") {
            feeGrandValue = feeGrandValue + feeTax;
        } else if (feeGrandValue === "") {
            feeGrandValue = 0;
        }

        let {
            dwsUrlInfo,
            topProgressBar,
            errorMsgVisible,
            errorMsgBody,
            errors
        } = this.state;
        return (
            <div>
                <div>
                    {topProgressBar ? (
                        <ErrorMessageView topProgressBar={topProgressBar} />
                    ) : null}
                    {errorMsgVisible ? (
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                    ) : null}

                    <Growl ref={el => (this.growl = el)} />
                    <style jsx>{`
                        table {
                        font-family: arial, sans-serif;
                        border-collapse: collapse;
                        width: 100%;
                        }

                        td,
                        th {
                        padding: 8px;
                        }

                        tr:nth-child(even) {
                        border-style: hidden;
                        }
                    `}</style>

                    <div className="p-fluid">
                        <div className="p-grid nw-form">
                            {this.state.checkEligibility === true ? (
                                <CoreConfigView
                                    instituteInfo={this.state.instituteInfo}
                                    logo={this.state.institutePicture}
                                />
                                ) : (
                                    <div className="p-grid nw-form">
                                        {this.state.instituteInfo.academicYearCoreCategoryInfoDTO && this.state.instituteInfo.academicYearCoreCategoryInfoDTO.categoryName != null ? 

                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.dataIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                        Academic Year
                                                                        </span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.instituteInfo.academicYearCoreCategoryInfoDTO ? this.state.instituteInfo.academicYearCoreCategoryInfoDTO.categoryName : ''}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        

                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.dataIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Class <span>*</span></span>
                                                                    <Dropdown
                                                                        placeholder="Select Class"
                                                                        options={classList}
                                                                        value={this.state.className}
                                                                        onChange={e =>
                                                                          
                                                                            this.setState({ className: e.value })
                                                                        }
                                                                        filter={true}
                                                                        filterBy="label,value"
                                                                        autoWidth={false}
                                                                        showClear={true}
                                                                    />
                                                                </div>
                                                            }
                                                            <span className="error-message">{this.state.className? "" : this.state.saveErrors["className"]}</span>
                                                        </div>


                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.secondDataIsLoading ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Group <span>*</span></span>
                                                                    <Dropdown
                                                                        placeholder="Select Group"
                                                                        options={groupList}
                                                                        value={this.state.groupName}
                                                                        onChange={e =>
                                                                            this.setState({ groupName: e.value })
                                                                        }
                                                                        filter={true}
                                                                        filterBy="label,value"
                                                                        autoWidth={false}
                                                                        showClear={true}
                                                                    />
                                                                </div>
                                                            }
                                                            <span className="error-message">{this.state.groupName ? "" : this.state.saveErrors["groupName"]}</span>

                                                        </div>
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Application Start <span>*</span></span>
                                                                <Calendar
                                                                    name="requestStartDate"
                                                                    placeholder="Select Start Date"
                                                                    value={this.state.startDate}
                                                                    onChange={e =>
                                                                        this.setState({ startDate: e.value })
                                                                    }
                                                                    showIcon={true}
                                                                    yearRange="2010:2030"
                                                                    dateFormat="dd/mm/yy"
                                                                    monthNavigator={true}
                                                                    yearNavigator={true}
                                                                    dateOnly="true"
                                                                    showButtonBar={true} 
                                                                />
                                                            </div>
                                                            <span className="error-message">{this.state.startDate ? "" : this.state.saveErrors["startDate"]}</span>

                                                        </div>
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Application End <span>*</span></span>
                                                                <Calendar
                                                                    name="requestEndDate"
                                                                    placeholder="Select End Date"
                                                                    value={this.state.endDate}
                                                                    onChange={e =>
                                                                        this.setState({ endDate: e.value })
                                                                    }
                                                                    showIcon={true}
                                                                    yearRange="2010:2030"
                                                                    dateFormat="dd/mm/yy"
                                                                    monthNavigator={true}
                                                                    yearNavigator={true}
                                                                    dateOnly="true"
                                                                    showButtonBar={true} 
                                                                />
                                                            </div>
                                                            <span className="error-message">{this.state.endDate ? "" : this.state.saveErrors["endDate"]}</span>

                                                        </div>
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon font-size-12">Total Required Applicant <span>*</span></span>
                                                                <InputText
                                                                    name="text"
                                                                    placeholder="Enter Total number Required Applicant"
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            requiredApplicant: e.target.value
                                                                        })
                                                                    }
                                                                    value={this.state.requiredApplicant}
                                                                    keyfilter="pint"
                                                                />
                                                            </div>

                                                            <span className="error-message">{this.state.requiredApplicant ? "" :this.state.saveErrors["requiredApplicant"]}</span>


                                                            {/* {this.state.proPic.contentName ? "" : errors["logo"]} */}
                                                        </div>





                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon ">Application Form Fee <span>*</span></span>
                                                                <InputText
                                                                    name="text"
                                                                    placeholder="Enter Application Form Fee"
                                                                    onChange={this.handlefeeValue}
                                                                    value={this.state.formFee}
                                                                    keyfilter="money"
                                                                />
                                                                <span className="p-inputgroup-addon nw-inputgroup-small-span">{isNaN(feeGrandValue) ? 0 : feeGrandValue} BDT</span>
                                                            </div>

                                                            <span className="error-message">{this.state.formFee ? "" : this.state.saveErrors["formFee"]}</span>
                                                        </div>
                                                        &nbsp; &nbsp; 25 Taka charge will be included with Fee


                                                        <div className="p-col-12 p-fluid">
                                                            <br />
                                                            <span>
                                                                {" "}
                                                                <label htmlFor="resolution-username">
                                                                    Do you need Applicant Previous Exam Info?
                                                                </label>
                                                                <br />
                                                                <RadioButton
                                                                    inputId="rb1"
                                                                    name="previousExamInfo"
                                                                    value={true}
                                                                    // onChange={e =>
                                                                    //     this.setState({ previousExamInfo: e.value })
                                                                    // }
                                                                    onChange={this.handlepreviousExamInfo}
                                                                    checked={this.state.previousExamInfo === true}
                                                                />
                                                                <label
                                                                    htmlFor="rb1"
                                                                    className="p-radiobutton-label"
                                                                >
                                                                    Yes
                                                                </label>
                                                                &nbsp;&nbsp; &nbsp;&nbsp;
                                                                <RadioButton
                                                                    inputId="rb2"
                                                                    name="previousExamInfo"
                                                                    value={false}
                                                                    onChange={this.handlepreviousExamInfo}
                                                                    checked={
                                                                        this.state.previousExamInfo === false
                                                                    }
                                                                />
                                                                <label
                                                                    htmlFor="rb2"
                                                                    className="p-radiobutton-label"
                                                                >
                                                                    No
                                                                </label>
                                                            </span>
                                                            
                                                            <br />
                                                        </div>
                                                        {this.state.previousExamInfo ? (
                                                            <div className="p-col-12 p-fluid">
                                                                <h2>Required Information</h2>

                                                                <table>
                                                                    {/* <tr>
                                                                        <th colspan="2">Required Information</th>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <td>★ Institute Name</td>
                                                                        <td>★ Exam</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>★ Board</td>
                                                                        <td>★ Grade</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>★ Class</td>
                                                                        <td>★ GPA</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>★ Roll No</td>
                                                                        <td>★ Passing Year</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        ) : (
                                                                ""
                                                            )}
                                                        <div className="p-col-12 p-fluid">
                                                            <span>
                                                                {" "}
                                                                <label htmlFor="resolution-username">
                                                                    Do you need Applicant Assesment?
                                                                </label>
                                                                <br />
                                                                <RadioButton
                                                                    inputId="rb3"
                                                                    name="previousAdmissionInfo"
                                                                    value={true}
                                                                    onChange={this.handlepreviousAdmissionInfo}
                                                                    checked={
                                                                        this.state.previousAdmissionInfo === true
                                                                    }
                                                                />
                                                                <label
                                                                    htmlFor="rb3"
                                                                    className="p-radiobutton-label"
                                                                >
                                                                    Yes
                                                                </label>
                                                                &nbsp;&nbsp; &nbsp;&nbsp;
                                                                <RadioButton
                                                                    inputId="rb4"
                                                                    name="previousAdmissionInfo"
                                                                    value={false}
                                                                    onChange={this.handlepreviousAdmissionInfo}
                                                                    checked={
                                                                        this.state.previousAdmissionInfo === false
                                                                    }
                                                                />
                                                                <label
                                                                    htmlFor="rb4"
                                                                    className="p-radiobutton-label"
                                                                >
                                                                    No
                                                                 </label>
                                                            </span>

                                                            <br />
                                                        </div>
                                                        {this.state.previousAdmissionInfo ? (
                                                            <div className="p-col-12 p-fluid">
                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="p-inputgroup-addon">Exam Date And Time <span>*</span></span>
                                                                        <Calendar
                                                                            name="examDateTime"
                                                                            dateFormat="dd/mm/yy"
                                                                            placeholder="Select Exam Date and Time"
                                                                            value={this.state.examDateTime}
                                                                            onChange={e =>
                                                                                this.setState({ examDateTime: e.value })
                                                                            }
                                                                            showIcon={true}
                                                                            showTime={true}
                                                                            showSeconds={true}
                                                                            hourFormat="12"
                                                                        />
                                                                    </div>

                                                                    <span className="error-message">
                                                                        {this.state.saveErrors["examDateTime"]}
                                                                    </span>
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">Exam Instruction <span>*</span></span>
                                                                        <InputTextarea
                                                                            value={this.state.examInstruction}
                                                                            onChange={e =>
                                                                                this.setState({
                                                                                    examInstruction: e.target.value
                                                                                })
                                                                            }
                                                                            placeholder="Write Exam Instruction"
                                                                            rows={5}
                                                                            cols={30}
                                                                        />
                                                                    </div>

                                                                    <span className="error-message">
                                                                        {this.state.saveErrors["examInstruction"]}
                                                                    </span>
                                                                </div>


                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="p-inputgroup-addon font-size-12" style={{ minWidth: "250px" }}>Auto Approve for Admission Test</span>
                                                                        <Dropdown
                                                                            options={yesAutoApproveOption}
                                                                            value={this.state.yesAutoApprove}
                                                                            onChange={this.handleyesAutoApproveOption}
                                                                            filter={true}
                                                                            filterBy="label,value"
                                                                            autoWidth={false}
                                                                            showClear={true}
                                                                        />
                                                                    </div>
                                                                </div>




                                                                {this.state.yesAutoApprove ? (
                                                                    <div className="p-col-12 p-fluid">
                                                                        <label htmlFor="resolution-username">
                                                                            By Selecting 'Yes'
                                                                        </label>{" "}
                                                                        <br />
                                                                        You can Find these applicants automatically
                                                                        into the (Approval / Assesment / Exam Mark) 'Exam Mark List'
                                                                        table. And applicants able to collect their
                                                                        'Admit Crad' from the admisia website for
                                                                        participating into the 'Admission Test'
                                                                        after completing online submission &
                                                                        payment.
                                                                    </div>
                                                                ) : (
                                                                        <div className="p-col-12 p-fluid">
                                                                            <label htmlFor="resolution-username">
                                                                                By Selecting 'No'
                                                                            </label>{" "}
                                                                            <br />
                                                                            You can find these applicants into the
                                                                            (Approval / Assesment / Pending Applicants) 'Assesment Pending Applicant List'
                                                                            table. <p />
                                                                            And you are able to approve the applicants
                                                                            manually, to give allowance for 'Admission
                                                                            Test' and for downloading 'Admit Card'.
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        ) : (
                                                                <div className="p-col-12 p-fluid">
                                                                    <div className="p-col-12 p-xl-12">
                                                                        <div className="p-inputgroup">
                                                                            <span className="p-inputgroup-addon font-size-12" style={{ minWidth: "250px" }}> Auto Approve for Admission Test</span>
                                                                            <Dropdown
                                                                                options={noAutoApproveOption}
                                                                                value={this.state.noAutoApprove}
                                                                                onChange={this.handlenoAutoApprove}
                                                                                filter={true}
                                                                                filterBy="label,value"
                                                                                autoWidth={false}
                                                                                showClear={true}
                                                                            />
                                                                        </div>

                                                                        <br />
                                                                    </div>
                                                                    {this.state.noAutoApprove ? (
                                                                        <div className="p-col-12 p-fluid">
                                                                            <label htmlFor="resolution-username">
                                                                                By Selecting 'Yes'
                                                                            </label>{" "}
                                                                            <br />
                                                                            You can Find these applicants automatically
                                                                            into the Admission Applicant 'Pending List'
                                                                            table. And applicants able to collect their
                                                                            'Admit Crad' from the admisia website for
                                                                            participating into the 'Admission Test'
                                                                            after completing online submission and
                                                                            payment.
                                                                        </div>
                                                                    ) : (
                                                                            <div className="p-col-12 p-fluid">
                                                                                <label htmlFor="resolution-username">
                                                                                    By Selecting 'No'
                                                                                </label>{" "}
                                                                                <br />
                                                                                You can find these applicants into the
                                                                                Admission Test 'Pending Applicant List'
                                                                                table. <p />
                                                                                And you are able to approve the applicants
                                                                                manually, to give allowance for 'Admission
                                                                                Test' and for downloading 'Admit Card'.
                                                                             </div>
                                                                        )}
                                                                </div>
                                                            )}
                                                        <div className="p-col-1"> </div>
                                                        <div className="p-col-5"> </div>
                                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                                            <div className="required-field">
                                                                (<span>*</span>) required fields
                                                            </div>

                                                            <Button
                                                                label="Save"
                                                                className="p-button p-button-primary nw-button nw-button-left"
                                                                icon="fas fa-check"
                                                                onClick={this.handleSave}
                                                            />
                                                        </div>
                                                        <div className="p-col-5"> </div>
                                                    </div>
                                                
                                            </div>
                                        </div>
                                        : "Please fill up Core Config first"
                                        }
                                    </div>
                                   
                                )}
                        </div>
                    </div>
                </div>
                {/* : null
            } */}
            </div>
        );
    }
}
