import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AuthService from './auth/AuthService'
import { ProfileService } from './service/profile/ProfileService';
import { MY_POINT } from './utils/PointWiseRouteConsts';
import { NetiFileHandler } from './utils/NetiFileHandler';

/* <START> My Point Page Import >> Kabir */

export class AppInlineProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            userData: {
            }
        };
        this.onClick = this.onClick.bind(this);

        this.AuthService = new AuthService();

        this.profileService = new ProfileService();
        this.netiFileHandler = new NetiFileHandler();

    }
    componentWillMount() {
        this.loadUserLightProfile();
    }
    onClick(event) {
        this.setState({ expanded: !this.state.expanded });
        event.preventDefault();
    }

    async loadUserLightProfile() {
        const profile = this.AuthService.getProfile();
        if (profile == null) {
            const res = await this.profileService.getUserProfileInfo();
            if (res && res.status === 302) {
                const body = await res.json();
                const stats=await this.AuthService.setProfile(body);
                const newProfile = await this.AuthService.getProfile();
                this.setState({ userData: newProfile });
               
                
            }
        } else {
            this.setState({ userData: profile});
        }
    }

    render() {
        const {userData} = this.state;
        return (
            <div className={classNames('layout-profile', { 'layout-profile-active': this.state.expanded })}>
                <button className="layout-profile-button p-link" onClick={this.onClick}>
                    {/* <img src="assets/layout/images/userAvatar.png" alt="User Photo"style={{borderRadius: '25px'}}/> */}
                    <div className="layout-profile-userinfo">
                        <span className="layout-profile-name"> {userData.customNetiID ? <span className="task-badge online"></span> : <span className="task-badge offline"></span> }Neti ID</span> {/*this.AuthService.getUserName() userProfileData.fullName*/}
                        <span className="layout-profile-role">
                        
                        {userData.customNetiID}
                        </span>
                    </div>
                    <i className="layout-profile-icon pi pi-angle-down" />
                </button>

                <ul className="layout-profile-menu nw-profile-popup">

                    <center>
                        {/* <img src="assets/layout/images/userAvatar.png" alt="User Photo" style={{borderRadius: '25px'}}/> */}
                        <img src={userData.imageContent ? userData.imageContent : "assets/layout/images/userAvatar.png"} alt="User Photo" />
                    </center>

                    <center>
                        <h2>{userData.fullName}</h2>
                    </center>

                    <div className="nw-profile-inside-double-border" />
                    {/* <li role="menuitem">
                        <Link to={MY_POINT.VIEW_PROFILE}>
                            <button className="p-link" tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="fas fa-user" />
                                <span>View Profile</span>
                            </button>
                        </Link>
                     
                    </li> */}
                    <li role="menuitem">
                        <Link to={MY_POINT.UPDATE_PROFILE}>
                            <button className="p-link" tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="fas fa-cogs" />
                                <span>Update Profile</span>
                            </button>
                        </Link>
                        {/* <Link to="/update-profile">
                            <button className="p-link" tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="pi pi-cog"/>
                                <span>Update Profile</span>
                            </button>
                        </Link> */}
                    </li>
                    <li role="menuitem">
                        <Link to={MY_POINT.CHANGE_PASSWORD}>
                            <button className="p-link" tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="pi pi-key" />
                                <span>Change Password</span>
                            </button>
                        </Link>
                    </li>
                    {/* <li role="menuitem">
                        <Link to={MY_POINT.NEW_TOKEN}>
                            <button className="p-link" tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="pi pi-lock" />
                                <span>Token</span>
                            </button>
                        </Link>
                    </li> */}

                    <div className="nw-profile-inside-double-border" />

                    {/* <br/>
                    <br/> */}



                    {/* <li role="menuitem">
                        <button className="p-link" tabIndex={this.state.expanded ? null : '-1'}>
							<i className="pi pi-bell"/>
							<span>Notifications</span>
                        </button>
                    </li> */}
                </ul>

            </div>
        )
    }
}