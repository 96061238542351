import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import { ErrorMessageView } from "../../components/common/ErrorMessageView";
import { CRMPointService } from "./../../service/crmPoint/CRMPointService";
import { PartnerPointService } from "./../../service/partnerPoint/PartnerPointService";
import { NetiDateUtils } from "../../utils/NetiDateUtils";
import { NetiFileHandler } from "../../utils/NetiFileHandler";
import { DownloadButton } from "../../utils/DownloadButton";
import NetiContentLoader from '../common/NetiContentLoader';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from 'primereact/inputtextarea';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import {Password} from 'primereact/password';
import {InputMask} from 'primereact/inputmask';



let statusListOptions = [
    { label: "Pending", value: 'pending' },
    { label: "Approve", value: 1 },
    { label: "Reject", value: 2 }
]
export class DomainApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domainErrorInfo: {},
            first: 0, 
            rows: 10, 
            errors:{},
            partnerPicture: '',
            approvalValue: 'pending',
            searchInfoObj: {
                pageNo:0
            },
            domainOrderAttachFile: "",
            domainOrderFileName: "",
            memberStatus: "",
            visible: false,
            passwordIcon: true,
            hidden: true,
            dataTableValue: [],
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: "",
            homeReturnButton: false,
            domainApproveObj:{
                domainhostingOrderDTO: {
                    domainhostingOrderID:'' 
                },
                purchaseDate: '',
                reminderDate: '',
                expiryDate: '',
                username: '',
                password: '',
                contact: '',
                email: '',
                approveNote: '',
                purchaseFrom: ''
            },
            domainDetailsInfo: {
                requestingUserBasicInfoDTO: {
                    imagePath: ''
                }
            }
        };

        this.PartnerPointService = new PartnerPointService();
        this.CRMPointService = new CRMPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.ValidatorUtility = new ValidatorUtility();
        this.getProfileImg = this.getProfileImg.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.netiContentLoader = new NetiContentLoader();
        this.viewDialog = this.viewDialog.bind(this)
        this.onHide = this.onHide.bind(this)
        this.togglePasswordShow = this.togglePasswordShow.bind(this)
    }

    componentWillMount() {
       this.viewDomainPendingList();
    }

    viewDomainPendingList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.CRMPointService.getDomainAndHostingListByStatus(0,"domain",this.state.searchInfoObj).then(res => {
                if (res.status == 302) {
                    return res.json().then(body => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].domainhostingOrderDate == null) {
                                body[i].domainhostingOrderDate = '';
                            } else {
                                body[i].domainhostingOrderDate = NetiDateUtils.getAnyShortForm(body[i].domainhostingOrderDate, 'DD-MMM-YYYY');
                            }
                        }
                        for (let k = 0; k < body.length; k++) {
                            if (body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate == null) {
                                body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate = '';
                            } else {
                                body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate = NetiDateUtils.getAnyShortForm(body[k].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
                            }
                        }
                        for (let j = 0; j < body.length; j++) {
                            if (body[j].domainhostingOrderStatus == 0) {
                                body[j].domainhostingOrderStatus = 'Pending';
                            } 
                        }
                        this.setState({ dataTableValue: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.PartnerPointService.Auth.handleErrorStatus(res).then(respon => {
                        this.setState({
                            topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: respon
                        });
                    });
                }
            })
            .catch(err => {
                this.setState({
                    topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Unable to load table"
                });
            });

    }


    actionTemplateProductName = rowData => {
        return (
            <span>
                {
                    rowData.productPurchaseCodeDTO.productPurchaseLogDTO.productInfoDTO
                        .productName
                }
            </span>
        );
    };

    onChangeStatus = (e) => {
        let { errors } = this.state;
        errors["transactionAndApproveError"] = ''
        this.setState({ errors, approvalValue: e.target.value });
    }

    onChangeReject = (e) => {
        let { domainErrorInfo, domainApproveObj } = this.state;
        domainErrorInfo["note"] = ''
        domainApproveObj.approveNote = e.target.value; 
        this.setState({ domainErrorInfo, domainApproveObj });
    }

    onChangeUserName = (e) => {
        let { domainErrorInfo, domainApproveObj } = this.state;
        domainErrorInfo["domainUsername"] = ''
        domainApproveObj.username = e.target.value; 
        this.setState({ domainErrorInfo, domainApproveObj });
    }

    onChangePassword = (e) => {
        let { domainErrorInfo, domainApproveObj } = this.state;
        domainErrorInfo["domainPassword"] = ''
        domainApproveObj.password = e.target.value; 
        this.setState({ domainErrorInfo, domainApproveObj });
    }

    onChangePurchaseDate = (e) => {
        let { domainErrorInfo, domainApproveObj } = this.state;
        domainErrorInfo["purchaseDate"] = ''
        domainApproveObj.purchaseDate = e.target.value; 
        this.setState({ domainErrorInfo, domainApproveObj });
    }

    onChangeExpiryDate = (e) => {
        let { domainErrorInfo, domainApproveObj } = this.state;
        domainErrorInfo["expireDate"] = ''
        domainApproveObj.expiryDate = e.target.value; 
        this.setState({ domainErrorInfo, domainApproveObj });
    }

    onChangeReminderDate = (e) => {
        let { domainErrorInfo, domainApproveObj } = this.state;
        domainErrorInfo["reminderDate"] = ''
        domainApproveObj.reminderDate = e.target.value; 
        this.setState({ domainErrorInfo, domainApproveObj });
    }

    onChangeDomainProvider = (e) => {
        let { domainErrorInfo, domainApproveObj } = this.state;
        domainErrorInfo["domainProvider"] = ''
        domainApproveObj.purchaseFrom = e.target.value; 
        this.setState({ domainErrorInfo, domainApproveObj });
    }

    onChangeEmail = (e) => {
        let { domainErrorInfo, domainApproveObj } = this.state;
        domainErrorInfo["email"] = ''
        domainApproveObj.email = e.target.value; 
        this.setState({ domainErrorInfo, domainApproveObj });
    }

    onChangeContact = (e) => {
        let { domainErrorInfo, domainApproveObj } = this.state;
        domainErrorInfo["contact"] = ''
        domainApproveObj.contact = e.target.value; 
        this.setState({ domainErrorInfo, domainApproveObj });
    }
    
    actionTemplateProductCode = rowData => {
        return <span>{rowData.productPurchaseCodeDTO.purchaseCode}</span>;
    };

    actionBodyTemplet = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-edit"
                tooltip="Update"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    viewDialog(rowData) {
        this.setState({domainDetailsInfo: rowData})
        this.setState({ visible: true });
        this.getProfileImg();
    }

    onHide() {
        this.setState({ visible: false })
        this.setState({ domainApproveObj:{
            domainhostingOrderDTO: {
                domainhostingOrderID:'' 
            },
            purchaseDate: '',
            reminderDate: '',
            expiryDate: '',
            username: '',
            password: '',
            contact: '',
            email: '',
            purchaseFrom: ''
        }});

    }

    getProfileImg() {

        let { domainDetailsInfo } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        if (domainDetailsInfo.requestingUserBasicInfoDTO.imagePath !== null) {
            this.netiFileHandler.getByteImage(domainDetailsInfo.requestingUserBasicInfoDTO.imagePath)
                .then((res) => {

                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(domainDetailsInfo.requestingUserBasicInfoDTO.imageName);
                                this.setState({ partnerPicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ partnerPicture: staticImg });
                    }
                });
        } else { this.setState({ partnerPicture: staticImg }) };

    }

    onSubmitUpdate = () => {
        let { domainDetailsInfo, domainApproveObj } = this.state

        if (this.onSubmitErrorUpdate()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
             domainApproveObj.domainhostingOrderDTO.domainhostingOrderID = domainDetailsInfo.domainhostingOrderID;
             this.setState({ domainApproveObj });
            this.CRMPointService.approveDomainAndHosting(this.state.approvalValue, this.state.domainApproveObj)
                .then(res => {
                    console.log(res);
                    if (res.status === 202) {
                        this.setState({ bankName: '', transactionNumber: '', approveNote: '', approvalValue: 'pending', topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Update successfully' });
                        this.setState({
                            domainApproveObj: {
                                domainhostingOrderDTO: {
                                    domainhostingOrderID: ''
                                },
                                purchaseDate: '',
                                reminderDate: '',
                                expiryDate: '',
                                username: '',
                                password: '',
                                contact: '',
                                email: '',
                                purchaseFrom: ''
                            }
                        });
                        this.onHide();
                        this.viewDomainPendingList();
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
       }
    }

    onSubmitErrorUpdate = () => {
        let { domainErrorInfo, domainApproveObj } = this.state;
        let formIsValid = true;

        if(this.state.approvalValue === 1 ) {
            if (domainApproveObj.username === '') {
                formIsValid = false;
                domainErrorInfo["domainUsername"] = "User name can't left empty.";
            }
    
            if (domainApproveObj.password === '') {
                formIsValid = false;
                domainErrorInfo["domainPassword"] = "Password can't left empty.";
            }
            if (domainApproveObj.purchaseDate === '') {
                formIsValid = false;
                domainErrorInfo["purchaseDate"] = "Purchase Date can't left empty.";
            }
            if (domainApproveObj.expiryDate === '') {
                formIsValid = false;
                domainErrorInfo["expireDate"] = "Expire Date can't left empty.";
            }
            if (domainApproveObj.reminderDate === '') {
                formIsValid = false;
                domainErrorInfo["reminderDate"] = "Reminder Date can't left empty.";
            }
            if (domainApproveObj.purchaseFrom === '') {
                formIsValid = false;
                domainErrorInfo["domainProvider"] = "Domain Provider can't left empty.";
            }
            if (domainApproveObj.email === '') {
                formIsValid = false;
                domainErrorInfo["email"] = "Email can't left empty.";
            }
            if(domainApproveObj.email !== '' && this.ValidatorUtility.emailIsValid(domainApproveObj.email) !== true) {
                formIsValid = false;
                domainErrorInfo["email"] = "Invalid Email format.";
            }
            if (domainApproveObj.contact === '') {
                formIsValid = false;
                domainErrorInfo["contact"] = "Contact no. can't left empty.";
            }
        }

        if(this.state.approvalValue === 2) {
            if (domainApproveObj.approveNote === '') {
                formIsValid = false;
                domainErrorInfo["note"] = "Note can't left empty.";
            }
        }

        this.setState({ domainErrorInfo });
        return formIsValid;
    }

    togglePasswordShow(event, props) {
        this.setState({
            hidden: !this.state.hidden,
            passwordIcon: !this.state.passwordIcon
        });

    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, domainDetailsInfo, domainApproveObj } = this.state;
        let { dataTableValue } = this.state;

        var tableHeader = (
            <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
                Domain Pending Order List
        <span style={{ float: "right" }}>
                    Total Found: {this.state.dataTableValue.length}{" "}
                </span>
            </div>
        );

        return (
            <div className="p-fluid">
                <Growl ref={el => (this.growl = el)} position="topright"></Growl>
                {topProgressBar ? (
                    <ErrorMessageView topProgressBar={topProgressBar} />
                ) : null}
                {errorMsgVisible ? (
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                ) : null}
                <Growl ref={el => (this.growl = el)} />

                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                        {this.state.dataTableIsLoading ? this.netiContentLoader.MyPointTableLoader() :
                            <div className="nw-data-table nw-data-table-tabview">
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event =>
                                        this.setState({ dataTableSelection: event.value })
                                    }
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                    columnResizeMode="fit"
                                >
                                    <Column field="domainhostingOrderDate" header="Order Date" filter={true} />
                                    <Column field="domainhostingOrderStatus" header="Order Status" filter={true} />
                                    <Column field="productPurchaseCodeDTO.productPurchaseLogDTO.productInfoDTO.productName" header="Product Name" body={this.actionTemplateProductName} filter={true} />
                                    <Column field="productPurchaseCodeDTO.purchaseCode" header="Purchase Code" filter={true} />
                                    <Column field="productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate" header="Purchase Date" filter={true} />
                                    <Column field="domainName" header="Desire URL" filter={true} />
                                    <Column field="" header="Action" body={this.actionBodyTemplet} style={{ textAlign: "center", width: "80px" }} />
                                </DataTable>
                  
                            </div>
                        }
                    </div>
                    <Dialog
                        className="nw-dialog"
                        header="Domain Request"
                        // className="nonHideDialog customDialogWidth " 
                        onHide={this.onHide}
                        visible={this.state.visible}
                        dismissableMask={true}
                        blockScroll={true}
                    >

                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">

                                    <div className="p-col-12 p-xl-12">
                                        <Fieldset legend="Requesting User Information" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                            <div className="p-col-12 p-xl-12">
                                                        <center>
                                                            <img
                                                                src={this.state.partnerPicture}
                                                                alt="partner picture"
                                                                style={{ height: "80px" }}
                                                            />
                                                        </center>
                                                    </div> 
                                                    
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Neti ID
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {domainDetailsInfo.requestingUserBasicInfoDTO && domainDetailsInfo.requestingUserBasicInfoDTO.customNetiID}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Name
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {domainDetailsInfo.requestingUserBasicInfoDTO && domainDetailsInfo.requestingUserBasicInfoDTO.fullName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Mobile No
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {domainDetailsInfo.requestingUserBasicInfoDTO && domainDetailsInfo.requestingUserBasicInfoDTO.basicMobile}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Wallet Balance
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {domainDetailsInfo.requestingUserBasicInfoDTO && domainDetailsInfo.requestingUserBasicInfoDTO.userWalletBalance ? this.ValidatorUtility.currencyFormatter(domainDetailsInfo.requestingUserBasicInfoDTO.userWalletBalance) : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </Fieldset>

                                        <Fieldset legend="" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Status</span>
                                                        <Dropdown
                                                            placeholder='Select Status'
                                                            options={statusListOptions}
                                                            value={this.state.approvalValue}
                                                            onChange={this.onChangeStatus}
                                                            filter={true}
                                                            filterBy="label,value"
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                </div>

                                                {
                                                    this.state.approvalValue === 2 ?
                                                        <div className="">
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon nw-inputtextarea-label">Reject Note <span>*</span></span>
                                                                    <InputTextarea
                                                                        name="note"
                                                                        placeholder='Enter Reject Note'
                                                                        value={domainDetailsInfo.approveNote}
                                                                        onChange={this.onChangeReject}
                                                                    />
                                                                </div>
                                                                <span className="error-message">{this.state.domainErrorInfo["note"]}</span>
                                                            </div>
                                                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                                                <Button
                                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                                    label="Submit"
                                                                    icon="fa fa-save"
                                                                    onClick={this.onSubmitUpdate}
                                                                />
                                                            </div>
                                                        </div>
                                                        : ""
                                                }

                                                {/* {
                                                    this.state.approvalValue === 1 ?
                                                        <div>

                                                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                                                <Button
                                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                                    label="Submit"
                                                                    icon="fa fa-save"
                                                                    onClick={this.onSubmitUpdate}
                                                                />
                                                            </div>
                                                        </div>
                                                        : ""
                                                } */}

                                            </div>



                                        </Fieldset>
                                        {
                                                    this.state.approvalValue === 1 ?

                                                <Fieldset legend="Input Domain Info" className="p-col-12 p-xl-12">
                                                    <div className="p-col-12 p-xl-12">

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">user name  <span>*</span></span>
                                                                <InputText
                                                                    placeholder='Enter User Name'
                                                                    name="domainUsername"
                                                                    value={domainApproveObj.username}
                                                                    onChange={this.onChangeUserName}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.domainErrorInfo["domainUsername"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">password  <span>*</span></span>
                                                                <InputText
                                                                    type={this.state.hidden ? "password" : "text"}
                                                                    placeholder='Enter Password'
                                                                    className="custom-field-control"
                                                                    name="domainPassword"
                                                                    keyfilter="alphanum"
                                                                    value={domainApproveObj.password}
                                                                    onChange={this.onChangePassword}
                                                                />
                                                                <Button
                                                                    icon={this.state.passwordIcon ? "far fa-eye-slash" : "far fa-eye"}
                                                                    className=""
                                                                    onClick={(event) => this.togglePasswordShow(event, this.props)} />

                                                            </div>
                                                            <span className='error-message'>{this.state.domainErrorInfo["domainPassword"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">purchase date  <span>*</span></span>
                                                                <Calendar
                                                                    name="purchaseDate"
                                                                    dateFormat="dd/mm/yy"
                                                                    showIcon={true}
                                                                    value={domainApproveObj.purchaseDate}
                                                                    onChange={this.onChangePurchaseDate}
                                                                    placeholder='Enter Purchase Date'
                                                                    className="custom-calender-input"
                                                                    autoWidth={false}
                                                                    yearRange="2010:2030"
                                                                    monthNavigator={true}
                                                                    yearNavigator={true}
                                                                    dateOnly="true"
                                                                    readOnlyInput={true}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.domainErrorInfo["purchaseDate"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">expire date  <span>*</span></span>
                                                                <Calendar
                                                                    name="expireDate"
                                                                    dateFormat="dd/mm/yy"
                                                                    showIcon={true}
                                                                    value={domainApproveObj.expiryDate}
                                                                    onChange={this.onChangeExpiryDate}
                                                                    placeholder='Enter Expire Date'
                                                                    className="custom-calender-input"
                                                                    autoWidth={false}
                                                                    yearRange="2010:2030"
                                                                    monthNavigator={true}
                                                                    yearNavigator={true}
                                                                    dateOnly="true"
                                                                    readOnlyInput={true}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.domainErrorInfo["expireDate"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Reminder date  <span>*</span></span>
                                                                <Calendar
                                                                    name="reminderDate"
                                                                    dateFormat="dd/mm/yy"
                                                                    showIcon={true}
                                                                    value={domainApproveObj.reminderDate}
                                                                    onChange={this.onChangeReminderDate}
                                                                    placeholder='Enter Reminder Date'
                                                                    className="custom-calender-input"
                                                                    autoWidth={false}
                                                                    yearRange="2010:2030"
                                                                    monthNavigator={true}
                                                                    yearNavigator={true}
                                                                    dateOnly="true"
                                                                    readOnlyInput={true}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.domainErrorInfo["reminderDate"]}</span>
                                                        </div>


                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Domain provider <span>*</span></span>
                                                                <InputText
                                                                    placeholder='Enter Domain Provider'
                                                                    name="domainProvider"
                                                                    value={domainApproveObj.purchaseFrom}
                                                                    onChange={this.onChangeDomainProvider}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.domainErrorInfo["domainProvider"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Email <span>*</span></span>
                                                                <InputText
                                                                    placeholder='Enter Email'
                                                                    name="email"
                                                                    type="text"
                                                                    value={domainApproveObj.email}
                                                                    onChange={this.onChangeEmail}
                                                                    keyfilter="email"
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.domainErrorInfo["email"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Contact No. <span>*</span></span>
                                                                {/* <InputText
                                                                    placeholder='Enter Contact no.'
                                                                    name="contact"
                                                                    mask="01999999999"
                                                                    keyfilter="pint"
                                                                    maxLength={9}
                                                                    value={domainApproveObj.contact}
                                                                    onChange={this.onChangeContact}
                                                                /> */}
                                                                <InputMask
                                                                    placeholder='Enter Contact'
                                                                    name="contact"
                                                                    mask="01999999999"
                                                                    keyfilter="pint"
                                                                    maxLength={9}
                                                                    value={domainApproveObj.contact}
                                                                    onChange={this.onChangeContact}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.domainErrorInfo["contact"]}</span>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                                            <Button
                                                                className="p-button p-button-primary nw-button nw-button-right"
                                                                label="Submit"
                                                                icon="fa fa-save"
                                                                onClick={this.onSubmitUpdate}
                                                            />
                                                        </div>



                                                    </div>



                                                </Fieldset>
                                               : ""
                                            }



                                    </div>

                                </div>
                            </div>
                        </div>


                    </Dialog>
                </div>
            </div>
        );
    }
}
