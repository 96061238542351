import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';
import CommonFuctionality from '../common/CommonFuctionality';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { Link } from 'react-router-dom';

let errors = {};
export class EmBillDiscountLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            dataTableIsLoading: false,
            discountNoteView: false,
            topProgressBar: false,
            discountNote: '',
            discountLogDialog: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            emDiscountBillLogList: [],
        }

        this.CommonFuctionality = new CommonFuctionality();
        this.CRMPointService = new CRMPointService();
        this.validatorUtility = new ValidatorUtility();
    }



    getEdumanDiscountBillLog = (param) => {
        this.setState({ topProgressBar: true, errorMsgBody: '', errorMsgVisible: false });
        this.CRMPointService.getEdumanBillLogByBillType("Bill Discount", param)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("em bill log body", body);
                        this.setState({emDiscountBillLogList: body});
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection.", topProgressBar: false })
            });
    }

    discountLogDialogAction = (param) => {
        this.setState({ discountLogDialog: true });
        this.getEdumanDiscountBillLog(param);
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    discountLogDialogActionHide = () => {
        this.setState({ discountLogDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    dateInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo">
                <div>
                    <div>Paid Date</div>
                    <div>:</div>
                    <div>{NetiDateUtils.getAnyShortForm(rowData.billDate, 'DD-MMM-YYYY')}</div>
                </div>

                <div>
                    <div>Bill Of</div>
                    <div>:</div>
                    <div>{rowData.emBillGenerateLogDTO.billYear} - {rowData.emBillGenerateLogDTO.billMonth}</div>
                </div>
            </div>
        );
    }
/* . ..idSearchField */
    discountInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo admin-point">
                <div className="calculation-box-wrapper">
                    <div>Discount Note</div>
                    <div>:</div>

                    <div><span onClick={e => this.discountNoteDialog(rowData.billNote)}><Link to="#">View</Link></span></div>
                    {/* {this.state.discountNoteView ? */}
                    {/* <div className="calculation-box-field idSearchField">
                    <div className="info-tooltips-wrapper">
                        <div className="info-tooltips">
                            <span className="info-tooltips-title">Neti ID Found</span>
                            <span className="msgClose" onClick={e => this.setState({ discountNoteView: false })}></span>

                            <div className="p-col-12 p-xl-12">
                                <div className="nw-search-view">
                                    <div>
                                        <div className="p-col-12 p-xl-12">
                                            <h1>note</h1>
                                            {this.state.discountNote}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    </div> */}
                    {/* : ''
                } */}
                </div>

      
            </div>
        );
    }

    discountNoteDialog = (note) => {

        this.setState({discountNoteView: true, discountNote: note});

    }

    approverInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo">
                <div>
                    <div>Neti ID</div>
                    <div>:</div>
                    <div>{rowData.productPuchaseLogDTO && rowData.productPuchaseLogDTO.userBasicInfoDTO && rowData.productPuchaseLogDTO.userBasicInfoDTO.customNetiID}</div>
                </div>
                <div>
                    <div>Name</div>
                    <div>:</div>
                    <div>{rowData.productPuchaseLogDTO && rowData.productPuchaseLogDTO.userBasicInfoDTO && rowData.productPuchaseLogDTO.userBasicInfoDTO.fullName}</div>
                </div>
            </div>
        );
    }

    discountAmount = (rowData) => {
        return (
            <div className="nw-datatable-dataview-small-panel priceInfo">
                <div>
                    <div>DISCOUNT</div>
                </div>
                <h2> <span className="symbol-taka">৳ </span>{this.validatorUtility.currencyFormatter(rowData.billAmount)}</h2>
            </div>
        );
    }



    render() {
        console.log("state emDiscountBillLogList",this.state.emDiscountBillLogList);
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let detailsTableHeader = <div className="header-title">
        <div className="header-title-left">Bill Discount Log</div>

        <div className="header-title-right">
            <Button
                className="nw-button nw-button-center p-button-success"
                name="district"
                label="Download"
                icon="fas fa-plus"
                iconPos="right"
            />
        </div>
    </div>

        return (
<>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                    <Growl ref={(el) => this.growl = el} />
                    <Dialog header="Discount Log" style={{width: '70vw'}} visible={this.state.discountLogDialog} onHide={this.discountLogDialogActionHide} className="nw-dialog product-info-dialog">
                        <br />
                        <div className="p-fluid">
                            <div className="p-col-12">
                                <div className="p-col-12 p-xl-12 nw-datatable-detail-view product-list-datatable-detail-view">
                                    <DataTable
                                        header={detailsTableHeader}
                                        selectionMode="single"
                                        responsive={true}
                                        value={this.state.emDiscountBillLogList}
                                        globalFilter={this.state.globalFilter}
                                        paginator={true}
                                        rows={5}
                                    >
                                        <Column field="" header="Date Info" body={this.dateInfo} />
                                        <Column field="" header="Discount Info" body={this.discountInfo} />
                                        <Column field="" header="Approver Info" body={this.approverInfo}/>
                                        <Column field="" header="Discount Amount" body={this.discountAmount}/>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                

</>
        )
    }
}