import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';

//custom imports
import { AdmisiaApplicationConfig } from "./AdmisiaApplicationConfig"
import { AdmisiaApplicationConfigList } from "./AdmisiaApplicationConfigList"

import { AdmisiaPointService } from '../../../../service/admisiaPoint/AdmisiaPointService';
import { DwsService } from '../../../../service/dwsPoint/DwsService';

let urlId;
export class AdmisiaApplicationConfigMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cmsId: '',
        }

        this.admisiaPointService = new AdmisiaPointService();
        this.DwsService = new DwsService();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        urlId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {
            let { cmsId } = this.state;
            this.setState({ homeReturnButton: false, cmsId })
            // this.admisiaCoreConfigInfo();
        }
        else { this.setState({ homeReturnButton: true }) }
    }

    render() {

        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    {this.state.homeReturnButton === false ?
                        <div className="p-col-12 p-lg-12 p-xl-12 nw-tabview">
                            <TabView>
                                <TabPanel header="Class Wise Config">
                                    <AdmisiaApplicationConfig />
                                </TabPanel>
                                <TabPanel header="Configuartion List" >
                                    <AdmisiaApplicationConfigList />
                                </TabPanel>
                            </TabView>
                        </div>
                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/institute_config"
                                            className="rainbow-button"
                                            alt="Go Admisia Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}