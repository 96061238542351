import { PlusOutlined } from "@ant-design/icons";
//antd
import { Upload } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Formik } from "formik";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import React, { useState } from "react";
import * as Yup from "yup";
import { AdminPointService } from "../../service/adminPoint/AdminPointService";
import { ValidatorUtility } from "../../utils/ValidatorUtility";
import getBase64 from "./OtherProductInfo";

const UpdateOtherProductInfoDetails = ({
  rowDataInfo,
  productUpdateDialog,
  growl,
  settopProgressBar,
  setdataTableIsLoading,
  seterrorMsgVisible,
  seterrorMsgBody,
  fetchOtherProductList,
}) => {
  const validatorUtility = new ValidatorUtility();
  const adminPointService = new AdminPointService();

  const [generateCode, setgenerateCode] = useState(rowDataInfo.codeGenStatus);
  const [additionalInfo, setadditionalInfo] = useState(
    rowDataInfo.productAdditionalStatus
  );
  const [togleBtnActive, setTogleBtnActive] = useState(false);
  const [salesPrice, setsalesPrice] = useState(rowDataInfo.salesPrice);
  const [vatPercent, setvatPercent] = useState(rowDataInfo.percentVat);
  const [fileList, setfileList] = useState(
    rowDataInfo.imgContent && rowDataInfo.imgContent.length
      ? [
          {
            uid: rowDataInfo.imageName,
            name: rowDataInfo.imageName,
            status: "done",
            url: rowDataInfo.imgContent.length
              ? "data:image/png;base64," + rowDataInfo.imgContent
              : "",
          },
        ]
      : []
  );
  const [imageContent, setImageContent] = useState(rowDataInfo.imgContent);
  const [imageName, setimageName] = useState(rowDataInfo.imageName);
  const [status, setstatus] = useState(0);

  console.log({ rowDataInfo });

  const handleImageChange = async ({ fileList }) => {
    setfileList(fileList);
    let imageContent = "";
    if (fileList && fileList.length) {
      imageContent = await getBase64(fileList[0].originFileObj);
      var keyw = "data:" + fileList[0].type + ";base64,"; //link will be same from the word webapps in URL
      var url = imageContent.substring(
        imageContent.indexOf(keyw) + keyw.length
      );
      setImageContent(url);
      setimageName(fileList[0].originFileObj.name);
    } else {
      setImageContent("");
      setimageName("");
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onChangeSalesPrice = (value) => {
    setsalesPrice(value);
  };

  const onChangeVatPercent = (value) => {
    setvatPercent(value);
  };

  let vatAmountCal = (salesPrice * vatPercent) / 100;

  const initialFormValues = {
    productType: rowDataInfo.productCategory,
    productTypeDefaultCode: rowDataInfo.categoryDefaultCode,
    productName: rowDataInfo.productName,
    defaultCode: rowDataInfo.productDefaultCode,
    productDetails: rowDataInfo.productDetails,
    remainingStock: rowDataInfo.remainingStock,
    sellNumber: rowDataInfo.bijoySaltNumber,
    multiNumber: rowDataInfo.bijoyMultiNumber,
    salesPrice: rowDataInfo.salesPrice,
    vatPercent: rowDataInfo.percentVat,
    videoUrl: rowDataInfo.videoUrl,
  };

  const ytVurlRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm;

  const updateFormValidationSchema = Yup.object().shape({
    productType: Yup.string()
      //   .matches(nameRegex, 'Only text is allowed')
      .label("Category Type")
      .required("Category Type is required"),
    productTypeDefaultCode: Yup.string()
      .label("Category Type Default Code")
      .required("Category Type Default Code is required"),

    productName: Yup.string()
      .label("Product Name")
      .required("Product Name is required"),

    defaultCode: Yup.string()
      //   .matches(phoneRegex, 'Invalid Phone Number')
      .label("Default Code")
      .required("Default Code is required"),

    productDetails: Yup.string()
      .label("Product Details")
      .required("Product Details is required"),

    remainingStock: Yup.number("Must be stock amount").required(
      "Stock amount is required"
    ),
    // .positive("Must be positive value"),

    sellNumber: additionalInfo
      ? Yup.string().label("Sell Number").required("Sell Number is required")
      : null,

    multiNumber: additionalInfo
      ? Yup.string().label("Multi Number").required("Multi Number is required")
      : null,

    salesPrice: Yup.number("Must be price amount").required(
      "Sales price is required"
    ),
    // .positive("Must be positive value"),

    vatPercent: Yup.number("Must be vat percentage amount"),
    //   .positive("Must be positive value"),

    videoUrl: Yup.string()
      .matches(ytVurlRegex, "Not Valid Url")
      .label("Video Url"),
  });

  const onProductUpdateSubmitHandler = (values, actions) => {
    let requestedObj = {};

    // if (this.handleError()) {
    if (true) {
      settopProgressBar(true);
      setdataTableIsLoading(true);
      seterrorMsgVisible(false);

      if (additionalInfo) {
        requestedObj = {
          // ...values,
          productId: rowDataInfo.productId,
          productDefaultCode: values.defaultCode,
          productName: values.productName,
          productDetails: values.productDetails,
          productAdditionalStatus: 1,
          remainingStock: values.remainingStock,
          salesPrice: values.salesPrice,
          percentVat: vatPercent,
          codeGenStatus: generateCode,
          bijoySaltNumber: values.sellNumber,
          bijoyMultiNumber: values.multiNumber,
          //   addedNetiId: userDetails.customNetiID,
          categoryDefaultCode: values.productTypeDefaultCode,
          productCategory: values.productType,
          imageContent: imageContent,
          imageName: imageName,
          imageSaveOrEditable: imageContent.length ? true : false,
          videoUrl: values.videoUrl,
          productStatus: status,
        };
      } else {
        requestedObj = {
          // ...values,
          productId: rowDataInfo.productId,
          productDefaultCode: values.defaultCode,
          productName: values.productName,
          productDetails: values.productDetails,
          productAdditionalStatus: 0,
          remainingStock: values.remainingStock,
          salesPrice: values.salesPrice,
          percentVat: values.vatPercent,
          codeGenStatus: generateCode,
          //   addedNetiId: userDetails.customNetiID,
          categoryDefaultCode: values.productTypeDefaultCode,
          productCategory: values.productType,
          imageContent: imageContent,
          imageName: imageName,
          imageSaveOrEditable: imageContent.length ? true : false,
          videoUrl: values.videoUrl,
          productStatus: status,
        };
      }
      adminPointService
        .updateOtherProduct(requestedObj)
        .then((res) => {
          if (res.status == 202) {
            growl.current.show({
              severity: "success",
              summary: "Success",
              detail: "Data saved successfully.",
            });

            actions.resetForm();
            fetchOtherProductList();
            settopProgressBar(false);
            setdataTableIsLoading(false);
            productUpdateDialog();
          } else {
            adminPointService.Auth.handleErrorStatus(res).then((resp) => {
              settopProgressBar(false);
              setdataTableIsLoading(false);
              seterrorMsgVisible(true);
              seterrorMsgBody(resp);
            });
          }
        })
        .catch((error) => {
          seterrorMsgVisible(true);
          settopProgressBar(false);
          setdataTableIsLoading(false);
          seterrorMsgBody("Please check your connection !!!");
        });
    }
  };

  let requiredSign = (
    <span style={{ color: "red", marginRight: "2px" }}>*</span>
  );

  const handlePreview = async (file) => {
    let a = document.createElement("a");
    a.href = file.url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    // <div className="p-fluid admin-point">
    // <div className="main-section blur-section">
    <div className="p-grid">
      <div className="p-col-12 p-xl-12 nw-form">
        <div className="p-col-12 p-xl-12">
          <div className="p-grid nw-form-body">
            <Formik
              initialValues={initialFormValues}
              onSubmit={(values, actions) =>
                onProductUpdateSubmitHandler(values, actions)
              }
              validationSchema={updateFormValidationSchema}
              validateOnBlur={false}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                isSubmitting,
                touched,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit} className="p-col-12 p-xl-12">
                  <div className="p-col-12 p-xl-12">
                    <div className="p-col-12 p-xl-12">
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Category Type
                          </span>
                          <InputText
                            id="productType"
                            name="productType"
                            placeholder="Enter Product Type"
                            value={values.productType}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("productType");
                            }}
                            type="text"
                            disabled
                          />
                        </div>
                        <span className="error-message">
                          {touched.productType && errors.productType}
                        </span>
                      </div>
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Category Default Code
                          </span>
                          <InputText
                            id="productTypeDefaultCode"
                            name="productTypeDefaultCode"
                            placeholder="Enter Product Type Default Code"
                            value={values.productTypeDefaultCode}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("productTypeDefaultCode");
                            }}
                            type="text"
                            disabled
                          />
                        </div>
                        <span className="error-message">
                          {touched.productTypeDefaultCode &&
                            errors.productTypeDefaultCode}
                        </span>
                      </div>
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Product Name
                          </span>
                          <InputText
                            id="productName"
                            name="productName"
                            placeholder="Enter Product Name"
                            value={values.productName}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("productName");
                            }}
                            type="text"
                            disabled
                          />
                        </div>
                        <span className="error-message">
                          {touched.productName && errors.productName}
                        </span>
                      </div>
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Default Code
                          </span>
                          <InputText
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("defaultCode");
                            }}
                            value={values.defaultCode}
                            placeholder="Enter Default Code"
                            type="text"
                            id="defaultCode"
                            name="defaultCode"
                            disabled
                          />
                        </div>
                        <span className="error-message">
                          {touched.defaultCode && errors.defaultCode}
                        </span>
                      </div>
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon nw-inputtextarea-label">
                            Product Details <span>*</span>
                          </span>
                          <InputTextarea
                            placeholder="Enter Product Details"
                            name="productDetails"
                            value={values.productDetails}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("productDetails");
                            }}
                          />
                        </div>
                        <span className="error-message">
                          {touched.productDetails && errors.productDetails}
                        </span>
                      </div>
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Remaining Stock {requiredSign}
                          </span>
                          <InputText
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("remainingStock");
                            }}
                            value={values.remainingStock}
                            placeholder="Enter Stock Amount"
                            type="text"
                            id="remainingStock"
                            name="remainingStock"
                          />
                        </div>
                        <span className="error-message">
                          {touched.remainingStock && errors.remainingStock}
                        </span>
                      </div>
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <div className="input-radio-button">
                            <span className="p-inputgroup-addon addon-no-style">
                              Generate Code <span>*</span>
                            </span>
                            <div className="radio-button-inside">
                              <RadioButton
                                value={1}
                                inputId="rb1"
                                onChange={(event) =>
                                  setgenerateCode(event.value)
                                }
                                checked={generateCode === 1}
                              />
                              <label
                                htmlFor="rb1"
                                className="p-radiobutton-label"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="radio-button-inside">
                              <RadioButton
                                value={0}
                                inputId="rb2"
                                onChange={(event) =>
                                  setgenerateCode(event.value)
                                }
                                checked={generateCode === 0}
                              />
                              <label
                                htmlFor="rb2"
                                className="p-radiobutton-label"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <span className="error-message">
                          {errors["generateCode"]}
                        </span>
                      </div>
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon addon-no-style">
                            Status <span>*</span>
                          </span>
                          <div className="nw-inputgroup-desc desc-no-style">
                            <div className="input-radio-button">
                              <div className="radio-button-inside">
                                <RadioButton
                                  value={1}
                                  inputId="rb1"
                                  onChange={(event) => setstatus(event.value)}
                                  checked={status == 1}
                                />
                                <label
                                  htmlFor="rb1"
                                  className="p-radiobutton-label"
                                >
                                  Enable
                                </label>
                              </div>

                              <div className="radio-button-inside">
                                <RadioButton
                                  value={0}
                                  inputId="rb2"
                                  onChange={(event) => setstatus(event.value)}
                                  checked={status == 0}
                                />
                                <label
                                  htmlFor="rb2"
                                  className="p-radiobutton-label"
                                >
                                  Disable
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon addon-no-style">
                            Additional Info<span></span>
                          </span>
                          <div className="nw-inputgroup-desc desc-no-style custom-inputswitch">
                            <span className="p-radiobutton-label">No</span>
                            <InputSwitch
                              checked={additionalInfo}
                              onChange={(e) => {
                                setadditionalInfo(e.value);
                                setTogleBtnActive(!togleBtnActive);
                              }}
                            />
                            {togleBtnActive === true ? (
                              <span
                                className="p-radiobutton-label"
                                style={{ color: "#0099FF" }}
                              >
                                Yes
                              </span>
                            ) : (
                              <span className="p-radiobutton-label">Yes</span>
                            )}
                          </div>
                        </div>
                        <span className="error-message"></span>
                      </div>
                      {additionalInfo ? (
                        <>
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Bijoy Sell Number {requiredSign}
                              </span>
                              <InputText
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("sellNumber");
                                }}
                                value={values.sellNumber}
                                placeholder="Enter Bijoy Sell Number"
                                type="text"
                                id="sellNumber"
                                name="sellNumber"
                              />
                            </div>
                            <span className="error-message">
                              {touched.sellNumber && errors.sellNumber}
                            </span>
                          </div>
                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">
                                Bijoy Multi Number {requiredSign}
                              </span>
                              <InputText
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("multiNumber");
                                }}
                                value={values.multiNumber}
                                placeholder="Enter Bijoy Sell Number"
                                type="text"
                                id="multiNumber"
                                name="multiNumber"
                              />
                            </div>
                            <span className="error-message">
                              {touched.multiNumber && errors.multiNumber}
                            </span>
                          </div>
                        </>
                      ) : null}
                      <div className="p-col-12 p-xl-12" />
                    </div>
                  </div>
                  <div className="p-col-12 p-col-nogutter">
                    <div className="p-col-12">
                      <div class="from-group-heading">
                        <span>Price Configuration</span>
                      </div>
                    </div>
                    <div className="p-col-12">
                      <div className="calculation-box-wrapper">
                        <div className="p-md-3 p-sm-12">
                          <div className="calculation-box plusSign">
                            <div className="calculation-box-title">
                              <span class="p-inputgroup-addon">
                                Sales Price <span>*</span>
                              </span>
                            </div>
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-amount">
                                {validatorUtility.currencyFormatter(salesPrice)}
                              </div>
                              <div className="calculation-box-field inputTkSign">
                                <span className="input-label">
                                  enter sales price
                                </span>
                                <InputText
                                  name="salesPrice"
                                  value={values.salesPrice}
                                  onChange={(e) => {
                                    onChangeSalesPrice(e.target.value);
                                    handleChange(e);
                                    setFieldTouched("salesPrice");
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <span className="error-message">
                            {touched.salesPrice && errors.salesPrice}
                          </span>
                        </div>
                        <div className="p-md-3 p-sm-12">
                          <div className="calculation-box">
                            <div className="calculation-box-title">
                              <span class="p-inputgroup-addon">
                                Vat Amount <span>*</span>
                              </span>
                            </div>
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-amount">
                                {validatorUtility.currencyFormatter(
                                  vatAmountCal
                                )}
                              </div>
                              <div className="calculation-box-field percentSign">
                                <span className="input-label">
                                  enter vat percent
                                </span>
                                <InputText
                                  name="vatPercent"
                                  value={values.vatPercent}
                                  onChange={(e) => {
                                    onChangeVatPercent(e.target.value);
                                    handleChange(e);
                                    setFieldTouched("vatPercent");
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <span className="error-message">
                            {touched.vatPercent && errors.vatPercent}
                          </span>
                        </div>
                        <div className="p-md-3 p-sm-12">
                          <div className="calculation-total-box">
                            <div className="calculation-box-inner-wrapper">
                              <div className="calculation-box-title">
                                <span class="p-inputgroup-addon">
                                  Total Price Including VAT
                                </span>
                              </div>
                              <div className="calculation-box-amount">
                                {validatorUtility.currencyFormatter(
                                  +salesPrice + vatAmountCal
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup nw-upload-button">
                      <span
                        style={{
                          //   height: "140px",
                          minHeight: "105px !important",
                        }}
                        className="p-inputgroup-addon nw-inputtextarea-label"
                      >
                        Upload Image
                        <br />
                        <span>(PNG/JPG/JPEG/GIF) </span>
                      </span>
                      {/* <div className="nw-upload-button-inside"> */}
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        customRequest={dummyRequest}
                        // action="//jsonplaceholder.typicode.com/posts/"
                        onChange={handleImageChange}
                        fileList={fileList}
                        accept="image/png, image/jpeg"
                        // onPreview={this.handlePreview}
                        onPreview={handlePreview}
                      >
                        {fileList.length >= 1 ? null : uploadButton}
                      </Upload>
                      {/* </div> */}
                    </div>
                    {/* <span className="error-message">
                                          {errors["logo"]}
                                        </span> */}
                    <span className="error-message">
                      {/* {this.state.proPic.contentName
                                      ? ""
                                      : errors["logo"]} */}
                    </span>
                  </div>
                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Video URL</span>
                      <InputText
                        id="videoUrl"
                        name="videoUrl"
                        placeholder="Enter Video URL"
                        value={values.videoUrl}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldTouched("videoUrl");
                        }}
                        type="text"
                      />
                    </div>
                    <span className="error-message">
                      {touched.videoUrl && errors.videoUrl}
                    </span>
                  </div>
                  <div className="required-field">
                    ({requiredSign}) required fields
                  </div>
                  <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                    <Button
                      label="Discard"
                      icon="fas fa-times"
                      className="p-button p-button-danger nw-button nw-button-multiple"
                      onClick={(e) => productUpdateDialog()}
                    />
                    <Button
                      className="p-button p-button-primary nw-button nw-button-multiple"
                      label="Update"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      icon="fas fa-check"
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
    // </div>
    // </div>
  );
};

export default UpdateOtherProductInfoDetails;
