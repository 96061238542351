import { BaseURIHolder } from '../utils/BaseURIHolder';
import AuthService from '../auth/AuthService';

export class UserOtherAccessService {
    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }
    getAccessablePoints(accessPath) {
        let uri = this.baseURIHolder.getUser() + '/'+ accessPath +'/point/types';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    loadEdumanModuleList() {
        let uri = this.baseURIHolder.getUser() + '/cs/global-info/eduman/module/list';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEdumanSelectedModuleList(moduleList) {
        let uri = this.baseURIHolder.getUser() + `/cs/global-info/eduman/module/selected-list/by/modules?modules=${moduleList}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getInstitutesByAssignedPartner() {
        let uri = this.baseURIHolder.getUser() + '/em/ac_inac/institute/by/partner';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    geEmSaModuleByEmDetailsIDAndNetiID(emID,netiID) {
        let uri = this.baseURIHolder.getUser() + `/em/sa/module/by/emDetailsIDAndNetiID?emID=${emID}&netiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findEdumanModulesByInstituteId(instituteID) {
        let uri = this.baseURIHolder.getUser() + `/sa/eduman/modules/by/institute-id?instituteId=${instituteID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findEmModulesByInstituteIdForUpdate(emID,instituteID,netiID) {
        let uri = this.baseURIHolder.getUser() + `/sa/eduman/modules/by/institute-id/for/update?emDetailsID=${emID}&instituteId=${instituteID}&saNetiID=${netiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findEdumanClassConfigurationsByInstituteID(instituteID) {
        let uri = this.baseURIHolder.getUser() + `/student/class-configurations?instituteId=${instituteID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findEdumanYearGroupCategoryByTypeID(instituteID, typeID) {
        let uri = this.baseURIHolder.getUser() + `/student/core-settings/by/type-id?instituteId=${instituteID}&typeId=${typeID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findCoreNoticesForNetiworld() {
        let uri = this.baseURIHolder.getUser() + '/nz/notices';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findMyPointDashboardInfo() {
        let uri = this.baseURIHolder.getUser() + '/my-point/dashboard/info';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    findEdumanSectionsByInstituteID(instituteID) {
        let uri = this.baseURIHolder.getUser() + `/student/class-configurations?instituteId=${instituteID}`;
       return this.Auth.getFetch(uri)
           .catch((error) => console.log('error', error));
   }

    updateEdumanInstituteInfo(instituteInfoObject){
    let uri = this.baseURIHolder.getUser() + '/em/institute/update';
    return this.Auth.putFetch(uri, instituteInfoObject)
        .catch((error) => console.log('error', error));
    } 

    updateBasicDetailsInfo(BasicDetailsInfoObject){
        let uri = this.baseURIHolder.getUser() + '/em/details-info/update';
        return this.Auth.putFetch(uri, BasicDetailsInfoObject)
            .catch((error) => console.log('error', error));
    }

    updatePaymentInfo(BasicDetailsInfoObject){
        let uri = this.baseURIHolder.getCRM() + '/em/payment-info/update';
        return this.Auth.putFetch(uri, BasicDetailsInfoObject)
            .catch((error) => console.log('error', error));
    }

    getHomeDashboardInfo() {
        let uri = this.baseURIHolder.getUser() + `/home/dashboard/info`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getHomeDashboardInfoList() {
        let uri = this.baseURIHolder.getUser() + `/dashboard`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getEdumanModuleNames(packageName ) {
        let uri = this.baseURIHolder.getUser() + `/em-modules/by?packageName=${packageName}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    
    // getEdumanInstituteNames(packageName ) {
    //     let uri = this.baseURIHolder.getUser() + `/em-modules/by?packageName=${packageName}`;
    //     return this.Auth.getFetch(uri)
    //         .catch((error) => console.log('error', error));
    // }

    findPartnerRevenueLog(searchObjInfo) {
        let uri = this.baseURIHolder.getUser() + '/report/revenue/log';
        return this.Auth.postFetch(uri,searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    getUserPurchaseDetailsByPurchaseID(purchaseID) {
        let uri = this.baseURIHolder.getUser() + `/report/purchase/details?purchaseID=${purchaseID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    sendAnySMS(sendObj, urlName) {
        let uri = this.baseURIHolder.getUser() + urlName;
        return this.Auth.postFetch(uri,sendObj)
            .catch((error) => console.log('error', error));
    }

    findAllBankBranches(bankID,districtID) {
        let uri = this.baseURIHolder.getUser() + `/bank/branches/by?bankID=${bankID}&districtID=${districtID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createUserBankAccount(searchObjInfo) {
        let uri = this.baseURIHolder.getUser() + '/bank/add-account';
        return this.Auth.postFetch(uri,searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    findBankAccountByUser() {
        let uri = this.baseURIHolder.getUser() + `/bank/accounts/by/user`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    tagUserBankAccount(requestObjInfo) {
        let uri = this.baseURIHolder.getUser() + '/bank/account/tag';
        return this.Auth.postFetch(uri,requestObjInfo)
            .catch((error) => console.log('error', error));
    }

    userBankTaggingInfo(bankAccountID) {
        let uri = this.baseURIHolder.getUser() + `/bank/account/taggings/by?bankAccountID=${bankAccountID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchUserBankList() {
        let uri = this.baseURIHolder.getUser() + `/banks`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserBankAccountsInfo(bankID) {
        let uri = this.baseURIHolder.getUser() + `/bank/accounts/get?bankID=${bankID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserBankTaggedList() {
        let uri = this.baseURIHolder.getUser() + `/bank/account/taggings`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

      getTaggedBankAccountInfoByDefcode(coreCategoryDefaultCode) {
        let uri = this.baseURIHolder.getUser() + `/tagged/bank-account/by?coreCategoryDefaultCode=${coreCategoryDefaultCode}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateBankBranch(requestObjInfo){
        let uri = this.baseURIHolder.getUser() + '/bank-account/branch/update';
        return this.Auth.postFetch(uri, requestObjInfo)
            .catch((error) => console.log('error', error));
    }

    sendSingleSMS(sendObj, urlName) {
        let uri = this.baseURIHolder.getUser() + urlName;
        return this.Auth.postFetch(uri,sendObj)
            .catch((error) => console.log('error', error));
    }

    createMeeting(requestObjInfo) {
        let uri = this.baseURIHolder.getUser() + '/zoom/call';
        return this.Auth.postFetch(uri,requestObjInfo)
            .catch((error) => console.log('error', error));
    }

    searchActiveDoctorByNetiID(customNetiID) {
        let uri = this.baseURIHolder.getUser() + `/doctor/room-id/by?customNetiID=${customNetiID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserMedicomSession(requestObjInfo) {
        let uri = this.baseURIHolder.getUser() + '/medicom/session';
        return this.Auth.postFetch(uri,requestObjInfo)
            .catch((error) => console.log('error', error));
    }

    sendSmsBodyFromUserEnd(requestObjInfo) {
        let uri = this.baseURIHolder.getUser() + '/send/sms/singl/body-by-user';
        return this.Auth.postFetch(uri,requestObjInfo)
            .catch((error) => console.log('error', error));
    }

    createUserProfileBankAccount(searchObjInfo) {
        let uri = this.baseURIHolder.getUser() + '/bank/account/add/and/tag';
        return this.Auth.postFetch(uri,searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    updateUserProfileBankAccount(searchObjInfo) {
        let uri = this.baseURIHolder.getUser() + '/bank/account/tagging/udate';
        return this.Auth.putFetch(uri,searchObjInfo)
            .catch((error) => console.log('error', error));
    }

    getMainDashboardInfo() {
        let uri = this.baseURIHolder.getUser() + `/dashboard`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

}
