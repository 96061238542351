import { EM_USER_POINT } from './PointWiseRouteConsts';
import { StudentProfile } from '../components/emUserPoint/studentPortal/StudentProfile';
import { MyPointMenu } from './MyPointMenu';

export const EmMerchantPointMenu = {

    items: [
        

        {
            label: "Merchant Portal", icon: "fas fa-user-tie",
            items: [
                { label: 'Student Fee Collection', icon: '', to: '/marchant'},   
            ]
            
        }
    ]
        
}