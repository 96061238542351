import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService'

export class SaCoreSettingsService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

//*******************Student Info summary *********************/

    getGlobalInfoByTypeId(typeId) {

        let uri = this.baseURIHolder.getUser()+'/sa/find/global-info/list/by/type-id?typeId='+typeId;

        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    console.log('No date found this Redirect Api ', uri);
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }
}