import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from 'primereact/growl';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import { EmAdminPointService } from "../../service/emAdminPoint/EmAdminPointService";
import { Dropdown } from "primereact/dropdown";
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { UserCategoryService } from '../../service/UserCategoryService';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';

import CommonFuctionality from "../common/CommonFuctionality";
import { FileUpload } from 'primereact/fileupload';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { DownloadButton } from '../../utils/DownloadButton';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { NetiDateUtils } from '../../utils/NetiDateUtils';


let taggingTypeList = ['Eduman', 'Admisia', 'Withdraw']


export class ObserverpointTagging extends Component {

  constructor(props) {
    super(props);
    this.state = {
      areaList:[],
      areaName: '',
      layerTypeList: [],
      observerTaggingInfo: {},
      observerType: '',
      searchView: false,
      searchViewError: false,
      updateLayerType: '',
      buttonDisabled: true,
      accountTaggingList: [],
      bankTaggingInfo: {},
      taggingTypeErr: {},
      taggingType: '',
      customNetiID: '',
      userBasicInfo: {
          netiID: '',
          basicMobile: ''
      },
      bankName: "",
      attachFileObj: {
        extention: '',
        contentPic: '',
        contentName: '',
      },
      updateFormerrors: {},
      defaultId: "",
      note: "",
      layerType:'',
      district: '',
      branchId: '',
      branchList: [],
      updateAreaName: '',
      branchInfo: {},
      branchName: '',
      accountNumber: '',
      accountHolder: '',
      districtList: [],
      accountType: '',
      accountDetails: '',
      dataTableValue: [],
      userBankDetails: {},
      errors: {},
      updateErrors: {},
      firstDropDownIsLoading: true,
      secondDropdownIsLoading: false,
      thirdDropdownIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',
    };

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.actionBody = this.actionBody.bind(this);

    this.viewDialog = this.viewDialog.bind(this);
    this.onHide = this.onHide.bind(this);


    this.supportPointService = new SupportPointService();
    this.emAdminPointService = new EmAdminPointService();
    this.NetiContentLoader = new NetiContentLoader();
    this.userCategoryService = new UserCategoryService();
    this.commonFuctionality = new CommonFuctionality();
    this.userOtherAccessService = new UserOtherAccessService();
    this.netiFileHandler = new NetiFileHandler();
    this.CRMPointService = new CRMPointService();
  }

  componentDidMount() {
    this.fetchObserverTypeList();
    this.fetchObserverPointTaggingList();
    // this.getDistrictList();
  }

  getDistrictList() {
    this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
    this.userCategoryService.fetchListByDefaultCode('T10203')
      .then(res => {
        if (res.status == 302) {
          return res.json().then((body) => {
            this.setState({ districtList: body, topProgressBar: false, secondDropdownIsLoading: false });
          });
        } else {
          this.userCategoryService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
            });
        }
      }).catch(error => {
        this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
      });
  }

  fetchObserverTypeList() {
    this.setState({ firstDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false })
    this.userCategoryService.fetchListByDefaultCode('T124')
      .then(res => {
        if (res.status === 302) {
          this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          return res.json().then((body) => { this.setState({ observerTypeList: body }) })
        } else {
          this.CRMPointService.Auth.handleErrorStatus(res)
          .then((resp) => {
            this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
          });
        };
      }).catch(error => {
        this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }

  onChangeObserverType = e => {
    this.setState({ observerType: e.target.value, errors: {} })
  };


  onChangeArea = e => {
    this.setState({ areaName: e.target.value, errors: {} })
    // if (this.state.branchList && this.state.branchList.length) {
    //   for (let i = 0; i < this.state.branchList.length; i++) {
    //     if (this.state.branchList[i].branchID === e.target.value) {
    //       this.setState({ routingNumber: this.state.branchList[i].routingNumber });
    //     }
    //   }
    // }
  }

  onChangeAccountNumber = e => {
    this.setState({ accountNumber: e.target.value, errors: {} })
  }

  onChangeAccountHolderName = e => {
    this.setState({ accountHolder: e.target.value, errors: {} })
  };

  onChangeAccountType = e => {
    this.setState({ accountType: e.target.value, errors: {} })
  };

  onChangeNote = e => {
    this.setState({ note: e.target.value, errors: {} })
  };

  onChangeLayerType = (e) => {
    this.setState({ layerType: e.target.value, errors: {} })
    this.fetchAreaListByTypeDefCode(e.target.value);
  }

  onChangeCustomNetiID = (e) => {
    let { errors } = this.state;
    errors['netiID'] = '';
    this.setState({ errors })
    this.setState({ customNetiID: e.target.value });
}

onSearchCustomNetiID = () => {
  let { userBasicInfo } = this.state;
  userBasicInfo.netiID = userBasicInfo.basicMobile = null;
  this.setState({ userBasicInfo, buttonDisabled: true, edumanTaggedIDbtnnDisabled: true });

  if (this.onSearchCustomNetiIDErrorHandle()) {
      this.setState({ topProgressBar: true, searchView: true, searchViewError: false, searchUserDataIsLoading: true, errorMsgVisible: false });
      this.CRMPointService.findUserForObserverTagging(this.state.customNetiID, 'ROLE_SA')
          .then(res => {
              if (res.status == 302) {
                  return res.json().then(body => {
                      this.setState({ edumanTaggedIDbtnnDisabled: false, buttonDisabled: false, userBasicInfo: body, topProgressBar: false, searchUserDataIsLoading: false, errorMsgVisible: false });
                  });
              } else {
                  this.CRMPointService.Auth.handleErrorStatus(res)
                      .then((resp) => {
                          this.setState({ searchViewError: true, searchViewErrorMsg: resp, topProgressBar: false, searchUserDataIsLoading: false })
                      });
              }
          }).catch((error) => {
              this.setState({ topProgressBar: false, searchView: false, searchUserDataIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
          });
  }
}

onSearchCustomNetiIDErrorHandle = () => {
  let { errors, customNetiID } = this.state;
  let formIsValid = true;
  this.setState({ searchView: false })

  if (!this.state.customNetiID) {
      formIsValid = false;
      errors['netiID'] = 'Neti ID can\'t left empty'
  }

  if(customNetiID.length < 10){
    formIsValid = false;
    errors['netiID'] = 'Neti ID is too sort'
  }

  if(customNetiID.length < 10 && customNetiID == ''){
    formIsValid = false;
    errors['netiID'] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10'
  }

  this.setState({ errors })

  return formIsValid;
}


  onSubmitErrorHandle = () => {
    let { errors } = this.state;
    let formIsValid = true;

    if (this.state.observerType === '') {
      formIsValid = false;
      errors['observerType'] = "Observer Type can't left empty."
    }
    if (this.state.layerType === '') {
      formIsValid = false;
      errors['layerType'] = "Layer Type can't left empty."
    }
    if (this.state.areaName === '') {
      formIsValid = false;
      errors['areaName'] = "Area Name can't left empty."
    }
    if (this.state.note === '') {
      formIsValid = false;
      errors['note'] = "Note can't left empty."
    }
  
    if (this.state.attachFileObj.contentName === '') {
      formIsValid = false;
      errors['attachment'] = "Attachment can't left empty."
    }

    this.setState({ errors });
    return formIsValid;
  }

  onChangeTaggingType = (e) => {
    this.setState({ taggingType: e.target.value });
  }

  actionBody(rowData) {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button edit"
          icon="fas fa-pencil-alt"
          title="Update"
          onClick={e => this.viewDialog(rowData)}
        />
      </div>
    );
  }

  viewDialog(rowData) {
    console.log("rowData",rowData.coreObserverTaggingDTO.areaCoreCategoryInfoDTO.categoryName );
    this.setState({ observerTaggingInfo: rowData });
    this.setState({updateAreaName:rowData.coreObserverTaggingDTO.areaCoreCategoryInfoDTO.coreCategoryID });
    this.setState({updateLayerType:rowData.coreObserverTaggingDTO.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode });
    this.setState({ visible: true });
  }

  // value={observerTaggingInfo && observerTaggingInfo.coreObserverTaggingDTO && observerTaggingInfo.coreObserverTaggingDTO.areaCoreCategoryInfoDTO && observerTaggingInfo.coreObserverTaggingDTO.areaCoreCategoryInfoDTO.parentTypeInfoDTO && observerTaggingInfo.coreObserverTaggingDTO.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryName}
  onUpdateLayerType = (e) => {
    this.setState({updateLayerType: e.target.value, updateFormerrors:{}});
    this.fetchAreaListByTypeDefCode(e.target.value);

  }

  onUpdateArea = (e) => {
    this.setState({updateAreaName: e.target.value, updateFormerrors:{}});

  }

  onHide(event) {
    this.setState({ visible: false });
  }

  onSubmitHandler() {
    let { attachFileObj, userBasicInfo } = this.state;
    if (this.onSubmitErrorHandle()) {
      this.setState({ topProgressBar: true, errorMsgVisible: false })
      let attachFileExtention = this.netiFileHandler.getImageExtention(attachFileObj.extention);
      let requestedObj = {
        'userRoleAssignDTO': {
          'userRoleAssignID': userBasicInfo && userBasicInfo.userRoleAssignDTO && userBasicInfo.userRoleAssignDTO.userRoleAssignID
        },
        'layerDefaultCode': this.state.layerType,
        'taggingNote': this.state.note,
        'observerTypeCoreCategoryInfoDTO': {
          'coreCategoryID': this.state.observerType
        },
        "attachmentEditable": true,
        "attachmentName": Date.now() + attachFileExtention,
        "attachmentContent": attachFileObj.contentPic,
        "areaCoreCategoryInfoDTO": {
          "coreCategoryID": this.state.areaName
        }

      }
      this.CRMPointService.tagCoreObserverPoint(requestedObj)
        .then(res => {
          if (res.status === 201) {
            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully submitted' });
            this.setState({ areaName: '', observerType: '', layerType: '', customNetiID: '', note: '',userBasicInfo: { } });
            this.fetchObserverPointTaggingList();
            this.setState({ attachFileObj: { contentPic: '', contentName: '' } });
            this.setState({ topProgressBar: false, errorMsgVisible: false })
          } else {
            this.CRMPointService.Auth.handleErrorStatus(res)
              .then((resp) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
              });
          }
        }).catch(error => {
          this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
        })
    }

  }

  fetchObserverPointTaggingList = () => {
    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    this.CRMPointService.findAllCoreObserverTaggings()
      .then(res => {
        if (res.status === 302) {
          return res.json().then((body) => {
            if(body.length !== 0) {
              this.setState({ dataTableValue: body })
              this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
            } else {
              this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Desired datatable list is empty' });
            }
           
          })
        } else {
          this.userOtherAccessService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
            });
        };
      }).catch(error => {
        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }

  getUserBankAccountTaggingInfo = (param) => {
    this.setState({ topProgressBar: true, errorMsgVisible: false });
    this.userOtherAccessService.userBankTaggingInfo(param)
      .then(res => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({ bankTaggingInfo: body })
            this.setState({ topProgressBar: false, errorMsgVisible: false });
          })
        } else {
          this.userOtherAccessService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
            });
        };
      }).catch(error => {
        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }

  fetchAreaListByTypeDefCode(param) {
    this.setState({ thirdDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    this.userCategoryService.fetchListByDefaultCode(param)
      .then(res => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({ areaList: body })
            this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          })
        } else {
          this.userCategoryService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
            });
        };
      }).catch(error => {
        this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }


  onSubmitUpdate = () => {
    let {observerTaggingInfo} = this.state;
    this.setState({ topProgressBar: true, errorMsgVisible: false })
    if (this.onUpdateErrorHandle()) {
      let requestedObj = {
        'areaCoreCategoryInfoDTO': {
          'coreCategoryID': this.state.updateAreaName
        },
        "observerTagId": observerTaggingInfo && observerTaggingInfo.coreObserverTaggingDTO && observerTaggingInfo.coreObserverTaggingDTO.observerTagId
      }
      this.CRMPointService.updateCoreObserverTagging(requestedObj)
        .then(res => {
          if (res.status === 202) {
            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully updated.' });
            this.setState({ visible: false });
            this.setState({ topProgressBar: false, errorMsgVisible: false })
            this.fetchObserverPointTaggingList();
          } else {
            this.CRMPointService.Auth.handleErrorStatus(res)
              .then((resp) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
              });
          };
        }).catch(error => {
          this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
        })
    }
  }

  onUpdateErrorHandle = () => {
    let { updateFormerrors } = this.state;
    let formIsValid = true;

    if (this.state.updateAreaName === '') {
      formIsValid = false;
      updateFormerrors['updateAreaName'] = "Area name can't left empty."
    }

    this.setState({ updateFormerrors });
    return formIsValid;
  }



  onFileUpload(e) {
    let { errors } = this.state;
    errors['attachment'] = ''

    var reader = new FileReader();
    let photo = e.files[0];
    const scope = this
    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      let fileObj = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name
      };
      scope.setState({ attachFileObj: fileObj });
    }
  }


  attachmentRemoveBtnclick = () => {
    this.setState({ attachFileObj: { contentPic: '' } });
    this.setState({ attachFileObj: { contentName: '' } });
  }

 

  onChangeTagging = (e) => {
    let {bankTaggingInfo} = this.state;
    let selectedTaggingValue = [...bankTaggingInfo.taggingTypes];
    if(e.checked) {
      selectedTaggingValue.push(e.value);
    } else {
      selectedTaggingValue.splice(selectedTaggingValue.indexOf(e.value), 1);
    }
    bankTaggingInfo.taggingTypes = selectedTaggingValue
    this.setState({bankTaggingInfo});
  }

  render() {
    console.log("this.state.areaItemList",this.state.areaItemList);
    let { topProgressBar,observerTaggingInfo, errorMsgVisible, errorMsgBody, userBankDetails, bankTaggingInfo, userBasicInfo } = this.state;
    let formatContactNo = (userBasicInfo.basicMobile ? userBasicInfo.basicMobile.replace(/-/g, '').replace(/\s/g, '').replace(/[+]/g, '') : '');
    let taggingDate = (userBasicInfo.taggingDate ? NetiDateUtils.getAnyShortForm(userBasicInfo.taggingDate, 'DD-MMM-YYYY') : '');

    let layerOptions = [
      { label: 'Country', value: 'T10201' },
      { label: 'Division', value: 'T10202' },
      { label: 'District', value: 'T10203' },
      { label: 'Upazilla', value: 'T10204' }
    ];

    let observerOptions = []
    if (this.state.observerTypeList && this.state.observerTypeList.length) {
      observerOptions = this.state.observerTypeList.map(item => ({ label: item.categoryName, value: item.coreCategoryID }))
    }

    let districtItemList = []
    if (this.state.districtList && this.state.districtList.length) {
      districtItemList = this.state.districtList.map((item) => ({
        value: item.coreCategoryID,
        label: item.categoryName,
      }));
    }

    let areaItemList = []
    if (this.state.areaList && this.state.areaList.length) {
      areaItemList = this.state.areaList.map((item) => ({
        value: item.coreCategoryID,
        label: item.categoryName,
      }));
    }



    let headervalue = (
      <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
        Observer Tagging List <span style={{ float: "right" }}>Total Found: {this.state.dataTableValue.length}</span>
      </div>
    );

    return (
      <div className="p-fluid">
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <Growl ref={(el) => this.growl = el} />
        <div className="p-grid nw-form">
          <div className="p-col-12 p-xl-12">
            <div className="nw-form-body">
            <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Neti ID <span>*</span></span>
                                            <InputText
                                                value={this.state.customNetiID}
                                                onChange={this.onChangeCustomNetiID}
                                                placeholder="Enter Neti ID"
                                                style={{ width: "15%" }}
                                                type="text"
                                                keyfilter="pint"
                                                name="netiID"
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                label={this.state.searchLabel}
                                                icon="fas fa-search"
                                                onClick={this.onSearchCustomNetiID}
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors['netiID']}</span>
                                    </div>
                                    {this.state.searchView ?
                                        <div className="p-col-12 p-xl-12">

                                            <div className="nw-search-view">

                                                {this.state.searchViewError ?
                                                    <center className="error-message">{this.state.searchViewErrorMsg || 'No Data Found'}</center> :
                                                    <div>

                                                        {this.state.searchUserDataIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {userBasicInfo && userBasicInfo.userBasicInfoDTO && userBasicInfo.userBasicInfoDTO.fullName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                            {this.state.searchUserDataIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Area</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {userBasicInfo && userBasicInfo.userRoleAssignDTO && userBasicInfo.userRoleAssignDTO.partnerAssignInfoDTO && userBasicInfo.userRoleAssignDTO.partnerAssignInfoDTO.partnerAreaInfoDTO && userBasicInfo.userRoleAssignDTO.partnerAssignInfoDTO.partnerAreaInfoDTO.categoryName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.searchUserDataIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Mobile No.</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {userBasicInfo && userBasicInfo.userBasicInfoDTO && userBasicInfo.userBasicInfoDTO.basicMobile}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    

                                                        {this.state.searchUserDataIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Tagging Date</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {taggingDate || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                    </div>
                                                }
                                            </div>



                                        </div>
                                        :
                                        ''
                                    }


              <div className="p-col-12 p-xl-12">
                {this.state.firstDropDownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Observer Type <span>*</span></span>
                    <Dropdown
                      placeholder="Select Observer Type"
                      options={observerOptions}
                      name="observerType"
                      onChange={this.onChangeObserverType}
                      value={this.state.observerType}
                      autoWidth={false}
                    />
                  </div>
                }
                <span className='error-message'>{this.state.errors['observerType']}</span>
              </div>

              <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                {this.state.secondDropdownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Layer Type <span>*</span></span>
                    <Dropdown
                      placeholder="Enter Layer Type"
                      onChange={this.onChangeLayerType}
                      value={this.state.layerType}
                      options={layerOptions}
                      autoWidth={false}
                      name="layerType"
                      showClear={true}
                    />
                  </div>
                }
                <span className="error-message">
                  {this.state.errors["layerType"]}
                </span>
              </div>

              <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                {this.state.thirdDropdownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Area <span>*</span></span>
                    <Dropdown
                      placeholder="Enter Area"
                      onChange={this.onChangeArea}
                      value={this.state.areaName}
                      options={areaItemList}
                      filter={true}
                      autoWidth={false}
                      name="areaName"
                      showClear={true}
                    />
                  </div>
                }
                <span className="error-message">
                  {this.state.errors["areaName"]}
                </span>
              </div>

              {/* <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">Routing Number<span>*</span></span>
                  <InputText
                    type="text"
                    className='disable'
                    disabled
                    value={this.state.routingNumber}
                  />
                </div>
                <span className='error-message'>{this.state.errors['branchId']}</span>
              </div> */}

           
              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                  <InputTextarea
                    type="text"
                    placeholder="Enter Note"
                    name="note"
                    onChange={this.onChangeNote}
                    value={this.state.note}
                    maxlength={120}
                  />
                </div>
                <span className='error-message'>{this.state.errors['note']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup nw-upload-button">
                  <span className="p-inputgroup-addon nw-inputtextarea-label">
                    Attachment <span>*</span>
                    <br />
                    <span>(PNG/JPG/PDF)</span>
                  </span>
                  <div className="nw-upload-button-inside">
                    {
                      this.state.attachFileObj.contentPic ?
                        <div className="image-view-main">
                          <div className="upload-image-view">
                            <Button
                              className="delete-upload-button"
                              icon="fas fa-times-circle"
                              onClick={this.attachmentRemoveBtnclick}
                            />
                            {this.state.attachFileObj.contentName ?
                              this.commonFuctionality.getFileContentTypeIcon(this.state.attachFileObj.contentName) : ''}
                          </div>
                          <div className="image-title">{this.state.attachFileObj.contentName}</div>
                        </div>
                        :
                        <FileUpload
                          id="attachments"
                          mode="basic"
                          maxFileSize={1000000}
                          name="attachment"
                          onSelect={this.onFileUpload.bind(this)}
                          auto={true}
                          chooseLabel={this.state.attachFileObj.contentName ? this.state.attachFileObj.contentName : "Click / Drag Here To Upload"}
                        />
                    }
                  </div>
                </div>
                <center><span className='error-message'>{this.state.attachFileObj.contentName === '' && this.state.errors["attachment"]}</span></center>
              </div>

           

              <div className="p-col-12 p-xl-12" />
              <div className="p-col-12 p-xl-12 nw-button-parent">
                <div className="required-field">
                  (<span>*</span>) required fields
                </div>
                <Button
                  className="p-button p-button-primary nw-button nw-button-right"
                  label="Save"
                  icon="fas fa-check"
                  disabled={this.state.buttonDisabled}
                  onClick={this.onSubmitHandler}
                />
              </div>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-lg-3 p-xl-3"></div>

          <div className="p-col-12 p-xl-12">
            <div className="nw-data-table nw-data-table-tabview">
              {this.state.dataTableIsLoading ?
                this.NetiContentLoader.MyPointTableLoader() :
                <DataTable
                  header={headervalue}
                  value={this.state.dataTableValue}
                  responsive={true}
                  paginator={true}
                  rows={10}
                >
                  <Column field="pointExpiryInfoResponseDTO.netiId" header="Neti ID" sortable={true} filter={true} />
                  <Column field="pointExpiryInfoResponseDTO.name" header="Name" sortable={true} filter={true} />
                  <Column field="coreObserverTaggingDTO.observerTypeCoreCategoryInfoDTO.categoryName" header="Observer Type" sortable={true} filter={true} />
                  <Column field="coreObserverTaggingDTO.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryName" header="Layer Type" sortable={true} filter={true} />
                  <Column field="coreObserverTaggingDTO.areaCoreCategoryInfoDTO.categoryName" header="Area" sortable={true} filter={true} />
                  <Column header="Action" body={this.actionBody} />
                </DataTable>
              }
            </div>
          </div>

          <Dialog header="Observer Point Tagging (Update)" className="nw-dialog" visible={this.state.visible} onHide={this.onHide}>
            <div className="p-fluid">
              <div className="p-grid nw-form">
              <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Observer Type <span>*</span></span>
                    <InputText
                      placeholder="Select Observer Type"
                      value={observerTaggingInfo && observerTaggingInfo.coreObserverTaggingDTO && observerTaggingInfo.coreObserverTaggingDTO.observerTypeCoreCategoryInfoDTO && observerTaggingInfo.coreObserverTaggingDTO.observerTypeCoreCategoryInfoDTO.categoryName}
                      autoWidth={false}
                      disabled
                    />
                  </div>
              </div>

              <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
       
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Layer Type<span>*</span></span>
                    <Dropdown
                      placeholder="Enter Layer Type"
                      onChange={this.onUpdateLayerType}
                      value={this.state.updateLayerType}
                      options={layerOptions}
                      autoWidth={false}
                      showClear={true}
                    />
                  </div>
              </div>

              <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Area<span>*</span></span>
                    <Dropdown
                      placeholder="Enter Area"
                      onChange={this.onUpdateArea}
                      value={this.state.updateAreaName}
                      options={areaItemList}
                      filter={true}
                      autoWidth={false}
                      name="updateAreaName"
                      showClear={true}
                    />
                  </div>
                <span className="error-message">
                  {this.state.updateFormerrors["updateAreaName"]}
                </span>
              </div>
              <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            label="Update"
                                                            icon="far fa-save"
                                                            onClick={e => this.onSubmitUpdate(e)}
                                                        />
                                                    </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
