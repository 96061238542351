import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { Card } from 'primereact/card';
import Chart from 'react-google-charts';

let errors = {};
let areaDatatableValueTree={};
let areaDatatableValueTreeNext=[];
let assignedPartnerListArr=[];

let areaList=[];
let areaListTwo=[];

        
export class MPPartnerDashboardMBPType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // areaDatatableValue: [
            //     {area: "", zone: "Dhaka Zone", partnerType: "MBP"},
            //     {area: "", zone: "Bogura Zone", partnerType: "MBP"},
            //     {area: "", zone: "Dhaka Zone", partnerType: "MBP"},
            //     {area: "", zone: "Dhaka Zone", partnerType: "MBP"},
            // ],
            onExpander: true,
            errorMsgVisible: false,
            errorMsgBody: '',
            errors: {},
        }
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount(){
        areaList=[
            {area: "Region", zone: "", partnerType: ""},
            {area: "Zone", zone: "", partnerType: ""},
            {area: "District", zone: "", partnerType: ""},
            {area: "Area", zone: "", partnerType: ""},
        ];
        areaList.map((item, i) => {
            areaDatatableValueTree.data = item;
            areaDatatableValueTree.children = []
            areaDatatableValueTree.leaf = false
            areaDatatableValueTree.key = i
            assignedPartnerListArr.push(Object.assign({}, areaDatatableValueTree))
        })
    }

    onExpandToggle = (e) => {
        this.setState({ expandedKeys: e.value })
    }

    searchAreaInfo = (lazyNode, objectName, isLeaf) => {
        let areaDatatableValue= [
            {area: "", zone: "Dhaka Zone", partnerType: "MBP"},
            {area: "", zone: "Bogura Zone", partnerType: "MBP"},
            {area: "", zone: "Dhaka Zone", partnerType: "MBP"},
            {area: "", zone: "Dhaka Zone", partnerType: "MBP"},
        ];

        if (lazyNode && lazyNode.children.length === 0) {
            // areaDatatableValue.map((item, i) => {
            //     objectName.data = item;
            //     objectName.children = [];
            //     objectName.leaf = isLeaf
            //     objectName.key = `${lazyNode['key']}-${i}`
            //     lazyNode.children.push(Object.assign({}, objectName))
            // })
        }

    }

    onExpand(event) {
        // let { areaDatatableValue } = this.state;
        let lazyNode = { ...event.node };
        console.log("EVENT", event);
        // this.searchAreaInfo(lazyNode, areaDatatableValueTreeNext, true)

        areaListTwo=[
            {area: "", zone: "Dhaka Zone", partnerType: "MBP"},
            {area: "", zone: "Bogura Zone", partnerType: "MBP"},
            {area: "", zone: "Dhaka Zone", partnerType: "MBP"},
            {area: "", zone: "Dhaka Zone", partnerType: "MBP"},
        ];
        areaListTwo.map((item, i) => {
            areaDatatableValueTree.data = item;
            areaDatatableValueTree.children = []
            areaDatatableValueTree.leaf = false
            areaDatatableValueTree.key = i
            lazyNode.children.push(Object.assign({}, areaDatatableValueTree))
        })
    }

    teamMemberInfo = (partnerType) =>{

        return(
            <React.Fragment>
                <Card footer={ partnerType + " Info"}>
                   
                    <div class="nw-search-view no-border">
                        <div>
                            <div class="p-grid p-col-12">
                                <div className="p-col-12 p-xl-12">
                                    {/* {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() : */}
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Total { partnerType }</span>
                                            <span className="p-inputgroup-colon">:</span>

                                            <div className="nw-inputgroup-desc">
                                                {/* {this.state.productOfferObj.productName || "-"} */}
                                            </div>
                                        </div>
                                    {/* } */}
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {/* {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() : */}
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Total { partnerType } Area</span>
                                            <span className="p-inputgroup-colon">:</span>

                                            <div className="nw-inputgroup-desc">
                                                {/* {this.state.productOfferObj.productName || "-"} */}
                                            </div>
                                        </div>
                                    {/* } */}
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {/* {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() : */}
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Total Assign Area</span>
                                            <span className="p-inputgroup-colon">:</span>

                                            <div className="nw-inputgroup-desc">
                                                {/* {this.state.productOfferObj.productName || "-"} */}
                                            </div>
                                        </div>
                                    {/* } */}
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {/* {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() : */}
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Total Not Assign Area</span>
                                            <span className="p-inputgroup-colon">:</span>

                                            <div className="nw-inputgroup-desc">
                                                {/* {this.state.productOfferObj.productName || "-"} */}
                                            </div>
                                        </div>
                                    {/* } */}
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {/* {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() : */}
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Total Suspened Area</span>
                                            <span className="p-inputgroup-colon">:</span>

                                            <div className="nw-inputgroup-desc">
                                                {/* {this.state.productOfferObj.productName || "-"} */}
                                            </div>
                                        </div>
                                    {/* } */}
                                </div>
                            </div>
                        </div>
                    </div>

                </Card>
            </React.Fragment>
        )
    }
    
    render() {
        let { regionDatatableValue, zoneDatatableValue, districtDatatableValue, areaDatatableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let dataTableHeader = <div className="header-title">
                                <div className="header-title-left">
                                    Unallocated Partner Area
                                </div>


                                <div className="header-title-right">

                                    {/* <Link to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 0 }}>
                                        More<i className="fas fa-angle-right" ></i>
                                    </Link> */}
                                </div>
                            </div>;

        let progressMainClass1 = "info-box-wrapper progress-view step-1 "
        let progressMainClass2 = "info-box-wrapper progress-view step-2 "
        let progressMainClass3 = "info-box-wrapper progress-view step-3 "
        let progressMainClass4 = "info-box-wrapper progress-view step-4 "
        let progressMainClass5 = "info-box-wrapper progress-view step-5 ";

        // if(tokenList && tokenList.tokenStatus == 0){
        //     progressMainClass1 = progressMainClass1+"active"
        //     progressMainClass2 = progressMainClass2+"active"
        //     // this.getRemainTime(tokenList && tokenList.createDate)
        // }
        // else if(tokenList && tokenList.tokenStatus == 1){
        //     progressMainClass1 = progressMainClass1+"active"
        //     progressMainClass2 = progressMainClass2+"active"
        //     progressMainClass3 = progressMainClass3+"active"
        // }

        // else if(tokenList && tokenList.tokenStatus == 10 && tokenList.ratingMark == 0){
        //     progressMainClass1 = progressMainClass1+"active"
        //     progressMainClass2 = progressMainClass2+"active"
        //     progressMainClass3 = progressMainClass3+"active"
        //     progressMainClass4 = progressMainClass4+"active"
        // }

        // else if(tokenList && tokenList.tokenStatus == 10 && tokenList.ratingMark > 0){
        //     progressMainClass1 = progressMainClass1+"active"
        //     progressMainClass2 = progressMainClass2+"active"
        //     progressMainClass3 = progressMainClass3+"active"
        //     progressMainClass4 = progressMainClass4+"active"
        //     progressMainClass5 = progressMainClass5+"active"
        // }

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />

                
                <div className="accrodion-section crm-dashboard">
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12">

                            <div className="tracking-progress-view">
                                <div className={ progressMainClass1 }>
                                    <div className="info-text">
                                        <h2>MBP</h2>
                                    </div>
                                    <div className="info-button info-btn-1">
                                        <i class="fas fa-info-circle"></i> Partner Type
                                    </div>
                                </div>

                                <div className="next"><div></div></div>

                                <div className={ progressMainClass2 }>
                                    <div className="info-text">
                                        <h3>Dhaka Region</h3>
                                    </div>
                                    <div className="info-button info-btn-1">
                                        <i class="fas fa-info-circle"></i> Partner Area
                                    </div>
                                </div>

                                <div className="next"><div></div></div>

                                <div className={ progressMainClass3 }>
                                    <div className="info-text">
                                        <h3>22-12-2020</h3>
                                    </div>
                                    <div className="info-button info-btn-1">
                                        <i class="fas fa-info-circle"></i> Assign Date
                                    </div>
                                </div>

                                <div className="next"><div></div></div>

                                <div className={ progressMainClass4 }>
                                    <div className="info-text">
                                        <h3>2 Years 1 Month 26 days</h3>
                                    </div>
                                    <div className="info-button info-btn-1">
                                        <i class="fas fa-info-circle"></i> Duration
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <Accordion activeIndex={this.state.activeIndex || 0} onTabChange={(e) => this.setState({activeIndex: e.index})} >
                                <AccordionTab header="Team Member Info">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-4">
                                            {this.teamMemberInfo('ZBP')}
                                        </div>
                                        <div className="p-col-12 p-xl-4">
                                            {this.teamMemberInfo('BDP')}
                                        </div>
                                        <div className="p-col-12 p-xl-4">
                                            {this.teamMemberInfo('BEP')}
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <Accordion activeIndex={this.state.activeIndex || 0} onTabChange={(e) => this.setState({activeIndex: e.index})} >
                                <AccordionTab header="Eduman Info">
                                    {/* <div className="p-grid"> */}
                                        <div className="p-grid p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-2">
                                                <Card footer="Active Institute">
                                                    <center><h2>155</h2></center>
                                                </Card>
                                            </div>
                                            <div className="p-col-12 p-xl-5">
                                                <div class="nw-search-view no-border p-t-0">
                                                    <div>
                                                        <div class="p-grid p-col-12">
                                                            <div className="p-col-12 p-xl-12">
                                                                {/* {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Active Pre-Paid Institute</span>
                                                                        <span className="p-inputgroup-colon">:</span>

                                                                        <div className="nw-inputgroup-desc">
                                                                            65
                                                                        </div>
                                                                    </div>
                                                                {/* } */}
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {/* {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Active Post-Paid Institute</span>
                                                                        <span className="p-inputgroup-colon">:</span>

                                                                        <div className="nw-inputgroup-desc">
                                                                            90
                                                                        </div>
                                                                    </div>
                                                                {/* } */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-5">
                                                <div class="nw-search-view no-border p-t-0">
                                                    <div>
                                                        <div class="p-grid p-col-12">
                                                            <div className="p-col-12 p-xl-12">
                                                                {/* {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Due Active Institute</span>
                                                                        <span className="p-inputgroup-colon">:</span>

                                                                        <div className="nw-inputgroup-desc">
                                                                            35
                                                                        </div>
                                                                    </div>
                                                                {/* } */}
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {/* {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Due Inactive Institute</span>
                                                                        <span className="p-inputgroup-colon">:</span>

                                                                        <div className="nw-inputgroup-desc">
                                                                            12
                                                                        </div>
                                                                    </div>
                                                                {/* } */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid p-col-12 p-xl-12">
                                        <div className="p-col-12 p-xl-2">
                                                <Card footer="Inactive Institute">
                                                    <center><h2>15</h2></center>
                                                </Card>
                                            </div>
                                            <div className="p-col-12 p-xl-5">
                                                <div class="nw-search-view no-border p-t-0">
                                                    <div>
                                                        <div class="p-grid p-col-12">
                                                            <div className="p-col-12 p-xl-12">
                                                                {/* {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Inactive Pre-Paid Institute</span>
                                                                        <span className="p-inputgroup-colon">:</span>

                                                                        <div className="nw-inputgroup-desc">
                                                                            6
                                                                        </div>
                                                                    </div>
                                                                {/* } */}
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {/* {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Inactive Post-Paid Institute</span>
                                                                        <span className="p-inputgroup-colon">:</span>

                                                                        <div className="nw-inputgroup-desc">
                                                                            9
                                                                        </div>
                                                                    </div>
                                                                {/* } */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-xl-5">
                                                <div class="nw-search-view no-border p-t-0">
                                                    <div>
                                                        <div class="p-grid p-col-12">
                                                            <div className="p-col-12 p-xl-12">
                                                                {/* {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Active Institute Due Bill</span>
                                                                        <span className="p-inputgroup-colon">:</span>

                                                                        <div className="nw-inputgroup-desc">
                                                                            1,53,124.00 taka
                                                                        </div>
                                                                    </div>
                                                                {/* } */}
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {/* {this.state.dataIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">Inactive Institute Due Bill</span>
                                                                        <span className="p-inputgroup-colon">:</span>

                                                                        <div className="nw-inputgroup-desc">
                                                                            33,124.00 taka
                                                                        </div>
                                                                    </div>
                                                                {/* } */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-col-12 p-xl-6 dashboard-card-view">
                                                <Card header="Active Institute">
                                                    <div className="p-grid p-col-nogutter">
                                                        <div className="p-col-12 p-xl-6 p-md-6">
                                                            <div className="info-box-wrapper not-hover"> 
                                                                <div className="chart-box">
                                                                    <Chart
                                                                        width={226}
                                                                        height={226}
                                                                        chartType="PieChart"
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={[
                                                                            ['City', '2010 Population'],
                                                                            ['Starter', 5],
                                                                            ['Basic', 10],
                                                                            ['Standard', 15],
                                                                            ['Advance', 25],
                                                                          ]}
                                                                        options={{
                                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                            backgroundColor: 'transparent',
                                                                            legend: 'none',
                                                                            slices: {
                                                                                0: { color: '#2e3192'},
                                                                                1: { color: '#0099ff'},
                                                                                2: { color: '#ffcc99'},
                                                                                3: { color: '#ff0099'},
                                                                            },
                                                                        }}
                                                                        // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                                        render={({ renderChart }) => {
                                                                            return (
                                                                                <div>{renderChart()}</div>
                                                                            )
                                                                        }}
                                                                        rootProps={{ 'data-testid': '1' }}
                                                                    />
                                                                </div>
                                                                <span className="chart-box-title">Package Wise Active Institute</span>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="p-col-12 p-xl-6 p-md-6">
                                                            <div className="netiWorld-DashBoard-wrapper">
                                                                <div className="chart-box-wrapper">
                                                                    <div className="point-list">
                                                                        <table>
                                                                            <tr>
                                                                                <td><span style={{ background: '#2e3192'}}></span><p>Starter</p></td>
                                                                                <td><p>5</p></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td><span style={{ background: '#0099ff'}}></span><p>Basic</p></td>
                                                                                <td><p>10</p></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td><span style={{ background: '#ffcc99'}}></span><p>Standard</p></td>
                                                                                <td><p>15</p></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td><span style={{ background: '#ff0099'}}></span><p>Advance</p></td>
                                                                                <td><p>25</p></td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>

                                            <div className="p-col-12 p-xl-6 dashboard-card-view">
                                                <Card header="Inactive Institute">
                                                    <div className="p-grid p-col-nogutter">
                                                        <div className="p-col-12 p-xl-6 p-md-6">
                                                            <div className="info-box-wrapper not-hover"> 
                                                                <div className="chart-box">
                                                                    <Chart
                                                                        width={226}
                                                                        height={226}
                                                                        chartType="PieChart"
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={[
                                                                            ['City', '2010 Population'],
                                                                            ['Starter', 5],
                                                                            ['Basic', 10],
                                                                            ['Standard', 15],
                                                                            ['Advance', 25],
                                                                          ]}
                                                                        options={{
                                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                            backgroundColor: 'transparent',
                                                                            legend: 'none',
                                                                            slices: {
                                                                                0: { color: '#2e3192'},
                                                                                1: { color: '#0099ff'},
                                                                                2: { color: '#ffcc99'},
                                                                                3: { color: '#ff0099'},
                                                                            },
                                                                        }}
                                                                        // chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                                        render={({ renderChart }) => {
                                                                            return (
                                                                                <div>{renderChart()}</div>
                                                                            )
                                                                        }}
                                                                        rootProps={{ 'data-testid': '1' }}
                                                                    />
                                                                </div>
                                                                <span className="chart-box-title">Package Wise Inactive Institute</span>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="p-col-12 p-xl-6 p-md-6">
                                                            <div className="netiWorld-DashBoard-wrapper">
                                                                <div className="chart-box-wrapper">
                                                                    <div className="point-list">
                                                                        <table>
                                                                            <tr>
                                                                                <td><span style={{ background: '#2e3192'}}></span><p>Starter</p></td>
                                                                                <td><p>5</p></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td><span style={{ background: '#0099ff'}}></span><p>Basic</p></td>
                                                                                <td><p>10</p></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td><span style={{ background: '#ffcc99'}}></span><p>Standard</p></td>
                                                                                <td><p>15</p></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td><span style={{ background: '#ff0099'}}></span><p>Advance</p></td>
                                                                                <td><p>25</p></td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>

                                            <div className="p-col-12 p-xl-6 dashboard-card-view">
                                                <Card header="Inactive Institute">
                                                    <div className="p-grid p-col-nogutter">
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="info-box-wrapper not-hover"> 
                                                                <div className="chart-box">
                                                                    <Chart
                                                                        // width={'226px'}
                                                                        // height={'226px'}
                                                                        chartType="Bar"
                                                                        loader={<div>Loading Chart</div>}
                                                                        data={[
                                                                            ['', ''],
                                                                            ['Govt.', 25],
                                                                            ['MPO', 52],
                                                                            ['Private', 95],
                                                                            ['NGO', 9],
                                                                        ]}
                                                                        options={{
                                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                                            backgroundColor: 'transparent',
                                                                            legend: 'none',
                                                                        }}
                                                                        rootProps={{ 'data-testid': '1' }}
                                                                        />
                                                                </div>
                                                                <span className="chart-box-title">Package Wise Inactive Institute</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>

                                            <div className="p-col-12 p-xl-6">
                                                <Accordion activeIndex={this.state.activeIndex || 0} onTabChange={(e) => this.setState({activeIndex: e.index})} >
                                                    <AccordionTab header="Board Wise Institute Info">
                                                        <div className="p-col-12 p-xl-12">
                                                            <Card>
                                                                <center>
                                                                    <h2>135</h2>
                                                                    <p>Dhaka Education Board</p>
                                                                </center>
                                                                
                                                            </Card>
                                                        </div>
                                                        <div className="p-col-12 p-xl-12">
                                                            <Card>
                                                                <center>
                                                                    <h2>135</h2>
                                                                    <p>Madrasha Education Board</p>
                                                                </center>
                                                            </Card>
                                                        </div>
                                                    </AccordionTab>
                                                </Accordion>
                                            </div>


                                        </div>
                                        
                                    {/* </div> */}
                                </AccordionTab>
                            </Accordion>

                            
                        </div>

                        <div className="p-col-12 p-xl-4 dashboard-card-view">
                            <Card header="Neti-CMS Info">
                                <center>
                                    <h2>135</h2>
                                    <p>Total Neti CMS</p>
                                </center>
                            </Card>
                        </div>

                        <div className="p-col-12 p-xl-4 dashboard-card-view">
                            <Card header="Admisia Info">
                                <center>
                                    <h2>95</h2>
                                    <p>Total Admisia</p>
                                </center>
                            </Card>
                        </div>

                        <div className="p-col-12 p-xl-4 dashboard-card-view">
                            <Card header="Attendancev Device Info">
                                <center>
                                    <h2>255</h2>
                                    <p>Active Device</p>
                                </center>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}