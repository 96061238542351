import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { BalanceService } from '../../../service/myPoint/BalanceService';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { EdumanActionLogService } from '../../../service/actionLog/EdumanActionLogService';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { pdfDownloadPortrait_FullTable } from '../../common/JsPdfDownload';

let maxDate = new Date();
let totalActions = 0;
let totalPercentage = 0;

// pdf
let subTitleColumn = [];
let subTitleList = [];
let selectedAcType = '';
export class EmActionTypeWiseModuleLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            dataTableSelection: null,
            dataTableValue: [],
            dateRangeParamFormat: {
                requestStartDate: "",
                requestEndDate: "",
                activityType: '',
            },
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
        };

        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.balanceService = new BalanceService();
        this.CRMPointService = new CRMPointService();
        this.edumanActionLogService = new EdumanActionLogService();

        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
        this.exportPdf = this.exportPdf.bind(this);

    }

    onChangeActionType = (e) => {
        let { errors, dateRangeParamFormat } = this.state;
        dateRangeParamFormat.activityType = e.target.value;
        this.setState({ dateRangeParamFormat });
        errors["activityType"] = "";
    }

    onSubmitHandler = () => {

        let { dateRangeParamFormat } = this.state;
        let startDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestStartDate);
        let endDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestEndDate);
        let searchQueryInfo = {
            "query": `SELECT moduleName,COUNT(*) action  FROM eduman where logDate between '${startDate}' and '${endDate}' and activityType='${dateRangeParamFormat.activityType}' GROUP BY moduleName`
        }

        if (this.searchHandleError()) {

            selectedAcType =  dateRangeParamFormat.activityType;
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.edumanActionLogService.getActionTypeWiseEmModuleLog(searchQueryInfo).then((body) => {

                totalActions = body.map(item => item.actions).reduce((a, b) => (a + b));
                totalPercentage = body.map(item => {
                    return ((item.actions * 100) / totalActions);
                }).reduce((a, b) => (a + b));

                // for add usePercentage column in both pdf & datatable
                for (let index = 0; index < body.length; index++) {
                    body[index]['usePercentage'] = ((body[index].actions * 100) / totalActions).toFixed(2) + '%';
                }

                this.setState({ dataTableValue: body });
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
        }
    }

    searchHandleError = () => {

        let { errors, dateRangeParamFormat } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (dateRangeParamFormat.requestStartDate === '') {
            formIsValid = false;
            errors["requestStartDate"] = "Start Date can't left empty.";
        }
        if (dateRangeParamFormat.requestEndDate === '') {
            formIsValid = false;
            errors["requestEndDate"] = "End Date can't left empty.";
        }
        if (dateRangeParamFormat.activityType === '') {
            formIsValid = false;
            errors["activityType"] = "Action Type can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;

    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onChangeStartDate = (e) => {
        let { dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        this.clearDateError(e.target.name);

    }

    exportPdf() {

        let pdfColumns = [
            { title: "Module", dataKey: "moduleName" },
            { title: "No. of Action", dataKey: "actions" },
            { title: "Percentage", dataKey: "usePercentage" },
        ]

        let tableColumnStyles = {};
        var totalArrayList = [
            {
                id: "1",totalPdf: "Total",totalAmount1: totalActions,totalAmount2: totalPercentage.toLocaleString('EN-IN') + '%',
            }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }, { dataKey: "totalAmount1" }, { dataKey: "totalAmount2" }];

        let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, cellWidth: 67, fontStyle: "bold", overflow: "linebreak" },
            totalAmount1: { halign: "right", fontStyle: "bold", fontSize: 10, cellWidth: 60.5 },
            totalAmount2: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 53.5 },
        }

        pdfDownloadPortrait_FullTable("Action Wise Module Log", 70, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableColumnStyles, totalColumns, totalArrayList, totalColumnStyles, "Action_Wise_Module_Log.pdf");

    }

    render() {

        let { dateRangeParamFormat, dataTableValue } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        let activityType = dateRangeParamFormat.activityType;

        if (dateRangeParamFormat.requestStartDate && dateRangeParamFormat.requestEndDate) {
            formatedStartDate = (dateRangeParamFormat.requestStartDate.toLocaleDateString('en-GB'));
            formatedEndDate = (dateRangeParamFormat.requestEndDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" },
            { dataKey: "E" },
            { dataKey: "F" },
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate, E: "Action Type", F: activityType }
        ];
        // pdf reletaed task end

        let tableHeader = <div className="header-title">
            <div className="header-title-left">{selectedAcType} Action Wise Module Log</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="Total" />
                <Column footer={totalActions ? totalActions.toLocaleString('EN-IN') : ''} style={{ textAlign: 'left' }} />
                <Column footer={totalPercentage ? `${totalPercentage.toLocaleString('EN-IN')}%` : ''} style={{ textAlign: 'left' }} />
            </Row>
        </ColumnGroup>;

        const actionTypes = [
            { label: 'Insert', value: 'Insert' },
            { label: 'Upload', value: 'Upload' },
            { label: 'Update', value: 'Update' },
            { label: 'Fetch', value: 'Fetch' },
            { label: 'Delete', value: 'Delete' },
            { label: 'Download', value: 'Download' },
            { label: 'Login', value: 'Login' },
            { label: 'Logout', value: 'Logout' },
        ]

        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <Growl ref={(el) => this.growl = el} />
                            {topProgressBar ?
                                <ErrorMessageView
                                    topProgressBar={topProgressBar}
                                />
                                : null
                            }
                            {errorMsgVisible ?
                                <ErrorMessageView
                                    errorMsgVisible={errorMsgVisible}
                                    errorMsgBody={errorMsgBody}
                                />
                                : null
                            }
                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                    <Calendar
                                        name='requestStartDate'
                                        value={dateRangeParamFormat.requestStartDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["requestStartDate"]}</span>

                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        name='requestEndDate'
                                        value={dateRangeParamFormat.requestEndDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        dateFormat='dd/mm/yy'
                                        yearRange="2010:2030"
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["requestEndDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Action Type <span>*</span></span>
                                    <Dropdown
                                        placeholder="select action type"
                                        name="activityType"
                                        options={actionTypes}
                                        value={dateRangeParamFormat.activityType}
                                        onChange={this.onChangeActionType}
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["activityType"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                <Button
                                    label="Search"
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitHandler}
                                />
                            </div>
                        </div>

                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table">
                                    {this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader() : <div>
                                            <DataTable
                                                value={this.state.dataTableValue}
                                                selectionMode="single"
                                                header={tableHeader}
                                                selection={this.state.dataTableSelection}
                                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                responsive={true}
                                                footerColumnGroup={footerGroup}
                                            >
                                                <Column field="moduleName" header="Module" sortable={true} />
                                                <Column field="actions" header="No. of Action" sortable={true} />
                                                <Column field="usePercentage" header="Percentage" sortable={true} />
                                            </DataTable>

                                            <div className="nw-button-parent m-t-8 p-r-0">
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                    label="Download PDF"
                                                    icon="fas fa-download"
                                                    onClick={this.exportPdf}
                                                />
                                            </div>
                                        </div>

                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}