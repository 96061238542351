import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { PartnerPointService } from '../../../../service/partnerPoint/PartnerPointService';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';


let totalActiveInstitute = 0;
let totalInactiveInstitute = 0;
let totalActiveBill = 0;
let totalInActiveBill = 0;
let activeBillTotal = 0;
let totalActiveBillArr = [];
let totalInActiveBillArr = [];
let totalActiveInstituteArr = [];
let totalInActiveInstituteArr = [];
let sumOfDueBill = 0;

export class PartnerBEPDueStatement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partnerAssignInfo:{},
            dataTableValue: [],
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            rowDataObj: this.props.location.searchObj
        }

        this.paidDetailsBody = this.paidDetailsBody.bind(this);
        this.PartnerPointService = new PartnerPointService;
        this.ManagementPointService = new ManagementPointService;
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
    }

    componentWillMount() {
        if (this.props.location.searchObj) {
            let partnerAssignID = this.props.location.searchObj.partnerAssignID
            console.log("ID", this.props.location.searchObj.partnerAssignID);
            
            localStorage.setItem("partnerAssignIDBEP", JSON.stringify(partnerAssignID));
        }

        this.fetchInstituteFoundInfo();
    }

    fetchInstituteFoundInfo = () => {
        totalActiveInstitute = 0;
        totalInactiveInstitute = 0;
        sumOfDueBill = 0;
        activeBillTotal = 0;
        totalInActiveBill = 0;

        let partnerAssignID = JSON.parse(localStorage.getItem("partnerAssignIDBEP"));
        if (!partnerAssignID) {
            return;
        }
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.PartnerPointService.fetchInstituteFoundInfoList(partnerAssignID) /*this.state.rowDataObj.partnerAssignID*/
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("Institute", body);
                        this.setState({
                            dataTableValue: body.detailsInfoDTOs,
                            partnerAssignInfo:body.partnerAssignInfoDTO
                        });

                        sumOfDueBill = body.detailsInfoDTOs.map(item => item.dueAmount).reduce((a, b) => (a + b));


                        body.detailsInfoDTOs.map( (item) => { 
                            console.log("active ITEM", item );

                            if(item.instituteStatus === 1) {
                                totalActiveInstituteArr.push(item.instituteStatus === 1 );
                                totalActiveInstitute = totalActiveInstituteArr.reduce((a, b) => (a + b));
                                totalActiveBillArr.push(item.dueAmount);
                                activeBillTotal =  totalActiveBillArr.reduce((a, b) => (a + b));

                            }

                            if(item.instituteStatus === 2) {
                                totalInActiveInstituteArr.push(item.instituteStatus === 2)
                                totalInactiveInstitute = totalInActiveInstituteArr.reduce((a, b) => (a + b));
                                totalInActiveBillArr.push(item.dueAmount);
                                totalInActiveBill =  totalInActiveBillArr.reduce((a, b) => (a + b));
                               
                            }
                        });

                        this.setState({ topProgressBar: false, dataTableIsLoading: false });

                    });
                } else {
                    this.PartnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection!!!' })

            );
    }

    paidDetailsBody(rowData, column) {

        return <div className='text-center'>
            <Link to={{
                pathname: MANAGEMENT_POINT.INSTITUTE_DUE_STATEMENT,
                searchObj: { ...rowData }
            }}>
                <i class="fas fa-arrow-circle-right fa-2x"></i>
                <u>{rowData.dataTableValue}</u>
            </Link>
        </div>
    }

    statusInstituteBody = (rowData, column) => {
        if (rowData.instituteStatus === 1) {
            return <p className="status-active" style={{color:"#1fe41f"}}>Active</p>
        } else if (rowData.instituteStatus === 2) {
            return <p className="status-inactive" style={{color:"red"}}>Inactive</p>
        }
    }

    dueBillBody(rowData) {
        return <span>
        {this.validatorUtility.currencyFormatter(rowData["dueAmount"])} 
        </span>

    }

    
    render() {
        let { partnerAssignInfo, topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, rowDataObj } = this.state;
        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Total Institute Found: {dataTableValue.length}<span style={{ 'float': 'right' }}> </span></div>;
        console.log("totalInActiveBillArr", totalInActiveBillArr );
        console.log("totalInActiveBill", totalInActiveBill );
        let footer = 
        <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={3} />
                <Column footer= {this.validatorUtility.currencyFormatter(sumOfDueBill)}/>
                <Column footer="" />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="card nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                <div>
                                    <div>Neti Id</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo.userBasicInfoDTO ? partnerAssignInfo.userBasicInfoDTO.customNetiID : ''}</div>
                                </div>

                                <div>
                                    <div>Name</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo.userBasicInfoDTO ? partnerAssignInfo.userBasicInfoDTO.fullName : ''}</div>
                                </div>

                                <div>
                                    <div>Mobile No</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo.userBasicInfoDTO ? partnerAssignInfo.userBasicInfoDTO.basicMobile : ''}</div>
                                </div>

                                <div>
                                    <div>Area</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo.partnerAreaInfoDTO ? partnerAssignInfo.partnerAreaInfoDTO.categoryName : '' }</div>
                                </div>

                            </div>


                            <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                <div>
                                    <div>Type</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo.partnerDesignationInfoDTO ? partnerAssignInfo.partnerDesignationInfoDTO.categoryName : '' }</div>
                                </div>

                                <div>
                                    <div>Total Institute</div>
                                    <div>:</div>
                                    <div>{dataTableValue.length}</div>
                                </div>


                            </div>


                            <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                <div>
                                    <div>Active Institute</div>
                                    <div>:</div>
                                    <div>{totalActiveInstitute}</div>
                                </div>

                                <div>
                                    <div>Active Bill</div>
                                    <div>:</div>
                                    <div>{this.validatorUtility.currencyFormatter(activeBillTotal)}</div>
                                </div>

                                <div>
                                    <div>Inactive Institute</div>
                                    <div>:</div>
                                    <div>{totalInactiveInstitute}</div>
                                </div>

                                <div>
                                    <div>Inactive Bill</div>
                                    <div>:</div>
                                    <div>{this.validatorUtility.currencyFormatter(totalInActiveBill)}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="nw-data-table">
                    {
                        this.state.dataTableIsLoading ?
                            this.NetiContentLoader.MyPointTableLoader()
                            :
                            <DataTable
                                header={header}
                                footerColumnGroup={footer}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                value={this.state.dataTableValue}
                            >
                                <Column field="instituteId" header="Institute ID" filter={true} />
                                <Column field="instituteName" header="Institute Name" filter={true} />
                                <Column field="" header="Status" filter={true} body={this.statusInstituteBody.bind(this)} />
                                <Column field="" header="Due Bill" body={this.dueBillBody.bind(this)} filter={true}/>
                                <Column field="details" header="At a Glance" body={this.paidDetailsBody} />
                            </DataTable>
                    }


                </div>


            </div>
        )
    }
}