import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

let totalFixedRevenueAmount = 0;
let totalFixedRevenuePercentage = 0;

let totalLevelRevenueAmount = 0;
let totalLevelRevenuePercentage = 0;
export class ProducListInfoDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ownerRevenueInfo: {},
            errorMsgVisible: false,
            levelAmountInfo: [],
            topProgressBar: false,
            productInfoDialog: false,
            errorMsgBody: '',

        }
        this.adminPointService = new AdminPointService;
        this.validatorUtility = new ValidatorUtility();
    }

    // onHideViewDialog() {
    //     this.setState({ viewDialogShow: false });
    //     this.CommonFuctionality.blurDialogBackgroundDeActive();
    // }


    fixedRevenueCashAmount = (rowData) => {
        return (<span>{this.validatorUtility.currencyFormatter(rowData['cash_amount'])}</span>);
    }

    levelRevenueCashAmount = (rowData) => {
        return (<span>{this.validatorUtility.currencyFormatter(rowData['cash_amount'])}</span>);
    }

    fixedRevenuePercentage = (rowData) => {
        return (<span>{rowData['percentage'] ? this.validatorUtility.currencyFormatter(rowData['percentage']) + '%' : ''}</span>);
    }


    levelRevenueBasePercent = (rowData) => {
        return (<span>{rowData['basePercent'] ? this.validatorUtility.currencyFormatter(rowData['basePercent']) + '%' : ''}</span>);
    }

    onHideProductInfoDialog = () => {
        this.setState({ productInfoDialog: false });

    }

    showProductInfoDialog = (rowData) => {
        return (<span onClick={e => this.getpartnerGradeInfoByLevelRevConfigID(rowData.levelRevConfigID, e)}>{rowData['partnerType']}</span>);
    }

    getpartnerGradeInfoByLevelRevConfigID = (param) => {
        this.setState({ productInfoDialog: true });
        this.setState({ topProgressBar: true, errorMsgVisible: false })
        this.adminPointService.findGradeWiseLevelAmount(param)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("body", body);
                        this.setState({ levelAmountInfo: body, topProgressBar: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { productInfo, fixedRevenueInfo, levelRevenueInfo } = this.props.productOwnerRevenueInfo;
        if(fixedRevenueInfo != undefined) {
            totalFixedRevenuePercentage = fixedRevenueInfo.map(item => item.percentage).reduce((a,b) => a + b,0);
            totalFixedRevenueAmount = fixedRevenueInfo.map(item => item.cash_amount).reduce((a,b) => a + b,0);
        }
        if(levelRevenueInfo != undefined) {
            totalLevelRevenuePercentage = levelRevenueInfo.map(item => item.basePercent).reduce((a,b) => a + b,0);
            totalLevelRevenueAmount = levelRevenueInfo.map(item => item.cash_amount).reduce((a,b) => a + b,0);
        }


        let fixedRevenueHeader = <div className="p-clearfix p-inputgroup" style={{ 'lineHeight': '1.87em', 'display': 'block' }}>
            Fixed Revenue List<span><p className="p-inputgroup-info-value-color2">{productInfo && productInfo.fixed_percent}</p> of sales Price =<p className="p-inputgroup-info-value-color2"> {productInfo && productInfo.fixed_amount}</p></span></div>;

        // <div>Fixed Revenue List  <span style={{ float: 'right', fontSize: '1em', color: '#004BA0' }}>20% of sales Price = 2000/-</span></div>;
        let levelRevenueHeader = <div className="p-clearfix p-inputgroup" style={{ 'lineHeight': '1.87em', 'display': 'block' }}>
            Level Revenue List<span><p className="p-inputgroup-info-value-color3">{productInfo && productInfo.level_percent}</p> of sales Price =<p className="p-inputgroup-info-value-color3"> {productInfo && productInfo.level_amount}</p></span></div>;;

        let productInfoTableHeader = <div className="header-title">
            <div className="header-title-left">Details</div>

            <div className="header-title-right">
                <Link to="#" className="total-found">
                    Total Found: {this.state.levelAmountInfo.length}
                </Link>
            </div>
        </div>;
              let fixedRevenueFooterGroup = <ColumnGroup>
              <Row>
                  <Column footer="" />
                  <Column footer='' />
                  <Column style={{textAlign:"left"}} footer={totalFixedRevenuePercentage ? this.validatorUtility.currencyFormatter(totalFixedRevenuePercentage) + '%' : 0} />
                  <Column style={{textAlign:"left", color:"#FF8800"}} footer={totalFixedRevenueAmount ? this.validatorUtility.currencyFormatter(totalFixedRevenueAmount) + '/-' : 0} />
              </Row>
          </ColumnGroup>;
                let levelRevenueFooterGroup = <ColumnGroup>
                <Row>
                    <Column footer="" />
                    <Column style={{textAlign:"left"}} footer={totalLevelRevenuePercentage ? this.validatorUtility.currencyFormatter(totalLevelRevenuePercentage) + '%' : 0} />
                    <Column style={{textAlign:"left", color: '#00AFD4' }} footer={totalLevelRevenueAmount ? this.validatorUtility.currencyFormatter(totalLevelRevenueAmount) + '/-': 0} />
                </Row>
            </ColumnGroup>;
        return (
            <div className="p-fluid">
                <div className="p-grid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                    <div className="p-col-12 p-xl-12">
                        <Dialog header="Product Owner Revenue Information"
                            visible={this.props.viewDialogShow}
                            onHide={this.props.onHideViewDialog}
                            className="nw-dialog product-info-dialog">

                            <div className="p-col-12">
                                <div class="nw-search-view no-border p-0">
                                    <div>
                                        <div class="p-col-12 p-xl-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon"> Owner Neti ID </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.owner_neti_id}</div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-xl-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon"> Owner Name  </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.owner_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="nw-search-view">
                                    <div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon"> Product Type </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.product_type}</div>
                                            </div>
                                        </div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon"> Product Name  </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.product_name}</div>
                                            </div>
                                        </div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon"> Default Code </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.default_code}</div>
                                            </div>
                                        </div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon"> Product Details </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.product_details}</div>
                                            </div>
                                        </div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon">Product Stock </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.remaining_stock}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12">
                                    <div class="from-group-heading"><span>Price Configuration Data</span></div>
                                </div>
                                <div class="nw-search-view">
                                    <div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon">Sales Price </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.sales_price}</div>
                                            </div>
                                        </div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon">Vat Percent </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.vat_percent}</div>
                                                <span class="p-inputgroup-info">Where vat is - <span className="p-inputgroup-info-value p-inputgroup-info-value-color4">{productInfo && productInfo.vat_amount}</span> </span>
                                            </div>
                                        </div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon">Total Price Including Vat </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.total_price_with_vat}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="p-col-12">
                                    <div class="from-group-heading"><span>Revenue Configuration Data</span></div>
                                </div>
                                <div class="nw-search-view">
                                    <div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon">Cost Percent </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.cost_percent}</div>
                                                <span class="p-inputgroup-info">Where amount is - <span className="p-inputgroup-info-value p-inputgroup-info-value-color1">{productInfo && productInfo.cost_amount}</span> </span>
                                            </div>
                                        </div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon">Fixed Percent </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc p-inputgroup-info-value-color2">{productInfo && productInfo.fixed_percent}</div>
                                                <span class="p-inputgroup-info">Where amount is - <span className="p-inputgroup-info-value p-inputgroup-info-value-color2">{productInfo && productInfo.fixed_amount}</span> </span>
                                            </div>
                                        </div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon">Level Percent </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc p-inputgroup-info-value-color3">{productInfo && productInfo.level_percent}</div>
                                                <span class="p-inputgroup-info">Where amount is - <span className="p-inputgroup-info-value p-inputgroup-info-value-color3">{productInfo && productInfo.level_amount}</span> </span>
                                            </div>
                                        </div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon">Total Percent </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc p-inputgroup-info-value-color4">{productInfo && productInfo.total_percent}</div>
                                                <span class="p-inputgroup-info">Where amount is - <span className="p-inputgroup-info-value p-inputgroup-info-value-color4">{productInfo && productInfo.total_amount}</span> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="p-col-12">
                                    <div class="from-group-heading"><span>Income Tax</span></div>
                                </div>
                                <div class="nw-search-view">
                                    <div>
                                        <div class="p-col-12">
                                            <div class="p-inputgroup">
                                                <span class="task-badge found"></span>
                                                <span class="p-inputgroup-addon">Income Tax Percent </span>
                                                <span class="p-inputgroup-colon">:</span>
                                                <div class="nw-inputgroup-desc">{productInfo && productInfo.tax_percent}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 nw-dialogue-data-table">
                                    <DataTable
                                        header={fixedRevenueHeader}
                                        value={fixedRevenueInfo}
                                        responsive={true}
                                        footerColumnGroup={fixedRevenueFooterGroup}
                                    >
                                        <Column field="netiID" header="NETI ID" />
                                        <Column field="netiName" header="NAME" />
                                        <Column field="percentage" body={this.fixedRevenuePercentage} header="PERCENTAGE" />
                                        <Column field="cash_amount" body={this.fixedRevenueCashAmount} header="CASH AMOUNT" />
                                    </DataTable>
                                </div>

                                <div className="p-col-12 nw-dialogue-data-table">
                                    <DataTable
                                        header={levelRevenueHeader}
                                        value={levelRevenueInfo}
                                        responsive={true}
                                    footerColumnGroup={levelRevenueFooterGroup}
                                    >
                                        <Column field="levelRevConfigID" header="LEVEL NAME" body={this.showProductInfoDialog} />
                                        <Column field="basePercent" header="PERCENTAGE" body={this.levelRevenueBasePercent} />
                                        <Column field="cash_amount" body={this.levelRevenueCashAmount} header="CASH AMOUNT" />
                                    </DataTable>
                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                    <Button
                                        label="Close"
                                        icon="fas fa-times"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        onClick={this.props.onHideViewDialog}
                                    />
                                </div>
                            </div>
                        </Dialog>

                    </div>



                    <div className="dialog-section">
                        <Dialog header="Product Info" className="nw-dialog" visible={this.state.productInfoDialog} onHide={this.onHideProductInfoDialog}>
                            <div className="p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-col-12">
                                            <div class="nw-search-view no-border p-0">
                                                <div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Owner Neti ID </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{productInfo && productInfo.owner_neti_id}</div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Owner Name  </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{productInfo && productInfo.owner_name}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-col-12 p-xl-12 nw-data-table">
                                            {this.state.dataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader() :
                                                <DataTable
                                                    header={productInfoTableHeader}
                                                    value={this.state.levelAmountInfo}
                                                    responsive={true}
                                                    paginator={true}
                                                    rows={20}
                                                >
                                                    <Column field="grade" header="Grade" />
                                                    <Column field="percent" header="Percent" />
                                                    <Column field="amount" header="Amount" />
                                                </DataTable>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>

            </div>
        )
    }


}