import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { DressCodeInfoList } from './DressCodeInfoList';
import { UserCategoryService } from '../../../service/UserCategoryService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';


let dialogStatus = false;

let cloneStateBeforeMount;
let errors={};
let urlId;
export class DressCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            proPic: {
                extention: '',
                contentPic: '',
                contentName: ''
            },
            serialNumber:'',
            gender: '',
            classRange: '',
            coreClassCategoryID:'',
            genderDropDownList: null,
            details: '',
            dressCode:{
                "dressSerial": 0,
                "classRange": "",
                "dressDetails": "",
                "dressImageName": "",
                "dressImagePath": "",
                "dressImageContent": "",
                "dressImageSaveOrEditable": true,
                "genderCategoryInfoDTO": {
                  "coreCategoryID": 0,
                  "categoryName": ""
                },
                "coreUrlInfoDTO": {
                    "urlInfoID": ''
                }
              }, 
            dataTableIsLoading: false,
            homeReturnButton: false,
            dressCodeInfoListVisible: true,
            firstDropDownIsLoading: false,
            totalDataFound:0,
            totalData:0
        } 

        this.UserCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
        this.netiFileHandler = new NetiFileHandler();
        this.DwsService = new DwsService();

        this.onChangeInputValue = this.onChangeInputValue.bind(this);
        this.onBasicUploadAuto = this.onBasicUploadAuto.bind(this);
        this.reloadCoreUrlIdFromLocal();
   
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => { 
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount(){
        const urlId=this.DwsService.getUrlInfoFromLocalStorage();
       
        if( urlId ){
            let {coreUrlInfoDTO} = this.state.dressCode
            coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ coreUrlInfoDTO })
            this.setState({ homeReturnButton: false})
            this.getClassOrGroupListByDefaultCode('T114');
        }
        else{
            this.setState({ homeReturnButton: true})
        }
        
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    getClassOrGroupListByDefaultCode(defaultCode){
        this.setState({ firstDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // if(defaultCode === 'T114'){
                            this.setState({ genderDropDownList: body});
                        // }
                        // else if(defaultCode === 'T109'){
                        //     this.setState({ groupDropDownList: body});
                        // }
                        this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else if(res.status == 404) {
                    return res.json().then((body) => {
                        errors["dropDownClassError"] = body.message
                        this.setState({ errors: errors })
                        this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                
            }).catch(error => {
                errors["dropDownClassError"] = 'Connection Problem'
                this.setState({ errors: errors })
                this.setState({firstDropDownIsLoadingg: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onBasicUploadAuto(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });

        }
        // this.growl.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Auto Mode' });
    }

    onChangeInputValue = (e) =>{
        errors={}
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeGender = (e) =>{
        errors={}
        this.setState({ gender: e.value})
    }

    onChangeClass = (e) =>{
        errors={}
        this.setState({ coreClassCategoryID: e.value})
    }

    handleError() {
        
        let formIsValid = true;

        if (this.state.gender === '' || this.state.gender === null) {
            formIsValid = false;
            errors["gender"] = "Gender can't left empty.";
        }
        if (this.state.classRange === '') {
            formIsValid = false;
            errors["class"] = "Class can't left empty.";
        }
        if (this.state.details === '') {
            formIsValid = false;
            errors["details"] = "Details can't left empty.";
        }
        if (this.state.proPic.contentName === '') {
            formIsValid = false;
            errors["image"] = "Please Upload image.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    dressCodeOnSubmitHandler = () =>{
        let { dressCode, totalDataFound } = this.state;
        let { genderCategoryInfoDTO } = this.state.dressCode
        this.setState({ errors: errors})

        if(this.handleError()){
            
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            
            dressCode.dressImageContent = this.state.proPic.contentPic;
            dressCode.attachFileSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            // dressCode.dressImageName = 'dress_code_' + urlId + '_' + Date.now() + extention;
            dressCode.dressImageName = this.state.proPic.contentName;
            

            // dressCode.dressSerial = this.state.serialNumber;
            dressCode.dressSerial = totalDataFound+1;
            genderCategoryInfoDTO.coreCategoryID = this.state.gender
            dressCode.classRange = this.state.classRange;
            dressCode.dressDetails = this.state.details;
            
            this.DwsService.createNewDressCode(dressCode)
            .then(res => {
                if(res.status == 201){
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    this.growl.show({severity: 'success', summary: 'Success', detail: 'Successfully Saved'});
                    this.setState({
                        proPic: cloneStateBeforeMount.proPic,
                        dressCode: cloneStateBeforeMount.dressCode,
                        serialNumber:'',
                        gender: '',
                        classRange: '',
                        details: '',
                    });
                    errors={}
                    this.updateDressCodeInfoList();
                }
                else{
                    this.DwsService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                    });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

        }
        
    }

    updateDressCodeInfoList = () => {
        this.setState({dressCodeInfoListVisible: false});
        this.setState({dressCodeInfoListVisible: true});
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let genderList=[];
        if ((this.state.genderDropDownList != null) && (this.state.genderDropDownList.length > 0)) {
            genderList = this.state.genderDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">(370 x 240 px (recommended)</span></div>

    
        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                { topProgressBar?
                    <ErrorMessageView 
                        topProgressBar={topProgressBar} 
                    />
                    : null
                }
                { errorMsgVisible ?
                    <ErrorMessageView 
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                {
                    this.state.homeReturnButton === false?
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12">
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.totalDataFound+1}
                                                </div>
                                            </div>
                                            <span className="error-message">{errors['serialNumber']}</span>
                                        </div>
                                        

                                        <div className="p-col-12 p-xl-12">
                                        { this.state.firstDropDownIsLoading ? 
                                            this.NetiContentLoader.normalFormInputField():
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Gender <span>*</span></span>
                                                    <Dropdown
                                                        placeholder="Select gender"
                                                        value={this.state.gender}
                                                        options={genderList}
                                                        onChange={this.onChangeGender}
                                                        // itemTemplate={this.bankTemplate}
                                                        showClear={true}
                                                        autoWidth={false}
                                                    />
                                            </div>
                                        }
                                            <span className="error-message">{errors['gender']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Class <span>*</span></span>
                                                <InputText
                                                    // value={this.state.customNetiID}
                                                    id="classRange"
                                                    placeholder="Enter Class Range"
                                                    showClear={true}
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="classRange"
                                                    onChange={this.onChangeInputValue}
                                                    value={this.state.classRange}
                                                />
                                            </div>
                                            <span className="error-message">{errors['class']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                                <InputTextarea
                                                    placeholder="Enter Dress Code Details"
                                                    onChange={this.onChangeInputValue}
                                                    value={this.state.details}
                                                    rows={3}
                                                    cols={30}
                                                    autoResize={true}
                                                    id="detail"
                                                    name="details"
                                                />
                                            </div>
                                            <span className="error-message">{errors['details']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Upload Image <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/GIF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    {
                                                        this.state.proPic.contentPic ?
                                                            <div className="image-view-main">
                                                                <div className="upload-image-view">
                                                                    <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={e => this.setState({ proPic: { contentPic: '' } })}
                                                                    />
                                                                    <img
                                                                        src={
                                                                            this.state.proPic.contentPic === '' ?
                                                                                'assets/images/demo.jpg'
                                                                                :
                                                                                "data:image/png;base64," + this.state.proPic.contentPic
                                                                        }
                                                                        style={{ "height": "80px" }}
                                                                    />


                                                                </div>
                                                                <div className="image-title">{this.state.proPic.contentName}</div>
                                                            </div>

                                                            :
                                                            <FileUpload
                                                                chooseLabel={this.state.proPic.contentName || uploadFileLabel}
                                                                mode="basic"
                                                                accept="image/*"
                                                                maxFileSize={1000000}
                                                                onSelect={this.onBasicUploadAuto.bind(this)}
                                                                auto={true}
                                                            />
                                                    }

                                                </div>


                                                {/* <center>
                                                    {this.state.uploadImage.contentName}
                                                </center> */}
                                            </div>
                                            <span className="error-message">{!this.state.proPic.contentName && errors["image"]}</span>
                                        </div>

                                        {/* <div className="p-col-12 p-xl-12 nw-upload-button">
                                                <FileUpload
                                                    mode="basic"
                                                    id="uploadImage"
                                                    accept="image/*"
                                                    maxFileSize={1000000}
                                                    onSelect={this.onBasicUploadAuto}
                                                    auto={true}
                                                    chooseLabel="Upload Photo"
                                                    autoWidth={false}
                                                />
                                                <center>{this.state.proPic.contentName}</center>
                                                <center><span className="error-message">{this.state.proPic.contentName === '' && errors['image']}</span></center>
                                        </div>

                                        <div className="p-col-12 p-xl-12" /> */}


                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div> 

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                label="Save"
                                                icon="fas fa-check"
                                                onClick={this.dressCodeOnSubmitHandler.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12 nw-data-table">
                                    {this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader():
                                        this.state.dressCodeInfoListVisible === true && <DressCodeInfoList countTableSize={(l)=>this.setState({totalDataFound:l})} genderList={genderList} />
                                        
                                    }
                                </div>

                            </div>
                        </div>
                        
                    </div>
                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link 
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div> 
                    
                }
            </div>
        );
    }
}