import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ChangeUserNameForm} from './ChangeUserNameForm';

export class ChangeUserNameDialog extends Component {
    constructor(props) {
        super(props);
        this.onHide = this.onHide.bind(this);
        this.state = {
            passwordIcon: true,
            passWordmatch: true,
            hidden: true,
            visible: false,
            changePassword: {
                    passwordSet: '',
                    passwordMatch: '12',
                    emptyPassword: false,
                }
            }
            this.onChangeHandlePassword = this.onChangeHandlePassword.bind(this)
            this.togglePasswordShow = this.togglePasswordShow.bind(this)
            this.onSubmit = this.onSubmit.bind(this)
        }
        onHide(event) {
            this.props.history.replace('/');
        }
        togglePasswordShow(event, props) {
            this.setState({
                hidden: !this.state.hidden,
                passwordIcon: !this.state.passwordIcon
            });
    
        }
        onChangeHandlePassword = (event, props) => {
            let { changePassword } = this.state;
            changePassword[event.target.name] = event.target.value;
            this.setState({ changePassword });
        }
        onSubmit = (event, props) => {
            let setPassword = this.state.changePassword.passwordSet
            let matchPassword = this.state.changePassword.passwordMatch
            if (setPassword === matchPassword) {
                this.setState({
                    visible: false,
                    updateprofileform:true
                })
            }
        }

    render() {
        
        return (
            <div>
                <Dialog header="Enter Password" className="nonHideDialog nw-dialog" onHide={this.onHide} visible={this.state.visible}>
                    {/* <p>Please enter your password for <b>update</b> your username</p> */}
                    <br />
                    <span className="p-float-label">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <InputText
                                                type={this.state.hidden ? "password" : "text"}
                                                name="passwordSet"
                                                className="custom-field-control"
                                                placeholder="Enter Password"
                                                onChange={this.onChangeHandlePassword}
                                                required="required"
                                                keyfilter="alphanum"
                                            />
                                            <Button
                                                icon={this.state.passwordIcon ? "far fa-eye-slash" : "far fa-eye"}
                                                className="p-button-secondary-custom"
                                                onClick={(event) => this.togglePasswordShow(event, this.props)} />
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                        <Button
                                            className="p-button-primary nw-button nw-button-right"
                                            label="Submit"
                                            icon="fas fa-check"
                                            onClick={this.onSubmit} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </span>
                    <br />
                </Dialog>
                <ChangeUserNameForm />
            </div>
        );
    }
}