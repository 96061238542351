import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import Formatter from '../../../utils/Formatter';
import { TreeTable } from 'primereact/treetable';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';

let partnerListArr = [];
let sumOfInstitute = 0;
let totalActiveInstitute = 0;
let totalInactiveInstitute = 0;
let totalActiveInstitutePercentage = 0;
let totalInactiveInstitutePercentage = 0;
let totalActiveRate = 0;
let totalInactiveRate = 0;
let totalActiveDue = 0;
let totalInactiveDue = 0;
let status = { display: 'none' };


let mbpListObj = {};
let zbpListObj = {};
let bdpListObj = {};
let bepListObj = {};

let maxDate = new Date();
export class EdumanBillGenerateByInstituteID extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue:[
                {billYear: 2020}
            ],
            viewAtaGlance: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,
            searchObj: {
                startDate: "",
                endDate: "",
                netiID: ""
            },
            errors: {},
            visible: false
        }

        this.ManagementPointService = new ManagementPointService;
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
        this.Formatter = new Formatter();
        this.CommonFuctionality = new CommonFuctionality();
    }

    billLogProcessBody = (rowData, column) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title='Click to view deposit details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    viewDialog = (rowData) => {
        this.setState({
            visible: true,
        })
    }

    onHide = () => {
        this.setState({ visible: false })
    }

    focusSearch = e => {
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white" });
    }

    blurSearch = e => {
        this.setState({ searchLabel: '', addSearchBtnClass: "" });
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let billLogHeader = <div className="header-title">
                        <div className="header-title-left">
                            Generated Bill Log
                        </div>


                        <div className="header-title-right">

                            {/* <Link to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 0 }}>
                                More<i className="fas fa-angle-right" ></i>
                            </Link> */}
                        </div>
                    </div>;

        let paidLogHeader = <div className="header-title">
                                <div className="header-title-left">
                                    Institute Bill Paid Log
                                </div>


                                <div className="header-title-right">

                                    {/* <Link to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 0 }}>
                                        More<i className="fas fa-angle-right" ></i>
                                    </Link> */}
                                </div>
                            </div>;

        let discountLogHeader = <div className="header-title">
                                <div className="header-title-left">
                                    Institute Bill Discount Log
                                </div>


                                <div className="header-title-right">

                                    {/* <Link to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 0 }}>
                                        More<i className="fas fa-angle-right" ></i>
                                    </Link> */}
                                </div>
                            </div>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section nw-tabview">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            Institute ID <span>*</span>
                                        </span>
                                        <InputText
                                            id="instituteID"
                                            style={{ width: "15%" }}
                                            name="instituteID"
                                            placeholder="Enter Institute ID"
                                            // onChange={event => this.purchaseCodeHandler(event)}
                                            keyfilter="pint"
                                            onFocus={this.focusSearch}
                                            onBlur={this.blurSearch}
                                        />
                                        <Button
                                            className={"p-button-animation " + this.state.addSearchBtnClass}
                                            label={this.state.searchLabel}
                                            icon="fas fa-search"
                                            onClick={(e) => this.purchaseCodeCheck(e)}
                                            onFocus={this.focusSearch}
                                            onBlur={this.blurSearch}
                                        />
                                    </div>
                                    <span className="error-message"></span>
                                </div>

                                
                            </div>
                        </div>

                        <div className="p-grid p-col-12 p-xl-12 ">

                            <div className="p-col-12 p-xl-6">
                                <div class="nw-search-view no-border p-t-0">
                                    <h2>Institute Info</h2>
                                    <br/>
                                    <div>
                                        <div class="p-grid p-col-12">
                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Institute Name</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            Name
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Institute Address</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            address
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Status</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            Active
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Package</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            Advance
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Institute Contact No.</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            address
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Partner Neti ID.</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            1000000001
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Partner Name</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            Name
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Partner Contact No.</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            address
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Partner Area</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            address
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-6">
                                <div class="nw-search-view no-border p-t-0">
                                    <h2>Payment Information</h2>
                                    <br/>
                                    <div>
                                        <div class="p-grid p-col-12">
                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Total Payable</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            20,73,645.00 Taka
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Total Paid</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            1,05,000.00 Taka
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Total Discount</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            73,645.00 Taka
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Total Due</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            95,000.00 Taka
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <hr style={{ width: '100%' }}/>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Last Billable Month</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            Jan 2020 - 24,500.00 Taka
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                            <TabView className="tabview-panel-height-0">
                                <TabPanel header="Bill Log" >
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-data-table nw-data-table-tabview">

                                            {/* {
                                                this.state.tableView === true ?
                                                    this.state.dataTableIsLoading ?
                                                        this.NetiContentLoader.MyPointTableLoader()
                                                        : */}
                                                        <div>
                                                            <DataTable
                                                                value={this.state.dataTableValue}
                                                                selectionMode="single"
                                                                header={billLogHeader}
                                                                selection={this.state.dataTableSelection}
                                                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                                responsive={true}
                                                                paginator={true}
                                                                rows={10}
                                                            >
                                                                <Column field="billYear" header="Bill Year" filter={true} />
                                                                <Column field="" header="Bill Month" filter={true} />
                                                                <Column field="" header="Academic Year" filter={true} />
                                                                <Column field="" header="Billable Student" filter={true} />
                                                                <Column field="" header="Disable Student" filter={true} />
                                                                <Column field="" header="Payable Amount" filter={true} />
                                                                <Column field="" header="Rate" filter={true} />
                                                                <Column field="" header="Process" body={ this.billLogProcessBody } />
                                                                
                                                            </DataTable>
                                                        </div>
                                                    {/* :
                                                    <BlankDataTableAnim />

                                            } */}

                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Paid Log">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-data-table nw-data-table-tabview">

                                            {/* {
                                                this.state.tableView === true ?
                                                    this.state.dataTableIsLoading ?
                                                        this.NetiContentLoader.MyPointTableLoader()
                                                        : */}
                                                        <div>
                                                            <DataTable
                                                                value={this.state.dataTableValue}
                                                                selectionMode="single"
                                                                header={paidLogHeader}
                                                                selection={this.state.dataTableSelection}
                                                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                                responsive={true}
                                                                paginator={true}
                                                                rows={10}
                                                            >
                                                                <Column field="" header="Date Time" filter={true} />
                                                                <Column field="" header="Bill Year" filter={true} />
                                                                <Column field="" header="Bill Month" filter={true} />
                                                                <Column field="" header="Paid Amount" filter={true} />
                                                                <Column field="" header="Invoice No" filter={true} />
                                                                
                                                            </DataTable>
                                                        </div>
                                                    {/* :
                                                    <BlankDataTableAnim />

                                            } */}

                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Discount Log">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-data-table nw-data-table-tabview">

                                            {/* {
                                                this.state.tableView === true ?
                                                    this.state.dataTableIsLoading ?
                                                        this.NetiContentLoader.MyPointTableLoader()
                                                        : */}
                                                        <div>
                                                            <DataTable
                                                                value={this.state.dataTableValue}
                                                                selectionMode="single"
                                                                header={discountLogHeader}
                                                                selection={this.state.dataTableSelection}
                                                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                                responsive={true}
                                                                paginator={true}
                                                                rows={10}
                                                            >
                                                                <Column field="" header="Date Time" filter={true} />
                                                                <Column field="" header="Bill Year" filter={true} />
                                                                <Column field="" header="Bill Month" filter={true} />
                                                                <Column field="" header="Discount Amount" filter={true} />
                                                                <Column field="" header="Discount Purpose" filter={true} />
                                                                
                                                            </DataTable>
                                                        </div>
                                                    {/* :
                                                    <BlankDataTableAnim />

                                            } */}

                                        </div>
                                    </div>
                                </TabPanel>
                            </TabView>

                        </div>
                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog" 
                        header="Generate Bill Details" 
                        visible={this.state.visible} 
                        onHide={this.onHide} 
                        closable
                    >
                        <div className="p-grid nw-form">
                            <div className="p-col-12">

                                <center>
                                    <h2>Bogura Zilla School, Bogura</h2>
                                    <p>Bogura Sadar, Bogura</p>
                                    <p>Institute ID: 10032</p>
                                </center>

                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div class="nw-search-view no-border p-t-0">
                                    
                                    <div>
                                        <div class="p-grid p-col-12">
                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Billing Year</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            2019
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Billing Month</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            December
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Academic Year</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            2020
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Monthly Rate</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            8.00 Taka
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Active Student</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            0
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {/* {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() : */}
                                                    <div className="p-inputgroup">
                                                        <span className="task-badge found"></span>
                                                        <span className="p-inputgroup-addon">Disable Student</span>
                                                        <span className="p-inputgroup-colon">:</span>

                                                        <div className="nw-inputgroup-desc">
                                                            0.00 Taka
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        Billable Student <span>*</span>
                                    </span>
                                    <InputText
                                        id="instituteID"
                                        style={{ width: "15%" }}
                                        name="instituteID"
                                        placeholder="Enter Billable Student"
                                        // onChange={event => this.purchaseCodeHandler(event)}
                                        keyfilter="pint"
                                    />
                                    
                                </div>
                                <span className="error-message"></span>
                            </div>

                            <div className="p-col-12 nw-button-parent">
                                <Button
                                    className="nw-button p-button-primary nw-button-right"
                                    label="Generate"
                                    icon="fas fa-retweet"
                                    // onClick={(e) => this.purchaseCodeCheck(e)}
                                />
                            </div>
                        </div>

                    </Dialog>
                </div>

                
            </div>
        )
    }
}