import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { PartnerPointService } from '../../../../service/partnerPoint/PartnerPointService';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../../common/JsPdfDownload';

let errors = {}
let maxDate = new Date();

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class EmPostpaidBillReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchObjInfo: {
                fromDate: '',
                toDate: ''
            },
            viewDetailsObject: {

            },
            dataTableValue: [],
            tableView: false,
            errors: {}
        };

        this.PartnerPointService = new PartnerPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
        this.exportPdf = this.exportPdf.bind(this);

    }

    onChangeStartDate = (e) => {
        let { searchObjInfo } = this.state;
        searchObjInfo.fromDate = e.target.value
        this.setState({ searchObjInfo });
        this.clearDateError(e.target.name);

    }

    onChangeEndDate = (e) => {
        let { searchObjInfo } = this.state;
        searchObjInfo.toDate = e.target.value
        this.setState({ searchObjInfo });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    paidAmountBody = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData['paidAmount'])}</div>)
    }

    detailsBody = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-download"
                onClick={(e) => this.downloadMoneyReceipt(rowData.billLogID)}
            />
        </div>
    }

    downloadMoneyReceipt = (param) => {
        this.PartnerPointService.downloadEdumanPostpaidMoneyReceipt(param);
    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    handleError = () => {
        let { errors, searchObjInfo } = this.state;
        let formIsValid = true;

        if (searchObjInfo.fromDate === '') {
            formIsValid = false;
            errors["fromDate"] = "From Date can't left empty.";
        }
        if (searchObjInfo.toDate === '') {
            formIsValid = false;
            errors["toDate"] = "To Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitHandler = (e, props) => {

        let { searchObjInfo } = this.state;
        if (this.handleError()) {
            this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(searchObjInfo.toDate) >= Date.parse(searchObjInfo.fromDate)) {
                let requestObj = {
                    "startDate": searchObjInfo.fromDate,
                    "endDate": searchObjInfo.toDate
                }

                this.PartnerPointService.findEmPostPaidBillsSummary(requestObj)
                    .then((res) => {
                        if (res.status == 302) {
                            return res.json().then((body) => {
                                for (let i = 0; i < body.length; i++) {
                                    if (body[i].paidDate == null) {
                                        body[i].paidDate = '';
                                    } else {
                                        body[i].paidDate = NetiDateUtils.getAnyShortForm(body[i].paidDate, 'DD-MMM-YYYY') + ' ' + NetiDateUtils.getHHMMSSFForHome(body[i].paidDate);
                                    }
                                }
                                this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                            });
                        } else {
                            this.PartnerPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });

            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be ahead of To Date." });
            }

        }

    }

    exportPdf() {

        let pdfColumns = [
            { title: "Date & Time", dataKey: "paidDate" },
            { title: "Institute ID", dataKey: "instituteID" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Year", dataKey: "billYear" },

            { title: "Month", dataKey: "billMonth" },
            { title: "Paid Amount", dataKey: "paidAmount" },
        ]

        let columnStyle = { paidAmount : { halign: 'right' } }

        pdfDownloadPortrait_TableWithSubTitle("Eduman Post Paid Bill Report", 75, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, columnStyle, "Eduman_PostPaid_Bill_Report.pdf");

    }

    render() {

        let { searchObjInfo, viewDetailsObject, dateRangeParamFormat, dataTableValue, errors } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (searchObjInfo.fromDate && searchObjInfo.toDate) {
            formatedStartDate = (searchObjInfo.fromDate.toLocaleDateString('en-GB'));
            formatedEndDate = (searchObjInfo.toDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        let tableHeader = <div className="header-title">
            <div className="header-title-left">
                Eduman Post-Paid Bill Report
                            </div>

            <div className="header-title-right">
                <Link to="#">
                    Total Found: {dataTableValue.length.toLocaleString("EN-IN")}
                </Link>
            </div>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12">

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">From Date <span>*</span></span>
                                        <Calendar
                                            value={searchObjInfo.fromDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            name="fromDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{errors["fromDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">To Date <span>*</span></span>
                                        <Calendar
                                            value={searchObjInfo.toDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            name="toDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{errors["toDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parrent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                            <div className="nw-data-table">
                                {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            : <div>
                                                <DataTable
                                                    value={dataTableValue}
                                                    header={tableHeader}
                                                    responsive={true}
                                                >
                                                    <Column field="paidDate" header="Date & Time" filter={true} />
                                                    <Column field="instituteID" header="Institute ID" filter={true} />
                                                    <Column field="instituteName" header="Institute Name" filter={true} />
                                                    <Column field="billYear" header="Year" filter={true} />
                                                    <Column field="billMonth" header="Month" filter={true} />
                                                    <Column field="paidAmount" header="Paid Amount" body={this.paidAmountBody} filter={true} />
                                                    <Column field="" header="Action" body={this.detailsBody} />
                                                </DataTable>
                                                <div className="nw-button-parent m-t-8 p-r-0">
                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                        label="Download PDF"
                                                        icon="fas fa-download"
                                                        onClick={this.exportPdf}
                                                    />
                                                </div>
                                            </div>
                                        :
                                        <BlankDataTableAnim />
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}