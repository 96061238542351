import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Dropdown } from 'primereact/dropdown';

// custom imports
import { DwsService } from '../../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { NetiCMSSetupProgress } from '../../common/NetiCMSSetupProgress';
import { ErrorMessageView } from '../../../common/ErrorMessageView';

let cmsId = 0;

export class ApplicantAnalytics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            submitSearch: false,

            academicYear: '',
            dataTableValue: [],
            totalCountObj: {}
        }

        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    onSubmitHandler() {

        this.setState({ submitSearch: true });
        if (this.state.academicYear) {
            this.getApplicantAnalyticsListByAcademicYear();
        }

    }

    getApplicantAnalyticsListByAcademicYear() {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.getAdmisiaApplicantAnalyticsList({ cmsId: cmsId, academicYear: this.state.academicYear })
            .then(res => {

                // console.log('res-body', res);
                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('body', body);

                        if (body.messageType == 1) {
                            if (body.item.length > 0) {
                                this.setState({ dataTableValue: body.item });
                                this.totalCounterFunc(body.item);
                                this.setState({ dataTableIsLoading: false, topProgressBar: false });
                            } else {
                                this.setState({ dataTableValue: [] });
                                this.totalCounterFunc([]);
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No data found.' });
                            }

                        } else {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    }))
                }

            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });

    }

    totalCounterFunc = (dataTableList) => {

        let totalApplicants = 0;
        let totalAssessmentPending = 0;
        let totalAssessmentRejected = 0;
        let totalAssessmentApproved = 0;
        let totalAdmissionPending = 0;
        let totalAdmissionWaiting = 0;
        let totalAdmissionRejected = 0;
        let totalAdmissionApproved = 0;
        let totalTransferred = 0;

        dataTableList.forEach(element => {
            totalApplicants += element.totalApplicant;
            totalAssessmentPending += element.pendingAssessment;
            totalAssessmentRejected += element.rejectedAssessment;
            totalAssessmentApproved += element.approvedAssessment;
            totalAdmissionPending += element.pendingAdmission;
            totalAdmissionWaiting += element.waitingAdmission;
            totalAdmissionRejected += element.rejectedAdmission;
            totalAdmissionApproved += element.approvedAdmission;
            totalTransferred += element.transferredAdmission;
        });

        let totalCountObj = {
            totalApplicants: totalApplicants,
            totalAssessmentPending: totalAssessmentPending,
            totalAssessmentRejected: totalAssessmentRejected,
            totalAssessmentApproved: totalAssessmentApproved,
            totalAdmissionPending: totalAdmissionPending,
            totalAdmissionWaiting: totalAdmissionWaiting,
            totalAdmissionRejected: totalAdmissionRejected,
            totalAdmissionApproved: totalAdmissionApproved,
            totalTransferred: totalTransferred
        }

        this.setState({ totalCountObj: totalCountObj })

    }

    render() {

        let { dataTableValue, totalCountObj, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let currentYear = new Date().getFullYear();
        let academicYearOptions = [
            { label: currentYear + 1, value: currentYear + 1 },
            { label: currentYear, value: currentYear },
            { label: currentYear - 1, value: currentYear - 1 },
            { label: currentYear - 2, value: currentYear - 2 },
            { label: currentYear - 3, value: currentYear - 3 },
            { label: currentYear - 4, value: currentYear - 4 },
        ];

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Applicant Analytics List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>;

        let headerGroup = <ColumnGroup>
            <Row>
                <Column field="class" header="Class" rowSpan={2} filter={true} />
                <Column field="group" header="Group" rowSpan={2} filter={true} />
                <Column header="Total Applicant" rowSpan={2} />
                <Column header="Assessment" colSpan={3} />
                <Column header="Admission" colSpan={4} />
                <Column header="Transferred" rowSpan={2} />
            </Row>

            <Row>
                <Column field="pendingAssessment" header="Pending" />
                <Column field="rejectedAssessment" header="Rejected" />
                <Column field="approvedAssessment" header="Approved" />

                <Column field="pendingAssessment" header="Pending" />
                <Column field="waitingAdmission" header="Waiting" />
                <Column field="rejectedAssessment" header="Rejected" />
                <Column field="approvedAssessment" header="Approved" />
            </Row>

        </ColumnGroup>;

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="Totals:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
                <Column footer={totalCountObj.totalApplicants} />
                <Column footer={totalCountObj.totalAssessmentPending} />
                <Column footer={totalCountObj.totalAssessmentRejected} />
                <Column footer={totalCountObj.totalAssessmentApproved} />

                <Column footer={totalCountObj.totalAdmissionPending} />
                <Column footer={totalCountObj.totalAdmissionWaiting} />
                <Column footer={totalCountObj.totalAdmissionRejected} />
                <Column footer={totalCountObj.totalAdmissionApproved} />

                <Column footer={totalCountObj.totalTransferred} />
            </Row>
        </ColumnGroup>;

        return (

            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section blur-section">

                    <div className="p-grid nw-form">
                        {/* <div className="p-grid "> */}
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-10">

                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Academic Year  <span>*</span></span>
                                        <Dropdown
                                            placeholder={"Select Academic Year"}
                                            filter={true}
                                            filterBy="label,value"
                                            options={academicYearOptions}
                                            value={this.state.academicYear}
                                            onChange={e => this.setState({ academicYear: e.value })}
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.academicYear === '' && this.state.submitSearch ? "Academic Year can't left empty" : ''}</span>
                                </div>

                                <div className="p-col-12 p-xl-2 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.homeReturnButton === false ?

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    header={tableHeader}
                                    headerColumnGroup={headerGroup}
                                    footerColumnGroup={footerGroup}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column field="class" header="Class" />
                                    <Column field="group" header="Group" />
                                    <Column field="totalApplicant" header="Total Applicant" />
                                    <Column field="pendingAssessment" header="Pending" />
                                    <Column field="rejectedAssessment" header="Rejected" />
                                    <Column field="approvedAssessment" header="Approved" />

                                    <Column field="pendingAdmission" header="Pending" />
                                    <Column field="waitingAdmission" header="Waiting" />
                                    <Column field="rejectedAdmission" header="Rejected" />
                                    <Column field="approvedAdmission" header="Approved" />
                                    <Column field="transferredAdmission" header="Transferred" />
                                </DataTable>

                                : this.NetiContentLoader.MyPointTableLoader()
                            }

                        </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

}