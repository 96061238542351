import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import { ValidatorUtility } from '../../../utils/ValidatorUtility';


let total = 0;
let totalPaidAmountBody = 0;
export class UserWallet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            SumValue: '',
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,

        }

        this.onHide = this.onHide.bind(this);
        this.paidDetailsBody = this.paidDetailsBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.ManagementPointService = new ManagementPointService;
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
    }

    componentWillMount() {
        this.fetchUserWalletInfo();
    }

    fetchUserWalletInfo = () => {
        let total = 0;
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.ManagementPointService.fetchUserWalletInfoList()
            .then((res) => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        totalPaidAmountBody = this.ValidatorUtility.currencyFormatter(body.map(item => item.userWalletBalance).reduce((a, b) => { return (a + b) }, 0))
                        body.map(item => {
                            item.userWalletBalance = item.userWalletBalance.toFixed(2)
                        })
                        this.setState({ dataTableValue: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    });
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection!!!' })

            );

    }

    userWalletBalanceTemplate = (rowData) => {
        return <div className="text-success col-number text-right" >
                    {this.ValidatorUtility.currencyFormatter(rowData.userWalletBalance)}
                </div>;
    }



    viewDialog(rowData) {
        // let {rowInformation} = this.state
        // rowInformation.salesDate = rowData.salesDate
        // rowInformation.productName = rowData.productName
        // rowInformation.purchaseCode = rowData.purchaseCode
        // rowInformation.salesPrice = rowData.salesPrice
        // rowInformation.customerName = rowData.customerName
        // rowInformation.customerContact = rowData.customerContact
        // rowInformation.salesNote = rowData.salesNote
        // this.setState({rowInformation})
        this.setState({ visible: true });
    }

    onHide(event) {
        this.setState({ visible: false });
    }

    paidDetailsBody(rowData, column) {

        return <div className='text-center'>
            <Button
                className=""
                icon="fas fa-info"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue } = this.state;
        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>User Wallet Info <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
                <Column footer={totalPaidAmountBody} />
            </Row>
        </ColumnGroup>;



        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <div className="nw-data-table">
                            {
                                this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    :
                                    <DataTable
                                        header={header}
                                        footerColumnGroup={footer}
                                        responsive={true}
                                        selectionMode="single"
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50]}
                                        value={this.state.dataTableValue}
                                    >
                                        <Column field="customNetiID" header="Neti ID" filter={true} />
                                        <Column field="fullName" header="Name" filter={true} />
                                        <Column field="userWalletBalance" header="Remaining Balance" body={this.userWalletBalanceTemplate} filter={true}
                                        />
                                    </DataTable>
                            }


                        </div>
                    </div>

                </div>



            </div>
        )
    }
}