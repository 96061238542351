import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';

export class SalesServices {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    addNewCustomer(createSalesNewUser) {
        let uri = this.baseURIHolder.getUser() + '/customer/new';
        return this.Auth.postFetch(uri,createSalesNewUser)
            .catch((error) => console.log('error', error));
    }

    findCustomerMobileNumber(mobileNumber) {
        let uri = this.baseURIHolder.getUser() + '/customer/find/by/contact?contact=' + mobileNumber;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getProductNameList(){
        let uri = this.baseURIHolder.getUser() + '/product/sales/byuser';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getTopSalesList() {
        let uri = this.baseURIHolder.getUser() + '/product/sales/log/by/top';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getPurchaseCodeByPurchaseLogID(purchaseID) {
        let uri = this.baseURIHolder.getUser() + '/purchase/codes/by/purchaseid?purchaseID='+purchaseID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getPurchaseCodeByProductID(productID) {
        let uri = this.baseURIHolder.getUser() + `/purchase/codes/by/productID?productID=${productID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getSalesListByDateRange(searchData) {
        let uri = this.baseURIHolder.getUser() + '/product/sales/log/by/date_range';
        return this.Auth.postFetch(uri, searchData)
            .catch((error) => console.log('error', error));
    }

    addNewSale(salesInfo, sendingSmsStatus) {
        let uri = this.baseURIHolder.getUser() + '/product/sales/new?sendingSmsStatus='+sendingSmsStatus;
        return this.Auth.postFetch(uri, salesInfo)
            .catch((error) => console.log('error', error));
    }
    
}
