import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import NetiContentLoader from '../../../src/components/common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';


export class MappingAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      instituteId: "",
      instituteDetails: "",
      bankList: [],
      bankName: "",
      accountNumberList: [],
      accountNumber: '',
      defaultId: "",
      noteValue: "",
      dataTableValue: [],
      selectedRowData: [],
      errors: {},
      updateErrors: {},
      firstDropdownIsLoading: false,
      secondDropdownIsLoading: false,
      searchIsloading: false,
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',
      searchErrorMsgBody: '',
      searchView: false,
      searchViewError: false,
    };

    this.onSearchInstituteId = this.onSearchInstituteId.bind(this);
    this.fetchBankList = this.fetchBankList.bind(this);
    this.onChangeBank = this.onChangeBank.bind(this);
    this.fetchAccountNumberList = this.fetchAccountNumberList.bind(this);
    this.onChangeAccNum = this.onChangeAccNum.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    this.viewDialog = this.viewDialog.bind(this);
    this.onHide = this.onHide.bind(this);

    this.fetchAlreadyMappedAccountList = this.fetchAlreadyMappedAccountList.bind(this);
    this.actionBody = this.actionBody.bind(this);
    this.onChangeBankUpdate = this.onChangeBankUpdate.bind(this);
    this.onChangeAccNumUpdate = this.onChangeAccNumUpdate.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);

    this.supportPointService = new SupportPointService();
    this.NetiContentLoader = new NetiContentLoader();

  }

  componentWillMount() { this.fetchBankList(); this.fetchAlreadyMappedAccountList() };

  onChangeInstitute = value => {
    this.clearErrors();
    this.setState({ instituteId: value })
  };

  onChangeBank(value) {
    this.clearErrors();
    this.setState({ bankName: value });
    this.fetchAccountNumberList(value)
  };

  onChangeAccNum(value) {
    this.clearErrors();
    this.setState({ accountNumber: value })
  };

  clearErrors = () => {
    this.setState({ errors: {} })
  }

  clearUpdateErrors = () => {
    this.setState({ updateErrors: {} })
  }

  onSearchInstituteId() {
    this.setState({ instituteDetails: '' })

    if (this.onSearchErrorHandle()) {
      this.setState({ searchView: true, searchIsloading: true, topProgressBar: true, errorMsgVisible: false })
      this.supportPointService.fetchInstituteDetailsByInstituteID(this.state.instituteId)
        .then(res => {
          if (res.status === 200) {
            return res.json().then(body => {
              this.setState({ instituteDetails: body.item });
              this.setState({ searchIsloading: false, topProgressBar: false, searchViewError: false, })

            });
          }
          // else if (res.status === 500) {
          //   res.text().then(body => {
          //     if (body.includes('"message":null')) {
          //       this.setState({ searchIsloading: false, topProgressBar: false, searchViewError: true, searchViewError: true, searchErrorMsgBody: 'Invalid Institute ID.' })
          //     }
          //     else {
          //       this.setState({ searchIsloading: false, searchView: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Connection problem.' })
          //     }
          //   })
          // }
          else {
            this.setState({ searchIsloading: false })
            return res.json().then(errorBody => {this.setState({ searchIsloading: false, topProgressBar: false, searchViewError: true, searchViewError: true, searchErrorMsgBody: 'Invalid Institute ID.' })})
          };
        }).catch(error => {
          this.setState({ searchIsloading: false, searchView: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Connection problem.' })
        });
    }
  }

  fetchBankList() {
    this.setState({ firstDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    this.supportPointService.fetchAllBankList()
      .then(res => {
        if (res.status === 200) {
          return res.json().then((body) => {
            this.setState({ bankList: body })
            this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          })
        } else {
          return res.json().then((errorBody) => {
            this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorBody.message });
          })
        };
      }).catch(error => {
        this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }

  fetchAccountNumberList(bankName) {
    this.setState({ secondDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    this.supportPointService.fetchAccountNumberListByBankId(bankName)
      .then(res => {
        if (res.status === 200) {
          return res.json().then((body) => {
            this.setState({ accountNumberList: body })
            this.setState({ secondDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          })
        } else {
          return res.json().then((errorBody) => {
            this.setState({ secondDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorBody });
          })
        };
      }).catch(error => {
        this.setState({ secondDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      })
  }

  onSubmitHandler() {
    if (this.onSubmitErrorHandle()) {
      let note = '';
      if (this.state.accountNumber) {
        this.state.accountNumberList.filter(type => {
          if (type.bankAccountId === this.state.accountNumber) { note = type.bankNote };
        });
      }

      this.supportPointService.submitForMappingAccount(this.state.accountNumber, this.state.instituteId, note)
        .then(res => {
          if (res.status === 200) {
            return res.text().then((body) => {
              if (body.includes('"responseCode":409')) {
                this.growl.show({ severity: 'error', summary: 'Error Message', detail: 'Duplicate entry not allowed.' });
              }
              else {
                this.growl.show({ severity: 'success', summary: 'Success', detail: 'Account Mapped Succesfully.' });
                this.setState({accountNumber:'',instituteId:'',bankName:'',instituteDetails:'',searchView: false});
                this.fetchAlreadyMappedAccountList();
              }

            });
          } else { return res.json().then((errorBody) => console.log(errorBody)) };
        })
    }

  }

  fetchAlreadyMappedAccountList() {
    this.setState({ dataTableIsLoading: true });
    this.supportPointService.fetchAllMappedAccountList()
      .then(res => {
        if (res.status === 200) {
          this.setState({ dataTableIsLoading: false });
          return res.json().then(body => this.setState({ dataTableValue: body }));
        } else {
          this.setState({ dataTableIsLoading: false }); return res.json().then(errorBody => console.log(errorBody))
        };
      }).catch(error => {
        this.setState({ dataTableIsLoading: false });
        console.log("error", 'Connection Problem')
      });
  }


  onSearchErrorHandle = () => {
    let { errors } = this.state;
    let formIsValid = true;
    this.setState({ searchView: false })

    if (this.state.instituteId === '') {
      formIsValid = false;
      errors['instituteId'] = "Institute ID can't left empty."
    }

    this.setState({ errors });
    return formIsValid;
  }

  onSubmitErrorHandle = () => {
    let { errors } = this.state;
    let formIsValid = true;

    if (this.state.instituteId === '') {
      formIsValid = false;
      errors['instituteId'] = "Institute ID can't left empty."
    }
    if (this.state.bankName === '') {
      formIsValid = false;
      errors['bankName'] = "Bank Name can't left empty."
    }
    if (this.state.accountNumber === '') {
      formIsValid = false;
      errors['accountNumber'] = "Account Number can't left empty."
    }

    this.setState({ errors });
    return formIsValid;
  }

  onUpdateErrorHandle = () => {
    let { updateErrors } = this.state;
    let formIsValid = true;

    if (this.state.selectedRowData['bankId'] === null) {
      formIsValid = false;
      updateErrors['bankName'] = "Bank Name can't left empty."
    }
    if (this.state.selectedRowData['bankAccountId'] === null) {
      formIsValid = false;
      updateErrors['accountNumber'] = "Account Number can't left empty."
    }

    this.setState({ updateErrors });
    return formIsValid;
  }

  viewDialog(rowData) {
    this.fetchAccountNumberList(rowData.bankId); this.setState({ selectedRowData: rowData, visible: true })
  };
  onHide() { this.setState({ visible: false }) };

  actionBody(rowData) {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button edit"
          icon="fas fa-pencil-alt"
          title="Update"
          onClick={e => this.viewDialog(rowData)}
        />
      </div>
    );
  }

  onChangeBankUpdate(value) {
    if (value) {
      this.clearUpdateErrors();
    }
    let { selectedRowData } = this.state;
    selectedRowData.bankId = value;
    this.setState({ selectedRowData, });
  };

  onChangeAccNumUpdate(value) {
    if (value) {
      this.clearUpdateErrors();
    }
    let { selectedRowData } = this.state;
    selectedRowData.bankAccountId = value;
    this.setState({ selectedRowData });
  };

  submitUpdate() {
    if (this.onUpdateErrorHandle()) {
      this.supportPointService.updateMappedAccount(this.state.selectedRowData)
        .then(res => {
          if (res.status === 200) {
            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Update successful.' });
            this.setState({ visible: false });
            this.fetchAlreadyMappedAccountList();
          } else { return res.text().then((errorBody) => console.log(errorBody)) };
        })
    }
  }

    focusSearch = e =>{
      this.setState({
          searchLabel:"Search",
          addSearchBtnClass:"nw-button p-button-primary text-color-white"
      });
  }

  blurSearch = e =>{
      this.setState({
          searchLabel:"",
          addSearchBtnClass:""
      });
  }


  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    let bankOptions = [];
    if (this.state.bankList && this.state.bankList.length) {
      bankOptions = this.state.bankList.map(item => ({ label: item.bankName, value: item.bankId }))
    }

    let accountNumOptions = [];
    if (this.state.accountNumberList && this.state.accountNumberList.length) {
      accountNumOptions = this.state.accountNumberList.map(item => ({ label: item.accountNumber, value: item.bankAccountId }))
    }

    // let headervalue = (
    //   <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
    //     
    //     <span style={{ float: "right" }}>
    //       Total Found: {this.state.dataTableValue.length}
    //     </span>
    //   </div>
    // );

    let headervalue = (<div className="header-title">
    <div className="header-title-left">
    Already Mapped Account List 
    </div>
    <div className="header-title-right">
        <a>
            Total Found: {this.state.dataTableValue.length.toLocaleString('EN-IN')}
        </a>
    </div>
</div>)

    let { instituteDetails } = this.state;

    return (
      <div className="p-fluid">
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <Growl ref={el => (this.growl = el)} />
        <div className="p-grid nw-form">
          <div className="p-col-12 p-xl-12">
            <div className="nw-form-body">

                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                    <InputText
                      placeholder="Enter Institute ID"
                      value={this.state.instituteId}
                      onChange={e => this.onChangeInstitute(e.target.value)}
                      keyfilter="pint"
                      type="text"
                      name="purchaseCode"
                      style={{ width:"15%" }}
                      onFocus={this.focusSearch}
                      onBlur={this.blurSearch}
                    />
                    <Button
                      className={"p-button-animation " + this.state.addSearchBtnClass}
                      label={this.state.searchLabel}
                      icon="fas fa-search"
                      onClick={this.onSearchInstituteId}
                      onFocus={this.focusSearch}
                      onBlur={this.blurSearch}
                    />
                  </div>
                  <span className='error-message'>{this.state.errors["instituteId"]}</span>
                </div>

                <div className="p-col-12 p-xl-12">
                  {this.state.searchView ?
                    <div className="nw-search-view">
                      {this.state.searchViewError ?
                        <center className="error-message">{this.state.searchErrorMsgBody || 'No Data Found'}</center> :
                        <div>
                          <div className="p-col-12 p-xl-12">
                            {this.state.searchIsloading ?
                              this.NetiContentLoader.normalFormInputField() :
                              <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                  Institute Name
                                  </span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                  {instituteDetails.instituteName || '-'}
                                </div>
                              </div>
                            }
                          </div>

                          <div className="p-col-12 p-xl-12">
                            {this.state.searchIsloading ?
                              this.NetiContentLoader.normalFormInputField() :
                              <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                  Institute Address
                                  </span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                  {instituteDetails.instituteAddress || '-'}
                                </div>
                              </div>
                            }
                          </div>
                        </div>}
                    </div> : ''
                  }
                </div>

                <div className="p-col-12 p-xl-12">
                  {this.state.firstDropdownIsLoading ?
                    this.NetiContentLoader.normalFormInputField() :
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Bank <span>*</span></span>
                      <Dropdown
                        placeholder="Select Bank"
                        options={bankOptions}
                        value={this.state.bankName}
                        onChange={e => this.onChangeBank(e.target.value)}
                        filter={true} filterBy="label,value"
                        autoWidth={false}
                      />
                    </div>
                  }
                  <span className='error-message'>{this.state.errors["bankName"]}</span>
                </div>

                <div className="p-col-12 p-xl-12">
                  {this.state.secondDropdownIsLoading ?
                    this.NetiContentLoader.normalFormInputField() :
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Account Number <span>*</span></span>
                      <Dropdown
                        placeholder="Select Account Number"
                        options={accountNumOptions}
                        value={this.state.accountNumber}
                        onChange={e => this.onChangeAccNum(e.target.value)}
                        filter={true} filterBy="label,value"
                        autoWidth={false}
                      />

                    </div>
                  }
                  <span className='error-message'>{this.state.errors["accountNumber"]}</span>
                </div>

                <div className="p-col-12 p-xl-12 nw-button-parent">

                  <div className="required-field">
                      (<span>*</span>) required fields
                  </div>
                  
                  <Button
                    className="p-button p-button-primary nw-button nw-button-right"
                    label="Save"
                    icon="fas fa-check"
                    onClick={this.onSubmitHandler}
                    disabled={this.state.instituteDetails ? false : true}
                  />
                </div>
            </div>

          </div>

        </div>



        <div className="p-col-12 p-xl-12">
          <div className="nw-data-table nw-data-table-tabview">
            {this.state.dataTableIsLoading ?
              this.NetiContentLoader.MyPointTableLoader() :
              <DataTable
                header={headervalue}
                value={this.state.dataTableValue}
                responsive={true}
                paginator={true}
                rows={10}
              >
                <Column field="instituteId" header="Institute ID" sortable={true} filter={true} />
                <Column field="bankName" header="Bank" sortable={true} filter={true} />
                <Column field="accountNumber" header="Account Number" sortable={true} filter={true} />
                <Column header="Action" body={this.actionBody} />
              </DataTable>
            }
            <Dialog
              header="Bank Info Update"
              visible={this.state.visible}
              className="nw-dialog"
              onHide={this.onHide}
              maximizable
            >
              <div className="p-fluid">
                
                <div className="p-col-12 p-xl-12">
                  <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                      <div className="nw-form-body">
                        <div className="p-col-12 p-xl-12">
                          <span>Institute ID : {this.state.selectedRowData.instituteId}</span>
                        </div>
                        
                          <div className="formControl">
                            <label>Bank Name <span>*</span></label>
                            <Dropdown
                              placeholder="Select Bank"
                              options={bankOptions}
                              value={this.state.selectedRowData.bankId || ''}
                              onChange={e => this.onChangeBankUpdate(e.target.value)}
                              filter={true}
                              filterBy="label,value"
                              style={{ width: "100%" }}
                              showClear={true}
                            />
                          </div>
                          <span className='error-message'>{this.state.updateErrors["bankName"]}</span>
                        
                          <div className="formControl">
                            <label>Account Number <span>*</span></label>
                            <Dropdown
                              placeholder="Select Account Number"
                              options={accountNumOptions}
                              value={this.state.selectedRowData.bankAccountId || ''}
                              onChange={e => this.onChangeAccNumUpdate(e.target.value)}
                              filter={true}
                              filterBy="label,value"
                              style={{ width: "100%" }}
                              showClear={true}
                            />
                          </div>
                          <span className='error-message'>{this.state.updateErrors["accountNumber"]}</span>
                      

                        <div className="p-col-12 p-xl-12 nw-button-parent">
                          <Button
                            className="p-button p-button-primary nw-button nw-button-right"
                            label="Update"
                            icon="fas fa-update"
                            onClick={this.submitUpdate}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
            </Dialog>

          </div>
        </div>
      </div>
    );
  }
}
