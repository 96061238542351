import React, { Component } from "react";
import classNames from "classnames";
import { AppTopbar } from "./AppTopbar";
import { StudentTopbar } from "./StudentTopbar";
import { AppBreadcrumb } from "./AppBreadcrumb";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppInlineProfile } from "./AppInlineProfile";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { Dashboard } from "./components/Dashboard";
import { Student } from "./components/Student";
import { Merchant } from "./components/Merchant";
import { Teacher } from "./components/Teacher";
import { Parent } from "./components/Parent";
import { FormsDemo } from "./components/FormsDemo";
import { SampleDemo } from "./components/SampleDemo";
import { DataDemo } from "./components/DataDemo";
import { PanelsDemo } from "./components/PanelsDemo";
import { OverlaysDemo } from "./components/OverlaysDemo";
import { MenusDemo } from "./components/MenusDemo";
import { MessagesDemo } from "./components/MessagesDemo";
import { MiscDemo } from "./components/MiscDemo";
import { EmptyPage } from "./components/EmptyPage";
import { Documentation } from "./components/Documentation";
import { ScrollPanel } from "primereact/components/scrollpanel/ScrollPanel";
import {ClassTest} from './components/emUserPoint/studentPortal/ClassTest';
import { StudentProfile } from "./components/emUserPoint/studentPortal/StudentProfile";

import AuthService from "./auth/AuthService";
import "fullcalendar/dist/fullcalendar.css";
import "./App.css";

import { HomeDashboard } from "./components/HomeDashboard";

/************ My Point *************/
import { MyProfile } from "./components/myPoint/myProfile/MyProfile";
import { MyPointDashboard } from "./components/myPoint/MyPointDashboard";
import { MainDashboard } from "./components/myPoint/dashboard/MainDashboard";
import { ViewProfile } from "./components/myPoint/profile/ViewProfile";
import { UpdateProfile } from "./components/myPoint/profile/UpdateProfile";
import { ChangePassword } from "./components/myPoint/profile/ChangePassword";
import { ChangeUserNameDialog } from "./components/myPoint/profile/ChangeUserNameDialog";

import { WalletMain } from "./components/myPoint/balance/WalletMain";
import { Testing } from "./components/myPoint/balance/Testing";

import { MessageRecharge } from "./components/myPoint/balance/MessageRecharge";
import { MessageLogRecharge } from "./components/myPoint/reports/balanceLog/MessageLogRecharge";

// import { PurchaseGeneralProduct } from "./components/myPoint/purchase/PurchaseGeneralProduct";
import { PurchaseGeneralProductClone } from "./components/myPoint/purchase/PurchaseGeneralProductClone";
import { PurchaseOfferProduct } from "./components/myPoint/purchase/PurchaseOfferProduct";

import { WalletLog } from "./components/myPoint/reports/balanceLog/WalletLog";
import { BalanceStatement } from "./components/myPoint/reports/balanceStatement/BalanceStatement";

import { PurchaseGeneralProductViewMoreDetails } from "./components/myPoint/reports/purchaseLog/PurchaseGeneralProductViewMoreDetails";
import { PurchaseLogOfferProduct } from "./components/myPoint/reports/purchaseLog/PurchaseLogOfferProduct";
import { PurchaseCodeLogMain } from "./components/myPoint/reports/purchaseCodeLog/PurchaseCodeLogMain";
import { UnusedPurchaseCodeList } from "./components/myPoint/reports/purchaseCodeLog/UnusedPurchaseCodeList";
import { UsedPurchaseCodeList } from "./components/myPoint/reports/purchaseCodeLog/UsedPurchaseCodeList";
import { ChatBox } from "./components/myPoint/chatbox/ChatBox";
import { ChatBoxList } from "./components/myPoint/chatbox/ChatBoxList";

import { CustomerInfo } from "./components/myPoint/sellProduct/CustomerInfo";
import { CustomerInfoLog } from "./components/myPoint/reports/sellProductLog/CustomerInfoLog";

import { SellProduct } from "./components/myPoint/sellProduct/SellProduct";
import { SellProductLogViewMore } from "./components/myPoint/reports/sellProductLog/SellProductLogViewMore";
/************ End My Point *************/

/************ EM User Point *************/
// import { AddPortal } from './components/emUserPoint/AddPortal';
import { GoToPortal } from "./components/emUserPoint/GoToPortal";
import { StudentPortalMain } from "./components/emUserPoint/studentPortal/StudentPortalMain";

import { AddPortalForStudent } from "./components/emUserPoint/AddPortalForStudent";
import { AddPortalForGuardian } from "./components/emUserPoint/AddPortalForGuardian";
import { AddPortalForEmployee } from "./components/emUserPoint/AddPortalForEmployee";

import { TeacherProfile } from "./components/emUserPoint/teacherPortal/TeacherProfile";
import { AddEmUserPoint } from "./components/emUserPoint/AddEmUserPoint";
import { Attendance } from "./components/emUserPoint/studentPortal/Attendance";
import { TeacherPortalMain } from "./components/emUserPoint/teacherPortal/TeacherPortalMain";
/************ End EM User Point *************/

/************ Start DWS Point *************/
import { Administration } from "./components/dwsPoint/contentManage/Administration";
import { Speech } from "./components/dwsPoint/contentManage/Speech";
import { AboutUs } from "./components/dwsPoint/contentManage/AboutUs";
import { DressCode } from "./components/dwsPoint/contentManage/DressCode";
import { FeesInfo } from "./components/dwsPoint/contentManage/FeesInfo";
import { SyllabusInfo } from "./components/dwsPoint/contentManage/SyllabusInfo";
import { SeatInfo } from "./components/dwsPoint/contentManage/SeatInfo";
import { EventLog } from "./components/dwsPoint/contentManage/EventLog";
import { DwsMenuConfig } from "./components/dwsPoint/settings/DwsMenuConfig";
import { MappedInstitute } from "./components/dwsPoint/settings/MappedInstitute";
import { PhotoGallery } from "./components/dwsPoint/contentManage/PhotoGallery";
import { BookList } from "./components/dwsPoint/contentManage/BookList";
import { ImportantLink } from "./components/dwsPoint/contentManage/ImportantLink";
import { DownloadCorner } from "./components/dwsPoint/contentManage/DownloadCorner";
import { Notice } from "./components/dwsPoint/contentManage/Notice";
import { DwsHome } from "./components/dwsPoint/settings/DwsHome";
import { CmsMenuConfig } from "./components/dwsPoint/settings/CmsMenuConfig";
import { DwsDashboard } from "./components/dwsPoint/DwsDashboard";
import { CmsThemeSetup } from "./components/dwsPoint/settings/CmsThemeSetup";
import { CmsVisitorFeedback } from "./components/dwsPoint/CmsVisitorFeedback";
/************ END DWS Point *************/

/**************Bijoy Point ************/
import { BijoyContentDownload } from "./components/bijoyPoint/download/BijoyContentDownload";
import { SoftwareDownload } from "./components/bijoyPoint/download/SoftwareDownload";
import { ExistingUser } from "./components/bijoyPoint/deviceUnlock/ExistingUser";
import { DeviceNewUser } from "./components/bijoyPoint/deviceUnlock/DeviceNewUser";
/************** End Bijoy Point ************/

/************ Start EM Admin Point *************/
import { EmPaidBill } from "./components/emAdminPoint/EmPrePaidBill";
import { EmPostPaidBill } from "./components/emAdminPoint/EmPostPaidBill";
import { EmSmsTransfer } from "./components/emAdminPoint/EmSmsTransfer";
import { CRMPointAssign } from "./components/crmPoint/CRMPointAssign";
import { EmAuditPointTagging } from "./components/emAdminPoint/EmAuditPointTagging";
import { CRMPointAssignedList } from "./components/crmPoint/CRMPointAssignedList";
import { EmYssConfiguredList } from "./components/emAdminPoint/EmYssConfiguredList";
import { EmAuditPointTaggingList } from "./components/emAdminPoint/EmAuditPointTaggingList";

import { OnlinePaymentSystemEMAdminPoint } from "./components/emAdminPoint/ops/OnlinePaymentSystemEMAdminPoint";
import { OpsCollectionSummary } from "./components/emAdminPoint/ops/OpsCollectionSummary";
import { OpsCollectionDetails } from "./components/emAdminPoint/ops/OpsCollectionDetails";
import { OpsCollectionRefundReport } from "./components/emAdminPoint/ops/OpsCollectionRefundReport";
import { OnlineCollectionRefundSummary } from "./components/emAdminPoint/ops/OnlineCollectionRefundSummary";

/************ End EM Admin Point *************/

/************ Start SA Point *************/
import { GenderAndReligionWiseStudentInfo } from "./components/saPoint/student/GenderAndReligionWiseStudentInfo";
import { Token } from "./components/myPoint/profile/Token";
import { MyPointTokenTable } from "./components/myPoint/profile/MyPointTokenTable";
import { GenderAndReligionWiseStaffInfo } from "./components/saPoint/staff/GenderAndReligionWiseStaffInfo";
import { StudentAttendanceSummary } from "./components/saPoint/attendance/StudentAttendanceSummary";
import { StaffAttendanceSummary } from "./components/saPoint/attendance/StaffAttendanceSummary";
import { DateWiseStudentFeeCollectionSummary } from "./components/saPoint/accounts/DateWiseStudentFeeCollectionSummary";
import { DateWiseCashFlowSummary } from "./components/saPoint/accounts/DateWiseCashFlowSummary";
import { InstitueSmsSummary } from "./components/saPoint/common/InstitueSmsSummary";
// import { DivisionWiseTeacherAttendanceInformationTable } from './components/saPoint/attendance/DivisionWiseAttendanceInformation';
import { DivisionWiseAttendence } from "./components/saPoint/attendance/DivisionWiseAttendence";
import { DevisionWiseAttendenceMap } from "./components/saPoint/attendance/DevisionWiseAttendenceMap";

import { DistrictWiseAttendance } from "./components/saPoint/attendance/DistrictWiseAttendance";
import { InstituteWiseAttendance } from "./components/saPoint/attendance/InstituteWiseAttendance";
/************ End SA Point *************/

/************ Start Admin Point *************/
import { BankAccountInfo } from "./components/adminPoint/coreConfig/BankAccountInfo";
import { PartnerList } from "./components/adminPoint/partnerConfig/PartnerList";
import { NewPartnerAssign } from "./components/adminPoint/partnerConfig/NewPartnerAssign";
import { PartnerIncomeTarget } from "./components/adminPoint/partnerConfig/PartnerIncomeTarget";
import { UserNature } from "./components/adminPoint/coreConfig/UserNature";

import { ProductInfo } from "./components/adminPoint/productConfig/ProductInfo";
import { ProductUserRole } from "./components/adminPoint/productConfig/ProductUserRole";
import { ProductOffer } from "./components/adminPoint/productConfig/ProductOffer";
import { ProductList } from "./components/adminPoint/productConfig/ProductList";
import { ProductRevenueMain } from "./components/adminPoint/productConfig/ProductRevenueMain";

import { CreateNotice } from "./components/adminPoint/createInfo/CreateNotice";
import { CreateReleaseNote } from "./components/adminPoint/createInfo/CreateReleaseNote";
import { CreateBanner } from "./components/adminPoint/createInfo/AdminPointBanner";
import { CreateGlobalCalendar } from "./components/adminPoint/createInfo/AdminPointGlobalCalendar";
import { CategoryInfo } from "./components/adminPoint/coreConfig/CategoryInfo";
import { CmsMenuItem } from "./components/adminPoint/coreConfig/CmsMenuItem";
import { AdminPointDownloadCorner } from "./components/adminPoint/createInfo/AdminPointDownloadCorner";
import { AdminPointAddTutorial } from "./components/adminPoint/tutorial/AdminPointAddTutorial";
import { AdminPointViewTutorial } from "./components/adminPoint/tutorial/AdminPointViewTutorial";
import { AdminPointAssign } from "./components/adminPoint/pointAssign/AdminPointAssign";
import { CoreBankInfo } from "./components/adminPoint/coreConfig/CoreBankInfo";
import { DfpBankInfo } from "./components/adminPoint/dfpBank/DfpBankInfo";
import { BankBranchList } from "./components/adminPoint/BankBranchList";
import { AdminUserNature } from "./components/adminPoint/AdminUserNature";
import { AdminAssingPoint } from "./components/adminPoint/AdminAssingPoint";

import { OtherProductInfo } from "./components/adminPoint/OtherProductInfo";

/************ End Admin Point *************/

//************* Start Support Point***************
import { CreateCallLog } from "./components/supportPoint/CreateCallLog";
import { BijoyDeviceIdReplace } from "./components/supportPoint/BijoyDeviceIdReplace";
import { DwsGlobalBookAdd } from "./components/supportPoint/DwsGlobalBookAdd";
import { SupportDwsMenuConfig } from "./components/supportPoint/SupportDwsMenuConfig";
import { ManageToken } from "./components/supportPoint/ManageToken";
import { MappingAccount } from "./components/supportPoint/MappingAccount";
import { ObserverPointInfo } from "./components/supportPoint/ObserverPointInfo";

//*************End Support Point***************

/************ Start Partner Point *************/

import { PrePaidBill } from "./components/partnerPoint/PrePaidBill";
import { PostPaidBill } from "./components/partnerPoint/PostPaidBill";
import { MessageTransfer } from "./components/partnerPoint/MessageTransfer";
import { DomainOrderMain } from "./components/partnerPoint/DomainOrderMain";
import { PointAssign } from "./components/partnerPoint/PointAssign";
import { PointAssignList } from "./components/partnerPoint/PointAssignList";
import { YssConfigList } from "./components/partnerPoint/YssConfigList";
import { EdumanOrderMain } from "./components/partnerPoint/EdumanOrderMain";
import { AssignEmInstitute } from "./components/partnerPoint/AssignEmInstitute";
import { PartnerRatingProvideToTeamLead } from "./components/partnerPoint/rating/PartnerRatingProvideToTeamLead";
import { PartnerRatingProvideToTeamMember } from "./components/partnerPoint/rating/PartnerRatingProvideToTeamMember";
import { EdumanRatingProvideByPartner } from "./components/partnerPoint/rating/EdumanRatingProvideByPartner";
import { PartnerPointDueStatement } from "./components/partnerPoint/report/dueStatement/PartnerPointDueStatement";
import { PartnerInformation } from "./components/partnerPoint/report/dueStatement/PartnerInformation";
import { PartnerBDPDueStatement } from "./components/partnerPoint/report/dueStatement/PartnerBDPDueStatement";
import { PartnerBEPDueStatement } from "./components/partnerPoint/report/dueStatement/PartnerBEPDueStatement";
import { PartnerYssConfigMain } from "./components/partnerPoint/PartnerYssConfigMain";
import { SendNotificationSms } from "./components/managementPoint/othersReport/SendNotificationSms";
import { PartnerPointProductPurchaseDetails } from "./components/partnerPoint/report/PartnerPointProductPurchaseDetails";
import { PartnerAreaWisePostpaidCollection } from "./components/partnerPoint/report/PartnerAreaWisePostpaidCollection";
import { PrepaidBillCollection } from "./components/partnerPoint/report/partnerBillCollection/PrepaidBillCollection";
import { partnerBillCollectionListMain } from "./components/partnerPoint/report/partnerBillCollection/partnerBillCollectionListMain";
import { PartnerAreaWiseProductList } from "./components/partnerPoint/report/PartnerAreaWiseProductList";
import { PartnerAreaWisePrepaidDeposit } from "./components/partnerPoint/report/PartnerAreaWisePrepaidDeposit";
import { PartnerDateAndYearWiseIncomeSummary } from "./components/partnerPoint/report/partnerIncomeSummary/PartnerDateAndYearWiseIncomeSummary";
import { PartnerTeamWisePurchaseDetails } from "./components/partnerPoint/report/PartnerTeamWisePurchaseDetails";
import { PartnerSelfDashboard } from "./components/partnerPoint/dashboard/PartnerSelfDashboard";
import { PartnerTeamDashboard } from "./components/partnerPoint/dashboard/PartnerTeamDashboard";
import { PartnerPerfomanceDashboard } from "./components/partnerPoint/dashboard/PartnerPerfomanceDashboard";
import { PartnerPointDueStatementTree } from "./components/partnerPoint/report/dueStatement/PartnerPointDueStatementTree";
import { PartnerTeamBillCollection } from "./components/partnerPoint/report/PartnerTeamBillCollection";

/************ End Partner Point *************/

/************ Start Admisia Point *************/
import { InstituteConfigHome } from "./components/admisiaPoint/settings/InstituteConfigHome";
import { CoreConfigHome } from "./components/admisiaPoint/settings/CoreConfigHome";

import { ApplicationConfigHome } from "./components/admisiaPoint/settings/ApplicationConfigHome";
import { AdmisiaPointMappedInstitute } from "./components/admisiaPoint/settings/AdmisiaPointMappedInstitute";

import { AdmisiaPointAssessment } from "./components/admisiaPoint/approval/AdmisiaPointAssessment";
import { AdmisiaPointAdmission } from "./components/admisiaPoint/approval/AdmisiaPointAdmission";
import { AdmisiaPointTransfer } from "./components/admisiaPoint/approval/AdmisiaPointTransfer";
import { SendSmsApplicant } from "./components/admisiaPoint/SendSmsApplicant";

import { ApplicantUpdateInfo } from "./components/admisiaPoint/ApplicantUpdateInfo";

import { AdmisiaApplicantAnalytics } from "./components/admisiaPoint/reports/AdmisiaApplicantAnalytics";
import { AdmisiaCollectionAnalytics } from "./components/admisiaPoint/reports/AdmisiaCollectionAnalytics";
import { AdmisiaCollectionInfo } from "./components/admisiaPoint/reports/AdmisiaCollectionInfo";
import { AdmisiaApplicantStatus } from "./components/admisiaPoint/reports/AdmisiaApplicantStatus";
import { AdmisiaApplicantInfo } from "./components/admisiaPoint/reports/AdmisiaApplicantInfo";
import { AdmisiaAtaGlance } from "./components/admisiaPoint/reports/AdmisiaAtaGlance";

/************ End Admisia Point *************/

/************ Management Point *************/
import { OnlinePaymentSystem } from "./components/managementPoint/OnlinePaymentSystem";
import { AccumulateStatement } from "./components/managementPoint/edumanBillReport/AccumulateStatement";
import { YearlyStatement } from "./components/managementPoint/edumanBillReport/YearlyStatement";
import { MonthlyStatement } from "./components/managementPoint/edumanBillReport/MonthlyStatement";
import { CollectiveStatementMain } from "./components/managementPoint/edumanBillReport/CollectiveStatementMain";

// import { DueStatementMain } from './components/managementPoint/edumanBillReport/dueStatement/DueStatementMain';
import { MbpDueStatement } from "./components/managementPoint/edumanBillReport/dueStatement/MbpDueStatement";
import { ZbpDueStatement } from "./components/managementPoint/edumanBillReport/dueStatement/ZbpDueStatement";
import { BdpDueStatement } from "./components/managementPoint/edumanBillReport/dueStatement/BdpDueStatement";
import { BepDueStatement } from "./components/managementPoint/edumanBillReport/dueStatement/BepDueStatement";
import { InstituteDueStatement } from "./components/managementPoint/edumanBillReport/dueStatement/InstituteDueStatement";

import { UserWallet } from "./components/managementPoint/balanceReport/UserWallet";

import { MonthlyTransaction } from "./components/managementPoint/balanceReport/MonthlyTransaction";
import { MonthlyDepositAmountList } from "./components/managementPoint/balanceReport/MonthlyDepositAmountList";
import { MonthlyWithdrawAmountList } from "./components/managementPoint/balanceReport/MonthlyWithdrawAmountList";
import { MonthlyTransferAmountList } from "./components/managementPoint/balanceReport/MonthlyTransferAmountList";
import { MonthlyPurchaseAmountList } from "./components/managementPoint/balanceReport/MonthlyPurchaseAmountList";
import { MonthlyDisbursmentAmountList } from "./components/managementPoint/balanceReport/MonthlyDisbursmentAmountList";

import { DailyTransaction } from "./components/managementPoint/balanceReport/DailyTransaction";
import { DailyDepositAmountList } from "./components/managementPoint/balanceReport/DailyDepositAmountList";
import { DailyWithdrawAmountList } from "./components/managementPoint/balanceReport/DailyWithdrawAmountList";
import { DailyTransferAmountList } from "./components/managementPoint/balanceReport/DailyTransferAmountList";
import { DailyPurchaseAmountList } from "./components/managementPoint/balanceReport/DailyPurchaseAmountList";
import { DailyDisbursmentAmountList } from "./components/managementPoint/balanceReport/DailyDisbursmentAmountList";

import { BalanceReportMessageTransfer } from "./components/managementPoint/balanceReport/BalanceReportMessageTransfer";
import { ManagementPointPrepaidDepositBalance } from "./components/managementPoint/balanceReport/ManagementPointPrepaidDepositBalance";
import { ManagementPointPrepaidDepositTreeView } from "./components/managementPoint/balanceReport/ManagementPointPrepaidDepositTreeView";

import { PartnerIncomeStatement } from "./components/managementPoint/partnerReport/PartnerIncomeStatement";
import { PartnerIncomeStatementDetails } from "./components/managementPoint/partnerReport/PartnerIncomeStatementDetails";
import { ManagementPointPartnerList } from "./components/managementPoint/partnerReport/ManagementPointPartnerList";
import { ManagementPointPartnerArea } from "./components/managementPoint/partnerReport/ManagementPointPartnerArea";
import { ManagementPointPartnerTree } from "./components/managementPoint/partnerReport/ManagementPointPartnerTree";
import { ManagementPointPartnerIncomeSummary } from "./components/managementPoint/partnerReport/ManagementPointPartnerIncomeSummary";
import { ManagementPointPartnerIncomeTreeView } from "./components/managementPoint/partnerReport/ManagementPointPartnerIncomeTreeView";
import { ManagementPointPartnerProductPurchaseTreeView } from "./components/managementPoint/partnerReport/ManagementPointPartnerProductPurchaseTreeView";

import { ProductPurchaseRevenue } from "./components/managementPoint/revenueReport/ProductPurchaseRevenue";
import { ProductPrePurchase } from "./components/managementPoint/revenueReport/ProductPrePurchase";
import { ProductPrePurchaseDetails } from "./components/managementPoint/revenueReport/ProductPrePurchaseDetails";

import { PointExpiryInfo } from "./components/managementPoint/pointReport/PointExpiryInfo";

import { ApplicationWiseToken } from "./components/managementPoint/serviceReport/applicationWiseToken/ApplicationWiseToken";
import { ModuleWiseToken } from "./components/managementPoint/serviceReport/applicationWiseToken/ModuleWiseToken";
import { ProblemWiseToken } from "./components/managementPoint/serviceReport/applicationWiseToken/ProblemWiseToken";
import { TitleWiseToken } from "./components/managementPoint/serviceReport/applicationWiseToken/TitleWiseToken";

import { PersonWiseToken } from "./components/managementPoint/serviceReport/personWiseToken/PersonWiseToken";
import { PersonWiseTokenSolvedList } from "./components/managementPoint/serviceReport/personWiseToken/PersonWiseTokenSolvedList";
import { PersonWiseReceivedCallList } from "./components/managementPoint/serviceReport/personWiseToken/PersonWiseReceivedCallList";

import { NetiChatHistory } from "./components/managementPoint/serviceReport/netiChat/NetiChatHistory";

import { ManagementPointProductPurchaseDetails } from "./components/managementPoint/productReport/ManagementPointProductPurchaseDetails";

import { ManagementEMCurrentStatus } from "./components/managementPoint/dashboard/ManagementEMCurrentStatus";
import { MpPartnerPerfomanceDashboard } from "./components/managementPoint/dashboard/MpPartnerPerfomanceDashboard";
import { PartnerBasicInfo } from "./components/managementPoint/othersReport/PartnerBasicInfo";
import { MPAreaWisePartnerInfo } from "./components/managementPoint/othersReport/MPAreaWisePartnerInfo";
import { MPRegionWisePaidUnpaidInstituteInfoTree } from "./components/managementPoint/othersReport/MPRegionWisePaidUnpaidInstituteInfoTree";
import { MPProductSellTreeView } from "./components/managementPoint/othersReport/MPProductSellTreeView";
import { MPPartnerDashboardMBPType } from "./components/managementPoint/othersReport/MPPartnerDashboardMBPType";
import { EmImplementationSMS } from "./components/managementPoint/othersReport/EmImplementationSMS";
import { EmImplementationStudentAndGeneralAccounts } from "./components/managementPoint/othersReport/EmImplementationStudentAndGeneralAccounts";
import { EmTotalActionLogSummaryChart } from "./components/managementPoint/othersReport/EmTotalActionLogSummaryChart";
import { ModuleAndPointWiseTokenSummary } from "./components/managementPoint/othersReport/applicationWiseToken/ModuleAndPointWiseTokenSummary";
import { PendingProcessingTokenListEmNw } from "./components/managementPoint/othersReport/applicationWiseToken/PendingProcessingTokenListEmNw";

import { MpSolvedModuleWiseToken } from "./components/managementPoint/othersReport/applicationWiseToken/MpSolvedModuleWiseToken";
import { MpSolvedProblemWiseToken } from "./components/managementPoint/othersReport/applicationWiseToken/MpSolvedProblemWiseToken";
import { MpSolvedTitleWiseToken } from "./components/managementPoint/othersReport/applicationWiseToken/MpSolvedTitleWiseToken";

import { EdumanBillGenerateByInstituteID } from "./components/managementPoint/othersReport/EdumanBillGenerateByInstituteID";
import { MpAreaWiseAttendanceTreeView } from "./components/managementPoint/othersReport/MpAreaWiseAttendanceTreeView";

import { MpNetiCMSDashboard } from "./components/managementPoint/othersReport/NetiCMSDashboard/MpNetiCMSDashboard";
import { MpNetiCMSConfiguredUrlList } from "./components/managementPoint/othersReport/NetiCMSDashboard/MpNetiCMSConfiguredUrlList";
import { MpNetiCMSNotConfiguredUrlList } from "./components/managementPoint/othersReport/NetiCMSDashboard/MpNetiCMSNotConfiguredUrlList";
import { MpNetiCMSInfoFilledPercent } from "./components/managementPoint/othersReport/NetiCMSDashboard/MpNetiCMSInfoFilledPercent";
import { MpNetiCMSInfoNotFilledPercent } from "./components/managementPoint/othersReport/NetiCMSDashboard/MpNetiCMSInfoNotFilledPercent";
import { MpNetiCMSInfoEdumanMapped } from "./components/managementPoint/othersReport/NetiCMSDashboard/MpNetiCMSInfoEdumanMapped";
import { MpNetiCMSInfoEdumanUnmapped } from "./components/managementPoint/othersReport/NetiCMSDashboard/MpNetiCMSInfoEdumanUnmapped";
import { MpCallInfoDashboard } from "./components/managementPoint/othersReport/callInfoDashBoard/MpCallInfoDashboard";
import { BalanceMismatchInfo } from "./components/managementPoint/balanceReport/BalanceMismatchInfo";
import { DateWiseCollection } from "./components/managementPoint/mfsReport/DateWiseCollection";
import { InstituteWiseCollection } from "./components/managementPoint/mfsReport/InstituteWiseCollection";
import { TransactionIdWiseCollection } from "./components/managementPoint/mfsReport/TransactionIdWiseCollection";
import { DateRangeWisePurchaseReport } from "./components/managementPoint/productReport/DateRangeWisePurchaseReport";
/************ Management Point *************/

/**************MENU JS FILE ************/
import { MyPointMenu, MyPointReportMenu } from "./utils/MyPointMenu";
import { EmUserPointMenu } from "./utils/EmUserPointMenu";
import { CMSPointMenu } from "./utils/CMSPointMenu";
import { BijoyPointMenu } from "./utils/BijoyPointMenu";
import { EMAdminPointMenu } from "./utils/EMAdminPointMenu";
import { ObserverPointMenu } from "./utils/ObserverPointMenu";
import { AdminPointMenu } from "./utils/AdminPointMenu";
import { EmStudentPointMenu } from "./utils/EmStudentPointMenu";
import { EmMerchantPointMenu } from "./utils/EmMerchantPointMenu";
import { EmTeacherPointMenu } from "./utils/EmTeacherPointMenu";
import { EmParentPointMenu } from "./utils/EmParentPointMenu";
import { SupportPointMenu } from "./utils/SupportPointMenu";
import { PartnerPointMenu } from "./utils/PartnerPointMenu";
import { AdmisiaPointMenu } from "./utils/AdmisiaPointMenu";
import { CRMPointMenu } from "./utils/CRMPointMenu";
import { ManagementPointMenu } from "./utils/ManagementPointMenu";
import {
  MY_POINT,
  DWS_POINT,
  SA_POINT,
  SUPPORT_POINT,
  ADMIN_POINT,
  PARTNER_POINT,
  BIJOY_POINT,
  EM_USER_POINT,
  EM_ADMIN_POINT,
  ADMISIA_POINT,
  CRM_POINT,
  ADD_PORTAL,
  MANAGEMENT_POINT,
} from "./utils/PointWiseRouteConsts";

/************ Crm Point Start *************/

import { CrmDashboard } from "./components/crmPoint/crmDashboard/CrmDashboard";
import { EdumanApproval } from "./components/crmPoint/EdumanApproval";
import { PrePaidDisbursment } from "./components/crmPoint/PrePaidDisbursment";
import { UrlInstituteMap } from "./components/crmPoint/UrlInstituteMap";

import { DeviceApproval } from "./components/crmPoint/DeviceApproval";
import { AddInventory } from "./components/crmPoint/AddInventory";
import { BillAdjustment } from "./components/crmPoint/EdumanBillAdjustment";
import { EdumanBillProcess } from "./components/crmPoint/EdumanBillProcess";
import { BalanceDepositApprove } from "./components/crmPoint/reports/BalanceDepositApprove";
import { BalanceWithdrawApprove } from "./components/crmPoint/reports/BalanceWithdrawApprove";
import { DomainAndHostingApproval } from "./components/crmPoint/DomainAndHostingApproval";
import { EdumanIDApprove } from "./components/crmPoint/reports/EdumanIDApprove";
import { DomainHostingApproveReport } from "./components/crmPoint/reports/DomainHostingApproveReport";
import { EdumanGlobalInfoCore } from "./components/supportPoint/EdumanGlobalInfoCore";
import { TempInstituteWiseBill } from "./components/crmPoint/tempComponent/TempInstituteWiseBill";
import { EdumanAtAGlance } from "./components/crmPoint/EdumanAtAGlance";
import { PartnerAtAGlance } from "./components/crmPoint/PartnerAtAGlance";
import { CRMAdvanceBill } from "./components/crmPoint/reports/CRMAdvanceBill";
import { EdumanDiscountApproval } from "./components/crmPoint/approval/EdumanDiscountApproval";
import { NetiUserDisable } from "./components/crmPoint/NetiUserDisable";
import { AnyoneMessageSend } from "./components/crmPoint/SendMessage/AnyoneMessageSend";
import { ObserverpointTagging } from "./components/crmPoint/ObserverpointTagging";
import { PartnerOccurrence } from "./components/crmPoint/occurrence/PartnerOccurrence";
import { Deposit } from "./components/crmPoint/balanceApproval/Deposit";
import { Withdraw } from "./components/crmPoint/balanceApproval/Withdraw";

/************ Crm Point End *************/

import NetiwordLogo from "./assets/images/login/netiworld-logo-white.png";
import { DemoMyPointDashboard } from "./components/myPoint/DemoMyPointDashboard";
import { DemoDwsDashboard } from "./components/dwsPoint/DemoDwsDashboard";
import { DemoCrmDashboard } from "./components/crmPoint/DemoCrmDashboard";
import { DashboardCurrentStatus } from "./components/crmPoint/crmDashboard/DashboardCurrentStatus";
import { CRMDashboardTokenAndCall } from "./components/crmPoint/crmDashboard/CRMDashboardTokenAndCall";
import { CRMDashboardDateWiseStatus } from "./components/crmPoint/crmDashboard/CRMDashboardDateWiseStatus";
import { DemoDashboard } from "./components/DemoDashboard";
import { DemoViewProfile } from "./components/myPoint/profile/DemoViewProfile";
import { EmYssConfigMain } from "./components/emAdminPoint/EmYssConfigMain";
import { EmBillDiscount } from "./components/partnerPoint/EmBillDiscount";
import { AdmisiaRequest } from "./components/crmPoint/AdmisiaRequest";

import { UniversalNotice } from "./components/common/UniversalNotice";
import { AdmisiaPaymentInfo } from "./components/admisiaPoint/reports/AdmisiaPaymentInfo";
import { EdumanManualBillProcess } from "./components/crmPoint/EdumanManualBillProcess";
import { EmActionTypeWiseModuleLog } from "./components/managementPoint/emActionLog/EmActionTypeWiseModuleLog";
import { EmModuleWiseInstituteActionLog } from "./components/managementPoint/emActionLog/EmModuleWiseInstituteActionLog";
import { EmIndividualModuleInstituteActionLog } from "./components/managementPoint/emActionLog/EmIndividualModuleInstituteActionLog";
import { EmInstituteWiseActionLogDetails } from "./components/managementPoint/emActionLog/EmInstituteWiseActionLogDetails";

import { PartnerRevenueLog } from "./components/myPoint/reports/balanceLog/PartnerRevenueLog";

// import { PartnerIncomeStatementDetails } from './components/managementPoint/partnerReport/PartnerIncomeStatementDetails';

import { ChatBoxService } from "./service/chatBox/chatBoxService";
import { EmCurrentStatus } from "./components/partnerPoint/dashboard/EmCurrentStatus";
import { EdumanBillReportMain } from "./components/partnerPoint/report/edumanBill/EdumanBillReportMain";
import { EmInstituteWiseInvoice } from "./components/partnerPoint/report/EmInstituteWiseInvoice";
import { PartnerDueStatementTree } from "./components/managementPoint/edumanBillReport/dueStatement/PartnerDueStatementTree";
import { ProductPurchaseInfoByInvoiceID } from "./components/managementPoint/revenueReport/ProductPurchaseInfoByInvoiceID";
import { BankBranchInfo } from "./components/adminPoint/coreConfig/BankBranchInfo";
import { DFPSSettlementPayment } from "./components/crmPoint/DFPSSettlementPayment";
import { UserBankAccountMain } from "./components/myPoint/bankAccount/UserBankAccountMain";
import { PartnerScoreDashboard } from "./components/partnerPoint/dashboard/PartnerScoreDashboard";
import { PartnerDashBoard } from "./components/partnerPoint/dashboard/PartnerDashBoard";
import { MessageSendToPartner } from "./components/crmPoint/SendMessage/MessageSendToPartner";
import { MessageSendToPointTypeWise } from "./components/crmPoint/SendMessage/MessageSendToPointTypeWise";
import { MessageSendForDueBill } from "./components/crmPoint/SendMessage/MessageSendForDueBill";
import { MessageSendToPointExpire } from "./components/crmPoint/SendMessage/MessageSendToPointExpire";
import { PartnerPointRevenueDistribution } from "./components/partnerPoint/PartnerPointRevenueDistribution";
import { AppPointMenu } from "./utils/AppPointMenu";
// import { ZoomMeetingMain } from './components/emAdminPoint/ZoomMeetingMain';
import { DFPSDashboard } from "./components/managementPoint/othersReport/DFPSDashboard";
import { MonthlyPaidUnpaidInstituteInfoTreeView } from "./components/managementPoint/othersReport/MonthlyPaidUnpaidInstituteInfoTreeView";
import { StudentPortalInfoList } from "./components/emUserPoint/studentPortal/StudentPortalInfoList";
import { TeacherPortalInfoList } from "./components/emUserPoint/teacherPortal/TeacherPortalInfoList";
import { AddPortalMain } from "./components/emUserPoint/AddPortalMain";
import { StudentAddPortal } from "./components/emUserPoint/StudentAddPortal";
import { ParentAddPortal } from "./components/emUserPoint/ParentAddPortal";
import { TeacherAddPortal } from "./components/emUserPoint/TeacherAddPortal";
import { GuardianPortalInfoList } from "./components/emUserPoint/studentPortal/GuardianPortalInfoList";

import { AdminDwsHome } from "./components/dwsPoint/settings/AdminDwsHome";
import { AdminSeatInfo } from "./components/dwsPoint/contentManage/AdminSeatInfo";
import { AdminGalleryImage } from "./components/dwsPoint/contentManage/AdminGalleryImage";
import { AdminImportantLink } from "./components/dwsPoint/contentManage/AdminImportantLink";
import { AdminAboutUs } from "./components/dwsPoint/contentManage/AdminAboutUs";
import { AdminFeesInfo } from "./components/dwsPoint/contentManage/AdminFeesInfo";
import { AdminDressCodeInfo } from "./components/dwsPoint/contentManage/AdminDressCodeInfo";
import { AdminDownloadCorner } from "./components/dwsPoint/contentManage/AdminDownloadCorner";
import { AdminSpeechInfo } from "./components/dwsPoint/contentManage/AdminSpeechInfo";
import { AdminAdministrationInfo } from "./components/dwsPoint/contentManage/AdminAdministrationInfo";

import { AdminSyllabusInfo } from "./components/dwsPoint/contentManage/AdminSyllabusInfo";
import { AdminEventInfo } from "./components/dwsPoint/contentManage/AdminEventInfo";

import { AdminNoticeInfo } from "./components/dwsPoint/contentManage/AdminNoticeInfo";
import { AdminClassInfo } from "./components/dwsPoint/contentManage/AdminClassInfo";
import { AdminGroupInfo } from "./components/dwsPoint/contentManage/AdminGroupInfo";
import { AdminBookInfo } from "./components/dwsPoint/contentManage/AdminBookInfo";
import { NewInstituteMapping } from "./components/dwsPoint/settings/NewInstituteMapping";
import { NewInstituteUrlMap } from "./components/supportPoint/NewInstituteUrlMap";

import { NewInstituteMappingTest } from "./components/dwsPoint/settings/NewInstituteMappingTest";
import { AdminAlumnus } from "./components/dwsPoint/contentManage/AdminAlumnus";

// ADMISIA PAGE IMPORT
import { AdmisiaCoreConfig } from "./components/dwsPoint/admisia/AdmisiaCoreConfig";
import { AdmisiaApplicationConfig } from "./components/dwsPoint/admisia/application/AdmisiaApplicationConfig";
import { AdmisiaApplicationConfigList } from "./components/dwsPoint/admisia/application/AdmisiaApplicationConfigList";
import { AdmisiaApplicationConfigMain } from "./components/dwsPoint/admisia/application/AdmisiaApplicationConfigMain";
import { ServiceChargeConfig } from "./components/adminPoint/admisiaCoreConfig/ServiceChargeConfig";
import { AdmissionMain } from "./components/dwsPoint/admisia/admission/AdmissionMain";
import { AdmisiaAssessmentMain } from "./components/dwsPoint/admisia/assessment/AdmisiaAssessmentMain";
import { ApplicantAnalytics } from "./components/dwsPoint/admisia/reports/ApplicantAnalytics";
import { ApplicantStatus } from "./components/dwsPoint/admisia/reports/ApplicantStatus";
import { ApplicantInfo } from "./components/dwsPoint/admisia/reports/ApplicantInfo";
import { ApplicantAtaGlance } from "./components/dwsPoint/admisia/reports/ApplicantAtaGlance";
import { PaymentCollectionAnalytics } from "./components/dwsPoint/admisia/reports/PaymentCollectionAnalytics";
import { PaymentCollectionInfo } from "./components/dwsPoint/admisia/reports/PaymentCollectionInfo";
import { OACollectionAnalytics } from "./components/dwsPoint/admisia/reports/OACollectionAnalytics";
import InstituteContactInfo from "./components/supportPoint/InstituteContactInfo";











//Student Portal
import { StudentPortalProfile } from "./components/emUserPoint/studentPortal/StudentPortalProfile";
import { StudentPortalClassTest } from "./components/emUserPoint/studentPortal/StudentPortalClassTest";
import { StudentPortalAttendance } from "./components/emUserPoint/studentPortal/StudentPortalAttendance";
import { StudentPortalSubject } from "./components/emUserPoint/studentPortal/StudentPortalSubject";
import {StudentPortalExam} from "./components/emUserPoint/studentPortal/StudentPortalExam";
import {StudentPortalClassRoutine} from "./components/emUserPoint/studentPortal/StudentPortalClassRoutine";
import {StudentPortalExamRoutine} from "./components/emUserPoint/studentPortal/StudentPortalExamRoutine";
import {StudentPortalAccounts} from "./components/emUserPoint/studentPortal/StudentPortalAccounts";
import {StudentPortalInventory} from "./components/emUserPoint/studentPortal/StudentPortalInventory";

//Merchant

import {MarchantPoint} from "./components/merchantPoint/MarchantPoint";





class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutMode: "static",
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      darkMenu: true,
      menuActive: false,
      profileMode: "inline",
      grouped: true,
      layoutMenuButtonIsActive: true,
    };


    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
    this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.getUnreadMessageList = this.getUnreadMessageList.bind(this);
    this.Auth = new AuthService();
    this.chatBoxService = new ChatBoxService();
    this.createMenu();
  }

  onMenuClick(event) {
    this.menuClick = true;
  }

  onMenuButtonClick(event) {
    let layoutMenuButton = document.getElementsByClassName(
      "layout-menu-button"
    )[0];

    // let routeBarBreadcrumb = document.getElementsByClassName('nw-breadcrumb')[0];
    // routeBarBreadcrumb.style.paddingLeft = "35px";

    // console.log('routeBarBreadcrumb', event);

    console.log("ACTIVE", this.state.layoutMenuButtonIsActive);

    this.menuClick = true;
    this.setState({
      topbarMenuActive: false,
    });

    if (this.state.layoutMode === "overlay") {
      if (this.isDesktop()) {
        this.state.layoutMenuButtonIsActive
          ? (layoutMenuButton.style.left = "-10px")
          : (layoutMenuButton.style.left = "-10px");

        this.setState({ overlayMenuActive: !this.state.overlayMenuActive });
      } else {
        this.setState({
          staticMenuMobileActive: !this.state.staticMenuMobileActive,
        });
      }
    } else {
      if (this.isDesktop()) {
        this.setState({
          layoutMenuButtonIsActive: !this.state.layoutMenuButtonIsActive,
        });

        this.state.layoutMenuButtonIsActive
          ? (layoutMenuButton.style.left = "-10px")
          : (layoutMenuButton.style.left = "-60px");
        this.setState({
          staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive,
        });
      } else {
        // layoutMenuButton.style.left = "-60px";
        this.setState({
          staticMenuMobileActive: !this.state.staticMenuMobileActive,
        });
      }
    }

    event.preventDefault();
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onTopbarItemClick(event) {
    this.topbarItemClick = true;

    if (this.state.activeTopbarItem === event.item)
      this.setState({ activeTopbarItem: null });
    else this.setState({ activeTopbarItem: event.item });

    event.originalEvent.preventDefault();
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.hideOverlayMenu();
    }

    if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
      this.setState({
        menuActive: false,
      });
    }

    if (event.item.items && !this.isHorizontal() && this.layoutMenuScroller) {
      setTimeout(() => {
        this.layoutMenuScroller.moveBar();
      }, 500);
    }
  }

  onRootMenuItemClick(event) {
    this.setState({
      menuActive: !this.state.menuActive,
    });

    event.originalEvent.preventDefault();
  }

  onDocumentClick(event) {
    if (!this.topbarItemClick) {
      this.setState({
        activeTopbarItem: null,
        topbarMenuActive: false,
      });
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.setState({
          menuActive: false,
        });
      }

      this.hideOverlayMenu();
    }

    this.topbarItemClick = false;
    this.menuClick = false;
  }

  hideOverlayMenu() {
    this.setState({
      overlayMenuActive: false,
      staticMenuMobileActive: false,
    });
  }

  isTablet() {
    let width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isOverlay() {
    return this.state.layoutMode === "overlay";
  }

  isHorizontal() {
    return this.state.layoutMode === "horizontal";
  }

  isSlim() {
    return this.state.layoutMode === "slim";
  }

  changeTheme(theme, scheme) {
    this.changeStyleSheetUrl("layout-css", theme, "layout", scheme);
    this.changeStyleSheetUrl("theme-css", theme, "theme", scheme);
  }

  changeStyleSheetUrl(id, value, prefix, scheme) {
    let element = document.getElementById(id);
    let urlTokens = element.getAttribute("href").split("/");

    if (id.localeCompare("layout-css") === 0) {
      urlTokens[urlTokens.length - 1] = prefix + "-" + value + ".css";
    } else {
      urlTokens[urlTokens.length - 2] = value;
      urlTokens[urlTokens.length - 1] = "theme-" + scheme + ".css";
    }
    let newURL = urlTokens.join("/");
    element.setAttribute("href", newURL);

    if (scheme === "dark") {
      this.setState({ darkMenu: true });
    } else if (scheme === "light") {
      this.setState({ darkMenu: false });
    }

    let topbarLogo = document.getElementById("layout-topbar-logo");
    let menuLogo = document.getElementById("layout-menu-logo");

    if (
      value.localeCompare("yellow") === 0 ||
      value.localeCompare("lime") === 0
    ) {
      topbarLogo.src = "assets/layout/images/logo-black.png";
      menuLogo.src = "assets/layout/images/logo-black.png";
    } else {
      topbarLogo.src = "assets/layout/images/logo-white.png";
      menuLogo.src = "assets/layout/images/logo-white.png";
    }
  }

  createMenu() {
    this.menuGrouped = [];
    // this.menuGrouped.push(MyPointMenu);
    // this.menuGrouped.push(EmUserPointMenu);
    // this.menuGrouped.push(ObserverPointMenu);
    // this.menuGrouped.push(BijoyPointMenu);
    // this.menuGrouped.push(CMSPointMenu);
    // this.menuGrouped.push(AdmisiaPointMenu);
    // this.menuGrouped.push(EMAdminPointMenu);
    // this.menuGrouped.push(PartnerPointMenu);
    // this.menuGrouped.push(SupportPointMenu);
    // this.menuGrouped.push(CRMPointMenu);
    // this.menuGrouped.push(ManagementPointMenu);
    // this.menuGrouped.push(AdminPointMenu);

    // if (!this.Auth.getToken()) {
    // 	this.props.history.replace('/login');
    // };
    const roles = this.Auth.getLoggedRoles();
    console.log("roles", roles)
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] === "ROLE_USER") {
        this.menuGrouped[0] = MyPointMenu;
      }
      if (roles[i] === "ROLE_EMUSER") {
        this.menuGrouped[1] = EmUserPointMenu;
      }
      if (roles[i] === "ROLE_SA") {
        this.menuGrouped[2] = ObserverPointMenu;
      }
      if (roles[i] === "ROLE_BIJOY") {
        this.menuGrouped[3] = BijoyPointMenu;
      }
      if (roles[i] === "ROLE_DWS") {
        this.menuGrouped[4] = CMSPointMenu;
      }
      // if (roles[i] === 'ROLE_ADMISIA') {
      // 	this.menuGrouped[5] = AdmisiaPointMenu;
      // }
      if (roles[i] === "ROLE_EMADMIN") {
        this.menuGrouped[6] = EMAdminPointMenu;
      }
      if (roles[i] === "ROLE_PARTNER") {
        this.menuGrouped[7] = PartnerPointMenu;
      }
      if (roles[i] === "ROLE_SUPPORT") {
        this.menuGrouped[8] = SupportPointMenu;
      }
      if (roles[i] === "ROLE_CRM") {
        this.menuGrouped[9] = CRMPointMenu;
      }
      if (roles[i] === "ROLE_MANAGEMENT") {
        this.menuGrouped[10] = ManagementPointMenu;
      }
      if (roles[i] === "ROLE_ADMIN") {
        this.menuGrouped[11] = AdminPointMenu;
      }
      if (roles[i] === "ROLE_AP") {
        this.menuGrouped[12] = AppPointMenu;
      }
      if (roles[i] === "ROLE_STUDENT") {
        this.menuGrouped[14] = EmStudentPointMenu;
      }
      if (roles[i] === "ROLE_MERCHANT") {
        this.menuGrouped[15] = EmMerchantPointMenu ;
      }
      if (roles[i] === "ROLE_PARENT") {
        this.menuGrouped[16] = EmParentPointMenu;
      }
      if (roles[i] === "ROLE_TEACHER") {
        this.menuGrouped[17] = EmTeacherPointMenu;
      }
      
    }

    this.menuUngrouped = [
      {
        label: "Main Menu",
        icon: "pi pi-fw pi-home",
        items: this.menuGrouped,
      },
    ];
  }

  getUnreadMessageList() {
    // this.setState({ topProgressBar:true, dataTableIsLoading: true, errorMsgVisible: false});
    this.chatBoxService
      .fetchUnreadMessageInfo()
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({
              unreadMessageList: body,
            });
          });
        } else {
          this.chatBoxService.Auth.handleErrorStatus(res).then(
            (responseBody) => {
              this.setState({
                unreadMessageList: responseBody,
              });
              // this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
            }
          );
        }
      })
      .catch((error) => {
        return error;
      });
  }

  render() {
    var isStudent= false;
    var isMerchant = false;
    var isParent = false;
    var isTeacher = false;
    var buttonTest = ''
    var RoutePath = ''
    const { props } = this.props;
    const Auth = new AuthService();
    const loggedRoles = this.Auth.getLoggedRoles();
    const roles = this.Auth.getLoggedRoles();
    for (let i = 0; i < roles.length; i++) {
    if(loggedRoles[i]=='ROLE_STUDENT'){
      isStudent=true;
    }
    if(loggedRoles[i]=='ROLE_MERCHANT'){
      isMerchant=true;
    }
    if(loggedRoles[i]=='ROLE_TEACHER'){
      isTeacher=true;
    }
    if(loggedRoles[i]=='ROLE_PARENT'){
      isParent=true;
    }
  }

    // if (!Auth.getToken()) {
    // 	props.history.replace('/login');
    // };

    const layoutClassName = classNames("layout-wrapper", {
      "layout-horizontal": this.state.layoutMode === "horizontal",
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-slim": this.state.layoutMode === "slim",
      "layout-static-inactive": this.state.staticMenuDesktopInactive,
      "layout-mobile-active": this.state.staticMenuMobileActive,
      "layout-overlay-active": this.state.overlayMenuActive,
      "layout-menu-dark": this.state.darkMenu,
      "layout-menu-light": !this.state.darkMenu,
    });
    // const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);
    
    if(isStudent){
      buttonTest = 
      <StudentTopbar
      topbarMenuActive={this.state.topbarMenuActive}
      activeTopbarItem={this.state.activeTopbarItem}
      onMenuButtonClick={this.onMenuButtonClick}
      onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
      onTopbarItemClick={this.onTopbarItemClick}
      profileMode={this.state.profileMode}
      horizontal={this.isHorizontal()}
      history={props.history}
      />
    }else if(isMerchant) {
      buttonTest = 
      <StudentTopbar
      topbarMenuActive={this.state.topbarMenuActive}
      activeTopbarItem={this.state.activeTopbarItem}
      onMenuButtonClick={this.onMenuButtonClick}
      onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
      onTopbarItemClick={this.onTopbarItemClick}
      profileMode={this.state.profileMode}
      horizontal={this.isHorizontal()}
      history={props.history}
      />
    } else if (isParent) {
      buttonTest = 
      <StudentTopbar
      topbarMenuActive={this.state.topbarMenuActive}
      activeTopbarItem={this.state.activeTopbarItem}
      onMenuButtonClick={this.onMenuButtonClick}
      onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
      onTopbarItemClick={this.onTopbarItemClick}
      profileMode={this.state.profileMode}
      horizontal={this.isHorizontal()}
      history={props.history}
      />
    } else if (isTeacher) {
      buttonTest = 
      <StudentTopbar
      topbarMenuActive={this.state.topbarMenuActive}
      activeTopbarItem={this.state.activeTopbarItem}
      onMenuButtonClick={this.onMenuButtonClick}
      onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
      onTopbarItemClick={this.onTopbarItemClick}
      profileMode={this.state.profileMode}
      horizontal={this.isHorizontal()}
      history={props.history}
      />
    } else {
      buttonTest = <AppTopbar
      topbarMenuActive={this.state.topbarMenuActive}
      activeTopbarItem={this.state.activeTopbarItem}
      onMenuButtonClick={this.onMenuButtonClick}
      onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
      onTopbarItemClick={this.onTopbarItemClick}
      profileMode={this.state.profileMode}
      horizontal={this.isHorizontal()}
      history={props.history}
      // getUnreadMessageList = { this.getUnreadMessageList } unreadMessageList={ this.state.unreadMessageList }
    /> 
    }
    // if(isStudent) {
    //  RoutePath =  <Route path="/" exact component={Student} />
    // } else if(isMerchant) {
    //   RoutePath =  <Route path="/" exact component={Merchant} />
    // } else if(isParent) {
    //   RoutePath =  <Route path="/" exact component={Parent} />
    // } else if(isTeacher) {
    //   RoutePath =  <Route path="/" exact component={Teacher} />
    // }else{
      RoutePath =  <Route path="/" exact component={Dashboard}/>
    //}
    return (
      <div className={layoutClassName} onClick={this.onDocumentClick}>
        <div className="layout-menu-container" onClick={this.onMenuClick}>
          <div className="layout-menu-logo">
            <button className="p-link">
              <img
                id="layout-menu-logo"
                src={NetiwordLogo}
                alt="Netizen IT Limited"
              />
            </button>
          </div>
          <div className="layout-menu-wrapper">
            <ScrollPanel
              ref={(el) => (this.layoutMenuScroller = el)}
              style={{ height: "100%" }}
            >
              <div className="menu-scroll-content">
                {this.state.profileMode === "inline" &&
                  this.state.layoutMode !== "horizontal" && (
                    <AppInlineProfile />
                  )}
                <AppMenu
                  model={
                    this.state.grouped ? this.menuGrouped : this.menuUngrouped
                  }
                  onMenuItemClick={this.onMenuItemClick}
                  onRootMenuItemClick={this.onRootMenuItemClick}
                  layoutMode={this.state.layoutMode}
                  active={this.state.menuActive}
                />
              </div>
            </ScrollPanel>
          </div>
        </div>
        {buttonTest}
        {/* {
          isStudent ? 
          <StudentTopbar
          topbarMenuActive={this.state.topbarMenuActive}
          activeTopbarItem={this.state.activeTopbarItem}
          onMenuButtonClick={this.onMenuButtonClick}
          onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
          onTopbarItemClick={this.onTopbarItemClick}
          profileMode={this.state.profileMode}
          horizontal={this.isHorizontal()}
          history={props.history}
          /> :
           <AppTopbar
          topbarMenuActive={this.state.topbarMenuActive}
          activeTopbarItem={this.state.activeTopbarItem}
          onMenuButtonClick={this.onMenuButtonClick}
          onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
          onTopbarItemClick={this.onTopbarItemClick}
          profileMode={this.state.profileMode}
          horizontal={this.isHorizontal()}
          history={props.history}
          // getUnreadMessageList = { this.getUnreadMessageList } unreadMessageList={ this.state.unreadMessageList }
        /> 
        }  */}
       
        {/* <AppTopbar
          topbarMenuActive={this.state.topbarMenuActive}
          activeTopbarItem={this.state.activeTopbarItem}
          onMenuButtonClick={this.onMenuButtonClick}
          onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
          onTopbarItemClick={this.onTopbarItemClick}
          profileMode={this.state.profileMode}
          horizontal={this.isHorizontal()}
          history={props.history}
          // getUnreadMessageList = { this.getUnreadMessageList } unreadMessageList={ this.state.unreadMessageList }
        /> */}

        <div className="layout-main">
          {/* <AppBreadCrumbWithRouter topLoadingBar={this.props.showTopLoadingBar} /> */}

          {/* <div className="route-bar">
            <UniversalNotice />
          </div> */}

          <div className="layout-content">
            {/* <UniversalNotice /> */}
            {/* <Route path="/" exact component={Student} /> */}

            {/* {
              isStudent ? <Route path="/" exact component={Student} />
              : <Route path="/" exact component={Dashboard} />
            } */}
            {RoutePath}
            <Route path="/homeDashboard" exact component={HomeDashboard} />
            <Route path="/main_dashboard" exact component={MainDashboard} />
            {/* <Route path="/" exact component={DemoDashboard} /> */}
            <Route path="/forms" component={FormsDemo} />
            <Route path="/forms1" component={FormsDemo} />
            <Route path="/sample" component={SampleDemo} />
            <Route path="/data" component={DataDemo} />
            <Route path="/panels" component={PanelsDemo} />
            <Route path="/panels1" component={PanelsDemo} />
            <Route path="/overlays" component={OverlaysDemo} />
            <Route path="/menus" component={MenusDemo} />
            <Route path="/messages" component={MessagesDemo} />
            <Route path="/misc" component={MiscDemo} />
            <Route path="/empty" component={EmptyPage} />
            <Route path="/documentation" component={Documentation} />
            {/*********** My Point Start *********/}
            <Route path={MY_POINT.MY_PROFILE} component={MyProfile} />
            <Route path={MY_POINT.DASHBORAD} component={MyPointDashboard} />
            {/* <Route path={MY_POINT.DEMO_DASHBORAD} component={DemoMyPointDashboard} /> */}
            <Route path={MY_POINT.VIEW_PROFILE} component={ViewProfile} />
            {/* <Route path={MY_POINT.DEMO_VIEW_PROFILE} component={DemoViewProfile} /> */}
            <Route path={MY_POINT.UPDATE_PROFILE} component={UpdateProfile} />
            <Route path={MY_POINT.CHANGE_PASSWORD} component={ChangePassword} />
            <Route
              path={MY_POINT.CHANGE_USER_NAME}
              component={ChangeUserNameDialog}
            />
            <Route path={MY_POINT.NEW_TOKEN} component={Token} />
            <Route
              path={MY_POINT.NEW_TOKEN_LIST}
              component={MyPointTokenTable}
            />
            <Route path={MY_POINT.WALLET} component={WalletMain} />
            <Route path={MY_POINT.WALLET2} component={Testing} />
            <Route path={MY_POINT.MESSAGE} component={MessageRecharge} />
            {/* <Route
              path={MY_POINT.GENERAL_PRODUCT}
              component={PurchaseGeneralProduct}
            /> */}
            <Route
              path={MY_POINT.GENERAL_PRODUCT}
              component={PurchaseGeneralProductClone}
            />
            <Route
              path={MY_POINT.OFFER_PRODUCT}
              component={PurchaseOfferProduct}
            />
            <Route path={MY_POINT.CHAT_BOX} component={ChatBox} />
            <Route path={MY_POINT.CHAT_BOX_LIST} component={ChatBoxList} />
            <Route path={MY_POINT.CUSTOMER_INFO} component={CustomerInfo} />
            <Route
              path={MY_POINT.CUSTOMER_INFO_LOG}
              component={CustomerInfoLog}
            />
            <Route
              path={MY_POINT.USER_BANK_ACCOUNT}
              component={UserBankAccountMain}
            />
            {/****** Report *****/}
            <Route path={MY_POINT.WALLET_LOGS} component={WalletLog} />
            <Route
              path={MY_POINT.BALANCE_STATEMENT}
              component={BalanceStatement}
            />
            <Route
              path={MY_POINT.MESSAGE_LOGS}
              component={MessageLogRecharge}
            />
            <Route
              path={MY_POINT.PURCHASE_LOG_GENERAL}
              component={PurchaseGeneralProductViewMoreDetails}
            />
            <Route
              path={MY_POINT.PURCHASE_LOG_OFFER}
              component={PurchaseLogOfferProduct}
            />
            <Route
              path={MY_POINT.PURCHASE_CODE_LOG}
              component={PurchaseCodeLogMain}
            />
            <Route
              path={MY_POINT.UNUSED_PURCHASE_CODE_LOG}
              component={UnusedPurchaseCodeList}
            />
            <Route
              path={MY_POINT.USED_PURCHASE_CODE_LOG}
              component={UsedPurchaseCodeList}
            />
            <Route path={MY_POINT.SELL_PRODUCT} component={SellProduct} />
            <Route
              path={MY_POINT.SALES_PRODUCT_LOG}
              component={SellProductLogViewMore}
            />
            <Route
              path={MY_POINT.PARTNER_REVENUE_LOG}
              component={PartnerRevenueLog}
            />
            {/* <Route path={MY_POINT.MEETING_CALL} component={ZoomMeetingMain} /> */}
            {/*********** End My Point *********/}
            {/*********** EM_User Point Start *********/}
            <Route
              path={EM_USER_POINT.TEACHER_PORTAL}
              component={TeacherPortalMain}
            />
            <Route path={EM_USER_POINT.GO_TO_PORTAL} component={GoToPortal} />
            <Route path={EM_USER_POINT.ADD_POINT} component={AddEmUserPoint} />
            <Route
              path={EM_USER_POINT.STUDENT_PORTAL}
              component={StudentPortalMain}
            />



          {/* work */}
          <Route path="/class-test" component={StudentPortalClassTest} /> 
          <Route path="/profile" component={StudentPortalProfile} />  
           <Route path="/attendance" component={StudentPortalAttendance} />  
           <Route path="/subject" component={StudentPortalSubject} />  
           <Route path="/exam" component={StudentPortalExam} />  
           <Route path="/class-routine" component={StudentPortalClassRoutine} />  
           <Route path="/exam-routine" component={StudentPortalExamRoutine} /> 
           <Route path="/accounts" component={StudentPortalAccounts} />
           <Route path="/inventory" component={StudentPortalInventory} />


           <Route path="/marchant" component={MarchantPoint} />
            
           
 

            <Route
              path={EM_USER_POINT.ADD_STUDENT_PORTAL}
              component={AddPortalForStudent}
            />
            <Route
              path={EM_USER_POINT.ADD_GUARDIAN_PORTAL}
              component={AddPortalForGuardian}
            />
            <Route
              path={EM_USER_POINT.ADD_EMPLOYEE_PORTAL}
              component={AddPortalForEmployee}
            />
            <Route
              path={EM_USER_POINT.STUDENT_LIST_INFO}
              component={StudentPortalInfoList}
            />
            <Route
              path={EM_USER_POINT.TEACHER_LIST_INFO}
              component={TeacherPortalInfoList}
            />
            <Route
              path={EM_USER_POINT.GUARDIAN_LIST_INFO}
              component={GuardianPortalInfoList}
            />
            <Route path={EM_USER_POINT.ADD_PORTAL} component={AddPortalMain} />
            <Route path={EM_USER_POINT.STUDENT_ADD_PORTAL} component={StudentAddPortal} />
            <Route path={EM_USER_POINT.PARENT_ADD_PORTAL} component={ParentAddPortal} />
            <Route path={EM_USER_POINT.TEACHER_ADD_PORTAL} component={TeacherAddPortal} />
            {/*********** EM_User Point End *********/}
            {/************DWS POINT START ****************/}
            <Route path={DWS_POINT.HOME} component={DwsHome} />
            <Route path={DWS_POINT.MENU_CONFIG} component={DwsMenuConfig} />
            <Route
              path={DWS_POINT.CMS_THEME_CONFIG}
              component={CmsThemeSetup}
            />
            <Route
              path={DWS_POINT.MAPPED_INSTITUTE}
              component={MappedInstitute}
            />
            <Route path={DWS_POINT.CMS_MENU_CONFIG} component={CmsMenuConfig} />
            <Route path={DWS_POINT.ADMIN_HOME} component={AdminDwsHome} />
            {/* <Route path="/home" component={DwsHome} />
						<Route path="/menu-config" component={DwsMenuConfig} /> */}
            <Route path={DWS_POINT.ADMINISTRATION} component={Administration} />
            <Route path={DWS_POINT.WELCOME_SPEECH} component={Speech} />
            <Route path={DWS_POINT.ABOUT} component={AboutUs} />
            <Route path={DWS_POINT.DRESS_CODE} component={DressCode} />
            <Route path={DWS_POINT.FEES_INFO} component={FeesInfo} />
            <Route path={DWS_POINT.SYLLABUS} component={SyllabusInfo} />
            <Route path={DWS_POINT.SEAT_INFO} component={SeatInfo} />
            <Route path={DWS_POINT.EVENT_LOG} component={EventLog} />
            <Route path={DWS_POINT.PHOTO_GALLERY} component={PhotoGallery} />
            <Route path={DWS_POINT.BOOK_INFO} component={BookList} />
            <Route path={DWS_POINT.IMPORTANT_LINK} component={ImportantLink} />
            <Route
              path={DWS_POINT.DOWNLOAD_CORNER}
              component={DownloadCorner}
            />
            <Route path={DWS_POINT.NOTICE} component={Notice} />
            {/* <Route path={DWS_POINT.DWS_DASHBOARD} component={DwsDashboard} /> */}
            <Route
              path={DWS_POINT.DEMO_CMS_DASHBOARD}
              component={DemoDwsDashboard}
            />
            <Route
              path={DWS_POINT.VISITOR_FEEDBACK}
              component={CmsVisitorFeedback}
            />
            {/**** / NEW ROUTING for cms point admin (neticms website data)  ****/}
            <Route
              path={DWS_POINT.ADMIN_NEW_INSTITUTE_MAPPING}
              component={NewInstituteMapping}
            />
            {/* <Route path={DWS_POINT.ADMIN_NEW_INSTITUTE_MAPPING_TEST} component={NewInstituteMappingTest} /> */}
            <Route
              path={DWS_POINT.ADMIN_CLASS_INFO}
              component={AdminClassInfo}
            />
            <Route
              path={DWS_POINT.ADMIN_GROUP_INFO}
              component={AdminGroupInfo}
            />
            <Route path={DWS_POINT.ADMIN_BOOK_INFO} component={AdminBookInfo} />
            {/* <Route path={"/cms_admin/seat_info"} component={AdminSeatInfo} /> */}
            <Route
              path={DWS_POINT.ADMIN_GALLERY_IMAGE}
              component={AdminGalleryImage}
            />
            <Route
              path={DWS_POINT.ADMIN_IMPORTANT_LINK}
              component={AdminImportantLink}
            />
            <Route path={DWS_POINT.ADMIN_ABOUT_US} component={AdminAboutUs} />
            <Route path={DWS_POINT.ADMIN_FEES_INFO} component={AdminFeesInfo} />
            <Route
              path={DWS_POINT.ADMIN_DRESS_CODE_INFO}
              component={AdminDressCodeInfo}
            />
            <Route
              path={DWS_POINT.ADMIN_ADD_MEMBER_INFO}
              component={AdminAdministrationInfo}
            />
            <Route
              path={DWS_POINT.ADMIN_EVENT_INFO}
              component={AdminEventInfo}
            />
            <Route path={DWS_POINT.ADMIN_SEAT_INFO} component={AdminSeatInfo} />
            <Route
              path={DWS_POINT.ADMIN_DOWNLOAD_CORNER}
              component={AdminDownloadCorner}
            />
            <Route
              path={DWS_POINT.ADMIN_SPEECH_INFO}
              component={AdminSpeechInfo}
            />
            <Route
              path={DWS_POINT.ADMIN_SYLLABUS_INFO}
              component={AdminSyllabusInfo}
            />
            <Route
              path={DWS_POINT.ADMIN_NOTICE_INFO}
              component={AdminNoticeInfo}
            />
            <Route
              path={DWS_POINT.ADMIN_ALUMNUS_INFO}
              component={AdminAlumnus}
            />
            {/* ADMISIA INSIDE NETICMS */}
            <Route
              path={DWS_POINT.ADMISIA_CORE_CONFIG}
              component={AdmisiaCoreConfig}
            />
            <Route
              path={DWS_POINT.ADMISIA_APPLICATION_CONFIG}
              component={AdmisiaApplicationConfigMain}
            />
            {/* ADMISIA Approval INSIDE NETICMS */}
            <Route
              path={DWS_POINT.ADMISIA_ASSESSMENT}
              component={AdmisiaAssessmentMain}
            />
            <Route
              path={DWS_POINT.ADMISIA_ADMISSION_APPROVAL}
              component={AdmissionMain}
            />
            {/* ADMISIA Report INSIDE NETICMS */}
            <Route
              path={DWS_POINT.ADMISIA_APPLICANT_ANALYTICS}
              component={ApplicantAnalytics}
            />
            <Route
              path={DWS_POINT.ADMISIA_APPLICANT_STATUS}
              component={ApplicantStatus}
            />
            <Route
              path={DWS_POINT.ADMISIA_APPLICANT_INFO}
              component={ApplicantInfo}
            />
            <Route
              path={DWS_POINT.ADMISIA_APPLICANT_AT_A_GLANCE}
              component={ApplicantAtaGlance}
            />
            <Route
              path={DWS_POINT.ADMISIA_PAYMENT_COLLECTION_ANALYTICS}
              component={PaymentCollectionAnalytics}
            />
            <Route
              path={DWS_POINT.ADMISIA_PAYMENT_COLLECTION_INFO}
              component={PaymentCollectionInfo}
            />
            <Route
              path={DWS_POINT.OA_COLLECTION_ANALYTICS}
              component={OACollectionAnalytics}
            />
            {/************DWS POINT END ****************/}
            {/************ADMISIA POINT START ****************/}
            <Route
              path={ADMISIA_POINT.INSTITUTE_CONFIG}
              component={InstituteConfigHome}
            />
            <Route
              path={ADMISIA_POINT.CORE_CONFIG}
              component={CoreConfigHome}
            />
            <Route
              path={ADMISIA_POINT.APPLICATION_CONFIG}
              component={ApplicationConfigHome}
            />
            <Route
              path={ADMISIA_POINT.MAPPED_INSTITUTE}
              component={AdmisiaPointMappedInstitute}
            />
            <Route
              path={ADMISIA_POINT.ASSESSMENT}
              component={AdmisiaPointAssessment}
            />
            <Route
              path={ADMISIA_POINT.ADMISSION}
              component={AdmisiaPointAdmission}
            />
            <Route
              path={ADMISIA_POINT.TRANSFER}
              component={AdmisiaPointTransfer}
            />
            <Route
              path={ADMISIA_POINT.SEND_SMS_APPLICANT}
              component={SendSmsApplicant}
            />
            <Route
              path={ADMISIA_POINT.APPLICANT_UPDATE_INFO}
              component={ApplicantUpdateInfo}
            />
            <Route
              path={ADMISIA_POINT.APPLICANT_ANALYTICS}
              component={AdmisiaApplicantAnalytics}
            />
            <Route
              path={ADMISIA_POINT.COLLECTION_ANALYTICS}
              component={AdmisiaCollectionAnalytics}
            />
            <Route
              path={ADMISIA_POINT.COLLECTION_INFO}
              component={AdmisiaCollectionInfo}
            />
            <Route
              path={ADMISIA_POINT.APPLICANT_STATUS}
              component={AdmisiaApplicantStatus}
            />
            <Route
              path={ADMISIA_POINT.APPLICANT_INFO}
              component={AdmisiaApplicantInfo}
            />
            <Route
              path={ADMISIA_POINT.ADMISIA_AT_A_GLANCE}
              component={AdmisiaAtaGlance}
            />
            <Route
              path={ADMISIA_POINT.PAYMENT_INFO}
              component={AdmisiaPaymentInfo}
            />
            {/************ADMISIA POINT END ****************/}
            {/************BIJOY POINT START ****************/}
            <Route
              path={BIJOY_POINT.BIJOY_CONTENT}
              component={BijoyContentDownload}
            />
            <Route
              path={BIJOY_POINT.ESSENTIAL_SOFTWARE}
              component={SoftwareDownload}
            />
            <Route
              path={BIJOY_POINT.BIJOY_NEW_USER}
              component={DeviceNewUser}
            />
            <Route
              path={BIJOY_POINT.BIJOY_EXISTING_USER}
              component={ExistingUser}
            />
            {/************BIJOY POINT END ****************/}
            {/************EM Admin POINT START ****************/}
            <Route path={EM_ADMIN_POINT.PRE_PAID_BILL} component={EmPaidBill} />
            <Route
              path={EM_ADMIN_POINT.POST_PAID_BILL}
              component={EmPostPaidBill}
            />
            <Route
              path={EM_ADMIN_POINT.SMS_TRANSFER}
              component={EmSmsTransfer}
            />
            <Route
              path={EM_ADMIN_POINT.YSS_CONFIG}
              component={EmYssConfigMain}
            />
            <Route
              path={EM_ADMIN_POINT.YSS_CONFIGURED_LIST}
              component={EmYssConfiguredList}
            />
            <Route
              path={EM_ADMIN_POINT.AUDIT_POINT_TAGGING}
              component={EmAuditPointTagging}
            />
            <Route
              path={EM_ADMIN_POINT.AUDIT_POINT_TAGGING_LIST}
              component={EmAuditPointTaggingList}
            />
            <Route
              path={EM_ADMIN_POINT.EM_ONLINE_PAYMENT_SYSTEM}
              component={OnlinePaymentSystemEMAdminPoint}
            />
            <Route
              path={EM_ADMIN_POINT.EM_ONLINE_PAYMENT_COLLECTION_SUMMARY}
              component={OpsCollectionSummary}
            />
            <Route
              path={EM_ADMIN_POINT.EM_ONLINE_PAYMENT_COLLECTION_DETAILS}
              component={OpsCollectionDetails}
            />
            <Route
              path={EM_ADMIN_POINT.EM_ONLINE_PAYMENT_REFUND_REPORT}
              component={OpsCollectionRefundReport}
            />
            <Route
              path={EM_ADMIN_POINT.EM_ONLINE_PAYMENT_REFUND_SUMMARY}
              component={OnlineCollectionRefundSummary}
            />
            {/************EM Admin POINT End ****************/}
            {/*********** SA Point Start *********/}
            <Route
              path={SA_POINT.STUDENT_SUMMARY}
              component={GenderAndReligionWiseStudentInfo}
            />
            <Route
              path={SA_POINT.STAFF_SUMMARY}
              component={GenderAndReligionWiseStaffInfo}
            />
            <Route
              path={SA_POINT.STUDENT_ATTENDANCE_SUMMARY}
              component={StudentAttendanceSummary}
            />
            <Route
              path={SA_POINT.STAFF_ATTENDANCE_SUMMARY}
              component={StaffAttendanceSummary}
            />
            <Route
              path={SA_POINT.FEE_COLLECTION_SUMMARY}
              component={DateWiseStudentFeeCollectionSummary}
            />
            <Route
              path={SA_POINT.CASH_FLOW_SUMMARY}
              component={DateWiseCashFlowSummary}
            />
            <Route
              path={SA_POINT.MESSAGING_INFO}
              component={InstitueSmsSummary}
            />
            {/* <Route path="/sapoint/table" component={DivisionWiseTeacherAttendanceInformationTable} /> */}
            <Route path="/sapoint/map" component={DevisionWiseAttendenceMap} />
            <Route
              path={SA_POINT.TEACHER_ATTENDENCE_INFO}
              component={DivisionWiseAttendence}
            />
            <Route
              path={SA_POINT.OBSERVER_DISTRICT_WISE_ATTENDANCE}
              component={DistrictWiseAttendance}
            />
            <Route
              path={SA_POINT.OBSERVER_INSTITUTE_WISE_ATTENDANCE}
              component={InstituteWiseAttendance}
            />
            {/*********** SA Point End *********/}
            {/************Admin POINT START ****************/}
            <Route path={ADMIN_POINT.CATEGORY_INFO} component={CategoryInfo} />
            <Route path={ADMIN_POINT.CMS_MENU_ITEM} component={CmsMenuItem} />
            <Route path={ADMIN_POINT.CORE_BANK_INFO} component={CoreBankInfo} />
            <Route
              path={ADMIN_POINT.ADMIN_POINT_ASSIGN}
              component={AdminPointAssign}
            />
            <Route path={ADMIN_POINT.USER_NATURE} component={UserNature} />
            <Route path={ADMIN_POINT.PRODUCT_INFO} component={ProductInfo} />
            <Route path={ADMIN_POINT.PRODUCT_LIST} component={ProductList} />
            <Route
              path={ADMIN_POINT.PRODUCT_USER_ROLE}
              component={ProductUserRole}
            />
            <Route path={ADMIN_POINT.PRODUCT_OFFER} component={ProductOffer} />
            <Route
              path={ADMIN_POINT.PRODUCT_REVENUE}
              component={ProductRevenueMain}
            />
            <Route path={ADMIN_POINT.PARTNER_LIST} component={PartnerList} />
            <Route
              path={ADMIN_POINT.NEW_PARTNER_ASSIGN}
              component={NewPartnerAssign}
            />
            <Route
              path={ADMIN_POINT.PARTNER_INCOME_TARGET}
              component={PartnerIncomeTarget}
            />
            <Route path={ADMIN_POINT.CREATE_NOTICE} component={CreateNotice} />
            <Route
              path={ADMIN_POINT.CREATE_RELEASE_NOTICE}
              component={CreateReleaseNote}
            />
            <Route path={ADMIN_POINT.CREATE_BANNER} component={CreateBanner} />
            <Route
              path={ADMIN_POINT.CREATE_CALENDAR}
              component={CreateGlobalCalendar}
            />
            <Route
              path={ADMIN_POINT.DOWNLOAD_CORNER}
              component={AdminPointDownloadCorner}
            />
            <Route
              path={ADMIN_POINT.ADD_TUTORIAL}
              component={AdminPointAddTutorial}
            />
            <Route
              path={ADMIN_POINT.VIEW_TUTORIAL}
              component={AdminPointViewTutorial}
            />
            <Route
              path={ADMIN_POINT.BANK_BRANCH_LIST}
              component={BankBranchList}
            />
            <Route
              path={ADMIN_POINT.ADMIN_USER_NATURE}
              component={AdminUserNature}
            />
            <Route
              path={ADMIN_POINT.ADMIN_ASSIGN_POINT}
              component={AdminAssingPoint}
            />
            <Route path={ADMIN_POINT.DFP_BANK_INFO} component={DfpBankInfo} />
            <Route
              path={ADMIN_POINT.SERVICE_CHARGE_CONFIG}
              component={ServiceChargeConfig}
            />
            <Route
              path={ADMIN_POINT.OTHER_PRODUCT_INFO}
              component={OtherProductInfo}
            />
            {/************Admin POINT End ****************/}
            {/************CRM POINT Start ****************/}
            {/* <Route path={CRM_POINT.CRM_DASHBOARD} component={CrmDashboard} /> */}
            <Route
              path={CRM_POINT.DEMO_CRMDASHBOARD}
              component={DemoCrmDashboard}
            />
            <Route
              path={CRM_POINT.CRM_CURRENT_STATUS_DASHBOARD}
              component={DashboardCurrentStatus}
            />
            <Route
              path={CRM_POINT.CRM_DASHBOARD_DATE_WISE_STATUS}
              component={CRMDashboardDateWiseStatus}
            />
            <Route
              path={CRM_POINT.CRM_DASHBOARD_TOKEN_AND_CALL}
              component={CRMDashboardTokenAndCall}
            />
            <Route
              path={CRM_POINT.EDUMAN_APPROVAL}
              component={EdumanApproval}
            />
            <Route
              path={CRM_POINT.URL_INSTITUTE_MAP}
              component={UrlInstituteMap}
            />
            <Route
              path={CRM_POINT.DOMAIN_AND_HOSTING_REQUEST}
              component={DomainAndHostingApproval}
            />
            <Route
              path={CRM_POINT.TEMP_PERSON_WISE_BILL}
              component={TempInstituteWiseBill}
            />
            <Route
              path={CRM_POINT.PRE_PAID_DISBURSEMENT}
              component={PrePaidDisbursment}
            />
            <Route
              path={CRM_POINT.DEVICE_APPROVAL}
              component={DeviceApproval}
            />
            <Route
              path={CRM_POINT.EM_BILL_PROCESS}
              component={EdumanBillProcess}
            />
            <Route
              path={CRM_POINT.EM_MANUAL_BILL_PROCESS}
              component={EdumanManualBillProcess}
            />
            <Route path={CRM_POINT.ADD_INVENTORY} component={AddInventory} />
            <Route
              path={CRM_POINT.BILL_ADJUSTMENT}
              component={BillAdjustment}
            />
            <Route
              path={CRM_POINT.DEPOSIT_APPROVE}
              component={BalanceDepositApprove}
            />
            <Route
              path={CRM_POINT.WITHDRAW_APPROVE}
              component={BalanceWithdrawApprove}
            />
            <Route
              path={CRM_POINT.EDUMAN_ID_APPROVE}
              component={EdumanIDApprove}
            />
            <Route
              path={CRM_POINT.DOMAIN_AND_HOSTING_APPROVE}
              component={DomainHostingApproveReport}
            />
            <Route path={CRM_POINT.POINT_ASSIGN} component={CRMPointAssign} />
            <Route
              path={CRM_POINT.POINT_ASSIGNED_LIST}
              component={CRMPointAssignedList}
            />
            <Route
              path={CRM_POINT.ADMISIA_REQUEST}
              component={AdmisiaRequest}
            />
            <Route
              path={CRM_POINT.EDUMAN_AT_A_GLANCE}
              component={EdumanAtAGlance}
            />
            <Route
              path={CRM_POINT.PARTNER_AT_A_GLANCE}
              component={PartnerAtAGlance}
            />
            <Route
              path={CRM_POINT.CRM_ADVANCE_BILL}
              component={CRMAdvanceBill}
            />
            <Route
              path={CRM_POINT.EDUMAN_DISCOUNT_APPROVAL}
              component={EdumanDiscountApproval}
            />
            <Route path={CRM_POINT.USER_DISABLE} component={NetiUserDisable} />
            <Route
              path={CRM_POINT.ANYONE_MESSAGE_SEND}
              component={AnyoneMessageSend}
            />
            <Route
              path={CRM_POINT.MESSAGE_SEND_TO_PARTNER}
              component={MessageSendToPartner}
            />
            <Route
              path={CRM_POINT.MESSAGE_SEND_TO_POINT_TYPE_WISE}
              component={MessageSendToPointTypeWise}
            />
            <Route
              path={CRM_POINT.MESSAGE_SEND_TO_DUE_BILL}
              component={MessageSendForDueBill}
            />
            <Route
              path={CRM_POINT.MESSAGE_SEND_TO_POINT_EXPIRE}
              component={MessageSendToPointExpire}
            />
            <Route
              path={CRM_POINT.DFPS_SETTLEMENT_PAYMENT}
              component={DFPSSettlementPayment}
            />
            <Route
              path={CRM_POINT.OBSERVER_POINT_TAGGING}
              component={ObserverpointTagging}
            />
            <Route
              path={CRM_POINT.PARTNER_OCCURRENCE}
              component={PartnerOccurrence}
            />
            <Route path={CRM_POINT.BALANCE_WITHDRAW} component={Withdraw} />
            <Route path={CRM_POINT.BALANCE_DEPOSIT} component={Deposit} />
            {/************CRM POINT End ****************/}
            {/************Support Point Start *********/}
            <Route
              path={SUPPORT_POINT.CREATE_CALL_LOGS}
              component={CreateCallLog}
            />
            <Route
              path={SUPPORT_POINT.BIJOY_ID_REPLACE}
              component={BijoyDeviceIdReplace}
            />
            <Route
              path={SUPPORT_POINT.MAPPING_BANK_ACCOUNT}
              component={MappingAccount}
            />
            <Route
              path={SUPPORT_POINT.EM_GLOBAL_INFO_ADD_CORE}
              component={EdumanGlobalInfoCore}
            />
            <Route
              path={SUPPORT_POINT.DWS_MENU_CONFIG}
              component={SupportDwsMenuConfig}
            />
            <Route
              path={SUPPORT_POINT.DWS_GLOBAL_BOOK_ADD}
              component={DwsGlobalBookAdd}
            />
            <Route path={SUPPORT_POINT.MANAGE_TOKEN} component={ManageToken} />
            <Route
              path={SUPPORT_POINT.OBSERVER_POINT_INFO}
              component={ObserverPointInfo}
            />
            <Route
              path={SUPPORT_POINT.NEW_INSTITUTE_URL_MAP}
              component={NewInstituteUrlMap}
            />
            <Route
              path={SUPPORT_POINT.INSTITUTE_CONTACT_INFO}
              component={InstituteContactInfo}
            />
            {/************Support Point End *********/}
            {/************Partner POINT Start ****************/}
            <Route path={PARTNER_POINT.PRE_PAID_BILL} component={PrePaidBill} />
            <Route
              path={PARTNER_POINT.POST_PAID_BILL}
              component={PostPaidBill}
            />
            <Route
              path={PARTNER_POINT.EM_BILL_DISCOUNT}
              component={EmBillDiscount}
            />
            <Route
              path={PARTNER_POINT.MESSAGE_TRANSFER}
              component={MessageTransfer}
            />
            <Route
              path={PARTNER_POINT.DOMAIN_HOSTING_ORDER}
              component={DomainOrderMain}
            />
            <Route path={PARTNER_POINT.POINT_ASSIGN} component={PointAssign} />
            <Route
              path={PARTNER_POINT.PARTNER_POINT_ASSIGNED_LIST}
              component={PointAssignList}
            />
            <Route
              path={PARTNER_POINT.YSS_CONFIG}
              component={PartnerYssConfigMain}
            />
            <Route
              path={PARTNER_POINT.PARTNER_POINT_YSS_CONFIG_LIST}
              component={YssConfigList}
            />
            <Route
              path={PARTNER_POINT.EDUMAN_ORDER}
              component={EdumanOrderMain}
            />
            <Route
              path={PARTNER_POINT.RATING_TO_TEAM_LEAD}
              component={PartnerRatingProvideToTeamLead}
            />
            <Route
              path={PARTNER_POINT.RATING_TO_TEAM_MEMBER}
              component={PartnerRatingProvideToTeamMember}
            />
            <Route
              path={PARTNER_POINT.RATING_BY_PARTNER}
              component={EdumanRatingProvideByPartner}
            />
            <Route
              path={PARTNER_POINT.DUE_STATEMENT}
              component={PartnerPointDueStatement}
            />
            <Route
              path={PARTNER_POINT.ASSIGN_EM_INSTITUTE}
              component={AssignEmInstitute}
            />
            <Route
              path={PARTNER_POINT.PARTNER_INFORMATION}
              component={PartnerInformation}
            />
            <Route
              path={PARTNER_POINT.PARTNER_BDP_DUE_STATEMENT}
              component={PartnerBDPDueStatement}
            />
            <Route
              path={PARTNER_POINT.PARTNER_BEP_DUE_STATEMENT}
              component={PartnerBEPDueStatement}
            />
            <Route
              path={PARTNER_POINT.EM_CURRENT_STATUS}
              component={EmCurrentStatus}
            />
            <Route
              path={PARTNER_POINT.EM_BILL_REPORT}
              component={EdumanBillReportMain}
            />
            <Route
              path={PARTNER_POINT.EM_INSTITUTE_INVOICE}
              component={EmInstituteWiseInvoice}
            />
            <Route
              path={PARTNER_POINT.PARTNER_BILL_COLLECTION}
              component={partnerBillCollectionListMain}
            />
            <Route
              path={PARTNER_POINT.PRODUCT_TYPE_WISE}
              component={PartnerAreaWiseProductList}
            />
            <Route
              path={PARTNER_POINT.PARTNER_POINT_PRODUCT_PURCHASE_DETAILS}
              component={PartnerPointProductPurchaseDetails}
            />
            <Route
              path={PARTNER_POINT.AREA_WISE_PREPAID_DEPOSIT}
              component={PartnerAreaWisePrepaidDeposit}
            />
            <Route
              path={PARTNER_POINT.AREA_WISE_POSTPAID_COLLECTION}
              component={PartnerAreaWisePostpaidCollection}
            />
            <Route
              path={PARTNER_POINT.PARTNER_INCOME_SUMMARY}
              component={PartnerDateAndYearWiseIncomeSummary}
            />
            <Route
              path={PARTNER_POINT.TEAM_WISE_PURCHASE}
              component={PartnerTeamWisePurchaseDetails}
            />
            <Route
              path={PARTNER_POINT.PARTNER_SELF_DASHBOARD}
              component={PartnerSelfDashboard}
            />
            <Route
              path={PARTNER_POINT.PARTNER_TEAM_DASHBOARD}
              component={PartnerTeamDashboard}
            />
            <Route
              path={PARTNER_POINT.PARTNER_PERFOMANC_BOARD}
              component={PartnerPerfomanceDashboard}
            />
            <Route
              path={PARTNER_POINT.PARTNER_SCORE_BOARD}
              component={PartnerScoreDashboard}
            />
            <Route
              path={PARTNER_POINT.PARTNER_DUE_STATEMENT_TREE}
              component={PartnerPointDueStatementTree}
            />
            <Route
              path={PARTNER_POINT.PARTNER_DASHBOARD}
              component={PartnerDashBoard}
            />
            <Route
              path={PARTNER_POINT.PARTNER_TEAM_BILL_COLLECTION}
              component={PartnerTeamBillCollection}
            />
            <Route
              path={PARTNER_POINT.PARTNER_REVENUE_DISTRIBUTION_INFO}
              component={PartnerPointRevenueDistribution}
            />
            {/************Partner POINT End ****************/}
            {/************Management POINT START ****************/}
            <Route
              path={MANAGEMENT_POINT.ACCUMULATE_STATEMENT}
              component={AccumulateStatement}
            />
            <Route
              path={MANAGEMENT_POINT.YEARLY_STATEMENT}
              component={YearlyStatement}
            />
            <Route
              path={MANAGEMENT_POINT.MONTHLY_STATEMENT}
              component={MonthlyStatement}
            />
            <Route
              path={MANAGEMENT_POINT.COLLECTIVE_STATEMENT}
              component={CollectiveStatementMain}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_EDUMAN_CURRENT_STATUS}
              component={ManagementEMCurrentStatus}
            />
            <Route
              path={MANAGEMENT_POINT.MP_PARTNER_PERFOMANC_BOARD}
              component={MpPartnerPerfomanceDashboard}
            />
            {/* <Route path={MANAGEMENT_POINT.DUE_STATEMENT_MAIN} component={DueStatementMain} /> */}
            <Route
              path={MANAGEMENT_POINT.DUE_STATEMENT_TREE}
              component={PartnerDueStatementTree}
            />
            <Route
              path={MANAGEMENT_POINT.MBP_DUE_STATEMENT}
              component={MbpDueStatement}
            />
            <Route
              path={MANAGEMENT_POINT.ZBP_DUE_STATEMENT}
              component={ZbpDueStatement}
            />
            <Route
              path={MANAGEMENT_POINT.BDP_DUE_STATEMENT}
              component={BdpDueStatement}
            />
            <Route
              path={MANAGEMENT_POINT.BEP_DUE_STATEMENT}
              component={BepDueStatement}
            />
            <Route
              path={MANAGEMENT_POINT.INSTITUTE_DUE_STATEMENT}
              component={InstituteDueStatement}
            />
            <Route
              path={MANAGEMENT_POINT.ONLINE_PAYMENT_SYSTEM}
              component={OnlinePaymentSystem}
            />
            <Route path={MANAGEMENT_POINT.USER_WALLET} component={UserWallet} />
            <Route
              path={MANAGEMENT_POINT.MONTHLY_TRANSACTION}
              component={MonthlyTransaction}
            />
            <Route
              path={MANAGEMENT_POINT.MONTHLY_DEPOSIT_AMOUNT_LIST}
              component={MonthlyDepositAmountList}
            />
            <Route
              path={MANAGEMENT_POINT.MONTHLY_WITHDRAW_AMOUNT_LIST}
              component={MonthlyWithdrawAmountList}
            />
            <Route
              path={MANAGEMENT_POINT.MONTHLY_TRANSFER_AMOUNT_LIST}
              component={MonthlyTransferAmountList}
            />
            <Route
              path={MANAGEMENT_POINT.MONTHLY_PURCHASE_AMOUNT_LIST}
              component={MonthlyPurchaseAmountList}
            />
            <Route
              path={MANAGEMENT_POINT.MONTHLY_DISBURSEMENT_AMOUNT_LIST}
              component={MonthlyDisbursmentAmountList}
            />
            <Route
              path={MANAGEMENT_POINT.DAILY_TRANSACTION}
              component={DailyTransaction}
            />
            <Route
              path={MANAGEMENT_POINT.DAILY_DEPOSIT_AMOUNT_LIST}
              component={DailyDepositAmountList}
            />
            <Route
              path={MANAGEMENT_POINT.DAILY_WITHDRAW_AMOUNT_LIST}
              component={DailyWithdrawAmountList}
            />
            <Route
              path={MANAGEMENT_POINT.DAILY_TRANSFER_AMOUNT_LIST}
              component={DailyTransferAmountList}
            />
            <Route
              path={MANAGEMENT_POINT.DAILY_PURCHASE_AMOUNT_LIST}
              component={DailyPurchaseAmountList}
            />
            <Route
              path={MANAGEMENT_POINT.DAILY_DISBURSEMENT_AMOUNT_LIST}
              component={DailyDisbursmentAmountList}
            />
            <Route
              path={MANAGEMENT_POINT.BALANCE_REPORT_MESSAGE_TRANSFER}
              component={BalanceReportMessageTransfer}
            />
            <Route
              path={MANAGEMENT_POINT.PREPAID_DEPOSIT_BALANCE}
              component={ManagementPointPrepaidDepositBalance}
            />
            <Route
              path={MANAGEMENT_POINT.PREPAID_DEPOSIT_TREE_VIEW}
              component={ManagementPointPrepaidDepositTreeView}
            />
            <Route
              path={MANAGEMENT_POINT.PARTNER_INCOME_STATEMENT}
              component={PartnerIncomeStatement}
            />
            <Route
              path={MANAGEMENT_POINT.PARTNER_INCOME_STATEMENT_DETAILS}
              component={PartnerIncomeStatementDetails}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_PARTNER_LIST}
              component={ManagementPointPartnerList}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_PARTNER_AREA}
              component={ManagementPointPartnerArea}
            />
            <Route
              path={MANAGEMENT_POINT.PARTNER_TREE}
              component={ManagementPointPartnerTree}
            />
            <Route
              path={MANAGEMENT_POINT.PARTNER_INCOME_SUMMARY}
              component={ManagementPointPartnerIncomeSummary}
            />
            <Route
              path={MANAGEMENT_POINT.MP_PARTNER_INCOME_TREE_VIEW}
              component={ManagementPointPartnerIncomeTreeView}
            />
            <Route
              path={MANAGEMENT_POINT.MP_PARTNER_PRODUCT_PURCHASE_TREE_VIEW}
              component={ManagementPointPartnerProductPurchaseTreeView}
            />
            <Route
              path={MANAGEMENT_POINT.PRODUCT_PURCHASE_INFO_BY_INVOICE_ID}
              component={ProductPurchaseInfoByInvoiceID}
            />
            <Route
              path={MANAGEMENT_POINT.PRODUCT_PURCHASE_REVENUE}
              component={ProductPurchaseRevenue}
            />
            <Route
              path={MANAGEMENT_POINT.PRODUCT_PRE_PURCHASE}
              component={ProductPrePurchase}
            />
            <Route
              path={MANAGEMENT_POINT.PRODUCT_PRE_PURCHASE_DETAILS}
              component={ProductPrePurchaseDetails}
            />
            <Route
              path={MANAGEMENT_POINT.POINT_EXPIRY_INFO}
              component={PointExpiryInfo}
            />
            <Route
              path={MANAGEMENT_POINT.APPLICATION_WISE_TOKEN}
              component={ApplicationWiseToken}
            />
            <Route
              path={MANAGEMENT_POINT.MODULE_WISE_TOKEN}
              component={ModuleWiseToken}
            />
            <Route
              path={MANAGEMENT_POINT.PROBLEM_WISE_TOKEN}
              component={ProblemWiseToken}
            />
            <Route
              path={MANAGEMENT_POINT.TITLE_WISE_TOKEN}
              component={TitleWiseToken}
            />
            <Route
              path={MANAGEMENT_POINT.PERSON_WISE_TOKEN}
              component={PersonWiseToken}
            />
            <Route
              path={MANAGEMENT_POINT.PERSON_WISE_TOKEN_SOLVED_LIST}
              component={PersonWiseTokenSolvedList}
            />
            <Route
              path={MANAGEMENT_POINT.PERSON_WISE_RECEIVED_CALL_LIST}
              component={PersonWiseReceivedCallList}
            />
            <Route
              path={MANAGEMENT_POINT.NETI_CHAT_HISTORY}
              component={NetiChatHistory}
            />
            <Route
              path={MANAGEMENT_POINT.EM_ACTION_TYPE_WISE_MODULE_LOG}
              component={EmActionTypeWiseModuleLog}
            />
            <Route
              path={MANAGEMENT_POINT.EM_MODULE_WISE_INSTITUTE_ACTION_LOG}
              component={EmModuleWiseInstituteActionLog}
            />
            <Route
              path={MANAGEMENT_POINT.EM_MODULE_USED_UNUSED_INSTITUTE_ACTION_LOG}
              component={EmIndividualModuleInstituteActionLog}
            />
            <Route
              path={MANAGEMENT_POINT.EM_INSTITUTE_WISE_ACTION_LOG}
              component={EmInstituteWiseActionLogDetails}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_PRODUCT_PURCHASE_DETAILS}
              component={ManagementPointProductPurchaseDetails}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_PARTNER_BASIC_INFO}
              component={PartnerBasicInfo}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_AREA_WISE_PARTNER_INFO}
              component={MPAreaWisePartnerInfo}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_PRODUCT_SELL_TREE_VIEW}
              component={MPProductSellTreeView}
            />
            <Route
              path={
                MANAGEMENT_POINT.MANAGEMENT_POINT_PARTNER_DASHBOARD_MBP_TYPE
              }
              component={MPPartnerDashboardMBPType}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_EM_IMPLEMENTATION_SMS}
              component={EmImplementationSMS}
            />
            <Route
              path={
                MANAGEMENT_POINT.MANAGEMENT_POINT_EM_IMPLEMENTATION_ACCOUNTS
              }
              component={EmImplementationStudentAndGeneralAccounts}
            />
            <Route
              path={
                MANAGEMENT_POINT.MANAGEMENT_POINT_EM_ACTION_LOG_SUMMARY_CHART
              }
              component={EmTotalActionLogSummaryChart}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_TOKEN_SUMMARY}
              component={ModuleAndPointWiseTokenSummary}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_TOKEN_LIST}
              component={PendingProcessingTokenListEmNw}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_SOLVED_MODULE_WISE_TOKEN}
              component={MpSolvedModuleWiseToken}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_SOLVED_PROBLEM_WISE_TOKEN}
              component={MpSolvedProblemWiseToken}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_SOLVED_TITLE_WISE_TOKEN}
              component={MpSolvedTitleWiseToken}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_EDUMAN_BILL_GENERATE}
              component={EdumanBillGenerateByInstituteID}
            />
            <Route
              path={
                MANAGEMENT_POINT.MANAGEMENT_POINT_AREA_WISE_ATTENDANCE_TREE_VIEW
              }
              component={MpAreaWiseAttendanceTreeView}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_DASHBOARD}
              component={MpNetiCMSDashboard}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_CONFIGURED_URL}
              component={MpNetiCMSConfiguredUrlList}
            />
            <Route
              path={
                MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_NOT_CONFIGURED_URL
              }
              component={MpNetiCMSNotConfiguredUrlList}
            />
            <Route
              path={
                MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_INFO_FILLED_PERCENT
              }
              component={MpNetiCMSInfoFilledPercent}
            />
            <Route
              path={
                MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_INFO_NOT_FILLED_PERCENT
              }
              component={MpNetiCMSInfoNotFilledPercent}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_EDUMAN_MAPPED}
              component={MpNetiCMSInfoEdumanMapped}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_NETICMS_EDUMAN_UNMAPPED}
              component={MpNetiCMSInfoEdumanUnmapped}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_DFPS_DASHBOARD}
              component={DFPSDashboard}
            />
            <Route
              path={MANAGEMENT_POINT.MONTHLY_PAID_UNPAID_INSTITUTE_INFO}
              component={MonthlyPaidUnpaidInstituteInfoTreeView}
            />
            <Route
              path={MANAGEMENT_POINT.MANAGEMENT_POINT_CALL_INFO_DASHBOARD}
              component={MpCallInfoDashboard}
            />
            <Route
              path={MANAGEMENT_POINT.SEND_NOTIFICATION_SMS}
              component={SendNotificationSms}
            />
            <Route
              path={MANAGEMENT_POINT.BALANCE_MISMATCH_INFO}
              component={BalanceMismatchInfo}
            />
            <Route
              path={MANAGEMENT_POINT.DATE_WISE_COLLECTION}
              component={DateWiseCollection}
            />
            <Route
              path={MANAGEMENT_POINT.INSTITUTE_WISE_COLLECTION}
              component={InstituteWiseCollection}
            />
            <Route
              path={MANAGEMENT_POINT.TRANSACTION_ID_WISE_COLLECTION}
              component={TransactionIdWiseCollection}
            />
            <Route
              path={MANAGEMENT_POINT.DATE_RANGE_WISE_PURCHASE_REPORT}
              component={DateRangeWisePurchaseReport}
            />
            {/************Management POINT END ****************/}
          </div>
        </div>

        <AppFooter />
        {this.state.staticMenuMobileActive && (
          <div className="layout-mask"></div>
        )}
      </div>
    );
  }
}

export default App;
