import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { SaSummaryService } from '../../../service/saPoint/SaSummaryService';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';


let viewInstituteInfoColumn = { display: 'none' };
let viewDivisionInfoColumn =  { display: 'table-cell' };
let tableHeaderName;
export class DatatableAttendanceInformation extends Component {
         constructor(props) {
           super(props);
           this.state = {
             attendanceDate: null,
             attendanceDateErrorMessage: null,
             topProgressBar: false,
             dataTableIsLoading: false,
             errors: {}
           };
           this.saSummaryService = new SaSummaryService();
           this.NetiContentLoader = new NetiContentLoader();
         }

         getDivisionName = (rowData => {
           console.log('rowdata division name', rowData);
           let divisionColorCount = 1;
           let divisionNumber = 8; //include color in $division-color-list in sass according to division number (division in BD).

           //clouser Function
           return rowData => {
             return (
               <div className="division-wrapper">
                 <span
                   onClick={e => this.showMoreInfoByDivisionName(rowData)}
                   className={`division-text division-text-color-${
                     divisionColorCount < divisionNumber
                       ? divisionColorCount++
                       : (divisionColorCount = 1)
                   }`}
                 >
                   {rowData.name}
                 </span>
               </div>
             );
           };
         })();

         getInstituteName = (rowData => {
          let divisionColorCount = 1;
          let divisionNumber = 8; //include color in $division-color-list in sass according to division number (division in BD).

          //clouser Function
          return rowData => {
            return (
              <div className="division-wrapper">
                <span
                  className={`division-text division-text-color-${
                    divisionColorCount < divisionNumber
                      ? divisionColorCount++
                      : (divisionColorCount = 1)
                  }`}
                >
                    {`${rowData.instituteId} - ${rowData.instituteName}`}
                </span>
              </div>
            );
          };
        })();

         showMoreInfoByDivisionName = rowData => {
          console.log('rowdata after click', rowData);
          console.log('rowdata after click props', this.props);


           //  Country Code T10201
           //  Division    T10202
           //  District    T10203
           //  Upazilla    T10204
           if(rowData.parentDefaultCode === 'T10201') {
            this.props.getAreaIDFromRowData(rowData.id);
            this.props.showDistrictWiseAttendanceInfoView();
            this.props.hideDivisionWiseAttendanceInfoView();
           } else if(rowData.parentDefaultCode === 'T10202') {
            this.props.getAreaIDFromRowData(rowData);
            this.props.showUpazillaWiseAttendanceInfoView();
            this.props.hideDistrictInfoViewComponent();
           } else if(rowData.parentDefaultCode === 'T10203') {
            this.props.getAreaIDFromRowData(rowData);
            this.props.showInstituteWiseAttendanceInfoView();
            this.props.hideUpazillaWiseAttendanceInfoView();
           }
         };

         getTotalTeacherData = rowData => {
           return (
             <div className="data-value-wrapper">
               <div>
                 <span className="totalValue">
                   {rowData.totalTeacher
                     ? rowData.totalTeacher.toLocaleString("EN-IN")
                     : 0}
                 </span>
               </div>
               <div>
                 <span className="data-value">
                   <i className="fa fa-male male-icon"></i>
                   {rowData.totalMaleTeacher}
                 </span>
                 <span className="data-value">
                   <i className="fa fa-female female-icon"></i>
                   {rowData.totalFemaleTeacher}
                 </span>
               </div>
               <div>
                 <span className="data-value">
                   <i className="fa fa-male male-icon"></i>
                   {`${rowData.totalMaleTeacherPercent} %`}
                 </span>
                 <span className="data-value">
                   <i className="fa fa-female female-icon"></i>
                   {`${rowData.totalFemaleTeacherPercent} %`}
                 </span>
               </div>
             </div>
           );
         };

         getTotalInstitute = rowData => {
           return (
             <div className="total-institute-value">
               {rowData.totalInstitute}
             </div>
           );
         };

         getInstituteType = rowData => {
          return (
            <div className="total-institute-value">
              {rowData.instituteType}
            </div>
          );
        };


         getTotalPresentData = rowData => {
           return (
             <div className="data-value-wrapper">
               <div>
                 <span className="totalValue">
                   {rowData.totalPresent
                     ? `${rowData.totalPresent.toLocaleString("EN-IN")} (${
                         rowData.totalPresentPercent
                       }%)`
                     : 0}
                 </span>
               </div>
               <div>
                 <span className="data-value">
                   <i className="fa fa-male male-icon"></i>
                   {rowData.totalMalePresent}
                 </span>
                 <span className="data-value">
                   <i className="fa fa-female female-icon"></i>
                   {rowData.totalFemalePresent}
                 </span>
               </div>
               <div>
                 <span className="data-value">
                   <i className="fa fa-male male-icon"></i>
                   {`${rowData.totalMalePresentPercent} %`}
                 </span>
                 <span className="data-value">
                   <i className="fa fa-female female-icon"></i>
                   {`${rowData.totalFemalePresentPercent} %`}
                 </span>
               </div>
             </div>
           );
         };

         getTotalAbsentData = rowData => {
           return (
             <div className="data-value-wrapper">
               <div>
                 <span className="totalValue">
                   {rowData.totalAbsent
                     ? `${rowData.totalAbsent.toLocaleString("EN-IN")} (${
                         rowData.totalAbsentPercent
                       }%)`
                     : 0}
                 </span>
               </div>
               <div>
                 <span className="data-value">
                   <i className="fa fa-male male-icon"></i>
                   {rowData.totalMaleAbsent}
                 </span>
                 <span className="data-value">
                   <i className="fa fa-female female-icon"></i>
                   {rowData.totalFemaleAbsent}
                 </span>
               </div>
               <div>
                 <span className="data-value">
                   <i className="fa fa-male male-icon"></i>
                   {`${rowData.totalMaleAbsentPercent} %`}
                 </span>
                 <span className="data-value">
                   <i className="fa fa-female female-icon"></i>
                   {`${rowData.totalFemaleAbsentPercent} %`}
                 </span>
               </div>
             </div>
           );
         };
         getTotalLeaveData = rowData => {
           return (
             <div className="data-value-wrapper">
               <div>
                 <span className="totalValue">
                   {rowData.totalLeave
                     ? `${rowData.totalLeave.toLocaleString("EN-IN")} (${
                         rowData.totalLeavePercent
                       }%)`
                     : 0}
                 </span>
               </div>
               <div>
                 <span className="data-value">
                   <i className="fa fa-male male-icon"></i>
                   {rowData.totalMaleLeave}
                 </span>
                 <span className="data-value">
                   <i className="fa fa-female female-icon"></i>
                   {rowData.totalFemaleLeave}
                 </span>
               </div>
               <div>
                 <span className="data-value">
                   <i className="fa fa-male male-icon"></i>
                   {`${rowData.totalMaleLeavePercent} %`}
                 </span>
                 <span className="data-value">
                   <i className="fa fa-female female-icon"></i>
                   {`${rowData.totalFemaleLeavePercent} %`}
                 </span>
               </div>
             </div>
           );
         };


        actionBodyTemplate = (rowData) => {
          return <div className='text-center'>
              <Button
                  className="nw-action-button edit"
                  icon="fas fa-eye"
                  tooltip="View Reports"
                  onClick={(e) => this.showSingleInstituteInfo(rowData)}
              />
          </div>
      }

      showSingleInstituteInfo = (rowData) => {
        this.props.getAreaIDFromRowData(rowData);
        this.props.showSingleInstituteAttendanceInfoView();
        this.props.hideInstituteWiseAttendanceInfoView();
      }

      goBackToDivisionPage = (e) => {
        this.props.showDivisionComponenentView();
        this.props.hideDistrictWiseAttendanceInfoView();
      }

      goBackToDistrictPage = (e) => {
        this.props.showDistrictInfoViewComponent();
        this.props.hideUpazillaWiseAttendanceInfoView();
      }

      goBackToUpazillaPage = () => {
        this.props.showUpazillaWiseAttendanceInfoView()
        this.props.hideInstituteWiseAttendanceInfoView()
        viewDivisionInfoColumn = { display: 'table-cell' };
      }
  
  

         render() {
          console.log("common datatable props", this.props);

           let { attendanceInfo } = this.props;
           let divisionTableHeaderName = <div className="header-title"><div className="header-title-left"> Division Wise Attendance Information List</div><div style={{cursor:"pointer"}} className="header-title-right"></div></div>;
           let districtTableHeaderName = <div className="header-title"><div className="header-title-left">District Wise Attendance Information List</div><div style={{cursor:"pointer"}} className="header-title-right"><a onClick={e => this.goBackToDivisionPage(e)}><i className="fas fa-angle-left" ></i> &nbsp;&nbsp;  Back</a></div></div>;
           let upazillaTableHeaderName = <div className="header-title"><div className="header-title-left">Upazilla Wise Attendance Information List</div><div style={{cursor:"pointer"}} className="header-title-right"><a onClick={e => this.goBackToDistrictPage(e)}><i className="fas fa-angle-left" ></i> &nbsp;&nbsp;  Back</a></div></div>;
           let instituteTableHeaderName = <div className="header-title"><div className="header-title-left">Institute Wise Attendance Information List</div><div style={{cursor:"pointer"}} className="header-title-right"><a onClick={ e => this.goBackToUpazillaPage(e)}><i className="fas fa-angle-left" ></i> &nbsp;&nbsp;  Back</a></div></div>;


          // let divisionTableHeaderName = (
          //   <div className="header-title">
          //     <div className="header-title-left">
          //       Division Wise Attendance Information List
          //     </div>
          //     <div className="header-title-right">
          //       <span>
          //         {/* Total Found: {attendanceInfo && attendanceInfo.details !== 0 ? attendanceInfo.details.length : 0} */}
          //       </span>
          //       <Button
          //         icon="fas fa-download"
          //         className=""
          //         style={{ padding: "6.7px 24px" }}

          //       />
          //     </div>
          //   </div>
          // );


          let columnHeaderName = 'Division Name';
           if(attendanceInfo && attendanceInfo.details) {
            attendanceInfo.details.map(item => {
              if(item.parentDefaultCode === 'T10202') {
                tableHeaderName = districtTableHeaderName
                columnHeaderName = 'District Name'
              } else if(item.parentDefaultCode === 'T10203') {
                tableHeaderName = upazillaTableHeaderName
                columnHeaderName = 'Upazilla Name'
              } else if(item.instituteId) {
                tableHeaderName = instituteTableHeaderName
              } else if(item.parentDefaultCode === 'T10201') {
                tableHeaderName = divisionTableHeaderName
              }

              if(item.instituteId != undefined) {
                viewInstituteInfoColumn = { display: 'table-cell' }
                viewDivisionInfoColumn = { display: 'none' }
              } else {
                viewInstituteInfoColumn = { display: 'none' }
              }
            });
          } 

           let footerForTeacherAttendance = (
             <ColumnGroup>
               <Row>
                 <Column
                   footer={
                     <div>
                       <span
                         className="footer-value"
                         style={{ textAlign: "center" }}
                       >
                         TOTAL
                       </span>
                     </div>
                   }
                 />

                 <Column
                   footer={
                     <div>
                       <span className="footer-head">Total Institute</span>
                       <span className="footer-value">
                         {attendanceInfo && attendanceInfo.totalInstitute
                           ? attendanceInfo.totalInstitute.toLocaleString(
                               "EN-IN"
                             )
                           : 0}
                       </span>
                     </div>
                   }
                 />

                 <Column
                   footer={
                     <div>
                       <span className="footer-head">Total Teacher</span>
                       <span className="footer-value">
                         {attendanceInfo && attendanceInfo.totalTeacher
                           ? attendanceInfo.totalTeacher.toLocaleString("EN-IN")
                           : 0}
                       </span>
                     </div>
                   }
                 />

                 <Column
                   footer={
                     <div>
                       <span className="footer-head">Present</span>
                       <span className="footer-value">
                         {attendanceInfo && attendanceInfo.totalPresent
                           ? attendanceInfo.totalPresent.toLocaleString("EN-IN")
                           : 0}
                       </span>
                     </div>
                   }
                 />

                 <Column
                   footer={
                     <div>
                       <span className="footer-head">Absent</span>
                       <span className="footer-value">
                         {attendanceInfo && attendanceInfo.totalAbsent
                           ? attendanceInfo.totalAbsent.toLocaleString("EN-IN")
                           : 0}
                       </span>
                     </div>
                   }
                 />

                 <Column
                   footer={
                     <div>
                       <span className="footer-head">leave</span>
                       <span className="footer-value">
                         {attendanceInfo && attendanceInfo.totalLeave
                           ? attendanceInfo.totalLeave.toLocaleString("EN-IN")
                           : 0}
                       </span>
                     </div>
                   }
                 />
                        <Column
                   footer={
                     <div>
                       <span className="footer-head"></span>
                       <span className="footer-value">
                      
                       </span>
                     </div>
                   }
                 />
               </Row>
             </ColumnGroup>
           );

           return (
             <div className="p-grid">
               <div className="p-col-12">
                 <div className="nw-data-table nw-attendence-data-table">
                   <DataTable
                     header={tableHeaderName}
                    //  header={upazillaTableHeader}
                     value={attendanceInfo.details}
                     responsive={true}
                     footerColumnGroup={footerForTeacherAttendance}
                   >
                     <Column
                       field=""
                      //  header="Division"
                       header={columnHeaderName}

                       body={this.getDivisionName}
                       style={{ minWidth: "114px" }}
                       style={viewDivisionInfoColumn}

                     />

<Column
                       field=""
                       header="Institute Name"
                       style={viewInstituteInfoColumn}
                       body={this.getInstituteName}
                     />
                     <Column
                       field=""
                       header="Institute Type"
                       style={viewInstituteInfoColumn}
                       body={this.getInstituteType}
                      //  style={{ minWidth: "114px" }}
                     />
                     <Column
                       field=""
                       header="Total Institute"
                       body={this.getTotalInstitute}
                       style={viewDivisionInfoColumn}
                     />
                     <Column
                       field=""
                       header="Total Teacher"
                       body={this.getTotalTeacherData}
                     />
                     <Column
                       field=""
                       header="Present"
                       body={this.getTotalPresentData}
                     />
                     <Column
                       field=""
                       header="Absent"
                       body={this.getTotalAbsentData}
                     />
                     <Column
                       field=""
                       header="Leave"
                       body={this.getTotalLeaveData}
                     />

<Column
                       field=""
                       header="Action"
                       style={viewInstituteInfoColumn}
                       body={this.actionBodyTemplate}
                     />
                   </DataTable>
                 </div>
               </div>
             </div>
           );
         }
       }