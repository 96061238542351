import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ReportService } from "../../../../service/myPoint/ReportService";
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { pdfDownloadLandscape_TableOnly, pdfDownloadPortrait_TableWithSubTitle, pdfDownloadLandscape_TableWithSubTitle } from "../../../common/JsPdfDownload";

let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
export class UnusedPurchaseCodeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectedStutas: "Custom Status",
      purchaseDate: {},
      dataTableSelection: null,
      dataViewValue: [],
      unuserdPurchaseCodesList: [],
      dataTableIsLoading: false,
      topProgressBar: false,
      errorMsgVisible: false
    }
    this.ReportService = new ReportService();
    this.NetiDateUtils = new NetiDateUtils();
    this.NetiContentLoader = new NetiContentLoader();
  }

  componentWillMount() {
    this.fetchUnusedPurchaseList();
  }

  fetchUnusedPurchaseList() {
    this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
    this.ReportService.getUsedUnusedPurchaseCodeByStatus("0")
      .then(res => {
        if (res.status == 302) {
          return res.json().then(body => {

            // console.log('Unused purchase code res', body);

            for (let i = 0; i < body.length; i++) {
              if (body[i].purchseDate == null) {
                body[i].purchseDate = '';
              } else {
                body[i].purchseDate = NetiDateUtils.getAnyShortForm(body[i].purchseDate, 'DD-MMM-YYYY hh:mm:ss a');
              }
            }

            //   body.map(item => {
            //     let purchaseDate = String(item.productPurchaseLogDTO.purchaseDate);
            //     let coreRoleNote = String(item.productPurchaseLogDTO.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote); 
            //     let categoryName = String(item.productPurchaseLogDTO.productInfoDTO.productTypeInfoDTO.categoryName); 
            //     let productName = String(item.productPurchaseLogDTO.productInfoDTO.productName);  

            //     item.productPurchaseLogDTO.purchaseDate = item.productPurchaseLogDTO.purchaseDate;
            //     item.productPurchaseLogDTO.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote = item.productPurchaseLogDTO.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote;
            //     item.productPurchaseLogDTO.productInfoDTO.productTypeInfoDTO.categoryName = item.productPurchaseLogDTO.productInfoDTO.productTypeInfoDTO.categoryName;
            //     item.productPurchaseLogDTO.productInfoDTO.productName = item.productPurchaseLogDTO.productInfoDTO.productName;

            //     Object.assign(item, {purchaseDate: purchaseDate,coreRoleNote: coreRoleNote, categoryName: categoryName, productName: productName });   // for pdf column
            // });

            this.setState({ unuserdPurchaseCodesList: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });

          });
        } else {

          this.ReportService.Auth.handleErrorStatus(res)
            .then((responseBody) => {
              this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
            });
        }
      }).catch(err => {
        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
      });
  }

  // actionTemplatePruchagePoint = (rowData) => {
  //   return <span>{rowData.productPurchaseLogDTO.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote}</span>;
  // }

  // actionTemplateProductType = (rowData) => {
  //   return <span>{rowData.productPurchaseLogDTO.productInfoDTO.productTypeInfoDTO.categoryName}</span>;
  // }

  // actionTemplateProductName = (rowData) => {
  //   return <span>{rowData.productPurchaseLogDTO.productInfoDTO.productName}</span>;
  // }

  // purchaseCodeBody = (rowData) => {
  //   return <span><b>{rowData.purchaseCode}</b></span>

  // }

  exportPdf = () => {

    let tableColumns = [

      { title: "Purchase Date", dataKey: "purchseDate" },
      { title: "Purchase Point", dataKey: "purchasePoint" },
      { title: "Product Type", dataKey: "productType" },
      { title: "Product Name", dataKey: "productName" },
      { title: "Purchase Code", dataKey: "puchaseCode" },

    ];

    let tableColumnStyles = {
      productName: { halign: "right" },
    };

    pdfDownloadLandscape_TableOnly("Purchase Log Offer Product", 110, tableColumns, this.state.unuserdPurchaseCodesList, tableColumnStyles, "Purchase_Log_Offer_Product.pdf");

  }

  render() {

    let { topProgressBar, errorMsgVisible, errorMsgBody, purchaseDate, unuserdPurchaseCodesList } = this.state;
    console.log(this.state.unuserdPurchaseCodesList);
    let header = <div className="header-title">
      <div className="header-title-left">
        Unused Purchase Code List
                            </div>
      <div className="header-title-right"><a>Total Found: {unuserdPurchaseCodesList.length.toLocaleString("EN-IN")}</a></div>
    </div>;

    return (
      <div className="fluid">

        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }

        <Growl ref={(el) => this.growl = el} />

        <div className="layout-dashboard">
          <div className="nw-data-table nw-data-table-tabview">
            {this.state.dataTableIsLoading ?
              this.NetiContentLoader.MyPointTableLoader()
              :
              <div>
                <DataTable
                  value={this.state.unuserdPurchaseCodesList}
                  selectionMode="single"
                  header={header}
                  selection={this.state.dataTableSelection}
                  onSelectionChange={event =>
                    this.setState({ dataTableSelection: event.value })
                  }
                  responsive={true}
                  paginator={true}
                  rows={10}
                >

                  <Column field="purchseDate" header="Purchase Date" sortable={true} filter={true} />
                  <Column field="purchasePoint" header="Purchase Point" sortable={true} filter={true} />
                  <Column field="productType" header="Product Type" sortable={true} filter={true} />
                  <Column field="productName" header="Product Name" sortable={true} filter={true} />
                  <Column field="puchaseCode" header="Purchase Code" sortable={true} filter={true} />

                </DataTable>
                <div className="nw-button-parent m-t-8 p-r-0">
                  <Button
                    className="p-button p-button-primary nw-button nw-button-right"
                    label="Download"
                    icon="fas fa-download"
                    onClick={this.exportPdf}
                  />
                </div>
              </div>
            }
          </div>

        </div>

      </div>

    );
  }
}
