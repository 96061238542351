import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';
import { pdfDownloadPortrait_TableOnly } from '../common/JsPdfDownload';
import { Button } from 'primereact/button';

export class MessageTransferInfoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            dataTableValue: [],

        }
        this.partnerPointService = new PartnerPointService();
        this.netiContentLoader = new NetiContentLoader();
        this.exportPdf = this.exportPdf.bind(this);

    }

    componentWillMount() {
        this.viewMessageTransferList();
    }

    viewMessageTransferList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        let messageLog = {
            "pageNo": 0,
            "limit": 10
        }
        this.partnerPointService.getMessageTransferLogs(messageLog)
            .then((res) => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].transactionDate == null) {
                                body[i].transactionDate = '';
                            } else {
                                body[i].transactionDate = NetiDateUtils.getAnyShortForm(body[i].transactionDate, 'DD-MMM-YYYY hh:mm:ss a');
                            }

                        }
                        console.log('body', body);
                        this.setState({ dataTableValue: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    instituteNameID = (rowData) => {
        return <div>
            <span>{rowData.instituteId}</span>&nbsp;<span>-</span>&nbsp;<span>{rowData.instituteName}</span>
        </div>
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Transfer Date", dataKey: "transactionDate" },
            { title: "Institute ID", dataKey: "instituteId" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Message Type", dataKey: "messageType" },
            { title: "Message Quantity", dataKey: "messageQuantity" },
            { title: "Note", dataKey: "note" },
        ]

        pdfDownloadPortrait_TableOnly('Message Transfer List', pdfColumns, this.state.dataTableValue, "Message_Transfer_Report.pdf");

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, dataTableIsLoading } = this.state;

        let tableHeader = <div className="header-title">
                                <div className="header-title-left">
                                    Message Transfer List
                                </div>
                                <div className="header-title-right">
                                    <a>
                                        Total Found: {dataTableValue.length.toLocaleString('EN-IN')}
                                    </a>
                                </div>
                            </div>

        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                {dataTableIsLoading ? this.netiContentLoader.MyPointTableLoader() :
                    <div className="nw-data-table">
                        <DataTable
                            value={this.state.dataTableValue}
                            selectionMode="single"
                            header={tableHeader}
                            paginator={true}
                            selection={this.state.dataTableSelection}
                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                            responsive={true}
                            rowsPerPageOptions={[5, 10, 20]}
                            rows={10}
                            columnResizeMode="fit"
                        >
                            <Column field="transactionDate" header="Transfer Date" style={{ width: '110px' }} filter={true} />
                            <Column field="instituteId" header="Institute ID" filter={true} />
                            <Column field="instituteName" header="Institute Name" filter={true} />
                            <Column field="messageType" header="Message Type" filter={true} />
                            <Column field="messageQuantity" header="Message Quantity" filter={true} />
                            <Column field="note" header="Note" filter={true} />
                        </DataTable>

                        <div className="nw-button-parent m-t-8 p-r-0">
                            <Button
                                className="p-button p-button-primary nw-button nw-button-right"
                                label="Download PDF"
                                icon="fas fa-download"
                                onClick={this.exportPdf}
                            />
                        </div>
                    </div>
                }

            </div>
        );
    }
}