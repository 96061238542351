export class ValidatorUtility {
  emailIsValid = email => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  };

  isUrlValid = url => {
    // return /(((http|ftp|https):\/{2})+(([0-9a-z_-]+\.)+(aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mn|mn|mo|mp|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|nom|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ra|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw|arpa)(:[0-9]+)?((\/([~0-9a-zA-Z\#\+\%@\.\/_-]+))?(\?[0-9a-zA-Z\+\%@\/&\[\];=_-]+)?)?))\b/imuS.test(
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
      url
    );
  };

  static isValidNumber(data) {
    let rex = /^\d|[0-9](\.\d{0,2}){0,1}$/
    return rex.test(data);
  }

  currencyFormatter = data => {
    if (data == null || typeof data == "undefined") return ''
    else {
      let floatData = typeof data == "string" ? parseFloat((+data).toFixed(2)) : parseFloat(data.toFixed(2));
      let intdata = parseInt(data);

      if (floatData == intdata) {
        return (floatData.toLocaleString('EN-IN') + '.00')
      } else return floatData.toLocaleString('EN-IN');
    }

  };

  // currencyMaskingRoundFormatter = data => {
  //   if (data == null || typeof data == "undefined") return ''
  //   else {
  //     let floatData = typeof data == "string" ? parseFloat((+data).toFixed(2)) : parseFloat(data.toFixed(2));
  //     let intdata = parseInt(data);

  //     if (floatData == intdata) {
  //       return (floatData.toLocaleString('EN-IN') )
  //     } else return floatData.toLocaleString('EN-IN');
  //   }

  // };


  messageFormatter = data => {

    if (data == null || typeof data == "undefined") return ''
    else {
      let floatData = typeof data == "string" ? parseFloat((+data).toFixed(2)) : parseFloat(data.toFixed(2));
      let intdata = parseInt(data);

      if (floatData == intdata) {
        return (floatData.toLocaleString('EN-IN'))
      } else return floatData.toLocaleString('EN-IN');
    }

  }


}
