import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable'; 
import { Column } from 'primereact/column';
import { UserOtherAccessService } from '../../../service/UserOtherAccessService';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import {Dialog} from 'primereact/dialog';
import CommonFuctionality from '../../common/CommonFuctionality';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
let tabIndexActive;
export class SendNotificationSms extends Component {
    constructor() {
        super();
        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            buttonIcon: "fas fa-search",
            statusValue: '',
            activeIndex: 0,
            smsDialogError: {},
            smsDialogShow:false,
            errors: {},
            smsBody: '',
            dataTableIsLoading: false,
            tableView: false,
            statusField: [
                { label: 'Enable', value: '1' },
                { label: 'Disable', value: '2' }
            ],
            notificationTableData: {},
            userDetails:{}
        }
        this.UserOtherAccessService = new UserOtherAccessService;
        this.ManagementPointService = new ManagementPointService;
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.validatorUtility = new ValidatorUtility();
    }
    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors });
        
    }


    onChangeSmsBody = (e) => {
        let { smsDialogError } = this.state;
        smsDialogError["smsBody"] = "";
        this.setState({ smsBody: e.target.value,smsDialogError  });
    }


    onchangeStatusValue = (e) => {
        let {errors} = this.state;
        errors["partnerStatus"] = "";
        errors["emadminStatus"] = "";
        this.setState({ statusValue: e.target.value,errors });
    }

    onShowDialog = (rowData) => {
        this.setState({  userDetails: {} });
        this.setState({ smsDialogShow: true, userDetails: rowData });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onHideDialog = () => {
        this.setState({ smsDialogShow: false, userDetails: {} });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    detailBody = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-info"
                title='Click to view deposit details'
                onClick={(e) => this.onShowDialog(rowData)}
            />
        </div>
    }
    partnerWiseNotification = (e) => {
        console.log("this.state.activeIndex",this.state.activeIndex);
   
        let { statusValue} = this.state;
        if(this.state.activeIndex === 0){
            tabIndexActive = 'partner'
        }
        if(this.state.activeIndex === 1){
        tabIndexActive = 'admin'
        }
        if (this.searchHandleError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: true });
            this.ManagementPointService.findEmPartnerOrAdminListForSmsSend(statusValue, tabIndexActive)
            .then((res) => {
                if(res.status == 302){
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "SMS Notifications Found" });
                    return res.json().then((body) => {
                        this.setState({notificationTableData:body, tableView: true});
                         
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false });
                    });
                }else{
                    this.growl.show({ severity: 'error', summary: 'Errror Message', detail: "SMS Notifications Not Found" });

                }
            });
            
        }
        
    }

    

    searchHandleError = () => {        
        let { errors, statusValue } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if(tabIndexActive = 'partner') {
            if (statusValue === '') {
                formIsValid = false;
                errors["partnerStatus"] = "Status field can't left empty.";
            }
        } 
        if( tabIndexActive = 'admin') {
            if (statusValue === '') {
                formIsValid = false;
                errors["emadminStatus"] = "Status field can't left empty.";
            }
        }
  
        this.setState({ errors });
        return formIsValid;
    }

    smsDialogErrorPopup = () => {        
        let { smsDialogError } = this.state;
        let formIsValid = true;

        if (this.state.smsBody === '') {
            formIsValid = false;
            smsDialogError["smsBody"] = "Sms body can't left empty.";
        }
        this.setState({ smsDialogError });
        return formIsValid;
    }

    changeIndex = (getActiveIndex) =>{
        console.log('getActiveIndex',getActiveIndex);
        this.setState({tableView:false, activeIndex: getActiveIndex.index, notificationTableData: {},statusValue : ''});
        
    }

    amountBody = (rowData) => {
        return <div >
            <span className="col-number text-right">{rowData.dueAmount ? this.validatorUtility.currencyFormatter(rowData.dueAmount) : 0 }</span> <br />
        </div>;
    }

    submitUpdate = () => {
        let requestObj = {
            "messageBody": this.state.smsBody,
            "receiver": this.state.userDetails.mobile
        }

        if (this.smsDialogErrorPopup()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });

            this.UserOtherAccessService.sendSmsBodyFromUserEnd(requestObj)
                .then(res => {
                    if (res.status === 201) {
                        this.partnerWiseNotification();
                        this.onHideDialog();
                        this.setState({ topProgressBar: false, errorMsgVisible: false,userDetails:{},smsBody:'' });

                    } else {
                        this.UserOtherAccessService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({  topProgressBar: false,errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })

                );
        }
    }

    render() {
        let { userDetails, topProgressBar, errorMsgVisible, errorMsgBody, notificationTableData, tableView } = this.state;

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Due Notifications Sms Send <span style={{ 'float': 'right' }}> Total Found: {notificationTableData.length ? notificationTableData.length : '0'} </span></div>;


        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />
                <div className="blur-section">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                            <TabView className="wallet-main-tabview" activeIndex={this.state.activeIndex} onTabChange={(e) => this.changeIndex(e)}>
                                <TabPanel header="Partner" >
                                    <div className="p-grid nw-form blur-section">
                                        <div className="p-col-12 p-xl-8 p-col-nogutter">
                                            <div className="p-col-12 p-xl-12">
                                                <div className='nw-form-body'>
                                                    <div className="p-grid seprator-inside">
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Status <span>*</span></span>
                                                                <Dropdown
                                                                    placeholder="Select status"
                                                                    filterBy="value"
                                                                    showClear={true}
                                                                    autoWidth={false}
                                                                    options={this.state.statusField}
                                                                    value={this.state.statusValue}
                                                                    onChange={this.onchangeStatusValue}
                                                                    name="partnerStatus"
                                                                />
                                                                {/* <span className="p-inputgroup-addon">@</span> */}
                                                            </div>
                                                            <span className='error-message'>{ this.state.errors["partnerStatus"] ?  this.state.errors["partnerStatus"] : ''}</span>
                                                        </div>
                                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                                            <div className="required-field">
                                                                (<span>*</span>) required fields
                                                            </div>
                                                            <Button
                                                                className="p-button-primary nw-button nw-button-right" //p-button-primary 
                                                                label="Search"
                                                                icon={this.state.buttonIcon}//"fas fa-check" //fas fa-spinner fa-spin
                                                                onClick={this.partnerWiseNotification}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-data-table nw-data-table-tabview">
                                        { 
                                            
                                            tableView === true ?
                                            this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader() :
                                            <DataTable
                                                value={notificationTableData}
                                                selectionMode="single"
                                                activeIndex="0"
                                                header={tableHeader}
                                                responsive={true}
                                                rows={10}
                                                paginator={true}
                                            >
                                                <Column field="instituteID" header="Instiute ID" body={this.dateFormatTempl} filter={true} />
                                                <Column field="instituteName" header="Instiute Name" filter={true}/>
                                                <Column field="dueAmount" header="Due Amount" body={this.amountBody} filter={true} />
                                                <Column field="netiID" header="Neti ID"  filter={true}/>
                                                <Column field="name" header="Name" filter={true}/>
                                                <Column field="mobile" header="Mobile No" filter={true} />
                                                <Column field="area" header="Area" filter={true}/>
                                                <Column  header="Action"  body={this.detailBody}/>
                                            </DataTable> 
                                            : ''
                                        }
                                            
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="EM Admin">
                                <div className="p-grid nw-form blur-section">
                                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                                            <div className="p-col-12 p-xl-12">
                                                <div className='nw-form-body'>
                                                    <div className="p-grid seprator-inside">
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Status <span>*</span></span>
                                                                <Dropdown
                                                                    placeholder="Select Status"
                                                                    filterBy="value"
                                                                    showClear={true}
                                                                    autoWidth={false}
                                                                    options={this.state.statusField}
                                                                    value={this.state.statusValue}
                                                                    onChange={this.onchangeStatusValue}
                                                                    name="emadminStatus"
                                                                />
                                                                {/* <span className="p-inputgroup-addon">@</span> */}
                                                            </div>
                                                            <span className='error-message'>{ this.state.errors["emadminStatus"] ?  this.state.errors["emadminStatus"] : ''}</span>
                                                        </div>
                                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                                            <div className="required-field">
                                                                (<span>*</span>) required fields
                                                            </div>
                                                            <Button
                                                                className="p-button-primary nw-button nw-button-right" //p-button-primary 
                                                                label="Search"
                                                                icon={this.state.buttonIcon}//"fas fa-check" //fas fa-spinner fa-spin
                                                                onClick={this.partnerWiseNotification}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-data-table nw-data-table-tabview">
                                        {this.state.tableView === true ? 
                                        this.state.dataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader() :
                                            <DataTable
                                                value={this.state.notificationTableData}
                                                selectionMode="single"
                                                activeIndex="1"
                                                header={tableHeader}
                                                responsive={true}
                                                rows={10}
                                                paginator={true}
                                            >
                                                 <Column field="instituteID" header="Instiute ID" body={this.dateFormatTempl} filter={true} />
                                                <Column field="instituteName" header="Instiute Name" filter={true}/>
                                                <Column field="dueAmount" header="Due Amount" body={this.amountBody} filter={true}/>
                                                <Column field="netiID" header="Neti ID" filter={true} />
                                                <Column field="name" header="Name" filter={true}/>
                                                <Column field="mobile" header="Mobile No" filter={true}/>
                                                <Column  header="Action"  body={this.detailBody}/>
                                            </DataTable>
                                            : ''}
                                        </div>
                                    </div>
                                
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
                <div className="dialog-section">
                    <Dialog header="Send Due Notification SMS" className="nw-dialog" visible={this.state.smsDialogShow} onHide={this.onHideDialog}>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form">
                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="nw-form-body">

                                
                                      


                                    


                       

                                   

                                    <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                              <span className="p-inputgroup-addon nw-inputtextarea-label">SMS Body <span>*</span></span>
                              <InputTextarea
                                                placeholder='Enter sms body'
                                                name="smsBody"
                                                value={this.state.smsBody}
                                                onChange={this.onChangeSmsBody}
                                                autoWidth={false}
                                            />
                          </div>
                          <span className='error-message'>{this.state.smsDialogError["smsBody"]}</span>
                        </div>

                                  

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            label="Discard"
                                            icon="fas fa-times"
                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                            onClick={this.onHideDialog}
                                        />
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            label="Update"
                                            onClick={this.submitUpdate}
                                            icon="fas fa-check"
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }
}