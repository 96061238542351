import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ProgressBar } from 'primereact/progressbar';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { FileUpload } from 'primereact/fileupload';
import { UserCoreUrlInfoService } from '../../../service/UserCoreUrlInfoService';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';

let errors = {}
export class AdmisiaPointViewInstitute extends Component {

    constructor(props) {
        super(props);
        this.state = {
            proPic: { contentName: '' },
            instituteInfo: {
                urlInfoID: props.instituteInfo.urlInfoID,
                instituteName: props.instituteInfo.instituteName,
                instituteAddress: props.instituteInfo.instituteAddress,
                instituteContact: props.instituteInfo.instituteContact,
                instituteEmail: props.instituteInfo.instituteEmail,
                applicationType: props.instituteInfo.applicationType,
                logoPath: props.instituteInfo.logoPath,
            },
            institutePicture: '',
            errors: {},
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            cloneInstituteImg:''
        };
        this.netiFileHandler = new NetiFileHandler();
        this.userCoreUrlInfoService = new UserCoreUrlInfoService();

        this.getProfileImg = this.getProfileImg.bind(this);
        this.onBasicUploadAuto = this.onBasicUploadAuto.bind(this);
        this.getProfileImg();
        this.reloadInstitutionInfo = this.reloadInstitutionInfo.bind(this);
        this.validatorUtility = new ValidatorUtility();
    }

    reloadInstitutionInfo() {
        this.props.fetchInstituteInfoDetails();
    }

    onHide = () => {

        this.getProfileImg();
        this.setState({ visible: false });
        errors = {};

    }

    
    viewDialog = () => {

      console.log("mobile Number", this.props.instituteInfo.instituteContact.length);
      
        this.setState({ visible: true });
        this.setState({
            proPic: { contentName: '' },
            instituteInfo: {
                urlInfoID: this.props.instituteInfo.urlInfoID,
                instituteName: this.props.instituteInfo.instituteName,
                instituteAddress: this.props.instituteInfo.instituteAddress,
                instituteContact: this.props.instituteInfo.instituteContact,
                instituteEmail: this.props.instituteInfo.instituteEmail,
                applicationType: this.props.instituteInfo.applicationType,
                logoPath: this.props.instituteInfo.logoPath,
            }
        })
    }

    onChangeEditHandler = (e) => { this.viewDialog(e) }

    onChangeInstituteName = (e) => {
        let { instituteInfo } = this.state;
        instituteInfo.instituteName = e.target.value;
        this.setState({ instituteInfo });
    }

    onChangeInstituteAddress = (e) => {
        let { instituteInfo } = this.state;
        instituteInfo.instituteAddress = e.target.value;
        this.setState({ instituteInfo });
    }

    onChangeInstituteContact = (event) => {

      
        let { instituteInfo } = this.state;
        instituteInfo.instituteContact = event.target.value;
        this.setState({ instituteInfo });

        console.log("Number", event.target.value.length);
        

    }

    onChangeInstituteEmail = (e) => {
        let { instituteInfo } = this.state;
        instituteInfo.instituteEmail = e.target.value;
        errors["instituteEmail"] = '';
        this.setState({ instituteInfo });

    }

    getProfileImg() {

        let { instituteInfo } = this.props;
        let staticImg = 'assets/layout/images/avatar.png';

        if (instituteInfo.logoPath !== null) {
            this.netiFileHandler.getByteImage(instituteInfo.logoPath)
                .then((res) => {

                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(instituteInfo.logoName);
                                this.setState({ institutePicture: contentType + body.fileContent });
                                this.setState({ cloneInstituteImg: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ institutePicture: staticImg });
                    }
                });
        } else { this.setState({ institutePicture: staticImg }) };

    }

    onBasicUploadAuto(e) {

        let { instituteInfo } = this.state;
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });
            instituteInfo.logoSaveOrEditable = true
        }
    }

    handleError() {

        let { instituteInfo } = this.state;

        errors = {};
        let formIsValid = true;

        if (instituteInfo.instituteName === '' || instituteInfo.instituteName === undefined) {
            formIsValid = false;
            errors["instituteName"] = "Institute Name can't left empty";
        }

        if (instituteInfo.instituteAddress === '' || instituteInfo.instituteAddress === undefined) {
            formIsValid = false;
            errors["instituteAddress"] = "Address can't left empty";
        }

        if (instituteInfo.instituteContact === '' || instituteInfo.instituteContact === undefined) {
            formIsValid = false;
            errors["instituteContact"] = "Mobile No. can't left empty";

        } 
        
        else if (instituteInfo.instituteContact.length < 11){
            formIsValid = false;
            errors["instituteContact"] = "Mobile No. can't be less than 11";
        }
        
        if (instituteInfo.instituteEmail === '' || instituteInfo.instituteEmail === undefined) {
            formIsValid = false;
            errors["instituteEmail"] = "Email can't left empty";
        }

        else if (!this.validatorUtility.emailIsValid(instituteInfo.instituteEmail)) {
            formIsValid = false;
            errors["instituteEmail"] = "Invalid Email format";

        }
        
        if (  this.state.instituteInfo.logoName === "" &&  this.state.proPic.contentName === "") {
            formIsValid = false;
            errors["logo"] = "Logo can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onUpdateInstitute = () => {
        if (this.handleError()) {

            let { instituteInfo } = this.state
            if (instituteInfo.logoSaveOrEditable === true) {
                instituteInfo.logoContent = this.state.proPic.contentPic;
                // let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
                // instituteInfo.logoName = instituteInfo.urlName + extention;
                instituteInfo.logoName = this.state.proPic.contentName;
            }
            
            this.setState({ instituteInfo })

            // console.log("instituteInfo", this.state.instituteInfo);

            this.setState({ errorMsgVisible: false })
            this.setState({ topProgressBar: true });

            this.userCoreUrlInfoService.updateInstituteInfo(instituteInfo)
                .then(res => {
                    if (res.status == 202) {
                        return res.json().then((body) => {
                            this.setState({ topProgressBar: false });
                            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data Update Successful.' });
                            this.setState({ visible: false });
                            this.onHide();
                            if (this.state.proPic.contentPic) {
                                window.history.go();
                            } else {
                                this.reloadInstitutionInfo();
                            }
                        })
                    }
                    else {

                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false });
                                this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                            });

                    }
                }).catch(error => {
                    // errors["dropDownClassError"] = 'Connection Problem'
                    this.setState({
                        errors: errors,
                        dataTableIsLoading: false,
                        topProgressBar: false
                    })
                });
        }


    }

    onResetInstitute = () => {
        this.setState({
            proPic: { contentName: '' },
            instituteInfo: {
                urlInfoID: this.props.instituteInfo.urlInfoID,
                instituteName: this.props.instituteInfo.instituteName,
                instituteAddress: this.props.instituteInfo.instituteAddress,
                instituteContact: this.props.instituteInfo.instituteContact,
                instituteEmail: this.props.instituteInfo.instituteEmail,
            }
        })
    }


    removeButtonOnclick = () =>{

      let {instituteInfo , proPic} = this.state;

      instituteInfo.logoContent = "";
      instituteInfo.logoName = "";
      proPic.extention = ""
      proPic.contentPic = ""
      proPic.contentName = ""
      

      // this.setState({ proPic: { contentPic: '' }, institutePicture: '' })

      this.setState({ institutePicture: '', proPic, instituteInfo })
          
        
    }


    render() {

        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">(370 x 240 px (recommended)</span></div>


        return (
          <div className="p-fluid">
            {this.state.topProgressBar ? (
              <div>
                <ProgressBar
                  mode="indeterminate"
                  showValue={true}
                  style={{ height: "4px" }}
                />
              </div>
            ) : null}
            {this.state.errorMsgVisible ? (
              <ErrorMessageView errorMsgBody={this.state.errorMsgBody} />
            ) : null}
            <Growl ref={el => (this.growl = el)} />
            <div className="p-grid nw-form">
              <div className="p-col-12 p-xl-12">
                <div className="nw-form-body">




                  {/* <div className="p-col-12 p-xl-12">
                    <center>
                      <img
                        src={
                          // this.state.proPic.contentPic ||
                          this.state.institutePicture || this.state.cloneInstituteImg
                        }
                        alt="Institute Image"
                        width="150px"
                      />
                    </center>
                  </div> */}

                 


                  {/* <div
                    className="p-col-12 p-xl-12"
                    style={{ paddingTop: "30px" }}
                  > */}
                    {/* <h1
                      className="text-center"
                      style={{
                        paddingLeft: "10px",
                        fontStyle: "bold",
                        fontSize: "1.5rem"
                      }}
                    >
                      Institute Information
                    </h1> */}

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Institute Name
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.props.instituteInfo.instituteName}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Address
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.props.instituteInfo.instituteAddress}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Mobile No.
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.props.instituteInfo.instituteContact}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Email Address
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.props.instituteInfo.instituteEmail}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Created URL
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.props.instituteInfo.urlName}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Created Date
                        </span>
                        <div className="nw-inputgroup-desc">
                          {NetiDateUtils.getAnyShortForm(
                            this.props.instituteInfo.urlCreateDate,
                            "DD-MMM-YYYY"
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      {this.state.dataIsLoading ?
                      this.NetiContentLoader.normalFormInputField() :
                          <div className="p-inputgroup nw-upload-button">
                              <span className="p-inputgroup-addon nw-inputtextarea-label">
                              Circular <span>*</span>
                              <br />
                              <span>(PNG/JPG/JPEG/GIF)</span>
                              </span>

                              <div className="nw-upload-button-inside">
                                <div className="image-view-main">
                                  <div className="upload-image-view">
                                      <img
                                          src={this.state.institutePicture || this.state.cloneInstituteImg}
                                          alt="Institute Logo"
                                          style={{ height: "80px" }}
                                      />
                                    
                                  </div>
                                  <div className="image-title">
                                  {this.state.proPic.contentName || this.state.instituteInfo.logoName}
                                  </div>
                                </div>
                            
                              </div>
                          
                          </div>
                      }
                    
                    </div>
 
                    <div className="p-col-12 p-xl-12 nw-button-parent">
                      <Button
                        className="p-button-primary nw-button nw-button-right"
                        label="Edit"
                        icon="fas fa-check"
                        onClick={this.onChangeEditHandler.bind(this)}
                      />
                    </div>
                 
                </div>
              </div>
              

              <Dialog
                className="nw-dialog"
                header="Edit Institute Information"
                visible={this.state.visible}
                onHide={this.onHide}
                closable
              >
                <div className="p-fluid">
                  <div className="p-grid">

                    <div className="p-col-12 p-xl-12">
                      <div className="p-grid nw-form">
                       
                          <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                  <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                      Institute Name <span>*</span>
                                    </span>
                                    <InputText
                                      id="instituteName"
                                      placeholder="Enter Institute Name"
                                      style={{ width: "100%" }}
                                      type="text"
                                      name="instituteName"
                                      onChange={this.onChangeInstituteName}
                                      value={this.state.instituteInfo.instituteName}
                                      maxLength="120"
                                    />
                                  </div>
                                  <span className="error-message">
                                    {this.state.instituteInfo.instituteName
                                      ? ""
                                      : errors["instituteName"]}
                                  </span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                  <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                      Address <span>*</span>
                                    </span>
                                    <InputText
                                      id="address"
                                      placeholder="Enter Address"
                                      style={{ width: "100%" }}
                                      type="text"
                                      name="instituteAddress"
                                      onChange={this.onChangeInstituteAddress}
                                      value={
                                        this.state.instituteInfo.instituteAddress
                                      }
                                      maxLength="120"
                                    />
                                  </div>
                                  <span className="error-message">
                                    {this.state.instituteInfo.instituteAddress
                                      ? ""
                                      : errors["instituteAddress"]}
                                  </span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                  <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                      Mobile No. <span>*</span>
                                    </span>
                                    <InputText
                                      placeholder="Enter mobile no."
                                      style={{ width: "100%" }}
                                      type="text"
                                      id="mobileNo"
                                      name="instituteContact"
                                      onChange={this.onChangeInstituteContact}
                                      value={
                                        this.state.instituteInfo.instituteContact
                                      }
                                      keyfilter="pint"
                                      maxlength={11}
                                    //   onInput = {(e) =>{
                                    //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                                    // }}/>

                                    />
                                  </div>
                                  <span className="error-message">
                                    {  this.state.instituteInfo.instituteContact && this.state.instituteInfo.instituteContact.length == 11 ? "" : errors["instituteContact"]}
                                  </span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                  <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                      E-mail Address <span>*</span>
                                    </span>
                                    <InputText
                                      placeholder="Enter email address"
                                      style={{ width: "100%" }}
                                      type="text"
                                      id="email"
                                      name="instituteEmail"
                                      onChange={this.onChangeInstituteEmail}
                                      value={
                                        this.state.instituteInfo.instituteEmail
                                      }
                                    />
                                  </div>
                                  <span className="error-message">
                                    {this.state.instituteInfo.instituteEmail &&
                                    !errors["instituteEmail"]
                                      ? ""
                                      : errors["instituteEmail"]}
                                  </span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                  <div className="p-inputgroup nw-upload-button">
                                    <span className="p-inputgroup-addon nw-inputtextarea-label">
                                      Upload Image <span>*</span>
                                      <br />
                                      <span>(PNG/JPG/JPEG/GIF)</span>
                                    </span>

                                    <div className="nw-upload-button-inside">
                                      {this.state.proPic.contentPic ||
                                      this.state.institutePicture ? (
                                        <div className="image-view-main">
                                          <div className="upload-image-view">
                                            <Button
                                              className="delete-upload-button"
                                              icon="fas fa-times-circle"
                                              onClick={this.removeButtonOnclick}
                                            />
                                            <img
                                              src={
                                                this.state.institutePicture === ""
                                                  ? "data:" +
                                                    this.state.proPic.extention +
                                                    ";base64," +
                                                    this.state.proPic.contentPic
                                                  : this.state.institutePicture
                                              }
                                              style={{ height: "80px" }}
                                            />
                                          </div>
                                          <div className="image-title">
                                            {this.state.proPic.contentName ||
                                              this.state.instituteInfo.logoName}
                                          </div>
                                        </div>
                                      ) : (
                                        <FileUpload
                                          chooseLabel={
                                            this.state.proPic.contentName ||
                                            uploadFileLabel
                                          }
                                          mode="basic"
                                          accept="image/*"
                                          maxFileSize={1000000}
                                          onSelect={this.onBasicUploadAuto.bind(
                                            this
                                          )}
                                          auto={true}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {/* <span className="error-message">
                                    {errors["logo"]}
                                  </span> */}

                                  <span className='error-message'>{this.state.proPic.contentName ? "" : errors["logo"]}</span>
                                </div>






                                <div className="p-grid p-col-12 p-col-12 nw-button-parent-multiple">
                                  <Button
                                    className="p-button-danger nw-button nw-button-multiple"
                                    label="Discard"
                                    icon="fas fa-times"
                                    onClick={e => this.onHide()}
                                    // onClick={this.onResetInstitute}
                                  />

                                  <Button
                                    className="p-button-primary nw-button nw-button-multiple"
                                    label="Update"
                                    icon="fas fa-check"
                                    onClick={this.onUpdateInstitute}
                                  />
                                </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        );
    }
}
