import { EM_ADMIN_POINT } from './PointWiseRouteConsts';

export const EMAdminPointMenu = {

    items: [
        {label: 'EM Admin Point', icon: 'fas fa-user-lock',
        items: [
            // {
            //     label: 'Payment', icon: '', //pi pi-fw pi-dollar
            //     items: [
            //         { label: 'Postpaid Bill', icon: '', to: EM_ADMIN_POINT.POST_PAID_BILL }, //pi pi-fw pi-money-bill
            //         { label: 'Prepaid Bill', icon: '', to: EM_ADMIN_POINT.PRE_PAID_BILL }, //pi pi-fw pi-money-bill
            //     ]
            // }
            
            ,

            {
                label: 'Transfer', icon: '', //pi pi-fw pi-dollar
                items: [
                    { label: 'SMS to Eduman', icon: '', to: EM_ADMIN_POINT.SMS_TRANSFER } //pi pi-fw pi-envelope
                ]
            },

            {
                label: 'Assign', icon: '', //pi pi-fw pi-dollar
                items: [
                    { label: 'Attendance Device', icon: '', to: EM_ADMIN_POINT.YSS_CONFIG }, //pi pi-fw pi-money-bill
                    { label: 'Observer Permission', icon: '', to: EM_ADMIN_POINT.AUDIT_POINT_TAGGING },
                    // Remove Configured List menu from menu List
                    // { label: 'Configured List', icon: '', to: EM_ADMIN_POINT.YSS_CONFIGURED_LIST}, //pi pi-fw pi-envelope
                ]
            },

            {   label: 'Reports'    },

            {
                label: 'Institute Info', icon: '', //pi pi-fw pi-dollar
                items: [
                    { label: 'Summary Info', icon: '', to: '' }, //new report will be added
                    { label: 'At a Glance', icon: '', to: '' }, //new report will be added
                ]
            },

            {
                label: 'DFPS Collection', icon: '', //pi pi-fw pi-dollar
                items: [
                    { label: 'Summary Info', icon: '', to: EM_ADMIN_POINT.EM_ONLINE_PAYMENT_SYSTEM }, //pi pi-fw pi-money-bill
                    { label: 'Date Wise', icon: '', to: EM_ADMIN_POINT.EM_ONLINE_PAYMENT_COLLECTION_SUMMARY }, //pi pi-fw pi-money-bill
                    { label: 'Student Wise', icon: '', to: EM_ADMIN_POINT.EM_ONLINE_PAYMENT_COLLECTION_DETAILS }, //pi pi-fw pi-money-bill
                    { label: 'Transaction ID', icon: '', to: EM_ADMIN_POINT.EM_ONLINE_PAYMENT_REFUND_REPORT }, //pi pi-fw pi-money-bill
                    { label: 'Settlement info', icon: '', to: EM_ADMIN_POINT.EM_ONLINE_PAYMENT_REFUND_SUMMARY }, //pi pi-fw pi-money-bill
                ]
            },
         ]
     }
    ]
}