import React, { Component } from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import {CommonSearchByClassAndGroup} from './CommonSearchByClassAndGroup';

export class AdmisiaPointAssessment extends Component { 
    constructor(props) {
        super(props);
        
    }


    render() {
        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView>
                            <TabPanel header="Pending Applicants" leftIcon="fas fa-download">
                                <CommonSearchByClassAndGroup componentName="assessmentPendingApplicants"/>
                            </TabPanel>
                            <TabPanel header="Exam Mark" leftIcon="fas fa-upload">
                                <CommonSearchByClassAndGroup componentName="assessmentExamMark"/>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }
}