import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import NetiContentLoader from "../../common/NetiContentLoader";
import { ManagementPointService } from "../../../service/managementPoint/ManagementPointService";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
import Formatter from "../../../utils/Formatter";

// pdf related import
import { pdfDownloadLandscape_FullTable } from "../../common/JsPdfDownload";
import { Workbook } from "react-excel-workbook";
import { SplitButton } from "primereact/splitbutton";
let subTitleColumn = "";
let subTitleList = "";
// pdf related import end

let totalPayableAmount = 0;
let totalDiscountAmount = 0;
let totalPaidAmount = 0;
let totalDueAmount = 0;

let ddkd = '';
export class YearlyStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTableValue: [],
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: "",
      yearValue: "",
      errors: {},
      dataTableIsLoading: false,

      items: [
        { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
        { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
      ]

    };

    this.managementPointService = new ManagementPointService();
    this.NetiContentLoader = new NetiContentLoader();
    this.ValidatorUtility = new ValidatorUtility();
    this.Formatter = new Formatter();

    totalPayableAmount = 0;
    totalDiscountAmount = 0;
    totalPaidAmount = 0;
    totalDueAmount = 0;

    this.exportPdf = this.exportPdf.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.exportExcelLog = this.exportExcelLog.bind(this);

  }

  exportPdf() {

    let pdfColumns = [
      { title: "Month", dataKey: "month" },
      { title: "Institute [Live]", dataKey: "liveInstitute" },
      { title: "Institute [Demo]", dataKey: "practiceInstitute" },
      { title: "Live Institute [Active]", dataKey: "liveActive" },
      { title: "Live Institute [Inactive]", dataKey: "liveInactive" },
      { title: "Student [Disable]", dataKey: "disableStudent" },
      { title: "Student [Billable]", dataKey: "billableStudent" },
      { title: "Payable Amount", dataKey: "BGTotal" },
      { title: "Discount Amount", dataKey: "BDTotal" },
      { title: "Paid Amount", dataKey: "BPTotal" },
      { title: "Due Amount", dataKey: "due" }
    ];

    let tableColumnStyles = {
      month: { columnWidth: 24 },
      liveInstitute: { columnWidth: 22 },
      practiceInstitute: { columnWidth: 22 },
      liveActive: { columnWidth: 26, textColor: "green" },
      liveInactive: { columnWidth: 26, textColor: "red" },
      disableStudent: { columnWidth: 24 },
      billableStudent: { columnWidth: 26 },

      BGTotal: { halign: "right", columnWidth: 26 },
      BDTotal: { halign: "right", columnWidth: 22 },
      BPTotal: { halign: "right", columnWidth: 24 },
      due: { halign: "right", columnWidth: 24 },
    }

    var totalArrayList = [
      {
        id: "1",
        totalPdf: "Total",
        totalAmount1: totalPayableAmount,
        totalAmount2: totalDiscountAmount,
        totalAmount3: totalPaidAmount,
        totalAmount4: totalDueAmount
      }
    ];

    let totalColumns = [{ dataKey: "totalPdf" }, { dataKey: "totalAmount1" }, { dataKey: "totalAmount2" }, { dataKey: "totalAmount3" }, { dataKey: "totalAmount4" },];

    let totalColumnStyles = {
      totalPdf: { halign: "right", fontSize: 10, cellWidth: 172.1, fontStyle: "bold", },
      totalAmount1: { halign: "right", fontStyle: "bold", fontSize: 9, cellWidth: 26 },
      totalAmount2: { halign: "right", fontSize: 9, fontStyle: "bold", cellWidth: 22 },
      totalAmount3: { halign: "right", fontSize: 9, fontStyle: "bold", columnWidth: 24 },
      totalAmount4: { halign: "right", fontSize: 9, fontStyle: "bold", columnWidth: 24 }
    }

    pdfDownloadLandscape_FullTable("Eduman Bill Yearly Statement", 114, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableColumnStyles, totalColumns, totalArrayList, totalColumnStyles, "YearlyStatement_of_EM_Bill.pdf");

  }

  exportExcel() {
    document.getElementById("clickExcelBtn").click();
  }

  exportExcelLog() { console.log('excel downloaded'); }

  onSubmitGetYearlyBalance() {

    if (this.onSubmitFormError()) {
      totalPayableAmount = totalDiscountAmount = totalPaidAmount = totalDueAmount = 0;
      this.setState({
        topProgressBar: true,
        dataTableIsLoading: true,
        errorMsgVisible: false
      });
      this.managementPointService
        .fetchEdumanYearlyBalanceTransactionList(this.state.yearValue)
        .then(res => {
          if (res.status == 302) {
            return res.json().then(body => {
              Object.values(body).map(item => {

                item.liveInstitute = item.liveInstitute.toLocaleString('EN-IN');
                item.practiceInstitute = item.practiceInstitute.toLocaleString('EN-IN');
                item.liveActive = item.liveActive.toLocaleString('EN-IN');
                item.liveInactive = item.liveInactive.toLocaleString('EN-IN');
                item.disableStudent = item.disableStudent.toLocaleString('EN-IN');
                item.billableStudent = item.billableStudent.toLocaleString('EN-IN');

                item.BGTotal = item.BGTotal.toFixed(2);
                item.BDTotal = item.BDTotal.toFixed(2);
                item.BPTotal = item.BPTotal.toFixed(2);
                item.due = item.due.toFixed(2);

              });
              this.setState({ dataTableValue: Object.values(body) });

              totalPayableAmount = Object.values(body)
                .map(item => +item.BGTotal)
                .reduce((a, b) => {
                  return a + b;
                }, 0);
              totalDiscountAmount = Object.values(body)
                .map(item => +item.BDTotal)
                .reduce((a, b) => {
                  return a + b;
                }, 0);
              totalPaidAmount = Object.values(body)
                .map(item => +item.BPTotal)
                .reduce((a, b) => {
                  return a + b;
                }, 0);
              totalDueAmount = Object.values(body)
                .map(item => +item.due)
                .reduce((a, b) => {
                  return a + b;
                }, 0);

              this.setState({
                topProgressBar: false,
                dataTableIsLoading: false,
                errorMsgVisible: false
              });
            });
          } else {
            this.managementPointService.Auth.handleErrorStatus(res).then(
              responseBody => {
                this.setState({
                  errorMsgVisible: true,
                  errorMsgBody: responseBody,
                  topProgressBar: false,
                  dataTableIsLoading: false
                });
              }
            );
          }
        })
        .catch(error => {
          this.setState({
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgVisible: true,
            errorMsgBody: error + "Please check your connection!!!"
          });
        });
    }
  }

  onSubmitFormError = () => {
    let { errors } = this.state;
    let formIsValid = true;

    if (this.state.yearValue === "") {
      formIsValid = false;
      errors["year"] = "Year can't left empty.";
    }

    this.setState({ errors });
    return formIsValid;
  };

  onChangeYearValue = e => {
    let { errors } = this.state;
    errors["year"] = "";
    this.setState({ yearValue: e.target.value, errors });
  };

  liveInstituteTemp(rowData) {
    return (
      <span>
        {rowData.liveInstitute} <br /> {rowData.practiceInstitute}{" "}
      </span>
    );
  }

  liveActiveInstituteTemp(rowData) {
    return (
      <span style={{ color: "green" }}>
        {rowData.liveActive} <br />{" "}
        <span style={{ color: "red" }}> {rowData.liveInactive} </span>{" "}
      </span>
    );
  }

  studentDisabledTemp(rowData) {
    return (
      <span>
        {rowData.disableStudent} <br /> {rowData.billableStudent}{" "}
      </span>
    );
  }

  PayableAmountTemp = rowData => {
    return (
      <div className="col-number text-right">
        {" "}
        {this.ValidatorUtility.currencyFormatter(rowData.BGTotal)}
      </div>
    );
  };
  DiscountAmountTemp = rowData => {
    return (
      <div className="col-number text-right">
        {" "}
        {this.ValidatorUtility.currencyFormatter(rowData.BDTotal)}
      </div>
    );
  };
  PaidAmountTemp = rowData => {
    return (
      <div className="col-number text-right">
        {" "}
        {this.ValidatorUtility.currencyFormatter(rowData.BPTotal)}
      </div>
    );
  };
  DueAmountTemp = rowData => {
    return (
      <div className="col-number text-right">
        {" "}
        {this.ValidatorUtility.currencyFormatter(rowData.due)}
      </div>
    );
  };

  render() {

    // pdf reletaed task
    subTitleColumn = [
      { dataKey: "A" },
      { dataKey: "B" },
      { dataKey: "C" },
      { dataKey: "D" }
    ];
    subTitleList = [
      { A: "Year", B: this.state.yearValue, C: "Statement Type", D: "Yearly" }
    ];
    // pdf reletaed task end

    let excelColumns = "";
    let excelSheetName = "";

    excelColumns = "";
    excelSheetName = this.state.yearValue + " Em Bill Statement";
    excelColumns = [
      <Workbook.Column value="month" label="Month" />,
      <Workbook.Column value="liveInstitute" label="Institute [Live]" />,
      <Workbook.Column value="practiceInstitute" label="Institute [Demo]" />,
      <Workbook.Column value="liveActive" label="Live Institute [Active]" />,
      <Workbook.Column value="liveInactive" label="Live Institute [Inactive]" />,
      <Workbook.Column value="disableStudent" label="Student [Disable]" />,
      <Workbook.Column value="billableStudent" label="Student [Billable]" />,
      <Workbook.Column value="BGTotal" label="Payable Amount" />,
      <Workbook.Column value="BDTotal" label="Discount Amount" />,
      <Workbook.Column value="BPTotal" label="Paid Amount" />,
      <Workbook.Column value="due" label="Due Amount" />,
    ];

    let {
      topProgressBar,
      errorMsgVisible,
      errorMsgBody,
      dataTableValue
    } = this.state;

    let tableHeader = <div className="header-title">
      <div className="header-title-left">Institute List</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
    </div>;

    let footer = (
      <ColumnGroup>
        <Row>
          <Column footer="" />
          <Column footer="" />
          <Column footer="" />
          <Column footer="Total:" className="nw-tfoot-total-text" />
          <Column
            footer={this.ValidatorUtility.currencyFormatter(totalPayableAmount)}
          />
          <Column
            footer={this.ValidatorUtility.currencyFormatter(
              totalDiscountAmount
            )}
          />
          <Column
            footer={this.ValidatorUtility.currencyFormatter(totalPaidAmount)}
          />
          <Column
            footer={this.ValidatorUtility.currencyFormatter(totalDueAmount)}
          />
        </Row>
      </ColumnGroup>
    );

    let yearSelection = [];

    const currentFullDate = new Date();
    const currentYear = currentFullDate.getFullYear('yyyy');
    ///year generator
    for (let index = 2013; index <= currentYear; index++) {
      yearSelection.unshift({ label: `${index}`, value: `${index}` });
  }

    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <div className='main-section'>

          <div className="nw-form">
            <div className="p-col-12 p-xl-12">
              <div className="p-grid nw-form-body">
                <div className="p-col-12 p-xl-9 p-lg-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Year <span>*</span>
                    </span>
                    <Dropdown
                      placeholder="Select Year"
                      name="year"
                      value={this.state.yearValue}
                      options={yearSelection}
                      autoWidth={false}
                      onChange={e => this.onChangeYearValue(e)}
                    />
                  </div>
                  <span className="error-message">
                    {this.state.errors["year"]}
                  </span>
                </div>
                <div className="p-col-12 p-xl-3 p-lg-12 nw-button-parent">
                  <Button
                    className="p-button p-button-primary nw-button nw-button-center"
                    label="Search"
                    icon="fas fa-search"
                    onClick={this.onSubmitGetYearlyBalance.bind(this)}
                  />
                </div>
              </div>
            </div>

            <div className="p-col-12 p-xl-12 nw-data-table">

              {this.state.dataTableIsLoading ? (
                this.NetiContentLoader.MyPointTableLoader()
              ) : (
                  <div>
                    <DataTable
                      header={tableHeader}
                      footerColumnGroup={footer}
                      responsive={true}
                      selectionMode="single"
                      value={this.state.dataTableValue}
                    >
                      <Column
                        field="month"
                        header="Month"
                        filter={true}
                        style={{ width: "110px" }}
                      />
                      <Column
                        field=""
                        header="Institute [Live/Demo]"
                        body={this.liveInstituteTemp.bind(this)}
                        style={{ width: "90px" }}
                      />
                      <Column
                        field=""
                        header="Live Institute [Active/Inactive] "
                        body={this.liveActiveInstituteTemp.bind(this)}
                        style={{ width: "120px" }}
                      />
                      <Column
                        field=""
                        header="Student [Disable/Billable] "
                        body={this.studentDisabledTemp.bind(this)}
                        style={{ width: "120px" }}
                      />
                      <Column
                        field="BGTotal"
                        header="Payable Amount"
                        body={this.PayableAmountTemp}
                      />
                      <Column
                        field="BDTotal"
                        header="Discount Amount"
                        body={this.DiscountAmountTemp}
                      />
                      <Column
                        field="BPTotal"
                        header="Paid Amount"
                        body={this.PaidAmountTemp}
                      />
                      <Column
                        field="due"
                        header="Due Amount"
                        body={this.DueAmountTemp}
                      />
                    </DataTable>

                    <div className="nw-button-parent m-t-20">

                      <SplitButton className="p-button-primary nw-split-button " label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                      <div style={{ display: 'none' }}> <Workbook filename={"Eduman Bill Yearly Statement.xlsx"}
                        element={
                          <Button
                            id="clickExcelBtn"
                            label="Download Excel"
                            icon="fas fa-download"
                            className="p-button p-button-primary nw-button nw-button-right"
                            onClick={this.exportExcelLog}
                          />
                        }
                      >
                        <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>{excelColumns}</Workbook.Sheet>
                      </Workbook>
                      </div>

                    </div >
                  </div>
                )}
            </div >

          </div >
        </div >
      </div>
    );
  }
}
