import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from "primereact/growl";
import { RadioButton } from 'primereact/radiobutton';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import Formatter from '../../../utils/Formatter';


export class ProductOfferUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productOfferUpdateObj: { ...props.rowData },
            updateOfferQuantity: '',
            updateDiscountPercentage: '',
            updateUsableTime: '',
            updateStartDate: '',
            updateExpireDate: '',
            updateNote: '',
            offerStatus: true,
            errors: {}
        }

        this.onChangeUpdateStartDate = this.onChangeUpdateStartDate.bind(this);
        this.onChangeUpdateExpireDate = this.onChangeUpdateExpireDate.bind(this);
        this.onChangeUpdateNote = this.onChangeUpdateNote.bind(this);
        this.onUpdateOfferProduct = this.onUpdateOfferProduct.bind(this);
        this.adminPointService = new AdminPointService();
        this.formatter = new Formatter();

    }

    shouldComponentUpdate() {
        return true
    }

    onChangeUpdateOfferQuantity = e => {
        let { productOfferUpdateObj } = this.state
        this.state.errors["offerQuantity"] = "";
        productOfferUpdateObj.offerQuantity = e.target.value
        this.setState({ productOfferUpdateObj, errors: this.state.errors });
    };

    onChangeUpdateDiscountPercentage = e => {
        let { productOfferUpdateObj } = this.state
        this.state.errors["discountPercentage"] = "";
        let fixedValue = this.formatter.getFixedDecimalNumber(e.target.value)
        productOfferUpdateObj.discountPercent = fixedValue
        this.setState({ productOfferUpdateObj, errors: this.state.errors });
    };

    onChangeUpdateUsableTime = e => {
        let { productOfferUpdateObj } = this.state
        this.state.errors["usableTime"] = "";
        productOfferUpdateObj.offerUseableTime = e.target.value
        this.setState({ productOfferUpdateObj, errors: this.state.errors });
    }

    onChangeUpdateStartDate = e => {
        let { productOfferUpdateObj } = this.state
        this.state.errors["startDate"] = "";
        productOfferUpdateObj.offerStartDate = e.target.value

        this.setState({ productOfferUpdateObj, errors: this.state.errors });
        this.setState({ updateStartDate: e.target.value })
    }

    onChangeUpdateExpireDate = e => {
        let { productOfferUpdateObj } = this.state
        this.state.errors["expireDate"] = "";
        productOfferUpdateObj.offerExpiryDate = e.target.value
        this.setState({ productOfferUpdateObj, errors: this.state.errors });
        this.setState({ updateExpireDate: e.target.value })
    }

    onChangeUpdateNote = e => {
        let { productOfferUpdateObj } = this.state
        this.state.errors["note"] = "";
        productOfferUpdateObj.offerNote = e.target.value
        this.setState({ productOfferUpdateObj, errors: this.state.errors });
    }

    onChangeOfferStatus = e => {
        let { productOfferUpdateObj } = this.state
        productOfferUpdateObj.offerEnableStatus = e.target.value
        this.setState({ productOfferUpdateObj });


    }



    onUpdateOfferProduct() {
        let productOfferUpdateObj = { ...this.state.productOfferUpdateObj }

        if (!this.state.updateStartDate && productOfferUpdateObj.offerStartDate) {
            productOfferUpdateObj.offerStartDate = Date.parse(NetiDateUtils.reverseToYYYYMMDD(productOfferUpdateObj.offerStartDate))
        }

        if (!this.state.updateExpireDate && productOfferUpdateObj.offerExpiryDate) {
            productOfferUpdateObj.offerExpiryDate = Date.parse(NetiDateUtils.reverseToYYYYMMDD(productOfferUpdateObj.offerExpiryDate))
        }

        if (this.handleUpdateError()) {
            this.adminPointService.updateOfferProduct(productOfferUpdateObj)
                .then(res => {
                    if (res.status === 202) {
                        this.growl.show({ severity: "success", summary: "Success Message", detail: "Successfully Updated", });

                        setTimeout(() => {
                            this.props.viewDialog();
                        }, 600);

                        this.props.productOffer();


                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error =>
                    this.setState({ errorMsgVisible: true, errorMsgBody: 'Please check your connection' })

                );
        }
    }

    updateDialogDiscard = () => {
        this.props.viewDialog();
    }

    handleUpdateError = () => {

        let { errors, productOfferUpdateObj } = this.state;
        let formIsValid = true;

        if (productOfferUpdateObj.offerQuantity === '') {
            formIsValid = false;
            errors["offerQuantity"] = "Offer quantity can't left empty.";
        }
        if (productOfferUpdateObj.discountPercent === '') {
            formIsValid = false;
            errors["discountPercentage"] = "Discount price can't left empty.";
        }

        if (productOfferUpdateObj.offerUseableTime === '') {
            formIsValid = false;
            errors["usableTime"] = "Usable time can't left empty.";
        }
        if (!productOfferUpdateObj.offerStartDate) {
            formIsValid = false;
            errors["startDate"] = "Start date can't left empty.";
        }
        if (!productOfferUpdateObj.offerExpiryDate) {
            formIsValid = false;
            errors["expireDate"] = "Expire date can't left empty.";
        }
        else if (!productOfferUpdateObj.offerExpiryDate) {
            formIsValid = false;
            errors["expireDate"] = "Expire date can't left empty.";
        }

        if (productOfferUpdateObj.offerNote === '') {
            formIsValid = false;
            errors["note"] = "Note can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;

    }


    render() {

        let { productOfferUpdateObj } = this.state

        return (

            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <div className="nw-form">
                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="p-grid nw-form-body">
                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Product Type  <span></span></span>
                                                <div className="nw-inputgroup-desc">
                                                    {productOfferUpdateObj.productRoleAssignDTO.productInfoDTO.productTypeInfoDTO.categoryName || ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Product Name</span>
                                                <div className="nw-inputgroup-desc">
                                                    {productOfferUpdateObj.productRoleAssignDTO.productInfoDTO.productName || ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Offer Code</span>
                                                <div className="nw-inputgroup-desc">
                                                    {productOfferUpdateObj.offerCode || ''}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">User Role</span>
                                                <div className="nw-inputgroup-desc">
                                                    {productOfferUpdateObj.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote || ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Actual Price</span>
                                                <div className="nw-inputgroup-desc">
                                                    {productOfferUpdateObj.actualPrice}
                                                </div>
                                            </div>
                                            <span className='error-message'>{this.state.errors["actualPrice"]}</span>
                                        </div>

                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Offer Quantity <span>*</span></span>
                                                <InputText
                                                    placeholder='Enter Offer Quantity'
                                                    name="offerQuantity"
                                                    value={productOfferUpdateObj.offerQuantity}
                                                    onChange={this.onChangeUpdateOfferQuantity}
                                                    keyfilter='pint'
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors["offerQuantity"]}</span>
                                        </div>

                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Discount Percentage <span>*</span></span>
                                                <InputText
                                                    placeholder='Enter Discount Percentage'
                                                    name="discountPercentage"
                                                    value={productOfferUpdateObj.discountPercent}
                                                    onChange={this.onChangeUpdateDiscountPercentage}
                                                    keyfilter="num"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors["discountPercentage"]}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Usable Time <span>*</span></span>
                                                <InputText
                                                    placeholder='Enter Usable Time'
                                                    name="usableTime"
                                                    value={productOfferUpdateObj.offerUseableTime}
                                                    onChange={this.onChangeUpdateUsableTime}
                                                    keyfilter="pint"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors["usableTime"]}</span>
                                        </div>

                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                                <Calendar
                                                    showIcon={true}
                                                    placeholder={productOfferUpdateObj.offerStartDate}
                                                    name="startDate"
                                                    value={this.state.updateStartDate}
                                                    onChange={this.onChangeUpdateStartDate}
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.errors["startDate"]}</span>
                                        </div>

                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Expire Date <span>*</span></span>
                                                <Calendar
                                                    showIcon={true}
                                                    placeholder={productOfferUpdateObj.offerExpiryDate}
                                                    name="expireDate"
                                                    value={this.state.updateExpireDate}
                                                    onChange={this.onChangeUpdateExpireDate}
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                />
                                                <span className='error-message'>{this.state.errors["expireDate"]}</span>
                                            </div>
                                        </div>

                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                                <InputTextarea
                                                    placeholder='Enter Note'
                                                    name="note"
                                                    value={productOfferUpdateObj.offerNote}
                                                    onChange={this.onChangeUpdateNote}
                                                />
                                                <span className='error-message'>{this.state.errors["note"]}</span>
                                            </div>
                                        </div>

                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <div className="input-radio-button">
                                                    <span className="p-inputgroup-addon addon-no-style">Offer Status <span>*</span></span>
                                                    <div className="radio-button-inside">
                                                        <RadioButton
                                                            value={1}
                                                            inputId="rb1"
                                                            checked={productOfferUpdateObj.offerEnableStatus === 1}
                                                            onChange={this.onChangeOfferStatus}
                                                        />
                                                        <label htmlFor="rb1" className="p-radiobutton-label">Yes</label>
                                                    </div>
                                                    <div className="radio-button-inside">
                                                        <RadioButton
                                                            value={0}
                                                            inputId="rb2"
                                                            checked={productOfferUpdateObj.offerEnableStatus === 0}
                                                            onChange={this.onChangeOfferStatus}
                                                        />
                                                        <label htmlFor="rb2" className="p-radiobutton-label">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            label="Discard"
                                            icon="fas fa-times"
                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                            onClick={this.updateDialogDiscard}
                                        />
                                        <Button
                                            label="Update"
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            icon="fas fa-check"
                                            onClick={this.onUpdateOfferProduct}
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="p-col-12 p-lg-1 p-xl-1" />
                </div>
            </div>

        )
    }
}
