import React, { Component } from 'react';

export class PortalProfileInfo extends Component {

    render() {
        // console.log("student id....", this.props.studentId)
        let basicInfo = this.props.basicInfo;
        let staticImg = 'assets/layout/images/userAvatar.png';
        let stdName, stdCustomId, stdImage, instituteID;

        if (basicInfo && Object.keys(basicInfo).length > 0) {
            console.log('Log: PortalProfileInfo -> render -> basicInfo1')
            stdName = basicInfo.studentName;
            stdCustomId = basicInfo.customStudentId;
            instituteID = basicInfo.instituteId;
            stdImage = basicInfo.studentImageForView ? "data:image/jpeg;base64," + basicInfo.studentImageForView : '';
        }


        return (
            <div className="portal-dashboard-profile  portal-dashboard p-0">
                <div className="layout-dashboard">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            <div className="portal-profile-info-wrapper">
                                <div className="portal-profile-image">
                                    <img
                                        src={stdImage || staticImg}
                                        alt="Institute Image"
                                    />
                                </div>
                                <div className="portal-profile-info">
                                    <h1>{stdName || ''}</h1>
                                    <h3>{stdCustomId || ''}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
} 