import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import edumanAD from '../../../assets/images/mainDashboardIcon/eduman-ad.png'
import { UserOtherAccessService } from '../../../service/UserOtherAccessService';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';

let profile={}
export class MainDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: [
                {},{},{},{},{},{},{},{},
            ],
            myPointDashboardDetails: {
                userBasicInfo: {},
                basicInfo: {},
                points: [],
                tokenSummary:{}

            },
            userProfileImage: ''
        }

        this.userOtherAccessService = new UserOtherAccessService();
        this.validatorUtility = new ValidatorUtility();
        this.reloadUserProfileFromLocal();
	}

	reloadUserProfileFromLocal() {
		profile = JSON.parse(localStorage.getItem('Profile'));
		if (profile) {
			this.setState({ userProfileImage: profile.imageContent, userFullName: profile.fullName });
		} else {
			setTimeout(() => {
				this.componentWillMount();
			}, 1000)
		}
	}

	componentWillMount() {
		profile = JSON.parse(localStorage.getItem('Profile'));
		if (profile) {
			this.setState({ userProfileImage: profile.imageContent, userFullName: profile.fullName });
        }
        
        console.log("profile", profile);
        
	}

	componentDidMount() {
		this.getHomeDashboardInfoDetails();
	}

	getHomeDashboardInfoDetails() {
		this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' });
		this.userOtherAccessService.getMainDashboardInfo()
			.then(res => {
				if (res.status === 302) {
					return res.json().then((body) => {
						this.setState({ myPointDashboardDetails: body })
						this.setState({ topProgressBar: false, errorMsgVisible: false, errorMsgBody: '' });

					});
				}
				else {
					this.userOtherAccessService.Auth.handleErrorStatus(res)
						.then((resp) => {
							this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
						});
				}
			}).catch((error) => {
				this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
			});

	}


    // getMyPointDashboardInfoDetails() {
    //     this.setState({ topProgressBar: true, errorMsgVisible: false });

    //     this.userOtherAccessService.getHomeDashboardInfoList()
    //         .then(res => {
    //             if (res.status === 302) {
    //                 return res.json().then((body) => {
    //                     // purchaseMonthData = Object.entries(body.monthlyPurchases)
    //                     // let columnsHeader = ["Title", "Price"];
    //                     // finalMonthlyPurchaseDataArr.push(columnsHeader, ...purchaseMonthData);
    //                     // totalMonthlyPurchaseAmount = purchaseMonthData.map(item => item[1]).reduce((a, b) => (a + b));
    //                     this.setState({ myPointDashboardDetails: body })
    //                     this.setState({ topProgressBar: false, errorMsgVisible: false });
    //                 });
    //             }
    //             else {
    //                 this.userOtherAccessService.Auth.handleErrorStatus(res)
    //                     .then((resp) => {
    //                         this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
    //                     });
    //             }
    //         }).catch((error) => {
    //             this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
    //         });

    // }

    render() {
        let { myPointDashboardDetails } = this.state
        return (
            <div className="p-fluid">
                <div className="main-section netiworld-main-dashboard">
                    <div className="p-grid header m-0">
                        <div className="p-col-12 p-xl-7">
                            <div className="p-grid p-col-12 user-info">
                                <div className="p-col-12 p-xl-4 img">
                                    { this.state.userProfileImage ? 
                                        <img src={ this.state.userProfileImage }/>
                                        :<img src="assets/layout/images/userAvatar.png"/>
                                    }
                                    {/* <i class="fas fa-shield-alt"></i> */}
                                </div>

                                <div className="p-grid p-col-12 p-xl-8 info">
                                    <div className="p-col-12 name p-0"><h1>{ profile.fullName || '---' }</h1></div>

                                    <div className="p-grid p-col-12 others-info">
                                        <div className="p-col-12 p-xl-4 p-md-4 p-0">
                                            <span className="font-10">Neti ID</span>
                                            <br />
                                            <span className="font-12">{ myPointDashboardDetails.userBasicInfo.customNetiID || '---' }</span>
                                    </div>
                                        <div className="p-col-12 p-xl-4 p-md-4 p-0">
                                            <span className="font-10">Mobile No.</span>
                                            <br />
                                            <span className="font-12">{ myPointDashboardDetails.userBasicInfo.basicMobile || '---' }</span>
                                    </div>
                                        <div className="p-col-12 p-xl-4 p-md-4 p-0">
                                            <span className="font-10">Email</span>
                                            <br />
                                            <span className="font-12">{ myPointDashboardDetails.userBasicInfo.basicEmail || '---' }</span>
                                        </div>
                                    </div>

                                    {/* <div className="p-col-12 p-xl-4 p-md-4 progress-wrapper p-0">
                                        <div className="progress">
                                            <p>Profile Details</p>
                                        </div>
                                    </div> */}

                                    <div className="p-col-12 progress-wrapper p-0">
                                        <div className="progress">
                                            <p>Profile Details</p>
                                        </div>

                                        <div className="progress">
                                            <ProgressBar value={20} displayValueTemplate={50}></ProgressBar>
                                            <div className="progress-icon">
                                                <Button icon="fas fa-info-circle" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                
                                
                            </div>
                        </div>
                        <div className="p-col-12 p-xl-5">
                            <div className="p-col-12 advertise-info">
                                <img src={edumanAD}/>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="p-grid card-section">
                        {/* {
                            this,this.state.array.map((item, index) =>
                                <div className="p-col-12 p-xl-3 card-section-inside ">
                                    <div className="inside-box">
                                        <div className="inside-box-icon wallet-icon"></div>
                                        <h1 className="font-16 color-white">Wallet Balance</h1>
                                        <hr />
                                        <div className="inside-currency">
                                            <p className="font-20 color-white tk-sign">1,00,00,000.00</p>
                                        </div>
                                        <div className="animated-circle">
                                            <div className="circle-first"></div>
                                            <div className="circle-second"></div>
                                            <div className="circle-third"></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        } */}
                        <div className="p-col-12 p-xl-3 card-section-inside wallet">
                            <div className="inside-box">
                                <div className="inside-box-icon wallet-icon"></div>
                                <h1 className="color-white">Wallet Balance</h1>
                                <hr />
                                <div className="inside-currency">
                                    <p className="color-white tk-sign">{ this.validatorUtility.currencyFormatter(myPointDashboardDetails.userBasicInfo.userWalletBalance) || 0 }</p>
                                </div>
                                <div className="animated-circle">
                                    <div className="circle-first"></div>
                                    <div className="circle-second"></div>
                                    <div className="circle-third"></div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-3 card-section-inside msg">
                            <div className="inside-box">
                                <div className="inside-box-icon msg-icon"></div>
                                <h1 className="font-16 color-white">Message Balance</h1>
                                <hr />
                                <div className="inside-currency">
                                    <p className="font-20 color-white arraw-sign"><i class="fas fa-arrow-right"></i>{ this.validatorUtility.currencyFormatter(myPointDashboardDetails.userBasicInfo.userWalletBalance) || 0 }</p>
                                </div>
                                <div className="animated-circle">
                                    <div className="circle-first"></div>
                                    <div className="circle-second"></div>
                                    <div className="circle-third"></div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-3 card-section-inside email">
                            <div className="inside-box">
                                <div className="inside-box-icon email-icon"></div>
                                <h1 className="font-16 color-white">E-mail Balance</h1>
                                <hr />
                                <div className="inside-currency">
                                    <p className="font-20 color-white arraw-sign"><i class="fas fa-arrow-right"></i>{ myPointDashboardDetails.userBasicInfo.emailBalance }</p>
                                </div>
                                <div className="animated-circle">
                                    <div className="circle-first"></div>
                                    <div className="circle-second"></div>
                                    <div className="circle-third"></div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-3 card-section-inside assign-point">
                            <div className="inside-box">
                                <div className="inside-box-icon assign-point-icon"></div>
                                <h1 className="font-16 color-white">Assigned Point</h1>
                                <hr />
                                <div className="inside-currency">
                                    <p className="font-20 color-white arraw-sign"><i class="fas fa-arrow-right"></i>{ myPointDashboardDetails.assignedPoints }</p>
                                </div>
                                <div className="animated-circle">
                                    <div className="circle-first"></div>
                                    <div className="circle-second"></div>
                                    <div className="circle-third"></div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-3 card-section-inside unsolved-token">
                            <div className="inside-box">
                                <div className="inside-box-icon unsolved-token-icon"></div>
                                <h1 className="font-16 color-white">Unsolved Token</h1>
                                <hr />
                                <div className="inside-currency">
                                    <p className="font-20 color-white arraw-sign"><i class="fas fa-arrow-right"></i>{ myPointDashboardDetails.unsolvedToken || 0 }</p>
                                </div>
                                <div className="animated-circle">
                                    <div className="circle-first"></div>
                                    <div className="circle-second"></div>
                                    <div className="circle-third"></div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-3 card-section-inside unrated-token">
                            <div className="inside-box">
                                <div className="inside-box-icon unrated-token-icon"></div>
                                <h1 className="font-16 color-white">Non Rating Token</h1>
                                <hr />
                                <div className="inside-currency">
                                    <p className="font-20 color-white arraw-sign"><i class="fas fa-arrow-right"></i>{ myPointDashboardDetails.notRatedToken || 0 }</p>
                                </div>
                                <div className="animated-circle">
                                    <div className="circle-first"></div>
                                    <div className="circle-second"></div>
                                    <div className="circle-third"></div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-3 card-section-inside unread-email">
                            <div className="inside-box">
                                <div className="inside-box-icon unread-email-icon"></div>
                                <h1 className="font-16 color-white">Unread Neti Mail</h1>
                                <hr />
                                <div className="inside-currency">
                                    <p className="font-20 color-white arraw-sign"><i class="fas fa-arrow-right"></i>{ myPointDashboardDetails.unReadMail || 0 }</p>
                                </div>
                                <div className="animated-circle">
                                    <div className="circle-first"></div>
                                    <div className="circle-second"></div>
                                    <div className="circle-third"></div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-3 card-section-inside unread-notice">
                            <div className="inside-box">
                                <div className="inside-box-icon unread-notice-icon"></div>
                                <h1 className="font-16 color-white">Unread Notice</h1>
                                <hr />
                                <div className="inside-currency">
                                    <p className="font-20 color-white arraw-sign"><i class="fas fa-arrow-right"></i>n/a</p>
                                </div>
                                <div className="animated-circle">
                                    <div className="circle-first"></div>
                                    <div className="circle-second"></div>
                                    <div className="circle-third"></div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="p-col-12 p-xl-3 card-section-inside">main dashboard</div>
                        <div className="p-col-12 p-xl-3 card-section-inside">main dashboard</div>
                        <div className="p-col-12 p-xl-3 card-section-inside">main dashboard</div>
                        <div className="p-col-12 p-xl-3 card-section-inside">main dashboard</div> */}
                    </div>
                </div>
            </div>
        )
    }
}