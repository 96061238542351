import { BaseURIHolder } from "../../utils/BaseURIHolder";
import AuthService from "../../auth/AuthService";

export class MessageRechargeService {
  constructor() {
    this.baseURIHolder = new BaseURIHolder();
    this.Auth = new AuthService();
  }
  getPurchasePoint() {
    let uri = this.baseURIHolder.getUser() + "/roles/assigned";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
  getMessageType(roleId) {
    let uri =
      this.baseURIHolder.getUser() + "/message/types/by/point?roleID=" + roleId;
    console.log(uri);
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
  getMessageTypeWithoutRole() {
    let uri = this.baseURIHolder.getUser() + "/message/types/by/roles";
    console.log(uri);
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
  userBalanceTransfer(balanceTransfer) {
    let uri = this.baseURIHolder.getUser() + "/balance/transfer";
    return this.Auth.postFetch(uri, balanceTransfer).catch((error) =>
      console.log("error", error)
    );
  }

  sendMessageRecharge(messageRecharge) {
    let uri = this.baseURIHolder.getUser() + "/message/recharge";
    return this.Auth.postFetch(uri, messageRecharge).catch((error) =>
      console.log("error", error)
    );
  }

  getUserMessageRechargesListByDate(rechargeRecords) {
    let uri = this.baseURIHolder.getUser() + "/message/by/date-range";
    return this.Auth.postFetch(uri, rechargeRecords).catch((error) =>
      console.log("error", error)
    );
  }

  getUserTopTenMessageRechargesList(transactionType) {
    let uri =
      this.baseURIHolder.getUser() +
      "/message/top10/by?trxType=" +
      transactionType;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
}
