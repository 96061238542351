import React, { Component } from 'react';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Growl} from 'primereact/growl';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { WalletBalance } from "../myPoint/common/WalletBalance";
import { AdmisiaPointService } from '../../service/admisiaPoint/AdmisiaPointService';
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';
import {NetiDateUtils} from '../../utils/NetiDateUtils';
import { Link } from 'react-router-dom';

let applicantContacts;
let admissionStatusList =[
    {label:"Pending for Assessment", value: 0, name: 'Assessment'},
    {label:"Approved for Assessment", value: 1},
    {label:"Rejected for Assessment", value: 2},
    {label:"Pending for Admission", value: 1},
    {label:"Approved for Admission", value: 5},
    {label:"Rejected for Admission", value: 3},
    {label:"Waiting for Admission", value: 4},
    {label:"Transfered for Admission", value: 10}
]

let errors = {};
let urlId;
export class SendSmsApplicant extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            rejectedStutas: "Custom Status",
            dataTableSelection: '',
            homeReturnButton: true,
            classAndGroupList: '',
            classAndGroupListValue: '',
            firstDropdownIsLoading: false,
            dataTableValue: [],
            visible: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible:false,
            errorMsgBody: null,
            errors:{},
            mobileNumber: [],

            messageSendInfo: {
                receiverContacts: [

                ],
                messageBody: ""
            }
            
        };

        this.viewDialog = this.viewDialog.bind(this)
        this.reloadCoreUrlIdFromLocal = this.reloadCoreUrlIdFromLocal.bind(this);
        this.admisiaPointService=new AdmisiaPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();


    }



    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 1000);
        }
    }



    componentWillMount() {
        urlId=this.admisiaPointService.getUrlInfoFromLocalStorage();
        if( urlId ){
            this.setState({ homeReturnButton: false })
            console.log("urlId will mount",typeof(urlId));
            this.getFetchClassAndGroupList(urlId);
        }
        else{
            this.setState({ homeReturnButton: true })
            // this.setState({errorMsgVisible: true, errorMsgBody: "Please reload page again..."})
        }
    }







    getFetchClassAndGroupList(urlId){
        this.setState({ dataTableIsLoading: true, topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        let urlID = parseInt(urlId);
        this.admisiaPointService.getClassAndGroupForApproval(urlID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if(body.length !== 0) {
                            this.setState({ classAndGroupList: body});
                            errors['classAndGroupListError'] = ""
                        } else {
                            errors['classAndGroupListError'] = "Desire list is empty"
                        }
                 
                        this.setState({ dataTableIsLoading: false, firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        
                    });
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        this.setState({ dataTableIsLoading: false, firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                    });
                }
            }).catch(error => {
                this.setState({dataTableIsLoading: false, firstDropdownIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }





    onChangeClassAndGroupList(e) {
        errors['classAndGroupListError']=''

        let assessmentRequired, autoApproveStatus= null
        
        if(e.target.value.admissionTestStatus === 0){
            assessmentRequired = "No"
        }
        else if(e.target.value.admissionTestStatus === 1){
            assessmentRequired = "Yes"
        }

        if(e.target.value.autoApproveStatus === 0){
            autoApproveStatus = "Manual Approve"
        }
        else if(e.target.value.autoApproveStatus === 1){
            autoApproveStatus = "Auto Approve"
        }

        this.setState({ 
            classAndGroupListValue: e.target.value,
            classAndGroupLabel: e.target.value.className + " (" + e.target.value.group +")",
            assessmentRequired: assessmentRequired,
            admissionBy: autoApproveStatus,
            applicantLimit: e.target.value.applicantLimit,
        })

    }

    onChangeStatusList(e) {

        this.setState({ statusListValue: e.target.value})
        this.setState({ statusListLabel: e.originalEvent.nativeEvent.target.innerText})
    }


    viewDialog(){
        this.setState({ 
            visible: true,
         })
    }

    onHide() {
		this.setState({ visible: false })
    }

    dateFormatTemplet = (rowData) =>{
        return <span>{NetiDateUtils.getAnyShortForm(rowData['applicationDate'], 'DD-MMM-YYYY')}</span>;
    }

    handleError(){
        let {statusListValue, statusListLabel} = this.state
        let formIsValid = true;
        
        
        this.setState({ errors });
        return formIsValid;
    }

    onSubmitHandler(){

        if(this.state.classAndGroupListValue){

            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            let {statusListValue, statusListLabel} = this.state
            let {componentName} = this.props
            let admisiaStatus, urlName = '';

            // if(componentName === "assessmentPendingApplicants" || componentName === "assessmentExamMark" ){ urlName = 'assessment' }
            // else if(){}
            
            if(statusListValue === 0 || (statusListLabel === 'Approved for Assessment' && statusListValue === 1) || statusListValue === 2 ) { 
                urlName = 'assessment'
            }else{
                urlName = 'admission'
            }
            

            let urlObj={
                "urlInfoID": urlId,
                "classConfigID": this.state.classAndGroupListValue.classConfigID,
                "status": statusListValue,
                "pageNo": 0,
                "limit": 10
            }

            this.admisiaPointService.fetchApprovalList( urlName, urlObj )
                .then(res => {
                    if(res.status == 302) {
                        return res.json().then((body) => {

                            console.log("DataTable", body);

                            for (let i = 0; i < body.length; i++) {
                                if (body[i].admisiaApplicantInfoDTO.applicationDate == null) {
                                    body[i].admisiaApplicantInfoDTO.applicationDate = '';
                                } else {
                                    body[i].admisiaApplicantInfoDTO.applicationDate = NetiDateUtils.getAnyShortForm(body[i].admisiaApplicantInfoDTO.applicationDate, 'DD-MMM-YYYY');
                                }

                            }

                           

                            this.setState({ dataTableValue: body})
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        });
                    }
                    else{
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp)=>{
                            this.setState({dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                        });
                    }
                }).catch((error) => {
                    this.setState({dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                });
        }
        else{
            errors['classAndGroupListError'] = "Field can not left empty"
        }
    }

    onChangeDatatableSelection = (e) => {
        let {messageSendInfo} = this.state;
        console.log("data selection", e.value);

        let applicantSelectionArr = [];
        applicantSelectionArr.push(e.value);    
        console.log("applicantSelectionArr", applicantSelectionArr);


        this.setState({ dataTableSelection: e.value })
        messageSendInfo.receiverContacts = applicantSelectionArr[0].map( item => {
            console.log("item", item.admisiaApplicantInfoDTO.mobileNo);
            return item.admisiaApplicantInfoDTO.mobileNo;
          
        })
        this.setState({messageSendInfo});

        console.log("NUmber", this.state.messageSendInfo.receiverContacts);
       
    }

    onChangeMessageInfo = (e) => {
        let {messageSendInfo} = this.state;
        messageSendInfo.messageBody = e.target.value;
        this.setState({messageSendInfo});
    }


   

    messageSubmitHandler = () => {
        this.setState({ firstDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });


        this.admisiaPointService.sendSmsToAdmisiaApplicant(this.state.messageSendInfo)
            .then(res => {
                if (res.status === 201) {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully Submitted' });
                    this.setState({ visible: false });

                    this.setState({
                        dataTableIsLoading: false, topProgressBar: false,
                    });
                }
                else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }

            }).catch(error => {
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, messageSendInfo } = this.state;        
        console.log("messageSendInfo",messageSendInfo);

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                        Applicant Admission Approved List
                        <div
                            style={{ color: "white", display: "flex", float: "right" }}
                        >
                            Total: { this.state.dataTableValue.length }
                            
                        </div>
                    </div>;

        let classAndGroupListOptions = []
        if (this.state.classAndGroupList && this.state.classAndGroupList.length) {
            classAndGroupListOptions = this.state.classAndGroupList.map((item) => ({
              value: item ,
              label: item.className + " (" + item.group +")"
            }));
        }


        return (
            <div className="layout-dashboard">

                { topProgressBar?
                    <ErrorMessageView 
                        topProgressBar={topProgressBar} 
                    />
                    : null
                }
                { errorMsgVisible ?
                    <ErrorMessageView 
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

               
                <Growl ref={(el) => this.growl = el} />
                 {this.state.homeReturnButton === false ?
                   <div  className="p-grid nw-form">
                    <div className="p-col-12 p-lg-12 p-xl-9 p-col-nogutter seprator">
                        <div className="p-grid seprator-inside">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">
                                
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-12 p-xl-6">
                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                            { this.state.firstDropdownIsLoading ?   
                                                this.NetiContentLoader.normalFormInputField():
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Class & Group <span>*</span></span>
                                                        <Dropdown 
                                                            placeholder="Select Class & Group Name"
                                                            filter={true}
                                                            filterBy="label,value"
                                                            
                                                            options={classAndGroupListOptions}
                                                            value={this.state.classAndGroupListValue}
                                                            onChange={(e) => this.onChangeClassAndGroupList(e)}
                                                            style={{width:"100%"}}
                                                        />
                                                    </div>
                                                }
                                                <span className="error-message">{errors['classAndGroupListError']}</span>
                                        </div>
                                        
                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                                <div className="p-grid">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Assessment Required
                                                            </span>
                                                            <div className="nw-inputgroup-desc">
                                                            { this.state.assessmentRequired }
                                                            </div>
                                                    </div>
                                                </div>

                                                </div>
                                        </div>

                                    </div>
                                    <div className="p-col-12 p-lg-12 p-xl-6">
                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-small-span">Status <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Desire Value"
                                                    options={admissionStatusList} 
                                                    value={this.state.statusListValue}
                                                    onChange={(e) => this.onChangeStatusList(e)}
                                                    tooltip='Test'
                                                    name='status'
                                                    style={{width:"100%"}}
                                                />
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-center"
                                                label="Search"
                                                icon="fas fa-search"
                                                onClick={this.onSubmitHandler.bind(this)}
                                            />
                                        </div>

                                    </div>

                                </div>
                             
                            </div>
                        </div>
                      </div>


                    </div>

                   


                    <div className="p-col-12 p-xl-3">
                            <WalletBalance 
                                messageBalanceName='Message'
                            />
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={this.state.dataTableValue}
                                    // selectionMode="multiple"
                                    // activeIndex="0"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={this.onChangeDatatableSelection}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                >
                                    <Column selectionMode="multiple" style={{width:'4em'}}/>
                                    <Column field="admisiaApplicantInfoDTO.registrationID" header="Registration No"  sortable={true} filter={true} />
                                    <Column field="admisiaApplicantInfoDTO.applicantName" header="Name" sortable={true} filter={true} />
                                    <Column field="admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName" header="Gender" sortable={true} filter={true} />
                                    <Column field="admisiaApplicantInfoDTO.religionCoreCategoryInfoDTO.categoryName" header="Religion" sortable={true} filter={true} />
                                    <Column field="admisiaApplicantInfoDTO.mobileNo" header="Mobile No." sortable={true} filter={true} />
                                    <Column field="admisiaApplicantInfoDTO.applicationDate" header="Application Date"  sortable={true} filter={true} />
                                </DataTable>
                                

                                : this.NetiContentLoader.MyPointTableLoader()}
                        </div>

                        <div className="p-grid">
                
                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                <Button
                                    label="Create Messsage"
                                    icon="fas fa-check"
                                    iconPos="right"
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    onClick={this.viewDialog}
                                    disabled={ !this.state.dataTableSelection.length > 0 ? true : false}
                                />
                            </div>
                        
                        </div>
                        <br/>
                        <br/>

                    <Dialog 
                        header="Create Message" 
                        className="nonHideDialog customDialogWidth text-center nw-dialog" 
                        onHide={this.onHide.bind(this)} 
                        visible={this.state.visible}
                        dismissableMask={true}
                    >

                        <div className="p-fluid">
                            
                            <div className="p-grid p-col-12 p-xl-12">
                                <label>
                                    Total Selected Applicant : 
                                </label>
                                <div className="nw-inputgroup-desc" style={{paddingTop:"5px"}}>
                                    {this.state.dataTableSelection.length}
                                </div>
                            </div>
                                
                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span>Message</span>
                                </div>
                        
                                <div className="p-col-12 p-xl-12">
                                    <InputTextarea
                                        id="textarea"
                                        rows={3}
                                        cols={30}
                                        autoResize={true}
                                        value={this.state.messageSendInfo.messageBody}
                                        name="tokenDetails"
                                        onChange={this.onChangeMessageInfo}
                                        placeholder="Approved for admission"
                                       
                                    />

                                    <span className="pull-left"> Characters 20/300 </span>
                                    <span className="pull-right"> Part: 0/5</span>
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Send Messsage"
                                    icon="fas fa-check"
                                    iconPos="right"
                                    onClick={this.messageSubmitHandler.bind(this)} 
                                />
                            </div>
                     
                        </div>
                    
                    </Dialog>
                        
                </div>
              </div>

                   :

                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/institute_config"
                                        className="rainbow-button"
                                        alt="Go Admisia Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                    }

                
            </div>
        )
    }
}