import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

// custom imports
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { DwsService } from '../../../../service/dwsPoint/DwsService';

import { AdmisiaPointService } from "../../../../service/admisiaPoint/AdmisiaPointService";
import NetiContentLoader from '../../../common/NetiContentLoader';

import { ApplicantPersonalInfo } from './ApplicantPersonalInfo';

let cmsId;

export class ApplicantInfo extends Component {

    constructor() {
        super();
        this.state = {
            applicantPicture: '',
            searchView: true,
            inputFieldLoader: false,
            regNo: '',
            errors: {},
            regError: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',

            applicantDetailsInfo: [],

            DOB: '',

        };

        this.DwsService = new DwsService();
        this.admisiaPointService = new AdmisiaPointService();

        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();

        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    onChangeRegNo = (e) => {
        let { regNo, regError } = this.state;
        regError['registrationNo'] = '';
        regNo = e.target.value;
        this.setState({ regNo, regError });
    }

    onSearchHandler = () => {

        this.setState({ searchView: true, topProgressBar: true, errorMsgVisible: false });

        if (this.searchRegError()) {

            this.setState({ inputFieldLoader: true });
            this.DwsService.getAdmisiaApplicantInfoByRegId(cmsId, parseInt(this.state.regNo))

                .then(res => {
                    return res.json();
                }).then(body => {

                    console.log('res-body', body);

                    if (body.messageType === 1) {
                        console.log('list-res-body', body);
                        this.setState({ applicantDetailsInfo: body.item, inputFieldLoader: false });
                    } else {

                        this.setState({ applicantDetailsInfo: [] })
                        this.setState({ inputFieldLoader: false, errorMsgVisible: true, errorMsgBody: body.message });
                    }

                })
                .catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, inputFieldLoader: false, errorMsgBody: "Please check your connection." })
                });

        }

    }

    searchRegError = () => {

        let { regError } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (this.state.regNo === '') {
            formIsValid = false;
            regError['registrationNo'] = "Registration no. can't left empty.";
        }

        this.setState({ regError });
        return formIsValid;
    }

    render() {

        console.log("Final Picture", this.state.applicantPicture);

        let { applicantDetailsInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        const pointType = [
            { label: 'DWS', value: 'DWS' },
            { label: 'Admisia', value: 'Admisia' },
            { label: 'Bijoy', value: 'Bijoy' }
        ]

        return (
            <div className="p-fluid">
                <div className="p-col-12 p-xl-12">
                    <div className="p-grid nw-form">
                        {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }

                        <div className="p-col-12 p-xl-12">
                            <Growl ref={(el) => this.growl = el} />

                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-9">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Registration No. <span>*</span></span>
                                        <InputText
                                            value={this.state.regNo}
                                            onChange={this.onChangeRegNo}
                                            placeholder="Enter Registration No."
                                            style={{ width: "100%" }}
                                            type="text"
                                            keyfilter="pint"
                                            name="registrationNo"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.regError["registrationNo"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-3 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        onClick={this.onSearchHandler}
                                        icon="fas fa-search"
                                    />
                                </div>
                            </div>
                        </div>

                        <ApplicantPersonalInfo applicantDetailsInfo={applicantDetailsInfo} inputFieldLoader={this.state.inputFieldLoader} />

                    </div>

                </div>

            </div>
        );
    }
}