import React, { Component } from 'react';
import { CarService } from '../../service/CarService';
import { Button } from 'primereact/components/button/Button';
import AuthService from '../../auth/AuthService';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { FullCalendar } from 'primereact/fullcalendar';
import { Chart } from "react-google-charts";
import { UserOtherAccessService } from '../../service/UserOtherAccessService';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { ErrorMessageView } from '../../components/common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { primaryChartsColorArray, dangerChartsColorArray } from '../../components/common/ChartsColorArray';


let purchaseMonthData = [];
let finalChartData = [];
let finalMonthlyPurchaseDataArr = [];
let monthlyPurchaseArr = [];
let totalMonthlyPurchase = [];
let totalMonthlyPurchaseAmount = 0;


export class MyPointDashboard extends Component {

    constructor() {
        super();
        this.state = {
            myPointDashboardDetails: {},
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            tasks: [],
            city: null,
            selectedCar: null,
            activeIndex: 0,
            fullcalendarOptions: {
                defaultDate: '2017-02-01',
                header: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'month,agendaWeek,agendaDay'
                }
            },
            events: [
                {
                    "id": 1,
                    "title": "All Day Event",
                    "start": "2017-02-01"
                },
                {
                    "id": 2,
                    "title": "Long Event",
                    "start": "2017-02-07",
                    "end": "2017-02-10"
                },
                {
                    "id": 3,
                    "title": "Repeating Event",
                    "start": "2017-02-09T16:00:00"
                },
                {
                    "id": 4,
                    "title": "Repeating Event",
                    "start": "2017-02-16T16:00:00"
                },
                {
                    "id": 5,
                    "title": "Conference",
                    "start": "2017-02-11",
                    "end": "2017-02-13"
                },
                {
                    "id": 6,
                    "title": "Meeting",
                    "start": "2017-02-12T10:30:00",
                    "end": "2017-02-12T12:30:00"
                },
                {
                    "id": 7,
                    "title": "Lunch",
                    "start": "2017-02-12T12:00:00"
                },
                {
                    "id": 8,
                    "title": "Meeting",
                    "start": "2017-02-12T14:30:00"
                },
                {
                    "id": 9,
                    "title": "Happy Hour",
                    "start": "2017-02-12T17:30:00"
                },
                {
                    "id": 10,
                    "title": "Dinner",
                    "start": "2017-02-12T20:00:00"
                },
                {
                    "id": 11,
                    "title": "Birthday Party",
                    "start": "2017-02-13T07:00:00"
                },
                {
                    "id": 12,
                    "title": "Click for Google",
                    "url": "http://google.com/",
                    "start": "2017-02-28"
                }
            ],
            chartData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                    label: 'Sales',
                    data: [12, 19, 3, 5, 2, 3, 9],
                    borderColor: [
                        '#7E57C2',
                    ],
                    borderWidth: 3,
                    borderDash: [5, 5],
                    fill: false,
                    pointRadius: 3
                }, {
                    label: 'Income',
                    data: [1, 2, 5, 3, 12, 7, 15],
                    backgroundColor: [
                        'rgba(187,222,251,0.2)',
                    ],
                    borderColor: [
                        '#42A5F5',
                    ],
                    borderWidth: 3,
                    fill: true
                },
                {
                    label: 'Expenses',
                    data: [7, 12, 15, 5, 3, 13, 21],
                    borderColor: [
                        '#FFB300',
                    ],
                    borderWidth: 3,
                    fill: false,
                    pointRadius: [4, 6, 4, 12, 8, 0, 4]
                },
                {
                    label: 'New Users',
                    data: [3, 7, 2, 17, 15, 13, 19],
                    borderColor: [
                        '#66BB6A',
                    ],
                    borderWidth: 3,
                    fill: false
                }]
            },
            chartOptions: {
                responsive: true,
                hover: {
                    mode: 'index'
                },
                scales: {
                    xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Month'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Value'
                        }
                    }]
                }
            },
            menuItems: [
                {
                    label: 'Save', icon: 'fa fa-fw fa-check'
                },
                {
                    label: 'Update', icon: 'fa fa-fw fa-refresh'
                },
                {
                    label: 'Delete', icon: 'fa fa-fw fa-trash'
                }
            ],
            realimg: '',
            resizeImg: ''
        };
        this.onTaskChange = this.onTaskChange.bind(this);
        this.logoTemplate = this.logoTemplate.bind(this);
        this.scrollLeftBtn = this.scrollLeftBtn.bind(this);
        this.scrollRightBtn = this.scrollRightBtn.bind(this);
        this.carservice = new CarService();
        this.AuthService = new AuthService();
        this.netiFileHandler = new NetiFileHandler();
        this.validatorUtility = new ValidatorUtility();
        this.userOtherAccessService = new UserOtherAccessService();

    }

    componentWillMount() {
        this.getMyPointDashboardInfoDetails();
    }


    getMyPointDashboardInfoDetails() {
        this.setState({ topProgressBar: true, errorMsgVisible: false });

        this.userOtherAccessService.findMyPointDashboardInfo()
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        purchaseMonthData = Object.entries(body.monthlyPurchases)
                        let columnsHeader = ["Title", "Price"];
                        finalMonthlyPurchaseDataArr.push(columnsHeader, ...purchaseMonthData);
                        totalMonthlyPurchaseAmount = purchaseMonthData.map(item => item[1]).reduce((a, b) => (a + b));
                        this.setState({ myPointDashboardDetails: body })
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.userOtherAccessService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });

    }



    onTaskChange(e) {
        let selectedTasks = [...this.state.tasks];
        if (e.checked)
            selectedTasks.push(e.value);
        else
            selectedTasks.splice(selectedTasks.indexOf(e.value), 1);

        this.setState({ tasks: selectedTasks });
    }

    async componentDidMount() {
        this.carservice.getCarsMedium().then(data => this.setState({ cars: data }));
        const profile = this.AuthService.getProfile();

    }

    scrollLeftBtn() {
        document.getElementById('scrollableFlexWrapper').scrollLeft -= 190;
    }

    scrollRightBtn() {
        document.getElementById('scrollableFlexWrapper').scrollLeft += 190;
    }

    actionTemplate() {
        return <div className="p-grid">
            <div className="p-col-6">
                <Button type="button" icon="pi pi-search" className="p-button-info" style={{ marginRight: '.5em' }}></Button>
            </div>
            <div className="p-col-6">
                <Button type="button" icon="pi pi-times" className="p-button-danger"></Button>
            </div>
        </div>;
    }

    logoTemplate(rowData, column) {
        var src = "assets/demo/images/car/" + rowData.brand + ".png";
        return <img src={src} alt={rowData.brand} width="50px" />;
    }

    render() {
        const { realimg, resizeImg, myPointDashboardDetails } = this.state;
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let purchaseMonthTable = purchaseMonthData.map((item, index) => {
            return (
                <tr>
                    <td><span className="gradient-color-dark-blue"></span><p>{item[0]}</p></td>
                    <td><span className="tksign"></span><p>{item[1]}</p></td>
                </tr>
            )
        });

        let tokenHeader = (
            <div className="taken">Token Taken <span>(1)</span></div>
        );
        let pendingHeader = (
            <div className="pending">Pending <span>(1)</span></div>
        );
        let rejectedHeader = (
            <div className="rejected">Rejected <span>(1)</span></div>
        );
        let solvedHeader = (
            <div className="solved">Solved <span>(1)</span></div>
        );

        return <div className="main-section netiWorld-DashBoard-wrapper">
            <div className="netiWorld-DashBoard">
            {topProgressBar ?
            <ErrorMessageView
              topProgressBar={topProgressBar}
            />
            : null
          }
          {errorMsgVisible ?
            <ErrorMessageView
              errorMsgVisible={errorMsgVisible}
              errorMsgBody={errorMsgBody}
            />
            : null
          }
          <Growl ref={(el) => this.growl = el} />
                <div className="p-grid m-0">
                    <div class="p-col-12 p-md-12 p-xl-12">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-myPoint-custom-div-size">
                                <div className="notify-box-wrapper gradient-color-red">
                                    <div className="notify-box-wrapper-bg-image boxHeight-245">
                                        <div className="notify-box clearfix">
                                            <div className="notify-title-position-l-b">
                                                <div className="notify-box-title">
                                                    <div className="notify-box-icon wallet-icon"></div>
                                                    <h1 className="font-16 color-white">Wallet Balance</h1>
                                                    <hr />
                                                    <div className="notify-currency">
                                                        <p className="font-20 color-white tk-sign">{myPointDashboardDetails && myPointDashboardDetails.walletBalance ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.walletBalance) : 0}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-myPoint-custom-div-size">
                                <div className="notify-box-wrapper gradient-color-light-orange">
                                    <div className="notify-box-wrapper-bg-image boxHeight-245">
                                        <div className="notify-box clearfix">
                                            <div className="notify-btn">
                                                <span>Last Recharge<i class="fas fa-angle-right text-white font-18"></i></span>
                                            </div>
                                            <div className="notify-title-position-l-b">
                                                <div className="notify-box-title">
                                                    <div className="notify-box-icon msg-icon"></div>
                                                    <h1 className="font-16 color-white">Message Balance</h1>
                                                    <hr />
                                                    <div className="notify-currency">
                                                        <p className="font-20 color-white arrow-sign">{myPointDashboardDetails && myPointDashboardDetails.smsBalance ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.smsBalance) : 0}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-myPoint-custom-div-size">
                                <div className="notify-box-wrapper gradient-color-light-yellow">
                                    <div className="notify-box-wrapper-bg-image boxHeight-245">
                                        <div className="notify-box clearfix">
                                            <div className="notify-btn">
                                                <span>See The Codes<i class="fas fa-angle-right text-white font-18"></i></span>
                                            </div>
                                            <div className="notify-title-position-l-b">
                                                <div className="notify-box-title">
                                                    <div className="notify-box-icon unUseCode-icon"></div>
                                                    <h1 className="font-16 color-white">Unused Purchase Code</h1>
                                                    <hr />
                                                    <div className="notify-currency">
                                                        <p className="font-20 color-white active-sign">{myPointDashboardDetails && myPointDashboardDetails.unusedPurchaseCode ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.unusedPurchaseCode) : 0}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-myPoint-custom-div-size">
                                <div className="notify-box-wrapper gradient-color-dark-gray">
                                    <div className="notify-box-wrapper-bg-image boxHeight-245">
                                        <div className="notify-box clearfix">
                                            <div className="notify-btn">
                                                <span>Current Month<i class="fas fa-sync-alt text-yellow font-18"></i></span>
                                            </div>
                                            <div className="notify-title-position-l-b">
                                                <div className="notify-box-title">
                                                    <div className="notify-box-icon msgInfo-icon"></div>
                                                    <h1 className="font-16 color-white">Message Info</h1>
                                                    <hr />
                                                    <div className="notify-currency notify-flex-title">
                                                        <p className="font-20 color-white">Expense <span>{myPointDashboardDetails && myPointDashboardDetails.smsExpense ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.smsExpense) : 0}</span></p>
                                                        <p className="font-20 color-white">Transfer <span>{myPointDashboardDetails && myPointDashboardDetails.smsTransfer ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.smsTransfer) : 0}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="panel-box p-l-0 p-r-0">
                                    <div className="panel-box-header">
                                        <h1 className="m-0">Today's Info</h1>
                                        <div className="scroll-btn-wrapper">
                                            <div className="notify-btn">
                                                <span>Current Month<i class="fas fa-sync-alt text-yellow font-18"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-4 p-xl-2 p-sm-6">
                                        <div className="gradient-color-gray">
                                            <div className="notify-box-wrapper-bg-image todays-info-custom-height">
                                                <div className="notify-box">
                                                    <div className="notify-box-title">
                                                        <h1 className="font-18 color-white text-left m-0">Deposit</h1>
                                                        <hr />
                                                    </div>
                                                    <div className="notify-flex boxHeight-10em">
                                                        <div className="notify-btn notify-title-position-l-b bottom-position">
                                                            <span>See Details<i class="fas fa-angle-right text-white font-18"></i></span>
                                                        </div>
                                                        <div className="notify-box-title text-center">
                                                            <div className="notify-currency">
                                                                <p className="font-20 color-white tk-sign">{myPointDashboardDetails && myPointDashboardDetails.deposit ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.deposit) : 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-xl-2 p-sm-6">
                                        <div className="gradient-color-gray">
                                            <div className="notify-box-wrapper-bg-image todays-info-custom-height">
                                                <div className="notify-box">
                                                    <div className="notify-box-title">
                                                        <h1 className="font-18 color-white text-left m-0">Purchase</h1>
                                                        <hr />
                                                    </div>
                                                    <div className="notify-flex boxHeight-10em">
                                                        <div className="notify-btn notify-title-position-l-b bottom-position">
                                                            <span>See Details<i class="fas fa-angle-right text-white font-18"></i></span>
                                                        </div>
                                                        <div className="notify-box-title text-center">
                                                            <div className="notify-currency">
                                                                <p className="font-20 color-white tk-sign">{myPointDashboardDetails && myPointDashboardDetails.purchase ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.purchase) : 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-xl-2 p-sm-6">
                                        <div className="gradient-color-gray">
                                            <div className="notify-box-wrapper-bg-image todays-info-custom-height">
                                                <div className="notify-box">
                                                    <div className="notify-box-title">
                                                        <h1 className="font-18 color-white text-left m-0">Withdraw</h1>
                                                        <hr />
                                                    </div>
                                                    <div className="notify-flex boxHeight-10em">
                                                        <div className="notify-btn notify-title-position-l-b bottom-position">
                                                            <span>See Details<i class="fas fa-angle-right text-white font-18"></i></span>
                                                        </div>
                                                        <div className="notify-box-title text-center">
                                                            <div className="notify-currency">
                                                                <p className="font-20 color-white tk-sign">{myPointDashboardDetails && myPointDashboardDetails.withdraw ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.withdraw) : 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-xl-2 p-sm-6">
                                        <div className="gradient-color-gray">
                                            <div className="notify-box-wrapper-bg-image todays-info-custom-height">
                                                <div className="notify-box">
                                                    <div className="notify-box-title">
                                                        <h1 className="font-18 color-white text-left m-0">Income</h1>
                                                        <hr />
                                                    </div>
                                                    <div className="notify-flex boxHeight-10em">
                                                        <div className="notify-btn notify-title-position-l-b bottom-position">
                                                            <span>See Details<i class="fas fa-angle-right text-white font-18"></i></span>
                                                        </div>
                                                        <div className="notify-box-title text-center">
                                                            <div className="notify-currency">
                                                                <p className="font-20 color-white tk-sign">{myPointDashboardDetails && myPointDashboardDetails.revenue ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.revenue) : 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-xl-2 p-sm-6">
                                        <div className="gradient-color-gray">
                                            <div className="notify-box-wrapper-bg-image todays-info-custom-height">
                                                <div className="notify-box">
                                                    <div className="notify-box-title">
                                                        <h1 className="font-18 color-white text-left m-0">Transfer</h1>
                                                        <hr />
                                                    </div>
                                                    <div className="notify-flex boxHeight-10em">
                                                        <div className="notify-btn notify-title-position-l-b bottom-position">
                                                            <span>See Details<i class="fas fa-angle-right text-white font-18"></i></span>
                                                        </div>
                                                        <div className="notify-box-title text-center">
                                                            <div className="notify-currency">
                                                                <p className="font-20 color-white tk-sign">{myPointDashboardDetails && myPointDashboardDetails.send ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.send) : 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-xl-2 p-sm-6">
                                        <div className="gradient-color-gray">
                                            <div className="notify-box-wrapper-bg-image todays-info-custom-height">
                                                <div className="notify-box">
                                                    <div className="notify-box-title">
                                                        <h1 className="font-18 color-white text-left m-0">Receive</h1>
                                                        <hr />
                                                    </div>
                                                    <div className="notify-flex boxHeight-10em">
                                                        <div className="notify-btn notify-title-position-l-b bottom-position">
                                                            <span>See Details<i class="fas fa-angle-right text-white font-18"></i></span>
                                                        </div>
                                                        <div className="notify-box-title text-center">
                                                            <div className="notify-currency">
                                                                <p className="font-20 color-white tk-sign">{myPointDashboardDetails && myPointDashboardDetails.receive ? this.validatorUtility.currencyFormatter(myPointDashboardDetails.receive) : 0}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-xl-6 p-sm-12">
                                <div className="panel-box p-l-0 p-r-0">
                                    <div className="panel-box-header">
                                        <h1 className="m-0">Purchase (This Month)</h1>
                                    </div>
                                </div>
                                <div className="panel-box gray-bg">
                                    <div className="p-grid chart-box-wrapper">
                                        <div className="p-col-12 p-md-12 p-xl-7 p-sm-12">
                                        {/* className="chart-box" */}
                                            <div >
                                                <Chart
                                                    width={245}
                                                    height={245}
                                                    data={finalMonthlyPurchaseDataArr}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    legend={{
                                                        position: "bottom",
                                                        alignment: "center",
                                                        // position: 'labeled',
                                                        textStyle: {
                                                            color: "233238",
                                                            fontSize: 16
                                                        }
                                                    }}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'block', showColorCode: true },
                                                        legend: 'none',
                                                        slices: primaryChartsColorArray,
                                                    }}
                                                />
                                                <span className="chart-box-title tksign">{totalMonthlyPurchaseAmount ? this.validatorUtility.currencyFormatter(totalMonthlyPurchaseAmount) : 0}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-12 p-xl-5 p-sm-12">
                                            <div className="point-list">
                                                <table>
                                                <tbody>
                                                            {purchaseMonthData.map((item, index) => {
                                                                    return(
                                                                        <tr>
                                                                            <td><span style={{ background: primaryChartsColorArray[index].color }}></span><p> {item[0]}</p></td>
                                                                            <td><p>: {item[1]}</p></td>
                                                                        </tr>
                                                                    )
                                                                }   
                                                            ) }
                                                        </tbody>
                                                    {/* {purchaseMonthTable} */}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="p-col-12 p-md-6 p-xl-6 p-sm-12">
                                <div className="panel-box p-l-0 p-r-0">
                                    <div className="panel-box-header">
                                        <h1 className="m-0">Purchase (This Month)</h1>
                                    </div>
                                </div>
                                <div className="panel-box gray-bg">
                                    <div className="p-grid chart-box-wrapper">
                                        <div className="p-col-12 p-md-12 p-xl-7 p-sm-12">
                                            <div className="chart-box">
                                                <Chart
                                                    width={196}
                                                    height={196}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={[
                                                        ['Name', 'Gender', 'Age', 'Donuts eaten'],
                                                        ['', '', 12, 5],
                                                        ['', '', 20, 7],
                                                        ['', '', 7, 3],
                                                        ['', '', 54, 2],
                                                        ['', '', 22, 6]
                                                    ]}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'none' },
                                                        pieSliceText: 'none',
                                                        legend: 'bottom',
                                                        slices: {
                                                            0: { color: '#FFD86D' },
                                                            1: { color: '#7178AC' },
                                                            2: { color: '#FF9191' },
                                                            3: { color: '#85C2FF' },
                                                            4: { color: '#65EEE1' },
                                                        },
                                                    }}
                                                    chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                    render={({ renderChart }) => {
                                                        return (
                                                            <div>{renderChart()}</div>
                                                        )
                                                    }}
                                                />
                                                <span className="chart-box-title tksign">52000/-</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-12 p-xl-5 p-sm-12">
                                            <div className="point-list">
                                                <table>
                                                    <tr>
                                                        <td><span className="gradient-color-dark-blue"></span><p>Eduman</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-red"></span><p>Bijoy</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-light-cyan"></span><p>My Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-light-purple"></span><p>Your Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-light-pink"></span><p>His Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-gray"></span><p>Her Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="p-grid">
                            <div className="p-col-12 p-md-6 p-xl-6 p-sm-12">
                                <div className="panel-box p-l-0 p-r-0">
                                    <div className="panel-box-header">
                                        <h1 className="m-0">Purchase (This Month)</h1>
                                    </div>
                                </div>
                                <div className="panel-box gray-bg">
                                    <div className="p-grid chart-box-wrapper">
                                        <div className="p-col-12 p-md-12 p-xl-7 p-sm-12">
                                            <div className="chart-box">
                                                <Chart
                                                    width={196}
                                                    height={196}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={[
                                                        ['Name', 'Gender', 'Age', 'Donuts eaten'],
                                                        ['', '', 12, 5],
                                                        ['', '', 20, 7],
                                                        ['', '', 7, 3],
                                                        ['', '', 54, 2],
                                                        ['', '', 22, 6]
                                                    ]}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'none' },
                                                        pieSliceText: 'none',
                                                        legend: 'bottom',
                                                        slices: {
                                                            0: { color: '#FFD86D' },
                                                            1: { color: '#7178AC' },
                                                            2: { color: '#FF9191' },
                                                            3: { color: '#85C2FF' },
                                                            4: { color: '#65EEE1' },
                                                        },
                                                    }}
                                                    chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                    render={({ renderChart }) => {
                                                        return (
                                                            <div>{renderChart()}</div>
                                                        )
                                                    }}
                                                />
                                                <span className="chart-box-title tksign">52000/-</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-12 p-xl-5 p-sm-12">
                                            <div className="point-list">
                                                <table>
                                                    <tr>
                                                        <td><span className="gradient-color-dark-blue"></span><p>Eduman</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-red"></span><p>Bijoy</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-light-cyan"></span><p>My Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-light-purple"></span><p>Your Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-light-pink"></span><p>His Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-gray"></span><p>Her Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 p-xl-6 p-sm-12">
                                <div className="panel-box p-l-0 p-r-0">
                                    <div className="panel-box-header">
                                        <h1 className="m-0">Purchase (This Month)</h1>
                                    </div>
                                </div>
                                <div className="panel-box gray-bg">
                                    <div className="p-grid chart-box-wrapper">
                                        <div className="p-col-12 p-md-12 p-xl-7 p-sm-12">
                                            <div className="chart-box">
                                                <Chart
                                                    width={196}
                                                    height={196}
                                                    chartType="PieChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={[
                                                        ['Name', 'Gender', 'Age', 'Donuts eaten'],
                                                        ['', '', 12, 5],
                                                        ['', '', 20, 7],
                                                        ['', '', 7, 3],
                                                        ['', '', 54, 2],
                                                        ['', '', 22, 6]
                                                    ]}
                                                    options={{
                                                        chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                        backgroundColor: 'transparent',
                                                        tooltip: { trigger: 'none' },
                                                        pieSliceText: 'none',
                                                        legend: 'bottom',
                                                        slices: {
                                                            0: { color: '#FFD86D' },
                                                            1: { color: '#7178AC' },
                                                            2: { color: '#FF9191' },
                                                            3: { color: '#85C2FF' },
                                                            4: { color: '#65EEE1' },
                                                        },
                                                    }}
                                                    chartWrapperParams={{ view: { columns: [0, 3] } }}
                                                    render={({ renderChart }) => {
                                                        return (
                                                            <div>{renderChart()}</div>
                                                        )
                                                    }}
                                                />
                                                <span className="chart-box-title tksign">52000/-</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-12 p-xl-5 p-sm-12">
                                            <div className="point-list">
                                                <table>
                                                    <tr>
                                                        <td><span className="gradient-color-dark-blue"></span><p>Eduman</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-red"></span><p>Bijoy</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-light-cyan"></span><p>My Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-light-purple"></span><p>Your Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-light-pink"></span><p>His Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="gradient-color-gray"></span><p>Her Point</p></td>
                                                        <td><span className="tksign"></span><p>12,500/-</p></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    }
}