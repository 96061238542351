import React, { Component } from 'react';

export class CustomerInfoLog extends Component {
  
    render() {
        
        return (
            <div className="p-fluid">
                <h1>This Module is Under Construction</h1>
            </div>

        )
    }

}