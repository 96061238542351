import React, { Component } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import profilePic from '../myProfile/images.jpg';
import qrCode from '../myProfile/qrCode.png';
import { Dialog } from 'primereact/dialog';
import CommonFuctionality from '../../common/CommonFuctionality';
import { FileUpload } from 'primereact/fileupload';
import { ProfileService } from '../../../service/profile/ProfileService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';

export class CommonProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value2: 80,
            visible: false,
            commonProfileInfo: {...props.commonProfileInfo},
            proPic: {
                contentName: ''
            },
        }
        
        this.displayValueTemplate = this.displayValueTemplate.bind(this);
        this.CommonFuctionality = new CommonFuctionality();
        this.ProfileService = new ProfileService()
        this.netiFileHandler = new NetiFileHandler();

    }

    // componentWillMount(){
    //     this.getCommonProfileInfo()
    // }

    getCommonProfileInfo = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ProfileService.fetchCommonProfileInfo()
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        this.setState({ commonProfileInfo: body, topProgressBar: false, errorMsgVisible: false });
                        // this.getImg();
                    })
                }
                else {
                    this.ProfileService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    getImg() {
        let { commonProfileInfo } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        if (commonProfileInfo.imagePath !== null) {
            this.netiFileHandler.getByteImage(commonProfileInfo.imagePath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                console.log("BODY", body);

                                let contentType = this.netiFileHandler.getImageContentType(commonProfileInfo.imageName);
                                this.setState({ viewUpdatePicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ viewUpdatePicture: staticImg });
                    }
                });
        } else {
            this.setState({ viewUpdatePicture: staticImg });
        }

    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.commonProfileInfo !== this.props.commonProfileInfo) {
            console.log('nextProps', nextProps);
            this.setState({ commonProfileInfo: nextProps.commonProfileInfo })
        // }
    }

    displayValueTemplate(value) {
        return (
            <React.Fragment>
                <b>Your Profile Completence  -  </b>{value}
            </React.Fragment>
        );
    }

    onHide = () => {
        this.setState({ visible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showDialog = () => {
        this.getImg();
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visible: true });
    }

    removeButtonOnclick = () =>{
        this.setState({ proPic: { contentPic: '' }, viewUpdatePicture: '' })
    }

    onLoadPic(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });

        }
        // this.growl.show({severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode'});
    }

    updatePicture = () => {
        
        let { commonProfileInfo } = this.state;
       
        if (this.state.proPic.contentPic != null) {
            commonProfileInfo.imageContent = this.state.proPic.contentPic;
            commonProfileInfo.imageSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            commonProfileInfo.imageName = 'pro_pic_' + commonProfileInfo.netiID + extention;
        }
        
        this.setState({ topProgressBar: true, dataTableIsLoading:true, errorMsgVisible:false});
        // console.log("User Basic Info obj",commonProfileInfo);

        this.ProfileService.updateUserbasicInfo(commonProfileInfo)
            .then(res => {
                if (res.status === 200) {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfuly submitted.' });
                    this.props.getCommonProfileInfo();
                    this.setState({ topProgressBar: false, errorMsgVisible: false })

                } else {
                    this.ProfileService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: 'Please check your connection.' })
            })
    }

    render() {
        let { commonProfileInfo, topProgressBar, errorMsgVisible, errorMsgBody } = this.state
        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">370 x 240 px (recommended)</span></div>

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="personalInfo-wrapper blur-section">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <div className="profile-info-wrapper">
                                <div className="profile-pic-wrapper">
                                    {
                                        commonProfileInfo.imageContent ?
                                        <img src={(`data:image/*;base64, ${commonProfileInfo.imageContent}`) } className="profile-pic" />
                                        :
                                        <img src='assets/layout/images/avatar.png' className="profile-pic" />
                                    }
                                    
                                    <span className="active"></span>
                                    <div className="change-image" onClick={this.showDialog}></div>
                                </div>
                                <div className="profile-info">
                                    <span className="netiID">NETI ID : { commonProfileInfo.customNetiID }</span>
                                    <span className="userName">{ commonProfileInfo.fullName }</span>
                                    <span className="mobileNumber">{ commonProfileInfo.basicMobile }</span>
                                    <span className="netimail">{ commonProfileInfo.basicEmail }</span>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="progress-wrapper">
                                <div className="qr-wrapper">
                                    <img src={qrCode} className="qr-code" />
                                </div>
                                <div className="progress">
                                    <ProgressBar value={this.state.value2} displayValueTemplate={this.props.displayValueTemplate}></ProgressBar>
                                    <div className="progress-icon">
                                        <Button icon="fas fa-info-circle" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dialog-section">
                    <Dialog id="dialog" className="nw-dialog height-auto" header="Change Profile Picture" visible={this.state.visible} onHide={this.onHide} maximizable>
                        {/* <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12 p-col-nogutter">
                                <div className="nw-form-body m-t-30">
                                    <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup nw-upload-button">
                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Select profile picture <span>*</span>
                                            <br />
                                            <span>(PNG/JPG/JPEG/GIF)</span>
                                        </span>
                                            <div className="nw-upload-button-inside">
                                            <FileUpload
                                                    chooseLabel={uploadFileLabel}
                                                    mode="basic" 
                                                    accept="image/*"
                                                    maxFileSize={1000000} 
                                                    auto={true}
                                            />
                                            
                                        </div>
                                    </div>
                                </div>

                                </div>
                            </div>
                        </div> */}
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup nw-upload-button">
                                    <span className="p-inputgroup-addon nw-inputtextarea-label">
                                        Select profile picture <span>*</span>
                                        <br />
                                        <span>(PNG/JPG/JPEG/GIF)</span>
                                    </span>
                                    <div className="nw-upload-button-inside">
                                        {
                                            this.state.proPic.contentPic || this.state.viewUpdatePicture ?
                                                <div className="image-view-main">
                                                    <div className="upload-image-view">
                                                        <Button
                                                            className="delete-upload-button"
                                                            icon="fas fa-times-circle"
                                                            onClick={this.removeButtonOnclick}
                                                        />
                                                        <img
                                                            src={
                                                                this.state.viewUpdatePicture === '' ?
                                                                    "data:" + this.state.proPic.extention + ";base64," + this.state.proPic.contentPic
                                                                    :
                                                                    this.state.viewUpdatePicture
                                                            }
                                                            style={{ "height": "80px" }}
                                                        />


                                                    </div>
                                                    <div className="image-title">{this.state.proPic.contentName || this.state.commonProfileInfo.photoName}</div>
                                                </div>
                                                :
                                                <FileUpload
                                                    chooseLabel={this.state.proPic.contentName || uploadFileLabel}
                                                    mode="basic"
                                                    accept="image/*"
                                                    maxFileSize={1000000}
                                                    onSelect={this.onLoadPic.bind(this)}
                                                    auto={true}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12 nw-button-parent">
                            <Button
                                icon="fas fa-check"
                                onClick={this.updatePicture}
                                label="Update"
                                className="p-button p-button-primary nw-button nw-button-right"
                            />
                        </div>
                       
                    </Dialog>
                </div>
            </div>
        )
    }
}