import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import profileImage from '../../../assets/images/mostofa-jobber.png';
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { ProfileService } from '../../../service/profile/ProfileService';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { DownloadButton } from '../../../utils/DownloadButton';

let maxDate = new Date();
let errors={}
export class PartnerOccurrence extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            occurrenceSubmitObj:{
                occurrenceDate: '',
                occurrenceDetails: '',
                resolvationDetails: '',
                userBasicInfoDTO: {
                    netiID: 0
                },
                occuranceTypeInfoDTO: {
                    coreCategoryID: 0
                },
                resolvationWayInfoDTO: {
                    coreCategoryID: 0
                }
            },
            viewDetailsObject: {
                ...this.occurrenceSubmitObj
            },
            occurrenceName:'',
            occurrenceTypeList: [],
            resolvationWayList:[],
            customNetiID: '',
            receiver: {},
            uploadImage:{},
            searchIsLoadingFirst: false,
            searchIsLoadingSecond: false,
            searchView: false,
            searchViewError: false,
            buttonDisabled: true,
            errors: {},
            visible: false,
        }

        this.profileService = new ProfileService();
        this.userCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.netiFileHandler = new NetiFileHandler();
        this.CRMPointService = new CRMPointService();
        
    }

    componentWillMount() {
        this.occurrenceType("T125");
        this.occurrenceType("T126");
        this.getOccuranceList();
    }

    occurrenceType = (defaultCode) =>{
        this.setState({ firstDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
          .then(res => {
            if (res.status === 302) {
              return res.json().then((body) => {
                  if(defaultCode == "T125"){
                      this.setState({ occurrenceTypeList: body })
                  }
                  else if(defaultCode == "T126"){
                    this.setState({ resolvationWayList: body })
                }
                
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
              })
            }else {
                this.userCategoryService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, tableView: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, tableView: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
        });
    }

    getOccuranceList = () =>{
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.CRMPointService.getFetchOccurrenceList()
          .then(res => {
            if (res.status === 302) {
              return res.json().then((body) => {
                this.setState({ dataTableValue: body, dataTableIsLoading:false, firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
              })
            }else {
                this.CRMPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
        });
    }

    onChangeCustomNetiID = (e) => {
        this.setState({
            customNetiID: e.target.value,
            errors: {}
        });
        this.setState({ receiver: {}, searchViewError: false, searchView: false, buttonDisabled: true });
    }

    onChangeDate = (e) => {
        let { occurrenceSubmitObj } = this.state;
        occurrenceSubmitObj.occurrenceDate = e.target.value;
        this.setState({ occurrenceSubmitObj });
        this.clearDateError('occurrenceDate');
    }

    onChangeOccurrenceType = (e) => {
        let { occurrenceSubmitObj } = this.state;
        occurrenceSubmitObj.occuranceTypeInfoDTO.coreCategoryID = e.target.value;
        this.setState({ occurrenceSubmitObj });
        this.clearDateError('occurrenceType');
    }

    onChangeResolvationWay = (e) => {
        let { occurrenceSubmitObj } = this.state;
        occurrenceSubmitObj.resolvationWayInfoDTO.coreCategoryID = e.target.value;
        this.setState({ occurrenceSubmitObj });
        this.clearDateError('resolvationWay');
    }

    onChangeOccurrenceDetails = (e) => {
        let { occurrenceSubmitObj } = this.state;
        occurrenceSubmitObj.occurrenceDetails = e.htmlValue;
        this.setState({ occurrenceSubmitObj });
        this.clearDateError('occurrenceDetails');
    }

    onChangeResolvationDetails = (e) => {
        let { occurrenceSubmitObj } = this.state;
        occurrenceSubmitObj.resolvationDetails = e.htmlValue;
        this.setState({ occurrenceSubmitObj });
        this.clearDateError('resolvationDetails');
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    handleErrorNetiId = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.customNetiID === '') {
            formIsValid = false;
            errors["customNetiID"] = "Custom neti ID can't left empty.";
            this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
        }

        this.setState({ errors });
        return formIsValid;
    }

    onChangeSearchByCustomNetiID = () => {
        let { occurrenceSubmitObj } = this.state
        if (this.handleErrorNetiId()) {
            this.setState({ topProgressBar: true, searchIsLoadingFirst: true, searchIsLoadingSecond: true, errorMsgVisible: false, searchView: true, searchViewError: false });
            let { balanceTransferInfo, errors } = this.state;
            this.profileService.findBasicInfoByCustomNetiID(this.state.customNetiID)
                .then((res) => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            let userProfile = JSON.parse(localStorage.getItem('Profile'));
                            if (body.customNetiID === userProfile.customNetiID) {
                                errors["customNetiID"] = "You can't transfer balance to your own account";
                                this.setState({ buttonDisabled: true, errors })
                                // balanceTransferInfo.receiver.netiID = body.netiID;
                                this.setState({
                                    receiver: body, balanceTransferInfo,
                                    errorMsgVisible: false,
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false,
                                    searchViewError: false
                                });
                            } else {
                                occurrenceSubmitObj.userBasicInfoDTO.netiID = body.netiID;
                                this.setState({
                                    receiver: body, 
                                    occurrenceSubmitObj,
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false,errorMsgVisible: false,
                                    searchViewError: false,
                                    buttonDisabled: false
                                });

                                // this.setState({ buttonDisabled: false })
                            }

                        });
                    } else {
                        this.profileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({
                                    receiver: {},
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false, errorMsgBody: responseBody,errorMsgVisible: true,
                                    searchViewError: true
                                })
                                this.setState({ buttonDisabled: true })

                            });
                    }
                }).catch(error => {
                    this.setState({
                        topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!",
                        searchViewError: false, searchView: false
                    })
                });
        }
    }

    actionBody = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-info"
                tooltip="Edit"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    viewDialog = (rowData) => {
        this.setState({ visible: true, viewDetailsObject: rowData });
    }

    onHide = (event) => {
        this.setState({ visible: false });
        this.setState({ approvalValue: 'Pending', approvedNote: '', rejectNote: '', approvedAmount: '' });
    }

    focusSearch = e =>{
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
    }

    blurSearch = e =>{
        this.setState({ searchLabel: '', addSearchBtnClass: ""  });
    }

    logDateBody = (rowdata) =>{
        return NetiDateUtils.getAnyShortForm(rowdata['logDate'], 'DD-MMM-YYYY hh:mm:ss a')
    }

    onBasicUpload = (e) => {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
          let content = reader.result;
          var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
          var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
          let album = {
            extention: photo.type,
            contentPic: urlStr,
            contentName: photo.name
          };
          scope.setState({ uploadImage: album });
        }
    }

    occurrenceError = () => {
        let { occurrenceSubmitObj, errors } = this.state
        let formIsValid = true;

        if (occurrenceSubmitObj.customNetiID == '') {
            formIsValid = false;
            errors["ratingMark"] = "Neti ID can't left empty.";
        }

        if (occurrenceSubmitObj.occurrenceDate == '') {
            formIsValid = false;
            errors["occurrenceDate"] = "Date can't left empty.";
        }

        if (occurrenceSubmitObj.occuranceTypeInfoDTO.coreCategoryID == '') {
            formIsValid = false;
            errors["occurrenceType"] = "Occurrence Type can't left empty.";
        }

        if (occurrenceSubmitObj.occurrenceDetails == '') {
            formIsValid = false;
            errors["occurrenceDetails"] = "Occurrence Details can't left empty.";
        }

        if (occurrenceSubmitObj.resolvationWayInfoDTO.coreCategoryID == '') {
            formIsValid = false;
            errors["resolvationWay"] = "Resolvation Way Type can't left empty.";
        }

        if (occurrenceSubmitObj.resolvationDetails == '') {
            formIsValid = false;
            errors["resolvationDetails"] = "Resolvation Details can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitOccurrence = (e) => {
        let { occurrenceSubmitObj } = this.state;

        if (this.state.uploadImage.contentPic !== null && this.state.uploadImage.contentPic !== undefined) {
            occurrenceSubmitObj.imgContent = this.state.uploadImage.contentPic;
            occurrenceSubmitObj.imgSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.uploadImage.extention);
            occurrenceSubmitObj.attachmentName = Date.now() + extention;
        }
        else if( !this.state.uploadImage.contentPic || this.state.uploadImage.contentPic == '' ){
            occurrenceSubmitObj.imgSaveOrEditable = false;
        }

        console.log("occurrenceSubmitObj", occurrenceSubmitObj);
        

        if( this.occurrenceError()){
            this.setState({ topProgressBar: true, errorMsgVisible: false });

            this.CRMPointService.submitPartnerOccurrence(occurrenceSubmitObj)
                .then((res) => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully submitted' });
                        this.getOccuranceList();
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                });
        }
        

    }

    onSearchCustomNetiIDErrorHandle = () => {
        let { errors, customNetiID } = this.state;
        let formIsValid = true;
        this.setState({ searchView: false })
        
        if (!this.state.customNetiID) {
        formIsValid = false;
        errors['customNetiID'] = 'Neti ID can\'t left empty'
        }
        
        if(customNetiID.length < 10){
        formIsValid = false;
        errors['customNetiID'] = 'Neti ID is too sort'
        }
        
        if(customNetiID.length < 10 && customNetiID == ''){
        formIsValid = false;
        errors['customNetiID'] = 'Neti ID can\'t left empty and Neti minimum lenght is 10'
        }
        
        this.setState({ errors })
        
        return formIsValid;
    }


    render() {
        let { viewDetailsObject, occurrenceSubmitObj, occurrenceTypeList, resolvationWayList, occurrenceName, buttonDisabled, receiver, errors, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody, dateRangeObj } = this.state

        let uploadFileLabel = <div>Click / Drag File Here To Upload <br/><span className="upload-file-label">(Max 1MB (recommended)</span></div>

        let headervalue = <div className="header-title">
            <div className="header-title-left">
                Partner Occurrence List
                            </div>


            <div className="header-title-right">
                <a>
                    Total Found: {dataTableValue && dataTableValue.length}
                </a>
            </div>
        </div>;

        let occuranceType=[]
        if (occurrenceTypeList && occurrenceTypeList.length) {
            occuranceType = occurrenceTypeList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let resolvationWay=[]
        if (resolvationWayList && resolvationWayList.length) {
            resolvationWay = resolvationWayList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section crm-dashboard">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">To Neti ID <span>*</span></span>
                                        <InputText
                                            value={this.state.customNetiID}
                                            onChange={this.onChangeCustomNetiID}
                                            placeholder="Enter Transfer Neti ID"
                                            name="customNetiID"
                                            showClear={true}
                                            style={{ width: "15%" }}
                                            type="text"
                                            onFocus={ this.focusSearch }
                                            onBlur={ this.blurSearch }
                                            onKeyUp={ this.onSearchCustomNetiIDErrorHandle}
                                        />
                                        <Button
                                            className={"p-button-animation " + this.state.addSearchBtnClass}
                                            label={ this.state.searchLabel}
                                            icon="fas fa-search"
                                            onClick={this.onChangeSearchByCustomNetiID}
                                            onFocus={ this.focusSearch }
                                            onBlur={ this.blurSearch }
                                        />
                                    </div>
                                    <span className="error-message">{errors["customNetiID"]}</span>
                                </div>

                                {this.state.searchView ?
                                    <div className="p-col-12 p-xl-12">

                                        <div className="nw-search-view">

                                            {this.state.searchViewError ?
                                                <center className="error-message">{this.state.errorMsgBody || "No Data Found"}</center> :
                                                <div>
                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.searchIsLoadingFirst ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Name</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {receiver.fullName}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.searchIsLoadingSecond ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Mobile No</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {receiver.basicMobile }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>



                                    </div>
                                    :
                                    ''
                                }
                                    
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Occurrence Date <span>*</span></span>
                                        <Calendar
                                            name='occurrenceDate'
                                            value={occurrenceSubmitObj.occurrenceDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{errors["occurrenceDate"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.firstDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Occurrence Type <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Occurrence Type"
                                                value={occurrenceSubmitObj.occuranceTypeInfoDTO.coreCategoryID}
                                                options={occuranceType}
                                                autoWidth={false}
                                                onChange={this.onChangeOccurrenceType}
                                                name="occurrenceType"
                                            />
                                        </div>
                                    }
                                    <span className="error-message">{errors["occurrenceType"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Occurrence Details <span>*</span></span>
                                        <Editor
                                            value={ occurrenceSubmitObj.occurrenceDetails}
                                            onTextChange={this.onChangeOccurrenceDetails}
                                            placeholder="Enter Occurence Details"
                                            className="nw-inputtexteditor"
                                        />
                                    </div>
                                    <span className='error-message'>{errors["occurrenceDetails"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.firstDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Resolvation Way <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Resolvation Type"
                                                value={occurrenceSubmitObj.resolvationWayInfoDTO.coreCategoryID}
                                                options={resolvationWay}
                                                autoWidth={false}
                                                onChange={this.onChangeResolvationWay}
                                                name="resolvationWay"
                                            />
                                        </div>
                                    }
                                    <span className="error-message">{errors["resolvationWay"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Resolvation Details <span>*</span></span>
                                        <Editor
                                            value={ occurrenceSubmitObj.resolvationDetails}
                                            onTextChange={this.onChangeResolvationDetails}
                                            placeholder="Enter Resolvation Details"
                                            className="nw-inputtexteditor"
                                        />
                                    </div>
                                    <span className='error-message'>{ errors["resolvationDetails"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup nw-upload-button">
                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                            Upload File
                                    <br />
                                            <span>(PNG/JPG/DOC/PDF)</span>
                                        </span>

                                        <div className="nw-upload-button-inside">

                                            {
                                                this.state.uploadImage.contentPic ?
                                                <div className="image-view-main">
                                                    <div className="upload-image-view">
                                                        <Button
                                                            className="delete-upload-button"
                                                            icon="fas fa-times-circle"
                                                            onClick={e => this.setState({ uploadImage: { contentPic: '' } })}
                                                        />
                                                        <img
                                                            src={
                                                                this.state.uploadImage.contentPic === '' ?
                                                                    'assets/images/demo.jpg'
                                                                    :
                                                                    "data:image/png;base64," + this.state.uploadImage.contentPic
                                                            }
                                                            style={{ "height": "80px" }}
                                                        />


                                                    </div>
                                                    <div className="image-title">{this.state.uploadImage.contentName}</div>
                                                </div>
                                                :
                                                <FileUpload
                                                    chooseLabel={this.state.uploadImage.contentName || uploadFileLabel}   //this.state.uploadImage.contentName || 
                                                    id="fileUpload"
                                                    mode="basic"
                                                    maxFileSize={1000000}
                                                    onSelect={this.onBasicUpload}
                                                    auto={true}
                                                />
                                            }

                                        </div>

                                    </div>
                                </div>
                                
                                
                                <div className="p-col-12 p-lg-12 nw-button-parent">
                                    <div class="required-field">(<span>*</span>) required fields</div>
                                    <Button
                                        label="Submit"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-check"
                                        onClick={this.onSubmitOccurrence}
                                        disabled={buttonDisabled}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table nw-data-table-tabview">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                header={headervalue}
                                value={dataTableValue}
                                responsive={true}
                                paginator={true}
                                rows={10}
                                >
                                <Column field="logDate" header="Request Date" body={ this.logDateBody } sortable={true} filter={true} />
                                <Column field="userBasicInfoDTO.customNetiID" header="Neti ID" sortable={true} filter={true} />
                                <Column field="occuranceTypeInfoDTO.categoryName" header="Occurrence Type" sortable={true} filter={true} />
                                <Column field="resolvationWayInfoDTO.categoryName" header="Resolvation Way" sortable={true} filter={true} />
                                <Column header="Action" body={this.actionBody} style={{ width: '90px'}}/>
                                </DataTable>
                             }
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog header="Partner Occurrence Details" visible={this.state.visible} className="nw-dialog" onHide={this.onHide} closable>
                        <div className="p-col-12 p-xl-12">

                            <div className="nw-search-view no-border">
                                <div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Date</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                {NetiDateUtils.getAnyShortForm(viewDetailsObject.logDate, 'DD-MMM-YYYY hh:mm:ss a')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Neti ID</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                {viewDetailsObject.userBasicInfoDTO && viewDetailsObject.userBasicInfoDTO.customNetiID}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Occurrence Type</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                {viewDetailsObject.occuranceTypeInfoDTO && viewDetailsObject.occuranceTypeInfoDTO.categoryName}
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Occurrence Details</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                <Editor
                                                    value={ viewDetailsObject.occurrenceDetails }
                                                    className="nw-inputtexteditor only-view"
                                                    readOnly={true}
                                                    headerTemplate={ e => {return null}}
                                                />
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Resolvation Way</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                {viewDetailsObject.resolvationWayInfoDTO && viewDetailsObject.resolvationWayInfoDTO.categoryName}
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Resolvation Details</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">
                                                <Editor
                                                    value={ viewDetailsObject.resolvationDetails }
                                                    className="nw-inputtexteditor only-view"
                                                    readOnly={true}
                                                    headerTemplate={ e => {return null}}
                                                />
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Attachment</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc" style={{ display: 'flex', alignItems: 'center'}}>
                                                <DownloadButton
                                                    fileName={viewDetailsObject.attachmentName} 
                                                    filePath={viewDetailsObject.attachmentPath}
                                                />
                                                {viewDetailsObject.attachmentName}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </Dialog>

                </div>
            </div>

        )
    }
}