import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from "primereact/calendar";
import { InputTextarea } from 'primereact/inputtextarea';
import { ProfileService } from '../../../service/profile/ProfileService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { UserCategoryService } from '../../../service/UserCategoryService';

export class PersonalExprienceInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userExperienceInfoObj: [],
            updateUserObj:{
                employmentStart: '',
                employmentEnd: '',
                countryInfoDTO:{}
            },
            insertUserObj:{
                countryInfoDTO:{
                    coreCategoryID: ''
                }
            },
            countryList: [],
            visibleDialog: false,
            visibleEditDialog: false,
            errors: {},
        }
        this.CommonFuctionality = new CommonFuctionality();
        this.ProfileService = new ProfileService();
        this.UserCategoryService = new UserCategoryService();
    }

    componentWillMount(){
        this.getUserExperienceInfo();
        this.getValueByDefaultCode('T10201');
    }

    getValueByDefaultCode = (defaultCode) => {
        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ countryList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    getUserExperienceInfo = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ProfileService.fetchUserBasicInfo('experience')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].employmentStart == null) {
                                body[i].employmentStartView = '';
                            } else {
                                body[i].employmentStartView = NetiDateUtils.getAnyShortForm(body[i].employmentStart, 'DD-MMM-YYYY');
                                body[i].employmentStartEdit = NetiDateUtils.getAnyShortForm(body[i].employmentStart, 'DD-MM-YYYY');
                            }

                            if (body[i].employmentEnd == null) {
                                body[i].employmentEndView = '';
                            } else {
                                body[i].employmentEndView = NetiDateUtils.getAnyShortForm(body[i].employmentEnd, 'DD-MMM-YYYY');
                                body[i].employmentEndEdit = NetiDateUtils.getAnyShortForm(body[i].employmentEnd, 'DD-MM-YYYY');
                            }
                        }

                        this.setState({ userExperienceInfoObj: body, topProgressBar: false, errorMsgVisible: false });
                        // this.getSlipFile(rowData.attachFilePath,rowData.attachFileName);
                    })
                }
                else {
                    this.ProfileService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeDialogHide = () => {
        this.setState({ visibleDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showDialog = () => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visibleDialog: true, errors: {} });
    }

    onChangeEditDialogHide = () => {
        this.setState({ visibleEditDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showEditDialog = (itemData) => {

            if (itemData.employmentStart == null) {
                itemData.employmentStart = '';
            } 
            else {
                itemData.employmentStart = NetiDateUtils.getAnyShortForm(itemData.employmentStart, 'YYYY-MM-DD');
            }

            if (itemData.employmentEnd == null) {
                itemData.employmentEnd = '';
            } else {
                itemData.employmentEnd = NetiDateUtils.getAnyShortForm(itemData.employmentEnd, 'YYYY-MM-DD');
            }
        
        this.CommonFuctionality.blurDialogBackgroundActive();
        // body[i].publishDate = NetiDateUtils.getAnyShortForm(body[i].publishDate, 'DD-MMM-YYYY');
        this.setState({ updateUserObj: {...itemData}, visibleEditDialog: true, errors: {} });
    }

    onChageCompanyName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.companyName = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.companyName = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageCompanyBusiness = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.companyBusiness = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.companyBusiness = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageDesignationName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.designationName = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.designationName = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageWorkingDepartment = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.workingDepartment = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.workingDepartment = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageCompanyLocation = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.companyLocation = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.companyLocation = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageCountryName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.countryInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.countryInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChangeStartDate = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.employmentStart = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.employmentStart = e.target.value
            updateUserObj.employmentStartEdit = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChangeEndDate = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.employmentEnd = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.employmentEnd = e.target.value
            updateUserObj.employmentEndEdit = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageResponsibilityDetails = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.responsibilityDetails = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.responsibilityDetails = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    handleError = (objectHandle) => {
        let { errors } = this.state;
        let formIsValid = true;

        if (!objectHandle.companyName) {
            formIsValid = false;
            errors["companyName"] = "Company Name can't left empty.";
        }

        if (!objectHandle.companyBusiness) {
            formIsValid = false;
            errors["companyBusiness"] = "Company business can't left empty.";
        }
        
        if (!objectHandle.designationName) {
            formIsValid = false;
            errors["designationName"] = "Designation can't left empty.";
        }

        if (!objectHandle.workingDepartment) {
            formIsValid = false;
            errors["workingDepartment"] = "Department can't left empty.";
        }

        if (!objectHandle.companyLocation) {
            formIsValid = false;
            errors["companyLocation"] = "Company location can't left empty.";
        }

        if (!objectHandle.countryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["countryName"] = "Company country can't left empty.";
        }

        if (!objectHandle.employmentStart) {
            formIsValid = false;
            errors["employmentStart"] = "Start Date can't left empty.";
        }

        if (!objectHandle.employmentEnd) {
            formIsValid = false;
            errors["employmentEnd"] = "End Date can't left empty.";
        }

        if (!objectHandle.responsibilityDetails) {
            formIsValid = false;
            errors["responsibilityDetails"] = "Details can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onUpdateUserExperienceInfo = () => {
        let { updateUserObj } = this.state;
        if (this.handleError(updateUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
            this.ProfileService.updateUserBasicInfo( updateUserObj, 'experience')
                .then(res => {
                    if (res.status == 202) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                        this.onChangeEditDialogHide();
                        setTimeout(() => {
                            this.getUserExperienceInfo();
                        }, 1000);
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' })
                });
        }
    }

    onInsertUserExperienceInfo = () => {
        let { insertUserObj } = this.state;
        if (this.handleError(insertUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
            this.ProfileService.insertUserBasicInfo( insertUserObj, 'experience')
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                        this.onChangeDialogHide();
                        setTimeout(() => {
                            this.getUserExperienceInfo();
                        }, 1000);
                        
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' })
                });
        }
    }

    render() {
        let { userExperienceInfoObj, updateUserObj, insertUserObj, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let uploadFileLabel = <div>Click / Drag Here To Upload</div>

        let countryName = []
        if (this.state.countryList && this.state.countryList.length) {
            countryName = this.state.countryList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        return (
            <div>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section blur-section">
                    <div className="p-grid">
                        {
                            userExperienceInfoObj.map((item, index) =>
                                <div className="p-col-12 p-md-6">
                                    <div className="personal-box-wrapper m-t-30">
                                        <div className="personal-box-title-wrapper">
                                            <div className="personal-box-title">
                                                <h4>Professional Qualification - {index + 1}</h4>
                                            </div>
                                            <div className="edit-button">
                                                <Button icon="fas fa-edit" onClick={e => this.showEditDialog(item)} />
                                            </div>
                                        </div>
                                        <div className="personal-box">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Company Name</td>
                                                        <td>{ item.companyName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Company Business</td>
                                                        <td>{ item.companyBusiness || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Designation</td>
                                                        <td>{ item.designationName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Department</td>
                                                        <td>{ item.workingDepartment || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Company Location</td>
                                                        <td>{ (item.companyLocation +', '+ item.countryInfoDTO.categoryName) || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Employment Start Date</td>
                                                        <td>{ item.employmentStartView || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Employment End Date</td>
                                                        <td>{ item.employmentEndView || '---' }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="responsibility-wrapper">
                                                <div className="responsibility-title">Responsibilities</div>
                                                <div className="responsibility">
                                                    {/* <p># Collect Client Requirement exactly which they want from Software </p>
                                                    <p># Analysis & Convert there requirement </p>
                                                    <p># Help to System & Database Design </p>
                                                    <p># Operate & Maintain software development Team </p>
                                                    <p># Maintenance & Backup Sever </p>
                                                    <p># Directly Communicate With Client</p>
                                                    <p># Give Customer Support About Software ERP Problem via Phone, E-mail, & Team viewer ( If require go to Field )</p>
                                                    <p># Maintain Troubleshoot Internet office Networking & Other IT Equipment</p>
                                                    <p># Maintain & Backup Database</p>
                                                    <p># Email Communicati</p>
                                                    <p># Maintain Their Internal ERP</p> */}

                                                    {
                                                        item.responsibilityDetails.replace(/\r?\n/g, '<br />').split("<br />").map((item, index) =>
                                                            <p>{item} </p>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="add-button">
                        <Button
                            icon="fas fa-plus"
                            tooltip="Add Exprience Info" 
                            tooltipOptions={{ position: 'bottom' }}
                            onClick={this.showDialog}
                        />
                    </div>
                </div>
                <div className="dialog-section">
                    <Dialog id="dialog" className="nw-dialog" header="Add Professional Qualification" visible={this.state.visibleDialog} onHide={this.onChangeDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Company Name <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Company Name"
                                                    name="companyName"
                                                    value={ insertUserObj.companyName }
                                                    onChange= { e => this.onChageCompanyName(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['companyName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Company Business<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Company Business"
                                                    name="companyBusiness"
                                                    value={ insertUserObj.companyBusiness }
                                                    onChange= { e => this.onChageCompanyBusiness(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['companyBusiness']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Designation<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Designation"
                                                    name="designationName"
                                                    value={ insertUserObj.designationName }
                                                    onChange={ e => this.onChageDesignationName(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['designationName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Department <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Department"
                                                    name="workingDepartment"
                                                    value={ insertUserObj.workingDepartment }
                                                    onChange= { e => this.onChageWorkingDepartment(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['workingDepartment']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Company Location <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Company Location"
                                                    name="companyLocation"
                                                    value={ insertUserObj.companyLocation }
                                                    onChange= { e => this.onChageCompanyLocation(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['companyLocation']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Country <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Country"
                                                    onChange= { e => this.onChageCountryName(e, 'insert') }
                                                    value={ insertUserObj.countryInfoDTO.coreCategoryID }
                                                    options={countryName}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="countryName"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['countryName']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Start Date  <span>*</span></span>
                                                <Calendar
                                                    name="employmentStart"
                                                    showIcon={true}
                                                    value={ insertUserObj.employmentStart}
                                                    onChange={ e => this.onChangeStartDate(e, 'insert')}
                                                    placeholder='Select Date'
                                                    className="custom-calender-input"
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                />
                                            </div>
                                            <span className="error-message">{errors['employmentStart']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                                <Calendar
                                                    name="employmentEnd"
                                                    showIcon={true}
                                                    value={ insertUserObj.employmentEnd}
                                                    onChange={ e => this.onChangeEndDate(e, 'insert')}
                                                    placeholder='Select Date'
                                                    className="custom-calender-input"
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                />
                                            </div>
                                            <span className="error-message">{errors['employmentEnd']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Responsibilities <span>*</span></span>
                                                <InputTextarea
                                                    name="responsibilityDetails"
                                                    placeholder="Enter Note"
                                                    onChange={ e => this.onChageResponsibilityDetails(e, 'insert') }
                                                    value={insertUserObj.responsibilityDetails}
                                                />
                                            </div>
                                            <span className="error-message">{errors['responsibilityDetails']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={ this.onInsertUserExperienceInfo }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog id="dialog" className="nw-dialog" header="Edit Professional Qualification" visible={this.state.visibleEditDialog} onHide={this.onChangeEditDialogHide} maximizable>
                    <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Company Name <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Company Name"
                                                    name="companyName"
                                                    value={ updateUserObj.companyName }
                                                    onChange= { e => this.onChageCompanyName(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['companyName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Company Business <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Company Business"
                                                    name="companyBusiness"
                                                    value={ updateUserObj.companyBusiness }
                                                    onChange= { e => this.onChageCompanyBusiness(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['companyBusiness']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Designation <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Designation"
                                                    name="designationName"
                                                    value={ updateUserObj.designationName }
                                                    onChange={ e => this.onChageDesignationName(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['designationName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Department <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Department"
                                                    name="workingDepartment"
                                                    value={ updateUserObj.workingDepartment }
                                                    onChange= { e => this.onChageWorkingDepartment(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['workingDepartment']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Company Location <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Company Location"
                                                    name="companyLocation"
                                                    value={ updateUserObj.companyLocation }
                                                    onChange= { e => this.onChageCompanyLocation(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['companyLocation']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Country <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Country"
                                                    onChange= { e => this.onChageCountryName(e, 'update') }
                                                    value={ updateUserObj.countryInfoDTO.coreCategoryID }
                                                    options={countryName}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="countryName"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['countryName']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                                <Calendar
                                                    id="startDate"
                                                    name="startDate"
                                                    showIcon={true}
                                                    value={ updateUserObj.employmentStartEdit}
                                                    onChange={ e => this.onChangeStartDate(e, 'update')}
                                                    placeholder='Select Date'
                                                    className="custom-calender-input"
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                />
                                            </div>
                                            <span className="error-message">{errors['employmentStart']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                                <Calendar
                                                    id="startDate"
                                                    name="startDate"
                                                    showIcon={true}
                                                    value={ updateUserObj.employmentEndEdit}
                                                    onChange={ e => this.onChangeEndDate(e, 'update')}
                                                    placeholder='Select Date'
                                                    className="custom-calender-input"
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                />
                                            </div>
                                            <span className="error-message">{errors['employmentEnd']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Responsibilities <span>*</span></span>
                                                <InputTextarea
                                                    name="responsibilityDetails"
                                                    placeholder="Enter Note"
                                                    onChange={ e => this.onChageResponsibilityDetails(e, 'update') }
                                                    value={updateUserObj.responsibilityDetails}
                                                />
                                            </div>
                                            <span className="error-message">{errors['responsibilityDetails']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={ this.onUpdateUserExperienceInfo }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </Dialog>
                </div>
            </div>
        )
    }
} 