import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { WalletBalance } from "../common/WalletBalance";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { MessageRechargeService } from "../../../service/myPoint/MessageRechargeService";
import { Growl } from "primereact/growl";
import { NetiDateUtils } from "../../../utils/NetiDateUtils";
import { MY_POINT } from "../../../utils/PointWiseRouteConsts";
import NetiContentLoader from "../../common/NetiContentLoader";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";

export class MessageRecharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balanceMsgRechargeErr: {},
      messageRechargeData: {
        quantity: 0,
        productInfoDTO: {
          productID: "",
        },
        productPurchaseLogDTO: {
          productRoleAssignDTO: {
            productRoleAssignID: "",
          },
        },
      },
      msgtypes: [],
      msgtypeObj: {
        salesPrice: 0,
        percentVat: 0,
      },
      value: null,
      purchasePoint: [],
      purchasePointValue: "",

      calCulatedObj: {
        quantity: "",
        totalPrice: 0,
        vatAmount: 0,
        payableAmount: 0,
      },
      msgtypevalue: "",
      dataTableValue: [],
      dataTableIsLoading: false,
      walletIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: "",
      firstDropdownIsLoading: false,
      secondDropdownIsLoading: false,
    };
    this.onPurchaseTypeChange = this.onPurchaseTypeChange.bind(this);
    this.onMsgtypeChange = this.onMsgtypeChange.bind(this);
    this.handleMessageType = this.handleMessageType.bind(this);
    this.MessageRechargeService = new MessageRechargeService();
    this.calculatePayableAmount = this.calculatePayableAmount.bind(this);
    this.NetiContentLoader = new NetiContentLoader();
    this.validatorUtility = new ValidatorUtility();
  }

  async componentWillMount() {
    this.showUserTopTenMessageRechargeList();
    // this.fetchPurchasePointInfo();
    this.fetchMessageTypeInfo();
  }

  fetchMessageTypeInfo() {
    this.setState({
      topProgressBar: true,
      firstDropdownIsLoading: true,
      errorMsgVisible: false,
    });
    this.MessageRechargeService.getMessageTypeWithoutRole()
      .then((res) => {
        this.setState({ topProgressBar: false });
        if (res.status == 302) {
          return res.json().then((body) => {
            this.setState({
              msgtypes: body,
              topProgressBar: false,
              firstDropdownIsLoading: false,
            });
          });
        } else {
          this.MessageRechargeService.Auth.handleErrorStatus(res).then(
            (responseBody) => {
              this.setState({
                topProgressBar: false,
                firstDropdownIsLoading: false,
                errorMsgVisible: true,
                errorMsgBody: responseBody,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          firstDropdownIsLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection !!!",
        });
      });
  }

  fetchPurchasePointInfo() {
    this.setState({
      topProgressBar: true,
      firstDropdownIsLoading: true,
      errorMsgVisible: false,
    });
    this.MessageRechargeService.getPurchasePoint()
      .then((res) => {
        this.setState({ topProgressBar: false });
        if (res.status == 302) {
          return res.json().then((body) => {
            this.setState({
              purchasePoint: body,
              topProgressBar: false,
              firstDropdownIsLoading: false,
            });
          });
        } else {
          this.MessageRechargeService.Auth.handleErrorStatus(res).then(
            (responseBody) => {
              this.setState({
                topProgressBar: false,
                firstDropdownIsLoading: false,
                errorMsgVisible: true,
                errorMsgBody: responseBody,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          firstDropdownIsLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection !!!",
        });
      });
  }

  onPurchaseTypeChange(e) {
    let { balanceMsgRechargeErr } = this.state;
    this.clearErrorMessage(e.target.name);

    if (e.target.value) {
      this.setState({
        purchasePointValue: e.target.value,
        topProgressBar: true,
        secondDropdownIsLoading: true,
        errorMsgVisible: false,
      });
      this.MessageRechargeService.getMessageType(e.target.value)
        .then((res) => {
          if (res.status == 302) {
            return res.json().then((body) => {
              this.setState({
                msgtypes: body,
                topProgressBar: false,
                secondDropdownIsLoading: false,
              });
            });
          } else {
            this.MessageRechargeService.Auth.handleErrorStatus(res).then(
              (resp) => {
                this.setState({
                  topProgressBar: false,
                  secondDropdownIsLoading: false,
                  errorMsgVisible: true,
                  errorMsgBody: resp,
                });
              }
            );
          }
        })
        .catch((error) => {
          this.setState({
            topProgressBar: false,
            secondDropdownIsLoading: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection !!!",
          });
        });
    } else {
      this.setState({
        purchasePointValue: e.target.value,
        msgtypes: [],
        msgtypevalue: null,
      });
    }
  }

  onMsgtypeChange(e) {
    this.setState({ msgtypevalue: e.value });
    let {
      messageRechargeData,
      msgtypeObj,
      msgtypes,
      calCulatedObj,
    } = this.state;
    msgtypes.map((m) => {
      if (m.productId == e.value.productId) {
        const obj = {
          salesPrice: m.salesPrice,
          percentVat: m.vatPercent,
        };
        msgtypeObj = obj;
      }
    });
    calCulatedObj = {};
    messageRechargeData.productInfoDTO.productID = e.value.productId;
    this.setState({ messageRechargeData, msgtypeObj, calCulatedObj });
  }

  handleMessageType() {}

  onSubmitHandler = (e) => {
    let { messageRechargeData } = this.state;
    messageRechargeData.quantity = this.state.calCulatedObj.quantity;
    messageRechargeData.productPurchaseLogDTO.productRoleAssignDTO.productRoleAssignID = this.state.msgtypevalue.productRoleAssignId;

    if (this.rechargeMessageFormHandleError()) {
      this.setState({
        topProgressBar: true,
        dataTableIsLoading: true,
        errorMsgVisible: false,
      });
      this.MessageRechargeService.sendMessageRecharge(messageRechargeData)
        .then((res) => {
          if (res.status == 201) {
            this.setState({
              topProgressBar: false,
              dataTableIsLoading: false,
              errorMsgVisible: false,
            });
            this.growl.show({
              severity: "success",
              summary: "Success Message",
              detail: "Successfully Submitted",
            });
            this.showUserTopTenMessageRechargeList();
          } else {
            this.MessageRechargeService.Auth.handleErrorStatus(res).then(
              (responseBody) => {
                this.setState({
                  errorMsgVisible: true,
                  errorMsgBody: responseBody,
                  topProgressBar: false,
                  dataTableIsLoading: false,
                });
              }
            );
          }
        })
        .catch((error) => {
          this.setState({
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection !!!",
          });
        });
    }
  };

  calculatePayableAmount(e) {
    let { msgtypeObj, calCulatedObj } = this.state;
    this.clearErrorMessage(e.target.name);

    calCulatedObj.quantity = e.target.value;
    calCulatedObj.totalPrice = msgtypeObj.salesPrice * calCulatedObj.quantity;
    calCulatedObj.vatAmount =
      (msgtypeObj.percentVat * calCulatedObj.totalPrice) / 100;
    calCulatedObj.payableAmount =
      calCulatedObj.totalPrice + calCulatedObj.vatAmount;
    this.setState({ calCulatedObj });
  }

  rechargeMessageFormHandleError = () => {
    let { balanceMsgRechargeErr } = this.state;
    let formIsValid = true;
    this.setState({ topProgressBar: false });

    if (this.state.msgtypevalue === "" || this.state.msgtypevalue === null) {
      formIsValid = false;
      balanceMsgRechargeErr["messageType"] = "Message type can't left empty.";
    }
    if (this.state.calCulatedObj.quantity === "") {
      formIsValid = false;
      balanceMsgRechargeErr["messageQuantity"] =
        "Message quantity can't left empty.";
    }
    this.setState({ balanceMsgRechargeErr });
    return formIsValid;
  };

  clearErrorMessage = (errorProperty) => {
    let { balanceMsgRechargeErr } = this.state;
    balanceMsgRechargeErr[errorProperty] = "";
    this.setState({ balanceMsgRechargeErr });
  };

  showUserTopTenMessageRechargeList = () => {
    this.setState({
      topProgressBar: true,
      dataTableIsLoading: true,
      errorMsgVisible: false,
    });
    this.MessageRechargeService.getUserTopTenMessageRechargesList("Recharge")
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            for (let i = 0; i < body.length; i++) {
              body[i].trxDate = NetiDateUtils.getAnyShortForm(
                body[i].trxDate,
                "DD-MMM-YYYY hh:mm:ss a"
              );
            }
            this.setState({
              dataTableValue: body,
              topProgressBar: false,
              dataTableIsLoading: false,
            });
          });
        } else {
          this.MessageRechargeService.Auth.handleErrorStatus(res).then(
            (resp) => {
              this.setState({
                topProgressBar: false,
                dataTableIsLoading: false,
                errorMsgVisible: true,
                errorMsgBody: resp,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          dataTableIsLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection !!!",
        });
      });
  };
  totalAmountBody = (rowData) => {
    return (
      <div className="col-number text-right">
        {" "}
        {this.validatorUtility.currencyFormatter(
          rowData.productPurchaseLogDTO.totalAmount
        )}
      </div>
    );
  };

  msgQuantityBody = (rowData) => {
    return <b> {rowData.quantity.toLocaleString("EN-IN")}</b>;
  };

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    let purchasePointOptions = [];
    if (this.state.purchasePoint) {
      purchasePointOptions = this.state.purchasePoint.map((item) => ({
        value: item.coreRoleID,
        label: item.coreRoleName,
      }));
    }
    let msgtypeOptions = [];
    let { msgtypeObj, calCulatedObj } = this.state;

    if (this.state.msgtypes) {
      msgtypeOptions = this.state.msgtypes.map((item) => ({
        value: item,
        label: item.productName,
      }));
    }

    let depositLinkText = "deposit-link-text";

    // let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
    //     Message Recharge List
    //                     <Link style={{ color: "white", display: "flex", float: "right" }} to={{ pathname: MY_POINT.MESSAGE_LOGS, querydeposittext: depositLinkText }}>
    //     More<i className="fas fa-angle-right" style={{ "fontSize": "28px", "paddingLeft": "5px" }}></i>
    //   </Link>
    // </div>;

    let header = (
      <div className="header-title">
        <div className="header-title-left">Message Recharge List</div>

        <div className="header-title-right">
          <Link
            to={{
              pathname: MY_POINT.MESSAGE_LOGS,
              querydeposittext: depositLinkText,
            }}
          >
            More<i className="fas fa-angle-right"></i>
          </Link>
        </div>
      </div>
    );

    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <Growl ref={(el) => (this.growl = el)} />

        <div className="main-section">
          <div className="p-grid nw-form">
            <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
              <div className="p-col-12 p-xl-12">
                <div className="nw-form-body">
                  <div className="p-grid seprator-inside">
                    {/* <div className="p-col-12 p-xl-12">
                      {this.state.firstDropdownIsLoading ? (
                        this.NetiContentLoader.normalFormInputField()
                      ) : (
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Purchase Point <span>*</span>
                          </span>
                          <Dropdown
                            value={this.state.purchasePointValue}
                            options={purchasePointOptions}
                            onChange={this.onPurchaseTypeChange}
                            filter={true}
                            filterPlaceholder="Search Purchase point"
                            placeholder="Select Purchase point"
                            filterBy="label,value"
                            showClear={true}
                            style={{ width: "100%" }}
                            name="purchasePoint"
                          />
                        </div>
                      )}
                      <span className="error-message">
                        {this.state.balanceMsgRechargeErr["purchasePoint"]}
                      </span>
                    </div> */}
                    <div className="p-col-12 p-xl-12">
                      {this.state.firstDropdownIsLoading ? (
                        this.NetiContentLoader.normalFormInputField()
                      ) : (
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            {" "}
                            Message Type <span>*</span>
                          </span>
                          <Dropdown
                            value={this.state.msgtypevalue}
                            options={msgtypeOptions}
                            onChange={this.onMsgtypeChange}
                            // filter={true}
                            filterPlaceholder="Search Message Type"
                            placeholder="Select Message Type"
                            filterBy="label,value"
                            showClear={true}
                            name="messageType"
                            style={{ width: "100%" }}
                          />
                        </div>
                      )}
                      <span className="error-message">
                        {this.state.balanceMsgRechargeErr["messageType"]}
                      </span>
                    </div>
                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Message Quantity <span>*</span>
                        </span>
                        <InputText
                          value={this.state.calCulatedObj.quantity}
                          onChange={this.calculatePayableAmount}
                          style={{ width: "100%" }}
                          name="messageQuantity"
                          placeholder="Ex: 10"
                        />
                      </div>
                      <span className="error-message">
                        {this.state.balanceMsgRechargeErr["messageQuantity"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12 ">
                      <div className="nw-circle-panel">
                        <div className="circle-panel circle-panel-first">
                          ৳<br />
                          {isNaN(msgtypeObj.salesPrice)
                            ? "0.00"
                            : this.validatorUtility.currencyFormatter(
                                msgtypeObj.salesPrice
                              )}
                          <div className="circle-panel-label">Unit Price</div>
                        </div>
                        <div className="circle-panel circle-panel-second">
                          ৳<br />
                          {isNaN(calCulatedObj.totalPrice)
                            ? "0.00"
                            : this.validatorUtility.currencyFormatter(
                                calCulatedObj.totalPrice
                              )}
                          <div className="circle-panel-label">Total Price</div>
                        </div>
                        <div className="circle-panel circle-panel-third">
                          ৳<br />
                          {isNaN(calCulatedObj.vatAmount)
                            ? "0.00"
                            : this.validatorUtility.currencyFormatter(
                                calCulatedObj.vatAmount
                              )}
                          <div className="circle-panel-label">Vat Amount</div>
                        </div>
                        <div className="circle-panel circle-panel-fourth">
                          ৳<br />
                          {isNaN(calCulatedObj.payableAmount)
                            ? "0.00"
                            : this.validatorUtility.currencyFormatter(
                                calCulatedObj.payableAmount
                              )}
                          <div className="circle-panel-label">
                            Payable Amount
                          </div>
                        </div>

                        <div className="circle-panel-hr"></div>

                        <div className="circle-panel circle-panel-fifth">
                          <Button
                            className="nw-button p-button-primary nw-button-center"
                            label="Recharge Now"
                            onClick={this.onSubmitHandler}
                            icon="fas fa-check"
                          ></Button>
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12 ">
                      <h4 style={{ paddingBottom: "20px", color: "blue" }}>
                        (<span style={{ color: "red" }}>*</span>) required
                        fields
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-col-12 p-xl-4">
              <div className="layout-dashboard">
                {this.state.dataTableIsLoading ? (
                  ""
                ) : (
                  <WalletBalance
                    walletBalanceName="Wallet"
                    messageBalanceName="Message"
                  />
                )}
              </div>
              <br />
            </div>

            <div className="p-col-12 p-xl-12 p-col-nogutter">
              <div className="nw-data-table">
                {this.state.dataTableIsLoading ? (
                  this.NetiContentLoader.MyPointTableLoader()
                ) : (
                  <DataTable value={this.state.dataTableValue} header={header}>
                    <Column
                      field="trxDate"
                      className="table-datetime"
                      header="Date & Time"
                    />
                    <Column
                      field="productInfoDTO.productName"
                      header="Message Type"
                    />
                    <Column
                      field="quantity"
                      header="Message Quantity"
                      body={this.msgQuantityBody}
                    />
                    <Column
                      field=""
                      header="Payable Amount"
                      body={this.totalAmountBody}
                    />
                  </DataTable>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
