import React, { Component } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/components/button/Button';
import { Fieldset } from 'primereact/fieldset';
import { WalletBalance } from '../common/WalletBalance'
import { ProfileService } from '../../../service/profile/ProfileService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ProgressBar } from 'primereact/progressbar';
import { profileImage } from '../../../assets/images/images.jpg';
import Rating from 'react-rating';
import {Growl} from 'primereact/growl';



export class ViewProfile extends Component {
    constructor(props) {
        super(props);
        this.mainWrapperWidth = React.createRef();
        this.PersonalDivHeight = React.createRef();
        this.addressDivHeight = React.createRef();
        this.infoWrapperHeight = React.createRef();
        this.logReportWrapperHeight = React.createRef();
        // this.fullDivWidth = React.createRef();
        this.state = {
            tasks: [],
            city: null,
            profileFullHeight: '',
            equaleHeight: '',
            mainDivWidth: '',
            topProgressBar:false,
            errorMsgVisible: false,
            errorMsgBody: '',
            selectedCar: null,
            progressbarStartValue: 0,
            userInfo: {

            },
            userBankInfo: {},
            profilePicture: '',
            menuItems: [
                {
                    label: 'Edit', icon: 'fa fa-fw fa-edit', command: () => { this.props.history.replace('/update-profile'); }
                },
                {
                    label: 'Update', icon: 'fa fa-fw fa-refresh'
                },
                {
                    label: 'Delete', icon: 'fa fa-fw fa-trash'
                }
            ]
        };

        this.profileService = new ProfileService();
        this.showWarn = this.showWarn.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.getProfileImg = this.getProfileImg.bind(this);

    }

    showWarn() {
        let msg = { severity: 'warn', summary: 'Warn Message', detail: 'There are unsaved changes', stiky: true };
        this.messages.show(msg);
    }

    componentWillMount() {
        this.getUserInformation();
        this.getUserBankInfo();
    }

    componentDidMount() {
        // this.interval = setInterval(() => {
        //     let startVal = this.state.progressbarStartValue * (100 / this.state.progressbarEndValue);
        //     console.log("this.state.progressbarStartValue",this.state.progressbarStartValue);
        //     console.log("this.state.progressbarEndValue",this.state.progressbarEndValue);
        //     console.log("startVal",Math.round(startVal));


        //     if (this.state.progressbarStartValue >= this.state.progressbarEndValue) {
        //         this.state.progressbarStartValue = this.state.progressbarEndValue;
        //         clearInterval(this.interval);
        //     }

        //     this.setState({
        //         progressbarStartValue: startVal
        //     });
        // }, 2000);

        let PersonalcurrerntDivHeight = this.PersonalDivHeight.current.clientHeight;
        let addressCurrentDivHeight = this.addressDivHeight.current.clientHeight;
        let logReportWrapperCurrentHeight = this.logReportWrapperHeight.current.clientHeight;
        let mainWrapperCurrentWidth = this.mainWrapperWidth.current.clientWidth;



        let profileHeight = addressCurrentDivHeight + logReportWrapperCurrentHeight - 7;

        if (mainWrapperCurrentWidth > 1500) {
            if (profileHeight) {
                this.setState({ profileFullHeight: profileHeight });
            }
        } else {
            this.setState({ profileFullHeight: profileHeight + PersonalcurrerntDivHeight + 20 });
        }



        if (PersonalcurrerntDivHeight > addressCurrentDivHeight) {
            this.setState({ equaleHeight: PersonalcurrerntDivHeight });
        } else {
            this.setState({ equaleHeight: addressCurrentDivHeight });
        }

    }

    getUserInformation = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });

        this.profileService.getUserProfileDetails()
            .then((res) => {
                if (res.status === 200) {
                    return res.json().then((body) => {
                        let userInfoObjEndLimit = Object.values(body);
                        let userInfoObjStartLimit = userInfoObjEndLimit.filter(item => item != null && item != '').length;
                        let startVal = userInfoObjStartLimit * (100 / userInfoObjEndLimit.length);
                        this.setState({ progressbarStartValue: Math.round(startVal) });
                        this.setState({ userInfo: body, topProgressBar: false, errorMsgVisible: false });
                        this.getProfileImg();
                    });
                } else {
                    this.profileService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
            });

    }


    getUserBankInfo = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });

        this.profileService.getBankAccInfoByUser()
            .then((res) => {

                if (res.status === 200) {
                    return res.json().then((body) => {
                        this.setState({ userBankInfo: body, topProgressBar: false, errorMsgVisible: false });
                        // this.getProfileImg();
                    });
                } else {
                    this.profileService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
            });

    }





    getProfileImg() {
        let { userInfo } = this.state;
        let staticImg = '/assets/images/images.jpg';

        if (userInfo.imagePath !== null) {
            this.netiFileHandler.getByteImage(userInfo.imagePath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(userInfo.imageName);
                                this.setState({ profilePicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ profilePicture: staticImg });
                    }
                });
        } else {
            this.setState({ profilePicture: staticImg });
        }
    }

    render() {
        let { userInfo } = this.state;
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let missingDataInProfile = "Please update your profile";
        let userEnableStatus, statusBGclass;

        // if (userInfo.userEnableStatus === 0) {
        //     userEnableStatus = "DisActive";
        //     statusBGclass = "statusBGclassDisActive";
        // }
        // else if (userInfo.userEnableStatus === 1) {
        //     userEnableStatus = "Active";
        //     statusBGclass = "statusBGclassActive"
        // }
        return (
            <div className="main-section netiWorld-DashBoard-wrapper" ref={this.mainWrapperWidth}>
                <div className="netiWorld-DashBoard">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <Growl ref={(el) => this.growl = el} />
                    <div className="p-grid m-0">
                        <div class="p-col-12 p-md-12 p-xl-12">
                            <div class="panel-box p-0">
                                <div class="panel-box-header">
                                    <h1 className="welcome-emotion m-b-0">Welcome {userInfo.username}! </h1>
                                    <span className="profile-link">Complete Profile</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid m-0">
                        <div class="p-col-12 p-md-12 p-xl-12 gray-bg m-b-30">
                            <div class="panel-box p-10">
                                <ProgressBar value={this.state.progressbarStartValue} className="profile-progress-bar"></ProgressBar>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid custom-profile-grid m-0">
                        <div class="p-col-12 p-md-12 p-xl-3 p-0">
                            <div className="seprator-inside">
                                <div className="gradient-color-dark-blue personalProfileEqual" style={{ minHeight: this.state.profileFullHeight }} >
                                    <div class="panel-box  notify-box-wrapper-bg-image gradient-color-dark-blue m-b-20 p-t-45">
                                        <div className="profile-image full-profile">
                                            <img src={userInfo.imagePath ? this.state.profilePicture : '/assets/images/images.jpg'} alt="neti-profile-image" name="neti-profile-image" />
                                        </div>
                                        <div className="profile-rating m-b-20">
                                            <Rating
                                                emptySymbol="far fa-star fa-2x"
                                                fullSymbol="fas fa-star fa-2x"
                                                initialRating={1.40}
                                                readonly
                                            />
                                            <span className="rating-word">1.4</span>
                                        </div>
                                        <div className="profile-name">
                                            <hr />
                                            <div className="p-b-20 p-t-20">
                                                <p className="text-white font-20 m-b-20 font-weight-normal">{userInfo.fullName}</p>
                                                <span className="profile-designation font-16 text-white"><b>Neti ID: {userInfo.customNetiID}</b></span>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-xl-9 p-0">
                            <div className="p-grid">
                                <div class="p-col-12 p-md-12 p-xl-4" ref={this.PersonalDivHeight}>
                                    <div className="gray-bg" style={{ minHeight: this.state.equaleHeight }}>
                                        <div className="panel-box">
                                            <div className="panel-box-header">
                                                <h1>Personal Info</h1>
                                            </div>
                                            <div className="profile-table profile-border-table">
                                                <div className="panel-box p-0">
                                                    <div className="profile-table profile-border-table">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Neti Id</td>
                                                                    <td><p>{userInfo.customNetiID}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mobile No.</td>
                                                                    <td><p>{userInfo.basicMobile}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email</td>
                                                                    <td><p>{userInfo.basicEmail}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Status</td>
                                                                    <td><p></p></td>
                                                                </tr>
                                                                {userInfo.leadName ?
                                                                    <tr>
                                                                        <td>Lead Name</td>
                                                                        <td><p>{userInfo.leadName}</p></td>
                                                                    </tr>
                                                                    :
                                                                    ''
                                                                }
                                                                {userInfo.leadCustomNetiID ?
                                                                    <tr>
                                                                        <td>Led Neti ID</td>
                                                                        <td><p>{userInfo.leadCustomNetiID}</p></td>
                                                                    </tr>
                                                                    :
                                                                    ''
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-12 p-xl-8" ref={this.addressDivHeight}>
                                    <div className="clearfix gray-bg" style={{ minHeight: this.state.equaleHeight }}>
                                        <div className="p-grid m-0">
                                            <div class="p-col-12 p-md-12 p-xl-3 p-0">
                                                <div className="panel-box p-b-0">
                                                    <div className="panel-box-header">
                                                        <h1 className="m-b-0">Address Info</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-12 p-xl-9 p-0">
                                                <div className="panel-box">
                                                    <div className="profile-table profile-border-table">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Village/Area</td>
                                                                    <td><p>{userInfo.area}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Upazilla/Thana</td>
                                                                    <td><p>{userInfo.upazilla}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>District</td>
                                                                    <td><p>{userInfo.district}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Division</td>
                                                                    <td><p>{userInfo.division}</p></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid m-0">
                                            <div class="p-col-12 p-md-12 p-xl-3 p-0">
                                                <div className="panel-box p-b-0">
                                                    <div className="panel-box-header">
                                                        <h1 className="m-b-0">User Info</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-12 p-xl-9 p-0">
                                                <div className="panel-box ">
                                                    <div className="profile-table profile-border-table">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Username</td>
                                                                    <td><p>{userInfo.username}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Status</td>
                                                                    <td><p>{userInfo.userStatus}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Reg. Date</td>
                                                                    <td><p>{NetiDateUtils.getAnyShortForm(userInfo.registrationDate, 'DD-MMM-YYYY hh:mm:ss a')}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Use From</td>
                                                                    <td><p>{NetiDateUtils.getAnyShortForm(userInfo.registrationDate, 'DD-MMM-YYYY hh:mm:ss a')}</p></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-grid p-t-05" ref={this.logReportWrapperHeight}>
                                <div class="p-col-12 p-md-12 p-xl-6">
                                    <div className="panel-box gray-bg">
                                        <div className="panel-box-header">
                                            <h1>Log Report (Current Month)</h1>
                                        </div>
                                        <div className="p-grid custom-notify-grid">
                                            <div className="p-col-12 p-sm-12 p-md-4">
                                                <div className="notify-box-wrapper gradient-color-light-pink">
                                                    <div className="notify-box-wrapper-bg-image boxHeight-165">
                                                        <div className="notify-box p-t-0 clearfix boxHeight-165">
                                                            <div className="notify-flex boxHeight-165">
                                                                <div className="notify-box-title text-center">
                                                                    <div className="notify-box-icon count-icon"></div>
                                                                    <h1 className="font-16 color-white"><b>Click Counts</b></h1>
                                                                    <div className="notify-currency">
                                                                        <p className="font-20 color-white">97</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-4">
                                                <div className="notify-box-wrapper gradient-color-light-cyan">
                                                    <div className="notify-box-wrapper-bg-image boxHeight-165">
                                                        <div className="notify-box p-t-0 clearfix boxHeight-165">
                                                            <div className="notify-flex boxHeight-165">
                                                                <div className="notify-box-title text-center">
                                                                    <div className="notify-box-icon window-icon"></div>
                                                                    <h1 className="font-16 color-white"><b>Pages Access</b></h1>
                                                                    <div className="notify-currency">
                                                                        <p className="font-20 color-white">132</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-sm-12 p-md-4">
                                                <div className="notify-box-wrapper gradient-color-orange">
                                                    <div className="notify-box-wrapper-bg-image boxHeight-165">
                                                        <div className="notify-box p-t-0 clearfix boxHeight-165">
                                                            <div className="notify-flex boxHeight-165">
                                                                <div className="notify-box-title text-center">
                                                                    <div className="notify-box-icon map-icon"></div>
                                                                    <h1 className="font-16 color-white"><b>Point Access</b></h1>
                                                                    <div className="notify-currency">
                                                                        <p className="font-20 color-white">52,000</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="p-col-12 p-md-12 p-xl-6">
                                    <div className="panel-box gray-bg">
                                        <div className="panel-box-header">
                                            <h1>Active Bank Info</h1>
                                            <div className="panel-box-header-btn">
                                                <span><i class="fas fa-info-circle"></i></span>
                                            </div>
                                            <div className="profile-table profile-border-table">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>Username</td>
                                                            <td><p>lubaba1232</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Status</td>
                                                            <td><p>Active</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Reg. Date</td>
                                                            <td><p>September 23, 2019</p></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Use From</td>
                                                            <td><p>October 23, 2019</p></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}