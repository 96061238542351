import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Growl } from "primereact/growl";
import { ProductOfferUpdate } from './ProductOfferUpdate';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from "../../common/ErrorMessageView";
import NetiContentLoader from '../../common/NetiContentLoader';
import Formatter from '../../../utils/Formatter';
import CommonFuctionality from '../../common/CommonFuctionality';


let showStatus = false;

export class ProductOffer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            productTypeList: '',
            productType: '',
            productList: '',
            productName: '',
            userRoleList: '',
            userRole: '',
            offerQuantity: '',
            discountPercentage: '',
            usableTime: '',
            startDate: '',
            expireDate: '',
            offerCode: '',
            note: '',
            visible: false,
            selectedData: '',
            dataTableValue: [],
            selectedDataView: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            thirdDropdownIsLoading: false,
            dataTableIsLoading: false,
            topProgressBar: false

        }

        this.onChangeProductType = this.onChangeProductType.bind(this);
        this.onChangeProductName = this.onChangeProductName.bind(this);
        this.onChangeUserRole = this.onChangeUserRole.bind(this);

        this.onChangeOfferQuantity = this.onChangeOfferQuantity.bind(this);
        this.onChangeDiscountPercentage = this.onChangeDiscountPercentage.bind(this);

        this.onChangeUsableTime = this.onChangeUsableTime.bind(this);
        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.onChangeExpireDate = this.onChangeExpireDate.bind(this);
        this.onChangeOfferCode = this.onChangeOfferCode.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);

        this.submitSave = this.submitSave.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);

        this.userCategoryService = new UserCategoryService();
        this.adminPointService = new AdminPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.formatter = new Formatter();
        this.NetiContentLoader = new NetiContentLoader();
        this.fetchProductOfferList = this.fetchProductOfferList.bind(this);
        this.CommonFuctionality = new CommonFuctionality();
    }

    componentWillMount() {

        this.fetchProductTypeList('T104');
        this.fetchProductOfferList();
    }

    onChangeProductType(value) {
        this.state.errors["productType"] = "";
        this.setState({ productType: value });
        this.fetchProductList(1, value);
    }

    onChangeProductName(value) {
        this.state.errors["productName"] = "";
        this.setState({ productName: value });
        this.onChangeLoadProductRolesByProduct(value);
    }

    onChangeUserRole(value) {
        this.state.errors["userRole"] = "";
        this.setState({ userRole: value });
    }

    onChangeOfferQuantity(value) {
        this.state.errors["offerQuantity"] = "";
        let intValue = parseInt(value)
        if (isNaN(intValue)) {
            intValue = ''
        }
        this.setState({ offerQuantity: intValue });
    }

    onChangeDiscountPercentage(value) {
        this.state.errors["discountPercentage"] = "";
        let fixedValue = this.formatter.getFixedDecimalNumber(value)
        this.setState({ discountPercentage: fixedValue });
    }

    onChangeUsableTime(value) {
        this.state.errors["usableTime"] = "";
        this.setState({ usableTime: value });
    }

    onChangeStartDate(value) {
        this.state.errors["startDate"] = "";
        this.setState({ startDate: value });
    }

    onChangeExpireDate(value) {
        this.state.errors["expireDate"] = "";
        this.setState({ expireDate: value });
    }

    onChangeOfferCode(value) {
        this.state.errors["offerCode"] = "";
        this.setState({ offerCode: value });
    }

    onChangeNote(value) {
        this.state.errors["note"] = "";
        this.setState({ note: value });
    }

    onChangeLoadProductRolesByProduct(productID) {
        this.setState({ topProgressBar: true, thirdDropdownIsLoading: true, errorMsgVisible: false })
        let product = { 'productID': productID };
        this.adminPointService.fetchListOfProductRoleAssignByProduct(product, 1)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ userRoleList: body, topProgressBar: false, thirdDropdownIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch(error =>
                this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }

    submitSave() {
        showStatus = true;
        this.productOfferConfig();
    }

    fetchProductTypeList(defaultCode) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productTypeList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    })
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }

    fetchProductList(enableStatus, productTypeId) {
        let productTypeInfoDTO = {
            "coreCategoryID": productTypeId,
        }
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchProductListByProductType(enableStatus, productTypeInfoDTO)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch(error =>
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }

    fetchProductOfferList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchtOfferProductList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        console.log('Log: ProductOffer -> fetchProductOfferList -> body', body)

                        body.map(item => {
                            if (item.offerStartDate) {
                                item.offerStartDate = NetiDateUtils.getAnyShortForm(item.offerStartDate, 'DD-MMM-YYYY');
                            } else {
                                item.offerStartDate = ''
                            }

                            if (item.offerExpiryDate) {
                                item.offerExpiryDate = NetiDateUtils.getAnyShortForm(item.offerExpiryDate, 'DD-MMM-YYYY');
                            } else {
                                item.offerExpiryDate = ''
                            }


                        })



                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }

    productOfferConfig() {

        let requestedObj = {

            "offerCode": this.state.offerCode,
            "discountPercent": this.state.discountPercentage,
            "offerStartDate": this.state.startDate,
            "offerExpiryDate": this.state.expireDate,
            "offerQuantity": this.state.offerQuantity,
            "offerUseableTime": this.state.usableTime,
            "offerNote": this.state.note,
            "productRoleAssignDTO": {
                "productRoleAssignID": this.state.userRole,
                "productInfoDTO": {
                    "productID": this.state.productName,
                    "productTypeInfoDTO": {
                        "coreCategoryID": this.state.productType,
                    },
                },

            }
        }
        if (this.handleProductOfferConfigError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            this.adminPointService.productOfferConfig(requestedObj)
                .then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data save successful.' });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false })
                        this.fetchProductOfferList();
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
        }
    }

    handleProductOfferConfigError = () => {

        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.productType === '') {
            formIsValid = false;
            errors["productType"] = "Product Type can't left empty.";
        }
        if (this.state.productName === '') {
            formIsValid = false;
            errors["productName"] = "Product Name can't left empty.";
        }
        if (this.state.userRole === '') {
            formIsValid = false;
            errors["userRole"] = "User role can't left empty.";
        }
        if (this.state.offerQuantity === '') {
            formIsValid = false;
            errors["offerQuantity"] = "Offer quantity can't left empty.";
        }
        if (this.state.discountPercentage === '') {
            formIsValid = false;
            errors["discountPercentage"] = "Discount price can't left empty.";
        }

        if (this.state.usableTime === '') {
            formIsValid = false;
            errors["usableTime"] = "Usable time can't left empty.";
        }
        if (this.state.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start date can't left empty.";
        }
        if (this.state.expireDate === '') {
            formIsValid = false;
            errors["expireDate"] = "Expiry date can't left empty.";
        }
        if (this.state.offerCode === '') {
            formIsValid = false;
            errors["offerCode"] = "Offer Code can't left empty.";
        }

        if (this.state.note === '') {
            formIsValid = false;
            errors["note"] = "Note can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;

    }

    editBody = (rowData) => {
        return <div className='text-center'>
            <Button
                className="p-button p-component p-button-text-only nw-action-button"
                icon="fas fa-pencil-alt"
                title="Edit"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    viewDialog(rowData) {
        this.setState({
            selectedData: rowData,
            visible: true,
            selectedDataView: true
        });
        this.CommonFuctionality.blurDialogBackgroundActive();

        // rowData.offerStartDate = NetiDateUtils.getAnyShortForm(rowData.offerExpiryDate, 'DD-MM-YYYY');

        // this.setState({ visible: true });
        // let { updateEventInfoDetails } = this.state
        // let { coreUrlInfoDTO } = this.state.updateEventInfoDetails

        // updateEventInfoDetails.eventID = rowData.eventID
        // updateEventInfoDetails.eventTitle = rowData.eventTitle
        // updateEventInfoDetails.eventType = rowData.eventType
        // updateEventInfoDetails.eventDetails = rowData.eventDetails
        // updateEventInfoDetails.eventStartDate = NetiDateUtils.getDateFromStringDDMMYYYY(rowData['eventStartDate'])
        // updateEventInfoDetails.eventEndDate = NetiDateUtils.getDateFromStringDDMMYYYY(rowData['eventEndDate'])
        // updateEventInfoDetails.coreUrlInfoDTO.urlInfoID = rowData.coreUrlInfoDTO.urlInfoID;

        // this.setState({ updateEventInfoDetails });
        // this.setState({ coreUrlInfoDTO });





    }

    onHide() {
        this.setState({
            visible: false,
            selectedDataView: false
        });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="header-title">
                                <div className="header-title-left">Product Offer List</div>

                                <div className="header-title-right">
                                    <a className="total-found">
                                        Total Found: {this.state.dataTableValue.length}
                                    </a>
                                </div>
                            </div>;
        let productTypeOptions = [];
        if (this.state.productTypeList && this.state.productTypeList.length) {
            productTypeOptions = this.state.productTypeList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let productListOptions = [];
        if (this.state.productList && this.state.productList.length) {
            productListOptions = this.state.productList.map(item => ({
                value: item.productID,
                label: item.productName
            }));
        }

        let userRoleOptions = [];
        if (this.state.userRoleList && this.state.userRoleList.length) {
            userRoleOptions = this.state.userRoleList.map(item => ({
                value: item.productRoleAssignID,
                label: item.coreUserRoleDTO.coreRoleNote
            }));
        }



        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section blur-section">
                    <div className="p-grid nw-form">

                        <Growl ref={(el) => this.growl = el} />

                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12">

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.firstDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Product Type <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Product Type"
                                                    name="productType"
                                                    options={productTypeOptions}
                                                    value={this.state.productType}
                                                    onChange={e => this.onChangeProductType(e.value)}
                                                    filter={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                        }
                                        <span className='error-message'>{this.state.errors["productType"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.secondDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Product Name <span>*</span></span>
                                                <Dropdown
                                                    placeholder='Enter Product Name'
                                                    name="productName"
                                                    options={productListOptions}
                                                    value={this.state.productName}
                                                    onChange={(e) => this.onChangeProductName(e.target.value)}
                                                    autoWidth={false}
                                                />
                                            </div>
                                        }
                                        <span className='error-message'>{this.state.errors["productName"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.thirdDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">User Role <span>*</span></span>
                                                <Dropdown
                                                    placeholder='Select User Role'
                                                    name="userRole"
                                                    options={userRoleOptions}
                                                    value={this.state.userRole}
                                                    onChange={(e) => this.onChangeUserRole(e.target.value)}
                                                    autoWidth={false}
                                                />
                                            </div>
                                        }
                                        <span className='error-message'>{this.state.errors["userRole"]}</span>
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Offer Quantity <span>*</span></span>
                                            <InputText
                                                placeholder='Enter Offer Quantity'
                                                name="offerQuantity"
                                                value={this.state.offerQuantity}
                                                onChange={(e) => this.onChangeOfferQuantity(e.target.value)}
                                                keyfilter="pint"
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["offerQuantity"]}</span>
                                    </div>

                                    <div className="p-col-7 p-xl-7">
                                        <div className="p-inputgroup p-inputgroup-flex">
                                            <span className="p-inputgroup-addon">Discount Percentage <span>*</span></span>
                                            <InputText
                                                placeholder='Enter Discount Percentage'
                                                name="discountPercentage"
                                                value={this.state.discountPercentage}
                                                keyfilter="num"
                                                onChange={(e) => this.onChangeDiscountPercentage(e.target.value)}
                                            />
                                            <div className="inline-percent">%</div>
                                            {/* <div className="inline-percent-taka">805.00/-</div> */}
                                        </div>
                                        <span className='error-message'>{this.state.errors["discountPercentage"]}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Usable Time  <span>*</span></span>
                                            <InputText
                                                placeholder='Enter Usable Time'
                                                name="usableTime"
                                                keyfilter='pint'
                                                value={this.state.usableTime}
                                                onChange={(e) => this.onChangeUsableTime(e.target.value)}
                                            ></InputText>
                                        </div>

                                        <span className='error-message'>{this.state.errors["usableTime"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Start Date  <span>*</span></span>
                                            <Calendar
                                                placeholder='dd/mm/yy'
                                                name="startDate"
                                                value={this.state.startDate}
                                                onChange={(e) => this.onChangeStartDate(e.target.value)}
                                                showIcon={true}
                                                yearRange="2010:2030"
                                                dateFormat="dd/mm/yy"
                                                monthNavigator={true}
                                                yearNavigator={true}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["startDate"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Expiry Date  <span>*</span></span>
                                            <Calendar
                                                placeholder='dd/mm/yy'
                                                name="expireDate"
                                                value={this.state.expireDate}
                                                onChange={(e) => this.onChangeExpireDate(e.target.value)}
                                                showIcon={true}
                                                yearRange="2010:2030"
                                                dateFormat="dd/mm/yy"
                                                monthNavigator={true}
                                                yearNavigator={true}

                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["expireDate"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Offer Code  <span>*</span></span>
                                            <InputText
                                                placeholder='Enter Offer Code'
                                                name="offerCode"
                                                value={this.state.offerCode}
                                                onChange={(e) => this.onChangeOfferCode(e.target.value)}

                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["offerCode"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                            <InputTextarea
                                                placeholder='Enter Note'
                                                name="note"
                                                value={this.state.note}
                                                onChange={(e) => this.onChangeNote(e.target.value)}

                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors["note"]}</span>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Save"
                                        icon="fas fa-check"
                                        onClick={event => this.submitSave(event)}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="p-col-12 nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    header={tableHeader}
                                    value={this.state.dataTableValue}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column field="productRoleAssignDTO.productInfoDTO.productTypeInfoDTO.categoryName" header="Product Type" sortable={true} filter={true} />
                                    <Column field="productRoleAssignDTO.productInfoDTO.productName" header="Product Name" sortable={true} filter={true} />
                                    <Column field="productRoleAssignDTO.coreUserRoleDTO.coreRoleNote" header="User Role" sortable={true} filter={true} />
                                    <Column field="actualPrice" header="Actual Price" sortable={true} filter={true} />
                                    <Column field="discountPercent" header="Discount Percentage" sortable={true} filter={true} />
                                    <Column field="offerStartDate" header="Start Date" sortable={true} filter={true} />
                                    <Column field="offerExpiryDate" header="Expiry Date" sortable={true} filter={true} />
                                    <Column field="offerCode" header="Offer Code" sortable={true} filter={true} />
                                    <Column field="" header="Action" body={this.editBody} filter={true} />

                                </DataTable>
                            }
                        </div>

                    </div>

                </div>
                <div className="dialog-section">
                    <Dialog className="nw-dialog" header="Update Product Offer Infomation" visible={this.state.visible} onHide={this.onHide}>
                        {
                            this.state.selectedDataView ?
                                <ProductOfferUpdate viewDialog={this.onHide} rowData={this.state.selectedData} productOffer={this.fetchProductOfferList} />
                                : ''
                        }
                    </Dialog>
                </div>
            </div>

        )
    }
}