import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { AdminPointService } from "../../service/adminPoint/AdminPointService";

export class BankBranchList extends Component {

    constructor(props) {
        super(props);
        this.state = {

            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,
            dataTableValue: [],

        };

        this.adminPointService = new AdminPointService();
        this.netiContentLoader = new NetiContentLoader();
        this.fetchBankBranchList = this.fetchBankBranchList.bind(this);

    }

    componentWillMount() { this.fetchBankBranchList(); }

    fetchBankBranchList() {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.adminPointService.fetch_Bank_BranchList()
            .then((res) => {

                if (res.status == 200) {
                    return res.json().then((body) => {
                        this.setState({ dataTableValue: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }

            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    render() {

        let { dataTableValue } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Branch List</div>
            <div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-col-12 p-xl-12 p-col-nogutter">
                    <div className="nw-data-table">
                        {
                            this.state.dataTableIsLoading ? this.netiContentLoader.MyPointTableLoader()
                                :
                                <DataTable
                                    value={dataTableValue}
                                    header={tableHeader}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                    rowsPerPageOptions={[10, 50, 100]}
                                >
                                    <Column field="bankName" header="Bank Name" filter={true} />
                                    <Column field="district" header="District" filter={true} />
                                    <Column field="branchName" header="Branch Name" filter={true} />
                                    <Column field="routingNo" header="Routing Number" filter={true} />
                                </DataTable>
                        }
                    </div>
                </div>
            </div>

        );
    }

}
