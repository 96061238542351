import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';

let maxDate = new Date();

// pdf
let subTitleColumn = [];
let subTitleList = [];
let selectedProductNm = '';

export class ManagementPointProductPurchaseDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            emModuleList: '',
            dataTableSelection: null,
            dataTableValue: [],
            dateRangeParamFormat: {
                startDate: "",
                endDate: "",
                productID: ''
            },
            productList: [],
            productInfoDetails: [],
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
        };

        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.ManagementPointService = new ManagementPointService();

        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
        this.exportPdf = this.exportPdf.bind(this);

    }

    componentWillMount() {
        this.getProductList();
    }

    getProductList() {
        this.ManagementPointService.fetchProductList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productList: body });
                    })
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeStartDate = (e) => {
        let { dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        this.clearDateError(e.target.name);
    }

    onChangePackage = (e) => {
        let { errors, dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        errors["productID"] = "";
        // this.getEmModuleList(e.target.value);
    }

    onSubmitHandler = () => {
        let { dateRangeParamFormat, emModuleList } = this.state;

        if (this.searchHandleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.ManagementPointService.fetchProductPurchaseList(dateRangeParamFormat)
                .then((res) => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ productInfoDetails: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                        });
                    }
                    else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection.' });
                });
        }
    }

    searchHandleError = () => {
        let { errors, dateRangeParamFormat } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (dateRangeParamFormat.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start Date can't left empty.";
        }
        if (dateRangeParamFormat.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End Date can't left empty.";
        }
        if (dateRangeParamFormat.productID === '') {
            formIsValid = false;
            errors["productID"] = "Product Item can't left empty.";
        }
        if (dateRangeParamFormat.activityType === '') {
            formIsValid = false;
            errors["activityType"] = "Activity Type can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    amountBody = (rowData) => {
        return <div className="col-number text-right"> {this.validatorUtility.currencyFormatter(rowData.paidAmount)}</div>
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Area", dataKey: "area" },
            { title: "Neti ID", dataKey: "customID" },
            { title: "Name", dataKey: "fullName" },
            { title: "Designation", dataKey: "designation" },
            { title: "Paid Amount", dataKey: "paidAmount" },
        ]

        let tableColStyle = { paidAmount: { halign: "right", } };

        pdfDownloadPortrait_TableWithSubTitle("Product Purchase Details", 72, subTitleColumn, subTitleList, pdfColumns, this.state.productInfoDetails, tableColStyle, "Product_Purchase_Details.pdf");

    }

    render() {

        let { dateRangeParamFormat, productInfoDetails } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        let selectedProduct = dateRangeParamFormat.productID;

        if (dateRangeParamFormat.startDate && dateRangeParamFormat.endDate) {
            formatedStartDate = (dateRangeParamFormat.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (dateRangeParamFormat.endDate.toLocaleDateString('en-GB'));
        }

        subTitleColumn = [
            { dataKey: "A" }, { dataKey: "B" },
            { dataKey: "C" }, { dataKey: "D" },
            { dataKey: "E" }, { dataKey: "F" },
        ];
        subTitleList = [
            { A: "From Date", B: formatedStartDate, C: "To Date", D: formatedEndDate, E: "Product Item", F: selectedProductNm }
        ];
        // pdf reletaed task end

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Product Purchase Details List</div>
            <div className="header-title-right"><a href="#">Total Found: {productInfoDetails.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let productList = []
        if (this.state.productList && this.state.productList.length) {

            productList = this.state.productList.map((item) => ({
                value: item.productID,
                label: item.productName,
            }));

            // for pdf
            this.state.productList.filter(product => {
                if (product.productID == dateRangeParamFormat.productID) { selectedProductNm = product.productName }
            })

        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-lg-12 p-xl-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                        <Calendar
                                            name='startDate'
                                            value={dateRangeParamFormat.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            name='endDate'
                                            value={dateRangeParamFormat.endDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            dateFormat='dd/mm/yy'
                                            yearRange="2010:2030"
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Product Item <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Product item"
                                            name="productID"
                                            options={productList}
                                            value={dateRangeParamFormat.productID}
                                            onChange={this.onChangePackage}
                                            autoWidth={false}
                                        />
                                    </div>
                                    <span className='error-message'>{this.state.errors["productID"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-12"></div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12 p-col-nogutter nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() : <div>
                                    <DataTable
                                        value={productInfoDetails}
                                        selectionMode="single"
                                        header={tableHeader}
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 30]}
                                    >
                                        <Column field="area" header="Area" sortable={true} filter={true} />
                                        <Column field="customID" header="Neti ID" sortable={true} filter={true} />
                                        <Column field="fullName" header="Name" sortable={true} filter={true} />
                                        <Column field="designation" header="Designation" sortable={true} filter={true} />
                                        <Column field="paidAmount" header="Paid Amount" body={this.amountBody} sortable={true} filter={true} />
                                    </DataTable>

                                    <div className="nw-button-parent m-t-8 p-r-0">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Download PDF"
                                            icon="fas fa-download"
                                            onClick={this.exportPdf}
                                        />
                                    </div>
                                </div>

                            }
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}