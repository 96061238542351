import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { EdumanActionLogService } from '../../../service/actionLog/EdumanActionLogService';


let maxDate = new Date();
export class PartnerSelfDashboard extends Component {

    constructor() {
        super();
        this.state = {
            transactionInfoDataTableValue:[
                {type: "deposit", amount: 50000000000},
                {type: "deposit", amount: 50000000000},
                {type: "deposit", amount: 50000000000},
                {type: "deposit", amount: 50000000000},
                {type: "deposit", amount: 50000000000},
            ],
            dateRangeObj: {
                startDate: "",
                endDate: "",
            },
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            errors: {},
            dashboardViewObj:{
                callLogInfo:[],
                nwLoginInfo:[],
            }

        }

        this.CRMPointService = new CRMPointService();
        this.ValidatorUtility = new ValidatorUtility();
        this.edumanActionLogService = new EdumanActionLogService();
    }

    componentWillMount(){

    }


    getDashboardStatusDetails = () => {
        let { dateRangeObj, dashboardViewObj } = this.state
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.CRMPointService.fetchDashboardStatusDetails(dateRangeObj)
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        let callLogInfo=[]
                        let nwLoginInfo=[]
                        let balanceTrxInfo=[]
                        let admisiaCollection=[]
                        let paymentInfo=[]
                        let tokenInfo=[]
                        let emOrders=[]
                        let emIdInfo=[]
                        let purchaseInfo=[]
                        let dfpsCollectionInfo=[]
                        let smsSendInfo=[]


                        this.mappingObject(body.balanceTrxInfo, balanceTrxInfo, "balanceTrxInfo");
                        this.mappingObject(body.emIdInfo, emIdInfo, "emIdInfo");
                        this.mappingObject(body.purchaseInfo, purchaseInfo, "purchaseInfo");
                        this.mappingObject(body.dfpsCollectionInfo, dfpsCollectionInfo, "dfpsCollectionInfo");
                        this.mappingObject(body.admisiaCollection, admisiaCollection, "admisiaCollection");
                        this.mappingObject(body.callLogInfo, callLogInfo, "callLogInfo");
                        this.mappingObject(body.tokenInfo, tokenInfo, "tokenInfo");
                        this.mappingObject(body.nwLoginInfo, nwLoginInfo, "nwLoginInfo");
                        this.mappingObject(body.smsSendInfo, smsSendInfo, "smsSendInfo");

                        this.setState({ topProgressBar: false, errorMsgVisible: false });

                        // this.getEdumanLoginInfo(dateRangeObj)
                    });
                }
                else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' }) 
            });
    }

    getEdumanLoginInfo = (dateRangeObj) => {
        
        let startDate = NetiDateUtils.getDateFromString(dateRangeObj.startDate);
        let endDate = NetiDateUtils.getDateFromString(dateRangeObj.endDate);
        let searchQueryInfo = {
                "query": `SELECT count(*)  FROM eduman where logDate between '${startDate}' and '${endDate}'  and activityType='Login' group by userId`
        }

        let body=[]
        // if(this.searchHandleError()) {
            // this.setState({ topProgressBar: true, dataTableIsLoading: true,  errorMsgVisible: false });
            this.edumanActionLogService.getEmLoginInfo(searchQueryInfo).then((apiBody) => {
                this.mappingObject(apiBody, body, "body");
                
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
        // }
    }

    replaceWord = (str) =>{
        let seperatedString = str.split(/(?=[A-Z])/)
        let finalString=''

        for( let i=0 ; i < seperatedString.length ; i++ ){
            finalString =  finalString.concat( seperatedString[i] + " ")
        }

        return finalString 
    }

    mappingObject = (object, array, objName) =>{
        let { dashboardViewObj } = this.state
        Object.entries(object).map((item, index) =>{
            array.push({ 
                "title":this.replaceWord( item[0] ), 
                "value": this.ValidatorUtility.currencyFormatter(item[1])
            })
        })
        dashboardViewObj[objName] = array
        this.setState({
            dashboardViewObj
        })
        // console.log("dashboardViewObj:::::::", dashboardViewObj);
        
    }

    onChangeDate = (e) => {
        let { dateRangeObj } = this.state;
        dateRangeObj[e.target.name] = e.target.value;
        this.setState({ dateRangeObj });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onSubmitHandler = () => {
        this.getDashboardStatusDetails();
    }





    render() {
        let { dateRangeObj, dashboardViewObj, topProgressBar, errorMsgVisible, errorMsgBody } = this.state

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section crm-dashboard">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            name='startDate'
                                            value={dateRangeObj.startDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            name='endDate'
                                            value={dateRangeObj.endDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            dateFormat='dd/mm/yy'
                                            yearRange="2010:2030"
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                            </div>
                            
                        </div>

                        <div className="p-col-12">
                            {/* <div className="panel-box p-0">
                                <div className="panel-box-header">
                                    <h1>Payable Information</h1>
                                </div>
                            </div> */}
                        </div>

                        <div className="p-col-6">
                            <div className="p-grid p-col-12 p-col-nogutter">
                                <div className="p-col-12 dashboard-card-view">
                                    <Card header="Balance Transaction Info" className="bg-success">
                                        <div className="nw-data-table">
                                            <DataTable
                                                value={dashboardViewObj.balanceTrxInfo}
                                            >
                                                <Column field="title" className="text-capitalize"/>
                                                <Column field="value" className="text-right text-bold"/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>
                                
                                

                                <div className="p-col-12 dashboard-card-view">
                                    <Card header="Purchase Info" className="bg-danger">
                                        <div className="nw-data-table">
                                            <DataTable
                                                value={dashboardViewObj.purchaseInfo}
                                            >
                                                <Column field="title" className="text-capitalize" />
                                                <Column field="value" className="text-right text-bold"/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>
                                
                                <div className="p-col-12 dashboard-card-view">
                                    <Card header="Call Info">
                                        <div className="nw-data-table">
                                            <DataTable
                                                value={dashboardViewObj.callLogInfo}
                                            >
                                                <Column field="title" className="text-capitalize"/>
                                                <Column field="value" className="text-right text-bold"/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>

                            </div>
                        </div>







                        <div className="p-col-6">
                            <div className="p-grid p-col-12 p-col-nogutter">

                                <div className="p-col-12 dashboard-card-view">
                                    <Card header="Eduman ID Info" className="bg-eduman">
                                        <div className="nw-data-table">
                                            <DataTable
                                                value={dashboardViewObj.emIdInfo}
                                            >
                                                <Column field="title" className="text-capitalize"/>
                                                <Column field="value" className="text-right text-bold"/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12 dashboard-card-view">
                                    <Card header="DFPS Collection Info">
                                        <div className="nw-data-table">
                                            <DataTable
                                                value={dashboardViewObj.dfpsCollectionInfo}
                                            >
                                                <Column field="title" className="text-capitalize"/>
                                                <Column field="value" className="text-right text-bold"/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12 dashboard-card-view">
                                    <Card header="Admisia Collection Info" className="bg-danger">
                                        <div className="nw-data-table">
                                            <DataTable
                                                value={dashboardViewObj.admisiaCollection}
                                            >
                                                <Column field="title" className="text-capitalize"/>
                                                <Column field="value" className="text-right text-bold"/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>

                                <div className="p-col-12 dashboard-card-view">
                                    <Card header="Token Info" className="bg-pending">
                                        <div className="nw-data-table">
                                            <DataTable
                                                value={dashboardViewObj.tokenInfo}
                                            >
                                                <Column field="title" className="text-capitalize"/>
                                                <Column field="value" className="text-right text-bold"/>
                                            </DataTable>
                                        </div>
                                    </Card>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>

        )
    }
}