import React, { Component } from 'react';
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { InputMask } from 'primereact/inputmask';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from "../../common/ErrorMessageView";
import CommonFuctionality from "../../common/CommonFuctionality";

let accountSerialListArr = [];
let accountSerialListObj = {};
export class BankAccountInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saveErrors: {},
            updateErrors: {},
            bankName: '',
            dialogBank: '',
            dialogusingPurpose: '',
            accountInfo: '',
            accountShortName: '',
            defualtCode: '',
            accountSerial: '',
            usingPurpose: '',
            maxRecharge: '',
            minRecharge: '',
            noteValue: '',
            topProgressBar: false,
            dataTableIsLoading: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            dialogmaxRecharge: '',
            dialogminRecharge: '',
            dialogaccountSerial: '',
            bankAccountDetails: {
                coreBankAccId: null,
                accDefaultCode: "",
                accInfo: "",
                accEnableStatus: null,
                accSerial: null,
                accNote: "",
                maxRecharge: null,
                minRecharge: null,
                accShortName: "",
                coreCategoryInfoDTO: {
                    coreCategoryID: null,
                    categoryName: "",
                },
                usingPurposeCoreCategoryInfoDTO: {
                    coreCategoryID: null,
                    categoryDefaultCode: "",
                    categoryName: ""
                }
            },
            dataTableValue:[]
        }
        this.actionBody = this.actionBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);
        this.handleUpdateError = this.handleUpdateError.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.userCategoryService = new UserCategoryService();
        this.adminPointService = new AdminPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();

    }
    componentWillMount() {
        this.getBankListInfo();
        this.getUserPurposeList();
        this.getBankAccountListInfo();

        for (let i = 1; i < 21; i++) {
            accountSerialListObj.value = i;
            accountSerialListObj.label = i;
            accountSerialListArr.push(Object.assign({}, accountSerialListObj))
        }

    }

    getBankListInfo() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListBy2ndParentTypeDefCode('T100')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ bankList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });

    }

    getUserPurposeList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchListByDefaultCode('T101', 1)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ usngPurposeList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }


    getBankAccountListInfo() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.adminPointService.getCoreBankAccounts()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    });
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });

    }




    handleSave = () => {

        let requestedObject = {
            "accInfo": this.state.accountInfo,
            "accDefaultCode": this.state.defualtCode,
            "accShortName": this.state.accountShortName,
            "accSerial": this.state.accountSerial,
            "accNote": this.state.noteValue,
            "maxRecharge": this.state.maxRecharge,
            "minRecharge": this.state.minRecharge,
            "coreCategoryInfoDTO": {
                "coreCategoryID": this.state.bankName
            },
            "usingPurposeCoreCategoryInfoDTO": {
                "coreCategoryID": this.state.usingPurpose
            }
        }


        if (this.handleSaveError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' })
            this.adminPointService.createNewBankAccount(requestedObject)
                .then(res => {
                    if (res.status === 201) {
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully Saved"
                        });
                        this.getBankAccountListInfo();
                        this.setState({ accountInfo: '', defualtCode: '', accountShortName: '', accountSerial: '', noteValue: '', maxRecharge: '', minRecharge: '', bankName: '', usingPurpose: '' });
                        this.setState({ topProgressBar: false, errorMsgVisible: false })
                    }
                    else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });

                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                );
        }
    };



    handleUpdate = () => {

        if (this.handleUpdateError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false })
            this.adminPointService.updateBankAccount(this.state.bankAccountDetails)
                .then(res => {
                    if (res.status === 202) {
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully Updated"
                        });
                        this.getBankAccountListInfo();
                        this.onHide();
                        this.setState({ topProgressBar: false })
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });

                    }
                })
                .catch(error =>
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                );
            this.setState({ visible: false });
        }

    }

    updateDialogDiscard = () => {
        this.onHide();
    }

    handleSaveError() {
        let saveErrors = {};
        let formIsValid = true;

        if (this.state.bankName === "") {
            formIsValid = false;
            saveErrors["bankName"] = "Bank Name can't left empty";
        }
        if (this.state.accountInfo === "") {
            formIsValid = false;
            saveErrors["accountInfo"] = "Account Info can't left empty";
        }
        if (this.state.accountShortName === "") {
            formIsValid = false;
            saveErrors["accountShortName"] = "Account Short Name can't left empty";
        }
        if (this.state.defualtCode === "") {
            formIsValid = false;
            saveErrors["defualtCode"] = "Default Code can't left empty";
        }
        if (this.state.accountSerial === "") {
            formIsValid = false;
            saveErrors["accountSerial"] = "Account Serial can't left empty";
        }
        if (this.state.usingPurpose === "") {
            formIsValid = false;
            saveErrors["usingPurpose"] = "Using Purpose can't left empty";
        }
        if (this.state.maxRecharge === "") {
            formIsValid = false;
            saveErrors["maxRecharge"] = "Max Recharge can't left empty";
        }
        if (this.state.minRecharge === "") {
            formIsValid = false;
            saveErrors["minRecharge"] = "Minimum Recharge can't left empty";
        }

        this.setState({ saveErrors: saveErrors });
        return formIsValid;
    }

    handleUpdateError() {
        
        
        let { bankAccountDetails } = this.state
        let updateErrors = {};
        let formIsValid = true;

        if (bankAccountDetails.coreCategoryInfoDTO.coreCategoryID === "" || bankAccountDetails.coreCategoryInfoDTO.coreCategoryID === null) {
            formIsValid = false;
            updateErrors["dialogBank"] = "Bank Type can't left empty";
        }
        if (bankAccountDetails.accInfo === "") {
            formIsValid = false;
            updateErrors["accInfo"] = "Account Info can't left empty";
        }
        if (bankAccountDetails.accShortName === "") {
            formIsValid = false;
            updateErrors["accShortName"] = "Account Short Name can't left empty";
        }
        if (bankAccountDetails.accSerial === "" || bankAccountDetails.accSerial === null) {
            formIsValid = false;
            updateErrors["accSerial"] = "Account Serial can't left empty";
        }
        if (this.state.dialogdefautCode === "") {
            formIsValid = false;
            updateErrors["dialogdefautCode"] = "Serial can't left empty";
        }
        if (bankAccountDetails.usingPurposeCoreCategoryInfoDTO.coreCategoryID === "" || bankAccountDetails.usingPurposeCoreCategoryInfoDTO.coreCategoryID === null) {
            formIsValid = false;
            updateErrors["dialogusingPurpose"] = "Using Purpose can't left empty";
        }
        if (bankAccountDetails.maxRecharge === "") {
            formIsValid = false;
            updateErrors["maxRecharge"] = "Max Amount can't left empty";
        }
        if (bankAccountDetails.minRecharge === "") {
            formIsValid = false;
            updateErrors["minRecharge"] = "Min Amount can't left empty";
        }

        this.setState({ updateErrors: updateErrors });
        return formIsValid;
    }

    actionBody(rowData) {
        return (
            <div className="text-center">
                <Button
                    className="nw-action-button edit"
                    icon="fas fa-edit"
                    tooltip="Update"
                    onClick={e => this.viewDialog(rowData)}
                />
            </div>
        );
    }
    onHide(event) {
        this.setState({ updateErrors: {},visible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    getListDetailData(coreBankAccId) {
        this.adminPointService.fetchBankAccountListDetailData(coreBankAccId)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("bankAccountDetails", this.state.bankAccountDetails);
                        this.setState({ bankAccountDetails: body });
                    });
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    viewDialog(rowData) {
        this.getListDetailData(rowData.coreBankAccId);

        let { bankAccountDetails } = this.state
        this.setState({ visible: true });
        this.setState({ dialogBank: rowData.bank });
        this.setState({ dialogcoreBankAccId: rowData.coreBankAccId });
        this.setState({ dialogdefautCode: rowData.accDefaultCode });
        this.setState({ dialogaccInfo: rowData.accInfo });
        this.setState({ dialogaccShortName: rowData.accShortName });
        this.CommonFuctionality.blurDialogBackgroundActive();

    }

    onChangeUpdateBankList = e => {
        let { bankAccountDetails,updateErrors } = this.state
        bankAccountDetails.coreCategoryInfoDTO.coreCategoryID = e.value
        this.setState({ bankAccountDetails, dialogBank: e.value })
        updateErrors["dialogBank"] = "";
    }

    onChangeUpdateUsingPurpose = e => {
        let { bankAccountDetails,updateErrors } = this.state
        bankAccountDetails.usingPurposeCoreCategoryInfoDTO.coreCategoryID = e.value
        this.setState({ bankAccountDetails, dialogusingPurpose: e.value })
        updateErrors["dialogusingPurpose"] = "";
    }

    onChangeUpdateAccInfo = e => {
        let { bankAccountDetails,updateErrors } = this.state
        bankAccountDetails.accInfo = e.target.value
        this.setState({ bankAccountDetails });
        updateErrors["accInfo"] = "";
    };

    onChangeUpdateAccShortName = e => {
        let { bankAccountDetails,updateErrors } = this.state
        bankAccountDetails.accShortName = e.target.value
        this.setState({ bankAccountDetails });
        updateErrors["accShortName"] = "";
    };

    onChangeUpdateAccSerial = e => {
        let { bankAccountDetails,updateErrors } = this.state
        bankAccountDetails.accSerial = e.target.value
        this.setState({ bankAccountDetails });
        updateErrors["accSerial"] = "";
    };

    onChangeUpdateMaxRecharge = e => {
        let { bankAccountDetails,updateErrors } = this.state
        bankAccountDetails.maxRecharge = e.target.value
        this.setState({ bankAccountDetails });
        updateErrors["maxRecharge"] = "";

    };

    onChangeUpdateMinRecharge = e => {
        let { bankAccountDetails,updateErrors } = this.state
        bankAccountDetails.minRecharge = e.target.value
        this.setState({ bankAccountDetails });
        updateErrors["minRecharge"] = "";
    };

    onChangeUpdateAccNote = e => {
        let { bankAccountDetails } = this.state
        bankAccountDetails.accNote = e.target.value
        this.setState({ bankAccountDetails });
    };

    bodyDefaultCode = (rowData) => {
        return (<div className="col-number text-left"> {rowData['accDefaultCode']}</div>)
    }


    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { bankAccountDetails } = this.state

        let bankListOptions = []
        if (this.state.bankList && this.state.bankList.length) {
            bankListOptions = this.state.bankList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
        let usingPurposeOption = []
        if (this.state.usngPurposeList && this.state.usngPurposeList.length) {
            usingPurposeOption = this.state.usngPurposeList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
        // let tfoud;
        // if (this.state.dataTableValue) {
        //     tfoud = this.state.dataTableValue.length
        // }
        // let headervalue = (
        //     <div className="p-clearfix" >
        //         Bank Account List <span style={{ float: "right" }}>
        //             {" "}
        //             Total Found: {tfoud}{" "}
        //         </span>
        //     </div>
        // );

        let headervalue = (
            <div className="header-title">
              <div className="header-title-left">
                Bank Account List
              </div>
              <div className="header-title-right">
                    <a>
                      Total Found: {this.state.dataTableValue.length}
                    </a>
                  </div>
            </div>
          );

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                    <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                                        {this.state.firstDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Bank <span>*</span></span>
                                                <Dropdown
                                                    placeholder='Select Bank'
                                                    options={bankListOptions}
                                                    value={this.state.bankName}
                                                    // value={this.state.bankName}
                                                    onChange={(e) => this.setState({ bankName: e.value })}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    autoWidth={false}
                                                    showClear={true}
                                                />
                                            </div>
                                        }
                                        <span className="error-message">
                                            {this.state.saveErrors["bankName"]}
                                        </span>

                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Account Info <span>*</span></span>
                                            <InputText
                                                name="text"
                                                placeholder="Enter Account Info"
                                                onChange={(e) => this.setState({ accountInfo: e.target.value })}
                                                value={this.state.accountInfo}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.saveErrors["accountInfo"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Account Short Name <span>*</span></span>
                                            <InputText
                                                name="text"
                                                placeholder="Enter Account Short Name"
                                                onChange={(e) => this.setState({ accountShortName: e.target.value })}
                                                value={this.state.accountShortName}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.saveErrors["accountShortName"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Default Code <span>*</span></span>
                                            <InputText
                                                name="text"
                                                placeholder="Enter Default Code"
                                                onChange={(e) => this.setState({ defualtCode: e.target.value })}
                                                value={this.state.defualtCode}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.saveErrors["defualtCode"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Account Serial <span>*</span></span>
                                            <Dropdown
                                                placeholder="Enter Account Serial"
                                                onChange={(e) => this.setState({ accountSerial: e.target.value })}
                                                value={this.state.accountSerial}
                                                options={accountSerialListArr}
                                                autoWidth={false}
                                                showClear={true}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.saveErrors["accountSerial"]}
                                        </span>
                                    </div>



                                
                            



                                    <div className="p-col-12 p-xl-12">
                                        {this.state.secondDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Using Purpose <span>*</span></span>
                                                <Dropdown
                                                    placeholder='Select Using Purpose'
                                                    options={usingPurposeOption}
                                                    value={this.state.usingPurpose}
                                                    onChange={(e) => this.setState({ usingPurpose: e.value })}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    autoWidth={false}
                                                    showClear={true}
                                                />
                                            </div>
                                        }
                                        <span className="error-message">
                                            {this.state.saveErrors["usingPurpose"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Max Recharge <span>*</span></span>
                                            <InputText
                                                name="text"
                                                placeholder="Enter Max Recharge"
                                                keyfilter="money"
                                                onChange={(e) => this.setState({ maxRecharge: e.target.value })}
                                                value={this.state.maxRecharge}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.saveErrors["maxRecharge"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Min Recharge <span>*</span></span>
                                            <InputText
                                                name="text"
                                                placeholder="Enter Min Recharge"
                                                keyfilter="money"
                                                onChange={(e) => this.setState({ minRecharge: e.target.value })}
                                                value={this.state.minRecharge}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.saveErrors["minRecharge"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Note</span>
                                            <InputTextarea
                                                name="text"
                                                placeholder="Enter Note"
                                                onChange={(e) => this.setState({ noteValue: e.target.value })}
                                                value={this.state.noteValue}
                                            />
                                        </div>

                                    </div>


                            

                                <div className="p-col-12 p-xl-12">

                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-check"
                                        label="Save"
                                        onClick={this.handleSave}
                                    />
                                </div>
                                <div className="p-col-5"> </div>
                            </div>

                        </div>
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    header={headervalue}
                                    value={this.state.dataTableValue}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                >
                                    <Column
                                        field="bank"
                                        header="Bank Name"
                                        sortable={true}
                                        filter={true}
                                    />
                                    <Column
                                        field="accInfo"
                                        header="Account Info"
                                        sortable={true}
                                        filter={true}
                                    />
                                    <Column
                                        field="accShortName"
                                        header="Account Short Name"
                                        sortable={true}
                                        filter={true}
                                    />
                                    <Column
                                        field="accDefaultCode"
                                        header="Default Code"
                                        body={this.bodyDefaultCode}
                                        sortable={true}
                                        filter={true}
                                    />
                                    <Column
                                        field="usingPurpose"
                                        header="Using Purpose"
                                        sortable={true}
                                        filter={true}
                                    />
                                    <Column
                                        body={this.actionBody}
                                        header="Action"
                                        style={{ width: "80px" }}
                                    />

                                </DataTable>
                            }
                        </div>

                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog
                        header="Bank Account Info (Update)"
                        visible={this.state.visible}
                        modal={true}
                        maximizable
                        onHide={this.onHide}
                        className="nw-dialog"
                    >

                        <div className="nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Default Code</span>
                                            <div className="nw-inputgroup-desc">
                                                {bankAccountDetails.accDefaultCode}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Bank <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Bank"
                                                options={bankListOptions}
                                                value={bankAccountDetails.coreCategoryInfoDTO.coreCategoryID}
                                                onChange={(e) => this.onChangeUpdateBankList(e)}
                                                filter={true}
                                                filterBy="label,value"
                                                autoWidth={false}
                                                showClear={true}
                                                name="dialogBank"
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.updateErrors["dialogBank"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Account Info <span>*</span></span>
                                            <InputText
                                                name="accInfo"
                                                placeholder="Enter Account Info"
                                                onChange={this.onChangeUpdateAccInfo}
                                                value={bankAccountDetails.accInfo}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.updateErrors["accInfo"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Account Short Name <span>*</span></span>
                                            <InputText
                                                name="accShortName"
                                                placeholder="Enter Account Short Name"
                                                onChange={this.onChangeUpdateAccShortName}
                                                value={bankAccountDetails.accShortName}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.updateErrors["accShortName"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Account Serial <span>*</span></span>
                                            <Dropdown
                                                placeholder="Enter Account Serial"
                                                onChange={this.onChangeUpdateAccSerial}
                                                value={bankAccountDetails.accSerial}
                                                options={accountSerialListArr}
                                                name="accSerial"
                                                autoWidth={false}
                                                showClear={true}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.updateErrors["accSerial"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Using Purpose <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Purpose"
                                                options={usingPurposeOption}
                                                value={bankAccountDetails.usingPurposeCoreCategoryInfoDTO.coreCategoryID}
                                                onChange={(e) => this.onChangeUpdateUsingPurpose(e)}
                                                filter={true}
                                                name="dialogusingPurpose"
                                                filterBy="label,value"
                                                autoWidth={false}
                                                showClear={true}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.updateErrors["dialogusingPurpose"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Max Recharge <span>*</span></span>
                                            <InputText
                                                name="maxRecharge"
                                                placeholder="Enter Max Recharge"
                                                onChange={this.onChangeUpdateMaxRecharge}
                                                value={bankAccountDetails.maxRecharge}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.updateErrors["maxRecharge"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Min Recharge <span>*</span></span>
                                            <InputText
                                                name="minRecharge"
                                                placeholder="Enter Min Recharge"
                                                onChange={this.onChangeUpdateMinRecharge}
                                                value={bankAccountDetails.minRecharge}
                                            />
                                        </div>
                                        <span className="error-message">
                                            {this.state.updateErrors["minRecharge"]}
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Note </span>
                                            <InputTextarea
                                                name="bankNote"
                                                placeholder="Enter Note"
                                                onChange={this.onChangeUpdateAccNote}
                                                value={bankAccountDetails.accNote}
                                            />
                                        </div>

                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            label="Discard"
                                            icon="fas fa-times"
                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                            onClick={this.updateDialogDiscard}
                                        />
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            label="Update"
                                            onClick={this.handleUpdate}
                                            icon="fas fa-check"
                                        />
                                    </div>


                                </div>
                            </div>

                        </div>
                    </Dialog>
                </div>

            </div>

        )
    }
}