import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { WalletBalance } from '../myPoint/common/WalletBalance';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import NetiContentLoader from '../common/NetiContentLoader';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { pdfDownloadPortrait_Full_Two_Table, pdfDownloadPortrait_TableWithFooter } from '../common/JsPdfDownload';

let cloneStateBeforeMount;

let billTotalMonth = 0;
let totalPayableAmount = 0;
let totalPaidAmount = 0;
let totalDueAmount = 0;
export class PostPaidBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payabaleInstituteList: [],
            postpaidBillList: [],
            postPaidBillError: {},
            errorMsgVisible: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            firstDropdownIsLoading: false,
            errorMsgBody: '',
            payableInstituteID: '',
            payableInstituteInfo: {},
            postPaidBillInfo: {
                billAmount: '',
                billNote: '',
                emBillGenerateLogDTO: {
                    generateBillId: ''
                },
                emDetailsInfoDTO: {
                    emDetailsId: ''
                }

            },
            billSummaryID: ''
        }
        this.partnerPointService = new PartnerPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
        this.exportPdf = this.exportPdf.bind(this);

    }

    componentWillMount() {
        this.getPayableInstituteList();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    getPayableInstituteList = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.partnerPointService.getPayableInstituteByPartner('POST-PAID')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ payabaleInstituteList: body });
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false });

                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })
            });

    }

    viewPostpaidBillSummaryList = (value) => {
        let { payableInstituteInfo, postpaidBillList } = this.state;
        this.setState({ postpaidBillList: [] })
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        billTotalMonth = 0;
        totalPayableAmount = 0;
        totalPaidAmount = 0;
        totalDueAmount = 0;
        this.partnerPointService.getPostpaidBillSummary(value)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log("bill summary body", body);
                        if (body.length !== 0) {
                            billTotalMonth = body.map(item => item.month).length;
                            totalPayableAmount = body.map(item => item.payableAmount).reduce((a, b) => (a + b));
                            totalPaidAmount = body.map(item => item.paidAmount).reduce((a, b) => (a + b));
                            totalDueAmount = body.map(item => item.dueAmount).reduce((a, b) => (a + b));
                            this.setState({ postpaidBillList: body, errorMsgVisible: false });
                        } else {
                            this.setState({ errorMsgVisible: true, errorMsgBody: 'No bill amount found' });
                        }

                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, topProgressBar: false, errorMsgBody: 'Please check your connection.' })
            });

    }

    onChangePayableInstitute = (event) => {
        let { postPaidBillError } = this.state;
        postPaidBillError["payableInstitute"] = '';
        let { payableInstituteID, payabaleInstituteList, payableInstituteInfo } = this.state;
        for (let i = 0; i < payabaleInstituteList.length; i++) {
            if (payabaleInstituteList[i].emDetailsId == event.target.value) {
                payableInstituteInfo = payabaleInstituteList[i];
                this.setState({ payableInstituteInfo });
                this.viewPostpaidBillSummaryList(event.target.value);
            }
        }
        this.setState({ postPaidBillError })
        this.setState({ payableInstituteID: event.target.value });

    }
    onChangeBillSummary = (e) => {
        let { postPaidBillError } = this.state;
        postPaidBillError["billMonth"] = '';
        let { postPaidBillInfo } = this.state;
        postPaidBillInfo.emBillGenerateLogDTO.generateBillId = e.target.value;
        this.setState({ postPaidBillInfo });
        this.setState({ postPaidBillError })
    }

    onChangeBillAmount = (e) => {
        let { postPaidBillError } = this.state;
        postPaidBillError["pay"] = '';
        let { postPaidBillInfo } = this.state;
        postPaidBillInfo.billAmount = e.target.value;
        this.setState({ postPaidBillInfo });
        this.setState({ postPaidBillError })
    }

    onChangeBillNote = (e) => {
        let { postPaidBillError } = this.state;
        postPaidBillError["requestNote"] = '';
        let { postPaidBillInfo } = this.state;
        postPaidBillInfo.billNote = e.target.value;
        this.setState({ postPaidBillInfo });
        this.setState({ postPaidBillError })
    }

    onSubmitHandler = () => {
        let { postPaidBillInfo, payableInstituteInfo } = this.state;
        postPaidBillInfo.emDetailsInfoDTO.emDetailsId = payableInstituteInfo.emDetailsId;
        if (this.postPaidBillFormError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, });
            this.partnerPointService.payPostBillByPartner(postPaidBillInfo)
                .then(res => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    if (res.status == 202) {

                        this.setState({
                            payableAmount: '',
                            postpaidBillList: [],
                            payableInstituteID: '',
                            payableInstituteInfo: cloneStateBeforeMount.payableInstituteInfo,
                            postPaidBillInfo: cloneStateBeforeMount.postPaidBillInfo
                        });
                        billTotalMonth = 0;
                        totalPayableAmount = 0;
                        totalPaidAmount = 0;
                        totalDueAmount = 0;
                        this.getPayableInstituteList();
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resBody, dataTableIsLoading: false })
                            });
                    }
                }).catch(error => {
                    this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: 'Please check your connection.' })

                });
        }

    }

    postPaidBillFormError = () => {
        let { postPaidBillError, postPaidBillInfo } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (!this.state.payableInstituteID) {
            formIsValid = false;
            postPaidBillError["payableInstitute"] = "Institute can't left empty.";
        }
        if (!postPaidBillInfo.emBillGenerateLogDTO.generateBillId) {
            formIsValid = false;
            postPaidBillError["billMonth"] = "Bill Month can't left empty.";
        }
        if (postPaidBillInfo.billAmount === '') {
            formIsValid = false;
            postPaidBillError["pay"] = "Payment can't left empty.";
        }
        if (postPaidBillInfo.billNote === '') {
            formIsValid = false;
            postPaidBillError["requestNote"] = "Note can't left empty.";
        }
        this.setState({ postPaidBillError });
        return formIsValid;
    }

    // onChangeDueAmount = (rowData) => {
    //     return <span>{(rowData['payableAmount'] - rowData['paidAmount'])}</span>
    // }

    onChangeDueAmount = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData['payableAmount'] - rowData['paidAmount'])}</div>)
    }

    payableAmount = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData['payableAmount'])}</div>)
    }

    paidAmount = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData['paidAmount'])}</div>)
    }

    yearAndMonth = (rowData) => {
        return (<div className="year-month">
            <span className="year">{rowData['year']}</span> <br /> <span>{this.ValidatorUtility.currencyFormatter(rowData['month'])}</span>
        </div>)
    }

    payableAndPaidBillAmount = (rowData) => {
        return (
            <div>
                <u>Amount</u>
                <div className="table-middle-col">
                    <span className="col-number text-left">Payable</span> <span className=""> {this.ValidatorUtility.currencyFormatter(rowData['payableAmount'])}</span>
                </div>
                <div className="table-middle-col">
                    <span className="col-number text-left">
                        <span className="required-field">
                            (<span>-</span>) Paid
                        </span>
                    </span> <span className="" style={{ float: "right" }}> {this.ValidatorUtility.currencyFormatter(rowData['paidAmount'])}</span>
                </div>

                <div className="table-middle-col">
                    <span className="col-number text-left">
                        <span className="required-field">
                            (<span>-</span>) Discount
                        </span>
                    </span> <span className="" style={{ float: "right" }}> {this.ValidatorUtility.currencyFormatter("0")}</span>
                </div>
            </div>
        )
    }

    dueAmount = (rowData) => {
        return (<div className="total-due">
            <span className="due">Due</span> <br /> <div><span className="symbol-taka">৳</span> {this.ValidatorUtility.currencyFormatter(rowData['dueAmount'])}</div>
        </div>)
    }

    exportPdf() {

        let mainTitle = "Post Paid Bill"+" of "+this.state.payableInstituteInfo.instituteName;
        let pdfColumns = [
            { title: "Year", dataKey: "year" },
            { title: "Month", dataKey: "month" },
            { title: "Payable Amount", dataKey: "payableAmount" },
            { title: "Paid Amount", dataKey: "paidAmount" },
            { title: "Due Amount", dataKey: "dueAmount" },
        ]

        var totalArrayList = [
            {
                id: "1",
                totalPdf: billTotalMonth+" Months",
                totalAmount1: totalPayableAmount,
                totalAmount2: totalPaidAmount,
                totalAmount3: totalDueAmount,
            }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }, { dataKey: "totalAmount1" }, { dataKey: "totalAmount2" }, { dataKey: "totalAmount3" }];

        let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, cellWidth: 43.3, fontStyle: "bold", overflow: "linebreak" },
            totalAmount1: { halign: "right", fontStyle: "bold", fontSize: 10, cellWidth: 52.7 },
            totalAmount2: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 43.1 },
            totalAmount3: { halign: "right", fontSize: 10, fontStyle: "bold", cellWidth: 41.4 },
        }
        let tableColumnStyle = {
            payableAmount: { halign: "right", fontSize: 10},
            paidAmount: { halign: "right", fontSize: 10},
            dueAmount: { halign: "right", fontSize: 10},
        }
        pdfDownloadPortrait_TableWithFooter(mainTitle, 66,  pdfColumns, this.state.postpaidBillList, tableColumnStyle, totalColumns, totalArrayList, totalColumnStyles, "Post_Paid_Bill_Report.pdf");

    }

    render() {

        let { postPaidBillInfo, postPaidBillError, payableInstituteInfo, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="header-title">
        <div className="header-title-left">
            Token List  
        </div>
        <div className="header-title-right">
            <a>
                Total Found: {this.state.postpaidBillList.length.toLocaleString('EN-IN')}
            </a>
        </div>
    </div>;

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="" />
                <Column footer={billTotalMonth ? `Total ${billTotalMonth} Months` : ''} />
                <Column footer={totalPayableAmount} />
                <Column footer={totalPaidAmount} />
                <Column footer={totalDueAmount} />
            </Row>
        </ColumnGroup>;
        let payabaleInstitute = [];
        if ((this.state.payabaleInstituteList != null) && (this.state.payabaleInstituteList.length > 0)) {
            payabaleInstitute = this.state.payabaleInstituteList.map((item) => ({
                value: item.emDetailsId,
                label: item.instituteId + " - " + item.instituteName,
            }));
        }

        let billSummaryList = [];
        if ((this.state.postpaidBillList != null) && (this.state.postpaidBillList.length > 0)) {
            billSummaryList = this.state.postpaidBillList.map((item) => ({
                value: item.generateBillId,
                label: `${item.year} - ${item.month} - ${item.dueAmount} Tk`
            }));
        }
        return (
            <div className="p-fluid">
                {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                            <div className="p-grid seprator-inside">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                        <div className="p-col-12 p-xl-12">
                                            {this.state.firstDropdownIsLoading ? this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Payable Institute <span>*</span></span>
                                                    <Dropdown
                                                        value={this.state.payableInstituteID}
                                                        options={payabaleInstitute}
                                                        onChange={this.onChangePayableInstitute}
                                                        placeholder="Select Institute"
                                                        showClear={true}
                                                        filterBy="value,label"
                                                        autoWidth={false}
                                                        filter={true}
                                                        name="payableInstitute"
                                                    />
                                                </div>
                                            }
                                            <span className="error-message">{this.state.postPaidBillError["payableInstitute"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Institute ID
                                                        </span>
                                                <div className="nw-inputgroup-desc">
                                                    {payableInstituteInfo.instituteId}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Institute Name
                                                        </span>
                                                <div className="nw-inputgroup-desc">
                                                    {payableInstituteInfo.instituteName}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Total Due
                                                        </span>
                                                <div className="nw-inputgroup-desc">
                                                    {payableInstituteInfo.dueAmount ? `${this.ValidatorUtility.currencyFormatter(payableInstituteInfo.dueAmount)} Tk.` : ''}
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Bill Month <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Bill Month"
                                                    value={postPaidBillInfo.emBillGenerateLogDTO.generateBillId || ''}
                                                    options={billSummaryList}
                                                    onChange={this.onChangeBillSummary}
                                                    filter={true}
                                                    filterBy="value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="billMonth"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.postPaidBillError["billMonth"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Pay <span>*</span></span>
                                                <InputText
                                                    placeholder="enter pay amount"
                                                    keyfilter="money"
                                                    name="pay"
                                                    value={postPaidBillInfo.billAmount || ''}
                                                    onChange={this.onChangeBillAmount}
                                                    autoWidth={false}
                                                    name="pay"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.postPaidBillError["pay"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                                <InputTextarea
                                                    value={postPaidBillInfo.billNote || ''}
                                                    onChange={this.onChangeBillNote}
                                                    placeholder="Enter Note"
                                                    rows={3}
                                                    cols={30}
                                                    autoResize={true}
                                                    name="requestNote"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.postPaidBillError["requestNote"]}</span>

                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Pay Bill"
                                                icon="fas fa-hand-holding-usd"
                                                onClick={this.onSubmitHandler}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-xl-4">
                            <div className="layout-dashboard">
                                {this.state.dataTableIsLoading ? "" :

                                    <WalletBalance
                                        walletBalanceName='Wallet'
                                    />
                                }
                            </div>

                            <div className="p-col-12 p-xl-12 p-col-nogutter nw-datatable-detail-view due-bill-info-table due-bill-info-table-responsive">
                                <DataTable
                                    value={this.state.postpaidBillList}
                                    header="Due Bill Info"
                                    responsive={true}
                                >
                                    <Column field="" body={this.yearAndMonth} style={{ width: "95px" }} />
                                    <Column field="" body={this.payableAndPaidBillAmount} />
                                    {/* <Column field="payableAmount" body={this.payableAmount} />
                                    <Column field="paidAmount" body={this.paidAmount}  /> */}
                                    <Column field="" body={this.dueAmount} style={{ width: "115px" }} />
                                </DataTable>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table">
                                <DataTable
                                    value={this.state.postpaidBillList}
                                    footerColumnGroup={footerGroup}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    paginator={true}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    rows={10}
                                    columnResizeMode="fit"
                                >
                                    <Column field="year" header="Year" />
                                    <Column field="month" header="Month" />
                                    <Column field="payableAmount" header="Payable Amount" body={this.payableAmount} />
                                    <Column field="paidAmount" header="Paid Amount" body={this.paidAmount} />
                                    <Column field="dueAmount" header="Due Amount" body={this.onChangeDueAmount} />
                                </DataTable>

                                <div className="nw-button-parent m-t-8 p-r-0">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Download PDF"
                                    icon="fas fa-download"
                                    onClick={this.exportPdf}
                                />
                            </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        );
    }
}