import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { FileUpload } from 'primereact/fileupload';
import { Link } from 'react-router-dom';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { CoreConfigView } from './CoreConfigView';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Messages } from 'primereact/messages';

let urlId;
export class AdmisiaCoreConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            admisiaCoreConfigInfo: {},
            insertUserObj: {
                currentAdmissionYear: '',
                circularTitle: '',
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false,
                cmsId: 0,
            },
            updateUserObj: {
                coreConfigId: 0,
                currentAdmissionYear: '',
                circularTitle: '',
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false,
                cmsId: 0,
            },
            uploadFile: {
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            errors: {},
            checkEligibility: false
        }

        this.CommonFuctionality = new CommonFuctionality();
        this.NetiFileHandler = new NetiFileHandler();
        this.DwsService = new DwsService();
        this.reloadCoreUrlIdFromLocal();
    }

    async reloadCoreUrlIdFromLocal() {
        urlId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {
            let { insertUserObj, updateUserObj } = this.state;
            insertUserObj.cmsId = urlId;
            updateUserObj.cmsId = urlId;
            this.setState({ homeReturnButton: false, insertUserObj, updateUserObj })
            this.getAdmisiaCoreConfigInfo();
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    getAdmisiaCoreConfigInfo = () => {
        if (urlId) {
            this.setState({ homeReturnButton: false, checkEligibility: false, topProgressBar: true })
            this.DwsService.getAdmisiaCoreConfigInfo(urlId)
                .then((res) => {
                    return res.json().then((body) => {
                        if (body.messageType == 1) {
                            this.setState({ checkEligibility: true, admisiaCoreConfigInfo: body, topProgressBar: false })
                        } else {
                            this.setState({ checkEligibility: false, topProgressBar: false })
                        }
                    })
                })
                .catch(errorBody => {
                    this.setState({ checkEligibility: false, topProgressBar: false })
                })
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    onChangeAcademicYear = (e) => {
        let { insertUserObj, errors } = this.state;
        insertUserObj.currentAdmissionYear = e.target.value;
        this.setState({ insertUserObj, errors });
        this.onClearErrorMsg(e.target.name);

    }

    onChangeCircularTitle = (e) => {
        let { insertUserObj } = this.state;
        insertUserObj.circularTitle = e.target.value;
        this.setState({ insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onLoadPic = (e, maxFileSize) => {
        let { errors } = this.state;
        let fileType = this.NetiFileHandler.getFileContentType(e.files[0].name);
        let supportedExtention = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/doc', 'application/docx'];
        // console.log('loadPic', e.files[0]);
        if (supportedExtention.includes(fileType)) {

            if (this.NetiFileHandler.getMaxFileSizeIsValid(e.files[0].size, maxFileSize)) {
                errors["fileContent"] = "";
                this.setState({ errors });
                var reader = new FileReader();
                let photo = e.files[0];
                const scope = this;
                reader.readAsDataURL(photo);
                reader.onload = () => {
                    let content = reader.result;
                    var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                    var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                    let album = {
                        // extention: photo.type,
                        fileContent: urlStr,
                        fileName: photo.name,
                        fileSaveOrEditable: true
                    };
                    scope.setState({ uploadFile: album });

                    Object.assign(this.state.insertUserObj, album);
                }

            }
            // else {
            //     errors["fileContent"] = "File size can't be more than " + maxFileSize / 1048576 + ' MB';
            //     this.setState({ errors });
            // }

        }
        else {
            errors["fileContent"] = "File format not supported";
            this.setState({ errors });
        }
    }

    removeUploadFile = () => {
        let { uploadFile, insertUserObj } = this.state;
        uploadFile.fileContent = ''
        uploadFile.fileName = ''
        this.setState({ uploadFile, insertUserObj });
        Object.assign(insertUserObj, uploadFile);
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    handleError = (objectHandle) => {
        let { errors, uploadFile } = this.state;
        let formIsValid = true;

        if (!objectHandle.currentAdmissionYear) {
            formIsValid = false;
            errors["academicYear"] = "Year can't left empty.";
        }

        if (!objectHandle.circularTitle) {
            formIsValid = false;
            errors["circularTitle"] = "Title can't left empty.";
        }

        if (!uploadFile.fileContent) {
            formIsValid = false;
            errors["fileContent"] = "Upload file can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitHandler = () => {

        let { insertUserObj } = this.state;
        console.log("insertUserObj", insertUserObj);

        if (this.handleError(insertUserObj)) {
            this.setState({ topProgressBar: true });
            this.DwsService.createAdmisiaCoreConfig(insertUserObj)
                .then(res => { return res.json() }).then(body => {
                    console.log('submit res', body);

                    this.setState({ topProgressBar: false });
                    if (body.messageType == 1) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Created!" });
                        setTimeout(() => {
                            this.reloadCoreUrlIdFromLocal();
                            // this.setState({ checkEligibility: true })
                        }, 1000);
                    }
                    else {
                        this.setState({ errorMsgVisible: true, errorMsgBody: body.message });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Something went wrong. Please check connection' });
                });
        }
    }

    render() {

        let { insertUserObj, admisiaCoreConfigInfo, uploadFile, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;

        let uploadFileLabel = <div>Click / Drag File Here To Upload <br /><span className="upload-file-label">(Max 2 MB (recommended))</span></div>

        let date = new Date();
        let year = date.getFullYear();
        let academicYear = [
            { label: year, value: year },
            { label: year + 1, value: year + 1 },
        ];

        return (

            <div className="p-fluid">
                {/* <NetiCMSSetupProgress /> */}

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section">
                    {this.state.homeReturnButton === false ?
                        <div>
                            {
                                this.state.checkEligibility ?
                                    <CoreConfigView ref="CoreConfigView" admisiaCoreConfigInfo={admisiaCoreConfigInfo.item} getAdmisiaCoreConfigInfo={this.getAdmisiaCoreConfigInfo} />
                                    :
                                    <div className="p-grid nw-form blur-section">
                                        <div className="p-col-12 p-xl-12">
                                            <div className=" row nw-form-body">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Academic Year <span>*</span></span>
                                                        <Dropdown
                                                            placeholder="Select Academic Year"
                                                            value={insertUserObj.currentAdmissionYear}
                                                            options={academicYear}
                                                            onChange={this.onChangeAcademicYear}
                                                            showClear={true}
                                                            autoWidth={false}
                                                            name="academicYear"
                                                            id="academicYear"
                                                        />
                                                    </div>
                                                    <span className="error-message">{errors['academicYear']}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Circular Title <span>*</span></span>
                                                        <InputText
                                                            value={insertUserObj.circularTitle}
                                                            id="circularTitle"
                                                            placeholder="Enter Circular Title"
                                                            style={{ width: "100%" }}
                                                            type="text"
                                                            name="circularTitle"
                                                            onChange={this.onChangeCircularTitle}
                                                        />
                                                    </div>
                                                    <span className="error-message">{errors['circularTitle']}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup nw-upload-button">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                            Upload File <span>*</span>
                                                            <br />
                                                            <span>(PNG/JPG/JPEG/DOC/PDF)</span>
                                                        </span>
                                                        <div className="nw-upload-button-inside">
                                                            {
                                                                this.state.imageInputFieldIsLoading ?
                                                                    <div className="p-col-12">{this.NetiContentLoader.updateFileLoader()} </div> :
                                                                    uploadFile.fileContent ?
                                                                        <div className="image-view-main">
                                                                            <div className="upload-image-view">
                                                                                <Button
                                                                                    className="delete-upload-button"
                                                                                    icon="fas fa-times-circle"
                                                                                    onClick={this.removeUploadFile}
                                                                                />

                                                                                {
                                                                                    uploadFile.fileName ?
                                                                                        this.CommonFuctionality.getFileContentTypeIcon(uploadFile.fileName)
                                                                                        :
                                                                                        this.CommonFuctionality.getFileContentTypeIcon(insertUserObj.fileName)
                                                                                }

                                                                            </div>
                                                                            <div className="image-title">{uploadFile.fileName}</div>
                                                                        </div>

                                                                        :
                                                                        <FileUpload
                                                                            chooseLabel={uploadFile.fileName || uploadFileLabel}
                                                                            accept="image/*, .pdf, .doc,.docx"
                                                                            id="fileUpload"
                                                                            mode="advanced"
                                                                            maxFileSize="2000000"
                                                                            name="uploadFile"
                                                                            onSelect={e => this.onLoadPic(e, 2000000)}
                                                                            auto={true}
                                                                        />
                                                            }

                                                        </div>
                                                    </div>
                                                    <span className='error-message'><Messages ref={(el) => this.messages = el}></Messages></span>
                                                    <span className='error-message'>{uploadFile.fileName ? null : errors.fileContent}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="nw-button-parent" >
                                                        <Button
                                                            className="p-button-primary nw-button nw-button-right"
                                                            label="Save"
                                                            icon="fas fa-check"
                                                            onClick={this.onSubmitHandler}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i className="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    {/* {this.formDialog()}
                    {this.deleteDialogView()} */}
                </div>
            </div>
        )
    }
}