import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from "primereact/components/inputtext/InputText";
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import CommonFuctionality from '../common/CommonFuctionality';
import NetiContentLoader from '../common/NetiContentLoader';

export class ManageTokenProcessingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processingTokenList: [],
            solvedTokenList: [],
            processingAssignInfo: {},
            processingSolveObj: {
                tokenInfoID: '',
                tokenStatus: '',
                solveMessage: '',
                solverBasicInfoDTO: {
                    fullName: ''
                },
                creatorBasicInfoDTO: {
                    fullName: '',
                    basicMobile: ''
                },
                assignerBasicInfoDTO: {
                    fullName: ''
                }
            },
            processingDownloadInfo: {
                tokenInfoID: '',
                tokenStatus: '',
                solverBasicInfoDTO: {
                    fullName: ''
                },
                creatorBasicInfoDTO: {
                    fullName: '',
                    basicMobile: ''
                },
                assignerBasicInfoDTO: {
                    fullName: ''
                }
            },
            tokenCancelObj: {
                tokenInfoID: '',
                tokenStatus: ''
            },
            layout: 'list',
            tokenID: '',
            checked: false,
            tokenType: '',
            hasSolved: false,
            applicantFormVisibility: true,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false
        };

        this.supportPointService = new SupportPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.commonFuctionality = new CommonFuctionality();
        this.NetiContentLoader = new NetiContentLoader();
    }

    async componentWillMount() {
       await this.viewAllProcessingToken();
    }


    componentDidMount() {
        this.interval = setInterval(() => { 
            this.viewAllProcessingToken();
        }, 30000);

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    viewAllProcessingToken = () => {
        
        this.setState({ processingTokenList: [], topProgressBar: true, dataTableIsLoading: true });
        this.supportPointService.getAllProcessingToken()
            .then(res => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ processingTokenList: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, dataTableIsLoading: false, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection." })
            });

    }

    renderProcessingList = (processingTokenData) => {
        return (
            <div className="processing-list">
                <div className="p-grid p-col-12 p-col-nogutter">
                    {/* <div className="p-col-1 first">
                        
                    </div> */}

                    <div className="p-col-10 second">
                        <div className="nw-search-view">
                            <div>
                                <div className="p-col-12 p-xl-12">
                                        <label>
                                            <h2>
                                                { 
                                                    processingTokenData.tokenApplication == "Eduman" ? 
                                                        <span style={{color: "#0036B8"}}>{processingTokenData.tokenApplication}</span> 
                                                        : processingTokenData.tokenApplication == "NetiWorld" ? 
                                                        <span style={{color: "#0078FF"}}>{processingTokenData.tokenApplication}</span> 
                                                        : '---'
                                                }
                                            </h2>
                                        </label>
                                    <br />
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Module</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { processingTokenData.tokenModule || '---' }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Type</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { processingTokenData.tokenType || '---' }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Token ID</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { processingTokenData.customTokenID || '---' }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Issue Date</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { NetiDateUtils.getAnyShortForm(processingTokenData.createDate, 'DD-MMM-YYYY') || '---' }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Contact</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { processingTokenData.tokenContact || '---' }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Attachment</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { processingTokenData.attachmentPath ? "YES" : "NO" }
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>




{/*                             
                        <div className="p-col-12"><h2>{processingTokenData.tokenApplication}{processingTokenData.requirementStatus == 0 ? '(Problem)' : '(Requirement)'}</h2></div>
                        <div className="p-col-12"><h3>{'#' + processingTokenData.tokenType}</h3></div>
                        <div className="p-col-12">Token ID:<span style={{ paddingLeft: "5px" }}>{processingTokenData.customTokenID}</span></div>
                        <div className="p-col-12">Token Solver:<span style={{ paddingLeft: "5px" }}>{processingTokenData.tokenSolverName}</span></div>
                        <div className="p-col-12">Token Assigner:<span style={{ paddingLeft: "5px" }}>{processingTokenData.tokenAssignerName}</span></div>
                        <div className="p-col-12">Assign Date:<span style={{ paddingLeft: "5px" }}>{"no data found from api"}</span></div> */}
                        {/* <div className="p-col-12">
                            <span onClick={e => this.viewProcessingDownloadDialog(e, processingTokenData.tokenInfoID)}><i className="fa fa-download" style={{ fontSize: "1rem", paddingRight: "5px" }}></i></span>
                            <span onClick={e => this.viewProcessingSolveDialog(e, processingTokenData.tokenInfoID)}><i className="fa fa-check" style={{ fontSize: "1rem", paddingRight: "5px" }}></i></span>
                        </div> */}
                    </div>

                    <div className="p-col-2 third p-col-nogutter">
                        <center>
                            <Button
                                icon="fas fa-eye"
                                onClick={ e => this.viewProcessingDownloadDialog(e, processingTokenData.tokenInfoID) }
                                className="p-button p-button-primary nw-action-button p-button-icon-only"
                                tooltip="View"
                            />

                            <Button
                                icon="fas fa-check"
                                onClick={ e => this.viewProcessingSolveDialog(e, processingTokenData.tokenInfoID) }
                                className="p-button p-button-primary nw-action-button p-button-icon-only"
                                tooltip="Solved"
                            />

                            <Button
                                icon="fas fa-times"
                                onClick={ e => this.viewProcessingCancelDialog(e, processingTokenData) }
                                className="p-button p-button-primary nw-action-button p-button-icon-only"
                                tooltip="Cancel"
                            />
                        </center>
                        
                    </div>

                </div>
            </div>
        );
    }

    processingDataTemplate = (processingTokenData) => {
        if (!processingTokenData) {
            return null;
        }
        else {
            return this.renderProcessingList(processingTokenData);
        }
    }

    onHideProcessingDownload = () => {
        this.setState({ processingDownload: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    onHideProcessingSolve = () => {
        this.setState({ processingSolve: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    viewProcessingDownloadDialog = (e, tokenInfoID) => {
        this.commonFuctionality.blurDialogBackgroundActive();

        this.setState({ processingDownload: true });
        this.supportPointService.getTokensByTokenInfoID(tokenInfoID)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ processingDownloadInfo: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection Problem');
            });
    }

    viewProcessingSolveDialog = (e, tokenInfoID) => {
        this.commonFuctionality.blurDialogBackgroundActive();

        this.setState({ processingSolve: true });
        this.supportPointService.getTokensByTokenInfoID(tokenInfoID)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ processingSolveObj: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection Problem');
            });
    }

    processingSolveHandler = (e) => {
        let { processingSolveObj } = this.state;
        this.setState({ topProgressBar: true });
        processingSolveObj.tokenStatus = 10;
        this.setState({ processingSolveObj });
        this.supportPointService.editTokenInfo(processingSolveObj)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 202) {
                    this.viewAllProcessingToken();
                    this.onHideProcessingSolve();
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Sent!" });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection Problem');
            });
    }

    onChangesolveMessage = (e) => {
        let { processingSolveObj } = this.state;
        processingSolveObj.solveMessage = e.target.value;
        this.setState({ processingSolveObj });
    }

    onSearchToken = (e) => {
        this.setState({ tokenSearchVisibility: true });
    }

    onHideProcessingDownloadDialog = () => {
        this.onHideProcessingDownload();
    }

    processingStageDownload = () => {
        let { processingDownloadInfo } = this.state;
        if (processingDownloadInfo.attachmentPath !== null && processingDownloadInfo.attachmentName !== null) {
            this.netiFileHandler.getByteImage(processingDownloadInfo.attachmentPath).then(res => {
                if (res.status == 200) {
                    return res.json().then(body => {
                        let contentType = this.netiFileHandler.getImageContentType(
                            processingDownloadInfo.attachmentName
                        );
                        let a = document.createElement("a");
                        a.href = contentType + body.fileContent;
                        a.download = processingDownloadInfo.attachmentName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    });
                } else {
                }
            });
        }

    }


    viewProcessingCancelDialog = (e, pendingData) => {

        this.commonFuctionality.blurDialogBackgroundActive();

        let { tokenCancelObj } = this.state;
        this.setState({ processingTokenCancel: true });
        tokenCancelObj.tokenInfoID = pendingData.tokenInfoID;
        tokenCancelObj.tokenStatus = pendingData.tokenStatus;
        this.setState({ tokenCancelObj });
    }

    onRejectCancel = () => {
        this.setState({ processingTokenCancel: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    onHideprocessingTokenCancel = () => {
        this.setState({ processingTokenCancel: false });

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    onSubmitCancel = (e) => {
        let { tokenCancelObj } = this.state;
        this.setState({ topProgressBar: true, dataTableIsLoading: true  });
        tokenCancelObj.tokenStatus = 2;
        this.setState({ tokenCancelObj });
        this.supportPointService.editTokenInfo(tokenCancelObj)
            .then(res => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false  });
                if (res.status == 202) {
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Sent!" });
                   
                    this.onHideprocessingTokenCancel()

                    setTimeout(() => {
                         this.viewAllProcessingToken();
                    }, 800);
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection Problem');
            });


    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        return (
            <div>
                <div className="blur-section">
                    <Growl ref={(el) => this.growl = el} />
                    
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                    <div>
                        {
                            this.state.dataTableIsLoading ? this.NetiContentLoader.ThreeDotsLoader() :
                                <div className="token-info">
                                    <DataScroller
                                        value={this.state.processingTokenList}
                                        inline={true}
                                        layout={this.state.layout}
                                        header={`Processing(${this.state.processingTokenList.length})`}
                                        itemTemplate={this.processingDataTemplate}
                                        scrollHeight="100vh"
                                        rows={10}
                                    ></DataScroller>
                            </div>
                        }
                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog 
                        maximizable 
                        header={this.state.processingDownloadInfo.tokenType ? this.state.processingDownloadInfo.tokenType : 'Processing Stage Download'} 
                        visible={this.state.processingDownload} 
                        className="nw-dialog" 
                        modal={true} 
                        onHide={this.onHideProcessingDownload}
                    >
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token ID</span>
                                                        <div class="nw-inputgroup-desc">
                                                            { this.state.processingDownloadInfo.customTokenID }
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label"> Note</span>
                                                        {/* <div class="nw-inputgroup-desc">
                                                            {  }
                                                        </div> */}
                                                        <div class="nw-inputgroup-desc">{this.state.processingDownloadInfo.tokenDetails}</div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token Source:</span>
                                                        <div class="nw-inputgroup-desc">
                                                            { this.state.processingDownloadInfo.tokenSource }
                                                        </div>
                                                    </div>
                                                </div>

                                                { this.state.processingDownloadInfo.edumanDetailsInfoDTO ?
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Institute</span>
                                                            <div class="nw-inputgroup-desc">
                                                                {this.state.processingDownloadInfo.edumanDetailsInfoDTO.instituteId} - {this.state.processingDownloadInfo.edumanDetailsInfoDTO.instituteName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :null
                                                }

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Issue Date:</span>
                                                        <div class="nw-inputgroup-desc">
                                                            { this.state.processingDownloadInfo.createDate ? NetiDateUtils.getAnyShortForm(this.state.processingDownloadInfo.createDate, 'DD-MMM-YYYY') : null }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Issue By:</span>
                                                        <div class="nw-inputgroup-desc">
                                                            { this.state.processingDownloadInfo.creatorBasicInfoDTO.fullName + ' ' + '(' + this.state.processingDownloadInfo.creatorBasicInfoDTO.basicMobile + ')' }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token Solver:</span>
                                                        <div class="nw-inputgroup-desc">
                                                            { this.state.processingDownloadInfo.solverBasicInfoDTO.fullName }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token Assigner:</span>
                                                        <div class="nw-inputgroup-desc">
                                                            { this.state.processingDownloadInfo.assignerBasicInfoDTO.fullName }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Download Attachment</span>
                                                        <div class="nw-inputgroup-desc nw-form-download-button">
                                                            <Button
                                                                label={ this.state.processingDownloadInfo.attachmentName}
                                                                icon="fas fa-download"
                                                                onClick={this.processingStageDownload}
                                                                className="nw-action-button info"
                                                                style={{ border: "1px solid #D2EAFF" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        onClick={this.onHideProcessingDownloadDialog}
                                                    />
                                                    {/* <Button
                                                        label="Download"
                                                        icon="fas fa-check"
                                                        onClick={this.processingStageDownload}
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                    /> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    

                    </Dialog>

                    <Dialog 
                        maximizable 
                        header='Processing Stage Solve' 
                        visible={this.state.processingSolve} 
                        className="nw-dialog" 
                        modal={true} 
                        onHide={this.onHideProcessingSolve}
                    >
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token ID:</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.processingSolveObj.customTokenID}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label"> Note</span>
                                                        <InputTextarea
                                                            placeholder="Details"
                                                            value={this.state.processingSolveObj.tokenDetails || null}
                                                            rows={3}
                                                            cols={30}
                                                            autoResize={true}
                                                            id="detail"
                                                            name="details"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token Source:</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.processingSolveObj.tokenSource}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Issue Date:</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.processingSolveObj.createDate ? NetiDateUtils.getAnyShortForm(this.state.processingSolveObj.createDate, 'DD-MMM-YYYY') : null}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Issue By:</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.processingSolveObj.creatorBasicInfoDTO.fullName + ' ' + '(' + this.state.processingSolveObj.creatorBasicInfoDTO.basicMobile + ')'}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token Solver:</span>
                                                        <InputText
                                                            type="text"
                                                            value= {this.state.processingSolveObj.solverBasicInfoDTO.fullName}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token Assigner:</span>
                                                        <InputText
                                                            type="text"
                                                            value= {this.state.processingSolveObj.assignerBasicInfoDTO.fullName}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label"> Comments:</span>
                                                        <InputTextarea
                                                            placeholder="Details"
                                                            value={this.state.processingSolveObj.solveMessage || null}
                                                            onChange={this.onChangesolveMessage}
                                                            rows={3}
                                                            cols={30}
                                                            autoResize={true}
                                                            id="detail"
                                                            name="details"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        onClick={this.onHideProcessingSolve}
                                                    />

                                                    <Button
                                                        icon="fas fa-check"
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        label="Solve"
                                                        onClick={this.processingSolveHandler}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>


                    <Dialog header="Processing Stage Cancel" className="nw-confirm-dialog" visible={this.state.processingTokenCancel} onHide={this.onHideprocessingTokenCancel} closable>
                        <div className="p-grid ">
                            <div className="p-col-12 p-xl-12">
                                <div className="confirm-wrapper">
                                    <h3>Do you want to cancel this Token?</h3>
                                    <br/>
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            icon="fas fa-times"
                                            label="No"
                                            onClick={this.onRejectCancel}
                                        />
                                        
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Yes"
                                            icon="fas fa-check"
                                            onClick={(e) => this.onSubmitCancel(e)}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </Dialog>

                </div>

            </div>
        );
    }
}