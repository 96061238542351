import React, { useState } from "react";
import { WalletBalanceDeposit2 } from "./WalletBalanceDeposit2";
import { WalletBalance } from "../common/WalletBalance";

const Testing = () => {
  const [selectedWallet, setselectedWallet] = useState("Bkash");
  const handleSelectedWallet = (wallet) => {
    setselectedWallet(wallet);
  };

  return (
    <div className="layout-dashboard">
      <div className="p-grid">
        <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
          <WalletBalanceDeposit2
            selectedHandler={handleSelectedWallet}
            selectedWallet={selectedWallet}
          />
        </div>
      </div>
    </div>
  );
};

export { Testing };
