import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from "primereact/components/inputtext/InputText";
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import { NetiFileHandler } from '../../utils/NetiFileHandler';


export class ManageTokenSolvedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            solvedTokenList: [],
            tokenInfoByMobileArr: [],
            tokenInfoByInstituteArr: [],
            solvedAssignInfo: {},
            solvedDownloadInfo: {
                solverBasicInfoDTO: {
                    fullName: ''
                },
                creatorBasicInfoDTO: {
                    fullName: ''
                },
                assignerBasicInfoDTO: {
                    fullName: ''
                }
            },
            emptyTokenInfoObj: false,
            callerContactNo: '',
            deviceInstituteId: '',
            layout: 'list',
            tokenID: '',
            checked: false,
            tokenType: '',
            hasSolved: false,
            applicantFormVisibility: true,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
        };

        this.supportPointService = new SupportPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
    }

    async componentWillMount() {
       await this.viewTopTenSolvedToken();
    }


    componentDidMount() {
        this.interval = setInterval(() => { 
            this.viewTopTenSolvedToken();
        }, 30000);

    }

    componentWillUnmount() {
        clearInterval(this.interval);
      }

    viewTopTenSolvedToken = () => {
        this.setState({ topProgressBar: true });
        this.supportPointService.getTopTenSolvedTokenList()
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ solvedTokenList: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection Problem');
            });

    }

    solvedStageDownload = () => {
        let { solvedDownloadInfo } = this.state;
        if (solvedDownloadInfo.attachmentPath !== null && solvedDownloadInfo.attachmentName !== null) {
            this.netiFileHandler.getByteImage(solvedDownloadInfo.attachmentPath).then(res => {
                if (res.status == 200) {
                    return res.json().then(body => {
                        let contentType = this.netiFileHandler.getImageContentType(
                            solvedDownloadInfo.attachmentName
                        );
                        let a = document.createElement("a");
                        a.href = contentType + body.fileContent;
                        a.download = solvedDownloadInfo.attachmentName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    });
                } else {
                }
            });
        }

    }


    renderSolvedList = (solvedTokenData) => {
        return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12" style={{ borderBottom: '1px solid #d9d9d9' }}>
                        <div className="p-lg-7"><h2>{solvedTokenData.tokenApplication}{solvedTokenData.requirementStatus == 0 ? '(Problem)' : '(Requirement)'}</h2></div>
                        <div className="p-lg-7"><h3>{'#' + solvedTokenData.tokenType}</h3></div>
                        <div className="p-lg-9">Token ID:<span style={{ paddingLeft: "5px" }}>{solvedTokenData.customTokenID}</span></div>
                        <div className="p-lg-9">Token Solver:<span style={{ paddingLeft: "5px" }}>{solvedTokenData.tokenSolverName}</span></div>
                        <div className="p-lg-9">Token Assigner:<span style={{ paddingLeft: "5px" }}>{solvedTokenData.tokenAssignerName}</span></div>
                        <div className="p-lg-9">Solved Date:<span style={{ paddingLeft: "5px" }}>{NetiDateUtils.getAnyShortForm(solvedTokenData.solveDate, 'DD-MMM-YYYY')}</span></div>
                        <div className="p-lg-8 p-lg-offset-10">
                            <span onClick={e => this.viewSolvedDownloadDialog(e, solvedTokenData.tokenInfoID)}><i className="fa fa-download" style={{ fontSize: "1rem", paddingRight: "5px" }}></i></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    solvedDataTemplate = (solvedTokenData) => {
        if (!solvedTokenData) {
            return null;
        }
        else {
            return this.renderSolvedList(solvedTokenData);
        }
    }

    onCheckStatus = () => {
        let { checked } = this.state;
        this.setState({ checked: !checked });
    }

    onCheckHasSolved = () => {
        let { hasSolved } = this.state;
        this.setState({ hasSolved: !hasSolved });
    }

    onChangeTokenType = (e) => {
        let { tokenType } = this.state;
        tokenType = e.target.value;
        this.setState({ tokenType });
    }

    onHideSolvedDownload = () => {
        this.setState({ solvedDownload: false });
    }

    viewSolvedDownloadDialog = (e, tokenInfoID) => {
        this.setState({ solvedDownload: true });
        this.supportPointService.getTokensByTokenInfoID(tokenInfoID)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ solvedDownloadInfo: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection Problem');
            });
    }

    onSearchToken = (e) => {
        this.setState({ tokenSearchVisibility: true });
    }

    checkTokenStatus = (status) => {
        if (status === undefined) {
            return null;
        }

        let contentType = '';
        if (status == 0) {
            contentType = 'Pending';
        }
        else if (status == 1) {
            contentType = 'Processing';
        }
        else if (status == 2) {
            contentType = 'Cancel';
        }
        else if (status == 5) {
            contentType = 'Resubmit';
        }
        else if (status == 10) {
            contentType = 'Solve';
        }
        return contentType;
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        return (
            <div>

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="content-section implementation">
                    <DataScroller
                        value={this.state.solvedTokenList}
                        inline={true}
                        layout={this.state.layout}
                        header={`Solved(${this.state.solvedTokenList.length})`}
                        itemTemplate={this.solvedDataTemplate}
                        scrollHeight="100vh"
                        rows={10}
                    ></DataScroller>
                </div>

                <div className="dialog-section">
                    <Dialog 
                        maximizable 
                        header={this.state.solvedDownloadInfo.tokenType ? this.state.solvedDownloadInfo.tokenType : 'Solved Stage Download'} 
                        visible={this.state.solvedDownload} 
                        className="nw-dialog" 
                        modal={true} 
                        onHide={this.onHideSolvedDownload}
                    >
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-form-body">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token ID:</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.solvedDownloadInfo.customTokenID}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">Note:</span>
                                                        <InputTextarea
                                                            placeholder="Details"
                                                            value={this.state.solvedDownloadInfo.tokenDetails}
                                                            rows={3}
                                                            cols={30}
                                                            autoResize={true}
                                                            id="detail"
                                                            name="details"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token Source:</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.solvedDownloadInfo.tokenSource}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Issue Date:</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.solvedDownloadInfo.createDate ? NetiDateUtils.getAnyShortForm(this.state.solvedDownloadInfo.createDate, 'DD-MMM-YYYY') : null}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Issue By:</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.solvedDownloadInfo.creatorBasicInfoDTO.fullName + ' ' + '(' + this.state.solvedDownloadInfo.creatorBasicInfoDTO.basicMobile + ')'}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Token Assigner:</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.solvedDownloadInfo.assignerBasicInfoDTO.fullName}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Solved By:</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.solvedDownloadInfo.solverBasicInfoDTO.fullName}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        onClick={this.onHideSolvedDownload}
                                                    />
                                                    <Button
                                                        label="Download"
                                                        icon="fas fa-check"
                                                        onClick={this.solvedStageDownload}
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>

            </div>
        );
    }
}