import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { EmAdminPointService } from '../../service/emAdminPoint/EmAdminPointService';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ProfileService } from '../../service/profile/ProfileService'
import { AdminPointService } from '../../service/adminPoint/AdminPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';

let cloneStateBeforeMount;
export class CRMPointAssign extends Component {
    constructor() {
        super();
        this.state = {
            roleIDList: [],
            netiIDError: [],
            purchaseCodeError: [],
            pointError: [],
            checked: false,
            purchaseCode: '',
            netiID: '',
            pointList: [],
            purchaseInformation: {
                productName: '',
            },
            netiInformation: {
                netiID: '',
                fullName: '',
            },
            assignPointInfo: {
                pointType: '',
                roleID: '',
                purchaseCode: '',
                netiID: ''
            },
            checkEligibility: true,
            errorMsgVisible: false,
            errorMsgBody: '',
            firstDropDownIsLoading: false,
            firstSearchIsLoading: false,
            secondSearchIsLoading: false,
            topProgressBar: false,
            firstSearchErrorMsg: '',
            secondSearchErrorMsg: '',
            firstfirstSearchView: false,
            secondfirstSearchView: false,
            firstSearchErrorVisible: false,
            secondSearchErrorVisible: false,

        };

        this.PartnerPointService = new PartnerPointService();
        this.emAdminPointService = new EmAdminPointService();
        this.CRMPointService = new CRMPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.ProfileService = new ProfileService();
        this.AdminPointService = new AdminPointService();
        this.ErrorMessageView = new ErrorMessageView();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.fetchAssignablePointList();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    fetchAssignablePointList() {
        this.setState({ topProgressBar: true, firstDropDownIsLoading: true });
        this.emAdminPointService.getAssignablePointsByEmAdminPoint()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ roleIDList: body });
                        this.setState({ topProgressBar: false, firstDropDownIsLoading: false });

                    })
                } else {
                    this.emAdminPointService.Auth.handleErrorStatus(res)
                        .then((resBody) => {
                            this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: resBody })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            );
    }

    pointTypeHandler = (event) => {
        let { assignPointInfo } = this.state
        assignPointInfo.roleID = event.target.value;
        assignPointInfo.coreRoleDefCode = event.target.value.coreRoleDefCode;
        this.setState({ pointError: {} })
        this.setState({ assignPointInfo });
    }

    purchaseCodeHandler = (event) => {
        let { assignPointInfo } = this.state
        assignPointInfo.purchaseCode = event.target.value;
        this.setState({ purchaseCodeError: {} })
        this.setState({ assignPointInfo });
    }

    netiIDHandler = (event) => {
        let { netiID } = this.state;
        this.setState({ netiIDError: {} })
        this.setState({ netiID: event.target.value });
    }

    purchaseCodeCheck = (e) => {
        let { purchaseInformation, assignPointInfo } = this.state;
        purchaseInformation.productName = purchaseInformation.usedStatus = purchaseInformation.purchaseDate = null;
        this.setState({ purchaseInformation });


        if (this.purchaseCodeError()) {
            this.setState({ topProgressBar: true, firstSearchView: true, firstSearchIsLoading: true, errorMsgVisible: false, firstSearchErrorVisible: false });
            this.PartnerPointService.getUserPurchaseCode(this.state.assignPointInfo.purchaseCode)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {

                            if( assignPointInfo.coreRoleDefCode == body.productPurchaseLogDTO.productInfoDTO.productDefaultCode){
                                purchaseInformation.productName = body.productPurchaseLogDTO.productInfoDTO.productName;
                                purchaseInformation.usedStatus = body.usedStatus;
                                purchaseInformation.purchaseDate = NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
                                this.setState({ purchaseInformation });
                                this.setState({ topProgressBar: false, firstSearchIsLoading: false });
                            }
                            else{
                                this.setState({ 
                                    topProgressBar: false, 
                                    firstSearchIsLoading: false, 
                                    firstSearchErrorVisible: true, 
                                    firstSearchErrorMsg: "Purchase code is invalid for this segment", 
                                })
                            }
                        })

                    } else {
                        this.PartnerPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, firstSearchIsLoading: false, firstSearchErrorVisible: true, firstSearchErrorMsg: resBody })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, firstSearchView: false, firstSearchIsLoading: false, firstSearchErrorVisible: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
        }
    }

    netiIdCheckHandler = (e) => {
        let { netiInformation } = this.state;
        netiInformation.netiID = netiInformation.fullName = netiInformation.basicMobile = netiInformation.registrationDate = null;
        this.setState({ netiInformation });

        if (this.netiIDCodeError()) {
            this.setState({ topProgressBar: true, secondSearchView: true, secondSearchIsLoading: true, errorMsgVisible: false, secondSearchErrorVisible: false });
            this.ProfileService.findBasicInfoByCustomNetiID(this.state.netiID)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            netiInformation.netiID = body.netiID;
                            netiInformation.fullName = body.fullName;
                            netiInformation.basicMobile = body.basicMobile;
                            netiInformation.registrationDate = NetiDateUtils.getAnyShortForm(body.registrationDate, 'DD-MMM-YYYY');
                            this.setState({ netiInformation });
                            this.setState({ topProgressBar: false, secondSearchIsLoading: false });

                        });

                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, secondSearchIsLoading: false, secondSearchErrorVisible: true, secondSearchErrorMsg: resBody })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, secondSearchView: false, secondSearchIsLoading: false, secondSearchErrorVisible: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
        }
    }

    saveCrmPointAssign = () => {
        let { netiInformation, assignPointInfo } = this.state;
        if (this.pointTypeError()) {
            if (this.purchaseCodeError()) {
                if (this.netiIDCodeError()) {
                    this.setState({ topProgressBar: true, firstDropDownIsLoading: true, errorMsgVisible: false });
                    assignPointInfo.netiID = netiInformation.netiID;
                    assignPointInfo.roleID=assignPointInfo.roleID.coreRoleID;
                    this.CRMPointService.createCrmPointAssign(assignPointInfo)
                        .then(res => {
                            if (res.status == 202) {
                                this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully submitted!' });
                                this.setState({
                                    netiID: '',
                                    assignPointInfo: cloneStateBeforeMount.assignPointInfo,
                                    purchaseInformation: cloneStateBeforeMount.purchaseInformation,
                                    netiInformation: cloneStateBeforeMount.netiInformation
                                });
                                this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: false });

                            }
                            else {
                                this.CRMPointService.Auth.handleErrorStatus(res)
                                    .then((responseBody) => {
                                        this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                    });
                            }
                        }).catch(error =>
                            this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                        );
                }
            }
        }
    }

    purchaseCodeError() {
        let { purchaseCode, purchaseCodeError } = this.state;
        let formIsValid = true;
        this.setState({ firstSearchView: false })

        if (this.state.assignPointInfo.purchaseCode === '') {
            formIsValid = false;
            purchaseCodeError["purchaseCode"] = "Purchase code can't left empty";
        } else {
            purchaseCodeError["purchaseCode"] = "";
        }
        this.setState({ purchaseCodeError });
        return formIsValid;
    }

    netiIDCodeError() {
        let { purchaseCode, netiIDError, netiID } = this.state;
        let formIsValid = true;
        this.setState({ secondSearchView: false })

        if (netiID === '') {
            formIsValid = false;
            netiIDError["netiIDError"] = "Neti ID can't left empty";
        }
        if( netiID.length < 10){
            formIsValid = false
            netiIDError['netiIDError'] = 'Neti ID is too sort';
        }

        if (netiID == '' && netiID.length < 10) {
            formIsValid = false
            netiIDError['netiIDError'] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10';
        }
        this.setState({ netiIDError });
        return formIsValid;
    }

    pointTypeError() {
        let { purchaseCode, pointError } = this.state;
        let formIsValid = true;
        if (this.state.assignPointInfo.roleID === '') {
            formIsValid = false;
            pointError["pointError"] = "Point type can't left empty";
        } else {
            pointError["pointError"] = "";
        }
        this.setState({ pointError });
        return formIsValid;
    }

    focusSearch = e =>{
        this.setState({
            searchLabel:"Search",
            addSearchBtnClass:"nw-button p-button-primary text-color-white"
        });
    }
  
    blurSearch = e =>{
        this.setState({
            searchLabel:"",
            addSearchBtnClass:""
        });
    }
    
    focusSearchNetiId = e =>{
        this.setState({
            searchLabelNetiId:"Search",
            addSearchBtnClassNetiId:"nw-button p-button-primary text-color-white"
        });
    }
  
    blurSearchNetiId = e =>{
        this.setState({
            searchLabelNetiId:"",
            addSearchBtnClassNetiId:""
        });
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let pointType = [];
        if (this.state.roleIDList && this.state.roleIDList.length) {
            pointType = this.state.roleIDList.map(item => ({
                value: item,
                label: item.coreRoleNote
            }));
        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid nw-form">

                    <div className="p-col-12 p-xl-8 p-xl-offset-2 ">
                        <div className="nw-form-body">
                            <div className="p-col-12 p-xl-12">
                                {this.state.firstDropDownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Point Type <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Point Type"
                                            value={this.state.assignPointInfo.roleID}
                                            options={pointType}
                                            autoWidth={false}
                                            onChange={(e) => this.pointTypeHandler(e)}
                                            filter={true}
                                            filterPlaceholder="Select Point Type"
                                            filterBy="label,value"
                                        />
                                    </div>
                                }
                                <span className="error-message">{this.state.pointError["pointError"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                    <InputText
                                        placeholder="Enter Purchase Code"
                                        value={this.state.assignPointInfo.purchaseCode}
                                        style={{ width: "15%" }}
                                        type="text"
                                        name="purchaseCode"
                                        onChange={(e) => this.purchaseCodeHandler(e)}
                                        keyfilter="num"
                                        onFocus={this.focusSearch}
                                        onBlur={this.blurSearch}
                                    />
                                    <Button
                                        className={"p-button-animation " + this.state.addSearchBtnClass}
                                        label={this.state.searchLabel}
                                        icon="fas fa-search"
                                        onClick={(e) => this.purchaseCodeCheck(e)}
                                        onFocus={this.focusSearch}
                                        onBlur={this.blurSearch}
                                    />
                                </div>
                                <span className="error-message">{this.state.purchaseCodeError["purchaseCode"]}</span>

                            </div>

                            <div>
                                {this.state.firstSearchView ?
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-search-view">
                                            {this.state.firstSearchErrorVisible ?
                                                <center className="error-message">{this.state.firstSearchErrorMsg || 'No Data Found'}</center> :

                                                <div>
                                                    {
                                                        this.state.firstSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Product Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">{this.state.purchaseInformation.productName || '-'}</div>
                                                                </div>
                                                            </div>
                                                    }

                                                    {
                                                        this.state.firstSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label"> Use Status</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.purchaseInformation.usedStatus === 0 ? "Unused" : "Used"}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                    }

                                                    {
                                                        this.state.firstSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Purchase Date</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.purchaseInformation.purchaseDate || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div> : ''
                                }



                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Neti ID <span>*</span></span>
                                        <InputText
                                            value={this.state.netiID}
                                            placeholder="Enter Neti ID"
                                            style={{ width: "15%" }}
                                            type="text"
                                            name="netiID"
                                            onChange={(e) => this.netiIDHandler(e)}
                                            keyfilter="num"
                                            onFocus={this.focusSearchNetiId}
                                            onBlur={this.blurSearchNetiId}
                                        />
                                        <Button
                                            className={"p-button-animation " + this.state.addSearchBtnClassNetiId}
                                            label={this.state.searchLabelNetiId}
                                            icon="fas fa-search"
                                            onClick={(e) => this.netiIdCheckHandler(e)}
                                            onFocus={this.focusSearchNetiId}
                                            onBlur={this.blurSearchNetiId}
                                        />
                                    </div>

                                    <span className="error-message">{this.state.netiIDError["netiIDError"]}</span>
                                </div>
                                {this.state.secondSearchView ?
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-search-view">
                                            {this.state.secondSearchErrorVisible ?
                                                <center className="error-message">{this.state.secondSearchErrorMsg || 'No Data Found'}</center> :

                                                <div>
                                                    {
                                                        this.state.secondSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            this.state.netiInformation.fullName === '' ? '' :

                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Name</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.netiInformation.fullName || '-'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }

                                                    {
                                                        this.state.secondSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            this.state.netiInformation.fullName === '' ? '' :

                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon nw-inputgroup-label"> Mobile No</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.netiInformation.basicMobile || '-'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }

                                                    {
                                                        this.state.secondSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            this.state.netiInformation.fullName === '' ? '' :


                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Register Date</span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.netiInformation.registrationDate || '-'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }

                                                   



                                                </div>
                                            }
                                        </div>
                                    </div> : ''
                                }

                                <div className="p-col-12 p-xl-12">

                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                    </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Save"
                                        icon="fas fa-check"
                                        onClick={(e) => this.saveCrmPointAssign(e)}
                                    />

                                </div>
                            </div>


                        </div>


                    </div>

                </div>
            </div>
        );
    }
}