import React, { Component } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export class AdmisiaApplicantPrevExam extends Component {

  render() {

    console.log("ExamValue", this.props.applicantPrevDataList);

    // let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Previous Exam Info<span style={{ 'float': 'right' }}>Total Found: {this.props.applicantPrevDataList.length} </span></div>;
    


    return (

        <div className="p-fluid">
            <div className="p-grid">
                <div className="p-col-12 p-xl-12">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Applicant ID
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                            {this.props.applicantPrevExamInfo.registrationID || ""}
                                        </div>
                                    </div>
                                </div>




                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Student Name
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                         
                                           {this.props.applicantPrevExamInfo.applicantName || "" } 
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-data-table">
                                    
                                        <DataTable
                                            // header={header}
                                            // footerColumnGroup={footerBEP}
                                            responsive={true}
                                            selectionMode="single"
                                            paginator={true}
                                            rows={10}
                                            value={this.props.applicantPrevDataList}
                                        >
                                            <Column field="prevInstituteName" header="Institute Name" filter={true} />
                                            <Column field="classCategoryInfoDTO.categoryName" header="Class Name" filter={true} />
                                            <Column field="rollNo" header="Roll No" filter={true} />
                                            <Column field="examNameCategoryInfoDTO.categoryName" header="Exam Name" filter={true} />
                                            <Column field="examGradeCategoryInfoDTO.categoryName" header="Exam Grade" filter={true} />
                                            <Column field="examGPA" header="GPA" filter={true} />
                                            <Column field="passingYearCategoryInfoDTO.categoryName" header="Passing Year" filter={true} />
                                            <Column field="boardCategoryInfoDTO.categoryName" header="Board Name" filter={true} />
                                            
                                        </DataTable>
                                
                                    </div>

                                </div>

                               
                              





                                {/* <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Class Name
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Exam Name
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Previous Institute Name
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Board Name
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                           Roll No.
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                           Exam Grade
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                           GPA
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                           Passing Year
                                        </span>
                                        <div className="nw-inputgroup-desc">
                                           
                                        </div>
                                    </div>
                                </div> */}



                                {/* <div className="p-col-12 p-xl-12">


                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Insitute Name</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                        { this.props.applicantPrevExamInfo.instituteName }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Board</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                        { this.props.applicantPrevExamInfo.board }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Class</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                        { this.props.applicantPrevExamInfo.className }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Roll No</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                        { this.props.applicantPrevExamInfo.rollNo }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Exam</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                        { this.props.applicantPrevExamInfo.examName }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Grade</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                        { this.props.applicantPrevExamInfo.grade }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">GPA</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                        { this.props.applicantPrevExamInfo.gpa }
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Passing Year</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                        { this.props.applicantPrevExamInfo.passingYear }
                                        </div>
                                    </div>
                                </div> */}





                                {/* <div className="p-grid p-col-12 p-col-12" >
                                    <div className="p-grid p-lg-4">
                                        <label htmlFor="instituteContactNo">Insitute Name</label>
                                    </div>
                                    <div className="p-grid p-lg-8" >
                                        <span style={{ paddingRight: ".5em" }}>:</span>
                                        {this.props.applicantPrevExamInfo.instituteName}
                                    </div>
                                </div> */}

                                {/* <div className="p-grid p-col-12">
                                    <div className="p-grid p-lg-4">
                                        <label htmlFor="instituteContactNo">Board</label>
                                    </div>
                                    <div className="p-grid p-lg-8">
                                        <span style={{ paddingRight: ".5em" }}>:</span>
                                        {this.props.applicantPrevExamInfo.board}
                                    </div>
                                </div> */}

                                {/* <div className="p-grid p-col-12">
                                    <div className="p-grid p-lg-4">
                                        <label htmlFor="instituteContactNo">Class</label>
                                    </div>
                                    <div className="p-grid p-lg-8">
                                        <span style={{ paddingRight: ".5em" }}>:</span>
                                        {this.props.applicantPrevExamInfo.className}
                                    </div>
                                </div> */}

                                {/* <div className="p-grid p-col-12">
                                    <div className="p-grid p-lg-4">
                                        <label htmlFor="instituteContactNo">Roll No</label>
                                    </div>
                                    <div className="p-grid p-lg-8">
                                        <span style={{ paddingRight: ".5em" }}>:</span>
                                        {this.props.applicantPrevExamInfo.rollNo}
                                    </div>
                                </div> */}

                                {/* <div className="p-grid p-col-12">
                                    <div className="p-grid p-lg-4">
                                        <label htmlFor="instituteContactNo">Exam</label>
                                    </div>
                                    <div className="p-grid p-lg-8">
                                        <span style={{ paddingRight: ".5em" }}>:</span>
                                        {this.props.applicantPrevExamInfo.examName}
                                    </div>
                                </div> */}

                                {/* <div className="p-grid p-col-12">
                                    <div className="p-grid p-lg-4">
                                        <label htmlFor="instituteContactNo">Grade</label>
                                    </div>
                                    <div className="p-grid p-lg-8">
                                        <span style={{ paddingRight: "5px" }}>:</span>
                                        {this.props.applicantPrevExamInfo.grade}
                                    </div>
                                </div> */}

                                {/* <div className="p-grid p-col-12">
                                    <div className="p-grid p-lg-4">
                                        <label htmlFor="instituteContactNo">GPA</label>
                                    </div>
                                    <div className="p-grid p-lg-8">
                                        <span style={{ paddingRight: "5px" }}>:</span>
                                        {this.props.applicantPrevExamInfo.gpa}
                                    </div>
                                </div>


                                <div className="p-grid p-col-12">
                                    <div className="p-grid p-lg-4">
                                        <label htmlFor="instituteContactNo">Passing Year</label>
                                    </div>
                                    <div className="p-grid p-lg-8">
                                        <span style={{ paddingRight: "5px" }}>:</span>
                                        {this.props.applicantPrevExamInfo.passingYear}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

    );
  }
}


