import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { BalanceService } from '../../../service/myPoint/BalanceService';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { MY_POINT } from '../../../utils/PointWiseRouteConsts';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { InputText } from "primereact/components/inputtext/InputText";
import { Fieldset } from 'primereact/fieldset';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { InputTextarea } from "primereact/inputtextarea";


let angle = '';
export class WalletBalanceTransferList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewNote: '',
            date: '',
            transactionType: '',
            netiID: '',
            name: '',
            transferAmount: '',

            dataTableValue: [],
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,

        }
        this.balanceService = new BalanceService();
        this.NetiContentLoader = new NetiContentLoader();
        this.boldBody = this.boldBody.bind(this);
        this.validatorUtility = new ValidatorUtility();
    }

    componentDidMount() {
        this.showUserTopTenBalanceTransferList();
    }

    showUserTopTenBalanceTransferList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });

        this.balanceService.getUserTopTenBalanceTransferList()
            .then((res) => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            body[i].transactionDate = NetiDateUtils.getAnyShortForm(body[i].transactionDate, 'DD-MMM-YYYY hh:mm:ss a');
                        }
                        this.setState({
                            dataTableValue: body,
                            dataTableIsLoading: false,
                            topProgressBar: false
                        });
                    });
                } else {
                    this.balanceService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })


            );
    }

    noteBody = (rowData, column) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-info"
                title='Click to show notes'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    transactionTypeBody = (rowData, column) => {
        (rowData.transactionFor === "Send") ?
            angle = "right text-danger"
            : angle = "left text-success"

        return <div>
            {rowData.transactionFor}

            <i className={"pull-right fas fa-angle-" + angle} style={{ "fontSize": "18px" }}></i>

        </div>;
    }

    boldBody(rowData) {
        if (rowData.transactionFor === "Send") {
            return <div className="col-number text-right text-danger">{this.validatorUtility.currencyFormatter(rowData['amount'])}</div>
        } else if (rowData.transactionFor === "Receive") {
            return <div className="col-number text-right text-success">{this.validatorUtility.currencyFormatter(rowData['amount'])}</div>
        }
        else {
            return (<b> {this.validatorUtility.currencyFormatter(rowData['amount'])}</b>);
        }
    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    viewDialog = (rowData) => {
        console.log("Notes", rowData);
        this.setState({ date: NetiDateUtils.getAnyShortForm(rowData['transactionDate'], 'DD-MMM-YYYY') })
        this.setState({ transactionType: rowData.transactionFor })
        this.setState({ netiID: rowData.netiID })
        this.setState({ name: rowData.fullName })
        this.setState({ transferAmount: rowData.amount })
        this.setState({ viewNote: rowData.note })
        this.setState({ visible: true });
    }

    changeTransactionTypeFontColor = (transactionType) => {

        if (transactionType === "Send") {
            return <span className="text-danger">
                <span
                >
                    {transactionType}
                </span>
            </span>;
        } else if (transactionType === "Receive") {
            return <span className="text-success">
                <span
                >
                    {transactionType}
                </span>
            </span>;
        }
        else {
            return (<span> {transactionType}
            </span>);
        }
    }

    changeTransferAmountFontColor = (transferAmount) => {

        if (this.state.transactionType === "Send") {
            return <span className="text-danger">
                <span
                >
                    {this.validatorUtility.currencyFormatter(transferAmount)}            </span>
            </span>;
        } else if (this.state.transactionType === "Receive") {
            return <span className="text-success">
                <span
                >
                    {this.validatorUtility.currencyFormatter(transferAmount)}
                </span>
            </span>;
        }
        else {
            return (<span>{this.validatorUtility.currencyFormatter(transferAmount)}
            </span>);
        }
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let depositLinkText = "deposit-link-text";
        let tableHeader =
            <div className="header-title">
                <div className="header-title-left">
                    Balance Transfer List
                </div>


                <div className="header-title-right">
                    <Link to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 2 }}>
                        More <i className="fas fa-angle-right"></i>
                    </Link>
                </div>
            </div>;

        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid">
                    <div className="p-col-12 p-xl-12">

                        {this.state.dataTableIsLoading ?
                            this.NetiContentLoader.MyPointTableLoader() :
                            <div className="nw-data-table nw-data-table-tabview">
                                <Growl ref={(el) => this.growl = el} />
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    rows={10}
                                    columnResizeMode="fit"
                                >
                                    <Column field="transactionDate" header="Date & Time" className="table-datetime" />
                                    <Column field="transactionFor" header="Transaction Type" body={this.transactionTypeBody} />
                                    <Column field="netiID" header="Neti ID" />
                                    <Column field="fullName" header="Name" />
                                    <Column field="amount" body={this.boldBody} header="Transfer Amount" />
                                    <Column field="note" body={this.noteBody} header="Details" style={{ width: "96px" }} />
                                </DataTable>
                            </div>
                        }
                    </div>


                    <Dialog header="Transfer Details" visible={this.state.visible} className="nw-dialog" onHide={this.onHide} closable>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Date
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.date}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Transaction Type
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.transactionType ? this.changeTransactionTypeFontColor(this.state.transactionType) : ''}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Neti ID
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.netiID}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Name
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.name}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Transfer Amount
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.transferAmount ? this.changeTransferAmountFontColor(this.state.transferAmount) : ''}


                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Note
                                                </span>
                                                <InputTextarea
                                                    readOnly={true}
                                                    name="text"
                                                    value={this.state.viewNote}
                                                />


                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </Dialog>



                </div>
            </div>
        );
    }
}