import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from "../../../service/managementPoint/ManagementPointService";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";


let cloneStateBeforeMount;
let eligibilityStatus;
let urlInfoID;

export class TransactionIdWiseCollection extends Component {
    constructor() {
        super();
        this.state = {
            applicantPicture: '',
            transactionIDError: {},
            transactionID: '',
            searchView: true,
            firstInputFieldIsLoading: false,
            regNo: '',
            errors: {},
            searchViewError: false,
            searchErrorMsgBody: '',
            eligibilityStatus: '',
            regError: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            DOB: '',
            mfsCollectionInfo: {
         }
        };

        this.admisiaPointService = new AdmisiaPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.getProfileImg = this.getProfileImg.bind(this);
        this.ManagementPointService = new ManagementPointService();
        this.validatorUtility = new ValidatorUtility();
    }



    onChangeTransactionID = (e) => {
        let { transactionIDError } = this.state;
        transactionIDError['transactionID'] = '';
        this.setState({ transactionID:e.target.value , transactionIDError });
    }

   

    getProfileImg() {
        if (this.state.applicantInfo.admisiaApplicantInfoDTO.photoPath !== null) {
            this.netiFileHandler.getByteImage(this.state.applicantInfo.admisiaApplicantInfoDTO.photoPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(this.state.applicantInfo.admisiaApplicantInfoDTO.photoName);
                                this.setState({ applicantPicture: contentType + body.fileContent });

                            })
                    } else {
                        return res.json()
                            .then((body) => {
                                console.log("image error", body);
                            })
                    }
                });
        }

    }





    onSearchHandler = () => {
        this.setState({ searchView: true,topProgressBar: true,searchErrorMsgBody: '',searchViewError: false, errorMsgVisible: false});
        if (this.searchRegError()) {
            this.setState({firstInputFieldIsLoading: true});
            this.ManagementPointService.fetchMfsCollectionInfoByTransactionId(this.state.transactionID)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            console.log("mfsCollectionInfo",body);
                            this.setState({ mfsCollectionInfo: body.item, firstInputFieldIsLoading: false, searchViewError: false,  errorMsgVisible: false });
                        });
                    } else {
                        this.ManagementPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ searchErrorMsgBody: responseBody, firstInputFieldIsLoading: false, errorMsgVisible: false, searchViewError: true})
                            });
                    }
                })
                .catch(error => {
                    this.setState({ topProgressBar: false ,errorMsgVisible: true,firstInputFieldIsLoading: false, searchViewError: false, errorMsgBody: "Please check your connection." })
                });

        }

    }

    searchRegError = () => {
        let { transactionIDError } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (this.state.transactionID === '') {
            formIsValid = false;
            transactionIDError['transactionID'] = 'Transaction ID can not left empty.';
        }

        this.setState({ transactionIDError });
        return formIsValid;
    }

    render() {

        console.log("Final Picture", this.state.applicantPicture);
        
        let { applicantInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors, mfsCollectionInfo } = this.state;
        const pointType = [
            { label: 'DWS', value: 'DWS' },
            { label: 'Admisia', value: 'Admisia' },
            { label: 'Bijoy', value: 'Bijoy' }
        ]
        let {firstInputFieldIsLoading} = this.props;



        console.log("Exam", this.state.examInfoValue);
        
        
        
        let staticImg = 'assets/layout/images/userAvatar.png';

        return (
            <div className="p-fluid">
                <div className="p-col-12 p-xl-12">
                    <div className="p-grid nw-form">
                        {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }
                        
                        <div className="p-col-12 p-xl-12">
                            <Growl ref={(el) => this.growl = el} />

                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-9">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Transaction ID</span>
                                            <InputText
                                                value={this.state.transactionID}
                                                onChange={this.onChangeTransactionID}
                                                placeholder="Enter Transaction ID"
                                                style={{ width: "100%" }}
                                                type="text"
                                                keyfilter="pint"
                                                name="transactionID"
                                            />
                                    </div>
                                    <span className="error-message">{this.state.transactionIDError["transactionID"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-3 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        onClick={this.onSearchHandler}
                                        icon="fas fa-search"
                                    />
                                </div>
                            </div>
                        </div>

                    { 

                        
                     this.state.searchView?
                        <div className="p-col-12 p-xl-12">
                             <div className="nw-search-view">
                             {  this.state.searchViewError ?
                                    <center className="error-message">{this.state.searchErrorMsgBody}</center> 
                                    :
                                    <div className="p-col-12 p-xl-12">
                    
                                            {/* <div className="p-grid"> */}
        
                                            {/* <div className="p-col-12 p-xl-6">
                                                
                                            </div> */}
                                        {/* <div className="p-col-12 p-xl-3" style={{'width': '120px','height': '120px','background': 'aliceblue'}}>
                                            <img src={this.props.photoInfo || staticImg } width="120px" />
                                        </div> */}
                    
                    
                               
                    
                                    <div className="p-grid p-col-12 p-xl-12 nw-search-view" >
                                        <div className="p-col-12 p-xl-6">
                                            {/* <div className=""> */}
                    
                                                <div className="p-col-12 p-xl-12">
                                                    {/* <div className="p-col-12 p-md-12 p-md-offset-10">
                                                    </div> */}
                                                   
                    
                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Transaction Date</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.transactionDate ?  NetiDateUtils.getAnyShortForm(mfsCollectionInfo.transactionDate, 'DD-MMM-YYYY hh:mm:ss a') : ''}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                    
                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Log Date</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.logDate ?  NetiDateUtils.getAnyShortForm(mfsCollectionInfo.logDate, 'DD-MMM-YYYY hh:mm:ss a') : ''}
                                                                   </div>
                                                                </div>
                                                            }
                                                        </div>
                    
                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon"> Student ID</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.studentId}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                    
                    
                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Student Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.studentName}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                    
                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Student Mobile No.</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.mobileNo}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                    
                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Class</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.className}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                    
                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Invoice ID</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.invoiceId}

                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                    
                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Invoice Amount</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.invoiceAmount ? this.validatorUtility.currencyFormatter(mfsCollectionInfo.invoiceAmount) : ''}

                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                    
                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Transaction By</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.transactionBy}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                    
                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Sender Mobile No.</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.senderMobileNo}

                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Account Mobile No.</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.accountMobileNo}

                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon"> Transaction ID</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.trnId}

                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Paid Amount</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {mfsCollectionInfo && mfsCollectionInfo.paidAmount ? this.validatorUtility.currencyFormatter(mfsCollectionInfo.paidAmount) : ''}


                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                    
                                               
                                                </div>
                                            {/* </div> */}
                                        </div>
                    
                                    </div>
                                </div>
                    
                                      
                                }
                            </div>
                   
                        </div>

                        :''}
                    </div>

                </div>
                    
            </div>
        );
    }
}