import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import NetiContentLoader from '../../common/NetiContentLoader';
import { Editor } from 'primereact/editor';
let cloneStateBeforeMount;
export class AdminPointAddTutorial extends Component {
    constructor() {
        super();
        this.state = {
            productNameList: [],
            tutorialInfoDetails: {
                defaultCode: "",
                menuBangla: "",
                menuEenglish: "",
                breadcrumbBangla: "",
                breadcrumbEnglish: "",
                titleBangla: "",
                titleEnglish: "",
                introBangla: "",
                introEnglish: "",
                instructionBangla: "",
                instructionEnglish: "",
                noteBangla: "",
                noteEnglish: "",
                youtubeLinkBangla: "",
                youtubeLinkEnglish: "",
                subMenuInfoDTO: {
                    coreCategoryID: ''
                }
            },
            modulePointList: [],
            subMenuList: [],
            modulePoint: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            productName: '',
            errors: {},
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            thirdDropdownIsLoading: false
        };

        this.adminPointService = new AdminPointService();
        this.validatorUtility = new ValidatorUtility();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentDidMount = () => {
        this.getProductNameListByDefCOde();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }



    getProductNameListByDefCOde = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.adminPointService.fetchListByDefaultCode('T121', 0)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productNameList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    });
                } else {
                    this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Opps! Something went wrong' })
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
            });
    }

    onChangeProductName = (e) => {
        let { errors } = this.state;
        this.setState({ productName: e.target.value });
        errors["productName"] = '';

        if (e.target.value !== null) {
            this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false });
            this.adminPointService.fetchListByparentCategoryID(e.target.value)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ modulePointList: body, topProgressBar: false, secondDropdownIsLoading: false });
                        });
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resBody, modulePointList: [] })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                });
        } else {
            this.setState({ modulePointList: [], subMenuList: [], modulePoint: '' });
        }
        this.setState({ errors });
    }

    onChangeModulePoint = (e) => {
        let { errors } = this.state;
        this.setState({ modulePoint: e.target.value });
        errors["modulePoint"] = '';

        if (e.target.value !== null) {
            this.setState({ topProgressBar: true, thirdDropdownIsLoading: true, errorMsgVisible: false });
            this.adminPointService.fetchListByparentCategoryID(e.target.value)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ subMenuList: body, topProgressBar: false, thirdDropdownIsLoading: false });
                        });
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                });
        } else {
            let { tutorialInfoDetails } = this.state;
            tutorialInfoDetails.subMenuInfoDTO.coreCategoryID = null;
            this.setState({ subMenuList: [], tutorialInfoDetails });
        }
        this.setState({ errors });

    }

    onChangesubMenu = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["subMenu"] = '';
        tutorialInfoDetails.subMenuInfoDTO.coreCategoryID = e.target.value;
        this.setState({ tutorialInfoDetails, errors });

    }

    onChangeDefaultCode = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["defaultCode"] = '';
        tutorialInfoDetails.defaultCode = e.target.value;
        this.setState({ tutorialInfoDetails, errors });
    }

    // onChangeMenuBangla = (e) => {
    //     let { tutorialInfoDetails, errors } = this.state;
    //     errors["menuBangla"] = '';
    //     tutorialInfoDetails.menuBangla = e.target.value;
    //     this.setState({ tutorialInfoDetails, errors });
    // }

    // onChangeMenuEnglish = (e) => {
    //     let { tutorialInfoDetails, errors } = this.state;
    //     errors["menuEnglish"] = '';
    //     tutorialInfoDetails.menuEenglish = e.target.value;
    //     this.setState({ tutorialInfoDetails, errors });
    // }

    onChangeBreadcrumbBangla = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["breadcrumbBangla"] = '';
        tutorialInfoDetails.breadcrumbBangla = e.target.value;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeBreadcrumbEnglish = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["breadcrumbEnglish"] = '';
        tutorialInfoDetails.breadcrumbEnglish = e.target.value;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeTitleBangla = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["titleBangla"] = '';
        tutorialInfoDetails.titleBangla = e.target.value;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeTitleEnglish = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["titleEnglish"] = '';
        tutorialInfoDetails.titleEnglish = e.target.value;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeIntroBangla = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["introBangla"] = '';
        tutorialInfoDetails.introBangla = e.htmlValue;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeIntroEnglish = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["introEnglish"] = '';
        tutorialInfoDetails.introEnglish = e.htmlValue;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeInstructionBangla = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["instructionBangla"] = '';
        tutorialInfoDetails.instructionBangla = e.htmlValue;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeInstructionEnglish = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["instructionEnglish"] = '';
        tutorialInfoDetails.instructionEnglish = e.htmlValue;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeNoteBangla = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["noteBangla"] = '';
        tutorialInfoDetails.noteBangla = e.htmlValue;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeNoteEnglish = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["noteEnglish"] = '';
        tutorialInfoDetails.noteEnglish = e.htmlValue;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeYoutubeLinkBangla = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["youtubeLinkBangla"] = '';
        tutorialInfoDetails.youtubeLinkBangla = e.target.value;
        this.setState({ tutorialInfoDetails, errors });
    }

    onChangeYoutubeLinkEnglish = (e) => {
        let { tutorialInfoDetails, errors } = this.state;
        errors["youtubeLinkEnglish"] = '';
        tutorialInfoDetails.youtubeLinkEnglish = e.target.value;
        this.setState({ tutorialInfoDetails, errors });
    }

    onSubmitTutorialHandler = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        let { tutorialInfoDetails } = this.state;
        
        if (this.tutorialAddFormHandleError()) {
            this.adminPointService.createTutorialDetails(tutorialInfoDetails)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                        {   tutorialInfoDetails.defaultCode = tutorialInfoDetails.menuBangla = tutorialInfoDetails.menuEenglish = 
                            tutorialInfoDetails.subMenuInfoDTO.coreCategoryID = 
                            tutorialInfoDetails.breadcrumbBangla = tutorialInfoDetails.breadcrumbEnglish = tutorialInfoDetails.titleBangla = 
                            tutorialInfoDetails.titleEnglish = tutorialInfoDetails.youtubeLinkBangla = tutorialInfoDetails.youtubeLinkEnglish =
                            tutorialInfoDetails.introBangla = tutorialInfoDetails.introEnglish = tutorialInfoDetails.instructionBangla =
                            tutorialInfoDetails.instructionEnglish = tutorialInfoDetails.noteBangla = tutorialInfoDetails.noteEnglish = ''
                        }
                        this.setState({productName: '',modulePoint: '' });
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resBody, topProgressBar: false })
                            });
                    }
                }).catch(error => {
                    console.log("error", 'Connection Problem')
                    this.setState({ errorMsgVisible: false, topProgressBar: false })
                });
        }
    }

    tutorialAddFormHandleError = () => {
        let { errors, tutorialInfoDetails } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (this.state.productName === '' || this.state.productName === null) {
            formIsValid = false;
            errors["productName"] = "Product Name can't left empty.";
        }
        if (this.state.modulePoint === '' || this.state.modulePoint === null) {
            formIsValid = false;
            errors["modulePoint"] = "Module Point can't left empty.";
        }
        if (tutorialInfoDetails.subMenuInfoDTO.coreCategoryID === '' || tutorialInfoDetails.subMenuInfoDTO.coreCategoryID === null) {
            formIsValid = false;
            errors["subMenu"] = "Sub Menu can't left empty.";
        }
        if (tutorialInfoDetails.defaultCode === '') {
            formIsValid = false;
            errors["defaultCode"] = "Default Code can't left empty.";
        }
        // if (tutorialInfoDetails.menuBangla.trim() === '') {
        //     formIsValid = false;
        //     errors["menuBangla"] = "Menu Bangla can't left empty.";
        // }
        // if (tutorialInfoDetails.menuEenglish.trim() === '') {
        //     formIsValid = false;
        //     errors["menuEnglish"] = "Menu English can't left empty.";
        // }
        if (tutorialInfoDetails.breadcrumbBangla.trim() === '') {
            formIsValid = false;
            errors["breadcrumbBangla"] = "Breadcrumb Bangla can't left empty.";
        }
        if (tutorialInfoDetails.breadcrumbEnglish.trim() === '') {
            formIsValid = false;
            errors["breadcrumbEnglish"] = "Breadcrumb English can't left empty.";
        }
        if (tutorialInfoDetails.titleBangla.trim() === '') {
            formIsValid = false;
            errors["titleBangla"] = "Title Bangla can't left empty.";
        }
        if (tutorialInfoDetails.titleEnglish.trim() === '') {
            formIsValid = false;
            errors["titleEnglish"] = "Title English can't left empty.";
        }
        if (tutorialInfoDetails.introBangla.trim() === '') {
            formIsValid = false;
            errors["introBangla"] = "Intro Bangla can't left empty.";
        }
        if (tutorialInfoDetails.introEnglish.trim() === '') {
            formIsValid = false;
            errors["introEnglish"] = "Intro English can't left empty.";
        }
        if (tutorialInfoDetails.instructionBangla.trim() === '') {
            formIsValid = false;
            errors["instructionBangla"] = "Instruction Bangla can't left empty.";
        }
        if (tutorialInfoDetails.instructionEnglish.trim() === '') {
            formIsValid = false;
            errors["instructionEnglish"] = "Instruction English can't left empty.";
        }
        if (tutorialInfoDetails.noteBangla.trim() === '') {
            formIsValid = false;
            errors["noteBangla"] = "Note Bangla can't left empty.";
        }
        if (tutorialInfoDetails.noteEnglish.trim() === '') {
            formIsValid = false;
            errors["noteEnglish"] = "Note English can't left empty.";
        }

        if (tutorialInfoDetails.youtubeLinkBangla.trim() === '') {
            formIsValid = false;
            errors["youtubeLinkBangla"] = "YoutubeLink Bangla can't left empty.";
        } else if (!this.validatorUtility.isUrlValid(tutorialInfoDetails.youtubeLinkBangla) || tutorialInfoDetails.youtubeLinkBangla.includes("..")) {
            formIsValid = false;
            errors["youtubeLinkBangla"] = "Invalid URL";
        }

        if (tutorialInfoDetails.youtubeLinkEnglish.trim() === '') {
            formIsValid = false;
            errors["youtubeLinkEnglish"] = "YoutubeLink English can't left empty.";
        } else if (!this.validatorUtility.isUrlValid(tutorialInfoDetails.youtubeLinkEnglish) || tutorialInfoDetails.youtubeLinkEnglish.includes("..")) {
            formIsValid = false;
            errors["youtubeLinkEnglish"] = "Invalid URL";
        }

        this.setState({ errors });
        return formIsValid;
    }


    render() {
        let { tutorialInfoDetails, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let ProductList = []
        if (this.state.productNameList && this.state.productNameList.length) {
            ProductList = this.state.productNameList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let moduleList = []
        if (this.state.modulePointList && this.state.modulePointList.length) {
            moduleList = this.state.modulePointList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let subMenu = []
        if (this.state.subMenuList && this.state.subMenuList.length) {
            subMenu = this.state.subMenuList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    <Growl ref={(el) => this.growl = el} />
                    {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                    {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                    <div className="p-col-12 p-xl-12">

                        <div className=" p-grid nw-form-body">
                            {/* <div className="p-col-12 p-xl-6"> */}
                                <div className="p-col-12 p-xl-12">
                                    {this.state.firstDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Product Name <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Product Name"
                                                value={this.state.productName}
                                                onChange={this.onChangeProductName}
                                                options={ProductList}
                                                showClear={true}
                                                autoWidth={false}
                                                name="productName"
                                            />
                                        </div>
                                    }
                                    <span className="error-message">{this.state.errors["productName"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.secondDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Module / Point <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Module/Point"
                                                value={this.state.modulePoint}
                                                onChange={this.onChangeModulePoint}
                                                options={moduleList}
                                                showClear={true}
                                                autoWidth={false}
                                                name="modulePoint"
                                            />
                                        </div>
                                    }
                                    <span className="error-message">{this.state.errors["modulePoint"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.thirdDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Sub-Menu <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Sub-Menu"
                                                value={this.state.tutorialInfoDetails.subMenuInfoDTO.coreCategoryID}
                                                onChange={this.onChangesubMenu}
                                                options={subMenu}
                                                showClear={true}
                                                autoWidth={false}
                                                name="subMenu"
                                            />
                                        </div>
                                    }
                                    <span className="error-message">{this.state.errors["subMenu"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Default Code <span>*</span></span>
                                        <InputText
                                            value={tutorialInfoDetails.defaultCode}
                                            onChange={this.onChangeDefaultCode}
                                            className="custom-form-control"
                                            id="defaultCode"
                                            keyfilter="pint"
                                            name="defaultCode"
                                            placeholder="Enter Default Code"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors['defaultCode']}</span>
                                </div>

                                {/* <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon ">Breadcrumb Bangla <span>*</span></span>
                                        <InputText
                                            className="custom-form-control"
                                            value={tutorialInfoDetails.menuBangla}
                                            onChange={this.onChangeMenuBangla}
                                            id="menuBangla"
                                            name="menuBangla"
                                            placeholder="Enter Breadcrumb Bangla"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["menuBangla"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Breadcrumb English <span>*</span></span>
                                        <InputText
                                            className="custom-form-control"
                                            value={tutorialInfoDetails.menuEenglish}
                                            onChange={this.onChangeMenuEnglish}
                                            id="menuEnglish"
                                            name="menuEnglish"
                                            placeholder="Enter Breadcrumb English"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["menuEnglish"]}</span>
                                </div> */}

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Breadcrumb Bangla <span>*</span></span>
                                        <InputText
                                            className="custom-form-control"
                                            value={tutorialInfoDetails.breadcrumbBangla}
                                            onChange={this.onChangeBreadcrumbBangla}
                                            id="breadcrumbBangla"
                                            name="breadcrumbBangla"
                                            placeholder="Enter Breadcrumb Bangla"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["breadcrumbBangla"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Breadcrumb English <span>*</span></span>
                                        <InputText
                                            className="custom-form-control"
                                            value={tutorialInfoDetails.breadcrumbEnglish}
                                            onChange={this.onChangeBreadcrumbEnglish}
                                            id="breadcrumbEnglish"
                                            name="breadcrumbEnglish"
                                            placeholder="Enter Breadcrumb English"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["breadcrumbEnglish"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Title Bangla <span>*</span></span>
                                        {/* <InputTextarea
                                            placeholder='Enter Title Bangla'
                                            name="titleBangla"
                                            value={tutorialInfoDetails.titleBangla}
                                            onChange={this.onChangeTitleBangla}
                                            id="titleBangla"
                                        /> */}

                                        {/* <Editor
                                            value={tutorialInfoDetails.titleBangla}
                                            onTextChange={ (e) =>this.onChangeTitleBangla(e)}
                                            className="nw-inputtexteditor"
                                            id="titleBangla"
                                            name="titleBangla"
                                            placeholder="Enter Title Bangla"
                                        /> */}

                                        <InputText
                                            className="custom-form-control"
                                            value={tutorialInfoDetails.titleBangla}
                                            onChange={this.onChangeTitleBangla}
                                            id="titleBangla"
                                            name="titleBangla"
                                            placeholder="Enter Title Bangla"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["titleBangla"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Title English <span>*</span></span>
                                        {/* <InputTextarea
                                            placeholder='Enter Title English'
                                            name="titleEnglish"
                                            id="titleEnglish"
                                            value={tutorialInfoDetails.titleEnglish}
                                            onChange={this.onChangeTitleEnglish}
                                        /> */}

                                        {/* <Editor
                                            value={tutorialInfoDetails.titleEnglish}
                                            onTextChange={ (e) =>this.onChangeTitleEnglish(e)}
                                            className="nw-inputtexteditor"
                                            id="titleEnglish"
                                            name="titleEnglish"
                                            placeholder="Enter Title English"
                                        /> */}

                                        <InputText
                                            className="custom-form-control"
                                            value={tutorialInfoDetails.titleEnglish}
                                            onChange={this.onChangeTitleEnglish}
                                            id="titleEnglish"
                                            name="titleEnglish"
                                            placeholder="Enter Title English"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["titleEnglish"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Intro Bangla <span>*</span></span>
                                        {/* <InputTextarea
                                            placeholder='Enter Intro Bangla'
                                            name="introBangla"
                                            id="introBangla"
                                            value={tutorialInfoDetails.introBangla}
                                            onChange={this.onChangeIntroBangla}
                                        /> */}

                                        <Editor
                                            value={tutorialInfoDetails.introBangla}
                                            onTextChange={ (e) =>this.onChangeIntroBangla(e)}
                                            className="nw-inputtexteditor"
                                            id="introBangla"
                                            name="introBangla"
                                            placeholder="Enter Intro Bangla"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["introBangla"]}</span>
                                </div>

                            {/* </div> */}



                            {/* <div className="p-col-12 p-xl-6"> */}

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Intro English <span>*</span></span>
                                        {/* <InputTextarea
                                            placeholder='Enter Intro English'
                                            name="introEnglish"
                                            value={tutorialInfoDetails.introEnglish}
                                            onChange={this.onChangeIntroEnglish}
                                        /> */}

                                        <Editor
                                            value={tutorialInfoDetails.introEnglish}
                                            onTextChange={ (e) =>this.onChangeIntroEnglish(e)}
                                            className="nw-inputtexteditor"
                                            id="introEnglish"
                                            name="introEnglish"
                                            placeholder="Enter Intro English"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["introEnglish"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Instruction Bangla <span>*</span></span>
                                        {/* <InputTextarea
                                            placeholder='Enter Instruction Bangla'
                                            name="instructionBangla"
                                            id="instructionBangla"
                                            value={tutorialInfoDetails.instructionBangla}
                                            onChange={this.onChangeInstructionBangla}
                                        /> */}

                                        <Editor
                                            value={tutorialInfoDetails.instructionBangla}
                                            onTextChange={ (e) =>this.onChangeInstructionBangla(e)}
                                            className="nw-inputtexteditor"
                                            id="instructionBangla"
                                            name="instructionBangla"
                                            placeholder="Enter Instruction Bangla"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["instructionBangla"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Instruction English <span>*</span></span>
                                        {/* <InputTextarea
                                            placeholder='Enter Instruction English'
                                            name="instructionEnglish"
                                            id="instructionEnglish"
                                            value={tutorialInfoDetails.instructionEnglish}
                                            onChange={this.onChangeInstructionEnglish}
                                        /> */}

                                        <Editor
                                            value={tutorialInfoDetails.instructionEnglish}
                                            onTextChange={ (e) =>this.onChangeInstructionEnglish(e)}
                                            className="nw-inputtexteditor"
                                            id="instructionEnglish"
                                            name="instructionEnglish"
                                            placeholder="Enter Instruction English"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["instructionEnglish"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Note Bangla <span>*</span></span>
                                        {/* <InputTextarea
                                            placeholder='Enter Note Bangla'
                                            name="noteBangla"
                                            id="noteBangla"
                                            value={tutorialInfoDetails.noteBangla}
                                            onChange={this.onChangeNoteBangla}
                                        /> */}

                                        <Editor
                                            value={tutorialInfoDetails.noteBangla}
                                            onTextChange={ (e) =>this.onChangeNoteBangla(e)}
                                            className="nw-inputtexteditor"
                                            id="noteBangla"
                                            name="noteBangla"
                                            placeholder="Enter Note Bangla"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["noteBangla"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Note English <span>*</span></span>
                                        {/* <InputTextarea
                                            placeholder='Enter Note English'
                                            name="noteEnglish"
                                            value={tutorialInfoDetails.noteEnglish}
                                            onChange={this.onChangeNoteEnglish}
                                        /> */}
                                        <Editor
                                            value={tutorialInfoDetails.noteEnglish}
                                            onTextChange={ (e) =>this.onChangeNoteEnglish(e)}
                                            className="nw-inputtexteditor"
                                            id="noteEnglish"
                                            name="noteEnglish"
                                            placeholder="Enter Note English"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["noteEnglish"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Youtube Link Bangla <span>*</span></span>
                                        <InputText
                                            className="custom-form-control"
                                            value={tutorialInfoDetails.youtubeLinkBangla}
                                            onChange={this.onChangeYoutubeLinkBangla}
                                            id="youtubeLinkBangla"
                                            name="youtubeLinkBangla"
                                            placeholder="Enter Youtube Link Bangla"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["youtubeLinkBangla"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Youtube Link English <span>*</span></span>
                                        <InputText
                                            className="custom-form-control"
                                            value={tutorialInfoDetails.youtubeLinkEnglish}
                                            onChange={this.onChangeYoutubeLinkEnglish}
                                            id="instituteEmai"
                                            name="youtubeLinkEnglish"
                                            placeholder="Enter Youtube Link English"
                                        />

                                    </div>
                                    <span className="error-message">{this.state.errors["youtubeLinkEnglish"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12" />

                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                    </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Submit"
                                        icon="fas fa-check"
                                        onClick={this.onSubmitTutorialHandler}

                                    />
                                </div>


                            {/* </div> */}
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}