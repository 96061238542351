import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';

export class TeacherReferenceInfo extends Component {

    constructor(props) {
        super(props);
        this.teacherPortal = new TeacherPortal();
    }

    render() {

        let referenceContent = ''
        if (this.props.basicInfo.item) {
            console.log(this.props.basicInfo.item);
            if (this.props.basicInfo.item.staffReference) {
                let i = 0;
                referenceContent = this.props.basicInfo.item.staffReference.map((item) => {

                    i++
                    return (
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2" >
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Reference {i}</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Name</h4>
                                        <p>{item.referencerName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Organization</h4>
                                        <p>{item.organization || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Designation</h4>
                                        <p>{item.designation || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Address</h4>
                                        <p>{item.address || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Email</h4>
                                        <p>{item.email || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Relation</h4>
                                        <p>{item.relation || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )



                }

                )
            }


        }

        return referenceContent
    }
}