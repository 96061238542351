import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../myPoint/common/NetiContentLoader';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import CommonFuctionality from '../../common/CommonFuctionality';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ImageCropper } from '../../common/ImageCropper';

let urlId;
let imageType = '';
let errors = {};
export class AdminGalleryImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            visible: false,
            listVisible: false,
            updateVisible: false,
            dialogLoad: false,
            deleteVisible: false,
            dataTableSelection: [],
            galleryImages: [],
            galleryImagesCount: [],
            dataTableIsLoading: false,
            dataTableValue: [],
            uploadImage: {
                extention: '',
                contentPic: '',
                contentName: ''
            },
            reqObject: {
                cmsId: "",
                fileContent: "",
                fileName: "",
                fileSaveOrEditable: true,
                photoDetails: "",
                photoSerial: "",
                photoTitle: "",
                photoType: ""
            },
            errors: {},
            photoGalleryId: "",
            imageType: "",
            urlId: "",
            imageInputFieldIsLoading: false,
            homeReturnButton: false,

            cropperVisible: false,
            sliderCropperVisible: false,
            cropperObject: {
                //main class parameter
                main: {
                    viewport: { width: 370, height: 240 },
                    boundary: { width: 600, height: 480 },
                    showZoomer: true,
                    enableOrientation: false,
                },
                //bind parameter
                bind: {
                    url: '',
                    orientation: 4
                }
            },
            cropperObjForHomeSlider: {
                //main class parameter
                main: {
                    viewport: { width: 840, height: 473 },
                    boundary: { width: 880, height: 580 },
                    showZoomer: true,
                    enableOrientation: false,
                },
                //bind parameter
                bind: {
                    url: '',
                    orientation: 4
                }
            }
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.NetiFileHandler = new NetiFileHandler();
        this.reloadCoreUrlIdFromLocal();
    }

    openDialog = (value, type, photoId, rowData) => {

        // console.log('value', value, 'type', type, photoId, rowData);
        let { reqObject, errors, uploadImage, cropperObject } = this.state;
        reqObject.cmsId = urlId;
        reqObject.photoSerial = '';
        reqObject.photoTitle = '';
        reqObject.photoDetails = '';
        reqObject.fileContent = "";
        reqObject.fileName = '';
        reqObject.photoType = type;
        /*****/
        errors.photoDetails = "";
        errors.photoSerial = "";
        errors.photoTitle = "";
        errors.uploadImage = "";

        uploadImage.extention = '';
        uploadImage.contentPic = '';
        uploadImage.contentName = '';

        // if (type === "Home Slider") {
        //   let  main ={
        //         viewport: { width: 1280, height: 720 },
        //         boundary: { width: 1280, height: 720 },
        //         showZoomer: true,
        //             enableOrientation: false,
        //         },
        // }

        if (value == 'addImage') {
            this.setState({ topProgressBar: false, errorMsgVisible: false, visible: true, dialogLoad: true, reqObject, errors, uploadImage });
        } else {
            this.setState({ topProgressBar: false, errorMsgVisible: false, visible: true, updateVisible: true, dialogLoad: true, reqObject, errors, uploadImage });
            this.onClickUpdate(rowData);

        }
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    hideDialog = (value) => {

        if (value == 'updateImage') {
            this.setState({ visible: false, updateVisible: false, dialogLoad: false });
        } else {
            this.setState({ visible: false, updateVisible: false, dialogLoad: false });
            this.CommonFuctionality.blurDialogBackgroundDeActive()
        }

        this.setState({ sliderCropperVisible: false });
    }

    openImageListDialog = (value) => {
        this.setState({ listVisible: true, dialogLoad: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.onClickFetchgalleryImgeList(value);
    }

    openDeleteDialog = (photoId) => {
        this.setState({ deleteVisible: true, dialogLoad: true, photoGalleryId: photoId });
        this.CommonFuctionality.blurDialogBackgroundActive();

    }

    hideImageListDialog = (urlId) => {
        this.setState({ listVisible: false, dialogLoad: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    hideDeleteDialog = () => {
        this.setState({ deleteVisible: false, dialogLoad: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();

    }

    onChangeSerialNo = (e) => {
        let { reqObject, errors } = this.state;
        let value = e.target.value;
        reqObject.photoSerial = value;
        errors["photoSerial"] = '';
        this.setState({ reqObject, errors });
    }

    onChangeGalleryTitle = (e) => {
        let { reqObject, errors } = this.state;
        let value = e.target.value;
        reqObject.photoTitle = value;
        errors["photoTitle"] = '';
        this.setState({ reqObject, errors });
    }

    onChangeGalleryDetails = (e) => {
        let { reqObject, errors } = this.state;
        let value = e.target.value;
        reqObject.photoDetails = value;
        errors["photoDetails"] = '';
        this.setState({ reqObject, errors });
    }

    onLoadPic(e) {

        let { reqObject, errors, cropperObject, cropperObjForHomeSlider } = this.state;
        errors["uploadImage"] = "";

        let fileType = this.NetiFileHandler.getFileContentType(e.files[0].name);
        let supportedExtention = ['image/jpeg', 'image/jpg', 'image/png'];
        console.log('fileType', e);
        if (supportedExtention.includes(fileType)) {

            var reader = new FileReader();
            let photo = e.files[0];
            const scope = this;
            reader.readAsDataURL(photo);
            reader.onload = (evt) => {

                if (reqObject.photoType == "Home Slider") {

                    var image = new Image();
                    image.src = evt.target.result;
                    image.onload = () => {

                        console.log(`width : ${image.width} px`, `height: ${image.height} px`);

                        if (image.width === 840 && image.height === 473) {

                            if (this.NetiFileHandler.getMaxFileSizeIsValid(photo.size, 1048576)) {

                                let content = reader.result;
                                var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                                var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                                let album = {
                                    extention: photo.type,
                                    contentPic: urlStr,
                                    contentName: photo.name
                                };
                                reqObject.fileContent = urlStr;
                                reqObject.fileName = photo.name;
                                scope.setState({ reqObject, uploadImage: album, errors, cropperVisible: false });

                            } else {
                                errors["uploadImage"] = "File size can't be more than " + 1048576 / 1048576 + ' MB';
                                this.setState({ errors });
                            }

                        } else if (image.width > 840 && image.height > 473) {

                            let content = reader.result;
                            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                            let album = {
                                extention: photo.type,
                                contentPic: urlStr,
                                contentName: photo.name
                            };
                            reqObject.fileContent = urlStr;
                            reqObject.fileName = photo.name;
                            scope.setState({ reqObject, uploadImage: album, errors, sliderCropperVisible: true, cropperVisible: true });
                        }
                        else {
                            errors["uploadImage"] = "Image must be: 840px X 473px";
                            scope.setState({ errors });
                        }
                    };

                } else {
                    let content = reader.result;
                    var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                    var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                    let album = {
                        extention: photo.type,
                        contentPic: urlStr,
                        contentName: photo.name
                    };
                    reqObject.fileContent = urlStr;
                    reqObject.fileName = photo.name;
                    scope.setState({ reqObject, uploadImage: album, errors, cropperVisible: true });
                }
            }

            if (reqObject.photoType != "Home Slider") {
                errors["uploadImage"] = '';
                cropperObject.bind.url = photo.objectURL;
                this.setState({ errors, cropperObject });

                console.log('photo.objectURL', photo.objectURL);
            } else {
                errors["uploadImage"] = '';
                cropperObjForHomeSlider.bind.url = photo.objectURL;
                this.setState({ errors, cropperObjForHomeSlider });
                console.log('photo.objectURL', photo.objectURL);
            }

        }
        else {
            errors["uploadImage"] = "File format not supported";
            this.setState({ errors });
        }

    }

    getCroppedResult = (imageObj) => {

        let { uploadImage, errors, reqObject } = this.state;
        let maxSize = this.NetiFileHandler.getMaxFileSizeIsValid(imageObj.photoBlob.size, 1000000)// 825548 825.55kb
        if (maxSize) {

            reqObject.fileContent = imageObj.contentPic;
            uploadImage.contentPic = imageObj.contentPic;
            errors["uploadImage"] = '';

        } else {

            errors["uploadImage"] = "Image size can't be more than 1 MB";
            this.removeCropSection();
        }

        this.setState({
            uploadImage,
            errors,
            reqObject,
            uploadImage,
            sliderCropperVisible: false,
            cropperVisible: false
        });
    }

    removeCropSection = () => {

        let { uploadImage } = this.state;
        uploadImage.extention = '';
        uploadImage.contentPic = '';
        uploadImage.contentName = '';

        this.setState({ sliderCropperVisible: false, cropperVisible: false, uploadImage });
    }

    actionTemplate = (rowData) => {
        let { reqObject } = this.state;
        let photoId = rowData.photoGalleryId;
        // Object.assign(reqObject.photoGalleryId, photoId);
        return <div className="text-center">
            <span style={{ marginRight: '5px' }}>
                <Button
                    type="button"
                    icon="fas fa-pencil-alt"
                    className="nw-action-button"
                    onClick={() => this.openDialog('updateImage', 'photoType', photoId, rowData)}
                    tooltip="Update"
                />
            </span>

            <span>
                <Button
                    type="button"
                    icon="fas fa-trash"
                    className="nw-action-button p-button-danger"
                    onClick={() => this.openDeleteDialog(photoId)}
                    tooltip="Delete"
                />
            </span>

        </div>;
    }

    photoView = rowData => {
        // console.log("ROW DATA", rowData);
        let staticImg = '../assets/layout/images/avatar.png';
        return (
            <div className="p-grid download-software">
                <div className="p-col-12 p-xl-3">
                    <div className="table-image">
                        {
                            rowData.fileContent ?
                                <img src={("data:image/*;base64," + rowData.fileContent)} style={{ width: "80px" }} /> :
                                <img src={staticImg} style={{ width: "80px" }} />
                        }
                    </div>
                </div>
                <div className="p-col-12 p-xl-9">
                    <h4>{rowData.photoTitle}</h4>
                    <p>{rowData.photoDetails}</p>
                </div>
            </div>
        )
    }

    async reloadCoreUrlIdFromLocal() {
        urlId = await this.DwsService.getCmsIdFromLocalStorage();
        if (urlId) {
            this.setState({ homeReturnButton: false });
            this.onLoadfectPhotoCount(urlId);
        } else {
            this.setState({ homeReturnButton: true });
        }
    }

    errorHandler = () => {
        let { reqObject, errors } = this.state;
        let formIsValid = true;

        if (!reqObject.photoSerial) {
            formIsValid = false;
            errors["photoSerial"] = "Photo Serial can't left empty.";
        }

        if (!reqObject.photoTitle) {
            formIsValid = false;
            errors["photoTitle"] = "Photo Title can't left empty.";
        }

        // if (!reqObject.photoDetails) {
        //     formIsValid = false;
        //     errors["photoDetails"] = "Photo Details can't left empty.";
        // }
        if (!reqObject.fileContent || !this.state.uploadImage.contentPic) {
            formIsValid = false;
            errors["uploadImage"] = "Upload image can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onclickSubmitBtn = (value) => {

        let { reqObject } = this.state;

        if (this.errorHandler()) {
            this.setState({ topProgressBar: true, errorMsgVisible: true });
            this.DwsService.cmsInserGalleryImage(reqObject, value)
                .then(res => {
                    if (res.status == 201) {
                        this.CommonFuctionality.blurDialogBackgroundDeActive();
                        this.setState({ topProgressBar: false, errorMsgVisible: false, visible: false, dialogLoad: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully Saved' });
                        this.onLoadfectPhotoCount(urlId);
                    } else if (res.status == 202) {
                        this.CommonFuctionality.blurDialogBackgroundDeActive();
                        this.setState({ topProgressBar: false, errorMsgVisible: false, visible: false, listVisible: false, dialogLoad: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully Update' });
                        this.onLoadfectPhotoCount(urlId);
                    } else {
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'error', summary: 'error', detail: 'Unsuccessful' });
                    }

                }).catch(error => {
                    // errors["dropDownClassError"] = 'Connection Problem'
                    this.setState({ errors: errors })
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                })
        }
    }

    onLoadfectPhotoCount = (urlId) => {
        if (urlId) {
            this.DwsService.cmsFetchPhotoCount(urlId)
                .then(res => {
                    return res.json().then((body) => {
                        this.setState({ galleryImagesCount: body.item });
                    });
                }

                )
        }
    }

    onClickFetchgalleryImgeList = (imageType) => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        if (imageType) {
            this.setState({ dataTableValue: '', imageType: imageType });
            this.DwsService.cmsFetchPhotoList(urlId, imageType)
                .then(res => {
                    if (res.status == 200) {
                        return res.json().then((body) => {
                            this.setState({ dataTableValue: body.item, dataTableIsLoading: false, topProgressBar: false });
                            // console.log('dataTableValue', this.state.dataTableValue);
                            this.onLoadfectPhotoCount(this.state.urlId);
                        })
                    }
                }
                )
        }
    }

    deleteSubmit = (photoId, urlId) => {
        if (photoId) {
            let { imageType } = this.state;
            this.setState({ topProgressBar: true, errorMsgVisible: true });
            this.DwsService.cmsDeletePhotoImage(photoId)
                .then(res => {
                    if (res.status == 202) {
                        this.onClickFetchgalleryImgeList(imageType);
                        this.setState({ topProgressBar: false, errorMsgVisible: false, deleteVisible: false, dialogLoad: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully Deleted' });

                        // this.onLoadfectPhotoCount(this.state.urlId);
                    }

                })
        }
    }

    onClickUpdate = (rowData) => {
        let { reqObject } = this.state;
        // console.log('rowData', rowData);
        reqObject.cmsId = urlId;
        reqObject.fileContent = rowData.fileContent;
        reqObject.fileName = rowData.fileName;
        reqObject.fileSaveOrEditable = true;
        reqObject.photoDetails = rowData.photoDetails;
        reqObject.photoSerial = String(rowData.photoSerial);
        reqObject.photoTitle = rowData.photoTitle;
        reqObject.photoType = rowData.photoType;
        reqObject.photoGalleryId = rowData.photoGalleryId;
        // Object.assign(reqObject,  rowData);
        let album = {
            contentPic: rowData.fileContent,
            contentName: rowData.fileName
        };
        this.setState({ reqObject: reqObject, uploadImage: album });

    }

    removeUploadFile = () => {
        let { reqObject, uploadImage } = this.state

        uploadImage.contentPic = ''
        uploadImage.contentName = ''
        // uploadImage.fileSaveOrEditable = false

        reqObject.fileContent = ''
        reqObject.fileName = ''
        // reqObject.fileSaveOrEditable = false

        this.setState({ uploadImage, reqObject })

        // Object.assign(insertUserObj, uploadFile)
        // Object.assign(updateUserObj, uploadFile)
    }

    render() {

        let { imageType, topProgressBar, errorMsgVisible, errorMsgBody, updateVisible, galleryImages, reqObject, errors, galleryImagesCount, dataTableValue, photoGalleryId, urlId } = this.state;
        let photoSerial = [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
        ];
        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br />
            {/* <span className="upload-file-label">(370 x 240 px (recommended)</span> */}
        </div>
        var tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            {imageType} Photo List
            <span style={{ 'float': 'right' }}>Total Found: {this.state.dataTableValue.length}</span>
        </div>;
        console.log('cropperVisible', this.state.cropperVisible);

        return (
            <div className="p-fluid" >
                <NetiCMSSetupProgress />

                <Growl ref={(el) => this.growl = el} />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="layout-dashboard">
                    {this.state.homeReturnButton === false ?
                        <div className="p-fluid">
                            <div className="main-section download blur-section">
                                <div className="p-grid download-software">
                                    <div className="p-col-12 p-xl-3">
                                        <div class="description">
                                            <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">
                                                <div class="p-col-2 ui-g-nopad">
                                                    <Button
                                                        className=""
                                                        icon="fas fa-plus"
                                                        tooltip="Add Home Slider Info"
                                                        onClick={() => this.openDialog('addImage', 'Home Slider', 'photoID')}
                                                    />
                                                </div>
                                                <div class="p-col-12">
                                                    <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                        <label>Home Slider</label>
                                                        <label>Image Shape: 840 x 473</label>
                                                        <label>Image Type : JPG, JPEG, PNG</label>
                                                        <label>Image Size : 1 MB</label>
                                                        <label>Max : <Link to="#" onClick={(e) => this.openImageListDialog('Home Slider')} >{galleryImagesCount && galleryImagesCount.homeSlider ? galleryImagesCount.homeSlider : '0'}</Link> Images</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="p-col-12 p-xl-3">
                                    <div class="description">
                                        <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">
                                            <div class="p-col-2 ui-g-nopad">
                                                <Button
                                                    className=""
                                                    icon="fas fa-plus"
                                                    tooltip="Add Image Info"
                                                    onClick={() => this.openDialog('addImage', 'Title Banner')}
                                                />
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                    <label>Title Banner</label>
                                                    <label>Image Shape : 370 x 240</label>
                                                    <label>Image Type : JPEG, PNG</label>
                                                    <label>Image Size : 400 KB</label>
                                                    <label>Max : <Link to="#" onClick={(e) => this.openImageListDialog('Title Banner')} >{galleryImagesCount.titleBanner ? galleryImagesCount.titleBanner : '0'}</Link> Images</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                    <div className="p-col-12 p-xl-3">
                                        <div class="description">
                                            <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">
                                                <div class="p-col-2 ui-g-nopad">
                                                    <Button
                                                        className=""
                                                        icon="fas fa-plus"
                                                        onClick={() => this.openDialog('addImage', 'Gallery Photo')}
                                                        tooltip="Add Gallery Photo Info"
                                                    />
                                                </div>
                                                <div class="p-col-12">
                                                    <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                        <label>Gallery Photo</label>
                                                        <label>Image Shape : 370 x 240</label>
                                                        <label>Image Type : JPG, JPEG, PNG</label>
                                                        <label>Image Size : 500 KB</label>
                                                        <label>Max : <Link to="#" onClick={(e) => this.openImageListDialog('Gallery Photo')} >{galleryImagesCount && galleryImagesCount.galleryPhoto ? galleryImagesCount.galleryPhoto : "0"}</Link> Images</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-3">
                                        <div class="description">
                                            <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">
                                                <div class="p-col-2 ui-g-nopad">
                                                    <Button
                                                        className=""
                                                        icon="fas fa-plus"
                                                        onClick={() => this.openDialog('addImage', 'Class Room')}
                                                        tooltip="Add Class Room Info"
                                                    />
                                                </div>
                                                <div class="p-col-12">
                                                    <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                        <label>Class Room</label>
                                                        <label>Image Shape : 370 x 240</label>
                                                        <label>Image Type : JPG, JPEG, PNG</label>
                                                        <label>Image Size : 500 KB</label>
                                                        <label>Max : <Link to="#" onClick={(e) => this.openImageListDialog('Class Room')} >{galleryImagesCount && galleryImagesCount.classRoom ? galleryImagesCount.classRoom : "0"}</Link> Images</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="p-col-12 p-xl-3">
                                    <div class="description">
                                        <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">
                                            <div class="p-col-2 ui-g-nopad">
                                                <Button
                                                    className=""
                                                    icon="fas fa-plus"
                                                    onClick={() => this.openDialog('addImage', 'Gallery Photo 2')}
                                                />
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                    <label>Gallery Photo 2</label>
                                                    <label>Image Shape : 370 x 240</label>
                                                    <label>Image Type : JPEG, PNG</label>
                                                    <label>Image Size : 400 KB</label>
                                                    <label>Max : <Link to="#" onClick={(e) => this.openImageListDialog('Gallery Photo 2')} >{galleryImagesCount.gallery2 ? galleryImagesCount.gallery2 : "0"}</Link> Images</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                    <div className="p-col-12 p-xl-3">
                                        <div class="description">
                                            <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">
                                                <div class="p-col-2 ui-g-nopad">
                                                    <Button
                                                        className=""
                                                        icon="fas fa-plus"
                                                        tooltip="Add Image Info"
                                                        onClick={() => this.openDialog('addImage', 'History')}
                                                        tooltip="Add History Info"
                                                    />
                                                </div>
                                                <div class="p-col-12">
                                                    <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                        <label>History</label>
                                                        <label>Image Shape : 370 x 240</label>
                                                        <label>Image Type : JPG, JPEG, PNG</label>
                                                        <label>Image Size : 500 KB</label>
                                                        <label>Max : <Link to="#" onClick={(e) => this.openImageListDialog('History')} >{galleryImagesCount && galleryImagesCount.history ? galleryImagesCount.history : '0'}</Link> Images</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-3">
                                        <div class="description">
                                            <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">
                                                <div class="p-col-2 ui-g-nopad">
                                                    <Button
                                                        className=""
                                                        icon="fas fa-plus"
                                                        tooltip="Add Image Info"
                                                        onClick={() => this.openDialog('addImage', 'Infrastructure')}
                                                        tooltip="Add Infrastructure Info"
                                                    />
                                                </div>
                                                <div class="p-col-12">
                                                    <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                        <label>Infrustructure</label>
                                                        <label>Image Shape : 370 x 240</label>
                                                        <label>Image Type : JPG, JPEG, PNG</label>
                                                        <label>Image Size : 500 KB</label>
                                                        <label>Max : <Link to="#" onClick={(e) => this.openImageListDialog('Infrastructure')} >{galleryImagesCount && galleryImagesCount.infrastructure ? galleryImagesCount.infrastructure : '0'}</Link> Images</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-3">
                                        <div class="description">
                                            <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">
                                                <div class="p-col-2 ui-g-nopad">
                                                    <Button
                                                        className=""
                                                        icon="fas fa-plus"
                                                        tooltip="Add Image Info"
                                                        onClick={() => this.openDialog('addImage', 'Play Ground')}
                                                        tooltip="Add Play Ground Info"
                                                    />
                                                </div>
                                                <div class="p-col-12">
                                                    <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                        <label>Play Ground</label>
                                                        <label>Image Shape : 370 x 240</label>
                                                        <label>Image Type : JPG, JPEG, PNG</label>
                                                        <label>Image Size : 500 KB</label>
                                                        <label>Max : <Link to="#" onClick={(e) => this.openImageListDialog('Play Ground')} >{galleryImagesCount && galleryImagesCount.playGround ? galleryImagesCount.playGround : '0'}</Link> Images</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-3">
                                        <div class="description">
                                            <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">
                                                <div class="p-col-2 ui-g-nopad">
                                                    <Button
                                                        className=""
                                                        icon="fas fa-plus"
                                                        tooltip="Add Image Info"
                                                        onClick={() => this.openDialog('addImage', 'Computer Lab')}
                                                        tooltip="Add Computer Lab Info"
                                                    />
                                                </div>
                                                <div class="p-col-12">
                                                    <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                        <label>Computer Lab</label>
                                                        <label>Image Shape : 370 x 240</label>
                                                        <label>Image Type : JPG, JPEG, PNG</label>
                                                        <label>Image Size : 500 KB</label>
                                                        <label>Max : <Link to="#" onClick={(e) => this.openImageListDialog('Computer Lab')} >{galleryImagesCount && galleryImagesCount.computerLab ? galleryImagesCount.computerLab : '0'}</Link> Images</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-3">
                                        <div class="description">
                                            <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">
                                                <div class="p-col-2 ui-g-nopad">
                                                    <Button
                                                        className=""
                                                        icon="fas fa-plus"
                                                        tooltip="Add Image Info"
                                                        onClick={() => this.openDialog('addImage', 'Library')}
                                                        tooltip="Add Library Info"
                                                    />
                                                </div>
                                                <div class="p-col-12">
                                                    <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                                                        <label>Library</label>
                                                        <label>Image Shape : 370 x 240</label>
                                                        <label>Image Type : JPG, JPEG, PNG</label>
                                                        <label>Image Size : 500 KB</label>
                                                        <label>Max : <Link to="#" onClick={(e) => this.openImageListDialog('Library')} >{galleryImagesCount && galleryImagesCount.library ? galleryImagesCount.library : '0'}</Link> Images</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="dialog-section">
                    <Dialog
                        header={updateVisible ? reqObject.photoType + ' Update Photo' : reqObject.photoType + ' Add Photo'}
                        visible={this.state.visible}
                        modal={true}
                        onHide={() => this.hideDialog('uploadImage')}
                        className="nw-dialog"
                        maximizable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12">

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Serial No. <span>*</span>
                                            </span>
                                            <Dropdown
                                                placeholder="Select Photo Serial"
                                                value={reqObject.photoSerial}
                                                options={photoSerial}
                                                autoWidth={false}
                                                onChange={(e) => this.onChangeSerialNo(e)}
                                                name="photoType"
                                            />
                                        </div>
                                        <span className='error-message'>{errors['photoSerial']}</span>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Title <span>*</span>
                                            </span>
                                            <InputText
                                                id="photoTitle"
                                                value={reqObject.photoTitle}
                                                onChange={(e) => this.onChangeGalleryTitle(e)}
                                                placeholder="Enter Image Title"
                                                name="photoTitle"
                                            />
                                        </div>
                                        <span className='error-message'>{errors['photoTitle']}</span>
                                    </div>

                                    {/* <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                            <InputTextarea
                                                id="details"
                                                onChange={(e) => this.onChangeGalleryDetails(e)}
                                                value={reqObject.photoDetails}
                                                name="photoDetails"
                                                rows={5}
                                                cols={30}
                                                placeholder="Write Image Details"
                                            />
                                        </div>
                                        <span className='error-message'>{errors['photoDetails']}</span>
                                    </div> */}
                                    <div className="formControl">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload Image <span>*</span>
                                                <br />
                                                <span>(JPG/JPEG/PNG)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                {
                                                    this.state.uploadImage.contentPic ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    // onClick={e => this.setState({ uploadImage: { contentPic: '',fileContent: '' } })}
                                                                    onClick={this.removeUploadFile}
                                                                />
                                                                <img
                                                                    src={
                                                                        this.state.uploadImage.contentPic === '' ?
                                                                            'assets/images/demo.jpg'
                                                                            :
                                                                            "data:image/*;base64," + this.state.uploadImage.contentPic
                                                                    }
                                                                    style={{ "height": "80px" }}
                                                                />

                                                            </div>
                                                            <div className="image-title">{this.state.uploadImage.contentName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            chooseLabel={this.state.uploadImage.contentName || uploadFileLabel}
                                                            mode="basic"
                                                            accept="image/*"
                                                            onSelect={(e) => this.onLoadPic(e)}
                                                            auto={true}
                                                        />
                                                }

                                            </div>
                                        </div>
                                        {
                                            this.state.uploadImage && this.state.uploadImage.contentPic && this.state.cropperVisible && reqObject.photoType != "Home Slider" ?
                                                <ImageCropper
                                                    cropperObject={this.state.cropperObject}
                                                    getCroppedResult={this.getCroppedResult}
                                                    removeCropSection={this.removeCropSection}
                                                />
                                                : this.state.sliderCropperVisible ? <ImageCropper
                                                    cropperObject={this.state.cropperObjForHomeSlider}
                                                    getCroppedResult={this.getCroppedResult}
                                                    removeCropSection={this.removeCropSection}
                                                /> : ''
                                        }
                                        <span className='error-message'>{errors['uploadImage']}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={() => this.hideDialog()}
                                        />

                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            name="district"
                                            label={updateVisible ? 'Update' : 'Save'}
                                            icon="fas fa-check"
                                            disabled={this.state.cropperVisible ? true : false}
                                            onClick={() => this.onclickSubmitBtn(updateVisible ? 'update' : 'submit')}
                                        // disabled={topProgressBar}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header={imageType + " Photo List"}
                        visible={this.state.listVisible}
                        modal={true}
                        onHide={(e) => this.hideImageListDialog(e)}
                        className="nw-dialog"
                        maximizable
                    >
                        <div className="p-fluid">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table">
                                    {!this.state.dataTableIsLoading ?
                                        <DataTable
                                            value={dataTableValue}
                                            header={tableHeader}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            rows={10}
                                            columnResizeMode="fit"
                                            paginator={true}
                                        >
                                            <Column field="photoSerial" header="Serial No." style={{ width: '100px' }} />
                                            <Column field="" header="Image Info" body={this.photoView} />
                                            <Column field="action" header="Action" body={this.actionTemplate} style={{ width: '120px' }} />
                                        </DataTable>
                                        : this.NetiContentLoader.MyPointTableLoader()
                                    }
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog
                        header={imageType + " Delete Photo"}
                        visible={this.state.deleteVisible}
                        modal={true}
                        onHide={() => this.hideDeleteDialog()}
                        className="nw-confirm-dialog"
                        maximizable
                    >
                        <div className="p-fluid">
                            <div className="p-col-12 p-xl-12">
                                <div className="confirm-wrapper">
                                    <h3 className="text-center">Are you want to delete?</h3>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                            <Button
                                                label='No'
                                                icon='fas fa-times'
                                                className="p-button p-button-danger nw-button nw-button-multiple"
                                                onClick={() => this.hideDeleteDialog()}
                                            />
                                            <Button
                                                label='Yes'
                                                icon='fas fa-check'
                                                className="p-button p-button-primary nw-button nw-button-multiple"
                                                onClick={() => this.deleteSubmit(photoGalleryId, urlId)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }

}