import React, {Component} from 'react';
import NetizenLogo from "./assets/images/login/netizen_logo_original.png";


export class AppFooter extends Component {
	render() {
		
		return <div className="layout-footer nw-footer">

			
			<div>
				<div className="nw-footer-left">
					{/* <img src={NetizenLogo} /> */}
					{/* <span>Copyright © 2019 <u><strong>NETIZEN IT LIMITED</strong></u></span> */}
					<strong>Powered By Eduman</strong>
				</div>
				{/* <div className="p-col-6">
					<a href="/" className="logo-container">
						<img src="http://192.168.31.25:5000/assets/layout/images/NetiWorld-2-PNG.png" alt="babylon-layout"/>
					</a>
				</div> */}
				<div className="p-col footer-icons nw-footer-right">
					{/* <span>Connect with us: </span> */}
					<button className="p-link">
						<i className="fas fa-globe-asia"/>
					</button>
					<button className="p-link">
						<i className="fab fa-facebook"/>
					</button>
					<button className="p-link">
						<i className="fab fa-twitter"/>
					</button>
					<button className="p-link">
						<i className="fab fa-linkedin-in"/>
					</button>
				</div>
			</div>
		</div>
	}
}