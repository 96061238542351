import { BaseURIHolder } from "../../utils/BaseURIHolder";
import AuthService from "../../auth/AuthService";
import { UserOtherAccessService } from "../UserOtherAccessService";

export class AdminPointService {
  constructor() {
    this.baseURIHolder = new BaseURIHolder();
    this.Auth = new AuthService();
    this.userOtherAccessService = new UserOtherAccessService();
  }

  createNoticeInfo(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/core/notice/new";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchNoticeList() {
    let uri = this.baseURIHolder.getAdmin() + "/core/notice/list";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchNoticeListByNoticeID(noticeId) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/core/notice".concat("?noticeId=").concat(noticeId);
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updateNoticeInfo(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/core/notice/update";
    return this.Auth.putFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  updateOtherProduct(requestedObject) {
    // let uri = this.baseURIHolder.getAdmin() + "/core/notice/update";
    let uri = "http://192.168.0.7:8081/product/info/update";

    return this.Auth.putFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  saveReleaseInfo(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/release/info/new";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchReleaseNoteList(pageLimit, pageNo) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/release/info/list"
        .concat("?pageLimit=")
        .concat(pageLimit)
        .concat("&pageNo=")
        .concat(pageNo);
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  createNewProduct(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/products/new";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  createOtherProduct(requestedObject) {
    // let uri = this.baseURIHolder.getAdmin() + "/products/new";
    //will change later on
    let uri = "http://192.168.0.7:8081/product/info/save";

    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchNewProductList() {
    let uri = this.baseURIHolder.getAdmin() + "/products/list";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchOtherProductList() {
    // let uri = this.baseURIHolder.getAdmin() + "/product/info/list";
    let uri = "http://192.168.0.7:8081/product/info/list";

    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updateProductInfo(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/products/update";
    return this.Auth.putFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  updateProductStock(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/product/stock/new";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchUserRoleList() {
    let uri = this.baseURIHolder.getAdmin() + "/user/role/findAll";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchProductListByProductType(enableStatus, requestedObject) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/products/by/type?enableStatus=" +
      enableStatus;
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  productUserRoleAssign(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/product/role/assign";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchListOfProductRoleAssignByProduct(requestedObject, status) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/product/roles/by/product?assignStatus=" +
      status;
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchtAssignedProductUserRoleList() {
    let uri = this.baseURIHolder.getAdmin() + "/products/roles/get";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updateProductUserRole(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/product/role/update";
    return this.Auth.putFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  createCategoryType(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/core/category/new/type";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  createNewCategory(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/core/category/new/category";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchCategoryListByTypeStatus(status) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/core/category/by/typeStatus?typeStatus=" +
      status;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchListByDefaultCode(typeDefaultCode, typeStatus) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/core/category/types/by-typeDefaultCode?typeDefaultCode=" +
      typeDefaultCode +
      "&typeStatus=" +
      typeStatus;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
  createNewBankAccount(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/core/bank/account/new";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchtOfferProductList() {
    let uri = this.baseURIHolder.getAdmin() + "/product/offer/get";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  productOfferConfig(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/product/offer/config";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  updateBankAccount(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/core/bank/account/update";
    return this.Auth.putFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  getCoreBankAccounts() {
    let uri = this.baseURIHolder.getAdmin() + "/core/bank/account/list";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
  getAllPartnerAssignInfoList(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/partner/assign/info/list";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  getAllAssignedPartnersList() {
    let uri = this.baseURIHolder.getAdmin() + "/assigned/partners";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  productFixedRevenueConfig(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/fixed-revenue/config/new";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchListOfProductFixedRevenue() {
    let uri = this.baseURIHolder.getAdmin() + "/fixed-revenue/get";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updateProductFixedRevenue(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/fixed-revenue/update";
    return this.Auth.putFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  productLevelRevenueConfig(requestedObject) {
    console.log(requestedObject);
    let uri = this.baseURIHolder.getAdmin() + "/level-revenue/config";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  getpartnerAssignID(partnerAssignID) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/partner/assign/info?partnerAssignID=" +
      partnerAssignID;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchListOfProductLevelRevenue() {
    let uri = this.baseURIHolder.getAdmin() + "/level-revenue/get";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updateProductLevelRevenue(requestedObject) {
    console.log(requestedObject);
    let uri = this.baseURIHolder.getAdmin() + "/level-revenue/update";
    return this.Auth.putFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  assignPartner(requestedObject) {
    let uri = this.baseURIHolder.getAdmin() + "/partner/assign";
    return this.Auth.postFetch(uri, requestedObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchParentPartnerByDesign(designationDefaultCode) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/partner/assign/designation/by?designationDefaultCode=" +
      designationDefaultCode;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchTeamMemberInfoList(parentIdObj) {
    let uri = this.baseURIHolder.getAdmin() + "/partner/assign/team/members";
    return this.Auth.postFetch(uri, parentIdObj).catch((error) =>
      console.log("error", error)
    );
  }

  updateCategoryType(categoryTypeObject) {
    let uri = this.baseURIHolder.getAdmin() + "/core/category/update/type";
    return this.Auth.putFetch(uri, categoryTypeObject).catch((error) =>
      console.log("error", error)
    );
  }
  updateCmsMenuItem(categoryTypeObject) {
    let uri = this.baseURIHolder.getHostNetiCMS() + "/menu/item/status/update";
    return this.Auth.patchFetch(uri, categoryTypeObject).catch((error) =>
      console.log("error", error)
    );
  }

  updateCategoryName(categoryNameObject) {
    let uri = this.baseURIHolder.getAdmin() + "/core/category/update/category";
    return this.Auth.putFetch(uri, categoryNameObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchBankAccountListDetailData(bankId) {
    let uri = this.baseURIHolder.getAdmin() + "/core/bank/account?id=" + bankId;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  createDownloadCorner(downloadCornerInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/download/corner/new";
    return this.Auth.postFetch(uri, downloadCornerInfo).catch((error) =>
      console.log("error", error)
    );
  }

  getDownloadCornerInfoList(searchDataObj) {
    let uri = this.baseURIHolder.getAdmin() + "/download/corner/list";
    return this.Auth.postFetch(uri, searchDataObj).catch((error) =>
      console.log("error", error)
    );
  }

  getInfoByDownloadCornerID(downloadCornerID) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/download/corner/?id=" +
      downloadCornerID;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updateDownloadCornerInfo(downloadCornerDetails) {
    let uri = this.baseURIHolder.getAdmin() + "/download/corner/update";
    return this.Auth.putFetch(uri, downloadCornerDetails).catch((error) =>
      console.log("error", error)
    );
  }
  updateOfferProduct(offerProductObject) {
    let uri = this.baseURIHolder.getAdmin() + "/product/offer/update";
    return this.Auth.putFetch(uri, offerProductObject).catch((error) =>
      console.log("error", error)
    );
  }

  fetchListByparentCategoryID(parentCategoryID) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/core/category/types/by-parentCatID?parentCategoryID=" +
      parentCategoryID;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  createTutorialDetails(tutorialInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/tutorials/new";
    return this.Auth.postFetch(uri, tutorialInfo).catch((error) =>
      console.log("error", error)
    );
  }

  updateTutorialDetails(tutorialInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/tutorials/update";
    return this.Auth.putFetch(uri, tutorialInfo).catch((error) =>
      console.log("error", error)
    );
  }

  getAllTutorialDetails() {
    let uri = this.baseURIHolder.getAdmin() + "/tutorials";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
  updatePartnerFromPartnerList(offerProductObject) {
    let uri = this.baseURIHolder.getAdmin() + "/partner/assign/change-partner";
    return this.Auth.putFetch(uri, offerProductObject).catch((error) =>
      console.log("error", error)
    );
  }

  updateAreaFromPartnerList(offerProductObject) {
    let uri = this.baseURIHolder.getAdmin() + "/partner/assign/change-area";
    return this.Auth.putFetch(uri, offerProductObject).catch((error) =>
      console.log("error", error)
    );
  }

  updateParentFromPartnerList(offerProductObject) {
    let uri = this.baseURIHolder.getAdmin() + "/partner/assign/change-parent";
    return this.Auth.putFetch(uri, offerProductObject).catch((error) =>
      console.log("error", error)
    );
  }

  updateReleaseInfo(releaseInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/release/info/update";
    return this.Auth.putFetch(uri, releaseInfo).catch((error) =>
      console.log("error", error)
    );
  }

  getAssignablePointsByAdminPoint() {
    let accessPath = this.baseURIHolder.getAdmin().split("/");
    return this.userOtherAccessService.getAccessablePoints(
      accessPath[accessPath.length - 1]
    );
  }

  createAdminPointAssign(pointAssignInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/assign/point";
    return this.Auth.postFetch(uri, pointAssignInfo).catch((error) =>
      console.log("error", error)
    );
  }

  getAdminBannerList() {
    let uri = this.baseURIHolder.getAdmin() + "/core/banner/all";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  getBannerFileContent(bannerId) {
    let uri =
      this.baseURIHolder.getAdmin() + `/core/banner/by?bannerId=${bannerId}`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  createAdminPointBanner(bannerInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/core/banner/new";
    return this.Auth.postFetch(uri, bannerInfo).catch((error) =>
      console.log("error", error)
    );
  }

  createNewGlobalCalender(calenderInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/core/calendar/new";
    return this.Auth.postFetch(uri, calenderInfo).catch((error) =>
      console.log("error", error)
    );
  }

  fetchAllEventList() {
    let uri = this.baseURIHolder.getAdmin() + "/core/calendar/all";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  getEventListInfo(coreCategoryId) {
    let uri =
      this.baseURIHolder.getAdmin() +
      "/core/calendar/by?calendarId=" +
      coreCategoryId;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updateCaldenderEventListInfo(calenderUpdateInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/core/calendar/update";
    return this.Auth.putFetch(uri, calenderUpdateInfo).catch((error) =>
      console.log("error", error)
    );
  }

  getAllAssignedMemberList(partnerInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/partner/assigned/members";
    return this.Auth.postFetch(uri, partnerInfo).catch((error) =>
      console.log("error", error)
    );
  }

  findByParentCatTypeID(parentTypeID) {
    let uri =
      this.baseURIHolder.getAdmin() +
      `/partner/unassigned/area/by-parentid?parentTypeID=${parentTypeID}`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  findParentsByLayer(layer) {
    let uri =
      this.baseURIHolder.getHostNetiCMS() +
      `/menu/item/findByMenuLayer?menuLayer=${layer}`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  saveMenu(menuInfo) {
    //uri will be changed in live
    let uri = this.baseURIHolder.getHostNetiCMS() + `/menu/item/save`;
    return this.Auth.postFetch(uri, menuInfo).catch((error) =>
      console.log("error", error)
    );
  }

  fetchMenuList() {
    //uri will be changed in live
    let uri = this.baseURIHolder.getHostNetiCMS() + `/menu/item/list`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
  cmsMenuItemDeelete(urlId) {
    let uri =
      this.baseURIHolder.getHostNetiCMS() +
      "/menu/item/delete?menuItemId=" +
      urlId;
    return this.Auth.deleteFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
  updateCoreBanner(bannerInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/core/banner/update";
    return this.Auth.putFetch(uri, bannerInfo).catch((error) =>
      console.log("error", error)
    );
  }

  createUserNature(natureObj) {
    let uri = this.baseURIHolder.getAdmin() + "/new/nature-config";
    return this.Auth.postFetch(uri, natureObj).catch((error) =>
      console.log("error", error)
    );
  }

  getUnAssignedAreaList(typeDefCode) {
    let uri =
      this.baseURIHolder.getAdmin() +
      `/partner/unassigned/area?typeDefCode=${typeDefCode}`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  getPartnerInfoByCustomNetiID(customNetiID) {
    let uri =
      this.baseURIHolder.getAdmin() +
      `/partner-info/by?customNetiID=${customNetiID}`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updatePartnerActiveStatus(partnerInfo) {
    let uri =
      this.baseURIHolder.getAdmin() + "/partner/active-status/change/by";
    return this.Auth.putFetch(uri, partnerInfo).catch((error) =>
      console.log("error", error)
    );
  }

  getPurchaseAtAGlanceByproductID(productID) {
    let uri =
      this.baseURIHolder.getAdmin() +
      `/product/glance/by?productID=${productID}`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  findGradeWiseLevelAmount(levelRevConfigID) {
    let uri =
      this.baseURIHolder.getAdmin() +
      `/partner/grade/details/by?levelRevConfigID=${levelRevConfigID}`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  saveAdminBankBranch(branchInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/bank/branch";
    return this.Auth.postFetch(uri, branchInfo).catch((error) =>
      console.log("error", error)
    );
  }

  findDFPSpaymentReportList() {
    let uri = this.baseURIHolder.getAdmin() + `/core/clientSettlements`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  saveDFPSpaymentForInstitute(paymentInfo) {
    let uri = this.baseURIHolder.getAdmin() + "/core/clientSettlement";
    return this.Auth.postFetch(uri, paymentInfo).catch((error) =>
      console.log("error", error)
    );
  }

  findUserNatureConfigList() {
    let uri = this.baseURIHolder.getAdmin() + `/user-nature/configs`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetch_Bank_BranchList() {
    let uri = this.baseURIHolder.getAdmin() + `/all/bank/branches`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetch_Assigned_PointList() {
    let uri = this.baseURIHolder.getAdmin() + `/all/assigned/point/by-admin`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
}
