import { PARTNER_POINT } from './PointWiseRouteConsts';

export const PartnerPointMenu = {

    items: [
        {
            label: 'Partner Point', icon: 'fas fa-user-tie',
            items: [

                // {
                //     label: 'Eduman', icon: '', //pi pi-fw pi-dollar
                //     items: [
                //         { label: 'Pre-Paid Bill', icon: '', to: PARTNER_POINT.PRE_PAID_BILL }, //pi pi-fw pi-money-bill
                //         { label: 'Post-Paid Bill', icon: '', to: PARTNER_POINT.POST_PAID_BILL }, //pi pi-fw pi-money-bill
                //         { label: 'Message Transfer', icon: '', to: PARTNER_POINT.MESSAGE_TRANSFER }, //pi pi-fw pi-envelope
                //         { label: 'Eduman Order', icon: '', to: PARTNER_POINT.EDUMAN_ORDER }, //pi pi-fw pi-envelope
                //         { label: 'Bill Discount', icon: '', to: PARTNER_POINT.EM_BILL_DISCOUNT } //pi pi-fw pi-envelope
                //     ]
                // },

                {
                    label: 'Order Submit', icon: '',
                    items: [
                        { label: 'New EM ID', icon: '', to: PARTNER_POINT.EDUMAN_ORDER }, //pi pi-fw pi-envelope
                        { label: 'EM Bill Discount', icon: '', to: PARTNER_POINT.EM_BILL_DISCOUNT },//pi pi-fw pi-envelope
                        { label: 'Domain & Hosting', icon: '', to: PARTNER_POINT.DOMAIN_HOSTING_ORDER },
                    ]
                },
                {
                    label: 'Payment', icon: '',
                    items: [
                        { label: 'Postpaid Bill', icon: '', to: PARTNER_POINT.POST_PAID_BILL }, //pi pi-fw pi-money-bill
                        { label: 'Prepaid Bill', icon: '', to: PARTNER_POINT.PRE_PAID_BILL }, //pi pi-fw pi-money-bill
                    ]
                },
                
                {
                    label: 'Transfer', icon: '',
                    items: [
                        { label: 'SMS to Eduman', icon: '', to: PARTNER_POINT.MESSAGE_TRANSFER },
                    ]
                },
                // {
                //     label: 'Send Notification', icon: '',
                //     items: [
                //         { label: 'Eduman Due Info', icon: '', to: PARTNER_POINT.PARTNER_SEND_NOTIFICATION_SMS},
                //     ]
                // },
                // {
                //     label: 'Rating', icon: '', //pi pi-fw pi-money-bill
                //     items: [
                //         { label: 'Provide To Team Lead', icon: '', to: PARTNER_POINT.RATING_TO_TEAM_LEAD }, //pi pi-fw pi-tag
                //         { label: 'Provide To Team Member', icon: '', to: PARTNER_POINT.RATING_TO_TEAM_MEMBER }, //pi pi-fw pi-tag
                //         { label: 'Provide By Partner', icon: '', to: PARTNER_POINT.RATING_BY_PARTNER}, //pi pi-fw pi-tag
                //     ]
                // },

                {
                    label: 'Reports'
                },
                {
                    label: 'Dashboard', icon: '',//pi pi-fw pi-dollar
                    items: [
                        { label: 'Eduman', icon: '', to: PARTNER_POINT.EM_CURRENT_STATUS },//pi pi-fw pi-home
                        // { label: 'Partner Dashboard', icon: '', to: PARTNER_POINT.PARTNER_DASHBOARD }
                        // { label: 'Self Dashboard', icon: '', to: PARTNER_POINT.PARTNER_SELF_DASHBOARD},//pi pi-fw pi-home
                        // { label: 'Team Dashboard', icon: '', to: PARTNER_POINT.PARTNER_TEAM_DASHBOARD},//pi pi-fw pi-home
                        { label: 'Perfomance', icon: '', to: PARTNER_POINT.PARTNER_PERFOMANC_BOARD},//pi pi-fw pi-home
                        // { label: 'Partner Score', icon: '', to: PARTNER_POINT.PARTNER_SCORE_BOARD},//pi pi-fw pi-home
                    ]
                },
                {
                    label: 'Tree View', icon: '',//pi pi-fw pi-dollar
                    items: [
                        // { label: 'Due Statement', icon: '', to: PARTNER_POINT.DUE_STATEMENT},  
                        { label: 'Due Bill', icon: '', to: PARTNER_POINT.PARTNER_DUE_STATEMENT_TREE },

                        { label: 'Bill Collection', icon: '', to: PARTNER_POINT.PARTNER_TEAM_BILL_COLLECTION }, //TAB VIEW
                        // {label: 'Area Wise Pre-Paid Deposit', icon: '',  to: PARTNER_POINT.AREA_WISE_PREPAID_DEPOSIT}, //TAB VIEW
                        // {label: 'Area Wise Post-Paid Collection', icon: '',  to: PARTNER_POINT.AREA_WISE_POSTPAID_COLLECTION},//TAB VIEW

                        { label: 'Point Info', icon: '', to: PARTNER_POINT.TEAM_WISE_PURCHASE },
                        { label: 'Income Info', icon: '', to: PARTNER_POINT.PARTNER_INCOME_SUMMARY },
                        { label: 'Quantity Info', icon: '', to: PARTNER_POINT.PRODUCT_TYPE_WISE },
                    ]
                },
                {
                    label: 'Details View', icon: '',//pi pi-fw pi-dollar
                    items: [
                        { label: 'Product Revenue', icon: '', to: PARTNER_POINT.PARTNER_REVENUE_DISTRIBUTION_INFO },
                        { label: 'Bill Invoice', icon: '', to: PARTNER_POINT.EM_INSTITUTE_INVOICE },
                        { label: 'Money Receipt', icon: '', to: PARTNER_POINT.EM_BILL_REPORT },
                        { label: 'Team Bill Collection', icon: '', to: PARTNER_POINT.PARTNER_BILL_COLLECTION },
                        { label: 'Team Purchase Info', icon: '', to: PARTNER_POINT.PARTNER_POINT_PRODUCT_PURCHASE_DETAILS },

                    ]
                },
                
            ]
        }
    ]
}