import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';

export class MonthlyDisbursmentAmountList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [
                // {"date":"27-12-2018", "netiId":"test ID", "name":"test Name", "remainingBalance":"5555" },
            ],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0
        }

        this.managementPointService = new ManagementPointService();
    }

    componentWillMount() {

        let { searchObj } = this.state


        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.managementPointService.fetchMonthlyBalanceTransactionListByType(searchObj.urlType, searchObj.year, searchObj.month, searchObj.type)
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        body.map(item => {
                            item.date = NetiDateUtils.getAnyShortForm(item.date, 'DD-MMM-YYYY')
                        })
                        this.setState({ dataTableValue: body });

                        this.setState({
                            totalCount: body.map(item => item.count).reduce((a, b) => (a + b)),
                            totalWithdrawAmount: body.map(item => item.amount).reduce((a, b) => (a + b))
                        })

                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    })
                } else if (res.status == 404) {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'No Data Found' });
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody } = this.state;
        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Disbursment List (Monthwise) <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={4} />
                <Column footer={totalPaidAmountBody} />
                <Column footer={totalPaidAmountBody} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className="p-grid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <div className="nw-data-table">
                            {/* {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                            <DataTable
                                header={header}
                                footerColumnGroup={footer}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={this.state.dataTableValue}
                            //selection={this.state.dataTableSelection}
                            >
                                <Column field="date" header="Date" filter={true} />
                                <Column field="" header="Total Row Found" filter={true} />
                                <Column field="" header="Total Neti ID Found" filter={true} />
                                <Column field="" header="Unique Neti ID Found" filter={true} />
                                <Column field="" header="Total Disbursment Amount" filter={true} />
                                <Column field="" header="Total Tax Amount" filter={true} />



                            </DataTable>

                            {/* :
                                    
                                    <BlankDataTableAnim/> 
                            } */}
                        </div>
                    </div>

                </div>



            </div>
        )
    }
}