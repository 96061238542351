import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { BijoyPointService } from "../../../service/bijoyPoint/BijoyPointService";
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { PartnerPointService } from "../../../service/partnerPoint/PartnerPointService";
import { GuestPointService } from "../../../service/guestAccessPoint/GuestPointService";
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
let cloneStateBeforeMount;

let profile;

export class DeviceNewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topProgressBar: false,
      userPassword: false,
      userType: '',
      purchaseCodeSearchViewError: false,
      purchaseCodeSearchBtnDisabled: true,
      purchaseCodeSearchErrorMsgBody: '',
      firstInputFieldIsLoading: false,
      purchaseCodeSearchView: false,
      newUserInfoSubmitError: [],
      purchaseInformation: {
        productName: '',
      },
      newUserInfo: {
        deviceId: "",
        purchaseCode: "",
        mobileNo: ""
      }
    };

    this.BijoyPointService = new BijoyPointService();
    this.guestPointService = new GuestPointService();
    this.PartnerPointService = new PartnerPointService();
    this.NetiDateUtils = new NetiDateUtils();
    this.NetiContentLoader = new NetiContentLoader();
  }

  componentWillMount() {
    cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
  }

  componentDidMount() {
    profile = JSON.parse(localStorage.getItem('Profile'));
    if (!profile) {
      this.setState({ userType: 'ROLE_GUEST' });
      console.log("guest user", profile);
    } else {
      console.log("bijoy user", profile);
      this.setState({ userType: 'ROLE_BIJOY' });
    }
  }

  deviceIDHandler = event => {
    let { newUserInfo } = this.state;
    newUserInfo.deviceId = event.target.value;
    this.setState({ newUserInfo });
  };

  purchaseCodeHandler = event => {
    let { newUserInfo } = this.state;
    newUserInfo.purchaseCode = event.target.value;
    this.setState({ newUserInfo });
  };

  mobileNumberHandler = event => {
    let { newUserInfo } = this.state;
    newUserInfo.mobileNo = event.target.value
      .replace(/-/g, "")
      .replace(/\s/g, "")
      .replace(/[+]/g, "");
    this.setState({ newUserInfo });
  };

  // extend search bar
  focusSearch = e => {
    this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white" });
  }

  blurSearch = e => {
    this.setState({ searchLabel: '', addSearchBtnClass: "" });
  }

  purchaseCodeCheck = (e) => {
    let url;
    this.setState({ purchaseInformation: cloneStateBeforeMount.purchaseInformation, purchaseCodeSearchErrorMsgBody: '', purchaseCodeSearchView: false });

    if (this.purchaseCodeError()) {
      this.setState({ topProgressBar: true, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: true, errorMsgVisible: false, purchaseCodeSearchView: true });
      if (this.state.userType === "ROLE_BIJOY") {
        url = this.PartnerPointService.getUserPurchaseCode(this.state.newUserInfo.purchaseCode)

      } else {
        url = this.guestPointService.findPurchaseCodeInfoByGuestUser(this.state.newUserInfo.purchaseCode)
      }

      if (url) {
        console.log('under if url',url);

        url.then(res => {
          if (res.status === 302) {
            return res.json().then((body) => {
              let { purchaseInformation } = this.state
              purchaseInformation.productName = body.productPurchaseLogDTO.productInfoDTO.productName;
              purchaseInformation.usedStatus = body.usedStatus;
              purchaseInformation.purchaseDate = NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
              this.setState({ purchaseInformation, topProgressBar: false, purchaseCodeSearchBtnDisabled: false, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: false, errorMsgVisible: false, purchaseCodeSearchView: true });
            })
          } else {
            this.PartnerPointService.Auth.handleErrorStatus(res)
              .then((resp) => {
                this.setState({
                  firstInputFieldIsLoading: false,
                  purchaseCodeSearchBtnDisabled: true,
                  topProgressBar: false,
                  purchaseCodeSearchErrorMsgBody: resp,
                  purchaseCodeSearchViewError: true,
                  purchaseCodeSearchView: true,
                  purchaseInformation: {
                    productName: ''
                  }
                });
              })
          }
        }).catch(error => {
          this.setState({ topProgressBar: false, purchaseCodeSearchBtnDisabled: true, firstInputFieldIsLoading: false, errorMsgVisible: true, purchaseCodeSearchView: false, purchaseCodeSearchViewError: false, errorMsgBody: 'Unable to load. Please check your connection.' })
        })
      }
      // this.setState({purchaseCodeSearchBtnDisabled:false})
    }
  }

  purchaseCodeError = (e) => {
    let { newUserInfo, newUserInfoSubmitError } = this.state;
    let formIsValid = true;
    if (newUserInfo.purchaseCode === "") {
      formIsValid = false;
      newUserInfoSubmitError["purchaseCode"] = "Purchase Code can't left empty";
    }
    if (isNaN(newUserInfo.purchaseCode)) {
      formIsValid = false;
      this.state.newUserInfoSubmitError["purchaseCode"] =
        "Invalid Purchase Code";
    }
    this.setState({ newUserInfoSubmitError });
    return formIsValid;
  }

  submitNewUser = () => {
    let url;
    let purchaseCodeUrl;
    let blurEffectFooter = document.getElementsByClassName('password-input-field')[0];
    let passwordOverlay = document.getElementsByClassName('password-overlay')[0];

    // blurEffectFooter.style.filter =  "blur(0px)";
    // passwordOverlay.style.display = "none"

    if (this.errorHandler()) {
    this.setState({ topProgressBar: true, errorMsgVisible: false });
    let { newUserInfo } = this.state;
    if (this.state.userType === "ROLE_BIJOY") {
      url = this.BijoyPointService.bijoyDeviceUnlock(newUserInfo);
    } else {
      url = this.guestPointService.unlockBijoyByGuest(newUserInfo);
    }

    if (url) {
      console.log('submit url',url);
      url.then(res => {
        if (res.status === 201) {
          this.growl.show({
            severity: "success",
            summary: "Success Message",
            detail: "New user create successfully"
          });
          this.setState({ topProgressBar: false });
         
          if (this.state.userType === "ROLE_BIJOY") {
            purchaseCodeUrl =  this.BijoyPointService.getBijoyDeviceInfo(newUserInfo.purchaseCode)
          } else{
            purchaseCodeUrl =  this.guestPointService.getBijoyPasswordByPurchaseCode(newUserInfo.purchaseCode)
          }

if(purchaseCodeUrl) {
         purchaseCodeUrl.then(res => {
            if (res.status === 200) {
              return res.json().then(body => {
                this.setState({ userPassword: body.password });
                this.setState({ topProgressBar: false });

                blurEffectFooter.style.filter = "blur(0px)"
                passwordOverlay.style.display = "none"
              });
            }
          });
        }

        } else {
          this.BijoyPointService.Auth.handleErrorStatus(res).then(errorRes => {
            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorRes });

            blurEffectFooter.style.filter = "blur(4px)"
            passwordOverlay.style.display = "block"
          });
        }
      }).catch(error => {
        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load datatable. Please check connection' })
      });
    }
      }
  };

  errorHandler() {
    let { newUserInfo, newUserInfoSubmitError } = this.state;
    let formIsValid = true;
    if (newUserInfo.deviceId === "") {
      formIsValid = false;
      newUserInfoSubmitError["deviceId"] = "Device ID can't left empty";
    }
    if (isNaN(newUserInfo.deviceId)) {
      formIsValid = false;
      this.state.newUserInfoSubmitError["deviceId"] = "Invalid Device ID";
    }
    if (newUserInfo.purchaseCode === "") {
      formIsValid = false;
      newUserInfoSubmitError["purchaseCode"] = "Purchase Code can't left empty";
    }
    if (isNaN(newUserInfo.purchaseCode)) {
      formIsValid = false;
      this.state.newUserInfoSubmitError["purchaseCode"] =
        "Invalid Purchase Code";
    }
    if (newUserInfo.mobileNo === "") {
      formIsValid = false;
      newUserInfoSubmitError["mobileNo"] = "Mobile No. can't left empty.";
    }
    this.setState({ newUserInfoSubmitError });
    return formIsValid;
  }

  render() {
    console.log("userType", this.state.userType);

    let { topProgressBar, errorMsgVisible, errorMsgBody, firstInputFieldIsLoading } = this.state;

    return (

      <div className="p-fluid">
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <Growl ref={el => (this.growl = el)} position="topright" />
        <div className="main-section">
          <div className="p-grid nw-form">
            <div className="p-col-12 p-xl-8 seprator">
              <div className="nw-form-body">
                <div className="p-grid seprator-inside">
                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Device ID check <span>*</span>
                      </span>
                      <InputText
                        className="custom-form-control"
                        id="deviceID"
                        value={this.state.newUserInfo.deviceId}
                        name="deviceID"
                        placeholder="Enter Device ID"
                        onChange={event => this.deviceIDHandler(event)}
                        keyfilter="pint"
                      />
                    </div>
                    <span className="error-message">
                      {(this.state.newUserInfo.deviceId === "" ||
                        isNaN(this.state.newUserInfo.deviceId)) &&
                        this.state.newUserInfoSubmitError.deviceId}
                    </span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Purchase Code <span>*</span>
                      </span>
                      <InputText
                        id="deviceID"
                        style={{ width: "15%" }}
                        name="deviceID"
                        placeholder="Enter Purchase Code"
                        onChange={event => this.purchaseCodeHandler(event)}
                        keyfilter="pint"
                        onFocus={this.focusSearch}
                        onBlur={this.blurSearch}
                      />
                      <Button
                        className={"p-button-animation " + this.state.addSearchBtnClass}
                        label={this.state.searchLabel}
                        icon="fas fa-search"
                        onClick={(e) => this.purchaseCodeCheck(e)}
                        onFocus={this.focusSearch}
                        onBlur={this.blurSearch}
                      />
                    </div>
                    <span className="error-message">
                      {(this.state.newUserInfo.purchaseCode === "" ||
                        isNaN(this.state.newUserInfo.purchaseCode)) &&
                        this.state.newUserInfoSubmitError.purchaseCode}
                    </span>
                  </div>

                  <div style={{ width: '100%' }}>

                    {this.state.purchaseCodeSearchView ?
                      <div className="p-col-12 p-xl-12">
                        <div className="nw-search-view">
                          {this.state.purchaseCodeSearchViewError ?
                            <center className="error-message">{this.state.purchaseCodeSearchErrorMsgBody}</center> :
                            <div>


                              {
                                this.state.purchaseInformation.productName === '' ? '' :
                                  <div>
                                    <div className="p-col-12 p-xl-12">
                                      {firstInputFieldIsLoading ? this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                          <span className="task-badge found"></span>
                                          <span className="p-inputgroup-addon">Product Name</span>
                                          <span className="p-inputgroup-colon">:</span>
                                          <div className="nw-inputgroup-desc">
                                            {this.state.purchaseInformation.productName}
                                          </div>

                                        </div>
                                      }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                      {firstInputFieldIsLoading ? this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                          <span className="task-badge found"></span>
                                          <span className="p-inputgroup-addon">Use Status</span>
                                          <span className="p-inputgroup-colon">:</span>
                                          <div className="nw-inputgroup-desc">
                                            {(this.state.purchaseInformation.usedStatus === 0) ? "Unused" : this.state.purchaseInformation.usedStatus}
                                          </div>

                                        </div>
                                      }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                      {firstInputFieldIsLoading ? this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                          <span className="task-badge found"></span>
                                          <span className="p-inputgroup-addon">Purchase Date</span>
                                          <span className="p-inputgroup-colon">:</span>
                                          <div className="nw-inputgroup-desc">
                                            {this.state.purchaseInformation.purchaseDate}
                                          </div>
                                        </div>
                                      }
                                    </div>

                                  </div>

                              }


                            </div>
                          }
                        </div>
                      </div>
                      :
                      ''
                    }
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Mobile No. <span>*</span>
                      </span>
                      <InputText
                        className="custom-form-control"
                        id="deviceID"
                        name="deviceID"
                        placeholder="Enter Mobile No."
                        onChange={event => this.mobileNumberHandler(event)}
                        keyfilter="pint"
                        maxlength={11}
                      />
                    </div>
                    <span className="error-message">
                      {this.state.newUserInfo.mobileNo === "" &&
                        this.state.newUserInfoSubmitError.mobileNo}
                    </span>
                  </div>

                  <div className="p-col-12 p-xl-12 nw-button-parent">

                    <div className="required-field">
                      (<span>*</span>) required fields
                      </div>

                    <Button
                      name="submit"
                      label="Submit"
                      icon="fas fa-check"
                      className="p-button p-button-primary nw-button nw-button-left"
                      onClick={event => this.submitNewUser(event)}
                      disabled={this.state.purchaseCodeSearchBtnDisabled}
                    />
                  </div>

                  {/* <div className="p-col-12 p-xl-12">
                    <div className="text-center">
                      {this.state.userPassword == "" ? (
                        <div>
                          <div className="devicePassword">
                            <i className="fa fa-lock" />
                          </div>
                          <span>Password will be generated here</span>
                        </div>
                      ) : (
                          <div>
                            <div className="devicePassword">
                              <i className="fa fa-check" />
                            </div>
                            <span>
                              <b>Your Password is: </b>
                              {this.state.userPassword}
                            </span>
                          </div>
                        )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="p-col-12 p-xl-4 p-col-nogutter">
              <div className="p-col-12 p-xl-12">
                {this.state.dataTableIsLoading ?
                  this.NetiContentLoader.WalletLoaderContent() :
                  <div className="nw-wallet">
                    <center className="container-password">
                      <h2><i className={!this.state.userPassword ? "fas fa-lock fa-3x" : "fas fa-unlock fa-3x"} /></h2>

                      <div className="password-title">
                        Your Password
                              </div>

                      <div className="password-field">

                        <div className="password-overlay"></div>

                        <InputText
                          className="password-input-field"
                          placeholder="Enter Mobile No."
                          value={!this.state.userPassword ? "99998888855555555555555558" : this.state.userPassword}
                        // onChange={event => this.mobileNumberHandler(event)}
                        />

                      </div>
                      <br />
                      <div className="footer-text">
                        {!this.state.userPassword ? "Please fill out the left form to reveal your password." : "Use this password to unlock Bijoy content on your PC."}</div>
                    </center>
                  </div>

                }
              </div>
            </div>


          </div>
        </div>

      </div>
    );
  }
}
