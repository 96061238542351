import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { WalletBalance } from '../common/WalletBalance';
import { WalletBalanceWithdrawList } from './WalletBalanceWithdrawList';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { UserOtherAccessService } from '../../../service/UserOtherAccessService';
import { BalanceService } from '../../../service/myPoint/BalanceService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CommonFuctionality from '../../common/CommonFuctionality';

let walletBalance = 0;

export class WalletBalanceWithdraw extends Component {

	constructor(props) {
		super(props)
		this.state = {
			bankList: [],
			dataTableValue: [],
			taggedBankInfo: {
				userBankAccountInfoDTO: {
					coreBankBranchInfoDTO: {
						coreBankInfoDTO: {
							coreCategoryID: ''
						}
					}
				}

			},
			commonError: false,
			visible: false,
			visibleBankInfo: false,
			addaccountError: false,
			addAccountDetailsMessage: false,
			bankName: null,
			newBankAccountDetails: {
				bankAccNumber: '',
				bankAccHolderName: '',
				bankNote: '',
				coreCategoryInfoDTO: {
					coreCategoryID: ''
				}
			},
			withdrawDetails: {
				requestedAmount: '',
				requestNote: '',
				userBankAccountInfoDTO: {
					userBankAccId: null
				}
			},
			banksAccountNumberList: '',
			withDrawError: {},
			addWithDrawError: '',
			dataTableIsLoading: false,
			firstDropdownIsLoading: false,
			secondDropdownIsLoading: false,
			errorMsgVisible: false,
			errorMsgBody: ''
		}
		this.selectBankHandle = this.selectBankHandle.bind(this)
		this.selectAccountNumberHandle = this.selectAccountNumberHandle.bind(this)
		this.withdrawAmountHandle = this.withdrawAmountHandle.bind(this)
		this.onHide = this.onHide.bind(this)
		this.reloadDataTableValue = this.reloadDataTableValue.bind(this)


		this.newbankNameHandle = this.newbankNameHandle.bind(this)
		this.newAccountHolderNameHandle = this.newAccountHolderNameHandle.bind(this)
		this.withDrawHandleError = this.withDrawHandleError.bind(this)
		this.addWithDrawHandleError = this.addWithDrawHandleError.bind(this)
		this.withdrawnotes = this.withdrawnotes.bind(this)
		this.userCategoryService = new UserCategoryService()
		this.userOtherAccessService = new UserOtherAccessService()
		this.balanceService = new BalanceService()
		this.NetiContentLoader = new NetiContentLoader();
		this.CommonFuctionality = new CommonFuctionality();

	}

	componentWillMount() {
		this.getTaggedBankInfo();
		// this.getBankList();
	}

	getBankList() {
		this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
		this.userCategoryService.fetchListBy2ndParentTypeDefCode('T100')
			.then(res => {
				if (res.status == 302) {
					return res.json().then((body) => {
						this.setState({ bankList: body, topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false });
					});
				} else {
					this.userCategoryService.Auth.handleErrorStatus(res)
						.then((resp) => {
							this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
						});
				}
			}).catch(error => {
				this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
			});

	}

	getTaggedBankInfo() {
		this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
		this.userOtherAccessService.getTaggedBankAccountInfoByDefcode('C123001')
			.then(res => {
				if (res.status == 302) {
					return res.json().then((body) => {
						console.log("body",body);
						this.setState({ taggedBankInfo: body, topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false });
					});
				} else {
					this.userOtherAccessService.Auth.handleErrorStatus(res)
						.then((resp) => {
							this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
						});
				}
			}).catch(error => {
				this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
			});

	}


	onHide(event) {
		this.setState({ visible: false })

		// this.CommonFuctionality.blurDialogBackgroundDeActive()
	}

	onHideBankInfo = (event) => {
		this.setState({ visibleBankInfo: false })

		// this.CommonFuctionality.blurDialogBackgroundDeActive()
	}

	selectBankHandle = (event, props) => {
		let { withdrawDetails, withDrawError } = this.state;
		withdrawDetails.userBankAccountInfoDTO.userBankAccId = null;
		withDrawError['withdrawDetailsBankName'] = ''
		this.setState({ withDrawError })

		if (event.target.value) {
			this.setState({ banksAccountNumberList: [], withdrawDetails })
			this.setState({ bankName: event.target.value, topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
			this.balanceService.findUserBankAccByCoreBankID(event.target.value)
				.then(res => {
					if (res.status === 302) {
						return res.json().then((body) => {
							this.setState({ banksAccountNumberList: body, topProgressBar: false, secondDropdownIsLoading: false })
						})
					} else {
						this.balanceService.Auth.handleErrorStatus(res)
							.then((resp) => {
								this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
							});
					}
				}).catch(error => {
					this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
				});
		} else {

			this.setState({
				bankName: event.target.value,
				banksAccountNumberList: [],
				withdrawDetails
			})
		}

	}

	selectAccountNumberHandle = (event, props) => {
		let { withdrawDetails, withDrawError } = this.state;
		withDrawError['withdrawDetailsAccountNumber'] = ''

		withdrawDetails.userBankAccountInfoDTO.userBankAccId = event.target.value;
		this.setState({ withdrawDetails, withDrawError })
	}

	withdrawAmountHandle = (event, props) => {
		let { withdrawDetails, withDrawError } = this.state;
		withDrawError['withdrawDetailsWithdrawAmount'] = ''
		event.target.value = (event.target.value.indexOf(".") >= 0) ? (event.target.value.substr(0, event.target.value.indexOf(".")) + event.target.value.substr(event.target.value.indexOf("."), 3)) : event.target.value;
		withdrawDetails.requestedAmount = event.target.value;
		this.setState({ withdrawDetails, withDrawError })
	}

	// addBankInfo = (event, props) => {
	// 	// this.fetchUserBankAccountList();
	// 	// this.CommonFuctionality.blurDialogBackgroundActive()
	// }




	withdrawnotes = (event, props) => {
		let { withdrawDetails, withDrawError } = this.state;
		withDrawError['withdrawNotes'] = ''
		withdrawDetails.requestNote = event.target.value;
		this.setState({ withdrawDetails, withDrawError })
	}

	withdrawSubmitHandle = () => {
		let {withdrawDetails, taggedBankInfo} = this.state;
		withdrawDetails.userBankAccountInfoDTO.userBankAccId = taggedBankInfo && taggedBankInfo.userBankAccountInfoDTO && taggedBankInfo.userBankAccountInfoDTO.userBankAccId;
		// withdrawDetails.coreCategoryInfoDTO.coreCategoryID = taggedBankInfo && taggedBankInfo.userBankAccountInfoDTO && taggedBankInfo.userBankAccountInfoDTO.coreBankBranchInfoDTO && taggedBankInfo.userBankAccountInfoDTO.coreBankBranchInfoDTO.coreBankInfoDTO && taggedBankInfo.userBankAccountInfoDTO.coreBankBranchInfoDTO.coreBankInfoDTO.coreCategoryID;

		if (this.withDrawHandleError()) {
		



			this.setState({withdrawDetails, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
			this.balanceService.withdrawRequestByAccountID(this.state.withdrawDetails)
				.then(res => {
					if (res.status === 201) {
						this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Submitted successfully' });
						this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
						let withdrawDetail = {
							requestedAmount: '',
							requestNote: '',
							userBankAccountInfoDTO: {
								userBankAccId: ''
							}
						}
						this.reloadDataTableValue();
						this.setState({ bankName: null, errorMsgVisible: false })
						this.setState({ withdrawDetails: withdrawDetail })
					} else {
						this.balanceService.Auth.handleErrorStatus(res)
							.then((responseBody) => {
								this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
							});
					}
				}).catch(error => {
					this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
				});
		}
	}

	newbankNameHandle = (event, props) => {
		let newBankAccount = this.state.newBankAccountDetails;
		newBankAccount.coreCategoryInfoDTO.coreCategoryID = event.target.value;
		this.setState({ newBankAccountDetails: newBankAccount })
		this.clearNewAddBankError(event.target.name)
	}

	newAccountHolderNameHandle = (event, props) => {
		let newBankAccount = this.state.newBankAccountDetails;
		newBankAccount.bankAccHolderName = event.target.value;
		this.setState({ newBankAccountDetails: newBankAccount })
		this.clearNewAddBankError(event.target.name)
	}

	newAccountNumberHandle = (event, props) => {
		let newBankAccount = this.state.newBankAccountDetails;
		newBankAccount.bankAccNumber = event.target.value;
		this.setState({ newBankAccountDetails: newBankAccount })
		this.clearNewAddBankError(event.target.name)
	}

	newAccountDetailsHandle = (event, props) => {
		let newBankAccount = this.state.newBankAccountDetails;
		newBankAccount.bankNote = event.target.value;
		this.setState({ newBankAccountDetails: newBankAccount })
		this.clearNewAddBankError(event.target.name)
	}

	newAccountSubmitHandle = (event, props) => {
		if (this.addWithDrawHandleError()) {
			this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
			let accountDetails = this.state.newBankAccountDetails;

			this.balanceService.addNewBankInfo(accountDetails)
				.then(res => {
					if (res.status === 201) {
						return res.json().then((body) => {
							this.setState({ visible: false })
							this.setState({ balanceDepositBody: { coreBankAccountInfoDTO: { coreBankAccId: '' } }, topProgressBar: false, dataTableIsLoading: false })
							this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'New bank account submited successfully' });
						})
					} else {
						this.balanceService.Auth.handleErrorStatus(res)
							.then((responseBody) => {
								this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
							});
					}
				}).catch(error => {
					this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
				});
		}
	}

	fetchUserBankAccountList = () => {
		this.setState({ visibleBankInfo: true })
		this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
		this.userOtherAccessService.findBankAccountByUser()
			.then(res => {
				if (res.status === 200) {
					return res.json().then((body) => {
						for (let i = 0; i < body.length; i++) {
							if (body[i].enableStatus === 1) {
								body[i].enableStatus = 'Active';
							} else if (body[i].enableStatus === 0) {
								body[i].chatboxDate = 'Inactive';
							}
						}
						this.setState({ dataTableValue: body })
						this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
					})
				} else {
					this.userOtherAccessService.Auth.handleErrorStatus(res)
						.then((resp) => {
							this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
						});
				};
			}).catch(error => {
				this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
			});
	}


	/**withdraw handle error start **/
	withDrawHandleError = () => {
		let { withDrawError } = this.state;
		let formIsValid = true;
		if (this.state.withdrawDetails.requestedAmount == '' || this.state.withdrawDetails.requestedAmount == 0) {
			formIsValid = false;
			withDrawError["withdrawDetailsWithdrawAmount"] = "Withdraw Amount cant't left empty";
		} else if (this.state.withdrawDetails.requestedAmount > walletBalance) {
			formIsValid = false;
			withDrawError["withdrawDetailsWithdrawAmount"] = "Insufficient Wallet Balance";
		}
		if (this.state.withdrawDetails.requestNote == '') {
			formIsValid = false;
			withDrawError["withdrawNotes"] = "Note cant't left empty";
		}

		this.setState({ withDrawError });
		return formIsValid;
	}

	addWithDrawHandleError = () => {
		let addWithDrawError = {};
		let formIsValid = true;
		if (!this.state.newBankAccountDetails.coreCategoryInfoDTO.coreCategoryID) {
			formIsValid = false;
			addWithDrawError["newBankName"] = "Bank Name can't left empty";
		}
		if (this.state.newBankAccountDetails.bankAccHolderName === '') {
			formIsValid = false;
			addWithDrawError["newBankAccountHolderName"] = "Account holder name can't left empty";
		}
		if (!ValidatorUtility.isValidNumber(this.state.newBankAccountDetails.bankAccNumber)) {
			formIsValid = false;
			addWithDrawError["newBankAccountNumber"] = "Account Number can't left empty";
		}
		if (this.state.newBankAccountDetails.bankNote === '') {
			formIsValid = false;
			addWithDrawError["newBankAccountDetails"] = "Account details can't left empty";
		}

		this.setState({ addWithDrawError: addWithDrawError });
		return formIsValid;
	}
	/**Add withdraw handle error end**/

	reloadDataTableValue() {
		this.refs.WalletBalanceWithdrawList.viewTopTenBalanceWithdrawList();
	}

	clearNewAddBankError = (errorProperty) => {
		let { addWithDrawError } = this.state;
		if (addWithDrawError[errorProperty]) {
			addWithDrawError[errorProperty] = '';
		}
		this.setState({ addWithDrawError })
	}

	render() {
		let { balanceDepositBody, topProgressBar, errorMsgVisible, errorMsgBody, taggedBankInfo } = this.state;
		walletBalance = this.refs.walletBalance && this.refs.walletBalance.state && this.refs.walletBalance.state.userWalletBalance && this.refs.walletBalance.state.userWalletBalance.walletBalance;
	
		let banksDetails = []
		if (this.state.bankList && this.state.bankList.length) {
			banksDetails = this.state.bankList.map((item) => ({
				value: item.coreCategoryID,
				label: item.categoryName,
			}));
		}
		let banksAccountNumber = []
		if (this.state.banksAccountNumberList && this.state.banksAccountNumberList.length) {
			banksAccountNumber = this.state.banksAccountNumberList.map((item) => ({
				value: item.userBankAccId,
				label: item.bankAccNumber + " " + '(' + item.bankAccHolderName + ")",
			}));
		}

		return (
			<div className="p-fluid">
				{topProgressBar ?
					<ErrorMessageView
						topProgressBar={topProgressBar}
					/>
					: null
				}
				{errorMsgVisible ?
					<ErrorMessageView
						errorMsgVisible={errorMsgVisible}
						errorMsgBody={errorMsgBody}
					/>
					: null
				}
				<Growl ref={(el) => this.growl = el} />

				<div className="main-section">
					<div className="p-grid nw-form">
						<div className="p-col-12 p-xl-8 p-col-nogutter seprator">
							<div className="p-col-12 p-xl-12">
								<div className='nw-form-body'>

									<div className="p-grid seprator-inside">
										<div className="p-col-12 p-xl-12">
											{this.state.firstDropdownIsLoading ?
												this.NetiContentLoader.normalFormInputField() :
												<div className="p-inputgroup">
													<span className="p-inputgroup-addon">Bank Name<span>*</span></span>
													<InputText
														placeholder="Select Bank Name"
														value={taggedBankInfo && taggedBankInfo.userBankAccountInfoDTO && taggedBankInfo.userBankAccountInfoDTO.coreBankBranchInfoDTO && taggedBankInfo.userBankAccountInfoDTO.coreBankBranchInfoDTO.coreBankInfoDTO && taggedBankInfo.userBankAccountInfoDTO.coreBankBranchInfoDTO.coreBankInfoDTO.categoryName}
														disabled
													/>
													
												</div>
											}
										</div>
										<div className="p-col-12 p-xl-12">
											{this.state.firstDropdownIsLoading ?
												this.NetiContentLoader.normalFormInputField() :
												<div className="p-inputgroup">
													<span className="p-inputgroup-addon">Branch Name<span>*</span></span>
													<InputText
														placeholder="Select Branch Name"
														value={taggedBankInfo && taggedBankInfo.userBankAccountInfoDTO && taggedBankInfo.userBankAccountInfoDTO.coreBankBranchInfoDTO && taggedBankInfo.userBankAccountInfoDTO.coreBankBranchInfoDTO.branchName}
														disabled
													/>
													
												</div>
											}
										</div>
										<div className="p-col-12 p-xl-12">
											{this.state.firstDropdownIsLoading ?
												this.NetiContentLoader.normalFormInputField() :
												<div className="p-inputgroup">
													<span className="p-inputgroup-addon">Account Holder Name<span>*</span></span>
													<InputText
														placeholder="Select Branch Name"
														value={taggedBankInfo && taggedBankInfo.userBankAccountInfoDTO && taggedBankInfo.userBankAccountInfoDTO.bankAccHolderName}
														disabled
													/>
													
												</div>
											}
										</div>
										<div className="p-col-12 p-xl-12">
											{this.state.firstDropdownIsLoading ?
												this.NetiContentLoader.normalFormInputField() :
												<div className="p-inputgroup">
													<span className="p-inputgroup-addon">Account Number<span>*</span></span>
													<InputText
														placeholder="Select Account Number"
														value={taggedBankInfo && taggedBankInfo.userBankAccountInfoDTO && taggedBankInfo.userBankAccountInfoDTO.bankAccNumber}
														disabled
													/>
													
												</div>
											}
										</div>
										{/* <div className="p-col-12 p-xl-12">
											{this.state.secondDropdownIsLoading ?
												this.NetiContentLoader.normalFormInputField() :
												<div className="p-inputgroup">
													<span className="p-inputgroup-addon">Account Number <span>*</span></span>
													<Dropdown
														value={this.state.withdrawDetails.userBankAccountInfoDTO.userBankAccId}
														options={banksAccountNumber}
														onChange={(event) => this.selectAccountNumberHandle(event, this.props)}
														filter={true}
														filterBy="label,value"
														showClear={true}
														style={{ width: "100%" }}
														placeholder="Select Account Number"
													/>
												</div>
											}
											<span className="error-message">{this.state.withDrawError["withdrawDetailsAccountNumber"]}</span>
										</div> */}
										<div className="p-col-12 p-xl-12">
											<div className="p-inputgroup">
												<span className="p-inputgroup-addon ">Withdraw Amount <span>*</span></span>
												<InputText
													value={this.state.withdrawDetails.requestedAmount}
													id="withdraw_amount"
													keyfilter="num"
													placeholder="Enter Withdraw Amount"
													name="withdrawAmount"
													onChange={(event) => this.withdrawAmountHandle(event, this.props)}
												/>
											</div>
											<span className="error-message">{this.state.withDrawError["withdrawDetailsWithdrawAmount"]}</span>
										</div>
										<div className="p-col-12 p-xl-12">
											<div className="p-inputgroup">
												<span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
												<InputTextarea
													value={this.state.withdrawDetails.requestNote}
													rows={5}
													cols={30}
													className="textareaHeight"
													id="accountDetails"
													placeholder="Enter Withdraw Note"
													onChange={(event) => this.withdrawnotes(event, this.props)}
													name="accountDetails" 
													autoResize={false}
													/>
											</div>
											<span className="error-message">{this.state.withDrawError["withdrawNotes"]}</span>
										</div>

										<div className="p-col-12 p-xl-12">

										</div>

										<div className="p-col-12 p-xl-12 nw-button-parent">
											<div className="required-field">
												(<span>*</span>) required fields
											</div>

											<Button
												className="nw-button nw-button-right p-button-primary"
												label="Save"
												icon="fas fa-check"
												onClick={this.withdrawSubmitHandle}
											/>
										</div>

									</div>
								</div>
							</div>
						</div>
						<div className="p-col-12 p-xl-4">
							{this.state.dataTableIsLoading ? "" :
								<WalletBalance
									walletBalanceName='Wallet'
									ref="walletBalance"
								/>
							}


						</div>

					</div>

					<div className="p-col-12 p-xl-12">

						{this.state.dataTableIsLoading ? "" :
							<WalletBalanceWithdrawList ref="WalletBalanceWithdrawList" />
						}
					</div>
				</div>
		
			</div>
		);
	}
}