import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { Growl } from 'primereact/growl';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Fieldset } from 'primereact/fieldset';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';
import Formatter from '../../../utils/Formatter';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { pdfDownloadPortrait_TableWithFooter, pdfDownloadLandscape_TableWithFooter } from '../../common/JsPdfDownload';

let cloneStateBeforeMount;
let totalDepositAmount = 0;
let requestDateTime;
let errors = {};
export class Deposit extends Component {

    constructor() {
        super();
        this.state = {
            status: 0,
            partnerPicture: '',
            depositSlip: '',
            transactionRefID: '',
            transactionRefNo: '',
            rejectNote: '',
            dataTableValue: [],
            netiIdAndNameBody: {
                customNetiID: ''
            },
            selectedRow: {},
            depositApproveObj: {
                userBasicReportDTO: {
                    partnerDesignation: '',
                    partnerStatus: '',
                    assignedArea: '',
                    userBasicInfoDTO: {
                        customNetiID: '',
                        approveNote: '',
                    globalAreaInfoDTO: {
                        categoryName: ''
                    }
                }
            },
                coreBankAccountInfoDTO: {
                    coreCategoryInfoDTO: {
                        categoryName: ''
                    }
                }
            },
            visible: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            errors: {}

        }

        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangeTransaction = this.onChangeTransaction.bind(this);
        this.onChangeReject = this.onChangeReject.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);
        this.getProfileImg = this.getProfileImg.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.CRMPointService = new CRMPointService();
        this.formatter = new Formatter();
        this.ValidatorUtility = new ValidatorUtility();
    }

    componentWillMount() {
        this.getDepositPendingList("deposit", 0);
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    getSlipFile = (filepath, fileName) => {
        let { depositApproveObj } = this.state;
        this.setState({depositSlip: ''});
        if (filepath !== null) {
            this.netiFileHandler.getByteImage(filepath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(fileName);
                                this.setState({ depositSlip: 'data:image/jpeg;base64,' + body.fileContent });
                            })
                    } else {
                    }
                });
        }
    }


    getDepositPendingList(type, status) {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        totalDepositAmount = 0

        this.CRMPointService.fetchDepositPendingList(type, status)
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        totalDepositAmount = body.map(item => item.requestedAmount).reduce((a, b) => { return (a + b) }, 0);
                        body.map((item) => {
                            item.userBasicReportDTO.userBasicInfoDTO.basicMobile = this.formatter.formatContactNo(item.userBasicReportDTO.userBasicInfoDTO.basicMobile);
                            // item['mobNoAndArea'] = `${item.userBasicInfoDTO.basicMobile} ${item.userBasicInfoDTO.globalAreaInfoDTO.categoryName}`
                            // item['netiID&name'] = `${item.userBasicInfoDTO.customNetiID} ${item.userBasicInfoDTO.fullName}`
                            item['requestTime'] = NetiDateUtils.getHHMMSSFForHome(item.requestDate);
                            item['requestDate'] = NetiDateUtils.getAnyShortForm(item['requestDate'], 'DD-MMM-YYYY');
                            item['requestedAmount'] = this.ValidatorUtility.currencyFormatter(item['requestedAmount']);

                            let customNetiID = String(item.userBasicReportDTO.userBasicInfoDTO.customNetiID);
                            let mobileNumber = String(item.userBasicReportDTO.userBasicInfoDTO.basicMobile);
                            let categoryName = String(item.coreBankAccountInfoDTO.coreCategoryInfoDTO.categoryName);
                            let accShortName = String(item.coreBankAccountInfoDTO.accShortName);

                            item.userBasicReportDTO.userBasicInfoDTO.customNetiID = item.userBasicReportDTO.userBasicInfoDTO.customNetiID;
                            item.userBasicReportDTO.userBasicInfoDTO.basicMobile = item.userBasicReportDTO.userBasicInfoDTO.basicMobile;
                            item.coreBankAccountInfoDTO.coreCategoryInfoDTO.categoryName = item.coreBankAccountInfoDTO.coreCategoryInfoDTO.categoryName;
                            item.coreBankAccountInfoDTO.accShortName = item.coreBankAccountInfoDTO.accShortName;
                            
                            Object.assign(item, {accShortName:accShortName, customNetiID : customNetiID, mobileNumber:mobileNumber, categoryName:categoryName });
                            
                        });
                        this.setState({ dataTableValue: body });
                        
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    onChangeStatus(value) {
        errors["transactionAndApproveError"] = ''
        this.setState({ errors, status: value });
    }

    onChangeTransaction(value) {
        let { depositApproveObj } = this.state
        errors["transactionAndApproveError"] = ''
        this.setState({ transactionRefID: value });
        depositApproveObj.transactionNumber = value;
        depositApproveObj.approveNote = ""
        this.setState({ errors, depositApproveObj });
    }

    onChangeReject(value) {
        let { depositApproveObj } = this.state
        errors["transactionAndApproveError"] = ''
        depositApproveObj.approveNote = value
        depositApproveObj.transactionNumber = ""
        this.setState({ errors, depositApproveObj });
    }


    actionBodyTemplet = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-edit"
                tooltip="Update"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    viewDialog(rowData) {
        let { depositApproveObj } = this.state
        this.setState({ depositApproveObj: cloneStateBeforeMount.depositApproveObj });
        this.setState({ selectedRow: rowData, visible: true });


        depositApproveObj.balanceRequestLogId = rowData.balanceRequestLogId
        depositApproveObj.transactionNumber = rowData.transactionNumber
        depositApproveObj.userBasicReportDTO.userBasicInfoDTO.userWalletBalance = rowData.userBasicReportDTO.userBasicInfoDTO.userWalletBalance
        depositApproveObj.userBasicReportDTO.userBasicInfoDTO.customNetiID = rowData.userBasicReportDTO.userBasicInfoDTO.customNetiID
        depositApproveObj.userBasicReportDTO.userBasicInfoDTO.fullName = rowData.userBasicReportDTO.userBasicInfoDTO.fullName
        depositApproveObj.userBasicReportDTO.userBasicInfoDTO.basicMobile = rowData.userBasicReportDTO.userBasicInfoDTO.basicMobile
        depositApproveObj.userBasicReportDTO.userBasicInfoDTO.globalAreaInfoDTO.categoryName = rowData.userBasicReportDTO.userBasicInfoDTO.globalAreaInfoDTO.categoryName

        depositApproveObj.requestDate = rowData.requestDate;
        depositApproveObj.coreBankAccountInfoDTO.coreCategoryInfoDTO.categoryName = rowData.coreBankAccountInfoDTO.coreCategoryInfoDTO.categoryName
        depositApproveObj.coreBankAccountInfoDTO.accShortName = rowData.coreBankAccountInfoDTO.accShortName
        depositApproveObj.paymentType = rowData.paymentType
        depositApproveObj.attachFilePath = rowData.attachFilePath
        depositApproveObj.attachFileName = rowData.attachFileName
        depositApproveObj.transactionDate = NetiDateUtils.getAnyShortForm(rowData['transactionDate'], 'DD-MMM-YYYY')
        depositApproveObj.fromWhere = rowData.fromWhere
        depositApproveObj.requestedAmount = rowData.requestedAmount
        depositApproveObj.requestNote = rowData.requestNote
        depositApproveObj.userBasicReportDTO.userBasicInfoDTO.imagePath = rowData.userBasicReportDTO.userBasicInfoDTO.imagePath
        depositApproveObj.userBasicReportDTO.userBasicInfoDTO.imageName = rowData.userBasicReportDTO.userBasicInfoDTO.imageName

        if (rowData.userBasicReportDTO !== null) {
            depositApproveObj.userBasicReportDTO.partnerDesignation = rowData.userBasicReportDTO.partnerDesignation
            depositApproveObj.userBasicReportDTO.partnerStatus = rowData.userBasicReportDTO.partnerStatus
            depositApproveObj.userBasicReportDTO.assignedArea = rowData.userBasicReportDTO.assignedArea
        }

        requestDateTime = rowData.requestTime
        this.setState({ depositApproveObj })
        this.getSlipFile(rowData.attachFilePath,rowData.attachFileName);
        this.getProfileImg()
    }

    getProfileImg() {

        let { depositApproveObj } = this.state;
        let staticImg = 'assets/layout/images/userAvatar.png';

        if (depositApproveObj.userBasicReportDTO.userBasicInfoDTO.imagePath !== null) {
            this.netiFileHandler.getByteImage(depositApproveObj.userBasicReportDTO.userBasicInfoDTO.imagePath)
                .then((res) => {

                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(depositApproveObj.userBasicReportDTO.userBasicInfoDTO.imageName);
                                this.setState({ partnerPicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ partnerPicture: staticImg });
                    }
                });
        } else { this.setState({ partnerPicture: staticImg }) };

    }

    onHide(e) {
        let { depositApproveObj } = this.state;
        depositApproveObj.approveNote = ''
        this.setState({ visible: false });
        this.setState({ status: 0, transactionRefID: ''});
        this.setState({
            depositApproveObj: {
                approveNote: '',
                userBasicReportDTO: {
                    partnerDesignation: '',
                    partnerStatus: '',
                    assignedArea: '',
                userBasicInfoDTO: {
                    customNetiID: '',
                    globalAreaInfoDTO: {
                        categoryName: ''
                    }
                }
            },
                coreBankAccountInfoDTO: {
                    coreCategoryInfoDTO: {
                        categoryName: ''
                    }
                }
            }
        })
    }

    handleError() {
        let { errors, depositApproveObj } = this.state;
        let formIsValid = true;
        if (this.state.status === 1) {
            if (this.state.transactionRefID === '') {
                formIsValid = false;
                errors["transactionAndApproveError"] = "Transaction Reference No. can't left empty.";
            }
        }
        else if (this.state.status === 2) {
            if (depositApproveObj.approveNote === '') {
                formIsValid = false;
                errors["note"] = "Reject Note can't left empty.";
            }
        }
        this.setState({ errors });
        return formIsValid;
    }

    onSubmitUpdate() {
        let { depositApproveObj } = this.state

        let updateDepositObj = {
            "balanceRequestLogId": depositApproveObj.balanceRequestLogId,
            "requestStatus": this.state.status,
            "transactionNumber": depositApproveObj.transactionNumber,
            "approveNote": depositApproveObj.approveNote,
        }

        if (this.handleError()) {

            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            this.CRMPointService.updateDepositBalanceApprove(updateDepositObj)
                .then(res => {
                    if (res.status === 202) {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Update successfully' })
                        this.onHide();
                        this.getDepositPendingList("deposit", 0);
                        this.setState({ updateDepositObj: cloneStateBeforeMount.updateDepositObj, transactionRefID: '', status: 0 });
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        }


    }

    netiIDandNameTemplate = (rowData) => {
        return <span>{rowData.userBasicReportDTO.userBasicInfoDTO.customNetiID} <br /> {rowData.userBasicReportDTO.userBasicInfoDTO.fullName}</span>;

    }
    mobNoAndAreaTemplate = (rowData) => {
        return <span>{rowData.userBasicReportDTO.userBasicInfoDTO.basicMobile} <br /> {rowData.userBasicReportDTO.userBasicInfoDTO.globalAreaInfoDTO.categoryName}</span>;
    }

    getDateFromTemplate = (rowData) => {
        return <center><div>{rowData.requestDate} <br /> {rowData.requestTime}</div></center>
    }

    export = () => {
        //this.dt.exportCSV();
        let mainTitle = "Pending Deposit Request";
        let pdfColumns = [
            { title: "Deposit Date", dataKey: "requestDate" },
            { title: "Neti ID", dataKey: "customNetiID" },
            { title: "Mobile No.", dataKey: "mobileNumber" },
            { title: "Bank", dataKey: "categoryName" },
            { title: "Account Number", dataKey: "accShortName" },
            { title: "Deposit Amount", dataKey: "requestedAmount" },
        ]

        var totalArrayList = [
            {
                id: "1",
                totalPdf: "Total",
                totalAmount1: this.ValidatorUtility.currencyFormatter(totalDepositAmount),
            }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }, { dataKey: "totalAmount1" }];

        let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, cellWidth: 222, fontStyle: "bold", overflow: "linebreak" },
            totalAmount1: { halign: "right", fontStyle: "bold", fontSize: 10, cellWidth: 46 }
        }
        let tableColumnStyle = {
            requestedAmount: { halign: "right", fontSize: 10},
        }
        pdfDownloadLandscape_TableWithFooter(mainTitle, 110,  pdfColumns, this.state.dataTableValue, tableColumnStyle, totalColumns, totalArrayList, totalColumnStyles, "Pending_Deposit_Request.pdf");

    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, depositApproveObj, dataTableValue } = this.state;
        let { selectedRow } = this.state;

        let statusOptions = [
            {
                label: 'Pending',
                value: 0,
            },
            {
                label: 'Approve',
                value: 1,
            },
            {
                label: 'Reject',
                value: 2,
            },
        ]

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Pending Deposit Request List <span style={{ float: 'right' }}> Total Found: {this.state.dataTableValue.length} </span></div>

        let footer = <ColumnGroup>
            <Row>
                <Column footer="" />
                <Column footer="" />
                <Column footer="" />
                <Column footer='' />
                <Column footer="Total:" className="nw-tfoot-total-text" />
                <Column footer={this.ValidatorUtility.currencyFormatter(totalDepositAmount)} />
                <Column footer='' />
            </Row>
        </ColumnGroup>;
        return (

            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid">

                    <div className="p-col-12 p-xl-12">
                        <Growl ref={(el) => this.growl = el} />

                        <div className="nw-data-table nw-data-table-tabview">
                            {!this.state.dataTableIsLoading ?
                                <div>
                                    <DataTable
                                        header={tableHeader}
                                        value={this.state.dataTableValue}
                                        responsive={true}
                                        paginator={true}
                                        ref={(el) => { this.dt = el; }}
                                        footerColumnGroup={footer}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="requestDate" header="Deposit Date" body={this.getDateFromTemplate} filter={true} />
                                        <Column field="userBasicReportDTO.userBasicInfoDTO.customNetiID" header="Neti ID" filter={true} />
                                        <Column field="userBasicReportDTO.userBasicInfoDTO.basicMobile" header="Mobile No." filter={true} />
                                        <Column field="coreBankAccountInfoDTO.coreCategoryInfoDTO.categoryName" header="Bank" filter={true} />
                                        <Column field="coreBankAccountInfoDTO.accShortName" header="Account Number" filter={true} />
                                        {/* <Column field="userBasicInfoDTO.userWalletBalance" header="Deposit Amount"  filter={true} /> */}
                                        {/*<Column field="userBasicInfoDTO.depositAmount" header="Available Balance"  filter={true} /> */}
                                        <Column field="requestedAmount" header="Deposit Amount" filter={true} />
                                        <Column field="" header="Action" body={this.actionBodyTemplet} style={{ textAlign: "center", width: "80px" }} />

                                    </DataTable>
                                    <div className="p-col-12 nw-button-parent" style={{paddingRight:0}}>
                                        <Button
                                            label="Download"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            icon="fas fa-arrow-down"
                                            onClick={this.export}
                                        />
                                    </div>
                                    
                                </div>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>



                        <Dialog className="nw-dialog" header="Deposit Request" visible={this.state.visible} onHide={e => this.onHide(e)}>
                            <div className="p-fluid">
                                <div className="p-grid">

                                    <div className="p-col-12">

                                        <div className="p-col-12">
                                            <Fieldset legend="Requester Information">
                                                <div className="p-grid nw-form">

                                                    <div className="p-col-12 p-xl-12">
                                                        <center>
                                                            <img
                                                                src={this.state.partnerPicture}
                                                                alt="partner picture"
                                                                style={{ height: "80px" }}
                                                            />
                                                        </center>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Neti ID
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.userBasicReportDTO.userBasicInfoDTO.customNetiID}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Name
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.userBasicReportDTO.userBasicInfoDTO.fullName}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Mobile No
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.userBasicReportDTO.userBasicInfoDTO.basicMobile}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Wallet Balance
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {this.ValidatorUtility.currencyFormatter(depositApproveObj.userBasicReportDTO.userBasicInfoDTO.userWalletBalance)}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Address
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.userBasicReportDTO.userBasicInfoDTO.globalAreaInfoDTO.categoryName}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        {depositApproveObj.userBasicReportDTO && depositApproveObj.userBasicReportDTO.partnerDesignation !== '' ?

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                        Partner Type
                                                                        </span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {depositApproveObj.userBasicReportDTO.partnerDesignation}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }
                                                        {depositApproveObj.userBasicReportDTO && depositApproveObj.userBasicReportDTO.partnerStatus !== '' ?

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                        Partner Status
                                                                        </span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {depositApproveObj.userBasicReportDTO.partnerStatus === 1 ? "Active" : 'Inactive'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }
                                                        {depositApproveObj.userBasicReportDTO && depositApproveObj.userBasicReportDTO.assignedArea !== '' ?

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                        Partner Area
                                                                        </span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {depositApproveObj.userBasicReportDTO && depositApproveObj.userBasicReportDTO.assignedArea}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}
                                                    </div>




                                                </div>

                                            </Fieldset>

                                            <Fieldset legend="Deposit Information">
                                                <div className="p-grid nw-form">


                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Deposit Date
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.transactionDate}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Branch
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.fromWhere}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Deposit Amount
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {this.ValidatorUtility.currencyFormatter(depositApproveObj.requestedAmount)}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Note
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.requestNote}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Deposit Slip
                                                                </span>
                                                            <div className="nw-inputgroup-desc">
                                                                <a download={depositApproveObj.attachFileName} href={this.state.depositSlip}>
                                                                    <i className="fas fa-download"></i> Download Slip
                                                                    </a>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Request Date
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.requestDate + '  ' + requestDateTime}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Bank
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.coreBankAccountInfoDTO.coreCategoryInfoDTO.categoryName}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Account Number
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.coreBankAccountInfoDTO.accShortName}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                Deposit Type
                                                                        </span>
                                                            <div className="nw-inputgroup-desc">
                                                                {depositApproveObj.paymentType}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                                        {/* <a download={depositApproveObj.attachFileName} href={this.state.depositSlip}>Download Slip</a> */}
                                                    </div>



                                                </div>
                                            </Fieldset>

                                        </div>


                                        <div className="p-grid nw-form">



                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-col-12 p-lg-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Status <span>*</span></span>
                                                        <Dropdown
                                                            name="status"
                                                            options={statusOptions}
                                                            value={this.state.status}
                                                            onChange={(e) => this.onChangeStatus(e.target.value)}
                                                            filter={true}
                                                            style={{ "width": "100%" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.status === 1 ?
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Transaction Reference No <span>*</span></span>
                                                            <InputText
                                                                name="status"
                                                                placeholder="Enter Transaction Reference No."
                                                                value={this.state.transactionRefID}
                                                                onChange={(e) => this.onChangeTransaction(e.target.value)}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["transactionAndApproveError"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            label="Submit"
                                                            icon="far fa-save"
                                                            onClick={e => this.onSubmitUpdate(e)}
                                                        />
                                                    </div>
                                                </div>

                                                : ''
                                            }

                                            {this.state.status === 2 ?
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Reject Note <span>*</span></span>
                                                            <InputTextarea
                                                                name="note"
                                                                placeholder="Enter Reject Note"
                                                                value={depositApproveObj.approveNote}
                                                                onChange={(e) => this.onChangeReject(e.target.value)}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.errors["note"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            label="Submit"
                                                            icon="far fa-save"
                                                            onClick={e => this.onSubmitUpdate(e)}
                                                        />
                                                    </div>
                                                </div>
                                                : ''}


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Dialog>

                    </div>

                </div>
            </div>
        )
    }
}