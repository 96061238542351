import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Link } from 'react-router-dom';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { PartnerPointService } from '../../../service/partnerPoint/PartnerPointService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ProfileService } from '../../../service/profile/ProfileService';
import { TokenService } from '../../../service/token/TokenService';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { Editor } from 'primereact/editor';
import { FileUpload } from 'primereact/fileupload';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import CommonFuctionality from '../../common/CommonFuctionality';


let purchaseError = {};
let netiIdError = {};
let tokenError = {};
let partnerAreaID;
export class NewPartnerAssign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            tokenTypeID: '',
            teamMemberInfoList: [],
            purchaseCode: '',
            tokenId: '',
            netiId: '',
            newPartnerAssignError: {},
            designation: '',
            parentId: '',
            areaId: '',
            tokenDetails: '',
            errorMsgVisible: false,
            topProgressBar: false,
            changingPurposeDropdownList: [],
            firstSearchIsLoading: false,
            secondSearchIsLoading: false,
            thirdSearchIsLoading: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            thirdDropdownIsLoading: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            usedStatus: '',
            purchaseError: {},
            searchDataObj: {
                pageLimit: 10,
                pageNo: 0
            },
            first: 0,
            rows: 10,
            netiIdError: {},
            tokenError: {},
            tokenPicInfo: {
                contentPic: '',
            },
            searchViewPurchaseCode: false,
            searchViewErrorPurchaseCode: false,

            searchViewTokenId: false,
            searchViewErrorTokenId: false,

            searchViewNetiId: false,
            searchViewErrorNetiId: false,
            purchaseCodeSearchBtnDisabled: true,
            tokenIdSearchBtnDisabled: true,
            netiIdSearchBtnDisabled: true,
            buttonDisabled: true
        };
        this.partnerPointService = new PartnerPointService();
        this.profileService = new ProfileService();
        this.tokenService = new TokenService();
        this.adminPointService = new AdminPointService();
        this.userCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
        this.onPageChange = this.onPageChange.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.commonFuctionality = new CommonFuctionality();
    }
    componentWillMount() {
        this.fetchPartnerTypeList();
        // this.fetchAreaList('T10301');
        this.getAllPartnerAssignInfoList();
        this.getChangingPurposeList();

    }

    onPageChange(event) {
        let { searchDataObj } = this.state
        searchDataObj.pageNo = event.page
        this.setState({
            first: event.first,
            rows: event.rows
        });
        this.getAllPartnerAssignInfoList();
    }

    onChangePurposeList = (e) => {
        let { newPartnerAssignError } = this.state;
        newPartnerAssignError["purposeList"] = "";
        this.setState({ tokenTypeID: e.target.value, newPartnerAssignError });
    }

    onChangeTokenDetails = (e) => {
        let { newPartnerAssignError } = this.state;
        newPartnerAssignError["tokenDetails"] = "";
        this.setState({ tokenDetails: e.htmlValue, newPartnerAssignError });
    }

    getChangingPurposeList = () => {
        this.setState({ topProgressBar: true, errorMsgBody: '', errorMsgVisible: false });
        this.userCategoryService.findByCategoryDefaultCode('C11002034')
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ changingPurposeDropdownList: body });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection.", topProgressBar: false })
            });
    }

    getAllPartnerAssignInfoList = () => {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.adminPointService.getAllPartnerAssignInfoList(this.state.searchDataObj)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({
                            dataTableValue: body, topProgressBar: false, dataTableIsLoading: false
                        });
                    });
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            )
    }

    submitSave = () => {
        let requestedObject = {
            "purchaseCode": this.state.purchaseCode,
            "tokenInfoDTO": {
                "attachContent": this.state.tokenPicInfo.contentPic,
                "attachSaveOrEditable": true,
                "attachmentName": Date.now() + this.netiFileHandler.getImageExtention(this.state.tokenPicInfo.extention),
                "tokenDetails": this.state.tokenDetails,
                "tokenTypeInfoDTO": {
                    "coreCategoryID": this.state.tokenTypeID
                }
            },
            "partnerDesignationInfoDTO": {
                "coreCategoryID": this.state.designation
            },
            "partnerAreaInfoDTO": {
                "coreCategoryID": this.state.areaId
            },
            "userBasicInfoDTO": {
                "netiID": this.state.netiidSave
            },
            "parentAssignDTO": {
                "partnerAssignID": this.state.parentId
            }

        }
        if (this.newPartnerAssignErrorHandler()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            this.adminPointService.assignPartner(requestedObject)
                .then(res => {
                    if (res.status === 201) {
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully submitted"
                        });
                        this.setState({ topProgressBar: false, searchViewPurchaseCode: false, searchViewNetiId: false, dataTableIsLoading: false, purchaseCode: '', tokenInfoID: '', customTokenID: '', designation: '', areaId: '', netiidSave: '', parentId: '' })
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });

                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })

                );
        }
    };

    removeButtonOnclick = () => {
        this.setState({ tokenPicInfo: { contentPic: '' } });
        this.setState({ tokenPicInfo: { contentName: '' } });

    }

    handlePurchaseError() {
        let purchaseError = {};
        let formIsValid = true;

        if (this.state.purchaseCode === "") {
            formIsValid = false;
            purchaseError["purchaseCode"] = "Please input Purchase Code";
        }
        this.setState({ purchaseError: purchaseError });
        return formIsValid;
    }

    handleTokenErrorError() {
        let tokenError = {};
        let formIsValid = true;

        if (this.state.tokenId === "") {
            formIsValid = false;
            tokenError["tokenId"] = "Please input Token Id";
        }
        this.setState({ tokenError: tokenError });
        return formIsValid;
    }

    handleNetiIdError() {
        let netiIdError = {};
        let formIsValid = true;
        let {netiId} = this.state; 

        if (this.state.netiId === "") {
            formIsValid = false;
            netiIdError["netiId"] = "Please input NetiId";
        }

        if (netiId.length < 10) {
            formIsValid = false;
            netiIdError["netiId"] = "Neti ID is too sort";
        } 
        if (netiId == '' && netiId.length < 10) {
            formIsValid = false
            netiIdError["netiId"] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10';
        }

        this.setState({ netiIdError: netiIdError });
        return formIsValid;
    }

    onFileUpload(e) {
        let { newPartnerAssignError } = this.state;
        newPartnerAssignError["attachments"] = "";
        this.setState({ newPartnerAssignError });
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let tokenPic = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ tokenPicInfo: tokenPic });
        }
      

    }

    fetchMemberAreaList(parentCategoryID) {
        this.setState({ topProgressBar: true, thirdDropdownIsLoading: true, errorMsgVisible: false })
        this.adminPointService.findByParentCatTypeID(parentCategoryID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ areaList: body, topProgressBar: false, thirdDropdownIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ areaList: [], topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchPartnerTypeList() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchListByDefaultCode('T11202', 1)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ partnerTypeList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    })
                }
                else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }


    onSearchPurchaseCode = () => {
        if (this.handlePurchaseError()) {
            this.setState({ topProgressBar: true, firstSearchIsLoading: true, errorMsgVisible: false, searchViewPurchaseCode: true, searchViewErrorPurchaseCode: false })
            this.partnerPointService.getUserPurchaseCode(this.state.purchaseCode)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            let productDefaultCode = body.productPurchaseLogDTO.productInfoDTO.productDefaultCode

                            if(productDefaultCode == 13 ){
                                this.setState({ productName: body.productPurchaseLogDTO.productInfoDTO.productName });
                                this.setState({ usedStatus: body.usedStatus });
                                this.setState({ purchashedDate: NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY') });
                                this.setState({ topProgressBar: false, firstSearchIsLoading: false, searchViewErrorPurchaseCode: false, purchaseCodeSearchBtnDisabled: false })
                            }
                            else{
                                purchaseError["Error"] = "Purchase code is invalid for this segment";
                                this.setState({ purchaseError, topProgressBar: false, firstSearchIsLoading: false, searchViewErrorPurchaseCode: true, purchaseCodeSearchBtnDisabled: true })
                                this.setState({ productName: "" });
                                this.setState({ usedStatus: "" });
                                this.setState({ purchashedDate: "" });
                            }
                        });
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                purchaseError["Error"] = resp;
                                this.setState({ purchaseError, topProgressBar: false, firstSearchIsLoading: false, searchViewErrorPurchaseCode: true, purchaseCodeSearchBtnDisabled: true })
                                this.setState({ productName: "" });
                                this.setState({ usedStatus: "" });
                                this.setState({ purchashedDate: "" });
                            });
                    }
                }).catch((error) => {
                    this.setState({ searchViewError: false, searchViewPurchaseCode: false, topProgressBar: false, purchaseCodeSearchBtnDisabled: true, firstSearchIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        } else {
            purchaseError["purchaseCode"] = "Please input Purchase Code";
            this.setState({ searchViewPurchaseCode: false, purchaseCodeSearchBtnDisabled: false })

        }
    }


    onSearchTokenId = () => {
        if (this.handleTokenErrorError()) {
            this.setState({ topProgressBar: true, secondSearchIsLoading: true, errorMsgVisible: false, searchViewTokenId: true })

            this.tokenService.findTokenInfoByTokenID(this.state.tokenId)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            this.setState({ purchaseInfo: body });
                            this.setState({ tokenType: body.tokenTypeInfoDTO.categoryName });
                            this.setState({ attachmentPath: body.attachmentPath });
                            this.setState({ tokenInfoID: body.tokenInfoID });
                            this.setState({ customTokenID: body.customTokenID });
                            this.setState({ tokenCreateDate: NetiDateUtils.getAnyShortForm(body.createDate, 'DD-MMM-YYYY') });
                            this.setState({ topProgressBar: false, secondSearchIsLoading: false, searchViewErrorTokenId: false, tokenIdSearchBtnDisabled: false })
                        });
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((res) => {
                                tokenError["Error"] = res;
                                this.setState({ topProgressBar: false, secondSearchIsLoading: false, searchViewErrorTokenId: true, tokenIdSearchBtnDisabled: true });
                                this.setState({ attachmentPath: "" });
                                this.setState({ tokenCreateDate: "" });
                                this.setState({ tokenInfoID: "" });
                                this.setState({ customTokenID: "" });
                            });

                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, searchViewErrorTokenId: false, searchViewErrorTokenId: true, searchViewTokenId: false, secondSearchIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        } else {
            tokenError["tokenId"] = "Please input Token Id";
            this.setState({ searchViewTokenId: false, searchViewErrorTokenId: true, })
        }
    }


    onSearchCustomNetiID = () => {
        if (this.handleNetiIdError()) {
            this.setState({ topProgressBar: true, thirdSearchIsLoading: true, errorMsgVisible: false, searchViewNetiId: true, searchViewErrorNetiId: false })
            this.profileService.findBasicInfoByCustomNetiID(this.state.netiId)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            this.setState({ netiName: body.fullName });
                            this.setState({ netiMobile: body.basicMobile });
                            this.setState({ netiidSave: body.netiID });
                            this.setState({ netiRegisterDate: NetiDateUtils.getAnyShortForm(body.registrationDate, 'DD-MMM-YYYY') });
                            this.setState({ topProgressBar: false, thirdSearchIsLoading: false, searchViewErrorNetiId: false, netiIdSearchBtnDisabled: false })
                        });
                    } else {
                        this.profileService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                netiIdError["Error"] = resp;
                                this.setState({ topProgressBar: false, netiIdSearchBtnDisabled: true, thirdSearchIsLoading: false, errorMsgVisible: true, errorMsgBody: resp, searchViewErrorNetiId: true })
                                this.setState({ netiName: "" });
                                this.setState({ netiMobile: "" });
                                this.setState({ netiRegisterDate: "" });
                                this.setState({ netiidSave: "" });
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, thirdSearchIsLoading: false, netiIdSearchBtnDisabled: true, errorMsgVisible: true, errorMsgBody: 'Please check your connection', searchViewErrorNetiId: false, searchViewNetiId: false });
                });
        } else {
            netiIdError["netiId"] = "Please input NetiId";
            this.setState({ searchViewNetiId: false, netiIdSearchBtnDisabled: true })
        }
    }

    loadParentByDesignationDefCod(e) {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        let { partnerTypeList, designation, newPartnerAssignError } = this.state;
        newPartnerAssignError["designation"] = "";
        this.setState({ newPartnerAssignError });
        let desigId = e.target.value;
        designation = desigId;
        this.setState({ designation });
        let desigObj = {};
        for (let i = 0; i < partnerTypeList.length; i++) {
            if (partnerTypeList[i].coreCategoryID == desigId) {
                desigObj = partnerTypeList[i];
            }
        }
        let desigDefCode = '';
        if (desigObj.categoryDefaultCode === 'T1120201') {
        } else if (desigObj.categoryDefaultCode === 'T1120202') {
            //AVP..
            desigDefCode = 'T1120201';
            // this.fetchAreaList('T10302');
        } else if (desigObj.categoryDefaultCode === 'T1120203') {
            //MBP..
            desigDefCode = 'T1120202';
            // this.fetchAreaList('T10303');
        } else if (desigObj.categoryDefaultCode === 'T1120204') {
            //ZBP..
            desigDefCode = 'T1120203';
            // this.fetchAreaList('T10304');
        } else if (desigObj.categoryDefaultCode === 'T1120205') {
            //BDP..
            desigDefCode = 'T1120204';
            // this.fetchAreaList('T10305');
        }
        this.adminPointService.fetchParentPartnerByDesign(desigDefCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ parentPartnerList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }


    getTeamMemberInfoList(assignID) {
        let parentIdObj = {
            "partnerAssignID": assignID,
            "pageNo": 0,
            "pageLimit": 10
        }

        this.adminPointService.getAllAssignedMemberList(parentIdObj)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("partnerParentList", body);
                        this.setState({ teamMemberInfoList: body });
                    })
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeParentId = (e) => {
        let { parentPartnerList, newPartnerAssignError } = this.state;
        newPartnerAssignError["parentType"] = "";
        this.setState({newPartnerAssignError});
        partnerAreaID = ''
        this.setState({ parentId: e.target.value })
        this.getTeamMemberInfoList(e.target.value)
        for (let i = 0; i < parentPartnerList.length; i++) {
            if (parentPartnerList[i].partnerAssignID == e.target.value) {
                partnerAreaID = parentPartnerList[i].partnerAreaInfoDTO.coreCategoryID;
                this.fetchMemberAreaList(partnerAreaID)
            }
        }
    }

    onChangeArea = (e) => {
        let { newPartnerAssignError } = this.state;
        newPartnerAssignError["area"] = "";
        this.setState({ areaId: e.target.value, newPartnerAssignError })
    }

    newPartnerAssignErrorHandler = () => {
        let { newPartnerAssignError, tokenError, netiIdError, purchaseError } = this.state;
        let formIsValid = true;

        // if (this.state.purchaseCode === "") {
        //     formIsValid = false;
        //     purchaseError["purchaseCode"] = "Purchase Code can't left empty.";
        // }
        // if (this.state.netiId === "") {
        //     formIsValid = false;
        //     netiIdError["netiId"] = "Neti ID can't left empty.";
        // }
        if (this.state.designation === "") {
            formIsValid = false;
            newPartnerAssignError["designation"] = "Designation can't left empty.";
        }

        if (this.state.parentId === "") {
            formIsValid = false;
            newPartnerAssignError["parentType"] = "Parent Type can't left empty.";
        }
        if (this.state.areaId === "") {
            formIsValid = false;
            newPartnerAssignError["area"] = "Area can't left empty.";
        }

        if (this.state.tokenTypeID === "" || this.state.tokenTypeID === null) {
            formIsValid = false;
            newPartnerAssignError["purposeList"] = "Changing Purpose can't left empty.";
        }
        if (this.state.tokenDetails === "") {
            formIsValid = false;
            newPartnerAssignError["tokenDetails"] = "Token Details can't left empty.";
        }
        if (this.state.tokenPicInfo.contentPic === "") {
            formIsValid = false;
            newPartnerAssignError["attachments"] = "Attachment can't left empty.";
        }
        this.setState({ newPartnerAssignError });
        return formIsValid;
    };

    focusSearch = e => {
        this.setState({
            searchLabel: "Search",
            addSearchBtnClass: "nw-button p-button-primary text-color-white"
        });
    }

    blurSearch = e => {
        this.setState({
            searchLabel: "",
            addSearchBtnClass: ""
        });
    }

    focusSearchToken = e => {
        this.setState({
            searchLabelToken: "Search",
            addSearchBtnClassToken: "nw-button p-button-primary text-color-white"
        });
    }

    blurSearchToken = e => {
        this.setState({
            searchLabelToken: "",
            addSearchBtnClassToken: ""
        });
    }

    focusSearchNetiId = e => {
        this.setState({
            searchLabelNetiId: "Search",
            addSearchBtnClassNetiId: "nw-button p-button-primary text-color-white"
        });
    }

    blurSearchNetiId = e => {
        this.setState({
            searchLabelNetiId: "",
            addSearchBtnClassNetiId: ""
        });
    }

    teamInfoBody = rowData =>{
        return (
            <div class="nw-datatable-dataview-small-panel">
                <div>
                    <div>Neti ID</div>
                    <div>:</div>
                    <div> {rowData.customID}</div>
                </div>

                <div>
                    <div>Name</div>
                    <div>:</div>
                    <div> {rowData.name}</div>
                </div>

                <div>
                    <div>Designation</div>
                    <div>:</div>
                    <div className="badge"> 
                        { rowData.mobileNo }
                    </div>
                </div>

                <div>
                    <div>Area</div>
                    <div>:</div>
                    <div> {rowData.area}</div>
                </div>
            </div>
        );
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let headervalue = <div className="header-title">
                            <div className="header-title-left">
                                Team Member Info
                            </div>
                            <div className="header-title-right">
                                <Link to="#" className="total-found">
                                    Total Found: {this.state.teamMemberInfoList.length} 
                                </Link>
                            </div>
                        </div>;

        let usedSts = ""

        if (this.state.usedStatus === 0) {
            usedSts = "Unused"
        } if (this.state.usedStatus === 1) {
            usedSts = "Used"
        }
        let partnerTypeOptions = [];
        if (this.state.partnerTypeList && this.state.partnerTypeList.length) {
            partnerTypeOptions = this.state.partnerTypeList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let areaListOptions = [];
        if (this.state.areaList && this.state.areaList.length) {
            areaListOptions = this.state.areaList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let parentPartnerOption = [];
        if (this.state.parentPartnerList && this.state.parentPartnerList.length) {
            parentPartnerOption = this.state.parentPartnerList.map(item => ({
                value: item.partnerAssignID,
                label: item.userBasicInfoDTO.fullName + ' (' + item.partnerAreaInfoDTO.categoryName + ')'
            }));
        }

        let purposeList = []
        if (this.state.changingPurposeDropdownList && this.state.changingPurposeDropdownList.length) {
            purposeList = this.state.changingPurposeDropdownList.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}

                <div className="main-section">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                            <div className="p-grid seprator-inside">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                                <InputText
                                                    value={this.state.purchaseCode}
                                                    onChange={(e) => this.setState({ purchaseCode: e.target.value })}
                                                    keyfilter="pint"
                                                    placeholder="Enter Purchase Code"
                                                    style={{ width: "15%" }}
                                                    type="text"
                                                    name="purchaseCode"
                                                    onFocus={this.focusSearch}
                                                    onBlur={this.blurSearch}
                                                />
                                                <Button
                                                    className={"p-button-animation " + this.state.addSearchBtnClass}
                                                    label={this.state.searchLabel}
                                                    icon="fas fa-search"
                                                    onClick={this.onSearchPurchaseCode}
                                                    onFocus={this.focusSearch}
                                                    onBlur={this.blurSearch}
                                                />
                                            </div>
                                            <span className="error-message">
                                                {this.state.purchaseError["purchaseCode"]}
                                            </span>

                                            {this.state.searchViewPurchaseCode ?

                                                <div className="nw-search-view">
                                                    {this.state.searchViewErrorPurchaseCode ?
                                                        <center className="error-message">{this.state.purchaseError["Error"] || "No Data Found"}</center> :
                                                        <div>
                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.firstSearchIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">
                                                                            Product Name
                                                                            </span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.productName}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.firstSearchIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">
                                                                            Use Status
                                                                            </span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {usedSts}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.firstSearchIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">
                                                                            Purchase Date
                                                                            </span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.purchashedDate}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                : ""
                                            }
                                        </div>


                                        <div className="p-col-12 p-xl-12">
                                            {this.state.packageDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Purpose <span>*</span></span>
                                                    <Dropdown
                                                        placeholder='select purpose'
                                                        value={this.state.tokenTypeID}
                                                        name="purposeList"
                                                        options={purposeList}
                                                        onChange={this.onChangePurposeList}
                                                        filter={true}
                                                        autoWidth={false}
                                                    />
                                                </div>
                                            }
                                            <span className='error-message'>{this.state.newPartnerAssignError["purposeList"]}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtexteditor-label">Details <span>*</span></span>
                                                <Editor
                                                    className="nw-inputtexteditor"
                                                    value={this.state.tokenDetails}
                                                    name="tokenDetails"
                                                    onTextChange={this.onChangeTokenDetails}
                                                    autoWidth={false}
                                                    maxLength={200}
                                                    placeholder="Enter token Details"

                                                />
                                            </div>
                                            <span className='error-message'>{this.state.newPartnerAssignError["tokenDetails"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Upload Attachment <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/PDF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    {
                                                        this.state.tokenPicInfo.contentPic ?
                                                            <div className="image-view-main">
                                                                <div className="upload-image-view">
                                                                    <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={this.removeButtonOnclick}
                                                                    />
                                                                    {this.state.tokenPicInfo.contentName ?
                                                                        this.commonFuctionality.getFileContentTypeIcon(this.state.tokenPicInfo.contentName) : ''}

                                                                </div>
                                                                <div className="image-title">{this.state.tokenPicInfo.contentName}</div>
                                                            </div>

                                                            :
                                                            <FileUpload
                                                                id="attachments"
                                                                name="attachments"
                                                                mode="basic"
                                                                maxFileSize={1000000}
                                                                onSelect={this.onFileUpload.bind(this)}
                                                                auto={true}
                                                                chooseLabel={this.state.tokenPicInfo.contentName ? this.state.tokenPicInfo.contentName : "Click / Drag Here To Upload"}

                                                            />
                                                    }

                                                </div>

                                            </div>
                                            <span className='error-message'>{this.state.newPartnerAssignError["attachments"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Neti Id <span>*</span></span>
                                                <InputText
                                                    value={this.state.netiId}
                                                    onChange={(e) => this.setState({ netiId: e.target.value })}
                                                    keyfilter="pint"
                                                    placeholder="Enter Neti Id"
                                                    style={{ width: "15%" }}
                                                    type="text"
                                                    name="netiId"
                                                    onFocus={this.focusSearchNetiId}
                                                    onBlur={this.blurSearchNetiId}
                                                />
                                                <Button
                                                    className={"p-button-animation " + this.state.addSearchBtnClassNetiId}
                                                    label={this.state.searchLabelNetiId}
                                                    icon="fas fa-search"
                                                    onClick={this.onSearchCustomNetiID}
                                                    onFocus={this.focusSearchNetiId}
                                                    onBlur={this.blurSearchNetiId}
                                                />
                                            </div>
                                            <span className="error-message">
                                                {this.state.netiIdError["netiId"]}
                                            </span>

                                            {this.state.searchViewNetiId ?

                                                <div className="nw-search-view">
                                                    {this.state.searchViewErrorNetiId ?
                                                        <center className="error-message">{this.state.netiIdError["Error"] || "No Data Found"}</center> :
                                                        <div>
                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.thirdSearchIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">
                                                                            Name
                                                                                </span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.netiName}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="p-col-12 p-xl-12">
                                                                {this.state.thirdSearchIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">
                                                                            Mobile No
                                                                                </span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.netiMobile}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="p-col-12 p-xl-12" style={{ height: "50px" }}>
                                                                {this.state.thirdSearchIsLoading ?
                                                                    this.NetiContentLoader.normalFormInputField() :
                                                                    <div className="p-inputgroup">
                                                                        <span className="task-badge found"></span>
                                                                        <span className="p-inputgroup-addon">
                                                                            Register Date
                                                                                </span>
                                                                        <span className="p-inputgroup-colon">:</span>
                                                                        <div className="nw-inputgroup-desc">
                                                                            {this.state.netiRegisterDate}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                : ""
                                            }
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            {this.state.firstDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Designation <span>*</span></span>
                                                    <Dropdown
                                                        placeholder='Select Designation'
                                                        options={partnerTypeOptions}
                                                        value={this.state.designation}
                                                        name="designation"
                                                        onChange={this.loadParentByDesignationDefCod.bind(this)}
                                                        filter={true}
                                                        filterBy="label,value"
                                                        style={{ width: "100%" }}
                                                        showClear={true}
                                                    />

                                                </div>
                                            }
                                            <span className="error-message">{this.state.newPartnerAssignError["designation"]}</span>


                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            {this.state.secondDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Parent <span>*</span></span>
                                                    <Dropdown
                                                        placeholder='Select Parent'
                                                        options={parentPartnerOption}
                                                        value={this.state.parentId}
                                                        onChange={this.onChangeParentId.bind(this)}
                                                        filter={true}
                                                        filterBy="label,value"
                                                        style={{ width: "100%" }}
                                                        name="parentType"
                                                        showClear={true}
                                                    />

                                                </div>
                                            }
                                            <span className="error-message">{this.state.newPartnerAssignError["parentType"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            {this.state.thirdDropdownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Area <span>*</span></span>
                                                    <Dropdown
                                                        placeholder='Select Area'
                                                        options={areaListOptions}
                                                        value={this.state.areaId}
                                                        onChange={this.onChangeArea}
                                                        filter={true}
                                                        filterBy="label,value"
                                                        style={{ width: "100%" }}
                                                        showClear={true}
                                                        name="area"
                                                    />

                                                </div>
                                            }
                                            <span className="error-message">{this.state.newPartnerAssignError["area"]}</span>
                                        </div>

                                        <div className="p-col-12" />

                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                                </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Save"
                                                icon="fas fa-check"
                                                onClick={event => this.submitSave(event)}
                                                disabled={(this.state.purchaseCodeSearchBtnDisabled === false) && (this.state.netiIdSearchBtnDisabled === false) ? !this.state.buttonDisabled : this.state.buttonDisabled}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-4">
                            <div className="p-col-12 p-xl-12 p-col-nogutter nw-datatable-detail-view due-bill-info-table parent-team-info-table">
                                <DataTable
                                    header={headervalue}
                                    value={this.state.teamMemberInfoList}
                                    responsive={true}
                                >
                                    <Column field="customID" header="Neti ID" body={this.teamInfoBody}/>
                                </DataTable>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        );
    }
}
