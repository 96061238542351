import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { MessageRechargeService } from '../../../../service/myPoint/MessageRechargeService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import { UserOtherAccessService } from '../../../../service/UserOtherAccessService';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { Dialog } from 'primereact/dialog';
import { pdfDownloadPortrait_TableWithSubTitle, pdfDownloadPortrait_TableOnly } from '../../../common/JsPdfDownload';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';


let maxDate = new Date();
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
export class PartnerRevenueLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableSelection: null,
            purchaseInfoDetails: {},
            viewDialogShow: false,
            messageRechargeError: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            tableView: false,
            inputErrorMessage: null,
            dataTableValue: [],
            partnerRevenueSearchInfo: {
                startDate: "",
                endDate: "",
            },
            dataTableIsLoading: false
        };

        this.messageRechargeService = new MessageRechargeService();
        this.userOtherAccessService = new UserOtherAccessService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
        this.commonFuctionality = new CommonFuctionality();
    }

    onChangeStartDate = (e) => {
        let { partnerRevenueSearchInfo } = this.state;
        partnerRevenueSearchInfo.startDate = e.target.value
        this.setState({ partnerRevenueSearchInfo });
        this.clearDateError(e.target.name);
    }

    onChangeEndDate = (e) => {
        let { partnerRevenueSearchInfo } = this.state;
        partnerRevenueSearchInfo.endDate = e.target.value
        this.setState({ partnerRevenueSearchInfo });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { messageRechargeError } = this.state;
        messageRechargeError[name] = ''
        this.setState({ messageRechargeError })
    }

    onSubmitHandler = (e, props) => {

        let { partnerRevenueSearchInfo } = this.state;
        if (this.messageRechargeFormHandleError()) {
            if (Date.parse(partnerRevenueSearchInfo.endDate) >= Date.parse(partnerRevenueSearchInfo.startDate)) {
                this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
                this.userOtherAccessService.findPartnerRevenueLog(partnerRevenueSearchInfo)
                    .then((res) => {
                        if (res.status == 302) {
                            return res.json().then((body) => {
                                body.map((item) => {
                                    item['amount'] = item.amount.replace('Taka', '');
                                });
                                this.setState({ dataTableValue: body, errorMsgVisible: false, topProgressBar: false, dataTableIsLoading: false })
                            });
                        } else {
                            this.userOtherAccessService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });

            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be ahead of To Date." });
            }

        }

    }

    messageRechargeFormHandleError = () => {
        let { messageRechargeError } = this.state;
        let formIsValid = true;

        if (this.state.partnerRevenueSearchInfo.startDate === '') {
            formIsValid = false;
            messageRechargeError["startDate"] = "From Date can't left empty.";
        }
        if (this.state.partnerRevenueSearchInfo.endDate === '') {
            formIsValid = false;
            messageRechargeError["endDate"] = "To Date can't left empty.";
        }

        this.setState({ messageRechargeError });
        return formIsValid;
    }

    balanceDateFormat = (rowData, Column) => {
        return <center><div>{rowData.date}</div></center>
    }

    payableAmountTemplate = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.productPurchaseLogDTO.totalAmount)
    }

    msgQuantityBody = (rowData) => {
        return (<b> {this.ValidatorUtility.currencyFormatter(rowData.quantity)}</b>)
    }

    actionBody = (rowData) => {
        return (
            <div className="text-center">
                <Button
                    className="nw-action-button edit"
                    icon="fas fa-eye"
                    tooltip="View"
                    onClick={e => this.viewDialog(rowData)}
                />
            </div>
        );
    }

    viewDialog = (rowData) => {
        this.commonFuctionality.blurDialogBackgroundActive();
        this.getPartnerPurchaseLog(rowData);
    }

    getPartnerPurchaseLog = (rowData) => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.setState({ viewDialogShow: true });

        this.userOtherAccessService.getUserPurchaseDetailsByPurchaseID(rowData.purchase_id)
            .then((res) => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ purchaseInfoDetails: body, errorMsgVisible: false });
                    });
                } else {
                    this.userOtherAccessService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    onHideViewDialog = () => {
        this.setState({ viewDialogShow: false });
        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    exportPdf = () => {
        
        let tableColumns = [
          { title: "Date", dataKey: "date" },
          { title: "Revenue Type", dataKey: "revenue_type" },
          { title: "Product Name", dataKey: "productName" },
          { title: "Amount", dataKey: "amount" },
        ];

        let tableColumnStyles = {
            amount: { halign: "right"},
        };

        pdfDownloadPortrait_TableWithSubTitle("Revenue Log Report", 80, pdfTitleHeader, pdfTitleHeaderData, tableColumns, this.state.dataTableValue, tableColumnStyles, "Revenue_Log.pdf");
    
    }

    textFormate = (rowData) => {
        let removeText = rowData.amount;
        return removeText.replace('Taka', '');
    }


    render() {
        let { dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody, purchaseInfoDetails, partnerRevenueSearchInfo } = this.state;
        let header = <div className="header-title">
                            <div className="header-title-left">
                                Revenue Log
                            </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length.toLocaleString("EN-IN")}
                                </a>
                            </div>
                        </div>;
        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        pdfTitleHeaderData = [
        { A: "From Date", B: NetiDateUtils.getAnyShortForm(partnerRevenueSearchInfo.startDate, 'DD-MMM-YYYY'), C: "To Date", D: NetiDateUtils.getAnyShortForm(partnerRevenueSearchInfo.endDate, 'DD-MMM-YYYY')}
        ];
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={el => (this.growl = el)} />
                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.partnerRevenueSearchInfo.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            name="startDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.messageRechargeError["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">From Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.partnerRevenueSearchInfo.endDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            name="endDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.messageRechargeError["endDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parrent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="p-grid">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table">
                                {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            :
                                            <div>
                                                <DataTable
                                                    value={this.state.dataTableValue}
                                                    header={header}
                                                    responsive={true}
                                                    paginator={true}
                                                    rows={10}
                                                    rowsPerPageOptions={[10, 20, 30]}
                                                >
                                                    <Column field="date" header="Date & Time"  filter={true} className="table-datetime"/>
                                                    <Column field="revenue_type" header="Revenue Type" filter={true} />
                                                    <Column field="productName" header="Product Name" filter={true} />
                                                    <Column field="amount" header="Amount" filter={true} className="text-right" body={this.textFormate} />
                                                    <Column body={this.actionBody} header="Details" style={{ width: "96px" }} />
                                                </DataTable>
                                                <div className="nw-button-parent m-t-8 p-r-0">
                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                        label="Download"
                                                        icon="fas fa-download"
                                                        onClick={this.exportPdf}
                                                    />
                                                </div>
                                            </div>
                                        :
                                        <BlankDataTableAnim />
                                }
                            </div>
                        </div>

                        </div>
                       
                    </div>
                </div>
                <div className="dialog-section">
                    <Dialog header="Purchase Info" visible={this.state.viewDialogShow} onHide={this.onHideViewDialog} className="nw-dialog product-info-dialog">
                        <div className="p-fluid">
                            <div className="p-grid">
                                {this.state.viewDialogShow ?
                                    <div className="p-grid p-col-12">
                                        <div className="p-col-6">
                                            <div class="nw-search-view no-border p-0">
                                                <div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Date </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.date}</div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Purchase Point  </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.purchase_point}</div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Product Type </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.product_type}</div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Product Name  </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.product_name}</div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Product Quantity </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.quantity}</div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="p-col-6">
                                            <div class="nw-search-view no-border p-0">
                                                <div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Unit Price </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.unit_price}</div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Total Amount  </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.total_amount}</div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Discount </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.discount}</div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Vat Amount  </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.vat_amount}</div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Paid Amount </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc"><span style={{ color: "#FF8C00", fontSize: '150%' }}>{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.paid_amount}</span></div>
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                        {purchaseInfoDetails.offerInfo && purchaseInfoDetails.offerInfo.offer_code !== null ?
                                            <div className="p-col-12">
                                                <div class="nw-search-view no-border p-0">
                                                    <div>
                                                        <div class="p-col-12 p-xl-12">
                                                            <div class="p-inputgroup">
                                                                <span class="task-badge found"></span>
                                                                <span class="p-inputgroup-addon"> Offer Code </span>
                                                                <span class="p-inputgroup-colon">:</span>
                                                                <div class="nw-inputgroup-desc">{purchaseInfoDetails.offerInfo && purchaseInfoDetails.offerInfo.offer_code}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}

                                        <div className="p-col-12">
                                            <div class="nw-search-view no-border p-0">
                                                <div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Purchase By Neti ID </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.purchase_neti_id}</div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-xl-12">
                                                        <div class="p-inputgroup">
                                                            <span class="task-badge found"></span>
                                                            <span class="p-inputgroup-addon"> Name </span>
                                                            <span class="p-inputgroup-colon">:</span>
                                                            <div class="nw-inputgroup-desc">{purchaseInfoDetails.productDetailsInfo && purchaseInfoDetails.productDetailsInfo.name}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {purchaseInfoDetails.instituteInfo && purchaseInfoDetails.instituteInfo.institute_id !== null ?
                                            <div className="p-col-12">
                                                <div class="nw-search-view no-border p-0">
                                                    <div>
                                                        <div class="p-col-12 p-xl-12">
                                                            <div class="p-inputgroup">
                                                                <span class="task-badge found"></span>
                                                                <span class="p-inputgroup-addon"> Institute ID </span>
                                                                <span class="p-inputgroup-colon">:</span>
                                                                <div class="nw-inputgroup-desc">{purchaseInfoDetails.instituteInfo && purchaseInfoDetails.instituteInfo.institute_id}</div>
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-xl-12">
                                                            <div class="p-inputgroup">
                                                                <span class="task-badge found"></span>
                                                                <span class="p-inputgroup-addon"> Institute Name </span>
                                                                <span class="p-inputgroup-colon">:</span>
                                                                <div class="nw-inputgroup-desc">{purchaseInfoDetails.instituteInfo && purchaseInfoDetails.instituteInfo.institute_name}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}

                                        {/* <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                            <Button
                                                label="Close"
                                                icon="fas fa-times"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                onClick={this.onHideViewDialog}
                                            />
                                        </div> */}
                                    </div>
                                    : ''}
                            </div>
                        </div>

                    </Dialog>
                </div>

            </div>
        );
    }
}