import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Dialog } from 'primereact/dialog';
import { ProfileService } from '../../../service/profile/ProfileService';
import { Growl } from 'primereact/growl';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import Formatter from '../../../utils/Formatter';
import {Lightbox} from 'primereact/lightbox';
import { Dropdown } from 'primereact/dropdown';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { CUSTOM_MESSAGE } from '../../common/CustomMessage';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

let errors = {};
let urlId;
export class UserNature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customNetiID: '',
            dataTableValue:[],
            receiver: {},
            balanceInfoError: {},
            OTPcode: '',
            searchUserObj: {
                requestedAmmount: '',
                receiver: {
                    netiID: null
                },
                requestNote: '',
            },
            userNatureObj:{
                userNatureTypeInfoDTO: {
                    coreCategoryID: ''
                },
                userBasicInfoDTO: {
                    netiID: ''
                },
                note: ''
            },
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            errors: {},
            checkedRetriveObj: {},
            searchIsLoadingFirst: false,
            searchIsLoadingSecond: false,
            searchView: false,
            searchViewError: false,
            buttonDisabled: true,
            imageContent:''
        }

        this.profileService = new ProfileService();
        this.NetiContentLoader = new NetiContentLoader();
        this.formatter = new Formatter();
        this.CommonFuctionality = new CommonFuctionality();

        this.userCategoryService = new UserCategoryService();
        this.AdminPointService = new AdminPointService();

        
    }

    componentWillMount() {
        this.natureListInfo();
        this.getUserNatureInfoList();
        // cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }


    natureListInfo() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.userCategoryService.fetchListByDefaultCode('T120')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ natureList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: CUSTOM_MESSAGE.ERROR_CONNECTION_MSG });
            });

    }

    onChangeNature(e) {
        let { userNatureObj, errors } = this.state;
        userNatureObj.userNatureTypeInfoDTO.coreCategoryID = e.target.value;
        errors["natureId"] = "";
        this.setState({ userNatureObj, errors, natureValue: e.target.value, });
    }

    onChangeCustomNetiID = (e) => {
        let { errors } = this.state;
        // userNatureObj.userBasicInfoDTO.netiID = e.target.value;
        errors["customNetiID"] = "";

        this.setState({customNetiID: e.target.value, errors});
        this.setState({ receiver: {}, searchViewError: false, searchView: false, buttonDisabled: true });
    }

    onChangeNote = (e) => {
        let { userNatureObj, errors } = this.state;
        userNatureObj.note = e.target.value;
        errors["note"] = "";
        this.setState({ userNatureObj, errors });
    }

    focusSearch = e =>{
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
    }

    blurSearch = e =>{
        this.setState({ searchLabel: '', addSearchBtnClass: ""  });
    }

    handleErrorNetiId = () => {
        let { errors, customNetiID } = this.state;
        let formIsValid = true;
        
        if (this.state.customNetiID === '') {
            formIsValid = false;
            errors["customNetiID"] = "Custom neti ID can't left empty.";
            this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
        }

        if (customNetiID.length < 10) {
            formIsValid = false;
            errors["customNetiID"] = "Neti ID is too sort";
        } 

        if (customNetiID == '' && customNetiID.length < 10) {
            formIsValid = false
            errors["customNetiID"] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10';
        }


        this.setState({ errors });
        return formIsValid;
    }

    onChangeSearchByCustomNetiID = (e, props) => {
        if (this.handleErrorNetiId()) {
            this.setState({ topProgressBar: true, searchIsLoadingFirst: true, searchIsLoadingSecond: true, errorMsgVisible: false, searchView: true, searchViewError: false });
            let { searchUserObj, userNatureObj } = this.state;
            this.profileService.findBasicInfoByCustomNetiID(this.state.customNetiID)
                .then((res) => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            let userProfile = JSON.parse(localStorage.getItem('Profile'));
                            if (body.customNetiID === userProfile.customNetiID) {
                                errors["customNetiID"] = "You can't assign nature in your own account";
                                this.setState({ buttonDisabled: true })
                                searchUserObj.receiver.netiID = body.netiID;
                                userNatureObj.userBasicInfoDTO.netiID = body.netiID
                                // body.imageContent = this.CommonFuctionality.getImg(body.imagePath).then((content)=>{ return content })

                                this.setState({
                                    userNatureObj,
                                    receiver: body, searchUserObj,
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false,
                                    searchViewError: false,
                                    // imageContent: this.CommonFuctionality.getImg(body.imagePath).then((content)=>{ return content })
                                });
                            } else {
                                errors["customNetiID"] = "";
                                searchUserObj.receiver.netiID = body.netiID;
                                userNatureObj.userBasicInfoDTO.netiID = body.netiID
                                this.setState({
                                    userNatureObj,
                                    receiver: body, searchUserObj,
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false,
                                    searchViewError: false,
                                    imageContent: this.CommonFuctionality.getImg(body.imagePath).then((content)=>{ return content })
                                });

                                this.setState({ buttonDisabled: false })
                            }

                            this.CommonFuctionality.getImg(body.imagePath).then((content)=>{
                                this.setState({
                                    imageContent:  content
                                }); 
                            })

                        });
                    } else {
                        this.profileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({
                                    receiver: {},
                                    topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false, errorMsgBody: responseBody,
                                    searchViewError: true
                                })
                                this.setState({ buttonDisabled: true })

                            });
                    }
                }).catch(error => {
                    this.setState({
                        topProgressBar: false, searchIsLoadingFirst: false, searchIsLoadingSecond: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!",
                        searchViewError: false, searchView: false
                    })
                });
        }
    }


    onNatureSubmit = (e) => {
        let { userNatureObj, receiver } = this.state
        if (this.userNatureObjHandleError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.AdminPointService.createUserNature(userNatureObj)
            .then((res) => {
                this.setState({ topProgressBar: false});
                if (res.status == 201) {
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully submitted' });
                    // this.onHide(e);
                    this.getUserNatureInfoList();
                } else {
                    this.AdminPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
        }
    }

    userNatureObjHandleError = () => {
        let { userNatureObj, errors } = this.state;
        let formIsValid = true;

        if (userNatureObj.userNatureTypeInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            errors["natureId"] = "To Nature ID can't left empty.";
        }
        if (userNatureObj.userBasicInfoDTO.netiID === '') {
            formIsValid = false;
            errors["customNetiID"] = "To Neti ID can't left empty.";
        }
        
        if (userNatureObj.note === '') {
            formIsValid = false;
            errors["note"] = "Note can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;
    }

    getUserNatureInfoList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.AdminPointService.findUserNatureConfigList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false  });
                    });
                } else {
                    this.AdminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }



    render() {
        let { dataTableValue, userNatureObj, receiver, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> User Nature List <span style={{ 'float': 'right' }}> Total Found: {dataTableValue ? dataTableValue.length : 0} </span></div>;

        let natureListOptions = []
        if (this.state.natureList && this.state.natureList.length) {
            natureListOptions = this.state.natureList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
                // parentTypeInfoDTO: item.parentTypeInfoDTO.categoryDefaultCode
            }));
        }
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section blur-section">
                    <div className="p-grid nw-form ">
                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                            <Growl ref={(el) => this.growl = el} />
                            <div className='nw-form-body'>
                                <div className="p-col-12 p-xl-12">

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.firstDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Nature Id<span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Bank Name"
                                                    value={userNatureObj.userNatureTypeInfoDTO.coreCategoryID}
                                                    options={natureListOptions}
                                                    onChange={(e) => this.onChangeNature(e)}
                                                    // itemTemplate={this.bankTemplate}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name={natureListOptions}
                                                />
                                                {/* <span className="p-inputgroup-addon">@</span> */}
                                            </div>
                                        }
                                        <span className='error-message'>{errors["natureId"]}</span>
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">To Neti ID <span>*</span></span>
                                            <InputText
                                                value={this.state.customNetiID}
                                                onChange={this.onChangeCustomNetiID}
                                                placeholder="Enter Transfer Neti ID"
                                                name="customNetiID"
                                                showClear={true}
                                                style={{ width: "15%" }}
                                                type="text"
                                                onFocus={ this.focusSearch }
                                                onBlur={ this.blurSearch }
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                label={ this.state.searchLabel}
                                                icon="fas fa-search"
                                                onClick={(e) => this.onChangeSearchByCustomNetiID(e, this.props)}
                                                onFocus={ this.focusSearch }
                                                onBlur={ this.blurSearch }
                                            />
                                        </div>
                                        <span className="error-message">{ errors["customNetiID"] }</span>
                                    </div>

                                    {this.state.searchView ?
                                        <div className="p-col-12 p-xl-12">

                                            <div className="nw-search-view">

                                                {this.state.searchViewError ?
                                                    <center className="error-message">{this.state.errorMsgBody || "No Data Found"}</center> :
                                                    <div>
                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.searchIsLoadingFirst ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {receiver.fullName}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.searchIsLoadingSecond ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Mobile No.</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {receiver.basicMobile ? this.formatter.getMaskedNumber(receiver.basicMobile) : ''}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.searchIsLoadingSecond ?
                                                                this.NetiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">Image</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        <img src={this.state.imageContent} style={{maxWidth:"150px"}}/>
                                                                    </div>
                                                                    
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>



                                        </div>
                                        :
                                        ''
                                    }

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                            <InputTextarea
                                                value={userNatureObj.note}
                                                onChange={(e) => this.onChangeNote(e)}
                                                placeholder="Enter Transfer Note"
                                                rows={3}
                                                cols={30}
                                                autoResize={true}
                                                name="requestNote"
                                            />
                                        </div>
                                        <span className="error-message">{errors["note"]}</span>

                                    </div>

                                    <div className="p-col-12">

                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            className="nw-button nw-button-right p-button-primary"
                                            label="Next"
                                            icon="fas fa-arrow-right"
                                            iconPos="right"
                                            onClick={this.onNatureSubmit}
                                            disabled={this.state.buttonDisabled}
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

           
                            <div className="nw-data-table">
                                {
                                    
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={dataTableValue}
                                            header={tableHeader}
                                            responsive={true}
                                        >
                                            <Column field="userBasicInfoDTO.customNetiID" header="Neti ID" filter={true} />
                                            <Column field="userBasicInfoDTO.fullName" header="Name" filter={true}/>
                                            <Column field="userBasicInfoDTO.basicMobile" header="Mobile" filter={true}/>
                                            <Column field="userNatureTypeInfoDTO.categoryName" header="Nature" filter={true}/>
                                        </DataTable>
                            }
                            </div>
                     

            </div>
        );
    }
}