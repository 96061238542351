import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';

export class TeacherEducationInfo extends Component {

    constructor(props) {
        super(props);
        this.teacherPortal = new TeacherPortal();
    }

    render() {

        let educationContent = ''
        // let gpa = (rowData) => {
        //     return <div>{rowData.gpa}/{rowData.outOfGpa}</div>
        // }
        if (this.props.basicInfo.item) {
            if (this.props.basicInfo.item.staffEducation) {
                let i = 0;
                educationContent = this.props.basicInfo.item.staffEducation.map((item) => {

                    i++
                    return (
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2" >
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Education Info {i}</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Exam Title</h4>
                                        <p>{item.examTitle || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Board</h4>
                                        <p>{item.boardName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Institute Name</h4>
                                        <p>{item.instituteName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Group</h4>
                                        <p>{item.groupName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Passing Year</h4>
                                        <p>{item.passingYear || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Grade Point</h4>
                                        <p>{item.division || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Grade Point</h4>
                                        <p>{item.gpa || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )



                }


                )
            }


        }

        return educationContent;
    }
}