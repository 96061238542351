import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { PurchaseService } from '../../service/myPoint/PurchaseService';
import {NetiDateUtils} from '../../utils/NetiDateUtils';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { Paginator } from 'primereact/paginator';

let errors = {};
export class DeviceApproval extends Component { 

    constructor() {
        super();
        this.state = {
            productInventoryID: '',
            searchDataObj: {
                defCode:"C104002"
            },
            first: 0, 
            rows: 10, 
            pendingDeviceList: '',
            unMappedDevices: '',
            dataTableValue: [],
            selectedRow: {},
            visible: false,
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
        }

        this.onChangeDevice = this.onChangeDevice.bind(this);
        this.submitForLinkedUp = this.submitForLinkedUp.bind(this);

        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);
        this.NetiContentLoader = new NetiContentLoader();

        this.purchaseService = new PurchaseService();
        this.crmPointService = new CRMPointService();
    }

    componentWillMount() { 
        this.fetchDevicePendingList(); 
    }

    fetchDevicePendingList() {
        this.setState ({topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false});
        this.purchaseService.fetchPendingDeviceListOfUser(this.state.searchDataObj)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => { 
                        body.map(item => {
                            item['orderDate'] = NetiDateUtils.getAnyShortForm(item['orderDate'], 'DD-MMM-YYYY');
                        })
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false })
                     });
                } 
                else { 
                    this.purchaseService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({  topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                } 
            }).catch(err => 
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
             );
    }
    
    fetchUnMappedDeviceList(value) {
        this.crmPointService.fetchProductUnApprovedDeviceListByProductID(value)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => { this.setState({ unMappedDevices: body }) })
                }
                else { 
                    return res.json().then((errorBody) => console.log(errorBody)) 
                }
            }).catch(err => console.log(err));
    }

    onChangeDevice(value) {
        this.setState({ productInventoryID: value });
    }

    viewDialog(rowData) {
        this.fetchUnMappedDeviceList(rowData.productID);
        this.setState({ selectedRow: rowData, visible: true });
    }

    onHide() {
        this.setState({ visible: false });
    }

    submitForLinkedUp() {

        if (this.handleError()) {

            let { selectedRow } = this.state;
            let requestedObj = {
                "productInventoryID": this.state.productInventoryID,
                "productPurchaseCodeDTO": {
                    "purchaseCode": selectedRow.purchaseCode,
                }
            }
            this.setState({ dataTableIsLoading: false,topProgressBar: true, errorMsgVisible: false });

            this.crmPointService.approveInventoryDevice(requestedObj)
                .then(res => {
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data Saved Successfully' });
                        this.setState({ visible: false });
                        this.setState({ dataTableIsLoading: true,topProgressBar: false, errorMsgVisible: false });
                        this.fetchDevicePendingList();
                    }   else {
                        this.crmPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false,topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ dataTableIsLoading: false,topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                });
        }
    }

    handleError() {
        
        errors = {};
        let formIsValid = true;

        if (this.state.productInventoryID === '') {
            formIsValid = false;
            errors["device"] = "Device can't left empty.";
        }
        else { return formIsValid }
        this.setState({ errors: errors });
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let {dataTableValue} = this.state;
        let deviceOptions = [];
        console.log("Device List",this.state.unMappedDevices);
        
        if (this.state.unMappedDevices && this.state.unMappedDevices.length) {
            deviceOptions = this.state.unMappedDevices.map(item => ({
                value: item.productInventoryID,
                label: item.productUniqueID
            }));
        }

        let { selectedRow } = this.state;
        let editBody =(rowData) =>{

            return <div className='text-center'>
                <Button
                    className="nw-action-button edit"
                    icon="fas fa-edit"
                    tooltip="Linkup"
                    onClick={(e) => this.viewDialog(rowData)}
                />
            </div>
        }

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Pending Device List <span style={{float:'right'}}> Total Found: {this.state.dataTableValue.length} </span></div>
        
        //template for Pending Device List


        return (
            
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                <div className="p-grid nw-form">
                    <Growl ref={(el) => this.growl = el} />
                    <div className="p-col-12 p-xl-12">
                    {  this.state.dataTableIsLoading ?
                       this.NetiContentLoader.MyPointTableLoader():
                        <div className="nw-data-table">
                            <DataTable
                                header={tableHeader}
                                value={this.state.dataTableValue}
                                responsive={true}
                                paginator={true}
                                rows={10}
                            >
                                    <Column field="orderDate" header="Order Date" filter={true} />
                                    <Column field="productName" header="Product Name" filter={true} />
                                    <Column field="purchaseCode" header="Purchase Code" filter={true} />
                                    <Column field="customNetiID" header="Neti ID" filter={true} />
                                    <Column field="name" header="Name" filter={true} />
                                    <Column field="mobile" header="Mobile No." filter={true} />
                                    <Column field="area" header="Area" filter={true} />
                                    <Column field="designation" header="Partner Type" filter={true} />
                                    <Column field="" header="Action" body={editBody} style={{ width:"80px" }} />
                            </DataTable>
                        </div>
                    }
                    </div>
                        
                     
                    </div>
                </div>
                <div className="dialog-section">

                <Dialog header="Device Request" 
                className="nw-dialog" 
                visible={this.state.visible} 
                onHide={this.onHide}>
                            <div className="p-fluid">
                                <div className="p-grid">

                                    <div className="p-col-12">
                                        <Panel>
                                        <div className="p-grid">
                                            
                                            <div className="p-col-6">
                                                <Fieldset legend="Purchase Code Info">
                                                    <div className="p-grid">
                                                        <div className="p-col-5">
                                                            <label>Product Name : </label><br />
                                                            <label>Purchase Code : </label><br />
                                                            <label>Order Date : </label><br />
                                                        </div>
                                                        {this.state.visible ?
                                                            <div className="p-col-7">
                                                                <label>{selectedRow.productName}</label> <br />
                                                                <label>{selectedRow.purchaseCode} </label> <br />
                                                                <label>{selectedRow.orderDate} </label>
                                                            </div>
                                                        : ''}
                                                            
                                                        </div>
                                                        <span><br/></span>
                                                    </Fieldset>
                                                </div>

                                                <div className="p-col-6">
                                                <Fieldset legend="Requester Information">
                                                    <div className="p-grid">
                                                            <div className="p-col-5">
                                                                <label>Neti ID : </label><br />
                                                                <label>Name : </label><br />
                                                                <label>Partner Type : </label><br />
                                                                <label>Mobile No. : </label><br />
                                                                <label>Area : </label>
                                                            </div>
                                                            {this.state.visible ?
                                                                <div className="p-col-7">
                                                                    <label>{selectedRow.customNetiID}</label> <br />
                                                                    <label>{selectedRow.name} </label> <br />
                                                                    <label>{selectedRow.designation} </label> <br />
                                                                    <label>{selectedRow.mobile} </label> <br />
                                                                    <label>{selectedRow.area} </label>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                </Fieldset>
                                            </div>
                                                
                                                <div className="p-col-12">
                                                    <div className="p-grid">

                                                        <div className="p-col-12" />
                                                        <div className="p-col-12 p-lg-3 p-xl-3" />
                                                        <div className="p-col-12 p-lg-1 p-xl-1">
                                                            <label style={{ marginTop: '3px' }}>Device: </label>
                                                        </div>

                                                        <div className="p-col-12 p-lg-4 p-xl-4">
                                                        <div className="formControl">
                                                            <Dropdown
                                                                placeholder='Select Device'
                                                                name="device"
                                                                options={deviceOptions}
                                                                value={this.state.productInventoryID}
                                                                onChange={(e) => this.onChangeDevice(e.target.value)}
                                                                filter={true}
                                                                autoWidth={false}
                                                                />
                                                                <span className='error-message'>{this.state.errors["device"]}</span>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="p-col-12 p-lg-4 p-xl-4" />
                                                    </div>

                                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        onClick={event => this.onHide(event)}
                                                    />
                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        label="Approve"
                                                        icon="fas fa-check"
                                                        onClick={event => this.submitForLinkedUp(event)}
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                </div>
                </div>
            
            
        )
    }
}