import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { PartnerPointService } from '../../../service/partnerPoint/PartnerPointService';


import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';

import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserOtherAccessService } from '../../../service/UserOtherAccessService';



let cloneStateBeforeMount;
let urlId;
export class AdmisiaPointMappedInstitute extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonDisabled: true,
            purchaseCodeSearchBtnDisabled: true,
            instituteIDSearchBtnDisabled: true,
            mappedInstituteList: [],
            dataTableIsLoading: false,
            instituteID: '',
            purchaseCodeError: {},
            instituteInformation: {},
            mappedInstituteInfoObj: {
                mappingTitle: '',
                productPurchaseCodeDTO: {
                    purchaseCode: '',
                    productPurchaseCodeID: ''
                },
                edumanDetailsInfoDTO: {
                    emDetailsId: ''
                },
                urlInfoDTO: {
                    urlInfoID: ''
                }
            },

            instituteIDErrorObj: {},
            firstInputFieldIsLoading: false,
            purchaseCodeSearchView: false,
            purchaseCodeSearchViewError: false,
            purchaseCodeSearchErrorMsgBody: '',
            secondInputFieldIsLoading: false,
            instituteIDSearchView: false,
            instituteIDSearchViewError: false,
            instituteIDSearchViewErrorMsgBody: '',
            purchaseCode: '',
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: '',
            purchaseInformation: {
                productName: '',
            }
        };

        this.admisiaPointService = new AdmisiaPointService();
        this.PartnerPointService = new PartnerPointService();
        this.userOtherAccessService = new UserOtherAccessService();
        this.netiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (urlId) {
            let { urlInfoDTO } = this.state.mappedInstituteInfoObj
            urlInfoDTO.urlInfoID = urlId;
            this.setState({ urlInfoDTO })
            this.setState({ homeReturnButton: false })
            this.viewMappedInstituteInfoList();
        }
        else {
            this.setState({ homeReturnButton: true })
        }
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    purchaseCodeHandler = (event) => {
        let { purchaseCodeError } = this.state
        purchaseCodeError["purchaseCode"] = "";
        this.setState({ purchaseCode: event.target.value, purchaseCodeError });
    }

    purchaseCodeCheck = (e) => {
        let  {productPurchaseCodeID} = this.state.mappedInstituteInfoObj.productPurchaseCodeDTO
        this.setState({ purchaseInformation: cloneStateBeforeMount.purchaseInformation, purchaseCodeSearchErrorMsgBody: '', purchaseCodeSearchView: false });
        if (this.purchaseCodeError()) {
            this.setState({ topProgressBar: true, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: true, errorMsgVisible: false, purchaseCodeSearchView: true });
            this.PartnerPointService.getUserPurchaseCode(this.state.purchaseCode)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            let productDefaultCode = body.productPurchaseLogDTO.productInfoDTO.productDefaultCode

                            if(productDefaultCode == 450 ){
                                let { purchaseInformation } = this.state;
                                purchaseInformation.productName = body.productPurchaseLogDTO.productInfoDTO.productName;
                                purchaseInformation.usedStatus = body.usedStatus;
                                purchaseInformation.purchaseDate = NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
                                this.setState({ productPurchaseCodeID: body.productPurchaseCodeID ,purchaseInformation,purchaseCodeSearchBtnDisabled: false , topProgressBar: false, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: false, errorMsgVisible: false, purchaseCodeSearchView: true });
                            }
                            else{
                                this.setState({
                                    purchaseCodeSearchBtnDisabled: true, 
                                    firstInputFieldIsLoading: false,
                                    topProgressBar: false,
                                    purchaseCodeSearchErrorMsgBody: "Purchase code is invalid for this segment",
                                    purchaseCodeSearchViewError: true,
                                    purchaseCodeSearchView: true,
                                    purchaseInformation: {
                                        productName: ''
                                    }
                                });
                            }
                        })
                    } else {
                        this.PartnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({
                                    purchaseCodeSearchBtnDisabled: true, 
                                    firstInputFieldIsLoading: false,
                                    topProgressBar: false,
                                    purchaseCodeSearchErrorMsgBody: resp,
                                    purchaseCodeSearchViewError: true,
                                    purchaseCodeSearchView: true,
                                    purchaseInformation: {
                                        productName: ''
                                    }
                                });
                            })
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, purchaseCodeSearchBtnDisabled: true, firstInputFieldIsLoading: false, errorMsgVisible: true, purchaseCodeSearchView: false, purchaseCodeSearchViewError: false, errorMsgBody: 'Unable to load. Please check your connection.' })

                });
        }
    }

    purchaseCodeError = () => {
        let { purchaseCodeError } = this.state;
        let formIsValid = true;
        if (this.state.purchaseCode === '') {
            formIsValid = false;
            purchaseCodeError["purchaseCode"] = "Purchase Code can't left empty";
        } else {
            purchaseCodeError["purchaseCode"] = "";
        }
        this.setState({ purchaseCodeError });
        return formIsValid;
    }

    onChangeinstituteID = (e) => {
        let { instituteIDErrorObj } = this.state;
        instituteIDErrorObj["instituteID"] = ''
        this.setState({ instituteID: e.target.value, instituteIDErrorObj });
    }

    instituteIDError = () => {
        let { instituteIDErrorObj } = this.state;
        let formIsValid = true;
        if (this.state.instituteID === '') {
            formIsValid = false;
            instituteIDErrorObj["instituteID"] = "Institute ID can't left empty";
        } else {
            instituteIDErrorObj["instituteID"] = "";
        }
        this.setState({ instituteIDErrorObj });
        return formIsValid;
    }

    searchInstituteID = (e) => {
        this.setState({ instituteInformation: cloneStateBeforeMount.instituteInformation, instituteIDSearchViewErrorMsgBody: '', instituteIDSearchView: false  });
        if (this.instituteIDError()) {
            this.setState({ topProgressBar: true, secondInputFieldIsLoading: true, errorMsgVisible: false,instituteIDSearchViewError: false,instituteIDSearchView: true  });
            this.admisiaPointService.getEdumanInstituteInfoByID(this.state.instituteID, urlId)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            console.log("institute info", body);
                            this.setState({ instituteInformation: body, instituteIDSearchBtnDisabled: false,topProgressBar: false, instituteIDSearchView: true , secondInputFieldIsLoading: false, errorMsgVisible: false, instituteIDSearchViewError: false });
                        })
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({
                                    instituteIDSearchBtnDisabled: true,
                                    errorMsgVisible: false,
                                    secondInputFieldIsLoading: false,
                                    topProgressBar: false,
                                    instituteIDSearchViewErrorMsgBody: resp,
                                    instituteIDSearchViewError: true,
                                    instituteIDSearchView: true,
                                    instituteInformation: {
                                        instituteContact: ''
                                    }
                                });
                            })
                    }
                }).catch(error => {
                    this.setState({instituteIDSearchBtnDisabled: true, topProgressBar: false, secondInputFieldIsLoading: false,instituteIDSearchView: false ,instituteIDSearchViewError: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })

                });
        }
    }

    viewMappedInstituteInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
 
        this.admisiaPointService.getURLMappedInstituteList(urlId)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("mapped lis 3",body);
                      

                        for(let i=0; i<body.length; i++){
                            if (body[i].approvedStatus === 0) {
                                body[i].approvedStatus = 'Pending';
                            } 
                            else if (body[i].approvedStatus === 1) {
                                body[i].approvedStatus = 'Approved';
                            }
                            else if (body[i].approvedStatus === 2) {
                                body[i].approvedStatus = 'Rejected';
                            }
                        }

                        this.setState({ mappedInstituteList: body, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })});


                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, dataTableIsLoading: false, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }



    onChangeMappingTitle = (e) => {
        let { mappedInstituteInfoObj } = this.state;
        mappedInstituteInfoObj.mappingTitle = e.target.value;
        this.setState({ mappedInstituteInfoObj });
    }

    onSubmitMappedInstitute = () => {
        let { mappedInstituteInfoObj, instituteInformation } = this.state;
        mappedInstituteInfoObj.edumanDetailsInfoDTO.emDetailsId = instituteInformation.emDetailsId;
        mappedInstituteInfoObj.productPurchaseCodeDTO.purchaseCode = this.state.purchaseCode;
        mappedInstituteInfoObj.productPurchaseCodeDTO.productPurchaseCodeID = this.state.productPurchaseCodeID;


        this.setState({ mappedInstituteInfoObj, topProgressBar: true, errorMsgVisible: false });
        console.log("mappedInstituteInfoObj save", mappedInstituteInfoObj);

        
        this.admisiaPointService.dwsCoreURLMapping(mappedInstituteInfoObj)
            .then(res => {
                if (res.status == 201) {

                    console.log("MAP", mappedInstituteInfoObj);
                    
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully submitted!" });
                    this.setState({ topProgressBar: false, errorMsgVisible: false });
                    this.viewMappedInstituteInfoList();
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }

    focusSearch = e =>{
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
    }

    blurSearch = e =>{
        this.setState({ searchLabel: '', addSearchBtnClass: ""  });
    }

    focusSearchInstitute = e =>{
        this.setState({ searchLabelInstitute: "Search", addInstituteSearchBtnClass: "nw-button p-button-primary text-color-white"  });
    }

    blurSearchInstitute = e =>{
        this.setState({ searchLabelInstitute: '', addInstituteSearchBtnClass: ""  });
    }

    render() {


        console.log("COde ID", this.state.mappedInstituteInfoObj.productPurchaseCodeDTO.purchaseCode);
        


      
        let { topProgressBar, errorMsgVisible, errorMsgBody, firstInputFieldIsLoading, instituteInformation, secondInputFieldIsLoading} = this.state;

        let { mappedInstituteList } = this.state;



        if(mappedInstituteList[0]){
            console.log("mappedInstituteList", mappedInstituteList[0].mappingTitle);
        }
       
    

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Mapped Institute List
    </div>;


        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                {this.state.homeReturnButton === false ?
                    <div className="main-section">

                     {this.state.mappedInstituteList.length != 0 ?
                     <div className="p-grid nw-form">


                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                    {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                              Institute Mapping Title
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {mappedInstituteList[0] ? mappedInstituteList[0].mappingTitle : ''}
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                              Institute Name
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                                {mappedInstituteList[0] ? mappedInstituteList[0].edumanDetailsInfoDTO.instituteName : ''}
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Institute ID
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                             {mappedInstituteList[0] ? mappedInstituteList[0].edumanDetailsInfoDTO.instituteId : ''}
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Institute Contact No.
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                              {mappedInstituteList[0] ? mappedInstituteList[0].edumanDetailsInfoDTO.instituteContact : ''}
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                            Status
                                            </span>
                                            <div className="nw-inputgroup-desc">
                                             {mappedInstituteList[0] ? mappedInstituteList[0].approvedStatus : ''}
                                            </div>
                                        </div>
                                    }
                                </div>

                
                            </div>
                        </div>
                    </div>
                    
                    :

                        <div className="p-grid nw-form">

                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                            <InputText
                                                placeholder="Enter Purchase Code"
                                                value={this.state.purchaseCode}
                                                style={{ width: "15%" }}
                                                type="text"
                                                name="purchaseCode"
                                                onChange={(e) => this.purchaseCodeHandler(e)}
                                                keyfilter="num"
                                               
                                               
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                icon="fas fa-search"
                                                label={ this.state.searchLabel}
                                                onClick={(e) => this.purchaseCodeCheck(e)}
                                                onFocus={ this.focusSearch }
                                                onBlur={ this.blurSearch }
                                            />
                                        </div>
                                        <span className="error-message">{this.state.purchaseCodeError["purchaseCode"]}</span>
                                    </div>


                                    {this.state.purchaseCodeSearchView ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-search-view">
                                                {this.state.purchaseCodeSearchViewError ?
                                                    <center className="error-message">{this.state.purchaseCodeSearchErrorMsgBody}</center> :
                                                    <div>


                                                        {
                                                            this.state.purchaseInformation.productName === '' ? '' :
                                                                <div>
                                                                    <div className="p-col-12 p-xl-12">
                                                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Product Name</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {this.state.purchaseInformation.productName}
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Use Status</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {(this.state.purchaseInformation.usedStatus === 0) ? "Unused" : this.state.purchaseInformation.usedStatus}
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Purchase Date</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {this.state.purchaseInformation.purchaseDate}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>

                                                        }


                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }



                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                            <InputText
                                                value={this.state.instituteID}
                                                onChange={(e) => this.onChangeinstituteID(e)}
                                                placeholder="Enter Institute ID"
                                                showClear={true}
                                                style={{ width: "15%" }}
                                                type="text"
                                                name="instituteID"
                                            />
                                            <Button
                                                icon="fas fa-search"
                                                onClick={(e) => this.searchInstituteID(e)}
                                                className={"p-button-animation " + this.state.addInstituteSearchBtnClass}
                                                label={ this.state.searchLabelInstitute}
                                                onFocus={ this.focusSearchInstitute}
                                                onBlur={ this.blurSearchInstitute}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.instituteIDErrorObj["instituteID"]}</span>
                                    </div>




                                    {this.state.instituteIDSearchView ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-search-view">
                                                {this.state.instituteIDSearchViewError ?
                                                    <center className="error-message">{this.state.instituteIDSearchViewErrorMsgBody}</center> :
                                                    <div>


                                                        {
                                                            this.state.instituteInformation.instituteContact === '' ? '' :
                                                                <div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Institute Name</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                {instituteInformation.instituteName}
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>


                                                                    <div className="p-col-12 p-xl-12">
                                                                        {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Address</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                {instituteInformation.instituteAddress}
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Mobile No</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                {instituteInformation.instituteContact}
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Email Address</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                {instituteInformation.instituteEmail}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>

                                                        }


                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Institute Mapping Title <span>*</span></span>
                                            <InputText
                                                value={this.state.mappedInstituteInfoObj.mappingTitle}
                                                className="custom-form-control"
                                                onChange={this.onChangeMappingTitle}
                                                name="instituteTitle"
                                                placeholder="Enter Mapping Title"
                                            />
                                        </div>
                                        {/* <span className="error-message">{this.state.downloadSubmitError.fileTitle}</span> */}
                                    </div>

                                    <div className="p-col-12 nw-button-parent">

                                        <Button
                                            className="nw-button p-button-primary nw-button-right"
                                            label="Save"
                                            icon="fas fa-check"
                                            onClick={this.onSubmitMappedInstitute}
                                            disabled={this.state.purchaseCodeSearchBtnDisabled == false && this.state.instituteIDSearchBtnDisabled == false ? !this.state.buttonDisabled : this.state.buttonDisabled}
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>
                     }

                    </div>

                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/institute_config"
                                        className="rainbow-button"
                                        alt="Go Admisia Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }
                }


            </div>

        );
    }
}



