import React, {Component} from 'react';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Rating } from 'primereact/rating';
import { Growl } from 'primereact/growl';
import { Link } from 'react-router-dom';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { DownloadButton } from '../../../utils/DownloadButton';
import CommonFuctionality from '../../common/CommonFuctionality';
import { TokenService } from '../../../service/myPoint/TokenService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { MY_POINT } from '../../../utils/PointWiseRouteConsts';

let countValue;

let errors={}
export class TokenCommonBody extends Component{

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            ratingObj:{
                ratingValue: '',
                commentValue:'',  
            },
            ratingValue:'',
            tokenRatingObj: {...props.tokenList},
            errors:{},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            
        };

        this.CommonFuctionality = new CommonFuctionality();
        this.tokenService = new TokenService();
        
    }

    // componentShouldUpdate(){
    //     this.getRemainTime()
    // }

    // componentWillMount(){
    //     this.getRemainTime()
    // }

    onChangeRatingValue = e =>{
        let { ratingObj, errors } = this.state
        errors["ratingMark"] = "";
        ratingObj.ratingValue = e.value
        this.setState({ ratingObj, ratingValue: e.value })
    }

    onChangeCommentValue = e =>{
        let { ratingObj, errors } = this.state
        errors["ratingComment"] = "";
        ratingObj.commentValue = e.target.value
        this.setState({ ratingObj })
    }

    ratingHandleError = () => {
        let { tokenRatingObj, ratingObj, errors } = this.state
        let formIsValid = true;

        if (ratingObj.ratingValue == '') {
            formIsValid = false;
            errors["ratingMark"] = "Rating Mark can't left empty.";
        }

        if (ratingObj.commentValue == '') {
            formIsValid = false;
            errors["ratingComment"] = "Rating Comment can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitRating = () =>{
        let { tokenRatingObj, ratingObj } = this.state

        tokenRatingObj.ratingMark = ratingObj.ratingValue
        tokenRatingObj.ratingMessage = ratingObj.commentValue

        // console.log("tokenRatingObj::::", tokenRatingObj)
        if (this.ratingHandleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.tokenService.saveUserRating(tokenRatingObj)
              .then(res => {
                if (res.status == 202) {
                  this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                  this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted" });
                //   this.showUserTopTenMessageRechargeList();
                setTimeout(() => {
                    this.onHide();
                    this.props.reloadTokenList()
                }, 800);
                } else {
                  this.MessageRechargeService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                      this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                    });
                }
            }).catch(error => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
        }
    }

    showTokenDetails = () =>{
        let { tokenList, ratingObj, errors } = this.state;

        this.setState({ visible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();

        

        // if(tokenList && tokenList.tokenStatus == 0){
        //     progressMainClass1 = progressMainClass1+"active"
        //     progressMainClass2 = progressMainClass2+"active"
        //     // this.getRemainTime(tokenList && tokenList.createDate)
        // }
        // else if(tokenList && tokenList.tokenStatus == 1){
        //     progressMainClass1 = progressMainClass1+"active"
        //     progressMainClass2 = progressMainClass2+"active"
        //     progressMainClass3 = progressMainClass3+"active"
        // }

        // else if(tokenList && tokenList.tokenStatus == 10){
        //     progressMainClass1 = progressMainClass1+"active"
        //     progressMainClass2 = progressMainClass2+"active"
        //     progressMainClass3 = progressMainClass3+"active"
        //     progressMainClass4 = progressMainClass4+"active"
        // }

        // this.CommonFuctionality.blurDialogBackgroundActive();
		// setTimeout(() => {
		// 	let dialogMask = document.getElementsByClassName('p-dialog-mask')[0]; //p-component-overlay p-dialog-mask
		// 	let dialog = document.getElementsByClassName('p-dialog')[0]; //p-component-overlay p-dialog-mask
		// 	let nwTopbar = document.getElementsByClassName('nw-topbar')[0]; //p-component-overlay p-dialog-mask
		// 	console.log("dialogMask", dialogMask);
		// 	console.log("dialogMask", dialog);

		// 	// dialogMask.style.zIndex = "995"
		// 	// dialog.style.zIndex = "996"
		// }, 500);
    }

    onHide = () => {
        this.setState({ visible: false })
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    getRemainTime = (start, end) =>{
        let countValue 
        var countDownDate = new Date( start ).getTime()
        var now
        if(end != null){ 
            now = new Date( end ).getTime()
        }else{
            now = new Date().getTime();
        }
            
                
            var distance = now - countDownDate;
                
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            countValue =days + "d " + hours + "h "+ minutes + "m " + seconds + "s "

        return countValue
    }

    render(){
        let { dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { tokenList, ratingObj, errors } = this.props;

        let progressMainClass1 = "info-box-wrapper progress-view step-1 "
        let progressMainClass2 = "info-box-wrapper progress-view step-2 "
        let progressMainClass3 = "info-box-wrapper progress-view step-3 "
        let progressMainClass4 = "info-box-wrapper progress-view step-4 "
        let progressMainClass5 = "info-box-wrapper progress-view step-5 ";

        if(tokenList && tokenList.tokenStatus == 0){
            progressMainClass1 = progressMainClass1+"active"
            progressMainClass2 = progressMainClass2+"active"
            // this.getRemainTime(tokenList && tokenList.createDate)
        }
        else if(tokenList && tokenList.tokenStatus == 1){
            progressMainClass1 = progressMainClass1+"active"
            progressMainClass2 = progressMainClass2+"active"
            progressMainClass3 = progressMainClass3+"active"
        }

        else if(tokenList && tokenList.tokenStatus == 10){
            progressMainClass1 = progressMainClass1+"active"
            progressMainClass2 = progressMainClass2+"active"
            progressMainClass3 = progressMainClass3+"active"
            progressMainClass4 = progressMainClass4+"active"
        }
        

        
        return (
            <div className="p-inputgroup">
                {topProgressBar ?
                    <ErrorMessageView
                    topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                    errorMsgVisible={errorMsgVisible}
                    errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <span className="p-inputgroup-addon info blur-section">
                    <div> Token Id: { tokenList && tokenList.customTokenID}</div>
                    <div> Issue Date: { tokenList && NetiDateUtils.getAnyShortForm(tokenList.createDate, 'DD-MMM-YYYY hh:mm:ss a') }</div>
                    { tokenList && tokenList.tokenStatus == 10? 
                        <div> Solve Date: { tokenList && NetiDateUtils.getAnyShortForm(tokenList.solveDate, 'DD-MMM-YYYY hh:mm:ss a') }</div>
                        :''
                    }
                </span>

                <Link to={{ pathname: MY_POINT.NEW_TOKEN, tokenList: tokenList }}>
                    <i className="fas fa-angle-right"/><i className="fas fa-angle-right"/>
                </Link>
                <Button
                    label="View"
                    className={ tokenList && tokenList.tokenStatus == 10? "bg-success blur-section" : tokenList && tokenList.tokenStatus == 0 ? "bg-warning blur-section" : ''}
                    onClick={this.showTokenDetails}
                />

                <Dialog 
                    header="Token Information" 
                    className="nw-dialog"
                    onHide={this.onHide} 
                    visible={this.state.visible}
                    dismissableMask={true}
                >

                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                        <div className="p-col-12 p-xl-12">

                                            <div className="tracking-progress-view">
                                                <div className={ progressMainClass1 }>
                                                    <div className="info-icon icon-bg-1">
                                                        <i class="fas fa-sign-in-alt"></i>
                                                    </div>
                                                    <div className="info-text">
                                                        <p>Submit</p>
                                                    </div>
                                                    <div className="info-button info-btn-1">
                                                        <i class="fas fa-info-circle"></i>
                                                    </div>
                                                </div>

                                                <div className="next"><div></div></div>

                                                <div className={ progressMainClass2 }>
                                                    <div className="info-icon icon-bg-1">
                                                        <i class="fas fa-spinner"></i>
                                                    </div>
                                                    <div className="info-text">
                                                        <p>Pending</p>
                                                    </div>
                                                    <div className="info-button info-btn-1">
                                                        <i class="fas fa-info-circle"></i>
                                                    </div>
                                                </div>

                                                <div className="next"><div></div></div>

                                                <div className={ progressMainClass3 }>
                                                    <div className="info-icon icon-bg-1">
                                                        <i class="fas fa-cogs"></i>
                                                    </div>
                                                    <div className="info-text">
                                                        <p>Process</p>
                                                    </div>
                                                    <div className="info-button info-btn-1">
                                                        <i class="fas fa-info-circle"></i>
                                                    </div>
                                                </div>

                                                <div className="next"><div></div></div>

                                                <div className={ progressMainClass4 }>
                                                    <div className="info-icon icon-bg-1">
                                                        <i class="fas fa-clipboard-check"></i>
                                                    </div>
                                                    <div className="info-text">
                                                        <p>Solved</p>
                                                    </div>
                                                    <div className="info-button info-btn-1">
                                                        <i class="fas fa-info-circle"></i>
                                                    </div>
                                                </div>

                                                <div className="next"><div></div></div>

                                                <div className={ progressMainClass5 }>
                                                    <div className="info-icon icon-bg-1">
                                                        <i class="fas fa-star"></i>
                                                    </div>
                                                    <div className="info-text">
                                                        <p>Rating</p>
                                                    </div>
                                                    <div className="info-button info-btn-1">
                                                        <i class="fas fa-info-circle"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12"></div>
                                        <div className="p-col-12 p-xl-12"></div>
                                        <div className="p-col-12 p-xl-12"></div>

                                        { tokenList && tokenList.tokenStatus == 10?

                                            <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Give Rating  <span>*</span></span>
                                                        <div className="nw-inputgroup-desc">
                                                            <Rating 
                                                                value={this.state.ratingValue} 
                                                                cancel={false} 
                                                                onChange={this.onChangeRatingValue}
                                                                />
                                                        </div>
                                                        
                                                    </div>
                                                    <span className="error-message">{this.state.errors["ratingMark"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputtextarea-label">Comments <span>*</span></span>
                                                        <InputTextarea
                                                            id="textarea"
                                                            rows={3}
                                                            cols={30}
                                                            autoResize={true}
                                                            name="tokenDetails"
                                                            value={ratingObj && ratingObj.commentValue}
                                                            onChange={this.onChangeCommentValue}
                                                        />
                                                    </div>
                                                    <span className="error-message">{this.state.errors["ratingComment"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                                    <div className="required-field">
                                                        (<span>*</span>) required fields
                                                    </div>

                                                    <Button
                                                        className="p-button-primary nw-button nw-button-right token-submit-button" //p-button-primary 
                                                        label="Submit"
                                                        icon="fas fa-check" //fas fa-spinner fa-spin
                                                        onClick={this.onSubmitRating}
                                                        disabled={ tokenList && tokenList.tokenStatus == 10? false:true }
                                                    />
                                                </div>
                                            </div>
                                            :null
                                        }
                                        

                                        <div className="p-col-12 p-xl-12">

                                            <div className="nw-search-view">
                                                <div>
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Token ID</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.customTokenID }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Issue Date</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && NetiDateUtils.getAnyShortForm(tokenList.createDate, 'DD-MMM-YYYY hh:mm:ss a') }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Contact No.</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.tokenContact }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Application</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.tokenSource }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Module</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Problem Type</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.tokenTypeInfoDTO.categoryName }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Problem Details</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc">
                                                                { tokenList && tokenList.tokenDetails }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup details">
                                                            <span className="task-badge found"></span>
                                                            <span className="p-inputgroup-addon">Attachment</span>
                                                            <span className="p-inputgroup-colon">:</span>
                                                            <div className="nw-inputgroup-desc attachment-download-button" style={{ display: 'flex', alignItems: 'center'}}>
                                                                <DownloadButton fileName={ tokenList && tokenList.attachmentName } filePath={ tokenList && tokenList.attachmentPath } /> <span className="download-button-text">Attachment</span> 
                                                            </div>
                                                        </div>
                                                    </div>

                                                    { tokenList && tokenList.tokenStatus <= 1?

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Pending Duration</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc demo" id='demo'>
                                                                { this.getRemainTime(tokenList && tokenList.createDate, null) }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :null
                                                    }


                                                    { tokenList && tokenList.tokenStatus == 10?
                                                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                            <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Solved Date</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc demo" id='demo'>
                                                                { tokenList && NetiDateUtils.getAnyShortForm(tokenList.solveDate, 'DD-MMM-YYYY hh:mm:ss a') }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Solved Duration</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc demo" id='demo'>
                                                                { this.getRemainTime(tokenList && tokenList.createDate, tokenList && tokenList.solveDate ) }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Solved Note</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc demo" id='demo'>
                                                                { tokenList && tokenList.solveMessage }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :null
                                                    }
                                                        


                                                </div>
                                            </div>
                                        </div>

                                        {/* { tokenList && tokenList.tokenStatus == 10?

                                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                                <div className="required-field">
                                                    (<span>*</span>) required fields
                                                </div>

                                                <Button
                                                    className="p-button-primary nw-button nw-button-right token-submit-button" //p-button-primary 
                                                    label="Submit"
                                                    icon="fas fa-check" //fas fa-spinner fa-spin
                                                    onClick={this.onSubmitRating}
                                                    disabled={ tokenList && tokenList.tokenStatus == 10? false:true }
                                                />
                                            </div>
                                            :null
                                        } */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </Dialog>
            </div>
        );
    }

}