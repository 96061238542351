import React, { Component } from 'react';
import {ApplicationConfigClassWise} from "./ApplicationConfigClassWise"
import {ApplicationConfigConfigurationList} from "./ApplicationConfigConfigurationList"
import {TabView,TabPanel} from 'primereact/tabview';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import { Link } from 'react-router-dom';

let urlId;
export class ApplicationConfigHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }

        this.admisiaPointService=new AdmisiaPointService();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal = () => {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        console.log("application config urlId",urlId);
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 1000);
        }
    }

    componentWillMount() {
        urlId=this.admisiaPointService.getUrlInfoFromLocalStorage();
        if( urlId ){
            this.setState({ homeReturnButton: false})
        }
        else{
            this.setState({ homeReturnButton: true})
        }
    }

   
    render() {

        
        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    {this.state.homeReturnButton === false?
                        <div className="p-col-12 p-lg-12 p-xl-12 nw-tabview">
                            <TabView>
                                <TabPanel header="Class Wise Config">
                                    <ApplicationConfigClassWise/>
                                </TabPanel>
                                <TabPanel header="Configuartion List" >
                                    <ApplicationConfigConfigurationList/>
                                </TabPanel>
                            </TabView>
                        </div>
                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link 
                                            to="/institute_config"
                                            className="rainbow-button"
                                            alt="Go Admisia Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}