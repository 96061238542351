
import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { Growl } from 'primereact/growl';
import { PurchaseService } from '../../../service/myPoint/PurchaseService'
import { CRMPointService } from '../../../service/crmPoint/CRMPointService'
import { NetiDateUtils } from '../../../utils/NetiDateUtils'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { ColumnGroup } from 'primereact/columngroup';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';
import {Card} from 'primereact/card';
import { ProducListInfoDialog } from '../../adminPoint/productConfig/ProducListInfoDialog';
import {Dialog} from 'primereact/dialog';

let errors = {}
let totalAmount = 0;

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class ProductPurchaseInfoByInvoiceID extends Component {
    constructor() {
        super();
        this.state = {
            invoiceID: '',
            productOwnerRevenueInfo: {},
            revenueAffectedLogs: [],
            viewAffectedLogs: false,
            viewDialogShow: false,
            productPurchaseInfo: {},
            productOfferObj: {
                actualPrice: 0,
                discountPercent: 0
            },
            purchesPoint: null,
            purchaseCodeListTable: [],
            productType: null,
            productName: null,
            productOfferID: null,
            offerCode: '',
            actualPrice: 0,
            discountPercent: null,
            offerStartDate: null,
            offerQuantity: null,
            offerUseableTime: null,
            offerNote: null,
            offerUsed: null,
            dialogPurchesPoint: null,
            dialogProductType: null,
            dialogProductName: null,
            dialogProductDate: null,
            dialogProductQuantity: null,
            dialogOfferCode: null,

            dataTableSelection: null,
            dataTableValue: [],
            errors: null,
            topProgressBar: false,
            dataTableIsLoading: false,
            dialogDataTableIsLoading: false,
            errorInfo: false,
            dataIsLoading: false,
            searchView: true,
            searchViewError: false,
            buttonDisabled: true,

            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]

        }

        this.purchaseService = new PurchaseService();
        this.CRMPointService = new CRMPointService();

        this.purchaseCodeBody = this.purchaseCodeBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);
        this.searchOfferConfigByOfferCode = this.searchOfferConfigByOfferCode.bind(this);
        this.getOfferProductTopTen = this.getOfferProductTopTen.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    componentDidMount() {
        this.getOfferProductTopTen();
    }

    submitButtonCheck() {
        if (this.state.offerCode) {
            this.setState({ buttonDisabled: false })
        }
        else {
            this.setState({ buttonDisabled: true })
        }
    }

    getOfferProductTopTen() {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.purchaseService.fetchTopTenOfferProduct()
            .then((res) => {

                if (res.status === 302) {
                    return res.json()
                        .then((body) => {
                            this.setState({ dataTableValue: body, dataTableIsLoading: false, topProgressBar: false });
                        })
                }
                else {
                    this.purchaseService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }

            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
    }

    viewDialog(rowData) {
        this.setState({ purchaseCodeListTable: [] });
        let formatedOfferUseDate = NetiDateUtils.getAnyShortForm(rowData.offerUseDate, 'DD-MMM-YYYY');

        this.setState({ dialogpurchasePoint: rowData.purchasePoint })
        this.setState({ dialogProductType: rowData.productType })
        this.setState({ dialogProductName: rowData.productName })
        this.setState({ dialogProductQuantity: rowData.productQuantity })
        this.setState({ dialogOfferCode: rowData.offerCode })
        this.setState({ dialogProductDate: formatedOfferUseDate })
        this.setState({ purchaseProductId: rowData.purchaseProductId })
        this.setState({ visible: true });

        this.setState({ topProgressBar: true, dialogDataTableIsLoading: true, errorMsgVisible: false })
        this.purchaseService.fetchProductPurchaseCodeDetailsByPurchaseId(rowData.productPurchaseLogID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ purchaseCodeListTable: body });
                        this.setState({ topProgressBar: false, dialogDataTableIsLoading: false, errorMsgVisible: false })
                    });
                }
                else {
                    this.purchaseService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dialogDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dialogDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
    }

    usedDateBody = (rowData) => {
        return (
            <span>
                {rowData.usedDate != null ? NetiDateUtils.getAnyShortForm(rowData.usedDate, 'DD-MMM-YYYY') : ''}

            </span>
        );
    }

    statusCheck = (rowData) => {
        return (
            <span>
                {rowData.usedStatus == 0 ? "Unused" : ''}
                {rowData.usedStatus == 1 ? "Used" : ''}
            </span>
        )
    }

    onHide(event) {
        this.setState({ visible: false });
    }

    purchaseCodeBody(rowData, column) {

        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title="View Purchase"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    searchOfferConfigByOfferCode() {

        this.setState({ productOfferObj: {} });

        if (this.state.invoiceID !== '') {
            errors['codeSearchError'] = '';
            this.setState({ topProgressBar: true, dataIsLoading: true, errorMsgVisible: false, searchView: true, searchViewError: false });
            this.CRMPointService.findPurchaseAtAGlanceByInvoiceId(this.state.invoiceID)
                .then((res) => {
                    if (res.status === 302) {
                        // errors['codeSearchError'] = ''
                        return res.json()
                            .then((body) => {
                                totalAmount = body.revenueSummary.map(item => item.distributedAmount).reduce((a, b) => a + b);
                                console.log('productPurchaseInfo', body);
                                this.setState({ productPurchaseInfo: body });

                                this.setState({ topProgressBar: false, dataIsLoading: false,  searchView: true, errorMsgVisible: false, searchViewError: false });

                                // for pdf
                                // body.map(item => {
                                //     Object.assign(item.productPurchaseInfo.revenueLog, { customNetiID: item.distributedUserBasicInfoDTO.customNetiID });   // for pdf column
                                //     Object.assign(item.productPurchaseInfo.revenueLog, { fullName: item.distributedUserBasicInfoDTO.fullName });   // for pdf column
                                // })

                                this.submitButtonCheck()

                            });
                    }
                    else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                let { productOfferObj } = this.state

                                productOfferObj.discountPercent = 0;
                                errors['codeSearchError'] = resp;

                                this.setState({ productOfferObj, dataIsLoading: false, topProgressBar: false,errorMsgVisible: false,searchView: true, searchViewError: true })

                                this.submitButtonCheck()
                            });
                    }
                }).catch(error => {
                    this.setState({ searchViewError: false, searchView: false, dataIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' });
                });

        }
        else {
            errors['codeSearchError'] = 'Please input invoice ID';
            this.state.buttonDisabled = true;
            this.state.searchViewError = false;

        }
        this.setState({ errors: errors['codeSearchError'] })
    }

    prettyBytes(e) {
        return parseFloat(e).toFixed(0)
    }

    totalAmountTemplate = (rowData) => {

        let totalValue = (parseInt(rowData.totalPrice) + parseInt(rowData.totalDiscount))
        return this.ValidatorUtility.currencyFormatter(totalValue)
    }

    productBaseAmount = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.baseAmount)
    }

    productDistributedAmount = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.distributedAmount)
    }


    discountTemplate = (rowData) => {
        return `${this.ValidatorUtility.currencyFormatter(rowData.totalDiscount)} (${rowData.discountPercent}%)`
    }

    paidAmountTemplate = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.payableAmount)
    }

    offerUseDateFormat = (rowData) => {
        return <center><div>{rowData.offerUseDate} <br /> {rowData.offerUseDateTime}</div></center>

    }

    distributedType = (rowData) => {
        return (<div className="year-month">
            <span>{rowData['distributedType']}</span>
        </div>)
    }

    distributedAmount = (rowData) => {
        return (
            <div>
                <div className="table-middle-col">
                    <span className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData['distributedAmount'])}</span>
                </div>
            </div>
        )
    }

    dueAmount = (rowData) => {
        return (<div className="total-due">
            <span className="due">Due</span> <br /> <div><span className="symbol-taka">৳</span> {this.ValidatorUtility.currencyFormatter(rowData['dueAmount'])}</div>
        </div>)
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Type", dataKey: "distributedType" },
            { title: "Base Amount", dataKey: "baseAmount" },
            { title: "Grade Name", dataKey: "gradeName" },
            { title: "Percentage (%)", dataKey: "gradePercent" },
            { title: "Distributed Amount", dataKey: "distributedAmount" },
            { title: "Neti ID", dataKey: "customNetiID" },
            { title: "Name", dataKey: "fullName" },
        ]

        let tableColStyle = { baseAmount: { halign: 'right' }, distributedAmount: { halign: 'right' }  }

        pdfDownloadPortrait_TableWithSubTitle("Revenue Log Report", 75, subTitleColumn, subTitleList, pdfColumns, this.state.productPurchaseInfo.revenueLog, tableColStyle, "Revenue_Log_Report.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    viewProductConfigurationDetails = () => {
        this.setState({ viewDialogShow: true});
        this.getProductOwnerRevenueInfo();

    }

    onHideViewDialog = () => {
        this.setState({ viewDialogShow: false });
    }


    getProductOwnerRevenueInfo = () => {
        let {productPurchaseInfo} = this.state;
        this.setState({ topProgressBar: true,errorMsgVisible: false })
        let productID = productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchase && productPurchaseInfo.purchaseInfo.purchase.productInfoDTO && productPurchaseInfo.purchaseInfo.purchase.productInfoDTO.productID
        this.CRMPointService.getProductConfigurationByproductID(productID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productOwnerRevenueInfo: body, topProgressBar: false });
                    })
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    detailsBody = (rowData) => {
        console.log('rowData',rowData);
        let disabledbtn = ''
        if (rowData.affectedLogExist == true) {
            disabledbtn = false;
        } else {
            disabledbtn = true;
        }
        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-download"
                disabled={disabledbtn}
                onClick={(e) => this.viewBalanceRevenueAffectedLogs(rowData)}
            />
        </div>
    }


    viewBalanceRevenueAffectedLogs = (rowData) => {
        this.setState({viewAffectedLogs: true});
        this.setState({ topProgressBar: true, dataTableIsLoading: true,  errorMsgVisible: false });

    this.CRMPointService.findUserBalanceRevenueAffectedLogs(rowData.blnRevLogID)
    .then((res) => {
        if (res.status === 302) {
            return res.json()
                .then((body) => {
                    // totalAmount = body.revenueSummary.map(item => item.distributedAmount).reduce((a, b) => a + b);
                    console.log('revenueAffectedLogs', body);
                    this.setState({ revenueAffectedLogs: body });
                    this.setState({ topProgressBar: false, dataTableIsLoading: false,  errorMsgVisible: false });
                });
        }
        else {
            this.CRMPointService.Auth.handleErrorStatus(res)
                .then((resp) => {
                    this.setState({   topProgressBar: false,errorMsgVisible: true,dataTableIsLoading: false,errorMsgBody:resp })

                });
        }
    }).catch(error => {
        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' });
    });
}

onHideAffectedLogs = () => {
    this.setState({viewAffectedLogs: false});
}

affectedAmountBody = (rowData) => {
    return (
        <div>
            <div className="table-middle-col">
                <span className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData['affectedAmount'])}</span>
            </div>
        </div>
    )

}

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, productPurchaseInfo } = this.state;

        // pdf reletaed task
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
        ];
        subTitleList = [
            { A: "INVOICE ID", B: this.state.invoiceID, }
        ];
        // pdf reletaed task end

        // excel
        let excelTableData = productPurchaseInfo.revenueLog;
        let excelColumns = "";
        let excelFileName = this.state.invoiceID + "_InvoiceID_Revenue_Log_Report.xlsx";
        let excelSheetName = "Revenue_Log";
        excelColumns = [
            <Workbook.Column value="distributedType" label="Type" />,
            <Workbook.Column value="baseAmount" label="Base Amount" />,
            <Workbook.Column value="gradeName" label="Grade Name" />,
            <Workbook.Column value="gradePercent" label="Percentage (%)" />,
            <Workbook.Column value="distributedAmount" label="Distributed Amount" />,
            <Workbook.Column value="customNetiID" label="Neti ID" />,
            <Workbook.Column value="fullName" label="Name" />,
        ];

        // end excel

        let tableHeader = <div className="header-title">
            <div className="header-title-left"> Revenue Log Report</div><div className="header-title-right"><a>Total Found: {productPurchaseInfo && productPurchaseInfo.revenueLog && productPurchaseInfo.revenueLog.length.toLocaleString('EN-IN')}</a></div>
        </div>;



        let revenueAffectedLogHeader = <div className="header-title">
            <div className="header-title-left"> Revenue Affected Log Report</div><div className="header-title-right"><a>Total Found: {this.state.revenueAffectedLogs && this.state.revenueAffectedLogs.length.toLocaleString('EN-IN')}</a></div>
        </div>;


        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="Total" />
                <Column footer={totalAmount ? this.ValidatorUtility.currencyFormatter(totalAmount) : 0} />
            </Row>
        </ColumnGroup>;
        return (
            <div className="p-fluid">
                {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">
                    <div className="p-grid nw-form">

                        <div className="p-col-12 p-xl-8 seprator">
                            <div className="p-grid seprator-inside">
                                <div className="p-col-12 p-xl-12 p-col-nogutter">
                                    <div className="p-grid nw-form-body">
                                        {/* <div className=" p-grid p-col-12 p-xl-12"> */}

                                        <div className="p-col-12 p-lg-12 p-xl-12">

                                            <div className="p-col-12 p-lg-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">Invoice ID <span>*</span> </span>
                                                    <InputText
                                                        name="productName"
                                                        value={this.state.invoiceID}
                                                        onChange={e => this.setState({ invoiceID: e.target.value })}
                                                        autowidth={'false'}
                                                        placeholder="enter invoice id"
                                                    />
                                                    <Button
                                                        className=""
                                                        label=""
                                                        icon="fas fa-search"
                                                        onClick={this.searchOfferConfigByOfferCode}
                                                    />
                                                </div>
                                                <span className='error-message'>{this.state.errors}</span>
                                            </div>

                                            {this.state.searchView ?
                                                <div className="p-col-12 p-lg-12 p-xl-12">
                                                    <div className="p-col-12 p-lg-12 p-xl-12">
                                                        <div class="nw-search-view">
                                                            {this.state.searchViewError ?
                                                                <center className="error-message" style={{ paddingTop: "85px", fontSize: "20px" }}>{errors['codeSearchError'] || "No Data Found"}</center> :
                                                                <div>
                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Purchase Date & Time</span>
                                                                                <span className="p-inputgroup-colon">:</span>

                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchase && productPurchaseInfo.purchaseInfo.purchase.purchaseDate ?   NetiDateUtils.getAnyShortForm(productPurchaseInfo.purchaseInfo.purchase.purchaseDate, 'DD-MMM-YYYY hh:mm:ss a') : '-'}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon"> Purchase By Neti ID </span>
                                                                                <span className="p-inputgroup-colon">:</span>

                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchaseBy && productPurchaseInfo.purchaseInfo.purchaseBy.netiID || "-"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon"> Purchase By Neti Name </span>
                                                                                <span className="p-inputgroup-colon">:</span>

                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchaseBy && productPurchaseInfo.purchaseInfo.purchaseBy.netiName || "-"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon"> Product Name </span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchase && productPurchaseInfo.purchaseInfo.purchase.productInfoDTO && productPurchaseInfo.purchaseInfo.purchase.productInfoDTO.productName  || "-"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon"> Purchase Point </span>
                                                                                <span className="p-inputgroup-colon">:</span>

                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchasePoint || "-"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon"> Quantity </span>
                                                                                <span className="p-inputgroup-colon">:</span>

                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchase && productPurchaseInfo.purchaseInfo.purchase.purchaseQuantity || "-"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon"> Unit Price </span>
                                                                                <span className="p-inputgroup-colon">:</span>

                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchase && productPurchaseInfo.purchaseInfo.purchase.unitPrice || "-"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Total Price</span>
                                                                                <span className="p-inputgroup-colon">:</span>

                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchase && productPurchaseInfo.purchaseInfo.purchase.totalAmount ?  this.ValidatorUtility.currencyFormatter(productPurchaseInfo.purchaseInfo.purchase.totalAmount) : "-"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Discount</span>
                                                                                <span className="p-inputgroup-colon">:</span>

                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchase && productPurchaseInfo.purchaseInfo.purchase.discountAmount ?  this.ValidatorUtility.currencyFormatter(productPurchaseInfo.purchaseInfo.purchase.discountAmount) : "-"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon"> Discount Code</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.offerInfo && productPurchaseInfo.purchaseInfo.offerInfo.offer_code || "-"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon"> VAT</span>
                                                                                <span className="p-inputgroup-colon">:</span>

                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchase && productPurchaseInfo.purchaseInfo.purchase.vatAmount ? this.ValidatorUtility.currencyFormatter(productPurchaseInfo.purchaseInfo.purchase.vatAmount) : '-'}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {this.state.dataIsLoading ?
                                                                            this.NetiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon"> Paid Amount</span>
                                                                                <span className="p-inputgroup-colon">:</span>

                                                                                <div className="nw-inputgroup-desc">
                                                                                    {productPurchaseInfo && productPurchaseInfo.purchaseInfo && productPurchaseInfo.purchaseInfo.purchase && productPurchaseInfo.purchaseInfo.purchase.paidAmount ? this.ValidatorUtility.currencyFormatter(productPurchaseInfo.purchaseInfo.purchase.paidAmount) : "-"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            }
                                                        </div>

                                                    </div>

                                                </div>
                                                : ""
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-4 p-col-nogutter nw-datatable-detail-view due-bill-info-table">
                            <DataTable
                                value={this.state.productPurchaseInfo.revenueSummary}
                                header="Revenue Summary"
                                footerColumnGroup={footerGroup}
                                responsive={true}
                            >
                                <Column field="distributedType" body={this.distributedType} header="Type" style={{ width: "95px" }} />
                                <Column field="distributedAmount" body={this.distributedAmount} header="Amount" />
                            </DataTable>
                            <div className="p-col-12 p-xl-12 p-col-nogutter">
                                  <div className="p-col-12">
                                    <Card className="panel-box-instruction">
                                        <center>
                                            <div className="p-col-12">
                                                <h1 style={{fontWeight: 200}}>Product Configuration</h1>
                                            </div>
                                            <div className="p-col-12">
                                                {/* <Link to="#"> */}
                                                <span style={{ color: "#0078ff", cursor: "pointer", textDecoration: "underline" }} onClick={this.viewProductConfigurationDetails}>View more <i class="fas fa-angle-right"></i><i class="fas fa-angle-right"></i></span>
                                                {/* </Link> */}
                                            </div>
                                        </center>
                                    </Card>
                                </div>
                                </div>
                        </div>

                        <Dialog
                    header="Revenue Affected Details Report"
                    className="nw-dialog"
                    visible={this.state.viewAffectedLogs}
                    // style={{ width: '55vw' }} 
                    onHide={this.onHideAffectedLogs}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="nw-data-table">
                                {
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={this.state.revenueAffectedLogs}
                                            header={revenueAffectedLogHeader}
                                            responsive={true}
                                            rows={10}
                                            paginator={true}
                                            columnResizeMode="fit"
                                        >
                                            <Column field="distributedType" header="Distribution Type" filter={true} />
                                            <Column field="affectedAmount" header="Amount" body={this.affectedAmountBody} filter={true} />
                                            <Column field="userBasicInfoDTO.customNetiID" header="Affected Neti ID" filter={true} />
                                            <Column field="userBasicInfoDTO.fullName" header="Name" filter={true} />
                                        </DataTable>
                                }
                            </div>

                        </div>
                    </div>
                </Dialog>
                      

                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table">
                                {!this.state.dataTableIsLoading ?
                                    <div>
                                        <DataTable
                                            header={tableHeader}
                                            value={productPurchaseInfo.revenueLog}
                                            selectionMode="single"
                                            selection={this.state.dataTableSelection}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            // paginator={true} 
                                            // rows={10}
                                        >
                                            <Column field="distributedType" header="Type" sortable={true} filter={true} />
                                            <Column field="baseAmount" header="Base Amount" sortable={true} body={this.productBaseAmount} filter={true} className="col-number" />
                                            <Column field="gradeName" header="Grade Name" sortable={true} filter={true} />
                                            <Column field="gradePercent" header="Percentage (%)" sortable={true} filter={true} />
                                            <Column field="distributedAmount" header="Distributed Amount" sortable={true} body={this.productDistributedAmount} filter={true} className="col-number" />
                                            <Column field="distributedUserBasicInfoDTO.customNetiID" header="Neti ID" sortable={true} filter={true} />
                                            <Column field="distributedUserBasicInfoDTO.fullName" header="Name" sortable={true} filter={true} />
                                            <Column field="" header="Action" body={this.detailsBody} />

                                        </DataTable>
                                        {/* 
                                        <div className="nw-button-parent m-t-8 p-r-0">
                                            <Workbook
                                                filename={excelFileName}
                                                element={
                                                    <Button
                                                        label="Download Excel"
                                                        id="clickExcelBtn"
                                                        icon="fas fa-download"
                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                    />
                                                }
                                            >
                                                <Workbook.Sheet data={excelTableData} name={excelSheetName}>
                                                    {excelColumns}
                                                </Workbook.Sheet>
                                            </Workbook>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Download PDF"
                                                icon="fas fa-download"
                                                onClick={this.exportPdf}
                                            />
                                        </div> */}

                                    </div>

                                    : this.NetiContentLoader.MyPointTableLoader()
                                }
                            </div>

                        </div>

                        <div className="p-col-12 p-xl-12 nw-button-parent">

                            <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                            <div style={{ display: 'none' }}>

                                <Workbook
                                    filename={excelFileName}
                                    element={
                                        <Button
                                            label="Download Excel"
                                            id="clickExcelBtn"
                                            icon="fas fa-download"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                        />
                                    }
                                >
                                    <Workbook.Sheet data={excelTableData} name={excelSheetName}>
                                        {excelColumns}
                                    </Workbook.Sheet>
                                </Workbook>

                            </div>

                        </div >

                    </div>
                </div>
                <div className="dialog-section">
          
          <ProducListInfoDialog
          viewDialogShow={this.state.viewDialogShow}
          onHideViewDialog={this.onHideViewDialog}
          productOwnerRevenueInfo={this.state.productOwnerRevenueInfo}
          />
 </div>
            </div>
        );
    }
}

