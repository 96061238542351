import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import Formatter from '../../../../utils/Formatter';
import { TreeTable } from 'primereact/treetable';
import { PartnerPointService } from '../../../../service/partnerPoint/PartnerPointService';
import { PartnerBEPDueStatement } from './PartnerBEPDueStatement';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InstituteDueStatement } from '../../../managementPoint/edumanBillReport/dueStatement/InstituteDueStatement';
import { Workbook } from 'react-excel-workbook';

let partnerListArr = [];
let sumOfInstitute = 0;
let totalActiveInstitute = 0;
let totalInactiveInstitute = 0;
let totalActiveRate = 0;
let totalInactiveRate = 0;
let totalActiveDue = 0;
let totalInactiveDue = 0;

let mbpListObj = {};
let zbpListObj = {};
let bdpListObj = {};
let bepListObj = {};
let assignedPartnerListArr = []
let instituteData = {};
let totalInActiveBill = 0;
let activeBillTotal = 0;
let sumOfDueBill = 0;
let partnerTypeHeader = "Partner Type";
let partnerDetailsHeader = "Name / Neti ID";
let partnerTotalInstituteHeader = "Total Institute";
export class PartnerPointDueStatementTree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mbpListObj: {},
            zbpListObj: {},
            bdpListObj: {},
            bepListObj: {},
            searchObj: {},
            viewAtaGlance: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,
            pdfTableData: [],
        }

        this.ManagementPointService = new ManagementPointService;
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
        this.Formatter = new Formatter();
        this.CommonFuctionality = new CommonFuctionality();
        this.PartnerPointService = new PartnerPointService();
    }

    componentDidMount() {
        assignedPartnerListArr = []
        this.fetchPartnerInfo();
    }

    fetchPartnerInfo = () => {
        partnerListArr = [];
        mbpListObj = {}
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.PartnerPointService.fetchPartnerUserList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        body.instituteReportDTOs.map((item, i) => {
                            mbpListObj.data = item;
                            mbpListObj.key = i;
                            mbpListObj.children = [];
                            mbpListObj.leaf = false;
                            partnerListArr.push(Object.assign({}, mbpListObj));
                        });
                        //column name check
                        for (let index = 0; index < partnerListArr.length; index++) {
                            console.log('partnerListArr[index].data.instituteId', partnerListArr[index]);
                            
                            if (partnerListArr[index].data.instituteId) {
                                partnerTypeHeader = "Institute ID";
                                partnerDetailsHeader = "Institute Name";
                                partnerTotalInstituteHeader = "Institute Status";
                            }
                            
                        }
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });

                    });
                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection!!!' })

            );
    }

    searchPartnerDueDetails(lazyNode, objectName, isLeaf, defaultCode) {
        console.log("LAZY", lazyNode);
        this.setState({ topProgressBar: true, errorMsgVisible: false, tableView: true, });
        this.PartnerPointService.fetchPartnerInfoList(defaultCode, lazyNode.data.partnerAssignID)
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        console.log('body', body);

                        if (lazyNode && lazyNode.children.length === 0) {
                            body.instituteReportDTOs.map((item, i) => {
                                objectName.data = item;
                                objectName.children = [];
                                objectName.leaf = isLeaf
                                objectName.key = `${lazyNode['key']}-${i}`
                                lazyNode.children.push(Object.assign({}, objectName))
                            })
                        }
                        this.setState({ dataTableIsLoading: false, tableView: true, errorMsgVisible: false, topProgressBar: false });
                    })
                } else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection.' });
            });

        setTimeout(() => {
            this.CommonFuctionality.setCustomizedTreeDatatable('pType', assignedPartnerListArr && assignedPartnerListArr[0] && assignedPartnerListArr[0].data && assignedPartnerListArr[0].data.designation);
        }, 500);
    }

    onExpand = (event) => {
        let lazyNode = { ...event.node };

        if (lazyNode.data.partnerType === "MBP") {
            this.searchPartnerDueDetails(lazyNode, mbpListObj, false, 'T1120203')
        }
        else if (lazyNode.data.partnerType === "ZBP") {
            this.searchPartnerDueDetails(lazyNode, zbpListObj, false, 'T1120204')
        }
        else if (lazyNode.data.partnerType === "BDP") {
            this.searchPartnerDueDetails(lazyNode, bdpListObj, false, 'T1120205')
        }
        else if (lazyNode.data.partnerType === "BEP") {
            this.fetchInstituteFoundInfo(lazyNode)
        }
    }

    fetchInstituteFoundInfo = (lazyNode) => {
        totalActiveInstitute = 0;
        totalInactiveInstitute = 0;
        sumOfDueBill = 0;
        activeBillTotal = 0;
        totalInActiveBill = 0;

        this.setState({ topProgressBar: true, errorMsgVisible: false, tableView: true, });
        this.PartnerPointService.fetchInstituteFoundInfoList(lazyNode.data.partnerAssignID)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("Institute", body);

                        if (lazyNode && lazyNode.children.length === 0) {
                            body.detailsInfoDTOs.map((item, i) => {
                                instituteData.data = item;
                                instituteData.children = [];
                                instituteData.leaf = true
                                instituteData.key = `${lazyNode['key']}-${i}`
                                lazyNode.children.push(Object.assign({}, instituteData))
                            })
                        }

                        this.setState({ dataTableIsLoading: false, tableView: true, errorMsgVisible: false, topProgressBar: false });

                    });
                } else {
                    this.PartnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection!!!' })

            );
    }

    mbpActiveInstituteBody = (rowData) => {
        return <span >
            {rowData.data.instituteId ?
                <div className='text-center'>
                    <Button
                        className="nw-action-button"
                        icon="fas fa-eye"
                        onClick={e => this.onShowDialog(rowData)}
                    >
                    </Button>
                </div>
                :
                <span>
                    <span className="text-success">{rowData.data.activeInstitute} {"[" + this.Formatter.getFixedDecimalNumber(rowData.data.activeInstitutePercentage) + "%" + "]"} {"[" + this.validatorUtility.currencyFormatter(rowData.data["activeRate"]) + "]"}</span> <br />
                    <span className="text-danger">{rowData.data.inActiveInstitute} {"[" + this.Formatter.getFixedDecimalNumber(rowData.data.inActiveInstitutePercentage) + "%" + "]"} {"[" + this.validatorUtility.currencyFormatter(rowData.data["inActiveRate"]) + "]"}</span>
                </span>
            }
        </span>;
    }

    onShowDialog = rowData => {
        this.setState({ visible: true, searchObj: { ...rowData } })
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    mbpDueActiveInstituteBody = (rowData) => {

        return <div className="text-success col-number text-right" >
            <span className="text-success">{this.validatorUtility.currencyFormatter(rowData.data["activePayble"])}</span><br />
            <span className="text-danger">{rowData.data && rowData.data.dueAmount && this.validatorUtility.currencyFormatter(rowData.data.dueAmount) || this.validatorUtility.currencyFormatter(rowData.data["inActivePayble"])}</span>
        </div>;
    }

    partnerAreaInfo = (rowData) => {

        return <span>
            {/* <span style={{ color: "blue" }}>{rowData.data.partnerMobile}</span><br /> */}
            <span>{rowData.data && rowData.data.instituteAddress || rowData.data.areaName}</span>
        </span>
    }

    totalInstitute = (rowData) => {

        return <span>
            <span>{rowData.data && rowData.data.instituteStatus === 1 ?
                <span className="text-success">Active</span> : rowData.data && rowData.data.instituteStatus === 2 ? <span className="text-danger">Inctive</span> :
                    rowData.data.totalInstitute ? rowData.data.totalInstitute : ''}</span>
        </span>
    }

    // partnerTypeBody = (rowData) => {
    //     return (
    //         <span>
    //             <span >{rowData.data && rowData.data.instituteId || rowData.data.partnerType}</span>
    //         </span>
    //     )
    // }

    partnerDetailInfo = (rowData) => {
        return <span>
            <span >{rowData.data && rowData.data.instituteName || rowData.data.partnerName}</span><br />
            <span >{rowData.data && rowData.data.partnerNetiID}</span>
        </span>
    }

    sumOfActiveInactive = () => {
        return <span>
            <span className="text-success">{totalActiveInstitute + "[" + this.Formatter.getFixedDecimalNumber(this.totalActiveInstitutePercentageInfo()) + "%" + "]" + "[" + this.validatorUtility.currencyFormatter(totalActiveRate) + "]"}</span><br />
            <span className="text-danger">{totalInactiveInstitute + "[" + this.Formatter.getFixedDecimalNumber(this.totalInactiveInstitutePercentageInfo()) + "%" + "]" + "[" + this.validatorUtility.currencyFormatter(totalInactiveRate) + "]"}</span>
        </span>

    }

    sumOfActiveInactiveDue = () => {
        return <span>
            <span className="text-success">{this.validatorUtility.currencyFormatter(totalActiveDue)}</span><br />
            <span className="text-danger">{this.validatorUtility.currencyFormatter(totalInactiveDue)}</span>
        </span>

    }

    totalActiveInstitutePercentageInfo = () => {

        let totalActiveInstitutePercentage = (totalActiveInstitute / sumOfInstitute) * 100
        if (totalActiveInstitutePercentage) {
            return isNaN(totalActiveInstitutePercentage) ? 0 : totalActiveInstitutePercentage;
        }
    }

    totalInactiveInstitutePercentageInfo = () => {

        let totalInactiveInstitutePercentage = (totalInactiveInstitute / sumOfInstitute) * 100
        if (totalInactiveInstitutePercentage) {
            return isNaN(totalInactiveInstitutePercentage) ? 0 : totalInactiveInstitutePercentage;
        }
    }

    onHide = () => {
        this.setState({ visible: false })
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    partnerTypeBody = (rowData) => {
        return (
            <span>
                <span >{rowData.data && rowData.data.instituteId || rowData.data.partnerType}</span>
            </span>
        )
    }

    

    render() {

            console.log('partnerListArr', partnerListArr);
            
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let profile = JSON.parse(localStorage.getItem('Profile'));


            

        // excel
        let excelColumns = "";
        let excelFileName = "Partner Due List.xlsx";
        let excelSheetName = "Partner Due List";
        excelColumns = [
            <Workbook.Column value="partnerType" label="Partner Type" />,
            <Workbook.Column value="count" label="Name" />,
            <Workbook.Column value="amount" label="Neti ID" />,
            <Workbook.Column value="amount" label="Area" />,
            <Workbook.Column value="totalInstitute" label="Total Institute" />,
            <Workbook.Column value="amount" label="Active Due" />,
            <Workbook.Column value="amount" label="Inactive Due" />,
            <Workbook.Column value="amount" label="Active" />,
            <Workbook.Column value="amount" label="Inactive" />,
        ];
        // end excel

        let header = <div className="header-title">
            <div className="header-title-left">
                Partner Due List
                        </div>

            <div className="header-title-right">

                {/* <Link to={{ pathname: MY_POINT.WALLET_LOGS, walletTabIndex: 0 }}>
                                More<i className="fas fa-angle-right" ></i>
                            </Link> */}
            </div>
        </div>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section blur-section">
                    <div className="p-grid nw-form">
                        <div className="nw-data-table">
                            {
                                this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : <div>
                                        
                                        <TreeTable
                                            header={header}
                                            lazy={true}
                                            value={partnerListArr}
                                            onExpand={this.onExpand}
                                            expandedKeys={this.state.expandedKeys}
                                            onToggle={this.onExpandToggle}
                                        >
                                            <Column field="partnerType" header={partnerTypeHeader} className='pType' expander body={this.partnerTypeBody} />
                                            <Column field="" header={partnerDetailsHeader}  body={this.partnerDetailInfo} filter={true} />
                                            <Column field="" header="Area" body={this.partnerAreaInfo} filter={true} />
                                            <Column field="totalInstitute" header={partnerTotalInstituteHeader} body={this.totalInstitute} />
                                            <Column field="" header="Active Due / Inactive Due" body={this.mbpDueActiveInstituteBody} />
                                            <Column field="" header="Active / Inactive" body={this.mbpActiveInstituteBody.bind(this)} />
                                        </TreeTable>
                                        {/* <div className="p-col-12 p-xl-12 m-t-8 nw-button-parent p-r-0">
                                            <Workbook
                                                filename={excelFileName}
                                                element={
                                                    <Button
                                                        label="Download Excel"
                                                        icon="fas fa-download"
                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                    />
                                                }
                                            >
                                                <Workbook.Sheet data={this.state.pdfTableData} name={excelSheetName}>
                                                    {excelColumns}
                                                </Workbook.Sheet>
                                            </Workbook>
                                        </div> */}
                                    </div>
                            }

                        </div>

                    </div>
                </div>

                <div className="dialog-section">
                    {/* {this.state.visible == true && */}
                    <Dialog
                        className="nw-dialog"
                        maximizable header="Institute Information"
                        visible={this.state.visible}
                        modal={true}
                        onHide={this.onHide}>

                        {this.state.visible == true && <InstituteDueStatement searchObj={this.state.searchObj} />}
                    </Dialog>

                    {/* } */}
                </div>

            </div>
        )
    }
}