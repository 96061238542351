import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { RadioButton } from 'primereact/radiobutton';
import { SaSummaryService } from '../../../service/saPoint/SaSummaryService';
import { SaCommonService } from '../../../service/saPoint/SaCommonService';
import { Card } from "primereact/card";
import Chart from "react-google-charts";
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import mapImage from '../../../assets/images/map-image.png';
import mapPin from '../../../assets/images/map-pin.png';
import ImageMapper from 'react-image-mapper';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {
  primaryChartsColorArray,
  dangerChartsColorArray,
} from "../../common/ChartsColorArray";
import { AttendancerChartsColorArray } from "../../common/ChartsColorArray";
import { InstituteWiseAttendance } from "./InstituteWiseAttendance";

let maxDate = new Date();
let headerNames = 'DIVISIONS';
let subHeaderName;

let districtWiseInstituteInfoFinalArr = [];
let districtWiseInstituteInfoArr = [];

let districtWiseTeacherInfoFinalArr = [];
let districtWiseTeacherInfoArr = [];

let districtWisePresentInfoFinalArr = [];
let districtWisePresentInfoArr = [];

let districtWiseAbsentInfoFinalArr = [];
let districtWiseAbsentInfoArr = [];

let districtWiseLeaveInfoFinalArr = [];
let districtWiseLeaveInfoArr = [];

let teacherAttendanceInfoArr = [];
let teacherAttendanceInfoFinalArr = [];
let totalAttendanceInfoArr = [];
let totalAttendanceInfoFinalArr = [];

let districtTableHeaderName;
let divisionTableHeaderName;
let upazillaTableHeaderName;
let instituteTableHeaderName;

let instituteInfoAtaGlance;
let formattedDate;
export class DivisionWiseAttendence extends Component {

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.state = {
          dataTableValue: [],
          districtWiseInfo: {},
          singleInstituteInfo: {},
          upazillaWiseInfo: {},
          instituteWiseInfo: {},
          divisionDatatableInfoView: true,
          viewDistrictChartInfo: false,
          viewDistrictDatatableInfo: false,
          viewUpazillaDatatableInfo: false,
          viewInstitutesDatatableInfo: false,
          viewSingleInstituteDetails: false,
          divisionID: '',
          divisionWiseAttendanceInfoView: true,
          districtWiseAttendanceInfoView: false,
          errorMsgVisible: false,
          topProgressBar: false,
          errorMsgBody: '',
          userType: {},
          hoveredArea: '',
          date:'',
          city: null,
          checked1: false,
        }

        this.cssAnimation = this.cssAnimation.bind();
        this.createMapDetails = this.createMapDetails.bind();
        this.saCommonService = new SaCommonService();
    }

    componentDidMount(){
      this.fetchObserverUserType()
      this.loadMapIcon();
       
            
    }


    loadMapIcon = () => {
      this.mapRef &&  this.mapRef.current && this.mapRef.current.children[0] &&  this.mapRef.current.children[0].classList &&  this.mapRef.current.children[0].classList.add('mapImage');

      let positionArray = [
          {top :157 , left : 189, name: 'Dhaka'}, //dhaka
          {top :92 , left : 230, name: 'Mymensingh'}, //Mymensingh
          {top :260 , left : 223, name: 'Barisal'}, //barishal
          {top :33 , left : 138, name: 'Rangpur'}, //rangpur
          {top :226 , left : 107, name: 'Khulna'}, //Khulna
          {top :101 , left : 95, name: 'Rajshahi'}, //rajshahi
          {top :95 , left : 364, name: 'Sylhet'}, //sylhet
          {top :254 , left : 439, name: 'Chittagong'}, //chittagong
      ];
      
              for(let i = 3; i <= 10; i++){
                  let positionValue = positionArray[i-3]
                  this.cssAnimation(i, positionValue);   
                  let createElement = document.createElement("div");
                  let spanElement = document.createElement("span");
                  let pinIcon = this.mapRef &&  this.mapRef.current && this.mapRef.current.children[0] 
                  pinIcon.appendChild(createElement);
                  createElement.addEventListener("click", (e) => this.createMapDetails(e, positionValue));
                  pinIcon.children[i].classList.add("mapPin");
                  pinIcon.children[i].classList.add("mapIconJump-"+(i-2));
                  pinIcon.children[i].classList.add("mapIcon-"+(i-2));
                  pinIcon.children[i].append(spanElement);
                  let pinIconSelect = document.querySelector(".mapIcon-"+(i-2));
                  pinIconSelect.style.animationName = "moveingObject-"+(i-2);
                  pinIconSelect.style.animationFillMode = "forwards";
                  pinIconSelect.style.animationDelay = "0.5s";
                  pinIconSelect.style.animationTimingFunction = "ease-out"
                  pinIconSelect.style.animationDuration = "1.5s";
                  this.cssBounceAnimation(i, positionValue);
                  
              }
              setTimeout(() => {    
                  for(let i = 3; i <= 10; i++){
                      let positionValue = positionArray[i-3];
                      let pinIconSelect = document.querySelector(".mapIcon-"+(i-2));
                      pinIconSelect.style.animationName = "";
                      pinIconSelect.style.top = positionValue.top+"px";
                      pinIconSelect.style.left = positionValue.left+"px";
                  }
              }, 2000);

              window.addEventListener('load', function(event){
                  let windowWidth = event.currentTarget.innerWidth;
                  let divSelector = document.querySelector('.mapImage');
                  if (windowWidth < 1079) {
                      divSelector.style.width = "100%";
                      divSelector.style.height = "100%";
                      divSelector.style.transform = "scale(0.5)";
                  }else{
                      divSelector.style.width = "540px";
                      divSelector.style.height = "469px";
                      divSelector.style.transform = "unset";
                  }
              });
    }

     fetchObserverUserType = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.saCommonService.findObserverLayerType()
          .then(res => {
            if (res.status === 302) {
              return res.json().then((body) => {
                if(body && body.areaCoreCategoryInfoDTO && body.areaCoreCategoryInfoDTO.parentTypeInfoDTO && body.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10201') {
                  headerNames = 'DIVISIONS'
                  subHeaderName = 'Division'
                } else if(body && body.areaCoreCategoryInfoDTO && body.areaCoreCategoryInfoDTO.parentTypeInfoDTO && body.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10202') {
                  headerNames = 'DISTRICTS'
                  subHeaderName = 'District'
                } else if(body && body.areaCoreCategoryInfoDTO && body.areaCoreCategoryInfoDTO.parentTypeInfoDTO && body.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10203') {
                  headerNames = 'UPAZILAS'
                  subHeaderName = 'Upazila'
                } 
                this.setState({ userType: body })
                  this.setState({ topProgressBar: false, errorMsgVisible: false });
              })
            } else {
              this.saCommonService.Auth.handleErrorStatus(res)
                .then((resp) => {
                  this.setState({  topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
                });
            };
          }).catch(error => {
            this.setState({  topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
          });
      }
 

    onChangeDate = (e) => {
      let {userType} = this.state;
       formattedDate = NetiDateUtils.getDateFromStringDDMMYYYY(e.target.value)
        this.setState({ date: e.target.value });
        if(userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10204') {
          this.fetchInstituteWiseAttendanceInfo(userType.areaCoreCategoryInfoDTO.coreCategoryID,formattedDate);
        } else {
          this.fetchAreaWiseAttendanceInfo(e.target.value);
        }
    }

    fetchAreaWiseAttendanceInfo = (param) => {
        this.setState({ upazillaWiseInfo: {},districtWiseInfo: {},dataTableValue: {},dataTableIsLoading: true, divisionDatatableInfoView: false, topProgressBar: true, errorMsgVisible: false });
        let {userType} = this.state;
        let date = NetiDateUtils.getDateFromStringDDMMYYYY(param)
        this.saCommonService.findObserverAttendanceInfoByDate(date)
          .then(res => {
            if (res.status === 302) {
              return res.json().then((body) => {
                if(userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10201') {
                   this.setState({ dataTableValue: body.item })
                   this.setState({ divisionDatatableInfoView: true, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                } else if(userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10202') {
                  this.setState({ districtWiseInfo: body.item, viewDistrictDatatableInfo:true, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                } else if(userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10203') {
                  this.setState({ upazillaWiseInfo: body.item,viewUpazillaDatatableInfo:true, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                } 
                // Country Code T10201
               // Division    T10202
              // District    T10203
             // Upazilla    T10204
              })
            } else {
              this.saCommonService.Auth.handleErrorStatus(res)
                .then((resp) => {
                  this.setState({ divisionDatatableInfoView: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
                });
            };
          }).catch(error => {
            this.setState({ divisionDatatableInfoView: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
          });
      }

    cssAnimation = (keyFrameNumber, positionValue) => {
        var cssAnimation = document.createElement('style');
        cssAnimation.type = 'text/css';
        var rules = document.createTextNode('@keyframes moveingObject-'+ (keyFrameNumber - 2) +' {'+
        'from { left: 189px; top: 157px; opacity:1; } to { left:'+positionValue.left+'px; top: '+positionValue.top+'px; opacity:1;}}');
        cssAnimation.appendChild(rules);
        document.getElementsByTagName("head")[0].appendChild(cssAnimation);
    }

    cssBounceAnimation(keyFrameNumber, positionValue){
        var cssAnimation = document.createElement('style');
        cssAnimation.type = 'text/css';
        var hoverRules =  document.createTextNode('.mapIconJump-'+ (keyFrameNumber - 2) +':hover{ animation-name: bounce-'+ (keyFrameNumber - 2) +' !important;animation-delay: 0.1s !important; animation-duration: 0.2s !important;animation-iteration-count: 1;}');
        var rules = document.createTextNode('@keyframes bounce-'+ (keyFrameNumber - 2) +' {100% {top: '+ positionValue.top +'px;}50% {top: '+ (positionValue.top - 10) +'px;}}');
        cssAnimation.appendChild(rules);
        cssAnimation.appendChild(hoverRules);
        document.getElementsByTagName("head")[0].appendChild(cssAnimation);
    }

    createMapDetails =  (e, pinDiv) => {
        let pinIcon = this.mapRef.current.children[0];
        let createElement = document.createElement("div");
        let removeDiv = pinIcon.children[11];
        let html = '';
        this.state.dataTableValue && this.state.dataTableValue.details && this.state.dataTableValue.details.map(itemName => {
        if(pinDiv.name == itemName.name) {
        if(removeDiv)removeDiv.remove();
        pinIcon.appendChild(createElement);
        pinIcon.children[11].classList.add("map-details-wrapper");
        html += '<div class="map-details">';
        html += '<i class="fas fa-times closeBtn"></i>';
        html += '</i>';
        html += '<div class="map-data-left">';
        html += '<div>';
        html += '</div>';
        html +=  '<p>'+pinDiv.name+'<p>';
        html += '</div>';
        html += '<div class="map-data-right">';
        html += '<ul>';
                   html += `<li>Institute : <span>${itemName.totalInstitute.toLocaleString('EN-IN')}</span></li>`;
                   html += `<li>Total Teacher : <span>${itemName.totalTeacher.toLocaleString('EN-IN')}</span></li>`;
                   html += `<li>Total Present : <span>${itemName.totalPresent.toLocaleString('EN-IN')}</span></li>`;
                   html += `<li>Total Absent : <span>${itemName.totalAbsent.toLocaleString('EN-IN')}</span></li>`;
                   html += `<li>Total Leave : <span>${itemName.totalLeave.toLocaleString('EN-IN')}</span></li>`;
        html += '</ul>';
        html += '</div>';
        html += '</div>';
        document.getElementsByClassName('map-details-wrapper')[0].insertAdjacentHTML('afterbegin', html);
        let mapWrapper = document.querySelector(".map-details-wrapper");
        let x =  `${e.target.offsetLeft - 50}px`;
        let y =  `${e.target.offsetTop - 140}px`;
        mapWrapper.style.backgroundColor = "#fff";
        mapWrapper.style.height = "auto";
        mapWrapper.style.minWidth = "300px";
        mapWrapper.style.left = x;
        mapWrapper.style.top =  y;
        mapWrapper.style.zIndex =  1;
        mapWrapper.style.position = "absolute";
      }
    });

    }
   
    showDistrictWiseAttendanceInfoView = () => {
      this.setState({districtWiseAttendanceInfoView: true});
    }

    hideDistrictWiseAttendanceInfoView = () => {
      this.setState({districtWiseAttendanceInfoView: false});
    }

    showDivisionWiseAttendanceInfoView = () => {
      this.setState({divisionWiseAttendanceInfoView: true});
    }

    hideDivisionWiseAttendanceInfoView = () => {
      this.setState({divisionWiseAttendanceInfoView: false});
    }

    getDivisionName = (rowData => {
      
      let divisionColorCount = 1;
      let divisionNumber = 8; //include color in $division-color-list in sass according to division number (division in BD).

      return rowData => {
        return (
          <div className="division-wrapper">
            <span
              onClick={e => this.showMoreInfoByDivisionName(rowData)}
              className={`division-text division-text-color-${
                divisionColorCount < divisionNumber
                  ? divisionColorCount++
                  : (divisionColorCount = 1)
              }`}
            >
              {rowData.name}
            </span>
          </div>
        );
      };
    })();


    getTotalInstitute = rowData => {
      return (
        <div className="total-institute-value">
          {rowData.totalInstitute}
        </div>
      );
    };

    getTotalTeacherData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalTeacher
                ? rowData.totalTeacher.toLocaleString("EN-IN")
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleTeacher}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleTeacher}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleTeacherPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleTeacherPercent} %`}
            </span>
          </div>
        </div>
      );
    };

    getTotalPresentData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalPresent
                ? `${rowData.totalPresent.toLocaleString("EN-IN")} (${
                    rowData.totalPresentPercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMalePresent}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemalePresent}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMalePresentPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemalePresentPercent} %`}
            </span>
          </div>
        </div>
      );
    };

    getTotalAbsentData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalAbsent
                ? `${rowData.totalAbsent.toLocaleString("EN-IN")} (${
                    rowData.totalAbsentPercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleAbsent}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleAbsent}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleAbsentPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleAbsentPercent} %`}
            </span>
          </div>
        </div>
      );
    };
    getTotalLeaveData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalLeave
                ? `${rowData.totalLeave.toLocaleString("EN-IN")} (${
                    rowData.totalLeavePercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleLeave}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleLeave}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleLeavePercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleLeavePercent} %`}
            </span>
          </div>
        </div>
      );
    };


    getDistrictName = (rowData => {
      let divisionColorCount = 1;
      let divisionNumber = 8; //include color in $division-color-list in sass according to division number (division in BD).

      //clouser Function
      return rowData => {
        return (
          <div className="division-wrapper">
            <span
              onClick={e => this.showMoreInfoByDistrictName(rowData)}
              className={`division-text division-text-color-${
                divisionColorCount < divisionNumber
                  ? divisionColorCount++
                  : (divisionColorCount = 1)
              }`}
            >
              {rowData.name}
            </span>
          </div>
        );
      };
    })();

    getDistrictTotalInstitute = rowData => {
      return (
        <div className="total-institute-value">
          {rowData.totalInstitute}
        </div>
      );
    };

    getDistrictTotalTeacherData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalTeacher
                ? rowData.totalTeacher.toLocaleString("EN-IN")
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleTeacher}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleTeacher}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleTeacherPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleTeacherPercent} %`}
            </span>
          </div>
        </div>
      );
    };

    getDistrictTotalPresentData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalPresent
                ? `${rowData.totalPresent.toLocaleString("EN-IN")} (${
                    rowData.totalPresentPercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMalePresent}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemalePresent}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMalePresentPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemalePresentPercent} %`}
            </span>
          </div>
        </div>
      );
    };

    getDistrictTotalAbsentData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalAbsent
                ? `${rowData.totalAbsent.toLocaleString("EN-IN")} (${
                    rowData.totalAbsentPercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleAbsent}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleAbsent}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleAbsentPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleAbsentPercent} %`}
            </span>
          </div>
        </div>
      );
    };
    getDistrictTotalLeaveData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalLeave
                ? `${rowData.totalLeave.toLocaleString("EN-IN")} (${
                    rowData.totalLeavePercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleLeave}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleLeave}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleLeavePercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleLeavePercent} %`}
            </span>
          </div>
        </div>
      );
    };

    getUpazillaName = (rowData => {
      let divisionColorCount = 1;
      let divisionNumber = 8; //include color in $division-color-list in sass according to division number (division in BD).

      //clouser Function
      return rowData => {
        return (
          <div className="division-wrapper">
            <span
              onClick={e => this.showMoreInfoByUpazillaName(rowData)}
              className={`division-text division-text-color-${
                divisionColorCount < divisionNumber
                  ? divisionColorCount++
                  : (divisionColorCount = 1)
              }`}
            >
              {rowData.name}
            </span>
          </div>
        );
      };
    })();

    getUpazillaTotalInstitute = rowData => {
      return (
        <div className="total-institute-value">
          {rowData.totalInstitute}
        </div>
      );
    };

    getUpazillaTotalTeacherData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalTeacher
                ? rowData.totalTeacher.toLocaleString("EN-IN")
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleTeacher}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleTeacher}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleTeacherPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleTeacherPercent} %`}
            </span>
          </div>
        </div>
      );
    };

    getUpazillaTotalPresentData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalPresent
                ? `${rowData.totalPresent.toLocaleString("EN-IN")} (${
                    rowData.totalPresentPercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMalePresent}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemalePresent}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMalePresentPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemalePresentPercent} %`}
            </span>
          </div>
        </div>
      );
    };

    getUpazillaTotalAbsentData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalAbsent
                ? `${rowData.totalAbsent.toLocaleString("EN-IN")} (${
                    rowData.totalAbsentPercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleAbsent}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleAbsent}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleAbsentPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleAbsentPercent} %`}
            </span>
          </div>
        </div>
      );
    };
    getUpazillaTotalLeaveData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalLeave
                ? `${rowData.totalLeave.toLocaleString("EN-IN")} (${
                    rowData.totalLeavePercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleLeave}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleLeave}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleLeavePercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleLeavePercent} %`}
            </span>
          </div>
        </div>
      );
    };

    getInstituteName = (rowData => {
      let divisionColorCount = 1;
      let divisionNumber = 8; //include color in $division-color-list in sass according to division number (division in BD).

      //clouser Function
      return rowData => {
        return (
          <div className="division-wrapper">
            <span
              className={`division-text division-text-color-${
                divisionColorCount < divisionNumber
                  ? divisionColorCount++
                  : (divisionColorCount = 1)
              }` }
            >
                {`${rowData.instituteId} - ${rowData.instituteName}`}
            </span>
          </div>
        );
      };
    })();

    getInstituteType = rowData => {
      return (
        <div className="total-institute-value">
          {rowData.instituteType}
        </div>
      );
    };

    getInstituteTotalInstitute = rowData => {
      return (
        <div className="total-institute-value">
          {rowData.totalInstitute}
        </div>
      );
    };

    getInstituteTotalTeacherData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalTeacher
                ? rowData.totalTeacher.toLocaleString("EN-IN")
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleTeacher}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleTeacher}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleTeacherPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleTeacherPercent} %`}
            </span>
          </div>
        </div>
      );
    };

    getInstituteTotalPresentData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalPresent
                ? `${rowData.totalPresent.toLocaleString("EN-IN")} (${
                    rowData.totalPresentPercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMalePresent}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemalePresent}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMalePresentPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemalePresentPercent} %`}
            </span>
          </div>
        </div>
      );
    };

    getInstituteTotalAbsentData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalAbsent
                ? `${rowData.totalAbsent.toLocaleString("EN-IN")} (${
                    rowData.totalAbsentPercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleAbsent}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleAbsent}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleAbsentPercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleAbsentPercent} %`}
            </span>
          </div>
        </div>
      );
    };
    getInstituteTotalLeaveData = rowData => {
      return (
        <div className="data-value-wrapper">
          <div>
            <span className="totalValue">
              {rowData.totalLeave
                ? `${rowData.totalLeave.toLocaleString("EN-IN")} (${
                    rowData.totalLeavePercent
                  }%)`
                : 0}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {rowData.totalMaleLeave}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {rowData.totalFemaleLeave}
            </span>
          </div>
          <div>
            <span className="data-value">
              <i className="fa fa-male male-icon"></i>
              {`${rowData.totalMaleLeavePercent} %`}
            </span>
            <span className="data-value">
              <i className="fa fa-female female-icon"></i>
              {`${rowData.totalFemaleLeavePercent} %`}
            </span>
          </div>
        </div>
      );
    };

    actionBodyTemplate = (rowData) => {
      return <div className='text-center'>
          <Button
              className="nw-action-button edit"
              icon="fas fa-eye"
              tooltip="View Reports"
              onClick={(e) =>  this.fetchSingleInstituteInfo(rowData.instituteId)}
          />
      </div>
  }


  fetchSingleInstituteInfo = (instituteID) => {
    teacherAttendanceInfoArr = [];
    teacherAttendanceInfoFinalArr = [];
    totalAttendanceInfoArr = [];
    totalAttendanceInfoFinalArr = [];
   this.setState({
     dataTableIsLoading: true,
     topProgressBar: true,
     errorMsgVisible: false,
   });
   this.saCommonService
     .findSingleInstituteWiseAttendanceInfo(formattedDate,instituteID)
     .then((res) => {
       if (res.status === 302) {
         return res.json().then((body) => {

          this.setState({ });
           let commonChartHeader = ["title", "value"];
           this.setState({
            divisionDatatableInfoView: false,
            viewDistrictChartInfo: false,
            viewDistrictDatatableInfo: false,
            viewUpazillaDatatableInfo: false,
            viewInstitutesDatatableInfo: false,
            viewSingleInstituteDetails: true

           });
           if(body && body.item) {
               for (const property in body.item) {
                       if(property == 'maleTeacherPercent') {
                           teacherAttendanceInfoArr.push([property,body.item[property]])
                       }
                       if(property == 'femaleTeacherPercent') {
                           teacherAttendanceInfoArr.push([property,body.item[property]])
                       }
                       if(property == 'presentPercent') {
                           totalAttendanceInfoArr.push([property,body.item[property]])
                       }
                       if(property == 'absentPercent') {
                           totalAttendanceInfoArr.push([property,body.item[property]])
                       }
                       if(property == 'leavePercent') {
                           totalAttendanceInfoArr.push([property,body.item[property]])
                       }
                 }
                 teacherAttendanceInfoFinalArr.push(commonChartHeader,...teacherAttendanceInfoArr)
                 totalAttendanceInfoFinalArr.push(commonChartHeader,...totalAttendanceInfoArr)
            }

           this.setState({
             singleInstituteInfo: body.item,
           });
           this.setState({
             dataTableIsLoading: false,
             topProgressBar: false,
             errorMsgVisible: false,
           });
         });
       } else {
         this.saCommonService.Auth.handleErrorStatus(res).then((resp) => {
           this.setState({
             dataTableIsLoading: false,
             topProgressBar: false,
             errorMsgVisible: true,
             errorMsgBody: resp,
           });
         });
       }
     })
     .catch((error) => {
       this.setState({
         dataTableIsLoading: false,
         topProgressBar: false,
         errorMsgVisible: true,
         errorMsgBody: "Unable to load. Please check your connection.",
       });
     });

 }

    fetchDistrictWiseAttendanceInfo = (divisionID,requestDate) => {
      districtWiseInstituteInfoFinalArr = [];
      districtWiseInstituteInfoArr = [];
     
      districtWiseTeacherInfoFinalArr = [];
      districtWiseTeacherInfoArr = [];
     
      districtWisePresentInfoFinalArr = [];
      districtWisePresentInfoArr = [];
     
      districtWiseAbsentInfoFinalArr = [];
      districtWiseAbsentInfoArr = [];
     
      districtWiseLeaveInfoFinalArr = [];
      districtWiseLeaveInfoArr = [];
     
     this.setState({
       dataTableIsLoading: true,
       districtWiseInfo: {},
       topProgressBar: true,
       errorMsgVisible: false,
     });
     this.saCommonService
       .findObserverAttendanceInfoByArea(divisionID,requestDate)
       .then((res) => {
         if (res.status === 302) {
           return res.json().then((body) => {
             this.setState({ viewDistrictChartInfo: true,
              viewDistrictDatatableInfo: true, divisionWiseAttendanceInfoView: false,divisionDatatableInfoView: false});

             let commonChartHeader = ["title", "value"];
             districtWiseInstituteInfoArr = body.item.details.map((item) => {
               return [item.name, item.totalInstitute];
             });
             districtWiseInstituteInfoFinalArr.push(
               commonChartHeader,
               ...districtWiseInstituteInfoArr
             );
 
             districtWiseTeacherInfoArr = body.item.details.map((item) => {
               return [item.name, item.totalTeacher];
             });
             districtWiseTeacherInfoFinalArr.push(
               commonChartHeader,
               ...districtWiseTeacherInfoArr
             );
 
             districtWisePresentInfoArr = body.item.details.map((item) => {
               return [item.name, item.totalPresent];
             });
             districtWisePresentInfoFinalArr.push(
               commonChartHeader,
               ...districtWisePresentInfoArr
             );
 
             districtWiseAbsentInfoArr = body.item.details.map((item) => {
               return [item.name, item.totalAbsent];
             });
             districtWiseAbsentInfoFinalArr.push(
               commonChartHeader,
               ...districtWiseAbsentInfoArr
             );
 
             districtWiseLeaveInfoArr = body.item.details.map((item) => {
               return [item.name, item.totalLeave];
             });
             districtWiseLeaveInfoFinalArr.push(
               commonChartHeader,
               ...districtWiseLeaveInfoArr
             );
 
             this.setState({
               districtWiseInfo: body.item,
             });
             this.setState({
               dataTableIsLoading: false,
               topProgressBar: false,
               errorMsgVisible: false,
             });
           });
         } else {
           this.saCommonService.Auth.handleErrorStatus(res).then((resp) => {
             this.setState({
               dataTableIsLoading: false,
               topProgressBar: false,
               errorMsgVisible: true,
               errorMsgBody: resp,
             });
           });
         }
       })
       .catch((error) => {
         this.setState({
           dataTableIsLoading: false,
           topProgressBar: false,
           errorMsgVisible: true,
           errorMsgBody: "Unable to load. Please check your connection.",
         });
       });
   };

   fetchUpazillaWiseAttendanceInfo = (areaID, date) => {
    this.setState({
      upazillaWiseInfo: {},
      dataTableIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });
    this.saCommonService
      .findObserverAttendanceInfoByArea(areaID, date)
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({
              upazillaWiseInfo: body.item,
              divisionWiseAttendanceInfoView: false,
              viewUpazillaDatatableInfo: true,
              divisionDatatableInfoView: false,
              viewDistrictChartInfo: false,
              viewDistrictDatatableInfo: false,
            });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.saCommonService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load. Please check your connection.",
        });
      });
  };

  fetchInstituteWiseAttendanceInfo = (areaID, date) => {
    this.setState({
      instituteWiseInfo:{},
      dataTableIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });
    this.saCommonService
      .findInstituteWiseObserverAreaAttendance(areaID, date)
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({
              instituteWiseInfo: body.item,
              divisionWiseAttendanceInfoView: false,
              divisionDatatableInfoView: false,
              viewDistrictChartInfo: false,
              viewDistrictDatatableInfo: false,
              viewUpazillaDatatableInfo: false,
              viewInstitutesDatatableInfo: true
            });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.saCommonService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load. Please check your connection.",
        });
      });
  };

   showMoreInfoByDivisionName = rowData => {
     if(rowData.parentDefaultCode === 'T10201') {
      this.fetchDistrictWiseAttendanceInfo(rowData.id,formattedDate);
     }
   };

   showMoreInfoByDistrictName = rowData => {
     if(rowData.parentDefaultCode === 'T10202') {
      this.fetchUpazillaWiseAttendanceInfo(rowData.id, formattedDate);
    } 
  };

  showMoreInfoByUpazillaName = rowData => {
   if(rowData.parentDefaultCode === 'T10203') {
      this.fetchInstituteWiseAttendanceInfo(rowData.id, formattedDate);
    }
  };


  goBackToDivisionPage = (e) => {
    this.setState({
      divisionWiseAttendanceInfoView: true,
      divisionDatatableInfoView: true,
      viewDistrictChartInfo: false,
      viewDistrictDatatableInfo: false
    })
         setTimeout(() => {
                        this.loadMapIcon();
                    }, 800);
 
  }

  goBackToDistrictPage = (e) => {
    this.setState({
    divisionWiseAttendanceInfoView: false,
    viewDistrictChartInfo: true,
    viewDistrictDatatableInfo: true,
    viewUpazillaDatatableInfo: false,
  })
  }

  goBackToUpazillaPage = () => {
    this.setState({
    divisionWiseAttendanceInfoView: false,
    viewUpazillaDatatableInfo: true,
    viewInstitutesDatatableInfo: false
  })
  }

  hideSingleInstituteAttendanceInfoView = () => {
    this.setState({
      viewSingleInstituteDetails: false
    });
  };


  showInstituteWiseAttendanceInfoView = () => {
    this.setState({
      viewInstitutesDatatableInfo: true
    });
  }

    render() {
       let { topProgressBar,districtWiseInfo,instituteWiseInfo, upazillaWiseInfo, errorMsgVisible, errorMsgBody, staffAttendanceSummaryList } = this.state;
        let {dataTableValue, userType} = this.state

        if(userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10201') {
           divisionTableHeaderName = <div className="header-title"><div className="header-title-left"> Division wise Teacher Attendance Info</div><div className="header-title-right"><a>Total Found: {dataTableValue && dataTableValue.details && dataTableValue.details.length && dataTableValue.details.length.toLocaleString('EN-IN')}</a></div></div>;
        } 
         if(userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10202') {
           districtTableHeaderName = <div className="header-title"><div className="header-title-left">District Wise Teacher Attendance Info</div><div className="header-title-right"><a>Total Found: {districtWiseInfo && districtWiseInfo.details && districtWiseInfo.details.length && districtWiseInfo.details.length.toLocaleString('EN-IN')}</a>  </div></div>;
        } else {
           districtTableHeaderName = <div className="header-title">
           <div className="header-title-left">District Wise Teacher Attendance Info</div>
           <div style={{cursor:"pointer"}} className="header-title-right"><a>Total Found: {districtWiseInfo && districtWiseInfo.details && districtWiseInfo.details.length && districtWiseInfo.details.length.toLocaleString('EN-IN')}</a>  
           <Button
           label="Back"
           icon="fas fa-angle-left"
           className="p-button p-button-success nw-button nw-button-right"
           onClick={e => this.goBackToDivisionPage(e)}
           iconPos="right"
         />
         </div>
         </div>;
        }
        if(userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10203') {
          upazillaTableHeaderName = <div className="header-title"><div className="header-title-left">Upazila Wise Teacher Attendance Info</div><div  className="header-title-right">          <a>Total Found: {upazillaWiseInfo && upazillaWiseInfo.details && upazillaWiseInfo.details.length && upazillaWiseInfo.details.length.toLocaleString('EN-IN')}</a>  
          </div></div>;
        } else {
          upazillaTableHeaderName = <div className="header-title"><div className="header-title-left">Upazila Wise Teacher Attendance Info</div><div style={{cursor:"pointer"}} className="header-title-right">
          <a>Total Found: {upazillaWiseInfo && upazillaWiseInfo.details && upazillaWiseInfo.details.length && upazillaWiseInfo.details.length.toLocaleString('EN-IN')}</a>  
           <Button
           label="Back"
           icon="fas fa-angle-left"
           className="p-button p-button-success nw-button nw-button-right"
           onClick={e => this.goBackToDistrictPage(e)}
           iconPos="right"
         />
          </div></div>;
        }
        if(userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10204') {
          instituteTableHeaderName = <div className="header-title"><div className="header-title-left">Institute Wise Teacher Attendance Info</div><div  className="header-title-right">           <a>Total Found: {instituteWiseInfo && instituteWiseInfo.details && instituteWiseInfo.details.length && instituteWiseInfo.details.length.toLocaleString('EN-IN')}</a>  
          </div></div>;
        } else {
          instituteTableHeaderName = <div className="header-title"><div className="header-title-left">Institute Wise Teacher Attendance Info</div><div style={{cursor:"pointer"}} className="header-title-right">
           <a>Total Found: {instituteWiseInfo && instituteWiseInfo.details && instituteWiseInfo.details.length && instituteWiseInfo.details.length.toLocaleString('EN-IN')}</a>  
           <Button
           label="Back"
           icon="fas fa-angle-left"
           className="p-button p-button-success nw-button nw-button-right"
           onClick={e => this.goBackToUpazillaPage(e)}
           iconPos="right"
         />
          </div></div>;
        }
  
        window.addEventListener('resize', function(event){
            let windowWidth = event.srcElement.outerWidth;
            let divSelector = document.querySelector('.mapImage');
            // if (windowWidth < 1079) {
            //     divSelector.style.width = "100%";
            //     divSelector.style.height = "100%";
            //     divSelector.style.transform = "scale(0.5)";
            // }else{
            //     divSelector.style.width = "540px";
            //     divSelector.style.height = "469px";
            //     divSelector.style.transform = "unset";
            // }
        });

        let closeBtn = document.querySelector(".closeBtn");
        if(closeBtn){
            closeBtn.addEventListener('click', function (event) {
                let removewrapper = document.querySelector(".map-details-wrapper");
                    removewrapper.remove();
             })
        }
            

        let tableHeader = (
            <div className="p-clearfix">
                HR Attendance Summary{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {staffAttendanceSummaryList ? staffAttendanceSummaryList.length : ''}{" "}
                </span>
            </div>
        );

        let MAP = {
            name: "my-map",
            areas: [
                // { name: "Mymenshingh", shape: "poly", coords: [183, 132, 172, 134, 166, 143, 164, 153, 164, 159, 173, 163, 178, 169, 172, 176, 167, 183, 168, 191, 171, 198, 172, 204, 181, 201, 190, 200, 198, 198, 206, 195, 212, 195, 218, 195, 225, 199, 232, 203, 237, 206, 244, 210, 252, 211, 261, 212, 270, 213, 278, 211, 286, 211, 292, 209, 298, 205, 291, 199, 297, 196, 303, 193, 310, 191, 309, 183, 309, 176, 308, 168, 303, 162, 298, 156, 288, 155, 280, 151, 280, 145, 269, 144, 260, 144, 245, 144, 231, 145, 204, 139, 194, 138, 186, 137], strokeColor: "rgb(255, 255, 255)", preFillColor: "rgba(255, 255, 255, 0.0)", fillColor: "rgba(255, 255, 255, 0)" },
                // { name: "Mymenshingh", shape: "poly", coords: [280, 144, 286, 143, 293, 143, 301, 142, 311, 142, 320, 143, 329, 145, 338, 146, 348, 145, 358, 145, 370, 143, 384, 143, 394, 146, 403, 147, 411, 151, 419, 153, 428, 158, 423, 160, 414, 161, 407, 161, 408, 168, 404, 175, 401, 182, 396, 187, 389, 190, 384, 199, 377, 198, 367, 197, 367, 203, 361, 198, 358, 203, 350, 205, 341, 204, 333, 203, 333, 211, 326, 209, 321, 203, 324, 197, 318, 195, 312, 195, 311, 188, 308, 181, 309, 174, 307, 167, 301, 161, 297, 157, 290, 155, 284, 153], strokeColor: "rgb(255, 255, 255)", preFillColor: "rgba(255, 255, 255, 0.0)", fillColor: "rgba(255, 255, 255, 0)" },
                // { name: "Mymenshingh", shape: "poly", coords: [526, 515, 520, 503, 504, 483, 496, 473, 490, 464, 489, 440, 497, 431, 507, 428, 514, 434, 521, 438, 532, 438, 544, 451, 550, 445, 544, 433, 535, 417, 526, 398, 520, 381, 509, 360, 508, 351, 497, 332, 487, 315, 469, 292, 459, 283, 455, 274, 451, 264, 449, 255, 441, 246, 435, 238, 430, 230, 426, 235, 419, 229, 412, 234, 403, 231, 396, 228, 399, 238, 401, 247, 391, 252, 387, 261, 390, 270, 390, 278, 382, 283, 372, 287, 365, 282, 360, 275, 355, 268, 348, 261, 345, 268, 348, 277, 341, 275, 336, 267, 330, 256, 325, 248, 320, 240, 315, 236, 314, 230, 320, 224, 322, 216, 329, 212, 325, 207, 322, 201, 326, 196, 318, 195, 310, 192, 300, 196, 291, 199, 300, 206, 293, 209, 290, 215, 283, 219, 275, 219, 268, 219, 268, 226, 259, 231, 266, 241, 258, 245, 258, 252, 266, 260, 263, 266, 261, 274, 263, 282, 270, 291, 277, 297, 282, 304, 290, 309, 297, 313, 310, 319, 323, 327, 332, 321, 338, 314, 337, 306, 347, 300, 356, 302, 370, 309, 378, 314, 387, 320, 432, 384, 431, 390, 426, 379, 422, 387, 424, 396, 431, 396, 431, 411, 437, 426, 450, 419, 448, 426, 454, 435, 465, 451, 476, 466, 491, 479], strokeColor: "rgb(255, 255, 255)", preFillColor: "rgba(255, 255, 255, 0.0)", fillColor: "rgba(255, 255, 255, 0)" },
                // { name: "Mymenshingh", shape: "poly", coords: [341, 305, 348, 312, 348, 306], strokeColor: "rgb(255, 255, 255)", preFillColor: "rgba(255, 255, 255, 0.0)", fillColor: "rgba(255, 255, 255, 0)" },

            ]
        }

        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>
        let staticDivisionList =  <ul className="division-list">
        <li className="active">
          <h1>Dhaka</h1>
          <h3>0 Institute</h3>
        </li>
        <li className="">
          <h1>Barisal</h1>
          <h3>0 Institute</h3>
        </li>
        <li className="">
          <h1>Chittagong</h1>
          <h3>0 Institute</h3>
        </li>
        <li className="">
          <h1>Khulna</h1>
          <h3>0 Institute</h3>
        </li>
        <li className="">
          <h1>Mymensingh</h1>
          <h3>0 Institute</h3>
        </li>
        <li className="">
          <h1>Rajshahi</h1>
          <h3>0 Institute</h3>
        </li>
        <li className="">
          <h1>Rangpur</h1>
          <h3>0 Institute</h3>
        </li>
        <li className="">
          <h1>Sylhet</h1>
          <h3>0 Institute</h3>
        </li>
      </ul>
        let footerForTeacherAttendance = (
          <ColumnGroup>
            <Row>
              <Column
                footer={
                  <div>
                    <span
                      className="footer-value"
                      style={{ textAlign: "center" }}
                    >
                      TOTAL
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Total Institute</span>
                    <span className="footer-value">
                      {dataTableValue && dataTableValue.totalInstitute
                        ? dataTableValue.totalInstitute.toLocaleString(
                            "EN-IN"
                          )
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Total Teacher</span>
                    <span className="footer-value">
                      {dataTableValue && dataTableValue.totalTeacher
                        ? dataTableValue.totalTeacher.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Present</span>
                    <span className="footer-value">
                      {dataTableValue && dataTableValue.totalPresent
                        ? dataTableValue.totalPresent.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Absent</span>
                    <span className="footer-value">
                      {dataTableValue && dataTableValue.totalAbsent
                        ? dataTableValue.totalAbsent.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">leave</span>
                    <span className="footer-value">
                      {dataTableValue && dataTableValue.totalLeave
                        ? dataTableValue.totalLeave.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />
                     <Column
                footer={
                  <div>
                    <span className="footer-head"></span>
                    <span className="footer-value">
                   
                    </span>
                  </div>
                }
              />
            </Row>
          </ColumnGroup>
        );

        let districtWisefooterForTeacherAttendance = (
          <ColumnGroup>
            <Row>
              <Column
                footer={
                  <div>
                    <span
                      className="footer-value"
                      style={{ textAlign: "center" }}
                    >
                      TOTAL
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Total Institute</span>
                    <span className="footer-value">
                      {districtWiseInfo && districtWiseInfo.totalInstitute
                        ? districtWiseInfo.totalInstitute.toLocaleString(
                            "EN-IN"
                          )
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Total Teacher</span>
                    <span className="footer-value">
                      {districtWiseInfo && districtWiseInfo.totalTeacher
                        ? districtWiseInfo.totalTeacher.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Present</span>
                    <span className="footer-value">
                      {districtWiseInfo && districtWiseInfo.totalPresent
                        ? districtWiseInfo.totalPresent.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Absent</span>
                    <span className="footer-value">
                      {districtWiseInfo && districtWiseInfo.totalAbsent
                        ? districtWiseInfo.totalAbsent.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">leave</span>
                    <span className="footer-value">
                      {districtWiseInfo && districtWiseInfo.totalLeave
                        ? districtWiseInfo.totalLeave.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />
                     <Column
                footer={
                  <div>
                    <span className="footer-head"></span>
                    <span className="footer-value">
                   
                    </span>
                  </div>
                }
              />
            </Row>
          </ColumnGroup>
        );
        let upazillaWisefooterForTeacherAttendance = (
          <ColumnGroup>
            <Row>
              <Column
                footer={
                  <div>
                    <span
                      className="footer-value"
                      style={{ textAlign: "center" }}
                    >
                      TOTAL
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Total Institute</span>
                    <span className="footer-value">
                      {upazillaWiseInfo && upazillaWiseInfo.totalInstitute
                        ? upazillaWiseInfo.totalInstitute.toLocaleString(
                            "EN-IN"
                          )
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Total Teacher</span>
                    <span className="footer-value">
                      {upazillaWiseInfo && upazillaWiseInfo.totalTeacher
                        ? upazillaWiseInfo.totalTeacher.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Present</span>
                    <span className="footer-value">
                      {upazillaWiseInfo && upazillaWiseInfo.totalPresent
                        ? upazillaWiseInfo.totalPresent.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Absent</span>
                    <span className="footer-value">
                      {upazillaWiseInfo && upazillaWiseInfo.totalAbsent
                        ? upazillaWiseInfo.totalAbsent.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">leave</span>
                    <span className="footer-value">
                      {upazillaWiseInfo && upazillaWiseInfo.totalLeave
                        ? upazillaWiseInfo.totalLeave.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />
                     <Column
                footer={
                  <div>
                    <span className="footer-head"></span>
                    <span className="footer-value">
                   
                    </span>
                  </div>
                }
              />
            </Row>
          </ColumnGroup>
        );
        let instituteWisefooterForTeacherAttendance = (
          <ColumnGroup>
            <Row>
              <Column
                footer={
                  <div>
                    <span
                      className="footer-value"
                      style={{ textAlign: "center" }}
                    >
                      TOTAL
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Total Institute</span>
                    <span className="footer-value">
                      {instituteWiseInfo && instituteWiseInfo.totalInstitute
                        ? instituteWiseInfo.totalInstitute.toLocaleString(
                            "EN-IN"
                          )
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Total Teacher</span>
                    <span className="footer-value">
                      {instituteWiseInfo && instituteWiseInfo.totalTeacher
                        ? instituteWiseInfo.totalTeacher.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Present</span>
                    <span className="footer-value">
                      {instituteWiseInfo && instituteWiseInfo.totalPresent
                        ? instituteWiseInfo.totalPresent.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">Absent</span>
                    <span className="footer-value">
                      {instituteWiseInfo && instituteWiseInfo.totalAbsent
                        ? instituteWiseInfo.totalAbsent.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />

              <Column
                footer={
                  <div>
                    <span className="footer-head">leave</span>
                    <span className="footer-value">
                      {instituteWiseInfo && instituteWiseInfo.totalLeave
                        ? instituteWiseInfo.totalLeave.toLocaleString("EN-IN")
                        : 0}
                    </span>
                  </div>
                }
              />
                     <Column
                footer={
                  <div>
                    <span className="footer-head"></span>
                    <span className="footer-value">
                   
                    </span>
                  </div>
                }
              />
            </Row>
          </ColumnGroup>
        );
        return (
          <div className="p-fluid">
            {topProgressBar ? (
              <ErrorMessageView topProgressBar={topProgressBar} />
            ) : null}
            {errorMsgVisible ? (
              <ErrorMessageView
                errorMsgVisible={errorMsgVisible}
                errorMsgBody={errorMsgBody}
              />
            ) : null}
            <div className="nw-form">
              <div className="p-col-12">
                <div className="p-grid">
                {this.state.divisionWiseAttendanceInfoView === true ? 
                <>
                  <div className="divisionAttendenceWrapper">
                    <div className="map-wrapper">
                      <div
                        clasName="map-image-wrapper"
                        id="mapImageWrapper"
                        ref={this.mapRef}
                      >
                        {/* <div  style="position: relative; width: 540px; height: 469px;">
                                        <img src={mapImage} />
                                    </div> */}
                        <ImageMapper src={mapImage} map={MAP} />
                      </div>
                      {userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10201' ? 
                      <div className="totalInfo-wrapper">
                                        <div className="p-grid">
                                            <div className="p-md-5 p-sm-12">
                                                <div className="totalInfo-details">
                                                    <h1>{headerNames} of Bangladesh</h1>
                                                    <h4>Total {subHeaderName} : {dataTableValue && dataTableValue.totalId ? dataTableValue.totalId : 0}</h4>
                                                    <h4>Total Institute  : {dataTableValue && dataTableValue.totalInstitute ? dataTableValue.totalInstitute.toLocaleString('EN-IN') : 0}</h4>
                                                </div>
                                            </div>
                                            <div className="p-md-4 p-sm-12">
                                                <div className="totalInfo-details">
                                                    <h1>Teacher Info</h1>
                                                    <h4>Total Teacher : {dataTableValue && dataTableValue.totalTeacher ? dataTableValue.totalTeacher.toLocaleString('EN-IN') : 0}</h4>
                                                    <h4>Total Institute  : {dataTableValue && dataTableValue.totalInstitute ? dataTableValue.totalInstitute.toLocaleString('EN-IN') : 0}</h4>
                                                </div>
                                            </div>
                                            <div className="p-md-3 p-sm-12">
                                                <div className="totalInfo-details">
                                                    <h1>Attendance Info</h1>
                                                    <h4 className="dot-1">Total  Present : {dataTableValue && dataTableValue.totalPresent ? dataTableValue.totalPresent.toLocaleString('EN-IN') : 0}</h4>
                                                    <h4 className="dot-2">Total Absent  : {dataTableValue && dataTableValue.totalAbsent ? dataTableValue.totalAbsent.toLocaleString('EN-IN') : 0}</h4>
                                                    <h4 className="dot-3">Total Leave  : {dataTableValue && dataTableValue.totalLeave ? dataTableValue.totalLeave.toLocaleString('EN-IN') : 0}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                    {userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10202' ? 
                      <div className="totalInfo-wrapper">
                                        <div className="p-grid">
                                            <div className="p-md-5 p-sm-12">
                                                <div className="totalInfo-details">
                                                    <h1>{headerNames} of Bangladesh</h1>
                                                    <h4>Total {subHeaderName} : {districtWiseInfo && districtWiseInfo.totalId ? districtWiseInfo.totalId : 0}</h4>
                                                    <h4>Total Institute  : {districtWiseInfo && districtWiseInfo.totalInstitute ? districtWiseInfo.totalInstitute.toLocaleString('EN-IN') : 0}</h4>
                                                </div>
                                            </div>
                                            <div className="p-md-4 p-sm-12">
                                                <div className="totalInfo-details">
                                                    <h1>Teacher Info</h1>
                                                    <h4>Total Teacher : {districtWiseInfo && districtWiseInfo.totalTeacher ? districtWiseInfo.totalTeacher.toLocaleString('EN-IN') : 0}</h4>
                                                    <h4>Total Institute  : {districtWiseInfo && districtWiseInfo.totalInstitute ? districtWiseInfo.totalInstitute.toLocaleString('EN-IN') : 0}</h4>
                                                </div>
                                            </div>
                                            <div className="p-md-3 p-sm-12">
                                                <div className="totalInfo-details">
                                                    <h1>Attendance Info</h1>
                                                    <h4 className="dot-1">Total  Present : {districtWiseInfo && districtWiseInfo.totalPresent ? districtWiseInfo.totalPresent.toLocaleString('EN-IN') : 0}</h4>
                                                    <h4 className="dot-2">Total Absent  : {districtWiseInfo && districtWiseInfo.totalAbsent ? districtWiseInfo.totalAbsent.toLocaleString('EN-IN') : 0}</h4>
                                                    <h4 className="dot-3">Total Leave  : {districtWiseInfo && districtWiseInfo.totalLeave ? districtWiseInfo.totalLeave.toLocaleString('EN-IN') : 0}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                    {userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10203' ? 
                      <div className="totalInfo-wrapper">
                                        <div className="p-grid">
                                            <div className="p-md-5 p-sm-12">
                                                <div className="totalInfo-details">
                                                    <h1>{headerNames} of Bangladesh</h1>
                                                    <h4>Total {subHeaderName} : {upazillaWiseInfo && upazillaWiseInfo.totalId ? upazillaWiseInfo.totalId : 0}</h4>
                                                    <h4>Total Institute  : {upazillaWiseInfo && upazillaWiseInfo.totalInstitute ? upazillaWiseInfo.totalInstitute.toLocaleString('EN-IN') : 0}</h4>
                                                </div>
                                            </div>
                                            <div className="p-md-4 p-sm-12">
                                                <div className="totalInfo-details">
                                                    <h1>Teacher Info</h1>
                                                    <h4>Total Teacher : {upazillaWiseInfo && upazillaWiseInfo.totalTeacher ? upazillaWiseInfo.totalTeacher.toLocaleString('EN-IN') : 0}</h4>
                                                    <h4>Total Institute  : {upazillaWiseInfo && upazillaWiseInfo.totalInstitute ? upazillaWiseInfo.totalInstitute.toLocaleString('EN-IN') : 0}</h4>
                                                </div>
                                            </div>
                                            <div className="p-md-3 p-sm-12">
                                                <div className="totalInfo-details">
                                                    <h1>Attendance Info</h1>
                                                    <h4 className="dot-1">Total  Present : {upazillaWiseInfo && upazillaWiseInfo.totalPresent ? upazillaWiseInfo.totalPresent.toLocaleString('EN-IN') : 0}</h4>
                                                    <h4 className="dot-2">Total Absent  : {upazillaWiseInfo && upazillaWiseInfo.totalAbsent ? upazillaWiseInfo.totalAbsent.toLocaleString('EN-IN') : 0}</h4>
                                                    <h4 className="dot-3">Total Leave  : {upazillaWiseInfo && upazillaWiseInfo.totalLeave ? upazillaWiseInfo.totalLeave.toLocaleString('EN-IN') : 0}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                    </div>
                    <div className="map-menu-wrapper">
                      <div className="title-wrapper">
                        <div className="title-icon">
                          <i className="fas fa-map-marker-alt"></i>
                        </div>
                        <div className="title">
                          <div className="main-title">
                            <h1>Division</h1>
                          </div>
                          <div className="sub-title">
                            <h3>Wise Info</h3>
                          </div>
                        </div>
                      </div>
                      <div className="calender-wrapper">
                        <div className="nw-w-100 p-relative">
                          <Calendar
                            name="requestStartDate"
                            onChange={this.onChangeDate}
                            value={this.state.date}
                            yearRange="2010:2030"
                            dateFormat="dd/mm/yy"
                            monthNavigator={true}
                            yearNavigator={true}
                            dateOnly="true"
                            placeholder="Select Date"
                          />
                          <span className="pi pi-calendar emptyDropdown"></span>
                        </div>
                      </div>
                      <div className="radio-button-wrapper">
                        <div>
                          <RadioButton
                            inputId="rb1"
                            value="val1"
                            name="city"
                            onChange={e => this.setState({ value: e.value })}
                            checked={this.state.value === "val1"}
                          />
                          <label htmlFor="rb1" className="p-radiobutton-label">
                            Institute Info
                          </label>
                        </div>
                        <div>
                          <RadioButton
                            inputId="rb1"
                            value="val1"
                            name="city"
                            onChange={e => this.setState({ value: e.value })}
                            checked={this.state.value === "val1"}
                          />
                          <label htmlFor="rb1" className="p-radiobutton-label">
                            Teacher Info
                          </label>
                        </div>
                        <div>
                          <RadioButton
                            inputId="rb1"
                            value="val1"
                            name="city"
                            onChange={e => this.setState({ value: e.value })}
                            checked={this.state.value === "val1"}
                          />
                          <label htmlFor="rb1" className="p-radiobutton-label">
                            Attendance Info
                          </label>
                        </div>
                      </div>
                      <div className="attendence-filters">
                        <div className="custom-inputswitch">
                          <span className="color-name-1"></span>
                          <span className="attendaence-cat">Total Present</span>
                          <InputSwitch
                            checked={this.state.checked1}
                            onChange={e => this.setState({ checked1: e.value })}
                          />
                        </div>
                        <div className="custom-inputswitch">
                          <span className="color-name-2"></span>
                          <span className="attendaence-cat">Total Absent</span>
                          <InputSwitch
                            checked={this.state.checked1}
                            onChange={e => this.setState({ checked1: e.value })}
                          />
                        </div>
                        <div className="custom-inputswitch">
                          <span className="color-name-3"></span>
                          <span className="attendaence-cat">Total Leave</span>
                          <InputSwitch
                            checked={this.state.checked1}
                            onChange={e => this.setState({ checked1: e.value })}
                          />
                        </div>
                      </div>
                     
                      <div className="division-wrapper">
                              <ul className="division-list">
                            
                          {userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10201' && dataTableValue && dataTableValue.details ? 
                          dataTableValue.details.map(item => {
                            return (
                                <li className="active">
                                  <h1>{item.name}</h1>
                                  <h3>{`${item.totalInstitute} institutes`}</h3>
                                </li>
                            );
                          })
                          : 
                          staticDivisionList}
                              </ul>
                      </div>
                    </div>
                  </div>
                   {/* userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10202' && districtWiseInfo && districtWiseInfo.details ? 
                          districtWiseInfo.details.map(item => {
                            return (
                                <li className="active">
                                  <h1>{item.name}</h1>
                                  <h3>{`${item.totalInstitute} institutes`}</h3>
                                </li>
                            );
                          }) */}
                      
                           {/* userType && userType.areaCoreCategoryInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO && userType.areaCoreCategoryInfoDTO.parentTypeInfoDTO.categoryDefaultCode === 'T10203' && upazillaWiseInfo && upazillaWiseInfo.details ? 
                          upazillaWiseInfo.details.map(item => {
                            return (
                                <li className="active">
                                  <h1>{item.name}</h1>
                                  <h3>{`${item.totalInstitute} institutes`}</h3>
                                </li>
                            );
                          }) */}
                  {this.state.divisionDatatableInfoView === true ? 
                  <div className="p-col-12">
                 <div className="nw-data-table nw-attendence-data-table">
                   <DataTable
                     header={divisionTableHeaderName}
                     responsive={true}
                     value={dataTableValue.details}
                     footerColumnGroup={footerForTeacherAttendance}
                   >
                     <Column
                       field=""
                       header='Division Name'
                       body={this.getDivisionName}
                       style={{ minWidth: "114px" }}

                     />
                     <Column
                       field=""
                       header="Total Institute"
                       body={this.getTotalInstitute}
                     />
                     <Column
                       field=""
                       header="Total Teacher"
                       body={this.getTotalTeacherData}
                     />
                     <Column
                       field=""
                       header="Present"
                       body={this.getTotalPresentData}
                     />
                     <Column
                       field=""
                       header="Absent"
                       body={this.getTotalAbsentData}
                     />
                     <Column
                       field=""
                       header="Leave"
                       body={this.getTotalLeaveData}
                     />
                   </DataTable>
                 </div>
               </div>
               : ''}
                  </>
                  : ''}
                   {this.state.viewDistrictChartInfo === true ? (
          <div className="main-section crm-dashboard">
            <div className="p-grid nw-form">
              <div className="p-col-12 p-xl-12">
              </div>
              <div className="p-col-12 dashboard-card-view">
                <Card
                  header="District Wise Institute Info"
                  className="bg-primary"
                >
                  <div className="p-grid p-col-nogutter">
                    <div className="p-col-12 p-xl-4">
                      <div className="info-box-wrapper not-hover">
                        <div className="chart-box">
                          <Chart
                            width={226}
                            height={226}
                            chartType="PieChart"
                            loader={<div> Loading Chart </div>}
                            data={districtWiseInstituteInfoFinalArr}
                            options={{
                              chartArea: {
                                left: 10,
                                top: 10,
                                right: 10,
                                bottom: 10,
                              },
                              backgroundColor: "transparent",
                              legend: "none",
                              slices: AttendancerChartsColorArray,
                            }}
                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                            render={({ renderChart }) => {
                              return <div> {renderChart()} </div>;
                            }}
                          />{" "}
                        </div>{" "}
                        <span className="chart-box-title">
                          Total Institute{" "}
                        </span>{" "}
                        <br />
                        <span className="chart-box-title">
                          {" "}
                          {districtWiseInfo && districtWiseInfo.totalInstitute
                            ? districtWiseInfo.totalInstitute.toLocaleString(
                                "EN-IN"
                              )
                            : 0}{" "}
                        </span>{" "}
                      </div>{" "}
                    </div>
                    <div className="p-col-12 p-xl-8">
                      <div className="netiWorld-DashBoard-wrapper">
                        <div className="chart-box-wrapper">
                          <div className="point-list">
                            <div className="p-grid p-col-12">
                              {" "}
                              {districtWiseInfo &&
                                districtWiseInfo.details &&
                                districtWiseInfo.details.map((item, index) => {
                                  return (
                                    <div className="p-col-12 p-xl-6 chart-text-view">
                                      <div className="text-view-wrapper">
                                        <span
                                          className="task-badge"
                                          style={{
                                            background: AttendancerChartsColorArray[
                                              index
                                            ]
                                              ? AttendancerChartsColorArray[
                                                  index
                                                ].color
                                              : "gray",
                                          }}
                                        ></span>{" "}
                                        <span className="title">
                                          {" "}
                                          {item.name}{" "}
                                        </span>{" "}
                                        -{" "}
                                        <span>
                                          {" "}
                                          {item.totalInstitute}{" "}
                                          {`(${item.totalInstitutePercent} %)`}{" "}
                                        </span>{" "}
                                      </div>{" "}
                                    </div>
                                  );
                                })}{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </Card>{" "}
              </div>
              <div className="p-col-12 dashboard-card-view">
                <Card
                  header="District Wise Teacher Info"
                  className="bg-primary"
                >
                  <div className="p-grid p-col-nogutter">
                    <div className="p-col-12 p-xl-4">
                      <div className="info-box-wrapper not-hover">
                        <div className="chart-box">
                          <Chart
                            width={226}
                            height={226}
                            chartType="PieChart"
                            loader={<div> Loading Chart </div>}
                            data={districtWiseTeacherInfoFinalArr}
                            options={{
                              chartArea: {
                                left: 10,
                                top: 10,
                                right: 10,
                                bottom: 10,
                              },
                              backgroundColor: "transparent",
                              legend: "none",
                              slices: AttendancerChartsColorArray,
                            }}
                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                            render={({ renderChart }) => {
                              return <div> {renderChart()} </div>;
                            }}
                          />{" "}
                        </div>{" "}
                        <span className="chart-box-title">Total Teacher </span>{" "}
                        <br />
                        <span className="chart-box-title">
                          {" "}
                          {districtWiseInfo && districtWiseInfo.totalTeacher
                            ? districtWiseInfo.totalTeacher.toLocaleString(
                                "EN-IN"
                              )
                            : 0}{" "}
                        </span>{" "}
                      </div>{" "}
                    </div>
                    <div className="p-col-12 p-xl-8">
                      <div className="netiWorld-DashBoard-wrapper">
                        <div className="chart-box-wrapper">
                          <div className="point-list">
                            <div className="p-grid p-col-12">
                              {" "}
                              {districtWiseInfo &&
                                districtWiseInfo.details &&
                                districtWiseInfo.details.map((item, index) => {
                                  return (
                                    <div className="p-col-12 p-xl-6 chart-text-view">
                                      <div className="text-view-wrapper">
                                        <span
                                          className="task-badge"
                                          style={{
                                            background: AttendancerChartsColorArray[
                                              index
                                            ]
                                              ? AttendancerChartsColorArray[
                                                  index
                                                ].color
                                              : "gray",
                                          }}
                                        ></span>{" "}
                                        <span className="title">
                                          {" "}
                                          {item.name}{" "}
                                        </span>{" "}
                                        -{" "}
                                        <span>
                                          {" "}
                                          {item.totalTeacher}{" "}
                                          {`(${item.totalTeacherPercent} %)`}{" "}
                                        </span>{" "}
                                      </div>{" "}
                                    </div>
                                  );
                                })}{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </Card>{" "}
              </div>
              <div className="p-col-12 dashboard-card-view">
                <Card
                  header="District Wise Present Info"
                  className="bg-primary"
                >
                  <div className="p-grid p-col-nogutter">
                    <div className="p-col-12 p-xl-4">
                      <div className="info-box-wrapper not-hover">
                        <div className="chart-box">
                          <Chart
                            width={226}
                            height={226}
                            chartType="PieChart"
                            loader={<div> Loading Chart </div>}
                            data={districtWisePresentInfoFinalArr}
                            options={{
                              chartArea: {
                                left: 10,
                                top: 10,
                                right: 10,
                                bottom: 10,
                              },
                              backgroundColor: "transparent",
                              legend: "none",
                              slices: AttendancerChartsColorArray,
                            }}
                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                            render={({ renderChart }) => {
                              return <div> {renderChart()} </div>;
                            }}
                          />{" "}
                        </div>{" "}
                        <span className="chart-box-title">Total Present </span>{" "}
                        <br />
                        <span className="chart-box-title">
                          {" "}
                          {districtWiseInfo &&
                          districtWiseInfo.totalPresentPercent
                            ? `${districtWiseInfo.totalPresentPercent}%`
                            : 0}{" "}
                        </span>{" "}
                      </div>{" "}
                    </div>
                    <div className="p-col-12 p-xl-8">
                      <div className="netiWorld-DashBoard-wrapper">
                        <div className="chart-box-wrapper">
                          <div className="point-list">
                            <div className="p-grid p-col-12">
                              {" "}
                              {districtWiseInfo &&
                                districtWiseInfo.details &&
                                districtWiseInfo.details.map((item, index) => {
                                  return (
                                    <div className="p-col-12 p-xl-6 chart-text-view">
                                      <div className="text-view-wrapper">
                                        <span
                                          className="task-badge"
                                          style={{
                                            background: AttendancerChartsColorArray[
                                              index
                                            ]
                                              ? AttendancerChartsColorArray[
                                                  index
                                                ].color
                                              : "gray",
                                          }}
                                        ></span>{" "}
                                        <span className="title">
                                          {" "}
                                          {item.name}{" "}
                                        </span>{" "}
                                        -{" "}
                                        <span>
                                          {" "}
                                          {item.totalPresent}{" "}
                                          {`(${item.totalPresentPercent} %)`}{" "}
                                        </span>{" "}
                                      </div>{" "}
                                    </div>
                                  );
                                })}{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </Card>{" "}
              </div>
              <div className="p-col-12 dashboard-card-view">
                <Card header="District Wise Absent Info" className="bg-primary">
                  <div className="p-grid p-col-nogutter">
                    <div className="p-col-12 p-xl-4">
                      <div className="info-box-wrapper not-hover">
                        <div className="chart-box">
                          <Chart
                            width={226}
                            height={226}
                            chartType="PieChart"
                            loader={<div> Loading Chart </div>}
                            data={districtWiseAbsentInfoFinalArr}
                            options={{
                              chartArea: {
                                left: 10,
                                top: 10,
                                right: 10,
                                bottom: 10,
                              },
                              backgroundColor: "transparent",
                              legend: "none",
                              slices: AttendancerChartsColorArray,
                            }}
                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                            render={({ renderChart }) => {
                              return <div> {renderChart()} </div>;
                            }}
                          />{" "}
                        </div>{" "}
                        <span className="chart-box-title">Total Absent </span>{" "}
                        <br />
                        <span className="chart-box-title">
                          {" "}
                          {districtWiseInfo &&
                          districtWiseInfo.totalAbsentPercent
                            ? `${districtWiseInfo.totalAbsentPercent}%`
                            : 0}{" "}
                        </span>{" "}
                      </div>{" "}
                    </div>
                    <div className="p-col-12 p-xl-8">
                      <div className="netiWorld-DashBoard-wrapper">
                        <div className="chart-box-wrapper">
                          <div className="point-list">
                            <div className="p-grid p-col-12">
                              {" "}
                              {districtWiseInfo &&
                                districtWiseInfo.details &&
                                districtWiseInfo.details.map((item, index) => {
                                  return (
                                    <div className="p-col-12 p-xl-6 chart-text-view">
                                      <div className="text-view-wrapper">
                                        <span
                                          className="task-badge"
                                          style={{
                                            background: AttendancerChartsColorArray[
                                              index
                                            ]
                                              ? AttendancerChartsColorArray[
                                                  index
                                                ].color
                                              : "gray",
                                          }}
                                        ></span>{" "}
                                        <span className="title">
                                          {" "}
                                          {item.name}{" "}
                                        </span>{" "}
                                        -{" "}
                                        <span>
                                          {" "}
                                          {item.totalAbsent}{" "}
                                          {`(${item.totalAbsentPercent} %)`}{" "}
                                        </span>{" "}
                                      </div>{" "}
                                    </div>
                                  );
                                })}{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </Card>{" "}
              </div>
              <div className="p-col-12 dashboard-card-view">
                <Card header="District Wise Leave Info" className="bg-primary">
                  <div className="p-grid p-col-nogutter">
                    <div className="p-col-12 p-xl-4">
                      <div className="info-box-wrapper not-hover">
                        <div className="chart-box">
                          <Chart
                            width={226}
                            height={226}
                            chartType="PieChart"
                            loader={<div> Loading Chart </div>}
                            data={districtWiseLeaveInfoFinalArr}
                            options={{
                              chartArea: {
                                left: 10,
                                top: 10,
                                right: 10,
                                bottom: 10,
                              },
                              backgroundColor: "transparent",
                              legend: "none",
                              slices: AttendancerChartsColorArray,
                            }}
                            // chartWrapperParams={{ view: { columns: [0, 3] } }}
                            render={({ renderChart }) => {
                              return <div> {renderChart()} </div>;
                            }}
                          />{" "}
                        </div>{" "}
                        <span className="chart-box-title"> Total Leave </span>{" "}
                        <br />
                        <span className="chart-box-title">
                          {" "}
                          {districtWiseInfo &&
                          districtWiseInfo.totalLeavePercent
                            ? `${districtWiseInfo.totalLeavePercent}%`
                            : 0}{" "}
                        </span>{" "}
                      </div>{" "}
                    </div>
                    <div className="p-col-12 p-xl-8">
                      <div className="netiWorld-DashBoard-wrapper">
                        <div className="chart-box-wrapper">
                          <div className="point-list">
                            <div className="p-grid p-col-12">
                              {" "}
                              {districtWiseInfo &&
                                districtWiseInfo.details &&
                                districtWiseInfo.details.map((item, index) => {
                                  return (
                                    <div className="p-col-12 p-xl-6 chart-text-view">
                                      <div className="text-view-wrapper">
                                        <span
                                          className="task-badge"
                                          style={{
                                            background: AttendancerChartsColorArray[
                                              index
                                            ]
                                              ? AttendancerChartsColorArray[
                                                  index
                                                ].color
                                              : "gray",
                                          }}
                                        ></span>{" "}
                                        <span className="title">
                                          {" "}
                                          {item.name}{" "}
                                        </span>{" "}
                                        -{" "}
                                        <span>
                                          {" "}
                                          {item.totalLeave}{" "}
                                          {`(${item.totalLeavePercent} %)`}{" "}
                                        </span>{" "}
                                      </div>{" "}
                                    </div>
                                  );
                                })}{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </Card>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        ) : (
          ""
        )}
        {this.state.viewDistrictDatatableInfo === true ? (
          <div className="p-col-12 p-xl-12">
          <div className="nw-data-table nw-attendence-data-table">
                   <DataTable
                     header={districtTableHeaderName}
                     responsive={true}
                     value={districtWiseInfo.details}
                     footerColumnGroup={districtWisefooterForTeacherAttendance}
                   >
                     <Column
                       field=""
                       header='District Name'
                       body={this.getDistrictName}
                       style={{ minWidth: "114px" }}

                     />
                     <Column
                       field=""
                       header="Total Institute"
                       body={this.getDistrictTotalInstitute}
                     />
                     <Column
                       field=""
                       header="Total Teacher"
                       body={this.getDistrictTotalTeacherData}
                     />
                     <Column
                       field=""
                       header="Present"
                       body={this.getDistrictTotalPresentData}
                     />
                     <Column
                       field=""
                       header="Absent"
                       body={this.getDistrictTotalAbsentData}
                     />
                     <Column
                       field=""
                       header="Leave"
                       body={this.getDistrictTotalLeaveData}
                     />
                   </DataTable>
                 </div>
               </div>
         
        
        ) : (
          ""
        )}

        {this.state.viewUpazillaDatatableInfo === true ? (
          <div className="p-col-12 p-xl-12">
          <div className="nw-data-table nw-attendence-data-table">
                   <DataTable
                     header={upazillaTableHeaderName}
                     responsive={true}
                     value={upazillaWiseInfo.details}
                     footerColumnGroup={upazillaWisefooterForTeacherAttendance}
                   >
                     <Column
                       field=""
                       header='Upazila Name'
                       body={this.getUpazillaName}
                       style={{ minWidth: "114px" }}

                     />
                     <Column
                       field=""
                       header="Total Institute"
                       body={this.getUpazillaTotalInstitute}
                     />
                     <Column
                       field=""
                       header="Total Teacher"
                       body={this.getUpazillaTotalTeacherData}
                     />
                     <Column
                       field=""
                       header="Present"
                       body={this.getUpazillaTotalPresentData}
                     />
                     <Column
                       field=""
                       header="Absent"
                       body={this.getUpazillaTotalAbsentData}
                     />
                     <Column
                       field=""
                       header="Leave"
                       body={this.getUpazillaTotalLeaveData}
                     />
                   </DataTable>
                 </div>
               </div>
         
        
        ) : (
          ""
        )}

        {this.state.viewInstitutesDatatableInfo === true ? (
          <div className="p-col-12 p-xl-12">
          <div className="nw-data-table nw-attendence-data-table">
          <DataTable
                     header={instituteTableHeaderName}
                     value={instituteWiseInfo.details}
                     responsive={true}
                     footerColumnGroup={instituteWisefooterForTeacherAttendance}
                   >

<Column
                       field=""
                       header="Institute Name"
                       body={this.getInstituteName}
                     />
                     <Column
                       field=""
                       header="Institute Type"
                       body={this.getInstituteType}
                      //  style={{ minWidth: "114px" }}
                     />
                     <Column
                       field=""
                       header="Total Teacher"
                       body={this.getInstituteTotalTeacherData}
                     />
                     <Column
                       field=""
                       header="Present"
                       body={this.getInstituteTotalPresentData}
                     />
                     <Column
                       field=""
                       header="Absent"
                       body={this.getInstituteTotalAbsentData}
                     />
                     <Column
                       field=""
                       header="Leave"
                       body={this.getInstituteTotalLeaveData}
                     />

<Column
                       field=""
                       header="View Details"
                       style={{ width: "100px" }}
                       body={this.actionBodyTemplate}
                     />
                   </DataTable>
                 </div>
               </div>
         
        
        ) : (
          ""
        )}

        {this.state.viewSingleInstituteDetails === true ? (
          <div className="p-col-12 p-xl-12">
            <InstituteWiseAttendance
              teacherAttendanceInfoFinalArr={teacherAttendanceInfoFinalArr}
              totalAttendanceInfoFinalArr={totalAttendanceInfoFinalArr}
              singleInstituteDetails={this.state.singleInstituteInfo}

              hideSingleInstituteAttendanceInfoView={this.hideSingleInstituteAttendanceInfoView}
              showInstituteWiseAttendanceInfoView={this.showInstituteWiseAttendanceInfoView}
            />{" "}
          </div>
        ) : (
          ""
        )}
              
                  
             
                </div>
              </div>
            </div>
          </div>
        );
    }
}