import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from "primereact/calendar";
import { ProfileService } from '../../../service/profile/ProfileService';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';



export class PersonalReferenceInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userReferenceInfoObj: [],
            updateUserObj:{
                relationInfoDTO:{}
            },
            insertUserObj:{
                relationInfoDTO:{
                    coreCategoryID: ''
                }
            },
            relationShipList: [],
            visibleDialog: false,
            visibleEditDialog: false,
            errors: {}
        }
        this.CommonFuctionality = new CommonFuctionality();
        this.ProfileService = new ProfileService();
        this.UserCategoryService = new UserCategoryService();


    }

    componentWillMount(){
        this.getUserReferenceInfo();
        this.getRelationByDefaultCode('T144');
    }

    getRelationByDefaultCode = (defaultCode) => {
        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ relationShipList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    getUserReferenceInfo = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ProfileService.fetchUserBasicInfo('reference')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ userReferenceInfoObj: body, topProgressBar: false, errorMsgVisible: false });
                        // this.getSlipFile(rowData.attachFilePath,rowData.attachFileName);
                    })
                }
                else {
                    this.ProfileService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeDialogHide = () => {
        this.setState({ visibleDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showDialog = () => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visibleDialog: true, errors: {} });
    }

    onChangeEditDialogHide = () => {
        this.setState({ visibleEditDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showEditDialog = (itemData) => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ updateUserObj: {...itemData}, visibleEditDialog: true, errors: {} });
    }

    onChageReferenceName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.referenceName = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.referenceName = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageReferenceDesignation = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.referenceDesignation = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.referenceDesignation = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageOrgainizationName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.orgainizationName = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.orgainizationName = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageReferenceMobile = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.referenceMobile = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.referenceMobile = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageReferenceEmail = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.referenceEmail = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.referenceEmail = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageReferenceAddress = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.referenceAddress = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.referenceAddress = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChageReferenceRelation = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.relationInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.relationInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }





    handleError = (objectHandle) => {
        let { errors } = this.state;
        let formIsValid = true;

        if (!objectHandle.referenceName) {
            formIsValid = false;
            errors["referenceName"] = "Reference Name can't left empty.";
        }

        if (!objectHandle.referenceDesignation) {
            formIsValid = false;
            errors["referenceDesignation"] = "Reference Designation can't left empty.";
        }
        
        if (!objectHandle.referenceAddress) {
            formIsValid = false;
            errors["referenceAddress"] = "Reference Address can't left empty.";
        }

        if (!objectHandle.orgainizationName) {
            formIsValid = false;
            errors["orgainizationName"] = "Reference Name can't left empty.";
        }

        if (!objectHandle.referenceMobile) {
            formIsValid = false;
            errors["referenceMobile"] = "Reference Mobile No. can't left empty.";
        }

        if (!objectHandle.referenceEmail) {
            formIsValid = false;
            errors["referenceEmail"] = "Reference Email can't left empty.";
        }

        if (!objectHandle.referenceAddress) {
            formIsValid = false;
            errors["referenceAddress"] = "Reference Address can't left empty.";
        }

        if (!objectHandle.relationInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["referenceRelation"] = "Reference Relation can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onUpdateUserReferenceInfo = () => {
        let { updateUserObj } = this.state;

        if (this.handleError(updateUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
            this.ProfileService.updateUserBasicInfo( updateUserObj, 'reference')
                .then(res => {
                    if (res.status == 202) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                        this.onChangeEditDialogHide();
                        setTimeout(() => {
                            this.getUserReferenceInfo();
                        }, 1000);
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' })
                });

        }
    }

    onInsertUserReferenceInfo = () => {
        let { insertUserObj } = this.state;

        if (this.handleError(insertUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
            this.ProfileService.insertUserBasicInfo( insertUserObj, 'reference')
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                        this.onChangeDialogHide();
                        setTimeout(() => {
                            this.getUserReferenceInfo();
                        }, 1000);
                        
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' })
                });

        }
    }

    render() {
        let { userReferenceInfoObj, updateUserObj, insertUserObj, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let uploadFileLabel = <div>Click / Drag Here To Upload</div>

        let relationShip = []
        if (this.state.relationShipList && this.state.relationShipList.length) {
            relationShip = this.state.relationShipList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
        return (
            <div>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section blur-section">
                    <div className="p-grid">
                        {
                            userReferenceInfoObj.map((item, index) =>
                                <div className="p-col-12 p-md-6">
                                    <div className="personal-box-wrapper m-t-30">
                                        <div className="personal-box-title-wrapper">
                                            <div className="personal-box-title">
                                                <h4>Reference - {index + 1}</h4>
                                            </div>
                                            <div className="edit-button">
                                                <Button icon="fas fa-edit" onClick={e => this.showEditDialog(item)} />
                                            </div>
                                        </div>
                                        <div className="personal-box">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>{ item.referenceName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Designation</td>
                                                        <td>{ item.referenceDesignation || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Organization</td>
                                                        <td>{ item.orgainizationName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mobile</td>
                                                        <td>{ item.referenceMobile || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Address</td>
                                                        <td>{ item.referenceAddress || '---' }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="add-button">
                        <Button
                            icon="fas fa-plus"
                            tooltip="Add Reference Info"
                            tooltipOptions={{ position: 'bottom' }}
                            onClick={this.showDialog}
                        />
                    </div>
                </div>
                <div className="dialog-section">
                    <Dialog id="dialog" className="nw-dialog" header="Add Reference" visible={this.state.visibleDialog} onHide={this.onChangeDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Name<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Name"
                                                    name="referenceName"
                                                    value={ insertUserObj.referenceName }
                                                    onChange= { e => this.onChageReferenceName(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Designation<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Designation"
                                                    name="referenceDesignation"
                                                    value={ insertUserObj.referenceDesignation }
                                                    onChange= { e => this.onChageReferenceDesignation(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceDesignation']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Organization<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Organization"
                                                    name="orgainizationName"
                                                    value={ insertUserObj.orgainizationName }
                                                    onChange= { e => this.onChageOrgainizationName(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['orgainizationName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Mobile <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Mobile No."
                                                    name="referenceMobile"
                                                    value={ insertUserObj.referenceMobile }
                                                    onChange= { e => this.onChageReferenceMobile(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceMobile']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Email <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Email"
                                                    name="referenceEmail"
                                                    value={ insertUserObj.referenceEmail }
                                                    onChange= { e => this.onChageReferenceEmail(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceEmail']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Address  <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Address"
                                                    name="referenceAddress"
                                                    value={ insertUserObj.referenceAddress }
                                                    onChange= { e => this.onChageReferenceAddress(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceAddress']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Relation <span>*</span></span>
                                                {/* <InputText
                                                    placeholder="Enter Relation"
                                                    name="referenceRelation"
                                                    value={ insertUserObj.relationInfoDTO.categoryName }
                                                    onChange= { e => this.onChageReferenceRelation(e, 'insert') }
                                                    autoWidth={false}
                                                /> */}

                                                <Dropdown
                                                    placeholder="Enter Relation"
                                                    onChange= { e => this.onChageReferenceRelation(e, 'insert') }
                                                    value={ insertUserObj.relationInfoDTO.coreCategoryID }
                                                    options={relationShip}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="referenceRelation"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceRelation']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={this.onInsertUserReferenceInfo}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog id="dialog" className="nw-dialog" header="Edit Reference" visible={this.state.visibleEditDialog} onHide={this.onChangeEditDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Name<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Name"
                                                    name="referenceName"
                                                    value={ updateUserObj.referenceName }
                                                    onChange= { e => this.onChageReferenceName(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Designation<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Designation"
                                                    name="referenceDesignation"
                                                    value={ updateUserObj.referenceDesignation }
                                                    onChange= { e => this.onChageReferenceDesignation(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceDesignation']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Organization<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Organization"
                                                    name="orgainizationName"
                                                    value={ updateUserObj.orgainizationName }
                                                    onChange= { e => this.onChageOrgainizationName(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['orgainizationName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Mobile No. <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Mobile No."
                                                    name="referenceMobile"
                                                    value={ updateUserObj.referenceMobile }
                                                    onChange= { e => this.onChageReferenceMobile(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceMobile']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Email <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Email"
                                                    name="referenceEmail"
                                                    value={ updateUserObj.referenceEmail }
                                                    onChange= { e => this.onChageReferenceEmail(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceEmail']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Address  <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Address"
                                                    name="referenceAddress"
                                                    value={ updateUserObj.referenceAddress }
                                                    onChange= { e => this.onChageReferenceAddress(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceAddress']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Relation <span>*</span></span>
                                                {/* <InputText
                                                    placeholder="Enter Relation"
                                                    name="referenceRelation"
                                                    value={ updateUserObj.relationInfoDTO.categoryName }
                                                    onChange= { e => this.onChageReferenceRelation(e, 'update') }
                                                    autoWidth={false}
                                                /> */}

                                                <Dropdown
                                                    placeholder="Enter Relation"
                                                    onChange={e => this.onChageReferenceRelation(e, 'update')}
                                                    value={updateUserObj.relationInfoDTO.coreCategoryID}
                                                    options={relationShip}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="referenceRelation"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['referenceRelation']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={ this.onUpdateUserReferenceInfo }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </Dialog>
                </div>
            </div>
        )
    }
} 