export default class Formatter {
    getMaskedNumber(data) {
        data = data.toString();
        let length = data.length;
        return data.substring(length - 11, length - 8) + "*****" + data.substring(length - 3)

    }

    formatContactNo = data => {
        if (data.toString().length > 11) {
            return data.replace('88', '');
        }
        else {
            return data;
        }
    }

    //get rounded decimal number
    getFixedDecimalNumber = data => {
        if(data === NaN){
            return 0;
        } else if(data === undefined){
            return 0;
        }
        else{
            if (parseFloat(data) > parseInt(data)) {
                if(data.toString().indexOf('.') >= 0) {
                    let num = data.toString();
                    return Math.floor(num) + (num.substr(num.indexOf('.') + 3, 1) < 5 ? num.substr(num.indexOf('.'), 3) : '.' + (+num.substr((num.indexOf('.') + 1), 2) + 1));
                } 
            }
            else {
                return data;
            }

        }
       
    }
}

