import React, { Component } from 'react';
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { UserCategoryService } from '../../service/UserCategoryService';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';

import { AdminPointService } from '../../service/adminPoint/AdminPointService';
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from "../common/ErrorMessageView";
import CommonFuctionality from "../common/CommonFuctionality";
import { Calendar } from 'primereact/calendar';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';

let maxDate = new Date();
export class DFPSSettlementPayment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            settlementByList: [],
            buttonDisabled: true,
            searchUserDataIsLoading: false,
            updateErrors: {},
            settlementBankName: '',
            date: '',
            settlementAmount: '',
            settlementBy: '',
            note: '',
            topProgressBar: false,
            dataTableIsLoading: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            instituteBasicInfo: {}
        }
        this.userCategoryService = new UserCategoryService();
        this.userOtherAccessService = new UserOtherAccessService();
        this.adminPointService = new AdminPointService();
        this.CRMPointService = new CRMPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();

    }
    componentDidMount() {
        this.getBankListInfo();
        this.getDFPSpaymentAmountList();
        this.getSettlementByList();
    }

    getBankListInfo() {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.userOtherAccessService.findBankAccountByUser()
        .then(res => {
                if (res.status === 200) {
                    return res.json().then((body) => {
                        this.setState({ bankList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    getSettlementByList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode('T127')
        .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        this.setState({ settlementByList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    getDFPSpaymentAmountList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.adminPointService.findDFPSpaymentReportList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].settlementDate == null) {
                                body[i].settlementDate = '';
                            } else {
                                body[i].settlementDate = NetiDateUtils.getAnyShortForm(body[i].settlementDate, 'DD-MMM-YYYY');
                            }
                        }

                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    });
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    handleSave = () => {
        let {instituteBasicInfo} = this.state;
        let requestedObject = {
            "settlementDate": this.state.date,
            "note": this.state.note,
            "edumanDetailsInfoDTO": {
                "emDetailsId": instituteBasicInfo && instituteBasicInfo.edumanDetailsInfoDTO && instituteBasicInfo.edumanDetailsInfoDTO.emDetailsId,
            },
            "instituteBankAccountInfoDTO": {
                "userBankAccId": instituteBasicInfo && instituteBasicInfo.userBankAccountTaggingDTO && instituteBasicInfo.userBankAccountTaggingDTO.userBankAccountInfoDTO && instituteBasicInfo.userBankAccountTaggingDTO.userBankAccountInfoDTO.userBankAccId,
            },
            "settlementByCoreCategoryInfoDTO": {
                "coreCategoryID": this.state.settlementBy,
            },
            "settlementAmount": this.state.settlementAmount
        }
        if (this.handleSaveError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' })
            this.adminPointService.saveDFPSpaymentForInstitute(requestedObject)
                .then(res => {
                    if (res.status === 201) {
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully Saved"
                        });
                        this.getDFPSpaymentAmountList();
                        this.setState({ searchView: false,instituteBasicInfo: {},settlementAmount: '', date: '', settlementBy: '', note: '', instituteID: '', settlementBankName: '' });
                        this.setState({ topProgressBar: false, errorMsgVisible: false })
                    }
                    else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                );
        }
    };

    updateDialogDiscard = () => {
        this.onHide();
    }

    handleSaveError() {
        let { errors } = this.state;
        let formIsValid = true;

        // if (this.state.instituteID === "") {
        //     formIsValid = false;
        //     errors["instituteID"] = "Institute ID can't left empty";
        // }
        if (this.state.date === "") {
            formIsValid = false;
            errors["date"] = "Date can't left empty";
        }
        if (this.state.settlementAmount === "") {
            formIsValid = false;
            errors["settlementAmount"] = "Settlement Amount can't left empty";
        }
        // if (this.state.settlementBankName === "") {
        //     formIsValid = false;
        //     errors["settlementBankName"] = "Bank Name can't left empty";
        // }
        if (this.state.settlementBy === "") {
            formIsValid = false;
            errors["settlementBy"] = "Settlement By can't left empty";
        }
        this.setState({ errors });
        return formIsValid;
    }


    onChangeDate = (e) => {
        let { errors } = this.state;
        errors["date"] = "";
        this.setState({
            date: e.target.value,
            errors
        })
    }

    onSearchInstituteID = () => {
        let { instituteBasicInfo } = this.state;
        
        if (this.onSearchInstituteIDErrorHandle()) {
            this.setState({ instituteBasicInfo, buttonDisabled: true });
            this.setState({ topProgressBar: true, searchView: true, searchViewError: false, searchUserDataIsLoading: true, errorMsgVisible: false });
            this.CRMPointService.getDFPSsettlementBankInfoByInstituteID(this.state.instituteID)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            this.setState({ buttonDisabled: false, instituteBasicInfo: body, topProgressBar: false, searchUserDataIsLoading: false, errorMsgVisible: false });
                        });
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({buttonDisabled: true, searchViewError: true, searchViewErrorMsg: resp, topProgressBar: false, searchUserDataIsLoading: false })
                            });
                    }
                }).catch((error) => {
                    this.setState({buttonDisabled: true, topProgressBar: false, searchView: false, searchUserDataIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
        }
    }

    onSearchInstituteIDErrorHandle = () => {
        let { errors } = this.state;
        let formIsValid = true;
        this.setState({ searchView: false })

        if (!this.state.instituteID) {
            formIsValid = false;
            errors['instituteID'] = 'Institute ID can\'t left empty'
        }

        this.setState({ errors })

        return formIsValid;
    }

    focusSearch = e =>{
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
}

    blurSearch = e =>{
        this.setState({ searchLabel: '', addSearchBtnClass: ""  });
    }


    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, instituteBasicInfo,settlementByList } = this.state;

        let bankListOptions = []
        if (this.state.bankList && this.state.bankList.length) {
            bankListOptions = this.state.bankList.map((item) => ({
                value: item.coreBankAccId,
                label: item.accShortName,
            }));
        }
        let settlementByOptions = []
        if (this.state.settlementByList && this.state.settlementByList.length) {
            settlementByOptions = this.state.settlementByList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
        

        let tfoud;
        if (this.state.dataTableValue) {
            tfoud = this.state.dataTableValue.length
        }
        let headervalue = (
            <div className="p-clearfix" >
                DFPS Settlement Payment List <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {tfoud}{" "}
                </span>
            </div>
        );

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                            <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                            <InputText
                                                value={this.state.instituteID}
                                                onChange={ e => this.setState({instituteID: e.target.value, buttonDisabled: true})}
                                                placeholder="Enter Institute ID"
                                                style={{ width: "15%" }}
                                                type="text"
                                                keyfilter="pint"
                                                name="instituteID"
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                label={this.state.searchLabel}
                                                icon="fas fa-search"
                                                onClick={this.onSearchInstituteID}
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors['instituteID']}</span>
                                    </div>

                                    {this.state.searchView ?
                                        <div className="p-col-12 p-xl-12">

                                            <div className="nw-search-view">

                                                {this.state.searchViewError ?
                                                    <center className="error-message">{this.state.searchViewErrorMsg || 'No Data Found'}</center> :
                                                    <div>

                                                        {this.state.searchUserDataIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Institute Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {instituteBasicInfo && instituteBasicInfo.edumanDetailsInfoDTO && instituteBasicInfo.edumanDetailsInfoDTO.instituteName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.searchUserDataIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Institute Bank</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                    {instituteBasicInfo && instituteBasicInfo.userBankAccountTaggingDTO && instituteBasicInfo.userBankAccountTaggingDTO.userBankAccountInfoDTO && instituteBasicInfo.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO && instituteBasicInfo.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.coreBankInfoDTO && instituteBasicInfo.userBankAccountTaggingDTO.userBankAccountInfoDTO.coreBankBranchInfoDTO.coreBankInfoDTO.categoryName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                
                                                    </div>
                                                }
                                            </div>



                                        </div>
                                        :
                                        ''
                                    }


                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Date <span>*</span></span>
                                        <Calendar
                                            placeholder="Select Date"
                                            value={this.state.date}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            name="date"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            maxDate={maxDate}
                                            monthNavigator={true}
                                            showButtonBar={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                        />
                                    </div>
                                    <span className='error-message'>{this.state.errors["date"]}</span>
                                </div>


                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Settlement Amount <span>*</span></span>
                                        <InputText
                                            name="text"
                                            placeholder="Enter Settlement Amount"
                                            keyfilter="pint"
                                            name='settlementAmount'
                                            onChange={(e) => this.setState({ settlementAmount: e.target.value, errors: {} })}
                                            value={this.state.settlementAmount}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["settlementAmount"]}</span>
                                </div>

                                {/* <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                                    {this.state.firstDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Settlement Bank <span>*</span></span>
                                            <Dropdown
                                                placeholder='Select Bank'
                                                options={bankListOptions}
                                                value={this.state.settlementBankName}
                                                onChange={(e) => this.setState({ settlementBankName: e.value, errors: {} })}
                                                filter={true}
                                                name="settlementBankName"
                                                filterBy="label,value"
                                                autoWidth={false}
                                                showClear={true}
                                            />
                                        </div>
                                    }
                                    <span className="error-message">
                                        {this.state.errors["settlementBankName"]}
                                    </span>
                                </div> */}


                                <div className="p-col-12 p-xl-12">
                                    {this.state.secondDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Settlement By <span>*</span></span>
                                            <Dropdown
                                                placeholder='Select Settlement By'
                                                options={settlementByOptions}
                                                value={this.state.settlementBy}
                                                onChange={(e) => this.setState({ settlementBy: e.target.value, errors: {} })}
                                                filter={true}
                                                filterBy="label,value"
                                                name="settlementBy"
                                                autoWidth={false}
                                                showClear={true}
                                            />
                                        </div>
                                    }
                                    <span className="error-message">
                                        {this.state.errors["settlementBy"]}
                                    </span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtextarea-label">Note</span>
                                        <InputTextarea
                                            name="text"
                                            placeholder="Enter Note"
                                            name="note"
                                            onChange={(e) => this.setState({ note: e.target.value, errors: {} })}
                                            value={this.state.note}
                                        />
                                    </div>
                                    <span className="error-message">
                                        {this.state.errors["note"]}
                                    </span>

                                </div>
                                <div className="p-col-12 p-xl-12">
                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-check"
                                        label="Save"
                                        onClick={this.handleSave}
                                        disabled={this.state.buttonDisabled}
                                    />
                                </div>
                                <div className="p-col-5"> </div>
                            </div>

                        </div>
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    header={headervalue}
                                    value={this.state.dataTableValue}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                >
                                    <Column
                                        field="settlementDate"
                                        header="Date"
                                        sortable={true}
                                        filter={true}
                                    />
                                    <Column
                                        field="edumanDetailsInfoDTO.instituteId"
                                        header="Institute ID"
                                        sortable={true}
                                        filter={true}
                                    />
                                    <Column
                                        field="edumanDetailsInfoDTO.instituteName"
                                        header="Institute Name"
                                        sortable={true}
                                        filter={true}
                                    />
                                    <Column
                                        field="settlementAmount"
                                        header="Settlement Amount"
                                        sortable={true}
                                        filter={true}
                                    />
                                    <Column
                                        field="instituteBankAccountInfoDTO.coreCategoryInfoDTO.categoryName"
                                        header="Settlement Bank"
                                        sortable={true}
                                        filter={true}
                                    />
                                    <Column
                                        field="settlementByCoreCategoryInfoDTO.categoryName"
                                        header="Settlement By"
                                        sortable={true}
                                        filter={true}
                                    />
                                </DataTable>
                            }
                        </div>

                    </div>
                </div>

            </div>

        )
    }
}