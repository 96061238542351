import React, { Component } from "react";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import { EdumanGlobalInfoService } from "../../service/supportPoint/EdumanGlobalInfoService";
import NetiContentLoader from '../../../src/components/common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';

export class EdumanGlobalInfoCore extends Component {

    constructor() {
        super();

        this.state = {
            allCoreTypes: [],
            coreType: '',
            dataTableValue: [],
            typeName: '',
            defaultID: '',
            saveTypeErrors: {},
            dataTableIsLoading: false,
            firstDropdownIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
        }
        this.fetchCoreTypeList = this.fetchCoreTypeList.bind(this);
        this.fetchIndividualAllTypesByTypeId = this.fetchIndividualAllTypesByTypeId.bind(this);
        this.onChangeCoreType = this.onChangeCoreType.bind(this);

        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);

        this.onChangeTypeName = this.onChangeTypeName.bind(this);
        this.onChangeDefaultID = this.onChangeDefaultID.bind(this);
        this.saveType = this.saveType.bind(this);

        this.supportPointService = new SupportPointService();
        this.edumanGlobalInfoService = new EdumanGlobalInfoService();
        this.NetiContentLoader = new NetiContentLoader();

    }
    componentWillMount() { this.fetchCoreTypeList() }

    onChangeCoreType(value) { this.setState({ coreType: value }); this.fetchIndividualAllTypesByTypeId(value); };
    viewDialog() { this.setState({ visible: true }); }
    onHide() { this.setState({ visible: false, saveTypeErrors: {} }) }

    onChangeTypeName(value) { this.setState({ typeName: value, saveTypeErrors: {} }) };
    onChangeDefaultID(value) { this.setState({ defaultID: value, saveTypeErrors: {} }) };

    fetchCoreTypeList() {
        this.setState({ firstDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.edumanGlobalInfoService.fetchAllCoreTypes()
            .then(res => {
                if (res.status === 200) {
                    return res.json().then((body) => {
                        this.setState({ allCoreTypes: body })
                        this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    })
                } else {
                    return res.json().then((errorBody) => {
                        this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorBody.message });
                    })
                };
            }).catch(error => {
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
            });
    }

    fetchIndividualAllTypesByTypeId(typeId) {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.edumanGlobalInfoService.fetchIndividualTypesByTypeId(typeId)
            .then(res => {
                if (res.status === 200) {
                    return res.json().then((body) => {
                        this.setState({ dataTableValue: body.item })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    })
                } else {
                    return res.json().then((errorBody) => {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorBody });
                    })
                };
            }).catch(error => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
            })
    }

    saveType() {
        if (this.onSaveTypeErrorHandler()) {

            this.supportPointService.addNewTypeInEmGlobalInfo(this.state.typeName, this.state.coreType)
                .then(res => {
                    return res.json();
                })
                .then((body)=>{
                    if (body.msgType === 1) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Type added successfully.' });
                        this.setState({ visible: false });
                        this.fetchIndividualAllTypesByTypeId(this.state.coreType);
                    } else { 
                        this.growl.show({ severity: 'error', summary: 'Error', detail: body.message });
                    };
                })
        }
    }

    onSaveTypeErrorHandler = () => {
        let { saveTypeErrors } = this.state;
        let formIsValid = true;

        if (this.state.typeName === '') {
            formIsValid = false;
            saveTypeErrors["typeName"] = "Type Name can't left empty.";
        }

        this.setState({ saveTypeErrors });
        return formIsValid;
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let coreTypeOptions = [];
        if (this.state.allCoreTypes && this.state.allCoreTypes.length) {
            coreTypeOptions = this.state.allCoreTypes.map(item => ({ value: item.typeId, label: item.typeName }));
        }

        let tableHeader = '';
        let disabledStatus = true;
        if (this.state.coreType) {

            this.state.allCoreTypes.filter(type => {
                if (type.typeId === this.state.coreType) {
                    tableHeader = <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
                        {`${type.typeName} List`} <span style={{ float: "right" }}>Total Found: {this.state.dataTableValue.length}</span>
                    </div>;
                };
            });

            disabledStatus = false;
        }

        return (

            <div>
                <div className="p-fluid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} />
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-6">
                            <div className="nw-form-body">
                                <div className="p-col-12 p-xl-12">
                                    {this.state.firstDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Type</span>
                                            <Dropdown
                                                placeholder='Select Type'
                                                name="coreType"
                                                options={coreTypeOptions}
                                                value={this.state.coreType}
                                                onChange={(e) => this.onChangeCoreType(e.target.value)}
                                                filter={true}
                                                autoWidth={false}
                                            />
                                        </div>
                                    }
                                    <span style={{ color: '#c85530' }}>* First select type for add new type.</span>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label='Add Type'
                                        icon="fas fa-save"
                                        onClick={this.viewDialog}
                                        disabled={disabledStatus} />
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-6">
                            <div className="p-col-12p-xl-12">
                                {this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() :
                                    <div className="nw-data-table">
                                        <DataTable
                                            header={tableHeader}
                                            value={this.state.dataTableValue}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            rowsPerPageOptions={[10, 20, 50]}
                                        >
                                            <Column field="typeId" header="Type ID" filter={true} />
                                            <Column field="name" header='Name' filter={true} />
                                        </DataTable>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>

                <Dialog header="Eduman Global Info Add" visible={this.state.visible} className="nw-dialog" onHide={this.onHide}>
                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-form-body">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Type Name<span>*</span></span>
                                                    <InputText
                                                        placeholder='Enter Type Name'
                                                        name="typeName"
                                                        value={this.state.typeName}
                                                        onChange={(e) => this.onChangeTypeName(e.target.value)}
                                                    />
                                                </div>
                                                <span className='error-message'>{this.state.saveTypeErrors["typeName"]}</span>
                                            </div>
                                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                    label='Save'
                                                    icon="fas fa-save"
                                                    onClick={this.saveType}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

            </div>
        )
    }
}
