import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import NetiContentLoader from '../../../common/NetiContentLoader';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { Link } from 'react-router-dom';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';

// pdf
let subTitleColumn = [];
let subTitleList = [];
export class ProblemWiseToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            problemWiseTokenList: [],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            module: '',
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]

        }

        this.managementPointService = new ManagementPointService();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    componentWillMount() {
        if (this.props.location.moduleWiseTokenInfo) {

            let problemDateRange = JSON.parse(localStorage.getItem("problemDateRange"));

            let problemWiseTokenInfo = {
                "startDate": problemDateRange.startDate,
                "endDate": problemDateRange.endDate,
                "defCode": this.props.location.moduleWiseTokenInfo.defCode,
                "module": this.props.location.moduleWiseTokenInfo.module,
            }
            localStorage.setItem("problemWiseTokenInfo", JSON.stringify(problemWiseTokenInfo));
        }
        this.getProblemWiseTokenList();
    }

    getProblemWiseTokenList = () => {
        let moduleTokenInfo = JSON.parse(localStorage.getItem("problemWiseTokenInfo"));

        this.setState({
            module: moduleTokenInfo.module
        })
        if (!moduleTokenInfo) {
            return;
        }

        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.managementPointService.getApplicationModuleProblemWiseTokens(moduleTokenInfo)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ problemWiseTokenList: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    tokenBody = (rowData) => {
        return (
            <Link to={{ pathname: MANAGEMENT_POINT.TITLE_WISE_TOKEN, problemWiseTokenInfo: rowData, startDate: this.props.location.startDate, endDate: this.props.location.endDate }}>
                <u>{rowData.numberOfToken}</u>
            </Link>
        )
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Problem Title", dataKey: "problem" },
            { title: "Number of Token", dataKey: "numberOfToken" },
        ]
        let tableColStyle = {};

        pdfDownloadPortrait_TableWithSubTitle("Problem Wise Token List", 69, subTitleColumn, subTitleList, pdfColumns, this.state.problemWiseTokenList, tableColStyle, this.state.module + "_ModuleWise_Token_List.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, problemWiseTokenList, problemWiseTokenInfo } = this.state;

        // pdf reletaed task
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" }
        ];
        subTitleList = [
            { A: "Module", B: this.state.module }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = this.state.module + "_Module_Wise_Token_List.xlsx";
        let excelSheetName = this.state.module + "_Token_List";
        excelColumns = [
            <Workbook.Column value="problem" label="Problem Title" />,
            <Workbook.Column value="numberOfToken" label="Number of Token" />,
        ];

        // end excel

        let header = <div className="header-title">
            <div className="header-title-left">Problem Wise Token List of {this.state.module}</div><div className="header-title-right"><a>Total Found: {problemWiseTokenList.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        // console.log("problemWiseTokenInfo", JSON.parse(localStorage.getItem("problemWiseTokenInfo")));

        return (
            <div className="p-grid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="nw-form">

                    <div className="p-col-12 p-xl-12">

                        <div className="nw-data-table">
                            {/* <h1>Module: {this.state.module}</h1> */}
                            {/* {
                        this.state.tableView === true ?
                            this.state.dataTableIsLoading ? 
                            this.NetiContentLoader.MyPointTableLoader()
                            : */}
                            <DataTable
                                header={header}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                value={this.state.problemWiseTokenList}
                            //selection={this.state.dataTableSelection}
                            >
                                <Column field="problem" header="Problem Title" filter={true} />
                                <Column field="numberOfToken" header="Number of Token" body={this.tokenBody} filter={true} />

                            </DataTable>

                            {/* :
                            
                            <BlankDataTableAnim/> 
                    } */}
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12 nw-button-parent">

                        <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                        <div style={{ display: 'none' }}>

                            <Workbook
                                filename={excelFileName}
                                element={
                                    <Button
                                        label="Download Excel"
                                        id="clickExcelBtn"
                                        icon="fas fa-download"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                    />
                                }
                            >
                                <Workbook.Sheet data={this.state.problemWiseTokenList} name={excelSheetName}>
                                    {excelColumns}
                                </Workbook.Sheet>
                            </Workbook>

                        </div>

                    </div >
                </div>

                {/* </div> */}

            </div>
        )
    }
}