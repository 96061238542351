import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';

import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { formatToCurrency } from '../../common/JsPdfEssentialFile';
import { pdfDownloadLandscape_TableWithFooter } from '../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';

export class DailyPurchaseAmountList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            pdfExcelTableValue: [],
            searchObj: { ...this.props.location.searchObj },
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPurchasePdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }
        this.managementPointService = new ManagementPointService();
        this.ValidatorUtility = new ValidatorUtility();

        this.exportPurchasePdf = this.exportPurchasePdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);
    }

    componentWillMount() {
        this.getDailyBasisPurchaseAmountList();
    }

    getDailyBasisPurchaseAmountList = () => {
        let { searchObj } = this.state;

        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.managementPointService.fetchDailyTransectionListByType(searchObj.type, searchObj.date, searchObj.transType)
            .then(res => {

                if (res.status === 200) {
                    return res.json().then(body => {
                        this.setState({ dataTableValue: body });
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.setState({ totalPaidAmountBody: this.calculateTotalAmount(body, 'amount') });

                        //for pdf
                        let tempDataList = []; tempDataList = body;
                        tempDataList.map(item => {
                            item.netiId = item.netiId.toString();
                            item['dateTime'] = NetiDateUtils.getAnyShortForm(item['dateTime'], 'DD-MMM-YYYY') + ' ' + NetiDateUtils.getHHMMSSFForHome(item['dateTime']);
                        });
                        this.setState({ pdfExcelTableValue: tempDataList });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        })
                }

            })
            .catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    calculateTotalAmount(body, amountCategory) {
        return body.map(item => item[`${amountCategory}`]).reduce((a, b) => (a + b));
    }

    getDataTableDate = (rowData) => {
        return <div>{NetiDateUtils.getAnyShortForm(rowData.dateTime, 'DD-MMM-YYYY')} {NetiDateUtils.getHHMMSSFForHome(rowData.dateTime)}</div>
    }

    unitPriceTemplet = (rowData) => {
        return <div className="col-number text-right" >
            {this.ValidatorUtility.currencyFormatter(rowData.price)}
        </div>
    }

    discountTemplet = (rowData) => {
        return <div className="col-number text-right" >{this.ValidatorUtility.currencyFormatter(rowData.discount)}</div>
    }

    amountTemplet = (rowData) => {
        return <div className="col-number text-right" >
            {this.ValidatorUtility.currencyFormatter(rowData.amount)}
        </div>
    }

    exportPurchasePdf() {

        let pdfColumns = [
            { title: "Purchase Date & Time", dataKey: "dateTime" },
            { title: "Neti ID", dataKey: "netiId" },
            { title: "Name", dataKey: "name" },
            { title: "Purchase Product", dataKey: "productName" },
            { title: "Unit Price", dataKey: "price" },
            { title: "Quantity", dataKey: "quantity" },
            { title: "Discount", dataKey: "discount" },
            { title: "Purchase Amount", dataKey: "amount" },
        ]

        let tableColStyles = { amount: { halign: "right" }, discount: { halign: "right" }, price: { halign: "right" },}

        var totalArrayList = [
            { id: "1", totalPdf: "Total Purchase : " + formatToCurrency(this.state.totalPaidAmountBody), }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }];

        let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, fontStyle: "bold", overflow: "linebreak" },
        }

        pdfDownloadLandscape_TableWithFooter("Purchase List (Date Wise)", 112, pdfColumns, this.state.pdfExcelTableValue, tableColStyles, totalColumns, totalArrayList, totalColumnStyles, "Daily_PurchaseList_Report.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody } = this.state;

        // excel
        let excelColumns = "";
        let excelFileName = "Daily_Purchase_List_Report.xlsx";
        let excelSheetName = "Daily_Purchase_List";
        excelColumns = [
            <Workbook.Column value="dateTime" label="Purchase Date & Time" />,
            <Workbook.Column value="netiId" label="Neti ID" />,
            <Workbook.Column value="name" label="Name" />,
            <Workbook.Column value="productName" label="Purchase Product" />,
            <Workbook.Column value="price" label="Unit Price" />,
            <Workbook.Column value="quantity" label="Quantity" />,
            <Workbook.Column value="discount" label="Discount" />,
            <Workbook.Column value="amount" label="Purchase Amount" />,
        ];
        // end excel

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Purchase List (Date Wise)</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={7} />
                <Column footer={this.ValidatorUtility.currencyFormatter(totalPaidAmountBody)} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className='main-section'>
                    <div className="p-grid">
                        {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }

                        <div className="p-col-12 p-lg-12 p-xl-12">
                            <div className="nw-data-table">
                                {/* {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                                <DataTable
                                    header={tableHeader}
                                    footerColumnGroup={footer}
                                    responsive={true}
                                    rowsPerPageOptions={[10, 20, 30]}
                                    selectionMode="single"
                                    paginator={true}
                                    rows={10}
                                    value={this.state.dataTableValue}
                                //selection={this.state.dataTableSelection}
                                >
                                    <Column field="" header="Purchase Date & Time" body={this.getDataTableDate} filter={true} />
                                    {/* <Column field="invoiceId" header="Invoice ID" filter={true} /> */}
                                    <Column field="netiId" header="Neti ID" filter={true} />
                                    <Column field="name" header="Name" filter={true} />
                                    <Column field="productName" header="Purchase Product" filter={true} />
                                    <Column field="price" header="Unit Price" filter={true} body={this.unitPriceTemplet} />
                                    <Column field="quantity" header="Quantity" filter={true} />
                                    <Column field="discount" header="Discount" filter={true} body={this.discountTemplet} />
                                    <Column field="amount" header="Purchase Amount" filter={true} body={this.amountTemplet} />

                                </DataTable>

                                {/* :
                                    
                                    <BlankDataTableAnim/> 
                            } */}
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12 nw-button-parent">

                            <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                            <div style={{ display: 'none' }}>

                                <Workbook
                                    filename={excelFileName}
                                    element={
                                        <Button
                                            label="Download Excel"
                                            id="clickExcelBtn"
                                            icon="fas fa-download"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                        />
                                    }
                                >
                                    <Workbook.Sheet data={this.state.pdfExcelTableValue} name={excelSheetName}>
                                        {excelColumns}
                                    </Workbook.Sheet>
                                </Workbook>

                            </div>

                        </div >

                    </div>

                </div>
            </div>
        )
    }
}