import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { PurchaseService } from '../../../../service/myPoint/PurchaseService';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import { pdfDownloadLandscape_TableWithSubTitle } from '../../../common/JsPdfDownload';


let maxDate = new Date();
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
export class PurchaseGeneralProductViewMoreDetails extends Component {

    constructor(props) {

        super(props);
        this.state = {
            errors: {},
            purchaseCodeListTable: [],
            fromDate: '',
            toDate: '',
            visible: false,
            purchesPoint: null,
            productType: null,
            productName: null,
            productQuantity: null,
            productPurchaseID: '',
            dataTableValue: [],
            errorMsgVisible: false,
            topProgressBar: false,
            tableView: false,
            dataTableIsLoading: false,
            dialogDataTableIsLoading: false,
            errorMsgBody: '',
        };

        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.purchaseCodeBody = this.purchaseCodeBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);
        this.PurchaseService = new PurchaseService();
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
    }



    onSubmitHandler = (e) => {

        if (this.searchHandleError()) {
            let fromDate = NetiDateUtils.getDateFromString(this.state.fromDate);
            let toDate = NetiDateUtils.getDateFromString(this.state.toDate);

            if (Date.parse(this.state.toDate) >= Date.parse(this.state.fromDate)) {
                this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });

                this.PurchaseService.fetchGeneralPurchaseProductListByDateRange(fromDate, toDate)
                    .then(res => {
                        this.setState({ topProgressBar: false });
                        if (res.status == 302) {
                            return res.json().then((body) => {
                                for (let i = 0; i < body.length; i++) {
                                    body[i].purchaseDate = NetiDateUtils.getAnyShortForm(body[i].purchaseDate, 'DD-MMM-YYYY hh:mm:ss a');
                                }
                                //for pdf column 
                                body.map(item => {
                                    let coreRoleNote = String(item.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote); 
                                    let productName = String(item.productInfoDTO.productName);
                                    item.purchaseInvoiceID = item.purchaseInvoiceID.toString();
                                    item.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote = item.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote;
                                    item.productInfoDTO.productName = item.productInfoDTO.productName;
                                    Object.assign(item, {purchaseCoreRoleNote: coreRoleNote, productName: productName});   // for pdf column
                                });
                                
                                this.setState({ dataTableValue: body, tableView: true, topProgressBar: false, dataTableIsLoading: false });
                            });
                        }
                        else {
                            this.PurchaseService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ tableView: false, topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                    });
            } else {
                this.growl.show({ severity: 'warn', summary: 'Warning', detail: "From Date can't be ahead of To Date." });
            }

        }

    }

    onFromDate = (e) => {
        this.setState({ fromDate: e.value })
        this.clearDateError(e.target.name)

    }

    onToDate = (e) => {
        this.setState({ toDate: e.value })
        this.clearDateError(e.target.name)
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    searchHandleError = () => {
        let { errors } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (this.state.fromDate === '') {
            formIsValid = false;
            errors["fromDate"] = "Start Date can't left empty.";
        }
        if (this.state.toDate === '') {
            formIsValid = false;
            errors["toDate"] = "End Date can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;
    }

    viewDialog(rowData) {
        this.setState({ topProgressBar: true, dialogDataTableIsLoading: true, errorMsgVisible: false })
        this.setState({ purchaseCodeListTable: [] });
        this.setState({ purchesPoint: rowData.productRoleAssignDTO.coreUserRoleDTO.coreRoleName })
        this.setState({ productType: rowData.productInfoDTO.productTypeInfoDTO.categoryName })
        this.setState({ productName: rowData.productInfoDTO.productName })
        this.setState({ productQuantity: rowData.purchaseQuantity })
        this.setState({ purchaseDate: rowData.purchaseDate })
        this.setState({ productPurchaseID: rowData.productPurchaseID })
        this.setState({ visible: true });

        this.PurchaseService.fetchProductPurchaseCodeDetailsByPurchaseId(rowData.productPurchaseID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ purchaseCodeListTable: body });
                        this.setState({ topProgressBar: false, dialogDataTableIsLoading: false });

                    });
                }
                else {
                    this.purchaseService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dialogDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dialogDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })

            });
    }

    usedDateBody = (rowData) => {
        return (
            <span>
                {rowData.usedDate != null ? NetiDateUtils.getAnyShortForm(rowData.usedDate, 'DD-MMM-YYYY') : ''}

            </span>
        );
    }

    statusCheck = (rowData) => {
        return (
            <span>
                {rowData.usedStatus == 0 ? "Unused" : ''}
                {rowData.usedStatus == 1 ? "Used" : ''}
            </span>
        )
    }

    onHide(event) {
        this.setState({ visible: false });
    }

    purchaseCodeBody(rowData) {
        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title="View Purchase Code"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    payableAmountBody = (rowData) => {
        return (<b> {this.validatorUtility.currencyFormatter(rowData.totalAmount)}</b>)

    }

    exportPdf = () => {

        let tableColumns = [
          { title: "Date", dataKey: "purchaseDate" },
          { title: "Purchase Point", dataKey: "purchaseCoreRoleNote" },
          { title: "Invoice ID", dataKey: "purchaseInvoiceID" },
          { title: "Product Name", dataKey: "productName" },
          { title: "Product Quantity", dataKey: "purchaseQuantity" },
          { title: "Paid Amount (with VAT)", dataKey: "paidAmount" },
          
        ];

        let tableColumnStyles = {
            paidAmount: { halign: "right"},
        }

        pdfDownloadLandscape_TableWithSubTitle("General Product Purchase Report",110, pdfTitleHeader, pdfTitleHeaderData, tableColumns, this.state.dataTableValue, tableColumnStyles, "General_Product_Purchase_Report.pdf");

    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue } = this.state;
        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                            Product Purchase List
                            </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length.toLocaleString("EN-IN")}
                                </a>
                            </div>
                        </div>;
        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        pdfTitleHeaderData = [
        { A: "From Date", B: NetiDateUtils.getAnyShortForm(this.state.fromDate, 'DD-MMM-YYYY'), C: "To Date", D: NetiDateUtils.getAnyShortForm(this.state.toDate, 'DD-MMM-YYYY')}
        ];

        return (

            <div className="p-fluid">
                <div className="nw-form">
                    <Growl ref={(el) => this.growl = el} />
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                    <Calendar
                                        value={this.state.fromDate}
                                        onChange={this.onFromDate}
                                        dateFormat="dd/mm/yy" showIcon={true}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        maxDate={maxDate}
                                        name="fromDate"
                                        yearRange={NetiDateUtils.getYearRange()}
                                        placeholder="Select Date"
                                    >
                                    </Calendar>
                                </div>
                                <span className="error-message">{this.state.errors["fromDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12  p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                    <Calendar
                                        value={this.state.toDate}
                                        maxDate={maxDate}
                                        onChange={this.onToDate}
                                        dateFormat="dd/mm/yy" showIcon={true}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        name="toDate"
                                        yearRange={NetiDateUtils.getYearRange()}
                                        placeholder="Select Date"
                                    >
                                    </Calendar>
                                </div>
                                <span className="error-message">{this.state.errors["toDate"]}</span>

                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={e => this.onSubmitHandler(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.tableView === true ?
                                this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    :
                                    <div>
                                        <DataTable
                                        header={tableHeader}
                                        value={this.state.dataTableValue}
                                        selectionMode="single"
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="purchaseDate" header="Date & Time" sortable={true} filter={true} className="table-datetime"/>
                                        <Column field="productRoleAssignDTO.coreUserRoleDTO.coreRoleNote" header="Purchase Point" sortable={true} filter={true} />
                                        <Column field="purchaseInvoiceID" header="Invoice ID" sortable={true} filter={true} />
                                        <Column field="productInfoDTO.productName" header="Product Name" sortable={true} filter={true} />
                                        <Column field="purchaseQuantity" header="Product Quantity" sortable={true} filter={true} />
                                        <Column field="paidAmount" className="text-right" header="Paid Amount (with VAT)" body={this.payableAmountBody} sortable={true} filter={true} />
                                        <Column field="" header="Purchase Code" body={this.purchaseCodeBody} style={{width:"95px"}}/>
                                    </DataTable>
                                    <div className="nw-button-parent m-t-8 p-r-0">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Download"
                                        icon="fas fa-download"
                                        onClick={this.exportPdf}
                                        />
                                     </div>    
                                    </div>
                                :

                                <BlankDataTableAnim />

                            }
                        </div>
                    </div>

                </div>



                <Dialog className="nw-dialog" header="General Product Purchase Code Info" visible={this.state.visible} onHide={this.onHide} maximizable>

                    <div className="p-fluid">
                        <div className="p-grid">
                            <div className="p-col-6 p-xl-6">
                                <span>Purchase Point : {this.state.purchesPoint}</span><br />
                                <span>Product Type :  {this.state.productType}</span><br />
                                <span>Product  Name : {this.state.productName} </span><br />
                            </div>
                            <div className="p-col-6 p-xl-6">
                                <span>Date : {NetiDateUtils.getAnyShortForm(this.state.purchaseDate, 'DD-MMM-YYYY')} </span><br />
                                <span>Quantity: {this.state.productQuantity} </span><br />
                            </div>

                            <div className="p-col-12 p-xl-12 nw-data-table">
                                {this.state.dialogDataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    :
                                    <div>
                                        <DataTable
                                            header={'Purchase Code List'}
                                            value={this.state.purchaseCodeListTable}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                        >
                                            <Column field="purchaseCode" header="Purchase Code" sortable={true} filter={true} />
                                            <Column field="usedStatus" header="Status" body={this.statusCheck} sortable={true} filter={true} />
                                            <Column field="" header="Used Date" body={this.usedDateBody} sortable={true} filter={true} />
                                        </DataTable>
                                        
                                    </div>
                                }
                            </div>

                        </div>
                    </div>

                </Dialog>

            </div>
        )
    }
}