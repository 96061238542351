import React, { Component } from "react";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Checkbox } from "primereact/checkbox";
import { Rating } from "primereact/rating";
import { Column } from "primereact/column";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { EdumanRatingList } from "./EdumanRatingList";

export class EdumanRatingProvideByPartner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTableValue: [
        {
          mark: "",
          checked: false,
          instituteIdAndName: "2015 - Test Name",
          rating: 3
        },
        {
          mark: "",
          checked: false,
          instituteIdAndName: "2015 - Test Name",
          rating: 2
        },
        {
          mark: "",
          checked: true,
          instituteIdAndName: "2015 - Test Name",
          rating: 1
        },
        {
          mark: "",
          checked: false,
          instituteIdAndName: "2015 - Test Name",
          rating: 5
        },
        {
          mark: "",
          checked: false,
          instituteIdAndName: "2015 - Test Name",
          rating: 5
        }
      ],

      edumanRatingProvideByPartner: {
        Year: null,
        Month: "",

        coreUrlInfoDTO: {
          urlInfoID: 1
        }
      },
      errors: {},
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: ""
    };

    this.markTemplate = this.markTemplate.bind(this);
  }

  markTemplate(rowData) {
    return (
      <Checkbox checked={rowData.checked} onChange={e => this.setState({})} />
    );
  }

  getEdumanRating = rowData => {
    return (
      <div class="p-inputgroup">
        <Rating value={rowData.rating} cancel={false} />
      </div>
    );
  };
  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}

        <div className="p-grid">
         
          <div className="p-col-12 p-xl-12">
            <div className="nw-form">
              <Growl ref={el => (this.growl = el)} />
                <div className="p-col-12 p-xl-12">
                  
                    <div className="p-col-12 p-xl-12">
                      <div className="nw-form-body">
                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">Year <span>*</span></span>
                            <Dropdown
                              // value={calendarInfo.banneCategory}
                              // onChange={this.onChangecalendarCategory}
                              id="year"
                              placeholder="Select Year"
                              showClear={true}
                              type="text"
                              name="year"
                              autoWidth={false}
                            />
                          </div>
                          <span className="error-message">
                            {/* {this.state.errors["calendarCategory"]} */}
                          </span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">Month <span>*</span></span>
                            <Dropdown
                              // value={calendarInfo.banneCategory}
                              // onChange={this.onChangecalendarCategory}
                              id="month"
                              placeholder="Select Month"
                              showClear={true}
                              type="text"
                              name="month"
                              autoWidth={false}
                            />
                          </div>
                          <span className="error-message">
                            {/* {this.state.errors["calendarCategory"]} */}
                          </span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                          <div className="nw-data-table nw-data-table-tabview">
                            {this.state.dataTableValue ? (
                              <DataTable
                                value={this.state.dataTableValue}
                                responsive={true}
                                scrollable={true}
                                scrollHeight="200px"
                                className="text-center"
                              >
                                <Column
                                  field="mark"
                                  header="Mark"
                                  body={this.markTemplate}
                                />
                                <Column
                                  field="instituteIdAndName"
                                  header="Institute ID &amp; Name"
                                />
                                <Column
                                  field="rating"
                                  header="Rating (1=Low, 5=High)"
                                  body={this.getEdumanRating}
                                />
                              </DataTable>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="p-col-12"/>

                        <div className="p-col-12 p-xl-12 nw-button-parent">
                          <div className="required-field">
                              (<span>*</span>) required fields
                          </div> 

                          <Button
                            className="p-button p-button-primary nw-button nw-button-right"
                            label="Save"
                            icon="fas fa-check"
                            onClick={this.onSubmitHandler}
                          />
                        </div>
                      </div>
                    </div>
                  
               </div>
               <div className="p-col-12 p-xl-12">
                  <div>
                    {!this.state.dataTableIsLoading ? (
                      <EdumanRatingList />
                    ) : (
                      this.NetiContentLoader.MyPointTableLoader()
                    )}
                  </div>
                </div>



            </div>
          </div>
         
        </div>
      </div>
    );
  }
}
