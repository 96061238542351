
import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import profileImage from '../../../assets/images/mostofa-jobber.png';

let emDueBillStatusArr = [];
let totalEmDueBillAmount = 0;
let emDueBillStatus;

let emDueInstituteStatusArr = [];
let totalEmDueInstitute = 0;
let emDueInstituteStatus;

let mbpPartnerStatusArr = [];
let totalMbp = 0;
let mbpPartnerStatus;

let zbpPartnerStatusArr = [];
let totalZbp = 0;
let zbpPartnerStatus;

let bdpPartnerStatusArr = [];
let totalBdp = 0;
let bdpPartnerStatus;

let bepPartnerStatusArr = [];
let totalBep = 0;
let bepPartnerStatus;


let uniquePointNames = [];
let yearNameColumn = [];
let pointName = [];

let uniqueYearNames = [];
let yearName = [];

export class PartnerDashBoard extends Component {

    constructor() {
        super();
        this.state = {
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            currentStatusDashboardInfo: {
                payableInfo: {},
                pendingRequestInfo: {}
            },


        }

        this.CRMPointService = new CRMPointService();
        this.validatorUtility = new ValidatorUtility();


    }


    componentDidMount() {
        this.getcrmCurrentStatusDashboardInfo();
    }

    getcrmCurrentStatusDashboardInfo() {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.CRMPointService.findCurrentStatusDashboardInfo()
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {

                        let emDueBillAmountColumnHeader = ["Active", "Inactive"];
                        emDueBillStatus = Object.entries(body.emDueBillAmountStatus)
                        emDueBillStatusArr.push(emDueBillAmountColumnHeader, ...emDueBillStatus);
                        totalEmDueBillAmount = emDueBillStatus.map(item => item[1]).reduce((a, b) => (a + b));


                        let emDueInstituteColumnHeader = ["Active", "Inactive"];
                        emDueInstituteStatus = Object.entries(body.emDueInstituteStatus)
                        emDueInstituteStatusArr.push(emDueInstituteColumnHeader, ...emDueInstituteStatus);
                        totalEmDueInstitute = emDueInstituteStatus.map(item => item[1]).reduce((a, b) => (a + b));


                        let mbpPartnerStatusColumnHeader = ["title", "count"];
                        mbpPartnerStatus = Object.entries(body.mbpPartnerStatus)
                        mbpPartnerStatusArr.push(mbpPartnerStatusColumnHeader, ...mbpPartnerStatus);
                        totalMbp = mbpPartnerStatus.map(item => item[1]).reduce((a, b) => (a + b));


                        let zbpPartnerStatusColumnHeader = ["title", "count"];
                        zbpPartnerStatus = Object.entries(body.zbpPartnerStatus)
                        zbpPartnerStatusArr.push(zbpPartnerStatusColumnHeader, ...zbpPartnerStatus);
                        totalZbp = zbpPartnerStatus.map(item => item[1]).reduce((a, b) => (a + b));

                        let bdpPartnerStatusColumnHeader = ["title", "count"];
                        bdpPartnerStatus = Object.entries(body.bdpPartnerStatus)
                        bdpPartnerStatusArr.push(bdpPartnerStatusColumnHeader, ...bdpPartnerStatus);
                        totalBdp = bdpPartnerStatus.map(item => item[1]).reduce((a, b) => (a + b));

                        let bepPartnerStatusColumnHeader = ["title", "count"];
                        bepPartnerStatus = Object.entries(body.bepPartnerStatus)
                        bepPartnerStatusArr.push(bepPartnerStatusColumnHeader, ...bepPartnerStatus);
                        totalBep = bepPartnerStatus.map(item => item[1]).reduce((a, b) => (a + b));

                        this.setState({ currentStatusDashboardInfo: body })
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { payableInfo, pendingRequestInfo, pointSummary } = this.state.currentStatusDashboardInfo;

        let unUsedHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Point Info <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {pointSummary ? pointSummary.length : 0}
            </span>
        </div>;

        let footer = <ColumnGroup>
            <Row>
            </Row>
        </ColumnGroup>;


        if (pointSummary != null) {
            for (let i = 0; i < pointSummary.length; i++) {
                for (let [key, value] of Object.entries(pointSummary[i])) {
                    pointName.push(key);
                    uniquePointNames = Array.from(new Set(pointName))
                    for (let k = 0; k < value.length; k++) {
                        yearName.push(value[k].year);
                        uniqueYearNames = Array.from(new Set(yearName)).sort(function (a, b) { return b - a })
                    }
                }
            }

        }

        return (
            <div className="main-section netiWorld-DashBoard-wrapper">
                <div className="netiWorld-DashBoard">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} />

                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="panel-box p-0">
                                <div className="panel-box-header">
                                    <h1>Partner Information</h1>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-1">
                                            <i class="fas fa-user-tie"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            <h4 className="font-size-50">ZBP</h4>
                                        </div>
                                        <p className="info-title">Partner Type</p>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-5">
                                            <i class="fas fa-calendar-day"></i>
                                        </div>
                                        <div className="info-text">
                                            {/* <h4>&nbsp;</h4> */}
                                            <span className="font-size-20">27 Dec 2017</span>
                                        </div>
                                        <p className="info-title">Assign Date</p>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-6">
                                            <i class="fas fa-stopwatch"></i>
                                        </div>
                                        <div className="info-text">
                                            {/* <h4>&nbsp;</h4> */}
                                            <span className="font-size-20">2 Years 7 Months 29 Days</span>
                                        </div>
                                        <p className="info-title">Duration</p>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-3 p-md-6 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-7">
                                            <i class="fas fa-user"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            <h4 className="font-size-50">ONE</h4>
                                        </div>
                                        <p className="info-title">Partner Grade</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid m-t-20">
                        <div className="p-col-12">
                            <div className="panel-box p-0">
                                <div className="panel-box-header">
                                    <h1>Team Lead Info</h1>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 p-md-12 p-sm-12">
                                    <div className="info-box-wrapper profile-wrapper not-hover">
                                        <div className="portal-dashboard-profile at-a-glance partner-at-a-glance">
                                            <div className="layout-dashboard">
                                                <div className="at-a-glance">
                                                    <div className="portal-profile-info-wrapper">
                                                        <div className="portal-profile-image">
                                                            <img src={profileImage} />
                                                        </div>
                                                        <div className="info-table p-md-8 p-xl-4">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>TEAM LEAD ID</td>
                                                                        <td>:</td>
                                                                        <td>1000021275622</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Name</td>
                                                                        <td>:</td>
                                                                        <td>MD.Samsul Islam</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>CONTACT NO.</td>
                                                                        <td>:</td>
                                                                        <td>01679974130</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>CONTACT NO.</td>
                                                                        <td>:</td>
                                                                        <td>MBP</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid m-t-20">
                        <div className="p-col-12 p-xl-12 p-md-12 p-sm-12">
                            <div className="panel-box p-l-0">
                                <div className="panel-box-header">
                                    <h1>Partner Information</h1>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-4 p-md-12 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-1">
                                            <i class="fas fa-laptop"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            <h4 className="font-size-50">30</h4>
                                        </div>
                                        <p className="info-title">Total Neti CMS</p>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-4 p-md-12 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-8">
                                            <i class="fab fa-autoprefixer"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            {/* <h4>&nbsp;</h4> */}
                                            <span className="font-size-50">29</span>
                                        </div>
                                        <p className="info-title">Total Admisia</p>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-4 p-md-12 p-sm-12">
                                    <div className="info-box-wrapper">
                                        <div className="info-icon icon-bg-6">
                                            <i class="fas fa-tablet-alt"></i>
                                        </div>
                                        <div className="info-text m-0">
                                            {/* <h4>&nbsp;</h4> */}
                                            <span className="font-size-50">87</span>
                                        </div>
                                        <p className="info-title">Total Device in Institute</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid m-t-20">
                        <div className="p-col-12 p-xl-12 p-md-12 p-sm-12">
                            <div className="panel-box p-l-0">
                                <div className="panel-box-header">
                                    <h1>Current Team Status</h1>
                                </div>
                            </div>
                            <div className="p-grid m-0">
                                <div className="p-col-12 p-xl-6 p-md-12 p-sm-12 p-l-0">
                                    <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover">
                                                <div className="chart-box">
                                                    <Chart
                                                        width={226}
                                                        height={226}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        data={mbpPartnerStatusArr}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            legend: 'none',
                                                            tooltip: { trigger: 'none', showColorCode: true },
                                                            slices: {
                                                                0: { color: '#27AE5F' },
                                                                1: { color: '#FF3A27' },
                                                                2: { color: '#FFDB10' },
                                                            },
                                                        }}
                                                        render={({ renderChart }) => {
                                                            return (
                                                                <div>{renderChart()}</div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <span className="chart-box-title">Total ZBP Area</span><br />
                                                <span className="chart-box-title">{totalMbp !== 0 ? totalMbp.toLocaleString('EN-IN') : 0}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                <div className="chart-box-wrapper">
                                                    <div className="point-list">
                                                        {mbpPartnerStatus !== undefined ?

                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: "#27AE5F" }}></span><p>Active</p></td>
                                                                    <td><p>: {mbpPartnerStatus[0][1] ? mbpPartnerStatus[0][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FF3A27" }}></span><p>Suspend</p></td>
                                                                    <td><p>: {mbpPartnerStatus[1][1] ? mbpPartnerStatus[1][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FFDB10" }}></span><p>Blank</p></td>
                                                                    <td><p>: {mbpPartnerStatus[2][1] ? mbpPartnerStatus[2][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                            </table>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-6 p-md-12 p-sm-12">
                                    <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover">
                                                <div className="chart-box">
                                                    <Chart
                                                        width={226}
                                                        height={226}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        data={zbpPartnerStatusArr}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            legend: 'none',
                                                            tooltip: { trigger: 'none', showColorCode: true },
                                                            slices: {
                                                                0: { color: '#27AE5F' },
                                                                1: { color: '#FF3A27' },
                                                                2: { color: '#FFDB10' },
                                                            },
                                                        }}
                                                        render={({ renderChart }) => {
                                                            return (
                                                                <div>{renderChart()}</div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <span className="chart-box-title">Total BDP Area</span><br />
                                                <span className="chart-box-title">{totalZbp !== 0 ? totalZbp.toLocaleString('EN-IN') : 0}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                <div className="chart-box-wrapper">
                                                    <div className="point-list">
                                                        {zbpPartnerStatus !== undefined ?

                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: "#27AE5F" }}></span><p>Active</p></td>
                                                                    <td><p>: {zbpPartnerStatus[0][1] ? zbpPartnerStatus[0][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FF3A27" }}></span><p>Suspend</p></td>
                                                                    <td><p>: {zbpPartnerStatus[1][1] ? zbpPartnerStatus[1][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FFDB10" }}></span><p>Blank</p></td>
                                                                    <td><p>: {zbpPartnerStatus[2][1] ? zbpPartnerStatus[2][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                            </table>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-grid m-0">
                                <div className="p-col-12 p-xl-6 p-md-12 p-sm-12 p-l-0 m-t-8">
                                    <div className="p-grid m-l-0 m-r-0 info-box-wrapper not-hover">
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover">
                                                <div className="chart-box">
                                                    <Chart
                                                        width={226}
                                                        height={226}
                                                        chartType="PieChart"
                                                        loader={<div>Loading Chart</div>}
                                                        legend={{
                                                            position: "bottom",
                                                            alignment: "center",
                                                            textStyle: {
                                                                color: "233238",
                                                                fontSize: 16
                                                            }
                                                        }}
                                                        data={bdpPartnerStatusArr}
                                                        options={{
                                                            chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
                                                            backgroundColor: 'transparent',
                                                            legend: 'none',
                                                            tooltip: { trigger: 'none', showColorCode: true },
                                                            slices: {
                                                                0: { color: '#27AE5F' },
                                                                1: { color: '#FF3A27' },
                                                                2: { color: '#FFDB10' },
                                                            },
                                                        }}
                                                        render={({ renderChart }) => {
                                                            return (
                                                                <div>{renderChart()}</div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <span className="chart-box-title">Total BEP Area</span><br />
                                                <span className="chart-box-title">{totalBdp !== 0 ? totalBdp.toLocaleString('EN-IN') : 0}</span>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-6 p-md-6">
                                            <div className="info-box-wrapper not-hover netiWorld-DashBoard-wrapper">
                                                <div className="chart-box-wrapper">
                                                    <div className="point-list">
                                                        {bdpPartnerStatus !== undefined ?
                                                            <table>
                                                                <tr>
                                                                    <td><span style={{ background: "#27AE5F" }}></span><p>Active</p></td>
                                                                    <td><p>: {bdpPartnerStatus[0][1] ? bdpPartnerStatus[0][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FF3A27" }}></span><p>Suspend</p></td>
                                                                    <td><p>: {bdpPartnerStatus[1][1] ? bdpPartnerStatus[1][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{ background: "#FFDB10" }}></span><p>Blank</p></td>
                                                                    <td><p>: {bdpPartnerStatus[2][1] ? bdpPartnerStatus[2][1].toLocaleString('EN-IN') : 0}</p></td>
                                                                </tr>
                                                            </table>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )

    }
}