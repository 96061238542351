import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';


let urlId;
export class ApplicationConfigConfigurationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classConfigList: [],
      updateErrors: {},
      startDate: '',
      endDate: '',
      admissionTestDate: '',
      topProgressBar: false,
      dataTableIsLoading: false,
      configListUpdate: {
        admisiaCoreConfigDTO: {},
        admissionTestDate: '',
        admissionTestInstruction: "",
        admissionTestStatus: '',
        applicantLimit: '',
        autoApproveStatus: '',
        classConfigID: '',
        classConfigSerial: '',
        classCoreCategoryInfoDTO: {
          categoryName: ''
        },
        enableStatus: '',
        endDate: '',
        feeAmount: '',
        groupCoreCategoryInfoDTO: {
          categoryName: ''
        },
        othersInfoData: "string",
        othersInfoRequired: '',
        prevExamInfoRequired: '',
        startDate: '',
      }

    };
    this.admisiaPointService = new AdmisiaPointService();
    this.actionBody = this.actionBody.bind(this);
    this.startBody = this.startBody.bind(this);
    this.endBody = this.endBody.bind(this);
    this.netiDateUtils = new NetiDateUtils();
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUpdateError = this.handleUpdateError.bind(this);
    this.viewDialog = this.viewDialog.bind(this);
    this.handlepreviousAdmissionInfo = this.handlepreviousAdmissionInfo.bind(this);
    this.handlefeeValue = this.handlefeeValue.bind(this);
    this.handlepreviousExamInfo = this.handlepreviousExamInfo.bind(this);
    this.handleyesAutoApproveOption = this.handleyesAutoApproveOption.bind(this);
    this.onChangeAdmissionTestDate = this.onChangeAdmissionTestDate.bind(this);
    this.NetiContentLoader = new NetiContentLoader();
  }

  componentWillMount() {
    urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
    this.getAdmisiaClassConfigListTable();
  }

  getAdmisiaClassConfigListTable = () => {
    let requestedObject = {
      urlInfoID: parseInt(urlId),
      pageNo: 0,
      limit: 10
    };

    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false })

    this.admisiaPointService.getAdmisiaClassConfigList(requestedObject)
      .then(res => {
        if (res.status == 302) {
          return res.json().then(body => {
            body = body.map(item => {
              item.admissionTestStatus === 0 ? item.admissionTestStatus = 'No' : item.admissionTestStatus = 'Yes';
              item.enableStatus === 0 ? item.enableStatus = 'Disable' : item.enableStatus = 'Enable';
              item.startDate = NetiDateUtils.getAnyShortForm(item.startDate, 'DD-MMM-YYYY');
              item.endDate = NetiDateUtils.getAnyShortForm(item.endDate, 'DD-MMM-YYYY');
              return item;
            })
            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
            this.setState({ classConfigList: body });
          });
        } else {
          this.admisiaPointService.Auth.handleErrorStatus(res)
            .then((responseBody) => {
              this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
            });
        }

      }).catch(err =>
        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
      );
  };


  actionBody(rowData) {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button edit"
          icon="fas fa-pencil-alt"
          title="Update"
          onClick={e => this.viewDialog(rowData)}
        />
      </div>
    );
  }


  viewDialog(rowData) {
    this.setState({ visible: true });
    this.admisiaPointService
      .getAdmisiaClassConfigListbyId(rowData.classConfigID)
      .then(res => {
        if (res.status == 302) {
          return res.json().then(body => {
            console.log('config update list', body)
            body.feeAmount = body.feeAmount - 25
            this.setState({ configListUpdate: body });
          });
        } else {
          return res.json().then(body => {
          });
        }
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({ diagclassName: rowData.className });
    this.setState({ diaggroupName: rowData.group });
    this.setState({ diagclassConfigID: rowData.classConfigID });
    this.setState({ requiredApplicant: rowData.applicantLimit });
    this.setState({ formFee: rowData.feeAmount });

  }

  startBody(rowData) {
    return NetiDateUtils.getDateFromString(rowData.startDate);
  }

  endBody(rowData) {
    return NetiDateUtils.getDateFromString(rowData.endDate);
  }

  onChangeStartDate = (e) => {
    let {configListUpdate} = this.state;
    configListUpdate.startDate = Date.parse(e.target.value);
    this.setState({ configListUpdate, startDate: e.target.value })
  }

  onChangeEndDate = (e) => {
    let {configListUpdate} = this.state;
    configListUpdate.endDate = Date.parse(e.target.value);
    this.setState({ endDate: e.target.value })
  }

  onChangeRequiredApplicant = (e) => {
    let { configListUpdate, updateErrors } = this.state
    updateErrors["totalRequiredApplicant"] = "";
    configListUpdate.applicantLimit = e.target.value;
    this.setState({ configListUpdate, updateErrors })
  }

  onChangeAdmissionTestDate = (e) => {
    this.setState({ admissionTestDate: e.value })
  }

  handleStatus = (e) => {
    let { configListUpdate } = this.state;

    if (e.target.value == 1) {
      configListUpdate.enableStatus = 1;
    } else if (e.value == 0) {
      configListUpdate.enableStatus = 0;
    }

    this.setState({ configListUpdate });

  }


  handlefeeValue = e => {
    let { configListUpdate } = this.state
    configListUpdate.feeAmount = e.target.value
    this.setState({ configListUpdate })
  };

  handlepreviousExamInfo = e => {
    let { configListUpdate } = this.state
    configListUpdate.prevExamInfoRequired = e.value
    this.setState({ configListUpdate });

    this.setState({ prevExamInfoRequired: e.value });
    if (e.value == 1) {
      this.setState({ prevExamInfoRequired: 1 });
    } else if (e.value == 0) {
      this.setState({ prevExamInfoRequired: 0 });
    }
  };
  handlepreviousAdmissionInfo = e => {
    this.setState({
      previousAdmissionInfo: e.value,
      autoApproveStatus: 0,
      yesAutoApprove: false,
      newAdmissionTestDate: ''
    });
    let { configListUpdate } = this.state
    configListUpdate.admissionTestStatus = e.value
    this.setState({ configListUpdate });

    if (e.value == 1) {
      this.setState({ admissionTestStatus: 1 });
    } else if (e.value == 0) {
      this.setState({ admissionTestStatus: 0 });
    }
  };

  admissionTestInstruction = e => {
    let { configListUpdate, updateErrors } = this.state
    updateErrors["examInstruction"] = '';
    configListUpdate.admissionTestInstruction = e.target.value
    this.setState({ configListUpdate, updateErrors })
  };

  handleyesAutoApproveOption = e => {
    let { configListUpdate } = this.state

    this.setState({ yesAutoApprove: e.value });
    if (e.value == true) {
      configListUpdate.autoApproveStatus = 1
      this.setState({ autoApproveStatus: 1 });
    } else if (e.value == false) {
      configListUpdate.autoApproveStatus = 0
      this.setState({ autoApproveStatus: 0 });
    }

    this.setState({ configListUpdate });
  };


  handleUpdate() {

    if (this.handleUpdateError()) {
      this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })


    
      let configListUpdate = { ...this.state.configListUpdate }

      if (this.state.startDate === '') {
        configListUpdate.startDate = configListUpdate.startDate
      }
      else {
        configListUpdate.startDate = this.state.startDate
      }

      if (this.state.endDate === '') {
        configListUpdate.endDate = configListUpdate.endDate
      }
      else {
        configListUpdate.endDate = this.state.endDate
      }

      if (this.state.admissionTestDate === '') {
        configListUpdate.admissionTestDate = configListUpdate.admissionTestDate
      }
      else {
        configListUpdate.admissionTestDate = this.state.admissionTestDate
      }

      configListUpdate.feeAmount = +configListUpdate.feeAmount + 25
      configListUpdate.admissionTestInstruction = configListUpdate.admissionTestInstruction.trim();
      // console.log("this.state.configListUpdate.startDate parse",Date.parse(this.state.configListUpdate.startDate));
      // console.log("this.state.configListUpdate.endDate parse",Date.parse(this.state.configListUpdate.endDate));
      
      if (this.state.configListUpdate.endDate > this.state.configListUpdate.startDate) {
        console.log("configListUpdate.feeAmount",configListUpdate.feeAmount);
      this.admisiaPointService.updateAdmisiaClassConfig(configListUpdate)
        .then(res => {
          if (res.status == 202) {
            console.log("Start Date", configListUpdate);
            this.growl.show({
              severity: "success",
              summary: "Success",
              detail: "Data updated successfully."
            });
            this.setState({ startDate: '',endDate:'' });
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false })
            this.setState({ visible: false, updateErrors: {} });
            this.getAdmisiaClassConfigListTable();
          } else {
            this.admisiaPointService.Auth.handleErrorStatus(res)
              .then((responseBody) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody.message })
              });

          }
        }).catch(error => {
          this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
        });

      }

      else {
        this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be greater than To Date." });
        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false })
    }


     
    }
  }

  handleUpdateError = () => {
    let formIsValid = true;
    let { updateErrors } = this.state;

    if (!this.state.configListUpdate.startDate && !this.state.startDate) {
      formIsValid = false;
      updateErrors['startDate'] = 'Please select Date'
    }
    else if (!Date.parse(this.state.startDate) && this.state.startDate) {
      formIsValid = false;
      updateErrors['startDate'] = 'Invalid Date format'
    }

    if (!this.state.configListUpdate.endDate && !this.state.endDate) {
      formIsValid = false;
      updateErrors['endDate'] = 'Please select Date'
    }
    else if (!Date.parse(this.state.endDate) && this.state.endDate) {
      formIsValid = false;
      updateErrors['endDate'] = 'Invalid Date format'
    }

    if (this.state.configListUpdate.feeAmount === '') {
      formIsValid = false;
      updateErrors["feeAmount"] = 'Please Input Value'
    }

    if (this.state.configListUpdate.applicantLimit === '') {
      formIsValid = false;
      updateErrors["totalRequiredApplicant"] = "Total required applicant can't be left empty";
    }

    if (this.state.configListUpdate.admissionTestStatus) {

      if (!this.state.configListUpdate.admissionTestDate && !this.state.admissionTestDate) {
        formIsValid = false;
        updateErrors["examDateTime"] = "Please select Date ";
      }
      else if (this.state.admissionTestDate && !Date.parse(this.state.admissionTestDate)) {
        formIsValid = false;
        updateErrors["examDateTime"] = "Invalid Date & Time format ";

      }
      if (this.state.configListUpdate.admissionTestInstruction == '') {
        formIsValid = false;
        updateErrors["examInstruction"] = "Please Input Value ";
      }
    }
    this.setState({ updateErrors });
    return formIsValid;
  }

  turnOffDialog = () => {
    let { configListUpdate } = this.state;
    this.setState({ visible: false, updateErrors: {}, endDate:'',startDate:'' });
  }

  render() {
    let { configListUpdate, updateErrors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state

    let header = (
      <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
        Class Configuration List{" "}
        <span style={{ color: "white", display: "flex", float: "right" }}>
          Total Found: {this.state.classConfigList.length}
        </span>
      </div>
    );
    const yesAutoApproveOption = [
      { label: "Yes", value: true },
      { label: "No", value: false }
    ];
    const noAutoApproveOption = [
      { label: "Yes", value: true },
      { label: "No", value: false }
    ];
    let feeGrandValue = parseFloat(configListUpdate.feeAmount);
    let feeTax = parseFloat(25);
    if (feeGrandValue == 0) {
      feeGrandValue += feeTax;
    } else if (feeGrandValue !== 0 && feeGrandValue !== "") {
      feeGrandValue = feeGrandValue + feeTax;
    } else if (feeGrandValue === "") {
      feeGrandValue = 0;
    }
    return (
      <div className="layout-dashboard">
        <Growl ref={el => (this.growl = el)} />
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }

        <div className="">
          {this.state.dataTableIsLoading ?
            this.NetiContentLoader.MyPointTableLoader() :
            <div className="nw-data-table">
              <DataTable
                value={this.state.classConfigList}
                header={header}
                responsive={true}
                paginator={true}
                rows={5}
              >
                <Column
                  field="className"
                  header="Class"
                  sortable={true}
                  filter={true}
                />
                <Column
                  field="group"
                  header="Group"
                  sortable={true}
                  filter={true}
                />
                <Column
                  field="startDate"
                  header="Application Start Date"
                  sortable={true}
                  filter={true}
                />
                <Column
                  field="endDate"
                  header="Application End Date"
                  sortable={true}
                  filter={true}
                />
                <Column
                  field="applicantLimit"
                  header="Total Required Applicant"
                  sortable={true}
                  filter={true}
                />
                <Column
                  field="feeAmount"
                  header="Application Form Fee"
                  sortable={true}
                  filter={true}
                />
                <Column
                  field="admissionTestStatus"
                  header="Assessment"
                  sortable={true}
                  filter={true}
                />
                <Column
                  field="enableStatus"
                  header="Status"
                  sortable={true}
                  filter={true}
                />
                <Column body={this.actionBody} header="Action" />
              </DataTable>

            </div>
          }

          <Dialog
            header="Update Application Information"
            className="nw-dialog"
            visible={this.state.visible}
            modal={true}
            onHide={this.turnOffDialog}
          >
            <div className="p-grid nw-form">
              <div className="p-col-12 p-xl-12">

                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputgroup-label">
                      Class
                          </span>
                    <div className="nw-inputgroup-desc">
                      {configListUpdate.classCoreCategoryInfoDTO.categoryName}
                    </div>
                  </div>
                </div>

                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputgroup-label">
                      Group
                          </span>
                    <div className="nw-inputgroup-desc">
                      {configListUpdate.groupCoreCategoryInfoDTO.categoryName}
                    </div>
                  </div>
                </div>

               
                  <div className="p-col-12 p-lg-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Application Start <span>*</span>
                      </span>
                      <Calendar
                        dateFormat="dd/mm/yy"
                        placeholder={NetiDateUtils.getAnyShortForm(configListUpdate.startDate, "DD/MM/YYYY")}
                        value={this.state.startDate || ''}
                        onChange={e => this.onChangeStartDate(e)}
                        showIcon={true}
                        showButtonBar={true}
                        yearRange="2010:2030"
                        monthNavigator={true}
                        yearNavigator={true}
                        dateOnly="true"
                      />
                    </div>
                    <span className="error-message">{Date.parse(this.state.startDate) ? '' : updateErrors["startDate"]}</span>
                  </div>

                  <div className="p-col-12 p-lg-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Application End <span>*</span>
                      </span>
                      <Calendar
                        dateFormat="dd/mm/yy"
                        placeholder={NetiDateUtils.getAnyShortForm(configListUpdate.endDate, "DD/MM/YYYY")}
                        value={this.state.endDate || ''}
                        onChange={e => this.onChangeEndDate(e)}
                        showIcon={true}
                        showButtonBar={true}
                        yearRange="2010:2030"
                        monthNavigator={true}
                        yearNavigator={true}
                        dateOnly="true"
                      />
                    </div>
                    <span className="error-message">{Date.parse(this.state.endDate) ? '' : updateErrors["endDate"]}</span>
                  </div>

                
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Total Required Applicant <span>*</span>
                    </span>
                      <InputText
                        name="text"
                        placeholder="Enter Total number Required Applicant"
                        onChange={this.onChangeRequiredApplicant}
                        value={configListUpdate.applicantLimit || ''} 
                        keyfilter="pint"
                        name="totalRequiredApplicant"
                      />                  
                  </div>
                  <span className="error-message">{updateErrors["totalRequiredApplicant"]}</span>
                </div>

                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputgroup-label">
                      Application Form Fee <span>*</span>
                    </span>
                    <InputText
                      name="text"
                      placeholder="Enter Application Form Fee"
                      onChange={this.handlefeeValue}
                      value={configListUpdate.feeAmount || ''}
                      keyfilter="money"
                    />
                    <span className="p-inputgroup-addon">{isNaN(feeGrandValue) ? 0 : feeGrandValue} BDT</span>
                  </div>
                  <span className="error-message">{this.state.configListUpdate.feeAmount ? '' : updateErrors["feeAmount"]}</span>
                  <br />
                  <span>25 Taka charge will be included with Fee</span>

                </div>

                <div className="p-grid p-col-12 p-xl-12">
                  <div className="p-col-4">
                    <label htmlFor="input">
                      {" "}
                      Do you need Applicant Previous Exam Info?
                    </label>
                  </div>
                  <span style={{ paddingTop: "5px", paddingRight: "10px" }}>
                    :
                      </span>
                  <div className="p-col-6 ">
                    <span>
                      <RadioButton
                        inputId="rb1"
                        name="previousExamInfo"
                        value={1}
                        // onChange={e =>
                        //     this.setState({ previousExamInfo: e.value })
                        // }
                        onChange={this.handlepreviousExamInfo}
                        checked={
                          configListUpdate.prevExamInfoRequired === 1 ? true : false
                        }
                      />
                      <label htmlFor="rb1" className="p-radiobutton-label">
                        Yes
                          </label>
                      &nbsp;&nbsp; &nbsp;&nbsp;
                          <RadioButton
                        inputId="rb2"
                        name="previousExamInfo"
                        value={0}
                        onChange={this.handlepreviousExamInfo}
                        checked={
                          configListUpdate.prevExamInfoRequired === 0 ? true : false
                        }
                      />
                      <label htmlFor="rb2" className="p-radiobutton-label">
                        No
                          </label>
                    </span>
                    {/* <span className="error-message">
                                          {this.state.saveErrors["groupName"]}
                                      </span> */}
                    <br />
                  </div>
                </div>
                {this.state.configListUpdate.prevExamInfoRequired ? (
                  <div className="p-col-12 p-fluid">
                    <center>
                      <table>
                        <th colspan="2">Required Information</th>
                        <tbody>
                          <tr>
                            <td> ★ Institute Name </td>
                            <td> ★ Exam </td>
                          </tr>
                          <tr>
                            <td> ★ Board </td>
                            <td> ★ Grade </td>
                          </tr>
                          <tr>
                            <td> ★ Class </td>
                            <td> ★ GPA </td>
                          </tr>
                          <tr>
                            <td> ★ Roll No </td>
                            <td> ★ Passing Year </td>
                          </tr>
                        </tbody>
                      </table>
                    </center>
                  </div>
                ) : (
                    ""
                  )}


                <div className="p-grid p-col-12 p-xl-12">
                  <div className="p-col-4">
                    <label htmlFor="input">
                      {" "}
                      Do you need Applicant Assesment?
                        </label>
                  </div>
                  <span style={{ paddingTop: "5px", paddingRight: "10px" }}>
                    :
                      </span>
                  <div className="p-col-6 ">
                    <span>
                      <RadioButton
                        inputId="rb3"
                        name="previousAdmissionInfo"
                        value={1}
                        onChange={this.handlepreviousAdmissionInfo}
                        checked={
                          configListUpdate.admissionTestStatus === 1 ? true : false
                        }
                      />
                      <label htmlFor="rb3" className="p-radiobutton-label">
                        Yes
                          </label>
                      &nbsp;&nbsp; &nbsp;&nbsp;
                          <RadioButton
                        inputId="rb4"
                        name="previousAdmissionInfo"
                        value={0}
                        onChange={this.handlepreviousAdmissionInfo}
                        checked={
                          configListUpdate.admissionTestStatus === 0 ? true : false
                        }
                      />
                      <label htmlFor="rb4" className="p-radiobutton-label">
                        No
                          </label>
                    </span>

                  </div>
                </div>
                {configListUpdate.admissionTestStatus != 0 ? (

                  <div className="p-col-12 p-fluid">
                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Exam Date And Time <span>*</span>
                        </span>
                        <Calendar
                          name="examDateTime"
                          dateFormat="dd/mm/yy"
                          placeholder={
                            configListUpdate.admissionTestDate ? NetiDateUtils.getAnyShortForm(configListUpdate.admissionTestDate, "DD/MM/YYYY hh:mm:s")
                              : 'Insert Exam Date'
                          }
                          value={this.state.admissionTestDate || ''}
                          onChange={e => this.onChangeAdmissionTestDate(e)}
                          showIcon={true}
                          showTime={true}
                          showSeconds={true}
                          hourFormat="12"
                          showIcon={true}
                        />
                      </div>
                      <span className="error-message">{updateErrors["examDateTime"]}</span>

                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                          Exam Instruction <span>*</span>
                        </span>
                        <InputTextarea
                          value={configListUpdate.admissionTestInstruction || ''}
                          onChange={e => this.admissionTestInstruction(e)}
                          placeholder="Write Exam Instruction"
                          rows={5}
                          name="examInstruction"
                          cols={30}
                        />
                      </div>
                    </div>
                    <span className="error-message">{this.state.updateErrors["examInstruction"]}</span>
                  </div>
                ) : ''}

                <div className="p-col-12 p-fluid">
                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon" style={{ minWidth: "250px" }}>
                        Auto Approve for Admission Test
                        </span>
                      <Dropdown
                        options={yesAutoApproveOption}
                        value={this.state.yesAutoApprove}
                        onChange={this.handleyesAutoApproveOption}
                        filter={true}
                        filterBy="label,value"
                        autoWidth={false}
                        showClear={true}
                        placeholder={
                          configListUpdate.autoApproveStatus === 0 ? "No" : 'Yes'
                        }
                      />
                    </div>
                  </div>


                  <div className="p-grid p-col-12 p-xl-12">
                    <div className="p-col-4">
                      <label htmlFor="input">
                        {" "}
                        Status
                        </label>
                    </div>
                    <span style={{ paddingTop: "5px", paddingRight: "10px" }}>
                      :
                      </span>
                    <div className="p-col-6 ">
                      <span>
                        <RadioButton
                          inputId="status1"
                          name="status1"
                          value={1}
                          onChange={this.handleStatus}
                          checked={
                            configListUpdate.enableStatus === 1 ? true : false
                          }
                        />
                        <label htmlFor="status1" className="p-radiobutton-label">
                          Enable
                          </label>
                        &nbsp;&nbsp; &nbsp;&nbsp;
                          <RadioButton
                          inputId="status2"
                          name="status2"
                          value={0}
                          onChange={this.handleStatus}
                          checked={
                            configListUpdate.enableStatus === 0 ? true : false
                          }
                        />
                        <label htmlFor="status2" className="p-radiobutton-label">
                          Disable
                          </label>
                      </span>
                      <br />
                    </div>
                  </div>


                </div>




                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">

                    <Button
                        className="p-button-danger nw-button nw-button-multiple"
                        label="Discard"
                        icon="fas fa-times"
                        onClick={this.turnOffDialog}
                    />

                    <Button
                        className="p-button-primary nw-button nw-button-multiple"
                        label="Update"
                        icon="fas fa-check"
                        onClick={this.handleUpdate}
                    />

                </div>




                {/* <div className="p-col-12 p-xl-12 nw-button-parent">
                  <Button
                    icon="fas fa-check"
                    label="Update"
                    className="p-button p-button-primary nw-button nw-button-center"
                    onClick={this.handleUpdate}
                  />
                </div> */}

               
              </div>
            </div>
          </Dialog>

        </div>
      </div>
    );
  }
}
