import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { PartnerPointService } from '../../../service/partnerPoint/PartnerPointService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserOtherAccessService } from '../../../service/UserOtherAccessService';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';

let cloneStateBeforeMount;
let urlId;
let error = []
export class MappedInstitute extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonDisabled: true,
            purchaseCodeSearchBtnDisabled: true,
            instituteIDSearchBtnDisabled: true,
            mappedInstituteList: [],
            dataTableIsLoading: false,
            instituteID: '',
            purchaseCodeError: {},
            instituteInformation: {},
            mappedInstituteInfoObj: {
                mappingTitle: '',
                productPurchaseCodeDTO: {
                    productPurchaseCodeID: 0,
                    purchaseCode: "",
                    productPurchaseLogDTO: {
                        productPurchaseID: 0,
                        productInfoDTO: {
                            productID: 0,
                            productName: "",
                        }
                    }
                },
                edumanDetailsInfoDTO: {
                    emDetailsId: ''
                },
                urlInfoDTO: {
                    urlInfoID: ''
                }
            },
            instituteIDErrorObj: {},
            firstInputFieldIsLoading: false,
            purchaseCodeSearchView: false,
            purchaseCodeSearchViewError: false,
            purchaseCodeSearchErrorMsgBody: '',
            secondInputFieldIsLoading: false,
            instituteIDSearchView: false,
            instituteIDSearchViewError: false,
            instituteIDSearchViewErrorMsgBody: '',
            purchaseCode: '',
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: '',
            purchaseInformation: {
                productName: '',
            },
            // error:[]
        };

        this.PartnerPointService = new PartnerPointService();
        this.userOtherAccessService = new UserOtherAccessService();
        this.netiContentLoader = new NetiContentLoader();
        this.DwsService = new DwsService();
        this.reloadCoreUrlIdFromLocal();

        this.resetInputField = this.resetInputField.bind(this);
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            let { urlInfoDTO } = this.state.mappedInstituteInfoObj
            urlInfoDTO.urlInfoID = urlId;
            this.setState({ urlInfoDTO })
            this.setState({ homeReturnButton: false })
            this.viewMappedInstituteInfoList();
        }
        else {
            this.setState({ homeReturnButton: true })
        }
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    purchaseCodeHandler = (event) => {
        let { purchaseCodeError } = this.state
        purchaseCodeError["purchaseCode"] = "";
        this.setState({ purchaseCode: event.target.value, purchaseCodeError });
    }

    purchaseCodeCheck = (e) => {
        this.setState({ purchaseInformation: cloneStateBeforeMount.purchaseInformation, purchaseCodeSearchErrorMsgBody: '', purchaseCodeSearchView: false });
        if (this.purchaseCodeError()) {
            this.setState({ topProgressBar: true, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: true, errorMsgVisible: false, purchaseCodeSearchView: true });
            this.PartnerPointService.getUserPurchaseCode(this.state.purchaseCode)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {

                            let productDefaultCode = body.productPurchaseLogDTO.productInfoDTO.productDefaultCode

                            if (productDefaultCode == 450) {
                                let { purchaseInformation } = this.state;
                                purchaseInformation.productName = body.productPurchaseLogDTO.productInfoDTO.productName;
                                purchaseInformation.productID = body.productPurchaseLogDTO.productInfoDTO.productID;
                                purchaseInformation.usedStatus = body.usedStatus;
                                purchaseInformation.purchaseDate = NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
                                purchaseInformation.generateDate = NetiDateUtils.getAnyShortForm(body.generateDate, 'YYYY-MM-DD');
                                purchaseInformation.productPurchaseCodeID = body.productPurchaseCodeID;
                                this.setState({ purchaseInformation, purchaseCodeSearchBtnDisabled: false, topProgressBar: false, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: false, errorMsgVisible: false, purchaseCodeSearchView: true });
                            }
                            else {
                                this.setState({
                                    purchaseCodeSearchBtnDisabled: true,
                                    firstInputFieldIsLoading: false,
                                    topProgressBar: false,
                                    purchaseCodeSearchErrorMsgBody: "Purchase code is invalid for this segment",
                                    purchaseCodeSearchViewError: true,
                                    purchaseCodeSearchView: true,
                                    purchaseInformation: {
                                        productName: ''
                                    }
                                });
                            }
                        })
                    } else {
                        this.PartnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({
                                    purchaseCodeSearchBtnDisabled: true,
                                    firstInputFieldIsLoading: false,
                                    topProgressBar: false,
                                    purchaseCodeSearchErrorMsgBody: resp,
                                    purchaseCodeSearchViewError: true,
                                    purchaseCodeSearchView: true,
                                    purchaseInformation: {
                                        productName: ''
                                    }
                                });
                            })
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, purchaseCodeSearchBtnDisabled: true, firstInputFieldIsLoading: false, errorMsgVisible: true, purchaseCodeSearchView: false, purchaseCodeSearchViewError: false, errorMsgBody: 'Unable to load. Please check your connection.' })

                });
        }
    }

    purchaseCodeError = () => {
        let { purchaseCodeError } = this.state;
        let formIsValid = true;
        if (this.state.purchaseCode === '') {
            formIsValid = false;
            purchaseCodeError["purchaseCode"] = "Purchase Code can't left empty";
        } else {
            purchaseCodeError["purchaseCode"] = "";
        }
        this.setState({ purchaseCodeError });
        return formIsValid;
    }

    onChangeinstituteID = (e) => {
        let { instituteIDErrorObj } = this.state;
        instituteIDErrorObj["instituteID"] = ''
        this.setState({ instituteID: e.target.value, instituteIDErrorObj });
    }

    instituteIDError = () => {
        let { instituteIDErrorObj } = this.state;
        let formIsValid = true;
        if (this.state.instituteID === '') {
            formIsValid = false;
            instituteIDErrorObj["instituteID"] = "Institute ID can't left empty";
        } else {
            instituteIDErrorObj["instituteID"] = "";
        }
        this.setState({ instituteIDErrorObj });
        return formIsValid;
    }

    searchInstituteID = (e) => {
        this.setState({ instituteInformation: cloneStateBeforeMount.instituteInformation, instituteIDSearchViewErrorMsgBody: '', instituteIDSearchView: false });
        if (this.instituteIDError()) {
            this.setState({ topProgressBar: true, secondInputFieldIsLoading: true, errorMsgVisible: false, instituteIDSearchViewError: false, instituteIDSearchView: true });
            this.DwsService.getEdumanInstituteInfoByID(this.state.instituteID, urlId)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            console.log("institute info", body);
                            this.setState({ instituteInformation: body, instituteIDSearchBtnDisabled: false, topProgressBar: false, instituteIDSearchView: true, secondInputFieldIsLoading: false, errorMsgVisible: false, instituteIDSearchViewError: false });
                        })
                    } else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({
                                    instituteIDSearchBtnDisabled: true,
                                    secondInputFieldIsLoading: false,
                                    topProgressBar: false,
                                    instituteIDSearchViewErrorMsgBody: resp,
                                    instituteIDSearchViewError: true,
                                    instituteIDSearchView: true,
                                    instituteInformation: {
                                        instituteContact: ''
                                    }
                                });
                            })
                    }
                }).catch(error => {
                    this.setState({ instituteIDSearchBtnDisabled: true, topProgressBar: false, secondInputFieldIsLoading: false, instituteIDSearchView: false, instituteIDSearchViewError: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })

                });
        }
    }

    viewMappedInstituteInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });

        this.DwsService.getURLMappedInstituteList(urlId)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("mapped list", body);
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].approvedStatus === 0) {
                                body[i].approvedStatus = 'Pending';
                            }
                            else if (body[i].approvedStatus === 1) {
                                body[i].approvedStatus = 'Approved';
                            }
                            else if (body[i].approvedStatus === 2) {
                                body[i].approvedStatus = 'Rejected';
                            }
                        }
                        this.setState({ mappedInstituteList: body, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                    });
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, dataTableIsLoading: false, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    onChangeMappingTitle = (e) => {
        error["mappingTitle"] = ''
        let { mappedInstituteInfoObj } = this.state;
        mappedInstituteInfoObj.mappingTitle = e.target.value;
        this.setState({ mappedInstituteInfoObj });
    }

    errorHandler() {
        let { mappedInstituteInfoObj } = this.state;
        let formIsValid = true;
        if (mappedInstituteInfoObj.mappingTitle === '') {
            formIsValid = false;
            error["mappingTitle"] = "Mapping title can't left empty";
        }

        this.setState({ mappedInstituteInfoObj });
        return formIsValid;
    }

    resetInputField() {

        let mappedInstituteInfoObj = {
            mappingTitle: '',
            productPurchaseCodeDTO: {
                productPurchaseCodeID: 0,
                purchaseCode: "",
                productPurchaseLogDTO: {
                    productPurchaseID: 0,
                    productInfoDTO: { productID: 0, productName: "", }
                }
            },
            edumanDetailsInfoDTO: { emDetailsId: '' },
            urlInfoDTO: { urlInfoID: '' }
        }
        this.setState({ mappedInstituteInfoObj: mappedInstituteInfoObj });

    }

    onSubmitMappedInstitute = () => {
        
        let { mappedInstituteInfoObj, instituteInformation, purchaseInformation } = this.state;
        mappedInstituteInfoObj.edumanDetailsInfoDTO.emDetailsId = instituteInformation.emDetailsId;
        mappedInstituteInfoObj.productPurchaseCodeDTO.purchaseCode = this.state.purchaseCode;
        mappedInstituteInfoObj.productPurchaseCodeDTO.productPurchaseCodeID = this.state.purchaseInformation.productPurchaseCodeID;
        mappedInstituteInfoObj.productPurchaseCodeDTO.generateDate = this.state.purchaseInformation.generateDate;
        mappedInstituteInfoObj.productPurchaseCodeDTO.productPurchaseLogDTO.productPurchaseID = this.state.purchaseInformation.productPurchaseCodeID;
        mappedInstituteInfoObj.productPurchaseCodeDTO.productPurchaseLogDTO.productInfoDTO.productID = this.state.purchaseInformation.productID;
        mappedInstituteInfoObj.productPurchaseCodeDTO.productPurchaseLogDTO.productInfoDTO.productName = this.state.purchaseInformation.productName;

        if (this.errorHandler()) {
            this.setState({ mappedInstituteInfoObj, topProgressBar: true, errorMsgVisible: false });
            // console.log("mappedInstituteInfoObj save", mappedInstituteInfoObj);

            console.log("mappedInstituteInfoObj", mappedInstituteInfoObj);

            this.DwsService.dwsCoreURLMapping(mappedInstituteInfoObj)
                .then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully submitted!" });
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.viewMappedInstituteInfoList();
                        this.resetInputField();
                    } else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }

    }

    focusSearchPurchaseCode = e => {
        this.setState({ searchLabelPurchaseCode: "Search", addSearchBtnClassPurchaseCode: "nw-button p-button-primary text-color-white" });
    }

    blurSearchPurchaseCode = e => {
        this.setState({ searchLabelPurchaseCode: '', addSearchBtnClassPurchaseCode: "" });
    }

    focusSearchInstituteId = e => {
        this.setState({ searchLabelInstituteId: "Search", addSearchBtnClassInstituteId: "nw-button p-button-primary text-color-white" });
    }

    blurSearchInstituteId = e => {
        this.setState({ searchLabelInstituteId: '', addSearchBtnClassInstituteId: "" });
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, firstInputFieldIsLoading, instituteInformation, secondInputFieldIsLoading } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Mapped Institute List
    </div>;

        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress />

                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                {this.state.homeReturnButton === false ?
                    <div className="main-section">
                        <div className="p-grid nw-form">

                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                            <InputText
                                                placeholder="Enter Purchase Code"
                                                value={this.state.purchaseCode}
                                                style={{ width: "15%" }}
                                                type="text"
                                                name="purchaseCode"
                                                onChange={(e) => this.purchaseCodeHandler(e)}
                                                keyfilter="num"
                                                onFocus={this.focusSearchPurchaseCode}
                                                onBlur={this.blurSearchPurchaseCode}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClassPurchaseCode}
                                                label={this.state.searchLabelPurchaseCode}
                                                icon="fas fa-search"
                                                onClick={(e) => this.purchaseCodeCheck(e)}
                                                onFocus={this.focusSearchPurchaseCode}
                                                onBlur={this.blurSearchPurchaseCode}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.purchaseCodeError["purchaseCode"]}</span>
                                    </div>

                                    {this.state.purchaseCodeSearchView ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-search-view">
                                                {this.state.purchaseCodeSearchViewError ?
                                                    <center className="error-message">{this.state.purchaseCodeSearchErrorMsgBody}</center> :
                                                    <div>

                                                        {
                                                            this.state.purchaseInformation.productName === '' ? '' :
                                                                <div>
                                                                    <div className="p-col-12 p-xl-12">
                                                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Product Name</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {this.state.purchaseInformation.productName}
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Use Status</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {(this.state.purchaseInformation.usedStatus === 0) ? "Unused" : this.state.purchaseInformation.usedStatus}
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {firstInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Purchase Date</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {this.state.purchaseInformation.purchaseDate}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>

                                                        }

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                            <InputText
                                                value={this.state.instituteID}
                                                onChange={(e) => this.onChangeinstituteID(e)}
                                                placeholder="Enter Institute ID"
                                                showClear={true}
                                                style={{ width: "15%" }}
                                                type="text"
                                                name="instituteID"
                                                onFocus={this.focusSearchInstituteId}
                                                onBlur={this.blurSearchInstituteId}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClassInstituteId}
                                                label={this.state.searchLabelInstituteId}
                                                icon="pi pi-search"
                                                onClick={(e) => this.searchInstituteID(e)}
                                                onFocus={this.focusSearchInstituteId}
                                                onBlur={this.blurSearchInstituteId}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.instituteIDErrorObj["instituteID"]}</span>
                                    </div>

                                    {this.state.instituteIDSearchView ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-search-view">
                                                {this.state.instituteIDSearchViewError ?
                                                    <center className="error-message">{this.state.instituteIDSearchViewErrorMsgBody}</center> :
                                                    <div>

                                                        {
                                                            this.state.instituteInformation.instituteContact === '' ? '' :
                                                                <div>
                                                                    <div className="p-col-12 p-xl-12">
                                                                        {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Institute Name</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {instituteInformation.instituteName}
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Address</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {instituteInformation.instituteAddress}
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Mobile No</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {instituteInformation.instituteContact}
                                                                                </div>

                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    <div className="p-col-12 p-xl-12">
                                                                        {secondInputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                            <div className="p-inputgroup">
                                                                                <span className="task-badge found"></span>
                                                                                <span className="p-inputgroup-addon">Email Address</span>
                                                                                <span className="p-inputgroup-colon">:</span>
                                                                                <div className="nw-inputgroup-desc">
                                                                                    {instituteInformation.instituteEmail}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>

                                                        }

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon font-size-12">Institute Mapping Title<span>*</span></span>
                                            <InputText
                                                value={this.state.mappedInstituteInfoObj.mappingTitle}
                                                className="custom-form-control"
                                                onChange={this.onChangeMappingTitle}
                                                name="instituteTitle"
                                                placeholder="Enter Mapping Title"
                                            />
                                        </div>
                                        <span className="error-message">{error["mappingTitle"]}</span>
                                    </div>

                                    <div className="p-col-12 nw-button-parent">

                                        <Button
                                            className="p-button-primary nw-button nw-button-right"
                                            label="Save"
                                            icon="pi pi-check"
                                            onClick={this.onSubmitMappedInstitute}
                                            disabled={this.state.purchaseCodeSearchBtnDisabled == false && this.state.instituteIDSearchBtnDisabled == false ? !this.state.buttonDisabled : this.state.buttonDisabled}
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table">
                                {this.state.dataTableIsLoading ?
                                    this.netiContentLoader.MyPointTableLoader() :
                                    <DataTable
                                        value={this.state.mappedInstituteList}
                                        selectionMode="single"
                                        header={tableHeader}
                                        selection={this.state.postpaidBillList}
                                        onSelectionChange={event => this.setState({ postpaidBillList: event.value })}
                                        responsive={true}
                                        paginator={true}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        rows={10}
                                        columnResizeMode="fit"
                                    >
                                        <Column field="mappingTitle" header="Institute Mapping Title" />
                                        <Column field="edumanDetailsInfoDTO.instituteName" header="Institute Name" />
                                        <Column field="edumanDetailsInfoDTO.instituteId" header="Institute ID" />
                                        <Column field="edumanDetailsInfoDTO.instituteContact" header="Institute Contact No." />
                                        <Column field="approvedStatus" header="Status" />
                                    </DataTable>
                                }
                            </div>
                        </div>

                    </div>

                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }

            </div>

        );
    }
}
