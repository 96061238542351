import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Growl } from 'primereact/growl';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { BalanceService } from '../../../../service/myPoint/BalanceService';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import { SplitButton } from 'primereact/splitbutton';

// pdf related import
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";

import {
	pdfmeta,
	pdfday,
	powerddBy_landscape,
	powerddBy_portrait,
	date_landscape,
	date_portrait,
	pfont,
	btmLine_landscape,
	btmLine_portrait,
	getPdfHeader
} from "../../../common/JsPdfEssentialFile";
import { pdfDownloadLandscape_TableWithSubTitle } from '../../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';

let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
// pdf related import end

let maxDate = new Date();
let angle = '';
export class BalanceLogTransfer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			viewNote: '',
			first: 0,
			rows: 10,
			tableView: false,
			errorMsgVisible: false,
			topProgressBar: false,
			errorMsgBody: '',
			balanceRecordsErr: {},
			balanceTransferRecords:
			{
				requestStartDate: "",
				requestEndDate: "",

			},

			date: "",
			transactionType: "",
			netiID: "",
			name: "",
			mobileNo: "",
			transferAmount: "",

			dataTableValue: [],
			dataTableIsLoading: false,
			items: [
				{ label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
				{ label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
			]
		}

		this.balanceService = new BalanceService();
		this.netiDateUtils = new NetiDateUtils();
		this.NetiContentLoader = new NetiContentLoader();
		this.validatorUtility = new ValidatorUtility();

		this.exportPdf = this.exportPdf.bind(this);
		this.exportExcel = this.exportExcel.bind(this);
		this.exportExcelLog = this.exportExcelLog.bind(this);

	}

	onSearchBtn = (e, props) => {
		if (this.balanceRecordsHandleError()) {
			this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
			let { balanceTransferRecords } = this.state;
			balanceTransferRecords.limit = 10;

			if (Date.parse(balanceTransferRecords.requestEndDate) >= Date.parse(balanceTransferRecords.requestStartDate)) {
				this.balanceService.getUserBalanceTransferRecords(balanceTransferRecords)
					.then((res) => {
						if (res.status == 200) {
							return res.json().then((body) => {

								if (body.length == 0) {
									return this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found.' })
								}

								for (let i = 0; i < body.length; i++) {
									body[i].transactionDate = NetiDateUtils.getAnyShortForm(body[i].transactionDate, 'DD-MMM-YYYY hh:mm:ss a');;
								}

								this.setState({ dataTableValue: body, topProgressBar: false, tableView: true, dataTableIsLoading: false });

							});
						} else {
							this.balanceService.Auth.handleErrorStatus(res)
								.then((responseBody) => {
									this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
								});
						}
					}).catch(error => {
						this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
					});
			} else {
				this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be greater than To Date." });
				this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: false });
			}

		}

	}

	onChangeStartDate = (e) => {
		let { balanceTransferRecords } = this.state;
		balanceTransferRecords[e.target.name] = e.target.value
		this.setState({ balanceTransferRecords });
		this.clearDateError(e.target.name);

	}

	onChangeEndDate = (e) => {
		let { balanceTransferRecords } = this.state;
		balanceTransferRecords[e.target.name] = e.target.value
		this.setState({ balanceTransferRecords });
		this.clearDateError(e.target.name);
	}

	balanceRecordsHandleError = () => {
		let balanceRecordsErr = {};
		let formIsValid = true;

		if (this.state.balanceTransferRecords.requestStartDate === '') {
			formIsValid = false;
			balanceRecordsErr["requestStartDate"] = "Start Date can't left empty.";
		}
		if (this.state.balanceTransferRecords.requestEndDate === '') {
			formIsValid = false;
			balanceRecordsErr["requestEndDate"] = "End Date can't left empty.";
		}
		if (this.state.balanceTransferRecords.transactionType === '') {
			formIsValid = false;
			balanceRecordsErr["transactionType"] = "Transaction type can't left empty.";
		}
		this.setState({ balanceRecordsErr: balanceRecordsErr });
		return formIsValid;
	}

	clearDateError = (name) => {
		let { balanceRecordsErr } = this.state;
		balanceRecordsErr[name] = ''
		this.setState({ balanceRecordsErr })
	}

	noteBody = (rowData, column) => {
		return <div className='text-center'>
			<Button
				className="nw-action-button note"
				icon="fas fa-info"
				title='Click to show notes'
				onClick={(e) => this.viewDialog(rowData)}
			/>
		</div>
	}

	onHide = (event) => {
		this.setState({ visible: false });
	}

	viewDialog = (rowData) => {
		this.setState({ visible: true });

		this.setState({ date: this.state.transactionDate === "null" ? " " : NetiDateUtils.getAnyShortForm(rowData['transactionDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['transactionDate']) })
		this.setState({ transactionType: rowData.transactionFor })
		this.setState({ netiID: rowData.customID })
		this.setState({ name: rowData.fullName })
		this.setState({ viewNote: rowData.note })

		this.setState({ mobileNo: rowData.basicMobile })
		this.setState({ transferAmount: rowData.amount })

	}

	balanceDateFormat = (rowData, Column) => {
		return <span>{NetiDateUtils.getAnyShortForm(rowData['transactionDate'], 'DD-MMM-YYYY')} &nbsp; {NetiDateUtils.getHHMMSSFForHome(rowData['transactionDate'])}</span>;

	}

	transactionTypeBody = (rowData, column) => {
		(rowData.transactionFor === "Send") ?
			angle = "right text-danger"
			: angle = "left text-success"

		return <div>
			{rowData.transactionFor}
			<i className={"pull-right fas fa-angle-" + angle} style={{ "fontSize": "18px" }}></i>

		</div>;
	}

	transactionAmountBody = (rowData) => {
		if (rowData.transactionFor === "Send") {
			return <span className="text-danger">
				<span
				>
					<b> {this.validatorUtility.currencyFormatter(rowData['amount'])}</b>
				</span>
			</span>;
		} else if (rowData.transactionFor === "Receive") {
			return <span className="text-success">
				<span
				>
					<b> {this.validatorUtility.currencyFormatter(rowData['amount'])}</b>
				</span>
			</span>;
		}
		else {
			return (<b> {this.validatorUtility.currencyFormatter(rowData['amount'])}</b>);
		}
	}

	changeTransactionTypeFontColor = (transactionType) => {

		if (transactionType === "Send") {
			return <span className="text-danger">
				<span
				>
					{transactionType}
				</span>
			</span>;
		} else if (transactionType === "Receive") {
			return <span className="text-success">
				<span
				>
					{transactionType}
				</span>
			</span>;
		}
		else {
			return (<span> {transactionType}
			</span>);
		}
	}

	changeTransferAmountFontColor = (transferAmount) => {

		if (this.state.transactionType === "Send") {
			return <span className="text-danger">
				<span
				>
					{this.validatorUtility.currencyFormatter(transferAmount)}            </span>
			</span>;
		} else if (this.state.transactionType === "Receive") {
			return <span className="text-success">
				<span
				>
					{this.validatorUtility.currencyFormatter(transferAmount)}
				</span>
			</span>;
		}
		else {
			return (<span>{this.validatorUtility.currencyFormatter(transferAmount)}
			</span>);
		}
	}

	exportPdf = () => {

		let pdfColumns = [
			{ title: "Date", dataKey: "transactionDate" },
			{ title: "Transaction Type", dataKey: "transactionFor" },
			{ title: "Neti ID", dataKey: "customID" },
			{ title: "Name", dataKey: "fullName" },
			{ title: "Transfer Amount", dataKey: "amount" },
		];

		let tableColumnStyles = {
			amount: { halign: "right" },
		}

		pdfDownloadLandscape_TableWithSubTitle("Wallet Transfer Logs", 120, pdfTitleHeader, pdfTitleHeaderData, pdfColumns, this.state.dataTableValue, tableColumnStyles, "wallet_logs_transfer.pdf");

	}

	exportExcel() {
		document.getElementById("clickExcelBtn").click();
	}

	exportExcelLog() { console.log('excel downloaded'); }

	render() {

		let { dataTableValue } = this.state;
		let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
		let tableHeader = <div className="header-title"><div className="header-title-left">Balance Transfer List</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div></div>;

		pdfTitleHeader = [
			{ dataKey: "A" },
			{ dataKey: "B" },
			{ dataKey: "C" },
			{ dataKey: "D" },
		];
		pdfTitleHeaderData = [
			{ A: "From Date", B: NetiDateUtils.getAnyShortForm(this.state.balanceTransferRecords.requestStartDate, 'DD-MMM-YYYY'), C: "End Date", D: NetiDateUtils.getAnyShortForm(this.state.balanceTransferRecords.requestEndDate, 'DD-MMM-YYYY') }
		];

		// excel
		let excelColumns = "";
		let excelFileName = "Wallet_Transfer_Logs.xlsx";
		let excelSheetName = "Wallet Transfer Logs";
		excelColumns = [
			<Workbook.Column value="transactionDate" label="Date" />,
			<Workbook.Column value="transactionFor" label="Transaction Type" />,
			<Workbook.Column value="customID" label="Neti ID" />,
			<Workbook.Column value="fullName" label="Name" />,
			<Workbook.Column value="amount" label="Transfer Amount" />,
		];

		return (
			<div className="p-fluid">
				<Growl ref={(el) => this.growl = el} />
				{topProgressBar ?
					<ErrorMessageView
						topProgressBar={topProgressBar}
					/>
					: null
				}
				{errorMsgVisible ?
					<ErrorMessageView
						errorMsgVisible={errorMsgVisible}
						errorMsgBody={errorMsgBody}
					/>
					: null
				}
				<div className="p-grid nw-form">
					<div className="p-col-12 p-xl-12">
						<div className="p-grid nw-form-body">
							<div className="p-col-12 p-lg-12 p-xl-5">
								<div className="p-inputgroup">
									<span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
									<Calendar
										value={this.state.balanceTransferRecords.requestStartDate}
										onChange={this.onChangeStartDate}
										showIcon={true}
										yearRange="2010:2030"
										dateFormat="dd/mm/yy"
										monthNavigator={true}
										yearNavigator={true}
										dateOnly="true"
										maxDate={maxDate}
										name="requestStartDate"
										placeholder="Select Date"
									/>
								</div>
								<span className="error-message">{this.state.balanceRecordsErr["requestStartDate"]}</span>
							</div>
							<div className="p-col-12 p-lg-12 p-xl-5">
								<div className="p-inputgroup">
									<span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
									<Calendar
										value={this.state.balanceTransferRecords.requestEndDate}
										onChange={this.onChangeEndDate}
										showIcon={true}
										yearRange="2010:2030"
										dateFormat="dd/mm/yy"
										monthNavigator={true}
										yearNavigator={true}
										dateOnly="true"
										maxDate={maxDate}
										name="requestEndDate"
										placeholder="Select Date" />
								</div>
								<span className="error-message">{this.state.balanceRecordsErr["requestEndDate"]}</span>
							</div>

							<div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
								<Button
									label="Search"
									icon="fas fa-search"
									className="p-button p-button-primary nw-button nw-button-center"
									onClick={(e) => this.onSearchBtn(e, this.props)}
								/>
							</div>
						</div>

						<div className="p-grid">
							<div className="p-col-12 p-xl-12">
								<div className="nw-data-table nw-data-table-tabview">
									{
										this.state.tableView === true ?
											this.state.dataTableIsLoading ?
												this.NetiContentLoader.MyPointTableLoader()
												:
												<div>
													<DataTable
														value={this.state.dataTableValue}
														selectionMode="single"
														header={tableHeader}
														selection={this.state.dataTableSelection}
														onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
														responsive={true}
														paginator={true}
														first={this.state.first}
														rows={this.state.rows}
														rowsPerPageOptions={[10, 20, 30]}
														onPageChange={this.onPageChange}
													>
														<Column field="transactionDate" header="Date & Time" filter={true} className="table-datetime" />
														<Column field="transactionFor" header="Transaction Type" body={this.transactionTypeBody} filter={true} />
														<Column field="customID" header="Neti ID" filter={true} />
														<Column field="fullName" header="Name" filter={true} />
														<Column field="amount" header="Transfer Amount" className="text-right" body={this.transactionAmountBody} filter={true} />
														<Column field="note" body={this.noteBody} header="Details" style={{ width: "95px" }} />
													</DataTable>

													<div className="p-col-12 p-xl-12 nw-button-parent">

														<SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

														<div style={{ display: 'none' }}>

															<Workbook
																filename={excelFileName}
																element={
																	<Button
																		label="Download Excel"
																		id="clickExcelBtn"
																		icon="fas fa-download"
																		className="p-button p-button-primary nw-button nw-button-right"
																	/>
																}
															>
																<Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
																	{excelColumns}
																</Workbook.Sheet>
															</Workbook>

														</div>

													</div >

													{/* <div className="p-col-12 nw-button-parent" style={{paddingRight:"0px"}}>
                                                        <Button
                                                            label="Download PDF"
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            icon="fas fa-file-pdf"
                                                            onClick={this.exportPdf}
                                                        />
                                                    </div> */}
												</div>
											:

											<BlankDataTableAnim />
									}

								</div>

								<Dialog header="Transfer Details" className="nw-dialog" visible={this.state.visible} onHide={this.onHide} closable>
									<div className="p-fluid">
										<div className="p-grid nw-form">
											<div className="p-col-12">

												<div className="formControl">
													<div className="p-inputgroup">
														<span className="p-inputgroup-addon nw-inputgroup-label">
															Date
														</span>
														<div className="nw-inputgroup-desc">
															{this.state.date}
														</div>
													</div>
												</div>

												<div className="formControl">
													<div className="p-inputgroup">
														<span className="p-inputgroup-addon nw-inputgroup-label">
															Transaction Type
														</span>
														<div className="nw-inputgroup-desc">
															{this.state.transactionType ? this.changeTransactionTypeFontColor(this.state.transactionType) : ''}
														</div>
													</div>
												</div>

												<div className="formControl">
													<div className="p-inputgroup">
														<span className="p-inputgroup-addon nw-inputgroup-label">
															Neti ID
														</span>
														<div className="nw-inputgroup-desc">
															{this.state.netiID}
														</div>
													</div>
												</div>

												<div className="formControl">
													<div className="p-inputgroup">
														<span className="p-inputgroup-addon nw-inputgroup-label">
															Name
														</span>
														<div className="nw-inputgroup-desc">
															{this.state.name}
														</div>
													</div>
												</div>
												{/* 
												<div className="formControl">
													<div className="p-inputgroup">
														<span className="p-inputgroup-addon nw-inputgroup-label">
															Mobile No.
														</span>
														<div className="nw-inputgroup-desc">
															{this.state.mobileNo}
														</div>
													</div>
												</div> */}

												<div className="formControl">

													<div className="p-inputgroup">
														<span className="p-inputgroup-addon nw-inputgroup-label">
															Transfer Amount
														</span>
														<div className="nw-inputgroup-desc">
															{this.state.transferAmount ? this.changeTransferAmountFontColor(this.state.transferAmount) : ''}
														</div>
													</div>

												</div>

												<div className="formControl">

													<div className="p-inputgroup">
														<span className="p-inputgroup-addon nw-inputgroup-label">
															Note
														</span>
														<div className="nw-inputgroup-desc">
															{this.state.viewNote}
														</div>
													</div>

												</div>

											</div>
										</div>
									</div>

								</Dialog>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}