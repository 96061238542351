import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';

import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { pdfDownloadPortrait_TableWithSubTitle, pdfDownloadPortrait_TableOnly } from '../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class PartnerIncomeStatement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [
                { "netiID": "100000025", "name": "kabir", "mobileNo": "01675886072", "area": "mirpur, Dhaka", "totalAmount": "4444" },
            ],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    totalAmountBody = (rowData) => {
        return (
            <Link to={{ pathname: MANAGEMENT_POINT.PARTNER_INCOME_STATEMENT_DETAILS }}>
                <u>{rowData.totalAmount}</u>
            </Link>
        )
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Neti ID", dataKey: "netiID" },
            { title: "Name", dataKey: "name" },
            { title: "Mobile No.", dataKey: "mobileNo" },
            { title: "Area", dataKey: "area" },
            { title: "Total Amount", dataKey: "totalAmount" },
        ]

        let tableColStyles = { totalAmount: { halign: "right" }, }

        pdfDownloadPortrait_TableOnly("Partner Income Info", 75, pdfColumns, this.state.dataTableValue, tableColStyles, "Partner_Income_Statement.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Partner Income Info</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;
        // console.log('dfsdf', this.state.dataTableValue);

        // excel
        let excelColumns = "";
        let excelFileName = "Partner_Income_Statement.xlsx";
        let excelSheetName = "Partner_Income_Statement";
        excelColumns = [
            <Workbook.Column value="netiID" label="Neti ID" />,
            <Workbook.Column value="name" label="Name" />,
            <Workbook.Column value="mobileNo" label="Mobile No." />,
            <Workbook.Column value="area" label="Area" />,
            <Workbook.Column value="totalAmount" label="Total Amount" />,
        ];
        // end excel

        let partnerTypeOption = [
            { "label": "MBP", "value": "MBP" },
            { "label": "ZBP", "value": "ZBP" },
            { "label": "BDP", "value": "BDP" },
            { "label": "BEP", "value": "BEP" },
        ]
        return (
            <div className="p-fluid">
                {/* <div className="p-grid"> */}
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            {/* <div className="p-col-12 p-xl-12"> */}
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Partner Type <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Year"
                                            options={partnerTypeOption}
                                            autoWidth={false}
                                        />

                                    </div>
                                </div>

                                <div className="p-col-12 p-lg-12 p-xl-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            name='requestStartDate'
                                            dateFormat='dd/mm/yy'
                                            // value=""
                                            // onChange=""
                                            showIcon={true}
                                            placeholder="Select Date"

                                            autoWidth={false}
                                            yearRange="2010:2030"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            showButtonBar={true}
                                        />
                                    </div>
                                    {/* <span className="error-message">{this.state.errors["requestStartDate"]}</span> */}

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            name='requestEndDate'
                                            dateFormat='dd/mm/yy'
                                            // value=""
                                            // onChange=""
                                            showIcon={true}
                                            placeholder="Select Date"
                                            autoWidth={false}
                                            yearRange="2010:2030"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            showButtonBar={true}

                                        />
                                    </div>
                                    {/* <span className="error-message">{this.state.errors["requestEndDate"]}</span> */}
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                    // onClick={this.onSubmitHandler}
                                    />
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        {/* </div> */}

                        <div className="nw-data-table">
                            <div className="p-col-12 p-lg-12 p-xl-12">

                                <DataTable
                                    header={tableHeader}
                                    responsive={true}
                                    selectionMode="single"
                                    paginator={true}
                                    rows={10}
                                    value={this.state.dataTableValue}
                                //selection={this.state.dataTableSelection}
                                >
                                    <Column field="netiID" header="Neti ID" filter={true} />
                                    <Column field="name" header="Name" filter={true} />
                                    <Column field="mobileNo" header="Mobile No." filter={true} />
                                    <Column field="area" header="Area" filter={true} />
                                    <Column field="totalAmount" header="Total Amount" filter={true} body={this.totalAmountBody} />

                                </DataTable>

                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                <div style={{ display: 'none' }}>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                label="Download Excel"
                                                id="clickExcelBtn"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>

                            </div >

                        </div>

                    </div>

                </div>
            </div>
        )
    }
}