import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from "primereact/components/inputtext/InputText";
import { ErrorMessageView } from '../common/ErrorMessageView';
import { UserCategoryService } from "../../service/UserCategoryService";
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import NetiContentLoader from '../common/NetiContentLoader';

export class EdumanManualBillProcess extends Component {

    constructor() {
        super();
        this.state = {
            billingYearList: [],
            instituteID: '',
            instituteIDError: {},
            btnDisabled: true,
            instituteInfo: {},
            instituteIDSearchIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            billingYear: '',
            billingMonth: '',
            visible: '',
            errors: [],
            firstDropDownIsLoading: false,
            dataTableIsLoading: false
        }
        this.onChangeBillingYear = this.onChangeBillingYear.bind(this);
        this.onChangeBillingMonth = this.onChangeBillingMonth.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.userCategoryService = new UserCategoryService();
        this.crmPointService = new CRMPointService();
    }

    componentWillMount() {
        this.getAcademicYearListByTypeID()
    }

    getAcademicYearListByTypeID = () => {
        this.setState({ topProgressBar: true, firstDropDownIsLoading: true, errorMsgVisible: false })

        this.userCategoryService.fetchListByDefaultCode("T107")
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ billingYearList: body, firstDropDownIsLoading: false, topProgressBar: false })
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                };
            }).catch((error) => {
                this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeBillingYear(value) {
        let { errors } = this.state;
        errors["billingYear"] = '';
        this.setState({ billingYear: value, errors })
    };
    onChangeBillingMonth(value) {
        let { errors } = this.state;
        errors["billingMonth"] = '';
        this.setState({ billingMonth: value, errors })
    };


    submitProcess = () => {
        let emBillInfo = {
            "billYear": this.state.billingYear,
            "billMonth": this.state.billingMonth,
            "emDetailsInfoDTO": {
                "emDetailsId": this.state.instituteInfo.emDetailsId
            }
        }
        if (this.handleError()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.crmPointService.generateEdumanBillManually(emBillInfo)
                .then(res => {
                    if (res.status === 201) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Bill Processed Successfully' });
                        this.setState({ billingYear: '', billingMonth: '', instituteID: '', instituteIDSearchView: false, instituteInfo: {}, btnDisabled: true, topProgressBar: false, errorMsgVisible: false });
                    } else {
                        this.crmPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }

    handleError = () => {

        let { errors } = this.state
        let formIsValid = true;

        if (this.state.billingYear === '' || this.state.billingYear === null) {
            formIsValid = false;
            errors["billingYear"] = "Billing Year can't left empty.";
        }

        if (this.state.billingMonth === '' || this.state.billingMonth === null) {
            formIsValid = false;
            errors["billingMonth"] = "Billing Month can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    instituteIDSearchHandler = () => {
        if (this.instituteIDFieldError()) {
            this.setState({ topProgressBar: true, instituteIDSearchView: true, instituteIDSearchIsLoading: true, errorMsgVisible: false, instituteIDSearchErrorVisible: false });
            this.crmPointService.findEmDetailsByInstituteId(this.state.instituteID)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ instituteInfo: body, btnDisabled: false });
                            this.setState({ topProgressBar: false, instituteIDSearchIsLoading: false, instituteIDSearchErrorVisible: false });
                        });
                    } else {
                        this.crmPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, btnDisabled: true, instituteIDSearchIsLoading: false, instituteIDSearchErrorVisible: true, instituteIDSearchErrorMsg: resBody })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, btnDisabled: true, instituteIDSearchView: false, instituteIDSearchIsLoading: false, instituteIDSearchErrorVisible: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
        }
    }

    instituteIDFieldError = () => {
        let { instituteIDError } = this.state;
        let formIsValid = true;
        this.setState({ instituteIDSearchView: false })

        if (this.state.instituteID === '') {
            formIsValid = false;
            instituteIDError["instituteID"] = "Institute ID can't left empty";
        } else {
            instituteIDError["instituteID"] = "";
        }
        this.setState({ instituteIDError });
        return formIsValid;
    }

    onChangeInstituteIDHandler = (e) => {
        let { instituteIDError } = this.state;
        instituteIDError["instituteID"] = "";
        this.setState({ instituteID: e.target.value, btnDisabled: true, instituteInfo: {}, instituteIDSearchView: false, instituteIDError });
    }

    instututeCheckStatus = (value) => {
        let instituteStatus = '';
        if (value === 1) {
            instituteStatus = "Active"
        } else if (value === 2) {
            instituteStatus = "Inactive"
        }
        return instituteStatus;
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let yearOptions = [];
        if (this.state.billingYearList != null && this.state.billingYearList.length) {
            yearOptions = this.state.billingYearList.map((item) => ({
                value: item.categoryName,
                label: item.categoryName,
            }));
        }

        let monthOptions = [
            {
                value: "January",
                label: "January"
            },
            {
                value: "February",
                label: "February"
            },
            {
                value: "March",
                label: "March"
            },
            {
                value: "April",
                label: "April"
            },
            {
                value: "May",
                label: "May"
            },
            {
                value: "June",
                label: "June"
            },
            {
                value: "July",
                label: "July"
            },
            {
                value: "August",
                label: "August"
            },
            {
                value: "September",
                label: "September"
            },
            {
                value: "October",
                label: "October"
            },
            {
                value: "November",
                label: "November"
            },
            {
                value: "December",
                label: "December"
            }
        ];



        return (

            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                        <InputText
                                            value={this.state.instituteID}
                                            placeholder="Enter Institute ID"
                                            style={{ width: "15%" }}
                                            type="text"
                                            name="instituteID"
                                            onChange={(e) => this.onChangeInstituteIDHandler(e)}
                                            keyfilter="pint"
                                            onFocus={this.focusSearchNetiId}
                                            onBlur={this.blurSearchNetiId}
                                        />
                                        <Button
                                            className={"p-button-animation " + this.state.addSearchBtnClassNetiId}
                                            label={this.state.searchLabelNetiId}
                                            icon="fas fa-search"
                                            onClick={this.instituteIDSearchHandler}
                                            onFocus={this.focusSearchNetiId}
                                            onBlur={this.blurSearchNetiId}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.instituteIDError["instituteID"]}</span>
                                </div>

                                {this.state.instituteIDSearchView ?
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-search-view">
                                            {this.state.instituteIDSearchErrorVisible ?
                                                <center className="error-message">{this.state.instituteIDSearchErrorMsg || 'No Data Found'}</center> :

                                                <div>
                                                    {
                                                        this.state.instituteIDSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Insitute ID</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.instituteInfo.instituteId || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                    {
                                                        this.state.instituteIDSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label"> Institute Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.instituteInfo.instituteName || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                    {
                                                        this.state.instituteIDSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label"> Institute Status</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.instituteInfo && this.state.instituteInfo.instituteStatus ? this.instututeCheckStatus(this.state.instituteInfo.instituteStatus) : '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                    {
                                                        this.state.instituteIDSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label"> Institute Address</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.instituteInfo.instituteAddress || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                    {
                                                        this.state.instituteIDSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label"> Institute Contact</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.instituteInfo.instituteContact || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                    {
                                                        this.state.instituteIDSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label"> Institute Email</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.instituteInfo.instituteEmail || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                    {
                                                        this.state.instituteIDSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label"> Contact Person</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.instituteInfo.instituteAdminContact || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                    {
                                                        this.state.instituteIDSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :


                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Due Amount</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {this.state.instituteInfo.dueAmount || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }





                                                </div>
                                            }
                                        </div>
                                    </div> : ''
                                }

                                <div className="p-col-12 p-xl-12">
                                    {this.state.firstDropDownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Billing Year <span>*</span></span>
                                            <Dropdown
                                                placeholder='Select Billing Year'
                                                name="billingYear"
                                                options={yearOptions}
                                                value={this.state.billingYear}
                                                onChange={(e) => this.onChangeBillingYear(e.target.value)}
                                                filter={true}
                                                autoWidth={false}
                                            />
                                        </div>
                                    }
                                    <span className='error-message'>{this.state.errors['billingYear']}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Billing Month <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Billing Month'
                                            name="status"
                                            options={monthOptions}
                                            value={this.state.billingMonth}
                                            onChange={(e) => this.onChangeBillingMonth(e.target.value)}
                                            filter={true}
                                            autoWidth={false}
                                        />
                                    </div>
                                    <span className='error-message'>{this.state.errors['billingMonth']}</span>
                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Process"
                                        icon="fas fa-search"
                                        disabled={this.state.btnDisabled}
                                        onClick={event => this.submitProcess(event)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}