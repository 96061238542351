import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { MemberInfoList } from './MemberInfoList';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import NetiContentLoader from '../../common/NetiContentLoader';
import { Link } from 'react-router-dom';
import { DownloadButton } from "../../../utils/DownloadButton";
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';

let urlId;
let dialogStatus = false;
let cloneStateBeforeMount;
let errors = {}

export class Administration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            memberInfo: {
                memberSerial: '',
                memberType: '',
                memberName: '',
                memberMobile: '',
                memberDesignation: '',
                memberEmail: '',
                totalDataFound: 0,
                coreUrlInfoDTO: {
                    urlInfoID: 1
                }
            },
            proPic: {
                extention: '',
                contentPic: '',
                contentName: ''
            },
            errors: {},
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            homeReturnButton: false

        }
        this.dwsService = new DwsService();
        this.netiFileHandler = new NetiFileHandler();
        this.validatorUtility = new ValidatorUtility();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.dwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }


    componentWillMount() {
        urlId = this.dwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            let { coreUrlInfoDTO } = this.state.memberInfo;
            coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ coreUrlInfoDTO });
            this.setState({ homeReturnButton: false })
        }
        else {
            this.setState({ homeReturnButton: true })
        }
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }


    onChangeMemberType = (e) => {
        this.emptyErrorMessage()
        let { memberInfo } = this.state;
        memberInfo.memberType = e.target.value;
        this.setState({ memberInfo });
    }

    onChangeMemberName = (e) => {
        this.emptyErrorMessage()
        let { memberInfo } = this.state;
        memberInfo.memberName = e.target.value;
        this.setState({ memberInfo });
    }

    onChangeMemberDesignation = (e) => {
        this.emptyErrorMessage()
        let { memberInfo } = this.state;
        memberInfo.memberDesignation = e.target.value;
        this.setState({ memberInfo });
    }

    onChangeMemberMobile = (e) => {
        this.emptyErrorMessage()
        let { memberInfo } = this.state;
        memberInfo.memberMobile = e.target.value;
        this.setState({ memberInfo });
    }

    onChangeMemberEmail = (e) => {
        this.emptyErrorMessage()
        let { memberInfo } = this.state;
        memberInfo.memberEmail = e.target.value;
        this.setState({ memberInfo });
    }

    emptyErrorMessage() {
        errors = {}
        this.setState({ errors });
    }

    onSubmitHandler = () => {
        let { memberInfo, errors, totalDataFound } = this.state;


        if (this.state.proPic.contentPic !== null) {
            memberInfo.imageContent = this.state.proPic.contentPic;
            memberInfo.imageSaveOrEditable = true;
            // let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            memberInfo.memberImgName = this.state.proPic.contentName;
        }

        if (this.addMemberFormHandleError()) {

            console.log("memberInfo", memberInfo);
            

            memberInfo.memberSerial = totalDataFound + 1;
            if (this.validatorUtility.emailIsValid(memberInfo.memberEmail) == true) {
                this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
                this.dwsService.createNewDwsMember(memberInfo)
                    .then(res => {
                        if (res.status == 201) {
                            this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });

                            this.setState({
                                proPic: cloneStateBeforeMount.proPic,
                                memberInfo: cloneStateBeforeMount.memberInfo,
                                dataTableIsLoading: false,
                                topProgressBar: false,
                                errorMsgVisible: false,
                            });
                        } else {
                            this.dwsService.Auth.handleErrorStatus(res)
                                .then((resp) => {
                                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                                });
                        }
                    }).catch((error) => {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });
            } else {
                errors["email"] = "Invalid Email format.";

            }

        }
    }

    onImageUpload(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });

        }
        console.log('proPic', this.state.proPic.contentName);
    }

    addMemberFormHandleError = () => {
        let { errors, memberInfo, proPic } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });


        if (memberInfo.memberType === '') {
            formIsValid = false;
            errors["type"] = "Type can't left empty.";
        }
        if (memberInfo.memberName === '') {
            formIsValid = false;
            errors["name"] = "Name can't left empty.";
        }
        if (memberInfo.memberDesignation === '') {
            formIsValid = false;
            errors["designation"] = "Designation can't left empty.";
        }
        if (memberInfo.memberMobile === '') {
            formIsValid = false;
            errors["mobileNo"] = "Mobile number can't left empty.";
        }
        if (memberInfo.memberEmail === '') {
            formIsValid = false;
            errors["email"] = "Email can't left empty.";
        }

        if (proPic.contentName === '') {
            formIsValid = false;
            errors["photoContent"] = "Image can't left empty.";
        }
        this.setState({ errors });
        return formIsValid;
    }



    render() {
        let { dwsUrlInfo, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">370 x 240 px (recommended)</span></div>

        let { memberInfo } = this.state;
        let memberListOption = [
            { label: 'Committee', value: 'Committee' },
            { label: 'Donor', value: 'Donor' },
        ];


        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                {this.state.homeReturnButton === false ?

                    <div className="main-section">
                        <div className="p-col-12 p-xl-12">
                            <Growl ref={(el) => this.growl = el} />

                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12 blur-section">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>
                                                <div className="nw-inputgroup-desc">
                                                    {this.state.totalDataFound ? (this.state.totalDataFound + 1) : 1}
                                                </div>
                                            </div>
                                            <span className="error-message">{this.state.errors["memberSerial"]}</span>

                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Type <span>*</span></span>
                                                <Dropdown
                                                    id="type"
                                                    name="type"
                                                    placeholder="Select member type"
                                                    options={memberListOption}
                                                    value={memberInfo.memberType}
                                                    onChange={this.onChangeMemberType}
                                                    // itemTemplate={this.bankTemplate}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["type"]}</span>

                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Name <span>*</span></span>
                                                <InputText
                                                    onChange={this.onChangeMemberName}
                                                    value={memberInfo.memberName}
                                                    placeholder="Enter member name"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["name"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Designation <span>*</span></span>
                                                <InputText
                                                    id="designation"
                                                    onChange={this.onChangeMemberDesignation}
                                                    value={memberInfo.memberDesignation}
                                                    placeholder="Enter member designation"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="designation"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["designation"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Mobile No. <span>*</span></span>
                                                <InputText
                                                    keyfilter="pint"
                                                    maxLength={11}
                                                    id="mobileNo"
                                                    onChange={this.onChangeMemberMobile}
                                                    value={memberInfo.memberMobile}
                                                    placeholder="Enter member mobile number"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="mobileNo"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["mobileNo"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Email <span>*</span></span>
                                                <InputText
                                                    id="email"
                                                    onChange={this.onChangeMemberEmail}
                                                    value={memberInfo.memberEmail}
                                                    placeholder="Enter member email address"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    name="email"
                                                    keyfilter="email"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["email"]}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Upload Image <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/GIF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    {
                                                        this.state.proPic.contentPic ?
                                                            <div className="image-view-main">
                                                                <div className="upload-image-view">
                                                                    <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={e => this.setState({ proPic: { contentPic: '' } })}
                                                                    />
                                                                    <img
                                                                        src={
                                                                            this.state.proPic.contentPic === '' ?
                                                                                'assets/images/demo.jpg'
                                                                                :
                                                                                "data:image/png;base64," + this.state.proPic.contentPic
                                                                        }
                                                                        style={{ "height": "80px" }}
                                                                    />


                                                                </div>
                                                                <div className="image-title">{this.state.proPic.contentName}</div>
                                                            </div>

                                                            :
                                                            <FileUpload 
                                                                chooseLabel={this.state.proPic.contentName || uploadFileLabel}
                                                                mode="basic"
                                                                maxFileSize={1000000} 
                                                                onSelect={this.onImageUpload.bind(this)} 
                                                                auto={true}
                                                                accept="image/*"
                                                            />
                                                    }

                                                </div>


                                                {/* <center>
                                                    {this.state.uploadImage.contentName}
                                                </center> */}
                                            </div>
                                            <span className='error-message'>{this.state.proPic.contentName ? null : this.state.errors['photoContent']}</span>
                                        </div>


                                        {/* <div className="p-col-12 p-xl-12 nw-upload-button">
                                            <FileUpload
                                                chooseLabel="Upload Photo"
                                                id="photoUpload"
                                                mode="basic"
                                                accept="image/*"
                                                maxFileSize={1000000}
                                                onSelect={this.onImageUpload.bind(this)}
                                                auto={true}
                                            />
                                            <center>{this.state.proPic.contentName}</center>

                                            <center><span className="error-message">{(this.state.proPic.contentName == '') && this.state.errors["photoContent"]}</span></center>

                                        </div> */}

                                        <div className="p-col-12 p-xl-12">
                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                label="Save"
                                                icon="fas fa-check"
                                                onClick={this.onSubmitHandler}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12 nw-data-table">
                                    <div>{this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <MemberInfoList countTableSize={(l) => this.setState({ totalDataFound: l })}
                                        />
                                    }
                                    </div>
                                </div>

                            </div>

                           
                        </div>
                       
                    </div>

                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }

            </div>
        );
    }
}