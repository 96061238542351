
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Dropdown } from 'primereact/dropdown';

// custom imports
import { DwsService } from '../../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../../common/ErrorMessageView';

let cmsId = 0;
export class PaymentCollectionInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,

            classAndGroupList: [],
            classConfigId: '',
            academicYear: '',

            dataTableValue: [],
            totalCountObj: {}
        }

        this.emptyHandleError = this.emptyHandleError.bind(this);

        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });
            this.getFetchClassAndGroupList(urlId)
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    getFetchClassAndGroupList(urlId) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.DwsService.getAdmisiaClassConfigurationList(urlId)
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        // console.log('body-cgList', body);
                        this.setState({ classAndGroupList: body.item });
                        this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                    });
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })

            });
    }

    onSearchHandler = () => {

        if (this.emptyHandleError()) {
            this.getPaymentCollectionInfo();
        }

    }

    getPaymentCollectionInfo() {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.getPaymentCollectionInfoList({ cmsId: cmsId, classConfigId: this.state.classConfigId, academicYear: this.state.academicYear })
            .then(res => {

                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('dataTable-body', body);

                        if (body.messageType == 1) {
                            this.setState({ dataTableValue: body.item });
                            this.totalCounterFunc(body.item);
                            this.setState({ dataTableIsLoading: false, topProgressBar: false });
                        } else {
                            this.setState({ dataTableValue: [] });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    }))
                }

            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    totalCounterFunc = (dataTableList) => {
        let totalPaymentCount = 0;
        dataTableList.forEach(element => {
            totalPaymentCount += element.paymentAmount;
        });
        this.setState({ totalPaymentCount: totalPaymentCount });
    }

    onChangeClassAndGroupList = (e) => {
        this.setState({ classConfigId: e.target.value });
        this.clearErrorMsg('classAndgroup');
    }

    onChangeAcademicYear = (e) => {
        this.setState({ academicYear: e.target.value });
        this.clearErrorMsg(e.target.name);
    }

    emptyHandleError() {

        let { classConfigId, academicYear, errors } = this.state;
        let formIsValid = true;

        if (classConfigId === '' || classConfigId === null) {
            formIsValid = false;
            errors["classAndgroup"] = "Class & Group can't left empty.";
        }

        if (academicYear === '' || academicYear === null) {
            formIsValid = false;
            errors["academicYear"] = "Academic Year can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;

    }

    clearErrorMsg = (name) => {
        let { errors } = this.state;
        errors[name] = '';
        this.setState({ errors })
    }

    paymentDateFormatBody = (rowData) => {
        return NetiDateUtils.getDDhypMMhypYY(rowData.paymentDate);
    }

    applicationDateFormatBody = (rowData) => {
        return NetiDateUtils.getDDhypMMhypYY(rowData.applicationDate);
    }

    render() {

        let { classAndGroupList, dataTableValue, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let currentYear = new Date().getFullYear();
        let academicYearOptions = [
            { label: currentYear + 1, value: currentYear + 1 },
            { label: currentYear, value: currentYear },
            { label: currentYear - 1, value: currentYear - 1 },
            { label: currentYear - 2, value: currentYear - 2 },
            { label: currentYear - 3, value: currentYear - 3 },
            { label: currentYear - 4, value: currentYear - 4 },
        ];

        let classConfigOptions = [];
        if (classAndGroupList && classAndGroupList.length) {
            classConfigOptions = classAndGroupList.map(item => ({ label: item.class + " (" + item.group + ")", value: item.classConfigId }))
        }

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Collection Information  List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>;

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
                <Column footer={this.state.totalPaymentCount} />
            </Row>
        </ColumnGroup>;

        return (

            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                {this.state.homeReturnButton === false ?

                    <div className="main-section blur-section">

                        <div className="p-grid nw-form">

                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="p-grid nw-form-body">
                                    <div className="p-col-12 p-lg-12 p-xl-5">

                                        {this.state.secondDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-small-span">Academic Year <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Academic Year"
                                                    filter={true}
                                                    filterBy="label,value"
                                                    name="academicYear"
                                                    options={academicYearOptions}
                                                    value={this.state.academicYear}
                                                    onChange={this.onChangeAcademicYear}
                                                    autoWidth={false}
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{errors["academicYear"]}</span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-5">
                                        {this.state.firstDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-small-span">Class & Group <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Class & Group"
                                                    filter={true}
                                                    filterBy="value"
                                                    value={this.state.classConfigId}
                                                    options={classConfigOptions}
                                                    onChange={this.onChangeClassAndGroupList}
                                                    showClear={false}
                                                    autoWidth={false}
                                                    name="classAndgroup"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{errors["classAndgroup"]}</span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-center"
                                            label="Search"
                                            icon="fas fa-search"
                                            onClick={this.onSearchHandler}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    footerColumnGroup={footerGroup}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                >
                                    <Column field="registrationNo" header="Registration No." filter={true} />
                                    <Column field="name" header="Name" filter={true} />
                                    <Column field="contractNo" header="Contact No." filter={true} />
                                    <Column field="applicationDate" header="Application Date" filter={true} body={this.applicationDateFormatBody} />
                                    <Column field="paymentDate" header="Payment Date" filter={true} body={this.paymentDateFormatBody} />
                                    <Column field="paymentAmount" header="Payment Amount" filter={true} />
                                </DataTable>

                                : this.NetiContentLoader.MyPointTableLoader()
                            }

                        </div>
                    </div>

                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}