import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { SeatInfoList } from "./SeatInfoList";
import { UserCategoryService } from "../../../service/UserCategoryService";
import { DwsService } from "../../../service/dwsPoint/DwsService";
import NetiContentLoader from "../../common/NetiContentLoader";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { Link } from "react-router-dom";
import { NetiCMSSetupProgress } from "../common/NetiCMSSetupProgress";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import staticImg from "../../../assets/images/avatar.png";
import CommonFuctionality from "../../common/CommonFuctionality";
import { NetiFileHandler } from "../../../utils/NetiFileHandler";
import { ImageCropper } from "../../common/ImageCropper";

let dialogStatus = false;
let errors = {};
let dropDownListName1 = "";
let dropDownListName2 = "";
let cloneStateBeforeMount;
let urlId;
export class AdminDressCodeInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogType: "",
      rowDataDressId: "",
      dressSerial: "",
      deleteDialogVisible: false,
      dressCodeInfo: {},
      viewDressInfo: false,
      dressId: "",
      dialogFormType: false,
      cmsId: "",
      uploadFile: {
        fileName: "",
        fileContent: "",
        fileSaveOrEditable: false,
      },
      gender: "",
      classRange: "",
      dressDetails: "",
      dataTableValue: [],
      coreClassCategoryID: "",
      coreGroupCategoryID: "",
      totalSeat: "",
      errors: {},

      seatInfoObject: {
        totalSeat: "",
        seatSerial: "",
        classCategoryInfoDTO: {
          coreCategoryID: 0,
        },
        groupCategoryInfoDTO: {
          coreCategoryID: 0,
        },
        coreUrlInfoDTO: {
          urlInfoID: "",
        },
      },
      homeReturnButton: true,
      dataTableIsLoading: false,
      firstDropDownIsLoading: false,
      secondDropDownIsLoading: false,
      totalDataFound: 0,

      cropperVisible: false,
      cropperObject: {
        //main class parameter
        main: {
          viewport: { width: 280, height: 270 },
          boundary: { width: 600, height: 480 },
          showZoomer: true,
          enableOrientation: false,
        },
        //bind parameter
        bind: {
          url: "",
          orientation: 4,
        },
      },
    };

    this.UserCategoryService = new UserCategoryService();
    this.DwsService = new DwsService();
    this.NetiContentLoader = new NetiContentLoader();
    this.reloadCoreUrlIdFromLocal();
    this.CommonFuctionality = new CommonFuctionality();
    this.NetiFileHandler = new NetiFileHandler();
  }

  async reloadCoreUrlIdFromLocal() {
    urlId = await this.DwsService.getCmsIdFromLocalStorage();
    console.log("urlId .>", urlId);

    if (typeof urlId === "string" || typeof urlId === "number") {
      this.setState({ cmsId: urlId });
      this.setState({ homeReturnButton: false });
      this.getTableData();
    } else {
      this.setState({ homeReturnButton: true });
    }
  }

  viewDialog = (rowData) => {
    this.setState({ dressCodeInfo: rowData });
    this.setState({ viewDressInfo: true });
  };

  getTableData = () => {
    this.setState({
      firstDropDownIsLoading: true,
      secondDropDownIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });

    this.DwsService.getCmsDressCodeInfoList(this.state.cmsId)
      .then((res) => {
        // console.log('res-list', res);

        if (res.status == 200) {
          return res.json().then((body) => {
            // console.log('table-list', body.item);

            // this.props.countTableSize(body.length);
            this.setState({ dataTableValue: body.item });
            debugger;
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.DwsService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection.",
        });
      });
  };

  onChangeGender = (e) => {
    errors = {};
    this.setState({ gender: e.target.value });
  };

  onChangeClassRange = (e) => {
    errors = {};
    this.setState({ classRange: e.target.value });
  };

  onChangeDressCodeDetails = (e) => {
    errors = {};
    this.setState({ dressDetails: e.target.value });
  };

  getClassOrGroupListByDefaultCode(defaultCode) {
    this.setState({ error: {} });
    this.setState({
      firstDropDownIsLoading: true,
      secondDropDownIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });

    this.UserCategoryService.fetchListByDefaultCode(defaultCode)
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            if (defaultCode === "T108") {
              this.setState({ classDropDownList: body });
            } else if (defaultCode === "T109") {
              this.setState({ groupDropDownList: body });
            }
            errors["classError"] = "";
            errors["groupError"] = "";
            errors[dropDownListName1] = "";
            this.setState({ errors: errors });
            this.setState({
              firstDropDownIsLoading: false,
              secondDropDownIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.UserCategoryService.Auth.handleErrorStatus(res).then((resp) => {
            errors[dropDownListName1] = resp.message;
            this.setState({
              firstDropDownIsLoading: false,
              secondDropDownIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        errors["classError"] = "Class List Not Found";
        errors["groupError"] = "Group List Not Found";
        errors[dropDownListName1] = "Connection Problem";
        this.setState({ errors: errors });
        this.setState({
          firstDropDownIsLoading: false,
          secondDropDownIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  }

  showMainForm = () => {
    this.setState({ visible: true });
  };

  onHide = () => {
    this.setState({ visible: false });
    this.resetSaveAndUpdateForm();
    const album = {
      fileContent: "",
      fileName: "",
      fileSaveOrEditable: false,
    };

    this.setState({ uploadFile: album });
    this.hideImageCropper();
  };

  dressInfoBody = (rowData) => {
    // let staticImg = 'assets/layout/images/avatar.png';

    return (
      <div className="p-grid">
        <div className="p-col-2" style={{ maxWidth: "110px" }}>
          {rowData.fileContent ? (
            <img
              src={"data:image/png;base64," + rowData.fileContent}
              height="auto"
              width="80px"
            />
          ) : (
            <img src={staticImg} width="100%" />
          )}
        </div>

        <div className="p-col-10 nw-datatable-dataview-small-panel productInfo">
          <div>
            <div>Gender</div>
            <div>:</div>
            <div>{rowData.gender}</div>
          </div>

          <div>
            <div>Class</div>
            <div>:</div>
            <div> {rowData.classRange}</div>
          </div>
        </div>
      </div>
    );
  };

  showDeleteDialog = (dressId) => {
    this.setState({ deleteDialogVisible: true, rowDataDressId: dressId });
    this.CommonFuctionality.blurDialogBackgroundActive();
  };

  // deleteDialogView = (dressId) => {
  //     return (

  //     )
  // }

  onHidedeleteDialog = () => {
    this.setState({ deleteDialogVisible: false, rowDataDressId: "" });
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  };

  onLoadPic = (e) => {
    let { errors, cropperObject } = this.state;

    let fileType = this.NetiFileHandler.getFileContentType(e.files[0].name);
    let supportedExtention = ["image/jpeg", "image/jpg", "image/png"];
    // console.log('fileType', fileType);
    if (supportedExtention.includes(fileType)) {
      var reader = new FileReader();
      let photo = e.files[0];
      const scope = this;
      reader.readAsDataURL(photo);
      reader.onload = () => {
        let content = reader.result;
        var keyw = "data:" + photo.type + ";base64,"; //link will be same from the word webapps in URL
        var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
        let album = {
          // extention: photo.type,
          fileContent: urlStr,
          fileName: photo.name,
          fileSaveOrEditable: true,
        };
        scope.setState({ uploadFile: album });
      };

      errors["image"] = "";
      cropperObject.bind.url = photo.objectURL;
      this.setState({ errors, cropperObject, cropperVisible: true });
    } else {
      errors["image"] = "File format not supported";
      this.setState({ errors });
    }
  };

  getCroppedResult = (imageObj) => {
    let { uploadFile, errors } = this.state;
    // console.log('cropped imageObj', imageObj);
    let maxSize = this.NetiFileHandler.getMaxFileSizeIsValid(
      imageObj.photoBlob.size,
      500000
    );
    if (maxSize) {
      uploadFile.fileContent = imageObj.contentPic;
      errors["image"] = "";
    } else {
      errors["image"] = "Image size can't be more than 500 KB";
      this.removeCropSection();
    }

    this.setState({ uploadFile, errors, cropperVisible: false });
  };

  hideImageCropper = () => {
    this.setState({ cropperVisible: false });
  };

  removeCropSection = () => {
    let { uploadFile } = this.state;
    uploadFile.fileContent = "";
    uploadFile.fileName = "";
    uploadFile.fileSaveOrEditable = false;

    this.setState({ cropperVisible: false, uploadFile });
  };

  onSubmitDressCodeInfo = () => {
    let { errors } = this.state;

    let dressCodeInfoObj = {
      classRange: this.state.classRange,
      cmsId: this.state.cmsId,
      dressDetails: this.state.dressDetails,
      dressImageName: this.state.uploadFile.fileName,
      dressSerial: this.state.dataTableValue.length + 1,
      fileContent: this.state.uploadFile.fileContent,
      fileName: this.state.uploadFile.fileName,
      fileSaveOrEditable: true,
      gender: this.state.gender,
    };
    if (this.handleError()) {
      this.DwsService.cmsSaveDressCodeInfo(dressCodeInfoObj)
        .then((res) => {
          return res.json();
        })
        .then((body) => {
          if (body.messageType == 1) {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
            this.growl.show({
              life: 800,
              severity: "success",
              summary: "Success",
              detail: body.message,
            });
            this.getTableData();
            this.resetSaveAndUpdateForm();
            setTimeout(() => {
              this.onHide();
            }, 2000);
          } else {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: body.message,
            });
          }
        })
        .catch((error) => {
          this.setState({ errors: errors });
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection",
          });
        });
    }
  };

  onUpdateDressCodeInfo = () => {
    let { errors } = this.state;

    let dressCodeInfoObj = {
      classRange: this.state.classRange,
      cmsId: this.state.cmsId,
      dressDetails: this.state.dressDetails,
      dressImageName: this.state.uploadFile.fileName,
      dressSerial: this.state.dressSerial,
      fileContent: this.state.uploadFile.fileContent,
      fileName: this.state.uploadFile.fileName,
      fileSaveOrEditable: true,
      gender: this.state.gender,
      dressId: this.state.dressId,
    };

    debugger;

    if (this.handleError()) {
      this.setState({ topProgressBar: true, errorMsgVisible: false });
      this.DwsService.cmsUpdateDressCodeInfo(dressCodeInfoObj)
        .then((res) => {
          return res.json();
        })
        .then((body) => {
          if (body.messageType == 1) {
            // console.log('body.message', body.message);
            this.growl.show({
              life: 800,
              severity: "success",
              summary: "Success",
              detail: body.message,
            });
            this.setState({ topProgressBar: false, errorMsgVisible: false });
            this.onHide();
            this.getTableData();
            this.resetSaveAndUpdateForm();

            // setTimeout(() => {
            //     this.onHide();
            // }, 2000);
          } else {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: body.message,
            });
          }
        })
        .catch((error) => {
          this.setState({ errors: errors });
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection",
          });
        });
    }
  };

  deleteRow = (dressID) => {
    let { errors } = this.state;
    this.DwsService.cmsDeleteDressCodeInfo(dressID)
      .then((res) => {
        // console.log('dress code delete info res', res);
        return res.json();
      })
      .then((body) => {
        // console.log('body', body);

        if (body.messageType == 1) {
          // console.log('body.message', body.message);
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
          });
          this.growl.show({
            life: 800,
            severity: "success",
            summary: "Success",
            detail: body.message,
          });
          this.getTableData();
          setTimeout(() => {
            this.onHidedeleteDialog();
          }, 1000);
        } else {
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: body.message,
          });
        }
      })
      .catch((error) => {
        this.setState({ errors: errors });
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  };

  resetSaveAndUpdateForm = () => {
    this.setState({
      dressSerial: "",
      classRange: "",
      dressDetails: "",
      uploadFile: {
        fileName: "",
        fileContent: "",
      },
      gender: "",
    });
  };

  editBody = (rowData) => {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button"
          icon="far fa-eye"
          title="View"
          onClick={(e) => this.viewDialog(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "top" }}
        />
        <Button
          className="nw-action-button"
          icon="fas fa-pencil-alt"
          title="Update"
          onClick={(e) => this.showFormDialog(e, rowData, "update")}
          tooltip="Update"
          tooltipOptions={{ position: "top" }}
        />
        <Button
          type="button"
          icon="fas fa-trash"
          className="nw-action-button p-button-danger"
          onClick={(e) => this.showDeleteDialog(rowData.dressId)}
          tooltip="Delete"
          tooltipOptions={{ position: "top" }}
        />
      </div>
    );
  };

  onHideMemberInfo = () => {
    this.setState({ viewDressInfo: false });
  };

  updateDialog = (rowData) => {
    console.log("rowData", rowData);
  };

  showFormDialog = (e, rowData, dialogType) => {
    this.setState({ dialogType: dialogType });
    if (dialogType === "update") {
      this.setState({
        dressId: rowData.dressId,
        dressSerial: rowData.dressSerial,
        gender: rowData.gender,
        classRange: rowData.classRange,
        dressDetails: rowData.dressDetails,
        uploadFile: {
          fileName: rowData.fileName,
          fileContent: rowData.fileContent,
          fileSaveOrEditable: true,
        },
      });
      this.showMainForm();

      this.setState({ dialogFormType: false });
    } else if (dialogType === "save") {
      this.resetSaveAndUpdateForm();
      this.showMainForm();

      this.setState({ dialogFormType: true });
    }
  };

  onHideDressInfo = () => {
    this.setState({ viewDressInfo: false });
  };

  onChageInputValue = (e) => {
    this.setState({
      ...this.state,
      dressSerial: e.target.value,
    });

    // this.setState({ updateUserObj, insertUserObj });
    // this.onClearErrorMsg(e.target.name);
  };

  handleError = () => {
    let formIsValid = true;

    if (this.state.gender === "" || this.state.gender === null) {
      formIsValid = false;
      errors["gender"] = "Gender can't left empty.";
    }
    if (this.state.classRange === "") {
      formIsValid = false;
      errors["class"] = "Class can't left empty.";
    }
    if (this.state.dressDetails === "") {
      formIsValid = false;
      errors["details"] = "Details can't left empty.";
    }
    if (this.state.uploadFile.fileName === "") {
      formIsValid = false;
      errors["image"] = "Please Upload image.";
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  render() {
    let {
      dataTableValue,
      topProgressBar,
      errorMsgVisible,
      errorMsgBody,
      errors,
    } = this.state;
    let uploadFileLabel = (
      <div>
        Click / Drag File Here To Upload <br />
        <span className="upload-file-label">(Max 500 KB (recommended))</span>
      </div>
    );

    let genderList = [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
      { label: "Combine", value: "Combine" },
    ];

    let tableHeader = (
      <div className="header-title">
        <div className="header-title-left">Dress Code Info List</div>
        <div className="header-title-right">
          <a>
            Total Found:{" "}
            {dataTableValue && dataTableValue.length.toLocaleString("EN-IN")}
          </a>

          <Button
            className="nw-button nw-button-center p-button-success"
            name="district"
            label="Add New"
            icon="fas fa-plus"
            iconPos="right"
            onClick={(e) => this.showFormDialog(e, null, "save")}
          />
        </div>
      </div>
    );

    return (
      <div className="p-fluid">
        <NetiCMSSetupProgress />

        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <Growl ref={(el) => (this.growl = el)} />
        <div className="main-section">
          {this.state.homeReturnButton === false ? (
            <div className="nw-data-table">
              {!this.state.dataTableIsLoading ? (
                <DataTable
                  value={this.state.dataTableValue}
                  selectionMode="single"
                  header={tableHeader}
                  responsive={true}
                  rows={10}
                  paginator={true}
                  columnResizeMode="fit"
                >
                  <Column
                    field="dressSerial"
                    header="Serial No."
                    style={{ width: "12em" }}
                    filter={true}
                  />
                  <Column
                    field={("gender", "classRange")}
                    header="Dress Info"
                    body={this.dressInfoBody}
                    filter={true}
                  />
                  <Column
                    field="action"
                    header="Action"
                    body={this.editBody}
                    style={{ textAlign: "center", width: "10em" }}
                  />
                </DataTable>
              ) : (
                this.NetiContentLoader.MyPointTableLoader()
              )}
            </div>
          ) : (
            <div className="">
              <div className="p-grid">
                <div className="card p-col-12 p-xl-3">
                  <center className="custom-center">
                    <Link
                      to="/home"
                      className="rainbow-button"
                      alt="Go DWS Home"
                    />
                  </center>
                </div>
                <div className="card p-col-12 p-xl-3">
                  <center className="custom-center">
                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                  </center>
                </div>
                <div className="card p-col-12 p-xl-6">
                  <img src="assets/images/dws_home.png" width="100%" />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="dialog-section">
          <Dialog
            className="nw-dialog"
            header={`${
              this.state.dialogFormType === true ? "Add" : "Update"
            } Dress Code Information`}
            visible={this.state.visible}
            onHide={this.onHide}
            closable
            maximizable
          >
            <div className="p-fluid">
              <div className="p-grid nw-form">
                <div className="p-col-12 p-xl-12">
                  <div className="nw-form-body">
                    <div className="nw-form-body">
                      {!this.state.dialogFormType ? (
                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              Serial No. <span>*</span>
                            </span>
                            <InputText
                              placeholder="Enter Serial"
                              name="dressSerial"
                              value={this.state.dressSerial}
                              onChange={(e) => this.onChageInputValue(e)}
                            />
                          </div>
                          <span className="error-message">
                            {errors["dressSerial"]}
                          </span>
                        </div>
                      ) : (
                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              Serial No.
                            </span>
                            <div className="nw-inputgroup-desc">
                              {this.state.dressSerial
                                ? this.state.dressSerial
                                : this.state.dataTableValue.length + 1}
                            </div>
                          </div>
                          {/* <span className="error-message">{errors['serialNumber']}</span> */}
                        </div>
                      )}

                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Gender <span>*</span>
                          </span>
                          <Dropdown
                            placeholder="Select gender"
                            value={this.state.gender}
                            options={genderList}
                            onChange={this.onChangeGender}
                            showClear={true}
                            autoWidth={false}
                            name="gender"
                          />
                        </div>

                        <span className="error-message">
                          {errors["gender"]}
                        </span>
                      </div>

                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Class <span>*</span>
                          </span>
                          <InputText
                            id="classRange"
                            placeholder="Enter Class Range"
                            showClear={true}
                            style={{ width: "100%" }}
                            type="text"
                            name="class"
                            onChange={this.onChangeClassRange}
                            value={this.state.classRange}
                          />
                        </div>
                        <span className="error-message">{errors["class"]}</span>
                      </div>

                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon nw-inputtextarea-label">
                            Details <span>*</span>
                          </span>
                          <InputTextarea
                            placeholder="Enter Dress Code Details"
                            onChange={this.onChangeDressCodeDetails}
                            value={this.state.dressDetails}
                            rows={3}
                            cols={30}
                            autoResize={true}
                            id="dressDetails"
                            name="details"
                          />
                        </div>
                        <span className="error-message">
                          {errors["details"]}
                        </span>
                      </div>

                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup nw-upload-button">
                          <span className="p-inputgroup-addon nw-inputtextarea-label">
                            Upload Image <span>*</span>
                            <br />
                            <span>(PNG/JPG/JPEG)</span>
                          </span>

                          <div className="nw-upload-button-inside">
                            {this.state.uploadFile.fileContent ? (
                              <div className="image-view-main">
                                <div className="upload-image-view">
                                  <Button
                                    className="delete-upload-button"
                                    icon="fas fa-times-circle"
                                    onClick={(e) =>
                                      this.setState({
                                        uploadFile: { fileContent: "" },
                                      })
                                    }
                                  />
                                  <img
                                    src={
                                      this.state.uploadFile.fileContent === ""
                                        ? "assets/images/demo.jpg"
                                        : "data:image/png;base64," +
                                          this.state.uploadFile.fileContent
                                    }
                                    style={{ height: "80px" }}
                                  />
                                </div>
                                <div className="image-title">
                                  {this.state.uploadFile.fileName}
                                </div>
                              </div>
                            ) : (
                              <FileUpload
                                chooseLabel={
                                  this.state.uploadFile.fileName ||
                                  uploadFileLabel
                                }
                                mode="basic"
                                accept="image/*"
                                onSelect={(e) => this.onLoadPic(e)}
                                auto={true}
                              />
                            )}
                          </div>
                        </div>
                        {this.state.uploadFile &&
                        this.state.uploadFile.fileContent &&
                        this.state.cropperVisible ? (
                          <ImageCropper
                            cropperObject={this.state.cropperObject}
                            getCroppedResult={this.getCroppedResult}
                            removeCropSection={this.removeCropSection}
                          />
                        ) : (
                          ""
                        )}
                        {/* <span className='error-message'>{this.state.uploadFile.fileName ? null : this.state.errors.noticeImgContent}</span> */}

                        <span className="error-message">
                          {!this.state.uploadFile.fileName && errors["image"]}
                        </span>
                      </div>

                      <div className="p-col-12 p-xl-12 nw-button-parent">
                        <div className="required-field">
                          (<span>*</span>) required fields
                        </div>

                        {this.state.dialogFormType === true ? (
                          <Button
                            className="p-button p-button-primary nw-button nw-button-left"
                            label="Save"
                            icon="fas fa-check"
                            disabled={this.state.cropperVisible ? true : false}
                            onClick={this.onSubmitDressCodeInfo.bind(this)}
                          />
                        ) : (
                          ""
                        )}

                        {this.state.dialogFormType === false ? (
                          <Button
                            className="p-button p-button-primary nw-button nw-button-left"
                            label="Update"
                            icon="fas fa-check"
                            disabled={this.state.cropperVisible ? true : false}
                            onClick={this.onUpdateDressCodeInfo.bind(this)}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            header="Delete Dress Info"
            visible={this.state.deleteDialogVisible}
            onHide={this.onHidedeleteDialog}
            className="nw-confirm-dialog"
          >
            <div className="p-fluid">
              <div className="p-col-12 p-col-12">
                <div className="confirm-wrapper">
                  <h1>Are You Sure?</h1>
                  <div className="nw-button-parent-multiple">
                    <Button
                      className="p-button-danger nw-button nw-button-multiple"
                      label="No"
                      icon="fas fa-times"
                      onClick={this.onHidedeleteDialog}
                    />
                    <Button
                      className="p-button-primary nw-button nw-button-multiple"
                      label="Yes"
                      icon="fas fa-check"
                      onClick={(e) => this.deleteRow(this.state.rowDataDressId)}

                      // onClick={ e => this.deleteRow(this.state.deleteUserObj)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            className="nw-dialog"
            header="View Dress Code Info"
            visible={this.state.viewDressInfo}
            onHide={this.onHideDressInfo}
            closable
          >
            <div className="p-fluid">
              <div className="p-grid nw-form">
                <div className="p-col-12 p-xl-12 blur-section">
                  <div className="nw-form-body">
                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Serial No.</span>
                        <div className="nw-inputgroup-desc">
                          {this.state.dressCodeInfo.dressSerial || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Gender</span>
                        <div className="nw-inputgroup-desc">
                          {this.state.dressCodeInfo.gender || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Class</span>
                        <div className="nw-inputgroup-desc">
                          {this.state.dressCodeInfo.classRange || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Details</span>
                        <div
                          className="nw-inputgroup-desc"
                          style={{ height: "auto", textAlign: "justify" }}
                        >
                          {this.state.dressCodeInfo.dressDetails || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Image</span>
                        <div style={{ paddingLeft: "22px" }}>
                          {/* {tutorialViewListItem.defaultCode || '---'} */}
                          {this.state.dressCodeInfo &&
                          this.state.dressCodeInfo.fileContent ? (
                            <img
                              src={
                                "data:image/*;base64," +
                                this.state.dressCodeInfo.fileContent
                              }
                              height="auto"
                              width="80px"
                            />
                          ) : (
                            <img src={staticImg} width="100%" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
