import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { PickList } from 'primereact/picklist';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { Growl } from 'primereact/growl';
import { SpeechUpdate } from './SpeechUpdate';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { ImageCropper } from '../../common/ImageCropper';
import Croppie from "croppie";

let urlId;
let cloneStateBeforeMount;
let vanilla;
export class Speech extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogLoad: true,
            visible: false,
            wrongEmail: true,
            value: '',
            rowInformation: {
                speakerName: '',
                urlInfoDTO: {
                    urlInfoID: ''
                }
            },
            speechList: [],
            selectedList: [],
            speechInfo: {
                speakerName: '',
                speechDetails: '',
                speechDate: '',
                speakerDesignation: '',
                speakerMobile: '',
                speakerEmail: '',
                speechImgName: '',
                speechImgPath: '',
                imageContent: '',
                urlInfoDTO: {
                    urlInfoID: ''
                }
            },
            uploadImage: {

            },
            speechSubmitError: {

            },
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            cropperObject: {
                //main class parameter
                main: {
                    viewport: { width: 480, height: 320 },
                    boundary: { width: 400, height: 260 },
                    showZoomer: true,
                    enableOrientation: false,
                },
                //bind parameter
                bind: {
                    url: '',
                    orientation: 4
                }

            }
        }

        this.netiFileHandler = new NetiFileHandler();
        this.validatorUtility = new ValidatorUtility();
        this.DwsService = new DwsService()
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.errorHandler = this.errorHandler.bind(this);
        this.reloadCoreUrlIdFromLocal();

    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentDidMount() {
        this.setState({ dialogLoad: false });
    }

    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            this.setState({ homeReturnButton: false })
            let { speechInfo } = this.state;
            speechInfo.urlInfoDTO.urlInfoID = urlId;
            this.setState({ speechInfo });
            this.viewTopSpeechList();
            this.viewSelectedSpeechList();
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    viewTopSpeechList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.DwsService.getSpeechList(urlId, '0')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ speechList: body })
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, });
                    })
                }
                else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' });
            })
    }

    viewSelectedSpeechList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.DwsService.getSpeechList(urlId, '1')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ selectedList: body })
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    })
                }
                else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' });
            })
    }

    viewSpeechData = (e, source) => {
        this.setState({ visible: false });
        this.setState({ dialogLoad: false });
        let { rowInformation } = this.state;
        rowInformation.speechID = source.speechID;
        rowInformation.speechDetails = source.speechDetails;
        rowInformation.speechImgName = source.speechImgName;
        rowInformation.speechImgPath = source.speechImgPath;
        rowInformation.speechSerial = source.speechSerial;
        rowInformation.speechStatus = source.speechStatus;
        rowInformation.speechDate = source.speechDate;
        rowInformation.welcomeSpeechStatus = source.welcomeSpeechStatus;
        rowInformation.speakerName = source.speakerName;
        rowInformation.speakerDesignation = source.speakerDesignation;
        rowInformation.speakerMobile = source.speakerMobile;
        rowInformation.speakerEmail = source.speakerEmail;
        rowInformation.speakerFacebookLink = source.speakerFacebookLink;
        rowInformation.speakerLinkedinLink = source.speakerLinkedinLink;
        rowInformation.speakerTwitterLink = source.speakerTwitterLink;
        rowInformation.imageContent = source.imageContent;
        rowInformation.imageSaveOrEditable = source.imageSaveOrEditable;
        rowInformation.urlInfoDTO.urlInfoID = source.urlInfoDTO.urlInfoID;
        this.setState({ rowInformation });
        this.setState({ dialogLoad: true });
        this.setState({ visible: true });

        console.log("rowInformation", rowInformation);

        this.CommonFuctionality.blurDialogBackgroundActive()

    }

    loadDialog = () => {
        this.componentWillMount();
        this.setState({ dialogLoad: false });
        this.onHide()
    }

    speechTemplate = (source) => {
        return (
            <div className="p-clearfix">
                <div>
                    <label
                        htmlFor="cb1"
                        className="p-checkbox-label">
                        {source.speakerName} - ( {source.speakerDesignation} )
                    </label>
                    <Button
                        className="pull-right"
                        icon="fas fa-pencil-alt"
                        onClick={(e) => this.viewSpeechData(e, source)}
                    />
                </div>
            </div>
        );
    }

    speakerNameHandler = (event, props) => {
        let { speechInfo, speechSubmitError } = this.state;
        speechSubmitError["speakerName"] = ''
        speechInfo.speakerName = event.target.value;
        this.setState({ speechInfo });
    }

    designationHandler = (event, props) => {
        let { speechInfo, speechSubmitError } = this.state;
        speechSubmitError["speakerInfo"] = ''
        speechInfo.speakerDesignation = event.target.value;
        this.setState({ speechInfo });
    }

    mobileNumberHandler = (event, props) => {
        let { speechInfo, speechSubmitError } = this.state;
        speechSubmitError["speakerMobile"] = ''
        speechInfo.speakerMobile = event.target.value;
        this.setState({ speechInfo });
    }

    emailHandler = (event, props) => {
        // console.log("email", (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)));

        let { speechInfo, speechSubmitError } = this.state;
        speechSubmitError["speakerEmail"] = ''
        speechInfo.speakerEmail = event.target.value;
        this.setState({ speechInfo });
    }

    speakerDetails = (event, props) => {
        let { speechInfo, speechSubmitError } = this.state;
        speechSubmitError["speechDetails"] = ''
        speechInfo.speechDetails = event.target.value;
        this.setState({ speechInfo });
    }

    onLoadPic = (e, fileSize) => {
        let { speechSubmitError, cropperObject } = this.state;

        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;

            console.log("content", content);

            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            speechSubmitError["imageContent"] = ''
            scope.setState({ uploadImage: album });

        }

        cropperObject.bind.url = photo.objectURL
        this.setState({ speechSubmitError, cropperObject })
    }

    getCroppedResult = (imageObj) => {
        let { uploadImage, speechSubmitError } = this.state
        let maxSize = this.netiFileHandler.getFileSizeError(imageObj.photoBlob.size, 1049000)// 825548 825.55kb
        if (maxSize) {
            speechSubmitError["imageContent"] = "Image size can't be more than " + maxSize
        } else {
            uploadImage.contentPic = imageObj.contentPic
            speechSubmitError["imageContent"] = ''
        }

        this.setState({ uploadImage, speechSubmitError });
    }

    removeCropSection = () => {
        // vanilla.destroy();
    }

    removeFile = () => {
        this.setState({ uploadImage: { contentPic: '' } })
        // vanilla.destroy();
    }

    addNewSpeech = () => {
        let { speechInfo, speechSubmitError } = this.state;

        if (this.state.uploadImage.contentPic != null) {
            speechInfo.imageContent = this.state.uploadImage.contentPic;
            speechInfo.imageSaveOrEditable = true;
            // let extention = this.netiFileHandler.getImageExtention(this.state.uploadImage.extention);
            // speechInfo.speechImgName = 'speech_pic_' + urlId + '_' + Date.now() + extention;

            speechInfo.speechImgName = this.state.uploadImage.contentName;
        }

        if (this.errorHandler()) {

            if (this.validatorUtility.emailIsValid(speechInfo.speakerEmail) == true) {
                this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
                this.setState({ speechInfo });
                this.DwsService.addNewSpeech(this.state.speechInfo)
                    .then(res => {
                        if (res.status == 201) {
                            this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Speech added successfully' })
                            this.setState({
                                uploadImage: {
                                    contentName: ''
                                },
                                speechInfo: {
                                    speakerName: '',
                                    speechDetails: '',
                                    speechDate: '',
                                    speakerDesignation: '',
                                    speakerMobile: '',
                                    speakerEmail: '',
                                    speechImgName: '',
                                    speechImgPath: '',
                                    imageContent: '',
                                }

                            });
                            this.setState({ topProgressBar: false, dataTableIsLoading: false });
                            this.viewTopSpeechList();
                        }
                        else {
                            this.DwsService.Auth.handleErrorStatus(res)
                                .then((resp) => {
                                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection." })
                    });
            } else {
                speechSubmitError["speakerEmail"] = "Invalid Email format.";
            }
        }
    }

    speechListSubmit = (event, props) => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        let { selectedList, speechList } = this.state;
        let selectedSpeechList = [];
        if (selectedList.length > 5) {
            this.growl.show({ severity: 'error', summary: 'Error Message', detail: 'You cross speech limit' });
            return;
        }
        for (let i = 0; i < selectedList.length; i++) {
            selectedList[i].welcomeSpeechStatus = 1;
            selectedList[i].speechSerial = i + 1;

            selectedSpeechList.push(selectedList[i]);
        }
        for (let i = 0; i < speechList.length; i++) {
            speechList[i].welcomeSpeechStatus = 0;
            selectedSpeechList.push(speechList[i]);
        }
        this.DwsService.updateSpeechList(selectedSpeechList)
            .then(res => {
                if (res.status == 202) {
                    this.viewTopSpeechList();
                    this.viewSelectedSpeechList();
                    this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Speech Update successfully' });
                } else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp, topProgressBar: false, dataTableIsLoading: false })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection." })
            });
    }

    errorHandler() {
        let { speechInfo, speechSubmitError } = this.state;
        let formIsValid = true;
        if (speechInfo.speakerName === '') {
            formIsValid = false;
            speechSubmitError["speakerName"] = "Speaker name can't left empty";
        }
        if (speechInfo.speakerDesignation === '') {
            formIsValid = false;
            speechSubmitError["speakerInfo"] = "Speaker designation can't left empty";
        }
        if (speechInfo.speakerMobile === '') {
            formIsValid = false;
            speechSubmitError["speakerMobile"] = "Mobile number can't left empty.";
        }
        if (speechInfo.speakerEmail === '') {
            formIsValid = false;
            speechSubmitError["speakerEmail"] = "Email address can't left empty.";
        }
        if (this.state.wrongEmail === false) {
            formIsValid = false;
            speechSubmitError["speakerEmail"] = "Please input correct email.";
        }
        if (speechInfo.speechDetails === '') {
            formIsValid = false;
            speechSubmitError["speechDetails"] = "Speech details can't left empty.";
        }
        if (speechInfo.imageContent === '') {
            formIsValid = false;
            speechSubmitError["imageContent"] = "Image can't left empty.";
        }
        this.setState({ speechSubmitError });
        return formIsValid;
    }

    turnOffDialog = () => {
        this.onHide()
    }

    onHide = (event) => {
        this.setState({ visible: false, dialogLoad: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive()
    }

    render() {
        let { cropperObject, uploadImage, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">370 x 240 px (recommended)</span></div>

        let sourceHeader = <div className="header-title">
            <div className="header-title-left">
                Speech List
                                </div>
            <div className="header-title-right">
                {/* <a>
                                        Total Found: {this.state.selectedBooksData.length}
                                    </a> */}
            </div>
        </div>;

        let targetHeader = <div className="header-title">
            <div className="header-title-left">
                Selected Speech for Neti CMS Home Page
                                </div>
            <div className="header-title-right">
                <a className="text-center"> MAX: 5</a>
            </div>
        </div>;

        // console.log("STATE::::", this.state);

        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress />

                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                {this.state.homeReturnButton === false ?
                    <div>
                        <div className="main-section blur-section">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Speaker Name <span>*</span></span>
                                                <InputText
                                                    id="speakerName"
                                                    value={this.state.speechInfo.speakerName}
                                                    onChange={(e) => this.speakerNameHandler(e, this.props)}
                                                    name="speakerName"
                                                    placeholder="Enter Speaker Name"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.speechSubmitError.speakerName}</span>
                                        </div>

                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Designation <span>*</span></span>
                                                <InputText
                                                    value={this.state.speechInfo.speakerDesignation}
                                                    id="designation"
                                                    onChange={(e) => this.designationHandler(e, this.props)}
                                                    name="designation"
                                                    placeholder="Enter Speaker Designation"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.speechSubmitError.speakerInfo}</span>
                                        </div>

                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Mobile No. <span>*</span></span>
                                                <InputText
                                                    type="text"
                                                    value={this.state.speechInfo.speakerMobile}
                                                    name="CustomerContactCheck"
                                                    id="contactCheck"
                                                    placeholder="Enter Mobile Number"
                                                    onChange={(e) => this.mobileNumberHandler(e, this.props)}
                                                    keyfilter="pint"
                                                    maxlength={11}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.speechSubmitError.speakerMobile}</span>
                                        </div>

                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Email Address <span>*</span></span>
                                                <InputText
                                                    value={this.state.speechInfo.speakerEmail}
                                                    placeholder="Enter Email Address"
                                                    id="emailAddress"
                                                    keyfilter="email"
                                                    onChange={(e) => this.emailHandler(e, this.props)}
                                                    name="emailAddress"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.speechSubmitError.speakerEmail}</span>
                                        </div>

                                        <div className="p-col-12 p-lg-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon  nw-inputtextarea-label">Details <span>*</span></span>
                                                <InputTextarea
                                                    value={this.state.speechInfo.speechDetails}
                                                    id="details"
                                                    placeholder="Enter Speech Details"
                                                    onChange={(e) => this.speakerDetails(e, this.props)}
                                                    name="details"
                                                    rows={5}
                                                    cols={30}
                                                />
                                            </div>
                                            <span className="error-message">{this.state.speechSubmitError.speechDetails}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Upload Image <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/GIF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    {
                                                        uploadImage && uploadImage.contentPic ?
                                                            <div className="image-view-main">
                                                                <div className="upload-image-view">
                                                                    <Button
                                                                        className="delete-upload-button"
                                                                        icon="fas fa-times-circle"
                                                                        onClick={this.removeFile}
                                                                    />
                                                                    <img
                                                                        src={
                                                                            uploadImage.contentPic === '' ?
                                                                                'assets/images/demo.jpg'
                                                                                :
                                                                                "data:image/png;base64," + uploadImage.contentPic
                                                                        }
                                                                        style={{ "height": "80px" }}
                                                                    />

                                                                </div>
                                                                <div className="image-title">{uploadImage.contentName}</div>
                                                            </div>

                                                            :
                                                            <FileUpload
                                                                chooseLabel={uploadImage && uploadImage.contentName || uploadFileLabel}
                                                                mode="basic"
                                                                // maxFileSize={1000000} 
                                                                onSelect={e => this.onLoadPic(e, 1000000)}
                                                                auto={true}
                                                                accept="image/*"
                                                                onBeforeUpload={this.onBeforeUpload}
                                                                onError={this.onError}
                                                            />
                                                    }

                                                </div>

                                                {/* <center>
                                                    {this.state.uploadImage.contentName}
                                                </center> */}
                                            </div>

                                            {
                                                uploadImage && uploadImage.contentPic ?
                                                    <ImageCropper
                                                        cropperObject={cropperObject}
                                                        getCroppedResult={this.getCroppedResult}
                                                    />
                                                    : null
                                            }

                                            <span className='error-message'>{this.state.speechSubmitError['imageContent']}</span>
                                        </div>

                                        <div className="p-col-12 -xl-12">
                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                name="submit"
                                                label="Save"
                                                icon="fas fa-check"
                                                onClick={(e) => this.addNewSpeech(e, this.props)}
                                            />

                                        </div>
                                    </div>
                                </div>

                                <br />
                                <div className="p-grid p-col-12 p-xl-12">
                                    <div className="p-col-12 p-xl-12 nw-data-table">
                                        <div className="content-section implementation">
                                            {this.state.dataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader() :
                                                <PickList
                                                    source={this.state.speechList}
                                                    target={this.state.selectedList}
                                                    itemTemplate={this.speechTemplate}
                                                    sourceHeader={sourceHeader}
                                                    targetHeader={targetHeader}
                                                    responsive={true}
                                                    sourceStyle={{ height: '300px' }}
                                                    targetStyle={{ height: '300px' }}
                                                    onChange={(e) => this.setState({ speechList: e.source, selectedList: e.target })}>
                                                </PickList>
                                            }
                                        </div>
                                        <br />
                                        <div className="p-grid p-col-12 p-xl-12 nw-button-parent">
                                            <Button
                                                label="Save"
                                                icon="fas fa-check"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                onClick={(e) => this.speechListSubmit(e, this.props)}
                                            />
                                        </div>
                                        <div className="p-col-12 p-xl-3" />
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='dialog-section'>

                            <Dialog
                                className="nw-dialog"
                                maximizable header="Update Speech Information"
                                visible={this.state.visible} modal={true}
                                onHide={this.onHide}
                            >
                                {this.state.dialogLoad == true ?
                                    <SpeechUpdate
                                        viewTopSpeechList={this.viewTopSpeechList}
                                        loadDialog={this.loadDialog}
                                        speechInformation={this.state.rowInformation}
                                        turnOffDialog={this.turnOffDialog}
                                    />
                                    : ''
                                }
                            </Dialog>

                        </div>
                    </div>
                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}