import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Card } from 'primereact/card';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { DataView } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';

let maxDate = new Date();
let count = 1;
let previousTableName;
let profileImage = 'assets/layout/images/avatar.png';
let selectedPartnerType = '';

export class MpPartnerPerfomanceDashboard extends Component {

    constructor() {
        super();

        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            errors: {},
            dateInfoError: {},
            dateRangeObj: {
                startDate: "",
                endDate: "",
                partnerType: '',
            },
            dashboardViewObjMain: {},
        }

        this.ValidatorUtility = new ValidatorUtility();
        this.ManagementPointService = new ManagementPointService();
    }

    onChangeDate = (e) => {
        let { dateRangeObj } = this.state;
        dateRangeObj[e.target.name] = e.target.value;
        this.setState({ dateRangeObj });
        this.clearDateError(e.target.name);
    }

    onChangePartnerType = e => {
        let { dateRangeObj } = this.state;
        dateRangeObj[e.target.name] = e.target.value;
        this.setState({ dateRangeObj });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { dateInfoError } = this.state;
        dateInfoError[name] = ''
        this.setState({ dateInfoError })
    }

    dateHandleError = () => {
        let { dateInfoError, dateRangeObj } = this.state;
        let formIsValid = true;

        if (dateRangeObj.startDate === '') {
            formIsValid = false;
            dateInfoError["startDate"] = "Start Date can't left empty.";
        }
        if (dateRangeObj.endDate === '') {
            formIsValid = false;
            dateInfoError["endDate"] = "End Date can't left empty.";
        }
        if (dateRangeObj.partnerType === '') {
            formIsValid = false;
            dateInfoError["partnerType"] = "Partner Type can't left empty.";
        }
        this.setState({ dateInfoError });
        return formIsValid;
    }

    onSubmitHandler = () => {

        let { dateRangeObj } = this.state

        if (this.dateHandleError()) {

            selectedPartnerType = dateRangeObj.partnerType.toUpperCase();
            this.setState({ topProgressBar: true, errorMsgVisible: false });

            this.ManagementPointService.fetchPartnerPerformance(dateRangeObj)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            this.setState({ dashboardViewObjMain: body, topProgressBar: false });
                        })
                    }
                    else {
                        this.ManagementPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
                    console.log("error", error);
                });
        }
    }

    itemTemplate = (rowData, tableName) => {

        if (previousTableName != tableName) {
            count = 1;
        } else { count++ }
        previousTableName = tableName;
        return (
            <div className="p-grid item-template-wrapper">
                <div className="p-col-12 p-grid">
                    <div className="p-sm-12 p-md-1 region-wrapper-full">
                        <div className="number">
                            {count}
                        </div>
                    </div>
                    <div className="p-sm-12 p-md-8">
                        <div className="profile-details-wrapper">
                            <div className="profile-image">
                                <img src={rowData.imageContent ? 'data:image/*;base64,' + rowData.imageContent : profileImage} />
                            </div>
                            <div className="profile-details">
                                <span className="netiId">{rowData.netiID}</span>
                                <span className="name">{rowData.name}</span>
                                <span className="location">{rowData.area}</span>

                            </div>
                        </div>
                    </div>
                    <div className="p-sm-12 p-md-3 region-wrapper-full">
                        <div className="amount-wrapper">

                            {
                                rowData.income ?
                                    <div className="amount">
                                        {this.ValidatorUtility.currencyFormatter(rowData.income)}
                                        <br />
                                        <span>Taka</span>
                                    </div>
                                    : rowData.quantity ?
                                        <div className="amount">{rowData.quantity.toLocaleString('EN-IN')}</div>
                                        : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        let { dashboardViewObjMain, topProgressBar, errorMsgVisible, errorMsgBody, dateRangeObj } = this.state
        console.log("dashboardViewObjMain", dashboardViewObjMain);
        let partnerTypeList = [
            { label: 'MBP', value: 'mbp' },
            { label: 'ZBP', value: 'zbp' },
            { label: 'BDP', value: 'bdp' },
            { label: 'BEP', value: 'bep' },
        ]
        count = 0;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section crm-dashboard">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            name='startDate'
                                            value={dateRangeObj.startDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.dateInfoError["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            name='endDate'
                                            value={dateRangeObj.endDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            dateFormat='dd/mm/yy'
                                            yearRange="2010:2030"
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.dateInfoError["endDate"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Partner Type <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Partner Type"
                                            value={dateRangeObj.partnerType}
                                            options={partnerTypeList}
                                            autoWidth={false}
                                            onChange={this.onChangePartnerType}
                                            name="partnerType"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.dateInfoError["partnerType"]}</span>
                                </div>

                                <div className="p-col-12 p-lg-12 nw-button-parent">
                                    <div class="required-field">(<span>*</span>) required fields</div>
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="p-col-12">
                            <div className="partner-performance-title"><h1> {selectedPartnerType + ' PERFORMANCE BOARD'}</h1></div>
                        </div>
                        <div className="p-grid p-col-12 performance-table-wrapper">
                            <div className="p-sm-12 p-md-6">
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Income">
                                            <div className="nw-data-table">
                                                <DataView
                                                    value={dashboardViewObjMain.income}
                                                    layout="list"
                                                    itemTemplate={e => this.itemTemplate(e, 'income')}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Pre-Paid Collection" className="bg-success">
                                            <div className="nw-data-table">
                                                <DataView
                                                    value={dashboardViewObjMain.prePaidBillCollection}
                                                    layout="list"
                                                    itemTemplate={e => this.itemTemplate(e, 'prePaidBillCollection')}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Neti CMS Purchase" className="bg-purple-light">
                                            <div className="nw-data-table">
                                                <DataView
                                                    value={dashboardViewObjMain.netiCmsPurchase}
                                                    layout="list"
                                                    itemTemplate={e => this.itemTemplate(e, 'netiCmsPurchase')}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Approved Post-Paid Order" className="bg-pending">
                                            <div className="nw-data-table">
                                                <DataView
                                                    value={dashboardViewObjMain.approvedPostPaidOrder}
                                                    layout="list"
                                                    itemTemplate={e => this.itemTemplate(e, 'approvedPostPaidOrder')}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <div className="p-sm-12 p-md-6">
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Post-Paid Collection">
                                            <div className="nw-data-table">
                                                <DataView
                                                    value={dashboardViewObjMain.postPaidBillCollection}
                                                    layout="list"
                                                    itemTemplate={e => this.itemTemplate(e, 'postPaidBillCollection')}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Device Purchase" className="bg-success-dark">
                                            <div className="nw-data-table">
                                                <DataView
                                                    value={dashboardViewObjMain.devicePurchase}
                                                    layout="list"
                                                    itemTemplate={e => this.itemTemplate(e, 'devicePurchase')}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Approved Pre-Paid Order" className="bg-purple">
                                            <div className="nw-data-table">
                                                <DataView
                                                    value={dashboardViewObjMain.approvedPrePaidOrder}
                                                    layout="list"
                                                    itemTemplate={e => this.itemTemplate(e, 'approvedPrePaidOrder')}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}