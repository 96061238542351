import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../../components/common/ErrorMessageView';
import { PartnerPointService } from './../../service/partnerPoint/PartnerPointService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { DownloadButton } from "../../utils/DownloadButton";
import NetiContentLoader from '../common/NetiContentLoader';
import { CRMPointService } from "./../../service/crmPoint/CRMPointService";
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from 'primereact/inputtextarea';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { Button } from 'primereact/button';
import { NetiFileHandler } from "../../utils/NetiFileHandler";
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import {InputMask} from 'primereact/inputmask';


let statusListOptions = [
    { label: "Pending", value: 'pending' },
    { label: "Approve", value: 1 },
    { label: "Reject", value: 2 }
]
export class HostingApproval extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hostingErrorInfo: {},
            hostingDetailsInfo: {
                requestingUserBasicInfoDTO: {
                    imagePath: ''
                }
            },
            errors:{},
            partnerPicture: '',
            passwordIcon: true,
            hidden: true,
            approvalValue: 'pending',
            memberStatus: '',
            dataTableValue: [],
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            homeReturnButton: false,
            hostingApproveObj:{
                domainhostingOrderDTO: {
                    domainhostingOrderID:'' 
                },
                purchaseDate: '',
                reminderDate: '',
                expiryDate: '',
                username: '',
                password: '',
                contact: '',
                email: '',
                purchaseFrom: '',
                approveNote: '',
            },
            searchInfoObj: {
                pageNo:0
            },
            first: 0, 
            rows: 10, 

        }

        this.PartnerPointService = new PartnerPointService();
        this.CRMPointService = new CRMPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.ValidatorUtility = new ValidatorUtility();
        this.netiContentLoader = new NetiContentLoader();
        this.viewDialog = this.viewDialog.bind(this)
        this.onHide = this.onHide.bind(this)
        this.netiFileHandler = new NetiFileHandler();
        this.togglePasswordShow = this.togglePasswordShow.bind(this)
    }

    componentWillMount() {
        this.viewHostingPendingList();
    }

    viewHostingPendingList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.CRMPointService.getDomainAndHostingListByStatus(0,"hosting",this.state.searchInfoObj)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].domainhostingOrderDate == null) {
                                body[i].domainhostingOrderDate = '';
                            } else {
                                body[i].domainhostingOrderDate = NetiDateUtils.getAnyShortForm(body[i].domainhostingOrderDate, 'DD-MMM-YYYY hh:mm:ss a');
                            }
                            if (body[i].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate == null) {
                                body[i].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate = '';
                            } else {
                                body[i].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate = NetiDateUtils.getAnyShortForm(body[i].productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY hh:mm:ss a');
                            }

                            if (body[i].domainhostingOrderStatus == 0) {
                                body[i].domainhostingOrderStatus = 'Pending';
                            } 
                        }
                        this.setState({ dataTableValue: body })
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                    })
                } else {
                    this.PartnerPointService.Auth.handleErrorStatus(res)
                        .then((respon) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: respon });
                        })
                }
            }).catch(err => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Unable to load table" });
            })
    }


    // onHide = (event) => {
    //     this.setState({ visible: false });
    // }

    // viewDialog = (rowData) => {
    //     this.setState({ visible: true });
    // }

    actionTemplate = (rowData) => {
        return (
            <DownloadButton fileName={rowData.attachFileName} filePath={rowData.attachFilePath} />
        )
    }



    // actionTemplateProductName = (rowData) => {
    //     return <span>{rowData.productPurchaseCodeDTO.productPurchaseLogDTO.productInfoDTO.productName}</span>;
    // }

    // actionTemplateProductCode = (rowData) => {
    //     return <span>{rowData.productPurchaseCodeDTO.purchaseCode}</span>;
    // }


    onChangeStatus = (e) => {
        let { errors } = this.state;
        errors["transactionAndApproveError"] = ''
        this.setState({ errors, approvalValue: e.target.value });
    }


    onChangeReject = (e) => {
        let { hostingErrorInfo, hostingApproveObj } = this.state;
        hostingErrorInfo["note"] = ''
        hostingApproveObj.approveNote = e.target.value; 
        this.setState({ hostingErrorInfo, hostingApproveObj });
    }

    onChangeUserName = (e) => {
        let { hostingErrorInfo, hostingApproveObj } = this.state;
        hostingErrorInfo["username"] = ''
        hostingApproveObj.username = e.target.value; 
        this.setState({ hostingErrorInfo, hostingApproveObj });
    }

    onChangePassword = (e) => {
        let { hostingErrorInfo, hostingApproveObj } = this.state;
        hostingErrorInfo["password"] = ''
        hostingApproveObj.password = e.target.value; 
        this.setState({ hostingErrorInfo, hostingApproveObj });
    }

    onChangePurchaseDate = (e) => {
        let { hostingErrorInfo, hostingApproveObj } = this.state;
        hostingErrorInfo["purchaseDate"] = ''
        hostingApproveObj.purchaseDate = e.target.value; 
        this.setState({ hostingErrorInfo, hostingApproveObj });
    }

    onChangeExpiryDate = (e) => {
        let { hostingErrorInfo, hostingApproveObj } = this.state;
        hostingErrorInfo["expireDate"] = ''
        hostingApproveObj.expiryDate = e.target.value; 
        this.setState({ hostingErrorInfo, hostingApproveObj });
    }

    onChangeReminderDate = (e) => {
        let { hostingErrorInfo, hostingApproveObj } = this.state;
        hostingErrorInfo["reminderDate"] = ''
        hostingApproveObj.reminderDate = e.target.value; 
        this.setState({ hostingErrorInfo, hostingApproveObj });
    }

    onChangeDomainProvider = (e) => {
        let { hostingErrorInfo, hostingApproveObj } = this.state;
        hostingErrorInfo["domainProvider"] = ''
        hostingApproveObj.purchaseFrom = e.target.value; 
        this.setState({ hostingErrorInfo, hostingApproveObj });
    }

    onChangeEmail = (e) => {
        let { hostingErrorInfo, hostingApproveObj } = this.state;
        hostingErrorInfo["email"] = ''
        hostingApproveObj.email = e.target.value; 
        this.setState({ hostingErrorInfo, hostingApproveObj });
    }

    onChangeContact = (e) => {
        let { hostingErrorInfo, hostingApproveObj } = this.state;
        hostingErrorInfo["contact"] = ''
        console.log("e.target.value",e.target.value);
        hostingApproveObj.contact = e.target.value; 
        this.setState({ hostingErrorInfo, hostingApproveObj });
    }

    actionBodyTemplet = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-edit"
                tooltip="Update"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    viewDialog(rowData) {
        this.setState({ visible: true });
        this.setState({hostingDetailsInfo: rowData})
        this.getProfileImg();
    }

    onHide() {
        this.setState({ visible: false })

    }

    togglePasswordShow(event, props) {
        this.setState({
            hidden: !this.state.hidden,
            passwordIcon: !this.state.passwordIcon
        });

    }

    getProfileImg() {

        let { hostingDetailsInfo } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        if (hostingDetailsInfo.requestingUserBasicInfoDTO.imagePath !== null) {
            this.netiFileHandler.getByteImage(hostingDetailsInfo.requestingUserBasicInfoDTO.imagePath)
                .then((res) => {

                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(hostingDetailsInfo.requestingUserBasicInfoDTO.imageName);
                                this.setState({ partnerPicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ partnerPicture: staticImg });
                    }
                });
        } else { this.setState({ partnerPicture: staticImg }) };

    }

    onSubmitUpdate = () => {
        let { hostingDetailsInfo, hostingApproveObj } = this.state

       if (this.onSubmitErrorUpdate()) {

            this.setState({  topProgressBar: true, errorMsgVisible: false });

            hostingApproveObj.domainhostingOrderDTO.domainhostingOrderID = hostingDetailsInfo.domainhostingOrderID;
            this.setState({ hostingApproveObj });

            this.CRMPointService.approveDomainAndHosting(this.state.approvalValue, this.state.hostingApproveObj)
                .then(res => {
                    console.log(res);
                    if (res.status === 202) {
                        this.setState({  bankName: '', transactionNumber: '', approveNote: '', approvalValue: 'pending', topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Update successfully' });
                        setTimeout(() => {
                            this.onHide();            
                        }, 800);
                        this.viewHostingPendingList();

                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({  topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
                });
        }
    }

    onSubmitErrorUpdate = () => {
        let { hostingErrorInfo, hostingApproveObj } = this.state;
        let formIsValid = true;

        if(this.state.approvalValue === 1 ) {
            if (hostingApproveObj.username === '') {
                formIsValid = false;
                hostingErrorInfo["username"] = "User name can't left empty.";
            }
    
            if (hostingApproveObj.password === '') {
                formIsValid = false;
                hostingErrorInfo["password"] = "Password can't left empty.";
            }
            if (hostingApproveObj.purchaseDate === '') {
                formIsValid = false;
                hostingErrorInfo["purchaseDate"] = "Purchase Date can't left empty.";
            }
            if (hostingApproveObj.expiryDate === '') {
                formIsValid = false;
                hostingErrorInfo["expireDate"] = "Expire Date can't left empty.";
            }
            if (hostingApproveObj.reminderDate === '') {
                formIsValid = false;
                hostingErrorInfo["reminderDate"] = "Reminder Date can't left empty.";
            }
            if (hostingApproveObj.purchaseFrom === '') {
                formIsValid = false;
                hostingErrorInfo["domainProvider"] = "Domain Provider can't left empty.";
            }
            if (hostingApproveObj.email === '') {
                formIsValid = false;
                hostingErrorInfo["email"] = "Email can't left empty.";
            }
            if(hostingApproveObj.email !== '' && this.ValidatorUtility.emailIsValid(hostingApproveObj.email) !== true) {
                formIsValid = false;
                hostingErrorInfo["email"] = "Invalid Email format.";
            }
            if (hostingApproveObj.contact === '') {
                formIsValid = false;
                hostingErrorInfo["contact"] = "Contact no. can't left empty.";
            }
        }

        if(this.state.approvalValue === 2) {
            if (hostingApproveObj.approveNote === '') {
                formIsValid = false;
                hostingErrorInfo["note"] = "Note can't left empty.";
            }
        }

        this.setState({ hostingErrorInfo });
        return formIsValid;
    }




    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody,hostingDetailsInfo, hostingApproveObj  } = this.state;
        let { dataTableValue } = this.state;

        let headerName;

        if (this.props.domain === true) {
            headerName = "Domain"
        } else if (this.props.hosting === true) {
            headerName = "Hosting"
        }
        var tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Hosting Pending Order List
            <span style={{ 'float': 'right' }}>Total Found: {this.state.dataTableValue.length} </span>
        </div>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid">
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                    <div className="p-col-12 p-xl-12">
                        {this.state.dataTableIsLoading ? this.netiContentLoader.MyPointTableLoader() :
                            <div className="nw-data-table nw-data-table-tabview">
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    selection={this.state.dataTableSelection}
                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                    columnResizeMode="fit"
                                >
                                    <Column field="domainhostingOrderDate" header="Order Date" filter={true} />
                                    <Column field="domainhostingOrderStatus" header="Order Status" filter={true} />
                                    <Column field="productPurchaseCodeDTO.productPurchaseLogDTO.productInfoDTO.productName" header="Product Name" body={this.actionTemplateProductName} filter={true} />
                                    <Column field="productPurchaseCodeDTO.purchaseCode" header="Purchase Code" body={this.actionTemplateProductCode} filter={true} />
                                    <Column field="productPurchaseCodeDTO.productPurchaseLogDTO.purchaseDate" header="Purchase Date" filter={true} />
                                    <Column field="" header="Action" body={this.actionBodyTemplet} style={{ textAlign: "center", width: "80px" }} />
                                </DataTable>
                            </div>
                        }
                    </div>
                    <Dialog
                        className="nw-dialog"
                        header="Hosting Request"
                        // className="nonHideDialog customDialogWidth " 
                        onHide={this.onHide}
                        visible={this.state.visible}
                        dismissableMask={true}
                        blockScroll={true}
                    >

                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">

                                    <div className="p-col-12 p-xl-12">
                                        <Fieldset legend="Requesting User Information" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                            <div className="p-col-12 p-xl-12">
                                                        <center>
                                                            <img
                                                                src={this.state.partnerPicture}
                                                                alt="partner picture"
                                                                style={{ height: "80px" }}
                                                            />
                                                        </center>
                                                    </div> 
                                                    
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Neti ID
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {hostingDetailsInfo.requestingUserBasicInfoDTO && hostingDetailsInfo.requestingUserBasicInfoDTO.customNetiID}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Name
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {hostingDetailsInfo.requestingUserBasicInfoDTO && hostingDetailsInfo.requestingUserBasicInfoDTO.fullName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Mobile No
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {hostingDetailsInfo.requestingUserBasicInfoDTO && hostingDetailsInfo.requestingUserBasicInfoDTO.basicMobile}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Wallet Balance
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {hostingDetailsInfo.requestingUserBasicInfoDTO && hostingDetailsInfo.requestingUserBasicInfoDTO.userWalletBalance ? this.ValidatorUtility.currencyFormatter(hostingDetailsInfo.requestingUserBasicInfoDTO.userWalletBalance) : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </Fieldset>

                                        <Fieldset legend="" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Status</span>
                                                        <Dropdown
                                                            placeholder='Select Status'
                                                            options={statusListOptions}
                                                            value={this.state.approvalValue}
                                                            onChange={this.onChangeStatus}
                                                            filter={true}
                                                            filterBy="label,value"
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                </div>

                                                {
                                                    this.state.approvalValue === 2 ?
                                                        <div className="">
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon nw-inputtextarea-label">Reject Note <span>*</span></span>
                                                                    <InputTextarea
                                                                        name="note"
                                                                        placeholder='Enter Reject Note'
                                                                        value={hostingApproveObj.approveNote}
                                                                        onChange={this.onChangeReject}
                                                                    />
                                                                </div>
                                                                <span className="error-message">{this.state.hostingErrorInfo["note"]}</span>
                                                            </div>
                                                            <div className="p-col-12 p-xl-12 nw-button-parent">
                                                                <Button
                                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                                    label="Submit"
                                                                    icon="fa fa-save"
                                                                    onClick={this.onSubmitUpdate}
                                                                />
                                                            </div>
                                                        </div>
                                                        : ""
                                                }

                                               

                                            </div>

                                        </Fieldset>
                                        {
                                                    this.state.approvalValue === 1 ?

                                                <Fieldset legend="Input Hosting Info" className="p-col-12 p-xl-12">
                                                    <div className="p-col-12 p-xl-12">

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">user name  <span>*</span></span>
                                                                <InputText
                                                                    placeholder='Enter User Name'
                                                                    name="username"
                                                                    value={hostingApproveObj.username}
                                                                    onChange={this.onChangeUserName}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.hostingErrorInfo["username"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">password  <span>*</span></span>
                                                                <InputText
                                                                    type={this.state.hidden ? "password" : "text"}
                                                                    placeholder='Enter Password'
                                                                    name="password"
                                                                    keyfilter="alphanum"
                                                                    value={hostingApproveObj.password}
                                                                    onChange={this.onChangePassword}
                                                                />
                                                                <Button
                                                                    icon={this.state.passwordIcon ? "far fa-eye-slash" : "far fa-eye"}
                                                                    className=""
                                                                    onClick={(event) => this.togglePasswordShow(event, this.props)} />
                                                                {/* <InputText
                                                                    placeholder='Enter Password'
                                                                    name="password"
                                                                    value={hostingApproveObj.password}
                                                                    onChange={this.onChangePassword}
                                                                /> */}
                                                            </div>
                                                            <span className='error-message'>{this.state.hostingErrorInfo["password"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">purchase date  <span>*</span></span>
                                                                <Calendar
                                                                    name="purchaseDate"
                                                                    dateFormat="dd/mm/yy"
                                                                    showIcon={true}
                                                                    value={hostingApproveObj.purchaseDate}
                                                                    onChange={this.onChangePurchaseDate}
                                                                    placeholder='Enter Purchase Date'
                                                                    className="custom-calender-input"
                                                                    autoWidth={false}
                                                                    yearRange="2010:2030"
                                                                    monthNavigator={true}
                                                                    yearNavigator={true}
                                                                    dateOnly="true"
                                                                    readOnlyInput={true}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.hostingErrorInfo["purchaseDate"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">expire date  <span>*</span></span>
                                                                <Calendar
                                                                    name="expireDate"
                                                                    dateFormat="dd/mm/yy"
                                                                    showIcon={true}
                                                                    value={hostingApproveObj.expiryDate}
                                                                    onChange={this.onChangeExpiryDate}
                                                                    placeholder='Enter Expire Date'
                                                                    className="custom-calender-input"
                                                                    autoWidth={false}
                                                                    yearRange="2010:2030"
                                                                    monthNavigator={true}
                                                                    yearNavigator={true}
                                                                    dateOnly="true"
                                                                    readOnlyInput={true}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.hostingErrorInfo["expireDate"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Reminder date  <span>*</span></span>
                                                                <Calendar
                                                                    name="reminderDate"
                                                                    dateFormat="dd/mm/yy"
                                                                    showIcon={true}
                                                                    value={hostingApproveObj.reminderDate}
                                                                    onChange={this.onChangeReminderDate}
                                                                    placeholder='Enter Reminder Date'
                                                                    className="custom-calender-input"
                                                                    autoWidth={false}
                                                                    yearRange="2010:2030"
                                                                    monthNavigator={true}
                                                                    yearNavigator={true}
                                                                    dateOnly="true"
                                                                    readOnlyInput={true}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.hostingErrorInfo["reminderDate"]}</span>
                                                        </div>


                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Domain provider <span>*</span></span>
                                                                <InputText
                                                                    placeholder='Enter Domain Provider'
                                                                    name="domainProvider"
                                                                    value={hostingApproveObj.purchaseFrom}
                                                                    onChange={this.onChangeDomainProvider}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.hostingErrorInfo["domainProvider"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Email <span>*</span></span>
                                                                <InputText
                                                                    placeholder='Enter Email'
                                                                    name="email"
                                                                    type="text"
                                                                    keyfilter="email"
                                                                    value={hostingApproveObj.email}
                                                                    onChange={this.onChangeEmail}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.hostingErrorInfo["email"]}</span>
                                                        </div>

                                                        <div className="p-col-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Contact No. <span>*</span></span>
                                                                <InputMask
                                                                    placeholder='Enter Contact'
                                                                    name="contact"
                                                                    mask="01999999999"
                                                                    keyfilter="pint"
                                                                    maxLength={9}
                                                                    value={hostingApproveObj.contact}
                                                                    onChange={this.onChangeContact}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.hostingErrorInfo["contact"]}</span>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                                            <Button
                                                                className="p-button p-button-primary nw-button nw-button-right"
                                                                label="Submit"
                                                                icon="fa fa-save"
                                                                onClick={this.onSubmitUpdate}
                                                            />
                                                        </div>



                                                    </div>



                                                </Fieldset>
                                               : ""
                                            }

                                    </div>

                                </div>
                            </div>
                        </div>


                    </Dialog>
                </div>
            </div>
        );
    }
}