import { DWS_POINT } from "./PointWiseRouteConsts";

export const CMSPointMenu = {
  items: [
    {
      label: "CMS Point",
      icon: "far fa-file-word",

      items: [
        { label: "Dashboard", icon: "", to: DWS_POINT.DEMO_CMS_DASHBOARD }, //pi pi-fw pi-home
        {
          label: "Settings",
          icon: "", //pi pi-fw pi-user
          items: [
            // { label: 'Home', icon: '', to: DWS_POINT.HOME }, //pi pi-fw pi-money-bill
            // { label: 'Menu Config', icon: '', to: DWS_POINT.MENU_CONFIG }, //pi pi-fw pi-money-bill
            // { label: 'Theme Config', icon: '', to: DWS_POINT.CMS_THEME_CONFIG }, //pi pi-fw pi-money-bill
            // { label: 'Mapped Institute', icon: '', to: DWS_POINT.MAPPED_INSTITUTE }, //pi pi-fw pi-money-bill

            { label: "Home", icon: "", to: DWS_POINT.ADMIN_HOME }, //pi pi-fw pi-money-bill
            {
              label: "Mapped New Institute",
              icon: "",
              to: DWS_POINT.ADMIN_NEW_INSTITUTE_MAPPING,
            }, //pi pi-fw pi-money-bill
            {
              label: "Menu Config",
              icon: "",
              to: DWS_POINT.CMS_MENU_CONFIG,
            },
            // { label: 'Mapped New Institute2', icon: '', to: DWS_POINT.ADMIN_NEW_INSTITUTE_MAPPING_TEST }, //pi pi-fw pi-money-bill
          ],
        },
        {
          label: "Content Manage",
          icon: "", //pi pi-fw pi-user
          items: [
            // {
            //     label: 'Photo Gallery', icon: '', to: DWS_POINT.PHOTO_GALLERY, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Welcome Speech', icon: '', to: DWS_POINT.WELCOME_SPEECH, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Notice', icon: '', to: DWS_POINT.NOTICE, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Event Log', icon: '', to: DWS_POINT.EVENT_LOG, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Important Link', icon: '', to: DWS_POINT.IMPORTANT_LINK, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Administration', icon: '', to: DWS_POINT.ADMINISTRATION, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'About Us', icon: '', to: DWS_POINT.ABOUT, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Book Info', icon: '', to: DWS_POINT.BOOK_INFO, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Dress Code', icon: '', to: DWS_POINT.DRESS_CODE, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Fees Info', icon: '', to: DWS_POINT.FEES_INFO, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Seat Info', icon: '', to: DWS_POINT.SEAT_INFO, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Syllabus', icon: '', to: DWS_POINT.SYLLABUS, //pi pi-fw pi-money-bill
            // },
            // {
            //     label: 'Download Corner', icon: '', to: DWS_POINT.DOWNLOAD_CORNER, //pi pi-fw pi-money-bill
            // },

            { label: "Class Info", icon: "", to: DWS_POINT.ADMIN_CLASS_INFO }, //pi pi-fw pi-money-bill
            { label: "Group Info", icon: "", to: DWS_POINT.ADMIN_GROUP_INFO }, //pi pi-fw pi-money-bill
            { label: "Notice Info", icon: "", to: DWS_POINT.ADMIN_NOTICE_INFO }, //pi pi-fw pi-money-bill
            { label: "Book Info", icon: "", to: DWS_POINT.ADMIN_BOOK_INFO }, //pi pi-fw pi-money-bill
            {
              label: "Syllabus Info",
              icon: "",
              to: DWS_POINT.ADMIN_SYLLABUS_INFO,
            }, //pi pi-fw pi-money-bill
            { label: "Speech Info", icon: "", to: DWS_POINT.ADMIN_SPEECH_INFO }, //pi pi-fw pi-money-bill
            { label: "Seat Info", icon: "", to: DWS_POINT.ADMIN_SEAT_INFO }, //pi pi-fw pi-money-bill
            {
              label: "Download Corner",
              icon: "",
              to: DWS_POINT.ADMIN_DOWNLOAD_CORNER,
            }, //pi pi-fw pi-money-bill
            { label: "Fees Info", icon: "", to: DWS_POINT.ADMIN_FEES_INFO }, //pi pi-fw pi-money-bill
            {
              label: "Dress Code Info",
              icon: "",
              to: DWS_POINT.ADMIN_DRESS_CODE_INFO,
            }, //pi pi-fw pi-money-bill
            {
              label: "Administration",
              icon: "",
              to: DWS_POINT.ADMIN_ADD_MEMBER_INFO,
            }, //pi pi-fw pi-money-bill
            { label: "Event Info", icon: "", to: DWS_POINT.ADMIN_EVENT_INFO }, //pi pi-fw pi-money-bill
            {
              label: "Link List",
              icon: "",
              to: DWS_POINT.ADMIN_IMPORTANT_LINK,
            }, //pi pi-fw pi-money-bill
            {
              label: "Gallery Image",
              icon: "",
              to: DWS_POINT.ADMIN_GALLERY_IMAGE,
            }, //pi pi-fw pi-money-bill
            { label: "About Us", icon: "", to: DWS_POINT.ADMIN_ABOUT_US }, //pi pi-fw pi-money-bill
            { label: "Alumnus", icon: "", to: DWS_POINT.ADMIN_ALUMNUS_INFO }, //pi pi-fw pi-money-bill
          ],
        },
        {
          label: "Admisia",
          icon: "", //pi pi-fw pi-user
          items: [
            {
              label: "Core Config",
              icon: "",
              to: DWS_POINT.ADMISIA_CORE_CONFIG,
            }, //pi pi-fw pi-money-bill
            // { label: 'Mapped New Institute', icon: '', to: DWS_POINT.ADMIN_NEW_INSTITUTE_MAPPING }, //pi pi-fw pi-money-bill

            {
              label: "Application Config",
              icon: "",
              to: DWS_POINT.ADMISIA_APPLICATION_CONFIG,
            },

            {
              label: "Approval",
              icon: "", //pi pi-fw pi-user
              items: [
                {
                  label: "Assessment",
                  icon: "",
                  to: DWS_POINT.ADMISIA_ASSESSMENT,
                },
                {
                  label: "Admission",
                  icon: "",
                  to: DWS_POINT.ADMISIA_ADMISSION_APPROVAL,
                },
              ],
            },

            { label: "Reports", icon: "" },

            {
              label: "Applicant",
              icon: "", //pi pi-fw pi-user
              items: [
                {
                  label: "Applicant Analytics",
                  icon: "",
                  to: DWS_POINT.ADMISIA_APPLICANT_ANALYTICS,
                },
                {
                  label: "Applicant Status",
                  icon: "",
                  to: DWS_POINT.ADMISIA_APPLICANT_STATUS,
                },
                {
                  label: "Applicant Info",
                  icon: "",
                  to: DWS_POINT.ADMISIA_APPLICANT_INFO,
                },
                {
                  label: "At a Glance",
                  icon: "",
                  to: DWS_POINT.ADMISIA_APPLICANT_AT_A_GLANCE,
                },
              ],
            },

            {
              label: "Payment",
              icon: "", //pi pi-fw pi-user
              items: [
                {
                  label: "Collection Analytics",
                  icon: "",
                  to: DWS_POINT.ADMISIA_PAYMENT_COLLECTION_ANALYTICS,
                },
                {
                  label: "Collection Info",
                  icon: "",
                  to: DWS_POINT.ADMISIA_PAYMENT_COLLECTION_INFO,
                },
                {
                  label: "OA Collection Analytics",
                  icon: "",
                  to: DWS_POINT.OA_COLLECTION_ANALYTICS,
                },
              ],
            },
          ],
        },
        {
          label: "Visitor Feedback",
          icon: "",
          to: DWS_POINT.VISITOR_FEEDBACK, //pi pi-fw pi-user
        },
      ],
    },
  ],
};
