import React, { Component } from "react";
import { Button } from "primereact/button";
import { Link } from 'react-router-dom';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { MY_POINT } from "../../../utils/PointWiseRouteConsts";

function TokenCommonBodyNew(props) {
  let { tokenList } = props;
  return (
    <div className="p-inputgroup">
        <span className="p-inputgroup-addon info blur-section">
            <div> Token Id: { tokenList && tokenList.customTokenID}</div>
            <div> Issue Date: { tokenList && NetiDateUtils.getAnyShortForm(tokenList.createDate, 'DD-MMM-YYYY hh:mm:ss a') }</div>
            { tokenList && tokenList.tokenStatus == 10? 
                <div> Solve Date: { tokenList && NetiDateUtils.getAnyShortForm(tokenList.solveDate, 'DD-MMM-YYYY hh:mm:ss a') }</div>
                :''
            }
        </span>

        <Link to={{ pathname: MY_POINT.NEW_TOKEN, tokenList: tokenList }}>
            <i className="fas fa-angle-right"/><i className="fas fa-angle-right"/>
        </Link>
        <Button
            label="View"
            className={ tokenList && tokenList.tokenStatus == 10? "bg-success blur-section" : tokenList && tokenList.tokenStatus == 0 ? "bg-warning blur-section" : ''}
            onClick={ e => props.showTokenDetails(tokenList) }
        />
    </div>
    );
}

export default TokenCommonBodyNew;