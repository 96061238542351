import React, { Component } from 'react';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';

export class TeacherAddressInfo extends Component {

    constructor(props) {
        super(props);
        this.teacherPortal = new TeacherPortal();
    }

    render() {

        let permanentHouseNo, permanentRoadNo, permanentPost, permanentUpozilla, permanentDistrict, permanentDevision,
            presentHouseNo, presentRoadNo, presentPost, presentUpozilla, presentDistrict, presentDevision

        if (this.props.basicInfo.item && this.props.basicInfo.item.staffAddress) {

            permanentHouseNo = this.props.basicInfo.item.staffAddress.permanentHouseNo;
            permanentRoadNo = this.props.basicInfo.item.staffAddress.permanentRoadNo;
            permanentPost = this.props.basicInfo.item.staffAddress.permanentPo;
            permanentUpozilla = this.props.basicInfo.item.staffAddress.permanentUpozilla;
            permanentDistrict = this.props.basicInfo.item.staffAddress.permanentDistrict;
            permanentDevision = this.props.basicInfo.item.staffAddress.permanentDivision;

            presentHouseNo = this.props.basicInfo.item.staffAddress.presentHouseNo;
            presentRoadNo = this.props.basicInfo.item.staffAddress.presentRoadNo;
            presentPost = this.props.basicInfo.item.staffAddress.presentPo;
            presentUpozilla = this.props.basicInfo.item.staffAddress.presentUpozilla;
            presentDistrict = this.props.basicInfo.item.staffAddress.presentDistrict;
            presentDevision = this.props.basicInfo.item.staffAddress.presentDivision;

        }

        return (
            <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                <div className="protal-single-info-box">
                    <div className="protal-single-info-title">
                        <h4>Address Info</h4>
                    </div>
                    <div className="protal-single-info-content-box">
                        <div className="protal-single-info-content">
                            <h4>Present Address</h4>
                            <p>{`${permanentHouseNo ? "House- " + permanentHouseNo + ", Road- " : ''}
                                ${permanentRoadNo ? permanentRoadNo + ", Village- " : ''}
                                ${permanentUpozilla ? permanentUpozilla + ", P.O- " : ''}
                                ${permanentPost ? permanentPost + ", District- " : ''}
                                ${permanentDistrict ? permanentDistrict + ", Division- " : ''}
                                ${permanentDevision ? permanentDevision : ''}`.trim() || '---'}
                            </p>
                        </div>
                        <div className="protal-single-info-content">
                            <h4>Present Address</h4>
                            <p>{`${presentHouseNo ? "House- " + presentHouseNo + ", Road- " : ''}
                                ${presentRoadNo ? presentRoadNo + ", Village- " : ''}
                                ${permanentUpozilla ? permanentUpozilla + ", P.O- " : ''}
                                ${presentPost ? presentPost + ", District- " : ''}
                                ${presentDistrict ? presentDistrict + ", Division- " : ''}
                                ${presentDevision ? presentDevision : ''}`.trim() || '---'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}