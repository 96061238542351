import React, { Component } from 'react';
import { CarService } from '../service/CarService';
import { Button } from 'primereact/components/button/Button';
import { TabView, TabPanel } from 'primereact/tabview';
import AuthService from '../auth/AuthService';
import { NetiFileHandler } from '../utils/NetiFileHandler';
import { FullCalendar } from 'primereact/fullcalendar';
import { UserOtherAccessService } from '../service/UserOtherAccessService';
import { ErrorMessageView } from './common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import NetiContentLoader from './common/NetiContentLoader';
import { NetiDateUtils } from '../utils/NetiDateUtils';
import { ValidatorUtility } from '../utils/ValidatorUtility';

export class HomeDashboard extends Component {

    constructor() {
        super();
        this.state = {
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            homeDashboardInfo: ''
        }
        this.userOtherAccessService = new UserOtherAccessService();
        this.validatorUtility = new ValidatorUtility();

    }

    componentWillMount() {
        this.getHomeDashboardInfo();
    }

    getHomeDashboardInfo() {
        this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' });
        this.userOtherAccessService.getHomeDashboardInfoList()
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        console.log('homeDashboard-body', body);

                        this.setState({ homeDashboardInfo: body })
                        this.setState({ topProgressBar: false, errorMsgVisible: false, errorMsgBody: '' });

                    });
                }
                else {
                    this.userOtherAccessService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, homeDashboardInfo } = this.state;

        return (

            <div className="main-section netiWorld-DashBoard-wrapper">
                <div className="netiWorld-DashBoard">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} />

                    <div className="p-grid">

                        <div class="p-col-12 p-md-12 p-xl-7 personalInfo-wrapper blur-section">
                        <div className="profile-info-wrapper ">
                                <div className="profile-pic-wrapper">

                                        {/* <img src={(`data:image/*;base64, ${commonProfileInfo.imageContent}`) } className="profile-pic" /> */}
                                        {/* : */}
                                        <img src='assets/layout/images/avatar.png' className="profile-pic" />
                                    {/* } */}
                                    
                                    <span className="active"></span>
                                    <div className="change-image" onClick={this.showDialog}></div>
                                </div>
                                <div className="profile-info">
                                    <span className="netiID">NETI ID : { homeDashboardInfo.customNetiID }</span>
                                    <span className="userName">{ 'homeDashboardInfo.fullName' }</span>
                                    {/* <span className="mobileNumber">{ homeDashboardInfo.basicMobile }</span>
                                    <span className="netimail">{ homeDashboardInfo.basicEmail }</span> */}
                                </div>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-xl-5">
                            <div style={{ backgroundColor: '#E6F4FF', marginBottom: '30px' }}> em</div>

                        </div>

                    </div>

                    <div className="p-grid m-0">
                        <div class="p-col-12 p-md-12 p-xl-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                    <div className="notify-box-wrapper gradient-color-navy-blue b-r-10">
                                        <div className="notify-box-wrapper-bg-image boxHeight-141">
                                            <div className="notify-box clearfix">
                                                <div className="notify-title-position-l-b">
                                                    <div className="notify-box-title">
                                                        <div className="notify-box-icon wallet-icon"></div>
                                                        <h1 className="font-16 color-white">Wallet Balance</h1>
                                                        <hr />
                                                        <div className="notify-currency">
                                                            <p className="font-20 color-white tk-sign">{homeDashboardInfo ? this.validatorUtility.currencyFormatter(homeDashboardInfo.userWalletBalance) : ''}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                    <div className="notify-box-wrapper gradient-color-feroza-lighy-feroza  b-r-10">
                                        <div className="notify-box-wrapper-bg-image boxHeight-141">
                                            <div className="notify-box clearfix">
                                                <div className="notify-title-position-l-b">
                                                    <div className="notify-box-title">
                                                        <div className="notify-box-icon msg-icon"></div>
                                                        <h1 className="font-16 color-white">Message Balance</h1>
                                                        <hr />
                                                        <div className="notify-currency">
                                                            <p className="font-20 color-white arrow-sign">{homeDashboardInfo ? this.validatorUtility.currencyFormatter(homeDashboardInfo.messageBalance) : ''}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                    <div className="notify-box-wrapper gradient-color-deep-red  b-r-10">
                                        <div className="notify-box-wrapper-bg-image boxHeight-141">
                                            <div className="notify-box clearfix">
                                                <div className="notify-title-position-l-b">
                                                    <div className="notify-box-title">
                                                        <div className="notify-box-icon today-icon"></div>
                                                        <h1 className="font-16 color-white">Email Balance</h1>
                                                        <hr />
                                                        <div className="notify-currency">
                                                            <p className="font-20 color-white active-sign">{homeDashboardInfo ? this.validatorUtility.currencyFormatter(homeDashboardInfo.emailBalance) : ''}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                    <div className="notify-box-wrapper gradient-color-light-green  b-r-10">
                                        <div className="notify-box-wrapper-bg-image boxHeight-141">
                                            <div className="notify-box clearfix">
                                                <div className="notify-title-position-l-b">
                                                    <div className="notify-box-title">
                                                        <div className="notify-box-icon user-icon"></div>
                                                        <h1 className="font-16 color-white">Assigned Point</h1>
                                                        <hr />
                                                        <div className="notify-currency">
                                                            <p className="font-20 color-white active-sign">{homeDashboardInfo ? this.validatorUtility.currencyFormatter(homeDashboardInfo.assignedPoints) : ''}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-grid m-0">
                        <div class="p-col-12 p-md-12 p-xl-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                    <div className="notify-box-wrapper gradient-color-deep-orange  b-r-10">
                                        <div className="notify-box-wrapper-bg-image boxHeight-141">
                                            <div className="notify-box clearfix">
                                                <div className="notify-title-position-l-b">
                                                    <div className="notify-box-title">
                                                        <div className="notify-box-icon wallet-icon"></div>
                                                        <h1 className="font-16 color-white">Unsolved Token</h1>
                                                        <hr />
                                                        <div className="notify-currency">
                                                            <p className="font-20 color-white tk-sign">{homeDashboardInfo ? this.validatorUtility.currencyFormatter(homeDashboardInfo.unsolvedToken) : ''}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                    <div className="notify-box-wrapper gradient-color-light-red  b-r-10">
                                        <div className="notify-box-wrapper-bg-image boxHeight-141">
                                            <div className="notify-box clearfix">
                                                <div className="notify-title-position-l-b">
                                                    <div className="notify-box-title">
                                                        <div className="notify-box-icon msg-icon"></div>
                                                        <h1 className="font-16 color-white">Non Rating Token</h1>
                                                        <hr />
                                                        <div className="notify-currency">
                                                            <p className="font-20 color-white arrow-sign">{homeDashboardInfo ? this.validatorUtility.currencyFormatter(homeDashboardInfo.notRatedToken) : ''}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                    <div className="notify-box-wrapper gradient-color-sky-blue b-r-10">
                                        <div className="notify-box-wrapper-bg-image boxHeight-141">
                                            <div className="notify-box clearfix">
                                                <div className="notify-title-position-l-b">
                                                    <div className="notify-box-title">
                                                        <div className="notify-box-icon user-icon"></div>
                                                        <h1 className="font-16 color-white">Unread Neti Mail</h1>
                                                        <hr />
                                                        <div className="notify-currency">
                                                            <p className="font-20 color-white active-sign">0</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
                                    <div className="notify-box-wrapper gradient-color-light-purple2 b-r-10">
                                        <div className="notify-box-wrapper-bg-image boxHeight-141">
                                            <div className="notify-box clearfix">
                                                <div className="notify-title-position-l-b">
                                                    <div className="notify-box-title">
                                                        <div className="notify-box-icon today-icon"></div>
                                                        <h1 className="font-16 color-white">Unread Notice</h1>
                                                        <hr />
                                                        <div className="notify-currency">
                                                            <p className="font-20 color-white">0</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        )
    }
}
