import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Messages } from 'primereact/messages';
import { Dropdown } from 'primereact/dropdown';
import { SeatInfoList } from './SeatInfoList';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';

let dialogStatus = false;
let errors = {};
let dropDownListName1 = '';
let dropDownListName2 = '';
let cloneStateBeforeMount;
let urlId;
export class AdminDownloadCorner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerName: false,
            dataTableValue: [],
            insertUserObj: {
                fileSerial: '',
                fileTitle: '',
                cmsId: 0,
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            updateUserObj: {
                fileSerial: '',
                fileTitle: '',
                cmsId: 0,
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            uploadFile: {
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            errors: {},
            homeReturnButton: true,
            dataTableIsLoading: false,
            imageInputFieldIsLoading: false,
            // secondDropDownIsLoading: false,
            totalDataFound: 0,
            deleteDialogVisible: false
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.NetiFileHandler = new NetiFileHandler();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {
        urlId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {
            let { insertUserObj, updateUserObj } = this.state
            insertUserObj.cmsId = urlId;
            updateUserObj.cmsId = urlId;
            this.setState({ homeReturnButton: false, insertUserObj, updateUserObj })

            this.viewDownloadCornerList();
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    // reloadCoreUrlIdFromLocal() {
    //     urlId = this.DwsService.getUrlInfoFromLocalStorage();
    //     if (!urlId) {
    //         setTimeout(() => {
    //             this.componentWillMount();
    //         }, 2000);
    //     }
    // }

    // componentWillMount() {

    //     urlId = this.DwsService.getUrlInfoFromLocalStorage();

    //     if (urlId) {
    //         let {insertUserObj, updateUserObj } = this.state
    //         insertUserObj.coreUrlInfoDTO.urlInfoID = urlId;
    //         updateUserObj.coreUrlInfoDTO.urlInfoID = urlId;
    //         this.setState({ insertUserObj, updateUserObj })
    //         this.setState({ homeReturnButton: false })

    //         this.viewDownloadCornerList();
    //     }
    //     else {
    //         this.setState({ homeReturnButton: true })
    //     }
    //     cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    // }

    viewDownloadCornerList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        // let coreUrl = {
        //     coreUrlInfoID: urlId,
        //     pageNo: 0,
        //     limit: 10
        // }

        this.DwsService.cmsDownloadCornerList(urlId)
            .then((res) => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        // this.props.countTableSize(body.length);
                        this.setState({ dataTableValue: body.item });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    showMainForm = (dialogType, rowData) => {
        // console.log(dialogType, rowData);

        if (dialogType == "update") {
            this.downloadFile(rowData, "update")
            Object.assign(this.state.updateUserObj, rowData)
            this.setState({ headerName: true });
        }
        else {
            this.removeUploadFile()
            this.setState({ headerName: false });

        }

        this.setState({ visible: true, dialogType: dialogType, errors: {} });
        this.CommonFuctionality.blurDialogBackgroundActive()
        // this.setState({ visible: true, dialogType: dialogType, updateUserObj: {...rowData} });
    }

    showDeleteDialog = (dialogType, rowData) => {
        this.setState({ deleteDialogVisible: true, deleteUserObj: rowData });
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    onHide = () => {
        this.setState({ visible: false, deleteDialogVisible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive()
    }

    downloadBody = (rowData) => {
        return <div>
            {
                !rowData.fileName ? "No Attachment Found" :
                    <React.Fragment>
                        <Button
                            // label={ rowData.fileName }
                            icon="fas fa-download"
                            onClick={() => this.downloadFile(rowData, 'download')}
                            className="nw-action-button info"
                        /> {rowData.fileName}
                    </React.Fragment>
            }

        </div>
    }

    actionBody = (rowData, column) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                tooltip="Update"
                className="nw-action-button"
                onClick={(e) => this.showMainForm('update', rowData)}
            />

            <Button
                type="button"
                icon="fas fa-trash"
                tooltip="Delete"
                className="nw-action-button p-button-danger"
                onClick={(e) => this.showDeleteDialog('delete', rowData)}
            />

        </div>;
    }

    formDialog = () => {

        let { insertUserObj, updateUserObj, dialogType, errors } = this.state;
        let userObj = { ...insertUserObj }
        if (dialogType == 'insert') {
            userObj = { ...insertUserObj }
        }
        else if (dialogType == 'update') {
            userObj = { ...updateUserObj }
        }

        //let uploadFileLabel = <div>Click / Drag File Here To Upload <br /><span className="upload-file-label">(Max 2MB (recommended))</span></div>
        let uploadFileLabel = <div>Click / Drag File Here To Upload <br /><span className="upload-file-label">(Max 5MB (recommended))</span></div>

        return (
            <Dialog
                className="nw-dialog"
                header={this.state.headerName === true ? 'Update Download Corner Information' : 'Add Download Corner Information'}
                visible={this.state.visible}
                onHide={this.onHide}
                closable
            >
                <div className="p-fluid">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Serial No.</span>

                                        {dialogType === "update" ?
                                            <InputText
                                                placeholder="Enter Serial no."
                                                name="fileSerial"
                                                value={userObj.fileSerial}
                                                onChange={e => this.onChageInputValue(e, dialogType)}
                                            />
                                            :
                                            <div className="nw-inputgroup-desc">
                                                {userObj.fileSerial || this.state.dataTableValue.length + 1}
                                            </div>
                                        }

                                    </div>
                                    {dialogType == "update" ? <span className="error-message">{errors["fileSerial"]}</span> : ''}

                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Title <span>*</span></span>
                                        <InputText
                                            placeholder="Enter Title"
                                            name="fileTitle"
                                            value={userObj.fileTitle}
                                            onChange={e => this.onChageInputValue(e, dialogType)}

                                        />
                                    </div>
                                    <span className="error-message">{errors["fileTitle"]}</span>
                                </div>

                                {/* <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup nw-upload-button">
                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                            Upload File <span>*</span>
                                            <br />
                                            <span>(PNG/JPG/JPEG/DOC/PDF)</span>
                                        </span>

                                        <div className="nw-upload-button-inside">

                                            {
                                                this.state.imageInputFieldIsLoading ?
                                                    <div className="p-col-12">{this.NetiContentLoader.updateFileLoader()} </div> :
                                                    this.state.uploadFile.fileContent ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={this.removeUploadFile}
                                                                />

                                                                {
                                                                    this.state.uploadFile.fileName ?
                                                                        this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadFile.fileName)
                                                                        :
                                                                        this.CommonFuctionality.getFileContentTypeIcon(this.state.updateUserObj.fileName)
                                                                }
                                                            </div>
                                                            <div className="image-title">{this.state.uploadFile.fileName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            chooseLabel={this.state.uploadFile.fileName || uploadFileLabel}
                                                            accept="image/*, .pdf, .doc,.docx"
                                                            id="fileUpload"
                                                            mode="basic"
                                                            name="uploadFile"
                                                            onSelect={e => this.onLoadPic(e, dialogType, 2097152)}
                                                            auto={true}
                                                        />
                                            }

                                        </div>
                                    </div>

                                    <span className="error-message">{!this.state.uploadFile.fileName && errors["image"]}</span>
                                </div> */}

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup nw-upload-button">
                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                            Upload File <span>*</span>
                                            <br />
                                            <span>(PNG/JPG/JPEG/DOC/PDF)</span>
                                        </span>

                                        <div className="nw-upload-button-inside">

                                            {
                                                this.state.imageInputFieldIsLoading ?
                                                    <div className="p-col-12">{this.NetiContentLoader.updateFileLoader()} </div> :
                                                    this.state.uploadFile.fileContent ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={this.removeUploadFile}
                                                                />

                                                                {
                                                                    this.state.uploadFile.fileName ?
                                                                        this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadFile.fileName)
                                                                        :
                                                                        this.CommonFuctionality.getFileContentTypeIcon(this.state.updateUserObj.fileName)
                                                                }

                                                            </div>
                                                            <div className="image-title">{this.state.uploadFile.fileName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            chooseLabel={this.state.uploadFile.fileName || uploadFileLabel}
                                                            accept="image/*, .pdf, .doc,.docx"
                                                            id="fileUpload"
                                                            mode="advanced"
                                                            //maxFileSize="2000000"
                                                            maxFileSize="5000000"
                                                            name="uploadFile"
                                                            onSelect={e => this.onLoadPic(e, dialogType, 5000000)}
                                                            //onSelect={e => this.onLoadPic(e, dialogType, 2000000)}
                                                            auto={true}
                                                        />
                                            }

                                        </div>
                                    </div>
                                    <Messages ref={(el) => this.messages = el}></Messages>
                                    <span className="error-message">{!this.state.uploadFile.fileName && errors["image"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12" />

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                    </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-left"
                                        label={dialogType == "insert" ? "Save" : "Update"}
                                        icon="fas fa-check"
                                        onClick={() => this.onSubmitHandler(dialogType)}
                                    />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </Dialog>
        )
    }

    deleteDialogView = () => {
        return (
            <Dialog header="Delete Download Corner Info" visible={this.state.deleteDialogVisible} onHide={this.onHide} className="nw-confirm-dialog">
                <div className="p-fluid">
                    <div className="p-col-12 p-col-12">
                        <div className="confirm-wrapper">
                            {/* <div className="body-wrapper">
                                <header>Total messages you are about to send</header>
                                <h1>{selectedCustomers.length}</h1>
                                <footer>{selectedCustomers.length}  Person x {selectedCustomers.length} Message</footer>
                            </div> */}
                            <h1>Are You Sure?</h1>
                            <div className="nw-button-parent-multiple">
                                <Button
                                    className="p-button-danger nw-button nw-button-multiple"
                                    label="No"
                                    icon="fas fa-times"
                                    onClick={this.onHide}
                                />
                                <Button
                                    className="p-button-primary nw-button nw-button-multiple"
                                    label="Yes"
                                    icon="fas fa-check"
                                    onClick={e => this.deleteRow(this.state.deleteUserObj)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }

    onChageInputValue = (e, action) => {

        let { updateUserObj, insertUserObj } = this.state;
        if (action == 'insert') { insertUserObj[e.target.name] = e.target.value; }
        else if (action == 'update') { updateUserObj[e.target.name] = e.target.value; }

        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    handleError = (objectHandle, dialogType) => {

        let { errors } = this.state;
        let formIsValid = true;

        if (dialogType === 'update') {

            if (objectHandle.fileSerial === '' || objectHandle.fileSerial === null) {
                formIsValid = false;
                errors["fileSerial"] = "Serial No. can't left empty.";
            }
        }

        if (!objectHandle.fileTitle) {
            formIsValid = false;
            errors["fileTitle"] = "Title can't left empty.";
        }

        if (!this.state.uploadFile.fileName) {
            formIsValid = false;
            errors["image"] = "Upload file can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitHandler = (dialogType) => {

        let { insertUserObj, updateUserObj } = this.state;
        let userObj = {}
        if (dialogType == 'insert') {
            insertUserObj.cmsId = urlId;
            userObj = { ...insertUserObj }
            userObj.fileSerial = this.state.dataTableValue.length + 1;
        }
        else if (dialogType == 'update') {
            updateUserObj.cmsId = urlId;
            userObj = { ...updateUserObj }
        }
        // console.log( dialogType, insertUserObj, insertUserObj );

        if (this.handleError(userObj, dialogType)) {
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            this.DwsService.cmsInserAndUpdateDownloadCorner(dialogType, userObj)
                .then(res => {
                    return res.json().then((body) => {
                        // console.log('Body', body);
                        if (body.messageType === 1) {
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                            this.growl.show({ severity: 'success', summary: 'Success', detail: body.message });
                            this.removeFormData();
                            this.viewDownloadCornerList()
                            setTimeout(() => {
                                this.onHide()
                            }, 1000);
                        }
                        else {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    })
                }).catch(error => {
                    // errors["dropDownClassError"] = 'Connection Problem'
                    this.setState({ errors: errors })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                });
        }
    }

    deleteRow = (userObj) => {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.cmsDeleteDownloadCorner(userObj, urlId)
            .then(res => {
                return res.json().then((body) => {
                    // console.log('Body', body);
                    if (body.messageType === 1) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: body.message });

                        this.onHide()
                        setTimeout(() => {
                            this.viewDownloadCornerList()
                        }, 1000);
                    }
                    else {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                    }
                })
            }).catch(error => {
                // errors["dropDownClassError"] = 'Connection Problem'
                this.setState({ errors: errors })
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

            });
    }

    removeUploadFile = () => {
        let { uploadFile, insertUserObj, updateUserObj } = this.state

        uploadFile.fileContent = ''
        uploadFile.fileName = ''
        uploadFile.fileSaveOrEditable = false

        this.setState({ uploadFile, insertUserObj, updateUserObj })

        Object.assign(insertUserObj, uploadFile)
        Object.assign(updateUserObj, uploadFile)
    }

    onLoadPic = (e, dialogType, maxFileSize) => {

        let { errors } = this.state;

        let fileType = this.NetiFileHandler.getFileContentType(e.files[0].name);
        let supportedExtention = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/doc', 'application/docx'];

        if (supportedExtention.includes(fileType)) {

            if (this.NetiFileHandler.getMaxFileSizeIsValid(e.files[0].size, maxFileSize)) {

                errors["image"] = "";
                this.setState({ errors });

                var reader = new FileReader();
                let photo = e.files[0];
                const scope = this;
                reader.readAsDataURL(photo);
                reader.onload = () => {
                    let content = reader.result;
                    var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                    var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                    let album = {
                        // extention: photo.type,
                        fileContent: urlStr,
                        fileName: photo.name,
                        fileSaveOrEditable: true
                    };
                    scope.setState({ uploadFile: album });

                    if (dialogType == "insert") {
                        Object.assign(this.state.insertUserObj, album)
                    }
                    else {
                        Object.assign(this.state.updateUserObj, album)
                    }

                }

            }
            //  else {

            //     errors["image"] = "File size can't be more than " + maxFileSize / 1048576 + ' MB';
            //     this.setState({ errors });

            // }

        }
        else {
            errors["image"] = "File format not supported";
            this.setState({ errors });
        }
    }

    downloadFile = (rowData, funcType) => {

        let { uploadFile } = this.state;
        if (rowData.fileName) {
            this.setState({ imageInputFieldIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.DwsService.downloadCornerGetByteImage(rowData.fileId).then(res => {
                if (res.status == 302) {
                    return res.json().then(body => {
                        let contentType = this.NetiFileHandler.getImageContentType(rowData.fileName);

                        if (funcType == "download") {
                            let a = document.createElement("a");
                            a.href = contentType + body.file;
                            a.download = rowData.fileName;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                        else {
                            uploadFile.fileName = rowData.fileName
                            uploadFile.fileContent = body.file
                            uploadFile.fileSaveOrEditable = true
                            this.setState({ uploadFile })
                        }
                        this.setState({ imageInputFieldIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.setState({ imageInputFieldIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Unable to download file" })
                }
            });
        }
    }

    removeFormData = () => {
        this.setState({
            insertUserObj: {
                fileSerial: '',
                fileTitle: '',
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            }
        });
    }

    render() {

        let { insertUserObj, updateUserObj, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">
                Download Corner List
                                    </div>
            <div className="header-title-right">
                <a>
                    Total Found: {dataTableValue.length}
                </a>

                <Button
                    className="nw-button nw-button-center p-button-success"
                    name="district"
                    label="Add New"
                    icon="fas fa-plus"
                    iconPos="right"
                    onClick={() => this.showMainForm('insert', null)}
                />
            </div>
        </div>;

        return (

            <div className="p-fluid">
                <NetiCMSSetupProgress />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section blur-section">
                    {this.state.homeReturnButton === false ?

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column field="fileSerial" header="Serial No." filter={true} style={{ width: '100px' }} />
                                    <Column field="fileTitle" header="Title" filter={true} />
                                    <Column field="fileName" header="Download" filter={true} body={this.downloadBody} />
                                    <Column field="action" header="Action" body={this.actionBody} style={{ textAlign: 'center', width: '8em' }} />
                                </DataTable>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i className="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    {this.formDialog()}
                    {this.deleteDialogView()}
                </div>
            </div>
        )
    }
}