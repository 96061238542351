import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { SeatInfoList } from './SeatInfoList';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { Editor } from 'primereact/editor';
import { Calendar } from 'primereact/calendar';
import { MyCalendar } from './AdminEventInfoCalendar'
// import { Calendar, momentLocalizer } from 'react-big-calendar'
// import moment from 'moment'
 
// const localizer = momentLocalizer(moment)
 
// // const MyCalendar = props => (
// //   <div>
// //     <Calendar
// //       localizer={localizer}
// //       events={myEventsList}
// //       startAccessor="start"
// //       endAccessor="end"
// //       style={{ height: 500 }}
// //     />
// //   </div>
// // )


let dialogStatus = false;
let errors = {};
let dropDownListName1 = '';
let dropDownListName2 = '';
let cloneStateBeforeMount;
let urlId;
let eventTypeList = [
    { label: "Holiday", value: "Holiday"},
    { label: "General Holiday", value: "General Holiday"},
    { label: "Exam day", value: "Exam day"},
    { label: "Event", value: "Event"},
]
export class AdminEventInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataTableValue: [],
            btnDisabled: false,
            insertUserObj:{
                eventSerial: 0,
                eventType: '',
                eventTitle: '',
                eventEndDate: '',
                eventStartDate: '',
                eventDetails: '',
                cmsId: 0,
            },
            updateUserObj:{
                eventId: 0,
                eventSerial: 0,
                eventType: '',
                eventTitle: '',
                eventEndDate: '',
                eventStartDate: '',
                eventDetails: '',
                cmsId: 0,
            },
            uploadFile: {
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            errors: {},
            homeReturnButton: true,
            dataTableIsLoading: false,
            imageInputFieldIsLoading: false,
            // secondDropDownIsLoading: false,
            totalDataFound: 0,
            deleteDialogVisible: false,
            visibleViewDetails: false
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.NetiFileHandler = new NetiFileHandler();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {
        urlId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {
            let {insertUserObj, updateUserObj } = this.state
            insertUserObj.cmsId = urlId;
            updateUserObj.cmsId = urlId;
            this.setState({homeReturnButton: false, insertUserObj, updateUserObj })

            this.viewEventInfoList();
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    viewEventInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })

        this.DwsService.cmsEventInfoList(urlId)
            .then((res) => {
                return res.json().then((body) => {
                    // console.log('Body', body);
                    if(body.messageType === 1){
                        this.setState({ dataTableValue: body.item });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    }
                    else{
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                    }
                })
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    showMainForm = (dialogType, rowData) => {
        let { updateUserObj } = this.state

        if(dialogType == "update"){
            // this.downloadFile(rowData, "update")
            Object.assign(updateUserObj, rowData)
            updateUserObj.eventStartDateView = new Date(rowData.eventStartDate)
            updateUserObj.eventEndDateView = new Date(rowData.eventEndDate)
        }
        else{
            // this.removeUploadFile()
        }
        
        
        this.setState({ visible: true, dialogType: dialogType, errors: {}});
        this.CommonFuctionality.blurDialogBackgroundActive()
        // this.setState({ visible: true, dialogType: dialogType, updateUserObj: {...rowData} });
    }

    showDetailsDialog = (dialogType, rowData) => {
        let { updateUserObj } = this.state

        if(dialogType == "view"){
            // this.downloadFile(rowData, "update")
            Object.assign(updateUserObj, rowData)
            // updateUserObj.eventStartDateView = new Date(rowData.eventStartDate)
            // updateUserObj.eventEndDateView = new Date(rowData.eventEndDate)
        }
        else{
            // this.removeUploadFile()
        }
        
        
        this.setState({ visibleViewDetails: true, dialogType: dialogType, errors: {}});
        this.CommonFuctionality.blurDialogBackgroundActive()
        // this.setState({ visible: true, dialogType: dialogType, updateUserObj: {...rowData} });
    }

    showDeleteDialog = (dialogType, rowData) =>{
        this.setState({ deleteDialogVisible: true, deleteUserObj: rowData });
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    onHide = () =>{
        this.setState({ visible: false, deleteDialogVisible: false, visibleViewDetails: false, btnDisabled:false });
        this.CommonFuctionality.blurDialogBackgroundDeActive()
    }

    startDateBody = (rowData) =>{
        return <div>
            { new Date(rowData.eventStartDate).toLocaleString("sv-BD") }
        </div>
    }

    endDateBody = (rowData) =>{
        return <div>
            { new Date(rowData.eventEndDate).toLocaleString("sv-BD") }
        </div>
    }

    // downloadBody = (rowData) =>{
    //     return <div>
    //         <Button 
    //             // label={ rowData.fileName }
    //             icon="fas fa-download"
    //             onClick={ () => this.downloadFile(rowData, 'download')}
    //             className="nw-action-button info"
    //         /> {rowData.fileName}
                
    //     </div>
    // }

    actionBody = (rowData, column) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-eye"
                tooltip="View"
                className="nw-action-button"
                onClick={(e) => this.showDetailsDialog('view', rowData)}
            />

            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                onClick={(e) => this.showMainForm('update', rowData)}
                tooltip="Update"

            />

            <Button
                type="button"
                icon="fas fa-trash"
                tooltip="Delete"
                className="nw-action-button p-button-danger"
                onClick={(e) => this.showDeleteDialog('delete', rowData)}
            />

        </div>;
    }

    

    formDialog = () =>{
        let {insertUserObj, updateUserObj, dialogType, errors} = this.state
        let userObj = {...insertUserObj}
        if( dialogType == 'insert' ){
            userObj = {...insertUserObj}
        }
        else if( dialogType == 'update' ){
            userObj = {...updateUserObj}
        }

        let uploadFileLabel = <div>Click / Drag File Here To Upload <br/><span className="upload-file-label">(Max 1MB (recommended))</span></div>

        return (
            <Dialog
                className="nw-dialog"
                header={ (dialogType == 'insert' ?'Add' : 'Update') + " Event Information"}
                visible={this.state.visible}
                onHide={this.onHide}
                closable
            >
                <div className="p-fluid">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">

                                {
                                    dialogType == 'insert' ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>
                                                <div className="nw-inputgroup-desc">
                                                    {userObj.eventSerial || this.state.dataTableValue.length + 1}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No. <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Serial"
                                                    name="eventSerial"
                                                    value={userObj.eventSerial}
                                                    onChange={e => this.onChageInputValue(e, dialogType)}
                                                />
                                            </div>
                                            <span className="error-message">{errors["eventSerial"]}</span>
                                        </div>
                                }
                                

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Type <span>*</span></span>
                                        <Dropdown
                                            name="eventType"
                                            placeholder="Select Event type"
                                            filter={true}
                                            filterBy="label,value"
                                            showClear={true}
                                            autoWidth={false}
                                            options={eventTypeList}
                                            value={userObj.eventType}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["eventType"]}</span>

                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Title <span>*</span></span>
                                        <InputText
                                            placeholder="Enter Title"
                                            name="eventTitle"
                                            value={userObj.eventTitle}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["eventTitle"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                        <Calendar
                                            showIcon={true}
                                            placeholder="Select Date"
                                            yearRange="2010:2030"
                                            // dateFormat="yyyy-mm-dd hh-mm-s"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            // dateOnly="true"
                                            showButtonBar={true}
                                            name="eventStartDate"
                                            showTime={true}
                                            // hourFormat="12"
                                            // stepHour={2}
                                            value={userObj.eventStartDateView}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["eventStartDate"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                        <Calendar
                                            showIcon={true}
                                            placeholder="Select Date"
                                            yearRange="2010:2030"
                                            // dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            // dateOnly="true"
                                            showButtonBar={true}
                                            name="eventEndDate"
                                            showTime={true}
                                            // hourFormat="12"
                                            // stepHour={2}
                                            value={userObj.eventEndDateView}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["eventEndDate"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Description <span>*</span></span>
                                        <Editor
                                            className="nw-inputtexteditor"
                                            name="eventDetails"
                                            value={userObj.eventDetails}
                                            onTextChange={(e) => this.onChangeDescription(e, dialogType)}
                                        />
                                    </div>
                                    <span className='error-message'>{errors["eventDetails"]}</span>
                                </div>


                                <div className="p-col-12 p-xl-12" />

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                    </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-left"
                                        label={dialogType == "insert" ? "Save" : "Update"}
                                        icon="fas fa-check"
                                        onClick={ () => this.onSubmitHandler(dialogType) }
                                        disabled={this.state.btnDisabled}
                                    />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </Dialog>
        )
    }

    deleteDialogView = () =>{
        return (
            <Dialog 
                header="Delete Event Info" 
                visible={this.state.deleteDialogVisible} 
                onHide={this.onHide} 
                className="nw-confirm-dialog"
            >
                <div className="p-fluid">
                    <div className="p-col-12 p-col-12">
                        <div className="confirm-wrapper">
                            {/* <div className="body-wrapper">
                                <header>Total messages you are about to send</header>
                                <h1>{selectedCustomers.length}</h1>
                                <footer>{selectedCustomers.length}  Person x {selectedCustomers.length} Message</footer>
                            </div> */}
                            <h1>Are You Sure?</h1>
                            <div className="nw-button-parent-multiple">
                                <Button
                                    className="p-button-danger nw-button nw-button-multiple"
                                    label="No"
                                    icon="fas fa-times"
                                    onClick={this.onHide}
                                />
                                <Button
                                    className="p-button-primary nw-button nw-button-multiple"
                                    label="Yes"
                                    icon="fas fa-check"
                                    onClick={ e => this.deleteRow(this.state.deleteUserObj)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }

    viewDetails = () =>{
        let { updateUserObj } = this.state
        return (
            <Dialog 
                header="Event Info Details" 
                visible={this.state.visibleViewDetails}
                onHide={this.onHide} 
                className="nw-dialog"
            >
                <div className="p-grid">
                    <div className="p-col-12 p-col-12">
                        <div className="nw-search-view">
                            <div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Serial No</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {updateUserObj.eventSerial}
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Type</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {updateUserObj.eventType}
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Title</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {updateUserObj.eventTitle}
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Start Date</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {updateUserObj.eventStartDate}
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">End Date</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {updateUserObj.eventEndDate}
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Total Days</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {updateUserObj.totalDay}
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Details</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {/* {updateUserObj.eventDetails} */}
                                            <Editor
                                                value={updateUserObj.eventDetails}
                                                className="nw-inputtexteditor only-view"
                                                readOnly={true}
                                                headerTemplate={ e => {return null}}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }

    onChageInputValue = (e, action) =>{
        console.log(e.target);
        console.log('typeof e.target.value', typeof e.target.value);
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            if(typeof e.target.value == 'object'){
                insertUserObj[e.target.name] = (e.target.value).toLocaleString("sv-BD")
                insertUserObj[ e.target.name +'View' ] = e.target.value
            }
            else{
                insertUserObj[e.target.name] = e.target.value
            }
            
        }
        else if(action == 'update'){
            if(typeof e.target.value == 'object'){
                updateUserObj[e.target.name] = (e.target.value).toLocaleString("sv-BD")
                updateUserObj[e.target.name +'View'] = e.target.value
            }
            else{
                updateUserObj[e.target.name] = e.target.value
            }
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChangeDescription = (e, action) => {
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj["eventDetails"] = e.htmlValue
        }
        else if(action == 'update'){
            updateUserObj["eventDetails"] = e.htmlValue
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg("eventDetails");
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }


    handleError = (objectHandle) => {
        let { errors } = this.state;
        let formIsValid = true;

        if (!objectHandle.eventType) {
            formIsValid = false;
            errors["eventType"] = "Type can't left empty.";
        }

        if (!objectHandle.eventTitle) {
            formIsValid = false;
            errors["eventTitle"] = "Title can't left empty.";
        }

        if (!objectHandle.eventStartDate) {
            formIsValid = false;
            errors["eventStartDate"] = "Start Date can't left empty.";
        }

        if (!objectHandle.eventEndDate) {
            formIsValid = false;
            errors["eventEndDate"] = "End Date can't left empty.";
        }

        if (!objectHandle.eventDetails) {
            formIsValid = false;
            errors["eventDetails"] = "Details can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitHandler = (dialogType) => {
        let {insertUserObj, updateUserObj} = this.state

        let userObj={}
        if( dialogType == 'insert' ){
            userObj = {...insertUserObj}
            userObj.eventSerial = this.state.dataTableValue.length + 1;
        }
        else if( dialogType == 'update' ){
            userObj = {...updateUserObj}
        }
        // console.log( dialogType, insertUserObj, insertUserObj );

        if (this.handleError(userObj)) {
            this.setState({ btnDisabled: true, dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

            // userObj.eventSerial = this.state.dataTableValue.length + 1;

            this.DwsService.cmsInserAndUpdateEventInfo(dialogType, userObj)
                .then(res => {
                    return res.json().then((body) => {
                        // console.log('Body', body);
                        if(body.messageType === 1){
                            this.setState({ topProgressBar: false, errorMsgVisible: false, btnDisabled: true  });
                            this.growl.show({ severity: 'success', summary: 'Success', detail: body.message });
                            this.removeFormData();
                            this.onHide()
                            setTimeout(() => {
                                this.viewEventInfoList()
                            }, 1000);
                        }
                        else{
                            this.setState({ btnDisabled: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    })
                }).catch(error => {
                    // errors["dropDownClassError"] = 'Connection Problem'
                    this.setState({ errors: errors })
                    this.setState({ dataTableIsLoading: false,btnDisabled: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                });
        }
    }

    removeFormData = () => {
        this.setState({
            insertUserObj:{
                eventSerial: 0,
                eventType: '',
                eventTitle: '',
                eventEndDate: '',
                eventStartDate: '',
                eventDetails: '',
                cmsId: urlId,
            },
            updateUserObj:{
                eventId: 0,
                eventSerial: 0,
                eventType: '',
                eventTitle: '',
                eventEndDate: '',
                eventStartDate: '',
                eventDetails: '',
                cmsId: urlId,
            },
        });
    }

    deleteRow = (userObj) =>{
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.cmsDeleteEventInfo( userObj, urlId )
                .then(res => {
                    return res.json().then((body) => {
                        // console.log('Body', body);
                        if(body.messageType === 1){
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                            this.growl.show({ severity: 'success', summary: 'Success', detail: body.message });
                            
                            this.onHide()
                            setTimeout(() => {
                                this.viewEventInfoList()
                            }, 1000);
                        }
                        else{
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    })
                }).catch(error => {
                    // errors["dropDownClassError"] = 'Connection Problem'
                    this.setState({ errors: errors })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                });
    }

    render() {
        let { insertUserObj, updateUserObj, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                                Event Info List
                                    </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length}
                                </a>

                                <Button
                                    className="nw-button nw-button-center p-button-success"
                                    label="Add New"
                                    icon="fas fa-plus"
                                    iconPos="right"
                                    onClick={ () => this.showMainForm('insert', null)}
                                />
                            </div>
                        </div>;

        const events = [
            {
                start: '2015-07-20',
                end: '2015-07-02',
                eventClasses: 'optionalEvent',
                title: 'test event',
                description: 'This is a test description of an event',
            },
            {
                start: '2015-07-19',
                end: '2015-07-25',
                title: 'test event',
                description: 'This is a test description of an event',
                data: 'you can add what ever random data you may want to use later',
            },
        ];

        return (

            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section">
                    {this.state.homeReturnButton === false ?
                        
                        <React.Fragment>
                            <div className="p-grid p-col-12 event-count blur-section">
                                {
                                    eventTypeList.map((item, index) =>
                                        <div className="p-col-12 p-xl-3 ">
                                            <div className={'event-' + index}>
                                                { item.value } -  <strong>{ dataTableValue.filter(tableItem => tableItem.eventType === item.value).length }</strong>
                                            </div>
                                        </div>
                                    )
                                }
                                
                            </div>
                            <div className="nw-data-table blur-section">
                                {!this.state.dataTableIsLoading ?
                                    <DataTable
                                        value={dataTableValue}
                                        selectionMode="single"
                                        header={tableHeader}
                                        responsive={true}
                                        rows={10}
                                        paginator={true}
                                    >
                                        <Column field="eventSerial" header="Serial No." filter={true} style={{ width: '100px' }} />
                                        <Column field="eventType" header="Event Type" filter={true} />
                                        <Column field="eventTitle" header="Event Title" filter={true} />
                                        <Column field="eventStartDate" header="Start Date" filter={true} body={this.startDateBody} />
                                        <Column field="eventEndDate" header="End Date" filter={true} body={this.endDateBody} />
                                        <Column field="totalDay" header="Total Day" filter={true} />
                                        <Column field="status" header="Status" filter={true}/>
                                        <Column field="action" header="Action" body={this.actionBody} style={{ textAlign: 'center', width: '10em' }} />
                                    </DataTable>
                                    : this.NetiContentLoader.MyPointTableLoader()
                                }
                            </div>

                            <div className="p-col-12 blur-section"/>

                            <div className="p-col-12">
                                <MyCalendar
                                    // localizer={localizer}
                                    events={dataTableValue}
                                    // startAccessor="start"
                                    // endAccessor="end"
                                    // style={{ height: 500 }}
                                />

                            </div>
                        </React.Fragment>
                        

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i className="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    { this.formDialog() }
                    { this.deleteDialogView() }
                    { this.viewDetails() }
                </div>
            </div>
        )
    }
}