import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { Link } from 'react-router-dom';
import NetiContentLoader from '../../common/NetiContentLoader';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Messages } from 'primereact/messages';

let urlId;
let errors = {}
export class CoreConfigView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            admisiaCoreConfigInfo: { ...props.admisiaCoreConfigInfo },
            updateUserObj: {
                coreConfigId: 0,
                currentAdmissionYear: '',
                circularTitle: '',
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false,
                cmsId: 0,
            },
            uploadFile: {
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            errors: {},
            dataTableIsLoading: false,
            dataIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            checkEligibility: false,
            errorMsgBody: '',
        };

        this.DwsService = new DwsService();
        this.CommonFuctionality = new CommonFuctionality();
        this.NetiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();
    }

    async reloadCoreUrlIdFromLocal() {
        urlId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {
            let { updateUserObj } = this.state
            updateUserObj.cmsId = urlId;
            this.setState({ homeReturnButton: false, updateUserObj })

            // this.props.getAdmisiaCoreConfigInfo();
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    onChangeAcademicYear = (e) => {
        let { updateUserObj, errors } = this.state;
        updateUserObj.currentAdmissionYear = e.target.value;
        this.setState({ updateUserObj, errors });
        this.onClearErrorMsg(e.target.name);

    }

    onChangeCircularTitle = (e) => {
        let { updateUserObj } = this.state;
        updateUserObj.circularTitle = e.target.value;
        this.setState({ updateUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    onChangeEditHandler = (e) => {
        let { admisiaCoreConfigInfo, uploadFile } = this.state;
        errors = {};
        uploadFile.fileName = admisiaCoreConfigInfo.fileName
        uploadFile.fileContent = admisiaCoreConfigInfo.fileContent
        this.setState({ visible: true, updateUserObj: { ...admisiaCoreConfigInfo } });
        this.CommonFuctionality.blurDialogBackgroundActive()
        // this.viewDialog(e);
    }

    onHide = () => {
        this.setState({ visible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive()
    }

    onLoadPic = (e, maxFileSize) => {
        let { errors } = this.state;

        let fileType = this.NetiFileHandler.getFileContentType(e.files[0].name);
        let supportedExtention = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/doc', 'application/docx'];
        // console.log('loadPic', e.files[0]);

        if (supportedExtention.includes(fileType)) {

            if (this.NetiFileHandler.getMaxFileSizeIsValid(e.files[0].size, maxFileSize)) {

                errors["fileContent"] = "";
                this.setState({ errors });

                var reader = new FileReader();
                let photo = e.files[0];
                const scope = this;
                reader.readAsDataURL(photo);
                reader.onload = () => {
                    let content = reader.result;
                    var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                    var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                    let album = {
                        // extention: photo.type,
                        fileContent: urlStr,
                        fileName: photo.name,
                        fileSaveOrEditable: true
                    };
                    scope.setState({ uploadFile: album });

                    Object.assign(this.state.updateUserObj, album);
                }

            }
        }
        else {
            errors["fileContent"] = "File format not supported";
            this.setState({ errors });
        }
    }

    removeUploadFile = () => {
        let { uploadFile, updateUserObj } = this.state;
        uploadFile.fileContent = ''
        uploadFile.fileName = ''
        // uploadFile.fileSaveOrEditable = false

        // noticeInfoObj.fileContent = ''
        // noticeInfoObj.fileName = ''
        // uploadFile.fileSaveOrEditable = false

        this.setState({ uploadFile, updateUserObj });
        Object.assign(updateUserObj, uploadFile);
    }

    getDownloadFile = (fileName, fileContent) => {
        let contentType = this.NetiFileHandler.getImageContentType(fileName);
        let a = document.createElement("a");
        a.href = contentType + fileContent;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    handleError = (objectHandle) => {
        let { errors, uploadFile } = this.state;
        let formIsValid = true;

        if (!objectHandle.currentAdmissionYear) {
            formIsValid = false;
            errors["currentAdmissionYear"] = "Year can't left empty.";
        }

        if (!objectHandle.circularTitle) {
            formIsValid = false;
            errors["circularTitle"] = "Title can't left empty.";
        }

        if (!uploadFile.fileContent) {
            formIsValid = false;
            errors["fileContent"] = "Upload file can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onUpdateHandler = () => {
        let { updateUserObj } = this.state;
        // console.log("updateUserObj", updateUserObj);
        if (this.handleError(updateUserObj)) {
            this.setState({ topProgressBar: true });
            this.DwsService.updateAdmisiaCoreConfig(updateUserObj)
                .then(res => { return res.json() }).then(body => {
                    this.setState({ topProgressBar: false });
                    if (body.messageType == 1) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated!" });
                        setTimeout(() => {
                            this.props.getAdmisiaCoreConfigInfo();
                            this.onHide()
                            // this.setState({ checkEligibility: true })
                        }, 1000);
                    }
                    else {
                        this.setState({ errorMsgVisible: true, errorMsgBody: body.message })
                    }
                }).catch(error => {
                    console.log(error);
                    // this.growl.show({severity: 'warn', summary: 'Warning', detail: 'Connection Problem'});
                });
        }
    }

    render() {
        let { admisiaCoreConfigInfo, updateUserObj, uploadFile, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">(Max 2 MB (recommended))</span></div>

        let date = new Date();
        let year = date.getFullYear();
        let academicYear = [
            { label: year, value: year },
            { label: year + 1, value: year + 1 },
        ];

        return (
            <div className="p-fluid">
                {this.state.topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {this.state.errorMsgVisible ?
                    <ErrorMessageView errorMsgBody={this.state.errorMsgBody} />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="">
                    <div className="p-grid nw-form blur-section">

                        {this.state.homeReturnButton === false ?
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.dataIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Admission Year
                                                    </span>
                                                <div className="nw-inputgroup-desc">
                                                    {admisiaCoreConfigInfo.currentAdmissionYear}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.dataIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                    Circular Title
                                                </span>
                                                <div className="nw-inputgroup-desc">
                                                    {admisiaCoreConfigInfo.circularTitle}
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.dataIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Circular <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/DOC/PDF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">
                                                    <div className="image-view-main">
                                                        <div className="upload-image-view">
                                                            {/* <img
                                                                src={"data:image/*;base64," + admisiaCoreConfigInfo.fileContent}
                                                                alt="Institute Logo"
                                                                style={{ height: "80px" }}
                                                            /> */}
                                                            {
                                                                admisiaCoreConfigInfo.fileName ?
                                                                    <>
                                                                        {this.CommonFuctionality.getFileContentTypeIcon(admisiaCoreConfigInfo.fileName)}

                                                                    </>
                                                                    :
                                                                    this.CommonFuctionality.getFileContentTypeIcon(updateUserObj.fileName)
                                                            }
                                                            <div class="download-file-button">
                                                                <Button
                                                                    icon="pi pi-download"
                                                                    className="nw-action-button edit bg-secondary"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={e => this.getDownloadFile(admisiaCoreConfigInfo.fileName, admisiaCoreConfigInfo.fileContent)}
                                                                >
                                                                </Button>
                                                            </div>

                                                        </div>
                                                        <div className="image-title">{admisiaCoreConfigInfo.fileName}</div>
                                                    </div>
                                                    {/* <a style={{ cursor: 'pointer' }}
                                                        onClick={e => this.getDownloadFile(admisiaCoreConfigInfo.fileName, admisiaCoreConfigInfo.fileContent)}
                                                    >
                                                        Click for download file
                                                    </a> */}

                                                </div>

                                            </div>
                                        }

                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                        <Button
                                            className="p-button-primary nw-button nw-button-right"
                                            label="Edit"
                                            icon="fas fa-check"
                                            onClick={this.onChangeEditHandler}
                                        />

                                    </div>
                                </div>
                            </div>

                            :
                            <div className="">
                                <div className="p-grid">
                                    <div className="card p-col-12 p-xl-3">
                                        <center className="custom-center">
                                            <Link
                                                to="/institute_config"
                                                className="rainbow-button"
                                                alt="Go Admisia Home"
                                            />
                                        </center>
                                    </div>
                                    <div className="card p-col-12 p-xl-3">
                                        <center className="custom-center">
                                            <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                        </center>
                                    </div>
                                    <div className="card p-col-12 p-xl-6">
                                        <img src="assets/images/dws_home.png" width="100%" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="dialog-section">
                        <Dialog
                            className="nw-dialog"
                            header="Core Config (Update)"
                            visible={this.state.visible}
                            onHide={this.onHide}
                            closable
                        >
                            <div className="p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-grid nw-form">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="nw-form-body">

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">
                                                                Academic Year <span>*</span>
                                                            </span>
                                                            <Dropdown
                                                                placeholder="Select Academic Year"
                                                                value={updateUserObj.currentAdmissionYear}
                                                                options={academicYear}
                                                                onChange={this.onChangeAcademicYear}
                                                                showClear={true}
                                                                autoWidth={false}
                                                                name="currentAdmissionYear"
                                                                id="currentAdmissionYear"
                                                            />
                                                        </div>
                                                        <span className="error-message">{errors['currentAdmissionYear']}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">
                                                                Circular Title <span>*</span>
                                                            </span>
                                                            <InputText
                                                                value={updateUserObj.circularTitle}
                                                                id="circularTitle"
                                                                placeholder="Enter Circular Title"
                                                                style={{ width: "100%" }}
                                                                type="text"
                                                                name="circularTitle"
                                                                onChange={this.onChangeCircularTitle}
                                                            />
                                                        </div>
                                                        <span className="error-message">{errors['circularTitle']}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup nw-upload-button">
                                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                                Upload File <span>*</span>
                                                                <br />
                                                                <span>(PNG/JPG/JPEG/DOC/PDF)</span>
                                                            </span>

                                                            <div className="nw-upload-button-inside">

                                                                {
                                                                    this.state.imageInputFieldIsLoading ?
                                                                        <div className="p-col-12">{this.NetiContentLoader.updateFileLoader()} </div> :
                                                                        uploadFile.fileContent ?
                                                                            <div className="image-view-main">
                                                                                <div className="upload-image-view">
                                                                                    <Button
                                                                                        className="delete-upload-button"
                                                                                        icon="fas fa-times-circle"
                                                                                        onClick={this.removeUploadFile}
                                                                                    />

                                                                                    {
                                                                                        uploadFile.fileName ?
                                                                                            this.CommonFuctionality.getFileContentTypeIcon(uploadFile.fileName)
                                                                                            :
                                                                                            this.CommonFuctionality.getFileContentTypeIcon(updateUserObj.fileName)
                                                                                    }

                                                                                </div>
                                                                                <div className="image-title">{uploadFile.fileName}</div>
                                                                            </div>

                                                                            :
                                                                            <FileUpload
                                                                                chooseLabel={uploadFile.fileName || uploadFileLabel}
                                                                                accept="image/*, .pdf, .doc,.docx"
                                                                                id="fileUpload"
                                                                                mode="advanced"
                                                                                maxFileSize="2000000"
                                                                                name="fileContent"
                                                                                onSelect={e => this.onLoadPic(e, 2000000)}
                                                                                auto={true}
                                                                            />
                                                                }

                                                            </div>
                                                        </div>
                                                        <span className='error-message'><Messages ref={(el) => this.messages = el}></Messages></span>
                                                        <span className='error-message'>{uploadFile.fileName ? null : errors.fileContent}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">

                                                        <Button
                                                            className="p-button-danger nw-button nw-button-multiple"
                                                            label="Discard"
                                                            icon="fas fa-times"
                                                            onClick={e => this.onHide()}
                                                        />

                                                        <Button
                                                            className="p-button-primary nw-button nw-button-multiple"
                                                            label="Update"
                                                            icon="fas fa-check"
                                                            onClick={this.onUpdateHandler}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>

        );
    }
}
