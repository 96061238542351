import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import { Dialog } from 'primereact/dialog';
import NetiContentLoader from '../../common/NetiContentLoader';
import { AdmisiaApplicantViewInfo } from '../AdmisiaApplicantViewInfo';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import Workbook from "react-excel-workbook";
import XLSX from 'xlsx';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';


let urlId;
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
let classAndGroupListOptions = [];
let classAndGroupName = '';
let classAndGroupStatus="";

export class AdmisiaApplicantStatus extends Component {
    constructor() {
        super();
        this.state = {
            applicantPicture: '',
            errors: {},
            applicantStatusSearchErr: {},
            applicantFormVisibility: false,
            applicantStatusList: {},
            applicantSearchObj: {},
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            classAndGroupList: '',
            classAndGroupListValue: '',
            applicantStatusValue: '',
            errorMsgVisible: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableValueView: false,
            dataTableValue: [],
            classAndGroupPdf:"",
            statusPdf:"",
            applicantInfo: {
                admisiaApplicantInfoDTO: {
                    applicationDate: '',
                    admisiaClassConfigDTO: {
                        admissionTestStatus: ''
                    },
                    addressCoreCategoryInfoDTO: {
                        coreCategoryID: ''
                    },
                    dateOfBirth: '',
                    motherOccupationCategoryInfoDTO: {
                        categoryName: ''
                    },
                    fatherOccupationCategoryInfoDTO: {
                        categoryName: ''
                    },
                    religionCoreCategoryInfoDTO: {
                        categoryName: ''
                    },
                    genderCoreCategoryInfoDTO: {
                        categoryName: ''
                    },
                    admisiaClassConfigDTO: {
                        classCoreCategoryInfoDTO: {
                            categoryName: ''
                        },
                        groupCoreCategoryInfoDTO: {
                            categoryName: ''
                        }
                    },
                    admisiaCoreConfigDTO: {
                        coreUrlInfoDTO: {
                            urlInfoID: ''
                        }
                    },
                },
            },
        };

        this.admisiaPointService = new AdmisiaPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.getProfileImg = this.getProfileImg.bind(this);
        this.reloadCoreUrlIdFromLocal();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 1000);
        }
    }

    componentWillMount() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (urlId) {
            this.viewApplicantStatusList();
            this.getFetchClassAndGroupList(urlId);
        }
        else {
            this.setState({ errorMsgVisible: true, errorMsgBody: "Please reload page again..." })
        }
    }

    getFetchClassAndGroupList(urlId) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.admisiaPointService.getClassAndGroupForApproval(urlId)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {


                        this.setState({ classAndGroupList: body });
                        this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                    });
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })

            });
    }


    viewApplicantStatusList() {
        let { errors } = this.state;
        this.setState({ secondDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.admisiaPointService.getApplicantStatusList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {


                        this.setState({ applicantStatusList: body });
                        this.setState({ secondDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ secondDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    statusBodyTemplete = (rowData) => {
        return <center><Button
            className="nw-action-button info"
            icon="far fa-eye"
            title="Show Applicant Details"
            onClick={(e) => this.viewDialog(rowData)}
        />
        </center>

    }

    reloadDataTableValue = () => {
        this.refs.admisiaApplicantViewInfo.getExamInfoList();

    }

    viewDialog = (rowData) => {
        this.setState({ visible: true });
        this.admisiaPointService.getApplicantInfoByID(rowData.admisiaApplicantInfoDTO.admisiaApplicantInfoId)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ applicantInfo: body });
                        this.getProfileImg();
                        this.reloadDataTableValue();
                    });
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log(error);

            });

    }


    onHide = (event) => {
        this.setState({ visible: false });
    }

    onChangeClassAndGroupList = (e) => {
        let classAndGroupValue = e.target.value;
        let classGroupName;
        classAndGroupListOptions.find(function(classAndGroupListOptions){
           if(classAndGroupListOptions.value == classAndGroupValue){
                classGroupName = classAndGroupListOptions.label
           }
        });
        this.setState({ classAndGroupListValue: classAndGroupValue, classAndGroupPdf: classGroupName});

    }

    onChangeApplicantStatusOptions = (e) => {
        classAndGroupStatus = e.target.value;
        this.setState({ applicantStatusValue: classAndGroupStatus});
        let applicantStatusOptionsSecond =[]
        let classGroupStatus;
        if (this.state.applicantStatusList) {
            let classGroupStatusArr;
            classGroupStatusArr = Object.entries(this.state.applicantStatusList);
            for (const key of classGroupStatusArr) {
                applicantStatusOptionsSecond.push({
                    value: key[1],
                    label: key[0],
                });
            }

            console.log('applicantStatusOptionsSecond', applicantStatusOptionsSecond);
            

            applicantStatusOptionsSecond.find(function(applicantStatusOptionsSecond){
                if(applicantStatusOptionsSecond.value == classAndGroupStatus){
                    classGroupStatus = applicantStatusOptionsSecond.label
                    console.log('this is demo');
                    
                }
             });

             this.setState({ statusPdf: classGroupStatus});

        }

        
        
        

    }


    getProfileImg() {
        if (this.state.applicantInfo.admisiaApplicantInfoDTO.photoPath !== null) {
            this.netiFileHandler.getByteImage(this.state.applicantInfo.admisiaApplicantInfoDTO.photoPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(this.state.applicantInfo.admisiaApplicantInfoDTO.photoName);
                                this.setState({ applicantPicture: contentType + body.fileContent });

                            })
                    } else {
                        return res.json()
                            .then((body) => {
                                console.log("image error", body);
                            })
                    }
                });
        }

    }


    onSearchHandler = () => {
        let { applicantSearchObj } = this.state;
        applicantSearchObj.urlInfoID = parseInt(urlId);
        applicantSearchObj.classConfigID = this.state.classAndGroupListValue;
        applicantSearchObj.status = this.state.applicantStatusValue;
        applicantSearchObj.pageNo = 0;
        applicantSearchObj.limit = 10;
        this.setState({ applicantSearchObj });
        if (this.onSearchError()) {
            this.setState({ topProgressBar: true, dataTableValueView: true, dataTableIsLoading: true, errorMsgVisible: false });

            if(applicantSearchObj.status === 0 || applicantSearchObj.status === 2) {
                this.admisiaPointService.getApplicantAssessmentListByStatus(applicantSearchObj)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            console.log("assessment Body", body);

                            for (let i = 0; i < body.length; i++) {
                                if (body[i].admisiaApplicantInfoDTO.applicationDate == null) {
                                    body[i].admisiaApplicantInfoDTO.applicationDate = '';
                                } else {
                                    body[i].admisiaApplicantInfoDTO.applicationDate = NetiDateUtils.getAnyShortForm(body[i].admisiaApplicantInfoDTO.applicationDate, 'DD-MMM-YYYY');
                                }
                            }

                            for (let i = 0; i < body.length; i++) {
                                if (body[i].admisiaApplicantInfoDTO.lastDateExecuted == null) {
                                    body[i].admisiaApplicantInfoDTO.lastDateExecuted = '';
                                } else {
                                    body[i].admisiaApplicantInfoDTO.lastDateExecuted = NetiDateUtils.getAnyShortForm(body[i].admisiaApplicantInfoDTO.lastDateExecuted, 'DD-MMM-YYYY');
                                }
                            }


                            this.setState({ dataTableValue: body });
                            this.setState({ dataTableValueView: true, dataTableIsLoading: false, topProgressBar: false });
                        });
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ dataTableValueView: false, errorMsgVisible: true, dataTableIsLoading: false, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ dataTableValueView: false, dataTableIsLoading: false, errorMsgVisible: false, errorMsgBody: 'Unable to load data. Please check connection' })

                });

            } else {
                this.admisiaPointService.getApplicantListByStatus(applicantSearchObj)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            console.log("admission Body", body);

                            for (let i = 0; i < body.length; i++) {
                                if (body[i].admisiaApplicantInfoDTO.applicationDate == null) {
                                    body[i].admisiaApplicantInfoDTO.applicationDate = '';
                                } else {
                                    body[i].admisiaApplicantInfoDTO.applicationDate = NetiDateUtils.getAnyShortForm(body[i].admisiaApplicantInfoDTO.applicationDate, 'DD-MMM-YYYY');
                                }
                            }

                            for (let i = 0; i < body.length; i++) {
                                if (body[i].admisiaApplicantInfoDTO.lastDateExecuted == null) {
                                    body[i].admisiaApplicantInfoDTO.lastDateExecuted = '';
                                } else {
                                    body[i].admisiaApplicantInfoDTO.lastDateExecuted = NetiDateUtils.getAnyShortForm(body[i].admisiaApplicantInfoDTO.lastDateExecuted, 'DD-MMM-YYYY');
                                }
                            }
                            
                            body.map(item => {
                                let registrationID = String(item.admisiaApplicantInfoDTO.registrationID);
                                let applicantName = String(item.admisiaApplicantInfoDTO.applicantName);
                                let categoryName = String(item.admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName);
                                let mobileNo = String(item.admisiaApplicantInfoDTO.mobileNo);
                                let applicationDate = String(item.admisiaApplicantInfoDTO.applicationDate);
                                let lastDateExecuted = String(item.admisiaApplicantInfoDTO.lastDateExecuted);
                                let requestStatus = String(item.admisiaApplicantInfoDTO.requestStatus);
                                
                                item.admisiaApplicantInfoDTO.registrationID = item.admisiaApplicantInfoDTO.registrationID;
                                item.admisiaApplicantInfoDTO.applicantName = item.admisiaApplicantInfoDTO.applicantName;
                                item.admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName = item.admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName;
                                item.admisiaApplicantInfoDTO.mobileNo = item.admisiaApplicantInfoDTO.mobileNo;
                                item.admisiaApplicantInfoDTO.applicationDate = item.admisiaApplicantInfoDTO.applicationDate;
                                item.admisiaApplicantInfoDTO.lastDateExecuted = item.admisiaApplicantInfoDTO.lastDateExecuted;
                                item.admisiaApplicantInfoDTO.requestStatus = item.admisiaApplicantInfoDTO.requestStatus;

                                Object.assign(item, {registrationID: registrationID, applicantName: applicantName, categoryName: categoryName, mobileNo: mobileNo, applicationDate:applicationDate, lastDateExecuted:lastDateExecuted, requestStatus: requestStatus});   // for pdf column
                            });

                            this.setState({ dataTableValue: body });
                            this.setState({ dataTableValueView: true, dataTableIsLoading: false, topProgressBar: false });
                        });
                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ dataTableValueView: false, errorMsgVisible: true, dataTableIsLoading: false, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ dataTableValueView: false, dataTableIsLoading: false, errorMsgVisible: false, errorMsgBody: 'Unable to load data. Please check connection' })

                });

            }

          
        }
    }




    onSearchError = () => {
        let { applicantStatusSearchErr } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (this.state.classAndGroupListValue === '') {
            formIsValid = false;
            applicantStatusSearchErr['classAndgroup'] = 'Class And Group can not left empty.';
        }

        if (this.state.applicantStatusValue === '') {
            formIsValid = false;
            applicantStatusSearchErr['status'] = 'Status can not left empty.';
        }

        this.setState({ applicantStatusSearchErr });
        return formIsValid;
    }

    ProcessExcel(data) {

        let workbook = XLSX.read(data, { type: 'binary' });
        const firstSheet = workbook.SheetNames[0];
        let excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
        this.setExcelRowsIntoDataTable(excelRows);

    }


    setExcelRowsIntoDataTable(excelRows) {

        let { dataTableValue } = this.state;

        let newDataList = [];
        for (const i in excelRows) {

            let dataTableObject = {};
            const rowValue = excelRows[i];

            for (let index = i; index <= i; index++) {
                const mainRowData = dataTableValue[index];

                dataTableObject = {

                    'admisiaApplicantInfoDTO': {
                        'admisiaApplicantInfoId': mainRowData.admisiaApplicantInfoDTO.admisiaApplicantInfoId,
                        'registrationID': rowValue.Registration_No,
                        'applicantName': rowValue.Name,
                        'genderCoreCategoryInfoDTO': {
                            'categoryName': rowValue.Gender,
                        },
                        'mobileNo': rowValue.Contact_No,
                        'applicationDate': rowValue.Application_Date,
                        'lastDateExecuted': rowValue.Approve_Date


                    },

                }
            }
            newDataList.push(dataTableObject);
        }
        this.setState({ dataTableValue: newDataList });
    }



    exportPdf = () => {
    
        
        let tableColumns = [
          { title: "Registration No", dataKey: "registrationID" },
          { title: "Name", dataKey: "applicantName" },
          { title: "Gender", dataKey: "categoryName" },
          { title: "Contact", dataKey: "mobileNo" },
          { title: "Application Date", dataKey: "applicationDate" },
          { title: "Approve Date", dataKey: "lastDateExecuted" },
        ];

        pdfDownloadPortrait_TableWithSubTitle("Assessment Approved Report",pdfTitleHeader, pdfTitleHeaderData, tableColumns, this.state.dataTableValue, "applicant_status_report.pdf");
    
    }






    // applicationDateFormat = (rowData) => {
    //     return <span>{NetiDateUtils.getAnyShortForm(rowData['applicationDate'], 'DD-MMM-YYYY')}</span>;
    // }

    // approveDateFormat = (rowData) => {
    //     return <span>{NetiDateUtils.getAnyShortForm(rowData['lastDateExecuted'], 'DD-MMM-YYYY')}</span>;
    // }

    // export = () => { 


    //     this.dt.exportCSV();

    // }

    render() {


        let { topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Assessment Approved List
                        <span
                style={{ color: "white", display: "flex", float: "right" }}
            >
                Total Found: {this.state.dataTableValue.length}
                        </span>
        </div>;

        
        if (this.state.classAndGroupList && this.state.classAndGroupList.length) {
            classAndGroupListOptions = this.state.classAndGroupList.map((item) => ({
                value: item.classConfigID,
                label: item.className + " (" + item.group + ")"
            }));
        }

        let applicantStatusOptions= [];
        if (this.state.applicantStatusList) {
            let applicantStatusArr;
            applicantStatusArr = Object.entries(this.state.applicantStatusList);
            for (const key of applicantStatusArr) {
                applicantStatusOptions.push({
                    value: key[1],
                    label: key[0],
                });
            }
        }

        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        pdfTitleHeaderData = [
        { A: "Class & Group", B: this.state.classAndGroupPdf, C: "Status", D: this.state.statusPdf}
        ];

        console.log('classAndGroupName', classAndGroupName);
        


        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    <Growl ref={(el) => this.growl = el} />


                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Class & Group</span>
                                        <Dropdown
                                            placeholder="Select Class and Group Name"
                                            filter={true}
                                            filterBy="value"
                                            value={this.state.classAndGroupListValue}
                                            options={classAndGroupListOptions}
                                            onChange={this.onChangeClassAndGroupList}
                                            showClear={false}
                                            autoWidth={false}
                                            name="classAndgroup"
                                        />
                                    </div>
                                }
                                <span className="error-message">{this.state.applicantStatusSearchErr["classAndgroup"]}</span>
                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-5">
                                {this.state.secondDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Status</span>
                                        <Dropdown
                                            placeholder="Select Status"
                                            filter={true}
                                            options={applicantStatusOptions}
                                            value={this.state.applicantStatusValue}
                                            onChange={this.onChangeApplicantStatusOptions}
                                            showClear={false}
                                            autoWidth={false}
                                            name='status'
                                        />
                                    </div>
                                }
                                <span className="error-message">{this.state.applicantStatusSearchErr["status"]}</span>
                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onSearchHandler}
                                />
                            </div>
                        </div>
                    </div>


                    {this.state.dataTableValueView == true ?
                        <div className="p-col-12 p-xl-12">

                            <div className="nw-data-table">
                                {this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() :
                                    <div>
                                        <DataTable
                                            value={this.state.dataTableValue}
                                            selectionMode="single"
                                            activeIndex="0"
                                            header={tableHeader}
                                            selection={this.state.dataTableSelection}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                        // ref={(el) => { console.log(el); this.dt = el; }}
                                        >
                                            <Column field="admisiaApplicantInfoDTO.registrationID" header="Registration No" filter={true} />
                                            <Column field="admisiaApplicantInfoDTO.applicantName" header="Name" filter={true} />
                                            <Column field="admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName" header="Gender" filter={true} />
                                            <Column field="admisiaApplicantInfoDTO.mobileNo" header="Contact No" filter={true} />
                                            <Column field="admisiaApplicantInfoDTO.applicationDate" header="Application Date" filter={true} />
                                            <Column field="admisiaApplicantInfoDTO.lastDateExecuted" header="Approve Date" filter={true} />
                                            <Column field="admisiaApplicantInfoDTO.requestStatus" header="Action" body={this.statusBodyTemplete} />
                                        </DataTable>
                                        <div className="nw-button-parent-multiple m-t-8 p-r-0">
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-multiple"
                                                label="Download PDF"
                                                icon="fas fa-download"
                                                onClick={this.exportPdf}
                                            />
                                             <Workbook filename={"AssessmentApprovedList.xlsx"}
                                        element=
                                        {
                                            <div className="nw-button-parent">
                                                <Button
                                                    label="Download CSV"
                                                    icon="fas fa-download"
                                                    className="p-button p-button-primary nw-button nw-button-multiple"

                                                />
                                            </div>

                                        }
                                    >
                                        <Workbook.Sheet data={this.state.dataTableValue} name="Assessment Approved">
                                            <Workbook.Column value={row => row.admisiaApplicantInfoDTO.registrationID} label="Registration_No" />
                                            <Workbook.Column value={row => row.admisiaApplicantInfoDTO.applicantName} label="Name" />
                                            <Workbook.Column value={row => row.admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName} label="Gender" />
                                            <Workbook.Column value={row => row.admisiaApplicantInfoDTO.mobileNo} label="Contact_No" />
                                            <Workbook.Column value={row => row.admisiaApplicantInfoDTO.applicationDate} label="Application_Date" />
                                            <Workbook.Column value={row => row.admisiaApplicantInfoDTO.lastDateExecuted} label="Approve_Date" />
                                        </Workbook.Sheet>
                                    </Workbook>

                                        </div>
                                    </div>
                                }

                            </div>

                            <div className="p-grid">
                                <div className="p-col-12 p-xl-9">
                                </div>
                                <div className="p-col-12 p-xl-3">
                                   
                                </div>


                            </div>

                        </div>

                        : null}


                    <Dialog
                        className="text-center nw-dialog"
                        header="Applicant Details"
                        visible={this.state.visible}
                        onHide={this.onHide} maximizable
                        style={{ "width": "90%" }}
                    >

                        <AdmisiaApplicantViewInfo
                            applicantInfo={this.state.applicantInfo}
                            photoInfo={this.state.applicantPicture}
                            ref="admisiaApplicantViewInfo"
                        />
                    </Dialog>

                </div>
            </div>
        );
    }
}