import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import CommonFuctionality from '../common/CommonFuctionality';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import NetiContentLoader from '../common/NetiContentLoader';


let errors = {};
export class EmGeneratedBillLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            dataTableIsLoading: false,
            discountNoteView: false,
            topProgressBar: false,
            discountNote: '',
            billLogDialog: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            emBillGeneratedLogList: [],
        }

        this.CommonFuctionality = new CommonFuctionality();
        this.CRMPointService = new CRMPointService();
        this.validatorUtility = new ValidatorUtility();
    }



    getEmBillGeneratedDetailsLog = (param) => {
        this.setState({ topProgressBar: true, errorMsgBody: '', errorMsgVisible: false });
        this.CRMPointService.getEdumanBillLogByBillType("Bill Generate", param)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({emBillGeneratedLogList: body});
                    });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, errorMsgBody: "Please check your connection.", topProgressBar: false })
            });
    }

    billDetailsLogDialog = (param) => {
        this.setState({ billLogDialog: true });
        this.getEmBillGeneratedDetailsLog(param);
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    billDetailsLogDialogHide = () => {
        this.setState({ billLogDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    billInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo">
                <div>
                    <div>Year</div>
                    <div>:</div>
                    <div>{rowData.emBillGenerateLogDTO.billYear}</div>
                </div>

                <div>
                    <div>Month</div>
                    <div>:</div>
                    <div>{rowData.emBillGenerateLogDTO.billMonth}</div>
                </div>
            </div>
        );
    }
    studentInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo admin-point">
                <div>
                    <div>Billable Student</div>
                    <div>:</div>
                    <div>{rowData.emBillGenerateLogDTO.billableStudent}</div>
                </div>

                <div>
                    <div>Disable Student</div>
                    <div>:</div>
                    <div>{rowData.emBillGenerateLogDTO.disableStudent}</div>
                </div>
            </div>
        );
    }

    discountNoteDialog = (note) => {

        this.setState({discountNoteView: true, discountNote: note});

    }

    rateInfo = (rowData) => {

        return (
            <div className="nw-datatable-dataview-small-panel productInfo">
                <div>
                    <div>Academic Year</div>
                    <div>:</div>
                    <div>{rowData.emBillGenerateLogDTO.academicYear}</div>
                </div>
                <div>
                    <div>Monthly Rate</div>
                    <div>:</div>
                    <div>{rowData.emBillGenerateLogDTO.ratePerStudent}</div>
                </div>
            </div>
        );
    }

    billableAmount = (rowData) => {
        return (
            <div className="nw-datatable-dataview-small-panel priceInfo">
                <div>
                    <div>BILLABLE</div>
                </div>
                <h2> <span className="symbol-taka">৳ </span>{this.validatorUtility.currencyFormatter(rowData.billAmount)}</h2>
            </div>
        );
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let detailsTableHeader = <div className="header-title">
        <div className="header-title-left">Generated Bill Log</div>

        <div className="header-title-right">
            <Button
                className="nw-button nw-button-center p-button-success"
                name="district"
                label="Download"
                icon="fas fa-plus"
                iconPos="right"
            />
        </div>
    </div>

        return (
<>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                    <Growl ref={(el) => this.growl = el} />
                    <Dialog header="Bill Details" style={{width: '70vw'}} visible={this.state.billLogDialog} onHide={this.billDetailsLogDialogHide} className="nw-dialog product-info-dialog">
                        <br />
                        <div className="p-fluid">
                            <div className="p-col-12">
                                <div className="p-col-12 p-xl-12 nw-datatable-detail-view product-list-datatable-detail-view">
                                    <DataTable
                                        header={detailsTableHeader}
                                        selectionMode="single"
                                        responsive={true}
                                        value={this.state.emBillGeneratedLogList}
                                        globalFilter={this.state.globalFilter}
                                        paginator={true}
                                        rows={5}
                                    >
                                        <Column field="" header="Bill Info" body={this.billInfo} />
                                        <Column field="" header="Student Info" body={this.studentInfo} />
                                        <Column field="" header="Rate Info" body={this.rateInfo}/>
                                        <Column field="" header="Billable Amount" body={this.billableAmount}/>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                

</>
        )
    }
}