import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { UserCategoryService } from '../../service/UserCategoryService';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import NetiContentLoader from '../../../src/components/common/NetiContentLoader';


let dialogStatus = false;
let dialogStatus1 = false;
export class DwsGlobalBookAdd extends Component {

    constructor() {
        super();
        this.state = {
            bookImage: {},
            updateBookImage: {},
            globalBookInfoErr: {},
            globalBookUpdateErr: {},
            classDropDownList: null,
            updateBookInfoObj: {
                bookType: "",
                bookName: "",
                bookPrice: '',
                authorName: "",
                publicationName: "",
                publicationYear: "",
                bookImageName: "",
                bookImageContent: "",
                bookImageSaveOrEditable: false,
                classCategoryInfoDTO: {
                    coreCategoryID: ''
                }
            },
            globalBookInfoObj: {
                bookType: "",
                bookName: "",
                bookPrice: '',
                authorName: "",
                publicationName: "",
                publicationYear: "",
                bookImageName: "",
                bookImageContent: "",
                bookImageSaveOrEditable: false,
                classCategoryInfoDTO: {
                    coreCategoryID: ''
                }
            },
            bookID:0,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            requestedBookList: [],
            globalBookList: [],
            firstDataTableIsLoading: false,
            secondDataTableIsLoading: false,
            firstDropdownIsLoading: false
        }

        this.globalBookEditAction = this.globalBookEditAction.bind(this);
        this.supportPointService = new SupportPointService();
        this.netiFileHandler = new NetiFileHandler();

        this.userCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentDidMount() {
        this.viewRequestedBookList();
        this.viewGlobalBookList();
    }

    viewRequestedBookList = () => {
        this.setState({ topProgressBar: true, firstDataTableIsLoading: true });

        this.supportPointService.getBookListByStatus(0)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ topProgressBar: false, firstDataTableIsLoading: false });

                        this.setState({ requestedBookList: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, firstDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })

                        });
                }
            }).catch(error => {
                console.log("error", 'Connection problem!');
                this.setState({ topProgressBar: false, firstDataTableIsLoading: false });
            });

    }

    viewGlobalBookList = () => {
        this.setState({ topProgressBar: true, secondDataTableIsLoading: true });
        this.supportPointService.getBookListByStatus(1)
            .then(res => {
                this.setState({ topProgressBar: false, secondDataTableIsLoading: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("TATA", body);
                        
                        this.setState({ globalBookList: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, secondDataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection problem!');
                this.setState({ topProgressBar: false, secondDataTableIsLoading: false });
            });

    }

    getClassListByDefaultCode(defaultCode) {
        this.setState({ firstDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if (defaultCode === 'T108') {
                            this.setState({ classDropDownList: body });
                        }
                        this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection problem!');
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
            });
    }

    onChangeClassName = (e) => {
        let { globalBookInfoObj } = this.state;
        globalBookInfoObj.classCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ globalBookInfoObj });
    }

    onChangeBookType = (e) => {
        let { globalBookInfoObj } = this.state;
        globalBookInfoObj.bookType = e.target.value;
        this.setState({ globalBookInfoObj });

    }

    onChangeBookName = (e) => {
        let { globalBookInfoObj } = this.state;
        globalBookInfoObj.bookName = e.target.value;
        this.setState({ globalBookInfoObj });
    }

    onChangeAuthorName = (e) => {
        let { globalBookInfoObj } = this.state;
        globalBookInfoObj.authorName = e.target.value;
        this.setState({ globalBookInfoObj });
    }

    onChangePublicationName = (e) => {
        let { globalBookInfoObj } = this.state;
        globalBookInfoObj.publicationName = e.target.value;
        this.setState({ globalBookInfoObj });
    }

    onChangePublicationYear = (e) => {
        let { globalBookInfoObj } = this.state;
        globalBookInfoObj.publicationYear = e.target.value;
        this.setState({ globalBookInfoObj });
    }

    onChangeBookPrice = (e) => {
        let { globalBookInfoObj } = this.state;
        globalBookInfoObj.bookPrice = e.target.value;
        this.setState({ globalBookInfoObj });
    }

    onImageUpload = (e) => {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = () => {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ bookImage: album });

        }
    }

    onSubmitGlobalNewBookInfo = () => {
        this.setState({ topProgressBar: true });
        let { globalBookInfoObj, globalBookInfoErr } = this.state;

        if (this.state.bookImage.contentPic !== null) {
            globalBookInfoObj.bookImageContent = this.state.bookImage.contentPic;
            globalBookInfoObj.bookImageSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.bookImage.extention);
            globalBookInfoObj.bookImageName = Date.now() + extention;
        }
        if (this.GlobalNewBookInfoSubmitError()) {
            this.supportPointService.saveGlobalBookInfo(globalBookInfoObj)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Sucessfully Approved!" });
                        this.viewRequestedBookList();
                        this.setState({ visible: false });
                    } else {
                        this.supportPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resBody })
                            });
                    }
                })
        }

    }

    GlobalNewBookInfoSubmitError = () => {
        let { globalBookInfoErr, globalBookInfoObj } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });

        if (globalBookInfoObj.classCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            globalBookInfoErr["class"] = "Class can't left empty.";
        }
        if (globalBookInfoObj.bookType === '') {
            formIsValid = false;
            globalBookInfoErr["bookType"] = "Book Type can't left empty.";
        }
        if (globalBookInfoObj.bookName === '') {
            formIsValid = false;
            globalBookInfoErr["bookName"] = "Book Name can't left empty.";
        }

        if (globalBookInfoObj.authorName === '') {
            formIsValid = false;
            globalBookInfoErr["authorName"] = "Author Name can't left empty.";
        }
        if (globalBookInfoObj.publicationName === '') {
            formIsValid = false;
            globalBookInfoErr["publications"] = "Publications can't left empty.";
        }
        if (this.state.bookImage.contentPic === undefined) {
            formIsValid = false;
            globalBookInfoErr["bookImageContent"] = "Book Image can't left empty.";
        }
        if (!ValidatorUtility.isValidNumber(globalBookInfoObj.bookPrice)) {
            formIsValid = false;
            globalBookInfoErr["bookPrice"] = "Price can't left empty.";
        }
        if (!ValidatorUtility.isValidNumber(globalBookInfoObj.publicationYear)) {
            formIsValid = false;
            globalBookInfoErr["publicationYear"] = "Publication Year can't left empty.";
        }

        this.setState({ globalBookInfoErr });
        return formIsValid;
    }



    viewEditDialog = (rowData) => {
        this.getClassListByDefaultCode('T108');
        dialogStatus1 = true;
        this.setState({ visible1: true });
        this.supportPointService.getBookInfoByID(rowData.globalBookId)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ updateBookInfoObj: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                console.log("error", 'Connection problem!');
            });



    }

    onUpdateClassName = (e) => {
        let { updateBookInfoObj } = this.state;
        updateBookInfoObj.classCategoryInfoDTO.coreCategoryID = e.target.value;
        this.setState({ updateBookInfoObj });
    }

    onUpdateBookType = (e) => {
        let { updateBookInfoObj } = this.state;
        updateBookInfoObj.bookType = e.target.value;
        this.setState({ updateBookInfoObj });

    }

    onUpdateBookName = (e) => {
        let { updateBookInfoObj } = this.state;
        updateBookInfoObj.bookName = e.target.value;
        this.setState({ updateBookInfoObj });
    }

    onUpdateAuthorName = (e) => {
        let { updateBookInfoObj } = this.state;
        updateBookInfoObj.authorName = e.target.value;
        this.setState({ updateBookInfoObj });
    }

    onUpdatePublicationName = (e) => {
        let { updateBookInfoObj } = this.state;
        updateBookInfoObj.publicationName = e.target.value;
        this.setState({ updateBookInfoObj });
    }

    onUpdatePublicationYear = (e) => {
        let { updateBookInfoObj } = this.state;
        updateBookInfoObj.publicationYear = e.target.value;
        this.setState({ updateBookInfoObj });
    }

    onUpdateBookPrice = (e) => {
        let { updateBookInfoObj } = this.state;
        updateBookInfoObj.bookPrice = e.target.value;
        this.setState({ updateBookInfoObj });
    }

    onUpdateImageUpload(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ updateBookImage: album });

        }
    }

    onUpdateHandler = () => {
        let { updateBookInfoObj } = this.state;
        this.setState({ topProgressBar: true });
        if (this.state.updateBookImage.contentPic !== null) {
            updateBookInfoObj.bookImageContent = this.state.updateBookImage.contentPic;
            updateBookInfoObj.bookImageSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.updateBookImage.extention);
            updateBookInfoObj.bookImageName = Date.now() + extention;
        }
        if (this.updateGlobalBookError()) {
            this.supportPointService.updateBookInfo(updateBookInfoObj)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated!" });
                        this.setState({ visible1: false });
                    } else {
                        this.supportPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    console.log('error', 'Connection problem!');
                });
        }
    }

    updateGlobalBookError = () => {
        let { globalBookUpdateErr, updateBookInfoObj } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });

        if (updateBookInfoObj.classCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            globalBookUpdateErr["updateClass"] = "Class can't left empty.";
        }
        if (updateBookInfoObj.bookType === '') {
            formIsValid = false;
            globalBookUpdateErr["updateBookType"] = "Book Type can't left empty.";
        }
        if (updateBookInfoObj.bookName === '') {
            formIsValid = false;
            globalBookUpdateErr["updateBookName"] = "Book Name can't left empty.";
        }

        if (updateBookInfoObj.authorName === '') {
            formIsValid = false;
            globalBookUpdateErr["updateAuthorName"] = "Author Name can't left empty.";
        }
        if (updateBookInfoObj.publicationName === '') {
            formIsValid = false;
            globalBookUpdateErr["updatePublications"] = "Publications can't left empty.";
        }
        if (updateBookInfoObj.publicationYear === '') {
            formIsValid = false;
            globalBookUpdateErr["updatePublicationYear"] = "Publication Year can't left empty.";
        }
        if (updateBookInfoObj.bookPrice === '') {
            formIsValid = false;
            globalBookUpdateErr["updateBookPrice"] = "Book Price can't left empty.";
        }
        if (this.state.updateBookImage.contentPic === undefined) {
            formIsValid = false;
            globalBookUpdateErr["bookImageContent"] = "Book Image can't left empty.";
        }

        this.setState({ globalBookUpdateErr });
        return formIsValid;
    }

    viewDialog = (e) => {
        this.getClassListByDefaultCode('T108');
        dialogStatus = true;
        this.setState({ visible: true });
    }

    onHideEditDialog = (event) => {
        this.setState({ visible1: false });
    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    globalBookAddAction = (e) => {
        this.viewDialog(e);
    }

    onSubmitBtn = (e) => {
        this.onHide(e);
    }

    globalBookEditAction = (rowData) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button"
                icon="fas fa-pencil-alt"
                onClick={(e) => this.viewEditDialog(rowData)} />
        </div>
    }

    onChangeBookStatus = (rowData) => {
        this.setState({ topProgressBar: true });
        this.supportPointService.changeBookStatus(rowData.globalBookId, 1)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 202) {
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully changed book status!" });
                    this.viewRequestedBookList();
                    this.viewGlobalBookList();
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((resBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resBody })
                        });
                }
            }).catch(error => console.log('error', 'Connection problem!'))
    }


    // requestBookDeleteAction= (rowData) => {
    //     return <div style={{ textAlign: 'center' }}>
            
    //     </div>;
    // }

    requestBookActiveAction = (rowData) => {
        return <div style={{ textAlign: 'center' }}>
            
            <Button
                className="nw-action-button"
                type="button"
                icon="fas fa-check"
                onClick={(e) => this.onChangeBookStatus(rowData)} 
            />

            <Button 
                className="nw-action-button"
                type="button" 
                icon="fas fa-trash-alt"
                onClick={(e) => this.deleteMemberRowData(e, rowData)}
            />    
        </div>;
    }



    deleteMemberRowData = (e, rowData) => {
       
    console.log("rowData", rowData);
    this.setState({ topProgressBar: true, errorMsgVisible: false });
    this.supportPointService.deleteBookInfo(rowData.globalBookId)
        .then(res => {
            if (res.status === 202) {
                this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully deleted' })
                this.setState({ topProgressBar: false, errorMsgVisible: false});
                this.viewRequestedBookList();
            } else {
                this.supportPointService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                    });  
            }
        }).catch(error => console.log(error));

    }




    render() {
        let { topProgressBar, errorMsgVisible, globalBookInfoObj, updateBookInfoObj, errorMsgBody, globalBookInfoErr, globalBookUpdateErr } = this.state;
        var requestedBookHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Requested Book List
        <span style={{ 'float': 'right' }}>Total Found:{this.state.requestedBookList.length}  </span>
        </div>;

        var globalBookHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Global Book List
       <span style={{ 'float': 'right' }}>Total Found:{this.state.globalBookList.length}  </span>
        </div>;

        let classList = [];
        if ((this.state.classDropDownList != null) && (this.state.classDropDownList.length > 0)) {
            classList = this.state.classDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">
                    <div className="p-grid nw-form">
                        
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            <Button
                                className="p-button p-button-primary nw-button nw-button-right"
                                label="Create Global Book"
                                onClick={(e) => this.globalBookAddAction(e)}
                            />
                        </div>

                        <div className="p-col-12 p-xl-6">
                            {this.state.firstDataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <div className="nw-data-table">
                                    <DataTable
                                        header={requestedBookHeader}
                                        value={this.state.requestedBookList}
                                        responsive={true}
                                        paginator={true}
                                        rows={10} >
                                        <Column field="bookType" header="Book Type" sortable={true} filter={true} />
                                        <Column field="className" header="Class Name" sortable={true} filter={true} />
                                        <Column field="bookName" header="Book Name" sortable={true} filter={true} />
                                        <Column body={this.requestBookActiveAction} header="Action" />
                                        {/* <Column body={this.requestBookDeleteAction} header="Delete" /> */}
                                    </DataTable>
                                </div>
                            }
                        </div>

                        <div className="p-col-12 p-xl-6">
                            {this.state.secondDataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <div className="nw-data-table">
                                    <DataTable
                                        header={globalBookHeader}
                                        value={this.state.globalBookList}
                                        responsive={true}
                                        paginator={true}
                                        rows={10} >
                                        <Column field="bookType" header="Book Type" sortable={true} filter={true} />
                                        <Column field="className" header="Class Name" sortable={true} filter={true} />
                                        <Column field="bookName" header="Book Name" sortable={true} filter={true} />
                                        <Column body={this.globalBookEditAction} header="Edit" />
                                    </DataTable>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="dialog-section">
                    <Dialog className="nw-dialog" header="Create Global Book" visible={this.state.visible} onHide={this.onHide} closable>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form">
                                        <div className="p-col-12 p-xl-12">
                                           <div className="p-grid nw-form-body">
                                                <div className="p-col-12 p-xl-6">
                                                    <div className="formControl">
                                                        <label>Book Type  <span>*</span></label>
                                                        <InputText
                                                            value={globalBookInfoObj.bookType}
                                                            onChange={this.onChangeBookType}
                                                            placeholder="Enter Book Type"
                                                            name="bookType"
                                                        />
                                                        <span className="error-message">{globalBookInfoErr['bookType']}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        {this.state.firstDropdownIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div>
                                                                <label>Class  <span>*</span></label>
                                                                <Dropdown
                                                                    autoWidth={false}
                                                                    options={classList}
                                                                    value={globalBookInfoObj.classCategoryInfoDTO.coreCategoryID}
                                                                    onChange={this.onChangeClassName}
                                                                    name="class"
                                                                    placeholder="Select Class Name" />
                                                            </div>
                                                        }
                                                        <span className="error-message">{globalBookInfoErr['class']}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label>Book Name  <span>*</span></label>
                                                        <InputText
                                                            value={globalBookInfoObj.bookName}
                                                            name='bookName'
                                                            onChange={this.onChangeBookName}
                                                            placeholder="Enter Book Name" />
                                                        <span className="error-message">{globalBookInfoErr['bookName']}</span>
                                                    </div>
                                                    <div className="formControl">
                                                        <label>Author Name  <span>*</span></label>
                                                        <InputText
                                                            value={globalBookInfoObj.authorName}
                                                            name='authorName'
                                                            onChange={this.onChangeAuthorName}
                                                            placeholder="Enter Author Name" />
                                                        <span className="error-message">{globalBookInfoErr['authorName']}</span>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-6">
                                                    <div className="formControl">
                                                        <label>Publication  <span>*</span></label>
                                                        <InputText
                                                            name="publications"
                                                            value={globalBookInfoObj.publicationName}
                                                            onChange={this.onChangePublicationName}
                                                            placeholder="Enter Publication" />
                                                        <span className="error-message">{globalBookInfoErr['publications']}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label>Publication Year  <span>*</span></label>
                                                        <InputText
                                                            value={globalBookInfoObj.publicationYear}
                                                            name="publicationYear"
                                                            onChange={this.onChangePublicationYear}
                                                            keyfilter="pint"
                                                            placeholder="Enter Publication Year" />
                                                        <span className="error-message">{globalBookInfoErr['publicationYear']}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label>Price  <span>*</span></label>
                                                        <InputText
                                                            keyfilter="num"
                                                            value={globalBookInfoObj.bookPrice}
                                                            onChange={this.onChangeBookPrice}
                                                            name="bookPrice"
                                                            placeholder="Enter Price" />
                                                        <span className="error-message">{globalBookInfoErr['bookPrice']}</span>
                                                    </div>

                                                    <div className="nw-upload-button">
                                                        <label>Upload Photo  <span>*</span></label>
                                                        <FileUpload
                                                            chooseLabel="Photo"
                                                            id="attachments"
                                                            mode="basic"
                                                            accept="image/*"
                                                            auto={true}
                                                            maxFileSize={1000000}
                                                            onSelect={this.onImageUpload}
                                                        />
                                                        <span className="error-message">{this.state.globalBookInfoErr.bookImageContent}</span>
                                                        <center>{this.state.bookImage.contentName}</center>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-grid">
                                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                        label="Save"
                                                        icon="fas fa-check"
                                                        onClick={this.onSubmitGlobalNewBookInfo}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </Dialog>

                    <Dialog className="nw-dialog" header="Update Global Book Information" visible={this.state.visible1} onHide={this.onHideEditDialog}>
                        <div className="p-fluid">
                            <div className="p-grid">
                                 <div className="p-col-12 p-xl-12">
                                    <div className="nw-form">
                                        <div className="p-col-12 p-xl-12">
                                           <div className="p-grid nw-form-body">
                                                <div className="p-col-12 p-xl-6">
                                                    <div className="formControl">
                                                        <label>Class</label>
                                                        <Dropdown
                                                            autoWidth={false}
                                                            options={classList}
                                                            value={this.state.updateBookInfoObj.classCategoryInfoDTO.coreCategoryID}
                                                            onChange={this.onUpdateClassName}
                                                            name="updateClass"
                                                            placeholder="Enter Class Name" />
                                                        <span className="error-message">{globalBookUpdateErr['updateclass']}</span>
                                                    </div>
                                                    <div className="formControl">
                                                        <label>Book Type</label>
                                                        <InputText
                                                            value={updateBookInfoObj.bookType}
                                                            onChange={this.onUpdateBookType}
                                                            placeholder="Select Book Type"
                                                            name="updateBookType"
                                                        />
                                                        <span className="error-message">{globalBookUpdateErr['updateBookType']}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label>Book Name</label>
                                                        <InputText
                                                            value={updateBookInfoObj.bookName}
                                                            name='updateBookName'
                                                            onChange={this.onUpdateBookName}
                                                            placeholder="Enter Book Name" />
                                                        <span className="error-message">{globalBookUpdateErr['updateBookName']}</span>
                                                    </div>
                                                    <div className="formControl">
                                                        <label>Author Name</label>
                                                        <InputText
                                                            value={updateBookInfoObj.authorName}
                                                            name='updateAuthorName'
                                                            onChange={this.onUpdateAuthorName}
                                                            placeholder="Enter Author Name" />
                                                        <span className="error-message">{globalBookUpdateErr['updateAuthorName']}</span>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-md-6">
                                                    <div className="formControl">
                                                        <label>Publications</label>
                                                        <InputText
                                                            name="updatePublications"
                                                            value={updateBookInfoObj.publicationName}
                                                            onChange={this.onUpdatePublicationName}
                                                            placeholder="Enter Publication" />
                                                        <span className="error-message">{globalBookUpdateErr['updatePublications']}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label>Publication Year</label>
                                                        <InputText
                                                            value={updateBookInfoObj.publicationYear}
                                                            name="updatePublicationYear"
                                                            onChange={this.onUpdatePublicationYear}
                                                            keyfilter="pint"
                                                            placeholder="Enter Publication Year" />
                                                        <span className="error-message">{globalBookUpdateErr['updatePublicationYear']}</span>
                                                    </div>

                                                    <div className="formControl">
                                                        <label>Price</label>
                                                        <InputText
                                                            keyfilter="pint"
                                                            value={updateBookInfoObj.bookPrice}
                                                            onChange={this.onUpdateBookPrice}
                                                            name="updateBookPrice"
                                                            placeholder="Enter Price" />
                                                        <span className="error-message">{globalBookUpdateErr['updateBookPrice']}</span>
                                                    </div>

                                                    <div className="nw-upload-button">
                                                        <label>Upload Photo</label>
                                                        <FileUpload
                                                            chooseLabel="Photo"
                                                            id="attachments"
                                                            mode="basic"
                                                            accept="image/*"
                                                            auto={true}
                                                            maxFileSize={1000000}
                                                            onSelect={this.onUpdateImageUpload.bind(this)}
                                                        />
                                                        <span className="error-message">{globalBookUpdateErr.bookImageContent}</span>
                                                        <center>
                                                            {this.state.updateBookImage.contentName}
                                                        </center>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-grid">
                                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                        label="Update"
                                                        icon="fas fa-check"
                                                        onClick={this.onUpdateHandler}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                 </div>
                            </div>
                        </div>
                       
                    </Dialog>
                </div>
            </div>
        );
    }
}