import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/components/inputtext/InputText';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { EmUserPointService } from '../../service/emUserPoint/EmUserPointService';
import { GuestPointService } from '../../service/guestAccessPoint/GuestPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { AddPortalForStudent } from './AddPortalForStudent';
import { AddPortalForGuardian } from './AddPortalForGuardian';
import { AddPortalForEmployee } from './AddPortalForEmployee';


let dialogStatus = false;

export class StudentAddPortal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            otpCodeError: {},
            errors: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            OTPcode: '',
            studentProfileLinkObj: {
                customUserID: '',
                instituteID: '',
                mappingType: 'Student'
            },

            guardianProfileLinkObj: {
                customUserID: '',
                instituteID: '',
                mappingType: 'Guardian'
            },
            hrProfileLinkObj: {
                customUserID: '',
                instituteID: '',
                mappingType: 'HR'
            },
            checkedRetriveObj: {}
        }
        this.emUserPointService = new EmUserPointService();
        this.guestPointService = new GuestPointService();
    }

    onChangeStudentProfileLinkUp(e) {
        let { studentProfileLinkObj } = this.state;
        studentProfileLinkObj[e.target.name] = e.target.value;
        this.setState({ studentProfileLinkObj });
    }
    onChangeGuardianProfileLinkUp(e) {
        let { guardianProfileLinkObj } = this.state;
        guardianProfileLinkObj[e.target.name] = e.target.value;
        this.setState({ guardianProfileLinkObj });
    }
    onChangeHrProfileLinkUp(e) {
        let { hrProfileLinkObj } = this.state;
        hrProfileLinkObj[e.target.name] = e.target.value;
        this.setState({ hrProfileLinkObj });
    }
    viewDialog() {
        dialogStatus = true;
        this.setState({ visible: true });
    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    onChangeOTPcode = (e) => {
        this.setState({
            OTPcode: e.target.value
        });
    }

    async onProcessBtn(e, v) {
        const { studentProfileLinkObj, guardianProfileLinkObj, hrProfileLinkObj } = this.state;
        let linkUpObj = {}
        if (v === 'student') {
            linkUpObj = studentProfileLinkObj;
        } else if (v === 'guardian') {
            linkUpObj = guardianProfileLinkObj;
        } else if (v === 'hr') {
            linkUpObj = hrProfileLinkObj;
        } else {
            return;
        }
        if (this.handleError(v)) {
            this.emUserPointService.checkForLinkUpNewPortal(linkUpObj)
                .then((res) => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            this.setState({ checkedRetriveObj: body });
                            this.viewDialog();
                        })
                    } else {
                        this.emUserPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                })
        }

    }


    handleError = (v) => {
        let { errors, studentProfileLinkObj, guardianProfileLinkObj, hrProfileLinkObj } = this.state;
        let formIsValid = true;

        if (v === 'student') {
            if (studentProfileLinkObj.customUserID === "") {
                formIsValid = false;
                errors["stdID"] = "Student ID can't be left empty";
            }
            if (studentProfileLinkObj.instituteID === "") {
                formIsValid = false;
                errors["instituteID"] = "Institute ID can't be left empty";
            }
        }

        if (v === 'guardian') {
            if (guardianProfileLinkObj.customUserID === "") {
                formIsValid = false;
                errors["guardianID"] = "Guardian ID can't be left empty";
            }
            if (guardianProfileLinkObj.instituteID === "") {
                formIsValid = false;
                errors["guardianInstituteID"] = "Institute ID can't be left empty";
            }
        }

        if (v === 'hr') {
            if (hrProfileLinkObj.customUserID === "") {
                formIsValid = false;
                errors["hrID"] = "HR ID can't be left empty";
            }
            if (hrProfileLinkObj.instituteID === "") {
                formIsValid = false;
                errors["hrInstituteID"] = "Institute ID can't be left empty";
            }
        }


        this.setState({ errors });
        return formIsValid;
    }

    async checkOtpCode(e) {
        if (this.otpCodeFieldError()) {
            const res = await this.guestPointService.checkOTP(this.state.OTPcode);
            const status = await res.status;
            if (status == 200) {
                this.emUserPointService.linkUpNewPortal(this.state.checkedRetriveObj)
                    .then((resp) => {
                        if (resp.status == 201) {
                            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully submitted.' });
                            this.onHide(e);
                        } else {
                            this.emUserPointService.Auth.handleErrorStatus(res)
                                .then((resp) => {
                                    this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                                });
                        }
                    })
            } else {
                const errorBody = await res.json();

            }
        }
    }

    otpCodeFieldError = () => {
        let { otpCodeError } = this.state;
        let formIsValid = true;
        if (this.state.OTPcode === "") {
            formIsValid = false;
            otpCodeError["otpCode"] = "OTP code can't be left empty";
        }
        this.setState({ otpCodeError });
        return formIsValid;

    }
    

    render() {

        let { studentProfileLinkObj, guardianProfileLinkObj, hrProfileLinkObj } = this.state;
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <AddPortalForStudent />
                {/* <AddPortalForGuardian />
                <AddPortalForEmployee /> */}
            </div>
        )
    }
}