import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from 'primereact/growl';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import { Dropdown } from "primereact/dropdown";
import NetiContentLoader from '../../../src/components/common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';

export class CreateBankAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bankList: [],
      bankName: "",
      defaultId: "",
      noteValue: "",
      branchId: '',
      branchName: '',
      accountNumber: '',
      accountHolder: '',
      accountType: '',
      accountDetails: '',
      dataTableValue: [],
      selectedRowData: [],
      errors: {},
      updateErrors: {},
      firstDropDownIsLoading: true,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',


    };

    this.fetchBankList = this.fetchBankList.bind(this);
    this.fetchBankAccountList = this.fetchBankAccountList.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.actionBody = this.actionBody.bind(this);

    this.viewDialog = this.viewDialog.bind(this);
    this.onHide = this.onHide.bind(this);

    this.submitUpdate = this.submitUpdate.bind(this);

    this.supportPointService = new SupportPointService();
    this.NetiContentLoader = new NetiContentLoader();

  }

  componentWillMount() { this.fetchBankList(); this.fetchBankAccountList(); };

  fetchBankList() {
    this.setState({ firstDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false })
    this.supportPointService.fetchAllBankList()
      .then(res => {
        if (res.status === 200) {
          this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          return res.json().then((body) => { this.setState({ bankList: body }) })
        } else {
          return res.json().then((errorBody) => {
            this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorBody.message });
          })
        };
      }).catch(error => {
        this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }

  onChangebankName = e => {
    this.setState({ bankName: e.target.value })
  };

  onChangeBranchId = e => {
    this.setState({ branchId: e.target.value })
  };

  onChangebranchName = e => {
    this.setState({ branchName: e.target.value })
  };

  onChangeAccountNumber = e => {
    this.setState({ accountNumber: e.target.value })
  };

  onChangeAccountHolderName = e => {
    this.setState({ accountHolder: e.target.value })
  };

  onChangeAccountType = e => {
    this.setState({ accountType: e.target.value })
  };

  onChangeAccountDetails = e => {
    this.setState({ accountDetails: e.target.value })
  };

  onSubmitErrorHandle = () => {
    let { errors } = this.state;
    let formIsValid = true;

    if (this.state.bankName === '') {
      formIsValid = false;
      errors['bankName'] = "Bank Name can't left empty."
    }
    if (this.state.branchId === '') {
      formIsValid = false;
      errors['branchId'] = "Branch ID can't left empty."
    }
    if (this.state.branchName === '') {
      formIsValid = false;
      errors['branchName'] = "Branch Name can't left empty."
    }
    if (this.state.accountNumber === '') {
      formIsValid = false;
      errors['accountNumber'] = "Account Number can't left empty."
    }
    if (this.state.accountHolder === '') {
      formIsValid = false;
      errors['accountHolder'] = "Account Holder Name can't left empty."
    }
    if (this.state.accountType === '') {
      formIsValid = false;
      errors['accountType'] = "Account Type can't left empty."
    }
    if (this.state.accountDetails === '') {
      formIsValid = false;
      errors['accountDetails'] = "Account Details can't left empty."
    }

    this.setState({ errors });
    return formIsValid;
  }

  onUpdateErrorHandle = () => {
    let { updateErrors } = this.state;
    let formIsValid = true;

    if (this.state.selectedRowData.bankName === '') {
      formIsValid = false;
      updateErrors['bankName'] = "Bank Name can't left empty."
    }
    if (this.state.selectedRowData.branchId === '') {
      formIsValid = false;
      updateErrors['branchId'] = "Branch ID can't left empty."
    }
    if (this.state.selectedRowData.branchName === '') {
      formIsValid = false;
      updateErrors['branchName'] = "Branch Name can't left empty."
    }
    if (this.state.selectedRowData.accountNumber === '') {
      formIsValid = false;
      updateErrors['accountNumber'] = "Account Number can't left empty."
    }
    if (this.state.selectedRowData.accountHolderName === '') {
      formIsValid = false;
      updateErrors['accountHolder'] = "Account Holder Name can't left empty."
    }
    if (this.state.selectedRowData.accountType === '') {
      formIsValid = false;
      updateErrors['accountType'] = "Account Type can't left empty."
    }
    if (this.state.selectedRowData.accountDetails === '') {
      formIsValid = false;
      updateErrors['accountDetails'] = "Account Details can't left empty."
    }

    this.setState({ updateErrors });
    return formIsValid;
  }

  actionBody(rowData) {
    return (
      <div className="text-center">
        <Button 
          className="nw-action-button edit" 
          icon="fas fa-pencil-alt" 
          title="Update" 
          onClick={e => this.viewDialog(rowData)} 
        />
      </div>
    );
  }

  viewDialog(rowData) {
    this.setState({ selectedRowData: rowData });
    this.setState({ visible: true });
  }

  onHide(event) {
    this.setState({ visible: false });
  }

  onSubmitHandler() {
    
    if (this.onSubmitErrorHandle()) {
      this.setState({ topProgressBar: true, errorMsgVisible: false })
      let requestedObj = {
        bankId: this.state.bankName,
        branchId: this.state.branchId,
        branchName: this.state.branchName,
        accountNumber: this.state.accountNumber,
        accountHolder: this.state.accountHolder,
        accountType: this.state.accountType,
        accountDetails: this.state.accountDetails
      }

      this.supportPointService.createBankAccount(requestedObj)
        .then(res => {
          if (res.status === 200) {
            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Bank account create successful.' });
            this.fetchBankAccountList();
            this.setState({ topProgressBar: false, errorMsgVisible: false })

          } else {
            return res.json().then((errorBody) => {
              this.setState({ topProgressBar: false, errorMsgVisible: false, errorBody: errorBody })
            })
          }
        }).catch(error => {
          this.setState({ topProgressBar: false, errorMsgVisible: false, errorBody: 'Please check your connection.' })
        })
    }

  }

  fetchBankAccountList() {
    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    this.supportPointService.fetchBankAccountList()
      .then(res => {
        if (res.status === 200) {
          return res.json().then((body) => {
            this.setState({ dataTableValue: body })
            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          })
        } else {
          return res.json().then((errorBody) => {
            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorBody.message });
          })
        };
      }).catch(error => {
        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }

  onChangeBankNameUpdate = e => {
    let { selectedRowData } = this.state;
    selectedRowData.bankId = e.target.value;
    this.setState({ selectedRowData });
  };

  onChangeBranchIdUpdate = e => {
    let { selectedRowData } = this.state;
    selectedRowData.branchId = e.target.value;
    this.setState({ selectedRowData });
  };

  onChangeBranchNameUpdate = e => {
    let { selectedRowData } = this.state;
    selectedRowData.branchName = e.target.value;
    this.setState({ selectedRowData });
  };

  onChangeAccNumberUpdate = e => {
    let { selectedRowData } = this.state;
    selectedRowData.accountNumber = e.target.value;
    this.setState({ selectedRowData });
  };

  onChangeAccHolderNameUpdate = e => {
    let { selectedRowData } = this.state;
    selectedRowData.accountHolderName = e.target.value;
    this.setState({ selectedRowData });
  };

  onChangeAccTypeUpdate = e => {
    let { selectedRowData } = this.state;
    selectedRowData.accountType = e.target.value;
    this.setState({ selectedRowData });
  };

  onChangeAccountDetailsUpdate = e => {
    let { selectedRowData } = this.state;
    selectedRowData.accountDetails = e.target.value;
    this.setState({ selectedRowData });
  };

  submitUpdate() {
    this.setState({ topProgressBar: true, errorMsgVisible: false })
    if (this.onUpdateErrorHandle()) {
      this.supportPointService.updateBankAccount(this.state.selectedRowData)
        .then(res => {
          if (res.status === 200) {
            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Bank account update successful.' });
            this.setState({ visible: false });
            this.setState({ topProgressBar: false, errorMsgVisible: false })
            this.fetchBankAccountList();
          } else {
            return res.json().then((errorBody) => {
              this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: errorBody })
            })
          };
        }).catch(error => {
          this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: 'Please check your connection.' })
        })
    }
  }

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    let bankOptions = [];
    if (this.state.bankList && this.state.bankList.length) {
      bankOptions = this.state.bankList.map(item => ({ label: item.bankName, value: item.bankId }))
    }

    let headervalue = (
      <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
        Created Bank Account List <span style={{ float: "right" }}>Total Found: {this.state.dataTableValue.length}</span>
      </div>
    );

    return (
      <div className="p-fluid">
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <Growl ref={(el) => this.growl = el} />
        <div className="p-grid nw-form">
          <div className="p-col-12 p-xl-12">
            <div className="nw-form-body">

              <div className="p-col-12 p-xl-12">
                {this.state.firstDropDownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Bank Name <span>*</span></span>
                    <Dropdown
                      placeholder="Select Bank Name"
                      options={bankOptions}
                      onChange={this.onChangebankName}
                      value={this.state.bankName}
                      filter={true}
                      autoWidth={false}
                    />
                  </div>
                }
                <span className='error-message'>{this.state.errors['bankName']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">Branch ID <span>*</span></span>
                  <InputText
                    type="text"
                    placeholder="Enter Branch ID"
                    onChange={this.onChangeBranchId}
                    value={this.state.branchId}
                    maxlength={50}
                  />
                </div>
                <span className='error-message'>{this.state.errors['branchId']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">Branch Name <span>*</span></span>
                  <InputText
                    type="text"
                    placeholder="Enter Branch Name"
                    onChange={this.onChangebranchName}
                    value={this.state.branchName}
                    maxlength={120}
                  />
                </div>
                <span className='error-message'>{this.state.errors['branchName']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">Account Number <span>*</span></span>
                  <InputText
                    type="text"
                    placeholder="Enter Account Number"
                    onChange={this.onChangeAccountNumber}
                    value={this.state.accountNumber}
                    maxlength={50}
                  />
                </div>
                <span className='error-message'>{this.state.errors['accountNumber']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">Account Holder Name <span>*</span></span>
                  <InputText
                    type="text"
                    placeholder="Enter Account Holder Name"
                    onChange={this.onChangeAccountHolderName}
                    value={this.state.accountHolder}
                    maxlength={120}
                  />
                </div>
                <span className='error-message'>{this.state.errors['accountHolder']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">Account Type <span>*</span></span>
                  <InputText
                    type="text"
                    placeholder="Enter Account Type"
                    onChange={this.onChangeAccountType}
                    value={this.state.accountType}
                    maxlength={50}
                  />
                </div>
                <span className='error-message'>{this.state.errors['accountType']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon nw-inputtextarea-label">Account Details <span>*</span></span>
                  <InputTextarea
                    type="text"
                    placeholder="Enter Account Details"
                    onChange={this.onChangeAccountDetails}
                    value={this.state.accountDetails}
                    maxlength={120}
                  />
                </div>
                <span className='error-message'>{this.state.errors['accountDetails']}</span>
              </div>

              <div className="p-col-12 p-xl-12" />

              <div className="p-col-12 p-xl-12 nw-button-parent">

                <div className="required-field">
                    (<span>*</span>) required fields
                </div>

                <Button
                  className="p-button p-button-primary nw-button nw-button-right"
                  label="Save"
                  icon="fas fa-check"
                  onClick={this.onSubmitHandler}
                />
              </div>

            </div>

          </div>
          <div className="p-col-12 p-md-3 p-lg-3 p-xl-3"></div>

          <div className="p-col-12 p-xl-12">
            <div className="nw-data-table nw-data-table-tabview">
              {this.state.dataTableIsLoading ?
                this.NetiContentLoader.MyPointTableLoader() :

                <DataTable
                  header={headervalue}
                  value={this.state.dataTableValue}
                  responsive={true}
                  paginator={true}
                  rows={10}
                >
                  <Column field="bankName" header="Bank Name" sortable={true} filter={true} />
                  <Column field="branchId" header="Branch ID" sortable={true} filter={true} />
                  <Column field="accountNumber" header="Account Number" sortable={true} filter={true} />
                  <Column field="accountHolderName" header="Account Holder Name" sortable={true} filter={true} />
                  <Column header="Action" body={this.actionBody} />
                </DataTable>
              }
            </div>
          </div>

          <Dialog header="Bank Account Update" className="nw-dialog" visible={this.state.visible} onHide={this.onHide}>

            <div className="p-fluid">
              <div className="p-grid">
                <div className="p-col-12 p-xl-12">
                  <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                      <div className="nw-form-body">
                          <div className="p-col-12 p-xl-12">
                            <label htmlFor="resolution-username">Bank Name <span>*</span></label>
                            <Dropdown
                              options={bankOptions}
                              placeholder="Select Bank Name"
                              onChange={this.onChangeBankNameUpdate}
                              value={this.state.selectedRowData.bankId || ''}
                              filter={true}
                              autoWidth={false}
                            />
                            <span className='error-message'>{this.state.updateErrors['bankName']}</span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <label htmlFor="resolution-username">Branch ID <span>*</span></label>
                            <InputText
                              type="text"
                              placeholder="Enter Branch ID"
                              onChange={this.onChangeBranchIdUpdate}
                              value={this.state.selectedRowData.branchId || ''}
                            />
                            <span className='error-message'>{this.state.updateErrors['branchId']}</span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <label htmlFor="resolution-username">Branch Name <span>*</span></label>
                            <InputText
                              type="text"
                              placeholder="Enter Branch Name"
                              onChange={this.onChangeBranchNameUpdate}
                              value={this.state.selectedRowData.branchName || ''}
                            />
                            <span className='error-message'>{this.state.updateErrors['branchName']}</span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <label htmlFor="resolution-username">Account Number <span>*</span></label>
                            <InputText
                              type="text"
                              placeholder="Enter Account Number"
                              onChange={this.onChangeAccNumberUpdate}
                              value={this.state.selectedRowData.accountNumber || ''}
                            />
                            <span className='error-message'>{this.state.updateErrors['accountNumber']}</span>
                          </div>

                          <div className="p-col-12 p-md-12">
                            <label htmlFor="resolution-username">Account Holder Name <span>*</span></label>
                            <InputText
                              type="text"
                              placeholder="Enter Account Holder Name"
                              onChange={this.onChangeAccHolderNameUpdate}
                              value={this.state.selectedRowData.accountHolderName || ''}
                            />
                            <span className='error-message'>{this.state.updateErrors['accountHolder']}</span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <label htmlFor="resolution-username">Account Type <span>*</span></label>
                            <InputText
                              type="text"
                              placeholder="Enter Account Type"
                              onChange={this.onChangeAccTypeUpdate}
                              value={this.state.selectedRowData.accountType || ''}
                            />
                            <span className='error-message'>{this.state.updateErrors['accountType']}</span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <label htmlFor="resolution-username">Account Details <span>*</span></label>
                            <InputTextarea
                              placeholder="Enter Note"
                              onChange={this.onChangeAccountDetailsUpdate}
                              value={this.state.selectedRowData.accountDetails || ''}
                            />
                            <span className='error-message'>{this.state.updateErrors['accountDetails']}</span>
                          </div>

                      
                          <div className="p-col-12 p-xl-12 nw-button-parent">
                            <Button 
                              label="Update" 
                              icon="fas fa-check"
                              className="p-button p-button-primary nw-button nw-button-right"
                              onClick={this.submitUpdate} 
                            />
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Dialog>
        </div>
      </div>
    );
  }
}
