import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { AppTopbar } from '../../../../AppTopbar';
class PaymentFailed extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
    render() {
        return (
            <div className="p-fluid">
                <div className="p-fluid blur-section">
                    <div className="paymentStatus">
                        <h1>Eduman Portal</h1>
                    </div>
                </div>
                <br />
                <div className="successPayment" id="successPayment">
                    <div className="successTop">
                        <div className="FailedCentralize">
                            <i class="fas fa-times"></i>
                        </div>
                        <h2>Your Payment Failed </h2>
                    </div>
                    <div className="successBottom">
                        <br />
                        <h3>Please try again</h3>
                        {/* <Link to="/"><button className="continueButton">Cancel Payment</button></Link> */}


                    </div>
                    <br />
                </div>
            </div>
        )
    }
}
export default PaymentFailed;
