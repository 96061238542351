import React, { Component } from 'react';
import ContentLoader from "react-content-loader"

export default class NetiContentLoader{
    
    MyLoader = () => (
    <ContentLoader 
        height={30}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="400" height="6" /> 
        <rect x="100" y="10" rx="3" ry="3" width="200" height="6" /> 
        <rect x="0" y="20" rx="3" ry="3" width="400" height="6" />
    </ContentLoader>
    )

    // WalletLoaderContent = () => (
    //     <ContentLoader 
    //         height={80}
    //         width={400}
    //         speed={2}
    //         primaryColor="#aaaaaa"
    //         secondaryColor="#ffffff"
    //     >
    //     <rect x="15" y="5" rx="3" ry="3" width="300" height="10" /> 
    //     <rect x="0" y="25" rx="3" ry="3" width="350" height="2" /> 
    //     <rect x="15" y="35" rx="3" ry="3" width="200" height="15" /> 
    //     <rect x="0" y="60" rx="0" ry="0" width="5" height="25" /> 
    //     <rect x="15" y="60" rx="3" ry="3" width="300" height="7" /> 
    //     <rect x="15" y="75" rx="3" ry="3" width="150" height="7" /> 
    //     <rect x="356" y="5" rx="0" ry="0" width="31" height="21" />
    //     </ContentLoader>
    //   )


      WalletLoaderContent = () => (
        <ContentLoader 
          height={240}
          width={600}
          speed={2}
          primaryColor="#e9f4ff"
          secondaryColor="#ffffff"
        >
          <rect x="0" y="0" rx="0" ry="0" width="600" height="4" /> 
          <rect x="0" y="0" rx="0" ry="0" width="4" height="240" /> 
          <rect x="0" y="236" rx="0" ry="0" width="600" height="4" /> 
          <rect x="596" y="0" rx="0" ry="0" width="4" height="240" /> 
          <rect x="227" y="28" rx="0" ry="0" width="150" height="30" /> 
          <rect x="48" y="82" rx="0" ry="0" width="500" height="40" /> 
          <rect x="153" y="144" rx="0" ry="0" width="37" height="40" /> 
          <rect x="205" y="144" rx="0" ry="0" width="264" height="40" /> 
          <rect x="185" y="203" rx="0" ry="0" width="252" height="20" />
        </ContentLoader>
      )

    MyPointFormLoader = () => (
        <ContentLoader 
          height={350}
          width={400}
          speed={2}
          primaryColor="#aaaaaa"
          secondaryColor="#ffffff"
        >
        <rect x="15" y="9" rx="3" ry="3" width="150" height="19" /> 
        <rect x="15" y="47" rx="3" ry="3" width="350" height="15" /> 
        <rect x="15" y="71" rx="3" ry="3" width="350" height="15" /> 
        <rect x="35" y="105" rx="3" ry="3" width="60" height="7" /> 
        <rect x="100" y="105" rx="3" ry="3" width="250" height="7" /> 
        <rect x="35" y="132" rx="3" ry="3" width="60" height="7" /> 
        <rect x="100" y="132" rx="3" ry="3" width="250" height="7" /> 
        <rect x="35" y="160" rx="3" ry="3" width="60" height="7" /> 
        <rect x="100" y="160" rx="3" ry="3" width="250" height="7" /> 
        <rect x="35" y="187" rx="3" ry="3" width="60" height="7" /> 
        <rect x="100" y="187" rx="3" ry="3" width="250" height="7" /> 
        <rect x="35" y="216" rx="3" ry="3" width="60" height="7" /> 
        <rect x="100" y="216" rx="3" ry="3" width="250" height="50" /> 
        <rect x="230" y="291" rx="3" ry="3" width="150" height="29" />
        </ContentLoader>
      )

      MyPointTableLoader = () => (
        <ContentLoader 
          height={200}
          width={1024}
          speed={2}
          primaryColor="#e9f4ff"
          secondaryColor="#ffffff"
        >
          <rect x="224" y="16" rx="0" ry="0" width="553" height="26" /> 
          <rect x="40" y="55" rx="2" ry="2" width="164" height="28" /> 
          <rect x="231" y="55" rx="2" ry="2" width="164" height="28" /> 
          <rect x="411" y="55" rx="2" ry="2" width="164" height="28" /> 
          <rect x="602" y="55" rx="2" ry="2" width="164" height="28" /> 
          <rect x="796" y="55" rx="2" ry="2" width="164" height="28" /> 
          <rect x="0" y="86" rx="0" ry="0" width="996" height="3" /> 
          <rect x="50" y="111" rx="0" ry="0" width="142" height="10" /> 
          <rect x="236" y="111" rx="0" ry="0" width="142" height="10" /> 
          <rect x="423" y="111" rx="0" ry="0" width="142" height="10" /> 
          <rect x="617" y="111" rx="0" ry="0" width="142" height="10" /> 
          <rect x="804" y="111" rx="0" ry="0" width="142" height="10" /> 
          <rect x="50" y="136" rx="0" ry="0" width="142" height="10" /> 
          <rect x="236" y="136" rx="0" ry="0" width="142" height="10" /> 
          <rect x="423" y="136" rx="0" ry="0" width="142" height="10" /> 
          <rect x="617" y="136" rx="0" ry="0" width="142" height="10" /> 
          <rect x="804" y="136" rx="0" ry="0" width="142" height="10" /> 
          <rect x="50" y="161" rx="0" ry="0" width="142" height="10" /> 
          <rect x="236" y="161" rx="0" ry="0" width="142" height="10" /> 
          <rect x="423" y="161" rx="0" ry="0" width="142" height="10" /> 
          <rect x="617" y="161" rx="0" ry="0" width="142" height="10" /> 
          <rect x="804" y="161" rx="0" ry="0" width="142" height="10" />
        </ContentLoader>
      )

      normalFormInputField = () => (
        <ContentLoader 
          height={50}
          width={1000}
          speed={2}
          primaryColor="#e9f4ff"
          secondaryColor="#ffffff"
        >
          <rect x="0" y="0" rx="0" ry="0" width="200" height="50" /> 
          <rect x="210" y="0" rx="0" ry="0" width="1000" height="50" />
        </ContentLoader>
      )

      updateFileLoader = () =>(
        <ContentLoader
          speed={2}
          width={400}
          height={160}
          viewBox="0 0 400 160"
          primaryColor="#e9f4ff"
          secondaryColor="#ffffff"
        >
          <rect x="0" y="0" rx="0" ry="0" width="70" height="75" />
          <rect x="85" y="20" rx="0" ry="0" width="320" height="40" />
        </ContentLoader>
      )

      ThreeDotsLoader = () => (
        <ContentLoader
          viewBox="0 0 400 160"
          height={100}
          width={400}
          speed={2}
          primaryColor="#e9f4ff"
          secondaryColor="#ffffff"
        >
          <circle cx="150" cy="86" r="8" />
          <circle cx="194" cy="86" r="8" />
          <circle cx="238" cy="86" r="8" />
        </ContentLoader>
      )
}
