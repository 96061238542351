import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { TreeTable } from 'primereact/treetable';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { PartnerPointService } from '../../../service/partnerPoint/PartnerPointService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Calendar } from 'primereact/calendar';

let assignedPartnerListArr = [];
let status = { display: 'table-cell' };
let maxDate = new Date();
let profile;

export class PartnerTeamWisePurchaseDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productType:'',
            tableView: false,
            errors:{},
            onExpander: true,
            searchObj: {
                startDate: "",
                endDate: "",
                netiID: ''
            },
            assignedPartnerList: [],
            productTypeErr: {},
            productType: '',
            dataTableIsLoading: false,
            mbpListObj: {},
            zbpListObj: {},
            bdpListObj: {},
            assignListObj: {},
            assignListArr: [],
            expandedKeys: {},
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            first: 0,
            rows: 10,
            totalRecords: 0,
            loading: false,
            searchData:[]
        }

        this.partnerPointService = new PartnerPointService;
        this.onExpand = this.onExpand.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.commonFuctionality = new CommonFuctionality();
    }

    onChangeStartDate = (e) => {
        let { searchObj } = this.state;
        searchObj.startDate = e.target.value
        this.setState({ searchObj });
        this.clearDateError(e.target.name);
    }

    onChangeEndDate = (e) => {
        let { searchObj } = this.state;
        searchObj.endDate = e.target.value
        this.setState({ searchObj });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onSearchHandler = () => {
        let { assignListObj, searchObj } = this.state;
        assignedPartnerListArr = [];
        this.setState({ assignListObj: {} });
         profile = JSON.parse(localStorage.getItem('Profile'));
        searchObj.netiID = profile.netiID;
        this.setState({searchObj});
        if (this.searchErrorHandler()) {
            if (Date.parse(searchObj.endDate) >= Date.parse(searchObj.startDate)) {
            this.setState({ topProgressBar: true, tableView: true, dataTableIsLoading: true, errorMsgVisible: false, errorMsgVisible: false });
            this.partnerPointService.findPartnerTeamWisePurchase(searchObj,this.state.productType)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            body.map((item, i) => {
                                assignListObj.data = item;
                                assignListObj.key = i;
                                assignListObj.children = [];
                                assignListObj.leaf = false;
                                assignedPartnerListArr.push(Object.assign({}, assignListObj));
                            });
        
                            this.setState({ searchData:body, dataTableIsLoading: false, tableView: true, errorMsgVisible: false, topProgressBar: false });
                        });
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
        }
    }

    }

    searchErrorHandler = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.searchObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start Date can't left empty.";
        }
        if (this.state.searchObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End Date can't left empty.";
        }
        if (this.state.productType === '') {
            formIsValid = false;
            errors["productType"] = "Product Type can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onExpand(event) {
        let { mbpListObj, zbpListObj, bdpListObj, assignListObj,searchObj } = this.state;
        this.setState({ mbpListObj: {} });
        this.setState({ zbpListObj: {} });
        this.setState({ bdpListObj: {} });
        let lazyNode = { ...event.node };
            if (lazyNode.data.designation === "MBP") {
                if(lazyNode.children.length === 0) {
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                searchObj.netiID = lazyNode.data.netiID;
                this.setState({searchObj});
                this.partnerPointService.findPartnerTeamWisePurchase(searchObj,this.state.productType).then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            body.map((item, i) => {
                                mbpListObj.data = item;
                                mbpListObj.key = `${lazyNode['key']}-${i}`;
                                mbpListObj.children = [];
                                mbpListObj.leaf = false;
                                lazyNode.children.push(Object.assign({}, mbpListObj));
                            });
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
             }
            }

            else if (lazyNode.data.designation === "ZBP") {
                if(lazyNode.children.length === 0) {
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                searchObj.netiID = lazyNode.data.netiID;
                this.setState({searchObj});
                this.partnerPointService.findPartnerTeamWisePurchase(this.state.searchObj,this.state.productType).then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            body.map((item, i) => {
                                zbpListObj.data = item;
                                zbpListObj.key = `${lazyNode['key']}-${i}`;
                                zbpListObj.children = [];
                                zbpListObj.leaf = false;
                                lazyNode.children.push(Object.assign({}, zbpListObj));
                            });

                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
             }
            }

            else if (lazyNode.data.designation === "BDP") {
                if(lazyNode.children.length === 0) {
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                searchObj.netiID = lazyNode.data.netiID;
                this.setState({searchObj});
                this.partnerPointService.findPartnerTeamWisePurchase(this.state.searchObj,this.state.productType).then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            body.map((item, i) => {
                                bdpListObj.data = item;
                                bdpListObj.key = `${lazyNode['key']}-${i}`;
                                lazyNode.children.push(Object.assign({}, bdpListObj));
                            });
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
            }
         }

         setTimeout(() => {
            this.commonFuctionality.setCustomizedTreeDatatable('pType', assignedPartnerListArr && assignedPartnerListArr[0] && assignedPartnerListArr[0].data && assignedPartnerListArr[0].data.designation);
        }, 500);

    }

    onExpandToggle = (e) => {
        this.setState({ expandedKeys: e.value })
    }

    onChangeStatus = (rowData) => {
        return (<span>{rowData.data["partnerStatus"] === 1 ? "Active" : "Inactive"}</span>);
    }

    onChangeProductType = e => {
        this.setState({ productType: e.target.value });
        this.clearDateError(e.target.name);
    }

    amountBodyTemplate = (rowData) => {
       return <div className="col-number text-right">{rowData && rowData.data.amount}</div>
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, searchData } = this.state;
        let header = <div className="header-title">
                        <div className="header-title-left">
                            Team Wise Purchase Report
                        </div>
                        <div className="header-title-right">
                            <a>
                                Total Found: {searchData.length.toLocaleString("EN-IN")}
                            </a>
                        </div>
                    </div>;
        let productTypeList = [
            { label: 'Admisia', value: 'Admisia' },
            { label: 'Neti_CMS', value: 'Neti_CMS' },
        ]
        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.searchObj.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            name="startDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.searchObj.endDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            name="endDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-10">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Product Type</span>
                                        <Dropdown
                                            placeholder="Select Product Type"
                                            value={this.state.productType}
                                            options={productTypeList}
                                            onChange={this.onChangeProductType}
                                            showClear={false}
                                            autoWidth={false}
                                            name="productType"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["productType"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-8"/>
                                <div className="p-col-12 p-xl-2 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSearchHandler}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-12 p-xl-12 p-col-nogutter nw-data-table">
                                {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            :
                                            <TreeTable
                                                header={header}
                                                lazy={true}
                                                value={assignedPartnerListArr}
                                                onExpand={this.onExpand}
                                                expandedKeys={this.state.expandedKeys}
                                                onToggle={this.onExpandToggle}
                                                style={{ marginTop: '.5em' }}>
                                                <Column field="designation" header="Partner Type" className='pType' expander={this.state.onExpander} />
                                                <Column field="area" header="Area" />
                                                <Column field="customNetiID" header="Neti ID" />
                                                <Column field="selfPurchase" header="Self" />
                                                <Column field="teamPurchase" header="Team" />
                                            </TreeTable>
                                        :
                                        <BlankDataTableAnim />
                                }
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}