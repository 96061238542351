import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from "primereact/calendar";
import { ProfileService } from '../../../service/profile/ProfileService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { UserCategoryService } from '../../../service/UserCategoryService';


export class PersonalCertificateInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userCertificateInfoObj: [],
            updateUserObj:{
                employmentStart: '',
                employmentEnd: '',
                countryInfoDTO:{}
            },
            insertUserObj:{
                countryInfoDTO:{
                    coreCategoryID: ''
                }
            },
            countryList: [],
            visibleDialog: false,
            visibleEditDialog: false,
            errors: {},
        }
        this.CommonFuctionality = new CommonFuctionality();
        this.ProfileService = new ProfileService();
        this.UserCategoryService = new UserCategoryService();

    }

    componentWillMount(){
        this.getUserCertificateInfo();
        this.getValueByDefaultCode('T10201');
    }

    getValueByDefaultCode = (defaultCode) => {
        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ countryList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    getUserCertificateInfo = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.ProfileService.fetchUserBasicInfo('certificate')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].achieveDate == null) {
                                body[i].achieveDateView = '';
                            } else {
                                body[i].achieveDateView = NetiDateUtils.getAnyShortForm(body[i].achieveDate, 'DD-MMM-YYYY');
                                body[i].achieveDateEdit = NetiDateUtils.getAnyShortForm(body[i].achieveDate, 'DD-MM-YYYY');
                            }
                        }
                        this.setState({ userCertificateInfoObj: body, topProgressBar: false, errorMsgVisible: false });
                    })
                }
                else {
                    this.ProfileService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeDialogHide = () => {
        this.setState({ visibleDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showDialog = () => {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visibleDialog: true, errors: {} });
    }

    onChangeEditDialogHide = () => {
        this.setState({ visibleEditDialog: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    showEditDialog = (itemData) => {
        if (itemData.achieveDate == null) {
            itemData.achieveDate = '';
        } 
        else {
            itemData.achieveDate = NetiDateUtils.getAnyShortForm(itemData.achieveDate, 'YYYY-MM-DD');
        }
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ updateUserObj: {...itemData}, visibleEditDialog: true, errors: {} });
    }

    onChageCertificationName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.certificationName = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.certificationName = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageInstituteName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.instituteName = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.instituteName = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageInstituteLocation = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.instituteLocation = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.instituteLocation = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageCountryName = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.countryInfoDTO.coreCategoryID = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.countryInfoDTO.coreCategoryID = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg(e.target.name);
    }

    onChangeStartDate = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.achieveDate = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.achieveDate = e.target.value
            updateUserObj.achieveDateEdit = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChageCourseDuration = (e, action) =>{
        let { updateUserObj, insertUserObj } = this.state;

        if(action == 'insert'){
            insertUserObj.courseDuration = e.target.value
        }
        else if(action == 'update'){
            updateUserObj.courseDuration = e.target.value
        }
        
        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    handleError = (objectHandle) => {
        let { errors } = this.state;
        let formIsValid = true;

        if (!objectHandle.certificationName) {
            formIsValid = false;
            errors["certificationName"] = "Certification Name can't left empty.";
        }

        if (!objectHandle.instituteName) {
            formIsValid = false;
            errors["instituteName"] = "Institute Name can't left empty.";
        }

        if (!objectHandle.instituteLocation) {
            formIsValid = false;
            errors["instituteLocation"] = "Institute Location can't left empty.";
        }

        if (!objectHandle.countryInfoDTO.coreCategoryID) {
            formIsValid = false;
            errors["countryName"] = "Certification country can't left empty.";
        }

        if (!objectHandle.achieveDate) {
            formIsValid = false;
            errors["achieveDate"] = "Achieve Date can't left empty.";
        }

        if (!objectHandle.courseDuration) {
            formIsValid = false;
            errors["courseDuration"] = "Course Duration can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onUpdateUserCertificateInfo = () => {
        let { updateUserObj } = this.state;
        if (this.handleError(updateUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
            this.ProfileService.updateUserBasicInfo( updateUserObj, 'certification')
                .then(res => {
                    if (res.status == 202) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                        this.onChangeEditDialogHide();
                        setTimeout(() => {
                            this.getUserCertificateInfo();
                        }, 1000);
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' })
                });
        }
    }

    onInsertUserCertificateInfo = () => {
        let { insertUserObj } = this.state;
        if (this.handleError(insertUserObj)) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: false })
            this.ProfileService.insertUserBasicInfo( insertUserObj, 'certification')
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated" });
                        this.onChangeDialogHide();
                        setTimeout(() => {
                            this.getUserCertificateInfo();
                        }, 1000);
                        
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' })
                });
        }
    }



    render() {
        let { userCertificateInfoObj, updateUserObj, insertUserObj, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let uploadFileLabel = <div>Click / Drag Here To Upload</div>

        let countryName = []
        if (this.state.countryList && this.state.countryList.length) {
            countryName = this.state.countryList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
        return (
            <div>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section blur-section">
                    <div className="p-grid">
                        {
                            userCertificateInfoObj.map((item, index) =>
                                <div className="p-col-12 p-md-6">
                                    <div className="personal-box-wrapper m-t-30">
                                        <div className="personal-box-title-wrapper">
                                            <div className="personal-box-title">
                                                <h4>Professional Qualification  - {index + 1}</h4>
                                            </div>
                                            <div className="edit-button">
                                                <Button icon="fas fa-edit" onClick={e => this.showEditDialog(item)} />
                                            </div>
                                        </div>
                                        <div className="personal-box">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Certification Name</td>
                                                        <td>{ item.certificationName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Institute </td>
                                                        <td>{ item.instituteName || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Location</td>
                                                        <td>{ (item.instituteLocation  +', '+ item.countryInfoDTO.categoryName) || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Date</td>
                                                        <td>{ item.achieveDateView || '---' }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Duration</td>
                                                        <td>{ item.courseDuration || '---' }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="add-button">
                        <Button
                            icon="fas fa-plus"
                            tooltip="Add Certificate Info"
                            tooltipOptions={{ position: 'bottom' }}
                            onClick={this.showDialog}
                        />
                    </div>
                </div>
                <div className="dialog-section">
                    <Dialog id="dialog" className="nw-dialog" header="Add Professional Qualification" visible={this.state.visibleDialog} onHide={this.onChangeDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Certification Name <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Certification Name"
                                                    name="certificationName"
                                                    value={ insertUserObj.certificationName }
                                                    onChange= { e => this.onChageCertificationName(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['certificationName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institute<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute Name"
                                                    name="instituteName"
                                                    value={ insertUserObj.instituteName }
                                                    onChange= { e => this.onChageInstituteName(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['instituteName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Location <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute Name"
                                                    name="instituteLocation"
                                                    value={ insertUserObj.instituteLocation }
                                                    onChange= { e => this.onChageInstituteLocation(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['instituteLocation']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Country <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Country"
                                                    onChange= { e => this.onChageCountryName(e, 'insert') }
                                                    value={ insertUserObj.countryInfoDTO.coreCategoryID }
                                                    options={countryName}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="countryName"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['countryName']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Achievement Date <span>*</span></span>
                                                <Calendar
                                                    id="startDate"
                                                    name="achieveDate"
                                                    showIcon={true}
                                                    value={ insertUserObj.achieveDate}
                                                    onChange={ e => this.onChangeStartDate(e, 'insert')}
                                                    placeholder='Select Date'
                                                    className="custom-calender-input"
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                />
                                            </div>
                                            <span className="error-message">{errors['achieveDate']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Course Duration <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Duration"
                                                    name="courseDuration"
                                                    value={ insertUserObj.courseDuration }
                                                    onChange= { e => this.onChageCourseDuration(e, 'insert') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['courseDuration']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={ this.onInsertUserCertificateInfo }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog id="dialog" className="nw-dialog" header="Edit Professional Qualification" visible={this.state.visibleEditDialog} onHide={this.onChangeEditDialogHide} maximizable>
                        <div className="p-col-12">
                            <div className="m-t-30 m-b-30">
                                <div className="nw-form">
                                    <div className="nw-form-body p-grid">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Certification Name <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Certification Name"
                                                    name="certificationName"
                                                    value={ updateUserObj.certificationName }
                                                    onChange= { e => this.onChageCertificationName(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['certificationName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institute<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute Name"
                                                    name="instituteName"
                                                    value={ updateUserObj.instituteName }
                                                    onChange= { e => this.onChageInstituteName(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['instituteName']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Location <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Institute Location"
                                                    name="instituteLocation"
                                                    value={ updateUserObj.instituteLocation }
                                                    onChange= { e => this.onChageInstituteLocation(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['instituteLocation']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Country <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Country"
                                                    onChange= { e => this.onChageCountryName(e, 'update') }
                                                    value={ updateUserObj.countryInfoDTO.coreCategoryID }
                                                    options={countryName}
                                                    filter={true}
                                                    autoWidth={false}
                                                    name="countryName"
                                                    showClear={true}
                                                />
                                            </div>
                                            <span className="error-message">{errors['countryName']}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Achievement Date <span>*</span></span>
                                                <Calendar
                                                    name="achieveDate"
                                                    showIcon={true}
                                                    value={ updateUserObj.achieveDateEdit}
                                                    onChange={ e => this.onChangeStartDate(e, 'update')}
                                                    placeholder='Select Date'
                                                    className="custom-calender-input"
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                />
                                            </div>
                                            <span className="error-message">{errors['achieveDate']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Course Duration <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Duration"
                                                    name="courseDuration"
                                                    value={ updateUserObj.courseDuration }
                                                    onChange= { e => this.onChageCourseDuration(e, 'update') }
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className="error-message">{errors['courseDuration']}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-button-parent">
                                                <Button
                                                    className="p-button-primary nw-button nw-button-right"
                                                    label="Save"
                                                    icon="fas fa-check"
                                                    onClick={ this.onUpdateUserCertificateInfo }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>
            </div>
        )
    }
} 