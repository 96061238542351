import React, { useState } from "react";
import { Button } from "primereact/button";
//antd
import { Upload } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

const OtherProductInfoDetails = ({ rowDataInfo, productViewDialog }) => {
  const fileList =
    rowDataInfo.imgContent && rowDataInfo.imgContent.length
      ? [
          {
            uid: rowDataInfo.imageName,
            name: rowDataInfo.imageName,
            status: "done",
            url: rowDataInfo.imgContent.length
              ? "data:image/png;base64," + rowDataInfo.imgContent
              : "",
          },
        ]
      : [];
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handlePreview = async (file) => {
    let a = document.createElement("a");
    a.href = file.url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  console.log({ rowDataInfo });
  return (
    <div class="nw-search-view no-border p-0">
      <div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon"> Product Type </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.productCategory}
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon"> Product Name </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.productName}
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon"> Default Code </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.productDefaultCode}
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon"> Product Details </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.productDetails}
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon">Product Stock </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.remainingStock}
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon">Generate Code </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.codeGenStatus === 1 ? "Yes" : "No"}
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon">Additional Info </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.productAdditionalStatus === 1
                ? "Yes"
                : "No"}
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon">Bijoy Sell Number </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.bijoySaltNumber}
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon">Bijoy Multi Number </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.bijoyMultiNumber}
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon">Sales Price </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.salesPrice} BDT
            </div>
          </div>
        </div>

        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon">Vat Percentage </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && rowDataInfo.percentVat}%
            </div>
          </div>
        </div>

        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon">Total Price with Vat </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo &&
                rowDataInfo.salesPrice +
                  (rowDataInfo.percentVat / 100) * rowDataInfo.salesPrice}{" "}
              BDT
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon">Video </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {rowDataInfo && (
                <a target="_blank" href={rowDataInfo.videoUrl}>
                  {rowDataInfo.videoUrl}
                </a>
              )}
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="task-badge found"></span>
            <span class="p-inputgroup-addon">Image </span>
            <span class="p-inputgroup-colon">:</span>
            <div class="nw-inputgroup-desc">
              {/* {rowDataInfo && rowDataInfo.imgContent} */}
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                customRequest={dummyRequest}
                fileList={fileList}
                onPreview={handlePreview}
                disabled
              >
                {fileList.length >= 1 ? null : "N/A"}
              </Upload>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
          <Button
            label="Close"
            icon="fas fa-times"
            className="p-button p-button-primary nw-button nw-button-right"
            onClick={(e) => productViewDialog()}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherProductInfoDetails;
