import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Growl } from "primereact/growl";
import { DwsService } from "../../../service/dwsPoint/DwsService";
import { NetiFileHandler } from "../../../utils/NetiFileHandler";
import { UserCategoryService } from "../../../service/UserCategoryService";

let errors = {};
let urlId;
let cloneStateBeforeMount;
export class AddNewBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proPic: {
        extention: "",
        contentPic: "",
        contentName: ""
      },
      visible: false,
      bookRequestInfoError: {},
        dwsGlobalBookInfoObj: {
          bookType: "",
          bookName: "",
          authorName: "",
          publicationName: "",
          publicationYear: "",
          bookPrice: "",
          bookImageSaveOrEditable: false,
          bookImageContent: "",
          classCategoryInfoDTO: {
            coreCategoryID: ""
          }
        },
      
    };

    this.dwsService = new DwsService();
    this.userCategoryService = new UserCategoryService();
    this.netiFileHandler = new NetiFileHandler();
    this.reloadCoreUrlIdFromLocal();
  }

  reloadCoreUrlIdFromLocal() {
    urlId = this.dwsService.getUrlInfoFromLocalStorage();
    if (!urlId) {
        setTimeout(() => { 
            this.componentWillMount();
        }, 2000);
    }
  }

  componentWillMount() {
    urlId=this.dwsService.getUrlInfoFromLocalStorage();
    this.getClassOrGroupListByDefaultCode("T108");
    cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
  }

  onChangeBookType = e => {
    let { dwsGlobalBookInfoObj, bookRequestInfoError } = this.state;
    bookRequestInfoError['bookType'] = ''
    dwsGlobalBookInfoObj.bookType = e.target.value;
    this.setState({ dwsGlobalBookInfoObj });
  };

  onChangeClass = e => {
    let { dwsGlobalBookInfoObj, bookRequestInfoError } = this.state;
    bookRequestInfoError['class'] = ''
    dwsGlobalBookInfoObj.classCategoryInfoDTO.coreCategoryID = e.target.value;
    this.setState({ dwsGlobalBookInfoObj });
  };

  onChangeBookName = e => {
    let { dwsGlobalBookInfoObj, bookRequestInfoError } = this.state;
    bookRequestInfoError['book'] = ''
    dwsGlobalBookInfoObj.bookName = e.target.value;
    this.setState({ dwsGlobalBookInfoObj });
  };

  onChangeBookAuthorName = e => {
    let { dwsGlobalBookInfoObj, bookRequestInfoError } = this.state;
    bookRequestInfoError['author'] = ''
    dwsGlobalBookInfoObj.authorName = e.target.value;
    this.setState({ dwsGlobalBookInfoObj });
  };

  onChangePublicationName = e => {
    let { dwsGlobalBookInfoObj, bookRequestInfoError } = this.state;
    bookRequestInfoError['publicationName'] = ''
    dwsGlobalBookInfoObj.publicationName = e.target.value;
    this.setState({ dwsGlobalBookInfoObj });
  };

  onChangePublicationYear = e => {
    let { dwsGlobalBookInfoObj, bookRequestInfoError } = this.state;
    bookRequestInfoError['publicationYear'] = ''
    dwsGlobalBookInfoObj.publicationYear = e.target.value;
    this.setState({ dwsGlobalBookInfoObj });
  };

  onChangeBookPrice = e => {
    let { dwsGlobalBookInfoObj, bookRequestInfoError } = this.state;
    bookRequestInfoError['bookPrice'] = ''
    dwsGlobalBookInfoObj.bookPrice = e.target.value;
    this.setState({ dwsGlobalBookInfoObj });
  };

  onImageUpload = e => {
    var reader = new FileReader();
    let photo = e.files[0];
    const scope = this;
    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = "data:" + photo.type + ";base64,"; //link will be same from the word webapps in URL
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      let album = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name
      };
      scope.setState({ proPic: album });
    };
  };

  onSubmitHandler = () => {
    let { dwsGlobalBookInfoObj } = this.state;
    if (this.state.proPic.contentPic !== null) {
      dwsGlobalBookInfoObj.bookImageContent = this.state.proPic.contentPic;
      dwsGlobalBookInfoObj.bookImageSaveOrEditable = true;
      let extention = this.netiFileHandler.getImageExtention(
        this.state.proPic.extention
      );
      //dwsGlobalBookInfoObj.bookImageName = 'book_list_' + urlId + '_' + Date.now() + extention;
      dwsGlobalBookInfoObj.bookImageName = this.state.proPic.contentName;

    }
    dwsGlobalBookInfoObj.bookAcceptableStatus = 0;
    if (this.addBookFormHandleError()) {
      this.setState({ dwsGlobalBookInfoObj });
      this.dwsService.createDwsNewBookRequest(dwsGlobalBookInfoObj).then(res => {
      
        if (res.status == 201) {
          this.growl.show({
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Created!",
            life: 800
          });
          this.setState({
            proPic: cloneStateBeforeMount.proPic,
            dwsGlobalBookInfoObj: cloneStateBeforeMount.dwsGlobalBookInfoObj
          });
          this.props.viewGlobalBooksList();
          setTimeout(() => {
            this.props.turnOffDialog();
          }, 800);
        } else {
          this.dwsService.Auth.handleErrorStatus(res)
          .then((resp) => {
            this.setState({ errorMsgVisible: true, errorMsgBody: resp, topProgressBar: false })
          });
          // this.growl.show({
          //   severity: "error",
          //   summary: "Error Message",
          //   detail: "Connection Problem!"
          // });
        }
      });
    }
  };

  getClassOrGroupListByDefaultCode(defaultCode) {
    this.userCategoryService
      .fetchListByDefaultCode(defaultCode)
      .then(res => {
        if (res.status == 302) {
          return res.json().then(body => {
            if (defaultCode === "T108") {
              this.setState({ classDropDownList: body });
            }
          });
        } else if (res.status == 404) {
          return res.json().then(body => {
          });
        }
      })
      .catch(error => {
       
      });
  }

  addBookFormHandleError = e => {
    let { bookRequestInfoError, dwsGlobalBookInfoObj } = this.state;
    let formIsValid = true;

    if (dwsGlobalBookInfoObj.bookType === "") {
      formIsValid = false;
      bookRequestInfoError["bookType"] = "Book Type can't left empty.";
    }
    if (dwsGlobalBookInfoObj.classCategoryInfoDTO.coreCategoryID === "") {
      formIsValid = false;
      bookRequestInfoError["class"] = "Class can't left empty.";
    }
    if (dwsGlobalBookInfoObj.bookName === "") {
      formIsValid = false;
      bookRequestInfoError["book"] = "Book Name can't left empty.";
    }
    if (dwsGlobalBookInfoObj.authorName === "") {
      formIsValid = false;
      bookRequestInfoError["author"] = "Author can't left empty.";
    }
    if (dwsGlobalBookInfoObj.publicationName === "") {
      formIsValid = false;
      bookRequestInfoError["publicationName"] =
        "Publication name can't left empty.";
    }
    if (dwsGlobalBookInfoObj.publicationYear === "") {
      formIsValid = false;
      bookRequestInfoError["publicationYear"] =
        "Publication year can't left empty.";
    }
    if (dwsGlobalBookInfoObj.bookPrice === "") {
      formIsValid = false;
      bookRequestInfoError["bookPrice"] = "Book price can't left empty.";
    }
    if (this.state.proPic.contentName === "") {
      formIsValid = false;
      bookRequestInfoError["bookImage"] = "Image file missing.";
    }
    this.setState({ bookRequestInfoError });
    return formIsValid;
  };

  render() {
    let { dwsGlobalBookInfoObj } = this.state;

    const bookType = [
      { label: "Board Book", value: "Board Book" },
      { label: "Additional Book", value: "Additional Book" }
    ];

    let classList = [];
    if (
      this.state.classDropDownList != null &&
      this.state.classDropDownList.length > 0
    ) {
      classList = this.state.classDropDownList.map(item => ({
        value: item.coreCategoryID,
        label: item.categoryName
      }));
    }

    return (
      <div className="p-fluid">
        <Growl ref={el => (this.growl = el)} />
        <div className="p-grid">
          <div className="p-col-12 p-xl-12">
            <div className="nw-form">
              <div className="p-col-12 p-xl-12">
                <div className="p-grid nw-form-body">
                  <div className="p-col-12 p-xl-12">
                    <label htmlFor="bookType">Book Type <span>*</span></label>
                    <Dropdown
                      value={dwsGlobalBookInfoObj.bookType}
                      placeholder="Select Book Type"
                      options={bookType}
                      autoWidth={false}
                      onChange={this.onChangeBookType}
                      id="bookType"
                      name="bookType"
                    />
                    <span className="error-message">
                      {this.state.bookRequestInfoError["bookType"]}
                    </span>
                  </div>

                  <div className="p-col-12 p-xl-6">
                      <label htmlFor="class">Class <span>*</span></label>
                      <Dropdown
                        placeholder="Select Class"
                        value={dwsGlobalBookInfoObj && dwsGlobalBookInfoObj.classCategoryInfoDTO ? dwsGlobalBookInfoObj.classCategoryInfoDTO.coreCategoryID : ''}
                        options={classList}
                        onChange={this.onChangeClass}
                        // itemTemplate={this.bankTemplate}
                        filter={true}
                        filterBy="label,value"
                        showClear={true}
                        autoWidth={false}
                        name="class"
                      />
                      <span className="error-message">
                        {this.state.bookRequestInfoError["class"]}
                      </span>
                  </div>

                  <div className="p-col-12 p-xl-6">
                      <label htmlFor="book">Book <span>*</span></label>
                      <InputText
                        value={dwsGlobalBookInfoObj.bookName}
                        // style={{margin:"0 !important"}}
                        placeholder="Enter Book Name"
                        // id="book"
                        name="book"
                        onChange={this.onChangeBookName}
                      />
                      <span className="error-message">
                        {this.state.bookRequestInfoError["book"]}
                      </span>
                  </div>

                  <div className="p-col-12 p-xl-6">
                      <label htmlFor="author">Author <span>*</span></label>
                      <InputText
                        value={dwsGlobalBookInfoObj.authorName}
                        placeholder="Enter Author Name"
                        id="author"
                        name="author"
                        onChange={this.onChangeBookAuthorName}
                      />
                      <span className="error-message">
                        {this.state.bookRequestInfoError["author"]}
                      </span>
                  </div>


                  <div className="p-col-12 p-xl-6">
                      <label htmlFor="publicationName">Publication Name <span>*</span></label>
                      <InputText
                        value={dwsGlobalBookInfoObj.publicationName}
                        placeholder="Enter Publication Name"
                        // options={bookType}
                        autoWidth={false}
                        onChange={this.onChangePublicationName}
                        id="publicationName"
                        name="publicationName"
                      />
                      <span className="error-message">
                        {this.state.bookRequestInfoError["publicationName"]}
                      </span>
                  </div>

                  <div className="p-col-12 p-xl-6">
                      <label htmlFor="publicationYear">Publication Year <span>*</span></label>
                      <InputText
                        value={dwsGlobalBookInfoObj.publicationYear}
                        placeholder="Enter Publication Year"
                        id="publicationYear"
                        name="publicationYear"
                        onChange={this.onChangePublicationYear}
                        keyfilter="pint"
                      />
                      <span className="error-message">
                        {this.state.bookRequestInfoError["publicationYear"]}
                      </span>
                  </div>

                  <div className="p-col-12 p-xl-6">
                      <label htmlFor="bookPrice">Price <span>*</span></label>
                      <InputText
                        value={dwsGlobalBookInfoObj.bookPrice}
                        placeholder="Enter Book Price"
                        id="bookPrice"
                        keyfilter="pint"
                        name="bookPrice"
                        onChange={this.onChangeBookPrice}
                      />
                      <span className="error-message">
                        {this.state.bookRequestInfoError["bookPrice"]}
                      </span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-upload-button">
                            <FileUpload
                              mode="basic"
                              accept="image/*"
                              id="attachment"
                              accept="image/*"
                              maxFileSize={1000000}
                              onSelect={this.onImageUpload}
                              auto={true}
                              chooseLabel="Upload Photo"
                            
                            />
                            <center className="error-message text-center">
                              { this.state.bookRequestInfoError["bookImage"] }
                            </center>
                            <center> 
                            {this.state.proPic.contentName}
                            </center>
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                      <Button
                          className="p-button p-button-danger nw-button nw-button-multiple"
                          label="Discard"
                          icon="fas fa-times"
                          onClick={this.props.turnOffDialog}
                      />

                      <Button
                        label="Save"
                        icon="fas fa-check"
                        className="p-button p-button-primary nw-button nw-button-multiple"
                        onClick={this.onSubmitHandler}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
