import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Editor } from 'primereact/editor';
import { Calendar } from "primereact/calendar";
import { CalendarEventList } from "./CalendarEventList";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import CommonFuctionality from "../../common/CommonFuctionality";
import { UserCategoryService } from '../../../service/UserCategoryService';
import { AdminPointService } from "../../../service/adminPoint/AdminPointService";
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';

let dialogStatus = false;
let cloneStateBeforeMount;
export class CreateGlobalCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarInfo: {
        coreCategoryInfoDTO:{
          coreCategoryID:''
        },
        taggingCode:'',
        eventTitle:'',
        eventDetails:'',
        fromDate:'',
        toDate:'',
       
      },
      errors: {},
      dataTableIsLoading: false,
      partnerTagging:'',
      errorMsgVisible: false,
      topProgressBar: false,
      secoundDropdownIsLoading:false,
      errorMsgBody: ""
    };
    this.CommonFuctionality = new CommonFuctionality();
    this.UserCategoryService = new UserCategoryService();
    this.adminPointService = new AdminPointService();
    this.NetiContentLoader = new NetiContentLoader();
  }

  componentWillMount() {
    this.getCategoryList();
    this.getpartnerTaggingList();
    //cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
  }

// Start  data save functionality
    onChangeBannerCategory = (e) => {
      let {calendarInfo,errors} = this.state;
      errors["calendarId"] = "";
      calendarInfo.coreCategoryInfoDTO.coreCategoryID = e.target.value;
      this.setState({errors: errors });
      this.setState({ calendarInfo })
    }

    onChangePartnerTagging = (e) => {
      let {calendarInfo,errors} = this.state;
      errors["taggingCode"] = "";
      calendarInfo.taggingCode = e.target.value;
      this.setState({errors: errors });
      this.setState({ calendarInfo })
    }

    onChangeEventTitle = (e) => {
      let {calendarInfo,errors} = this.state;
      errors["eventTitle"] = "";
      calendarInfo.eventTitle = e.target.value;
      this.setState({errors: errors });
      this.setState({ calendarInfo })
    }

    onChangeCalendarDetails = (e) => {
      let {calendarInfo,errors} = this.state;
      errors["eventDetails"] = "";
      calendarInfo.eventDetails = e.htmlValue;
      this.setState({errors: errors });
      this.setState({ calendarInfo })
    }

    onChangeCalendarEventStartDate = (e) => {
      let {calendarInfo,errors} = this.state;
      errors["fromDate"] = "";
      calendarInfo.fromDate = e.target.value;
      this.setState({errors: errors });
      this.setState({ calendarInfo })
    }

    onChangeCalendarEventEndDate = (e) => {
      let {calendarInfo,errors} = this.state;
      errors["toDate"] = "";
      calendarInfo.toDate = e.target.value;
      this.setState({errors: errors });
      this.setState({ calendarInfo })
    }

      // form validation
    handleError = () => {
        let { calendarInfo,errors } = this.state
        let formIsValid = true;

        if (calendarInfo.coreCategoryInfoDTO.coreCategoryID === '' || calendarInfo.coreCategoryInfoDTO.coreCategoryID === null) {
            formIsValid = false;
            errors["calendarId"] = "Calender category can't left empty";
        }

        if (calendarInfo.taggingCode === '' || calendarInfo.taggingCode === null) {
          formIsValid = false;
          errors["taggingCode"] = "Partner tagging can't left empty";
        }

        if (calendarInfo.eventTitle === '' || calendarInfo.eventTitle === null) {
          formIsValid = false;
          errors["eventTitle"] = "Event title can't left empty";
        }

        if (calendarInfo.eventDetails === '' || calendarInfo.eventDetails === null) {
          formIsValid = false;
          errors["eventDetails"] = "Event details can't left empty";
        }

        if (calendarInfo.fromDate === '' || calendarInfo.fromDate === undefined) {
          formIsValid = false;
          errors["fromDate"] = "Event start date can't left empty";
        }

        if (calendarInfo.toDate === '' || calendarInfo.toDate === undefined) {
          formIsValid = false;
          errors["toDate"] = "Event end date can't left empty";
        }
        
        else{
          return formIsValid;
        }
        this.setState({errors});
      }

  onSubmitHandler = () => {
    let { calendarInfo, errors } = this.state;


      if (this.handleError()) {
        this.setState({dataTableIsLoading: true});
          calendarInfo.fromDate = NetiDateUtils.getDateFromString(calendarInfo.fromDate);
          calendarInfo.toDate = NetiDateUtils.getDateFromString(calendarInfo.toDate);
          this.adminPointService.createNewGlobalCalender(calendarInfo)
          .then(res =>{
            if (res.status == 201) {
              this.setState({  dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
              this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Data Saved Successfully." });
              let { calendarInfo } = this.state;
              calendarInfo.coreCategoryInfoDTO.coreCategoryID = calendarInfo.eventDetails = calendarInfo.eventTitle = calendarInfo.fromDate = calendarInfo.taggingCode = calendarInfo.toDate = ''
              this.setState({calendarInfo});
            }else {
              this.adminPointService.Auth.handleErrorStatus(res)
                .then((resp) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                });
          }
          })
       
      }
  }

  // banner category
    getCategoryList(){
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.UserCategoryService.fetchListByDefaultCode('T120')
        .then(res=>{
            if (res.status == 302) {
                return res.json().then((body) => {
                    this.setState({ bannerCategoryList: body, topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false });
                    // this.setState({ bankList: body });
                  });
            }else{
                this.UserCategoryService.authService.handleErrorStatus(res)
                .then((resp) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp, firstDropdownIsLoading: false })
                });
            }
        }).catch(error =>{
            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
        })
    }

    // partner list
    getpartnerTaggingList(){
      this.setState({ topProgressBar: true, secoundDropdownIsLoading: true, errorMsgVisible: false })
      this.UserCategoryService.findByTypeDefaultCodeTypeStatus('T11202','1')
      .then(res=>{
          if (res.status == 302) {
              return res.json().then((body) => {
                  this.setState({ partnerTaggingList: body, topProgressBar: false, secoundDropdownIsLoading: false, errorMsgVisible: false });
                });
          }else{
              this.UserCategoryService.authService.handleErrorStatus(res)
              .then((resp) => {
                  this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp, secoundDropdownIsLoading: false })
              });
          }
      }).catch(error =>{
          this.setState({ topProgressBar: false, secoundDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
      })
  }


  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    let { calendarInfo } = this.state;


    let bannerCategoryOptions = [];
    if (this.state.bannerCategoryList && this.state.bannerCategoryList.length) {
        bannerCategoryOptions = this.state.bannerCategoryList.map((item)=>({
            value:item.coreCategoryID,
            label:item.categoryName,
        }));
    }

    let partnerTaggingOptions = [];
    if (this.state.partnerTaggingList && this.state.partnerTaggingList.length) {
      partnerTaggingOptions = this.state.partnerTaggingList.map((item)=>({
            value:item.coreCategoryID,
            label:item.categoryName,
        }));
    }

    let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>


    return (
      <div >
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <Growl ref={el => (this.growl = el)} />
        <div className="main-section">
            <div className="p-grid nw-form blur-section">
              <div className="p-col-12 p-xl-12">
                <div className="nw-form-body">
                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Category {requiredSing}</span>
                      <Dropdown
                        id="calendarCategory"
                        value={calendarInfo.coreCategoryInfoDTO.coreCategoryID}
                        options={bannerCategoryOptions}
                        onChange={this.onChangeBannerCategory}
                        placeholder="Select Category"
                        showClear={true}
                        name="calendarCategory"
                        autoWidth={false}
                      />
                    </div>
                    <span className="error-message">{this.state.errors["calendarId"]}</span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Partner Tagging {requiredSing}
                      </span>
                      <Dropdown
                        id="calendarTitle"
                        name="calendarTitle"
                        options={partnerTaggingOptions}
                        placeholder="Select partner for tagging"
                        filter={true}
                        value={calendarInfo.taggingCode}
                        onChange={this.onChangePartnerTagging}
                        filterBy="label,value"
                        showClear={true}
                        autoWidth={false}
                      />
                    </div>
                    <span className="error-message">{this.state.errors["taggingCode"]}</span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Event Title {requiredSing}</span>
                      <InputText
                        onChange={this.onChangeEventTitle}
                        value={calendarInfo.eventTitle}
                        placeholder="Enter Event Title"
                        type="text"
                        id="name"
                        name="name"
                      />
                    </div>
                    <span className="error-message">{this.state.errors["eventTitle"]}</span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon nw-inputtexteditor-label">
                        Event Details {requiredSing}
                      </span>
                      <Editor
                        id="calendarDetails"
                        // onChange={this.onChangeBannerDetails}
                        // value={calendarInfo.calendarDetails}
                        value={calendarInfo.eventDetails}
                        onTextChange={this.onChangeCalendarDetails}
                        name="calendarDetails"
                        autoWidth={false}
                        maxi
                      />
                    </div>
                    <span className="error-message">{this.state.errors["eventDetails"]}</span>
                  </div>

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Event Start {requiredSing}
                      </span>
                      <Calendar
                        id="startDate"
                        name="startDate"
                        showIcon={true}
                        value={calendarInfo.fromDate}
                        onChange={this.onChangeCalendarEventStartDate}
                        placeholder='Select Date'
                        className="custom-calender-input"
                        yearRange="2010:2030"
                        dateFormat="dd/mm/yy"
                        monthNavigator={true}
                        yearNavigator={true}
                        dateOnly="true"
                      />
                    </div>
                    <span className="error-message">{this.state.errors["fromDate"]}</span>
                  </div>
                  

                  <div className="p-col-12 p-xl-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        Event End {requiredSing}
                      </span>
                      <Calendar
                        placeholder='Select Date'
                        id="endDate"
                        name="endDate"
                        value={calendarInfo.toDate}
                        onChange={this.onChangeCalendarEventEndDate}
                        showIcon={true}
                        className="custom-calender-input"
                        yearRange="2010:2030"
                        dateFormat="dd/mm/yy"
                        monthNavigator={true}
                        yearNavigator={true}
                        dateOnly="true"
                      />
                    </div>
                    <span className="error-message">{this.state.errors["toDate"]}</span>
                  </div>

                  <div className="p-col-12 p-xl-12"/>

                  <div className="p-col-12 p-xl-12 nw-button-parent">

                    <div className="required-field">
                        (<span>*</span>) required fields
                    </div> 

                    <Button
                      className="p-button p-button-primary nw-button nw-button-right"
                      label="Save"
                      icon="fas fa-check"
                      onClick={this.onSubmitHandler}
                    />
                  </div>
                </div>
              </div> 
            </div>
        
           
         
          <div className="p-col-12">
            <div>
              {this.state.dataTableIsLoading ? 
                this.NetiContentLoader.MyPointTableLoader():
                  <CalendarEventList 
                    bannerCategory={bannerCategoryOptions}
                    partnerTagging={partnerTaggingOptions}
                  />
                }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
