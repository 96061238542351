import React, { Component } from 'react';

export class BlankDataTableAnim extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount(){

        this.blankTableAnim();

    }

    blankTableAnim=()=>{
        const script = document.createElement("script");

        script.src = "/assets/js/anim/BlankTableAnim.js";
        script.async = true;

        document.body.appendChild(script);
    }

    render() {
        return (
            <div className="p-col-12 nw-blank-table" style={{"height": "60vh"}}>
                <div id="blank-table-anim" className="blank-table-anim"></div>
            </div>
        )
    }
}