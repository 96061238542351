import React, { component, Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Dropdown } from 'primereact/dropdown';
// custom imports
import { DwsService } from '../../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { ErrorMessageView } from '../../../common/ErrorMessageView';

let cmsId = 0;

export class OACollectionAnalytics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,

            classAndGroupList: [],
            classConfigId: '',
            academicYear: '',

            searchObj: {
                fromDate: '',
                toDate: '',
            },
            dataTableValue: [
                { cmsId: '100123', instituteID: '10023', instituteName: 'Pathshala School & College', partnerID: '88100123', totalApplicant: '32,455', totalAmount: "3,22,455.00", applicationCharge: '2,72,455.00', serviceCharge: '36,450.00', mfsCharge: '13,550.00' }
            ],
            totalCountObj: {}
        }

        // this.emptyHandleError = this.emptyHandleError.bind(this);

        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    onChageDate = (e) => {
        let { searchObj } = this.state;
        searchObj[e.target.name] = e.target.value;
        this.setState({ searchObj });
    }

    render() {

        let { searchObj, dataTableValue, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">OA Collection Statement </div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>;

        const count_totalApplicant = '32,455';
        const count_totalAmount = '3,22,455.00';
        const count_applicationCharge = '2,72,455.00';
        const count_serviceCharge = '36,450.00,';
        const count_mfsCharge = '13,550.00';

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={4} footerStyle={{ textAlign: 'right' }} />
                <Column footer={count_totalApplicant} />
                <Column footer={count_totalAmount} />
                <Column footer={count_applicationCharge} />
                <Column footer={count_serviceCharge} />
                <Column footer={count_mfsCharge} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                {this.state.homeReturnButton === false ?

                    <div className="main-section blur-section">

                        <div className="p-grid nw-form">

                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="p-grid nw-form-body">
                                    <div className="p-col-12 p-lg-12 p-xl-5">

                                        {this.state.secondDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-small-span">From Date<span>*</span></span>
                                                <Calendar
                                                    showIcon={true}
                                                    placeholder="Select Date"
                                                    yearRange="2010:2030"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                    showButtonBar={true}
                                                    name="fromDate"
                                                    value={searchObj.fromDate}
                                                    onChange={e => this.onChageDate(e)}
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{errors["fromDate"]}</span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-5">
                                        {this.state.firstDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-small-span">To Date<span>*</span></span>
                                                <Calendar
                                                    showIcon={true}
                                                    placeholder="Select Date"
                                                    yearRange="2010:2030"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                    showButtonBar={true}
                                                    name="toDate"
                                                    value={searchObj.toDate}
                                                    onChange={e => this.onChageDate(e)}
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{errors["classAndgroup"]}</span>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-center"
                                            label="Search"
                                            icon="fas fa-search"
                                            onClick={this.onSearchHandler}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="p-grid m-t-30">

                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="collection-summary-header">OA Collection Summary </div>
                                <div className="collection-summary-body">

                                    <div className="summary-container">
                                        <label className="font-w-401"> Total CMS ID</label>
                                        <div className="text-bold m-t-15">47</div>
                                    </div>

                                    <div className="summary-container">
                                        <label className="font-w-401">Total Paid Applicant</label>
                                        <div className="text-bold m-t-15">32,404</div>
                                    </div>

                                    <div className="summary-container">
                                        <label className="font-w-401">  Total Collected OA Amount</label>
                                        <div className="text-bold m-t-15">11,30,450.00</div>
                                    </div>

                                    <div className="summary-container">
                                        <label className="font-w-401"> Total Collected Service Charge</label>
                                        <div className="text-bold m-t-15">25,405.50</div>
                                    </div>

                                </div>
                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="nw-data-table">
                                    {!this.state.dataTableIsLoading ?
                                        <DataTable
                                            value={dataTableValue}
                                            selectionMode="single"
                                            header={tableHeader}
                                            footerColumnGroup={footerGroup}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                        >
                                            <Column field="cmsId" header="CMS ID & URL" filter={true} />
                                            <Column field="instituteID" header="Institute ID" filter={true} />
                                            <Column field="instituteName" header="Institute Name" filter={true} />
                                            <Column field="partnerID" header="Partner ID & Name" filter={true} />
                                            <Column field="totalApplicant" header="Total Applicant" filter={true} />
                                            <Column field="totalAmount" header="Total Amount" filter={true} />
                                            <Column field="applicationCharge" header="Application Charge" filter={true} />
                                            <Column field="serviceCharge" header="Service Charge" filter={true} />
                                            <Column field="mfsCharge" header="MFS Charge" filter={true} />
                                        </DataTable>
                                        : this.NetiContentLoader.MyPointTableLoader()
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}