import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import CommonFuctionality from "../../common/CommonFuctionality";
import { AdminPointService } from "../../../service/adminPoint/AdminPointService";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { Growl } from "primereact/growl";
import { from } from "rxjs";
import { Link } from 'react-router-dom';

let cloneStateBeforeMount;
export class CategoryType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      show: false,
      categoryType: "",
      categoryDefaultCode: "",
      parentStaus: "0",
      parentValue: "",
      parentValueid: "",
      noteValue: "",
      CategoryTypeError: {},
      errors: {},
      dataTableValue: [],
      dataTableIsLoading: false,
      topProgressBar: false,
      dialogDropdownIsLoading: false,
      updateTypeObject: {
        parentTypeInfoDTO: {
          coreCategoryID: 0
        }
      }
    };
    // this.actionBody = this.actionBody.bind(this);
    this.CommonFuctionality = new CommonFuctionality();
    this.viewDialog = this.viewDialog.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onHide2 = this.onHide2.bind(this);
    this.adminPointService = new AdminPointService();
    this.NetiContentLoader = new NetiContentLoader();
  }
  componentWillMount() {
    this.loadCategoryTypeList();
    cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
  }

  loadCategoryTypeList() {
    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false })
    this.adminPointService.fetchCategoryListByTypeStatus(1)
      .then(res => {
        if (res.status == 302) {
          return res.json().then(body => {

            for (var i = 0; i < body.length; i++) {
              if (body[i].parentStatus == 1) {
                body[i].parentStatus = "Yes";
              } else {
                body[i].parentStatus = "No";
                body[i].parentTypeInfoDTO = { categoryName: "" };
              }
            }

            this.setState({ dataTableValue: body, dataTableIsLoading: false, topProgressBar: false });
          });
        } else {
          this.adminPointService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
            });
        }
      })
      .catch(error => {
        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
      });
  }

  onCreateCategoryType = () => {
    this.setState({ visible: true, CategoryTypeError: {} })
    this.CommonFuctionality.blurDialogBackgroundActive()
  }

  onChangeCategoryType = e => {
    let { CategoryTypeError } = this.state;
    CategoryTypeError[e.target.name] = '';
    this.setState({ categoryType: e.target.value });
    this.setState({ CategoryTypeError });
  };

  onChangecategoryDefaultCode = e => {
    let { CategoryTypeError } = this.state;
    CategoryTypeError[e.target.name] = '';
    this.setState({ categoryDefaultCode: e.target.value });
    this.setState({ CategoryTypeError });
  };

  onChangeNoteValue = e => {
    let { CategoryTypeError } = this.state;
    CategoryTypeError[e.target.name] = '';
    this.setState({ noteValue: e.target.value });
    this.setState({ CategoryTypeError });
  };

  onChangeParentStatus = e => {
    this.setState({ parentStaus: e.target.value, show: false });

    let parentStatusValue;
    if (e.target.value == 1) {
      this.setState({ show: true });
      parentStatusValue = 1;
    } else if (e.target.value == 0) {
      this.setState({ show: false });
      parentStatusValue = 0;
    }
    let { updateTypeObject } = this.state;
    updateTypeObject.parentStatus = e.target.value;
    this.setState({ updateTypeObject });
  };

  onChangedialogdialogNotevalue = e => {
    this.setState({
      dialogNotevalue: e.target.value
    });
  };

  onChangedialogcategoryType = e => {
    let { updateTypeObject, errors } = this.state;
    updateTypeObject.categoryName = e.target.value;
    errors["dialogcategoryType"] = ''
    this.setState({ updateTypeObject, errors });
  };

  onUpdateNoteValue = e => {
    let { updateTypeObject, errors } = this.state;
    updateTypeObject.categoryNote = e.target.value;
    errors["updateNote"] = ''
    this.setState({ updateTypeObject, errors });
  };

  onChangeUpdateParentStatus(e) {
    let { updateTypeObject, errors } = this.state;
    if (e.value === "1") {
      updateTypeObject.parentStatus = "1";
      this.setState({ diagparentStaus: "1", diagShow: true });
    }
    if (e.value === "0") {
      updateTypeObject.parentStatus = "0";
      this.setState({ diagparentStaus: "1", diagShow: false, parentValueid: null });
    }

    errors["updateParent"] = ''
    this.setState({ errors })
  }

  onChangeParentTypeInfoId = e => {
    let { parentTypeInfoDTO } = this.state.updateTypeObject;
    parentTypeInfoDTO.coreCategoryID = e.target.value;
    this.setState({ parentTypeInfoDTO });

    let { errors } = this.state
    errors["updateParent"] = ''
    this.setState({ errors })
  };

  actionBody(rowData) {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button edit"
          icon="fas fa-edit"
          tooltip="Update"
          onClick={e => this.viewDialog(rowData)}
        />
      </div>
    );
  }

  viewDialog(rowData) {
    this.setState({ visible2: true });
    let { updateTypeObject } = this.state;
    let { parentTypeInfoDTO } = this.state.updateTypeObject;

    updateTypeObject.coreCategoryID = rowData.coreCategoryID;
    updateTypeObject.categoryDefaultCode = rowData.categoryDefaultCode;
    updateTypeObject.categoryName = rowData.categoryName;
    updateTypeObject.categoryNote = rowData.categoryNote;
    updateTypeObject.categoryEnableStatus = rowData.categoryEnableStatus;
    updateTypeObject.categorySerial = rowData.categorySerial;

    // updateTypeObject.parentStatus = rowData.parentStatus

    if (rowData.parentStatus === "Yes") {
      updateTypeObject.parentStatus = "1";
      this.setState({ diagparentStaus: 1, diagShow: true });
    }
    if (rowData.parentStatus === "No") {
      updateTypeObject.parentStatus = "0";
      this.setState({
        diagparentStaus: 0,
        diagShow: false,
        parentValueid: null
      });
    }

    updateTypeObject.typeStatus = rowData.typeStatus;
    parentTypeInfoDTO.coreCategoryID = rowData.parentTypeInfoDTO.coreCategoryID;
    parentTypeInfoDTO.categoryName = rowData.parentTypeInfoDTO.categoryName;

    // this.setState({parentStatus: rowData.parentStatus})

    this.setState({ updateTypeObject: updateTypeObject });
    this.setState({ parentTypeInfoDTO: parentTypeInfoDTO });
    // this.setState({ parentStaus: rowData.parentStatus });
    // this.setState({ parentCoreCategoryName: rowData.parentTypeInfoDTO.categoryName });
    // this.setState({ parentCoreCategoryId: rowData.parentTypeInfoDTO.categoryId });
    // coreCategoryID = rowData.parentTypeInfoDTO.categoryName;

    this.CommonFuctionality.blurDialogBackgroundActive();

  }



  onHide(event) {
    this.setState({ visible: false }); 
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  }

  onHide2(event) {
    this.setState({ visible2: false });
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  }

  createCategoryType = () => {
    let requestedObject = {
      categoryName: this.state.categoryType,
      categoryNote: this.state.noteValue,
      parentStatus: this.state.parentStaus,
      categoryDefaultCode: this.state.categoryDefaultCode,
      parentTypeInfoDTO: {
        coreCategoryID: this.state.parentValueid
      }
    };

    if (this.createCategoryTypeError()) {
      this.adminPointService
        .createCategoryType(requestedObject)
        .then(res => {
          if (res.status === 201) {
            this.growl.show({ severity: "success", summary: "Success Message", detail: "Successfully Submitted" });
            this.setState(cloneStateBeforeMount);
            this.onHide();
            this.loadCategoryTypeList();
          } else {
            this.adminPointService.Auth.handleErrorStatus(res)
              .then((resp) => {
                this.setState({ errorMsgVisible: true, errorMsgBody: resp })
              });
          }

        })
        .catch(error => console.log("error", 'Connection Problem'));
    }
  };

  handleCategoryTypeUpdate = () => {
    if (this.updateCategoryTypeError()) {
      this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
      this.adminPointService.updateCategoryType(this.state.updateTypeObject).then(res => {
        console.log('Log: CategoryType -> handleCategoryTypeUpdate -> res.status', res.status)
        if (res.status === 202) {
          this.growl.show({ severity: "success", summary: "Success Message", detail: "Successfully updated" });
          this.setState({ visible2: false });
          this.setState({ topProgressBar: false, dataTableIsLoading: false })
          this.setState({ updateTypeObject: cloneStateBeforeMount.updateTypeObject })
          this.loadCategoryTypeList();
          this.onHide2();
        } else {
          this.adminPointService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
            });
        }
      }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" }));

      this.setState({ visible: false });
      this.setState({ categoryType: "" });
      this.setState({ categoryDefaultCode: "" });
      this.setState({ noteValue: "" });
    }
  };

  updateDialogDiscard = () => {
    this.onHide2();

  }

  createCategoryTypeError() {
    let { CategoryTypeError } = this.state;
    let formIsValid = true;

    if (this.state.categoryType === '') {
      formIsValid = false;
      CategoryTypeError["categoryType"] = "Category Type can't left empty.";
    }
    if (this.state.categoryDefaultCode === '') {
      formIsValid = false;
      CategoryTypeError["categoryDefaultCode"] = "Default Code can't left empty.";
    }
    if (this.state.noteValue === '') {
      formIsValid = false;
      CategoryTypeError["note"] = "Note can't left empty.";
    }
    if (this.state.parentStaus === '1' && !this.state.parentValueid) {
      formIsValid = false;
      CategoryTypeError["parentValueid"] = "Parent status can't left empty.";
    }

    this.setState({ CategoryTypeError });
    return formIsValid;
  }

  updateCategoryTypeError() {
    let { errors, updateTypeObject } = this.state;
    let formIsValid = true;

    if (updateTypeObject.categoryName === '') {
      formIsValid = false;
      errors["dialogcategoryType"] = "Category Type can't left empty.";
    }
    if (updateTypeObject.categoryNote === '') {
      formIsValid = false;
      errors["updateNote"] = "Note can't left empty.";
    }

    if (updateTypeObject.parentStatus === '1' && !updateTypeObject.parentTypeInfoDTO.coreCategoryID) {
      formIsValid = false;
      errors["updateParent"] = "Parent status can't left empty.";
    }

    this.setState({ errors });
    return formIsValid;
  }

  dialogDiscard = () => {
    this.onHide();
  }

  bodyDefaultCode = (rowData) => {
    return (<div className="col-number text-left"> {rowData['categoryDefaultCode']}</div>)
}

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    let { updateTypeObject } = this.state;
    let { parentTypeInfoDTO } = this.state.updateTypeObject;

    // let headervalue = (
    //   <div className="p-clearfix">
    //     Category Type List{" "}
    //     <span style={{ float: "right" }}>
    //       {" "}
    //       Total Found: {this.state.dataTableValue.length}{" "}
    //     </span>
    //     <Button
    //       label="Create Category Type"
    //       icon="fas fa-plus"
    //       className="p-button p-button-primary nw-button nw-button-right"
    //       onClick={this.onCreateCategoryType}
    //       iconPos="left"
    //     />
    //   </div>
    // );

    let headervalue = <div className="header-title">
            <div className="header-title-left">
                Category Type List
            </div>


            <div className="header-title-right">
              <a>
                Total Found: {this.state.dataTableValue.length}
              </a>

              <Button
                label="Create Category Type"
                icon="fas fa-plus"
                className="p-button p-button-success nw-button nw-button-right"
                onClick={this.onCreateCategoryType}
                iconPos="right"
              />
            </div>
        </div>;

    let parentValueOption = [];
    if (
      this.state.dataTableValue != null &&
      this.state.dataTableValue.length > 0
    ) {
      parentValueOption = this.state.dataTableValue.map(item => ({
        value: item.coreCategoryID,
        label: item.categoryName + " - " + item.categoryDefaultCode
      }));
    }
    return (
      <div className="p-fluid">
        {topProgressBar ? (
            <ErrorMessageView topProgressBar={topProgressBar} />
          ) : null}
          {errorMsgVisible ? (
            <ErrorMessageView
              errorMsgVisible={errorMsgVisible}
              errorMsgBody={errorMsgBody}
            />
          ) : null}
          <Growl ref={el => (this.growl = el)} />
        <div className="main-section">
          <div className="p-grid nw-form">
            <div className="p-col-12 p-xl-12">
              <div className="nw-data-table nw-data-table-tabview blur-section">
                {this.state.dataTableIsLoading ? (
                  this.NetiContentLoader.MyPointTableLoader()
                ) : (
                  <DataTable
                    header={headervalue}
                    value={this.state.dataTableValue}
                    responsive={true}
                    paginator={true}
                    rows={10}
                  >
                    <Column
                      field="categoryName"
                      header="Type"
                      sortable={true}
                      filter={true}
                    />
                    <Column
                      field="categoryDefaultCode"
                      header="Default Code"
                      sortable={true}
                      filter={true}
                      body={this.bodyDefaultCode}
                    />
                    <Column
                      field="parentStatus"
                      header="Parent Status"
                      sortable={true}
                      filter={true}
                    />
                    <Column
                      field="parentTypeInfoDTO.categoryName"
                      header="Parent"
                      sortable={true}
                      filter={true}
                    />
                    <Column header="Action" style={{width: "80px"}} body={this.actionBody.bind(this)} />
                  </DataTable>
                )}

              </div>
            </div>
            </div>
        </div>

        {/* start dialog */}
        <div className="dialog-section">
        <Dialog
                  header="Category Type(Create)"
                  className="nw-dialog"
                  visible={this.state.visible}
                  onHide={this.onHide}
                  maximizable
                >
                  <div className="nw-form">
                      <div className="p-col-12 p-xl-12">
                        <div className="nw-form-body">

                          <div className="p-col-12 p-xl-12">
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Type <span>*</span></span>
                                <InputText
                                  type="text"
                                  placeholder="Enter Type Name"
                                  onChange={this.onChangeCategoryType}
                                  value={this.state.categoryType}
                                  name="categoryType"
                                />
                              </div>
                              <span className="error-message">{this.state.CategoryTypeError["categoryType"]}</span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Default Code <span>*</span></span>
                                <InputText
                                  type="text"
                                  name="categoryDefaultCode"
                                  placeholder="Enter Default Code"
                                  onChange={this.onChangecategoryDefaultCode}
                                  value={this.state.categoryDefaultCode}
                                />
                              </div>
                              <span className="error-message">{this.state.CategoryTypeError["categoryDefaultCode"]}</span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                <InputTextarea
                                  type="text"
                                  name="note"
                                  placeholder="Enter Note"
                                  onChange={this.onChangeNoteValue}
                                  value={this.state.noteValue}
                                />
                              </div>
                              <span
                                  className="error-message"
                              >
                                  {this.state.CategoryTypeError["note"]}
                              </span>
                          </div>

                          <div className="p-col-12 p-xl-12">
                            <div className="p-inputgroup">
                              <div className="input-radio-button">
                                <span className="p-inputgroup-addon addon-no-style">Parent Status <span>*</span></span>
                                <div className="radio-button-inside">
                                  <RadioButton
                                      inputId="rb1"
                                      name="parentStaus"
                                      value="1"
                                      onChange={e =>
                                        this.setState({ parentStaus: e.value, show: true })
                                      }
                                      checked={this.state.parentStaus === "1"}
                                    />
                                    <label htmlFor="rb1" className="p-radiobutton-label">
                                      Yes
                                    </label>
                              </div>
                                <div className="radio-button-inside">
                                    <RadioButton
                                      inputId="rb2"
                                      name="parentStaus"
                                      value="0"
                                      onChange={e =>
                                        this.setState({
                                          parentStaus: e.value,
                                          show: false,
                                          parentValueid: 0
                                        })
                                      }
                                      checked={this.state.parentStaus === "0"}
                                    />
                                    <label htmlFor="rb1" className="p-radiobutton-label">
                                      No
                                    </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.show ? (
                            <div className="p-col-12 p-xl-12">
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Parent <span>*</span></span>
                                <Dropdown
                                  placeholder="Select Parent"
                                  options={parentValueOption}
                                  name="parentValueid"
                                  value={this.state.parentValueid}
                                  onChange={e =>
                                    this.setState({ parentValueid: e.value })
                                  }
                                  filter={true}
                                  autoWidth={false}
                                  showClear={true}
                                />
                              </div>
                              <span className="error-message">
                                {this.state.parentStaus == "1" &&
                                  !this.state.parentValueid &&
                                  this.state.CategoryTypeError["parentValueid"]}
                              </span>
                            </div>
                          ) : (
                            <span> </span>
                          )}

                          <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                            <Button
                              label="Save"
                              icon="fas fa-check"
                              className="p-button p-button-primary nw-button nw-button-multiple"
                              onClick={this.createCategoryType}
                            />
                          </div>
                        </div>
                      </div>
                  </div>
                </Dialog>
                {/* Table Dialog */}
                <Dialog
                  className="nw-dialog"
                  header="Category Type(Update)"
                  visible={this.state.visible2}
                  onHide={this.onHide2}
                  maximizable
                >
                  <div className="nw-form">
                    <div className="p-col-12 p-xl-12">
                      <div className="nw-form-body">
                        
                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">Default Code</span>
                              <div className="nw-inputgroup-desc">{updateTypeObject.categoryDefaultCode}</div>
                          </div>
                        </div>

                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">Type <span>*</span></span>
                              <InputText
                                type="text"
                                name="dialogcategoryType"
                                placeholder="Enter Type Name"
                                onChange={this.onChangedialogcategoryType}
                                value={updateTypeObject.categoryName || ""}
                              />
                          </div>
                          <span className="error-message">{this.state.errors["dialogcategoryType"]}</span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                              <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                              <InputTextarea
                                type="text"
                                name="updateNote"
                                placeholder="Enter Note"
                                onChange={this.onUpdateNoteValue}
                                value={updateTypeObject.categoryNote}
                              />
                          </div>
                          <span className="error-message">{this.state.errors["updateNote"]}</span>
                        </div>

                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                            <div className="input-radio-button">
                                <span className="p-inputgroup-addon addon-no-style">Parent Status <span>*</span></span>
                                <div className="radio-button-inside">
                                  <RadioButton
                                    inputId="rb1"
                                    name="diagparentStaus"
                                    value="1"
                                    onChange={e => this.onChangeUpdateParentStatus(e)}
                                    checked={updateTypeObject.parentStatus === "1"}
                                  />
                                  <label htmlFor="rb1" className="p-radiobutton-label">
                                    Yes
                                  </label>
                                </div>
                                <div className="radio-button-inside">
                                  <RadioButton
                                    inputId="rb2"
                                    name="diagparentStaus"
                                    value="0"
                                    onChange={e => this.onChangeUpdateParentStatus(e)}
                                    checked={updateTypeObject.parentStatus === "0"}
                                  />
                                  <label htmlFor="rb1" className="p-radiobutton-label">
                                    No
                                  </label>
                                </div>
                            </div>
                          </div>
                        </div>

                            {this.state.diagShow ? (
                              <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                  <span className="p-inputgroup-addon">Parent <span>*</span></span>
                                  <Dropdown
                                    placeholder="Select Parent"
                                    name="updateParent"
                                    options={parentValueOption}
                                    value={parentTypeInfoDTO.coreCategoryID}
                                    onChange={this.onChangeParentTypeInfoId}
                                    autoWidth={false}
                                    filter={true}
                                    showClear={true}
                                  />
                                </div>
                                <span className="error-message">{this.state.errors["updateParent"]}</span>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                              <Button
                                label="Discard"
                                icon="fas fa-times"
                                className="p-button p-button-danger nw-button nw-button-multiple"
                                onClick={this.updateDialogDiscard}
                              />
                              <Button
                                label="Update"
                                className="p-button p-button-primary nw-button nw-button-multiple"
                                icon="fas fa-check"
                                onClick={this.handleCategoryTypeUpdate}
                              />
                            </div>

                            </div>
                        </div>

                  </div>
                </Dialog>
        </div>
        {/* end dialog */}
      </div>
    );
  }
}
