import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';
import { UserCoreUrlInfoService } from '../UserCoreUrlInfoService';

export class AdmisiaPointService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
        this.userCoreUrlInfoService=new UserCoreUrlInfoService();
    }

    getUrlInfoFromLocalStorage(){
        const urlInfo=localStorage.getItem('admUrlInfo');
        if(urlInfo!=null){
            console.log('From Local', urlInfo);
            return urlInfo;
        }else{
            this.userCoreUrlInfoService.fetchInstituteDetails('admisia', 'adm-basic')
            .then((res)=>{
                if(res.status == 302){
                    return res.json().then((body) => {
                        console.log('From Server', body.urlInfoID);
                        localStorage.setItem('admUrlInfo',body.urlInfoID);
                        return body.urlInfoID;
                    })
                }else{
                    return null;
                }
            }).catch((error) => console.log('error', error));
        }
    }


    createAdmisiaCoreConfig(coreConfigInfo){
        let uri = this.baseURIHolder.getAdmisia() + '/core-config/new';
        return this.Auth.postFetch(uri, coreConfigInfo)
            .catch((error) => console.log('error', error));
    }

    getCoreConfigInfo(urlId) {
        let uri = this.baseURIHolder.getAdmisia() + '/core-config/?urlId=' + urlId;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateCoreConfigInfo(updateCoreConfig) {
        let uri = this.baseURIHolder.getAdmisia() + '/core-config/update';
        return this.Auth.putFetch(uri,updateCoreConfig)
            .catch((error) => console.log('error', error));
    }

    createAdmisiaClassConfig(requestedObject) {
       // console.log(requestedObject);
        let uri = this.baseURIHolder.getAdmisia() + '/class-config/new';
        return this.Auth.postFetch(uri, requestedObject)
        .catch((error) => console.log("error",error));
    }
    updateAdmisiaClassConfig(requestedObject) {
       // console.log(requestedObject);
        let uri = this.baseURIHolder.getAdmisia() + '/class-config/update';
        return this.Auth.putFetch(uri, requestedObject)
        .catch((error) => console.log("error",error));
    }
    getAdmisiaClassConfigList(requestedObject) {
        let uri = this.baseURIHolder.getAdmisia() + '/class-config/list';
        return this.Auth.postFetch(uri,requestedObject)
            .catch((error) => console.log('error', error));
    }

    getAdmisiaClassConfigListbyId(classConfigID) {
        let uri = this.baseURIHolder.getAdmisia() + '/class-config?classConfigID=' + classConfigID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    getClassAndGroupForApproval(urlId) {
        let uri = this.baseURIHolder.getAdmisia() + '/class-config/class/list?urlID=' + urlId;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }


    getApplicantInfoByRegNo(urlInfoID, regID) {
        let uri = this.baseURIHolder.getAdmisia() + `/applicant_info/by/reg_no?urlInfoID=${urlInfoID}&regID=${regID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }


    // getEdumanInstituteInfoByID(instituteID, urlID) {
    //     let uri = this.baseURIHolder.getUser() + `/get/institute/by/instituteID?instituteID=${instituteID}&urlID=${urlID}`;
    //     return this.Auth.getFetch(uri)
    //         .catch((error) => console.log('error', error));
    // }



    updateApplicantInfo(applicantInfo) {
        let uri = this.baseURIHolder.getAdmisia() + '/applicant_info/update';
        return this.Auth.putFetch(uri,applicantInfo)
            .catch((error) => console.log('error', error));
    }

    fetchApprovalList(urlName, requestedObject) {
        let uri = this.baseURIHolder.getAdmisia() + '/applicant_info/'+ urlName +'/list';
        return this.Auth.postFetch(uri, requestedObject)
            .catch((error) => console.log('error', error));
    }

    updateStudentAdmissionExamMarks(marksObj) {
        let uri = this.baseURIHolder.getAdmisia() + '/applicant_info/update/marks';
        return this.Auth.putFetch(uri,marksObj)
            .catch((error) => console.log('error', error));
    }

    updateForApproveAssessment(updateObj) {
        let uri = this.baseURIHolder.getAdmisia() + '/applicant_info/change/status';
        return this.Auth.putFetch(uri, updateObj)
            .catch((error) => console.log('error', error));
    }
    
    getApplicantAnalyticsReport(urlID,year) {
        let uri = this.baseURIHolder.getAdmisia() + `/applicant/report/analytics?urlID=${urlID}&year=${year}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getApplicantStatusList() {
        let uri = this.baseURIHolder.getAdmisia() + '/applicant/status/list';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    
    fetchApplicantCollectionAnalytics(urlID, year) {
        let uri = this.baseURIHolder.getAdmisia() + `/applicant/report/collection/analytics?urlID=${urlID}&year=${year}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getApplicantListByStatus(applicantListInfo) {
        let uri = this.baseURIHolder.getAdmisia() + '/applicant_info/admission/list';
        return this.Auth.postFetch(uri, applicantListInfo)
            .catch((error) => console.log('error', error));
    }

    getApplicantInfoByID(applicantInfoID) {
        let uri = this.baseURIHolder.getAdmisia() + `/applicant_info/by/id?applicantInfoID=${applicantInfoID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    fetchApplicantCollectionInfo(classAndGroupId, year) {
        let uri = this.baseURIHolder.getAdmisia() + `/applicant/report/individual/collection?classID=${classAndGroupId}&year=${year}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
    
    getApplicantListForGlance(academicYear, admUrlInfoID) {
        let uri = this.baseURIHolder.getAdmisia() + `/applicant/glance/list?academicYear=${academicYear}&urlInfoID=${admUrlInfoID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }




    getEdumanInstituteInfoByID(instituteID, urlID) {
        let uri = this.baseURIHolder.getUser() + `/get/institute/by/instituteID?instituteID=${instituteID}&urlID=${urlID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }



    getOtherInfoByID(applicantID) {
        let uri = this.baseURIHolder.getAdmisia() + `/applicant/other-info/list?applicantID=${applicantID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }


    getPreviousInfoByID(applicantID) {
        let uri = this.baseURIHolder.getAdmisia() + `/applicant/previous-exam/list?applicantID=${applicantID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }


    




    getURLMappedInstituteList(urlID) {
        let uri = this.baseURIHolder.getUser() + `/mapped/url/by/urlID?urlID=${urlID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    dwsCoreURLMapping(urlMappingInfo) {
        let uri = this.baseURIHolder.getUser() + '/map/url';
        return this.Auth.postFetch(uri, urlMappingInfo)
            .catch((error) => console.log("error", error));
    }

    // getEdumanInstituteInfoByID(instituteID) {
    //     let uri = this.baseURIHolder.getDWS() + `/get/institute/by/instituteID?instituteID=${instituteID}`;
    //     return this.Auth.getFetch(uri)
    //         .catch((error) => console.log('error', error));
    // }

    // getURLMappedInstituteList(urlID) {
    //     let uri = this.baseURIHolder.getDWS() + `/mapped/url/by/urlID?urlID=${urlID}`;
    //     return this.Auth.getFetch(uri)
    //         .catch((error) => console.log('error', error));
    // }

    
      downloadAllApplicantAdmitCard(classConfigID,status,urlInfoID) {
        let uri = this.baseURIHolder.getAdmisiaJasper() + `/applicants/admit-card/download?classConfigID=${classConfigID}&status=${status}&urlInfoID=${urlInfoID}`;
        return uri;
      }


      downloadAllApplicantSeatPlan(classConfigID,urlInfoID) {
        let uri = this.baseURIHolder.getAdmisiaJasper() + `/applicants/seat-plan/download?classConfigID=${classConfigID}&urlInfoID=${urlInfoID}`;
        return uri;
      }


      downloadAllApplicantExamAttendance(classConfigID,urlInfoID) {
        let uri = this.baseURIHolder.getAdmisiaJasper() + `/applicants/exam-attendance/download?classConfigID=${classConfigID}&urlInfoID=${urlInfoID}`;
        return uri;
      }


    sendSmsToAdmisiaApplicant(messageSendInfo) {
        let uri = this.baseURIHolder.getAdmisia() + '/applicant/send/sms';
        return this.Auth.postFetch(uri, messageSendInfo)
            .catch((error) => console.log('error', error));
    }

    getApplicantAssessmentListByStatus(applicantListInfo) {
        let uri = this.baseURIHolder.getAdmisia() + '/applicant_info/assessment/list';
        return this.Auth.postFetch(uri, applicantListInfo)
            .catch((error) => console.log('error', error));
    }

    applicantTransferToEduman(urlMappingId,applicantInfo) {
        let uri = this.baseURIHolder.getAdmisia() + `/applicant/transfer/to/eduman?urlMappingId=${urlMappingId}`;
        return this.Auth.postFetch(uri, applicantInfo)
            .catch((error) => console.log('error', error));
    }

    getStudentListByClassConfigID(classConfigId,instituteId) {
        let uri = this.baseURIHolder.getAdmisia() + `/student/list/get/by?classConfigId=${classConfigId}&instituteId=${instituteId}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getMaxRollByClassConfigId(academicYear,classConfigId,instituteId) {
        let uri = this.baseURIHolder.getAdmisia() + `/em-student/max-roll/by/class-config-id?academicYear=${academicYear}&classConfigId=${classConfigId}&instituteId=${instituteId}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }


    getPaymentInfo() {
        let uri = this.baseURIHolder.getAdmisia() + '/refund/request/approved';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }
}