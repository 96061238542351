import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from "primereact/growl";
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
let errors = {};
export class ProductStockInfoUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            stockUpdateObj: {
                stockQuantity: '',
                stockDate: '',
                stockNote: '',
                productInfoDTO:
                {
                    productID: ''
                }
            },
        }
        this.onChangeNewStock = this.onChangeNewStock.bind(this);
        // this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.submitUpdate = this.submitUpdate.bind(this);
        // this.hideStockUpdateDialog = this.hideStockUpdateDialog.bind(this);
        // this.fetchProductList = this.fetchProductList.bind(this);

        this.adminPointService = new AdminPointService();
    }

    onChangeNewStock(e) {
        errors["stockQuantity"] = "";
        let { stockUpdateObj } = this.state;
        stockUpdateObj.stockQuantity = e.target.value;
        this.setState({ stockUpdateObj, errors: errors })
    }

    onChangeDate = (e) => {
        errors["stockDate"] = "";
        let { stockUpdateObj } = this.state;
        stockUpdateObj.stockDate = e.target.value;

        // stockUpdateObj.stockDate = NetiDateUtils.getDateFromString(value);
        this.setState({ stockUpdateObj, errors: errors });
    }
    onChangeNote(value) {
        errors["stockNote"] = "";
        let { stockUpdateObj } = this.state;
        stockUpdateObj.stockNote = value;
        this.setState({ stockUpdateObj, errors: errors });
    }

    hideStockUpdateDialog() {
        let { stockUpdateObj } = this.state;
        stockUpdateObj.stockQuantity = '';
        stockUpdateObj.stockDate = '';
        stockUpdateObj.stockNote = '';
        this.setState({ stockUpdateObj });
        this.props.stockUpdateDialog();
    }

    fetchProductList() {
        this.props.productList();
    }

    submitUpdate() {

        let { stockUpdateObj } = this.state;
        stockUpdateObj.productInfoDTO.productID = this.props.rowDataInfo.productID;
        // stockUpdateObj.stockDate = NetiDateUtils.getDateFromString(stockUpdateObj.stockDate)
        if (this.handleError()) {
            this.adminPointService.updateProductStock(stockUpdateObj)
                .then(res => {
                    if (res.status == 201) {
                        this.fetchProductList();
                        this.hideStockUpdateDialog();
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error =>
                    this.setState({ errorMsgVisible: true, errorMsgBody: 'Please check your connection' })

                );
        }
    }

    updateDialogDiscard = () => {
        this.hideStockUpdateDialog();
    }


    handleError() {

        errors = {};
        let formIsValid = true;

        if (this.state.stockUpdateObj.stockQuantity == '') {
            formIsValid = false;
            errors["stockQuantity"] = "New Stock can't left empty.";
        }
        if (!this.state.stockUpdateObj.stockDate) {
            formIsValid = false;
            errors["stockDate"] = "Date can't left empty.";
        }
        if (this.state.stockUpdateObj.stockNote == '') {
            formIsValid = false;
            errors["stockNote"] = "Stock Note can't left empty.";
        } else {
            return formIsValid;
        }
        this.setState({ errors: errors });
    }

    render() {
        return (
            <div className="p-fluid">
                <div className="p-grid">
                    <Growl ref={(el) => this.growl = el} />
                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="nw-form-body">

                                
                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Product Type</span>
                                                <div className="nw-inputgroup-desc">
{this.props.rowDataInfo.productTypeInfoDTO && this.props.rowDataInfo.productTypeInfoDTO.categoryName}                                                </div>
                                            </div>
                                        </div>


                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Product Name</span>
                                                <div className="nw-inputgroup-desc">
{this.props.rowDataInfo.productName}                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Remaining Stock</span>
                                                <div className="nw-inputgroup-desc">
{this.props.rowDataInfo.remainingStock}                                                </div>
                                            </div>
                                        </div>


                                        <div className="p-col-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Remaining Stock</span>
                                                <div className="nw-inputgroup-desc">
{this.props.rowDataInfo.remainingStock}                                                </div>
                                            </div>
                                        </div>


                                        <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">New Stock <span>*</span></span>
                              <InputText
                                                placeholder='Enter Product New Stock'
                                                name="stockQuantity"
                                                keyfilter="pint"
                                                value={this.state.stockUpdateObj.stockQuantity}
                                                onChange={this.onChangeNewStock}
                                            />
                          </div>
                          <span className='error-message'>{this.state.errors["stockQuantity"]}</span>
                        </div>


                                    <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                              <span className="p-inputgroup-addon">Date <span>*</span></span>
                              <Calendar
                                                placeholder='Select Date'
                                                name="stockDate"
                                                value={this.state.stockUpdateObj.stockDate}
                                                onChange={this.onChangeDate}
                                                autoWidth={false}
                                                showIcon={true}
                                                yearRange="2010:2030"
                                                dateFormat="dd/mm/yy"
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                dateOnly="true"
                                            />
                          </div>
                          <span className='error-message'>{this.state.errors["stockDate"]}</span>
                        </div>

                                   

                                    <div className="p-col-12 p-xl-12">
                          <div className="p-inputgroup">
                              <span className="p-inputgroup-addon nw-inputtextarea-label">Stock Note <span>*</span></span>
                              <InputTextarea
                                                placeholder='Enter Note'
                                                name="stockNote"
                                                value={this.state.stockUpdateObj.stockNote}
                                                onChange={(e) => this.onChangeNote(e.target.value)}
                                                autoWidth={false}
                                            />
                          </div>
                          <span className='error-message'>{this.state.errors["stockNote"]}</span>
                        </div>

                                  

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            label="Discard"
                                            icon="fas fa-times"
                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                            onClick={this.updateDialogDiscard}
                                        />
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                            label="Update"
                                            onClick={event => this.submitUpdate(event)}
                                            icon="fas fa-check"
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}