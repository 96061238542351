import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ErrorMessageView } from '../common/ErrorMessageView';
import CommonFuctionality from '../common/CommonFuctionality';
import { Calendar } from 'primereact/calendar';
import { UserCategoryService } from '../../service/UserCategoryService';
import { CUSTOM_MESSAGE } from '../common/CustomMessage';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';

let errors = {};
export class UpdateEMBasicInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            basicInfo: {
                educationBoard:'',
                division:'',
            },
            instituteInfo:{

            },
            updateBasicInfo:{
                emDetailsId: '',
                instituteBoardCoreCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                instituteCategoryCoreCategoryInfoDTO:{
                    coreCategoryID: ''
                },
                instituteTypeCoreCategoryInfoDTO:{
                    coreCategoryID: ''
                },
                globalAreaCoreCategoryInfoDTO:{
                    coreCategoryID: ''
                },
                instituteContact: '',
                instituteEmail: '',
                agreementExpiryDate: '',
                dataSubmissionDate: '',
                handoverDate: '',
                formSerial: '',
            },
            instituteType: [],
            instituteCategory: [],
            educationBoard: [],
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            basicInfoDialogHide: false,
        }

        this.CommonFuctionality = new CommonFuctionality();
        this.UserCategoryService = new UserCategoryService();
        this.UserOtherAccessService = new UserOtherAccessService();
        this.NetiDateUtils = new NetiDateUtils();

    }

    getDropDownByDefaultCode = code =>{
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.UserCategoryService.fetchListByDefaultCode(code)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        if(code == "T106"){
                            this.setState({ educationBoard: body});
                        }
                        else if(code == "T105"){
                            this.setState({ instituteType: body});
                        }
                        else if(code == "T113"){
                            this.setState({ instituteCategory: body});
                        }
                        else if(code == "T10202"){
                            this.setState({ division: body});
                        }

                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: CUSTOM_MESSAGE.ERROR_CONNECTION_MSG });
            });
    }

    getDistrictListByCategoryID = code =>{
        this.setState({ districtDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.UserCategoryService.fetchListByParentCategoryID(code)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ districtDropDownList: body });
                        this.setState({ districtDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false })

                    });
                } else {
                    return res.json().then(errorMsg => {
                        this.setState({ districtDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Opps! Something went wrong' })
                    });
                }
            }).catch(error => {
                this.setState({ districtDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })

            });
        // this.onClearErrorMsg(e.target.name);
    }

    getUpazillaListByCategoryID = code =>{
        this.setState({ upazillaDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false })
    
        this.UserCategoryService.fetchListByParentCategoryID( code )
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ upazillaDropDownList: body });
                        this.setState({ upazillaDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ upazillaDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ upazillaDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' })
            });
    }

    

    basicInfoUpdateDialog = () => {
        let { basicInfoProps } = this.props

        this.setState({ basicInfoDialogHide: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
        

        this.setState({
            updateBasicInfo: {
                emDetailsId: basicInfoProps.emDetailsId,
                instituteBoardCoreCategoryInfoDTO: {
                    coreCategoryID: basicInfoProps.educationBoardID
                },
                instituteCategoryCoreCategoryInfoDTO:{
                    coreCategoryID: basicInfoProps.instituteCategoryID
                },
                instituteTypeCoreCategoryInfoDTO:{
                    coreCategoryID: basicInfoProps.instituteTypeID
                },
                division: basicInfoProps.divisionID,
                district: basicInfoProps.districtID,
                globalAreaCoreCategoryInfoDTO:{
                    coreCategoryID: basicInfoProps.areaID
                },
                instituteContact: basicInfoProps.institutePhone,
                instituteEmail: basicInfoProps.instituteEmail,
                agreementExpiryDate: basicInfoProps.agreementExpiryDate,
                dataSubmissionDate: basicInfoProps.dataSubmissionDate,
                handoverDate: basicInfoProps.handoverDate,
                formSerial: basicInfoProps.formSerial,
            }
        })

        this.getDropDownByDefaultCode("T106");  //Education Board
        this.getDropDownByDefaultCode("T105");  //Institute Type
        this.getDropDownByDefaultCode("T113");  //Institute Category
        this.getDropDownByDefaultCode("T10202");  //Division

        this.getDistrictListByCategoryID(basicInfoProps.divisionID);
        this.getUpazillaListByCategoryID(basicInfoProps.districtID);

    }

    basicInfoUpdateDialogHide = () => {
        this.setState({ basicInfoDialogHide: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    onChangeEducationBoard = (e) =>{
        let { updateBasicInfo } = this.state;
        updateBasicInfo.instituteBoardCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        errors["educationBoard"] = ''
        this.setState({ updateBasicInfo, errors });
    }

    onChangeInstituteCategory = (e) =>{
        let { updateBasicInfo } = this.state;
        
        updateBasicInfo.instituteCategoryCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        errors["instituteCategory"] = ''
        this.setState({ updateBasicInfo, errors });
    }

    onChangeInstituteType = (e) =>{
        let { updateBasicInfo } = this.state;
        console.log("e.target.value", e.target.value);
        
        updateBasicInfo.instituteTypeCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        errors["instituteType"] = ''
        this.setState({ updateBasicInfo, errors });
    }

    onChangeDivision = (e) =>{
        let { updateBasicInfo } = this.state;
        updateBasicInfo.division = e.target.value;
        errors["division"] = ''
        this.setState({ updateBasicInfo, errors });
        this.getDistrictListByCategoryID( e.target.value)
    }

    onChangeDistrict = (e) =>{
        let { updateBasicInfo } = this.state;
        updateBasicInfo.district = e.target.value;
        errors["district"] = ''
        this.setState({ updateBasicInfo, errors });
        this.getUpazillaListByCategoryID( e.target.value)
    }

    onChangeArea = (e) =>{
        let { updateBasicInfo } = this.state;
        
        updateBasicInfo.globalAreaCoreCategoryInfoDTO.coreCategoryID = e.target.value;
        errors["area"] = ''
        this.setState({ updateBasicInfo, errors });
    }

    onChangeInstituteContact = (e) =>{
        let { updateBasicInfo } = this.state;
        
        updateBasicInfo.instituteContact = e.target.value;
        errors["instituteContact"] = ''
        this.setState({ updateBasicInfo, errors });
    }

    onChangeInstituteEmail = (e) =>{
        let { updateBasicInfo } = this.state;
        
        updateBasicInfo.instituteEmail = e.target.value;
        errors["instituteEmail"] = ''
        this.setState({ updateBasicInfo, errors });
    }

    onChangeagreementExpiryDate = e =>{
        let { updateBasicInfo } = this.state;
        
        updateBasicInfo.agreementExpiryDate = e.target.value;
        errors["agreementExpiryDate"] = ''
        this.setState({ updateBasicInfo, errors });
    }

    onChangeDataSubmissionDate = e =>{
        let { updateBasicInfo } = this.state;
        
        updateBasicInfo.dataSubmissionDate = e.target.value;
        errors["dataSubmissionDate"] = ''
        this.setState({ updateBasicInfo, errors });
    }

    onChangehandoverDate = e =>{
        let { updateBasicInfo } = this.state;
        
        updateBasicInfo.handoverDate = e.target.value;
        errors["handoverDate"] = ''
        this.setState({ updateBasicInfo, errors });
    }

    onChangeFormSerial = e =>{
        let { updateBasicInfo } = this.state;
        
        updateBasicInfo.formSerial = e.target.value;
        errors["formSerial"] = ''
        this.setState({ updateBasicInfo, errors });
    }


    updateBasicInfoError = () =>{
        let { updateBasicInfo, errors } = this.state;
        let formIsValid = true;

        if (updateBasicInfo.instituteBoardCoreCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            errors["educationBoard"] = "Education Board can't left empty.";
        }
        if (updateBasicInfo.instituteCategoryCoreCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            errors["instituteCategory"] = "Institute Category can't left empty.";
        }
        if (updateBasicInfo.instituteTypeCoreCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            errors["instituteType"] = "Institute Type can't left empty.";
        }
        if (updateBasicInfo.division === '') {
            formIsValid = false;
            errors["division"] = "Division can't left empty.";
        }

        if (updateBasicInfo.district === '') {
            formIsValid = false;
            errors["district"] = "District can't left empty.";
        }
        if (updateBasicInfo.globalAreaCoreCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            errors["area"] = "Upazilla can't left empty.";
        }
        if (updateBasicInfo.instituteContact === '') {
            formIsValid = false;
            errors["instituteContact"] = "Institute Contact can't left empty.";
        }
        if (updateBasicInfo.instituteEmail === '') {
            formIsValid = false;
            errors["instituteEmail"] = "Institute Email Year can't left empty.";
        }
        if (updateBasicInfo.agreementExpiryDate === '') {
            formIsValid = false;
            errors["agreementExpiryDate"] = "Agreement Expiry Date Price can't left empty.";
        }
        if (updateBasicInfo.dataSubmissionDate === '') {
            formIsValid = false;
            errors["dataSubmissionDate"] = "Submission Date Expiry Date Price can't left empty.";
        }
        if (updateBasicInfo.handoverDate === '') {
            formIsValid = false;
            errors["handoverDate"] = "Hand Over Date Expiry Date Price can't left empty.";
        }
        if (updateBasicInfo.formSerial === '') {
            formIsValid = false;
            errors["formSerial"] = "Form Serial can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    updateBasicInfo = () =>{
        let { updateBasicInfo } = this.state

        updateBasicInfo.agreementExpiryDate = NetiDateUtils.getAnyShortForm(updateBasicInfo.agreementExpiryDate, 'YYYY-MM-DD');
        updateBasicInfo.dataSubmissionDate = NetiDateUtils.getAnyShortForm(updateBasicInfo.dataSubmissionDate, 'YYYY-MM-DD');
        updateBasicInfo.handoverDate = NetiDateUtils.getAnyShortForm(updateBasicInfo.handoverDate, 'YYYY-MM-DD');

        this.setState({ updateBasicInfo });

        console.log('updateBasicInfo', updateBasicInfo);

        
        if (this.updateBasicInfoError()) {
            this.setState({ topProgressBar: true });
            this.UserOtherAccessService.updateBasicDetailsInfo(updateBasicInfo)
                .then(res => {
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated!" });
                        this.setState({ topProgressBar: false  });
                        setTimeout(() => {
                            this.basicInfoUpdateDialogHide();
                        }, 800);
                    } else {
                        this.UserOtherAccessService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false , errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false , errorMsgVisible: true, errorMsgBody: "Please Check Your Connection !!!" })
                });
        }
        
    }

    

    




    render() {
        let { updateBasicInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;

        let educationBoardList = []
        if (this.state.educationBoard && this.state.educationBoard.length) {
            educationBoardList = this.state.educationBoard.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let instituteCategoryList = []
        if (this.state.instituteCategory && this.state.instituteCategory.length) {
            instituteCategoryList = this.state.instituteCategory.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
        
        let instituteTypeList = []
        if (this.state.instituteType && this.state.instituteType.length) {
            instituteTypeList = this.state.instituteType.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let divisionList = []
        if (this.state.division && this.state.division.length) {
            divisionList = this.state.division.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let districtList = []
        if (this.state.districtDropDownList && this.state.districtDropDownList.length) {
            districtList = this.state.districtDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let areaList = []
        if (this.state.upazillaDropDownList && this.state.upazillaDropDownList.length) {
            areaList = this.state.upazillaDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }
       


        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                    <Growl ref={(el) => this.growl = el} />
                    <Dialog header="Update Basic Information" visible={this.state.basicInfoDialogHide} onHide={this.basicInfoUpdateDialogHide} className="nw-dialog product-info-dialog">
                        <div className="p-fluid">
                            <div className="p-grid  nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-grid nw-form-body">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Education Board <span>*</span></span>
                                                <Dropdown
                                                    options={educationBoardList}
                                                    value={ updateBasicInfo.instituteBoardCoreCategoryInfoDTO && updateBasicInfo.instituteBoardCoreCategoryInfoDTO.coreCategoryID }
                                                    onChange={this.onChangeEducationBoard}
                                                    placeholder='Select education board'
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["educationBoard"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institite Category<span>*</span></span>
                                                <Dropdown
                                                    options={instituteCategoryList}
                                                    value={ updateBasicInfo.instituteCategoryCoreCategoryInfoDTO && updateBasicInfo.instituteCategoryCoreCategoryInfoDTO.coreCategoryID }
                                                    onChange={this.onChangeInstituteCategory}
                                                    placeholder='Select Institite Category'
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["instituteCategory"] }</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institite Type<span>*</span></span>
                                                <Dropdown
                                                    options={instituteTypeList}
                                                    value={ updateBasicInfo.instituteTypeCoreCategoryInfoDTO && updateBasicInfo.instituteTypeCoreCategoryInfoDTO.coreCategoryID }
                                                    onChange={this.onChangeInstituteType}
                                                    placeholder='Select institite type'
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["instituteType"] }</span>
                                        </div>
                                        
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institite Phone No.<span>*</span></span>
                                                <InputText
                                                    placeholder='Select Partner Type'
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                    value={ updateBasicInfo.instituteContact }
                                                    onChange={this.onChangeInstituteContact}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["instituteContact"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Institite Email <span>*</span></span>
                                                <InputText
                                                    placeholder='Select Partner Type'
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                    value={ updateBasicInfo.instituteEmail }
                                                    onChange={this.onChangeInstituteEmail}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["instituteEmail"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Division<span>*</span></span>
                                                <Dropdown
                                                    options={divisionList}
                                                    value={ updateBasicInfo.division }
                                                    onChange={this.onChangeDivision}
                                                    placeholder='Select division'
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["division"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">District<span>*</span></span>
                                                <Dropdown
                                                    options={ districtList }
                                                    value={ updateBasicInfo.district }
                                                    onChange={this.onChangeDistrict}
                                                    placeholder='Select district'
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["district"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Area<span>*</span></span>
                                                <Dropdown
                                                    options={ areaList }
                                                    value={ updateBasicInfo.globalAreaCoreCategoryInfoDTO && updateBasicInfo.globalAreaCoreCategoryInfoDTO.coreCategoryID }
                                                    onChange={this.onChangeArea}
                                                    placeholder='Select Area'
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["area"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Agreement Expire<span>*</span></span>
                                                <Calendar
                                                    placeholder='dd/mm/yy'
                                                    name="startDate"
                                                    showIcon={true}
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    value={ updateBasicInfo.agreementExpiryDate }
                                                    onChange={this.onChangeagreementExpiryDate}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["agreementExpiryDate"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Data Submission<span>*</span></span>
                                                <Calendar
                                                    placeholder='dd/mm/yy'
                                                    name="startDate"
                                                    showIcon={true}
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    value={ updateBasicInfo.dataSubmissionDate }
                                                    onChange={this.onChangeDataSubmissionDate}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["dataSubmissionDate"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Hand Over<span>*</span></span>
                                                <Calendar
                                                    placeholder='dd/mm/yy'
                                                    name="startDate"
                                                    showIcon={true}
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    value={ updateBasicInfo.handoverDate }
                                                    onChange={this.onChangehandoverDate}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["handoverDate"] }</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Form serial<span>*</span></span>
                                                <InputText
                                                    placeholder='0221323154100'
                                                    name='partnerType'
                                                    filterBy="value"
                                                    autoWidth={false}
                                                    filter={true}
                                                    value={ updateBasicInfo.formSerial }
                                                    onChange={this.onChangeFormSerial}
                                                />
                                            </div>
                                            <span className='error-message'>{ errors["formSerial"] }</span>
                                        </div>
                                        <div className="p-col-12 p-col-12  nw-button-parent-multiple">
                                            <Button
                                                className="p-button-danger nw-button nw-button-multiple"
                                                label="Discard"
                                                icon="fas fa-times"
                                                onClick={this.basicInfoUpdateDialogHide}
                                            // onClick={this.onResetInstitute}
                                            />

                                            <Button
                                                className="p-button-primary nw-button nw-button-multiple"
                                                label="Update"
                                                icon="fas fa-check"
                                                onClick={this.updateBasicInfo}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
            </div>


        )
    }
}