import React, {Component} from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import {EdumanOrder} from './EdumanOrder';
import {EdumanOrderList} from './EdumanOrderList';

export class EdumanOrderMain extends Component {

        
    render() {

        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView>
                            <TabPanel header="Eduman Order">
                                <EdumanOrder/>
                            </TabPanel>
                            <TabPanel header="Eduman Order List">
                                <EdumanOrderList/>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }
}