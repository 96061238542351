import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { ErrorMessageView } from '../../common/ErrorMessageView';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';

let dialogType = '';
let dialogHeader = '';
let cmsId = 0;

export class AdminBookInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            dataTableValue: [],
            classList: [],
            dialogVisible: false,
            requestedBodyObj: {
                "authorName": "",
                "bookName": "",
                "bookPrice": 0,
                "bookType": "",
                "classId": 0,
                "cmsId": 0,
                "publicationName": "",
                "publicationYear": "",
                'bookId': '',
            },
            deleteDialogVisible: false,
            selectedRowData: '',

        }

        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });

            this.getBookListTableData();
            this.getClassListData();
        }
        else { this.setState({ homeReturnButton: true }); }
    }

    getBookListTableData() {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.getBookList(cmsId)
            .then(res => {

                console.log('res-body', res);
                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('table-body', body);

                        if (body.messageType == 1) {
                            this.setState({ dataTableValue: body.item });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                        } else {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    }))
                }

            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });

    }

    getClassListData() {

        this.setState({ errorMsgVisible: false });
        this.DwsService.getClassList(cmsId)
            .then(res => {

                console.log('res-body', res);
                if (res.status == 200) {

                    return res.json().then((body => {

                        // console.log('body', body);

                        if (body.messageType == 1) {
                            this.setState({ classList: body.item });
                            this.setState({ errorMsgVisible: false });

                        } else {
                            this.setState({ errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    }))
                }

            }).catch((error) => {
                this.setState({ errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });

    }

    onChangeToDialogVisible = (source) => {
        if (source == "insert") { dialogHeader = "Add New Book"; dialogType = source; }
        else if (source == "update") { dialogHeader = "Update Book Info"; dialogType = source; }
        this.setState({ dialogVisible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    };

    onHideDialog = () => {

        this.setState({ dialogVisible: false });
        this.resetDialogValue();
        let { errors } = this.state;
        errors['classId'] = '';
        errors['bookType'] = '';
        errors['bookName'] = '';
        errors['authorName'] = '';
        errors['publicationName'] = '';
        errors['publicationYear'] = '';
        errors['bookPrice'] = '';
        this.setState({ errors })
        this.CommonFuctionality.blurDialogBackgroundDeActive();

    }

    resetDialogValue = () => {

        let requestedBodyObj = {
            "authorName": "",
            "bookName": "",
            "bookPrice": 0,
            "bookType": "",
            "classId": 0,
            "cmsId": Number(cmsId),
            "publicationName": "",
            "publicationYear": "",
            "bookId": '',
        }

        this.setState({ requestedBodyObj: requestedBodyObj });

    }

    setRowDataToUpdateDialog = (rowData) => {

        console.log('rowData', rowData);
        let requestedBodyObj = {
            "authorName": rowData.authorName,
            "bookName": rowData.bookName,
            "bookPrice": rowData.bookPrice,
            "bookType": rowData.bookType,
            "classId": rowData.classId,
            "cmsId": Number(cmsId),
            "publicationName": rowData.publicationName,
            "publicationYear": rowData.publicationYear,
            "bookId": Number(rowData.bookId)
        }

        this.setState({ requestedBodyObj: requestedBodyObj });
        this.onChangeToDialogVisible('update')

    }

    showDeleteDialog = (dialogType, rowData) => {
        this.setState({ deleteDialogVisible: true, selectedRowData: rowData });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onHideDeleteDialog = () => {
        this.setState({ deleteDialogVisible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    bodyActionTemplate = (rowData) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                tooltip="Update"
                onClick={(e) => this.setRowDataToUpdateDialog(rowData, 'update')}
            >
            </Button>

            <Button
                type="button"
                icon="fas fa-trash"
                tooltip="Delete"
                className="nw-action-button p-button-danger"
                onClick={(e) => this.showDeleteDialog('delete', rowData)}
            />
        </div>;
    }

    onChageInputValue = (e) => {

        let { requestedBodyObj } = this.state;
        requestedBodyObj[e.target.name] = e.target.value;

        this.setState({ requestedBodyObj })
        this.clearErrorMsg(e.target.name);
    }

    clearErrorMsg = (name) => {
        let { errors } = this.state;
        errors[name] = '';
        this.setState({ errors })
    }

    // onImageUpload = e => {
    //     var reader = new FileReader();
    //     let photo = e.files[0];
    //     const scope = this;
    //     reader.readAsDataURL(photo);
    //     reader.onload = function () {
    //         let content = reader.result;
    //         var keyw = "data:" + photo.type + ";base64,"; //link will be same from the word webapps in URL
    //         var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
    //         let album = {
    //             extention: photo.type,
    //             contentPic: urlStr,
    //             contentName: photo.name
    //         };
    //         scope.setState({ proPic: album });
    //     };
    // };

    onSubmitHandler = (dialogType) => {

        let { requestedBodyObj } = this.state;

        if (this.emptyHandleError()) {

            if (dialogType == 'insert') {

                requestedBodyObj.cmsId = cmsId;
                let { errors } = this.state;
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.DwsService.cmsSaveBook(requestedBodyObj)
                    .then(res => {
                        console.log('save-res', res);

                        res.json().then((body => {

                            // console.log('save-res-body', body);

                            if (body.messageType == 1) {
                                this.setState({ topProgressBar: false, errorMsgVisible: false });
                                this.onHideDialog();
                                this.getBookListTableData();
                                this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Book Info Successfully Saved' });
                            } else {
                                this.DwsService.Auth.handleErrorStatus(res)
                                    .then((res) => {
                                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: res })
                                    });
                            }
                        }))
                    })
                    .catch(error => {
                        this.setState({ errors: errors })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });

            } else if (dialogType == 'update') {

                // console.log('update obj', requestedBodyObj);

                let { errors } = this.state;
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.DwsService.cmsUpdateBookInfo(requestedBodyObj)
                    .then(res => {
                        console.log('update-res', res);
                        if (res.status == 202) {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                            this.onHideDialog();
                            this.getBookListTableData();
                            this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Book Info Successfully Updated' });
                        }

                    })
                    .catch(error => {
                        this.setState({ errors: errors })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });
            }
        }

    }

    emptyHandleError = () => {

        let { requestedBodyObj, errors } = this.state;
        let formIsValid = true;

        if (requestedBodyObj.classId === '' || requestedBodyObj.classId === 0) {
            formIsValid = false;
            errors["classId"] = "Class can't left empty.";
        }

        if (requestedBodyObj.bookType === '' || requestedBodyObj.bookType === null) {
            formIsValid = false;
            errors["bookType"] = "Book Type can't left empty.";
        }

        if (requestedBodyObj.bookName === '' || requestedBodyObj.bookName === null) {
            formIsValid = false;
            errors["bookName"] = "Book Name can't left empty.";
        }

        if (requestedBodyObj.authorName === '' || requestedBodyObj.authorName === null) {
            formIsValid = false;
            errors["authorName"] = "Author Name can't left empty.";
        }

        if (requestedBodyObj.publicationName === '' || requestedBodyObj.publicationName === null) {
            formIsValid = false;
            errors["publicationName"] = "Publication Name can't left empty.";
        }

        if (requestedBodyObj.publicationYear === '' || requestedBodyObj.publicationYear === null) {
            formIsValid = false;
            errors["publicationYear"] = "Publication Year can't left empty.";
        }

        // if (requestedBodyObj.bookPrice === '' || requestedBodyObj.bookPrice === 0) {
        //     formIsValid = false;
        //     errors["bookPrice"] = "Price can't left empty.";
        // }

        this.setState({ errors });
        return formIsValid;

    }

    deleteRow = () => {

        let { errors, selectedRowData } = this.state;
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.DwsService.cmsDeleteBook(selectedRowData.bookId)
            .then(res => {

                console.log('delete-res', res);

                if (res.status == 202) {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    this.onHideDeleteDialog();
                    this.getBookListTableData();
                    this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Book successfully deleted.' });
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((res) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: res })
                        });
                }
            })
            .catch(error => {
                this.setState({ errors: errors })
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    render() {

        let { requestedBodyObj, dataTableValue, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Book List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
                <Button
                    className="nw-button nw-button-center p-button-success"
                    name="district"
                    label="Add New"
                    icon="fas fa-plus"
                    iconPos="right"
                    onClick={() => this.onChangeToDialogVisible('insert')}
                />
            </div>
        </div>;

        let classListOptions = [];
        if (this.state.classList && this.state.classList.length) {
            classListOptions = this.state.classList.map(item => ({
                value: item.classId,
                label: item.className
            }));
        }

        const bookTypeOptions = [
            { label: "Board Book", value: "Board Book" },
            { label: "Additional Book", value: "Additional Book" }
        ];

        return (

            <div className="p-fluid">
                <NetiCMSSetupProgress />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">
                    {this.state.homeReturnButton === false ?

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column field="className" header="Class" filter={true} />
                                    <Column field="bookType" header="Book Type" filter={true} />
                                    <Column field="bookName" header="Book Name" filter={true} />
                                    <Column field="authorName" header="Author Name" filter={true} />
                                    <Column field="publicationName" header="Publication Name" filter={true} />
                                    <Column field="publicationYear" header="Publication Year" filter={true} />
                                    <Column field="action" header="Action" body={this.bodyActionTemplate} />
                                </DataTable>

                                : this.NetiContentLoader.MyPointTableLoader()
                            }

                        </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        header={dialogHeader}
                        visible={this.state.dialogVisible}
                        onHide={this.onHideDialog}
                        closable
                    >
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-grid nw-form-body">

                                                <div className="p-col-12 p-xl-12">
                                                    <label htmlFor="class">Class <span>*</span></label>
                                                    <Dropdown
                                                        placeholder="Select Class"
                                                        value={requestedBodyObj.classId}
                                                        options={classListOptions}
                                                        onChange={(e) => this.onChageInputValue(e)}
                                                        filter={true}
                                                        filterBy="label,value"
                                                        autoWidth={false}
                                                        name="classId"
                                                    />
                                                    <span className="error-message">
                                                        {this.state.errors["classId"]}
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-xl-6">
                                                    <label htmlFor="bookType">Book Type <span>*</span></label>
                                                    <Dropdown
                                                        value={requestedBodyObj.bookType}
                                                        placeholder="Select Book Type"
                                                        options={bookTypeOptions}
                                                        autoWidth={false}
                                                        onChange={this.onChageInputValue}
                                                        id="bookType"
                                                        name="bookType"
                                                    />
                                                    <span className="error-message">
                                                        {this.state.errors["bookType"]}
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-xl-6">
                                                    <label htmlFor="book">Book Name <span>*</span></label>
                                                    <InputText
                                                        value={requestedBodyObj.bookName}
                                                        placeholder="Enter Book Name"
                                                        id="bookName"
                                                        name="bookName"
                                                        onChange={this.onChageInputValue}
                                                    />
                                                    <span className="error-message">
                                                        {this.state.errors["bookName"]}
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-xl-6">
                                                    <label htmlFor="author">Author Name <span>*</span></label>
                                                    <InputText
                                                        value={requestedBodyObj.authorName}
                                                        placeholder="Enter Author Name"
                                                        id="authorName"
                                                        name="authorName"
                                                        onChange={this.onChageInputValue}
                                                    />
                                                    <span className="error-message">
                                                        {this.state.errors["authorName"]}
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-xl-6">
                                                    <label htmlFor="publicationName">Publication Name <span>*</span></label>
                                                    <InputText
                                                        value={requestedBodyObj.publicationName}
                                                        placeholder="Enter Publication Name"
                                                        autoWidth={false}
                                                        onChange={this.onChageInputValue}
                                                        id="publicationName"
                                                        name="publicationName"
                                                    />
                                                    <span className="error-message">
                                                        {this.state.errors["publicationName"]}
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-xl-6">
                                                    <label htmlFor="publicationYear">Publication Year <span>*</span></label>
                                                    <InputText
                                                        value={requestedBodyObj.publicationYear}
                                                        placeholder="Enter Publication Year"
                                                        id="publicationYear"
                                                        name="publicationYear"
                                                        onChange={this.onChageInputValue}
                                                        keyfilter="pint"
                                                    />
                                                    <span className="error-message">
                                                        {this.state.errors["publicationYear"]}
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-xl-6">
                                                    {/* <label htmlFor="bookPrice">Price <span>*</span></label> */}
                                                    <label htmlFor="bookPrice">Price <span></span></label>
                                                    <InputText
                                                        value={requestedBodyObj.bookPrice}
                                                        placeholder="Enter Book Price"
                                                        id="bookPrice"
                                                        keyfilter="pint"
                                                        name="bookPrice"
                                                        onChange={this.onChageInputValue}
                                                    />
                                                    <span className="error-message">
                                                        {this.state.errors["bookPrice"]}
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                                    <div className="required-field">(<span>*</span>) required fields</div>

                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-left"
                                                        label={dialogType == "insert" ? "Save" : "Update"}
                                                        icon="fas fa-check"
                                                        onClick={() => this.onSubmitHandler(dialogType)}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Dialog>
                </div>

                <Dialog header="Delete Book" visible={this.state.deleteDialogVisible} onHide={this.onHideDeleteDialog} className="nw-confirm-dialog">
                    <div className="p-fluid">
                        <div className="p-col-12 p-col-12">
                            <div className="confirm-wrapper">

                                <h1>Are You Sure?</h1>
                                <div className="nw-button-parent-multiple">
                                    <Button
                                        className="p-button-danger nw-button nw-button-multiple"
                                        label="No"
                                        icon="fas fa-times"
                                        onClick={this.onHideDeleteDialog}
                                    />
                                    <Button
                                        className="p-button-primary nw-button nw-button-multiple"
                                        label="Yes"
                                        icon="fas fa-check"
                                        onClick={(e) => this.deleteRow()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>

            </div>

        )
    }
}