import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from 'primereact/growl';
import { SupportPointService } from "../../../service/supportPoint/SupportPointService";
import { Dropdown } from "primereact/dropdown";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { UserOtherAccessService } from '../../../service/UserOtherAccessService';
import CommonFuctionality from "../../common/CommonFuctionality";
import { FileUpload } from 'primereact/fileupload';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { DownloadButton } from '../../../utils/DownloadButton';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';

let taggingTypeList = ['Eduman', 'Admisia', 'Withdraw']

export class CreateUserBankAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bankList: [],
      accountTaggingList: [],
      updateBranchID: "",
      bankTaggingInfo: {},
      taggingTypeErr: {},
      taggingType: '',
      bankName: "",
      chequeLeafFile: {
        extention: '',
        contentPic: '',
        contentName: '',
      },
      othersFile: {
        extention: '',
        contentPic: '',
        contentName: '',
      },
      defaultId: "",
      noteValue: "",
      district: '',
      branchId: '',
      branchList: [],
      branchInfo: {},
      branchName: '',
      accountNumber: '',
      accountHolder: '',
      districtList: [],
      accountType: '',
      accountDetails: '',
      dataTableValue: [],
      userBankDetails: {},
      errors: {},
      updateErrors: {},
      firstDropDownIsLoading: true,
      secondDropdownIsLoading: false,
      thirdDropdownIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',
    };

    this.fetchBankList = this.fetchBankList.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.actionBody = this.actionBody.bind(this);

    this.viewDialog = this.viewDialog.bind(this);
    this.onHide = this.onHide.bind(this);

    this.supportPointService = new SupportPointService();
    this.NetiContentLoader = new NetiContentLoader();
    this.userCategoryService = new UserCategoryService();
    this.commonFuctionality = new CommonFuctionality();
    this.userOtherAccessService = new UserOtherAccessService();
    this.netiFileHandler = new NetiFileHandler();

  }

  componentDidMount() {
    this.fetchBankList();
    this.fetchUserBankAccountList();
    this.getDistrictList();
  }

  getDistrictList() {
    this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
    this.userCategoryService.fetchListByDefaultCode('T10203')
      .then(res => {
        if (res.status == 302) {
          return res.json().then((body) => {
            this.setState({ districtList: body, topProgressBar: false, secondDropdownIsLoading: false });
          });
        } else {
          this.userCategoryService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
            });
        }
      }).catch(error => {
        this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
      });
  }

  fetchBankList() {
    this.setState({ firstDropDownIsLoading: true, topProgressBar: true, errorMsgVisible: false })
    this.userCategoryService.fetchListBy2ndParentTypeDefCode('T100')
      .then(res => {
        if (res.status === 302) {
          this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          return res.json().then((body) => { this.setState({ bankList: body }) })
        } else {
          return res.json().then((errorBody) => {
            this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorBody.message });
          })
        };
      }).catch(error => {
        this.setState({ firstDropDownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }

  onChangebankName = e => {
    this.setState({ bankName: e.target.value, errors: {} })
  };

  onChangeBranch = e => {
    this.setState({ branchName: e.target.value, errors: {} })
    if (this.state.branchList && this.state.branchList.length) {
      for (let i = 0; i < this.state.branchList.length; i++) {
        if (this.state.branchList[i].branchID === e.target.value) {
          this.setState({ routingNumber: this.state.branchList[i].routingNumber });
        }
      }
    }
  }

  onUpdateBranchID = (e) => {
    this.setState({ updateBranchID: e.target.value });
  }

  onChangeAccountNumber = e => {
    this.setState({ accountNumber: e.target.value, errors: {} })
  }

  onChangeAccountHolderName = e => {
    this.setState({ accountHolder: e.target.value, errors: {} })
  };

  onChangeAccountType = e => {
    this.setState({ accountType: e.target.value, errors: {} })
  };

  onChangeAccountDetails = e => {
    this.setState({ accountDetails: e.target.value, errors: {} })
  };

  onChangeDistrict = (e) => {
    this.setState({ district: e.target.value, errors: {} })
    this.fetchBankBranchesList(this.state.bankName, e.target.value);
  }

  onSubmitErrorHandle = () => {
    let { errors } = this.state;
    let formIsValid = true;

    if (this.state.bankName === '') {
      formIsValid = false;
      errors['bankName'] = "Bank Name can't left empty."
    }
    if (this.state.district === '') {
      formIsValid = false;
      errors['district'] = "District can't left empty."
    }
    if (this.state.branchName === '') {
      formIsValid = false;
      errors['branch'] = "Branch Name can't left empty."
    }
    if (this.state.accountHolder === '') {
      formIsValid = false;
      errors['accountHolder'] = "Account Holder can't left empty."
    }
    if (this.state.accountNumber === '') {
      formIsValid = false;
      errors['accountNumber'] = "Account Number Name can't left empty."
    }
    if (this.state.accountDetails === '') {
      formIsValid = false;
      errors['accountDetails'] = "Account Details can't left empty."
    }
    if (this.state.chequeLeafFile.contentName === '') {
      formIsValid = false;
      errors['attachment'] = "Cheque leaf file can't left empty."
    }

    // if (this.state.othersFile.contentName === '') {
    //   formIsValid = false;
    //   errors['othersAttachment'] = "Cheque leaf file can't left empty."
    // }

    this.setState({ errors });
    return formIsValid;
  }

  onChangeTaggingType = (e) => {
    this.setState({ taggingType: e.target.value });
  }

  onUpdateErrorHandle = () => {
    let { updateErrors } = this.state;
    let formIsValid = true;

    if (this.state.selectedRowData.bankName === '') {
      formIsValid = false;
      updateErrors['bankName'] = "Bank Name can't left empty."
    }
    if (this.state.selectedRowData.branchId === '') {
      formIsValid = false;
      updateErrors['branchId'] = "Branch ID can't left empty."
    }
    if (this.state.selectedRowData.branchName === '') {
      formIsValid = false;
      updateErrors['branchName'] = "Branch Name can't left empty."
    }
    if (this.state.selectedRowData.accountNumber === '') {
      formIsValid = false;
      updateErrors['accountNumber'] = "Account Number can't left empty."
    }
    if (this.state.selectedRowData.accountHolderName === '') {
      formIsValid = false;
      updateErrors['accountHolder'] = "Account Holder Name can't left empty."
    }
    if (this.state.selectedRowData.accountType === '') {
      formIsValid = false;
      updateErrors['accountType'] = "Account Type can't left empty."
    }
    if (this.state.selectedRowData.accountDetails === '') {
      formIsValid = false;
      updateErrors['accountDetails'] = "Account Details can't left empty."
    }

    this.setState({ updateErrors });
    return formIsValid;
  }

  actionBody(rowData) {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button edit"
          icon="fas fa-pencil-alt"
          title="Update"
          onClick={e => this.viewDialog(rowData)}
        />
      </div>
    );
  }

  viewDialog(rowData) {
    this.setState({ userBankDetails: rowData });
    this.getUserBankAccountTaggingInfo(rowData.userBankAccountID);
    this.setState({ visible: true });
  }

  onHide(event) {
    this.setState({ visible: false });
  }

  onSubmitHandler() {
    let { chequeLeafFile, othersFile } = this.state;
    if (this.onSubmitErrorHandle()) {
      this.setState({ topProgressBar: true, errorMsgVisible: false })
      let chequeFileExtention = this.netiFileHandler.getImageExtention(chequeLeafFile.extention);
      let othersFileExtention = this.netiFileHandler.getImageExtention(othersFile.extention);

      let requestedObj = {
        'coreCategoryInfoDTO': {
          'coreCategoryID': this.state.bankName
        },
        "bankAccNumber": this.state.accountNumber,
        "bankAccHolderName": this.state.accountHolder,
        "bankNote": this.state.accountDetails,

        "chequeSlipEditable": true,
        "chequeSlipName": Date.now() + chequeFileExtention,
        "chequeSlipContent": chequeLeafFile.contentPic,

        "othersAttachmentEditable": true,
        "othersAttachmentName": Date.now() + othersFileExtention,
        "othersAttachmentContent": othersFile.contentPic,

        "coreBankBranchInfoDTO": {
          "branchID": this.state.branchName
        }
      }
      this.userOtherAccessService.createUserBankAccount(requestedObj)
        .then(res => {
          if (res.status === 201) {
            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfuly submitted.' });
            this.setState({ bankName: '', accountNumber: '', accountHolder: '', branchName: '', accountDetails: '', });
            this.fetchUserBankAccountList();
            this.setState({ chequeLeafFile: { contentPic: '', contentName: '' } });
            this.setState({ othersFile: { contentPic: '', contentName: '' } });
            this.setState({ topProgressBar: false, errorMsgVisible: false })

          } else {
            this.userOtherAccessService.Auth.handleErrorStatus(res)
              .then((resp) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: resp })
              });
          }
        }).catch(error => {
          this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: 'Please check your connection.' })
        })
    }

  }

  fetchUserBankAccountList = () => {
    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    this.userOtherAccessService.findBankAccountByUser()
      .then(res => {
        if (res.status === 200) {
          return res.json().then((body) => {
            for (let i = 0; i < body.length; i++) {
              if (body[i].enableStatus === 1) {
                body[i].enableStatus = 'Active';
              } else if (body[i].enableStatus === 0) {
                body[i].chatboxDate = 'Inactive';
              }
            }
            this.setState({ dataTableValue: body })
            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          })
        } else {
          this.userOtherAccessService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
            });
        };
      }).catch(error => {
        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }

  getUserBankAccountTaggingInfo = (param) => {
    this.setState({ topProgressBar: true, errorMsgVisible: false });
    this.userOtherAccessService.userBankTaggingInfo(param)
      .then(res => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({ bankTaggingInfo: body })
            this.fetchBankBranchesList(body.bankID, body.districtID);
            this.setState({ topProgressBar: false, errorMsgVisible: false });
          })
        } else {
          this.userOtherAccessService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
            });
        };
      }).catch(error => {
        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }

  fetchBankBranchesList(param1, param2) {
    this.setState({ branchList: [], thirdDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    this.userOtherAccessService.findAllBankBranches(param1, param2)
      .then(res => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({ branchList: body })
            this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          })
        } else {
          this.userOtherAccessService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp });
            });
        };
      }).catch(error => {
        this.setState({ thirdDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }

  onUpdateBankBranch = () => {
    let { bankTaggingInfo } = this.state;
    this.setState({ topProgressBar: true, errorMsgVisible: false })
    // if (this.onUpdateErrorHandle()) {
    let requestedObj = {
      "userBankAccId": bankTaggingInfo.userBankAccountID,
      "coreBankBranchInfoDTO": {
        'branchID': this.state.updateBranchID
      }
    }
    this.userOtherAccessService.updateBankBranch(requestedObj)
      .then(res => {
        if (res.status === 202) {
          this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully updated.' });
          this.setState({ visible: false });
          this.setState({ topProgressBar: false, errorMsgVisible: false })
          this.fetchUserBankAccountList();
        } else {
          this.userOtherAccessService.Auth.handleErrorStatus(res)
            .then((resp) => {
              this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: resp })
            });
        };
      }).catch(error => {
        this.setState({ topProgressBar: false, errorMsgVisible: true, errorBody: 'Please check your connection.' })
      })
    // }
  }

  chequeLeafFileUpload(e) {
    let { errors } = this.state;
    errors['attachment'] = ''

    var reader = new FileReader();
    let photo = e.files[0];
    const scope = this
    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      let fileObj = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name
      };
      scope.setState({ chequeLeafFile: fileObj });
    }
  }

  othersFileUpload(e) {
    var reader = new FileReader();
    let photo = e.files[0];
    const scope = this
    reader.readAsDataURL(photo);
    reader.onload = function () {
      let content = reader.result;
      var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
      var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
      let fileObj = {
        extention: photo.type,
        contentPic: urlStr,
        contentName: photo.name
      };
      scope.setState({ othersFile: fileObj });
    }
  }

  chequeLeafemoveButtonOnclick = () => {
    this.setState({ chequeLeafFile: { contentPic: '' } });
    this.setState({ chequeLeafFile: { contentName: '' } });
  }

  othersRemoveButtonOnclick = () => {
    this.setState({ othersFile: { contentPic: '' } });
    this.setState({ othersFile: { contentName: '' } });

  }

  onChangeTagging = (e) => {
    let { bankTaggingInfo } = this.state;
    let selectedTaggingValue = [...bankTaggingInfo.taggingTypes];
    if (e.checked) {
      selectedTaggingValue.push(e.value);
    } else {
      selectedTaggingValue.splice(selectedTaggingValue.indexOf(e.value), 1);
    }
    bankTaggingInfo.taggingTypes = selectedTaggingValue
    this.setState({ bankTaggingInfo });
  }

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody, userBankDetails, bankTaggingInfo, dataTableValue } = this.state;
    let bankOptions = [];
    if (this.state.bankList && this.state.bankList.length) {
      bankOptions = this.state.bankList.map(item => ({ label: item.categoryName, value: item.coreCategoryID }))
    }

    let districtItemList = []
    if (this.state.districtList && this.state.districtList.length) {
      districtItemList = this.state.districtList.map((item) => ({
        value: item.coreCategoryID,
        label: item.categoryName,
      }));
    }

    let branchItemList = []
    if (this.state.branchList && this.state.branchList.length) {
      branchItemList = this.state.branchList.map((item) => ({
        value: item.branchID,
        label: item.branchName,
      }));
    }

    let headervalue = <div className="header-title">
      <div className="header-title-left">
        User Bank Account List
                            </div>
      <div className="header-title-right">
        <a>
          Total Found: {dataTableValue.length.toLocaleString("EN-IN")}
        </a>
      </div>
    </div>;

    return (
      <div className="p-fluid">
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <Growl ref={(el) => this.growl = el} />
        <div className="p-grid nw-form">
          <div className="p-col-12 p-xl-12">
            <div className="nw-form-body">

              <div className="p-col-12 p-xl-12">
                {this.state.firstDropDownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Bank Name <span>*</span></span>
                    <Dropdown
                      placeholder="Select Bank Name"
                      options={bankOptions}
                      onChange={this.onChangebankName}
                      value={this.state.bankName}
                      filter={true}
                      autoWidth={false}
                    />
                  </div>
                }
                <span className='error-message'>{this.state.errors['bankName']}</span>
              </div>

              <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                {this.state.secondDropdownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">District<span>*</span></span>
                    <Dropdown
                      placeholder="select district"
                      onChange={this.onChangeDistrict}
                      value={this.state.district}
                      options={districtItemList}
                      filter={true}
                      autoWidth={false}
                      name="district"
                      showClear={true}
                    />
                  </div>
                }
                <span className="error-message">
                  {this.state.errors["district"]}
                </span>
              </div>

              <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>
                {this.state.thirdDropdownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Branch<span>*</span></span>
                    <Dropdown
                      placeholder="select branch"
                      onChange={this.onChangeBranch}
                      value={this.state.branchName}
                      options={branchItemList}
                      filter={true}
                      autoWidth={false}
                      name="branch"
                      showClear={true}
                    />
                  </div>
                }
                <span className="error-message">
                  {this.state.errors["branch"]}
                </span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">Routing Number<span>*</span></span>
                  <InputText
                    type="text"
                    className='disable'
                    disabled
                    value={this.state.routingNumber}
                  />
                </div>
                <span className='error-message'>{this.state.errors['branchId']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">Account Holder Name <span>*</span></span>
                  <InputText
                    type="text"
                    placeholder="Enter Account Holder Name"
                    onChange={this.onChangeAccountHolderName}
                    value={this.state.accountHolder}
                    maxlength={120}
                  />
                </div>
                <span className='error-message'>{this.state.errors['accountHolder']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">Account Number <span>*</span></span>
                  <InputText
                    type="text"
                    placeholder="Enter Account Number"
                    onChange={this.onChangeAccountNumber}
                    value={this.state.accountNumber}
                    maxlength={50}
                  />
                </div>
                <span className='error-message'>{this.state.errors['accountNumber']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon nw-inputtextarea-label">Account Details <span>*</span></span>
                  <InputTextarea
                    type="text"
                    placeholder="Enter Account Details"
                    onChange={this.onChangeAccountDetails}
                    value={this.state.accountDetails}
                    maxlength={120}
                  />
                </div>
                <span className='error-message'>{this.state.errors['accountDetails']}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup nw-upload-button">
                  <span className="p-inputgroup-addon nw-inputtextarea-label">
                    Cheque Leaf Attachment <span>*</span>
                    <br />
                    <span>(PNG/JPG/PDF)</span>
                  </span>
                  <div className="nw-upload-button-inside">
                    {
                      this.state.chequeLeafFile.contentPic ?
                        <div className="image-view-main">
                          <div className="upload-image-view">
                            <Button
                              className="delete-upload-button"
                              icon="fas fa-times-circle"
                              onClick={this.chequeLeafemoveButtonOnclick}
                            />
                            {this.state.chequeLeafFile.contentName ?
                              this.commonFuctionality.getFileContentTypeIcon(this.state.chequeLeafFile.contentName) : ''}
                          </div>
                          <div className="image-title">{this.state.chequeLeafFile.contentName}</div>
                        </div>
                        :
                        <FileUpload
                          id="attachments"
                          mode="basic"
                          maxFileSize={1000000}
                          name="attachment"
                          onSelect={this.chequeLeafFileUpload.bind(this)}
                          auto={true}
                          chooseLabel={this.state.chequeLeafFile.contentName ? this.state.chequeLeafFile.contentName : "Click / Drag Here To Upload"}
                        />
                    }
                  </div>
                </div>
                <span className='error-message'>{this.state.chequeLeafFile.contentName === '' && this.state.errors["attachment"]}</span>
              </div>

              <div className="p-col-12 p-xl-12">
                <div className="p-inputgroup nw-upload-button">
                  <span className="p-inputgroup-addon nw-inputtextarea-label">
                    Others Attachment
                    <br />
                    <span>(PNG/JPG/PDF)</span>
                  </span>
                  <div className="nw-upload-button-inside">
                    {
                      this.state.othersFile.contentPic ?
                        <div className="image-view-main">
                          <div className="upload-image-view">
                            <Button
                              className="delete-upload-button"
                              icon="fas fa-times-circle"
                              onClick={this.othersRemoveButtonOnclick}
                            />
                            {this.state.othersFile.contentName ?
                              this.commonFuctionality.getFileContentTypeIcon(this.state.othersFile.contentName) : ''}
                          </div>
                          <div className="image-title">{this.state.othersFile.contentName}</div>
                        </div>
                        :
                        <FileUpload
                          id="attachments"
                          mode="basic"
                          maxFileSize={1000000}
                          name="othersAttachment"
                          onSelect={this.othersFileUpload.bind(this)}
                          auto={true}
                          chooseLabel={this.state.othersFile.contentName ? this.state.othersFile.contentName : "Click / Drag Here To Upload"}
                        />
                    }
                  </div>
                </div>
                {/* <center><span className='error-message'>{this.state.othersFile.contentName === '' && this.state.errors["othersAttachment"]}</span></center> */}
              </div>

              <div className="p-col-12 p-xl-12" />
              <div className="p-col-12 p-xl-12 nw-button-parent">
                <div className="required-field">
                  (<span>*</span>) required fields
                </div>
                <Button
                  className="p-button p-button-primary nw-button nw-button-right"
                  label="Save"
                  icon="fas fa-check"
                  onClick={this.onSubmitHandler}
                />
              </div>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-lg-3 p-xl-3"></div>

          <div className="p-col-12 p-xl-12">
            <div className="nw-data-table nw-data-table-tabview">
              {this.state.dataTableIsLoading ?
                this.NetiContentLoader.MyPointTableLoader() :
                <DataTable
                  header={headervalue}
                  value={this.state.dataTableValue}
                  responsive={true}
                  paginator={true}
                  rows={10}
                >
                  <Column field="bank" header="Bank Name" sortable={true} filter={true} />
                  <Column field="districtName" header="District" sortable={true} filter={true} />
                  <Column field="branchName" header="Branch" sortable={true} filter={true} />
                  <Column field="holderName" header="Account Holder" sortable={true} filter={true} />
                  <Column field="accNumber" header="Account Number" sortable={true} filter={true} />
                  <Column field="routingNumber" header="Routing Number" sortable={true} filter={true} />
                  <Column field="enableStatus" header="Status" sortable={true} filter={true} />
                  <Column header="Action" body={this.actionBody} style={{ width: "130px" }} />
                </DataTable>
              }
            </div>
          </div>

          <Dialog header="Update User Bank Account" className="nw-dialog" visible={this.state.visible} onHide={this.onHide}>

            <div className="p-fluid">
              <div className="p-grid nw-form">
                <div className="p-col-12">
                  <div class="nw-search-view no-border p-0">
                    <div>
                      <div class="p-col-12 p-xl-12">
                        <div class="p-inputgroup">
                          <span class="task-badge found"></span>
                          <span class="p-inputgroup-addon"> Bank Name </span>
                          <span class="p-inputgroup-colon">:</span>
                          <div class="nw-inputgroup-desc">{bankTaggingInfo.bank}</div>
                        </div>
                      </div>
                      <div class="p-col-12 p-xl-12">
                        <div class="p-inputgroup">
                          <span class="task-badge found"></span>
                          <span class="p-inputgroup-addon"> District </span>
                          <span class="p-inputgroup-colon">:</span>
                          <div class="nw-inputgroup-desc">{bankTaggingInfo.districtName}</div>
                        </div>
                      </div>

                      {/* <div class="p-col-12 p-xl-12">
                        <div class="p-inputgroup">
                          <span class="task-badge found"></span>
                          <span class="p-inputgroup-addon"> Branch </span>
                          <span class="p-inputgroup-colon">:</span>
                          <div class="nw-inputgroup-desc">{bankTaggingInfo.branchName}</div>
                        </div>
                      </div> */}

                      <div className="p-col-12 p-xl-12" style={{ paddingBottom: "0px" }}>

                      </div>
                      <div class="p-col-12 p-xl-12">
                        <div class="p-inputgroup">
                          <span class="task-badge found"></span>
                          <span class="p-inputgroup-addon"> Routing Number </span>
                          <span class="p-inputgroup-colon">:</span>
                          <div class="nw-inputgroup-desc">{bankTaggingInfo.routingNumber}</div>
                        </div>
                      </div>
                      <div class="p-col-12 p-xl-12">
                        <div class="p-inputgroup">
                          <span class="task-badge found"></span>
                          <span class="p-inputgroup-addon"> Account Holder </span>
                          <span class="p-inputgroup-colon">:</span>
                          <div class="nw-inputgroup-desc">{bankTaggingInfo.holderName}</div>
                        </div>
                      </div>
                      <div class="p-col-12 p-xl-12">
                        <div class="p-inputgroup">
                          <span class="task-badge found"></span>
                          <span class="p-inputgroup-addon"> Account Number </span>
                          <span class="p-inputgroup-colon">:</span>
                          <div class="nw-inputgroup-desc">{bankTaggingInfo.accNumber}</div>
                        </div>
                      </div>

                      <div class="p-col-12 p-xl-12">
                        <div class="p-inputgroup">
                          <span class="task-badge found"></span>
                          <span class="p-inputgroup-addon"> Account Details  </span>
                          <span class="p-inputgroup-colon">:</span>
                          <div class="nw-inputgroup-desc">{bankTaggingInfo.accountDetail}</div>
                        </div>
                      </div>

                      <div class="p-col-12 p-xl-12">
                        <div class="p-inputgroup">
                          <span class="task-badge found"></span>
                          <span class="p-inputgroup-addon"> Cheque Leaf Attachment</span>
                          <span class="p-inputgroup-colon">:</span>
                          <div className="nw-inputgroup-desc attachment-download-button" style={{ display: 'flex', alignItems: 'center' }}>
                            <DownloadButton fileName={bankTaggingInfo.chequeSlipName} filePath={bankTaggingInfo.chequeSlipPath} /> <span className="download-button-text">Cheque Slip</span>
                          </div>
                        </div>
                      </div>

                      <div class="p-col-12 p-xl-12">
                        <div class="p-inputgroup">
                          <span class="task-badge found"></span>
                          <span class="p-inputgroup-addon"> Others Attachment</span>
                          <span class="p-inputgroup-colon">:</span>
                          <div className="nw-inputgroup-desc attachment-download-button" style={{ display: 'flex', alignItems: 'center' }}>
                            <DownloadButton fileName={bankTaggingInfo.othersAttachmentName} filePath={bankTaggingInfo.othersAttachmentPath} /> <span className="download-button-text">Others Attachment</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="nw-form-body">
                    {this.state.thirdDropdownIsLoading ?
                      this.NetiContentLoader.normalFormInputField() :
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Branch<span>*</span></span>
                        <Dropdown
                          placeholder="Enter Branch"
                          onChange={this.onUpdateBranchID}
                          value={this.state.updateBranchID}
                          options={branchItemList}
                          filter={true}
                          autoWidth={false}
                          name="updateBranch"
                          showClear={true}
                        />
                      </div>
                    }
                    {/* <span className="error-message">
                  {this.state.errors["branch"]}
                </span> */}

                    <div className="p-col-12 p-xl-12" />
                    <div className="p-col-12 p-xl-12 nw-button-parent">
                      <div className="required-field">
                        (<span>*</span>) required fields
                </div>
                      <Button
                        className="p-button p-button-primary nw-button nw-button-right"
                        label="Update"
                        icon="fas fa-check"
                        onClick={this.onUpdateBankBranch}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
