import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { PartnerPointService } from '../../../../service/partnerPoint/PartnerPointService';
import { PARTNER_POINT } from '../../../../utils/PointWiseRouteConsts';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import Formatter from '../../../../utils/Formatter';


let sumOfInstitute = 0;
let totalActiveInstitute = 0;
let totalInactiveInstitute = 0;
let totalActiveRate = 0;
let totalInactiveRate = 0;
let totalActiveDue = 0;
let totalInactiveDue = 0;

export class PartnerInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partnerAssignInfo:{},
            dataTableValue: [],
            id:{},
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,
            partner: ''
        }

        this.PartnerPointService = new PartnerPointService;
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
        this.Formatter = new Formatter();
    }

    componentDidMount() {
       
        if (this.props.location.searchObj) {

            let partnerAssignID = this.props.location.searchObj.partnerAssignID;
            localStorage.setItem("partnerAssignID", JSON.stringify(partnerAssignID));
        }

        if (this.props.location.tableObj) {
            
            let dynamicDefaultCode = this.props.location.tableObj.dataTableValue[0].partnerDesignationCode;
            console.log("dynamicDefaultCode", dynamicDefaultCode);
            localStorage.setItem("dynamicDefaultCode", JSON.stringify(dynamicDefaultCode));
            this.partnerID();
        }

        this.fetchPartnerInfo();
    }


    partnerID = () => {

        let dynamicDefaultCode = JSON.parse(localStorage.getItem("dynamicDefaultCode"));
        if (!dynamicDefaultCode) {
            return;
        }

        if (dynamicDefaultCode === "T1120205"){
            
            return dynamicDefaultCode;

        } else {
            let {id} = this.state;
            let defaultCode =  dynamicDefaultCode.substring(7);
            id = parseInt(defaultCode) + 1;
            let partnerDefaultCode = dynamicDefaultCode.substring(0,7);
            let finalDefaultCode = partnerDefaultCode + id;
            return finalDefaultCode;
        }
        
    }



    fetchPartnerInfo = () => {

        totalActiveInstitute = 0;
        totalInactiveInstitute = 0;
        totalActiveDue = 0;
        totalInactiveDue = 0;

        let partnerAssignID = JSON.parse(localStorage.getItem("partnerAssignID"));
        if (!partnerAssignID) {
            return;
        }

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.PartnerPointService.fetchPartnerInfoList(this.partnerID(), partnerAssignID) 
        .then((res) => {
            
            if (res.status == 302) {

                return res.json().then((body) => {
                    console.log("BDP", body);
                    
                    this.setState({
                        
                        dataTableValue: body.instituteReportDTOs,
                        partnerAssignInfo: body.partnerAssignInfoDTO,
                        partner: !body.instituteReportDTOs[0].partnerType ? "" : body.instituteReportDTOs[0].partnerType,
                        
                    });

                    sumOfInstitute = body.instituteReportDTOs.map(item => item.totalInstitute).reduce((a, b) => (a + b));
                    totalActiveInstitute = body.instituteReportDTOs.map(item => item.activeInstitute).reduce((a, b) => (a + b));
                    totalInactiveInstitute = body.instituteReportDTOs.map(item => item.inActiveInstitute).reduce((a, b) => (a + b));
                    totalActiveRate = body.instituteReportDTOs.map(item => item.activeRate).reduce((a, b) => (a + b));
                    totalInactiveRate = body.instituteReportDTOs.map(item => item.inActiveRate).reduce((a, b) => (a + b));
                    totalActiveDue = body.instituteReportDTOs.map(item => item.activePayble).reduce((a, b) => (a + b));
                    totalInactiveDue = body.instituteReportDTOs.map(item => item.inActivePayble).reduce((a, b) => (a + b));


                    this.setState({ topProgressBar: false, dataTableIsLoading: false });

                });
            } else {
                this.PartnerPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection!!!' })

        );

    }

    paidDetailsBody(rowData, column) {

        console.log("data found", rowData);
           
        if (rowData.partnerDesignationCode=== "T1120205") {
            return <div className='text-center'>
            <Link to={{
                pathname: PARTNER_POINT.PARTNER_BEP_DUE_STATEMENT,
                searchObj: { ...rowData }
            }}>
                <i class="fas fa-arrow-circle-right fa-2x"></i>
                <u>{rowData.dataTableValue}</u>
            </Link>
        </div>
        
        } else {
            return <div className='text-center'>
                <Link to={{
                    pathname: PARTNER_POINT.PARTNER_BDP_DUE_STATEMENT,
                    searchObj: { ...rowData }
                }}>
                    <i class="fas fa-arrow-circle-right fa-2x"></i>
                    <u>{rowData.dataTableValue}</u>
                </Link>
            </div>

        }

    }


    bdpActiveInstituteBody(rowData) {
        return (<div>
            <div className="text-success">{rowData.activeInstitute} {"[" + this.Formatter.getFixedDecimalNumber(rowData.activeInstitutePercentage) + "%" + "]"} {"[" + this.validatorUtility.currencyFormatter(rowData["activeRate"]) + "]"  }</div> 
   
            <div className="text-danger">{rowData.inActiveInstitute} {"[" +  this.Formatter.getFixedDecimalNumber(rowData.inActiveInstitutePercentage) + "%" + "]"} {"[" + this.validatorUtility.currencyFormatter(rowData["inActiveRate"]) + "]"}</div>

        </div>) 
    }

    bdpDueActiveInstituteBody(rowData) {
        return <span >
        
            <span className="text-success">{this.validatorUtility.currencyFormatter(rowData["activePayble"])}</span>
            <br/>
            <span className="text-danger">{this.validatorUtility.currencyFormatter(rowData["inActivePayble"])}</span> 

        </span>;
    }

    sumOfActiveInactive() {
        return<span>

            <span className="text-success">{totalActiveInstitute + "[" + this.Formatter.getFixedDecimalNumber(this.totalActiveInstitutePercentageInfo()) + "%" + "]" + "[" + this.validatorUtility.currencyFormatter(totalActiveRate) + "]" }</span>
            <br/>
            <span className="text-danger">{totalInactiveInstitute + "[" + this.Formatter.getFixedDecimalNumber(this.totalInactiveInstitutePercentageInfo()) + "%" + "]" + "[" + this.validatorUtility.currencyFormatter(totalInactiveRate) + "]"}</span>

        </span>

    }

    sumOfActiveInactiveDue() {
        return<span>

            <span className="text-success">{this.validatorUtility.currencyFormatter(totalActiveDue)}</span> 
            <br/>
            <span className="text-danger">{this.validatorUtility.currencyFormatter(totalInactiveDue)}</span>

       </span>

    }

    partnerDetailInfo(rowData) {
        return <span>
            <span style={{color: "blue"}}>{rowData.partnerMobile}</span>  <br/>
            <span style={{color: "#bb14bbc9"}}>{rowData.areaName}</span>
        </span>
    }

    totalActiveInstitutePercentageInfo() {
        console.log("TotalActive", totalActiveInstitutePercentage);
    
        //  let totalActiveInstitutePercentage = (totalActiveInstitute / sumOfInstitute) * 100
        //  return totalActiveInstitutePercentage;

        let totalActiveInstitutePercentage = (totalActiveInstitute / sumOfInstitute) * 100
        if(totalActiveInstitutePercentage) {
            return isNaN(totalActiveInstitutePercentage) ? 0 : totalActiveInstitutePercentage;      
        }
      
    }

    totalInactiveInstitutePercentageInfo() {
        // console.log("Total", totalInactiveInstitutePercentage);
    
        //  let totalInactiveInstitutePercentage = (totalInactiveInstitute/ sumOfInstitute) * 100
        //  return totalInactiveInstitutePercentage;

         console.log("Total", totalInactiveInstitutePercentage);

         let totalInactiveInstitutePercentage = (totalInactiveInstitute / sumOfInstitute) * 100
         if(totalInactiveInstitutePercentage) {
             return isNaN(totalInactiveInstitutePercentage) ? 0 : totalInactiveInstitutePercentage;
         }
      
    }


    render() {
       
        let { partner, partnerAssignInfo, topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, rowDataObj } = this.state;
        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Total {partner} Found: {dataTableValue.length}<span style={{ 'float': 'right' }}> </span></div>;


        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={3} />
                <Column footer={sumOfInstitute} />
                <Column footer={this.sumOfActiveInactive()} />
                <Column footer={this.sumOfActiveInactiveDue()} />
                <Column footer="" colSpan={1} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="card nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                <div>
                                    <div>Neti Id</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo.userBasicInfoDTO ? partnerAssignInfo.userBasicInfoDTO.customNetiID : ''}</div>
                                </div>

                                <div>
                                    <div>Name</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo.userBasicInfoDTO ? partnerAssignInfo.userBasicInfoDTO.fullName : ''}</div>
                                </div>

                                <div>
                                    <div>Mobile No</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo.userBasicInfoDTO ? partnerAssignInfo.userBasicInfoDTO.basicMobile : ''}</div>
                                </div>

                                <div>
                                    <div>Area</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo.partnerAreaInfoDTO ? partnerAssignInfo.partnerAreaInfoDTO.categoryName : '' }</div>
                                </div>

                            </div>


                            <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                <div>
                                    <div>Type</div>
                                    <div>:</div>
                                    <div>{partnerAssignInfo.partnerDesignationInfoDTO ? partnerAssignInfo.partnerDesignationInfoDTO.categoryName : '' } </div>
                                </div>

                                <div>
                                    <div>Team Member</div>
                                    <div>:</div>
                                    <div>{dataTableValue.length}</div>
                                </div>

                                <div>
                                    <div>Total Institute</div>
                                    <div>:</div>
                                    <div>{sumOfInstitute}</div>
                                </div>


                            </div>


                            <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                <div>
                                    <div>Active Institute</div>
                                    <div>:</div>
                                    <div>{totalActiveInstitute} </div>
                                </div>

                                <div>
                                    <div>Active Bill</div>
                                    <div>:</div>
                                    <div>{this.validatorUtility.currencyFormatter(totalActiveDue)}</div>
                                </div>

                                <div>
                                    <div>Inactive Institute</div>
                                    <div>:</div>
                                    <div>{totalInactiveInstitute}</div>
                                </div>

                                <div>
                                    <div>Inactive Bill</div>
                                    <div>:</div>
                                    <div>{this.validatorUtility.currencyFormatter(totalInactiveDue)}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="nw-data-table">
                    {
                        this.state.dataTableIsLoading ?
                            this.NetiContentLoader.MyPointTableLoader()
                            :
                            <DataTable
                                header={header}
                                footerColumnGroup={footer}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                value={this.state.dataTableValue}
                            //selection={this.state.dataTableSelection}
                            >
                                <Column field="partnerNetiID" header="Neti ID" filter={true} />
                                <Column field="partnerName" header="Name" filter={true} style= {{width:"250px"}}/>
                                <Column field="" header="Mobile No/Area" body={this.partnerDetailInfo.bind(this)} filter={true} />
                                <Column field="totalInstitute" header="Total Institute" style= {{width:"100px"}}/>
                                <Column field="" header="Active / Inactive" body={this.bdpActiveInstituteBody.bind(this)} />
                                <Column field="" header="Active Due / Inactive Due" body={this.bdpDueActiveInstituteBody.bind(this)} />
                                <Column field="details" header="Details" body={this.paidDetailsBody} style= {{width:"100px"}} />
                            </DataTable>
                    }


                </div>


            </div>
        )
    }
}