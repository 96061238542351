import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { StudentClassConfigInfo } from '../common/StudentClassConfigInfo';
import { Panel } from 'primereact/panel';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';


export class Subject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subjectList: '',
            errorMsgBody: '',
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false
        }
        this.studentPortal = new StudentPortal();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {

        this.getStudentSubjectInfo();


    }


    getStudentSubjectInfo() {
        let requestobject = this.props.basicInfo;
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.studentPortal.fetchStudentSubjectList(requestobject).then(res => {
            if (res.status == 200) {
                return res.json().then((body) => {
                    this.setState({ subjectList: body.item });
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false })
                });
            } else {
                this.studentPortal.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error =>
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' }));

    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
                        <div className="header-title-left">
                            Subject List  
                        </div>
                        <div className="header-title-right">
                            <a>
                                Total Subject Found: {this.state.subjectList && this.state.subjectList.length || 0}
                            </a>
                        </div>
                    </div>;

        return (
            <div className="p-col-12 p-col-nogutter">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid nw-form">
                    <div className="p-col-12">
                        <StudentClassConfigInfo basicInfo={this.props.basicInfo} />
                    </div>

                    <div className="p-col-12 p-lg-12 p-xl-12">
                        {this.state.dataTableIsLoading ?
                            this.NetiContentLoader.MyPointTableLoader() :
                            this.state.subjectList ?
                                <div className="nw-data-table">
                                    <DataTable
                                        header={tableHeader}
                                        value={this.state.subjectList}
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="subjectName" header="Subject Name" sortable={true} filter={true} />
                                        <Column field="subjectType" header="Subject Type" sortable={true} filter={true} />

                                    </DataTable>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}