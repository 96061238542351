import React, { Component } from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Growl } from "primereact/growl";
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import NetiContentLoader from '../../../src/components/common/NetiContentLoader';
export class BijoyDeviceIdReplace extends Component {

  constructor() {
    super();
    this.state = {
      visible: false,
      purchaseCode: '',
      newDeviceID: '',
      searchBtnError: {},
      saveBtnError: {},
      bijoyDeviceDetails: {
        replaceLogList: []
      },
      replaceDeviceIDObj: {
        currentDeviceID: '',
        bijoyUnlockLogDTO: {
          unlockLogID: ''
        }
      },
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',
      searchIsLoading: false,
      dataTableIsLoading: false,
      searchErrorMsgBody: '',
      searchView: true,
      searchViewError: false,
    };
    this.supportPointService = new SupportPointService();
    this.NetiContentLoader = new NetiContentLoader();
  }

  onChangePurchaseCode = (e) => {
    this.setState({
      purchaseCode: e.target.value,
      searchBtnError: {}
    });
  }

  onSearchHandler = () => {
    let { bijoyDeviceDetails } = this.state;
    bijoyDeviceDetails = { replaceLogList: [] }
    this.setState({ bijoyDeviceDetails })

    if (this.searchPurchaseCodeError()) {
      this.setState({ topProgressBar: true, searchView: true, searchIsLoading: true, errorMsgVisible: false, searchViewError: false, dataTableIsLoading: true });

      this.supportPointService.getDeviceInfoByPurchaseCode(this.state.purchaseCode)
        .then(res => {
          this.setState({ topProgressBar: false, searchIsLoading: false, dataTableIsLoading: false });
          if (res.status == 200) {
            return res.json().then((body) => {
              this.setState({ bijoyDeviceDetails: body });
            });
          } else {
            this.supportPointService.Auth.handleErrorStatus(res)
              .then((responseBody) => {
                this.setState({ searchViewError: true, searchErrorMsgBody: 'Device Information not Found with specified purchase code' })
              });
          }
        }).catch(error => {
          this.setState({ topProgressBar: false, searchIsLoading: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
        });

    }

  }

  onChangeNewDeviceID = (e) => {
    let { replaceDeviceIDObj } = this.state;
    replaceDeviceIDObj.currentDeviceID = e.target.value;
    this.setState({ replaceDeviceIDObj });
  }

  onSubmitNewDeviceID = () => {
    this.setState({ topProgressBar: true });
    let { replaceDeviceIDObj, bijoyDeviceDetails } = this.state;
    replaceDeviceIDObj.purchaseCode = bijoyDeviceDetails.purchaseCode;
    replaceDeviceIDObj.replacedQuantity = bijoyDeviceDetails.replaceTimes;
    replaceDeviceIDObj.bijoyUnlockLogDTO.unlockLogID = bijoyDeviceDetails.unlockLogID;
    this.setState({ replaceDeviceIDObj });
    if (this.submitNewDeviceIDError()) {
      this.supportPointService.saveReplaceDeviceLog(replaceDeviceIDObj)
        .then(res => {
          this.setState({ topProgressBar: false });
          if (res.status == 201) {
            this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Changed!" });
            this.setState({ visible: false });
          } else {
            this.supportPointService.Auth.handleErrorStatus(res)
              .then((resBody) => {
                this.setState({ errorMsgVisible: true, errorMsgBody: resBody })
              });
          }
        })
    }

  }

  searchPurchaseCodeError = () => {
    let { searchBtnError } = this.state;
    let formIsValid = true;
    this.setState({ topProgressBar: false, searchViewError: false });

    if (this.state.purchaseCode === '') {
      formIsValid = false;
      searchBtnError["purchaseCode"] = "Purchase Code can not left empty.";
    }

    this.setState({ searchBtnError });
    return formIsValid;
  }

  submitNewDeviceIDError = () => {
    let { saveBtnError, replaceDeviceIDObj } = this.state;
    this.setState({ topProgressBar: false });
    let formIsValid = true;

    if (replaceDeviceIDObj.currentDeviceID === '') {
      formIsValid = false;
      saveBtnError["deviceID"] = "Device ID can not left empty.";
    }

    this.setState({ saveBtnError });
    return formIsValid;

  }

  onClick = () => {
    this.setState({ visible: true });
  }

  onHide = () => {
    this.setState({ visible: false });
  }

  replaceDateFormat = (rowData) => {
    return <span>{NetiDateUtils.getAnyShortForm(rowData['replacedDate'], 'DD-MMM-YYYY')}</span>;

  }

  focusSearch = e =>{
    this.setState({
        searchLabel:"Search",
        addSearchBtnClass:"nw-button p-button-primary text-color-white"
    });
}

blurSearch = e =>{
    this.setState({
        searchLabel:"",
        addSearchBtnClass:""
    });
}


  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

    return (
      <div className="p-fluid">
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <Growl ref={el => (this.growl = el)} />
        <div className="p-grid">
          <div className="p-col-12 p-xl-12">
              <div className="p-grid nw-form">
                <div className="p-col-12 p-xl-12">

                  <div className="p-grid nw-form-body">

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Purchase Code <span>*</span>
                        </span>
                        <InputText
                          placeholder="Enter Purchase Code"
                          keyfilter="pint"
                          name="purchaseCode"
                          value={this.state.purchaseCode}
                          onChange={this.onChangePurchaseCode}
                          autowidth={"false"}
                          style={{ width:"15%" }}
                          onFocus={this.focusSearch}
                          onBlur={this.blurSearch}
                        />
                        <Button
                          className={"p-button-animation " + this.state.addSearchBtnClass}
                          label={this.state.searchLabel}
                          onClick={this.onSearchHandler}
                          icon="fas fa-search"
                          onFocus={this.focusSearch}
                          onBlur={this.blurSearch}
                        />
                      </div>
                      <span className="error-message">{this.state.searchBtnError["purchaseCode"]}</span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      {this.state.searchView ?
                        <div className="nw-search-view">
                          {this.state.searchViewError ?
                            <center className="error-message">{this.state.searchErrorMsgBody || 'No Data Found'}</center> :
                            <div>
                              <div className="p-col-12 p-xl-12">
                                {this.state.searchIsLoading ?
                                  this.NetiContentLoader.normalFormInputField() :
                                  <div className="p-inputgroup">
                                    <span className="task-badge found"></span>
                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                      Product Name
                                </span>
                                    <span className="p-inputgroup-colon">:</span>
                                    <div className="nw-inputgroup-desc">
                                      {this.state.bijoyDeviceDetails.productName || '-'}
                                    </div>
                                  </div>
                                }
                              </div>

                              <div className="p-col-12 p-xl-12">
                                {this.state.searchIsLoading ?
                                  this.NetiContentLoader.normalFormInputField() :
                                  <div className="p-inputgroup">
                                    <span className="task-badge found"></span>
                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                      Purchase Code
                                </span>
                                    <span className="p-inputgroup-colon">:</span>
                                    <div className="nw-inputgroup-desc">
                                      {this.state.bijoyDeviceDetails.purchaseCode || '-'}
                                    </div>
                                  </div>
                                }
                              </div>

                              <div className="p-col-12 p-xl-12">
                                {this.state.searchIsLoading ?
                                  this.NetiContentLoader.normalFormInputField() :
                                  <div className="p-inputgroup">
                                    <span className="task-badge found"></span>
                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                      Purchase Date
                                </span>
                                    <span className="p-inputgroup-colon">:</span>
                                    <div className="nw-inputgroup-desc">
                                      {this.state.bijoyDeviceDetails.purchaseDate ? NetiDateUtils.getAnyShortForm(this.state.bijoyDeviceDetails.purchaseDate, 'DD-MMM-YYYY') : '-'}
                                    </div>
                                  </div>
                                }
                              </div>

                              <div className="p-col-12 p-xl-12">
                                {this.state.searchIsLoading ?
                                  this.NetiContentLoader.normalFormInputField() :
                                  <div className="p-inputgroup">
                                    <span className="task-badge found"></span>
                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                      Activation Date
                                </span>
                                    <span className="p-inputgroup-colon">:</span>
                                    <div className="nw-inputgroup-desc">
                                      {this.state.bijoyDeviceDetails.activationDate ? NetiDateUtils.getDateFromString(this.state.bijoyDeviceDetails.activationDate, 'DD-MMM-YYYY') : '-'}
                                    </div>
                                  </div>
                                }
                              </div>

                              <div className="p-col-12 p-xl-12">
                                {this.state.searchIsLoading ?
                                  this.NetiContentLoader.normalFormInputField() :
                                  <div className="p-inputgroup">
                                    <span className="task-badge found"></span>
                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                      Device Id
                                </span>
                                    <span className="p-inputgroup-colon">:</span>
                                    <div className="nw-inputgroup-desc">
                                      {this.state.bijoyDeviceDetails.deviceID || '-'}
                                    </div>
                                  </div>
                                }
                              </div>

                              <div className="p-col-12 p-xl-12">
                                {this.state.searchIsLoading ?
                                  this.NetiContentLoader.normalFormInputField() :
                                  <div className="p-inputgroup">
                                    <span className="task-badge found"></span>
                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                      Password
                                </span>
                                    <span className="p-inputgroup-colon">:</span>
                                    <div className="nw-inputgroup-desc">
                                      {this.state.bijoyDeviceDetails.password || '-'}
                                    </div>
                                  </div>
                                }
                              </div>

                              <div className="p-col-12 p-xl-12">
                                {this.state.searchIsLoading ?
                                  this.NetiContentLoader.normalFormInputField() :
                                  <div className="p-inputgroup">
                                    <span className="task-badge found"></span>
                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                      Mobile No
                                </span>
                                    <span className="p-inputgroup-colon">:</span>
                                    <div className="nw-inputgroup-desc">
                                      {this.state.bijoyDeviceDetails.mobileNo || '-'}
                                    </div>
                                  </div>
                                }
                              </div>

                              <div className="p-col-12 p-xl-12">
                                {this.state.searchIsLoading ?
                                  this.NetiContentLoader.normalFormInputField() :
                                  <div className="p-inputgroup">
                                    <span className="task-badge found"></span>
                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                      Replace Time
                                </span>
                                    <span className="p-inputgroup-colon">:</span>
                                    <div className="nw-inputgroup-desc">
                                      {this.state.bijoyDeviceDetails.replaceTimes || '-'}
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>}
                        </div> : ''
                      }
                    </div>


                    <div className="p-col-12 p-xl-12 nw-button-parent">
                      <Button
                        className="p-button p-button-primary nw-button nw-button-right"
                        label="Change"
                        onClick={this.onClick}
                        icon="fas fa-pencil-alt"
                        disabled={this.state.bijoyDeviceDetails.deviceID ? false : true}
                      />
                    </div>

                  </div>

                </div>

                <div className="p-col-12 p-lg-5 p-xl-4">
                  <div className="layout-dashboard" />
                </div>

                <div className="p-col-12">
                  <div className="nw-data-table">
                    {this.state.dataTableIsLoading ?
                      this.NetiContentLoader.MyPointTableLoader() :

                      <DataTable
                        header="Device ID Replace Log"
                        value={this.state.bijoyDeviceDetails.replaceLogList}
                        responsive={true}
                        paginator={true}
                        rows={10}
                      >
                        <Column field="replacedDate" header="Replace Date" body={this.replaceDateFormat} sortable={true} filter={true} />
                        <Column field="previousDeviceID" header="Previous Device ID" sortable={true} filter={true} />
                        <Column field="currentDeviceID" header="Current Device ID" sortable={true} filter={true} />
                        <Column field="userBasicInfoDTO.customNetiID" header="Replace Neti ID" sortable={true} filter={true} />
                        <Column header="Name" field="userBasicInfoDTO.fullName" sortable={true} filter={true} />
                      </DataTable>
                    }
                  </div>
                </div>
            </div>
          </div>
        </div>

        <Dialog
          header="Change Device ID"
          visible={this.state.visible}
          className="nw-dialog"
          onHide={this.onHide}
          maximizable
        >
          <div className="p-fluid">
            <div className="p-grid">
              <div className="p-col-12 p-xl-12">
                <div className="nw-form">
                  <div className="p-col-12 p-xl-12">
                    <div className="p-grid nw-form-body">

                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                        New Device ID<span>*</span>
                        </span>
                        <InputText
                            keyfilter="pint"
                            name="deviceID"
                            placeholder="Enter New Device ID"
                            value={this.state.replaceDeviceIDObj.currentDeviceID}
                            onChange={this.onChangeNewDeviceID}
                            autowidth={"false"}
                          />
                      </div>
                      </div>
                     
                      <div className="p-col-12 p-xl-12 nw-button-parent">
                        <Button
                          className="p-button p-button-primary nw-button nw-button-right"
                          label="Save"
                          icon="fas fa-check"
                          onClick={this.onSubmitNewDeviceID}
                        />
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}