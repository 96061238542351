import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { ProfileService } from '../../../service/profile/ProfileService';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';


export class LevelRevenue extends Component {

    constructor() {
        super();
        this.state = {
            updateLevelRevenueError: {},
            checkEligibility: true,
            errorMsgVisible: false,
            errorMsgBody: '',
            productTypeList: '',
            productType: '',
            productList: '',
            productName: '',
            basePercentage: '',
            partnerTypeList: '',
            partnerType: '',
            grade: '',
            note: '',
            visible: false,
            dataTableValue: [],
            selectedRow: {},
            topProgressBar: false,
            dataTableIsLoading: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            thirdDropdownIsLoading: false,

            updateLevelName: '',
            updateBasePercentage: '',
            updateNote: '',
            gradeTableValue: { "gradeOne": '', "gradeTwo": '', "gradeThree": '', "gradeFour": '', "gradeFive": '', "gradeSix": '', "gradeSeven": '', "gradeEight": '', "gradeNine": '', "gradeTen": '' },
            errors: {},
        }

        this.onChangeProductType = this.onChangeProductType.bind(this);
        this.onChangeProductName = this.onChangeProductName.bind(this);
        this.onChangePartnerType = this.onChangePartnerType.bind(this);
        this.onChangeBasePercentage = this.onChangeBasePercentage.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.submitSave = this.submitSave.bind(this);

        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);

        this.onChangeUpdateLevelName = this.onChangeUpdateLevelName.bind(this);
        this.onChangeUpdateBasePercentage = this.onChangeUpdateBasePercentage.bind(this);
        this.onChangeUpdateNote = this.onChangeUpdateNote.bind(this);
        this.submitUpdate = this.submitUpdate.bind(this);

        this.userCategoryService = new UserCategoryService();
        this.adminPointService = new AdminPointService();
        this.profileService = new ProfileService();
        this.fetchProductTypeList = this.fetchProductTypeList.bind(this);
        this.fetchProductList = this.fetchProductList.bind(this);
        this.fetchPartnerTypeList = this.fetchPartnerTypeList.bind(this);
        this.fetchConfiguredLevelRevenueList = this.fetchConfiguredLevelRevenueList.bind(this);
        this.submitSave = this.submitSave.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.fetchProductTypeList('T104');
        this.fetchPartnerTypeList();
        this.fetchConfiguredLevelRevenueList();
    }

    onChangeProductType(value) {
        let { errors } = this.state;
        errors["productType"] = "";
        this.setState({ productType: value, errors });
        this.fetchProductList(1, value);
    }

    onChangeProductName(value) {
        let { errors } = this.state;
        errors["productName"] = "";
        this.setState({ productName: value, errors });
    }

    onChangePartnerType(value) {
        let { errors } = this.state;
        errors["partnerType"] = "";
        this.setState({ partnerType: value, errors });
    }

    onChangeBasePercentage(value) {
        let { errors } = this.state;
        errors["basePercentage"] = "";
        this.setState({ basePercentage: value, errors });
    }

    onChangeNote(value) {
        let { errors } = this.state;
        errors["note"] = "";
        this.setState({ note: value, errors });
    }

    fetchProductTypeList(defaultCode) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productTypeList: body, topProgressBar: false, firstDropdownIsLoading: false });
                    })
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchProductList(enableStatus, productTypeId) {
        let productTypeInfoDTO = {
            "coreCategoryID": productTypeId,
        }
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchProductListByProductType(enableStatus, productTypeInfoDTO)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchPartnerTypeList() {
        this.setState({ topProgressBar: true, thirdDropdownIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchListByDefaultCode('T11202', 1)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ partnerTypeList: body, topProgressBar: false, thirdDropdownIsLoading: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }




    fetchConfiguredLevelRevenueList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchListOfProductLevelRevenue()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });

                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    submitSave() {

        if (this.handleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })

            let gradeValue = this.state.gradeTableValue;
            let requestedObj = {
                // "levelRevConfigID": 0,
                "levelNote": this.state.note,
                "basePercentage": parseInt(this.state.basePercentage),
                "gradeOne": parseInt(gradeValue.gradeOne),
                "gradeTwo": parseInt(gradeValue.gradeTwo),
                "gradeThree": parseInt(gradeValue.gradeThree),
                "gradeFour": parseInt(gradeValue.gradeFour),
                "gradeFive": parseInt(gradeValue.gradeFive),
                "gradeSix": parseInt(gradeValue.gradeSix),
                "gradeSeven": parseInt(gradeValue.gradeSeven),
                "gradeEight": parseInt(gradeValue.gradeEight),
                "gradeNine": parseInt(gradeValue.gradeNine),
                "gradeTen": parseInt(gradeValue.gradeTen),
                "productInfoDTO": {
                    "productID": this.state.productName,
                },
                "partnerTypeDTO": {
                    "coreCategoryID": this.state.partnerType,
                }
            }

            this.adminPointService.productLevelRevenueConfig(requestedObj)
                .then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data save successful.' });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false })
                        this.fetchConfiguredLevelRevenueList();
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }

                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }

    handleError() {

        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.productType === '') {
            formIsValid = false;
            errors["productType"] = "Product Type can't left empty.";
        }
        if (this.state.productName === '') {
            formIsValid = false;
            errors["productName"] = "Product Name can't left empty.";
        }
        if (this.state.partnerType === '') {
            formIsValid = false;
            errors["partnerType"] = "Partner Type can't left empty.";
        }
        if (this.state.basePercentage === '') {
            formIsValid = false;
            errors["basePercentage"] = "Base Percentage can't left empty.";
        }
        if (this.state.note === '') {
            formIsValid = false;
            errors["note"] = "Note can't left empty.";
        }

        if (this.state.gradeTableValue.gradeOne === '' || this.state.gradeTableValue.gradeTwo === '' || this.state.gradeTableValue.gradeThree === '' || this.state.gradeTableValue.gradeFour === '' || this.state.gradeTableValue.gradeFive === ''
            || this.state.gradeTableValue.gradeSix === '' || this.state.gradeTableValue.gradeSeven === '' || this.state.gradeTableValue.gradeEight === '' || this.state.gradeTableValue.gradeNine === '' || this.state.gradeTableValue.gradeTen === '') {
            formIsValid = false;
            errors["gradeList"] = "Grade List can't left empty.";
        }
        else {
            errors["gradeList"] = "";
            return formIsValid;
        }
        this.setState({ errors });
    }

    viewDialog(rowData) {
        this.setState({ selectedRow: rowData, visible: true });
    }

    onHide() {
        this.setState({ visible: false });
    }

    onChangeUpdateLevelName(value) {
        let { selectedRow, updateLevelRevenueError } = this.state;
        updateLevelRevenueError["updatePartnerType"] = "";
        selectedRow.partnerTypeDTO.coreCategoryID = value;
        this.setState({ selectedRow, updateLevelRevenueError });
    }

    onChangeUpdateBasePercentage(value) {
        let { selectedRow, updateLevelRevenueError } = this.state;
        updateLevelRevenueError["updateBasePercentage"] = '';
        selectedRow.basePercentage = value;
        this.setState({ selectedRow, updateLevelRevenueError });
    }

    onChangeUpdateNote(value) {
        let { selectedRow, updateLevelRevenueError } = this.state;
        updateLevelRevenueError["updateNote"] = ''
        selectedRow.levelNote = value;
        this.setState({ selectedRow, updateLevelRevenueError });
    }

    submitUpdate() {
        if (this.updateLevelRevenueErrorhandler()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            this.adminPointService.updateProductLevelRevenue(this.state.selectedRow)
                .then(res => {
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data update successful.' });
                        this.fetchConfiguredLevelRevenueList();
                        this.setState({ visible: false, dataTableIsLoading: false, topProgressBar: false });
                    } else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });

                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }

    updateDialogDiscard = () => {
        this.fetchConfiguredLevelRevenueList();
        this.setState({visible: false});
    }

    updateLevelRevenueErrorhandler = () => {

        let { selectedRow, updateLevelRevenueError } = this.state;
        let formIsValid = true;

        if (selectedRow.partnerTypeDTO.coreCategoryID === '') {
            formIsValid = false;
            updateLevelRevenueError["updatePartnerType"] = "Partner Type can't left empty.";
        }
        if (selectedRow.basePercentage === '') {
            formIsValid = false;
            updateLevelRevenueError["updateBasePercentage"] = "Base Percentage can't left empty.";
        }
        if (selectedRow.levelNote === '') {
            formIsValid = false;
            updateLevelRevenueError["updateNote"] = "Note can't left empty.";
        }

        this.setState({ updateLevelRevenueError });
        return formIsValid;
    }

    onChangeGradeOne(value) {
        let { gradeTableValue } = this.state;
        gradeTableValue.gradeOne = value;
        this.setState(gradeTableValue)
    }

    onChangeGradeTwo(value) {
        let { gradeTableValue } = this.state;
        gradeTableValue.gradeTwo = (value);
        this.setState(gradeTableValue)
    }

    onChangeGradeThree(value) {
        let { gradeTableValue } = this.state;
        gradeTableValue.gradeThree = (value);
        this.setState(gradeTableValue)
    }

    onChangeGradeFour(value) {
        let { gradeTableValue } = this.state;
        gradeTableValue.gradeFour = (value);
        this.setState(gradeTableValue)
    }

    onChangeGradeFive(value) {
        let { gradeTableValue } = this.state;
        gradeTableValue.gradeFive = (value);
        this.setState(gradeTableValue)
    }

    onChangeGradeSix(value) {
        let { gradeTableValue } = this.state;
        gradeTableValue.gradeSix = (value);
        this.setState(gradeTableValue)
    }

    onChangeGradeSeven(value) {
        let { gradeTableValue } = this.state;
        gradeTableValue.gradeSeven = (value);
        this.setState(gradeTableValue)
    }

    onChangeGradeEight(value) {
        let { gradeTableValue } = this.state;
        gradeTableValue.gradeEight = (value);
        this.setState(gradeTableValue)
    }

    onChangeGradeNine(value) {
        let { gradeTableValue } = this.state;
        gradeTableValue.gradeNine = (value);
        this.setState(gradeTableValue)
    }

    onChangeGradeTen(value) {
        let { gradeTableValue } = this.state;
        gradeTableValue.gradeTen = (value);
        this.setState(gradeTableValue)
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        var tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Configured Level Revenue List
            <span style={{ 'float': 'right' }}>Total Found: {this.state.dataTableValue.length} </span>
        </div>;
        let productTypeOptions = [];
        if (this.state.productTypeList && this.state.productTypeList.length) {
            productTypeOptions = this.state.productTypeList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let productListOptions = [];
        if (this.state.productList && this.state.productList.length) {
            productListOptions = this.state.productList.map(item => ({
                value: item.productID,
                label: item.productName
            }));
        }

        let partnerTypeOptions = [];
        if (this.state.partnerTypeList && this.state.partnerTypeList.length) {
            partnerTypeOptions = this.state.partnerTypeList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let editBody = (rowData) => {

            return <div className='text-center'>
                <Button
                    className="nw-action-button edit"
                    icon="fas fa-edit"
                    tooltip="Edit"
                    onClick={(e) => this.viewDialog(rowData)}
                />
            </div>
        }

        let gradeOneBody = (rowData) => {
            return <InputText
                value={this.state.gradeTableValue.gradeOne}
                onChange={(e) => this.onChangeGradeOne(e.target.value)}
            />
        }

        let gradeTwoBody = (rowData) => {
            return <InputText
                value={this.state.gradeTableValue.gradeTwo}
                onChange={(e) => this.onChangeGradeTwo(e.target.value)}
            />
        }

        let gradeThreeBody = (rowData) => {
            return <InputText
                value={this.state.gradeTableValue.gradeThree}
                onChange={(e) => this.onChangeGradeThree(e.target.value)}
            />
        }

        let gradeFourBody = (rowData) => {
            return <InputText
                value={this.state.gradeTableValue.gradeFour}
                onChange={(e) => this.onChangeGradeFour(e.target.value)}
            />
        }

        let gradeFiveBody = (rowData) => {
            return <InputText
                value={this.state.gradeTableValue.gradeFive}
                onChange={(e) => this.onChangeGradeFive(e.target.value)}
            />
        }

        let gradeSixBody = (rowData) => {
            return <InputText
                value={this.state.gradeTableValue.gradeSix}
                onChange={(e) => this.onChangeGradeSix(e.target.value)}
            />
        }

        let gradeSevenBody = (rowData) => {
            return <InputText
                value={this.state.gradeTableValue.gradeSeven}
                onChange={(e) => this.onChangeGradeSeven(e.target.value)}
            />
        }

        let gradeEightBody = (rowData) => {
            return <InputText
                value={this.state.gradeTableValue.gradeEight}
                onChange={(e) => this.onChangeGradeEight(e.target.value)}
            />
        }

        let gradeNineBody = (rowData) => {
            return <InputText
                value={this.state.gradeTableValue.gradeNine}
                onChange={(e) => this.onChangeGradeNine(e.target.value)}
            />
        }

        let gradeTenBody = (rowData) => {
            return <InputText
                value={this.state.gradeTableValue.gradeTen}
                onChange={(e) => this.onChangeGradeTen(e.target.value)}
            />
        }

        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} />

                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-12">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Product Type <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Product Type'
                                            name='productType'
                                            options={productTypeOptions}
                                            value={this.state.productType}
                                            onChange={(e) => this.onChangeProductType(e.target.value)}
                                            autoWidth={false}
                                            filter={true}
                                        />

                                    </div>
                                }
                                <span className='error-message'>{this.state.errors["productType"]}</span>

                            </div>

                            <div className="p-col-12 p-xl-12">
                                {this.state.secondDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon ">Product Name <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Product Name'
                                            name='productName'
                                            options={productListOptions}
                                            value={this.state.productName}
                                            onChange={(e) => this.onChangeProductName(e.target.value)}
                                            autoWidth={false}
                                            filter={true}
                                        />

                                    </div>
                                }
                                <span className='error-message'>{this.state.errors["productName"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                {this.state.thirdDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Partner Type <span>*</span></span>
                                        <Dropdown
                                            placeholder='Select Partner Type'
                                            name='partnerType'
                                            filterBy="value"
                                            options={partnerTypeOptions}
                                            value={this.state.partnerType}
                                            onChange={(e) => this.onChangePartnerType(e.target.value)}
                                            autoWidth={false}
                                            filter={true}
                                        />

                                    </div>
                                }
                                <span className='error-message'>{this.state.errors["partnerType"]}</span>

                            </div>


                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Base Percentage <span>*</span></span>
                                    <InputText
                                        placeholder='Enter Base Percentage'
                                        name="basePercentage"
                                        value={this.state.basePercentage}
                                        onChange={(e) => this.onChangeBasePercentage(e.target.value)}
                                    />

                                </div>
                                <span className='error-message'>{this.state.errors["basePercentage"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon" style={{ minHeight: "94px" }}>Grade List <span>*</span>
                                        {/* <label className="p-inputgroup-addon" style={{marginTop:"14px"}} >Percentage</label> */}
                                    </span>
                                    <div>
                                        <DataTable
                                            value={[this.state.gradeTableValue]}
                                            responsive={true}
                                            name="gradeList"
                                        >
                                            <Column field="gradeOne" header="One" body={gradeOneBody} />
                                            <Column field="gradeTwo" header="Two" body={gradeTwoBody} />
                                            <Column field="gradeThree" header="Three" body={gradeThreeBody} />
                                            <Column field="gradeFour" header="Four" body={gradeFourBody} />
                                            <Column field="gradeFive" header="Five" body={gradeFiveBody} />

                                            <Column field="gradeSix" header="Six" body={gradeSixBody} />
                                            <Column field="gradeSeven" header="Seven" body={gradeSevenBody} />
                                            <Column field="gradeEight" header="Eight" body={gradeEightBody} />
                                            <Column field="gradeNine" header="Nine" body={gradeNineBody} />
                                            <Column field="gradeTen" header="Ten" body={gradeTenBody} />
                                        </DataTable>
                                    </div>
                                </div>
                                <span className='error-message'>{this.state.errors["gradeList"]}</span>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                                    <InputTextarea
                                        placeholder='Enter Note'
                                        name="note"
                                        value={this.state.note}
                                        onChange={(e) => this.onChangeNote(e.target.value)}
                                    />

                                </div>
                                <span className='error-message'>{this.state.errors["note"]}</span>
                            </div>

                            <div className="p-col-12" />
                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <div className="required-field">
                                    (<span>*</span>) required fields
                                </div>

                                <Button
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    label="Save"
                                    icon="fas fa-check"
                                    onClick={event => this.submitSave(event)}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table nw-data-table-tabview">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    header={tableHeader}
                                    value={this.state.dataTableValue}
                                    responsive={true}
                                    paginator={true}
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column field="productInfoDTO.productTypeInfoDTO.categoryName" header="Product Type" sortable={true} filter={true} />
                                    <Column field="productInfoDTO.productName" header="Product Name" sortable={true} filter={true} />
                                    <Column field="partnerTypeDTO.categoryName" header="Partner Type" sortable={true} filter={true} />
                                    <Column field="basePercentage" header="Base Percentage" sortable={true} filter={true} />
                                    <Column field="" header="Action" style={{width: "80px"}} body={editBody} />
                                </DataTable>
                            }
                        </div>
                    </div>

                    <Dialog 
                        header="Update Level Revenue Infomation" 
                        className="nw-dialog" 
                        visible={this.state.visible} 
                        onHide={this.onHide}
                    >
                        {this.state.visible ?
                            <div className="p-fluid">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12">
                                       <div className="p-grid nw-form">
                                          <div className="p-col-12 p-xl-12">
                                             <div className="nw-form-body">
                                                <div className="p-col-12">
                                                    <div className="p-grid">
                                                        <div className="p-col-3">
                                                            <label>Product Type : </label><br />
                                                            <label>Product Name : </label><br />
                                                        </div>

                                                        <div className="p-col-8 text-bold">
                                                            <label>{this.state.selectedRow.productInfoDTO.productTypeInfoDTO.categoryName}</label> <br />
                                                            <label>{this.state.selectedRow.productInfoDTO.productName} </label> <br />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12">
                                                    <div className="formControl">
                                                        <label>Partner Type <span>*</span></label>
                                                        <Dropdown
                                                            placeholder='Select Partner Type'
                                                            name="updatePartnerType"
                                                            options={partnerTypeOptions}
                                                            autoWidth={false}
                                                            value={this.state.selectedRow.partnerTypeDTO.coreCategoryID || ''}
                                                            onChange={(e) => this.onChangeUpdateLevelName(e.target.value)}
                                                        />
                                                        <span className='error-message'>{this.state.updateLevelRevenueError["updatePartnerType"]}</span>
                                                    </div>
                                                </div>

                                                <div className="p-col-12">
                                                    <div className="formControl">
                                                        <label>Base Percentage <span>*</span></label>
                                                        <InputText
                                                            placeholder='Enter Base Percentage'
                                                            name="updateBasePercentage"
                                                            value={this.state.selectedRow.basePercentage || ''}
                                                            onChange={(e) => this.onChangeUpdateBasePercentage(e.target.value)}
                                                        />
                                                        <span className='error-message'>{this.state.updateLevelRevenueError["updateBasePercentage"]}</span>
                                                    </div>
                                                </div>

                                                <div className="p-col-12">
                                                    <div className="formControl">
                                                        <label>Note <span>*</span></label>
                                                        <InputTextarea
                                                            placeholder='Enter Note'
                                                            name="updateNote"
                                                            value={this.state.selectedRow.levelNote || ''}
                                                            onChange={(e) => this.onChangeUpdateNote(e.target.value)}
                                                        />
                                                        <span className='error-message'>{this.state.updateLevelRevenueError["updateNote"]}</span>
                                                    </div>
                                                </div>

                                                {/* <div className="p-col-8" /> */}

                                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                        <Button
                                                            label="Discard"
                                                            icon="fas fa-times"
                                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                                            onClick={this.updateDialogDiscard}
                                                        />
                                                        <Button
                                                            label="Update"
                                                            className="p-button p-button-primary nw-button nw-button-multiple"
                                                            icon="fas fa-check"
                                                            onClick={event => this.submitUpdate(event)}
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            : ''}

                    </Dialog>

                </div>
            </div>

        )
    }
}