import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { EventLogList } from './EventLogList';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { Link } from 'react-router-dom';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';


let urlId;
export class EventLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requestStartDate: '',
            requestEndDate: '',
            eventLogInfoErr: {},
            EventLogInfo: {
                eventTitle: '',
                eventDetails: '',
                eventType: '',
                eventStartDate: '',
                eventEndDate: '',
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            eventLogListVisible: true,
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: ''
        }

        this.netiDateUtils = new NetiDateUtils();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();

    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        const urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            let { coreUrlInfoDTO } = this.state.EventLogInfo
            coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ coreUrlInfoDTO })
            this.setState({ homeReturnButton: false })
            this.setState({ topProgressBar: false });
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    onChangeEventType = (e) => {
        let { EventLogInfo } = this.state;
        EventLogInfo.eventType = e.target.value;
        this.setState({ EventLogInfo });
    }

    onChangeEventTitle = (e) => {
        let { EventLogInfo } = this.state;
        EventLogInfo.eventTitle = e.target.value;
        this.setState({ EventLogInfo });
    }

    onChangeEventDetails = (e) => {
        let { EventLogInfo } = this.state;
        EventLogInfo.eventDetails = e.target.value;
        this.setState({ EventLogInfo });
    }

    onChangeStartDate = (e) => {
        let { EventLogInfo } = this.state;

        // NetiDateUtils.getDateFromStringDDMMYYYY(rowData['eventStartDate'])



        EventLogInfo.eventStartDate = e.target.value;
        this.setState({ EventLogInfo });
    }

    onChangeEndDate = (e) => {
        let { EventLogInfo } = this.state;
        EventLogInfo.eventEndDate = e.target.value;
        this.setState({ EventLogInfo });
    }





    onSubmitHandler = () => {
        let { EventLogInfo } = this.state;

        if (this.eventLogFormHandleError()) {
            if (Date.parse(EventLogInfo.eventEndDate) >= Date.parse(EventLogInfo.eventStartDate)) {
                this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
                this.DwsService.createDwsEventLog(EventLogInfo)
                    .then(res => {
                        if (res.status == 201) {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                            this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Created!" });

                            let { EventLogInfo } = this.state;
                            EventLogInfo.eventTitle = EventLogInfo.eventDetails = EventLogInfo.eventType = EventLogInfo.eventStartDate = EventLogInfo.eventEndDate = ''
                            this.setState({ EventLogInfo })

                            this.updateEventLogList();
                        } else {
                            this.DwsService.Auth.handleErrorStatus(res)
                                .then((resp) => {
                                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                                });
                        }
                    }).catch(error => {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "Invalid Date Range." });
            }

        }

    }

    eventLogFormHandleError = () => {
        let { eventLogInfoErr, EventLogInfo } = this.state;
        let formIsValid = true;

        if (EventLogInfo.eventType === '') {
            formIsValid = false;
            eventLogInfoErr["type"] = "Type can't left empty.";
        }
        if (EventLogInfo.eventTitle === '') {
            formIsValid = false;
            eventLogInfoErr["title"] = "Title can't left empty.";
        }
        if (EventLogInfo.eventDetails === '') {
            formIsValid = false;
            eventLogInfoErr["details"] = "Details can't left empty.";
        }
        if (EventLogInfo.eventStartDate === '') {
            formIsValid = false;
            eventLogInfoErr["startDate"] = "Start date can't left empty.";
        }
        if (EventLogInfo.eventEndDate === '') {
            formIsValid = false;
            eventLogInfoErr["endDate"] = "End date can't left empty.";
        }
        this.setState({ eventLogInfoErr });
        return formIsValid;
    }

    updateEventLogList = () => {
        this.setState({ eventLogListVisible: false });
        this.setState({ eventLogListVisible: true });
    }

    eventLogSelector = (eventType) => {
        return (
            <div className="input-radio-button">
                <div className="radio-button-inside">
                    <RadioButton
                        value="Holiday"
                        inputId="rb1"
                        checked={eventType === "Holiday"}
                        onChange={this.onChangeEventType}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Holiday</label>
                </div>

                <div className="radio-button-inside">
                    <RadioButton
                        value="Exam Day"
                        inputId="rb2"
                        checked={eventType === "Exam Day"}
                        onChange={this.onChangeEventType}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Exam Day</label>
                </div>

                <div className="radio-button-inside">
                    <RadioButton
                        value="Event"
                        inputId="rb3"
                        onChange={this.onChangeEventType}
                        checked={eventType === "Event"}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Event</label>
                </div>
            </div>
        )
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { EventLogInfo } = this.state;


        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                {this.state.homeReturnButton === false ?
                    <div className="main-section p-grid">
                        <div className="p-col-12 p-xl-12">

                            <Growl ref={(el) => this.growl = el} />
                            <div className="nw-form">
                                <div className="p-col-12 p-xl-12 blur-section">
                                    <div className="p-grid nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon addon-no-style">Type <span>*</span></span>
                                                
                                                <div className="nw-inputgroup-desc desc-no-style">{this.eventLogSelector(EventLogInfo.eventType)}</div>

                                            </div>
                                            {/* <span className="error-message">{this.state.photoSubmitError.photoType}</span> */}
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Title <span>*</span></span>
                                                <InputText
                                                    onChange={this.onChangeEventTitle}
                                                    value={EventLogInfo.eventTitle}
                                                    placeholder="Enter Event Title"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    id="title"
                                                    name="title"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.eventLogInfoErr["title"]}</span>
                                        </div>

                                        
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                                <InputTextarea
                                                    placeholder="Write Event Details"
                                                    onChange={this.onChangeEventDetails}
                                                    value={EventLogInfo.eventDetails}
                                                    id="details"
                                                    rows={3}
                                                    cols={30}
                                                    autoResize={true}
                                                    name="details"
                                                />
                                            </div>
                                            <span className="error-message">{this.state.eventLogInfoErr["details"]}</span>
                                        </div>


                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                                <Calendar
                                                    value={EventLogInfo.eventStartDate}
                                                    showIcon={true}
                                                    onChange={this.onChangeStartDate}
                                                    name="startDate"
                                                    placeholder="Select Date" 
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                    showButtonBar={true} 
                                                />
                                            </div>
                                            <span className="error-message">{this.state.eventLogInfoErr["startDate"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">End Date <span>*</span> </span>
                                                <Calendar
                                                    value={EventLogInfo.eventEndDate}
                                                    showIcon={true}
                                                    dateFormat="yyyy-MM-dd"
                                                    onChange={this.onChangeEndDate}
                                                    name="endDate"
                                                    placeholder="Select Date" 
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                    showButtonBar={true} 
                                                />
                                            </div>
                                            <span className="error-message">{this.state.eventLogInfoErr["endDate"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">

                                        </div>

                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                label="Save"
                                                icon="fas fa-check"
                                                onClick={this.onSubmitHandler}
                                            />
                                        </div>
                                    </div>
                                </div>

                                

                                {this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() :
                                    this.state.eventLogListVisible == true && <EventLogList />
                                }


                            </div>

                        </div>
                        
                    </div>
                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}