import React, {Component} from 'react';
import {DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {NetiDateUtils} from '../../../utils/NetiDateUtils';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import NetiContentLoader from '../../myPoint/common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import {Growl} from 'primereact/growl';
import { AdmisiaApplicantViewInfo } from '../AdmisiaApplicantViewInfo';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';

let errors = {}
export class AdmissionPendingApplicantList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            applicantPicture: '',
            rejectedStutas: "Custom Status",
            dataTableSelection: null,
            dataViewValue: [],
            dataTableValue: props.componentNameData,
            applicantDetailObj: {
                admisiaApplicantInfoDTO:{
                    registrationID: '',
                    applicationDate:'',
                    applicantName:'',
                    dateOfBirth:'',
                    mobileNo:'',
                    addressDetails:'',
                    addressCoreCategoryInfoDTO:{
                        categoryName: ''
                    },
                    fatherName:'',
                    motherName:'',
                    fatherOccupationCategoryInfoDTO:{
                        categoryName:''
                    },
                    motherOccupationCategoryInfoDTO:{
                        categoryName:''
                    },
                    admisiaClassConfigDTO:{
                        classCoreCategoryInfoDTO:{
                            categoryName:''
                        },
                        groupCoreCategoryInfoDTO:{
                            categoryName:''
                        }
                    },
                    genderCoreCategoryInfoDTO:{
                        categoryName:''
                    },
                    religionCoreCategoryInfoDTO:{
                        categoryName:''
                    }
                }
            },
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible:false,
            errorMsgBody: null,
            errors:{}
        };

        this.admisiaPointService=new AdmisiaPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.onSubmitApproveForAdmission = this.onSubmitApproveForAdmission.bind(this)
        this.onSubmitWaitingForAdmission = this.onSubmitWaitingForAdmission.bind(this)
        this.onSubmitRejectForAdmission = this.onSubmitRejectForAdmission.bind(this)
        this.netiFileHandler = new NetiFileHandler();
        this.getProfileImg = this.getProfileImg.bind(this);
       
    }

    dateFormatTemplet = (rowData) =>{        
        return <span>{NetiDateUtils.getAnyShortForm(rowData['requestDate'], 'DD-MMM-YYYY')}</span>;
    }

    prevExamInfoTemplet = (rowData) =>{
        
        if (rowData.admisiaApplicantPrevExamInfoDTOList) {

            return (
                    <DataTable 
                        value={rowData.admisiaApplicantPrevExamInfoDTOList}
                        responsive={false}
                        className="pre-exam-info-datatable"
                    >
                        <Column field="prevInstituteName" header="Institute Name" />
                        <Column field="examGradeCategoryInfoDTO.categoryName" header="Grade"/>
                        <Column field="examGPA" header="GPA"/>
                    </DataTable>
                  
            );
        }
        else{
            return <center>-</center>;
        }

    }

    statusBodyTemplet = (rowData) => {
        return <center><Button
            className="p-button p-button-primary p-button-info p-button-text-only"
            icon="far fa-eye"
            title="View Purchase"
            onClick={(e) => this.viewDialog(rowData)}
        />
        </center>

    }

    viewDialog = (rowData) => {
        this.setState({ visible: true });

        this.getApplicantDetailInfo( rowData.admisiaApplicantInfoDTO.admisiaApplicantInfoId )
    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    getProfileImg() {
        if (this.state.applicantDetailObj.admisiaApplicantInfoDTO.photoPath !== null) {
            this.netiFileHandler.getByteImage(this.state.applicantDetailObj.admisiaApplicantInfoDTO.photoPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(this.state.applicantDetailObj.admisiaApplicantInfoDTO.photoName);
                                this.setState({ applicantPicture: contentType + body.fileContent });
                                console.log("applicantPicture", this.state.applicantPicture);
                                
                            })
                    } else {
                        return res.json()
                            .then((body) => {
                                console.log("image error", body);
                            })
                    }
                });
        }

    }

    getApplicantDetailInfo(applicantID){
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.admisiaPointService.getApplicantInfoByID(applicantID)
                .then(res => {
                    if(res.status == 302) {
                        return res.json().then((body) => {
                        console.log("Body88", body);
                            
                        this.setState({ applicantDetailObj:  body});
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.getProfileImg();
                        })
                    }
                    else{
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp)=>{
                            this.setState({dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                        });
                    }
                }).catch((error) => {
                    this.setState({dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                });
    }


    

    displaySelection(data) {
        if(!data || data.length === 0) {
            return <div style={{textAlign: 'left'}}>No Selection</div>;
        }
        else {
            errors['submitApproveError'] = ""
        }
    }

    getUpdateObj(updateStatus){
        let { dataTableSelection } = this.state

            let markMapValue = []
            if (dataTableSelection && dataTableSelection.length) {
                markMapValue = dataTableSelection.map((item) => ({
                    admisiaApplicantInfoId: item.admisiaApplicantInfoDTO.admisiaApplicantInfoId
                }));
            }
            let approveAssessmentObj = {
                "applicantInfoDTOList": markMapValue,
                "status": updateStatus
               }

        
        return approveAssessmentObj
    }

    getConnectToUpdateStatusApi(updateStatus){
        if(!this.state.dataTableSelection){
            errors['submitApproveError'] = "Please select at least one ROW"
        }
        else{
            this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.admisiaPointService.updateForApproveAssessment( this.getUpdateObj(updateStatus) )
                .then(res => {
                    if(res.status == 202) {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                            this.growl.show({severity: 'success', summary: 'Success', detail: ""});

                            this.props.reCallClassGroupSearch();
                    }
                    else{
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp)=>{
                            this.setState({dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                        });
                    }
                }).catch((error) => {
                    this.setState({dataTableIsLoading: false, topProgressBar: false,  errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                });
        }
    }

    onSubmitApproveForAdmission(){
        this.getConnectToUpdateStatusApi(5)
    }

    onSubmitWaitingForAdmission(){
        this.getConnectToUpdateStatusApi(4)
    }

    onSubmitRejectForAdmission(){
        this.getConnectToUpdateStatusApi(3)
    }
    
    render() {

        console.log("Final Picture", this.state.dataTableValue);
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableIsLoading } = this.state;

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                        Admission Pending Applicant List
                        <div
                            style={{ color: "white", display: "flex", float: "right" }}
                        >
                            Total: { this.state.dataTableValue.length }
                            
                        </div>
                    </div>;
        
        return (
            <div className="p-fluid">
                <Growl ref={(el) => { this.growl = el; }}></Growl>
                { topProgressBar?
                    <ErrorMessageView 
                        topProgressBar={topProgressBar} 
                    />
                    : null
                }
                { errorMsgVisible ?
                    <ErrorMessageView 
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid">
                    
                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table nw-data-table-tabview">
                          
                            <DataTable
                                value={this.state.dataTableValue}
                                header={tableHeader}
                                responsive={true}
                                paginator={true}
                                rows={10}
                                selection={this.state.dataTableSelection}
                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                footer={this.displaySelection(this.state.dataTableSelection)}
                            >
                                <Column selectionMode="multiple" style={{width:'4em'}}/>
                                <Column field="admisiaApplicantInfoDTO.registrationID" header="Registration No" sortable={true} filter={true} />
                                <Column field="admisiaApplicantInfoDTO.applicantName" header="Name" sortable={true} filter={true} />
                                <Column field="admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName" header="Gender" sortable={true} filter={true} />
                                <Column field="admisiaApplicantInfoDTO.mobileNo" header="Mobile No." sortable={true} filter={true} />
                                <Column field="admisiaApplicantInfoDTO.applicationDate" header="Application Date" sortable={true} filter={true} />
                                <Column field="admisiaApplicantInfoDTO.examMark" header="Marks" sortable={true} filter={true} />
                                <Column field="admisiaApplicantPrevExamInfoDTOList.prevInstituteName" header="Previous Exam Info" body={this.prevExamInfoTemplet} style={{width:"210px"}} sortable={true} filter={true} />
                                <Column field="requestStatus" header="Action" body={this.statusBodyTemplet} />
                            </DataTable>
                            
                            <span className="error-message">{errors['submitApproveError']}</span>
                        </div>

                        <div className="p-grid nw-form">
                            <div className="p-xl-3"></div>
                            <div className="p-col-12 p-xl-3 nw-button-parent">
                                <Button
                                    label="Reject For Admission"
                                    icon="fas fa-times"
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    onClick={this.onSubmitRejectForAdmission}
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 nw-button-parent">
                                <Button
                                    label="Waiting For Admission"
                                    icon="far fa-clock"
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    onClick={this.onSubmitWaitingForAdmission}
                                />
                            </div>

                            <div className="p-col-12 p-xl-3 nw-button-parent">
                                <Button
                                    label="Approve For Admission"
                                    icon="fas fa-check"
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    onClick={this.onSubmitApproveForAdmission}
                                />
                            </div>
                        </div>

                        <Dialog 
                            className="nw-dialog" 
                            header="-" 
                            visible={this.state.visible} 
                            onHide={this.onHide} 
                            maximizable
                            style = {{"width": "90%"}}
                        >

                            <AdmisiaApplicantViewInfo 
                            applicantInfo={this.state.applicantDetailObj}
                            photoInfo={this.state.applicantPicture}    
                            /> 
                        
                        </Dialog>

                    </div>
                </div>
            </div>
        );
    }
}