import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { SeatInfoList } from "./SeatInfoList";
import { UserCategoryService } from "../../../service/UserCategoryService";
import { DwsService } from "../../../service/dwsPoint/DwsService";
import NetiContentLoader from "../../common/NetiContentLoader";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { Link } from "react-router-dom";
import { NetiCMSSetupProgress } from "../common/NetiCMSSetupProgress";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import staticImg from "../../../assets/images/avatar.png";
import { NetiFileHandler } from "../../../utils/NetiFileHandler";
import { ImageCropper } from "../../common/ImageCropper";

let dialogStatus = false;
let errors = {};
let dropDownListName1 = "";
let dropDownListName2 = "";
let cloneStateBeforeMount;
let urlId;
export class AdminAdministrationInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      memberSerial: "",
      btnDisabled: false,
      updateBtnDisabled: false,
      memberInfo: {},
      memberId: "",
      memberStatus: "",
      dialogFormType: false,
      cmsId: "",
      memberType: "",
      memberEmail: "",
      facebookProfile: "",
      twitterProfile: "",
      memberName: "",
      linkedinProfile: "",
      memberMobile: "",
      memberDesignation: "",
      viewMemberInfo: false,
      uploadFile: {
        fileName: "",
        fileContent: "",
        fileSaveOrEditable: false,
      },
      dataTableValue: [],
      errors: {},
      homeReturnButton: true,
      dataTableIsLoading: false,
      firstDropDownIsLoading: false,
      secondDropDownIsLoading: false,
      totalDataFound: 0,

      cropperVisible: false,
      cropperObject: {
        //main class parameter
        main: {
          viewport: { width: 270, height: 270 },
          boundary: { width: 600, height: 480 },
          showZoomer: true,
          enableOrientation: false,
        },
        //bind parameter
        bind: {
          url: "",
          orientation: 4,
        },
      },
    };

    this.UserCategoryService = new UserCategoryService();
    this.DwsService = new DwsService();
    this.NetiContentLoader = new NetiContentLoader();
    this.NetiFileHandler = new NetiFileHandler();

    this.reloadCoreUrlIdFromLocal();
  }

  async reloadCoreUrlIdFromLocal() {
    urlId = await this.DwsService.getCmsIdFromLocalStorage();
    console.log("urlId .>", urlId);

    if (typeof urlId === "string" || typeof urlId === "number") {
      this.setState({ cmsId: urlId });
      this.setState({ homeReturnButton: false });
      this.getTableData();
    } else {
      this.setState({ homeReturnButton: true });
    }
  }

  showFormDialog = (e, rowData, dialogType) => {
    if (dialogType === "update") {
      this.setState({
        memberId: rowData.memberId,
        memberName: rowData.memberName,
        memberStatus: rowData.memberStatus,
        memberSerial: rowData.memberSerial,
        memberType: rowData.memberType,
        memberEmail: rowData.memberEmail,
        facebookProfile: rowData.facebookProfile,
        twitterProfile: rowData.twitterProfile,
        linkedinProfile: rowData.linkedinProfile,
        memberMobile: rowData.memberMobile,
        memberDesignation: rowData.memberDesignation,
        uploadFile: {
          fileName: rowData.memberImgName,
          fileContent: rowData.memberImg,
          fileSaveOrEditable: true,
        },
      });
      this.showMainForm();

      this.setState({ dialogFormType: false });
    } else if (dialogType === "save") {
      this.resetSaveAndUpdateForm();
      this.showMainForm();

      this.setState({ dialogFormType: true });
    }
  };

  deleteRow = (memberID) => {
    let { errors } = this.state;
    this.DwsService.cmsDeleteMemberInfo(memberID)
      .then((res) => {
        return res.json();
      })
      .then((body) => {
        // console.log('body', body)

        if (body.messageType == 1) {
          // console.log('body.message', body.message);
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
          });
          this.growl.show({
            life: 800,
            severity: "success",
            summary: "Success",
            detail: body.message,
          });
          this.getTableData();
        } else {
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: body.message,
          });
        }
      })
      .catch((error) => {
        this.setState({ errors: errors });
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  };

  getTableData = () => {
    this.setState({ topProgressBar: true, errorMsgVisible: false });

    this.DwsService.getCmsMemberInfoList(this.state.cmsId)
      .then((res) => {
        // console.log('res-list', res);

        if (res.status == 200) {
          return res.json().then((body) => {
            // console.log('table-list', body.item);

            // this.props.countTableSize(body.length);
            this.setState({ dataTableValue: body.item });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.DwsService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection.",
        });
      });
  };

  showMainForm = () => {
    this.setState({ visible: true });
  };

  resetSaveAndUpdateForm = () => {
    this.setState({
      memberName: "",
      memberSerial: "",
      memberType: "",
      memberEmail: "",
      facebookProfile: "",
      twitterProfile: "",
      linkedinProfile: "",
      memberMobile: "",
      memberDesignation: "",
      uploadFile: {
        fileName: "",
        fileContent: "",
        fileSaveOrEditable: false,
      },
    });
  };

  onHide = () => {
    this.setState({ visible: false });
    this.emptyErrorMessage();
    this.resetSaveAndUpdateForm();
    this.hideImageCropper();
    this.setState({ btnDisabled: false });
    this.setState({ updateBtnDisabled: false });
  };

  onHideMemberInfo = () => {
    this.setState({ viewMemberInfo: false });
  };

  memberInfoBody = (rowData) => {
    // let staticImg = 'assets/layout/images/avatar.png';

    return (
      <div className="p-grid">
        <div className="p-col-2" style={{ minWidth: "110px" }}>
          {rowData.memberImg ? (
            <img
              src={"data:image/*;base64," + rowData.memberImg}
              height="auto"
              width="80px"
            />
          ) : (
            <img src={staticImg} width="100%" />
          )}
        </div>

        <div className="p-col-10 nw-datatable-dataview-small-panel productInfo">
          {/* <div> */}

          <div>{rowData.memberType}</div>

          {/* </div> */}

          {/* <div> */}
          <div>{rowData.memberName}</div>

          {/* </div> */}

          {/* <div> */}
          <div>{rowData.memberDesignation}</div>

          {/* </div> */}

          {/* <div> */}
          <div>{rowData.memberMobile}</div>

          {/* </div> */}

          {/* <div> */}
          <div>{rowData.memberEmail}</div>

          {/* </div> */}
        </div>
      </div>
    );
  };

  editBody = (rowData) => {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button"
          icon="far fa-eye"
          title="View"
          onClick={(e) => this.viewDialog(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "top" }}
        />
        <Button
          className="nw-action-button"
          icon="fas fa-pencil-alt"
          title="Update"
          onClick={(e) => this.showFormDialog(e, rowData, "update")}
          tooltip="Update"
          tooltipOptions={{ position: "top" }}
        />
        <Button
          type="button"
          icon="fas fa-trash"
          className="nw-action-button p-button-danger"
          onClick={(e) => this.deleteRow(rowData.memberId)}
          tooltip="Delete"
          tooltipOptions={{ position: "top" }}
        />
      </div>
    );
  };

  viewDialog = (rowData) => {
    this.setState({ memberInfo: rowData });
    this.setState({ viewMemberInfo: true });
  };

  onChangeMemberType = (e) => {
    this.emptyErrorMessage();
    this.setState({ memberType: e.target.value });
  };

  onChangeMemberName = (e) => {
    this.emptyErrorMessage();
    this.setState({ memberName: e.target.value });
  };

  onChangeMemberDesignation = (e) => {
    this.emptyErrorMessage();
    this.setState({ memberDesignation: e.target.value });
  };

  onChangeMemberMobile = (e) => {
    this.emptyErrorMessage();
    this.setState({ memberMobile: e.target.value });
  };

  onChangeMemberEmail = (e) => {
    this.emptyErrorMessage();
    this.setState({ memberEmail: e.target.value });
  };

  onChangeFacebookProfile = (e) => {
    this.emptyErrorMessage();
    this.setState({ facebookProfile: e.target.value });
  };

  onChangeLinkedinProfile = (e) => {
    this.emptyErrorMessage();
    this.setState({ linkedinProfile: e.target.value });
  };

  onChangeTwitterProfile = (e) => {
    this.emptyErrorMessage();
    this.setState({ twitterProfile: e.target.value });
  };

  onLoadPic = (e) => {
    let { errors, cropperObject } = this.state;
    this.emptyErrorMessage();

    let fileType = this.NetiFileHandler.getFileContentType(e.files[0].name);
    let supportedExtention = ["image/jpeg", "image/jpg", "image/png"];
    // console.log('fileType', fileType);
    if (supportedExtention.includes(fileType)) {
      var reader = new FileReader();
      let photo = e.files[0];
      const scope = this;
      reader.readAsDataURL(photo);
      reader.onload = () => {
        let content = reader.result;
        var keyw = "data:" + photo.type + ";base64,"; //link will be same from the word webapps in URL
        var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
        let album = {
          fileContent: urlStr,
          fileName: photo.name,
          fileSaveOrEditable: true,
        };
        scope.setState({ uploadFile: album });
      };
      errors["image"] = "";
      cropperObject.bind.url = photo.objectURL;
      this.setState({ errors, cropperObject, cropperVisible: true });
    } else {
      errors["image"] = "File format not supported";
      this.setState({ errors });
    }
  };

  getCroppedResult = (imageObj) => {
    let { uploadFile, errors } = this.state;

    let maxSize = this.NetiFileHandler.getMaxFileSizeIsValid(
      imageObj.photoBlob.size,
      500000
    );
    if (maxSize) {
      uploadFile.fileContent = imageObj.contentPic;
      errors["image"] = "";
      this.setState({ errors: errors });
    } else {
      errors["image"] = "Image size can't be more than 500 KB";
      this.setState({ errors });
      this.removeCropSection();
    }

    this.hideImageCropper();
    this.setState({ uploadFile });
  };

  hideImageCropper = () => {
    this.setState({ cropperVisible: false });
  };

  removeCropSection = () => {
    let { uploadFile } = this.state;
    uploadFile.fileContent = "";
    uploadFile.fileName = "";
    uploadFile.fileSaveOrEditable = false;

    this.setState({ cropperVisible: false, uploadFile });
  };

  onChageInputValue = (e) => {
    this.setState({
      ...this.state,
      memberSerial: e.target.value,
    });

    // this.onClearErrorMsg(e.target.name);
  };

  onSubmitMemberInfo = () => {
    let { errors } = this.state;
    let user_name = localStorage.getItem("user_name");

    let memberInfoObj = {
      cmsId: this.state.cmsId,
      facebookProfile: this.state.facebookProfile,
      imageContent: this.state.uploadFile.fileContent,
      imageName: this.state.uploadFile.fileName,
      imageSaveOrEditable: true,
      linkedinProfile: this.state.linkedinProfile,
      memberDesignation: this.state.memberDesignation,
      memberEmail: this.state.memberEmail,
      memberMobile: this.state.memberMobile,
      memberName: this.state.memberName,
      memberSerial: this.state.dataTableValue.length + 1,
      memberType: this.state.memberType,
      twitterProfile: this.state.twitterProfile,
      userNmae: user_name,
    };

    if (this.addMemberFormHandleError()) {
      this.setState({
        topProgressBar: true,
        errorMsgVisible: false,
        btnDisabled: true,
      });
      this.DwsService.cmsSaveMemberInfo(memberInfoObj)
        .then((res) => {
          // console.log('dress code info res', res);
          return res.json();
        })
        .then((body) => {
          if (body.messageType == 1) {
            // console.log('body.message', body.message);
            this.growl.show({
              life: 800,
              severity: "success",
              summary: "Success",
              detail: body.message,
            });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
            this.getTableData();
            this.resetSaveAndUpdateForm();
            setTimeout(() => {
              this.onHide();
            }, 2000);
          } else {
            this.setState({ btnDisabled: false });

            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: body.message,
            });
          }
        })
        .catch((error) => {
          this.setState({ errors: errors });
          this.setState({ btnDisabled: false });
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection",
          });
        });
    }
  };

  onUpdateMemberInfo = () => {
    let { errors } = this.state;
    let user_name = localStorage.getItem("user_name");
    // console.log('user_name', user_name);

    let memberUpdateInfoObj = {
      cmsId: this.state.cmsId,
      facebookProfile: this.state.facebookProfile,
      imageContent: this.state.uploadFile.fileContent,
      imageName: this.state.uploadFile.fileName,
      imageSaveOrEditable: true,
      linkedinProfile: this.state.linkedinProfile,
      memberDesignation: this.state.memberDesignation,
      memberEmail: this.state.memberEmail,
      memberMobile: this.state.memberMobile,
      memberName: this.state.memberName,
      memberSerial: this.state.memberSerial,
      memberType: this.state.memberType,
      twitterProfile: this.state.twitterProfile,
      userName: user_name,
      memberStatus: this.state.memberStatus,
      memberId: this.state.memberId,
    };

    if (this.addMemberFormHandleError()) {
      this.setState({
        topProgressBar: true,
        errorMsgVisible: false,
        updateBtnDisabled: true,
      });
      this.DwsService.cmsUpdateMemberInfo(memberUpdateInfoObj)
        .then((res) => {
          // console.log('update member res', res);
          return res.json();
        })
        .then((body) => {
          if (body.messageType == 1) {
            // console.log('update member body.message', body.message);
            this.growl.show({
              life: 800,
              severity: "success",
              summary: "Success",
              detail: body.message,
            });
            this.getTableData();
            this.resetSaveAndUpdateForm();
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
            setTimeout(() => {
              this.onHide();
            }, 2000);
          } else {
            this.setState({ updateBtnDisabled: false });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: body.message,
            });
          }
        })
        .catch((error) => {
          this.setState({ errors: errors });
          this.setState({ updateBtnDisabled: false });
          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection",
          });
        });
    }
  };

  emptyErrorMessage() {
    errors = {};
    this.setState({ errors });
  }

  addMemberFormHandleError = () => {
    let { errors } = this.state;
    let formIsValid = true;
    this.setState({ topProgressBar: false });

    if (this.state.memberType === "" || this.state.memberType === null) {
      formIsValid = false;
      errors["type"] = "Type can't left empty.";
    }
    if (this.state.memberName === "") {
      formIsValid = false;
      errors["name"] = "Name can't left empty.";
    }
    if (this.state.memberDesignation === "") {
      formIsValid = false;
      errors["designation"] = "Designation can't left empty.";
    }
    // if (this.state.memberMobile === '') {
    //     formIsValid = false;
    //     errors["mobileNo"] = "Mobile number can't left empty.";
    // }
    // if (this.state.memberEmail === '') {
    //     formIsValid = false;
    //     errors["email"] = "Email can't left empty.";
    // }

    if (this.state.uploadFile.fileName === "") {
      formIsValid = false;
      errors["image"] = "Upload Image can't left empty.";
    }
    this.setState({ errors });
    return formIsValid;
  };

  render() {
    let {
      dataTableValue,
      topProgressBar,
      errorMsgVisible,
      errorMsgBody,
    } = this.state;
    let uploadFileLabel = (
      <div>
        Click / Drag File Here To Upload <br />
        <span className="upload-file-label">(Max 500 KB (recommended))</span>
      </div>
    );

    let memberListOption = [
      { label: "Committee", value: "Committee" },
      { label: "Donor", value: "Donor" },
    ];

    let classList = [];
    if (
      this.state.classDropDownList != null &&
      this.state.classDropDownList.length > 0
    ) {
      classList = this.state.classDropDownList.map((item) => ({
        value: item.coreCategoryID,
        label: item.categoryName,
      }));
    }

    let groupList = [];
    if (
      this.state.groupDropDownList != null &&
      this.state.groupDropDownList.length > 0
    ) {
      groupList = this.state.groupDropDownList.map((item) => ({
        value: item.coreCategoryID,
        label: item.categoryName,
      }));
    }

    let tableHeader = (
      <div className="header-title">
        <div className="header-title-left">Member Information List</div>
        <div className="header-title-right">
          <a>Total Found: {dataTableValue.length.toLocaleString("EN-IN")}</a>

          <Button
            className="nw-button nw-button-center p-button-success"
            name="district"
            label="Add New"
            icon="fas fa-plus"
            iconPos="right"
            onClick={(e) => this.showFormDialog(e, null, "save")}
          />
        </div>
      </div>
    );

    return (
      <div className="p-fluid">
        <NetiCMSSetupProgress />

        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <Growl ref={(el) => (this.growl = el)} />

        <div className="main-section">
          {this.state.homeReturnButton === false ? (
            <div className="nw-data-table">
              {!this.state.dataTableIsLoading ? (
                <DataTable
                  value={this.state.dataTableValue}
                  selectionMode="single"
                  header={tableHeader}
                  responsive={true}
                  rows={10}
                  paginator={true}
                  columnResizeMode="fit"
                >
                  <Column
                    field="memberSerial"
                    header="Serial No."
                    style={{ width: "12em" }}
                    filter={true}
                  />
                  <Column
                    field=""
                    header="Member Info"
                    body={this.memberInfoBody}
                    filter={true}
                  />
                  <Column
                    field="action"
                    header="Action"
                    body={this.editBody}
                    style={{ textAlign: "center", width: "10em" }}
                  />
                </DataTable>
              ) : (
                this.NetiContentLoader.MyPointTableLoader()
              )}
            </div>
          ) : (
            <div className="">
              <div className="p-grid">
                <div className="card p-col-12 p-xl-3">
                  <center className="custom-center">
                    <Link
                      to="/home"
                      className="rainbow-button"
                      alt="Go DWS Home"
                    />
                  </center>
                </div>
                <div className="card p-col-12 p-xl-3">
                  <center className="custom-center">
                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                  </center>
                </div>
                <div className="card p-col-12 p-xl-6">
                  <img src="assets/images/dws_home.png" width="100%" />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="dialog-section">
          <Dialog
            className="nw-dialog"
            header={`${
              this.state.dialogFormType === true ? "Add" : "Update"
            } Member Information`}
            visible={this.state.visible}
            onHide={this.onHide}
            closable
            maximizable
          >
            <div className="p-fluid">
              <div className="p-grid nw-form">
                <div className="p-col-12 p-xl-12 blur-section">
                  <div className="nw-form-body">
                    {!this.state.dialogFormType ? (
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Serial No. <span>*</span>
                          </span>
                          <InputText
                            placeholder="Enter Serial"
                            name="memberSerial"
                            value={this.state.memberSerial}
                            onChange={(e) => this.onChageInputValue(e)}
                          />
                        </div>
                        <span className="error-message">
                          {errors["memberSerial"]}
                        </span>
                      </div>
                    ) : (
                      <div className="p-col-12 p-xl-12">
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">Serial No.</span>
                          <div className="nw-inputgroup-desc">
                            {this.state.memberSerial
                              ? this.state.memberSerial
                              : this.state.dataTableValue.length + 1}
                          </div>
                        </div>
                        {/* <span className="error-message">{this.state.errors["memberSerial"]}</span> */}
                      </div>
                    )}

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Type <span>*</span>
                        </span>
                        <Dropdown
                          id="type"
                          name="type"
                          placeholder="Select member type"
                          options={memberListOption}
                          value={this.state.memberType}
                          onChange={this.onChangeMemberType}
                          filter={true}
                          filterBy="label,value"
                          showClear={true}
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["type"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Name <span>*</span>
                        </span>
                        <InputText
                          onChange={this.onChangeMemberName}
                          value={this.state.memberName}
                          placeholder="Enter member name"
                          style={{ width: "100%" }}
                          type="text"
                          id="name"
                          name="name"
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["name"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Designation <span>*</span>
                        </span>
                        <InputText
                          id="designation"
                          onChange={this.onChangeMemberDesignation}
                          value={this.state.memberDesignation}
                          placeholder="Enter member designation"
                          style={{ width: "100%" }}
                          type="text"
                          name="designation"
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["designation"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Mobile No. <span></span>
                        </span>
                        <InputText
                          keyfilter="pint"
                          maxLength={11}
                          id="mobileNo"
                          onChange={this.onChangeMemberMobile}
                          value={this.state.memberMobile}
                          placeholder="Enter member mobile number"
                          style={{ width: "100%" }}
                          type="text"
                          name="mobileNo"
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["mobileNo"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Email <span></span>
                        </span>
                        <InputText
                          id="email"
                          onChange={this.onChangeMemberEmail}
                          value={this.state.memberEmail}
                          placeholder="Enter member email address"
                          style={{ width: "100%" }}
                          type="text"
                          name="email"
                          keyfilter="email"
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["email"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Facebook Link{" "}
                        </span>
                        <InputText
                          id="email"
                          onChange={this.onChangeFacebookProfile}
                          value={this.state.facebookProfile}
                          placeholder="Enter member Facebook Link"
                          style={{ width: "100%" }}
                          type="text"
                          name="fb"
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Linkedin Link{" "}
                        </span>
                        <InputText
                          id="email"
                          onChange={this.onChangeLinkedinProfile}
                          value={this.state.linkedinProfile}
                          placeholder="Enter member Linkedin Link"
                          style={{ width: "100%" }}
                          type="text"
                          name="linkedin"
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Twitter Link{" "}
                        </span>
                        <InputText
                          id="email"
                          onChange={this.onChangeTwitterProfile}
                          value={this.state.twitterProfile}
                          placeholder="Enter member twitter link"
                          style={{ width: "100%" }}
                          type="text"
                          name="twitterProfile"
                        />
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup nw-upload-button">
                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                          Upload Image <span>*</span>
                          <br />
                          <span>(PNG/JPG/JPEG)</span>
                        </span>

                        <div className="nw-upload-button-inside">
                          {this.state.uploadFile.fileContent ? (
                            <div className="image-view-main">
                              <div className="upload-image-view">
                                <Button
                                  className="delete-upload-button"
                                  icon="fas fa-times-circle"
                                  onClick={(e) =>
                                    this.setState({
                                      uploadFile: {
                                        fileContent: "",
                                        fileName: "",
                                      },
                                    })
                                  }
                                />
                                <img
                                  src={
                                    this.state.uploadFile.fileContent === ""
                                      ? "assets/images/demo.jpg"
                                      : "data:image/png;base64," +
                                        this.state.uploadFile.fileContent
                                  }
                                  style={{ height: "80px" }}
                                />
                              </div>
                              <div className="image-title">
                                {this.state.uploadFile.fileName}
                              </div>
                            </div>
                          ) : (
                            <FileUpload
                              chooseLabel={
                                this.state.uploadFile.fileName ||
                                uploadFileLabel
                              }
                              mode="basic"
                              accept="image/*"
                              onSelect={(e) => this.onLoadPic(e)}
                              auto={true}
                            />
                          )}
                        </div>
                      </div>
                      {/* <span className='error-message'>{this.state.uploadFile.fileName ? null : this.state.errors.noticeImgContent}</span> */}
                      {this.state.uploadFile &&
                      this.state.uploadFile.fileContent &&
                      this.state.cropperVisible ? (
                        <ImageCropper
                          cropperObject={this.state.cropperObject}
                          getCroppedResult={this.getCroppedResult}
                          removeCropSection={this.removeCropSection}
                        />
                      ) : (
                        ""
                      )}
                      <span className="error-message">
                        {!this.state.uploadFile.fileName &&
                          this.state.errors["image"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12"></div>

                    <div className="p-col-12 p-xl-12 nw-button-parent">
                      <div className="required-field">
                        (<span>*</span>) required fields
                      </div>

                      {this.state.dialogFormType === true ? (
                        <Button
                          className="p-button p-button-primary nw-button nw-button-left"
                          label="Save"
                          icon="fas fa-check"
                          onClick={this.onSubmitMemberInfo.bind(this)}
                          disabled={this.state.btnDisabled}
                          disabled={this.state.cropperVisible ? true : false}
                        />
                      ) : (
                        ""
                      )}

                      {this.state.dialogFormType === false ? (
                        <Button
                          className="p-button p-button-primary nw-button nw-button-left"
                          label="Update"
                          icon="fas fa-check"
                          onClick={this.onUpdateMemberInfo.bind(this)}
                          disabled={this.state.updateBtnDisabled}
                          disabled={this.state.cropperVisible ? true : false}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>

          <Dialog
            className="nw-dialog"
            header="View Member Info"
            visible={this.state.viewMemberInfo}
            onHide={this.onHideMemberInfo}
            closable
          >
            <div className="p-fluid">
              <div className="p-grid nw-form">
                <div className="p-col-12 p-xl-12 blur-section">
                  <div className="nw-form-body">
                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Serial No.</span>
                        <div className="nw-inputgroup-desc">
                          {this.state.memberInfo.memberSerial || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Type</span>
                        <div className="nw-inputgroup-desc">
                          {this.state.memberInfo.memberType || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Speaker Name</span>
                        <div className="nw-inputgroup-desc">
                          {this.state.memberInfo.memberName || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Designation</span>
                        <div className="nw-inputgroup-desc">
                          {this.state.memberInfo.memberDesignation || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Mobile No.</span>
                        <div className="nw-inputgroup-desc">
                          {this.state.memberInfo.memberMobile || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Email</span>
                        <div className="nw-inputgroup-desc">
                          {this.state.memberInfo.memberEmail || "---"}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Upload Image</span>
                        <div style={{ paddingLeft: "22px" }}>
                          {/* {tutorialViewListItem.defaultCode || '---'} */}
                          {this.state.memberInfo &&
                          this.state.memberInfo.memberImg ? (
                            <img
                              src={
                                "data:image/*;base64," +
                                this.state.memberInfo.memberImg
                              }
                              height="auto"
                              width="80px"
                            />
                          ) : (
                            <img src={staticImg} width="100%" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
