import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import NetiContentLoader from "../../common/NetiContentLoader";
import { ManagementPointService } from "../../../service/managementPoint/ManagementPointService";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
import { pdfDownloadLandscape_Two_TableWithSubTitle } from "../../common/JsPdfDownload";
import { Button } from "primereact/button";
import { Workbook } from "react-excel-workbook";
import { SplitButton } from "primereact/splitbutton";

let firstTableTotalLength = 0;
export class AccumulateStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTableValue: [],
      secondDataTableValue: [],
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: "",
      dataTableIsLoading: false,
      secondDataTableIsLoading: false,
      searchDataObj: {
        pageLimit: 10,
        pageNo: 0,
      },
      first: 0,
      rows: 10,
      totalInstitute: 0,

      items: [
        {
          label: "PDF",
          icon: "far fa-file-pdf",
          command: (e) => this.exportPdf(),
        },
        {
          label: "Excel",
          icon: "far fa-file-excel",
          command: (e) => {
            this.exportExcel();
          },
        },
      ],
    };

    this.ManagementPointService = new ManagementPointService();
    this.NetiContentLoader = new NetiContentLoader();
    this.ValidatorUtility = new ValidatorUtility();
    this.onPageChange = this.onPageChange.bind(this);

    this.exportPdf = this.exportPdf.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.exportExcelLog = this.exportExcelLog.bind(this);
  }

  onPageChange(event) {
    let { searchDataObj } = this.state;
    searchDataObj.pageNo = event.page;

    this.setState({
      first: event.first,
      rows: event.rows,
    });

    this.fetchAccumulateStatementInstituteList();
  }

  componentWillMount() {
    this.fetchInstituteListInfo();
    this.fetchAccumulateStatementInstituteList();
  }

  fetchInstituteListInfo = () => {
    let total = 0;
    this.setState({
      topProgressBar: true,
      dataTableIsLoading: true,
      errorMsgVisible: false,
    });
    this.ManagementPointService.fetchInstituteList()
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            let summary = [body];
            summary.map((item) => {
              item.totalInstitute = item.totalInstitute.toLocaleString("EN-IN");
              item.liveInstitute = item.liveInstitute.toLocaleString("EN-IN");
              item.practiceInstitute = item.practiceInstitute.toLocaleString(
                "EN-IN"
              );
              item.liveActiveInstitute = item.liveActiveInstitute.toLocaleString(
                "EN-IN"
              );
              item.liveInactiveInstitute = item.liveInactiveInstitute.toLocaleString(
                "EN-IN"
              );
              item.practiceActiveInstitute = item.practiceActiveInstitute.toLocaleString(
                "EN-IN"
              );
              item.practiceInactiveInstitute = item.practiceInactiveInstitute.toLocaleString(
                "EN-IN"
              );

              item.liveTotalBillAmount = this.ValidatorUtility.currencyFormatter(
                item.liveTotalBillAmount
              );
              item.liveTotalDiscountAmount = this.ValidatorUtility.currencyFormatter(
                item.liveTotalDiscountAmount
              );
              item.liveTotalPaidAmount = this.ValidatorUtility.currencyFormatter(
                item.liveTotalPaidAmount
              );
              item.liveTotalDueAmount = this.ValidatorUtility.currencyFormatter(
                item.liveTotalDueAmount
              );
            });
            this.setState({
              dataTableValue: summary,
              totalInstitute: body.totalInstitute,
            });
            firstTableTotalLength = summary.length;
            this.setState({ topProgressBar: false, dataTableIsLoading: false });
          });
        } else {
          this.ManagementPointService.Auth.handleErrorStatus(res).then(
            (responseBody) => {
              this.setState({
                topProgressBar: false,
                dataTableIsLoading: false,
                errorMsgVisible: true,
                errorMsgBody: responseBody,
              });
            }
          );
        }
      })
      .catch((error) =>
        this.setState({
          topProgressBar: false,
          dataTableIsLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check connection",
        })
      );
  };

  fetchAccumulateStatementInstituteList = () => {
    let searchDataObj = {
      pageLimit: 5000,
      pageNo: 0,
    };

    this.setState({
      topProgressBar: true,
      secondDataTableIsLoading: true,
      errorMsgVisible: false,
    });
    this.ManagementPointService.fetchAccumulateStatementInstituteList(
      searchDataObj
    )
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            body.map((item) => {
              item.status = item.status == 1 ? "Active" : "Inactive";
              item.dueAmount = this.ValidatorUtility.currencyFormatter(
                item.dueAmount
              );
            });
            this.setState({ secondDataTableValue: body });
            this.setState({
              topProgressBar: false,
              secondDataTableIsLoading: false,
            });
          });
        } else {
          this.managementPointService.Auth.handleErrorStatus(res).then(
            (responseBody) => {
              this.setState({
                topProgressBar: false,
                secondDataTableIsLoading: false,
                errorMsgVisible: true,
                errorMsgBody: responseBody,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          secondDataTableIsLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection!!!",
        });
      });
  };

  liveInstituteTemp(rowData) {
    return (
      <span>
        {rowData.liveInstitute} <br /> {rowData.practiceInstitute}{" "}
      </span>
    );
  }

  liveActiveInstituteTemp(rowData) {
    return (
      <span>
        <span style={{ color: "green" }}>{rowData.liveActiveInstitute}</span>{" "}
        <br />{" "}
        <span style={{ color: "red" }}> {rowData.liveInactiveInstitute} </span>
      </span>
    );
  }

  liveDemoInstituteTemp(rowData) {
    return (
      <span>
        <span style={{ color: "green" }}>
          {rowData.practiceActiveInstitute}
        </span>{" "}
        <br />{" "}
        <span style={{ color: "red" }}>
          {rowData.practiceInactiveInstitute}{" "}
        </span>{" "}
      </span>
    );
  }

  liveTotalBillAmount = (rowData) => {
    return (
      <div className="col-number text-right">
        {" "}
        {rowData["liveTotalBillAmount"]}
      </div>
    );
  };

  liveTotalDiscountAmount = (rowData) => {
    return (
      <div className="col-number text-right">
        {" "}
        {rowData["liveTotalDiscountAmount"]}
      </div>
    );
  };

  liveTotalPaidAmount = (rowData) => {
    return (
      <div className="col-number text-right">
        {" "}
        {rowData["liveTotalPaidAmount"]}
      </div>
    );
  };

  liveTotalDueAmount = (rowData) => {
    return (
      <div className="col-number text-right">
        {" "}
        {rowData["liveTotalDueAmount"]}
      </div>
    );
  };

  statusTemplate(rowData) {
    if (rowData.status == "Active") {
      return <span style={{ color: "green" }}>Active</span>;
    } else if (rowData.status == "Inactive") {
      return <span style={{ color: "red" }}>Inactive</span>;
    }
  }

  dueAmount = (rowData) => {
    return <div className="col-number text-right"> {rowData["dueAmount"]}</div>;
  };

  // paginatorTemplateBody = e => {
  // console.log("dataTableValue:::::", dataTableValue);

  // }

  exportPdf() {
    let pdfSubTitleColumn = [
      { title: "Total Institute", dataKey: "totalInstitute" },
      { title: "Institute [Live]", dataKey: "liveInstitute" },
      { title: "Institute [Demo]", dataKey: "practiceInstitute" },
      { title: "Live Institute [Active]", dataKey: "liveActiveInstitute" },
      { title: "Live Institute [Inactive]", dataKey: "liveInactiveInstitute" },
      { title: "Demo Institute [Active]", dataKey: "practiceActiveInstitute" },
      {
        title: "Demo Institute [Inactive]",
        dataKey: "practiceInactiveInstitute",
      },
      { title: "Live Total Bill Amount", dataKey: "liveTotalBillAmount" },
      {
        title: "Live Total Discount Amount",
        dataKey: "liveTotalDiscountAmount",
      },
      { title: "Live Total Paid Amount", dataKey: "liveTotalPaidAmount" },
      { title: "Live Total Due Amount", dataKey: "liveTotalDueAmount" },
    ];

    let subTitleColStyle = {
      dueAmount: { halign: "right" },
      liveActiveInstitute: { textColor: "green" },
      liveInactiveInstitute: { textColor: "red" },
      practiceActiveInstitute: { textColor: "green" },
      practiceInactiveInstitute: { textColor: "red" },

      liveTotalBillAmount: { halign: "right" },
      liveTotalDiscountAmount: { halign: "right" },
      liveTotalPaidAmount: { halign: "right" },
      liveTotalDueAmount: { halign: "right" },
    };

    let tableColumns = [
      { title: "Institute ID", dataKey: "instituteID" },
      { title: "Institute Name", dataKey: "instituteName" },
      { title: "Using Type", dataKey: "usingType" },
      { title: "ID Payment Type", dataKey: "paymentType" },
      { title: "Status", dataKey: "status" },
      { title: "Due Amount", dataKey: "dueAmount" },
      { title: "Partner Neti ID", dataKey: "partnerNetiID" },
      { title: "Partner Name", dataKey: "partnerName" },
    ];

    let tableColStyle = { dueAmount: { halign: "right" } };

    pdfDownloadLandscape_Two_TableWithSubTitle(
      "EM Bill Accumulate Statement Summary & Details",
      94,
      pdfSubTitleColumn,
      this.state.dataTableValue,
      subTitleColStyle,
      tableColumns,
      this.state.secondDataTableValue,
      tableColStyle,
      "EM_Bill_Report_AccumulateStatement.pdf"
    );
  }

  exportExcel() {
    document.getElementById("clickExcelBtn").click();
  }

  exportExcelLog() {
    console.log("excel downloaded");
  }

  render() {
    let {
      topProgressBar,
      errorMsgVisible,
      errorMsgBody,
      dataTableValue,
      totalInstitute,
    } = this.state;

    // excel
    let excelColumns_1stSheet = "";
    excelColumns_1stSheet = [
      <Workbook.Column value="totalInstitute" label="Total Institute" />,
      <Workbook.Column value="liveInstitute" label="Institute [Live]" />,
      <Workbook.Column value="practiceInstitute" label="Institute [Demo]" />,
      <Workbook.Column
        value="liveActiveInstitute"
        label="Live Institute [Active]"
      />,
      <Workbook.Column
        value="liveInactiveInstitute"
        label="Live Institute [Inactive]"
      />,
      <Workbook.Column
        value="practiceActiveInstitute"
        label="Demo Institute [Active]"
      />,
      <Workbook.Column
        value="practiceInactiveInstitute"
        label="Demo Institute [Inactive]"
      />,
      <Workbook.Column
        value="liveTotalBillAmount"
        label="Live Total Bill Amount"
      />,
      <Workbook.Column
        value="liveTotalDiscountAmount"
        label="Live Total Discount Amount"
      />,
      <Workbook.Column
        value="liveTotalPaidAmount"
        label="Live Total Paid Amount"
      />,
      <Workbook.Column
        value="liveTotalDueAmount"
        label="Live Total Due Amount"
      />,
    ];

    let excelColumns_2ndSheet = "";
    excelColumns_2ndSheet = [
      <Workbook.Column value="instituteID" label="Institute ID" />,
      <Workbook.Column value="instituteName" label="Institute Name" />,
      <Workbook.Column value="usingType" label="Using Type" />,
      <Workbook.Column value="paymentType" label="ID Payment Type" />,
      <Workbook.Column value="status" label="Status" />,
      <Workbook.Column value="dueAmount" label="Due Amount" />,
      <Workbook.Column value="partnerNetiID" label="Partner Neti ID" />,
      <Workbook.Column value="partnerName" label="Partner Name" />,
    ];
    // end excel

    let summaryHeader = (
      <div className="header-title">
        <div className="header-title-left">Statement Summary</div>
        <div className="header-title-right">
          <a>Total Found: {firstTableTotalLength}</a>
        </div>
      </div>
    );

    let header = (
      <div className="header-title">
        <div className="header-title-left">
          Institute List & Statement Details
        </div>
        <div className="header-title-right">
          <a>Total Found: {totalInstitute}</a>
        </div>
      </div>
    );

    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <div className="main-section">
          <div className="nw-form">
            <div className="p-col-12 p-xl-12 nw-data-table">
              {this.state.dataTableIsLoading ? (
                this.NetiContentLoader.MyPointTableLoader()
              ) : (
                <DataTable
                  header={summaryHeader}
                  responsive={true}
                  value={this.state.dataTableValue}
                  //selection={this.state.dataTableSelection}
                >
                  <Column
                    field="totalInstitute"
                    header="Total Institute"
                    style={{ width: "80px" }}
                  />
                  <Column
                    field=""
                    header="Institute [Live/Demo]"
                    body={this.liveInstituteTemp.bind(this)}
                    style={{ width: "100px" }}
                  />
                  <Column
                    field=""
                    header="Live Institute [Active/Inactive]"
                    body={this.liveActiveInstituteTemp.bind(this)}
                    style={{ width: "120px" }}
                  />
                  <Column
                    field=""
                    header="Demo Institute [Active/Inactive] "
                    body={this.liveDemoInstituteTemp.bind(this)}
                    style={{ width: "120px" }}
                  />
                  <Column
                    field="liveTotalBillAmount"
                    header="Live Total Bill Amount"
                    body={this.liveTotalBillAmount}
                  />
                  <Column
                    field="liveTotalDiscountAmount"
                    header="Live Total Discount Amount"
                    body={this.liveTotalDiscountAmount}
                  />
                  <Column
                    field="liveTotalPaidAmount"
                    header="Live Total Paid Amount"
                    body={this.liveTotalPaidAmount}
                  />
                  <Column
                    field="liveTotalDueAmount"
                    header="Live Total Due Amount"
                    body={this.liveTotalDueAmount}
                  />
                </DataTable>
              )}
            </div>

            <div className="p-col-12 p-xl-12 p-lg-12 nw-data-table">
              {this.state.secondDataTableIsLoading ? (
                this.NetiContentLoader.MyPointTableLoader()
              ) : (
                <div>
                  <DataTable
                    header={header}
                    responsive={true}
                    selectionMode="single"
                    value={this.state.secondDataTableValue}
                    paginator={true}
                    rows={20}
                    rowsPerPageOptions={[20, 100, 200]}
                  >
                    <Column
                      field="instituteID"
                      header="Institute ID"
                      filter={true}
                    />
                    <Column
                      field="instituteName"
                      header="Institute Name"
                      filter={true}
                    />
                    <Column
                      field="usingType"
                      header="Using Type"
                      filter={true}
                    />
                    <Column
                      field="paymentType"
                      header="ID Payment Type"
                      filter={true}
                    />
                    <Column
                      field="status"
                      header="Status"
                      body={this.statusTemplate}
                      filter={true}
                    />
                    <Column
                      field="dueAmount"
                      header="Due Amount"
                      body={this.dueAmount}
                      filter={true}
                    />
                    <Column
                      field="partnerNetiID"
                      header="Partner Neti ID"
                      filter={true}
                    />
                    <Column
                      field="partnerName"
                      header="Partner Name"
                      filter={true}
                    />
                  </DataTable>
                </div>
              )}
            </div>

            <div className="p-col-12 p-xl-12 nw-button-parent p-r-0">
              <SplitButton
                className="p-button-primary nw-split-button"
                label="Download"
                icon="fas fa-download"
                model={this.state.items}
              ></SplitButton>

              <div style={{ display: "none" }}>
                <Workbook
                  filename={"Em Bill Report Accumulate Statement.xlsx"}
                  element={
                    <Button
                      id="clickExcelBtn"
                      label="Download Excel"
                      icon="fas fa-download"
                      className="p-button p-button-primary nw-button nw-button-right"
                    />
                  }
                >
                  <Workbook.Sheet
                    data={this.state.dataTableValue}
                    name="Accumulate Statement Summary"
                  >
                    {excelColumns_1stSheet}
                  </Workbook.Sheet>
                  <Workbook.Sheet
                    data={this.state.secondDataTableValue}
                    name="Accumulate Statement Institutes"
                  >
                    {excelColumns_2ndSheet}
                  </Workbook.Sheet>
                </Workbook>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
