import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { BalanceService } from '../../../service/myPoint/BalanceService';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { UserOtherAccessService } from '../../../service/UserOtherAccessService';

import { EdumanActionLogService } from '../../../service/actionLog/EdumanActionLogService';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { pdfDownloadPortrait_FullTable, pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';

let selectedModuleNm = '';
let maxDate = new Date();
let totalActions = 0;
let totalPercentage = 0;
let uniqueInstituteIDsArr = [];
let instituteIDArr = [];
let instituteActionLogArr = [];
let unusedInstituteList = [];
let unusedUniqueInstituteList = [];
let status = { display: 'table-cell' };

// pdf
let subTitleColumn = [];
let subTitleList = [];
let pdfColumns = [];

export class EmIndividualModuleInstituteActionLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            emModuleList: [],
            emInstituteList: [],
            useStatus: '',
            dataTableSelection: null,
            dataTableValue: [],
            dateRangeParamFormat: {
                requestStartDate: "",
                requestEndDate: "",
                activityType: '',
                package: '',
                moduleName: ''
            },
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
        };

        this.balanceService = new BalanceService();
        this.CRMPointService = new CRMPointService();
        this.userOtherAccessService = new UserOtherAccessService();
        this.edumanActionLogService = new EdumanActionLogService();

        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
        this.exportPdf = this.exportPdf.bind(this);
    }

    onChangeStartDate = (e) => {
        let { dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        this.clearDateError(e.target.name);
    }

    onChangePackage = (e) => {
        let { errors, dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        errors["package"] = "";
        this.getEmModuleList(e.target.value);
        this.getEmInstituteList(e.target.value);
    }

    onChangeModuleName = (e) => {
        let { errors, dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        errors["moduleName"] = "";
    }

    onChangeUseStatus = (e) => {
        let { errors } = this.state;
        this.setState({ useStatus: e.target.value });
        errors["useStatus"] = "";
    }

    getEmModuleList(param) {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.userOtherAccessService.getEdumanModuleNames(param)
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        this.setState({ emModuleList: body.item })
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.userOtherAccessService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    getEmInstituteList(param) {
        this.setState({ topProgressBar: true, errorMsgVisible: false, emInstituteList: [] });
        this.edumanActionLogService.getEdumanInstituteNames(param)
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        this.setState({ emInstituteList: body.item })
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.userOtherAccessService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    onChangeActionType = (e) => {
        let { errors, dateRangeParamFormat } = this.state;
        dateRangeParamFormat.activityType = e.target.value;
        this.setState({ dateRangeParamFormat });
        errors["activityType"] = "";
    }

    useSubmitHandler = () => {

        let { dateRangeParamFormat, emModuleList, emInstituteList } = this.state;
        instituteActionLogArr = [];
        let startDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestStartDate);
        let endDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestEndDate);
        let searchQueryInfo = {
            "query": `SELECT instituteId, userId, count(*) FROM eduman where moduleName='${this.state.dateRangeParamFormat.moduleName}' and activityType='${dateRangeParamFormat.activityType}' and logDate between '${startDate}' and '${endDate}' group by instituteId, userId`
        }
        let uniqIDs = new Set();
        let usedInstituteActionLog = [];
        pdfColumns = [];
        if (this.searchHandleError()) {

            selectedModuleNm = dateRangeParamFormat.moduleName + '-';
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.edumanActionLogService.getEmModuleUsedUnusedActionLog(searchQueryInfo).then((body) => {
                if (body.length !== 0) {
                    for (let i = 0; i < body.length; i++) {
                        uniqIDs.add(body[i].instituteID);
                    }
                }
                for (let id of uniqIDs) {
                    let actionBody = body.filter(b => b.instituteID == id);
                    let totalUser = actionBody.length;
                    let reducer = (a, b) => a + b;
                    let totalAction = actionBody.map(a => a.actions).reduce(reducer);
                    let institute = emInstituteList.filter(i => i.instituteId == id)[0];
                    if (institute != undefined) {
                        let actionLogObj = {
                            "instituteID": id,
                            "instituteName": `${id} - ${institute.instituteName}`,
                            "instituteStatus": institute.instituteStatus == 1 ? 'Active' : 'Inactive',
                            "noOfUser": totalUser.toLocaleString('EN-IN'),
                            "actions": totalAction.toLocaleString('EN-IN'),
                        }
                        if (totalAction !== 0) {
                            usedInstituteActionLog.push(actionLogObj);
                        }
                    }
                }
                if (this.state.useStatus == "Used") {
                    status = { display: 'table-cell' }
                } else {
                    status = { display: 'none' }
                }

                // for pdf 

                pdfColumns.push(
                    { title: "Institute ID & Name", dataKey: "instituteName" },
                    { title: "Status", dataKey: "instituteStatus" },
                    { title: "No. of User", dataKey: "noOfUser" },
                    { title: "No. of Action", dataKey: "actions" },
                );

                this.setState({ dataTableValue: usedInstituteActionLog });
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
        }
    }

    unUseSubmitHandler = () => {

        let { dateRangeParamFormat, emModuleList, emInstituteList } = this.state;
        instituteActionLogArr = [];
        let startDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestStartDate);
        let endDate = NetiDateUtils.getDateFromString(dateRangeParamFormat.requestEndDate);
        let searchQueryInfo = {
            "query": `SELECT instituteId, userId, count(*) FROM eduman where moduleName='${this.state.dateRangeParamFormat.moduleName}' and activityType='${dateRangeParamFormat.activityType}' and logDate between '${startDate}' and '${endDate}' group by instituteId, userId`
        }
        let uniqIDs = new Set();
        let unUsedInstituteActionLog = [];
        pdfColumns = [];

        if (this.searchHandleError()) {

            selectedModuleNm = dateRangeParamFormat.moduleName + '-';
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.edumanActionLogService.getEmModuleUsedUnusedActionLog(searchQueryInfo).then((body) => {
                if (body.length !== 0) {
                    for (let i = 0; i < body.length; i++) {
                        uniqIDs.add(body[i].instituteID);
                    }
                }
                for (let i = 0; i < emInstituteList.length; i++) {
                    let actionBody = body.filter(b => b.instituteID == emInstituteList[i].instituteId);
                    let totalUser = actionBody.length;
                    if (totalUser === 0) {
                        let actionLogObj = {
                            "instituteID": emInstituteList[i].instituteId,
                            "instituteName": `${emInstituteList[i].instituteId} - ${emInstituteList[i].instituteName}`,
                            "instituteStatus": emInstituteList[i].instituteStatus == 1 ? 'Active' : 'Inactive',
                        }
                        unUsedInstituteActionLog.push(actionLogObj);
                    }
                }
                if (this.state.useStatus == "Unused") {
                    status = { display: 'none' }
                } else {
                    status = { display: 'table-cell' }
                }

                // for pdf 
                pdfColumns.push(
                    { title: "Institute ID & Name", dataKey: "instituteName" },
                    { title: "Status", dataKey: "instituteStatus" },
                )

                this.setState({ dataTableValue: unUsedInstituteActionLog });
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
        }
    }

    searchHandleError = () => {
        let { errors, dateRangeParamFormat } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (dateRangeParamFormat.requestStartDate === '') {
            formIsValid = false;
            errors["requestStartDate"] = "Start Date can't left empty.";
        }
        if (dateRangeParamFormat.requestEndDate === '') {
            formIsValid = false;
            errors["requestEndDate"] = "End Date can't left empty.";
        }
        if (dateRangeParamFormat.package === '') {
            formIsValid = false;
            errors["package"] = "Package can't left empty.";
        }
        if (dateRangeParamFormat.moduleName === '') {
            formIsValid = false;
            errors["moduleName"] = "Module can't left empty.";
        }
        if (this.state.useStatus === '') {
            formIsValid = false;
            errors["useStatus"] = "Status can't left empty.";
        }
        if (dateRangeParamFormat.activityType === '') {
            formIsValid = false;
            errors["activityType"] = "Action Type can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    bodyPercentageTemplate = (rowData) => {
        return (<span>{((rowData['actions'] * 100) / totalActions).toFixed(2) + '%'}</span>);

    }

    bodyInstituteTemplate = (rowData) => {
        return (<span>{`${rowData['instituteID']}  ${rowData['instituteName'] !== undefined ? rowData['instituteName'] : ''} `}</span>);
    }

    exportPdf() {

        let mainTitle =  selectedModuleNm+ "Module " + this.state.useStatus + " Institute Action Log";
        // let pdfColumns = [
        //     { title: "Institute ID & Name", dataKey: "instituteName" },
        //     { title: "Status", dataKey: "instituteStatus" },
        //     { title: "No. of User", dataKey: "noOfUser" },
        //     { title: "No. of Action", dataKey: "actions" },
        // ]

        let tableColStyle = {};

        pdfDownloadPortrait_TableWithSubTitle(mainTitle, 54, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableColStyle, "Individual_Module_Institute_Log.pdf");

    }

    render() {

        let { dateRangeParamFormat, dataTableValue } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        let selectedPkg = dateRangeParamFormat.package;
        let activityType = dateRangeParamFormat.activityType;

        if (dateRangeParamFormat.requestStartDate && dateRangeParamFormat.requestEndDate) {
            formatedStartDate = (dateRangeParamFormat.requestStartDate.toLocaleDateString('en-GB'));
            formatedEndDate = (dateRangeParamFormat.requestEndDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" }, { dataKey: "B" },
            { dataKey: "C" }, { dataKey: "D" },
            { dataKey: "E" }, { dataKey: "F" },
            { dataKey: "G" }, { dataKey: "H" },
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate, E: "Package", F: selectedPkg, G: "Action Type", H: activityType }
        ];
        // pdf reletaed task end

        let tableHeader = <div className="header-title">
            <div className="header-title-left">{selectedModuleNm}Module {this.state.useStatus} Institute Action Log</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        const actionTypes = [
            { label: 'Insert', value: 'Insert' },
            { label: 'Upload', value: 'Upload' },
            { label: 'Update', value: 'Update' },
            { label: 'Fetch', value: 'Fetch' },
            { label: 'Delete', value: 'Delete' },
            { label: 'Download', value: 'Download' },
            { label: 'Login', value: 'Login' },
            { label: 'Logout', value: 'Logout' },
        ]

        const emPackageList = [
            { label: 'PACKAGE_STARTER', value: 'PACKAGE_STARTER' },
            { label: 'PACKAGE_BASIC', value: 'PACKAGE_BASIC' },
            { label: 'PACKAGE_STANDARD', value: 'PACKAGE_STANDARD' },
            { label: 'PACKAGE_ADVANCE', value: 'PACKAGE_ADVANCE' },
        ]

        const useStatusList = [
            { label: 'Used', value: 'Used' },
            { label: 'Unused', value: 'Unused' },
        ]

        let emModuleNamesList = [];
        if (this.state.emModuleList && this.state.emModuleList.length) {
            emModuleNamesList = this.state.emModuleList.map(item => ({
                value: item.moduleName,
                label: item.moduleName
            }));
        }

        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <Growl ref={(el) => this.growl = el} />
                            {topProgressBar ?
                                <ErrorMessageView
                                    topProgressBar={topProgressBar}
                                />
                                : null
                            }
                            {errorMsgVisible ?
                                <ErrorMessageView
                                    errorMsgVisible={errorMsgVisible}
                                    errorMsgBody={errorMsgBody}
                                />
                                : null
                            }
                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                    <Calendar
                                        name='requestStartDate'
                                        value={dateRangeParamFormat.requestStartDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["requestStartDate"]}</span>

                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        name='requestEndDate'
                                        value={dateRangeParamFormat.requestEndDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        dateFormat='dd/mm/yy'
                                        yearRange="2010:2030"
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["requestEndDate"]}</span>
                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Package <span>*</span></span>
                                    <Dropdown
                                        placeholder="Select Package"
                                        name="package"
                                        options={emPackageList}
                                        value={dateRangeParamFormat.package}
                                        onChange={this.onChangePackage}
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["package"]}</span>
                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Module <span>*</span></span>
                                    <Dropdown
                                        placeholder="Select Module"
                                        name="moduleName"
                                        options={emModuleNamesList}
                                        value={dateRangeParamFormat.moduleName}
                                        onChange={this.onChangeModuleName}
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["moduleName"]}</span>
                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Use Status <span>*</span></span>
                                    <Dropdown
                                        placeholder="Select Status"
                                        name="useStatus"
                                        options={useStatusList}
                                        value={this.state.useStatus}
                                        onChange={this.onChangeUseStatus}
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["useStatus"]}</span>
                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Action Type <span>*</span></span>
                                    <Dropdown
                                        placeholder="select action type"
                                        name="activityType"
                                        options={actionTypes}
                                        value={dateRangeParamFormat.activityType}
                                        onChange={this.onChangeActionType}
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["activityType"]}</span>
                            </div>
                            {this.state.useStatus == 'Used' ?
                                <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.useSubmitHandler}
                                    />
                                </div>
                                : ''}

                            {this.state.useStatus == 'Unused' ?
                                <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.unUseSubmitHandler}
                                    />
                                </div>
                                : ''}
                        </div>

                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table">
                                    {this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <DataTable
                                            value={this.state.dataTableValue}
                                            selectionMode="single"
                                            header={tableHeader}
                                            selection={this.state.dataTableSelection}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            paginator={true}
                                            rows={20}
                                            rowsPerPageOptions={[20, 50, 100]}
                                        >
                                            <Column field="instituteName" header="Institute ID & Name" filter={true} sortable={true} />
                                            <Column field="instituteStatus" header="Status" filter={true} sortable={true} body={this.statusBody}/>
                                            <Column field="noOfUser" header="No. of User" filter={true} sortable={true} style={status} />
                                            <Column field="actions" header="No. of Action" filter={true} sortable={true} style={status} />
                                        </DataTable>
                                    }
                                </div>
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="nw-button-parent p-r-0">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Download PDF"
                                        icon="fas fa-download"
                                        onClick={this.exportPdf}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}