import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MessageRechargeService } from "../../../service/myPoint/MessageRechargeService";
import { PurchaseService } from "../../../service/myPoint/PurchaseService";
import { NetiDateUtils } from "../../../utils/NetiDateUtils";
import { MY_POINT } from "../../../utils/PointWiseRouteConsts";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
import CommonFuctionality from "../../common/CommonFuctionality";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { pdfDownloadLandscape_TableOnly } from "../../common/JsPdfDownload";
import NetiContentLoader from "../../common/NetiContentLoader";
import { WalletBalance } from "../common/WalletBalance";

let errors = {};

export class PurchaseGeneralProductClone extends Component {
  constructor() {
    super();
    this.state = {
      purchasePointList: "",
      productList: "",
      purchasePoint: null,
      productRoleAssignID: 0,
      productType: null,
      productName: {
        salesPrice: 0,
        percentVat: 0,
        productID: null,
      },
      productQuantity: "",
      productPurchaseID: "",

      errors: {},
      dialogpurchasePoint: null,
      dialogProductType: null,
      dialogProductName: null,
      dialogProductQuantity: null,
      dialogProductDate: null,

      dataTableSelection: null,
      dataViewValue: [],
      dataTableValue: [],
      purchaseCodeListTable: [],
      productNameOptions: [],
      todayPurchageAmountBody: [],
      dataTableIsLoading: false,
      secondDataTableIsLoading: false,
      firstDropdownIsLoading: false,
      secondDropdownIsLoading: false,

      // reloadComponent: false
    };

    this.onChangePurchasePoint = this.onChangePurchasePoint.bind(this);
    this.productNameOnChange = this.productNameOnChange.bind(this);
    this.onChangeProductQuantity = this.onChangeProductQuantity.bind(this);
    this.fetchProductsListByRole = this.fetchProductsListByRole.bind(this);

    // this.purchaseDateBody = this.purchaseDateBody.bind(this);
    this.purchaseCodeBody = this.purchaseCodeBody.bind(this);
    this.viewDialog = this.viewDialog.bind(this);
    this.onHide = this.onHide.bind(this);
    this.purchaseProductOnSubmit = this.purchaseProductOnSubmit.bind(this);
    this.fetchTopTenGeneralPurchaseList = this.fetchTopTenGeneralPurchaseList.bind(
      this
    );

    this.MessageRechargeService = new MessageRechargeService();
    this.PurchaseService = new PurchaseService();
    this.NetiContentLoader = new NetiContentLoader();
    this.validatorUtility = new ValidatorUtility();
    this.CommonFuctionality = new CommonFuctionality();
  }

  componentWillMount() {
    // this.fetchPurchasePointInfo();

    this.fetchTopTenGeneralPurchaseList();

    this.todayPurchageAmount();

    this.fetchProductsListByRole();
  }

  fetchPurchasePointInfo() {
    this.setState({
      firstDropdownIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });
    this.MessageRechargeService.getPurchasePoint()
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({ purchasePointList: body });
            this.setState({
              firstDropdownIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.MessageRechargeService.Auth.handleErrorStatus(res).then(
            (resp) => {
              this.setState({
                firstDropdownIsLoading: false,
                topProgressBar: false,
                errorMsgVisible: true,
                errorMsgBody: resp,
              });
            }
          );
        }
      })
      .catch((error) => {
        errors["purchasePoint"] = "Connection Problem";
        this.setState({ errors: errors["purchasePoint"] });
        this.setState({
          firstDropdownIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  }

  fetchProductsListByRole() {
    this.setState({
      topProgressBar: true,
      secondDropdownIsLoading: true,
      errorMsgVisible: false,
    });
    this.PurchaseService.fetchGeneralProduct()
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            this.setState({ productList: body });
            this.setState({ productNameOptions: body });
            this.setState({
              secondDropdownIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.MessageRechargeService.Auth.handleErrorStatus(res).then(
            (resp) => {
              this.setState({
                secondDropdownIsLoading: false,
                topProgressBar: false,
                errorMsgVisible: true,
                errorMsgBody: resp,
              });
            }
          );
        }
      })
      .catch((error) => {
        errors["products"] = "Connection Problem";
        this.setState({ errors: errors["products"] });
        this.setState({
          secondDropdownIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection",
        });
      });
  }

  fetchTopTenGeneralPurchaseList() {
    this.setState({
      topProgressBar: true,
      dataTableIsLoading: true,
      errorMsgVisible: false,
    });
    this.PurchaseService.fetchGeneralPurchaseProductListTopTen()
      .then((res) => {
        if (res.status === 302) {
          return res.json().then((body) => {
            for (let i = 0; i < body.length; i++) {
              if (body[i].purchaseDate == null) {
                body[i].purchaseDate = "";
              } else {
                body[i].purchaseDate =
                  NetiDateUtils.getAnyShortForm(
                    body[i].purchaseDate,
                    "DD-MMM-YYYY"
                  ) +
                  " " +
                  NetiDateUtils.getHHMMSSFForHome(body[i].purchaseDate);
              }

              body[i].paidAmount = body[i].paidAmount.toFixed(2);
            }
            //for pdf column
            body.map((item) => {
              let coreRoleNote = String(
                item.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote
              );
              let categoryName = String(
                item.productInfoDTO.productTypeInfoDTO.categoryName
              );
              let productName = String(item.productInfoDTO.productName);
              item.purchaseInvoiceID = item.purchaseInvoiceID.toString();
              item.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote =
                item.productRoleAssignDTO.coreUserRoleDTO.coreRoleNote;
              item.productInfoDTO.productTypeInfoDTO.categoryName =
                item.productInfoDTO.productTypeInfoDTO.categoryName;
              item.productInfoDTO.productName = item.productInfoDTO.productName;
              Object.assign(item, {
                purchaseCoreRoleNote: coreRoleNote,
                categoryName: categoryName,
                productName: productName,
              }); // for pdf column
            });
            this.setState({ dataTableValue: body });
            this.setState({
              topProgressBar: false,
              dataTableIsLoading: false,
              errorMsgVisible: false,
            });
          });
        } else {
          this.PurchaseService.Auth.handleErrorStatus(res).then((resp) => {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          topProgressBar: false,
          dataTableIsLoading: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection !!!",
        });
      });
  }

  handleError() {
    errors = {};
    let formIsValid = true;

    // if (!this.state.purchasePoint) {
    //   formIsValid = false;
    //   errors["purchasePoint"] = "Purchase Product can't left empty.";
    // } else
    if (!this.state.productName) {
      formIsValid = false;
      errors["productNameError"] = "Product Name can't left empty.";
    } else if (!this.state.productQuantity) {
      formIsValid = false;
      errors["productQuantity"] = "Product Quantity can't left empty.";
    } else {
      return formIsValid;
    }
    this.setState({ errors: errors });
  }

  purchaseProductOnSubmit = (e) => {
    if (this.handleError()) {
      this.setState({
        topProgressBar: true,
        dataTableIsLoading: true,
        errorMsgVisible: false,
      });
      let { productNameOptions } = this.state;

      let { salesPrice, percentVat, productID } = this.state.productName;
      let totalPrice = this.state.productQuantity * salesPrice;
      let payableAmount = (totalPrice * percentVat) / 100 + totalPrice;

      let vatAmount =
        (salesPrice * percentVat * this.state.productQuantity) / 100;

      let productRoleAssignID = 0;
      for (let i = 0; i < productNameOptions.length; i++) {
        if (productNameOptions[i].productId == productID) {
          productRoleAssignID = productNameOptions[i].productRoleAssignId;
        }
      }

      let purchaseBody = {
        purchaseQuantity: this.state.productQuantity,
        unitPrice: salesPrice,
        totalAmount: totalPrice,
        vatAmount: vatAmount,
        paidAmount: payableAmount,

        productInfoDTO: {
          productID: productID,
        },
        productRoleAssignDTO: {
          productRoleAssignID: productRoleAssignID,
        },
      };

      this.PurchaseService.submitPurchaseGeneralProduct(purchaseBody)
        .then((res) => {
          if (res.status == 201) {
            this.setState({ successmessage: true });
            this.growl.show({
              severity: "success",
              summary: "Success",
              detail: "Successfully Submitted",
            });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              productQuantity: "",
              purchasePoint: null,
              productName: {
                salesPrice: 0,
                percentVat: 0,
              },
            });
            this.fetchTopTenGeneralPurchaseList();
            this.todayPurchageAmount();
          } else {
            this.PurchaseService.Auth.handleErrorStatus(res).then(
              (responseBody) => {
                this.setState({
                  errorMsgVisible: true,
                  errorMsgBody: responseBody,
                  dataTableIsLoading: false,
                  topProgressBar: false,
                });
              }
            );
          }
        })
        .catch((error) => {
          this.setState({ fetchError: false });

          this.setState({
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection !!!",
          });
        });
    }
  };

  onChangePurchasePoint = (e) => {
    errors["purchasePoint"] = "";
    this.setState({
      purchasePoint: e.value,
      productName: {
        salesPrice: 0,
        percentVat: 0,
      },
    });
    if (e.value) {
      this.setState({
        topProgressBar: true,
        secondDropdownIsLoading: true,
        errorMsgVisible: false,
      });
      this.PurchaseService.fetchGeneralProductByUserRole(e.value)
        .then((res) => {
          if (res.status == 302) {
            return res.json().then((body) => {
              this.setState({
                productNameOptions: body,
                topProgressBar: false,
                secondDropdownIsLoading: false,
              });
            });
          } else {
            this.PurchaseService.Auth.handleErrorStatus(res).then(
              (responseBody) => {
                this.setState({
                  errorMsgVisible: true,
                  errorMsgBody: responseBody,
                  topProgressBar: false,
                  secondDropdownIsLoading: false,
                });
              }
            );
          }
        })
        .catch((error) => {
          // errors["productName"] = 'Connection Problem';
          this.setState({
            errors: errors["purchasePoint"],
            topProgressBar: false,
            secondDropdownIsLoading: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection !!!",
          });
        });
    } else {
      this.setState({
        productNameOptions: [],
      });
    }
  };

  componentDidMount() {
    console.log(this.state.productList);
  }

  productNameOnChange = (e) => {
    errors["productNameError"] = "";
    this.setState({
      productName: e.value,
    });
  };

  onChangeProductQuantity = (e) => {
    errors["productQuantity"] = "";
    this.setState({
      productQuantity: e.target.value,
      errors: errors,
    });
  };

  viewDialog(e, rowData) {
    this.CommonFuctionality.blurDialogBackgroundActive();
    this.setState({ visible: true });
    this.setState({ purchaseCodeListTable: [] });
    this.setState({
      dialogpurchasePoint:
        rowData.productRoleAssignDTO.coreUserRoleDTO.coreRoleName,
    });
    this.setState({
      dialogProductType: rowData.productInfoDTO.productTypeInfoDTO.categoryName,
    });
    this.setState({ dialogProductName: rowData.productInfoDTO.productName });
    this.setState({ dialogProductQuantity: rowData.purchaseQuantity });
    this.setState({ dialogProductDate: rowData.purchaseDate });
    this.setState({ productPurchaseID: rowData.productPurchaseID });

    this.setState({
      secondDataTableIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false,
    });
    this.PurchaseService.fetchProductPurchaseCodeDetailsByPurchaseId(
      rowData.productPurchaseID
    )
      .then((res) => {
        if (res.status == 302) {
          return res.json().then((body) => {
            this.setState({
              purchaseCodeListTable: body,
              secondDataTableIsLoading: false,
              topProgressBar: false,
            });
          });
        } else {
          this.PurchaseService.Auth.handleErrorStatus(res).then(
            (responseBody) => {
              this.setState({
                errorMsgVisible: true,
                errorMsgBody: responseBody,
                topProgressBar: false,
                secondDataTableIsLoading: false,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          secondDataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Please check your connection !!!",
        });
      });
  }

  usedDateBody = (rowData) => {
    return (
      <span>
        {rowData.usedDate != null
          ? NetiDateUtils.getAnyShortForm(rowData.usedDate, "DD-MMM-YYYY")
          : ""}
      </span>
    );
  };

  statusCheck = (rowData) => {
    return (
      <span>
        {rowData.usedStatus == 0 ? "Unused" : ""}
        {rowData.usedStatus == 1 ? "Used" : ""}
      </span>
    );
  };

  onHide(event) {
    this.setState({ visible: false });

    this.CommonFuctionality.blurDialogBackgroundDeActive();
  }

  purchaseCodeBody(rowData) {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button info"
          icon="fas fa-info"
          title="View Purchase"
          onClick={(e) => this.viewDialog(e, rowData)}
        />
      </div>
    );
  }

  todayPurchageAmount = () => {
    let fromDate = NetiDateUtils.getCurrentDate();
    let toDate = fromDate;

    if (fromDate != null && toDate != null) {
      this.setState({
        topProgressBar: true,
        dataTableIsLoading: true,
        errorMsgVisible: false,
      });
      this.PurchaseService.fetchGeneralPurchaseProductListByDateRange(
        fromDate,
        toDate
      )
        .then((res) => {
          if (res.status == 302) {
            return res.json().then((body) => {
              this.setState({
                todayPurchageAmountBody: body,
                topProgressBar: false,
                dataTableIsLoading: false,
                errorMsgVisible: false,
              });
            });
          } else {
            this.PurchaseService.Auth.handleErrorStatus(res).then(
              (responseBody) => {
                this.setState({
                  topProgressBar: false,
                  errorMsgVisible: false,
                  dataTableIsLoading: false,
                });
              }
            );
          }
        })
        .catch((error) => {
          this.setState({
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection !!!",
            dataTableIsLoading: false,
          });
        });
    } else {
      this.growl.show({
        severity: "error",
        detail: "Please fill up date field properly.",
      });
    }
  };

  purchaseAmountBody = (rowData) => {
    return (
      <span>
        {" "}
        {rowData["paidAmount"]
          ? this.validatorUtility.currencyFormatter(rowData["paidAmount"])
          : ""}
      </span>
    );
  };

  productQuantityBody = (rowData) => {
    return <b> {rowData.purchaseQuantity.toLocaleString("EN-IN")}</b>;
  };

  exportPdf = () => {
    let tableColumns = [
      { title: "Date", dataKey: "purchaseDate" },
      { title: "Purchase Point", dataKey: "purchaseCoreRoleNote" },
      { title: "Invoice ID", dataKey: "purchaseInvoiceID" },
      { title: "Product Type", dataKey: "categoryName" },
      { title: "Product Name", dataKey: "productName" },
      { title: "Product Quantity", dataKey: "purchaseQuantity" },
      { title: "Paid Amount (with VAT)", dataKey: "paidAmount" },
    ];

    let tableColumnStyles = {
      paidAmount: { halign: "right" },
    };

    pdfDownloadLandscape_TableOnly(
      "General Product Purchase Report",
      105,
      tableColumns,
      this.state.dataTableValue,
      tableColumnStyles,
      "General_Product_Purchase_Report.pdf"
    );
  };

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

    let { salesPrice, percentVat } = this.state.productName;
    let totalPrice = this.state.productQuantity * salesPrice;
    let vatAmount =
      (salesPrice * percentVat * this.state.productQuantity) / 100;

    let payableAmount = (totalPrice * percentVat) / 100 + totalPrice;

    let purchasePointOptions = [];
    if (this.state.purchasePointList && this.state.purchasePointList.length) {
      purchasePointOptions = this.state.purchasePointList.map((item) => ({
        value: item.coreRoleID,
        label: item.coreRoleName,
      }));
    }

    let productNameOptionsList = [];
    if (this.state.productNameOptions && this.state.productNameOptions.length) {
      productNameOptionsList = this.state.productNameOptions.map((item) => {
        const prices = {
          salesPrice: item.salesPrice,
          percentVat: item.vatPercent,
          productID: item.productId,
        };
        return {
          value: prices,
          label: item.productName,
        };
      });
    }

    console.log({ productNameOptionsList });

    let totalPurchaseToday = [];
    let totalAmount = 0;
    totalPurchaseToday = this.state.todayPurchageAmountBody.map((item) => ({
      totalPurchase: item.paidAmount,
    }));
    for (var i = 0; i < totalPurchaseToday.length; i++) {
      totalAmount += totalPurchaseToday[i].totalPurchase;
    }

    let tableHeader = (
      <div className="header-title">
        <div className="header-title-left">Product Purchase List</div>
        <div className="header-title-right">
          <Link to={{ pathname: MY_POINT.PURCHASE_LOG_GENERAL }}>
            More
            <i className="fas fa-angle-right" style={{ fontSize: "28px" }}></i>
          </Link>
        </div>
      </div>
    );

    return (
      <div className="p-fluid">
        <Growl ref={(el) => (this.growl = el)} />

        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}

        <div className="main-section blur-section">
          <div className="p-grid nw-form">
            <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
              <div className="p-col-12 p-xl-12">
                <div className="nw-form-body">
                  <div className="p-grid seprator-inside">
                    {/* <div className="p-col-12 p-xl-12">
                      {this.state.firstDropdownIsLoading ? (
                        this.NetiContentLoader.normalFormInputField()
                      ) : (
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Purchase Point <span>*</span>
                          </span>
                          <Dropdown
                            name="purchasePoint"
                            options={purchasePointOptions}
                            value={this.state.purchasePoint}
                            onChange={(e) => this.onChangePurchasePoint(e)}
                            autoWidth={false}
                          />
                        </div>
                      )}
                      <span className="error-message">
                        {this.state.errors["purchasePoint"]}
                      </span>
                    </div> */}

                    <div className="p-col-12 p-xl-12">
                      {this.state.secondDropdownIsLoading ? (
                        this.NetiContentLoader.normalFormInputField()
                      ) : (
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">
                            Product Name <span>*</span>
                          </span>
                          <Dropdown
                            name="productName"
                            options={productNameOptionsList}
                            value={this.state.productName}
                            onChange={(e) => this.productNameOnChange(e)}
                            autoWidth={false}
                            // filter={true}
                          />
                        </div>
                      )}
                      <span className="error-message">
                        {this.state.errors["productNameError"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Product Quantity <span>*</span>
                        </span>
                        <InputText
                          keyfilter="pint"
                          name="productQuantity"
                          value={this.state.productQuantity}
                          onChange={(e) => this.onChangeProductQuantity(e)}
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["productQuantity"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Unit Price
                        </span>
                        <div className="nw-inputgroup-desc">
                          {" "}
                          {this.validatorUtility.currencyFormatter(
                            salesPrice
                          )}{" "}
                          Tk{" "}
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Total Price
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.validatorUtility.currencyFormatter(totalPrice)}{" "}
                          Tk
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Vat Amount
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.validatorUtility.currencyFormatter(vatAmount)}{" "}
                          Tk
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputgroup-label">
                          Payable Amount
                        </span>
                        <div className="nw-inputgroup-desc">
                          {this.validatorUtility.currencyFormatter(
                            payableAmount
                          )}{" "}
                          Tk
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12 "></div>

                    <div className="p-col-12 p-xl-12 nw-button-parent">
                      <div className="required-field">
                        (<span>*</span>) required fields
                      </div>

                      <Button
                        className="p-button p-button-primary nw-button nw-button-right"
                        label="Save"
                        icon="fas fa-check"
                        onClick={(event) => this.purchaseProductOnSubmit(event)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-col-12 p-xl-4 p-col-nogutter">
              <div className="p-col-12 p-xl-12">
                {this.state.dataTableIsLoading ? (
                  ""
                ) : (
                  <WalletBalance walletBalanceName="Wallet" />
                )}
              </div>

              <div className="p-col-12 p-xl-12">
                {this.state.dataTableIsLoading ? (
                  this.NetiContentLoader.WalletLoaderContent()
                ) : (
                  <div className="nw-wallet">
                    <center className="today-purchase-balance">
                      <h2>
                        <i class="fas fa-calendar-day fa-3x"></i>
                      </h2>
                      <div className="nw-wallet-title">
                        Today's Purchase Amount
                      </div>

                      <div className="nw-wallet-balance">
                        <div>
                          <span>৳</span>{" "}
                          {totalAmount !== 0
                            ? this.validatorUtility.currencyFormatter(
                                parseFloat(totalAmount)
                              )
                            : "0.00"}{" "}
                        </div>
                      </div>
                    </center>
                  </div>
                )}
              </div>
            </div>

            <div className="p-col-12 p-xl-12">
              <div className="nw-data-table">
                {!this.state.dataTableIsLoading ? (
                  <div>
                    {this.state.dataTableValue ? (
                      <div>
                        <DataTable
                          header={tableHeader}
                          value={this.state.dataTableValue}
                          selectionMode="single"
                          selection={this.state.dataTableSelection}
                          onSelectionChange={(event) =>
                            this.setState({ dataTableSelection: event.value })
                          }
                          responsive={true}
                          paginator={true}
                          rows={10}
                        >
                          <Column
                            field="purchaseDate"
                            header="Date & Time"
                            sortable={true}
                            style={{ width: "110px" }}
                            filter={true}
                          />
                          <Column
                            field="productRoleAssignDTO.coreUserRoleDTO.coreRoleNote"
                            header="Purchase Point"
                            sortable={true}
                            filter={true}
                          />
                          <Column
                            field="purchaseInvoiceID"
                            header="Invoice ID"
                            sortable={true}
                            filter={true}
                          />
                          <Column
                            field="productInfoDTO.productName"
                            header="Product Name"
                            sortable={true}
                            filter={true}
                          />
                          <Column
                            field="purchaseQuantity"
                            header="Product Quantity"
                            body={this.productQuantityBody}
                            sortable={true}
                            filter={true}
                          />
                          <Column
                            field="paidAmount"
                            header="Paid Amount (with VAT)"
                            body={this.purchaseAmountBody}
                            sortable={true}
                            filter={true}
                            className="text-right"
                          />
                          <Column
                            field=""
                            header="Purchase Code"
                            body={this.purchaseCodeBody}
                            style={{ width: "96px" }}
                          />
                        </DataTable>
                        <div className="nw-button-parent m-t-8 p-r-0">
                          <Button
                            className="p-button p-button-primary nw-button nw-button-right"
                            label="Download"
                            icon="fas fa-download"
                            onClick={this.exportPdf}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  this.NetiContentLoader.MyPointTableLoader()
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="dialog-section">
          <Dialog
            id="dialog"
            className="nw-dialog"
            header="General Product Purchase Code Info"
            visible={this.state.visible}
            onHide={this.onHide}
            maximizable
          >
            <div className="p-fluid">
              <div className="p-grid">
                <div className="p-col-6 p-xl-6">
                  <span>Purchase Point : {this.state.dialogpurchasePoint}</span>
                  <br />
                  <span>Product Type : {this.state.dialogProductType}</span>
                  <br />
                  <span>Product Name : {this.state.dialogProductName} </span>
                  <br />
                </div>
                <div className="p-col-6 p-xl-6">
                  <span>Date : {this.state.dialogProductDate} </span>
                  <br />
                  <span>Quantity: {this.state.dialogProductQuantity} </span>
                  <br />
                </div>

                <div className="p-col-12 p-xl-12 nw-data-table">
                  {this.state.secondDataTableIsLoading ? (
                    this.NetiContentLoader.MyPointTableLoader()
                  ) : (
                    <DataTable
                      header={"Purchase Code List"}
                      value={this.state.purchaseCodeListTable}
                      onSelectionChange={(event) =>
                        this.setState({ dataTableSelection: event.value })
                      }
                      responsive={true}
                      paginator={true}
                      rows={10}
                    >
                      <Column
                        field="purchaseCode"
                        header="Purchase Code"
                        sortable={true}
                        filter={true}
                      />
                      <Column
                        field="usedStatus"
                        header="Status"
                        body={this.statusCheck}
                        sortable={true}
                        filter={true}
                      />
                      <Column
                        field=""
                        header="Used Date"
                        body={this.usedDateBody}
                        sortable={true}
                        filter={true}
                      />
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
