import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { Growl } from 'primereact/growl';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { DownloadButton } from "../../../utils/DownloadButton";
import { Workbook } from 'react-excel-workbook';
import { pdfDownloadLandscape_FullTable } from '../../common/JsPdfDownload';
import { SplitButton } from 'primereact/splitbutton';
import CommonFuctionality from '../../common/CommonFuctionality';

let totalPaidAmount = 0;
let maxDate = new Date();

// for pdf
let subTitleColumn = [];
let subTitleList = [];
export class CollectiveStatementPaid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            paidSubmitInfo: {
                startDate: '',
                endDate: ''
            },
            collectivePaidInstituteList: [],
            paidDetailsInfo: {},
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            moneyReceipt: '',
            dataTableIsLoading: false,

            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }

        this.managementPointService = new ManagementPointService();
        this.onHide = this.onHide.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.paidDetailsBody = this.paidDetailsBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
        this.CommonFuctionality = new CommonFuctionality();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    onChangeFromDate = (e) => {
        let { paidSubmitInfo, errors } = this.state;
        errors["fromDate"] = "";
        paidSubmitInfo.startDate = e.target.value
        this.setState({ paidSubmitInfo, errors });
    }

    onChangeToDate = (e) => {
        let { paidSubmitInfo, errors } = this.state;
        errors["toDate"] = "";
        paidSubmitInfo.endDate = e.target.value
        this.setState({ paidSubmitInfo, errors });
    }

    onSubmitPaid = () => {
        let paidSubmitInfo = { ...this.state.paidSubmitInfo }
        totalPaidAmount = 0;
        paidSubmitInfo.pageLimit = 10;
        paidSubmitInfo.pageNo = 0;
        paidSubmitInfo.startDate = NetiDateUtils.getDateFromString(paidSubmitInfo.startDate)
        paidSubmitInfo.endDate = NetiDateUtils.getDateFromString(paidSubmitInfo.endDate)
        if (this.onSubmitPaidFormError()) {
            if (Date.parse(paidSubmitInfo.endDate) >= Date.parse(paidSubmitInfo.startDate)) {
                this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
                this.managementPointService.getEdumanCollectiveStatementPaidByDate(paidSubmitInfo)
                    .then(res => {
                        if (res.status == 302) {
                            return res.json().then((body) => {
                                // console.log('body', body);
                                
                                body.map(item => {
                                    item.partnerNetiID = item.partnerNetiID.toString();
                                    item.date = NetiDateUtils.getAnyShortForm(item.date, 'DD-MMM-YYYY hh:mm:ss a');
                                    item.paidAmount = item.paidAmount.toFixed(2);
                                })
                                totalPaidAmount = body.map(item => +item.paidAmount).reduce((a, b) => { return (a + b) }, 0);
                                this.setState({ collectivePaidInstituteList: body });
                                this.setState({ topProgressBar: false, dataTableIsLoading: false });
                            })
                        }
                        else {
                            this.managementPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                                });
                        }
                    }).catch((error) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
                    });
            } else {
                this.growl.show({ severity: 'warn', summary: 'Warning', detail: "To Date can not be earlier than From Date." });
            }
        }
    }

    onSubmitPaidFormError = () => {
        let { errors, paidSubmitInfo } = this.state;
        let formIsValid = true;

        if (paidSubmitInfo.startDate === '') {
            formIsValid = false;
            errors["fromDate"] = "Start Date can't left empty.";
        }
        if (paidSubmitInfo.endDate === '') {
            formIsValid = false;
            errors["toDate"] = "End Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    viewDialog(rowData) {

        this.setState({ paidDetailsInfo: rowData, moneyReceipt: '' });
        if (rowData.attachmentPath !== null) {
            this.netiFileHandler.getByteImage(rowData.attachmentPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(rowData.attachmentName);
                                this.setState({ moneyReceipt: contentType + body.fileContent });

                            })
                    } else {
                    }
                });
        }
        this.setState({ visible: true });
        this.CommonFuctionality.blurDialogBackgroundActive();

    }

    onHide(event) {
        this.setState({ visible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    paidDetailsBody(rowData) {

        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-info"
                title='Click to view deposit details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    PaidAmountTemp = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.paidAmount)
    }

    paidAmountBody = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData.paidAmount)} </div>)
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Date & Time", dataKey: "date" },
            { title: "Institute ID", dataKey: "instituteId" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Paid Amount", dataKey: "paidAmount" },
            { title: "Partner Neti ID", dataKey: "partnerNetiID" },
            { title: "Partner Name", dataKey: "partnerName" },
        ];

        let tableColStyle = { paidAmount: { halign: 'right' } };
        var totalArrayList = [
            {
                id: "1",
                totalPdf: "Total Paid Amount : " + totalPaidAmount,
            }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }];

        let totalColumnStyles = {
            totalPdf: { halign: "center", fontSize: 10, fontStyle: "bold", overflow: "linebreak" },
        }

        pdfDownloadLandscape_FullTable("EM Collective Paid Bill Report", 110, subTitleColumn, subTitleList, pdfColumns, this.state.collectivePaidInstituteList, tableColStyle, totalColumns, totalArrayList, totalColumnStyles, "EM_Collective_Paid_Bill_Report.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (this.state.paidSubmitInfo.startDate && this.state.paidSubmitInfo.endDate) {
            formatedStartDate = (this.state.paidSubmitInfo.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (this.state.paidSubmitInfo.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = formatedStartDate + ' to ' + formatedEndDate + " Collective Paid Em Bill.xlsx";
        excelColumns = [
            <Workbook.Column value="date" label="Date & Time" />,
            <Workbook.Column value="instituteId" label="Institute ID" />,
            <Workbook.Column value="instituteName" label="Institute Name" />,
            <Workbook.Column value="paidAmount" label="Paid Amount" />,
            <Workbook.Column value="partnerNetiID" label="Partner Neti ID" />,
            <Workbook.Column value="partnerName" label="Partner Name" />,
        ];
        // end excel

        let { topProgressBar, errorMsgVisible, errorMsgBody, paidDetailsInfo, collectivePaidInstituteList } = this.state;

        if (collectivePaidInstituteList.length == 0) { totalPaidAmount = 0; }

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Institute List</div><div className="header-title-right"><a>Total Found: {collectivePaidInstituteList.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
                <Column footer={this.ValidatorUtility.currencyFormatter(totalPaidAmount)} />
                <Column footer="" colSpan={3} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-5 p-lg-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Start Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={this.state.paidSubmitInfo.startDate}
                                        onChange={this.onChangeFromDate}
                                        showIcon={true}
                                        dateFormat="dd/mm/yy"
                                        name="fromDate"
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["fromDate"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-5 p-lg-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">End Date <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        value={this.state.paidSubmitInfo.endDate}
                                        onChange={this.onChangeToDate}
                                        showIcon={true}
                                        dateFormat="dd/mm/yy"
                                        name="toDate"
                                        placeholder="Select Date"
                                        autoWidth={false}
                                        yearRange="2010:2030"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        showButtonBar={true}
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["toDate"]}</span>
                            </div>
                            <div className="p-col-12 p-xl-2 p-lg-12 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitPaid}
                                />
                            </div>
                        </div>

                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table nw-data-table-tabview">
                                    {
                                        this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            :
                                            <div>
                                                <DataTable
                                                    header={tableHeader}
                                                    value={this.state.collectivePaidInstituteList}
                                                    footerColumnGroup={footer}
                                                    responsive={true}
                                                    selectionMode="single"
                                                    paginator={true}
                                                    rows={20}
                                                    rowsPerPageOptions={[20, 50, 100]}
                                                >
                                                    <Column field="date" header="Date & Time" className="table-datetime" filter={true} />
                                                    <Column field="instituteId" header="Institute ID" filter={true} />
                                                    <Column field="instituteName" header="Institute Name" filter={true} />
                                                    <Column field="paidAmount" header="Paid Amount" body={this.paidAmountBody} filter={true} />
                                                    <Column field="partnerNetiID" header="Partner Neti ID" filter={true} />
                                                    <Column field="partnerName" header="Partner Name" filter={true} />
                                                    <Column field="details" header="Details" body={this.paidDetailsBody} style={{ width: '95px' }}/>
                                                </DataTable>
                                            </div>

                                    }
                                </div>

                            </div>

                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                <div style={{ display: 'none' }}>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                id="clickExcelBtn"
                                                label="Download Excel"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.collectivePaidInstituteList} name={'collective_paid_institutes'}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>

                            </div >

                        </div>

                    </div>

                </div>

                <div className="dialog-section">
                    <Dialog className="nw-dialog" header="Payment Information" visible={this.state.visible} onHide={this.onHide} closable>
                        <div className="nw-dataview-small-panel">
                            <div>
                                <div>Bill Month</div>
                                <div>:</div>
                                <div> {paidDetailsInfo.billMonth}</div>
                            </div>

                            <div>
                                <div>Payable Amount</div>
                                <div>:</div>
                                <div> {paidDetailsInfo.payableBill}</div>
                            </div>

                            <div>
                                <div>Discount Amount</div>
                                <div>:</div>
                                <div> {paidDetailsInfo.discountAmount}</div>
                            </div>

                            <div>
                                <div>Paid Amount</div>
                                <div>:</div>
                                <div> {paidDetailsInfo.paidAmount}</div>
                            </div>

                            <div>
                                <div>Paid By(Neti ID)</div>
                                <div>:</div>
                                <div> {paidDetailsInfo.partnerNetiID}</div>
                            </div>

                            <div>
                                <div>Paid By(Name)</div>
                                <div>:</div>
                                <div> {paidDetailsInfo.partnerName}</div>
                            </div>

                            <div>
                                <div>Money Receipt</div>
                                <div>:</div>
                                {/* <div><a download={paidDetailsInfo.attachmentName} href={this.state.moneyReceipt ? this.state.moneyReceipt : null}> Download</a></div> */}
                                <div>Download <DownloadButton fileName={paidDetailsInfo.attachmentName} filePath={paidDetailsInfo.attachmentPath} /></div>
                            </div>

                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }
}