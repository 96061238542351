import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Card } from 'primereact/card';
import { ScrollPanel } from 'primereact/scrollpanel';
import AuthService from '../../auth/AuthService';
import { EmUserPointService } from '../../service/emUserPoint/EmUserPointService';
import { GUEST_POINT } from '../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../common/ErrorMessageView';


export class AddEmUserPoint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkAddpoint: false,
            renderSuccessView: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: '',
        }
        this.Auth = new AuthService();
        this.emUserPointService = new EmUserPointService();
    }

    componentDidMount() {
        const roles = this.Auth.getLoggedRoles();
        for (let i = 0; i < roles.length; i++) {
            console.log('Role', roles[i]);
            if (roles[i] === 'ROLE_EMUSER') {
                this.setState({ checkAddpoint: true })
            }
        }
    }
    assignPoint() {
        let { checkAddpoint } = this.state;
        if (checkAddpoint) {
            return;
        }

        this.setState({  topProgressBar: true, errorMsgVisible: false });

        this.emUserPointService.assignMyEmUserPoint()
            .then((res) => {
                if (res.status == 201) {
                    this.setState({ renderSuccessView: true })
                    this.setState({  topProgressBar: false, errorMsgVisible: false });
                    this.successPointAdding();
                } else {
                    this.emUserPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({  topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        })
                }
            }).catch((error) => {
                this.setState({  topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });

    }

    successPointAdding() {

        setTimeout(function () {
            this.setState({ renderSuccessView: false })
            this.Auth.logout();
            this.props.history.replace(GUEST_POINT.LOGIN)
        }.bind(this), 3000)

    }
    render() {
        let { renderSuccessView, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        const header = <center>
            <div className="p-col-12 p-lg-12 p-xl-12">
                <h1>Eduman User Point</h1>
            </div>
            <hr />
        </center>;

        const footer = <center>
            {this.state.checkAddpoint ?
                <Button label="Already Assigned" icon="fas fa-check" style={{ marginRight: '.25em', backgroundColor: 'green' }} />
                : <Button label="Add Point" icon="fas fa-plus" style={{ marginRight: '.25em' }}
                    onClick={this.assignPoint.bind(this)} />
            }
        </center>;
        
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section">
                    <div className="p-grid">
                        {renderSuccessView ? <div className="p-col-12 p-lg-6 p-xl-6 p-offset-3">
                            <Card style={{ background: '#2196f3', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
                                <span style={{ fontSize: 'x-large' }}>Successful !!!</span><br />
                                <span>Eduman User Point has successfully added.</span><br />
                                <span>Sign In again to access this Point.</span>
                            </Card>
                        </div> : null}
                        <div className="p-col-12 p-lg-6 p-xl-6 p-offset-3">
                            <Card footer={footer} header={header}>
                                <ScrollPanel style={{ width: '100%', height: '200px' }}>
                                    <p>
                                        At EM user point, after link up a student by mapping student ID and institute ID, a student can view their information such as Student Profile, Attendance Information, General and Grand Final Exam mark, result and merit position, Class Test marks, Class Routine, Exam Routine, Subject list, Accounts information and so more.
                                        <br />
                                        <br />
                                        After link up a student by mapping student ID and institute ID, a guardian can view information such as Student Profile, Attendance Information, General and Grand Final Exam mark, result and merit position, Class Test marks, Class Routine, Exam Routine, Subject list, Accounts information and so more.
                                        <br />
                                        <br />
                                        After link up an HR by mapping HR ID and institute ID, HR can view information such as HR Profile, Attendance Information, Student Information, Weekly Routine, Accounts information and so more.
                                    </p>
                                </ScrollPanel>
                            </Card>

                            {/* </div>  */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}