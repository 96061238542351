import React, { Component } from 'react';
import { CreateBank } from '../../supportPoint/CreateBank';
import { CreateBankAccount } from '../../supportPoint/CreateBankAccount';
import { TabView, TabPanel } from 'primereact/tabview';

export class DfpBankInfo extends Component {
    
    render() {
        return (
            <div className="p-fluid">
                <div className="p-grid">

                    <div className="p-col-12 p-lg-12 p-xl-12 nw-tabview">
                        <TabView >
                            <TabPanel header="Create Bank">
                                <CreateBank />
                            </TabPanel>
                            <TabPanel header="Create Account">
                                <CreateBankAccount />
                            </TabPanel>
                        </TabView>
                    </div>

                </div>
            </div>
        )
    }
}