import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { PhotoDialog } from './PhotoDialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
// import staticImg from 'assets/layout/images/userAvatar.png';

let urlId;
export class PhotoGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dialogLoad: false,
            dialogLoadGallery: false,
            photoSubmitError: {},
            uploadImage: {
                extention: '',
                contentPic: '',
                contentName: ''
            },
            rowInformation: {},
            galleryRowInformation: {},
            academicYearList: [],
            homePagePhotoList: [],
            galleryPhotoList: [],
            totalDataFound: 0,
            homeTotalDataFound: 0,
            galleryTotalDataFound: 0,
            photoInfo: {
                photoType: '',
                photoTitle: '',
                photoDetails: '',
                photoName: '',
                photoPath: '',
                photoSerial: '',
                photoStatus: '',
                photoContent: '',
                photoCreateYear: '',
                coreUrlInfoDTO: {
                    urlInfoID: ''
                }
            },
            dataTableIsLoadingHomepage: false,
            dataTableIsLoadingGallery: false,
            firstDropdownIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            textViewer: true,
            mainFormVisible: false,
            imageAnim: 'assets/layout/images/ani2.gif'
        }
        this.NetiContentLoader = new NetiContentLoader();
        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.netiFileHandler = new NetiFileHandler();
        this.CommonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();
        this.onSortChange = this.onSortChange.bind(this);

    }

    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.setState({
                sortOrder: -1,
                sortField: value.substring(1, value.length),
                sortKey: value
            });
        }
        else {
            this.setState({
                sortOrder: 1,
                sortField: value,
                sortKey: value
            });
        }
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            let { photoInfo } = this.state;
            photoInfo.coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ photoInfo });
            this.setState({ homeReturnButton: false })
            this.getClassOrGroupListByDefaultCode('T107');
            this.getPhotoList('Home Page');
            this.getPhotoList('gallery');
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    getClassOrGroupListByDefaultCode(defaultCode) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, dataTableIsLoadingHomepage: true, errorMsgVisible: false });
        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status === 302) {
                    return res.json().then((body) => {
                        if (defaultCode === 'T107') {
                            this.setState({ academicYearList: body });
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, dataTableIsLoadingHomepage: false, dataTableIsLoadingGallery: false });

                        }
                    });
                } else if (res.status === 404) {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ firstDropdownIsLoading: false, topProgressBar: false, dataTableIsLoading: false, dataTableIsLoadingHomepage: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                } else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ firstDropdownIsLoading: false, topProgressBar: false, dataTableIsLoadingHomepage: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ firstDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, dataTableIsLoadingHomepage: false, errorMsgBody: 'Please check connection' });
            });
    }

    onHide = (event) => {
        this.setState({ visible: false, dialogLoad: false, mainFormVisible: false });
        this.setState({ galleryVisible: false, dialogLoadGallery: false });

        this.CommonFuctionality.blurDialogBackgroundDeActive()

    }

    photoDetails = (rowData) => {
        this.setState({ visible: false, dialogLoad: false });
        let { rowInformation } = this.state
        rowInformation.photoGalleryId = rowData.photoGalleryId;
        rowInformation.photoType = rowData.photoType;
        rowInformation.photoTitle = rowData.photoTitle;
        rowInformation.photoDetails = rowData.photoDetails;
        rowInformation.photoName = rowData.photoName;
        rowInformation.photoPath = rowData.photoPath;
        rowInformation.photoSerial = rowData.photoSerial;
        rowInformation.photoStatus = rowData.photoStatus;
        rowInformation.photoCreateDate = rowData.photoCreateDate;
        rowInformation.photoCreateYear = rowData.photoCreateYear;
        rowInformation.photoContent = rowData.photoContent;
        rowInformation.photoSaveOrEditable = rowData.photoSaveOrEditable;
        this.setState({ rowInformation, visible: true, dialogLoad: true });

        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    galleryDetails = (galleryDetails) => {

        this.setState({ galleryVisible: false, dialogLoadGallery: false, dialogLoad: false });
        // this.setState({dialogLoad:false});
        let { galleryRowInformation } = this.state
        galleryRowInformation.photoGalleryId = galleryDetails.photoGalleryId;
        galleryRowInformation.photoType = galleryDetails.photoType;
        galleryRowInformation.photoTitle = galleryDetails.photoTitle;
        galleryRowInformation.photoDetails = galleryDetails.photoDetails;
        galleryRowInformation.photoName = galleryDetails.photoName;
        galleryRowInformation.photoPath = galleryDetails.photoPath;
        galleryRowInformation.photoSerial = galleryDetails.photoSerial;
        galleryRowInformation.photoStatus = galleryDetails.photoStatus;
        galleryRowInformation.photoCreateDate = galleryDetails.photoCreateDate;
        galleryRowInformation.photoCreateYear = galleryDetails.photoCreateYear;
        galleryRowInformation.photoContent = galleryDetails.photoContent;
        galleryRowInformation.photoSaveOrEditable = galleryDetails.photoSaveOrEditable;
        this.setState({ galleryRowInformation, dialogLoad: true, galleryVisible: true, dialogLoadGallery: true })

    }

    actionBody = (rowData) => {

        return <div className='text-left'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-edit"
                title="View for Update"
                onClick={(e) => this.photoDetails(rowData)}
            />
        </div>
    }
    actionBodyGallery = (galleryDetails) => {

        return <div className='text-center'>
            <Button
                className="nw-action-button edit"
                icon="fas fa-pencil-alt"
                title="View Purchase"
                onClick={(e) => this.galleryDetails(galleryDetails)}
            />
        </div>

    }

    photoView = rowData => {

        let staticImg = 'assets/layout/images/avatar.png';
        
        return (
            <div className="table-image">
                {
                    rowData.photoContent ?
                        <img src={("data:image/png;base64," + rowData.photoContent)} />:
                        <img src={ staticImg } />
                }
            </div>

        )
    }

    photoStatusCheck = (rowData) => {
        let switchButtonEnable = "switch-button-yes", switchButtonYes, switchButtonNo, toggleOnOff;
        if (rowData.photoStatus === 1) {
            switchButtonYes = "switch-button " + switchButtonEnable
            toggleOnOff = "fas fa-toggle-on switch-button-yes"
        } else if (rowData.photoStatus === 0) {
            switchButtonNo = "switch-button " + switchButtonEnable
            toggleOnOff = "fas fa-toggle-off switch-button-no"
        }
        return (
            <div className="input-switch-button">
                <span className={switchButtonNo}>No </span> <i class={toggleOnOff} /> <span className={switchButtonYes}> Yes</span>
            </div>
        )
    }

    photoPublishTo = (rowData) => {
        let activeHomePage = "far fa-circle", activeGallery = "far fa-circle";

        if (rowData.photoType === "Home Page") {
            activeHomePage = "fas fa-check-circle"
        } else if (rowData.photoType === "gallery") {
            activeGallery = "fas fa-check-circle"
        }
        return (
            <div className="input-radio-button">
                <div className="p-col-12 p-col-nogutter">
                    <i class={activeHomePage}></i><label htmlFor="rb1" className="p-radiobutton-label">Home Page</label>
                </div>

                <div className="p-col-12 p-col-nogutter">
                    <i class={activeGallery}></i><label htmlFor="rb1" className="p-radiobutton-label">Gallery</label>
                </div>

            </div>
        )
    }

    showMainForm = () => {
        this.setState({
            mainFormVisible: true
        })

        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    photoTypeHandler = (event, props) => {
        let { photoInfo, photoSubmitError } = this.state;
        photoSubmitError["photoType"] = ""

        if (event.target.value === "Home Page") {
            this.setState({ totalDataFound: this.state.homeTotalDataFound })
        }
        else if (event.target.value === "gallery") {
            this.setState({ totalDataFound: this.state.galleryTotalDataFound })
        }

        photoInfo.photoType = event.target.value;
        this.setState({ photoInfo, textViewer: false });

    }

    titleHandler = (event, props) => {
        let { photoInfo, photoSubmitError } = this.state;
        photoSubmitError["photoTitle"] = ""

        photoInfo.photoTitle = event.target.value;
        this.setState({ photoInfo });
    }

    photoDetailsHandeler = (event, props) => {
        let { photoInfo, photoSubmitError } = this.state;
        photoSubmitError["photoDetails"] = ""

        photoInfo.photoDetails = event.target.value;
        this.setState({ photoInfo });
    }

    photoYearHandler = (event, props) => {
        let { photoInfo, photoSubmitError } = this.state;
        photoSubmitError["photoCreateYear"] = ""

        photoInfo.photoCreateYear = event.target.value;
        this.setState({ photoInfo });
    }

    onLoadPic(e) {
        let { photoSubmitError } = this.state;
        photoSubmitError["photoContent"] = ""

        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ uploadImage: album, photoSubmitError });

            // this.state.photoSubmitError["photoContent"] = ""
        }
    }

    photoGallerySubmit = (event, props) => {
        let { photoInfo, totalDataFound } = this.state;
        
        photoInfo.coreUrlInfoDTO.urlInfoID = urlId;
        if (this.state.uploadImage.contentPic != null) {
            photoInfo.photoContent = this.state.uploadImage.contentPic;
            photoInfo.photoSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.uploadImage.extention);
            // photoInfo.photoName = 'gallery_photo_' + photoInfo.photoSerial + extention;
            // photoInfo.photoName = 'gallery_photo_' + urlId + '_' + Date.now() + extention;
            photoInfo.photoName = this.state.uploadImage.contentName;

        }
        if (this.errorHandler()) {

            this.setState({ topProgressBar: true, errorMsgVisible: false });

            if (this.state.photoInfo.photoType === "Home Page") {
                this.setState({ dataTableIsLoadingHomepage: true });
            } else {
                this.setState({ dataTableIsLoadingGallery: true });
            }

            photoInfo.photoSerial = totalDataFound + 1;
            this.setState({ photoInfo });
            this.DwsService.createNewPhotoGallery(this.state.photoInfo)
                .then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Submitted successfully' });
                        this.getPhotoList('Home Page');
                        this.getPhotoList('gallery');
                        this.setState({
                            photoInfo: {
                                photoType: '',
                                photoTitle: '',
                                photoDetails: '',
                                photoName: '',
                                photoPath: '',

                                photoStatus: '',
                                photoContent: '',
                                photoCreateYear: '',
                                coreUrlInfoDTO: {
                                    urlInfoID: urlId
                                }
                            },
                            uploadImage: {},
                            totalDataFound: 0,
                            textViewer: true
                        })
                        this.setState({ topProgressBar: false, dataTableIsLoadingGallery: false, dataTableIsLoadingHomepage: false });

                    } else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ errorMsgVisible: true, dataTableIsLoadingGallery: false, errorMsgBody: resp, topProgressBar: false, dataTableIsLoadingHomepage: false })
                            });
                    }
                }).catch(error => {
                    this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoadingHomepage: false, dataTableIsLoadingGallery: false, errorMsgBody: "Please check your connection !!!" });
                });

        }

    }

    getPhotoList(listType) {
        let photoInfo = this.state.photoInfo.coreUrlInfoDTO

        this.setState({
            dataTableIsLoadingHomepage: true, dataTableIsLoadingGallery: true, topProgressBar: true, errorMsgVisible: false,
            homePagePhotoList: '', galleryPhotoList: ''
        });
        this.DwsService.fetchPhotolist(photoInfo, listType)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        this.setState({ homePageAndGalleryPhotoList: body });

                        if (listType == 'Home Page') {

                            this.setState({ homePagePhotoList: body, homeTotalDataFound: body.length });

                        } else if (listType == 'gallery') {
                            this.setState({ galleryPhotoList: body, galleryTotalDataFound: body.length });
                        }
                        this.setState({ topProgressBar: false, dataTableIsLoadingHomepage: false, dataTableIsLoadingGallery: false, errorMsgVisible: false });

                    })
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoadingGallery: false, firstDropdownIsLoading: false, dataTableIsLoading: false, errorMsgVisible: false, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoadingHomepage: false, dataTableIsLoadingGallery: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
            });
    }

    errorHandler() {
        let { photoInfo, photoSubmitError } = this.state;
        let formIsValid = true;

        if (photoInfo.photoType === '') {
            formIsValid = false;
            photoSubmitError["photoType"] = "Photo Type can't left empty";
        }
        if (photoInfo.photoTitle === '') {
            formIsValid = false;
            photoSubmitError["photoTitle"] = "Photo Title can't left empty.";
        }
        if (photoInfo.photoDetails === '') {
            formIsValid = false;
            photoSubmitError["photoDetails"] = "Photo Details can't left empty.";
        }
        if (photoInfo.photoCreateYear === '') {
            formIsValid = false;
            photoSubmitError["photoCreateYear"] = "Photo Year can't left empty.";
        }
        if (photoInfo.photoContent === '') {
            formIsValid = false;
            photoSubmitError["photoContent"] = "Attachment file missing.";
        }
        this.setState({ photoSubmitError });
        return formIsValid;
    }

    turnOffDialog = () => {
        this.getPhotoList('Home Page');
        this.getPhotoList('gallery');
        this.setState({
            visible: false,
            galleryVisible: false
        })

        this.onHide()
    }

    diagGrowl = (severityType, message) => {

        if (severityType == 'success') {
            this.growl.show({ severity: 'success', summary: 'Success Message', detail: message })
        }
        else if (severityType == 'info') {
            this.growl.show({ severity: 'info', summary: 'Info Message', detail: message })
        }
        else if (severityType == 'warn') {
            this.growl.show({ severity: 'warn', summary: 'Warn Message', detail: message })
        }
        else if (severityType == 'error') {
            this.growl.show({ severity: 'error', summary: 'Error Message', detail: message })
        }
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let homePagePhotoHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Homepage Photo List
                                    <span style={{ 'float': 'right' }}>Total Found: {this.state.homePagePhotoList.length} </span>
        </div>;

        let galleryPhotoHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Gallery Photo List
                                    <span style={{ 'float': 'right' }}>Total Found: {this.state.galleryPhotoList.length} </span>
        </div>;

        let homePageAndGalleryPhotoHeader = <div className="header-title">
            <div className="header-title-left">List Of Photos</div>

            <div className="header-title-right">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="fas fa-search"></i>
                    </span>
                    <InputText
                        type="search"
                        onInput={(e) => this.setState({ globalFilter: e.target.value })}
                        placeholder="Search By Title, Serial No. etc"
                        size="50"

                    />
                </div>

                <Button
                    className="nw-button nw-button-center p-button-success"
                    name="district"
                    label="Add New Photo"
                    icon="fas fa-plus"
                    iconPos="right"
                    onClick={this.showMainForm}
                />
            </div>
        </div>;

        let yearList = [];
        if ((this.state.academicYearList != null) && (this.state.academicYearList.length > 0)) {
            yearList = this.state.academicYearList.map((item) => ({
                value: item.categoryName,
                label: item.categoryName,
            }));
        }

        const photoTypeField = [
            { label: 'Home Page', value: 'Home Page' },
            { label: 'Gallery', value: 'gallery' }
        ];

        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">(370 x 240 px (recommended)</span></div>

        let homePageAndGalleryPhoto = []

        for (let i = 0; i < this.state.homePagePhotoList.length; i++) {
            homePageAndGalleryPhoto.push(this.state.homePagePhotoList[i]);
        }

        for (let i = 0; i < this.state.galleryPhotoList.length; i++) {
            homePageAndGalleryPhoto.push(this.state.galleryPhotoList[i]);
        }

        // homePageAndGalleryPhoto.push( this.state.galleryPhotoList)

        // console.log("homePageAndGalleryPhoto", homePageAndGalleryPhoto);

        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                <Growl ref={(el) => this.growl = el} />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section blur-section">

                    {this.state.homeReturnButton === false ?
                        <div>
                            <div className="p-grid">

                                <div className="p-col-12 p-xl-12 nw-datatable-detail-view">
                                    {this.state.dataTableIsLoadingHomepage ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <DataTable
                                            header={homePageAndGalleryPhotoHeader}
                                            value={homePageAndGalleryPhoto}
                                            selectionMode="single"
                                            selection={this.state.dataTableSelection}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            paginator={true}
                                            rows={20}
                                            globalFilter={this.state.globalFilter}
                                        >
                                            <Column field="photoName" header="Image" body={this.photoView} style={{ "width": "100px" }} />
                                            <Column field="photoSerial" header="Serial No." style={{ "width": "100px" }} />
                                            <Column field="photoTitle" header="Title of The Image" />
                                            <Column field="photoStatus" header="Publish This Post" body={this.photoStatusCheck} />
                                            <Column field="photoType" header="Publish To" body={this.photoPublishTo} />
                                            <Column header="Action" body={this.actionBody} style={{ "width": "100px" }} />
                                        </DataTable>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">

                    <Dialog
                        header="Photo Gallery"
                        visible={this.state.mainFormVisible}
                        className="nw-dialog"
                        modal={true}
                        onHide={this.onHide}
                    >

                        <div className="nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Serial No. <span>*</span></span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.textViewer ?
                                                    "Please Select Photo Type"
                                                    :
                                                    this.state.totalDataFound + 1
                                                }
                                            </div>
                                        </div>
                                        <span className="error-message">{this.state.photoSubmitError["photoSerial"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Photo Type <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Photo Type"
                                                value={this.state.photoInfo.photoType}
                                                options={photoTypeField}
                                                autoWidth={false}
                                                onChange={(e) => this.photoTypeHandler(e, this.props)}
                                                name="photoType"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.photoSubmitError["photoType"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Title <span>*</span></span>
                                            <InputText
                                                id="photoTitle"
                                                value={this.state.photoInfo.photoTitle}
                                                onChange={(e) => this.titleHandler(e, this.props)}
                                                placeholder="Enter Photo Title"
                                                name="photoTitle"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.photoSubmitError["photoTitle"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                            <InputTextarea
                                                id="details"
                                                onChange={(e) => this.photoDetailsHandeler(e, this.props)}
                                                value={this.state.photoInfo.photoDetails}
                                                name="photoDetails"
                                                rows={5}
                                                cols={30}
                                                placeholder="Write Photo Details"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.photoSubmitError['photoDetails']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.firstDropdownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Year <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Photo Year"
                                                    value={this.state.photoInfo.photoCreateYear}
                                                    options={yearList}
                                                    id="yearList"
                                                    autoWidth={false}
                                                    name="photoCreateYear"
                                                    onChange={(e) => this.photoYearHandler(e, this.props)}
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{this.state.photoSubmitError["photoCreateYear"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload Image <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/JPEG/GIF)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                {
                                                    this.state.uploadImage.contentPic ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={e => this.setState({ uploadImage: { contentPic: '' } })}
                                                                />
                                                                <img
                                                                    src={
                                                                        this.state.uploadImage.contentPic === '' ?
                                                                            'assets/images/demo.jpg'
                                                                            :
                                                                            "data:image/png;base64," + this.state.uploadImage.contentPic
                                                                    }
                                                                    style={{ "height": "80px" }}
                                                                />

                                                            </div>
                                                            <div className="image-title">{this.state.uploadImage.contentName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            chooseLabel={this.state.uploadImage.contentName || uploadFileLabel}
                                                            mode="basic"
                                                            accept="image/*"
                                                            maxFileSize={1000000}
                                                            onSelect={this.onLoadPic.bind(this)}
                                                            auto={true}
                                                        />
                                                }

                                            </div>
                                        </div>
                                        <span className="error-message">{this.state.photoSubmitError["photoContent"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={e => this.onHide()}
                                        />

                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            name="district"
                                            label="Save"
                                            icon="fas fa-check"
                                            onClick={(e) => this.photoGallerySubmit(e, this.props)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog
                        maximizable
                        header="Update Photo Information"
                        visible={this.state.visible}
                        className="nw-dialog" //custom-md-dialog
                        modal={true}
                        onHide={this.onHide}
                    >
                        {this.state.dialogLoad == true ?
                            <PhotoDialog rowInformation={this.state.rowInformation} turnOffDialog={this.turnOffDialog} diagGrowl={this.diagGrowl} />
                            :
                            ''
                        }
                    </Dialog>
                    
                </div>

            </div>
        );
    }
}