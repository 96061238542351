import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { Growl } from 'primereact/growl';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from "../../common/CommonFuctionality";
import {Editor} from 'primereact/editor';

let showStatus = false;

export class CreateReleaseNote extends Component {

    constructor() {
        super();
        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            updateNoteError: {},
            updateReleaseInfoObj: {
                applicationCategoryInfoDTO: {
                    coreCategoryID: ''
                }
            },
            productList: '',
            productName: '',
            releaseVersion: '',
            releaseDate: '',
            releaseDetails: '',
            updateProductReleaseID: '',
            updateProductName: '',
            updateProductCategoryName:'',
            updateReleaseVersion: '',
            updateReleaseDate: '',
            updateReleaseDetails: '',
            dataTableValue: [],
            errors: {},
            topProgressBar: false,
            dataTableIsLoading: false,
            firstDropdownIsLoading: false
        }
        this.fetchProductList = this.fetchProductList.bind(this);
        this.onChangeProductName = this.onChangeProductName.bind(this);
        this.onChangeReleaseVersion = this.onChangeReleaseVersion.bind(this);
        this.onChangeReleaseDate = this.onChangeReleaseDate.bind(this);
        this.onChangeReleaseDetails = this.onChangeReleaseDetails.bind(this);;
        this.createReleaseNote = this.createReleaseNote.bind(this);
        this.NetiContentLoader = new NetiContentLoader();

        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);

        this.onChangeUpdateProductName = this.onChangeUpdateProductName.bind(this);
        this.onChangeUpdateReleaseVersion = this.onChangeUpdateReleaseVersion.bind(this);
        this.onChangeUpdateReleaseDate = this.onChangeUpdateReleaseDate.bind(this);
        this.onChangeUpdateReleaseDetails = this.onChangeUpdateReleaseDetails.bind(this);;
        this.updateReleaseInfoHandler = this.updateReleaseInfoHandler.bind(this);

        this.adminPointService = new AdminPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.userCategoryService = new UserCategoryService();
        this.CommonFuctionality = new CommonFuctionality();


    }

    componentWillMount() {
        this.fetchProductList();
        this.fetchReleaseNoteList();
    }

    onChangeProductName(value) {
        this.state.errors["productName"] = "";
        this.setState({ productName: value, errors: this.state.errors });
    }
    onChangeReleaseVersion(value) {
        this.state.errors["releaseVersion"] = "";
        this.setState({ releaseVersion: value, errors: this.state.errors });
    }
    onChangeReleaseDate(value) {
        this.state.errors["releaseDate"] = "";
        this.setState({ releaseDate: value, errors: this.state.errors });
    }
    onChangeReleaseDetails(e) {
        this.state.errors["releaseDetails"] = "";
        this.setState({ releaseDetails: e.htmlValue, errors: this.state.errors });
    }

    fetchReleaseNoteList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchReleaseNoteList(10, 0)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].releaseDate == null) {
                                body[i].releaseDate = '';
                            } else {
                                body[i].releaseDate = NetiDateUtils.getAnyShortForm(body[i].releaseDate, 'DD-MMM-YYYY');
                            }
                        }
                        this.setState({ dataTableValue: body, firstDropdownIsLoading: false, dataTableIsLoading: false, topProgressBar: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    fetchProductList() {

        this.userCategoryService.fetchListByDefaultCode('T11001')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productList: body });
                    })
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    createReleaseNote() {
        if (this.onSubmitErrorHandler()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            let requestedObject = {
                "releaseDate": NetiDateUtils.getDateFromString(this.state.releaseDate),
                "releaseVersion": this.state.releaseVersion,
                "releaseDetails": this.state.releaseDetails,
                "applicationCategoryInfoDTO": {
                    "coreCategoryID": this.state.productName,
                }
            }
            this.adminPointService.saveReleaseInfo(requestedObject).then(res => {
                if (res.status == 201) {
                    this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data save successful.' });
                    this.setState({ productName: '', releaseDate: '', releaseVersion: '', releaseDetails: '' });
                    this.setState({ topProgressBar: false, dataTableIsLoading: false })
                    this.fetchReleaseNoteList();
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
        }

    }

    viewDialog(rowData) {
        this.setState({
            updateProductReleaseID: rowData.productReleaseID,
            updateProductName: rowData.productName,
            updateProductCategoryName: rowData.categoryName,
            updateReleaseVersion: rowData.releaseVersion,
            updateReleaseDate: rowData.releaseDate,
            updateReleaseDetails: rowData.releaseDetails,
            visible: true
        })
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onHide() {
        this.setState({ visible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    onChangeUpdateProductName(value) {
        this.state.updateNoteError["updateProductName"] = "";
        this.setState({ updateProductName: value });
    }
    onChangeUpdateReleaseVersion(value) {
        this.state.updateNoteError["updateReleaseVersion"] = "";
        this.setState({ updateReleaseVersion: value });
    }
    onChangeUpdateReleaseDate(value) {
        this.state.updateNoteError["updateReleaseDate"] = "";
        this.setState({ updateReleaseDate: value });
    }
    onChangeUpdateReleaseDetails(e) {
        this.state.updateNoteError["updateReleaseDetails"] = "";
        this.setState({ updateReleaseDetails: e.htmlValue });
    }

    updateReleaseInfoHandler() {
        let { updateReleaseInfoObj } = this.state
        updateReleaseInfoObj.productReleaseID = this.state.updateProductReleaseID;
        updateReleaseInfoObj.applicationCategoryInfoDTO.coreCategoryID = this.state.updateProductName;
        updateReleaseInfoObj.applicationCategoryInfoDTO.updateProductCategoryName = this.state.categoryName;
        updateReleaseInfoObj.releaseVersion = this.state.updateReleaseVersion;
        updateReleaseInfoObj.releaseDate = NetiDateUtils.reverseToYYYYMMDD(this.state.updateReleaseDate);
        updateReleaseInfoObj.releaseDetails = this.state.updateReleaseDetails;
        this.setState({ updateReleaseInfoObj });
        if (this.releaseNoteUpdateError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            this.adminPointService.updateReleaseInfo(updateReleaseInfoObj)
                .then(res => {
                    if (res.status === 202) {
                        this.growl.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Successfully Updated",
                            life: 800
                        });
                        setTimeout(() => {
                            this.onHide();
                        }, 800);
                        this.setState({ topProgressBar: false, dataTableIsLoading: false })
                        this.fetchReleaseNoteList();

                    }
                    else {
                        this.adminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resp, topProgressBar: false, dataTableIsLoading: false })
                            });
                    }
                }).catch(error =>
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                );
        }

    }

    updateDialogDiscard = () => {
        this.onHide();
    }

    onSubmitErrorHandler = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.productName === '' || this.state.productName === null) {
            formIsValid = false;
            errors["productName"] = "Product Name can't left empty.";
        }
        if (this.state.releaseVersion === '') {
            formIsValid = false;
            errors["releaseVersion"] = "Release Version can't left empty.";
        }
        if (this.state.releaseDate === '') {
            formIsValid = false;
            errors["releaseDate"] = "Release Date can't left empty.";
        }
        if (!this.state.releaseDetails) {
            formIsValid = false;
            errors["releaseDetails"] = "Release Details can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    releaseNoteUpdateError = () => {
        let { updateNoteError } = this.state;
        let formIsValid = true;

        if (this.state.updateProductName === '' || this.state.updateProductName === null) {
            formIsValid = false;
            updateNoteError["updateProductName"] = "Product Name can't left empty.";
        }
        if (!this.state.updateReleaseVersion) {
            formIsValid = false;
            updateNoteError["updateReleaseVersion"] = "Release Version can't left empty.";
        }
        if (this.state.updateReleaseDate === '') {
            formIsValid = false;
            updateNoteError["updateReleaseDate"] = "Release Date can't left empty.";
        }
        if (!this.state.updateReleaseDetails) {
            formIsValid = false;
            updateNoteError["updateReleaseDetails"] = "Release Details can't left empty.";
        }

        this.setState({ updateNoteError });
        return formIsValid;
    }

    render() {
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Released Product List<span style={{ 'float': 'right' }}> Total Found:{this.state.dataTableValue.length} </span></div>;
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let productNameOptions = [];
        if (this.state.productList && this.state.productList.length) {
            productNameOptions = this.state.productList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let editBody = (rowData) => {

            return <div className='text-center'>
                <Button
                    className="nw-action-button edit"
                    icon="fas fa-edit"
                    tooltip="Update"
                    onClick={(e) => this.viewDialog(rowData)}
                />
                <Button
                    className="nw-action-button info"
                    icon="fas fa-eye"
                    tooltip="View"
                />
            </div>
        }

        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                    {this.state.firstDropdownIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Product Name {requiredSing}</span>
                                            <Dropdown
                                                placeholder='Select Product Name'
                                                name="productName"
                                                options={productNameOptions}
                                                value={this.state.productName}
                                                onChange={(e) => this.onChangeProductName(e.value)}
                                                filter={true}
                                                filterBy="label,value"
                                                autoWidth={false}
                                            />
                                        </div>
                                    }
                                    <span className='error-message'>{this.state.errors["productName"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Release Version {requiredSing}</span>

                                        <InputText
                                            placeholder='Enter Product Release Version'
                                            name="releaseVersion"
                                            value={this.state.releaseVersion}
                                            onChange={(e) => this.onChangeReleaseVersion(e.target.value)}
                                            autoWidth={false}
                                        />
                                    </div>
                                    <span className='error-message'>{this.state.errors["releaseVersion"]}</span>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Release Date {requiredSing}</span>

                                        <Calendar
                                            placeholder='Select Release Date'
                                            name="releaseDate"
                                            value={this.state.releaseDate}
                                            onChange={(e) => this.onChangeReleaseDate(e.target.value)}
                                            autoWidth={false}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                        />
                                    </div>
                                    <span className='error-message'>{this.state.errors["releaseDate"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Release Details {requiredSing}</span>
                                            <Editor 
                                                name="releaseDetails"
                                                value={this.state.releaseDetails}
                                                onTextChange={(e) => this.onChangeReleaseDetails(e)}
                                                autoWidth={false}
                                            />

                                    </div>
                                    <span className='error-message'>{this.state.errors["releaseDetails"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12" />

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                        </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Save"
                                        icon="fas fa-check"
                                        onClick={event => this.createReleaseNote(event)}
                                    />

                                </div>
                            </div>
                        </div>


                        <div className="p-col-12 p-xl-12">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <div className="nw-data-table blur-section">
                                    <DataTable
                                        header={tableHeader}
                                        value={this.state.dataTableValue}
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[10, 20, 50]}
                                    >
                                        <Column field="productName" header="Product Name" sortable={true} filter={true} />
                                        <Column field="releaseVersion" header="Release Version" sortable={true} filter={true} />
                                        <Column field="releaseDate" header="Release Date" sortable={true} filter={true} />
                                        <Column field="" header="Action" style={{ width: "110px" }} body={editBody} />
                                    </DataTable>
                                </div>
                            }
                        </div>



                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog 
                        className="nw-dialog" 
                        header="Release Info(Update)" 
                        visible={this.state.visible} 
                        onHide={this.onHide}
                    >

                        <div className="nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Product Name </span>
                                                <div className="nw-inputgroup-desc">{this.state.updateProductName}</div>
                                            </div>
                                            <span className='error-message'>{this.state.updateNoteError["updateProductName"]}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Release Version <span>*</span></span>
                                                <InputText
                                                    placeholder='Enter Product Release Version'
                                                    name="updateReleaseVersion"
                                                    value={this.state.updateReleaseVersion}
                                                    onChange={(e) => this.onChangeUpdateReleaseVersion(e.target.value)}
                                                    autoWidth={false}
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.updateNoteError["updateReleaseVersion"]}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Release Date <span>*</span></span>
                                                <Calendar
                                                    placeholder='Select Release Date'
                                                    name="updateReleaseDate"
                                                    value={this.state.updateReleaseDate}
                                                    onChange={(e) => this.onChangeUpdateReleaseDate(e.value)}
                                                    autoWidth={false}
                                                    yearRange="2010:2030"
                                                    dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    dateOnly="true"
                                                    showIcon={true}
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.updateNoteError["updateReleaseDate"]}</span>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtexteditor-label">Release Details <span>*</span></span>
                                                <Editor
                                                    name="updateReleaseDetails"
                                                    value={this.state.updateReleaseDetails}
                                                    onTextChange={(e) => this.onChangeUpdateReleaseDetails(e)}
                                                    autoWidth={false}
                                                    maxi
                                                />
                                            </div>
                                            <span className='error-message'>{this.state.updateNoteError["updateReleaseDetails"]}</span>
                                        </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                    <Button
                                                        label="Discard"
                                                        icon="fas fa-times"
                                                        className="p-button p-button-danger nw-button nw-button-multiple"
                                                        onClick={this.updateDialogDiscard}
                                                    />
                                                    <Button
                                                        label="Update"
                                                        icon="fas fa-check"
                                                        className="p-button p-button-primary nw-button nw-button-multiple"
                                                        onClick={event => this.updateReleaseInfoHandler(event)}
                                                    />
                                                </div>


                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>

            </div>
        )
    }
}