import React, { Component } from "react";
import { Button } from "primereact/button";
import { Link } from 'react-router-dom';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { MY_POINT } from "../../../utils/PointWiseRouteConsts";

function ChatBoxCommonBody(props) {
  let { unreadMessage } = props;
  return (
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon img">
          <img src="/assets/images/demo.jpg" />
        </span>
        <span className="p-inputgroup-addon info">
          <div> {unreadMessage.senderBasicInfoDTO && unreadMessage.senderBasicInfoDTO.customNetiID}</div>
          <div>{unreadMessage.senderBasicInfoDTO && unreadMessage.senderBasicInfoDTO.fullName}</div>
          <div>{NetiDateUtils.getAnyShortForm(unreadMessage.chatboxDate, 'DD-MMM-YYYY hh:mm:ss a')}</div>
        </span>
        
        <Link to={{ pathname: MY_POINT.CHAT_BOX, unreadMessage: unreadMessage }}>
          <i className="fas fa-angle-right"/><i className="fas fa-angle-right"/>
        </Link>
          <Button
            label="View"
            onClick={(e) => props.viewUnreadMSGdetails(unreadMessage)}
          />
        
      </div>
    );
}

export default ChatBoxCommonBody;