import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import { ManageTokenCreate } from "./ManageTokenCreate"
import { ManageTokenPendingList } from "./ManageTokenPendingList";
import { ManageTokenProcessingList } from "./ManageTokenProcessingList";
import { ManageTokenSolvedList } from "./ManageTokenSolvedList";

import { DataTable, Column } from 'primereact/datatable';
import { Link } from 'react-router-dom';
import CommonFuctionality from '../common/CommonFuctionality';


let tokenStatus;
let eligibilityStatus;
let netiWorldValue;
let errors = {}
export class ManageToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenIDError: {},
            tokenInfoView: false,
            tokenInfoViewByMobileNo: false,
            tokenInfoViewByInstituteID: false,
            tokenFindBy: '',
            mobileNo: '',
            instituteID: '',
            tokenInfoByMobileArr: [],
            tokenInfoByInstituteArr: [],
            tokenInfoObj: {
                tokenTypeInfoDTO: {
                    parentCoreCategoryInfoDTO: {
                        categoryName: '',
                        parentCoreCategoryInfoDTO: {
                            categoryName: ''
                        }
                    },

                },
                solverBasicInfoDTO: {
                    fullName: ''
                },
                tokenStatus: '',
                tokenTypeInfoDTO: {
                    categoryName: ''
                }
            },
            emptyTokenInfoObj: false,
            callerContactNo: '',
            deviceInstituteId: '',
            layout: 'list',
            tokenID: '',
            checked: false,
            tokenType: '',
            hasSolved: false,
            errors: {},
            eligibilityStatus: '',
            applicantFormVisibility: true,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            inputFieldIsLoading: false
        };

        this.supportPointService = new SupportPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.netiContentLoader = new NetiContentLoader();
        this.commonFuctionality = new CommonFuctionality();
    }

    onChangeTokenID = (e) => {
        this.setState({ tokenID: e.target.value});
        this.clearDateError('tokenID');
    }
    onChangeMobileNo = (e) => {
        this.setState({ mobileNo: e.target.value});
        this.clearDateError('mobileNo');
    }

    onChangeInstituteID = (e) => {
        this.setState({ instituteID: e.target.value });
        this.clearDateError('instituteID');
    }

    onChangeTokenFindBy = (e) => {
        this.setState({ tokenFindBy: e.target.value });
    }

    clearDateError = (name) => {
		let { tokenIDError } = this.state;
		tokenIDError[name] = ''
		this.setState({ tokenIDError })
	}

    onSearchByTokenID = () => {
        
        this.setState({ tokenInfoByMobileArr: [], tokenInfoByInstituteArr: [] });
        if (this.searchByTokenIDError()) {
        this.setState({ topProgressBar: true, tokenInfoViewByMobileNo: false, tokenInfoViewByInstituteID: false, errorMsgVisible: false, tokenInfoView: true, inputFieldIsLoading: true  });
            this.supportPointService.getTokenInfoByTokenID(this.state.tokenID)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 302) {
                        return res.json().then((body) => {
                            // this.setState({ tokenInfoView: true });
                            this.setState({ tokenInfoObj: body });
                            this.setState({ errorMsgVisible: false, inputFieldIsLoading: false });

                        });
                    } else {
                        this.supportPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, tokenInfoView: false, inputFieldIsLoading: false })
                            });
                    }
                }).catch(error => {
                    this.setState({ inputFieldIsLoading: false, tokenInfoView: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
                });

        }

    }

    onSearchByMobileNo = () => {
        this.setState({ tokenInfoViewByInstituteID: false, tokenInfoView: false, errorMsgVisible: false  });
        if (this.searchByMobileError()) {
            this.setState({ topProgressBar: true})
            this.supportPointService.getTokenInfoByMobileNo(this.state.mobileNo)
            .then(res => {
                // this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ tokenInfoViewByMobileNo: true });
                        this.setState({ tokenInfoByMobileArr: body });
                        this.setState({ topProgressBar: false, errorMsgVisible: false })
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
        }
    }

    onSearchByInstituteID = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false, tokenInfoView: false, tokenInfoViewByMobileNo: false });
        if (this.searchByInstiuteIDError()) {
            this.setState({ topProgressBar: true})
            this.supportPointService.getTokenInfoByInstituteID(this.state.instituteID)
            .then(res => {
                // this.setState({  });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ tokenInfoViewByInstituteID: true });
                        this.setState({ tokenInfoByInstituteArr: body });
                        this.setState({ topProgressBar: false, errorMsgVisible: false })
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
            });
        }
    }

    searchByTokenIDError = () => {
        this.setState({ topProgressBar: false })
        let { tokenIDError } = this.state
        let formIsValid = true;

        if (this.state.tokenID === '') {
            formIsValid = false;
            tokenIDError["tokenID"] = "Token ID can't left empty.";
        }else{
            formIsValid = true;
        }

        this.setState({ tokenIDError });
        return formIsValid;
    }

    searchByMobileError = () => {
        this.setState({ topProgressBar: false })
        let { tokenIDError } = this.state
        let formIsValid = true;

        if (this.state.mobileNo === '') {
            formIsValid = false;
            tokenIDError["mobileNo"] = "Mobile number can't left empty.";
        }else{
            formIsValid = true;
        }

        this.setState({ tokenIDError });
        return formIsValid;
    }

    searchByInstiuteIDError = () => {
        this.setState({ topProgressBar: false })
        let { tokenIDError } = this.state
        let formIsValid = true;

        if (this.state.instituteID === '') {
            formIsValid = false;
            tokenIDError["instituteID"] = "Institute ID can't left empty.";
        }else{
            formIsValid = true;
        }

        this.setState({ tokenIDError });
        return formIsValid;
    }

    onHideTokenSearch = () => {
        this.commonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ tokenFindBy: '',tokenID: '',mobileNo:'',instituteID:'',tokenSearchVisibility: false, tokenInfoView: false, tokenInfoViewByMobileNo: false, tokenInfoViewByInstituteID: false, tokenInfoByMobileArr: [], tokenInfoByInstituteArr: [] });

        this.setState({
            tokenInfoObj: {
                tokenTypeInfoDTO: {
                    parentCoreCategoryInfoDTO: {
                        categoryName: '',
                        parentCoreCategoryInfoDTO: {
                            categoryName: ''
                        }
                    },

                },
                solverBasicInfoDTO: {
                    fullName: ''
                },
                tokenStatus: '',
                tokenTypeInfoDTO: {
                    categoryName: ''
                }
            }
        });
    }

    onHide = () =>{
        this.setState({
            createTokenDialogVisibility: false
        })

        this.commonFuctionality.blurDialogBackgroundDeActive();
    }

    tokenDataByMobileNoTemplate = (tokenData) => {
        if (!tokenData) {
            return null;
        }
        else {
            return this.renderTokenDataByMobileNo(tokenData);
        }
    }

    tokenDataByInstituteIDTemplate = (tokenData) => {
        if (!tokenData) {
            return null;
        }
        else {
            return this.renderTokenDataByInstituteID(tokenData);
        }
    }

    renderTokenDataByMobileNo = (tokenData) => {
        return (
            <div className="nw-search-view">
                <div>
                    <div className="p-col-12 p-xl-12">
                        {/* <center> */}
                            {/* <label><h2>{tokenData.tokenTypeInfoDTO ? tokenData.tokenTypeInfoDTO.categoryName : ''}</h2></label> */}
                            <label><h2>{tokenData.tokenSource || ''}</h2></label>
                            {/* <p>{this.state.tokenInfoObj.instituteAddress}</p> */}
                        {/* </center> */}
                        <br />
                        <br />
                    </div>

                    <div className="p-col-12 p-xl-12">
                        {this.state.inputFieldIsLoading ?
                            this.netiContentLoader.normalFormInputField() :
                            <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon">Module</span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                    {
                                        tokenData.tokenTypeInfoDTO && tokenData.tokenTypeInfoDTO.parentCoreCategoryInfoDTO ? 
                                        tokenData.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''
                                    } 

                                    {/* {
                                        ` (${
                                            tokenData.tokenSource ? 
                                            tokenData.tokenSource : ''
                                        })`
                                    } */}
                                </div>
                            </div>
                        }
                    </div>

                    <div className="p-col-12 p-xl-12">
                        {this.state.inputFieldIsLoading ?
                            this.netiContentLoader.normalFormInputField() :
                            <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon">Problem Type</span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                    { tokenData.tokenTypeInfoDTO ? tokenData.tokenTypeInfoDTO.categoryName : '' }
                                </div>
                            </div>
                        }
                    </div>

                    <div className="p-col-12 p-xl-12">
                        {this.state.inputFieldIsLoading ?
                            this.netiContentLoader.normalFormInputField() :
                            <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon">Token Type</span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                    {tokenData.requirementStatus == 0 ? "Problem" : 'Requirement'}
                                </div>
                            </div>
                        }
                    </div>

                    <div className="p-col-12 p-xl-12">
                        {this.state.inputFieldIsLoading ?
                            this.netiContentLoader.normalFormInputField() :
                            <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon">Token ID</span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                    {tokenData.customTokenID}
                                </div>
                            </div>
                        }
                    </div>

                    <div className="p-col-12 p-xl-12">
                        {this.state.inputFieldIsLoading ?
                            this.netiContentLoader.normalFormInputField() :
                            <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon">Status</span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                    {this.checkTokenStatus(tokenData.tokenStatus)}
                                </div>
                            </div>
                        }
                    </div>

                    <div className="p-col-12 p-xl-12">
                        {this.state.inputFieldIsLoading ?
                            this.netiContentLoader.normalFormInputField() :
                            <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon">Description</span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                    { tokenData.tokenDetails }
                                </div>
                            </div>
                        }
                    </div>

                    <div className="p-col-12 p-xl-12">
                        {this.state.inputFieldIsLoading ?
                            this.netiContentLoader.normalFormInputField() :
                            <div className="p-inputgroup">
                                <span className="task-badge found"></span>
                                <span className="p-inputgroup-addon">Token Solver</span>
                                <span className="p-inputgroup-colon">:</span>
                                <div className="nw-inputgroup-desc">
                                    {
                                        tokenData.solverBasicInfoDTO ? 
                                        tokenData.solverBasicInfoDTO.fullName : '---'
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    {(tokenData && tokenData.tokenStatus == 10) || (tokenData && tokenData.tokenStatus == 2) ?
                        <>
                            <div className="p-col-12 p-xl-12">
                                {this.state.inputFieldIsLoading ?
                                    this.netiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">
                                            {tokenData && tokenData.tokenStatus == 10 ?
                                                "Solved Date"
                                                : tokenData && tokenData.tokenStatus == 2 ?
                                                    "Rejected Date" : null
                                            }
                                        </span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            {/* { tokenData.solveDate || "---" } */}
                                            {tokenData && NetiDateUtils.getAnyShortForm(tokenData.solveDate, 'DD-MMM-YYYY hh:mm:ss a')}
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="p-col-12 p-xl-12">
                                <div className="p-inputgroup details nw-inputgroup-desc-parent">
                                    <span className="task-badge found"></span>
                                    <span className="p-inputgroup-addon">
                                        {tokenData && tokenData.tokenStatus == 10 ?
                                            "Solved Note"
                                            : tokenData && tokenData.tokenStatus == 2 ?
                                                "Rejected Note" : null
                                        }
                                    </span>
                                    <span className="p-inputgroup-colon">:</span>
                                    <div className="nw-inputgroup-desc demo" id='demo'>
                                        {tokenData && tokenData.solveMessage}
                                    </div>
                                </div>
                            </div>
                        </>
                        : ''
                    }
                </div>
            </div>
            // <div className="p-fluid">
            //     <div className="p-grid">
            //         <div className="p-col-12">
            //             <Fieldset className="p-col-12 p-xl-12" legend="Token Information">
            //                 <div className="p-col-12" >
            //                     <div className="p-lg-7"><h2>{tokenData.tokenTypeInfoDTO.categoryName}</h2></div>
            //                     <div className="p-grid p-col-12">
            //                         <div className="p-lg-4">Token ID:<span style={{ paddingLeft: "5px" }}>{tokenData.customTokenID}</span></div>
            //                         <div className="p-lg-4">Token Type:<span style={{ paddingLeft: "5px" }}>{tokenData.requirementStatus == 0 ? "Problem" : 'Requirement'}</span></div>
            //                         <div className="p-lg-4">Status:<span style={{ paddingLeft: "5px" }}>{this.checkTokenStatus(tokenData.tokenStatus)}</span></div>
            //                     </div>
            //                     <div className="p-grid p-col-8">

            //                         <div className="p-lg-4">Module:<span style={{ paddingLeft: "5px" }}>{tokenData.tokenTypeInfoDTO && tokenData.tokenTypeInfoDTO.parentCoreCategoryInfoDTO ? tokenData.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''} {`(${tokenData.tokenSource ? tokenData.tokenSource : ''})`}</span></div>

            //                     </div>

            //                     <div className="p-col-8 formControl">
            //                         <InputTextarea
            //                             placeholder="Token Details"
            //                             value={tokenData.tokenDetails || null}
            //                             rows={2}
            //                             cols={20}
            //                             autoResize={false}
            //                             id="detail"
            //                             name="details"
            //                         />
            //                     </div>

            //                     <div className="p-grid p-col-8">
            //                         <div className="p-lg-4">Assign Date:<span style={{ paddingLeft: "5px" }}>{"no data found from api"}</span></div>
            //                     </div>

            //                 </div>
            //             </Fieldset>
            //         </div>
            //     </div>
            // </div>
        );
    }

    onChangeTokenType = (e) => {
        let { tokenType } = this.state;
        tokenType = e.target.value;
        this.setState({ tokenType });
    }

    renderTokenDataByInstituteID = (tokenData) => {
        return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12 ">
                        <Fieldset className="p-col-12 p-xl-12" legend="Token Information">
                            <div className="p-col-12" >
                                <div className="p-lg-7"><h2>{tokenData.tokenTypeInfoDTO.categoryName}</h2></div>
                                <div className="p-grid p-col-12">
                                    <div className="p-lg-4">Token ID:<span style={{ paddingLeft: "5px" }}>{tokenData.customTokenID}</span></div>
                                    <div className="p-lg-4">Token Type:<span style={{ paddingLeft: "5px" }}>{tokenData.requirementStatus == 0 ? "Problem" : 'Requirement'}</span></div>
                                    <div className="p-lg-4">Status:<span style={{ paddingLeft: "5px" }}>{this.checkTokenStatus(tokenData.tokenStatus)}</span></div>
                                </div>
                                <div className="p-grid p-col-8">
                                    {/* <div className="p-lg-6">Problem Type:<span style={{ paddingLeft: "5px" }}>{tokenData.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.categoryName}</span></div> */}
                                    <div className="p-lg-4">Module:<span style={{ paddingLeft: "5px" }}>{tokenData.tokenTypeInfoDTO && tokenData.tokenTypeInfoDTO.parentCoreCategoryInfoDTO ? tokenData.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''} {`(${tokenData.tokenSource ? tokenData.tokenSource : ''})`}</span></div>

                                </div>

                                <div className="p-col-8 formControl">
                                    <InputTextarea
                                        placeholder="Token Details"
                                        value={tokenData.tokenDetails || null}
                                        rows={2}
                                        cols={20}
                                        autoResize={false}
                                        id="detail"
                                        name="details"
                                    />
                                </div>

                                <div className="p-grid p-col-8">
                                    {/* <div className="p-lg-6">Token Solver:<span style={{ paddingLeft: "5px" }}>{tokenData.solverBasicInfoDTO.fullName}</span></div> */}
                                    <div className="p-lg-4">Assign Date:<span style={{ paddingLeft: "5px" }}>{"no data found from api"}</span></div>
                                </div>

                            </div>
                        </Fieldset>
                    </div>
                </div>
            </div>
        );
    }




    onChangeTokenType = (e) => {
        let { tokenType } = this.state;
        tokenType = e.target.value;
        this.setState({ tokenType });
    }

    onSearchToken = (e) => {
        this.setState({ tokenSearchVisibility: true });

        this.commonFuctionality.blurDialogBackgroundActive();
    }

    showCreateTokenDialog = (e) => {
        this.setState({ createTokenDialogVisibility: true });

        this.commonFuctionality.blurDialogBackgroundActive();
    }

    checkTokenStatus = (status) => {
        if (status === undefined) {
            return null;
        }

        let contentType = '';
        if (status == 0) {
            contentType = 'Pending';
        }
        else if (status == 1) {
            contentType = 'Processing';
        }
        else if (status == 2) {
            contentType = 'Cancel';
        }
        else if (status == 5) {
            contentType = 'Resubmit';
        }
        else if (status == 10) {
            contentType = 'Solve';
        }
        return contentType;
    }

    loadProcessingTokenDataTable = () => {
       this.refs.manageTokenProcessingList.viewAllProcessingToken();
    }


    render() {
        let { applicantUpdatedInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;

        let pointType = []
        if (this.state.tokenInfoDropDown && this.state.tokenInfoDropDown.length) {
            pointType = this.state.tokenInfoDropDown.map((item) => ({
                value: item.categoryName,
                label: item.categoryName
            }));
        }

        const tokenFindByList = [
            { label: 'Token ID', value: 'Token ID' },
            { label: 'Mobile No.', value: 'Mobile No.' },
            { label: 'Institute ID', value: 'Institute ID' }
        ]

        let tokenInfoHeader = <div className="header-title">
                                    <div className="header-title-left">
                                        Token Information
                                    </div>
                                    <div className="header-title-right">

                                        <Button
                                            className="p-button-dark nw-button nw-button-right"
                                            label="Search By"
                                            icon="fas fa-search"
                                            onClick={e => this.onSearchToken()}
                                        />

                                        <Button
                                            className="p-button-success nw-button nw-button-right"
                                            label="Create Token"
                                            icon="fas fa-plus-square"
                                            onClick={this.showCreateTokenDialog}
                                        />
                                    </div>
                                </div>;

        return (
            <div className="p-fluid">
                {/* <div className="p-grid nw-form"> */}
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <Growl ref={(el) => this.growl = el} />
                    <div className="main-section">
                        
                        {/* <div className="p-col-12 p-xl-12">
                            <ManageTokenCreate onSearchToken={this.onSearchToken.bind(this)} />
                        </div> */}

                        {/* <div className="p-col-12 p-xl-12">
                            <div className="p-grid">

                                <div className="p-col-12 p-xl-12 nw-data-table">
                                    {this.state.dataTableIsLoadingHomepage ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <DataTable
                                            header={tokenInfoHeader}
                                        >

                                            <Column field="" header="Pending" body={this.pendingBody} />

                                        </DataTable>
                                    }
                                </div>
                            </div>
                        </div> */}

                        <div className="p-grid nw-data-table">

                                <div className="p-col-12 p-xl-12 nw-datatable-scroller">
                                    <div className="blur-section">
                                        <DataScroller
                                            className="dataScroller-no-border"
                                            inline={true}
                                            header={tokenInfoHeader}
                                        ></DataScroller>
                                    </div>


                                    <div className="p-col-12 p-xl-12 token-info-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 token-info-body-inside">
                                                <ManageTokenPendingList 
                                                    loadProcessingTokenDataTable={this.loadProcessingTokenDataTable}
                                                    
                                                />
                                            </div>

                                            <div className="p-col-6 token-info-body-inside">
                                                <ManageTokenProcessingList ref="manageTokenProcessingList"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                

                                

                                {/* <div className="p-col-4">
                                    <ManageTokenSolvedList ref="manageTokenSolvedList"/>
                                </div> */}
                        </div>
                    </div>

                    <div className="dialog-section">

                        <Dialog maximizable header='Token Search' visible={this.state.tokenSearchVisibility} className="nw-dialog" modal={true} onHide={this.onHideTokenSearch}>
                            <div className="p-fluid">
                                <div className="p-grid nw-form">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-form-body">

                                            <div className=" p-grid">
                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Find By <span>*</span></span>
                                                        <Dropdown
                                                            placeholder="Select Find By"
                                                            value={this.state.tokenFindBy}
                                                            options={tokenFindByList}
                                                            onChange={this.onChangeTokenFindBy}
                                                            showClear={true}
                                                            autoWidth={false}
                                                            name="findBy"
                                                        />
                                                    </div>
                                                </div>

                                                {this.state.tokenFindBy == "Token ID" ?

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Token ID <span>*</span></span>
                                                            <InputText
                                                                value={this.state.tokenID}
                                                                className="custom-form-control"
                                                                placeholder="Enter Token ID"
                                                                id="tokenID"
                                                                name="tokenID"
                                                                keyfilter="pint"
                                                                onChange={this.onChangeTokenID}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.tokenIDError["tokenID"]}</span>
                                                    </div>
                                                    : null}

                                                {this.state.tokenFindBy == "Mobile No." ?

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Mobile No. <span>*</span></span>
                                                            <InputText
                                                                value={this.state.mobileNo}
                                                                className="custom-form-control"
                                                                placeholder="Enter Mobile No"
                                                                name="mobileNo"
                                                                maxLength={11}
                                                                keyfilter="pint"
                                                                onChange={this.onChangeMobileNo}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.tokenIDError["mobileNo"]}</span>
                                                    </div>
                                                    : null}
                                                {this.state.tokenFindBy == "Institute ID" ?

                                                    <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                                            <InputText
                                                                value={this.state.instituteID}
                                                                className="custom-form-control"
                                                                placeholder="Enter Institute ID"
                                                                name="instituteID"
                                                                keyfilter="pint"
                                                                onChange={this.onChangeInstituteID}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.tokenIDError["instituteID"]}</span>
                                                    </div>
                                                    : null}

                                                {this.state.tokenFindBy == "Token ID" ?
                                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            icon="fas fa-search"
                                                            label="Search"
                                                            onClick={this.onSearchByTokenID}
                                                        />
                                                    </div>
                                                    : null}

                                                {this.state.tokenFindBy == "Mobile No." ?
                                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            icon="fas fa-search"
                                                            label="Search"
                                                            onClick={this.onSearchByMobileNo}
                                                        />
                                                    </div>
                                                    : null}

                                                {this.state.tokenFindBy == "Institute ID" ?
                                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                                        <Button
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                            icon="fas fa-search"
                                                            label="Search"
                                                            onClick={this.onSearchByInstituteID}
                                                        />
                                                    </div>
                                                    : null}

                                            </div>

                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">

                                        {this.state.tokenInfoView ?
                                            this.renderTokenDataByMobileNo(this.state.tokenInfoObj): ''
                                        }
                                        {/* {this.state.tokenInfoView ?
                                            <div className="nw-search-view">
                                                <div>
                                                    <div className="p-col-12 p-xl-12">
                                                            <label><h2>{this.state.tokenInfoObj.tokenTypeInfoDTO ? this.state.tokenInfoObj.tokenTypeInfoDTO.categoryName : ''}</h2></label>
                                                        <br />
                                                        <br />
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.inputFieldIsLoading ?
                                                            this.netiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Module</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {
                                                                        this.state.tokenInfoObj.tokenTypeInfoDTO && this.state.tokenInfoObj.tokenTypeInfoDTO.parentCoreCategoryInfoDTO ? 
                                                                            this.state.tokenInfoObj.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''
                                                                    } 

                                                                    {
                                                                        ` (${
                                                                        this.state.tokenInfoObj.tokenSource ? 
                                                                            this.state.tokenInfoObj.tokenSource : ''
                                                                        })`
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.inputFieldIsLoading ?
                                                            this.netiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Problem Type</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    { this.state.tokenInfoObj.tokenTypeInfoDTO ? this.state.tokenInfoObj.tokenTypeInfoDTO.categoryName : '' }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.inputFieldIsLoading ?
                                                            this.netiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Token Type</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {this.state.tokenInfoObj.requirementStatus == 0 ? "Problem" : 'Requirement'}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.inputFieldIsLoading ?
                                                            this.netiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Token ID</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {this.state.tokenInfoObj.customTokenID}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.inputFieldIsLoading ?
                                                            this.netiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Status</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {this.checkTokenStatus(this.state.tokenInfoObj.tokenStatus)}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.inputFieldIsLoading ?
                                                            this.netiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Description</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    { this.state.tokenInfoObj.tokenDetails }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.inputFieldIsLoading ?
                                                            this.netiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Token Solver</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {
                                                                        this.state.tokenInfoObj && this.state.tokenInfoObj.solverBasicInfoDTO ? 
                                                                            this.state.tokenInfoObj.solverBasicInfoDTO.fullName : '---'
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.inputFieldIsLoading ?
                                                            this.netiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">Solved Date</span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {
                                                                        this.state.tokenInfoObj.solveDate || "---"
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>


                                                </div>
                                            </div>
                                            :''
                                        } */}



                                        {this.state.tokenInfoViewByMobileNo ?
                                            <DataScroller
                                                value={this.state.tokenInfoByMobileArr}
                                                inline={true}
                                                layout={this.state.layout}
                                                header={`Token Search By User Contact No (${this.state.tokenInfoByMobileArr.length})`}
                                                itemTemplate={this.tokenDataByMobileNoTemplate}
                                                rows={10}
                                                scrollHeight="100vh"
                                            ></DataScroller>
                                                
                                            : ''
                                        }

                                        {this.state.tokenInfoViewByInstituteID ?
                                            <DataScroller
                                                value={this.state.tokenInfoByInstituteArr}
                                                inline={true}
                                                layout={this.state.layout}
                                                header={`Token Search By Institute ID (${this.state.tokenInfoByInstituteArr.length})`}
                                                itemTemplate={this.tokenDataByMobileNoTemplate}
                                                rows={10}
                                                scrollHeight="100vh"
                                            ></DataScroller>
                                            : ''
                                        }

                                    </div>
                                </div>





                                    
                                {
                                // this.state.tokenInfoView ?
                                //     <Fieldset className="p-col-12 p-xl-12">
                                //         <div className="p-col-12" style={{ borderBottom: '1px solid #d9d9d9' }}>
                                //             <div className="p-lg-7"><h2>{this.state.tokenInfoObj.tokenTypeInfoDTO ? this.state.tokenInfoObj.tokenTypeInfoDTO.categoryName : ''}</h2></div>
                                //             <div className="p-grid p-col-12">
                                //                 <div className="p-lg-4">Token ID:<span style={{ paddingLeft: "5px" }}>{this.state.tokenInfoObj.customTokenID}</span></div>
                                //                 <div className="p-lg-4">Token Type:<span style={{ paddingLeft: "5px" }}>{this.state.tokenInfoObj.requirementStatus == 0 ? "Problem" : 'Requirement'}</span></div>
                                //                 <div className="p-lg-4">Status:<span style={{ paddingLeft: "5px" }}>{this.checkTokenStatus(this.state.tokenInfoObj.tokenStatus)}</span></div>
                                //             </div>
                                //             <div className="p-grid p-col-8">
                                //                 {/* <div className="p-lg-6">Problem Type:<span style={{ paddingLeft: "5px" }}>{this.state.tokenInfoObj.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.categoryName}</span></div> */}
                                //                 <div className="p-lg-4">Module:<span style={{ paddingLeft: "5px" }}>{this.state.tokenInfoObj.tokenTypeInfoDTO && this.state.tokenInfoObj.tokenTypeInfoDTO.parentCoreCategoryInfoDTO ? this.state.tokenInfoObj.tokenTypeInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''} {`(${this.state.tokenInfoObj.tokenSource ? this.state.tokenInfoObj.tokenSource : ''})`}</span></div>

                                //             </div>

                                //             <div className="p-col-8 formControl">
                                //                 <InputTextarea
                                //                     placeholder="Token Details"
                                //                     value={this.state.tokenInfoObj.tokenDetails || null}
                                //                     rows={2}
                                //                     cols={20}
                                //                     autoResize={false}
                                //                     id="detail"
                                //                     name="details"
                                //                 />
                                //             </div>

                                //             <div className="p-grid p-col-8">
                                //                 <div className="p-lg-6">Token Solver:<span style={{ paddingLeft: "5px" }}>{this.state.tokenInfoObj && this.state.tokenInfoObj.solverBasicInfoDTO ? this.state.tokenInfoObj.solverBasicInfoDTO.fullName : ''}</span></div>
                                //                 <div className="p-lg-4">Assign Date:<span style={{ paddingLeft: "5px" }}>{"no data found from api"}</span></div>
                                //             </div>

                                //         </div>
                                //     </Fieldset>
                                //     : ''
                                    }

                                {/* {this.state.tokenInfoViewByMobileNo ?
                                    <div className="p-col-12">
                                        <DataScroller
                                            value={this.state.tokenInfoByMobileArr}
                                            inline={true}
                                            layout={this.state.layout}
                                            header={`Token Search By User Contact No (${this.state.tokenInfoByMobileArr.length})`}
                                            itemTemplate={this.tokenDataByMobileNoTemplate}
                                            rows={10}
                                            scrollHeight="100vh"
                                        ></DataScroller>
                                    </div>
                                        
                                    : ''} */}

                                {/* {this.state.tokenInfoViewByInstituteID ?
                                    <Fieldset className="p-col-12 p-xl-12">
                                        <div className="p-grid p-col-12 card card-w-title">
                                            <div className="p-col-12">
                                                <div className="content-section implementation">
                                                    <DataScroller
                                                        value={this.state.tokenInfoByInstituteArr}
                                                        inline={true}
                                                        layout={this.state.layout}
                                                        header={`Token Search By Institute ID (${this.state.tokenInfoByInstituteArr.length})`}
                                                        itemTemplate={this.tokenDataByInstituteIDTemplate}
                                                        rows={10}
                                                        scrollHeight="100vh"
                                                    ></DataScroller>
                                                </div>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    : ''} */}
                            </div>

                        </Dialog>

                        <Dialog 
                            maximizable header='Create Token' 
                            visible={this.state.createTokenDialogVisibility} 
                            className="nw-dialog" 
                            modal={true} 
                            onHide={this.onHide}
                        >
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    {this.state.createTokenDialogVisibility ?
                                        <ManageTokenCreate onSearchToken={this.onSearchToken.bind(this)} turnOffDialog={this.onHide}/>
                                    :''}
                                                
                                </div>
                            </div>
                        </Dialog>
                </div>
            </div>
        );
    }
}