import React, { Component } from 'react';
import BalanceWithdrowLogDetail from './BalanceWithdrowLogDetail';

export class BalanceLogWithdraw extends Component {

    constructor(props) {
        super(props);
        this.state = {
		
        }

    }
	render() {
		return (
            <div >
                <BalanceWithdrowLogDetail/>
            </div>
        );
	}
}