import React, { Component } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { StudentPortalMain } from '../studentPortal/StudentPortalMain';
import { EM_USER_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { EmUserPointService } from '../../../service/emUserPoint/EmUserPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
let userID;
let userList = [];
export class TeacherPortalInfoList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: '',
        };
        this.emUserPointService = new EmUserPointService();
        this.portalBody = this.portalBody.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        userList = [];
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.emUserPointService.fetchMappedPortalList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log('Log: GoToPortal -> componentWillMount -> body', body)
                        body.map(item => {
                            if(item.mappingType === "HR") {
                                userList.push(item)
                            }
                        })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                    })
                } else {
                    this.emUserPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });

    }

    portalBody = (rowData) => {

        if (rowData.mappingType === "HR") {

            return <div className='text-center'>
                <Link to={{ pathname: EM_USER_POINT.TEACHER_PORTAL, userHRDetails: rowData }}> <Button className="nw-action-button view"  icon="fas fa-eye" tooltip="Click for Details" /> </Link>
            </div>

        }
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let header = <div className="header-title">
                            <div className="header-title-left">
                                Teacher List
                            </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {userList.length}
                                </a>
                            </div>
                        </div>;
        return (
            <div className="p-fluid main-section netiWorld-DashBoard-wrapper">
                <div className="m-0">
                    <div className="p-fluid">
                        {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }

                        <div className="nw-data-table m-0">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader()
                                :
                                <DataTable
                                    value={userList}
                                    header={header}
                                    responsive={true}
                                    paginator={true}
                                    rows={5}
                                    rowsPerPageOptions={[10, 20, 50]}
                                >
                                    <Column field="mappingType" header="User Type" sortable={true} filter={true} />
                                    <Column field="idForHr" header="User ID" sortable={true} filter={true} />
                                    {/* <Column field="emDetailsInfoDTO.assignedUserBasicInfoDTO.basicMobile" header="Mobile No." sortable={true} filter={true} /> */}
                                    <Column field="emDetailsInfoDTO.instituteId" header="Institute ID" sortable={true} filter={true} />
                                    <Column field="emDetailsInfoDTO.instituteName" header="Institute Name" sortable={true} filter={true} />
                                    <Column header="Details" style={{width: '100px'}} body={this.portalBody} />
                                </DataTable>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}