import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { PartnerIncomeTargetList } from "./PartnerIncomeTargetList";

export class PartnerIncomeTarget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTableValue: [
        { mark: "", checked: false, partnerType: "MBP", targetAmount: null },
        { mark: "", checked: false, partnerType: "ZBP", targetAmount: null },
        { mark: "", checked: true, partnerType: "BDP", targetAmount: null },
        { mark: "", checked: false, partnerType: "BEP", targetAmount: null }
      ],
      partnerIncomeTargetInfo: {
        incomeCategory: "",
        incomeYear: null,
        incomeMonth: "",
        incomeNote: "",

        coreUrlInfoDTO: {
          urlInfoID: 1
        }
      },
      errors: {},
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: ""
    };

    this.markTemplate = this.markTemplate.bind(this);
  }

  markTemplate(rowData) {
    return (
      <Checkbox checked={rowData.checked} onChange={e => this.setState({})} />
    );
  }

  targetAmountTemplate = () => {
    return (
      <div class="p-inputgroup">
        <InputText
          id="incomeNote"
          type="text"
          name="incomeNote"
          autoWidth={true}
          keyfilter="pint"
        />
        <span> BDT</span>
      </div>
    );
  };

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

    let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>


    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}

        <div className="p-grid nw-form">
       
              <Growl ref={el => (this.growl = el)} />
                <div className="p-col-12 p-xl-12">
                  <div className="nw-form-body">
                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Category {requiredSing}</span>
                        <Dropdown
                          id="incomeCategory"
                          placeholder="Select Category"
                          showClear={true}
                          type="text"
                          name="incomeCategory"
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["incomeCategory"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Year {requiredSing}</span>
                        <Dropdown
                          // value={calendarInfo.banneCategory}
                          // onChange={this.onChangecalendarCategory}
                          id="year"
                          placeholder="Select Year"
                          showClear={true}
                          type="text"
                          name="year"
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["incomeCategory"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Month {requiredSing}</span>
                        <Dropdown
                          // value={calendarInfo.banneCategory}
                          // onChange={this.onChangecalendarCategory}
                          id="month"
                          placeholder="Select Month"
                          showClear={true}
                          type="text"
                          name="month"
                          autoWidth={false}
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["incomeCategory"]}
                      </span>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="nw-data-table">
                        {this.state.dataTableValue ? (
                          <DataTable
                            value={this.state.dataTableValue}
                            responsive={true}
                            className="text-center"
                          >
                            <Column
                              field="mark"
                              header="Mark"
                              body={this.markTemplate}
                            />
                            <Column field="partnerType" header="Partner Type" />
                            <Column
                              field="targetAmount"
                              header="Target Amount"
                              body={this.targetAmountTemplate}
                            />
                          </DataTable>
                        ) : (
                            ""
                          )}
                      </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                          Note {requiredSing}
                        </span>
                        <InputTextarea
                          id="incomeNote"
                          // onChange={this.onChangeBannerDetails}
                          // value={calendarInfo.incomeNote}
                          placeholder="Enter Note"
                          type="text"
                          rows={3}
                          cols={30}
                          name="incomeNote"
                        />
                      </div>
                      <span className="error-message">
                        {this.state.errors["incomeNote"]}
                      </span>
                    </div>

                    <span className="error-message">
                      {this.state.errors["incomeNote"]}
                    </span>

                    <div className="p-col-12 p-xl-12"/>
                    

                    <div className="p-col-12 p-xl-12 nw-button-parent">

                      <div className="required-field">
                          (<span>*</span>) required fields
                      </div>

                      <Button
                        className="p-button p-button-primary nw-button nw-button-right"
                        label="Save"
                        icon="fas fa-check"
                        onClick={this.onSubmitHandler}
                      />

                </div>
              </div>
          </div>
           
           
          
          <div className="p-col-12">
            <div>
              {!this.state.dataTableIsLoading ? (
                <PartnerIncomeTargetList />
              ) : (
                  this.NetiContentLoader.MyPointTableLoader()
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
