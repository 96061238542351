import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { DwsService } from '../../service/dwsPoint/DwsService';
import NetiContentLoader from '../common/NetiContentLoader';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { NetiCMSSetupProgress } from './common/NetiCMSSetupProgress';
import { ProgressBar } from 'primereact/progressbar';
import { useQuery } from 'react-query'
import { UserCoreUrlInfoService } from '../../service/UserCoreUrlInfoService';
let cmsId;
let feedbackStatus;
export class CmsVisitorFeedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            updateErrors: {},
            viewMsgDetails: false,
            visible: false,
            updateSenderInfoObj: {},
            msgDetailsInfo: {},
            dataTableValue: [],
            errorMsgBody: null,
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            feedbackStatus: 0,
            urlId:false,
        }
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();
        this.UserCoreUrlInfoService= new UserCoreUrlInfoService();
    }

    async reloadCoreUrlIdFromLocal() {

        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        // console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.getFeedBackInfoList();
        }
    }


    getFeedBackInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
         this.DwsService.getFeedBackInfo(this.state.feedbackStatus, cmsId)
            .then((res) => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        console.log(body)
                        for (let i = 0; i < body.item.length; i++) {
                            if (body.item[i].feedbackStatus === 1) {
                                body.item[i].feedbackStatus = 'Read';
                            }
                            else if (body.item[i].feedbackStatus === 0) {
                                body.item[i].feedbackStatus = 'Unraed';
                            }
                        }
                        
                        this.setState({ dataTableValue: body.item, topProgressBar: false, dataTableIsLoading: false });

                    });
                } else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }
    
    onChangeAddInstitute = () => {
        this.setState({feedbackStatus: !this.state.feedbackStatus + 0});
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.state.feedbackStatus !== prevState.feedbackStatus){
            this.getFeedBackInfoList();
        }
    }
    updateBody = (rowData) => {
        return (
            <div className="text-center">
                <Button
                    className="nw-action-button info"
                    icon="fas fa-eye"
                    tooltip="View"
                    onClick={e => this.viewUpdateDialog(rowData)}
                />
            </div>
        );
    }

    viewUpdateDialog = (rowData) => {
        console.log(rowData);
        this.setState({ visible: true });
        this.setState({ topProgressBar: true })
        if (rowData.feedbackStatus == 'Unraed') {

            let updtObj = {
                feedbackId: rowData.feedbackId,
                feedbackStatus: 1
            }
            this.DwsService.updateFeedbacknfo(updtObj)
                .then(res => {
                    this.setState({ topProgressBar: false });
                })
                .catch(error =>
                    this.setState({ topProgressBar: false })
                );
        } else {
            this.setState({ topProgressBar: false })
        }
        this.setState({ updateSenderInfoObj: rowData });
    }


    updateDialogDiscard = () => {
        this.setState({visible: false});
        this.getFeedBackInfoList();
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, updateSenderInfoObj } = this.state;
        let tableHeader = <div className="header-title">
            <div className="header-title-left">
                {this.state.feedbackStatus ? <>Read Feedback List</>:<>Unread Feedback List</>}
            </div>

            <div className="header-title-right">
                <a>
                    Total Found: {this.state.dataTableValue.length}
                </a>

                <Button
                    label={this.state.feedbackStatus ? <>Unread Feedback</>:<>Read Feedback</>}
                    icon="fas fa-sync-alt"
                    className="p-button p-button-primary nw-button nw-button-right"
                    onClick={this.onChangeAddInstitute}
                    iconPos="right"
                />
            </div>
        </div>;


        return (
            
            <div className="p-fluid">
                <NetiCMSSetupProgress/>

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table">
                                {   this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() :
                                    <DataTable
                                        value={this.state.dataTableValue}
                                        selectionMode="single"
                                        header={tableHeader}
                                        selection={this.state.dataTableSelection}
                                        onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        columnResizeMode="fit"
                                    >
                                        <Column field="senderName" header="Sender Name" filter={true} />
                                        <Column field="senderMobileNo" header="Sender Contact No." filter={true} />
                                        <Column field="senderEmail" header="Sender Email." filter={true} />
                                        <Column field="feedbackStatus" header="Read Status" filter={true} />
                                        {this.state.feedbackStatus ? <Column style={{ width: "0px" }}></Column>:<Column body={this.updateBody}   header="Action" style={{ width: "80px" }}/>}
                                    </DataTable>
                                }
                                

                            </div>


                        </div>


                    </div>
                </div>
                <div className="dialog-section">
                <Dialog
                        header="Visitor Feedback Information"
                        visible={this.state.visible}
                        modal={true}
                        maximizable
                        onHide={this.updateDialogDiscard}
                        className="nw-dialog"
                    >
                        <div className="nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Sender Name <span>*</span></span>
                                            <div className="nw-inputgroup-desc">{updateSenderInfoObj.senderName}</div>
                                        </div>
                                      
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Sender Contact No. <span>*</span></span>
                                            <div className="nw-inputgroup-desc">{updateSenderInfoObj.senderMobileNo}</div>
                                        </div>
                                      
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Sender Email. <span>*</span></span>
                                            <div className="nw-inputgroup-desc">{updateSenderInfoObj.senderEmail}</div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Message Details <span>*</span></span>
                                            <div className="nw-inputgroup-desc">{updateSenderInfoObj.msgDetails}</div>
                                        </div>
                                    </div>


                                    <div className="p-col-12 p-xl-12">
                                        
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            label="Discard"
                                            icon="fas fa-times"
                                            className="p-button p-button-danger nw-button nw-button-multiple"
                                            onClick={this.updateDialogDiscard}
                                        />
                                     
                                    </div>


                                </div>
                            </div>

                        </div>
                    </Dialog>

                </div>
               
            </div>
        );
    }
}