import React, { Component } from "react";
import { Button } from "primereact/button";
import { NetiFileHandler } from "./NetiFileHandler";

export class DownloadButton extends Component {
    constructor(props) {
        super(props);
        this.netiFileHandler = new NetiFileHandler();
    }

    downloadFile() {
        console.log("this.props",this.props);
        const { fileName, filePath } = this.props;
        if (filePath !== null && fileName !== null) {
            this.netiFileHandler.getByteImage(filePath).then(res => {
                if (res.status == 200) {
                    return res.json().then(body => {
                        let contentType = this.netiFileHandler.getImageContentType(
                            fileName
                        );
                        let a = document.createElement("a");
                        a.href = contentType + body.fileContent;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    });
                } else {
                }
            });
        }
    }
    render() {
        return (
            <div className="download-file-button">
                <Button
                    type="button"
                    icon="pi pi-download"
                    className="nw-action-button edit"
                    onClick={this.downloadFile.bind(this)}
                ></Button>
            </div>
        );
    }
}
