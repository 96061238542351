import React, { Component } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ChatBoxService } from '../../../service/chatBox/chatBoxService';
import Formatter from '../../../utils/Formatter';
import CommonFuctionality from '../../common/CommonFuctionality';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ProfileService } from '../../../service/profile/ProfileService';
import { SendSmsAndNetiMail } from "../../common/SendSmsAndNetiMail";
import {Dropdown} from 'primereact/dropdown';



export class MessageSendToPartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageBody: "",
            userInfo: {},
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            searchIsLoading: false,
            dataTableValue: [],
            searchIsLoadingFirst: false,
            searchIsLoadingSecond: false,
            uploadImage: {},
            secondSearchIsLoading: false,
            secondSearchErrorMsg: '',
            secondfirstSearchView: false,
            secondSearchErrorVisible: false,
            netiInformation:{},
            disableAddButton: true,
            designationArray:[],
            selectPartner:null,
            /***Data table checkbox***/
            selectedCustomers: [],
            globalFilter: null,
            selectedRepresentatives: null,
        };
        
        this.NetiContentLoader = new NetiContentLoader();
        this.formatter = new Formatter();
        this.CommonFuctionality = new CommonFuctionality();
        this.CRMPointService = new CRMPointService();

    }

    componentWillMount(){
        this.netiIdCheckHandler();
    }

    //Get Partner List
    netiIdCheckHandler = () =>{
        let { designationArray, topProgressBar, firstDropdownIsLoading } = this.state; 
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.CRMPointService.getPartnerDesignationListForSendSms("T11202")
            .then(res => {
                if (res.status == 302) {
                    this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
                    return res.json().then((body) => {
                        for (let index = 0; index < body.length; index++) {
                            let parent = { 
                                "label" : body[index].categoryName,
                                "value" : body[index].categoryDefaultCode
                            }
                            designationArray.push(parent);
                        }
                        
                    });
                    
                }
        }).catch(error => {
            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
          });;
    }

    selectPartnerHandler = (e) =>{
        this.setState({selectPartner : e.target.value});
    }


    ///Sms sender list
    getSenderInformation = () => {
        if(this.netiIdHandleError()){
            let { designationArray, topProgressBar, dataTableIsLoading, selectPartner, dataTableValue } = this.state; 
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.CRMPointService.getPartnerListForSendSms(selectPartner)
            .then(res => {
                if (res.status == 302) {
                    this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
                    return res.json().then((body) => {

                        body.map(item => {
                            let areaName = String(item.partnerAreaInfoDTO.categoryName);
                            let netiID = String(item.userBasicInfoDTO.netiID);
                            let customNetiID = String(item.userBasicInfoDTO.customNetiID);
                            let name = String(item.userBasicInfoDTO.fullName);
                            let mobile = String(item.userBasicInfoDTO.basicMobile);
                            let email = String(item.userBasicInfoDTO.basicEmail);
                            
                            item.partnerAreaInfoDTO.categoryName = item.partnerAreaInfoDTO.categoryName;
                            item.userBasicInfoDTO.netiID = item.userBasicInfoDTO.netiID;
                            item.userBasicInfoDTO.customNetiID = item.userBasicInfoDTO.customNetiID;
                            item.userBasicInfoDTO.fullName =  item.userBasicInfoDTO.fullName;
                            item.userBasicInfoDTO.basicMobile = item.userBasicInfoDTO.basicMobile;
                            item.userBasicInfoDTO.basicMobile = item.userBasicInfoDTO.basicMobile;
                            Object.assign(item, {areaName: areaName, netiID: netiID, customNetiID: customNetiID, name : name, mobile : mobile, email : email});
                        });

                        this.setState({dataTableValue : body, topProgressBar: false, dataTableIsLoading: false,});
                    });
                }
        }).catch(error => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
          });;
        }
    }

    netiIdHandleError = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.selectPartner) {
            formIsValid = false;
            errors["partnerList"] = "Please Select Partner Type.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    



    render() {
        let { dataTableValue, selectedCustomers, topProgressBar, errorMsgVisible, errorMsgBody, designationArray } = this.state;

        let header =  <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Institute List <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;
        
        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={el => (this.growl = el)} />
                <div className="main-section">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                            <div className="p-grid seprator-inside">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                    {this.state.firstDropdownIsLoading ?
                                         this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Designation <span>*</span></span>
                                                <Dropdown 
                                                    autoWidth={false} 
                                                    placeholder="Select a Partner"
                                                    value={this.state.selectPartner}
                                                    onChange={this.selectPartnerHandler}
                                                    options={designationArray} 
                                                />
                                            </div>
                                            <span className="error-message">{this.state.errors["partnerList"]}</span>
                                        </div>
                                    }

                                        <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Search"
                                                onClick={this.getSenderInformation}
                                                icon="fas fa-search"
                                            />
                                        </div>
                                        <div className="p-col-12 p-lg-12 p-xl-12 p-col-nogutter">
                                            {
                                                this.state.dataTableIsLoading ?
                                                    this.NetiContentLoader.MyPointTableLoader():
                                                    <div className="nw-data-table">
                                                        <DataTable
                                                            value={dataTableValue}
                                                            header={header}
                                                            responsive={true}
                                                            selection={this.state.selectedCustomers} 
                                                            onSelectionChange={e => this.setState({selectedCustomers: e.value})}
                                                            paginator={true}
                                                            rows={10}
                                                        >
                                                            <Column selectionMode="multiple" style={{width:'3em'}}/>
                                                            <Column field="areaName" header="Area" filter={true} />
                                                            <Column field="customNetiID" header="Neti ID" filter={true} />
                                                            <Column field="name" header="Name" filter={true} />
                                                            <Column field="mobile" header="Mobile" filter={true} />
                                                            <Column field="email" header="Email" filter={true} />
                                                        </DataTable>

                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-4">
                            <SendSmsAndNetiMail smsActive={ true } netiMailActive={ true } emailActive={ true } userInfo={ selectedCustomers }  filterData={true}/>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
