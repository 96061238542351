import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { AdminPointService } from "../../../service/adminPoint/AdminPointService";
import NetiContentLoader from "../../common/NetiContentLoader";

import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CommonFuctionality from "../../common/CommonFuctionality";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { Growl } from "primereact/growl";
import { from } from "rxjs";
import { Link } from "react-router-dom";

const AddMenuForm = () => {
  const growl = useRef(null);
  const adminPointService = new AdminPointService();
  const [topProgressBar, settopProgressBar] = useState(false);
  const [errorMsgBody, seterrorMsgBody] = useState(false);
  const [errorMsgVisible, seterrorMsgVisible] = useState(false);
  const [menuName, setmenuName] = useState("");
  const [defaultCode, setdefaultCode] = useState("");
  const [menuLayer, setmenuLayer] = useState(0);
  const [updateMenuLayer, setupdateMenuLayer] = useState(0);
  const [url, seturl] = useState("");
  const [saveErrors, setsaveErrors] = useState({});
  const [deleteDialogVisible, setdeleteDialogVisible] = useState(false);
  const [deleteUserObj, setdeleteUserObj] = useState({});
  const [parentMenu, setParentMenu] = useState({});
  const [updateParentMenu, setupdateParentMenu] = useState({});
  const [parentStatus, setparentStatus] = useState(false);
  const [updateParentStatus, setupdateEnableStatus] = useState(false);
  const [note, setnote] = useState("");
  const [parentCmsMenuItemId, setparentCmsMenuItemId] = useState(null);
  const [tdataTableValue, settdataTableValue] = useState([]);
  const [dataTableIsLoading, setdataTableIsLoading] = useState(false);
  const [updateTypeObject, setupdateTypeObject] = useState({});
  const [error, seterror] = useState({});
  const [showDialogue, setshowDialogue] = useState(false);
  const [buttonIconAnimClass, setbuttonIconAnimClass] = useState(
    "p-button-primary"
  );
  const [buttonIcon, setbuttonIcon] = useState("fas fa-check");

  const functionality = new CommonFuctionality();

  const resetForm = () => {
    setmenuName("");
    setdefaultCode("");
    setmenuLayer("");
    setParentMenu({});
    setparentStatus(false);
    setnote("");
    setparentCmsMenuItemId(null);
  };

  const handleSaveError = () => {
    let saveErrors = {};
    let formIsValid = true;

    if (menuName === "") {
      formIsValid = false;
      saveErrors["menuName"] = "Menu Name can't left empty";
    }
    if (defaultCode === "") {
      formIsValid = false;
      saveErrors["defaultCode"] = "Default code can't left empty";
    }
    if (menuLayer === 0) {
      formIsValid = false;
      saveErrors["menuLayer"] = "Menu Layer can't left empty";
    }
    if (note === "") {
      formIsValid = false;
      saveErrors["note"] = "Note can't left empty";
    }
    if (parentStatus && parentMenu === {}) {
      formIsValid = false;
      saveErrors["parentMenu"] = "Please select parent menu";
    }

    setsaveErrors(saveErrors);
    return formIsValid;
  };

  const onHide = (event) => {
    setdeleteDialogVisible(false);
    setshowDialogue(false);
    functionality.blurDialogBackgroundDeActive();
  };

  const onMenuNameChangeHandle = (event) => {
    setmenuName(event.target.value);
  };

  const onDefaultCodeChangeHandle = (event) => {
    setdefaultCode(event.target.value);
  };

  const menuLayerListOptions = [
    { label: "- 1st", value: 1 },
    { label: "- 2nd", value: 2 },
    { label: "- 3rd", value: 3 },
  ];

  const [parentLayerOptions, setparentLayerOptions] = useState([]);
  useEffect(() => {
    if (menuLayer) {
      setparentStatus(false);
    }
  }, [menuLayer]);
  useEffect(() => {
    if (parentStatus) {
      //network request
      findParentsByLayer(menuLayer);
    }
  }, [parentStatus, menuLayer]);

  const findParentsByLayer = (layer) => {
    const parentLayer = layer - 1;
    adminPointService
      .findParentsByLayer(parentLayer)
      .then((res) => {
        if (res.status == 200) {
          return res.json().then((body) => {
            const layers = body.item.map((layer) => {
              return {
                ...layer,
                value: {
                  name: layer.name,
                  id: layer.menuItemId,
                  url: layer.url,
                },
                label: layer.name,
              };
            });

            setparentLayerOptions(layers);

            //set state here
          });
        } else {
          adminPointService.Auth.handleErrorStatus(res).then((resp) => {
            //set state here
          });
        }
      })
      .catch((error) => {
        //setstate here
      });
  };

  const onChangeMenuLayerOptions = (event) => {
    setmenuLayer(event.target.value);
  };

  useEffect(() => {
    loadMenuItemList();
  }, []);

  const loadMenuItemList = () => {
    setdataTableIsLoading(true);

    adminPointService
      .fetchMenuList()
      .then((res) => {
        if (res.status == 200) {
          return res.json().then((body) => {
            if (body.messageType === 1) {
              for (let i = 0; i < body.item.length; i++) {
                if (body.item[i].enableStatus == 1) {
                  body.item[i].status = "Enable";
                } else {
                  body.item[i].status = "Disable";
                }

                if (body.item[i].parentStatus == 1) {
                  body.item[i].pStatus = "Yes";
                } else {
                  body.item[i].pStatus = "No";
                }
              }
              settdataTableValue(body.item);
              setdataTableIsLoading(false);
            } else {
              //set state here
            }
          });
        } else {
          adminPointService.Auth.handleErrorStatus(res).then((resp) => {
            //set state here
          });
        }
      })
      .catch((error) => {});
  };

  const onParentDropdownChange = (event) => {
    setParentMenu(event.target.value);
    setparentCmsMenuItemId(event.target.value.id);
    seturl(event.target.value.url);
  };

  const onSubmitHandler = (event) => {
    const postData = {
      defaultCode: defaultCode,
      name: menuName,
      note: note,
      parentStatus: parentStatus ? 1 : 0,
      menuLayer: menuLayer,
      parentCmsMenuItemId: parentCmsMenuItemId,
      url: url,
    };

    if (handleSaveError()) {
      setdataTableIsLoading(true);
      settopProgressBar(true);
      seterrorMsgVisible(false);
      adminPointService
        .saveMenu(postData)
        .then((res) => {
          if (res.status == 201) {
            return res.json().then((body) => {
              if (body.messageType === 1) {
                //set state here
                growl.current.show({
                  severity: "success",
                  summary: "Success",
                  detail: body.message,
                });
                settopProgressBar(false);
                seterrorMsgVisible(false);
                resetForm();
                setTimeout(() => {
                  loadMenuItemList();
                }, 1000);
              } else {
                setdataTableIsLoading(false);
                settopProgressBar(false);
                seterrorMsgVisible(true);
                seterrorMsgBody(body.message);
              }
            });
          } else {
            adminPointService.Auth.handleErrorStatus(res).then((resp) => {
              //set state here
            });
          }
          //can set state here
        })
        .catch((error) => {});
    }
  };

  const loader = new NetiContentLoader();

  const viewDialog = (rowData) => {
    setshowDialogue(true);

    let updateTypeObject = {};
    updateTypeObject.enableStatus = rowData.enableStatus;
    updateTypeObject.menuItemId = rowData.menuItemId;

    setupdateTypeObject(updateTypeObject);

    functionality.blurDialogBackgroundActive();
  };

  const deleteDialogView = () => {
    return (
      <Dialog
        header="Delete Menu Item"
        visible={deleteDialogVisible}
        onHide={onHide}
        className="nw-confirm-dialog"
      >
        <div className="p-fluid">
          <div className="p-col-12 p-col-12">
            <div className="confirm-wrapper">
              {/* <div className="body-wrapper">
                                <header>Total messages you are about to send</header>
                                <h1>{selectedCustomers.length}</h1>
                                <footer>{selectedCustomers.length}  Person x {selectedCustomers.length} Message</footer>
                            </div> */}
              <h1>Are You Sure?</h1>
              <div className="nw-button-parent-multiple">
                <Button
                  className="p-button-danger nw-button nw-button-multiple"
                  label="No"
                  icon="fas fa-times"
                  onClick={onHide}
                />
                <Button
                  className="p-button-primary nw-button nw-button-multiple"
                  label="Yes"
                  icon="fas fa-check"
                  onClick={(e) => {
                    deleteRow(deleteUserObj);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const deleteRow = (userObj) => {
    // this.setState({
    //   dataTableIsLoading: true,
    //   topProgressBar: true,
    //   errorMsgVisible: false,
    // });
    setdataTableIsLoading(true);
    settopProgressBar(true);
    seterrorMsgVisible(false);
    adminPointService
      .cmsMenuItemDeelete(userObj.menuItemId)
      .then((res) => {
        return res.json().then((body) => {
          console.log("Body", body);
          if (body.messageType === 0) {
            // this.setState({ topProgressBar: false, errorMsgVisible: false });
            settopProgressBar(false);
            seterrorMsgVisible(false);
            growl.current.show({
              severity: "success",
              summary: "Success",
              detail: body.message,
            });
            onHide();
            setTimeout(() => {
              // viewSeatInfoList();
              loadMenuItemList();
            }, 1000);
          } else {
            setdataTableIsLoading(false);
            settopProgressBar(false);
            seterrorMsgVisible(true);
            seterrorMsgBody(body.message);
          }
        });
      })
      .catch((error) => {
        // errors["dropDownClassError"] = 'Connection Problem'
        seterror(error);
        setdataTableIsLoading(false);
        settopProgressBar(false);
        seterrorMsgVisible(true);
        seterrorMsgBody("Please check your connection");
      });
  };

  const onChangeUpdateEnableStatus = (e) => {
    let updateObject = { ...updateTypeObject },
      errors = {};
    if (e.value === true) {
      updateObject.enableStatus = 1;
      // setupdateTypeObject({ parentStatus: "1", diagShow: true });
    }
    if (e.value === false) {
      updateObject.enableStatus = 0;
    }

    errors["updateParent"] = "";
    seterror({ errors });
    setupdateTypeObject(updateObject);
  };

  const updateDialogDiscard = (e) => {
    onHide();
  };

  const UpdateDepositTypeCheck = () => {
    let activeHomePage = "far fa-circle",
      activeGallery = "far fa-circle";
    let cashStatus = false;
    let chequeStatus = false;

    if (updateTypeObject.enableStatus) {
      activeHomePage = "fas fa-check-circle";
      cashStatus = true;
    } else {
      activeGallery = "fas fa-check-circle";
      chequeStatus = true;
    }
    return (
      <div className="input-radio-button">
        <div className="radio-button-inside">
          {/* <i class={activeHomePage}></i> */}
          <RadioButton
            value={false}
            inputId="rb1"
            onChange={(event) => {
              onChangeUpdateEnableStatus(event);
              setupdateEnableStatus(event.value);
            }}
            checked={!updateTypeObject.enableStatus}
          />
          <label htmlFor="rb1" className="p-radiobutton-label">
            Disable
          </label>
        </div>

        <div className="radio-button-inside">
          {/* <i class={activeGallery}></i> */}
          <RadioButton
            value={true}
            inputId="rb1"
            onChange={(event) => {
              onChangeUpdateEnableStatus(event);
              setupdateEnableStatus(event.value);
            }}
            checked={updateTypeObject.enableStatus}
          />
          <label htmlFor="rb1" className="p-radiobutton-label">
            Enable
          </label>
        </div>

        {/* { rowData.photoType} */}
      </div>
    );
  };

  const updateCategoryTypeError = () => {
    let errors = {},
      updateObject = { ...updateTypeObject };
    let formIsValid = true;

    if (updateObject.name === "") {
      formIsValid = false;
      errors["dialogcategoryType"] = "Category Type can't left empty.";
    }

    if (updateObject.parentStatus === "1") {
      formIsValid = false;
      errors["updateParent"] = "Parent status can't left empty.";
    }

    seterror(errors);
    return formIsValid;
  };

  const handleCategoryTypeUpdate = (e) => {
    if (updateCategoryTypeError()) {
      settopProgressBar(true);
      setdataTableIsLoading(true);
      seterrorMsgVisible(false);
      adminPointService
        .updateCmsMenuItem(updateTypeObject)
        .then((res) => {
          console.log(
            "Log: CategoryType -> handleCategoryTypeUpdate -> res.status",
            res.status
          );
          if (res.status === 202) {
            growl.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Successfully updated",
            });
            setshowDialogue(false);
            settopProgressBar(false);
            setdataTableIsLoading(false);
            setupdateTypeObject(updateTypeObject);
            loadMenuItemList();
            onHide();
          } else {
            adminPointService.Auth.handleErrorStatus(res).then((resp) => {
              settopProgressBar(false);
              setdataTableIsLoading(false);
              seterrorMsgBody(resp);
              seterrorMsgVisible(true);
            });
            onHide();
          }
        })
        .catch((error) => {
          settopProgressBar(false);
          setdataTableIsLoading(false);
          seterrorMsgBody("Please check your connection");
          seterrorMsgVisible(true);
        });
      setshowDialogue(false);
      setupdateTypeObject("");
    }
  };
  const depositTypeCheck = () => {
    let activeHomePage = "far fa-circle",
      activeGallery = "far fa-circle";
    let cashStatus = false;
    let chequeStatus = false;

    if (parentStatus) {
      activeHomePage = "fas fa-check-circle";
      cashStatus = true;
    } else {
      activeGallery = "fas fa-check-circle";
      chequeStatus = true;
    }
    return (
      <div className="input-radio-button">
        <div className="radio-button-inside">
          {/* <i class={activeHomePage}></i> */}
          <RadioButton
            value={false}
            inputId="rb1"
            onChange={(event) => setparentStatus(event.value)}
            checked={!parentStatus}
          />
          <label htmlFor="rb1" className="p-radiobutton-label">
            No
          </label>
        </div>

        <div className="radio-button-inside">
          {/* <i class={activeGallery}></i> */}
          <RadioButton
            disabled={menuLayer === 1}
            value={true}
            inputId="rb1"
            onChange={(event) => setparentStatus(event.value)}
            checked={parentStatus}
          />
          <label htmlFor="rb1" className="p-radiobutton-label">
            Yes
          </label>
        </div>

        {/* { rowData.photoType} */}
      </div>
    );
  };

  const showDeleteDialog = (event, tdataTableValue) => {
    setdeleteDialogVisible(true);
    setdeleteUserObj(tdataTableValue);
    functionality.blurDialogBackgroundDeActive();
  };
  const actionBody = (tdataTableValue) => {
    return (
      <div className="text-center">
        <Button
          className="nw-action-button edit"
          icon="fas fa-edit"
          tooltip="Update"
          onClick={(e) => viewDialog(tdataTableValue)}
        />
        <Button
          type="button"
          icon="fas fa-trash"
          tooltip="Delete"
          className="nw-action-button p-button-danger"
          onClick={(e) => showDeleteDialog("delete", tdataTableValue)}
        ></Button>
      </div>
    );
  };

  const headervalue = (
    <div className="header-title">
      <div className="header-title-left">Menu List</div>

      <div className="header-title-right">
        <a>Total Found: {tdataTableValue.length}</a>
      </div>
    </div>
  );

  return (
    <div className="p-fluid">
      {/* some tools gonna  go here */}
      {topProgressBar ? (
        <ErrorMessageView topProgressBar={topProgressBar} />
      ) : null}
      {errorMsgVisible ? (
        <ErrorMessageView
          errorMsgVisible={errorMsgVisible}
          errorMsgBody={errorMsgBody}
        />
      ) : null}
      <Growl ref={growl} />
      <div className="menu_section">
        <div className="p-grid nw-form">
          <div className="p-col-12 p-xl-12">
            <div className="nw-form-body">
              <div className="p-grid">
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Menu Name
                      <span>*</span>
                    </span>

                    <InputText
                      placeholder="Menu Name"
                      id="menu_name"
                      name="menuName"
                      value={menuName}
                      onChange={onMenuNameChangeHandle}
                      autoWidth={true}
                    />
                  </div>
                  <span className="error-message">
                    {saveErrors["menuName"]}
                  </span>
                </div>
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Default Code
                      <span>*</span>
                    </span>

                    <InputText
                      placeholder="Default Code"
                      id="default_code"
                      name="defaultCode"
                      value={defaultCode}
                      onChange={onDefaultCodeChangeHandle}
                      autoWidth={true}
                    />
                  </div>
                  <span className="error-message">
                    {saveErrors["defaultCode"]}
                  </span>
                </div>
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Menu Layer <span>*</span>
                    </span>
                    <Dropdown
                      placeholder="Select Menu Layer"
                      value={menuLayer}
                      options={menuLayerListOptions}
                      onChange={onChangeMenuLayerOptions}
                      // itemTemplate={this.bankAccountTemplate}
                      filter={true}
                      filterBy="value"
                      showClear={true}
                      autoWidth={false}
                    />
                  </div>

                  <span className="error-message">
                    {saveErrors["menuLayer"]}
                  </span>
                </div>
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon addon-no-style">
                      Parent Status <span>*</span>
                    </span>

                    <div className="nw-inputgroup-desc desc-no-style">
                      {depositTypeCheck()}
                    </div>
                  </div>
                  <span className="error-message"></span>
                </div>
                {parentStatus && menuLayer !== 1 ? (
                  <>
                    <div className="p-col-12 p-xl-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                          Parent <span>*</span>
                        </span>
                        <Dropdown
                          placeholder="Select Parent Menu"
                          value={parentMenu}
                          options={parentLayerOptions}
                          onChange={onParentDropdownChange}
                          // itemTemplate={this.bankAccountTemplate}
                          filter={true}
                          filterBy="value"
                          showClear={true}
                          autoWidth={false}
                        />
                      </div>

                      <span className="error-message">
                        {saveErrors["parentMenu"]}
                      </span>
                    </div>
                  </>
                ) : null}
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputtextarea-label">
                      Note <span>*</span>
                    </span>
                    <InputTextarea
                      placeholder="Enter Note"
                      value={note}
                      id="textarea"
                      rows={3}
                      cols={30}
                      autoResize={false}
                      name="menuNote"
                      onChange={(event) => setnote(event.target.value)}
                    />
                  </div>
                  <span className="error-message">{saveErrors["note"]}</span>
                </div>
                <div className="p-col-12 p-xl-12" />

                <div className="p-col-12 p-xl-12 nw-button-parent">
                  <div className="required-field">
                    (<span>*</span>) required fields
                  </div>
                  <Button
                    className={
                      buttonIconAnimClass + " nw-button nw-button-right"
                    } //p-button-primary
                    label="Save"
                    icon={buttonIcon} //"fas fa-check" //fas fa-spinner fa-spin
                    onClick={onSubmitHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-grid nw-form">
          <div className="p-col-12 p-xl-12">
            <div className="nw-data-table nw-data-table-tabview blur-section">
              {dataTableIsLoading ? (
                loader.MyPointTableLoader()
              ) : (
                <DataTable
                  header={headervalue}
                  value={tdataTableValue}
                  responsive={true}
                  paginator={true}
                  rows={10}
                >
                  <Column
                    field="name"
                    header="Menu Name"
                    sortable={true}
                    filter={true}
                  />
                  <Column
                    field="defaultCode"
                    header="Default Code"
                    sortable={true}
                    filter={true}
                    // body={this.bodyDefaultCode}
                  />
                  <Column
                    field="menuLayer"
                    header="Menu Layer"
                    sortable={true}
                    filter={true}
                    // body={this.bodyDefaultCode}
                  />
                  <Column
                    field="status"
                    header="Enable Status"
                    sortable={true}
                    filter={true}
                  />

                  <Column
                    field="pStatus"
                    header="Parent Status"
                    sortable={true}
                    filter={true}
                  />

                  <Column
                    field="parentName"
                    header="Parent Name"
                    sortable={true}
                    filter={true}
                  />
                  <Column
                    filed="action"
                    header="Action"
                    style={{ textAlign: "center", width: "8em" }}
                    body={actionBody}
                  />
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="dialog-section">{deleteDialogView()}</div>
      <div className="dialogue-section">
        <Dialog
          className="nw-dialog"
          header="Menu Item(Update Enable Status)"
          visible={showDialogue}
          onHide={onHide}
          maximizable
        >
          <div className="nw-form">
            <div className="p-col-12 p-xl-12">
              <div className="nw-form-body">
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <div className="input-radio-button">
                      <span className="p-inputgroup-addon addon-no-style">
                        Enable Status <span>*</span>
                      </span>
                      <div className="nw-inputgroup-desc desc-no-style">
                        {UpdateDepositTypeCheck()}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                  <Button
                    label="Discard"
                    icon="fas fa-times"
                    className="p-button p-button-danger nw-button nw-button-multiple"
                    onClick={updateDialogDiscard}
                  />
                  <Button
                    label="Update"
                    className="p-button p-button-primary nw-button nw-button-multiple"
                    icon="fas fa-check"
                    onClick={handleCategoryTypeUpdate}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default AddMenuForm;
