import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { Dropdown } from 'primereact/dropdown';
import { BalanceService } from '../../../service/myPoint/BalanceService';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';




let maxDate = new Date();
export class BalanceDepositApprove extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            dateRequest: '',
            bank: '',
            accountNumber: '',
            requestedAmount: '',
            transactionDate: '',
            transactionNumber: '',
            approveDate: '',
            requestStatus: '',
            note: '',
            startDate: null,
            errors: {},
            endDate: null,
            rejectedStutas: 'Your Rejected Status Here',
            dataTableSelection: null,
            dataViewValue: [],
            tableView: false,
            inputErrorMessage: null,
            dataTableValue: [],
            dateRangeParamFormat: {
                requestStartDate: "",
                requestEndDate: "",
                requestType: "Deposit",
                transactionType: "",
                status:null,
                limit: 10
            },
            visible: false,
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            rejectNote: ''

        };

        this.statusBody = this.statusBody.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.balanceService = new BalanceService();
        this.CRMPointService = new CRMPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();
    }

    statusBody(rowData) {

        if (rowData['status'] === "Rejected") {
            return <span style={{ color: "red" }} tooltip="Insufficient Balance To Withdraw">
                <u>{rowData['status']}</u>
            </span>;
        }
        else {
            return <span>{rowData['status']}</span>;
        }
    }

    onSubmitHandler = (event, props) => {
        let { dateRangeParamFormat } = this.state
        this.setState({ dataTableValue: [] });

        if (this.searchHandleError()) {
            this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(dateRangeParamFormat.requestEndDate) >= Date.parse(dateRangeParamFormat.requestStartDate)) {
                this.CRMPointService.getBalanceRequestsInfoByDateRange(dateRangeParamFormat)
                    .then(res => {
                        if (res.status == 200) {
                            return res.json().then((body) => {
                                if (body.length == 0) {
                                    return this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found.' })
                                } else {
                                    body.map((item) => {
                                        item['requestDate'] = NetiDateUtils.getAnyShortForm(item['requestDate'], 'DD-MMM-YYYY hh:mm:ss a');
                                        item['transactionDate'] = NetiDateUtils.getAnyShortForm(item['transactionDate'], 'DD-MMM-YYYY hh:mm:ss a');
                                        item['requestedAmount'] =  this.validatorUtility.currencyFormatter(item['requestedAmount'])
                                        
                                    });
                                    this.setState({ dataTableValue: body, topProgressBar: false, tableView: true, dataTableIsLoading: false });
                                }

                            });
                        } else {
                            this.CRMPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });

            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be greater than To Date." });
                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: false })
            }

        }
    }

    searchHandleError = () => {
        let { errors, dateRangeParamFormat } = this.state;
        let formIsValid = true;
        this.setState({ topProgressBar: false });
        if (dateRangeParamFormat.requestStartDate === '') {
            formIsValid = false;
            errors["requestStartDate"] = "From Date can't left empty.";
        }
        if (dateRangeParamFormat.requestEndDate === '') {
            formIsValid = false;
            errors["requestEndDate"] = "To Date can't left empty.";
        }
        
        if (dateRangeParamFormat.status === null) {
            formIsValid = false;
            errors["requestEnableStatus"] = "Enable Status can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onChangeEnableStatus(e){
        let { errors, dateRangeParamFormat } = this.state;
        dateRangeParamFormat.status=e.target.value;
        this.setState({ dateRangeParamFormat });
        errors["requestEnableStatus"] = "";
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    onClick = () => {
        this.setState({ visible: true })
    }

    onHide() {
        this.setState({ visible: false })
    }

    onChangeStartDate = (e) => {
        let { dateRangeParamFormat } = this.state;
        dateRangeParamFormat[e.target.name] = e.target.value;
        this.setState({ dateRangeParamFormat });
        this.clearDateError(e.target.name);

    }

    statusBody(rowData) {
        if (rowData['requestStatus'] === "Rejected") {
            return <span onClick={(e) => this.viewDialog(rowData)}
                style={{ color: "red" }} tooltip="Insufficient Balance To Withdraw">
                {rowData['requestStatus']}
            </span>;
        }
        else if (rowData['requestStatus'] === "approved") {
            return <span>
                {rowData['requestStatus']}
            </span>;
        }
        return <span>
            {rowData['requestStatus']}
        </span>;
    }
    viewDialog = (rowData) => {
        this.setState({
            visible: true,
            rejectNote: rowData.approveNote

        })
        this.setState({ dateRequest: this.state.requestDate === "null" ? " " : NetiDateUtils.getAnyShortForm(rowData['requestDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['requestDate']) })
        this.setState({ bank: rowData.bank })
        this.setState({ transactionType: rowData.accountNumber })
        this.setState({ requestedAmount: this.validatorUtility.currencyFormatter(rowData.requestedAmount) })
        this.setState({ transactionDate: this.state.transactionDate === "null" ? " " : NetiDateUtils.getAnyShortForm(rowData['transactionDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['transactionDate']) })
        this.setState({ transactionNumber: rowData.transactionNumber })
        this.setState({ approveDate: this.state.approveDate === "null" ? " " : NetiDateUtils.getAnyShortForm(rowData['approveDate'], 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(rowData['approveDate']) })
        this.setState({ requestStatus: rowData.requestStatus })
        this.setState({ note: rowData.approveNote })
    }

    // requestedAmount = (rowData) => {
    //     return (<b> {this.validatorUtility.currencyFormatter(rowData.requestedAmount)}</b>)

    // }

    detailBody = (rowData, column) => {
        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title='Click to view deposit details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    onChangeStatus(value) {
        this.setState({ requestStatus: value });
    }


    render() {
        let { dateRangeParamFormat, dataTableValue } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Balance Deposit Approved List <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;

        const statusOptions = [
            {label:'Approve', value:1},
            {label:'Reject', value:2}
        ]



        return (
            <div className="p-fluid">
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <Growl ref={(el) => this.growl = el} />
                            {topProgressBar ?
                                <ErrorMessageView
                                    topProgressBar={topProgressBar}
                                />
                                : null
                            }
                            {errorMsgVisible ?
                                <ErrorMessageView
                                    errorMsgVisible={errorMsgVisible}
                                    errorMsgBody={errorMsgBody}
                                />
                                : null
                            }
                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">From <span>*</span></span>
                                    <Calendar
                                        name='requestStartDate'
                                        value={dateRangeParamFormat.requestStartDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["requestStartDate"]}</span>

                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">To <span>*</span></span>
                                    <Calendar
                                        maxDate={maxDate}
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        name='requestEndDate'
                                        value={dateRangeParamFormat.requestEndDate}
                                        onChange={this.onChangeStartDate}
                                        showIcon={true}
                                        dateFormat='dd/mm/yy'
                                        yearRange="2010:2030"
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["requestEndDate"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Status <span>*</span></span>
                                    <Dropdown
                                        placeholder='Select Status'
                                        name="enableStatus"
                                        options={statusOptions}
                                        value={dateRangeParamFormat.status}
                                        onChange={(e) => this.onChangeEnableStatus(e)}
                                        autoWidth={false}
                                    />
                                </div>
                                <span className='error-message'>{this.state.errors["requestEnableStatus"]}</span>
                            </div>
                            <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                <Button
                                    label="Search"
                                    className="p-button p-button-primary nw-button nw-button-right"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitHandler}
                                />
                            </div>
                        </div>


                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table nw-data-table-tabview">

                                    {
                                        this.state.tableView === true ?
                                            this.state.dataTableIsLoading ?
                                                this.NetiContentLoader.MyPointTableLoader()
                                                :
                                                <DataTable
                                                    value={this.state.dataTableValue}
                                                    selectionMode="single"
                                                    header={tableHeader}
                                                    selection={this.state.dataTableSelection}
                                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                    responsive={true}
                                                    paginator={true}
                                                    rows={10}
                                                >
                                                    <Column field="requestDate" header="Request Date" filter={true} />
                                                    <Column field="bank" header="Bank" filter={true} />
                                                    <Column field="accountNumber" header="Account Number" filter={true} />
                                                    <Column field="requestedAmount" header="Request Amount" filter={true} />
                                                    <Column field="transactionDate" header="Transaction Date" filter={true} />
                                                    <Column field="" header="Details" body={this.detailBody} style={{ width:'100px' }} />
                                                </DataTable>
                                            :
                                            <BlankDataTableAnim />

                                    }

                                </div>
                            </div>

                            <Dialog className="nw-dialog" header="Deposit Detail" visible={this.state.visible} onHide={this.onHide} closable>

                                <div className="p-fluid">
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12">

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Request Date
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.dateRequest}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Bank
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.bank}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Account Number
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.transactionType}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Request Amount
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.requestedAmount}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Transaction Date
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.transactionDate}
                                                    </div>
                                                </div>
                                            </div>


                                            {this.state.requestStatus === "Approved" ?
                                                <div className="formControl">

                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Transaction ID
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.transactionNumber}
                                                        </div>
                                                    </div>

                                                </div>

                                                : ''
                                            }

                                            <div className="formControl">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">
                                                        Status
                                                    </span>
                                                    <div className="nw-inputgroup-desc">
                                                        {this.state.requestStatus}
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.requestStatus === "Approved" ?
                                                <div className="formControl">

                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Approved Date
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.approveDate === "null" ? " " : this.state.approveDate}
                                                        </div>
                                                    </div>

                                                </div>

                                                : ''
                                            }

                                            {this.state.requestStatus === "Rejected" ?
                                                <div className="formControl">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Reject Date
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.approveDate}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                            }


                                            {this.state.requestStatus === "Rejected" ?
                                                <div className="formControl">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Reject Note
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {this.state.note}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''
                                            }


                                        </div>
                                    </div>
                                </div>





                            </Dialog>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}