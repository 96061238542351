import React, {Component} from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';

import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';

export class DailyDisbursmentAmountList extends Component {

    constructor(props) {
		super(props);
		this.state = {
            dataTableValue:[
                {"date":"27-12-2018", "netiId":"test ID", "name":"test Name", "remainingBalance":"5555" },
            ],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody:0
        }

    }
        
    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody } = this.state;
        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Disbursment List (Datehwise) <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;

        let footer = <ColumnGroup>
                            <Row>
                                <Column footer="Totals:" colSpan={5} />
                                <Column footer={totalPaidAmountBody}/>
                                <Column footer={totalPaidAmountBody}/>
                            </Row>
                         </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className="p-grid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    
                    <div className="p-col-12 p-lg-12 p-xl-12">
                        <div className="nw-data-table">
                            {/* {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                                    <DataTable
                                        header={header}
                                        footerColumnGroup={footer}
                                        responsive={true}
                                        selectionMode="single"
                                        paginator={true}
                                        rowsPerPageOptions={[10, 20, 30]}
                                        rows={10}
                                        value={this.state.dataTableValue}
                                        //selection={this.state.dataTableSelection}
                                    >
                                        <Column field="date" header="Purchase Date & Time" filter={true} />
                                        <Column field="" header="Invoice ID" filter={true} />
                                        <Column field="" header="Neti ID" filter={true} />
                                        <Column field="" header="Name" filter={true} />
                                        <Column field="" header="Revenue Type" filter={true} />
                                        <Column className="col-number" field="" header="Disbursment Amount" filter={true} />
                                        <Column className="col-number" field="" header="Tax Amount" filter={true} />
                                        
                                        

                                    </DataTable>

                                    {/* :
                                    
                                    <BlankDataTableAnim/> 
                            } */}
                        </div>
                    </div>
                
                </div>


                
            </div>
        )
    }
}