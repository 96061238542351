import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SaSummaryService } from '../../../service/saPoint/SaSummaryService';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { SaCoreSettingsService } from '../../../service/saPoint/SaCoreSettingsService';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';

let maxDate = new Date();
export class StudentAttendanceSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            attendancePeriod: null,
            academicYear: null,
            attendanceDate: null,
            fDateErrorMessage: null,
            tDateErrorMessage: null,
            attendancePeriods: [],
            academicYears: [],
            studentAttendanceSummaryList: [],
            topProgressBar: false,
            dataTableIsLoading: false,
            firstDropdownIsLoading: false,
            secondDropDownIsLoading: false

        }

        this.saSummaryService = new SaSummaryService();
        this.saCoreSettingsService = new SaCoreSettingsService();
        this.NetiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        this.academicYear();
        this.attendancePeriod();
    }


    academicYear = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.saCoreSettingsService.getGlobalInfoByTypeId(2101)
            .then(data => {
                if (data) {
                    this.setState({ academicYears: data.item });
                    this.setState({ topProgressBar: false, firstDropdownIsLoading: false })
                }
                else {
                    this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Opps! Something went wrong' })
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })
            });

    }

    attendancePeriod = () => {
        this.setState({ topProgressBar: true, secondDropDownIsLoading: true, errorMsgVisible: false })
        this.saCoreSettingsService.getGlobalInfoByTypeId(2301)
            .then(data => {
                if (data.item) {
                    this.setState({ attendancePeriods: data.item });
                    this.setState({ topProgressBar: false, secondDropDownIsLoading: false })
                }
                else {
                    this.setState({ topProgressBar: false, secondDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Opps! Something went wrong' })
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })
            });

    }


    onSubmitHandler = (e) => {
        this.setState({ studentAttendanceSummaryList: [] });
        if (this.submitFormHandleError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            this.saSummaryService.getStudentAttendanceSummaryInfo(this.state.attendanceDate, this.state.academicYear, this.state.attendancePeriod)
                .then(data => {
                    if (data.item) {
                        this.setState({ studentAttendanceSummaryList: data.item });
                        console.log('Log: StudentAttendanceSummary -> onSubmitHandler -> data.item ', data.item)
                        this.setState({ topProgressBar: false, dataTableIsLoading: false })
                    }
                    else {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No data Found' })
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load datatable. Please check connection' })
                });
        }
    }

    submitFormHandleError = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.academicYear === null) {
            formIsValid = false;
            errors["academicYear"] = "Academic Year can't left empty.";
        }
        if (this.state.attendanceDate === null) {
            formIsValid = false;
            errors["date"] = "Attendance Date can't left empty.";
        }
        if (this.state.attendancePeriod === null) {
            formIsValid = false;
            errors["period"] = "Period can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onChangeAcademicYear = (e) => {
        let { errors, academicYear } = this.state;
        errors["academicYear"] = "";
        academicYear = e.target.value;
        this.setState({ academicYear, errors });
    }

    onChangeAttendanceDate = (e) => {
        let { errors, attendanceDate } = this.state;
        errors["date"] = "";
        attendanceDate = NetiDateUtils.getDateFromStringDDMMYYYY(e.target.value);
        this.setState({ attendanceDate, errors });
    }

    onChangeAttendancePeriod = (e) => {
        let { errors, attendancePeriod } = this.state;
        errors["period"] = "";
        attendancePeriod = e.target.value;
        this.setState({ attendancePeriod, errors });
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, studentAttendanceSummaryList } = this.state;

        let headervalue = (
            <div className="p-clearfix">
                Student Attendance Summary{" "}
                <span style={{ float: "right" }}>
                    {" "}
                    Total Found: {studentAttendanceSummaryList.length}{" "}
                </span>
            </div>
        );

        let attendancePeriodDefaultIds = []
        attendancePeriodDefaultIds = this.state.attendancePeriods.map((item) => ({
            value: item.defaultId,
            label: item.name,
        }));

        let academicYearList = []
        academicYearList = this.state.academicYears.map((item) => ({
            value: item.name,
            label: item.name,
        }));


        let requiredSing = <span style={{ color: 'red', marginRight: '2px' }}>*</span>
        return (
            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-lg-12 p-xl-6">
                                {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Academic Year {requiredSing}</span>
                                        <Dropdown
                                            options={academicYearList}
                                            name='academicYear'
                                            placeholder="Select Academic Year"
                                            value={this.state.academicYear}
                                            onChange={this.onChangeAcademicYear}
                                            autoWidth={false}
                                        />
                                    </div>
                                }
                                <span className="error-message">{this.state.errors["academicYear"]}</span>

                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-6">

                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Date {requiredSing}</span>
                                    <Calendar value={this.state.attendanceDate}
                                        onChange={this.onChangeAttendanceDate}
                                        showIcon={true}
                                        name="date"
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                        showButtonBar={true} 
                                    />
                                </div>
                                <span className="error-message">{this.state.errors["date"]}</span>
                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-6">
                                {this.state.secondDropDownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Period {requiredSing}</span>
                                        <Dropdown
                                            options={attendancePeriodDefaultIds}
                                            placeholder="Select Period"
                                            value={this.state.attendancePeriod}
                                            onChange={this.onChangeAttendancePeriod}
                                            autoWidth={false}
                                            name="period"
                                        />
                                    </div>
                                }
                                <span className="error-message">{this.state.errors["period"]}</span>
                            </div>

                            <div className="p-col-12 p-lg-12 p-xl-6 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitHandler}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader() :
                                <DataTable
                                    header={headervalue}
                                    value={studentAttendanceSummaryList}
                                    responsive={true}
                                    paginator={true}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    rows={5}
                                >
                                    <Column field="instituteId" header="Institute ID" sortable={true} filter={true} />
                                    <Column field="instituteName" header="Institute Name" sortable={true} filter={true} />
                                    <Column field="totalStudent" header="Total Student" sortable={true} filter={true} />
                                    <Column field="totalTakenAttendance" header="Attendance Taken" sortable={true} filter={true} />
                                    <Column field="totalPresent" header="Total Present" sortable={true} filter={true} />
                                    <Column field="totalAbsent" header="Total Absent" sortable={true} filter={true} />
                                    <Column field="totalLeave" header="On Leave" sortable={true} filter={true} />
                                </DataTable>
                            }
                        </div>
                    </div>


                </div>

            </div>
        );
    }
}