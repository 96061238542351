import React, { Component } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { Growl } from 'primereact/growl';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { Link } from 'react-router-dom';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { CUSTOM_MESSAGE } from '../../common/CustomMessage';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';

let errors = {};
let urlId;
export class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proPic: {
                extention: '',
                contentPic: '',
                contentName: ''
            },
            details: '',
            topProgressBar: false,
            updateVisible: false,
            addVisible: false,
            value: '',
            headerType: '',
            target: [],
            message: '',
            updateId: 0,
            updateImg: '',
            aboutUsObj: {
                "aboutusId": 0,
                "aboutusType": "",
                "aboutusDetails": "",
                "aboutusImageName": "",
                "imageContent": "",
                "imageSaveOrEditable": false,
                "coreUrlInfoDTO": {
                    "urlInfoID": ''
                }
            },
            aboutUsListWithDesc: [
                { "aboutusType": "", "aboutusDetails": "" },
                { "aboutusType": "", "aboutusDetails": "" },
                { "aboutusType": "", "aboutusDetails": "" },
                { "aboutusType": "", "aboutusDetails": "" },
                { "aboutusType": "", "aboutusDetails": "" },
                { "aboutusType": "", "aboutusDetails": "" }
            ],
            errors: {},
            dataTableIsLoading: false
        }

        this.netiFileHandler = new NetiFileHandler();
        this.DwsService = new DwsService();

        this.onChangeInputValue = this.onChangeInputValue.bind(this);
        this.onBasicUploadAuto = this.onBasicUploadAuto.bind(this);
        this.reloadCoreUrlIdFromLocal();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            let { coreUrlInfoDTO } = this.state.aboutUsObj
            coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ coreUrlInfoDTO })
            this.setState({ homeReturnButton: false })
            this.getAboutUsDetailsList(urlId);
        }
        else {
            this.setState({ homeReturnButton: true })
        }

    }

    getAboutUsDetailsList(defaultCode) {
        this.setState({ dataTableIsLoading: true, topProgressBar: true });

        this.DwsService.fetchAboutUsList(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ aboutUsListWithDesc: body });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false });
                    });
                } else if (res.status == 404) {
                    return res.json().then((body) => {

                    });
                }
            }).catch(error => {
                errors["dropDownClassError"] = 'Connection Problem'
                this.setState({ errors: errors, dataTableIsLoading: false })
            });
    }

    onChangeInputValue = (e) => {
        let { errors } = this.state
        errors["details"] = ""
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onBasicUploadAuto(e) {
        let { errors } = this.state
        errors["image"] = ""
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ proPic: album });
            scope.setState({ updateImg: album.contentPic })

        }
    }


    handleError() {

        let formIsValid = true;

        if (this.state.details === '') {
            formIsValid = false;
            errors["details"] = "Details can't left empty.";
        }
        if (this.state.proPic.contentName === '') {
            formIsValid = false;
            errors["image"] = "Please Upload image.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleUpdateError() {

        let formIsValid = true;

        if (this.state.messageUpdate === '') {
            formIsValid = false;
            errors["updateDetails"] = "Details can't left empty.";
        }
        if (this.state.updateImg === '') {
            formIsValid = false;
            errors["updateImage"] = "Image can't left empty.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    infoOnSubmitHandler = () => {


        if (this.handleError()) {
            this.setState({ errors: errors, dataTableIsLoading: true, topProgressBar: true })

            let { aboutUsObj } = this.state;
            let { genderCategoryInfoDTO } = this.state.aboutUsObj

            aboutUsObj.imageContent = this.state.proPic.contentPic;
            aboutUsObj.imageSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            // aboutUsObj.aboutusImageName = 'about_us_' + urlId + '_' + Date.now() + extention;
            aboutUsObj.aboutusImageName = this.state.proPic.contentName;

            aboutUsObj.aboutusType = this.state.headerType;
            aboutUsObj.aboutusDetails = this.state.details;

            this.setState({ aboutUsObj });
            this.DwsService.createNewAboutUsInfo(aboutUsObj)
                .then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: CUSTOM_MESSAGE.SUCCESS_MSG_SHORT, detail: CUSTOM_MESSAGE.SUCCESS_MSG_LONG_SAVE, life: 800 });
                        this.setState({
                            dataTableIsLoading: false,
                            topProgressBar: false,
                            proPic: {
                                extention: '',
                                contentPic: '',
                                contentName: ''
                            },
                            details: '',

                        })
                        setTimeout(() => {
                            this.onHideAdd();
                        }, 800);
                        this.getAboutUsDetailsList(urlId);
                    }
                    else {
                        return res.json().then((body) => {
                            this.setState({ dataTableIsLoading: false })
                            this.growl.show({ severity: 'warn', summary: 'Warning', detail: body.message });
                        })
                    }


                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' });
                });

        }


    }

    aboutUsOnUpdate = () => {

        if (this.handleUpdateError()) {
            this.setState({ errors: errors, dataTableIsLoading: true, topProgressBar: true })

            let { aboutUsObj } = this.state;
            let { genderCategoryInfoDTO } = this.state.aboutUsObj

            aboutUsObj.imageContent = this.state.proPic.contentPic || this.state.updateImg;
            aboutUsObj.imageSaveOrEditable = true;
            let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
            // aboutUsObj.aboutusImageName = 'about_us_' + urlId + '_' + Date.now() + extention;
            aboutUsObj.aboutusImageName = this.state.proPic.contentName;

            aboutUsObj.aboutusId = this.state.updateId;
            aboutUsObj.aboutusType = this.state.headerType;
            aboutUsObj.aboutusDetails = this.state.messageUpdate;
            aboutUsObj.aboutusImagePath = this.state.aboutusImagePath;


            this.DwsService.updateAboutUsInfo(aboutUsObj)
                .then(res => {
                    if (res.status == 202) {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false })
                        this.growl.show({ severity: 'success', summary: CUSTOM_MESSAGE.SUCCESS_MSG_SHORT, detail: CUSTOM_MESSAGE.SUCCESS_MSG_LONG_UPDATE });
                        this.getAboutUsDetailsList(urlId);
                        this.onHideUpdate();
                        this.setState({ updateImg: '', proPic: {} });
                    }
                    else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                                this.setState({ dataTableIsLoading: false})
                                // this.growl.show({ severity: 'warn', summary: 'Warning', detail: resp });
                            });
                    }


                }).catch(error => {
                    this.setState({ errors: errors, dataTableIsLoading: false, topProgressBar: false })
                    this.growl.show({ severity: 'warn', summary: 'Warning', detail: 'Connection Problem' });
                });
        }

        
       

    }


    viewDialogUpdate = (e) => {
        this.CommonFuctionality.blurDialogBackgroundActive()
        this.setState({ updateVisible: true });
    }

    onHideUpdate = (event) => {
        this.CommonFuctionality.blurDialogBackgroundDeActive()
        this.setState({ updateVisible: false });
        
    }

    viewDialogAdd = (e) => {
        this.CommonFuctionality.blurDialogBackgroundActive()
        this.setState({ details:'', addVisible: true, proPic:{ 
            extention: '',
            contentPic: '',
            contentName: '' 
        } });
    }

    onHideAdd = (event) => {
        this.CommonFuctionality.blurDialogBackgroundDeActive()
        this.setState({ addVisible: false });
    }


    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, } = this.state;

        let uploadFileLabel = <div>Click / Drag Image Here To Upload <br /><span className="upload-file-label">(370 x 240 px (recommended)</span></div>

        let { aboutUsListWithDesc } = this.state;
        let data = [
            { aboutusType: "History" },
            { aboutusType: "Infrastructure" },
            { aboutusType: "Library" },
            { aboutusType: "Laboratory" },
            { aboutusType: "Computer Lab" },
            { aboutusType: "Playground" },
        ];

        let bodyMessageHistory, bodyMessageInfrastructure, bodyMessageLibrary,
            bodyMessageLaboratory, bodyMessageComputerLab, bodyMessagePlayground;

        let historyId, infrastructureId, libraryId,
            laboratoryId, computerLabId, playgroundId;

        let historyImg, infrastructureImg, libraryImg,
            laboratoryImg, computerLabImg, playgroundImg;

        let historyImagePath, infrastructureImagePath, libraryImagePath, laboratoryImagePath, computerLabImagePath,playgroundImagePath

        for (var i = 0; i < aboutUsListWithDesc.length; i++) {

            if (aboutUsListWithDesc[i].aboutusType === "History") {
                bodyMessageHistory = aboutUsListWithDesc[i].aboutusDetails;
                historyImg = aboutUsListWithDesc[i].imageContent
                historyId = aboutUsListWithDesc[i].aboutusId;
                historyImagePath = aboutUsListWithDesc[i].aboutusImagePath;
            }
            else if (aboutUsListWithDesc[i].aboutusType === "Infrastructure") {
                bodyMessageInfrastructure = aboutUsListWithDesc[i].aboutusDetails;
                infrastructureImg = aboutUsListWithDesc[i].imageContent
                infrastructureId = aboutUsListWithDesc[i].aboutusId;
                infrastructureImagePath = aboutUsListWithDesc[i].aboutusImagePath;
            }
            else if (aboutUsListWithDesc[i].aboutusType === "Library") {
                bodyMessageLibrary = aboutUsListWithDesc[i].aboutusDetails;
                libraryImg = aboutUsListWithDesc[i].imageContent
                libraryId = aboutUsListWithDesc[i].aboutusId;
                libraryImagePath = aboutUsListWithDesc[i].aboutusImagePath;

            }
            else if (aboutUsListWithDesc[i].aboutusType === "Laboratory") {
                bodyMessageLaboratory = aboutUsListWithDesc[i].aboutusDetails;
                laboratoryImg = aboutUsListWithDesc[i].imageContent
                laboratoryId = aboutUsListWithDesc[i].aboutusId;
                laboratoryImagePath = aboutUsListWithDesc[i].aboutusImagePath;

            }
            else if (aboutUsListWithDesc[i].aboutusType === "Computer Lab") {
                bodyMessageComputerLab = aboutUsListWithDesc[i].aboutusDetails;
                computerLabImg = aboutUsListWithDesc[i].imageContent
                computerLabId = aboutUsListWithDesc[i].aboutusId;
                computerLabImagePath = aboutUsListWithDesc[i].aboutusImagePath;

            }
            else if (aboutUsListWithDesc[i].aboutusType === "Playground") {
                bodyMessagePlayground = aboutUsListWithDesc[i].aboutusDetails;
                playgroundImg = aboutUsListWithDesc[i].imageContent
                playgroundId = aboutUsListWithDesc[i].aboutusId;
                playgroundImagePath = aboutUsListWithDesc[i].aboutusImagePath;

            }

        }

        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                <Growl ref={(el) => this.growl = el} />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="main-section blur-section">
                    {this.state.homeReturnButton === false ?
                        <div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-4" >
                                    <Fieldset legend={data[0].aboutusType} className="fieldset-box-parent">

                                        {!bodyMessageHistory ?
                                            <span>
                                                <Button icon="fas fa-plus-circle"
                                                    onClick={(e) => { this.viewDialogAdd(); this.setState({ headerType: 'History' }) }}
                                                    style={{ fontSize: "2rem", marginTop: "20%", marginLeft: "40%" }}
                                                />
                                            </span>
                                            :
                                            <div>
                                                <div className="p-col-12 p-xl-2" style={{ border: "1ps solid red" }}>
                                                    <img src={`data:image/png;base64, ${historyImg}`} width="80px" />
                                                </div>
                                                <br />
                                                <h2>Description:</h2>
                                                <span className="fieldset-box-content">
                                                    <Button
                                                        icon="fas fa-external-link-alt"
                                                        className="nw-action-button"
                                                        onClick={(e) => { this.viewDialogUpdate(); this.setState({ updateId: historyId, updateImg: historyImg, headerType: 'History', messageUpdate: bodyMessageHistory, aboutusImagePath: historyImagePath }) }}
                                                    />
                                                </span>
                                                <p className="padding-top-medium">
                                                    {bodyMessageHistory}
                                                </p>
                                            </div>
                                        }
                                    </Fieldset>
                                </div>
                                <div className="p-col-12 p-xl-4" >
                                    <Fieldset legend={data[1].aboutusType} className="fieldset-box-parent">

                                        {!bodyMessageInfrastructure ?
                                            <span>
                                                <Button icon="fas fa-plus-circle"
                                                    onClick={(e) => { this.viewDialogAdd(); this.setState({ headerType: 'Infrastructure' }) }}
                                                    style={{ fontSize: "2rem", marginTop: "20%", marginLeft: "40%" }}
                                                />
                                            </span>
                                            :
                                            <div>
                                                <div className="p-col-12 p-xl-2" style={{ border: "1ps solid red" }}>
                                                    <img src={`data:image/png;base64, ${infrastructureImg}`} width="80px" />
                                                </div>
                                                <br />
                                                <h2>Description:</h2>
                                                <span className="fieldset-box-content">
                                                    <Button
                                                        icon="fas fa-external-link-alt"
                                                        onClick={(e) => { this.viewDialogUpdate(); this.setState({ updateId: infrastructureId, updateImg: infrastructureImg, headerType: 'Infrastructure', messageUpdate: bodyMessageInfrastructure, aboutusImagePath: infrastructureImagePath }) }}
                                                    />
                                                </span>
                                                <p className="padding-top-medium">
                                                    {bodyMessageInfrastructure}
                                                </p>
                                            </div>
                                        }
                                    </Fieldset>
                                </div>
                                <div className="p-col-12 p-xl-4" >
                                    <Fieldset legend={data[2].aboutusType} className="fieldset-box-parent">

                                        {!bodyMessageLibrary ?
                                            <span>
                                                <Button icon="fas fa-plus-circle"
                                                    onClick={(e) => { this.viewDialogAdd(); this.setState({ headerType: 'Library' }) }}
                                                    style={{ fontSize: "2rem", marginTop: "20%", marginLeft: "40%" }}
                                                />
                                            </span>
                                            :
                                            <div>
                                                <div className="p-col-12 p-xl-2" style={{ border: "1ps solid red" }}>
                                                    <img src={`data:image/png;base64, ${libraryImg}`} width="80px" />
                                                </div>
                                                <br />
                                                <h2>Description:</h2>
                                                <span className="fieldset-box-content">
                                                    <Button
                                                        icon="fas fa-external-link-alt"
                                                        onClick={(e) => { this.viewDialogUpdate(); this.setState({ updateId: libraryId, updateImg: libraryImg, headerType: 'Library', messageUpdate: bodyMessageLibrary, aboutusImagePath: libraryImagePath }) }}
                                                    />
                                                </span>
                                                <p className="padding-top-medium">
                                                    {bodyMessageLibrary}
                                                </p>
                                            </div>
                                        }
                                    </Fieldset>
                                </div>
                                <div className="p-col-12 p-xl-4" >
                                    <Fieldset legend={data[3].aboutusType} className="fieldset-box-parent">

                                        {!bodyMessageLaboratory ?
                                            <span>
                                                <Button icon="fas fa-plus-circle"
                                                    onClick={(e) => { this.viewDialogAdd(); this.setState({ headerType: 'Laboratory' }) }}
                                                    style={{ fontSize: "2rem", marginTop: "20%", marginLeft: "40%" }}
                                                />
                                            </span>
                                            :
                                            <div>
                                                <div className="p-col-12 p-xl-2" style={{ border: "1ps solid red" }}>
                                                    <img src={`data:image/png;base64, ${laboratoryImg}`} width="80px" />
                                                </div>
                                                <br />
                                                <h2>Description:</h2>
                                                <span className="fieldset-box-content">
                                                    <Button
                                                        icon="fas fa-external-link-alt"
                                                        onClick={(e) => { this.viewDialogUpdate(); this.setState({ updateId: laboratoryId, updateImg: laboratoryImg, headerType: 'Laboratory', messageUpdate: bodyMessageLaboratory, aboutusImagePath: laboratoryImagePath }) }}
                                                    />
                                                </span>
                                                <p className="padding-top-medium">
                                                    {bodyMessageLaboratory}
                                                </p>
                                            </div>
                                        }
                                    </Fieldset>
                                </div>
                                <div className="p-col-12 p-xl-4" >
                                    <Fieldset legend={data[4].aboutusType} className="fieldset-box-parent">

                                        {!bodyMessageComputerLab ?
                                            <span>
                                                <Button icon="fas fa-plus-circle"
                                                    onClick={(e) => { this.viewDialogAdd(); this.setState({ headerType: 'Computer Lab' }) }}
                                                    style={{ fontSize: "2rem", marginTop: "20%", marginLeft: "40%" }}
                                                />
                                            </span>
                                            :
                                            <div>
                                                <div className="p-col-12 p-xl-2" style={{ border: "1ps solid red" }}>
                                                    <img src={`data:image/png;base64, ${computerLabImg}`} width="80px" />
                                                </div>
                                                <br />
                                                <h2>Description:</h2>
                                                <span className="fieldset-box-content">
                                                    <Button
                                                        icon="fas fa-external-link-alt"
                                                        onClick={(e) => { this.viewDialogUpdate(); this.setState({ updateId: computerLabId, updateImg: computerLabImg, headerType: 'Computer Lab', messageUpdate: bodyMessageComputerLab, aboutusImagePath: computerLabImagePath }) }}
                                                    /></span>
                                                <p className="padding-top-medium">
                                                    {bodyMessageComputerLab}
                                                </p>
                                            </div>
                                        }
                                    </Fieldset>
                                </div>

                                <div className="p-col-12 p-xl-4" >
                                    <Fieldset legend={data[5].aboutusType} className="fieldset-box-parent">

                                        {!bodyMessagePlayground ?
                                            <span>
                                                <Button icon="fas fa-plus-circle"
                                                    onClick={(e) => { this.viewDialogAdd(); this.setState({ headerType: 'Playground' }) }}
                                                    style={{ fontSize: "2rem", marginTop: "20%", marginLeft: "40%" }}
                                                />
                                            </span>
                                            :
                                            <div>
                                                <div className="p-col-12 p-xl-2" style={{ border: "1ps solid red" }}>
                                                    <img src={`data:image/png;base64, ${playgroundImg}`} width="80px" />
                                                </div>
                                                <br />
                                                <h2>Description:</h2>
                                                <span className="fieldset-box-content">
                                                    <Button icon="fas fa-external-link-alt"
                                                        onClick={(e) => { this.viewDialogUpdate(); this.setState({ updateId: playgroundId, updateImg: playgroundImg, headerType: 'Playground', messageUpdate: bodyMessagePlayground, aboutusImagePath: playgroundImagePath }) }}
                                                    />
                                                </span>
                                                <div>
                                                    <p className="padding-top-medium">
                                                        {bodyMessagePlayground}
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </Fieldset>
                                </div>

                            </div>

                            
                        </div>
                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        maximizable
                        header={"Update " + this.state.headerType + " Information"}
                        visible={this.state.updateVisible}
                        // modal={true}
                        onHide={this.onHideUpdate}
                        closable
                    >
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form-body">

                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                            <InputTextarea
                                                className=""
                                                id="details"
                                                name="messageUpdate"
                                                rows={5}
                                                cols={30}
                                                placeholder="Write photo details"
                                                onChange={this.onChangeInputValue}
                                                value={this.state.messageUpdate}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors['updateDetails']}</span>
                                    </div>

                                    {/* <div className="formControl">
                                        <label htmlFor="detail">Details</label>
                                        <InputTextarea
                                            style={{ minHeight: "60px" }}
                                            placeholder="Enter details"
                                            rows={3}
                                            cols={30}
                                            autoResize={true}
                                            id="detail"
                                            name="messageUpdate"
                                            onChange={this.onChangeInputValue}
                                            value={this.state.messageUpdate}
                                        />
                                        <span className="error-message"></span>
                                    </div> */}

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload Image <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/JPEG/GIF)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                {
                                                    this.state.updateImg ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={e => this.setState({ proPic: { contentPic: ''}, updateImg:''  })}
                                                                />

                                                                {
                                                                    this.state.updateImg ? 
                                                                        <img src={`data:image/png;base64, ${this.state.updateImg}`} style={{ "height": "80px" }} /> : ''
                                                                }
                                                                {/* <img
                                                                    src={
                                                                        this.state.proPic.contentPic === '' ?
                                                                            'assets/images/demo.jpg'
                                                                            :
                                                                            "data:image/png;base64," + this.state.proPic.contentPic
                                                                    }
                                                                    style={{ "height": "80px" }}
                                                                /> */}


                                                            </div>
                                                            <div className="image-title">{this.state.proPic.contentName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            chooseLabel={this.state.proPic.contentName || uploadFileLabel}
                                                            mode="basic"
                                                            accept="image/*"
                                                            maxFileSize={1000000}
                                                            onSelect={this.onBasicUploadAuto}
                                                            auto={true}
                                                        />
                                                }

                                            </div>


                                            {/* <center>
                                                {this.state.uploadImage.contentName}
                                            </center> */}
                                        </div>
                                        <span className='error-message'>{this.state.proPic.contentName ? null : errors["updateImage"]}</span>
                                    </div>
                                    {/* <div className="p-col-12 p-xl-12 nw-upload-button">
                                        <label htmlFor="attachments">Upload Photo</label>
                                        <center>{this.state.updateImg ? <img src={`data:image/png;base64, ${this.state.updateImg}`} width="120px" /> : ''}</center>
                                        <FileUpload
                                            id="attachments"
                                            mode="basic"
                                            accept="image/*"
                                            maxFileSize={1000000}
                                            auto={true}
                                            onSelect={this.onBasicUploadAuto}
                                        />
                                        <center>
                                            {this.state.proPic.contentName}
                                        </center>
                                    </div> */}
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={this.onHideUpdate}
                                        />
                                        
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Update"
                                            icon="fas fa-check"
                                            onClick={this.aboutUsOnUpdate.bind(this)}
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </Dialog>

                    <Dialog
                        className="nw-dialog"
                        maximizable
                        header={"Add " + this.state.headerType + " Information"}
                        visible={this.state.addVisible}
                        modal={true}
                        onHide={this.onHideAdd}
                        closable
                    >
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                    <div className="p-col-12 p-md-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                            <InputTextarea
                                                // style={{ minHeight: "60px" }}
                                                placeholder="Enter details"
                                                // rows={3}
                                                // cols={30}
                                                // autoResize={true}
                                                id="detail"
                                                name="details"
                                                onChange={this.onChangeInputValue}
                                                value={this.state.details}
                                            />
                                        </div>
                                        <span className='error-message'>{this.state.errors['details']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload Image <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/JPEG/GIF)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                {
                                                    this.state.proPic.contentPic ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={e => this.setState({ proPic: { contentPic: '' } })}
                                                                />
                                                                <img
                                                                    src={
                                                                        this.state.proPic.contentPic === '' ?
                                                                            'assets/images/demo.jpg'
                                                                            :
                                                                            "data:image/png;base64," + this.state.proPic.contentPic
                                                                    }
                                                                    style={{ "height": "80px" }}
                                                                />


                                                            </div>
                                                            <div className="image-title">{this.state.proPic.contentName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            chooseLabel={this.state.proPic.contentName || uploadFileLabel}
                                                            mode="basic"
                                                            accept="image/*"
                                                            maxFileSize={1000000}
                                                            onSelect={this.onBasicUploadAuto.bind(this)}
                                                            auto={true}
                                                        />
                                                }

                                            </div>


                                            {/* <center>
                                                {this.state.uploadImage.contentName}
                                            </center> */}
                                        </div>
                                        <span className="error-message">{!this.state.proPic.contentName && this.state.errors["image"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={this.onHideAdd}
                                        />

                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Save"
                                            icon="fas fa-check"
                                            onClick={this.infoOnSubmitHandler.bind(this)}
                                        />
                                    </div>
                                </div>



                                {/* <div className="card card-w-title"> */}
                                    {/* <div className="formControl">
                                        <label htmlFor="detail">Details</label>
                                        <InputTextarea
                                            // style={{ minHeight: "60px" }}
                                            placeholder="Enter details"
                                            // rows={3}
                                            // cols={30}
                                            // autoResize={true}
                                            id="detail"
                                            name="details"
                                            onChange={this.onChangeInputValue}
                                            value={this.state.details}
                                        />
                                        <span className="error-message">{this.state.errors['details']}</span>
                                    </div> */}

                                    {/* <div className="formControl nw-upload-button">
                                        <center><label htmlFor="attachments">Upload Photo</label></center>
                                        <FileUpload
                                            className="p-button-raised p-button-secondary"
                                            mode="basic"
                                            accept="image/*"
                                            maxFileSize={1000000}
                                            onSelect={this.onBasicUploadAuto}
                                            auto={true}
                                            chooseLabel="Choose"
                                            autoWidth={false}
                                        />

                                    </div> */}
                                    {/* <div className="formControl">
                                        <center>
                                            <span className="error-message">{!this.state.proPic.contentName && this.state.errors["image"]}</span>
                                            <div>{this.state.proPic.contentName}</div>
                                        </center>
                                    </div> */}

                                    {/* <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-xl-12 nw-button-parent">
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-center"
                                                    label="Save"
                                                    onClick={this.infoOnSubmitHandler.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div> */}

                                {/* </div> */}
                            </div>
                            <div className="p-md-2"> </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}