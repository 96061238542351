
export default class ActionLogReport {
    // Initializing important variables
    constructor(domain) {
        this.actionLogCount = {
            clickCount: 0,
            pageAccessCount: 0,
            pointAccessCount: 0,
        }

        // localStorage.setItem('actionLogCount', JSON.stringify(this.actionLogCount));
    }

    actionCount(clickCount, pageAccessCount, pointAccessCount){
        
        if( clickCount == 1){
            this.actionLogCount.clickCount++;
            localStorage.setItem('clickCount', JSON.stringify(this.actionLogCount.clickCount));
        }

        if( pageAccessCount == 1){
            // console.log("pageAccessCountSet 2nd :::::::::::::::::::::::::::", pageAccessCount);
            const pageCount = JSON.parse(localStorage.getItem('pageAccessCount'));

            if( pageCount == 1 ){
                this.actionLogCount.pageAccessCount += pageCount;
            }
            else{
                this.actionLogCount.pageAccessCount++;
            }
            
            localStorage.setItem('pageAccessCount', JSON.stringify(this.actionLogCount.pageAccessCount));
        }

        
        

        // localStorage.setItem('actionLogCount', JSON.stringify(this.actionLogCount));
        // return actionLogCount;
    }

    clickCountSet(click) {
        // this.actionLogCount.clickCount++;
        this.actionCount( click, this.actionLogCount.pageAccessCount, this.actionLogCount.pointAccessCount )

        // localStorage.setItem('actionLogCount', JSON.stringify(this.actionLogCount));
    }

    pageAccessCountSet(click) {
        console.log("pageAccessCountSet 1st :::::::::::::::::::::::::::", click);
        
        // this.actionLogCount.pageAccessCount++;
        this.actionCount( this.actionLogCount.clickCount, click, this.actionLogCount.pointAccessCount )

        // localStorage.setItem('actionLogCount', JSON.stringify(this.actionLogCount));
    }

    pointAccessCountSet(click) {
        this.actionLogCount.pointAccessCount++;

        // localStorage.setItem('actionLogCount', JSON.stringify(this.actionLogCount));
    }

    clickCountGet() {
        return this.actionLogCount.clickCount;
    }
}
// export const countObject = () =>{
//     let actionLogCount={
//         clickCount: 0,
//     }
// }

// clickCountSet = (click) => {
//     countObject.a
// }

// clickCountGet = () => {
//     return this.actionLogCount.clickCount;
// }