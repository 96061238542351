import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import NetiContentLoader from '../common/NetiContentLoader';

let cloneStateBeforeMount;
let eligibilityStatus;
let netiWorldValue;
let errors = {}
export class ManageTokenCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitTokenError: {},
            tokenInfoObj: {
                instituteId: '',
                instituteName: '',
                instituteAddress: '',
                netiID: '',
                customNetiID: '',
                fullName: '',
                assignedUserBasicInfoDTO: {
                    fullName: ''
                }
            },
            tokenInfoObjBasicInfo: {
                fullName: ''
            },
            netiRequirementSubmitObj: {
                "tokenSource": "-",
                "tokenDetails": "-",
                "tokenContact": "-",
                "tokenStatus": 0,
                "requirementStatus": 1,
                "priorityStatus": 0,
                "tokenTypeInfoDTO": {
                    "coreCategoryID": "-"
                },
                "creatorBasicInfoDTO": {
                    "netiID": 0
                },
                "solverBasicInfoDTO": {
                    "netiID": 0
                }
            },
            edumanSubmitObj: {
                "tokenSource": "",
                "tokenDetails": "",
                "tokenContact": "",
                "requirementStatus": 1,
                "tokenTypeInfoDTO": {
                    "coreCategoryID": 0
                },
                "edumanDetailsInfoDTO": {
                    "emDetailsId": 0
                },
                "solverBasicInfoDTO": {
                    "netiID": 0
                }
            },
            // problemSubmitObj:{
            //     "tokenSource": "-",
            //     "tokenDetails": "-",
            //     "tokenContact": "-",
            //     "tokenStatus": 0,
            //     "requirementStatus": 1,
            //     "tokenTypeInfoDTO": {
            //         "coreCategoryID": "-"
            //     },
            //     "creatorBasicInfoDTO":{
            //         "netiID": 0
            //     }
            // },
            pointTypeValue: '',
            emptyTokenInfoObj: false,
            callerContactNo: '',
            deviceInstituteIdORnetiId: '',
            showTokenField: false,
            layout: 'list',
            regNo: '',
            moduleType: '',
            checked: false,
            tokenType: '',
            problemDetails: '',
            hasSolved: false,
            errors: {},
            userListValue: '',
            moduleValue: '',
            priorityValue: '',
            eligibilityStatus: '',
            applicantFormVisibility: true,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dropdownIsLoading: false,
            inputFieldIsLoading: false,
            secondDropdownIsLoading: false,
            thirdDropdownIsLoading: false,
        };

        this.supportPointService = new SupportPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.netiFileHandler = new NetiFileHandler();
        this.netiContentLoader = new NetiContentLoader();
    }

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
        console.log("cloneStateBeforeMount",cloneStateBeforeMount)

        this.onSearchHandler()
      }

    solvedDataTemplate = (solvedTokenData) => {
        if (!solvedTokenData) {
            return null;
        }
        else {
            return this.renderSolvedList(solvedTokenData);
        }
    }

    onChangeRegNo = (e) => {
        this.setState({ regNo: e.target.value })
    }

    onChangeCallerContactNo = (e) => {
        this.setState({ callerContactNo: e.target.value })
    }

    onChangePointTypeValue = (e) => {
        
        this.setState({
            pointTypeValue: e.value.categoryName,
            problemModuleCategoryDefaultCode: e.value.coreCategoryID
        });

        this.getProblemModuleList(e.value.coreCategoryID)
    }

    onChangedeviceInstituteIdORnetiId = (e) => {
        this.setState({ deviceInstituteIdORnetiId: e.target.value });
    }

    onSearchHandler = () => {
        this.setState({ topProgressBar: true, showTokenField: true, errorMsgVisible: false, dropdownIsLoading: true });

        // this.setState({ com: true });
        this.supportPointService.fetchTokenApplicationDropDown("T11001", 0)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ topProgressBar: false, dropdownIsLoading: false, tokenInfoDropDown: body });
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, dropdownIsLoading: false, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, dropdownIsLoading: false, errorMsgBody: 'Please check your connection.' })
            });

        this.getUserByRole();

    }

    onSearchInfoEdumanAndNetiWorld = () => {

        if (this.handleError()) {
            this.setState({ topProgressBar: true, showTokenField: true, tokenInfoObj: '' });
            this.setState({ errorMsgVisible: false })
            this.supportPointService.fetchTokenInfoByContactNoAndApplicationType(this.state.callerContactNo, this.state.pointTypeValue)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 302) {

                        this.setState({ topProgressBar: false, emptyTokenInfoObj: false });

                        return res.json().then((body) => {
                            if (this.state.pointTypeValue === 'Netiworld') {
                                this.setState({ tokenInfoObj: body[0] });
                            }
                            else if (this.state.pointTypeValue === 'Eduman') {
                                this.setState({ tokenInfoObj: body });
                            }
                        });
                    } else {
                        this.setState({ emptyTokenInfoObj: true });
                        this.supportPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
                });
        }
           
        
    }

    onSearchdeviceInstituteIdORnetiId = () => {
        console.log("this.state.pointTypeValue",this.state.pointTypeValue)
        console.log("     this.state.deviceInstituteIdORnetiId",     this.state.deviceInstituteIdORnetiId)

   
        
        if (this.state.pointTypeValue === "NetiWorld") {
            this.setState({ errorMsgVisible: false ,topProgressBar: true })

          this.supportPointService.fetchTokenInfoByCustomID(this.state.deviceInstituteIdORnetiId).then(res => {
                console.log("res",res);
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("netiworld id search body",body);
                        this.setState({ tokenInfoObj: body });
                        this.setState({ topProgressBar: false, errorMsgVisible: false })
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
            });
    
        }
        else if (this.state.pointTypeValue === "Eduman") {
            this.setState({ errorMsgVisible: false ,topProgressBar: true })

          this.supportPointService.fetchTokenInfoByDeviceInstituteID(this.state.deviceInstituteIdORnetiId).then(res => {
                console.log("res",res);
                if (res.status == 302) {
                    return res.json().then((body) => {
                            this.setState({ tokenInfoObjBasicInfo: body.assignedUserBasicInfoDTO });
                            this.setState({ topProgressBar: false, errorMsgVisible: false })
                    });
                } else {
                    this.supportPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
            });
    
        }

    }

    getProblemModuleList = (defaultCode) => {
        this.setState({ topProgressBar: true, inputFieldIsLoading: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.supportPointService.getUserTokenByDefaultCategory(defaultCode)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ problemModule: body });
                        this.setState({ topProgressBar: false, inputFieldIsLoading: false, secondDropdownIsLoading: false, errorMsgVisible: false })
                    });
                } else {
                    // return res.json().then((errorRes) => {
                    //     this.setState({ topProgressBar: false, inputFieldIsLoading: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: errorRes })

                    // });
                    this.supportPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, inputFieldIsLoading: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, inputFieldIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
            });

    }

    getProblemTypeList = (e) => {
        this.setState({ topProgressBar: true, thirdDropdownIsLoading: true, errorMsgVisible: false })

        this.setState({ problemType: null });
        this.setState({ moduleValue: e.value });
        this.supportPointService.getUserTokenByDefaultCategory(e.value)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ problemType: body });
                        this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: false })
                    });
                } else {
                    return res.json().then((Errbody) => {
                        this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: Errbody })
                    });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, thirdDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
            });

    }

    getUserByRole = () => {
        this.supportPointService.getUserListByRole()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ userListBody: body });
                    });
                } else {
                    return res.json().then((ErrorMsg) => {
                        console.log(ErrorMsg);
                    });
                }
            }).catch(error => {
                this.growl.show({ severity: 'warn', summary: 'Warning', detail: 'Connection Problem' });
            });

    }

    closeToken = () => {
        this.setState({ tokenInfoObj: cloneStateBeforeMount.tokenInfoObj,deviceInstituteIdORnetiId:'', showTokenField: false,callerContactNo:'',pointTypeValue:'',tokenType:'',moduleType:'',problemDetails:'',checked:false,userListValue:'' });
    }

    onChangeTokenType = (e) => {
        let { tokenType } = this.state;
        tokenType = e.target.value;
        this.setState({ tokenType });
    }

    onChangeList = (e) => {
        let { moduleType } = this.state;
        moduleType = e.target.value;
        this.setState({ moduleType });
    }

    onCheckStatus = () => {
        let { checked } = this.state;
        this.setState({ checked: !checked });
    }

    onCheckHasSolved = () => {
        let { hasSolved } = this.state;
        this.setState({ hasSolved: !hasSolved });
    }

    onChangePriorityValue = (e) => {
        this.setState({ priorityValue: e.target.value })
    }

    onChangeUserList = (e) => {
        this.setState({ userListValue: e.target.value })
    }

    onChangeProblemDetails = (e) => {
        this.setState({ problemDetails: e.target.value })
    }

    handleError = () => {
        // let { balanceMsgRechargeErr } = this.state;
        let formIsValid = true;

        if (this.state.callerContactNo === '') {
            formIsValid = false;
            errors["callerContactNo"] = "Caller Contact No. can't be empty.";
        }
        if (this.state.pointTypeValue === '') {
            formIsValid = false;
            errors["pointTypeValue"] = "Point Type can't left empty.";
        }
        if (this.state.tokenType === "Problem") {
            if (this.state.moduleValue === "") {
                formIsValid = false;
                errors["moduleValue"] = "Problem module can't left empty.";
            }
            if (this.state.moduleType === "") {
                formIsValid = false;
                errors["problemType"] = "Problem type can't left empty.";
            }
            if (this.state.problemDetails === "") {
                formIsValid = false;
                errors["problemDetails"] = "Problem Details can't left empty.";
            }
            if (this.state.checked == true) {
                if (this.state.priorityValue === "") {
                    formIsValid = false;
                    errors["problemPriority"] = "Problem priority can't left empty.";
                }
                if (this.state.userListValue === "") {
                    formIsValid = false;
                    errors["solverName"] = "Solver Name can't left empty.";
                }
            }
        } else if (this.state.tokenType === "Requirement") {
            if (this.state.moduleValue === "") {
                formIsValid = false;
                errors["module"] = "Module can't left empty.";
            }
            if (this.state.problemDetails === "") {
                formIsValid = false;
                errors["requirementProblemDetails"] = "Problem Details can't left empty.";
            }

        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitProblemToken = () => {
        this.setState({ errorMsgVisible: false })
        let { netiRequirementSubmitObj, edumanSubmitObj, tokenInfoObj } = this.state
        console.log("pointTypeValue in token",this.state.pointTypeValue);

        if (this.handleError()) {
            if (this.state.pointTypeValue === 'NetiWorld') {
                console.log("inside if",this.state.pointTypeValue);
                netiRequirementSubmitObj.tokenContact = this.state.callerContactNo
                netiRequirementSubmitObj.tokenSource = this.state.pointTypeValue

                netiRequirementSubmitObj.creatorBasicInfoDTO.netiID = tokenInfoObj.netiID

                netiRequirementSubmitObj.tokenDetails = this.state.problemDetails

                if (this.state.tokenType === "Requirement") {
                    netiRequirementSubmitObj.requirementStatus = 1
                    netiRequirementSubmitObj.tokenTypeInfoDTO.coreCategoryID = this.state.moduleValue
                }
                else if (this.state.tokenType === "Problem") {
                    netiRequirementSubmitObj.requirementStatus = 0
                    netiRequirementSubmitObj.tokenTypeInfoDTO.coreCategoryID = this.state.moduleType

                    if (this.state.checked) {
                        netiRequirementSubmitObj.tokenStatus = 1
                        netiRequirementSubmitObj.priorityStatus = this.state.priorityValue
                        netiRequirementSubmitObj.solverBasicInfoDTO.netiID = this.state.userListValue
                    }
                    else if (this.state.hasSolved) {
                        netiRequirementSubmitObj.tokenStatus = 10
                        netiRequirementSubmitObj.priorityStatus = ''
                        netiRequirementSubmitObj.solverBasicInfoDTO.netiID = ''
                    }
                }

                this.setState({ netiRequirementSubmitObj })
                console.log("netiRequirementSubmitObj",netiRequirementSubmitObj);

                this.supportPointService.postNetiWorldTokenRequirements(netiRequirementSubmitObj)
                    .then(res => {
                        if (res.status === 201) {
                            this.setState({ tokenInfoObj: cloneStateBeforeMount.tokenInfoObj,deviceInstituteIdORnetiId:'', callerContactNo:'',pointTypeValue:'',tokenType:'',moduleType:'',problemDetails:'',checked:false,userListValue:'' });

                            // this.setState({netiRequirementSubmitObj:cloneStateBeforeMount.netiRequirementSubmitObj});
                            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data Submitted Successfully.' });
                        }
                        else {
                            this.supportPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        console.log("Error", 'Connection problem');
                    });

            }
            else if (this.state.pointTypeValue === 'Eduman') {
                edumanSubmitObj.tokenContact = this.state.callerContactNo
                edumanSubmitObj.tokenSource = this.state.pointTypeValue

                edumanSubmitObj.edumanDetailsInfoDTO = tokenInfoObj

                edumanSubmitObj.tokenDetails = this.state.problemDetails

                if (this.state.tokenType === "Requirement") {
                    edumanSubmitObj.requirementStatus = 1
                    edumanSubmitObj.tokenTypeInfoDTO.coreCategoryID = this.state.moduleValue
                }
                else if (this.state.tokenType === "Problem") {
                    edumanSubmitObj.requirementStatus = 0
                    edumanSubmitObj.tokenTypeInfoDTO.coreCategoryID = this.state.moduleType

                    if (this.state.checked) {
                        edumanSubmitObj.tokenStatus = 1
                        edumanSubmitObj.priorityStatus = this.state.priorityValue
                        edumanSubmitObj.solverBasicInfoDTO.netiID = this.state.userListValue
                    }
                    else if (this.state.hasSolved) {
                        edumanSubmitObj.tokenStatus = 10
                        edumanSubmitObj.priorityStatus = ''
                        edumanSubmitObj.solverBasicInfoDTO.netiID = ''
                    }
                }

                this.setState({ edumanSubmitObj })

                this.supportPointService.postEdumanTokenRequirements(edumanSubmitObj)
                    .then(res => {
                        if (res.status === 201) {
                            this.setState({ tokenInfoObj: cloneStateBeforeMount.tokenInfoObj,deviceInstituteIdORnetiId:'', callerContactNo:'',pointTypeValue:'',tokenType:'',moduleType:'',problemDetails:'',checked:false,userListValue:'' });
                            // this.setState({netiRequirementSubmitObj:cloneStateBeforeMount.edumanSubmitObj});
                            this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data Submitted Successfully.' });
                        }
                        else {
                            this.supportPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        console.log("Error", 'Connection problem');
                    });

            }

         }

    }

    tokenProblemSubmitError = () => {
        let { errors } = this.state;
        let formIsValid = true;
        // if (this.state.tokenType === "Problem") {
        if (this.state.moduleValue === "") {
            formIsValid = false;
            errors["moduleValue"] = "Problem module can't left empty.";
        }

        if (this.state.moduleType === "") {
            formIsValid = false;
            errors["class"] = "Problem type can't left empty.";
        }

        if (this.state.problemDetails === "") {
            formIsValid = false;
            errors["problemDetails"] = "Problem Details can't left empty.";
        }

        //  } 
        // else if (this.state.tokenType === "Requirement") {

        //     if (this.state.moduleValue === "") {
        //         formIsValid = false;
        //         errors["module"] = "Module can't left empty.";
        //     }
        //     if (this.state.problemDetails === "") {
        //         formIsValid = false;
        //         errors["requirementProblemDetails"] = "Problem Details can't left empty.";
        //     }

        // }


        this.setState({ errors });
        return formIsValid;

    }


    renderHeader = () => {
        return (
            <div className="p-grid">
                <div className="p-col-6" style={{ textAlign: 'right' }}>
                    <span>Applicants Info</span>
                </div>
            </div>
        );
    }


    render() {
        let { applicantUpdatedInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        const header = this.renderHeader();
        const priorityList = [
            { label: 'Normal', value: '0' },
            { label: 'Medium', value: '1' },
            { label: 'High', value: '2' },
            { label: 'Emergency', value: '3' },
        ]

        let solverNameList = [];
     
        if ((this.state.tokenSolverList != null) && (this.state.tokenSolverList.length > 0)) {
            solverNameList = this.state.tokenSolverList.map((item) => ({
                value: item.netiID,
                label: item.fullName,
            }));
        }

        let pointType = []
        if (this.state.tokenInfoDropDown && this.state.tokenInfoDropDown.length) {
            pointType = this.state.tokenInfoDropDown.map((item) => ({
                value: item,
                label: item.categoryName
            }));
        }

        let problemModuleList = []
        if (this.state.problemModule && this.state.problemModule.length) {
            problemModuleList = this.state.problemModule.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        let problemTypeList = []
        if (this.state.problemType && this.state.problemType.length) {
            problemTypeList = this.state.problemType.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        let userList = [];
        if ((this.state.userListBody != null) && (this.state.userListBody.length > 0)) {
            userList = this.state.userListBody.map((item) => ({
                value: item.netiID,
                label: item.fullName,
            }));
        }


        return (
          
            <div className="nw-form">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-col-12 p-xl-12">
                    {/* <div className="p-col-12 p-xl-12">
                        <div className="p-col-12 p-xl-12 nw-button-parent">
                            <Button
                                className="p-button p-button-primary nw-button nw-button-left"
                                label="Search Token"
                                icon="fas fa-search"
                                onClick={e => this.props.onSearchToken()}
                            />
                            <Button
                                className="p-button p-button-primary nw-button nw-button-right"
                                label="Create Token"
                                onClick={this.onSearchHandler}
                            />
                            {this.state.showTokenField ?

                                <div className="p-col-12 p-xl-1 nw-button-parent" >
                                    <Button
                                        className="p-button-danger nw-button-right"
                                        icon="fa fa-times"
                                        style={{ "minWidth" : "40px !important" }}
                                        onClick={this.closeToken}
                                    />
                                </div>

                                : ""
                                }
                        </div>
                    </div> */}
                </div>




                    <div className="p-col-12 p-xl-12">
                        <Growl ref={(el) => this.growl = el} />

                        <div className="p-grid nw-form-body">

                          {/* {this.state.showTokenField ? */}
                                        
                                        <div className="p-grid p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12 p-col-nogutter" >

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Caller Contact No. <span>*</span></span>
                                                        <InputText
                                                            className="custom-form-control"
                                                            id="callerContactNo"
                                                            name="callerContactNo"
                                                            placeholder="Enter Caller Contact No."
                                                            maxLength={11}
                                                            value={this.state.callerContactNo}
                                                            onChange={this.onChangeCallerContactNo}
                                                        />

                                                    </div>
                                                    <span className='error-message'>{this.state.errors["callerContactNo"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    {this.state.dropdownIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Application For <span>*</span></span>
                                                            <Dropdown
                                                                placeholder={this.state.pointTypeValue || "Select Application"}
                                                                autoWidth={false}
                                                                name="application"
                                                                options={pointType}
                                                                value={this.state.pointTypeValue}
                                                                onChange={this.onChangePointTypeValue}
                                                                
                                                            />
                                                        </div>
                                                    }

                                                    <span className='error-message'>{this.state.errors["pointTypeValue"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                                    <Button
                                                        className="p-button p-button-primary nw-button nw-button-right"
                                                        label="Search"
                                                        icon="fas fa-search"
                                                        onClick={this.onSearchInfoEdumanAndNetiWorld}
                                                    />
                                                </div>

                                                {this.state.emptyTokenInfoObj ?
                                                    <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                        <div className="p-col-12 p-xl-12 ">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">{this.state.pointTypeValue} ID <span>*</span></span>
                                                                <InputText
                                                                    className="custom-form-control"
                                                                    id="studentName"
                                                                    name="studentName"
                                                                    placeholder={"Enter " + this.state.pointTypeValue + "ID"}
                                                                    value={this.state.deviceInstituteIdORnetiId}
                                                                    onChange={this.onChangedeviceInstituteIdORnetiId}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12 nw-button-parent">
                                                            <Button
                                                                className="p-button-primary nw-button nw-button-right"
                                                                label="Search"
                                                                icon="fas fa-search"
                                                                onClick={this.onSearchdeviceInstituteIdORnetiId}
                                                            />
                                                        </div>
                                                    </div>
                                                    : ''
                                                }
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {this.state.dropdownIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                    <div className="p-inputgroup">
                                                        {/* <span className="p-inputgroup-addon">Serial No. <span>*</span></span> */}
                                                        <div className="nw-inputgroup-desc info-bar">
                                                            <span className="first">{this.state.pointTypeValue + " Info"}</span>
                                                            <span className="second">
                                                                <span className="title">
                                                                    {
                                                                        this.state.pointTypeValue == 'Eduman' ?
                                                                            'Insitute ID' : 'Neti ID'
                                                                    }
                                                                </span>
                                                                <span> : </span>
                                                                <span className="desc">
                                                                    {
                                                                        this.state.pointTypeValue == 'Eduman' ?
                                                                            this.state.tokenInfoObj.instituteId
                                                                            : this.state.tokenInfoObj.customNetiID
                                                                    }
                                                                </span>
                                                                
                                                            </span>
                                                            <span className="third">
                                                                <span className="title">
                                                                    {
                                                                        this.state.pointTypeValue == 'Eduman' ?
                                                                            'Insitute Name' : 'Name'
                                                                    }
                                                                </span>
                                                                <span> : </span>
                                                                <span className="desc">
                                                                    {
                                                                        this.state.pointTypeValue == 'Eduman' ?
                                                                            this.state.tokenInfoObj.instituteName
                                                                            : this.state.tokenInfoObj.fullName
                                                                    }
                                                                </span>
                                                                
                                                            </span>
                                                        </div>
                                                    </div>
                                                }   
                                                <span className='error-message'>{this.state.errors["pointTypeValue"]}</span>
                                            </div>

                                            {/* <div className="p-col-12 p-xl-12 ">

                                                <div className="nw-search-view">

                                                    <div>
                                                        {this.state.tokenInfoObj.instituteName ?
                                                            <div className="p-col-12 p-xl-12">
                                                                <center>
                                                                    <label><h3 htmlFor="instituteContactNo">{this.state.tokenInfoObj.instituteName}</h3></label>
                                                                    <p>{this.state.tokenInfoObj.instituteAddress}</p>
                                                                </center>
                                                                <br />
                                                            </div>
                                                            :''
                                                        }
                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.inputFieldIsLoading ?
                                                                this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">
                                                                        {
                                                                            this.state.pointTypeValue == 'Eduman' ?
                                                                                'Insitute ID' : 'Neti ID'
                                                                        }
                                                                    </span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {
                                                                            this.state.pointTypeValue == 'Eduman' ?
                                                                                this.state.tokenInfoObj.instituteId
                                                                                : this.state.tokenInfoObj.customNetiID
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.inputFieldIsLoading ?
                                                                this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon">
                                                                        {
                                                                            this.state.pointTypeValue == 'Eduman' ?
                                                                                'Insitute Name' : 'Name'
                                                                        }
                                                                    </span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {
                                                                            this.state.pointTypeValue == 'Eduman' ?
                                                                                this.state.tokenInfoObj.instituteName
                                                                                : this.state.tokenInfoObj.fullName
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div> */}

                                            

                                            {/* <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                <Fieldset legend={this.state.pointTypeValue + " Info"}>
                                                    <div className="p-col-12 p-xl-12">

                                                        {this.state.tokenInfoObj.instituteName ?
                                                            <div>
                                                                <div className="p-col-12">
                                                                    <center>
                                                                        <label><h3 htmlFor="instituteContactNo">{this.state.tokenInfoObj.instituteName}</h3></label>
                                                                        <p>{this.state.tokenInfoObj.instituteAddress}</p>
                                                                    </center>
                                                                    <br />
                                                                    <hr />
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }

                                                        {this.state.inputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                            <div className="p-grid p-col-12">
                                                                <div className="p-grid p-lg-4">
                                                                    <label htmlFor="instituteContactNo">

                                                                        {
                                                                            this.state.pointTypeValue == 'Eduman' ?
                                                                                'Insitute ID' : 'Neti ID'
                                                                        }

                                                                    </label>
                                                                </div>

                                                                <div className="p-grid p-lg-8">
                                                                    <span style={{ paddingRight: ".5em" }}>:</span>
                                                                    {
                                                                        this.state.pointTypeValue == 'Eduman' ?
                                                                            this.state.tokenInfoObj.instituteId
                                                                            : this.state.tokenInfoObj.customNetiID
                                                                    }
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.inputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                            <div className="p-grid p-col-12">
                                                                <div className="p-grid p-lg-4">
                                                                    <label htmlFor="instituteContactNo">

                                                                        {
                                                                            this.state.pointTypeValue == 'Eduman' ?
                                                                                'Insitute Name' : 'Name'
                                                                        }
                                                                    </label>
                                                                </div>
                                                                <div className="p-grid p-lg-8">
                                                                    <span style={{ paddingRight: ".5em" }}>:</span>
                                                                    {
                                                                        this.state.pointTypeValue == 'Eduman' ?
                                                                            this.state.tokenInfoObj.instituteName
                                                                            : this.state.tokenInfoObj.fullName
                                                                    }
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.tokenInfoObjBasicInfo.fullName ?
                                                            <div className="p-grid p-col-12">
                                                                <div className="p-grid p-lg-4">
                                                                    <label htmlFor="instituteContactNo">Responsible User</label>
                                                                </div>
                                                                <div className="p-grid p-lg-8">
                                                                    <span style={{ paddingRight: ".5em" }}>:</span>
                                                                    {this.state.tokenInfoObjBasicInfo.fullName}
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </Fieldset>

                                        </div> */}

                                        
                                        
                                        <div className="p-col-12 p-xl-12">
                                        {/* <br/><br/><hr/> <br/><br/> */}
                                          
                                            <div className="p-grid">

                                                <div className="p-col-12 p-xl-12">
                                                    
                                                    <div className="p-inputgroup">
                                    
                                                        <span className="p-inputgroup-addon addon-no-style">
                                                            Token Type <span>*</span>
                                                        </span>
                                                        <div className="nw-inputgroup-desc desc-no-style">

                                                            <div className="input-radio-button">
                                                                <div className="radio-button-inside">
                                                                    <RadioButton
                                                                        value="Problem"
                                                                        inputId="rb1"
                                                                        checked={this.state.tokenType === "Problem"}
                                                                        onChange={this.onChangeTokenType}
                                                                    />
                                                                    <label htmlFor="rb1" className="p-radiobutton-label">Problem</label>
                                                                </div>

                                                                <div className="radio-button-inside">
                                                                    <RadioButton
                                                                        value="Requirement"
                                                                        inputId="rb2"
                                                                        checked={this.state.tokenType === "Requirement"}
                                                                        onChange={this.onChangeTokenType}
                                                                    />
                                                                    <label htmlFor="rb1" className="p-radiobutton-label">Requirement</label>
                                                                </div>

                                                                
                                                            </div>



                                                            {/* <div className="p-grid p-col-12">
                                                                <div className="p-col-4 p-col-nogutter">
                                                                    <RadioButton
                                                                        value="Problem"
                                                                        inputId="rb1"
                                                                        checked={this.state.tokenType === "Problem"}
                                                                        onChange={this.onChangeTokenType}
                                                                    />
                                                                    <label htmlFor="rb1" className="p-radiobutton-label">Problem</label>
                                                                </div>

                                                                <div className="p-col-4 p-col-nogutter">
                                                                    <RadioButton
                                                                        value="Requirement"
                                                                        inputId="rb2"
                                                                        checked={this.state.tokenType === "Requirement"}
                                                                        onChange={this.onChangeTokenType}
                                                                    />
                                                                    <label htmlFor="rb2" className="p-radiobutton-label">Requirement</label>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>

                                                    
                                                {this.state.tokenType == "Problem" ?
                                                    <div className="p-col-12 p-xl-12 p-col-nogutter">
                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.secondDropdownIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Problem Module <span>*</span></span>
                                                                    <Dropdown
                                                                        placeholder="Select problem Module"
                                                                        showClear={true}
                                                                        autoWidth={false}
                                                                        name="problemModule"
                                                                        value={this.state.moduleValue}
                                                                        options={problemModuleList}
                                                                        onChange={this.getProblemTypeList}
                                                                    />
                                                                </div>
                                                            }
                                                            <span className='error-message'>{this.state.errors["moduleValue"]}</span>
                                                        </div>


                                                        <div className="p-col-12 p-xl-12">
                                                            {this.state.thirdDropdownIsLoading ? this.netiContentLoader.normalFormInputField() :

                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">Problem Type <span>*</span></span>
                                                                    <Dropdown
                                                                        placeholder="Select Problem Type"
                                                                        showClear={true}
                                                                        autoWidth={false}
                                                                        name="problemType"
                                                                        value={this.state.moduleType}
                                                                        options={problemTypeList}
                                                                        onChange={this.onChangeList}
                                                                    />
                                                                </div>
                                                            }
                                                            <span className='error-message'>{this.state.errors["problemType"]}</span>
                                                        </div>


                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Problem Details <span>*</span></span>
                                                                <InputTextarea
                                                                    placeholder="Enter Problem Details"
                                                                    rows={3}
                                                                    maxLength={140}
                                                                    name="problemDetails"
                                                                    cols={30}
                                                                    autoResize={true}
                                                                    value={this.state.problemDetails || ''}
                                                                    onChange={this.onChangeProblemDetails}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.errors["problemDetails"]}</span>
                                                        </div>

                                                        {this.state.hasSolved == true ?
                                                            null
                                                            :
                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="p-inputgroup-addon addon-no-style">Do you want to assign? <span>*</span> </span>

                                                                        <span className="nw-inputgroup-desc desc-no-style">
                                                                            <Checkbox
                                                                                value={this.state.checked}
                                                                                onChange={this.onCheckStatus}
                                                                                checked={this.state.checked}>
                                                                            </Checkbox>
                                                                        </span>
                                                                        
                                                                    </div>

                                                                </div>
                                                        }

                                                        {this.state.checked == true
                                                            ? <div>
                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="p-inputgroup-addon">Priority <span>*</span> </span>
                                                                        <Dropdown
                                                                            placeholder="Select Problem Priority"
                                                                            showClear={true}
                                                                            autoWidth={false}
                                                                            name="problemPriority"
                                                                            options={priorityList}
                                                                            value={this.state.priorityValue}
                                                                            onChange={this.onChangePriorityValue}
                                                                        />
                                                                    </div>
                                                                    <span className='error-message'>{this.state.errors["problemPriority"]}</span>
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    <div className="p-inputgroup">
                                                                        <span className="p-inputgroup-addon">Assign To Solver <span>*</span></span>
                                                                        <Dropdown
                                                                            placeholder="Select Solver Name"
                                                                            showClear={true}
                                                                            autoWidth={false}
                                                                            name="solverName"
                                                                            options={userList}
                                                                            value={this.state.userListValue}
                                                                            onChange={this.onChangeUserList}
                                                                        />
                                                                    </div>
                                                                    <span className='error-message'>{this.state.errors["solverName"]}</span>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }


                                                        {this.state.checked == true ?
                                                            null
                                                            :
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon addon-no-style">Has Already Solved? <span>*</span> </span>

                                                                    <span className="nw-inputgroup-desc desc-no-style">
                                                                        <Checkbox
                                                                            value={this.state.hasSolved}
                                                                            onChange={this.onCheckHasSolved}
                                                                            checked={this.state.hasSolved}>
                                                                        </Checkbox>
                                                                    </span>

                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    null
                                                }

                                                {this.state.tokenType == "Requirement" ?
                                                    <div  className="p-col-12 p-xl-12 p-col-nogutter">
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon">Module <span>*</span></span>
                                                                <Dropdown
                                                                    placeholder="Select Module"
                                                                    showClear={true}
                                                                    autoWidth={false}
                                                                    name="module"
                                                                    value={this.state.moduleValue}
                                                                    options={problemModuleList}
                                                                    onChange={this.getProblemTypeList}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.errors["module"]}</span>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon nw-inputtextarea-label">Problem Details <span>*</span></span>
                                                                <InputTextarea
                                                                    placeholder="Enter Problem Details"
                                                                    rows={3}
                                                                    cols={30}
                                                                    maxLength={140}
                                                                    autoResize={true}
                                                                    name='requirementProblemDetails'
                                                                    value={this.state.problemDetails}
                                                                    onChange={this.onChangeProblemDetails}
                                                                />
                                                            </div>
                                                            <span className='error-message'>{this.state.errors["requirementProblemDetails"]}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                
                                            </div>
                                            


                                            <div className="p-col-12 nw-button-parent-multiple">
                                                <Button
                                                    className="p-button p-button-danger nw-button nw-button-multiple"
                                                    label="Discard"
                                                    icon="fas fa-times"
                                                    onClick={this.props.turnOffDialog}
                                                />
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                    label="Submit"
                                                    icon="fas fa-check"
                                                    onClick={this.onSubmitProblemToken}
                                                />
                                            </div>




                                        </div>


                                    </div>

                                    {/* : ''
                             } */}

                        </div>


                    </div>

                    
                </div>
           
        )
    }
}