import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import NetiContentLoader from '../../../common/NetiContentLoader';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { Link } from 'react-router-dom';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { pdfDownloadPortrait_TableWithSubTitle, pdfDownloadLandscape_TableWithSubTitle } from '../../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';
import CommonFuctionality from '../../../common/CommonFuctionality';
import { Dialog } from 'primereact/dialog';

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class TitleWiseToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            titleWiseTokenList: [],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            problem: '',

            detailsContent: '',
            visible: false,
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }

        this.CommonFuctionality = new CommonFuctionality();
        this.managementPointService = new ManagementPointService();

        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    componentWillMount() {
        if (this.props.location.problemWiseTokenInfo) {

            let problemDateRange = JSON.parse(localStorage.getItem("problemDateRange"));

            let titleWisetokenInfo = {
                "startDate": problemDateRange.startDate,
                "endDate": problemDateRange.endDate,
                "defCode": this.props.location.problemWiseTokenInfo.defCode,
                "problem": this.props.location.problemWiseTokenInfo.problem,
            }
            localStorage.setItem("titleWisetokenInfo", JSON.stringify(titleWisetokenInfo));
        }
        this.getModuleProblemQuantityWiseList();
    }

    getModuleProblemQuantityWiseList = () => {
        let moduleTokenInfo = JSON.parse(localStorage.getItem("titleWisetokenInfo"));

        this.setState({
            problem: moduleTokenInfo.problem
        })

        if (!moduleTokenInfo) {
            return;
        }

        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.managementPointService.getApplicationModuleProblemWiseTokenQuantity(moduleTokenInfo)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        for (let i = 0; i < body.length; i++) {

                            body[i].solverNetiID = body[i].solverNetiID.toString(); // for pdf & excel

                            if (body[i].submitDate == null) {
                                body[i].submitDate = '';
                            } else {
                                body[i].submitDate = NetiDateUtils.getDateWithHHMMSSA(body[i].submitDate);
                            }
                            if (body[i].solvedDate == null) {
                                body[i].solvedDate = '';
                            } else {
                                body[i].solvedDate = NetiDateUtils.getDateWithHHMMSSA(body[i].solvedDate);
                            }
                        }
                        this.setState({ titleWiseTokenList: body });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    viewDialog(rowData) {

        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visible: true, detailsContent: rowData.tokenDetails });

    }

    onHide(event) {

        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visible: false });

    }

    tokenBody = (rowData) => {

        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-info"
                title='Click to view deposit details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>

    }

    // bodySubmitDate = (rowData) => {
    //     return(<div>

    //          { NetiDateUtils.getDateWithTime(rowData["submitDate"], "DD-MM-YY") }
    //     </div>);

    // }

    exportPdf() {

        let pdfColumns = [
            { title: "Submit Date & Time", dataKey: "submitDate" },
            { title: "Solved Date & Time", dataKey: "solvedDate" },
            { title: "Token ID", dataKey: "customTokenID" },
            { title: "Solver Neti ID", dataKey: "solverNetiID" },
            { title: "Solver Name", dataKey: "solverName" },
        ]

        let tableColStyle = {};

        pdfDownloadLandscape_TableWithSubTitle("Title Wise Token List", 113, subTitleColumn, subTitleList, pdfColumns, this.state.titleWiseTokenList, tableColStyle, "TitleWise_Token_List.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, titleWiseTokenList } = this.state;

        // pdf reletaed task
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" }
        ];
        subTitleList = [
            { A: "Problem Title", B: this.state.problem }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = this.state.problem + "_Token_List.xlsx";
        let excelSheetName = "TitleWise_Token_List";
        excelColumns = [
            <Workbook.Column value="submitDate" label="Submit Date & Time" />,
            <Workbook.Column value="solvedDate" label="Solved Date & Time" />,
            <Workbook.Column value="customTokenID" label="Token ID" />,
            <Workbook.Column value="solverNetiID" label="Number of Token" />,
            <Workbook.Column value="solverName" label="Solver Name" />,
        ];

        // end excel

        let header = <div className="header-title">
            <div className="header-title-left">Title Wise Token List of {this.state.problem}</div><div className="header-title-right"><a>Total Found: {titleWiseTokenList.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        return (
            <div className="p-grid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="nw-form">

                    <div className="p-col-12 p-xl-12 nw-data-table">
                        {/* {
                        this.state.tableView === true ?
                            this.state.dataTableIsLoading ? 
                            this.NetiContentLoader.MyPointTableLoader()
                            : */}
                        <DataTable
                            header={header}
                            responsive={true}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            value={this.state.titleWiseTokenList}
                        //selection={this.state.dataTableSelection}
                        >
                            <Column field="submitDate" header="Submit Date & Time" filter={true} />
                            <Column field="solvedDate" header="Solved Date & Time" filter={true} />
                            <Column field="customTokenID" header="Token ID" filter={true} />
                            <Column field="solverNetiID" header="Solver Neti ID" filter={true} />
                            <Column field="solverName" header="Solver Name" filter={true} />
                            <Column field="" header="Problem Details" body={this.tokenBody} style={{ width: '95px' }} />

                        </DataTable>

                        {/* :
                            
                            <BlankDataTableAnim/> 
                    } */}
                    </div>
                    <div className="p-col-12 p-xl-12 nw-button-parent">

                        <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                        <div style={{ display: 'none' }}>

                            <Workbook
                                filename={excelFileName}
                                element={
                                    <Button
                                        label="Download Excel"
                                        id="clickExcelBtn"
                                        icon="fas fa-download"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                    />
                                }
                            >
                                <Workbook.Sheet data={this.state.titleWiseTokenList} name={excelSheetName}>
                                    {excelColumns}
                                </Workbook.Sheet>
                            </Workbook>

                        </div>

                    </div >

                    <Dialog className="nw-dialog" header="Problem Details" visible={this.state.visible} onHide={this.onHide} maximizable>

                        <div className="p-col-12 p-xl-12">

                            <div className="nw-search-view">
                                <div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="task-badge found"></span>
                                            <span className="p-inputgroup-addon">Details</span>
                                            <span className="p-inputgroup-colon">:</span>
                                            <div className="nw-inputgroup-desc">{this.state.detailsContent}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </Dialog>

                </div>

                {/* </div> */}

            </div>
        )
    }
}