import React, { Component } from 'react';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { TreeTable } from 'primereact/treetable';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Calendar } from 'primereact/calendar';
import { Workbook } from 'react-excel-workbook';

let assignedPartnerListArr = [];
let status = { display: 'table-cell' };
let maxDate = new Date();
let profile;

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class ManagementPointPartnerIncomeTreeView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableView: false,
            errors: {},
            onExpander: true,
            searchObj: {
                startDate: "",
                endDate: "",
                netiID: ""
            },
            assignedPartnerList: [],
            productTypeErr: {},
            productType: '',
            dataTableIsLoading: false,
            mbpListObj: {},
            zbpListObj: {},
            bdpListObj: {},
            assignListObj: {},
            assignListArr: [],
            expandedKeys: {},
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            first: 0,
            rows: 10,
            totalRecords: 0,
            loading: false,
            excelTableData: []
        }

        this.managementPointService = new ManagementPointService();
        this.onExpand = this.onExpand.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.commonFuctionality = new CommonFuctionality();
    }

    onChangeStartDate = (e) => {
        let { searchObj } = this.state;
        searchObj.startDate = e.target.value
        this.setState({ searchObj });
        this.clearDateError(e.target.name);
    }

    onChangeEndDate = (e) => {
        let { searchObj } = this.state;
        searchObj.endDate = e.target.value
        this.setState({ searchObj });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    searchErrorHandler = () => {
        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.searchObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start Date can't left empty.";
        }
        if (this.state.searchObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    // onSearchHandler = () => {
    //     let { assignListObj, searchObj } = this.state;
    //     assignedPartnerListArr = [];
    //     this.setState({ assignListObj: {} });
    //      profile = JSON.parse(localStorage.getItem('Profile'));
    //     searchObj.netiID = profile.netiID;
    //     this.setState({searchObj});
    //     if (this.searchErrorHandler()) {
    //         if (Date.parse(searchObj.endDate) >= Date.parse(searchObj.startDate)) {
    //         this.setState({ topProgressBar: true, tableView: true, dataTableIsLoading: true, errorMsgVisible: false, errorMsgVisible: false });
    //         this.managementPointService.findPartnerIncomeTreeView(searchObj)
    //             .then(res => {
    //                 if (res.status == 302) {
    //                     return res.json().then((body) => {
    //                         body.map((item, i) => {
    //                             assignListObj.data = item;
    //                             assignListObj.key = i;
    //                             assignListObj.children = [];
    //                             assignListObj.leaf = false;
    //                             assignedPartnerListArr.push(Object.assign({}, assignListObj));
    //                         });

    //                         this.setState({ dataTableIsLoading: false, tableView: true, errorMsgVisible: false, topProgressBar: false });
    //                     });
    //                 } else {
    //                     this.managementPointService.Auth.handleErrorStatus(res)
    //                         .then((responseBody) => {
    //                             this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
    //                         });
    //                 }
    //             }).catch(error => {
    //                 this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
    //             });
    //         }
    //     }

    // }

    // onExpand(event) {
    //     let { mbpListObj, zbpListObj, bdpListObj, assignListObj,searchObj } = this.state;
    //     this.setState({ mbpListObj: {} });
    //     this.setState({ zbpListObj: {} });
    //     this.setState({ bdpListObj: {} });
    //     let lazyNode = { ...event.node };
    //         if (lazyNode.data.designation === "MBP") {
    //             if(lazyNode.children.length === 0) {
    //             this.setState({ topProgressBar: true, errorMsgVisible: false });
    //             searchObj.netiID = lazyNode.data.netiID;
    //             this.setState({searchObj});
    //             this.managementPointService.findPartnerIncomeTreeView(searchObj).then(res => {
    //                 if (res.status === 302) {
    //                     return res.json().then((body) => {
    //                         body.map((item, i) => {
    //                             mbpListObj.data = item;
    //                             mbpListObj.key = `${lazyNode['key']}-${i}`;
    //                             mbpListObj.children = [];
    //                             mbpListObj.leaf = false;
    //                             lazyNode.children.push(Object.assign({}, mbpListObj));
    //                         });
    //                         this.setState({ topProgressBar: false, errorMsgVisible: false });
    //                     })
    //                 } else {
    //                     this.managementPointService.Auth.handleErrorStatus(res)
    //                         .then((resp) => {
    //                             this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
    //                         });
    //                 }
    //             }).catch((error) => {
    //                 this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
    //             });
    //          }
    //         }

    //         else if (lazyNode.data.designation === "ZBP") {
    //             if(lazyNode.children.length === 0) {
    //             this.setState({ topProgressBar: true, errorMsgVisible: false });
    //             searchObj.netiID = lazyNode.data.netiID;
    //             this.setState({searchObj});
    //             this.managementPointService.findPartnerPrePaidDepositSummary(this.state.searchObj).then(res => {
    //                 if (res.status === 302) {
    //                     return res.json().then((body) => {
    //                         body.map((item, i) => {
    //                             zbpListObj.data = item;
    //                             zbpListObj.key = `${lazyNode['key']}-${i}`;
    //                             zbpListObj.children = [];
    //                             zbpListObj.leaf = false;
    //                             lazyNode.children.push(Object.assign({}, zbpListObj));
    //                         });

    //                         this.setState({ topProgressBar: false, errorMsgVisible: false });
    //                     })
    //                 } else {
    //                     this.managementPointService.Auth.handleErrorStatus(res)
    //                         .then((resp) => {
    //                             this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
    //                         });
    //                 }
    //             }).catch((error) => {
    //                 this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
    //             });
    //          }
    //         }

    //         else if (lazyNode.data.designation === "BDP") {
    //             if(lazyNode.children.length === 0) {
    //             this.setState({ topProgressBar: true, errorMsgVisible: false });
    //             searchObj.netiID = lazyNode.data.netiID;
    //             this.setState({searchObj});
    //             this.managementPointService.findPartnerIncomeTreeView(this.state.searchObj).then(res => {
    //                 if (res.status === 302) {
    //                     return res.json().then((body) => {
    //                         body.map((item, i) => {
    //                             bdpListObj.data = item;
    //                             bdpListObj.key = `${lazyNode['key']}-${i}`;
    //                             lazyNode.children.push(Object.assign({}, bdpListObj));
    //                         });
    //                         this.setState({ topProgressBar: false, errorMsgVisible: false });
    //                     })
    //                 } else {
    //                     this.managementPointService.Auth.handleErrorStatus(res)
    //                         .then((resp) => {
    //                             this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
    //                         });
    //                 }
    //             }).catch((error) => {
    //                 this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
    //             });
    //         }
    //      }

    //      setTimeout(() => {
    //         this.commonFuctionality.setCustomizedTreeDatatable('pType', assignedPartnerListArr && assignedPartnerListArr[0] && assignedPartnerListArr[0].data && assignedPartnerListArr[0].data.designation);
    //     }, 500);

    // }

    onSearchHandler = () => {
        let { assignListObj, searchObj } = this.state;
        assignedPartnerListArr = []
        this.searchPartnerSelfIncomeDetails(null, assignListObj, false)
    }

    searchPartnerSelfIncomeDetails(lazyNode, objectName, isLeaf) {
        let { searchObj } = this.state;
        profile = JSON.parse(localStorage.getItem('Profile'));

        searchObj.netiID = (lazyNode != null ? lazyNode.data.netiID : profile.netiID)
        this.setState({ searchObj });

        if (this.searchErrorHandler()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false, tableView: true, });
            this.managementPointService.findPartnerIncomeTreeView(searchObj)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {

                            this.setState({ excelTableData: body }); // for excel
                            if (lazyNode && lazyNode.children.length === 0) {
                                body.map((item, i) => {
                                    objectName.data = item;
                                    objectName.children = [];
                                    objectName.leaf = isLeaf
                                    objectName.key = `${lazyNode['key']}-${i}`
                                    lazyNode.children.push(Object.assign({}, objectName))
                                })
                            }
                            else if (assignedPartnerListArr.length === 0) {
                                body.map((item, i) => {
                                    objectName.data = item;
                                    objectName.children = []
                                    objectName.leaf = isLeaf
                                    objectName.key = i
                                    assignedPartnerListArr.push(Object.assign({}, objectName))
                                })
                            }

                            this.setState({ dataTableIsLoading: false, tableView: true, errorMsgVisible: false, topProgressBar: false });
                        })
                    } else {
                        this.managementPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection.' });
                });

            setTimeout(() => {
                this.commonFuctionality.setCustomizedTreeDatatable('pType', assignedPartnerListArr && assignedPartnerListArr[0] && assignedPartnerListArr[0].data && assignedPartnerListArr[0].data.designation);
            }, 500);
        }

    }

    onExpand(event) {
        let { mbpListObj, zbpListObj, bdpListObj } = this.state;

        let lazyNode = { ...event.node };
        if (lazyNode.data.designation === "MBP") {
            this.searchPartnerSelfIncomeDetails(lazyNode, mbpListObj, false)
        }
        else if (lazyNode.data.designation === "ZBP") {
            this.searchPartnerSelfIncomeDetails(lazyNode, zbpListObj, false)
        }
        else if (lazyNode.data.designation === "BDP") {
            this.searchPartnerSelfIncomeDetails(lazyNode, bdpListObj, true)
        }
    }

    onExpandToggle = (e) => {
        this.setState({ expandedKeys: e.value })
    }

    onChangeStatus = (rowData) => {
        return (<span>{rowData.data["partnerStatus"] === 1 ? "Active" : "Inactive"}</span>);
    }

    onChangeProductType = e => {
        this.setState({ productType: e.target.value });
    }

    amountBodyTemplate = (rowData) => {

        if (rowData && rowData.data.amount === null) {
            return <div className="col-number text-right">0.00</div>
        } else { return <div className="col-number text-right">{rowData.data.amount}</div> }

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, searchObj } = this.state;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (searchObj.startDate && searchObj.endDate) {
            formatedStartDate = (searchObj.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (searchObj.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = formatedStartDate + ' to ' + formatedEndDate + "_Partner_Self_Income_Tree.xlsx";
        let excelSheetName = "Partner_Income_Tree";
        excelColumns = [
            <Workbook.Column value="designation" label="Designation" />,
            <Workbook.Column value="area" label="Area" />,
            <Workbook.Column value="customNetiID" label="Neti ID" />,
            <Workbook.Column value="name" label="Name" />,
            <Workbook.Column value="mobile" label="Mobile No." />,
            <Workbook.Column value="amount" label="Paid Amount" />,
        ];
        // end excel

        let header = <div className="header-title">
            <div className="header-title-left">Partner Income Tree</div>
            <div className="header-title-right"></div>
        </div>;

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.searchObj.startDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            name="startDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            value={this.state.searchObj.endDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            name="endDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>

                                </div>

                                <div className="p-col-12 p-xl-2 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSearchHandler}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="p-col-12 p-lg-12 p-xl-12 p-col-nogutter nw-data-table">
                            {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        : <div>
                                            <TreeTable
                                                header={header}
                                                lazy={true}
                                                value={assignedPartnerListArr}
                                                onExpand={this.onExpand}
                                                expandedKeys={this.state.expandedKeys}
                                                onToggle={this.onExpandToggle}
                                                style={{ marginTop: '.5em' }}>
                                                <Column field="designation" header="Partner Type" className='pType' expander={this.state.onExpander} />
                                                <Column field="area" header="Area" />
                                                <Column field="customNetiID" header="Neti ID" />
                                                <Column field="name" header="Name" />
                                                <Column field="mobile" header="Mobile No." />
                                                <Column field="" header="Amount" body={this.amountBodyTemplate} />
                                            </TreeTable>

                                            {/* <div className="nw-button-parent m-t-8 p-r-0" style={{ float: "right" }}>
                                                <Workbook
                                                    filename={excelFileName}
                                                    element={
                                                        <Button
                                                            label="Download Excel"
                                                            icon="fas fa-download"
                                                            className="p-button p-button-primary nw-button nw-button-right"
                                                        />
                                                    }
                                                >
                                                    <Workbook.Sheet data={this.state.excelTableData} name={excelSheetName}>
                                                        {excelColumns}
                                                    </Workbook.Sheet>
                                                </Workbook>
                                            </div> */}

                                        </div>
                                    :
                                    <BlankDataTableAnim />
                            }
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}