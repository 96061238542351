import React, {Component} from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';

import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';

export class PartnerIncomeStatementDetails extends Component {

    constructor(props) {
		super(props);
		this.state = {
            dataTableValue:[
                {"netiID":"100000025", "name":"kabir", "mobileNo":"01675886072", "area":"mirpur, Dhaka", "totalAmount":"4444" },
            ],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody:0
        }
    }

    totalAmountBody = (rowData) =>{
        return rowData.totalAmount
    }
        
    render() {
        
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody } = this.state;
        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Partner Income Info Details <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;

        return (
            <div className="p-fluid">
                {/* <div className="p-grid"> */}
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }

                    <div className="p-grid nw-form">
                        <div className="card p-col-12 p-lg-12 p-xl-12">
                                <div className="p-grid nw-form-body">
                                    <div className="p-col-12 p-lg-12 p-xl-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">Neti Id </span>
                                            <span className="nw-inputgroup-desc">1000000035 </span>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">Mobile No <span>*</span></span>
                                            <span className="nw-inputgroup-desc">01675886072</span>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">Name <span>*</span></span>
                                            <span className="nw-inputgroup-desc">Kabir </span>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-12 p-xl-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">Area <span>*</span></span>
                                            <span className="nw-inputgroup-desc">Mirpur, Dhaka </span>
                                        </div>
                                    </div>
                                    
                                </div>
                        </div>
                    
                    <div className="nw-data-table">
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            {/* {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                                    <DataTable
                                        header={header}
                                        responsive={true}
                                        selectionMode="single"
                                        paginator={true}
                                        rows={10}
                                        value={this.state.dataTableValue}
                                        //selection={this.state.dataTableSelection}
                                    >
                                        <Column field="name" header="Product Name" filter={true} />
                                        <Column field="totalAmount" header="Income Amount" filter={true} body={this.totalAmountBody} />

                                    </DataTable>

                                    {/* :
                                    
                                    <BlankDataTableAnim/> 
                            } */}
                        </div>
                    </div>
                
                </div>

            </div>
        )
    }
}