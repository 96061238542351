import React, { Component } from 'react';
import { CarService } from '../service/CarService';
import { Button } from 'primereact/components/button/Button';
import { TabView, TabPanel } from 'primereact/tabview';
import AuthService from '../auth/AuthService';
import { NetiFileHandler } from '../utils/NetiFileHandler';
import { FullCalendar } from 'primereact/fullcalendar';
import { UserOtherAccessService } from '../service/UserOtherAccessService';
import { ErrorMessageView } from './common/ErrorMessageView';
import { Growl } from 'primereact/growl';
import NetiContentLoader from './common/NetiContentLoader';
import { NetiDateUtils } from '../utils/NetiDateUtils';
import { ValidatorUtility } from '../utils/ValidatorUtility';



// import { profileImage } from '../../src/assets/images/profile-image-1.png';
let staticImg = 'assets/layout/images/avatar.png';
let userProfileImage;
let userFullName;
let profile;

export class Parent extends Component {

	constructor() {
		super();
		this.state = {
			userProfileImage: "",
			userFullName: '',
			homeDashboardInfo: {},
			dataTableIsLoading: false,
			errorMsgVisible: false,
			topProgressBar: false,
			errorMsgBody: '',
			tasks: [],
			city: null,
			selectedCar: null,
			activeIndex: 0,
			fullcalendarOptions: {
				defaultDate: '2017-02-01',
				header: {
					left: 'prev,next today',
					center: 'title',
					right: 'month,agendaWeek,agendaDay'
				}
			},
			events: [
				{
					"id": 1,
					"title": "All Day Event",
					"start": "2017-02-01"
				},
				{
					"id": 2,
					"title": "Long Event",
					"start": "2017-02-07",
					"end": "2017-02-10"
				},
				{
					"id": 3,
					"title": "Repeating Event",
					"start": "2017-02-09T16:00:00"
				},
				{
					"id": 4,
					"title": "Repeating Event",
					"start": "2017-02-16T16:00:00"
				},
				{
					"id": 5,
					"title": "Conference",
					"start": "2017-02-11",
					"end": "2017-02-13"
				},
				{
					"id": 6,
					"title": "Meeting",
					"start": "2017-02-12T10:30:00",
					"end": "2017-02-12T12:30:00"
				},
				{
					"id": 7,
					"title": "Lunch",
					"start": "2017-02-12T12:00:00"
				},
				{
					"id": 8,
					"title": "Meeting",
					"start": "2017-02-12T14:30:00"
				},
				{
					"id": 9,
					"title": "Happy Hour",
					"start": "2017-02-12T17:30:00"
				},
				{
					"id": 10,
					"title": "Dinner",
					"start": "2017-02-12T20:00:00"
				},
				{
					"id": 11,
					"title": "Birthday Party",
					"start": "2017-02-13T07:00:00"
				},
				{
					"id": 12,
					"title": "Click for Google",
					"url": "http://google.com/",
					"start": "2017-02-28"
				}
			],
			chartData: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				datasets: [{
					label: 'Sales',
					data: [12, 19, 3, 5, 2, 3, 9],
					borderColor: [
						'#7E57C2',
					],
					borderWidth: 3,
					borderDash: [5, 5],
					fill: false,
					pointRadius: 3
				}, {
					label: 'Income',
					data: [1, 2, 5, 3, 12, 7, 15],
					backgroundColor: [
						'rgba(187,222,251,0.2)',
					],
					borderColor: [
						'#42A5F5',
					],
					borderWidth: 3,
					fill: true
				},
				{
					label: 'Expenses',
					data: [7, 12, 15, 5, 3, 13, 21],
					borderColor: [
						'#FFB300',
					],
					borderWidth: 3,
					fill: false,
					pointRadius: [4, 6, 4, 12, 8, 0, 4]
				},
				{
					label: 'New Users',
					data: [3, 7, 2, 17, 15, 13, 19],
					borderColor: [
						'#66BB6A',
					],
					borderWidth: 3,
					fill: false
				}]
			},
			chartOptions: {
				responsive: true,
				hover: {
					mode: 'index'
				},
				scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Month'
						}
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Value'
						}
					}]
				}
			},
			menuItems: [
				{
					label: 'Save', icon: 'fa fa-fw fa-check'
				},
				{
					label: 'Update', icon: 'fa fa-fw fa-refresh'
				},
				{
					label: 'Delete', icon: 'fa fa-fw fa-trash'
				}
			],
			realimg: '',
			resizeImg: ''
		};
		this.onTaskChange = this.onTaskChange.bind(this);
		this.logoTemplate = this.logoTemplate.bind(this);
		this.scrollLeftBtn = this.scrollLeftBtn.bind(this);
		this.scrollRightBtn = this.scrollRightBtn.bind(this);
		this.reloadUserProfileFromLocal = this.reloadUserProfileFromLocal.bind(this);

		this.AuthService = new AuthService();
		this.carservice = new CarService();
		this.netiFileHandler = new NetiFileHandler();
		this.validatorUtility = new ValidatorUtility();
		this.userOtherAccessService = new UserOtherAccessService();
		this.reloadUserProfileFromLocal();
	}

	reloadUserProfileFromLocal() {
		profile = JSON.parse(localStorage.getItem('Profile'));
		if (profile) {
			this.setState({ userProfileImage: profile.imageContent, userFullName: profile.fullName });
		} else {
			setTimeout(() => {
				this.componentWillMount();
			}, 1000)
		}
	}

	componentWillMount() {
		profile = JSON.parse(localStorage.getItem('Profile'));
		if (profile) {
			this.setState({ userProfileImage: profile.imageContent, userFullName: profile.fullName });
		}
	}

	componentDidMount() {
		this.getHomeDashboardInfoDetails();
	}

	getHomeDashboardInfoDetails() {
		this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' });
		this.userOtherAccessService.getHomeDashboardInfo()
			.then(res => {
				if (res.status === 302) {
					return res.json().then((body) => {
						this.setState({ homeDashboardInfo: body })
						this.setState({ topProgressBar: false, errorMsgVisible: false, errorMsgBody: '' });

					});
				}
				else {
					this.userOtherAccessService.Auth.handleErrorStatus(res)
						.then((resp) => {
							this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
						});
				}
			}).catch((error) => {
				this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
			});

	}



	onTaskChange(e) {
		let selectedTasks = [...this.state.tasks];
		if (e.checked)
			selectedTasks.push(e.value);
		else
			selectedTasks.splice(selectedTasks.indexOf(e.value), 1);

		this.setState({ tasks: selectedTasks });
	}




	scrollLeftBtn() {
		document.getElementById('scrollableFlexWrapper').scrollLeft -= 190;
	}

	scrollRightBtn() {
		document.getElementById('scrollableFlexWrapper').scrollLeft += 190;
	}

	actionTemplate() {
		return <div className="p-grid">
			<div className="p-col-6">
				<Button type="button" icon="pi pi-search" className="p-button-info" style={{ marginRight: '.5em' }}></Button>
			</div>
			<div className="p-col-6">
				<Button type="button" icon="pi pi-times" className="p-button-danger"></Button>
			</div>
		</div>;
	}

	logoTemplate(rowData, column) {
		var src = "assets/demo/images/car/" + rowData.brand + ".png";
		return <img src={src} alt={rowData.brand} width="50px" />;
	}

	render() {
		let { topProgressBar, errorMsgVisible, errorMsgBody, homeDashboardInfo } = this.state;
		let pointAssignedList;
		if (homeDashboardInfo && homeDashboardInfo.points !== undefined) {
			pointAssignedList = homeDashboardInfo.points.map((item) => {
				return (<div className="flex-box">
					<div className="flip-card boxHeight-174 boxWidth-174">
						<div className="flip-card-inner">
							<div className="flip-card-front gradient-color-gray">
								<div className="notify-box-wrapper-bg-image boxHeight-174 boxWidth-174">
									<div className="notify-box">
										<div className="notify-box-title">
											<h1 className="font-18 color-white text-left m-0">{item.pointName}</h1>
											<hr />
										</div>
										<div className="notify-subtitle">
											<h3>Days Left:</h3>
										</div>
										<ul className="date-counter">
											<li>
												<div className="date-counter-title">
													Year
											</div>
												<div className="date-count">
													{item.leftDays[0]}
												</div>
											</li>
											<li>
												<div className="date-counter-title">
													Mon
											</div>
												<div className="date-count">
													{item.leftDays[1]}

												</div>
											</li>
											<li>
												<div className="date-counter-title">
													Day
											</div>
												<div className="date-count">
													{item.leftDays[2]}

												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="flip-card-back boxHeight-174 boxWidth-174 gradient-color-light-puple">
								<div className="notify-box-wrapper-bg-image boxHeight-174 boxWidth-174">
									<div className="notify-box">
										<div className="date-wrapper">
											<div className="date-list">
												<p>Activated On:</p>
												<span>{NetiDateUtils.getAnyShortForm(item.createDate, 'DD-MMM-YYYY')}</span>
											</div>
											<div className="date-list">
												<p>Expire On:</p>
												<span>{NetiDateUtils.getAnyShortForm(item.expireDate, 'DD-MMM-YYYY')}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>)
			});



		}
		const { realimg, resizeImg } = this.state;
		// let tokenHeader = (
		// 	<div className="taken">Token Taken <span>(1)</span></div>
		// );
		let pendingHeader = (
			<div className="pending">Pending <span>{homeDashboardInfo && homeDashboardInfo.tokenSummary && homeDashboardInfo.tokenSummary.pending ? homeDashboardInfo.tokenSummary.pending : ''}</span></div>
		);
		let rejectedHeader = (
			<div className="rejected">Rejected <span>{homeDashboardInfo && homeDashboardInfo.tokenSummary && homeDashboardInfo.tokenSummary.rejected ? homeDashboardInfo.tokenSummary.rejected : ''}</span></div>
		);
		let solvedHeader = (
			<div className="solved">Solved <span>{homeDashboardInfo && homeDashboardInfo.tokenSummary && homeDashboardInfo.tokenSummary.solved ? homeDashboardInfo.tokenSummary.solved : ''}</span></div>
		);
		// return <div className="main-section netiWorld-DashBoard-wrapper">
		//      <h1> Welcome To The Parent Portal</h1>	
		// </div>
			return <div className="main-section netiWorld-DashBoard-wrapper">
			<div className="netiWorld-DashBoard">
				{topProgressBar ?
					<ErrorMessageView
						topProgressBar={topProgressBar}
					/>
					: null
				}
				{errorMsgVisible ?
					<ErrorMessageView
						errorMsgVisible={errorMsgVisible}
						errorMsgBody={errorMsgBody}
					/>
					: null
				}
				<Growl ref={(el) => this.growl = el} />
				<h1> Welcome To The Parent Portal</h1>	
				<div className="p-grid m-0">
					<div className="p-col-12 p-md-12 p-xl-9 p-col-nogutter seprator-inside">
						<div className="p-grid">
							<div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
								<div className="notify-box-wrapper gradient-color-dark-blue">
									<div className="notify-box-wrapper-bg-image boxHeight-284">
										<div className="notify-box clearfix">
											<div className="notify-title-position-l-b">
												<div className="notify-box-title">
													<div className="notify-box-icon wallet-icon"></div>
													<h1 className="font-16 color-white">Wallet Balance</h1>
													<hr />
													<div className="notify-currency">
														<p className="font-20 color-white tk-sign">{homeDashboardInfo && homeDashboardInfo.basicInfo && homeDashboardInfo.basicInfo.userWalletBalance !== undefined ? this.validatorUtility.currencyFormatter(homeDashboardInfo.basicInfo.userWalletBalance) : ''}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
								<div className="notify-box-wrapper gradient-color-light-cyan">
									<div className="notify-box-wrapper-bg-image boxHeight-284">
										<div className="notify-box clearfix">
											<div className="notify-title-position-l-b">
												<div className="notify-box-title">
													<div className="notify-box-icon msg-icon"></div>
													<h1 className="font-16 color-white">Message Balance</h1>
													<hr />
													<div className="notify-currency">
														<p className="font-20 color-white arrow-sign">{homeDashboardInfo && homeDashboardInfo.basicInfo && homeDashboardInfo.basicInfo.smsBalance !== undefined ? this.validatorUtility.currencyFormatter(homeDashboardInfo.basicInfo.smsBalance) : ''}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
								<div className="notify-box-wrapper gradient-color-light-pink">
									<div className="notify-box-wrapper-bg-image boxHeight-284">
										<div className="notify-box clearfix">
											<div className="notify-title-position-l-b">
												<div className="notify-box-title">
													<div className="notify-box-icon user-icon"></div>
													<h1 className="font-16 color-white">User Status</h1>
													<hr />
													<div className="notify-currency">
														<p className="font-20 color-white active-sign">{homeDashboardInfo && homeDashboardInfo.basicInfo && homeDashboardInfo.basicInfo.userEnableStatus === 1 ? "Active" : 'Inactive'}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="p-col-12 p-md-6 p-sm-6 p-lg-3 notify-custom-div-size">
								<div className="notify-box-wrapper gradient-color-light-purple">
									<div className="notify-box-wrapper-bg-image boxHeight-284">
										<div className="notify-box clearfix">
											<div className="notify-title-position-l-b">
												<div className="notify-box-title">
													<div className="notify-box-icon today-icon"></div>
													<h1 className="font-16 color-white">Today</h1>
													<hr />
													<div className="notify-currency">
														<p className="font-20 color-white">0</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="p-grid custom-p-grid">
							<div className="p-col-12 p-md-12 p-sm-12 p-lg-6">
								<div className="panel-box gray-bg">
									<div className="p-grid">
										<div className="p-col-12 p-md-12 p-sm-12 p-lg-4 full-width-block">
											<div className="profile-details">
												<div className="profile-image">
													<img src={this.state.userProfileImage || staticImg} alt="neti-profile-image" name="neti-profile-image" />
												</div>
												<div className="profile-name"><p>{this.state.userFullName}</p></div>
												{/* <div className="profile-designation">Senior Team Lead</div> */}
											</div>
										</div>
										<div className="p-col-12 p-md-12 p-sm-12 p-lg-8 full-width-block">
											<div className="profile-table">
												<table>
													<tbody>
														<tr>
															<td>Neti ID</td>
															<td><p>{homeDashboardInfo && homeDashboardInfo.basicInfo && homeDashboardInfo.basicInfo.customNetiID ? homeDashboardInfo.basicInfo.customNetiID : ''}</p></td>
														</tr>
														<tr>
															<td>Mobile No.</td>
															<td><p>{homeDashboardInfo && homeDashboardInfo.basicInfo && homeDashboardInfo.basicInfo.basicMobile ? homeDashboardInfo.basicInfo.basicMobile : ''}</p></td>
														</tr>
														<tr>
															<td>Email</td>
															<td><p>{homeDashboardInfo && homeDashboardInfo.basicInfo && homeDashboardInfo.basicInfo.basicEmail ? homeDashboardInfo.basicInfo.basicEmail : ''}</p></td>
														</tr>
														<tr>
															<td>Status</td>
															<td><p>{homeDashboardInfo && homeDashboardInfo.basicInfo && homeDashboardInfo.basicInfo.validationStatus === 1 ? "Verified" : 'Unverified'}</p></td>
														</tr>
														<tr>
															<td>Lead Neti ID</td>
															<td><p>{homeDashboardInfo && homeDashboardInfo.basicInfo && homeDashboardInfo.basicInfo.parentCustomNetiID ? homeDashboardInfo.basicInfo.parentCustomNetiID : ''}</p></td>
														</tr>

														<tr>
															<td>Lead Name</td>
															<td><p>{homeDashboardInfo && homeDashboardInfo.basicInfo && homeDashboardInfo.basicInfo.parentName ? homeDashboardInfo.basicInfo.parentName : ''}</p></td>
														</tr>

													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="p-col-12 p-md-12 p-sm-12 p-lg-6">
								<div className="notify-box-wrapper gradient-color-orange">
									<div className="notify-box-wrapper-bg-image boxHeight-400">
										<div className="notify-box clearfix">
											<div className="notify-title-position-l-b">
												<div className="notify-box-title">
													<div className="notify-box-icon callCenter-icon"></div>
													<h1 className="font-16 color-white">Customer Care Helpline</h1>
													<hr />
													<div className="notify-currency">
														<p className="font-20 color-white">Saturday - Thursday | 09:00 AM - 04:00 PM</p>
													</div>
												</div>
												<div className="browser-info inline-block notify-border">
													<p>Call: 09612885522</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="p-grid">
							<div className="p-col-12">
								<div className="panel-box p-l-0 p-r-0">
									<div className="panel-box-header">
										<h1 className="m-0">Assigned Point List</h1>
										<div className="scroll-btn-wrapper">
											<ul>
												<li onClick={(e) => this.scrollLeftBtn(e)}><i className="fas fa-chevron-left"></i></li>
												<li onClick={(e) => this.scrollRightBtn(e)}><i className="fas fa-chevron-right"></i></li>
											</ul>
										</div>
									</div>
									<div className="scrollable-flex-wrapper m-t-20" id="scrollableFlexWrapper">
										{pointAssignedList}
									</div>
								</div>
							</div>
						</div>
						<div className="p-grid">
							<div className="p-col-12">
								<div className="panel-box p-l-0 p-r-0">
									<div className="panel-box-header">
										<h1 className="m-0">Token Information</h1>
									</div>
								</div>
								<div className="panel-box gray-bg">
									<div className="dashBoard-tabs">
										<TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
											{/* <TabPanel header={tokenHeader}>
												<div className="issue-list">
													<ul>
														<li>
															<div className="issue-icon pending-issue pending-text">
																<i className="fas fa-arrow-right"></i>
															</div>
															<div className="issue">
																How do I change my current password?
															</div>
															<div className="issue-status">
																<span>User Point</span>
																<span className="pending-text pending-border">User Point</span>
															</div>
														</li>
														<li>
															<div className="issue-icon pending-issue reject-text">
																<i className="fas fa-arrow-right"></i>
															</div>
															<div className="issue">
																How do I change my current password?
															</div>
															<div className="issue-status">
																<span>User Point</span>
																<span className="reject-text reject-border">User Point</span>
															</div>
														</li>
														<li>
															<div className="issue-icon solved-issue reject-text">
																<i className="fas fa-arrow-right"></i>
															</div>
															<div className="issue">
																How do I change my current password?
															</div>
															<div className="issue-status">
																<span>User Point</span>
																<span className="solved-text solved-border">User Point</span>
															</div>
														</li>
														<li>
															<div className="issue-icon solved-issue reject-text">
																<i className="fas fa-arrow-right"></i>
															</div>
															<div className="issue">
																How do I change my current password?
															</div>
															<div className="issue-status">
																<span>User Point</span>
																<span className="solved-text solved-border">User Point</span>
															</div>
														</li>
													</ul>
												</div>
											</TabPanel> */}
											<TabPanel header={pendingHeader}>
												Content II
    										</TabPanel>
											<TabPanel header={rejectedHeader}>
												Content III
    										</TabPanel>
											<TabPanel header={solvedHeader}>
												Content III
    										</TabPanel>
										</TabView>
									</div>
								</div>
							</div>
						</div>
						<div className="p-grid">
							<div className="p-col-12 p-sm-12 p-md-12 p-lg-6">
								<div className="panel-box p-l-0 p-r-0">
									<div className="gradient-color-dark-purple">
										<div className="schedule-calender notify-box-wrapper-bg-image boxHeight-385">
											<div className="notify-box-title">
												<h1 className="font-16 color-white m-t-0">Schedule Calender</h1>
												<hr />
											</div>
											<FullCalendar ref={(el) => this.fc = el} events={this.state.events} options={this.state.options} />
										</div>
									</div>
								</div>
							</div>
							<div className="p-col-12 p-sm-12 p-md-12 p-lg-6">
								<div className="panel-box p-l-0 p-r-0">
									<div className="advisement-wrapper">
										<div className="gradient-color-dark-blue">
											<div className="notify-box-wrapper-bg-image boxHeight-385">
												<div className="advisement-text">
													<p>PLACE FOR AD</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="p-col-12 p-md-12 p-xl-3 p-col-nogutter">
						<div className="panel-box  gray-bg m-b-20">
							<div className="panel-box-header">
								<h1>Notice Board</h1>
							</div>
							<div className="notice-list">
								<div className="notice notice-success">
									<div className="notice-tooltips">Holiday</div>
									<div className="notice-title"><h3>Ramadan Eid Holidays!</h3></div>
									<div className="notice-text">
										<p>School will be closed from 12th - 28th December due to Ramadan Eid Festival.</p>
									</div>
								</div>
								<div className="notice notice-danger">
									<div className="notice-tooltips">Holiday</div>
									<div className="notice-title"><h3>Ramadan Eid Holidays!</h3></div>
									<div className="notice-text">
										<p>School will be closed from 12th - 28th December due to Ramadan Eid Festival.</p>
									</div>
								</div>
								<div className="notice notice-warning">
									<div className="notice-tooltips">Holiday</div>
									<div className="notice-title"><h3>Ramadan Eid Holidays!</h3></div>
									<div className="notice-text">
										<p>School will be closed from 12th - 28th December due to Ramadan Eid Festival.</p>
									</div>
								</div>
								<div className="notice notice-warning">
									<div className="notice-tooltips">Holiday</div>
									<div className="notice-title"><h3>Ramadan Eid Holidays!</h3></div>
									<div className="notice-text">
										<p>School will be closed from 12th - 28th December due to Ramadan Eid Festival.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="panel-box  gray-bg">
							<div className="panel-box-header">
								<h1>Login Info</h1>
							</div>
							<div className="browser-info-list">
								<div className="browser-info-wrapper">
									<div className="browser-info-title">
										<h3>Last Login at:</h3>
									</div>
									<div className="browser-info">
										<p>Monday, 01-09-2019 at 4:00 PM</p>
									</div>
								</div>
								<div className="browser-info-wrapper">
									<div className="browser-info-title">
										<h3>Last Login at:</h3>
									</div>
									<div className="browser-info">
										<p>Monday, 01-09-2019 at 4:00 PM</p>
									</div>
								</div>
								<div className="browser-info-wrapper">
									<div className="browser-info-table">
										<table>
											<tr>
												<td>Action Taken:</td>
												<td className="tootips"><p>05</p></td>
											</tr>
											<tr>
												<td>Page Access:</td>
												<td className="tootips"><p>05</p></td>
											</tr>
											<tr>
												<td>Module Access:</td>
												<td className="tootips"><p>05</p></td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}