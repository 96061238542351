import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import NetiContentLoader from '../../common/NetiContentLoader';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Dropdown } from "primereact/dropdown";
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import { Link } from 'react-router-dom';
import { UserOtherAccessService } from "../../../service/UserOtherAccessService";

let errors = {}
let urlId;

export class PendingTransferList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            sectionDropDownList: [],
            sectionID: '',
            lastRollNo: '',
            groupID: '',
            academicYr: '',
            categoryID: '',
            totalDataFound: 0,
            edumanInstituteInfo: {},
            academicYrListOption: [],
            groupDropDownList: [],
            categoryDropDownList: [],
            homeReturnButton: true,
            instituteMapReturnButton: true,
            rejectedStutas: "Custom Status",
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            mappedInstituteList: [],
            errorMsgBody: null,
            dataTableSelection: null,
            dataViewValue: [],
            errors: {},
            dataTableValue: props.componentNameData
        };

        this.NetiContentLoader = new NetiContentLoader();
        this.admisiaPointService = new AdmisiaPointService();
        this.userOtherAccessService = new UserOtherAccessService();
        this.reloadCoreUrlIdFromLocal();
    }


    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentDidMount();
            }, 1000);
        }
    }

    componentDidMount = () => {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();

        if (urlId) {
            this.setState({ homeReturnButton: false })
        }
        else {
            this.setState({ homeReturnButton: true })

        }
    }

    viewMappedInstituteInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });

        this.admisiaPointService.getURLMappedInstituteList(urlId)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("mapped list", body);
                        if (body.length !== 0) {
                            this.setState({ instituteMapReturnButton: false });
                            for (let i = 0; i < body.length; i++) {
                                if (body[i].approvedStatus === 0) {
                                    body[i].approvedStatus = 'Pending';
                                }
                                else if (body[i].approvedStatus === 1) {
                                    body[i].approvedStatus = 'Approved';
                                }
                                else if (body[i].approvedStatus === 2) {
                                    body[i].approvedStatus = 'Rejected';
                                }
                            }

                            for (let j = 0; j < body.length; j++) {
                                if (body.length !== 0) {
                                    this.setState({ edumanInstituteInfo: body[0] });
                                }

                            }
                            this.getYearGroupCategoryByTypeID('2101');
                            this.getYearGroupCategoryByTypeID('2105');
                            this.getYearGroupCategoryByTypeID('2106');
                            this.getEdumanSectionList();
                            this.setState({ mappedInstituteList: body, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                        } else {
                            console.log("mapped empty", body);
                            this.setState({ mappedInstituteList: [], dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                        }
                        this.setState({ mappedInstituteList: body, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                    });


                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, dataTableIsLoading: false, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }

    dateFormat = (rowData) => {
        return <span>{NetiDateUtils.getAnyShortForm(rowData['admisiaApplicantInfoDTO.dateOfBirth'], 'DD-MMM-YYYY')}</span>;
    }

    onTransferPendingApplicant = () => {
        let { dataTableSelection } = this.state

        if (!dataTableSelection) {
            errors['submitApproveError'] = "Please select at least one ROW"
        } else {
            errors['submitApproveError'] = ""
            this.setState({ visible: true });
            this.viewMappedInstituteInfoList();
        }
    }

    // getUpdateObj(updateStatus) {
    //     let { dataTableSelection } = this.state

    //     let markMapValue = []
    //     if (dataTableSelection && dataTableSelection.length) {
    //         markMapValue = dataTableSelection.map((item) => ({
    //             admisiaApplicantInfoId: item.admisiaApplicantInfoDTO
    //         }));
    //     }
    //     let approveAssessmentObj = {
    //         "applicantInfoDTOList": markMapValue,
    //         "status": updateStatus
    //     }


    //     return approveAssessmentObj
    // }

    onHide = () => {
        this.setState({ visible: false })
    }

    onChangeAcademicYr = (e) => {
        this.setState({ academicYr: e.target.value })
    }

    onChangeSection = (e) => {
        this.setState({ sectionID: e.target.value })
    }

    onChangeGroup = (e) => {
        this.setState({ groupID: e.target.value })
    }

    onChangeCategory = (e) => {
        this.setState({ categoryID: e.target.value })
    }

    countTableSize = () => {
        const { dataTableSelection } = this.state;
        return dataTableSelection.length;
    }

    submitProcess = () => {
        let { edumanInstituteInfo, dataTableSelection } = this.state;
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.admisiaPointService.getMaxRollByClassConfigId(this.state.academicYr, this.state.sectionID, edumanInstituteInfo.edumanDetailsInfoDTO.instituteId)
            .then(res => {
                if (res.status === 200) {
                    return res.json().then((body) => {
                        console.log("process body", body);

                        if (dataTableSelection && dataTableSelection.length) {
                            dataTableSelection.map((item) => {
                                item.admisiaApplicantInfoDTO.studentRoll = 1 + body++;
                            }
                            );
                        }

                        this.setState({ lastRollNo: body, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });


    }

    getYearGroupCategoryByTypeID = (typeID) => {
        let { edumanInstituteInfo } = this.state;
        this.setState({ topProgressBar: true, errorMsgVisible: false });

        this.userOtherAccessService.findEdumanYearGroupCategoryByTypeID(edumanInstituteInfo.edumanDetailsInfoDTO.instituteId, typeID)
            .then(res => {
                if (res.status == 200) {
                    return res.json().then((body) => {
                        console.log("category body", body);
                        if (typeID === '2101') {
                            this.setState({ academicYrListOption: body.item });
                        }
                        else if (typeID === '2105') {
                            this.setState({ groupDropDownList: body.item });
                        }
                        else if (typeID === '2106') {
                            this.setState({ categoryDropDownList: body.item });
                        }
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.userOtherAccessService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, applicantFormVisibility: false })
                        });
                }

            }).catch(error => {
                // errors["dropDownClassError"] = 'Connection Problem'
                // this.setState({ errors: errors })
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    getEdumanSectionList = () => {
        let { edumanInstituteInfo } = this.state;
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        console.log("section api value", edumanInstituteInfo.edumanDetailsInfoDTO.instituteId);

        this.userOtherAccessService.findEdumanSectionsByInstituteID(edumanInstituteInfo.edumanDetailsInfoDTO.instituteId)
            .then(res => {
                if (res.status == 200) {
                    console.log("section api res", res);
                    return res.json().then((body) => {
                        console.log("section body", body);
                        this.setState({ sectionDropDownList: body });
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.userOtherAccessService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, applicantFormVisibility: false })
                        });
                }

            }).catch(error => {
                // errors["dropDownClassError"] = 'Connection Problem'
                // this.setState({ errors: errors })
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    getConnectToUpdateStatusApi = () => {
        let { dataTableSelection, edumanInstituteInfo } = this.state

        if (!dataTableSelection) {
            errors['submitApproveError'] = "Please select at least one ROW"
        }
        else {
            let applicantTransferObj = {};
            let studentRequestHelpers = [];
            if (dataTableSelection && dataTableSelection.length) {
                applicantTransferObj = dataTableSelection.map((item) => {
                    studentRequestHelpers.push(Object.assign({},
                        {
                            fatherName: item.admisiaApplicantInfoDTO.fatherName,
                            guardianMobile: item.admisiaApplicantInfoDTO.mobileNo,
                            identificationId: item.admisiaApplicantInfoDTO.admisiaApplicantInfoId,
                            imageName: item.admisiaApplicantInfoDTO.photoName,
                            motherName: item.admisiaApplicantInfoDTO.motherName,
                            studentDOB: Date.parse(item.admisiaApplicantInfoDTO.dateOfBirth),
                            studentGender: item.admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName,
                            studentName: item.admisiaApplicantInfoDTO.applicantName,
                            studentReligion: item.admisiaApplicantInfoDTO.religionCoreCategoryInfoDTO.categoryName,
                            studentRoll: item.admisiaApplicantInfoDTO.studentRoll
                        }
                    ));
                }
                );

            }

            let applicantTransferListObj = {
                academicYear: this.state.academicYr,
                studentCategoryId: this.state.categoryID,
                classConfigId: this.state.sectionID,
                groupId: this.state.groupID,
                smsSendingStatus: false,
                studentRequestHelpers: studentRequestHelpers

            }


            this.setState({ topProgressBar: true, errorMsgVisible: false });
            console.log("applicantTransferListObj obj", applicantTransferListObj);
            this.admisiaPointService.applicantTransferToEduman(edumanInstituteInfo.urlMappingID, applicantTransferListObj)
                .then(res => {
                    if (res.status === 202) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: "Applicant transferred successfully" });
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        setTimeout(() => {
                            this.props.reCallClassGroupSearch();
                        }, 800);
                        studentRequestHelpers = [];

                    }
                    else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                studentRequestHelpers = [];
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
                });
        }
    }

    displaySelection(data) {
        if (!data || data.length === 0) {
            return <div style={{ textAlign: 'left' }}>No Selection</div>;
        }
        else {
            errors['submitApproveError'] = ""
        }
    }

    onSaveApplicantTransferToEm = () => {
        this.getConnectToUpdateStatusApi();
    }

    // onCountTableSize = (l) => {
    //     console.log("table size",l);
    //     this.setState({totalDataFound: l});

    // }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { mappedInstituteList, edumanInstituteInfo } = this.state;

        let academicYrOption = [];
        if (this.state.academicYrListOption != null && this.state.academicYrListOption.length) {
            academicYrOption = this.state.academicYrListOption.map((item) => ({
                value: item.name,
                label: item.name,
            }));
        }

        let groupList = [];
        if (this.state.groupDropDownList != null && this.state.groupDropDownList.length) {
            groupList = this.state.groupDropDownList.map((item) => ({
                value: item.id,
                label: item.name,
            }));
        }

        let categoryList = [];
        if (this.state.categoryDropDownList != null && this.state.categoryDropDownList.length) {
            categoryList = this.state.categoryDropDownList.map((item) => ({
                value: item.id,
                label: item.name,
            }));
        }

        let sectionList = [];
        if (this.state.sectionDropDownList != null && this.state.sectionDropDownList.length) {
            sectionList = this.state.sectionDropDownList.map((item) => ({
                value: item.classConfigId,
                label: item.classShiftSection,
            }));
        }

        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Pending For Transfer Applicant List
                        <span
                style={{ color: "white", display: "flex", float: "right" }}
            >
                Total Found: {this.state.dataTableValue.length}
            </span>
        </div>;

        let applicantTransferHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Applicant Registration Information (Transfer to Eduman)
            <span
                style={{ color: "white", display: "flex", float: "right" }}
            >
                Total Found: {this.state.dataTableSelection === null ? '' : this.state.dataTableSelection.length}
            </span>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    {this.state.homeReturnButton === false ?

                        <div>
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-data-table nw-data-table-tabview">

                                    <DataTable
                                        value={this.state.dataTableValue}
                                        header={tableHeader}
                                        selection={this.state.dataTableSelection}
                                        onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                    // footer={this.displaySelection(this.state.dataTableSelection)}
                                    >
                                        <Column selectionMode="multiple" style={{ width: '4em' }} />
                                        <Column field="admisiaApplicantInfoDTO.registrationID" header="Registration No." filter={true} />
                                        <Column field="admisiaApplicantInfoDTO.applicantName" header="Name" filter={true} />
                                        <Column field="admisiaApplicantInfoDTO.dateOfBirth" header="Date Of Birth" filter={true} />
                                        <Column field="admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName" header="Gender" filter={true} />
                                        <Column field="admisiaApplicantInfoDTO.religionCoreCategoryInfoDTO.categoryName" header="Religion" filter={true} />
                                        <Column field="admisiaApplicantInfoDTO.mobileNo" header="Mobile No." filter={true} />
                                        <Column field="admisiaApplicantInfoDTO.fatherName" header="Father's Name" filter={true} />
                                        <Column field="admisiaApplicantInfoDTO.motherName" header="Mother's Name" filter={true} />
                                        <Column field="admisiaApplicantInfoDTO.examMark" header="Mark" filter={true} />
                                    </DataTable>
                                    <span className="error-message">{errors['submitApproveError']}</span>


                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <Button
                                        label="Transfer"
                                        icon="fas fa-arrow-right"
                                        iconPos="right"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        onClick={this.onTransferPendingApplicant}
                                    />

                                </div>

                                <div className="dialog-section">
                                    <Dialog className="nw-dialog"
                                        header="Applicant Selection Information "
                                        visible={this.state.visible}
                                        onHide={this.onHide}
                                        maximizable
                                        style={{ width: "70vw" }}
                                    >
                                        {this.state.instituteMapReturnButton === false ?

                                            <div className="p-fluid">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-grid nw-form">
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="nw-form-body">
                                                                    {this.state.visible == true ?
                                                                        <div className="p-grid">
                                                                            {this.state.mappedInstituteList.length !== 0 ?
                                                                                <div>


                                                                                    <div className="p-col-12">
                                                                                        <div className="formControl">
                                                                                            <div className="p-inputgroup">
                                                                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                                                    Institute Name
                                                    </span>
                                                                                                <div className="nw-inputgroup-desc">
                                                                                                    {edumanInstituteInfo && edumanInstituteInfo.edumanDetailsInfoDTO ? edumanInstituteInfo.edumanDetailsInfoDTO.instituteName : ''}

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="p-col-12">
                                                                                        <div className="formControl">
                                                                                            <div className="p-inputgroup">
                                                                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                                                    Institute ID
                                                    </span>
                                                                                                <div className="nw-inputgroup-desc">
                                                                                                    {edumanInstituteInfo && edumanInstituteInfo.edumanDetailsInfoDTO ? edumanInstituteInfo.edumanDetailsInfoDTO.instituteId : ''}

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                :
                                                                                ''
                                                                            }



                                                                            <div className="p-col-12">
                                                                                <div className="formControl">
                                                                                    <label>Academic Year</label>
                                                                                    <Dropdown
                                                                                        placeholder='Select Academic Year'
                                                                                        name="academicYr"
                                                                                        options={academicYrOption}
                                                                                        value={this.state.academicYr}
                                                                                        onChange={this.onChangeAcademicYr}
                                                                                        filter={true}
                                                                                        autoWidth={false}
                                                                                    />
                                                                                    <span className='error-message'>{this.state.errors["academicYr"]}</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="p-col-12">
                                                                                <div className="formControl">
                                                                                    <label>Section</label>
                                                                                    <Dropdown
                                                                                        placeholder='Select Section Name'
                                                                                        name="section"
                                                                                        options={sectionList}
                                                                                        value={this.state.sectionID}
                                                                                        onChange={this.onChangeSection}
                                                                                        filter={true}
                                                                                        autoWidth={false}
                                                                                    />
                                                                                    <span className='error-message'>{this.state.errors["section"]}</span>
                                                                                </div>

                                                                            </div>

                                                                            <div className="p-col-12">
                                                                                <div className="formControl">
                                                                                    <label>Group</label>
                                                                                    <Dropdown
                                                                                        placeholder='Select Group'
                                                                                        name="group"
                                                                                        options={groupList}
                                                                                        value={this.state.groupID}
                                                                                        onChange={this.onChangeGroup}
                                                                                        filter={true}
                                                                                        autoWidth={false}
                                                                                    />
                                                                                    <span className='error-message'>{this.state.errors["group"]}</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="p-col-12">
                                                                                <div className="formControl">
                                                                                    <label>Category</label>
                                                                                    <Dropdown
                                                                                        placeholder='Select Category'
                                                                                        value={this.state.categoryID}
                                                                                        name="category"
                                                                                        options={categoryList}
                                                                                        onChange={this.onChangeCategory}
                                                                                        filter={true}
                                                                                        autoWidth={false}

                                                                                    />
                                                                                    <span className='error-message'>{this.state.errors["category"]}</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                                                <Button
                                                                                    className="p-button p-button-danger nw-button nw-button-multiple"
                                                                                    label="Discard"
                                                                                    icon="fas fa-times"
                                                                                    onClick={event => this.onHide(event)}
                                                                                />
                                                                                <Button
                                                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                                                    label="Process"
                                                                                    icon="far fa-save"
                                                                                    onClick={this.submitProcess}
                                                                                />
                                                                            </div>




                                                                            <div className="nw-data-table nw-data-table-tabview">

                                                                                <DataTable
                                                                                    value={this.state.dataTableSelection}
                                                                                    header={applicantTransferHeader}
                                                                                    selection={this.state.dataTableSelection}
                                                                                    onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                                                    responsive={true}
                                                                                    paginator={true}
                                                                                    rows={10}
                                                                                // footer={this.displaySelection(this.state.dataTableSelection)}
                                                                                >
                                                                                    <Column selectionMode="multiple" style={{ width: '4em' }} />
                                                                                    <Column field="admisiaApplicantInfoDTO.studentRoll" header="Roll No." filter={true} />
                                                                                    <Column field="admisiaApplicantInfoDTO.registrationID" header="Registration No." filter={true} />
                                                                                    <Column field="admisiaApplicantInfoDTO.applicantName" header="Name" filter={true} />
                                                                                    <Column field="admisiaApplicantInfoDTO.dateOfBirth" header="Date Of Birth" body={this.dateFormat} filter={true} />
                                                                                    <Column field="admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName" header="Gender" filter={true} />
                                                                                    <Column field="admisiaApplicantInfoDTO.religionCoreCategoryInfoDTO.categoryName" header="Religion" filter={true} />
                                                                                    <Column field="admisiaApplicantInfoDTO.mobileNo" header="Mobile No." filter={true} />
                                                                                    <Column field="admisiaApplicantInfoDTO.fatherName" header="Father's Name" filter={true} />
                                                                                    <Column field="admisiaApplicantInfoDTO.motherName" header="Mother's Name" filter={true} />
                                                                                    <Column field="admisiaApplicantInfoDTO.examMark" header="Mark" filter={true} />
                                                                                </DataTable>
                                                                                <span className="error-message">{errors['submitApproveError']}</span>


                                                                            </div>
                                                                            <div className="p-col-12 p-xl-12 nw-button-parent-multiple">

                                                                                <Button
                                                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                                                    label="Save"
                                                                                    icon="far fa-save"
                                                                                    onClick={this.onSaveApplicantTransferToEm}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="">
                                                <div className="p-grid">
                                                    <div className="card p-col-12 p-xl-3">
                                                        <center className="custom-center">
                                                            <Link
                                                                to="/admisia_map_institute"
                                                                className="rainbow-button"
                                                                alt="Go Admisia Map Institute"
                                                            />
                                                        </center>
                                                    </div>
                                                    <div className="card p-col-12 p-xl-3">
                                                        <center className="custom-center">
                                                            <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                                        </center>
                                                    </div>
                                                    <div className="card p-col-12 p-xl-6">
                                                        <img src="assets/images/dws_home.png" width="100%" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Dialog>
                                </div>


                            </div>
                        </div>
                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/institute_config"
                                            className="rainbow-button"
                                            alt="Go Admisia Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        );
    }
}