import { EM_USER_POINT } from './PointWiseRouteConsts';

export const EmUserPointMenu = {

    items: [
        
        {label: 'User Point', icon: 'fas fa-users',

            items: [
                { label: 'Settings', icon: '',
                items: [
                    { label: 'Add Portal', icon: '', to: EM_USER_POINT.ADD_PORTAL}, //pi pi-fw pi-money-bill
                ] 
            
            },
            //     { label: 'Add Portal', icon: '',
            //     items: [
            //         { label: 'Student', icon: '', to: EM_USER_POINT.ADD_STUDENT_PORTAL}, //pi pi-fw pi-money-bill
            //         { label: 'Guardian', icon: '', to: EM_USER_POINT.ADD_GUARDIAN_PORTAL}, //pi pi-fw pi-envelope
            //         { label: 'Employee', icon: '', to: EM_USER_POINT.ADD_EMPLOYEE_PORTAL}, //pi pi-fw pi-envelope
            //     ] 
            
            // }, 
            { label: 'Go to Portal', icon: '',
            items: [
                { label: 'Student', icon: '', to: EM_USER_POINT.STUDENT_LIST_INFO}, //pi pi-fw pi-money-bill
                { label: 'Guardian', icon: '', to: EM_USER_POINT.GUARDIAN_LIST_INFO}, //pi pi-fw pi-envelope
                { label: 'Teacher', icon: '', to: EM_USER_POINT.TEACHER_LIST_INFO}, //pi pi-fw pi-envelope
            ] 
        
        },
                // pi pi-fw pi-user
                // { label: 'Go to Portal', icon: '', to: EM_USER_POINT.GO_TO_PORTAL } // pi pi-fw pi-arrow-right
            ]
        }
    ]
}