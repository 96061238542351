import React, { Component } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

export class SoftwareDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getDownloadContent(linkAd) {
    setTimeout(() => {
      const response = {
        file: linkAd
      };
      window.location.href = response.file;
    }, 100);
  }

  render() {
    
    let msOffice =
      "https://drive.google.com/open?id=12gqd7jOYoKej1T7RDbz34o7XoptQBGcj";
    let yssV =
      "http://www.mediafire.com/file/4dmgmrlrlubvco8/YSS%2528v1.0.4%2529.msi/file";
    let yssTutorial =
      "http://www.mediafire.com/file/oe8tp7j9movhrom/YSS_Setup_Tutorial.pptx/file";
    let attender14Apps =
      "http://www.mediafire.com/file/34n156wysfl3tep/Attender1.4.zip/file";
    let zkt5 =
      "http://www.mediafire.com/file/yr8b8s6jewj6ixj/ZKTime5.0.zip/file";
    let dotNet45 =
      "http://www.mediafire.com/file/ab7lbjrj9qwcq7c/dotnetfx45_full_x86_x64.exe/file";
    let dotNet46 =
      "http://www.mediafire.com/file/8dbyy0otak985a8/dotnetfx46_full_x86_x64.exe/file";
    let aeroAdmin =
      "http://www.mediafire.com/file/7n8co76ciqi76uu/AeroAdmin.exe/file";
    let anyDesk =
      "http://www.mediafire.com/file/ai0srtxd1sgvs1w/AnyDesk.exe/file";

    return (
      <div className="layout-dashboard">
        
        <div className="p-fluid">
          <div className="main-section download">

            <div className="p-grid download-software">
              <div className="p-col-12 p-xl-6">
                <div class="description">
                    <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">

                      <div class="p-col-3 download-img">
                          <div class="img-div">
                              <img 
                                className="img-size"
                                alt="Card" 
                                src="assets/images/softwareIcons/Office_2013.png" 
                              />
                          </div>
                      </div>
                        
                      <div class="p-col-7">
                          <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                              <label>Microsoft Office</label>
                              <label>File Size: 706.00 MB</label>
                              <label>UPLOADED ON: 22, August 2019</label>
                          </div>
                      </div>

                      <div class="p-col-2 ui-g-nopad">
                              <Button 
                                className="" 
                                icon="fas fa-download"
                                onClick={this.getDownloadContent.bind(this, msOffice)}
                              />
                      </div>
                    </div>
                </div>
              </div>

              <div className="p-col-12 p-xl-6">
                <div class="description">
                    <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">

                      <div class="p-col-3 download-img">
                          <div class="img-div">
                              <img 
                                className="img-size"
                                alt="Card" 
                                src="assets/images/softwareIcons/yss_1.0.1.png" 
                              />
                          </div>
                      </div>
                        
                      <div class="p-col-7">
                          <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                              <label>YSS 1.0.1</label>
                              <label>File Size: 6.40 MB</label>
                              <label>UPLOADED ON: 22, August 2019</label>
                          </div>
                      </div>

                      <div class="p-col-2 ui-g-nopad">
                              <Button 
                                className="" 
                                icon="fas fa-download"
                                onClick={this.getDownloadContent.bind(this, yssV)}
                              />
                      </div>
                    </div>
                </div>
              </div>

              <div className="p-col-12 p-xl-6">
                <div class="description">
                    <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">

                      <div class="p-col-3 download-img">
                          <div class="img-div">
                              <img 
                                className="img-size"
                                alt="Card" 
                                src="assets/images/softwareIcons/yss_tutorial.png" 
                              />
                          </div>
                      </div>
                        
                      <div class="p-col-7">
                          <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                              <label>YSS Tutorial</label>
                              <label>File Size: 0.37 MB</label>
                              <label>UPLOADED ON: 22, August 2019</label>
                          </div>
                      </div>

                      <div class="p-col-2 ui-g-nopad">
                              <Button 
                                className="" 
                                icon="fas fa-download"
                                onClick={this.getDownloadContent.bind(this, yssTutorial)}
                              />
                      </div>
                    </div>
                </div>
              </div>

              <div className="p-col-12 p-xl-6">
                <div class="description">
                    <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">

                      <div class="p-col-3 download-img">
                          <div class="img-div">
                              <img 
                                className="img-size"
                                alt="Card" 
                                src="assets/images/softwareIcons/yss_tutorial.png" 
                              />
                          </div>
                      </div>
                        
                      <div class="p-col-7">
                          <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                              <label>Attender Apps 1.4</label>
                              <label>File Size: 1.26 MB</label>
                              <label>UPLOADED ON: 22, August 2019</label>
                          </div>
                      </div>

                      <div class="p-col-2 ui-g-nopad">
                              <Button 
                                className="" 
                                icon="fas fa-download"
                                onClick={this.getDownloadContent.bind(this, attender14Apps)}
                              />
                      </div>
                    </div>
                </div>
              </div>

              <div className="p-col-12 p-xl-6">
                <div class="description">
                    <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">

                      <div class="p-col-3 download-img">
                          <div class="img-div">
                              <img 
                                className="img-size"
                                alt="Card" 
                                src="assets/images/softwareIcons/ZKT_5.0.png" 
                              />
                          </div>
                      </div>
                        
                      <div class="p-col-7">
                          <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                              <label>ZKTeco 5.0</label>
                              <label>File Size: 40.10 MB</label>
                              <label>UPLOADED ON: 22, August 2019</label>
                          </div>
                      </div>

                      <div class="p-col-2 ui-g-nopad">
                              <Button 
                                className="" 
                                icon="fas fa-download"
                                onClick={this.getDownloadContent.bind(this, zkt5)}
                              />
                      </div>
                    </div>
                </div>
              </div>

              <div className="p-col-12 p-xl-6">
                <div class="description">
                    <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">

                      <div class="p-col-3 download-img">
                          <div class="img-div">
                              <img 
                                className="img-size"
                                alt="Card" 
                                src="assets/images/softwareIcons/dot_net_4.5.png" 
                              />
                          </div>
                      </div>
                        
                      <div class="p-col-7">
                          <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                              <label>.Net Framework 4.5</label>
                              <label>File Size: 48.00 MB</label>
                              <label>UPLOADED ON: 22, August 2019</label>
                          </div>
                      </div>

                      <div class="p-col-2 ui-g-nopad">
                              <Button 
                                className="" 
                                icon="fas fa-download"
                                onClick={this.getDownloadContent.bind(this, dotNet45)}
                              />
                      </div>
                    </div>
                </div>
              </div>

              <div className="p-col-12 p-xl-6">
                <div class="description">
                    <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">

                      <div class="p-col-3 download-img">
                          <div class="img-div">
                              <img 
                                className="img-size"
                                alt="Card" 
                                src="assets/images/softwareIcons/dot_net_4.6.png" 
                              />
                          </div>
                      </div>
                        
                      <div class="p-col-7">
                          <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                              <label>.Net Framework 4.6</label>
                              <label>File Size: 62.40 MB</label>
                              <label>UPLOADED ON: 22, August 2019</label>
                          </div>
                      </div>

                      <div class="p-col-2 ui-g-nopad">
                              <Button 
                                className="" 
                                icon="fas fa-download"
                                onClick={this.getDownloadContent.bind(this, dotNet46)}
                              />
                      </div>
                    </div>
                </div>
              </div>

              <div className="p-col-12 p-xl-6">
                <div class="description">
                    <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">

                      <div class="p-col-3 download-img">
                          <div class="img-div">
                              <img 
                                className="img-size"
                                alt="Card" 
                                src="assets/images/softwareIcons/aero_admin.png" 
                              />
                          </div>
                      </div>
                        
                      <div class="p-col-7">
                          <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                              <label>Aero Admin</label>
                              <label>File Size: 1.99 MB</label>
                              <label>UPLOADED ON: 22, August 2019</label>
                          </div>
                      </div>

                      <div class="p-col-2 ui-g-nopad">
                              <Button 
                                className="" 
                                icon="fas fa-download"
                                onClick={this.getDownloadContent.bind(this, aeroAdmin)}
                              />
                      </div>
                    </div>
                </div>
              </div>

              <div className="p-col-12 p-xl-6">
                <div class="description">
                    <div class="p-col-12 p-xl-12 ui-g-nopad description-inside">

                      <div class="p-col-3 download-img">
                          <div class="img-div">
                              <img 
                                className="img-size"
                                alt="Card" 
                                src="assets/images/softwareIcons/AnyDesk-Official-Logo.png" 
                              />
                          </div>
                      </div>
                        
                      <div class="p-col-7">
                          <div class="ui-g-12 ui-lg-12 ui-g-nopad download-details">
                              <label>Any Desk</label>
                              <label>File Size: 2.00 MB</label>
                              <label>UPLOADED ON: 22, August 2019</label>
                          </div>
                      </div>

                      <div class="p-col-2 ui-g-nopad">
                              <Button 
                                className="" 
                                icon="fas fa-download"
                                onClick={this.getDownloadContent.bind(this, anyDesk)}
                              />
                      </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    );
  }
}
