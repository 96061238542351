import React, { Component } from "react";
import { Panel } from "primereact/panel";
import {  DataViewLayoutOptions } from "primereact/dataview";
import { AdminPointService } from "../../../service/adminPoint/AdminPointService";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { NetiDateUtils } from "../../../utils/NetiDateUtils";
import { DataTable } from "primereact/datatable";
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';
import { AdminPointUpdateTutorial } from './AdminPointUpdateTutorial';
import CommonFuctionality from "../../common/CommonFuctionality";
import { Growl } from 'primereact/growl';
import NetiContentLoader from '../../common/NetiContentLoader';

export class AdminPointViewTutorial extends Component {
  constructor() {
    super();
    this.state = {
      AllTutorialInfoList: [],
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: "",
      layout: "list"
    };
    this.adminPointService = new AdminPointService();
    this.CommonFuctionality = new CommonFuctionality();
    this.NetiContentLoader = new NetiContentLoader();
  }

  componentDidMount = () => {
    this.viewAllTutorialInfoList();
  };

  viewAllTutorialInfoList = () => {
    let { AllTutorialInfoList } = this.state;
    this.setState({ dataTableIsLoading:true, topProgressBar: true });
    this.adminPointService
      .getAllTutorialDetails()
      .then(res => {
        this.setState({ dataTableIsLoading:false, topProgressBar: false });
        if (res.status == 302) {
          return res.json().then(body => {
            this.setState({ AllTutorialInfoList: body });
          });
        } else {
          this.adminPointService.Auth.handleErrorStatus(res).then(
            responseBody => {
              this.setState({
                dataTableIsLoading:false, 
                errorMsgVisible: true,
                errorMsgBody: responseBody
              });
            }
          );
        }
      })
      .catch(error => {
        this.setState({dataTableIsLoading:false, errorMsgVisible: true, errorMsgBody: "Please check your connection"});
      });
  };

  renderListItem = tutorialViewListItem => {

    if(!tutorialViewListItem){
      return 'test'
    }
    else{
      return (
        <div>


          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">Create Date</span>
              <div className="nw-inputgroup-desc">
                { NetiDateUtils.getAnyShortForm(tutorialViewListItem.createDate || '---',"DD/MM/YYYY")}
              </div>
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">Default Code</span>
              <div className="nw-inputgroup-desc">
                {tutorialViewListItem.defaultCode || '---'}
              </div>
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">Breadcrumb Bangla</span>
              <div className="nw-inputgroup-desc">
                {tutorialViewListItem.breadcrumbBangla || '---'}
              </div>
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">Breadcrumb English</span>
              <div className="nw-inputgroup-desc">
                {tutorialViewListItem.breadcrumbEnglish || '---'}
              </div>
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">Title Bangla</span>
              <div className="nw-inputgroup-desc">
                {tutorialViewListItem.titleBangla || '---'}
              </div>
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">Title English</span>
              <div className="nw-inputgroup-desc">
                {tutorialViewListItem.titleEnglish || '---'}
              </div>
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">Youtube Link Bangla</span>
              <div className="nw-inputgroup-desc">
                <a href={tutorialViewListItem.youtubeLinkBangla || '#' } target="_blank">{tutorialViewListItem.youtubeLinkBangla || '---'}</a>
              </div>
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">Youtube Link English</span>
              <div className="nw-inputgroup-desc">
                <a href={tutorialViewListItem.youtubeLinkEnglish || '#' } target="_blank">{tutorialViewListItem.youtubeLinkEnglish || '---'}</a>
              </div>
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon nw-inputtexteditor-label">Intro Bangla</span>
              <Editor
                  value={tutorialViewListItem.introBangla}
                  className="nw-inputtexteditor"
                  readOnly={true}
              />
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon nw-inputtexteditor-label">Intro English</span>
              <Editor
                  value={tutorialViewListItem.introEnglish}
                  className="nw-inputtexteditor"
                  readOnly={true}
              />
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon nw-inputtexteditor-label">Instruction Bangla</span>
              <Editor
                  value={tutorialViewListItem.instructionBangla}
                  className="nw-inputtexteditor"
                  readOnly={true}
              />
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon nw-inputtexteditor-label">Instruction English</span>
              <Editor
                  value={tutorialViewListItem.instructionEnglish}
                  className="nw-inputtexteditor"
                  readOnly={true}
              />
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon nw-inputtexteditor-label">Note Bangla</span>
              <Editor
                  value={tutorialViewListItem.noteBangla}
                  className="nw-inputtexteditor"
                  readOnly={true}
              />
            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon nw-inputtexteditor-label">Note English</span>
              <Editor
                  value={tutorialViewListItem.noteEnglish}
                  className="nw-inputtexteditor"
                  readOnly={true}
              />
            </div>
          </div>

          {/* <div className="p-col-12 p-xl-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon nw-inputtexteditor-label">Note Bangla <span>*</span></span>
              <Editor
                  value={tutorialViewListItem.noteEnglish}
                  className="nw-inputtexteditor"
                  readOnly={true}
              />
            </div>
          </div> */}


        </div>
      );
    }
  };

  renderGridItem = tutorialViewGridItem => {
    return (

      // <div className="p-grid">
      
        <div className="p-col-12 p-xl-4">
          <Panel
            header={tutorialViewGridItem.breadcrumbBangla}
            style={{ textAlign: "center" }}
          >
            <div className="car-detail">
              {tutorialViewGridItem.breadcrumbBangla}
            </div>
            <div className="car-detail">
              {tutorialViewGridItem.breadcrumbEnglish}
            </div>
            <div className="car-detail">
              {NetiDateUtils.getAnyShortForm(tutorialViewGridItem.createDate)}
            </div>
            <hr className="ui-widget-content" style={{ borderTop: 0 }} />
          </Panel>
        </div>
        
      // </div>
    );
  };

  itemTemplate = (car, layout) => {
    if (!car) {
      return null;
    }

    if (layout === "list") return this.renderListItem(car);
    else if (layout === "grid") return this.renderGridItem(car);
  };

  renderHeader = () => {
    return (
      // <div className="p-grid">
      //   <div className="p-col-6" style={{ textAlign: "right" }}>
          <DataViewLayoutOptions
            layout={this.state.layout}
            onChange={e => this.setState({ layout: e.value })}
          />
      //   </div>
      // </div>
    );
  };

  actionBody = (rowData) => {
    return <div className='text-center'>
      <Button
        className="nw-action-button edit"
        icon="fas fa-edit"
        title="Edit"
        onClick={(e) => this.updateTutorialDetails(rowData)}
      />

      <Button
        className="nw-action-button edit"
        icon="fas fa-eye"
        title="View"
        onClick={(e) => this.viewTutorialDetails(rowData)}
      />
    </div>
  }

  menuItemBody = (rowData) => {
    // let activeHomePage = "circle-blue", activeGallery = "circle-green";

    // if (rowData.photoType === "Home Page") {
    //   activeHomePage = "circle-blue"
    // } else if (rowData.photoType === "gallery") {
    //   activeGallery = "circle-green"
    // }
    // return (
    //   <div className="input-radio-button">
    //     <div className="p-col-12 p-col-nogutter">
    //       <div class={activeHomePage}>E</div><label htmlFor="rb1" className="p-radiobutton-label">Home Page</label>
    //     </div>

    //     <div className="p-col-12 p-col-nogutter">
    //       <div class={activeGallery}>B</div><label htmlFor="rb1" className="p-radiobutton-label">Gallery</label>
    //     </div>
    //   </div>
    // )
  }

  breadcrumbBody = (rowData) => {
    let activeHomePage = "circle-blue", activeGallery = "circle-green";

    if (rowData.photoType === "Home Page") {
      activeHomePage = "circle-blue"
    } else if (rowData.photoType === "gallery") {
      activeGallery = "circle-green"
    }
    return (
      <div className="input-radio-button">
        <div className="p-col-12 p-col-nogutter">
          <div class={activeHomePage}>E</div><label htmlFor="rb1" className="p-radiobutton-label">{ rowData.breadcrumbEnglish }</label>
        </div>

        <div className="p-col-12 p-col-nogutter">
          <div class={activeGallery}>B</div><label htmlFor="rb1" className="p-radiobutton-label">{ rowData.breadcrumbBangla }</label>
        </div>

        {/* { rowData.photoType} */}
      </div>
    )
  }

  onHide = (event) => {
    this.setState({ viewDialogVisible: false, updateDialogVisible: false });
    this.CommonFuctionality.blurDialogBackgroundDeActive();
  }

  viewTutorialDetails = (rowData) => {
      this.setState({ viewDialogVisible: true, rowData: rowData });
      this.CommonFuctionality.blurDialogBackgroundActive();
  }

  updateTutorialDetails = (rowData) => {
    this.setState({ updateDialogVisible: true, rowData: rowData });
    this.CommonFuctionality.blurDialogBackgroundActive();
    // this.renderListItem(rowData)
  }

  diagGrowl = (severityType, message) => {
    this.onHide();

    this.viewAllTutorialInfoList();
    if (severityType == 'success') {
        this.growl.show({ severity: 'success', summary: 'Success Message', detail: message })
    }
    else if (severityType == 'info') {
        this.growl.show({ severity: 'info', summary: 'Info Message', detail: message })
    }
    else if (severityType == 'warn') {
        this.growl.show({ severity: 'warn', summary: 'Warn Message', detail: message })
    }
    else if (severityType == 'error') {
        this.growl.show({ severity: 'error', summary: 'Error Message', detail: message })
    }
}

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody, rowData, AllTutorialInfoList } = this.state;
    const header = this.renderHeader();

    let tableHeader = <div className="header-title">
                        <div className="header-title-left">Tutorial List</div>

                        <div className="header-title-right">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="fas fa-search"></i>
                                </span>
                                <InputText
                                    type="search"
                                    onInput={(e) => this.setState({ globalFilter: e.target.value })}
                                    placeholder="Search by Name, module etc."
                                    size="50"

                                />
                            </div>

                            <span className="total-found">
                              Total Found: {AllTutorialInfoList.length}
                            </span>
                        </div>
                    </div>;

    return (
      <div className="p-fluid">
        <Growl ref={(el) => this.growl = el} />
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
            ) : null}
            {errorMsgVisible ? (
              <ErrorMessageView
                errorMsgVisible={errorMsgVisible}
                errorMsgBody={errorMsgBody}
              />
            ) : null}

        <div className="main-section blur-section">
          <div className="p-col-12 nw-datatable-detail-view">
            {this.state.dataTableIsLoading ?
                this.NetiContentLoader.MyPointTableLoader() :
                <DataTable
                  value={this.state.AllTutorialInfoList}
                  header={tableHeader}
                  selection={this.state.dataTableSelection}
                  responsive={true}
                  rows={10}
                  paginator={true}
                  columnResizeMode="fit"
                  globalFilter={ this.state.globalFilter }
                >
                  <Column field="subMenuInfoDTO.parentCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.categoryName" header="Product Name" />
                  <Column field="subMenuInfoDTO.parentCoreCategoryInfoDTO.categoryName" header="Module / Point" />
                  <Column field="subMenuInfoDTO.categoryName" header="Sub-menu" />
                  <Column field="defaultCode" header="Default Code" />
                  {/* <Column field="" header="Menu Item" body={this.menuItemBody}/> */}
                  <Column field="" header="Breadcrumb" body={this.breadcrumbBody} />
                  <Column header="Action" body={this.actionBody} style={{ textAlign: 'center' }} />
                </DataTable>
            }
          </div>
        </div>

        <div className="dialog-section">
          <Dialog 
              className="nw-dialog" 
              header="Tutorial Information" 
              maximizable 
              visible={this.state.viewDialogVisible}
              onHide={this.onHide} 
              closable
          >
            <div className="p-grid nw-form">
                <div className="p-col-12 p-xl-12">
                    <div className="nw-form-body">
                        { this.renderListItem(rowData) }
                    </div>
                </div>
            </div>
          </Dialog>

          <Dialog 
              className="nw-dialog" 
              header="Update Tutorial Information" 
              maximizable 
              visible={this.state.updateDialogVisible}
              onHide={this.onHide} 
              closable
          >
            {this.state.updateDialogVisible == true ?
                <AdminPointUpdateTutorial rowInformation={this.state.rowData} turnOffDialog={this.onHide} diagGrowl={this.diagGrowl} />
                :
                ''
            }
          </Dialog>
        </div>
      </div>
    );
  }
}
