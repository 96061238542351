import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { PickList } from 'primereact/picklist';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { NoticeUpdate } from './NoticeUpdate';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { Link } from 'react-router-dom';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';

let cloneStateBeforeMount;
let urlId;
let noticeSubmitError = {}
export class Notice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogLoad: false,
            visible: false,
            value: '',
            noticeList: [],
            selectNotice: '',
            newSelectedNoticeArr: [],
            rowInformation: {
                urlInfoDTO: {
                    urlInfoID: ''
                }
            },
            noticeSubmitError: [],
            noticeInfo: {
                noticeTitle: "",
                noticeDetails: "",
                noticeImgName: "",
                noticeImgPath: "",
                noticeCreateDate: "",
                noticeExpiryDate: "",
                noticeImgContent: "",
                urlInfoDTO: {
                    urlInfoID: ''
                }
            },
            selectedList: [],
            uploadImage: {

            },
            dataTableIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
        }
        this.DwsService = new DwsService();
        this.netiFileHandler = new NetiFileHandler();
        this.NetiDateUtils = new NetiDateUtils();
        this.NetiContentLoader = new NetiContentLoader();
        this.commonFuctionality = new CommonFuctionality();
        this.reloadCoreUrlIdFromLocal();
        this.showUpdateMessage = this.showUpdateMessage.bind(this);
    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (urlId) {
            this.setState({ homeReturnButton: false })
            let { noticeInfo } = this.state;
            noticeInfo.urlInfoDTO.urlInfoID = urlId;
            this.setState({ noticeInfo });
            this.viewTopNoticeList();
            this.viewTopSelectedNoticeList();
        }
        else {
            this.setState({ homeReturnButton: true })
        }

        let urlInfoDTO = this.state.noticeInfo.urlInfoDTO;
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    showUpdateMessage(updateStatus) {
        if (updateStatus) { this.growl.show({ severity: "success", summary: "Success Message", detail: "Successfully Updated." }); }
    }

    viewTopNoticeList = () => {

        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        let urlInfoDTO = this.state.noticeInfo.urlInfoDTO;
        this.DwsService.getTopNoticeList(urlInfoDTO, '0')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ noticeList: body })
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, });
                    })
                }
                else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, dataTableIsLoading: false, errorMsgBody: 'Please check connection' });
            })

    }

    viewTopSelectedNoticeList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        let urlInfoDTO = this.state.noticeInfo.urlInfoDTO;
        this.DwsService.getTopNoticeList(urlInfoDTO, '1').then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    this.setState({ selectedList: body })
                    this.setState({ topProgressBar: false, dataTableIsLoading: false });
                })
            }
        }).catch(error => {
            this.setState({ topProgressBar: false, errorMsgVisible: true, dataTableIsLoading: false, errorMsgBody: 'Please check connection' });
        })
    }

    viewNoticeData = (e, source) => {

        // console.log('e', e.target);
        // console.log('source', source);
        
        this.setState({ visible: true });
        let { rowInformation } = this.state;
        rowInformation.noticeTitle = source.noticeTitle;
        rowInformation.noticeCreateDate =  source.noticeCreateDate;
        rowInformation.noticeExpiryDate = source.noticeExpiryDate;
        rowInformation.noticeDetails = source.noticeDetails;
        rowInformation.noticeImgName = source.noticeImgName;
        rowInformation.noticeImgContent = source.noticeImgContent;
        rowInformation.noticeImgPath = source.noticeImgPath;
        rowInformation.noticeStatus = source.noticeStatus;
        rowInformation.noticeID = source.noticeID;
        rowInformation.urlInfoDTO.urlInfoID = source.urlInfoDTO.urlInfoID;
        rowInformation.topNotice = source.topNotice;
        
        this.setState({ rowInformation });
        this.setState({ dialogLoad: true });

        this.commonFuctionality.blurDialogBackgroundActive()
    }

    loadDialog = () => {
        this.setState({ dialogLoad: false });
    }

    itemTemplate = (source) => {
        return (
            <div className="p-clearfix">
                <div>
                    <label
                        htmlFor="cb1"
                        className="p-checkbox-label">
                        {source.noticeTitle}
                    </label>
                    <Button
                        className="pull-right nw-action-button"
                        icon="fas fa-pencil-alt"
                        onClick={(e) => this.viewNoticeData(e, source)}
                    />
                </div>
            </div>
        );
    }

    noticeNameHandler = (event, props) => {
        let { noticeInfo } = this.state;
        noticeInfo.noticeTitle = event.target.value;
        noticeSubmitError["noticeTitle"] = "";
        
        this.setState({ noticeInfo, noticeSubmitError });
    }

    issueDateHandler = (event, props) => {
        let { noticeInfo } = this.state;
        noticeInfo.noticeCreateDate = event.target.value;
        noticeSubmitError["noticeCreateDate"] = "";
        
        this.setState({ noticeInfo, noticeSubmitError });
    }

    expiryDateHandler = (event, props) => {
        let { noticeInfo } = this.state;
        noticeInfo.noticeExpiryDate = event.target.value;

        if (noticeInfo.noticeCreateDate > noticeInfo.noticeExpiryDate) {
            noticeSubmitError["noticeExpiryDate"] = "Expiry date can't less than Issue date";
        }
        else{
            noticeSubmitError["noticeExpiryDate"] = "";
        }
        
        this.setState({ noticeInfo, noticeSubmitError });
    }

    noticeDetailsHandler = (event, props) => {
        let { noticeInfo } = this.state;
        noticeInfo.noticeDetails = event.target.value;
        
        noticeSubmitError["noticeDetails"] = "";

        this.setState({ noticeInfo, noticeSubmitError });
    }

    onLoadPic(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ uploadImage: album });

            noticeSubmitError["noticeImgContent"] = "";

        }
        
    }

    addNewNoticeHandler = () => {

        let { noticeInfo } = this.state;
        if (this.state.uploadImage.contentPic != null) {
            noticeInfo.noticeImgContent = this.state.uploadImage.contentPic;
            noticeInfo.noticeImgSaveOrEditable = true;
            // let extention = this.netiFileHandler.getImageExtention(this.state.uploadImage.extention);
            // noticeInfo.noticeImgName = 'notice_' + urlId + '_' + Date.now() + extention;

            noticeInfo.noticeImgName = this.state.uploadImage.contentName;
        }

        if (this.errorHandler()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.setState({ noticeInfo });
            this.DwsService.addNewNotice(noticeInfo)
                .then(res => {
                    if (res.status == 201) {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Notice added successfully' })
                        this.setState({
                            noticeInfo: cloneStateBeforeMount.noticeInfo,
                            uploadImage: cloneStateBeforeMount.uploadImage
                        });
                        this.viewTopNoticeList();
                    }
                    else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
                });
        }

    }

    onMoveToTarget = (e) =>{
        e.value[0].topNotice = 1
    }

    onMoveToSource = (e) =>{
        e.value[0].topNotice = 0
    }

    noticeListSubmit = (event, props) => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        let { selectedList, noticeList } = this.state;
        let selectedNoticeList = [];

        if (selectedList.length > 5) {
            noticeSubmitError["tableError"] = "You cross speech limit";
            //this.growl.show({ severity: 'error', summary: 'Error Message', detail: 'You cross speech limit' });
            this.setState({ noticeSubmitError, topProgressBar: false, dataTableIsLoading: false });
            return;
        }

        for (let i = 0; i < selectedList.length; i++) {
            selectedList[i].topNotice = 1;
            selectedList[i].noticeSerial = i + 1;

            selectedNoticeList.push(selectedList[i]);
        }

        for (let i = 0; i < noticeList.length; i++) {
            noticeList[i].topNotice = 0;
            selectedNoticeList.push(noticeList[i]);
        }

        this.DwsService.updateTopNoticeList(selectedNoticeList)
            .then(res => {
                if (res.status == 202) {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Notice list updated successfully' });
                    this.viewTopNoticeList();
                    this.viewTopSelectedNoticeList();
                } else {

                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp.message })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection." })
            });

    }

    errorHandler() {
        let { noticeInfo, noticeSubmitError } = this.state;
        let formIsValid = true;
        if (noticeInfo.noticeTitle === '') {
            formIsValid = false;
            noticeSubmitError["noticeTitle"] = "Notice title can't left empty";
        }
        if (noticeInfo.noticeCreateDate === '') {
            formIsValid = false;
            noticeSubmitError["noticeCreateDate"] = "Issue date can't left empty";
        }
        if (noticeInfo.noticeExpiryDate === '') {
            formIsValid = false;
            noticeSubmitError["noticeExpiryDate"] = "Expiry date can't left empty.";
        }

        if (noticeInfo.noticeCreateDate > noticeInfo.noticeExpiryDate) {
            formIsValid = false;
            noticeSubmitError["noticeExpiryDate"] = "Expiry date can't less than Issue date";
        }

        if (noticeInfo.noticeDetails === '') {
            formIsValid = false;
            noticeSubmitError["noticeDetails"] = "Notice details can't left empty.";
        }
        if (noticeInfo.noticeImgName === '') {
            formIsValid = false;
            noticeSubmitError["noticeImgContent"] = "Attachment file missing.";
        }
        this.setState({ noticeSubmitError });
        return formIsValid;
    }

    onChangeSelectNotice = (e) => {
        noticeSubmitError["tableError"] = ''
        this.setState({ noticeList: e.source, selectedList: e.target })
    }

    turnOffDialog = () => {
        this.setState({ visible: false })
        this.commonFuctionality.blurDialogBackgroundDeActive()
    }

    onHide = (event) => {
        this.setState({ visible: false});
        this.commonFuctionality.blurDialogBackgroundDeActive()
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let uploadFileLabel = <div>Click / Drag File Here To Upload <br/><span className="upload-file-label">(Max 1MB (recommended))</span></div>

        let sourceHeader = <div className="header-title">
                                <div className="header-title-left">
                                    Notice List
                                </div>
                                <div className="header-title-right">
                                    {/* <a>
                                        Total Found: {this.state.selectedBooksData.length}
                                    </a> */}
                                </div>
                            </div>;

        let targetHeader = <div className="header-title">
                                <div className="header-title-left">
                                    Selected Notice for Neti CMS Sidebar
                                </div>
                                <div className="header-title-right">
                                    <a className="text-center"> MAX: 5 </a>
                                </div>
                            </div>;
        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                {this.state.homeReturnButton === false ?
                    <div className="main-section blur-section">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Title <span>*</span></span>
                                            <InputText
                                                className="custom-form-control"
                                                value={this.state.noticeInfo.noticeTitle}
                                                id="noticeTitle"
                                                onChange={(e) => this.noticeNameHandler(e, this.props)}
                                                name="noticeTitle"
                                                placeholder="Enter Notice Title"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.noticeSubmitError.noticeTitle}</span>
                                    </div>
                                    
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Issue Date <span>*</span></span>
                                            <Calendar
                                                id="issueDate"
                                                name="issueDate"
                                                showIcon={true}
                                                value={this.state.noticeInfo.noticeCreateDate}
                                                onChange={(e) => this.issueDateHandler(e, this.props)}
                                                placeholder="Select Issue Date"
                                                // className="custom-calender-input"
                                                yearRange="2010:2030"
                                                dateFormat="dd/mm/yy"
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                dateOnly="true"
                                                showButtonBar={true} 
                                            />
                                        </div>
                                        <span className="error-message">{this.state.noticeSubmitError.noticeCreateDate}</span>
                                    </div>
                                   
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Expiry Date <span>*</span></span>
                                            <Calendar
                                                placeholder="Select Expiry Date"
                                                id="expiryDate"
                                                name="expiryDate"
                                                value={this.state.noticeInfo.noticeExpiryDate}
                                                showIcon={true}
                                                onChange={(e) => this.expiryDateHandler(e, this.props)}
                                                // className="custom-calender-input"
                                                yearRange="2010:2030"
                                                dateFormat="dd/mm/yy"
                                                monthNavigator={true}
                                                yearNavigator={true}
                                                dateOnly="true"
                                                showButtonBar={true} 
                                            />
                                        </div>
                                        <span className="error-message">{this.state.noticeSubmitError.noticeExpiryDate}</span>
                                    </div>
                                   
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                            <InputTextarea
                                                className="custom-form-control"
                                                value={this.state.noticeInfo.noticeDetails}
                                                id="details"
                                                onChange={(e) => this.noticeDetailsHandler(e, this.props)}
                                                name="details"
                                                rows={5}
                                                cols={30}
                                                placeholder="Write Notice Details"
                                            />
                                        </div>
                                        <span className="error-message"><span className="error-message">{this.state.noticeSubmitError.noticeDetails}</span></span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                Upload File <span>*</span>
                                                <br />
                                                <span>(PNG/JPG/DOC/PDF)</span>
                                            </span>

                                            <div className="nw-upload-button-inside">

                                                {
                                                    this.state.uploadImage.contentPic ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={e => this.setState({ uploadImage: { contentPic: '' } })}
                                                                />

                                                                {
                                                                    this.state.uploadImage.contentName ?
                                                                        this.commonFuctionality.getFileContentTypeIcon(this.state.uploadImage.contentName) : this.commonFuctionality.getFileContentTypeIcon(this.props.noticeInfo.noticeImgName)
                                                                }
                                                                {/* <img
                                                                    src={
                                                                        this.state.uploadImage.contentPic === '' ?
                                                                            'assets/images/demo.jpg'
                                                                            :
                                                                            "data:image/png;base64," + this.state.uploadImage.contentPic
                                                                    }
                                                                    style={{ "height": "80px" }}
                                                                /> */}

                                                            </div>
                                                            <div className="image-title">{this.state.uploadImage.contentName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload 
                                                            chooseLabel={this.state.uploadImage.contentName || uploadFileLabel}
                                                            id="fileUpload"
                                                            mode="basic"
                                                            maxFileSize={1024000}
                                                            onSelect={this.onLoadPic.bind(this)}
                                                            auto={true}
                                                        />
                                                }

                                            </div>

                                            {/* <center>
                                                {this.state.uploadImage.contentName}
                                            </center> */}
                                        </div>
                                        <span className='error-message'>{this.state.uploadImage.contentName ? null : this.state.noticeSubmitError.noticeImgContent}</span>
                                    </div>
                                    
                                    <div className="p-col-12 p-xl-12">

                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                        <div className="required-field">
                                           (<span>*</span>) required fields
                                        </div> 

                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            name="district"
                                            label="Save"
                                            icon="fas fa-check"
                                            onClick={(e) => this.addNewNoticeHandler(e, this.props)}
                                        />

                                    </div>
                                </div>
                            </div>

                            <br />
                            <div className="p-col-12 p-xl-12 nw-data-table">
                               
                                    <div className="content-section implementation">
                                        {this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader() :
                                            <PickList
                                                source={this.state.noticeList}
                                                target={this.state.selectedList}
                                                itemTemplate={this.itemTemplate}
                                                sourceHeader={ sourceHeader }
                                                targetHeader={ targetHeader }
                                                responsive={true}
                                                sourceStyle={{ height: '300px' }}
                                                targetStyle={{ height: '300px' }}
                                                onMoveToTarget={this.onMoveToTarget}
                                                onMoveToSource={this.onMoveToSource}
                                                onChange={this.onChangeSelectNotice}
                                            >
                                            </PickList>
                                        }
                                        <span className="error-message">{noticeSubmitError["tableError"]}</span>
                                    </div>
                                    <br />
                                    <div className="p-grid p-col-12 p-xl-12 nw-button-parent">
                                        <Button
                                            label="Save"
                                            icon="fas fa-check"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            onClick={(e) => this.noticeListSubmit(e, this.props)}
                                        />
                                    </div>

                            </div>

                        </div>
                       
                    </div>
                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }

                {this.state.dialogLoad == true &&
                    <Dialog 
                        className="nw-dialog" 
                        maximizable header="Update Notice Information" 
                        visible={this.state.visible} 
                        modal={true} 
                        onHide={ this.onHide}>
                    
                        <NoticeUpdate 
                            loadDialog={this.loadDialog} 
                            viewTopNoticeList={this.viewTopNoticeList} 
                            noticeInformation={this.state.rowInformation} 
                            turnOffDialog={this.turnOffDialog}
                            showUpdateMessage={this.showUpdateMessage}
                        />
                    </Dialog>

                }
            </div>
        );
    }
}