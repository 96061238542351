import React, { Component } from "react";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";
import { SaCoreSettingsService } from "../../../service/saPoint/SaCoreSettingsService";
import { StudentClassConfigInfo } from "../common/StudentClassConfigInfo";
import { Panel } from "primereact/panel";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { EmUserPointService } from '../../../service/emUserPoint/EmUserPointService';
export class StudentPortalAccounts extends Component {
  constructor() {
    super();

    this.state = {
      paidunpaid: "",
      errors: {},
      academicYearList: [],
      academicYearFiltering: false,
      academicYear: "",
      basicInfo: "",
      tableDetailspaid: [],
      tableDetailsUnpaid: [],
      show: false,
      show2: false,
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',
      firstDropdownIsLoading: false,
      userList: [],
      userID: '',
      userLength: '',
      insID: '',
      basicInfo: [],
      stId: '',
    };
    this.onPaidUnpaid = this.onPaidUnpaid.bind(this);
    this.onChangeAcYear = this.onChangeAcYear.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.studentPortal = new StudentPortal();
    this.saCoreSettingsService = new SaCoreSettingsService();
    this.NetiContentLoader = new NetiContentLoader();
    this.emUserPointService = new EmUserPointService();
  }

  onPaidUnpaid = e => {
    let { errors } = this.state;
    errors["status"] = "";
    this.setState({ paidunpaid: e.target.value, errors })
  };

  onChangeAcYear = e => {
    let { errors } = this.state;
    errors["academicYear"] = "";
    this.setState({ academicYear: e.target.value, errors })
  };

  componentWillMount() {
    this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
    this.saCoreSettingsService.getGlobalInfoByTypeId(2101).then(res => {
      if (res.item) {
        this.setState({ academicYearList: res.item });
        this.setState({ topProgressBar: false, firstDropdownIsLoading: false });

      } else {
        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No data found' })
      }
    }).catch(err =>
      this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' })

    )

    this.setCurrentAcademicYearToDropDown();
    this.loadStudentInfo();
  }

  loadStudentInfo = () => {

    setTimeout(() => {
        [...document.querySelectorAll('.protal-single-info-content')].map(item => {
            if (!item.querySelector('p').innerHTML.trim()) {
                item.querySelector('p').innerHTML = '---'
            }
        })
    }, 1500);

    let userListtt = [];
    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    this.emUserPointService.fetchMappedPortalList()
        .then((res) => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    // console.log('Log: GoToPortal -> componentWillMount -> body......', body[0].idForStudent)
                    console.log('Log: GoToPortal -> componentWillMount -> body......', body)
                    

                    body.map(item => {
                        if(item.mappingType === "Student") {
                          userListtt.push(item)
                        }
                    })
                    this.setState({userList: userListtt})
                    this.setState({userLength: body.length})
                    this.setState({stId:body[0].idForStudent})

                    console.log("user id inside : ", this.state.userID)
                    
                    this.studentPortal
                    .fetchStudentProfileDetailsList(
                        this.state.insID || body[0]["emDetailsInfoDTO"].instituteId ,
                        this.state.userID || body[0].idForStudent
                        // body[this.state.userID]["emDetailsInfoDTO"].instituteId,
                        // body[this.state.userID].idForStudent,
                    )
                    .then(res => {
                        if (res.status == 200) {
                          
                            return res.json().then(body => {
                                this.setState({ topProgressBar: false, errorMsgVisible: false })
                                this.setState({ basicInfo: body });
                    
                            });
                        } else {
                            this.studentPortal.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    })
                    .catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));

                    body.map(item => {
                        if(item.mappingType === "Student") {
                          userListtt.push(item)
                        }
                    })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                })
            } else {
                this.emUserPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch((error) => {
            console.log(error)
            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        });


    this.setState({ topProgressBar: true, errorMsgVisible: false })
  };


handledropdwon = (id, insId) => {
    console.log("id......................", id)
    this.setState({userID: id})
    this.setState({insID:insId})

    this.loadStudentInfo(); 
 }

  setCurrentAcademicYearToDropDown = () => {
    let academicYear = NetiDateUtils.getYear(new Date());
    this.setState({ academicYear })
  }


  handleSubmit(e) {
    this.setState({ tableDetailspaid: [] });
    this.setState({ tableDetailsUnpaid: [] });

    if (this.handleError()) {
      this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
      if (this.state.academicYear) {
        if (this.state.paidunpaid == 1) {

          this.setState({ show: true, show2: false });
          this.studentPortal.fetchfindPaidInvoiceByStudentId(this.state.basicInfo.instituteId, this.state.basicInfo.studentId, this.state.academicYear)
            .then(res => {
              if (res.status == 200) {
                return res.json().then(body => {
                  if (body.msgType === 0) {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: "No data found" });
                  } else if (body.msgType === 1) {
                    for (let i = 0; i < body.item.length; i++) {
                      if (body.item[i].feeHeadsName == null) {
                        body.item[i].feeHeadsName = '';
                      } else {
                        body.item[i].feeHeadsName = body.item[i].feeHeadsName.trim();
                      }
                    }
                    for (let j = 0; j < body.item.length; j++) {
                      if (body.item[j].feeSubHeadsName == null) {
                        body.item[j].feeSubHeadsName = '';
                      } else {
                        body.item[j].feeSubHeadsName = body.item[j].feeSubHeadsName.trim();
                      }
                    }
                    this.setState({ tableDetailspaid: body.item })
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                  }
                });
              } else {
                this.studentPortal.Auth.handleErrorStatus(res)
                  .then((responseBody) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                  });
              }
            }).catch(err => {
              console.log(err)
              this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            })

        } else if (this.state.paidunpaid == 0) {


          this.setState({ show: false, show2: true });
          this.studentPortal.fetchfindunPaidInvoiceByStudentId(this.state.basicInfo.instituteId, this.state.basicInfo.studentId, this.state.academicYear)
            .then(res => {
              if (res.status == 200) {
                return res.json().then(body => {
                  if (body.msgType === 0) {
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: "No data found" });
                  } else if (body.msgType === 1) {
                    for (let i = 0; i < body.item.length; i++) {
                      if (body.item[i].feeHeadsName == null) {
                        body.item[i].feeHeadsName = '';
                      } else {
                        body.item[i].feeHeadsName = body.item[i].feeHeadsName.trim();
                      }
                    }
                    for (let j = 0; j < body.item.length; j++) {
                      if (body.item[j].feeSubHeadsName == null) {
                        body.item[j].feeSubHeadsName = '';
                      } else {
                        body.item[j].feeSubHeadsName = body.item[j].feeSubHeadsName.trim();
                      }
                    }
                    this.setState({ tableDetailsUnpaid: body.item })
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true });
                  }
                });
              } else {
                this.studentPortal.Auth.handleErrorStatus(res)
                  .then((responseBody) => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                  });
              }
            }).catch(error => {
              this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            })
        }
      }
    }
  }


  handleError = () => {

    let { errors } = this.state;
    let formIsValid = true;

    if (!this.state.academicYear) {
      formIsValid = false;
      errors["academicYear"] = "Academic Year can't left empty.";
    }
    if (this.state.paidunpaid === '') {
      formIsValid = false;
      errors["status"] = "Status can't left empty.";
    }
    else {
      return formIsValid;
    }
    this.setState({ errors });
  }

  onAcademicYearFilter = () => {
    if (this.state.academicYearFiltering) this.setCurrentAcademicYearToDropDown();
    this.setState({ academicYearFiltering: !this.state.academicYearFiltering });
  }

  render() {

    var rows = [];
    let l = this.state.userLength;
    this.state.userList.map((item) =>{
       if(l > rows.length){
           rows.push(<a  onClick={() => this.handledropdwon(item.idForStudent, item["emDetailsInfoDTO"].instituteId)} >    {item.idForStudent}  </a>)
       }
   })

    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
    let paidTableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
      Paid List
    <div
        style={{ color: "white", display: "flex", float: "right" }}
      >
        Total Found: {this.state.tableDetailspaid.length}
      </div>
    </div>;

    let unpaidTableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
      Unpaid List
      <div style={{ color: "white", display: "flex", float: "right" }} >
        Total Found: {this.state.tableDetailsUnpaid.length}
      </div>
    </div>;

    let paidUnpaidOptions = [
      {
        value: "1",
        label: "Paid"
      },
      {
        value: "0",
        label: "Unpaid"
      }
    ];

    let acYearOptions = [];
    if (this.state.academicYearList && this.state.academicYearList.length) {
      acYearOptions = this.state.academicYearList.map(item => ({
        value: item.name,
        label: item.name
      }));
    }

    return (
      <div className="p-col-12 p-col-nogutter">
        <Growl ref={(el) => this.growl = el} />
        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <div className="p-grid nw-form">
          <div className="p-col-12" style={{position: "relative"}}>
            <StudentClassConfigInfo basicInfo={this.state.basicInfo} />
            <div className='dropdown'>
                        <button className="dropbtn">
                         {/* <i className="fa fa-caret-down"></i> */}
                         <span class="fas fa-align-right p-c p-button-icon-left"></span>
                        </button>
                        <div className="dropdown-content">
                            {rows}
                        </div>             
             </div>
          </div>


          <div className="p-col-12">
            <p className='profile-custom-table-header'>Accounts</p>
          </div>

          <div className="p-col-12 p-col-nogutter">
            <div className="nw-form-body">

              <div className="p-col-12">
                <div className="p-xl-2 p-xl-offset-10 p-md-3 p-md-offset-9 p-col-4 p-offset-8">
                  <Button
                    label="Academic Year"
                    icon="fas fa-filter"
                    iconPos="right"
                    onClick={this.onAcademicYearFilter}
                  />
                </div>
              </div>

              <div className="p-col-12">
                {this.state.firstDropdownIsLoading ?
                  this.NetiContentLoader.normalFormInputField() :
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputgroup-small-span">
                      Academic Year <span>*</span>
                    </span>

                    {this.state.academicYearFiltering ?
                      <Dropdown
                        options={acYearOptions}
                        placeholder="Select Academic Year"
                        value={this.state.academicYear}
                        onChange={this.onChangeAcYear}
                        autoWidth={false}
                        name='academicYear'
                        filter={true}
                      />
                      :
                      <Dropdown
                        disabled
                        options={acYearOptions}
                        placeholder="Select Academic Year"
                        value={this.state.academicYear}
                        onChange={this.onChangeAcYear}
                        autoWidth={false}
                        name='academicYear'
                      />}
                  </div>
                }
                <span className="error-message"> {this.state.academicYear ? '' : this.state.errors['academicYear']} </span>
              </div>

              <div className="p-col-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon nw-inputgroup-small-span">
                    Status <span>*</span>
                  </span>
                  <Dropdown
                    placeholder="Select Status"
                    options={paidUnpaidOptions}
                    value={this.state.paidunpaid}
                    onChange={this.onPaidUnpaid}
                    filter={true}
                    name='status'
                    filterBy="label,value"
                    autoWidth={false}
                  />
                </div>
                <span className='error-message'>{this.state.errors["status"]}</span>
              </div>

              <div className="p-col-12 nw-button-parent">
                <Button
                  className="p-button p-button-primary nw-button nw-button-right"
                  label="Search"
                  icon="fas fa-search"
                  onClick={this.handleSubmit}
                />
                <br />
              </div>

            </div>
          </div>

          <div className="p-col-12 p-xl-12">
            {this.state.show ?
              this.state.dataTableIsLoading ?
                this.NetiContentLoader.normalFormInputField() :

                <div className="nw-data-table">

                  <DataTable
                    header={paidTableHeader}
                    value={this.state.tableDetailspaid}
                    responsive={true}
                    paginator={true}
                    rows={10}
                  >
                    <Column field="invoiceId" header="Invoice ID" sortable={true} filter={true} />
                    <Column field="date" header="Payment Date" sortable={true} filter={true} />
                    <Column field="feeHeadsName" header="Fee Head" sortable={true} filter={true} />
                    <Column field="feeSubHeadsName" header="Fee Sub Head" sortable={true} filter={true} />
                    <Column field="payableAmt" header="Payable Amount" sortable={true} filter={true} />
                    <Column field="paidAmt" header="Paid Amount" sortable={true} filter={true} />
                    <Column field="dueAmt" header="Due Amount" sortable={true} filter={true} />

                  </DataTable>
                </div>

              : ""}

            {this.state.show2 ?
              this.state.dataTableIsLoading ?
                this.NetiContentLoader.normalFormInputField() :

                <div className="nw-data-table">
                  <DataTable
                    header={unpaidTableHeader}
                    value={this.state.tableDetailsUnpaid}
                    responsive={true}
                    paginator={true}
                    rows={10}
                  >
                    <Column field="invoiceId" header="Payslip Id" sortable={true} filter={true} />
                    <Column field="date" header="Create Date" sortable={true} filter={true} />
                    <Column field="feeHeadsName" header="Fee Head" sortable={true} filter={true} />
                    <Column field="feeSubHeadsName" header="Fee Sub Head" sortable={true} filter={true} />
                    <Column field="payableAmt" header="Payable Amount" sortable={true} filter={true} />
                  </DataTable>
                </div>

              : ""}
          </div>
        </div>
      </div>

    );
  }
}
