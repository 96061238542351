import React, { Component } from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
// import { CoreConfigView } from "./CoreConfigView";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from 'primereact/fileupload';
import { Growl } from "primereact/growl";
import { Messages } from 'primereact/messages';

//custom imports
import { DwsService } from '../../../../service/dwsPoint/DwsService';
import { UserCategoryService } from "../../../../service/UserCategoryService";
import { AdmisiaPointService } from "../../../../service/admisiaPoint/AdmisiaPointService";
import NetiContentLoader from '../../../common/NetiContentLoader';
import { ErrorMessageView } from "../../../common/ErrorMessageView";
import CommonFuctionality from '../../../common/CommonFuctionality';
import { NetiFileHandler } from '../../../../utils//NetiFileHandler';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';

export class AdmisiaApplicationConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultLanguage: "English",
            cmsId: '',
            errors: {},
            checkEligibility: false,
            errorMsgVisible: false,
            topProgressBar: false,
            dataIsLoading: false,
            secondDataIsLoading: false,
            errorMsgBody: "",

            "admissionExamDate": "",
            "admissionExamInstruction": "",
            "admissionExamStatus": 0,
            "admissionExamTime": "",
            "applicantLimit": '',
            "applicationEndDate": "",
            "applicationFee": '',
            "applicationStartDate": "",
            "autoApproveStatus": 0,
            "classConfigSerial": 0,
            "classId": '',
            "cmsId": 0,
            "coreConfigId": 0,
            "examCenterName": "",
            "fileContent": "",
            "fileName": "",
            "fileSaveOrEditable": false,
            "groupId": '',
            "prevExamInfoRequiredStatus": 0,
            "signatureTitle": "",

            uploadFile: {
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            applicationServiceCharge: 0,

            previousAdmissionInfo: false,
            previousExamInfo: false,

            admissionExamTime: "",
            formFee: "",
            yesAutoApprove: false,
            noAutoApprove: false,

            autoApproveStatus: 0,
            prevExamInfoRequired: 0,
            admissionTestStatus: 0,
            saveErrors: {},
            examInstruction: '',
            instituteInfo: {
                coreConfigID: ''
            }
        };
        this.handlefeeValue = this.handlefeeValue.bind(this);
        this.handlepreviousExamInfo = this.handlepreviousExamInfo.bind(this);
        this.handlenoAutoApprove = this.handlenoAutoApprove.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.handlepreviousAdmissionInfo = this.handlepreviousAdmissionInfo.bind(this);
        // this.handleyesAutoApproveOption = this.handleyesAutoApproveOption.bind(this);

        this.DwsService = new DwsService();
        this.userCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
        this.admisiaPointService = new AdmisiaPointService();

        this.netiFileHandler = new NetiFileHandler();
        this.CommonFuctionality = new CommonFuctionality();

        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {
        let cmsId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("cmsId .-->", cmsId);

        if (typeof cmsId === 'string' || typeof cmsId === 'number') {

            this.setState({ homeReturnButton: false });
            // this.viewInstituteDetails(cmsId);
            this.setState({ cmsId: cmsId });
            this.getClassListByTypeAndCmsId('class', cmsId);
            this.getGroupListByTypeAndCmsId('group', cmsId);
            this.fetchApplicationServiceCharge(cmsId);

        }
        else { this.setState({ homeReturnButton: true }) }
    }

    handlefeeValue = e => {
        this.setState({ applicationFee: e.target.value });
    };

    handlepreviousExamInfo = e => {
        this.setState({ previousExamInfo: e.value });
        if (e.value == true) {
            this.setState({ prevExamInfoRequiredStatus: 1 });
        } else if (e.value == false) {
            this.setState({ prevExamInfoRequiredStatus: 0 });
        }

    };

    handlepreviousAdmissionInfo = e => {
        this.setState({ previousAdmissionInfo: e.value })
        if (e.value == true) {
            this.setState({ admissionExamStatus: 1 });
        } else if (e.value == false) {
            this.setState({ admissionExamStatus: 0 });
        }
    };

    handlenoAutoApprove = e => {
        // this.setState({ autoApproveStatus: e.value });
        if (e.value == true) {
            this.setState({ autoApproveStatus: 1 });
        } else if (e.value == false) {
            this.setState({ autoApproveStatus: 0 });
        }
    };

    handleSignatureTitle = e => {
        this.setState({ signatureTitle: e.target.value });
    };

    getClassListByTypeAndCmsId(type, cmsId) {
        this.setState({ dataIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.DwsService.cmsClassOrGroupList(type, cmsId)
            .then(res => {
                // console.log('class-res', res);

                if (res.status == 200) {
                    return res.json().then((body) => {
                        // console.log('class-body', body);
                        this.setState({ classDropDownList: body.item, dataIsLoading: false, topProgressBar: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            })
            .catch(error => {
                this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })

            });
    }

    getGroupListByTypeAndCmsId(type, cmsId) {
        this.setState({ secondDataIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.DwsService.cmsClassOrGroupList(type, cmsId)
            .then(res => {
                // console.log('group-res', res);

                if (res.status == 200) {
                    return res.json().then(body => {
                        // console.log('group-body', body.item);
                        this.setState({ groupDropDownList: body.item, secondDataIsLoading: false, topProgressBar: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, secondDataIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            })
            .catch(error => {
                this.setState({ topProgressBar: false, secondDataIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    fetchApplicationServiceCharge(cmsId) {
        this.setState({ topProgressBar: true, errorMsgVisible: false })
        this.DwsService.fetchApplicationServiceChargeByCmsId(cmsId)
            .then(res => {
                // console.log('serviceCharge-res', res);

                if (res.status == 200) {
                    return res.json().then(body => {
                        console.log('serviceCharge-body', body);
                        this.setState({ applicationServiceCharge: body, topProgressBar: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody });
                        });
                }
            })
            .catch(error => {
                this.setState({ topProgressBar: false, secondDataIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    handleSave() {

        if (this.handleSaveError()) {

            let { uploadFile } = this.state;
            let admissionTestInstruction;

            // console.log('this.state.admissionExamDate', this.state.admissionExamDate);

            if (Date.parse(this.state.applicationEndDate) >= Date.parse(this.state.applicationStartDate)) {

                this.setState({ topProgressBar: true, errorMsgVisible: false });

                admissionTestInstruction = this.state.admissionExamInstruction ? this.state.admissionExamInstruction.trim() : '';
                let requestedObject = {

                    "admissionExamDate": this.state.admissionExamDate !== '' ? NetiDateUtils.getDateFromString(this.state.admissionExamDate) : '',
                    "admissionExamInstruction": admissionTestInstruction,
                    "admissionExamStatus": parseInt(this.state.admissionExamStatus),
                    "admissionExamTime": this.state.admissionExamTime ? NetiDateUtils.getHHMMSS24H(this.state.admissionExamTime) : '',
                    "applicantLimit": parseInt(this.state.applicantLimit),
                    "applicationEndDate": NetiDateUtils.getDateFromString(this.state.applicationEndDate),
                    "applicationFee": parseFloat(this.state.applicationFee),
                    "applicationStartDate": NetiDateUtils.getDateFromString(this.state.applicationStartDate),
                    "autoApproveStatus": parseInt(this.state.autoApproveStatus),
                    "classConfigSerial": this.state.classConfigSerial,
                    "classId": parseInt(this.state.classId),
                    "cmsId": parseInt(this.state.cmsId),
                    "coreConfigId": 0,
                    "examCenterName": this.state.examCenterName,
                    "fileContent": uploadFile.fileContent,
                    "fileName": uploadFile.fileName,
                    "fileSaveOrEditable": uploadFile.fileSaveOrEditable,
                    "groupId": parseInt(this.state.groupId),
                    "prevExamInfoRequiredStatus": parseInt(this.state.prevExamInfoRequiredStatus),
                    "signatureTitle": this.state.signatureTitle,

                }

                // console.log('requestedObject', requestedObject);

                this.setState({ topProgressBar: true, errorMsgVisible: false });

                this.DwsService.admisiaSaveClassConfig(requestedObject).then(res => {
                    if (res.status == 201) {

                        return res.json().then((body) => {
                            // console.log('res.status', body, body.messageType);
                            if (body.messageType === 1) {
                                this.growl.show({ severity: 'success', summary: 'Success', detail: body.message });
                                this.setState({ topProgressBar: false });
                                this.resetInputFieldValue();
                            } else {
                                this.growl.show({ severity: 'error', summary: 'Error', detail: body.message });
                                this.setState({ topProgressBar: false });
                            }
                        });

                    } else {
                        this.admisiaPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "Start Date can't be greater than End Date." });
                this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: false })
            }

        }
    }

    resetInputFieldValue() {

        this.setState({
            "admissionExamDate": "",
            "admissionExamInstruction": "",
            "admissionExamStatus": 0,
            "admissionExamTime": "",
            "applicantLimit": '',
            "applicationEndDate": "",
            "applicationFee": '',
            "applicationStartDate": "",
            "autoApproveStatus": 0,
            "classConfigSerial": 0,
            "classId": '',
            "cmsId": 0,
            "coreConfigId": 0,
            "examCenterName": "",
            "fileContent": "",
            "fileName": "",
            "fileSaveOrEditable": false,
            "groupId": '',
            "prevExamInfoRequiredStatus": 0,
            "signatureTitle": "",

            previousExamInfo: false,
            previousAdmissionInfo: false
        });
        this.removeUploadFile();
    }

    handleSaveError() {

        let saveErrors = {};
        let formIsValid = true;

        if (this.state.classConfigSerial === "" || this.state.classConfigSerial === undefined || this.state.classConfigSerial === 0) {
            formIsValid = false;
            saveErrors["classConfigSerial"] = "Serial No. can't left empty.";
        }

        if (this.state.classId === "" || this.state.classId === undefined) {
            formIsValid = false;
            saveErrors["className"] = "Class Name can't left empty.";
        }
        if (this.state.groupId === "" || this.state.groupId === undefined) {
            formIsValid = false;
            saveErrors["groupName"] = "Group Name can't left empty.";
        }
        if (this.state.applicationStartDate === "" || !this.state.applicationStartDate) {
            formIsValid = false;
            saveErrors["startDate"] = "Start Date can't left empty.";
        }
        else if (!Date.parse(this.state.applicationStartDate)) {
            formIsValid = false;
            saveErrors["startDate"] = "Invalid Date format";
        }

        if (this.state.applicationEndDate === "" || !this.state.applicationEndDate) {
            formIsValid = false;
            saveErrors["endDate"] = "End Date can't left empty.";
        }
        else if (!Date.parse(this.state.applicationEndDate)) {
            formIsValid = false;
            saveErrors["endDate"] = "Invalid Date format";
        }
        if (this.state.applicantLimit === "" || this.state.applicantLimit === undefined) {
            formIsValid = false;
            saveErrors["requiredApplicant"] = "Total Required Applicant can't left empty.";
        }
        if (this.state.applicationFee === "" || this.state.applicationFee === undefined) {
            formIsValid = false;
            saveErrors["formFee"] = "Application Fee can't left empty.";
        }
        if (this.state.admissionExamStatus === 1 || this.state.admissionExamStatus === true) {

            if (this.state.examCenterName === "" || this.state.examCenterName === undefined) {
                formIsValid = false;
                saveErrors["examCenterName"] = "Exam Center Name can't left empty.";
            }

            if (!this.state.admissionExamDate) {
                formIsValid = false;
                saveErrors["admissionExamDate"] = "Exam Date can't left empty.";
            } else if (this.state.applicationStartDate !== '' && this.state.applicationEndDate !== '') {
                console.log('first condition tru');

                if (Date.parse(this.state.admissionExamDate) > Date.parse(this.state.applicationEndDate) && Date.parse(this.state.admissionExamDate) > Date.parse(this.state.applicationStartDate)) {
                    console.log('all date  condition true');
                } else {
                    console.log('date  validation false');
                    // this.messages.show({ severity: 'error', detail: "Invalid date configuration." });
                    this.growl.show({ severity: 'error', detail: "Invalid date configuration." });

                    formIsValid = false;
                }
            }

            if (this.state.admissionExamDate && !Date.parse(this.state.admissionExamDate)) {
                formIsValid = false;
                saveErrors["admissionExamDate"] = "Invalid Date format";
            }
            if (!this.state.admissionExamTime) {
                formIsValid = false;
                saveErrors["admissionExamTime"] = "Exam Time can't left empty.";
            }
            if (!this.state.admissionExamInstruction) {
                formIsValid = false;
                saveErrors["examInstruction"] = "Exam Instruction can't left empty.";
            }

        }

        if (this.state.signatureTitle === "" || this.state.signatureTitle === undefined) {
            formIsValid = false;
            saveErrors["signatureTitle"] = "Signature Title can't left empty.";
        }

        if (!this.state.uploadFile.fileContent) {
            formIsValid = false;
            saveErrors["fileContent"] = "Upload file can't left empty.";
        }

        this.setState({ saveErrors: saveErrors });
        return formIsValid;
    }

    onLoadPic = (e, maxFileSize) => {

        let { errors } = this.state;

        let fileType = this.netiFileHandler.getFileContentType(e.files[0].name);
        let supportedExtention = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
        // console.log('loadPic', e.files[0]);

        if (supportedExtention.includes(fileType)) {

            if (this.netiFileHandler.getMaxFileSizeIsValid(e.files[0].size, maxFileSize)) {

                errors["fileContent"] = "";
                this.setState({ errors });

                var reader = new FileReader();
                let photo = e.files[0];
                const scope = this;
                reader.readAsDataURL(photo);
                reader.onload = () => {
                    let content = reader.result;
                    var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                    var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                    let album = {
                        // extention: photo.type,
                        fileContent: urlStr,
                        fileName: photo.name,
                        fileSaveOrEditable: true
                    };
                    scope.setState({ uploadFile: album });
                }

            }
            // else {
            //     errors["noticeImgContent"] = "File size can't be more than " + maxFileSize / 1048576 + ' MB';
            //     this.setState({ errors });
            // }

        }
        else {
            errors["fileContent"] = "File format not supported";
            this.setState({ errors });
        }

    }

    removeUploadFile = () => {

        let { uploadFile } = this.state;

        uploadFile.fileContent = '';
        uploadFile.fileName = '';
        uploadFile.fileSaveOrEditable = false;

        this.setState({ uploadFile });
    }

    render() {

        let classList = [];
        if (this.state.classDropDownList != null && this.state.classDropDownList.length > 0) {
            classList = this.state.classDropDownList.map(item => ({
                value: item.classId,
                label: item.className
            }));
        }

        let groupList = [];
        if (this.state.groupDropDownList != null && this.state.groupDropDownList.length > 0) {
            groupList = this.state.groupDropDownList.map(item => ({
                value: item.groupId,
                label: item.groupName
            }));
        }

        const yesAutoApproveOption = [
            { label: "Yes", value: 1, },
            { label: "No", value: 0, id: 0 }
        ];
        const noAutoApproveOption = [
            { label: "Yes", value: true, },
            { label: "No", value: false, }
        ];

        let totalFeeServiceCharge = 0;
        const feeAmount = this.state.applicationFee;
        let feeServiceCharge = this.state.applicationServiceCharge;
        // console.log('feeAmount', feeAmount);

        if (feeAmount === NaN || feeAmount === '') {
            totalFeeServiceCharge = 0;
        } else {
            totalFeeServiceCharge += parseFloat(feeAmount) + parseFloat(feeServiceCharge);
        }

        let { dwsUrlInfo, topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let uploadFileLabel = <div>Click / Drag File Here To Upload <br /><span className="upload-file-label">(Max 1MB (recommended))</span></div>
        // console.log('examCenterName', this.state.examCenterName);

        // console.log('applicationStartDate', this.state.applicationStartDate);
        // console.log('admissionExamTime', this.state.admissionExamTime);

        return (
            <div>
                <div>
                    {topProgressBar ? (
                        <ErrorMessageView topProgressBar={topProgressBar} />
                    ) : null}
                    {errorMsgVisible ? (
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                    ) : null}

                    <Growl ref={el => (this.growl = el)} />
                    <style jsx>{`
                        table {
                        font-family: arial, sans-serif;
                        border-collapse: collapse;
                        width: 100%;
                        }

                        td,
                        th {
                        padding: 8px;
                        }

                        tr:nth-child(even) {
                        border-style: hidden;
                        }
                    `}</style>

                    <div className="p-fluid">
                        <div className="p-grid nw-form">

                            <div className="p-grid nw-form">

                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                        <div className="p-grid">

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Serial No.</span>
                                                    <InputText
                                                        placeholder="Enter Serial no."
                                                        name="classConfigSerial"
                                                        value={this.state.classConfigSerial}
                                                        onChange={e => this.setState({ classConfigSerial: e.target.value })}
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.classConfigSerial ? "" : this.state.saveErrors["classConfigSerial"]}</span>

                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {this.state.dataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() :
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Class <span>*</span></span>
                                                        <Dropdown
                                                            placeholder="Select Class"
                                                            options={classList}
                                                            value={this.state.classId}
                                                            onChange={e => this.setState({ classId: e.value })}
                                                            filter={true}
                                                            filterBy="label,value"
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                }
                                                <span className="error-message">{this.state.classId ? "" : this.state.saveErrors["className"]}</span>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                {this.state.secondDataIsLoading ?
                                                    this.NetiContentLoader.normalFormInputField() :
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">Group <span>*</span></span>
                                                        <Dropdown
                                                            placeholder="Select Group"
                                                            options={groupList}
                                                            value={this.state.groupId}
                                                            onChange={e => this.setState({ groupId: e.value })}
                                                            filter={true}
                                                            filterBy="label,value"
                                                            autoWidth={false}
                                                        />
                                                    </div>
                                                }
                                                <span className="error-message">{this.state.groupId ? "" : this.state.saveErrors["groupName"]}</span>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Application Start <span>*</span></span>
                                                    <Calendar
                                                        name="applicationStartDate"
                                                        placeholder="Select Start Date"
                                                        value={this.state.applicationStartDate}
                                                        onChange={e => this.setState({ applicationStartDate: e.value })}
                                                        showIcon={true}
                                                        yearRange="2010:2030"
                                                        dateFormat="dd/mm/yy"
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        dateOnly="true"
                                                        showButtonBar={true}
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.applicationStartDate ? "" : this.state.saveErrors["startDate"]}</span>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Application End <span>*</span></span>
                                                    <Calendar
                                                        name="applicationEndDate"
                                                        placeholder="Select End Date"
                                                        value={this.state.applicationEndDate}
                                                        onChange={e => this.setState({ applicationEndDate: e.value })}
                                                        showIcon={true}
                                                        yearRange="2010:2030"
                                                        dateFormat="dd/mm/yy"
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        dateOnly="true"
                                                        showButtonBar={true}
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.applicationEndDate ? "" : this.state.saveErrors["endDate"]}</span>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon font-size-12">Total Required Applicant <span>*</span></span>
                                                    <InputText
                                                        name="text"
                                                        placeholder="Enter Total number Required Applicant"
                                                        onChange={e => this.setState({ applicantLimit: e.target.value })}
                                                        value={this.state.applicantLimit}
                                                        keyfilter="pint"
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.applicantLimit ? "" : this.state.saveErrors["requiredApplicant"]}</span>
                                                {/* {this.state.proPic.contentName ? "" : errors["logo"]} */}
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon ">Application Form Fee <span>*</span></span>
                                                    <InputText
                                                        name="text"
                                                        placeholder="Enter Application Form Fee"
                                                        onChange={this.handlefeeValue}
                                                        value={this.state.applicationFee}
                                                        keyfilter="money"
                                                    />
                                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">{totalFeeServiceCharge} BDT</span>
                                                </div>
                                                <span className="error-message">{this.state.applicationFee ? "" : this.state.saveErrors["formFee"]}</span>
                                            </div>
                                            &nbsp; &nbsp; {this.state.applicationServiceCharge} Taka charge will be included with Fee

                                            <div className="p-col-12 p-fluid">
                                                <br />
                                                <span>
                                                    <label htmlFor="resolution-username">Do you need Applicant Previous Exam Info?</label>
                                                    <br />
                                                    <RadioButton
                                                        inputId="rb1"
                                                        name="previousExamInfo"
                                                        value={true}
                                                        onChange={this.handlepreviousExamInfo}
                                                        checked={this.state.previousExamInfo === true}
                                                    />
                                                    <label htmlFor="rb1" className="p-radiobutton-label">Yes</label>
                                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                                        <RadioButton
                                                        inputId="rb2"
                                                        name="previousExamInfo"
                                                        value={false}
                                                        onChange={this.handlepreviousExamInfo}
                                                        checked={this.state.previousExamInfo === false}
                                                    />
                                                    <label htmlFor="rb2" className="p-radiobutton-label">No</label>
                                                </span>
                                                <br />
                                            </div>

                                            {/* Previous Exam Info (if yes) required list */}
                                            {this.state.previousExamInfo ? (
                                                <div className="p-col-12 p-fluid">

                                                    <h2>Required Information</h2>

                                                    <table>
                                                        <tr>
                                                            <td>★ Institute Name</td>
                                                            <td>★ Exam</td>
                                                        </tr>
                                                        <tr>
                                                            <td>★ Board</td>
                                                            <td>★ Grade</td>
                                                        </tr>
                                                        <tr>
                                                            <td>★ Class</td>
                                                            <td>★ GPA</td>
                                                        </tr>
                                                        <tr>
                                                            <td>★ Roll No</td>
                                                            <td>★ Passing Year</td>
                                                        </tr>
                                                    </table>

                                                </div>
                                            ) : ("")}

                                            {/* Previous Exam Info (if yes) required list End */}

                                            <div className="p-col-12 p-fluid">
                                                <span>
                                                    <label htmlFor="resolution-username">Do you need Applicant Assesment?</label>
                                                    <br />
                                                    <RadioButton
                                                        inputId="rb3"
                                                        name="previousAdmissionInfo"
                                                        value={true}
                                                        onChange={this.handlepreviousAdmissionInfo}
                                                        checked={this.state.previousAdmissionInfo === true}
                                                    />
                                                    <label htmlFor="rb3" className="p-radiobutton-label">Yes</label>
                                                    &nbsp;&nbsp; &nbsp;&nbsp;

                                                                <RadioButton
                                                        inputId="rb4"
                                                        name="previousAdmissionInfo"
                                                        value={false}
                                                        onChange={this.handlepreviousAdmissionInfo}
                                                        checked={this.state.previousAdmissionInfo === false}
                                                    />
                                                    <label htmlFor="rb4" className="p-radiobutton-label">No</label>
                                                </span>

                                                <br />
                                            </div>

                                            {/* Applicant Assesment (if yes) Exam Part */}
                                            {this.state.previousAdmissionInfo ?
                                                <div className="p-col-12 p-fluid">

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Exam Center Name<span>*</span></span>
                                                            <InputText
                                                                name="examCenterName"
                                                                placeholder="Enter Exam Center Name"
                                                                onChange={e => this.setState({ examCenterName: e.target.value })}
                                                                value={this.state.examCenterName}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.saveErrors["examCenterName"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Exam Date<span>*</span></span>
                                                            <Calendar
                                                                name="admissionExamDate"
                                                                dateFormat="dd/mm/yy"
                                                                placeholder="Select Exam Date"
                                                                value={this.state.admissionExamDate}
                                                                onChange={e => this.setState({ admissionExamDate: e.value })}
                                                                showIcon={true}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.saveErrors["admissionExamDate"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">Exam Time <span>*</span></span>
                                                            <Calendar
                                                                name="admissionExamTime"
                                                                placeholder="Select Exam Time"
                                                                value={this.state.admissionExamTime}
                                                                onChange={e => this.setState({ admissionExamTime: e.value })}
                                                                showIcon={true}
                                                                timeOnly
                                                                hourFormat="12"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.saveErrors["admissionExamTime"]}</span>
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Exam Instruction <span>*</span></span>
                                                            <InputTextarea
                                                                value={this.state.admissionExamInstruction}
                                                                onChange={e => this.setState({ admissionExamInstruction: e.target.value })}
                                                                placeholder="Write Exam Instruction"
                                                                rows={5}
                                                                cols={30}
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.saveErrors["examInstruction"]}</span>
                                                    </div>
                                                    {/* Applicant Assesment (if yes) Exam Part End*/}
                                                </div>
                                                :
                                                ''}

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon font-size-12" style={{ minWidth: "250px" }}>Auto Approve for Admission Test</span>
                                                    <Dropdown
                                                        options={yesAutoApproveOption}
                                                        value={this.state.autoApproveStatus}
                                                        onChange={this.handlenoAutoApprove}
                                                        filter={true}
                                                        filterBy="label,value"
                                                        autoWidth={false}
                                                        showClear={true}
                                                    />
                                                </div>
                                            </div>

                                            {this.state.autoApproveStatus ? (
                                                <div className="p-col-12 p-fluid">
                                                    <label htmlFor="resolution-username">By Selecting 'Yes'</label>{" "}
                                                    <br />
                                                    You can Find these applicants automatically
                                                    into the (Approval / Assesment / Exam Mark) 'Exam Mark List'
                                                    table. And applicants able to collect their
                                                    'Admit Crad' from the admisia website for
                                                    participating into the 'Admission Test'
                                                    after completing online submission &
                                                    payment.
                                                </div>
                                            ) : (
                                                    <div className="p-col-12 p-fluid">
                                                        <label htmlFor="resolution-username">By Selecting 'No'</label>{" "}
                                                        <br />
                                                        You can find these applicants into the
                                                        (Approval / Assesment / Pending Applicants) 'Assesment Pending Applicant List'
                                                                            table. <p />
                                                        And you are able to approve the applicants
                                                        manually, to give allowance for 'Admission
                                                        Test' and for downloading 'Admit Card'.
                                                    </div>
                                                )}

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon ">Signature Title<span>*</span></span>
                                                    <InputText
                                                        name="signatureTitle"
                                                        placeholder="Enter Signature Title"
                                                        onChange={e => this.handleSignatureTitle(e)}
                                                        value={this.state.signatureTitle}
                                                    />
                                                </div>
                                                <span className="error-message">{this.state.signatureTitle ? "" : this.state.saveErrors["signatureTitle"]}</span>
                                            </div>

                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup nw-upload-button">
                                                    <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                        Upload File <span>*</span>
                                                        <br />
                                                        <span>(PNG/JPG/JPEG/PDF)</span>
                                                    </span>

                                                    <div className="nw-upload-button-inside">

                                                        {
                                                            this.state.imageInputFieldIsLoading ?
                                                                <div className="p-col-12">{this.NetiContentLoader.updateFileLoader()} </div> :
                                                                this.state.uploadFile.fileContent ?
                                                                    <div className="image-view-main">
                                                                        <div className="upload-image-view">
                                                                            <Button
                                                                                className="delete-upload-button"
                                                                                icon="fas fa-times-circle"
                                                                                onClick={this.removeUploadFile}
                                                                            />

                                                                            {
                                                                                this.state.uploadFile.fileName ?
                                                                                    this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadFile.fileName)
                                                                                    :
                                                                                    ''
                                                                                // this.CommonFuctionality.getFileContentTypeIcon(this.state.updateUserObj.fileName)
                                                                            }

                                                                        </div>
                                                                        <div className="image-title">{this.state.uploadFile.fileName}</div>
                                                                    </div>

                                                                    :
                                                                    <FileUpload
                                                                        chooseLabel={this.state.uploadFile.fileName || uploadFileLabel}
                                                                        accept="image/*, .pdf"
                                                                        id="fileUpload"
                                                                        mode="advanced"
                                                                        maxFileSize="2000000"
                                                                        name="uploadFile"
                                                                        onSelect={e => this.onLoadPic(e, 1000000)}
                                                                        auto={true}
                                                                    />
                                                        }

                                                    </div>
                                                </div>
                                                <span className='error-message'><Messages ref={(el) => this.messages = el}></Messages></span>
                                                <span className='error-message'>{this.state.uploadFile.fileContent ? null : this.state.saveErrors.fileContent}</span>
                                            </div>

                                        </div>

                                        <div className="p-col-1"> </div>
                                        <div className="p-col-5"> </div>
                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">(<span>*</span>) required fields</div>

                                            <Button
                                                label="Save"
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                icon="fas fa-check"
                                                onClick={this.handleSave}
                                            />
                                        </div>
                                        <div className="p-col-5"> </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}