import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { SeatInfoList } from './SeatInfoList';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import CommonFuctionality from '../../common/CommonFuctionality';
import { InputTextarea } from 'primereact/inputtextarea';
import { Editor } from 'primereact/editor';
import staticImg from '../../../assets/images/avatar.png';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { RadioButton } from 'primereact/radiobutton';
import { ImageCropper } from '../../common/ImageCropper';

let dialogStatus = false;
let errors = {};
let dropDownListName1 = '';
let dropDownListName2 = '';
let cloneStateBeforeMount;
let urlId;
export class AdminSpeechInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {

            dialogType: '',
            dataTableValue: [],
            insertUserObj: {
                headerName: false,
                speechSerial: 0,
                speakerName: '',
                speakerDesignation: '',
                speakerMobile: '',
                speakerEmail: '',
                speakerFacebookLinke: '',
                speakerLinkedinLinke: '',
                speakerTwitterLinke: '',
                speechDetails: '',
                speechStatus: 0,
                welcomeSpeechStatus: 0,
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false,
                cmsId: 0,
            },
            updateUserObj: {
                speechSerial: 0,
                speakerName: '',
                speakerDesignation: '',
                speakerMobile: '',
                speakerEmail: '',
                speakerFacebookLinke: '',
                speakerLinkedinLinke: '',
                speakerTwitterLinke: '',
                speechDetails: '',
                speechStatus: 0,
                welcomeSpeechStatus: 0,
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false,
                cmsId: 0,
            },
            uploadFile: {
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            errors: {},
            homeReturnButton: true,
            dataTableIsLoading: false,
            imageInputFieldIsLoading: false,
            totalDataFound: 0,
            deleteDialogVisible: false,

            cropperVisible: false,
            cropperObject: {
                //main class parameter
                main: {
                    viewport: { width: 180, height: 210 },
                    boundary: { width: 600, height: 480 },
                    showZoomer: true,
                    enableOrientation: false,
                },
                //bind parameter
                bind: {
                    url: '',
                    orientation: 4
                }

            }
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.NetiFileHandler = new NetiFileHandler();
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {
        urlId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {
            let { insertUserObj, updateUserObj } = this.state
            insertUserObj.cmsId = urlId;
            updateUserObj.cmsId = urlId;
            this.setState({ homeReturnButton: false, insertUserObj, updateUserObj })

            this.viewSpeechInfoList();
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    viewSpeechInfoList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        // let coreUrl = {
        //     coreUrlInfoID: urlId,
        //     pageNo: 0,
        //     limit: 10
        // }

        this.DwsService.cmsSpeechInfoList(urlId)
            .then((res) => {
                return res.json().then((body) => {
                    if (body.messageType === 1) {
                        this.setState({ dataTableValue: body.item });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    }
                    else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                })
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    showMainForm = (dialogType, rowData) => {

        let { uploadFile } = this.state
        if (dialogType == "update") {
            this.setState({ headerName: true });
            // this.downloadFile(rowData, "update")
            uploadFile.fileContent = rowData.fileContent
            uploadFile.fileName = rowData.fileName
            uploadFile.fileSaveOrEditable = true
            Object.assign(this.state.updateUserObj, rowData)
        }
        else {
            this.setState({ headerName: false });
            this.removeUploadFile()
        }

        this.setState({ visible: true, dialogType: dialogType, errors: {} });
        // this.CommonFuctionality.blurDialogBackgroundActive()
    }

    showDeleteDialog = (dialogType, rowData) => {
        this.setState({ deleteDialogVisible: true, deleteUserObj: rowData });
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    onHide = () => {
        this.setState({ visible: false, deleteDialogVisible: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.hideImageCropper();
    }

    actionTemplate = (rowData, column) => {
        return <div>
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                tooltip="Update"
                onClick={(e) => this.showMainForm('update', rowData)}
            />
            <Button
                type="button"
                icon="fas fa-trash"
                tooltip="Delete"
                className="nw-action-button p-button-danger"
                onClick={(e) => this.showDeleteDialog('delete', rowData)}
            />
        </div>;
    }

    speechInfoBody = (rowData) => {
        // let staticImg = 'assets/layout/images/avatar.png';

        return (
            <div className="p-grid">
                <div className="p-col-4 p-col-nogutter" style={{ maxWidth: "78px", width: 'auto' }}>
                    {
                        rowData.fileContent ?
                            <img src={("data:image/*;base64," + rowData.fileContent)} width="100%" /> :
                            <img src={staticImg} width="100%" />
                    }
                </div>

                <div className="p-col-8 nw-datatable-dataview-small-panel">
                    <div>
                        <div>Name</div>
                        <div>:</div>
                        <div>{rowData.speakerName}</div>
                    </div>

                    <div>
                        <div>Designation</div>
                        <div>:</div>
                        <div>{rowData.speakerDesignation}</div>
                    </div>

                    <div>
                        <div>Mobile No.</div>
                        <div>:</div>
                        <div>{rowData.speakerMobile}</div>
                    </div>

                    <div>
                        <div>Email</div>
                        <div>:</div>
                        <div>{rowData.speakerEmail}</div>
                    </div>
                </div>

            </div>

        )
    }

    welcomeSpeechStatusCheck = (rowData) => {
        let switchButtonEnable = "switch-button-yes", switchButtonYes, switchButtonNo, toggleOnOff;
        if (rowData.welcomeSpeechStatus === 1) {
            switchButtonYes = "switch-button " + switchButtonEnable
            toggleOnOff = "fas fa-toggle-on switch-button-yes"
        } else {
            switchButtonNo = "switch-button " + switchButtonEnable
            toggleOnOff = "fas fa-toggle-off switch-button-no"
        }
        return (
            <div className="input-switch-button">
                <span className={switchButtonNo}>No </span> <i class={toggleOnOff} /> <span className={switchButtonYes}> Yes</span>
            </div>
        )
    }

    formDialog = () => {

        let { insertUserObj, updateUserObj, dialogType, errors } = this.state
        let userObj = { ...insertUserObj }
        if (dialogType == 'insert') {
            userObj = { ...insertUserObj }
        }
        else if (dialogType == 'update') {
            userObj = { ...updateUserObj }
        }

        let uploadFileLabel = <div>Click / Drag File Here To Upload <br /><span className="upload-file-label">(Max 500 KB (recommended))</span></div>

        return (
            <Dialog
                className="nw-dialog"
                header={this.state.headerName === true ? 'Update Speech Information' : 'Add Speech Information'}
                visible={this.state.visible}
                onHide={this.onHide}
                closable
                maximizable
            >
                <div className="p-fluid">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Serial No.</span>

                                        {dialogType === "update" ?
                                            <InputText
                                                placeholder="Enter Serial no."
                                                name="speechSerial"
                                                value={userObj.speechSerial}
                                                onChange={e => this.onChageInputValue(e, dialogType)}
                                            />
                                            :

                                            <div className="nw-inputgroup-desc">
                                                {userObj.speechSerial || this.state.dataTableValue.length + 1}
                                            </div>
                                        }
                                    </div>

                                    {dialogType == "update" ? <span className="error-message">{errors["speechSerial"]}</span> : ''}

                                </div>
                                {/* :
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No. <span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Serial"
                                                    name="speechSerial"
                                                    value={userObj.speechSerial}
                                                    onChange={e => this.onChageInputValue(e, dialogType)}
                                                />
                                            </div>
                                            <span className="error-message">{errors["speechSerial"]}</span>
                                        </div>
                                } */}

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Speaker Name <span>*</span></span>
                                        <InputText
                                            placeholder="Enter Speaker Name"
                                            autoWidth={false}
                                            name="speakerName"
                                            value={userObj.speakerName}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["speakerName"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Designation <span>*</span></span>
                                        <InputText
                                            placeholder="Enter Designation"
                                            autoWidth={false}
                                            name="speakerDesignation"
                                            value={userObj.speakerDesignation}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["speakerDesignation"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Mobile No.<span></span></span>
                                        <InputText
                                            placeholder="Enter Mobile No."
                                            autoWidth={false}
                                            keyfilter="pint"
                                            maxlength={11}
                                            name="speakerMobile"
                                            value={userObj.speakerMobile}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["speakerMobile"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Email <span></span></span>
                                        <InputText
                                            placeholder="Enter Email"
                                            autoWidth={false}
                                            keyfilter="email"
                                            maxlength={100}
                                            name="speakerEmail"
                                            value={userObj.speakerEmail}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["speakerEmail"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Facebook Link</span>
                                        <InputText
                                            placeholder="Enter Facebook Link"
                                            autoWidth={false}
                                            name="speakerFacebookLinke"
                                            value={userObj.speakerFacebookLinke}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["speakerFacebookLinke"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Linkedin Link</span>
                                        <InputText
                                            placeholder="Enter Linkedin Link"
                                            autoWidth={false}
                                            name="speakerLinkedinLinke"
                                            value={userObj.speakerLinkedinLinke}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["speakerLinkedinLinke"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Twitter Link</span>
                                        <InputText
                                            placeholder="Enter Twitter Link"
                                            autoWidth={false}
                                            name="speakerTwitterLinke"
                                            value={userObj.speakerTwitterLinke}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        />
                                    </div>
                                    <span className="error-message">{errors["speakerTwitterLinke"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Speech Type <span>*</span></span>
                                        <div className="nw-inputgroup-desc desc-no-style">{this.eventLogSelector(userObj.welcomeSpeechStatus, dialogType)}</div>
                                        {/* <InputText
                                            placeholder="Enter Twitter Link"
                                            autoWidth={false}
                                            name="welcomeSpeechStatus"
                                            value={userObj.welcomeSpeechStatus}
                                            onChange={e => this.onChageInputValue(e, dialogType)}
                                        /> */}
                                    </div>
                                    <span className="error-message">{errors["welcomeSpeechStatus"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputtexteditor-label">Description <span>*</span></span>
                                        <Editor
                                            className="nw-inputtexteditor"
                                            name="speechDetails"
                                            value={userObj.speechDetails}
                                            onTextChange={(e) => this.onChangeDescription(e, dialogType)}
                                        />
                                    </div>
                                    <span className='error-message'>{errors["speechDetails"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup nw-upload-button">
                                        <span className="p-inputgroup-addon nw-inputtextarea-label">
                                            Upload Image
                                            <br />
                                            <span>(PNG/JPG/JPEG)</span>
                                        </span>

                                        <div className="nw-upload-button-inside">

                                            {
                                                this.state.imageInputFieldIsLoading ?
                                                    <div className="p-col-12">{this.NetiContentLoader.updateFileLoader()} </div> :
                                                    this.state.uploadFile.fileContent ?
                                                        <div className="image-view-main">
                                                            <div className="upload-image-view">
                                                                <Button
                                                                    className="delete-upload-button"
                                                                    icon="fas fa-times-circle"
                                                                    onClick={this.removeUploadFile}
                                                                />

                                                                {/* {
                                                                        this.state.uploadFile.fileName ?
                                                                            this.CommonFuctionality.getFileContentTypeIcon( this.state.uploadFile.fileName)
                                                                            : 
                                                                            this.CommonFuctionality.getFileContentTypeIcon(this.state.updateUserObj.fileName)
                                                                    } */}
                                                                <img
                                                                    src={
                                                                        this.state.uploadFile.fileName ?
                                                                            "data:image/*;base64," + this.state.uploadFile.fileContent
                                                                            :
                                                                            "data:image/*;base64," + this.state.updateUserObj.fileContent
                                                                    }
                                                                    style={{ "height": "80px" }}
                                                                />

                                                            </div>
                                                            <div className="image-title">{this.state.uploadFile.fileName}</div>
                                                        </div>

                                                        :
                                                        <FileUpload
                                                            chooseLabel={this.state.uploadFile.fileName || uploadFileLabel}
                                                            id="fileUpload"
                                                            mode="basic"
                                                            accept="image/*"
                                                            name="uploadFile"
                                                            onSelect={e => this.onLoadPic(e, dialogType)}
                                                            auto={true}
                                                        />
                                            }

                                        </div>

                                        {/* <center>
                                                {this.state.uploadImage.contentName}
                                            </center> */}
                                    </div>

                                    {
                                        this.state.uploadFile && this.state.uploadFile.fileContent && this.state.cropperVisible ?
                                            <ImageCropper
                                                cropperObject={this.state.cropperObject}
                                                getCroppedResult={this.getCroppedResult}
                                                removeCropSection={this.removeCropSection}
                                            />
                                            : null
                                    }
                                    <span className='error-message'>{this.state.uploadFile.fileName ? null : this.state.errors.noticeImgContent}</span>
                                </div>

                                <div className="p-col-12 p-xl-12" />

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                    <div className="required-field">
                                        (<span>*</span>) required fields
                                    </div>

                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-left"
                                        label={dialogType == "insert" ? "Save" : "Update"}
                                        icon="fas fa-check"
                                        disabled={this.state.cropperVisible ? true : false}
                                        onClick={() => this.onSubmitHandler(dialogType)}
                                    />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </Dialog>
        )
    }

    deleteDialogView = () => {
        return (
            <Dialog header="Delete Speech Info" visible={this.state.deleteDialogVisible} onHide={this.onHide} className="nw-confirm-dialog">
                <div className="p-fluid">
                    <div className="p-col-12 p-col-12">
                        <div className="confirm-wrapper">
                            {/* <div className="body-wrapper">
                                <header>Total messages you are about to send</header>
                                <h1>{selectedCustomers.length}</h1>
                                <footer>{selectedCustomers.length}  Person x {selectedCustomers.length} Message</footer>
                            </div> */}
                            <h1>Are You Sure?</h1>
                            <div className="nw-button-parent-multiple">
                                <Button
                                    className="p-button-danger nw-button nw-button-multiple"
                                    label="No"
                                    icon="fas fa-times"
                                    onClick={this.onHide}
                                />
                                <Button
                                    className="p-button-primary nw-button nw-button-multiple"
                                    label="Yes"
                                    icon="fas fa-check"
                                    onClick={e => this.deleteRow(this.state.deleteUserObj)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }

    onChageInputValue = (e, action) => {
        // console.log(e.target);
        let { updateUserObj, insertUserObj } = this.state;

        if (action == 'insert') {
            insertUserObj[e.target.name] = e.target.value;
        }
        else if (action == 'update') {
            updateUserObj[e.target.name] = e.target.value;
        }

        this.setState({ updateUserObj, insertUserObj });
        this.onClearErrorMsg(e.target.name);
    }

    onChangeDescription = (e, action) => {
        let { updateUserObj, insertUserObj } = this.state;

        if (action == 'insert') {
            insertUserObj["speechDetails"] = e.htmlValue
        }
        else if (action == 'update') {
            updateUserObj["speechDetails"] = e.htmlValue
        }

        this.setState({ updateUserObj, insertUserObj })
        this.onClearErrorMsg("speechDetails");
    }

    eventLogSelector = (welcomeSpeechStatus, dialogType) => {
        return (
            <div className="input-radio-button">
                <div className="radio-button-inside">
                    <RadioButton
                        name="welcomeSpeechStatus"
                        value={0}
                        checked={welcomeSpeechStatus == 0}
                        onChange={(e) => this.onChageInputValue(e, dialogType)}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Speech</label>
                </div>

                <div className="radio-button-inside">
                    <RadioButton
                        name="welcomeSpeechStatus"
                        value={1}
                        checked={welcomeSpeechStatus == 1}
                        onChange={(e) => this.onChageInputValue(e, dialogType)}
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">Welcome Speech</label>
                </div>
            </div>
        )
    }

    onClearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    handleError = (objectHandle, dialogType) => {

        // console.log('dialogType-emptyHandle', dialogType);
        let { errors } = this.state;
        let formIsValid = true;

        if (dialogType === 'update') {

            if (objectHandle.speechSerial === '' || objectHandle.speechSerial === null) {
                formIsValid = false;
                errors["speechSerial"] = "Serial No. can't left empty.";
            }
        }

        if (!objectHandle.speakerName) {
            formIsValid = false;
            errors["speakerName"] = "Name can't left empty.";
        }

        if (!objectHandle.speakerDesignation) {
            formIsValid = false;
            errors["speakerDesignation"] = "Designation can't left empty.";
        }

        // if (!objectHandle.speakerMobile) {
        //     formIsValid = false;
        //     errors["speakerMobile"] = "Mobile No. can't left empty.";
        // }

        // if (!objectHandle.speakerEmail) {
        //     formIsValid = false;
        //     errors["speakerEmail"] = "Email can't left empty.";
        // }

        // console.log('objectHandle.speechDetails', objectHandle.speechDetails);
        if (objectHandle.speechDetails === '' || objectHandle.speechDetails === null) {
            formIsValid = false;
            errors["speechDetails"] = "Description can't left empty.";
        }

        // if (!objectHandle.speakerFacebook) {
        //     formIsValid = false;
        //     errors["speakerFacebook"] = "Facebook Link can't left empty.";
        // }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitHandler = (dialogType) => {

        let { insertUserObj, updateUserObj } = this.state;

        let userObj = {}
        if (dialogType == 'insert') {
            userObj = { ...insertUserObj }
            userObj.speechSerial = this.state.dataTableValue.length + 1;
        }
        else if (dialogType == 'update') {
            userObj = { ...updateUserObj }
        }
        // console.log(dialogType, insertUserObj, updateUserObj);

        if (this.handleError(userObj, dialogType)) {

            this.setState({ dataTableIsLoading: false, topProgressBar: true, errorMsgVisible: false });

            this.DwsService.cmsInserAndUpdateSpeechInfo(dialogType, userObj)
                .then(res => {
                    return res.json().then((body) => {
                        // console.log('Body', body);
                        if (body.messageType === 1) {
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                            this.growl.show({ severity: 'success', summary: 'Success', detail: body.message });

                            this.onHide()
                            setTimeout(() => {
                                this.viewSpeechInfoList()
                            }, 1000);
                        }
                        else {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }
                    })
                }).catch(error => {
                    // errors["dropDownClassError"] = 'Connection Problem'
                    this.setState({ errors: errors })
                    this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                });
        }
    }

    deleteRow = (userObj) => {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.DwsService.cmsDeleteSpeechInfo(userObj, urlId)
            .then(res => {
                return res.json().then((body) => {
                    // console.log('Body', body);
                    if (body.messageType === 1) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: body.message });

                        this.viewSpeechInfoList()
                        setTimeout(() => {
                            this.onHide()
                        }, 1000);
                    }
                    else {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                    }
                })
            }).catch(error => {
                // errors["dropDownClassError"] = 'Connection Problem'
                this.setState({ errors: errors })
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

            });
    }

    downloadFile = (rowData, funcType) => {
        let { uploadFile } = this.state
        if (rowData.fileName) {
            this.setState({ imageInputFieldIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            this.DwsService.downloadCornerGetByteImage(rowData.speechId).then(res => {
                if (res.status == 302) {
                    return res.json().then(body => {
                        let contentType = this.NetiFileHandler.getImageContentType(rowData.fileName);

                        if (funcType == "download") {
                            let a = document.createElement("a");
                            a.href = contentType + body.file;
                            a.download = rowData.fileName;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                        else {
                            uploadFile.fileName = rowData.fileName
                            uploadFile.fileContent = body.file
                            uploadFile.fileSaveOrEditable = true
                            this.setState({ uploadFile })
                        }
                        this.setState({ imageInputFieldIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.setState({ imageInputFieldIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: "Unable to download file" })
                }
            });
        }
    }

    removeUploadFile = () => {
        let { uploadFile, insertUserObj, updateUserObj } = this.state;

        uploadFile.fileContent = '';
        uploadFile.fileName = '';
        uploadFile.fileSaveOrEditable = false;

        this.setState({ uploadFile, insertUserObj, updateUserObj });

        Object.assign(insertUserObj, uploadFile);
        Object.assign(updateUserObj, uploadFile);
    }

    removeCropSection = () => {
        this.setState({ cropperVisible: false });
        this.removeUploadFile();
    }

    onLoadPic = (e, dialogType) => {

        let { errors, cropperObject } = this.state;

        let fileType = this.NetiFileHandler.getFileContentType(e.files[0].name);
        let supportedExtention = ['image/jpeg', 'image/jpg', 'image/png'];
        // console.log('fileType', fileType);

        if (supportedExtention.includes(fileType)) {

            var reader = new FileReader();
            let photo = e.files[0];
            const scope = this;
            reader.readAsDataURL(photo);
            reader.onload = () => {
                let content = reader.result;
                var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                let album = {
                    // extention: photo.type,
                    fileContent: urlStr,
                    fileName: photo.name,
                    fileSaveOrEditable: true
                };
                scope.setState({ uploadFile: album });

                if (dialogType == "insert") {
                    Object.assign(this.state.insertUserObj, album);
                }
                else {
                    Object.assign(this.state.updateUserObj, album);
                }
            }

            errors["noticeImgContent"] = ''

            cropperObject.bind.url = photo.objectURL;
            this.setState({ errors, cropperObject, cropperVisible: true });

        }
        else {
            errors["noticeImgContent"] = "File format not supported";
            this.setState({ errors });
        }

    }

    getCroppedResult = (imageObj) => {

        let { uploadFile, errors, dialogType, insertUserObj, updateUserObj } = this.state;
        // console.log('cropped imageObj', imageObj);

        let maxSize = this.NetiFileHandler.getMaxFileSizeIsValid(imageObj.photoBlob.size, 500000);
        if (maxSize) {

            uploadFile.fileContent = imageObj.contentPic;
            errors["noticeImgContent"] = '';

            if (dialogType === 'insert') { insertUserObj.fileContent = imageObj.contentPic; }
            else { updateUserObj.fileContent = imageObj.contentPic; }

        } else {
            errors["noticeImgContent"] = "Image size can't be more than 500 KB";
            uploadFile.fileContent = '';
            uploadFile.fileName = '';
            uploadFile.fileSaveOrEditable = false;
        }

        this.setState({ uploadFile, errors, cropperVisible: false });

    }

    hideImageCropper = () => {
        this.setState({ cropperVisible: false });
    }

    render() {

        let { insertUserObj, updateUserObj, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        // console.log('dataTableValue', dataTableValue);
        let tableHeader = <div className="header-title">
            <div className="header-title-left">Speech Info List</div>
            <div className="header-title-right">
                <a>
                    Total Found: {dataTableValue.length.toLocaleString("EN-IN")}
                </a>

                <Button
                    className="nw-button nw-button-center p-button-success"
                    name="district"
                    label="Add New"
                    icon="fas fa-plus"
                    iconPos="right"
                    onClick={() => this.showMainForm('insert', null)}
                />
            </div>
        </div>;

        return (

            <div className="p-fluid">
                <NetiCMSSetupProgress />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="main-section blur-section">
                    {this.state.homeReturnButton === false ?

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column field="speechSerial" header="Serial No." filter={true} style={{ width: '100px' }} />
                                    <Column field={"speakerName"} header="Speech Info" body={this.speechInfoBody} filter={true} />
                                    <Column field="" header="Welcome Speech" body={this.welcomeSpeechStatusCheck} style={{ width: '8em' }} />
                                    <Column field="action" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                </DataTable>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    {this.formDialog()}
                    {this.deleteDialogView()}
                </div>
            </div>
        )
    }
}