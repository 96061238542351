import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { FeesInfoList } from './FeesInfoList';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';

const paymentMode = [
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Half Yearly", value: "Half Yearly" },
    { label: "Yearly", value: "Yearly" },
    { label: "Exam Wise", value: "Exam Wise" }
]

let errors = {};
let dropDownListName1 = '';
let dropDownListName2 = '';
let urlId;
export class FeesInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serialNumber: '',
            coreClassCategoryID: '',
            coreGroupCategoryID: '',
            feeName: '',
            details: '',
            feeAmount: '',
            paymentMode: '',
            classDropDownList: null,
            groupDropDownList: null,
            feeSubmitObject: {
                feeSerial: 0,
                feeName: "",
                feeDetails: "",
                feeAmount: 0,
                feePaymentMode: "",
                totalDataFound: 0,
                classCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                groupCategoryInfoDTO: {
                    coreCategoryID: ''
                },
                "coreUrlInfoDTO": {
                    "urlInfoID": 1
                }
            },
            feeInfoTableData: [],
            errors: {},
            dataTableIsLoading: false,
            firstDropDownIsLoading: false,
            secondDropDownIsLoading: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            homeReturnButton: false
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();

        this.onChangeClass = this.onChangeClass.bind(this);
        this.onChangeGroup = this.onChangeGroup.bind(this);
        this.onChangePaymentMode = this.onChangePaymentMode.bind(this);
        this.onChangeInputValue = this.onChangeInputValue.bind(this);
        this.reloadCoreUrlIdFromLocal();

    }

    reloadCoreUrlIdFromLocal() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }


    componentWillMount() {
        urlId = this.DwsService.getUrlInfoFromLocalStorage();

        if (urlId) {
            let { coreUrlInfoDTO } = this.state.feeSubmitObject
            coreUrlInfoDTO.urlInfoID = urlId;
            this.setState({ coreUrlInfoDTO })
            this.setState({ homeReturnButton: false })
            this.getClassOrGroupListByDefaultCode('T108');
            this.getClassOrGroupListByDefaultCode('T109');
        }
        else {
            this.setState({ homeReturnButton: true })
        }

    }

    getClassOrGroupListByDefaultCode(defaultCode) {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, firstDropDownIsLoading: true, secondDropDownIsLoading: true, errorMsgVisible: false });

        this.UserCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        if (defaultCode === 'T108') {
                            this.setState({ classDropDownList: body });
                        }
                        else if (defaultCode === 'T109') {
                            this.setState({ groupDropDownList: body });
                        }
                        this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else if (res.status == 404) {
                    return res.json().then((body) => {
                        errors[dropDownListName1] = body.message
                        this.setState({ errors: errors })
                        this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.DwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                errors[dropDownListName1] = 'Connection Problem'
                this.setState({ errors: errors })
                this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onChangeClass = (e) => {
        errors = {}
        this.setState({ coreClassCategoryID: e.value })
    }

    onChangeGroup = (e) => {
        errors = {}
        this.setState({ coreGroupCategoryID: e.value })
    }

    onChangePaymentMode = (e) => {
        errors = {}
        this.setState({ paymentMode: e.value })
    }

    onChangeInputValue = (e) => {
        errors = {}
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleError() {
        let formIsValid = true;

        if (this.state.coreClassCategoryID === '' || this.state.coreClassCategoryID === null) {
            formIsValid = false;
            errors["classError"] = "Class can't left empty.";
        }
        if (this.state.coreGroupCategoryID === '' || this.state.coreGroupCategoryID === null) {
            formIsValid = false;
            errors["groupError"] = "Group can't left empty.";
        }
        if (this.state.feeName === '') {
            formIsValid = false;
            errors["feeName"] = "Fee Name can't left empty.";
        }
        if (this.state.details === '') {
            formIsValid = false;
            errors["details"] = "Details can't left empty.";
        }
        if (this.state.feeAmount === '') {
            formIsValid = false;
            errors["feeAmount"] = "Fee Amount can't left empty.";
        }
        if (this.state.paymentMode === '' || this.state.paymentMode === null) {
            formIsValid = false;
            errors["paymentMode"] = "Payment Mode can't left empty.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    feeOnSubmitHandler = () => {
        let { feeSubmitObject, totalDataFound } = this.state
        let { classCategoryInfoDTO, groupCategoryInfoDTO } = this.state.feeSubmitObject

        if (this.handleError()) {
            this.setState({ firstDropDownIsLoading: true, secondDropDownIsLoading: true, dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
            // feeSubmitObject.feeSerial = this.state.serialNumber
            feeSubmitObject.feeSerial = totalDataFound + 1;
            classCategoryInfoDTO.coreCategoryID = this.state.coreClassCategoryID
            groupCategoryInfoDTO.coreCategoryID = this.state.coreGroupCategoryID
            feeSubmitObject.feeName = this.state.feeName
            feeSubmitObject.feeDetails = this.state.details
            feeSubmitObject.feeAmount = this.state.feeAmount
            feeSubmitObject.feePaymentMode = this.state.paymentMode

            this.DwsService.studentFeeInformationSubmit(feeSubmitObject)
                .then(res => {
                    if (res.status == 201) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data saved' });
                        this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    }
                    else {
                        this.DwsService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                            });
                    }
                }).catch(error => {
                    this.setState({ firstDropDownIsLoading: false, secondDropDownIsLoading: false, dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }




    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let { classCategoryInfoDTO, groupCategoryInfoDTO } = this.state;

        let classList = [];
        if ((this.state.classDropDownList != null) && (this.state.classDropDownList.length > 0)) {
            classList = this.state.classDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let groupList = [];
        if ((this.state.groupDropDownList != null) && (this.state.groupDropDownList.length > 0)) {
            groupList = this.state.groupDropDownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName,
            }));
        }

        let feesSerialNo = [];
        (getFeesSerialFieldData => {
            for (let i = 0; i < 50; i++) {
                feesSerialNo[i] = { label: i + 1, value: i + 1 }
            }
        })()

        return (
            <div className="p-fluid" >
                <NetiCMSSetupProgress/>
                
                <Growl ref={(el) => this.growl = el} />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                {this.state.homeReturnButton === false ?
                    <div className="main-section">
                        <div className="p-grid nw-form" >
                            
                            <div className="p-col-12 p-xl-12 blur-section">

                                <div className="nw-form-body">

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Serial No. </span>
                                            <div className="nw-inputgroup-desc">
                                                {this.state.totalDataFound ? (this.state.totalDataFound + 1) : 1}
                                            </div>
                                        </div>
                                        <span className="error-message">{errors["serialNumber"]}</span>
                                    </div>



                                    <div className="p-col-12 p-xl-12">
                                        {this.state.firstDropDownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Class <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Class"
                                                    value={this.state.coreClassCategoryID}
                                                    options={classList}
                                                    onChange={this.onChangeClass}
                                                    // itemTemplate={this.bankTemplate}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                        }
                                        <span className='error-message'>{errors['classError']}</span>

                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.secondDropDownIsLoading ?
                                            this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Group <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Group"
                                                    value={this.state.coreGroupCategoryID}
                                                    options={groupList}
                                                    onChange={this.onChangeGroup}
                                                    // itemTemplate={this.bankTemplate}
                                                    filter={true}
                                                    filterBy="label,value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                />
                                            </div>
                                        }
                                        <span className='error-message'>{errors['groupError']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Fee Name <span>*</span></span>
                                            <InputText
                                                id="feeName"
                                                onChange={this.onChangeInputValue}
                                                value={this.state.feeName}
                                                placeholder="Enter Fee Name"
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="feeName"
                                            />
                                        </div>
                                        <span className='error-message'>{errors['feeName']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Details <span>*</span></span>
                                            <InputTextarea
                                                placeholder="Write Fee Details"
                                                onChange={this.onChangeInputValue}
                                                value={this.state.details}
                                                rows={3}
                                                cols={30}
                                                autoResize={true}
                                                id="detail"
                                                name="details"
                                            />
                                        </div>
                                        <span className='error-message'>{errors['details']}</span>

                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Fee Amount <span>*</span></span>
                                            <InputText
                                                id="feeAmount"
                                                onChange={this.onChangeInputValue}
                                                value={this.state.feeAmount}
                                                keyfilter="pint"
                                                placeholder="Enter Fee Amount"
                                                style={{ width: "100%" }}
                                                type="text"
                                                name="feeAmount"
                                            />
                                        </div>
                                        <span className='error-message'>{errors['feeAmount']}</span>

                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Payment Mode <span>*</span></span>
                                            <Dropdown
                                                id="paymentMode"
                                                placeholder="Select Payment Mode"
                                                options={paymentMode}
                                                value={this.state.paymentMode}
                                                onChange={this.onChangePaymentMode}
                                                filter={true}
                                                filterBy="label,value"
                                                showClear={true}
                                                autoWidth={false}
                                                name="paymentMode"
                                            />
                                        </div>
                                        <span className='error-message'>{errors['paymentMode']}</span>

                                    </div>

                                    <div className="p-col-12 p-xl-12" />

                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-left"
                                            label="Save"
                                            icon="fas fa-check"
                                            onClick={this.feeOnSubmitHandler.bind(this)}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="p-col-12 p-xl-12 nw-data-table">
                                {!this.state.dataTableIsLoading ?
                                    <FeesInfoList countTableSize={(l) => this.setState({ totalDataFound: l })}
                                        classList={classList}
                                        groupList={groupList}
                                        coreUrl={urlId}
                                    />
                                    : this.NetiContentLoader.MyPointTableLoader()
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/home"
                                        className="rainbow-button"
                                        alt="Go DWS Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}