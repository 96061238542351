import { BaseURIHolder } from "../../utils/BaseURIHolder";
import AuthService from "../../auth/AuthService";
import UnAuthService from "../../auth/UnAuthService";

export class ProfileService {
  constructor() {
    this.baseURIHolder = new BaseURIHolder();
    this.Auth = new AuthService();
    this.unAuth = new UnAuthService();
  }

  checkUserProfileName(username) {
    let uri = this.baseURIHolder.getUserProfile() + "/by?username=" + username;
    const header = localStorage.getItem("id_token");

    const headers = {
      Authorization: "bearer " + header,
    };

    return fetch(uri, { method: "GET", headers })
      .then(function (response) {
        return response.json();
      })
      .catch((error) => console.log("responseErrorData:::" + error));
  }

  getUserDetailsFromLocalStorage() {
    return localStorage.getItem("Profile");
  }

  updateUserbasicInfo(userBasic) {
    let uri = this.baseURIHolder.getUserProfile() + "/edit/basic";
    return this.Auth.putFetch(uri, userBasic).catch((error) =>
      console.log("responseErrorData:::" + error)
    );
  }

  findBasicInfoByCustomNetiID(customNetiID) {
    let uri =
      this.baseURIHolder.getUserProfile() +
      "/by/custom_id?custom_id=" +
      customNetiID;
    console.log(uri);
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  getUserProfileInfo() {
    let uri = this.baseURIHolder.getUserProfile();
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  checkUserNameChangeEligibility() {
    let uri = this.baseURIHolder.getUser() + "/check/username-eligibility";
    // let uri = "http://192.168.31.23:9002/user/check/username-eligibility"
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  checkUserNameForUpdate(userInfo) {
    let uri = this.baseURIHolder.getUser() + "/check/username";
    return this.Auth.postFetch(uri, userInfo).catch((error) =>
      console.log("error", error)
    );
  }

  checkOPTCode(otpCode) {
    let uri = this.baseURIHolder.getGuest() + "/core/check-otp?code=" + otpCode;
    return this.unAuth
      .getFetch(uri)
      .catch((error) => console.log("error", error));
  }

  checkPasswordForUpdate(userInfo) {
    let uri = this.baseURIHolder.getUser() + "/check/password";
    return this.Auth.postFetch(uri, userInfo).catch((error) =>
      console.log("error", error)
    );
  }

  changeUserName(userInfo) {
    let uri = this.baseURIHolder.getUser() + "/change/username";
    return this.Auth.putFetch(uri, userInfo).catch((error) =>
      console.log("error", error)
    );
  }

  updateUserPassword(userPasswordInfo) {
    let uri = this.baseURIHolder.getUser() + "/change/password";
    return this.Auth.postFetch(uri, userPasswordInfo).catch((error) =>
      console.log("error", error)
    );
  }

  addAdditionalInfoSubmit(addInfo) {
    let uri = this.baseURIHolder.getUser() + "/details/new";
    return this.Auth.postFetch(uri, addInfo).catch((error) => "");
  }

  getAdditionalInfoList() {
    let uri = this.baseURIHolder.getUser() + "/details/get";
    return this.Auth.getFetch(uri).catch((error) => "");
  }

  updateAdditionalInfoSubmit(addInfo) {
    let uri = this.baseURIHolder.getUser() + "/details/edit";
    return this.Auth.putFetch(uri, addInfo).catch((error) => "");
  }

  getUserProfileDetails() {
    let uri = this.baseURIHolder.getUser() + "/profile/details";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  getBankAccInfoByUser() {
    let uri = this.baseURIHolder.getUser() + "/bank/accounts/by/user";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  //new profile api

  fetchCommonProfileInfo() {
    let uri = this.baseURIHolder.getUser() + "/profile/details";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchUserBankInfo() {
    let uri = this.baseURIHolder.getUser() + "/bank/accounts/by/user";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  fetchUserBasicInfo(infoSection) {
    let uri = this.baseURIHolder.getUser() + "/" + infoSection + "-infos/find";
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }

  updateUserBasicInfo(updateUserObj, infoSection) {
    let uri = this.baseURIHolder.getUser() + "/" + infoSection + "-info/update";
    return this.Auth.putFetch(uri, updateUserObj).catch((error) => "");
  }

  insertUserBasicInfo(insertUserObj, infoSection) {
    let uri = this.baseURIHolder.getUser() + "/" + infoSection + "-info/save";
    return this.Auth.postFetch(uri, insertUserObj).catch((error) => "");
  }

  findBankAccountByUser() {
    let uri = this.baseURIHolder.getUser() + `/bank/account/taggings`;
    return this.Auth.getFetch(uri).catch((error) =>
      console.log("error", error)
    );
  }
}
