import React, { Component } from 'react';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';

import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { pdfDownloadLandscape_TableWithFooter } from '../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { formatToCurrency } from '../../common/JsPdfEssentialFile';
import { SplitButton } from 'primereact/splitbutton';

export class DailyTransferAmountList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            pdfExcelTableValue: [],
            searchObj: { ...this.props.location.searchObj },
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportTransferPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }
        this.managementPointService = new ManagementPointService();
        this.ValidatorUtility = new ValidatorUtility();

        this.exportTransferPdf = this.exportTransferPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    componentWillMount() {
        this.getDailyBasisTransferAmountList();
    }

    getDailyBasisTransferAmountList = () => {
        let { searchObj } = this.state;

        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.managementPointService.fetchDailyTransectionListByType(searchObj.type, searchObj.date, searchObj.transType)
            .then(res => {
                if (res.status === 200) {

                    return res.json().then(body => {
                        this.setState({ dataTableValue: body });
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.setState({ totalPaidAmountBody: this.calculateTotalAmount(body, 'amount') });

                        //for pdf
                        let tempDataList = []; tempDataList = body;
                        tempDataList.map(item => {
                            item.senderNetiId = item.senderNetiId.toString();
                            item.receiverNetiId = item.receiverNetiId.toString();
                            item['dateTime'] = NetiDateUtils.getAnyShortForm(item['dateTime'], 'DD-MMM-YYYY') + ' ' + NetiDateUtils.getHHMMSSFForHome(item['dateTime']);
                        });
                        this.setState({ pdfExcelTableValue: tempDataList });
                    })

                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        })
                }
            })
            .catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    calculateTotalAmount(body, amountCategory) {
        return body.map(item => item[`${amountCategory}`]).reduce((a, b) => (a + b));
    }

    getDataTableDate = (rowData) => {
        return <span>{NetiDateUtils.getAnyShortForm(rowData.dateTime, 'DD-MMM-YYYY')} {NetiDateUtils.getHHMMSSFForHome(rowData.dateTime)}</span>
    }

    transferTemplet = (rowData) => {
        return <div className="col-number text-right" >
            {this.ValidatorUtility.currencyFormatter(rowData.amount)}
        </div>
    }

    exportTransferPdf() {

        let pdfColumns = [
            { title: "Transfer Date & Time", dataKey: "dateTime" },
            { title: "Sender Neti ID", dataKey: "senderNetiId" },
            { title: "Sender Name", dataKey: "senderName" },
            { title: "Receiver Neti ID", dataKey: "receiverNetiId" },
            { title: "Receiver Name", dataKey: "receiverName" },
            { title: "Transfer Amount", dataKey: "amount" },
        ]
        let tableColStyles = { amount: { halign: "right" }, }

        var totalArrayList = [
            { id: "1", totalPdf: "Total Transfer : " + formatToCurrency(this.state.totalPaidAmountBody), }
        ];

        let totalColumns = [{ dataKey: "totalPdf" }];

        let totalColumnStyles = {
            totalPdf: { halign: "right", fontSize: 10, fontStyle: "bold", overflow: "linebreak" },
        }

        pdfDownloadLandscape_TableWithFooter("Transfer List (Date Wise)", 110, pdfColumns, this.state.pdfExcelTableValue, tableColStyles, totalColumns, totalArrayList, totalColumnStyles, "Daily_TransferList_Report.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody } = this.state;

        // excel
        let excelColumns = "";
        let excelFileName = "Daily_Transfer_List_Report.xlsx";
        let excelSheetName = "Daily_Transfer_List";
        excelColumns = [
            <Workbook.Column value="dateTime" label="Transfer Date & Time" />,
            <Workbook.Column value="senderNetiId" label="Sender Neti ID" />,
            <Workbook.Column value="senderName" label="Sender Name" />,
            <Workbook.Column value="receiverNetiId" label="Receiver Neti ID" />,
            <Workbook.Column value="receiverName" label="Receiver Name" />,
            <Workbook.Column value="amount" label="Transfer Amount" />,
        ];
        // end excel

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Transfer List (Date Wise)</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Totals:" colSpan={5} />
                <Column footer={this.ValidatorUtility.currencyFormatter(totalPaidAmountBody)} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className='main-section'>

                    <div className="p-grid">
                        {topProgressBar ?
                            <ErrorMessageView
                                topProgressBar={topProgressBar}
                            />
                            : null
                        }
                        {errorMsgVisible ?
                            <ErrorMessageView
                                errorMsgVisible={errorMsgVisible}
                                errorMsgBody={errorMsgBody}
                            />
                            : null
                        }

                        <div className="p-col-12 p-lg-12 p-xl-12">
                            <div className="nw-data-table">
                                {/* {
                                this.state.tableView === true ?
                                    this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader()
                                    : */}
                                <DataTable
                                    header={tableHeader}
                                    footerColumnGroup={footer}
                                    responsive={true}
                                    selectionMode="single"
                                    paginator={true}
                                    rowsPerPageOptions={[10, 20, 30]}
                                    rows={10}
                                    value={this.state.dataTableValue}
                                //selection={this.state.dataTableSelection}
                                >
                                    <Column field="dateTime" header="Transfer Date & Time" body={this.getDataTableDate} filter={true} />
                                    <Column field="senderNetiId" header="Sender Neti ID" filter={true} />
                                    <Column field="receiverName" header="Sender Name" filter={true} />
                                    <Column field="receiverNetiId" header="Receiver Neti ID" filter={true} />
                                    <Column field="senderName" header="Receiver Name" filter={true} />
                                    <Column field="amount" header="Transfer Amount" filter={true} body={this.transferTemplet} />

                                </DataTable>

                                {/* :
                                    
                                    <BlankDataTableAnim/> 
                            } */}
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12 nw-button-parent">

                            <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                            <div style={{ display: 'none' }}>

                                <Workbook
                                    filename={excelFileName}
                                    element={
                                        <Button
                                            label="Download Excel"
                                            id="clickExcelBtn"
                                            icon="fas fa-download"
                                            className="p-button p-button-primary nw-button nw-button-right"
                                        />
                                    }
                                >
                                    <Workbook.Sheet data={this.state.pdfExcelTableValue} name={excelSheetName}>
                                        {excelColumns}
                                    </Workbook.Sheet>
                                </Workbook>

                            </div>

                        </div >

                    </div>

                </div>
            </div>
        )
    }
}