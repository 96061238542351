import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from 'primereact/dialog';
import { Link } from "react-router-dom";
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { ProfileService } from '../../service/profile/ProfileService';
import { TokenService } from '../../service/token/TokenService';
import { UserCategoryService } from '../../service/UserCategoryService';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import CommonFuctionality from '../common/CommonFuctionality';

let purchaseError = {};
let netiIdError = {};
let tokenError = {};
let partnerAreaID;
export class NetiUserDisable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userDetails: '',
            visible:false,
            isDisabled: true,
            dataTableValue: [],
            tokenTypeID: '',
            teamMemberInfoList: [],
            purchaseCode: '',
            tokenId: '',
            netiId: '',
            newPartnerAssignError: {},
            designation: '',
            parentId: '',
            areaId: '',
            tokenDetails: '',
            errorMsgVisible: false,
            topProgressBar: false,
            changingPurposeDropdownList: [],
            firstSearchIsLoading: false,
            secondSearchIsLoading: false,
            thirdSearchIsLoading: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            thirdDropdownIsLoading: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            usedStatus: '',
            purchaseError: {},
            searchDataObj: {
                pageLimit: 10,
                pageNo: 0
            },
            first: 0,
            rows: 10,
            netiIdError: {},
            tokenError: {},
            tokenPicInfo: {
                contentPic: '',
            },
            searchViewPurchaseCode: false,
            searchViewErrorPurchaseCode: false,

            searchViewTokenId: false,
            searchViewErrorTokenId: false,

            searchViewNetiId: false,
            searchViewErrorNetiId: false,
            purchaseCodeSearchBtnDisabled: true,
            tokenIdSearchBtnDisabled: true,
            netiIdSearchBtnDisabled: true,
            buttonDisabled: true,
            
        };
        this.partnerPointService = new PartnerPointService();
        this.profileService = new ProfileService();
        this.tokenService = new TokenService();
        this.CRMPointService = new CRMPointService();
        this.userCategoryService = new UserCategoryService();
        this.NetiContentLoader = new NetiContentLoader();
        this.onPageChange = this.onPageChange.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.CommonFuctionality = new CommonFuctionality();
    }
    componentWillMount() {
        this.getDisabledMemberInfoList()
    }

    onPageChange(event) {

        console.log("event", event);

        let { searchDataObj } = this.state
        searchDataObj.pageNo = event.page

        this.setState({
            first: event.first,
            rows: event.rows
        });

        this.getAllPartnerAssignInfoList();

    }

    onChangePurposeList = (e) => {
        let { newPartnerAssignError } = this.state;
        newPartnerAssignError["purposeList"] = "";
        this.setState({ tokenTypeID: e.target.value, newPartnerAssignError });
    }

    onChangeTokenDetails = (e) => {
        let { newPartnerAssignError } = this.state;
        newPartnerAssignError["tokenDetails"] = "";
        this.setState({ tokenDetails: e.htmlValue, newPartnerAssignError });
    }

    removeButtonOnclick = () => {
        this.setState({ tokenPicInfo: { contentPic: '' } });
        this.setState({ tokenPicInfo: { contentName: '' } });

    }

    handlePurchaseError() {
        let purchaseError = {};
        let formIsValid = true;

        if (this.state.purchaseCode === "") {
            formIsValid = false;
            purchaseError["purchaseCode"] = "Please input Purchase Code";
        }
        this.setState({ purchaseError: purchaseError });
        return formIsValid;
    }

    handleTokenErrorError() {
        let tokenError = {};
        let formIsValid = true;

        if (this.state.tokenId === "") {
            formIsValid = false;
            tokenError["tokenId"] = "Please input Token Id";
        }
        this.setState({ tokenError: tokenError });
        return formIsValid;
    }

    handleNetiIdError() {
        let netiIdError = {};
        let formIsValid = true;
        let {netiId} = this.state;

        if (this.state.netiId === "") {
            formIsValid = false;
            netiIdError["netiId"] = "Please input NetiId";
        }

        if(netiId.length < 10){
            formIsValid = false;
            netiIdError['netiId'] = 'Neti ID is too sort';            
        }
        
        if(netiId.length < 10 && netiId === ''){
            formIsValid = false;
            netiIdError['netiId'] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10';
        }
        this.setState({ netiIdError: netiIdError });
        return formIsValid;
    }

    onFileUpload(e) {
        let { newPartnerAssignError } = this.state;
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let tokenPic = {
                extention: photo.type,
                contentPic: urlStr,
                contentName: photo.name
            };
            scope.setState({ tokenPicInfo: tokenPic });
        }

    }

    onSearchPurchaseCode = () => {
        if (this.handlePurchaseError()) {
            this.setState({ topProgressBar: true, firstSearchIsLoading: true, errorMsgVisible: false, searchViewPurchaseCode: true, searchViewErrorPurchaseCode: false })
            this.partnerPointService.getUserPurchaseCode(this.state.purchaseCode)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            this.setState({ productName: body.productPurchaseLogDTO.productInfoDTO.productName });
                            this.setState({ usedStatus: body.usedStatus });
                            this.setState({ purchashedDate: NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY') });
                            this.setState({ topProgressBar: false, firstSearchIsLoading: false, searchViewErrorPurchaseCode: false, purchaseCodeSearchBtnDisabled: false })
                        });
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                purchaseError["Error"] = resp;
                                this.setState({ topProgressBar: false, firstSearchIsLoading: false, searchViewErrorPurchaseCode: true, purchaseCodeSearchBtnDisabled: true })
                                this.setState({ productName: "" });
                                this.setState({ usedStatus: "" });
                                this.setState({ purchashedDate: "" });
                            });
                    }
                }).catch((error) => {
                    this.setState({ searchViewError: false, searchViewPurchaseCode: false, topProgressBar: false, purchaseCodeSearchBtnDisabled: true, firstSearchIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        } else {
            purchaseError["purchaseCode"] = "Please input Purchase Code";
            this.setState({ searchViewPurchaseCode: false, purchaseCodeSearchBtnDisabled: false })

        }
    }

    onSearchTokenId = () => {
        if (this.handleTokenErrorError()) {
            this.setState({ topProgressBar: true, secondSearchIsLoading: true, errorMsgVisible: false, searchViewTokenId: true })

            this.tokenService.findTokenInfoByTokenID(this.state.tokenId)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            this.setState({ purchaseInfo: body });
                            this.setState({ tokenType: body.tokenTypeInfoDTO.categoryName });
                            this.setState({ attachmentPath: body.attachmentPath });
                            this.setState({ tokenInfoID: body.tokenInfoID });
                            this.setState({ customTokenID: body.customTokenID });
                            this.setState({ tokenCreateDate: NetiDateUtils.getAnyShortForm(body.createDate, 'DD-MMM-YYYY') });
                            this.setState({ topProgressBar: false, secondSearchIsLoading: false, searchViewErrorTokenId: false, tokenIdSearchBtnDisabled: false })
                        });
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((res) => {
                                tokenError["Error"] = res;
                                this.setState({ topProgressBar: false, secondSearchIsLoading: false, searchViewErrorTokenId: true, tokenIdSearchBtnDisabled: true });
                                this.setState({ attachmentPath: "" });
                                this.setState({ tokenCreateDate: "" });
                                this.setState({ tokenInfoID: "" });
                                this.setState({ customTokenID: "" });
                            });

                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, searchViewErrorTokenId: false, searchViewErrorTokenId: true, searchViewTokenId: false, secondSearchIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        } else {
            tokenError["tokenId"] = "Please input Token Id";
            this.setState({ searchViewTokenId: false, searchViewErrorTokenId: true, })
        }
    }

    onSearchCustomNetiID = () => {
        if (this.handleNetiIdError()) {
            this.setState({ topProgressBar: true, thirdSearchIsLoading: true, errorMsgVisible: false, searchViewNetiId: true, searchViewErrorNetiId: false })
            this.profileService.findBasicInfoByCustomNetiID(this.state.netiId)
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            this.setState({ userDetails: body });
                            this.setState({ netiName: body.fullName });
                            this.setState({ netiMobile: body.basicMobile });
                            this.setState({ netiidSave: body.netiID });
                            this.setState({ netiRegisterDate: NetiDateUtils.getAnyShortForm(body.registrationDate, 'DD-MMM-YYYY') });
                            this.setState({ topProgressBar: false, thirdSearchIsLoading: false, searchViewErrorNetiId: false, netiIdSearchBtnDisabled: false })
                            this.getProfileImg(body.imagePath, body.imageName);
                            if(body.userEnableStatus === 1){
                                this.setState({ isDisabled: false });
                            }else{
                                this.setState({ isDisabled: true });
                            }
                        });
                    } else {
                        this.profileService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                netiIdError["Error"] = resp;
                                this.setState({ topProgressBar: false, netiIdSearchBtnDisabled: true, thirdSearchIsLoading: false, errorMsgVisible: true, errorMsgBody: resp, searchViewErrorNetiId: true })
                                this.setState({ netiName: "" });
                                this.setState({ netiMobile: "" });
                                this.setState({ netiRegisterDate: "" });
                                this.setState({ netiidSave: "" });
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, thirdSearchIsLoading: false, netiIdSearchBtnDisabled: true, errorMsgVisible: true, errorMsgBody: 'Please check your connection', searchViewErrorNetiId: false, searchViewNetiId: false });
                });
        } else {
            netiIdError["netiId"] = "Please input NetiId";
            this.setState({ searchViewNetiId: false, netiIdSearchBtnDisabled: true })
        }
    }

    getDisabledMemberInfoList() {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.CRMPointService.getDisabledUser()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ dataTableValue: body });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false})
                    })
                }else {
                    this.CRMPointService.Auth.handleErrorStatus(res)                    
                        .then((resp) => {
                            console.log('res', res);
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp, })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection !!!' });
            });
    }

    newPartnerAssignErrorHandler = () => {
        let { newPartnerAssignError, tokenError, netiIdError, purchaseError } = this.state;
        let formIsValid = true;

        if (this.state.purchaseCode === "") {
            formIsValid = false;
            purchaseError["purchaseCode"] = "Purchase Code can't left empty.";
        }

        if (this.state.netiId === "") {
            formIsValid = false;
            netiIdError["netiId"] = "Neti ID can't left empty.";
        }
        if (this.state.designation === "") {
            formIsValid = false;
            newPartnerAssignError["designation"] = "Designation can't left empty.";
        }

        if (this.state.parentId === "") {
            formIsValid = false;
            newPartnerAssignError["parentType"] = "Parent Type can't left empty.";
        }
        if (this.state.areaId === "") {
            formIsValid = false;
            newPartnerAssignError["area"] = "Area can't left empty.";
        }

        this.setState({ newPartnerAssignError });
        return formIsValid;
    };

    focusSearch = e => {
        this.setState({
            searchLabel: "Search",
            addSearchBtnClass: "nw-button p-button-primary text-color-white"
        });
    }

    blurSearch = e => {
        this.setState({
            searchLabel: "",
            addSearchBtnClass: ""
        });
    }

    focusSearchToken = e => {
        this.setState({
            searchLabelToken: "Search",
            addSearchBtnClassToken: "nw-button p-button-primary text-color-white"
        });
    }

    blurSearchToken = e => {
        this.setState({
            searchLabelToken: "",
            addSearchBtnClassToken: ""
        });
    }

    focusSearchNetiId = e => {
        this.setState({
            searchLabelNetiId: "Search",
            addSearchBtnClassNetiId: "nw-button p-button-primary text-color-white"
        });
    }

    blurSearchNetiId = e => {
        this.setState({
            searchLabelNetiId: "",
            addSearchBtnClassNetiId: ""
        });
    }

    userDisableConfirmation = () =>{
        this.setState({ visible: true })
        this.CommonFuctionality.blurDialogBackgroundActive()
    }

    onHide = () =>{
        this.setState({ visible: false })
        this.CommonFuctionality.blurDialogBackgroundDeActive()
    }

    getProfileImg = (path, name) => {
        let { userDetails } = this.props;
        let staticImg = 'assets/layout/images/avatar.png';

        if (path) {
            this.netiFileHandler.getByteImage(path)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                // console.log("BODY", body);

                                let contentType = this.netiFileHandler.getImageContentType(name);
                                this.setState({ userPicture: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ userPicture: staticImg });
                    }
                });
        } else {
            this.setState({ userPicture: staticImg });
        }

    }

    onSubmitDisableUser = () =>{
        let { userDetails } = this.state

        // if (this.updatePaymentInfoError()) {
            this.setState({ topProgressBar: true });
            this.CRMPointService.updateUserStatus(userDetails)
                .then(res => {
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated!" });
                        this.setState({ topProgressBar: false  });

                        setTimeout(() => {
                            this.setState({ searchViewNetiId: false, isDisabled: true})
                            this.onHide();
                            this.getDisabledMemberInfoList()
                        }, 1000);
                    } else {
                        this.CRMPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false , errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false , errorMsgVisible: true, errorMsgBody: "Please Check Your Connection !!!" })
                });
        // }
    }

    render() {
        let { userDetails, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
  
        let headerValue = <div className="header-title">
                            <div className="header-title-left">
                                Disabled Team Member Info
                            </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {this.state.dataTableValue.length}
                                </a>
                            </div>
                        </div>;

        let usedSts = ""

        if (this.state.usedStatus === 0) {
            usedSts = "Unused"
        } if (this.state.usedStatus === 1) {
            usedSts = "Used"
        }
        let partnerTypeOptions = [];
        if (this.state.partnerTypeList && this.state.partnerTypeList.length) {
            partnerTypeOptions = this.state.partnerTypeList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let areaListOptions = [];
        if (this.state.areaList && this.state.areaList.length) {
            areaListOptions = this.state.areaList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let parentPartnerOption = [];
        if (this.state.parentPartnerList && this.state.parentPartnerList.length) {
            parentPartnerOption = this.state.parentPartnerList.map(item => ({
                // value: item.partnerAreaInfoDTO.coreCategoryID,
                value: item.partnerAssignID,
                label: item.userBasicInfoDTO.fullName + ' (' + item.partnerAreaInfoDTO.categoryName + ')'
            }));
        }

        let purposeList = []
        if (this.state.changingPurposeDropdownList && this.state.changingPurposeDropdownList.length) {
            purposeList = this.state.changingPurposeDropdownList.map((item) => ({
                label: item.categoryName,
                value: item.coreCategoryID
            }));
        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                <div className="main-section blur-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Neti Id <span>*</span></span>
                                        <InputText
                                            value={this.state.netiId}
                                            onChange={(e) => this.setState({ netiId: e.target.value })}
                                            keyfilter="pint"
                                            placeholder="Enter Neti Id"
                                            style={{ width: "15%" }}
                                            type="text"
                                            name="netiId"
                                            onFocus={this.focusSearchNetiId}
                                            onBlur={this.blurSearchNetiId}
                                        />
                                        <Button
                                            className={"p-button-animation " + this.state.addSearchBtnClassNetiId}
                                            label={this.state.searchLabelNetiId}
                                            icon="fas fa-search"
                                            onClick={this.onSearchCustomNetiID}
                                            onFocus={this.focusSearchNetiId}
                                            onBlur={this.blurSearchNetiId}
                                        />
                                    </div>
                                    <span className="error-message">
                                        {this.state.netiIdError["netiId"]}
                                    </span>

                                    {this.state.searchViewNetiId ?

                                        <div className="nw-search-view">
                                            {this.state.searchViewErrorNetiId ?
                                                <center className="error-message">{this.state.netiIdError["Error"] || "No Data Found"}</center> :
                                                <div>
                                                    {/* <div className="p-col-12 p-xl-12">
                                                        <img
                                                            src={this.state.userPicture}
                                                            alt="Institute Logo"
                                                            height="100px"
                                                        />
                                                    </div> */}
                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.thirdSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">
                                                                    Name
                                                                        </span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {userDetails.fullName}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.thirdSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">
                                                                    Mobile No
                                                                        </span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {userDetails.basicMobile}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.thirdSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">
                                                                    Email
                                                                </span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {userDetails.basicEmail}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.thirdSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">
                                                                    Register Date
                                                                        </span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {NetiDateUtils.getAnyShortForm(userDetails.registrationDate, 'DD-MMM-YYYY')}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.thirdSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">
                                                                    Validation Status
                                                                </span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {userDetails.validationStatus === 0 ?
                                                                        "Invalid":
                                                                        "Valid"
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    {/* <div className="p-col-12 p-xl-12">
                                                        {this.state.thirdSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">
                                                                    Total Point
                                                                </span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {userDetails.validationStatus === 0 ?
                                                                        "Static Value":
                                                                        "Static Value"
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div> */}

                                                    <div className="p-col-12 p-xl-12">
                                                        {this.state.thirdSearchIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-inputgroup">
                                                                <span className="task-badge found"></span>
                                                                <span className="p-inputgroup-addon">
                                                                    User Status
                                                                </span>
                                                                <span className="p-inputgroup-colon">:</span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {userDetails.userEnableStatus === 0 ?
                                                                        <span><i className="fas fa-times"></i>Disabled</span>:
                                                                        <span 
                                                                            style={{ display:'flex', alignItems: 'center'}}
                                                                        >
                                                                            <img
                                                                                src={this.state.userPicture}
                                                                                alt="Institute Logo"
                                                                                height="100px"
                                                                                style={{ marginRight: "5px"}}
                                                                            />
                                                                            <div>
                                                                                 <i className="fas fa-check"></i> Enable
                                                                            </div>
                                                                            
                                                                            {/* <Button 
                                                                                icon="fas fa-times" 
                                                                                onClick={this.userDisableConfirmation} 
                                                                                className=" no-border nw-action-button p-button-danger"
                                                                                label=""
                                                                                tooltip="Disable User"
                                                                                // style={{ color: "red"}}
                                                                            /> */}
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            }
                                        </div>

                                        : ""
                                    }
                                </div>

                                <div className="p-col-12 p-xl-12 nw-button-parent">

                                        <div className="required-field" style={{ color: "#000000"}}>
                                            {/* <img
                                                src={this.state.userPicture}
                                                alt="Institute Logo"
                                                height="100px"
                                            />
                                            {userDetails.userEnableStatus === 0 ?
                                                "User Status: Disabled":
                                                userDetails.userEnableStatus === 1 ?
                                                "User Status: Enable": null
                                            } */}
                                        </div>

                                        <Button 
                                            icon="fas fa-times" 
                                            onClick={this.userDisableConfirmation} 
                                            className=" no-border nw-button p-button-danger"
                                            label="Disable"
                                            tooltip="Disable User"
                                            disabled={ this.state.isDisabled }
                                            // style={{ color: "red"}}
                                        />
                                    </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-12">
                            <div className="nw-data-table">
                                {this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() :
                                    <div>
                                        <DataTable
                                            header={headerValue}
                                            value={this.state.dataTableValue}
                                            responsive={true}
                                        >
                                            <Column field="customNetiID" header="Neti ID" sortable={true} filter={true} />
                                            <Column field="fullName" header="Name" sortable={true} filter={true} />
                                            <Column field="basicEmail" header="Email" sortable={true} filter={true} />
                                            <Column field="basicMobile" header="Mobile No." sortable={true} filter={true} />
                                            <Column field="gender" header="Gender" sortable={true} filter={true} />
                                        </DataTable>

                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog header="Confirmation" visible={this.state.visible} className="nw-confirm-dialog" onHide={this.onHide} closable>
                        
                        <div className="p-grid ">
                            <div className="p-col-12 p-xl-12">
                                <div className="confirm-wrapper">
                                    <h3>Do you want to disable <b>{ userDetails.fullName }</b>?</h3>
                                    <br/>
                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="No"
                                            icon="fas fa-times"
                                            onClick={this.onHide}
                                        />

                                        <Button
                                            name="district"
                                            label="Yes"
                                            icon="fas fa-check"
                                            className="p-button-primary nw-button nw-button-multiple"
                                            onClick={this.onSubmitDisableUser}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>
                
            </div>
        );
    }
}
