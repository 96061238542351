import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';

export class ReportService {
    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    getPurchaseCodeByStatus(statusCode) {
        let uri = this.baseURIHolder.getUser() + '/purchase/codes/by/usedstatus?usedStatus=' + statusCode;
        console.log('uri', uri);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUsedUnusedPurchaseCodeByStatus(statusCode) {
        let uri = this.baseURIHolder.getUser() + '/purchase/codes/by/user-usedstatus?usedStatus=' + statusCode;
        console.log('uri', uri);
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

}
