import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';

export class ManagementPointService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    // addNewProductAtInventory(requestedObject) {
    //     let uri = this.baseURIHolder.getCRM() + '/inventory/product/new';
    //     return this.Auth.postFetch(uri, requestedObject)
    //     .catch((error) => console.log("error",error));
    // }

    // fetchProductListFromInventory(pageLimit, pageNo) {
    //     let uri = this.baseURIHolder.getCRM() + `/inventory/products?pageLimit=${pageLimit}&pageNo=${pageNo}`;
    //     return this.Auth.getFetch(uri)
    //         .catch((error) => console.log("error", error));
    // }

    fetchChatHistoryByDate(searchDataObj) {
        let uri = this.baseURIHolder.getManagement() + '/report/netichat/history';
        return this.Auth.postFetch(uri, searchDataObj)
            .catch((error) => console.log("error", error));
    }

    fetchExpiryPointInfoList(pointId) {
        let uri = this.baseURIHolder.getManagement() + '/report/expiry/info?coreRoleId=' + pointId;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchUserWalletInfoList() {
        let uri = this.baseURIHolder.getManagement() + '/report/user/wallet/info';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchMonthlyBalanceTransactionList(yearValue) {
        let uri = this.baseURIHolder.getManagement() + '/report/user/monthly/transaction?transactionYear=' + yearValue;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchMonthlyBalanceTransactionListByType(urlType, year, month, type) {
        let uri = this.baseURIHolder.getManagement() + '/report/date/wise/' + urlType + '/list?year=' + year + '&month=' + month + '&type=' + type;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchDailyTransactionSummary(searchDataObj) {
        let uri = this.baseURIHolder.getManagement() + '/report/daily/basis/transaction/summary';
        return this.Auth.postFetch(uri, searchDataObj)
            .catch((error) => console.log("error", error));
    }

    fetchDailyTransectionListByType(type, date, transType) {
        let uri = this.baseURIHolder.getManagement() + '/report/daily/basis/' + type + '/summary?date=' + date + '&transType=' + transType;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchMessageTransferLogList(searchObj) {
        let uri = this.baseURIHolder.getManagement() + '/report/user/message/transfer';
        return this.Auth.postFetch(uri, searchObj)
            .catch((error) => console.log("error", error));
    }

    fetchDateRangeWisePurchaseList(searchObj) {
        let uri = this.baseURIHolder.getManagement() + '/purchase/transactions/by/date-range';
        return this.Auth.postFetch(uri, searchObj)
            .catch((error) => console.log("error", error));
    }

    // ---Eduman Bill Report---

    fetchInstituteList() {
        let uri = this.baseURIHolder.getManagement() + '/report/em/bill/accumulate/statement';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchMbpInfoList() {
        let uri = this.baseURIHolder.getManagement() + '/partner/institute/due/by-mbp';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchPartnerInfoList(defaultCode, parentAssignID) {
        let uri = this.baseURIHolder.getManagement() + '/partner/institute/due/by-designation?designationCode=' + defaultCode + '&parentAssignID=' + parentAssignID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchAccumulateStatementInstituteList(searchDataObj) {
        let uri = this.baseURIHolder.getManagement() + '/report/em/accumulate/statement/institute/list';
        return this.Auth.postFetch(uri, searchDataObj)
            .catch((error) => console.log("error", error));

    }

    fetchEdumanYearlyBalanceTransactionList(yearValue) {
        let uri = this.baseURIHolder.getManagement() + '/report/em/bill/yearly/statement?year=' + yearValue;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    getEdumanMonthlyBillReportForAllInstitute(instituteInfoObject) {
        let uri = this.baseURIHolder.getManagement() + '/report/em/bill/monthly/statement/all_institute';
        return this.Auth.postFetch(uri, instituteInfoObject)
            .catch((error) => console.log("error", error));
    }

    getEdumanCollectiveStatementPaidByDate(dateRangeInfo) {
        let uri = this.baseURIHolder.getManagement() + '/report/em/bill/paid/statement';
        return this.Auth.postFetch(uri, dateRangeInfo)
            .catch((error) => console.log("error", error));
    }

    getEdumanCollectiveStatementDiscountByDate(dateRangeInfo) {
        let uri = this.baseURIHolder.getManagement() + '/report/em/bill/discount/statement';
        return this.Auth.postFetch(uri, dateRangeInfo)
            .catch((error) => console.log("error", error));
    }

    // ---Token Service Report---
    getTokenListByApplication(fromDate, toDate) {
        let uri = this.baseURIHolder.getManagement() + `/report/service/token/application/wise?fromDate=${fromDate}&toDate=${toDate}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    getTokenListByApplicationModule(moduleInfo) {
        let uri = this.baseURIHolder.getManagement() + '/report/service/token/application/module/wise';
        return this.Auth.postFetch(uri, moduleInfo)
            .catch((error) => console.log("error", error));
    }

    getApplicationModuleProblemWiseTokens(moduleInfo) {
        let uri = this.baseURIHolder.getManagement() + '/report/service/token/application/module/problem/wise';
        return this.Auth.postFetch(uri, moduleInfo)
            .catch((error) => console.log("error", error));
    }

    getApplicationModuleProblemWiseTokenQuantity(moduleInfo) {
        let uri = this.baseURIHolder.getManagement() + '/report/service/token/application/module/problem/wise/quantity';
        return this.Auth.postFetch(uri, moduleInfo)
            .catch((error) => console.log("error", error));
    }

    getPersonWiseTokenList(fromDate, toDate) {
        let uri = this.baseURIHolder.getManagement() + `/report/service/token/person/wise?fromDate=${fromDate}&toDate=${toDate}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchEdumanInstituteDueStatementById(id) {
        let uri = this.baseURIHolder.getUser() + '/em/institute/details/by/?emDetailsID=' + id;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    getPersonWiseSolvedTokenList(personWiseTokenInfo) {
        let uri = this.baseURIHolder.getManagement() + '/report/service/token/person/wise/quantity';
        return this.Auth.postFetch(uri, personWiseTokenInfo)
            .catch((error) => console.log("error", error));
    }

    getPersonWiseCallQuantityReport(receivedCallInfo) {
        let uri = this.baseURIHolder.getManagement() + '/report/service/call/person/wise/quantity';
        return this.Auth.postFetch(uri, receivedCallInfo)
            .catch((error) => console.log("error", error));
    }
    fetchInstituteFoundInfoList(parentAssignID) {
        let uri = this.baseURIHolder.getManagement() + '/get/institutes/by-partnerAssignID?partnerAssignID=' + parentAssignID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    findMbpTreeForAdmin() {
        let uri = this.baseURIHolder.getManagement() + '/partner/mbp/tree';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    findPartnerTreeInfoForAdmin(designationCode, parentAssignID) {
        let uri = this.baseURIHolder.getManagement() + `/partner/tree/by-parent?designationCode=${designationCode}&parentAssignID=${parentAssignID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchProductList() {
        let uri = this.baseURIHolder.getManagement() + '/products/list';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchProductPurchaseList(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/team-wise/product/purchase/summary/by/product-id?productID=' + dateRange.productID;
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    fetchPrepaidDepositBalance(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/report/partner-wise/prepaid/total-deposit/by/date-range';
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    fetchPartnerIncomeSummary(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/report/partner-wise/total-income/by/date-range';
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    findPartnerPrePaidDepositSummary(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/report/partner/pre-paid/deposit';
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    findPartnerIncomeTreeView(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/report/partner/income/individual';
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    findPartnerPaidAmountTreeView(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/report/partner/paid-amount/team?productID=' + dateRange.productID;
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    fetchPartnerPerformance(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/report/partner-performance/by?designation=' + dateRange.partnerType;
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    // Ops related report

    fetchOpsInstituteCollectionList() {
        let uri = this.baseURIHolder.getManagement() + '/report/ops/summary';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchOpsDateWiseCollectionList(instituteID) {
        let uri = this.baseURIHolder.getManagement() + '/report/ops/date-wise/collections/by?instituteID=' + instituteID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchOpsStudentWiseCollectionList(date, instituteID) {
        let uri = this.baseURIHolder.getManagement() + `/report/ops/student-wise/collections/by?date=${date}&instituteID=${instituteID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchOpsInstituteTotalSettlementList(instituteID) {
        let uri = this.baseURIHolder.getManagement() + '/report/ops/date-wise/settlement-amount/details/by?instituteID=' + instituteID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    // Ops related report end

    fetchRegionWise_PartnerInfo(designation) {
        let uri = this.baseURIHolder.getManagement() + '/report/partner/info/by?designation=' + designation;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchAreaWise_Partner_DashboardInfo() {
        let uri = this.baseURIHolder.getManagement() + '/partner/area/dashboard';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchAreaWise_UnallocatedPartnerList(parentAreaID) {
        let uri = this.baseURIHolder.getManagement() + '/unallocated/area/by?parentAreaID=' + parentAreaID;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchTokenInfo() {
        let uri = this.baseURIHolder.getManagement() + '/report/application-wise/token-summary';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchPendingTokenInfo(tokenStatus, appName) {
        let uri = this.baseURIHolder.getManagement() + '/' + tokenStatus + '/' + appName + '/tokens';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchModuleWiseSolvedTokenInfo(appDefCode) {
        let uri = this.baseURIHolder.getManagement() + '/solved/module/wise/tokens/by?tokenSourceDefCode=' + appDefCode;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchProblemWiseSolvedTokenInfo(appDefCode) {
        let uri = this.baseURIHolder.getManagement() + '/token-title/wise/token/summary/by?moduleDefaultCode=' + appDefCode;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchSolvedTokenInfoDetails(appDefCode) {
        let uri = this.baseURIHolder.getManagement() + '/token/details/by?tokenTitleDefCode=' + appDefCode;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchCountDfpsInstituteInfo() {
        let uri = this.baseURIHolder.getManagement() + '/count/dfps/institute-info';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchDfpsFeesCollectionInfo(dateObj) {
        let uri = this.baseURIHolder.getManagement() + `/find/dfps/summary/by?fromDate=${dateObj.startDate}&toDate=${dateObj.endDate}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchDfpsConnectedInstituteList() {
        let uri = this.baseURIHolder.getManagement() + '/find/dfps/connected/institute-infos';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchDfpsTransactionalInstituteList() {
        let uri = this.baseURIHolder.getManagement() + '/find/today/transaction/dfps/institute-infos';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchApplicationWiseCallInfo(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/count/module-wise/calls';
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    findMbpWiseMonthlyPaidUnpaidInstitute(billMonth, billYear) {
        let uri = this.baseURIHolder.getManagement() + `/monthly/paid-unpaid/institute/by-mbp?billMonth=${billMonth}&billYear=${billYear}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    findMonthlyPaidUnpaidInstituteByParent(billMonth, billYear, designation, parentAssignID) {
        let uri = this.baseURIHolder.getManagement() + `/monthly/paid-unpaid/institute/by-parent?billMonth=${billMonth}&billYear=${billYear}&designation=${designation}&parentAssignID=${parentAssignID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchCallInfo(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/status-wise/call/count/by';
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    fetchCallAttendantInfo(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/find/person-wise/call/summary';
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    findDesignationWisePurchaseInfo(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/report/partner/purchase-info/by';
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    findEmPartnerOrAdminListForSmsSend(status, userType) {
        let uri = this.baseURIHolder.getManagement() + `/send/sms/to/partner-admin?status=${status}&userType=${userType}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    findMismatchBalanceInfo() {
        let uri = this.baseURIHolder.getManagement() + `/user/balance/mismatch`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchInstituteWiseMfsReport(dateRange) {
        let uri = this.baseURIHolder.getManagement() + '/institute-wise/mfs/collections';
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    fetchMfsCollectionByInstituteId(instituteID, dateRange) {
        let uri = this.baseURIHolder.getManagement() + `/mfs/collections/by/institute-id?instituteID=${instituteID}`;
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    fetchDateWiseMfsCollectionByInstituteId(instituteID, transactionDate) {
        let uri = this.baseURIHolder.getManagement() + `/mfs/collections/by?instituteID=${instituteID}&transactionDate=${transactionDate}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchMfsCollectionInfoByTransactionId(transactionID) {
        let uri = this.baseURIHolder.getManagement() + `/mfs/collection/by/trx-id?transactionID=${transactionID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    fetchMfsCollectionAccountList(instituteID, dateRange) {
        let uri = this.baseURIHolder.getManagement() + `/mfs/collection/accounts/by?instituteID=${instituteID}`;
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

    findEmSmsDetails() {
        let uri = this.baseURIHolder.getManagement() + `/em/sms/balance/details`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log("error", error));
    }

    findProductPurchseRevenueDistribution(dateRange) {
        let uri = this.baseURIHolder.getManagement() + `/purchase/revenue/distribution`;
        return this.Auth.postFetch(uri, dateRange)
            .catch((error) => console.log("error", error));
    }

}   
