import { ADMIN_POINT } from "./PointWiseRouteConsts";

export const AdminPointMenu = {
  items: [
    {
      label: "Admin Point",
      icon: "fas fa-users-cog",

      items: [
        {
          label: "Core Config",
          icon: "", //pi pi-fw pi-dollar
          items: [
            { label: "Category Info", icon: "", to: ADMIN_POINT.CATEGORY_INFO }, //pi pi-fw pi-money-bill
            { label: "CMS Menu Item", icon: "", to: ADMIN_POINT.CMS_MENU_ITEM }, //pi pi-fw pi-money-bill
            { label: "Core Bank", icon: "", to: ADMIN_POINT.CORE_BANK_INFO }, //pi pi-fw pi-envelope
            { label: "DFP Bank", icon: "", to: ADMIN_POINT.DFP_BANK_INFO }, //pi pi-fw pi-envelope
            {
              label: "Point Assign",
              icon: "",
              to: ADMIN_POINT.ADMIN_POINT_ASSIGN,
            }, //pi pi-fw pi-tag
            { label: "User Nature", icon: "", to: ADMIN_POINT.USER_NATURE }, //pi pi-fw pi-tag
          ],
        },
        {
          label: "Product Config",
          icon: "", //pi pi-fw pi-money-bill
          items: [
            { label: "Create New", icon: "", to: ADMIN_POINT.PRODUCT_INFO }, //pi pi-fw pi-tag
            {
              label: "Product Revenue",
              icon: "",
              to: ADMIN_POINT.PRODUCT_REVENUE,
            }, //pi pi-fw pi-tags
            { label: "Config Update", icon: "", to: ADMIN_POINT.PRODUCT_LIST }, //pi pi-fw pi-tag
            {
              label: "Config User Role",
              icon: "",
              to: ADMIN_POINT.PRODUCT_USER_ROLE,
            }, //pi pi-fw pi-tags
            { label: "Offer Config", icon: "", to: ADMIN_POINT.PRODUCT_OFFER }, //pi pi-fw pi-tags
          ],
        },
        {
          label: "Partner Config",
          icon: "", //pi pi-fw pi-money-bill
          items: [
            {
              label: "Partner Assign",
              icon: "",
              to: ADMIN_POINT.NEW_PARTNER_ASSIGN,
            }, //pi pi-fw pi-tag
            { label: "Partner Update", icon: "", to: ADMIN_POINT.PARTNER_LIST }, //pi pi-fw pi-tag
            // { label: 'Income Target', icon: '', to: ADMIN_POINT.PARTNER_INCOME_TARGET }, //Transfer to CRM > New Add > Income Target
          ],
        },
        {
          label: "Create Info",
          icon: "", //pi pi-fw pi-money-bill
          items: [
            { label: "Notice", icon: "", to: ADMIN_POINT.CREATE_NOTICE }, //pi pi-fw pi-tag
            {
              label: "Release Note",
              icon: "",
              to: ADMIN_POINT.CREATE_RELEASE_NOTICE,
            }, //pi pi-fw pi-tags
            { label: "Banner", icon: "", to: ADMIN_POINT.CREATE_BANNER }, //pi pi-fw pi-tags
            {
              label: "Global Calender",
              icon: "",
              to: ADMIN_POINT.CREATE_CALENDAR,
            }, //pi pi-fw pi-tags
            {
              label: "Download Corner",
              icon: "",
              to: ADMIN_POINT.DOWNLOAD_CORNER,
            }, //pi pi-fw pi-tags
          ],
        },
        {
          label: "Tutorial",
          icon: "", //to: ADMIN_POINT.TUTORIAL,  //pi pi-fw pi-money-bill
          items: [
            { label: "Add New", icon: "", to: ADMIN_POINT.ADD_TUTORIAL }, //pi pi-fw pi-tag
            { label: "Update", icon: "", to: ADMIN_POINT.VIEW_TUTORIAL }, //pi pi-fw pi-tags
          ],
        },

        {
          label: "Admisia Core Config",
          icon: "", //to: ADMIN_POINT.TUTORIAL,  //pi pi-fw pi-money-bill
          items: [
            {
              label: "Service Charge",
              icon: "",
              to: ADMIN_POINT.SERVICE_CHARGE_CONFIG,
            }, //pi pi-fw pi-tag
          ],
        },

        {
          label: "Create Other Products",
          icon: "",
          to: ADMIN_POINT.OTHER_PRODUCT_INFO,
        },

        // {
        //     label: 'Point', icon: '', //to: ADMIN_POINT.TUTORIAL,  //pi pi-fw pi-money-bill
        //     items: [
        //         { label: 'Assign', icon: '', to: ADMIN_POINT.ADMIN_POINT_ASSIGN }, //pi pi-fw pi-tag
        //         { label: 'Assign List', icon: '', to: ADMIN_POINT.ADMIN_POINT_ASSIGNED_LIST }, //pi pi-fw pi-tags
        //     ]
        // },

        //didn't used this menu anywhere start
        // { label: 'Admin User Nature', icon: '', to: ADMIN_POINT.ADMIN_USER_NATURE }, //pi pi-fw pi-tag
        //didn't used this menu anywhere end
      ],
    },
  ],
};
