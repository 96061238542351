import { CRM_POINT, ADMIN_POINT, SUPPORT_POINT, MANAGEMENT_POINT, PARTNER_POINT } from './PointWiseRouteConsts';

export const CRMPointMenu = {

    items: [
        {
            label: 'CRM Point ', icon: 'fas fa-user-shield',
            items: [
                // { label: 'Dashboard', icon: '', to: CRM_POINT.CRM_DASHBOARD },//pi pi-fw pi-home


                // Transfer to Management Point (Start)

                // {
                //     label: 'Dashboard', icon: '',
                //     items: [
                //         { label: 'Current Status', icon: '', to: CRM_POINT.CRM_CURRENT_STATUS_DASHBOARD },//pi pi-fw pi-home
                //         { label: 'Token & Call', icon: '', to: CRM_POINT.CRM_DASHBOARD_TOKEN_AND_CALL },//pi pi-fw pi-home
                //         { label: 'Date Wise Status', icon: '', to: CRM_POINT.CRM_DASHBOARD_DATE_WISE_STATUS },//pi pi-fw pi-home
                //     ]
                // },

                // Transfer to Management Point (END)
                
                {
                    label: 'Approval', icon: '',
                    items: [
                        { label: 'Balance Withdraw', icon: '', to: CRM_POINT.BALANCE_WITHDRAW }, //pi pi-fw pi-home
                        { label: 'Balance Deposit', icon: '', to: CRM_POINT.BALANCE_DEPOSIT }, //pi pi-fw pi-home

                        { label: 'EM ID Request', icon: '', to: CRM_POINT.EDUMAN_APPROVAL }, //pi pi-fw pi-money-bill
                        { label: 'Bill Discount', icon: '', to: CRM_POINT.EDUMAN_DISCOUNT_APPROVAL }, //pi pi-fw pi-home
                        // { label: 'Url Institute Map', icon: '', to: CRM_POINT.URL_INSTITUTE_MAP }, //Transfer to Support NetiCMS > NetiCms with EM
                        { label: 'Device Request', icon: '', to: CRM_POINT.DEVICE_APPROVAL }, //pi pi-fw pi-envelope
                        { label: 'Admisia Refund', icon: '', to: CRM_POINT.ADMISIA_REQUEST },
                        { label: 'User Disable', icon: '', to: CRM_POINT.USER_DISABLE },
                        

                    ]
                },

                {
                    label: 'Eduman Bill', icon: '',
                    items: [
                        { label: 'Bulk Bill Process', icon: '', to: CRM_POINT.EM_BILL_PROCESS }, //pi pi-fw pi-envelope
                        { label: 'Single Bill Process', icon: '', to: CRM_POINT.EM_MANUAL_BILL_PROCESS }, //pi pi-fw pi-envelope
                        { label: 'Prepaid Disbursement', icon: '', to: CRM_POINT.PRE_PAID_DISBURSEMENT }, //pi pi-fw pi-money-bill
                    ]
                },

                {
                    label: 'New Add', icon: '',
                    items: [
                        { label: 'DPF Settlement', icon: '', to: CRM_POINT.DFPS_SETTLEMENT_PAYMENT },
                        { label: 'Inventory', icon: '', to: CRM_POINT.ADD_INVENTORY }, //pi pi-fw pi-envelope
                        { label: 'Occurrence Info', icon: '', to: CRM_POINT.PARTNER_OCCURRENCE }, //pi pi-fw pi-home
                        { label: 'Income Target', icon: '', to: ADMIN_POINT.PARTNER_INCOME_TARGET }, //pi pi-fw pi-tag
                        { label: 'Eduman Core Info', icon: '', to: SUPPORT_POINT.EM_GLOBAL_INFO_ADD_CORE } //pi pi-fw pi-home
                    ]
                },
                {
                    label: 'Assign', icon: '',
                    items: [
                        { label: 'New Point', icon: '', to: PARTNER_POINT.POINT_ASSIGN }, //pi pi-fw pi-money-bill
                        { label: 'EM Admin', icon: '', to: PARTNER_POINT.ASSIGN_EM_INSTITUTE }, //pi pi-fw pi-tag
                        { label: 'Device', icon: '', to: PARTNER_POINT.YSS_CONFIG },
                    ]
                },

                {
                    label: 'Info Correction', icon: '', //pi pi-fw pi-dollar
                    items: [
                        { label: 'Eduman', icon: '', to: CRM_POINT.EDUMAN_AT_A_GLANCE }, //both in CRM and Support (Transfer to Support Find Details > Eduman At a Glance)
                        { label: 'User', icon: '', to: CRM_POINT.PARTNER_AT_A_GLANCE } //both in CRM and Support (Transfer to Support Find Details > User At a Glance)
                    ]
                },

                {
                    label: 'Send Notification', icon: '', //pi pi-fw pi-dollar
                    items: [
                        { label: 'Specific NetiID', icon: '', to: CRM_POINT.ANYONE_MESSAGE_SEND },
                        { label: 'To Partner', icon: '', to: CRM_POINT.MESSAGE_SEND_TO_PARTNER },
                        { label: 'To Point', icon: '', to: CRM_POINT.MESSAGE_SEND_TO_POINT_TYPE_WISE },
                        { label: 'For EM Due Bill', icon: '', to: CRM_POINT.MESSAGE_SEND_TO_DUE_BILL },
                        { label: 'To Eduman', icon: '', to: MANAGEMENT_POINT.SEND_NOTIFICATION_SMS },//pi pi-fw pi-user
                        { label: 'For Point Expire', icon: '', to: CRM_POINT.MESSAGE_SEND_TO_POINT_EXPIRE },
                    ]
                },

                { label: 'Reports' },

                {
                    label: 'Approval Info', icon: '', //pi pi-fw pi-bookmark
                    items: [
                        { label: 'Balance Deposit', icon: '', to: CRM_POINT.DEPOSIT_APPROVE }, //pi pi-fw pi-home
                        { label: 'Balance Withdraw', icon: '', to: CRM_POINT.WITHDRAW_APPROVE }, //pi pi-fw pi-home
                        { label: 'Eduman ID', icon: '', to: CRM_POINT.EDUMAN_ID_APPROVE },
                        { label: 'Domain Hosting', icon: '', to: CRM_POINT.DOMAIN_AND_HOSTING_APPROVE },
                    ]
                },

                {
                    label: 'Details Info', icon: '', //pi pi-fw pi-bookmark
                    items: [
                        { label: 'Prepaid Collection', icon: '', to: CRM_POINT.CRM_ADVANCE_BILL }
                    ]
                },
                // { label: 'Disable User', icon: '', to: CRM_POINT.USER_DISABLE },//Transfer to Approval > User disable
                





                //do not added any where (Start)

                // {
                //     label: 'Point', icon: '', //pi pi-fw pi-dollar
                //     items: [
                //         { label: 'Assign', icon: '', to: CRM_POINT.POINT_ASSIGN }, //pi pi-fw pi-money-bill
                //         // Remove Assigned List menu from menu List
                //         // { label: 'Assigned List', icon: '', to: EM_ADMIN_POINT.POINT_ASSIGNED_LIST  }, //pi pi-fw pi-envelope
                //     ]
                // },

                // {
                //     label: 'Temporary', icon: '', //pi pi-fw pi-dollar
                //     items: [
                //         { label: 'Person Wise Bill', icon: '', to: CRM_POINT.TEMP_PERSON_WISE_BILL }, //pi pi-fw pi-money-bill
                //         // Remove Assigned List menu from menu List
                //         // { label: 'Assigned List', icon: '', to: EM_ADMIN_POINT.POINT_ASSIGNED_LIST  }, //pi pi-fw pi-envelope
                //     ]
                // },

                //do not added any where (End)




                // {
                //     label: 'Anyone Message Send', icon: '', to: CRM_POINT.ANYONE_MESSAGE_SEND
                // },

                // {
                //     label: 'Message Send To Partner', icon: '', to: CRM_POINT.MESSAGE_SEND_TO_PARTNER
                // },
                // {
                //     label: 'Message Send To Point Wise', icon: '', to: CRM_POINT.MESSAGE_SEND_TO_POINT_TYPE_WISE
                // },

                // {
                //     label: 'Message Send To Due Bill', icon: '', to: CRM_POINT.MESSAGE_SEND_TO_DUE_BILL
                // },

                // {
                //     label: 'Message Send To Point Expire', icon: '', to: CRM_POINT.MESSAGE_SEND_TO_POINT_EXPIRE
                // },



                //do not added any where (Start)

                // {
                //     label: 'Eduman Bill Adjustment', icon: '', to: CRM_POINT.BILL_ADJUSTMENT
                // },

                //do not added any where (End)





                // {
                //     label: 'Admisia Request', icon: '', //pi pi-fw pi-dollar
                //     items: [
                //         { label: 'Refund Request', icon: '', to: CRM_POINT.ADMISIA_REQUEST }, //Transfer to Approval > Admisia Refund
                //         // Remove Assigned List menu from menu List
                //         // { label: 'Assigned List', icon: '', to: EM_ADMIN_POINT.POINT_ASSIGNED_LIST  }, //pi pi-fw pi-envelope
                //     ]
                // },
                // {
                //     label: 'DFPS Settlement Payment', icon: '', to: CRM_POINT.DFPS_SETTLEMENT_PAYMENT
                // },




                //do not added any where (Start)

                // {
                //     label: 'Observer Point Tagging', icon: '', to: CRM_POINT.OBSERVER_POINT_TAGGING
                // },
                
                //do not added any where (End)
                
                

            ]
        }
    ]
}