

import { BaseURIHolder } from '../utils/BaseURIHolder';
import AuthService from '../auth/AuthService';

export class CoreSettings {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }

    fetchGlobalInfoListByTypeId(typeId, instituteId) {

        let uri = this.baseURIHolder.getUser() + '/student/core-settings/by/type-id'.concat('?typeId=').concat(typeId).concat('&instituteId=').concat(instituteId); 
        return this.Auth.getFetch(uri)
            .then(function (response) {
                if (response.status === 302 || response.status === 200) {
                    return response.json()
                } else {
                    console.log('No date found this Redirect Api ', uri);
                }
            })
            .catch(error => console.log('responseErrorData:::' + error));
    }
}