export const GUEST_POINT = {
  SIGN_UP: "/signup",
  LOGIN: "/login",
  FORGET_PASSWORD: "/forget_password",
  PaymentCancel: "/PaymentCancel",
  PaymentFailed: "/PaymentFailed",
  PaymentStatus: "/PaymentStatus"
};
export const MY_POINT = {
  VIEW_PROFILE: "/view_profile",
  MY_PROFILE: "/my_profile",
  DEMO_VIEW_PROFILE: "/demo_view_profile",
  UPDATE_PROFILE: "/update_profile",
  CHANGE_PASSWORD: "/change_password",
  NEW_TOKEN: "/new_token",
  NEW_TOKEN_LIST: "/token_list",
  CHANGE_USER_NAME: "/change_username",
  CHAT_BOX: "/chat_box",
  CHAT_BOX_LIST: "/chat_box_list",

  DASHBORAD: "/my_point_dashboard",
  DEMO_DASHBORAD: "/demo_my_point_dashboard",
  WALLET: "/wallet",
  WALLET2: "/wallet2",
  MESSAGE: "/message_recharge",

  GENERAL_PRODUCT: "/purchase_general_product",
  OFFER_PRODUCT: "/purchase_offer_product",
  SELL_PRODUCT: "/sell_product",
  CUSTOMER_INFO: "/customer_info",

  WALLET_LOGS: "/wallet_log",
  MESSAGE_LOGS: "/message_recharge_log",
  BALANCE_STATEMENT: "/balance_statement",
  PURCHASE_LOG_GENERAL: "/purchase_log_general_product",
  PURCHASE_LOG_OFFER: "/purchase_log_offer_product",
  PURCHASE_CODE_LOG: "/purchase_code_log",
  UNUSED_PURCHASE_CODE_LOG: "/unused_purchase_code_log",
  USED_PURCHASE_CODE_LOG: "/used_purchase_code_log",
  SALES_PRODUCT_LOG: "/sell_product_log",
  CUSTOMER_INFO_LOG: "/customer_info_log",
  PARTNER_REVENUE_LOG: "/partner_revenue_log",
  USER_BANK_ACCOUNT: "/bank_account",
  MEETING_CALL: "/meeting_call",
};

export const EM_USER_POINT = {
  TEACHER_PORTAL: "/teacher_portal",
  GO_TO_PORTAL: "/go_to_portal",
  STUDENT_PORTAL: "/student_portal",
  ADD_POINT: "/add_point",
  ADD_STUDENT_PORTAL: "/add_student_portal",
  ADD_GUARDIAN_PORTAL: "/add_guardian_portal",
  ADD_EMPLOYEE_PORTAL: "/add_employee_portal",
  STUDENT_LIST_INFO: "/student_list_info",
  TEACHER_LIST_INFO: "/teacher_list_info",
  GUARDIAN_LIST_INFO: "/guardian_list_info",
  ADD_PORTAL: "/add_portal",
  STUDENT_ADD_PORTAL: "/Student_Add_Portal",
  PARENT_ADD_PORTAL: "/Parent_Add_Portal",
  TEACHER_ADD_PORTAL: "/Teacher_Add_Portal",
};

export const DWS_POINT = {
  DWS_DASHBOARD: "/cms_dashboard",
  DEMO_CMS_DASHBOARD: "/demo_cms_dashboard",
  HOME: "/home",
  MENU_CONFIG: "/menu_config",
  CMS_THEME_CONFIG: "/cms_theme_config",
  MAPPED_INSTITUTE: "/mapped_institute",
  PHOTO_GALLERY: "/photo_gallery",
  WELCOME_SPEECH: "/welcome_speech",
  NOTICE: "/notice",
  EVENT_LOG: "/event_log",
  IMPORTANT_LINK: "/important_link",
  ADMINISTRATION: "/administration",
  ABOUT: "/about_us",
  BOOK_INFO: "/book_info",
  DRESS_CODE: "/dress_code",
  FEES_INFO: "/fees_info",
  SEAT_INFO: "/seat_info",
  SYLLABUS: "/syllabus",
  DOWNLOAD_CORNER: "/download_corner",
  VISITOR_FEEDBACK: "/visitor_feedback",

  //
  ADMIN_NEW_INSTITUTE_MAPPING: "/cms_admin/mapped_new_institute",
  ADMIN_NEW_INSTITUTE_MAPPING_TEST: "/cms_admin/mapped_new_institute2",
  CMS_MENU_CONFIG: "/cms_admin/cms_menu_config",

  ADMIN_HOME: "/cms_admin/home",
  ADMIN_GALLERY_IMAGE: "/cms_admin/gallery_image",
  ADMIN_ABOUT_US: "/cms_admin/about_us",
  ADMIN_IMPORTANT_LINK: "/cms_admin/link_list",
  ADMIN_SEAT_INFO: "/cms_admin/seat_info",
  ADMIN_DOWNLOAD_CORNER: "/cms_admin/download_corner",
  ADMIN_SPEECH_INFO: "/cms_admin/speech_info",
  ADMIN_SYLLABUS_INFO: "/cms_admin/syllabus_info",
  ADMIN_EVENT_INFO: "/cms_admin/event_info",
  ADMIN_NOTICE_INFO: "/cms_admin/notice_info",
  ADMIN_FEES_INFO: "/cms_admin/fees_info",
  ADMIN_DRESS_CODE_INFO: "/cms_admin/dress_code_info",
  ADMIN_ADD_MEMBER_INFO: "/cms_admin/member_info",
  ADMIN_CLASS_INFO: "/cms_admin/class_info",
  ADMIN_GROUP_INFO: "/cms_admin/group_info",
  ADMIN_BOOK_INFO: "/cms_admin/book_info",
  ADMIN_ALUMNUS_INFO: "/cms_admin/alumnus_info",

  // Admisia Menu List
  ADMISIA_CORE_CONFIG: "/admisia/core_config",
  ADMISIA_APPLICATION_CONFIG: "/admisia/application/config",
  ADMISIA_APPLICATION_CONFIG_LIST: "/admisia/application/config/List",

  // Admisia approve
  ADMISIA_ASSESSMENT: "/admisia/assessment/approval",
  ADMISIA_ADMISSION_APPROVAL: "/admisia/admission/approval",

  // Admisia Report
  ADMISIA_APPLICANT_ANALYTICS: "/admisia/applicant/Analytics",
  ADMISIA_APPLICANT_STATUS: "/admisia/applicant/status",
  ADMISIA_APPLICANT_INFO: "/admisia/applicant/info",
  ADMISIA_APPLICANT_AT_A_GLANCE: "/admisia/applicant/at_a_glance",
  ADMISIA_PAYMENT_COLLECTION_ANALYTICS: "/admisia/payment/collection_analytics",
  ADMISIA_PAYMENT_COLLECTION_INFO: "/admisia/payment/collection_info",

  OA_COLLECTION_ANALYTICS: "/oa/collection_analytics",
};

export const ADMISIA_POINT = {
  INSTITUTE_CONFIG: "/institute_config",
  CORE_CONFIG: "/core_config",
  APPLICATION_CONFIG: "/application_config",

  ASSESSMENT: "/admisia_assessment",
  ADMISSION: "/admisia_admission",
  TRANSFER: "/admisia_transfer",
  APPLICANT_UPDATE_INFO: "/applicant_update_info",
  SEND_SMS_APPLICANT: "/send_sms_to_applicant",
  APPLICANT_ANALYTICS: "/applicant_analytics",
  APPLICANT_STATUS: "/applicant_status",
  APPLICANT_INFO: "/applicant_info",
  COLLECTION_ANALYTICS: "/collection_analytics",
  COLLECTION_INFO: "/collection_info",
  ADMISIA_AT_A_GLANCE: "/admisia_at_a_glance",
  MAPPED_INSTITUTE: "/admisia_map_institute",
  PAYMENT_INFO: "/payment_info",
};

export const BIJOY_POINT = {
  EXPLORE_BIJOY_CONTENT: "/explore_bijoy_content",
  BIJOY_CONTENT: "/bijoy_content",
  ESSENTIAL_SOFTWARE: "/essential_software",
  BIJOY_NEW_USER: "/new_user",
  BIJOY_EXISTING_USER: "/existing_user",
  GUEST_USER_DEVICE_UNLOCK: "/device_unlock",
};

export const EM_ADMIN_POINT = {
  PRE_PAID_BILL: "/eduman_pre-paid_bill",
  POST_PAID_BILL: "/eduman_post-paid_bill",
  SMS_TRANSFER: "/eduman_sms_transfer",
  YSS_CONFIG: "/eduman_yss_config",
  YSS_CONFIGURED_LIST: "/em_yss_configured_list",
  AUDIT_POINT_TAGGING: "/eduman_audit_point_tagging",
  AUDIT_POINT_TAGGING_LIST: "/em_audit_point_tagging_list",
  EM_ONLINE_PAYMENT_SYSTEM: "/em_online_payment_system",
  EM_ONLINE_PAYMENT_COLLECTION_SUMMARY: "/em_online_collection_summary",
  EM_ONLINE_PAYMENT_COLLECTION_DETAILS: "/em_online_collection_details",
  EM_ONLINE_PAYMENT_REFUND_REPORT: "/em_online_payment_refund_report",
  EM_ONLINE_PAYMENT_REFUND_SUMMARY: "/em_online_payment_refund_summary",
};

export const SA_POINT = {
  STUDENT_SUMMARY: "/student_summary",
  TEACHER_ATTENDENCE_INFO: "/teacher_attendance_info",
  STAFF_SUMMARY: "/hr_summary",
  STUDENT_ATTENDANCE_SUMMARY: "/student_attendance_summary",
  STAFF_ATTENDANCE_SUMMARY: "/hr_attendance_summary",
  FEE_COLLECTION_SUMMARY: "/fee_collection_summary",
  CASH_FLOW_SUMMARY: "/cash_flow_summary",
  MESSAGING_INFO: "/messaging_info",
  OBSERVER_DISTRICT_WISE_ATTENDANCE: "/observer_district_wise_attendance",
  OBSERVER_INSTITUTE_WISE_ATTENDANCE: "/observer_institute_wise_attendance",
};

export const ADMIN_POINT = {
  CATEGORY_INFO: "/category_info",
  CMS_MENU_ITEM: "/cms_menu_item",
  BANK_ACCOUNT_INFO: "/bank_account_info",
  BANK_BRANCH_INFO: "/bank_branch_info",
  PRODUCT_INFO: "/product_info",
  PRODUCT_LIST: "/product_list",
  PRODUCT_USER_ROLE: "/product_user_role",
  PRODUCT_OFFER: "/product_offer",
  PRODUCT_REVENUE: "/product_revenue",
  NEW_PARTNER_ASSIGN: "/new_partner_assign",
  PARTNER_LIST: "/partner_list",
  PARTNER_INCOME_TARGET: "/partner_income_target",
  CREATE_NOTICE: "/create_notice",
  CREATE_RELEASE_NOTICE: "/create_release_note",
  CREATE_BANNER: "/create_banner",
  CREATE_CALENDAR: "/create_global_calendar",
  DOWNLOAD_CORNER: "/download_corner_Add",
  ADD_TUTORIAL: "/add_tutorial",
  VIEW_TUTORIAL: "/view_tutorial",
  ADMIN_POINT_ASSIGN: "/admin_point_assign",
  ADMIN_POINT_ASSIGNED_LIST: "/admin_point_assigned_list",
  USER_NATURE: "/user_nature",
  BANK_BRANCH_LIST: "/bank_branch_list",
  ADMIN_USER_NATURE: "/admin_user_nature",
  ADMIN_ASSIGN_POINT: "/admin_assign_point",
  CORE_BANK_INFO: "/core_bank_info",
  DFP_BANK_INFO: "/dfp_bank_info",
  SERVICE_CHARGE_CONFIG: "/service_charge_config",
  OTHER_PRODUCT_INFO: "/other_product_info",
};

export const CRM_POINT = {
  CRM_DASHBOARD: "/crm_dashboard",
  CRM_CURRENT_STATUS_DASHBOARD: "/crm_current_info_dashboard",
  CRM_DASHBOARD_TOKEN_AND_CALL: "/crm_token_and_call_info_dashboard",
  CRM_DASHBOARD_DATE_WISE_STATUS: "/crm_date_wise_status_info_dashboard",
  DEMO_CRMDASHBOARD: "/demo_crm_dashboard",
  EDUMAN_APPROVAL: "/eduman_approval",
  URL_INSTITUTE_MAP: "/url_institute_map",
  DEVICE_APPROVAL: "/device_approval",
  PRE_PAID_DISBURSEMENT: "/prepaid_disbursement",
  EM_BILL_PROCESS: "/eduman_bill_process",
  EM_MANUAL_BILL_PROCESS: "/eduman_manual_bill_process",
  ADD_INVENTORY: "/add_inventory",
  POINT_ASSIGN: "/crm_point_assign",
  BILL_ADJUSTMENT: "/eduman_bill_adjustment",
  POINT_ASSIGNED_LIST: "/crm_point_assigned_list",
  DEPOSIT_APPROVE: "/deposit_balance_reports",
  WITHDRAW_APPROVE: "/withdraw_balance_reports",
  EDUMAN_ID_APPROVE: "/eduman_id_approve_reports",
  DOMAIN_AND_HOSTING_REQUEST: "/domain_and_hosting_request",
  DOMAIN_AND_HOSTING_APPROVE: "/domain_and_hosting_approve_reports",
  TEMP_PERSON_WISE_BILL: "/institute_wise_bill",
  ADMISIA_REQUEST: "/admisia_request",
  EDUMAN_AT_A_GLANCE: "/eduman_at_a_glance",
  PARTNER_AT_A_GLANCE: "/partner_at_a_glance",
  CRM_ADVANCE_BILL: "/crm_advance_bill",
  EDUMAN_DISCOUNT_APPROVAL: "/eduman_discount_approval",
  USER_DISABLE: "/user_disable",
  ANYONE_MESSAGE_SEND: "/anyone_message_send",
  MESSAGE_SEND_TO_PARTNER: "/message_send_to_partner",
  MESSAGE_SEND_TO_POINT_TYPE_WISE: "/message_send_to_point_type_wise",
  MESSAGE_SEND_TO_DUE_BILL: "/message_send_to_due_bill",
  MESSAGE_SEND_TO_POINT_EXPIRE: "/message_send_to_point_expire",
  DFPS_SETTLEMENT_PAYMENT: "/dfps_settlement_payment",
  OBSERVER_POINT_TAGGING: "/observer_point_tagging",
  PARTNER_OCCURRENCE: "/partner_occurrence",
  BALANCE_WITHDRAW: "/balance_withdraw",
  BALANCE_DEPOSIT: "/balance_deposit",
};

export const SUPPORT_POINT = {
  CREATE_CALL_LOGS: "/create_call_log",
  MANAGE_TOKEN: "/manage_token",
  BIJOY_ID_REPLACE: "/device_id_replace",
  EM_GLOBAL_INFO_ADD_BANK: "/eduman_global_info_add_bank",
  EM_GLOBAL_INFO_ADD_CORE: "/eduman_global_info_add_core",
  DWS_MENU_CONFIG: "/cms_menu_config",
  DWS_GLOBAL_BOOK_ADD: "/cms_global_book_add",
  MAPPING_BANK_ACCOUNT: "/mapping_bank_account",
  OBSERVER_POINT_INFO: "/observer_point_info",
  INSTITUTE_CONTACT_INFO: "/institute_contact_info",

  NEW_INSTITUTE_URL_MAP: "/new_institute_url_map",
};

export const PARTNER_POINT = {
  PRE_PAID_BILL: "/pre_paid_bill",
  POST_PAID_BILL: "/post_paid_bill",
  EM_BILL_DISCOUNT: "/em_bill_discount",
  MESSAGE_TRANSFER: "/message_transfer",
  EDUMAN_ORDER: "/eduman_order",
  DOMAIN_HOSTING_ORDER: "/domain_hosting_order",
  POINT_ASSIGN: "/point_assign",
  PARTNER_POINT_ASSIGNED_LIST: "/point_assigned_list",
  YSS_CONFIG: "/yss_config",
  PARTNER_POINT_YSS_CONFIG_LIST: "/point_yss_configured_List",
  RATING_TO_TEAM_LEAD: "/partner_rating_provide_to_team_lead",
  RATING_TO_TEAM_MEMBER: "/partner_rating_provide_to_team_member",
  RATING_BY_PARTNER: "/eduman_rating_provide_by_partner",
  DUE_STATEMENT: "/partner_due_statement",
  ASSIGN_EM_INSTITUTE: "/assign_em_institute",
  PARTNER_INFORMATION: "/partner_information",
  PARTNER_BDP_DUE_STATEMENT: "/partner_bdp_due_statement",
  PARTNER_BEP_DUE_STATEMENT: "/partner_bep_due_statement",
  EM_CURRENT_STATUS: "/em_current_status",
  EM_BILL_REPORT: "/em_money_receipt",
  EM_INSTITUTE_INVOICE: "/em_institute_invoice",
  PARTNER_BILL_COLLECTION: "/partner_bill_collection",
  PRODUCT_TYPE_WISE: "/product_type_wise",
  PARTNER_POINT_PRODUCT_PURCHASE_DETAILS: "/partner_product_purchase_details",
  PARTNER_TEAM_BILL_COLLECTION: "/partner_team_bill_collection",
  AREA_WISE_PREPAID_DEPOSIT: "/area_wise_prepaid_deposit",
  AREA_WISE_POSTPAID_COLLECTION: "/area_wise_postpaid_collection",
  PARTNER_INCOME_SUMMARY: "/partner_income_summary",
  TEAM_WISE_PURCHASE: "/team_wise_purchase",
  PARTNER_SELF_DASHBOARD: "/partner_self_dashboard",
  PARTNER_TEAM_DASHBOARD: "/partner_team_dashboard",
  PARTNER_PERFOMANC_BOARD: "/partner_performance_board",
  PARTNER_SCORE_BOARD: "/parther_score_dashboard",
  PARTNER_DUE_STATEMENT_TREE: "/partner_due_statement_tree",
  PARTNER_DASHBOARD: "/partner_dashboard",
  PARTNER_REVENUE_DISTRIBUTION_INFO: "/partner_revenue_distribution_info",
};

export const MANAGEMENT_POINT = {
  ACCUMULATE_STATEMENT: "/accumulate_statement",
  YEARLY_STATEMENT: "/yearly_statement",
  MONTHLY_STATEMENT: "/monthly_statement",
  COLLECTIVE_STATEMENT: "/collective_statement",
  MANAGEMENT_EDUMAN_CURRENT_STATUS: "/management_eduman_current_status",
  ONLINE_PAYMENT_SYSTEM: "/online_payment_system",
  MP_PARTNER_PERFOMANC_BOARD: "/mp_partner_performance_board",

  DUE_STATEMENT_TREE: "/due_statement_tree",
  MBP_DUE_STATEMENT: "/mbp_due_statement",
  ZBP_DUE_STATEMENT: "/zbp_due_statement",
  BDP_DUE_STATEMENT: "/bdp_due_statement",
  BEP_DUE_STATEMENT: "/bep_due_statement",
  INSTITUTE_DUE_STATEMENT: "/institute_due_statement",

  USER_WALLET: "/user_wallet",
  MONTHLY_TRANSACTION: "/monthly_transaction",
  MONTHLY_DEPOSIT_AMOUNT_LIST: "/monthly_deposit_amount_list",
  MONTHLY_WITHDRAW_AMOUNT_LIST: "/monthly_withdraw_amount_list",
  MONTHLY_TRANSFER_AMOUNT_LIST: "/monthly_transfer_amount_list",
  MONTHLY_PURCHASE_AMOUNT_LIST: "/monthly_purchase_amount_list",
  MONTHLY_DISBURSEMENT_AMOUNT_LIST: "/monthly_disbursement_amount_list",

  DAILY_TRANSACTION: "/daily_transaction",
  DAILY_DEPOSIT_AMOUNT_LIST: "/daily_deposit_amount_list",
  DAILY_WITHDRAW_AMOUNT_LIST: "/daily_withdraw_amount_list",
  DAILY_TRANSFER_AMOUNT_LIST: "/daily_transfer_amount_list",
  DAILY_PURCHASE_AMOUNT_LIST: "/daily_purchase_amount_list",
  DAILY_DISBURSEMENT_AMOUNT_LIST: "/daily_disbursement_amount_list",

  BALANCE_REPORT_MESSAGE_TRANSFER: "/balance_report_message_transfer",

  PREPAID_DEPOSIT_BALANCE: "/mp_prepaid_deposit_balance",
  PREPAID_DEPOSIT_TREE_VIEW: "/mp_prepaid_deposit_tree",

  MANAGEMENT_POINT_PARTNER_LIST: "/management_point_partner_list",
  MANAGEMENT_POINT_PARTNER_AREA: "/management_point_partner_area",
  PARTNER_TREE: "/partner_tree",

  PARTNER_INCOME_STATEMENT: "/partner_income_statement",
  PARTNER_INCOME_STATEMENT_DETAILS: "/partner_income_statement_details",
  PARTNER_INCOME_SUMMARY: "/mp_partner_income_summary",
  MP_PARTNER_INCOME_TREE_VIEW: "/mp_partner_income_tree",
  MP_PARTNER_PRODUCT_PURCHASE_TREE_VIEW: "/mp_partner_product_purchase_tree",

  PRODUCT_PURCHASE_REVENUE: "/product_purchase_revenue",
  PRODUCT_PRE_PURCHASE: "/product_pre_purchase",
  PRODUCT_PRE_PURCHASE_DETAILS: "/product_pre_purchase_details",
  PRODUCT_PURCHASE_INFO_BY_INVOICE_ID: "/product_purchase_info_by_invoice_ID",

  POINT_EXPIRY_INFO: "/point_expiry_info",

  APPLICATION_WISE_TOKEN: "/application_wise_token",
  MODULE_WISE_TOKEN: "/module_wise_token",
  PROBLEM_WISE_TOKEN: "/problem_wise_token",
  TITLE_WISE_TOKEN: "/title_wise_token",
  PERSON_WISE_TOKEN: "/person_wise_token",
  PERSON_WISE_TOKEN_SOLVED_LIST: "/person_wise_token_solved_list",
  PERSON_WISE_RECEIVED_CALL_LIST: "/person_wise_received_call_list",
  NETI_CHAT_HISTORY: "/neti_chat_history",

  EM_ACTION_TYPE_WISE_MODULE_LOG: "/em_action_type_wise_module_Log",
  EM_MODULE_WISE_INSTITUTE_ACTION_LOG: "/em_module_wise_institute_action_log",
  EM_MODULE_USED_UNUSED_INSTITUTE_ACTION_LOG:
    "/em_module_used_unused_institute_action_log",
  EM_INSTITUTE_WISE_ACTION_LOG: "/em_institute_wise_action_log",

  MANAGEMENT_POINT_PRODUCT_PURCHASE_DETAILS:
    "/product_purchase_details_for_management",

  //Others

  MANAGEMENT_POINT_PARTNER_BASIC_INFO: "/partner_basic_info", //PartnerAndEmAdminSendSMSNotification
  // PARTNER_AND_EMADMIN_SEND_SMS_NOTIFICATION: "/partner_and_emadmin_sendSMSnotification",  //PartnerAndEmAdminSendSMSNotification
  MANAGEMENT_POINT_AREA_WISE_PARTNER_INFO: "/area_wise_partner_info",
  MANAGEMENT_POINT_PRODUCT_SELL_TREE_VIEW: "/mp_product_sell_tree",
  MANAGEMENT_POINT_PARTNER_DASHBOARD_MBP_TYPE: "/mp_partner_dashboard_mbp_type",

  MANAGEMENT_POINT_EM_IMPLEMENTATION_SMS: "/em_implementation_SMS",
  MANAGEMENT_POINT_EM_IMPLEMENTATION_ACCOUNTS: "/em_implementation_accounts",
  MANAGEMENT_POINT_EM_ACTION_LOG_SUMMARY_CHART:
    "/em_total_actionlog_summary_chart",
  MANAGEMENT_POINT_TOKEN_SUMMARY: "/token_summary",
  MANAGEMENT_POINT_TOKEN_LIST: "/mp_token_list",

  MANAGEMENT_POINT_SOLVED_MODULE_WISE_TOKEN: "/module_wise_solved_token",
  MANAGEMENT_POINT_SOLVED_PROBLEM_WISE_TOKEN: "/problem_wise_solved_token",
  MANAGEMENT_POINT_SOLVED_TITLE_WISE_TOKEN: "/title_wise_solved_token",

  MANAGEMENT_POINT_EDUMAN_BILL_GENERATE: "/mp_eduman_bill_generate",
  MANAGEMENT_POINT_AREA_WISE_ATTENDANCE_TREE_VIEW:
    "/mp_area_wise_attendance_tree",
  MANAGEMENT_POINT_NETICMS_DASHBOARD: "/mp_neticms_dashboard",
  MANAGEMENT_POINT_NETICMS_CONFIGURED_URL: "/mp_neticms_configured_url",
  MANAGEMENT_POINT_NETICMS_NOT_CONFIGURED_URL: "/mp_neticms_not_configured_url",
  MANAGEMENT_POINT_NETICMS_INFO_FILLED_PERCENT:
    "/mp_neticms_info_filled_percent",
  MANAGEMENT_POINT_NETICMS_INFO_NOT_FILLED_PERCENT:
    "/mp_neticms_info_not_filled_percent",
  MANAGEMENT_POINT_NETICMS_EDUMAN_MAPPED: "/mp_neticms_eduman_mapped",
  MANAGEMENT_POINT_NETICMS_EDUMAN_UNMAPPED: "/mp_neticms_eduman_unmapped",

  MANAGEMENT_POINT_CALL_INFO_DASHBOARD: "/mp_call_info_dashboard",

  MANAGEMENT_POINT_VIDEO_CHAT: "/video-chat",
  MANAGEMENT_POINT_VIDEO_CHAT2: "/video-chat-2",
  MANAGEMENT_POINT_DFPS_DASHBOARD: "/dfps_dashboard",
  MONTHLY_PAID_UNPAID_INSTITUTE_INFO: "/Monthly_Paid_unpaid_institute_info",
  SEND_NOTIFICATION_SMS: "/send_sms_notification",
  BALANCE_MISMATCH_INFO: "/balance_mismatch_info",

  DATE_WISE_COLLECTION: "/date_wise_dfps_collection",
  INSTITUTE_WISE_COLLECTION: "/institute_wise_dfps_collection",
  TRANSACTION_ID_WISE_COLLECTION: "/transaction_id_wise_dfps_collection",

  DATE_RANGE_WISE_PURCHASE_REPORT: "/date_range_wise_purchase_report",
};
