import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { BalanceLogDeposit } from './BalanceLogDeposit';
import { BalanceLogWithdraw } from './BalanceLogWithdraw';
import { BalanceLogTransfer } from './BalanceLogTransfer';

export class WalletLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: this.props.location.walletTabIndex,
        }


    }

    render() {

        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                            <TabPanel header="Deposit" >
                                <BalanceLogDeposit />
                            </TabPanel>
                            <TabPanel header="Withdraw" >
                                <BalanceLogWithdraw />
                            </TabPanel>
                            <TabPanel header="Transfer" >
                                <BalanceLogTransfer />
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }

}


