import React, { Component } from 'react';
import { Growl } from 'primereact/components/growl/Growl';
import { Button } from 'primereact/components/button/Button';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Panel } from 'primereact/components/panel/Panel';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import { Link } from 'react-router-dom';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';


let urlId;
export class DwsMenuConfig extends Component {
    constructor() {
        super();
        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            accesstoken: '',
            userInfo: {},
            basicInfoDTO: {},
            dynamicWebUrldto: {},
            urlInfoDTO: {
                urlInfoID: ''
            },
            webMenuConfigDTO: {
                urlInfoDTO: {
                    urlInfoID: ''
                }
            },
            loading: false,
            homeReturnButton: false
        }

        this.dwsService = new DwsService();
        this.reloadCoreUrlIdFromLocal();
    }


    reloadCoreUrlIdFromLocal() {
        urlId = this.dwsService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount() {
        let { urlInfoDTO } = this.state;
        urlId = this.dwsService.getUrlInfoFromLocalStorage();

        if (urlId) {
            this.setState({ homeReturnButton: false })
            urlInfoDTO.urlInfoID = urlId;
            this.setState({ urlInfoDTO });
            this.dwsMenuConfig();
        } else {
            this.setState({ homeReturnButton: true })
        }
    }



    dwsMenuConfig = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        this.dwsService.getMenuConfigByUrlID(urlId)
            .then(res => {

                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log("dwsMenuConfig", body);
                        
                        this.setState({ webMenuConfigDTO: body })
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                    });
                }
                else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' });
            });
    }

    onAboutUsChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.instituteInfo = 1;
            webMenuConfigDTO.aboutUs = 1;
            webMenuConfigDTO.history = 1;
            webMenuConfigDTO.infrastructure = 1;
            webMenuConfigDTO.laboratory = 1;
            webMenuConfigDTO.playGround = 1;
            webMenuConfigDTO.computerLab = 1;
            webMenuConfigDTO.library = 1;
        } else {
            webMenuConfigDTO.aboutUs = 0;
            webMenuConfigDTO.history = 0;
            webMenuConfigDTO.infrastructure = 0;
            webMenuConfigDTO.laboratory = 0;
            webMenuConfigDTO.playGround = 0;
            webMenuConfigDTO.computerLab = 0;
            webMenuConfigDTO.library = 0;
            if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.generalInfo === 0) {
                webMenuConfigDTO.instituteInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onGeneralInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.instituteInfo = 1;
            webMenuConfigDTO.generalInfo = 1;
            webMenuConfigDTO.bookList = 1;
            webMenuConfigDTO.dressCode = 1;
            webMenuConfigDTO.feesInfo = 1;
            webMenuConfigDTO.seatInfo = 1;
            webMenuConfigDTO.syllabus = 1;
        } else {
            webMenuConfigDTO.generalInfo = 0;
            webMenuConfigDTO.bookList = 0;
            webMenuConfigDTO.dressCode = 0;
            webMenuConfigDTO.feesInfo = 0;
            webMenuConfigDTO.seatInfo = 0;
            webMenuConfigDTO.syllabus = 0;
            if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.aboutUs === 0) {
                webMenuConfigDTO.instituteInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onListOfChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.listOf = 1;
            webMenuConfigDTO.studentInfo = 1;
            webMenuConfigDTO.teacherInfo = 1;
            webMenuConfigDTO.staffInfo = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.listOf = 0;
            webMenuConfigDTO.studentInfo = 0;
            webMenuConfigDTO.teacherInfo = 0;
            webMenuConfigDTO.staffInfo = 0;
            if (webMenuConfigDTO.admission === 0 && webMenuConfigDTO.routine === 0 && webMenuConfigDTO.accountsInfo === 0) {
                webMenuConfigDTO.academicInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onAdmissionChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.admission = 1;
            webMenuConfigDTO.admisiaLink = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.admission = 0;
            webMenuConfigDTO.admisiaLink = 0;
            if (webMenuConfigDTO.listOf === 0 && webMenuConfigDTO.routine === 0 && webMenuConfigDTO.accountsInfo === 0) {
                webMenuConfigDTO.academicInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onAccountsInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.accountsInfo = 1;
            webMenuConfigDTO.findPayslip = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.accountsInfo = 0;
            webMenuConfigDTO.findPayslip = 0;
            if (webMenuConfigDTO.admission === 0 && webMenuConfigDTO.routine === 0 && webMenuConfigDTO.listOf === 0) {
                webMenuConfigDTO.academicInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onOthersChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.others = 1;
            webMenuConfigDTO.hrAttendance = 1;
            webMenuConfigDTO.attendance = 1;
        } else {
            webMenuConfigDTO.others = 0;
            webMenuConfigDTO.hrAttendance = 0;
            if (webMenuConfigDTO.studentAttendance === 0) {
                webMenuConfigDTO.attendance = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onHrAttendanceChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.others = 1;
            webMenuConfigDTO.hrAttendance = 1;
            webMenuConfigDTO.attendance = 1;
        } else {
            webMenuConfigDTO.others = 0;
            webMenuConfigDTO.hrAttendance = 0;
            if (webMenuConfigDTO.studentAttendance === 0) {
                webMenuConfigDTO.attendance = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onRoutineChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.routine = 1;
            webMenuConfigDTO.classRoutine = 1;
            webMenuConfigDTO.examRoutine = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.routine = 0;
            webMenuConfigDTO.classRoutine = 0;
            webMenuConfigDTO.examRoutine = 0;
            if (webMenuConfigDTO.admission === 0 && webMenuConfigDTO.listOf === 0 && webMenuConfigDTO.accountsInfo === 0) {
                webMenuConfigDTO.academicInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onstudentAttendanceChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.studentAttendance = 1;
            webMenuConfigDTO.sectionWiseAttd = 1;
            webMenuConfigDTO.studentWiseAttd = 1;
            webMenuConfigDTO.attendance = 1;
        } else {
            webMenuConfigDTO.studentAttendance = 0;
            webMenuConfigDTO.sectionWiseAttd = 0;
            webMenuConfigDTO.studentWiseAttd = 0;
            if (webMenuConfigDTO.others === 0) {
                webMenuConfigDTO.attendance = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }

    onphotoGalleryChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.photoGallery = 1;
            webMenuConfigDTO.othersInfo = 1;
            webMenuConfigDTO.eventGallery = 1;
        } else {
            webMenuConfigDTO.photoGallery = 0;
            webMenuConfigDTO.eventGallery = 0;
            if (webMenuConfigDTO.download === 0) {
                webMenuConfigDTO.othersInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }

    onDownloadChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.download = 1;
            webMenuConfigDTO.othersInfo = 1;
            webMenuConfigDTO.downloadCorner = 1;
        } else {
            webMenuConfigDTO.download = 0;
            webMenuConfigDTO.downloadCorner = 0;
            if (webMenuConfigDTO.photoGallery === 0) {
                webMenuConfigDTO.othersInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }

    

    onAdministrationChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.instituteInfo = 1;
            webMenuConfigDTO.administration = 1;
            webMenuConfigDTO.committee = 1;
            webMenuConfigDTO.donor = 1;
        } else {
            webMenuConfigDTO.administration = 0;
            webMenuConfigDTO.committee = 0;
            webMenuConfigDTO.donor = 0;
            if (webMenuConfigDTO.aboutUs === 0 && webMenuConfigDTO.generalInfo === 0) {
                webMenuConfigDTO.instituteInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }

    onCommitteeChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.committee = 1;
            webMenuConfigDTO.administration = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.committee = 0;
            if (webMenuConfigDTO.donor === 0) {
                webMenuConfigDTO.administration = 0;
                if (webMenuConfigDTO.aboutUs === 0 && webMenuConfigDTO.generalInfo === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onDonorChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.donor = 1;
            webMenuConfigDTO.administration = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.donor = 0;
            if (webMenuConfigDTO.committee === 0) {
                webMenuConfigDTO.administration = 0;
                if (webMenuConfigDTO.aboutUs === 0 && webMenuConfigDTO.generalInfo === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }

    onHistoryChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.history = 1;
            webMenuConfigDTO.aboutUs = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.history = 0;
            if (webMenuConfigDTO.infrastructure === 0 && webMenuConfigDTO.laboratory === 0 &&
                webMenuConfigDTO.playGround === 0 && webMenuConfigDTO.computerLab === 0 && webMenuConfigDTO.library === 0) {
                webMenuConfigDTO.aboutUs = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.generalInfo === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onInfrastructureChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.infrastructure = 1;
            webMenuConfigDTO.aboutUs = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.infrastructure = 0;
            if (webMenuConfigDTO.history === 0 && webMenuConfigDTO.laboratory === 0 &&
                webMenuConfigDTO.playGround === 0 && webMenuConfigDTO.computerLab === 0 && webMenuConfigDTO.library === 0) {
                webMenuConfigDTO.aboutUs = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.generalInfo === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onLaboratoryChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.laboratory = 1;
            webMenuConfigDTO.aboutUs = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.laboratory = 0;
            if (webMenuConfigDTO.infrastructure === 0 && webMenuConfigDTO.history === 0 &&
                webMenuConfigDTO.playGround === 0 && webMenuConfigDTO.computerLab === 0 && webMenuConfigDTO.library === 0) {
                webMenuConfigDTO.aboutUs = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.generalInfo === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onPlayGroundChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.playGround = 1;
            webMenuConfigDTO.aboutUs = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.playGround = 0;
            if (webMenuConfigDTO.infrastructure === 0 && webMenuConfigDTO.laboratory === 0 &&
                webMenuConfigDTO.history === 0 && webMenuConfigDTO.computerLab === 0 && webMenuConfigDTO.library === 0) {
                webMenuConfigDTO.aboutUs = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.generalInfo === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onComputerLabChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.computerLab = 1;
            webMenuConfigDTO.aboutUs = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.computerLab = 0;
            if (webMenuConfigDTO.infrastructure === 0 && webMenuConfigDTO.laboratory === 0 &&
                webMenuConfigDTO.playGround === 0 && webMenuConfigDTO.history === 0 && webMenuConfigDTO.library === 0) {
                webMenuConfigDTO.aboutUs = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.generalInfo === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onLibraryChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.library = 1;
            webMenuConfigDTO.aboutUs = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.library = 0;
            if (webMenuConfigDTO.infrastructure === 0 && webMenuConfigDTO.laboratory === 0 &&
                webMenuConfigDTO.playGround === 0 && webMenuConfigDTO.history === 0 && webMenuConfigDTO.computerLab === 0) {
                webMenuConfigDTO.aboutUs = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.generalInfo === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onBookListChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.bookList = 1;
            webMenuConfigDTO.generalInfo = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.bookList = 0;
            if (webMenuConfigDTO.dressCode === 0 && webMenuConfigDTO.feesInfo === 0 &&
                webMenuConfigDTO.seatInfo === 0 && webMenuConfigDTO.syllabus === 0) {
                webMenuConfigDTO.generalInfo = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.aboutUs === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onDressCodeChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.dressCode = 1;
            webMenuConfigDTO.generalInfo = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.dressCode = 0;
            if (webMenuConfigDTO.bookList === 0 && webMenuConfigDTO.feesInfo === 0 &&
                webMenuConfigDTO.seatInfo === 0 && webMenuConfigDTO.syllabus === 0) {
                webMenuConfigDTO.generalInfo = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.aboutUs === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onFeesInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.feesInfo = 1;
            webMenuConfigDTO.generalInfo = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.feesInfo = 0;
            if (webMenuConfigDTO.bookList === 0 && webMenuConfigDTO.dressCode === 0 &&
                webMenuConfigDTO.seatInfo === 0 && webMenuConfigDTO.syllabus === 0) {
                webMenuConfigDTO.generalInfo = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.aboutUs === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onSeatInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.seatInfo = 1;
            webMenuConfigDTO.generalInfo = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.seatInfo = 0;
            if (webMenuConfigDTO.bookList === 0 && webMenuConfigDTO.dressCode === 0 &&
                webMenuConfigDTO.feesInfo === 0 && webMenuConfigDTO.syllabus === 0) {
                webMenuConfigDTO.generalInfo = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.aboutUs === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onSyllabusChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.syllabus = 1;
            webMenuConfigDTO.generalInfo = 1;
            webMenuConfigDTO.instituteInfo = 1;
        } else {
            webMenuConfigDTO.syllabus = 0;
            if (webMenuConfigDTO.bookList === 0 && webMenuConfigDTO.dressCode === 0 &&
                webMenuConfigDTO.feesInfo === 0 && webMenuConfigDTO.seatInfo === 0) {
                webMenuConfigDTO.generalInfo = 0;
                if (webMenuConfigDTO.administration === 0 && webMenuConfigDTO.aboutUs === 0) {
                    webMenuConfigDTO.instituteInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onStudentInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.studentInfo = 1;
            webMenuConfigDTO.listOf = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.studentInfo = 0;
            if (webMenuConfigDTO.teacherInfo === 0 && webMenuConfigDTO.staffInfo === 0) {
                webMenuConfigDTO.listOf = 0;
                if (webMenuConfigDTO.admission === 0 && webMenuConfigDTO.routine === 0 && webMenuConfigDTO.accountsInfo === 0) {
                    webMenuConfigDTO.academicInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onTeacherInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.teacherInfo = 1;
            webMenuConfigDTO.listOf = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.teacherInfo = 0;
            if (webMenuConfigDTO.studentInfo === 0 && webMenuConfigDTO.staffInfo === 0) {
                webMenuConfigDTO.listOf = 0;
                if (webMenuConfigDTO.admission === 0 && webMenuConfigDTO.routine === 0 && webMenuConfigDTO.accountsInfo === 0) {
                    webMenuConfigDTO.academicInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onStaffInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.staffInfo = 1;
            webMenuConfigDTO.listOf = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.staffInfo = 0;
            if (webMenuConfigDTO.studentInfo === 0 && webMenuConfigDTO.teacherInfo === 0) {
                webMenuConfigDTO.listOf = 0;
                if (webMenuConfigDTO.admission === 0 && webMenuConfigDTO.routine === 0 && webMenuConfigDTO.accountsInfo === 0) {
                    webMenuConfigDTO.academicInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onClassRoutineChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.classRoutine = 1;
            webMenuConfigDTO.routine = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.classRoutine = 0;
            if (webMenuConfigDTO.examRoutine === 0) {
                webMenuConfigDTO.routine = 0;
                if (webMenuConfigDTO.admission === 0 && webMenuConfigDTO.listOf === 0 && webMenuConfigDTO.accountsInfo === 0) {
                    webMenuConfigDTO.academicInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onExamRoutineChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.examRoutine = 1;
            webMenuConfigDTO.routine = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.examRoutine = 0;
            if (webMenuConfigDTO.classRoutine === 0) {
                webMenuConfigDTO.routine = 0;
                if (webMenuConfigDTO.admission === 0 && webMenuConfigDTO.listOf === 0 && webMenuConfigDTO.accountsInfo === 0) {
                    webMenuConfigDTO.academicInfo = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onsectionWiseAttdChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.sectionWiseAttd = 1;
            webMenuConfigDTO.studentAttendance = 1;
            webMenuConfigDTO.attendance = 1;
        } else {
            webMenuConfigDTO.sectionWiseAttd = 0;
            if (webMenuConfigDTO.studentWiseAttd === 0) {
                webMenuConfigDTO.studentAttendance = 0;
                if (webMenuConfigDTO.others === 0) {
                    webMenuConfigDTO.attendance = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    oneventGalleryChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.eventGallery = 1;
            webMenuConfigDTO.photoGallery = 1;
            webMenuConfigDTO.othersInfo = 1;
        } else {
            webMenuConfigDTO.eventGallery = 0;
            webMenuConfigDTO.photoGallery = 0;
            if (webMenuConfigDTO.download === 0) {
                webMenuConfigDTO.othersInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onDownloadCornerChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.downloadCorner = 1;
            webMenuConfigDTO.download = 1;
            webMenuConfigDTO.othersInfo = 1;
        } else {
            webMenuConfigDTO.downloadCorner = 0;
            webMenuConfigDTO.download = 0;
            if (webMenuConfigDTO.photoGallery === 0) {
                webMenuConfigDTO.othersInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }

    /******     Result Info Start    ******/

    onAcademicExamChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.academicExam = 1;
            webMenuConfigDTO.resultInfo = 1;
            webMenuConfigDTO.sectionWiseResult = 1;
            webMenuConfigDTO.meritList = 1;
            webMenuConfigDTO.failList = 1;
            webMenuConfigDTO.detailsResult = 1;
        } else {
            webMenuConfigDTO.academicExam = 0;
            webMenuConfigDTO.resultInfo = 0;
            webMenuConfigDTO.sectionWiseResult = 0;
            webMenuConfigDTO.meritList = 0;
            webMenuConfigDTO.failList = 0;
            webMenuConfigDTO.detailsResult = 0;
        }
        this.setState({ webMenuConfigDTO });
    }

    onsectionWiseResultChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.academicExam = 1;
            webMenuConfigDTO.resultInfo = 1;
            webMenuConfigDTO.sectionWiseResult = 1;
        } else {
            webMenuConfigDTO.sectionWiseResult = 0;
            if (webMenuConfigDTO.meritList === 0 && webMenuConfigDTO.failList === 0 && webMenuConfigDTO.detailsResult === 0) {
                webMenuConfigDTO.academicExam = 0;
                webMenuConfigDTO.resultInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onMeritListChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.academicExam = 1;
            webMenuConfigDTO.resultInfo = 1;
            webMenuConfigDTO.meritList = 1;
        } else {
            webMenuConfigDTO.meritList = 0;
            if (webMenuConfigDTO.sectionWiseResult === 0 && webMenuConfigDTO.failList === 0 && webMenuConfigDTO.detailsResult === 0) {
                webMenuConfigDTO.academicExam = 0;
                webMenuConfigDTO.resultInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onFailListChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.academicExam = 1;
            webMenuConfigDTO.resultInfo = 1;
            webMenuConfigDTO.failList = 1;
        } else {
            webMenuConfigDTO.failList = 0;
            if (webMenuConfigDTO.meritList === 0 && webMenuConfigDTO.sectionWiseResult === 0 && webMenuConfigDTO.detailsResult === 0) {
                webMenuConfigDTO.academicExam = 0;
                webMenuConfigDTO.resultInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    ondetailsResultChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.academicExam = 1;
            webMenuConfigDTO.resultInfo = 1;
            webMenuConfigDTO.detailsResult = 1;
        } else {
            webMenuConfigDTO.detailsResult = 0;
            if (webMenuConfigDTO.meritList === 0 && webMenuConfigDTO.failList === 0 && webMenuConfigDTO.sectionWiseResult === 0) {
                webMenuConfigDTO.academicExam = 0;
                webMenuConfigDTO.resultInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    /******     Result info End      *******/
    
    onstudentWiseAttdChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.studentWiseAttd = 1;
            webMenuConfigDTO.studentAttendance = 1;
            webMenuConfigDTO.attendance = 1;
        } else {
            webMenuConfigDTO.studentWiseAttd = 0;
            if (webMenuConfigDTO.sectionWiseAttd === 0) {
                webMenuConfigDTO.studentAttendance = 0;
                if (webMenuConfigDTO.others === 0) {
                    webMenuConfigDTO.attendance = 0;
                }
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onOnlineAdmissionChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.admisiaLink = 1;
            webMenuConfigDTO.admission = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.admisiaLink = 0;
            webMenuConfigDTO.admission = 0;
            if (webMenuConfigDTO.listOf === 0 && webMenuConfigDTO.routine === 0 && webMenuConfigDTO.accountsInfo === 0) {
                webMenuConfigDTO.academicInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }
    onFindPayslipChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.findPayslip = 1;
            webMenuConfigDTO.accountsInfo = 1;
            webMenuConfigDTO.academicInfo = 1;
        } else {
            webMenuConfigDTO.findPayslip = 0;
            webMenuConfigDTO.accountsInfo = 0;
            if (webMenuConfigDTO.admission === 0 && webMenuConfigDTO.routine === 0 && webMenuConfigDTO.listOf === 0) {
                webMenuConfigDTO.academicInfo = 0;
            }
        }
        this.setState({ webMenuConfigDTO });
    }

    onAttendanceChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.attendance = 1;
            webMenuConfigDTO.studentAttendance = 1;
            webMenuConfigDTO.sectionWiseAttd = 1;
            webMenuConfigDTO.studentWiseAttd = 1;
            webMenuConfigDTO.others = 1;
            webMenuConfigDTO.hrAttendance = 1;
        } else {
            webMenuConfigDTO.attendance = 0;
            webMenuConfigDTO.studentAttendance = 0;
            webMenuConfigDTO.sectionWiseAttd = 0;
            webMenuConfigDTO.studentWiseAttd = 0;
            webMenuConfigDTO.others = 0;
            webMenuConfigDTO.hrAttendance = 0;
        }
        this.setState({ webMenuConfigDTO });
    }

    onOthersInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.othersInfo = 1;
            webMenuConfigDTO.photoGallery = 1;
            webMenuConfigDTO.download = 1;
            webMenuConfigDTO.downloadCorner = 1;
            webMenuConfigDTO.eventGallery = 1;
        } else {
            webMenuConfigDTO.othersInfo = 0;
            webMenuConfigDTO.photoGallery = 0;
            webMenuConfigDTO.download = 0;
            webMenuConfigDTO.downloadCorner = 0;
            webMenuConfigDTO.eventGallery = 0;
        }
        this.setState({ webMenuConfigDTO });
    }

    onResultInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.resultInfo = 1;
            webMenuConfigDTO.academicExam = 1;
            webMenuConfigDTO.sectionWiseResult = 1;
            webMenuConfigDTO.meritList = 1;
            webMenuConfigDTO.failList = 1;
            webMenuConfigDTO.detailsResult = 1;
        } else {
            webMenuConfigDTO.resultInfo = 0;
            webMenuConfigDTO.academicExam = 0;
            webMenuConfigDTO.sectionWiseResult = 0;
            webMenuConfigDTO.meritList = 0;
            webMenuConfigDTO.failList = 0;
            webMenuConfigDTO.detailsResult = 0;
        }
        this.setState({ webMenuConfigDTO });
    }

    onAcademicInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.academicInfo = 1;
            webMenuConfigDTO.listOf = 1;
            webMenuConfigDTO.studentInfo = 1;
            webMenuConfigDTO.teacherInfo = 1;
            webMenuConfigDTO.staffInfo = 1;
            webMenuConfigDTO.admission = 1;
            webMenuConfigDTO.admisiaLink = 1;
            webMenuConfigDTO.routine = 1;
            webMenuConfigDTO.classRoutine = 1;
            webMenuConfigDTO.examRoutine = 1;
            webMenuConfigDTO.accountsInfo = 1;
            webMenuConfigDTO.findPayslip = 1;
        } else {
            webMenuConfigDTO.academicInfo = 0;
            webMenuConfigDTO.listOf = 0;
            webMenuConfigDTO.studentInfo = 0;
            webMenuConfigDTO.teacherInfo = 0;
            webMenuConfigDTO.staffInfo = 0;
            webMenuConfigDTO.admission = 0;
            webMenuConfigDTO.admisiaLink = 0;
            webMenuConfigDTO.routine = 0;
            webMenuConfigDTO.classRoutine = 0;
            webMenuConfigDTO.examRoutine = 0;
            webMenuConfigDTO.accountsInfo = 0;
            webMenuConfigDTO.findPayslip = 0;
        }
        this.setState({ webMenuConfigDTO });
    }

    onInstituteInfoChange(e) {
        var { webMenuConfigDTO } = this.state;
        if (e.checked) {
            webMenuConfigDTO.instituteInfo = 1;
            webMenuConfigDTO.administration = 1;
            webMenuConfigDTO.committee = 1;
            webMenuConfigDTO.donor = 1;
            webMenuConfigDTO.aboutUs = 1;
            webMenuConfigDTO.history = 1;
            webMenuConfigDTO.infrastructure = 1;
            webMenuConfigDTO.laboratory = 1;
            webMenuConfigDTO.playGround = 1;
            webMenuConfigDTO.computerLab = 1;
            webMenuConfigDTO.library = 1;
            webMenuConfigDTO.generalInfo = 1;
            webMenuConfigDTO.bookList = 1;
            webMenuConfigDTO.dressCode = 1;
            webMenuConfigDTO.feesInfo = 1;
            webMenuConfigDTO.seatInfo = 1;
            webMenuConfigDTO.syllabus = 1;
        } else {
            webMenuConfigDTO.instituteInfo = 0;
            webMenuConfigDTO.administration = 0;
            webMenuConfigDTO.committee = 0;
            webMenuConfigDTO.donor = 0;
            webMenuConfigDTO.aboutUs = 0;
            webMenuConfigDTO.history = 0;
            webMenuConfigDTO.infrastructure = 0;
            webMenuConfigDTO.laboratory = 0;
            webMenuConfigDTO.playGround = 0;
            webMenuConfigDTO.computerLab = 0;
            webMenuConfigDTO.library = 0;
            webMenuConfigDTO.generalInfo = 0;
            webMenuConfigDTO.bookList = 0;
            webMenuConfigDTO.dressCode = 0;
            webMenuConfigDTO.feesInfo = 0;
            webMenuConfigDTO.seatInfo = 0;
            webMenuConfigDTO.syllabus = 0;
        }
        this.setState({ webMenuConfigDTO });
    }

    updateDynamicWebMenuConfig = () => {
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        let { webMenuConfigDTO } = this.state;

        this.dwsService.updateMenuConfig(webMenuConfigDTO)
            .then(res => {
                if (res.status == 202) {
                    this.setState({ topProgressBar: false, errorMsgVisible: false });
                    this.dwsMenuConfig();
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully updated!" });
                } else {
                    this.dwsService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' });
            });
    }

    dwsMenuConfigReset = () => {
        this.dwsMenuConfig();
    }

    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let getInstituteInfoMenu = <div className="p-col-12">
            <Checkbox value={this.state.webMenuConfigDTO.instituteInfo} onChange={this.onInstituteInfoChange.bind(this)}
                checked={this.state.webMenuConfigDTO.instituteInfo === 1 ? true : false}></Checkbox>
            <label className="p-checkbox-label">Institute Info</label>
        </div>
        let getAcademicInfoMenu = <div className="p-col-12">
            <Checkbox value={this.state.webMenuConfigDTO.academicInfo} onChange={this.onAcademicInfoChange.bind(this)}
                checked={this.state.webMenuConfigDTO.academicInfo === 1 ? true : false}></Checkbox>
            <label className="p-checkbox-label">Academic Info</label>
        </div>

        let getAttendanceMenu = <div className="p-col-12">
            <Checkbox value={this.state.webMenuConfigDTO.attendance} onChange={this.onAttendanceChange.bind(this)}
                checked={this.state.webMenuConfigDTO.attendance === 1 ? true : false}></Checkbox>
            <label className="p-checkbox-label">Attendance</label>
        </div>
        let getResultInfoMenu = <div className="p-col-12">
            <Checkbox value={this.state.webMenuConfigDTO.resultInfo} onChange={this.onResultInfoChange.bind(this)}
                checked={this.state.webMenuConfigDTO.resultInfo === 1 ? true : false}></Checkbox>
            <label className="p-checkbox-label">Result Info</label>
        </div>
        let getOthersInfoMenu = <div className="p-col-12">
            <Checkbox value={this.state.webMenuConfigDTO.othersInfo} onChange={this.onOthersInfoChange.bind(this)}
                checked={this.state.webMenuConfigDTO.othersInfo === 1 ? true : false}></Checkbox>
            <label className="p-checkbox-label">Others Info</label>
        </div>


        return (

            <div className="p-fluid">
                <NetiCMSSetupProgress/>
                
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                

                    <div className="accrodion-section">

                        <Accordion activeIndex={this.state.activeIndex || 0} onTabChange={(e) => this.setState({activeIndex: e.index})} >
                            <AccordionTab header="Menu Configuration">
                                <div className="main-section">
                                    <div className="p-grid nw-form">
                                        <div className="p-col-12 p-xl-12">
                                            
                                            

                                            {this.state.homeReturnButton === false ?
                                                <div className="main-section">

                                                    {/* <div className="main-section">

                                                    </div> */}

                                                    <Panel header="Menu Config">
                                                        <Panel header={getInstituteInfoMenu}>
                                                            <div className="p-grid p-col-12" >
                                                                <div className="p-col-12 p-lg-4" >
                                                                    <div className="p-col-12 ">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.administration} onChange={this.onAdministrationChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.administration === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">Administration</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.committee} onChange={this.onCommitteeChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.committee === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Committee</label>
                                                                        </div>

                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.donor} onChange={this.onDonorChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.donor === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Donor</label>
                                                                        </div>

                                                                    </div>
                                                                </div>



                                                                <div className="p-col-12 p-lg-4" >
                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.aboutUs} onChange={this.onAboutUsChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.aboutUs === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">About Us</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.history} onChange={this.onHistoryChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.history === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">History</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.infrastructure} onChange={this.onInfrastructureChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.infrastructure === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Infrastructure</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.laboratory} onChange={this.onLaboratoryChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.laboratory === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Laboratory</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.playGround} onChange={this.onPlayGroundChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.playGround === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Playground</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.computerLab} onChange={this.onComputerLabChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.computerLab === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Computer Lab</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.library} onChange={this.onLibraryChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.library === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Library</label>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                                <div className="p-col-12 p-lg-4" >

                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.generalInfo} onChange={this.onGeneralInfoChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.generalInfo === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">General Info</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.bookList} onChange={this.onBookListChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.bookList === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Book List</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.dressCode} onChange={this.onDressCodeChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.dressCode === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Dress Code</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.feesInfo} onChange={this.onFeesInfoChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.feesInfo === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Fees Info</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.seatInfo} onChange={this.onSeatInfoChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.seatInfo === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Seat Info</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.syllabus} onChange={this.onSyllabusChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.syllabus === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Syllabus</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Panel>

                                                        <Panel header={getAcademicInfoMenu}>
                                                            <div className="p-grid p-col-12" >
                                                                <div className="p-col-12 p-lg-3" >
                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.listOf} onChange={this.onListOfChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.listOf === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">List Of</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.studentInfo} onChange={this.onStudentInfoChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.studentInfo === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Student Info</label>
                                                                        </div>

                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.teacherInfo} onChange={this.onTeacherInfoChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.teacherInfo === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Teacher Info</label>
                                                                        </div>

                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.staffInfo} onChange={this.onStaffInfoChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.staffInfo === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Staff Info</label>
                                                                        </div>

                                                                    </div>
                                                                </div>



                                                                <div className="p-col-12 p-lg-3" >
                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.admission} onChange={this.onAdmissionChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.admission === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">Admission</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.admisiaLink} onChange={this.onOnlineAdmissionChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.admisiaLink === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Online Admission</label>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="p-col-12 p-lg-3" >

                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.routine} onChange={this.onRoutineChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.routine === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">Routine</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.classRoutine} onChange={this.onClassRoutineChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.classRoutine === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Class Routine</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.examRoutine} onChange={this.onExamRoutineChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.examRoutine === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Exam Routine</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="p-col-12 p-lg-3" >

                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.accountsInfo} onChange={this.onAccountsInfoChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.accountsInfo === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">Accounts Info</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.findPayslip} onChange={this.onFindPayslipChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.findPayslip === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Find Pay-slip</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Panel>

                                                        <Panel header={getAttendanceMenu}>
                                                            <div className="p-grid p-col-12" >
                                                                <div className="p-col-12 p-lg-4" >
                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.studentAttendance} onChange={this.onstudentAttendanceChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.studentAttendance === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">Student Attendance</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.sectionWiseAttd} onChange={this.onsectionWiseAttdChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.sectionWiseAttd === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Section Wise</label>
                                                                        </div>

                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.studentWiseAttd} onChange={this.onstudentWiseAttdChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.studentWiseAttd === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Student Wise</label>
                                                                        </div>

                                                                    </div>
                                                                </div>



                                                                <div className="p-col-12 p-lg-4" >
                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.others} onChange={this.onOthersChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.others === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">HR Attendance</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.hrAttendance} onChange={this.onHrAttendanceChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.hrAttendance === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Staff & Teachers</label>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="p-col-12 p-lg-4" >


                                                                </div>

                                                            </div>
                                                        </Panel>

                                                        <Panel header={getResultInfoMenu}>
                                                            <div className="p-grid p-col-12" >
                                                                <div className="p-col-12 p-lg-4" >
                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.academicExam} onChange={this.onAcademicExamChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.academicExam === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">Academic Exam</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.sectionWiseResult} onChange={this.onsectionWiseResultChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.sectionWiseResult === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Section Wise Result</label>
                                                                        </div>

                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.meritList} onChange={this.onMeritListChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.meritList === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Merit List</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.failList} onChange={this.onFailListChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.failList === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Fail List</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.detailsResult} onChange={this.ondetailsResultChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.detailsResult === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Details Result</label>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="p-col-12 p-lg-4" >

                                                                </div>


                                                                <div className="p-col-12 p-lg-4" >


                                                                </div>

                                                            </div>
                                                        </Panel>

                                                        <Panel header={getOthersInfoMenu}>
                                                            <div className="p-grid p-col-12" >
                                                                <div className="p-col-12 p-lg-4" >
                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.photoGallery} onChange={this.onphotoGalleryChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.photoGallery === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">Gallery</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.eventGallery} onChange={this.oneventGalleryChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.eventGallery === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Event Gallery</label>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="p-col-12 p-lg-4" >
                                                                    <div className="p-col-12">
                                                                        <Checkbox value={this.state.webMenuConfigDTO.download} onChange={this.onDownloadChange.bind(this)}
                                                                            checked={this.state.webMenuConfigDTO.download === 1 ? true : false}></Checkbox>
                                                                        <label className="p-checkbox-label">Download</label>
                                                                    </div>
                                                                    <div className="p-grid p-col-12">
                                                                        <div className="p-md-3"></div>
                                                                        <div className="p-md-9">
                                                                            <Checkbox value={this.state.webMenuConfigDTO.downloadCorner} onChange={this.onDownloadCornerChange.bind(this)}
                                                                                checked={this.state.webMenuConfigDTO.downloadCorner === 1 ? true : false}></Checkbox>
                                                                            <label className="p-checkbox-label">Download Corner</label>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="p-col-12 p-lg-4" >
                                                                </div>

                                                            </div>


                                                            
                                                            
                                                        </Panel>

                                                    </Panel>

                                                    <div className="p-grid">
                                                        <div className="p-col-12"></div>
                                                        <div className="p-col-12 nw-button-parent-multiple">
                                                            <Button
                                                                className="p-button p-button-danger nw-button nw-button-multiple"
                                                                label="Reset"
                                                                icon="fas fa-times"
                                                                onClick={this.dwsMenuConfigReset}
                                                            />
                                                            <Button
                                                                className="p-button p-button-primary nw-button nw-button-multiple"
                                                                label="Update"
                                                                icon="fas fa-check"
                                                                onClick={this.updateDynamicWebMenuConfig.bind(this)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="">
                                                    <div className="p-grid">
                                                        <div className="card p-col-12 p-xl-3">
                                                            <center className="custom-center">
                                                                <Link
                                                                    to="/home"
                                                                    className="rainbow-button"
                                                                    alt="Go DWS Home"
                                                                />
                                                            </center>
                                                        </div>
                                                        <div className="card p-col-12 p-xl-3">
                                                            <center className="custom-center">
                                                                <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                                            </center>
                                                        </div>
                                                        <div className="card p-col-12 p-xl-6">
                                                            <img src="assets/images/dws_home.png" width="100%" />
                                                        </div>
                                                    </div>
                                                </div>
                                            }


                                            
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                

            </div>

        );
    }
}

