import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Panel } from 'primereact/panel';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { FileUpload } from 'primereact/fileupload';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { DomainOrderList } from './DomainOrderList';
import { ErrorMessageView } from '../common/ErrorMessageView';
import NetiContentLoader from '../common/NetiContentLoader';


let errors = {}
let cloneStateBeforeMount;
export class DomainOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            urlSearchViewError: false,
            hostingError: [],
            buttonDisabled: true,
            purchaseCodeSearchBtnDisabled: true,
            urlSearchBtnDisabled: true,
            urlCheckError: [],
            purchaseCodeError: {},
            chekMessage: '',
            purchaseCodeDomain: '',
            purchaseCodeHosting: '',
            domainDetails: {
                purchaseCode: ''
            },
            hostingDetails: {
                purchaseCode: ''

            },
            orderInfo: {
                domainName: '',
                attachFileName: '',
                attachFilePath: '',
                attachFileContent: '',
                attachFileSaveOrEditable: true,
                productPurchaseCodeDTO: {}
            },
            uploadfile: {
            },
            errors: {},
            domainOrderListReload: true,

            topProgressBar: false,
            errorMsgVisible: false,
            inputFieldIsLoading: false,
            errorMsgBody: '',
            searchView: false,
            searchViewError: false,
            searchErrorMsgBody: '',

        }

        this.PartnerPointService = new PartnerPointService();
        this.NetiDateUtils = new NetiDateUtils();
        this.NetiFileHandler = new NetiFileHandler();
        this.netiContentLoader = new NetiContentLoader();

    }

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state))
    }

    purchaseCodeDomainHandler = (event) => {
        let { purchaseCodeError } = this.state;
        purchaseCodeError['purchaseCodeDomain'] = '';
        this.setState({ purchaseCodeDomain: event.target.value, purchaseCodeError })
    }

    urlHandler = (event) => {
        let { orderInfo } = this.state;
        orderInfo.domainName = event.target.value.toLowerCase()
        this.setState({ orderInfo })
    }

    searchPurchaseCode = (e) => {
        let { purchaseCodeError } = this.state;
        this.setState({ domainDetails: cloneStateBeforeMount.domainDetails, searchView: false, searchErrorMsgBody: '', });
        if (this.checkPurchaseCodeError()) {
            this.setState({ topProgressBar: true, searchViewError: false, searchView: true, inputFieldIsLoading: true, errorMsgVisible: false });

            this.PartnerPointService.getUserPurchaseCode(this.state.purchaseCodeDomain)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            let productDefaultCode = body.productPurchaseLogDTO.productInfoDTO.productDefaultCode

                            if(productDefaultCode == 326 || productDefaultCode == 328){
                                this.setState({ purchaseCodeSearchBtnDisabled: false })

                                let { domainDetails, orderInfo } = this.state;
                                domainDetails.productPurchaseCodeID = body.productPurchaseCodeID
                                domainDetails.productName = body.productPurchaseLogDTO.productInfoDTO.productName;
                                domainDetails.purchaseCode = body.purchaseCode;
                                domainDetails.usedStatus = body.usedStatus;
                                domainDetails.purchaseDate = NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
                                this.setState({ domainDetails, topProgressBar: false, searchView: true, searchViewError: false, inputFieldIsLoading: false, errorMsgVisible: false });
                            }
                            else{
                                this.setState({
                                    purchaseCodeSearchBtnDisabled: true,
                                    searchErrorMsgBody: "Purchase code is invalid for this segment",
                                    searchViewError: true,
                                    searchView: true,
                                    domainDetails: {
                                        purchaseCode: ''
                                    }
                                });
                                this.setState({ topProgressBar: false, inputFieldIsLoading: false, errorMsgVisible: true });
                            }
                            
                        })

                    } else {
                        this.PartnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({
                                    purchaseCodeSearchBtnDisabled: true,
                                    searchErrorMsgBody: resp,
                                    searchViewError: true,
                                    searchView: true,
                                    domainDetails: {
                                        purchaseCode: ''
                                    }
                                });
                                this.setState({ topProgressBar: false, inputFieldIsLoading: false, errorMsgVisible: true });
                            })
                    }
                }).catch(error => {
                    this.setState({ purchaseCodeSearchBtnDisabled: true, topProgressBar: false, inputFieldIsLoading: false, searchView: false, searchViewError: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection' });
                })
        }
    }

    checkPurchaseCodeError = () => {
        let { purchaseCodeError } = this.state;
        let formIsValid = true;

        if (!this.state.purchaseCodeDomain) {
            formIsValid = false;
            purchaseCodeError["purchaseCodeDomain"] = "Purchase Code can't left empty";
        }

        this.setState({ purchaseCodeError });
        return formIsValid;
    }

    exitsUrlCheckHandler = () => {
        if (this.urlCheckError()) {
            let { orderInfo, urlCheckError } = this.state;
            this.setState({ topProgressBar: true })
            this.PartnerPointService.checkExitsUrl(orderInfo.domainName)
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ urlSearchBtnDisabled: false })
                        urlCheckError["success"] = "This URL is available";
                        this.setState({ urlCheckError, topProgressBar: false, urlSearchViewError: true });
                    }
                    else {
                        this.setState({ urlSearchBtnDisabled: true })
                        urlCheckError["success"] = "This URL is currently unavailable.Please try another one";
                        this.setState({ urlCheckError, topProgressBar: false, urlSearchViewError: true });
                    }
                }).catch(error => {
                    this.setState({ urlSearchBtnDisabled: true, topProgressBar: false, urlSearchViewError: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection' });
                })
            errors['urlError'] = '';
        }

    }

    onLoadFile(e) {
        var reader = new FileReader();
        let photo = e.files[0];
        const scope = this
        reader.readAsDataURL(photo);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
            var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
            let album = {
                extention: photo.type,
                fileContent: urlStr,
                contentName: photo.name
            };
            scope.setState({ uploadfile: album });

        }
    }

    domainOrderHandler = () => {

        let { orderInfo, domainDetails } = this.state;

        if (this.props.domain == true) {
            orderInfo.productPurchaseCodeDTO.productPurchaseCodeID = domainDetails.productPurchaseCodeID
            orderInfo.productPurchaseCodeDTO.purchaseCode = domainDetails.purchaseCode
        }



        if (this.checkOrderSubmitError()) {

            if (this.state.uploadfile.fileContent != null) {
                orderInfo.attachFileContent = this.state.uploadfile.fileContent;
                orderInfo.attachFileSaveOrEditable = true;
                let extention = this.NetiFileHandler.getImageExtention(this.state.uploadfile.extention);
                orderInfo.attachFileName = 'domain_order_file_' + Date.now() + extention;
            }
            else {
                orderInfo.attachFileSaveOrEditable = false;
            }
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.PartnerPointService.createNewDomainOrder(orderInfo)
                .then(res => {
                    if (res.status == 201) {
                        return res.json().then((body) => {
                            this.growl.show({ severity: 'success', summary: 'Order Successfully', detail: 'Data Saved' });
                            this.setState(cloneStateBeforeMount);
                            errors = {};
                            this.setState({ domainOrderListReload: false });
                            this.setState({ domainOrderListReload: true });
                            this.setState({ topProgressBar: false, errorMsgVisible: false });

                        })
                    } else {
                        return res.json().then((erbody) => {
                            //this.growl.show({ severity: 'error', summary: 'Error Message', detail: 'Purchase code not correct' });
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: erbody.message });

                        })
                    }
                })
        }

    }



    urlCheckError() {
        let { urlCheckError, orderInfo, purchaseCodeError } = this.state;
        let formIsValid = true;

        if (!orderInfo.domainName) {
            formIsValid = false;
            errors["urlError"] = "Desire URL can't left empty";
            this.setState({ urlCheckError: [] })
        }
        return formIsValid;
    }

    checkOrderSubmitError() {
        let { urlCheckError, orderInfo, purchaseCodeError, uploadfile } = this.state;
        let formIsValid = true;

        if (!orderInfo.productPurchaseCodeDTO.productPurchaseCodeID && !this.state.purchaseCodeDomain) {
            formIsValid = false;
            errors["purchaseCodeDomain"] = "Purchase Code can't left empty";
        }
        if (!orderInfo.domainName) {
            formIsValid = false;
            errors["urlError"] = "Desire URL can't left empty";
            this.setState({ urlCheckError: [] })
        }
        if (!uploadfile.contentName) {
            formIsValid = false;
            errors["attachment"] = "Attachment can't left empty";
        }
        return formIsValid;
    }

    focusSearch = e =>{
        this.setState({
            searchLabel:"Search",
            addSearchBtnClass:"nw-button p-button-primary text-color-white"
        });
    }

    blurSearch = e =>{
        this.setState({
            searchLabel:"",
            addSearchBtnClass:""
        });
    }

    focusSearchUrl = e =>{
        this.setState({
            searchLabelUrl:"Search",
            addSearchBtnClassUrl:"nw-button p-button-primary text-color-white"
        });
    }

    blurSearchUrl = e =>{
        this.setState({
            searchLabelUrl:"",
            addSearchBtnClassUrl:""
        });
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, inputFieldIsLoading } = this.state;
        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} position="topright"></Growl>
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12">
                        <Panel header="Domain Info" style={{ margin: "0px" }}>
                            <Growl ref={(el) => this.growl = el} />
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Purchase Code <span>*</span></span>
                                            <InputText
                                                value={this.state.purchaseCodeDomain}
                                                onChange={(e) => this.purchaseCodeDomainHandler(e)}
                                                placeholder="Enter Purchase Code"
                                                name="customNetiID"
                                                type="text"
                                                style={{ width:"15%" }}
                                                keyfilter="pint"
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                label={this.state.searchLabel}
                                                icon="fas fa-search"
                                                onClick={(e) => this.searchPurchaseCode(e)}
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.purchaseCodeDomain ? '' : this.state.purchaseCodeError["purchaseCodeDomain"]}</span>
                                    </div>


                                    {this.state.searchView ?
                                        <div className="p-col-12 p-xl-12">
                                            <div className="nw-search-view">
                                                {this.state.searchViewError ?
                                                    <center className="error-message">{this.state.searchErrorMsgBody}</center> :
                                                    <div>

                                                        {!this.state.domainDetails.purchaseCode && !inputFieldIsLoading ?
                                                            <div className="text-center">
                                                                <div className="devicePassword">
                                                                    <i className="fa fa-info-circle"></i>
                                                                </div>
                                                                <span><b>Purchase domain info is showing here</b></span>
                                                            </div>
                                                            :
                                                            <div>
                                                                <div className="p-col-12 p-xl-12">
                                                                    {inputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Product Name</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.domainDetails.productName}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {inputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Purchase Code</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.domainDetails.purchaseCode}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {inputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Use Status</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.domainDetails.usedStatus === 0 ? "Unused" : this.state.domainDetails.usedStatus}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div className="p-col-12 p-xl-12">
                                                                    {inputFieldIsLoading ? this.netiContentLoader.normalFormInputField() :
                                                                        <div className="p-inputgroup">
                                                                            <span className="task-badge found"></span>
                                                                            <span className="p-inputgroup-addon">Purchase Date</span>
                                                                            <span className="p-inputgroup-colon">:</span>
                                                                            <div className="nw-inputgroup-desc">
                                                                                {this.state.domainDetails.purchaseDate}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }


                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }

                                </div>
                            </div>

                        </Panel>
                    </div>


                    <div className="p-col-12 p-xl-12">
                        <div className="card card-w-title">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Desire URL <span>*</span></span>
                                            <InputText
                                                placeholder="Vote"
                                                value={this.state.orderInfo.domainName}
                                                onChange={(e) => this.urlHandler(e)}
                                                placeholder="Enter Desire URL"
                                                style={{ width:"15%" }}
                                                onFocus={this.focusSearchUrl}
                                                onBlur={this.blurSearchUrl}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClassUrl}
                                                label={this.state.searchLabelUrl}
                                                icon="fas fa-search"
                                                onFocus={this.focusSearchUrl}
                                                onBlur={this.blurSearchUrl}
                                                onClick={(e) => this.exitsUrlCheckHandler(e)}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.orderInfo.domainName ? '' : errors["urlError"]}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="nw-search-view">
                                            {
                                                this.state.urlSearchViewError ?
                                                    <center>{this.state.urlCheckError['success']}</center> :
                                                    <div></div>
                                            }
                                        </div>
                                    </div>

                                    <div className="p-xl-12 p-col-12 nw-upload-button">
                                        <FileUpload
                                            mode="basic"
                                            maxFileSize={10000000}
                                            onSelect={this.onLoadFile.bind(this)}
                                            auto={true}
                                            chooseLabel="Choose an Attachment"
                                        />
                                        <center>
                                            <span>{this.state.uploadfile.contentName}</span>
                                            <span className="error-message">{this.state.uploadfile.contentName ? '' : errors["attachment"]}</span>
                                        </center>
                                        
                                    </div>

                                    <div className="p-col-12 p-xl-12" >
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Order"
                                            icon="fas fa-cart-arrow-down"
                                            onClick={(e) => this.domainOrderHandler(e)}
                                            disabled={this.state.purchaseCodeSearchBtnDisabled == false && this.state.urlSearchBtnDisabled == false ? !this.state.buttonDisabled : this.state.buttonDisabled}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="p-col-12" >
                        {this.state.domainOrderListReload ? <DomainOrderList domain={this.props.domain} /> : ''}
                    </div>


                </div>
            </div>
        );
    }
}