import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { Growl } from 'primereact/growl';

import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../common/JsPdfDownload';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';

let maxDate = new Date();
let errors = {}

// pdf
let subTitleColumn = [];
let subTitleList = [];
export class BalanceReportMessageTransfer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchDataObj: {
                startDate: '',
                endDate: '',
                pageLimit: 0,
                pageNo: 0
            },
            dataTableValue: [],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            errors: {},
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ]
        }

        this.NetiDateUtils = new NetiDateUtils();
        this.managementPointService = new ManagementPointService();

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.exportExcelLog = this.exportExcelLog.bind(this);

    }

    startDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["startDate"] = "";
        searchDataObj.startDate = event.target.value;
        this.setState({ searchDataObj, errors })
    }

    endDateHandler = event => {
        let { searchDataObj } = this.state;
        errors["endDate"] = "";
        searchDataObj.endDate = event.target.value;
        this.setState({ searchDataObj, errors })
    }

    searchHandleError = () => {
        let { errors, searchDataObj } = this.state;
        let formIsValid = true;
        if (searchDataObj.startDate === '') {
            formIsValid = false;
            errors["startDate"] = "Start date can't left empty";
        }
        if (searchDataObj.endDate === '') {
            formIsValid = false;
            errors["endDate"] = "End date can't left empty";
        }
        this.setState({ errors });
        return formIsValid;
    }

    onDateWiseSearchHandler = () => {
        let { searchDataObj, errors } = this.state;
        this.setState({ errors: {} })

        if (this.searchHandleError()) {
            this.setState({ tableview: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });

            if (Date.parse(searchDataObj.endDate) >= Date.parse(searchDataObj.startDate)) {
                let saveSearchObj = { ...searchDataObj }
                saveSearchObj.startDate = NetiDateUtils.getDateFromString(searchDataObj.startDate);
                saveSearchObj.endDate = NetiDateUtils.getDateFromString(searchDataObj.endDate);
                this.managementPointService.fetchMessageTransferLogList(saveSearchObj)
                    .then(res => {
                        if (res.status == 200) {
                            return res.json().then((body) => {
                                if (body.length == 0) {
                                    return this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "No Data Found." })
                                }
                                body.map(item => {
                                    item.trxDate = NetiDateUtils.getAnyShortForm(item.trxDate, 'DD-MMM-YYYY ') + NetiDateUtils.getHHMMSSFForHome(item.trxDate);
                                    Object.assign(item, { emDetailsDTOinstituteId: item.emDetailsInfoDTO.instituteId });  // for pdf column
                                    Object.assign(item, { emDetailsDTOinstituteName: item.emDetailsInfoDTO.instituteName });  // for pdf column
                                })

                                this.setState({ dataTableValue: body, topProgressBar: false, tableView: true, dataTableIsLoading: false });
                            });
                        } else {
                            this.managementPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });
            } else {
                this.growl.show({ severity: 'warn', summary: 'Warning', detail: "To Date can not be earlier than From Date." });
                this.setState({ topProgressBar: false, errorMsgVisible: true })
            }
        }
    }

    getDataTableInstituteId = (rowData) => {
        return rowData.emDetailsInfoDTO ? rowData.emDetailsInfoDTO.instituteId : '';
    }
    getDataTableInstituteName = (rowData) => {
        return rowData.emDetailsInfoDTO ? rowData.emDetailsInfoDTO.instituteName : '';
    }

    // getDateFromTemplate = (rowData) => {
    //     return <div>{rowData.trxDate} {rowData.trxTime}</div>
    // }

    exportPdf() {

        let pdfColumns = [
            { title: "Date & Time", dataKey: "trxDate" },
            { title: "Institute ID", dataKey: "emDetailsDTOinstituteId" },
            { title: "Institute Name", dataKey: "emDetailsDTOinstituteName" },
            { title: "Message Type", dataKey: "trxType" },
            { title: "Message Quantity", dataKey: "quantity" },
        ]

        let tableColStyles = {}

        pdfDownloadPortrait_TableWithSubTitle("Message Transfer Log", 74, subTitleColumn, subTitleList, pdfColumns, this.state.dataTableValue, tableColStyles, "Message_Transfer_Log_Report.pdf");

    }

    exportExcel() {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog() { console.log('excel downloaded'); }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, totalPaidAmountBody, searchDataObj } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Message Transfer Log</div><div className="header-title-right"><a>Total Found: {dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        // pdf reletaed task
        let formatedStartDate = '';
        let formatedEndDate = '';
        if (searchDataObj.startDate && searchDataObj.endDate) {
            formatedStartDate = (searchDataObj.startDate.toLocaleDateString('en-GB'));
            formatedEndDate = (searchDataObj.endDate.toLocaleDateString('en-GB'));
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
        ];
        // pdf reletaed task end

        // excel
        let excelColumns = "";
        let excelFileName = formatedStartDate + ' to ' + formatedEndDate + "_Message_Transfer_Log.xlsx";
        let excelSheetName = "Message_Transfer_Log";
        excelColumns = [
            <Workbook.Column value="trxDate" label="Date & Time" />,
            <Workbook.Column value="emDetailsDTOinstituteId" label="Institute ID" />,
            <Workbook.Column value="emDetailsDTOinstituteName" label="Institute Name" />,
            <Workbook.Column value="trxType" label="Message Type" />,
            <Workbook.Column value="quantity" label="Message Quantity" />,
        ];
        // end excel

        return (
            <div className="p-fluid">
                {/* <div className="p-grid"> */}
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className='main-section'>

                    <div className="nw-form">
                        <div className="p-col-12 p-lg-12 p-xl-12">

                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            name='requestStartDate'
                                            dateFormat='dd/mm/yy'
                                            value={this.state.searchDataObj.startDate}
                                            onChange={this.startDateHandler}
                                            showIcon={true}
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                            autoWidth={false}
                                            yearRange="2010:2030"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            showButtonBar={true}
                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            name='requestEndDate'
                                            dateFormat='dd/mm/yy'
                                            value={this.state.searchDataObj.endDate}
                                            onChange={this.endDateHandler}
                                            showIcon={true}
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                            autoWidth={false}
                                            yearRange="2010:2030"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            showButtonBar={true}

                                        />
                                    </div>
                                    <span className="error-message">{this.state.errors["endDate"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                    <Button
                                        label="Search"
                                        className="p-button-primary nw-button nw-button-center"
                                        icon="fas fa-search"
                                        onClick={this.onDateWiseSearchHandler}
                                    />
                                </div>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className="nw-data-table">
                            <div className="p-col-12 p-lg-12 p-xl-12">

                                <DataTable
                                    header={tableHeader}
                                    responsive={true}
                                    selectionMode="single"
                                    rowsPerPageOptions={[10, 20, 30]}
                                    paginator={true}
                                    ref={(el) => { this.dt = el; }}
                                    rows={10}
                                    value={this.state.dataTableValue}
                                //selection={this.state.dataTableSelection}
                                >
                                    <Column field="trxDate" header="Date & Time" className="table-datetime" filter={true} />
                                    <Column field='emDetailsInfoDTO.instituteId' header="Institute ID" filter={true} />
                                    <Column field='emDetailsInfoDTO.instituteName' header="Institute Name" filter={true} />
                                    <Column field="trxType" header="Message Type" filter={true} />
                                    <Column field="quantity" header="Message Quantity" filter={true} />

                                </DataTable>

                                {/* :
                                        
                                        <BlankDataTableAnim/> 
                                } */}
                            </div>
                            <div className="p-col-12 p-xl-12 nw-button-parent">

                                <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>

                                <div style={{ display: 'none' }}>

                                    <Workbook
                                        filename={excelFileName}
                                        element={
                                            <Button
                                                label="Download Excel"
                                                id="clickExcelBtn"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                            />
                                        }
                                    >
                                        <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                            {excelColumns}
                                        </Workbook.Sheet>
                                    </Workbook>

                                </div>

                            </div >

                        </div>

                    </div>

                </div>
            </div>

            // </div>
        )
    }
}