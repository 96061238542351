import React, { Component } from 'react';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import CommonFuctionality from '../../common/CommonFuctionality';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { Messages } from 'primereact/messages';

import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';
import { Calendar } from 'primereact/calendar';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { NetiFileHandler } from '../../../utils//NetiFileHandler';

let dialogType = '';
let dialogHeader = '';
let dropDownListName1 = '';
let cmsId = '';
let updateType = '';

export class AdminNoticeInfo extends Component {

    constructor(props) {

        super(props);

        this.state = {
            homeReturnButton: false,
            btnDisabled: false,
            dataTableIsLoading: false,
            dataTableIsLoading2: false,
            dataTableValue: [],
            disableDataTableValue: [],
            dialogVisible: false,
            viewDialogVisible: false,
            selectedViewRowData: '',
            classDropDownList: [],
            groupDropDownList: [],
            noticeInfoObj: {
                "cmsId": 0,
                fileContent: "",
                "fileName": "",
                "fileSaveOrEditable": false,
                noticeDetails: "",
                noticeExpiryDate: "",
                noticeIssueDate: "",
                noticeSerial: 0,
                "noticeTitle": "",
                "userName": "",
                "noticeId": '',
                "noticeStatus": 1,
            },
            uploadFile: {
                fileName: '',
                fileContent: '',
                fileSaveOrEditable: false
            },
            imageInputFieldIsLoading: false,

            errors: {},

        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.netiFileHandler = new NetiFileHandler();

        this.getEnableTableData = this.getEnableTableData.bind(this);
        this.reloadCoreUrlIdFromLocal();

    }

    async reloadCoreUrlIdFromLocal() {
        let urlId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("urlId .>", urlId);

        if (typeof urlId === 'string' || typeof urlId === 'number') {

            cmsId = urlId;
            this.setState({ homeReturnButton: false });

            this.getEnableTableData();
            this.getDisableTableData();
        }
        else {
            this.setState({ homeReturnButton: true })
        }
    }

    getEnableTableData() {

        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.DwsService.getCmsEnableNoticeInfo(cmsId)
            .then((res) => {
                // console.log('res-list', res);

                if (res.status == 200) {
                    return res.json().then((body) => {
                        if (body.messageType == 1) {
                            this.setState({ dataTableValue: body.item });
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });

                        } else {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })
                        }

                    });
                }
            }).catch((error) => {
                // console.log('enable-tbl-err');
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    getDisableTableData() {

        this.setState({ dataTableIsLoading2: true, topProgressBar: true, errorMsgVisible: false });

        this.DwsService.getCmsDisableNoticeInfo(cmsId)
            .then((res) => {
                // console.log('res-list', res);
                if (res.status == 200) {
                    return res.json().then((body) => {
                        if (body.messageType == 1) {
                            this.setState({ disableDataTableValue: body.item });
                            this.setState({ dataTableIsLoading2: false, topProgressBar: false, errorMsgVisible: false });
                        } else {
                            this.setState({ dataTableIsLoading2: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: body.message })

                        }
                    });
                }
            }).catch((error) => {
                // console.log('disable-tbl-err');
                this.setState({ dataTableIsLoading2: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' });
            });
    }

    onChangeToDialogVisible = (source) => {
        if (source == "insert") { dialogHeader = "Add Notice Info"; dialogType = source; }
        else if (source == "enable") { dialogHeader = "Update Enable Notice Info"; dialogType = 'update'; }
        else if (source == "disable") { dialogHeader = "Update Expired / Disable Notice Info"; dialogType = 'update'; }
        this.setState({ dialogVisible: true });
    };

    onHideDialog = () => {

        this.setState({ dialogVisible: false });
        this.resetDialogValue();
        this.setState({ errors: {} })
        // this.clearErrorMsg('noticeSerial');
        this.setState({ btnDisabled: false });

    }

    viewDialog = (rowData, source) => {
        this.setState({ btnDisabled: false })

        this.setState({ viewDialogVisible: true, selectedViewRowData: rowData });
    }

    onHideViewDialog = () => { this.setState({ viewDialogVisible: false, selectedViewRowData: '' }); }

    resetDialogValue = () => {

        const noticeInfoObj = {
            "cmsId": Number(cmsId),
            "fileContent": "",
            "fileName": "",
            "fileSaveOrEditable": false,
            "noticeDetails": "",
            "noticeExpiryDate": "",
            "noticeIssueDate": "",
            "noticeSerial": '',
            "noticeTitle": "",
            "userName": "",
            "noticeId": '',
            "noticeStatus": '',
        }

        const uploadFile = {
            fileName: '',
            fileContent: '',
            fileSaveOrEditable: false
        }

        this.setState({ noticeInfoObj: noticeInfoObj, uploadFile: uploadFile });

    }

    setRowDataToUpdateDialog = (rowData, source) => {
        this.DwsService.getCmsNoticeFileContent(rowData.noticeId)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log('fileContent Fetch', body);
                        this.setState({
                            uploadFile: {
                                fileContent: body.file,
                                fileName: rowData.noticeFileName,
                            }
                        });
                    });
                }
            }).catch((error) => {
            });

        // console.log('rowData', rowData, this.state.uploadFile);
        let noticeInfoObj = {
            "cmsId": Number(cmsId),
            "fileContent": '',
            "fileName": rowData.noticeFileName,
            "fileSaveOrEditable": false,
            "noticeDetails": rowData.noticeDetails,
            "noticeExpiryDate": NetiDateUtils.getDateFromString(rowData.noticeExpiryDate) + ' ' + NetiDateUtils.getHHcloneMMcloneSS(rowData.noticeExpiryDate),
            "noticeIssueDate": NetiDateUtils.getDateFromString(rowData.noticeIssueDate) + ' ' + NetiDateUtils.getHHcloneMMcloneSS(rowData.noticeIssueDate),
            "noticeSerial": rowData.noticeSerial,
            "noticeTitle": rowData.noticeTitle,
            "userName": "",
            "noticeId": rowData.noticeId,
            "noticeStatus": rowData.noticeStatus
        }

        updateType = source;
        this.setState({ noticeInfoObj: noticeInfoObj });
        this.onChangeToDialogVisible(source)
    }

    getSlipFile = (noticeID, fileName) => {
        this.DwsService.getCmsNoticeFileContent(noticeID)
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        let contentType = this.netiFileHandler.getImageContentType(fileName);

                        let a = document.createElement("a");
                        a.href = contentType + body.file;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);

                    });
                }
            }).catch((error) => {
            });

    }

    onChageInputValue = (e, value) => {

        let { noticeInfoObj } = this.state;
        noticeInfoObj[e.target.name] = value;

        this.setState({ noticeInfoObj })
        this.clearErrorMsg(e.target.name);
    }

    onChageDate = (e) => {

        let { noticeInfoObj } = this.state;
        noticeInfoObj[e.target.name] = e.target.value;

        this.setState({ noticeInfoObj })
        this.clearErrorMsg(e.target.name);
    }

    onChangeDescription = (e) => {
        let { noticeInfoObj, errors } = this.state;
        errors["noticeDetails"] = "";

        noticeInfoObj["noticeDetails"] = e.htmlValue;

        this.setState({ noticeInfoObj, errors })
        // this.onClearErrorMsg("speechDetails");
    }

    removeUploadFile = () => {

        let { uploadFile, noticeInfoObj } = this.state;

        uploadFile.fileContent = ''
        uploadFile.fileName = ''
        // uploadFile.fileSaveOrEditable = false

        // noticeInfoObj.fileContent = ''
        // noticeInfoObj.fileName = ''
        // uploadFile.fileSaveOrEditable = false

        this.setState({ uploadFile, noticeInfoObj });
        Object.assign(noticeInfoObj, uploadFile);
    }

    onLoadPic = (e, maxFileSize) => {

        let { errors } = this.state;

        let fileType = this.netiFileHandler.getFileContentType(e.files[0].name);
        let supportedExtention = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/doc', 'application/docx'];
        // console.log('loadPic', e.files[0]);

        if (supportedExtention.includes(fileType)) {

            if (this.netiFileHandler.getMaxFileSizeIsValid(e.files[0].size, maxFileSize)) {

                errors["noticeImgContent"] = "";
                this.setState({ errors });

                var reader = new FileReader();
                let photo = e.files[0];
                const scope = this;
                reader.readAsDataURL(photo);
                reader.onload = () => {
                    let content = reader.result;
                    var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
                    var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
                    let album = {
                        // extention: photo.type,
                        fileContent: urlStr,
                        fileName: photo.name,
                        fileSaveOrEditable: true
                    };
                    scope.setState({ uploadFile: album });

                    Object.assign(this.state.noticeInfoObj, album);
                }

            }
            // else {
            //     errors["noticeImgContent"] = "File size can't be more than " + maxFileSize / 1048576 + ' MB';
            //     this.setState({ errors });
            // }

        }
        else {
            errors["noticeImgContent"] = "File format not supported";
            this.setState({ errors });
        }

    }

    onSubmitHandler = (dialogType) => {

        let { noticeInfoObj } = this.state;
        noticeInfoObj.fileContent = this.state.uploadFile.fileContent;

        // console.log('save-noticeInfoObj', noticeInfoObj);

        if (this.errorHandler()) {

            this.setState({ btnDisabled: true })
            let userName = localStorage.getItem('user_name');
            noticeInfoObj.userName = userName;
            noticeInfoObj.cmsId = Number(cmsId);
            noticeInfoObj.noticeExpiryDate = NetiDateUtils.getDateFromString(noticeInfoObj.noticeExpiryDate) + ' ' + NetiDateUtils.getHHcloneMMcloneSS(noticeInfoObj.noticeExpiryDate);
            noticeInfoObj.noticeIssueDate = NetiDateUtils.getDateFromString(noticeInfoObj.noticeIssueDate) + ' ' + NetiDateUtils.getHHcloneMMcloneSS(noticeInfoObj.noticeIssueDate);

            if (dialogType == 'insert') {

                let { errors } = this.state;
                noticeInfoObj.noticeSerial = this.state.dataTableValue.length + 1;
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                this.DwsService.cmsSaveNoticeInfo(noticeInfoObj)
                    .then(res => {
                        // console.log('save-res', res);

                        if (res.status == 201) {
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                            this.onHideDialog();
                            this.setState({ btnDisabled: false })
                            this.getEnableTableData();
                            this.getDisableTableData();
                            this.removeUploadFile();
                            this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Notice information successfully saved' });
                        } else {
                            this.DwsService.Auth.handleErrorStatus(res)
                                .then((res) => {
                                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: res })
                                    this.setState({ btnDisabled: false })

                                });
                        }
                    })
                    .catch(error => {
                        this.setState({ errors: errors })
                        this.setState({ btnDisabled: false })

                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });

            } else if (dialogType == 'update') {

                let { errors } = this.state;
                this.setState({ topProgressBar: true, errorMsgVisible: false });
                // noticeInfoObj.fileContent = this.state.uploadFile.fileContent;
                // console.log('update obj', noticeInfoObj);

                this.DwsService.cmsUpdateNoticeInfo(noticeInfoObj)
                    .then(res => {
                        // console.log('update-res', res);

                        if (res.status == 202) {
                            this.setState({ topProgressBar: false, errorMsgVisible: false });
                            this.onHideDialog();
                            this.getEnableTableData();
                            this.getDisableTableData();
                            this.removeUploadFile();
                            this.setState({ btnDisabled: false })
                            this.growl.show({ life: 5000, severity: 'success', summary: 'Success', detail: 'Notice information Successfully Updated' });
                        }

                    })
                    .catch(error => {
                        this.setState({ errors: errors })
                        this.setState({ btnDisabled: false })
                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                    });
            }
        }

    }

    clearErrorMsg = (errorIndex) => {
        let { errors } = this.state;
        errors[errorIndex] = ''
        this.setState({ errors })
    }

    enableActionTemplate = (rowData, column) => {
        return <div>
            <Button
                className="nw-action-button info"
                icon="fas fa-eye"
                tooltip="View Notice Info"
                onClick={(e) => this.viewDialog(rowData, 'enable')}
            />
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                tooltip="Update"
                onClick={(e) => this.setRowDataToUpdateDialog(rowData, 'enable')}
            >
            </Button>
        </div>;
    }

    disableActionTemplate = (rowData, column) => {
        return <div>
            <Button
                className="nw-action-button info"
                icon="fas fa-eye"
                tooltip="View Notice Info"
                onClick={(e) => this.viewDialog(rowData, 'disable')}
            />
            <Button
                type="button"
                icon="fas fa-pencil-alt"
                className="nw-action-button"
                onClick={(e) => this.setRowDataToUpdateDialog(rowData, 'disable')}
            >
            </Button>
        </div>;
    }

    noticeExpiryDateBody = (rowData) => {
        return <div> {NetiDateUtils.getDateFromString(rowData.noticeExpiryDate) + ' ' + NetiDateUtils.getHHcloneMMcloneSS(rowData.noticeExpiryDate)} </div>;
    }

    remainExpirationBody = (rowData) => {
        return <div>
            {rowData.remainExpireation == 0 ? 'Today' : rowData.remainExpireation == 1 ? rowData.remainExpireation + ' ' + 'Day' : rowData.remainExpireation > 1 ? rowData.remainExpireation + ' ' + 'Days' : rowData.remainExpireation}
        </div>;
    }

    errorHandler = () => {

        let { noticeInfoObj, errors } = this.state;
        let formIsValid = true;

        if (dialogType === 'update' && noticeInfoObj.noticeStatus === 1) {

            if (noticeInfoObj.noticeSerial === '' || noticeInfoObj.noticeSerial === null) {
                formIsValid = false;
                errors["noticeSerial"] = "Serial No. can't left empty.";
            }
        }

        if (noticeInfoObj.noticeTitle === '') {
            formIsValid = false;
            errors["noticeTitle"] = "Notice title can't left empty";
        }
        if (noticeInfoObj.noticeIssueDate === '') {
            formIsValid = false;
            errors["noticeIssueDate"] = "Issue date can't left empty";
        }
        if (noticeInfoObj.noticeExpiryDate === '') {
            formIsValid = false;
            errors["noticeExpiryDate"] = "Expiry date can't left empty.";
        }

        if (noticeInfoObj.noticeIssueDate > noticeInfoObj.noticeExpiryDate) {
            formIsValid = false;
            errors["noticeExpiryDate"] = "Expiry date can't less than Issue date";
        }

        if (noticeInfoObj.noticeDetails === '' || noticeInfoObj.noticeDetails === null) {
            formIsValid = false;
            errors["noticeDetails"] = "Notice details can't left empty.";
        }

        // if (!this.state.uploadFile.fileContent || !noticeInfoObj.fileContent) {
        //     formIsValid = false;
        //     errors["noticeImgContent"] = "Upload file can't left empty.";
        // }
        this.setState({ errors });
        return formIsValid;
    }

    render() {

        let { noticeInfoObj, dataTableValue, disableDataTableValue, selectedViewRowData, errors, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let uploadFileLabel = <div>Click / Drag File Here To Upload <br /><span className="upload-file-label">(Max 2MB (recommended))</span></div>

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Enable Notice List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue && dataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
                <Button
                    className="nw-button nw-button-center p-button-success"
                    name="district"
                    label="Add New"
                    icon="fas fa-plus"
                    iconPos="right"
                    onClick={() => this.onChangeToDialogVisible('insert')}
                />
            </div>
        </div>;

        let tableHeader2 = <div className="header-title">
            <div className="header-title-left">Expired / Disable Notice List</div>
            <div className="header-title-right">
                <a>Total Found: {disableDataTableValue && disableDataTableValue.length.toLocaleString("EN-IN") || '0'}</a>
            </div>
        </div>;

        let noticeStatusOptions = [
            { label: 'Enable', value: 1 },
            { label: 'Disable', value: 0 },
        ];

        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress />

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">
                    {this.state.homeReturnButton === false ?

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                >
                                    <Column field="noticeSerial" header="Serial No." filter={true} />
                                    <Column field="noticeTitle" header="Title" filter={true} />
                                    <Column field="noticeExpiryDate" header="Expiry Date" body={this.noticeExpiryDateBody} filter={true} />
                                    <Column field="remainExpireation" header="Remain Expiration" body={this.remainExpirationBody} filter={true} />
                                    <Column field="action" header="Action" body={this.enableActionTemplate} />
                                </DataTable>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                            <div style={{ marginTop: '36px' }}>

                                {!this.state.dataTableIsLoading2 ?
                                    <DataTable
                                        value={disableDataTableValue}
                                        selectionMode="single"
                                        header={tableHeader2}
                                        responsive={true}
                                        rows={10}
                                        paginator={true}
                                    >
                                        <Column field="noticeSerial" header="Serial No." filter={true} />
                                        <Column field="noticeTitle" header="Title" filter={true} />
                                        <Column field="noticeExpiryDate" header="Expiry Date" body={this.noticeExpiryDateBody} filter={true} />
                                        <Column field="noticeStatus" header="Enable Status" filter={true} />
                                        <Column field="expireStatus" header="Expiry Status" filter={true} />
                                        <Column field="action" header="Action" body={this.disableActionTemplate} />
                                    </DataTable>
                                    : this.NetiContentLoader.MyPointTableLoader()
                                }
                            </div>
                        </div>

                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    <Dialog
                        className="nw-dialog"
                        header={dialogHeader}
                        visible={this.state.dialogVisible}
                        onHide={this.onHideDialog}
                        closable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>

                                                {dialogType == "update" && noticeInfoObj.noticeStatus === 1 ?
                                                    <InputText
                                                        placeholder="Enter Serial no."
                                                        name="noticeSerial"
                                                        value={noticeInfoObj.noticeSerial}
                                                        onChange={e => this.onChageInputValue(e, e.target.value)}
                                                    />
                                                    :
                                                    <div className="nw-inputgroup-desc">
                                                        {noticeInfoObj.noticeSerial || this.state.dataTableValue.length + 1}
                                                    </div>

                                                }
                                            </div>
                                            {dialogType == "update" && noticeInfoObj.noticeStatus === 1 ? <span className="error-message">{errors["noticeSerial"]}</span> : ''}

                                        </div>

                                        {/* <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Serial No.</span>
                                                <div className="nw-inputgroup">
                                                    <InputText
                                                        placeholder="Enter Serial No."
                                                        keyfilter="pint"
                                                        name="noticeSerial"
                                                        value={noticeInfoObj.noticeSerial}
                                                        onChange={e => this.onChageInputValue(e, e.target.value, dialogType)}
                                                    />
                                                </div>
                                                <span className="error-message">{errors["noticeSerial"]}</span>
                                            </div>
                                        </div> */}

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Title<span>*</span></span>
                                                <InputText
                                                    placeholder="Enter Title"
                                                    name="noticeTitle"
                                                    value={noticeInfoObj.noticeTitle}
                                                    onChange={e => this.onChageInputValue(e, e.target.value, dialogType)}
                                                />
                                            </div>
                                            <span className="error-message">{errors["noticeTitle"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Issue Date<span>*</span></span>
                                                <Calendar
                                                    showIcon={true}
                                                    placeholder="Select Date"
                                                    yearRange="2010:2030"
                                                    // dateFormat="yyyy-mm-dd hhmmss"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    // dateOnly="true"
                                                    showButtonBar={true}
                                                    name="noticeIssueDate"
                                                    // showTime={true}
                                                    // hourFormat="12"
                                                    // stepHour={2}
                                                    value={noticeInfoObj.noticeIssueDate}
                                                    onChange={e => this.onChageDate(e)}
                                                />
                                            </div>
                                            <span className="error-message">{errors["noticeIssueDate"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Expiry Date<span>*</span></span>
                                                <Calendar
                                                    showIcon={true}
                                                    placeholder="Select Date"
                                                    yearRange="2010:2030"
                                                    // dateFormat="dd/mm/yy"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    // dateOnly="true"
                                                    showButtonBar={true}
                                                    name="noticeExpiryDate"
                                                    // showTime={true}
                                                    // hourFormat="12"
                                                    // stepHour={2}
                                                    value={noticeInfoObj.noticeExpiryDate}
                                                    onChange={e => this.onChageDate(e)}
                                                />
                                            </div>
                                            <span className="error-message">{errors["noticeExpiryDate"]}</span>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtexteditor-label">Details <span>*</span></span>
                                                <Editor
                                                    className="nw-inputtexteditor"
                                                    name="noticeDetails"
                                                    value={noticeInfoObj.noticeDetails}
                                                    onTextChange={(e) => this.onChangeDescription(e, dialogType)}
                                                />
                                            </div>
                                            <span className="error-message">{errors["noticeDetails"]}</span>
                                        </div>

                                        {/* <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Upload File <span>*</span>
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/DOC/PDF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    {
                                                        this.state.imageInputFieldIsLoading ?
                                                            <div className="p-col-12">{this.NetiContentLoader.updateFileLoader()} </div> :
                                                            this.state.uploadFile.fileContent ?
                                                                <div className="image-view-main">
                                                                    <div className="upload-image-view">
                                                                        <Button
                                                                            className="delete-upload-button"
                                                                            icon="fas fa-times-circle"
                                                                            onClick={this.removeUploadFile}
                                                                        />

                                                                        {
                                                                            this.state.uploadFile.fileName ?
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadFile.fileName)
                                                                                :
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.updateUserObj.fileName)
                                                                        }
                                                                      
                                                                    </div>
                                                                    <div className="image-title">{this.state.uploadFile.fileName}</div>
                                                                </div>

                                                                :
                                                                <FileUpload
                                                                    chooseLabel={this.state.uploadFile.fileName || uploadFileLabel}
                                                                    accept="image/*, .pdf, .doc,.docx"
                                                                    id="fileUpload"
                                                                    mode="basic"
                                                                    name="uploadFile"
                                                                    onSelect={e => this.onLoadPic(e, 2097152)}
                                                                    auto={true}
                                                                />
                                                    }

                                                </div>
                                            </div>
                                            <span className='error-message'>{this.state.uploadFile.fileName ? null : this.state.errors.noticeImgContent}</span>
                                        </div> */}

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup nw-upload-button">
                                                <span className="p-inputgroup-addon nw-inputtextarea-label">
                                                    Upload File 
                                                    {/* <span>*</span> */}
                                                    <br />
                                                    <span>(PNG/JPG/JPEG/DOC/PDF)</span>
                                                </span>

                                                <div className="nw-upload-button-inside">

                                                    {
                                                        this.state.imageInputFieldIsLoading ?
                                                            <div className="p-col-12">{this.NetiContentLoader.updateFileLoader()} </div> :
                                                            this.state.uploadFile.fileContent ?
                                                                <div className="image-view-main">
                                                                    <div className="upload-image-view">
                                                                        <Button
                                                                            className="delete-upload-button"
                                                                            icon="fas fa-times-circle"
                                                                            onClick={this.removeUploadFile}
                                                                        />

                                                                        {
                                                                            this.state.uploadFile.fileName ?
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.uploadFile.fileName)
                                                                                :
                                                                                this.CommonFuctionality.getFileContentTypeIcon(this.state.updateUserObj.fileName)
                                                                        }

                                                                    </div>
                                                                    <div className="image-title">{this.state.uploadFile.fileName}</div>
                                                                </div>

                                                                :
                                                                <FileUpload
                                                                    chooseLabel={this.state.uploadFile.fileName || uploadFileLabel}
                                                                    accept="image/*, .pdf, .doc,.docx"
                                                                    id="fileUpload"
                                                                    mode="advanced"
                                                                    maxFileSize="2000000"
                                                                    name="uploadFile"
                                                                    onSelect={e => this.onLoadPic(e, 2000000)}
                                                                    auto={true}
                                                                />
                                                    }

                                                </div>
                                            </div>
                                            <span className='error-message'><Messages ref={(el) => this.messages = el}></Messages></span>
                                            <span className='error-message'>{this.state.uploadFile.fileName ? null : this.state.errors.noticeImgContent}</span>
                                        </div>

                                        {dialogType == 'update' ?
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Enable Status<span>*</span></span>
                                                    <Dropdown
                                                        name="noticeStatus"
                                                        options={noticeStatusOptions}
                                                        value={noticeInfoObj.noticeStatus}
                                                        onChange={e => this.onChageInputValue(e, e.value, dialogType)}
                                                        autoWidth={false}
                                                    />
                                                </div>
                                                <span className="error-message">{errors["noticeStatus"]}</span>
                                            </div>
                                            : ''}
                                        <div className="p-col-12 p-xl-12 nw-button-parent">

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                    </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-left"
                                                label={dialogType == "insert" ? "Save" : "Update"}
                                                icon="fas fa-check"
                                                onClick={() => this.onSubmitHandler(dialogType)}
                                                disabled={this.state.btnDisabled}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog
                        className="nw-dialog"
                        header='View Notice Info'
                        visible={this.state.viewDialogVisible}
                        onHide={this.onHideViewDialog}
                        closable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">Serial No.</span>
                                                <div className="nw-inputgroup-desc"> {selectedViewRowData.noticeSerial} </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">Title</span>
                                                <div className="nw-inputgroup-desc">{selectedViewRowData.noticeTitle} </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">Issue Date</span>
                                                <div className="nw-inputgroup-desc">{NetiDateUtils.getDateFromString(selectedViewRowData.noticeIssueDate) + ' ' + NetiDateUtils.getHHcloneMMcloneSS(selectedViewRowData.noticeIssueDate)} </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">Expiry Date</span>
                                                <div className="nw-inputgroup-desc"> {NetiDateUtils.getDateFromString(selectedViewRowData.noticeExpiryDate) + ' ' + NetiDateUtils.getHHcloneMMcloneSS(selectedViewRowData.noticeExpiryDate)}</div>
                                            </div>
                                        </div>

                                        {/* <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">Details</span>
                                                <div className="nw-inputgroup-desc">{selectedViewRowData.noticeDetails} </div>
                                            </div>
                                        </div> */}

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputtexteditor-label">Details</span>
                                                <Editor
                                                    value={selectedViewRowData.noticeDetails}
                                                    className="nw-inputtexteditor"
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">Upload File</span>
                                                {/* <div className="nw-inputgroup-desc">{selectedViewRowData.noticeFileName} </div> */}
                                                <div className="nw-inputgroup-desc"> <span className="">
                                                    <a style={{ cursor: 'pointer' }}
                                                        onClick={e => this.getSlipFile(selectedViewRowData.noticeId, selectedViewRowData.noticeFileName)}
                                                    >
                                                        Click for download file
                                                            </a>
                                                </span> </div>

                                            </div>
                                        </div>

                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon nw-inputgroup-label">Enable Status</span>
                                                <div className="nw-inputgroup-desc">{selectedViewRowData.noticeStatus == 1 ? 'Enable' : 'Disable'} </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                </div>

            </div>
        )
    }
}