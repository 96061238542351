import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from 'primereact/growl';
import { SupportPointService } from "../../service/supportPoint/SupportPointService";
import NetiContentLoader from '../../../src/components/common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';


let errors = {};
export class CreateBank extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bankName: "",
      defaultId: "",
      note: "",
      bankNameUpdate: "",
      noteUpdate: "",
      dataTableValue: [],
      selectedRowData: {},
      selectedRowDataUpdate: {
        bankIdUpdate: '',
        bankNameUpdate: '',
        defaultIdUpdate: '',
        noteUpdate: ''
      },
      errors: {},
      dataTableIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: '',
    };

    this.fetchBankList = this.fetchBankList.bind(this);
    this.submitSave = this.submitSave.bind(this);
    this.actionBody = this.actionBody.bind(this);
    this.viewDialog = this.viewDialog.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onChangeBankNameUpdate = this.onChangeBankNameUpdate.bind(this);
    this.onChangeNoteUpdate = this.onChangeNoteUpdate.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);

    this.supportPointService = new SupportPointService();
    this.NetiContentLoader = new NetiContentLoader();
  }

	componentWillMount() {

    this.fetchBankList(); 

  };

  onChangebankName = e => {
    errors["bankName"] = "";
    this.setState({ bankName: e.target.value, errors: errors })
  };

  onChangedefaultId = e => {
    errors["defaultId"] = "";
    this.setState({ defaultId: e.target.value, errors: errors })
  };

  onChangenoteValue = e => {
    errors["note"] = "";
    this.setState({ note: e.target.value, errors: errors })
  };



  actionBody(rowData, ) {
    return (
      <div className="text-center">
        <Button 
            className="nw-action-button edit" 
            icon="fas fa-pencil-alt" 
            title="Update" 
            onClick={e => this.viewDialog(rowData)} 
          />
      </div>
    );
  }

  onChangeBankNameUpdate = (e) => {
    let {selectedRowDataUpdate} = this.state
    selectedRowDataUpdate.bankNameUpdate = e.target.value
    this.setState({ selectedRowDataUpdate });
    
  }

  onChangeNoteUpdate = (e) => {
    let {selectedRowDataUpdate} = this.state
    selectedRowDataUpdate.noteUpdate = e.target.value
    this.setState({ selectedRowDataUpdate });
  }

  fetchBankList() {
    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });

    this.supportPointService.fetchAllBankList()
      .then(res => {
        if (res.status === 200) {
          return res.json().then((body) => {
            console.log("BODY", body);
            this.setState({ dataTableValue: body });
            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
          })

        } else {
          return res.json().then((errorBody) => {
            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: errorBody.message });
          })
        };
      }).catch(error => {
        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load. Please check your connection.' });
      });
  }




  submitSave() {
   
    if (this.handleError()) {
      this.setState({ topProgressBar: true, errorMsgVisible: false});


      this.supportPointService.createBankOfEmGlobalInfo(this.state.bankName, this.state.defaultId, this.state.note)
     
        .then(res => {

          if (res.status === 200) {

              res.json().then(body => {
                if(body.status == 409){
                  this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Duplicate Entry!' });
                } 
              }).catch(e => {
                  this.growl.show({ severity: 'success', summary: 'Success', detail: 'Bank created successfully.' });
                  this.fetchBankList(); 
                  this.setState({ topProgressBar: false, errorMsgVisible: false })
                  this.setState({bankName:'', defaultId:'', note:''})
            })
                
          }  else {
            this.supportPointService.Auth.handleErrorStatus(res)
              .then((resp) => {
                this.setState({ topProgressBar: false,  errorMsgVisible: true, errorMsgBody: resp })
              });
          }

      
        }).catch(error => {
          this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection.' });
        });
    }
  }




  handleError() {
    let formIsValid = true;

    if (this.state.bankName === '') {
      formIsValid = false;
      errors["bankName"] = "Bank Name can't left empty.";
    }
    if (this.state.defaultId === '') {
      formIsValid = false;
      errors["defaultId"] = "Default ID can't left empty.";
    }
    if (this.state.note === '') {
      formIsValid = false;
      errors["note"] = "Note can't left empty.";
    }
    else {
      return formIsValid;
    }
    this.setState({ errors: errors });
  }


  viewDialog(rowData) {
    let {selectedRowDataUpdate} = this.state;
    selectedRowDataUpdate.bankNameUpdate = rowData.bankName;
    selectedRowDataUpdate.bankIdUpdate = rowData.bankId;
    selectedRowDataUpdate.defaultIdUpdate = rowData.defaultId;
    selectedRowDataUpdate.noteUpdate = rowData.note;
    this.setState({ selectedRowDataUpdate  });
    
    this.setState({ visible: true });
  }

  onHide() { this.setState({ visible: false }) };

  submitUpdate() {
    if (this.handleErrorUpdate()) {
      this.setState({ topProgressBar: true, errorMsgVisible: false, dataTableIsLoading: true })
      console.log('this.state.selectedRowDataUpdate', this.state.selectedRowDataUpdate);
      
    this.supportPointService.updateBankOfEmGlobalInfo(this.state.selectedRowDataUpdate)
      .then(res => {
        if (res.status === 200) {
          this.growl.show({ severity: 'success', summary: 'Success', detail: 'Bank update successful.' });
          this.setState({ visible: false });
          this.fetchBankList();
          this.setState({ topProgressBar: false, errorMsgVisible: false, dataTableIsLoading: false })
        } else {
          return res.json().then((errorBody) => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: errorBody })
          })
        };
      }).catch(error => {
        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection.' })
      })
    }
    
  }

  handleErrorUpdate = () => {
    let formIsValid = true;
    let { selectedRowDataUpdate } = this.state;
    if (selectedRowDataUpdate.bankNameUpdate === '') {
      formIsValid = false;
      errors["bankNameUpdate"] = "Bank Name can't left empty.";
    }
    if (selectedRowDataUpdate.noteUpdate === '') {
      formIsValid = false;
      errors["noteUpdate"] = "Note can't left empty.";
    }
    else {
      return formIsValid;
    }
    this.setState({ errors: errors });
  }

  render() {
    let { topProgressBar, errorMsgBody, errorMsgVisible } = this.state;
    let headervalue = (
      <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
        Created Bank List <span style={{ float: "right" }}>Total Found: {this.state.dataTableValue.length}</span>
      </div>
    );

    let { selectedRowData, selectedRowDataUpdate } = this.state;

    return (

      <div className="p-fluid">
        {topProgressBar ?
					<ErrorMessageView
						topProgressBar={topProgressBar}
					/>
					: null
				}
				{errorMsgVisible ?
					<ErrorMessageView
						errorMsgVisible={errorMsgVisible}
						errorMsgBody={errorMsgBody}
					/>
					: null
				}

        <Growl ref={(el) => this.growl = el} />
        <div className="p-grid nw-form">

       
          <div className="p-col-12 p-xl-12">
            <div className="nw-form-body">
                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Bank Name <span>*</span></span>
                    <InputText
                      name="bankName"
                      placeholder="Enter Bank Name"
                      onChange={this.onChangebankName}
                      value={this.state.bankName}
                      maxlength={120}
                    />
                  </div>
                  <span className='error-message'>{this.state.errors["bankName"]}</span>
                </div>


                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Default ID <span>*</span></span>
                    <InputText
                      name="defaultId"
                      placeholder="Enter Default ID"
                      onChange={this.onChangedefaultId}
                      value={this.state.defaultId}
                      keyfilter="pint"
                      maxlength={13}
                    />
                  </div>
                  <span className='error-message'>{this.state.errors["defaultId"]}</span>
                </div>


                <div className="p-col-12 p-xl-12">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon nw-inputtextarea-label">Note <span>*</span></span>
                    <InputTextarea
                      name="note"
                      placeholder="Enter Note"
                      onChange={this.onChangenoteValue}
                      value={this.state.note}
                      maxlength={120}
                    />
                  </div>
                  <span className='error-message'>{this.state.errors["note"]}</span>
                </div>

                <div className="p-col-12 p-xl-12" />

                <div className="p-col-12 p-xl-12 nw-button-parent">

                  <div className="required-field">
                    (<span>*</span>) required fields
                  </div>

                  <Button
                    className="p-button p-button-primary nw-button nw-button-right"
                    label="Save"
                    icon='fas fa-check'
                    onClick={this.submitSave}
                  />
                </div>
            </div>
          </div>
          <div className="p-col-3 p-lg-3 p-md-3" />

          <div className="p-col-12 p-xl-12">
            <div className="nw-data-table nw-data-table-tabview">

              {this.state.dataTableIsLoading ?
                this.NetiContentLoader.MyPointTableLoader() :

                <DataTable header={headervalue}
                  value={this.state.dataTableValue}
                  paginator={true} rows={10}
                  rowsPerPageOptions={[10, 20, 50]} responsive={true}
                >
                  <Column field="bankName" header="Bank" sortable={true} filter={true} />
                  <Column field="defaultId" header="Default ID" sortable={true} filter={true} />
                  <Column field="note" header="Note" sortable={true} filter={true} />
                  <Column header="Action" body={this.actionBody} />
                </DataTable>
              }

              <Dialog header="Bank Info Update" className="nw-dialog" visible={this.state.visible} onHide={this.onHide} maximizable>

                <div className="p-fluid">
                  <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                      <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                        
                          <div className="nw-form-body">

                              <label>Default ID : {selectedRowDataUpdate.defaultIdUpdate}</label>
                              <div>
                                <div className="formControl">
                                  <label htmlFor="resolution-username">Bank Name <span>*</span></label>
                                  <InputText
                                    type="text"
                                    name='bankNameUpdate'
                                    placeholder="Enter Bank Name"
                                    onChange={this.onChangeBankNameUpdate}
                                    value={selectedRowDataUpdate.bankNameUpdate || ''}
                                  />
                                </div>
                                <span className='error-message'>{this.state.errors["bankNameUpdate"]}</span>
                              </div>
                              <div>
                                <div className="formControl">
                                  <label htmlFor="resolution-username">Note <span>*</span></label>
                                  <InputTextarea
                                    type="text"
                                    name='noteUpdate'
                                    placeholder="Enter Note"
                                    onChange={this.onChangeNoteUpdate}
                                    value={selectedRowDataUpdate.noteUpdate || ''}
                                  />
                                </div>
                                <span className='error-message'>{this.state.errors["noteUpdate"]}</span>

                              </div>
                            

                              
                            
                              <div className="p-col-12 p-xl-12 nw-button-parent">
                                <Button 
                                className="p-button p-button-primary nw-button nw-button-right"
                                label="Update" 
                                icon='fas fa-check'
                                onClick={this.submitUpdate} />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog>

            </div>

          </div>
        </div>
      </div>
    );
  }
}
