import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { Dropdown } from "primereact/dropdown";
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputTextarea } from 'primereact/inputtextarea';
import { NetiFileHandler } from '../../utils/NetiFileHandler';
import { RadioButton } from 'primereact/radiobutton';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import NetiContentLoader from '../common/NetiContentLoader';
import { Growl } from 'primereact/growl';
import { DownloadButton } from "../../utils/DownloadButton";
import { UserOtherAccessService } from '../../service/UserOtherAccessService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import Formatter from '../../utils/Formatter';
import { InputText } from 'primereact/inputtext';
import { TeacherPortal } from '../../service/emUserPoint/TeacherPortal';

export class EdumanApproval extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partnerPicture: '',
            dataTableValue: [],
            errorMsgVisible: false,
            selectedModuleList: [],
            topProgressBar: false,
            errorMsgBody: '',
            viewPendingEdumanList: false,
            pendingOrderDetails: {},
            instituteAdminContact: '',
            instituteAdminContactReadOnly: true,
            addSearchBtnClass: '',
            searchLabel: '',
            agreementForm: '',
            approvalValue: 0,
            usingPurpose: '',
            searchDataObj: {
                // pageLimit: 10,
                pageNo: 0
            },
            first: 0,
            rows: 10,
            rejectNote: '',
            dataTableIsLoading: false,
            error: {}

        };

        this.actionDetails = this.actionDetails.bind(this);
        this.netiFileHandler = new NetiFileHandler();
        this.viewDialog = this.viewDialog.bind(this)
        this.onHide = this.onHide.bind(this)
        this.fetchPendingEmOrderList = this.fetchPendingEmOrderList.bind(this);
        this.fetchPendingEmOrderDetails = this.fetchPendingEmOrderDetails.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.crmPointService = new CRMPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.UserOtherAccessService = new UserOtherAccessService();
        this.formatter = new Formatter();
        this.getProfileImg = this.getProfileImg.bind(this);
        this.teacherPortal = new TeacherPortal();

    }



    fetchPendingEmOrderList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.crmPointService.fetchPendingEM_OrderList(this.state.searchDataObj).then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    body.map(item => {
                        item['formApplyDate'] = NetiDateUtils.getAnyShortForm(item['formApplyDate'], 'DD-MMM-YYYY hh:mm:ss a')
                        item['mobileNo'] = this.formatter.formatContactNo(item['mobileNo'])
                    })
                    this.setState({ dataTableValue: body, dataTableIsLoading: false, topProgressBar: false })
                });
            } else {
                this.crmPointService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                    });
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
        });
    }

    // fetchSelectedEdumanModuleList = () => {
    //     let { pendingOrderDetails } = this.state;
    //     this.setState({ topProgressBar: true, errorMsgVisible: false })
    //     this.UserOtherAccessService.getEdumanSelectedModuleList(pendingOrderDetails.modules).then(res => {
    //         if (res.status == 200) {
    //             console.log("selected module list res", res);
    //             return res.json().then((body) => {
    //                 if (body.item.length === 0) {
    //                     this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: "No Data Found" })
    //                 } else {
    //                     console.log("selected module list", body);
    //                     this.setState({ selectedModuleList: body.item, topProgressBar: false })
    //                 }
    //             });
    //         } else {
    //             this.UserOtherAccessService.Auth.handleErrorStatus(res)
    //                 .then((resp) => {
    //                     this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
    //                 });
    //         }
    //     }).catch((error) => {
    //         this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
    //     });
    // }

    fetchPendingEmOrderDetails(emDetailsID) {

        this.crmPointService.fetchPendingEM_OrderDetailsByEmDetailsID(emDetailsID).then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {

                    this.setState({ pendingOrderDetails: body, instituteAdminContact: body.instituteAdminContact });
                    this.getProfileImg();
                    // this.fetchSelectedEdumanModuleList();
                });
            } else {
                return res.json().then((errorBody) => console.log('ErrorBody', errorBody))
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
        });
    }

    actionDetails(rowData) {
        return <center>
            <Button
                className="nw-action-button edit"
                icon="fas fa-edit"
                tooltip="Edit"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </center>;
    }

    viewDialog(rowData) {
        this.fetchPendingEmOrderDetails(rowData.emDetailsId);
        this.setState({
            approvalValue: 0,
            usingPurpose: '',
            rejectNote: ''
        })
        this.setState({ visible: true })
    }
    onHide() { this.setState({ visible: false }) }
    onChangeRejectNote = (value) => { this.setState({ rejectNote: value, error: {} }) };

    onSubmitHandler() {
        if (this.submitErrorHandle()) {
            this.setState({ topProgressBar: true });
            let requestObject = {
                emID: this.state.pendingOrderDetails.emDetailsId,
                instituteStatus: this.state.approvalValue,
                purpose: this.state.usingPurpose,
                note: this.state.rejectNote,
                instituteAdminContact: this.state.instituteAdminContact
            }

            this.crmPointService.edumanOrderApproveSubmit(requestObject)
                .then(res => {
                    this.setState({ topProgressBar: false, errorMsgVisible: false })
                    if (res.status == 202) {
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Data update successful.' });
                        this.setState({ visible: false });
                        this.fetchPendingEmOrderList();
                    } else {
                        this.crmPointService.Auth.handleErrorStatus(res)
                            .then((responseBody) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
                });
        }
    }

    submitErrorHandle = () => {
        let formIsValid = true;
        let { error } = this.state;

        if (this.state.approvalValue === 4 && !this.state.rejectNote) {
            formIsValid = false;
            error['rejectNote'] = "Reject Note can't left empty"
        }
        if (this.state.instituteAdminContact == '') {
            formIsValid = false;
            error['instituteAdminContact'] = "Institute Admin Contact can't left empty"
        }

        this.setState({ error })
        return formIsValid
    }

    viewPendingEdumanOrderInfo = () => {
        this.fetchPendingEmOrderList();
        this.setState({ viewPendingEdumanList: true });
    }

    getProfileImg() {

        let { pendingOrderDetails } = this.state;
        let staticImg = 'assets/layout/images/avatar.png';

        if (pendingOrderDetails.partnerAssignDTO.userBasicInfoDTO.imagePath !== null) {
            this.netiFileHandler.getByteImage(pendingOrderDetails.partnerAssignDTO.userBasicInfoDTO.imagePath)
                .then((res) => {

                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(pendingOrderDetails.partnerAssignDTO.userBasicInfoDTO.imageName);
                                this.setState({ partnerPicture: contentType + body.fileContent });
                                this.setState({ cloneInstituteImg: contentType + body.fileContent });
                            })
                    } else {
                        this.setState({ partnerPicture: staticImg });
                    }
                });
        } else { this.setState({ partnerPicture: staticImg }) };

    }

    focusSearch = e => {
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white" });
    }

    blurSearch = e => {
        this.setState({ searchLabel: '', addSearchBtnClass: "" });
    }

    onChangeInstituteAdminContact = (e) => {
        this.setState({ instituteAdminContact: e.target.value });
    }

    onSearchAdminMblNo = () => {
        let { error } = this.state;
        this.setState({ topProgressBar: true, secondInputFieldIsLoading: true });
        this.teacherPortal.isStaffAsAUser(this.state.instituteAdminContact)
            .then(data => {
                if (data == true) {
                    error['instituteAdminContactError'] = <b style={{ color: 'blue' }}>You can use this contact number.</b>;
                    this.setState(error);
                    this.setState({ viewAdminMobileNoField: true });
                    this.setState({ topProgressBar: false, netiSearchViewError: false, secondInputFieldIsLoading: false, errorMsgVisible: false })
                } else {
                    error['instituteAdminContactError'] = <b>Please try with another number.</b>;
                    this.setState(error);
                    this.setState({ viewAdminMobileNoField: false });
                    this.setState({ topProgressBar: false, netiSearchViewError: false, secondInputFieldIsLoading: false, errorMsgVisible: true })
                }
            })

    }
    render() {
        let { pendingOrderDetails, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let git = [
            {
                label: 'Pending',
                value: 0,
            },
            {
                label: 'Approve',
                value: 1,
            },
            {
                label: 'Reject',
                value: 4,
            }
        ]

        let agreementStartDate = NetiDateUtils.getAnyShortForm(pendingOrderDetails.agreementStartDate, 'DD-MM-YYYY');
        let dataSubmissionDate = NetiDateUtils.getAnyShortForm(pendingOrderDetails.dataSubmissionDate, 'DD-MM-YYYY');
        let handoverDate = NetiDateUtils.getAnyShortForm(pendingOrderDetails.handoverDate, 'DD-MM-YYYY');

        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Pending Eduman Order List <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {this.state.dataTableValue.length}
            </span>
        </div>;
        //let orderDateFormate = (rowData) => { return new Date(rowData.formApplyDate).toLocaleDateString('en-GB') }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid nw-form">

                    <div className="p-col-12 p-xl-8 p-xl-offset-2">

                        <div className="p-grid p-col-12 nw-form-body">
                            <div className="p-col-12 p-md-6 p-sm-5 p-xl-6" >
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Pending Eduman Order Info</span>
                                </div>

                            </div>


                            <div className="p-col-12 p-md-6 p-sm-5 p-xl-6 nw-button-parent">
                                {/* <div className="p-inputgroup"> */}
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-left"
                                    label="View"
                                    icon="far fa-eye"
                                    onClick={this.viewPendingEdumanOrderInfo}
                                />
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    {this.state.viewPendingEdumanList ?
                        <div className="nw-data-table">

                            <DataTable
                            value={this.state.dataTableValue}
                            header={header}
                            responsive={true}
                            paginator={true}
                            rows={10}

                            >
                                <Column field="formApplyDate" className="table-datetime" header="Order Date"  filter={true} />
                                <Column field="customNetiID" header="Neti ID" filter={true} />
                                <Column field="name" header="Name" filter={true} />
                                <Column field="mobileNo" header="Mobile No." filter={true} />
                                <Column field="area" header="Area" filter={true} />
                                <Column field="institute" header="Institute" filter={true} />
                                <Column field="action" header="Action" style={{ width: "80px" }} body={this.actionDetails} />
                            </DataTable>
                        </div>
                        : ''}
                    <Dialog
                        header="Eduman Approval"
                        onHide={this.onHide}
                        className="nw-dialog"
                        visible={this.state.visible}
                        dismissableMask={true}
                        blockScroll={true}
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12 p-xl-12">

                                    <div className="p-col-12 p-xl-12">
                                        <Fieldset legend="Institute Info" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Institute Name</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.instituteName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Institute Address</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.instituteAddress}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Institute Mobile No.</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.instituteContact}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Institute Admin Contact</span>
                                                        <InputText
                                                            type="text"
                                                            value={this.state.instituteAdminContact}
                                                            placeholder="Enter Your Mobile No."
                                                            onChange={this.onChangeInstituteAdminContact}
                                                            keyfilter="pint"
                                                            name="instituteAdminContact"
                                                            maxlength={11}
                                                            readOnly={this.state.instituteAdminContactReadOnly}
                                                        />
                                                        <Button
                                                            className=""
                                                            icon="fas fa-pencil-alt"
                                                            onClick={(e) => this.setState({ instituteAdminContactReadOnly: false })}
                                                            style={{ display: this.state.instituteAdminContactReadOnly == true ? 'block' : 'none' }}
                                                        >
                                                        </Button>
                                                        <Button
                                                            className={"p-button-animation " + this.state.addSearchBtnClass}
                                                            label={this.state.searchLabel}
                                                            onClick={this.onSearchAdminMblNo}
                                                            icon="fas fa-search"
                                                            onFocus={this.focusSearch}
                                                            onBlur={this.blurSearch}
                                                            style={{ display: this.state.instituteAdminContactReadOnly == false ? 'block' : 'none' }}
                                                        >
                                                        </Button>
                                                    </div>
                                                    <span className="error-message">{this.state.error["instituteAdminContactError"]}</span>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Institute Email Address</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.instituteEmail}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Agreement Form</span>
                                                        <div className="nw-inputgroup-desc">
                                                            <DownloadButton fileName={pendingOrderDetails.formAttachmentName} filePath={pendingOrderDetails.formAttachmentPath} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Education Board</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.instituteBoardCoreCategoryInfoDTO ? pendingOrderDetails.instituteBoardCoreCategoryInfoDTO.categoryName : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Institute Type</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.instituteTypeCoreCategoryInfoDTO ? pendingOrderDetails.instituteTypeCoreCategoryInfoDTO.categoryName : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Institute Category</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.instituteCategoryCoreCategoryInfoDTO ? pendingOrderDetails.instituteCategoryCoreCategoryInfoDTO.categoryName : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Total Students</span>
                                                        <div className="nw-inputgroup-desc">
                                                           
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Total Teachers</span>
                                                        <div className="nw-inputgroup-desc">
                                                           
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </Fieldset>

                                        <Fieldset legend="Address Details" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Address</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.instituteAddress}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Upazilla/Thana</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.globalAreaCoreCategoryInfoDTO ? pendingOrderDetails.globalAreaCoreCategoryInfoDTO.categoryName : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">District</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.globalAreaCoreCategoryInfoDTO && pendingOrderDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO ? pendingOrderDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Division</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.globalAreaCoreCategoryInfoDTO && pendingOrderDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO && pendingOrderDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.parentCoreCategoryInfoDTO ? pendingOrderDetails.globalAreaCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.parentCoreCategoryInfoDTO.categoryName : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Fieldset>

                                        <Fieldset legend="Payment Info" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Package Name</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.packageCoreCategoryInfoDTO ? pendingOrderDetails.packageCoreCategoryInfoDTO.categoryName : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Service Charge</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.monthlyRate}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Payment Type</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.paymentType}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Registration Charge</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.developmentCharge}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    {/* <div className="p-col-12 p-xl-12">
                                                        <DataTable
                                                            value={this.state.selectedModuleList}
                                                            header="Modules"
                                                            responsive={true}
                                                            metaKeySelection={false}
                                                            rows={10}
                                                            columnResizeMode="fit"
                                                        >
                                                            <Column field="moduleName" header="Module Name" />
                                                            <Column field="modulePrice" header="Module Price" />
                                                        </DataTable>

                                                    </div> */}

                                                </div>
                                            </div>
                                        </Fieldset>

                                        <Fieldset legend="Admin Info" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Admin Neti ID</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.assignedUserBasicInfoDTO ? pendingOrderDetails.assignedUserBasicInfoDTO.customNetiID : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Admin Name</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.assignedUserBasicInfoDTO ? pendingOrderDetails.assignedUserBasicInfoDTO.fullName : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Mobile  No.</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.instituteAdminContact ? pendingOrderDetails.instituteAdminContact : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Fieldset>

                                        <Fieldset legend="Partner Info" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <center>
                                                        <img
                                                            src={this.state.partnerPicture}
                                                            alt="partner picture"
                                                            style={{ height: "80px" }}
                                                        />
                                                    </center>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Partner Neti ID</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.partnerAssignDTO && pendingOrderDetails.partnerAssignDTO.userBasicInfoDTO ? pendingOrderDetails.partnerAssignDTO.userBasicInfoDTO.customNetiID : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Partner Name </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.partnerAssignDTO && pendingOrderDetails.partnerAssignDTO.userBasicInfoDTO ? pendingOrderDetails.partnerAssignDTO.userBasicInfoDTO.fullName : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Mobile No. </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.partnerAssignDTO && pendingOrderDetails.partnerAssignDTO.userBasicInfoDTO ? pendingOrderDetails.partnerAssignDTO.userBasicInfoDTO.basicMobile : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Area </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.partnerAssignDTO && pendingOrderDetails.partnerAssignDTO.partnerAreaInfoDTO ? pendingOrderDetails.partnerAssignDTO.partnerAreaInfoDTO.categoryName : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Fieldset>

                                        <Fieldset legend="Partner Institute Info" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Total Institute </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.partnersTotalInstitute}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Active Institute </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.partnersActiveInstitute}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Inactive Institute </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.partnersInActiveInstitute}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Terminated Institute</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.partnersTerminatedInstitute}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Active Percentage</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {((pendingOrderDetails.partnersActiveInstitute / pendingOrderDetails.partnersTotalInstitute) * 100).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Active Payable </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.activePayable}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Active Average Rate  </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.activeAverageRate ? pendingOrderDetails.activeAverageRate.toFixed(2) : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Inactive Percentage </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {((pendingOrderDetails.partnersInActiveInstitute / pendingOrderDetails.partnersTotalInstitute) * 100).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Inactive Payable</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.inactivePayable}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Inactive Average Rate</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {pendingOrderDetails.inactiveAverageRate ? pendingOrderDetails.inactiveAverageRate.toFixed(2) : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fieldset>

                                        <Fieldset legend="Others Info" className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Agreement Date</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {agreementStartDate ? agreementStartDate : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Data Submission Date </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {dataSubmissionDate ? dataSubmissionDate : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">Handover Date</span>
                                                        <div className="nw-inputgroup-desc">
                                                            {handoverDate ? handoverDate : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Fieldset>

                                        <br />
                                        <div className="p-grid">
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Status</span>
                                                    <Dropdown
                                                        placeholder='Select Status'
                                                        options={git}
                                                        value={this.state.approvalValue}
                                                        onChange={(e) => this.setState({ approvalValue: e.value })}
                                                        filter={true}
                                                        filterBy="label,value"
                                                        autoWidth={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {
                                            this.state.approvalValue == 1 ?

                                                <div className="p-grid">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <div className="input-radio-button">
                                                                <span className="p-inputgroup-addon addon-no-style">Using Purpose</span>
                                                                <div className="radio-button-inside">
                                                                    <RadioButton
                                                                        value="Live"
                                                                        onChange={e => this.setState({ usingPurpose: e.value })}
                                                                        checked={this.state.usingPurpose === "Live"}
                                                                    />
                                                                    <label className="p-radiobutton-label">Live</label>
                                                                </div>
                                                                <div className="radio-button-inside">
                                                                    <RadioButton
                                                                        value="Practice"
                                                                        onChange={e => this.setState({ usingPurpose: e.value })}
                                                                        checked={this.state.usingPurpose === "Practice"}
                                                                    />
                                                                    <label className="p-radiobutton-label">Practice</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                        {
                                            this.state.approvalValue === 4 ?
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Reject Note</span>
                                                            <InputTextarea
                                                                id="textarea"
                                                                autoResize={true}
                                                                value={this.state.rejectNote}
                                                                onChange={e => this.onChangeRejectNote(e.target.value)}
                                                                placeholder="Enter Reject Note"
                                                            />
                                                        </div>
                                                        <span className="error-message">{this.state.error['rejectNote']}</span>
                                                    </div>
                                                </div>
                                                : ""
                                        }

                                        {this.state.approvalValue === 1 && this.state.usingPurpose !== "" ?
                                            <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                    label="Submit"
                                                    icon="fas fa-check"
                                                    onClick={this.onSubmitHandler}
                                                />
                                            </div>
                                            : ''
                                        }

                                        {this.state.approvalValue === 4 ?
                                            <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                                    label="Submit"
                                                    icon="fas fa-check"
                                                    onClick={this.onSubmitHandler}
                                                />
                                            </div>
                                            : ''
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog>
                </div>
            </div>
        )
    }
}
