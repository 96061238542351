import React, { Component } from 'react';
import { CRMPointAssign } from '../crmPoint/CRMPointAssign';
import { ObserverpointTagging } from '../crmPoint/ObserverpointTagging';
import { TabView, TabPanel } from 'primereact/tabview';

export class ObserverPointInfo extends Component {
    
    render() {
        return (
            <div className="p-fluid">
                <div className="p-grid">

                    <div className="p-col-12 p-lg-12 p-xl-12 nw-tabview">
                        <TabView >
                            <TabPanel header="Assign">
                                <CRMPointAssign />
                            </TabPanel>
                            <TabPanel header="Tagging">
                                <ObserverpointTagging />
                            </TabPanel>
                        </TabView>
                    </div>

                </div>
            </div>
        )
    }
}