import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import { ErrorMessageView } from "../../common/ErrorMessageView";
export class PartnerRatingListToTeamMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTableValue: [
        {
          year: "test",
          month: "test",
          date: "test",
          designation: "test",
          netiId: "test",
          name: "test",
          rating: "test"
        }
      ],

      coreUrlInfoDTO: {
        urlInfoID: 1
      },
      topProgressBar: false,
      errorMsgVisible: false,
      errorMsgBody: null
    };
  }

  componentWillMount() {
    //this.viewPartnerIncomeTergetInfoList();
  }

  // viewPartnerIncomeTergetInfoList = () => {
  //   this.setState({ topProgressBar: true });
  // };

  // onChangeBannerCategory = (e) => {
  //     let { updatePartnerIncomeTargetDetails } = this.state;
  //     updatePartnerIncomeTargetDetails.bannerCategory = e.target.value;
  //     this.setState({ updatePartnerIncomeTargetDetails });
  // }

  // onChangeBannerUrl = (e) => {
  //     let { updatePartnerIncomeTargetDetails } = this.state;
  //     updatePartnerIncomeTargetDetails.bannerUrl = e.target.value;
  //     this.setState({ updatePartnerIncomeTargetDetails });
  // }

  // onChangeBanneryear = (e) => {
  //     let { updatePartnerIncomeTargetDetails } = this.state;
  //     updatePartnerIncomeTargetDetails.banneryear = e.target.value;
  //     this.setState({ updatePartnerIncomeTargetDetails });
  // }

  // onChangeBannerDetails = (e) => {
  //     let { updatePartnerIncomeTargetDetails } = this.state;
  //     updatePartnerIncomeTargetDetails.bannerDetails = e.target.value;
  //     this.setState({ updatePartnerIncomeTargetDetails });
  // }

  // onImageUpload(e) {
  //     var reader = new FileReader();
  //     let photo = e.files[0];
  //     const scope = this
  //     reader.readAsDataURL(photo);
  //     reader.onload = function () {
  //         let content = reader.result;
  //         var keyw = 'data:' + photo.type + ';base64,'; //link will be same from the word webapps in URL
  //         var urlStr = content.substring(content.indexOf(keyw) + keyw.length);
  //         let album = {
  //             extention: photo.type,
  //             contentPic: urlStr,
  //             contentName: photo.name
  //         };
  //         scope.setState({ proPic: album });

  //     }
  // }

  // onChangeBannerStatus = (e) => {
  //     let { updatePartnerIncomeTargetDetails } = this.state;
  //     updatePartnerIncomeTargetDetails.bannerStatus = e.target.value;
  //     this.setState({ updatePartnerIncomeTargetDetails });
  // }

  onUpdateHandler = () => {
    // let { updatePartnerIncomeTargetDetails } = this.state;
    // if (this.state.proPic.contentPic) {
    //     updatePartnerIncomeTargetDetails.imageContent = this.state.proPic.contentPic;
    //     updatePartnerIncomeTargetDetails.imageSaveOrEditable = true;
    //     let extention = this.netiFileHandler.getImageExtention(this.state.proPic.extention);
    //     updatePartnerIncomeTargetDetails.bannerImgName = Date.now() + extention;
    // }
    // console.log("Submit object:", updatePartnerIncomeTargetDetails);
    // if (this.partnerRatingFormHandleError()) {
    //     this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    //     this.setState({ updatePartnerIncomeTargetDetails });
    //     this.dwsService.updateDwsMemberInfo(updatePartnerIncomeTargetDetails)
    //         .then(res => {
    //             console.log("response", res);
    //             if (res.status == 202) {
    //                 this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Updated", life: 800 });
    //                 this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
    //                 setTimeout(() => {
    //                     this.onHide();
    //                 }, 800);
    //                 this.viewPartnerIncomeTergetInfoList();
    //             } else {
    //                 this.dwsService.Auth.handleErrorStatus(res)
    //                     .then((resp) => {
    //                         this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
    //                     });
    //             }
    //         }).catch((error) => {
    //             this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to update data. Please check connection' });
    //         });
    // }
  };

  partnerRatingFormHandleError = () => {
    let {
      updateCalendarInfoError,
      updatePartnerIncomeTargetDetails
    } = this.state;
    let formIsValid = true;

    // if (updatePartnerIncomeTargetDetails.memberSerial === '') {
    //     formIsValid = false;
    //     updateCalendarInfoError["Category"] = "Serial Number can't left empty.";
    // }
    // if (updatePartnerIncomeTargetDetails.banneryear === '') {
    //     formIsValid = false;
    //     updateCalendarInfoError["year"] = "year can't left empty.";
    // }
    // if (updatePartnerIncomeTargetDetails.bannerUrl === '') {
    //     formIsValid = false;
    //     updateCalendarInfoError["bannerUrl"] = "URL can't left empty.";
    // }
    // if (updatePartnerIncomeTargetDetails.bannerDetails === '') {
    //     formIsValid = false;
    //     updateCalendarInfoError["details"] = "Details can't left empty.";
    // }
    // if (updatePartnerIncomeTargetDetails.bannerImgName === '') {
    //     formIsValid = false;
    //     updateCalendarInfoError["attachments"] = "Attachment is missing.";
    // }

    this.setState({ updateCalendarInfoError });
    return formIsValid;
  };

  render() {
    let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

    var tableHeader = (
      <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
        Partner Rating List
        <span style={{ float: "right" }}>
          Total Found: {this.state.dataTableValue.length}
        </span>
      </div>
    );

    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <div className="p-grid">
          <div className="nw-data-table">
            <Growl ref={el => (this.growl = el)} />
            <DataTable
              value={this.state.dataTableValue}
              selectionMode="single"
              header={tableHeader}
              selection={this.state.dataTableSelection}
              onSelectionChange={event =>
                this.setState({ dataTableSelection: event.value })
              }
              responsive={true}
              rows={10}
              columnResizeMode="fit"
              paginator={true}
              rows={5}
              rowsPerPageOptions={[5, 10, 20]}
              className="text-center"
            >
              <Column field="year" header="Year" filter={true} />
              <Column field="month" header="Month" filter={true} />
              <Column field="designation" header="Designation" filter={true} />
              <Column field="netiId" header="Neti ID" filter={true} />
              <Column field="name" header="Name" filter={true} />
              <Column field="rating" header="Rating" filter={true} />
            </DataTable>
          </div>
        </div>
      </div>
    );
  }
}
