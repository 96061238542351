import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { Growl } from "primereact/growl";
import { RadioButton } from "primereact/radiobutton";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";
import { Link } from 'react-router-dom';
import NetiContentLoader from '../../common/NetiContentLoader';

import { ProductInfoUpdate } from "./ProductInfoUpdate";
import { ProductStockInfoUpdate } from "./ProductStockInfoUpdate";
import { UserCategoryService } from '../../../service/UserCategoryService';
import { ProfileService } from '../../../service/profile/ProfileService';
import { AdminPointService } from '../../../service/adminPoint/AdminPointService';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import CommonFuctionality from '../../common/CommonFuctionality';
import { ProducListInfoDialog } from './ProducListInfoDialog';

let cloneStateBeforeMount;
let errors = {};
const hundred = 100;
export class ProductList extends Component {

    constructor() {
        super();
        this.state = {
            viewDialogShow: false,
            errors: {},
            productOwnerRevenueInfo: {},
            productTypeList: '',
            productType: '',
            productName: '',
            defaultCode: '',
            productDetails: '',
            remainingStock: '',
            generateCode: 'No',
            additionalInfo1: false,
            additionalInfo2: true,
            bijoySellNumb: '',
            bijoyMultiNumb: '',
            salesPrice: '',
            costPercent: '',
            fixedPercent: '',
            levelPercent: '',
            taxPercent: '',
            vatPercent: '',
            ownerNetiId: '',
            userDetails: '',

            dataTableValue: [],
            productOwnerDialogueFixedRevenueDataTableValue: [
                { netiID: 'testData', name: 'testData', percentage: 'testData', amount: 'testData' }
            ],
            productOwnerDialogueLevelRevenueDataTableValue: [
                { netiID: 'testData', name: 'testData', percentage: 'testData', amount: 'testData' }
            ],

            updateDialogShow: false,
            stockDialogShow: false,
            firstDropdownIsLoading: false,
            searchIsLoading: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            selectedRowData: {},
            selectedRowDataId:'',
            searchView: false,
            searchViewError: false,
            netiIDViewError: false,
            buttonDisabled: true,
            togleBtnActive: false,
        }

        this.onChangeProductType = this.onChangeProductType.bind(this);
        this.onChangeProductName = this.onChangeProductName.bind(this);
        this.onChangeDefaultCode = this.onChangeDefaultCode.bind(this);
        this.onChangeProductDetails = this.onChangeProductDetails.bind(this);
        this.onChangeRemainingStock = this.onChangeRemainingStock.bind(this);
        this.onChangeGenerateCode = this.onChangeGenerateCode.bind(this);
        this.validatorUtility = new ValidatorUtility();

        this.onChangeBijoySellNumb = this.onChangeBijoySellNumb.bind(this);
        this.onChangeBijoyMultiNumb = this.onChangeBijoyMultiNumb.bind(this);
        this.onChangeSalesPrice = this.onChangeSalesPrice.bind(this);
        this.onChangeCostPercent = this.onChangeCostPercent.bind(this);
        this.onChangeFixedPercent = this.onChangeFixedPercent.bind(this);

        this.onChangeLevelPercent = this.onChangeLevelPercent.bind(this);
        this.onChangeTaxPercent = this.onChangeTaxPercent.bind(this);
        this.onChangeVatPercent = this.onChangeVatPercent.bind(this);
        this.onChangeOwnerNetiId = this.onChangeOwnerNetiId.bind(this);
        this.submitSearch = this.submitSearch.bind(this);


        this.fetchNewProductList = this.fetchNewProductList.bind(this);

        this.viewDialog = this.viewDialog.bind(this);
        this.updateDialog = this.updateDialog.bind(this);
        this.stockUpdateDialog = this.stockUpdateDialog.bind(this);
        this.onHideUpdateDialog = this.onHideUpdateDialog.bind(this);
        this.onHideStockDialog = this.onHideStockDialog.bind(this);

        this.userCategoryService = new UserCategoryService();
        this.profileService = new ProfileService();
        this.adminPointService = new AdminPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();
        this.ValidatorUtility = new ValidatorUtility();
    }

    componentWillMount() {
        this.fetchProductTypeList('T104');
        this.fetchNewProductList();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));

    }

    onChangeProductType(value) {

        errors["productType"] = "";
        this.setState({
            productType: value,
            errors: errors
        });
    }

    onChangeProductName(value) {
        errors["productName"] = "";
        this.setState({
            productName: value,
            errors: errors
        });
    }

    onChangeDefaultCode(value) {
        errors["defaultCode"] = "";
        this.setState({
            defaultCode: value,
            errors: errors
        });
    }

    onChangeProductDetails(value) {
        errors["productDetails"] = "";
        this.setState({
            productDetails: value,
            errors: errors
        });
    }

    onChangeRemainingStock(value) {
        errors["remainingStock"] = "";
        this.setState({
            remainingStock: value,
            errors: errors
        });
    }

    onChangeGenerateCode(value) {
        errors["generateCode"] = "";
        this.setState({
            generateCode: value,
            errors: errors
        });
    }

    onChangeBijoySellNumb(value) {
        errors["bijoySellNumb"] = "";
        this.setState({
            bijoySellNumb: value,
            errors: errors
        });
    }

    onChangeBijoyMultiNumb(value) {
        errors["bijoyMultiNumb"] = "";
        this.setState({
            bijoyMultiNumb: value,
            errors: errors
        });
    }

    onChangeSalesPrice(value) {
        errors["salesPrice"] = "";
        this.setState({
            salesPrice: value,
            errors: errors
        });
    }

    onChangeCostPercent(value) {
        errors["costPercent"] = "";
        value = (+(value) + +(this.state.fixedPercent) + +(this.state.levelPercent)) <= hundred ? value : '';
        this.setState({
            costPercent: value,
            errors: errors,
        });
    }

    onChangeFixedPercent(value) {
        errors["fixedPercent"] = "";

        value = (+(this.state.costPercent) + +(value) + +(this.state.levelPercent)) <= hundred ? value : '';

        this.setState({
            fixedPercent: value,
            errors: errors,
        });
    }

    onChangeLevelPercent(value) {
        errors["levelPercent"] = "";
        value = (+(this.state.costPercent) + +(this.state.fixedPercent) + +(value)) <= hundred ? value : '';
        this.setState({
            levelPercent: value,
            errors: errors
        });
    }

    onChangeTaxPercent(value) {
        errors["taxPercent"] = "";
        this.setState({
            taxPercent: value,
            errors: errors
        });
    }

    onChangeVatPercent(value) {
        errors["vatPercent"] = "";
        this.setState({
            vatPercent: value,
            errors: errors
        });
    }

    onChangeOwnerNetiId(value) {
        errors["ownerNetiId"] = "";
        errors["EmptySearch"] = ''
        this.setState({
            ownerNetiId: value,
            errors: errors
        });
    }


    submitSearch() {
        if (this.state.ownerNetiId !== '') {
            errors["Error"] = "";
            errors['EmptySearch'] = "";
            this.setState({ topProgressBar: true, searchIsLoading: true, errorMsgVisible: false, searchView: false, searchViewError: false, netiIDViewError: false })
            this.profileService.findBasicInfoByCustomNetiID(this.state.ownerNetiId)
                .then((res) => {
                    if (res.status == 302) {
                        errors["Error"] = "";
                        errors['EmptySearch'] = "";
                        return res.json().then((body) => {
                            this.setState({ userDetails: body, topProgressBar: false, searchIsLoading: false, searchView: true, errorMsgVisible: false, netiIDViewError: false, searchViewError: false, buttonDisabled: false })

                        });
                    } else {
                        this.profileService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                console.log("search resp", resp);
                                // errors["Error"] = resp;
                                this.setState({ searchViewError: false, searchView: false, errors, topProgressBar: false, searchIsLoading: false, netiIDErrorBody: 'No ID Found', netiIDViewError: true, buttonDisabled: true })
                            });
                    }
                }).catch(error =>
                    this.setState({ searchViewError: false, netiIDViewError: false, searchView: false, topProgressBar: false, searchIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
                )

        } else {
            errors['EmptySearch'] = "Neti ID can't left empty.";
            this.setState({ errors, searchView: false, searchViewError: false, buttonDisabled: true, netiIDViewError: false })

        }


    }



    viewDialog(rowData) {
        this.setState({ selectedRowData: rowData,viewDialogShow: true,
        });
       
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.getProductOwnerRevenueInfo(rowData.productID);
    }

    getProductOwnerRevenueInfo = (param) => {
        this.setState({ topProgressBar: true,errorMsgVisible: false })
        this.adminPointService.getPurchaseAtAGlanceByproductID(param)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productOwnerRevenueInfo: body, topProgressBar: false });
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    updateDialog(rowData) {
        let { selectedRowData } = this.state;
        if (rowData.genCodeStatus == 'Yes') {
            rowData.genCodeStatus = 1;
        } else if (rowData.genCodeStatus == 'No') {
            rowData.genCodeStatus = 0;
        }

        this.setState({ selectedRowData: rowData });
        this.setState({ updateDialogShow: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    stockUpdateDialog(rowData) {
        this.setState({ selectedRowData: rowData });
        this.setState({ stockDialogShow: true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }


    onHideUpdateDialog() {
        this.setState({ updateDialogShow: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    onHideStockDialog() {
        this.setState({ stockDialogShow: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    fetchProductTypeList(defaultCode) {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.fetchListByDefaultCode(defaultCode)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ productTypeList: body, topProgressBar: false, firstDropdownIsLoading: false });

                    })
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error =>
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            );
    }

    fetchNewProductList() {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.adminPointService.fetchNewProductList()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].genCodeStatus == 1) {
                                body[i].genCodeStatus = 'Yes';
                            } else if (body[i].genCodeStatus == 0) {
                                body[i].genCodeStatus = 'No';
                            }
                        }
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                        console.log('table body', body);
                        
                    })
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }



    focusSearch = e => {
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white" });
    }

    blurSearch = e => {
        this.setState({ searchLabel: '', addSearchBtnClass: "" });
    }

    productStatusInfoBody = (rowData) => {

        if (rowData.productEnableStatus === 0) {
            return <span className="text-danger">
                <span>Disable</span>
            </span>;
        }   else if (rowData.productEnableStatus === 1) {
            return <span className="text-success">
                <span>Enable</span>
            </span>;
        }
        else {
            return <span>{rowData.productEnableStatus}</span>;

        }

        // return (
        //     <div className="badge"> 
        //         {rowData.productEnableStatus === 1 ?
        //             <div style={{color:"#00E38F"}}><span className="task-badge online"> </span> Enable</div>:
        //             <div style={{color:"#ff0000"}}><span className="task-badge offline"> </span> Disable</div>
        //         }
        //     </div>
        // );
    }

    codeInfoBody = rowData =>{
        return (
            <div className="nw-datatable-dataview-small-panel">
                <div>
                    <div>Default Code</div>
                    <div>:</div>
                    <div> {rowData.productDefaultCode}</div>
                </div>

                <div>
                    <div>Generate Code</div>
                    <div>:</div>
                    <div> {rowData.genCodeStatus}</div>
                </div>

            </div>
        );
    }

    ownerInfoBody = rowData =>{
        return (
            <div className="nw-datatable-dataview-small-panel ownerInfo">
                <div>
                    <div>Name</div>
                    <div>:</div>
                    <div> {rowData.userBasicInfoDTO.fullName}</div>
                </div>

                <div>
                    <div>Neti ID.</div> 
                    <div>:</div>
                    <div> {rowData.userBasicInfoDTO.customNetiID}</div>
                </div>

            </div>
        );
    }

    priceInfoBody = rowData =>{
        return (
            <div className="nw-datatable-dataview-small-panel priceInfo">
                    <h2> 
                    <span className="symbol-taka">৳</span> {this.ValidatorUtility.currencyFormatter(rowData.salesPrice)}
                        
                    </h2>

                <div>
                    <div>Vat-Tax (%)</div>
                    <div>:</div>
                    <div> {this.ValidatorUtility.currencyFormatter(rowData.percentVat)} - {this.ValidatorUtility.currencyFormatter(rowData.percentTax)}</div>
                </div>

                <div>
                    <div>Cost-Fixed-Level (%)</div>
                    <div>:</div>
                    <div> {this.ValidatorUtility.currencyFormatter(rowData.distributePercentCost)} - {this.ValidatorUtility.currencyFormatter(rowData.distributePercentFixed)} -  {this.ValidatorUtility.currencyFormatter(rowData.distributePercentLevel)}</div>
                </div>

                {/* <div>
                    <div>{rowData.distributePercentCost}</div>
                    <div>{rowData.distributePercentFixed}</div>
                    <div>{rowData.distributePercentLevel}</div>
                </div> */}

            </div>
        );
    }

     onHideViewDialog = () => {
        this.setState({ viewDialogShow: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }



    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        // let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Product List<span style={{ 'float': 'right' }}> Total Found:{this.state.dataTableValue.length} </span></div>;

        let tableHeader = <div className="header-title">
                        <div className="header-title-left">
                            Product List
                        </div>
                        <div className="header-title-right">
                            <Link to="#">
                                Total Found: {this.state.dataTableValue.length} 
                            </Link>
                        </div>
                        </div>;

        let detailsTableHeader = <div className="header-title">
                    <div className="header-title-left">Product List</div>

                    <div className="header-title-right">
                        <a className="total-found">
                            Total Found: {this.state.dataTableValue.length} 
                        </a>
                    </div>
                </div>;

        let productTypeOptions = [];
        if (this.state.productTypeList && this.state.productTypeList.length) {
            productTypeOptions = this.state.productTypeList.map(item => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

        let costLevelFixedBody = (rowData) => {
            return <span>{rowData.distributePercentCost} - {rowData.distributePercentFixed} - {rowData.distributePercentLevel}</span>
        }

        let vatTaxBody = (rowData) => {
            return <span>{rowData.percentVat} - {rowData.percentTax}</span>
        }

        let editBody = (rowData) => {

            return <div className='text-center'>
                    <Button
                        className="nw-action-button"
                        icon="far fa-eye"
                        title="View"
                        onClick={(e) => this.viewDialog(rowData)}
                        tooltip="View"
                        tooltipOptions={{position: 'top'}}
                    />
                    <Button
                        className="nw-action-button"
                        icon="fas fa-pencil-alt"
                        title="Edit"
                        onClick={(e) => this.updateDialog(rowData)}
                        tooltip="Edit"
                        tooltipOptions={{position: 'top'}}
                    />
                    <Button
                        className="nw-action-button"
                        icon="fas fa-box-open"
                        title="Stock Update"
                        onClick={(e) => this.stockUpdateDialog(rowData)}
                        tooltip="Stock Update"
                        tooltipOptions={{position: 'top'}}
                    />
            </div>
        }

        let { selectedRowData } = this.state;

        let footerForDialogueFixedRevenue = <ColumnGroup>
            <Row rowSpan={2}>
                <Column footer="" colSpan={2} rowSpan={2} />
                <Column footer="100%" rowSpan={2} style={{ textAlign: 'left' }} />
                <Column footer={<div>
                    <span style={{ fontSize: '14px', opacity: '0.8' }}>Total:</span>
                    <br />
                    <span style={{ color: '#FF8800' }}>20,00.00/-</span>
                </div>} rowSpan={2} className="nw-tfoot-total-text" style={{ textAlign: 'left' }} />
            </Row>
        </ColumnGroup>;

        let footerForDialogueLevelRevenue = <ColumnGroup>
            <Row>

                <Column footer="" colSpan={2} rowSpan={2} />
                <Column footer="100%" rowSpan={2} style={{ textAlign: 'left' }} />
                <Column footer={<div>
                    <span style={{ fontSize: '14px', opacity: '0.8' }}>Total:</span>
                    <br />
                    <span style={{ color: '#00AFD4' }}>20,00.00/-</span>
                </div>} rowSpan={2} className="nw-tfoot-total-text" style={{ textAlign: 'left' }} />

            </Row>
        </ColumnGroup>;

        let dialogRevenueHeader1 = <div className="p-clearfix p-inputgroup" style={{ 'lineHeight': '1.87em', 'display': 'block' }}>
            Fixed Revenue List<span><p className="p-inputgroup-info-value-color2">20%</p> of sales Price =<p className="p-inputgroup-info-value-color2"> 2000/-</p></span></div>;

        // <div>Fixed Revenue List  <span style={{ float: 'right', fontSize: '1em', color: '#004BA0' }}>20% of sales Price = 2000/-</span></div>;
        let dialogRevenueHeader2 = <div className="p-clearfix p-inputgroup" style={{ 'lineHeight': '1.87em', 'display': 'block' }}>
            Level Revenue List<span><p className="p-inputgroup-info-value-color3">20%</p> of sales Price =<p className="p-inputgroup-info-value-color3"> 2000/-</p></span></div>;;


        let vatAmountCal = (this.state.salesPrice * this.state.vatPercent) / 100;
        let revenueParcentCal = +(this.state.costPercent) + +(this.state.fixedPercent) + +(this.state.levelPercent);
        let totalRevenueCal = (this.state.salesPrice * this.state.costPercent) / 100 + (this.state.salesPrice * this.state.fixedPercent) / 100 + (this.state.salesPrice * this.state.levelPercent) / 100
        let fixedPercentCal = (this.state.salesPrice * this.state.fixedPercent) / 100
        let levelPercentCal = (this.state.salesPrice * this.state.levelPercent) / 100
        let costPercentCal = (this.state.salesPrice * this.state.costPercent) / 100
        let incomeTaxPercentCal = (+(fixedPercentCal + levelPercentCal) * this.state.taxPercent) / 100


        return (
            <div className="p-fluid admin-point">   
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section blur-section">
                    <div className="p-grid">
                        <Growl ref={(el) => this.growl = el} />
                        <div className="p-col-12 p-xl-12 nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-col-12 p-xl-12 nw-data-table">
                                    {this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader() :
                                        <DataTable
                                            header={detailsTableHeader}
                                            value={this.state.dataTableValue}
                                            selectionMode="single"
                                            //selection={this.state.dataTableSelection}
                                            //onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            paginator={true}
                                            rows={20}
                                            globalFilter={this.state.globalFilter}
                                        >
                                            <Column field="productDefaultCode" header="Default Code" />
                                            <Column field="productName" header="Product Name" />
                                            <Column field="remainingStock" header="Remain Stock" />
                                            <Column field="genCodeStatus" header="Code Generate" />
                                            <Column field="salesPrice" header="Payable Price" />
                                            <Column field="productEnableStatus" header="Product Status" body={this.productStatusInfoBody}/>
                                            <Column header="Action" body={editBody}/>
                                        </DataTable>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dialog-section">
          
                        <ProducListInfoDialog
                        viewDialogShow={this.state.viewDialogShow}
                        onHideViewDialog={this.onHideViewDialog}
                        productOwnerRevenueInfo={this.state.productOwnerRevenueInfo}
                        />
              
                
                    <Dialog header="Update Product Information" className="nw-dialog" visible={this.state.updateDialogShow} onHide={this.onHideUpdateDialog} maximizable>

                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    {this.state.updateDialogShow ? <ProductInfoUpdate rowDataInfo={this.state.selectedRowData} productUpdateDialog={this.onHideUpdateDialog} productList={this.fetchNewProductList} /> : ''}
                                </div>
                            </div>
                        </div>
                    </Dialog>
                
                    <Dialog header="Update Product Stock Information" className="nw-dialog" visible={this.state.stockDialogShow} onHide={this.onHideStockDialog}>

                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12">
                                    <ProductStockInfoUpdate rowDataInfo={this.state.selectedRowData} stockUpdateDialog={this.onHideStockDialog} productList={this.fetchNewProductList} />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
               
            </div>
        );
    }
}
