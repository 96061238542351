import React, { Component } from 'react';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { CRMPointService } from '../../service/crmPoint/CRMPointService';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ValidatorUtility } from '../../utils/ValidatorUtility';



let cloneStateBeforeMount;

let billTotalMonth = 0;
let totalPayableAmount = 0;
let totalPaidAmount = 0;
let totalDueAmount = 0;
let instituteDueAmount = 0;
let instituteInfoArr = [];
export class BillAdjustment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            generateBillId: '',
            billAmount:'',
            instituteIdSearchView: false,
            instituteIdSearchViewError: false,
            firstInputFieldIsLoading: false,
            firstDropdownIsLoading:false,
            instituteIdSearchBtnDisabled:false,
            instituteIdSearchViewErrorMsgBody:'',
            postpaidBillList: [],
            payabaleInstituteList:[],
            buttonDisabled:false,
            instituteError: [],
            billAssignmentError:[],
            assignPointInfo: {
                instituteId: '',
            },
            instituteInfo:{
                instituteName:'',
            },
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: '',
        };
        this.netiContentLoader = new NetiContentLoader();
        this.ErrorMessageView = new ErrorMessageView();
        this.CRMPointService = new CRMPointService();
        this.validatorUtility = new ValidatorUtility();
        this.PartnerPointService = new PartnerPointService();
    }

    componentWillMount() {
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    focusSearch = e =>{
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white"  });
    }

    blurSearch = e =>{
        this.setState({ searchLabel: '', addSearchBtnClass: ""  });
    }

    instituteIdHandler = (event) => {
        let { assignPointInfo, instituteError } = this.state
        instituteError["instituteError"] = "";
        assignPointInfo.instituteId = event.target.value;
        this.setState({ assignPointInfo, instituteError });
    }

    viewPostpaidBillSummaryList = (value) => {
        
        this.setState({ postpaidBillList: [] })
        this.setState({ topProgressBar: true,errorMsgVisible: false });
        billTotalMonth = 0;
        totalPayableAmount = 0;
        totalPaidAmount = 0;
        totalDueAmount = 0;
        this.PartnerPointService.getPostpaidBillSummary(value)
        .then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    console.log("bill summary body", body);
                    if(body.length !== 0) {
                        billTotalMonth = body.map(item => item.month).length;
                        totalPayableAmount = body.map(item => item.payableAmount).reduce((a, b) => (a + b));
                        totalPaidAmount = body.map(item => item.paidAmount).reduce((a, b) => (a + b));
                        totalDueAmount = body.map(item => item.dueAmount).reduce((a, b) => (a + b));
                        this.setState({ topProgressBar: false,postpaidBillList: body, errorMsgVisible: false });
                    } else {
                        this.setState({ topProgressBar: false,errorMsgVisible: true, errorMsgBody: 'No bill amount found' });
                    }
                   
                });
            } else {
                this.PartnerPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ errorMsgVisible: true, errorMsgBody: responseBody,topProgressBar: false, })
                    });
            }
        }).catch(error => {
            this.setState({ errorMsgVisible: true, topProgressBar: false, errorMsgBody: 'Please check your connection.' })
        });

    }

    instituteIdCheck = (e) => {
        this.setState({ instituteIdSearchView:false,instituteInfo: cloneStateBeforeMount.instituteInfo })
        instituteInfoArr = [];
        if (this.instituteIdError()) {
            this.setState({ instituteIdSearchBtnDisabled:false,topProgressBar: true, instituteIdSearchViewError: false })
            this.CRMPointService.getUserInstituteId(this.state.assignPointInfo.instituteId)
            .then(res => {
                    if (res.status === 200) {
                        return res.json().then((body) => {
                            console.log("body",body);
                            this.viewPostpaidBillSummaryList(body.emDetailsId);
                            let {instituteInfo} = this.state;
                            instituteInfo.emDetailsId = body.emDetailsId;
                            instituteInfo.instituteName = body.instituteName;
                            instituteInfo.originalDue = Math.floor(body.originalDue);
                            instituteInfo.logDue = Math.floor(body.logDue);
                            instituteInfo.generatedAmount = Math.floor(body.generatedAmount);
                            instituteInfo.paidAmount = Math.floor(body.paidAmount);
                            instituteInfo.discountAmount = Math.floor(body.discountAmount);
                            instituteInfo.difference = body.logDue - body.originalDue;
                            instituteInfo.idCreateDate = body.idCreateDate;
                            instituteInfo.instituteAddress = body.instituteAddress;
                            instituteInfo.perStudentRate = body.perStudentRate;
                            instituteInfo.instituteStatus = body.instituteStatus;


                            this.setState({ instituteIdSearchView:true,instituteInfo,topProgressBar:false})
                            instituteInfoArr.push(instituteInfo);
                    })
                    }else{
                        this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({
                                instituteIdSearchBtnDisabled:false,
                                instituteIdSearchViewErrorMsgBody: resp,
                                instituteIdSearchViewError: true,
                                instituteIdSearchView: true, 
                                topProgressBar:false,
                                errorMsgVisible: false,
                                instituteInfo: {
                                    instituteName: ''
                                }
                            });
                        })
                    }
                    
                
            }).catch(error =>{
                this.setState({ topProgressBar: false,errorMsgVisible: true, instituteIdSearchView: false, instituteIdSearchViewError: false, errorMsgBody: 'Unable to load. Please check your connection.' })
            })
        
        }
    }

    instituteIdError = (e) =>{
        let {instituteError,assignPointInfo} = this.state;
        let formIsValid = true;
        if (this.state.assignPointInfo.instituteId === '') {
            formIsValid = false;
            instituteError["instituteError"] = "Institute ID can't left empty";
        } else {
            instituteError["instituteError"] = "";
        }
        this.setState({ instituteError });
        return formIsValid;
    }

    billAssignmentSubmitHandlerError = (e) => {
        let {billAssignmentError,assignPointInfo} = this.state;
        let formIsValid = true;
        if(this.state.generateBillId === ''){
            formIsValid = false;
            billAssignmentError["generateBillIdError"] = "Monthly Bill can't left empty";
        }

        if(this.state.billAmount === '' || this.state.billAmount === null){
            billAssignmentError["billAmountError"] = "Bill Amount can't left empty";
        } else if (this.state.billAmount > instituteDueAmount) {
            formIsValid = false;
            billAssignmentError["billAmountError"] = "Exceeding the monthly bill due amount";
        }
        this.setState({ billAssignmentError });
        return formIsValid;
    }

    onChangeMonthlyBill = (e) => {
        let {postpaidBillList,billAssignmentError} = this.state;
        billAssignmentError["generateBillIdError"] = "";
        this.setState({
            generateBillId:e.target.value,
            billAssignmentError
        })

        for (let i = 0; i < postpaidBillList.length; i++) {
            if (postpaidBillList[i].generateBillId == e.target.value) {
                instituteDueAmount = postpaidBillList[i].dueAmount;
            }
        }
        this.setState({
            instituteIdSearchBtnDisabled: true
        })
    }

    onChangeBillAmount = (e) =>{
        let {billAssignmentError} = this.state;
        billAssignmentError["billAmountError"] = "";
        this.setState({
            billAmount:e.target.value,
            billAssignmentError
        })
    }

    billAssignmentSubmitHandler = (e) =>{
        let {instituteInfo} = this.state;
        if(this.billAssignmentSubmitHandlerError()){
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
            let requestedObject = {
                "emDetailsId":instituteInfo.emDetailsId,
                "generateBillId":this.state.generateBillId,
                "billAmount": this.state.billAmount
            }
            console.log("request object",requestedObject);
            
            this.CRMPointService.createBillAdjustment(requestedObject).then(res => {
                if (res.status == 201) {
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                } else {
                    this.CRMPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
        } 
        }

        differenceAmount = (rowData) => {
            return(<span>{rowData['logDue'] - rowData['originalDue']}</span>)
        }
        


    render() {
        let {firstInputFieldIsLoading,topProgressBar,firstDropdownIsLoading,errorMsgVisible,instituteError,assignPointInfo} = this.state;
        let {errorMsgBody,instituteInfo} = this.state

        let billSummaryList = [];
        if ((this.state.postpaidBillList != null) && (this.state.postpaidBillList.length > 0)) {
            billSummaryList = this.state.postpaidBillList.map((item) => ({
                value: item.generateBillId,
                label: `${item.year} - ${item.month} - ${item.dueAmount} Tk`
            }));
        }

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <div className="p-grid nw-form">
                    <div  className="p-col-12 p-xl-12">
                        <div className="nw-form-body">

                            <div className="p-col-12 p-xl-12">
                                {firstDropdownIsLoading ? this.netiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Institute ID <span>*</span></span>
                                        <InputText
                                            placeholder="Enter Institute ID"
                                            style={{ width: "15%" }}
                                            type="text"
                                            name="instituteId"
                                            keyfilter="num"
                                            value={this.state.assignPointInfo.instituteId}
                                            onChange={(e) => this.instituteIdHandler(e)}
                                            onFocus={this.focusSearch}
                                            onBlur={this.blurSearch}
                                        />
                                        <Button
                                            className={"p-button-animation " + this.state.addSearchBtnClass}
                                            label={this.state.searchLabel}
                                            icon="fas fa-search"
                                            onClick={(e) => this.instituteIdCheck(e)}
                                            onFocus={this.focusSearch}
                                            onBlur={this.blurSearch}
                                        />
                                    </div>
                                }
                                <span className="error-message">{this.state.instituteError["instituteError"]}</span>
                            </div>

                            { this.state.instituteIdSearchView ? 
                            
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-search-view">
                                {this.state.instituteIdSearchViewError ?
                                                    <center className="error-message">{this.state.instituteIdSearchViewErrorMsgBody}</center> :
                                        <div>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon">Institute Name</span>
                                                    <span className="p-inputgroup-colon">:</span>
                                                    <div className="nw-inputgroup-desc">
                                                        {instituteInfo.instituteName}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon">Institute Address</span>
                                                    <span className="p-inputgroup-colon">:</span>
                                                    <div className="nw-inputgroup-desc">
                                                        {instituteInfo.instituteAddress}  
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon">Status</span>
                                                    <span className="p-inputgroup-colon">:</span>
                                                    <div className="nw-inputgroup-desc">
                                                        {instituteInfo.instituteStatus == 1 ? "Active" : "Inactive" }  
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon">Rate Per Student</span>
                                                    <span className="p-inputgroup-colon">:</span>
                                                    <div className="nw-inputgroup-desc"> 
                                                        {instituteInfo.perStudentRate}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon">ID Create Date</span>
                                                    <span className="p-inputgroup-colon">:</span>
                                                    <div className="nw-inputgroup-desc"> 
                                                        {instituteInfo.idCreateDate}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>    
                                }
                                </div>
                                </div>
                             : ''
                             }

                            { instituteInfo.difference === 0 || instituteInfo.difference > 0 ? 
                                <div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Monthly Bill<span>*</span></span>
                                                <Dropdown
                                                    value={this.state.generateBillId}
                                                    options={billSummaryList}
                                                    onChange={this.onChangeMonthlyBill}
                                                    placeholder='Select Institute'
                                                    name="device"
                                                    autoWidth={false}
                                                />
                                        </div>
                                        <span className="error-message">{this.state.billAssignmentError["generateBillIdError"]}</span>
                                    </div>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Bill Amount <span>*</span></span>
                                            <InputText 
                                                value={this.state.billAmount}
                                                type="text" 
                                                keyfilter="pint"
                                                name="billAmountError"
                                                onChange={this.onChangeBillAmount}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.billAssignmentError["billAmountError"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                    <div className="nw-search-view">
                                    <div>

                                    <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon">Note</span>
                                                    <span className="p-inputgroup-colon">:</span>
                                                    <div className="nw-inputgroup-desc"> 
                                                        {`Discount adjustment to resolve migration mismatch`}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="task-badge found"></span>
                                                    <span className="p-inputgroup-addon">Date</span>
                                                    <span className="p-inputgroup-colon">:</span>
                                                    <div className="nw-inputgroup-desc"> 
                                                        {`31-Dec-2019 / 10.00 am`}
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                <div className="p-col-12" />

                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                        <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div>

                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Save"
                                            icon="fas fa-check"
                                            onClick={(e) => this.billAssignmentSubmitHandler(e)}
                                            disabled={this.state.instituteIdSearchBtnDisabled === false ? !this.state.buttonDisabled : this.state.buttonDisabled}

                                        />

                                    </div>
                                </div>
                            : '' 
                            }
                             

                        </div>

                    </div>

                    <div className="p-col-12 p-xl-12">
                
                        <div className="nw-data-table nw-data-table-tabview">
                        <DataTable
                            value={instituteInfoArr}
                            header="Due Bill Info"
                            responsive={true}
                        >
                            <Column field="logDue" header="Log Bill Due" style={{width:"95px"}} />
                            <Column field="generatedAmount" header="Bill generate" style={{width:"95px"}} />
                            <Column field="paidAmount" header="Paid" style={{width:"95px"}} />
                            <Column field="discountAmount" header="Discount" style={{width:"95px"}} />
                            <Column field="originalDue" header="Original Bill Due" style={{width:"95px"}} />
                            <Column field="" header="Difference" body={this.differenceAmount}  style={{width:"95px"}} />
                        </DataTable>
                    </div>

                    
                    </div>

                </div>
            </div>
        );
    }
}