import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { AdmisiaPointService } from "../../../service/admisiaPoint/AdmisiaPointService";
import { UserCategoryService } from "../../../service/UserCategoryService";
import NetiContentLoader from '../../common/NetiContentLoader';
import { Link } from 'react-router-dom';
import { pdfDownloadLandscape_TableWithSubTitle, pdfDownloadLandscape_TableOnly } from '../../common/JsPdfDownload';

let urlId;
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
let classAndGroupListOptions = [];

export class AdmisiaCollectionInfo extends Component {

    constructor() {
        super();
        this.state = {
            errors: {},
            applicantStatusSearchErr:{},
            academicYearList: [],
            academicYear: '',
            dataTableIsLoading: false,
            firstDropdownIsLoading: false,
            secondDropdownIsLoading: false,
            classAndGroupList: '',
            classAndGroupId: '',
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableValue: [],
            classAndGroupPdf:''
        }
        this.onChangeAcYear = this.onChangeAcYear.bind(this);
        this.onChangeClassAndGroupList = this.onChangeClassAndGroupList.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.export = this.export.bind(this);

        this.NetiContentLoader = new NetiContentLoader();
        this.admisiaPointService = new AdmisiaPointService();
        this.userCategoryService = new UserCategoryService();
        this.reloadCoreUrlIdFromLocal();

    }


    reloadCoreUrlIdFromLocal() {
        urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
        if (!urlId) {
            setTimeout(() => {
                this.componentWillMount();
            }, 2000);
        }
    }

    componentWillMount = () => {
        urlId=this.admisiaPointService.getUrlInfoFromLocalStorage();
       
        if (urlId) { 
            this.setState({ homeReturnButton: false })
            this.getAcademicYearListByTypeID();
            this.getFetchClassAndGroupList(urlId) 
        }
        else {
            this.setState({ homeReturnButton: true })
            // this.setState({ errorMsgVisible: true, errorMsgBody: "Please reload page again..." })
        }
    }



    // componentWillMount() {
        
    //     urlId = this.admisiaPointService.getUrlInfoFromLocalStorage();
    //     if (urlId) {
    //         this.setState({ homeReturnButton: false })
    //         this.viewInstituteDetails();
    //     }
    //     else {
    //         this.setState({ homeReturnButton: true })
    //     }
      
    //     cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));

    //     this.getAcademicYearListByTypeID();
    // }


    getFetchClassAndGroupList(urlId) {
        let { errors } = this.state;
        this.setState({ secondDropdownIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.admisiaPointService.getClassAndGroupForApproval(urlId)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ classAndGroupList: body });
                        this.setState({ secondDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ secondDropdownIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                        });
                }
            }).catch(error => {
                this.setState({secondDropdownIsLoading: false, topProgressBar: false,  errorMsgVisible: false});
            });
    }

    getAcademicYearListByTypeID = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.userCategoryService.fetchListByDefaultCode("T107")
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                            this.setState({ academicYearList: body })
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
                        });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                    .then((errorBody) => {
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: errorBody });
                    });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
            });
    }

    onSearchError = () => {
        let { applicantStatusSearchErr } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (this.state.academicYear === '') {
            formIsValid = false;
            applicantStatusSearchErr['academicYear'] = 'Academic Year can not left empty.';
        }

        if (this.state.classAndGroupId === '') {
            formIsValid = false;
            applicantStatusSearchErr['classAndGroupId'] = 'Class & Group can not left empty.';
        }

        this.setState({ applicantStatusSearchErr });
        return formIsValid;
    }


    onChangeAcYear(value) {
        let { applicantStatusSearchErr } = this.state;
        applicantStatusSearchErr["academicYear"] = '';
        this.setState({ academicYear: value, applicantStatusSearchErr });
    }

    onChangeClassAndGroupList = (e) => {
        let { applicantStatusSearchErr } = this.state;
        applicantStatusSearchErr['classAndGroupId'] = '';
        let classGroupName;
        classAndGroupListOptions.find(function(classAndGroupListOptions){
           if(classAndGroupListOptions.value == e.target.value){
                classGroupName = classAndGroupListOptions.label
           }
        });
        this.setState({ classAndGroupId: e.target.value, classAndGroupPdf: classGroupName, applicantStatusSearchErr });
    }

    submitSearch() {
        //this.setState({ dataTableValue: [] }); 
        if(this.onSearchError()) {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false});
        this.admisiaPointService.fetchApplicantCollectionInfo(this.state.classAndGroupId, this.state.academicYear)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                         this.setState({ dataTableValue: body }) 
                         this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        })
                } else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                    .then((errorBody) => { 
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: errorBody });
                    });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading:false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection'});
               
            });
        }
    }

    export() { 

        this.dt.exportCSV();

    }

    exportPdf = () =>{
        
        let tableColumns = [
            { title: "Registration No.", dataKey: "regNo" },
            { title: "Name", dataKey: "name" },
            { title: "Mobile No.", dataKey: "contact" },
            { title: "Application Date", dataKey: "applicationDate" },
            { title: "Payment Date", dataKey: "paymentDate" },
            { title: "MFS Charge", dataKey: "paidAmount" },
            { title: "Paid Amount", dataKey: "receiveableAmount" }, 
          ];
  
          pdfDownloadLandscape_TableWithSubTitle("Collection Information Report",pdfTitleHeader, pdfTitleHeaderData, tableColumns, this.state.dataTableValue, "collection_info_Report.pdf");

        
    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, errors } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Collection Information List
                        <span style={{ color: "white", display: "flex", float: "right" }}>
                Total Found: {this.state.dataTableValue.length}
                        </span>
        </div>;

        let academicYearOptions = [];
        if (this.state.academicYearList != null && this.state.academicYearList.length) {
            academicYearOptions = this.state.academicYearList.map((item) => ({
                value: item.categoryName,
                label: item.categoryName,
            }));
        }

        
        if (this.state.classAndGroupList && this.state.classAndGroupList.length) {
            classAndGroupListOptions = this.state.classAndGroupList.map((item) => ({
                value: item.classConfigID,
                label: item.className + " (" + item.group + ")"
            }));
        }

        let AppDateFormatBody = (rowData) => {
            return new Date(rowData.applicationDate).toLocaleDateString('en-GB');
        }

        let paymentDateFormatBody = (rowData) => {
            return new Date(rowData.paymentDate).toLocaleDateString('en-GB');
        }

        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        pdfTitleHeaderData = [
        { A: "Year", B: this.state.academicYear, C: "CLASS & GROUP Name", D: this.state.classAndGroupPdf}
        ];

        return (
            

            <div className="p-fluid">
                <div className="p-grid nw-form">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                  
                  
                    <Growl ref={(el) => this.growl = el} />
                    {this.state.homeReturnButton === false ?

                   
                    <div>
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    {this.state.firstDropdownIsLoading ?
                                    this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-small-span">Academic year</span>
                                                <Dropdown
                                                    placeholder="Select Academic year"
                                                    value={this.state.academicYear}
                                                    options={academicYearOptions}
                                                    onChange={(e) => this.onChangeAcYear(e.target.value)}
                                                    filter={true}
                                                    filterBy="value"
                                                    showClear={false}
                                                    autoWidth={false}
                                                    name= "academicYear"
                                                />
                                        </div>
                                    }
                                    <span className="error-message">{this.state.applicantStatusSearchErr['academicYear']}</span>
                                </div>

                                <div className="p-col-12 p-lg-12 p-xl-5">
                                {this.state.secondDropdownIsLoading ?
                                this.NetiContentLoader.normalFormInputField() :
                                    <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon nw-inputgroup-small-span">Class & Group</span>
                                        <Dropdown
                                            placeholder="Select Class & Group"
                                            filter={true}
                                            filterBy="value"
                                            value={this.state.classAndGroupId}
                                            options={classAndGroupListOptions}
                                            onChange={this.onChangeClassAndGroupList}
                                            showClear={false}
                                            autoWidth={false}
                                            name= "classAndGroupId"
                                        />
                                    </div>
                                }
                                    <span className="error-message">{this.state.applicantStatusSearchErr['classAndGroupId']}</span>
                                </div>

                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-left"
                                        label="Search"
                                        onClick={this.submitSearch}
                                        icon="fas fa-search"
                                    />
                                </div>
                            </div>
                        </div>
                        
                    
                    
                        <div className="p-col-12 p-lg-12 p-xl-12">
                            <div className="nw-data-table">
                                {this.state.dataTableValue ?
                                this.state.dataTableIsLoading ? 
                                    this.NetiContentLoader.MyPointTableLoader():
                                    
                                    <DataTable
                                        value={this.state.dataTableValue}
                                        selectionMode="single"
                                        activeIndex="0"
                                        header={tableHeader}
                                        selection={this.state.dataTableSelection}
                                        onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                        responsive={true}
                                        paginator={true}
                                        rows={10}
                                        ref={(el) => { this.dt = el; }}

                                    >
                                        <Column field="regNo" header="Registration No." filter={true} />
                                        <Column field="name" header="Name" filter={true} />
                                        <Column field="contact" header="Mobile No." filter={true} />
                                        <Column field="applicationDate" header="Application Date" body={AppDateFormatBody} filter={true} />
                                        <Column field="paymentDate" header="Payment Date" body={paymentDateFormatBody} filter={true} />
                                        <Column field="paidAmount" header="Paid Amount" filter={true} />
                                    </DataTable>
                                    : ''}
                            </div>
                        
                            <div className="nw-button-parent-multiple m-t-8 p-r-0">
                                <Button
                                    label="Download CSV"
                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                    icon="fas fa-arrow-down"
                                    onClick={this.export}
                                />
                                <Button
                                    label="Download PDF"
                                    className="p-button p-button-primary nw-button nw-button-multiple"
                                    icon="fas fa-arrow-down"
                                    onClick={this.exportPdf}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="">
                        <div className="p-grid">
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <Link
                                        to="/institute_config"
                                        className="rainbow-button"
                                        alt="Go Admisia Home"
                                    />
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-3">
                                <center className="custom-center">
                                    <i className="layout-menuitem-icon fas fa-chevron-right"></i>
                                </center>
                            </div>
                            <div className="card p-col-12 p-xl-6">
                                <img src="assets/images/dws_home.png" width="100%" />
                            </div>
                        </div>
                    </div>

                    }

                 

                          
                </div>
            </div>
        );
    }
}