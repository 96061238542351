import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';

import profileImage from '../../../assets/images/mostofa-jobber.png';

let maxDate = new Date();
export class PartnerScoreDashboard extends Component {

    constructor() {
        super();
        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            dataTableIsLoading: false,
            errorMsgBody: '',
            errors: {},
            dateInfoError: {},
            dateRangeObj: {
                startDate: "",
                endDate: "",
                partnerType: ""
            },
            demoTableData: [{
                'id': '1st',
                'name': 'Hossain',
                'netiId': '100006584',
                'profileImage': '',
                'region': 'Dhaka'
            },
            {
                'id': '1st',
                'name': 'Hossain',
                'netiId': '100006584',
                'profileImage': '',
                'region': 'Dhaka'
            },
            {
                'id': '1st',
                'name': 'Hossain',
                'netiId': '100006584',
                'profileImage': '',
                'region': 'Dhaka'
            },
            {
                'id': '1st',
                'name': 'Hossain',
                'netiId': '100006584',
                'profileImage': '',
                'region': 'Dhaka'
            },
            {
                'id': '1st',
                'name': 'Hossain',
                'netiId': '100006584',
                'profileImage': '',
                'region': 'Dhaka'
            },
            ]
        }

        this.ValidatorUtility = new ValidatorUtility();
    }

    componentWillMount() {

    }

    onChangeDate = (e) => {
        let { dateRangeObj } = this.state;
        dateRangeObj[e.target.name] = e.target.value;
        this.setState({ dateRangeObj });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    dateHandleError = () => {
        let { dateInfoError, dateRangeObj } = this.state;
        let formIsValid = true;

        if (dateRangeObj.startDate === '') {
            formIsValid = false;
            dateInfoError["startDate"] = "From Date can't left empty.";
        }
        if (dateRangeObj.endDate === '') {
            formIsValid = false;
            dateInfoError["endDate"] = "To Date can't left empty.";
        }
        if (dateRangeObj.partnerType === '') {
            formIsValid = false;
            dateInfoError["partnerType"] = "Partner type can't left empty.";
        }
        this.setState({ dateInfoError });
        return formIsValid;
    }

    onChangePartnerType = (e) => {
        let { dateRangeObj } = this.state;
        dateRangeObj[e.target.name] = e.target.value;
        this.setState({ dateRangeObj });
    }

    onSubmitHandler = () => {
        if (this.dateHandleError()) {
            console.log('this is demo');

        }
    }

    itemTemplate(car) {
        return (
            <div className="p-grid item-template-wrapper">
                <div className="p-col-12 p-grid">
                    <div className="p-sm-12 p-md-1 region-wrapper-full">
                        <div className="number">
                            1st
                    </div>
                    </div>
                    <div className="p-sm-12 p-md-8">
                        <div className="profile-details-wrapper">
                            <div className="profile-image">
                                <img src={profileImage} />
                            </div>
                            <div className="profile-details">
                                <span className="netiId">10000000665</span>
                                <span className="name">Hossain</span>

                            </div>
                        </div>
                    </div>
                    <div className="p-sm-12 p-md-3 total-score-wrapper">
                        <div className="total-score">
                            <span>12500</span>
                            <span>Taka</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, dateRangeObj } = this.state
        console.log('profileImage', profileImage);

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section crm-dashboard">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            name='startDate'
                                            value={dateRangeObj.startDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.dateInfoError["startDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            maxDate={maxDate}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            name='endDate'
                                            value={dateRangeObj.endDate}
                                            onChange={this.onChangeDate}
                                            showIcon={true}
                                            dateFormat='dd/mm/yy'
                                            yearRange="2010:2030"
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{this.state.dateInfoError["endDate"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Partner Type <span>*</span></span>
                                        <Dropdown
                                            placeholder="Select Partner Type"
                                            value=""
                                            options=""
                                            onChange={(e) => { this.onChangePartnerType(e) }}
                                            // itemTemplate={this.bankAccountTemplate}
                                            filter={true}
                                            filterBy="value"
                                            showClear={true}
                                            autoWidth={false}
                                            name="partnerType"
                                        />
                                        {/* <span className="p-inputgroup-addon">@</span> */}
                                    </div>
                                    <span className="error-message">{this.state.dateInfoError["partnerType"]}</span>
                                </div>
                                <div className="p-col-12 p-lg-12 nw-button-parent">
                                    <div class="required-field">(<span>*</span>) required fields</div>
                                    <Button
                                        label="Search"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="p-col-12">
                            <div className="partner-performance-title">
                                <h1>BDP PERFORMANCE BOARD</h1>
                            </div>
                        </div>
                        <div className="p-grid performance-table-wrapper">
                            <div className="p-sm-12 p-md-6">
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Income">
                                            <div className="nw-data-table">
                                                <DataView value={this.state.demoTableData} layout="list" itemTemplate={this.itemTemplate}></DataView>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Prepaid Collection" className="bg-success">
                                            <div className="nw-data-table">
                                                <DataView value={this.state.demoTableData} layout="list" itemTemplate={this.itemTemplate}></DataView>

                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Neti CMS Purchase" className="bg-purple-light">
                                            <div className="nw-data-table">
                                                <DataView value={this.state.demoTableData} layout="list" itemTemplate={this.itemTemplate}></DataView>

                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Approved Postpaid Order" className="bg-pending">
                                            <div className="nw-data-table">
                                                <DataView value={this.state.demoTableData} layout="list" itemTemplate={this.itemTemplate}></DataView>

                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <div className="p-sm-12 p-md-6">
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Postpaid Bill Collection">
                                            <div className="nw-data-table">
                                                <DataView value={this.state.demoTableData} layout="list" itemTemplate={this.itemTemplate}></DataView>

                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Device Purchase" className="bg-success-dark">
                                            <div className="nw-data-table">
                                                <DataView value={this.state.demoTableData} layout="list" itemTemplate={this.itemTemplate}></DataView>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div className="p-col-12 p-col-nogutter">
                                    <div className="p-col-12 dashboard-card-view">
                                        <Card header="Approved Prepaid Order" className="bg-purple">
                                            <div className="nw-data-table">
                                                <DataView value={this.state.demoTableData} layout="list" itemTemplate={this.itemTemplate}></DataView>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}