import React, { Component } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import NetiContentLoader from '../common/NetiContentLoader';
import { ErrorMessageView } from '../common/ErrorMessageView';


export class AdminUserNature extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emBillGenerateLogIds: '',
            searchObjInfo:{
                fromDate: '',
                toDate: ''
            },
            viewDetailsObject:{

            },
            dataTableValue: [],
            tableView: false,
            errors: {}
        };

        this.NetiContentLoader = new NetiContentLoader();
    }



    render() {
        let {  dataTableValue } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> User Nature List <span style={{ 'float': 'right' }}> Total Found: 3 </span></div>;




        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                            <div className="nw-data-table">
                                {
                                    
                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        :
                                        <DataTable
                                            value={dataTableValue}
                                            header={tableHeader}
                                            responsive={true}
                                        >
                                            <Column field="netiId" header="Neti ID" filter={true} />
                                            <Column field="name" header="Name" filter={true}/>
                                            <Column field="mobile" header="Mobile" filter={true}/>
                                            <Column field="nature" header="Nature" filter={true}/>
                                        </DataTable>
                            }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
