import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { ManagementPointService } from '../../../../service/managementPoint/ManagementPointService';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim'
import NetiContentLoader from '../../../common/NetiContentLoader';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { pdfDownloadPortrait_TableWithSubTitle } from '../../../common/JsPdfDownload';
import { Dialog } from 'primereact/dialog';
import CommonFuctionality from '../../../common/CommonFuctionality';

// pdf
let subTitleColumn = [];
let subTitleList = [];

export class PersonWiseReceivedCallList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            receivedCallInfoList: [],
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0
        }

        this.managementPointService = new ManagementPointService();
        this.CommonFuctionality = new CommonFuctionality();

        this.viewDialog = this.viewDialog.bind(this);
        this.onHide = this.onHide.bind(this);

        this.exportPdf = this.exportPdf.bind(this);
    }

    componentWillMount() {
        if (this.props.location.personWiseReceivedCallInfo) {
            let receivedCallInfo = {
                "startDate": this.props.location.startDate,
                "endDate": this.props.location.endDate,
                "netiID": this.props.location.personWiseReceivedCallInfo.netiID
            }
            localStorage.setItem("receivedCallInfo", JSON.stringify(receivedCallInfo));
        }
        this.getcallQuantityReportList();
    }

    getcallQuantityReportList = () => {
        let receivedCallInfo = JSON.parse(localStorage.getItem("receivedCallInfo"));
        if (!receivedCallInfo) {
            return;
        }
        this.setState({ topProgressBar: true, dataTableIsLoading: true });
        this.managementPointService.getPersonWiseCallQuantityReport(receivedCallInfo)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if (body.length == 0) {
                            this.setState({ errorMsgVisible: true, errorMsgBody: "No Data Found", topProgressBar: false, dataTableIsLoading: false })
                        } else {
                            for (let i = 0; i < body.length; i++) {
                                if (body[i].callDate == null) {
                                    body[i].callDate = '';
                                } else {
                                    body[i].callDate = NetiDateUtils.getDateWithHHMMSSA(body[i].callDate);
                                }
                            }
                            this.setState({ receivedCallInfoList: body });
                            this.setState({ topProgressBar: false, dataTableIsLoading: false });
                        }
                    })
                }
                else {
                    this.managementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, dataTableIsLoading: false })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: error + 'Please check your connection!!!' });
            });
    }

    viewDialog(rowData) {

        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visible: true, detailsContent: rowData.callNote });

    }

    onHide(event) {

        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visible: false });

    }

    problemDetailsBody = rowData => {

        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-info"
                title='Click to view deposit details'
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>

    }

    exportPdf() {

        let pdfColumns = [
            { title: "Called Date & Time", dataKey: "callDate" },
            { title: "Call Type", dataKey: "callType" },
            { title: "Mobile No.", dataKey: "mobileNo" },
            { title: "Call Purpose", dataKey: "callPurpose" },
            { title: "Status", dataKey: "status" },
        ]

        let tableColStyle = {};

        pdfDownloadPortrait_TableWithSubTitle("Person Wise Received Call List", 68, subTitleColumn, subTitleList, pdfColumns, this.state.receivedCallInfoList, tableColStyle, "token_received_call_List.pdf");

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody, receivedCallInfoList } = this.state;

        // pdf reletaed task
        let getName = '';
        let getNetiId = '';

        if (this.props.location.personWiseReceivedCallInfo) {
            getName = this.props.location.personWiseReceivedCallInfo.name;
            getNetiId = this.props.location.personWiseReceivedCallInfo.customID;
        }
        subTitleColumn = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        subTitleList = [
            { A: "Neti ID", B: getNetiId, C: "Name", D: getName }
        ];
        // pdf reletaed task end

        let header = <div className="header-title">
            <div className="header-title-left">Person Wise Received Call List</div><div className="header-title-right"><a>Total Found: {receivedCallInfoList.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        return (
            <div className="p-fluid">
                {/* <div className="p-grid"> */}
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="nw-data-table">
                    <div className="p-col-12 p-lg-12 p-xl-12">
                        {/* {
                            this.state.tableView === true ?
                                this.state.dataTableIsLoading ? 
                                this.NetiContentLoader.MyPointTableLoader()
                                : */}
                        <DataTable
                            header={header}
                            responsive={true}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            value={this.state.receivedCallInfoList}
                        //selection={this.state.dataTableSelection}
                        >
                            <Column field="callDate" header="Call Date & Time" style={{width: '114px'}} filter={true} />
                            <Column field="callType" header="Call Type" filter={true} />
                            <Column field="mobileNo" header="Mobile No." filter={true} />
                            <Column field="callPurpose" header="Call Purpose" filter={true} />
                            <Column field="status" header="Status" filter={true} />
                            <Column field="" header="Problem Details" body={this.problemDetailsBody} style={{ width: '95px' }} />

                        </DataTable>

                        {/* :
                                
                                <BlankDataTableAnim/> 
                        } */}
                    </div>

                    <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                        <Button
                            className="p-button p-button-primary nw-button nw-button-right"
                            label="Download PDF"
                            icon="fas fa-download"
                            onClick={this.exportPdf}
                        />
                    </div>

                </div>

                <Dialog className="nw-dialog" header="Problem Details" visible={this.state.visible} onHide={this.onHide} maximizable>

                    <div className="p-col-12 p-xl-12">

                        <div className="nw-search-view">
                            <div>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Details</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{this.state.detailsContent}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </Dialog>

            </div>

        )
    }
}