import React, { Component } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { AddNewBook } from "./AddNewBook";
import { DwsService } from '../../../service/dwsPoint/DwsService'
import { Link } from "react-router-dom";
import { Growl } from 'primereact/growl';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import CommonFuctionality from '../../common/CommonFuctionality';
import { NetiCMSSetupProgress } from "../common/NetiCMSSetupProgress";

let urlId;
export class BookList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      CoreUrlInfoDTO: {
        coreUrlInfoDTO: ''
      },
      selectedBooks: [],
      selectedGlobalBooks: [],
      globalBooksData: [],
      selectedBooksData: [],
      selectedCar2: "",
      rowInformation: {},
      dataTableIsLoading: false,
      topProgressBar: false,
      errorMsgVisible: false,
      errorMsgBody: '',
      pickUpButtonDisabled: true
    };

    this.DwsService = new DwsService();
    this.reloadCoreUrlIdFromLocal();
    this.NetiContentLoader = new NetiContentLoader();
    this.CommonFuctionality = new CommonFuctionality();
  }

  reloadCoreUrlIdFromLocal() {
    urlId = this.DwsService.getUrlInfoFromLocalStorage();
    if (!urlId) {
      setTimeout(() => {
        this.componentWillMount();
      }, 2000);
    }
  }

  componentWillMount() {
    urlId = this.DwsService.getUrlInfoFromLocalStorage();
    if (urlId) {
      this.setState({ homeReturnButton: false });
      this.viewPublishedBooksList();
      this.viewGlobalBooksList();
    } else {
      this.setState({ homeReturnButton: true });
    }
  }

  viewGlobalBooksList = () => {
    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    this.DwsService
      .getDwsGlobalBooks(urlId)
      .then(res => {
        if (res.status == 302) {

          return res.json().then(body => {

            let statusName = '';
            body.map(item => {
              if (item.status == 1) { statusName = 'Approved' } else if (item.status == 0) { statusName = 'Pending' }
              Object.assign(item, { formatedStatus: statusName });
            });

            this.setState({
              globalBooksData: body, dataTableIsLoading: false,
              errorMsgVisible: false,
              topProgressBar: false
            });
          });

        } else {
          this.DwsService.Auth.handleErrorStatus(res)
            .then((responseBody) => {
              this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })

            });
        }
      })
      .catch(error => {
        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgVisible: true, errorMsgBody: 'Please check connection' });
      });
  };

  viewPublishedBooksList = () => {
    this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
    let urlIdObj = {
      urlInfoID: urlId
    }

    this.DwsService.getDwsPublishedBooks(urlIdObj)
      .then(res => {
        if (res.status == 302) {
          return res.json().then(body => {
            this.setState({
              selectedBooksData: body, dataTableIsLoading: false,
              errorMsgVisible: false,
              topProgressBar: false
            });
          });
        } else {
          this.DwsService.Auth.handleErrorStatus(res)
            .then((responseBody) => {
              this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
            });
        }
      })
      .catch(error => {
        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' });
      });
  };

  turnOffDialog = () => {
    this.setState({
      visible: false
    });

    this.CommonFuctionality.blurDialogBackgroundDeActive()
  };

  globalBookSelection = (e) => {
    this.setState({ selectedGlobalBooks: e.value });
  }

  globalBookPickup = (e) => {
    let { selectedGlobalBooks } = this.state;

    let bookListArr = [];
    for (let i = 0; i < selectedGlobalBooks.length; i++) {
      var newObjCreate = new Object();
      newObjCreate.dwsGlobalBookInfoDTO = JSON.parse(JSON.stringify({ globalBookId: selectedGlobalBooks[i].globalBookInfoID }));
      newObjCreate.coreUrlInfoDTO = JSON.parse(JSON.stringify({ urlInfoID: urlId }));
      bookListArr.push(newObjCreate);
    }

    this.setState({ topProgressBar: true, errorMsgVisible: false, errorMsgBody: '' });
    this.DwsService.saveDwsBookInfo(bookListArr).then(res => {

      if (res.status === 201) {
        this.setState({ selectedGlobalBooks: [], topProgressBar: false, errorMsgVisible: false, errorMsgBody: '' });
        this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Successfully submitted' });

        setTimeout(() => {
          this.viewGlobalBooksList();
          this.viewPublishedBooksList();
        }, 800);

      }
      else {
        this.DwsService.Auth.handleErrorStatus(res)
          .then((resp) => {
            this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
          });
      }
    }).catch((error) => {
      this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check connection.' });
    });
  }

  statusBody = rowData => {
    // return(
    //   rowData.status == 0 ? "Pending" : "Approved" 
    // )

    if (rowData.formatedStatus == 'Approved') {
      return <span className="text-success"><span>Approved</span></span>;
    } else if (rowData.formatedStatus == 'Pending') {
      return <span className="text-warning"><span>Pending</span></span>;
    }
    else {
      return <span>{rowData['requestStatus']}</span>;
    }
  }

  displaySelection(data) {

    if (!data || data.length === 0) {
      return <div style={{ textAlign: 'left' }}>No Selection</div>;
    }
    else {
      if (data instanceof Array) {
        // if( data.status == 1){
        return <ul style={{ textAlign: 'left', margin: 0, listStyleType: 'none' }}>{data.map((book, i) =>
          <li key={book.globalBookInfoID} className={book.status == 0 ? 'text-danger' : 'text-success'} >
            {book.bookName + ' - ' + book.class + ' - ' + book.bookType}
          </li>
        )}
        </ul>;
        // }
        // else{

        // }
      }
      else {
        return <div style={{ textAlign: 'left' }}>Selected book: {data.bookName + ' - ' + data.class + ' - ' + data.bookType}</div>
      }

    }
  }

  // checkStatus = () =>{
  //   console.log("selectedGlobalBooks.status", this.state.selectedGlobalBooks.status);

  // }

  render() {
    let { selectedGlobalBooks, globalBooksData, selectedBooksData, topProgressBar, errorMsgVisible, errorMsgBody, pickUpButtonDisabled } = this.state;

    let globalBookListHeadervalue = <div className="header-title">
      <div className="header-title-left">Global Book List</div>
      <div className="header-title-right"><a>Total Found: {this.state.globalBooksData.length}</a></div>
    </div>;

    let selectedBookListHeadervalue = <div className="header-title">
      <div className="header-title-left">Selected Book List</div>
      <div className="header-title-right"><a>Total Found: {this.state.selectedBooksData.length}</a></div>
    </div>;

    let selectedGlobalBooksStatus = true;

    let selectedGlobalBooksStatusArray = []
    selectedGlobalBooks.map((book, i) => {
      selectedGlobalBooksStatusArray.push(book.status)
      if (selectedGlobalBooksStatusArray.includes(0)) {
        selectedGlobalBooksStatus = true
      }
      else if (selectedGlobalBooksStatusArray.includes(1)) {
        selectedGlobalBooksStatus = false
      }
    })

    // selectedGlobalBooks.every(this.checkStatus)

    // for( let i=0 ; i<selectedGlobalBooks.length ; i++){
    //   if(selectedGlobalBooks.status == 1){
    //     selectedGlobalBooksStatus = false
    //   }
    //   else{
    //     selectedGlobalBooksStatus = true
    //   }
    // }

    // let checkStatus = checkStatus = () =>{
    //   console.log("selectedGlobalBooks.status", selectedGlobalBooks.status);

    // }

    return (

      <div className="p-fluid">
        <NetiCMSSetupProgress />

        {topProgressBar ?
          <ErrorMessageView
            topProgressBar={topProgressBar}
          />
          : null
        }
        {errorMsgVisible ?
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
          : null
        }
        <Growl ref={(el) => this.growl = el} />
        {this.state.homeReturnButton === false ? (
          <div className="main-section">
            <div className="p-grid blur-section">
              <div className="p-col-12">
                <div className="p-col-12 nw-button-parent">
                  <Button
                    label="Send Book Request"
                    icon="fas fa-plus"
                    className="float-left"
                    className="p-button p-button-primary nw-button nw-button-left"
                    onClick={e => { this.setState({ visible: true }); this.CommonFuctionality.blurDialogBackgroundActive() }}
                  />
                </div>
              </div>

              <div className="p-col-12 p-xl-7">
                <div className="nw-data-table">
                  {this.state.dataTableIsLoading ?
                    this.NetiContentLoader.MyPointTableLoader() :
                    <DataTable
                      value={globalBooksData}
                      header={globalBookListHeadervalue}
                      selection={selectedGlobalBooks}
                      onSelectionChange={e => this.globalBookSelection(e)}
                      responsive={true}
                      paginator={true}
                      rows={10}
                      rowsPerPageOptions={[5, 10, 20]}
                      footer={this.displaySelection(selectedGlobalBooks)}
                    >
                      <Column selectionMode="multiple" style={{ width: '4em', padding: "0 15px" }} />
                      <Column field="bookName" header="Book" filter={true} />
                      <Column field="authorName" header="Author Name" filter={true} />
                      <Column field="class" header="Class" filter={true} />
                      <Column field="formatedStatus" header="Status" body={this.statusBody} filter={true} />
                    </DataTable>
                  }

                  <div className="p-grid">
                    <div className="p-col-12 p-xl-12"></div>
                    <div className="p-col-12 p-xl-12 nw-button-parent">
                      <Button
                        className="p-button p-button-primary nw-button nw-button-right"
                        label="Pick Up"
                        icon="fas fa-arrow-right"
                        onClick={(e) => this.globalBookPickup(e, this.props)}
                        disabled={selectedGlobalBooksStatus}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className="p-col-12  p-xl-5">
                <div className="nw-data-table">
                  {this.state.dataTableIsLoading ?
                    this.NetiContentLoader.MyPointTableLoader() :
                    <DataTable
                      value={selectedBooksData}
                      header={selectedBookListHeadervalue}
                      responsive={true}
                      paginator={true}
                      rows={10}
                      rowsPerPageOptions={[5, 10, 20]}
                    >
                      <Column field="dwsGlobalBookInfoDTO.bookName" header="Book" filter={true} />
                      <Column field="dwsGlobalBookInfoDTO.authorName" header="Author Name" filter={true} />
                      <Column field="dwsGlobalBookInfoDTO.classCategoryInfoDTO.categoryName" header="Class" filter={true} />
                    </DataTable>
                  }
                </div>
              </div>

            </div>
            <div className="dialog-section">
              <Dialog
                className="nw-dialog"
                maximizable
                header="Add Book Request"
                visible={this.state.visible}
                modal={true}
                onHide={this.turnOffDialog}
              >
                <AddNewBook viewGlobalBooksList={this.viewGlobalBooksList} turnOffDialog={this.turnOffDialog} />
              </Dialog>
            </div>
          </div>
        ) : (
            <div className="">
              <div className="p-grid">
                <div className="card p-col-12 p-xl-3">
                  <center className="custom-center">
                    <Link
                      to="/home"
                      className="rainbow-button"
                      alt="Go DWS Home"
                    />
                  </center>
                </div>
                <div className="card p-col-12 p-xl-3">
                  <center className="custom-center">
                    <i class="layout-menuitem-icon fas fa-arrow-right" />
                  </center>
                </div>
                <div className="card p-col-12 p-xl-6">
                  <img src="assets/images/dws_home.png" width="100%" />
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
