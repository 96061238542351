import React, { Component, Children, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CommonFuctionality from '../../common/CommonFuctionality';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles';
 
const localizer = momentLocalizer(moment)
let allViews = Object.keys(Views).map(k => Views[k])

// const ColoredDateCellWrapper = ({ children }) =>
//     React.cloneElement(React.Children.only(children), {
//         style: {
//             backgroundColor: 'red',
//         },
//     })

const MyEvent = (e) =>{
    console.log('MyEvent:::::::::::::::::::::', e);
    return <div>
        <strong>MyEvent</strong>
    </div>
}

// const MyToolBar = (e) =>{
//     console.log('MyToolBar:::::::::::::::::::::', e);
//     return <div>
//         <strong>MyToolBar</strong>
//     </div>
// }

const MyToolBar = (e) =>{
    console.log('MyToolBar:::::::::::::::::::::', e);

    // e.onNavigate = (action, newDate) =>{
    //     console.log('MyToolBar:::::::::::::::::::::', e);
    // }
    return <div>
        <strong>MyToolBar</strong>
        <Button
            label='prev'
            // onClick={e.onNavigate('PREV', CURRENT_DATE)}
        />
        <Button
            label='next'
            // onClick={e.onNavigate('PREV', CURRENT_DATE)}
        />
    </div>
}

const MyAgenda = (e) =>{
    console.log('MyAgenda:::::::::::::::::::::', e);
    return <div>
        <strong>MyAgenda</strong>
    </div>
}

const CURRENT_DATE = moment().toDate();
// const ColoredDateCellWrapper = ({children, value}) =>
//     React.cloneElement(Children.only(children), {
//         style: {
//             ...children.style,
//             backgroundColor: value < CURRENT_DATE ? 'lightgreen' : 'lightblue',
//         },
//     });

export const MyCalendar = props => {
    const [viewMore, viewMoreSet] = useState({ moreClick:false, events:[], date: ''});
    let events = []

    props.events.map((item, index) => {
        events.push({
            start: moment(item.eventStartDate, 'YYYY-MM-DD'),
            end: moment(item.eventEndDate, 'YYYY-MM-DD').add(1, "days").toDate(),
            title: item.eventTitle + ' (' + item.eventType + ')'
        })
    })

    let dateBody = (rowData) => new Date(rowData.end).toLocaleString("sv-BD")

    let commonFuctionality = new CommonFuctionality();

    return <div>
        <Calendar
            className=" blur-section"
            localizer={localizer}
            events={events}
            showMultiDayTimes
            startAccessor="start"
            endAccessor="end"
            views={{
                month: true,
                agenda: true,
                // week: true
            }}
            // views={allViews}
            showMultiDayTimes
            drilldownView="agenda"
            getDrilldownView={(targetDate, currentViewName, configuredViewNames) =>{
                if(currentViewName === 'month' && configuredViewNames.includes('week')) {
                    return 'week'
                } 
                return  null
            }}
            style={{ height: 500 }}
            components={{
                // timeSlotWrapper: ColoredDateCellWrapper,
                // event: MyEvent, // used by each view (Month, Day, Week)
                // toolbar: MyToolBar,
                // agenda: {
                //     event: MyAgenda // with the agenda view use a different component to render events
                // }
                // dateCellWrapper: ColoredDateCellWrapper,
            }}

            onShowMore={(events, date) => {
                // console.log( events, date)
                commonFuctionality.blurDialogBackgroundActive()
                viewMoreSet({moreClick: true, events: events, date: date})
            }}
        />

        <Dialog
            className="nw-dialog"
            header="Selected Event"
            visible={viewMore.moreClick}
            onHide={ ()=> {
                commonFuctionality.blurDialogBackgroundDeActive()
                viewMoreSet({moreClick: false, events: [], date: ''})
            }}
        >
            <div className="nw-data-table">
                <DataTable
                    value={viewMore.events}
                    selectionMode="single"
                    header="View More"
                    responsive={true}
                    rows={10}
                    paginator={true}
                    columnResizeMode="fit"
                >
                    <Column field="title" header="Title"/>
                    <Column field="start._i" header="Start Date" />
                    <Column field="end" header="End Date" body={ dateBody } />
                </DataTable>
            </div>
        </Dialog>
    </div>
}