import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { PartnerPointService } from '../../../../service/partnerPoint/PartnerPointService';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { PARTNER_POINT } from '../../../../utils/PointWiseRouteConsts';
import { MANAGEMENT_POINT } from '../../../../utils/PointWiseRouteConsts';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import Formatter from '../../../../utils/Formatter';


let sumOfInstitute = 0;
let totalActiveInstituteList = 0;
let totalInactiveInstituteList = 0;
let totalActiveRate = 0;
let totalInactiveRate = 0;
let totalActiveDue = 0;
let totalInactiveDue = 0;

// BEP Partner Info

let totalActiveInstitute = 0;
let totalInactiveInstitute = 0;
let totalInActiveBill = 0;
let activeBillTotal = 0;
let totalActiveBillArr = [];
let totalInActiveBillArr = [];
let sumOfInstituteBEP = 0;


export class PartnerPointDueStatement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partnerStatus: {},
            partnerCode: [],
            bepDefaultCode: {},
            partnerID:{},
            dataTableValue: [],
            bepDataTableValue:[],
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,
            bepDataTable: false,
            partner: ''
        }

        this.paidDetailsBody = this.paidDetailsBody.bind(this);
        this.instituteDetailsBody = this.instituteDetailsBody.bind(this);
        this.totalActiveInstitutePercentageInfo = this.totalActiveInstitutePercentageInfo.bind(this);
        this.totalInactiveInstitutePercentageInfo = this.totalInactiveInstitutePercentageInfo.bind(this);
        this.NetiContentLoader = new NetiContentLoader();
        this.PartnerPointService = new PartnerPointService;
        this.validatorUtility = new ValidatorUtility();
        this.Formatter = new Formatter();
    }

    componentWillMount() {

        this.fetchListInfo();
      
    }

    fetchListInfo = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.PartnerPointService.fetchPartnerUserList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        if(body.instituteReportDTOs.length!=0){
                        this.setState({
                            dataTableValue: body.instituteReportDTOs,
                            partnerStatus: body.partnerAssignInfoDTO,
                            bepDefaultCode: body.partnerAssignInfoDTO.partnerDesignationInfoDTO.categoryDefaultCode,
                            partnerCode: body.instituteReportDTOs[0].partnerDesignationCode,
                            partner: !body.instituteReportDTOs[0].partnerType ? '' : body.instituteReportDTOs[0].partnerType,
                        
                        });
                        sumOfInstitute = body.instituteReportDTOs.map(item => item.totalInstitute).reduce((a, b) => (a + b));
                        totalActiveInstituteList = body.instituteReportDTOs.map(item => item.activeInstitute).reduce((a, b) => (a + b));
                        totalInactiveInstituteList = body.instituteReportDTOs.map(item => item.inActiveInstitute).reduce((a, b) => (a + b));
                        totalActiveRate = body.instituteReportDTOs.map(item => item.activeRate).reduce((a, b) => (a + b));
                        totalInactiveRate = body.instituteReportDTOs.map(item => item.inActiveRate).reduce((a, b) => (a + b));
                        totalActiveDue = body.instituteReportDTOs.map(item => item.activePayble).reduce((a, b) => (a + b));
                        totalInactiveDue = body.instituteReportDTOs.map(item => item.inActivePayble).reduce((a, b) => (a + b));

                    }

                        //BEP Partner Info

                        sumOfInstituteBEP = body.instituteReportDTOs.map(item => item.dueAmount).reduce((a, b) => (a + b));

                        body.instituteReportDTOs.map( (item) => { 
                            if(item.instituteStatus === 1) {
                                totalActiveInstitute += 1;
                                totalActiveBillArr.push(item.dueAmount);
                                activeBillTotal =  totalActiveBillArr.reduce((a, b) => (a + b));
                            }

                            if(item.instituteStatus === 2) {
                                totalInactiveInstitute += 1;
                                totalInActiveBillArr.push(item.dueAmount);
                                totalInActiveBill =  totalInActiveBillArr.reduce((a, b) => (a + b));
                            }
                        });


                        this.setState({ topProgressBar: false, dataTableIsLoading: false });

                    });
                } else {
                    this.PartnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection!!!' })

            );
    }


    paidDetailsBody(rowData, column) {
        
        if (rowData.partnerDesignationCode === "T1120205") {

            return <div className='text-center'>
            <Link to={{
                
                pathname: PARTNER_POINT.PARTNER_BEP_DUE_STATEMENT,
                searchObj: { ...rowData },
               
                
            }}>
                <i class="fas fa-arrow-circle-right fa-2x"></i>
                <u>{rowData.dataTableValue}</u>
            </Link>
        </div>

        } else {
            let {dataTableValue} = this.state
            return <div className='text-center'>
                <Link to={{
                    
                    pathname: PARTNER_POINT.PARTNER_INFORMATION,
                    searchObj: { ...rowData },
                    tableObj: { dataTableValue }
        
                }}>
                    <i class="fas fa-arrow-circle-right fa-2x"></i>
                    <u>{rowData.dataTableValue}</u>
                </Link>
            </div>
            }

    }


    instituteDetailsBody(rowData, column) {
       
        let { dataTableValue} = this.state

        return <div className='text-center'>
            <Link to={{
                
                pathname: MANAGEMENT_POINT.INSTITUTE_DUE_STATEMENT,
                searchObj: { ...rowData }
                
            }}>
                <i class="fas fa-arrow-circle-right fa-2x"></i>
                <u>{rowData.dataTableValue}</u>
            </Link>
        </div>
    }


    totalActiveInstitutePercentageInfo() {
        // console.log("Total", totalActiveInstitutePercentage);
    
        //  let totalActiveInstitutePercentage = (totalActiveInstituteList / sumOfInstitute) * 100
        //  return totalActiveInstitutePercentage;


         console.log("TotalActive", totalActiveInstitutePercentage);
 
         let totalActiveInstitutePercentage = (totalActiveInstituteList / sumOfInstitute) * 100
         if(totalActiveInstitutePercentage) {
             return isNaN(totalActiveInstitutePercentage) ? 0 : totalActiveInstitutePercentage;      
         }
      
    }

    totalInactiveInstitutePercentageInfo() {
        // console.log("Total", totalInactiveInstitutePercentage);
    
        //  let totalInactiveInstitutePercentage = (totalInactiveInstituteList / sumOfInstitute) * 100
        //  return totalInactiveInstitutePercentage;

         console.log("Total", totalInactiveInstitutePercentage);

         let totalInactiveInstitutePercentage = (totalInactiveInstituteList / sumOfInstitute) * 100
         if(totalInactiveInstitutePercentage) {
             return isNaN(totalInactiveInstitutePercentage) ? 0 : totalInactiveInstitutePercentage;
         }
      
    }
       


    mbpActiveInstituteBody(rowData) {

        console.log("RowData", rowData);
     
        return <div>
            <div className="text-success">{rowData.activeInstitute} {"[" +  this.Formatter.getFixedDecimalNumber(rowData.activeInstitutePercentage)  + "%" + "]"} {"[" + this.validatorUtility.currencyFormatter(rowData["activeRate"]) + "]"}</div> 
    
            <div className="text-danger">{rowData.inActiveInstitute} {"[" + this.Formatter.getFixedDecimalNumber(rowData.inActiveInstitutePercentage) + "%" + "]"} {"[" + this.validatorUtility.currencyFormatter(rowData["inActiveRate"]) + "]"}</div>

        </div>
        
    }


    mbpDueActiveInstituteBody(rowData) {
        return <span >
            <span className="text-success">{this.validatorUtility.currencyFormatter(rowData["activePayble"]) } </span> <br/> 
            <span className="text-danger">{this.validatorUtility.currencyFormatter(rowData["inActivePayble"])} </span>
        </span>;
    }

    activeInactiveList = () => {
        return <span >
            <span className="text-success">{totalActiveInstituteList} {"[" + this.Formatter.getFixedDecimalNumber(this.totalActiveInstitutePercentageInfo()) + "%" + "]"} {"[" + this.validatorUtility.currencyFormatter(totalActiveRate) + "]"}</span><br/>
            <span className="text-danger">{totalInactiveInstituteList} {"[" + this.Formatter.getFixedDecimalNumber(this.totalInactiveInstitutePercentageInfo()) + "%" + "]"} {"[" + this.validatorUtility.currencyFormatter(totalInactiveRate) + "]"}</span>

        </span>;
    }

    activeInactiveDueList = () => {
        return <span >
            <span className="text-success">{this.validatorUtility.currencyFormatter(totalActiveDue) }</span> <br/> 
            <span className="text-danger">{this.validatorUtility.currencyFormatter(totalInactiveDue)}</span>
        </span>;
    }

    partnerDetailInfo = (rowData) => {

        return <span>
            <span style={{color: "blue"}}>{rowData.partnerMobile}</span>  <br/> 
            <span style={{color: "#bb14bbc9"}}>{rowData.areaName}</span>
        </span>
     
    }


    statusInstituteBody = (rowData, column) => {
        if (rowData.instituteStatus === 1) {
            return <p className="status-active" style={{color:"#1fe41f"}}>Active</p>
        } else if (rowData.instituteStatus === 2) {
            return <p className="status-inactive" style={{color:"red"}}>Inactive</p>
        }
    }


    dueAmountBody = (rowData, column) => {
        return <span> {this.validatorUtility.currencyFormatter(rowData.dueAmount)}</span>
    }




    render() {
        
        let { bepDefaultCode, partner, partnerStatus, topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue } = this.state;

        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Total {!partner ? "" : partner} Found: {dataTableValue.length}<span style={{ 'float': 'right' }}> </span></div>;
        let headerBep = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Total Found: {dataTableValue.length}<span style={{ 'float': 'right' }}> </span></div>;

        let footer = <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={3} />
                <Column footer={sumOfInstitute} />
                <Column footer= {this.activeInactiveList()}/>
                <Column footer= {this.activeInactiveDueList()}/>
                
                <Column footer="" colSpan={1} />
            </Row>
        </ColumnGroup>;

        let footerBEP = <ColumnGroup>
        <Row>
            <Column footer="Total:" colSpan={3} />
            <Column footer={this.validatorUtility.currencyFormatter(sumOfInstituteBEP)} />
            <Column footer="" colSpan={1} />
        </Row>
        </ColumnGroup>;


        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }

                <h1>Partner Status : {partnerStatus.partnerDesignationInfoDTO ? partnerStatus.partnerDesignationInfoDTO.categoryName : ''}</h1>


                {
                    
                    bepDefaultCode === "T1120205"? 
                    <div className="p-grid">
                     <div className="p-col-12 p-xl-12">
                       <div className="nw-form">
                             <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form-body">
                                  <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                     <div>
                                        <div>Neti Id</div>
                                        <div>:</div>
                                        <div>{partnerStatus.userBasicInfoDTO ? partnerStatus.userBasicInfoDTO.customNetiID : ''}</div>
                                    </div>

                                    <div>
                                        <div>Name</div>
                                        <div>:</div>
                                        <div>{partnerStatus.userBasicInfoDTO ? partnerStatus.userBasicInfoDTO.fullName : ''}</div>
                                    </div>

                                    <div>
                                        <div>Mobile No</div>
                                        <div>:</div>
                                        <div>{partnerStatus.userBasicInfoDTO ? partnerStatus.userBasicInfoDTO.basicMobile : ''}</div>
                                    </div>

                                    <div>
                                        <div>Area</div>
                                        <div>:</div>
                                        <div>{partnerStatus.partnerAreaInfoDTO ? partnerStatus.partnerAreaInfoDTO.categoryName : '' }</div>
                                    </div>

                                </div>


                                <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                    <div>
                                        <div>Type</div>
                                        <div>:</div>
                                        <div>{partnerStatus.partnerDesignationInfoDTO ? partnerStatus.partnerDesignationInfoDTO.categoryName : ''}</div>
                                    </div>

                                    <div>
                                        <div>Total Institute</div>
                                        <div>:</div>
                                        <div>{dataTableValue.length}</div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                    <div>
                                       
                                        <div>Active Institute</div>
                                        <div>:</div>
                                        <div>{totalActiveInstitute}</div>
                                    </div>

                                    <div>
                                        <div>Active Bill</div>  
                                        <div>:</div>
                                        <div>{this.validatorUtility.currencyFormatter(activeBillTotal)}</div>
                                    </div>

                                    <div>
                                        <div>Inactive Institute</div>
                                        <div>:</div>
                                        <div>{totalInactiveInstitute}</div>
                                    </div>

                                    <div>
                                        <div>Inactive Bill</div>
                                        <div>:</div>
                                        <div>{this.validatorUtility.currencyFormatter(totalInActiveBill)}</div>
                                    </div>

                                </div>
                            </div>
                          </div>

                          <div className="nw-data-table">
                            {
                        
                            this.state.dataTableIsLoading ?
                                this.NetiContentLoader.MyPointTableLoader()
                                :
                                <DataTable
                                    header={headerBep}
                                    footerColumnGroup={footerBEP}
                                    responsive={true}
                                    selectionMode="single"
                                    paginator={true}
                                    rows={10}
                                    value={this.state.dataTableValue}
                                >
                                    <Column field="instituteId" header="Institute ID" filter={true} />
                                    <Column field="instituteName" header="Institute Name" filter={true} />
                                    <Column field="" header="Status" filter={true} body={this.statusInstituteBody.bind(this)} />
                                    <Column field="" header="Due Bill" filter={true} body={this.dueAmountBody.bind(this)}/>
                                    <Column field="details" header="At a Glance" body={this.instituteDetailsBody} style= {{width:"80px"}}/>
                                </DataTable>
                            }
                    
 
                          </div>

                        </div> 
                    </div>
                        
                </div>

                :  <div className="p-grid">
                       <div className="p-col-12 p-xl-12">

                          <div className="nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="p-grid nw-form-body">
                                    <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                        <div>
                                            <div>Neti Id</div>
                                            <div>:</div>
                                            <div>{partnerStatus.userBasicInfoDTO ? partnerStatus.userBasicInfoDTO.customNetiID : ''}</div>
                                        </div>

                                        <div>
                                            <div>Name</div>
                                            <div>:</div>
                                            <div>{partnerStatus.userBasicInfoDTO ? partnerStatus.userBasicInfoDTO.fullName : ''}</div>
                                        </div>

                                        <div>
                                            <div>Mobile No</div>
                                            <div>:</div>
                                            <div>{partnerStatus.userBasicInfoDTO ? partnerStatus.userBasicInfoDTO.basicMobile : ''}</div>
                                        </div>

                                        <div>
                                            <div>Area</div>
                                            <div>:</div>
                                            <div>{partnerStatus.partnerAreaInfoDTO ? partnerStatus.partnerAreaInfoDTO.categoryName : '' }</div>
                                        </div>

                                    </div>


                                    <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                        <div>
                                            <div>Type</div>
                                            <div>:</div>
                                            <div>{partnerStatus.partnerDesignationInfoDTO ? partnerStatus.partnerDesignationInfoDTO.categoryName : '' }</div>
                                        </div>

                                        <div>
                                            <div>Team Member</div>
                                            <div>:</div>
                                            <div>{dataTableValue.length}</div>
                                        </div>

                                        <div>
                                            <div>Total Institute</div>
                                            <div>:</div>
                                            <div>{sumOfInstitute}</div>
                                        </div>


                                    </div>


                                    <div className="p-col-12 p-xl-4 nw-dataview-small-panel">
                                        <div>
                                            <div>Active Institute</div>
                                            <div>:</div>
                                            <div>{totalActiveInstituteList}</div>
                                        </div>

                                        <div>
                                            <div>Active Bill</div>
                                            <div>:</div>
                                            <div>{this.validatorUtility.currencyFormatter(totalActiveDue)}</div>
                                        </div>

                                        <div>
                                            <div>Inactive Institute</div>
                                            <div>:</div>
                                            <div>{totalInactiveInstituteList}</div>
                                        </div>

                                        <div>
                                            <div>Inactive Bill</div>
                                            <div>:</div>
                                            <div>{this.validatorUtility.currencyFormatter(totalInactiveDue)}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="nw-data-table">

                                {
                                    this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader()
                                    :
                                    <DataTable
                                        header={header}
                                        footerColumnGroup={footer}
                                        responsive={true}
                                        selectionMode="single"
                                        paginator={true}
                                        rows={10}
                                        value={this.state.dataTableValue}
                                    //selection={this.state.dataTableSelection}
                                    >
                                        <Column field="partnerNetiID" header="Neti ID" filter={true} style= {{width:"120px"}}/>
                                        <Column field="partnerName" header="Name" filter={true} />
                                        <Column field="" header="Mobile No/Area"  body={this.partnerDetailInfo.bind(this)} filter={true} style= {{width:"180px"}}/>
                                        <Column field="totalInstitute" header="Total Institute" style= {{width:"120px"}} />
                                        <Column field="" header="Active / Inactive" body={this.mbpActiveInstituteBody.bind(this)} />
                                        <Column field="" header="Active Due / Inactive Due" body={this.mbpDueActiveInstituteBody.bind(this)} />
                                        <Column field="details" header="Details" body={this.paidDetailsBody} style= {{width:"80px"}}/>

                                    </DataTable>
                                }
                                
                            </div>



                        </div>
                    </div>
                    
                        

                   </div>

               }


                


            </div>
        )
    }
}