import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { GUEST_POINT } from '../../utils/PointWiseRouteConsts';

export default class Registrationsuccessmessage extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                <div className="p-col-12 p-md-12">
                    <h2>Hello <b>{this.props.name}</b>,</h2>
                    <br />
                    <h3>Your registration is successful, Now you can login into Netiworld.Go to <span><Link to={GUEST_POINT.LOGIN}> Login </Link></span> Page.</h3>
                    <br />
                </div>
                <div className="p-col-12 p-md-12">
                    <hr />
                </div>
            </div>
        )
    }
}