import React, { Component } from 'react';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import { Dropdown } from 'primereact/dropdown';

// custom imports
import NetiContentLoader from '../../common/NetiContentLoader';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import CommonFuctionality from '../../common/CommonFuctionality';

let cmsId = '';
export class CommonClassGroupDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {

            dataIsLoading: false,
            classDropDownList: [],
            groupDropDownList: [],
            classId: '',
            groupId: '',
            classConfigStatusDetails: [],
            errors: {},

        }

        this.DwsService = new DwsService();
        this.CommonFuctionality = new CommonFuctionality();
        this.NetiFileHandler = new NetiFileHandler();
        this.NetiContentLoader = new NetiContentLoader();
        this.reloadCoreUrlIdFromLocal();
    }

    async reloadCoreUrlIdFromLocal() {

        cmsId = await this.DwsService.getCmsIdFromLocalStorage();
        console.log("cmsId .>", cmsId);

        if (typeof cmsId === 'string' || typeof cmsId === 'number') {
            this.getClassOrGroupListByDefaultCode('class', cmsId);
            this.getClassOrGroupListByDefaultCode('group', cmsId);
        }

    }

    getClassOrGroupListByDefaultCode(type, cmsId) {

        let { errors } = this.state;
        this.setState({ dataIsLoading: true, topProgressBar: true, errorMsgVisible: false });

        this.DwsService.cmsClassOrGroupList(type, cmsId)
            .then(res => {

                if (res.status == 200) {
                    return res.json().then((body) => {

                        if (type === 'class') {
                            this.setState({ classDropDownList: body.item });
                        }
                        else if (type === 'group') {
                            this.setState({ groupDropDownList: body.item });
                        }
                        errors['classError'] = '';
                        errors['groupError'] = '';
                        this.setState({ errors: errors })
                        this.setState({ dataIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                    });

                }
                else {
                    this.UserCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });

                }
            }).catch(error => {
                errors['classError'] = 'Class List Not Found'
                errors['groupError'] = 'Group List Not Found'
                this.setState({ errors: errors })
                this.setState({ dataIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });
            });
    }

    onSearchHandler = () => {

        let statusSearchObj = {
            cmsId: cmsId,
            classId: parseInt(this.state.classId),
            groupId: parseInt(this.state.groupId)
        };

        if (this.handleEmptyError()) {
            console.log('search clicked');
            this.getClassConfigInfoWithStatus(statusSearchObj);
            this.props.onSubmitSearch(this.state);

        }

    }

    getClassConfigInfoWithStatus(statusSearchObj) {
        this.setState({ dataIsLoading: true, topProgressBar: true, errorMsgVisible: false })
        this.DwsService.getAdmisiaClassConfigInfoWithStatus(statusSearchObj)
            .then(res => {
                console.log('status-res', res);

                if (res.status == 200) {
                    return res.json().then((body) => {
                        console.log('status-body', body);
                        this.setState({ classConfigStatusDetails: body.item, dataIsLoading: false, topProgressBar: false });
                        this.props.setClassConfigStatus(body.item);

                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            })
            .catch(error => {
                this.setState({ topProgressBar: false, dataIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })

            });
    }

    handleEmptyError = () => {

        let { errors } = this.state;
        let formIsValid = true;

        if (this.state.classId === '') {
            formIsValid = false;
            errors["classError"] = "Class can't left empty.";
        }
        if (this.state.groupId === '') {
            formIsValid = false;
            errors["groupError"] = "Group can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    render() {

        let { classDropDownList, groupDropDownList, classConfigStatusDetails } = this.state;

        let classOptions = [];
        let groupOptions = [];

        if (classDropDownList && classDropDownList.length) {
            classOptions = classDropDownList.map(item => ({ label: item.className, value: item.classId }));
        }

        if (groupDropDownList && groupDropDownList.length) {
            groupOptions = groupDropDownList.map(item => ({ label: item.groupName, value: item.groupId }));
        }

        return (
            <div className="p-fluid">

                <Growl ref={(el) => this.growl = el} />
                <div className="">

                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="nw-form-body">

                                <div className="p-col-12 p-xl-12">
                                    {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Class <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Class"
                                                value={this.state.classId}
                                                options={classOptions}
                                                onChange={(e) => this.setState({ classId: e.target.value })}
                                                filter={true}
                                                filterBy="label,value"
                                                autoWidth={false}
                                                name="classError"
                                            />
                                        </div>
                                    }
                                    <span className="error-message">{this.state.classId ? "" : this.state.errors["classError"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    {this.state.dataIsLoading ?
                                        this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">Group <span>*</span></span>
                                            <Dropdown
                                                placeholder="Select Group"
                                                value={this.state.groupId}
                                                options={groupOptions}
                                                onChange={(e) => this.setState({ groupId: e.target.value })}
                                                filter={true}
                                                filterBy="label,value"
                                                autoWidth={false}
                                                name="groupError"
                                            />
                                        </div>
                                    }
                                    <span className="error-message">{this.state.groupId ? "" : this.state.errors["groupError"]}</span>
                                </div>

                                <div className="p-col-12 p-xl-12">
                                    <span> AUTO APPROVE FOR ADMISSION : {classConfigStatusDetails.autoApprovedStatus === 0 ? <span style={{ color: 'red' }}>NO</span> : classConfigStatusDetails.autoApprovedStatus === 1 ? <span style={{ color: 'green' }}>YES</span> : ''}</span>
                                </div>
                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <Button
                                        className="p-button-primary nw-button nw-button-right"
                                        label="Search"
                                        icon="fas fa-check"
                                        onClick={() => this.onSearchHandler()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}