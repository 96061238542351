import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { PartnerPointService } from '../../../service/partnerPoint/PartnerPointService';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { pdfDownloadPortrait_TableOnly } from '../../common/JsPdfDownload';

let errors = {}
let maxDate = new Date();

export class EmInstituteWiseInvoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emBillGenerateLogIds: '',
            searchObjInfo: {
                fromDate: '',
                toDate: ''
            },
            viewDetailsObject: {

            },
            dataTableValue: [],
            tableView: false,
            errors: {}
        };

        this.PartnerPointService = new PartnerPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
        this.exportPdf = this.exportPdf.bind(this);
    }

    componentDidMount() {
        this.getInstituteWiseInvoiceList();
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    dueAmountBody = (rowData) => {
        return (<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData['totalDueAmount'])}</div>)
    }

    detailsBody = (rowData) => {
        let disabledbtn = ''
        if (rowData.currentDues.length != 0) {
            disabledbtn = false;
        } else {
            disabledbtn = true;
        }
        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-download"
                //disabled={disabledbtn}
                onClick={(e) => this.downloadInvoice(rowData)}
            />
        </div>
    }

    downloadInvoice = (rowData) => {
        var strBody = '';
        let emBillGenerateLogIds = '';
        rowData.currentDues.map(item => {
            console.log('item', item);
            strBody += `${item.generateBillId},`
        });
        if (strBody.length !== 0) {
            emBillGenerateLogIds = strBody.substr(0, strBody.length - 1)
        }
        this.PartnerPointService.downloadEdumanInvoice(rowData.emDetailsID, emBillGenerateLogIds)
    }

    getInstituteWiseInvoiceList = () => {
        this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.PartnerPointService.findEmInvoicesSummary()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].instituteStatus === 1) {
                                body[i].instituteStatus = 'Active';
                            } else {
                                body[i].instituteStatus = 'Inactive';
                            }
                        }
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    });
                } else {
                    this.PartnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
            });
    }

    exportPdf() {

        let pdfColumns = [
            { title: "Institute ID", dataKey: "instituteID" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Status", dataKey: "instituteStatus" },

            { title: "Type", dataKey: "billType" },

            { title: "Due Month", dataKey: "totalDueMonth" },
            { title: "Due Amount", dataKey: "totalDueAmount" },
        ]

        let columnStyle = {
            totalDueAmount : {halign : "right"}
        }

        pdfDownloadPortrait_TableOnly("Institute Wise Invoice List",75, pdfColumns, this.state.dataTableValue, columnStyle, "InstituteWise_Invoice_List.pdf");

    }

    render() {

        let { dataTableValue } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="header-title">
            <div className="header-title-left">
                Institute Wise Invoice Download
                            </div>

            <div className="header-title-right">
                <Link to="#">
                    Total Found: {dataTableValue.length.toLocaleString('EN-IN')}
                </Link>
            </div>
        </div>;

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="p-grid nw-form">

                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                            <div className="nw-data-table">
                                {

                                    this.state.dataTableIsLoading ?
                                        this.NetiContentLoader.MyPointTableLoader()
                                        : <div>
                                            <DataTable
                                                value={dataTableValue}
                                                header={tableHeader}
                                                responsive={true}
                                            >
                                                <Column field="instituteID" header="Institute ID" filter={true} />
                                                <Column field="instituteName" header="Institute Name" filter={true} />
                                                <Column field="instituteStatus" header="Status" filter={true} />
                                                <Column field="billType" header="Type" filter={true} />
                                                <Column field="totalDueMonth" header="Due Month" filter={true} />
                                                <Column field="totalDueAmount" header="Due Amount" body={this.dueAmountBody} filter={true} />
                                                <Column field="" header="Action" body={this.detailsBody} />
                                            </DataTable>
                                            <div className="nw-button-parent m-t-8 p-r-0">
                                                <Button
                                                    className="p-button p-button-primary nw-button nw-button-right"
                                                    label="Download PDF"
                                                    icon="fas fa-download"
                                                    onClick={this.exportPdf}
                                                />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}