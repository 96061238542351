import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { SeatInfoList } from './SeatInfoList';
import { UserCategoryService } from '../../../service/UserCategoryService';
import { DwsService } from '../../../service/dwsPoint/DwsService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { Link } from 'react-router-dom';
import { NetiCMSSetupProgress } from '../common/NetiCMSSetupProgress';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import CommonFuctionality from '../../common/CommonFuctionality';

let dialogStatus = false;
let errors = {};
let dropDownListName1 = '';
let dropDownListName2 = '';
let cloneStateBeforeMount;
let urlId;
export class AdminImportantLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTableValue: [],
            totalSeat: '',
            errors: {},
            homeReturnButton: false,
            dataTableIsLoading: false,
            reqObject: {
                cmsId: null,
                linkSerial: null,
                linkTitle: null,
                linkUrl: null,
                username: null,
                linkType : ''
            },
            visible: false,
            updateVisible: false,
            dialogLoad: false,
            deleteVisible: false,
            linkId: "",
            DropdownSelectedData : ''
        }

        this.UserCategoryService = new UserCategoryService();
        this.DwsService = new DwsService();
        this.NetiContentLoader = new NetiContentLoader();
        this.CommonFuctionality = new CommonFuctionality();

        this.reloadCoreUrlIdFromLocal();

    }

    //Admin dropdown Handler;
    adminDropDownDataHandler = (e)=>{
        let { reqObject, errors } = this.state;
        let value = e.target.value;
        reqObject.linkType = value;
        errors["linkType"] = '';
        this.setState({ reqObject, errors });
    }
    componentWillMount() {
        this.reloadCoreUrlIdFromLocal();
    }

    async reloadCoreUrlIdFromLocal() {
        urlId = await this.DwsService.getCmsIdFromLocalStorage();

        if (urlId) {
            this.setState({ homeReturnButton: false });
            this.fetchImportantLinks(urlId);
        } else {
            this.setState({ homeReturnButton: true });
        }
    }

    fetchImportantLinks = (urlId) => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        if (urlId) {
            this.DwsService.cmsFetchImportantLinks(urlId)
                .then(res => {
                    return res.json().then((body) => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false });
                        this.setState({ dataTableValue: body.item });
                        
                    });
                }

                )
        }
    }

    openDeleteDialog = (rowData) => {
        this.setState({ deleteVisible: true, dialogLoad: true, linkId: rowData.linkId });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    hideDeleteDialog = () => {
        this.setState({ deleteVisible: false, dialogLoad: false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }

    openDialog = (value, rowData) => {

        let { reqObject, errors } = this.state;
        reqObject.cmsId = '';
        reqObject.linkSerial = '';
        reqObject.linkTitle = '';
        reqObject.linkUrl = '';
        reqObject.username = '';

        /**************/
        errors.cmsId = '';
        errors.linkSerial = '';
        errors.linkTitle = '';
        errors.linkUrl = '';
        errors.username = '';
        if (value == "addLink") {
            reqObject.cmsId = urlId;
            reqObject.username = localStorage.getItem("user_name");
            this.setState({ visible: true, updateVisible: false, dialogLoad: true, reqObject });
    
        } else {
            this.setState({ visible: true, updateVisible: true, dialogLoad: true });
            reqObject.cmsId = urlId;
            reqObject.username = localStorage.getItem("user_name");
            this.onClickUpdate(rowData);

        }
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onClickUpdate = (rowData) => {;
        let { reqObject } = this.state;
        reqObject.linkId = rowData.linkId;
        reqObject.linkSerial = String(rowData.linkSerial);
        reqObject.linkTitle = rowData.linkTitle;
        reqObject.linkUrl = rowData.linkUrl;
        this.setState({ reqObject });

    }

    hideDialog = (value) => {
        if (value == 'updateImage') {
            this.setState({ visible: true, updateVisible: true, dialogLoad: true });
        } else {
            this.setState({ visible: false, updateVisible: false, dialogLoad: false });
            this.CommonFuctionality.blurDialogBackgroundDeActive();
        }
        //this.setState({ visible: false, dialogLoad: false });
    }

    onChangeSerialNo = (e) => {
        let { reqObject, errors } = this.state;
        let value = e.target.value;
        reqObject.linkSerial = value;
        errors["linkSerial"] = '';
        this.setState({ reqObject, errors });
    }

    onChangeLinkTitle = (e) => {
        let { reqObject, errors } = this.state;
        let value = e.target.value;
        reqObject.linkTitle = value;
        errors["linkTitle"] = '';
        this.setState({ reqObject, errors });
    }

    onChangeLinkUrl = (e) => {
        let { reqObject, errors } = this.state;
        let value = e.target.value;
        reqObject.linkUrl = value;
        errors["linkUrl"] = '';
        this.setState({ reqObject, errors });
    }

    actionTemplate = (rowData) => {
        let { reqObject } = this.state;
        // reqObject.linkId = rowData.linkId;
        //this.setState({reqObject});
        return (
            <div className="text-center">
                <span style={{ marginRight: '5px' }}>
                    <Button
                        type="button"
                        icon="fas fa-pencil-alt"
                        className="nw-action-button"
                        onClick={() => this.openDialog('updateLink', rowData)}
                        tooltip="Update"
                    />
                </span>

                <span>
                    <Button
                        type="button"
                        icon="fas fa-trash"
                        className="nw-action-button p-button-danger"
                        onClick={() => this.openDeleteDialog(rowData)}
                        tooltip="Delete"
                    />
                </span>

            </div>);
    }

    onclickSubmitBtn = (value) => {
        let { reqObject } = this.state;

        if (value == 'submit') {
            reqObject.linkSerial = this.state.dataTableValue.length + 1;
            this.setState({ reqObject });
        }

        if (this.errorHandler()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.DwsService.cmsInserImportantLink(reqObject, value)
                .then(res => {
                    if (res.status == 201) {
                        this.CommonFuctionality.blurDialogBackgroundDeActive();
                        this.setState({ topProgressBar: false, errorMsgVisible: false, visible: false, dialogLoad: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully Saved' });
                        this.fetchImportantLinks(urlId);
                    }
                    else if (res.status == 202) {
                        this.CommonFuctionality.blurDialogBackgroundDeActive();
                        this.setState({ topProgressBar: false, errorMsgVisible: false, visible: false, dialogLoad: false });
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully Update' });
                        this.fetchImportantLinks(urlId);
                    }
                    else {
                        this.setState({ topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'error', summary: 'error', detail: 'Unsuccessfully Saved' });
                    }

                }).catch(error => {
                    this.setState({ errors: errors })
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' });

                })
        }

    }

    deleteSubmit = () => {
        let linkId = this.state.linkId;
        if (linkId) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.DwsService.cmsDeleteImportantLink(linkId)
                .then(res => {
                    if (res.status == 202) {
                        this.setState({ topProgressBar: false, errorMsgVisible: false, deleteVisible: false, dialogLoad: false });
                        this.CommonFuctionality.blurDialogBackgroundDeActive();
                        this.growl.show({ severity: 'success', summary: 'Success', detail: 'Successfully Deleted' });
                        this.fetchImportantLinks(urlId);
                    }
                })
        }
    }

    errorHandler = () => {

        let { reqObject, errors, updateVisible } = this.state;
        let formIsValid = true;

        if (updateVisible) {

            if (reqObject.linkSerial === '' || reqObject.linkSerial === null) {
                formIsValid = false;
                errors["linkSerial"] = "Serial No. can't left empty.";
            }
        }

        if (!reqObject.linkTitle) {
            formIsValid = false;
            errors["linkTitle"] = "Title Title can't left empty.";
        }

        if (!reqObject.linkUrl) {
            formIsValid = false;
            errors["linkUrl"] = "Link Url Details can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    render() {
        let adminDropDownData = [{
            "value": "Important Link",
            "label": "Important Link"
            },
            {
                "value": "Yearly Working Plan",
                "label": "Yearly Working Plan"
            },
            {
                "value": "Information Rights",
                "label": "Information Rights"
            },
            {
                "value": "Integrity Strategy",
                "label": "Integrity Strategy"
            },
            {
                "value": "Mujib 100 Corner",
                "label": "Mujib 100 Corner"
            },
            {
                "value": "Golden Jubilee of Independence",
                "label": "Golden Jubilee of Independence"
            },
        ]

        let { reqObject, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody, updateVisible, errors } = this.state;
        let linkSerial = [
            { label: 'One', value: '1' },
            { label: 'Two', value: '2' },
            { label: 'Three', value: '3' },
            { label: 'Four', value: '4' },
            { label: 'Five', value: '5' },
        ];

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Link List</div>
            <div className="header-title-right">
                <a>Total Found: {dataTableValue.length.toLocaleString("EN-IN")}</a>

                <Button
                    className="nw-button nw-button-center p-button-success"
                    name="district"
                    label="Add New"
                    icon="fas fa-plus"
                    iconPos="right"
                    onClick={() => this.openDialog('addLink')}
                />
            </div>
        </div>;

        return (
            <div className="p-fluid">
                <NetiCMSSetupProgress />
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section blur-section">
                    {this.state.homeReturnButton === false ?

                        <div className="nw-data-table">
                            {!this.state.dataTableIsLoading ?
                                <DataTable
                                    value={this.state.dataTableValue}
                                    selectionMode="single"
                                    header={tableHeader}
                                    responsive={true}
                                    rows={10}
                                    paginator={true}
                                    columnResizeMode="fit"
                                >
                                    <Column field="linkSerial" header="Serial No." filter={true} />
                                    <Column field="linkType" header="Link Type" filter={true} />
                                    <Column field="linkTitle" header="Link Title" filter={true} />
                                    <Column field="linkUrl" header="Link URL" filter={true} />
                                    <Column field="action" header="Action" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                </DataTable>
                                : this.NetiContentLoader.MyPointTableLoader()
                            }
                        </div>
                        :
                        <div className="">
                            <div className="p-grid">
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <Link
                                            to="/home"
                                            className="rainbow-button"
                                            alt="Go DWS Home"
                                        />
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-3">
                                    <center className="custom-center">
                                        <i class="layout-menuitem-icon fas fa-arrow-right"></i>
                                    </center>
                                </div>
                                <div className="card p-col-12 p-xl-6">
                                    <img src="assets/images/dws_home.png" width="100%" />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="dialog-section">
                    <Dialog
                        header={updateVisible ? 'Update Important Link' : 'Add Important Link'}
                        visible={this.state.visible}
                        modal={true}
                        onHide={() => this.hideDialog('uploadImage')}
                        className="nw-dialog"
                        maximizable
                    >
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <div className="p-col-12">
                                    <div className="formControl">

                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Serial No.</span>

                                            {updateVisible ?
                                                <InputText
                                                    placeholder="Enter Serial no."
                                                    name="linkSerial"
                                                    value={reqObject.linkSerial}
                                                    onChange={e => this.onChangeSerialNo(e)}
                                                />
                                                :

                                                <div className="nw-inputgroup-desc">
                                                    {reqObject.linkSerial || this.state.dataTableValue.length + 1}
                                                </div>
                                            }
                                        </div>
                                        {updateVisible ? <span className="error-message">{errors["linkSerial"]}</span> : ''}

                                    </div>
                                    {/* 
                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Serial No. <span>*</span>
                                            </span>
                                            <Dropdown
                                                id="linkSerial"
                                                placeholder="Select Link Serial"
                                                value={reqObject.linkSerial}
                                                options={linkSerial}
                                                autoWidth={false}
                                                onChange={(e) => this.onChangeSerialNo(e)}
                                                name="linkSerial"
                                            />
                                        </div>
                                        <span className='error-message'>{errors['linkSerial']}</span>
                                    </div> */}

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Title <span>*</span>
                                            </span>
                                            <InputText
                                                id="linkTitle"
                                                value={reqObject.linkTitle}
                                                onChange={(e) => this.onChangeLinkTitle(e)}
                                                placeholder="Enter Link Title"
                                                name="linkTitle"
                                            />
                                        </div>
                                        <span className='error-message'>{errors['linkTitle']}</span>
                                    </div>
                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Link URL <span>*</span>
                                            </span>
                                            <InputText
                                                id="linkUrl"
                                                value={reqObject.linkUrl}
                                                onChange={(e) => this.onChangeLinkUrl(e)}
                                                placeholder="Enter Link URL"
                                                name="linkUrl"
                                            />
                                        </div>
                                        <span className='error-message'>{errors['linkUrl']}</span>
                                    </div>

                                    <div className="formControl">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputgroup-label">
                                                Link Type <span>*</span>
                                            </span>
                                            <Dropdown 
                                                placeholder={"Select Link Type"}
                                                showClear={true}
                                                autoWidth={false}
                                                options={adminDropDownData}
                                                onChange={this.adminDropDownDataHandler}
                                                value={this.state.reqObject.linkType}
                                            />

                                        </div>
                                        <span className='error-message'>{errors['linkUrl']}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent-multiple ">
                                        <Button
                                            className="p-button-danger nw-button nw-button-multiple"
                                            label="Discard"
                                            icon="fas fa-times"
                                            onClick={() => this.hideDialog()}
                                        />

                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            name="district"
                                            label={updateVisible ? 'Update' : 'Save'}
                                            icon="fas fa-check"
                                            onClick={() => this.onclickSubmitBtn(updateVisible ? 'update' : 'submit')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header="Delete Important Link"
                        visible={this.state.deleteVisible}
                        modal={true}
                        onHide={() => this.hideDeleteDialog()}
                        className="nw-confirm-dialog"
                        maximizable
                    >
                        <div className="p-fluid">
                            <div className="p-col-12 p-xl-12">
                                <div className="confirm-wrapper">
                                    <h3 className="text-center">Are you want to delete?</h3>
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-col-12 p-xl-12 nw-button-parent-multiple">
                                            <Button
                                                label='No'
                                                icon='fas fa-times'
                                                className="p-button p-button-danger nw-button nw-button-multiple"
                                                onClick={() => this.hideDeleteDialog()}
                                            />
                                            <Button
                                                label='Yes'
                                                icon='fas fa-check'
                                                className="p-button p-button-primary nw-button nw-button-multiple"
                                                onClick={() => this.deleteSubmit()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }
}