import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import NetiContentLoader from '../../common/NetiContentLoader';
import { BlankDataTableAnim } from '../../common/BlankDataTableAnim';
import { ValidatorUtility } from '../../../utils/ValidatorUtility';
import { Workbook } from 'react-excel-workbook';
import { SplitButton } from 'primereact/splitbutton';
import { pdfDownloadLandscape_TableWithSubTitle } from '../../common/JsPdfDownload';

let errors={}
let maxDate = new Date();
// pdf
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";
export class CRMAdvanceBill extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [
                { label: 'PDF', icon: 'far fa-file-pdf', command: (e) => this.exportPdf() },
                { label: 'Excel', icon: 'far fa-file-excel', command: (e) => { this.exportExcel() } },
            ],
            advanceBillObject:{
                fromDate: '',
                toDate: ''
            },
            viewDetailsObject:{

            },
            dataTableValue: [],
            tableView: false,
            errors: {}
        };

        this.CRMPointService = new CRMPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
    }

    onChangeStartDate = (e) => {
        let { advanceBillObject } = this.state;
        advanceBillObject.fromDate = e.target.value
        this.setState({ advanceBillObject });
        this.clearDateError(e.target.name);

    }

    onChangeEndDate = (e) => {
        let { advanceBillObject } = this.state;
        advanceBillObject.toDate = e.target.value
        this.setState({ advanceBillObject });
        this.clearDateError(e.target.name);
    }

    clearDateError = (name) => {
        let { errors } = this.state;
        errors[name] = ''
        this.setState({ errors })
    }

    paymentDateBody = (rowData) =>{
        return NetiDateUtils.getAnyShortForm(rowData.paymentDate, "DD-MMM-YYYY hh:mm:ss a");
    }

    amountBody = (rowData) =>{
        return(<div className="col-number text-right"> {this.ValidatorUtility.currencyFormatter(rowData['paymentAmount'])}</div>)
    }

    detailsBody = (rowData) =>{
        return <div className='text-center'>
            <Button
                className="nw-action-button note"
                icon="fas fa-info"
                title='Click to show notes'
                tooltip="View Details"
                tooltipOptions={{position: 'top'}}
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    viewDialog = (rowData) => {
        console.log("Notes", rowData);
        this.setState({ visible: true, viewDetailsObject: rowData });
    }

    onHide = (event) => {
        this.setState({ visible: false });
    }







    handleError = () =>{
        let { errors, advanceBillObject } = this.state;
        let formIsValid = true;

        if (advanceBillObject.fromDate === '') {
            formIsValid = false;
            errors["fromDate"] = "Start Date can't left empty.";
        }
        if (advanceBillObject.toDate === '') {
            formIsValid = false;
            errors["toDate"] = "End Date can't left empty.";
        }

        this.setState({ errors });
        return formIsValid;
    }

    onSubmitHandler = (e, props) => {

        let { advanceBillObject } = this.state;
        if (this.handleError()) {
            this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(advanceBillObject.toDate) >= Date.parse(advanceBillObject.fromDate)) {
                let requestObj={
                    "startDate": advanceBillObject.fromDate,
                    "endDate":advanceBillObject.toDate
                }

                this.CRMPointService.getDateWiseAdvanceBill(requestObj)
                    .then((res) => {
                        if (res.status == 302) {
                            return res.json().then((body) => {

                                for (let i = 0; i < body.length; i++) {
                                    body[i]['paymentDateSearch'] = NetiDateUtils.getAnyShortForm(body[i].paymentDate, "DD-MMM-YYYY hh:mm:ss a");
                                    body[i]['instituteId'] = body[i].emDetailsInfoDTO.instituteId;
                                    body[i]['instituteName'] = body[i].emDetailsInfoDTO.instituteName;
                                    body[i]['customNetiID'] = body[i].userBasicInfoDTO.customNetiID.toString();
                                    body[i]['fullName'] = body[i].userBasicInfoDTO.fullName;



                                       



                                    // if (body[i].paymentDate == null) {
                                    //     body[i].paymentDateSearch = '';
                                    // } else {
                                    //     body[i].paymentDateSearch = NetiDateUtils.getAnyShortForm(body[i].paymentDate, "DD-MMM-YYYY hh:mm:ss a");
                                    // }
                                }

                                this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                            });
                        } else {
                            this.CRMPointService.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(error => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection!!!" })
                    });

            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be ahead of To Date." });
            }

        }

    }

    exportPdf = () => {

        let pdfColumns = [
            { title: "Payment Date", dataKey: "paymentDateSearch" },
            { title: "Institute ID", dataKey: "instituteId" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Neti ID", dataKey: "customNetiID" },
            { title: "Name", dataKey: "fullName" },
            { title: "Payment Type", dataKey: "paymentType" },
            { title: "Amount", dataKey: "paymentAmount" },

        ]

        let tableColStyle = { paymentAmount: { halign: 'right' } }

        pdfDownloadLandscape_TableWithSubTitle("Advance Bill Payment",  113, pdfTitleHeader,pdfTitleHeaderData, pdfColumns,this.state.dataTableValue, tableColStyle, "Advance_Bill_Payment.pdf");

    }

    exportExcel = () => {
        document.getElementById("clickExcelBtn").click();
    }

    exportExcelLog = () => { console.log('excel downloaded'); }

    render() {
        let { advanceBillObject, viewDetailsObject, dateRangeParamFormat, dataTableValue, errors } = this.state
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                                Advance Bill Payment Reports
                            </div>
                            <div className="header-title-right">
                                <a>Total Found: { dataTableValue.length } </a>
                            </div>
                        </div>;

 // pdf related task
 let formatedStartDate = '';
 let formatedEndDate = '';
 if (advanceBillObject.fromDate && advanceBillObject.toDate) {
     formatedStartDate = (advanceBillObject.fromDate.toLocaleDateString('en-GB'));
     formatedEndDate = (advanceBillObject.toDate.toLocaleDateString('en-GB'));
 }
 pdfTitleHeader = [
     { dataKey: "A" },
     { dataKey: "B" },
     { dataKey: "C" },
     { dataKey: "D" }
 ];
 pdfTitleHeaderData = [
     { A: "Start Date", B: formatedStartDate, C: "End Date", D: formatedEndDate }
 ];
 // pdf related task end

 // excel
 let excelColumns = "";
 let excelFileName = formatedStartDate + ' to ' + formatedEndDate + "_Advance Bill Payment.xlsx";
 let excelSheetName = "Advance_Bill_Payment";
 excelColumns = [
     <Workbook.Column value="paymentDateSearch" label="Payment Date" />,
     <Workbook.Column value="instituteId" label="Institute ID" />,
     <Workbook.Column value="instituteName" label="Institute Name" />,
     <Workbook.Column value="customNetiID" label="Neti ID" />,
     <Workbook.Column value="fullName" label="Name" />,
     <Workbook.Column value="paymentType" label="Payment Type" />,
     <Workbook.Column value="paymentAmount" label="Amount" />,

 ];
 // end excel


        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid nw-form-body">

                                <div className="p-col-12">
                                    
                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">Start Date <span>*</span></span>
                                        <Calendar
                                            value={advanceBillObject.fromDate}
                                            onChange={this.onChangeStartDate}
                                            showIcon={true}
                                            name="fromDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Start Date"
                                        />
                                    </div>
                                    <span className="error-message">{errors["fromDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-5">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon nw-inputgroup-small-span">End Date <span>*</span></span>
                                        <Calendar
                                            value={advanceBillObject.toDate}
                                            onChange={this.onChangeEndDate}
                                            showIcon={true}
                                            name="toDate"
                                            yearRange="2010:2030"
                                            dateFormat="dd/mm/yy"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            dateOnly="true"
                                            maxDate={maxDate}
                                            placeholder="Select Date"
                                        />
                                    </div>
                                    <span className="error-message">{errors["toDate"]}</span>

                                </div>
                                <div className="p-col-12 p-lg-12 p-xl-2 nw-button-parrent">
                                    <Button
                                        className="p-button p-button-primary nw-button nw-button-center"
                                        label="Search"
                                        icon="fas fa-search"
                                        onClick={this.onSubmitHandler}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="p-col-12 p-xl-12 p-col-nogutter">
                            <div className="nw-data-table">
                                {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ?
                                            this.NetiContentLoader.MyPointTableLoader()
                                            :
                                            <>
                                            <DataTable
                                                value={dataTableValue}
                                                header={tableHeader}
                                                responsive={true}
                                                paginator={true}
                                                rows={15}
                                            >
                                                <Column field="paymentDateSearch" header="Payment Date" body={ this.paymentDateBody } filter={true} />
                                                <Column field="instituteId" header="Institute ID" filter={true} />
                                                <Column field="instituteName" header="Institute Name" filter={true} />
                                                <Column field="customNetiID" header="Neti ID" filter={true} />
                                                <Column field="fullName" header="Name" filter={true} />
                                                <Column field="paymentType" header="Payment Type" filter={true} />
                                                {/* <Column field="paymentDetails" header="Details" filter={true} /> */}
                                                <Column field="paymentAmount" header="Amount" body={this.amountBody} filter={true} />
                                                <Column field="" header="Action" body={ this.detailsBody } filter={true} />
                                            </DataTable>
                                              <div className="p-col-12 p-xl-12 nw-button-parent">

                                              <SplitButton className="p-button-primary nw-split-button" label="Download" icon="fas fa-download" model={this.state.items}></SplitButton>
                                              
                                              <div style={{ display: 'none' }}>
                                              
                                                  <Workbook
                                                      filename={excelFileName}
                                                      element={
                                                          <Button
                                                              label="Download Excel"
                                                              id="clickExcelBtn"
                                                              icon="fas fa-download"
                                                              className="p-button p-button-primary nw-button nw-button-right"
                                                          />
                                                      }
                                                  >
                                                      <Workbook.Sheet data={this.state.dataTableValue} name={excelSheetName}>
                                                          {excelColumns}
                                                      </Workbook.Sheet>
                                                  </Workbook>
                                              
                                              </div>
                                              
                                              </div >
                                              </>
                                        :
                                        <BlankDataTableAnim />
                                }
                            </div>
                          
                        </div>
                    </div>
                </div>

                <div className="dialog-section">
                    <Dialog header="Bill Info" visible={this.state.visible} className="nw-dialog" onHide={this.onHide} closable>
                        <div className="nw-search-view no-border p-0">
                            <div>
                                <div className="p-col-12 p-xl-12">
                                    <br />
                                    <div className="custom-header">
                                        <i className="fa fa-wallet"></i>
                                        <h1>Advance Bill Details Info</h1>
                                    </div>
                                </div>
                                <br />
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Payment Date</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{ NetiDateUtils.getAnyShortForm( viewDetailsObject.paymentDate, "DD-MMM-YYYY") || "---" }</div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Institute ID</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { viewDetailsObject.emDetailsInfoDTO && viewDetailsObject.emDetailsInfoDTO.instituteId || "---" }
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Institute Name</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { viewDetailsObject.emDetailsInfoDTO && viewDetailsObject.emDetailsInfoDTO.instituteName || "---" }
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Institute Address</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { viewDetailsObject.emDetailsInfoDTO && viewDetailsObject.emDetailsInfoDTO.instituteAddress || "---" }
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Phone No.</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { viewDetailsObject.emDetailsInfoDTO && viewDetailsObject.emDetailsInfoDTO.instituteContact || "---" }
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Email</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { viewDetailsObject.emDetailsInfoDTO && viewDetailsObject.emDetailsInfoDTO.instituteEmail || "---" }
                                        </div>
                                    </div>
                                </div>
                                <br/>

                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Neti ID</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { viewDetailsObject.userBasicInfoDTO && viewDetailsObject.userBasicInfoDTO.customNetiID || "---" }
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Name</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">
                                            { viewDetailsObject.userBasicInfoDTO && viewDetailsObject.userBasicInfoDTO.fullName || "---" }
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Description</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{ viewDetailsObject.paymentDetails || "---" }</div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-xl-12">
                                    <div className="p-inputgroup">
                                        <span className="task-badge found"></span>
                                        <span className="p-inputgroup-addon">Amount</span>
                                        <span className="p-inputgroup-colon">:</span>
                                        <div className="nw-inputgroup-desc">{ viewDetailsObject.paymentAmount || "---" }</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }

}