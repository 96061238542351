import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import NetiContentLoader from '../common/NetiContentLoader';
import { Button } from "primereact/button";

import { pdfDownloadPortrait_TableWithFooter, pdfDownloadPortrait_FullTable } from '../common/JsPdfDownload';

let angle = '';
export class EmPostPaidBillSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableValue: [],
            dataTableIsLoading: false
        }

        this.NetiContentLoader = new NetiContentLoader();
        this.exportPdf = this.exportPdf.bind(this);

    }

    onChangeDueAmount = (rowData) => {
        return  (<div className="col-number text-right"> {(rowData['payableAmount'] - rowData['paidAmount'])}</div>)
    }

    payableAmount = (rowData) => {
        return (<div className="col-number text-right"> {rowData['payableAmount']}</div>)
    }

    paidAmount = (rowData) => {
        return (<div className="col-number text-right"> {rowData['paidAmount']}</div>)
    }

    exportPdf(){

    let pdfSubTitleColumn = [
      { dataKey: "A" },
      { dataKey: "B" },
    ];
   let  pdfSubTitleList = [
      { A: "Payable Institute", B: this.props.payableInstitute, }
    ];

      let tableColumns = [
        { title: "Year", dataKey: "year" },
        { title: "Month", dataKey: "month" },
        { title: "Payable Amount", dataKey: "payableAmount" },
        { title: "PaidAmount", dataKey: "paidAmount" },
        { title: "Due Amount", dataKey: "dueAmount" }
      ];
  
      let totalMonth = this.props.billTotalMonth + " Months";
      var footerDataList = [
        {
          id: "1",totalPdf: totalMonth, totalAmount1: this.props.totalPayableAmount, totalAmount2: this.props.totalPaidAmount, totalAmount3: this.props.totalDueAmount
        }
      ];
      let footerColumns = [{ dataKey: "totalPdf" }, { dataKey: "totalAmount1" }, { dataKey: "totalAmount2" }, { dataKey: "totalAmount3" } ];
  
      let footerColumnStyles = {
        totalPdf: { halign: "right", fontSize: 10, columnWidth: 44, fontStyle: "bold", overflow: "linebreak" },
        totalAmount1: { halign: "right", fontStyle: "bold", fontSize: 10, columnWidth: 52.8 },
        totalAmount2: { halign: "right", fontSize: 10, fontStyle: "bold", columnWidth: 41.3 },
        totalAmount3: { halign: "right", fontSize: 10, fontStyle: "bold", columnWidth: 42.7 },
      }
      
      pdfDownloadPortrait_FullTable("Eduman Post-Paid Bill Report", pdfSubTitleColumn, pdfSubTitleList, tableColumns, this.props.postpaidBillList, footerColumns, footerDataList,footerColumnStyles, "EM_Post-Paid_Bill.pdf");
  
    }
    
    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Bill Summary
                        </div>;
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="" />
                <Column footer={this.props.billTotalMonth ? `${this.props.billTotalMonth} Months` : ''} />
                <Column footer={this.props.totalPayableAmount} />
                <Column footer={this.props.totalPaidAmount} />
                <Column footer={this.props.totalDueAmount} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                <Growl ref={(el) => this.growl = el} />

                <div className="nw-data-table">
                    {this.state.dataTableIsLoading ? this.NetiContentLoader.MyPointTableLoader() :
                < div className="p-col-12 p-xl-12">
                  <DataTable
                    footerColumnGroup={footerGroup}
                    value={this.props.postpaidBillList}
                    selectionMode="single"
                    header={tableHeader}
                    responsive={true}
                    rows={10}
                    columnResizeMode="fit"
                  >
                    <Column field="year" header="Year" />
                    <Column field="month" header="Month" />
                    <Column field="payableAmount" body={this.payableAmount} header="Payable Amount" />
                    <Column field="paidAmount" body={this.paidAmount} header="Paid Amount" />
                    <Column field="dueAmount" header="Due Amount" body={this.onChangeDueAmount} />
                  </DataTable>

                  <div className="nw-button-parent m-t-8 p-r-0">
                    <Button
                      className="p-button p-button-primary nw-button nw-button-right"
                      label="Download"
                      icon="fas fa-download"
                      onClick={this.exportPdf}
                    />
                  </div>
                </div>
                    }
                </div>
            </div >
        );
    }
}