import React, { Component } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import { SupportPointService } from "../../../service/supportPoint/SupportPointService";
import { Dropdown } from "primereact/dropdown";
import NetiContentLoader from "../../common/NetiContentLoader";
import { ErrorMessageView } from "../../common/ErrorMessageView";
import { UserCategoryService } from "../../../service/UserCategoryService";
import { UserOtherAccessService } from "../../../service/UserOtherAccessService";
import CommonFuctionality from "../../common/CommonFuctionality";
import { NetiFileHandler } from "../../../utils/NetiFileHandler";

let taggingTypeList = [
  { label: "Balance Withdraw", value: "C123001" },
  { label: "Eduman Fee", value: "C123002" },
  { label: "Admisia Withdraw", value: "C123003" }
];

export class LinkBankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankList: [],
      bankAccountInfo: {},
      accountTaggingList: [],
      bankTaggingInfo: {},
      taggingType: "",
      bankName: "",
      bankAccountSelection: {},
      accountType: "",
      accountDetails: "",
      dataTableValue: [],
      userBankDetails: {},
      errors: {},
      updateErrors: {},
      firstDropDownIsLoading: true,
      secondDropdownIsLoading: false,
      errorMsgVisible: false,
      topProgressBar: false,
      errorMsgBody: ""
    };

    this.fetchBankList = this.fetchBankList.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);

    this.supportPointService = new SupportPointService();
    this.NetiContentLoader = new NetiContentLoader();
    this.userCategoryService = new UserCategoryService();
    this.commonFuctionality = new CommonFuctionality();
    this.userOtherAccessService = new UserOtherAccessService();
    this.netiFileHandler = new NetiFileHandler();
  }

  componentDidMount() {
    this.fetchBankList();
    this.fetchTaggedBankAccountList();
  }

  // getBankAccountInfo(param) {
  //   this.setState({
  //     topProgressBar: true,
  //     errorMsgVisible: false
  //   });
  //   this.userOtherAccessService
  //     .getUserBankAccountsInfo(param)
  //     .then(res => {
  //       if (res.status == 302) {
  //         return res.json().then(body => {
  //           this.setState({
  //             accountInfo: body,
  //             topProgressBar: false,
  //           });
  //         });
  //       } else {
  //         this.userOtherAccessService.Auth.handleErrorStatus(res).then(resp => {
  //           this.setState({
  //             topProgressBar: false,
  //             errorMsgVisible: true,
  //             errorMsgBody: resp
  //           });
  //         });
  //       }
  //     })
  //     .catch(error => {
  //       this.setState({
  //         topProgressBar: false,
  //         errorMsgVisible: true,
  //         errorMsgBody: "Please check your connection !!!"
  //       });
  //     });
  // }

  fetchBankList() {
    this.setState({
      firstDropDownIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false
    });
    this.userOtherAccessService
      .findBankAccountByUser()
      .then(res => {
        if (res.status === 200) {
          this.setState({
            firstDropDownIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false
          });
          return res.json().then(body => {
            this.setState({ bankList: body });
          });
        } else {
          this.userOtherAccessService.Auth.handleErrorStatus(res)
          .then((resp) => {
              this.setState({  firstDropDownIsLoading: false,topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
          });
        }
      })
      .catch(error => {
        this.setState({
          firstDropDownIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load. Please check your connection."
        });
      });
  }

  onChangebankName = e => {
    let {bankList} = this.state;
    this.setState({ bankName: e.target.value, errors: {} });
    for (let i = 0; i < bankList.length; i++) {
      if (bankList[i].userBankAccountID == e.target.value) {
          this.setState({ bankAccountInfo:bankList[i] });
      }
  }
  };

  onSubmitErrorHandle = () => {
    let { errors } = this.state;
    let formIsValid = true;

    if (this.state.taggingType === "") {
      formIsValid = false;
      errors["taggingType"] = "Tagging Type can't left empty.";
    }
    if (this.state.bankName === "") {
      formIsValid = false;
      errors["bankName"] = "Bank Name can't left empty.";
    }
    this.setState({ errors });
    return formIsValid;
  };

  onChangeTaggingType = e => {
    this.setState({ taggingType: e.target.value, errors: {}  });
  };

  onSubmitHandler() {
    let {bankAccountInfo} = this.state;
    if (this.onSubmitErrorHandle()) {
      this.setState({ topProgressBar: true, errorMsgVisible: false });
      let requestedObj = {
        userBasicInfoDTO: {
          netiID: bankAccountInfo.netiID
        },
        userBankAccountTaggingDTO: {
          userBankAccountInfoDTO: {
            userBankAccId: this.state.bankName
          }
        },
        taggingTypeDefaultCode: this.state.taggingType
      };
      this.userOtherAccessService
        .tagUserBankAccount(requestedObj)
        .then(res => {
          if (res.status === 201) {
            this.growl.show({
              severity: "success",
              summary: "Success",
              detail: "Successfully submitted."
            });
            this.fetchTaggedBankAccountList();
            this.setState({
              bankName: "",
              taggingType: "",
              accountInfo: [],
              bankAccountSelection: {}
            });
            this.setState({ topProgressBar: false, errorMsgVisible: false });
          } else {
            this.userOtherAccessService.Auth.handleErrorStatus(res)
            .then((resp) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
            });
          }
        })
        .catch(error => {
          this.setState({
            topProgressBar: false,
            errorMsgVisible: true,
            errorMsgBody: "Please check your connection."
          });
        });
    }
  }

  fetchTaggedBankAccountList = () => {
    this.setState({
      dataTableIsLoading: true,
      topProgressBar: true,
      errorMsgVisible: false
    });
    this.userOtherAccessService
      .getUserBankTaggedList()
      .then(res => {
        if (res.status === 302) {
          return res.json().then(body => {
            this.setState({ dataTableValue: body });
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: false
            });
          });
        } else {
          this.userOtherAccessService.Auth.handleErrorStatus(res).then(resp => {
            this.setState({
              dataTableIsLoading: false,
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp
            });
          });
        }
      })
      .catch(error => {
        this.setState({
          dataTableIsLoading: false,
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load. Please check your connection."
        });
      });
  };

  getUserBankAccountTaggingInfo = param => {
    this.setState({ topProgressBar: true, errorMsgVisible: false });
    this.userOtherAccessService
      .userBankTaggingInfo(param)
      .then(res => {
        if (res.status === 302) {
          return res.json().then(body => {
            this.setState({ bankTaggingInfo: body });
            this.setState({ topProgressBar: false, errorMsgVisible: false });
          });
        } else {
          this.userOtherAccessService.Auth.handleErrorStatus(res).then(resp => {
            this.setState({
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp
            });
          });
        }
      })
      .catch(error => {
        this.setState({
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load. Please check your connection."
        });
      });
  };

  fetchBankBranchesList(param1, param2) {
    this.setState({
      topProgressBar: true,
      errorMsgVisible: false
    });
    this.userOtherAccessService
      .findAllBankBranches(param1, param2)
      .then(res => {
        if (res.status === 302) {
          return res.json().then(body => {
            this.setState({ branchList: body });
            this.setState({
              topProgressBar: false,
              errorMsgVisible: false
            });
          });
        } else {
          this.userOtherAccessService.Auth.handleErrorStatus(res).then(resp => {
            this.setState({
              topProgressBar: false,
              errorMsgVisible: true,
              errorMsgBody: resp
            });
          });
        }
      })
      .catch(error => {
        this.setState({
          topProgressBar: false,
          errorMsgVisible: true,
          errorMsgBody: "Unable to load. Please check your connection."
        });
      });
  }

  onChangeBankAccountSelection = e => {
    this.setState({ bankAccountSelection: e.value });
  };

  render() {
    let {
      topProgressBar,
      errorMsgVisible,
      errorMsgBody,
      userBankDetails,
      bankTaggingInfo,
      accountInfo,
      dataTableValue
    } = this.state;
    let bankOptions = [];
    if (this.state.bankList && this.state.bankList.length) {
      bankOptions = this.state.bankList.map(item => ({
        //label: `${item.bank} (${item.branchName} - ${item.accNumber}) `,
        label: `${item.bank} (${item.accNumber}) `,
        value: item.userBankAccountID
      }));
    }

    let dataTableHeader = <div className="header-title">
        <div className="header-title-left">
        Account Info
        </div>
        <div className="header-title-right">
            <a>
                Total Found: {dataTableValue.length.toLocaleString("EN-IN")}
            </a>
        </div>
    </div>;

    return (
      <div className="p-fluid">
        {topProgressBar ? (
          <ErrorMessageView topProgressBar={topProgressBar} />
        ) : null}
        {errorMsgVisible ? (
          <ErrorMessageView
            errorMsgVisible={errorMsgVisible}
            errorMsgBody={errorMsgBody}
          />
        ) : null}
        <Growl ref={el => (this.growl = el)} />
        <div className="p-grid nw-form">
          <div className="p-col-12 p-xl-12">
            <div className="nw-form-body">
              <div
                className="p-col-12 p-xl-12"
                style={{ paddingBottom: "0px" }}
              >
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                    Using Type<span>*</span>
                    </span>
                    <Dropdown
                      placeholder="Select Type"
                      onChange={this.onChangeTaggingType}
                      value={this.state.taggingType}
                      options={taggingTypeList}
                      filter={true}
                      autoWidth={false}
                      name="taggingType"
                      showClear={true}
                    />
                  </div>
                <span className="error-message">
                  {this.state.errors["taggingType"]}
                </span>
              </div>

              <div className="p-col-12 p-xl-12">
                {this.state.firstDropDownIsLoading  ? (
                  this.NetiContentLoader.normalFormInputField()
                ) : (
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      Bank Name <span>*</span>
                    </span>
                    <Dropdown
                      placeholder="Select Bank Name"
                      options={bankOptions}
                      name="bankName"
                      onChange={this.onChangebankName}
                      value={this.state.bankName}
                      filter={true}
                      autoWidth={false}
                    />
                  </div>
                )}
                <span className="error-message">
                  {this.state.errors["bankName"]}
                </span>
              </div>

              {/* <div className="p-col-12 p-xl-10">
                <div className="p-fluid">
                  <div className="p-grid">
                    <div className="p-col-12 p-xl-12">
                      <div className="card card-w-title">
                        <DataTable
                          value={this.state.accountInfo}
                          header={headervalue}
                          selection={this.state.bankAccountSelection}
                          onSelectionChange={this.onChangeBankAccountSelection}
                          responsive={true}
                          rows={10}
                          columnResizeMode="fit"
                        >
                          <Column
                            selectionMode="single"
                            style={{ width: "4em" }}
                          />
                          <Column
                            field="coreBankBranchInfoDTO.branchName"
                            header="Branch Name"
                          />
                          <Column
                            field="bankAccNumber"
                            header="Account Number"
                          />
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="p-col-12 p-xl-12" />
              <div className="p-col-12 p-xl-12 nw-button-parent">
                <div className="required-field">
                  (<span>*</span>) required fields
                </div>
                <Button
                  className="p-button p-button-primary nw-button nw-button-right"
                  label="Tag"
                  icon="fas fa-check"
                  onClick={this.onSubmitHandler}
                />
              </div>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-lg-3 p-xl-3"></div>
          <div className="p-col-12 p-xl-12">
            <div className="nw-data-table nw-data-table-tabview">
              {this.state.dataTableIsLoading ?
                this.NetiContentLoader.MyPointTableLoader() :
                <DataTable
                  header={dataTableHeader}
                  value={this.state.dataTableValue}
                  responsive={true}
                  paginator={true}
                  rows={10}
                >
                  <Column field="taggingTypeCoreCategoryInfoDTO.categoryName" header="Tagged Type" sortable={true} filter={true} />
                  <Column field="userBankAccountInfoDTO.coreBankBranchInfoDTO.coreBankInfoDTO.categoryName" header="Bank Name" sortable={true} filter={true} />
                  <Column field="userBankAccountInfoDTO.coreBankBranchInfoDTO.branchName" header="Branch Name" sortable={true} filter={true} />
                  <Column field="userBankAccountInfoDTO.bankAccHolderName" header="Account Holder" sortable={true} filter={true} />
                  <Column field="userBankAccountInfoDTO.bankAccNumber" header="Account Number" sortable={true} filter={true} />
                </DataTable>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
