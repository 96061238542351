import React, { useState, useEffect, useRef } from "react";
import { Tree } from "primereact/tree";
import { Growl } from "primereact/growl";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Skeleton from "react-loading-skeleton";
import { UserCoreUrlInfoService } from "../../../service/UserCoreUrlInfoService";
import { DwsService } from "../../../service/dwsPoint/DwsService";
import AuthService from "../../../auth/AuthService";
import { ErrorMessageView } from "../../common/ErrorMessageView";

const CmsMenuConfig = () => {
  const [nodes, setNodes] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState(null);

  const [cmsInfoId, setcmsInfoId] = useState(null);

  const [assignUserId, setassignUserId] = useState(null);
  const [topProgressBar, settopProgressBar] = useState(false);
  const [errorMsgBody, seterrorMsgBody] = useState(false);
  const [errorMsgVisible, seterrorMsgVisible] = useState(false);

  const userCoreUrlInfoService = new UserCoreUrlInfoService();
  const dwsService = new DwsService();

  const authService = new AuthService();

  const getChildNodes = (node) => {
    return {
      ...node,
      key: node.itemId,
      label: node.name,
      children:
        node.children && node.children.length
          ? node.children.map((item) => {
              return getChildNodes(item);
            })
          : [],
    };
  };

  const checkedKeys = {};

  const getSelectedKeyOfChild = (item) => {
    if (item.added) {
      checkedKeys[item.itemId] = {
        checked: true,
        // partialChecked: false
      };
    }
    if (item.children && item.children.length) {
      item.children.forEach((subitem) => {
        getSelectedKeyOfChild(subitem);
      });
    }
  };

  useEffect(() => {
    //get cms id
    dwsService.getCmsIdFromLocalStorage().then((res) => {
      setcmsInfoId(res);
    });

    //get user id
    let profile = authService.getProfile().customNetiID;
    setassignUserId(profile);
  }, []);

  useEffect(() => {
    //call api here
    if (cmsInfoId) {
      userCoreUrlInfoService
        .fetchNetiCmsMenuItems(cmsInfoId)
        .then((res) => {
          if (res.status == 200) {
            return res.json().then((body) => {
              const nodes = body.item;
              const formattedNodes = nodes.map((item) => {
                return getChildNodes(item);
              });
              setNodes(formattedNodes);
              nodes.forEach((element) => {
                getSelectedKeyOfChild(element);
              });
              setSelectedKeys(checkedKeys);
            });
          } else {
            dwsService.Auth.handleErrorStatus(res).then((resp) => {
              // this.setState({ topProgressBar: false });
              // this.setState({ errorMsgVisible: true, errorMsgBody: resp });
            });
          }
        })
        .catch((error) => {
          // errors["dropDownClassError"] = 'Connection Problem'
          // this.setState({
          //   errors: errors,
          //   dataTableIsLoading: false,
          //   topProgressBar: false,
          // });
          //set state here
        });
    }
  }, [cmsInfoId]);

  const onNodeSelect = (node) => {
    //will show notification
    // growl.current.show({
    //   severity: "success",
    //   summary: "Menu Selected",
    //   detail: node.node.label,
    //   life: 3000,
    // });
  };

  useEffect(() => {}, [nodes]);

  const onNodeUnselect = (node) => {
    //will show notificatin
    // growl.current.show({
    //   severity: "success",
    //   summary: "Menu Unselected",
    //   detail: node.node.label,
    //   life: 3000,
    // });
  };

  const growl = useRef(null);

  const onSubmitHandler = (event) => {
    const formattedCmsIds = [];
    for (const key in selectedKeys) {
      formattedCmsIds.push(parseInt(key));
    }
    const body = {
      assignUserId: assignUserId,
      cmsInfoId: cmsInfoId,
      cmsMenuItemIds: formattedCmsIds,
    };
    settopProgressBar(true);
    seterrorMsgVisible(false);
    userCoreUrlInfoService
      .saveCmsMenuConfig(body)
      .then((res) => {
        if (res.status == 201) {
          return res.json().then((body) => {
            //set state here
            growl.current.show({
              severity: "success",
              summary: "Success Message",
              detail: body.message,
              life: 3000,
            });
            settopProgressBar(false);
            seterrorMsgVisible(false);
          });
        } else {
          settopProgressBar(false);
          seterrorMsgVisible(true);
          seterrorMsgBody(body.message);

          dwsService.Auth.handleErrorStatus(res).then((resp) => {
            // this.setState({ topProgressBar: false });
            // this.setState({ errorMsgVisible: true, errorMsgBody: resp });
          });
        }
      })
      .catch((error) => {
        // errors["dropDownClassError"] = 'Connection Problem'
        // this.setState({
        //   errors: errors,
        //   dataTableIsLoading: false,
        //   topProgressBar: false,
        // });
        //set state here
      });
  };
  console.log({ selectedKeys });
  console.log({ checkedKeys });

  return (
    <div className="p-col-12 p-xl-12">
      {topProgressBar ? (
        <ErrorMessageView topProgressBar={topProgressBar} />
      ) : null}
      {errorMsgVisible ? (
        <ErrorMessageView
          errorMsgVisible={errorMsgVisible}
          errorMsgBody={errorMsgBody}
        />
      ) : null}
      <Growl ref={growl} />
      <div className="main-section">
        <div style={{ width: "70%" }}>
          <h2 style={{ paddingBottom: "20px", paddingLeft: "15px" }}>
            Menu Config
          </h2>
          {nodes && nodes.length && (
            <>
              <Tree
                value={nodes}
                selectionMode="checkbox"
                selectionKeys={selectedKeys}
                onSelectionChange={(e) => setSelectedKeys(e.value)}
                onSelect={onNodeSelect}
                onUnselect={onNodeUnselect}
              />
            </>
          )}
          {!nodes && <Skeleton height={50} count={7} />}
          {/* <button onClick={onSubmitHandler}>Save</button> */}
          <div className="p-col-12 p-xl-12 nw-button-parent">
            <Button
              style={{ marginRight: "10px" }}
              className="p-button-primary nw-button nw-button-right"
              label="Save"
              icon="fas fa-check"
              onClick={onSubmitHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { CmsMenuConfig };
