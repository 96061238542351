import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { AdmisiaApplicantViewInfo } from '../AdmisiaApplicantViewInfo';
import { AdmisiaPointService } from '../../../service/admisiaPoint/AdmisiaPointService';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiFileHandler } from '../../../utils/NetiFileHandler';
import Workbook from "react-excel-workbook";
import XLSX from 'xlsx';

export class AdmissionApprovedApplicantList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rejectedStutas: "Custom Status",
            dataTableSelection: null,
            dataViewValue: [],
            examInfoValue: [],
            dataTableValue: props.componentNameData,
            applicantDetailObj: {
                admisiaApplicantInfoDTO: {
                    registrationID: '',
                    applicationDate: '',
                    applicantName: '',
                    dateOfBirth: '',
                    mobileNo: '',
                    addressDetails: '',
                    addressCoreCategoryInfoDTO: {
                        categoryName: ''
                    },
                    fatherName: '',
                    motherName: '',
                    fatherOccupationCategoryInfoDTO: {
                        categoryName: ''
                    },
                    motherOccupationCategoryInfoDTO: {
                        categoryName: ''
                    },
                    admisiaClassConfigDTO: {
                        classCoreCategoryInfoDTO: {
                            categoryName: ''
                        },
                        groupCoreCategoryInfoDTO: {
                            categoryName: ''
                        }
                    },
                    genderCoreCategoryInfoDTO: {
                        categoryName: ''
                    },
                    religionCoreCategoryInfoDTO: {
                        categoryName: ''
                    }
                }
            },
            dataTableIsLoading: false,
            topProgressBar: false,
            errorMsgVisible: false,
            errorMsgBody: null,
            hiddenDataTable: false,
            errors: {}
        };

   
        this.admisiaPointService = new AdmisiaPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.netiFileHandler = new NetiFileHandler();
        this.getProfileImg = this.getProfileImg.bind(this);

    }


    // dateFormatTemplet = (rowData) => {
    //     return <span>{NetiDateUtils.getAnyShortForm(rowData['requestDate'], 'DD-MMM-YYYY')}</span>;
    // }

    statusBodyTemplet = (rowData) => {
        return <center><Button
            className="p-button p-button-primary p-button-info p-button-text-only"
            icon="far fa-eye"
            title="View Purchase"
            onClick={(e) => this.viewDialog(rowData)}
        />
        </center>
    }

    viewDialog = (rowData) => {
        this.setState({ visible: true });

        this.getApplicantDetailInfo(rowData.admisiaApplicantInfoDTO.admisiaApplicantInfoId)
    }

    onHide = (event) => {
        this.setState({ visible: false });
    }

    

    getProfileImg() {
        if (this.state.applicantDetailObj.admisiaApplicantInfoDTO.photoPath !== null) {
            this.netiFileHandler.getByteImage(this.state.applicantDetailObj.admisiaApplicantInfoDTO.photoPath)
                .then((res) => {
                    if (res.status == 200) {
                        return res.json()
                            .then((body) => {
                                let contentType = this.netiFileHandler.getImageContentType(this.state.applicantDetailObj.admisiaApplicantInfoDTO.photoName);
                                this.setState({ applicantPicture: contentType + body.fileContent });
                                console.log("applicantPicture", this.state.applicantPicture);
                                
                            })
                    } else {
                        return res.json()
                            .then((body) => {
                                console.log("image error", body);
                            })
                    }
                });
        }

    }



    getApplicantDetailInfo(applicantID) {
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.admisiaPointService.getApplicantInfoByID(applicantID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {

                        console.log("Detail Body", body);

                        this.setState({ applicantDetailObj: body });
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false });
                        this.getProfileImg();
                        this.getExamInfoList();
                    })
                }
                else {
                    this.admisiaPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch((error) => {
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });
    }



    getExamInfoList = () => {
        
        this.admisiaPointService.getPreviousInfoByID(this.state.applicantDetailObj.admisiaApplicantInfoDTO.admisiaApplicantInfoId)  
        .then(res => {
            this.setState({ topProgressBar: false });
            if (res.status == 302) {
                return res.json().then((body) => {
                    
                    console.log("ExamInfoList3333", body);
                    this.setState({ examInfoValue: body });  
                    
                });
            } else {
                this.admisiaPointService.Auth.handleErrorStatus(res)
                    .then((responseBody) => {
                        this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                    });
            }
        }).catch(error => {
            this.setState({ topProgressBar: false });

            console.log(error);
        });

    }


    ProcessExcel(data) {

        let workbook = XLSX.read(data, { type: 'binary' });
        const firstSheet = workbook.SheetNames[0];
        let excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
        this.setExcelRowsIntoDataTable(excelRows);

    }


    setExcelRowsIntoDataTable(excelRows) {

        let { dataTableValue } = this.state;

        let newDataList = [];
        for (const i in excelRows) {

            let dataTableObject = {};
            const rowValue = excelRows[i];

            for (let index = i; index <= i; index++) {
                const mainRowData = dataTableValue[index];

                dataTableObject = {

                    'admisiaApplicantInfoDTO': {
                        'admisiaApplicantInfoId': mainRowData.admisiaApplicantInfoDTO.admisiaApplicantInfoId,
                        'registrationID': rowValue.Registration_No,
                        'applicantName': rowValue.Name,
                        'genderCoreCategoryInfoDTO': {
                            'categoryName': rowValue.Gender,
                        },
                        'mobileNo': rowValue.Mobile_No,
                        'applicationDate': rowValue.Application_Date,
                       
                    },
                 
                }
            }
            newDataList.push(dataTableObject);
        }
        this.setState({ dataTableValue: newDataList });
    }

  


    // export() { 

    //     console.log("DT", this.dt);
        
    //     // this.dt.exportCSV();

    // }

    render() {
        
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            Admission Approved Applicant List
                        <div
                style={{ color: "white", display: "flex", float: "right" }}
            >
                Total: {this.state.dataTableValue.length}

            </div>
        </div>;

        return (
            <div className="p-fluid">
                <div className="p-grid">
                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <div className="p-col-12 p-xl-12">

                         <div className="nw-data-table nw-data-table-tabview">
                            <DataTable
                                value={this.state.dataTableValue}
                                activeIndex="0"
                                header={tableHeader}
                                responsive={true}
                                paginator={true}
                                rows={10}
                            >

                                <Column field="admisiaApplicantInfoDTO.registrationID" header="Registration No" sortable={true} filter={true} />
                                <Column field="admisiaApplicantInfoDTO.applicantName" header="Name" sortable={true} filter={true} />
                                <Column field="admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName" header="Gender" sortable={true} filter={true} />
                                <Column field="admisiaApplicantInfoDTO.mobileNo" header="Mobile No." sortable={true} filter={true} />
                                <Column field="admisiaApplicantInfoDTO.applicationDate" header="Application Date" sortable={true} filter={true} />
                                <Column field="requestStatus" header="Action" body={this.statusBodyTemplet} />
                            </DataTable>
                        </div>


                        
                      

                        <div className="p-grid">

                            <div className="p-col-12 p-xl-9">
                            </div>

                            <div className="p-col-12 p-xl-3">

                                <Workbook filename={"AdmissionApprovedApplicantList.xlsx"}
                                    element=
                                    {
                                        <div className="nw-button-parent">
                                            <Button
                                                label="Download"
                                                icon="fas fa-download"
                                                className="p-button p-button-primary nw-button nw-button-center"
                                               
                                            />
                                        </div>
                                    
                                    }
                                >
                                    <Workbook.Sheet data={this.state.dataTableValue} name="Admission Approved Applicant">
                                        <Workbook.Column value={row => row.admisiaApplicantInfoDTO.registrationID} label="Registration_No" />
                                        <Workbook.Column value={row => row.admisiaApplicantInfoDTO.applicantName} label="Name" />
                                        <Workbook.Column value={row => row.admisiaApplicantInfoDTO.genderCoreCategoryInfoDTO.categoryName} label="Gender" />
                                        <Workbook.Column value={row => row.admisiaApplicantInfoDTO.mobileNo} label="Mobile_No" />
                                        <Workbook.Column value={row => row.admisiaApplicantInfoDTO.applicationDate} label="Application_Date" />
                                       
                                    </Workbook.Sheet>
                                </Workbook>

                            </div>
                           
                           
                        </div>



                        <Dialog 
                            className="text-center nw-dialog" 
                            header="-" 
                            visible={this.state.visible}
                            onHide={this.onHide} maximizable
                            style = {{"width": "90%"}}
                        >
                            <AdmisiaApplicantViewInfo 
                              applicantInfo={this.state.applicantDetailObj} 
                              photoInfo={this.state.applicantPicture}
                              examInfo={this.state.examInfoValue}

                            />

                        </Dialog>

                    </div>
                </div>
            </div>
        );
    }
}