import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TeacherPortal } from '../../../service/emUserPoint/TeacherPortal';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';

export class TeacherTrainingInfo extends Component {

    constructor(props) {
        super(props);
        this.teacherPortal = new TeacherPortal();
    }

    render() {

        let trainingContent = ''
        if (this.props.basicInfo.item) {
            console.log(this.props.basicInfo.item);
            if (this.props.basicInfo.item.staffTraining) {
                let i = 0;
                trainingContent = this.props.basicInfo.item.staffTraining.map((item) => {

                    i++
                    return (
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2" >
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Training {i}</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Title</h4>
                                        <p>{item.trainingTitle || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Institute</h4>
                                        <p>{item.trainingInstitute || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Topic</h4>
                                        <p>{item.topic || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Address</h4>
                                        <p>{item.address || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Note</h4>
                                        <p>{item.trainingNote || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Duration</h4>
                                        <p>{item.duration || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )



                }


                )
            }


        }

        return trainingContent
    }
}