import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './AppWrapper';
import App from "./App";
import {HashRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import { sessionService, sessionReducer } from 'redux-react-session';
import thunkMiddleware from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom'
import 'babel-polyfill';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import './assets/style/_globalStyle_nw4.scss'
// import { Croppie } from "croppie";
// import "./styles.css";



// Add the sessionReducer
const reducer = combineReducers({
    session: sessionReducer
 });

 const store = createStore(reducer, undefined, compose(applyMiddleware(thunkMiddleware)));
// Init the session service
sessionService.initSessionService(store);

ReactDOM.render(
    <Provider store={store}>
   <BrowserRouter>
   <AppWrapper/>
   </BrowserRouter>
 </Provider>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
