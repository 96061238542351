import React, { component, Component } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { Dialog } from "primereact/dialog";
import { ErrorMessageView } from "../common/ErrorMessageView";
import NetiContentLoader from '../common/NetiContentLoader';

import { ManagementPointService } from "../../service/managementPoint/ManagementPointService";
import { ValidatorUtility } from "../../utils/ValidatorUtility";
import { NetiDateUtils } from "../../utils/NetiDateUtils";
import { pdfDownloadPortrait_TableOnly } from "../common/JsPdfDownload";
import CommonFuctionality from "../common/CommonFuctionality";
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

// dialog content related
let selectedInstitute = '';
let dialogHeader = '';
let dialogTableHeader = '';
let dialogTableValue = [];
let dialogTableColumns = [];
let backBtnVisible = false;

let pdfTitle = '';
let pdfDialogTableColumns = [];

let totalCollection = 0;
let totalSettlement = 0;

export class OnlinePaymentSystem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            dataTableIsLoading: false,
            dataTableIsLoading2: false,

            visible: false,
            dataTableValue: [],
            dataWiseCollectionTableValue: [],
        }

        this.ManagementPointService = new ManagementPointService();
        this.CommonFuctionality = new CommonFuctionality();
        this.netiContentLoader = new NetiContentLoader();
        this.validatorUtility = new ValidatorUtility();

        this.fetchOpsSummaryOf_InstituteList = this.fetchOpsSummaryOf_InstituteList.bind(this);
        this.fetchDrillDownDataOf_TotalCollection = this.fetchDrillDownDataOf_TotalCollection.bind(this);
        this.fetchDrillDownDataOf_CollectionAmnt = this.fetchDrillDownDataOf_CollectionAmnt.bind(this);
        this.fetch_DrillDownDataOf_TotalSettlement = this.fetch_DrillDownDataOf_TotalSettlement.bind(this);
        this.backOnPreviousTable = this.backOnPreviousTable.bind(this);
        this.resetDialogAndTableData = this.resetDialogAndTableData.bind(this);

        this.makeDialogVisible = this.makeDialogVisible.bind(this);
        this.makeDialogHide = this.makeDialogHide.bind(this);

        this.exportPdf = this.exportPdf.bind(this);
        this.exportPdfOfDrillDown = this.exportPdfOfDrillDown.bind(this);

    }

    componentWillMount() { this.fetchOpsSummaryOf_InstituteList(); }

    fetchOpsSummaryOf_InstituteList() {

        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
        this.ManagementPointService.fetchOpsInstituteCollectionList()
            .then((res) => {

                if (res.status == 302) {
                    return res.json().then((body) => {

                        totalCollection = body.item.map(item => item.totalCollection)
                        .reduce((a, b) => {
                            return a + b;
                        }, 0);
                        totalSettlement = body.item.map(item => item.totalSettlement)
                        .reduce((a, b) => {
                            return a + b;
                        }, 0);

                        body.item.map(item => {
                            item.totalCollection = this.validatorUtility.currencyFormatter(item.totalCollection);
                            item.totalSettlement = this.validatorUtility.currencyFormatter(item.totalSettlement);
                            item.refundableBalance = this.validatorUtility.currencyFormatter(item.refundableBalance);
                        })

                  

                        this.setState({ dataTableValue: body.item });
                        this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }

            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    fetchDrillDownDataOf_TotalCollection(instituteId) {

        this.setState({ topProgressBar: true, dataTableIsLoading2: true, errorMsgVisible: false });
        this.ManagementPointService.fetchOpsDateWiseCollectionList(instituteId)
            .then((res) => {

                if (res.status == 302) {
                    return res.json().then((body) => {

                        body.item.map(item => {
                            item.collectionAmount = this.validatorUtility.currencyFormatter(item.collectionAmount);
                            Object.assign(item, { formatedCollectionDate: NetiDateUtils.getAnyShortForm(item.collectionDate, 'DD-MMM-YYYY hh:mm:ss a') });  // for pdf column
                        });
                        this.setState({ dataWiseCollectionTableValue: body.item });

                        // dialog content set
                        dialogTableHeader = <div className="header-title">
                            <div className="header-title-left">Date Wise Total Collection Details List</div>
                            <div className="header-title-right"><a>Total Found: {body.item.length.toLocaleString('EN-IN')}</a></div>
                        </div>;

                        Array.prototype.push.apply(dialogTableValue, body.item);
                        dialogTableColumns.push(
                            <Column field="formatedCollectionDate" header="Date & Time" style={{ width: '110px' }} />,
                            <Column field="collectionDay" header="Day" />,
                            <Column field="collectionAmount" header="Collection Amount" body={this.collectionAmountBody} />
                        );
                        // dialog content set end

                        //pdf column set
                        pdfTitle = "Date Wise Total Collection Details List of " + instituteId;
                        pdfDialogTableColumns.push(
                            { title: "Date & Time", dataKey: "formatedCollectionDate" },
                            { title: "Day", dataKey: "collectionDay" },
                            { title: "Collection Amount", dataKey: "collectionAmount" },
                        );
                        //pdf column set end

                        this.setState({ topProgressBar: false, dataTableIsLoading2: false });
                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    fetchDrillDownDataOf_CollectionAmnt(collectionDate) {

        dialogHeader = 'Student Wise Total Collection';
        let splitDate = collectionDate.split("-");
        let formatedDate = splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0];

        this.setState({ topProgressBar: true, dataTableIsLoading2: true, errorMsgVisible: false });
        this.ManagementPointService.fetchOpsStudentWiseCollectionList(formatedDate, selectedInstitute)
            .then((res) => {

                if (res.status == 302) {

                    return res.json().then((body) => {

                        // dialog content set

                        dialogTableHeader = <div className="header-title">
                            <div className="header-title-left">Collection Amount Details List</div>
                            <div className="header-title-right"><a>Total Found: {body.item.length.toLocaleString('EN-IN')}</a></div>
                        </div>;

                        body.item.map(item => { item.paidAmount = this.validatorUtility.currencyFormatter(item.paidAmount) });

                        Array.prototype.push.apply(dialogTableValue, body.item);
                        dialogTableColumns.push(
                            <Column field="customStudentId" header="Student ID" />,
                            <Column field="studentName" header="Student Name" />,
                            <Column field="sectionName" header="Section" />,
                            <Column field="invoiceId" header="Invoice ID" />,
                            <Column field="paidAmount" header="Paid Amount" className="col-number text-right" />,
                            <Column field="opsTransactionId" header="Transaction ID" />,
                            <Column field="toAccount" header="To Account" />
                        )
                        // dialog content set end

                        //pdf column set
                        pdfTitle = "Collection Amount Details List";
                        pdfDialogTableColumns.push(
                            { title: "Student ID", dataKey: "customStudentId" },
                            { title: "Student Name", dataKey: "studentName" },
                            { title: "Section", dataKey: "sectionName" },
                            { title: "Invoice ID", dataKey: "invoiceId" },
                            { title: "Paid Amount", dataKey: "paidAmount" },
                            { title: "Transaction ID", dataKey: "opsTransactionId" },
                            { title: "To Account", dataKey: "toAccount" },
                        );
                        //pdf column set end

                        this.setState({ topProgressBar: false, dataTableIsLoading2: false });
                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    fetch_DrillDownDataOf_TotalSettlement(instituteId) {

        this.setState({ topProgressBar: true, dataTableIsLoading2: true, errorMsgVisible: false });
        this.ManagementPointService.fetchOpsInstituteTotalSettlementList(instituteId)
            .then((res) => {

                if (res.status == 302) {
                    return res.json().then((body) => {

                        // dialog content set

                        dialogTableHeader = <div className="header-title">
                            <div className="header-title-left">Total Settlement Details List</div>
                            <div className="header-title-right"><a>Total Found: {body.length.toLocaleString('EN-IN')}</a></div>
                        </div>;

                        body.map(item => {
                            item.amount = this.validatorUtility.currencyFormatter(item.amount);
                            item.settlementDate = NetiDateUtils.getAnyShortForm(item.settlementDate, 'DD-MMM-YYYY hh:mm:ss a');

                        });

                        Array.prototype.push.apply(dialogTableValue, body);
                        dialogTableColumns.push(
                            <Column field="settlementDate" header="Date & Time" style={{ width: '110px' }} />,
                            <Column field="instituteBank" header="Settlement Bank" />,
                            <Column field="accountNumber" header="Settlement Account" />,
                            <Column field="amount" header="Amount" body={this.bodyAmount} />,
                            <Column field="settlementBy" header="Settlement By" />
                        )
                        // dialog content set end

                        //pdf column set
                        pdfTitle = "Total Settlement Details List of " + instituteId;
                        pdfDialogTableColumns.push(
                            { title: "Date & Time", dataKey: "settlementDate" },
                            { title: "Settlement Bank", dataKey: "instituteBank" },
                            { title: "Settlement Account", dataKey: "accountNumber" },
                            { title: "Amount", dataKey: "amount" },
                            { title: "settlementBy", dataKey: "settlementBy" },
                        );
                        //pdf column set end

                        this.setState({ topProgressBar: false, dataTableIsLoading2: false });
                    });

                } else {
                    this.ManagementPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => { this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: responseBody }) });
                }
            }).catch(error => this.setState({ topProgressBar: false, dataTableIsLoading2: false, errorMsgVisible: true, errorMsgBody: 'Please check connection' })

            );

    }

    makeDialogVisible() {
        this.CommonFuctionality.blurDialogBackgroundActive();
        this.setState({ visible: true });
    }

    makeDialogHide() {
        this.CommonFuctionality.blurDialogBackgroundDeActive();
        this.setState({ visible: false });
        this.resetDialogAndTableData();
        backBtnVisible = false;
    }

    resetDialogAndTableData() {
        dialogHeader = ''
        dialogTableHeader = '';
        dialogTableValue = []
        dialogTableColumns = [];
        pdfDialogTableColumns = [];
    }

    onClickTotalCollectionRData = (rowdata) => {

        this.makeDialogVisible();
        dialogHeader = 'Date Wise Total Collection';
        this.fetchDrillDownDataOf_TotalCollection(rowdata.instituteId);
        selectedInstitute = rowdata.instituteId;

    }

    onClickCollectionAmountRData = (rowData) => {

        backBtnVisible = true;
        this.resetDialogAndTableData();
        this.fetchDrillDownDataOf_CollectionAmnt(rowData.collectionDate);

    }

    onClickTotalSettlementRData = (rowData) => {

        this.makeDialogVisible();
        dialogHeader = 'Settlement Amount Details';
        this.fetch_DrillDownDataOf_TotalSettlement(rowData.instituteId);

    }

    totalCollectionBody = (rowData) => {
        return <span style={{ color: "#0078ff", cursor: "pointer", textDecoration: "underline" }} onClick={(e) => this.onClickTotalCollectionRData(rowData)} ><div className="col-number text-right"> {rowData.totalCollection} </div></span>
    }

    collectionAmountBody = (rowData) => {
        return <span style={{ color: "#0078ff", cursor: "pointer", textDecoration: "underline" }} onClick={(e) => this.onClickCollectionAmountRData(rowData)} ><div className="col-number text-right">{rowData.collectionAmount}</div></span>
    }

    totalSettlementBody = (rowData) => {
        return <span style={{ color: "#0078ff", cursor: "pointer", textDecoration: "underline" }} onClick={(e) => this.onClickTotalSettlementRData(rowData)} ><div className="col-number text-right">{rowData.totalSettlement}</div></span>
    }

    refundBody = (rowData) => { return <div className="col-number text-right"> {rowData.refundableBalance}</div> }

    bodyAmount = (rowData) => { return <div className="col-number text-right"> {rowData.amount}</div> }

    bodyAmount = (rowData) => { return <div className="col-number text-right"> {rowData.amount}</div> }

    backOnPreviousTable() {

        this.resetDialogAndTableData();
        backBtnVisible = false;
        // dialog content set
        dialogHeader = 'Date Wise Total Collection';

        dialogTableHeader = <div className="header-title">
            <div className="header-title-left">Date Wise Total Collection Details List</div>
            <div className="header-title-right"><a>Total Found: {this.state.dataWiseCollectionTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        Array.prototype.push.apply(dialogTableValue, this.state.dataWiseCollectionTableValue);
        dialogTableColumns.push(
            <Column field="formatedCollectionDate" header="Date & Time" style={{ width: '110px' }} />,
            <Column field="collectionDay" header="Day" />,
            <Column field="collectionAmount" header="Collection Amount" body={this.collectionAmountBody} />
        )
        // dialog content set end

        //pdf column set
        pdfTitle = "Date Wise Total Collection Details List of " + selectedInstitute;
        pdfDialogTableColumns.push(
            { title: "Date & Time", dataKey: "formatedCollectionDate" },
            { title: "Day", dataKey: "collectionDay" },
            { title: "Collection Amount", dataKey: "collectionAmount" },
        );
        //pdf column set end

    }

    exportPdf() {

        let pdfColumns = [
            { title: "Institute ID", dataKey: "instituteId" },
            { title: "Institute Name", dataKey: "instituteName" },
            { title: "Total Collection", dataKey: "totalCollection" },
            { title: "Total Settlement", dataKey: "totalSettlement" },
            { title: "Refund Available", dataKey: "refundableBalance" },
        ]

        let tableColStyle = { totalCollection: { halign: "right" }, totalSettlement: { halign: "right" }, refundableBalance: { halign: "right" } };

        pdfDownloadPortrait_TableOnly("Institute Wise OPS Summary Report", 68, pdfColumns, this.state.dataTableValue, tableColStyle, "Institute_Wise_OPS_Summary.pdf");

    }

    exportPdfOfDrillDown() {

        let tableColStyle = { collectionAmount: { halign: "right" }, amount: { halign: "right" }, paidAmount: { halign: "right" } };

        pdfDownloadPortrait_TableOnly(pdfTitle, 56, pdfDialogTableColumns, dialogTableValue, tableColStyle, pdfTitle + ".pdf");

    }

    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let tableHeader = <div className="header-title">
            <div className="header-title-left">Institute Wise OPS Summary Report</div>
            <div className="header-title-right"><a>Total Found: {this.state.dataTableValue.length.toLocaleString('EN-IN')}</a></div>
        </div>;

        let instituteWiseSummaryFooter = <ColumnGroup>
        <Row>
            <Column footer="Total:" className="nw-tfoot-total-text" />
            <Column footer='' />
            <Column footer={totalCollection ? this.validatorUtility.currencyFormatter(totalCollection) : 0} />
            <Column footer={totalSettlement ? this.validatorUtility.currencyFormatter(totalSettlement) : 0 } />
            <Column footer='' />
        </Row>
    </ColumnGroup>;



        let dialogContent = <div className="p-col-12 p-xl-12 m-b-20 nw-data-table nw-data-table-tabview">
            <DataTable
                value={dialogTableValue}
                header={dialogTableHeader}
                responsive={true}
                paginator={true}
                rows={10}
            >
                {dialogTableColumns}
            </DataTable>
        </div>

        return (
            <div className="p-fluid">
                <div className='main-section'>

                    {topProgressBar ?
                        <ErrorMessageView
                            topProgressBar={topProgressBar}
                        />
                        : null
                    }
                    {errorMsgVisible ?
                        <ErrorMessageView
                            errorMsgVisible={errorMsgVisible}
                            errorMsgBody={errorMsgBody}
                        />
                        : null
                    }
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-12">
                            <div className="p-grid">
                                <div className="nw-data-table nw-data-table-tabview">

                                    <div className="p-col-12 p-xl-12 m-b-20">
                                        {
                                            this.state.dataTableIsLoading ? this.netiContentLoader.MyPointTableLoader()
                                                :
                                                <DataTable
                                                    value={this.state.dataTableValue}
                                                    header={tableHeader}
                                                    responsive={true}
                                                    paginator={true}
                                                    rowsPerPageOptions={[10, 50,100]}
                                                    rows={10}
                                                    footerColumnGroup={instituteWiseSummaryFooter}
                                                >
                                                    <Column field="instituteId" header="Institute ID" />
                                                    <Column field="instituteName" header="Institute Name" />
                                                    <Column field="totalCollection" header="Total Collection" body={this.totalCollectionBody} />
                                                    <Column field="totalSettlement" header="Total Settlement" body={this.totalSettlementBody} />
                                                    <Column field="refundableBalance" header="Refund Available" body={this.refundBody} />
                                                </DataTable>
                                        }

                                    </div>
                                    <div className="p-col-12 p-xl-12 nw-button-parent">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Download PDF"
                                            icon="fas fa-download"
                                            onClick={this.exportPdf}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Dialog className="nw-dialog" header={dialogHeader} visible={this.state.visible} onHide={this.makeDialogHide} maximizable>

                        <div className="p-fluid">
                            <div className="p-grid">

                                {this.state.dataTableIsLoading2 ? this.netiContentLoader.MyPointTableLoader() : dialogContent}

                                <div class="p-col-12 p-xl-12 nw-button-parent-multiple p-r-0">

                                    <Button
                                        className="p-button-primary nw-button nw-button-multiple"
                                        label="Download PDF"
                                        icon="fas fa-download"
                                        onClick={this.exportPdfOfDrillDown}
                                    />

                                    {backBtnVisible ?
                                        <Button
                                            className="p-button-primary nw-button nw-button-multiple"
                                            label="Back"
                                            icon="fas fa-chevron-left"
                                            onClick={this.backOnPreviousTable}
                                        />
                                        : ''}
                                </div>

                            </div>
                        </div>
                    </Dialog>
                </div>
            </div >
        )
    }
}