import React, { Component } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import { MANAGEMENT_POINT } from '../../../utils/PointWiseRouteConsts';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { NetiDateUtils } from '../../../utils/NetiDateUtils';
import { ManagementPointService } from '../../../service/managementPoint/ManagementPointService';
import { Growl } from 'primereact/growl';


import { BlankDataTableAnim } from '../../common/BlankDataTableAnim'
import NetiContentLoader from '../../common/NetiContentLoader';

let errors = {}

export class ProductPrePurchaseDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchDataObj: {
                startDate: '',
                endDate: '',
                pageLimit: 0,
                pageNo: 0
            },
            dataTableValueRevenue: [{"details": "hello"}],
            dataTableValueStatement: [{"details": "hello"}],
            tableView: false,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            totalPaidAmountBody: 0,
            errors: {},
        }

        this.managementPointService = new ManagementPointService();

    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValueStatement, dataTableValueRevenue, totalPaidAmountBody, searchDataObj } = this.state;
        // let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>Product Pre Purchase List <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;
        let headerRevenue = <div className="header-title">
                        <div className="header-title-left">
                            Revenue Details
                        </div>


                        <div className="header-title-right">

                            <span> Total Found: {dataTableValueRevenue.length} </span>
                        </div>
                    </div>;

        let headerStatement = <div className="header-title">
                        <div className="header-title-left">
                            Statement
                        </div>


                        <div className="header-title-right">

                            <span> Total Found: {dataTableValueStatement.length} </span>
                        </div>
                        </div>;

        return (
            <div className="p-fluid">

                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />

                <div className="main-section">

                    <div className="nw-data-table">
                        <div className="p-col-12 p-xl-12">
                            {/* {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ? 
                                        this.NetiContentLoader.MyPointTableLoader()
                                        : */}
                            <DataTable
                                header={headerStatement}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                value={this.state.dataTableValueStatement}
                            //selection={this.state.dataTableSelection}
                            >

                                <Column field="name" header="Expense" filter={true} />
                                <Column field="" header="Income" filter={true} />

                            </DataTable>

                            {/* :
                                        
                                        <BlankDataTableAnim/> 
                                } */}
                        </div>

                        <div className="p-col-12 p-xl-12">
                            {/* {
                                    this.state.tableView === true ?
                                        this.state.dataTableIsLoading ? 
                                        this.NetiContentLoader.MyPointTableLoader()
                                        : */}
                            <DataTable
                                header={headerRevenue}
                                responsive={true}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                value={this.state.dataTableValueRevenue}
                            //selection={this.state.dataTableSelection}
                            >

                                <Column field="name" header="Cost" filter={true} />
                                <Column field="" header="Fixed" filter={true} />
                                <Column field="" header="Level" filter={true} />
                                <Column field="" header="VAT" filter={true} />

                            </DataTable>

                            {/* :
                                        
                                        <BlankDataTableAnim/> 
                                } */}
                        </div>
                    </div>

                </div>

            </div>



            // </div>
        )
    }
}