import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { ProfileService } from '../../../service/profile/ProfileService';
import { Growl } from 'primereact/growl';
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import CommonFuctionality from '../../common/CommonFuctionality';

export default class Additionalinformation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            updateDialog: false,
            additionalField: {
                columnValue: '',
                typeCategoryInfoDTO: {
                    coreCategoryID: ''
                }
            },
            additionalFieldReturn: [],
            updateAdditionalField: [],
            additionalError: {

            },
            infoType: '',
            infoValue: '',
            additionalOptionSelect: '',
            additionalOption: [
                { label: 'Problem Type', value: '57' },
                { label: 'Module', value: '58' },
                { label: 'Netiworld', value: '59' },
                { label: 'Eduman', value: '60' },
                { label: 'DWS Point', value: '61' },
                { label: 'My Point', value: '62' },
                { label: 'Bijoy Point', value: '62' }
            ],
            dataTableIsLoading: false,
            topProgressBar: false
        }

        this.ProfileService = new ProfileService;
        this.NetiContentLoader = new NetiContentLoader();
        this.ErrorMessageView = new ErrorMessageView();
        this.CommonFuctionality = new CommonFuctionality();

    }

    componentDidMount() {
        this.getAdditionalInfoList();
    }
    getAdditionalInfoList(){
        this.ProfileService.getAdditionalInfoList()
            .then(res => {
                if (res.status == 202) {
                    return res.json().then((body) => {
                        this.setState({ additionalFieldReturn: body })
                    })
                }
            })

    }

    /**Add additional info start***/
    addAdditionalInfoType = (event, props) => {
        let addField = this.state.additionalField;
        addField.typeCategoryInfoDTO.coreCategoryID = event.target.value;
        this.setState({ additionalField: addField });
    }

    /**Add additional info end***/
    onClick(event) {
        this.setState({ visible: true });
    }

    infoValueHandler = (event, props) => {
        let infoValue = this.state.additionalField;
        infoValue.columnValue = event.target.value;
        this.setState({ additionalField: infoValue });
    }

    additionalOptionHadler = (event, props) => {
        
        if (this.additionalError()) 
        this.setState({ dataTableIsLoading: true, topProgressBar:true, errorMsgVisible: false })
        {
            this.ProfileService.addAdditionalInfoSubmit(this.state.additionalField)
                .then(res => {
                    if (res.status == 201) {
                        return res.json().then((body) => {
                            this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Additional information submit successfully' });
                            this.setState({ visible: false });
                            this.getAdditionalInfoList();
                            this.setState({ dataTableIsLoading: false, topProgressBar:false})

                            this.onHide();
                        })
                    } else {
                        this.ProfileService.Auth.handleErrorStatus(res)
                        .then((resp)=>{
                            this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                        });
                    }
                }).catch( err =>{
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
                })
        }
    }

    updateAdditionalInfo = (event, props) => {
       this.setState({ dataTableIsLoading: true, topProgressBar:true, errorMsgVisible: false })
       this.ProfileService.updateAdditionalInfoSubmit(this.state.additionalFieldReturn)
            .then(res => {
                if (res.status == 202) {
                    this.growl.show({ severity: 'success', summary: 'Success Message', detail: 'Additional information updated successfully' });
                    this.setState({ updateDialog: true });
                    this.setState({ dataTableIsLoading: false, topProgressBar:false})

                    this.onHide();
                } else {
                    this.setState({ updateDialog: true });
                    this.ProfileService.Auth.handleErrorStatus(res)
                    .then((resp)=>{
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: resp})
                    });
                }
            }).catch( err =>{
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Please check your connection' })
            })

    }

    aditonalInfoFieldUpdate = (event, props) => {
        let { additionalFieldReturn } = this.state;
        for (let i = 0; i < additionalFieldReturn.length; i++) {
            if (additionalFieldReturn[i].userDetailsID == event.target.name) {
                additionalFieldReturn[i].userDetailsID = additionalFieldReturn[i].userDetailsID;
                additionalFieldReturn[i].columnValue = event.target.value;
                additionalFieldReturn[i].typeName = event.target.id;
            }
        }
        
        this.setState({ additionalFieldReturn });
    }

    additionalError = () => {
        let additionalError = {};
        let formIsValid = true;
        if (this.state.additionalField.columnValue === '') {
            formIsValid = false;
            additionalError["columnValue"] = "Column value can't left empty";
        }
        if (this.state.additionalField.typeCategoryInfoDTO.coreCategoryID === '') {
            formIsValid = false;
            additionalError["coreCategoryID"] = "Additional value can't left empty";
        }
        this.setState({ additionalError: additionalError });
        return formIsValid;
    }

    additionalInfoDialogView = () =>{
        this.setState({ visible:true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    updateDialogView = () =>{
        this.setState({ updateDialog:true });
        this.CommonFuctionality.blurDialogBackgroundActive();
    }

    onHide = () =>{
        this.setState({ visible:false, updateDialog:false });
        this.CommonFuctionality.blurDialogBackgroundDeActive();
    }


    render() {

        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
      
        return (
            <div className="p-col-12 p-lg-12">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={(el) => this.growl = el} />
                <div className="card card-w-title statistics custom-inner-field blur-section">
                    <Button 
                        label="Add aditional information" 
                        icon="fas fa-plus" style={{ marginRight: '10px', marginBottom: '10px' }} 
                        iconPos="left" 
                        onClick={this.additionalInfoDialogView}
                        disabled
                    />
                    <Button 
                        label="Update aditional information" 
                        icon="fas fa-pencil-alt" 
                        onClick={this.updateDialogView}
                        iconPos="left" 
                        disabled
                    />
                    {/* Add information dialog start*/}

                

                    <Fieldset legend="Additional Information">
                        <div className="nw-form">
                            <div className="p-grid p-col-12 nw-form-body">
                            {
                                this.state.additionalFieldReturn.map(field => {
                                    const { typeName, columnValue } = field;
                                    return (
                                        <div className="p-col-12 p-xl-6">
                                            {  this.state.dataTableIsLoading?
                                               this.NetiContentLoader.normalFormInputField():
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">{typeName}</span>
                                                    <InputText
                                                        type="text"
                                                        name=""
                                                        placeholder={columnValue}
                                                        readOnly
                                                    />
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </Fieldset>
                </div>

                <div className="dialog-section">
                    <Dialog 
                        className="nw-dialog" 
                        header="Aditional info" 
                        visible={this.state.visible} 
                        modal={true} 
                        onHide={this.onHide}
                    >
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Information Type</span>
                                            <Dropdown
                                                placeholder="Select information type"
                                                options={this.state.additionalOption}
                                                autoWidth={false}
                                                className="custom-field-control"
                                                onChange={event => this.addAdditionalInfoType(event, this.props)}
                                                value={this.state.additionalField.typeCategoryInfoDTO.coreCategoryID}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.additionalError["coreCategoryID"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Additional Value</span>
                                            <InputText
                                                type="text"
                                                id="additionalValue"
                                                name="fullName"
                                                placeholder="Enter your value"
                                                className="custom-field-control"
                                                onChange={event => this.infoValueHandler(event, this.props)}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.additionalError["columnValue"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12 nw-button-parent" >
                                        <Button 
                                            label="Add" 
                                            icon="fa fa-plus" 
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            onClick={(event) => this.additionalOptionHadler(event, this.props)} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog 
                        className="nw-dialog" 
                        header="Update info" 
                        visible={this.state.updateDialog} 
                        modal={true} 
                        onHide={(e) => this.setState({ updateDialog: false })}
                    >
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                {
                                this.state.additionalFieldReturn.map(field => {
                                    const { typeName, columnValue, userDetailsID } = field;
                                    return (
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon"><b>{typeName}</b></span>
                                                <InputText
                                                    type="text"
                                                    name={userDetailsID}
                                                    value={columnValue}
                                                    id={typeName}
                                                    onChange={(event) => this.aditonalInfoFieldUpdate(event, this.props)}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                                <div className="p-col-12 p-xl-12 nw-button-parent">
                                    <Button 
                                        label="Update" 
                                        icon="fas fa-check"
                                        className="p-button p-button-primary nw-button nw-button-right"
                                        onClick={(event) => this.updateAdditionalInfo(event, this.props)} 
                                    />
                                </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                   
                </div>
            </div>
        )
    }
}
