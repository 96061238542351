import React, { Component } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Growl } from "primereact/growl";
import NetiContentLoader from '../../common/NetiContentLoader';
import { ErrorMessageView } from '../../common/ErrorMessageView';
import { ChatBoxService } from '../../../service/chatBox/chatBoxService';
import Formatter from '../../../utils/Formatter';
import CommonFuctionality from '../../common/CommonFuctionality';
import { CRMPointService } from '../../../service/crmPoint/CRMPointService';
import { ProfileService } from '../../../service/profile/ProfileService';
import { SendSmsAndNetiMail } from "../../common/SendSmsAndNetiMail";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog } from "primereact/dialog";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";
import { CUSTOM_MESSAGE } from "../../common/CustomMessage";
import { UserOtherAccessService } from "../../../service/UserOtherAccessService";


export class MessageSendToPointExpire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageBody: "",
            userInfo: {},
            errors: {},
            dataTableIsLoading: false,
            topProgressBar: false,
            searchIsLoading: false,
            dataTableValue: [],
            searchViewError: false,
            searchIsLoadingFirst: false,
            searchIsLoadingSecond: false,
            uploadImage: {},
            secondSearchIsLoading: false,
            secondSearchErrorMsg: '',
            secondfirstSearchView: false,
            secondSearchErrorVisible: false,
            netiInformation: {},
            disableAddButton: true,

            /***Data table checkbox***/
            pointType:[],
            filterArray: [],
            selectPartner: null,
            selectedCustomers: [],
            globalFilter: null,
            selectedRepresentatives: null,
            smsDialogVisible: false,
            selectPointType:null
        };

        this.NetiContentLoader = new NetiContentLoader();
        this.chatBoxService = new ChatBoxService();
        this.formatter = new Formatter();
        this.CommonFuctionality = new CommonFuctionality();
        this.CRMPointService = new CRMPointService();
        this.ProfileService = new ProfileService();
        this.validatorUtility = new ValidatorUtility();
        this.UserOtherAccessService = new UserOtherAccessService();
        
    }

    componentWillMount(){
        this.getPointTypekHandler();
    }

    netiIdHandleError() {
        let errors = {};
        let formIsValid = true;

        if (this.state.netiId === "") {
            formIsValid = false;
            errors["netiId"] = "Please input atleast one ID.";
            this.setState({ buttonDisabled: true, searchViewError: false, searchView: false })
        }

        this.setState({ errors: errors });
        return formIsValid;
    }



    viewActionBody = (rowData) => {
        return <div>
            {/* <span>{rowData.basicMobile}</span> */}
            <Button
                className="p-button-danger nw-action-button"
                icon="fas fa-times"
                onClick={e => this.onDeleteRow(rowData)}
            />
        </div>
    }

    onDeleteRow = (rowData) => {
        let { dataTableValue } = this.state
        const filteredItems = dataTableValue.filter(function (item) {
            return item !== rowData
        })

        this.setState({ dataTableValue: filteredItems })
    }



    selectPartnerHandler = (e) => {

        this.setState({ selectPartner: e.target.value, selectedCustomers: [] });
    }

    ///Sms sender list
    getSenderInformation = () => {
        if (this.netiIdHandleError()) {
            let { selectPointType, designationArray, topProgressBar, dataTableIsLoading, selectPartner, dataTableValue } = this.state;
            this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            this.CRMPointService.getUserDetailsForExpireList(selectPointType, selectPartner)
                .then(res => {
                    if (res.status == 302) {
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
                        return res.json().then((body) => {
                            this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false, });
                            console.log('body', body);
                        });
                        
                        
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
                });;
        }
    }

    netiIdHandleError = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.selectPartner) {
            formIsValid = false;
            errors["netiIDError"] = "Please Select User Type.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    selectPointType = (e) => {
        this.setState({selectPointType:e.target.value});
    }

    showSMSDialog = () => {
        this.setState({
            smsDialogVisible: true
        })
    }

    IdTerminateConfirmation = () => {
        this.setState({
            smsDialogVisible: false
        })
    }

    onSubmitSendSMSorMail = () => {
        let { selectedCustomers, filterArray } = this.state;
        let selectedCustomersMakeArray = [];
        selectedCustomers.map(item => {
            let createArray = [item.netiID, item.name, item.mobile, item.email, item.expireDate, item.expireDays];
            selectedCustomersMakeArray.push(createArray);
        });
        console.log('selectedCustomersMakeArray', selectedCustomersMakeArray);
        
        let urlName="/send/sms/single?reportType=pointExpire";

        this.setState({ topProgressBar: true, errorMsgVisible: false, smsDialogVisible: false })
        this.UserOtherAccessService.sendSingleSMS(selectedCustomersMakeArray, urlName)
            .then((res) => {
                if (res.status === 201) {
                    this.growl.show({ severity: 'success', summary: CUSTOM_MESSAGE.SUCCESS_MSG_SHORT, detail: "SMS Send Successfull." });
                    this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: false})
                    
                } else {
                    this.UserOtherAccessService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody, topProgressBar: false, })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check connection !!!", buttonIcon: "fas fa-check", buttonIconAnimClass: "p-button-primary"  });
            });


    }


    getPointTypekHandler = () =>{
        let { pointType, topProgressBar, firstDropdownIsLoading} = this.state; 
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
       
        this.CRMPointService.getPointTypeList()
            .then(res => {
                if (res.status == 302) {
                    this.setState({ topProgressBar: false, firstDropdownIsLoading: false });
                    return res.json().then((body) => {
                        for (let index = 0; index < body.length; index++) {
                            let parent = { 
                                "label" : body[index].coreRoleNote,
                                "value" : body[index].coreRoleName
                            }
                            pointType.push(parent);
                        }
                        
                    });
                    
                }
        }).catch(error => {
            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" });
        });;
    }


    render() {
        let { selectedCustomers, pointType, dataTableValue, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        const designationArray = [
            { label: 'Owner', value: 'owner' },
            { label: 'Parent', value: 'parent' }
        ];

        let header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> User List <span style={{ 'float': 'right' }}> Total Found: {dataTableValue.length} </span></div>;
        let headerForMessage = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> Message List <span style={{ 'float': 'right' }}> Total Found: {selectedCustomers.length} </span></div>;

        let messageTemplate = (rowData) => {
            return <div>
                {`Dear ${this.state.selectPartner} your  ${rowData.expireDays === 'Date Expired' ? `${this.state.selectPointType} Date Expired.` : `${this.state.selectPointType} will expire within next ${rowData.expireDate}. Point owner Neti ID - ${rowData.netiID} Point Owner Name - ${rowData.name}`} please take proper initiative for renew thiis point Netizen`}
            </div>
        }

        let mobileTemplate = (rowData) => {
            return <div>
                <span><b>Mobile : {rowData.mobile}</b></span><br />
                <span><b>Email : {rowData.email}</b></span>
            </div>
        }

        return (
            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <Growl ref={el => (this.growl = el)} />
                <div className="main-section">
                    <div className="p-grid nw-form">
                        <div className="p-col-12 p-xl-8 p-col-nogutter seprator">
                            <div className="p-grid seprator-inside">
                                <div className="p-col-12 p-xl-12">
                                    <div className="nw-form-body">
                                    {this.state.firstDropdownIsLoading ?
                                         this.NetiContentLoader.normalFormInputField() :
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">User Type <span>*</span></span>
                                                <Dropdown
                                                    options={designationArray}
                                                    autoWidth={false}
                                                    onChange={this.selectPartnerHandler}
                                                    value={this.state.selectPartner}
                                                    placeholder="User Type" />
                                            </div>

                                            <span className="error-message">{this.state.errors["netiIDError"]}</span>
                                        </div>
                                         }
                                         {this.state.firstDropdownIsLoading ?
                                         this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-col-12 p-xl-12">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Point Type <span>*</span></span>
                                                    <Dropdown
                                                        options={pointType}
                                                        autoWidth={false}
                                                        onChange={this.selectPointType}
                                                        value={this.state.selectPointType}
                                                        placeholder="Point Type" />
                                                </div>

                                                <span className="error-message">{this.state.errors["netiIDError"]}</span>
                                            </div>
                                        }
                                        

                                        <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Search"
                                                onClick={this.getSenderInformation}
                                                icon="fas fa-search"
                                            />
                                        </div>

                                        <div className="p-col-12" />
                                        <div className="p-col-12" />
                                        <div className="p-col-12" />

                                        <div className="p-col-12 p-lg-12 p-xl-12 p-col-nogutter">
                                            {
                                                this.state.dataTableIsLoading ? this.NetiContentLoader.MyPointTableLoader() :
                                                    <div className="nw-data-table">
                                                        <DataTable
                                                            value={dataTableValue}
                                                            header={header}
                                                            responsive={true}
                                                            selection={this.state.selectedCustomers}
                                                            onSelectionChange={e => this.setState({ selectedCustomers: e.value })}
                                                            paginator={true}
                                                            rows={10}
                                                        >
                                                            <Column selectionMode="multiple" style={{ width: '3em' }} />
                                                            <Column field="netiID" header="Neti ID" filter={true} />
                                                            <Column field="name" header="Name" filter={true} />
                                                            <Column field="mobile" header="Mobile No. / Email" filter={true} body={mobileTemplate} />
                                                            <Column field="expireDate" header="Expire Date" filter={true} />
                                                            <Column field="expireDays" header="Expire Day" filter={true} />
                                                        </DataTable>

                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-xl-4">
                            <div className="p-col-nogutter nw-message-view-main">
                                <div className="p-col-12 p-xl-12 nw-message-view header">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Total Contacts </span>

                                        <Button
                                            label={selectedCustomers.length || "0"}
                                            onClick={(e) => window.location.href = "#"}
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12 p-col-nogutter nw-message-view body nw-tabview">
                                        <div className="nw-data-table">
                                            <DataTable
                                                value={selectedCustomers}
                                                header={headerForMessage}
                                                responsive={true}
                                                paginator={true}
                                                rows={10}
                                            >
                                                <Column field="netiID" header="Neti ID" />
                                                <Column field="name" header=" Name" />
                                                <Column field="netiID" header="Message" body={messageTemplate} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className='p-grid'>
                                    <div className="p-col-12 p-lg-12 p-xl-12 nw-button-parent">
                                        <div className="required-field"></div>
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Send"
                                            onClick={this.showSMSDialog}
                                            icon="fas fa-check"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="dialog-section">
                                <Dialog header="Send SMS" visible={this.state.smsDialogVisible} onHide={this.IdTerminateConfirmation} className="nw-confirm-dialog">
                                    <div className="p-fluid">
                                        <div className="p-col-12 p-col-12">
                                            <div className="confirm-wrapper">
                                                <div className="body-wrapper">
                                                    <header>Total messages you are about to send</header>
                                                    <h1>{selectedCustomers.length}</h1>
                                                    <footer>{selectedCustomers.length}  Person x {selectedCustomers.length} Message</footer>
                                                </div>
                                                <h1>Are You Sure?</h1>
                                                <div className="nw-button-parent-multiple">
                                                    <Button
                                                        className="p-button-danger nw-button nw-button-multiple"
                                                        label="No"
                                                        icon="fas fa-times"
                                                        onClick={this.IdTerminateConfirmation}
                                                    />
                                                    <Button
                                                        className="p-button-primary nw-button nw-button-multiple"
                                                        label="Yes"
                                                        icon="fas fa-check"
                                                        onClick={this.onSubmitSendSMSorMail}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}
