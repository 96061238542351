import React, { Component } from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import {CommonSearchByClassAndGroup} from './CommonSearchByClassAndGroup';



export class AdmisiaPointTransfer extends Component { 
    constructor(props) {
        super(props);
        
    }


    render() {
        return (
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12 nw-tabview">
                        <TabView>
                            <TabPanel header="Pending For Transfer" leftIcon="fas fa-download">
                                <CommonSearchByClassAndGroup componentName="pendingTransfer"/>
                            </TabPanel>
                            <TabPanel header="Transferred Applicants" leftIcon="fas fa-upload">
                                <CommonSearchByClassAndGroup componentName="transferredApplicants"/>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        )
    }
}