import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Growl } from 'primereact/growl';
import { Dialog } from 'primereact/dialog';
import { ErrorMessageView } from '../../../common/ErrorMessageView';
import { Calendar } from 'primereact/calendar';
import { SellProductCodeInfoTable } from '../../../myPoint/sellProduct/SellProductCodeInfoTable';
import { NetiDateUtils } from '../../../../utils/NetiDateUtils';
import { SalesServices } from '../../../../service/sales/SalesServices';
import { BlankDataTableAnim } from '../../../common/BlankDataTableAnim';
import NetiContentLoader from '../../../common/NetiContentLoader';
import { ValidatorUtility } from '../../../../utils/ValidatorUtility';
import { pdfDownloadLandscape_TableWithSubTitle } from '../../../common/JsPdfDownload';

let maxDate = new Date();
let pdfTitleHeader = "";
let pdfTitleHeaderData = "";

export class SellProductLogViewMore extends Component {
    constructor() {
        super();
        this.state = {
            inputErrorMessage: true,
            errorMsgVisible: false,
            topProgressBar: false,
            errorMsgBody: '',
            mobileDialog: false,
            searchData: {
                requestStartDate: '',
                requestEndDate: '',
                requestType: 'sales',
                transactionType: 'sales',
                pageNo: 0,
                limit: 10
            },
            rowInformation: {

            },
            searchDateError: [],
            dataViewValue: [],
            visible: false,
            dataTableValue: [],
            dataTableIsLoading: false
        }
        this.onHide = this.onHide.bind(this);
        this.sellCodeBody = this.sellCodeBody.bind(this);
        this.viewDialog = this.viewDialog.bind(this);
        this.salesServices = new SalesServices();
        this.NetiContentLoader = new NetiContentLoader();
        this.ValidatorUtility = new ValidatorUtility();
    }

    viewDialog(rowData) {
        let { rowInformation } = this.state
        rowInformation.salesDate = rowData.salesDate
        rowInformation.productName = rowData.productName
        rowInformation.purchaseCode = rowData.purchaseCode
        rowInformation.salesPrice = rowData.salesPrice
        rowInformation.customerName = rowData.customerName
        rowInformation.customerContact = rowData.customerContact
        rowInformation.salesNote = rowData.salesNote
        this.setState({ rowInformation })
        this.setState({ visible: true });
    }

    onHide(event) {
        this.setState({ visible: false });
    }

    sellCodeBody(rowData, column) {

        return <div className='text-center'>
            <Button
                className="nw-action-button info"
                icon="fas fa-info"
                title="View Purchase"
                onClick={(e) => this.viewDialog(rowData)}
            />
        </div>
    }

    startDateHandler = (event, props) => {
        let { searchData, searchDateError } = this.state;
        searchData.requestStartDate = event.target.value;
        searchDateError["searchDateErrorStart"] = "";
        this.setState({ searchData, searchDateError })      
    }

    endDateHandler = (event, props) => {
        let { searchData, searchDateError } = this.state;
        searchData.requestEndDate = event.target.value;
        searchDateError["searchDateErrorEnd"] = "";
        this.setState({ searchData, searchDateError })
    }

    onSubmitHandler = (event, props) => {
        let { searchData } = this.state;
        if (this.searchErrorHandler()) {
            this.setState({ tableView: true, topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false });
            if (Date.parse(searchData.requestEndDate) >= Date.parse(searchData.requestStartDate)) {
                this.salesServices.getSalesListByDateRange(this.state.searchData)
                    .then(res => {
                        if (res.status === 302) {
                            return res.json().then((body) => {
                                
                                if (body.length == 0) {
                                    return this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: 'No Data Found.' })
                                }

                                for (let i = 0; i < body.length; i++) {
                                    if (body[i].salesDate == null) {
                                        body[i].salesDate = '';
                                    } else {
                                        body[i].salesDate = NetiDateUtils.getAnyShortForm(body[i].salesDate, 'DD-MMM-YYYY hh:mm:ss a'); 
                                        
                                    }

                                    body[i].salesPrice = body[i].salesPrice.toFixed(2)
                                }

                                this.setState({ dataTableValue: body, tableView: true, inputErrorMessage: false, topProgressBar: false, dataTableIsLoading: false });

                            })
                        } else {
                            this.salesServices.Auth.handleErrorStatus(res)
                                .then((responseBody) => {
                                    this.setState({ topProgressBar: false, tableView: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                });
                        }
                    }).catch(err => {
                        this.setState({ topProgressBar: false, dataTableIsLoading: false, tableView: false, errorMsgVisible: false, errorMsgBody: 'Please check your connection' });
                    });
            } else {
                this.growl.show({ severity: 'error', summary: 'Error', detail: "From Date can't be ahead of To Date." });
            }

        }
    }

    salesPriceTemplate = (rowData) => {
        return this.ValidatorUtility.currencyFormatter(rowData.salesPrice)
    }

    searchErrorHandler = () => {
        let searchDateError = {};
        let formIsValid = true;
        if (this.state.searchData.requestStartDate === '') {
            formIsValid = false;
            searchDateError["searchDateErrorStart"] = "Start Date can't left empty";
        }
        if (this.state.searchData.requestEndDate === '') {
            formIsValid = false;
            searchDateError["searchDateErrorEnd"] = "End Date can't left empty";
        }
        this.setState({ searchDateError: searchDateError });
        return formIsValid;
    }

    exportPdf = () => {

        let tableColumns = [
          { title: "Sales Date", dataKey: "salesDate" },
          { title: "Product Name", dataKey: "productName" },
          { title: "Purchase Code", dataKey: "purchaseCode" },
          { title: "Sales Price", dataKey: "salesPrice" },
          { title: "Customer Name", dataKey: "customerName" },
          { title: "Customer Mobile", dataKey: "customerContact" },
        ];

        let tableColumnStyles = {
            salesPrice: { halign: "right"},
        }

        // pdfDownloadLandscape_TableWithSubTitle("Sell Product Report",110, tableColumns, this.state.dataTableValue, tableColumnStyles, "sell_product_report.pdf");

        pdfDownloadLandscape_TableWithSubTitle("Sell Product Report",120, pdfTitleHeader, pdfTitleHeaderData, tableColumns, this.state.dataTableValue, tableColumnStyles,  "sell_product_report.pdf");


    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody, dataTableValue, searchData } = this.state;
console.log('searchData.requestStartDate', this.state.searchData.requestStartDate);

        let tableHeader = <div className="header-title">
                            <div className="header-title-left">
                                Product Sell List 
                            </div>
                            <div className="header-title-right">
                                <a>
                                    Total Found: {dataTableValue.length.toLocaleString("EN-IN")}
                                </a>
                            </div>
                        </div>;

        pdfTitleHeader = [
            { dataKey: "A" },
            { dataKey: "B" },
            { dataKey: "C" },
            { dataKey: "D" }
        ];
        pdfTitleHeaderData = [
        { A: "From Date", B: NetiDateUtils.getAnyShortForm(searchData.requestStartDate, 'DD-MMM-YYYY'), C: "To Date", D: NetiDateUtils.getAnyShortForm(searchData.requestEndDate, 'DD-MMM-YYYY')}
        ];

        return (
            <div className="p-fluid">
                <Growl ref={(el) => this.growl = el} />
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="nw-form">
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form-body">
                            <div className="p-col-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon ">Start Date <span>*</span></span>
                                    <Calendar
                                        value={this.state.searchData.requestStartDate}
                                        onChange={(event) => this.startDateHandler(event, this.props)}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.searchDateError["searchDateErrorStart"]}</span>

                            </div>
                            <div className="p-col-12 p-xl-5">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon ">End Date <span>*</span></span>
                                    <Calendar
                                        value={this.state.searchData.requestEndDate}
                                        onChange={(event) => this.endDateHandler(event, this.props)}
                                        showIcon={true}
                                        yearRange="2010:2030"
                                        dateFormat="dd/mm/yy"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        dateOnly="true"
                                        maxDate={maxDate}
                                        placeholder="Select Date"
                                    />
                                </div>
                                <span className="error-message">{this.state.searchDateError["searchDateErrorEnd"]}</span>

                            </div>
                            <div className="p-col-12 p-xl-2 nw-button-parent">
                                <Button
                                    className="p-button p-button-primary nw-button nw-button-center"
                                    label="Search"
                                    icon="fas fa-search"
                                    onClick={this.onSubmitHandler.bind(this)}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="p-col-12 p-xl-12">
                        <div className="nw-data-table">
                            {this.state.tableView == true ?
                                this.state.dataTableIsLoading ?
                                    this.NetiContentLoader.MyPointTableLoader() :
                                    <div>
                                        <DataTable
                                            header={tableHeader}
                                            value={this.state.dataTableValue}
                                            selectionMode="single"
                                            selection={this.state.dataTableSelection}
                                            onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                            responsive={true}
                                            paginator={true}
                                            rows={10}
                                            rowsPerPageOptions={[10, 20, 30, 50, 100]}
                                        >
                                            <Column field="salesDate" header="Sales Date" sortable={true} filter={true} />
                                            <Column field="productName" header="Product Name" sortable={true} filter={true} />
                                            <Column field="purchaseCode" header="Purchase Code" sortable={true} filter={true} />
                                            <Column field="salesPrice" header="Sales Price" className="text-right" sortable={true} filter={true} body={this.salesPriceTemplate} />
                                            <Column field="customerName" header="Customer Name" sortable={true} filter={true} />
                                            <Column field="customerContact" header="Customer Mobile" sortable={true} filter={true} />
                                            <Column field="processDate" header="Action" style={{width:"96px"}} body={this.sellCodeBody} />
                                        </DataTable>
                                        <div className="nw-button-parent m-t-8 p-r-0">
                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Download"
                                            icon="fas fa-download"
                                            onClick={this.exportPdf}
                                            />
                                        </div>
                                    </div>

                                : <BlankDataTableAnim />
                            }
                        </div>

                    </div>






                    <Dialog className="nw-dialog" header="Product Sell information" visible={this.state.visible} onHide={this.onHide} maximizable>
                        <div className="p-fluid">
                            <div className="p-grid nw-form">
                                <SellProductCodeInfoTable getSalesProductInfo={this.state.rowInformation} />
                            </div>
                        </div>
                    </Dialog>


                </div>

            </div>
        )
    }
}