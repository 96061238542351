import React, { Component } from 'react';

export class AdmisiaEligibilityStatus {

    checkStatus(status) {
        if(status === undefined){
            return null;
        }
        let contentType = '';
        if (status == 0) {
            contentType = 'Pending For Assessment';
        } 
        else if (status == 1) {
            contentType = 'Approved For Assessment';
        }
        else if (status == 2) {
            contentType = 'Rejected For Assessment';
        }
        else if (status == 3) {
            contentType = 'Rejected For Admission';
        }
        else if (status == 4) {
            contentType = 'Waiting For Admission';
        }
        else if (status == 5) {
            contentType = 'Approved For Admission';
        }
        else if (status == 10) {
            contentType = 'Transferred For Admission';
        }

        return contentType;
    }
}
