import React, { Component } from "react";
import { NetiDateUtils } from "../../../utils/NetiDateUtils";
import { ValidatorUtility } from "../../../utils/ValidatorUtility";

export class SellProductCodeInfoTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowInformation: this.props.getSalesProductInfo
    };
    this.dateFormatTempl = this.dateFormatTempl.bind(this);
    this.ValidatorUtility = new ValidatorUtility();
  }

  dateFormatTempl(salesDate) {
    return NetiDateUtils.getAnyShortForm(salesDate, "DD-MMM-YYYY");
  }

  render() {
    const salesDate = this.state.rowInformation.salesDate
    return (

      <div className="p-col-12 p-xl-12">
        <div className="formControl">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon nw-inputgroup-label">
              Sales Date
                      </span>
            <div className="nw-inputgroup-desc">
              {salesDate}
            </div>
          </div>
        </div>

        <div className="formControl">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon nw-inputgroup-label">
              Product Name
                      </span>
            <div className="nw-inputgroup-desc">
              {this.state.rowInformation.productName}
            </div>
          </div>
        </div>

        <div className="formControl">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon nw-inputgroup-label">
              Purchase Code
                      </span>
            <div className="nw-inputgroup-desc">
              {this.state.rowInformation.purchaseCode}
            </div>
          </div>
        </div>

        <div className="formControl">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon nw-inputgroup-label">
              Sales Price
                      </span>
            <div className="nw-inputgroup-desc">
              {this.ValidatorUtility.currencyFormatter(this.state.rowInformation.salesPrice)}
            </div>
          </div>
        </div>

        <div className="formControl">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon nw-inputgroup-label">
              Sales Note
                    </span>
            <div className="nw-inputgroup-desc">
              {this.state.rowInformation.salesNote}
            </div>
          </div>
        </div>

        <div className="formControl">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon nw-inputgroup-label">
              Customer Name
                    </span>
            <div className="nw-inputgroup-desc">
              {this.state.rowInformation.customerName}
            </div>
          </div>
        </div>

        <div className="formControl">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon nw-inputgroup-label">
              Customer Mobile No
                    </span>
            <div className="nw-inputgroup-desc">
              {this.state.rowInformation.customerContact}
            </div>
          </div>
        </div>
      </div>




    );
  }
}
