import { BaseURIHolder } from '../../utils/BaseURIHolder';
import AuthService from '../../auth/AuthService';

export class SupportPointService {

    constructor() {
        this.baseURIHolder = new BaseURIHolder();
        this.Auth = new AuthService();
    }
    
    createCallLog(requestedObject) {
        let uri = this.baseURIHolder.getSupport() + '/call_log/new';
        return this.Auth.postFetch(uri, requestedObject)
        .catch((error) => console.log("error",error));
    }

    getDeviceInfoByPurchaseCode(purchaseCode) {
        let uri = this.baseURIHolder.getSupport() + `/unlock/password/by/purchasecode?code=${purchaseCode}`;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log("error",error));
    }
   
    saveReplaceDeviceLog(deviceInfo) {
        let uri = this.baseURIHolder.getSupport() + '/replace/device';
        return this.Auth.postFetch(uri, deviceInfo)
        .catch((error) => console.log("error",error));
    }

    fetchUserByrole() {
        let uri = this.baseURIHolder.getUser() + '/by/role';
        return this.Auth.getFetch(uri)
        .catch((error) => console.log('error', error));
    }

    saveGlobalBookInfo(bookInfo) {
        let uri = this.baseURIHolder.getSupport() + '/book/global/add';
        return this.Auth.postFetch(uri, bookInfo)
        .catch((error) => console.log("error",error));
    }

    deleteBookInfo(globalBookId) {
        let uri = this.baseURIHolder.getSupport() + `/book/global/delete?id=${globalBookId}`;
        return this.Auth.deleteFetch(uri)
            .catch((error) => console.log("error", error));
    }

    
    getBookListByStatus(acceptableStatus) {
        let uri = this.baseURIHolder.getSupport() + `/book/global/list/by/status?status=${acceptableStatus}`;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log('error', error));
    }

    changeBookStatus(id,status) {
        let uri = this.baseURIHolder.getSupport() + `/book/global/change/status?id=${id}&status=${status}`;
        return this.Auth.putFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getBookInfoByID(bookID) {
        let uri = this.baseURIHolder.getSupport() + `/book/global/by/id?id=${bookID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateBookInfo(bookInfoDetails) {
        let uri = this.baseURIHolder.getSupport() + '/book/global/update';
        return this.Auth.putFetch(uri,bookInfoDetails)
            .catch((error) => console.log('error', error));
    }

    getAllPendingToken() {
        let uri = this.baseURIHolder.getSupport() + '/token/manage/pending';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getAllProcessingToken() {
        let uri = this.baseURIHolder.getSupport() + '/token/manage/processing/by/user';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getTopTenSolvedTokenList() {
        let uri = this.baseURIHolder.getSupport() + '/token/manage/solved/top10/by/user';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getTokensByTokenInfoID(tokenInfoID) {
        let uri = this.baseURIHolder.getSupport() + `/tokens/by/token-info-id?tokenInfoID=${tokenInfoID}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    editTokenInfo(tokenInfo) {
        let uri = this.baseURIHolder.getSupport() + '/tokens/edit';
        return this.Auth.putFetch(uri,tokenInfo)
            .catch((error) => console.log('error', error));
    }

    fetchAllBankList() {
        let uri = this.baseURIHolder.getUser() + `/cs/banking/bank/find-all`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    createBankOfEmGlobalInfo(bankName, defaultId, note) {
        let uri = this.baseURIHolder.getUser() + `/cs/banking/bank/save?bankName=${bankName}&defaultId=${defaultId}&note=${note}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    updateBankOfEmGlobalInfo(requestedObj) {
        let uri = this.baseURIHolder.getUser() + `/cs/banking/bank/update?bankId=${requestedObj.bankIdUpdate}&bankName=${requestedObj.bankNameUpdate}&defaultId=${requestedObj.defaultIdUpdate}&note=${requestedObj.noteUpdate}`;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    addNewTypeInEmGlobalInfo(name, typeId) {
        let uri = this.baseURIHolder.getUser() + `/cs/global-info/save?name=${name}&typeId=${typeId}`;
        return this.Auth.getFetch(uri)
            .catch((error) => { console.log(error) })
    }

    createBankAccount(requestedObject) {
        let uri = this.baseURIHolder.getUser() + `/cs/banking/bank/account/save?branchName=${requestedObject.branchName}&branchId=${requestedObject.branchId}&bankId=${requestedObject.bankId}&accountType=${requestedObject.accountType}&accountNumber=${requestedObject.accountNumber}&accountHolderName=${requestedObject.accountHolder}&accountDetails=${requestedObject.accountDetails}`;
        return this.Auth.getFetch(uri)
            .catch((error) => { console.log(error) })
    }

    fetchBankAccountList() {
        let uri = this.baseURIHolder.getUser() + `/cs/banking/bank/account/find-all`;
        return this.Auth.getFetch(uri)
            .catch((error) => { console.log(error) })
    }

    updateBankAccount(requestedObject) {
        let status;
        { requestedObject.status === "Active" ? status = 1 : status = 0}
        let uri = this.baseURIHolder.getUser() + `/cs/banking/bank/account/update?branchName=${requestedObject.branchName}&branchId=${requestedObject.branchId}&bankId=${requestedObject.bankId}&accountType=${requestedObject.accountType}&accountNumber=${requestedObject.accountNumber}&accountHolderName=${requestedObject.accountHolderName}&accountDetails=${requestedObject.accountDetails}&bankAccId=${requestedObject.bankAccountId}&status=${status}`;
        return this.Auth.getFetch(uri)
            .catch((error) => { console.log(error) })
    }

    fetchInstituteDetailsByInstituteID(instituteId) {
        let uri = this.baseURIHolder.getUser() + `/cs/banking/institute/find/by/institute-id?instituteId=${instituteId}`;
        return this.Auth.getFetch(uri)
            .catch((error) => { console.log(error) })
    }

    fetchAccountNumberListByBankId(bankId){
    let uri = this.baseURIHolder.getUser() + `/cs/banking/bank/account-map/by/bank-id?bankId=${bankId}`;
    return this.Auth.getFetch(uri)
        .catch((error) => { console.log(error) })
    }

    submitForMappingAccount(bankAccountId, instituteId, bankNote) {
        let uri = this.baseURIHolder.getUser() + `/cs/banking/bank/account-map/with-institute/save?bankAccountId=${bankAccountId}&instituteId=${instituteId}&note=${bankNote}`;
        return this.Auth.getFetch(uri)
            .catch((error) => { console.log(error) })
    }

    fetchAllMappedAccountList() {
        let uri = this.baseURIHolder.getUser() + `/cs/banking/bank/account-map/with-institute/find-all`;
        return this.Auth.getFetch(uri)
            .catch((error) => { console.log(error) })
    }

    fetchTokenInfoByContactNoAndApplicationType(contactNo, pointTypeValue) {
        let uri = this.baseURIHolder.getUser() + "/get/institute-user/by?cellNo=" + contactNo + "&application=" + pointTypeValue;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log('error', error));
    }

    fetchTokenApplicationDropDown(defaultCode, typeStatus) {
        let uri = this.baseURIHolder.getUser() + "/tokens/application/by-typeDefaultCode?typeDefaultCode=" + defaultCode + "&typeStatus=" + typeStatus;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log('error', error));
    }

    getTokenInfoByTokenID(tokenID) {
        let uri = this.baseURIHolder.getSupport() + `/token/get/by/token-id?tokenID=${tokenID}`;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log('error', error));
    }

    fetchTokenInfoByCustomID(netiId) {
        let uri = this.baseURIHolder.getUser() + "/profile/by/custom_id?custom_id=" + netiId;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log('error', error));
    }

    getTokenInfoByMobileNo(mobileNo) {
        let uri = this.baseURIHolder.getSupport() + `/token/get/by/cellno?contactNumber=${mobileNo}`;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log('error', error));
    }

    fetchTokenInfoByDeviceInstituteID(deviceInstituteId) {
        let uri = this.baseURIHolder.getUser() + "/em/institute/info/by/?instituteID=" + deviceInstituteId;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log('error', error));
    }

    getTokenInfoByInstituteID(instituteID) {
        let uri = this.baseURIHolder.getSupport() + `/token/get/by/institute-id?instituteID=${instituteID}`;
        return this.Auth.getFetch(uri)
        .catch((error) => console.log('error', error));
    }

    updateMappedAccount(requestedObj) {
        let uri = this.baseURIHolder.getUser() + `/cs/banking/bank/account-map/with-institute/update?bankAccountId=${requestedObj.bankAccountId}&instituteId=${requestedObj.instituteId}&note=${requestedObj.bankNote}&accountMapId=${requestedObj.accountMapId}`;
        return this.Auth.getFetch(uri)
            .catch((error) => { console.log(error) })
    }

    fetchDWS_MenuConfigurationList() {
        let uri = this.baseURIHolder.getSupport() + `/menu/config/list`
        return this.Auth.getFetch(uri)
        .catch((error) => { console.log(error) })
    }

    submitDWS_MenuConfig(requestedObj) {
        let uri = this.baseURIHolder.getSupport() + `/menu/config/change/status?key=${requestedObj.menuName}&status=${requestedObj.status}`
        return this.Auth.putFetch(uri)
        .catch((error) => { console.log(error) })
    }

    getUserTokenByDefaultCategory(catDefaultCode){
        let uri = this.baseURIHolder.getUser() + '/tokens/types/by?parentCategoryID=' + catDefaultCode;
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    getUserListByRole(catDefaultCode){
        let uri = this.baseURIHolder.getUser() + '/by/role';
        return this.Auth.getFetch(uri)
            .catch((error) => console.log('error', error));
    }

    postNetiWorldTokenRequirements(reqObj) {
        let uri = this.baseURIHolder.getSupport() + '/tokens/new';
        return this.Auth.postFetch(uri, reqObj)
        .catch((error) => console.log("error",error));
    }

    postEdumanTokenRequirements(reqObj) {
        let uri = this.baseURIHolder.getSupport() + '/tokens/eduman/new';
        return this.Auth.postFetch(uri, reqObj)
        .catch((error) => console.log("error",error));
    }   

    // downloadTokenStageFile(tokenInfoID){
    //     let uri = this.baseURIHolder.getSupport() + '/token/attachment/download/by/${tokenInfoID}';
    //     return this.Auth.getFetch(uri)
    //         .catch((error) => console.log('error', error));
    // }

  

}
