// import React, { Component } from 'react';
// import { StudentPortalMain } from './StudentPortalMain'
// import { Fieldset } from 'primereact/fieldset';
// import { Panel } from 'primereact/panel';
// import { PortalProfileInfo } from '../common/PortalProfileInfo';
// import { Growl } from 'primereact/growl';
// import { Button } from 'primereact/button';
// import { Menu } from 'primereact/menu';
// export class StudentProfile extends Component {

//     componentDidMount() {
//         //if Data value doesn't exist, fill the value field with three dots (...)
//         //This is only for exceptional empty/null/undefined output
//         setTimeout(() => {
//             [...document.querySelectorAll('.protal-single-info-content')].map(item => {
//                 if (!item.querySelector('p').innerHTML.trim()) {
//                     item.querySelector('p').innerHTML = '---'
//                 }
//             })
//         }, 1500);
//     }

//     constructor(props) {
//         super(props);
//         this.state = {
//             studentInfo: [],
//         }
//         this.StudentPortalMain = new StudentPortalMain();
//     }

//     render() {
//         let basicInfo = this.props.basicInfo;
//         // let staticImg = 'assets/layout/images/userAvatar.png';
//         // let stdName, stdCustomId, stdImage, instituteID, stdPresentAddress;

//         // if (basicInfo) {
//         //     stdName = basicInfo.studentName;
//         //     stdCustomId = basicInfo.customStudentId;
//         //     instituteID = basicInfo.instituteId;
//         //     stdImage = this.props.basicInfo.studentImageForView ? "data:image/jpeg;base64," + this.state.basicInfo.studentImageForView : null;
//         // }

//         return (
//             <div className="p-fluid nw-form nw-w-100">
//                 <div className="p-col-12">
//                     <PortalProfileInfo basicInfo={this.props.basicInfo} />
//                 </div>
//                 <div className="p-col-12 nw-m-t-80">
//                     <div className="p-grid m-l-m-35 m-r-m-35">
//                         <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
//                             <div className="protal-single-info-box">
//                                 <div className="protal-single-info-title">
//                                     <h4>Basic Info</h4>
//                                 </div>
//                                 <div className="protal-single-info-content-box">
//                                     <div className="protal-single-info-content">
//                                         <h4>Gender</h4>
//                                         <p>{this.props.basicInfo.studentGender || '---'}</p>
//                                     </div>
//                                     <div className="protal-single-info-content">
//                                         <h4>Religion</h4>
//                                         <p>{this.props.basicInfo.studentReligion || '---'}</p>
//                                     </div>
//                                     <div className="protal-single-info-content">
//                                         <h4>Date of Birth</h4>
//                                         <p>{this.props.basicInfo.studentDOB || '---'}</p>
//                                     </div>
//                                     <div className="protal-single-info-content">
//                                         <h4>Mobile No.</h4>
//                                         <p>{this.props.basicInfo.studentMobile || '---'}</p>
//                                     </div>
//                                     <div className="protal-single-info-content">
//                                         <h4>Email</h4>
//                                         <p>{this.props.basicInfo.studentEmail || '---'}</p>
//                                     </div>
//                                     <div className="protal-single-info-content">
//                                         <h4>Birth Certificate No.</h4>
//                                         <p>{this.props.basicInfo.birthCertificateNo || '---'}</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
            //             <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
            //                 <div className="protal-single-info-box">
            //                     <div className="protal-single-info-title">
            //                         <h4>Academic Info</h4>
            //                     </div>
            //                     <div className="protal-single-info-content-box">
            //                         <div className="protal-single-info-content">
            //                             <h4>Class</h4>
            //                             <p>{this.props.basicInfo.className || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Shift</h4>
            //                             <p>{this.props.basicInfo.shiftName || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Section</h4>
            //                             <p>{this.props.basicInfo.sectionName || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Roll No.</h4>
            //                             <p>{this.props.basicInfo.studentRoll || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Group</h4>
            //                             <p>{this.props.basicInfo.groupName || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Category</h4>
            //                             <p>{this.props.basicInfo.admissionCategory || '---'}</p>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //             <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
            //                 <div className="protal-single-info-box">
            //                     <div className="protal-single-info-title">
            //                         <h4>Parent Info</h4>
            //                     </div>
            //                     <div className="protal-single-info-content-box">
            //                         <div className="protal-single-info-content">
            //                             <h4>Father's Name</h4>
            //                             <p>{this.props.basicInfo.fatherName || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Mother's Name</h4>
            //                             <p>{this.props.basicInfo.motherName || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Guardian Mobile No.</h4>
            //                             <p>{this.props.basicInfo.guardianMobile || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Roll No.</h4>
            //                             <p>{this.props.basicInfo.studentRoll || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Mother's NID</h4>
            //                             <p>{this.props.basicInfo.mothersNID || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Guardian Email</h4>
            //                             <p>{this.props.basicInfo.guardianEmail || '---'}</p>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //             <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
            //                 <div className="protal-single-info-box">
            //                     <div className="protal-single-info-title">
            //                         <h4>Address Info</h4>
            //                     </div>
            //                     <div className="protal-single-info-content-box">
            //                         <div className="protal-single-info-content">
            //                             <h4>Present Address</h4>
            //                             <p>{`${this.props.basicInfo.presentHouseNo ? "House- " + this.props.basicInfo.presentHouseNo + ", Road- " : ''}
            //                              ${this.props.basicInfo.presentRoadNo ? this.props.basicInfo.presentRoadNo + ", Village- " : ''}
            //                              ${this.props.basicInfo.presentVillage ? this.props.basicInfo.presentVillage + ", P.O- " : ''}
            //                              ${this.props.basicInfo.postalCode ? this.props.basicInfo.postalCode + ", P.S- " : ''}
            //                              ${this.props.basicInfo.presentThana ? this.props.basicInfo.presentThana + ", District- " : ''}
            //                              ${this.props.basicInfo.presentDistrict ? this.props.basicInfo.presentDistrict + ", Country- " : ''}
            //                              ${this.props.basicInfo.country ? this.props.basicInfo.country : ''}`.trim() || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Permanent Address</h4>
            //                             <p>{`${this.props.basicInfo.houseNo ? "House- " + this.props.basicInfo.houseNo + ", Road- " : ''} 
            //                             ${this.props.basicInfo.roadNo ? this.props.basicInfo.roadNo + ", Village- " : ''}
            //                             ${this.props.basicInfo.village ? this.props.basicInfo.village + ", P.O- " : ''}
            //                             ${this.props.basicInfo.postOffice ? this.props.basicInfo.postOffice + ", P.S- " : ''}
            //                             ${this.props.basicInfo.thana ? this.props.basicInfo.thana + ", District- " : ''}
            //                             ${this.props.basicInfo.district ? this.props.basicInfo.district + ", Country- " : ''}
            //                             ${this.props.basicInfo.country ? this.props.basicInfo.country : ''}`.trim() || '---'}</p>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //             <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
            //                 <div className="protal-single-info-box">
            //                     <div className="protal-single-info-title">
            //                         <h4>Medical Info</h4>
            //                     </div>
            //                     <div className="protal-single-info-content-box">
            //                         <div className="protal-single-info-content">
            //                             <h4>Blood Group</h4>
            //                             <p>{this.props.basicInfo.bloodGroup || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Height</h4>
            //                             <p>{this.props.basicInfo.height || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Weight</h4>
            //                             <p>{this.props.basicInfo.weight || '---'}</p>
            //                         </div>
            //                         {/* <div className="protal-single-info-content">
            //                             <h4>Eye Color</h4>
            //                             <p></p>
            //                         </div> */}
            //                         <div className="protal-single-info-content">
            //                             <h4>Special Disease</h4>
            //                             <p>{this.props.basicInfo.specialDisease || '---'}</p>
            //                         </div>
            //                         {/* <div className="protal-single-info-content">
            //                             <h4>Last Treatment Date</h4>
            //                             <p></p>
            //                         </div> */}
            //                     </div>
            //                 </div>
            //             </div>
            //             <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
            //                 <div className="protal-single-info-box">
            //                     <div className="protal-single-info-title">
            //                         <h4>Previous Institute Info</h4>
            //                     </div>
            //                     <div className="protal-single-info-content-box">
            //                         <div className="protal-single-info-content">
            //                             <h4>Institute Name</h4>
            //                             <p>{this.props.basicInfo.previousInstituteName || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Address</h4>
            //                             <p>{this.props.basicInfo.previousInstituteAddress || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Contact No.</h4>
            //                             <p>{this.props.basicInfo.instituteMobile || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Email</h4>
            //                             <p>{this.props.basicInfo.instituteEmail || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Time Period</h4>
            //                             <p>{this.props.basicInfo.timePeriod || '---'}</p>
            //                         </div>
            //                         <div className="protal-single-info-content">
            //                             <h4>Last Education</h4>
            //                             <p>{this.props.basicInfo.lastEducation || '---'}</p>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div >



//         )
//     }
// }






















import React, { Component } from 'react';
import { StudentPortalMain } from './StudentPortalMain'
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import { PortalProfileInfo } from '../common/PortalProfileInfo';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { EmUserPointService } from '../../../service/emUserPoint/EmUserPointService';
import { StudentPortal } from "../../../service/emUserPoint/StudentPortal";

let userList2;
let stidd;
let insidd;
export class StudentProfile extends Component {


    constructor(props) {
        super(props);
        this.state = {
            studentInfo: [],
            basicInfo: [],
            stId: '',
            insId: '',
            topProgressBar: '',
            userList: [],
            userList2:[],
            userList3:{}
        }
        this.StudentPortalMain = new StudentPortalMain();
        this.emUserPointService = new EmUserPointService();
        this.studentPortal = new StudentPortal();
    }


    componentWillMount() {
        // debugger
        // let userListtt = [];
        // this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        // this.emUserPointService.fetchMappedPortalList()
        //     .then((res) => {
        //         if (res.status == 302) {
        //             return res.json().then((body) => {
        //                 console.log('Log: GoToPortal -> componentWillMount -> body', body)
        //                 body.map(item => {
        //                     if(item.mappingType === "Student") {
        //                       userListtt.push(item)
        //                     }
        //                 })
        //                 this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
        //             })
        //         } else {
        //             this.emUserPointService.Auth.handleErrorStatus(res)
        //                 .then((responseBody) => {
        //                     this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
        //                 });
        //         }
        //     }).catch((error) => {
        //         console.log(error)
        //         this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        //     });
        //     this.setState({userList: userListtt})
            // console.log("uslist...................", userListtt)
            // userList2 = userListtt;
        
    }
    
    componentDidMount() {
        var yy = '';
        
        // let userListtt = [];
        // this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        // this.emUserPointService.fetchMappedPortalList()
        //     .then((res) => {
        //         if (res.status == 302) {
        //             return res.json().then((body) => {
        //                 console.log('Log: GoToPortal -> componentWillMount -> body......', body[0].idForStudent)
        //                 this.setState({stId:body[0].idForStudent})
        //                 yy = body[0].idForStudent;
        //                 // console.log("y",y)
        //                 // this.setState({stId:body[0].idForStudent})
        //                 body.map(item => {
        //                     if(item.mappingType === "Student") {
        //                       userListtt.push(item)
        //                     }
        //                 })
        //                 this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
        //             })
        //         } else {
        //             this.emUserPointService.Auth.handleErrorStatus(res)
        //                 .then((responseBody) => {
        //                     this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
        //                 });
        //         }
        //     }).catch((error) => {
        //         console.log(error)
        //         this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        //     });
        //     //this.setState({userList: userListtt})
            
        //     const items = Object.assign({}, userListtt);
        //     for(let i in items){
        //         console.log(i, items[i])
        //     }

            // userListtt
         
        //if Data value doesn't exist, fill the value field with three dots (...)
        //This is only for exceptional empty/null/undefined output
        // setTimeout(() => {
        //     [...document.querySelectorAll('.protal-single-info-content')].map(item => {
        //         if (!item.querySelector('p').innerHTML.trim()) {
        //             item.querySelector('p').innerHTML = '---'
        //         }
        //     })
        // }, 1500);
        

        // let userListtt = [];
        // this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        // this.emUserPointService.fetchMappedPortalList()
        //     .then((res) => {
        //         if (res.status == 302) {
        //             return res.json().then((body) => {
        //                 console.log('Log: GoToPortal -> componentWillMount -> body', body)
        //                 body.map(item => {
        //                     if(item.mappingType === "Student") {
        //                       userListtt.push(item)
        //                     }
        //                 })
        //                 this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
        //             })
        //         } else {
        //             this.emUserPointService.Auth.handleErrorStatus(res)
        //                 .then((responseBody) => {
        //                     this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
        //                 });
        //         }
        //     }).catch((error) => {
        //         console.log(error)
        //         this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
        //     });
            //console.log("uslist...................", userListtt)
            // console.log("zfgsfd",userListtt)
            // console.log(typeof(this.state.userList))
            // userListtt.map ( (item) =>{
            //  console.log("ins ID : .........", item["emDetailsInfoDTO"].instituteId)
            //  console.log("STUDENT ID : .........", item.idForStudent)
 
            //  })
       
           this.loadStudentInfo();
      
    
           
    }

    loadStudentInfo = () => {
// start here
    let userListtt = [];
    var yy='';
        this.setState({ dataTableIsLoading: true, topProgressBar: true, errorMsgVisible: false });
        this.emUserPointService.fetchMappedPortalList()
            .then((res) => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        console.log('Log: GoToPortal -> componentWillMount -> body......', body[0].idForStudent)
                        this.setState({stId:body[0].idForStudent})
       


                        this.studentPortal
                        .fetchStudentProfileDetailsList(
                            body[0]["emDetailsInfoDTO"].instituteId,
                            body[0].idForStudent,
                        )
                        .then(res => {
                            if (res.status == 200) {
                              
                                return res.json().then(body => {
                                    this.setState({ topProgressBar: false, errorMsgVisible: false })
                                    this.setState({ basicInfo: body });
                        
                                });
                            } else {
                                this.studentPortal.Auth.handleErrorStatus(res)
                                    .then((responseBody) => {
                                        this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
                                    });
                            }
                        })
                        .catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));




















                       if(!body[0].idForStudent==undefined){
                        yy = body[0].idForStudent;}
                        // console.log("y",y)
                        // this.setState({stId:body[0].idForStudent})
                        body.map(item => {
                            if(item.mappingType === "Student") {
                              userListtt.push(item)
                            }
                        })
                        this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: false })
                    })
                } else {
                    this.emUserPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch((error) => {
                console.log(error)
                this.setState({ dataTableIsLoading: false, topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' });
            });


// end here

        console.log(this.yy)

    //    if(!stidd){
    //        console.log("asdfasdf.....................", stidd)
    //    }
       
    
        this.setState({ topProgressBar: true, errorMsgVisible: false })
        console.log(stidd)
        // this.studentPortal
        //     .fetchStudentProfileDetailsList(
        //         "14976",
        //         "149762292220",
        //     )
        //     .then(res => {
        //         if (res.status == 200) {
                  
        //             return res.json().then(body => {
        //                 this.setState({ topProgressBar: false, errorMsgVisible: false })
        //                 this.setState({ basicInfo: body });
            
        //             });
        //         } else {
        //             this.studentPortal.Auth.handleErrorStatus(res)
        //                 .then((responseBody) => {
        //                     this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: responseBody })
        //                 });
        //         }
        //     })
        //     .catch(error => this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data table. Please check connection' }));
    };

 

   
    render() {
      
       //console.log("userlist.....", this.state.userList) 

        // this.state.userList.map((item) =>{
        //     console.log("#########");
        //     stidd = item["emDetailsInfoDTO"].instituteId;
        //     insidd =  item.idForStudent
        //     console.log("ins ID : .........", item["emDetailsInfoDTO"].instituteId)
        //     console.log("STUDENT ID : .........", item.idForStudent)
 
        // })
        

       
        
        // let staticImg = 'assets/layout/images/userAvatar.png';
        // let stdName, stdCustomId, stdImage, instituteID, stdPresentAddress;

        // if (basicInfo) {
        //     stdName = basicInfo.studentName;
        //     stdCustomId = basicInfo.customStudentId;
        //     instituteID = basicInfo.instituteId;
        //     stdImage = this.props.basicInfo.studentImageForView ? "data:image/jpeg;base64," + this.state.basicInfo.studentImageForView : null;
        // }
        // let basicInfoList = [];

        // this.state.basicInfo.map ((item)=>{
        //     basicInfoList.push(item);
        // })

        // let basicInfoObj = basicInfoList[0];
        // console.log(basicInfoList);
        // console.log(basicInfoObj);
        // if(basicInfo !== undefined) {
        //     console.log(basicInfo["emDetailsInfoDTO"].assignedUserBasicInfoDTO.gender);
        //     check = true;
        // }
        let basicInfo = this.state.basicInfo;
        
        return (

            
              

            <div className="p-fluid nw-form nw-w-100">
                <div className="p-col-12">
                    <PortalProfileInfo basicInfo={this.state.basicInfo} />
                </div>
                <div className="p-col-12 nw-m-t-80">
                    <div className="p-grid m-l-m-35 m-r-m-35">
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Basic Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Gender</h4>
                                       
                                        <p>{this.state.basicInfo.studentGender || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Religion</h4>
                                        <p>{this.state.basicInfo.studentReligion || '---'}</p>
                                       
                                        
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Date of Birth</h4>
                                        <p>{this.state.basicInfo.studentDOB || '---'}</p>
                                       
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Mobile No.</h4>
                                        <p>{this.state.basicInfo.studentMobile || '---'}</p>
                                        
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Email</h4>
                                        <p>{this.state.basicInfo.studentEmail || '---'}</p>
                                        {/* { check && <p> {basicInfo["emDetailsInfoDTO"].assignedUserBasicInfoDTO.basicEmail}  </p>} */}
                                        {/* { check ? <p> {basicInfo["emDetailsInfoDTO"].assignedUserBasicInfoDTO.basicEmail} </p>: "......"} */}
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Birth Certificate No.</h4>
                                        <p>{this.state.basicInfo.birthCertificateNo || '---'}</p>
                                         {/* { check ? <p> {basicInfo["emDetailsInfoDTO"].assignedUserBasicInfoDTO.dateOfBirth} </p>: "......"} */}

                                         
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Academic Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Class</h4>
                                        <p>{this.state.basicInfo.className || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Shift</h4>
                                        <p>{this.state.basicInfo.shiftName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Section</h4>
                                        <p>{this.state.basicInfo.sectionName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Roll No.</h4>
                                        <p>{this.state.basicInfo.studentRoll || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Group</h4>
                                        <p>{this.state.basicInfo.groupName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Category</h4>
                                        <p>{this.state.basicInfo.admissionCategory || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Parent Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Father's Name</h4>
                                        <p>{this.state.basicInfo.fatherName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Mother's Name</h4>
                                        <p>{this.state.basicInfo.motherName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Guardian Mobile No.</h4>
                                        <p>{this.state.basicInfo.guardianMobile || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Roll No.</h4>
                                        <p>{this.state.basicInfo.studentRoll || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Mother's NID</h4>
                                        <p>{this.state.basicInfo.mothersNID || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Guardian Email</h4>
                                        <p>{this.state.basicInfo.guardianEmail || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Address Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Present Address</h4>
                                        <p>{`${this.state.basicInfo.presentHouseNo ? "House- " + this.state.basicInfo.presentHouseNo + ", Road- " : ''}
                                         ${this.state.basicInfo.presentRoadNo ? this.state.basicInfo.presentRoadNo + ", Village- " : ''}
                                         ${this.state.basicInfo.presentVillage ? this.state.basicInfo.presentVillage + ", P.O- " : ''}
                                         ${this.state.basicInfo.postalCode ? this.state.basicInfo.postalCode + ", P.S- " : ''}
                                         ${this.state.basicInfo.presentThana ? this.state.basicInfo.presentThana + ", District- " : ''}
                                         ${this.state.basicInfo.presentDistrict ? this.state.basicInfo.presentDistrict + ", Country- " : ''}
                                         ${this.state.basicInfo.country ? this.state.basicInfo.country : ''}`.trim() || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Permanent Address</h4>
                                        <p>{`${this.state.basicInfo.houseNo ? "House- " + this.state.basicInfo.houseNo + ", Road- " : ''} 
                                        ${this.state.basicInfo.roadNo ? this.state.basicInfo.roadNo + ", Village- " : ''}
                                        ${this.state.basicInfo.village ? this.state.basicInfo.village + ", P.O- " : ''}
                                        ${this.state.basicInfo.postOffice ? this.state.basicInfo.postOffice + ", P.S- " : ''}
                                        ${this.state.basicInfo.thana ? this.state.basicInfo.thana + ", District- " : ''}
                                        ${this.state.basicInfo.district ? this.state.basicInfo.district + ", Country- " : ''}
                                        ${this.state.basicInfo.country ? this.state.basicInfo.country : ''}`.trim() || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Medical Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Blood Group</h4>
                                        <p>{this.state.basicInfo.bloodGroup || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Height</h4>
                                        <p>{this.state.basicInfo.height || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Weight</h4>
                                        <p>{this.state.basicInfo.weight || '---'}</p>
                                    </div>
                                    {/* <div className="protal-single-info-content">
                                        <h4>Eye Color</h4>
                                        <p></p>
                                    </div> */}
                                    <div className="protal-single-info-content">
                                        <h4>Special Disease</h4>
                                        <p>{this.state.basicInfo.specialDisease || '---'}</p>
                                    </div>
                                    {/* <div className="protal-single-info-content">
                                        <h4>Last Treatment Date</h4>
                                        <p></p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="p-xs-12 p-sm-4 p-lg-4 p-xl-2">
                            <div className="protal-single-info-box">
                                <div className="protal-single-info-title">
                                    <h4>Previous Institute Info</h4>
                                </div>
                                <div className="protal-single-info-content-box">
                                    <div className="protal-single-info-content">
                                        <h4>Institute Name</h4>
                                        <p>{this.state.basicInfo.previousInstituteName || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Address</h4>
                                        <p>{this.state.basicInfo.previousInstituteAddress || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Contact No.</h4>
                                        <p>{this.state.basicInfo.instituteMobile || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Email</h4>
                                        <p>{this.state.basicInfo.instituteEmail || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Time Period</h4>
                                        <p>{this.state.basicInfo.timePeriod || '---'}</p>
                                    </div>
                                    <div className="protal-single-info-content">
                                        <h4>Last Education</h4>
                                        <p>{this.state.basicInfo.lastEducation || '---'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        )
        }
    
}




