import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { WalletBalance } from '../myPoint/common/WalletBalance';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import NetiContentLoader from '../common/NetiContentLoader';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ValidatorUtility } from '../../utils/ValidatorUtility';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { UserCategoryService } from '../../service/UserCategoryService';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { pdfDownloadPortrait_TableOnly } from '../common/JsPdfDownload';

let cloneStateBeforeMount;

let billTotalMonth = 0;
let totalPayableAmount = 0;
let totalPaidAmount = 0;
let totalDueAmount = 0;
let totalDiscountAmount = 0;
let totalRemainDue = 0;
let totalDiscount= 0;

export class EmBillDiscount extends Component {
    constructor(props) {
        super(props);
        this.state = {

            finalArray: [],
            
            payabaleInstituteList: [],
            discountRequestDetails: {},
            purchaseCode: '',
            purchaseInformation: {
                productName: '',
            },
            value: '',

            requestDiscount:{},
            remainDue:{},
            totalDue: '',
            totalRemainDues: 0,
            totalDiscountt: 0,

            reasonDetail: '',
            coreCategoryI: '',

            purchaseCodeError: [],
            emBillDiscountInfo: {
                reasonDetails: '',
                requestAmount: '',
                // productPurchaseCodeDTO: {
                //     productPurchaseCodeID: ''
                // },
                emDetailsInfoDTO: { //ok
                    emDetailsId: ''
                },
                edumanBillGenerateLogDTO: {
                    generateBillId: '' //id only not year ,month, due 
                },
                reasonTitleCoreCategoryDTO: {
                    coreCategoryID: '' //reason id
                }
            },
            

            purchaseCodeSearchView: false,
            purchaseCodeSearchViewError: false,
            purchaseCodeSearchErrorMsgBody: '',
            dataTableValue: [],
            postpaidBillList: [],
            firstInputFieldIsLoading: false,
            billDiscountInfoError: {},
            errorMsgVisible: false,
            secondDropDownIsLoading: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            firstDropdownIsLoading: false,
            errorMsgBody: '',
            payableInstituteID: '',
            payableInstituteInfo: {},
            billSummaryID: '',
            pdfs: '',
            urlStr: ''
        }
        this.partnerPointService = new PartnerPointService();
        this.NetiContentLoader = new NetiContentLoader();
        this.NetiDateUtils = new NetiDateUtils();
        this.userCategoryService = new UserCategoryService();
        this.ValidatorUtility = new ValidatorUtility();
        this.exportPdf = this.exportPdf.bind(this);
       
        // this.onChangeRequestDiscount = this.onChangeRequestDiscount.bind(this);
    }

    componentWillMount() {
        this.getPayableInstituteList();
        this.getReasonTitleList();
        this.getDiscountRequestList();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    getDiscountRequestList = () => {
        this.setState({ topProgressBar: true, dataTableIsLoading: true, errorMsgVisible: false })
        this.partnerPointService.findEmBillDiscountsByNetiID()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then(body => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].requestDate == null) {
                                body[i].requestDate = '';
                            } else {
                                body[i].requestDate = NetiDateUtils.getAnyShortForm(body[i].requestDate, 'DD-MMM-YYYY') + " " + NetiDateUtils.getHHMMSSFForHome(body[i].requestDate);;
                            }
                            body[i]['instituteID&Name'] = body[i].emDetailsInfoDTO['instituteId'] + ' - ' + body[i].emDetailsInfoDTO['instituteName'];
                        }
                        for (var j = 0; j < body.length; j++) {
                            if (body[j].requestStatus == 0) {
                                body[j].requestStatus = "Pending";
                            } else if (body[j].requestStatus = 1) {
                                body[j].requestStatus = <span style={{ color: "green" }}>{"Approve"}</span>;
                            } else if (body[j].requestStatus = 2)
                                body[j].requestStatus = <span style={{ color: "red" }}>{"Reject"}</span>;
                        }
                        this.setState({ dataTableValue: body, topProgressBar: false, dataTableIsLoading: false });
                    });
                } else {
                    this.adminPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            })
            .catch(error => {
                this.setState({ topProgressBar: false, dataTableIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    purchaseCodeCheck = (e) => {
        this.setState({ purchaseInformation: cloneStateBeforeMount.purchaseInformation, purchaseCodeSearchErrorMsgBody: '', purchaseCodeSearchView: false });
        if (this.purchaseCodeError()) {
            this.setState({ topProgressBar: true, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: true, errorMsgVisible: false, purchaseCodeSearchView: true });
            this.partnerPointService.getUserPurchaseCode(this.state.purchaseCode)
                .then(res => {
                    if (res.status === 302) {
                        return res.json().then((body) => {
                            console.log("purchaseInformation", body);
                            let { purchaseInformation } = this.state;
                            purchaseInformation.productName = body.productPurchaseLogDTO.productInfoDTO.productName;
                            purchaseInformation.usedStatus = body.usedStatus;
                            purchaseInformation.purchaseDate = NetiDateUtils.getAnyShortForm(body.productPurchaseLogDTO.purchaseDate, 'DD-MMM-YYYY');
                            purchaseInformation.productPurchaseCodeID = body.productPurchaseCodeID
                            this.setState({ purchaseInformation, topProgressBar: false, purchaseCodeSearchBtnDisabled: false, purchaseCodeSearchViewError: false, firstInputFieldIsLoading: false, errorMsgVisible: false, purchaseCodeSearchView: true });
                        })
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({
                                    firstInputFieldIsLoading: false,
                                    purchaseCodeSearchBtnDisabled: true,
                                    topProgressBar: false,
                                    purchaseCodeSearchErrorMsgBody: resp,
                                    purchaseCodeSearchViewError: true,
                                    purchaseCodeSearchView: true,
                                    purchaseInformation: {
                                        productName: ''
                                    }
                                });
                            })
                    }
                }).catch(error => {
                    this.setState({ topProgressBar: false, purchaseCodeSearchBtnDisabled: true, firstInputFieldIsLoading: false, errorMsgVisible: true, purchaseCodeSearchView: false, purchaseCodeSearchViewError: false, errorMsgBody: 'Unable to load. Please check your connection.' })

                });
        }
    }

    purchaseCodeHandler = (event) => {
        let { purchaseCodeError } = this.state
        purchaseCodeError["purchaseCode"] = "";
        this.setState({ purchaseCode: event.target.value, purchaseCodeError });
    }

    purchaseCodeError() {
        let { purchaseCodeError } = this.state;
        let formIsValid = true;
        if (this.state.purchaseCode === '') {
            formIsValid = false;
            purchaseCodeError["purchaseCode"] = "Purchase Code can't left empty";
        } else {
            purchaseCodeError["purchaseCode"] = "";
        }
        this.setState({ purchaseCodeError });
        return formIsValid;
    }

    getPayableInstituteList = () => {
        this.setState({ topProgressBar: true, firstDropdownIsLoading: true, errorMsgVisible: false });
        this.partnerPointService.getInstituteByPartner()
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ payabaleInstituteList: body });
                        this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: false });

                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, firstDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' })
            });

    }

    viewPostpaidBillSummaryList = (value) => {
        let { payableInstituteInfo, postpaidBillList } = this.state;
        this.setState({ postpaidBillList: [] })
        this.setState({ topProgressBar: true, errorMsgVisible: false });
        billTotalMonth = 0;
        totalPayableAmount = 0;
        totalPaidAmount = 0;
        totalDueAmount = 0;
        this.partnerPointService.getPostpaidBillSummary(value)
            .then(res => {
                this.setState({ topProgressBar: false });
                if (res.status == 302) {
                    return res.json().then((body) => {
                        // console.log("bill summary body", body);
                        if (body.length !== 0) {
                            billTotalMonth = body.map(item => item.month).length;
                            totalPayableAmount = body.map(item => item.payableAmount).reduce((a, b) => (a + b));
                            totalRemainDue = totalPayableAmount
                            totalPaidAmount = body.map(item => item.paidAmount).reduce((a, b) => (a + b));
                            totalDueAmount = body.map(item => item.dueAmount).reduce((a, b) => (a + b));
                            this.setState({ postpaidBillList: body, errorMsgVisible: false });
                        } else {
                            this.setState({ errorMsgVisible: true, errorMsgBody: 'No bill amount found' });
                        }

                    });
                    
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((responseBody) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: responseBody })
                        });
                }
            }).catch(error => {
                this.setState({ errorMsgVisible: true, topProgressBar: false, errorMsgBody: 'Please check your connection.' })
            });

            // this.setState({totalDue: totalPayableAmount})
            console.log(totalPayableAmount);

    }

    onChangePayableInstitute = (event) => {
        let { billDiscountInfoError } = this.state;
        billDiscountInfoError["payableInstitute"] = '';
        let { payableInstituteID, payabaleInstituteList, payableInstituteInfo } = this.state;
        for (let i = 0; i < payabaleInstituteList.length; i++) {
            if (payabaleInstituteList[i].emDetailsId == event.target.value) {
                payableInstituteInfo = payabaleInstituteList[i];
                this.setState({ payableInstituteInfo });
                this.viewPostpaidBillSummaryList(event.target.value);
            }
        }
        this.setState({ billDiscountInfoError })
        this.setState({ payableInstituteID: event.target.value });

    }
    // onChangeBillMonth = (e) => {
    //     let { billDiscountInfoError } = this.state;
    //     billDiscountInfoError["billMonth"] = '';
    //     let { emBillDiscountInfo } = this.state;
    //     emBillDiscountInfo.edumanBillGenerateLogDTO.generateBillId = e.target.value;
    //     this.setState({ emBillDiscountInfo });
    //     this.setState({ billDiscountInfoError })
    // }
    // onChangeReasonTitle = (e) => {
    //     let { billDiscountInfoError } = this.state;
    //     billDiscountInfoError["reasonTitle"] = '';
    //     let { emBillDiscountInfo } = this.state;
    //     emBillDiscountInfo.reasonTitleCoreCategoryDTO.coreCategoryID = e.target.value;
    //     this.setState({ emBillDiscountInfo });
    //     this.setState({ billDiscountInfoError })
    // }

     onChangeReasonTitle = (e) => {
        let { billDiscountInfoError, coreCategoryI } = this.state;
        billDiscountInfoError["reasonTitle"] = '';
        coreCategoryI = e.target.value;
        this.setState({ coreCategoryI });
        this.setState({ billDiscountInfoError })
    }

   
      onChangeReasonDetails = (e) => {
        let { billDiscountInfoError, reasonDetail } = this.state;
        billDiscountInfoError["reasonDetails"] = '';
        reasonDetail = e.target.value;
        this.setState({ reasonDetail });
        this.setState({ billDiscountInfoError })
    }

    onSubmitHandler = () => {
        //let { emBillDiscountInfo, payableInstituteInfo, purchaseInformation, requestDiscount } = this.state;
        let {emBillDiscountInfo, requestDiscount, } = this.state;
        this.state.finalArray = []
  

      for(let key in requestDiscount) {

        let {  payableInstituteInfo, reasonDetail, coreCategoryI, emBillDiscountInfo} = this.state;

        let obj1 = {
            reasonDetails: '',
            requestAmount: '',
            productPurchaseCodeDTO: {
                productPurchaseCodeID: ''
            },
            emDetailsInfoDTO: { 
                emDetailsId: ''
            },
            edumanBillGenerateLogDTO: {
                generateBillId: '' 
            },
            reasonTitleCoreCategoryDTO: {
                coreCategoryID: '' 
            },
            attachmentFileName: '',
            content: ''
        }

        obj1.reasonDetails = reasonDetail;
        obj1.requestAmount = requestDiscount[key];
        obj1.edumanBillGenerateLogDTO.generateBillId = key;
        obj1.emDetailsInfoDTO.emDetailsId = payableInstituteInfo.emDetailsId;
        obj1.productPurchaseCodeDTO.productPurchaseCodeID = 1234;
        obj1.reasonTitleCoreCategoryDTO.coreCategoryID = coreCategoryI;
        obj1.attachmentFileName = this.state.pdfs.name;
        obj1.fileContent = this.state.urlStr;

        
        this.state.finalArray.push(obj1);
      

      }


      let { finalArray,pdfs} = this.state;

     const initialMemo = [];
       
      finalArray = finalArray.reduce((memo, iteratee) => {
            if (iteratee.requestAmount) {
                memo.push(iteratee); 
            }
            return memo; 
        }, initialMemo);


        
        if (this.discountBillFormError()) {
            this.setState({ topProgressBar: true, dataTableIsLoading: true, });
            // this.partnerPointService.saveEdumanBillDiscount(emBillDiscountInfo)
            this.partnerPointService.saveEdumanBillDiscount(finalArray)
                .then(res => {
                    this.setState({ topProgressBar: false, dataTableIsLoading: false });
                    if (res.status == 201) {
                        this.setState({
                            payableAmount: '',
                            postpaidBillList: [],
                            payableInstituteID: '',
                            payableInstituteInfo: cloneStateBeforeMount.payableInstituteInfo,
                            emBillDiscountInfo: cloneStateBeforeMount.emBillDiscountInfo
                        });
                        billTotalMonth = 0;
                        totalPayableAmount = 0;
                        totalPaidAmount = 0;
                        totalDueAmount = 0;
                        this.getPayableInstituteList();
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Submitted!" });
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ errorMsgVisible: true, errorMsgBody: resBody, dataTableIsLoading: false })
                            });
                    }
                }).catch(error => {
                    this.setState({ errorMsgVisible: true, topProgressBar: false, dataTableIsLoading: false, errorMsgBody: 'Please check your connection.' })

                });
                console.log(finalArray);
                
        }

    }

    getReasonTitleList() {
        this.setState({ topProgressBar: true, secondDropdownIsLoading: true, errorMsgVisible: false })
        this.userCategoryService.findByCategoryDefaultCode('C11002035')
            .then(res => {
                if (res.status == 302) {
                    return res.json().then((body) => {
                        this.setState({ reasonTitleDropdownList: body, topProgressBar: false, secondDropdownIsLoading: false });
                    });
                } else {
                    this.userCategoryService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                        });
                }
            }).catch(error => {
                this.setState({ topProgressBar: false, secondDropdownIsLoading: false, errorMsgVisible: true, errorMsgBody: "Please check your connection !!!" })
            });
    }

    discountBillFormError = () => {
        let { billDiscountInfoError, emBillDiscountInfo } = this.state;
        this.setState({ topProgressBar: false });
        let formIsValid = true;

        if (!this.state.payableInstituteID) {
            formIsValid = false;
            billDiscountInfoError["payableInstitute"] = "Institute can't left empty.";
        }
        if (!this.state.reasonDetail) {
            formIsValid = false;
            billDiscountInfoError["reasonDetails"] = "Reason Details can't left empty.";
        }
        if (!this.state.coreCategoryI) {
            formIsValid = false;
            billDiscountInfoError["reasonTitle"] = "Reason Title can't left empty.";
        }
        if (!this.state.urlStr) {
            formIsValid = false;
            billDiscountInfoError["billfile"] = "FIle  can't left empty.";
        }
        // if (finalArray[0] = reasonDetail.reasonTitleCoreCategoryDTO.coreCategoryID === '') {
        //     formIsValid = false;
        //     billDiscountInfoError["reasonTitle"] = "Reason Title can't left empty.";
        // }
        // if (finalArray[0].reasonDetails  === '') {
        //     formIsValid = false;
        //     billDiscountInfoError["reasonDetails"] = "Reason Details can't left empty.";
        // }
        // if (emBillDiscountInfo.requestAmount === '') {
        //     formIsValid = false;
        //     billDiscountInfoError["requestAmount"] = "Request Amount can't left empty.";
        // }

        // this.setState({ billDiscountInfoError });
        return formIsValid;
    }

    // onChangeDueAmount = (rowData) => {
    //     return <span>{(rowData['payableAmount'] - rowData['paidAmount'])}</span>
    // }


    
    onChangeRequestDiscountt = (e, idd, dueamountt) => {
        e.preventDefault();
        
        var value  = e.target.value;
        var requestDiscount = this.state.requestDiscount;
        requestDiscount[idd] = value
        this.setState({ requestDiscount });

        var remainDue = this.state.remainDue;

        remainDue[idd] = dueamountt - value;

        this.setState({ remainDue });


        var total = 0;
        for(const key in requestDiscount) {
            if(requestDiscount[key]) {
                total = total + parseInt(requestDiscount[key]);
            }
        }

        this.setState({totalDiscountt:total})
        let totalremaindue = totalPayableAmount - total;

        this.setState({totalRemainDues: totalremaindue })
        
           
    }

    onChangeDueAmount = (rowData) => {
        return (<div className="col-number text-right"> {(rowData['payableAmount'] - rowData['paidAmount'])}</div>)
    }

    requestAmount = (rowData) => {
        return (<div className="col-number text-right"> {rowData['requestAmount']}</div>)
    }

    paidAmount = (rowData) => {
        return (<div className="col-number text-right"> {rowData['paidAmount']}</div>)
    }

    yearAndMonth = (rowData) => {
        return (<div className="year-month">
            <span className="year">{rowData['year']}</span> <br /> <span>{rowData['month']}</span>
        </div>)
    }
    
    payableAndPaidBillAmount = (rowData) => {

        return (
            <div>
                <u>Amount</u>
                <div className="table-middle-col">
                    <span className="col-number text-left">Payable</span> <span className=""> {(rowData['payableAmount'])}</span>
                    dueAmount      </div>
                <div className="table-middle-col">
                    <span className="col-number text-left">
                        <span className="required-field">
                            (<span>-</span>) Paid
                        </span>
                    </span> <span className="" style={{ float: "right" }}> {rowData['paidAmount']}</span>
                </div>

                <div className="table-middle-col">
                    <span className="col-number text-left">
                        <span className="required-field">
                            (<span>-</span>) Discount
                        </span>
                    </span>
                    {/* </span> <span className="" style={{ float: "right" }}> {this.ValidatorUtility.currencyFormatter("0")}</span> */}
                </div>
            </div>
        )
    }

    // dueAmount = (rowData) => {
    //     return (<div className="total-due">
    //         <span className="due">Due</span> <br /> <div> {this.ValidatorUtility.currencyFormatter(rowData['dueAmount'])}</div>
    //     </div>)
    // }

    remainDueCal = (rowData) =>{

        var remainDue = this.state.remainDue;

        if( remainDue[`${rowData['generateBillId']}`] === null || remainDue[`${rowData['generateBillId']}`] === "" ){
            remainDue[`${rowData['generateBillId']}`] = rowData['dueAmount'];
        }
        // if(!remainDue[`${rowData['generateBillId']}`]){
            
        // }
        
        return(
            <div>
                 {this.state.remainDue[`${rowData['generateBillId']}`]}
            </div>
        )
    }

    discountt = (rowData) =>{  
        
        return(
            <div>
                <input 
                    type="text"                          
                    name="data"
                    value={this.state.requestDiscount[`${rowData['generateBillId']}`]  || ""}
                    onChange={ (e)=>{
                    this.onChangeRequestDiscountt(e, rowData['generateBillId'],rowData['dueAmount'])
                    }}
                />
            </div>
        )    
    }

    
    dueAmount = (rowData) => {
        // totalPayableAmount = totalPayableAmount + rowData['dueAmount'];
  

       // console.log(totalPayableAmount)
        
        return (<div className="total-due">
            <div> {this.ValidatorUtility.currencyFormatter(rowData['dueAmount'])}</div>
        </div>)
    }

    onHide = (e) => {
        this.setState({ visible: false });
    }

    actionBody = (rowData) => {
        return (
            <div className="text-center">
                <Button
                    className="nw-action-button info"
                    tooltip="View"
                    icon="fas fa-eye"
                    onClick={e => this.viewDialog(rowData)}
                />
            </div>
        );
    }

    viewDialog = (rowData) => {
        console.log("rowData", rowData);
        this.setState({ visible: true, discountRequestDetails: rowData });
    }
    
    discountRequestChecking = (val) => {
        let requestStatus;
        if (val == "Approve") {
            requestStatus = "Approve Info"
        } else if (val == "Reject") {
            requestStatus = "Reject Info"
        } else if (val == "Pending") {
            requestStatus = "Pending Info"
        }
        return requestStatus;
    }
    
    
    handleFile(e){

        this.setState({pdfs : e.target.files[0]}) 
        var reader = new FileReader();
        let file = e.target.files[0];
        const scope = this
        reader.readAsDataURL(file);
        reader.onload = function () {
            let content = reader.result;
            var keyw = 'data:' + file.type + ';base64,'; //link will be same from the word webapps in URL
            var urlSt = content.substring(content.indexOf(keyw) + keyw.length);
           
            scope.setState({ urlStr: urlSt });
        }
        
    }


    exportPdf() {
        

        let pdfColumns = [
            { title: "Request Date", dataKey: "requestDate" },
            { title: "Institute ID & Name", dataKey: "instituteID&Name" },
            { title: "Request Amount", dataKey: "requestAmount" },
            { title: "Status", dataKey: "requestStatus" },
        ]

        pdfDownloadPortrait_TableOnly('Em Bill Discount Request List', pdfColumns, this.state.dataTableValue, "Em_Bill_Discount_Request.pdf");

    }


  
    render() {

        let { discountRequestDetails, emBillDiscountInfo, firstInputFieldIsLoading, billDiscountInfoError, payableInstituteInfo, topProgressBar, errorMsgVisible, errorMsgBody } = this.state;
        let tableHeader = <div className="header-title">
            <div className="header-title-left">
                Discount Request List
            </div>

            <div className="header-title-right">
                <span>Total Found: {this.state.dataTableValue.length}</span>
            </div>
        </div>;



        let tableFooter = 
            <div className="p-datatable-footer-cus">
          
                <div>
                    <span> Total: </span>
                </div>
                <div>
                    <span>{totalPayableAmount} </span>
                </div>
                <div>
                    <span>{this.state.totalDiscountt}</span>
                </div>
                <div>
                    <span> {this.state.totalRemainDues} </span>
                </div>

            </div>;
           
       

       



        let payabaleInstitute = [];
        if ((this.state.payabaleInstituteList != null) && (this.state.payabaleInstituteList.length > 0)) {
            payabaleInstitute = this.state.payabaleInstituteList.map((item) => ({
                value: item.emDetailsId,
                label: item.instituteId + " - " + item.instituteName,
            }));
        }

        let billSummaryList = [];
        if ((this.state.postpaidBillList != null) && (this.state.postpaidBillList.length > 0)) {
            billSummaryList = this.state.postpaidBillList.map((item) => ({
                value: item.generateBillId,
                label: `${item.year} - ${item.month} - ${item.dueAmount} Tk`
            }))
            
        }

        let reasonTitleList = [];
        if ((this.state.reasonTitleDropdownList != null) && (this.state.reasonTitleDropdownList.length > 0)) {
            reasonTitleList = this.state.reasonTitleDropdownList.map((item) => ({
                value: item.coreCategoryID,
                label: item.categoryName
            }));
        }

       

        
        
        return (
            <div className="p-fluid">
                {topProgressBar ? <ErrorMessageView topProgressBar={topProgressBar} /> : null}
                {errorMsgVisible ? <ErrorMessageView errorMsgVisible={errorMsgVisible} errorMsgBody={errorMsgBody} /> : null}
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid nw-form">
                    <div className="p-col-12 p-xl-12 p-col-nogutter">
                        <div className="p-grid seprator-inside">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">

                                   

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.firstDropdownIsLoading ? this.NetiContentLoader.normalFormInputField() :
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Payable Institute <span>*</span></span>
                                                <Dropdown
                                                    value={this.state.payableInstituteID}
                                                    options={payabaleInstitute}
                                                    onChange={this.onChangePayableInstitute}
                                                    placeholder="Select Institute"
                                                    showClear={true}
                                                    filterBy="value,label"
                                                    autoWidth={false}
                                                    filter={true}
                                                    name="payableInstitute"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{this.state.billDiscountInfoError["payableInstitute"]}</span>
                                    </div>

                                   

                                    <div className="p-col-12 p-xl-12">
                                        {this.state.secondDropdownIsLoading ? this.NetiContentLoader.normalFormInputField() :

                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Reason Title <span>*</span></span>
                                                <Dropdown
                                                    placeholder="Select Reason Title"
                                                   // value={emBillDiscountInfo.reasonTitleCoreCategoryDTO.coreCategoryID || ''}
                                                    value={this.state.coreCategoryI || ''}
                                                    options={reasonTitleList}
                                                    onChange={this.onChangeReasonTitle}
                                                    filter={true}
                                                    Name            filterBy="value"
                                                    showClear={true}
                                                    autoWidth={false}
                                                    name="reasonTitle"
                                                />
                                            </div>
                                        }
                                        <span className="error-message">{this.state.billDiscountInfoError["reasonTitle"]}</span>
                                    </div>

                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">Reason Details <span>*</span></span>
                                             <InputTextarea
                                               // value={emBillDiscountInfo.reasonDetails || ''}
                                                value={this.state.reasonDetail || ''}
                                                onChange={this.onChangeReasonDetails}
                                                placeholder="Enter Reason Details"
                                                rows={3}
                                                cols={30}
                                                autoResize={true}
                                                name="reasonDetails"
                                            />
                                        </div>
                                        <span className="error-message">{this.state.billDiscountInfoError["reasonDetails"]}</span>
                                    </div>



                                    <div className="p-col-12 p-xl-12">
                                         <div className="p-inputgroup nw-upload-button">
                                            <span className="p-inputgroup-addon nw-inputtextarea-label">
                                            Attachment <span>*</span>
                                                 <br />
                                                    
                                            </span>

                                        <div className="nw-upload-button-inside">

                                            <input  type="file" name="fileUpload"
                                                onChange={(e)=> this.handleFile(e)} 
                                            />

                                        </div>



                                        </div>
                                     </div>

                                   


                                   
                                {/* 
                                    <div className="p-col-12 p-xl-12">

                                        <input  type="file" name="fileUpload"
                                            onChange={(e)=> this.handleFile(e)} 
                                        />

                                    </div> */}




                                    <div className="p-col-12 p-xl-12">

                                        <div className="p-col-12 p-xl-12 nw-data-table">

                                            <DataTable
                                            value={this.state.postpaidBillList}
                                            selectionMode="single"
                                            //  header={tableHeader}
                                            footer={tableFooter}
                                            responsive={true}
                                            columnResizeMode="fit"
                                            >

                                                <Column field="year" header="Year"  />
                                                <Column field="month"  header="Month"  />
                                                <Column field="due" body={this.dueAmount} header="Due" />
                                                <Column field='discount' body={  this.discountt}  header="Discount" />
                                                <Column field="remaindue" body={this.remainDueCal}  header="Remain Due" />
                                                
                                                
                                            </DataTable>

                                        </div>
                                    </div>




                                  
                                   



                                    <div className="p-col-12 p-xl-12 nw-button-parent">

                                        {/* <div className="required-field">
                                            (<span>*</span>) required fields
                                        </div> */}
                           
                                       


                                        <Button
                                            className="p-button p-button-primary nw-button nw-button-right"
                                            label="Submit"
                                            icon="fas fa-hand-holding-usd"
                                            onClick={this.onSubmitHandler}
                                            // disabled
                                        />
                                        
                                    </div>


                                    

                                  

                                </div>
                            </div>
                        </div>
                    </div>
                    
        

                    



                </div>
                <Dialog
                    header="Discount Request Details"
                    visible={this.state.visible}
                    className="nw-dialog"
                    onHide={this.onHide}
                    closable
                >
                    <div className="p-fluid">
                        <div className="p-grid nw-form">

                            <div className="p-grid p-col-12 p-lg-12 p-xl-12">
                                <Fieldset className="p-col-12 p-xl-12" legend={this.discountRequestChecking(discountRequestDetails.requestStatus)}>

                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-12">

                                            <div className="p-col-12 p-xl-12">

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Request Date
                                                        </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {discountRequestDetails.requestDate ? NetiDateUtils.getAnyShortForm(discountRequestDetails.requestDate, 'DD-MMM-YYYY') : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Institute ID & Name
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {`${discountRequestDetails.emDetailsInfoDTO && discountRequestDetails.emDetailsInfoDTO.instituteId} -  ${discountRequestDetails.emDetailsInfoDTO && discountRequestDetails.emDetailsInfoDTO.instituteName}`}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-xl-12">
                                                    <div className="p-inputgroup">
                                                        <span className="p-inputgroup-addon nw-inputgroup-label">
                                                            Request Amount
                                                                </span>
                                                        <div className="nw-inputgroup-desc">
                                                            {discountRequestDetails.requestAmount}
                                                        </div>
                                                    </div>
                                                </div>

                                                {discountRequestDetails.requestStatus == "Approve" ?
                                                    <div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                    Approve Date
                                                                </span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {discountRequestDetails.approvedDate ? NetiDateUtils.getAnyShortForm(discountRequestDetails.approvedDate, 'DD-MMM-YYYY') : ''}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                    Approve Amount
                                                            </span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {discountRequestDetails.approvedAmount}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                    Approve Note
                                                                </span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {discountRequestDetails.approvedNote}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                    Approve By
                                                                </span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {`${discountRequestDetails.approvedByUserDTO && discountRequestDetails.approvedByUserDTO.customNetiID} - ${discountRequestDetails.approvedByUserDTO && discountRequestDetails.approvedByUserDTO.fullName}`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-xl-12">
                                            <div className="p-col-12 p-xl-12">

                                                {discountRequestDetails.requestStatus == "Reject" ?

                                                    <div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                    Reject Date
                                                                </span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {discountRequestDetails.approvedDate ? NetiDateUtils.getAnyShortForm(discountRequestDetails.approvedDate, 'DD-MMM-YYYY') : ''}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                    Reject Note
                                                                </span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {discountRequestDetails.approvedNote}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col-12 p-xl-12">
                                                            <div className="p-inputgroup">
                                                                <span className="p-inputgroup-addon nw-inputgroup-label">
                                                                    Reject By
                                                                </span>
                                                                <div className="nw-inputgroup-desc">
                                                                    {`${discountRequestDetails.approvedByUserDTO && discountRequestDetails.approvedByUserDTO.customNetiID} - ${discountRequestDetails.approvedByUserDTO && discountRequestDetails.approvedByUserDTO.fullName}`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''}

                                            </div>
                                        </div>
                                    </div>

                                </Fieldset>

                            </div>

                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}