import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { ErrorMessageView } from '../common/ErrorMessageView';
import { ProfileService } from '../../service/profile/ProfileService';
import { NetiDateUtils } from '../../utils/NetiDateUtils';
import { EmAdminPointService } from '../../service/emAdminPoint/EmAdminPointService';
import { PartnerPointService } from '../../service/partnerPoint/PartnerPointService';
import { UserOtherAccessService } from '../../service/UserOtherAccessService';

import NetiContentLoader from '../common/NetiContentLoader';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

let cloneStateBeforeMount;
export class AssignEmInstitute extends Component {
    constructor() {
        super();
        this.state = {
            errors: {},
            checked: false,
            buttonDisabled: true,
            errorMsgVisible: false,
            insitituteID: '',
            errorMsgBody: '',
            pointList: [],
            assignModuleObj: {
                edumanDetailsInfoDTO: {},
                edumanDetailsInfoDTO: {
                    emDetailsId: ''
                },
                moduleStudent: 0,
                moduleHrManagement: 0,
                moduleStudentAttendance: 0,
                moduleSemesterExam: 0,
                moduleClassTest: 0,
                moduleRoutine: 0,
                moduleStudentAccounts: 0,
            },
            userTaggingList: [],
            instituteDropdownList: [],
            customNetiID: '',
            userBasicInfo: {
                netiID: '',
                basicMobile: ''
            },
            searchUserDataIsLoading: false,
            edumanTaggedIDbtnnDisabled: true,
            firstDropDownIsLoading: false,
            dataTableIsLoading: false,
            topProgressBar: false,
            searchView: false,
            searchViewError: false,
            searchViewErrorMsg: '',
            dataTableValue: [],

        };

        this.profileService = new ProfileService();
        this.emAdminPointService = new EmAdminPointService();
        this.partnerPointService = new PartnerPointService();
        this.netiDateUtils = new NetiDateUtils();
        this.NetiContentLoader = new NetiContentLoader();
        this.UserOtherAccessService = new UserOtherAccessService();
    }

    componentWillMount() {
        this.viewInstituteList();
        cloneStateBeforeMount = JSON.parse(JSON.stringify(this.state));
    }

    onChangeCustomNetiID = (e) => {
        let { errors } = this.state;
        errors['netiID'] = '';
        this.setState({ errors })
        this.setState({ customNetiID: e.target.value });
    }

    onChangeInstitute = (e) => {
        this.setState({ insitituteID: e.target.value });
    }

    onSearchCustomNetiID = () => {
        let { userBasicInfo } = this.state;
        userBasicInfo.netiID = userBasicInfo.basicMobile = null;
        this.setState({ userBasicInfo, buttonDisabled: true, edumanTaggedIDbtnnDisabled: true });

        if (this.onSearchCustomNetiIDErrorHandle()) {
            this.setState({ topProgressBar: true, searchView: true, searchViewError: false, searchUserDataIsLoading: true, errorMsgVisible: false });
            this.emAdminPointService.getUserByCustomIDAndRole(this.state.customNetiID, 'ROLE_EMADMIN')
                .then(res => {
                    if (res.status == 302) {
                        return res.json().then(body => {
                            this.setState({ edumanTaggedIDbtnnDisabled: false, buttonDisabled: false, userBasicInfo: body, topProgressBar: false, searchUserDataIsLoading: false, errorMsgVisible: false });
                        });
                    } else {
                        this.emAdminPointService.Auth.handleErrorStatus(res)
                            .then((resp) => {
                                this.setState({ searchViewError: true, searchViewErrorMsg: resp, topProgressBar: false, searchUserDataIsLoading: false })
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, searchView: false, searchUserDataIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
        }
    }

    onSearchCustomNetiIDErrorHandle = () => {
        let { errors, customNetiID } = this.state;
        let formIsValid = true;
        this.setState({ searchView: false })

        if (!customNetiID) {
            formIsValid = false;
            errors['netiID'] = 'Neti ID can\'t left empty'
        }

        if (customNetiID.length < 10) {
            formIsValid = false;
            errors["netiID"] = "Neti ID is too sort";
        }
        if (customNetiID == '' && customNetiID.length < 10) {
            formIsValid = false
            errors['netiID'] = 'Neti ID can\'t left empty and Neti ID minimum lenght is 10';
        }

        this.setState({ errors })

        return formIsValid;
    }



    viewInstituteList = () => {
        this.setState({ topProgressBar: true, firstDropDownIsLoading: true, errorMsgVisible: false });
        this.UserOtherAccessService.getInstitutesByAssignedPartner().then(res => {
            if (res.status == 302) {
                return res.json().then((body) => {
                    this.setState({ instituteDropdownList: body })
                    this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: false });
                })
            } else {
                this.emAdminPointService.Auth.handleErrorStatus(res)
                    .then((resp) => {
                        this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: resp })
                    });
            }
        }).catch((error) => {
            this.setState({ topProgressBar: false, firstDropDownIsLoading: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
        });
    }

    assignEmInstituteHandler = (e) => {
        let { userBasicInfo } = this.state;
        if (this.onSaveErrorHandle()) {
            this.setState({ topProgressBar: true, errorMsgVisible: false });
            this.partnerPointService.assignExistingInstituteToEmAdmin(this.state.insitituteID, userBasicInfo.netiID)
                .then(res => {
                    this.setState({ topProgressBar: false });
                    if (res.status == 202) {
                        this.setState({ insitituteID: '', customNetiID: '', userBasicInfo: cloneStateBeforeMount.userBasicInfo, topProgressBar: false, errorMsgVisible: false });
                        this.growl.show({ severity: 'success', summary: 'Success Message', detail: "Successfully Assigned!" });
                    } else {
                        this.partnerPointService.Auth.handleErrorStatus(res)
                            .then((resBody) => {
                                this.setState({ topProgressBar: false, errorMsgVisible: true})
                                this.growl.show({ severity: 'error', summary: 'Error Message', detail: resBody });
                            });
                    }
                }).catch((error) => {
                    this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
                });
        }
    }

    onSaveErrorHandle = () => {
        let formIsValid = true;
        let { errors } = this.state;

        if (!this.onSearchCustomNetiIDErrorHandle()) {
            formIsValid = false;
        }
        else if (this.onSearchCustomNetiIDErrorHandle() && !this.state.userBasicInfo.netiID) {
            formIsValid = false;
            this.growl.show({ severity: 'error', summary: 'Error Message', detail: "User's Basic Info not found. Please search with a valid Neti-ID" });
        }


        if (!this.state.insitituteID) {
            formIsValid = false;
            errors['insititute'] = 'Insititute can\'t left empty'
        }

        this.setState({ errors })
        return formIsValid;
    }

    onChangeEdumanTaggedID = () => {
        let { userBasicInfo } = this.state;
        this.setState({ edumanTaggedID: true, errorMsgVisible: false });
        this.partnerPointService.getInstituteListByUserAdmin(userBasicInfo.netiID)
            .then(res => {
                if (res.status == 302) {
                    return res.json().then(body => {
                        for (let i = 0; i < body.length; i++) {
                            if (body[i].formApproveDate == null) {
                                body[i].formApproveDate = '';
                            } else {
                                body[i].formApproveDate = NetiDateUtils.getAnyShortForm(body[i].formApproveDate, 'DD-MMM-YYYY');
                            }
                        }
                        this.setState({ dataTableValue: body, topProgressBar: false, errorMsgVisible: false });
                    });
                } else {
                    this.partnerPointService.Auth.handleErrorStatus(res)
                        .then((resp) => {
                            this.setState({ errorMsgVisible: true, errorMsgBody: resp, topProgressBar: false, })
                        });
                }
            }).catch((error) => {
                this.setState({ topProgressBar: false, errorMsgVisible: true, errorMsgBody: 'Unable to load data. Please check connection' });
            });
    }


    onHideEdumanTaggedID = (event) => {
        this.setState({ edumanTaggedID: false });
    }

    focusSearch = e => {
        this.setState({ searchLabel: "Search", addSearchBtnClass: "nw-button p-button-primary text-color-white" });
    }

    blurSearch = e => {
        this.setState({ searchLabel: '', addSearchBtnClass: "" });
    }


    render() {
        let { topProgressBar, errorMsgVisible, errorMsgBody } = this.state;

        let { userBasicInfo } = this.state;
        let formatContactNo = (userBasicInfo.basicMobile ? userBasicInfo.basicMobile.replace(/-/g, '').replace(/\s/g, '').replace(/[+]/g, '') : '');
        let formatRegisterDate = (userBasicInfo.registrationDate ? NetiDateUtils.getDateFromString(userBasicInfo.registrationDate) : '');

        let instituteList = [];
        if ((this.state.instituteDropdownList != null) && (this.state.instituteDropdownList.length > 0)) {
            instituteList = this.state.instituteDropdownList.map((item) => ({
                value: item.emDetailsId,
                label: item.instituteId + " - " + item.instituteName,
            }));
        }

        var tableHeader = <div className="header-title">
            <div className="header-title-left">
                Eduman Tagged ID
        </div>
            <div className="header-title-right">
                <a>
                    Total Found: {this.state.dataTableValue ? this.state.dataTableValue.length : ''}
                </a>
            </div>
        </div>
        return (

            <div className="p-fluid">
                {topProgressBar ?
                    <ErrorMessageView
                        topProgressBar={topProgressBar}
                    />
                    : null
                }
                {errorMsgVisible ?
                    <ErrorMessageView
                        errorMsgVisible={errorMsgVisible}
                        errorMsgBody={errorMsgBody}
                    />
                    : null
                }
                <div className="p-grid">
                    <Growl ref={(el) => this.growl = el} />
                    <div className="p-col-12 p-xl-12">
                        <div className="p-grid nw-form">
                            <div className="p-col-12 p-xl-12">
                                <div className="nw-form-body">
                                    <div className="p-col-12 p-xl-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Neti ID : <span>*</span></span>
                                            <InputText
                                                value={this.state.customNetiID}
                                                onChange={this.onChangeCustomNetiID}
                                                placeholder="Enter Neti ID"
                                                style={{ width: "15%" }}
                                                type="text"
                                                // keyfilter="pint"
                                                name="netiID"
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                                maxLength={10}
                                            />
                                            <Button
                                                className={"p-button-animation " + this.state.addSearchBtnClass}
                                                label={this.state.searchLabel}
                                                icon="fas fa-search"
                                                onClick={this.onSearchCustomNetiID}
                                                onFocus={this.focusSearch}
                                                onBlur={this.blurSearch}
                                            />
                                        </div>
                                        <span className="error-message">{this.state.errors['netiID']}</span>
                                    </div>

                                    {this.state.searchView ?
                                        <div className="p-col-12 p-xl-12">

                                            <div className="nw-search-view">

                                                {this.state.searchViewError ?
                                                    <center className="error-message">{this.state.searchViewErrorMsg || 'No Data Found'}</center> :
                                                    <div>

                                                        {this.state.searchUserDataIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :
                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Name</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {userBasicInfo.fullName || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.searchUserDataIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Mobile No</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {formatContactNo || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.searchUserDataIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Email Address</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {userBasicInfo.basicEmail || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {this.state.searchUserDataIsLoading ?
                                                            this.NetiContentLoader.normalFormInputField() :

                                                            <div className="p-col-12 p-xl-12">
                                                                <div className="p-inputgroup">
                                                                    <span className="task-badge found"></span>
                                                                    <span className="p-inputgroup-addon nw-inputgroup-label">Register Date</span>
                                                                    <span className="p-inputgroup-colon">:</span>
                                                                    <div className="nw-inputgroup-desc">
                                                                        {formatRegisterDate || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                    </div>
                                                }
                                            </div>



                                        </div>
                                        :
                                        ''
                                    }


                                    <div>
                                        <div className="p-col-12 p-xl-12">
                                            {this.state.firstDropDownIsLoading ?
                                                this.NetiContentLoader.normalFormInputField() :
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Institute <span>*</span></span>
                                                    <Dropdown
                                                        placeholder="Select Institute"
                                                        filter={true}
                                                        filterBy="value"
                                                        value={this.state.insitituteID}
                                                        options={instituteList}
                                                        showClear={true}
                                                        onChange={this.onChangeInstitute}
                                                        autoWidth={false}
                                                    />
                                                </div>
                                            }
                                            <span className="error-message">{this.state.insitituteID ? '' : this.state.errors['insititute']}</span>
                                        </div>


                                        <div className="p-col-12 p-xl-12 nw-button-parent">


                                            <Button
                                                type="button"
                                                icon="fas fa-info-circle"
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                // style={{width:'70px' , padding:'0px'}}
                                                onClick={this.onChangeEdumanTaggedID}
                                                disabled={this.state.edumanTaggedIDbtnnDisabled}

                                            >
                                            </Button>

                                        </div>


                                        <div className="p-col-12 p-xl-12 nw-button-parent" >

                                            <div className="required-field">
                                                (<span>*</span>) required fields
                                            </div>

                                            <Button
                                                className="p-button p-button-primary nw-button nw-button-right"
                                                label="Assign"
                                                icon="fas fa-check"
                                                onClick={this.assignEmInstituteHandler}
                                                disabled={this.state.buttonDisabled}
                                            />
                                        </div>
                                        <Dialog className="nw-dialog" header="Eduman Tagged ID" visible={this.state.edumanTaggedID} onHide={this.onHideEdumanTaggedID} closable>
                                            <div className="p-fluid">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-xl-12">
                                                        <div className="nw-data-table">
                                                            <DataTable
                                                                value={this.state.dataTableValue}
                                                                selectionMode="single"
                                                                header={tableHeader}
                                                                selection={this.state.dataTableSelection}
                                                                onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                                                responsive={true}
                                                                rows={10}
                                                                columnResizeMode="fit"
                                                                paginator={true}
                                                            >
                                                                <Column field="instituteId" header="Eduman ID" filter={true} />
                                                                <Column field="instituteName" header="Institute Name" filter={true} />
                                                                <Column field="formApproveDate" header="ID Create Date" filter={true} />
                                                            </DataTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Dialog>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        );
    }
}